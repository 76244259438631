import {Box, Chip, Tooltip} from "@mui/material";

import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

export default function CardLinkedRessources({issue, scene}) {
  // data

  const ressources = useRessourcesByScene(scene);

  // helpers

  const resIds = issue.linkedTo?.ressources ? issue.linkedTo.ressources : [];
  const linkedRessources = ressources.filter((r) => resIds.includes(r.id));

  return (
    <Box sx={{p: 1, display: "flex", alignItems: "center"}}>
      {linkedRessources.map((ressource) => (
        <Box key={ressource.key}>
          <Tooltip title={ressource.name}>
            <Chip
              size="small"
              label={`${ressource.group} • ${ressource.num}`}
            />
          </Tooltip>
        </Box>
      ))}
    </Box>
  );
}
