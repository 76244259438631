import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneDefaultScopeId(scene) {
  const dispatch = useDispatch();

  const updateSceneDefaultScopeId = (scopeId) => {
    if (!scene) return;
    const newScene = {
      ...scene,
      data: {...scene?.data, defaultScopeId: scopeId},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneDefaultScopeId;
}
