import { useDispatch } from "react-redux";

import {Button, Box, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";

import { updateScene } from "Features/scenes/scenesSlice";

export default function ButtonDeleteSceneTypes({scene}) {
  const dispatch = useDispatch();

  // strings

  const label = "Supprimer les types de la scene";

  // handlers

  function handleDelete() {
    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        elementTypes: [],
      },
    };
    dispatch(updateScene({scene: newScene}));
  }
  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Button size="small" onClick={handleDelete}>
        <Delete fontSize="small" />
        <Typography variant="body2">{label}</Typography>
      </Button>
    </Box>
  );
}
