import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useSelectionMulti from "Features/selection/useSelectionMulti";
import SectionMeasurementMultiInSelectionPanel from "Features/measurements/components/SectionMeasurementMultiInSelectionPanel";

export default function SectionSelectionMulti({scene, caplaEditor}) {
  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();
  const selectionMulti = useSelectionMulti();

  return (
    <Box>
      {(sceneModule === "ELEMENT_TYPES" ||
        sceneModule === "ARTICLES" ||
        sceneModule === "EDIT_MEASUREMENTS" ||
        sceneModule === "MEASUREMENTS" ||
        sceneModule === "PACKAGES" ||
        pageType === "COLORING") &&
        selectionMulti.type === "MEASUREMENTS" && (
          <SectionMeasurementMultiInSelectionPanel
            scene={scene}
            caplaEditor={caplaEditor}
          />
        )}
    </Box>
  );
}
