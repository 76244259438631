import {
  useMemo,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import {nanoid} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import {Transforms, createEditor, Editor, Path} from "slate";
import {
  Slate,
  Editable,
  withReact,
} from "slate-react";
import {withHistory} from "slate-history";

import {withActions, insertAction} from "./plugins/actions";
import {
  withPdfExtractedText,
  insertPdfExtractedText,
} from "./plugins/pdfExtractedText";
import {withImages} from "./plugins/images";
import {withViewpoints, insertViewpoint} from "./plugins/viewpoints";
import Element from "./components/Element";
import defaultValue from "./data/defaultValue";

function TextEditor(
  {
    initialValue = defaultValue,
    onChange,
    listingId = "default",
    accessToken,
    readOnly = false,
    placeholder = "Tapez votre texte ou insérer des images",
    caplaEditor,
    sceneClientId,
  },
  ref
) {
  // local state

  const [value, setValue] = useState(initialValue);

  // handlers

  function handleValueChange(v) {
    setValue(v);
    onChange(v);
  }

  // update

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  // editor

  const editor = useMemo(
    () =>
      withImages(
        withViewpoints(
          withActions(
            withPdfExtractedText(withHistory(withReact(createEditor())))
          )
        ),
        {
          accessToken: accessToken ? accessToken : "default",
          listingId: listingId ? listingId : "default",
        }
      ),
    [] // huge bug if add here accessToken dependencies.
  );

  // functions called from parent.

  useImperativeHandle(
    ref,
    () => ({
      addAction(action) {
        insertAction(editor, action);
      },
      addPdfExtractedText(pdfExtractedText) {
        insertPdfExtractedText(editor, pdfExtractedText);
      },
      addViewpoint({camera, image, file, context}) {
        const id = nanoid();
        insertViewpoint(editor, {
          loading: true,
          id,
          accessToken,
          listingId,
          camera,
          image,
          file,
          context,
        });
      },
    }),
    [editor, accessToken]
  );

  return (
    <Box
      sx={{
        p: 0,
        width: 1,
        bgcolor: readOnly ? "unset" : "background.default",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        flexGrow: 1,
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      {(accessToken || readOnly) && listingId && (
        <Slate editor={editor} value={value} onChange={handleValueChange}>
          <Editable
            readOnly={readOnly}
            style={{padding: "8px"}}
            renderElement={(props) => (
              <Element
                {...props}
                caplaEditor={caplaEditor}
                sceneClientId={sceneClientId}
              />
            )}
            placeholder={placeholder}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                const [parentNode, parentPath] = Editor.parent(
                  editor,
                  editor.selection?.focus?.path
                );
                if (
                  parentNode.type === "image" ||
                  parentNode.type === "viewpoint" ||
                  parentNode.type === "pdfExtractedText"
                ) {
                  event.preventDefault();
                  const previousPath = parentPath[0] - 1;
                  const nextPath = parentPath[0] + 1;
                  if (previousPath >= 0) {
                    Transforms.select(editor, [previousPath]);
                    const [prevNode, prevPath] = Editor.parent(
                      editor,
                      editor.selection.focus?.path
                    );
                    console.log(prevNode);
                    Transforms.insertNodes(
                      editor,
                      {type: "paragraph", children: [{text: ""}]},
                      {at: Path.next(prevPath), select: true}
                    );
                  }
                  if (previousPath === -1) {
                    Transforms.insertNodes(
                      editor,
                      {type: "paragraph", children: [{text: ""}]},
                      {at: Path.next(parentPath), select: true}
                    );
                    //Transforms.insertText(editor, "");
                    Transforms.moveNodes(editor, {at: [0], to: [1]});
                  }
                }
              }
            }}
          />
        </Slate>
      )}
    </Box>
  );
}

export default forwardRef(TextEditor);
