import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SelectorSectorInScope from "Features/sectors/components/SelectorSectorInScope";
import SelectorRoomInScope from "Features/rooms/components/SelectorRoomInScope";
import SelectorBuildingInScope from "Features/buildings/components/SelectorBuildingInScope";

export default function SectionSelectorMeasurementScope({
  scene,
  viewer,
  editor3d,
}) {
  const scopeSelectionMode = useSelector(
    (s) => s.measurements.scopeSelectionMode
  );

  return (
    <Box>
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {scopeSelectionMode === "BY_BUILDING" && (
          <SelectorBuildingInScope scene={scene} viewer={viewer} />
        )}
        {scopeSelectionMode === "BY_SECTOR" && (
          <SelectorSectorInScope scene={scene} viewer={viewer} />
        )}
        {scopeSelectionMode === "BY_ROOM" && (
          <SelectorRoomInScope scene={scene} viewer={viewer} editor3d={editor3d} />
        )}
      </Box>
    </Box>
  );
}
