import {useDispatch, useSelector} from "react-redux";

import {Add, Edit, Refresh} from "@mui/icons-material";
import useFetchSurveys from "./useFetchSurveys";

export default function useActionsSurveysMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const fetchSurveys = useFetchSurveys(scene?.id);
  const selectedSurveyId = useSelector((s) => s.surveys.selectedSurveyId);

  // actions map

  const actionsMap = {
    fetchSurveys: {
      icon: <Refresh fontSize="small" />,
      name: "Mise à jour",
      label: "Mettre à jour les visites",
      handler: fetchSurveys,
    },
    createSurvey: {
      icon: <Add fontSize="small" />,
      name: "Visite",
      label: "Créer une visite",
    },
    editSurvey: {
      icon: <Edit fontSize="small" />,
      name: "Visite",
      label: "Modifier la visite",
    },
    deleteSurvey: {
      label: "Supprimer la visite",
      enabled: Boolean(selectedSurveyId),
    },
  };

  return actionsMap;
}
