import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {
  deleteRemoteScene,
  deleteScene,
  setSelectedSceneClientId,
} from "Features/scenes/scenesSlice";
import {deleteModel} from "Features/viewer3D/viewer3DSlice";
import {deleteStoredModelsByScene} from "Database/models/services";
import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function ButtonMoreActionsScene({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const deleteS = "Supprimer";

  // data

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId
  );

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);

    try {
      if (scene?.id) {
        await dispatch(deleteRemoteScene({scene, accessToken}));
      }
      await dispatch(deleteScene(scene));
      for (const model of models) {
        await dispatch(deleteModel({model}));
      }
      await deleteStoredModelsByScene(scene?.clientId);
      dispatch(setSelectedSceneClientId(null));
    } catch (e) {
      console.log(e);
    }
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"scene"}
      />
    </>
  );
}
