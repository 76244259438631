import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import {setSelectedMarker} from "../markersSlice";
import useSelectedMarker from "../hooks/useSelectedMarker";
import useSelectedMarkersModel from "../hooks/useSelectedMarkersModel";

import { setSelectedListItemId } from "Features/leftPanel/leftPanelSlice";

export default function ButtonBackMarkertInSelectionPanel({editor3d}) {
  const dispatch = useDispatch();

  // string

  const newS = "New";

  // data

  const selectedMarker = useSelectedMarker();
  const selectedMarkersModel = useSelectedMarkersModel();

  // handlers

  function handleClick() {
    //3D
    const entity = editor3d?.getEntity(selectedMarkersModel.id);
    entity?.unselectMarker(selectedMarker.id);
    // state
    dispatch(setSelectedMarker({}));
    dispatch(setSelectedListItemId(null));
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {newS}
    </Button>
  );
}
