import {useState, useEffect} from "react";
import useAccessToken from "Features/auth/useAccessToken";
import {useDispatch, useSelector} from "react-redux";
import {fetchSceneListings} from "Features/viewer3D/viewer3DSlice";

export default function useSceneListings({sceneId}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // data

  const sceneListings = useSelector((state) => state.viewer3D.sceneListings); // {data,status,sceneId}

  // init

  useEffect(() => {
    if (accessToken && sceneId && sceneListings.status !== "loaded")
      dispatch(fetchSceneListings({accessToken, sceneId}));
  }, [accessToken, sceneId, sceneListings.status]);

  return sceneListings;
}
