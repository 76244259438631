export default function getRessourcesFromGroupRemote(group) {
  if (Array.isArray(group?.ressources)) {
    return group.ressources.map((ressource) => {
      return {
        ...ressource,
        groupId: group.id,
        group: group.name,
        fromScene: false,
      };
    });
  } else {
    return [];
  }
}
