import React, {useState} from "react";

import {
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
} from "@mui/material";
import {Share} from "@mui/icons-material";

import ListMembers from "Features/listings/components/ListMembers";
import InputAddMembership from "Features/listings/components/InputAddMembership";

export default function ButtonDialogMemberships({listing}) {
  // strings

  const titleString = "Memberships";

  // state

  const [open, setOpen] = useState(false);

  // handle - dialog

  function openDialog() {
    setOpen(true);
  }
  function closeDialog() {
    setOpen(false);
  }

  return (
    <Box>
      <IconButton size="small" onClick={openDialog}>
        <Share fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{titleString}</DialogTitle>
        <DialogContent>
          <ListMembers listing={listing} />
          <Box sx={{my: 1}} />
          <InputAddMembership listing={listing} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
