import {createSlice} from "@reduxjs/toolkit";

export const versionsSlice = createSlice({
  name: "versions",
  initialState: {
    selectedVersionForMeasurements: null,
    shortcutVersions: [],
    selectedVersionIds: [],
    lastVersionId: null,
    selectedVersion: null,
    editedVersion: null,
    tempVersion: {},
    showDeletedInVersion: false,
  },
  reducers: {
    setSelectedVersionForMeasurements: (state, action) => {
      state.selectedVersionForMeasurements = action.payload;
    },
    setSelectedVersionIds: (state, action) => {
      state.selectedVersionIds = action.payload;
    },
    setLastVersionId: (state, action) => {
      state.lastVersionId = action.payload;
    },
    setSelectedVersion: (state, action) => {
      state.selectedVersion = action.payload;
    },
    setTempVersion: (state, action) => {
      state.tempVersion = action.payload;
    },
    setEditedVersion: (state, action) => {
      state.editedVersion = action.payload;
    },
    setShortcutVersions: (state, action) => {
      state.shortcutVersions = action.payload;
    },
    setShowDeletedInVersion: (state, action) => {
      state.showDeletedInVersion = action.payload;
    }
  },
});

export const {
  setSelectedVersionForMeasurements,
  setSelectedVersionIds,
  setLastVersionId,
  setSelectedVersion,
  setEditedVersion,
  setTempVersion,
  setShortcutVersions,
  setShowDeletedInVersion
} = versionsSlice.actions;

export default versionsSlice.reducer;
