import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

import useMeasurementsProgressForDataGrid from "../useMeasurementsProgressForDataGrid";

// import useTranslation from "Features/translations/useTranslation";

export default function DataGridMeasurementsProgress({scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  // const dispatch = useDispatch();

  // strings

  const codeS = "Code";
  const recordS = "Record";
  const progressS = "Progress";

  // grid - rows

  const rows = useMeasurementsProgressForDataGrid(scene);

  // grid - columns

  const columns = [
    {
      field: "code",
      headerName: codeS,
      flex: 0.2,
    },
    {
      field: "record",
      headerName: recordS,
      width: 100,
    },
    {
      field: "progress",
      headerName: progressS,
      width: 100,
    },
  ];

  // grid - selection model

  // handlers

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        // checkboxSelection={true}
        //onSelectionModelChange={handleSelectionChange}
        //selectionModel={selectionModel}
      />
    </Box>
  );
}
