import argbToHex from "./argbToHex";

import theme from "Styles/theme";

export default function getStyleAndPropsFromCell(cell) {
  //
  const bgTitle1 = theme.palette.excel.bgTitle1.toLowerCase();
  const bgTitle2 = theme.palette.excel.bgTitle2.toLowerCase();
  //const colorDetail = theme.palette.excel.colorDetail.toLowerCase();
  //
  const bold = cell.font?.bold;
  const underline = cell.font?.underline;
  const italic = cell.font?.italic;
  const fg = cell.fill?.fgColor?.argb;
  const bg = cell.fill?.bgColor?.argb;
  const alignmentH = cell.alignment?.horizontal;
  const color = cell.font?.color?.argb;
  //
  let style = {};
  let props = {};
  //
  if (fg) {
    style.bg = argbToHex(fg) ?? argbToHex(bg);
    if (style.bg === bgTitle1) props.style = "title1";
    if (style.bg === bgTitle2) props.style = "title2";
  }
  //
  if (color) {
    style.color = argbToHex(color);
  }
  //
  if (bold) style.bold = true;
  if (underline) style.underline = true;
  if (italic) style.italic = true;
  if (alignmentH === "right") {
    style.alignToRight = true;
  }
  //
  return {style, props};
}
