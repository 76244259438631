import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSelector} from "react-redux";
export default function useRoomsGroups(scene, options) {
  //

  const sortByName = options?.sortByName;
  const filterByScope = options?.filterByScope;

  //

  let groups = useSelector((s) => s.rooms.roomsGroups);
  const scope = useSelectedScope();

  // filter
  if (scene?.id) {
    groups = groups.filter((g) => g.sceneId === scene?.id);
  }

  if (filterByScope && scope?.id && scope?.id !== "all") {
    groups = groups.filter(
      (g) =>
        !scope?.data?.roomsGroupsIds ||
        scope?.data?.roomsGroupsIds.includes(g.id)
    );
  }

  // sort

  if (sortByName) {
    groups = groups.sort((a, b) => a.name.localeCompare(b.name));
  }

  return groups;
}
