import {useKvfTranslationMap} from "./useKvfTranslationMap";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

// import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";

export default function useKvtMeasurementV2(scene, item) {
  const translationMap = useKvfTranslationMap();

  // data

  const mode = useMeasurementsScopeMode(scene);
  const zones = useZonesByScene(scene);
  let [relatedMeasurements] = useFilteredSceneMeasurements(scene, mode);
  // Use only the elementTypes that are not title and not aggregate
  const elementTypes = useElementTypesBySceneProxy(scene).filter(
    (t) => !t.isTitle && !t.isAgg
  );
  const phases = usePhasesByScene(scene);
  const sectors = useSectorsByScene(scene);
  let rooms = useRoomsByScene(scene);
  // const materials = useMaterialsByScene(scene);
  const ressources = useRessourcesByScene(scene);
  const versions = useVersionsByScene(scene);

  // helper - elementType

  const elementType = elementTypes.find((t) => t.id === item?.elementTypeId);

  // helper - constrain rooms to selected sector
  const code = item?.sector?.code;
  if (code) rooms = rooms.filter((r) => r.sector === code);
  rooms = rooms
    .map((r) => ({...r, name: `${r.num} • ${r.name}`}))
    .sort((a, b) => a.num?.localeCompare(b.num));

  // helper - relatedMeasurements

  relatedMeasurements = relatedMeasurements
    .filter((m) => m.elementTypeId === elementType?.relatedT)
    .map((m) => ({
      ...m,
      name: m.codeName ? m.codeName : "- ? -",
    }));

  const kvtMeasurement = {
    key: "measurement",
    groups: [
      {code: "PROPS", label: translationMap["props"]},
      {code: "QUANTITIES", label: translationMap["quantities"]},
      {code: "CATEGORIES", label: translationMap["categories"]},
      {code: "VOIDS", label: translationMap["voidsSection"]},
    ],
    fields: [
      {
        key: "elementTypeId",
        name: translationMap["elementType"],
        type: "elementTypeId",
        group: "PROPS",
      },
      {
        key: "identification",
        name: translationMap["identification"],
        type: "section",
        group: "PROPS",
      },
      {
        key: "codeName",
        name: translationMap["codeName"],
        type: "text",
        group: "PROPS",
      },
      {
        group: "PROPS",
        key: "isVoid",
        name: translationMap["isVoid"],
        type: "checkbox",
      },
      // {
      //   group: "PROPS",
      //   type: "section",
      //   key: "materialSection",
      //   name: translationMap["material"],
      // },
      // {
      //   group: "PROPS",
      //   type: "combo",
      //   key: "material",
      //   name: translationMap["material"],
      //   options: materials,
      // },
      {
        key: "shape",
        name: translationMap["shape"],
        type: "section",
        group: "PROPS",
      },
      {
        key: "helperImage",
        type: "imageRead",
        height: "140px",
        group: "PROPS",
      },
      {
        group: "PROPS",
        key: "drawingShape",
        name: translationMap["shape"],
        type: "selector",
        options: [
          {key: "SEGMENT", name: translationMap["segment"]},
          {key: "BEAM", name: translationMap["beam"]},
          {key: "POLYLINE", name: translationMap["polyline"]},
          {key: "GABLE", name: translationMap["gable"]},
          {key: "SLOPE", name: translationMap["slope"]},
          {key: "STAIRS", name: translationMap["stairs"]},
          {key: "RECTANGLE", name: translationMap["rectangle"]},
          {key: "POLYGON", name: translationMap["polygon"]},
          {key: "SLOPING_POLYGON", name: translationMap["slopingPolygon"]},
          {key: "BANK", name: translationMap["bank"]},
          {key: "CIRCLE", name: translationMap["circle"]},
          {key: "BRIDGE", name: translationMap["bridge"]},
          {key: "BOWL", name: translationMap["bowl"]},
        ],
      },
      {
        group: "PROPS",
        key: "color",
        name: translationMap["color"],
        type: "color",
      },
      {
        group: "PROPS",
        key: "dotted",
        name: translationMap["dotted"],
        type: "checkbox",
      },
      {
        group: "PROPS",
        type: "section",
        key: "geometry2D",
        name: translationMap["geometry2D"],
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "POLYLINE",
            "BRIDGE",
            "RECTANGLE",
            "CIRCLE",
            "BANK",
            "GABLE",
          ],
        },
      },
      {
        group: "PROPS",
        key: "dim1",
        name: translationMap["dim1"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "STAIRS",
            "POLYLINE",
            "BRIDGE",
            "RECTANGLE",
            "CIRCLE",
            "BANK",
            "SLOPE",
            "GABLE",
          ],
        },
      },
      {
        group: "PROPS",
        key: "dim2",
        name: translationMap["dim2"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SEGMENT", "BEAM", "STAIRS", "RECTANGLE", "BANK"],
        },
      },
      {
        group: "PROPS",
        key: "dim3",
        name: translationMap["dim3"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "PROPS",
        type: "section",
        key: "geometry3D",
        name: translationMap["geometry3D"],
      },
      {
        group: "PROPS",
        key: "height",
        name: translationMap["height"],
        type: "number",
        condition: {
          type: "showIfNotEqual",
          key: "drawingShape",
          values: ["GABLE"],
        },
      },
      {
        group: "PROPS",
        key: "heights",
        name: translationMap["heights"],
        type: "text",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["GABLE"],
        },
      },
      {
        group: "PROPS",
        key: "heightE",
        name: translationMap["heightE"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "PROPS",
        key: "heightN",
        name: translationMap["heightN"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "PROPS",
        key: "side",
        name: translationMap["side"],
        type: "selector",
        options: [
          {key: "UP", name: translationMap["up"]},
          {key: "DOWN", name: translationMap["down"]},
        ],
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM", "BRIDGE", "BOWL"],
        },
      },
      {
        group: "PROPS",
        key: "faces",
        name: translationMap["faces"],
        type: "selector",
        options: [
          {key: "END", name: translationMap["endBridge"]},
          {key: "SIDE", name: translationMap["sideBridge"]},
        ],
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BRIDGE"],
        },
      },
      {
        group: "PROPS",
        key: "slopeH",
        name: translationMap["slopeH"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SLOPE"],
        },
      },
      {
        group: "PROPS",
        key: "slopingA",
        name: translationMap["slopingA"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SLOPING_POLYGON"],
        },
      },
      {
        group: "PROPS",
        type: "section",
        key: "position3D",
        name: translationMap["position3D"],
      },
      {
        group: "PROPS",
        key: "zone",
        name: translationMap["zone"],
        type: "combo",
        options: zones,
      },
      {
        group: "PROPS",
        key: "offset2d",
        name: translationMap["offset2d"],
        type: "number",
      },
      {
        group: "PROPS",
        key: "offset",
        name: translationMap["offset"],
        type: "number",
      },
      {
        group: "PROPS",
        key: "zInf",
        name: translationMap["zInf"],
        type: "number",
      },
      {
        group: "PROPS",
        key: "zSup",
        name: translationMap["zSup"],
        type: "number",
      },
      {
        group: "PROPS",
        key: "rot",
        name: translationMap["rotation"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["RECTANGLE"],
        },
      },
      {
        group: "PROPS",
        key: "relatedM",
        name: translationMap["relatedMeasurement"],
        type: "combo",
        options: relatedMeasurements,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BANK"],
        },
      },
      // {
      //   group: "PROPS",
      //   key: "file3d",
      //   name: "Fichier 3D",
      //   type: "fileUrl",
      // },
      // {
      //   group: "PROPS",
      //   type: "section",
      //   key: "updates",
      //   name: translationMap["updates"],
      // },
      // {
      //   group: "PROPS",
      //   key: "isLocked",
      //   name: translationMap["locked"],
      //   type: "checkbox",
      // },
      {
        group: "QUANTITIES",
        type: "sectionMeasurementQuantities",
        key: "quantities",
        name: translationMap["quantities"],
      },
      {
        group: "QUANTITIES",
        key: "unit",
        name: translationMap["unit"],
        type: "selector",
        options: [
          {key: "UN", name: translationMap["UN"]},
          {key: "ML", name: translationMap["ML"]},
          {key: "M2", name: translationMap["M2"]},
          {key: "M3", name: translationMap["M3"]},
        ],
      },
      {
        group: "QUANTITIES",
        key: "count",
        name: translationMap["countX"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "length",
        name: translationMap["lengthInM"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "area",
        name: translationMap["areaInM2"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "volume",
        name: translationMap["volumeInM3"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "res",
        name: translationMap["ressources"],
        type: "ressources",
      },
      // {
      //   group: "CATEGORIES",
      //   type: "section",
      //   key: "categSection",
      //   name: translationMap["categories"],
      // },
      // {
      //   group: "CATEGORIES",
      //   type: "checkbox",
      //   key: "isRoom",
      //   name: translationMap["isRoom"],
      // },
      // {
      //   group: "CATEGORIES",
      //   type: "checkbox",
      //   key: "isGhost",
      //   name: translationMap["isGhost"],
      // },
      // {
      //   group: "CATEGORIES",
      //   type: "text",
      //   key: "geoCat",
      //   name: translationMap["geometry"],
      // },
      {
        group: "CATEGORIES",
        type: "section",
        key: "location",
        name: translationMap["location"],
      },
      {
        group: "CATEGORIES",
        type: "combo",
        key: "sector",
        name: translationMap["sector"],
        options: sectors,
      },
      {
        group: "CATEGORIES",
        type: "combo",
        key: "room",
        name: translationMap["room"],
        options: rooms,
      },
      {
        group: "CATEGORIES",
        type: "combo",
        key: "ressource",
        name: translationMap["ressource"],
        options: ressources,
      },
      {
        group: "CATEGORIES",
        type: "section",
        key: "execution",
        name: translationMap["execution"],
      },
      {
        group: "CATEGORIES",
        key: "phase",
        name: translationMap["phase"],
        type: "combo",
        options: phases,
      },
      // {
      //   group: "CATEGORIES",
      //   key: "builtAt",
      //   name: translationMap["builtAt"],
      //   type: "date",
      // },
      // {
      //   group: "CATEGORIES",
      //   key: "exeComment",
      //   name: translationMap["comment"],
      //   type: "text",
      //   multiline: true,
      // },
      {
        group: "CATEGORIES",
        key: "versioning",
        name: translationMap["versioning"],
        type: "section",
      },
      {
        group: "CATEGORIES",
        key: "version",
        name: translationMap["version"],
        type: "combo",
        options: versions,
      },
      {
        group: "CATEGORIES",
        type: "checkbox",
        key: "isDeletedInV",
        name: translationMap["isDeletedInVersion"],
      },
      {
        group: "CATEGORIES",
        type: "checkbox",
        key: "isChangedInV",
        name: translationMap["isChangedInVersion"],
      },
      {
        group: "CATEGORIES",
        key: "additionalParameters",
        name: translationMap["additionalParameters"],
        type: "section",
      },
      {
        group: "CATEGORIES",
        key: "p0",
        name: "Mot clé",
        type: "text",
      },
      {
        group: "CATEGORIES",
        key: "p1",
        name: "P1",
        type: "text",
      },
      {
        group: "CATEGORIES",
        key: "p2",
        name: "P2",
        type: "text",
      },
      {
        group: "CATEGORIES",
        key: "p3",
        name: "P3",
        type: "text",
      },
      {
        group: "VOIDS",
        key: "hostedTypes",
        name: translationMap["hostedTypes"],
        type: "hostedTypes",
        condition: {
          type: "showIfEqual",
          key: "isVoid",
          values: [true],
        },
      },
      {
        group: "VOIDS",
        key: "voids",
        name: translationMap["voids"],
        type: "voids",
        condition: {
          type: "showIfEqual",
          key: "isVoid",
          values: [false, null, undefined],
        },
      },
    ],
  };

  return kvtMeasurement;
}
