import {
  Box,
  Chip,
  Tooltip,
  List,
  ListItemButton,
  Typography,
  IconButton,
} from "@mui/material";
import {Add, Visibility} from "@mui/icons-material";

export default function SelectorElementTypesGroupWithCountAndPreview({
  selectedItem,
  items,
  onClick,
  counters, // {itemId:3, itemId:5}
  showZero,
}) {
  // items data transfo

  items = items
    .map((item) => {
      let label = item?.name ? item.name : "-?-";
      if (item.code) label = `[${item.code}] ${label}`;
      return {...item, label};
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  // handler

  function handleClick(item) {
    onClick(item, {closeGroupsList: true});
  }

  function handlePreviewClick(e, item) {
    e.stopPropagation();
    e.preventDefault();
    onClick(item);
  }

  return (
    <List dense disablePadding sx={{bgcolor: "background.default"}}>
      {items.map((item) => {
        const fromScene = item.fromScene;
        let name = item?.name ? item.name : "-?-";
        if (fromScene) name = name + ".";

        let selected = item.id === selectedItem?.id;
        let color = "secondary";
        let count;
        let countLabel = "";
        if (counters) {
          count = counters[item.id];
          if (!count) {
            color = "default";
            if (showZero) countLabel = "0 elt";
          } else {
            countLabel = count + " elt";
          }
        }

        return (
          <ListItemButton
            divider
            sx={{
              p: 0,
              ...(counters && !count && {color: "text.secondary"}),
            }}
            selected={selected}
            color={color}
            key={item.id}
            size="small"
            onClick={() => handleClick(item)}
          >
            <Box sx={{p: 1, width: 1}}>
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bgcolor: "common.white",
                  p: 1,
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 1,
                  }}
                >
                  <Typography sx={{fontSize: 13}}>{item.label}</Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: count > 0 ? "text.secondary" : "divider",
                    }}
                  >
                    {countLabel}
                  </Typography>
                </Box>
                {/* <IconButton
                  size="small"
                  onClick={(e) => handlePreviewClick(e, item)}
                >
                  <Visibility fontSize="small" />
                </IconButton> */}
              </Box>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
