import {useState} from "react";

import {useDispatch} from "react-redux";
import {updateElementTypesGroup} from "../elementTypesSlice";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useSelectedElementType from "../hooks/useSelectedElementType";
import useDeleteSceneElementType from "../hooks/useDeleteSceneElementType";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

// import useAccessToken from "Features/auth/useAccessToken";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import deleteElementTypeFromElementTypesGroup from "../utils/deleteElementTypeFromElementTypesGroup";

import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonMoreActionsElementType({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const deleteS = "Delete";

  // data

  const deleteSceneElementType = useDeleteSceneElementType(scene);
  const elementType = useSelectedElementType(scene);
  const selectedGroup = useSelectedElementTypesGroup(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    if (!elementType.groupId) {
      deleteSceneElementType(elementType);
    } else {
      const updatedGroup = deleteElementTypeFromElementTypesGroup(
        elementType.id,
        selectedGroup
      );
      setLoading(false);
      await dispatch(
        updateElementTypesGroup({elementTypesGroup: updatedGroup, accessToken})
      );
      setLoading(true);
    }
    setOpenDeleteDialog(false);
    setAnchorEl(null);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"elementType"}
      />
    </>
  );
}
