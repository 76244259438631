// import {AmbientLight, DirectionalLight} from "three";
import {AmbientLight, DirectionalLight} from "three";

class StandardMode {
  constructor() {
    this.ambientLight = new AmbientLight(0xffffff, 0.2);

    this.directionalLight = new DirectionalLight(0xffffff, 1);
    this.directionalLight.position.set(100, 50, 0);
    //this.directionalLight.target.position.set(-5, 0, 0);
    this.directionalLight.layers.enable(1);
    this.directionalLight.layers.enable(3);
    this.directionalLight.castShadow = true;

    this.directionalLight2 = new DirectionalLight(0xffffff, 0.8);
    this.directionalLight2.position.set(-100, 50, 100);
    //this.directionalLight2.target.position.set(-5, 0, 0);
    this.directionalLight2.layers.enable(1);
    this.directionalLight2.layers.enable(3);

    this.directionalLight3 = new DirectionalLight(0xffffff, 0.6);
    this.directionalLight3.position.set(-100, 50, -100);
    //this.directionalLight3.target.position.set(-5, 0, 0);
    this.directionalLight3.layers.enable(1);
    this.directionalLight3.layers.enable(3);

    this.isEnabled = false;

    this.directionalLights = [
      this.directionalLight,
      this.directionalLight2,
      this.directionalLight3,
    ];
  }

  loadTo(scene) {
    scene.add(
      this.ambientLight,
      this.directionalLight,
      this.directionalLight2,
      this.directionalLight3
      //this.directionalLight.target
    );
  }
  enable() {
    this.ambientLight.layers.enable(1);
    this.ambientLight.layers.enable(3);

    this.directionalLights.forEach((light) => {
      light.layers.enable(1);
      light.layers.enable(3);
    });

    this.isEnabled = true;
  }
  disable() {
    this.ambientLight.layers.disable(1);
    this.ambientLight.layers.disable(3);

    //this.directionalLight.target.layers.disable(1);
    //this.directionalLight.target.layers.disable(3);

    this.directionalLights.forEach((light) => {
      light.layers.disable(1);
      light.layers.disable(3);
    });

    this.isEnabled = false;
  }
}
export default StandardMode;
