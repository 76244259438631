import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionDetailModel from "./components/SectionDetailModel";
import SectionListModels from "./components/SectionListModels";

import Stretch from "Features/ui/components/Stretch";

export default function ToolModels({editor3d, scene, onClose, viewer}) {
  // data

  const selectedModelId = useSelector(
    (state) => state.viewer3D.selectedModelId
  );
  const allModels = useSelector((state) => state.viewer3D.models);

  // helpers

  const model = allModels.find((m) => m.id === selectedModelId);

  return (
    <Box sx={{width: 325}}>
      <Stretch>
        {selectedModelId ? (
          <SectionDetailModel
            editor3d={editor3d}
            scene={scene}
            model={model}
            viewer={viewer}
          />
        ) : (
          <SectionListModels editor3d={editor3d} scene={scene} onClose={onClose} />
        )}
      </Stretch>
    </Box>
  );
}
