import React, {useState} from "react";

import {Box} from "@mui/material";

import LastColumn from "./LastColumn";
import SectionColumn from "./SectionColumn";
import getItemsFromSelectedItem from "../utils/getItemsFromSelectedItem";

export default function SectionEditableTree({
  rankNames,
  items,
  onRankNamesChange,
  onItemsChange,
}) {
  // tree = {ranksNames:[],items:[{id,num,name}]}

  // state

  const [selectedItem, setSelectedItem] = useState(null);

  // handlers

  function handleRankNameChange({oldName, newName}) {
    const newRankNames = rankNames.map((name) => {
      if (name === oldName) {
        return newName;
      } else {
        return name;
      }
    });
    onRankNamesChange(newRankNames);
  }

  function handleAddColumn(rankName) {
    console.log("debugAFA add rankName", rankName);
    const newRanks = [...rankNames, rankName];
    onRankNamesChange(newRanks);
  }

  function handleAddItem(item) {
    console.log("debugAFA add item", item);
    const newItems = [...items, item];
    onItemsChange(newItems);
  }

  function handleChangeItem(changedItem) {
    console.log("debugAFA changedItem", changedItem);
    const newItems = items.map((item) => {
      if (item.id === changedItem.id) {
        return changedItem;
      } else {
        return item;
      }
    });
    onItemsChange(newItems);
  }

  function handleSelectItem(item) {
    setSelectedItem(item);
  }

  return (
    <Box sx={{display: "flex", p: 1, height: 1, width: 1}}>
      {rankNames?.map((rankName, index) => {
        // items by rank
        const rankItems = getItemsFromSelectedItem({
          items,
          selectedItem,
          rank: index + 1,
        });
        // showAddItem
        const selectedItemRank = selectedItem
          ? selectedItem.num.split(".").length
          : 1;
        const showAddItem =
          (index === 0 || selectedItem) && index <= selectedItemRank;
        return (
          <Box
            key={rankName}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "stretch",
              border: "2px solid blue",
              width: "20%",
            }}
          >
            <SectionColumn
              rankName={rankName}
              allItems={items}
              items={rankItems}
              selectedItem={selectedItem}
              onRankNameChange={handleRankNameChange}
              onAddItem={handleAddItem}
              onChangeItem={handleChangeItem}
              onSelect={handleSelectItem}
              showAddItem={showAddItem}
            />
          </Box>
        );
      })}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch",
          border: "1px solid green",
          width: "25%",
          px: 1,
        }}
      >
        <LastColumn onAdd={handleAddColumn} />
      </Box>
    </Box>
  );
}
