import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "API/capla360";

export const postNote = createAsyncThunk(
  "notes/postNote",
  async ({accessToken, listingId, note}) => {
    const response = await client.post(`listings/${listingId}/notes/`, note, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return {data: response.data, listingId};
  }
);

export const fetchNotes = createAsyncThunk(
  "notes/fetchNotes",
  async ({accessToken, listingId}) => {
    const response = await client.get(`/listings/${listingId}/notes/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return {data: response.data, listingId};
  }
);
export const notesSlice = createSlice({
  name: "notes",
  initialState: {datasets: {}},
  reducers: {
    addNote: (state, action) => {
      console.log(action);
    },
  },
  extraReducers: {
    [fetchNotes.pending]: (state, action) => {
      const listingId = action.meta;
      state.datasets[listingId] = {status: "loading"};
    },
    [fetchNotes.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId] = {data, status: "succeed"};
    },
    [fetchNotes.rejected]: (state, action) => {
      const listingId = action.meta;
      state.datasets[listingId].status = "failed";
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {notes} = action.payload;
      notes.forEach((note) => {
        state.datasets[note.listing.id] = {data: note.items, status: "succeed"};
      });
    },
    [postNote.fulfilled]: (state, action) => {
      const {data: newNote, listingId} = action.payload;
      const prevNotes = state.datasets[listingId]?.data;
      if (Array.isArray(prevNotes)) {
        state.datasets[listingId].data.push(newNote);
      } else {
        state.datasets[listingId].data = [newNote];
      }
      state.datasets[listingId].data = state.datasets[listingId].data
        .slice()
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
    "note/updateNote/fulfilled": (state, action) => {
      const {data: updatedNote, listingId, noteId} = action.payload;
      // we update the full notes if it was previously fetched
      if (state.datasets[listingId]) {
        const notes = state.datasets[listingId].data.map((note) => {
          if (note.id !== noteId) {
            return note;
          } else {
            console.log("update notes state with", updatedNote);
            return updatedNote;
          }
        });
        state.datasets[listingId].data = notes;
      }
    },
  },
});

export const {addNote} = notesSlice.actions;

// selectors

export const selectNotesRows = (state) => {
  const notes = state.notes.ids.map((id) => {
    const note = state.notes.items[id];
    return {id, ...note};
  });
  return notes;
};

// const notes = {"modelId":{notes:{nodeId:note,...},elements:{"codeName":[nodeId,...]}}}
// notes of one element : notes[modelRemoteId].elements["codeName"].

export const selectOpenedNotesByModel = (state) => {
  const notes = [];

  Object.entries(state.notes.datasets).forEach(([key, value]) => {
    if (Array.isArray(value?.data)) notes.push(...value.data);
  });

  const notesByModel = {};

  notes
    .filter((note) => note.isOpened && note.relationsElements?.length > 0)
    .forEach((note) => {
      note.relationsElements.forEach(({sceneModel, elements}) => {
        // notes[sceneModel].notes
        if (notesByModel[sceneModel]?.notes) {
          notesByModel[sceneModel].notes[note.id] = note;
        } else {
          notesByModel[sceneModel] = {notes: {[note.id]: note}, elements: {}};
        }
        // notes[sceneModel].elements
        elements.codeNames.forEach((codeName) => {
          if (notesByModel[sceneModel].elements[codeName]) {
            notesByModel[sceneModel].elements[codeName] = [
              ...notesByModel[sceneModel].elements[codeName],
              note.id,
            ];
          } else {
            notesByModel[sceneModel].elements = {
              ...notesByModel[sceneModel].elements,
              [codeName]: [note.id],
            };
          }
        });
      });
    });
  return notesByModel;
};

export default notesSlice.reducer;
