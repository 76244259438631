import {Box, List, ListItemButton, Typography} from "@mui/material";

export default function ListScopes({
  scopes,
  selectedScopeId,
  selectedScopeIdInScene,
  onSelectionChange,
}) {
  // handlers

  function handleClick(scope) {
    if (selectedScopeId === scope.id) {
      onSelectionChange(null);
    } else {
      onSelectionChange(scope.id);
    }
  }

  return (
    <List disablePadding>
      {scopes.map((scope) => {
        const selected = scope.id === selectedScopeId;
        const selectedInScene = scope.id === selectedScopeIdInScene;
        return (
          <ListItemButton
            selected={selected}
            key={scope.id}
            onClick={() => handleClick(scope)}
            divider
          >
            <Typography
              variant="body2"
              sx={{...(selectedInScene && {fontWeight: "bold"})}}
            >
              {scope?.name}
            </Typography>
          </ListItemButton>
        );
      })}
    </List>
  );
}
