import {Button, Box} from "@mui/material";

export default function ButtonShowIssueImageOverlay({editor3d, issue}) {
  // string

  const showS = "Superposer l'image à la 3D";

  // handlers

  function handleClick() {
    editor3d?.imageOverlay.setImage(issue.imageUrl);
    editor3d?.imageOverlay.show();
    const camera = issue.context?.camera;
    if (camera) editor3d?.sceneEditor.updateCamera(camera);
  }
  return (
    <Box sx={{color: "text.secondary"}}>
      <Button onClick={handleClick} size="small" color="inherit">
        {showS}
      </Button>
    </Box>
  );
}
