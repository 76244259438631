import React, {useState} from "react";

import {
  Box,
  List,
  ListItem,
  Typography,
  Divider,
  TextField,
} from "@mui/material";

import MeasuresTableItem from "./MeasuresTableItem";
export default function MeasuresTable({points, pointsArray, area}) {
  // helpers

  const startDistance = pointsArray[0].length > 1;

  const distances = pointsArray[0].reduce((acc, current, index) => {
    if (index >= 1) acc.push(computeDistance(current, points[index - 1]));
    return acc;
  }, []);

  const totalDistance = distances.reduce((sum, d) => sum + d, 0); // we just need the first perimeter

  function computeDistance(point1, point2) {
    if (point1 && point2) {
      return Math.sqrt(
        (point1.x - point2.x) ** 2 +
          (point1.y - point2.y) ** 2 +
          (point1.z - point2.z) ** 2
      );
    } else {
      return undefined;
    }
  }

  // strings

  const totalDistanceString = "Longueur totale";
  const areaString = "Surface";
  const depthString = "Depth (m)";
  const volumeString = "Volume";

  // state

  const [depth, setDepth] = useState("");
  const volume = area && depth && depth * area;

  // handlers

  function handleDepthChange(e) {
    let d = e.target.value;
    d = d.replace(",", ".");
    setDepth(d);
  }

  return (
    <Box sx={{width: 1}}>
      {pointsArray.map((points, index) => (
        <MeasuresTableItem key={index} points={points} isHole={index > 0} />
      ))}

      {startDistance && <Divider sx={{width: 1, mb: 1}} />}
      {startDistance && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{width: 100}}>
            {area && (
              <TextField
                size="small"
                label={<Typography variant="body2">{depthString}</Typography>}
                value={depth}
                onChange={handleDepthChange}
                inputProps={{
                  sx: (theme) => ({
                    fontSize: theme.typography.body2.fontSize,
                  }),
                }}
              />
            )}
          </Box>

          <Box sx={{ml: 2}}>
            <Typography
              variant="body2"
              noWrap
            >{`${totalDistanceString} ${totalDistance.toFixed(
              3
            )} m`}</Typography>
            {area && (
              <Typography variant="body2">{`${areaString} ${area.toFixed(
                3
              )} m2`}</Typography>
            )}
            {volume && (
              <Typography variant="body2">{`${volumeString} ${volume.toFixed(
                3
              )} m3`}</Typography>
            )}
          </Box>
        </Box>
      )}
      <Box sx={{height: 24}} />
    </Box>
  );
}
