import {Box, IconButton, Icon, Tooltip, Badge} from "@mui/material";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function ContainerFilterTab({
  tab,
  onClick,
  selectedTab,
  count,
  exclude,
}) {
  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  const icon = iconMap[tab];
  const selected = selectedTab === tab;
  const title = nameMap[tab];

  let bgcolor = selected ? "darkGrey" : "lightGrey";
  if (count > 0) bgcolor = selected ? "secondary.dark" : "secondary.light";

  return (
    <Tooltip title={title}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: bgcolor,
          color: "common.white",
          height: 32,
          width: 32,
          borderRadius: 16,
        }}
      >
        <IconButton
          color="inherit"
          fontSize="small"
          onClick={() => onClick(tab)}
        >
          <Badge
            badgeContent={count}
            invisible={count === "none"}
            color={exclude ? "primary" : "secondary"}
            showZero
          >
            <Icon fontSize="small">{icon}</Icon>
          </Badge>
        </IconButton>
      </Box>
    </Tooltip>
  );
}
