export function createIssueFromTitleAndScreenshot(title, screenshot) {
  const imageUrl = screenshot.url;
  const {fromViewMode, position, camera, sceneContext, pdfContext} =
    screenshot.options;

  const context = {};
  if (camera) context.camera = camera;
  if (sceneContext) context.sceneContext = sceneContext;
  if (pdfContext) context.pdfContext = pdfContext;

  return {title, imageUrl, position, context, fromViewMode};
}
