import {useSelector, useDispatch} from "react-redux";
import {setLinkedTypeGroupsOnly} from "../boxhomeSlice";

import {FormControlLabel, Typography, Switch, Box} from "@mui/material";

export default function SwitchLinkedTypeGroupsOnly() {
  const dispatch = useDispatch();

  // string

  const label = "Bibliothèques utilisées uniquement";

  // data

  const checked = useSelector((s) => s.boxhome.linkedTypeGroupsOnly);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setLinkedTypeGroupsOnly(checked));
  }

  return (
    <Box sx={{pl: 2, py: 0.5}}>
      <FormControlLabel
        control={
          <Switch size="small" value={checked} onChange={handleChange} />
        }
        label={
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {label}
          </Typography>
        }
      />
    </Box>
  );
}
