import {useSelector} from "react-redux";

export default function useBestViewProps(bestView) {
  const os = useSelector((s) => s.ui.openSections);

  let viewer = "viewer3D";
  if (os.fixedViewersBox) viewer = "fixedViewersBox";

  const viewsMap = {
    // no selection
    grid_selection: {
      navPanel: false,
      outlet: true,
      fixedViewersBox: false,
      viewer3D: false,
      callToAction: false,
      selection: true,
      fullscreen: false,
    },
    //generic,
    grid_viewer_selection: {
      navPanel: false,
      outlet: true,
      fixedViewersBox: viewer === "fixedViewersBox",
      viewer3D: viewer === "viewer3D",
      callToAction: false,
      selection: true,
      fullscreen: true,
    },
    viewer_selection: {
      navPanel: false,
      outlet: false,
      fixedViewersBox: viewer === "fixedViewersBox",
      viewer3D: viewer === "viewer3D",
      callToAction: false,
      selection: true,
      fullscreen: true,
    },
    viewer: {
      navPanel: false,
      outlet: false,
      fixedViewersBox: viewer === "fixedViewersBox",
      viewer3D: viewer === "viewer3D",
      callToAction: false,
      selection: false,
      fullscreen: true,
    },
    // pdf
    pdf: {
      navPanel: false,
      outlet: false,
      fixedViewersBox: true,
      viewer3D: false,
      callToAction: false,
      selection: false,
      fullscreen: true,
    },
    pdf_image: {
      navPanel: false,
      outlet: false,
      fixedViewersBox: true,
      viewer3D: false,
      callToAction: true,
      selection: false,
      fullscreen: true,
      fixedViewersBoxWidth: 0.5,
      //viewer3DWidth: 0.5,
    },
    pdf_selection: {
      navPanel: false,
      outlet: false,
      fixedViewersBox: true,
      viewer3D: false,
      callToAction: false,
      selection: true,
      fullscreen: true,
    },
    grid_pdf_selection: {
      navPanel: false,
      outlet: true,
      fixedViewersBox: true,
      viewer3D: false,
      callToAction: false,
      selection: true,
      fullscreen: false,
      fixedViewersBoxWidth: 0.7,
    },
  };

  const defaultProps = {
    navPanel: true,
    outlet: true,
    fixedViewersBox: false,
    viewer3D: false,
    callToAction: false,
    selection: true,
    fullscreen: false,
  };

  const props = viewsMap[bestView] ? viewsMap[bestView] : defaultProps;

  const hashA = Object.entries(props).map(([prop, bool]) => (bool ? "1" : "0"));
  const hash = hashA.join();

  return [props, hash];
}
