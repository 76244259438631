import {useSelector, useDispatch} from "react-redux";

import {setOpenPackagesPanel} from "../packagesSlice";

import {Box, Typography} from "@mui/material";
import {Layers as Packages, ArrowDropDown as Down} from "@mui/icons-material";

export default function SelectorPackageInTopbarOverview() {
  const dispatch = useDispatch();

  // data

  const enabledPackageIds = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );

  // helper

  const count = enabledPackageIds?.length;

  // helper - label

  let label = count > 1 ? `${count} calques` : "1 calque";
  if (!count) label = "0 calque actif";

  // handlers

  function handleClick() {
    console.log("click");
    dispatch(setOpenPackagesPanel(true));
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        color: "common.white",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        px: 0.5,
      }}
    >
      <Packages color="inherit" sx={{fontSize: 16}} />
      <Typography sx={{fontSize: 12, px: 0.5}}>{label}</Typography>
      <Down color="inherit" sx={{fontSize: 16}} />
    </Box>
  );
}
