import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {createRessourcesGroup} from "Features/ressources/ressourcesSlice";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";

import useAccessToken from "Features/auth/useAccessToken";

const unitMap = {
  UN: "un",
  ML: "m",
  M2: "m²",
  M3: "m³",
};

const formulMap = {
  UN: "C",
  ML: "L",
  M2: "S",
  M3: "V",
};

export default function useCreateRessourcesGroupFromTypesGroup() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const updateElementTypesGroup = useUpdateElementTypesGroup();

  //

  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  //

  const createRessourcesFromTypes = async (elementTypesGroup) => {
    const types = elementTypesGroup?.elementTypes;
    const updatedTypes = [];
    const ressources = [];
    const editedRelations = {...relations};

    // update types, ressources & relations

    types.forEach((t) => {
      const id = nanoid();

      const newRessource = {
        id,
        num: t.num,
        code: t.code,
        name: t.name,
        description: "",
        unit: unitMap[t.unit],
        //types: [relation],
      };
      if (t.isTitle) newRessource.isTitle = t.isTitle;
      if (t.style) newRessource.style = t.style;

      const relation = {
        id: `${t.id}-${id}`,
        typeId: t.id,
        resId: id,
        mode: "FORMUL",
        func: formulMap[t.unit],
      };

      const resItem = {resId: id, mode: "FORMUL", func: formulMap[t.unit]};

      ressources.push(newRessource);
      const updatedType = {...t};
      if (!t.isTitle) {
        updatedType.res = t.res ? [...t.res, resItem] : [resItem];
      }
      updatedTypes.push(updatedType);
      editedRelations[`${t.id}-${id}`] = relation;
    });

    // create ressources group

    const newGroup = {
      name: elementTypesGroup.name,
      sceneId: elementTypesGroup.sceneId,
      fromScene: false,
      ressources,
    };
    await dispatch(
      createRessourcesGroup({ressourcesGroup: newGroup, accessToken})
    );

    // update elementTypesGroup
    const updatedElementTypesGroup = {
      ...elementTypesGroup,
      elementTypes: updatedTypes,
    };
    await updateElementTypesGroup(updatedElementTypesGroup);

    dispatch(setRelationsTypesRessources(editedRelations));

    return updatedTypes;
  };
  return createRessourcesFromTypes;
}
