import {nanoid} from "@reduxjs/toolkit";

import useRessourcesByScene from "./useRessourcesByScene";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import sortByNum from "Features/ressources/utils/sortByNum";

export default function useRessourcesAndTypesByScene(scene, options) {
  const editMode = options?.editMode;
  // data

  const types = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene, options);

  const theMap = {};
  types.forEach((type) => {
    if (Array.isArray(type.res)) {
      const typeId = type.id;
      type.res.forEach(({id, resId, mode, func}) => {
        if (theMap[resId])
          theMap[resId].push({
            id: id ? id : nanoid(),
            typeId,
            resId,
            mode,
            func,
          });
        else
          theMap[resId] = [
            {
              id: id ? id : nanoid(),
              typeId,
              resId,
              mode,
              func,
            },
          ];
      });
    }
  });

  const result = ressources.map((ressource) => {
    return {...ressource, types: theMap[ressource.id]};
  });

  return result.sort((a, b) => sortByNum(a.num, b.num));
}
