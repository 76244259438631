import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setStepBasic} from "../elementTyporSlice";

import {Box} from "@mui/material";

import SectionStepIdentification from "./SectionStepIdentification";
import SectionStepShape from "./SectionStepShape";
import SectionStepDimensions from "./SectionStepDimensions";
import SectionStepTree from "./SectionStepTree";
import SectionStepRessources from "./SectionStepRessources";

import SectionStepGlobal from "./SectionStepGlobal";

export default function SectionSteps({scene}) {
  const dispatch = useDispatch();

  // data

  const stepBasic = useSelector((s) => s.elementTypor.stepBasic);

  // helper

  const hideIdentification = stepBasic !== "IDENTIFICATION";
  const hideShape = stepBasic !== "SHAPE";
  const hideDimensions = stepBasic !== "DIMENSIONS";
  const hideTree = stepBasic !== "TREE";
  const hideRessources = stepBasic !== "RESSOURCES";

  const showGlobal = stepBasic === "GLOBAL";

  useEffect(() => {
    return () => {
      dispatch(setStepBasic("GLOBAL"));
    };
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
      }}
    >
      {!hideIdentification && <SectionStepIdentification />}
      {!hideShape && <SectionStepShape />}
      {!hideDimensions && <SectionStepDimensions scene={scene} />}
      {!hideTree && <SectionStepTree scene={scene} />}
      {!hideRessources && <SectionStepRessources scene={scene} />}

      {showGlobal && <SectionStepGlobal scene={scene} />}
    </Box>
  );
}
