import {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

// import {setSelectedModelId, setHomeViewMode} from "Features/ui/uiSlice";
// import {setOpenSections, newFirstLoadedPage} from "Features/ui/uiSlice";
import {setPdfViewerMode} from "Features/pdf/pdfSlice";
// import {setIsViewerFullScreen} from "Features/viewer3D/viewer3DSlice";
import useIsMobile from "Features/ui/useIsMobile";
import Stretch from "Features/ui/components/Stretch";
import ListContainerSceneDatasets from "Features/scenes/components/ListContainerSceneDatasets";
import ListIssuesBySceneImageGrid from "Features/issues/components/ListIssuesBySceneImageGrid";
import SectionMeasurementsInHome from "Features/measurements/components/SectionMeasurementsInHome";

export default function SceneHome() {
  const dispatch = useDispatch();
  const {caplaEditor, scene, viewer} = useOutletContext();

  // data

  // let models = useSelector((s) => s.viewer3D.models);
  // let issues = useSelector((s) => s.issues.items);

  // const selectedModelId = useSelector((s) => s.ui.selectedModelId);
  // const selectedIssueId = useSelector((s) => s.ui.selectedIssueId);
  const homeViewMode = useSelector((s) => s.ui.homeViewMode);
  // const firstLoadedPages = useSelector((s) => s.ui.firstLoadedPages);

  // const mobile = useSelector((s) => s.ui.mobile);
  const isMobile = useIsMobile();

  // helpers - isFirstRender

  // const isFirstRender = !firstLoadedPages.includes("HOME");

  // helpers

  // const selectedModel = models.find((m) => m.id === selectedModelId);
  // const selectedIssue = issues.find((i) => i.id === selectedIssueId);

  // const selectedIssuePdfModelId = selectedIssue?.context?.pdfContext?.modelId;

  // helpers - fullscreenHAsh

  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const fullscreenHash = fullscreen ? "1" : "0";

  // helpers - openSections

  let os = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(os).join("-");

  useEffect(() => dispatch(setPdfViewerMode("DEFAULT")), []);

  // // effect : display notes for trainings

  // useEffect(() => {
  //   if (scene?.description?.includes("Formation")) {
  //     dispatch(setHomeViewMode("ISSUES"));
  //   }
  // }, [scene?.description]);

  // // display callToAction by default

  // useEffect(() => {
  //   console.log("[EFFECT 91]");
  //   const _os = {...os};
  //   if (!viewer && isFirstRender) {
  //     _os.outlet = true;
  //     //_os.callToAction = true;
  //     _os.viewer3D = true;
  //   }
  //   if (
  //     !viewer &&
  //     !os.viewer3D &&
  //     !os.fixedViewersBox &&
  //     !os.callToAction &&
  //     !os.outlet
  //   ) {
  //     _os.outlet = true;
  //   }
  //   // home - issues - edge case

  //   if (homeViewMode === "ISSUES" && !selectedIssue && os.fixedViewersBox) {
  //     _os.fixedViewersBox = false;
  //     _os.callToAction = true;
  //     dispatch(setSelectedModelId(null));
  //   }
    // issue selected edge case

    // comment : edge case : home page, ressource issue selected.
    // if (
    //   selectedIssue &&
    //   !selectedIssuePdfModelId &&
    //   os.fixedViewersBox &&
    //   os.outlet
    // ) {
    //   _os.fixedViewersBox = false;
    //   _os.viewer3D = true;
    // }
    // if (selectedIssue && selectedIssuePdfModelId && os.viewer3D && os.outlet) {
    //   _os.viewer3D = false;
    //   _os.fixedViewersBox = true;
    // }

  //   // pdf selected edge case

  //   if (
  //     selectedModel?.type === "PDF" &&
  //     os.callToAction &&
  //     !os.fixedViewersBox
  //   ) {
  //     _os.callToAction = false;
  //     _os.fixedViewersBox = true;
  //   }

  //   // measurements home edge case

  //   if (mobile && os.outlet) {
  //     _os.callToAction = false;
  //   }

  //   dispatch(setOpenSections(_os));
  // }, [
  //   osHash,
  //   selectedModelId,
  //   isFirstRender,
  //   selectedIssueId,
  //   mobile,
  //   homeViewMode,
  // ]);

  // useEffect(() => {
  //   dispatch(newFirstLoadedPage("HOME"));
  //   dispatch(setIsViewerFullScreen(false));
  // }, []);

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        width: 1,
        display: "flex",
        flexDirection: "column",
        minWidth: 0,
        minHeight: 0,
      }}
    >
      {!isMobile && <Box sx={{height: "60px"}} />}
      {homeViewMode === "ISSUES" && os.outlet && (
        <Box>
          <Stretch>
            <Box sx={{height: 1}}>
              <Box
                sx={{
                  overflowY: "auto",
                  height: 1,
                  mr: "-50px",
                  pr: "50px",
                  pb: 10,

                  boxSizing: "content-box",
                }}
              >
                <ListIssuesBySceneImageGrid scene={scene} caplaEditor={caplaEditor} />
                <Box sx={{height: "30px"}} />
              </Box>
            </Box>
          </Stretch>
        </Box>
      )}

      {homeViewMode === "MEASUREMENTS" && os.outlet && (
        <SectionMeasurementsInHome
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      )}
      {homeViewMode === "DATA" && os.outlet && (
        <Box sx={{display: "flex", mt: 2}}>
          <Box sx={{flexGrow: 1}}>
            {/* <Box sx={{height: "150px", mb: 2}}>
            <ImageScene scene={scene} />
          </Box> */}
            <Stretch propDependency={osHash + fullscreenHash}>
              <Box
                sx={{
                  width: 1,
                  height: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ListContainerSceneDatasets
                  scene={scene}
                  caplaEditor={caplaEditor}
                  viewer={viewer}
                />
              </Box>
            </Stretch>
          </Box>
        </Box>
      )}
    </Box>
  );
}
