import {Box} from "@mui/material";

import CardSelection from "./components/CardSelection";
import CardHiddenElements from "./components/CardHiddenElements";

import TitleCloseHeader from "Components/TitleCloseHeader";

export default function ToolHide({editor3d, onClose}) {
  // strings

  const title = "Hide Park";

  // handlers

  function handleClose() {
    onClose();
  }
  return (
    <Box
      sx={{
        width: 300,
        backgroundColor: "background.default",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <TitleCloseHeader title={title} onClose={handleClose} divider={false} />
      <Box sx={{p: 1}}>
        <CardHiddenElements editor3d={editor3d} />
      </Box>
      <Box sx={{p: 1}}>
        <CardSelection editor3d={editor3d} />
      </Box>
    </Box>
  );
}
