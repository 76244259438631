import {useParams} from "react-router-dom";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";

export default function useMeasurementsScopeMode(scene, viewer) {
  const sceneModule = useSceneModule();
  const pageType = usePageType();

  const {modelId} = useParams();
  const isEditMode = modelId ? true : false;

  let mode = "MULTI_MODELS";
  if (
    sceneModule === "EDIT_MEASUREMENT" ||
    sceneModule === "ANNOTATED_PDF" ||
    isEditMode ||
    //mIds.length === 1 ||
    pageType === "COLORING"
  ) {
    mode = "ONE_MODEL";
  }
  return mode;
}
