import {useState} from "react";
import {useDispatch} from "react-redux";

import useDrawingShapeImageUrlMap from "Features/elementTypes/hooks/useDrawingShapeImageUrlMap";
import useDrawingShapeDefaultUnitMap from "Features/elementTypes/hooks/useDrawingShapeDefaultUnitMap";

import useTempElementType from "../hooks/useTempElementType";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button} from "@mui/material";

import SectionStepper from "./SectionStepper";
import SectionSteps from "./SectionSteps";
import SectionStepTree from "./SectionStepTree";

import ContainerEditElementType from "./ContainerEditElementType";

import ImageBasic from "Features/images/components/ImageBasic";
import useCreateElementType from "Features/elementTypes/hooks/useCreateElementType";
import {resetTempElementType} from "../elementTyporSlice";

export default function SectionCreateElementTypeBasic({
  scene,
  caplaEditor,
  onCreated,
}) {
  const dispatch = useDispatch();
  // strings

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);

  // data

  const tempElementType = useTempElementType(scene);
  const imageMap = useDrawingShapeImageUrlMap();
  const shape = tempElementType?.drawingShape;
  const unitMap = useDrawingShapeDefaultUnitMap();

  const createElementType = useCreateElementType(scene, {resetNums: true});

  // helpers

  const canSave = tempElementType?.name;
  // handler

  async function handleCreate() {
    if (loading) return;
    setLoading(true);
    const {name, code, drawingShape, color, num, dim1, dim2, height, style} =
      tempElementType;
    const id = nanoid();
    const newType = {
      id,
      name,
      code,
      drawingShape,
      color,
      num,
      style,
      unit: unitMap[drawingShape],
    };
    if (dim1) {
      dim1.replace(",", ".");
      newType.dim1 = Number(dim1);
    }
    if (dim2) {
      dim2.replace(",", ".");
      newType.dim2 = Number(dim2);
    }
    if (height) {
      height.replace(",", ".");
      newType.height = Number(height);
    }
    await createElementType(newType);
    setLoading(false);
    onCreated();
    dispatch(resetTempElementType());
  }

  return (
    <Box
      sx={{display: "flex", justifyItems: "stretch", minHeight: 0, flexGrow: 1}}
    >
      <Box sx={{display: "flex", flexDirection: "column", minHeight: 0}}>
        <SectionStepTree />
      </Box>
      <Box>
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
              px: 2,
            }}
          >
            <Box sx={{width: 400}}>
              <ContainerEditElementType elementType={tempElementType} />
            </Box>
          </Box>

          <Box
            sx={{
              p: 1,
              display: "flex",
              width: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{flexGrow: 1}}>{/* <SectionStepper /> */}</Box>
            <Button
              disabled={loading || !canSave}
              onClick={handleCreate}
              size="small"
              variant="contained"
            >
              {createS}
            </Button>
          </Box>

          <Box
            sx={{
              mt: 2,
              height: "400px",
              display: "flex",
              justifyItems: "stretch",
              minHeight: 0,
              bgcolor: "background.default",
            }}
          >
            <SectionSteps scene={scene} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
