import {Box} from "@mui/material";

import useSelectedMeasurement from "Features/measurements/useSelectedMeasurement";

import ToolbarMeasurementPdf from "Features/measurements/components/ToolbarMeasurementPdf";

import {useSelector} from "react-redux";
import ToolbarBottomViewerPdfCallToAction from "./ToolbarBottomViewerPdfCallToAction";
import useSelectedPdfModel from "../useSelectedPdfModel";
import ButtonCreateZone from "Features/zones/components/ButtonCreateZone";

export default function ToolbarBottomViewerPdfEditMeasurements({
  caplaEditor,
  scene,
  viewer,
}) {
  // data

  const selectedPdfModel = useSelectedPdfModel();
  const currentPage = useSelector((s) => s.viewer3D.pdfCurrentPage);

  // data - selected ElementType

  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );

  // data - selected Measurement

  const selectedMeasurement = useSelectedMeasurement();

  // helpers - pdf has zones

  const zones = selectedPdfModel?.zones;

  const hasZones =
    zones?.filter((zone) => zone?.pageNumber === currentPage)?.length > 0;

  // helpers - show

  const showCreateZone = !hasZones;
  //const showCreateZone = false;

  const showSelectMeas =
    !selectedElementTypeId && !selectedMeasurement && hasZones;
  //const showSelectMeas = !selectedElementTypeId && !selectedMeasurement;

  const showTools = (hasZones && selectedElementTypeId) || selectedMeasurement;
  //const showTools = selectedElementTypeId;

  return (
    <Box>
      {showTools && (
        <ToolbarMeasurementPdf caplaEditor={caplaEditor} scene={scene} />
      )}
      {showSelectMeas && (
        <ToolbarBottomViewerPdfCallToAction
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {showCreateZone && (
        <Box
          sx={{
            display: "flex",
            p: 1,
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "warning.main",
            borderRadius: "8px",
          }}
        >
          <ButtonCreateZone caplaEditor={caplaEditor} scene={scene} />
        </Box>
      )}
    </Box>
  );
}
