import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import {setSelectedZoneId} from "../zonesSlice";

import { setSelectedListItemId } from "Features/leftPanel/leftPanelSlice";

export default function ButtonBackZonesInSelectionPanel({editorPdf}) {
  const dispatch = useDispatch();

  // string

  const backS = "New";

  // handlers

  function handleClick() {
    dispatch(setSelectedZoneId(null));
    dispatch(setSelectedListItemId(null));
    editorPdf.annotationsManager.selectAnnotation(null);
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {backS}
    </Button>
  );
}
