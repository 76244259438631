import {
  OnMouseMoveProcessMeasure,
  setStep,
  setMode,
} from "Features/viewer3D/viewer3DSlice";

import {Vector2} from "three";

export default class SegmentDrawer {
  lineAnnot;
  point1;
  point2;
  point1WithRot;
  point2WithRot;
  isDrawingOneByOne;

  constructor({sceneEditor}) {
    this.sceneEditor = sceneEditor;
    this.step = "OFF"; // "POINT_1","POINT_2"...
    this.lineAnnot = null; // segment that will be added eventually

    //this.initSceneProcess();
    //this.initAnnotation(); // we create the annotation here. Side effect if not : document lisnter "click" is triggered twice..
    //this.addListeners();
  }

  // getters

  get annotationColor() {
    return this.sceneEditor.annotationsManager.measurementsPdfManager
      .annotationColor;
  }

  get strokeThickness() {
    const mngr = this.sceneEditor.annotationsManager.measurementsPdfManager;
    if (!mngr.dim1) {
      return 2;
    } else {
      return mngr.dim1 / mngr.mpd;
    }
  }

  // setters

  setLineAnnot(segment) {
    this.lineAnnot = segment;
  }
  setStep(step) {
    this.step = step;
  }

  // start

  startDrawing() {
    this.addListeners();
    this.initAnnotation();

    this.setStep("POINT_1");
    this.sceneEditor.setMode("DRAW_SEGMENT");
    this.sceneEditor.enablePointer();

    this.sceneEditor.dispatch(setMode("DRAW_SEGMENT"));
    this.sceneEditor.dispatch(setStep("POINT_1"));
  }

  // in progress

  handleClick = (e) => {
    console.log("click in line creation", this.step, this.sceneEditor.mode);
    if (this.sceneEditor.mode === "DRAW_SEGMENT") {
      switch (this.step) {
        case "POINT_1": {
          this.point1 = this.sceneEditor.getPointerRawPosition();
          this.point1WithRot = this.sceneEditor.getPointerPosition();
          this.startLine(this.point1);
          this.sceneEditor.dispatch(setStep("POINT_2"));
          this.setStep("POINT_2");
          break;
        }
        case "POINT_2": {
          const point = this.sceneEditor.getPointerRawPosition();
          const pointWithRot = this.sceneEditor.getPointerPosition();
          const pageRotation = this.sceneEditor.getPointerPageRotation();
          this.point2 = this.getTarget(this.point1, point, pageRotation);
          this.point2WithRot = this.getTargetWithRot(
            this.point1WithRot,
            pointWithRot
          );
          this.addLineAnnot();
          if (
            this.sceneEditor.annotationsManager.measurementsPdfManager
              .isDrawingOneByOne
          ) {
            this.stopDrawing();
            this.setStep("OFF");
          } else {
            this.setStep("POINT_1");
            this.initAnnotation();
          }
          break;
        }
      }
    }
  };

  // end

  stopDrawing = () => {
    this.removeListeners();

    this.sceneEditor.disablePointer();
    this.sceneEditor.enablePanTool();
    this.sceneEditor.setMode("DEFAULT");

    this.sceneEditor.dispatch(setMode("DEFAULT"));
    this.sceneEditor.dispatch(setStep("DEFAULT"));
    this.sceneEditor.disablePointer();
    this.sceneEditor.enablePanTool();

    this.sceneEditor.annotationsManager.measurementsPdfManager.setIsDrawing(
      false
    );
  };

  // cancel

  reset = () => {
    this.removeListeners();
    this.deleteLineAnnot();
    this.setStep("OFF");

    this.sceneEditor.setMode("DEFAULT");

    this.sceneEditor.dispatch(setMode("DEFAULT"));
    this.sceneEditor.dispatch(setStep("DEFAULT"));
    this.sceneEditor.disablePointer();
    this.sceneEditor.enablePanTool();

    this.sceneEditor.annotationsManager.measurementsPdfManager.setIsDrawing(
      false
    );
  };

  // helpers - start

  addListeners = () => {
    const {documentViewer} = this.sceneEditor.webViewer.Core;
    documentViewer.addEventListener("click", this.handleClick);
  };

  initAnnotation = () => {
    const {Annotations} = this.sceneEditor.webViewer.Core;
    const lineAnnot = new Annotations.LineAnnotation();

    lineAnnot.StrokeColor = this.annotationColor;
    lineAnnot.StrokeThickness = this.strokeThickness;
    lineAnnot.Opacity = 0.8;
    //lineAnnot.ReadOnly = true; // to prevent selection once the line was drawn
    //this.annotationManager.addAnnotation(lineAnnot); // added in the end Annotation process
    this.setLineAnnot(lineAnnot);
  };

  // helper - draw

  startLine(point) {
    this.point1 = point;
    this.lineAnnot.setStartPoint(point.x, point.y);
    this.lineAnnot.setEndPoint(point.x + 1, point.y + 1);
    this.lineAnnot.PageNumber = point.pageNumber;
  }

  // helpers - in progress

  getTarget(point1, point2, pageRotation) {
    const direction = this.sceneEditor.drawDirection;
    let x = point2.x,
      y = point2.y;
    if (direction === "HORIZONTAL" && !pageRotation) {
      x = point2.x;
      y = point1.y;
    } else if (direction === "HORIZONTAL" && pageRotation) {
      x = point1.x;
      y = point2.y;
    } else if (direction === "VERTICAL" && !pageRotation) {
      x = point1.x;
      y = point2.y;
    } else if (direction === "VERTICAL" && pageRotation) {
      x = point2.x;
      y = point1.y;
    }
    return {x, y};
  }

  getTargetWithRot(point1, point2) {
    const direction = this.sceneEditor.drawDirection;
    let x = point2.x,
      y = point2.y;
    if (direction === "HORIZONTAL") {
      x = point2.x;
      y = point1.y;
    } else if (direction === "VERTICAL") {
      x = point1.x;
      y = point2.y;
    }
    return {x, y};
  }
  // called when moving the mouse

  updateOnMouseMove({
    rawPageCoordinates: point,
    pageNumber,
    pageRotation,
    width,
  }) {
    if (this.step === "POINT_1") {
      if (width) this.lineAnnot.StrokeThickness = width;
    }
    if (this.step === "POINT_2") {
      const {annotationManager} = this.sceneEditor.webViewer.Core;
      const target = this.getTarget(this.point1, point, pageRotation);
      this.lineAnnot.setEndPoint(target.x, target.y);
      annotationManager.redrawAnnotation(this.lineAnnot);
    }
  }

  // helpers - end

  removeListeners = () => {
    const {documentViewer} = this.sceneEditor.webViewer.Core;
    documentViewer.removeEventListener("click", this.handleClick);
  };

  deleteLineAnnot = () => {
    const {documentViewer, annotationManager} = this.sceneEditor.webViewer.Core;
    if (this.lineAnnot) {
      this.lineAnnot.ReadOnly = false;
      console.log("delete26");
      annotationManager.deleteAnnotation(this.lineAnnot);
    }
    this.lineAnnot = null;
  };

  addLineAnnot = () => {
    const annotationManager =
      this.sceneEditor.webViewer.Core.documentViewer.getAnnotationManager();
    if (this.lineAnnot) annotationManager.addAnnotation(this.lineAnnot);
  };
}
