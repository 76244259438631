import {useSelector, useDispatch} from "react-redux";

import {Box, Tooltip, Typography} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import {DataGrid, frFR, GridActionsCellItem} from "@mui/x-data-grid";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import {setSelectedRessourcesInDatagrid} from "../measurementsSlice";
import useMeasurementsByRessourceForDatagrid from "../useMeasurementsByRessourceForDatagrid";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridMeasurementsByRessource({
  mode,
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // strings

  const numString = "Num";
  const nameString = "Article";
  const unitString = "Unité";
  const quantityString = "Qté";
  const focusS = "Focus";

  // data

  const os = useSelector((s) => s.ui.openSections);

  const measurementsByR = useMeasurementsByRessourceForDatagrid(scene, mode);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const zones = useZonesByScene(scene);
  const [measurements] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    true,
    true
  );

  const selectedRessourcesInDatagrid = useSelector(
    (s) => s.measurements.selectedRessourcesInDatagrid
  );
  const group = useSelector((s) => s.measurements.selectedRessourceGroup);
  const noQuantities = useSelector((s) => s.ressources.noQuantities);

  // grid - rows

  const rows = measurementsByR.filter(
    (r) =>
      r.group === group &&
      (noQuantities ? noQuantities : r.isTitle || r.quantity)
  );

  // grid - columns

  const columns = [
    {field: "num", headerName: numString, width: 80},
    {
      field: "name",
      headerName: nameString,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography
            variant="body2"
            sx={{fontWeight: params.row.isTitle ? "bold" : "normal"}}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {field: "unit", headerName: unitString, width: 60},
    {field: "quantity", headerName: quantityString, width: 70},

    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: (params) => {
        if (
          !params.row.isTitle &&
          selectedRessourcesInDatagrid.includes(params.row.id)
        ) {
          return [
            <GridActionsCellItem
              icon={<Focus />}
              onClick={() => handleFocusClick(params.row.id)}
              label={focusS}
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  // selectionModel

  const selectionModel = selectedRessourcesInDatagrid;

  // handlers

  function handleSelectionChange(selection) {
    dispatch(setSelectedRessourcesInDatagrid(selection));
  }

  function handleFocusClick(ressourceId) {
    const measZoneIds = [...new Set(measurements.map((m) => m.zoneId))];
    const measZones = zones.filter((z) => measZoneIds.includes(z.id));
    const imageModelIds = [...new Set(measZones.map((z) => z.imageModelId))];
    const types = elementTypes.filter(
      (type) => type.res?.filter(({resId}) => resId === ressourceId)?.length > 0
    );
    if (types.length > 0) {
      caplaEditor?.editor3d?.sceneEditor.focusElementTypes(
        types.map((t) => t.id)
      );
      caplaEditor?.editor3d?.sceneEditor.showImageModels(imageModelIds);
    }
    // highlight measurements
    const ms = measurements.filter((m) =>
      m.res?.map(({resId}) => resId === ressourceId)
    );
    const ids = ms.map((m) => m.id);
    if (os.fixedViewersBox) {
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.highlightAnnotations(
        ids
      );
    }
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--NoItem": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          if (params.row.style === "title1" ||
            (params.row.isTitle && params.row.style === undefined && params.row.rank === 1)
          )
            return `super-app-theme--Title1`;
          if (params.row.style === "title1" ||
            (params.row.isTitle && params.row.style === undefined && params.row.rank === 2)
          )
            return `super-app-theme--Title2`;
          if (params.row.style === "detail" || params.row.parent && params.row.count > 0)
            return `super-app-theme--Child`;
          if (params.row.count === 0) return `super-app-theme--NoItem`;
        }}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
