import {Box} from "@mui/material";

import useSelection from "Features/selection/useSelection";
import CardZoneSelectionDetail from "Features/zones/components/CardZoneSelectionDetail";

export default function SectionHomeZoneInSelectionPanel({
  caplaEditor,
  scene,
  viewer,
}) {
  const selection = useSelection({scene, editorPdf: caplaEditor?.editorPdf, viewer});
  const zone = selection?.item;

  return (
    <Box sx={{p: 2, width: 1}}>
      <Box sx={{p: 1, bgcolor: "background.default"}}>
        <CardZoneSelectionDetail zone={zone} editor3d={caplaEditor?.editor3d} />
      </Box>
    </Box>
  );
}
