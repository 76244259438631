import {client} from "API/capla360";
import cleanScopeToSync from "./utils/cleanScopeToSync";

export const createScopeService = async ({accessToken, scope}) => {
  const sceneId = scope.sceneId;
  const serializedScope = {
    ...scope,
    scene: sceneId,
  };
  delete serializedScope.sceneId;

  const response = await client.post(
    `scenes/${sceneId}/scopes/`,
    serializedScope,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

export const fetchScopesService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/scopes/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export const updateScopeService = async ({accessToken, scope}) => {
  const sceneId = scope.sceneId;
  const cleanScope = cleanScopeToSync(scope);
  console.log("cleanScope", cleanScope);
  const response = await client.patch(
    `/scenes/${sceneId}/scopes/${scope.id}/`,
    cleanScope,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

export const deleteScopeService = async ({accessToken, scope}) => {
  const scopeId = scope.id;
  const sceneId = scope.sceneId;
  console.log("try to delete typesScope", sceneId, scope);
  await client.delete(`/scenes/${sceneId}/scopes/${scopeId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {scopeId};
};
