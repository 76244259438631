import React from "react";

import {useDispatch} from "react-redux";
import {IconButton} from "@mui/material";
import {Download} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

import {fetchPictures} from "Features/pictures/picturesSlice";

export default function IconButtonDownloadPictures({listingId}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // handlers

  async function handleUploadClick() {
    await dispatch(fetchPictures({listingId, accessToken}));
  }

  return (
    <IconButton size="small" onClick={handleUploadClick}>
      <Download fontSize="small" />
    </IconButton>
  );
}
