import progressSlice from "Features/progress/progressSlice";

import {
  Rule as Progress,
  DisabledByDefault as Disable,
  Note as Issues,
} from "@mui/icons-material";

export default function useMobileModules() {
  // strings

  const disableMeasurementsS = "Désactivation des éléments";
  const disableMeasurementsD =
    "Désactivez les éléments qui ne font pas partie de votre périmètre.";

  const progressS = "Pointage";
  const progressD = "Pointer l'avancement de vos travaux.";

  const issuesS = "Notes";
  const issuesD = "Localiser des notes : travaux sup, réserves, problèmes,...";

  // helpers

  const mods = [
    {
      id: "1",
      code: "DISABLE_MEASUREMENTS",
      name: disableMeasurementsS,
      description: disableMeasurementsD,
      icon: <Disable size="small" color="inherit" />,
    },
    {
      id: "2",
      code: "PROGRESS",
      name: progressS,
      description: progressD,
      icon: <Progress size="small" color="inherit" />,
    },
    {
      id: "3",
      code: "ISSUES",
      name: issuesS,
      description: issuesD,
      icon: <Issues size="small" color="inherit" />,
    },
  ];

  return mods;
}
