import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedInputId, setTempInput} from "../inputsSlice";

// import useAccessToken from "Features/auth/useAccessToken";
import {urlToFile} from "Features/files/utils";
import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";
import {setTempPdfModelId} from "Features/pdf/pdfSlice";

export default function ActionsNewInputInSelectionPanel({editor3d}) {
  const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const createS = "Ajouter";

  // data

  const tempInput = useSelector((s) => s.inputs.tempInput);

  // helpers

  const disabled = !tempInput?.name?.length > 0;

  // handlers

  async function handleCreateClick() {
    console.log("create new input", tempInput);
    const file = await urlToFile(tempInput.fileUrl, tempInput.fileName);

    const type = getModelTypeFromFile(file);
    const model = await editor3d?.loader.loadFile({
      file,
      type,
      options: {name: tempInput.name},
    });

    dispatch(setTempPdfModelId(null));
    dispatch(setTempInput({}));
    dispatch(setSelectedInputId(model?.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
          disabled={disabled}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
