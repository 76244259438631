import {Box, List, ListItemButton, Typography} from "@mui/material";
import {
  CheckBox as Checked,
  CheckBoxOutlineBlank as UnChecked,
} from "@mui/icons-material";

import ListItemArticle from "./ListItemArticle";

export default function SelectorArticleStyle({
  style,
  isAdded,
  onStyleChange,
  onIsAddedChange,
}) {
  // string

  const title = "Choisissez un style d'article";

  // helpers

  const articlesProxy = [
    {id: "title1", name: "Titre 1", style: "title1"},
    {id: "title2", name: "Titre 2", style: "title2"},
    {id: "overview", name: "Ouvrage (synthèse)", style: "overview"},
    {id: "element", name: "Ouvrage", style: "element"},
    {id: "detail", name: "Détail", style: "detail"},
    {id: "subDetail", name: "Sous-détail", style: "subDetail"},
    {id: "section", name: "Section", style: "section"},
    {id: "normal", name: "Normal", style: "normal"},
  ];

  // handlers

  function handleClick(id) {
    if (id === style) {
      onStyleChange("normal");
    } else {
      onStyleChange(id);
    }
  }
  return (
    <Box>
      <Typography sx={{fontSize: 13, color: "text.secondary", mb: 0.5}}>
        {title}
      </Typography>
      <List dense disablePadding>
        {articlesProxy.map((article) => (
          <ListItemButton
            key={article.id}
            sx={{p: 0, m: 0}}
            onClick={() => handleClick(article.id)}
          >
            <ListItemArticle
              article={article}
              showCheck={true}
              selected={article.style === style}
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
