import {useDispatch} from "react-redux";

import {IconButton, Box, Tooltip} from "@mui/material";
import {Architecture as Scale} from "@mui/icons-material";

import {
  setAnnotCreationTool,
  setOpenDrawingDetail,
} from "Features/measurements/measurementsSlice";
// import DialogSetScaleFromMeasurement from "Features/measurements/components/DialogSetScaleFromMeasurement";

export default function ButtonSetScaleFromMeasurement({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const setScaleS = "Mesure";

  // handlers

  function handleClick() {
    const manager = editorPdf?.annotationsManager.measurementsPdfManager;
    dispatch(setAnnotCreationTool("SCALE_SEGMENT"));
    dispatch(setOpenDrawingDetail(true));
    manager?.setMeasurementCreationMode("SCALE_SEGMENT"); // check useMeasurementCreationMode effect
    manager?.triggerDrawSegment();
  }

  return (
    <Box>
      <Tooltip title={setScaleS}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "common.white",
          }}
        >
          <IconButton size="small" color="inherit" onClick={handleClick}>
            <Scale color="inherit" fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      {/* <DialogSetScaleFromMeasurement editor={editor} /> */}
    </Box>
  );
}
