import {Box} from "@mui/material";

import TableGroupedItems from "Components/TableGroupedItems";
// import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
// import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

export default function ContainerElementTypeRessourcesWithQuantities({
  elementType,
  scene,
  viewer
}) {
  // helpers

  const elementTypeResIdRessourceMap = {};
  let ressourceIds = [];
  if (Array.isArray(elementType?.res)) {
    elementType.res.forEach(({resId, mode, func}) => {
      ressourceIds.push(resId);
      elementTypeResIdRessourceMap[resId] = {mode, func};
    });
  }

  const options = {
    disableHeader: true,
    disableExpandGroups: true,
    disableQties: false,
    disableNums: false,
    disableDescriptions: false,
    disableGroupedShowModes: true,
  };

  //const elementTypes = useElementTypesBySceneProxy(scene);
  // const ressources = useRessourcesByScene(scene);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const ressources = useMeasurementsByRessourceForDatagrid(
    scene,
    mode,
    null,
    null,
    elementType.id,
  );

  const headerTitle = "Articles & quantités";

  // TO DO add the qty value to ressources. r.qty = ??
  const allSortedItems = ressources.map((r) => {
    let func = elementTypeResIdRessourceMap[r.id]?.func;
    return {
      ...r,
      groupName: r.group ? r.group : "-?-",
      groupId: r.group ? r.group : "-?-",
      description: func ? `Qté = ${func}` : "A définir",
      qty: r.quantity
    };
  });

  const scopedItemIdsWithCount = ressourceIds.map((id, index) => ({
    id,
    count: index,
  }));
  const showedItemIds = ressourceIds;

  // handlers

  function handleShowItemIdsChange(ids) {
    console.log("Click42", ids);
  }
  return (
    <Box>
      <TableGroupedItems
        headerTitle={headerTitle}
        allSortedItems={allSortedItems}
        scopedItemIdsWithCount={scopedItemIdsWithCount}
        showedItemIds={showedItemIds}
        onShowedItemIdsChange={handleShowItemIdsChange}
        variant="light"
        options={options}
      />
    </Box>
  );
}
