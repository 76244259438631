export default function getArticleTypesCount(types) {
  let count = 0;

  if (!types) return count;

  Object.entries(types).forEach(([groupId, typeMap]) => {
    if (groupId !== "filtersStr" && groupId !== "globalFunc") {
      try {
        count += Object.keys(typeMap).filter(
          (typeId) => typeId !== "filtersStr"
        ).length;
      } catch (e) {
        console.log("error", typeMap, e);
      }
    }
  });

  return count;
}
