import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setOpenMobileDialogScene} from "Features/navigation/navigationSlice";

import {Dialog, Box, Typography, Paper, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import SectionSelectedScene from "./SectionSelectedScene";

export default function DialogMobileScene({viewer, scene}) {
  const dispatch = useDispatch();

  // string

  const openS = "Voir les articles";

  // data

  const open = useSelector((s) => s.navigation.openMobileDialogScene);

  // handlers

  function handleClose() {
    dispatch(setOpenMobileDialogScene(false));
  }

  return (
    <Dialog fullScreen open={open}>
      <Box sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}>
        <Box sx={{flexGrow: 1, overflow: "auto"}}>
          <SectionSelectedScene selectedScene={scene} viewer={viewer} />
        </Box>
        <Box sx={{p: 1}}>
          <Paper
            elevation={12}
            sx={{
              bgcolor: "primary.flash",
              p: 2,
              display: "flex",
              justifyContent: "center",
              color: "common.white",
              fontWeight: "bold",
            }}
            onClick={handleClose}
          >
            <Typography>{openS}</Typography>
          </Paper>
        </Box>
      </Box>
    </Dialog>
  );
}
