import {Box, List} from "@mui/material";

import ListHeader from "./components/ListHeader";
import ListItemClientAndRemoteSceneModel from "./components/ListItemClientAndRemoteSceneModel";

import {useClientAndRemoteSceneModels} from "Features/scenes/useClientAndRemoteSceneModels";

export default function ListContainerClientAndRemoteSceneModels({scene}) {
  // data

  const clientAndRemoteSceneModels = useClientAndRemoteSceneModels(scene);

  return (
    <Box sx={{width: 1}}>
      <ListHeader scene={scene} />
      <List dense disablePadding sx={{width: 1}}>
        {clientAndRemoteSceneModels.map((sceneModel) => (
          <ListItemClientAndRemoteSceneModel
            key={sceneModel.id}
            clientAndRemoteSceneModel={sceneModel}
          />
        ))}
      </List>
    </Box>
  );
}
