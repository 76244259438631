import {nanoid} from "@reduxjs/toolkit";
import React, {useState, useEffect} from "react";

import useUpdateScenePhase from "../hooks/useUpdateScenePhase";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Edit} from "@mui/icons-material";

import FormPhase from "./FormPhase";

export default function ButtonDialogUpdatePhase({scene, phase}) {
  // strings

  const editS = "Edit";

  const cancelS = "Cancel";
  const saveS = "Save";

  // data

  const updateScenePhase = useUpdateScenePhase(scene);

  // state

  const [tempPhase, setTempPhase] = useState(phase);
  useEffect(() => setTempPhase(phase), [phase?.id]);

  const [open, setOpen] = useState(false);

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handlePhaseChange(phase) {
    const updatedPhase = {id: tempPhase.id, ...phase};
    setTempPhase(updatedPhase);
  }

  function handleSave() {
    console.log("tempPhase", tempPhase);
    updateScenePhase(tempPhase);
    setOpen(false);
  }

  function handleCancelClick() {
    setOpen(false);
  }

  return (
    <Box sx={{}}>
      <Button
        startIcon={<Edit />}
        color="inherit"
        size="small"
        onClick={handleOpenDialog}
      >
        {editS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editS}</DialogTitle>
        <DialogContent>
          <FormPhase phase={tempPhase} onChange={handlePhaseChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button onClick={handleSave}>{saveS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
