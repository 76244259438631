import {useEffect, useState} from "react";
import useAccessToken from "Features/auth/useAccessToken";
import {useDispatch} from "react-redux";
import {fetchOrganisation, fetchOrganisations} from "../organisationsSlice";

export default function useFetchOrganisations() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  function getOrganisations() {
    dispatch(fetchOrganisations({accessToken}));
  }

  useEffect(() => {
    if (accessToken) {
      getOrganisations();
    }
  }, [accessToken]);
}
