/*
 *   return measurements to be saved in measurements models
 * {measurementModelId:{categories:[categories],measurements:[measurements], presets:[preset]}}
 */

import {useSelector} from "react-redux";
import useSceneMeasurements from "./useSceneMeasurements";

export default function useSelectedMeasurementsModelsData(scene) {
  const measurements = useSceneMeasurements(scene);
  const categories = useSelector((s) => s.measurements.categories);
  const presets = useSelector((s) => s.measurements.presets);

  const selectedMeasurementsModelId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );

  let ms = measurements
    .filter(
      (m) => m.measurementsModelId === selectedMeasurementsModelId && m.deleted !== true
    )
    .map((measurement) => {
      const m = {...measurement};
      delete m.measurementsModelId;
      delete m.measurementsModel;
      delete m.pdfModelId;
      delete m.elementType;
      delete m.zone;
      delete m.room;
      delete m.material;
      delete m.sector;
      delete m.ressource;
      delete m.phase;
      delete m.version;
      return m;
    });

  // // be sure not to have duplicates with same id
  // ms = [...new Map(ms.map((m) => [m.id, m])).values()];

  const categoryIds = [
    ...new Set(ms.filter((m) => m.categoryId).map((m) => m.categoryId)),
  ];
  const cats = categories.filter((c) => categoryIds.includes(c.id));

  const presetIds = [
    ...new Set(ms.filter((m) => m.presetId).map((m) => m.presetId)),
  ];
  const sets = presets.filter((p) => presetIds.includes(p.id));

  return {
    categories: cats,
    measurements: ms,
    presets: sets,
  };
}
