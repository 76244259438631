const removeFormulaFilter = (func) => {
  return func.replace(/\s?\{\{[^\}]+\}\}/g, "");
};

export default function getFuncObjectFromFunc(func) {
  let formula = "";
  let filter = "";
  if (func) {
    formula = removeFormulaFilter(func);
    if (formula !== func)
      filter = func.replace(formula, "")
        .replace("{{", "").replace("}}", "");
  }
  return {formula, filter};
}