import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Paper,
  Box,
  Typography,
  IconButton,
  InputBase,
  Button,
} from "@mui/material";
import {ExpandMore, ExpandLess, Close} from "@mui/icons-material";

import {setMeasuredLength} from "../measurementsSlice";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

export default function SectionMeasuredLength({editorPdf}) {
  const dispatch = useDispatch();

  // string

  const inconsistentS = "Pas cohérent ?";
  const targetS = "Quelle devrait-être la longueur mesurée (m) ?";

  const updateS = "Changer l'échelle";

  // data

  const measuredLength = useSelector((s) => s.measurements.measuredLength);

  const selectedPdfModel = useSelectedPdfModel();

  // state
  const [targetLength, setTargetLength] = useState("");
  const [expand, setExpand] = useState(false);

  // helper

  const measuredLengthS = measuredLength?.length
    ? measuredLength.length.toFixed(3)
    : "-";

  const lengthLabel = measuredLengthS + " m";

  const measuredAngleS = measuredLength?.angleDeg
    ? measuredLength.angleDeg.toFixed(1) + "°"
    : "0";

  // helper - new scale

  const targetL = parseFloat(targetLength);
  const ratio = targetL && targetL / measuredLength.length;
  const newScale = measuredLength.scale && measuredLength.scale * ratio;

  const newScaleS = `1 : ${newScale.toFixed(0)}`;
  console.log("newScale", newScale);

  // handlers

  function toggleExpand() {
    setExpand((expand) => !expand);
  }

  function handleTargetLengthChange(e) {
    let length = e.target.value;
    length = length.replace(",", ".");
    setTargetLength(length);
  }

  function handleClose() {
    dispatch(setMeasuredLength({length: null}));

    // // added to stop drawing.
    // dispatch(setAnnotCreationTool(null));
    // editorPdf.annotationsManager.measurementsPdfManager.setIsDrawing(
    //   false
    // );
    // editorPdf.sceneEditor.stopProcess();
  }

  function handleUpdateScale() {
    const targetL = parseFloat(targetLength);
    const ratio = targetL && targetL / measuredLength.length;
    const newScale = measuredLength.scale && measuredLength.scale * ratio;

    const updatedModel = {
      id: selectedPdfModel.id,
      pdfScale: newScale,
    };
    dispatch(updateModel({updatedModel}));
    editorPdf?.annotationsManager.setScale(newScale);
    editorPdf?.setToolbarGroupMeasureScale(newScale);
    dispatch(setMeasuredLength({length: null}));
  }

  return (
    <Paper elevation={12} sx={{position: "relative", width: 300}}>
      <IconButton
        sx={{position: "absolute", top: 0, right: 0}}
        size="small"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">{lengthLabel}</Typography>
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {measuredAngleS}
        </Typography>
      </Box>

      {expand ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "top",
            justifyContent: "space-between",
            pl: 2,
          }}
        >
          <Typography variant="body2">{targetS}</Typography>
          <IconButton size="small" onClick={toggleExpand} sx={{ml: 1}}>
            <ExpandLess fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            color: "text.secondary",
            display: "flex",
            width: 1,
            justifyContent: "end",
          }}
        >
          <Button
            color="inherit"
            sx={{fontSize: 13}}
            onClick={toggleExpand}
            endIcon={<ExpandMore />}
          >
            {inconsistentS}
          </Button>
        </Box>
      )}

      {expand && (
        <Box sx={{p: 2, pt: 1}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "top",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <InputBase
                value={targetLength}
                onChange={handleTargetLengthChange}
                sx={{
                  width: 100,
                  fontSize: (theme) => theme.typography.body2.fontSize,
                  bgcolor: "background.default",
                  height: "24px",
                  mr: 0.5,
                  px: 1,
                }}
              />
              {!isNaN(newScale) && (
                <Typography sx={{fontSize: 12, pl: 1}} color="text.secondary">
                  {newScaleS}
                </Typography>
              )}
            </Box>
            <Box>
              <Button
                variant="oulined"
                size="small"
                onClick={handleUpdateScale}
              >
                {updateS}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
}
