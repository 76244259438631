export default function getTwoLevelsMapFromItems(
  items,
  field1,
  field2,
  fieldLabel
) {
  const map = {};

  items.forEach((item) => {
    const key1 = item[field1];
    const key2 = item[field2];
    if (!map[key1]) {
      map[key1] = {[key2]: [item[fieldLabel]]};
    } else {
      if (!map[key1][key2]) {
        map[key1][key2] = [item[fieldLabel]];
      } else {
        map[key1][key2] = [...new Set([...map[key1][key2], item[fieldLabel]])];
      }
    }
  });

  return map;
}
