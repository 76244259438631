import {Box} from "@mui/material";

import ButtonApplyFilters from "./ButtonApplyFilters";

import useSceneModule from "Features/navigation/useSceneModule";
import ToolbarProgressBottomRightViewer3D from "Features/progress/components/ToolbarProgressBottomRightViewer3D";

export default function ToolbarBottomRightViewer3D({caplaEditor}) {
  // data

  const sceneModule = useSceneModule();

  if (sceneModule === "PROGRESS")
    return (
      <Box>
        <Box sx={{mr: 1, ml: 2}}>
          <ButtonApplyFilters />
        </Box>
        <ToolbarProgressBottomRightViewer3D editor3d={caplaEditor?.editor3d} />
      </Box>
    );
  else return <ButtonApplyFilters />;
}
