import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
// import {addMeasurements, setModifiedMeasurements} from "../measurementsSlice";
import useMeasurementsModels from "../useMeasurementsModels";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import {Refresh, Close} from "@mui/icons-material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";
import SelectOptions from "Components/SelectOptions";
import ToggleNameFromRawType from "./ToggleNameFromRawType";
import DataGridRawMeasAndElementTypes from "./DataGridRawMeasAndElementTypes";
import DataGridRawTypesAndElementTypes from "Features/elementTypes/components/DataGridRawTypesAndElementTypes";
import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";
import PdfWebViewerInstance2 from "Features/pdf/components/PdfWebViewerInstance2";
import ToggleExtractMode from "./ToggleExtractMode";

import {nanoid} from "nanoid";
import updateElementTypesGroupWithManyUpdatedTypes from "Features/elementTypes/utils/updateElementTypesGroupWithManyUpdatedTypes";
import useUpdateElementTypesGroup from "Features/elementTypes/hooks/useUpdateElementTypesGroup";

import {setSnackbarMessage} from "Features/ui/uiSlice";
import {rawTypeToType} from "../utils/rawTypeToType";
import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";

import cleanElementType from "Features/elementTypes/utils/cleanElementType";

export default function DialogExtractMeasurements({
  caplaEditor,
  pdfModel,
  open,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();

  // const

  const heightDefault = 0.024;

  // strings

  const title = "Extraire les éléments des annotations PDF";

  const filterS = "Filtrer par auteur";
  const description1 = "Enregistrer les éléments dans un calque";
  const targetS = "Enregistrer";

  const authorS = "Auteur";
  const measurementsS = "Eléments";
  const subjectsS = "Sujets";

  const extractS = "Extraire";
  const cancelS = "Annuler";

  const groupS = "Liste d'ouvrages / éléments";

  // data

  const models = useMeasurementsModels(scene?.clientId);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const elementTypesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(
    scene,
    {sortByName: true}
  );

  const target = useSelectedMeasurementsModel();

  // data - handlers

  const updateTypesGroup = useUpdateElementTypesGroup();

  // state

  const [fileUrl, setFileUrl] = useState(null);

  const [nameFrom, setNameFrom] = useState("subject");
  const [selection, setSelection] = useState([]);

  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState("");

  const [mode, setMode] = useState("ELEMENT_TYPES"); // "ELEMENT_TYPES" / "MEASUREMENTS"

  const [measurementsBySubjectOverview, setMeasurementsBySubjectOverview] =
    useState([]);

  const [rawMeasurements, setRawMeasurements] = useState([]);

  const [subjectElementTypeMap, setSubjectElementTypeMap] = useState({}); // {subject:elementType}

  const [selectedTypesGroup, setSelectedTypesGroup] = useState(null);

  // helpers

  const mnger =
    caplaEditor?.editorPdfInstance2?.annotationsManager?.measurementsPdfManager;

  // helpers - options

  const options = usePackagesByScene(scene, {filterByScope: true});

  // helpers - items

  console.log("subjectElementTypeMap", subjectElementTypeMap);
  const items = measurementsBySubjectOverview.map((rawType) => {
    let elementType = subjectElementTypeMap[rawType.rawName];
    if (!elementType)
      elementType = rawTypeToType({
        rawDrawingShape: rawType.rawDrawingShape,
        rawColor: rawType.rawColor,
        rawName: rawType.rawName,
        rawElementName: rawType.rawElementName,
      });
    if (mode === "MEASUREMENTS") {
      if (!elementType?.height) elementType.height = heightDefault;
    }
    return {
      id: rawType.rawId,
      rawType,
      elementType,
    };
  });

  console.log("items", items, subjectElementTypeMap);

  // helpers - selectedMeas

  const selectedItems = items.filter(({id}) => selection.includes(id));
  const selectedRawNames = selectedItems.map((item) => item.rawType.rawName);
  const selectedMeas = rawMeasurements.filter((meas) => {
    if (nameFrom === "subject") {
      return selectedRawNames.includes(meas.subject);
    } else {
      return selectedRawNames.includes(meas.author);
    }
  });

  // helpers - actionLabel

  const updateTypesLabel = `Rajouter les ouvrages sélectionnés (x${selection.length})`;
  const addMeasLabel = `Rajouter les repérages sélectionné (x${selectedMeas.length})`;

  const actionLabel =
    mode === "ELEMENT_TYPES" ? updateTypesLabel : addMeasLabel;

  // helpers - canExtract

  const canExtract = true;

  // helpers - count

  let count = rawMeasurements?.filter(
    (m) => !author || m.author === author
  )?.length;
  if (!count) count = "...";

  const annotationsCount = rawMeasurements?.length ?? "...";

  // helper - annotationsTitle

  const annotationsTitle = `${annotationsCount} annotations classées par`;

  // helpers - title 2

  let count2 = measurementsBySubjectOverview?.length;
  if (!count2) count2 = "...";
  const title2 = `${count2} ${subjectsS}`;

  // handlers

  function handleFilesChange(files) {
    const url = URL.createObjectURL(files[0]);
    setFileUrl(url);
    caplaEditor.editorPdfInstance2.load({url});
  }
  // handlers

  function handleClose() {
    onClose();
  }

  function handleTypesGroupChange(group) {
    setSelectedTypesGroup(group);
  }

  // handlers - subjectCode

  function handleRawTypeElementTypeChange(item) {
    const newMap = {...subjectElementTypeMap};
    newMap[item.rawType.rawName] = cleanElementType(item.elementType);
    setSubjectElementTypeMap(newMap);
  }

  function handleMeasElementTypeChange(item) {
    console.log("new item 12", item);
    const newMap = {...subjectElementTypeMap};
    newMap[item.rawType.rawName] = cleanElementType(item.elementType);
    setSubjectElementTypeMap(newMap);
  }

  // handlers - extract types

  async function handleExtractElementTypes() {
    const selectedItems = items.filter((item) => selection.includes(item.id));
    const types = [];
    selectedItems.forEach(({rawType, elementType}) => {
      if (!elementType?.id) {
        const newElementType = {
          ...elementType,
          subject: rawType.rawName,
          id: nanoid(),
        };
        types.push(newElementType);
      } else {
        types.push(elementType);
      }
    });

    const gr = elementTypesGroups.find(
      (group) => group.id === selectedTypesGroup?.id
    ); // important : the group can change after its selection
    const updatedGroup = updateElementTypesGroupWithManyUpdatedTypes(types, gr);

    await updateTypesGroup(updatedGroup);
    const message = "Liste d'ouvrages mis à jour";
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt})); // is hidden
  }

  // handlers - extract

  async function handleExtract() {
    const mnger =
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;

    const measurements = [];

    for (let rawMeasurement of selectedMeas) {
      const rawName =
        nameFrom === "author" ? rawMeasurement.author : rawMeasurement.subject;
      const elementType = subjectElementTypeMap[rawName];

      if (elementType) {
        const measurement = await mnger?.annotationToMeasurementFromElementType(
          rawMeasurement.annotation,
          elementType
        );
        measurements.push(measurement);
      }
    }

    // debug

    console.log("extract measurements", measurements);

    // new meas

    if (target) {
      const newMeas = measurements.map((measurement) => ({
        ...measurement,
        measurementsModelId: target.id,
        pdfModelId: target.fromModel.modelId,
      }));
      caplaEditor?.measDataManager.addMeasurements(newMeas);
      const message = `${measurements.length} repérages ajoutés`;
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt})); // is hidden
    }

    // if (target) {
    //   const updatedModel = {
    //     ...target,
    //     measurementsData: {...target.measurementsData, measurements},
    //   };
    //   dispatch(updateModel({updatedModel}));
    //   caplaEditor?.measDataManager.createOrUpdateModelInManager(updatedModel);
    // // state

    // const newMs = measurements.map((m) => ({
    //   ...m,
    //   measurementsModelId: target.id,
    //   pdfModelId: target.fromModel.modelId,
    // }));
    // dispatch(addMeasurements(newMs));
    // dispatch(setModifiedMeasurements([]));
    //}

    onClose();
  }

  // handlers - author

  function handleAuthorChange(author) {
    setAuthor(author);
  }

  // handlers - refresh mapping

  function handleRefreshMapping() {
    const _subjectElementTypeMap = {};
    items.forEach(({rawType}) => {
      const elementType = elementTypes.find(
        (t) => t.subject === rawType.rawName
      );
      if (elementType) _subjectElementTypeMap[rawType.rawName] = elementType;
    });
    setSubjectElementTypeMap(_subjectElementTypeMap);
  }

  // effects

  useEffect(() => {
    if (mnger && fileUrl) {
      mnger.extractor.extractRawMeasurements();
      setRawMeasurements(mnger.extractor.rawMeasurements);

      const authors = mnger.extractor.getRawMeasurementsByAuthorOverview();
      setAuthors(authors.map((a) => a.name));

      const mBySubject =
        mnger.extractor.getAuthorRawMeasurementsBySubjectOverview(
          author,
          nameFrom
        );
      setMeasurementsBySubjectOverview(mBySubject);
    }
  }, [fileUrl, mnger, target?.id, author, nameFrom]);

  return (
    <>
      {open && (
        <Box
          sx={{
            position: "fixed",
            bgcolor: "common.white",
            zIndex: 100,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyItems: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Box sx={{width: "200px", height: "100px", mr: 2}}>
                <PdfWebViewerInstance2
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              </Box>
              <Typography variant="h6">{title}</Typography>
            </Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          {!fileUrl && (
            <Box sx={{width: 1, height: 1, p: 2}}>
              <ContainerFilesSelector
                onFilesChange={handleFilesChange}
                accept=".pdf"
                multiple={false}
              />
            </Box>
          )}
          {fileUrl && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                width: 1,
                justifyItems: "stretch",
                minHeight: 0,
              }}
            >
              <Box
                sx={{
                  mb: 1,
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "64px",
                  maxHeight: "64px",
                  minHeight: "64px",
                  bgcolor: "background.default",
                }}
              >
                <Box sx={{display: "flex", alignItems: "center", pl: 2}}>
                  <Typography variant="body2" sx={{fontWeight: "bold"}}>
                    {annotationsTitle}
                  </Typography>
                  <Box sx={{ml: 1}}>
                    <ToggleNameFromRawType
                      nameFrom={nameFrom}
                      onChange={setNameFrom}
                    />
                  </Box>
                  {nameFrom === "subject" && (
                    <Box
                      sx={{
                        ml: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2" sx={{mr: 1}}>
                        {filterS}
                      </Typography>
                      <SelectOptions
                        options={authors}
                        value={author}
                        onChange={handleAuthorChange}
                        label={authorS}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{width: 1, p: 2, display: "flex", alignItems: "center"}}>
                <Box sx={{display: "flex", mr: 2}}>
                  <IconButton
                    size="small"
                    onClick={handleRefreshMapping}
                    sx={{mr: 1}}
                  >
                    <Refresh fontSize="small" />
                  </IconButton>
                  <ToggleExtractMode mode={mode} onChange={setMode} />
                </Box>
                {mode === "ELEMENT_TYPES" && (
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{fontSize: 13, mr: 2}}>
                      {actionLabel}
                    </Typography>
                    <Box sx={{display: "flex", width: 200}}>
                      <SelectorAutocomplete
                        options={elementTypesGroups}
                        value={selectedTypesGroup}
                        onChange={handleTypesGroupChange}
                        name={groupS}
                      />
                    </Box>
                    <Button sx={{ml: 1}} onClick={handleExtractElementTypes}>
                      {extractS}
                    </Button>
                  </Box>
                )}
                {mode === "MEASUREMENTS" && (
                  <Box sx={{display: "flex", alignItems: "center"}}>
                    <Typography sx={{fontSize: 13, mr: 2}}>
                      {actionLabel}
                    </Typography>

                    <Button sx={{ml: 1}} onClick={handleExtract}>
                      {extractS}
                    </Button>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  //overflowY: "auto",
                  width: 1,
                  minHeight: 0,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {mode === "MEASUREMENTS" && (
                  <DataGridRawMeasAndElementTypes
                    scene={scene}
                    items={items}
                    selection={selection}
                    onSelectionChange={setSelection}
                    onItemChange={handleMeasElementTypeChange}
                  />
                )}

                {mode === "ELEMENT_TYPES" && (
                  <DataGridRawTypesAndElementTypes
                    items={items}
                    scene={scene}
                    selection={selection}
                    onSelectionChange={setSelection}
                    onItemChange={handleRawTypeElementTypeChange}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
