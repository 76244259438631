import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import CardModelName from "./components/CardModelName";
import ListStoreys from "./components/ListStoreys";

export default function ToolQuickFilterByStorey({editor3d, scene}) {
  // data

  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);
  const models = useSelector((s) => s.viewer3D.models);
  const ifcModels = models.filter(
    (m) => m.sceneClientId === scene?.clientId && m.type === "IFC"
  );

  // helpers

  const clickedModel = models.find((m) => m.id === clickedObject?.modelId);
  const firstIfcModel = ifcModels[0];

  // state

  const [model, setModel] = useState(firstIfcModel);
  useEffect(() => setModel(firstIfcModel), [ifcModels.length]);

  const [pin, setPin] = useState(false);

  // handlers

  function handlePinChange(pin) {
    setPin(pin);
  }

  // effect

  useEffect(() => {
    if (clickedModel?.id && !pin) {
      setModel(clickedModel);
    }
  }, [clickedModel?.id, pin]);

  return (
    <Box>
      <CardModelName model={model} pin={pin} onPinChange={handlePinChange} />
      {model && <ListStoreys model={model} editor3d={editor3d} />}
    </Box>
  );
}
