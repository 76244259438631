import theme from "Styles/theme";

export default class Marker {
  constructor() {
    this.size = 24;
    this.element = this.createDivElement();
  }

  createDivElement() {
    const el = document.createElement("div");
    el.style.position = "absolute";
    el.style.width = `${this.size * 1}px`;
    el.style.height = `${this.size * 1}px`;
    el.style.transform = "translate(-50%,-50%)";
    el.style.borderRadius = "50%";
    el.style.border = `1px solid ${theme.palette.primary.flash}`;
    el.style.zIndex = 35;
    return el;
  }

  setPosition({pageCoordinates, zoom}) {
    this.element.style.left = `${pageCoordinates.x * zoom}px`;
    this.element.style.top = `${pageCoordinates.y * zoom}px`;
    this.pageCoordinates = pageCoordinates;
  }
  getPosition() {
    return this.pageCoordinates;
  }

  update({zoom}) {
    if (this.pageCoordinates) {
      this.element.style.left = `${this.pageCoordinates.x * zoom}px`;
      this.element.style.top = `${this.pageCoordinates.y * zoom}px`;
    }
  }

  show() {
    this.element.style.visibility = "visible";
  }

  hide() {
    this.element.style.visibility = "hidden";
  }

  enable() {
    this.show();
  }

  disable() {
    this.hide();
  }
}
