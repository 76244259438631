export default function getElementTypesCountFromGroups(groups) {
  let count = 0;
  if (!groups) return 0;
  groups.forEach((group) => {
    if (Array.isArray(group.elementTypes)) {
      count = count + group.elementTypes.length;
    }
  });
  return count;
}
