import {nanoid} from "@reduxjs/toolkit";

export function getViewpoints(note) {
  try {
    const content = JSON.parse(note.data);
    const viewpoints = content.filter((node) => node.type === "viewpoint");
    console.log(viewpoints);
    const cameras = viewpoints.map((v) => ({
      ...v.camera,
      context: v.context,
      image: v.image,
    }));
    return cameras;
  } catch (e) {
    console.log(e);
  }
}

export function getCamerasAndContexts(note) {
  try {
    const content = JSON.parse(note.data);
    const viewpoints = content.filter((node) => node.type === "viewpoint");
    const cameras = viewpoints.map((v) => ({
      camera: v.camera,
      context: v.context,
      image: v.image,
    }));
    return cameras;
  } catch (e) {
    console.log(e);
  }
}

export function createNoteFromTitleAndScreenshot(title, screenshot) {
  const id = nanoid();
  const image = screenshot.url;
  const context = screenshot.context;
  const camera = screenshot.camera;

  const line = {type: "paragraph", children: [{text: ""}]};
  const viewpoint = {
    camera,
    context,
    id,
    image,
    loading: false,
    type: "viewpoint",
    children: [{text: ""}],
  };
  const content = [line, viewpoint, line];

  return {title, data: JSON.stringify(content)};
}

export const computeStarterFromBlocks = (blocks) => {
  if (blocks) {
    const texts = blocks.filter((block) => block.type === "text");
    const actions = blocks.filter((block) => block.type === "action");
    const imageCaptions = blocks.filter(
      (block) => block.type === "image" && block.data.caption
    );
    if (blocks.length === 0) {
      return "";
    }
    if (texts.length > 0) {
      return texts[0].data.text;
    }
    if (actions.length > 0) {
      return actions[0].data.description;
    }
    if (imageCaptions.length > 0) {
      return imageCaptions[0].data.caption;
    }
  }
};
