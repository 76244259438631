import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setOpenDialogFsCreateElementType} from "Features/elementTypor/elementTyporSlice";

import {
  Paper,
  Box,
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import DialogCreateEntity from "./DialogCreateEntity";

import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
// import useSceneModuleKvtBasicMap from "Features/buildbox/hooks/useSceneModuleKvtBasicMap";
import DialogCreateZone from "Features/zones/components/DialogCreateZone";

export default function ButtonAddEntity({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // string

  const description = "Création rapide";

  // data

  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  const sceneModule = useSceneModule();
  const os = useSelector((s) => s.ui.openSections);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDialog, setOpenDialog] = useState(false);
  const [entityCode, setEntityCode] = useState();

  // helper - entities

  const entityCodes = [
    "ELEMENT_TYPES",
    "DIVIDER",
    "ZONES",
    "DIVIDER",
    "SECTORS",
    "ROOMS",
  ];

  const hideMap = {
    ZONES: !os.fixedViewersBox,
  };

  const entities = entityCodes.map((code, index) => {
    return code === "DIVIDER"
      ? {code: code + index, type: "DIVIDER"}
      : {
          code,
          icon: iconMap[code],
          name: nameMap[code],
          hide: hideMap[code],
        };
  });

  // helper - style

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers - menu item

  function handleEntityClick(entity) {
    setEntityCode(entity.code);
    setAnchorEl(null);
    if (entity.code === "ZONES") {
      caplaEditor?.editorPdf?.annotationsManager?.setCreatingZone(true);
      caplaEditor?.editorPdf.triggerCreateRectangle();
    } else if (entity.code === "ELEMENT_TYPES") {
      caplaEditor?.editorPdf?.annotationsManager?.setCreatingType(true);
      caplaEditor?.editorPdf?.annotationsManager?.setCreatingZone(false);
      caplaEditor?.editorPdf.triggerCreateRectangle();
      //dispatch(setOpenDialogFsCreateElementType(true));
    } else {
      setOpenDialog(true);
    }
  }

  // handlers - menu

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function handleOpenMenu(e) {
    setAnchorEl(e.currentTarget);
  }

  // handlers - create dialog

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  return (
    <Box>
      <Paper
        elevation={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background,
          color: "common.white",
          p: 0.5,
        }}
      >
        <IconButton
          onClick={handleOpenMenu}
          color="inherit"
          sx={{height: 30, width: 30}}
        >
          <Add fontSize="small" />
        </IconButton>
      </Paper>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        sx={{p: 0}}
      >
        <Box sx={{px: 1, pb: 1}}>
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {description}
          </Typography>
        </Box>
        <MenuList dense disablePadding>
          {entities.map((entity) => {
            if (entity.type === "DIVIDER") {
              return <Divider key={entity.code} />;
            } else if (!entity.hide) {
              return (
                <MenuItem
                  onClick={() => handleEntityClick(entity)}
                  color="primary"
                  size="small"
                  key={entity.code}
                >
                  <ListItemIcon>{entity.icon}</ListItemIcon>
                  <ListItemText>{entity.name}</ListItemText>
                </MenuItem>
              );
            }
          })}
        </MenuList>
      </Menu>

      <DialogCreateEntity
        open={openDialog}
        onClose={handleCloseDialog}
        sceneModule={entityCode}
        scene={scene}
      />
      {sceneModule !== "ZONES" && (
        <DialogCreateZone scene={scene} caplaEditor={caplaEditor} />
      )}
    </Box>
  );
}
