import {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Plane, Vector3} from "three";

import {
  Box,
  Typography,
  LinearProgress,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Button,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Autocomplete,
} from "@mui/material";
import {
  Close,
  MoreHoriz as Point2Point,
  BorderStyle as Point2Ortho,
  VerticalAlignTop as Point2Plane,
  Height as Point2Height,
  Adjust as Point2Marker,
} from "@mui/icons-material";

// import ActionPopper from "./ActionPopper";
import ContainerPosition from "./ContainerPosition";

// import Vector from "../js/Vector";

import DisplayBox from "Components/DisplayBox";

import {
  setPositioningObject,
  setMode,
  setStep,
  setProcessMoveOnePoint,
  setSelectedModelId,
  setSelectedPdfModelId,
} from "Features/viewer3D/viewer3DSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import useTranslation from "Features/translations/useTranslation";
import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";

export default function ProcessMoveOnePoint({caplaEditor, onClose, scene}) {
  const {t} = useTranslation("viewer3D");
  const dispatch = useDispatch();

  // strings

  const title = t("viewer3D:positionTool.moveOnePoint.title");
  const step1Name = t("viewer3D:positionTool.moveOnePoint.step1.name");
  const step1Description = t(
    "viewer3D:positionTool.moveOnePoint.step1.description"
  );
  const step2Name = t("viewer3D:positionTool.moveOnePoint.step2.name");
  const step2Description = t(
    "viewer3D:positionTool.moveOnePoint.step2.description"
  );
  const step3Name = "Translate";
  const step2ModePointToPointTitle = t(
    "viewer3D:positionTool.moveOnePoint.step2.modePointToPoint.title"
  );
  const step2ModePointToPointDescription = t(
    "viewer3D:positionTool.moveOnePoint.step2.modePointToPoint.description"
  );
  const step2ModePointToPlaneTitle = t(
    "viewer3D:positionTool.moveOnePoint.step2.modePointToPlane.title"
  );
  const step2ModePointToPlaneDescription = t(
    "viewer3D:positionTool.moveOnePoint.step2.modePointToPlane.description"
  );
  const step2Variant = t("viewer3D:positionTool.moveOnePoint.step2.variant");
  const step2VariantButton = t(
    "viewer3D:positionTool.moveOnePoint.step2.variantButton"
  );
  const markerNameString = t(
    "viewer3D:config.processConfigTwoMarkers.action.markerName"
  );
  const toMoveS = "Modèle à déplacer : ";

  // refs

  const clickableRef = useRef(true); // use to prevent click event when close is clicked.

  // steps

  const steps = {
    ORIGIN: {name: step1Name},
    TARGET: {name: step2Name},
    MOVE: {name: step3Name},
  };

  // virtual element for popper

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef();

  // data

  // const mode = useSelector((state) => state.viewer3D.mode);
  const step = useSelector((state) => state.viewer3D.step);
  // const subStep = useSelector((state) => state.viewer3D.subStep);

  const markers = useMarkersByScene(scene?.clientId);
  const models = useSelector((s) => s.viewer3D.models);

  const process = useSelector((state) => state.viewer3D.processMoveOnePoint); // the process is updated when clicking on one entity.
  const {originN, originP, targetN, targetP, entityID, entityType, modelId} =
    process;
  //const movedEntity = caplaEditor.editor3d?.getEntityById(entityType, entityID);
  const movedEntity = caplaEditor.editor3d?.getEntity(modelId);
  const movedModel = models.find((m) => m.id === modelId);
  const selectionName = movedModel?.name;

  let oldModelId;
  let oldPdfModelId;
  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  console.log("movedEntity", movedEntity);
  console.log("movedModel", movedModel);
  //const partId = parsedObjectEntity?.partId;
  const partId = undefined;

  const activeStep = Object.keys(steps).indexOf(step);

  // local data

  const [translation, setTranslation] = useState({x: 0, y: 0, z: 0});
  const [translationMode, setTranslationMode] = useState("POINT_2_PLANE"); // "POINT_2_POINT", "POINT_2_ORTHO", "POINT_2_HEIGHT", "POINT_2_MARKER"
  const [distance, setDistance] = useState(""); // used by the POINT_2_ORTHO option.
  const [height, setHeight] = useState("");
  const [_, setMarker] = useState();

  console.log("translation", translation);

  // helpers

  function killProcess() {
    dispatch(setPositioningObject({editor: false}));
    dispatch(setMode("DEFAULT"));
    dispatch(setStep("DEFAULT"));
    caplaEditor.editor3d?.switchToMode("PICKING");
    onClose();
  }

  function endProcess() {
    dispatch(setMode("DEFAULT"));
    dispatch(setStep("DEFAULT"));
    dispatch(setPositioningObject({editor: false}));
    caplaEditor.editor3d?.switchToMode("PICKING");
    caplaEditor.editor3d?.sceneEditor.hideMarkers();
    onClose();
  }

  function translate(t) {
    if (movedEntity.type === "IMAGE_MODEL") {
      const oldP = movedEntity.object.position;
      const newP = {x: oldP.x + t.x, y: oldP.y + t.y, z: oldP.z + t.z};
      const updatedModel = {id: modelId, position: newP};
      caplaEditor.editor3d?.loader.updateImageModel({updatedModel});

      // update pdf
      const fromModel = movedModel.fromModel;
      const zoneId = fromModel?.zoneId;
      // const pdfModelId = fromModel?.pdfModelId;
      caplaEditor.editorPdf?.annotationsManager.updateAnnotationEntityPosition({
        annotationId: zoneId,
        position: newP,
        updateImageModel: false,
      });
    } else if (movedEntity?.translate) {
      if (partId) {
        if (t) movedEntity.translatePart(partId, t.x, t.y, t.z);
      } else {
        if (t) movedEntity.translate(t.x, t.y, t.z);
      }
    }
    endProcess();
  }

  function computeTranslation(originP, originN, targetP, targetN, mode) {
    switch (mode) {
      case "POINT_2_POINT": {
        return {
          x: targetP.x - originP.x,
          y: targetP.y - originP.y,
          z: targetP.z - originP.z,
        };
      }
      case "POINT_2_PLANE": {
        const target = new Vector3();
        const originV = new Vector3(originP.x, originP.y, originP.z);
        const point = new Vector3(targetP.x, targetP.y, targetP.z);
        const plane = new Plane(
          new Vector3(targetN.x, targetN.y, targetN.z).normalize()
        );
        const distance = plane.distanceToPoint(point);
        plane.constant = -distance;
        plane.projectPoint(originV, target);
        return {
          x: target.x - originP.x,
          y: target.y - originP.y,
          z: target.z - originP.z,
        };
      }
      case "POINT_2_ORTHO": {
        const point = new Vector3(targetP.x, targetP.y, targetP.z);
        const originV = new Vector3(originP.x, originP.y, originP.z);
        const normalV = new Vector3(originN.x, originN.y, originN.z);
        const distance = point.sub(originV).dot(normalV);
        normalV.multiplyScalar(distance);
        return {x: normalV.x, y: normalV.y, z: normalV.z};
      }
      case "POINT_2_HEIGHT": {
        return {x: 0, y: targetP.y, z: 0};
      }
    }
  }

  // handlers

  function handleProcessChange(changes) {
    const newProcess = {...process, ...changes};
    dispatch(setProcessMoveOnePoint(newProcess));
  }
  function handleTranslationChange(translation) {
    setTranslation(translation);
  }
  function handleDistanceChange(e) {
    const d = e.target.value;
    setDistance(d);
    const normalV = new Vector3(originN.x, originN.y, originN.z).normalize();
    normalV.multiplyScalar(-d);
    setTranslation({x: normalV.x, y: normalV.y, z: normalV.z});
  }
  function handleHeightChange(e) {
    let h = e.target.value;
    h = h.replace(",", ".");
    setHeight(h);
    const distanceH = h.length > 0 ? parseFloat(h) - originP.y : -originP.y;
    setTranslation({x: 0, y: distanceH, z: 0});
  }
  function handleMarkerChange(e, v) {
    setMarker(v);
    const originV = new Vector3(originP.x, originP.y, originP.z);
    const markerV = new Vector3(v.x, v.y, v.z);
    markerV.sub(originV);
    setTranslation({x: markerV.x, y: markerV.y, z: markerV.z});
  }
  function handleTranslateClick() {
    translate(translation);
  }
  const handleOriginClose = () => {
    dispatch(setStep("ORIGIN"));
    handleProcessChange({
      originN: null,
      originP: null,
      entityID: null,
      entityType: null,
    });
    console.log("delete origin");
    clickableRef.current = false;
    //caplaEditor.editor3d?.sceneEditor.disableMarkerTemp1();
  };
  const handleTargetClose = () => {
    console.log("delete target");
    dispatch(setStep("TARGET"));
    clickableRef.current = false;
    handleProcessChange({targetN: null, targetP: null});
    //caplaEditor.editor3d?.sceneEditor.disableMarkerTemp1();
  };

  // handler - click

  async function handleEditorClick(props) {
    console.log("handleEditorClick");

    if (!caplaEditor.editor3d?.dragging) {
      try {
        const {
          sceneP,
          normal,
          // screenP,
          modelEntity,
          // pickedEntity,
          // pickedFaceNormal,
          // facePoints,
        } = props;

        // virtualElementRef.current.getBoundingClientRect = generateBBCR(
        //   screenP.x,
        //   screenP.y
        // );

        if (step === "ORIGIN" && clickableRef.current) {
          console.log("debug ORIGIN", modelEntity?.model);
          oldModelId = selectedModelId;
          oldPdfModelId = selectedPdfModelId;
          dispatch(setSelectedModelId(modelEntity?.model?.fromModel?.modelId));
          dispatch(
            setSelectedPdfModelId(modelEntity?.model?.fromModel?.modelId)
          );
          dispatch(setSelectedZoneId(modelEntity?.model?.fromModel?.zoneId));
          caplaEditor.editorPdf.annotationsManager.selectAnnotation(
            modelEntity?.model?.fromModel?.zoneId
          );

          caplaEditor.editor3d.sceneEditor.showMarkerTemp();
          caplaEditor.editor3d.sceneEditor.moveMarkerTempTo(sceneP, normal);
          caplaEditor.editor3d.sceneEditor.markerTemp.setCloseHandler(
            handleOriginClose
          );

          let movedE = modelEntity;
          console.log("model Entity 12", modelEntity);
          // if (pickedEntity.type === "IFC_MODEL_SUBSET") {
          //   const objectId = pickedEntity.ifcModel.id;
          //   movedE = caplaEditor.editor3d?.getEntityByObjectId(objectId);
          // }

          //const parsedObjectEntity = await pickedEntity.parse();
          // const parsedObjectEntity = null; // TO CHANGE ?

          handleProcessChange({
            originP: sceneP,
            originN: {
              x: normal.x,
              y: normal.y,
              z: normal.z,
            },
            // normal: {
            //   x: pickedFaceNormal.x,
            //   y: pickedFaceNormal.y,
            //   z: pickedFaceNormal.z,
            // },
            entityID: movedE.entityID,
            entityType: movedE.type,
            modelId: movedE.modelId,

            //parsedObjectEntity,
          });

          dispatch(setStep("TARGET"));
        } else if (step === "TARGET" && clickableRef.current) {
          caplaEditor.editor3d?.sceneEditor.showMarkerTemp1();
          caplaEditor.editor3d?.sceneEditor.moveMarkerTemp1To(
            process.originP,
            process.originN
          );
          caplaEditor.editor3d?.sceneEditor.showMarkerTemp();
          caplaEditor.editor3d?.sceneEditor.moveMarkerTempTo(sceneP, normal);
          caplaEditor.editor3d?.sceneEditor.markerTemp.setCloseHandler(
            handleTargetClose
          );

          handleProcessChange({
            targetP: sceneP,
            targetN: {
              x: normal.x,
              y: normal.y,
              z: normal.z,
            },
          });
          dispatch(setStep("MOVE"));
        }
        clickableRef.current = true; // we activate it for the next click
      } catch (e) {
        console.log(e);
      }
    }
  }

  // initializing

  useEffect(() => {
    if (caplaEditor.editor3d)
      caplaEditor.editor3d.onProcessEditorClick = handleEditorClick;
    dispatch(setPositioningObject({editor: true}));
    virtualElementRef.current = {getBoundingClientRect: generateBBCR};

    return () => {
      if (caplaEditor.editor3d)
        caplaEditor.editor3d.onProcessEditorClick = () => {};
    };
  }, []);

  // update handlers
  useEffect(() => {
    if (caplaEditor.editor3d)
      caplaEditor.editor3d.onProcessEditorClick = handleEditorClick;
  }, [
    step,
    originN?.x,
    originN?.y,
    originN?.z,
    originP?.x,
    originP?.y,
    originP?.z,
    translationMode,
  ]);

  // compute translation
  useEffect(() => {
    if (step === "MOVE")
      setTranslation(
        computeTranslation(originP, originN, targetP, targetN, translationMode)
      );
    dispatch(setSelectedModelId(oldModelId));
    dispatch(setSelectedPdfModelId(oldPdfModelId));
  }, [
    step,
    originN?.x,
    originN?.y,
    originN?.z,
    originP?.x,
    originP?.y,
    originP?.z,
    targetN?.x,
    targetN?.y,
    targetN?.z,
    targetP?.x,
    targetP?.y,
    targetP?.z,
    translationMode,
  ]);
  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        background: (theme) => theme.palette.common.white,
        pb: 2,
      }}
    >
      <LinearProgress sx={{width: 1}} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{p: 2}}>
          <b>{title}</b>
        </Typography>
        <IconButton sx={{mr: 1}} onClick={killProcess}>
          <Close />
        </IconButton>
      </Box>
      {selectionName && (
        <Box>
          <Box sx={{width: 1, backgroundColor: "white", p: 1, ml: 1}}>
            <Typography variant="body2" noWrap>
              {toMoveS + selectionName}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{width: 1, mt: 2}}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {Object.keys(steps).map((step) => (
            <Step key={step} size="small">
              <StepLabel>
                <Typography variant="body2">{steps[step].name}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <DisplayBox open={step === "ORIGIN"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step1Description}
        </Typography>
      </DisplayBox>

      <DisplayBox open={step === "TARGET"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step2Description}
        </Typography>
      </DisplayBox>

      <DisplayBox open={step === "MOVE"}>
        <Box sx={{display: "flex", justifyContent: "center", width: 1, my: 3}}>
          <ToggleButtonGroup
            value={translationMode}
            onChange={(e, v) => setTranslationMode(v)}
            exclusive
            size="small"
          >
            <ToggleButton value="POINT_2_PLANE">
              <Point2Plane />
            </ToggleButton>
            <ToggleButton value="POINT_2_ORTHO">
              <Point2Ortho />
            </ToggleButton>
            <ToggleButton value="POINT_2_POINT">
              <Point2Point />
            </ToggleButton>
            <ToggleButton value="POINT_2_HEIGHT">
              <Point2Height />
            </ToggleButton>
            <ToggleButton value="POINT_2_MARKER">
              <Point2Marker />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <DisplayBox open={translationMode === "POINT_2_POINT"}>
          <Typography variant="body2" sx={{px: 2}}>
            <b>{step2ModePointToPointTitle}</b>
          </Typography>
          <Typography variant="body2" sx={{px: 2}}>
            {step2ModePointToPointDescription}
          </Typography>
        </DisplayBox>

        <DisplayBox open={translationMode === "POINT_2_PLANE"}>
          <Typography variant="body2" sx={{px: 2}} gutterBottom>
            <b>{step2ModePointToPlaneTitle}</b>
          </Typography>
          <Typography variant="body2" sx={{px: 2}}>
            {step2ModePointToPlaneDescription}
          </Typography>
        </DisplayBox>

        <DisplayBox open={translationMode === "POINT_2_ORTHO"}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
            <TextField
              size="small"
              value={distance}
              onChange={handleDistanceChange}
            />
          </Box>
        </DisplayBox>

        <DisplayBox open={translationMode === "POINT_2_HEIGHT"}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
            <TextField
              size="small"
              value={height}
              onChange={handleHeightChange}
            />
          </Box>
        </DisplayBox>

        <DisplayBox open={translationMode === "POINT_2_MARKER"}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
            <Autocomplete
              size="small"
              options={markers}
              getOptionLabel={(option) =>
                `${option.description} (${option.modelName}) `
              }
              sx={{width: 200, mr: 2}}
              onChange={handleMarkerChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={markerNameString}
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Box>
        </DisplayBox>

        <Divider sx={{mt: 2}} />
        <Typography variant="body2" sx={{p: 2}}>
          {step2Variant}
        </Typography>
        <Box sx={{p: 2}}>
          <ContainerPosition
            position={translation}
            onPositionChange={handleTranslationChange}
          />
        </Box>
        <Divider sx={{my: 2}} />
        <Button
          variant="outlined"
          size="small"
          sx={{ml: 2}}
          onClick={handleTranslateClick}
        >
          {step2VariantButton}
        </Button>
      </DisplayBox>
    </Box>
  );
}
