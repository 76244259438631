import {useSelector, useDispatch} from "react-redux";

import {
  // List,
  // ListItem,
  Box,
  // ListItemText,
  // ListItemIcon,
  Button,
  // IconButton,
  Typography,
} from "@mui/material";
import {Visibility} from "@mui/icons-material";

import {setHiddenElements} from "Features/viewer3D/viewer3DSlice";

export default function CardHiddenElements({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const hiddenElementsString = "hidden objects";
  const showAllString = "Show all";

  // data

  const hiddenElements = useSelector((state) => state.viewer3D.hiddenElements);

  const count = hiddenElements.length;
  // helpers

  const title = `${count} ${hiddenElementsString}`;

  // function removeHiddenElement(hiddenEls, el) {
  //   const newHiddenEls = hiddenEls.filter(
  //     (hiddenEl) =>
  //       hiddenEl.modelId !== el.modelId || hiddenEl.expressID !== el.expressID
  //   );
  //   return newHiddenEls;
  // }

  function getItemSetsFromHiddenItems(hiddenItems) {
    const itemSets = [];
    hiddenItems.forEach(({modelId, ifcModelID, expressID}) => {
      const itemSet = itemSets.find(
        (itemSet) =>
          itemSet.modelId === modelId && itemSet.ifcModelID === ifcModelID
      );
      if (itemSet) {
        itemSet.expressIDs.push(expressID);
      } else {
        itemSets.push({modelId, ifcModelID, expressIDs: [expressID]});
      }
    });
    return itemSets;
  }

  function showItems(itemSet) {
    const ifcModelID = itemSet.ifcModelID;
    const ids = itemSet.expressIDs;
    const modelId = itemSet.modelId;
    const entity = editor3d?.getEntity(modelId);

    if (entity) {
      const options = {
        modelID: ifcModelID,
        ids,
        scene: entity.ifcModelEntity.modelObject,
        removePrvious: false,
        applyBVH: true,
      };
      editor3d?.ifcLoader.ifcManager.createSubset(options);
    }
  }

  // handlers

  // function handleShowClick(el) {
  //   const newHiddenEls = removeHiddenElement(hiddenElements, el);
  //   dispatch(setHiddenElements(newHiddenEls));
  // }

  function handleShowAllClick() {
    const itemSets = getItemSetsFromHiddenItems(hiddenElements);
    itemSets.forEach(showItems);
    dispatch(setHiddenElements([]));
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2">
          <b>{title}</b>
        </Typography>
        <Button
          startIcon={<Visibility fontSize="small" />}
          size="small"
          onClick={handleShowAllClick}
          disabled={count === 0}
        >
          {showAllString}
        </Button>
      </Box>
      {/* <List dense>
        {hiddenElements.map((element, index) => (
          <ListItem disablePadding key={index} size="small">
            <ListItemText>{element.expressID}</ListItemText>
            <ListItemIcon>
              <IconButton size="small" onClick={() => handleShowClick(element)}>
                <Visibility fontSize="small" />
              </IconButton>
            </ListItemIcon>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );
}
