// DEPRECATED - All Features/issusets ???

import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  MenuItem,
  Typography,
  Menu,
  IconButton,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import useIssuesetsByScene from "Features/issuesets/useIssuesetsByScene";
import { setSelectedIssuesListingId } from "Features/issues/issuesSlice";

export default function SelectorIssueset({scene}) {
  const dispatch = useDispatch();

  // data

  let issuesets = useIssuesetsByScene(scene);
  issuesets = issuesets.sort((a, b) => a.name.localeCompare(b.name));
  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );


  // helper

  const issueset = issuesets.find((i) => i.id === selectedIssuesListingId);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers - menu

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }
  // handlers - issueset item

  function handleListingClick(issueset) {
    dispatch(setSelectedIssuesListingId(issueset.id));
    setAnchorEl(null);
  }

  // effect

  useEffect(() => {
    if (issuesets.length > 0 && !selectedIssuesListingId) {
      dispatch(setSelectedIssuesListingId(issuesets[0].id));
    }
  }, [issuesets?.length, selectedIssuesListingId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography noWrap sx={{fontSize: 13, mr: 1}} color="inherit">
          {issueset?.name}
        </Typography>
        <IconButton size="small" onClick={handleMoreClick} color="inherit">
          <Down color="inherit" fontSize="small" />
        </IconButton>
      </Box>

      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {issuesets.map((issueset) => (
          <MenuItem
            key={issueset.id}
            onClick={() => handleListingClick(issueset)}
          >
            <Typography variant="body2">{issueset.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
