export default function getNumberOrStringFromString(str) {
  if (!str) return null;
  //
  const num = parseFloat(str);
  if (!isNaN(num)) {
    return num;
  } else {
    return `"${str}"`;
  }
}
