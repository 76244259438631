import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchPicturesService,
  postPictureService,
  fetchPictureService,
  updatePictureService,
} from "./services";

export const postPicture = createAsyncThunk(
  "pictures/postPicture",
  postPictureService
);

export const fetchPictures = createAsyncThunk(
  "pictures/fetchPictures",
  fetchPicturesService
);

export const fetchPicture = createAsyncThunk(
  "pictures/fetchPicture",
  fetchPictureService
);

export const updatePicture = createAsyncThunk(
  "pictures/updatePicture",
  updatePictureService
);

const picturesSlice = createSlice({
  name: "pictures",
  initialState: {items: [], status: "idle"},

  reducers: {
    addTempPictures: (state, action) => {
      const pictures = action.payload;
      state.items.push(...pictures);
    },
  },
  extraReducers: {
    [postPicture.fulfilled]: (state, action) => {
      const {clientId, picture} = action.payload;
      const pictures = state.items.filter((item) => item.clientId !== clientId);
      state.items = [...pictures, picture];
    },
    [fetchPictures.fulfilled]: (state, action) => {
      const pictures = action.payload;
      state.items = pictures;
      state.status = "loaded";
    },
    [fetchPictures.pending]: (state, action) => {
      state.status = "pending";
    },
    [updatePicture.fulfilled]: (state, action) => {
      const picture = action.payload;
      const newItems = state.items.map((item) => {
        if (item.id === picture.id) {
          return picture;
        } else {
          return item;
        }
      });
      state.items = newItems;
    },
  },
});

export const {addTempPictures} = picturesSlice.actions;

export default picturesSlice.reducer;
