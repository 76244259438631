import {client} from "API/capla360";

export async function createPsetTemplateService({accessToken, psetTemplate}) {
  const response = await client.post(`/psettemplates/`, psetTemplate, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function fetchPsetTemplateService({accessToken, psetTemplateId}) {
  const response = await client.get(`/psettemplates/${psetTemplateId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function fetchPsetsService({accessToken, userId, listingId}) {
  const response = await client.get(
    `users/${userId}/listings/${listingId}/psets/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, listingId};
}

export async function createPsetService({
  accessToken,
  userId,
  listingId,
  pset,
}) {
  const response = await client.post(
    `users/${userId}/listings/${listingId}/psets/`,
    pset,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, listingId};
}
