import {useState} from "react";

import useActionsSurveysMap from "../hooks/useActionsSurveysMap";
import useSelectedSurvey from "../hooks/useSelectedSurvey";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateSurvey from "./DialogCreateSurvey";
import DialogEditSurvey from "./DialogEditSurvey";
import DialogDeleteSurvey from "./DialogDeleteSurvey";
import {deleteSurvey} from "../surveysSlice";

export default function SectionActionsSurveys({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateSurvey, setOpenDialogCreateSurvey] = useState(false);
  const [openDialogEditSurvey, setOpenDialogEditSurvey] = useState(false);
  const [openDialogDeleteSurvey, setOpenDialogDeleteSurvey] = useState(false);

  // state - selection

  const selectedSurvey = useSelectedSurvey(scene);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createSurvey: () => setOpenDialogCreateSurvey(true),
    editSurvey: () => setOpenDialogEditSurvey(true),
    deleteSurvey: () => setOpenDialogDeleteSurvey(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateSurvey() {
    setOpenDialogCreateSurvey(false);
  }

  // data - handlers

  const actionsMap = useActionsSurveysMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateSurvey && (
        <DialogCreateSurvey
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateSurvey}
          onClose={handleCloseDialogCreateSurvey}
        />
      )}
      {openDialogEditSurvey && (
        <DialogEditSurvey
          scene={scene}
          survey={selectedSurvey}
          open={openDialogEditSurvey}
          onClose={() => setOpenDialogEditSurvey(false)}
        />
      )}
      {openDialogDeleteSurvey && (
        <DialogDeleteSurvey
          survey={selectedSurvey}
          scene={scene}
          open={openDialogDeleteSurvey}
          onClose={() => setOpenDialogDeleteSurvey(false)}
        />
      )}
    </>
  );
}
