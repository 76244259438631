import React from "react";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormRoom({room, onChange, canEdit, hiddenFields = []}) {
  // strings

  const numS = "Num";
  const codeS = "Code";
  const nameS = "Nom";
  const sectorS = "Secteur";
  const hspS = "HSP (m)";
  const surfaceS = "Surface";
  const typeS = "Type";

  // template

  const template = {
    key: "kvfSector",
    fields: [
      {key: "num", type: "text", name: numS},
      {key: "name", type: "text", name: nameS},
      {key: "code", type: "text", name: codeS},
      {key: "sector", type: "text", name: sectorS},
      {key: "surface", type: "number", name: surfaceS},
      {key: "hsp", type: "number", name: hspS},
      {key: "type", type: "text", name: typeS},
      //{key: "hsfp", type: "number", name: hsfpS}, => no need : pass by measurements height.
    ],
  };
  return room ? (
    <KeyValueForm
      item={room}
      template={template}
      onChange={onChange}
      hiddenFields={hiddenFields}
      canEdit={canEdit}
    />
  ) : (
    <div />
  );
}
