export default function getModelLoadingStatusNum(modelId, statusModelMap) {
  if (!modelId || !statusModelMap) return 0;

  const ids4 = statusModelMap["IN_3D_DETAILED"];
  const ids3 = statusModelMap["IN_3D_OVERVIEW"];
  const ids2 = statusModelMap["IN_STATE"];
  const ids1 = statusModelMap["LIGHT"];

  if (ids4.includes(modelId)) {
    return 4;
  } else if (ids3.includes(modelId)) {
    return 3;
  } else if (ids2.includes(modelId)) {
    return 2;
  } else if (ids1.includes(modelId)) {
    return 1;
  } else {
    return 0;
  }
}
