export default function getStatusKeyFromNum(num) {
  if (num === 1) {
    return "LIGHT";
  } else if (num === 2) {
    return "IN_STATE";
  } else if (num === 3) {
    return "IN_3D_OVERVIEW";
  } else if (num === 4) {
    return "IN_3D_DETAILED";
  } else {
    return "UNDEFINED";
  }
}
