import {Typography} from "@mui/material";

import Image from "./Image";
import Action from "./Action";
import Viewpoint from "./Viewpoint";
import PdfExtractedText from "./PdfExtractedText";

export default function Element(props) {
  const newProps = {
    ...props,
    editorPdf: props.caplaEditor?.editorPdf,
    editor3d: props.caplaEditor?.editor3d,
  }

  switch (props.element.type) {
    case "image":
      return <Image {...newProps} />;
    case "action":
      return <Action {...newProps} />;
    case "viewpoint":
      return <Viewpoint {...newProps} />;
    case "pdfExtractedText":
      return <PdfExtractedText {...newProps} />;
    default:
      return (
        <Typography {...props.attributes} variant="body2">
          {props.children}
        </Typography>
      );
  }
}
