import React from "react";

import useMobileModules from "../hooks/useMobileModules";

import SelectorAutocompleteMultiple from "Components/SelectorAutocompleteMultiple";

export default function FieldMobileModuleCodes({
  name,
  value,
  onChange,
  locked,
}) {
  // data

  const momos = useMobileModules();

  // helpers

  const selection = Array.isArray(value)
    ? momos.filter((m) => value.includes(m.code))
    : [];

  // handlers

  function handleChange(mobileModules) {
    onChange(mobileModules.map((m) => m.code));
  }

  return (
    <SelectorAutocompleteMultiple
      name={name}
      value={selection}
      onChange={handleChange}
      options={momos}
      disabled={locked}
    />
  );
}
