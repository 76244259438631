import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import {setSelectedRessourceIds} from "Features/ressources/ressourcesSlice";
import useSceneModule from "Features/navigation/useSceneModule";
import { setSelectedListItemId } from "Features/leftPanel/leftPanelSlice";

export default function ButtonBackRessourcesInSelectionPanel() {
  const dispatch = useDispatch();

  // string

  const backS = "New";

  // data

  const sceneModule = useSceneModule();

  // helpers

  const label = sceneModule === "RESSOURCES" ? backS : "";

  // handlers

  function handleClick() {
    // state
    dispatch(setSelectedRessourceIds([]));
    dispatch(setSelectedListItemId(null));
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {label}
    </Button>
  );
}
