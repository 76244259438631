import {useSelector} from "react-redux";
import useSceneListings from "Features/listings/useSceneListings";

export default function useSelectedProgressListing(scene) {
  const listings = useSceneListings(scene?.id);
  const selectedProgressListingId = useSelector(
    (s) => s.progress.selectedProgressListingId
  );
  return listings.find((l) => l.id === selectedProgressListingId);
}
