import { useSelector } from "react-redux";

// import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";
import useFilteredMeasurementsFromPackages from "./useFilteredMeasurementsFromPackages";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import getItemsMapById from "Utils/getItemsMapById";

export default function useMeasurementsByElementType(scene, mode, filters) {
  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // filters

  const filtersRessourceIds = filters?.ressourceIds;

  // let [measurements] = useFilteredSceneMeasurements(scene, mode);
  let measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });

  // apply filters
  if (Array.isArray(filtersRessourceIds)) {
    const filteredTypesIds = elementTypes
      .filter((type) =>
        type.res?.some(({ resId }) => filtersRessourceIds.includes(resId))
      )
      .map(({ id }) => id);
    measurements = measurements.filter((m) =>
      filteredTypesIds.includes(m.elementTypeId)
    );
  }

  if (!measurements) return [];

  let typesMap = {};
  const typesById = getItemsMapById(elementTypes);

  measurements.forEach((m) => {
    const mType = typesById[m.elementTypeId];
    const length = !filterNoVoids && m.lengthNet ? m.lengthNet : m.length;
    const area = !filterNoVoids && m.areaNet ? m.areaNet : m.area;
    const volume = !filterNoVoids && m.volumeNet ? m.volumeNet : m.volume;
    if (!mType) return;
    const type = typesMap[mType?.id];
    if (!type) {
      typesMap[mType.id] = {
        ...mType,
        count: 1,
        length: length,
        area: area,
        volume: volume,
      };
    } else {
      typesMap[mType.id] = {
        ...mType,
        count: type.count + 1,
        length: type.length + length,
        area: type.area + area,
        volume: type.volume + volume,
      };
    }

    if (mType.parent) {
      const pType = typesById[mType.parent];
      const type = typesMap[pType?.id];
      if (!type) {
        typesMap[pType.id] = {
          ...pType,
          count: 1,
          length: length,
          area: area,
          volume: volume,
        };
      } else {
        typesMap[pType?.id] = {
          ...pType,
          count: type.count + 1,
          length: type.length + length,
          area: type.area + area,
          volume: type.volume + volume,
        };
      }
    }
  });

  // // voids

  // for (let m of measurements) {
  //   if (m.isVoid && Array.isArray(m.hostedTypes)) {
  //     m.hostedTypes.forEach((typeId) => {
  //       const qItem = typesMap[typeId];
  //       if (qItem) {
  //         typesMap[typeId] = {
  //           count: qItem.count,
  //           length: qItem.length - m.length,
  //           area: qItem.area - m.area,
  //           volume: qItem.volume - m.volume,
  //         };
  //       }
  //     });
  //   }
  // }

  const measurementsByType = [];

  // helper
  function round(value, number) {
    if (!value) return null;
    return parseFloat(value.toFixed(number));
  }

  Object.entries(typesMap).forEach(([elementTypeId, type]) => {
    const mByT = {
      length: round(type.length, 3),
      area: round(type.area, 2),
      volume: round(type.volume, 2),
    };

    measurementsByType.push({ elementTypeId, ...type, ...mByT });
  });

  return measurementsByType;
}
