import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormSector from "./FormSector";

import useSelectedSector from "../hooks/useSelectedSector";
import {setEditedSector, setTempSector} from "../sectorsSlice";

export default function SectionSectorInSelectionPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const isEditing = useSelector((s) => s.sectors.isEditingSector);
  const editedSector = useSelector((s) => s.sectors.editedSector);
  const tempSector = useSelector((s) => s.sectors.tempSector);
  const selectedSector = useSelectedSector(scene);

  // helper

  const isTemp = !selectedSector && !isEditing;
  const isEdited = selectedSector && isEditing;
  const isSelected = selectedSector && !isEditing;

  // handlers

  function handleTempSectorChange(sector) {
    dispatch(setTempSector(sector));
  }
  function handleEditedSectorChange(sector) {
    dispatch(setEditedSector(sector));
  }
  return (
    <Box sx={{pt: 4}}>
      <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
        {isTemp && (
          <FormSector
            sector={tempSector}
            onChange={handleTempSectorChange}
            canEdit={true}
          />
        )}
        {isSelected && <FormSector sector={selectedSector} canEdit={false} />}
        {isEdited && (
          <FormSector
            sector={editedSector}
            onChange={handleEditedSectorChange}
            canEdit={true}
          />
        )}
      </Box>
    </Box>
  );
}
