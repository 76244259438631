import {Box, Typography} from "@mui/material";

export default function HeaderOrder({operationName, operationDescription}) {
  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        bgcolor: "white",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="h6">{"Opération " + operationName}</Typography>
      <Typography sx={{mx: 2}}>{" • "}</Typography>
      <Typography>{operationDescription}</Typography>
    </Box>
  );
}
