import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {Delete} from "@mui/icons-material";

// import {deleteMeasurement} from "../measurementsSlice";
import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";

export default function ButtonDeleteMeasurements({
  caplaEditor,
  measurements,
  onDelete,
}) {
  const dispatch = useDispatch();

  // strings

  const deleteS = "Delete";

  // data

  const model = useSelectedMeasurementsModel();

  // handlers

  function handleDelete() {
    // 3D
    const entity = caplaEditor?.editor3d?.getEntity(model.id);
    measurements.forEach((measurement) => {
      entity?.deleteMeasurement(measurement.id);
      // // state
      // dispatch(deleteMeasurement(measurement.id));
    });

    // state and models
    caplaEditor?.measDataManager.deleteMeasurements(measurements);

    // delete PDF annotations
    const mnger = caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;
    mnger?.deleteMeasurements(measurements.map((m) => m.id));

    // callback
    if (onDelete) onDelete();
  }
  return (
    <Button startIcon={<Delete />} size="small" onClick={handleDelete}>
      {deleteS}
    </Button>
  );
}
