import {Box, List} from "@mui/material";

import ListItemModelLoadingStatus from "./ListItemModelLoadingStatus";

export default function ContainerModelsInScope({
  models,
  loadingStatusModelIdsMap,
}) {
  return (
    <Box sx={{display: "flex", width: 1, flexDirection: "column"}}>
      <List dense disablePadding sx={{width: 1}}>
        {models.map((model) => {
          return (
            <ListItemModelLoadingStatus
              key={model.id}
              model={model}
              loadingStatusModelIdsMap={loadingStatusModelIdsMap}
            />
          );
        })}
      </List>
    </Box>
  );
}
