import {useDispatch, useSelector} from "react-redux";

import {deleteArticle, setSelectedArticleId} from "../articlesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedArticlesGroup from "./useSelectedArticlesGroup";

export default function useDeleteArticle() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const articlesGroup = useSelectedArticlesGroup();
  const articlesMap = useSelector((s) => s.articles.articlesMap);

  const sortedArticles = articlesGroup?.sortedArticles ?? [];

  const deleteA = async (article) => {
    if (accessToken && article?.id) {
      const deletedIndex = sortedArticles.indexOf(article.clientId);
      const prevIndex = deletedIndex ? deletedIndex - 1 : 0;
      const articlesArray = Object.values(articlesMap);

      const prevArticle = articlesArray.find(
        (article) => article.clientId === sortedArticles[prevIndex]
      );

      dispatch(setSelectedArticleId(prevArticle?.id));
      //
      await dispatch(deleteArticle({article, accessToken}));
    }
    //
  };

  return deleteA;
}
