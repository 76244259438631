/*
 * sector : {id,code,name,z}
 */
import {nanoid} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneSectorsFromAnonymSectors(scene) {
  // anonym sectors = sectors identified with their code only.

  const dispatch = useDispatch();

  const updateSceneSector = (sectors) => {
    if (!scene) return;
    const sectorsCodes = sectors.map((r) => r.code);
    const sceneSectorsPrev = scene?.data?.sectors ? scene.data.sectors : [];

    // unchanged
    let otherSectors = sceneSectorsPrev.filter(
      (r) => !sectorsCodes.includes(r.code)
    );

    // updated
    let updatedPrevSectors = sceneSectorsPrev.filter((r) =>
      sectorsCodes.includes(r.code)
    );
    let updatedNextSectors = [];
    if (updatedPrevSectors) {
      updatedNextSectors = updatedPrevSectors.map((prevSector) => {
        const newSector = sectors.find((r) => r.code === prevSector.code);
        return {...prevSector, ...newSector};
      });
    }

    // news
    let prevCodes = sceneSectorsPrev.map((r) => r.code);
    let newSectors = sectors.filter((r) => !prevCodes.includes(r.code));
    newSectors = newSectors.map((r) => ({id: nanoid(), ...r}));

    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        sectors: [...otherSectors, ...updatedNextSectors, ...newSectors],
      },
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneSector;
}
