import {createSlice} from "@reduxjs/toolkit";

export const relationsSlice = createSlice({
  name: "relations",
  initialState: {
    mode: "list",
    selectedTypesGroupForList: null,
    selectedRessourcesGroupForList: null,
    relationsTypesRessources: null,
    relationsTypesRessourcesToRestore: null,
    editedRelations: {},
    updatedAt: null, // use to force updateRelations hook
    //
    selectedResGroupProxyIdInRelations: null,
    selectedTypeGroupProxyIdInRelations: null,
    selectedResIdsInRelations: null,
    selectedTypeIdsInRelations: null,
  },
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setSelectedTypesGroupForList: (state, action) => {
      state.selectedTypesGroupForList = action.payload;
    },
    setSelectedRessourcesGroupForList: (state, action) => {
      state.selectedRessourcesGroupForList = action.payload;
    },
    setRelationsTypesRessources: (state, action) => {
      state.relationsTypesRessources = action.payload;
    },
    setRelationsTypesRessourcesToRestore: (state, action) => {
      state.relationsTypesRessourcesToRestore = action.payload;
    },
    setEditedRelations: (state, action) => {
      state.editedRelations = action.payload;
    },
    //
    setSelectedResGroupProxyIdInRelations: (state, action) => {
      state.selectedResGroupProxyIdInRelations = action.payload;
    },
    setSelectedTypeGroupProxyIdInRelations: (state, action) => {
      state.selectedTypeGroupProxyIdInRelations = action.payload;
    },
    setSelectedResIdsInRelations: (state, action) => {
      state.selectedResIdsInRelations = action.payload;
    },
    setSelectedTypeIdsInRelations: (state, action) => {
      state.selectedTypeIdsInRelations = action.payload;
    },
    //
    setUpdatedAt: (state, action) => {
      state.updatedAt = action.payload;
    },
  },
  // weird behaviour when updating ressources groups...
  // extraReducers: {
  //   "elementTypes/updateElementTypesGroup/fulfilled": (state, action) => {
  //     state.updatedAt = Date.now();
  //   },
  //   "ressources/updateRessourcesGroup/fulfilled": (state, action) => {
  //     state.updatedAt = Date.now();
  //   },
  // },
});

export const {
  setMode,
  setSelectedTypesGroupForList,
  setSelectedRessourcesGroupForList,
  setRelationsTypesRessources,
  setRelationsTypesRessourcesToRestore,
  setEditedRelations,
  //
  setSelectedResGroupProxyIdInRelations,
  setSelectedTypeGroupProxyIdInRelations,
  setSelectedResIdsInRelations,
  setSelectedTypeIdsInRelations,
  //
  setUpdatedAt,
} = relationsSlice.actions;

export default relationsSlice.reducer;
