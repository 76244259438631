import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

export default function SelectorArticlesGroupTypesGroups({
  selectedGroupsIds,
  onSelectionChange,
  groups,
}) {
  function handleClick(id) {
    if (selectedGroupsIds.includes(id)) {
      onSelectionChange(
        selectedGroupsIds.filter((selectedId) => selectedId !== id)
      );
    } else {
      onSelectionChange([...selectedGroupsIds, id]);
    }
  }

  return (
    <List dense disablePadding>
      {groups.map((group) => (
        <ListItemButton
          divider
          selected={selectedGroupsIds.includes(group.id)}
          key={group.id}
          onClick={() => handleClick(group.id)}
        >
          <Typography sx={{fontSize: 12}}>
            {`[${group.code ?? "-"}] ${group?.name}`}
          </Typography>
        </ListItemButton>
      ))}
    </List>
  );
}
