import {Box} from "@mui/material";
import useInitFetchSurveys from "../hooks/useInitFetchSurveys";
import {useOutletContext} from "react-router-dom";

export default function PageSurveys() {
  //
  const {scene} = useOutletContext();
  const sceneId = scene?.id;
  //
  useInitFetchSurveys(sceneId);
  //
  return <Box sx={{width: 1, height: 1}}></Box>;
}
