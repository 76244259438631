import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

import {updateSharedbox} from "../sharedboxesSlice";

export default function useUpdateSceneSharedbox(sceneId) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const updateSceneSharedbox = (sharedbox) => {
    dispatch(updateSharedbox({sharedbox, sceneId, accessToken}));
  };

  return updateSceneSharedbox;
}
