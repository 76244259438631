// oldTypes = {groupId:{elementTypeId1:{func,filtersStr}}}
import copyTypes from "./copyTypes";

import applyInnerGlobalFiltersStrToArticleTypes from "./applyInnerGlobalFiltersStrToArticleTypes";
import articleTypesMapToArray from "./articleTypesMapToArray";
import updateArticleTypesWithOneItem from "./updateArticleTypesWithOneItem";

export default function mergeTwoArticleTypes(oldTypes, newTypes, options) {
  // options

  const transferGlobalFiltersStr = options?.transferGlobalFiltersStr;
  const transferParentsFiltersStr = options?.transferParentsFiltersStr;
  const removeParentItems = options?.removeParentItems;

  //
  let mergedTypes = copyTypes(oldTypes); // copy to avoid unvalid mutation.

  const newTypesArray = articleTypesMapToArray(newTypes);

  newTypesArray.forEach((item) => {
    mergedTypes = updateArticleTypesWithOneItem(mergedTypes, item, {
      updateMode: "merge",
      transferParentsFiltersStr,
      removeParentItems,
      transferGlobalFiltersStr,
    });
  });

  // v it is taken care by the transferGlobalFiltersStr option
  //mergedTypes = applyInnerGlobalFiltersStrToArticleTypes(mergedTypes);

  return mergedTypes;
}
