import useScopesByScene from "Features/scopes/hooks/useScopesByScene";
import getModelsInScope from "Features/scopes/utils/getModelsInScope";
import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import getModelLoadingStatusNum from "../utils/getModelLoadingStatusNum";
import {triggerNewLoadingOrder} from "../overviewerSlice";
import useScopeToOpen from "Features/scopes/hooks/useScopeToOpen";

export default function useAutoDetectModelsToLoad() {
  const dispatch = useDispatch();

  const models = useSelector((s) => s.viewer3D.models);

  const loadingStatusModelIdsMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  // helpers - models to load

  const scopeToOpen = useScopeToOpen({useFixedScope: true});

  const scopeModels = getModelsInScope(models, scopeToOpen);

  const modelsToLoad = scopeModels.filter((model) => {
    const loadingStatusNum = getModelLoadingStatusNum(
      model.id,
      loadingStatusModelIdsMap
    );
    return loadingStatusNum >= 2;
  });

  // helper - loadingRatio

  const loadingRatioByScopeIdMap = useSelector(
    (s) => s.overviewer.loadingRatioByScopeIdMap
  );

  const loadingRatio = loadingRatioByScopeIdMap[scopeToOpen?.id];

  let modelsToLoadAreAllFetched = false;
  if (
    modelsToLoad?.length > 0 &&
    modelsToLoad.length === loadingRatio?.modelIdsToFetch?.length
  ) {
    modelsToLoadAreAllFetched = true;
  }

  console.log(
    "[DEBUG AUTO DETECT MODELS TO LOAD",
    modelsToLoad?.length,
    loadingRatio?.modelIdsToFetch?.length
  );
  // loadingOrder

  useEffect(() => {
    if (
      modelsToLoad?.length > 0 &&
      scopeToOpen?.id &&
      modelsToLoadAreAllFetched
    ) {
      console.log("TRIGGER NEW LOADING ORDER", modelsToLoad?.length);
      dispatch(
        triggerNewLoadingOrder({modelIds: modelsToLoad.map((m) => m.id)})
      );
    }
  }, [scopeToOpen?.id, modelsToLoadAreAllFetched]);
}
