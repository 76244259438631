import {Box, Typography} from "@mui/material";
import {lighten} from "@mui/material/styles";

import useUpdateSceneDefaultScopeId from "../hooks/useUpdateSceneDefaultScopeId";
import useSceneDefaultScopeId from "../hooks/useSceneDefaultScopeId";

export default function ChipDefaultLabel({scopeId, scene}) {
  // strings

  const label = "par défaut";

  // data

  const sceneDefaultScopeId = useSceneDefaultScopeId(scene);
  const updateSceneDefaultScopeId = useUpdateSceneDefaultScopeId(scene);

  // helper

  const selected =
    sceneDefaultScopeId === scopeId ||
    (scopeId === "all" && !sceneDefaultScopeId);

  // handlers

  function handleDefaultChange() {
    if (selected) {
      updateSceneDefaultScopeId(null);
    } else {
      updateSceneDefaultScopeId(scopeId);
    }
  }

  return (
    <Box
      sx={{
        height: 18,
        borderRadius: "4px",
        bgcolor: (theme) =>
          selected
            ? "primary.main"
            : lighten(theme.palette.common.caplaBlack, 0.2),
        color: selected ? "common.white" : "text.secondary",
        px: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={handleDefaultChange}
    >
      <Typography sx={{fontSize: 10}} noWrap>
        {label}
      </Typography>
    </Box>
  );
}
