import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function FieldZoneRoom({zone, scene, onZoneChange}) {
  // strings

  const roomS = "Zone";

  // data

  const rooms = useRoomsByScene(scene)
    .map((r) => ({...r, num: r.num ? r.num : "0"}))
    .sort((a, b) => a.num - b.num);

  // template

  const template = {
    key: "kvtZoneRoom",
    fields: [
      {
        key: "room",
        name: roomS,
        type: "combo",
        options: rooms,
        canCreate: false,
      },
    ],
  };

  // helpers - item

  const item = {room: rooms.find((s) => s.id === zone.roomId)};

  // handlers

  function handleRoomChange({room}) {
    // const annotationId = zone.id;
    // const props = {roomId: room?.id};
    // editorPdf.annotationsManager.updateAnnotationEntityProps({
    //   annotationId,
    //   props,
    // });
    onZoneChange({...zone, roomId: room?.id});
  }

  return (
    <KeyValueForm item={item} template={template} onChange={handleRoomChange} />
  );
}
