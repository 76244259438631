import {Typography} from "@mui/material";

export default function Action({attributes, children, element}) {
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <Typography>{element.description}</Typography>
      </div>
      {children}
    </div>
  );
}
