import {List, ListItemButton, Typography, Box} from "@mui/material";

import formatDate from "../utils/formatDate";

export default function ListSurveys({surveys, selection, onSelectionChange}) {
  return (
    <List dense>
      {surveys.map((survey) => {
        const selected = selection === survey.id;
        const formatedDate = survey.scheduledAt
          ? formatDate(survey.scheduledAt)
          : "-";
        return (
          <ListItemButton
            divider
            selected={selected}
            onClick={() => {
              console.log("clicked on survey", survey);
              onSelectionChange(survey.id);
            }}
          >
            <Box>
              <Typography variant="body2">{survey.name}</Typography>
              <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                {formatedDate}
              </Typography>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
