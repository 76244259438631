import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setElementTypes} from "../librarySlice";

import loadTypesFromExcel from "../utils/loadTypesFromExcel";

import libraryUrl from "../assets/library.xlsx";

export default function useLibrary() {
  const dispatch = useDispatch();
  const library = useSelector((s) => s.library.elementTypes);

  useEffect(() => {
    async function load() {
      let types = await loadTypesFromExcel({url: libraryUrl});
      types = types.filter((t) => t.name);
      types = types.map((t) => ({...t, isTitle: Boolean(t.isTitle)}));
      dispatch(setElementTypes(types));
    }
    if (library.length === 0) {
      load();
    }
  }, [library]);

  return library;
}
