import {useSelector} from "react-redux";

import {Box, IconButton, Badge, Tooltip} from "@mui/material";
import {Save} from "@mui/icons-material";
export default function ContainerContextOverviewActions({
  modificationsCount,
  onSaveClick,
}) {
  // string

  const title = `Enregistrer les modifications (x${modificationsCount})`;

  // data

  const sceneIsSyncing = useSelector((s) => s.overviewer.sceneIsSyncing);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      <Tooltip title={title}>
        <IconButton
          size="small"
          color="inherit"
          onClick={onSaveClick}
          disabled={sceneIsSyncing}
        >
          {/* <Badge
            badgeContent={modificationsCount}
            color="primary"
            variant="dot"
            sx={{
              "& .MuiBadge-badge": {
                fontSize: 8,
                p: 0,
                m: 0,
              },
            }}
          > */}
          <Save color="inherit" sx={{fontSize: 14}} />
          {/* </Badge> */}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
