import {client} from "API/capla360";

export async function createMapviewService({
  accessToken,
  userId,
  listingId,
  mapview,
}) {
  const response = await client.post(
    `/users/${userId}/listings/${listingId}/mapviews/`,
    mapview,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}

export async function fetchMapviewsService({accessToken, userId, listingId}) {
  try {
    const response = await client.get(
      `/users/${userId}/listings/${listingId}/mapviews/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
