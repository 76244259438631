export default function extractNomenclatureTypesFromGroup(
  typesGroupWithSortedTypes,
  headerTypeId
) {
  const elementTypes = typesGroupWithSortedTypes.elementTypes ?? [];
  const groupId = typesGroupWithSortedTypes.id;

  const headerIndex = elementTypes.findIndex((t) => t.id === headerTypeId);

  const firstItem = elementTypes[headerIndex + 1];
  const headerItem = elementTypes[headerIndex];

  console.log("firstItem", firstItem);

  const rank = firstItem?.num.split(".").length;

  const extractedTypes = elementTypes.filter(
    (t) => t.num.startsWith(headerItem.num) && t?.num.split(".").length === rank
  );

  // we add the groupId here in case some types doesn't have it (the ones created automatically for example from a nomenclature)
  const extractedTypesWithGroupId = extractedTypes.map((t) => ({
    ...t,
    groupId,
  }));

  return extractedTypesWithGroupId;
}
