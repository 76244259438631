import {useState} from "react";

import SectionColumn from "./SectionColumn";

import {Typography, Box} from "@mui/material";

export default function LastColumn({onAdd}) {
  // string

  const newS = `Nouveau niveau dans l'arborescence : Bâtiments, Allées, Niveaux, Appartements,...`;

  // state

  const [rankName, setRankName] = useState("");

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 1,
        }}
      >
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {newS}
        </Typography>
      </Box>
      <SectionColumn onAdd={onAdd} />
    </Box>
  );
}
