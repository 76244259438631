import {useEffect, useState} from "react";

import {Slider, Box, Typography} from "@mui/material";

export default function SliderImageOpacity({modelId, editor3d, enabled}) {
  // strings

  const opacityString = "Opacité";

  // props

  const entity = editor3d?.getEntity(modelId);

  // state

  const initOpacity = entity?.opacity ? entity.opacity : 100;
  const [opacity, setOpacity] = useState(initOpacity);
  useEffect(() => {
    setOpacity(initOpacity);
  }, [modelId]);

  // handlers

  function handleChange(e) {
    const value = e.target.value;
    setOpacity(value);
    if (entity?.setOpacity) entity.setOpacity(opacity);
  }

  return (
    <Box
      sx={{
        minWidth: 200,
        px: 1,
        pr: 2,
        display: "flex",
        alignItems: "center",
        width: 1,
      }}
    >
      <Typography sx={{fontSize: 12, mr: 2}}>{opacityString}</Typography>
      <Slider
        value={opacity}
        onChange={handleChange}
        size="small"
        disabled={!enabled}
      />
    </Box>
  );
}
