import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControlLabel, Switch, Typography, Box } from "@mui/material";

import { setHideSavedMeasurements } from "../measurementsSlice";

export default function SwitchHideSavedMeasurements() {
    const dispatch = useDispatch();

  // strings

  const disableSavedS = "Masquer les annotations sauvegardées";

  // data

  const hideSavedMeasurements = useSelector(
    (s) => s.measurements.hideSavedMeasurements
  );

  // state

  const [checked, setChecked] = useState(hideSavedMeasurements);

  // handler

  const handleCheckChange = () => {
    dispatch(setHideSavedMeasurements(!checked));
    setChecked(!checked);
  };

  return (
    <Box sx={{pl: 3, pb: 2}}>
      <FormControlLabel
        control={
        <Switch size="small" checked={checked} onChange={handleCheckChange} />
        }
        label={<Typography variant="body2">{disableSavedS}</Typography>}
      />
    </Box>
  )
}