import articleTypesMapToArray from "./articleTypesMapToArray";
import articleTypesArrayToMap from "./articleTypesArrayToMap";

export default function removeGlobalScopeFromArticleTypes(articleTypes) {
  const typesArray = articleTypesMapToArray(articleTypes);
  const newTypesArray = typesArray.filter(
    (item) => !(!item.groupId && !item.typeId)
  );

  return articleTypesArrayToMap(newTypesArray);
}
