import makeStyles from '@mui/styles/makeStyles';

import ToolModelsManager from "./ToolModelsManager";
import CardSites from "./CardSites";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

export default function ToolIfcs({editor3d}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CardSites />
      <ToolModelsManager editor3d={editor3d} type="IFC" />
    </div>
  );
}
