import {client} from "API/capla360";

export async function fetchListingService({accessToken, userId, listingId}) {
  const response = await client.get(`/users/${userId}/listings/${listingId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, listingId};
}
