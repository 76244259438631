import React from "react";

import {Autocomplete, TextField, Typography, Box} from "@mui/material";

export default function SelectorModel({models, onChange, model}) {
  // props

  if (model === undefined) model = null;

  // handlers

  function handleModelChange(model) {
    onChange(model);
  }
  return (
    <Box sx={{width: 1, bgcolor: "common.white", borderRadius: "4px"}}>
      <Autocomplete
        sx={{flexGrow: 1}}
        options={models}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Typography {...props} variant="body2" sx={{pl: 1}}>
            {option.name}
          </Typography>
        )}
        value={model}
        onChange={(e, v) => handleModelChange(v)}
        //input={input}
        //onInputChange={(e, v) => setInput(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            //label={<Typography variant="body2">{modelsString}</Typography>}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
                height: "30px",
              },
            }}
            inputProps={{
              ...params.inputProps,
              style: {paddingTop: 0},
            }}
          />
        )}
      />
    </Box>
  );
}
