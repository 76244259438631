import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {unwrapResult, nanoid} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import SectionPdfsInHome from "./SectionPdfsInHome";
import HeaderEntityPdfs from "./HeaderEntityPfds";

import SectionImportPdf from "./SectionImportPdf";
import SectionTempPdf from "./SectionTempPdf";

import useAccessToken from "Features/auth/useAccessToken";

import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import SectionStartPdf from "./SectionStartPdf";

export default function PageHomeAdminPdfs({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const inputs = useInputsByScene(scene);

  // state

  const [mode, setMode] = useState("LIST"); // "IMPORT", "TEMP"
  useEffect(() => {
    if (inputs?.length === 0) setMode("START");
  }, [inputs?.length]);

  const [tempFile, setTempFile] = useState(null);

  const initPdfName = tempFile?.name ? tempFile.name : "";
  const [tempPdfName, setTempPdfName] = useState(initPdfName);
  useEffect(() => {
    setTempPdfName(initPdfName);
  }, [initPdfName]);

  // handlers

  function handleModeChange(mode) {
    setMode(mode);
  }

  function handleNewFile(file) {
    setTempFile(file);
    setMode("TEMP");
  }

  function handleTempPdfNameChange(name) {
    setTempPdfName(name);
  }

  function handleAdd() {
    setMode("IMPORT");
  }
  function handleCancel() {
    setTempFile([]);
    setMode("LIST");
  }

  async function handleCreate() {
    const type = getModelTypeFromFile(tempFile);
    const options = {name: tempPdfName};
    await caplaEditor?.editor3d?.loader.loadFile({
      file: tempFile,
      type,
      options,
      openOnceLoaded: false,
    });
    setMode("LIST");
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyItems: "stretch",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <HeaderEntityPdfs
        mode={mode}
        onAdd={handleAdd}
        onCancel={handleCancel}
        onCreate={handleCreate}
      />
      {mode === "START" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            pt: 2,
          }}
        >
          <SectionStartPdf scene={scene} onFileChange={handleNewFile} />
        </Box>
      )}
      {mode === "LIST" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            pt: 2,
            minHeight: 0,
          }}
        >
          <SectionPdfsInHome scene={scene} />
        </Box>
      )}
      {mode === "IMPORT" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SectionImportPdf scene={scene} onFileChange={handleNewFile} />
        </Box>
      )}
      {mode === "TEMP" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            pt: 2,
          }}
        >
          <SectionTempPdf
            tempFile={tempFile}
            tempPdfName={tempPdfName}
            onTempPdfNameChange={handleTempPdfNameChange}
            onCancel={handleCancel}
          />
        </Box>
      )}
    </Box>
  );
}
