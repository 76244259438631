import {useSelector} from "react-redux";

import useMeasurementsScopeMode from "./useMeasurementsScopeMode";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function useMeasurementsFiltersAndShortcuts(scene) {
  // data

  const mode = useMeasurementsScopeMode(scene);

  const sceneElementTypes = useElementTypesBySceneProxy(scene);

  const measurementsModelsInScope = useSelector(
    (s) => s.measurements.measurementsModelsInScope
  );
  const selectedMeasurementsModelId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );

  // filters

  const filterElementTypeId = useSelector(
    (s) => s.measurements.filterElementTypeId
  );
  const filterSectorId = useSelector((s) => s.measurements.filterSectorId);
  const filterZoneId = useSelector((s) => s.measurements.filterZoneId);
  const filterRoomId = useSelector((s) => s.measurements.filterRoomId);
  const filterMaterialId = useSelector((s) => s.measurements.filterMaterialId);
  const filterRessourceId = useSelector(
    (s) => s.measurements.filterRessourceId
  );
  const filterPhaseId = useSelector(
    (s) => s.measurements.filterPhaseId
  );
  const filterP1 = useSelector(
    (s) => s.measurements.filterP1
  );
  const filterP2 = useSelector(
    (s) => s.measurements.filterP2
  );
  const filterP3 = useSelector(
    (s) => s.measurements.filterP3
  );

  // shortcut

  const selectedTypesInDatagrid = useSelector(
    (s) => s.measurements.selectedTypesInDatagrid
  );

  // helpers - enabled elementTypes

  const disabledTypes = sceneElementTypes
    .filter((t) => t.disabled)
    .map((t) => t.id);

  // compute filters

  let measurementsModelIds,
    elementTypeIds,
    sectorIds,
    zoneIds,
    roomIds,
    materialIds,
    ressourceIds,
    phaseIds,
    p1s,
    p2s,
    p3s;

  // measurementsModelIds

  measurementsModelIds = measurementsModelsInScope;
  if (mode === "ONE_MODEL") {
    measurementsModelIds = [selectedMeasurementsModelId];
  }

  // elementsTypeIds

  //elementTypeIds = [...elementTypesShortcut];
  elementTypeIds = [];
  if (filterElementTypeId) {
    //elementTypeIds = elementTypeIds.filter((id) => id === filterElementTypeId);
    elementTypeIds = [filterElementTypeId];
  }
  if (selectedTypesInDatagrid.length > 0) {
    elementTypeIds = selectedTypesInDatagrid;
  }

  // sectorIds

  //sectorIds = [...sectorsShortcut];
  sectorIds = [];
  if (filterSectorId) {
    //sectorIds = sectorIds.filter((id) => id === filterSectorId);
    sectorIds = [filterSectorId];
  }
  if (selectedTypesInDatagrid.length > 0) {
    sectorIds = selectedTypesInDatagrid;
  }

  // zoneIds

  if (filterZoneId) {
    zoneIds = [filterZoneId];
  } else {
    zoneIds = [];
  }

  // roomIds

  if (filterRoomId) {
    roomIds = [filterRoomId];
  } else {
    roomIds = [];
  }

  // materialIds

  if (filterMaterialId) {
    materialIds = [filterMaterialId];
  } else {
    materialIds = [];
  }

  // ressourceIds

  if (filterRessourceId) {
    ressourceIds = [filterRessourceId];
  } else {
    ressourceIds = [];
  }

  // phaseIds

  if (filterPhaseId) {
    phaseIds = [filterPhaseId];
  } else {
    phaseIds = [];
  }

  // P1 / P2 / P3

  if (filterP1) {
    p1s = [filterP1];
  } else {
    p1s = [];
  }
  if (filterP2) {
    p2s = [filterP2];
  } else {
    p2s = [];
  }
  if (filterP3) {
    p3s = [filterP3];
  } else {
    p3s = [];
  }

  // hash

  const h1 = measurementsModelIds.join("-");
  const h2 = elementTypeIds.map((id) => (id ? id : "null")).join(";");
  const h3 = sectorIds.map((id) => (id ? id : "null")).join(";");
  const h4 = roomIds.map((id) => (id ? id : "null")).join(";");
  const h5 = phaseIds.map((id) => (id ? id : "null")).join(";");
  const h6 = zoneIds.map((id) => (id ? id : "null")).join(";");
  const h7 = materialIds.map((id) => (id ? id : "null")).join(";");
  const h8 = ressourceIds.map((id) => (id ? id : "null")).join(";");
  const h9 = p1s.map((id) => (id ? id : "null")).join(";");
  const h10 = p2s.map((id) => (id ? id : "null")).join(";");
  const h11 = p3s.map((id) => (id ? id : "null")).join(";");

  const h = h1 + h2 + h3 + h4 + h5 + h6 + h7 + h8 + h9 + h10 + h11;

  return [
    {
      measurementsModelIds,
      elementTypes: elementTypeIds,
      disabledTypes,
      sectors: sectorIds,
      zones: zoneIds,
      rooms: roomIds,
      materials: materialIds,
      ressources: ressourceIds,
      phases: phaseIds,
      p1s,
      p2s,
      p3s,
    },
    h,
  ];
}
