export default function fillWorksheetWithSectors({
  worksheet,
  sectors,
  rowStartIndex,
}) {
  sectors.forEach(({code, name, z, building}, index) => {
    const row = worksheet.getRow(rowStartIndex + index);
    row.getCell(1).value = code;
    row.getCell(2).value = name;
    row.getCell(3).value = z;
    row.getCell(4).value = building;
  });
}
