import {createSlice} from "@reduxjs/toolkit";

export const phasesSlice = createSlice({
  name: "phases",
  initialState: {
    selectedPhaseIds: [],
    lastPhaseId: null, // last created zone => used to select in the DataGrid the new zone.
    selectedPhase: null,
    editedPhase: null,
    tempPhase: {},
  },
  reducers: {
    setSelectedPhaseIds: (state, action) => {
      state.selectedPhaseIds = action.payload;
    },
    setLastPhaseId: (state, action) => {
      state.lastPhaseId = action.payload;
    },
    setSelectedPhase: (state, action) => {
      state.selectedPhase  = action.payload;
    },
    setTempPhase: (state, action) => {
      state.tempPhase  = action.payload;
    },
    setEditedPhase: (state, action) => {
      state.editedPhase  = action.payload;
    },
  },
});

export const {
  setSelectedPhaseIds,
  setLastPhaseId,
  setSelectedPhase,
  setEditedPhase,
  setTempPhase,
} = phasesSlice.actions;

export default phasesSlice.reducer;
