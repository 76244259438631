import EXIF from "exif-js";

export function getImageFileLatLng(file) {
  if (
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/tiff"
  ) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const exifInfo = EXIF.readFromBinaryFile(arrayBuffer);
        let pos = getExifPosition(exifInfo);
        if (pos) {
          resolve({
            lng: pos[0],
            lat: pos[1],
            orientation: exifInfo.orientation,
          });
        } else {
          resolve(undefined);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }
}

export function drawSideUpImageAsync(file, srcOrientation) {
  // srcOrientation = exif orientation
  var img = new Image();
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      canvas.toBlob(
        (blob) => {
          const file = new File([blob]);
          resolve(file);
        },
        "image/jpeg",
        0.95
      );
    };

    img.src = url;
  });
}

function getExifPosition(exifInfo) {
  console.log("exif", exifInfo);
  if (
    typeof exifInfo.GPSLongitude !== "undefined" &&
    typeof exifInfo.GPSLongitudeRef !== "undefined" &&
    typeof exifInfo.GPSLatitude !== "undefined" &&
    typeof exifInfo.GPSLatitudeRef !== "undefined"
  ) {
    //Convert this information into a GeoJSON position.
    var pos = [
      convertDMSToDD(exifInfo.GPSLongitude, exifInfo.GPSLongitudeRef),
      convertDMSToDD(exifInfo.GPSLatitude, exifInfo.GPSLatitudeRef),
    ];

    if (typeof exifInfo.GPSAltitude !== "undefined") {
      pos.push(exifInfo.GPSAltitude);
    }

    return pos;
  }

  return null;
}

//Converts a decimal, minute, second coordinate into a decimal degrees coordinate.
function convertDMSToDD(dms, direction) {
  if (Array.isArray(dms) && dms.length >= 3) {
    var dd =
      dms[0] + //days
      dms[1] / 60 + //minutes
      dms[2] / (60 * 60); //seconds

    if (direction === "S" || direction === "W") {
      dd *= -1;
    } // Don't do anything for N or E

    return dd;
  }

  return null;
}
