export function capitalizeStart(string) {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (e) {
    console.log(e);
  }
}

export function decode(string) {
  let decode = string?.toString().replaceAll("\\X2\\00E9\\X0\\", "é");
  decode = decode?.replaceAll("\\S\\i", "é");
  decode = decode?.replaceAll("\\X2\\00E8\\X0\\", "è");
  decode = decode?.replaceAll("\\X2\\00D8\\X0\\", "ø");
  decode = decode?.replaceAll("\\S\\h", "è");

  return decode;
}
