export function getWindowSize() {
  return {height: window.innerHeight, width: window.innerWidth};
}

export function getElementSize(element) {
  return {height: element.offsetHeight, width: element.offsetWidth};
}

export function imageToModelSize(imageSize) {
  // 1px ?= 1-10-100.. m
  const dim = Math.max(imageSize.width, imageSize.height);
  // dim = 9 > order = 0, 10-99 > 1, 100-999> 2,...
  const order = Math.trunc(Math.log(dim) / Math.log(10));
  const ratio = Math.pow(10, order);
  const s = {
    width: imageSize.width / ratio,
    height: imageSize.height / ratio,
  };
  return s;
}
