import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import DataGridMaterials from "./DataGridMaterials";
import ToolbarMaterialsMain from "./ToolbarMaterialMain";
import SectionTitleMaterials from "./SectionTitleMaterials";

import Stretch from "Features/ui/components/Stretch";

export default function PageMaterials() {
  // params

  const {scene} = useOutletContext();

  return (
    <Box sx={{width: 1}}>
      <Box sx={{height: "60px"}} />
      <SectionTitleMaterials />
      <ToolbarMaterialsMain scene={scene} />
      <Stretch>
        <DataGridMaterials scene={scene} />
      </Stretch>
    </Box>
  );
}
