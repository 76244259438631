import {useSelector, useDispatch} from "react-redux";
import {setTabInEditMeasurements} from "Features/leftPanel/leftPanelSlice";

import {ToggleButtonGroup, ToggleButton, Box, Typography} from "@mui/material";

export default function TabSelectorForEditMeasurementsInLeftPanel() {
  const dispatch = useDispatch();

  // strings

  const typeS = "Eléments";
  const ressourceS = "Articles";
  const measurementS = "Repérages";

  // data

  const tab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  // handlers

  function handleModeChange(v) {
    dispatch(setTabInEditMeasurements(v));
  }
  return (
    <ToggleButtonGroup
      exclusive
      value={tab}
      onChange={(_, v) => handleModeChange(v)}
      size="small"
      sx={{
        height: "24px",
        // bgcolor: "primary.flash",
        // color: "common.white",
      }}
    >
      <ToggleButton value="ELEMENT_TYPES">
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography sx={{fontSize: 12}}>{typeS}</Typography>
        </Box>
      </ToggleButton>
      {/* <ToggleButton value="RESSOURCES">
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography sx={{fontSize: 12}}>{ressourceS}</Typography>
        </Box>
      </ToggleButton> */}
      <ToggleButton value="MEASUREMENTS">
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography sx={{fontSize: 12}}>{measurementS}</Typography>
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
