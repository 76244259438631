import {useState} from "react";

import useActionsInputsMap from "../hooks/useActionsInputsMap";

import {Box} from "@mui/material";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogSelectFilesToCreateInputs from "./DialogSelectFilesToCreateInputs";

export default function SectionActionsInputs({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateInputs, setOpenDialogCreateInputs] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createInputs: () => setOpenDialogCreateInputs(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateInputs() {
    setOpenDialogCreateInputs(false);
  }

  // data - handlers

  const actionsMap = useActionsInputsMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateInputs && (
        <DialogSelectFilesToCreateInputs
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateInputs}
          onClose={handleCloseDialogCreateInputs}
        />
      )}
    </>
  );
}
