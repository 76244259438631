import {Object3D} from "three";

export default class SceneElement {
  constructor({scene, editor}) {
    this.editor = editor;
    this.sceneClientId = scene.clientId;
    this.sceneId = scene.id;
    this.offset = scene.offset;
    this.visible = true; // used to update visibility

    this.createObject();
  }

  createObject() {
    this.object = new Object3D();
    //this.editor.scene.add(this.object);
    this.object.layers.enable(1);
  }

  resetTransformation() {
    this.object.position.set(0, 0, 0);
    this.object.rotation.set(0, 0, 0);
  }

  loadObjectEntities() {
    // init object pos & rot before adding new objects (for update)
    this.resetTransformation();

    // adding objects
    const entities = this.editor.entities.filter(
      (e) => e.model.sceneClientId === this.sceneClientId
    );
    const objects = entities.filter((e) => e.object).map((e) => e.object);
    console.log("loading objects to sceneElement", objects);
    if (objects.length > 0) {
      objects.forEach((object) => {
        object.removeFromParent();
        this.object.attach(object);
      });
    }
  }

  applyOffset(offset) {
    console.log("apply offset", offset);
    const p = offset ? offset.position : this.offset.position;
    const r = offset ? offset.rotation : this.offset.rotation;
    this.object.position.set(p.x, p.y, p.z);
    this.object.rotation.set(r.x, r.y, r.z);
  }

  // hide & show

  hide() {
    this.editor.scene.remove(this.object);
    this.visible = false;
  }

  show() {
    this.editor.scene.add(this.object);
    this.visible = true;
  }

  applyVisibility() {
    if (this.visible) {
      this.show();
    } else {
      this.hide();
    }
  }
}
