import React from "react";

import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
export default function Landing({scene, onSceneOpenClick}) {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        position: "relative",
        backgroundImage: `url(${scene?.imageUrl})`,
      }}
    >
      <Card sx={{width: 250, position: "absolute", top: "30%", left: "30%"}}>
        <CardContent>
          <Typography variant="h6">
            <b>{scene?.title}</b>
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={onSceneOpenClick}>Open the box</Button>
        </CardActions>
      </Card>
    </Box>
  );
}
