import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {Typography, Box} from "@mui/material";

import SelectorMeasurementsScope from "./SelectorMeasurementsScope";

import {
  setScopeSelectionMode,
  setSectorsInScope,
  setRoomsInScope,
  setScopeDefaultSelectionMode,
} from "Features/measurements/measurementsSlice";
import { setBuildingInScope } from "Features/buildings/buildingsSlice";
import useIsMobile from "Features/ui/useIsMobile";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function SectionMeasurementsCountInScope({scene, mode, editor3d}) {
  const dispatch = useDispatch();

  // strings

  const measurementsS = "éléments";

  // data

  const isMobile = useIsMobile();

  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false,
    true
  );
  const sectors = useSectorsByScene(scene);
  const defaultScope = useSelector((s) => s.measurements.scopeDefaultSelectionMode);

  // helpers

  const count = fms.length;
  const fmSectorIds = [...new Set(fms.map((m) => m.sectorId))];
  const fmRoomIds = [...new Set(fms.map((m) => m.roomId))];
  let buildings = [];
  fmSectorIds.map((id) => {
    if (id) {
      const sector = sectors.find((s) => s.id === id);
      if (sector?.building) buildings.push(sector.building);
    }
  })
  const scopeDefault = buildings.length > 1 ? "BY_BUILDING" : (
    fmSectorIds.filter((si) => si).length > 1 ? "BY_SECTOR" : (
      fmRoomIds.length > 1 ? "BY_ROOM" : "BY_SECTOR"
    )
  );

  // effect

  useEffect(() => {
    if (defaultScope !== scopeDefault) {
      dispatch(setScopeDefaultSelectionMode(scopeDefault))
      dispatch(setScopeSelectionMode(scopeDefault));
      dispatch(setSectorsInScope([]));
      dispatch(setRoomsInScope([]));
      dispatch(setBuildingInScope(null));
    }
  }, [defaultScope, sectors.length])

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "baseline",
        px: 2,
        pl: "16px",
        pt: 1,
      }}
    >
      <Box sx={{display: "flex", alignItems: "baseline"}}>
        <Typography variant="h4">{count}</Typography>
        <Typography variant="h6" sx={{ml: 1}}>
          {measurementsS}
        </Typography>
        <Box sx={{ml: 2}}>
          <SelectorMeasurementsScope />
        </Box>
      </Box>
    </Box>
  );
}
