import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid, frFR} from "@mui/x-data-grid";

import {setSelectedSharedbox} from "../sharedboxesSlice";
import useSharedboxesByScene from "../hooks/useSharedboxesByScene";

export default function DataGridSharedboxes({scene}) {
  const dispatch = useDispatch();

  // strings

  const nameS = "Nom";

  // data

  const sharedboxes = useSharedboxesByScene(scene);

  const selectedSharedbox = useSelector(
    (s) => s.sharedboxes.selectedSharedbox
  );

  // helpers

  const selectionModel = selectedSharedbox ? [selectedSharedbox.id] : [];

  const rows = sharedboxes.sort((a,b) => a.name.localeCompare(b.name));
  
  const columns = [
    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    dispatch(setSelectedSharedbox(sharedboxes.find((s) => s.id === selectionId)));
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
