import {Box} from "@mui/material";
// import {
//   Accessibility as Assistant,
// } from "@mui/icons-material";

// import ButtonOpenMenu from "./ButtonOpenMenu";

// import usePageType from "../usePageType";

//import ButtonToggleGridView from "Features/ui/components/ButtonToggleGridView";
import IconModuleInLeftPanel from "Features/ui/components/IconModuleInLeftPanel";

export default function ButtonLeftTopBar({caplaEditor}) {
  // data

  // const pageType = usePageType();

  // helpers

  // const assistant = pageType === "ASSISTANT";
  // const newScene = pageType === "NEW_SCENE";

  return (
    <Box sx={{display: "flex", pr: 2}}>
      {/* {assistant || newScene ? <Assistant /> : <ButtonOpenMenu />} */}
      {/* <ButtonToggleGridView caplaEditor={caplaEditor} /> */}
      <IconModuleInLeftPanel />
    </Box>
  );
}
