import React, {useEffect, useState} from "react";
import {Box, TextField, styled, Typography} from "@mui/material";

const CaplaTextField = styled(TextField)(({theme}) => ({
  "& .MuiInputBase-input": {
    fontSize: theme.typography.body2.fontSize,
    zIndex: 1,
  },
}));
export default function ContainerRotation({
  rotation = {x: 0, y: 0, z: 0},
  onRotationChange,
  readOnly = false,
}) {
  // local state

  const [rot, setRot] = useState(threeToString(rotation)); // rot = local rotation.

  // helpers

  function threeToString(rotation) {
    let {x, y, z} = rotation;

    const _x = ((+parseFloat(x) * 180) / Math.PI).toFixed(2);
    const _y = ((-parseFloat(z) * 180) / Math.PI).toFixed(2);
    const _z = ((+parseFloat(y) * 180) / Math.PI).toFixed(2);
    return {x: _x, y: _y, z: _z};
  }

  function stringToThree(rotation) {
    let {x, y, z} = rotation;

    const _x = (+parseFloat(x) * Math.PI) / 180;
    const _y = (parseFloat(z) * Math.PI) / 180;
    const _z = (-parseFloat(y) * Math.PI) / 180;
    return {x: _x, y: _y, z: _z};
  }

  function validCoordinates({x, y, z}) {
    const validation =
      !isNaN(parseFloat(x)) && !isNaN(parseFloat(y)) && !isNaN(parseFloat(z));
    return validation;
    //return true;
  }

  // handlers

  function handleRotationChange(e) {
    const nr = {...rot};
    const _coord = e.target.id;
    let value = e.target.value;
    value = value.replace(",", ".");

    if (_coord === "x") nr.x = value;
    if (_coord === "y") nr.y = value;
    if (_coord === "z") nr.z = value;

    setRot(nr);

    if (validCoordinates(nr) && onRotationChange) {
      onRotationChange(stringToThree(nr));
    }
  }

  // effect

  useEffect(() => {
    console.log("effect");
    setRot(threeToString(rotation));
  }, [rotation.x, rotation.y, rotation.z]);

  return (
    <Box
      sx={{
        display: "flex",
        "&>*:not(:last-child)": {
          marginRight: 1,
        },
      }}
    >
      <CaplaTextField
        size="small"
        label="X"
        id="x"
        value={rot.x}
        onChange={handleRotationChange}
        disabled={readOnly}
      />
      <CaplaTextField
        size="small"
        label="Y"
        id="y"
        value={rot.y}
        onChange={handleRotationChange}
        disabled={readOnly}
      />
      <CaplaTextField
        size="small"
        value={rot.z}
        label="Z"
        id="z"
        onChange={handleRotationChange}
        disabled={readOnly}
      />
    </Box>
  );
}
