import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import {setSelectedRoomsInDatagrid} from "../measurementsSlice";

import {
  // setSelectedMeasurementId,
  setSelectedMeasurementIds,
} from "../measurementsSlice";
import useMeasurementsByRoomForDatagrid from "../useMeasurementsByRoomForDatagrid";

// import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
// import {setClickedItem} from "Features/selection/selectionSlice";
// import useTranslation from "Features/translations/useTranslation";
import useIsNarrow from "Features/ui/useIsNarrow";
import useRoomsBySceneWithMsts from "Features/rooms/hooks/useRoomsBySceneWithMsts";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridMeasurementsByRoom({mode, scene, caplaEditor}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  const codeS = "Code";
  const surfaceS = "S (m2)";
  const perimeterS = "Périm. (m)";
  // const hsfpS = "HSFP (m)";
  const envelopS = "Par. (m2)";
  const focusS = "Focus";
  const heightS = "H (m)";

  // data

  const os = useSelector((s) => s.ui.openSections);

  const measurements = useMeasurementsByRoomForDatagrid(scene, mode);
  const rooms = useRoomsBySceneWithMsts(scene);

  const selectedRoomsInDatagrid = useSelector(
    (s) => s.measurements.selectedRoomsInDatagrid
  );

  const isNarrow = useIsNarrow();

  // grid - rows

  const rows = measurements;

  // grid - columns

  const columns = [
    {
      field: "code",
      headerName: codeS,
      flex: 1,
    },
    {field: "surface", headerName: surfaceS, width: 100, hide: isNarrow},
    {field: "perimeter", headerName: perimeterS, width: 100, hide: isNarrow},
    //{field: "hsfp", headerName: hsfpS, width: 100, hide: isNarrow},
    {field: "height", headerName: heightS, width: 100},
    {field: "envelop", headerName: envelopS, width: 100, hide: isNarrow},
    {
      field: "hasMsts",
      type: "actions",
      width: 50,
      getActions: (params) => {
        if (params.row.type !== "sector") {
          return [
            <GridActionsCellItem
              icon={<Focus fontSize="small" />}
              onClick={() => handleFocus(params.row.msts)}
              label={focusS}
            />,
          ];
        } else {
          return [];
        }
      },
    },

    // {
    //   field: "actions",
    //   type: "actions",
    //   width: 50,
    //   hide: !os.viewer3D,
    //   getActions: (params) => {
    //     if (!params.row.isTitle && params.row.count > 0) {
    //       return [
    //         <GridActionsCellItem
    //           icon={<Focus />}
    //           onClick={() => handleFocusClick(params.row.id)}
    //           label={focusS}
    //         />,
    //       ];
    //     } else {
    //       return [];
    //     }
    //   },
    // },
  ];

  // selectionModel

  const selectionModel = selectedRoomsInDatagrid;

  // handlers

  function handleSelectionChange(selection) {
    dispatch(setSelectedRoomsInDatagrid(selection));
    if (selection.length > 0) {
      const room = rooms.find((r) => r.id === selection[0]);
      const ids = room?.msts ? room.msts.map((m) => m.id) : [];
      console.log("measurementsIds of room", ids, room);
      // dispatch(setClickedItem({type: "MEASUREMENTS_ROOM", ...room}));
      // dispatch(setSelectedMeasurementId(ids[0]));
      dispatch(setSelectedMeasurementIds(ids));
    }
  }

  function handleFocus(msts) {
    const ids = msts.map((m) => m.id);
    caplaEditor?.editor3d?.sceneEditor.focusMeasurement(msts[0]);

    if (os.fixedViewersBox) {
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.selectMeasurements(
        ids
      );
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.jumpToAnnotationId(
        ids[0]
      );
    }
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--NoItem": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          if (params.row.type === "sector") return `super-app-theme--Title1`;
        }}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
