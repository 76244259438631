import React from "react";
import {useMsal} from "@azure/msal-react";
import {fetchUserId} from "Features/auth/authSlice";
import {useSelector, useDispatch} from "react-redux";
import {Button, CircularProgress} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  progress: {
    marginLeft: theme.spacing(2),
  },
}));

export default function FetchUserIdButton() {
  const classes = useStyles();
  // actions
  const dispatch = useDispatch();

  // state

  const authStatus = useSelector((state) => state.auth.status);
  const loading = authStatus == "loading";
  const {id, email} = useSelector((state) => state.auth.data);

  // acquire accessToken

  const {instance, accounts} = useMsal();
  const tokenRequestConfig = {
    account: accounts[0],
    scopes: [
      "https://capla360.onmicrosoft.com/api/capla.read",
      "https://capla360.onmicrosoft.com/api/capla.write",
    ],
  };

  // handler

  async function handleClick() {
    const {accessToken} = await instance.acquireTokenSilent(tokenRequestConfig);
    dispatch(fetchUserId({accessToken}));
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleClick}
      >
        User Id
        {loading && (
          <CircularProgress
            className={classes.progress}
            size={20}
            color="inherit"
          />
        )}
      </Button>
      {id}
    </div>
  );
}
