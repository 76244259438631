import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box, Typography} from "@mui/material";

import useSelectedIssue from "Features/issues/useSelectedIssue";
import SelectorIssueset from "Features/issues/components/SelectorIssueset";
import DataGridIssues from "Features/issues/components/DataGridIssues";
import ToolbarIssuesMain from "Features/issues/components/ToolbarIssuesMain";
import SectionOutletIssueDetail from "Features/issues/components/SectionOutletIssueDetail";
import useIssuesetsInScope from "Features/issues/useIssuesetsInScope";
import Stretch from "Features/ui/components/Stretch";
import ButtonMoreActionsIssueSet from "Features/issues/components/ButtonMoreActionsIssueSet";

export default function SceneIssues() {
  // params

  const {caplaEditor, scene, viewer} = useOutletContext();

  // strings

  const title = "Notes";

  // data

  const [_, __, modelsIdsHash] = useIssuesetsInScope();
  const selectedIssue = useSelectedIssue();
  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const openSectionIssueDetailInOutlet = useSelector(
    (s) => s.issues.openSectionIssueDetailInOutlet
  );

  // helpers

  const fullscreenHash = fullscreen ? "1" : "0";

  // helpers - openSections

  const openSections = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(openSections).join("-");

  return (
    <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
      {openSectionIssueDetailInOutlet ? (
        <Box sx={{width: 1, height: 1}}>
          <SectionOutletIssueDetail
            caplaEditor={caplaEditor}
            scene={scene}
            viewer={viewer}
            issue={selectedIssue}
          />
        </Box>
      ) : (openSections.outlet ? (        
        <Box sx={{width: 1, height: 1}}>
          <Box sx={{height: "58px"}} />
          <Box sx={{p: 1, pl: "16px", pt: "12px", width: 1}}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pr: 2,
            }}
          >
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Box sx={{px: 3, mt: 1, width: "240px"}}>
                <SelectorIssueset scene={scene} />
              </Box>
              <Box sx={{mt: 1}} >
                <ButtonMoreActionsIssueSet sceneId={scene?.id} />
              </Box>
            </Box>
          </Box>
          <ToolbarIssuesMain scene={scene} />
          <Stretch propDependency={modelsIdsHash + osHash + fullscreenHash}>
            <Box sx={{height: 1, width: 1}}>
              <DataGridIssues caplaEditor={caplaEditor} scene={scene} />
            </Box>
          </Stretch>
        </Box>
      ) : (<></>))}
    </Box>
  );
}
