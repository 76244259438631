import {useState} from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import {useSelector, useDispatch} from "react-redux";

import { Box, Snackbar, Alert } from "@mui/material";

import {setSelectedIssuesListingId} from "../issuesSlice";

import SelectorAutocompleteWithCreate from "Components/SelectorAutocompleteWithCreate";

import useSceneListings from "Features/listings/useSceneListings";
import useAccessToken from "Features/auth/useAccessToken";
import { createListing } from "Features/listings/listingsSlice";
import useListingtypes from "Features/listingtypes/useListingtypes";
import { useEffect } from "react";

export default function SelectorIssueset({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const scopeString = "Notebook";
  const createLabel = "Nouveau notebook";
  const messageS = "Nouveau notebook créé !";

  // data

  const issuesets = useSceneListings(scene?.id).filter(
    (l) => l.listingType === "issueset"
  ).sort((a, b) => a.name?.localeCompare(b.name));
  const id = useSelector((s) => s.issues.selectedIssuesListingId);
  const listingTypes = useListingtypes();

  // state

  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  // helpers

  const type = listingTypes.find((t) => t.id === "issueset");
  const selectedIssueset = issuesets.find((s) => s.id === id);

  // handlers

  function handleInputChange() {
    if (!open) setOpen(true);
  }

  function handleChange(v) {
    dispatch(setSelectedIssuesListingId(v?.id));
    setOpen(false);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSnackClose() {
    setOpenSnack(false);
  }

  async function handleNewOption(option) {
    setOpen(false);
    const listing = {
      listingType: type?.id,
      name: option.name,
      description: "",
      scenes: [scene.id],
    };
    const result = await dispatch(
      createListing({accessToken, listing, scene})
    );
    const {listing: newListing} = unwrapResult(result);
    dispatch(setSelectedIssuesListingId(newListing?.id));
    setOpenSnack(true);
  }

  // effect

  useEffect(() => {
    if (issuesets.length > 0 && !id)
      dispatch(setSelectedIssuesListingId(issuesets[0].id));
  }, [issuesets.length])

  return (
    <Box sx={{width: 1, display: "flex"}}>
      <SelectorAutocompleteWithCreate
        onClose={handleClose}
        name={scopeString}
        value={selectedIssueset ? selectedIssueset : null}
        options={issuesets}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onNewOption={handleNewOption}
        createLabel={createLabel}
        canCreate={true}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{width: "100%"}}
        >
          {messageS}
        </Alert>
      </Snackbar>
    </Box>
  );
}
