import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  setViewMode,
  setShow3D,
  setMultiviews,
} from "Features/viewer3D/viewer3DSlice";

export default function ButtonToggle2D3D({editor3d, viewer}) {
  const dispatch = useDispatch();
  const cam1deltaY = 0.2;
  // strings

  const view2DString = "2D";
  const view3DString = "3D";
  const viewMixString = "MIX";

  // data

  const viewMode = useSelector((s) => s.viewer3D.viewMode);
  const show3D = useSelector((s) => s.viewer3D.show3D);
  //const cam2Height = useSelector((s) => s.viewer3D.cam2Height);
  //const cutAtHeight = useSelector((s) => s.viewer3D.cutAtHeight);
  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const isViewerFullScreen = useSelector((s) => s.viewer3D.isViewerFullScreen);

  // helpers - MIX

  const isMixEnabled = fullscreen || isViewerFullScreen || viewer;

  // helpers

  let mode;
  if (viewMode === "MULTIVIEWS" && !show3D) {
    mode = "2D";
  } else if (viewMode === "MULTIVIEWS" && show3D) {
    mode = "MIX";
  } else if (viewMode === "3D") {
    mode = "3D";
  } else {
    mode = "3D";
  }

  // helpers - camera switch

  // function cam2ToCam() {
  //   const cam2 = editor3d?.cameras.camera2;
  //   const cam = editor3d?.cameras.camera;
  //   const orbit = editor3d?.controls.orbitControls;
  //   const orbit2 = editor3d?.controls.orbitControls2;

  //   cam.position.set(cam2.position.x, cam2.position.y, cam2.position.z);
  //   orbit.target.set(orbit2.target.x, orbit2.target.y, orbit2.target.z);
  //   orbit.update();

  //   editor3d?.cameras.activeCamera = editor3d?.cameras.camera;
  // }

  function camToCam2() {
    const cam2 = editor3d?.cameras.camera2;
    const cam = editor3d?.cameras.camera;
    const orbit = editor3d?.controls.orbitControls;
    const orbit2 = editor3d?.controls.orbitControls2;

    //let cam2H = typeof cam2Height === "number" ? cam2Height : cam.position.y;
    //if (cutAtHeight) cam2H = cam.position.y;
    let cam2H = cam.position.y;

    cam2.position.set(cam.position.x, cam2H, cam.position.z);
    orbit2.target.set(orbit.target.x, orbit.target.y, orbit.target.z);
    orbit2.update();

    if (editor3d?.multiviews.cutCam1) {
      editor3d?.cameras.camera1.position.setY(cam2H + cam1deltaY + 10.73);
    } else {
      editor3d?.cameras.camera1.position.setY(500);
    }
  }

  // handlers
  function handleViewChange(v) {
    if (v === "MIX") {
      if (viewMode === "3D") {
        camToCam2();
      }
      editor3d?.setViewMode("MULTIVIEWS");
      editor3d?.multiviews.show3D();
      dispatch(setViewMode("MULTIVIEWS"));
      dispatch(setShow3D(true));
      dispatch(setMultiviews(true));
      //editor3d?.sceneEditor.setShowEdges(true);
      editor3d?.controls.switchTo("ORBIT2");
      editor3d?.clipper.setCut3DWithActiveHCP(false);
      editor3d?.clipper.updateMaterials();
      editor3d?.imageOverlay.hide();
    } else if (v === "2D") {
      editor3d?.setViewMode("MULTIVIEWS");
      editor3d?.multiviews.hide3D();
      dispatch(setShow3D(false));
      dispatch(setViewMode("MULTIVIEWS"));
      dispatch(setMultiviews(true));
      //editor3d?.sceneEditor.setShowEdges(true);
      editor3d?.controls.switchTo("ORBIT1");
      editor3d?.clipper.setCut3DWithActiveHCP(false);
      editor3d?.clipper.updateMaterials();
      editor3d?.imageOverlay.hide();
    } else if (v === "3D") {
      //cam2ToCam();
      editor3d?.sceneEditor.setCamFromCam1();
      editor3d?.setViewMode("3D");
      dispatch(setViewMode("3D"));
      dispatch(setMultiviews(false));
      editor3d?.controls.switchTo("ORBIT");
      editor3d?.clipper.setCut3DWithActiveHCP(true);
      editor3d?.clipper.updateMaterials();
    } else {
      //dispatch(setViewMode("3D"));
    }
  }

  return (
    <Box>
      <ToggleButtonGroup
        exclusive
        //disabled={!showViewer}
        value={mode}
        onChange={(_, v) => handleViewChange(v)}
        size="small"
        sx={{height: 30, bgcolor: "common.caplaBlack", color: "common.white"}}
      >
        <ToggleButton
          value="2D"
          sx={{
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Tooltip title={view2DString}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{fontSize: 13, color: "common.white"}}>
                2D
              </Typography>
            </Box>
          </Tooltip>
        </ToggleButton>

        <ToggleButton
          value="3D"
          sx={{
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Tooltip title={view3DString}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{fontSize: 13, color: "common.white"}}>
                3D
              </Typography>
            </Box>
          </Tooltip>
        </ToggleButton>
        {isMixEnabled && (
          <ToggleButton
            value="MIX"
            sx={{
              "&.Mui-selected": {
                bgcolor: "common.darkGrey",
                "&:hover": {
                  bgcolor: "common.darkGrey",
                },
              },
            }}
          >
            <Tooltip title={viewMixString}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{fontSize: 13, color: "common.white"}}>
                  MX
                </Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </Box>
  );
}
