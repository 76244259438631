import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {
  alpha,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Typography,
  Box,
} from "@mui/material";

import CallToActionLoadModel from "./CallToActionLoadModel";

// import {createStoredModelService} from "../services";

import {
  setSelection,
  setDetailSection,
  toggleModelVisibility,
  createModel,
  setConfiguring,
  setConfigCenter,
} from "Features/viewer3D/viewer3DSlice";
import FileSelectorButton from "Features/files/components/FileSelectorButton";


export default function ToolModelsManager({editor3d, type}) {
  // type : IMAGE, IFC
  // editor3d : main editor3d
  // editor2 : configuration editor3d

  const dispatch = useDispatch();

  // strings

  const IFCModelsString = "Modèles IFC";
  const imagesString = "Images";

  // data

  const allModels = useSelector((state) => state.viewer3D.models);
  const models = allModels.filter((model) => model.type === type);

  // helpers

  const modelsIsEmpty = models.length === 0;
  const title = type === "IMAGE" ? imagesString : IFCModelsString;

  // handlers

  async function handleFileChange(file) {
    if (file) {
      const extension = file.name.split(".").pop();
      if (type === "IMAGE" && file.type === "image/png") {
        const result = await dispatch(
          createModel({file, type: "IMAGE"})
        );
        const {model} = unwrapResult(result);
        const editorEntity = editor3d?.getEntityById("IMAGE_MODEL", model.id);

        dispatch(setConfiguring(true));
        dispatch(setConfigCenter(true));

        //editor3d.configCenter.configEntity({entity:editorEntity});
        editor3d?.switchToViewMode("VIEW_2D_CONFIG", {target: editorEntity});
      } else if (type === "IFC" && extension === "ifc") {
        dispatch(createModel({file, type: "IFC"}));

        // IMPORTANT : the AddModel dispatch is sent from the onEntityAdded editor3d prop.
        // indeed, for ifc Model, we need to compute the position after it is loaded...
      } else {
        console.log("Please select a valid file");
      }
    }
  }

  function handleModelClick(model) {
    const selectionTypes = {
      IFC: "IFC_MODEL",
      IMAGE: "IMAGE_MODEL",
    };
    const entity = {
      type: selectionTypes[model.type],
      model,
    };
    dispatch(setDetailSection(true));
    dispatch(setSelection(entity));
    //editor3d.selectedEntity = editor3d.getEntityById(entity.type, model.id);
  }

  function handleSwitchChange(event, modelID) {
    const checked = event.target.checked;
    dispatch(toggleModelVisibility(modelID));
    let entity;
    if (type === "IFC") {
      entity = editor3d?.getEntityById("IFC_MODEL", modelID);
      entity?.toggleVisibility();
    }
    if (type === "IMAGE") {
      entity = editor3d?.getEntityById("IMAGE_MODEL", modelID);
      entity?.toggleVisibility();
    }
  }

  return (
    <Paper
      sx={{
        width: 1,
        background: (theme) => alpha(theme.palette.common.white, 0.8),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          p: 1,
        }}
      >
        <Typography variant="body2">
          <b>{title}</b>
        </Typography>
        <FileSelectorButton variant="icon" onFileChange={handleFileChange} />
      </Box>
      {modelsIsEmpty ? (
        <CallToActionLoadModel type={type} />
      ) : (
        <List dense sx={{maxHeight: 400, overflow: "auto"}}>
          {models.map((model) => {
            return (
              <ListItem
                key={model.id}
                button
                onClick={() => handleModelClick(model)}
              >
                <ListItemText>
                  <Typography noWrap>{model.name}</Typography>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Switch
                    size="small"
                    checked={!model.hidden}
                    onChange={(event) => handleSwitchChange(event, model.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
    </Paper>
  );
}
