import React from "react";

import {Box} from "@mui/material";
import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormProgressRecord({progressRecord, onChange}) {
  // strings

  const nameS = "Name";
  const startS = "Start";
  const endS = "End";

  // template

  const template = {
    key: "kvfProgressRecord",
    fields: [
      {key: "name", type: "text", name: nameS},
      {key: "startAt", type: "date", name: startS},
      {key: "endAt", type: "date", name: endS},
    ],
  };

  return progressRecord ? (
    <Box
      sx={{
        minWidth: 400,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <KeyValueForm
        item={progressRecord}
        template={template}
        onChange={onChange}
        sizeKey={4}
      />
    </Box>
  ) : (
    <div />
  );
}
