import {useSelector, useDispatch} from "react-redux";

import {useState} from "react";

import {
  setDatagridIsInEditMode,
  setEditedElementTypesGroup,
} from "../elementTypesSlice";

import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";

import {Box, Button} from "@mui/material";

import SwitchShowElementTypesReorderHelpers from "./SwitchShowElementTypesReorderHelpers";
import ButtonUpdateEditedElementTypesGroupNums from "./ButtonUpdateEditedElementTypesGroupNums";

export default function ToolbarEditDatagridElementTypesGroup() {
  const dispatch = useDispatch();

  // strings

  const editS = "Editer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const datagridIsInEditMode = useSelector(
    (s) => s.elementTypes.datagridIsInEditMode
  );
  //const ressources = useRessourcesByScene(scene);

  const editedElementTypesGroup = useSelector(
    (s) => s.elementTypes.editedElementTypesGroup
  );

  const selectedElementTypesGroup = useSelectedElementTypesGroup();

  // data - methods

  const updateElementTypesGroup = useUpdateElementTypesGroup();

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  function handleEdit() {
    dispatch(setDatagridIsInEditMode(true));
    dispatch(
      setEditedElementTypesGroup({
        group: selectedElementTypesGroup,
        sortByNum: true,
      })
    );
  }

  function handleCancel() {
    dispatch(setDatagridIsInEditMode(false));
    dispatch(setEditedElementTypesGroup(null));
  }

  async function handleSave() {
    setLoading(true);
    await updateElementTypesGroup(editedElementTypesGroup);
    setLoading(false);
    dispatch(setDatagridIsInEditMode(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        px: 1,
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        bgcolor: (theme) =>
          datagridIsInEditMode ? theme.palette.warning.main : "white",
      }}
    >
      {datagridIsInEditMode ? (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <SwitchShowElementTypesReorderHelpers />
            <ButtonUpdateEditedElementTypesGroupNums />
          </Box>
          <Box sx={{display: "flex", alignItems: "center", color: "white"}}>
            <Button
              size="small"
              color="inherit"
              onClick={handleCancel}
              sx={{mr: 1}}
            >
              {cancelS}
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={handleSave}
              disabled={loading}
            >
              {saveS}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{color: "text.secondary"}}>
          <Button size="small" color="inherit" onClick={handleEdit}>
            {editS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
