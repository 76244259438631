import {useState} from "react";
import {useDispatch} from "react-redux";

import {Box} from "@mui/material";

import ListItemIssueInViewer from "./ListItemIssueInViewer";

import {setImageOverlayMode} from "Features/viewer3D/viewer3DSlice";
// import {setImageOverlayProps} from "Features/viewer3D/viewer3DSlice";

export default function ListIssuesInViewer({issues, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  // const imageOverlayProps = useSelector((s) => s.viewer3D.imageOverlayProps);
  // state

  const [selection, setSelection] = useState(null);

  // handlers

  function handleItemClick(issue) {
    console.log("issue", issue);
    if (issue.fromViewMode === "2D" || issue.fromViewMode === "3D") {
      if (selection === issue.id) {
        setSelection(null);
        caplaEditor?.editor3d?.imageOverlay.hide();
      } else {
        setSelection(issue.id);
        caplaEditor?.editor3d?.imageOverlay.show();
        caplaEditor?.editor3d?.imageOverlay.setImage(issue.imageUrl);
        const camera = issue.context?.camera;
        if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);
      }
    } else if (issue.fromViewMode === "PDF") {
      if (selection === issue.id) {
        setSelection(null);
        dispatch(setImageOverlayMode(false));
      } else {
        setSelection(issue.id);
        // const {windowPointTL, windowPointBR, scrollLeft, scrollTop} =
        //   caplaEditor?.editorPdf?.setPdfContext(issue.context.pdfContext);
        // const {x, y} = windowPointTL;
        // const width = windowPointBR.x - x;
        // const height = windowPointBR.y - y;

        caplaEditor?.editorPdf?.annotationsManager.createIssueAnnotation(issue);
      }
    }
  }

  return (
    <Box sx={{"&>*": {mb: 1}}}>
      {issues.map((issue) => {
        const selected = selection === issue.id;
        return (
          <ListItemIssueInViewer
            key={issue.id}
            issue={issue}
            selected={selected}
            onClick={handleItemClick}
          />
        );
      })}
    </Box>
  );
}
