import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  Chip,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import SliderImageOpacity from "./SliderImageOpacity";
import SliderImageTranslate from "./SliderImageTranslate";
import SliderImageCrop from "./SliderImageCrop";

import {
  setSelectedPdfModelId,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import {setJump} from "Features/pdf/pdfSlice";
// import ToggleShowMainPart from "Features/viewer3D/components/ToggleShowMainPart";

export default function SectionZoneOverviewInLeftPanel({zone, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const noSelectedZoneS = "Pas de fond de plan sélectionné";
  const focus3dS = "3D";
  const focusPdfS = "Plan PDF";
  const originS = "Image d'origine";
  const mainPartS = "Image modifiée";

  // data

  const model = useSelector((s) => s.viewer3D.models).find(
    (m) => m.id === zone?.imageModelId
  );

  // helpers

  const entity = caplaEditor?.editor3d?.getEntity(model?.id);
  const mode = model?.showMainPart ? "MAIN_PART" : "ORIGIN";

  // handlers

  function handleFocusPdf() {
    dispatch(setJump({page: zone.pageNumber, measId: zone.id}));
    dispatch(setSelectedPdfModelId(zone.pdfModelId));
    caplaEditor?.editorPdf.annotationsManager.goToZone(zone?.id);
  }

  const handleFocus3d = async () => {
    if (typeof model.showMainPart !== "boolean") {
      const updatedModel = {...model, hidden: false};
      const options = {hideImageModels: true, loadTexture: true};
      await caplaEditor?.editor3d.loader.loadInitialModel(model, options);
      dispatch(updateModel({updatedModel, sync: false}));
    }
    entity?.show();
    caplaEditor?.editor3d.sceneEditor.focusImageModel(model);
  };

  function handleModeChange(mode) {
    const checked = mode === "MAIN_PART";
    const updatedModel = {...model, showMainPart: checked};
    dispatch(updateModel({updatedModel, sync: false}));
    if (checked) {
      if (entity?.showMainPart) entity.showMainPart();
    } else {
      if (entity?.hideMainPart) entity.hideMainPart();
    }
  }

  return (
    <Box sx={{width: 1, height: 1, overflow: "hidden"}}>
      {!zone && (
        <Box
          sx={{
            p: 1,
            pl: 2,
            display: "flex",
            width: 1,
            //justifyContent: "space-between",
          }}
        >
          <Typography sx={{p: 1, fontSize: 13, color: "text.secondary"}}>
            {noSelectedZoneS}
          </Typography>
        </Box>
      )}
      {zone && (
        <Box sx={{width: 1, display: "flex", alignItems: "center", p: 1}}>
          <Typography variant="h6" noWrap>
            {zone?.name}
          </Typography>
        </Box>
      )}
      {zone && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "primary.flash",
            pl: 1,
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <Chip
            size="small"
            onClick={handleFocus3d}
            label={focus3dS}
            icon={<Focus />}
          />
          <Chip
            size="small"
            onClick={handleFocusPdf}
            label={focusPdfS}
            icon={<Focus />}
          />
        </Box>
      )}
      {zone && (
        <Box sx={{display: "flex", flexDirection: "column", pt: 2}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mode === "ORIGIN"}
                onChange={() => handleModeChange("ORIGIN")}
                size="small"
                sx={{height: "25px"}}
              />
            }
            label={
              <Typography variant="body2" fontWeight={"bold"}>
                {originS}
              </Typography>
            }
            sx={{height: "25px", p: 2}}
          />
          <Divider />
          <FormControlLabel
            control={
              <Checkbox
                checked={mode === "MAIN_PART"}
                onChange={() => handleModeChange("MAIN_PART")}
                size="small"
                sx={{height: "25px"}}
              />
            }
            label={
              <Typography variant="body2" fontWeight={"bold"}>
                {mainPartS}
              </Typography>
            }
            sx={{height: "25px", px: 2, pt: 1}}
          />
        </Box>
      )}
      {/* <Box sx={{display: "flex", width: 1, justifyContent: "center", my: 1}}>
          <ToggleShowMainPart modelId={zone?.imageModelId} editor3d={caplaEditor?.editor3d} />
        </Box> */}
      {zone && (
        <Box
          sx={{
            width: 1,
            color: mode === "MAIN_PART" ? "inherit" : "text.secondary",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
              pt: 1,
            }}
          >
            <SliderImageOpacity
              editor3d={caplaEditor?.editor3d}
              modelId={zone?.imageModelId}
              enabled={mode === "MAIN_PART"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            <SliderImageTranslate
              editor3d={caplaEditor?.editor3d}
              modelId={zone?.imageModelId}
              enabled={mode === "MAIN_PART"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            <SliderImageCrop
              editor3d={caplaEditor?.editor3d}
              modelId={zone?.imageModelId}
              enabled={mode === "MAIN_PART"}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
