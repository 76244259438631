import React from "react";

import {Box, IconButton} from "@mui/material";
import {
  RadioButtonUnchecked as Unchecked,
  Circle as Checked,
} from "@mui/icons-material";

import theme from "Styles/theme";

export default function ColorSelector({color, onChange, mobile}) {
  let colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.common.caplaBlack,
  ];
  if (mobile)
    colors = [theme.palette.primary.main, theme.palette.common.caplaBlack];

  const colorIndex = colors.indexOf(color);

  return (
    <Box
      sx={{
        bgcolor: "common.white",
        border: "1px solid white",
        borderRadius: "4px",
        display: "flex",
      }}
    >
      {colors.map((color, index) => {
        const selected = colorIndex === index;
        return (
          <IconButton
            size="small"
            key={color}
            sx={{color}}
            onClick={() => onChange(color)}
          >
            {selected ? (
              <Checked color="inherit" />
            ) : (
              <Unchecked color="inherit" />
            )}
          </IconButton>
        );
      })}
    </Box>
  );
}
