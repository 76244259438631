import Scene from "Pages/Scene";
import caplaDB, {SCENE_DO} from "../db";
import {fetchStoredFile} from "../files/services";
import bimbox from "../assets/bimbox.png";

// create

export async function createStoredScene({storedScene}) {
  const db = await caplaDB.dbPromise;

  const store = db.transaction(SCENE_DO, "readwrite").objectStore(SCENE_DO);

  const oldScene = await store.get(storedScene.clientId);
  if (oldScene) {
    store.put(storedScene);
  } else {
    store.add(storedScene);
  }
  return storedScene;
}

// used when scene backup is triggered.

export async function fetchStoredScene(sceneClientId) {
  if (!sceneClientId) return;
  const db = await caplaDB.dbPromise;
  // get scene
  const store = db.transaction(SCENE_DO, "readonly").objectStore(SCENE_DO);
  const scene = await store.get(sceneClientId);
  // get file
  let file;
  if (scene?.imageFileClientId)
    file = await fetchStoredFile(scene.imageFileClientId);

  return {scene, file};
}

// used at initial load.

export async function fetchStoredScenes() {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(SCENE_DO, "readonly").objectStore(SCENE_DO);
  const scenes = await store.getAll();
  const updatedScenes = await Promise.all(
    scenes.map(async (scene) => {
      let imageClientUrl = bimbox;
      if (scene.imageFileClientId) {
        const file = await fetchStoredFile(scene.imageFileClientId);
        imageClientUrl = URL.createObjectURL(file);
      }
      return {...scene, imageClientUrl};
    })
  );
  return updatedScenes;
}

// update

export async function updateStoredScene({scene}) {
  // scene update
  const db = await caplaDB.dbPromise;
  const store = db.transaction(SCENE_DO, "readwrite").objectStore(SCENE_DO);
  const oldStoredScene = await store.get(scene.clientId);
  const newStoredScene = {
    ...oldStoredScene,
    ...scene,
  };
  store.put(newStoredScene);
  return newStoredScene;
}
