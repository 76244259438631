import React from "react";

import {useNavigate} from "react-router-dom";

import {
  Box,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";

import {ArrowDropDown as Down, ArrowDropUp as Up} from "@mui/icons-material";

import useSceneModule from "../useSceneModule";

export default function MenuItemSceneChild({itemKey, icon, name, path}) {
  const navigate = useNavigate();
  const sceneModule = useSceneModule();

  // helper

  const selected = itemKey === sceneModule;

  // handlers

  function handleClick(path) {
    navigate(path);
  }

  return (
    <Box sx={{width: 1}}>
      <ListItemButton
        onClick={() => handleClick(path)}
        selected={selected}
        sx={{pl: 3}}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          sx={{my: 0}}
          primary={name}
          primaryTypographyProps={{
            fontSize: (theme) => theme.typography.body2.fontSize,
          }}
        />
      </ListItemButton>
    </Box>
  );
}
