import {useState, useEffect} from "react";

import {Box, TextField, Paper, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import ContainerEditSector from "./ContainerEditSector";
import {nanoid} from "@reduxjs/toolkit";

export default function ContainerEditBuilding({
  sectors,
  onChange,
  onAddSector,
  onDeleteSector,
}) {
  const building = sectors[0]?.building;

  const [tempBuilding, setTempBuilding] = useState(building);
  useEffect(() => {
    setTempBuilding(building);
  }, [building]);

  // helpers

  const sortedSectors = sectors
    .map((s) => ({...s, z: s.z ?? 0}))
    .sort((a, b) => b.z - a.z);

  function handleChange(sector) {
    const newSectors = sectors.map((s) => {
      if (s.id === sector.id) {
        return sector;
      } else {
        return s;
      }
    });
    onChange(newSectors);
  }

  function handleAddSector() {
    const maxZ = Math.max(...sectors.map((s) => s.z));
    const newSector = {code: "R+", id: nanoid(), building, z: maxZ + 3};

    onAddSector(newSector);
  }

  function handleDeleteSector(sector) {
    onDeleteSector(sector);
  }

  function handleBuildingChange(e) {
    setTempBuilding(e.target.value);
  }

  function handleBuildingSave() {
    const newSectors = sectors.map((s) => ({...s, building: tempBuilding}));
    onChange(newSectors);
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          bgcolor: "primary.flash",
          color: "common.white",
          borderRadius: "50%",
          width: 24,
          height: 24,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton size="small" color="inherit" onClick={handleAddSector}>
          <Add fontSize="small" />
        </IconButton>
      </Box>
      {sortedSectors.map((sector) => (
        <Box sx={{py: 1}}>
          <ContainerEditSector
            key={sector.id}
            sector={sector}
            onChange={handleChange}
            onDelete={() => handleDeleteSector(sector)}
          />
        </Box>
      ))}
      <Box>
        <Paper sx={{p: 1, bgcolor: "grey.800", color: "common.white"}}>
          <TextField
            size="small"
            value={tempBuilding}
            onChange={handleBuildingChange}
            onBlur={handleBuildingSave}
            fullWidth
            InputProps={{
              sx: {
                color: "common.white",
                fontSize: (theme) => theme.typography.body2.fontSize,
              },
            }}
          />
        </Paper>
      </Box>
    </Box>
  );
}
