import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setOpenSections} from "Features/ui/uiSlice";

import {Box, Paper, Typography, ListItemButton} from "@mui/material";
import ImageBasic from "Features/images/components/ImageBasic";

import FieldSceneProdStatus from "../../FieldSceneProdStatus";
import bimbox from "Assets/bimbox.svg";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {setSceneIdClickedFromScenes} from "Features/overviewer/overviewerSlice";

export default function ContainerSceneOverview({scene}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isStagingEnv = useIsStagingEnv();
  const isRemoteScene = Boolean(scene?.id);

  // helpers

  const name = scene?.title;
  const description = scene?.description ? scene.description : "";
  const imageUrl = scene?.imageUrl ? scene?.imageUrl : bimbox;
  const status = scene?.prodStatus;
  const version = scene?.version;
  const clientReference = scene?.clientReference;

  let subtitle = `[v${version}] ${description}`;
  if (!version) subtitle = "Bimbox éphémère";

  // handlers

  function handleClick() {
    const sceneId = scene.id ? scene.id : scene.clientId;

    dispatch(setSceneIdClickedFromScenes(sceneId));

    let url = `/bimboxes/${sceneId}/measurements`;
    if (isStagingEnv) url = url + `?env=staging`;
    navigate(url);
    dispatch(
      setOpenSections({
        outlet: false,
        fixedViewersBox: true,
        viewer3D: false,
        callToAction: false,
      })
    );
  }

  return (
    <ListItemButton
      disableGutters
      sx={{width: 1, p: 0, m: 0}}
      onClick={handleClick}
    >
      <Paper sx={{bgcolor: "common.caplaBlack", width: 1}} elevation={6}>
        <Box sx={{height: 240, position: "relative"}}>
          <ImageBasic url={imageUrl} />
          <Box sx={{position: "absolute", top: 8, right: 8, zIndex: 2}}>
            <FieldSceneProdStatus value={status} readOnly={true} size="small" />
          </Box>
          <Box
            sx={{
              p: 1,
              pt: 12,
              position: "absolute",
              bottom: 0,
              width: 1,
              background: (theme) =>
                `linear-gradient(to bottom,rgba(255, 255, 255, 0) 0%,${theme.palette.common.caplaBlack}) 100%`,
            }}
          >
            <Typography sx={{color: "common.white", fontWeight: "bold"}}>
              {name}
            </Typography>
            <Typography sx={{color: "common.white"}}>{subtitle}</Typography>
            {clientReference && (
              <Typography
                sx={{color: "common.white", fontSize: 12, fontStyle: "italic"}}
              >
                {"# " + clientReference}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </ListItemButton>
  );
}
