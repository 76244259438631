function getNomenclatureMap(nomenclature, fields, fieldLabel) {
  let map = {};

  nomenclature.forEach((item, index) => {
    let keyFromFieldsValues = fields.map((field) => item[field]).join("-");
    map[keyFromFieldsValues] = {index, [fieldLabel]: item[fieldLabel], item};
  });

  return map;
}

export default function mergeTwoMeasNomenclatures(
  nom1,
  nom2,
  fields,
  fieldLabel
) {
  //
  if (nom1.length === 0) return nom2;
  //
  const nomMap1 = getNomenclatureMap(nom1, fields, fieldLabel);
  const nomMap2 = getNomenclatureMap(nom2, fields, fieldLabel);
  //
  const keys1 = Object.keys(nomMap1);
  const keys2 = Object.keys(nomMap2);
  //
  const keys1Only = keys1.filter((key) => !keys2.includes(key));
  const keys2Only = keys2.filter((key) => !keys1.includes(key));
  const keys1And2 = keys1.filter((key) => keys2.includes(key));
  //
  const items1Only = keys1Only.map((key) => nomMap1[key].item);
  const items2Only = keys2Only.map((key) => nomMap2[key].item);
  //
  // items1And2
  //
  const items1And2 = keys1And2.map((key) => {
    const item1 = nomMap1[key].item;
    const item2 = nomMap2[key].item;
    return {
      ...item1,
      [fieldLabel]: [...new Set([...item1[fieldLabel], ...item2[fieldLabel]])],
    };
  });
  //
  return [...items1Only, ...items2Only, ...items1And2];
}
