import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";

import {Vector3} from "three";

import {Box} from "@mui/material";

import MeasuresTable from "./MeasuresTable";

import TitleCloseHeader from "Components/TitleCloseHeader";

import {setMode} from "Features/viewer3D/viewer3DSlice";

export default function ProcessMeasure({editor3d, onClose}) {
  const dispatch = useDispatch();
  // strings

  const title = "Mesure";

  // refs

  const measureRef = useRef();

  // local state

  const [points, setPoints] = useState([]);
  const [pointsArray, setPointsArray] = useState([[]]);
  const [area, setArea] = useState();
  const distances = points.reduce((acc, current, index) => {
    if (index >= 1) acc.push(computeDistance(current, points[index - 1]));
    return acc;
  }, []);

  // helpers

  function computeDistance(point1, point2) {
    if (point1 && point2) {
      return Math.sqrt(
        (point1.x - point2.x) ** 2 +
          (point1.y - point2.y) ** 2 +
          (point1.z - point2.z) ** 2
      );
    } else {
      return undefined;
    }
  }

  // editor3d listeners

  function handleEditorClick({sceneP, pickedFaceNormal}) {
    if (measureRef.current && !measureRef.current.stop) {
      const normalV = new Vector3(
        pickedFaceNormal.x,
        pickedFaceNormal.y,
        pickedFaceNormal.z
      );
      const pointV = new Vector3(sceneP.x, sceneP.y, sceneP.z);
      pointV.add(normalV.normalize().multiplyScalar(0.01)); // we add 1cm to see the lines drawn on a surface.
      const _point = {x: pointV.x, y: pointV.y, z: pointV.z};

      const index = measureRef.current.shapesCount;

      const point = measureRef.current.addPoint(_point);

      setPointsArray((pointsArray) => {
        const newPointsArray = [...pointsArray];
        if (newPointsArray[index]) {
          newPointsArray[index].push(point);
        } else {
          newPointsArray[index] = [point];
        }

        return newPointsArray;
      });

      setPoints((points) => {
        const newPoints = [...points];
        newPoints.push(point);
        return newPoints;
      });
    }
  }

  function handleEditorMove({sceneP, normal}) {
    const normalV = new Vector3(normal.x, normal.y, normal.z);
    const pointV = new Vector3(sceneP.x, sceneP.y, sceneP.z);
    pointV.add(normalV.normalize().multiplyScalar(0.005)); // we add 5mm to see the lines drawn on a surface.
    const point = {x: pointV.x, y: pointV.y, z: pointV.z};

    measureRef.current?.drawNextPoint(point);
  }

  function handleClosingLine({area}) {
    console.log("area", area);
    setArea(area);
  }

  // objects creation

  useEffect(() => {
    measureRef.current = editor3d?.createMeasure();
    if (measureRef.current) measureRef.current.onClosingLine = handleClosingLine;
    //editor3d.measure = measureRef.current.object;
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
    if (editor3d) editor3d.onProcessEditorMove = handleEditorMove;
  }, []);

  // helpers

  function endProcess() {
    dispatch(setMode("PICKING"));
    editor3d?.switchToMode("PICKING");
  }
  // handlers

  function handleClose() {
    measureRef.current.clear();
    endProcess();
    onClose();
  }

  // unmount

  useEffect(() => {
    return () => {
      measureRef.current.clear();
    };
  }, []);

  return (
    <Box>
      <TitleCloseHeader title={title} onClose={handleClose} divider={true} />
      <Box sx={{p: 1, maxHeight: 500, overflow: "auto"}}>
        <MeasuresTable
          points={points}
          pointsArray={pointsArray}
          distances={distances}
          area={area}
        />
      </Box>
    </Box>
  );
}
