import {useEffect} from "react";

import {fetchRemoteScenes} from "./scenesSlice";

import {useSelector, useDispatch} from "react-redux";
import {setIsSyncing} from "Features/sync/syncSlice";

import useAccessToken from "Features/auth/useAccessToken";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

/*
 * call to get remote scenes related to the user.
 * used by the home page.
 */

export default function useFetchRemoteScenes() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // return

  const getRemoteScenes = async () => {
    if (accessToken) {
      dispatch(setIsSyncing(true));
      await dispatch(fetchRemoteScenes({accessToken}));
      dispatch(setIsSyncing(false));
    }
  };

  return [getRemoteScenes, accessToken];
}
