import {client} from "API/capla360";
//
import stateToRemoteArticle from "./utils/stateToRemoteArticle";
import remoteToStateArticle from "./utils/remoteToStateArticle";
import remoteToStateArticlesGroup from "./utils/remoteToStateArticlesGroup";
import stateToRemoteArticlesGroup from "./utils/stateToRemoteArticlesGroup";

// CREATE

export const createArticlesGroupService = async ({
  accessToken,
  articlesGroup,
  fromScopeId,
}) => {
  const sceneId = articlesGroup.sceneId;
  const newArticlesGroup = stateToRemoteArticle(articlesGroup);

  const response = await client.post(
    `scenes/${sceneId}/articlesgroups/`,
    newArticlesGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        fromScope: fromScopeId,
      },
    }
  );

  const remoteArticlesGroup = response.data;
  const item = remoteToStateArticlesGroup(remoteArticlesGroup);
  return {item, fromScopeId};
};

export const createArticleService = async ({accessToken, article}) => {
  const articlesGroupId = article.articlesGroupId;
  const newArticle = stateToRemoteArticle(article);

  const response = await client.post(
    `articlesgroups/${articlesGroupId}/articles/`,
    newArticle,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteArticle = response.data;
  const item = remoteToStateArticle(remoteArticle);
  return {item};
};

export const createArticlesBatchService = async ({accessToken, articles}) => {
  const articlesGroupId = articles[0].articlesGroupId;
  const newArticles = articles.map(stateToRemoteArticle);

  const response = await client.post(`articles/batch/`, newArticles, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      groupid: articlesGroupId,
    },
  });
  const remoteArticles = response.data;
  const items = remoteArticles.map(remoteToStateArticle);
  return {items};
};

//  FETCH

export const fetchArticlesGroupsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/articlesgroups/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteArticlesGroups = response.data;
  const items = remoteArticlesGroups.map(remoteToStateArticlesGroup);
  console.log("[FETCH] articlesGroups", items);
  return {items, sceneId};
};

export const fetchArticlesGroupService = async ({
  accessToken,
  sceneId,
  articlesGroupId,
}) => {
  const response = await client.get(
    `/scenes/${sceneId}/articlesgroups/${articlesGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteArticlesGroup = response.data;
  const item = remoteToStateArticlesGroup(remoteArticlesGroup);
  return {item, sceneId};
};

export const fetchArticlesService = async ({accessToken, articlesGroupId}) => {
  const response = await client.get(
    `/articlesgroups/${articlesGroupId}/articles/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteArticles = response.data;
  const items = remoteArticles.map(remoteToStateArticle);
  return {items, articlesGroupId};
};

//  UPDATE

export const updateArticlesGroupService = async ({accessToken, updates}) => {
  const sceneId = updates.sceneId;
  const remoteUpdates = stateToRemoteArticlesGroup(updates);
  try {
    const response = await client.patch(
      `/scenes/${sceneId}/articlesgroups/${updates.id}/`,
      remoteUpdates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const remoteArticlesGroup = response.data;
    const item = remoteToStateArticlesGroup(remoteArticlesGroup);
    return {item};
  } catch (e) {
    console.log("error", e);
    return {};
  }
};

export const updateArticleService = async ({accessToken, updates}) => {
  const articlesGroupId = updates.articlesGroupId;
  const remoteUpdates = stateToRemoteArticle(updates);
  const response = await client.patch(
    `/articlesgroups/${articlesGroupId}/articles/${updates.id}/`,
    remoteUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const item = remoteToStateArticle(response.data);
  return {item};
};

export const updateArticlesBatchService = async ({
  accessToken,
  updatesBatch,
}) => {
  const updates0 = updatesBatch[0];
  console.log("updates0", updates0);
  const articlesGroupId = updates0.articlesGroupId;
  const remoteUpdatesBatch = updatesBatch.map(stateToRemoteArticle);
  const response = await client.patch(
    `/articles/batch/update/`,
    remoteUpdatesBatch,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        groupid: articlesGroupId,
      },
    }
  );
  const items = response.data.map(remoteToStateArticle);
  return {items};
};

//  DELETE

export const deleteArticlesGroupService = async ({
  accessToken,
  articlesGroupId,
  sceneId,
}) => {
  await client.delete(`/scenes/${sceneId}/articlesgroups/${articlesGroupId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {articlesGroupId};
};

export const deleteAllArticlesService = async ({
  accessToken,
  articlesGroupId,
}) => {
  await client.delete(`/articlesgroups/delete-all-articles/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      groupid: articlesGroupId,
    },
  });
  return {articlesGroupId};
};

export const deleteArticleService = async ({accessToken, article}) => {
  const articlesGroupId = article.articlesGroupId;
  const articleId = article.id;
  await client.delete(
    `/articlesgroups/${articlesGroupId}/articles/${articleId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {articleId};
};

export const deleteArticlesService = async ({
  accessToken,
  articlesIds,
  groupId,
}) => {
  await client.request({
    method: "delete",
    url: "/articles/batch/delete/",
    data: {articlesIds, groupId},
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {articlesIds, groupId};
};
