import {Box} from "@mui/material";

import ButtonDataGridClearSelection from "./ButtonDataGridClearSelection";

export default function SectionDataGridToolbar() {
  return (
    <Box sx={{display: "flex", alignItems: "center", px: 1}}>
      <ButtonDataGridClearSelection />
    </Box>
  );
}
