import React from "react";

import {Box, Typography, TextField} from "@mui/material";

export default function FieldIssueTitleInSelectionPanel({
  title,
  onChange,
  isEditing,
}) {
  // strings

  const titleS = "Title";

  // handlers

  function handleChange(e) {
    const title = e.target.value;
    onChange(title);
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        justifyContent: "center",
        alignItems: "center",
        p: 1,
      }}
    >
      {!isEditing ? (
        <Typography sx={{fontWeight: "bold"}}>{title}</Typography>
      ) : (
        <TextField
          label={<Typography variant="body2">{titleS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          fullWidth
          value={title}
          onChange={handleChange}
        />
      )}
    </Box>
  );
}
