import React from "react";

import {Box, Typography} from "@mui/material";
import {useSelector} from "react-redux";

export default function BoxLibraryName() {
  // strings

  const defaultS = "Bibliothèque par défaut";

  // data

  const name = useSelector((s) => s.library.name);

  // helper

  const label = name ? name : defaultS;

  return (
    <Box sx={{mr: 2}}>
      <Typography variant="h6">{label}</Typography>
    </Box>
  );
}
