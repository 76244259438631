import {useEffect, useState} from "react";

import {Slider, Box, Typography} from "@mui/material";

export default function SliderOpacity({value, onChange}) {
  // handlers

  function handleChange(e) {
    const value = e.target.value;
    onChange(value);
  }

  return (
    <Box
      sx={{
        minWidth: 200,
        px: 1,
        pr: 2,
        display: "flex",
        alignItems: "center",
        width: 1,
      }}
    >
      <Slider value={value} onChange={handleChange} size="small" />
    </Box>
  );
}
