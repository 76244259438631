import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedBimapp from "../hooks/useSelectedBimapp";
import useUpdateSceneBimapp from "../hooks/useUpdateSceneBimapp";
import {
  setIsEditingBimapp,
  setEditedBimapp,
  // setTempBimappTypes,
} from "../bimappsSlice";

import {uploadFileService} from "Features/files/services";
import {urlToFile} from "Features/files/utils";
import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsBimappInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id;
  const accessToken = useAccessToken();

  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.bimapps.isEditingBimapp);
  const selectedBimapp = useSelectedBimapp(scene);
  const editedBimapp = useSelector((s) => s.bimapps.editedBimapp);

  const updateBimapp = useUpdateSceneBimapp(scene);

  const [loading, setLoading] = useState(false);

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingBimapp(true));
    dispatch(setEditedBimapp({...selectedBimapp}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingBimapp(false));
  }

  async function handleSaveClick() {
    if (loading) return;
    setLoading(true);

    // bimapps
    const updatedBimapp = {...editedBimapp};

    if (editedBimapp.ogImageUrl !== selectedBimapp.ogImageUrl) {
      const options = {type: "image/png"};
      const blobName = nanoid() + ".png";

      const file = await urlToFile(editedBimapp.ogImageUrl, blobName, options);

      const imageUrl = await uploadFileService({
        accessToken,
        file,
        sceneId,
        container: "scene-files",
        blobName,
        blobContentType: "image/png",
      });
      updatedBimapp.ogImageUrl = imageUrl;
    }

    updateBimapp(updatedBimapp);
    dispatch(setIsEditingBimapp(false));

    setLoading(false);
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
