import {useDispatch} from "react-redux";
import {useState} from "react";

import {createView} from "../viewsSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogCreateView({open, onClose, scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const sceneId = scene?.id;

  // strings

  const title = "Nouvelle vue";

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  // handlers

  function handleClose() {
    onClose();
  }

  async function handleCreate() {
    const view = {name, sceneId};
    //
    if (loading) return;
    setLoading(true);
    //
    await dispatch(createView({accessToken, view}));
    setLoading(false);
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField value={name} onChange={(e) => setName(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate}>{createS}</Button>
      </DialogActions>
    </Dialog>
  );
}
