import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {setOpenSections} from "Features/ui/uiSlice";
import {setOpen} from "Features/navigation/navigationSlice";

import {Box, Button} from "@mui/material";

import ScenesDialog from "Features/scenes/components/ScenesDialog";
import SectionScenes from "Features/scenes/components/SectionScenes";
import useFetchRemoteScenes from "Features/scenes/useFetchRemoteScenes";

import SectionCallToActionCreateNewScene from "Features/scenes/components/SectionCallToActionCreateNewScene";
import {setOpenDialogFsSelectScope} from "Features/overviewer/overviewerSlice";

export default function Scenes() {
  const dispatch = useDispatch();
  console.log("[DEBUG] LOAD PAGE WITH SCENES");
  const darkMode = true;

  // data

  const [fetchRemoteScenes, accessToken] = useFetchRemoteScenes();
  const [count, setCount] = useState(0);

  const os = useSelector((s) => s.ui.openSections);

  const scenes = useSelector((s) => s.scenes.items);
  const isFetchingRemoteScenes = useSelector(
    (s) => s.scenes.isFetchingRemoteScenes
  );

  // init - close dialogs if any
  const openDialogFsSelectScope = useSelector(
    (s) => s.overviewer.openDialogFsSelectScope
  );
  useEffect(() => {
    if (openDialogFsSelectScope) dispatch(setOpenDialogFsSelectScope(false));
  }, [openDialogFsSelectScope]);

  // init

  useEffect(() => {
    dispatch(
      setOpenSections({
        outlet: true,
        viewer3D: false,
        fixedViewersBox: false,
        callToAction: false,
      })
    );
  }, []);

  useEffect(() => {
    if (accessToken && count < 5) {
      fetchRemoteScenes();
      setCount((count) => count + 1);
    }
  }, [accessToken]);

  // helpers

  const noScenes = scenes?.length === 0 && !isFetchingRemoteScenes;

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        bgcolor: darkMode ? "common.caplaBlack" : "common.white",
        color: darkMode ? "common.white" : "default",
      }}
    >
      {noScenes && <SectionCallToActionCreateNewScene />}

      <SectionScenes />
    </Box>
  );
}
