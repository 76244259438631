import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import useSelectedProgressListing from "Features/progress/useSelectedProgressListing";
import {updateListing} from "Features/listings/listingsSlice";
import useAccessToken from "Features/auth/useAccessToken";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import SelectorMobileModules from "./SelectorMobileModules";
import {setOpen} from "Features/navigation/navigationSlice";

export default function DialogEditListingMobileModules({scene, open, onClose}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const title = "Modules (mobile)";

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const progressListing = useSelectedProgressListing(scene);

  // helpers

  let initMomos = progressListing?.metaData?.mobileModules;
  if (!initMomos) initMomos = [];
  const hash = initMomos.join(";");
  const [momos, setMomos] = useState(initMomos);
  useEffect(() => {
    setMomos(initMomos);
  }, [hash]);

  const [loading, setLoading] = useState(false);

  // handlers

  function handleMomosChange(modules) {
    setMomos(modules);
  }

  function handleCancelClick() {
    const listingId = progressListing?.id;
    onClose();
  }
  async function handleSaveClick() {
    const updatedData = {
      metaData: {...progressListing.metaData, mobileModules: momos},
    };
    const listingId = progressListing?.id;
    if (loading) return;
    setLoading(true);
    await dispatch(
      updateListing({
        listingId,
        accessToken,
        updatedData,
      })
    );
    setLoading(false);
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <SelectorMobileModules
            selection={momos}
            onChange={handleMomosChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button variant="contained" onClick={handleSaveClick}>
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
