import {Box} from "@mui/material";

import SectionActionsRooms from "./SectionActionsRooms";

export default function SectionLeftPanelActionsRooms({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["createRoomsGroup", "createRoom", "editRoomsGroup"];
  const actionsMoreKeys = ["deleteRoomsGroup"];

  return (
    <Box>
      <SectionActionsRooms
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
