// TODO description

import { pointInsidePolygon } from "../utils/intersections";

export default function affectPropsByProxy({
  measurements,
  params,
  data
}) {
  const {proxyPackageId, props} = params;
  const {measurementsByPackageId} = data;
  const proxies = measurementsByPackageId[proxyPackageId];
  const usedProxies = new Set();
  return measurements.map((m) => {
    if (usedProxies.size !== proxies.length) {
      for (const proxy of proxies) {
        if (
          m.zoneId === proxy.zoneId &&
          !usedProxies.has(proxy.id) &&
          Array.isArray(proxy.path3D) &&
          Array.isArray(m.path3D) &&
          pointInsidePolygon(proxy.path3D[0], m.path3D)
        ) {
          usedProxies.add(proxy.id);
          const newM = {
            ...m,
            affectedByProxy: true,
            affectedProps: [],
          };
          if (Array.isArray(props)) {
            for (const prop of props) {
              newM[prop] = proxy[prop];
              newM.affectedProps.push(prop);
            }
          }
          if (m.height !== newM.height) {
            if (m.zFrom !== "zSup") {
              newM.zSup = newM.zInf + newM.height;
            } else {
              newM.zInf = newM.zSup - newM.height;
            }
          }
          return newM;
        }
      }
    }
    return {...m};
  });
}