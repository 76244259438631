import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {deleteSceneMemberships, updateScene} from "Features/scenes/scenesSlice";

import {List, ListItem, Typography, IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";

import useSceneMemberships from "Features/scenes/useSceneMemberships";
import useAccessToken from "Features/auth/useAccessToken";

export default function ListMembers({scene, propDependency, onDelete}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  // data

  const userMemberships = useSceneMemberships(scene?.id, propDependency);

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  let members = userMemberships?.map(({role, user}) => ({
    role,
    email: user,
  }));

  if (!members) members = [];

  // handlers

  async function handleDeleteMembership(membership) {
    // membership = {role,email}

    if (loading) return;

    setLoading(true);

    // data

    // sync
    const result = await dispatch(
      deleteSceneMemberships({
        accessToken,
        sceneId: scene.id,
        memberships: [membership],
      })
    );
    const {scene: newScene} = unwrapResult(result);
    const updatedScene = {...scene, userMemberships: newScene.userMemberships};
    dispatch(updateScene({scene: updatedScene}));

    // callback
    onDelete();
  }

  return (
    <List>
      {members.map((member) => (
        <ListItem
          key={member.email}
          secondaryAction={
            <IconButton onClick={() => handleDeleteMembership(member)}>
              <Delete />
            </IconButton>
          }
        >
          <Typography variant="body2">{member.email}</Typography>
        </ListItem>
      ))}
    </List>
  );
}
