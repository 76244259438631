import React, {useState} from "react";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import ListMembers from "./ListMembers";
import InputAddMembership from "./InputAddMembership";
export default function DialogEditMemberships({open, onClose, scene}) {
  const titleString = "Bimbox members";

  // state

  const [count, setCount] = useState(0); // used to update Listmembers...

  // helpers

  const dep = open ? count : count * 10;

  // handlers

  function handleAddMembership() {
    setCount((c) => c + 1);
  }

  function handleDeleteMembership() {
    setCount((c) => c + 1);
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleString}</DialogTitle>
      <DialogContent>
        <ListMembers
          scene={scene}
          propDependency={dep}
          onDelete={handleDeleteMembership}
        />
        <Box sx={{my: 1}} />
        <InputAddMembership scene={scene} onAdd={handleAddMembership} />
      </DialogContent>
    </Dialog>
  );
}
