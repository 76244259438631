import {useSelector} from "react-redux";
import {useState} from "react";

import {Dialog, DialogContent, Box} from "@mui/material";

import SectionSelectorEntity from "./SectionSelectorEntity";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import getModelsInScopeByType from "Features/scopes/utils/getModelsInScopeByType";
import ContainerModelsInScope from "./ContainerModelsInScope";
import CheckboxIsStagingEnv from "../hooks/CheckboxIsStagingEnv";

export default function DialogModelsInScope({open, onClose}) {
  // data

  const models = useSelector((s) => s.viewer3D.models);
  const scope = useSelectedScope();
  const loadingStatusModelIdsMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  // local state

  const [selection, setSelection] = useState("PACKAGES");

  // helpers - models

  const modelsInScopeByType = getModelsInScopeByType(models, scope);

  // helpers - countMap

  const countMap = {
    INPUTS: modelsInScopeByType["INPUTS"].length,
    ZONES: modelsInScopeByType["ZONES"].length,
    PACKAGES: modelsInScopeByType["PACKAGES"].length,
  };

  const filteredModels = modelsInScopeByType[selection];
  const sortedModels = [...filteredModels].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // handlers

  function handleClose() {
    onClose();
  }

  function handleSelectionChange(entity) {
    setSelection(entity);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            p: 2,
            width: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <SectionSelectorEntity
            selection={selection}
            onChange={handleSelectionChange}
            countMap={countMap}
          />
        </Box>
        <Box sx={{width: 1, height: 300, overflow: "auto"}}>
          <ContainerModelsInScope
            models={sortedModels}
            loadingStatusModelIdsMap={loadingStatusModelIdsMap}
          />
        </Box>
      </Box>

      <Box sx={{mt: 4, pl: 2, bgcolor: "background.default"}}>
        <CheckboxIsStagingEnv />
      </Box>
    </Dialog>
  );
}
