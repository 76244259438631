// TODOS
// - building storeys from sectors
// - make buildings from sector info
// - proper georeferencement in project
// - replace the author and organization in the HEADER with proper information

import {nanoid} from "@reduxjs/toolkit";

import {saveBlob} from "Features/files/utils";

///////////////////////////////////////////////////////////////////////////////////////////////////
// UTILS
///////////////////////////////////////////////////////////////////////////////////////////////////

const uuids = new Set([""]);
const uuid = () => {
  let newUuid = "";
  while (uuids.has(newUuid)) newUuid = nanoid() + "0";
  uuids.add(newUuid);
  return newUuid;
};

const date = new Date();
const timeStamp = () =>
  [
    date.getFullYear(),
    "/",
    date.getMonth() + 1,
    "/",
    date.getDate(),
    "T",
    date.getHours(),
    ":",
    date.getMinutes(),
    ":",
    date.getSeconds(),
  ].join("");

const hexToRGB = (hex) => {
  const color = hex.substr(1);
  if (color.length !== 6) {
    return [0.5, 0.5, 0.5];
  }
  const rgbHex = color.match(/.{1,2}/g);
  return [
    parseInt(rgbHex[0], 16) / 255.0,
    parseInt(rgbHex[1], 16) / 255.0,
    parseInt(rgbHex[2], 16) / 255.0,
  ];
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// TEMPLATE PROJECT + OWNERHISTORY
///////////////////////////////////////////////////////////////////////////////////////////////////

// file_schema : IFC4_ADD2 ? IFC4 ?
const fileHeader = (
  fileName,
  author = "CAPLA 360",
  organization = "CAPLA Technologies"
) => `ISO-10303-21;
HEADER;
FILE_DESCRIPTION(('ViewDefinition [Ifc4X3NotAssigned]'),'2;1');
FILE_NAME('${fileName}','${timeStamp()}',('${author}'),('${organization}'),'CAPLA 360','app.capla360.com','export');
FILE_SCHEMA(('IFC4_ADD2'));
ENDSEC;
DATA;`;

const origin = `
#1=IFCDIMENSIONALEXPONENTS(0,0,0,0,0,0,0);
#2=IFCDIRECTION((1.,0.,0.));
#3=IFCDIRECTION((0.,1.,0.));
#4=IFCDIRECTION((0.,0.,1.));
#5=IFCCARTESIANPOINT((0.,0.,0.));`;

const ownerHistory = (
  author = "CAPLA 360",
  organization = "CAPLA Technologies"
) => `
#6=IFCOWNERHISTORY(#9,#11,.READONLY.,.ADDED.,${+date},#9,#11,${+date});
#7=IFCPERSON($,'${author.split(" ")[0]}','${author.split(" ")[1]}',$,$,$,$,$);
#8=IFCORGANIZATION($,'${organization}',$,$,$);
#9=IFCPERSONANDORGANIZATION(#7,#8,$);
#10=IFCORGANIZATION($,'CAPLA Technologies','www.capla-technologies.com',$,$);
#11=IFCAPPLICATION(#10,'0.1','CAPLA 360','CAPLA 360');`;

const project = `
#12=IFCPROJECT('${uuid()}',#6,'My Bimbox',$,$,$,$,(#23),#13);
#13=IFCUNITASSIGNMENT((#14,#15,#16,#19,#20,#21,#22));
#14=IFCSIUNIT(*,.LENGTHUNIT.,$,.METRE.);
#15=IFCSIUNIT(*,.AREAUNIT.,$,.SQUARE_METRE.);
#16=IFCSIUNIT(*,.VOLUMEUNIT.,$,.CUBIC_METRE.);
#17=IFCSIUNIT(*,.PLANEANGLEUNIT.,$,.RADIAN.);
#18=IFCMEASUREWITHUNIT(IFCPLANEANGLEMEASURE(0.017453292519943295),#17);
#19=IFCCONVERSIONBASEDUNIT(#1,.PLANEANGLEUNIT.,'degree',#18);
#20=IFCSIUNIT(*,.MASSUNIT.,.KILO.,.GRAM.);
#21=IFCSIUNIT(*,.TIMEUNIT.,$,.SECOND.);
#22=IFCMONETARYUNIT('EUR');
#23=IFCGEOMETRICREPRESENTATIONCONTEXT($,'Model',3,1.E-6,#24,#3);
#24=IFCAXIS2PLACEMENT3D(#5,#4,#2);`;

///////////////////////////////////////////////////////////////////////////////////////////////////
// BELOW IS PROGRAMMATIC
///////////////////////////////////////////////////////////////////////////////////////////////////

let currentId = 24;

const typesMap = {};
const entityMap = {
  xDirection: "#2",
  yDirection: "#3",
  zDirection: "#4",
  originPoint: "#5",
  ownerHistory: "#6",
  project: "#12",
  representationContext: "#23",
  originPlacement: "#24",
};

const aggregates = {};
const relContained = {};

///////////////////////////////////////////////////////////////////////////////////////////////////
// SITE
///////////////////////////////////////////////////////////////////////////////////////////////////

const addSite = () => {
  entityMap.site = `#${currentId + 1}`;
  entityMap.sitePlacement = `#${currentId + 2}`;
  const content = `
${entityMap.site}=IFCSITE('${uuid()}',${
    entityMap.ownerHistory
  },'Default Site',$,$,${entityMap.sitePlacement},$,$,$,$,$,$,$,$);
${entityMap.sitePlacement}=IFCLOCALPLACEMENT($,${entityMap.originPlacement});
#${currentId + 3}=IFCRELAGGREGATES('${uuid()}',$,$,$,${entityMap.project},(${
    entityMap.site
  }));`;
  currentId += 3;
  aggregates["site"] = [];
  return content;
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// BUILDINGS
///////////////////////////////////////////////////////////////////////////////////////////////////

const addBuilding = (building) => {
  currentId += 1;
  entityMap[building] = `#${currentId}`;
  const content = `
#${currentId}=IFCBUILDING('${uuid()}',${
    entityMap.ownerHistory
  },'${building}',$,$,${entityMap.sitePlacement},$,$,.ELEMENT.,$,$,$);`;
  aggregates["site"].push(`#${currentId}`);
  aggregates[building] = [];
  relContained[building] = [];
  return content;
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// SECTORS
///////////////////////////////////////////////////////////////////////////////////////////////////

const addSector = (sector) => {
  let content = "";
  const {id, name, code, z} = sector;
  //   currentId += 1;
  //   content += `
  // #${currentId}=IFCCARTESIANPOINT(0.0,0.0,${z});`;
  //   currentId += 1;
  //   content += `
  // #${currentId}=IFCAXIS2PLACEMENT3D(#${currentId - 1},$,$);`;
  //   currentId += 1;
  //   content += `
  // #${currentId}=IFCLOCALPLACEMENT(${entityMap.sitePlacement},#${currentId - 1});`;
  //   entityMap[id + "Placement"] = `#${currentId}`;
  currentId += 1;
  entityMap[id] = `#${currentId}`;
  entityMap[code] = `#${currentId}`;
  content += `
#${currentId}=IFCBUILDINGSTOREY('${uuid()}',${
    entityMap.ownerHistory
  },'${name}',$,$,${entityMap.sitePlacement},$,$,.ELEMENT.,${z});`;
  // ${entityMap[id]}=IFCBUILDINGSTOREY('${uuid()}',${entityMap.ownerHistory},'${name}','Sector : ${code}',$,#${currentId - 1},$,$,.ELEMENT.,$,$,$);`;
  if (sector.building) aggregates[sector.building].push(`#${currentId}`);
  else aggregates["Default Building"].push(`#${currentId}`);
  return content;
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// ROOMS
///////////////////////////////////////////////////////////////////////////////////////////////////

const addRoom = (room) => {
  let content = "";
  const {id, name, code, type} = room;
  const typeS = type ? type : "";
  currentId += 1;
  entityMap[id] = `#${currentId}`;
  content += `
#${currentId}=IFCSPACE('${uuid()}',${
    entityMap.ownerHistory
  },'${name}','${typeS}',$,${entityMap.sitePlacement},$,$,.ELEMENT.,$,$,$);`;
  if (room.sector) {
    try {
      aggregates[room.sector].push(`#${currentId}`);
    } catch {
      aggregates[room.sector] = [`#${currentId}`];
    }
  } else aggregates["Default Building"].push(`#${currentId}`);
  return content;
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// COLORS
///////////////////////////////////////////////////////////////////////////////////////////////////

const addColor = (color) => {
  const rgb = hexToRGB(color);
  entityMap[color] = `#${currentId + 4}`;
  const content = `
#${currentId + 1}=IFCCOLOURRGB('${color}',${rgb[0]},${rgb[1]},${rgb[2]});
#${currentId + 2}=IFCSURFACESTYLERENDERING(#${
    currentId + 1
  },$,$,$,$,$,IFCNORMALISEDRATIOMEASURE(3.90625E-3),IFCSPECULAREXPONENT(10.),.NOTDEFINED.);
#${currentId + 3}=IFCSURFACESTYLE($,.POSITIVE.,(#${currentId + 2}));
#${currentId + 4}=IFCPRESENTATIONSTYLEASSIGNMENT((#${currentId + 3}));`;
  currentId += 4;
  return content;
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// PRODUCTS
///////////////////////////////////////////////////////////////////////////////////////////////////

// Possibility to add a line (shape) representation (#66 à rajouter dans les ifcproductdefinitionshape)
// #66 = IFCSHAPEREPRESENTATION(#20, 'Axis', 'Curve2D', (#67));
// #67 = IFCPOLYLINE((#68, #69));
// #68 = IFCCARTESIANPOINT((0., 150.));
// #69 = IFCCARTESIANPOINT((3000., 150.));

// TODO see if IFCCARTESIANPOINTLIST can replace IFCPOLYLINE + IFCCARTESIANPOINT(s!)

const addProduct = (product) => {
  let extrusionHeight = product.height;
  let path3d = product.path3d3;
  if (product.drawingShape === "STAIRS") {
    const path = product.path3D;
    const A = [path[0][0], product.zInf, path[0][1]];
    const B = [path[1][0], product.zSup, path[1][1]];
    const C = [path[2][0], product.zSup, path[2][1]];
    const D = [path[3][0], product.zInf, path[3][1]];
    path3d = [A, B, C, D, A];
    extrusionHeight = 0.001;
  }
  let content = "";
  const lineIds = [];
  path3d.map((p) => {
    currentId += 1;
    lineIds.push(`#${currentId}`);
    content += `
#${currentId}=IFCCARTESIANPOINT((${p[0]},${-p[2]},${p[1]}));`;
  });
  currentId += 1;
  content += `
#${currentId}=IFCPOLYLINE((${lineIds.join(",")}));`;
  currentId += 1;
  content += `
#${currentId}=IFCARBITRARYCLOSEDPROFILEDEF(.AREA.,$,#${currentId - 1});`;
  currentId += 1;
  content += `
#${currentId}=IFCEXTRUDEDAREASOLID(#${currentId - 1},${
    entityMap.originPlacement
  },${entityMap.zDirection},${extrusionHeight});`;
  currentId += 1;
  content += `
#${currentId}=IFCSHAPEREPRESENTATION(${
    entityMap.representationContext
  },'Body','SweptSolid',(#${currentId - 1}));`;
  currentId += 1;
  content += `
#${currentId}=IFCPRODUCTDEFINITIONSHAPE($,$,(#${currentId - 1}));`;
  currentId += 1;
  let color = product.material ? product.material.color : product.color;
  if (!entityMap[color]) {
    color = "#808080";
  }
  content += `
#${currentId}=IFCSTYLEDITEM(#${currentId - 3},(${entityMap[color]}),$);`;
  const placement = entityMap.sitePlacement;
  // const placement = product.sectorId ? entityMap[product.sectorId + "Placement"] : entityMap.sitePlacement;
  currentId += 1;
  content += `
#${currentId}=IFCBUILDINGELEMENT('${uuid()}',${entityMap.ownerHistory},'${
    typesMap[product.elementTypeId]
  }','${product.codeName}',$,${placement},#${currentId - 2},$,$);`;
  if (product.roomId) {
    try {
      relContained[product.roomId].push(`#${currentId}`);
    } catch {
      relContained[product.roomId] = [`#${currentId}`];
    }
  } else if (product.sectorId) {
    try {
      relContained[product.sectorId].push(`#${currentId}`);
    } catch {
      relContained[product.sectorId] = [`#${currentId}`];
    }
  } else relContained["Default Building"].push(`#${currentId}`);
  return content;
};

///////////////////////////////////////////////////////////////////////////////////////////////////
// AGGREGATES AND CONTAINERS
///////////////////////////////////////////////////////////////////////////////////////////////////

const makeRelAggregates = () => {
  let content = "";
  Object.entries(aggregates).map(([k, v]) => {
    if (v.length > 0) {
      currentId += 1;
      content += `
#${currentId}=IFCRELAGGREGATES('${uuid()}',${entityMap.ownerHistory},$,$,${
        entityMap[k]
      },(${v.join(",")}));`;
    }
  });
  return content;
};

const makeRelContainedInSpatialStructure = () => {
  let content = "";
  Object.entries(relContained).map(([k, v]) => {
    currentId += 1;
    content += `
#${currentId}=IFCRELCONTAINEDINSPATIALSTRUCTURE('${uuid()}',${
      entityMap.ownerHistory
    },$,$,(${v.join(",")}),${entityMap[k]});`;
  });
  return content;
};

//                                             iiii
//                                            i::::i
//                                             iiii
//
// mmmmmmm    mmmmmmm     aaaaaaaaaaaaa   iiiiiiinnnn  nnnnnnnn
// mm:::::::m  m:::::::mm   a::::::::::::a  i:::::in:::nn::::::::nn
// m::::::::::mm::::::::::m  aaaaaaaaa:::::a  i::::in::::::::::::::nn
// m::::::::::::::::::::::m           a::::a  i::::inn:::::::::::::::n
// m:::::mmm::::::mmm:::::m    aaaaaaa:::::a  i::::i  n:::::nnnn:::::n
// m::::m   m::::m   m::::m  aa::::::::::::a  i::::i  n::::n    n::::n
// m::::m   m::::m   m::::m a::::aaaa::::::a  i::::i  n::::n    n::::n
// m::::m   m::::m   m::::ma::::a    a:::::a  i::::i  n::::n    n::::n
// m::::m   m::::m   m::::ma::::a    a:::::a i::::::i n::::n    n::::n
// m::::m   m::::m   m::::ma:::::aaaa::::::a i::::::i n::::n    n::::n
// m::::m   m::::m   m::::m a::::::::::aa:::ai::::::i n::::n    n::::n
// mmmmmm   mmmmmm   mmmmmm  aaaaaaaaaa  aaaaiiiiiiii nnnnnn    nnnnnn

export default function ifcExport({scene, measurements}) {
  const fileName = scene.title + ".ifc";

  const elementTypes = scene.data.elementTypes;

  let rooms = scene.data.rooms;

  const sectors = [{name: "Général", code: "GEN", z: 0}];
  // Ugly way of dealing with duplicate default sectors
  const sceneSectors = scene.data.sectors.filter(
    (s) => s.name !== "Général" && s.code !== "GEN" && s.z !== 0
  );
  if (sceneSectors) sceneSectors.map((s) => sectors.push(s));

  const buildings = ["Default Building"];
  const sectorsBuildings = sectors.filter((s) => s.building);
  if (sectorsBuildings) sectorsBuildings.map((s) => buildings.push(s.building));

  const allColors = elementTypes.map((t) => {
    typesMap[t.id] = t.name;
    return t.material ? t.material.color : t.color ? t.color : "#808080";
  });
  const colors = new Set(allColors);

  let fileContent = fileHeader(fileName);
  fileContent += origin;
  fileContent += ownerHistory();
  fileContent += project;

  fileContent += addSite();

  buildings.map((b) => (fileContent += addBuilding(b)));

  colors.forEach((c) => (fileContent += addColor(c)));

  measurements.map((m) => (fileContent += addProduct(m)));

  if (rooms) {
    const containers = new Set(Object.keys(relContained));
    rooms = rooms.filter((r) => containers.has(r.id));
    rooms.map((r) => (fileContent += addRoom(r)));
  }

  sectors.map((s) => (fileContent += addSector(s)));

  fileContent += makeRelAggregates();
  fileContent += makeRelContainedInSpatialStructure();

  fileContent += `
ENDSEC;
END-ISO-10303-21;`;

  const newBlob = new Blob([fileContent], {type: "text/plain;charset=utf-8"});
  saveBlob(newBlob, fileName);
}
