import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {updateIssue} from "../issuesSlice";
import useAccessToken from "Features/auth/useAccessToken";

import {IconButton, Box, Typography, CircularProgress} from "@mui/material";
import {
  ArrowUpward as Raise,
  ArrowDownward as Lower,
} from "@mui/icons-material";

export default function ButtonSwitchPriorityInDataGrid({
  id,
  _listingId,
  priority,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  console.log("listingId", _listingId);

  // strings

  const lowS = "Low";
  const mediumS = "Medium";
  const highS = "High";

  // state

  const [loadingRaise, setLoadingRaise] = useState(false);
  const [loadingLower, setLoadingLower] = useState(false);

  // helpers

  const priorityMap = {
    LOW: {label: lowS, color: "text.secondary", raised: "MEDIUM"},
    MEDIUM: {
      label: mediumS,
      color: "common.black",
      lowered: "LOW",
      raised: "HIGH",
    },
    HIGH: {label: highS, color: "error.main", lowered: "MEDIUM"},
  };

  const color = priorityMap[priority]?.color;
  const label = priorityMap[priority]?.label;
  const raised = priorityMap[priority]?.raised;
  const lowered = priorityMap[priority]?.lowered;

  // handlers

  function handleRaiseClick() {
    setLoadingRaise(true);
    const newIssue = {id, listingId: _listingId, priority: raised};
    dispatch(updateIssue({accessToken, issue: newIssue}));
    setLoadingRaise(false);
  }
  function handleLowerClick() {
    setLoadingLower(true);
    const newIssue = {id, listingId: _listingId, priority: lowered};
    dispatch(updateIssue({accessToken, issue: newIssue}));
    setLoadingLower(false);
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{width: "75px", color}} variant="body2">
        {label}
      </Typography>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <IconButton
          size="small"
          onClick={handleLowerClick}
          sx={{visibility: priority === "LOW" ? "hidden" : "visible"}}
        >
          {loadingLower ? (
            <CircularProgress size={16} />
          ) : (
            <Lower fontSize="small" />
          )}
        </IconButton>
        <IconButton
          size="small"
          onClick={handleRaiseClick}
          sx={{visibility: priority === "HIGH" ? "hidden" : "visible"}}
        >
          {loadingRaise ? (
            <CircularProgress size={16} />
          ) : (
            <Raise fontSize="small" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
