import {Dialog, DialogTitle, DialogContent} from "@mui/material";

import SectionVoidsAutoAdd from "./SectionVoidsAutoAdd";

export default function DialogVoids({open, onClose, scene, caplaEditor}) {
  // strings

  const title = "Les vides";

  //
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <SectionVoidsAutoAdd
          scene={scene}
          caplaEditor={caplaEditor}
          onAdded={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
