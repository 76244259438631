import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Button, Box} from "@mui/material";
import {AddLocation as Add} from "@mui/icons-material";

import {
  // setPositioningObject,
  setMode,
  setStep,
  setPointerMode,
} from "Features/viewer3D/viewer3DSlice";

export default function ButtonCreateMarker({editor3d}) {
  const dispatch = useDispatch();

  // string

  const newS = "New";

  // data

  const mode = useSelector((s) => s.viewer3D.mode);

  //handlers

  function handleClick() {
    editor3d?.switchToMode("POSITIONING_MARKER");
    //dispatch(setPositioningObject({editor3d: true}));
    dispatch(setMode("PROCESS_ADD_MARKER"));
    dispatch(setStep("POSITION"));
  }

  // effects

  useEffect(() => {
    if (editor3d?.sceneEditor) {
      const bool = mode === "PROCESS_ADD_MARKER";
      editor3d.sceneEditor.setPointerMode(bool);
      dispatch(setPointerMode(bool));
    }
  }, [mode, editor3d?.sceneEditor]);

  return (
    <Box sx={{color: "common.white"}}>
      <Button
        startIcon={<Add />}
        onClick={handleClick}
        size="small"
        variant="outlined"
        color="inherit"
      >
        {newS}
      </Button>
    </Box>
  );
}
