import {openDB} from "idb";
import {deleteDB} from "idb";

export const CAPLA_DATASTORE = "capla360-datastore";

export const MODEL_DO = "Model";
export const RESSOURCE_DO = "Ressource";
export const SCENE_DO = "Scene";
export const FILE_DO = "File";
export const OBJECTSET_DO = "Objectset";
export const PRICE_DO = "Pricing";

//let dbPromise;

class DB {
  constructor() {
    this.init();
  }

  init() {
    this.dbPromise = openDB(CAPLA_DATASTORE, 5, {
      upgrade(db) {
        // Models
        // {id,remoteId,sceneId,sceneClientId,,name,position,rotation,type,data,fileClientId,fileRemoteUrl,boundingbox}
        if (!db.objectStoreNames.contains(MODEL_DO)) {
          const modelStore = db.createObjectStore(MODEL_DO, {
            keyPath: "id",
          });
          modelStore.createIndex("sceneClientId", "sceneClientId");
        }
        // Prices
        // {id,sharedBimboxId,resGroup,resId,pu}
        if (!db.objectStoreNames.contains(PRICE_DO)) {
          const pricingStore = db.createObjectStore(PRICE_DO, {
            keyPath: "id",
          });
          pricingStore.createIndex("sharedBimboxId", "sharedBimboxId");
        }
        // Ressources
        // {id,syncedVersion,type,fileClientId}
        if (!db.objectStoreNames.contains(RESSOURCE_DO)) {
          const ressourceStore = db.createObjectStore(RESSOURCE_DO, {
            keyPath: "clientId",
          });
        }
        // Objectsets
        if (!db.objectStoreNames.contains(OBJECTSET_DO)) {
          const objectsetStore = db.createObjectStore(OBJECTSET_DO, {
            keyPath: "id",
          });
          objectsetStore.createIndex("sceneClientId", "sceneClientId");
        }
        // Scene
        if (!db.objectStoreNames.contains(SCENE_DO)) {
          const sceneStore = db.createObjectStore(SCENE_DO, {
            keyPath: "clientId",
          });
          sceneStore.createIndex("id", "id");
          sceneStore.createIndex("localStorage", "localStorage");
        }
        // Files
        // {clientId,arrayBuffer,name,size,type,lastModified}
        if (!db.objectStoreNames.contains(FILE_DO)) {
          const fileStore = db.createObjectStore(FILE_DO, {
            keyPath: "clientId",
          });
          fileStore.createIndex("name,lastModified,size", [
            "name",
            "lastModified",
            "size",
          ]);
          fileStore.createIndex("sceneClientId", "sceneClientId");
        }
      },
    });
  }

  async delete() {
    const db = await this.dbPromise;
    db.close();
    await deleteDB(CAPLA_DATASTORE);
    console.log("database deleted");
  }
}

const caplaDB = new DB();

export default caplaDB;
