import polyline from "Features/measurements/assets/polyline.png";
import gable from "Features/measurements/assets/gable.png";
import polygon from "Features/measurements/assets/polygon.png";
import segment from "Features/measurements/assets/segment.png";
import rectangle from "Features/measurements/assets/rectangle.png";
import circle from "Features/measurements/assets/circle.png";
import slope from "Features/measurements/assets/slope.png";
import stairs from "Features/measurements/assets/stairs.png";
import beam from "Features/measurements/assets/beam.png";
import bank from "Features/measurements/assets/bank.png";
import slopingPolygon from "Features/measurements/assets/slopingPolygon.png";
import bridge from "Features/measurements/assets/bridge.png";
import bowl from "Features/measurements/assets/bowl.png";

export default function useDrawingShapeImageUrlMap() {
  return {
    POLYLINE: polyline,
    GABLE: gable,
    POLYGON: polygon,
    SEGMENT: segment,
    RECTANGLE: rectangle,
    CIRCLE: circle,
    SLOPE: slope,
    STAIRS: stairs,
    BEAM: beam,
    BANK: bank,
    SLOPING_POLYGON: slopingPolygon,
    BRIDGE: bridge,
    BOWL: bowl,
  };
}
