import getElementTypesByGroupIdById from "Features/elementTypes/utils/getElementTypesByGroupIdById";
import articleTypesMapToArray from "./articleTypesMapToArray";
import {testMeasurementOnFuncFilters} from "./testMeasurementOnFuncFilters";

export default function getMeasurementsFromArticleTypes(
  measurements,
  articleTypes,
  data,
  option
) {
  // data

  const groupsWithSortedTypes = data.groupsWithSortedTypes;
  const sectors = data.sectors ?? [];

  // dataById

  const elementTypesByGroupById = getElementTypesByGroupIdById(
    groupsWithSortedTypes
  );
  const sectorsById = sectors.reduce((acc, sector) => {
    acc[sector?.id] = sector;
    return acc;
  }, {});

  // options

  const addElementTypeProp = option?.addElementTypeProp;

  // helpers

  const typesArray = articleTypesMapToArray(articleTypes);
  const articleTypeItemById = typesArray.reduce((acc, item) => {
    acc[item.typeId] = item;
    return acc;
  }, {});

  const filteredMeasurements = [];

  measurements.forEach((measurement) => {
    // there is only one articleType to use, the one related to the meas elementType.
    const articleTypeItem = articleTypeItemById[measurement.elementTypeId];
    if (articleTypeItem) {
      const elementType =
        elementTypesByGroupById[articleTypeItem.groupId]?.[
          articleTypeItem.typeId
        ];
      const sector = sectorsById[measurement.sectorId];
      if (elementType) {
        if (
          testMeasurementOnFuncFilters({
            measurement,
            filtersStr: articleTypeItem.filtersStr,
            sector,
          })
        ) {
          filteredMeasurements.push({
            ...measurement,
            elementType,
            elementTypesGroupId: articleTypeItem.groupId,
          });
        }
      }
    }
  });

  return measurements;
}
