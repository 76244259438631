import {useDispatch, useSelector} from "react-redux";

import {setTabMultipleSelection} from "../filtersSlice";
import {FormControlLabel, Typography, Switch, Box} from "@mui/material";

export default function SwitchMultipleSelection({tab}) {
  const dispatch = useDispatch();
  // string

  const label = "Sélection multiple";

  const multipleSelectionTabMap = useSelector(
    (s) => s.filters.multipleSelectionTabMap
  );
  const checked = Boolean(multipleSelectionTabMap[tab]);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setTabMultipleSelection({tab, multipleSelection: checked}));
  }

  return (
    <Box sx={{pl: 2}}>
      <FormControlLabel
        control={
          <Switch size="small" checked={checked} onChange={handleChange} />
        }
        label={<Typography sx={{fontSize: 13}}>{label}</Typography>}
      />
    </Box>
  );
}
