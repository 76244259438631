export function rawTypeToType({drawingShape, subject, color, dotted}) {
  let num, name, dim2, height;

  // name

  name = subject.trim();

  // num

  const regex = /(\d+\.?)+/g;
  const found = subject.match(regex);
  if (found?.length > 0) {
    num = found[0];
    name = name.replace(num, "").trim();
  }

  // dim2 & height

  const regex2 = /(\d+)\sx\s(\d+)/;
  let results = subject.match(regex2);
  if (results?.length > 1) {
    dim2 = results[1];
    height = results[2];

    dim2 = parseFloat(dim2) / 100;
    height = parseFloat(height) / 100;
  }

  // code

  const code = name.substring(0, 3).toUpperCase();

  const result = {num, name, color, drawingShape, dotted, dim2, height};

  if (drawingShape && color) result.code = code;

  return result;
}
