export default function computeExpr({
  expr,
  measurement,
  zone,
  room,
  sector,
  voidsById,
}) {
  if (!expr) return null;

  const {
    count,
    perimeter,
    length,
    area,
    volume,
    lengthNet,
    areaNet,
    volumeNet,
    lengthRaw,
    areaRaw,
    volumeRaw,
    dim1,
    dim2,
    dim3,
    height,
    heightE,
    heightN,
    lengthP,
    areaP,
    p0,
    p1,
    p2,
    p3,
    offset,
    zInf,
    zSup,
    voids,
  } = measurement;

  // clean expr

  const cleanExpr = typeof expr === "string" ? expr.trim() : "";
  if (!cleanExpr) return null;

  // helpers

  let voidsL = 0;
  if (voidsById) {
    voidsL = voids?.reduce((acc, voidId) => {
      return acc + voidsById[voidId]?.length;
    }, 0);
  }

  try {
    const result = Function(
      `"use strict";
        const C=${count ? count : 0};
        const UN=${count ? count : 0};
        const P=${perimeter ? perimeter : 0};
        const L=${lengthRaw ? lengthRaw : length ? length : 0};
        const A=${areaRaw ? areaRaw : area ? area : 0};
        const S=${areaRaw ? areaRaw : area ? area : 0};
        const V=${volumeRaw ? volumeRaw : volume ? volume : 0};
        const L_RAW=${lengthRaw ? lengthRaw : 0};
        const A_RAW=${areaRaw ? areaRaw : 0};
        const S_RAW=${areaRaw ? areaRaw : 0};
        const V_RAW=${volumeRaw ? volumeRaw : 0};
        const L_NET=${lengthNet ? lengthNet : 0};
        const A_NET=${areaNet ? areaNet : 0};
        const S_NET=${areaNet ? areaNet : 0};
        const V_NET=${volumeNet ? volumeNet : 0};
        const D1=${dim1 ? dim1 : 0};
        const D2=${dim2 ? dim2 : 0};
        const D3=${dim3 ? dim3 : 0};
        const H=${height ? height : 0};
        const HE=${heightE ? heightE : 0};
        const HN=${heightN ? heightN : 0};
        const VE=${heightE ? heightE * length * dim1 : 0};
        const VN=${heightN ? heightN * length * (dim1 + 2 * dim3) : 0};
        const LP=${lengthP ? lengthP : 0};
        const SP=${areaP ? areaP : 0};

        const MC=${p0 ? '"' + p0 + '"' : null};
        const P1=${p1 ? '"' + p1 + '"' : null};
        const P2=${p2 ? '"' + p2 + '"' : null};
        const P3=${p3 ? '"' + p3 + '"' : null};

        const TS = ${volumeRaw && p1 ? volumeRaw * p1 : 0};
        const HA = ${volumeRaw && p2 ? volumeRaw * p2 : 0};
        const ARM = ${volumeRaw && p2 && p1 ? volumeRaw * (p2 + p1) : 0};


        const BET_POUTRE = ${heightE ? heightE * lengthRaw * dim1 : 0};
        const COF_POUTRE = ${heightE ? (2 * heightE + dim1) * lengthRaw : 0};
        const HA_POUTRE = ${height && p2 ? height * dim1 * lengthRaw * p2 : 0};
        const ARM_POUTRE = ${height && p2 ? height * dim1 * lengthRaw * p2 : 0};

        const BET_VOILE = ${volumeNet ? volumeNet : 0};
        const COF_VOILE = ${height ? lengthRaw * height : 0};


        const OFFSET=${
          zone?.position?.z && offset
            ? zone.position.z + offset
            : offset
            ? offset
            : 0
        };
        const ZINF=${zInf ? zInf : 0};
        const ZSUP=${zSup ? zSup : 0};
        const SECTEUR=${sector?.code ? '"' + sector.code + '"' : 0};
        const BAT=${sector?.building ? '"' + sector.building + '"' : 0};
        const ZONE=${room?.code ? '"' + room.code + '"' : 0};

        const VIDES_UN=${voids?.length ? voids.length : 0};
        const VIDES_LIN=${voidsL ? voidsL : 0};

        return(${cleanExpr})`
    )();
    //console.log("debugAFA computeExpr", expr, measurement.p2, result);
    return result;
  } catch (e) {
    console.log("ERROR computing formula", expr, e);
    return null;
  }
}

// const MC=${p0 ? (typeof p0 === "string" ? '"' + p0 + '"' : p0) : null};
// const P1=${p1 ? (typeof p1 === "string" ? '"' + p1 + '"' : p1) : null};
// const P2=${p2 ? (typeof p2 === "string" ? '"' + p2 + '"' : p2) : null};
// const P3=${p3 ? (typeof p3 === "string" ? '"' + p3 + '"' : p3) : null};
