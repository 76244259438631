import {useSelector, useDispatch} from "react-redux";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import getModelsLoadingRatio from "../utils/getModelsLoadingRatio";
import getModelsInScope from "Features/scopes/utils/getModelsInScope";
import useIsStagingEnv from "./useIsStagingEnv";
import {setOpenDialogFsLoadingScope} from "../overviewerSlice";
import useScopeToOpen from "Features/scopes/hooks/useScopeToOpen";

export default function useOpenDialogFsLoadingScope() {
  //
  const dispatch = useDispatch();

  const isStagingEnv = useIsStagingEnv();

  const openDialogFsSelectScope = useSelector(
    (s) => s.overviewer.openDialogFsSelectScope
  );

  const openDialogFsOpenScope = useSelector(
    (s) => s.overviewer.openDialogFsOpenScope
  );

  const scopeToOpen = useScopeToOpen({useFixedScope: true});
  const models = useSelector((s) => s.viewer3D.models);
  const loadingStatusModelIdsMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  console.log("[openLoadingDialog for]", scopeToOpen);

  const modelsInScope = getModelsInScope(models, scopeToOpen);

  const ratio = getModelsLoadingRatio(modelsInScope, loadingStatusModelIdsMap);

  const openLoading =
    isStagingEnv &&
    modelsInScope?.length > 0 &&
    ratio < 99 &&
    ratio > 0 &&
    !openDialogFsSelectScope &&
    !openDialogFsOpenScope;

  if (openLoading) {
    dispatch(setOpenDialogFsLoadingScope(true));
  } else {
    dispatch(setOpenDialogFsLoadingScope(false));
  }
}
