export default function getStateElementTypesGroupFromRemoteItem(remoteItem) {
  return {
    id: remoteItem.id,
    version: remoteItem.version,
    name: remoteItem.name,
    code: remoteItem.code,
    sceneId: remoteItem.scene,
    elementTypes: remoteItem.elementTypes,
  };
}
