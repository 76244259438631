import {useSelector} from "react-redux";

import useElementTypesByScene from "./useElementTypesByScene";

import getSceneElementTypesWithGroupProps from "../utils/getSceneElementTypesWithGroupProps";
import removeItemsWithDuplicatedId from "Utils/removeItemsWithDuplicatedId";

import sortByNum from "Utils/sortByNum";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {filter} from "jszip";

export default function useElementTypesBySceneProxy(scene, options) {
  const sortTypesByNum = options?.sortByNum;
  const filterByScope = options?.filterByScope;
  const filterByTypesGroupsIds = options?.filterByTypesGroupsIds;

  // scope

  const scope = useSelectedScope();

  // scene
  let sceneTypes = useElementTypesByScene(scene);
  sceneTypes = getSceneElementTypesWithGroupProps(sceneTypes);

  //remote
  const elementTypesGroups = useSelector(
    (s) => s.elementTypes.elementTypesGroups
  );
  let sceneElementTypesGroups = elementTypesGroups.filter(
    (g) => g.sceneId === scene?.id
  );
  if (filterByScope && scope?.data?.typesGroupIds) {
    sceneElementTypesGroups = sceneElementTypesGroups.filter((g) =>
      scope.data.typesGroupIds.includes(g.id)
    );
  }
  if (filterByTypesGroupsIds) {
    sceneElementTypesGroups = sceneElementTypesGroups.filter((g) =>
      filterByTypesGroupsIds.includes(g.id)
    );
  }

  let remoteTypes = [];
  sceneElementTypesGroups.forEach((group) => {
    if (Array.isArray(group?.elementTypes))
      remoteTypes = [
        ...remoteTypes,
        ...group?.elementTypes.map((t) => ({
          ...t,
          groupId: group.id,
          group: group.name,
          groupCode: group.code,
          fromScene: false,
        })),
      ];
  });

  // result

  let types = [...sceneTypes, ...remoteTypes];

  if (sortTypesByNum) types = types.sort((a, b) => sortByNum(a.num, b.num));

  const [clean, duplicates] = removeItemsWithDuplicatedId(types);
  if (duplicates.length > 0) {
    console.log("WARNING : duplicated elementTypes", duplicates);
  }

  // if (sceneModule === "EDIT_MEASUREMENTS") {
  //   const groups = selectedModel?.restrictedTypes;
  //   if (groups?.length > 0) {
  //     const gs = new Set(groups);
  //     types = types.filter((t) => gs.has(t.groupId));
  //   }
  // }

  return clean;
}
