import {Box, Typography} from "@mui/material";

import SelectorScopeGrid from "Features/scopes/components/SelectorScopeGrid";
import useScopesByScene from "Features/scopes/hooks/useScopesByScene";

export default function SectionSelectorScopeGrid({scene, onOpen, onSeeDetail}) {
  // strings

  const selectS = "Sélectionnez une sous-bimbox";

  // data

  const scopes = useScopesByScene(scene, {sortByName: true, addScopeAll: true});

  // handlers

  function handleOpen(scope) {
    onOpen(scope);
  }
  function handleSeeDetail(scope) {
    onSeeDetail(scope);
  }
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        bgcolor: "common.caplaBlack",
        p: 3,
        width: 1,
      }}
    >
      <Box>
        <SelectorScopeGrid
          scene={scene}
          scopes={scopes}
          onOpen={handleOpen}
          onSeeDetail={handleSeeDetail}
          title={selectS}
        />
      </Box>
    </Box>
  );
}
