import articleTypesMapToArray from "./articleTypesMapToArray";

export default function getParentItemsOfOneArticleTypesItem(
  articleTypes,
  item
) {
  // compute
  const typesArray = articleTypesMapToArray(articleTypes);
  const parentItems = typesArray.filter((type) => {
    let test = false;
    if (type.groupId && type.typeId && type.typeId !== item.typeId) {
      if (type.typeNum && item.typeNum) {
        if (item.typeNum.startsWith(type.typeNum)) {
          test = true;
        }
      }
    }
    return test;
  });

  return parentItems;
}
