// DEPRECATED ? (used in CardZoneSelectionDetail)

import {useDispatch, useSelector} from "react-redux";

import {Box, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

import {updateModel} from "../viewer3DSlice";

export default function ToggleShowMainPart({modelId, editor3d}) {
  const dispatch = useDispatch();

  // strings

  const originS = "Image d'origine";
  const mainPartS = "Image modifiée";

  // data

  const model = useSelector((s) => s.viewer3D.models).find(
    (m) => m.id === modelId
  );

  // helpers

  const entity = editor3d?.getEntity(modelId);
  const mode = model?.showMainPart ? "MAIN_PART" : "ORIGIN";

  // handlers

  function handleModeChange(mode) {
    const checked = mode === "MAIN_PART";
    const updatedModel = {...model, showMainPart: checked};
    dispatch(updateModel({updatedModel, sync: false}));
    if (checked) {
      if (entity?.showMainPart) entity.showMainPart();
    } else {
      if (entity?.hideMainPart) entity.hideMainPart();
    }
  }

  return (
    <Box>
      <ToggleButtonGroup
        exclusive
        value={mode}
        onChange={(e, v) => handleModeChange(v)}
        size="small"
        sx={{height: 30}}
      >
        <ToggleButton value="ORIGIN">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{fontSize: 13}}>{originS}</Typography>
          </Box>
        </ToggleButton>

        <ToggleButton value="MAIN_PART">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",

              justifyContent: "center",
            }}
          >
            <Typography sx={{fontSize: 13}}>{mainPartS}</Typography>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
