import React from "react";

import {alpha, Typography, Box} from "@mui/material";

export default function CallToActionLoadModel({type}) {
  const loadIfcModelString = `Cliquez sur + pour ajouter un fichier IFC`;
  const loadImageModelString = `Cliquez sur + pour ajouter une image`;

  const loadModelStrings = {
    IFC: loadIfcModelString,
    IMAGE: loadImageModelString,
  };
  const callToActionString = loadModelStrings[type];

  return (
    <Box
      sx={{
        p: (theme) => theme.spacing(5, 1, 5, 1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: (theme) => alpha(theme.palette.background.default, 0.8),
      }}
    >
      <Typography variant="body2" align="center">
        {callToActionString}
      </Typography>
    </Box>
  );
}
