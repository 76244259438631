import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Save} from "@mui/icons-material";

import {
  setIsEditingElementType,
  // setEditedElementType,
  updateElementTypesGroup,
} from "../elementTypesSlice";

// import useSelectedElementType from "../hooks/useSelectedElementType";
import useUpdateSceneElementType from "../hooks/useUpdateSceneElementType";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import updateElementTypesGroupWithOneUpdatedType from "../utils/addElementTypeToElementTypesGroup";
import {
  setEditedRelations,
  setRelationsTypesRessources,
  setRelationsTypesRessourcesToRestore,
} from "Features/relations/relationsSlice";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

export default function ActionsElementTypeInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Save";
  // const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.elementTypes.isEditingElementType);
  // const selectedElementType = useSelectedElementType(scene);
  const editedElementType = useSelector(
    (s) => s.elementTypes.editedElementType
  );
  const selectedGroup = useSelectedElementTypesGroup(scene);

  const updateElementType = useUpdateSceneElementType(scene);
  const relationsToRestore = useSelector(
    (s) => s.relations.relationsTypesRessourcesToRestore
  );
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const editedRelations = useSelector((s) => s.relations.editedRelations);

  // sceneData

  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  // function handleEditClick() {
  //   dispatch(setIsEditingElementType(true));
  //   dispatch(setEditedElementType({...selectedElementType}));
  // }

  function handleCancelClick() {
    dispatch(setRelationsTypesRessources(relationsToRestore));
    dispatch(setRelationsTypesRessourcesToRestore(null));
    dispatch(setIsEditingElementType(false));
    dispatch(setEditedRelations({}));
  }

  async function handleSaveClick() {
    const typeToUpdate = {...editedElementType};
    const sceneData = {ressources, elementTypes};
    //
    console.log(
      "updateElementType newName:",
      editedElementType?.name,
      editedElementType,
      "group",
      selectedGroup
    );
    //
    if (relations && Object.keys(editedRelations).length > 0) {
      const itemRelationKeys = Object.keys(relations).filter((k) =>
        k.startsWith(editedElementType.id)
      );
      typeToUpdate.res = itemRelationKeys.map((k) => relations[k]);
      const modelIds = new Set();
      const measurementsToUpdate = [];
      caplaEditor?.measDataManager
        .getAllMeasurements()
        .filter((m) => m.elementTypeId === typeToUpdate.id)
        .forEach((m) => {
          measurementsToUpdate.push({
            ...m,
            res: typeToUpdate?.res
              ? typeToUpdate.res.map(({resId}) => ({resId, off: false}))
              : [],
          });
          modelIds.add(m.measurementsModelId);
        });
      caplaEditor?.measDataManager.updateMeasurements(measurementsToUpdate);
      for (const modelId of modelIds) {
        caplaEditor?.measDataManager.saveModificationsForModelById(
          modelId,
          sceneData
        );
      }
      dispatch(setEditedRelations({}));
    }
    if (typeToUpdate.fromScene) {
      updateElementType({...typeToUpdate});
    } else {
      const updatedGroup = updateElementTypesGroupWithOneUpdatedType(
        typeToUpdate,
        selectedGroup
      );
      setLoading(true);
      await dispatch(
        updateElementTypesGroup({accessToken, elementTypesGroup: updatedGroup})
      );
      setLoading(false);
    }
    dispatch(setIsEditingElementType(false));
    dispatch(setRelationsTypesRessourcesToRestore(null));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )} */}
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
            disabled={loading}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
