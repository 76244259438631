import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsMeasurements from "./SectionActionsMeasurements";

export default function SectionLeftPanelActionsMeasurements({
  scene,
  caplaEditor,
}) {
  // data

  const sceneModule = useSceneModule();

  // actions - main

  const actionsMainKeys = [
    "createElementType",
    "updateRessourcesFromTypes",
    "refresh3D",
    "automation",
  ];
  const actionsMoreKeys = [
    "copyMeasurements",
    "pasteMeasurements",
    "divider",
    "extractMeasFromPdf",
    "divider",
    "useTypeColor",
    "cleanMeasurements",
  ];

  return (
    <Box>
      {sceneModule === "EDIT_MEASUREMENTS" && (
        <SectionActionsMeasurements
          scene={scene}
          caplaEditor={caplaEditor}
          actionsMainKeys={actionsMainKeys}
          actionsMoreKeys={actionsMoreKeys}
        />
      )}
    </Box>
  );
}
