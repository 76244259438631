import {useDispatch} from "react-redux";

import {setOpenFilterPanel} from "Features/filters/filtersSlice";

import {Box} from "@mui/material";

import ContainerContextOverviewActions from "./ContainerContextOverviewActions";
import ContainerContextOverviewCounter from "./ContainerContextOverviewCounter";
// import ContainerContextOverviewFilter from "./ContainerContextOverviewFilter";

export default function ContainerContextOverview({
  count,
  bgcolor,
  showFilters,
  filteredCount,
  filtersCount,
  modificationsCount,
  onSaveClick,
  shrink,
  onCountClick,
}) {
  const dispatch = useDispatch();
  const showModifications = modificationsCount > 0;

  // handlers

  function handleFilterClick() {
    dispatch(setOpenFilterPanel(true));
  }

  function handleCountClick() {
    if (onCountClick) onCountClick();
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        //p: 0.5,
        borderRadius: "20px",
        ...(showModifications && {bgcolor: "warning.main"}),
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <ContainerContextOverviewCounter
          count={count}
          onCountClick={onCountClick}
          showFilters={showFilters}
          filteredCount={filteredCount}
          filtersCount={filtersCount}
          onFilterClick={handleFilterClick}
          shrink={shrink}
          bgcolor={bgcolor}
        />
      </Box>
      {showModifications && (
        <Box sx={{ml: 1, pr: 1}}>
          <ContainerContextOverviewActions
            onSaveClick={onSaveClick}
            modificationsCount={modificationsCount}
          />
        </Box>
      )}
    </Box>
  );
}
