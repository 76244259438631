import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormSector({
  sector,
  onChange,
  hiddenFields = [],
  canEdit,
}) {
  // strings

  // const numS = "Num";
  const codeS = "Code";
  const nameS = "Name";
  const zS = "Z (m)";
  const buildingS = "Building";

  // template

  const template = {
    key: "kvfSector",
    fields: [
      //{key: "num", type: "number", name: numS},
      {key: "code", type: "text", name: codeS},
      {key: "name", type: "text", name: nameS},
      {key: "z", type: "number", name: zS},
      {key: "building", type: "text", name: buildingS},
    ],
  };
  return sector ? (
    <KeyValueForm
      item={sector}
      template={template}
      onChange={onChange}
      hiddenFields={hiddenFields}
      canEdit={canEdit}
    />
  ) : (
    <div />
  );
}
