import {useSelector, useDispatch} from "react-redux";

import {Box, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

import {setView3DMode} from "../progressSlice";

export default function ToggleProgressView3DMode({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const toDoS = "TO DO";
  const doneS = "DONE";

  // data

  const mode = useSelector((s) => s.progress.view3DMode);
  const measurementsProgress = useSelector(
    (s) => s.progress.measurementsProgress
  );

  // handlers

  function handleModeChange(mode) {
    if (!mode) return;
    dispatch(setView3DMode(mode));
    editor3d?.sceneEditor.updateLayoutFromProgress({measurementsProgress, mode});
  }

  return (
    <Box>
      <ToggleButtonGroup
        exclusive
        value={mode}
        onChange={(e, v) => handleModeChange(v)}
        size="small"
        sx={{height: 30, color: "common.white"}}
      >
        <ToggleButton
          value="DONE"
          sx={{
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{fontSize: 13, color: "common.white"}}>
              {doneS}
            </Typography>
          </Box>
        </ToggleButton>

        <ToggleButton
          value="TODO"
          sx={{
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{fontSize: 13, color: "common.white"}}>
              {toDoS}
            </Typography>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
