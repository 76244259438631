import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import {DataGrid} from "@mui/x-data-grid";

// import {format} from "date-fns";

import {setSelectedPhase, setSelectedPhaseIds} from "../phasesSlice";
// import useSelectedPhase from "../hooks/useSelectedPhase";
import usePhasesByScene from "../hooks/usePhasesByScene";
import phasesForDataGrid from "../utils/phasesForDataGrid";

// import useTranslation from "Features/translations/useTranslation";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridPhases({scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  const numS = "Num";
  // const codeS = "Code";
  const nameS = "Name";
  // const unitS = "Unit";

  // data

  const phases = usePhasesByScene(scene);
  const selectedPhase = useSelector((s) => s.phases.selectedPhase);
  const selectionModel = selectedPhase ? [selectedPhase.id] : [];

  // grid - rows

  const rows = phasesForDataGrid(phases);

  // grid - columns

  const columns = [
    {
      field: "num",
      headerName: numS,
      width: 100,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const phaseId = selection[0];
    if (selectedPhase && selectedPhase.id === phaseId)
      dispatch(setSelectedPhase(null));
    else
      dispatch(setSelectedPhase(phases.find((p) => p.id === phaseId)));
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--NoItem": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
        getRowClassName={(params) => {
          if (params.row.isTitle && params.row.rank === 1)
            return `super-app-theme--Title1`;
          if (params.row.isTitle && params.row.rank === 2)
            return `super-app-theme--Title2`;
          if (params.row.parent && params.row.count > 0)
            return `super-app-theme--Child`;
          if (params.row.count === 0) return `super-app-theme--NoItem`;
        }}
      />
    </Box>
  );
}
