import getItemsMapById from "Utils/getItemsMapById";

export default function getMeasurementsByRessourceAndRessourceGroup(
  measurements,
  ressources
) {
  const byResMap = {}; // {resId:{modelId:measCount}
  const byResGroupMap = {};

  const ressourcesById = getItemsMapById(ressources);

  measurements.forEach((measurement) => {
    if (Array.isArray(measurement.res)) {
      measurement.res.forEach(({resId: measResId}) => {
        const measModelId = measurement.measurementsModelId;
        const measResGroupId = ressourcesById[measResId]?.groupId;

        if (measResId) {
          // ressource

          const byRes = byResMap[measResId];
          if (!byRes) {
            byResMap[measResId] = {[measModelId]: 1};
          } else if (!byRes[measModelId]) {
            byResMap[measResId][measModelId] = 1;
          } else {
            byResMap[measResId][measModelId] += 1;
          }

          // ResGroup

          const byResGroup = byResGroupMap[measResGroupId];
          if (!byResGroup) {
            byResGroupMap[measResGroupId] = {[measModelId]: 1};
          } else if (!byResGroup[measModelId]) {
            byResGroupMap[measResGroupId][measModelId] = 1;
          } else {
            byResGroupMap[measResGroupId][measModelId] += 1;
          }
        }
      });
    }
  });

  return [byResMap, byResGroupMap];
}
