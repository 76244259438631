import React from "react";

import {useDispatch} from "react-redux";
import useSelectionMulti from "../useSelectionMulti";
import {
  setSelectedMeasurementIds,
  // setSelectedMeasurementId,
} from "Features/measurements/measurementsSlice";

import {IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function ButtonResetSelectionMulti({caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectionMulti = useSelectionMulti();

  // helpers

  const mnger = caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;

  // handlers

  function handleClose() {
    switch (selectionMulti?.type) {
      case "MEASUREMENTS":
        {
          dispatch(setSelectedMeasurementIds([]));
          const ids = selectionMulti.items;
          mnger.unselectMeasurements(ids);
        }

        // viewer3D
        caplaEditor?.editor3d?.sceneEditor.unselect();
    }
  }
  return (
    <IconButton size="small" onClick={handleClose}>
      <Close fontSize="small" />
    </IconButton>
  );
}
