function getRankNum(num) {
  if (!num) return 0;
  return num.split(".").length;
}

function getNextBrother(num) {
  const numArray = num.split(".");
  const suffix = numArray.pop();
  const nextSuffix = (Number(suffix) + 1).toString();
  return [...numArray, nextSuffix].join(".");
}

function getParent(num) {
  const numArray = num.split(".");
  return [...numArray.pop()].join(".");
}

export default function getNextNum(num, nums) {
  if (!Array.isArray(nums)) return "0";

  const length = nums.length;
  const numIndex = nums.indexOf(num);
  const isLastNum = length === numIndex + 1;

  if (isLastNum) {
    return getNextBrother(num);
  } else {
    const follower = nums[numIndex + 1];
    const rankDiffNumVsFollower = getRankNum(num) - getRankNum(follower);
    if (rankDiffNumVsFollower >= 0) {
      return getNextBrother(num);
    } else {
      return num + ".1";
    }
  }
}
