import {Box, Typography} from "@mui/material";
import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";
import ButtonDownloadExcelElementTypeGroupGeneric from "Features/elementTypes/components/ButtonDownloadExcelElementTypeGroupGeneric";

import getElementTypesFromFile from "Features/excel/utils/getElementTypesFromFile";

export default function SectionImportPdf({scene, onFileChange}) {
  // strings

  const addS = "Charger un plan au format PDF";

  // handlers

  async function handleFilesChange(files) {
    const file = files[0];
    onFileChange(file);
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      <Box sx={{color: "text.secondary", p: 1}}>
        <Typography variant="body2">{addS}</Typography>
      </Box>

      <Box sx={{flexGrow: 1}}>
        <ContainerFilesSelector
          onFilesChange={handleFilesChange}
          accept=".pdf"
          multiple={false}
        />
      </Box>
    </Box>
  );
}
