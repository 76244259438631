import {useState, useEffect} from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import SectionAddInputs from "./SectionAddInputs";

export default function DialogSelectFilesToCreateInputs({
  caplaEditor,
  scene,
  open,
  onClose,
}) {
  // handlers

  function handleClose() {
    if (onClose) onClose();
  }

  function handleFilesChange(files) {
    console.log("Add files 33", files);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Box
          sx={{
            width: "300px",
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <SectionAddInputs
            caplaEditor={caplaEditor}
            scene={scene}
            onFilesChange={handleFilesChange}
            onInputsAdded={handleClose}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
