import {useEffect, useState} from "react";
import {fetchWorksites} from "./worksitesSlice";

import {useSelector, useDispatch} from "react-redux";

import useAccessToken from "Features/auth/useAccessToken";

export default function useWorksites() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const status = useSelector((state) => state.worksites.status);
  const worksites = useSelector((state) => state.worksites.items);

  // state

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (status == "idle" && accessToken && !fetched) {
      dispatch(fetchWorksites({accessToken}));
      setFetched(true);
    }
  }, [fetched, accessToken, status]);

  return worksites;
}
