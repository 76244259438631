import {seDispatch} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box, Typography} from "@mui/material";

import SectionConfigModes from "./SectionConfigModes";
import SectionConfigModules from "./SectionConfigModules";
import CheckboxIsStagingEnv from "Features/overviewer/hooks/CheckboxIsStagingEnv";

export default function PageConfigModules() {
  const {caplaEditor, scene} = useOutletContext();

  // strings

  const title = "Fonctionnalités de votre bimbox";

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "common.caplaBlack",
        color: "common.white",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        borderLeft: (theme) => `1px solid ${theme.palette.grey[700]}`,
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 6,
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box sx={{mt: 1, width: 1}}>
        <SectionConfigModes scene={scene} />
      </Box>

      <Box
        sx={{
          p: 2,
          px: 6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SectionConfigModules scene={scene} />
      </Box>
      <Box
        sx={{
          px: 2,
          //bgcolor: "grey.600",
          position: "absolute",
          bottom: "8px",
          left: "8px",
          borderRadiux: "8px",
        }}
      >
        <CheckboxIsStagingEnv />
      </Box>
    </Box>
  );
}
