import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
// import {unwrapResult} from "@reduxjs/toolkit";


import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import MobileLayout from "./layouts/MobileLayout";
import DesktopLayout from "./layouts/DesktopLayout";

import {fetchSharedRemoteScene} from "Features/scenes/scenesSlice";
// import useScenes from "Features/scenes/useScenes";
// import useAccessToken from "Features/auth/useAccessToken";

export default function SharedScene() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const {shareLink} = useParams();
  const dispatch = useDispatch();

  const {scene, status} = useSelector((state) => state.scenes.sharedRemoteScene);
  console.log("remote scene", scene);

  useEffect(() => {
    if (status === "idle" && shareLink) {
      console.log("fetching remote scene");
      dispatch(fetchSharedRemoteScene({shareLink}));
    }
  }, [status, shareLink]);

  return (
    <div>
      {matches ? (
        <DesktopLayout scene={scene} />
      ) : (
        <MobileLayout scene={scene} />
      )}
    </div>
  );
}
