import getFuncObjectFromFunc from "./getFuncObjectFromFunc";

export default function getFuncObjectFromMultipleFunc(relations) {
  if (!Array.isArray(relations) || relations.length === 0)
    return {formula: "", filter: ""};

  let formula = "";
  let filter = "";
  
  const firstRelation = relations[0];
  const funcObject = getFuncObjectFromFunc(firstRelation.func);
  formula = funcObject.formula;
  filter = funcObject.filter;
  
  if (relations.length > 1) {
    for (let i = 1; i < relations.length; i++) {
      const relation = relations[i];
      const funcObject_i = getFuncObjectFromFunc(relation.func);
      const formula_i = funcObject_i.formula;
      const filter_i = funcObject_i.filter;
      if (formula_i !== formula) formula = "";
      if (filter_i !== filter) filter = "";
    }
  }

  return {formula, filter};
}
