import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import HeaderTools from "./components/HeaderTools";
import ShortcutMarkers from "./components/ShortcutMarkers";
import ShortcutPdfMarkers from "./components/ShortcutPdfMarkers";
import ShortcutPdfElements from "./components/ShortcutPdfElements";
import ShortcutStoreys from "./components/ShortcutStoreys";
import ShortcutZones from "./components/ShortcutZones";
import ShortcutZonesInPdf from "./components/ShortcutZonesInPdf";
import ShortcutSectors from "./components/ShortcutSectors";
import ShortcutPhases from "./components/ShortcutPhases";
import ShortcutModels from "./components/ShortcutModels";
import ShortcutScenes from "./components/ShortcutScenes";
import ShortcutMeasurements from "./components/ShortcutMeasurements";
import ShortcutMeasurementsBuiltAtInPdf from "./components/ShortcutMeasurementsBuiltAtInPdf";
import ShortcutMeasurementsBuiltAt from "./components/ShortcutMeasurementsBuiltAt";
import ShortcutElevations from "./components/ShortcutElevations";
import ShortcutIssues from "./components/ShortcutIssues";
import ShortcutClippingPlanes from "./components/ShortcutClippingPlanes";
import ShortcutTypes from "./components/ShortcutTypes";
import ShortcutElementTypes from "./components/ShortcutElementTypesIn3D";
import ShortcutElementTypesInPdf from "./components/ShortcutElementTypesInPdf";
import HeaderShortcut from "./components/HeaderShortcut";

import {setShortcutToolViewer3D} from "Features/ui/uiSlice";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import ShortcutVersions from "./components/ShortcutVersions";
// import SectionLeftSelectionInViewer from "Features/leftPanel/components/SectionLeftSelectionInViewer";
// import ShortcutZonesInViewer from "Features/shortcut/components/ShortcutZonesInViewer";

export default function ToolShortcuts({
  caplaEditor,
  scene,
  space,
  sceneViewer = "3D",
  viewer,
}) {
  const dispatch = useDispatch();
  // data

  const rendererSize = useSelector((s) => s.viewer3D.rendererSize);
  const toolViewer3D = useSelector((s) => s.ui.shortcutToolViewer3D);

  // state

  //let [tool, setTool] = useState("ISSUES");
  let [tool, setTool] = useState();

  if (sceneViewer === "3D") tool = toolViewer3D;

  // helpers

  const maxHeight = rendererSize.height - 300;

  const mode = useMeasurementsScopeMode(scene, viewer);

  // handler

  function handleToolChange(tool) {
    setTool(tool);
    if (
      !tool ||
      [
        "SCENES",
        "SECTORS",
        "ZONES",
        "PHASES",
        "MODELS",
        "ELEMENT_TYPES",
        "MEASUREMENTS_BUILT_AT",
        "ISSUES",
        "ELEVATIONS",
        "VERSIONS",
      ].includes(tool)
    ) {
      dispatch(setShortcutToolViewer3D(tool));
    }
  }

  return (
    <Box sx={{display: "flex"}}>
      <HeaderTools
        tool={tool}
        toolViewer3D={toolViewer3D}
        onChange={handleToolChange}
        space={space}
        sceneViewer={sceneViewer}
        caplaEditor={caplaEditor}
        scene={scene}
        viewer={viewer}
      />
      <Box>
        <HeaderShortcut
          scene={scene}
          caplaEditor={caplaEditor}
          viewer={viewer}
          tool={tool}
        />
        <Box
          sx={{
            // mt: 1,
            overflow: "hidden",
            //width: 250,
          }}
        >
          {(tool || toolViewer3D) && tool !== "SELECTION" && (
            <Box
              sx={{
                display: tool || toolViewer3D ? "flex" : "none",
                maxHeight: `${maxHeight}px`,
                overflowY: "scroll",
                width: 1,
                mr: "-50px",
                pr: "50px",
                pb: 2,
                pl: 1,
                boxSizing: "content-box",
                //bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
              }}
            >
              <Box sx={{mt: 1}}>
                {tool === "SCENES" && <ShortcutScenes />}
                {tool === "MODELS" && <ShortcutModels scene={scene} />}
                {tool === "STOREYS" && (
                  <ShortcutStoreys
                    editor3d={caplaEditor?.editor3d}
                    scene={scene}
                  />
                )}
                {tool === "TYPES" && (
                  <ShortcutTypes
                    editor3d={caplaEditor?.editor3d}
                    scene={scene}
                  />
                )}
                {tool === "ELEMENT_TYPES" && (
                  <ShortcutElementTypes
                    scene={scene}
                    mode={mode}
                    viewer={viewer}
                  />
                )}
                {tool === "MEASUREMENTS_BUILT_AT" && (
                  <ShortcutMeasurementsBuiltAt scene={scene} mode={mode} />
                )}
                {tool === "CLIPPING_PLANES" && (
                  <ShortcutClippingPlanes
                    editor3d={caplaEditor?.editor3d}
                    scene={scene}
                  />
                )}
                {tool === "MARKERS" && (
                  <ShortcutMarkers
                    editor3d={caplaEditor?.editor3d}
                    scene={scene}
                  />
                )}
                {tool === "PDF_MARKERS" && (
                  <ShortcutPdfMarkers caplaEditor={caplaEditor} />
                )}
                {tool === "PDF_ELEMENTS" && (
                  <ShortcutPdfElements
                    editorPdf={caplaEditor?.editorPdf}
                    scene={scene}
                  />
                )}
                {tool === "SECTORS" && (
                  <ShortcutSectors scene={scene} viewer={viewer} />
                )}
                {tool === "PHASES" && <ShortcutPhases scene={scene} />}
                {tool === "ZONES" && (
                  <ShortcutZones
                    editor3d={caplaEditor?.editor3d}
                    scene={scene}
                  />
                )}
                {/* {tool === "ZONES" && viewer && (
                <ShortcutZonesInViewer
                  caplaEditor={caplaEditor}
                  scene={scene}
                />
              )} */}
                {tool === "ZONES_IN_PDF" && (
                  <ShortcutZonesInPdf
                    editorPdf={caplaEditor?.editorPdf}
                    scene={scene}
                    viewer={viewer}
                  />
                )}
                {tool === "PDF_MEASUREMENTS" && (
                  <ShortcutMeasurements editorPdf={caplaEditor?.editorPdf} />
                )}
                {tool === "PDF_MEASUREMENTS_BUILT_AT" && (
                  <ShortcutMeasurementsBuiltAtInPdf scene={scene} mode={mode} />
                )}
                {tool === "PDF_ELEMENT_TYPES" && (
                  <ShortcutElementTypesInPdf
                    scene={scene}
                    mode={mode}
                    viewer={viewer}
                    editorPdf={caplaEditor?.editorPdf}
                  />
                )}
                {tool === "ELEVATIONS" && (
                  <ShortcutElevations scene={scene} viewer={viewer} />
                )}
                {tool === "ISSUES" && (
                  <ShortcutIssues
                    caplaEditor={caplaEditor}
                    scene={scene}
                    viewer={viewer}
                  />
                )}
                {tool === "VERSIONS" && (
                  <ShortcutVersions
                    caplaEditor={caplaEditor}
                    scene={scene}
                    viewer={viewer}
                  />
                )}
              </Box>
            </Box>
          )}
          {/* <Box
          sx={{
            display: tool === "SELECTION" && !viewer ? "flex" : "none",
            p: 1,
            width: 1,
            boxSizing: "border-box",
          }}
        >
          <SectionLeftSelectionInViewer
            caplaEditor={caplaEditor}
            scene={scene}
            viewer={viewer}
          />
        </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
