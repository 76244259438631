import {useState} from "react";
import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useSelectedRessource from "../hooks/useSelectedRessource";
import useDeleteSceneRessource from "../hooks/useDeleteSceneRessource";

export default function DialogDeleteRessource({scene, open, onClose}) {
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedRessource = useSelectedRessource(scene);
  const deleteRessource = useDeleteSceneRessource(scene);

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    await deleteRessource(selectedRessource);
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="ressourceItem"
      loading={loading}
    />
  );
}
