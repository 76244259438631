import computeRessourceQuantity, {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import sortByNum from "Features/ressources/utils/sortByNum";

export default function getRessourcesByVersion({
  measurements,
  elementTypes,
  ressources,
  versions,
  rooms,
  filterNoVoids,
  sectors,
  zones,
}) {
  if (!measurements || !versions) return [];

  try {

    // helpers

    const zonesById = {};
    for (const item of zones) {
      zonesById[item.id] = item;
    }
    const versionsById = {};
    for (const item of versions) {
      versionsById[item.id] = item;
    }
    const roomsById = {};
    for (const item of rooms) {
      roomsById[item.id] = item;
    }
    const sectorsById = {};
    for (const item of sectors) {
      sectorsById[item.id] = item;
    }
    const ressourcesById = {};
    for (const item of ressources) {
      ressourcesById[item.id] = item;
    }
    const elementTypesById = {};
    for (const item of elementTypes) {
      elementTypesById[item.id] = item;
    }

    const priorVersionMap = {};
    const sortedVersionsIds = ["-?-"];
    versions.sort((a, b) => a.num.toString() - b.num.toString()).forEach((v, i) => {
      if (i === 0) priorVersionMap[v.id] = "-?-";
      else priorVersionMap[v.id] = versions[i - 1]?.id;
      sortedVersionsIds.push(v.id);
    })
  
    // measurements & ressources

    const resByVersion = {}; // {resId:{versionId:Qty}]}
    measurements.forEach((measurement) => {
      const res = measurement.res;
      if (!res) return;
      const versionId = measurement.versionId ?? "-?-";
      const elementType = elementTypesById[measurement.elementTypeId];
      res
        .filter((r) => !r.off)
        .forEach((mr) => {
          const resId = mr.resId;
          const ressource = ressourcesById[resId];
          if (!ressource) return;
          let res = elementType?.res?.find((r) => resId === r.resId);
          if (!res) res = {resId, mode: "AUTO"}; // if formula not in elementType, by default, we compute with mode="AUTO"
          const useMeas = applyFormulaFilter(
            measurement,
            res?.func,
            zonesById[measurement.zoneId],
            roomsById[measurement.roomId],
            sectorsById[measurement.sectorId],
            filterNoVoids
          );
          if (useMeas) {
            // let mQ = filterNoVoids ? mr.qtyRaw : mr.qtyNet;
            let mQ;
            if (!mQ) {
              mQ = computeRessourceQuantity({
                item: measurement,
                res,
                ressource,
                withDecimal: false,
                filterNoVoids,
              });
            }
            if (measurement.isDeletedInV) {
              if (versionId !== "-?-") {
                const priorVersion = priorVersionMap[versionId]
                if (!resByVersion[resId]) {
                  resByVersion[resId] = {[priorVersion]: mQ};
                } else if (resByVersion[resId] && !resByVersion[resId][priorVersion]) {
                  resByVersion[resId][priorVersion] = mQ;
                } else if (resByVersion[resId] && resByVersion[resId][priorVersion]) {
                  resByVersion[resId][priorVersion] += mQ;
                }
              }
              mQ *= -1;
            }
            if (!resByVersion[resId]) {
              resByVersion[resId] = {[versionId]: mQ};
            } else if (resByVersion[resId] && !resByVersion[resId][versionId]) {
              resByVersion[resId][versionId] = mQ;
            } else if (resByVersion[resId] && resByVersion[resId][versionId]) {
              resByVersion[resId][versionId] += mQ;
            }
          }
        });
    });
    const result = [];
    for (const ressource of ressources.sort((a, b) => sortByNum(a.num, b.num))) {
      const qtyByVersion = resByVersion[ressource.id];
      if (!qtyByVersion) result.push(ressource);
      else {
        const quantity = Object.values(qtyByVersion).reduce((acc, cv) => {
          return acc + cv
        }, 0);
        result.push({
          ...ressource,
          quantity,
          byVersion: qtyByVersion,
        });
      }
    }
    return result;
  } catch (e) {
    console.log(e);
  }
}
