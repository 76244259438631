import React from "react";

import {Typography, IconButton, Box} from "@mui/material";
import {ContentCopy as Copy} from "@mui/icons-material";

export default function CardBimappUrl({bimapp}) {
  // helpers

  const url = `/${bimapp.id}`;
  const baseURL = process.env.REACT_APP_BIMAPPS_BASE_URL;
  const link = `${baseURL}${url}`;

  // handlers

  function handleCopyLink() {
    navigator.clipboard.writeText(link);
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
        px: 1,
      }}
    >
      <Typography sx={{fontSize: 12}} color="text.secondary" noWrap>
        {link}
      </Typography>
      <IconButton size="small" onClick={handleCopyLink}>
        <Copy fontSize="small" />
      </IconButton>
    </Box>
  );
}
