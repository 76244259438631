import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";

import {Box, Paper, Typography, IconButton, Tooltip} from "@mui/material";
import {Palette} from "@mui/icons-material";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

export default function CardPackageInHome({caplaEditor, scene, model}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id ?? scene?.clientId;
  const isStagingEnv = useIsStagingEnv();
  const navigate = useNavigate();

  // strings

  const editS = "Repérez les ouvrages";

  // handler

  function handleEdit() {
    const modelId = model.id;
    dispatch(setSelectedMeasurementsModelId(modelId));
    let url = `/bimboxes/${sceneId}/${modelId}/measurements`;
    if (isStagingEnv) url = url + "?env=staging";
    navigate(url);
  }

  return (
    <Box sx={{p: 1, minWidth: 0, display: "flex", width: 1}}>
      <Paper
        elevation={6}
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: 0,
          width: 1,
        }}
      >
        <Typography variant="body2" noWrap>
          {model.name}
        </Typography>

        <Tooltip title={editS}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              bgcolor: "common.yellow",
              color: "common.white",
            }}
          >
            <IconButton size="small" color="inherit" onClick={handleEdit}>
              <Palette fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      </Paper>
    </Box>
  );
}
