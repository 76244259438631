import {useDispatch, useSelector} from "react-redux";

import {Box, Button} from "@mui/material";

import ListItemZone from "./ListItemZone";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import {
  setUseBufferInShortcut,
  setZonesInBuffer,
} from "Features/zones/zonesSlice";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ShortcutZones({editor3d, scene}) {
  const dispatch = useDispatch();

  // strings

  const emptyBufferS = "x : vider la sélection";

  // data

  const zones = useZonesByScene(scene);
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);
  const zonesInBuffer = useSelector((s) => s.zones.zonesInBuffer);
  const sectorsInScope = useSelector((s) => s.measurements.sectorsInScope);
  const roomsInScope = useSelector((s) => s.measurements.roomsInScope);
  const zonesInScope = useSelector((s) => s.measurements.zonesInScope);
  let modelIdsFromIssue = useSelector((s) => s.issues.imageModelIdsFromIssue);
  const imageModels = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.type === "IMAGE"
  );

  // helpers

  let scopedZones = zones;
  if (zonesInScope.length > 0) {
    const zscope = new Set(zonesInScope);
    scopedZones = scopedZones.filter((z) => zscope.has(z.id));
  }
  if (roomsInScope.length > 0) {
    const rs = new Set(roomsInScope);
    scopedZones = scopedZones.filter((z) => rs.has(z.roomId));
    if (rs.has(null))
      scopedZones = [].concat(scopedZones, [...zones.filter((z) => !z.roomId)]);
  } else if (
    sectorsInScope.length > 0 &&
    sectorsInScope[0] !== undefined &&
    sectorsInScope.slice(-1) !== null
  ) {
    const ss = new Set(sectorsInScope);
    scopedZones = scopedZones.filter((z) => ss.has(z.sectorId));
    if (ss.has(null))
      scopedZones = [].concat(scopedZones, [
        ...zones.filter((z) => !z.sectorId),
      ]);
  }
  modelIdsFromIssue = new Set(modelIdsFromIssue);

  let zonesToShow = scopedZones;
  if (useBuffer)
    zonesToShow = [...zonesInBuffer].sort((a, b) =>
      a.name?.localeCompare(b.name)
    );

  // handlers

  const handleEmptyBuffer = () => {
    zonesInBuffer.forEach((zone) => {
      const zoneModel = imageModels.find((m) => m.id === zone.imageModelId);
      if (zoneModel?.hidden === false) {
        const updatedModel = {...zoneModel, hidden: true};
        dispatch(updateModel({updatedModel, sync: false}));
        const entity = editor3d?.getEntity(zoneModel.id);
        entity?.hide();
      }
    });
    dispatch(setZonesInBuffer([]));
    dispatch(setUseBufferInShortcut(false));
  };

  return (
    <Box sx={{height: 1, pt: 1}}>
      {zonesToShow.map((zone) => (
        <ListItemZone key={zone.id} zone={zone} editor3d={editor3d} />
      ))}
      {useBuffer && (
        <Box sx={{width: 1, color: "text.secondary", pt: 1}}>
          <Button onClick={handleEmptyBuffer} size="small" color="inherit">
            {emptyBufferS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
