import {Box, Tooltip, Typography} from "@mui/material";
import {lighten} from "@mui/material/styles";
import modelLoadingStatusMap from "../modelLoadingStatusMap";
import getModelLoadingStatusNum from "../utils/getModelLoadingStatusNum";
import getStatusKeyFromNum from "../utils/getStatusKeyFromNum";
import theme from "Styles/theme";

export default function ContainerModelLoadingStatus({
  modelId,
  count,
  loadingStatusModelIdsMap,
}) {
  const num = getModelLoadingStatusNum(modelId, loadingStatusModelIdsMap);
  const key = getStatusKeyFromNum(num);
  const status = modelLoadingStatusMap[key];

  const color = status?.color ? status.color : theme.palette.warning.main;

  return (
    <Tooltip title={status.label}>
      <Box
        sx={{
          width: 18,
          height: 18,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "common.white",
          bgcolor: count === 0 ? lighten(color, 0.5) : color,
          borderRadius: "50%",
        }}
      >
        <Typography sx={{fontSize: 12}}>{count === 0 ? "." : count}</Typography>
      </Box>
    </Tooltip>
  );
}
