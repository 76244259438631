import {useState} from "react";
import useDeletePoi from "../hooks/useDeletePoi";

import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function DialogDeletePoi({scene, open, onClose, onDeleted}) {
  // data - handlers

  const deletePoi = useDeletePoi(scene);

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  async function handleConfirm() {
    console.log("delete poi");
    setLoading(true);
    await deletePoi();
    setLoading(false);
    onDeleted();
  }
  return (
    <DeleteDialog
      open={open}
      onCancel={onClose}
      onConfirm={handleConfirm}
      ressource="poi"
      loading={loading}
    />
  );
}
