export default function getElementTypeChildren(elementTypeIndex, elementTypes) {
  //
  if (!elementTypes) return [];
  //
  const children = [];
  //
  const elementType = elementTypes[elementTypeIndex];
  const elementTypeNum = elementType?.num;
  //
  if (!elementTypeNum || elementTypeIndex === elementTypes?.length - 1) return;
  //
  let stop = false;
  let i = 1;
  let i_max = elementTypes.length - elementTypeIndex;
  //

  while (!stop && i <= i_max) {
    const childIndex = elementTypeIndex + i;
    const child = elementTypes[childIndex];
    if (child) {
      if (child.num?.startsWith(elementTypeNum)) {
        children.push(child);
      } else {
        stop = true;
      }
    }

    i += 1;
  }

  return children;
}
