import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {Box, IconButton, Menu, MenuItem, Paper, Typography} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import { fetchPrices } from "../pricingSlice";
import formatPriceWithSpaces from "../utils/formatPriceWithSpaces";

import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";
import DialogImportPricesFromExcel from "./DialogImportPricesFromExcel";

export default function SectionPricingInViewer({scene}) {
  const dispatch = useDispatch();
  const {shareLink} = useParams();

  // strings

  const priceS = "Prix";
  const noPrice = "Saisissez vos P.U pour chaque article";
  const refreshPriceS = "Rafraîchir";
  const importPricesS = "Importer excel";

  // data

  const quantities = useMeasurementsByRessourceForDatagrid(scene, "ONE_MODEL", 2);
  const pricingTable = useSelector((s) => s.pricing.pricingTable);
  const groupViewer = useSelector((s) => s.measurements.selectedRessourceGroup);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const [openImportDialog, setOpenImportDialog] = useState(false);

  // helpers

  let aggregatedPrice = 0;
  const table = pricingTable[shareLink];
  if (table) {
    for (const [key, value] of Object.entries(table)) {
      const res = quantities.find((q) => q.id === key);
      if (res && res.quantity && res.group === groupViewer)
        aggregatedPrice += res.quantity * value;
    }
  }

  const priceLabel = aggregatedPrice ? `${formatPriceWithSpaces(aggregatedPrice)} €` : "- €";

  // handlers

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMorePricing = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const refreshPrice = () => {
    dispatch(fetchPrices({
      sceneId: scene?.id,
      sharedBimboxId: shareLink,
    }));
    handleMenuClose();
  }

  const importPrices = () => {
    handleMenuClose();
    setOpenImportDialog(true);
  }

  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  // // effect

  // useEffect(() => {
  //   if (scene && shareLink)
  //     dispatch(fetchPrices({
  //       sceneId: scene.id,
  //       sharedBimboxId: shareLink,
  //     }));
  // }, [scene, shareLink])

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      width: 1,
      p: 1,
      mt: 2,
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Paper
        elevation={6}
        size="small"
        sx={{
          color: "common.caplaBlack",
          bgcolor: "stabilo.orange",
          pl: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "15px",
          height: "30px",
          width: 0.6,
        }}
      >
        <Typography sx={{fontSize: 13, fontWeight: "bold"}}>
          {priceS}
        </Typography>
        <Typography sx={{fontSize: 13, fontWeight: "bold"}}>
          {priceLabel}
        </Typography>
        <IconButton
          size="small"
          sx={{px: 0, mx:0, width: "30px", height: "30px"}}
          onClick={handleMorePricing}
        >
          <MoreVert color="inherit" size="small" />
        </IconButton>
      </Paper>
      {!aggregatedPrice && (
        <Typography sx={{fontSize: 12, color: "red", pt: 1}}>
          {noPrice}
        </Typography>
      )}
      <Menu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem
          key={refreshPrice}
          onClick={() => refreshPrice()}
          divider
        >
          <Typography variant="body2">{refreshPriceS}</Typography>
        </MenuItem>
        <MenuItem
          key={importPricesS}
          onClick={() => importPrices()}
        >
          <Typography variant="body2">{importPricesS}</Typography>
        </MenuItem>
      </Menu>
      <DialogImportPricesFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
    </Box>
  );
}