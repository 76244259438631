// TODO description

import { nanoid } from "@reduxjs/toolkit";

import { segmentPolylineIntersection } from "../utils/intersections";

const getZInfZSupForElement = (element, zone) => {
  let zInf = 0;
  let zSup = 0;
  const zoneZ = zone?.position.y ?? 0;
  if (element.zFrom === "zInf") {
    zInf = element.zInf;
    zSup = zInf + element.height;
  } else if (element.zFrom === "zSup") {
    zSup = element.zSup;
    zInf = zSup - element.height;
  } else {
    const offset = element.offset ?? 0;
    zInf = zoneZ + offset;
    zSup = zInf + element.height;
  }
  return { zInf, zSup };
};

export default function addVoids({ measurements, params, data }) {
  const { voidsPackageIds } = params;
  const { measurementsByPackageId, zonesById, typesById } = data;
  let voids = [];
  for (const packageId of voidsPackageIds) {
    const newVoids = measurementsByPackageId[packageId].filter(
      (m) => m.drawingShape === "SEGMENT"
    );
    voids.push(...newVoids);
  }
  voids = voids.map((v) => ({ ...v, id: nanoid(), affectedByProxy: true }));
  const polylinesById = {};
  for (const item of measurements.filter(
    (m) => m.drawingShape === "POLYLINE"
  )) {
    polylinesById[item.id] = item;
  }
  const polylinesVoids = {};
  const usedVoids = new Set();
  for (const v of voids) {
    const voidZ = getZInfZSupForElement(v, zonesById[v.zoneId]);
    if (Array.isArray(v.path3D)) {
      const segment = [v.path3D[0], v.path3D[3]];
      for (const polyline of Object.values(polylinesById)) {
        const polyZ = getZInfZSupForElement(
          polyline,
          zonesById[polyline.zoneId]
        );
        if (
          v.zoneId !== polyline.zoneId ||
          voidZ.zInf > polyZ.zSup ||
          polyZ.zInf > voidZ.zSup
        )
          continue;
        if (
          segmentPolylineIntersection(segment, polyline.centerLine) !== false
        ) {
          usedVoids.add(v.id);
          if (polyline.id in polylinesVoids) {
            polylinesVoids[polyline.id].push(v.id);
          } else {
            polylinesVoids[polyline.id] = [v.id];
          }
        }
      }
    }
  }
  let newVoids = voids.filter((v) => usedVoids.has(v.id));
  if (params.newTypeId)
    newVoids = newVoids.map((v) => {
      const newType = typesById[params.newTypeId];
      if (newType)
        return { ...v, elementTypeId: newType.id, color: newType.color };
      return { ...v };
    });
  return measurements
    .map((m) => {
      if (m.drawingShape === "POLYLINE") {
        return {
          ...polylinesById[m.id],
          voids: polylinesVoids[m.id],
        };
      }
      return { ...m };
    })
    .concat(newVoids);
}
