import {useSelector} from "react-redux";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";

export default function usePackagesByScene(scene, options) {
  // options

  const enabledOnly = options?.enabledOnly;
  const filterByVersion = options?.filterByVersion;
  const filterByScope = options?.filterByScope;
  const sortByName = options?.sortByName;
  const filterByPdf = options?.filterByPdf;
  const enabledInMainScopeOnly = options?.enabledInMainScopeOnly;
  const addHelpers = options?.addHelpers;

  // data - models

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) =>
      m.sceneClientId === scene?.clientId &&
      m.type === "MEASUREMENTS" &&
      !m.fromColoring
  );

  // data - for filters

  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const version = useSelectedVersionForMeasurements();
  const scope = useSelectedScope();
  const enabledPackageIdsInMainScope = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );

  // filtered models

  let filteredModels = models.filter((m) => {
    let boolEnabledOnly = true;
    let boolFilterByVersion = true;
    let boolFilterByScope = true;
    let boolFilterByPdf = true;
    let boolEnabledInMainScope = true;

    //
    if (enabledOnly) {
      boolEnabledOnly = m.enabled && !m.archived;
    }
    if (filterByPdf) {
      if (selectedPdfModelId)
        boolFilterByPdf = m.fromModel?.modelId === selectedPdfModelId;
    }
    if (filterByScope) {
      boolFilterByScope =
        !scope?.data?.packageIds || scope.data.packageIds.includes(m.id);
    }
    if (filterByVersion) {
      boolFilterByVersion =
        !version?.id || !m.revisionIds || m.revisionIds?.includes(version?.id);
    }
    if (enabledInMainScopeOnly) {
      boolEnabledInMainScope = enabledPackageIdsInMainScope?.includes(m.id);
    }
    let bool =
      boolEnabledOnly &&
      boolFilterByScope &&
      boolFilterByVersion &&
      boolEnabledInMainScope &&
      boolFilterByPdf;

    // helpers
    if (addHelpers) {
      if (m.isHelper && !bool) bool = true;
    }
    return bool;
  });

  // sort
  if (sortByName) {
    filteredModels = filteredModels.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  // return

  return filteredModels;
}
