import React from "react";

import {
  Typography,
  Dialog,
  Box,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";

export default function DialogCleanMeasurements({
  open,
  onClose,
  cleanMeasurementIds,
  onConfirm,
  onCancel,
}) {
  const title = "Suppression et modification d'éléments";

  const confirmS = "Confirmer";
  const cancelS = "Annuler";
  const noClean = "Pas d'éléments supprimés ni modifiés";

  const countD = cleanMeasurementIds?.duplicates ?? 0;
  const countZ = cleanMeasurementIds?.noZone ?? 0;
  const countT = cleanMeasurementIds?.noType ?? 0;
  const countC = cleanMeasurementIds?.changedRes ?? 0;
  const count = countD + countZ + countT + countC;

  const countS = `Nombre d'éléments supprimés / modifiés : ${count}`;
  const countDS = `Nombre d'élements dupliqués : ${countD}`;
  const countZS = `Nombre d'éléments sans fond de plan : ${countZ}`;
  const countTS = `Nombre d'éléments sans type existant : ${countT}`;
  const countCS = `Nombre d'éléments modifiés (articles) : ${countC}`;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {count === 0 && <Typography variant="body2">{noClean}</Typography>}
        {count > 0 && <Typography variant="body2">{countS}</Typography>}
        {count > 0 && <Typography>{"---"}</Typography>}
        {countD > 0 && <Typography variant="body2">{countDS}</Typography>}
        {countZ > 0 && <Typography variant="body2">{countZS}</Typography>}
        {countT > 0 && <Typography variant="body2">{countTS}</Typography>}
        {countC > 0 && <Typography variant="body2">{countCS}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>{cancelS}</Button>
        <Button disabled={count === 0} onClick={() => onConfirm()}>{confirmS}</Button>
      </DialogActions>
    </Dialog>
  );
}
