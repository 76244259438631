import React from "react";

import useColoringModel from "../useColoringModel";

import {Box, Typography} from "@mui/material";
import {Palette} from "@mui/icons-material";

export default function BoxNavigationColoringName({scene}) {
  // data

  const coloringModel = useColoringModel();

  // helpers

  const name = coloringModel?.name;

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Palette />
      <Typography sx={{ml: 1}}>{name}</Typography>
    </Box>
  );
}
