import {useDispatch} from "react-redux";
import {setOneFirstScopeWasOpened} from "../overviewerSlice";

import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import ContainerLoadingBimbox from "./ContainerLoadingBimbox";

export default function ScreenStartApp() {
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setOneFirstScopeWasOpened(true));
  }

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 101,
          color: "grey.400",
        }}
      >
        <IconButton onClick={handleClick} color="inherit">
          <Close />
        </IconButton>
      </Box>
      <ContainerLoadingBimbox />
    </Box>
  );
}
