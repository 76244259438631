import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateColoringJob} from "../coloringJobsSlice";
import useAccessToken from "Features/auth/useAccessToken";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Edit} from "@mui/icons-material";

import FormColoringJob from "./FormColoringJob";
import SectionCopyColoringJobLink from "./SectionCopyColoringJobLink";

export default function DialogUpdateColoringJob({
  scene,
  coloringJob,
  open,
  onClose,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const editS = "Edit";

  const title = "Edit job";
  const updateS = "Update";
  const cancelS = "Cancel";

  // state

  const [tempColoringJob, setTempColoringJob] = useState(coloringJob);

  useEffect(() => {
    setTempColoringJob(coloringJob);
  }, [coloringJob?.id, open]);

  // handlers

  function handleColoringJobChange(coloringJob) {
    setTempColoringJob(coloringJob);
  }

  function handleCancel() {
    onClose();
  }

  function handleClose() {
    onClose();
  }

  function handleUpdate() {
    dispatch(
      updateColoringJob({
        accessToken,
        coloringJob: tempColoringJob,
      })
    );
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{mt: 1}}>
          <Box sx={{width: 1, mb: 2}}>
            <SectionCopyColoringJobLink coloringJob={coloringJob} />
          </Box>

          <FormColoringJob
            coloringJob={tempColoringJob}
            onChange={handleColoringJobChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleUpdate}>{updateS}</Button>
      </DialogActions>
    </Dialog>
  );
}
