import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {updateDownloads, createScene} from "./scenesSlice";
import {fetchRemoteSceneService} from "./services";

import {downloadFileService} from "Features/files/services";
import {
  // createModel,
  createModels,
  addFetchedRemoteScene,
  updateScenesStatus,
} from "Features/viewer3D/viewer3DSlice";
// import {setStartDownloadingRemote} from "Features/sync/syncSlice";
import {fetchElementTypesGroups} from "Features/elementTypes/elementTypesSlice";
import {fetchRessourcesGroups} from "Features/ressources/ressourcesSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {setModelIdsInMeasDataManager} from "Features/overviewer/overviewerSlice";

/*
 * used to download files & data of the scene in the local store.
 * used when opening one remote scene from the list of all the scenes.
 * remote scene are not supposed to be in the scenes.items .
 * => models are added to the store.
 * => scene is added to the store + scene image is downloaded.
 */

export default function useDownloadRemoteScene(accessToken, caplaEditor) {
  const isStagingEnv = useIsStagingEnv();

  const dispatch = useDispatch();

  // data

  const remoteDataOnly = useSelector((s) => s.sync.remoteDataOnly);

  // state - output

  const [download, setDownload] = useState(null);

  useEffect(() => {
    if (accessToken && !isStagingEnv) {
      // step1 : function creation
      async function downloadRemoteScene(sceneId) {
        // init

        dispatch(updateDownloads({sceneId, status: "loading", progress: 0}));

        const result = await fetchRemoteSceneService({accessToken, sceneId});
        const scene = result?.remoteScene;
        if (!scene) return;

        console.log("FETCH REMOTE SCENE 32", scene);

        let models = [];
        for (const model of scene?.models) {
          models.push(model);
        }

        // scene

        let imageFile;
        if (!remoteDataOnly)
          imageFile = await downloadFileService({
            url: scene.imageUrl,
            fileName: scene.title,
          });

        await dispatch(
          createScene({
            title: scene.title,
            bimboxMode: scene.bimboxMode,
            clientReference: scene.clientReference,
            operationName: scene.operationName,
            description: scene.description,
            createdBy: scene.createdBy,
            createdAt: scene.createdAt,
            imageUrl: scene.imageUrl,
            imageFile: imageFile,
            id: scene.id,
            clientId: scene.clientId,
            version: scene.version,
            data: scene.data,
            fromRemote: true,
            lat: scene.lat,
            lng: scene.lng,
            worksite: scene.worksite,
            offset: scene.offset,
            altitude: scene.altitude,
            remoteDataOnly,
            tradesName: scene.tradesName,
            buildingsName: scene.buildingsName,
            prodStatus: scene.prodStatus,
            prodDeadline: scene.prodDeadline,
            contactEmail: scene.contactEmail,
            onboardingUrl: scene.onboardingUrl,
          })
        );

        console.log("FETCH - CREATE SCENE");

        //models

        models = models.map((m) => {
          if (m.type === "IMAGE" || m.type === "MARKERS") {
            return {...m, hidden: true};
          } else {
            return {...m, hidden: false};
          }
        });
        models = await dispatch(
          createModels({models, fromRemote: true, remoteDataOnly})
        );

        console.log("FETCH - MODELS CREATED");

        const loadedModels = unwrapResult(models);

        loadedModels
          .filter((m) => m.type === "MEASUREMENTS")
          .forEach((model) => {
            if (!model.archived && model.enabled) {
              caplaEditor?.measDataManager?.createOrUpdateModelInManager(
                model,
                true,
                true
              );
            }
          });

        dispatch(setModelIdsInMeasDataManager(loadedModels.map((m) => m.id)));

        // elementTypesGroup

        dispatch(fetchElementTypesGroups({sceneId, accessToken}));

        // ressourcesGroup

        dispatch(fetchRessourcesGroups({sceneId, accessToken}));

        // end
        dispatch(
          updateScenesStatus({
            sceneClientId: scene.clientId,
            status: "preloaded",
          })
        ); // to trigger Async load models (conditions)
        dispatch(addFetchedRemoteScene(scene.clientId)); // to trigger AsyncLoadModels from loader.
        dispatch(updateDownloads({sceneId, status: "success", progress: 1}));

        return scene;
      }
      // step2 : state update
      setDownload(() => downloadRemoteScene);
    }
  }, [accessToken, remoteDataOnly]);

  return [download];
}
