import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, Input} from "@mui/material";
import {update} from "Features/ifc/ifcSlice";

export default function BlockEditableArticleDescription({article}) {
  const dispatch = useDispatch();

  // state

  const initDescription = article?.description ?? "";

  const [description, setDescription] = useState(initDescription);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setDescription(initDescription);
  }, [initDescription]);

  // helpers

  const noDescription = !description?.length > 0;
  const placeholder = "Description de l'article";

  // handlers

  function handleBlur() {
    setIsEditing(false);
    dispatch(updateArticleTemp({id: article.id, description}));
  }

  function handleFocus() {
    setIsEditing(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        //bgcolor: "background.default",
        px: 1,
        borderRadius: "4px",
      }}
    >
      <Input
        multiline
        fullWidth
        disableUnderline
        sx={{
          color: isEditing ? "primary.main" : "text.secondary",
          p: 0,
          fontSize: 13,
        }}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
      />
    </Box>
  );
}
