import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import {fetchIssuesByScene} from "./issuesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useIssuesByScene(sceneId) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const issues = useSelector((s) => s.issues.items)?.filter(
    (issue) => issue.scene === sceneId
  );

  const status = useSelector((s) => s.issues.statusByScene)[sceneId];

  useEffect(() => {
    if (status !== "loaded" && sceneId && accessToken)
      dispatch(fetchIssuesByScene({accessToken, sceneId}));
  }, [status, accessToken, sceneId]);

  return issues ? issues : [];
}
