import {Vector2, ShapeUtils} from "three";

import MeasurementsDataManager from "Features/dataManagers/MeasurementsDataManager";
import getBankMeasurementQuantities from "Features/measurements/utils/getBankMeasurementQuantities";
import getSlopingPolygonMeasurementQuantities from "Features/measurements/utils/getSlopingPolygonMeasurementQuantities";
import getGableMeasurementQuantities from "Features/measurements/utils/getGableMeasurementQuantities";
import getBridgeMeasurementQuantities from "Features/measurements/utils/getBridgeMeasurementQuantities";

const getABLength = (A, B) => {
  return Math.sqrt((B[0] - A[0]) ** 2 + (B[1] - A[1]) ** 2);
};

const getPathPerimeter = (path, mpd) => {
  const perimeter = path.reduce((ac, cur, index) => {
    if (index === 0) return ac;
    const dist = getABLength(path[index], path[index - 1]);
    return ac + dist;
  }, 0);
  return perimeter * mpd;
};

const getPathArea = (path, mpd) => {
  const points = [];
  path.forEach((point) => {
    points.push(new Vector2(point[0], point[1]));
  });
  const area = Math.abs(ShapeUtils.area(points));
  return area * mpd ** 2;
};

export default class CaplaEditor {
  editor3d;
  editorPdf;
  editorPdfInstance2;

  constructor({dispatch, isViewer}) {
    console.log("new CaplaEditor");
    this.isViewer = isViewer;
    this.dispatch = dispatch;
    this.openSections = {
      outlet: true,
      outletDetail: false,
      viewer3D: false,
      fixedViewersBox: false,
      callToAction: false,
    };
    this.measDataManager = new MeasurementsDataManager({
      editor: this,
    });
  }

  setEditorPdf(editor) {
    this.editorPdf = editor;
  }

  setEditorPdfInstance2(editor) {
    this.editorPdfInstance2 = editor;
  }

  setEditor3d(editor) {
    this.editor3d = editor;
  }

  // setOpenSections(openSections, selectedMeasurementIds) {
  //   this.openSections = openSections;
  //   // this.editor3d.sceneEditor.unselect();
  //   const selected = new Set(selectedMeasurementIds);
  //   const toSelect = this.editor3d.objectEntities.filter((e) =>
  //     selected.has(e.entityID)
  //   );
  //   toSelect.forEach((e) => {
  //     this.editor3d.sceneEditor.selectElementEntity({
  //       modelId: e.modelId,
  //       entityID: e.entityID,
  //       keepSelection: true,
  //       fromPdf: true,
  //     });
  //   });
  // }

  round(value, num) {
    if (value || value === 0) {
      return parseFloat(value.toFixed(num));
    }
  }

  computeQuantitiesFromPaths({
    drawingShape,
    path,
    path3D,
    path3d2,
    height,
    heights,
    heightE,
    heightN,
    slopeH,
    slopingA,
    dim1,
    dim2,
    mpd,
  }) {
    let quantities = {
      count: 1,
      length: 0,
      area: 0,
      volume: 0,
    };
    if (drawingShape === "BANK" && path3d2?.length > 1) {
      quantities = getBankMeasurementQuantities({
        pathInf: path3d2,
        pathSup: path3D,
        height,
      });
    } else if (drawingShape === "SLOPING_POLYGON" && path3D?.length > 1) {
      quantities = getSlopingPolygonMeasurementQuantities({
        pathInf: path3D,
        slopingA,
        height,
      });
    } else if (drawingShape === "GABLE" && path3D?.length > 1) {
      quantities = getGableMeasurementQuantities({
        path3D,
        heights,
        dim1,
      });
    } else if (drawingShape === "BRIDGE" && path3D?.length > 1) {
      quantities = getBridgeMeasurementQuantities({
        path3D,
        height,
        dim1,
      });
      quantities.length = getPathPerimeter(path, mpd);
    } else {
      let length, area, volume, lengthP;
      switch (drawingShape) {
        case "SEGMENT": {
          if (dim2) {
            length = dim2;
          } else {
            length = getABLength(path[0], path[1]) * mpd;
          }
          area = length * height;
          volume = dim1 * area;
          break;
        }
        case "BEAM": {
          if (dim2) {
            length = dim2;
          } else {
            length = getABLength(path[0], path[1]) * mpd;
          }
          if (!heightE && !heightN) heightE = height;
          else if (!heightE && heightN) heightE = height - heightN;
          area = length * (2 * heightE + dim1);
          volume = heightE * dim1 * length;
          break;
        }
        case "POLYLINE": {
          length = getPathPerimeter(path, mpd);
          area = length * height;
          volume = dim1 * area;
          break;
        }
        case "SLOPE": {
          lengthP = getPathPerimeter(path, mpd);
          length = Math.sqrt(lengthP ** 2 + slopeH ** 2);
          area = length * dim1;
          volume = area * height;
          break;
        }
        case "STAIRS": {
          lengthP = getPathPerimeter(path, mpd);
          length = Math.sqrt(lengthP ** 2 + height ** 2);
          area = length * dim1;
          volume = area * 0.1;
          break;
        }
        case "RECTANGLE": {
          if (dim2) {
            length = 2 * (dim2 + dim1);
            area = dim1 * dim2;
          } else {
            length = (getABLength(path[0], path[1]) * mpd + dim1) * 2;
            area = getABLength(path[0], path[1]) * mpd * dim1;
          }
          volume = area * height;
          break;
        }
        // case "RECTANGLE2": {
        //   if (dim1 && dim2) {
        //     length = 2 * dim1 + 2 * dim2;
        //     area = dim1 * dim2;
        //   } else {
        //     const rot = (annot.Rotation * Math.PI) / 180;
        //     const _perimeter = mpd * 2 * (annot.Width + annot.Height);
        //     // correction : the width is relative to the bounding box. Need to take into account the rotation.
        //     const sin = Math.abs(Math.sin(rot));
        //     const cos = Math.abs(Math.cos(rot));
        //     length = _perimeter / (sin + cos);
        //     const _area = mpd * mpd * annot.Width * annot.Height;
        //     area =
        //       (1 / (1 - 2 * sin * cos)) *
        //       (_area - (length ** 2 / 4) * sin * cos);
        //   }
        //   // volume
        //   volume = area * height;
        //   break;
        // }
        case "POLYGON": {
          length = getPathPerimeter(path, mpd);
          area = getPathArea(path, mpd);
          volume = area * height;
          break;
        }
        case "BOWL": {
          length = getPathPerimeter(path, mpd);
          area = getPathArea(path, mpd);
          volume = area * height;
          break;
        }
        case "CIRCLE": {
          if (dim1) {
            area = Math.PI * (dim1 / 2) ** 2;
            volume = area * height;
          }
        }
      }
      quantities.length = length;
      quantities.area = area;
      quantities.volume = volume;
      if (lengthP) quantities["lengthP"] = lengthP;
    }
    const result = {};
    for (const [key, value] of Object.entries(quantities)) {
      result[key] = this.round(value, 5);
    }
    return result;
  }

  getMeasurementQuantities(measurement) {
    let {length, area, volume, lengthP, areaP} = measurement;
    let {
      drawingShape,
      dim1,
      height,
      heightE,
      heightN,
      slopeH,
      path3D,
      path3d2,
      slopingA,
    } = measurement;

    switch (drawingShape) {
      case "SEGMENT": {
        area = length * height;
        volume = dim1 * area;
        break;
      }
      case "BEAM": {
        if (!heightE && !heightN) heightE = height;
        else if (!heightE && heightN) heightE = height - heightN;
        area = length * (2 * heightE + dim1);
        volume = heightE * dim1 * length;
        break;
      }
      case "POLYLINE": {
        area = length * height;
        volume = dim1 * area;
        break;
      }
      case "SLOPE": {
        length = Math.sqrt(lengthP ** 2 + slopeH ** 2);
        area = length * dim1;
        volume = area * height;
        break;
      }
      case "STAIRS": {
        length = Math.sqrt(lengthP ** 2 + height ** 2);
        area = length * dim1;
        volume = area * 0.1;
        break;
      }
      case "RECTANGLE": {
        volume = area * height;
        break;
      }
      case "RECTANGLE2": {
        volume = area * height;
        break;
      }
      case "POLYGON": {
        volume = area * height;
        break;
      }
      case "CIRCLE": {
        volume = area * height;
        break;
      }
      case "BANK": {
        if (path3d2?.length > 1) {
          const result = getBankMeasurementQuantities({
            pathInf: path3d2,
            pathSup: path3D,
            height,
          });
          area = result.area;
          volume = result.volume;
        } else {
          area = 0;
          volume = 0;
        }
        break;
      }
      case "SLOPING_POLYGON": {
        if (path3D?.length > 1) {
          const result = getSlopingPolygonMeasurementQuantities({
            pathInf: path3D,
            slopingA,
            height,
          });
          length = result.length;
          area = result.area;
          volume = result.volume;
        } else {
          area = 0;
          volume = 0;
        }
        break;
      }
      case "GABLE": {
        const heights = measurement.heights;
        if (path3D.length > 1) {
          const result = getGableMeasurementQuantities({
            path3D,
            heights,
            dim1,
          });
          area = result.area;
          volume = result.volume;
        } else {
          area = 0;
          volume = 0;
        }
        break;
      }
      case "BRIDGE": {
        if (path3D.length > 1) {
          const result = getBridgeMeasurementQuantities({
            path3D,
            height,
            dim1,
          });
          areaP = result.areaP;
          area = result.area;
          volume = result.volume;
        } else {
          areaP = 0;
          area = 0;
          volume = 0;
        }
        break;
      }
    }
    const toReturn = {
      area: this.round(area, 5),
      volume: this.round(volume, 5),
    };
    if (length) toReturn["length"] = this.round(length, 5);
    if (lengthP) toReturn["lengthP"] = this.round(lengthP, 5);
    if (areaP) toReturn["areaP"] = this.round(areaP, 5);
    return toReturn;
  }
}
