import React from "react";

import {Box, IconButton, Typography} from "@mui/material";
import {ContentCopy as Copy} from "@mui/icons-material";

export default function SectionCopyColoringJobLink({coloringJob}) {
  // helpers

  const url = `/coloringJobs/${coloringJob.id}/newColoring`;
  const baseURL = process.env.REACT_APP_URL_PREFIX;

  const link = `${baseURL}${url}`;

  // handler

  function handleCopy() {
    navigator.clipboard.writeText(`${baseURL}${url}`);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 1,
      }}
    >
      <Typography noWrap sx={{fontSize: 13}} color="text.secondary">
        {link}
      </Typography>
      <IconButton size="small" onClick={handleCopy}>
        <Copy fontSize="small" />
      </IconButton>
    </Box>
  );
}
