import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedZone from "../hooks/useSelectedZone";
import {setIsEditingZone, setEditedZone} from "../zonesSlice";
import getChangedFields from "../utils/getChangedFields";

import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
// import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
// import updateMeasurementFromType from "Features/measurements/utils/updateMeasurementFromType";
// import { removeModifications, updateMeasurements } from "Features/measurements/measurementsSlice";
// import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";
// import useSelectedMeasurementsModelsData from "Features/measurements/useSelectedMeasurementsModelData";
// import { updateModel } from "Features/viewer3D/viewer3DSlice";

export default function ActionsZoneInSelectionPanel({editorPdf, scene}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.zones.isEditingZone);
  const selectedZone = useSelectedZone(scene);
  const editedZone = useSelector((s) => s.zones.editedZone);

  const measurements = useSceneMeasurements(scene);
  // const elementTypes = useElementTypesBySceneProxy(scene);

  // const modifiedMeasurements = useSelector(
  //   (s) => s.measurements.modifiedMeasurements
  // );
  // const measurementsData = useSelectedMeasurementsModelsData(scene);
  // const model = useSelectedMeasurementsModel();

  // helpers

  const mnger = editorPdf?.annotationsManager?.measurementsPdfManager;

  // handlers

  function handleEditClick() {
    console.log("editZone", selectedZone);
    dispatch(setIsEditingZone(true));
    dispatch(setEditedZone({...selectedZone}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingZone(false));
  }

  function handleSaveClick() {
    const changedFields = getChangedFields(selectedZone, editedZone);
    editorPdf.annotationsManager.updateAnnotationEntityProps({
      annotationId: editedZone.id,
      props: changedFields,
    });
    dispatch(setIsEditingZone(false));

    // const modifications = [];
    // const measurementsData = {categories: [], measurements: [], presets: []}
    measurements
      .filter((m) => m.zoneId === editedZone.id)
      .map((m) => {
        mnger.updateMeasurement3D(m);
        // measurementsData.measurements.push(m);
        // modifications.push({modelId: model.id, measId: m.id});
      });

    // const updatedModel = {...model, measurementsData};
    // dispatch(updateModel({updatedModel, sync: true}));
    // dispatch(removeModifications(modifications));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
