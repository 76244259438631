import React from "react";

import {Box} from "@mui/material";

import SelectOptions from "Components/SelectOptions";
import SelectOptionsWithObjects from "Components/SelectOptionsWithObjects";

export default function FieldTempZoneSlope({slope, onChange}) {
  // string

  const HS = "Vue en plan (Horizontal)";
  const VS = "Coupe (Vertical)";

  // helpers

  const options = [
    {id: "HORIZONTAL", name: HS},
    {id: "VERTICAL", name: VS},
  ];

  const option = options.find((o) => o.id === slope);

  // handlers

  function handleChange(option) {
    onChange(option.id);
  }

  return (
    <Box sx={{width: 1}}>
      <SelectOptionsWithObjects
        options={options}
        option={option}
        onChange={handleChange}
      />
    </Box>
  );
}
