import {useDispatch} from "react-redux";

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";

import {setMode, setStep} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";

export default function CardPositionOnePoint({editor3d}) {
  const {t} = useTranslation("viewer3D");
  const dispatch = useDispatch();

  // strings

  const title = t("viewer3D:positionTool.moveOnePointCard.title");
  const description = t("viewer3D:positionTool.moveOnePointCard.description");
  const button = t("viewer3D:positionTool.moveOnePointCard.button");

  // handlers

  function handleStartClick() {
    dispatch(setMode("MOVE_ONE_POINT"));
    dispatch(setStep("ORIGIN"));
    editor3d?.switchToMode("MOVE_ONE_POINT");
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="body2" gutterBottom>
          <b>{title}</b>
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions>
        <Button variant="outlined" size="small" onClick={handleStartClick}>
          {button}
        </Button>
      </CardActions>
    </Card>
  );
}
