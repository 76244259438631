import React, {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import useSelectedIssue from "Features/issues/useSelectedIssue";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import {Box, Typography, Paper} from "@mui/material";
import useModelsAndStatus from "Features/viewer3D/useModelsAndStatus";
import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

import ButtonLoadModels from "Features/viewer3D/components/FilesViewer/components/ButtonLoadModels";
import SectionCallToActionAddFile from "Features/viewer3D/components/FilesViewer/components/SectionCallToActionAddFile";
import Stretch from "Features/ui/components/Stretch";
import SectionSceneHome from "Features/scenes/components/SectionSceneHome";
import SectionSelectionDetail from "Features/navigation/components/SectionSelectionDetail";

import PageMeasurementsPreview from "Features/measurements/components/PageMeasurementsPreview";
import PageIssueOverview from "Features/issues/components/PageIssueOverview";
import PageMarkersPreview from "Features/markers/components/PageMarkersPreview";

import ViewerZone from "Features/zones/components/ViewerZone";

import ButtonFullScreenTable from "./ButtonFullScreenTable";
import useIsFullscreen from "../useIsFullscreen";

export default function SectionCallToActionSelectDataItem({
  editor,
  scene,
  viewer,
}) {
  const dispatch = useDispatch();

  // strings

  const selectS = "Select one item on the left.";
  const noScreenshotS = "No preview available";

  // data

  const selectedPdfModel = useSelectedPdfModel();

  //const models = useSelector((s) => s.viewer3D.models);
  const models = useSelector((s) => s.viewer3D.models);
  const issues = useSelector((s) => s.issues.items);

  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  const selectedModelIdUi = useSelector((s) => s.ui.selectedModelId);

  let selectedModel = models.find((m) => m.id === selectedModelId);
  const selectedModelUi = models.find((m) => m.id === selectedModelIdUi);

  let selectedIssue = useSelectedIssue();
  const selectedIssueIdUi = useSelector((s) => s.ui.selectedIssueId);
  const selectedIssueUi = issues.find((i) => i.id === selectedIssueIdUi);

  const os = useSelector((s) => s.ui.openSections);
  const fullscreen = useIsFullscreen();
  const pageType = usePageType();

  const sceneModule = useSceneModule();
  const homeViewMode = useSelector((s) => s.ui.homeViewMode);

  // helpers - selection

  if (sceneModule === "HOME" || viewer) {
    selectedIssue = selectedIssueUi;
    selectedModel = selectedModelUi;
  }

  // helper - url

  const modelUrl = selectedModel?.screenshot?.imageUrl;
  const issueUrl = selectedIssue?.imageUrl;

  const url = issueUrl ? issueUrl : modelUrl;

  // helper - no selected item

  const noSelection =
    scene?.clientId &&
    !selectedIssue &&
    !selectedModel?.id &&
    !selectedPdfModel;

  // helper - no models

  const noModels = scene?.clientId && models?.length === 0;

  // helper - home - scene

  const displaySectionSceneHome = noSelection;

  // helper - selectedIssueOnly, selectedModelOnly

  const selectedIssueOnly = selectedIssue && !selectedModelId;
  const selectedModelOnly = !selectedIssue && selectedModel;

  // helper - dom

  const top = fullscreen ? "8px" : "68px";

  // helper - display

  const display = os.callToAction ? "flex" : "none";

  // helper

  const showFullscreenTable = selectedModel;

  // helper - forceSceneImage

  const forceSceneImage =
    (homeViewMode === "MEASUREMENTS" ||
      (homeViewMode === "ISSUES" && !selectedIssue)) &&
    (sceneModule === "HOME" || viewer);

  // effects

  useEffect(() => {
    if (selectedModel?.fromModel?.type === "PDF") {
      dispatch(setSelectedPdfModelId(selectedModel.fromModel.modelId));
    }
  }, [selectedModel?.id]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "common.white",
        //border: "1px solid black",
        //borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        display,
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Paper sx={{width: 1, height: 1, bgcolor: "red"}} elevation={12}>
        <ViewerZone editor={editor} scene={scene} />
      </Paper>

      {/* {selectedModel?.type === "MARKERS" && !forceSceneImage && (
        <PageMarkersPreview scene={scene} editor={editor} viewer={viewer} />
      )} */}

      {/* {!selectedIssue &&
        selectedModel?.type === "MEASUREMENTS" &&
        !forceSceneImage && (
          <PageMeasurementsPreview
            scene={scene}
            editor={editor}
            viewer={viewer}
          />
        )} */}

      {/* {((noSelection && !noModels) || forceSceneImage) && os?.callToAction && (
        <SectionSceneHome scene={scene} />
      )}
      {selectedIssue && !forceSceneImage && (
        <PageIssueOverview issue={selectedIssue} />
      )} */}
    </Box>
  );
}
