import {useState} from "react";
import {useDispatch} from "react-redux";

import {IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";

import {deleteElementTypeInEditedElementTypesGroup} from "../elementTypesSlice";

import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function IconButtonDialogDeleteElementType({elementTypeId}) {
  const dispatch = useDispatch();

  // state

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //handlers

  function handleDeleteClick() {
    setOpenDeleteDialog(true);
  }
  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }
  function handleDeleteConfirm() {
    dispatch(deleteElementTypeInEditedElementTypesGroup(elementTypeId));
  }

  return (
    <>
      <IconButton size="small" onClick={handleDeleteClick}>
        <Delete fontSize="small" />
      </IconButton>
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource="elementType"
      />
    </>
  );
}
