import {useState} from "react";

import {Box, Button, Typography} from "@mui/material";
import {AutoFixHigh as Enhance} from "@mui/icons-material";

import * as Excel from "exceljs";

import {blobToArrayBuffer} from "Database/utils";
import {urlToBlob, saveBlob} from "Features/files/utils";
import template from "Features/excel/assets/template_ressources_with_ids.xlsx";
import FileSelectorButton from "Features/files/components/FileSelectorButton";

const properValue = (excelValue) => {
  if (excelValue && typeof excelValue === "object") {
    if (excelValue.result) return excelValue.result;
    if (excelValue.hyperlink) return excelValue.text;
    if (excelValue.richText)
      return excelValue.richText.map((t) => t.text).join("");
    return null; // sharedFormula without result for instance
  }
  // typeof date ?
  return excelValue;
};

const incrementNum = (num, firstNull) => {
  if (!num) return null;
  if (firstNull) {
    return num + ".1";
  } else {
    const split = num.split(".");
    const popped = parseInt(split.pop()) + 1;
    return [...split, popped.toString()].join(".");
  }
};

const applyStyle = (row, i, isTitle, style) => {
  if ((isTitle && style === undefined) || ["titre_1", "titre_2"].includes(style)) {
    row.getCell(i).font = {bold: true};
    if (style === "titre_1")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'bbbbc0'}
      };
    if (style === "titre_2")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F4F4F8'}
      };
  }
  if (style === "détail")
    row.getCell(i).font = {color: {argb: "777777"}};
};

export default function ShangriLA() {
  // strings

  const selectS = "Import Shangri LA";
  const enhanceS = "export Shangri LA";

  // state

  const [src, setSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  // helpers

  const srcName = src?.name ? src.name : "";

  // handlers

  function handleFileChange(file) {
    setSrc(file);
  }

  async function handleClick() {
    setLoading(true);

    // load src
    const srcWB = new Excel.Workbook();
    const arrayBuffer2 = await blobToArrayBuffer(src);
    await srcWB.xlsx.load(arrayBuffer2);

    // load data
    let i = 1;
    let wsNum = 0;
    const data = [];
    for (let srcWS of srcWB.worksheets) {
      const rowCount = srcWS.rowCount;
      wsNum = i.toString();
      data.push({
        num: wsNum,
        name: srcWS.name,
        isTitle: "x",
        style: "titre_1",
        hideNum: null,
        unit: null,
      })
      const header = srcWS.getRow(4).values.map((v) => properValue(v));
      const unitIdx = header.findIndex((x) => x?.replace(" ", "") === "U");
      const titleIdx = header.findIndex((x) => x?.replace(" ", "") === "Titre");
      const articleIdx = header.findIndex((x) => x?.replace(" ", "") === "Article");
      if (unitIdx > -1 && titleIdx > -1 && articleIdx > -1) {
        let title1Num, title2Num, articleNum;
        const rows = srcWS.getRows(5, rowCount).values();
        for (let row of rows) {
          const hasA = properValue(row.getCell(1).value);
          const hasB = properValue(row.getCell(2).value);
          const hasC = properValue(row.getCell(3).value);
          const unit = properValue(row.getCell(unitIdx).value);
          const isTitle = properValue(row.getCell(titleIdx).value);
          const isArticle = properValue(row.getCell(articleIdx).value);
          if (isTitle && hasA) {
            if (!title1Num)
              title1Num = incrementNum(wsNum, true);
            else title1Num = incrementNum(title1Num);
            data.push({
              num: title1Num,
              name: hasB,
              isTitle: "x",
              style: "titre_1",
              hideNum: null,
              unit: null,
            })
            title2Num = null;
            articleNum = null;
          } else if (isTitle && hasB) {
            if (!title2Num)
              title2Num = incrementNum(title1Num, true);
            else title2Num = incrementNum(title2Num);
            data.push({
              num: title2Num,
              name: hasB,
              isTitle: "x",
              style: "titre_2",
              hideNum: null,
              unit: null,
            })
            articleNum = null;
          } else if (isTitle && hasC) {
            const detailNum = articleNum ? articleNum + ".0" :
              (title2Num ? title2Num + ".0" : title1Num + ".0")
            data.push({
              num: detailNum,
              name: hasC,
              isTitle: "x",
              style: "détail",
              hideNum: "x",
              unit: null,
            })
          } else if (isArticle) {
            if (!articleNum)
              articleNum = title2Num ? incrementNum(title2Num, true) :
                incrementNum(title1Num, true);
            else articleNum = incrementNum(articleNum);
            data.push({
              num: articleNum,
              name: hasC,
              isTitle: null,
              style: "normal",
              hideNum: null,
              unit
            })
          }
        }
      }
      i += 1;
    }

    // load dst
    const dstWB = new Excel.Workbook();
    const blob = await urlToBlob(template);
    const arrayBuffer = await blobToArrayBuffer(blob);
    await dstWB.xlsx.load(arrayBuffer);
    const dstWS = dstWB.worksheets[0];

    // write data
    data.forEach(({num, name, isTitle, style, hideNum, unit}, idx) => {
      const row = dstWS.getRow(2 + idx);
      row.getCell(3).value = num;
      row.getCell(4).value = name;
      row.getCell(6).value = isTitle;
      row.getCell(7).value = style;
      row.getCell(8).value = hideNum;
      row.getCell(9).value = unit;
      for (let i = 1; i <= 12; i++) {
        applyStyle(row, i, isTitle, style);
      }
    })

    // export
    const newBuffer = await dstWB.xlsx.writeBuffer();
    const newBlob = new Blob([newBuffer], {type: "application/octet-stream"});
    saveBlob(newBlob, srcName.replace("import", "export"));

    setLoading(false);
  }

  return (
    <Box sx={{width: 1, p: 2}}>
      <Box sx={{bgcolor: "background.default", p: 2, width: 1}}>
        <Box
          sx={{display: "flex", width: 1, justifyContent: "center", height: 30}}
        >
          <Typography variant="body2" noWrap>
            {srcName}
          </Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", width: 1}}>
          <FileSelectorButton
            onFileChange={handleFileChange}
            buttonName={selectS}
            accept={".xlsx"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<Enhance />}
          onClick={handleClick}
          disabled={!src || loading}
        >
          {enhanceS}
        </Button>
      </Box>
    </Box>
  );
}
