import {useSelector} from "react-redux";

import CardMarkersDescription from "./CardMarkersDescription";
import CardMarkersSelector from "./CardMarkersSelector";
// import ProcessMarkerAdd from "./ProcessMarkerAdd";

import DisplayBox from "Components/DisplayBox";

export default function ToolMarkers({editor3d, scene}) {
  // data

  const mode = useSelector((state) => state.viewer3D.mode);

  // helper

  const _adding = mode === "PROCESS_ADD_MARKER";

  return (
    <>
      <DisplayBox open={!_adding}>
        <CardMarkersDescription />
        <CardMarkersSelector editor3d={editor3d} scene={scene} />
      </DisplayBox>
      <DisplayBox open={_adding}>
        {/* <ProcessMarkerAdd editor={editor} /> */}
      </DisplayBox>
    </>
  );
}
