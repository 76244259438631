import { Vector3 } from "three";

import { lineLineIntersection } from "./intersections";

const computeNormal = (a, b) => {
  const v = new Vector3().subVectors(b, a);
	return new Vector3(v.y, -v.x, 0).normalize();
};

export const makeCenterLine = (path) => {
  const newPath = [];
  const pathLen = path.length;
  const newPathLen = (pathLen - 1) / 2;
  for (let i = 0; i < newPathLen; i++) {
    const a = path[i];
    const b = path[pathLen - i - 2];
    const p1 = new Vector3(a[0], a[1], 0);
    const p2 = new Vector3(b[0], b[1], 0);
    const center = p1.clone().add(p2).divideScalar(2);
    newPath.push([center.x, center.y]);
  }
  return newPath;
}

const makeOffsetLine = (line, offsetValue) => {
  const newPoints = [];
  for (let i = 0; i < line.length - 1; i++) {
    const p1 = new Vector3(line[i][0], line[i][1], 0);
    const p2 = new Vector3(line[i + 1][0], line[i + 1][1], 0);
    const offset = computeNormal(p1, p2);
    offset.multiplyScalar(offsetValue);
    p1.add(offset);
    p2.add(offset);
    newPoints.push(p1, p2);
  }
  let newPath;
  if (newPoints.length > 1) {
    const firstPoint = newPoints[0];
    newPath = [[firstPoint.x, firstPoint.y]];
    for (let i = 0; i < newPoints.length - 2; i += 2) {
      const a = newPoints[i];
      const b = newPoints[i + 1];
      const c = newPoints[i + 2];
      const d = newPoints[i + 3];
      const intersection = lineLineIntersection(a, b, c, d);
      if (intersection) newPath.push(intersection);
    }
    const lastPoint = newPoints[newPoints.length - 1];
    newPath.push([lastPoint.x, lastPoint.y]);
  }
  return newPath;
};

export default function makeBufferLine(centerLine, width) {
  const inLine = makeOffsetLine(centerLine, -width * 0.5);
  let outLine;
  if (inLine) outLine = makeOffsetLine(centerLine, width * 0.5);
  if (inLine && outLine) return [...inLine, ...outLine.reverse(), inLine[0]];
  return null;
}