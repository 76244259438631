import {useState} from "react";
import {useDispatch} from "react-redux";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import {setSelectedRoomsGroupId, createRoomsGroup} from "../roomsSlice";

import {setSnackbarMessage} from "Features/ui/uiSlice";
import useCreateRoomsGroup from "../hooks/useCreateRoomsGroup";
import SectionSelectorRoomsGroupRankNames from "./SectionSelectorRoomsGroupRankNames";

export default function DialogCreateRoomsGroup({
  scene,
  open,
  onClose,
  onCreated,
}) {
  const dispatch = useDispatch();

  // data

  const create = useCreateRoomsGroup(scene);

  // strings

  const title = "Nouvelle arborescence de pièces";

  const descriptionS =
    "Conseil : créez une arborescence de pièces par bâtiment / ouvrage";
  const placeholder = "Bât.A, Bât.B,..";

  const nameS = "Nom";

  const createS = "Créer";

  // state

  const [name, setName] = useState("");
  const [rankNames, setRankNames] = useState([]);
  const [rankNamesModeCode, setRankNamesModeCode] = useState(null);
  const [loading, setLoading] = useState(false);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleClose() {
    setLoading(false);
    onClose();
  }
  function handleRankNamesChange(newRankNames) {
    setRankNames(newRankNames);
  }

  async function handleSave() {
    try {
      if (loading) return;
      setLoading(true);
      //
      const newGroup = {
        name,
        sceneId: scene?.id,
        data: {rankNames},
      };
      //
      const item = await create(newGroup);
      //
      setLoading(false);
      //
      setName("");
      setRankNames([]);
      //
      if (item) {
        dispatch(setSelectedRoomsGroupId(item?.id));
        //
        const triggeredAt = Date.now();
        const message = "Nouvelle arborescence créée";
        dispatch(setSnackbarMessage({triggeredAt, message}));
        //
        if (onCreated) onCreated(item);
      }

      onClose();
    } catch (e) {
      console.log("error", e);
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{descriptionS}</Typography>
        <TextField
          fullWidth
          placeholder={placeholder}
          sx={{mt: 2}}
          //label={<Typography variant="body2">{nameS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={name}
          onChange={handleNameChange}
        />
        <Box sx={{mt: 2}}>
          <SectionSelectorRoomsGroupRankNames
            selectedModeCode={rankNamesModeCode}
            onSelectionChange={setRankNamesModeCode}
            onRankNamesChange={handleRankNamesChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || name?.length === 0} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
