import React from "react";

import {Box, Typography} from "@mui/material";

export default function CardEntityTypes({types}) {
  // strings

  const infoS = "Info";
  const sectorsS = "Secteurs";

  return (
    <Box sx={{width: 1}}>
      {types.map(({type, code, label, ressourcesCode, worksheetName}) => {
        switch (type) {
          case "INFO": {
            return (
              <Box key={worksheetName}>
                <Typography variant="body2">{infoS}</Typography>
              </Box>
            );
          }
          case "SECTORS": {
            return (
              <Box key={worksheetName}>
                <Typography variant="body2">{sectorsS}</Typography>
              </Box>
            );
          }
        }
      })}
    </Box>
  );
}
