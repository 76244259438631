export default function getVoidIdMeasurementMap(measurements) {
  const result = {};
  measurements.forEach((m) => {
    if (m.voids?.length > 0) {
      m.voids.forEach((voidId) => {
        result[voidId] = m;
      });
    }
  });
  return result;
}
