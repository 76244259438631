import {createSlice} from "@reduxjs/toolkit";

export const buildingsSlice = createSlice({
  name: "buildings",
  initialState: {
    buildingInScope: null, // name of the building
  },
  reducers: {
    setBuildingInScope: (state, action) => {
      state.buildingInScope = action.payload;
    },
  },
});

export const {
  setBuildingInScope,
} = buildingsSlice.actions;

export default buildingsSlice.reducer;