import {useDispatch, useSelector} from "react-redux";

import {Box, Button} from "@mui/material";

import ButtonCreateRessource from "./ButtonCreateRessource";
import ButtonDownloadExcelRessources from "./ButtonDownloadExcelRessources";
import ButtonExcelRessources from "./ButtonExcelRessources";

import useSelectedRessource from "../hooks/useSelectedRessource";

import { setSelectedRessourceIds } from "Features/ressources/ressourcesSlice";
import ButtonMoreRessourcesInLeftPanel from "./ButtonMoreRessourcesInLeftPanel";

export default function ToolbarRessourcesMain({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const clearS = "Clear selection";

  // data
  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );
  const selectedRessourceIds = useSelector(
    (s) => s.ressources.selectedRessourceIds
  );
  const openPanel = useSelector((s) => s.selection.openSelectionPanel);
  const selectedRessource = useSelectedRessource(scene);

  // helper
  const hideCreate = openPanel && !selectedRessourceId;

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
      }}
    >
      <Box sx={{display: "flex"}}>
        {!hideCreate && <ButtonCreateRessource />}
        {!selectedRessource && (
          <Box sx={{display: "flex", alignItems: "center", ml: 1}}>
            <ButtonDownloadExcelRessources scene={scene} />
            <ButtonExcelRessources scene={scene} />
            <ButtonMoreRessourcesInLeftPanel scene={scene} caplaEditor={caplaEditor} />
          </Box>
        )}
              <Box sx={{display: "flex", alignItems: "center", px: 1}}>
        <Box
          sx={{
            color: "text.secondary",
            visibility: selectedRessourceIds.length > 0 ? "visible" : "hidden"
          }}
        >
          <Button
            onClick={() => dispatch(setSelectedRessourceIds([]))}
            size="small"
            color="inherit"
          >
            {clearS}
          </Button>
        </Box>
      </Box>
      </Box>
    </Box>
  );
}
