import {Box, Typography, Paper} from "@mui/material";

import ImageScene from "Features/scenes/components/ImageScene";
import SectionSceneOnboardingVideo from "Features/scenes/components/SectionSceneOnboardingVideo";

export default function SectionSceneInLeftPanelInViewer({scene}) {
  // strings

  const contactS =
    "A contacter pour toute information relative à cette bimbox :";

  const bimboxS = "Bimbox";

  // helper

  const sceneTitleLabel = `${bimboxS}: ${scene?.title ? scene.title : ""}`;

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Box sx={{width: 1, height: 200}}>
          <ImageScene scene={scene} />
        </Box>
        <Box sx={{p: 2, pt: 0.5, pl: 0.5}}>
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {sceneTitleLabel}
          </Typography>
        </Box>
        <Box sx={{p: 2}}>
          <Paper
            elevation={12}
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {scene?.operationName && (
              <Typography variant="body2">{scene?.operationName}</Typography>
            )}
            {scene?.tradesName && (
              <Typography sx={{mt: 2, fontWeight: "bold"}} variant="body1">
                {scene?.tradesName}
              </Typography>
            )}
            {scene?.buildingsName && (
              <Typography sx={{mt: 2}} variant="body2" color="text.secondary">
                {scene?.buildingsName}
              </Typography>
            )}
          </Paper>
        </Box>
        {scene?.onboardingUrl && (
          <Box sx={{width: 1, height: 240, p: 2}}>
            <Paper elevation={12} sx={{widht: 1, height: 1}}>
              <SectionSceneOnboardingVideo
                onboardingUrl={scene?.onboardingUrl}
              />
            </Paper>
          </Box>
        )}
      </Box>
      {scene?.contactEmail && (
        <Box sx={{width: 1, p: 2}}>
          <Box sx={{p: 1, bgcolor: "background.default"}}>
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {contactS}
            </Typography>
            <Typography sx={{mt: 1, color: "text.secondary", fontSize: 13}}>
              {scene.contactEmail}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}