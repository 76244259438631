import {useSelector} from "react-redux";
import useScopesByScene from "./useScopesByScene";

export default function useSelectedScope(options) {
  const inModule = options?.inModule;
  const scopes = useScopesByScene(null, {addScopeAll: true});
  //
  const scopeId = useSelector((s) => s.scopes.selectedScopeId);
  const scopeIdInModule = useSelector((s) => s.scopes.selectedScopeIdInModule);
  //
  const id = Boolean(inModule) ? scopeIdInModule : scopeId;
  //
  return scopes?.find((scope) => scope.id === id);
}
