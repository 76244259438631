import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Button,
} from "@mui/material";

import {updateMeasurements} from "../measurementsSlice";

import SelectorAutocompleteMultiple from "Components/SelectorAutocompleteMultiple";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

export default function DialogUpdateMeasurementRessources({
  open,
  onClose,
  measurement,
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Articles";
  const ressourcesS = "Articles";
  const cancelS = "Annuler";
  const saveS = "Enregistrer";

  // data

  const ressources = useRessourcesByScene(scene);

  // helpers

  const options = ressources
    .map((r) => ({
      ...r,
      name: `${r.group} • ${r.num} - ${r.name}`,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
  const resIds = measurement?.res ? measurement.res.map((r) => r.resId) : [];

  // state

  const [res, setRes] = useState([]); // [ressource1, ressource2,...]

  useEffect(() => {
    const res = options.filter((r) => resIds.includes(r.id));
    setRes(res);
  }, [open, measurement?.id, ressources?.length]);

  // handlers

  function handleChange(ressources) {
    setRes(ressources);
  }
  function handleCancel() {
    onClose();
  }
  function handleSave() {
    // dispatch(
    //   updateMeasurements([
    //     {...measurement, res: res.map((r) => ({resId: r.id}))}
    //   ])
    // );
    caplaEditor?.measDataManager.updateMeasurements([
      {...measurement, res: res.map((r) => ({resId: r.id}))},
    ]);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{width: 500}}>
          <SelectorAutocompleteMultiple
            name={ressourcesS}
            options={options}
            value={res}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
