import {Box} from "@mui/material";

import SwitchShowMeasurements from "Features/measurements/components/SwitchShowMeasurements";
import ButtonHideMeasurementsInPdf from "Features/measurements/components/ButtonHideMeasurementsInPdf";

export default function ShortcutMeasurements({editorPdf}) {
  return (
    <Box sx={{}}>
      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <ButtonHideMeasurementsInPdf editorPdf={editorPdf} />
      </Box>
      <SwitchShowMeasurements editorPdf={editorPdf} />
    </Box>
  );
}
