import styleMap from "Styles/listStyleMap";

export default function getRessourceStyleObject(ressource) {
  let styleType = "DEFAULT";
  if (
    ressource?.isTitle &&
    ressource?.rank === 1 &&
    ressource?.style === undefined
  )
    styleType = "TITLE1";
  if (
    ressource?.isTitle &&
    ressource?.rank === 2 &&
    ressource?.style === undefined
  )
    styleType = "TITLE2";
  if (ressource.style === "title1") styleType = "TITLE1";
  if (ressource.style === "title2") styleType = "TITLE2";
  if (ressource.style === "detail") styleType = "DETAIL";

  const styleObject = styleMap[styleType];

  return styleObject;
}
