import usePdfsByScene from "Features/pdf/hooks/usePdfsByScene";
import usePackagesByScene from "../usePackagesByScene";
import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function usePackagesByPdfList(scene, options) {
  const items = [];
  //
  const filterBySelectedVersionForMeasurements =
    options.filterBySelectedVersionForMeasurements;
  const filterByScope = options.filterByScope;
  //
  const selectedVersionForMeasurements = useSelectedVersionForMeasurements();
  const selectedScope = useSelectedScope();
  //
  let packages = usePackagesByScene(scene);
  console.log("packages", packages);
  let pdfs = usePdfsByScene(scene);
  //
  if (selectedScope && Array.isArray(selectedScope?.data?.packageIds)) {
    packages = packages.filter((pdf) =>
      selectedScope?.data?.packageIds.includes(pdf.id)
    );
  }

  const sortedPdfs = pdfs.sort((a, b) => a.name.localeCompare(b.name));

  sortedPdfs?.forEach((pdf) => {
    const pdfPackages = packages
      .filter((p) => p.fromModel.modelId === pdf.id)
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((p) => {
        if (
          !selectedVersionForMeasurements ||
          !filterBySelectedVersionForMeasurements
        ) {
          return true;
        } else {
          if (!Array.isArray(p?.revisionIds)) {
            return true;
          } else {
            return p.revisionIds.includes(selectedVersionForMeasurements.id);
          }
        }
      });
    if (pdfPackages.length > 0) {
      items.push({
        id: pdf.id,
        name: pdf.name,
        style: "title1",
        isPdf: true,
      });
      pdfPackages.forEach((p) => {
        items.push({
          id: p.id,
          name: p.name,
          count: p.measurementsData
            ? p.measurementsData.measurements?.length
            : p.measCount,
          style: p.enabled && !p.archived ? "normal" : "detail",
          archived: p.archived,
          revisionIds: p.revisionIds,
          isPdf: false,
          fromModel: p.fromModel,
        });
      });
    }
  });

  return items;
}
