import {useSelector} from "react-redux";
import useScopesByScene from "./useScopesByScene";
import getFixedScope from "../utils/getFixedScope";

export default function useScopeToOpen(options) {
  const useFixedScope = options?.useFixedScope;

  const models = useSelector((s) => s.viewer3D.models);

  const scopes = useScopesByScene(null, {addScopeAll: true});

  const scopeId = useSelector((s) => s.overviewer.scopeIdToOpen);

  let scopeToOpen = scopes?.find((scope) => scope.id === scopeId);
  if (useFixedScope) scopeToOpen = getFixedScope(scopeToOpen, models);

  return scopeToOpen;
}
