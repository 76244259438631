import {rawTypeToType} from "./rawTypeToType";
import sortByNum from "Features/elementTypes/utils/sortByNum";

export default function getSubjectTypeMapForExcel(subjects) {
  const _map = subjects.map((sub) => {
    const {
      subject: subjectName,
      color,
      dotted,
      drawingShape,
      count,
      elementType,
    } = sub;
    let type = {...elementType};
    if (!type.code) {
      type = rawTypeToType({
        dotted,
        drawingShape,
        subject: subjectName,
        color,
      });
    }

    return {
      subjectName: subjectName,
      subjectCount: count,
      subjectDrawingShape: drawingShape,
      subjectColor: color,
      subjectDotted: dotted,
      id: type?.id,
      group: type.group ? type.group : "-?-",
      num: type.num ? type.num.toString() : "0",
      name: type.name,
      code: type.code,
      drawingShape: type.drawingShape,
      color: type.color,
      dotted: type.dotted,
      isVoid: type.isVoid,
      unit: type.unit,
      dim1: type.dim1,
      dim2: type.dim2,
      height: type.height,
    };
  });

  const items = _map
    .sort((a, b) => sortByNum(a.num, b.num))
    .sort((a, b) => a.group.localeCompare(b.group));

  return items;
}
