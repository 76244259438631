import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedElementTypesGroupProxyInEditMeasurements} from "Features/elementTypes/elementTypesSlice";
import {
  setSelectedRessourceGroupProxyInEditMeasurements,
  setSelectedRessourceIdInEditMeasurements,
} from "Features/ressources/ressourcesSlice";
import {
  setSelectedElementTypeId,
  setShortcutElementTypes,
  setShortcutElementTypeGroups,
  setSelectedMeasurementIds,
} from "Features/measurements/measurementsSlice";
import ListElementTypesMultipleWithGroups from "Features/elementTypes/components/ListElementTypesMultipleWithGroups";
import ListRessourcesMultipleWithGroups from "Features/ressources/components/ListRessourcesMultipleWithGroups";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import getElementTypesWithMainQties from "Features/elementTypes/utils/getElementTypesWithMainQties";
import getMeasurementsCountByElementTypeGroupIdMap from "Features/elementTypes/utils/getMeasurementsCountByElementTypeGroupIdMap";
import {updateTempMeasurementFromElementType} from "Features/measurements/measurementsSlice";
// import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";
import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import getRessourcesWithQuantities from "Features/ressources/utils/getRessourcesWithQuantities";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import getMeasurementsCountByRessourceGroupIdMap from "Features/ressources/utils/getMeasurementsCountByRessourceGroupIdMap";
import getItemsMapById from "Utils/getItemsMapById";
import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";
import ButtonDialogDownloadExcel from "Features/excel/components/ButtonDialogDownloadExcel";

export default function SectionMeasurementsInLeftPanelV2({scene, caplaEditor}) {
  const dispatch = useDispatch();

  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // data - ui

  const os = useSelector((s) => s.ui.openSections);
  const pdfOnly = os.fixedViewerBox && !os.viewer3d;

  // data - tab

  const tab = useSelector((s) => s.leftPanel.tabInEditMeasurements);

  // data - measuremnts

  const measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: true,
    filterPackagesByVersion: true,
    filterPackagesBySelectedPdf: pdfOnly,
    filterPackagesBySelectedMeasurementsModel: false,
  });

  // data - types

  let types = useElementTypesBySceneProxy(scene);
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );
  const shortcutElementTypeGroups = useSelector(
    (s) => s.measurements.shortcutElementTypeGroups
  );

  // data - ressources

  const ressources = useRessourcesByScene(scene);

  // data - other entities

  const zones = useZonesByScene(scene);
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);

  const entitiesMap = {
    zone: zones,
    sectors: sectors,
    rooms: rooms,
  };

  // helpers - qties

  const typesWithMainQties = getElementTypesWithMainQties(
    types,
    measurements,
    filterNoVoids
  );
  // let ressourcesWithQuantities = getRessourcesWithQuantities(
  //   ressources,
  //   measurements,
  //   types,
  //   entitiesMap,
  //   filterNoVoids
  // );

  // const ressourcesWithNotNullQuantities = ressourcesWithQuantities.filter(
  //   (r) => typeof r.qtyRaw === "number"
  // );
  // ressourcesWithQuantities = getFilteredNomenclatureWithItems(
  //   ressources,
  //   ressourcesWithNotNullQuantities
  // );

  //console.log("ressourcesWithQuantities", ressourcesWithQuantities);

  // data - selection

  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );
  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceIdInEditMeasurements
  );

  const selectedMeasIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  // data - groups

  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  // const selectedRessourceGroupProxy = useSelector(
  //   (s) => s.ressources.selectedRessourceGroupProxyInEditMeasurements
  // );

  // helper - elementTypesMap

  const elementTypesMap = getItemsMapById(types);

  // helpers - groupCountMap

  const groupCountMap = getMeasurementsCountByElementTypeGroupIdMap(
    measurements,
    types
  );
  // const ressourceGroupCount = getMeasurementsCountByRessourceGroupIdMap(
  //   measurements,
  //   ressources
  // );

  // handlers

  function handleSelectionChangeMeasurements(ids) {
    dispatch(setSelectedMeasurementIds(ids));
  }

  function handleSelectionChange(ids) {
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    dispatch(setSelectedElementTypeId(id));
    if (id) dispatch(updateTempMeasurementFromElementType(elementTypesMap[id]));
  }

  function handleGroupProxyChange(groupProxy) {
    console.log("click on groupProxy", groupProxy);
    dispatch(setSelectedElementTypesGroupProxyInEditMeasurements(groupProxy));
  }

  function handleRessourceSelectionChange(ids) {
    console.log("selected ressource group", ids);
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    dispatch(setSelectedRessourceIdInEditMeasurements(id));
  }
  function handleRessourceGroupProxyChange(groupProxy) {
    console.log("click on groupProxy", groupProxy);
    dispatch(setSelectedRessourceGroupProxyInEditMeasurements(groupProxy));
  }
  function handleVisibleGroupIdsChange(ids) {
    dispatch(setShortcutElementTypeGroups(ids));
  }
  //
  return (
    <Box
      sx={{
        flexGrow: 1,
        //border: "2px solid red",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        //justifyItems: "stretch",
        minHeight: 0, // make it works !!!
      }}
    >
      {tab === "ELEMENT_TYPES" && (
        <ListElementTypesMultipleWithGroups
          elementTypes={typesWithMainQties}
          selectedElementTypeIds={[selectedElementTypeId]}
          onSelectionChange={handleSelectionChange}
          multipleSelection={false}
          selectedGroupId={selectedGroupProxy?.id}
          onSelectedGroupProxyChange={handleGroupProxyChange}
          counters={groupCountMap}
          showZero={true}
          forceSelection={true}
          maxHeightGroups={180}
          variant="list"
          showVisibility={true}
          visibleIds={shortcutElementTypes}
          onVisibleIdsChange={(ids) => dispatch(setShortcutElementTypes(ids))}
          visibleGroupIds={shortcutElementTypeGroups}
          onVisibleGroupIdsChange={handleVisibleGroupIdsChange}
          showMainQuantities={true}
        />
      )}
      {/* {tab === "RESSOURCES" && (
        <ListRessourcesMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          ressources={ressourcesWithQuantities}
          selectedRessourceIds={[selectedRessourceId]}
          onSelectionChange={handleRessourceSelectionChange}
          multipleSelection={false}
          selectedGroupId={selectedRessourceGroupProxy?.id}
          onSelectedGroupProxyChange={handleRessourceGroupProxyChange}
          counters={ressourceGroupCount}
          maxHeightGroups={180}
          forceSelection={true}
          showZero={true}
          variant="list"
          showTypes
        />
      )} */}
      <Box sx={{display: "flex"}}>
        <ButtonDialogDownloadExcel scene={scene} />
      </Box>
    </Box>
  );
}
