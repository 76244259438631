import ObjectsDataGrid from "Features/objects/components/ObjectsDataGrid";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {useSearchParams} from "react-router-dom";

export default function useSceneModulePathMap(sceneId, modelId = null) {
  // modelId = entityId
  const isStagingEnv = useIsStagingEnv();

  const [searchParams, setSearchParams] = useSearchParams();
  const scopeParam = searchParams.get("scope");

  let urlMap = {
    HOME: `/bimboxes/${sceneId}`,
    SCOPES: `/bimboxes/${sceneId}/scopes`,

    MODULES: `/bimboxes/${sceneId}/modules`,

    ADMIN: `/bimboxes/${sceneId}/admin`,
    ASSISTANT: `/bimboxes/${sceneId}/start/discoverUi`,
    ONBOARDING: `/bimboxes/${sceneId}/onboarding`,

    BUILD: `/bimboxes/${sceneId}/build/info`,
    SECTORS: `/bimboxes/${sceneId}/sectors`,
    ROOMS: `/bimboxes/${sceneId}/rooms`,
    PHASES: `/bimboxes/${sceneId}/phases`,
    VERSIONS: `/bimboxes/${sceneId}/versions`,
    LIBRARY: `/bimboxes/${sceneId}/library`,
    ELEMENT_TYPES: `/bimboxes/${sceneId}/elementTypes`,
    RESSOURCES: `/bimboxes/${sceneId}/ressources`,
    ARTICLES: `/bimboxes/${sceneId}/articles`,
    RELATIONS: `/bimboxes/${sceneId}/relations`,
    MARKERS: `/bimboxes/${sceneId}/markers`,
    VIEWER_3D: `/bimboxes/${sceneId}/viewer3D`,

    INPUTS: `/bimboxes/${sceneId}/inputs`,
    DATA: `/bimboxes/${sceneId}/data`,
    ZONES: `/bimboxes/${sceneId}/zones`,

    MEASUREMENTS: `/bimboxes/${sceneId}/measurements`,
    PACKAGES: `/bimboxes/${sceneId}/packages`,
    EDIT_MEASUREMENTS: `/bimboxes/${sceneId}/${modelId}/measurements`,

    SURVEYS: `/bimboxes/${sceneId}/surveys`,
    DATASETS: `/bimboxes/${sceneId}/datasets`,
    ISSUESETS: `/bimboxes/${sceneId}/issuesets`,
    ISSUES: `/bimboxes/${sceneId}/issues`,
    PROGRESS: `/bimboxes/${sceneId}/progress`,
    SAMPLES: `/bimboxes/${sceneId}/samples`,

    BIMAPPS: `/bimboxes/${sceneId}/bimapps`,
    SHAREDBOXES: `/bimboxes/${sceneId}/sharedboxes`,
    MATERIALS: `/bimboxes/${sceneId}/materials`,
    PRICINGS: `/bimboxes/${sceneId}/pricings`,
    VIEWS: `/bimboxes/${sceneId}/views`,
    VIEW: `/bimboxes/${sceneId}/views/${modelId}`,
    REPORTS: `/bimboxes/${sceneId}/reports`,
  };

  if (scopeParam) {
    Object.keys(urlMap).forEach((key) => {
      urlMap[key] = urlMap[key] + `?scope=${scopeParam}`;
    });
  }

  if (isStagingEnv) {
    Object.keys(urlMap).forEach((key) => {
      urlMap[key] = urlMap[key] + "?env=staging";
    });
  }

  return urlMap;
}
