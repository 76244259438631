import {useSelector, useDispatch} from "react-redux";

import {Box, Button, IconButton} from "@mui/material";

import SwitchShowReorderHelpers from "./SwitchShowReorderHelpers";

import {setDatagridIsInEditMode, setEditedRessources} from "../ressourcesSlice";
import useRessourcesByScene from "../hooks/useRessourcesByScene";
import useUpdateSceneRessources from "../hooks/useUpdateSceneRessources";
import getSortedItemsArrayByNum from "Utils/getSortedItemsArrayByNum";
import {Refresh} from "@mui/icons-material";

export default function ToolbarEditDatagrid({scene, hideButtons}) {
  const dispatch = useDispatch();

  // strings

  const editS = "Editer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const datagridIsInEditMode = useSelector(
    (s) => s.ressources.datagridIsInEditMode
  );
  const ressources = useRessourcesByScene(scene);

  const editedRessources = useSelector((s) => s.ressources.editedRessources);

  // const showReorderHelpers = useSelector(
  //   (s) => s.ressources.showReorderHelpers
  // );

  // data - methods

  const updateSceneRessources = useUpdateSceneRessources(scene);

  // handlers

  function handleEdit() {
    dispatch(setDatagridIsInEditMode(true));
    const sortedRessources = getSortedItemsArrayByNum(ressources);
    dispatch(setEditedRessources(sortedRessources));
  }

  function handleCancel() {
    dispatch(setDatagridIsInEditMode(false));
    dispatch(setEditedRessources([]));
  }

  function handleSave() {
    updateSceneRessources(editedRessources);
    dispatch(setDatagridIsInEditMode(false));
  }

  function handleClear() {
    const cleared = [];
    editedRessources.forEach((r) => {
      cleared.push({
        ...r,
        description: typeof r.description === "string" ? r.description : "",
      });
    });
    updateSceneRessources(cleared);
  }

  return (
    <Box
      sx={{
        width: 1,
        px: 1,
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        bgcolor: (theme) => (datagridIsInEditMode ? "common.white" : "white"),
      }}
    >
      {datagridIsInEditMode ? (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <SwitchShowReorderHelpers />
            <IconButton
              size="small"
              color="inherit"
              onClick={handleClear}
              sx={{mr: 1}}
            >
              <Refresh fontSize="small" />
            </IconButton>
          </Box>
          {!hideButtons && (
            <Box sx={{display: "flex", alignItems: "center", color: "white"}}>
              <Button
                size="small"
                color="inherit"
                onClick={handleCancel}
                sx={{mr: 1}}
              >
                {cancelS}
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={handleSave}
              >
                {saveS}
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{color: "text.secondary"}}>
          <Button size="small" color="inherit" onClick={handleEdit}>
            {editS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
