import {Box, Typography, List} from "@mui/material";

import ListItemButtonModule from "./ListItemButtonModule";

export default function SectionConfigModulesColumn({title, moduleKeys, scene}) {
  return (
    <Box>
      <Box
        sx={{
          p: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>{title}</Typography>
      </Box>
      <List>
        {moduleKeys.map((moduleKey) => {
          return (
            <ListItemButtonModule
              moduleKey={moduleKey}
              key={moduleKey}
              scene={scene}
            />
          );
        })}
      </List>
    </Box>
  );
}
