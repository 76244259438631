import getItemsWithNumsFromItemsWithStyles from "Utils/getItemsWithNumsFromItemsWithStyles";
import sortByNum from "Utils/sortByNum";

export default function resetElementTypesGroupNums(group) {
  const elementTypes = [...group.elementTypes].sort((a, b) =>
    sortByNum(a.num, b.num)
  );

  const newElementTypes = getItemsWithNumsFromItemsWithStyles(
    elementTypes,
    "1"
  );

  console.log(
    "newNums",
    newElementTypes.map((t) => t.num)
  );

  return {...group, elementTypes: newElementTypes};
}
