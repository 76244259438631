import React from "react";

import {Box} from "@mui/material";

import ButtonLoadExcelLibrary from "./ButtonLoadExcelLibrary";
import BoxLibraryName from "./BoxLibraryName";

export default function HeaderLibrary({scene}) {
  return (
    <Box sx={{pl: 2, mb: 2, display: "flex", alignItems: "center"}}>
      <BoxLibraryName />
      <ButtonLoadExcelLibrary scene={scene} />
    </Box>
  );
}
