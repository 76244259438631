import {useSelector} from "react-redux";

import {Button, Box} from "@mui/material";
import {SystemUpdateAlt as Load} from "@mui/icons-material";

export default function ButtonLoadAllModels({models, editor3d}) {
  // strings

  const loadAllString = "Load all the models";

  // data

  const detailedModels = useSelector((state) => state.viewer3D.detailedModels);

  // handlers

  async function handleLoadAll() {
    for (let i = 0; i < models.length; i++) {
      const model = models[i];
      if (!detailedModels.includes(model.id)) {
        await editor3d?.loader.loadModelFilesAndLoadDetail(model);
      }
    }
  }

  return (
    <Box sx={{bgcolor: "common.white"}}>
      <Button
        size="small"
        startIcon={<Load />}
        onClick={handleLoadAll}
        variant="outlined"
        fullWidth
      >
        {loadAllString}
      </Button>
    </Box>
  );
}
