import {useEffect} from "react";

import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";

import {useSelector, useDispatch} from "react-redux";

import {
  setEnabledPackageIdsInMainScope,
  setHelperPackageIdsInMainScope,
  setMeasCountPackageIdMap,
  setPackageIdsInMainScope,
  setScopeAndCounter,
} from "../overviewerSlice";
// import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
import useSelectedPackage from "Features/elementPackages/useSelectedPackage";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import useSelectedElementTypesGroup from "Features/elementTypes/hooks/useSelectedElementTypesGroup";

import getMeasurementsFromPackages from "Features/measurements/utils/getMeasurementsFromPackages";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
// import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import getMeasurementsByElementTypeAndElementTypeGroup from "Features/measurements/utils/getMeasurementsByElementTypeAndElementTypeGroup";
import getMeasurementsByRessourceAndRessourceGroup from "Features/measurements/utils/getMeasurementsByRessourceAndRessourceGroup";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

import getModelIdMeasCountMapFromPackages from "Features/elementPackages/utils/getModelIdMeasCountMapFromPackages";
// import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";
import getMeasCountPackageIdMapFromMeasurements from "Features/measurements/utils/getMeasCountPackageIdMapFromMeasurements";
import useSelectedRessourcesGroup from "Features/ressources/hooks/useSelectedRessourcesGroup";
import useSelectedArticlesGroup from "Features/articles/hooks/useSelectedArticlesGroup";

export default function useUpdatePackageIdsInMainScope(scene) {
  const dispatch = useDispatch();

  //

  console.time("debugtime useUpdatePackageIdsInMainScope");

  // const

  const limit = 900; // number of elements displayed in 3D viewer at once.

  // data

  const sceneModule = useSceneModule();
  let limitInSceneModule;
  if (sceneModule !== "ARTICLES") limitInSceneModule = limit;

  const selectedMeasurementsModel = useSelectedMeasurementsModel();
  // const selectedPdfModel = useSelectedPdfModel();
  const models = useSelector((s) => s.viewer3D.models);
  const selectedPackage = useSelectedPackage();

  // const selectedScope = useSelectedScope();
  // const selectedVersion = useSelectedVersionForMeasurements();

  const os = useSelector((s) => s.ui.openSections);

  // packages

  const pdfOnly = os.fixedViewersBox && !os.viewer3D;

  const options = {
    filterByScope: true,
    filterByVersion: true,
    enabledOnly: true,
    filterByPdf:
      ["INPUTS", "EDIT_MEASUREMENTS"].includes(sceneModule) || pdfOnly,
  };
  const packages = usePackagesByScene(scene, options);

  // helpers

  const helpers = models.filter((m) => m.isHelper);

  // ressources

  const selectedRessource = useSelectedRessource(scene);
  const selectedRessourceGroup = useSelectedRessourcesGroup(scene);
  const ressources = useRessourcesByScene(scene, {filterByScope: true});

  // articles

  const selectedArticlesGroup = useSelectedArticlesGroup();

  // types

  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const selectedElementType = useSelectedElementType(scene);
  const selectedElementTypesGroup = useSelectedElementTypesGroup(scene);

  // measurements

  const allMeasurements = getMeasurementsFromPackages(packages);

  const measCountPackageIdMap =
    getMeasCountPackageIdMapFromMeasurements(allMeasurements);

  const [typeMeasCountMap, groupTypeMeasCountMap] =
    getMeasurementsByElementTypeAndElementTypeGroup(
      allMeasurements,
      elementTypes
    );

  const [resMeasCountMap, resGroupMeasCountMap] =
    getMeasurementsByRessourceAndRessourceGroup(allMeasurements, ressources);

  // return

  let idsInScope = [];
  let enabledIds = null; // if not set in the if loop, set eventually.
  let entityType = "DEFAULT"; // "RESSOURCE", "RESSOURCE_GROUP"
  let iconType = "DEFAULT";
  let entityObject = null;
  let modelIdCountMap = {}; // to set counters in Card.
  let callToAction = "-";

  let enableAll = false;

  // computation

  if (sceneModule === "RESSOURCES") {
    iconType = "RESSOURCES";
    const ressourceGroup = selectedRessourceGroup;
    const ressourceId = selectedRessource?.id;

    if (!ressourceGroup && !ressourceId) {
      entityType = "RESSOURCE_GROUP";
      callToAction = "Sélectionnez un groupe / article";
      idsInScope = [];
      enabledIds = [];
      //} else if (ressourceGroup && !ressourceId) { we do not update on group change.
    } else if (ressourceGroup) {
      entityType = "RESSOURCES_GROUP";
      entityObject = {name: ressourceGroup.name};
      if (resGroupMeasCountMap) {
        modelIdCountMap = resGroupMeasCountMap[ressourceGroup.id];
        idsInScope = modelIdCountMap ? Object.keys(modelIdCountMap) : [];
      }
    } else if (ressourceGroup && ressourceId) {
      entityType = "RESSOURCE";
      entityObject = selectedRessource;
      if (resMeasCountMap) {
        modelIdCountMap = resMeasCountMap[ressourceId];
        idsInScope = modelIdCountMap ? Object.keys(modelIdCountMap) : [];
      }
      // enabled
      //enabledIds = [];
    }
    // enabled
    // if (modelIdCountMap) {
    //   enableAll =
    //     Object.values(modelIdCountMap).reduce((ac, cur) => ac + cur, 0) < limit;
    // }
    if (enableAll) enabledIds = [...idsInScope];
  } else if (sceneModule === "ELEMENT_TYPES") {
    iconType = "ELEMENT_TYPES";
    const elementTypesGroupId = selectedElementTypesGroup?.id;
    const elementTypeId = selectedElementType?.id;
    if (!elementTypesGroupId && !elementTypeId) {
      entityType = "ELEMENT_TYPE";
      callToAction = "Sélectionnez un groupe / type";
      idsInScope = [];
      enabledIds = [];
      // } else if (elementTypesGroupId && !elementTypeId) { // we do not update packages on selection.
    } else if (elementTypesGroupId) {
      //
      entityType = "ELEMENT_TYPES_GROUP";
      entityObject = selectedElementTypesGroup;
      if (groupTypeMeasCountMap) {
        modelIdCountMap = groupTypeMeasCountMap[elementTypesGroupId];
        idsInScope = modelIdCountMap ? Object.keys(modelIdCountMap) : [];
      }
    } else if (elementTypeId) {
      entityType = "ELEMENT_TYPE";
      entityObject = selectedElementType;
      if (typeMeasCountMap) {
        modelIdCountMap = typeMeasCountMap[elementTypeId];
        idsInScope = modelIdCountMap ? Object.keys(modelIdCountMap) : [];
      }
    }
  } else if (sceneModule === "INPUTS") {
    idsInScope = packages
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((m) => m.id);
  } else if (sceneModule === "PACKAGES") {
    entityType = "PACKAGE";
    iconType = "PACKAGES";
    entityObject = selectedPackage;
    idsInScope = [selectedPackage?.id];
    enabledIds = [selectedPackage?.id];
  } else if (sceneModule === "EDIT_MEASUREMENTS") {
    entityType = "PACKAGE";
    iconType = "PACKAGES";
    entityObject = selectedMeasurementsModel;
    idsInScope = packages
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((m) => m.id);
    enabledIds = [selectedMeasurementsModel?.id];
    modelIdCountMap = getModelIdMeasCountMapFromPackages([
      selectedMeasurementsModel,
    ]);
  } else if (sceneModule === "MEASUREMENTS") {
    entityType = "SCOPE";
    iconType = "MEASUREMENTS";
    idsInScope = packages.map((p) => p.id);
    modelIdCountMap = getModelIdMeasCountMapFromPackages(packages);
  } else if (sceneModule === "ARTICLES") {
    //
    entityType = "ARTICLE";
    iconType = "ARTICLES";
    idsInScope = packages.map((p) => p.id);

    modelIdCountMap = getModelIdMeasCountMapFromPackages(packages);
  } else {
    console.log("-");
  }

  //
  // enabledIds
  //

  if (modelIdCountMap) {
    const measCount = Object.values(modelIdCountMap).reduce(
      (ac, cur) => ac + cur,
      0
    );
    enableAll = !limitInSceneModule || measCount < limitInSceneModule;
  }
  if (!enabledIds) {
    if (enableAll) {
      enabledIds = [...idsInScope];
    } else {
      enabledIds = [];
    }
  }

  // update - ids in scope

  const inScopeHash = idsInScope
    .map((id) => (id ? id : "-"))
    .sort()
    .join();
  useEffect(() => {
    dispatch(setPackageIdsInMainScope(idsInScope));
  }, [inScopeHash]);

  // update - enabled ids

  const enabledHash =
    enabledIds.length === 0
      ? "-"
      : enabledIds
          .map((id) => (id ? id : "-"))
          .sort()
          .join();

  useEffect(() => {
    dispatch(setEnabledPackageIdsInMainScope(enabledIds));
    //mnger.setModelsInShortMemory(enabledIds)
  }, [enabledHash + inScopeHash]);

  // update - helpers

  const helperHash = helpers
    .map((m) => (m.id ? m.id : "-"))
    .sort()
    .join();

  useEffect(() => {
    dispatch(setHelperPackageIdsInMainScope(helpers.map((m) => m.id)));
  }, [helperHash]);

  // update - measCount

  const measCountHash = allMeasurements
    .map((m) => m.id)
    .sort()
    .join();

  //

  useEffect(() => {
    dispatch(setMeasCountPackageIdMap(measCountPackageIdMap));
  }, [measCountHash]);

  // return

  dispatch(
    setScopeAndCounter({entityObject, entityType, iconType, modelIdCountMap})
  );

  //

  console.timeEnd("debugtime useUpdatePackageIdsInMainScope");
}
