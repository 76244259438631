import {Box, alpha, Typography} from "@mui/material";
import {SettingsApplications as Logo} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";

import ButtonShowMasks from "./ButtonShowMasks";

export default function HeaderConfigCenter({model, editor3d}) {
  const {t} = useTranslation(["ui", "viewer3D"]);

  // strings

  const titleString = t("viewer3D:configCenter.header.title");

  return (
    <Box
      sx={{
        width: 1,
        background: (theme) => alpha(theme.palette.common.yellow, 0.8),
        height: (theme) => theme.spacing(7),
        display: "flex",
        alignItems: "center",
        pl: 2,
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Logo sx={{mr: 1}} />
        <Typography variant="body2">
          <b>{titleString}</b>
        </Typography>
      </Box>

      <Typography sx={{ml: 5, maxWidth: 600}} noWrap>
        {model?.name}
      </Typography>

      <Box sx={{ml: 5}}>
        <ButtonShowMasks editor3d={editor3d} />
      </Box>
    </Box>
  );
}
