import React from "react";

import {Box, Typography} from "@mui/material";

export default function ListItemRoom({room}) {
  return (
    <Box sx={{width: 1, p: 0.5}}>
      <Typography>{room.name}</Typography>
    </Box>
  );
}
