// import {useEffect} from "react";
import {useSelector} from "react-redux";

// import {
//   setSelectedMeasurementIds,
//   setSelectedMeasurementId,
// } from "Features/measurements/measurementsSlice";
import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import useSelectedZone from "Features/zones/hooks/useSelectedZone";
// import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSelectedMeasurement from "Features/measurements/useSelectedMeasurement";
import useTempMeasurement from "Features/measurements/useTempMeasurement";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import useSelectedPackage from "Features/elementPackages/useSelectedPackage";
import useSelectedMarker from "Features/markers/hooks/useSelectedMarker";
import useSelectedIssue from "Features/issues/useSelectedIssue";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
import useSelectedScene from "Features/scenes/useSelectedScene";
import useSelectedInput from "Features/inputs/hooks/useSelectedInput";
import useSelectedBimapp from "Features/bimapps/hooks/useSelectedBimapp";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSelectedIssueInModule from "Features/issues/useSelectedIssueInModule";
import useSelectedProgressListing from "Features/progress/useSelectedProgressListing";
import useSelectedRoom from "Features/rooms/hooks/useSelectedRoom";
import useSelectedSector from "Features/sectors/hooks/useSelectedSector";
import useAnnotatedPdfModel from "Features/annotatedPdf/hooks/useAnnotatedPdfModel";
// import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
import useSelectedMaterial from "Features/materials/hooks/useSelectedMaterial";
import useSelectedSurvey from "Features/surveys/hooks/useSelectedSurvey";
import useSelectedArticle from "Features/articles/hooks/useSelectedArticle";
import useSelectedArticlesGroup from "Features/articles/hooks/useSelectedArticlesGroup";
// import useSelectedSharedbox from "Features/sharedboxes/hooks/useSelectedSharedbox";

export default function useSelection({scene, caplaEditor, viewer}) {
  // const dispatch = useDispatch();

  let selection = {};

  // strings

  const newRessourceS = "Nouvel article";
  const newRoomS = "Nouvelle zone";
  const newSectorS = "Nouveau secteur";
  const sharedSceneS = "Bimbox partagée";
  const newBimappS = "Nouvelle Bim App";
  const newSharedboxS = "Nouveau partage";
  const newMaterialS = "Nouveau matériau";
  const nameVoid = "";
  const newPhaseS = "Nouvelle phase";
  const newSurveyS = "Nouvelle visite";

  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  // data - clicked object

  // const clickedItem = useSelector((s) => s.selection.clickedItem);

  // const clickedObject = useSelector((s) => s.viewer3D.clickedObject);
  // const clickedType = clickedObject?.type;
  // const clickedId = clickedObject?.entityID;

  // data - selection

  const selectedMarker = useSelectedMarker();
  const selectedPdfModel = useSelectedPdfModel();
  const tempInput = useSelector((s) => s.inputs.tempInput);
  const annotatedPdfModel = useAnnotatedPdfModel();
  const selectedInput = useSelectedInput();

  // data - sector
  const selectedSector = useSelectedSector(scene);
  const tempSector = useSelector((s) => s.sectors.tempSector);

  // data - room
  const selectedRoom = useSelectedRoom(scene);
  const tempRoom = useSelector((s) => s.rooms.tempRoom);

  // data - material
  const selectedMaterial = useSelectedMaterial(scene);
  const newMaterial = useSelector((s) => s.materials.tempMaterial);

  // data - scene

  const selectedScene = useSelectedScene();

  // data - home selection

  const homeViewMode = useSelector((s) => s.ui.homeViewMode);

  // data - selected ressource

  const selectedRessource = useSelectedRessource(scene);

  // data - selected article

  const selectedArticle = useSelectedArticle(scene);
  const selectedArticlesGroup = useSelectedArticlesGroup();

  // data - element type

  const selectedElementType = useSelectedElementType(scene);

  // data - selected zone

  const selectedZone = useSelectedZone(scene);
  // const zones = useZonesByScene(scene);
  // const clickedZone = zones.find((z) => z.id === clickedItem?.id);

  // data - selected package
  const selectedPackage = useSelectedPackage();

  // data - selected issue

  const selectedIssue = useSelectedIssue();
  const selectedIssueInModule = useSelectedIssueInModule();

  // data - selected measurement & types & ressources ()

  const selectedMeasurement = useSelectedMeasurement();
  const tempMeasurement = useTempMeasurement({
    scene,
    editorPdf: caplaEditor?.editorPdf,
  });
  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedMeasurementElementType = elementTypes.find(
    (t) => t.id === selectedMeasurement?.elementTypeId
  );
  const selectedPhase = useSelector((s) => s.phases.selectedPhase);
  // const tId = useSelector((s) => s.measurements.selectedTypesInDatagrid)[0];
  // const measurementsElementType = elementTypes.find((t) => t.id === tId);

  // const sceneMeasurements = useSceneMeasurements(scene);
  // const clickedMeasurement = sceneMeasurements.find((m) => m.id === clickedId);

  // data - progress

  const selectedProgressListing = useSelectedProgressListing(scene);

  // data - bimapp

  const selectedBimapp = useSelectedBimapp(scene);

  // data - sharedbox

  // const selectedSharedbox = useSelectedSharedbox(scene);
  const selectedSharedbox = useSelector((s) => s.sharedboxes.selectedSharedbox);

  // data - survey

  const selectedSurvey = useSelectedSurvey(scene);

  // effects

  // useEffect(() => {
  //   if (!clickedId) {
  //     // dispatch(setSelectedMeasurementId(null));
  //   } else {
  //     if (clickedType === "MEASUREMENT")
  //       dispatch(setSelectedMeasurementIds([clickedId]));
  //     // dispatch(setSelectedMeasurementId(clickedId));
  //   }
  // }, [clickedId]);

  if (viewer) {
    selection = {
      type: "SCENE",
      item: {...scene, name: scene?.title},
    };
  }

  if (pageType === "HOME") {
    if (scene) {
      selection = {
        type: "SCENE",
        item: {...selectedScene, name: selectedScene?.title},
      };
    } else {
      selection = {
        type: "NEW_SCENE",
        item: {name: "Nouvelle Bimbox"},
      };
    }
  } else if (selectedIssue) {
    selection = {
      type: "ISSUE",
      item: {...selectedIssue, name: selectedIssue.title},
    };
  } else if (selectedIssueInModule) {
    selection = {
      type: "ISSUE",
      item: {...selectedIssueInModule, name: selectedIssueInModule.title},
    };
  } else if (sceneModule === "ADMIN") {
    if (selectedScene) {
      selection = {
        type: "SCENE",
        item: {...selectedScene, name: selectedScene.title},
      };
    }
  } else if (sceneModule === "HOME") {
    if (homeViewMode === "ISSUES" && selectedIssueInModule) {
      selection = {
        type: "ISSUE",
        item: {...selectedIssueInModule, name: selectedIssueInModule?.title},
      };
      // } else if (clickedItem?.type === "ISSUE") {
      //   selection = {
      //     type: "ISSUE",
      //     item: {
      //       ...clickedItem,
      //       name: clickedItem.title,
      //     },
      //   };
      // } else if (clickedItem?.type === "MEASUREMENTS_RESSOURCE") {
      //   selection = {
      //     type: "HOME_RESSOURCE",
      //     item: {
      //       ...clickedItem,
      //       name: `${clickedItem.num} • ${clickedItem.name}`,
      //       nameOrigin: clickedItem.name,
      //     },
      //   };
      // } else if (clickedItem?.type === "MEASUREMENTS_ROOM") {
      //   selection = {
      //     type: "ROOM",
      //     item: {
      //       ...clickedItem,
      //     },
      //   };
      // } else if (clickedItem?.type === "MEASUREMENT") {
      //   selection = {
      //     type: "MEASUREMENT",
      //     item: {
      //       ...clickedItem,
      //       name: clickedItem?.codeName,
      //     },
      //   };
      // } else if (
      //   clickedItem?.type === "MEASUREMENT_ELEMENT" &&
      //   clickedType === "MEASUREMENT"
      // ) {
      //   selection = {
      //     type: "MEASUREMENT",
      //     item: {
      //       ...clickedMeasurement,
      //       name: clickedMeasurement.codeName,
      //     },
      //   };
      // } else if (
      //   clickedItem?.type === "MEASUREMENTS_ELEMENT_TYPE" &&
      //   measurementsElementType
      // ) {
      //   selection = {
      //     type: "MEASUREMENTS_ELEMENT_TYPE",
      //     item: measurementsElementType,
      //   };
      // } else if (clickedItem?.type === "ZONE" && clickedZone) {
      //   selection = {
      //     type: "ZONE",
      //     item: clickedZone,
      //   };
    } else {
      selection = {
        type: "SCENE",
        item: {
          ...scene,
          name: viewer ? sharedSceneS : scene?.title,
        },
      };
    }
  } else if (sceneModule === "VIEWER_3D") {
    if (selectedZone) {
      selection = {
        type: "ZONE",
        item: selectedZone,
      };
    } else if (selectedMeasurement) {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    }
  } else if (sceneModule === "ZONES") {
    if (selectedZone) {
      selection = {
        type: "ZONE",
        item: selectedZone,
      };
    }
  } else if (sceneModule === "SECTORS") {
    if (selectedSector) {
      selection = {
        type: "SECTOR",
        item: {...selectedSector},
      };
    } else {
      selection = {
        type: "TEMP_SECTOR",
        item: {
          ...tempSector,
          name: tempSector?.name ? tempSector.name : newSectorS,
        },
      };
    }
  } else if (sceneModule === "ROOMS") {
    if (selectedRoom) {
      selection = {
        type: "ROOM",
        item: {...selectedRoom},
      };
    } else {
      selection = {
        type: "TEMP_ROOM",
        item: {
          ...tempRoom,
          name: tempRoom?.name ? tempRoom.name : newRoomS,
        },
      };
    }
  } else if (sceneModule === "MATERIALS") {
    if (selectedMaterial) {
      selection = {
        type: "MATERIAL",
        item: {...selectedMaterial},
      };
    } else {
      selection = {
        type: "NEW_MATERIAL",
        item: {
          ...newMaterial,
          name: newMaterial?.name ? newMaterial.name : newMaterialS,
        },
      };
    }
  } else if (sceneModule === "INPUTS") {
    if (selectedInput && selectedPdfModel) {
      selection = {
        type: "PDF_MODEL",
        item: {...selectedPdfModel},
      };
    } else {
      selection = {
        type: "TEMP_INPUT",
        item: {
          ...tempInput,
          name: tempInput?.name ? tempInput.name : "Nouveau fichier",
        },
      };
    }
  } else if (sceneModule === "DATA") {
    if (selectedPdfModel) {
      selection = {
        type: "PDF_MODEL",
        item: {...selectedPdfModel},
      };
    }
  } else if (sceneModule === "MEASUREMENTS") {
    if (selectedMeasurement) {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    }
  } else if (sceneModule === "EDIT_MEASUREMENTS" || pageType === "COLORING") {
    if (selectedMeasurement) {
      selection = {
        type: "MEASUREMENT",
        item: {
          ...selectedMeasurement,
          name: selectedMeasurementElementType?.name,
        },
      };
    } else {
      selection = {
        type: "TEMP_MEASUREMENT",
        item: {
          ...tempMeasurement,
          name: tempMeasurement?.elementTypeId
            ? tempMeasurement?.codeName
            : nameVoid,
        },
      };
    }
  } else if (sceneModule === "MARKERS") {
    if (selectedMarker) {
      selection = {
        type: "MARKER",
        item: {...selectedMarker, name: selectedMarker.description},
      };
    } else {
      selection = {
        type: "NEW_MARKER",
        item: {name: "New"},
      };
    }
  } else if (sceneModule === "RESSOURCES") {
    if (selectedRessource) {
      selection = {
        type: "RESSOURCE",
        item: selectedRessource,
      };
    } else {
      selection = {
        type: "NEW_RESSOURCE",
        item: {name: newRessourceS},
      };
    }
  } else if (sceneModule === "ELEMENT_TYPES") {
    if (selectedElementType) {
      selection = {
        type: "ELEMENT_TYPE",
        item: {...selectedElementType},
      };
    } else {
      selection = {
        type: "NEW_ELEMENT_TYPE",
        item: {name: "New"},
      };
    }
  } else if (sceneModule === "PACKAGES") {
    if (selectedPackage) {
      selection = {
        type: "PACKAGE",
        item: {...selectedPackage},
      };
    } else {
      selection = {
        type: "NEW_PACKAGE",
        item: {name: "New"},
      };
    }
  } else if (sceneModule === "ISSUES") {
    if (selectedIssue) {
      selection = {
        type: "ISSUE",
        item: {...selectedIssue, name: selectedIssue.title},
      };
    } else {
      selection = {
        type: "NEW_ISSUE",
        item: {name: "New"},
      };
    }
  } else if (sceneModule === "PROGRESS") {
    selection = {
      type: "PROGRESS_LISTING",
      item: selectedProgressListing,
    };
  } else if (sceneModule === "ANNOTATED_PDF") {
    selection = {
      type: "ANNOTATED_PDF_MODEL",
      item: annotatedPdfModel,
    };
  } else if (sceneModule === "BIMAPPS") {
    if (selectedBimapp) {
      selection = {
        type: "BIMAPP",
        item: selectedBimapp,
      };
    } else {
      selection = {
        type: "NEW_BIMAPP",
        item: {name: newBimappS},
      };
    }
  } else if (sceneModule === "SHAREDBOXES") {
    if (selectedSharedbox) {
      selection = {
        type: "SHAREDBOX",
        item: selectedSharedbox,
      };
    } else {
      selection = {
        type: "NEW_SHAREDBOX",
        item: {name: newSharedboxS},
      };
    }
  } else if (sceneModule === "SURVEYS") {
    if (selectedSurvey) {
      selection = {
        type: "SURVEY",
        item: selectedSurvey,
      };
    } else {
      selection = {
        type: "NEW_SURVEY",
        item: {name: newSurveyS},
      };
    }
  } else if (sceneModule === "PHASES") {
    if (selectedPhase) {
      selection = {
        type: "PHASE",
        item: selectedPhase,
      };
    } else {
      selection = {
        type: "NEW_PHASE",
        item: {name: newPhaseS},
      };
    }
  } else if (sceneModule === "ARTICLES") {
    if (selectedArticle?.id) {
      selection = {
        type: "ARTICLE",
        item: selectedArticle,
      };
    } else if (selectedArticlesGroup) {
      selection = {
        type: "ARTICLES_GROUP",
        item: selectedArticlesGroup,
      };
    }
  }
  return selection;
}
