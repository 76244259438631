import {Vector3, Object3D} from "three";

/*
 * compute camera position normal to the plane, at a distance close to initial camera position
 */
export function getCameraPositionFromPlaneIntersection({
  target,
  normal,
  originV,
}) {
  let newOriginV = new Vector3();

  let Ci = new Vector3(); // initial camera position
  let T = new Vector3(target.x, target.y, target.z); // target position on the plane
  let n = new Vector3(normal.x, normal.y, normal.z); // normal direction of the plane.
  let TCi = new Vector3();

  n.normalize();

  Ci.copy(originV);
  TCi = Ci.sub(T);
  const dist = TCi.dot(n);

  T.add(n.multiplyScalar(dist));

  newOriginV.copy(T);
  return newOriginV;
}

export function getRotationPath(center, start, count = 4) {
  const objectC = new Object3D();
  const objectS = new Object3D();

  const path = [];

  const angle = (2 * Math.PI) / count;

  objectC.position.set(center.x, center.y, center.z);
  objectC.add(objectS);
  objectS.position.set(
    start.x - center.x,
    start.y - center.y,
    start.z - center.z
  );

  for (let i = 0; i < count; i++) {
    const p = new Vector3();
    objectC.rotateY(angle);
    objectS.getWorldPosition(p);
    path.push(p);
  }

  return path;
}
