import {useState} from "react";
import {useSelector} from "react-redux";

import useActionsMeasurementsMap from "../useActionsMeasurementsMap";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateElementType from "Features/elementTypes/components/DialogCreateElementType";
import DialogExtractMeasurements from "./DialogExtractMeasurements";
import DialogCopyMeasurements from "./DialogCopyMeasurements";
import DialogPasteMeasurements from "./DialogPasteMeasurements";
import DialogAutomation from "./DialogAutomation";

import getItemsMapById from "Utils/getItemsMapById";

//import DialogToRessources from "./DialogToRessources";

export default function SectionActionsMeasurements({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  const selectedPdfModel = useSelectedPdfModel();
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const measurements = useSelector((s) => s.measurements.items);

  // helpers - selectedMeasurements

  const measById = getItemsMapById(measurements);
  const selectedMeasurements = selectedMeasurementIds.map((id) => measById[id]);
  const copiedMeasurements = useSelector(
    (s) => s.measurements.copiedMeasurements
  );

  // state

  const [openDialogCreateElementType, setOpenDialogCreateElementType] =
    useState(false);
  const [openDialogExtractMeasFromPdf, setOpenDialogExtractMeasFromPdf] =
    useState(false);
  const [openDialogCopyMeasurements, setOpenDialogCopyMeasurements] =
    useState(false);
  const [openDialogPasteMeasurements, setOpenDialogPasteMeasurements] =
    useState(false);
  const [openDialogAutomation, setOpenDialogAutomation] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    createElementType: () => setOpenDialogCreateElementType(true),
    extractMeasFromPdf: () => setOpenDialogExtractMeasFromPdf(true),
    copyMeasurements: () => setOpenDialogCopyMeasurements(true),
    pasteMeasurements: () => setOpenDialogPasteMeasurements(true),
    automation: () => setOpenDialogAutomation(true),
  };

  // handlers

  function handleClick(actionKey) {
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // data - handlers

  const actionsMap = useActionsMeasurementsMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateElementType && (
        <DialogCreateElementType
          scene={scene}
          open={openDialogCreateElementType}
          onClose={() => setOpenDialogCreateElementType(false)}
        />
      )}

      {openDialogExtractMeasFromPdf && (
        <DialogExtractMeasurements
          open={openDialogExtractMeasFromPdf}
          onClose={() => setOpenDialogExtractMeasFromPdf(false)}
          scene={scene}
          pdfModel={selectedPdfModel}
          caplaEditor={caplaEditor}
        />
      )}
      {openDialogCopyMeasurements && (
        <DialogCopyMeasurements
          open={openDialogCopyMeasurements}
          onClose={() => setOpenDialogCopyMeasurements(false)}
          scene={scene}
          measurements={selectedMeasurements}
        />
      )}
      {openDialogPasteMeasurements && (
        <DialogPasteMeasurements
          open={openDialogPasteMeasurements}
          onClose={() => setOpenDialogPasteMeasurements(false)}
          scene={scene}
          caplaEditor={caplaEditor}
          copiedMeasurements={copiedMeasurements}
        />
      )}
      {openDialogAutomation && (
        <DialogAutomation
          open={openDialogAutomation}
          onClose={() => setOpenDialogAutomation(false)}
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {/* <DialogToRessources
        open={openDialogToRessources}
        onClose={() => setOpenDialogToRessources(false)}
      /> */}
    </>
  );
}
