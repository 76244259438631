import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

// import CardTwoMarkersConfig from "./CardTwoMarkersConfig";
import ProcessConfigTwoMarkers from "./ProcessConfigTwoMarkers";

import DisplayBox from "Components/DisplayBox";

import {setMode, setStep, setSubStep} from "Features/viewer3D/viewer3DSlice";

export default function ToolTwoMakersConfig({editor3d, onClose}) {
  const dispatch = useDispatch();
  // data

  const mode = useSelector((state) => state.viewer3D.mode);

  // init

  useEffect(() => {
    dispatch(setMode("CONFIG_TWO_MARKERS"));
    dispatch(setStep("SLOPE"));
    dispatch(setSubStep("DEFAULT"));
  }, []);

  return (
    <div>
      <DisplayBox open={mode === "CONFIG_TWO_MARKERS"}>
        <ProcessConfigTwoMarkers editor3d={editor3d} onClose={onClose} />
      </DisplayBox>

      {/* <CardTwoMarkersConfig
        editor3d={editor3d}
        editor2={editor2}
        onClose={onClose}
      /> */}
    </div>
  );
}
