import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {fetchProfileService, updateProfileService} from "./services";

export const fetchProfile = createAsyncThunk(
  "profile/fetchProfile",
  fetchProfileService
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  updateProfileService
);

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    status: "idle", // once fetch from api => "loaded"
    data: {},
  },
  reducers: {
    setProfileStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: {
    [fetchProfile.pending]: (state) => {
      state.status = "loading";
    },
    [fetchProfile.fulfilled]: (state, action) => {
      console.log("STATE fetchProfile", action.payload);
      state.data = action.payload;
      state.status = "loaded";
    },
    [updateProfile.pending]: (state) => {
      state.status = "updating";
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "loaded";
    },
  },
});

export const {setProfileStatus} = profileSlice.actions;

export default profileSlice.reducer;
