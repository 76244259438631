import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {Box, ListItemButton, Typography} from "@mui/material";

export default function ListItemSceneRessources({
  ressource,
  selected,
  onRessourceClick,
  resWithProperQuantity,
}) {
  const {shareLink} = useParams();

  // data

  const pricingTable = useSelector((s) => s.pricing.pricingTable);

  // helper - styles

  const styleMap = {
    DEFAULT: {
      fontWeight: "normal",
      bgcolor: "common.white",
      color: "text.primary",
    },
    TITLE1: {fontWeight: "bold", bgcolor: "#bbbbc0", color: "text.primary"},
    TITLE2: {
      fontWeight: "bold",
      bgcolor: "background.default",
      color: "text.primary",
    },
    DETAIL: {
      fontWeight: "normal",
      bgcolor: "common.white",
      color: "text.secondary",
    },
  };

  // helpers

  let ressourceLabel =
    ressource.hideNum === true
      ? ressource.name
      : `${ressource.num} • ${ressource.name}`;

  if (ressource.extractedText) ressourceLabel += " .";
  const priceIndicator = "€";
  let quantity = ressource?.hardcodedQty
    ? ressource.hardcodedQty
    : resWithProperQuantity?.quantity;
  if (!quantity) {
    quantity = ressource?.quantity;
  }

  let qtyLabel =
    typeof quantity === "number"
      ? `${quantity.toFixed(0)} ${ressource.unit}`
      : `- ${ressource.unit}`;
  if (ressource.isTitle) qtyLabel = "";

  let styleType = "DEFAULT";
  if (
    ressource.isTitle &&
    ressource.rank === 1 &&
    ressource.style === undefined
  )
    styleType = "TITLE1";
  if (
    ressource.isTitle &&
    ressource.rank === 2 &&
    ressource.style === undefined
  )
    styleType = "TITLE2";
  if (ressource.style === "title1") styleType = "TITLE1";
  if (ressource.style === "title2") styleType = "TITLE2";
  if (ressource.style === "detail") styleType = "DETAIL";
  const {fontWeight, bgcolor, color} = styleMap[styleType];

  const hasGap = Boolean(
    ressource.refQty &&
      Math.abs(quantity - ressource.refQty) / ressource.refQty > 0.05
  );

  const paddingLeft = `${ressource.rank * 4}px`;

  const getPrice = (res) => {
    let value = null;
    if (shareLink in pricingTable) {
      value = pricingTable[shareLink][res?.id];
    }
    return value;
  };

  // helpers

  const hasPois = ressource?.pois?.length > 0;

  // handlers

  function handleListItemClick(ressource) {
    onRessourceClick(ressource);
  }

  return (
    <ListItemButton
      key={ressource.id}
      sx={{
        fontWeight,
        bgcolor,
        color,
        pl: 1,
        m: 0,
        ...(hasPois && {
          borderRight: (theme) => `2px solid ${theme.palette.primary.flash}`,
        }),
      }}
      selected={selected}
      onClick={() => handleListItemClick(ressource)}
      disableGutters
      divider={!ressource.isTitle}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          position: "relative",
        }}
      >
        {hasGap && (
          <Box
            sx={{
              position: "absolute",
              top: "-2px",
              right: "2px",
              height: "8px",
              width: "8px",
              borderRadius: "4px",
              bgcolor: "primary.flash",
              zIndex: 2,
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: selected ? "center" : "baseline",
            justifyContent: "space-between",
            minWidth: 0,
            paddingLeft,
            pr: 0.5,
            width: 1,
          }}
        >
          <Box sx={{display: "flex", flexDirection: "row"}}>
            <Typography sx={{fontWeight, fontSize: 12}}>
              {ressourceLabel}
            </Typography>
            {getPrice(ressource) && (
              <Typography
                sx={{
                  fontWeight,
                  fontSize: 12,
                  color: "stabilo.orange",
                  pl: 1,
                }}
              >
                {priceIndicator}
              </Typography>
            )}
          </Box>
          <Typography
            sx={{
              fontSize: 12,
              color:
                typeof quantity === "number" && quantity > 0
                  ? !ressource.noPricing
                    ? "secondary.main"
                    : "text.primary"
                  : "text.secondary",
              minWidth: "fit-content",
              pl: "8px",
            }}
            noWrap
            align="right"
          >
            {qtyLabel}
          </Typography>
        </Box>
      </Box>
    </ListItemButton>
  );
}
