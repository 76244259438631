import {nanoid} from "@reduxjs/toolkit";
import theme from "Styles/theme";

const elementTypesDefault = [
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Formes génériques",
    num: "1",
    isTitle: true,
    style: "title1",
  },
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Rectangles",
    num: "1.1",
    isTitle: true,
    style: "title2",
  },
  {
    id: nanoid(),
    name: "Rectangle 10 x 10 cm",
    code: "RECT",
    num: "1.1.1",
    color: theme.palette.primary.main,
    drawingShape: "RECTANGLE",
    group: "Liste générique",
    unit: "UN",
    dim1: 0.1,
    dim2: 0.1,
    description: "Utilisé pour compter des objets",
  },
  {
    id: nanoid(),
    name: "Rectangle 50 x 50 cm",
    code: "RECT",
    num: "1.1.2",
    color: theme.palette.secondary.main,
    drawingShape: "RECTANGLE",
    group: "Liste générique",
    unit: "UN",
    dim1: 0.5,
    dim2: 0.5,
    description: "Utilisé pour compter des objets v2",
  },
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Segments",
    num: "1.2",
    isTitle: true,
    style: "title2",
  },
  {
    id: nanoid(),
    name: "Segment",
    code: "SEGMENT",
    num: "1.2.1",
    color: "#009CE0",
    drawingShape: "SEGMENT",
    group: "Liste générique",
    unit: "ML",
    description: "Utilisé pour les ouvertures : portes, fenêtres,...",
  },
  {
    id: nanoid(),
    name: "Segment 90 x ht.210 cm",
    code: "SEGMENT",
    num: "1.2.2",
    color: "#16A5A5",
    drawingShape: "SEGMENT",
    group: "Liste générique",
    unit: "ML",
    description: "Utilisé pour les portes",
  },
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Polylignes",
    num: "1.3",
    isTitle: true,
    style: "title2",
  },

  {
    id: nanoid(),
    name: "Polyligne",
    code: "POLYLIGNE",
    num: "1.3.1",
    color: theme.palette.primary.main,
    drawingShape: "POLYLINE",
    group: "Liste générique",
    unit: "ML",
    description: "Utilisé pour les murs, cloisons, revêtements muraux,...",
  },
  {
    id: nanoid(),
    name: "Polyligne ép.10 cm",
    code: "POLYLIGNE",
    num: "1.3.1",
    color: "#FCC400",
    drawingShape: "POLYLINE",
    group: "Liste générique",
    unit: "ML",
    dim1: 0.1,
    description: "Utilisé pour les murs de 10cm",
  },
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Polygones",
    num: "1.4",
    isTitle: true,
    style: "title2",
  },
  {
    id: nanoid(),
    name: "Polygone",
    code: "POLYGONE",
    num: "1.4.1",
    color: theme.palette.secondary.main,
    drawingShape: "POLYGON",
    group: "Liste générique",
    unit: "M2",
    description: "Utilisé pour les dalles, plafonds, sols",
  },
  {
    id: nanoid(),
    name: "Polygone",
    code: "POLYGONE",
    num: "1.4.2",
    color: "#7B64FF",
    drawingShape: "POLYGON",
    group: "Liste générique",
    unit: "M3",
    height: 0.2,
    description: "Utilisé pour les dalles de 20cm",
  },
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Formes génériques (avancées)",
    num: "2",
    isTitle: true,
    style: "title1",
  },
  {
    id: nanoid(),
    group: "Liste générique",
    name: "Pentes",
    num: "2.1",
    isTitle: true,
    style: "title2",
  },

  {
    id: nanoid(),
    name: "Pente",
    code: "PENTE",
    num: "2.1.1",
    color: "#7B64FF",
    drawingShape: "SLOPE",
    group: "Liste générique",
    unit: "M2",
  },
];

export default elementTypesDefault;
