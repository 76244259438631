import React from "react";

import {Box, Typography, IconButton, Divider} from "@mui/material";
import {Close} from "@mui/icons-material";
export default function TitleCloseHeader({title, onClose, divider = true}) {
  return (
    <Box
      sx={{
        width: 1,
        height: "43px",
        display: "flex",
        justifyContent: "space-between",
        p: 1,
        pl: 2,
        alignItems: "center",
        ...(divider && {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }),
      }}
    >
      <Typography variant="body2">
        <b>{title}</b>
      </Typography>
      <IconButton size="small" onClick={onClose} color="inherit">
        <Close fontSize="small" />
      </IconButton>
    </Box>
  );
}
