import {useState, useEffect} from "react";
import {nanoid} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import {Save} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import ContainerPosition from "./ContainerPosition";

import {updateWorksite} from "Features/worksites/worksitesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function DialogSaveMarker({
  editor3d,
  scene,
  open,
  onClose,
  marker,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const titleString = "Save the marker";
  const descriptionString = "This marker will be saved as a worksite marker.";
  const latString = "Latitude";
  const lngString = "Longitude";
  const saveString = "Save";

  const latLngString = "Latitude & longitude";
  const positionString = "Position in the 3D scene";

  const updateLatLngString = "Update latitude & longitude from position.";
  const updatePositionString = "Update position from latitude & longitude";

  // state

  const [loading, setLoading] = useState(false);

  const initLat = marker?.lat ? marker.lat.toString() : "";
  const initLng = marker?.lat ? marker.lng.toString() : "";
  const initPos = {x: marker?.x, y: marker?.y, z: marker?.z};
  const [lat, setLat] = useState(initLat);
  const [lng, setLng] = useState(initLng);
  const [position, setPosition] = useState(initPos);

  useEffect(() => {
    setLat(initLat);
    setLng(initLng);
    setPosition(initPos);
  }, [marker?.id]);

  // helpers
  console.log(scene);
  const canSave =
    typeof scene?.worksite?.lat === "number" &&
    !isNaN(scene?.worksite?.lat) &&
    scene?.offset?.position;

  const canUpdate = scene?.lat && scene?.lng;

  // handlers - form

  function handleLatChange(e) {
    let l = e.target.value;
    l = l.replace(",", ".");
    setLat(l);
  }

  function handleLngChange(e) {
    let l = e.target.value;
    l = l.replace(",", ".");
    setLng(l);
  }

  function handlePositionChange(p) {
    setPosition(p);
  }

  // handlers - update

  function handleUpdatePosition() {
    const _lat = parseFloat(lat);
    const _lng = parseFloat(lng);
    const p = editor3d?.mapEditor.azmEditor.latLngToCoord(
      {lat: _lat, lng: _lng},
      scene?.rotation
    );
    setPosition(p);
  }
  function handleUpdateLatLng() {
    const latLng = editor3d?.mapEditor.azmEditor.coordToLatLng(
      position,
      scene.rotation
    );
    setLat(latLng.lat.toString());
    setLng(latLng.lng.toString());
  }

  // handlers - onSave

  async function handleSave() {
    setLoading(true);
    const worksite = scene.worksite;
    const offset = scene.offset.position;
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);
    const {x, y, z} = position;
    const newMarker = {
      id: nanoid(),
      name: marker.description,
      lat: latitude,
      lng: longitude,
      x: x + offset.x,
      y: y + offset.y,
      z: z + offset.z,
      sceneId: scene.id,
      type: "WORKSITE_MARKER",
    };

    let data;
    if (!worksite.data) {
      data = {markers: [newMarker]};
    } else if (!worksite.data.markers) {
      data = {...worksite.data, markers: [newMarker]};
    } else {
      data = {...worksite.data, markers: [...worksite.data.markers, newMarker]};
    }
    const updatedWorksite = {id: worksite.id, data};
    await dispatch(updateWorksite({worksite: updatedWorksite, accessToken}));
    setLoading(false);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleString}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">{descriptionString}</Typography>
        <Typography variant="body2" sx={{mt: 2}}>
          <b>{latLngString}</b>
        </Typography>
        <Box sx={{display: "flex", mt: 1}}>
          <TextField
            label={<Typography variant="body2">{latString}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={lat}
            onChange={handleLatChange}
          />
          <TextField
            sx={{ml: 2}}
            label={<Typography variant="body2">{lngString}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={lng}
            onChange={handleLngChange}
          />
        </Box>
        <Button
          sx={{mt: 1}}
          size="small"
          onClick={handleUpdateLatLng}
          disabled={!canUpdate}
        >
          {updateLatLngString}
        </Button>

        <Typography variant="body2" sx={{mt: 2, mb: 1}}>
          <b>{positionString}</b>
        </Typography>
        <ContainerPosition
          position={position}
          onChange={handlePositionChange}
        />
        <Button
          size="small"
          onClick={handleUpdatePosition}
          disabled={!canUpdate}
          sx={{mt: 1}}
        >
          {updatePositionString}
        </Button>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          disabled={!canSave}
          onClick={handleSave}
          startIcon={<Save />}
          loading={loading}
        >
          {saveString}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
