import {
  MeshBasicMaterial,
  PlaneBufferGeometry,
  DoubleSide,
  Mesh,
  LineLoop,
} from "three";

import {
  imageModelPickedLineMaterial,
  imageModelLineMaterial,
} from "../utils/materials";

import MaskGeometry from "./MaskGeometry";

import theme from "Styles/theme";

export default class ImageEntity {
  objectCam2; // object in 3D split view, without cutting plane.

  constructor({imageTexture, model}) {
    this.type = "IMAGE_ENTITY";
    this.imageTexture = imageTexture;
    this.model = model;
    this.modelId = model.id;

    this.maskGeometry = undefined; // created with the image loader.

    this.pickId = undefined; // used by the editor click handler
    this.selectionId = undefined; // will be the image model id

    this.materialLR = new MeshBasicMaterial({
      color: theme.palette.secondary.main,
      side: DoubleSide,
      transparent: true,
      opacity: 0.8,
    });

    this.materialHR = new MeshBasicMaterial({
      color: theme.palette.secondary.main,
      side: DoubleSide,
      transparent: true,
      opacity: 0.8,
    });

    this.materialRR = new MeshBasicMaterial({
      color: theme.palette.secondary.main,
      side: DoubleSide,
      alphaTest: 0.1,
      //transparent: true,
      //opacity: 0.8,
    });

    this.materialNULL = new MeshBasicMaterial({
      color: 0x284a78,
      side: DoubleSide,
    });
  }

  updateMaterialsFromTexture() {
    this.materialLR =
      this.imageTexture.textureLR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureLR,
        side: DoubleSide,
        transparent: true,
        opacity: 0.8,
      });

    this.materialHR =
      this.imageTexture.textureHR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureHR,
        side: DoubleSide,
        transparent: true,
        opacity: 0.8,
      });

    this.materialRR = new MeshBasicMaterial({
      map: this.imageTexture.textureRR,
      side: DoubleSide,
      alphaTest: 0.1,
      //transparent: true,
      //opacity: 0.8,
    });
  }

  loadImage(resolution) {
    this.createObject(resolution);
    this.updateHelpers();
  }

  createObject(resolution) {
    // geometry

    // const plane = new PlaneBufferGeometry(this.model.width, this.model.height);

    this.maskGeometry = new MaskGeometry({
      width: this.model.width,
      height: this.model.height,
      mask: this.model.mask,
    });
    const plane = this.maskGeometry.geometry;

    // object
    const materialsMap = {
      RR: this.materialRR,
      LR: this.materialLR,
      HR: this.materialHR,
      NULL: this.materialNULL,
    };

    this.object = new Mesh(plane, materialsMap[resolution]);

    // objectCam2 : object used in 3D scene in multiview mode.
    this.objectCam2 = new Mesh(plane);

    if (this.object.material) {
      const material = this.object.material.clone(); // BUG when downloading an image...material doesn't exist...
      material.userData = {doNotClip: true};
      this.objectCam2.material = material;
    }

    // we do not display instantly the image.
    //this.object.layers.enable(1);
    //this.object.layers.enable(3);
    //this.objectCam2.layers.enable(1);

    this.object.add(this.objectCam2);
  }
  /*
   * Resolution
   */

  updateResoMaterials(reso) {
    this.materialRR =
      this.imageTexture.url &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureRR,
        side: DoubleSide,
        alphaTest: 0.05,
        transparent: true,
        opacity: 0.8,
      });
    // !! we changed this.materialRR reference

    this.materialLR =
      this.imageTexture.textureLR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureLR,
        side: DoubleSide,
      });

    this.materialHR =
      this.imageTexture.textureHR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureHR,
        side: DoubleSide,
      });

    this.switchResolutionTo(reso); // we changed this.material(s) references...
  }

  switchResolutionTo(resolution) {
    if (!this.object) {
      return;
    }
    if (resolution === "LR") {
      this.object.material = this.materialLR
        ? this.materialLR
        : this.materialRR;
    }
    if (resolution === "HR") {
      this.object.material = this.materialHR
        ? this.materialHR
        : this.materialRR;
    }
    if (resolution === "RR") {
      this.object.material = this.materialRR;
    }

    this.updateObjectCam2Material();
  }

  updateObjectCam2Material() {
    if (this.object?.material) {
      const material = this.object.material.clone();
      material.userData = {doNotClip: true};
      this.objectCam2.material = material;
    }
  }
  /*
   * Hide & Show
   */

  show() {
    this.hidden = false;
    if (this.object) {
      this.object.layers.enable(1);
      this.object.layers.enable(3);
      this.object.visible = true;
    }

    if (this.objectCam2) {
      this.objectCam2.layers.enable(1);
      this.objectCam2.visible = true;
    }
  }

  hide() {
    this.hidden = true;
    if (this.object) {
      this.object.layers.disable(1);
      this.object.layers.disable(3);
      this.object.visible = false;
    }

    if (this.objectCam2) {
      this.objectCam2.layers.disable(1);
      this.objectCam2.visible = false;
    }
  }

  setOpacity(opacity) {
    if (opacity) {
      if (this.materialRR) this.materialRR.opacity = opacity / 100;
      if (this.materialLR) this.materialLR.opacity = opacity / 100;
      if (this.materialHR) this.materialHR.opacity = opacity / 100;

      if (this.objectCam2) this.objectCam2.material.opacity = opacity / 100;
    }
  }

  updateMask(mask) {
    this.maskGeometry.updateMask(mask);
    this.object.geometry = this.maskGeometry.geometry;
    this.updateHelpers();
  }

  updateHelpers() {
    // clean
    if (this.helper && this.pickHelper) {
      this.object.remove(this.helper, this.pickHelper);
    }
    // geometry
    const geometry = this.object.geometry;
    // helper
    this.helper = new LineLoop(geometry, imageModelLineMaterial);
    //this.helper.layers.enable(1);
    // pick helper
    this.pickHelper = new LineLoop(geometry, imageModelPickedLineMaterial);
    this.pickHelper.scale.set(1.01, 1.01, 1.01);
    this.pickHelper.layers.enable(1);

    // updates
    //this.object.add(this.pickHelper, this.helper); // remove helpers because of blinking effect.
  }

  /*
   * Pick & Select
   */

  pick() {
    this.pickHelper.layers.enable(1);
    this.pickId = this.model.id;
    return this;
  }

  unpick() {
    this.pickHelper.layers.disable(1);
    this.pickId = undefined;
  }

  select() {
    this.selectionId = this.model.id;
    return this;
  }
  unselect() {
    this.selectionId = undefined;
  }

  parse() {
    return {
      type: "IMAGE_ENTITY",
      name: this.model.name,
      modelId: this.model.id,
      model: {
        ...this.model,
        // position: this.position,
        // rotation: this.rotation,
        // width: this.width,
        // height: this.height,
      },
    };
  }
}
