import {useState} from "react";

import {Box, Typography, Paper} from "@mui/material";
import IconElementType from "./IconElementType";
import {ArrowDropDown as Down} from "@mui/icons-material";
import DialogSelectorElementTypesGeneric from "./DialogSelectorElementTypesGeneric";

export default function ContainerElementTypeBasic({
  scene,
  elementType,
  onChange,
}) {
  // state

  const [open, setOpen] = useState(false);

  // helpers

  const clickable = Boolean(onChange);

  // handlers

  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      <DialogSelectorElementTypesGeneric
        scene={scene}
        open={open}
        onClose={() => setOpen(false)}
        selection={elementType}
        onSelectionChange={onChange}
      />
      <Box
        sx={{
          width: 1,
          p: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          ...(clickable && {cursor: "pointer"}),
        }}
        onClick={handleClick}
      >
        <Box sx={{minWidth: 0, display: "flex", alignItems: "center"}}>
          <IconElementType {...elementType} />
          {elementType?.id && (
            <Box sx={{ml: 1}}>
              <Typography variant="body2">{elementType?.name}</Typography>
              <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                {elementType?.group}
              </Typography>
            </Box>
          )}
        </Box>
        {clickable ? <Down color="action" /> : <Box />}
      </Box>
    </>
  );
}
