import {Box, Paper, Typography, Icon, Button, IconButton} from "@mui/material";
import {Add, ArrowBack as Back} from "@mui/icons-material";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";

export default function HeaderEntityPdfs({mode, onCancel, onCreate, onAdd}) {
  // strings

  const title = "Fichiers PDF";
  const newS = "Nouveau fichier";
  const startS = "Ajouter un 1er plan";

  const newLabel = "Nouveau";

  const cancelS = "Annuler";
  const createS = "Créer";

  // data

  const iconMap = useSceneModuleIconMap();

  // helper

  const icon = iconMap["INPUTS"];

  return (
    <Paper
      elevation={0}
      sx={{
        //p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minWidth: 0,
        bgcolor: "unset",
      }}
    >
      {mode === "START" && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "warning.main",
            p: 1,
            borderRadius: "8px",
            color: "common.white",
          }}
        >
          <Typography>{startS}</Typography>
        </Box>
      )}
      {mode === "LIST" && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Box
              sx={{
                bgcolor: "grey.500",
                borderRadius: "4px",
                height: 32,
                width: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "common.white",
                mr: 1,
              }}
            >
              {icon}
            </Box>

            <Typography>{title}</Typography>
          </Box>
          <IconButton
            size="small"
            variant="contained"
            color="primary"
            onClick={onAdd}
          >
            <Add fontSize="small" />
          </IconButton>
        </Box>
      )}
      {(mode === "IMPORT" || mode === "TEMP") && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 1,
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <IconButton onClick={onCancel}>
              <Back />
            </IconButton>
            <Typography noWrap>{newS}</Typography>
          </Box>
          {mode === "TEMP" && (
            <Button variant="contained" color="secondary" onClick={onCreate}>
              {createS}
            </Button>
          )}
        </Box>
      )}
    </Paper>
  );
}
