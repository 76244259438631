import {useSelector} from "react-redux";

export default function useSelectionMulti() {
  // strings

  const measurementsS = "éléments";

  // data

  const measurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  // helpers

  let ms = {
    type: "NONE",
    items: [],
  };

  if (measurementIds?.length > 1) {
    ms.type = "MEASUREMENTS";
    ms.items = measurementIds;
    ms.label = measurementsS;
  }

  // return
  return ms;
}
