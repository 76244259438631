import {useSelector, useDispatch} from "react-redux";
import {setOpenSections} from "Features/ui/uiSlice";

import {Box, Button} from "@mui/material";

export default function ButtonOpenViewers({caplaEditor}) {
  const dispatch = useDispatch();
  const showS = "Ouvrir les visionneuses";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  // handlers

  function handleOpenViewers() {
    const openSections = {...os};
    openSections.outlet = !os.outlet;
    dispatch(setOpenSections(openSections));
    // caplaEditor?.setOpenSections(openSections, selectedMeasurementIds);
  }

  return (
    <Box sx={{px: 0.5}}>
      <Box
        sx={{
          color: "common.white",
          height: "24px",
          borderRadius: "4px",
          bgcolor: "primary.flash",
          color: "common.white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          size="small"
          color="inherit"
          sx={{heigth: "24px"}}
          onClick={handleOpenViewers}
        >
          {showS}
        </Button>
      </Box>
    </Box>
  );
}
