import {Box} from "@mui/material";

import useSelection from "../useSelection";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useIsDrawing from "Features/measurements/useIsDrawing";
import CardIsDrawing from "Features/measurements/components/CardIsDrawing";
import SectionSceneInSelectionPanel from "Features/scenes/components/SectionSceneInSelectionPanel";
import SectionRessourceInSelectionPanel from "Features/ressources/components/SectionRessourceInSelectionPanel";
import SectionElementTypeInSelectionPanel from "Features/elementTypes/components/SectionElementTypeInSelectionPanel";
import SectionMeasurementInSelectionPanel from "Features/measurements/components/SectionMeasurementInSelectionPanel";
import SectionPackageInSelectionPanel from "Features/elementPackages/components/SectionPackageInSelectionPanel";
import SectionMarkersInSelectionPanel from "Features/markers/components/SectionMarkersInSelectionPanel";
import SectionIssueInSelectionPanel from "Features/issues/components/SectionIssueInSelectionPanel";
import SectionPdfInSelectionPanel from "Features/pdf/components/SectionPdfInSelectionPanel";
import SectionZoneInSelectionPanel from "Features/zones/components/SectionZoneInSelectionPanel";
import SectionHomeSelectionInSelectionPanel from "Features/boxhome/components/SectionHomeSelectionInSelectionPanel";
import SectionViewer3DSelectionInSelectionPanel from "Features/navigation/components/SectionViewer3DSelectionInSelectionPanel";
import SectionProgressListingInSelectionPanel from "Features/progress/components/SectionProgressListingInSelectionPanel";
import SectionTempInputInSelectionPanel from "Features/inputs/components/SectionTempInputInSelectionPanel";
import SectionRoomInSelectionPanel from "Features/rooms/components/SectionRoomInSelectionPanel";
import SectionSectorInSelectionPanel from "Features/sectors/components/SectionSectorInSelectionPanel";
import SectionVoidInSelectionPanel from "./SectionVoidInSelectionPanel";
import SectionAnnotatedPdfModelInSelectionPanel from "Features/annotatedPdf/components/SectionAnnotatedPdfModelInSelectionPanel";
import SectionBimappInSelectionPanel from "Features/bimapps/components/SectionBimappInSelectionPanel";
import SectionSharedboxInSelectionPanel from "Features/sharedboxes/components/SectionSharedboxInSelectionPanel";
import SectionMaterialInSelectionPanel from "Features/materials/components/SectionMaterialInSelectionPanel";
import SectionPhaseInSelectionPanel from "Features/phases/components/SectionPhaseInSelectionPanel";
import SectionSurveyInSelectionPanel from "Features/surveys/components/SectionSurveyInSelectionPanel";
import SectionArticleInSelectionPanel from "Features/articles/components/SectionArticleInSelectionPanel";
import SectionArticlesGroupInSelectionPanel from "Features/articles/components/SectionArticlesGroupInSelectionPanel";

export default function SectionSelection({caplaEditor, scene, viewer}) {
  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  const selection = useSelection({scene, caplaEditor, viewer});

  const isDrawing = useIsDrawing();

  return (
    <Box
      sx={{
        width: 1,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      {isDrawing && <CardIsDrawing editorPdf={caplaEditor?.editorPdf} />}
      {!isDrawing && (
        <Box sx={{width: 1, height: 1}}>
          {!selection.type && !viewer && <SectionVoidInSelectionPanel />}
          {selection.type === "SCENE" && viewer && (
            <SectionSceneInSelectionPanel viewer={viewer} scene={scene} />
          )}

          {(pageType === "HOME" || sceneModule === "ADMIN") && (
            <SectionSceneInSelectionPanel scene={scene} viewer={viewer} />
          )}
          {pageType === "SCENE" && sceneModule === "HOME" && (
            <SectionHomeSelectionInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
              viewer={viewer}
            />
          )}
          {sceneModule === "VIEWER_3D" && (
            <SectionViewer3DSelectionInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {((selection?.type === "ISSUE" && sceneModule !== "HOME") ||
            sceneModule === "ISSUES") && (
            <SectionIssueInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {sceneModule === "MARKERS" && <SectionMarkersInSelectionPanel />}
          {sceneModule === "ZONES" && (
            <SectionZoneInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {sceneModule === "SURVEYS" && (
            <SectionSurveyInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {(sceneModule === "EDIT_MEASUREMENTS" ||
            sceneModule === "MEASUREMENTS" ||
            pageType === "COLORING") && (
            <SectionMeasurementInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {sceneModule === "SECTORS" && (
            <SectionSectorInSelectionPanel
              scene={scene}
              caplaEditor={caplaEditor}
            />
          )}
          {sceneModule === "ROOMS" && (
            <SectionRoomInSelectionPanel scene={scene} />
          )}
          {sceneModule === "RESSOURCES" && (
            <SectionRessourceInSelectionPanel
              editorPdf={caplaEditor?.editorPdf}
              scene={scene}
            />
          )}
          {sceneModule === "ARTICLES" && selection?.type === "ARTICLE" && (
            <SectionArticleInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {sceneModule === "ARTICLES" &&
            selection?.type === "ARTICLES_GROUP" && (
              <SectionArticlesGroupInSelectionPanel
                caplaEditor={caplaEditor}
                scene={scene}
              />
            )}
          {sceneModule === "ELEMENT_TYPES" && (
            <SectionElementTypeInSelectionPanel
              scene={scene}
              caplaEditor={caplaEditor}
            />
          )}
          {sceneModule === "PACKAGES" && (
            <SectionPackageInSelectionPanel
              editor3d={caplaEditor?.editor3d}
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}

          {sceneModule === "DATA" && selection?.type === "PDF_MODEL" && (
            <SectionPdfInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {sceneModule === "INPUTS" && selection?.type === "TEMP_INPUT" && (
            <SectionTempInputInSelectionPanel
              editor3d={caplaEditor?.editor3d}
            />
          )}
          {sceneModule === "INPUTS" && selection?.type === "PDF_MODEL" && (
            <SectionPdfInSelectionPanel
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
          {sceneModule === "PROGRESS" && (
            <SectionProgressListingInSelectionPanel scene={scene} />
          )}
          {sceneModule === "ANNOTATED_PDF" && (
            <SectionAnnotatedPdfModelInSelectionPanel
              editorPdf={caplaEditor?.editorPdf}
              scene={scene}
            />
          )}
          {sceneModule === "BIMAPPS" && (
            <SectionBimappInSelectionPanel scene={scene} />
          )}
          {sceneModule === "SHAREDBOXES" && (
            <SectionSharedboxInSelectionPanel scene={scene} />
          )}
          {sceneModule === "MATERIALS" && (
            <SectionMaterialInSelectionPanel scene={scene} />
          )}
          {sceneModule === "PHASES" && (
            <SectionPhaseInSelectionPanel scene={scene} />
          )}
        </Box>
      )}
    </Box>
  );
}
