import getElementTypesByGroupIdById from "Features/elementTypes/utils/getElementTypesByGroupIdById";
import articleTypesMapToArray from "./articleTypesMapToArray";
import stringifyArticleTypesArrayItem from "./stringifyArticleTypesArrayItem";

export default function stringifyArticleTypes(
  articleTypes,
  elementTypesGroups
) {
  const elementTypesByGroupIdById =
    getElementTypesByGroupIdById(elementTypesGroups);

  const articleTypesArray = articleTypesMapToArray(articleTypes);

  const strArray = [];

  articleTypesArray.forEach((item) => {
    const str = stringifyArticleTypesArrayItem(
      item,
      elementTypesGroups,
      elementTypesByGroupIdById
    );

    //  DEBUG
    const type = elementTypesByGroupIdById[item?.groupId]?.[item?.typeId];
    if (type?.name === "Remblais") {
      console.log("item", type);
    }
    //
    if (str) strArray.push(str);
  });

  const result = strArray.join("; ");
  return result;
}
