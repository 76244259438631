import {useState} from "react";

import Fuse from "fuse.js";
import {ListItemButton, List, Box, Typography, TextField} from "@mui/material";

export default function ListWithHeaderAndSearchGeneric({
  title,
  items,
  selectedItemIds,
  onSelectionChange,
}) {
  // state

  const [text, setText] = useState("");

  // filtered items

  let filteredItems = items;
  if (text.length > 0) {
    const fuse = new Fuse(items, {keys: ["name"]});
    const result = fuse.search(text);
    filteredItems = result.map((r) => r.item);
  }

  // handlers

  function handleSelectionChange(id) {
    const s = new Set(selectedItemIds);
    if (s.has(id)) {
      s.delete(id);
    } else {
      s.add(id);
    }
    onSelectionChange(Array.from(s));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{p: 1, width: 1}}>
        <Box sx={{width: 1, p: 1, bgcolor: "background.default"}}>
          <Typography sx={{fontSize: 13}}>{title}</Typography>
        </Box>
      </Box>
      <Box sx={{width: 1, p: 1}}>
        <TextField
          size="small"
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <List dense>
          {filteredItems.map((item) => {
            return (
              <ListItemButton
                divider
                key={item.id}
                selected={selectedItemIds.includes(item.id)}
                onClick={() => handleSelectionChange(item.id)}
              >
                <Typography sx={{fontSize: 13}}>{item.name}</Typography>
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
