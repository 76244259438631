import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import FormZone from "./FormZone";
import SectionHelperDrawZone from "./SectionHelperDrawZone";
import CardImageModelPreview from "./CardImageModelPreview";

import useSelectedZone from "../hooks/useSelectedZone";
import useIsCreatingZone from "../hooks/useIsCreatingZone";

export default function SectionZoneInSelectionPanel({caplaEditor, scene}) {
  // strings

  // data

  const selectedZone = useSelectedZone(scene);

  const isEditingZone = useSelector((s) => s.zones.isEditingZone);
  const editedZone = useSelector((s) => s.zones.editedZone);

  const isCreatingZone = useIsCreatingZone();

  const groupCode = useSelector((s) => s.zones.selectedFormGroupCode);

  // helper

  const zone = isEditingZone ? editedZone : selectedZone;
  console.log("zoneAAA", zone);

  return (
    <Box>
      {zone && !isCreatingZone && (
        <FormZone caplaEditor={caplaEditor} scene={scene} zone={zone} />
      )}
      {isCreatingZone && <SectionHelperDrawZone />}
      {groupCode === "IMAGE" && (
        <CardImageModelPreview previewUrl={zone?.imageModelPreviewUrl} />
      )}
    </Box>
  );
}
