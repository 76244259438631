import {Box, Typography} from "@mui/material";
import {PictureAsPdf as PdfIcon} from "@mui/icons-material";

export default function CardPdfName({model}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: 1,
        borderRadius: "4px",
        bgcolor: "background.default",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "grey.600",
          mr: 2,
        }}
      >
        <PdfIcon color="inherit" />
      </Box>
      <Typography variant="body2" color="text.secondary">
        {model?.name}
      </Typography>
    </Box>
  );
}
