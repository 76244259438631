import {useSelector} from "react-redux";

import HeaderConfigCenter from "./HeaderConfigCenter";
// import HeaderMainScene from "./HeaderMainScene";

import HeaderSceneStory from "Features/scenes/components/HeaderSceneStory";

export default function Header({editor3d, config = false, story = false}) {
  //const configuredEntity = editor3d.configCenter.entity;
  const {model: configModel} = useSelector(
    (state) => state.viewer3D.configuredModel
  );
  const models = useSelector((state) => state.viewer3D.models);
  const model = models.find((m) => m.id === configModel?.id);

  if (story) {
    return <HeaderSceneStory />;
  } else if (!config) {
    return <></>;
  } else {
    //const model = configuredEntity?.model;
    return <HeaderConfigCenter model={model} editor3d={editor3d} />;
  }
}
