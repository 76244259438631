import {useSelector, useDispatch} from "react-redux";
import {setLinkedPdfsOnly} from "../boxhomeSlice";

import {FormControlLabel, Typography, Switch, Box} from "@mui/material";

export default function SwitchLinkedPdfsOnly() {
  const dispatch = useDispatch();

  // string

  const label = "Plans liés à la bibliothèque uniquement";

  // data

  const checked = useSelector((s) => s.boxhome.linkedPdfsOnly);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setLinkedPdfsOnly(checked));
  }

  return (
    <Box sx={{pl: 2, py: 0.5}}>
      <FormControlLabel
        control={
          <Switch size="small" value={checked} onChange={handleChange} />
        }
        label={
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {label}
          </Typography>
        }
      />
    </Box>
  );
}
