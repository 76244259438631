/*
 * allItemIdsWithCount : [{id,count}]. eg: types of scoped measurements.
 * showedItemIds: [id]. active items.
 * onShowedItemIdsChange: [id]. Array of active items.
 * expandGroupIds: [groupId].
 * sortedCategories : [{id,groupId,groupName,style,color,unit,qty}]
 *
 * - mode (ACTIVE, INACTIVE, UNDEFINED) for groups are computed from the items and the nomenclatures
 */
import {useRef, useState} from "react";
import {Box, IconButton} from "@mui/material";
import {ArrowDropDown as Down, ArrowDropUp as Up} from "@mui/icons-material";
import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";
import ItemGroup from "./components/ItemGroup";
import ItemGeneric from "./components/ItemGeneric";
import Header from "./components/Header";

import ButtonCaptureRefAsImage from "Features/images/components/ButtonCaptureRefAsImage";
import SectionSettings from "./components/SectionSettings";

export default function TableGroupedItems({
  headerTitle,
  allSortedItems,
  scopedItemIdsWithCount: scopedIds,
  showedItemIds = [],
  onShowedItemIdsChange,
  expandGroupIds = [],
  onExpandGroupIdsChange,
  options = {},
  variant,
  editorPdf,
}) {
  const componentRef = useRef();

  // options

  const {
    disableHeader,
    disableExpandGroups,
    disableQties,
    disableNums,
    disableDescriptions,
    disableGroupedShowModes,
  } = options;

  // state

  const [openSettings, setOpenSettings] = useState(false);
  const [isPdfLayout, setIsPdfLayout] = useState(false);
  const [positionInPdf, setPositionInPdf] = useState({x: 50, y: 50, dim: 240});
  const [showQties, setShowQties] = useState(true);

  // helper scopedItemIdsWithCount

  const scopedItemIdsWithCount = isPdfLayout
    ? scopedIds.filter((scopedIds) => showedItemIds.includes(scopedIds.id))
    : scopedIds;
  // helper

  const expandTable = expandGroupIds?.length > 0;

  // helper - header title

  const totalCount = Array.isArray(scopedItemIdsWithCount)
    ? scopedItemIdsWithCount.reduce((ac, cur) => ac + cur.count, 0)
    : 0;
  const title = `${totalCount} ${headerTitle}`;

  // helper - header showMode

  let headerShowMode = "OFF";
  if (showedItemIds?.length === scopedItemIdsWithCount?.length)
    headerShowMode = "ON";
  if (
    showedItemIds?.length > 0 &&
    showedItemIds?.length !== scopedItemIdsWithCount?.length
  )
    headerShowMode = "UNDEFINED";

  // itemIdGroupIdMap
  const itemIdGroupIdMap = {};
  allSortedItems.forEach((item) => {
    itemIdGroupIdMap[item.id] = item.groupId;
  });

  // scopedGroupIds
  const scopedItemIds = scopedItemIdsWithCount.map((item) => item.id);
  const scopedGroupIds = [
    ...new Set(
      allSortedItems
        .filter((item) => scopedItemIds?.includes(item.id))
        .map((item) => item.groupId)
    ),
  ];

  // helper - scopedItemsWithParentsAndCount

  let scopedItemsWithGroupsAndParentsAndCount = [];

  const scopedItemIdsWithCountAndGroupId = scopedItemIdsWithCount.map(
    (item) => ({...item, groupId: itemIdGroupIdMap[item.id]})
  );
  scopedGroupIds.forEach((groupId) => {
    const nomenclature = allSortedItems.filter(
      (item) => item.groupId === groupId
    );
    const items = scopedItemIdsWithCountAndGroupId.filter(
      (item) => item.groupId === groupId
    );
    const groupName = nomenclature[0].groupName;

    const expand = expandGroupIds?.includes(groupId);

    scopedItemsWithGroupsAndParentsAndCount.push({
      type: "GROUP",
      groupId,
      name: groupName,
      expand,
    });

    if (disableExpandGroups || expand) {
      scopedItemsWithGroupsAndParentsAndCount.push(
        ...getFilteredNomenclatureWithItems(nomenclature, items)
      );
    }
  });

  // helpers - showedItems with groupId
  const showedItemsIdsWithGroupId = showedItemIds.map((id) => ({
    id,
    groupId: itemIdGroupIdMap[id],
  }));

  // helper - items by group

  const groupScopedAndShowedItemsIdsMap = {};
  scopedGroupIds.forEach((groupId) => {
    groupScopedAndShowedItemsIdsMap[groupId] = {
      scopedIds: scopedItemIdsWithCountAndGroupId
        .filter((item) => item.groupId === groupId && item.type !== "GROUP")
        .map(({id}) => id),
      showedIds: showedItemsIdsWithGroupId
        .filter((item) => item.groupId === groupId)
        .map(({id}) => id),
    };
  });

  // handler - header

  function handleExpandHeaderChange(expand) {
    if (expand) {
      onExpandGroupIdsChange(scopedGroupIds);
    } else {
      onExpandGroupIdsChange([]);
    }
  }

  function handleHeaderShowModeChange(showMode) {
    let newShowedItemsIds;
    if (showMode === "OFF") newShowedItemsIds = [];
    if (showMode === "ON") newShowedItemsIds = [...scopedItemIds];
    onShowedItemIdsChange(newShowedItemsIds);
  }

  // handler group

  function handleGroupExpandChange(groupId, expand) {
    let newGroupIds;
    if (!expand) {
      newGroupIds = expandGroupIds.filter((id) => id !== groupId);
    } else {
      newGroupIds = [...expandGroupIds, groupId];
    }
    onExpandGroupIdsChange(newGroupIds);
  }

  function handleGroupShowModeChange(groupId, showMode) {
    let newShowedItemIds;
    const scopedIds = groupScopedAndShowedItemsIdsMap[groupId]?.scopedIds;
    if (showMode === "OFF") {
      newShowedItemIds = showedItemsIdsWithGroupId
        .filter((item) => item.groupId !== groupId)
        .map(({id}) => id);
    } else if (showMode === "ON") {
      newShowedItemIds = [...new Set([...showedItemIds, ...scopedIds])];
    }

    onShowedItemIdsChange(newShowedItemIds);
  }

  // handlers - item

  function handleItemShowChange(itemId) {
    let newShowedItemIds = [...showedItemIds];
    if (showedItemIds?.includes(itemId)) {
      newShowedItemIds = newShowedItemIds.filter((id) => id !== itemId);
    } else {
      newShowedItemIds.push(itemId);
    }
    onShowedItemIdsChange(newShowedItemIds);
  }

  // handlers - settings

  function handleIsPdfLayoutChange(isPL) {
    setIsPdfLayout(isPL);
  }
  function handlePositionInPdfChange(position) {
    setPositionInPdf(position);
  }
  function handleShowQtiesChange(show) {
    setShowQties(show);
  }

  return (
    <Box sx={{width: 1}}>
      {!disableHeader && (
        <Box sx={{width: 1, mb: 1}}>
          <Header
            title={title}
            showMode={headerShowMode}
            onShowModeChange={handleHeaderShowModeChange}
            expand={expandTable}
            onExpandChange={handleExpandHeaderChange}
            disableShowModeButton={disableGroupedShowModes}
            disableExpand={disableExpandGroups}
          />
        </Box>
      )}
      <Box
        ref={componentRef}
        sx={{
          ...(isPdfLayout && {
            p: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }),
        }}
      >
        {scopedItemsWithGroupsAndParentsAndCount.map((item) => {
          if (item.type === "GROUP") {
            // helper - compute showMode for one group.
            let showMode;
            const scopedAndShowedIds =
              groupScopedAndShowedItemsIdsMap[item.groupId];
            const delta =
              scopedAndShowedIds.scopedIds?.length -
              scopedAndShowedIds.showedIds?.length;
            if (delta === 0) {
              showMode = "ON";
            } else if (scopedAndShowedIds.showedIds?.length === 0) {
              showMode = "OFF";
            } else if (delta > 0) {
              showMode = "UNDEFINED";
            }
            return (
              <ItemGroup
                isPdfLayout={isPdfLayout}
                key={item.groupId}
                groupId={item.groupId}
                name={item.name}
                expand={
                  disableExpandGroups || expandGroupIds?.includes(item.groupId)
                }
                onExpandChange={(expand) =>
                  handleGroupExpandChange(item.groupId, expand)
                }
                showMode={showMode}
                onShowModeChange={(showMode) =>
                  handleGroupShowModeChange(item.groupId, showMode)
                }
                disableExpand={disableExpandGroups}
                disableNum={disableNums}
                disableShowModeButton={disableGroupedShowModes}
                hideShowButton={disableGroupedShowModes}
                variant={variant}
              />
            );
          } else {
            return (
              <ItemGeneric
                variant={variant}
                key={item.id}
                itemId={item.id}
                num={item.num}
                name={item.name}
                color={item.color}
                isTitle={item.isTitle}
                style={item.style}
                qty={item.qty}
                count={item.count}
                unit={item.unit}
                show={showedItemIds?.includes(item.id)}
                onShowChange={() => handleItemShowChange(item.id)}
                disableQty={disableQties}
                disableNum={disableNums}
                description={item.description}
                showDescription={!disableDescriptions && item.unit}
                hideShowButton={disableGroupedShowModes}
                isPdfLayout={isPdfLayout}
                showQties={showQties}
              />
            );
          }
        })}
      </Box>
      {editorPdf && (
        <Box
          sx={{
            bgcolor: "common.white",
            flexDirection: "column",
            display:
              scopedItemsWithGroupsAndParentsAndCount.length > 0 || isPdfLayout
                ? "flex"
                : "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: 1,
            }}
          >
            <ButtonCaptureRefAsImage
              componentRef={componentRef}
              editorPdf={editorPdf}
              positionInPdf={positionInPdf}
            />
            <IconButton
              size="small"
              onClick={() => setOpenSettings(!openSettings)}
            >
              {openSettings ? (
                <Up fontSize="small" />
              ) : (
                <Down fontSize="small" />
              )}
            </IconButton>
          </Box>
          {openSettings && (
            <SectionSettings
              positionInPdf={positionInPdf}
              onPositionInPdfChange={handlePositionInPdfChange}
              isPdfLayout={isPdfLayout}
              onIsPdfLayoutChange={handleIsPdfLayoutChange}
              showQties={showQties}
              onShowQtiesChange={handleShowQtiesChange}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
