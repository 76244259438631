import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtVersion from "Features/kvform/hooks/useKvtVersion";
import {Box} from "@mui/material";

export default function FormVersion({
  version,
  onChange,
  hiddenFields = [],
  canEdit,
}) {
  // template

  const template = useKvtVersion();

  return version ? (
    <Box
      sx={{
        width: 400,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <KeyValueForm
        item={version}
        template={template}
        onChange={onChange}
        hiddenFields={hiddenFields}
        sizeKey={3}
        canEdit={canEdit}
      />
    </Box>
  ) : (
    <div />
  );
}
