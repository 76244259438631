import {setUrlLoadedInInstance2} from "../pdfSlice";

import AnnotationsManager from "./AnnotationsManager";

export default class PdfEditorInstance2 {
  constructor({webViewer, caplaEditor}) {
    this.webViewer = webViewer;
    this.caplaEditor = caplaEditor;

    this.url = null;

    this.removeButtons();
    this.addEventListeners();

    this.annotationsManager = new AnnotationsManager({
      webViewer: this.webViewer,
      caplaEditor,
    });
  }

  load({pdfModel, url}) {
    this.url = url;
    this.webViewer.UI.loadDocument(url, {extension: "pdf"});
  }
  removeButtons() {
    this.webViewer.UI.disableElements([
      "header",
      "toolsHeader",
      "annotationPopup",
      "measurementOverlay",
      "annotationOverlay",
      "scaleOverlayContainer",
      "toolsOverlay",
    ]);
  }

  addEventListeners = () => {
    const {documentViewer} = this.webViewer.Core;
    documentViewer.addEventListener("documentLoaded", () => {
      console.log("documentLoaded !");
      this.caplaEditor?.dispatch(setUrlLoadedInInstance2(this.url));
    });
  };

  async getPdfImageAsync({pageNumber, x, y, width, height, zoom}) {
    try {
      console.log(
        "[getPdfImageAsync] pageNumber,x,y",
        pageNumber,
        x,
        y,
        width,
        height,
        zoom
      );
      return new Promise(async (resolve) => {
        const docViewer = this.webViewer.Core.documentViewer;
        //
        const pageHeight = docViewer.getPageHeight(pageNumber);
        const pageWidth = docViewer.getPageWidth(pageNumber);
        const rotation = docViewer.getCompleteRotation(pageNumber);
        //
        let x1 = x;
        let y1 = y;
        let x2 = x1 + width;
        let y2 = y1 + height;

        let renderRect = {x1, y1, x2, y2};
        if (rotation === 1) {
          renderRect = {
            x1: pageHeight - renderRect.y2,
            y1: renderRect.x1,
            x2: pageHeight - renderRect.y1,
            y2: renderRect.x2,
          };
        } else if (rotation === 3) {
          renderRect = {
            x2: renderRect.y2,
            y2: pageWidth - renderRect.x1,
            x1: renderRect.y1,
            y1: pageWidth - renderRect.x2,
          };
        }

        console.log("renderRect", renderRect);
        const loadCanvasOptions = {
          pageNumber,
          renderRect,
          zoom,
          drawComplete: async (canvas) => {
            let _url = canvas.toDataURL();
            const imageData = canvas
              .getContext("2d")
              .getImageData(0, 0, canvas.width, canvas.height);
            return resolve({
              url: _url,
              imageData,
              width,
              height,
              x,
              y,
              pageNumber,
            });
          },
        };
        docViewer.getDocument().loadCanvas(loadCanvasOptions);
      });
    } catch (e) {
      console.log("error", e);
    }
  }
}
