import {useState} from "react";
import {useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";

import {Box, Typography, Skeleton, IconButton} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import ContainerSceneImageThumbnail from "Features/scenes/components/ContainerSceneImageThumbnail";

import ContainerLoadingProgressModels from "./ContainerLoadingProgressModels";
import ContainerLoadingProgressModelsHeader from "./ContainerLoadingProgressModelsHeader";

export default function SectionLoadingScene({scene}) {
  const {sceneId} = useParams();
  const navigate = useNavigate();
  const sceneOverview = useSelector((s) => s.overviewer.sceneOverview);
  const sceneIdOfSceneOverview = useSelector(
    (s) => s.overviewer.sceneIdOfSceneOverview
  );

  console.log("debugafa sceneOverview", sceneOverview);

  // state

  const [expand, setExpand] = useState(false);

  // helpers

  const loadingSceneOverview = sceneIdOfSceneOverview !== sceneId;

  // handlers

  function handleExpand(exp) {
    setExpand(exp);
  }

  function handleBackClick() {
    navigate("/");
  }

  return (
    <Box>
      <Box
        sx={{
          height: 60,
          display: "flex",
          alignItems: "center",
          position: "relative",
          bgcolor: "common.caplaBlack",
          color: "common.white",
        }}
      >
        <Box sx={{color: "common.white", px: 1}}>
          <IconButton color="inherit" onClick={handleBackClick}>
            <Back />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: 100,
              height: 50,
              bgcolor: "common.caplaBlack",
              //bgcolor: (theme) => lighten(theme.palette.common.caplaBlack, 0.1),
            }}
          >
            {!loadingSceneOverview ? (
              <ContainerSceneImageThumbnail
                imageUrl={sceneOverview?.imageUrl}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width={100}
                height={50}
                sx={{mr: 2, bgcolor: "grey.800"}}
              />
            )}
          </Box>
          {!loadingSceneOverview ? (
            <Box sx={{px: 2}}>
              <Typography sx={{fontWeight: "bold"}}>
                {sceneOverview?.title}
              </Typography>

              <Typography variant="body2">
                {sceneOverview?.description ?? "-"}
              </Typography>
            </Box>
          ) : (
            <Skeleton
              variant="rectangular"
              width={300}
              height={50}
              sx={{ml: 2, bgcolor: "grey.800"}}
            />
          )}
          {!loadingSceneOverview && (
            <Box
              sx={{
                visible: loadingSceneOverview ? "hidden" : "visible",
                width: 300,
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                right: "56px",
                top: "8px",
                zIndex: 50,
              }}
            >
              {/* <ContainerLoadingProgress /> */}
              <ContainerLoadingProgressModelsHeader
                type="BIMBOX"
                scene={scene}
                expand={expand}
                onExpandChange={handleExpand}
              />
              <Box
                sx={{
                  display: expand ? "flex" : "none",
                  flexDirection: "column",
                }}
              >
                <ContainerLoadingProgressModels
                  type="RESSOURCES"
                  scene={scene}
                />
                <ContainerLoadingProgressModels type="ELEMENT_TYPE_GROUPS" />
                <ContainerLoadingProgressModels type="PDF" />
                <ContainerLoadingProgressModels type="IMAGE" />
                <ContainerLoadingProgressModels type="MEASUREMENTS" />
                <ContainerLoadingProgressModels type="MARKERS" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
