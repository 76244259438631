import {useState} from "react";
import {nanoid} from "@reduxjs/toolkit";
import {useSelector, useDispatch} from "react-redux";

import {Box, Paper, CircularProgress, Button, Typography} from "@mui/material";
import {Save} from "@mui/icons-material";


import {updateModel, updateRemoteModel} from "Features/viewer3D/viewer3DSlice";
import {uploadFileService} from "Features/files/services";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
import useAccessToken from "Features/auth/useAccessToken";

export default function FormAddModelPov({
  editor3d,
  modelId,
  screenshot,
  onCreate,
  scene,
}) {
  const dispatch = useDispatch();
  const {t} = useTranslation(["scenes", "common"]);
  const accessToken = useAccessToken();

  // string

  const saveString = capitalizeStart(t("common:save"));
  const saveScreenshotString = "Save model's point of view";

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const model = models.find((m) => m.id === modelId);

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const sceneId = scene.id;

  // handlers

  function handleClick(e) {
    e.stopPropagation();
  }

  async function handleCreateClick() {
    if (!loading) {
      if (accessToken && modelId) {
        setLoading(true);

        // post file

        const url = editor3d?.annotator.stage.toDataURL();
        const fileName = `note-image-${nanoid()}.png`;
        const response = await fetch(url);
        const blob = await response.blob();
        let file;
        let remoteUrl;
        file = new File([blob], fileName, {type: "image/png"});

        if (file) {
          remoteUrl = await uploadFileService({
            sceneId,
            accessToken,
            file,
            container: "listing-files",
          });
        }

        // update model
        const newScreenshot = {...screenshot, image: remoteUrl};
        const updatedModel = {...model, screenshot: newScreenshot};
        await dispatch(updateModel({updatedModel, sync: false}));
        await dispatch(updateRemoteModel({modelId, accessToken}));
        setLoading(false);
      }
      onCreate();
    }
  }
  return (
    <Paper
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider} `,
        bgcolor: "common.caplaBlack",
        p: 1,
        color: "common.white",
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" noWrap sx={{mr: 2}}>
          {saveScreenshotString}
        </Typography>
        <Button
          sx={{m: 1, whiteSpace: "nowrap"}}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : <Save />
          }
          size="small"
          variant="contained"
          onClick={handleCreateClick}
        >
          {saveString}
        </Button>
      </Box>
    </Paper>
  );
}
