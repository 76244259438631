import {useSelector} from "react-redux";

import useSceneModule from "Features/navigation/useSceneModule";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import getItemsMapById from "Utils/getItemsMapById";

export default function useFilterIdsByEntityFromSelection(scene) {
  const sceneModule = useSceneModule();

  // helper - Poi // used to disable selection if POI is clicked.

  const selectedPoiId = useSelector((s) => s.pois.selectedPoiId);

  // helper - ELEMENT_TYPE;

  const selectedTypeId = useSelector(
    (s) => s.elementTypes.selectedElementTypeId
  );
  const selectedTypeGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const selectedTypeIdInMeasurements = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );
  const selectedTypeGroupProxyInMeasurements = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );

  // helper - RESSOUCES

  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );
  const selectedRessourcesGroupProxy = useSelector(
    (s) => s.ressources.selectedRessourcesGroupProxy
  );

  // helper - ARTICLES

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);
  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );

  // helper - SECTORS

  const selectedSectorId = useSelector((s) => s.sectors.selectedSectorId);

  // compute

  const idsMap = {};

  if (!selectedPoiId) {
    if (sceneModule === "ELEMENT_TYPES") {
      if (selectedTypeId) idsMap.elementType = [selectedTypeId];
      if (selectedTypeGroupProxy)
        idsMap.elementTypeGroup = [selectedTypeGroupProxy.id];
    } else if (sceneModule === "RESSOURCES") {
      if (selectedRessourceId) idsMap.ressource = [selectedRessourceId];
      if (selectedRessourcesGroupProxy)
        idsMap.ressourcesGroup = [selectedRessourcesGroupProxy.id];
    } else if (sceneModule === "SECTORS") {
      //
    } else if (sceneModule === "ARTICLES") {
      if (selectedArticlesGroupId)
        idsMap.articlesGroup = [selectedArticlesGroupId];
      if (selectedArticleId) idsMap.article = [selectedArticleId];
    } else if (sceneModule === "MEASUREMENTS") {
      if (selectedTypeIdInMeasurements)
        idsMap.elementType = [selectedTypeIdInMeasurements];
      if (selectedTypeGroupProxyInMeasurements) {
        //idsMap.elementTypeGroup = [selectedTypeGroupProxyInMeasurements.id];
      }
    }
  }

  return idsMap;
}
