import {useSelector} from "react-redux";

import {Box, Divider} from "@mui/material";

import HeaderDetailModel from "./HeaderDetailModel";
import FormLoadingSettings from "./FormLoadingSettings";
import CardModelSettingsMisc from "./CardModelSettingsMisc";
import CardModelDisplayOptions from "./CardModelDisplayOptions";
import CardImageResolution from "./CardImageResolution";
import CardIfcSite from "./CardIfcSite";
import ButtonOpenPdf from "./ButtonOpenPdf";
import CardDownloadFile from "./CardDownloadFile";
import CardModelKeywords from "./CardModelKeywords";
import ButtonUploadModelData from "./ButtonUploadModelData";
//import ButtonExportGLTF from "./ButtonExportGLTF";
import CardGLTF from "./CardGLTF";
import SwitchImageTransparent from "./SwitchImageTransparent";
import ButtonLoadElements from "./ButtonLoadElements";

export default function SectionDetailModel({model, editor3d, scene, viewer}) {
  const isIFC = model?.type === "IFC";
  const isIMAGE = model?.type === "IMAGE";
  const isPDF = model?.type === "PDF";
  const isCAPLA = model?.type === "CAPLA";

  // data

  const detailedModels = useSelector((state) => state.viewer3D.detailedModels);
  const detailed = detailedModels.includes(model.id);

  // handlers

  // function handleModelDetailClick() {
  //   const prefix = process.env.REACT_APP_URL_PREFIX;
  //   const url = `${prefix}/bimbox/${scene.clientId}/models/${model.id}`;
  //   console.log("url", url);
  //   window.open(url, "_blank");
  // }

  return (
    <Box sx={{height: 1, overflow: "auto"}}>
      <HeaderDetailModel model={model} editor3d={editor3d} scene={scene} />

      <Box sx={{mt: 1, p: 1}}>
        <CardDownloadFile model={model} />
      </Box>

      <Box sx={{width: 1, mt: 1, position: "relative"}}>
        <Box sx={{mt: 1}}>
          <CardModelKeywords model={model} viewer={viewer} />
          {isIFC && detailed && <CardIfcSite model={model} editor3d={editor3d} />}
          {isIFC && detailed && (
            <CardModelDisplayOptions model={model} editor3d={editor3d} />
          )}
          {isIFC && !viewer && <FormLoadingSettings model={model} />}
          {isIFC && detailed && (
            <CardModelSettingsMisc editor3d={editor3d} model={model} />
          )}

          {isIMAGE && (
            <Box sx={{px: 2}}>
              <SwitchImageTransparent editor3d={editor3d} model={model} />
            </Box>
          )}
          {isIMAGE && <CardImageResolution editor3d={editor3d} model={model} />}
          {isPDF && <ButtonOpenPdf />}
          {!viewer && (
            <Box
              sx={{display: "flex", justifyContent: "center", width: 1, mt: 2}}
            >
              <ButtonUploadModelData model={model} scene={scene} />
            </Box>
          )}
          <Divider sx={{my: 2}} />
          {isIFC && !viewer && (
            <Box
              sx={{display: "flex", justifyContent: "center", width: 1, px: 2}}
            >
              {/* <ButtonExportGLTF model={model} editor3d={editor3d} /> */}
              <CardGLTF model={model} editor3d={editor3d} />
            </Box>
          )}
          {isCAPLA && !viewer && (
            <ButtonLoadElements model={model} editor3d={editor3d} />
          )}
        </Box>
        {/* {isIFC && (
          <Button sx={{mt: 1}} size="small" onClick={handleModelDetailClick}>
            Detail
          </Button>
        )} */}
      </Box>
    </Box>
  );
}
