import {useDispatch} from "react-redux";

import {Button} from "@mui/material";

import {setShowMeasurements} from "../measurementsSlice";

export default function ButtonHideMeasurementsInPdf({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const hideS = "Hide all elements";

  // handlers

  function handleClick() {
    const mnger = editorPdf?.annotationsManager.measurementsPdfManager;
    mnger?.hideAllMeasurementAnnotations();
    dispatch(setShowMeasurements(false));
  }

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      size="small"
    >
      {hideS}
    </Button>
  );
}
