import React from "react";

import {Box} from "@mui/material";

import SelectorProgressListing from "./SelectorProgressListing";
import ButtonDialogCreateProgressListing from "./ButtonDialogCreateProgressListing";

export default function SectionProgressSelector({scene}) {
  return (
    <Box sx={{width: 1, px: 2, display: "flex", pt: 2}}>
      <Box sx={{width: 250, mr: 2}}>
        <SelectorProgressListing scene={scene} />
      </Box>
      <ButtonDialogCreateProgressListing scene={scene} />
    </Box>
  );
}
