import Konva from "konva";
import theme from "Styles/theme";
import {nanoid} from "@reduxjs/toolkit";

export default class Annotator {
  stage;
  layer;
  width;
  height;

  strokeWidth;
  color;

  painting;
  writting;
  onWritting;
  onTextPosition;
  mode;

  lastAnnotation;
  lastTextAnnotation;
  lastTextarea;
  annotations;
  selection;

  constructor({
    container,
    width,
    height,
    onWritting,
    onTextPosition,
    onModeChange,
  }) {
    this.stage = new Konva.Stage({
      container, // container string  ("container")
      width,
      height,
    });

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.width = width;
    this.height = height;

    this.mode = "FREE";
    this.painting = false;
    this.writting = false;

    this.onWritting = onWritting;
    this.onTextPosition = onTextPosition;
    this.onModeChange = onModeChange;

    this.color = theme.palette.primary.main;
    this.strokeWidth = 4;

    this.annotations = [];

    this.addListeners();
  }

  addImage(url) {
    const imageObj = new Image();
    imageObj.onload = () => {
      const image = new Konva.Image({
        image: imageObj,
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
      });
      this.layer.add(image);
      this.layer.draw();
    };
    imageObj.src = url;
  }

  draw() {
    this.layer.draw();
  }

  clear() {
    this.stage.clear();
    this.stage.clearCache();
    if (this.lastTextarea) document.body.removeChild(this.lastTextarea);
  }

  setMode(mode) {
    this.mode = mode;
  }

  setColor(color) {
    this.color = color;
  }

  undo() {
    if (this.annotations.length > 0) {
      const annotation = this.annotations.pop();
      annotation?.remove();
      this.layer.draw();
    }
  }

  addListeners() {
    this.stage.on("mousedown touchstart", (e) => {
      var pos = this.stage.getPointerPosition();

      let annotation;

      if (this.writting) {
        console.log("save writting");
        const textareaWidth = this.lastTextarea.getBoundingClientRect().width;
        const textareaHeight = this.lastTextarea.getBoundingClientRect().height;
        this.lastTextAnnotation.width(textareaWidth);
        this.lastTextAnnotation.height(textareaHeight);
        this.lastTextAnnotation.text(this.lastTextarea.value);

        document.body.removeChild(this.lastTextarea);
        this.painting = false;
        this.writting = false;
        this.mode = null;
        this.onModeChange(null);

        this.lastTextarea = null;
      }

      if (this.mode === "FREE") {
        this.painting = true;
        annotation = new Konva.Line({
          stroke: this.color,
          strokeWidth: this.strokeWidth,
          globalCompositeOperation:
            this.mode === "FREE" ? "source-over" : "destination-out",
          // round cap for smoother lines
          lineCap: "round",
          // add point twice, so we have some drawings even on a simple click
          points: [pos.x, pos.y, pos.x, pos.y],
        });
      } else if (this.mode === "ARROW") {
        this.painting = true;
        annotation = new Konva.Arrow({
          x: pos.x,
          y: pos.y,
          points: [0, 0, 0, 0],
          pointerLength: 14,
          pointerWidth: 14,
          fill: this.color,
          stroke: this.color,
          strokeWidth: this.strokeWidth,
          draggable: true,
        });
      } else if (this.mode === "TEXT" && !this.writting) {
        this.painting = true;
        this.writting = true;
        this.onWritting(true);
        this.onTextPosition(pos);
        annotation = new Konva.Text({
          text: "test",
          x: pos.x,
          y: pos.y,
          fontSize: 20,
          fontStyle: "bold",
          fontFamily: theme.typography.body2.fontFamily,
          fill: this.color,
          draggable: true,
        });

        this.lastTextAnnotation = annotation;

        const stageBox = this.stage.container().getBoundingClientRect();
        const areaPosition = {
          x: stageBox.left + pos.x,
          y: stageBox.top + pos.y,
        };
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);

        textarea.value = "";
        textarea.style.position = "absolute";
        textarea.style.top = areaPosition.y + "px";
        textarea.style.left = areaPosition.x + "px";
        textarea.style.width = "300px";
        textarea.style.height = "90px";
        textarea.style.zIndex = "100";
        textarea.style.fontFamily = theme.typography.body2.fontFamily;
        textarea.style.fontSize = "20px";
        textarea.style.fontWeight = "bold";
        textarea.style.color = this.color;

        console.log(
          "textarea",
          textarea,
          textarea.style.top,
          textarea.style.left
        );

        setTimeout(() => textarea.focus(), 20);

        annotation.on("dblclick", () => {
          if (!this.writting) {
            this.writting = true;
            this.lastTextAnnotation = annotation;
            const pos = annotation.getAbsolutePosition();
            const stageBox = this.stage.container().getBoundingClientRect();
            const areaPosition = {
              x: stageBox.left + pos.x,
              y: stageBox.top + pos.y,
            };
            const textarea = document.createElement("textarea");
            document.body.appendChild(textarea);

            textarea.value = annotation.text();
            textarea.style.position = "absolute";
            textarea.style.top = areaPosition.y + "px";
            textarea.style.left = areaPosition.x + "px";
            textarea.style.fontWeight = "bold";
            textarea.style.width = annotation.width() + "px";
            textarea.style.height = annotation.height() + "px";
            textarea.style.zIndex = "70";
            textarea.style.fontFamily = theme.typography.body2.fontFamily;
            textarea.style.fontSize = "20px";
            textarea.style.color = this.color;

            this.lastTextarea = textarea;

            setTimeout(() => textarea.focus(), 20);
          }
        });

        this.lastTextarea = textarea;
      }

      if (annotation) {
        this.layer.add(annotation);

        annotation.id = nanoid();

        annotation.on("mouseenter", () => {
          if (!this.painting) this.stage.container().style.cursor = "pointer";
        });

        annotation.on("mouseleave", () => {
          if (!this.painting) this.stage.container().style.cursor = "default";
        });

        annotation.on("click", () => {
          console.log(annotation.id);
          if (this.mode === "DELETE") {
            this.stage.container().style.cursor = "default";
            annotation.remove();
            this.stage.draw();
          }
        });

        this.lastAnnotation = annotation;

        this.annotations.push(annotation);
      }
    });

    this.stage.on("mouseup touchend", () => {
      this.painting = false;
    });

    this.stage.on("mousemove touchmove", (e) => {
      if (!this.painting) {
        return;
      }
      // prevent scrolling on touch devices
      e.evt.preventDefault();

      const pos = this.stage.getPointerPosition();

      if (this.mode === "FREE") {
        var newPoints = this.lastAnnotation.points().concat([pos.x, pos.y]);
        this.lastAnnotation.points(newPoints);
      } else if (this.mode === "ARROW") {
        const oldPoints = this.lastAnnotation.points();
        const newPoints = [
          oldPoints[0],
          oldPoints[1],
          pos.x - this.lastAnnotation.x(),
          pos.y - this.lastAnnotation.y(),
        ];

        this.lastAnnotation.points(newPoints);
      }

      this.layer.draw();
    });
  }
}
