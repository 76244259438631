import CardOneMarkerConfig from "./CardOneMarkerConfig";

export default function ToolOneMarkerConfig({editor3d, onClose}) {
  return (
    <div>
      <CardOneMarkerConfig
        editor3d={editor3d}
        onClose={onClose}
      />
    </div>
  );
}
