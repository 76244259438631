import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {createIssue} from "../issuesSlice";
import {setSelectedIssueId} from "../issuesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsNewIssueInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const createS = "Create";

  // data

  const tempIssue = useSelector((s) => s.issues.tempIssue);
  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );

  // handlers

  async function handleCreateClick() {
    const newIssue = {
      ...tempIssue,
      id: nanoid(),
    };

    const result = await dispatch(
      createIssue({
        accessToken,
        listingId: selectedIssuesListingId,
        sceneId: scene.id,
        issue: newIssue,
      })
    );
    const {item} = unwrapResult(result);
    dispatch(setSelectedIssueId(item.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
