import {useState} from "react";

import {Box, IconButton, Tooltip} from "@mui/material";
import {SelectAll as Void} from "@mui/icons-material";

import DialogVoids from "./DialogVoids";

export default function ButtonDialogVoids({scene, caplaEditor}) {
  // strings

  const title = "Gérer les vides";

  //
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <DialogVoids
          scene={scene}
          caplaEditor={caplaEditor}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
      <Box sx={{color: "common.white"}}>
        <Tooltip title={title}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(true)}
          >
            <Void fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}
