import {updateProcessMeasure, setStep} from "Features/viewer3D/viewer3DSlice";
import {Vector2} from "three";

export default class LineMeasurement {
  lineAnnot;
  point1;
  point2;
  point1WithRot;
  point2WithRot;

  constructor({sceneEditor}) {
    this.sceneEditor = sceneEditor;
    this.step = "POINT_1"; // "POINT_2"...
    this.initSceneProcess();
    this.initAnnotation(); // we create the annotation here. Side effect if not : document lisnter "click" is triggered twice..
    this.addListeners();
  }

  // getters

  get annotationColor() {
    return this.sceneEditor.annotationsManager.measurementsPdfManager
      .annotationColor;
  }

  get strokeThickness() {
    const measurementsMngr =
      this.sceneEditor.annotationsManager.measurementsPdfManager;
    return measurementsMngr.strokeM / measurementsMngr.mpd;
  }

  initAnnotation = () => {
    this.annotationManager =
      this.sceneEditor.webViewer.Core.documentViewer.getAnnotationManager();
    const {Annotations} = this.sceneEditor.webViewer.Core;
    const lineAnnot = new Annotations.LineAnnotation();

    lineAnnot.StrokeColor = this.annotationColor;
    lineAnnot.StrokeThickness = this.strokeThickness;
    lineAnnot.Opacity = 0.8;
    //lineAnnot.ReadOnly = true; // to prevent selection once the line was drawn
    //this.annotationManager.addAnnotation(lineAnnot); // added in the end Annotation process
    this.lineAnnot = lineAnnot;
  };

  initSceneProcess() {
    this.sceneEditor.setMode("MEASURE");
  }

  addListeners = () => {
    const {documentViewer} = this.sceneEditor.webViewer.Core;
    documentViewer.addEventListener("click", this.handleClick);
  };
  removeListeners = () => {
    const {documentViewer} = this.sceneEditor.webViewer.Core;
    documentViewer.removeEventListener("click", this.handleClick);
  };

  nextStep = () => {
    switch (this.step) {
      case "POINT_1": {
        this.step = "POINT_2";
        break;
      }
      case "POINT_2": {
        this.step = "COMPUTE";
        break;
      }
      case "COMPUTE": {
        this.step = "POINT_1";
        break;
      }
    }
  };

  delete() {
    const {documentViewer, annotationManager} = this.sceneEditor.webViewer.Core;
    if (this.lineAnnot) {
      this.lineAnnot.ReadOnly = false;
      console.log("delete24");
      annotationManager.deleteAnnotation(this.lineAnnot);
    }
    this.lineAnnot = null;
    documentViewer.removeEventListener("click", this.handleClick);
  }

  handleClick = (e) => {
    console.log("click in line creation", this.step);
    if (this.sceneEditor.mode === "MEASURE") {
      switch (this.step) {
        case "POINT_1": {
          this.point1 = this.sceneEditor.getPointerRawPosition();
          this.point1WithRot = this.sceneEditor.getPointerPosition();
          this.startLine(this.point1);
          this.sceneEditor.dispatch(setStep("POINT_2"));
          break;
        }
        case "POINT_2": {
          const point = this.sceneEditor.getPointerRawPosition();
          const pointWithRot = this.sceneEditor.getPointerPosition();
          const pageRotation = this.sceneEditor.getPointerPageRotation();
          this.point2 = this.getTarget(this.point1, point, pageRotation);
          this.point2WithRot = this.getTargetWithRot(
            this.point1WithRot,
            pointWithRot
          );
          this.sceneEditor.dispatch(setStep("COMPUTE"));
          this.dispatchResult();
          this.endLine();
          break;
        }
        case "COMPUTE": {
          //this.sceneEditor.stopProcess();
          break;
        }
      }
      this.nextStep();
    }
  };

  startLine(point) {
    this.point1 = point;
    console.log("create line annotation at ", point);
    this.lineAnnot.setStartPoint(point.x, point.y);
    this.lineAnnot.setEndPoint(point.x + 1, point.y + 1);
    this.lineAnnot.PageNumber = point.pageNumber;
  }

  getTarget(point1, point2, pageRotation) {
    const direction = this.sceneEditor.drawDirection;
    let x = point2.x,
      y = point2.y;
    if (direction === "HORIZONTAL" && !pageRotation) {
      x = point2.x;
      y = point1.y;
    } else if (direction === "HORIZONTAL" && pageRotation) {
      x = point1.x;
      y = point2.y;
    } else if (direction === "VERTICAL" && !pageRotation) {
      x = point1.x;
      y = point2.y;
    } else if (direction === "VERTICAL" && pageRotation) {
      x = point2.x;
      y = point1.y;
    }
    return {x, y};
  }

  getTargetWithRot(point1, point2) {
    const direction = this.sceneEditor.drawDirection;
    let x = point2.x,
      y = point2.y;
    if (direction === "HORIZONTAL") {
      x = point2.x;
      y = point1.y;
    } else if (direction === "VERTICAL") {
      x = point1.x;
      y = point2.y;
    }
    return {x, y};
  }
  // called when moving the mouse
  update({rawPageCoordinates: point, pageNumber, pageRotation, width}) {
    if (this.step === "POINT_1") {
      if (width) this.lineAnnot.StrokeThickness = width;
    }
    if (this.step === "POINT_2") {
      const target = this.getTarget(this.point1, point, pageRotation);
      this.lineAnnot.setEndPoint(target.x, target.y);
      this.annotationManager.redrawAnnotation(this.lineAnnot);
    }
  }

  endLine() {
    this.removeListeners();
    console.log("add lineAnnotation", this.lineAnnot);
    this.annotationManager.addAnnotation(this.lineAnnot);
    this.sceneEditor.startProcessMeasure();
    //this.sceneEditor.disablePointer();
    //this.sceneEditor.enablePanTool();
  }

  dispatchResult() {
    const zone = this.sceneEditor.annotationsManager.getPointBoundingZone(
      this.point1
    );
    let scale = zone?.scale;
    if (!scale) {
      const dpi = 72;
      const meterByInch = 0.0254;
      scale = (this.sceneEditor.annotationsManager.scale * meterByInch) / dpi;
    }
    const p1p2 = new Vector2();
    const p1V = new Vector2(this.point1.x, this.point1.y);
    const p2V = new Vector2(this.point2.x, this.point2.y);
    p1p2.subVectors(p2V, p1V);
    const distance = p1p2.length() * scale;

    const p1p2WithRot = new Vector2();
    const p1WithRotV = new Vector2(this.point1WithRot.x, -this.point1WithRot.y);
    const p2WithRotV = new Vector2(this.point2WithRot.x, -this.point2WithRot.y);
    p1p2WithRot.subVectors(p2WithRotV, p1WithRotV);

    let angle = p1p2WithRot.angle();

    this.sceneEditor.dispatch(updateProcessMeasure({distance, angle}));
  }
}
