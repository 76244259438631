import {createSlice} from "@reduxjs/toolkit";
import {setSelectedElementTypeGroup} from "Features/elementTypes/elementTypesSlice";

export const boxhomeSlice = createSlice({
  name: "boxhome",
  initialState: {
    selectedRessourceIdInHome: null, // not a good idea to duplicate selection in home...
    //
    selectedElementTypeGroupIdsInHome: [],
    selectedPdfIdsInHome: [],
    //
    linkedTypeGroupsOnly: false, // filter on groups related to packages.
    linkedPdfsOnly: false, // filter onPdf related to one group.
  },
  reducers: {
    setSelectedRessourceIdInHome: (state, action) => {
      state.selectedRessourceIdInHome = action.payload;
    },
    //
    setSelectedElementTypeGroupIdsInHome: (state, action) => {
      state.selectedElementTypeGroupIdsInHome = action.payload;
    },

    setSelectedPdfIdsInHome: (state, action) => {
      state.selectedPdfIdsInHome = action.payload;
    },
    setLinkedTypeGroupsOnly: (state, action) => {
      state.linkedTypeGroupsOnly = action.payload;
    },
    setLinkedPdfsOnly: (state, action) => {
      state.linkedPdfsOnly = action.payload;
    },
  },
});

export const {
  setSelectedRessourceIdInHome,
  setSelectedElementTypeGroupIdsInHome,
  setSelectedPdfIdsInHome,
  setLinkedTypeGroupsOnly,
  setLinkedPdfsOnly,
} = boxhomeSlice.actions;

export default boxhomeSlice.reducer;
