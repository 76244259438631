import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";

import {setHasBimData, removeFromDialogLoadBimDataScope} from "../pdfSlice";
import useSelectedPdfModel from "../useSelectedPdfModel";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {
  // updateModelMeasurements,
  addMeasurementsModelToScope,
  addZonesInScope,
  addSectorsInScope,
  addToShortcutElementTypes,
  addToShortcutSectors,
  addToShortcutPhases,
  addElementTypesInScope,
} from "Features/measurements/measurementsSlice";
import useAddSceneBimData from "Features/measurements/useAddSceneBimData";

export default function DialogLoadBimData({scene, caplaEditor, viewer}) {
  const dispatch = useDispatch(false);

  // strings

  const title = "Chargement noBIM";

  const description =
    "Ce PDF contient des données noBIM. Voulez vous les charger ?";

  const loadS = "Charger";
  const cancelS = "Annuler";

  const measurementsS = "Eléments";
  const packagesS = "Calques de répérage";

  // data

  const selectedPdfModel = useSelectedPdfModel(viewer);
  const scope = useSelector((s) => s.pdf.dialogLoadBimDataScope);
  const hasBimData = useSelector((s) => s.pdf.hasBimData);

  // data - function

  const addSceneBimData = useAddSceneBimData(scene);

  // state

  const [measurementsBD, setMeasurementsBD] = useState([]);
  const [packagesBD, setPackagesBD] = useState([]);

  const [checkedPackClientIds, setCheckedPackClientIds] = useState([]);

  // helpers

  const open = hasBimData && scope.includes(selectedPdfModel?.id);

  // helpers - filteredMeasurements

  const filteredMeasurements = measurementsBD.filter((m) =>
    checkedPackClientIds.includes(m.measurementsModelId)
  );

  const count = filteredMeasurements.length;

  const label = `${count} ${measurementsS}`;

  // init - load data

  useEffect(() => {
    if (open) {
      let {
        // phases,
        // sectors,
        // rooms,
        // zones,
        // elementTypes,
        // ressources,
        measurements,
        // pdfScale,
        packages,
      } = caplaEditor?.editorPdf.loader.getBimData();
      setMeasurementsBD(measurements);
      setPackagesBD(packages);
    }
  }, [open]);
  // handlers - close

  function handleClose() {
    dispatch(removeFromDialogLoadBimDataScope(selectedPdfModel?.id));
    dispatch(setHasBimData(false));
  }

  // handlers - actions

  async function handleLoad() {
    dispatch(removeFromDialogLoadBimDataScope(selectedPdfModel?.id));

    let updatedModel = {...selectedPdfModel};

    let {
      phases,
      sectors,
      rooms,
      zones,
      elementTypes,
      ressources,
      packages,
      measurements,
      pdfScale,
    } = caplaEditor?.editorPdf.loader.getBimData();

    // update scale

    caplaEditor?.editorPdf.annotationsManager.setScale(pdfScale);
    updatedModel.pdfScale = pdfScale;
    //dispatch(updateModel({updatedModel: {...selectedPdfModel, pdfScale}}));

    // sectors & elementTypes & ressources & phases
    addSceneBimData({sectors, rooms, elementTypes, ressources, phases});
    dispatch(addToShortcutElementTypes(elementTypes.map((t) => t.id)));
    dispatch(addElementTypesInScope(elementTypes.map((t) => t.id)));
    dispatch(addToShortcutSectors(sectors.map((s) => s.id)));
    dispatch(addSectorsInScope(sectors.map((s) => s.id)));
    dispatch(addToShortcutPhases(phases.map((s) => s.id)));

    // zones
    dispatch(addZonesInScope(zones.map((z) => z.id)));
    updatedModel.zones = zones;

    caplaEditor?.editorPdf.annotationsManager.loadZoneAnnotations(
      zones,
      selectedPdfModel?.id
    );
    zones = zones.map((z) => ({...z, pdfModelId: selectedPdfModel?.id}));
    caplaEditor?.editorPdf.annotationsManager.createImageModelsFromZones(zones);

    // measurementModels (packages)
    if (packages) {
      const fromModel = {type: "PDF", modelId: selectedPdfModel?.id};
      const allMeasurements = measurements;
      if (!allMeasurements) allMeasurements = [];
      for (let pack of packages) {
        if (checkedPackClientIds.includes(pack.id)) {
          const name = pack.name;
          const measurements = allMeasurements.filter(
            (m) => m.measurementsModelId === pack.id
          );
          console.log("MOM createModel", name, fromModel, measurements);
          const measurementsModel = await caplaEditor?.editor3d?.loader.createMeasurementsModel(
            {
              name,
              fromModel,
              measurements,
            }
          );
          const measurementsModelId = measurementsModel.id;
          console.log("MOM2 create model", measurementsModelId);

          // // add measurements to state.
          // dispatch(
          //   updateModelMeasurements({
          //     measurementsModelId: measurementsModelId,
          //     measurements: measurementsModel?.measurementsData?.measurements,
          //     pdfModelId: selectedPdfModel?.id,
          //   })
          // );

          caplaEditor?.measDataManager?.createOrUpdateModelInManager(measurementsModel);

          dispatch(addMeasurementsModelToScope(measurementsModelId));
        }
      }
    }

    // pdf model
    dispatch(updateModel({updatedModel}));

    // init state for next loading
    dispatch(setHasBimData(false));

    // measurements
    // if (measurements) {
    //   const fromModel = {type: "PDF", modelId: selectedPdfModel?.id};
    //   const name = measurementsS;
    //   const measurementsModel = await editor.loader.createMeasurementsModel({
    //     name,
    //     fromModel,
    //     measurements,
    //   });
    //   const measurementsModelId = measurementsModel.id;
    //   console.log("MOM2 create model", measurementsModelId);

    //   // add measurements to state.
    //   dispatch(
    //     updateModelMeasurements({
    //       measurementsModelId: measurementsModelId,
    //       measurements: measurementsModel?.measurementsData?.measurements,
    //       pdfModelId: selectedPdfModel?.id,
    //     })
    //   );

    //   dispatch(addMeasurementsModelToScope(measurementsModelId));

    //   dispatch(updateModel({updatedModel}));

    //   // init state for next loading
    //   dispatch(setHasBimData(false));
    // }

    // init filters
    // dispatch(setShortcutElementTypes(elementTypesInScope));
    // dispatch(setSectorsInScope([...sectors.map((s) => s.id), null]));
    // dispatch(setShortcutSectors([...sectors.map((s) => s.id), null]));
    // dispatch(setZonesInScope([...zonesH.map((z) => z.id), null]));
  }

  function handleCancel() {
    handleClose();
  }

  function handleCheck(e, modelId) {
    const checked = e.target.checked;
    let ids = [...checkedPackClientIds];
    if (checked) {
      ids.push(modelId);
    } else {
      ids = ids.filter((id) => id !== modelId);
    }
    setCheckedPackClientIds(ids);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </DialogContent>
      <Box sx={{p: 2}}>
        <Grid container sx={{mb: 1}}>
          <Grid item xs={10}>
            <Typography color="text.secondary" variant="body2">
              {packagesS}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="text.secondary" variant="body2">
              {measurementsS}
            </Typography>
          </Grid>
        </Grid>
        {packagesBD.map((model) => (
          <Grid container key={model.id}>
            <Grid item xs={1} sx={{alignItems: "center", display: "flex"}}>
              <Checkbox
                sx={{height: 24, width: 24}}
                size="small"
                checked={checkedPackClientIds.includes(model.id)}
                onChange={(e) => handleCheck(e, model.id)}
              />
            </Grid>
            <Grid item xs={10} sx={{alignItems: "center", display: "flex"}}>
              <Typography variant="body2">{model.name}</Typography>
            </Grid>
            <Grid item xs={1} sx={{alignItems: "center", display: "flex"}}>
              <Typography variant="body2">{model.elements}</Typography>
            </Grid>
          </Grid>
        ))}
        <Typography sx={{mt: 2}} variant="body2" color="text.secondary">
          {label}
        </Typography>
      </Box>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleLoad}>{loadS}</Button>
      </DialogActions>
    </Dialog>
  );
}
