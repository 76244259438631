const applyStyle = (row, i, isTitle, rank, style) => {
  if ((isTitle && style === undefined) || ["title1", "title2"].includes(style)) {
    row.getCell(i).font = {bold: true};
    if (rank === 1 || style === "title1")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'bbbbc0'}
      };
    if (rank === 2 || style === "title2")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F4F4F8'}
      };
  }
  if (style === "detail")
    row.getCell(i).font = {color: {argb: "777777"}};
};

export default function fillWorksheetWithRessources({
  worksheet,
  ressources,
  rowStartIndex,
  withRefQty,
  withDescription,
  withPrices,
  areTotals,
  maxCol = 0,
  ressourcesPU,
  hasPU,
}) {
  ressources.forEach(({
    num,
    name,
    isTitle,
    unit,
    quantity,
    description,
    refQty,
    byBuildings,
    hardcodedQty,
    style,
    rank,
    hideNum,
    pu,
    group,
  }, index) => {
    const row = worksheet.getRow(rowStartIndex + index);

    if (hasPU && !pu) pu = ressourcesPU[group][num];

    if (maxCol > 0) {
      for (let i = 1; i <= maxCol + 1; i++) {
        applyStyle(row, i, isTitle, rank, style);
        if (areTotals.includes(i.toString()))
          row.getCell(i).font = {bold: true};
      }
    }

    if (typeof num === "string") {
      if (!hideNum) row.getCell(1).value = num;
    }

    if (typeof name === "string") {
      row.getCell(2).value = name;
    }

    if (typeof unit === "string" && !isTitle) {
      row.getCell(3).value = unit;
    }

    let quantityIdx = 4;

    if (withRefQty) {
      quantityIdx += 1;
      if (typeof refQty === "number" && !isNaN(refQty))
        row.getCell(4).value = refQty;
    }

    let qValue;
    if (typeof hardcodedQty === "number" && !isNaN(hardcodedQty))
      qValue = hardcodedQty;
    else if (typeof quantity === "number" && !isNaN(quantity))
      qValue = quantity;

    if (qValue) {
      row.getCell(quantityIdx).value = qValue;
      if (qValue < 0) row.getCell(quantityIdx).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'FF0000'}
      };
    }

    if (withPrices || hasPU) {
      quantityIdx += 1;
      if (typeof pu === "number")
        row.getCell(quantityIdx).value = Math.round(pu * 100) / 100;
      quantityIdx += 1;
      if (qValue && typeof pu === "number")
        row.getCell(quantityIdx).value = Math.round((pu * qValue) * 100) / 100;
    }

    if (withDescription) {
      quantityIdx += 1;
      if (typeof description === "string") row.getCell(quantityIdx).value = description;
    }

    if (byBuildings) for (const [i, v] of Object.entries(byBuildings)) {
      if (v && !hardcodedQty) {
        const value = Math.round(v * 100) / 100;
        row.getCell(parseInt(i)).value = value;
        if (value < 0) row.getCell(parseInt(i)).fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'FF0000'}
        };
      } else if (v) {
        row.getCell(parseInt(i)).value = "x";
      }
    }
  });
}
