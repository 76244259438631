import {useState, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";

import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import CardPdfName from "Features/pdf/components/CardPdfName";

import {urlToFile} from "Features/files/utils";
import {getImageSize} from "Features/images/imageUtils";

export default function DialogCreateZoneBasic({
  open,
  onClose,
  caplaEditor,
  scene,
}) {
  // string

  const title = "Ajoutez un fond de plan";
  const subtitle = `Un fond de plan est une zone rectangulaire découpée dans le PDF.
  Par défault, 1 page = 1 fond de plan.
  Vous pouvez créer un fond de plan en dessinant le rectangle qui vous intéresse.`;

  const createS = "Ajouter";

  const nameS = "Nom";

  const description = "Création du fond de plan en cours ...";

  // data

  const selectedPdfModel = useSelectedPdfModel();

  // state

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // state init

  useEffect(() => {
    if (selectedPdfModel?.name) {
      setName(selectedPdfModel?.name);
    }
  }, [selectedPdfModel?.name]);

  // handlers

  function handleClose() {
    if (!loading) onClose();
  }

  async function handleCreate() {
    if (loading || !caplaEditor?.editorPdf) return;
    setLoading(true);
    const tempZone = {};
    //
    const id = nanoid();
    const modelId = selectedPdfModel?.id;
    const pdfModelId = selectedPdfModel?.id;
    //
    const rotation = {x: -Math.PI / 2, y: 0, z: 0};
    const position = {x: 0, y: 0, z: 0};
    //
    const {url, width, height, pageNumber} =
      await caplaEditor.editorPdf.getImageOfPageAsync();
    console.log("creating zone", width, height, pageNumber);
    const imageSize = await getImageSize({fileURL: url});
    const fileName = name + id;
    const file = await urlToFile(url, fileName);
    const scale = (50 * 0.0254) / 72.0; //    const dpi = 72; const meterByInch = 0.0254;
    //
    tempZone.id = id;
    tempZone.name = name;
    tempZone.modelId = modelId;
    tempZone.pdfModelId = pdfModelId;
    tempZone.scale = scale;
    tempZone.position = position;
    tempZone.rotation = rotation;
    tempZone.imageSize = imageSize;
    tempZone.width = width;
    tempZone.height = height;
    tempZone.pageNumber = pageNumber;

    //
    const options = {d3: false}; // disable 3D
    await caplaEditor?.editorPdf?.annotationsManager.createZoneFromTempZone(
      tempZone,
      file,
      options
    );
    setLoading(false);
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              visibility: loading ? "hidden" : "visible",
            }}
          >
            <Box sx={{p: 1, color: "text.secondary", mb: 2}}>
              <Typography sx={{whiteSpace: "pre-line", fontSize: 13}}>
                {subtitle}
              </Typography>
            </Box>
            <Box sx={{width: 1, p: 1, display: "flex", alignItems: "center"}}>
              <Box sx={{display: "flex", mr: 2, flexGrow: 1}}>
                <CardPdfName model={selectedPdfModel} />
              </Box>
              <Box>
                <TextField
                  size="small"
                  label={nameS}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                width: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>{description}</Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCreate}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
