import useSceneModule from "Features/navigation/useSceneModule";
import useSelection from "../useSelection";

import {Box} from "@mui/system";

import HeaderInSelectionPanelArticle from "Features/articles/components/HeaderInSelectionPanelArticle";

import HeaderInSelectionPanelArticlesGroup from "Features/articles/components/HeaderInSelectionPanelArticlesGroup";

export default function HeaderSelectionByModule({scene}) {
  const sceneModule = useSceneModule();

  const selection = useSelection({scene});

  return (
    <Box sx={{width: 1}}>
      {sceneModule === "ARTICLES" && selection?.type === "ARTICLE" && (
        <HeaderInSelectionPanelArticle scene={scene} />
      )}
      {sceneModule === "ARTICLES" && selection?.type === "ARTICLES_GROUP" && (
        <HeaderInSelectionPanelArticlesGroup scene={scene} />
      )}
    </Box>
  );
}
