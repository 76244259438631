import React from "react";

import {useSelector, useDispatch} from "react-redux";
import useSelectedProgressRecord from "../useSelectedProgressRecord";

import {Box} from "@mui/material";

import ButtonDialogCreateProgressRecord from "./ButtonDialogCreateProgressRecord";
import ButtonDialogUpdateProgressRecord from "./ButtonDialogUpdateProgressRecord";
import ButtonDeleteProgressRecord from "./ButtonDeleteProgressRecord";

import ToggleProgressViewMode from "./ToggleProgressViewMode";

export default function ToolbarProgressMain({scene}) {
  const dispatch = useDispatch();

  // data

  const selectedProgressRecord = useSelectedProgressRecord(scene);
  const index = useSelector((s) => s.progress.progressTabsIndex);

  // handlers

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        justifyContent: "space-between",
      }}
    >
      {index === 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <ButtonDialogCreateProgressRecord scene={scene} />
          {selectedProgressRecord && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "&>*:not(:last-child)": {mr: 1},
              }}
            >
              <ButtonDialogUpdateProgressRecord
                scene={scene}
                progressRecord={selectedProgressRecord}
              />
              <ButtonDeleteProgressRecord
                scene={scene}
                progressRecord={selectedProgressRecord}
              />
            </Box>
          )}
        </Box>
      )}

      {index === 1 && <Box></Box>}
      <ToggleProgressViewMode />
    </Box>
  );
}
