import {client} from "API/capla360";
export async function fetchWorksitesService({accessToken}) {
  const response = await client.get(`/worksites/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function postWorksiteService({accessToken, worksite}) {
  const response = await client.post(`/worksites/`, worksite, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function fetchWorksiteService({accessToken, worksiteId}) {
  const response = await client.get(`/worksites/${worksiteId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function updateWorksiteService({accessToken, worksite}) {
  const worksiteId = worksite.id;
  const response = await client.put(`/worksites/${worksiteId}`, worksite, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

// spaces

export async function createSpaceService({accessToken, worksiteId, space}) {
  const response = await client.post(
    `/worksites/${worksiteId}/spaces/`,
    space,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {space: response.data, worksiteId};
}

export async function updateSpaceService({accessToken, space, worksiteId}) {
  const spaceId = space.id;
  const response = await client.put(`/spaces/${spaceId}`, space, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {space: response.data, worksiteId};
}
