import React, {useState} from "react";

import {nanoid} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";

import {
  IconButton,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function ButtonCreateModelFromMap({editor, scene}) {
  // strings

  const tooltipString = "Create a model";
  const titleString = "Create a model from the map";
  const saveString = "Save";
  const nameString = "Name";

  const googleMapString = "Google map";
  const azureMapString = "Microsoft map";

  // data

  const mapProvider = useSelector((s) => s.viewer3D.mapProvider);

  // state

  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // helpers

  const fileNameMap = {
    AZURE: azureMapString,
    GOOGLE: googleMapString,
  };

  const offsetMap = {
    AZURE: -0.02,
    GOOGLE: -0.01,
  };

  const fileName = fileNameMap[mapProvider];

  // handlers - dialog

  async function handleCreateClick() {
    setOpen(true);
    const canvas = await editor.mapEditor.getScreenshotCanvas();
    setUrl(canvas.toDataURL());
  }

  function handleDialogClose() {
    setOpen(false);
  }

  // handlers - form

  function handleNameChange(e) {
    setName(e.target.value);
  }

  async function handleSaveClick() {
    if (!loading) {
      setLoading(true);

      //file
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = `bb-${nanoid()}.png`;
      const file = new File([blob], fileName, {type: "image/png"});
      //width & height
      const {width, height} = editor.mapEditor.getSize();
      // position
      const position = editor.mapEditor.getCenterPosition(scene.rotation);
      position.y = offsetMap[mapProvider];
      //rotation
      const rotation = {
        x: -Math.PI / 2,
        y: 0,
        z: (Math.PI / 180) * scene?.rotation,
      };

      const options = {
        name,
        //fromMap: {lat, lng, zoom},
        width,
        height,
        rotation,
        position,
      };

      editor.loader.loadFile({file, type: "IMAGE", options});
      setLoading(false);
    }

    setOpen(false);
  }
  return (
    <>
      <Box sx={{bgcolor: "common.white"}}>
        <Tooltip title={tooltipString}>
          <IconButton
            sx={{borderRadius: "4px"}}
            size="small"
            onClick={handleCreateClick}
          >
            <Add fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{titleString}</DialogTitle>
        <DialogContent>
          <Box sx={{mt: 2, display: "flex", flexDirection: "column"}}>
            <TextField
              sx={{mb: 2}}
              label={<Typography variant="body2">{nameString}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={name}
              onChange={handleNameChange}
            />
            <Box
              sx={{
                height: 200,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundImage: `url(${url})`,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveClick}>{saveString}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
