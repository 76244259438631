import {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import {fetchSharedRemoteScene} from "./scenesSlice";

export default function useSharedRemoteScene({shareLink, caplaEditor}) {
  const dispatch = useDispatch();

  const {scene, status} = useSelector(
    (state) => state.scenes.sharedRemoteScene
  );

  useEffect(() => {
    if (status === "idle" && shareLink) {
      dispatch(fetchSharedRemoteScene({shareLink, caplaEditor}));
    }
  }, [status, shareLink]);

  return scene;
}
