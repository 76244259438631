const offerDescriptionMap = {
  PEINTURE_LOGEMENTS: {
    name: "Pack peinture logement",
    priceLabel: "9.99 € / logement",
    description:
      "DCE bimboxé pour le lot peinture d'une opération de logements",
    details: `- Accès à la bimbox depuis un lien web.
- Quantité par article de la DPGF (export Excel)
- Localisation des ouvrages (en 3D et 2D).
- Liens DPGF / CCTP.
- Mise à disposition de la bimbox jusqu'à la fin des travaux.`,
  },
};

export default offerDescriptionMap;
