import {Dialog, DialogTitle, DialogContent} from "@mui/material";

import SectionAutoMeasTypesDetails from "./SectionAutoMeasTypesDetails";

export default function DialogAutomation({open, onClose, scene, caplaEditor}) {
  // strings

  const titleS = "Automatisation";

  // handlers

  function handleSaved() {
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent>
        <SectionAutoMeasTypesDetails
          scene={scene}
          caplaEditor={caplaEditor}
          onSaved={handleSaved}
        />
      </DialogContent>
    </Dialog>
  );
}
