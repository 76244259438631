export default function getFiltersStrFromArticleTypesItems(articleTypesItems) {
  let filtersStr = [];

  articleTypesItems.forEach((item) => {
    if (Array.isArray(item.filtersStr) && item.filtersStr.length > 0) {
      filtersStr = [...new Set([...filtersStr, ...item.filtersStr])];
    }
  });

  return filtersStr;
}
