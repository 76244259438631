import {useSelector} from "react-redux";

export default function useViewsByScene(scene, options) {
  let views = useSelector((s) => s.views.items);

  views = views.filter((v) => v.sceneId === scene?.id);

  if (options?.sortByName) {
    views = views.sort((a, b) => a.name.localeCompare(b.name));
  }

  return views;
}
