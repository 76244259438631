import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  Button,
  DialogActions,
  Paper,
} from "@mui/material";

import {getFileSizeString} from "Features/files/utils";
import FieldTempZoneName from "./FieldTempZoneName";
import FieldTempZoneSlope from "./FieldTempZoneSlope";
import FieldTempZoneSector from "./FieldTempZoneSector";
import FieldTempZoneRoom from "./FieldTempZoneRoom";
import FieldTempZoneScale from "./FieldTempZoneScale";
import FieldTempZoneHeight from "./FieldTempZoneHeight";
import FieldTempZoneD3 from "./FieldTempZoneD3";
import useZonesByScene from "../hooks/useZonesByScene";

import {setOpenDialogCreateZone} from "Features/zones/zonesSlice";
import ImageBasic from "Features/images/components/ImageBasic";
import {urlToFile} from "Features/files/utils";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

const isProperScale = (s) => {
  const vs = s.split(":");
  if (vs.length !== 2) return false;
  const checkV1 = !isNaN(vs[0]) && !isNaN(parseFloat(vs[0]));
  const checkV2 = !isNaN(vs[1]) && !isNaN(parseFloat(vs[1]));
  return checkV1 && checkV2;
};

export default function DialogCreateZone({scene, caplaEditor}) {
  const dispatch = useDispatch();
  // strings

  const title = "Nouveau fond de plan";
  const heightD = "A quelle altimétrie situer ce fond de plan ?";
  const cancelS = "Annuler";
  const saveS = "Enregistrer";

  // data

  const open = useSelector((s) => s.zones.openDialogCreateZone);

  const tempZoneToCreate = useSelector((s) => s.zones.tempZoneToCreate);

  const zones = useZonesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);

  const pdfScale = caplaEditor?.editorPdf?.annotationsManager.scale;

  // state

  const [name, setName] = useState("");
  const [slope, setSlope] = useState("HORIZONTAL");
  const [sector, setSector] = useState(null);
  const [room, setRoom] = useState(null);
  const [scale, setScale] = useState(pdfScale ? `1:${pdfScale}` : "1:50");
  const [height, setHeight] = useState("");
  const [d3, setD3] = useState(false); // désactive la 3D : d3.

  // init
  useEffect(() => {
    if (!sector?.id && sectors?.length > 0) {
      setSector(sectors[0]);
    }
  }, [sector?.id, sectors?.length]);

  const [loading, setLoading] = useState(false);

  const disabled =
    name.length === 0 || !sector?.id || !tempZoneToCreate.fileUrl;

  // helper • tempZone

  const rotation = {x: -Math.PI / 2, y: 0, z: 0};
  if (slope === "VERTICAL") rotation.x = 0;

  const heightS = height.toString().replace(",", ".");
  const y = heightS ? parseFloat(heightS) : 0;
  const position = {x: 0, y, z: 0};

  const tempZoneWithoutId = {
    modelId: tempZoneToCreate.modelId,
    pdfModelId: tempZoneToCreate.modelId, // need both modelId & pdfModelId...
    x: tempZoneToCreate.x,
    y: tempZoneToCreate.y,
    width: tempZoneToCreate.width,
    height: tempZoneToCreate.height,
    imageSize: tempZoneToCreate.imageSize,
    pageRotation: tempZoneToCreate.pageRotation,
    pageNumber: tempZoneToCreate.pageNumber,
    scale: tempZoneToCreate.scale,
    position,
    rotation,
    sectorId: sector?.id,
    roomId: room?.id,
    name,
    d3,
  };

  // handlers

  function handleCloseDialog() {
    handleStop();
    dispatch(setOpenDialogCreateZone(false));
  }

  function handleSectorChange(sector) {
    setSector(sector);
    if (sector?.id) {
      // if (!height && height !== 0) setHeight(sector.z);
      setHeight(sector.z);
    }
  }
  function handleRoomChange(room) {
    setRoom(room);
  }

  function handleScaleChange(formatedScale) {
    setScale(formatedScale);
  }

  function handleStop(options) {
    const deleteAnnotation = options?.deleteAnnotation;
    dispatch(setOpenDialogCreateZone(false));
    caplaEditor?.editorPdf?.annotationsManager.stopDrawingZone();
    caplaEditor?.editorPdf?.annotationsManager.setCreatingZone(false);
    if (deleteAnnotation)
      caplaEditor?.editorPdf?.annotationsManager.deleteTempAnnotationToCreateZone();
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    const id = nanoid();
    const fileName = name + id;
    const file = await urlToFile(tempZoneToCreate.fileUrl, fileName);
    let formatedScale = scale;
    if (!formatedScale || !isProperScale(formatedScale)) formatedScale = "1:50";
    tempZoneWithoutId.scale =
      (parseFloat(formatedScale.split(":")[1]) * 0.0254) / 72.0; //    const dpi = 72; const meterByInch = 0.0254;
    const tempZone = {id, ...tempZoneWithoutId};
    const {maxX} = caplaEditor?.editor3d.sceneEditor.getSize();
    const imageWidth = tempZone.width * tempZone.scale;
    const newPosition = {...position};
    if (maxX > 1 && zones.length > 0) newPosition.x = maxX + 5 + imageWidth;
    tempZone.position = newPosition;

    const options = {d3}; // disable 3D
    await caplaEditor?.editorPdf?.annotationsManager.createZoneFromTempZone(
      tempZone,
      file,
      options
    );
    setLoading(false);
    //
    // stop
    //
    handleStop();
  }

  useEffect(() => {
    setScale(`1:${pdfScale}`);
  }, [pdfScale]);

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <Box
        sx={{
          width: 400,
          height: 200,
          bgcolor: "background.default",
          position: "relative",
        }}
      >
        <ImageBasic url={tempZoneToCreate?.fileUrl} />
        <Box
          sx={{
            position: "absolute",
            left: "32px",
            right: "32px",
            bottom: "16px",
          }}
        >
          <Paper sx={{bgcolor: "common.white", p: 2}}>
            <FieldTempZoneSlope
              slope={slope}
              onChange={(slope) => setSlope(slope)}
            />
          </Paper>
        </Box>
      </Box>

      <Typography sx={{fontSize: 13, color: "text.secondary", pl: 1}}>
        {getFileSizeString(tempZoneToCreate?.fileSize)}
      </Typography>

      <Box sx={{p: 1, mt: 3, "&>*:not(:last-child)": {mb: 3}}}>
        <FieldTempZoneName name={name} onChange={(name) => setName(name)} />

        <Box sx={{"&>*:not(:last-child)": {mb: 2}}}>
          <FieldTempZoneSector
            sector={sector}
            onChange={handleSectorChange}
            scene={scene}
          />
          {rooms.length > 0 && (
            <FieldTempZoneRoom
              room={room}
              onChange={handleRoomChange}
              scene={scene}
              sectorCode={sector?.code}
            />
          )}
        </Box>
      </Box>
      {slope === "HORIZONTAL" && (
        <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="text.secondary" sx={{mr: 2}}>
            {heightD}
          </Typography>

          <Box sx={{width: 100}}>
            <FieldTempZoneHeight
              height={height}
              onChange={(height) => setHeight(height)}
            />
          </Box>
        </Box>
      )}
      <Box sx={{display: "flex", mb: 1, p: 1, ml: 1}}>
        <Box sx={{width: 100}}>
          <FieldTempZoneScale
            scale={scale}
            onChange={(s) => handleScaleChange(s)}
          />
        </Box>
        <Box sx={{pl: 3}}>
          <FieldTempZoneD3 d3={d3} onChange={() => setD3(!d3)} />
        </Box>
      </Box>
      <DialogActions>
        <Button onClick={() => handleStop({deleteAnnotation: true})}>
          {cancelS}
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={disabled}>
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
