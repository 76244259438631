import {useState} from "react";

import {Box} from "@mui/material";

import ButtonCreateMarkerOnPdf from "Features/viewer3D/components/ButtonCreateMarkerOnPdf";

export default function ShortcutPdfMarkers({caplaEditor, scene}) {
  // strings

  // const sceneString = "Bimbox";
  // const worksiteString = "Worksite";

  // state

  const initType = scene?.worksite ? "WORKSITE" : "SCENE";
  const [type, _] = useState(initType);

  // handler

  // function handleTypeChange(v) {
  //   setType(v);
  // }

  return (
    <Box
      sx={{
        width: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <Box
        sx={{
          borderRadius: "4px",
          bgcolor: "common.white",
          width: "fit-content",
          mb: 2,
        }}
      >
        <ToggleButtonGroup
          exclusive
          value={type}
          onChange={(e, v) => handleTypeChange(v)}
          size="small"
          //sx={{height: 34}}
        >
          <ToggleButton
            value="WORKSITE"
            sx={{textTransform: "unset", width: 100}}
          >
            <Typography variant="body2">
              <b>{worksiteString}</b>
            </Typography>
          </ToggleButton>
          <ToggleButton value="SCENE" sx={{textTransform: "unset", width: 100}}>
            <Typography variant="body2">
              <b>{sceneString}</b>
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box> */}

      <ButtonCreateMarkerOnPdf caplaEditor={caplaEditor} type={type} />
    </Box>
  );
}
