export default function addSectorsToRow(row, sectors, startIndex, styleIndex) {
  const styleCell = row.getCell(styleIndex); // used to get the style and apply it to the rest.
  const font = styleCell.font;
  const fill = styleCell.fill;

  sectors?.forEach((sector, index) => {
    const sectorCell = row.getCell(startIndex + index);
    sectorCell.value = sector.code;
    sectorCell.font = font;
    sectorCell.fill = fill;
    sectorCell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  });
}
