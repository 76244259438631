import {useEffect, useState} from "react";
import {Box} from "@mui/material";

import operationImage from "../assets/operationImage.png";
import bimboxOverview from "../assets/bimboxOverview.png";

import Stretch from "Features/ui/components/Stretch";
import HeaderOrder from "Features/ebusiness/components/HeaderOrder";
import ImageBasic from "Features/images/components/ImageBasic";
import SectionOrder from "./SectionOrder";
import {useParams} from "react-router-dom";
import {fetchBimboxOrder} from "../services";

const bimboxOrderDefault = {
  operationName: "Arc Attria",
  operationDescription: "Construction de 37 logements à Nantes",
  operationImageUrl: operationImage,
  bimboxName: "Lot Peinture",
  bimboxDescription: "DCE bimboxé pour le lot peinture",
  bimboxOverviewUrl: bimboxOverview,
  bimboxPrice: 369.63,
};

export default function PageBimboxOrder() {
  const {sharedBimboxId} = useParams();

  // state

  const [bimboxOrder, setBimboxOrder] = useState(bimboxOrderDefault);

  // init data loading

  async function getBimboxOrder() {
    const order = await fetchBimboxOrder(sharedBimboxId);
    if (order?.bimboxName) setBimboxOrder(order);
  }

  useEffect(() => {
    getBimboxOrder();
  }, []);

  // data helpers

  const operationName = bimboxOrder.operationName;
  const operationDescription = bimboxOrder.operationDescription;
  const operationImageUrl = bimboxOrder.operationImageUrl;
  const bimboxName = bimboxOrder.bimboxName;
  const bimboxDescription = bimboxOrder.bimboxDescription;
  const bimboxOverviewUrl = bimboxOrder.bimboxOverviewUrl;
  const bimboxPrice = bimboxOrder.bimboxPrice;
  const bimboxOfferKey = bimboxOrder.bimboxOfferKey;

  return (
    <Box sx={{width: 1}}>
      <Box sx={{width: 1}}>
        <HeaderOrder
          operationName={operationName}
          operationDescription={operationDescription}
        />
      </Box>
      <Stretch>
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              height: 1,
              flex: 1,
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <ImageBasic url={operationImageUrl} grayscale />
          </Box>
          <Box sx={{height: 1, width: 500, overflow: "auto"}}>
            <SectionOrder
              operationName={operationName}
              bimboxName={bimboxName}
              bimboxDescription={bimboxDescription}
              bimboxOverviewUrl={bimboxOverviewUrl}
              bimboxPrice={bimboxPrice}
              bimboxOfferKey={bimboxOfferKey}
              sharedBimboxId={sharedBimboxId}
            />
          </Box>
        </Box>
      </Stretch>
    </Box>
  );
}
