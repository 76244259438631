import {useEffect, useState} from "react";

import {Slider, Box, Typography} from "@mui/material";

import throttle from "lodash.throttle";

export default function SliderImageCrop({modelId, editor3d, enabled}) {
  // strings

  const cropS = "Rogner";

  // props

  const entity = editor3d?.getEntity(modelId);

  // state

  const initCropRatios = entity?.cropRatios
    ? [entity.cropRatios[0] * 100, entity.cropRatios[1] * 100]
    : [0, 100];
  const [cropRatios, setCropRatios] = useState(initCropRatios);
  useEffect(() => setCropRatios(initCropRatios), [modelId]);

  // handlers

  function handleCropChange(_, newValue) {
    const [l, r] = newValue;
    setCropRatios([l, r]);
    if (entity?.tempCrop) {
      entity.tempCrop([l / 100, r / 100, 0, 1]);
    }
  }

  return (
    <Box
      sx={{
        minWidth: 200,
        px: 1,
        pr: 2,
        display: "flex",
        alignItems: "center",
        width: 1,
      }}
    >
      <Typography sx={{fontSize: 12, mr: 2}}>{cropS}</Typography>
      <Slider
        size="small"
        color="primary"
        min={0}
        max={100}
        value={cropRatios}
        onChange={throttle(handleCropChange, 100)}
        disabled={!enabled}
      />
    </Box>
  );
}
