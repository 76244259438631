import {Box, Typography, Tooltip} from "@mui/material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

const getBackgroundColor = (color, selected) => {
  let col = color ? color : theme.palette.common.yellow;
  return selected ? col : lighten(col, 0.9);
};

export default function ChipVersion({
  id,
  num,
  name,
  color,
  onClick,
  selected,
  variant,
}) {
  let label = id === "all" ? num : `v${num}`;
  if (id === "undefined") label = "-?-";

  const clickable = Boolean(onClick);

  function handleClick() {
    if (clickable) onClick(id);
  }

  if (variant === "overview") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "8px",
          width: "8px",
          borderRadius: "4px",
          bgcolor: getBackgroundColor(color, selected),
          ...(clickable && {cursor: "pointer"}),
        }}
      />
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "24px",
          borderRadius: "8px",
          px: 1,
          bgcolor: getBackgroundColor(color, selected),
          ...(clickable && {cursor: "pointer"}),
        }}
        onClick={handleClick}
      >
        <Tooltip title={name}>
          <Typography sx={{fontSize: 13}}>{label}</Typography>
        </Tooltip>
      </Box>
    );
  }
}
