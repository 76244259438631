import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";
import {deleteElementTypesGroup} from "../elementTypesSlice";

import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function DialogDeleteElementTypeGroup({
  open,
  onClose,
  elementTypeGroup,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // handlers

  async function handleDeleteConfirm() {
    console.log("[DEBUG] confirm group deletion", elementTypeGroup);
    dispatch(
      deleteElementTypesGroup({
        accessToken,
        elementTypesGroup: elementTypeGroup,
      })
    );
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={onClose}
      onConfirm={handleDeleteConfirm}
      ressource={"elementTypesGroup"}
    />
  );
}
