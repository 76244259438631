import React from "react";

import {Box} from "@mui/material";

import ListIconDatasetType from "./ListIconDatasetType";

export default function ListSceneDatasetsFilters({
  modelTypes,
  listingTypes,
  selectedTypes,
  onClick,
}) {
  // helpers

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {modelTypes.map((type) => {
        const unselected = !selectedTypes.includes(type);
        return (
          <Box
            key={type}
            onClick={() => onClick(type)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ListIconDatasetType
              isModel={true}
              type={type}
              unselected={unselected}
            />
          </Box>
        );
      })}

      {listingTypes.map((type) => {
        const unselected = !selectedTypes.includes(type);
        return (
          <Box
            key={type}
            onClick={() => onClick(type)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ListIconDatasetType
              isListing={true}
              type={type}
              unselected={unselected}
            />
          </Box>
        );
      })}
    </Box>
  );
}
