import React, {useEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {setCutBeingAdded, addCut} from "../cutsSlice";

import {Dialog, DialogContent, IconButton, Box} from "@mui/material";
import {Close} from "@mui/icons-material";
import {getImageSize} from "Features/images/imageUtils";

export default function DialogAddCut({editor}) {
  const dispatch = useDispatch();

  // data

  const cutBeingAdded = useSelector((s) => s.cuts.cutBeingAdded);

  // state

  const [size, setSize] = useState({width: 200, height: 100});

  // helpers

  const open = Boolean(cutBeingAdded?.url);
  const url = cutBeingAdded?.url;

  // handlers

  function handleClose() {
    dispatch(addCut(cutBeingAdded));
    dispatch(setCutBeingAdded({}));
    // editorPdf.annotationsManager.measurementsPdfManager.deleteAutoMeasureAnnotation();
  }

  // effects

  async function getSize(url) {
    const size = await getImageSize({fileURL: url});
    setSize(size);
  }

  useEffect(() => {
    if (url) getSize(url);
  }, [url]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Box
          sx={{
            bgcolor: "background.default",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundImage: `url(${url})`,
            width: `${size.width}px`,
            height: `${size.height}px`,
          }}
        ></Box>
      </DialogContent>
    </Dialog>
  );
}
