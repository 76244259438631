import {createSlice} from "@reduxjs/toolkit";

export const poisSlice = createSlice({
  name: "pois",
  initialState: {
    tempPoi: "",
    selectedPoiId: null,
  },
  reducers: {
    setTempPoi: (state, action) => {
      state.tempPoi = action.payload;
    },
    setSelectedPoiId: (state, action) => {
      state.selectedPoiId = action.payload;
    },
  },
});

export const {setTempPoi, setSelectedPoiId} = poisSlice.actions;

export default poisSlice.reducer;
