import {
  SphereGeometry,
  MeshBasicMaterial,
  Mesh,
  Vector3,
  Color,
  Object3D,
} from "three";
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import Vector from "./Vector";

import theme from "Styles/theme";

class Pointer {
  object;
  normalEntity; // 3D vector
  locked; // the pointer doesn't move. Used for snapping.
  position;

  constructor(color, radius = 0.005) {
    this.object = this._create(color, radius);
    this.normal = undefined; // set when computing the intersection
    this.enabled = true;
  }

  _create(color, radius) {
    //object
    const geometry = new SphereGeometry(radius, 16, 16);
    const material = new MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.8,
    });
    const object = new Mesh(geometry, material);
    //const object = new Object3D();

    // label
    const pointer = document.createElement("div");
    pointer.style.height = "24px";
    pointer.style.width = "24px";
    pointer.style.borderRadius = "12px";
    pointer.style.transform = "translate(-12px,-12px)";
    pointer.style.border = "1px solid black";
    const objectCSS = new CSS2DObject(pointer);
    //objectCSS.layers.enable(1);
    this.objectCSS = objectCSS;

    // vector

    const normalEntity = new Vector();
    normalEntity.enable();
    this.normalEntity = normalEntity;

    // object

    object.add(objectCSS);
    normalEntity.loadTo(object);

    return object;
  }

  // getters

  getPosition() {
    if (this.position) {
      return {
        x: this.position.x,
        y: this.position.y,
        z: this.position.z,
      };
    }
  }

  // change

  setPosition(p) {
    this.position = p;
    if (p) this.object.position.set(p.x, p.y, p.z);
  }

  setNormal(n) {
    this.normal = n;
    if (n) {
      this.normalEntity.setPosition(
        new Vector3(0, 0, 0),
        new Vector3(n.x, n.y, n.z)
      );
      if (this.enabled) this.showNormal();
    } else {
      this.hideNormal();
    }
  }

  update({position, normal}) {
    if (!this.locked) {
      this.setPosition(position);
      this.setNormal(normal);
    }
  }

  // visibility

  showNormal() {
    this.normalEntity.enable();
  }

  hideNormal() {
    this.normalEntity.disable();
  }

  show() {
    this.object.layers.enable(1);
    this.showNormal();
    this.objectCSS.element.hidden = false;
  }

  enable() {
    this.show();
    this.enabled = true;
  }

  hide() {
    this.object.layers.disable(1);
    this.hideNormal();
    this.objectCSS.element.hidden = true;
  }

  disable() {
    this.hide();
    this.enabled = false;
  }

  // snapping

  setSnapped(value) {
    if (value) {
      this.objectCSS.element.style.border = `1px solid ${theme.palette.common.flashGreen}`;
    } else {
      this.objectCSS.element.style.border = `1px solid ${theme.palette.primary.flash}`;
    }
  }

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  // misc

  loadTo(scene) {
    scene.add(this.object);
  }
}

export default Pointer;
