import {useState} from "react";

import {Box, IconButton, Tooltip} from "@mui/material";
import {SettingsSuggest as Procedures} from "@mui/icons-material";

import DialogProcedures from "./DialogProcedures";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

export default function ButtonDialogProcedureInToolbar({scene, caplaEditor}) {
  const isStagingEnv = useIsStagingEnv();

  // strings

  const title = "Repérages automatiques";

  //
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <DialogProcedures
          scene={scene}
          caplaEditor={caplaEditor}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
      <Box
        sx={{color: "common.white", display: isStagingEnv ? "flex" : "none"}}
      >
        <Tooltip title={title}>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(true)}
          >
            <Procedures fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}
