import {useState} from "react";
import {useDispatch} from "react-redux";

import {Box, Typography, Icon} from "@mui/material";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";

import DialogAddInputs from "Features/inputs/components/DialogAddInputs";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

export default function SectionNoInputAddInput({editor3d}) {
  const dispatch = useDispatch();
  console.log("[NoInput] show section");
  // strings

  const addS = "Glisser & déposer un plan au format PDF";
  const selectS = "Depuis mon ordinateur";

  // data

  const pageType = usePageType();
  const sceneModule = useSceneModule();

  // state

  const [isOver, setIsOver] = useState(false);
  const [tempFiles, setTempFiles] = useState([]);

  // helper - open

  const open = Boolean(tempFiles?.length > 0);

  // helper

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // helper - display

  const display = ["INPUTS", "PACKAGES", "ONBOARDING"].includes(sceneModule);

  /*
   * Drag & Drop
   */

  function handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(true);
  }

  function handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("Leave");
    setIsOver(false);
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleAddInputs(inputs) {
    console.log("[DEBUG] addInputs", inputs);
    if (inputs[0]) {
      dispatch(setSelectedPdfModelId(inputs[0].id));
    }
  }

  async function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(false);
    const files = [];
    if (e.dataTransfer.files instanceof FileList) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        const item = e.dataTransfer.items[i];
        files.push(item.getAsFile());
      }
      setTempFiles(files);
    }
  }

  function handleFilesChange(files) {
    setTempFiles(files);
  }

  function handleClose() {
    setTempFiles([]);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        //bgcolor: "common.caplaBlack",
        display: display ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
        ...(isOver && {background}),
      }}
    >
      <DialogAddInputs
        editor3d={editor3d}
        tempFiles={tempFiles?.length > 0 ? tempFiles : null}
        open={open}
        onClose={handleClose}
        onAddInputs={handleAddInputs}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "left",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            //color: "white",
            ...(!isOver && {bgcolor: "warning.main"}),
            p: 6,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              zIndex: 10,
              //border: (theme) => `1px dashed ${theme.palette.common.white}`,
            }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          />
          <Typography color="background.dark" textAlign="center">
            {addS}
          </Typography>
        </Box>
        <Box
          sx={{
            visibility: !isOver ? "visible" : "hidden",
            color: "text.secondary",
            mt: 1,
          }}
        >
          <FileSelectorButton
            onFileChange={handleFilesChange}
            buttonName={selectS}
            buttonVariant="inline"
            buttonColor="inherit"
            accept=".pdf"
            startIcon="computer"
            multiple={true}
          />
        </Box>
      </Box>
    </Box>
  );
}
