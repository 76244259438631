import {useDispatch, useSelector} from "react-redux";

import {Button, Box} from "@mui/material";

import {setSelectedProgressRecordId} from "../progressSlice";

export default function ButtonDataGridClearSelection() {
  const dispatch = useDispatch();

  // strings

  const clearS = "Clear selection";

  // data

  const id = useSelector((s) => s.progress.selectedProgressRecordId);

  // helpers

  const isEmpty = !Boolean(id);

  // handlers

  function handleClear() {
    dispatch(setSelectedProgressRecordId(null));
    //editor3d.sceneEditor.unselect();
  }

  // helpers

  return (
    <Box
      sx={{color: "text.secondary", visibility: isEmpty ? "hidden" : "visible"}}
    >
      <Button onClick={handleClear} size="small" color="inherit">
        {clearS}
      </Button>
    </Box>
  );
}
