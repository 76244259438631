import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import refreshGroupedItemsNums from "Utils/refreshGroupedItemsNums";

import {
  createRessourcesGroupService,
  fetchRessourcesGroupsService,
  updateRessourcesGroupService,
  deleteRessourcesGroupService,
} from "./services";

import getStateRessourcesGroupsFromRemoteItems from "./utils/getStateRessourcesGroupsFromRemoteItems";
import getStateRessourcesGroupFromRemoteItem from "./utils/getStateRessourcesGroupFromRemoteItem";

export const createRessourcesGroup = createAsyncThunk(
  "ressources/createRessourcesGroup",
  createRessourcesGroupService
);
export const fetchRessourcesGroups = createAsyncThunk(
  "ressources/fetchRessourcesGroups",
  fetchRessourcesGroupsService
);
export const updateRessourcesGroup = createAsyncThunk(
  "ressources/updateRessourcesGroup",
  updateRessourcesGroupService
);
export const deleteRessourcesGroup = createAsyncThunk(
  "ressources/deleteRessourcesGroup",
  deleteRessourcesGroupService
);

export const ressourcesSlice = createSlice({
  name: "ressources",
  initialState: {
    selectedRessourceIds: [],
    selectedRessourceId: null,

    selectedRessourceIdInEditMeasurements: null,
    selectedRessourceGroupProxyInEditMeasurements: null,

    lastRessourceId: null, // last created zone => used to select in the DataGrid the new zone.

    ressourcesGroups: [],
    selectedRessourceGroup: null, // "Budget","DPGF",..
    initRessourceGroups: [],
    tempGroup: "", // group created, with no articles.

    selectedRessourcesGroupProxy: null,

    isEditingRessource: false,
    editedRessource: null,
    newRessource: {},

    selectedFormGroup: "PROPS",

    tempRessourceTypes: [], // used when editing one ressource. [{id,resId,typeId,mode,func,status}],status: CREATE,UPDATE,DELETE
    noQuantities: true,

    datagridIsInEditMode: false,
    showReorderHelpers: false,
    editedRessources: [], // sceneRessources being edited. Change happen when saved. initiated with initEditRessources.
    openContextMenu: false,
    ressourcesWithPU: {},
  },
  extraReducers: {
    [createRessourcesGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[state] new ressourceGroup", item);
      const newGroup = {...item};
      newGroup.sceneId = item.scene;
      delete newGroup.scene;
      state.ressourcesGroups.push(newGroup);
    },
    [fetchRessourcesGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      const stateGroups = getStateRessourcesGroupsFromRemoteItems(items);
      state.ressourcesGroups = stateGroups;
    },
    [updateRessourcesGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[state] update scene ressourcesGroup", item);
      const ressourcesGroups = state.ressourcesGroups.filter(
        (g) => g.id !== item.id
      );
      state.ressourcesGroups = [
        ...ressourcesGroups,
        getStateRessourcesGroupFromRemoteItem(item),
      ];
      // handle rename
      if (state.selectedRessourcesGroupProxy?.id === item?.id) {
        state.selectedRessourcesGroupProxy.name = item.name;
      }
    },
    [deleteRessourcesGroup.fulfilled]: (state, action) => {
      console.log("[STATE] delete ressourcesGroup", action.payload);
      const {ressourcesGroupId} = action.payload;
      const ressourcesGroups = state.ressourcesGroups.filter(
        (g) => g.id !== ressourcesGroupId
      );
      state.ressourcesGroups = ressourcesGroups;
      state.selectedRessourcesGroupProxy = null;
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {ressourcesGroups} = action.payload;
      let groups = [];
      if (Array.isArray(ressourcesGroups)) {
        groups = ressourcesGroups.map((g) => ({...g, sceneId: g.scene}));
      }
      state.ressourcesGroups = groups;
    },
  },
  reducers: {
    setSelectedRessourcesGroupProxy: (state, action) => {
      state.selectedRessourcesGroupProxy = action.payload;
    },
    removeGroupFromInitRessourceGroups: (state, action) => {
      const group = action.payload;
      state.initRessourceGroups = state.initRessourceGroups.filter(
        (g) => g !== group
      );
    },
    updateGroupFromInitRessourceGroups: (state, action) => {
      const {oldGroup, newGroup} = action.payload;
      state.initRessourceGroups = state.initRessourceGroups.filter(
        (g) => g !== oldGroup
      );
      state.initRessourceGroups.push(newGroup);
    },
    setSelectedRessourceId: (state, action) => {
      state.selectedRessourceId = action.payload;
    },
    setSelectedRessourceIds: (state, action) => {
      const ids = action.payload;
      const id = Array.isArray(ids) && ids.length > 0 ? ids[0] : null;
      state.selectedRessourceId = id;
      state.selectedRessourceIds = ids;
    },
    addIdToSelectedRessourceIds: (state, action) => {
      const newId = action.payload;
      const newIds = [...new Set([...state.selectedRessourceIds, newId])];
      state.selectedRessourceIds = newIds;
      state.selectedRessourceId = newId;
    },
    setLastRessourceId: (state, action) => {
      state.lastRessourceId = action.payload;
    },
    setSelectedRessourceGroup: (state, action) => {
      state.selectedRessourceGroup = action.payload;
    },
    setSelectedRessourceIdInEditMeasurements: (state, action) => {
      state.selectedRessourceIdInEditMeasurements = action.payload;
    },
    setSelectedRessourceGroupProxyInEditMeasurements: (state, action) => {
      state.selectedRessourceGroupProxyInEditMeasurements = action.payload;
    },
    addGroupsToRessourceGroups: (state, action) => {
      const oldGroups = state.initRessourceGroups;
      const newGroups = action.payload;
      const groups = [...new Set([...oldGroups, ...newGroups])];

      state.initRessourceGroups = groups;
    },
    setIsEditingRessource: (state, action) => {
      state.isEditingRessource = action.payload;
    },
    setEditedRessource: (state, action) => {
      state.editedRessource = action.payload;
    },
    setNewRessource: (state, action) => {
      state.newRessource = action.payload;
    },
    setSelectedFormGroup: (state, action) => {
      state.selectedFormGroup = action.payload;
    },
    setTempRessourceTypes: (state, action) => {
      state.tempRessourceTypes = action.payload;
    },
    setTempGroup: (state, action) => {
      state.tempGroup = action.payload;
    },
    setNoQuantities: (state, action) => {
      state.noQuantities = action.payload;
    },
    setDatagridIsInEditMode: (state, action) => {
      state.datagridIsInEditMode = action.payload;
    },
    setOpenContextMenu: (state, action) => {
      state.openContextMenu = action.payload;
    },
    setShowReorderHelpers: (state, action) => {
      state.showReorderHelpers = action.payload;
    },
    // edited ressources
    setEditedRessources: (state, action) => {
      const ressources = action.payload;
      state.editedRessources = [...ressources];
    },
    addRessourceToEditedRessources: (state, action) => {
      // we add the ressource after the selected ressource.
      const ressource = action.payload;
      let editedRessources = [...state.editedRessources];
      const selectedRessourceIndex = editedRessources
        .map((r) => r.id)
        .indexOf(state.selectedRessourceId);
      editedRessources.splice(selectedRessourceIndex + 1, 0, ressource);
      if (state.selectedRessourceGroup) {
        editedRessources = refreshGroupedItemsNums(
          editedRessources,
          state.selectedRessourceGroup
        );
      }
      state.editedRessources = editedRessources;
    },
    updateRessourceInEditedRessources: (state, action) => {
      const {ressource, updateNums} = action.payload;
      let newEditedRessources = state.editedRessources.map((r) => {
        if (r.id !== ressource.id) {
          return r;
        } else {
          return ressource;
        }
      });
      if (updateNums)
        newEditedRessources = refreshGroupedItemsNums(
          newEditedRessources,
          ressource.group
        );
      state.editedRessources = newEditedRessources;
    },
    moveEditedRessource: (state, action) => {
      const {ressourceId, prevRessourceId, updateNums, isFirstItem} =
        action.payload;

      const editedRessources = [...state.editedRessources];
      const ressource = editedRessources.find((r) => r.id === ressourceId);
      let newEditedRessources = editedRessources.filter(
        (r) => r.id !== ressourceId
      );

      if (isFirstItem) {
        newEditedRessources = [ressource, ...newEditedRessources];
      } else {
        const prevIndex = newEditedRessources
          .map((r) => r.id)
          .indexOf(prevRessourceId);
        newEditedRessources.splice(prevIndex + 1, 0, ressource);
      }

      if (updateNums)
        newEditedRessources = refreshGroupedItemsNums(
          newEditedRessources,
          ressource.group
        );
      state.editedRessources = newEditedRessources;
    },
    deleteRessourceInEditedRessources: (state, action) => {
      const ressourceId = action.payload;
      state.editedRessources = state.editedRessources.filter(
        (r) => r.id !== ressourceId
      );
    },
    setRessourcesWithPU: (state, action) => {
      const {excelGroup, resPU} = action.payload;
      state.ressourcesWithPU[excelGroup] = resPU;
    },
  },
});

export const {
  setSelectedRessourceId,
  setSelectedRessourceIds,
  addIdToSelectedRessourceIds,
  setLastRessourceId,
  setSelectedRessourceGroup,
  addGroupsToRessourceGroups,
  setIsEditingRessource,
  setEditedRessource,
  setNewRessource,
  setSelectedFormGroup,
  setTempRessourceTypes,
  setTempGroup,
  setNoQuantities,
  setDatagridIsInEditMode,
  setOpenContextMenu,
  // editedREssources
  setEditedRessources,
  addRessourceToEditedRessources,
  updateRessourceInEditedRessources,
  moveEditedRessource,
  deleteRessourceInEditedRessources,
  setShowReorderHelpers,
  setRessourcesWithPU,
  removeGroupFromInitRessourceGroups,
  updateGroupFromInitRessourceGroups,
  setSelectedRessourceGroupProxyInEditMeasurements,
  setSelectedRessourcesGroupProxy,
  setSelectedRessourceIdInEditMeasurements,
} = ressourcesSlice.actions;

export default ressourcesSlice.reducer;
