function removeLastNItems(arrayA, N) {
  if (N <= 0) return arrayA; // If N is less than or equal to 0, return the original array
  if (N >= arrayA.length) return []; // If N is greater than or equal to the length of the array, return an empty array

  const newLength = arrayA.length - N;
  const arrayB = arrayA.slice(0, newLength);
  return arrayB;
}

function getNextBrother(num) {
  const numArray = num.split(".");
  const suffix = numArray.pop();
  const nextSuffix = (Number(suffix) + 1).toString();
  return [...numArray, nextSuffix].join(".");
}

function getNumParent(num, rankDiff) {
  const numArray = num.split(".");
  const parentNumArray = removeLastNItems(numArray, rankDiff);
  const parentNum = parentNumArray.join(".");
  return parentNum;
}

function getRank(num) {
  return num.split(".").length;
}

export default function getItemsWithNumsFromItemsWithWeights(
  itemsWithWeights,
  firstNum
) {
  // itemsWithWeights = [{..., weight:1}, {..., weight:1},   {..., weight:2},...]
  // itemsWithNums = [{..., num:firstNum}, {..., num:},   {..., num:},...]

  // the weight specifies the relative position of the items.

  // examples of num = "1.1.1", "1.1", "4.1.1". Nums are used to specify positions of items as in a tree.
  // For example, "3.1" is the parent of "3.1.1".

  // the first item num is firstNum.

  // itemsWithWeights are originally sorted as the should appear if the sortByNum function is used with the itemsWithNums.

  // the function compute one by one the num of each item in itemsWithWeights, taking into account :
  // - the weight of the item and the previous item, and their difference (diff = weight - previousWeight)
  //    - if diff = 0, the item is a brother of the previous item. To get the num of the item, we just increment the last number of the previous num.
  //    - if diff < 0, the item is a child of the previous item. To get the num of the item, we just add a ".1" to the previous num.
  //    - if diff > 0 then (
  //        > the item is the brother of one parent (lets called it parentA) of the previous item.
  //        > let's call the rank of one item the number of "." in its num.
  //        > we need to determine if parentA is the parent, the parent's parent, .... of the previous item. To do so based on the diff value BUT we stop when parentA rank = firstNum rank.
  //      )

  // Note 1 : rank and weight are different concepts. 2 items can have the same rank but different weights.
  // Note 2 : when looking for the parent of the previous item in the diff>0 case, we look for the parent, then the parent's parent,... but we should stop when the parent's rank = the firstNum rank.

  // example 1 :
  // - itemsWithWeights = [{id:1,weight:3},{id:2,weight:1},{id:3,weight:3}]
  // - firstNum = "6"
  // => itemsWithNums [{id:1,weight:3,num:"6"},{id:2,weight:1, num:"6.1"},{id:3,weight:3,num:"7"}]

  const itemsWithNums = [];

  itemsWithWeights.forEach((itemWithWeight, index) => {
    if (index === 0) {
      itemsWithNums.push({...itemWithWeight, num: firstNum});
    } else {
      const previousItemWithNum = itemsWithNums[index - 1];
      const previousNum = previousItemWithNum.num;
      const diff = itemWithWeight.weight - previousItemWithNum.weight;

      let itemNum;
      if (diff == 0) {
        itemNum = getNextBrother(previousNum);
      } else if (diff < 0) {
        itemNum = previousNum + ".1";
      } else {
        const maxDiff = getRank(previousNum) - getRank(firstNum);
        const rankDiff = Math.min(diff, maxDiff);
        const parentNum = getNumParent(previousNum, rankDiff);
        itemNum = getNextBrother(parentNum);
      }
      itemsWithNums.push({...itemWithWeight, num: itemNum});
    }
  });

  //
  const testedItems = itemsWithNums.map((item) => ({
    weight: item.weight,
    num: item.num,
  }));
  console.log("testedItems", testedItems);
  //
  return itemsWithNums;
}
