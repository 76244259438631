import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import useListingtypes from "Features/listingtypes/useListingtypes";

import {Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {DataGrid, frFR} from "@mui/x-data-grid";

import {parseISO, format} from "date-fns";

import {openingsTable} from "../data/openingsSample";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  toolbarContainer: {
    width: "100%",
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  dataGridContainer: {
    width: "100%",
    height: "100%",
  },
  dataGrid: {
    background: "#ffffff",
  },
}));

export default function ObjectsDataGrid({
  objects = openingsTable,
  onSelectionChange = console.log,
  checkboxSelection = false,
}) {
  const classes = useStyles();

  // local state

  const [selectionModel, setSelectionModel] = useState([]);

  // data

  const rows = objects.slice();

  // helper

  function _getDateTime(dateData) {
    const date = new Date(dateData);
    const formattedDate = format(date, "dd/MM/yyyy");
    return `${formattedDate}`;
  }

  const columns = [
    {field: "sector", headerName: "Secteur", width: 200},
    {field: "typeName", headerName: "Type", width: 200},
    {field: "reference", headerName: "Rérérence", width: 200},
  ];

  // handlers

  function handleSelectionChange(newSelection) {
    setSelectionModel(newSelection.selectionModel);
    onSelectionChange(newSelection.selectionModel);
  }

  return (
    <div className={classes.root}>
      <div className={classes.dataGridContainer}>
        <DataGrid
          className={classes.dataGrid}
          rows={rows}
          columns={columns}
          localeText={frFR.props.MuiDataGrid.localeText}
          density="compact"
          checkboxSelection={checkboxSelection}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectionModel}
        />
      </div>
    </div>
  );
}
