import {useSelector} from "react-redux";

import {Box, Slider} from "@mui/material";

import AngleLabel from "./components/AngleLabel";

export default function SliderCamVerticalAngle() {
  // data

  const angle = useSelector((s) => s.viewer3D.cam2Polar);

  // helpers

  const marksAngles = [-90, -45, 0, 45, 90];
  const marks = marksAngles.map((a) => ({value: a, label: `${a}°`}));
  const min = -90.1;
  const max = 90.1;

  return (
    <Box
      onPointerMove={(e) => e.stopPropagation()}
      sx={{
        height: 1,
        //bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
        py: 1,
        width: 20,
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.black",
      }}
    >
      <Slider
        marks={marks}
        onPointerMove={(e) => e.stopPropagation()}
        orientation="vertical"
        size="small"
        min={min}
        max={max}
        value={angle}
        valueLabelDisplay="auto"
        components={{ValueLabel: AngleLabel}}
        sx={{
          color: "common.black",
          mr: 0,
          "& .MuiSlider-rail": {
            color: "common.black",
            opacity: 0.5,
          },
          "& .MuiSlider-mark": {
            height: 2,
            width: 8,
          },
          "& .MuiSlider-markLabel": {
            fontSize: 10,
            left: -10,
          },
          "& .MuiSlider-thumb": {
            height: 4,
            width: 14,
            borderRadius: 0,
            color: "rgb(250,180,31)",
            backgroundColor: "#fff",
            border: "2px solid currentColor",
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
              boxShadow: "inherit",
            },
            "&:before": {
              display: "none",
            },
          },
        }}
      />
    </Box>
  );
}
