import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import useSelection from "./useSelection";
//import SectionClickedObjectInViewer from "./SectionClickedObjectInViewer";

import {setLastSelectionType} from "Features/navigation/navigationSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import {
  setSelectedModelId,
  setSelectedIfcElement,
} from "Features/viewer3D/viewer3DSlice";
// import {setSelectedMeasurementId} from "Features/measurements/measurementsSlice";

export default function useSelectionFromClickedObject({scene, editorPdf}) {
  const dispatch = useDispatch();

  // data

  const selection = useSelection({scene, editorPdf});

  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);

  // const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  const models = useSelector((s) => s.viewer3D.models);
  // const selectedModel = models.find((m) => m.id === selectedModelId);

  // helpers - clicked model

  const clickedModel = models?.find((m) => m.id === clickedObject?.modelId);

  // effect - clicked => selected

  useEffect(() => {
    if (!clickedObject.modelId) {
      dispatch(setSelectedIfcElement(null));
      dispatch(setSelectedModelId(null));
      dispatch(setSelectedZoneId(null));
      // dispatch(setSelectedMeasurementId(null));
      //dispatch(setSelectedMeasurementIds([]));
      dispatch(setLastSelectionType(null));
    } else if (clickedObject.type === "MEASUREMENT") {
      dispatch(setSelectedModelId(clickedObject.modelId));
      // dispatch(setSelectedMeasurementId(clickedObject.entityID));
      //dispatch(updateSelectedMeasurementIds([clickedObject.entityID]));
      dispatch(setLastSelectionType("MEASUREMENT"));
    } else if (
      clickedModel?.type === "IMAGE" &&
      clickedModel.fromModel?.zoneId
    ) {
      console.log(
        "debug 20-06 setSelectedZoneId",
        clickedModel.fromModel?.zoneId
      );
      dispatch(setSelectedZoneId(clickedModel.fromModel?.zoneId));
      dispatch(setSelectedModelId(clickedModel.id));
      dispatch(setLastSelectionType("ZONE"));
    } else if (clickedModel?.type === "IFC") {
      dispatch(setSelectedModelId(clickedModel.id));
      dispatch(setSelectedIfcElement(clickedObject.data));
      dispatch(setLastSelectionType("IFC_ELEMENT"));
    }
  }, [
    clickedModel?.id,
    clickedObject?.data?.expressID,
    clickedObject?.entityID,
  ]);

  return selection;
}
