import {useState, useEffect} from "react";

import {fetchScopesService} from "Features/scopes/services";

import {
  Button,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tooltip,
} from "@mui/material";
import {Share} from "@mui/icons-material";

import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

import useShareLink from "Features/scenes/useShareLink";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonDialogShare({scene, size}) {
  const accessToken = useAccessToken();

  // strings

  const shareS = "Partage";

  const title = "Partager votre bimbox";

  const description = `Toute personne en possession de ce lien pourra consulter en lecture seule votre bimbox.
  Copiez collez ce lien dans votre outil de communication favori.`;

  const copyS = "Copier le lien";

  // state

  const [open, setOpen] = useState(false);
  const [sharedboxes, setSharedboxes] = useState(false);

  // data

  const selectedScope = useSelectedScope();

  let shareLink = useShareLink({scene, propDependency: open});
  if (!shareLink) shareLink = "xxxxx x x  x x x x x ";

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers - actions

  function handleCopy() {
    navigator.clipboard.writeText(shareLink);
    setOpen(false);
  }

  // effect - fetch sceneScopes

  async function getScopes() {
    try {
      if (accessToken && open && scene?.id) {
        const result = await fetchScopesService({
          accessToken,
          sceneId: scene?.id,
        });
        console.log("result", result.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  }
  useEffect(() => {
    getScopes();
  }, [open, accessToken, scene?.id]);

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      {size === "small" ? (
        <Tooltip title={title}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              boxSizing: "content-box",
              border: (theme) =>
                `1px solid ${lighten(theme.palette.common.caplaBlack, 0.5)}`,
              bgcolor: lighten(theme.palette.common.caplaBlack, 0.2),
              color: lighten(theme.palette.common.caplaBlack, 0.5),
              width: 24,
              height: 24,
            }}
          >
            <IconButton size="small" onClick={handleOpenDialog} color="inherit">
              <Share fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          sx={{
            bgcolor: "primary.flash",
            height: "28px",
            borderRadius: "14px",
            fontSize: 12,
          }}
          startIcon={<Share sx={{fontSize: 12}} />}
          onClick={handleOpenDialog}
        >
          {shareS}
        </Button>
      )}

      <Dialog open={open} onClose={handleCloseDialog} maxWidth={false}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
              {description}
            </Typography>
            <Box sx={{bgcolor: "background.default", px: 1, width: 1, mt: 2}}>
              <Typography variant="body2" color="text.secondary">
                {shareLink}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopy}>{copyS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
