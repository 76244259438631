import {useEffect} from "react";
import {useSelector} from "react-redux";

export default function useUpdateMeasurementsDataManager(caplaEditor) {
  // data

  const enabledPackageIdsInMainScope = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );

  const modelIdsInMeasDataManager = useSelector(
    (s) => s.overviewer.modelIdsInMeasDataManager
  );

  // helpers

  const mnger = caplaEditor?.measDataManager;
  const loader = caplaEditor?.editor3d?.loader;

  const enabledHash = enabledPackageIdsInMainScope
    ? [...enabledPackageIdsInMainScope].sort().join()
    : null;

  // helpers - can set models in shortMemory

  const canSetModelsInShorMemory =
    Boolean(mnger) && Boolean(loader) && modelIdsInMeasDataManager?.length > 0;

  // update

  useEffect(() => {
    if (enabledHash && canSetModelsInShorMemory) {
      mnger.setModelsInShortMemory(enabledPackageIdsInMainScope);
    } else {
      mnger.setModelsInShortMemory([]);
    }
  }, [canSetModelsInShorMemory, enabledHash]);
}
