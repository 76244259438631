import {useSelector} from "react-redux";

export default function useIsDrawing() {
  let isDrawing = false;

  const annotCreationTool = useSelector(
    (s) => s.measurements.annotCreationTool
  );

  if (annotCreationTool) {
    isDrawing = true;
  }

  return isDrawing;
}
