import React from "react";

import {useSelector} from "react-redux";
import {Box, LinearProgress, Typography} from "@mui/material";

import {getFileSizeString} from "Features/files/utils";

export default function SectionLoadingPdf({pdfModel}) {
  // data

  const modelsSyncStatus = useSelector((s) => s.viewer3D.modelsSyncStatus);

  // helper - progress

  const status = modelsSyncStatus.find((s) => s.modelId === pdfModel?.id);

  let progress = status?.downloadingProgress
    ? status.downloadingProgress * 100
    : 0;

  // helper

  const label = `${pdfModel?.name}  [ ${getFileSizeString(
    pdfModel?.fileSize
  )} ]`;

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* <TestFlex /> */}
      <LinearProgress
        //size={16}
        variant={progress > 0 ? "determinate" : "indeterminate"}
        value={progress}
        color={progress > 0 ? "inherit" : "primary"}
        sx={{width: 240}}
      />
      <Typography color="inherit" sx={{mt: 1, fontSize: 13}}>
        {label}
      </Typography>
    </Box>
  );
}
