import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchElementTypesGroups} from "Features/elementTypes/elementTypesSlice";
import {useParams} from "react-router-dom";
import useAccessToken from "Features/auth/useAccessToken";
import useIsStagingEnv from "./useIsStagingEnv";

export default function useInitFetchElementTypesGroups() {
  const isStagingEnv = useIsStagingEnv();
  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken && sceneId && isStagingEnv) {
      dispatch(fetchElementTypesGroups({sceneId, accessToken}));
    }
  }, [sceneId, accessToken]);
}
