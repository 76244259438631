// worksheet 2 = rapport détaillé
function containsAnyLetters(str) {
  return /[a-zA-Z]/.test(str);
}

function numProxyToNum(numProxy) {
  return numProxy.replaceAll(",", ".");
}

function getParentNum(num) {
  if (!num) return;
  let parent = num.split(".");
  parent.pop();
  parent = parent.join(".");
  return parent;
}

function getUnitFromString(string) {
  switch (string) {
    case "Longueur (m)":
      return "ML";
    case "Nombre d'objets":
      return "UN";
    case "Surface (m²)":
      return "M2";
  }
}

function getUnitSFromUnit(unit) {
  switch (unit) {
    case "ML":
      return "ml";
    case "UN":
      return "u";
    case "M2":
      return "m²";
  }
}

function isTitle(row, style) {
  const name = row[1];
  if (!name || typeof name === "number") return;
  const nameC = name.toUpperCase();
  const hasLetters = containsAnyLetters(name);
  return (
    name === nameC &&
    hasLetters &&
    !style.font.italic &&
    !style.font.underline &&
    row.length === 2
  );
}

function isArticle(row, style) {
  const name = row[1];
  if (!name || typeof name === "number") return;
  const nameC = name.toUpperCase();
  return (
    name !== nameC &&
    !style.font.italic &&
    !style.font.underline &&
    row.length === 2
  );
}

// article proxy : extension used as an article. use case : voids
function isArticleProxy(row, style) {
  const rawName = row[1];
  const num = extractNum(rawName);
  const numProxy = extractNumProxy(rawName);

  return isExtension(row, style) && (num || numProxy);
}

function isExtension(row, style) {
  return style.font.italic && style.font.underline;
}

function getRowType(row) {
  const name = row[1];
  const num = extractNum(name);

  if (isTitle(row)) return "TITLE";
}

function extractExtensionParameter(name) {
  if (!name) return;
  let extensionParameter = "";
  if (name.includes("Longeur associée")) extensionParameter = "LENGTH";
  if (name.includes("Hauteur associée")) extensionParameter = "HEIGHT";
}

function extractNum(name) {
  if (!name || typeof name === "number") return;
  let num;
  // 1.2.3 xxx

  // reject 1,1,...
  const reg = /^(\d+,)+/gm;
  const f = name.match(reg);
  if (f?.length > 0) return;

  const regex = /^(\d+\.?)+/gm;
  //const regex = /^(\d+\..)+/gm;
  const found = name.match(regex);
  if (found?.length > 0) {
    num = found[0];
  }
  return num;
}

function extractNumProxy(name) {
  if (!name || typeof name === "number") return;
  let num;
  // 1,2,3 xxx
  const regex = /^(\d+,.)+/gm;
  const found = name.match(regex);

  if (found?.length > 0) {
    num = found[0];
  }
  return num;
}

function extractHeight(name) {
  let heightS, heightN;
  const regex = /\((\d+)\)$/g;
  const found = name.match(regex);
  if (found?.length > 0) {
    heightS = found[0];
    heightN = parseFloat(heightS.replace("(", "").replace(")", ""));
  }
  return {heightS, heightN};
}

function removeNumAndHeightFromName(name, num, height) {
  let _name = name.replace(num, "");
  _name = _name.replace(height, "");
  _name = _name.trim();
  return _name;
}

function removeNumFromName(num, name) {
  if (!name) return;
  let _name = name.replace(num, "");
  _name = _name.trim();
  return _name;
}
function removeStringFromName(string, name) {
  if (!name) return;
  let _name = name.replace(string, "");
  _name = _name.trim();
  return _name;
}

function nextUnit(unit) {
  switch (unit) {
    case "": {
      return "ml";
    }
    case "u": {
      return "ml";
    }
    case "m": {
      return "m²";
    }
  }
}

function articleHasExtension(data, articleRawIndex, nextArticleRawIndex) {
  let has = false;
  let isProxy = false;
  let extensionRawIndex;
  for (let i = articleRawIndex; i < nextArticleRawIndex; i += 1) {
    const {rowValues: row, rowStyle: style} = data[i];
    if (isExtension(row, style)) {
      has = true;
      extensionRawIndex = i;
      isProxy = isArticleProxy(row, style);
    }
  }
  return {has, extensionRawIndex, isProxy};
}

function getNextArticleRawIndex(articles, articleRawIndex) {
  let next = 0;
  const articleLastIndex = articles.length - 1;
  articles.forEach((article, index) => {
    if (article.rawIndex > articleRawIndex) {
      if (
        next === 0 &&
        (article.type === "ARTICLE" || article.type === "ARTICLE_EMPTY")
      )
        next = article.rawIndex;
    }
  });
  if (next === 0) next = articles[articleLastIndex].rawIndex;
  return next;
}

function getArticles(data) {
  const articles = [];

  const dataMaxIndex = data.length - 1;
  data.forEach(({rowValues: row, rowStyle: style}, index) => {
    let type, num, numProxy, name, rawName, uniqueName;
    let heightS, heightN;
    let row_plus1, row_plus3;
    let name_plus1, name_plus3;
    if (index + 1 <= dataMaxIndex) {
      row_plus1 = data[index + 1];
      name_plus1 = row_plus1[1];
    }
    if (index + 3 <= dataMaxIndex) {
      row_plus3 = data[index + 3];
      name_plus3 = row_plus3[1];
    }
    if (isTitle(row, style)) {
      type = "TITLE";
      name = row[1];
      rawName = row[1];
      uniqueName = name;
      articles.push({rawIndex: index, type, name, rawName, uniqueName});
    } else if (isArticle(row, style)) {
      rawName = row[1];
      num = extractNum(rawName);
      if (num) {
        type = "ARTICLE";
        const height = extractHeight(rawName);
        heightS = height.heightS;
        name = removeNumFromName(num, rawName);
        console.log("heightS", heightS, height);
        if (heightS) name = removeStringFromName(heightS, name);
        uniqueName = getParentNum(num) + " " + name;
        articles.push({rawIndex: index, type, num, name, rawName, uniqueName});
      } else {
        type = "ARTICLE_EMPTY";
        articles.push({rawIndex: index, type, rawName});
      }
    } else if (isArticleProxy(row, style)) {
      type = "ARTICLE_PROXY";
      rawName = row[1];
      num = extractNum(rawName);
      numProxy = extractNumProxy(rawName);
      if (num) name = removeNumFromName(num, rawName);
      if (numProxy) name = removeNumFromName(numProxy, rawName);
      if (!num && numProxy) num = numProxyToNum(numProxy);
      uniqueName = getParentNum(num) + " " + name;
      articles.push({rawIndex: index, type, num, name, rawName, uniqueName});
    }
  });

  return articles;
}

function getArticleWithQuantities(data) {
  let articles = getArticles(data);

  articles = articles.map((article) => {
    let unit, unitS, quantity;

    const articleRawIndex = article.rawIndex;
    const nextArticleRawIndex = getNextArticleRawIndex(
      articles,
      articleRawIndex
    );
    const {has, extensionRawIndex, isProxy} = articleHasExtension(
      data,
      articleRawIndex,
      nextArticleRawIndex
    );

    if (article.type === "TITLE" || article.type === "ARTICLE_EMPTY") {
      return article;
    } else if (article.type === "ARTICLE" && (!has || isProxy)) {
      const row_plus1 = data[article.rawIndex + 1];
      const row_plus2 = data[article.rawIndex + 2];
      unit = getUnitFromString(row_plus1.rowValues[1]);
      if (unit === "UN") quantity = row_plus2.rowValues[1];
      if (unit === "ML") quantity = row_plus2.rowValues[3];
      if (unit === "M2") quantity = row_plus2.rowValues[3];
      unitS = getUnitSFromUnit(unit);
      return {...article, unit, unitS, quantity};
    } else if (article.type === "ARTICLE" && has && !isProxy) {
      const row_label = data[extensionRawIndex + 3];
      const row_value = data[extensionRawIndex + 4];
      unit = getUnitFromString(row_label.rowValues[1]);
      const v1 = row_value.rowValues[1];
      const v3 = row_value.rowValues[3];
      if (unit === "UN") quantity = v1;
      if (unit === "ML") quantity = v3 ? v3 : v1;
      if (unit === "M2") quantity = v3 ? v3 : v1;
      unitS = getUnitSFromUnit(unit);

      return {...article, unit, unitS, quantity};
    } else if (article.type === "ARTICLE_PROXY") {
      const row_label = data[article.rawIndex + 3];
      const row_value = data[article.rawIndex + 4];
      unit = getUnitFromString(row_label.rowValues[1]);
      const v1 = row_value.rowValues[1];
      const v3 = row_value.rowValues[3];
      if (unit === "UN") quantity = v1;
      if (unit === "ML") quantity = v3 ? v3 : v1;
      if (unit === "M2") quantity = v3 ? v3 : v1;
      unitS = getUnitSFromUnit(unit);
      return {...article, unit, unitS, quantity};
    }
  });
  return articles.filter((article) => article.type !== "ARTICLE_EMPTY");
}

export default function rawDataToObjectsWorksheet2(data) {
  return getArticleWithQuantities(data);
}
