import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import {useSelector} from "react-redux";

export default function useMeasurementsProgressForDataGrid(scene) {
  const [measurements] = useFilteredSceneMeasurements();

  const allRecords = useSelector((s) => s.progress.items);

  const selectedProgressListingId = useSelector(
    (s) => s.progress.selectedProgressListingId
  );

  // all

  let measurementsProgress = useSelector(
    (s) => s.progress.measurementsProgress
  );

  // filters

  measurementsProgress = measurementsProgress.filter(
    (m) => m.progressListingId === selectedProgressListingId
  );

  // add names

  measurementsProgress = measurementsProgress.map((mp) => {
    const measurement = measurements.find((m) => m.id === mp.measurementId);
    const record = allRecords.find((r) => r.id === mp.progressRecordId);
    return {
      id: measurement.id + "-" + record.id,
      code: measurement.codeName,
      record: record.name,
      progress: mp.progress,
    };
  });

  return measurementsProgress;
}
