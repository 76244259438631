import theme from "Styles/theme";

export default function getStyleFromItem(item) {
  let style = {};
  //
  if (item.style === "title1") {
    style.bg = theme.palette.excel.bgTitle1;
    style.bold = true;
  } else if (item.style === "title2") {
    style.bg = theme.palette.excel.bgTitle2;
    style.bold = true;
  } else if (item.style === "overview") {
    style.bold = true;
    style.underline = true;
  } else if (item.style === "element") {
    style.bold = true;
  } else if (item.style === "section") {
    style.underline = true;
  } else if (item.style === "detail") {
    style.alignToRight = true;
  } else if (item.style === "subDetail") {
    style.alignToRight = true;
    style.italic = true;
    style.color = theme.palette.excel.colorSubDetail;
  }
  //
  if (item.isAdded && item.style !== "subDetail") {
    style.color = theme.palette.excel.colorIsAdded;
  }
  //
  if (item.isNomenclature) {
    style.alignToRight = true;
    style.italic = true;
    style.underline = true;
    style.color = theme.palette.excel.colorNomenclature;
  }
  return style;
}
