import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import {useSelector} from "react-redux";

export default function useSelectedElementTypeFromMeasurements(scene) {
  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );
  const selectedElementType = elementTypes.find(
    (t) => t.id === selectedElementTypeId
  );

  return selectedElementType;
}
