import useUpdateSceneElementType from "Features/elementTypes/hooks/useUpdateSceneElementType";

export default function useAddPoiToElementType(scene) {
  //
  const updateElementType = useUpdateSceneElementType(scene);

  const addPoiToElementType = async ({poi, elementType}) => {
    const newElementType = {...elementType};
    if (elementType.pois) {
      newElementType.pois = [...elementType.pois, poi];
    } else {
      newElementType.pois = [poi];
    }
    await updateElementType(newElementType);
  };

  return addPoiToElementType;
}
