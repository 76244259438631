export default function getMeasurementsFromFilters(
  measurements,
  filtersMap = {}
) {
  if (!Array.isArray(measurements)) return;

  const filteredMeasurements = [];
  const tabs = [
    "PACKAGES",
    "ZONES",
    "ELEMENT_TYPES",
    "RESSOURCES",
    "SECTORS",
    "ROOMS",
    "PHASES",
  ];

  measurements.forEach((measurement) => {
    if (measurement && !measurement.deleted) {
      let include = true;
      let index = 0;
      while (include && index < tabs.length - 1) {
        const tab = tabs[index];
        const filters = filtersMap[tab];
        if (filters && Array.isArray(filters?.ids)) {
          if (tab === "ELEMENT_TYPES") {
            if (!filters.ids.includes(measurement.elementTypeId)) {
              include = false;
            }
          }
        }
        index += 1;
      }
      // PACKAGES

      // ZONES

      // ELEMENT_TYPES

      // RESSOURCES

      // SECTORS

      // ROOMS

      // PHASES

      // return
      if (include) filteredMeasurements.push(measurement);
    }
  });

  return filteredMeasurements;
}
