import {useDispatch} from "react-redux";

import {updateOrganisation} from "../organisationsSlice";

import {Box} from "@mui/material";
import EditableImage from "Features/images/components/EditableImage";

import useAccessToken from "Features/auth/useAccessToken";

export default function ContainerEditableLogo({organisation}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // helpers

  const logoUrl = organisation?.logoUrl;

  const organisationId = organisation?.id;

  const container = "organisation";

  const blobPrefix = `logo_`;

  // handlers

  function handleUrlChange(url) {
    console.log("newUrl", url);
    dispatch(
      updateOrganisation({accessToken, organisationId, updates: {logoUrl: url}})
    );
  }

  //
  return (
    <Box
      sx={{
        width: 400,
        height: 200,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <EditableImage
        url={logoUrl}
        onChange={handleUrlChange}
        container={container}
        blobPrefix={blobPrefix}
        organisationId={organisationId}
      />
    </Box>
  );
}
