import {useSelector} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

export default function useScenesAreLoaded() {
  const accessToken = useAccessToken();
  const remoteScenesStatus = useSelector((s) => s.scenes.remoteScenes.status);

  const fetchScenesStatus = useSelector((s) => s.scenes.fetchScenesStatus);

  return (
    (!accessToken || (accessToken && remoteScenesStatus !== "idle")) &&
    fetchScenesStatus !== "idle"
  );
}
