import {useDispatch} from "react-redux";
import {setScopeIdToOpen, setOpenDialogFsSelectScope} from "../overviewerSlice";

import {Button, Box} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";

export default function ButtonBackToSelectScope() {
  const dispatch = useDispatch();

  // string

  const label = "Voir toutes les sous-bimboxes";

  // handlers

  function handleClick() {
    dispatch(setScopeIdToOpen(null));
    dispatch(setOpenDialogFsSelectScope(true));
  }
  return (
    <Box sx={{bgcolor: "common.caplaBlack", color: "common.white"}}>
      <Button
        startIcon={<ArrowBack />}
        size="small"
        color="inherit"
        onClick={handleClick}
      >
        {label}
      </Button>
    </Box>
  );
}
