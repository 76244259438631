import React from "react";

import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";

export default function SelectorAutocompleteMultiple({
  name,
  value,
  options,
  onChange,
  disabled,
}) {
  // handlers

  function handleOptionChange(value) {
    onChange(value);
  }

  return (
    <Box sx={{width: 1, p: 1, pt: 2}}>
      <Autocomplete
        sx={{flexGrow: 1}}
        disabled={disabled}
        options={options}
        multiple
        limitTags={2}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name}
        renderOption={(props, option, {selected}) => (
          <li {...props}>
            <Checkbox
              size="small"
              checked={selected}
              sx={{mr: 1, height: "14px", width: "14px"}}
            />
            <Typography variant="body2" sx={{pl: 1}}>
              {option.name}
            </Typography>
          </li>
        )}
        value={value}
        onChange={(e, v) => handleOptionChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={<Typography variant="body2">{name}</Typography>}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: 13,
              },
            }}
          />
        )}
      />
    </Box>
  );
}
