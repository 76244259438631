export default function getMeasCountPackageIdMapFromMeasurements(measurements) {
  const map = {};
  measurements.forEach((measurement) => {
    const count = map[measurement.measurementsModelId];
    if (!count) {
      map[measurement.measurementsModelId] = 1;
    } else {
      map[measurement.measurementsModelId] += 1;
    }
  });
  return map;
}
