import {createSlice} from "@reduxjs/toolkit";

export const measurementsAutoSlice = createSlice({
  name: "measurementsAuto",
  initialState: {
    isPickingColor: false,
    isPickingColorFromCanvas: false,
    pickedColor: null,
    pickedColorFromCanvas: null,
    autoMeasureZone: {}, // {pageCoords,pageRotation,url}

    greyUrl: null,
    colorUrl: null,
    morphedUrl: null,
    contoursUrl: null,

    filteredGreyUrl: null,
    withoutCrumbsUrl: null,
    withoutThinLinesUrl: null,

    transformedUrl: null,
    transformedUrlLast: null,
    zonesUrl: null,
    linesUrl: null,
    lastTransformation: null, // "FILTER_GREY","REMOVE_THIN_LINES", "REMOVE_CRUMBS"

    morphSize: 20,
    morphIteration: 1,
    contoursApprox: 0.1,

    polylinesEditorIsOpened: false,
    polylinesEditorIsLoaded: false,
    polylines: [],
    selectedPolylineIndex: null,

    popupWindowPosition: {},
    showPopup: false,
  },
  reducers: {
    setAutoMeasureZone: (state, action) => {
      state.autoMeasureZone = action.payload;
    },
    setIsPickingColor: (state, action) => {
      state.isPickingColor = action.payload;
    },
    setIsPickingColorFromCanvas: (state, action) => {
      state.isPickingColorFromCanvas = action.payload;
    },
    setPickedColor: (state, action) => {
      state.pickedColor = action.payload;
    },
    setPickedColorFromCanvas: (state, action) => {
      state.pickedColorFromCanvas = action.payload;
    },
    // image process
    setGreyUrl: (state, action) => {
      state.greyUrl = action.payload;
    },
    setColorUrl: (state, action) => {
      state.colorUrl = action.payload;
    },
    setMorphedUrl: (state, action) => {
      state.morphedUrl = action.payload;
    },
    setContoursUrl: (state, action) => {
      state.contoursUrl = action.payload;
    },
    // image transformations
    setFilteredGreyUrl: (state, action) => {
      state.filteredGreyUrl = action.payload;
    },
    setWithoutCrumbsUrl: (state, action) => {
      state.withoutCrumbsUrl = action.payload;
    },
    setWithoutThinLinesUrl: (state, action) => {
      state.withoutThinLinesUrl = action.payload;
    },
    setTransformedUrl: (state, action) => {
      state.transformedUrl = action.payload;
    },
    setTransformedUrlLast: (state, action) => {
      state.transformedUrlLast = action.payload;
    },
    setZonesUrl: (state, action) => {
      state.zonesUrl = action.payload;
    },
    setLinesUrl: (state, action) => {
      state.linesUrl = action.payload;
    },
    setLastTransformation: (state, action) => {
      state.lastTransformation = action.payload;
    },
    // polyline editor
    setPolylinesEditorIsOpened: (state, action) => {
      state.polylinesEditorIsOpened = action.payload;
    },
    setPolylinesEditorLoaded: (state, action) => {
      state.polylinesEditorIsLoaded = action.payload;
    },
    setPolylines: (state, action) => {
      state.polylines = action.payload;
    },
    deletePolyline: (state, action) => {
      const index = action.payload;
      state.polylines = state.polylines.filter((p) => p.index !== index);
    },
    setMorphSize: (state, action) => {
      state.morphSize = action.payload;
    },
    setMorphIteration: (state, action) => {
      state.morphIteration = action.payload;
    },
    setContoursApprox: (state, action) => {
      state.contoursApprox = action.payload;
    },
    setSelectedPolylineIndex: (state, action) => {
      state.selectedPolylineIndex = action.payload;
    },
    // popup context menu
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setPopupPosition: (state, action) => {
      state.popupPosition = action.payload;
    },
  },
});

export const {
  setAutoMeasureZone,
  setIsPickingColor,
  setIsPickingColorFromCanvas,
  setPickedColor,
  setPickedColorFromCanvas,
  setColorUrl,
  setGreyUrl,
  setMorphedUrl,
  setContoursUrl,
  setPolylinesEditorIsOpened,
  setPolyinesEditorIsLoaded,
  setPolylines,
  deletePolyline,
  setMorphSize,
  setMorphIteration,
  setContoursApprox,
  setSelectedPolylineIndex,

  setFilteredGreyUrl,
  setWithoutCrumbsUrl,
  setWithoutThinLinesUrl,

  setTransformedUrl,
  setTransformedUrlLast,
  setLastTransformation,
  setZonesUrl,
  setLinesUrl,

  setShowPopup,
  setPopupPosition,
} = measurementsAutoSlice.actions;

export default measurementsAutoSlice.reducer;
