import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {updateRemoteScene} from "./scenesSlice";
import {
  updateRemoteModel,
  createRemoteModel,
  updateModelsSyncStatus,
  updateScenesSyncStatus,
} from "Features/viewer3D/viewer3DSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {useClientAndRemoteSceneModels} from "./useClientAndRemoteSceneModels";

export default function useSyncActions(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const clientAndRemoteSceneModels = useClientAndRemoteSceneModels(scene);

  // state

  const [trigger, setTrigger] = useState();

  // helpers

  const syncActions = clientAndRemoteSceneModels.filter(
    (sceneModels) => sceneModels.nextSync?.action
  );

  const triggerSyncAction = (accessToken) => async (action, force) => {
    if (action.actionType === "updateRemoteScene") {
      dispatch(
        updateScenesSyncStatus({
          sceneClientId: action.sceneClientId,
          isUploading: true,
        })
      );
      await dispatch(
        updateRemoteScene({
          sceneClientId: action.sceneClientId,
          accessToken,
        })
      );
      dispatch(
        updateScenesSyncStatus({
          sceneClientId: action.sceneClientId,
          isUploading: false,
        })
      );
    } else if (action.actionType === "createRemoteModel") {
      dispatch(
        updateModelsSyncStatus({modelId: action.modelId, isUploading: true})
      );
      await dispatch(
        createRemoteModel({
          accessToken,
          sceneId: action.sceneId,
          modelId: action.modelId,
        })
      );
      dispatch(
        updateModelsSyncStatus({modelId: action.modelId, isUploading: false})
      );
    } else if (
      action.actionType === "updateRemoteModel" &&
      (force || action?.clientVersion >= action?.remoteVersion)
    ) {
      console.log("[useSyncActions] updateRemoteModel", action.modelId);
      dispatch(
        updateModelsSyncStatus({modelId: action.modelId, isUploading: true})
      );
      await dispatch(
        updateRemoteModel({
          accessToken,
          sceneId: action.sceneId,
          modelId: action.modelId,
        })
      );
      dispatch(
        updateModelsSyncStatus({modelId: action.modelId, isUploading: false})
      );
    }
  };

  useEffect(() => {
    if (accessToken) {
      setTrigger(() => triggerSyncAction(accessToken));
    }
  }, [accessToken]);

  return [syncActions, trigger];
}
