import {useState, useEffect} from "react";
import useAccessToken from "Features/auth/useAccessToken";
import {fetchSceneMembershipsService} from "Features/scenes/services";

export default function useSceneMemberships(sceneId, propDependency) {
  const accessToken = useAccessToken();

  const [memberships, setMemberships] = useState([]);

  async function getMembers() {
    const scene = await fetchSceneMembershipsService({
      accessToken,
      sceneId,
    });
    setMemberships(scene?.userMemberships);
  }

  useEffect(() => {
    if (accessToken && sceneId) getMembers();
  }, [accessToken, propDependency, sceneId]);

  return memberships;
}
