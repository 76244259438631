import useSceneMeasurements from "Features/measurements/useSceneMeasurements";

export default function useMaterialsBySceneWithMsts(scene) {
  // data
  const measurements = useSceneMeasurements(scene);
  //
  let materials = scene?.data?.materials;
  if (!materials) materials = [];
  materials = materials.filter((r) => r?.id);
  //
  materials = materials.map((r) => {
    const msts = measurements.filter((m) => m.materialId === r.id);
    return {...r, msts};
  });
  //
  return materials;
}
