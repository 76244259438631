import {Box, Typography, Icon, Tooltip} from "@mui/material";
import {Layers as Packages} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

export default function ContainerPackagesCount({count, color}) {
  const label = `x${count}`;
  const title = `Nombre de calques : x${count}`;

  let bgcolor = lighten(theme.palette.common.caplaBlack, 0.2);

  return (
    <Tooltip title={title}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "common.white",
          bgcolor,
          px: 1,
          minWidth: "content",
          height: 24,
          borderRadius: 12,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Packages sx={{fontSize: 14}} />
        </Box>
        <Typography sx={{fontSize: 12, ml: 1}} noWrap>
          {label}
        </Typography>
      </Box>
    </Tooltip>
  );
}
