import {useSelector} from "react-redux";
export default function useSelectedMarker() {
  const models = useSelector((s) => s.viewer3D.models);
  const selectedMarkerProps = useSelector((s) => s.markers.selectedMarker);

  const model = models.find(
    (model) => model.id === selectedMarkerProps?.markersModelId
  );

  const markers = model?.elements?.items;

  return markers?.find(({id}) => id === selectedMarkerProps?.markerId);
}
