import {useSelector, useDispatch} from "react-redux";

import {Box, Typography, TextField} from "@mui/material";

import {setTempInput} from "Features/inputs/inputsSlice";
import {setTempPdfModelId} from "Features/pdf/pdfSlice";
import useDispatchSetBestView from "Features/ui/useDispatchSetBestView";
import FileSelectorButton from "Features/files/components/FileSelectorButton";
import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";

export default function FormTempInput({editor3d}) {
  const dispatch = useDispatch();

  // string

  const selectFileS = "Choisir un fichier";

  const messageAdd = "Ajoutez un fichier source dans votre bimbox.";

  const nameS = "Nom d'usage";
  const messageName = "Donnez un nom d'usage à ce fichier.";

  // data

  const tempInput = useSelector((s) => s.inputs.tempInput);
  const dispatchSetBestView = useDispatchSetBestView(
    "grid_pdf_selection",
    true
  );

  // helper

  const fileName = tempInput?.fileName ? tempInput.fileName : "";
  const name = tempInput?.name ? tempInput?.name : "";

  // helper - style

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  async function handleFileChange(file) {
    const fileUrl = URL.createObjectURL(file);
    const fileName = file.name;
    dispatch(setTempInput({...tempInput, fileUrl, fileName}));

    const type = getModelTypeFromFile(file);
    const model = await editor3d?.loader.loadFile({
      file,
      type,
      options: {name: fileName, type: "TEMP_PDF"},
    });
    dispatch(setTempPdfModelId(model?.id));
    dispatchSetBestView();
  }

  function handleNameChange(e) {
    const name = e.target.value;
    dispatch(setTempInput({...tempInput, name}));
  }

  return (
    <Box sx={{width: 1, p: 2}}>
      <Box
        sx={{
          mt: 4,
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          p: 3,
          bgcolor: "background.default",
          borderRadius: "4px",
          background,
          color: "common.white",
        }}
      >
        {!fileName && (
          <Box>
            <Typography variant="body2">{messageAdd}</Typography>
          </Box>
        )}
        {fileName && (
          <Box>
            <Typography variant="body2">{fileName}</Typography>
          </Box>
        )}
        <Box sx={{mt: 2}}>
          <FileSelectorButton
            accept=".pdf, .ifc, .glb"
            buttonName={selectFileS}
            onFileChange={handleFileChange}
            buttonVariant="outlined"
            buttonColor="inherit"
          />
        </Box>
      </Box>

      {fileName && (
        <Box sx={{p: 1, mt: 2}}>
          <Typography variant="body2" sx={{mb: 2}}>
            {messageName}
          </Typography>
          <TextField
            fullWidth
            label={<Typography variant="body2">{nameS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={name}
            onChange={handleNameChange}
          />
        </Box>
      )}
    </Box>
  );
}
