import sortByNum from "Features/elementTypes/utils/sortByNum";

export default function reduceObjects(rawObjects) {
  const objects = {};

  let titleIndex;
  rawObjects.forEach((object, index) => {
    if (object.type === "TITLE") {
      objects[object.uniqueName] = {
        ...object,
        num: "0",
        titleIndex: object.rawIndex,
      }; // for final sorting
      titleIndex = object.rawIndex;
    } else {
      const mainObject = objects[object.uniqueName];
      if (mainObject) {
        objects[object.uniqueName] = {
          ...mainObject,
          quantity: mainObject.quantity + object.quantity,
        };
      } else {
        objects[object.uniqueName] = {...object, titleIndex};
      }
    }
  });

  const objectsA = Object.entries(objects).map(([key, value]) => value);

  console.log("objectsA", objectsA);
  const result = objectsA
    .filter((o) => !o.rawName.includes("LogiKutch"))
    .sort((a, b) => sortByNum(a.num, b.num))
    .sort((a, b) => a.titleIndex - b.titleIndex);

  return result;
  //.filter((o) => !o.uniqueName.includes("LogiKutch"))
  //.sort((a, b) => a.rawIndex - b.rawIndex);
}
