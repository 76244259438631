import {useState} from "react";

import {Box} from "@mui/material";

import FormCreateDifference from "./components/FormCreateDifference";
import ListDifference from "./components/ListDifference";

export default function ToolDifference({scene, editor3d}) {
  const [diffs, setDiffs] = useState();

  function handleDiffsCreate(newDiffs) {
    setDiffs(newDiffs);
  }

  function handleListClose() {
    setDiffs(null);
  }
  return (
    <Box>
      {diffs ? (
        <ListDifference
          diffs={diffs}
          onClose={handleListClose}
          editor3d={editor3d}
        />
      ) : (
        <FormCreateDifference
          editor3d={editor3d}
          scene={scene}
          onCreate={handleDiffsCreate}
        />
      )}
    </Box>
  );
}
