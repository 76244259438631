import React, {useEffect, useState} from "react";

import useWorksite from "Features/worksites/useWorksite";
import {useDispatch} from "react-redux";
import {updateScene, updateRemoteScene} from "Features/scenes/scenesSlice";

import {Box, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {
  OpenWith as Translate,
  ThreeDRotation as Rotate,
  Save,
} from "@mui/icons-material";

import ContainerPosition from "./components/ContainerPosition";
import ContainerRotation from "./components/ContainerRotation";
import SectionLatLngDeltaBase from "./components/SectionLagLngDeltaBase";
import SectionMarkerBase from "./components/SectionMarkersBase";

import useAccessToken from "Features/auth/useAccessToken";

export default function FormLocateSceneInWorksite({scene, editor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Locate the bimbox in the worksite";

  const offsetPositionTitle = "Offset - position";
  const offsetRotationTitle = "Offet - rotation";

  const saveString = "Save";

  // state

  const initPosition = scene?.offset?.position;
  const initRotation = scene?.offset?.rotation;

  const [position, setPosition] = useState(initPosition);
  const [rotation, setRotation] = useState(initRotation);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof initPosition?.x === "number" && !isNaN(initPosition?.x)) {
      setPosition(initPosition);
      setRotation(initRotation);
    }
  }, [
    initPosition?.x,
    initPosition?.y,
    initPosition?.z,
    initRotation?.x,
    initRotation?.y,
    initRotation?.z,
  ]);

  // helpers

  function radToDeg(rot) {
    if (typeof rot?.x !== "number") return;
    const {x, y, z} = rot;
    const s = 180 / Math.PI;
    return {x: x * s, y: y * s, z: z * s};
  }

  function degToRad(rot) {
    if (typeof rot?.x !== "number") return;
    const {x, y, z} = rot;
    const s = Math.PI / 180;
    return {x: x * s, y: y * s, z: z * s};
  }
  // handlers

  function handlePositionChange(p) {
    setPosition(p);
  }
  function handleRotationChange(p) {
    setRotation(p);
  }

  // handlers - latlng base

  function handleLatLngBaseUpdate(delta, rot) {
    const newPosition = {...position, x: delta.x, z: delta.z};
    setPosition(newPosition);
    const newR = {...rotation, y: -(rot * Math.PI) / 180};
    setRotation(newR);
  }

  // handlers - markers base

  function handleMarkersUpdate(delta) {
    const newPosition = {...position, x: delta.offsetP?.x, z: delta.offsetP?.z};
    setPosition(newPosition);
    const newR = {...rotation, y: (delta.offsetR * Math.PI) / 180};
    setRotation(newR);
  }

  // handlers - save

  async function handleSaveClick() {
    try {
      const newScene = {
        ...scene,
        offset: {rotation, position},
      };
      setLoading(true);
      await dispatch(updateScene({scene: newScene}));
      await dispatch(
        updateRemoteScene({sceneClientId: newScene.clientId, accessToken})
      );
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box>
      <Typography variant="body2">
        <b>{title}</b>
      </Typography>
      <Box sx={{display: "flex", alignItems: "center", mt: 2, mb: 2}}>
        <Translate sx={{mr: 1, fontSize: 16}} color="action" />
        <Typography variant="body2">{offsetPositionTitle}</Typography>
      </Box>

      <ContainerPosition
        position={position}
        onPositionChange={handlePositionChange}
      />

      <Box sx={{display: "flex", alignItems: "center", mt: 2, mb: 2}}>
        <Rotate sx={{mr: 1, fontSize: 16}} color="action" />
        <Typography variant="body2">{offsetRotationTitle}</Typography>
      </Box>

      <ContainerRotation
        rotation={rotation}
        onRotationChange={handleRotationChange}
      />
      <Box sx={{mt: 2}}>
        <SectionLatLngDeltaBase
          scene={scene}
          editor={editor}
          onSave={handleLatLngBaseUpdate}
        />
      </Box>

      <Box sx={{mt: 2}}>
        <SectionMarkerBase
          scene={scene}
          editor={editor}
          onSave={handleMarkersUpdate}
        />
      </Box>

      <Box sx={{width: 1, mt: 2, display: "flex", justifyContent: "flex-end"}}>
        <LoadingButton
          startIcon={<Save />}
          loading={loading}
          loadingPosition="start"
          onClick={handleSaveClick}
          variant="outlined"
          size="small"
        >
          {saveString}
        </LoadingButton>
      </Box>
    </Box>
  );
}
