import {Paper, IconButton, Tooltip} from "@mui/material";
import {ZoomOutMap as ZoomOut} from "@mui/icons-material";

export default function ButtonRefreshPdfInViewer({editorPdf}) {
  // strings

  const zoomOutS = "Zoom page";

  // handler

  function handleClick() {
    editorPdf?.refreshZoom();
  }
  return (
    <Paper
      elevation={1}
      sx={{
        p: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 34,
        width: 34,
      }}
    >
      <Tooltip title={zoomOutS}>
        <IconButton sx={{borderRadius: "4px"}} onClick={handleClick}>
          <ZoomOut fontSize="small" />
        </IconButton>
      </Tooltip>
    </Paper>
  );
}
