import sortByNum from "./sortByNum";

export default function versionsForDataGrid(versions) {
  function getRank(num) {
    return num ? num.split(".").length : null;
  }

  const ranks = [
    ...new Set(versions.map((r) => getRank(r.num)).filter((rank) => rank)),
  ];
  const rankMin = Math.min(...ranks);

  return versions
    .map((version) => ({
      ...version,
      rank: getRank(version.num) - rankMin + 1,
    }))
    .sort((a, b) => sortByNum(a.num, b.num));
}
