import React from "react";

import {Box, Typography} from "@mui/material";
import ImageBasic from "Features/images/components/ImageBasic";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function FieldImage({
  locked,
  name,
  value,
  onChange,
  height = 140,
}) {
  // image = {url,name}

  // strings

  const selectS = "Choisir une image";

  // handlers

  function handleFileChange(file) {
    const url = URL.createObjectURL(file);
    const name = file.name;
    onChange({url, name});
  }

  return (
    <Box
      sx={{
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{width: 1, bgcolor: "background.default", px: 1, py: 0.5}}>
        <Typography sx={{fontSize: 13}}>{name}</Typography>
      </Box>
      <Box sx={{width: 1, height, position: "relative"}}>
        <ImageBasic url={value?.url} />
        {!locked && (
          <Box
            sx={{
              position: "absolute",
              bottom: "8px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <FileSelectorButton
              onFileChange={handleFileChange}
              buttonName={selectS}
              buttonVariant="contained"
              accept=".png,.jpeg,.jpg"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
