import React from "react";

import {Box, TextField, Typography} from "@mui/material";

export default function FormNewProgressListing({listing, onChange}) {
  // strings

  const nameString = "Name";
  const descriptionString = "Description";
  const defaultName = "Avancement";

  // local state

  const name = listing?.name;
  const description = listing?.description;

  // handlers

  function handleNameChange(e) {
    const newName = e.target.value;
    onChange({...listing, name: newName});
  }

  function handleDescriptionChange(e) {
    const newDescription = e.target.value;
    onChange({...listing, description: newDescription});
  }

  return (
    <Box sx={{p: 1, display: "flex", flexDirection: "column"}}>
      <TextField
        value={name}
        autoFocus
        onChange={handleNameChange}
        label={<Typography variant="body2">{nameString}</Typography>}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        sx={{mb: 2, mt: 1}}
      />
      <TextField
        value={description}
        onChange={handleDescriptionChange}
        label={<Typography variant="body2">{descriptionString}</Typography>}
        variant="outlined"
        size="small"
        multiline
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        sx={{mb: 1}}
      />
    </Box>
  );
}
