import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import debounce from "lodash.debounce";

import SectionListings from "./components/SectionListings";
import SectionListingDetail from "./components/SectionListingDetail";

import BoxResizable from "Components/BoxResizable";
import {setDataSectionWidth} from "Features/viewer3D/viewer3DSlice";
import useSceneListings from "Features/listings/useSceneListings";
// import useListings from "Features/listings/useListings";

export default function SectionDatasets({scene, caplaEditor, viewer}) {
  const dispatch = useDispatch();

  const width = useSelector((state) => state.viewer3D.dataSectionWidth);
  const show = useSelector((state) => state.viewer3D.rightSection);

  // data

  const listingId = useSelector((state) => state.viewer3D.sceneListingId);
  // const listings = useListings();
  // const sceneListing = listings.find((l) => l?.id === listingId);
  const sceneListing = useSceneListings(scene?.id);

  // handlers

  function handleWidthChange(width) {
    dispatch(setDataSectionWidth(width));
  }

  // helpers

  const display = show ? "box" : "none";

  return (
    <Box
      sx={{
        height: 1,
        bgcolor: "background.default",
        display,
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <BoxResizable
        width={width}
        onChange={debounce(handleWidthChange, 20)}
        left={true}
        right={false}
      >
        {listingId ? (
          <SectionListingDetail
            scene={scene}
            caplaEditor={caplaEditor}
            viewer={viewer}
            listing={sceneListing}
          />
        ) : (
          <SectionListings scene={scene} />
        )}
      </BoxResizable>
    </Box>
  );
}
