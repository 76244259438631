import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormMaterial from "./FormMaterial";

import {setEditedMaterial, setNewMaterial} from "../materialsSlice";
import useSelectedMaterial from "../hooks/useSelectedMaterial";

export default function SectionMaterialInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // data

  const selectedMaterial = useSelectedMaterial(scene);
  const isEditingMaterial = useSelector(
    (s) => s.materials.isEditingMaterial
  );
  const editedMaterial = useSelector((s) => s.materials.editedMaterial);

  const newMaterial = useSelector((s) => s.materials.newMaterial);

  // handlers

  function handleMaterialChange(material) {
    dispatch(setEditedMaterial(material));
  }

  function handleNewMaterialChange(material) {
    dispatch(setNewMaterial(material));
  }

  // helper

  const material = isEditingMaterial ? editedMaterial : selectedMaterial;

  return (
    <Box sx={{width: 1}}>
      {selectedMaterial ? (
        <>
          <FormMaterial
            scene={scene}
            material={material}
            onChange={handleMaterialChange}
            canEdit={isEditingMaterial}
          />
        </>
      ) : (
        <FormMaterial
          scene={scene}
          material={newMaterial}
          onChange={handleNewMaterialChange}
          canEdit={true}
        />
      )}
    </Box>
  );
}
