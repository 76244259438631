import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItemButton,
  // ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  Close,
} from "@mui/icons-material";

import ListItemRoom from "./ListItemRoom";
import sortByNum from "Utils/sortByNum";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import SelectorRoomsGroup from "./SelectorRoomsGroup";
import {setSelectedRoomsGroupId} from "../roomsSlice";

export default function ListRoomsMultipleWithGroups({
  scene,
  caplaEditor,
  rooms = [],
  roomsGroups = [],
  selectedRoomIds,
  multipleSelection,
  onSelectionChange,
  selectedRoomsGroupId,
  onSelectedRoomsGroupIdChange, // {id,name,fromScene}
  resetSelectionOnGroupChange = true,
  forceSelection, // if true, return one default selection
  maxHeightGroups,
  variant = "chips",
}) {
  const dispatch = useDispatch();

  // strings

  const groupS = "Arborescence de pièces";
  const groupsS = "Arborescences";

  // helpers

  const filteredRooms = rooms.filter(
    (r) => !selectedRoomsGroupId || r.roomsGroupId === selectedRoomsGroupId
  );

  let selectedRoomsGroup = roomsGroups.find(
    (g) => g.id === selectedRoomsGroupId
  );

  if (!selectedRoomsGroup)
    selectedRoomsGroup = {
      id: "undefined",
      name: "Sélectionnez une arborescence",
    };

  // init - forceSelection

  useEffect(() => {
    if (forceSelection && !selectedRoomsGroupId && roomsGroups.length > 0) {
      const id0 = roomsGroups[0].id;
      dispatch(setSelectedRoomsGroupId(id0));
    }
  }, [selectedRoomsGroupId, roomsGroups.length]);

  // state

  const [openGroups, setOpenGroups] = useState(false);

  // handlers

  function handleGroupClick(group, options) {
    //
    const closeGroupsList = options?.closeGroupsList;
    //
    if (group?.id && selectedRoomsGroupId !== group?.id) {
      // used to avoid to have one hidden selection...
      if (resetSelectionOnGroupChange) onSelectionChange([]);
    }
    onSelectedRoomsGroupIdChange(group.id);
    //
    if (closeGroupsList) setOpenGroups(false);
  }

  function handleClick(room) {
    console.log("click on room", room);
    const id = room?.id;
    const idIsSelected = selectedRoomIds?.includes(id);
    let newIds = selectedRoomIds?.filter((typeId) => typeId !== id);
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (room.isTitle) {
        return;
      } else {
        if (!selectedRoomIds?.includes(id)) newIds.push(id);
      }
    } else {
      newIds = idIsSelected ? [] : [id];
    }
    if (onSelectionChange) onSelectionChange(newIds);
  }

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: 0}}
    >
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={roomsGroups}
            selectedItem={selectedRoomsGroup}
            onClick={handleGroupClick}
            maxHeight={maxHeightGroups}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "secondary.main",
                p: 0.5,
                px: 1,
                visibility: openGroups ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: openGroups ? "common.white" : "secondary.main",
              }}
            >
              <Box
                onClick={() => setOpenGroups((open) => !open)}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: "text.secondary",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {!openGroups ? selectedRoomsGroup?.name : groupsS}
                </Typography>
                {!openGroups ? <Down /> : <Close color="inherit" />}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {!openGroups && (
        <Box
          sx={{
            bgcolor: "common.white",
            overflowY: "auto",
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <List dense disablePadding>
            {filteredRooms
              .sort((a, b) => sortByNum(a.num, b.num))
              .map((room) => {
                let selected = false;
                if (Array.isArray(selectedRoomIds))
                  selected = selectedRoomIds?.includes(room?.id);
                return (
                  <ListItemButton
                    divider
                    disableGutters
                    sx={{p: 0}}
                    key={room?.id}
                    selected={selected}
                    onClick={() => handleClick(room)}
                  >
                    <ListItemRoom
                      key={room.id}
                      scene={scene}
                      room={room}
                      selected={selected}
                      //onClick={handleClick}
                    />
                  </ListItemButton>
                );
              })}
            {/* <ListItemButton dense disabled>
              <ListItemText primary={"-"} />
            </ListItemButton> */}
          </List>
        </Box>
      )}
      {openGroups && variant === "list" && (
        <Box sx={{overflowY: "auto"}}>
          <SelectorRoomsGroup
            items={roomsGroups}
            selectedItem={selectedRoomsGroup}
            onClick={handleGroupClick}
          />
        </Box>
      )}
    </Box>
  );
}
