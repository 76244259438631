import React from "react";

import ListIconModelType from "Features/viewer3D/components/ListIconModelType";

import ListIconListingType from "Features/listingtypes/components/ListIconListingType";

export default function ListIconDatasetType({
  isModel,
  isListing,
  type,
  unselected,
}) {
  return (
    <>
      {isModel && <ListIconModelType type={type} unselected={unselected} />}
      {isListing && (
        <ListIconListingType listingType={type} unselected={unselected} />
      )}
    </>
  );
}
