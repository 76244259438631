import {Box, Typography} from "@mui/material";
import {common} from "@mui/material/colors";
import theme from "Styles/theme";

import SelectionBox from "./SelectionBox";
import ButtonExpand from "./ButtonExpand";

export default function ItemGroup({
  groupId,
  name,
  count,
  expand,
  onExpandChange,
  disableExpand,
  showMode,
  onShowModeChange,
  disableShowModeButton,
  hideShowButton,
  variant,
  isPdfLayout,
}) {
  // handlers

  function handleShowModeChange({itemId, showMode}) {
    if (onShowModeChange) onShowModeChange(showMode);
  }

  function handleExpandChange(expand) {
    onExpandChange(expand);
  }
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        mt: 0.5,
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      {!hideShowButton && !isPdfLayout && (
        <SelectionBox
          itemId={groupId}
          color={theme.palette.common.caplaBlack}
          showMode={showMode ? showMode : "OFF"}
          onShowModeChange={handleShowModeChange}
          hide={disableShowModeButton}
        />
      )}
      <Box
        sx={{
          flexGrow: 1,
          px: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: variant === "light" ? "common.white" : "common.caplaBlack",
          color: variant === "light" ? "grey" : "common.white",
          py: 0.25,
          borderBottom: (theme) => `1px solid ${theme.palette.common.white}`,
        }}
      >
        <Typography sx={{fontSize: 13}} color="inherit">
          {name}
        </Typography>
        {!disableExpand && !isPdfLayout && (
          <ButtonExpand expand={expand} onExpandChange={handleExpandChange} />
        )}
      </Box>
    </Box>
  );
}
