import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, InputBase} from "@mui/material";

export default function BlockEditableArticleCode({article}) {
  const dispatch = useDispatch();

  const placeholder = "XXX";
  const label = "Code article";

  // state

  let initCode = article?.code;
  if (!initCode) initCode = "";

  const [code, setCode] = useState(initCode);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setCode(initCode);
  }, [initCode]);

  // handlers

  function handleChange(e) {
    e.stopPropagation();
    e.preventDefault();
    let newCode = e.target.value;
    setCode(newCode);
  }

  function handleBlur() {
    setIsEditing(false);
    let newCode = code.trim();
    if (newCode === "") newCode = null;
    if ((newCode && newCode !== article.code) || (!newCode && article.code)) {
      dispatch(updateArticleTemp({id: article.id, code}));
    }
  }

  function handleFocus() {
    setIsEditing(true);
  }

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Typography sx={{fontSize: 12, color: "text.secondary", mr: 1}}>
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "30px",
          bgcolor: "background.default",
          px: 1,
        }}
      >
        <InputBase
          disableUnderline
          sx={{
            color: isEditing ? "primary.main" : "text.secondary",
            fontSize: 12,
          }}
          value={code}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={handleClick}
        />
      </Box>
    </Box>
  );
}
