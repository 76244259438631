import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton,
  IconButton,
} from "@mui/material";
import {Launch} from "@mui/icons-material";

import useSelectedPdfModel from "../useSelectedPdfModel";

import {setSelectedZoneId} from "Features/zones/zonesSlice";
import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function SectionPdfZonesInSelectionPanel({scene}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // strings

  // const title = "Fonds de plan";

  // data

  const selectedPdfModel = useSelectedPdfModel();
  // const models = useSelector((s) => s.viewer3D.models);

  // helpers

  let sceneId = scene?.id;
  if (!sceneId) sceneId = scene?.clientId;
  let zones = selectedPdfModel.zones;
  if (!zones) zones = [];

  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  // helpers - noZone

  // const noZones = zones.length === 0;

  // handlers

  function handleLaunchZone(zone) {
    navigate(`/bimboxes/${sceneId}/zones`);
    dispatch(setSelectedZoneId(zone.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{display: "flex", alignItems: "center", color: "text.secondary"}}
      >
        {iconMap["ZONES"]}
        <Typography sx={{ml: 1}} variant="body2">
          {nameMap["ZONES"]}
        </Typography>
      </Box>

      <List dense>
        {zones.map((zone) => (
          <ListItem
            key={zone.id}
            disablePadding
            secondaryAction={
              <IconButton onClick={() => handleLaunchZone(zone)} size="small">
                <Launch fontSize="small" />
              </IconButton>
            }
          >
            <ListItemButton>
              <Typography variant="body2">{zone.name}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
