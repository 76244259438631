import {Box3, Vector3} from "three";

export default function fitOrthoCameraToImageModelObject(
  camera,
  entity,
  canvasSize,
  controls
) {
  //

  console.log("entity position", entity.object.position);
  const cameraP = new Vector3(0, 0, 10);
  //entity.object.localToWorld(cameraP);
  cameraP.add(entity.object.position);
  console.log("camera position", cameraP);

  const aspect = canvasSize.width / canvasSize.height;
  const width = entity.model.width;
  const height = width / aspect;
  camera.position.copy(cameraP);

  camera.left = -width / 2;
  camera.right = width / 2;
  camera.top = height / 2;
  camera.bottom = -height / 2;

  camera.updateProjectionMatrix();

  controls.target.copy(entity.object.position);
  controls.update();
}
