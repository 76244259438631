import {Vector3} from "three";

function getNormalFromPoints(a, b, c) {
  const pA = new Vector3();
  const pB = new Vector3();
  const pC = new Vector3();

  const cb = new Vector3();
  const ab = new Vector3();

  const [ax, ay, az] = a;
  const [bx, by, bz] = b;
  const [cx, cy, cz] = c;

  pA.set(ax, ay, az);
  pB.set(bx, by, bz);
  pC.set(cx, cy, cz);

  cb.subVectors(pC, pB);
  ab.subVectors(pA, pB);
  cb.cross(ab);

  cb.normalize();
  return [cb.x, cb.y, cb.z];
}

function ns(a, b, c) {
  const normal = getNormalFromPoints(a, b, c);
  return [...normal, ...normal, ...normal];
}

export default function getBoxVerticesFromPoints(points) {
  const [a1, a2, a3, a4, b1, b2, b3, b4] = points;

  const v1 = [...a1, ...a4, ...a2];
  const n1 = ns(a1, a4, a2);
  const v2 = [...a2, ...a4, ...a3];
  const n2 = ns(a2, a4, a3);

  const v3 = [...b1, ...b2, ...b3];
  const n3 = ns(b1, b2, b3);
  const v4 = [...b1, ...b3, ...b4];
  const n4 = ns(b1, b3, b4);

  const v5 = [...a2, ...b3, ...b2];
  const n5 = ns(a2, b3, b2);
  const v6 = [...a2, ...a3, ...b3];
  const n6 = ns(a2, a3, b3);

  const v7 = [...a4, ...b3, ...a3];
  const n7 = ns(a4, b3, a3);
  const v8 = [...b4, ...b3, ...a4];
  const n8 = ns(b4, b3, a4);

  const v9 = [...a1, ...b1, ...b4];
  const n9 = ns(a1, b1, b4);
  const v10 = [...a1, ...b4, ...a4];
  const n10 = ns(a1, b4, a4);

  const v11 = [...a1, ...a2, ...b2];
  const n11 = ns(a1, a2, b2);
  const v12 = [...a1, ...b2, ...b1];
  const n12 = ns(a1, b2, b1);

  const positions = [
    ...v1,
    ...v2,
    ...v3,
    ...v4,
    ...v5,
    ...v6,
    ...v7,
    ...v8,
    ...v9,
    ...v10,
    ...v11,
    ...v12,
  ];
  const normals = [
    ...n1,
    ...n2,
    ...n3,
    ...n4,
    ...n5,
    ...n6,
    ...n7,
    ...n8,
    ...n9,
    ...n10,
    ...n11,
    ...n12,
  ];

  return {positions, normals};
}
