import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {updateIssue} from "../issuesSlice";

import {LoadingButton} from "@mui/lab";
import {
  TaskAlt as IsNotOpened,
  RadioButtonUnchecked as IsOpened,
} from "@mui/icons-material";
import {Box} from "@mui/system";

import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonToggleIsOpenedInDataGrid({
  id,
  _listingId,
  opened,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const openS = "Open";
  const closeS = "Close";

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const label = opened ? closeS : openS;
  const icon = opened ? <IsNotOpened /> : <IsOpened />;

  // handlers

  async function handleClick() {
    setLoading(true);
    const newIssue = {id, listingId: _listingId, isOpened: !opened};
    await dispatch(updateIssue({accessToken, issue: newIssue}));
    setLoading(false);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <LoadingButton
        startIcon={icon}
        loadingPosition="start"
        loading={loading}
        onClick={handleClick}
        size="small"
        color={!opened ? "inherit" : "primary"}
      >
        {label}
      </LoadingButton>
    </Box>
  );
}
