import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import useUpdateShortcutElementTypesFromLeftPanel from "../hooks/useUpdateShortcutElementTypesFromLeftPanel";

import {Box, Paper} from "@mui/material";

import HeaderInLeftPanel from "./HeaderInLeftPanel";
//import ListItemLeftPanel from "./ListItemLeftPanel";
import SectionRessourcesInLeftPanelInViewer from "./SectionRessourcesInLeftPanelInViewer";
import SectionSceneInLeftPanelInViewer from "./SectionSceneInLeftPanelInViewer";

import useSceneModule from "Features/navigation/useSceneModule";
import SelectorRessourcesGroup from "Features/ressources/components/SelectorRessourcesGroup";

import SectionMeasurementsInLeftPanelV2 from "./SectionMeasurementsInLeftPanelV2";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import SectionMeasurementsFilters from "Features/measurements/components/SectionMeasurementsFilters";
import ScreenHelperDatagrid from "./ScreenHelperDatagrid";
import SectionIssuesInLeftPanel from "Features/issues/components/SectionIssuesInLeftPanel";

import ListSceneSectors from "./ListSceneSectors";

import PanelFilters from "Features/filters/components/PanelFilters";
import PanelPackages from "Features/elementPackages/components/PanelPackages";

import SectionOnboardingInLeftPanel from "Features/onboarding/components/SectionOnboardingInLeftPanel";
import SectionPackagesInLeftPanel from "Features/elementPackages/components/SectionPackagesInLeftPanel";
import SectionElementTypesInLeftPanel from "Features/elementTypes/components/SectionElementTypesInLeftPanel";
import SectionRessourcesInLeftPanel from "Features/ressources/components/SectionRessourcesInLeftPanel";
import SectionArticlesInLeftPanel from "Features/articles/components/SectionArticlesInLeftPanel";
import SectionSubHeaderInLeftPanel from "./SectionSubHeaderInLeftPanel";
import SectionLeftPanelHandel from "./SectionLeftPanelHandel";
import SectionMarkersInLeftPanel from "Features/markers/components/SectionMarkersInLeftPanel";
import SectionEditMeasurementsInLeftPanelV2 from "./SectionEditMeasurementsInLeftPanelV2";
import SectionInputsInLeftPanel from "Features/inputs/components/SectionInputsInLeftPanel";
import TopBarOverviewInLeftPanel from "Features/navigation/components/TopBarOverviewInLeftPanel";

import HeaderInLeftPanelOnboarding from "Features/onboarding/components/HeaderInLeftPanelOnboarding";
import HeaderInLeftPanelPackage from "Features/elementPackages/components/HeaderInLeftPanelPackage";

import SectionLeftPanelActions from "./SectionLeftPanelActions";
import SectionViewsInLeftPanel from "Features/views/components/SectionViewsInLeftPanel";
import SectionSurveysInLeftPanel from "Features/surveys/components/SectionSurveysInLeftPanel";
import SectionZonesInLeftPanel from "Features/zones/components/SectionZonesInLeftPanel";
import SectionRoomsInLeftPanel from "Features/rooms/components/SectionRoomsInLeftPanel";
import SectionRessourcesInLeftPanel_debug from "Features/ressources/components/SectionRessourcesInLeftPanel_debug";
import SectionScopesInLeftPanel from "Features/scopes/components/SectionScopesInLeftPanel";

export default function SectionLeftPanel({scene, caplaEditor, viewer}) {
  // update shortcuts from Leftpanel selection

  useUpdateShortcutElementTypesFromLeftPanel(scene, viewer);

  // data

  const {sceneId} = useParams();
  const sceneModule = useSceneModule();

  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  const openFilterPanel = useSelector((s) => s.filters.openFilterPanel);
  const openPackagesPanel = useSelector((s) => s.packages.openPackagesPanel);

  const os = useSelector((s) => s.ui.openSections);
  const leftPanelWidth = useSelector((s) => s.navigation.leftPanelWidth);
  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);
  const openIssuesInLeftPanel = useSelector(
    (s) => s.issues.openIssuesInLeftPanel
  );
  const viewerStep = useSelector((s) => s.leftPanel.leftPanelSection);
  const showScreenDatagridHelper = useSelector(
    (s) => s.leftPanel.showScreenDatagridHelper
  );
  // const [fms] = useFilteredSceneMeasurements(
  //   scene,
  //   "ONE_MODEL",
  //   false,
  //   true,
  //   false,
  //   false,
  //   sceneModule === "MEASUREMENTS" || sceneModule === "EDIT_MEASUREMENTS"
  // );
  const fms = []; // use to remove fms computation, done module by module
  const inOutMode = useSelector(
    (s) => s.measurements.filteredMeasurementsViewModeBckp
  );

  // helpers

  let count = fms.length;
  if (!count) count = 0;

  const display =
    (sceneId || viewer) &&
    sceneModule !== "LIBRARY" &&
    sceneModule !== "PRICINGS" &&
    sceneModule !== "RELATIONS" &&
    sceneModule !== "MODULES" &&
    (sceneModule !== "HOME" || viewer);

  // helpers - show left panel

  const showLeftPanel =
    (openLeftPanel && !os.outlet) ||
    (openLeftPanel && sceneModule === "ARTICLES");

  //
  let width = 0;
  if (showLeftPanel) width = leftPanelWidth;

  const [openFilters, setOpenFilters] = useState(false); // to reset size.

  // effect - keep MeasurementsDataManager in sync with shortcutTypes

  useEffect(() => {
    //caplaEditor?.measDataManager.setShortcutElementTypes(shortcutElementTypes); // why ?
  }, [shortcutElementTypes]);

  if (!display) {
    return <></>;
  } else if (openIssuesInLeftPanel)
    return (
      <Box
        sx={{
          width: width,
          minWidth: width,
          height: 1,
          maxHeight: 1,
          bgcolor: "background.default",
          flexDirection: "column",
          position: "relative",
          //zIndex: 50,
          display: "flex",
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <SectionIssuesInLeftPanel
          scene={scene}
          caplaEditor={caplaEditor}
          width={width}
          inOutMode={inOutMode}
        />
        {!os.outlet && <SectionLeftPanelHandel />}
      </Box>
    );
  // and the rest...
  else
    return (
      <Paper
        elevation={12}
        sx={{
          width: width,
          minWidth: width,
          height: 1,
          maxHeight: 1,
          bgcolor: "background.default",
          position: "relative",
          //zIndex: 50,
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch",
          //border: "3px solid blue",
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        {openFilterPanel && (
          <Box
            sx={{
              position: "absolute",
              top: 36,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 50,
            }}
          >
            <PanelFilters scene={scene} caplaEditor={caplaEditor} />
          </Box>
        )}
        {openPackagesPanel && (
          <Box
            sx={{
              position: "absolute",
              top: 36,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 50,
            }}
          >
            <PanelPackages scene={scene} caplaEditor={caplaEditor} />
          </Box>
        )}

        {!os.outlet && <SectionLeftPanelHandel />}
        {showLeftPanel && (
          <Box
            sx={{
              height: 1,
              display: "flex",
              flexDirection: "column",
              //border: "4px solid orange",
              boxSizing: "border-box",
              justifyItems: "stretch",
            }}
          >
            {viewer && (
              <Box
                sx={{
                  height: 1,
                  display: "flex",
                  flexDirection: "column",
                  //border: "4px solid orange",
                  boxSizing: "border-box",
                  justifyItems: "stretch",
                }}
              >
                {viewerStep === "SCENE" && (
                  <SectionSceneInLeftPanelInViewer scene={scene} />
                )}
                {viewerStep === "MEASUREMENTS" && showScreenDatagridHelper && (
                  <ScreenHelperDatagrid scene={scene} />
                )}
                {viewerStep === "MEASUREMENTS" && !showScreenDatagridHelper && (
                  <SectionRessourcesInLeftPanelInViewer
                    scene={scene}
                    caplaEditor={caplaEditor}
                    viewer={viewer}
                  />
                )}
                {viewerStep === "ELEMENT_TYPES" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyItems: "stretch",
                      height: 1,
                    }}
                  >
                    <HeaderInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                      viewer={viewer}
                    />
                    <SectionMeasurementsInLeftPanelV2 scene={scene} />
                  </Box>
                )}
              </Box>
            )}
            {!viewer && (
              <TopBarOverviewInLeftPanel
                scene={scene}
                caplaEditor={caplaEditor}
              />
            )}
            {!viewer &&
              !["ONBOARDING", "EDIT_MEASUREMENTS"].includes(sceneModule) && (
                <HeaderInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                  viewer={viewer}
                />
              )}
            {!viewer && sceneModule === "ONBOARDING" && (
              <HeaderInLeftPanelOnboarding
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={viewer}
              />
            )}
            {!viewer && sceneModule === "EDIT_MEASUREMENTS" && (
              <HeaderInLeftPanelPackage
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={viewer}
              />
            )}

            {!viewer &&
              !["ONBOARDING", "INPUTS", "ELEMENT_TYPES", "PACKAGES"].includes(
                sceneModule
              ) && (
                <SectionSubHeaderInLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                  viewer={viewer}
                />
              )}

            {!viewer && (
              <SectionLeftPanelActions
                scene={scene}
                caplaEditor={caplaEditor}
              />
            )}

            {/* {!viewer && sceneModule !== "ONBOARDING" && (
              <SectionMeasurementsFilters
                scene={scene}
                onOpen={() => setOpenFilters(!openFilters)}
              />
            )} */}

            {/* {!viewer && sceneModule === "RESSOURCES" && (
              <Box
                sx={{
                  p: 1,
                  minHeight: 80,
                  maxHeight: 240,
                  overflowY: "auto",
                }}
              >
                <SelectorRessourcesGroup scene={scene} />
              </Box>
            )} */}

            {!viewer &&
              [
                "SCOPES",
                "ONBOARDING",
                "MARKERS",
                "SECTORS",
                "ELEMENT_TYPES",
                "RESSOURCES",
                "ARTICLES",
                "INPUTS",
                "PACKAGES",
                "MEASUREMENTS",
                "EDIT_MEASUREMENTS",
                "ISSUES",
                "VIEWS",
                "SURVEYS",
                "ZONES",
                "ROOMS",
              ].includes(sceneModule) && (
                <Box
                  sx={{
                    flexGrow: 1,
                    minHeight: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {sceneModule === "SCOPES" && (
                    <SectionScopesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "ONBOARDING" && (
                    <SectionOnboardingInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "MARKERS" && (
                    <SectionMarkersInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "SECTORS" && (
                    <ListSceneSectors
                      scene={scene}
                      caplaEditor={caplaEditor}
                      measurements={fms}
                    />
                  )}
                  {sceneModule === "ELEMENT_TYPES" && (
                    <SectionElementTypesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "RESSOURCES" && (
                    <SectionRessourcesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "ARTICLES" && (
                    <SectionArticlesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "INPUTS" && (
                    <SectionInputsInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "ZONES" && (
                    <SectionZonesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "ROOMS" && (
                    <SectionRoomsInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "PACKAGES" && (
                    <SectionPackagesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "MEASUREMENTS" && (
                    <SectionMeasurementsInLeftPanelV2
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "EDIT_MEASUREMENTS" && (
                    <SectionEditMeasurementsInLeftPanelV2
                      scene={scene}
                      caplaEditor={caplaEditor}
                    />
                  )}
                  {sceneModule === "ISSUES" && (
                    <SectionIssuesInLeftPanel
                      scene={scene}
                      caplaEditor={caplaEditor}
                      width={width}
                      viewer={false}
                      inOutMode={inOutMode}
                    />
                  )}
                  {sceneModule === "VIEWS" && (
                    <SectionViewsInLeftPanel scene={scene} />
                  )}
                  {sceneModule === "SURVEYS" && (
                    <SectionSurveysInLeftPanel scene={scene} />
                  )}
                </Box>
              )}

            {/* {!viewer &&
              sceneModule &&
              sceneModule !== "VIEWS" &&
              sceneModule !== "PACKAGES" &&
              sceneModule !== "SECTORS" &&
              sceneModule !== "MARKERS" &&
              sceneModule !== "INPUTS" &&
              sceneModule !== "ELEMENT_TYPES" &&
              sceneModule !== "RESSOURCES" &&
              sceneModule !== "ARTICLES" &&
              sceneModule !== "EDIT_MEASUREMENTS" &&
              sceneModule !== "MEASUREMENTS" &&
              sceneModule !== "ZONES" &&
              sceneModule !== "ROOMS" &&
              sceneModule !== "ISSUES" && (
                <ListItemLeftPanel
                  scene={scene}
                  caplaEditor={caplaEditor}
                  measurements={fms}
                />
              )} */}
          </Box>
        )}
      </Paper>
    );
}
