export default function updateElementTypesGroupWithManyUpdatedTypes(
  elementTypes,
  group
) {
  let types = group.elementTypes;
  if (!Array.isArray(types)) types = [];
  const elementTypesIds = elementTypes.map((t) => t.id);
  types = types.filter((t) => !elementTypesIds.includes(t.id));
  return {...group, elementTypes: [...types, ...elementTypes]};
}
