import {Box, Typography} from "@mui/material";
import {common} from "@mui/material/colors";
import theme from "Styles/theme";

import SelectionBox from "./SelectionBox";
import ButtonExpand from "./ButtonExpand";

export default function Header({
  title,
  count,
  expand,
  onExpandChange,
  showMode,
  onShowModeChange,
  disableShowModeButton,
  disableExpand,
}) {
  // handlers

  function handleShowModeChange({itemId, showMode}) {
    if (onShowModeChange) onShowModeChange(showMode);
  }

  function handleExpandChange(expand) {
    onExpandChange(expand);
  }
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        py: 0.25,
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      <SelectionBox
        color={theme.palette.common.darkGrey}
        showMode={showMode ? showMode : "OFF"}
        onShowModeChange={handleShowModeChange}
        hide={disableShowModeButton}
      />
      <Box
        sx={{
          flexGrow: 1,
          px: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{fontSize: 13}}>{title}</Typography>
        {!disableExpand && (
          <ButtonExpand expand={expand} onExpandChange={handleExpandChange} />
        )}
      </Box>
    </Box>
  );
}
