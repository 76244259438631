import {useState} from "react";
import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import {
  setSelectedElementTypesGroupProxy,
  createElementTypesGroup,
} from "../elementTypesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function DialogCreateElementTypeGroup({
  scene,
  open,
  onClose,
  onCreated,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Nouvelle liste";

  const descriptionS = "Créez une nouvelle liste d'éléments / ouvrages.";
  const placeholder = "Lot GO, Matériaux, Tâches,...";
  const placeholderCode = "GO, MAT, TAC,...";

  const nameS = "Nom";
  const codeS = "Code";

  const createS = "Créer";

  // data

  const selectedScope = useSelectedScope();

  // state

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleCodeChange(e) {
    setCode(e.target.value);
  }
  function handleClose() {
    setLoading(false);
    onClose();
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    //
    const newGroup = {};
    newGroup.name = name.trim();
    if (code) newGroup.code = code.trim();
    newGroup.fromScene = false;
    newGroup.sceneId = scene?.id;
    newGroup.elementTypes = {};
    //
    const result = await dispatch(
      createElementTypesGroup({
        elementTypesGroup: newGroup,
        accessToken,
        fromScopeId: selectedScope?.id,
      })
    );
    const {item} = unwrapResult(result);
    // v moved in the call back : the setSelection depends on the location of the dialog v

    // const group = {};
    // group.id = item.id;
    // group.name = item.name;
    // group.sceneId = item.sceneId;
    // group.fromScene = false;
    // dispatch(setSelectedElementTypesGroupProxy(group));
    //
    setLoading(false);
    if (onCreated)
      onCreated({
        id: item?.id,
        name: item?.name,
        fromScene: false,
        code: item?.code,
      });
    onClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{descriptionS}</Typography>
        <Box sx={{mt: 2, display: "flex", alignItems: "center"}}>
          <TextField
            placeholder={placeholderCode}
            label={<Typography variant="body2">{codeS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={code}
            onChange={handleCodeChange}
            sx={{width: 100, mr: 1}}
          />
          <TextField
            fullWidth
            placeholder={placeholder}
            label={<Typography variant="body2">{nameS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={name}
            onChange={handleNameChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || name?.length === 0} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
