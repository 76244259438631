import {useSelector, useDispatch} from "react-redux";

import {setShowOld, setShowNew} from "Features/zones/zonesSlice";

import {Button, Box} from "@mui/material";

export default function ButtonToggleNewOld({caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const seeOld = "Avant seulement";
  const seeNew = "Après seulement";

  // data

  const showOld = useSelector((s) => s.zones.showOld);
  const showNew = useSelector((s) => s.zones.showNew);

  // helpers

  const label = showOld ? seeNew : seeOld;

  // handlers

  function handleToggle() {
    if (showOld) {
      dispatch(setShowOld(false));
      dispatch(setShowNew(true));
      caplaEditor.zonesEditor.hideImageOld();
      caplaEditor.zonesEditor.showImage();
    } else {
      dispatch(setShowOld(true));
      dispatch(setShowNew(false));
      caplaEditor.zonesEditor.showImageOld();
      caplaEditor.zonesEditor.hideImage();
    }
  }

  return (
    <Box sx={{width: 1}}>
      <Button fullWidth size="small" variant="contained" onClick={handleToggle}>
        {label}
      </Button>
    </Box>
  );
}
