import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid, frFR} from "@mui/x-data-grid";

import {setSelectedInputId} from "../inputsSlice";
import inputsForDatagrid from "../utils/inputsForDatagrid";

import ListIconModelType from "Features/viewer3D/components/ListIconModelType";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import useIsNarrow from "Features/ui/useIsNarrow";

export default function DataGridInputs({inputs}) {
  const dispatch = useDispatch();

  // strings

  const typeString = "-";
  const nameString = "Name";
  const fileNameString = "File";
  const sizeString = "Size";

  // data

  const selectedInputId = useSelector((s) => s.inputs.selectedInputId);
  const isNarrow = useIsNarrow();

  // grid - columns

  const columns = [
    {
      headerName: typeString,
      field: "type",
      width: 40,
      renderCell: (params) => {
        return <ListIconModelType type={params.value} />;
      },
    },
    {
      field: "name",
      headerName: nameString,
      flex: 0.5,
    },
    {
      hide: isNarrow,
      field: "fileName",
      headerName: fileNameString,
      flex: 0.5,
    },
    {
      field: "size",
      hide: isNarrow,
      headerName: sizeString,
      size: 120,
    },
  ];

  // grid - row

  let rows = inputsForDatagrid(inputs);

  // selection

  const selection = selectedInputId ? [selectedInputId] : [];

  // handlers

  function handleSelectionChange(selection) {
    const id = selection[0] ? selection[0] : null;
    dispatch(setSelectedInputId(id));
    const model = inputs.find((m) => m.id === id);
    if (model?.type === "PDF") {
      dispatch(setSelectedPdfModelId(model.id));
    }
  }

  return (
    <Box sx={{height: 1, width: 1, position: "relative"}}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selection}
      />
    </Box>
  );
}
