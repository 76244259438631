import { Vector3 } from "three";

import {
  pointInsidePolygon,
  segmentPolylineIntersection,
  segmentSegmentIntersection,
} from "../utils/intersections";

import makeBufferLine, { makeCenterLine } from "../utils/bufferLine";
import { nanoid } from "@reduxjs/toolkit";

const computeNormal = (a, b) => {
  const v = new Vector3().subVectors(b, a);
	return new Vector3(v.y, -v.x, 0).normalize();
};

const computeCenter = (a, b) => {
  const p1 = new Vector3(a[0], a[1], 0);
  const p2 = new Vector3(b[0], b[1], 0);
  return p1.clone().add(p2).divideScalar(2);
};

export default function createPV({measurements, params, data}) {
  const {partitionsPackageIds, proxyPackageId, dim1, props} = params;
  const {
    measurementsByPackageId,
    // typesById,
    initialPolygons,
    initialSegments,
  } = data;
  const proxies = measurementsByPackageId[proxyPackageId];
  const inputPolygons = [];
  const usedProxies = new Set();
  initialPolygons.forEach((p) => {
    if (usedProxies.size !== proxies.length) {
      for (const proxy of proxies) {
        if (
          p.zoneId === proxy.zoneId &&
          !usedProxies.has(proxy.id) &&
          Array.isArray(proxy.path3D) &&
          Array.isArray(p.path3D) &&
          pointInsidePolygon(proxy.path3D[0], p.path3D)
        ) {
          usedProxies.add(proxy.id);
          const newInput = {...p, proxy, toDelete: []};
          for (const delSegment of initialSegments) {
            const segment = [delSegment.path3D[0], delSegment.path3D[3]];
            if (p.measModelId === delSegment.measModelId) {
              const toDelete = segmentPolylineIntersection(
                segment,
                p.path3D
              );
              if (toDelete !== false) {
                newInput.toDelete.push(toDelete);
              }
            }
          }
          inputPolygons.push(newInput);
        }
      }
    }
  })
  const partitions = [];
  for (const partId of partitionsPackageIds) {
    partitions.push(...measurementsByPackageId[partId].filter(
      (m) => m.drawingShape === "POLYLINE"
    ));
  }
  const partitionsById = {};
  // const toDeg = 180 / Math.PI;
  const partitionsSegments = [];
  partitions.forEach((p) => {
    partitionsById[p.id] = p;
    let path3D = p.path3D;
    // const path3DLen = path3D.length;
    // if (path3DLen === p.drawingProps.path.length * 2 + 1) {
    //   path3D = makeCenterLine(path3D);
    // }
    for (let i = 0; i < path3D.length - 1; i++) {
      const p1 = path3D[i];
      const p2 = path3D[i + 1];
      // let angle = Math.atan2(p2[1] - p1[1], p2[0] - p1[0]) * toDeg;
      // if (angle < 0) angle = 180 - angle;
      // if (angle > 180) angle -=180;
      partitionsSegments.push({
        pts: [p1, p2],
        // angle: angle,
        partId: p.id,
      });
    }
  })
  const polylinesToAdd = [];
  inputPolygons.forEach((p) => {
    const output = [];
    for (let i = 0; i < p.path3D.length - 1; i++) {
      if (!p.toDelete.includes(i)) {
        const p1 = p.path3D[i];
        const p2 = p.path3D[i + 1];
        // let angle = Math.atan2(p2[1] - p1[1], p2[0] - p1[0]) * toDeg;
        // if (angle < 0) angle = 180 - angle;
        // if (angle > 180) angle -= 180;
        // console.log("haaaaaaaaaaaaaa", angle, i)
        // const candidates = partitionsSegments.filter((ps) =>
        //   ps.angle - 5 <= angle && angle <= ps.angle + 5
        // );
        // if (candidates.length > 0) {
        const p10 = new Vector3(p1[0], p1[1], 0);
        const p20 = new Vector3(p2[0], p2[1], 0);
        const normal = computeNormal(p10, p20);
        const center = computeCenter(p1, p2);
        normal.multiplyScalar(dim1);
        const p11 = center.clone().sub(normal);
        const p21 = center.clone().add(normal);
        const x1 = p11.x;
        const y1 = p11.y;
        const x2 = p21.x;
        const y2 = p21.y;
        for (const candidate of partitionsSegments) {
          const x3 = candidate.pts[0][0];
          const y3 = candidate.pts[0][1];
          const x4 = candidate.pts[1][0];
          const y4 = candidate.pts[1][1];
          if (segmentSegmentIntersection(x1, y1, x2, y2, x3, y3, x4, y4)) {
            output.push([candidate.partId, i]);
          }
        }
      }
    }
  // }
    const pvs = [];
    let lastId, lastPV, firstCut, lastCut;
    for (const [id, cut] of output) {
      if (id !== lastId || cut !== lastCut) {
        firstCut = cut;
        if (lastPV) {
          pvs.push(lastPV);
        }
        lastPV = {partId: id, firstCut: cut, lastCut: cut + 1};
      } else {
        lastPV.lastCut = cut + 1;
      }
      lastCut = cut + 1;
      lastId = id;
    }
    pvs.push(lastPV);
    pvs.forEach((pv) => {
      const partition = partitionsById[pv.partId];
      const newPath = p.drawingProps.path.slice(pv.firstCut, pv.lastCut + 1);
      let path3D = p.path3D;
      const path3DLen = path3D.length;
      if (path3DLen === p.drawingProps.path.length * 2 + 1) {
        path3D = makeCenterLine(path3D);
      }
      const centerPath = path3D.slice(pv.firstCut, pv.lastCut + 1);
      const newPath3D = makeBufferLine(centerPath, dim1);
      const zFrom3D3 = partition.path3d3[0][1];
      const newM = {
        ...partition,
        id: nanoid(),
        drawingProps: {
          ...partition.drawingProps,
          path: newPath,
        },
        dim1,
        path3D: newPath3D,
        path3d3: newPath3D.map((p) => {return [p[0], zFrom3D3, p[1]]}),
        affectedByProxy: true,
        affectedProps: [],
      }
      if (Array.isArray(props)) {
        for (const prop of props) {
          newM[prop] = p.proxy[prop];
          newM.affectedProps.push(prop);
        }
      }
      polylinesToAdd.push(newM)
    })
    // }
  })
  return measurements.concat(polylinesToAdd);
}