const applyStyle = (row, i, isTitle, rank, style) => {
  const cell = row.getCell(i);
  if ((isTitle && style === undefined) || ["title1", "title2"].includes(style)) {
    cell.font = {bold: true};
    if (rank === 1 || style === "title1")
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'bbbbc0'}
      };
    if (rank === 2 || style === "title2")
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F4F4F8'}
      };
  }
  if (style === "detail")
    cell.font = {color: {argb: "777777"}};
  cell.alignment = {vertical: "middle", horizontal: "left", wrapText: false};
};

export default function fillWorksheetWithRessourcesByVersion({
  worksheet,
  ressourcesByVersion,
  rowStartIndex,
  versions
}) {
  const headerRow = worksheet.getRow(2);
  const usedVersionsIds = new Set(ressourcesByVersion.filter((r) => r.byVersion).map((r) => Object.keys(r.byVersion)).flat());
  let usedVersions = []
  for (const id of usedVersionsIds) {
    const usedVersion = versions.find((v) => v.id === id);
    if (usedVersion) usedVersions.push(usedVersion);
  }
  usedVersions = usedVersions.sort((a, b) => a.num.toString() - b.num.toString());
  let col = 6;
  const versionsColumns = {};
  if (usedVersionsIds.has("-?-")) {
    const cell = headerRow.getCell(col);
    cell.value = "-?-";
    versionsColumns["-?-"] = col;
    col += 1;
  }
  for (const v of usedVersions) {
    let name = `V${v.num.toString()}`;
    if (col > 6) name = "Δ " + name;
    const cell = headerRow.getCell(col);
    cell.value = name;
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: v.color.replace("#", "")}
    };
    versionsColumns[v.id] = col;
    col += 1;
  }
  ressourcesByVersion.forEach((item, idx) => {
    const row = worksheet.getRow(rowStartIndex + idx);
    if (typeof item.num === "string" && !item.hideNum)
      row.getCell(1).value = item.num;
    if (typeof item.name === "string")
      row.getCell(2).value = item.name;
    if (typeof item.unit === "string" && !item.isTitle)
      row.getCell(3).value = item.unit;
    if (typeof item.quantity === "number" && !isNaN(item.quantity)) {
      row.getCell(4).value = item.quantity;
      if (item.quantity < 0) 
        row.getCell(4).font = {color: {argb: "FF0000"}};
    }
    const rank = item.rank ? item.rank : item.num?.split(".").length;
    for (let i = 1; i <= 4; i++) {
      applyStyle(row, i, item.isTitle, rank, item.style);
    }
    if (item.byVersion) {
      for (const [k, v] of Object.entries(item.byVersion)) {
        row.getCell(versionsColumns[k]).value = v;
        if (v < 0) row.getCell(versionsColumns[k]).font = {color: {argb: "FF0000"}};
      }
    }
  })
}
