import {useDispatch} from "react-redux";
import {updateArticleTemp} from "Features/articles/articlesSlice";

export default function useAddPoiToArticle() {
  const dispatch = useDispatch();
  const add = ({poi, article}) => {
    const pois = article.pois ?? [];
    const updates = {id: article.id, pois: [...pois, poi]};
    dispatch(updateArticleTemp(updates));
  };
  return add;
}
