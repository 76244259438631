import useAccessToken from "Features/auth/useAccessToken";
import {useDispatch} from "react-redux";
import {updateRemoteScenePartial} from "./scenesSlice";

export default function useUpdateBimboxMode() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const updateBimboxMode = ({sceneId, bimboxMode}) => {
    if ((accessToken, sceneId, bimboxMode)) {
      const updates = {bimboxMode};
      dispatch(updateRemoteScenePartial({accessToken, sceneId, updates}));
    }
  };
  return updateBimboxMode;
}
