import {getBase64} from "Utils/image";
import {ReactEditor} from "slate-react";
import {Transforms, Node, Editor, Path} from "slate";
import {createImageService} from "../../../services";

import {uploadFileService} from "Features/files/services";

/* Viewpoint
 * viewpoint={id,image,camera:{name,camera,position}, context}
 */
export function withViewpoints(editor) {
  const {isInline, isVoid} = editor;
  editor.isVoid = (element) => {
    return element.type === "viewpoint" ? true : isVoid(element);
  };
  return editor;
}

export async function insertViewpoint(
  editor,
  {id, listingId, accessToken, camera, context, file, image}
) {
  const {selection} = editor;
  ReactEditor.focus(editor);

  const props = {loading: true, id, camera, context, image};

  const paragraph = {type: "paragraph", children: [{text: ""}]};
  const viewpoint = [
    {
      type: "viewpoint",
      ...props,
      children: [{text: ""}],
    },
    paragraph,
  ];
  console.log("inserting viewpoint", viewpoint);

  // we first create the viewpoint without data.
  // we update the viewpoint asynchronously.

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );
    if (editor.isVoid(parentNode) || Node.string(parentNode)?.length) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, viewpoint, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, {at: parentPath});
      Transforms.insertNodes(editor, viewpoint, {at: parentPath, select: true});
    }
  } else {
    console.log("insert at the end");
    Transforms.insertNodes(editor, viewpoint, {select: true});
  }

  //we update the image
  try {
    const remoteUrl = await uploadFileService({
      accessToken,
      listingId,
      file,
      container: "listing-files",
    });
    const newProps = {...props, loading: false, image: remoteUrl};
    updateViewpoint(editor, newProps, id);
  } catch (error) {
    console.log(error);
  }
}

const updateViewpoint = (editor, newProps) => {
  Transforms.setNodes(editor, newProps, {
    at: [],
    match: (node, path) => {
      return node.id === newProps.id;
    },
  });
};
