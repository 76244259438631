import {useDispatch, useSelector} from "react-redux";

import {setFilterExcludeByTab} from "../filtersSlice";
import {FormControlLabel, Typography, Switch, Box} from "@mui/material";

export default function SwitchExcludeFilter({tab}) {
  const dispatch = useDispatch();
  // string

  const label = "Mode exclusion";

  const filtersByTab = useSelector((s) => s.filters.filtersByTab);
  const checked = Boolean(filtersByTab[tab]?.exclude);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setFilterExcludeByTab({tab, exclude: checked}));
  }

  return (
    <Box sx={{pl: 2}}>
      <FormControlLabel
        control={
          <Switch size="small" checked={checked} onChange={handleChange} />
        }
        label={<Typography sx={{fontSize: 13}}>{label}</Typography>}
      />
    </Box>
  );
}
