import {useSelector, useDispatch} from "react-redux";
import {setEnabledPackageIdsInMainScope} from "Features/overviewer/overviewerSlice";

import {
  Box,
  List,
  ListItemButton,
  Typography,
  IconButton,
  Checkbox,
} from "@mui/material";
import {VisibilityOff, Visibility} from "@mui/icons-material";

export default function ListPackagesWithVisibility({
  packages,
  selection,
  onSelectionChange,
}) {
  const dispatch = useDispatch();
  // data

  const enabledPackageIds = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );

  const scopeAndCounter = useSelector((s) => s.overviewer.scopeAndCounter);

  // helpers

  const modelIdCountMap = scopeAndCounter.modelIdCountMap ?? {};

  // handlers

  function handleVisibilityClick(e, modelId) {
    e.stopPropagation();
    e.preventDefault();
    const newEnabledIds = enabledPackageIds.filter((id) => id !== modelId);
    if (!enabledPackageIds.includes(modelId)) newEnabledIds.push(modelId);
    dispatch(setEnabledPackageIdsInMainScope(newEnabledIds));
  }

  return (
    <List dense>
      {packages.map((item) => {
        const selected = selection === item.id;
        const enabled = enabledPackageIds?.includes(item.id);
        const measCount = item.measCount;
        const measCountLabel = measCount ? `x${measCount}` : "";

        return (
          <ListItemButton
            divider
            sx={{p: 0, m: 0, pr: 1}}
            key={item.id}
            selected={selected}
            onClick={() => {
              onSelectionChange(item.id);
            }}
          >
            <Box
              sx={{
                py: 0.5,
                display: "flex",
                alignItems: "center",
                minWidth: 0,
                width: 1,
                color: enabled ? "text.primary" : "text.secondary",
              }}
            >
              {/* <IconButton
                sx={{px: 1}}
                size="small"
                onClick={(e) => handleVisibilityClick(e, item.id)}
              >
                {enabled ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton> */}
              <Box onClick={(e) => handleVisibilityClick(e, item.id)}>
                <Checkbox
                  size="small"
                  checked={enabled}
                  onChange={(e) => handleVisibilityClick(e, item.id)}
                />
              </Box>

              <Box
                sx={{
                  ml: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexGrow: 1,
                  minWidth: 0,
                }}
              >
                <Box>
                  <Typography variant="body2">{item.name}</Typography>
                </Box>

                {measCount && (
                  <Box
                    sx={{
                      ml: 1,
                      borderRadius: "4px",
                      px: 0.5,
                      bgcolor: enabled
                        ? "secondary.main"
                        : "background.default",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: enabled ? "common.white" : "text.secondary",
                      }}
                      noWrap
                    >
                      {measCountLabel}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
