import {useSelector, useDispatch} from "react-redux";

import {Box, Typography, Button} from "@mui/material";
import {VisibilityOff as Hide} from "@mui/icons-material";

import {
  // setMultipleSelection,
  setHiddenElements,
} from "Features/viewer3D/viewer3DSlice";

export default function CardSelection({editor3d}) {
  const dispatch = useDispatch();
  // strings

  const hideString = "Hide";
  const selectedItemsString = "selected objects";

  // data
  const ifcElement = useSelector((s) => s.viewer3D.selectedIfcElement);
  // const ms = useSelector((state) => state.viewer3D.multipleSelection);

  const hiddenElements = useSelector((state) => state.viewer3D.hiddenElements);

  //const count = ms.reduce((ac, cur) => ac + cur.expressIDs.length, 0);
  const count = ifcElement ? 1 : 0;

  // helper

  const itemsCountString = `${count} ${selectedItemsString}`;

  // function hideItems(itemSet) {
  //   const ifcModelID = itemSet.ifcModelID;
  //   const ids = itemSet.expressIDs;
  //   editor3d?.ifcLoader.ifcManager.removeFromSubset(ifcModelID, ids);
  // }

  function hideItem(item) {
    const ifcModelID = item.ifcModelID;
    const id = item.expressID;
    editor3d?.ifcLoader.ifcManager.removeFromSubset(ifcModelID, [id]);
  }

  // function getElementsFromMultipleSelection(ms) {
  //   const elements = [];
  //   ms.forEach((s) => {
  //     s.expressIDs.forEach((id) =>
  //       elements.push({
  //         expressID: id,
  //         modelId: s.modelId,
  //         ifcModelID: s.ifcModelID,
  //       })
  //     );
  //   });
  //   return elements;
  // }

  function addHiddenElements(hiddenEls, els) {
    const hiddenElsIds = hiddenEls.map((el) => `${el.modelId}-${el.expressID}`);
    const newHiddenEls = [...hiddenEls];
    els.forEach((el) => {
      if (!hiddenElsIds.includes(`${el.modelId}-${el.expressID}`)) {
        newHiddenEls.push(el);
      }
    });
    return newHiddenEls;
  }

  // handlers

  // function handleHideClick() {
  //   dispatch(setMultipleSelection([]));

  //   ms.forEach(hideItems);
  //   const elements = getElementsFromMultipleSelection(ms);
  //   const newHiddenElements = addHiddenElements(hiddenElements, elements);

  //   dispatch(setHiddenElements(newHiddenElements));
  // }

  function handleHideElementClick() {
    hideItem(ifcElement);
    const newHiddenElements = addHiddenElements(hiddenElements, [ifcElement]);
    dispatch(setHiddenElements(newHiddenElements));
  }

  return (
    <Box sx={{bgcolor: "common.white", borderRadius: "4px", p: 1}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography gutterBottom variant="body2">
          {itemsCountString}
        </Typography>
        <Button
          startIcon={<Hide fontSize="small" />}
          size="small"
          disabled={count === 0}
          onClick={handleHideElementClick}
        >
          {hideString}
        </Button>
      </Box>
    </Box>
  );
}
