import getArticleMeasurementsMap from "./getArticleMeasurementsMap";
import getArticleQtyMapFromArticleMeasurementsMap from "./getArticleQtyMapFromArticleMeasurementsMap";
import getVoidsByIdFromMeasurements from "./getVoidsByIdFromMeasurements";

export default function computeArticlesQties({
  articles,
  measurements,
  elementTypesMap,
  sectorsMap,
  noVoids,
}) {
  // STEP 1 : group measurementId by article based on types & filters
  // STEP 2 : compute qties for each article based on grouped measurementIds

  // STEP 1

  const articleMeasurementsMap = getArticleMeasurementsMap({
    articles,
    measurements,
    elementTypesMap,
    sectorsMap,
  });

  // STEP 2
  const voidsById = getVoidsByIdFromMeasurements(measurements);

  const articlesQties = getArticleQtyMapFromArticleMeasurementsMap({
    articleMeasurementsMap,
    measurements,
    noVoids,
    voidsById,
  });

  return articlesQties;
}
