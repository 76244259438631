import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {Box} from "@mui/material";

import SectionIssueInOutlet from "./SectionIssueInOutlet";
import SectionIssueInOutletEdit from "./SectionIssueInOutletEdit";

import {setOpenSectionIssueDetailInOutlet} from "../issuesSlice";

export default function SectionOutletIssueDetail({
  caplaEditor,
  scene,
  viewer,
  issue,
}) {
  const dispatch = useDispatch();

  // helpers

  const isNew = !Boolean(issue?.id);

  // state

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (isNew) setEditing(true);
  }, [isNew]);

  // handlers - editing

  function handleCancelClick() {
    setEditing(false);
    if (!issue?.id) dispatch(setOpenSectionIssueDetailInOutlet(false));
  }
  function handleSaveClick() {
    setEditing(false);
  }
  return (
    <Box>
      {editing || !issue?.id ? (
        <SectionIssueInOutletEdit
          issue={issue}
          onCancel={handleCancelClick}
          onSave={handleSaveClick}
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      ) : (
        <SectionIssueInOutlet
          caplaEditor={caplaEditor}
          viewer={viewer}
          issue={issue}
          onEditClick={() => {
            setEditing(true);
          }}
        />
      )}
    </Box>
  );
}
