import {Box, Typography, IconButton, Badge} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

import IconElementType from "./IconElementType";

import getElementTypeStyleObject from "../utils/getElementTypeStyleObject";
import getElementTypeQuantityLabel from "../utils/getElementTypeQuantityLabel";

export default function ListItemElementType({
  elementType,
  options,
  onVisibilityChange,
  visible,
  selected,
}) {
  // options

  const hideQuantity = options?.hideQuantity;
  const showMainQuantity = options?.showMainQuantity;
  const showVisibility = options?.showVisibility;
  const hideNum = options?.hideNum;

  // helper - styles

  const {fontWeight, bgcolor, color} = getElementTypeStyleObject(elementType);

  // helper - qtyLabel

  const {quantity, label: qtyLabel} = getElementTypeQuantityLabel(elementType, {
    showMainQuantity,
  });

  // helpers - pois

  const poisCount = elementType.pois?.length;
  const hasPois = elementType.pois?.length > 0;

  // handlers

  function handleVisibilityClick(e, type) {
    e.stopPropagation();
    e.preventDefault();
    if (onVisibilityChange && showVisibility) onVisibilityChange(type);
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
        bgcolor: selected ? "unset" : bgcolor,
        pr: 1,
        py: 0.5,
        fontWeight,
        color,
        ...(hasPois && {borderRight: "3px solid", borderColor: "primary.main"}),
        boxSizing: "border-box",
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          {showVisibility && !elementType.isTitle && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: visible ? "grey.400" : "grey.200",
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                px: 1,
                mr: 1,
              }}
            >
              <IconButton
                sx={{width: 18, height: 18, color: "inherit"}}
                onClick={(e) => handleVisibilityClick(e, elementType)}
              >
                {visible ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </Box>
          )}
          {!elementType.isTitle && (
            <IconElementType
              color={visible || !showVisibility ? elementType.color : "divider"}
              drawingShape={elementType.drawingShape}
              size={"small"}
            />
          )}
          {elementType.isTitle &&
            !["title1,title2"].includes(elementType.style) && (
              <Box sx={{width: 20, heigth: 20}} />
            )}
        </Box>

        <Typography
          sx={{
            fontWeight,
            ml: 1,
            fontSize: 12,
            ...(elementType.style === "detail" && {pl: 2, fontStyle: "italic"}),
            color:
              visible || !showVisibility ? "text.primary" : "text.secondary",
          }}
        >
          {elementType.num?.length > 0 && !hideNum
            ? `${elementType.num} • ${elementType.name}`
            : elementType.name}
        </Typography>
      </Box>

      {!hideQuantity && !elementType.isTitle && (
        <Box sx={{ml: 1}}>
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              color: visible && quantity > 0 ? "secondary.main" : "divider",
            }}
          >
            {qtyLabel}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
