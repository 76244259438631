import React from "react";

import {Box} from "@mui/material";

import ContainerEditableColumnTitle from "./ContainerEditableColumnTitle";
import ListOneRankItems from "./ListOneRankItems";

export default function SectionColumn({
  rankName,
  onRankNameChange,
  items,
  allItems,
  selectedItem,
  onSelect,
  onAdd,
  onAddItem,
  onChangeItem,
  showAddItem,
}) {
  // handlers

  function handleTitleChange(title) {
    if (!onAdd) onRankNameChange({oldName: rankName, newName: title});
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyItems: "stretch",
        flexGrow: 1,
        alignItems: "center",
        flexDirection: "column",
        //border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box>
        <ContainerEditableColumnTitle
          title={rankName}
          onTitleChange={handleTitleChange}
          onAdd={onAdd}
        />
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
        <ListOneRankItems
          allItems={allItems}
          items={items}
          selectedItem={selectedItem}
          onAdd={onAddItem}
          onChangeItem={onChangeItem}
          onSelect={onSelect}
          showAddItem={showAddItem}
        />
      </Box>
    </Box>
  );
}
