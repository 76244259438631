import getQtyWithDec from "Features/articles/utils/getQtyWithDec";
import getMergedItems from "Utils/getMergedItems";

// template : "byDim1", "byDim1xH", ...
// nomenclature: [{dim1,dim2,height,measIds:[]}]

function findItem(
  nomenclature,
  dim1,
  dim2,
  height,
  p0,
  p1,
  p2,
  p3,
  elementTypeId
) {
  return nomenclature.find(
    (item) =>
      item.dim1 === dim1 &&
      (typeof dim2 === "number" ? item.dim2 === dim2 : true) &&
      item.height === height &&
      (p0 ? item.p0 === p0 : true) &&
      (p1 ? item.p1 === p1 : true) &&
      (p2 ? item.p2 === p2 : true) &&
      (p3 ? item.p3 === p3 : true) &&
      (elementTypeId ? item.elementTypeId === elementTypeId : true)
  );
}
export default function getMeasNomenclatureFromMeasurements(
  measurements,
  template
) {
  //
  const nomenclature = [];
  //
  measurements.forEach((meas) => {
    const dim1 =
      typeof meas.dim1 === "number" ? parseFloat(meas.dim1.toFixed(3)) : null;
    const dim2 =
      typeof meas.dim2 === "number" && !isNaN(meas.dim2)
        ? parseFloat(meas.dim2.toFixed(3))
        : null;
    const height =
      typeof meas.height === "number"
        ? parseFloat(meas.height.toFixed(3))
        : null;
    const p0 = meas.p0 ?? null;
    const p1 = meas.p1 ?? null;
    const p2 = meas.p2 ?? null;
    const p3 = meas.p3 ?? null;
    const elementTypeId = meas.elementTypeId ?? null;

    let item = findItem(
      nomenclature,
      dim1,
      dim2,
      height,
      p0,
      p1,
      p2,
      p3,
      elementTypeId
    );

    if (!item) {
      item = {
        elementTypeId,
        dim1:
          typeof dim1 === "number" && !isNaN(dim1) ? parseFloat(dim1) : null,
        dim2:
          typeof dim2 === "number" && !isNaN(dim2) ? parseFloat(dim2) : null,
        height:
          typeof height === "number" && !isNaN(height)
            ? parseFloat(height)
            : null,
        p0,
        p1,
        p2,
        p3,
        measIds: [],
      };
      nomenclature.push(item);
    }
    item.measIds.push(meas.id);
  });
  //
  let result;
  if (template === "dim1xheight cm") {
    result = getMergedItems(nomenclature, ["dim1", "height"], "elementTypeId");
  } else if (template === "diam cm") {
    result = getMergedItems(nomenclature, ["dim1"], "elementTypeId");
  } else if (template === "p0") {
    result = getMergedItems(nomenclature, ["p0"], "elementTypeId");
  } else if (template === "HA") {
    result = getMergedItems(nomenclature, ["p2"], "elementTypeId");
  } else if (template === "TS") {
    result = getMergedItems(nomenclature, ["p1"], "elementTypeId");
  } else if (template === "p3") {
    result = getMergedItems(nomenclature, ["p3"], "elementTypeId");
  } else if (template === "dim1xdim2 cm") {
    result = getMergedItems(nomenclature, ["dim1", "dim2"], "elementTypeId");
  } else if (template === "dim1xL cm") {
    result = getMergedItems(nomenclature, ["dim1", "length"], "elementTypeId");
  } else if (template === "dim1 cm") {
    result = getMergedItems(nomenclature, ["dim1"], "elementTypeId");
  } else if (template === "height m") {
    result = getMergedItems(nomenclature, ["height"], "elementTypeId");
  } else if (template === "height cm") {
    result = getMergedItems(nomenclature, ["height"], "elementTypeId");
  } else if (template === "stairs") {
    result = getMergedItems(
      nomenclature,
      ["p2", "p1", "height", "dim1"],
      "elementTypeId"
    );
  }

  return result;
}
