import {useState, useEffect} from "react";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  List,
  ListItemButton,
  Typography,
} from "@mui/material";

export default function DialogScale({open, onClose, onSave, scale, zones}) {
  // strings

  const titleString = "Echelle du PDF";
  const scaleStart = "1 :";
  const saveString = "Enregistrer";

  // state

  const [tempScale, setTempScale] = useState(scale);
  const [selected, setSelected] = useState(null);

  // handlers

  function handleSaveClick() {
    let scaleToSave;
    if (tempScale && parseFloat(tempScale)) scaleToSave = tempScale;
    else scaleToSave = "50";
    onSave(scaleToSave);
  }

  function handleTempScaleChange(e) {
    setTempScale(e.target.value);
    setSelected(null);
  }

  function handleScaleFromSelection(z) {
    setTempScale(((z.scale * 72.0) / 0.0254).toFixed(4));
    setSelected(z);
  }

  // effect

  useEffect(() => {
    setTempScale(scale);
    setSelected(null);
  }, [scale]);

  // useEffect(() => {
  //   if (!selected && scale && zones && zones.length > 0) {
  //     const selected = zones.find(
  //       (z) => `${Number((z.scale * 72.0 / 0.0254).toFixed(4))}` === `${Number(scale.toFixed(4))}`
  //     );
  //     setSelected(selected);
  //   }
  // }, [scale, zones]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleString}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            width: 250,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              bgcolor: "background.default",
              pl: 2,
            }}
          >
            <Typography variant="body2">{scaleStart}</Typography>
            <InputBase
              value={
                tempScale ? `${Number(parseFloat(tempScale).toFixed(4))}` : ""
              }
              onChange={handleTempScaleChange}
              sx={{
                ml: 1,
                width: 80,
                fontSize: (theme) => theme.typography.body2.fontSize,
              }}
            />
          </Box>
        </Box>
        <List dense>
          {zones.map((z) => (
            <ListItemButton
              key={z?.id}
              selected={z?.id === selected?.id}
              onClick={() => handleScaleFromSelection(z)}
            >
              <Typography variant="body2">
                {`${z.name} • 1:${Number(
                  ((z.scale * 72.0) / 0.0254).toFixed(4)
                )}`}
              </Typography>
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveClick}>{saveString}</Button>
      </DialogActions>
    </Dialog>
  );
}
