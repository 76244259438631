import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {updateRoomsGroup} from "../roomsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateRoomsGroup(scene) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const update = async (updates) => {
    if (accessToken && scene?.id) {
      const result = await dispatch(updateRoomsGroup({accessToken, updates}));
      const _result = unwrapResult(result);
      if (_result) {
        const item = _result.item;
        return item;
      }
    }
  };
  return update;
}
