import {useDispatch} from "react-redux";

import {updateArticlesBatch} from "../articlesSlice";
import {updateArticlesGroup} from "../articlesSlice";

import useArticlesToSync from "./useArticlesToSync";
import useArticlesGroupsToSync from "./useArticlesGroupsToSync";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateArticlesBatch(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const update = async (updatesBatch) => {
    if (updatesBatch.length > 0) {
      await dispatch(updateArticlesBatch({accessToken, updatesBatch}));
    }
  };

  return update;
}
