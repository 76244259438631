import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import {Box} from "@mui/material";

import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";
import AppDesktopViewer from "./AppDesktopViewer";

import ScreenStartApp from "Features/overviewer/components/ScreenStartApp";

import {
  setAppMode,
  setDesktopOrMobile,
  setOpenSections,
} from "Features/ui/uiSlice";
import useIsViewer from "Features/ui/useIsViewer";

import CaplaEditor from "Features/caplaEditor/js/CaplaEditor";
import useInitialSetIsStagingEnv from "Features/overviewer/hooks/useInitialSetIsStagingEnv";
import usePageType from "Features/navigation/usePageType";
import {setOpenDialogFsOpenScope} from "Features/overviewer/overviewerSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

export default function App() {
  const dispatch = useDispatch();
  const isStagingEnv = useIsStagingEnv();

  const caplaEditorRef = useRef();

  // data

  const desktopOrMobile = useSelector((s) => s.ui.desktopOrMobile);
  const isViewer = useIsViewer();
  useEffect(() => {
    dispatch(setAppMode(isViewer ? "VIEWER" : "EDITOR"));
  }, [isViewer]);

  const appMode = useSelector((s) => s.ui.appMode);

  // data - helper - starting App

  const oneFirstScopeWasOpened = useSelector(
    (s) => s.overviewer.oneFirstScopeWasOpened
  );

  const outOfDateTestWasDone = useSelector(
    (s) => s.overviewer.lastOpenedScopeIsOutOfDateTestWasDone
  );

  const openDialogFsSelectScope = useSelector(
    (s) => s.overviewer.openDialogFsSelectScope
  );

  const openDialogFsOpenScope = useSelector(
    (s) => s.overviewer.openDialogFsOpenScope
  );

  const openDialogFsInvalidBimbox = useSelector(
    (s) => s.overviewer.openDialogFsInvalidBimbox
  );
  console.log("openDialogFsInvalidBimbox", openDialogFsInvalidBimbox);

  const pageType = usePageType();

  const isStartingApp =
    pageType === "SCENE" &&
    !oneFirstScopeWasOpened &&
    !(openDialogFsSelectScope || openDialogFsOpenScope);

  console.log("isViewer", isViewer, isStartingApp);
  // init

  useEffect(() => {
    // test
    const isMobile = /Android|iPhone/i.test(navigator.userAgent);
    const dOm = isMobile ? "MOBILE" : "DESKTOP";
    dispatch(setDesktopOrMobile(dOm));

    if (isMobile) {
      dispatch(
        setOpenSections({
          outlet: true,
          fixedViewersBox: false,
          viewer3D: true,
          callToAction: false,
        })
      );
    }

    if (!caplaEditorRef.current) {
      caplaEditorRef.current = new CaplaEditor({dispatch, isViewer});
      window.caplaEditor = caplaEditorRef.current; // make editor accessible from the browser
    }
  }, []);

  // init isStagingEnv

  // useInitialSetIsStagingEnv(); // ! it removes the initial search params, like the scope.

  // return

  return (
    <Box sx={{position: "fixed", top: 0, bottom: 0, left: 0, right: 0}}>
      <Box sx={{width: 1, height: 1}}>
        {desktopOrMobile === "DESKTOP" && !isViewer && appMode === "EDITOR" && (
          <AppDesktop caplaEditor={caplaEditorRef.current} />
        )}
        {desktopOrMobile === "DESKTOP" && isViewer && appMode === "VIEWER" && (
          <AppDesktopViewer caplaEditor={caplaEditorRef.current} />
        )}
        {desktopOrMobile === "MOBILE" && <AppMobile />}
      </Box>
      {isStartingApp &&
        !isViewer &&
        isStagingEnv &&
        !openDialogFsInvalidBimbox &&
        !outOfDateTestWasDone && <ScreenStartApp />}
    </Box>
  );
}
