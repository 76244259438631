import React from "react";

import {Button, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
export default function CreateNoteButton({onClick, disabled}) {
  const {t} = useTranslation("notes");

  // strings

  const createNoteString = t("createNoteButton");

  return (
    <Button
      variant="contained"
      startIcon={<Add />}
      disabled={disabled}
      sx={{
        position: "absolute",
        bottom: (theme) => theme.spacing(2),
        left: "50%",
        transform: "translateX(-50%)",
      }}
      onClick={onClick}
    >
      <Typography variant="body2" noWrap>
        {createNoteString}
      </Typography>
    </Button>
  );
}
