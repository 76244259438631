import {Box, Typography} from "@mui/material";

import ContainerScopeInGrid from "./ContainerScopeInGrid";
import ContainerScope from "./ContainerScope";

export default function SelectorScopeGrid({
  scopes,
  onOpen,
  onSeeDetail,
  scene,
  title,
}) {
  return (
    <Box sx={{color: "common.white"}}>
      {title && (
        <Typography color="inherit" sx={{mb: 2}}>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          "&>*": {mr: 1},
        }}
      >
        {scopes.map((scope) => {
          return (
            <ContainerScope
              key={scope?.id}
              scene={scene}
              scope={scope}
              onOpenClick={onOpen}
              onSeeDetailClick={onSeeDetail}
            />
          );
        })}
      </Box>
    </Box>
  );
}
