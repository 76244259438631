const defaultValue = [
  {
    type: "paragraph",
    children: [
      {
        text: "Valeur par défaut dans l'éditeur. Ouuups... ",
      },
    ],
  },
];
export default defaultValue;
