import {useDispatch} from "react-redux";

import {updateArticlesGroupTemp} from "../articlesSlice";

import {Box, Typography} from "@mui/material";

import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import SelectorArticlesGroupTypesGroups from "./SelectorArticlesGroupTypesGroups";

export default function BlockEditableArticlesGroupTypesGroups({
  scene,
  articlesGroup,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Lists d'ouvrages/élements associées";

  // data

  const groups = useElementTypesGroupsProxyByScene(scene, {
    sortByName: true,
    filterByScope: true,
  });

  // helpers

  const selectedGroupsIds = articlesGroup.typesGroupsIds ?? [];

  // handlers

  function handleSelectionChange(selectedGroupsIds) {
    dispatch(
      updateArticlesGroupTemp({
        id: articlesGroup.id,
        typesGroupsIds: selectedGroupsIds,
      })
    );
  }

  return (
    <Box sx={{width: 1, p: 1}}>
      <Box sx={{p: 1}}>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
      </Box>
      <SelectorArticlesGroupTypesGroups
        groups={groups}
        selectedGroupsIds={selectedGroupsIds}
        onSelectionChange={handleSelectionChange}
      />
    </Box>
  );
}
