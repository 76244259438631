import articleTypesMapToArray from "./articleTypesMapToArray";
import articleTypesArrayToMap from "./articleTypesArrayToMap";

export default function getUpdatesRemovingUnknownArticleTypes(
  articles,
  elementTypesByGroupIdById
) {
  const batchUpdates = [];
  //
  articles.forEach((article) => {
    const articleTypes = article.types ?? {};
    const articleCoreTypes = article.coreTypes ?? {};
    //
    const typesArray = articleTypesMapToArray(articleTypes);
    const coreTypesArray = articleTypesMapToArray(articleCoreTypes);
    //
    let updateTypes = false;
    const cleanTypesArray = typesArray.filter((typeItem) => {
      let test = true;
      const typeId = typeItem?.typeId;
      const groupId = typeItem?.groupId;
      if (groupId || typeId) {
        if (!elementTypesByGroupIdById[groupId]?.[typeId]) {
          test = false;
          updateTypes = true;
        }
      }
      return test;
    });
    //
    let updateCoreTypes = false;
    const cleanCoreTypesArray = coreTypesArray.filter((typeItem) => {
      let test = true;
      const typeId = typeItem?.typeId;
      const groupId = typeItem?.groupId;
      if (groupId || typeId) {
        if (!elementTypesByGroupIdById[groupId]?.[typeId]) {
          test = false;
          updateCoreTypes = true;
        }
      }
      return test;
    });
    //
    let updates = {id: article.id};
    if (updateTypes) updates.types = articleTypesArrayToMap(cleanTypesArray);
    if (updateCoreTypes)
      updates.coreTypes = articleTypesArrayToMap(cleanCoreTypesArray);
    if (updateTypes || updateCoreTypes) batchUpdates.push(updates);
  });

  return batchUpdates;
}
