import {useState} from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import useUpdatePrices from "../hooks/useUpdatePrices";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getPricesFromFile from "Features/excel/utils/getPricesFromFile";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function DialogImportPricesFromExcel({
  open,
  onClose,
  scene,
}) {
  const {shareLink} = useParams();

  // strings

  const title = "Importez des prix";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";
  const buttonName = "Choisir un fichier";

  // data

  const updatePrices = useUpdatePrices(scene);
  const ressources = useRessourcesByScene(scene);
  const pricings = useSelector((s) => s.pricing.pricings);

  // state

  const [prices, setPrices] = useState(null);
  const [file, setFile] = useState(null);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    const importedPrices = {};
    const excelPrices = await getPricesFromFile(file);
    for (const [group, map] of Object.entries(excelPrices)) {
      const groupRes = ressources.filter((r) => r.group === group);
      for (const [num, pu] of Object.entries(map)) {
        const res = groupRes.find((r) => r.num === num);
        if (res) importedPrices[res.id] = {
          sharedBimboxId: shareLink,
          resId: res.id,
          pu
        };
      }
    }
    setPrices(importedPrices);
  }
  function handleCancelClick() {
    onClose();
  }
  function handleSaveClick() {
    updatePrices(pricings, prices);
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}