//import {useSelector, useDispatch} from "react-redux";

import {FormControlLabel, Typography, Checkbox, Box} from "@mui/material";

export default function CheckboxOpenAfterCreation({checked, onChange}) {
  // const dispatch = useDispatch();

  // string

  const label = "Ouvrir à la création";

  // data

  // const checked = useSelector((s) => s.boxhome.linkedPdfsOnly);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    onChange(checked);
  }

  return (
    <Box sx={{pl: 1, py: 0.5}}>
      <FormControlLabel
        control={
          <Checkbox size="small" checked={checked} onChange={handleChange} />
        }
        label={
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {label}
          </Typography>
        }
      />
    </Box>
  );
}
