import {useState} from "react";

import useActionsRessourcesMap from "../hooks/useActionsRessourcesMap";
import useSelectedRessourcesGroup from "../hooks/useSelectedRessourcesGroup";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateRessourcesGroup from "./DialogCreateRessourcesGroup";
import DialogDeleteRessourcesGroup from "./DialogDeleteRessourcesGroup";
import DialogCreateRessource from "./DialogCreateRessource";
import DialogDeleteRessource from "./DialogDeleteRessource";
import DialogImportRessourcesFromExcel from "./DialogImportRessourcesFromExcel";
import DialogFsEditRessourcesGroup from "./DialogFsEditRessourcesGroup";

export default function SectionActionsRessources({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  const selectedRessourcesGroup = useSelectedRessourcesGroup(scene);

  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateRessourcesGroup, setOpenDialogCreateRessourcesGroup] =
    useState(false);
  const [openDialogDeleteRessourcesGroup, setOpenDialogDeleteRessourcesGroup] =
    useState(false);
  const [openDialogCreateRessource, setOpenDialogCreateRessource] =
    useState(false);
  const [openDialogDeleteRessource, setOpenDialogDeleteRessource] =
    useState(false);
  const [
    openDialogImportRessourcesFromExcel,
    setOpenDialogImportRessourcesFromExcel,
  ] = useState(false);
  const [openDialogEditRessourcesGroup, setOpenDialogEditRessourcesGroup] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createRessourcesGroup: () => setOpenDialogCreateRessourcesGroup(true),
    deleteRessourcesGroup: () => setOpenDialogDeleteRessourcesGroup(true),
    createRessource: () => setOpenDialogCreateRessource(true),
    deleteRessource: () => setOpenDialogDeleteRessource(true),
    importExcelRessources: () => setOpenDialogImportRessourcesFromExcel(true),
    editRessourcesGroup: () => setOpenDialogEditRessourcesGroup(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateRessourcesGroup() {
    setOpenDialogCreateRessourcesGroup(false);
  }
  function handleCloseDialogCreateRessource() {
    setOpenDialogCreateRessource(false);
  }

  // data - handlers

  const actionsMap = useActionsRessourcesMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateRessourcesGroup && (
        <DialogCreateRessourcesGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateRessourcesGroup}
          onClose={handleCloseDialogCreateRessourcesGroup}
        />
      )}
      {openDialogDeleteRessourcesGroup && (
        <DialogDeleteRessourcesGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteRessourcesGroup}
          onClose={() => setOpenDialogDeleteRessourcesGroup(false)}
        />
      )}

      {openDialogCreateRessource && (
        <DialogCreateRessource
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateRessource}
          onClose={handleCloseDialogCreateRessource}
        />
      )}
      {openDialogDeleteRessource && (
        <DialogDeleteRessource
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteRessource}
          onClose={() => setOpenDialogDeleteRessource(false)}
        />
      )}
      {openDialogImportRessourcesFromExcel && (
        <DialogImportRessourcesFromExcel
          open={openDialogImportRessourcesFromExcel}
          onClose={() => {
            setOpenDialogImportRessourcesFromExcel(false);
          }}
          scene={scene}
        />
      )}
      {openDialogEditRessourcesGroup && (
        <DialogFsEditRessourcesGroup
          open={openDialogEditRessourcesGroup}
          onClose={() => {
            setOpenDialogEditRessourcesGroup(false);
          }}
          scene={scene}
          initRessourcesGroup={selectedRessourcesGroup}
        />
      )}
    </>
  );
}
