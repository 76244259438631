import { useSelector } from "react-redux";

import {Grid, Box, Typography} from "@mui/material";

import TextFieldRessourcePriceInSelectionDetail from "Features/pricing/components/TextFieldRessourcePriceInSelectionDetail";
import formatPriceWithSpaces from "Features/pricing/utils/formatPriceWithSpaces";

export default function GridRessourceQuantities({
  scene,
  ressourceWithQuantities,
  pu,
}) {
  // strings

  const qtyS = "Quantité";
  const unitS = "Unité";
  const costS = "P.U.";

  // data

  const editedPU = useSelector((s) => s.pricing.editedValue);

  // helpers

  const dataMap = {
    quantity: {
      label: qtyS,
      value: ressourceWithQuantities?.quantity?.toFixed(2),
      color: "secondary",
    },
    unit: {
      label: unitS,
      value: ressourceWithQuantities?.unit,
      color: "secondary",
    },
    cost: {
      label: costS,
      value: ressourceWithQuantities?.cost
        ? ressourceWithQuantities.cost
        : null,
      color: "text.primary",
    },
    pricing: {
      label: costS,
      resId: ressourceWithQuantities?.id,
      value: formatPriceWithSpaces(pu),
      color: "secondary",
    },
  };

  const showedData = ["quantity", "unit"];
  if (scene?.usesPrices &&
    !ressourceWithQuantities?.noPricing &&
    ressourceWithQuantities?.quantity
  )
    showedData.push("pricing");
  else showedData.push("cost");


  let price;
  if (editedPU && ressourceWithQuantities) {
    price = formatPriceWithSpaces(
      ressourceWithQuantities.quantity * parseFloat(editedPU.replace(/\s/g, ''))
    );
  }

  return (
    <>
    <Box
      sx={{
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid
        key={"labels"}
        container
        sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
      >
        {showedData.map((data, i) => (
          <Grid
            item
            key={i.toString() + "label"}
            sx={{width: 1, maxWidth: 0.33}}
          >
            <Typography
              sx={{fontSize: 13}}
              color="text.secondary"
              align="center"
            >
              {dataMap[data].label}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        key={"values"}
        container
        sx={{
          display: "flex",
          width: 1,
          py: 0,
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {showedData.map((data, i) => (
          <Grid
            item
            key={i.toString() + "value"}
            sx={{
              display: "flex",
              width: 1,
              maxWidth: 0.33,
              height: "40px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {data === "pricing" ? (
              <TextFieldRessourcePriceInSelectionDetail data={dataMap[data]} scene={scene} />
            ) : (
              <Typography
                variant="body2"
                color={dataMap[data].color}
                align="center"
              >
                {dataMap[data].value}
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
    <Box
      sx={{
        display: "flex",
        width: 1,
        mb: 1,
        mt: price ? 1 : 0,
      }}
    >
      {price && !ressourceWithQuantities.noPricing && (
          <Typography variant="body2"
          sx={{
            ml: "auto",
            mr: 0.5,
            color: "common.caplaBlack",
            bgcolor: "stabilo.orange",
            borderRadius: "15px",
            px: "15px",
          }}
        >
          {`Prix : ${price} €`}
        </Typography>
      )}
    </Box>
    </>
  );
}