import addOneFiltersStrToArticleTypes from "./addOneFiltersStrToArticleTypes";
import articleTypesMapToArray from "./articleTypesMapToArray";
import cleanArticleTypesBasic from "./cleanArticleTypesBasic";
import getFiltersStrFromArticleTypesItems from "./getFiltersStrFromArticleTypesItems";
import getParentItemsOfOneArticleTypesItem from "./getParentItemsOfOneArticleTypesItem";
import removeParentItemsFromArticleTypes from "./removeParentItemsFromArticleTypes";
import replaceOneItemInArticleTypes from "./replaceOneItemInArticleTypes";
import updateFiltersStrWithOneFiltersStr from "./updateFiltersStrWithOneFiltersStr";
import applyInnerGlobalFiltersStrToArticleTypes from "./applyInnerGlobalFiltersStrToArticleTypes";
import addFiltersStrToOneArticleTypesItem from "./addFiltersStrToOneArticleTypesItem";
import updateFiltersStrWithFiltersStr from "./updateFiltersStrWithFiltersStr";
//import removeGlobalFiltersStrFromArticleTypes from "./removeGlobalFiltersSrFromArticleTypes";
import removeGlobalScopeFromArticleTypes from "./removeGlobalScopeFromArticleTypes";
import copyTypes from "./copyTypes";

export default function updateArticleTypesWithOneItem(types, item, options) {
  // options

  const filtersStrOnly = options?.filtersStrOnly;
  const funcOnly = options?.funcOnly;

  const updateMode = options?.updateMode ?? "merge"; // "merge", "replace"
  const transferGlobalFiltersStr = options?.transferGlobalFiltersStr;
  const transferParentsFiltersStr = options?.transferParentsFiltersStr;
  const removeParentItems = options?.removeParentItems;

  const optionsKeys = options ? Object.keys(options) : [];

  const updateFiltersStr =
    !optionsKeys.includes("filtersStrOnly") || filtersStrOnly;
  const updateFunc = !optionsKeys.includes("funcOnly") || funcOnly;

  if (!item) return types;

  //
  const {groupId, typeId, filtersStr, func, ...props} = item;

  const newProps = {...props};
  if (updateFiltersStr) newProps["filtersStr"] = filtersStr;
  if (updateFunc) newProps["func"] = func;

  //
  // EDGE CASE
  //
  if (!groupId && !typeId && !filtersStr?.length > 0) {
    // we remove the filtersStr gloabaly
    //return removeGlobalFiltersStrFromArticleTypes(types);
    return removeGlobalScopeFromArticleTypes(types);
  }
  if (groupId && !typeId && !filtersStr?.length > 0 && !func) return types;

  //
  // changes
  //

  let oldTypes = copyTypes(types);

  // globalScope

  console.log(
    "debugAFA99 T11 S1",
    articleTypesMapToArray(oldTypes, {debugFormat: true})
  );

  if (!groupId && !typeId && updateFiltersStr) {
    if (!oldTypes["filtersStr"]) {
      oldTypes = {...oldTypes, filtersStr};
    } else {
      oldTypes["filtersStr"] = updateFiltersStrWithFiltersStr(
        oldTypes["filtersStr"],
        filtersStr,
        updateMode
      );
      oldTypes["globalFunc"] = func;
    }

    //oldTypes = addOneFiltersStrToArticleTypes(oldTypes, filtersStr); // add the filter over all the items !
    // prev line doesn't work : the global scope is not the one added, but the first one.
    // new function is asymetric

    // groupScope
  } else if (groupId && !typeId && updateFiltersStr) {
    if (!oldTypes[groupId]) {
      oldTypes[groupId] = {filtersStr};
    } else {
      oldTypes[groupId]["filtersStr"] = updateFiltersStrWithFiltersStr(
        oldTypes[groupId]["filtersStr"],
        filtersStr,
        updateMode
      );
      // if (transferGlobalFiltersStr) {
      //   oldTypes = addOneFiltersStrToArticleTypes(
      //     oldTypes,
      //     filtersStr,
      //     groupId
      //   );
      // }
    }

    // typeScope
  } else if (groupId && typeId) {
    if (!oldTypes[groupId]) {
      oldTypes[groupId] = {[typeId]: newProps};
    } else {
      // we are in the case where the added item's type can be a child of other article types.
      // in this case, we do no want to keep parent's items in the list to avoid
      // to get all the elementType's parent children's items in the article scope.

      if (!oldTypes[groupId][typeId]) {
        oldTypes[groupId][typeId] = newProps;
      } else {
        const oldFunc = oldTypes[groupId][typeId].func;
        const newFunc = newProps.func;
        let updatedFunc;
        if (updateMode === "replace") {
          updatedFunc = newFunc;
        } else {
          updatedFunc = newFunc ? newFunc : oldFunc;
        }
        if (!updateFunc) updatedFunc = "x";
        //
        const oldFiltersStr = oldTypes[groupId][typeId].filtersStr;
        const newFiltersStr = newProps.filtersStr;
        const updatedFiltersStr = updateFiltersStrWithFiltersStr(
          oldFiltersStr,
          newFiltersStr,
          updateMode
        );

        //
        const updatedProps = {func: updatedFunc, filtersStr: updatedFiltersStr};
        //

        //
        oldTypes[groupId][typeId] = {
          ...oldTypes[groupId][typeId],
          ...updatedProps,
        };
        //
      }
    }
  }

  console.log(
    "debugAFA99 T11 S2",
    articleTypesMapToArray(oldTypes, {debugFormat: true})
  );

  if (transferGlobalFiltersStr) {
    oldTypes = applyInnerGlobalFiltersStrToArticleTypes(oldTypes);
  }

  console.log(
    "debugAFA99 T11 S3",
    articleTypesMapToArray(oldTypes, {debugFormat: true})
  );
  // console.log(
  //   "updateTypesWithItem 1st transfo",
  //   articleTypesMapToArray(oldTypes, {debugFormat: true})
  // );

  //
  if (transferParentsFiltersStr) {
    // console.log(
    //   "TRANSFER PARENTSS START",
    //   articleTypesMapToArray(oldTypes, {debugFormat: true})
    // );
    const parents = getParentItemsOfOneArticleTypesItem(types, item);

    if (parents?.length > 0) {
      const parentsFiltersS = getFiltersStrFromArticleTypesItems(parents);
      //console.log("updateTypesWithItem 2nd transfo - filters", parents);
      const newItem = addFiltersStrToOneArticleTypesItem(item, parentsFiltersS);
      //console.log("updateTypesWithItem 2nd transfo - newItem", parents);
      oldTypes = replaceOneItemInArticleTypes(newItem, oldTypes);
    }

    console.log(
      "TRANSFER PARENTSS END",
      articleTypesMapToArray(oldTypes, {debugFormat: true})
    );
  }

  if (removeParentItems) {
    oldTypes = removeParentItemsFromArticleTypes(oldTypes, item);
  }
  //

  const result = cleanArticleTypesBasic(oldTypes);

  console.log(
    item.typeName,
    "updateTypesWithItemAA",
    types,
    articleTypesMapToArray(types, {debugFormat: true}),
    {
      groupId: item.groupId ? "gid" : "global",
      filtersStr: item?.filtersStr,
      func: item?.func,
    },
    articleTypesMapToArray(result, {debugFormat: true})
  );

  return result;
}
