import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

import {
  Card,
  CardContent,
  CardActions,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
export default function CardModelKeywords({model, viewer}) {
  const {t} = useTranslation("common");
  const dispatch = useDispatch();

  // strings

  const saveString = capitalizeStart(t("save"));
  const keywordsString = "Keywords";

  // state

  const initialKeywords = model.keywords ? model.keywords : "";

  const [keywords, setKeywords] = useState(initialKeywords);

  // handlers

  function handleKeywordsChange(e) {
    const newKeywords = e.target.value;
    setKeywords(newKeywords);
  }

  function handleSaveClick() {
    const updates = {...model, keywords};
    dispatch(updateModel({updatedModel: updates, sync: true}));
  }

  return (
    <Box sx={{p: 2}}>
      <Card>
        <CardContent>
          <Typography variant="body2" gutterBottom>
            <b>{keywordsString}</b>
          </Typography>
          <TextField
            value={keywords}
            onChange={handleKeywordsChange}
            fullWidth
            multiline
            size="small"
            InputProps={{
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
              },
            }}
          />
        </CardContent>
        {!viewer && (
          <CardActions>
            <Button onClick={handleSaveClick}>{saveString}</Button>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}
