import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogImportRessourcesFromExcel from "./DialogImportRessourcesFromExcel";
import DialogAddRelationsRessources from "./DialogAddRelationsRessources";

import {setSelectedRessourceGroup} from "../ressourcesSlice";
import useRenameSceneRessourcesGroup from "../hooks/useRenameSceneRessourcesGroup";
import useCopySceneRessourcesGroup from "../hooks/useCopySceneRessourcesGroup";
import useDeleteSceneRessourcesGroup from "../hooks/useDeleteSceneRessourcesGroup";
import useRessourceGroupsByScene from "../hooks/useRessourceGroupsByScene";
import useRessourcesByScene from "../hooks/useRessourcesByScene";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import RenameDialog from "Features/ui/components/RenameDialog";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useUpdateSceneElementType from "Features/elementTypes/hooks/useUpdateSceneElementType";
import useUpdateElementTypesGroup from "Features/elementTypes/hooks/useUpdateElementTypesGroup";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";
import DialogCopyRessourcesTable from "./DialogCopyRessourcesTable";
import useSharedboxesByScene from "Features/sharedboxes/hooks/useSharedboxesByScene";
import useUpdateSceneSharedbox from "Features/sharedboxes/hooks/useUpdateSceneSharedbox";
import {setSelectedSharedbox} from "Features/sharedboxes/sharedboxesSlice";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import sanitizeString from "Utils/sanitizeString";
import sortByNum from "Utils/sortByNum";
import DialogImportRessourcesRelations from "./DialogImportRessourcesRelations";

export default function ButtonMoreRessourcesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const importS = "Import excel";
  const exportS = "Export excel";
  const renameS = "Renommer la table";
  const copyS = "Copier la table";
  const deleteS = "Supprimer la table";
  const renameTitleS = "Renommer la table d'articles";
  const copyTitleS = "Définir un nouveau nom pour la table d'articles";
  const addRelationsS = "Ajouter des relations";
  const exportRelationsS = "Exporter des relations";
  const importRelationsS = "Importer des relations";

  // data

  const renameRessourcesGroup = useRenameSceneRessourcesGroup(scene);
  const copyRessourcesGroup = useCopySceneRessourcesGroup(scene);
  const deleteSceneRessourcesGroup = useDeleteSceneRessourcesGroup();
  const selectedGroup = useSelector((s) => s.ressources.selectedRessourceGroup);
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const types = useElementTypesBySceneProxy(scene);
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const updateElementType = useUpdateSceneElementType(scene);
  const updateElementTypesGroup = useUpdateElementTypesGroup();
  const ressourceGroups = useRessourceGroupsByScene(scene);
  const ressources = useRessourcesByScene(scene);
  const sharedboxes = useSharedboxesByScene(scene);
  const updateSharedbox = useUpdateSceneSharedbox(scene?.id);
  const selectedSharedbox = useSelector((s) => s.sharedboxes.selectedSharedbox);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openAddRelations, setOpenAddRelations] = useState(false);
  const [openImportRelations, setOpenImportRelations] = useState(false);

  // helpers

  const groupsMap = {};
  for (const group of groups) {
    groupsMap[group.id] = group.name;
  }

  const typesById = {};
  for (const item of types) {
    typesById[item.id] = {
      ...item,
      groupName: groupsMap[item.groupId],
    };
  }

  const measurements = caplaEditor?.measDataManager.getAllMeasurements();

  let ressourcesWithIds = ressources
    .map((r) => ({
      ...r,
      group: r.group ? r.group : "-?-",
      num: r.num ? r.num : "0",
    }))
    .sort((a, b) => sortByNum(a.num, b.num))
    .sort((a, b) => a.group.localeCompare(b.group));
  if (selectedGroup)
    ressourcesWithIds = ressourcesWithIds.filter(
      (r) => r.group === selectedGroup
    );

  // handlers

  async function updateTypesByRelations(editedRelations, editedTypesGroups) {
    for (let idx = 0; idx < groups.length; idx++) {
      const group = groups[idx];
      if (editedTypesGroups.has(group.id)) {
        const groupTypes = types.filter(
          (t) => t.groupId === group.id || t.group === group.name
        );
        const editedTypes = [];
        const modelIds = new Set();
        const measurementsToUpdate = [];
        groupTypes.forEach((t) => {
          const editedType = {...t};
          const itemRelationKeys = Object.keys(editedRelations).filter((k) =>
            k.startsWith(editedType.id)
          );
          editedType.res = itemRelationKeys.map((k) => editedRelations[k]);
          if (group.fromScene) updateElementType(editedType);
          else editedTypes.push(editedType);
          typesById[t.id] = editedType;
          measurements
            .filter((m) => m.elementTypeId === editedType.id)
            .forEach((m) => {
              measurementsToUpdate.push({
                ...m,
                res: editedType?.res
                  ? editedType.res.map(({resId}) => ({resId, off: false}))
                  : [],
              });
              modelIds.add(m.measurementsModelId);
            });
        });
        const sceneData = {ressources, elementTypes: Object.values(typesById)};
        caplaEditor?.measDataManager.updateMeasurements(measurementsToUpdate);
        for (const modelId of modelIds) {
          caplaEditor?.measDataManager.saveModificationsForModelById(
            modelId,
            sceneData
          );
        }
        if (!group.fromScene) {
          const updatedGroup = {...group, elementTypes: editedTypes};
          await updateElementTypesGroup(updatedGroup);
        }
      }
    }
  }

  // handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    const {typesToEdit} = deleteSceneRessourcesGroup(
      scene,
      selectedGroup,
      relations
    );
    // groups.forEach((g) => {
    for (let idx = 0; idx < groups.length; idx++) {
      const g = groups[idx];
      const groupTypes = types
        .filter((t) => t.groupId === g.id || t.group === g.name)
        .map((t) => {
          if (t.id in typesToEdit) {
            const resSet = typesToEdit[t.id];
            return {
              ...t,
              res: t.res.filter((r) => !resSet.has(r.resId)),
            };
          }
          return {...t};
        });
      if (!g.fromScene) {
        const editedGroup = {...g, elementTypes: groupTypes};
        await updateElementTypesGroup(editedGroup);
      } else {
        // updateElementTypes(groupTypes);
        console.log("NOT IMPLEMENTED");
      }
    }
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // handlers - rename

  function handleRename() {
    setOpenRenameDialog(true);
    setAnchorEl(null);
  }

  function handleRenameConfirm(newName) {
    setOpenRenameDialog(false);
    const oldName = selectedGroup;
    renameRessourcesGroup(oldName, newName);
    sharedboxes.forEach((sharedbox) => {
      const resGroups = sharedbox.entities?.ressourceGroups;
      if (resGroups?.includes(oldName)) {
        const sharedGroups = resGroups.filter((g) => g !== oldName);
        sharedGroups.push(newName);
        const updatedSharedbox = {
          ...sharedbox,
          entities: {
            ...sharedbox.entities,
            ressourceGroups: sharedGroups.sort((a, b) => a.localeCompare(b)),
          },
        };
        updateSharedbox(updatedSharedbox);
        if (selectedSharedbox && selectedSharedbox.id === updatedSharedbox.id)
          dispatch(setSelectedSharedbox(updatedSharedbox));
      }
    });
    dispatch(setSelectedRessourceGroup(newName));
  }

  function handleRenameCancel() {
    setOpenRenameDialog(false);
  }

  // handlers - copy

  function handleCopy() {
    setOpenCopyDialog(true);
    setAnchorEl(null);
  }

  function handleCopyConfirm(newName, forDemo) {
    setOpenCopyDialog(false);
    const editedRelations = copyRessourcesGroup(
      selectedGroup,
      newName,
      relations,
      forDemo
    );
    updateTypesByRelations(editedRelations);
    dispatch(setSelectedRessourceGroup(newName));
  }

  function handleCopyCancel() {
    setOpenCopyDialog(false);
  }

  // handlers - excel

  async function handleExport() {
    let fileName = "ressources.xlsx";
    if (selectedGroup)
      fileName = sanitizeString(selectedGroup) + "_ressources.xlsx";
    setAnchorEl(null);
    const wb = await createExcelWorkbook({
      template: "RESSOURCES_WITH_IDS",
      data: ressourcesWithIds,
      withIds: true,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  // handlers - add relations

  function handleAddRelations() {
    setOpenAddRelations(true);
    setAnchorEl(null);
  }

  async function handleAddRelationsConfirm(group) {
    const newRelations = new Set();
    const typesToUpdate = new Set();
    const typesGroupsToUpdate = new Set();
    const updatedRelations = {...relations};
    const refRessources = ressources.filter((r) => r.group === selectedGroup);
    const targetRessources = ressources.filter((r) => r.group === group.id);
    refRessources?.forEach((ref) => {
      const target = targetRessources.find((r) => r.num === ref.num);
      if (target) {
        const targetRel = Object.keys(relations).filter((r) =>
          r.endsWith(target.id)
        );
        targetRel.forEach((key) => {
          const typeId = key.replace(`-${target.id}`, "");
          const typeToUpdate = typesById[typeId];
          if (typeToUpdate && typeToUpdate.groupId) {
            typesToUpdate.add(typeId);
            typesGroupsToUpdate.add(typeToUpdate.groupId);
            const newKey = key.replace(target.id, ref.id);
            updatedRelations[newKey] = {
              ...relations[key],
              id: newKey,
              resId: ref.id,
            };
            newRelations.add(newKey);
          }
        });
      }
    });
    // typesGroupsToUpdate.forEach((tg) => {
    for (let idx = 0; idx < typesGroupsToUpdate.length; idx++) {
      const tg = typesGroupsToUpdate[idx];
      const typesGroup = groups.find((g) => g.id === tg);
      if (typesGroup) {
        const editedTypes = [];
        typesGroup.elementTypes?.forEach((t) => {
          if (typesToUpdate.has(t.id)) {
            const untouchedRes = t.res
              ? t.res.filter((r) => !newRelations.has(r.id))
              : [];
            const relToAdd = [];
            newRelations.forEach((nr) => {
              if (nr.startsWith(t.id)) {
                relToAdd.push(updatedRelations[nr]);
              }
            });
            editedTypes.push({...t, res: [...untouchedRes, ...relToAdd]});
          } else {
            editedTypes.push({...t});
          }
        });
        const editedGroup = {...typesGroup, elementTypes: editedTypes};
        await updateElementTypesGroup(editedGroup);
      }
    }
    if (newRelations.size > 0)
      dispatch(setRelationsTypesRessources(updatedRelations));
    setOpenAddRelations(false);
  }

  function handleAddRelationsCancel() {
    setOpenAddRelations(false);
  }

  // handlers - export relations

  async function handleExportRelations() {
    let fileName = "ressources_relations.xlsx";
    if (selectedGroup)
      fileName = sanitizeString(selectedGroup) + "_ressources_relations.xlsx";
    const ressourcesWithTypes = ressources
      .filter((r) => r.group === selectedGroup)
      .sort((a, b) => sortByNum(a.num, b.num))
      .map((r) => {
        const usedTypes = Object.keys(relations)
          .filter((k) => k.endsWith(r.id))
          .map((k) => {
            const rel = relations[k];
            const type = typesById[rel.typeId];
            if (!type) return;
            const typeGroup = groups.find((g) => g.id === type.groupId);
            return {
              ...type,
              groupName: typeGroup?.name,
              func: rel.func,
            };
          })
          .sort(
            (a, b) =>
              a.groupName.localeCompare(b.groupName) || sortByNum(a.num, b.num)
          );
        return {...r, types: usedTypes};
      });
    const data = {
      ressourcesTypesRelations: ressourcesWithTypes,
      ressourcesTable: selectedGroup,
      elementTypes: types,
    };
    const wb = await createExcelWorkbook({
      template: "RESSOURCES_ELEMENT_TYPES",
      data,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
    setAnchorEl(null);
  }

  // handlers - import relations

  function handleImportRelations() {
    setOpenImportRelations(true);
    setAnchorEl(null);
  }

  function handleConfirmImportRelations(editedRelations, editedTypesGroups) {
    updateTypesByRelations(editedRelations, editedTypesGroups);
    dispatch(setRelationsTypesRessources(editedRelations));
    setOpenImportRelations(false);
  }

  function handleCloseImportRelations() {
    setOpenImportRelations(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "RENAME",
      label: renameS,
      handler: handleRename,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
    {id: "DIVIDER"},
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
    },
    {id: "DIVIDER"},
    {
      id: "EXPORT_RELATIONS",
      label: exportRelationsS,
      handler: handleExportRelations,
    },
    {
      id: "IMPORT_RELATIONS",
      label: importRelationsS,
      handler: handleImportRelations,
    },
    {id: "DIVIDER"},
    {
      id: "COPY",
      label: copyS,
      handler: handleCopy,
    },
    {
      id: "ADD_RELATIONS",
      label: addRelationsS,
      handler: handleAddRelations,
    },
  ];

  return (
    <Box sx={{display: selectedGroup ? "flex" : "none"}}>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool, idx) => {
          if (tool.id === "DIVIDER") return <Divider key={idx} />;
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <RenameDialog
        open={openRenameDialog}
        onCancel={handleRenameCancel}
        onConfirm={handleRenameConfirm}
        title={renameTitleS}
        initial={selectedGroup}
      />
      <DialogCopyRessourcesTable
        open={openCopyDialog}
        onCancel={handleCopyCancel}
        onConfirm={handleCopyConfirm}
        title={copyTitleS}
        initial={selectedGroup}
        forceDifferent={true}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource="ressourcesGroup"
      />
      <DialogImportRessourcesFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
      <DialogImportRessourcesRelations
        open={openImportRelations}
        onConfirm={handleConfirmImportRelations}
        onCancel={handleCloseImportRelations}
        relations={relations}
        typesById={typesById}
      />
      <DialogAddRelationsRessources
        open={openAddRelations}
        groups={ressourceGroups
          .filter((g) => g && g !== selectedGroup)
          .map((g) => ({id: g, name: g}))}
        onCancel={handleAddRelationsCancel}
        onConfirm={handleAddRelationsConfirm}
        initialGroup={selectedGroup}
      />
    </Box>
  );
}
