import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FormControlLabel, Checkbox, Typography} from "@mui/material";

import {setIsSnapping} from "Features/measurements/measurementsSlice";

export default function CheckboxIsSnapping({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const labelS = "Accroche vectorielle";

  // data

  const isSnapping = useSelector((s) => s.measurements.isSnapping);

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    if (checked) {
      dispatch(setIsSnapping(true));
    } else {
      dispatch(setIsSnapping(false));
    }
    editorPdf?.annotationsManager.measurementsPdfManager.setSnapping(checked);
    editorPdf?.setSnapIsEnabled(checked);
  }

  useEffect(() => {
    if (editorPdf) {
      editorPdf.annotationsManager.measurementsPdfManager.setSnapping(
        isSnapping
      );
      editorPdf.setSnapIsEnabled(isSnapping);
    }
  }, [isSnapping, editorPdf]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={isSnapping}
          onChange={handleCheckChange}
        />
      }
      label={
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {labelS}
        </Typography>
      }
    />
  );
}
