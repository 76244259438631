import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {addListingMembership} from "Features/listings/listingsSlice";

import {Box, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function InputAddMembership({listing}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const memberString = "User email";
  const addString = "Add";

  // state

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleAddClick() {
    if (accessToken) {
      const membership = {email, role: "maker"};
      const listingId = listing.id;
      setLoading(true);
      await dispatch(
        addListingMembership({accessToken, listingId, membership})
      );
      setLoading(false);
    }
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <TextField
        sx={{mr: 2, width: 340}}
        label={<Typography variant="body2">{memberString}</Typography>}
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        size="small"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <LoadingButton
        loading={loading}
        startIcon={<Add />}
        loadingPosition="start"
        onClick={handleAddClick}
      >
        {addString}
      </LoadingButton>
    </Box>
  );
}
