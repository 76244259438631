import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {CompactPicker} from "react-color";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function DialogRenameModel({open, model, onClose, editor3d}) {
  const dispatch = useDispatch();
  const {t} = useTranslation(["viewer3D", "common"]);

  // string

  const dialogTitleString = "Change color";
  const saveButton = capitalizeStart(t("common:save"));

  // local state

  const initColor = model?.color ? model.color : "#FFFFFF";

  const [newColor, setNewColor] = useState(initColor);

  // handlers

  function handleNewColorChange(color) {
    setNewColor(color.hex);
  }

  function handleChangeSave() {
    const updates = {...model, color: newColor};
    dispatch(updateModel({updatedModel: updates, sync: true}));

    editor3d?.mapEditor.updateModelColor(model.id, newColor);

    onClose();
  }

  // update newName

  useEffect(() => {
    if (model?.color) setNewColor(model.color);
  }, [model?.color]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitleString} </DialogTitle>
      <DialogContent>
        <CompactPicker color={newColor} onChange={handleNewColorChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleChangeSave}>{saveButton}</Button>
      </DialogActions>
    </Dialog>
  );
}
