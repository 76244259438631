import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getTypeRessourceMapFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(3, rowCount - 2).values();

  let valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    valuesA.push(rowValues);
  }

  const fields = [
    "typeId",
    "typeGroup",
    "typeNum",
    "typeName",
    "resId",
    "resGroup",
    "resNum",
    "resName",
    "mode",
    "func",
    "toDelete",
  ];

  const loadedTypeRessourceMap = [];

  valuesA.map((valueA) => {
    const item = {};
    valueA.forEach((value, index) => (item[fields[index]] = value));
    loadedTypeRessourceMap.push(item);
  });

  return loadedTypeRessourceMap;
}
