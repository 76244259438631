import {useSelector, useDispatch} from "react-redux";
import {Dialog, Box, Divider, Typography} from "@mui/material";

import ListElementTypesMultipleWithGroups from "./ListElementTypesMultipleWithGroups";

import {
  setOpenDialogSelectElementType,
  setSelectedElementTypesGroupProxyInEditMeasurements,
} from "Features/elementTypes/elementTypesSlice";

import {setSelectedElementTypeId} from "Features/measurements/measurementsSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import {updateTempMeasurementFromElementType} from "Features/measurements/measurementsSlice";

import getItemsMapById from "Utils/getItemsMapById";
import useSceneModule from "Features/navigation/useSceneModule";
import ContainerElementType from "./ContainerElementType";

export default function DialogSelectorElementTypes({scene}) {
  const dispatch = useDispatch();
  const sceneModule = useSceneModule();

  // string

  const selectS = "Sélectionnez un ouvrage / élément";

  // data - dialog

  const open = useSelector((s) => s.elementTypes.openDialogSelectElementType);

  // data - types

  let types = useElementTypesBySceneProxy(scene);

  // data - selection

  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );

  // data - groups

  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  // helper - elementTypesMap

  const elementTypesMap = getItemsMapById(types);

  const selectedElementType = elementTypesMap[selectedElementTypeId];

  // helper - selected group

  let selectedGroupId;
  if (sceneModule === "EDIT_MEASUREMENTS")
    selectedGroupId = selectedGroupProxy?.id;

  // handlers

  function closeDialog() {
    dispatch(setOpenDialogSelectElementType(false));
  }

  function handleSelectionChange(ids) {
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    const newType = elementTypesMap[id];
    if (newType?.isTitle) return;

    if (sceneModule === "EDIT_MEASUREMENTS") {
      dispatch(setSelectedElementTypeId(id));
      if (id) {
        dispatch(updateTempMeasurementFromElementType(elementTypesMap[id]));
      }
    }
    if (elementTypesMap[id]) closeDialog();
  }

  function handleGroupProxyChange(groupProxy) {
    dispatch(setSelectedElementTypesGroupProxyInEditMeasurements(groupProxy));
  }

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={false}>
      <Box
        sx={{
          display: "flex",
          width: 1,
          flexGrow: 1,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          minHeight: 0, // make it works !!!
          width: "40vw",
          height: "80vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Typography variant="h6">{selectS}</Typography>
        </Box>
        <Divider />
        <ListElementTypesMultipleWithGroups
          elementTypes={types}
          selectedElementTypeIds={[selectedElementTypeId]}
          onSelectionChange={handleSelectionChange}
          multipleSelection={false}
          selectedGroupId={selectedGroupProxy?.id}
          onSelectedGroupProxyChange={handleGroupProxyChange}
          hideQuantities={true}
        />
      </Box>
    </Dialog>
  );
}
