import {useSelector} from "react-redux";

import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

import {Box, Typography} from "@mui/material";

import HeaderFilters from "./HeaderFilters";
import SectionSelectorFilterTab from "./SectionSelectorFilterTab";
import SectionFilterTitle from "./SectionFilterTitle";
import SectionFilterList from "./SectionFilterList";
import SectionFilterOptions from "./SectionFilterOptions";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
// import useElementTypeGroupsByScene from "Features/elementTypes/hooks/useElementTypeGroupsByScene";
// import ContainerContextOverview from "Features/overviewer/components/ContainerContextOverview";
// import SectionContextOverviewInFilterPanel from "./SectionContextOverviewInFilterPanel";
import SectionFiltersNoVoidsInFilterPanel from "./SectionFiltersNoVoidsInFilterPanel";

export default function PanelFilters({scene, caplaEditor}) {
  //const isStagingEnv = useIsStagingEnv();
  const isStagingEnv = true;
  // strings

  const comingS = "En cours de développement...";

  // data

  const selectedTab = useSelector((s) => s.filters.selectedFilterTab);

  const elementTypes = useElementTypesBySceneProxy(scene);

  // helpers

  const itemsMap = {
    ELEMENT_TYPES: elementTypes,
  };

  const items = itemsMap[selectedTab] ? itemsMap[selectedTab] : [];

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "common.white",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
      }}
    >
      <HeaderFilters />
      {/* <SectionContextOverviewInFilterPanel scene={scene} /> */}
      <SectionFiltersNoVoidsInFilterPanel caplaEditor={caplaEditor} />

      {isStagingEnv && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyItems: "strech",
          }}
        >
          <SectionSelectorFilterTab selectedFilterTab={selectedTab} />
          <SectionFilterTitle tab={selectedTab} />
          <SectionFilterOptions tab={selectedTab} />
          <Box sx={{minHeight: 0, overflowY: "auto", flexGrow: 1}}>
            <SectionFilterList tab={selectedTab} items={items} />
          </Box>
        </Box>
      )}
      {!isStagingEnv && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "stretch",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Typography variant="h6" sx={{color: "divider"}}>
            {comingS}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
