export default function getCenterAndAngleFromPath3(path3) {
  // coordinates in 2d

  const A = [path3[0][0], -path3[0][2]];
  const B = [path3[1][0], -path3[1][2]];
  const C = [path3[2][0], -path3[2][2]];
  const D = [path3[3][0], -path3[3][2]];

  const centerX = (A[0] + C[0]) / 2;
  const centerY = (A[1] + C[1]) / 2;
  const center = [centerX, path3[0][1], -centerY];

  const dx = B[0] - A[0];
  const dy = B[1] - A[1];
  let angleRadians = Math.atan2(dy, dx);
  if (dx < 0 && dy >= 0) {
    angleRadians += Math.PI; // Adjust for quadrant 1
  } else if (dx < 0 && dy < 0) {
    angleRadians += Math.PI * 2; // Adjust for quadrant 2
  }

  return [center, angleRadians];
}
