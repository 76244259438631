import {createSlice} from "@reduxjs/toolkit";

export const helper3dSlice = createSlice({
  name: "helpers3d",
  initialState: {
    showHelperModelIds: [],
  },
  reducers: {
    setShowHelperModelIds: (state, action) => {
      state.showHelperModelIds = action.payload;
    },
  },
});

export const {setShowHelperModelIds} = helper3dSlice.actions;

export default helper3dSlice.reducer;
