export default function cleanMeasurementRawAndNetQties(item, options) {
  const noVoids = options?.noVoids; // used to remove voids from project.

  let cleanItem = {
    ...item,
    lengthRaw: item.length,
    areaRaw: item.area,
    volumeRaw: item.volume,
  };
  if (noVoids !== true && item.lengthNet) {
    // use voids => dimNet = dim
    cleanItem.length = item.lengthNet;
    cleanItem.area = item.areaNet;
    cleanItem.volume = item.volumeNet;
  } else if (!item.lengthNet) {
    // no voids & no dimNet => dimNet = dimRaw
    cleanItem.lengthNet = item.length;
    cleanItem.areaNet = item.area;
    cleanItem.volumeNet = item.volume;
  }

  return cleanItem;
}
