import {
  Box,
  Chip,
  Tooltip,
  List,
  ListItemButton,
  Typography,
  IconButton,
} from "@mui/material";
import {Add, Visibility} from "@mui/icons-material";
import ActionsNewRessourceInSelectionPanel from "Features/ressources/components/ActionsNewRessourceInSelectionPanel";

export default function ListElementTypeGroupsMultiple({
  items,
  selection,
  onSelectionChange,
  multipleSelection,
  divider = true,
}) {
  // handler

  function handleClick(item) {
    let newSelection = [...selection].filter((id) => id !== item.id);
    let selected = selection.includes(item.id);
    if (!selected) {
      if (multipleSelection) {
        newSelection = [...newSelection, item.id];
      } else {
        newSelection = [item.id];
      }
    }
    onSelectionChange(newSelection);
  }

  return (
    <List dense disablePadding sx={{bgcolor: "background.default", width: 1}}>
      {items.map((item) => {
        let selected = selection.includes(item.id);
        let name = item.name;
        let qtyValue = Number(item.qtyValue) > 0 ? item.qtyValue : "";
        let qtyUnit = Number(item.qtyValue) > 0 ? item.qtyUnit : "";

        if (qtyValue === 1) qtyUnit = qtyUnit.slice(0, -1);

        let color = "text.primary";
        if (!qtyValue) color = "text.secondary";

        return (
          <ListItemButton
            divider={divider}
            sx={{
              p: 0,
            }}
            selected={selected}
            color={color}
            key={item.id}
            size="small"
            onClick={() => handleClick(item)}
          >
            <Box sx={{p: 1, width: 1}}>
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bgcolor: "common.white",
                  p: 1,
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 1,
                  }}
                >
                  <Typography sx={{fontSize: 13, color}}>{name}</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{fontSize: 13, color: "text.secondary"}}>
                      {qtyValue}
                    </Typography>
                    <Typography
                      sx={{fontSize: 13, color: "text.secondary", ml: 1}}
                    >
                      {qtyUnit}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
