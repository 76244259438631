import {useSelector, useDispatch} from "react-redux";
import useRoomsByScene from "../hooks/useRoomsByScene";

import {setSelectedRoomIds} from "../roomsSlice";
import {setSelectedRoomsGroupId} from "../roomsSlice";

import {Box, Typography} from "@mui/material";

import ListRoomsMultipleWithGroups from "./ListRoomsMultipleWithGroups";
import useRoomsGroups from "../hooks/useRoomsGroups";

export default function SectionRoomsInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // string

  const noGroupS = "Aucune arborescence de pièces";

  // data

  const rooms = useRoomsByScene(scene);
  const roomsGroups = useRoomsGroups(scene, {
    sortByName: true,
    filterByScope: true,
  });
  const selectedRoomIds = useSelector((s) => s.rooms.selectedRoomIds);
  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);

  // handlers

  function handleSelectionChange(ids) {
    dispatch(setSelectedRoomIds(ids));
  }

  function handleSelectedRoomsGroupIdChange(id) {
    dispatch(setSelectedRoomsGroupId(id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        justifyItems: "strech",
      }}
    >
      {roomsGroups.length > 0 ? (
        <ListRoomsMultipleWithGroups
          scene={scene}
          caplaEditor={caplaEditor}
          rooms={rooms}
          selectedRoomIds={selectedRoomIds}
          onSelectionChange={handleSelectionChange}
          roomsGroups={roomsGroups}
          forceSelection={true}
          selectedRoomsGroupId={selectedRoomsGroupId}
          onSelectedRoomsGroupIdChange={handleSelectedRoomsGroupIdChange}
          multipleSelection={false}
          variant="list"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {noGroupS}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
