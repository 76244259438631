import React from "react";

import useProfile from "Features/profile/useProfile";

import {Paper, Box, Typography} from "@mui/material";

import ButtonDialogEditProfile from "./ButtonDialogEditProfile";

import {getNameFromEmail} from "../utils";

export default function CardProfile() {
  // strings

  const hiString = "Hi";

  const emailString = "Email";
  const nameString = "Name";

  // data

  const profile = useProfile();
  console.log("profile", profile);

  // helper

  const email = profile.data?.user;
  let name = getNameFromEmail(email);
  if (profile.data?.name?.length > 0) name = profile.data.name;

  // helpers - strings

  const hiLabel = `${hiString} ${name} !`;
  const emailLabel = `${emailString}: `;
  const nameLabel = `${nameString}: `;

  return (
    <Paper sx={{p: 1}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>{hiLabel}</Typography>
        <ButtonDialogEditProfile />
      </Box>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography variant="body2" sx={{mr: 1}}>
          {nameLabel}
        </Typography>
        <Typography variant="body2">{name}</Typography>
      </Box>

      <Box sx={{display: "flex", alignItems: "center"}}>
        <Typography variant="body2" sx={{mr: 1}}>
          {emailLabel}
        </Typography>
        <Typography variant="body2">{email}</Typography>
      </Box>
    </Paper>
  );
}
