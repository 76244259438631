import {Box, IconButton} from "@mui/material";
import {ExpandMore as More, ExpandLess as Less} from "@mui/icons-material";

export default function ButtonExpand({itemId, expand, onExpandChange}) {
  function handleExpandChange() {
    onExpandChange(!expand);
  }
  return (
    <Box
      sx={{
        width: 20,
        height: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "inherit",
      }}
    >
      <IconButton size="small" color="inherit" onClick={handleExpandChange}>
        {expand ? <Less fontSize="small" /> : <More fontSize="small" />}
      </IconButton>
    </Box>
  );
}
