import React from "react";

import {
  Adjust as Opened,
  CheckCircleOutlined as Closed,
} from "@mui/icons-material";

import {Box} from "@mui/material";
export default function IsOpenedIcon({isOpened}) {
  return (
    <Box>
      {isOpened ? (
        <Box sx={{color: "common.green"}}>
          <Opened fontSize="small" color="inherit" />
        </Box>
      ) : (
        <Box sx={{color: "common.red"}}>
          <Closed fontSize="small" color="inherit" />
        </Box>
      )}
    </Box>
  );
}
