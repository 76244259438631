import {useState} from "react";

import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogTitle,
  TextField,
  Box,
  DialogActions,
  Button,
} from "@mui/material";

import SelectorArticleStyle from "./SelectorArticleStyle";

export default function FormEditArticle({article, onChange}) {
  const dispatch = useDispatch();

  // strings

  const numS = "Num";
  const nameS = "Désignation";
  const unitS = "Unité";
  const codeS = "Code";

  const numPlaceholder = "x.x.x";
  const namePlaceholder = "Désignation de l'article";
  const unitPlaceholder = "ml, m², m³,...";
  const codePlaceholder = "COF, BET, ...";

  // state

  const [num, setNum] = useState("");
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [code, setCode] = useState("");
  const [hideNum, setHideNum] = useState("");
  const [isTitle, setIsTitle] = useState(false);

  // helper

  const style = article?.style;
  const isAdded = article?.isAdded;

  // handler - onBlur

  function handleBlur() {
    onChange({...article, num, name, unit, code});
  }
  // handlers

  function handleNumChange(e) {
    setNum(e.target.value);
  }
  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleUnitChange(e) {
    setUnit(e.target.value);
  }
  function handleCodeChange(e) {
    let newCode = e.target.value;
    if (newCode) newCode = newCode.trim();
    setCode(newCode);
  }
  function handleHideNumChange(e) {
    setHideNum(e.target.value);
  }
  function handleIsTitleChange(e) {
    setIsTitle(e.target.value);
  }
  function handleStyleChange(value) {
    onChange({...article, style: value});
  }
  function handleIsAddedChange(value) {
    onChange({...article, isAdded: value});
  }

  return (
    <Box sx={{width: 500}}>
      <Box sx={{display: "flex", alignItems: "center", mb: 2, width: 1}}>
        <Box sx={{width: 100, mr: 1}}>
          <TextField
            placeholder={numPlaceholder}
            label={numS}
            value={num}
            size="small"
            onChange={handleNumChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{flexGrow: 1, mr: 1}}>
          <TextField
            fullWidth
            placeholder={namePlaceholder}
            label={nameS}
            value={name}
            size="small"
            onChange={handleNameChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{width: 100}}>
          <TextField
            placeholder={unitPlaceholder}
            label={unitS}
            value={unit}
            size="small"
            onChange={handleUnitChange}
            onBlur={handleBlur}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          width: 1,
        }}
      >
        <Box sx={{width: 100}}>
          <TextField
            placeholder={codePlaceholder}
            label={codeS}
            value={code}
            size="small"
            onChange={handleCodeChange}
            onBlur={handleBlur}
          />
        </Box>
      </Box>

      <SelectorArticleStyle
        style={style}
        isAdded={isAdded}
        onStyleChange={handleStyleChange}
        onIsAddedChange={handleIsAddedChange}
      />
    </Box>
  );
}
