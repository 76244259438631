export default function getGroupObjectsFromElementTypes(elementTypes, options) {
  let groups = [];
  const groupsMap = {};

  const sortByName = options?.sortByName;

  elementTypes.forEach((type) => {
    const exists = groupsMap[type.groupId];
    //
    if (!exists) {
      groups.push({
        id: type.groupId,
        name: type.group,
        code: type.groupCode,
        fromScene: type.fromScene,
      });
      groupsMap[type.groupId] = "ok";
    }
  });

  if (sortByName) groups.sort((a, b) => a.name.localeCompare(b.name));

  return groups;
}
