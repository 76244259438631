import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {updateProgressRecord} from "Features/progress/progressSlice";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Edit} from "@mui/icons-material";

import FormProgressRecord from "./FormProgressRecord";

import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonDialogUpdateProgressRecord({
  scene,
  progressRecord,
}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const editS = "Edit";

  const cancelS = "Cancel";
  const saveS = "Save";

  // data

  const userData = useSelector((s) => s.auth.data);

  // state

  const [tempProgressRecord, setTempProgressRecord] = useState(progressRecord);
  useEffect(() => setTempProgressRecord(progressRecord), [progressRecord?.id]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handleProgressRecordChange(progressRecord) {
    const updatedProgressRecord = {
      ...progressRecord,
      id: tempProgressRecord.id,
      createdBy: tempProgressRecord.createdBy
        ? tempProgressRecord.createdBy
        : userData.id,
    };
    setTempProgressRecord(updatedProgressRecord);
  }

  async function handleSave() {
    if (loading) return;

    setLoading(true);

    await dispatch(
      updateProgressRecord({accessToken, progressRecord: tempProgressRecord})
    );

    setLoading(false);
    setOpen(false);
  }

  function handleCancelClick() {
    setOpen(false);
  }

  return (
    <Box sx={{}}>
      <Button
        startIcon={<Edit />}
        color="inherit"
        size="small"
        onClick={handleOpenDialog}
      >
        {editS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editS}</DialogTitle>
        <DialogContent>
          <FormProgressRecord
            progressRecord={tempProgressRecord}
            onChange={handleProgressRecordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button onClick={handleSave}>{saveS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
