import Pointer from "./Pointer";
import Marker from "./Marker";
import LineMeasurement from "./LineMeasurement";
import SegmentDrawer from "./SegmentDrawer";

import {setMode, setStep} from "Features/viewer3D/viewer3DSlice";

export default class SceneEditor {
  pointerMode;
  pointer;
  dispatch;
  annotationsManager;

  lineMeasurement; // object created when measuring something.
  drawDirection; // to snap to direction.

  currentProcessAnnotation; // the annotation being rendered. used to delete.

  tempLineStroke; // lineStroke used to draw a line. Used when drawing lines from surfaces.

  zoneCorners; // array of point

  constructor({webViewer, dispatch, annotationsManager}) {
    this.markers = [];
    this.webViewer = webViewer;
    this.dispatch = dispatch;
    this.annotationsManager = annotationsManager;
    this.drawDirection = "DEFAULT"; // cf viewer3Dslice pdfDrawDirection
    this.zoneCorners = [];

    this.segmentDrawer = new SegmentDrawer({sceneEditor: this});

    this.mode = "DEFAULT"; // MEASURE, DRAW_SEGMENT

    this.extractedTextAnnotation = null; // used to highlight extracted text.
  }

  // setters

  setDrawDirection(value) {
    this.drawDirection = value; // DEFAULT, HORIZONTAL,VERTICAL
  }

  setZoneCorners(corners) {
    this.zoneCorners = corners;
  }

  setMode(mode) {
    this.mode = mode;
  }

  // POINTER

  createPointer() {
    if (this.pointer) return;
    const pointer = new Pointer();
    const pageContainer =
      this.webViewer.UI.iframeWindow.document.getElementById(
        "pageContainer" + 1
      );
    pageContainer.appendChild(pointer.element);
    this.pointer = pointer;
    this.pointer.disable();
  }

  createTempMarker() {
    if (this.tempMarker) return;
    const marker = new Marker();
    const pageContainer =
      this.webViewer.UI.iframeWindow.document.getElementById(
        "pageContainer" + 1
      );
    pageContainer.appendChild(marker.element);

    this.markers.push(marker);
    this.tempMarker = marker;
    this.tempMarker.hide();
  }

  updateMarkers({page}) {
    const zoom = this.getZoom();
    const pageContainer =
      this.webViewer.UI.iframeWindow.document.getElementById(
        "pageContainer" + page
      );
    this.markers.forEach((marker) => {
      marker.update({zoom});
      pageContainer.appendChild(marker.element);
    });
  }

  updatePointer() {
    const pageContainer =
      this.webViewer.UI.iframeWindow.document.getElementById(
        "pageContainer" + this.getPage()
      );
    this.pointer.update({zoom: this.getZoom()});
    pageContainer.appendChild(this.pointer.element);
  }

  getPage() {
    return this.webViewer.Core.documentViewer.getCurrentPage();
  }
  getZoom() {
    return this.webViewer.Core.documentViewer.getZoomLevel();
  }

  // pointer

  enablePointer() {
    this.pointerMode = true;
    if (this.pointer) this.pointer.enable();
  }
  disablePointer() {
    this.pointerMode = false;
    if (this.pointer) this.pointer.disable();
  }
  setPointerPosition({
    pageCoordinates,
    rawPageCoordinates,
    snapMode,
    snapped,
    pageNumber,
    pageRotation,
  }) {
    this.pointer.setPosition({
      pageCoordinates,
      rawPageCoordinates,
      pageRotation,
      zoom: this.getZoom(),
      pageNumber,
    });
    this.pointer.setStyle(snapped ? snapMode : null);
  }

  getPointerPosition() {
    return this.pointer.getPosition();
  }
  getPointerRawPosition() {
    return this.pointer.getRawPosition(); // without rotation, to compute annotations.
  }
  getPointerPageRotation() {
    return this.pointer.getPageRotation(); //to draw lines with snapping.
  }

  //temp marker

  setTempMarkerPosition({pageCoordinates}) {
    this.tempMarker.show();
    this.tempMarker.setPosition({pageCoordinates, zoom: this.getZoom()});
  }

  hideTempMarker() {
    if (this.tempMarker) this.tempMarker.hide();
  }
  saveTempMarker() {
    const pageCoordinates = this.tempMarker.getPosition();
    const marker = new Marker();
    marker.show();
    marker.setPosition({pageCoordinates, zoom: this.getZoom()});
    this.markers.push(marker);
  }

  // style

  // setCursor(type) {
  //   const body = this.webViewer.UI.iframeWindow.document.querySelector("body");
  //   body.style.cursor = `${type} !important`;
  // }

  /*
   * create a segment
   */

  startDrawSegment() {
    this.segmentDrawer.startDrawing();
  }

  /*
   * create a measure
   */

  startProcessMeasure() {
    //if (this.lineMeasurement) this.lineMeasurement.delete();
    this.enablePointer();
    this.setProcess("MEASURE");
    this.lineMeasurement = new LineMeasurement({sceneEditor: this});
  }

  /*
   * for all processes
   */

  cancelProcess() {
    if (this.mode === "DRAW_SEGEMENT") {
      this.segmentDrawer.reset();
    } else {
      this.dispatch(setMode("DEFAULT"));
      this.dispatch(setStep("DEFAULT"));
      this.disablePointer();
      this.enablePanTool();
      this.setMode("DEFAULT");

      this.annotationsManager.measurementsPdfManager.setIsDrawing(false);

      if (this.currentProcessAnnotation) {
        //this.annotationMananger.delete(this.currentProcessAnnotation); // we disable this to keep the drawn annotation.
        this.setCurrentProcessAnnotation(null);
      }
    }

    console.log("scene editor - stop process");
  }

  updateProcess({rawPageCoordinates, pageNumber, pageRotation, width}) {
    switch (this.mode) {
      case "MEASURE": {
        this.lineMeasurement.update({
          rawPageCoordinates,
          pageNumber,
          pageRotation,
          width,
        });
        break;
      }
      case "DRAW_SEGMENT": {
        this.segmentDrawer.updateOnMouseMove({
          rawPageCoordinates,
          pageNumber,
          pageRotation,
          width,
        });
        break;
      }
    }
  }

  stopProcess() {
    if (this.mode === "DRAW_SEGMENT") {
      this.segmentDrawer.stopDrawing();
    } else {
      this.dispatch(setMode("DEFAULT"));
      this.dispatch(setStep("DEFAULT"));
      this.disablePointer();
      this.enablePanTool();
      this.setMode("DEFAULT");
      if (this.currentProcessAnnotation) {
        //this.annotationMananger.delete(this.currentProcessAnnotation); // we disable this to keep the drawn annotation.
        this.setCurrentProcessAnnotation(null);
      }

      this.annotationsManager.measurementsPdfManager.setIsDrawing(false);
      //if (this.lineMeasurement) this.lineMeasurement.delete();

      console.log("scene editor - stop process");
    }
  }

  setCurrentProcessAnnotation(annotation) {
    this.currentProcessAnnotation = annotation;
  }

  // misc

  enablePanTool() {
    console.log("enablePanTool");
    setTimeout(() => this.webViewer.UI.setToolMode(["Pan"]), 30);
  }

  // snapping to zone corners

  snapToZoneCorners(x, y) {
    const point = {x, y};
    const points = this.zoneCorners;
    const [start, end] = this.getNearestPoints(points, point);
    const center = {x: (end.x + start.x) / 2, y: (end.y + start.y) / 2};
    const width = Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2);
    return {center, width};
  }

  getNearestPoints(points, point) {
    const dists = points.map(
      (p) => (p.x - point.x) ** 2 + (p.y - point.y) ** 2
    );
    const min1 = Math.min(...dists);
    const min2 = Math.min(...dists.filter((d) => d !== min1));
    const index1 = dists.indexOf(min1);
    const index2 = dists.indexOf(min2);
    return [points[index1], points[index2]];
  }

  getZoneCorners(pageNumber) {
    const corners =
      this.annotationsManager.measurementsPdfManager.getZoneCorners(pageNumber);
    console.log("getZoneCorners", corners);
    this.setZoneCorners(corners);
  }

  /*
   * Zones
   */

  hideZoneAnnotations() {
    this.annotationsManager.hideZoneAnnotations();
  }
  showZoneAnnotations() {
    this.annotationsManager.showZoneAnnotations();
  }

  /*
   * Extracted Text
   */

  highlightExtractedText(extractedText) {
    const {annotationManager, Annotations, documentViewer, Math} =
      this.webViewer.Core;
    const {pageNumber, rects} = extractedText;
    console.log("hightlightExtractText", extractedText);
    // quads
    const quads = rects.map(({x1, x2, y1, y2}) => {
      const rect = new Math.Rect(x1, y1, x2, y2);
      return rect.toQuad();
    });
    // annotations
    const annotation = new Annotations.TextHighlightAnnotation();
    annotation.Author = annotationManager.getCurrentUser();
    annotation.PageNumber = pageNumber;
    annotation.Quads = quads;
    annotation.StrokeColor = new Annotations.Color(0, 255, 255);
    //
    annotationManager.addAnnotations([annotation]);
    //
    this.extractedTextAnnotation = annotation;
  }

  removeHighlightedExtractedText() {
    if (this.extractedTextAnnotation) {
      const {annotationManager} = this.webViewer.Core;
      annotationManager.deleteAnnotation(this.extractedTextAnnotation);
      this.extractedTextAnnotation = null;
    }
  }
}
