import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedScene from "../useSelectedScene";

import {updateScene, updateRemoteScene} from "Features/scenes/scenesSlice";
import {setIsEditing, setEditedScene} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsSceneInSelectionPanel() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const {sceneId} = useParams();

  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.scenes.isEditing);
  const selectedScene = useSelectedScene();
  const editedScene = useSelector((s) => s.scenes.editedScene);

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  function handleEditClick() {
    dispatch(setIsEditing(true));
    dispatch(setEditedScene({...selectedScene}));
  }

  function handleCancelClick() {
    dispatch(setIsEditing(false));
  }

  async function handleSaveClick() {
    console.log("CLICKAA");

    // update scene
    if (!loading) {
      setLoading(true);
      const updateSceneProps = {scene: editedScene};
      console.log(
        "updateScene...",
        editedScene.imageClientUrl,
        selectedScene.imageClientUrl
      );
      if (
        editedScene.imageClientUrl &&
        editedScene.imageClientUrl !== selectedScene.imageClientUrl
      ) {
        const response = await fetch(editedScene.imageClientUrl);
        const blob = await response.blob();
        const fileName = `bb-${nanoid()}.png`;
        const file = new File([blob], fileName, {type: "image/png"});
        updateSceneProps.file = file;
        console.log("updateScene", updateSceneProps);
      }
      const result = await dispatch(updateScene(updateSceneProps));
      const updatedScene = unwrapResult(result);

      // remote scene
      const canUpdateRemoteScene = Boolean(editedScene.id && accessToken);
      if (canUpdateRemoteScene) {
        dispatch(
          updateRemoteScene({
            sceneClientId: editedScene.clientId,
            accessToken,
            updatedScene,
          })
        );
        setLoading(false);
      }
    }
    // end
    dispatch(setIsEditing(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* <Box sx={{flexGrow: 1}}>
        <Button variant="contained" color="primary" fullWidth>
          {deleteS}
        </Button>
      </Box> */}

      {!isEditing && sceneId && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
