import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {fetchSceneToSync, setSceneIsSyncing} from "Features/scenes/scenesSlice";

import useSyncActions from "Features/scenes/useSyncActions";
import getItemsMapById from "Utils/getItemsMapById";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useAutoSyncModels(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const [syncActions, triggerSyncAction] = useSyncActions(scene);
  const saveTriggeredAt = useSelector((s) => s.overviewer.saveTriggeredAt);

  console.log("syncActions", syncActions);

  // state

  const [syncingActionId, setSyncingActionId] = useState(null);

  // heper

  const actionIdTriggeredAtMap = {}; // used to prevent multi sync for the same save clik

  // helpers

  const actionIdsToSyncSorted = syncActions.map((action) => action.id).sort();
  const actionIdToTrigger = actionIdsToSyncSorted[0];

  const syncActionsMap = getItemsMapById(syncActions);

  async function syncAction(id) {
    const action = syncActionsMap[id];
    if (!action || !id) return;
    try {
      dispatch(setSceneIsSyncing(true));
      actionIdTriggeredAtMap[id] = saveTriggeredAt;
      setSyncingActionId(id);
      console.log(
        "[syncing action]",
        action.id,
        action.clientName,
        action.type
      );
      await triggerSyncAction(action);
      const message = `Données synchronisées : ${action.clientName}`;
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt}));
      setSyncingActionId(null);
      dispatch(setSceneIsSyncing(false));
    } catch (e) {
      setSyncingActionId(null);
      console.log("error syncing action", action.id, e);
    }
  }

  // effect : sync action by action

  useEffect(() => {
    const canSync =
      Boolean(triggerSyncAction) &&
      saveTriggeredAt &&
      actionIdToTrigger &&
      actionIdTriggeredAtMap[actionIdToTrigger] !== saveTriggeredAt;
    if (canSync) syncAction(actionIdToTrigger);
  }, [actionIdToTrigger, saveTriggeredAt, Boolean(triggerSyncAction)]);

  // effect : when no action, fetch remote versions to compare and specify actions

  useEffect(() => {
    if (syncActions.length === 0 && accessToken && scene?.id) {
      console.log("[EFFECT] fetch sceneToSync");
      dispatch(fetchSceneToSync({accessToken, sceneId: scene.id}));
    }
  }, [syncActions.length, accessToken, scene?.id]);
}
