import {useDispatch} from "react-redux";

import {Button, IconButton} from "@mui/material";
import {ArrowBackIos as Back, Close} from "@mui/icons-material";

import {setSelectedIssueId} from "../issuesSlice";

import {setSelectedIssueId as setSelectedIssueIdInHome} from "Features/ui/uiSlice";
import useSceneModule from "Features/navigation/useSceneModule";
import { setSelectedListItemId } from "Features/leftPanel/leftPanelSlice";

export default function ButtonBackIssuesInSelectionPanel() {
  const dispatch = useDispatch();

  // string

  const newS = "New";

  // data

  const sceneModule = useSceneModule();

  // helpers

  const label = sceneModule === "ISSUES" ? newS : "";

  // handlers

  function handleClick() {
    if (sceneModule !== "ISSUES") {
      dispatch(setSelectedIssueIdInHome(null));
      dispatch(setSelectedIssueId(null));
    } else {
      dispatch(setSelectedIssueId(null));
    }
    dispatch(setSelectedListItemId(null));
  }

  return (
    <>
      {sceneModule !== "ISSUES" ? (
        <IconButton size="small" onClick={handleClick}>
          <Close fontSize="small" />
        </IconButton>
      ) : (
        <Button startIcon={<Back />} size="small" onClick={handleClick}>
          {label}
        </Button>
      )}
    </>
  );
}
