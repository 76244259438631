import {Mesh, MeshLambertMaterial, MeshPhysicalMaterial} from "three";
import MaskGeometry from "./MaskGeometry";

export default class ImageMaskEntity {
  constructor({model, part}) {
    this.type = "IMAGE_MASK_ENTITY";
    this.modelId = model.id;
    this.model = model;

    this.name = part.name;
    //this.position = part.position ? part.position : {x: 0, y: 0, z: 0};
    //this.rotation = part.rotation ? part.rotation : {x: 0, y: 0, z: 0};
    this.mask = part.mask;
    this.partId = part.id;

    this.pickId = undefined; // used by the editor click handler
    this.selectionId = undefined; // will be the image model id

    this.material = new MeshLambertMaterial({
      color: 0x39ff14,
      //transparent: true,
      //opacity: 0.8,
      depthTest: true,
    });

    this.pickedMaterial = new MeshPhysicalMaterial({
      color: 0xfab41f, // yellow
      transparent: true,
      opacity: 0.9,
    });

    this.selectedMaterial = new MeshPhysicalMaterial({
      color: 0xf52585, // pink
      transparent: true,
      opacity: 0.9,
    });

    this.object = this.createObject();
  }

  createObject() {
    // geometry

    this.maskGeometry = new MaskGeometry({
      width: this.model.width,
      height: this.model.height,
      mask: this.mask,
    });
    const geometry = this.maskGeometry.geometry;

    // object

    const object = new Mesh(geometry, this.material);
    object.layers.enable(1);

    //object.position.set(this.position.x, this.position.y, this.position.z);
    //object.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);

    object.translateZ(0.01);

    return object;
  }

  /*
   * Pick & Select
   */

  pick() {
    this.pickId = this.partId;
    if (!this.selectionId) {
      this.object.material = this.pickedMaterial;
    }
    return this;
  }

  unpick() {
    this.pickId = undefined;
    if (this.selectionId) {
      this.object.material = this.selectedMaterial;
    } else {
      this.object.material = this.material;
    }
  }

  select() {
    this.selectionId = this.partId;
    this.object.material = this.selectedMaterial;
    return this;
  }

  unselect() {
    this.selectionId = undefined;
    if (this.pickId) {
      this.object.material = this.pickedMaterial;
    } else {
      this.object.material = this.material;
    }
  }

  parse() {
    return {
      type: "IMAGE_MASK_ENTITY",
      name: this.name,
      modelId: this.model.id,
      partId: this.partId,
      model: {
        ...this.model,
      },
    };
  }

  /*
   * Hide & Show
   */

  show() {
    this.object.layers.enable(1);
  }

  hide() {
    this.object.layers.disable(1);
  }
}
