import React from "react";

import useSelectedRoom from "../hooks/useSelectedRoom";

import {Box} from "@mui/material";

import ButtonRefreshRooms from "./ButtonRefreshRooms";
import ButtonExcel from "./ButtonExcel";

export default function ToolbarRoomsMain({scene}) {
  const selectedRoom = useSelectedRoom(scene);

  return (
    <Box sx={{p: 2, display: "flex", alignItems: "center", "&>*": {mr: 1}}}>
      <ButtonRefreshRooms scene={scene} />
      <ButtonExcel scene={scene} />
    </Box>
  );
}
