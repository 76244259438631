import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import SectionDataGridToolbar from "Features/progress/components/SectionDataGridToolbar";
import SectionProgressSelector from "Features/progress/components/SectionProgressSelector";
import ToolbarProgressMain from "Features/progress/components/ToolbarProgressMain";
import DataGridProgressRecords from "Features/progress/components/DataGridProgressRecords";
import DataGridMeasurementsProgress from "Features/progress/components/DataGridMeasurementsProgress";
import TabsProgress from "Features/progress/components/TabsProgress";
import SectionTitleProgress from "Features/progress/components/SectionTitleProgress";
import Stretch from "Features/ui/components/Stretch";

export default function SceneProgress() {
  // params

  const {scene} = useOutletContext();

  // data

  const openSections = useSelector((s) => s.ui.openSections);
  const index = useSelector((s) => s.progress.progressTabsIndex);

  // helpers - openSections

  const osHash = Object.values(openSections).join("-");

  return (
    <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
      <Box sx={{height: "60px"}} />
      <SectionTitleProgress />
      <SectionProgressSelector scene={scene} />
      <TabsProgress />
      <ToolbarProgressMain scene={scene} />
      <SectionDataGridToolbar />
      <Stretch propDependency={osHash}>
        <Box sx={{height: 1, width: 1}}>
          {index === 0 && (
            <DataGridProgressRecords scene={scene} />
          )}
          {index === 1 && (
            <DataGridMeasurementsProgress scene={scene} />
          )}
        </Box>
      </Stretch>
    </Box>
  );
}
