import {Box, Button, Typography, Paper} from "@mui/material";
import {Undo} from "@mui/icons-material";

export default function ContainerArticlesToSync({count, onSave, onCancel}) {
  // strings

  const cancelS = "Annuler";
  const saveS = "Synchroniser";

  // helpers

  const countLabel = count
    ? `${count} article${count > 1 ? "s" : ""} modifié${count > 1 ? "s" : ""}`
    : "Aucun article modifié";

  return (
    <Box sx={{p: 1}}>
      <Paper
        elevation={count > 0 ? 6 : 0}
        sx={{p: 1, ...(count > 0 && {bgcolor: "warning.main"})}}
      >
        <Box>
          <Typography
            variant="body2"
            color={count > 0 ? "common.white" : "text.secondary"}
          >
            {countLabel}
          </Typography>
        </Box>
        {count > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              "&>*:not(:last-child)": {mr: 1},
            }}
          >
            <Box sx={{flex: 1, color: "grey.300"}}>
              <Button
                fullWidth
                size="small"
                startIcon={<Undo />}
                onClick={onCancel}
                color="inherit"
                variant="outlined"
              >
                {cancelS}
              </Button>
            </Box>
            <Box sx={{flex: 1, color: "common.white"}}>
              <Button
                color="inherit"
                variant="outlined"
                fullWidth
                size="small"
                onClick={onSave}
              >
                {saveS}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
