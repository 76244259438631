export default function fillWorksheetWithMeasurementsByRoom({
  worksheet,
  measurementsByRoom,
  rowStartIndex,
}) {
  measurementsByRoom.forEach(
    ({code, sector, hsfp, surface, perimeter, envelop}, index) => {
      const row = worksheet.getRow(rowStartIndex + index);
      row.getCell(1).value = code;
      row.getCell(2).value = sector;
      row.getCell(3).value = hsfp;
      row.getCell(4).value = surface;
      row.getCell(5).value = perimeter;
      row.getCell(6).value = envelop;
      //row.getCell(7).value = room;
    }
  );
}
