import {useState} from "react";
import { useSelector } from "react-redux";

import {Menu, MenuItem, Typography, Button} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import DialogImportRessourcesFromExcel from "./DialogImportRessourcesFromExcel";

import sortByNum from "../utils/sortByNum";
import useRessourcesByScene from "../hooks/useRessourcesByScene";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";

export default function ButtonExcelRessources({scene}) {
  // strings

  const label = "Imp.Exp.";
  const exportS = "Export";
  const importS = "Import";

  const fileName = "ressources.xlsx";

  // data

  const selectedRessourceGroup = useSelector(
    (s) => s.ressources.selectedRessourceGroup
  );

  let ressources = useRessourcesByScene(scene);

  if (selectedRessourceGroup)
    ressources = ressources.filter((r) => r.group === selectedRessourceGroup);

  const ressourcesWithIds = ressources 
    .map((r) => ({
      ...r,
      group: r.group ? r.group : "-?-",
      num: r.num ? r.num : "0",
    }))
    .sort((a, b) => sortByNum(a.num, b.num))
    .sort((a, b) => a.group.localeCompare(b.group));
  //ressources = ressourcesForDataGrid(ressources);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openImportDialog, setOpenImportDialog] = useState(false);

  // helpers - handlers

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  async function handleExport() {
    setAnchorEl(null);
    const wb = await createExcelWorkbook({
      template: "RESSOURCES_WITH_IDS",
      data: ressourcesWithIds,
      withIds: true,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  // helpers - tools

  const tools = [
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
    },
  ];

  return (
    <>
      <Button
        endIcon={<More />}
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </Button>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogImportRessourcesFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
    </>
  );
}
