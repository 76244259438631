import {useSelector} from "react-redux";

import {Box, Paper, alpha, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import ToggleEdge from "./ToggleEdge";
// import ToggleGrid from "./ToggleGrid";
import ToggleSnapping from "./ToggleSnapping";
import CheckboxEnableOptim from "./CheckboxEnableOptim";
import ButtonDialogSettings from "./ButtonDialogSettings";

import CardHeight from "Features/viewer3D/components/CardHeight";
// import ButtonToggleImmersiveView from "Features/viewer3D/components/ButtonToggleImmersiveView";
// import ToolPdfBottomBar from "Features/pdf/components/ToolPdfBottomBar";
// import ToolMapBottomBar from "Features/map/components/ToolMapBottomBar";
// import ToggleAlignNorth from "Features/viewer3D/components/ToggleAlignNorth";

export default function ToolbarSettings({
  editor3d,
  // viewer,
  // scene,
  // space,
  // mobile,
  landingPage,
  onClose,
}) {
  const showEdge = useSelector((state) => state.viewer3D.showEdge);
  // const viewMode = useSelector((state) => state.viewer3D.viewMode);

  // helper

  // const multiviews = viewMode === "MULTIVIEWS";
  // const modePdf = viewMode === "PDF";
  // const modeMap = viewMode === "MAP";
  // const mode3D = viewMode === "3D";

  return (
    <Paper
      elevation={12}
      sx={{
        width: 1,
        display: landingPage ? "none" : "block",
        bgcolor: "common.white",
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 44,
          bgcolor: (theme) => alpha(theme.palette.background.default, 0.8),
          display: "flex",
          alignItems: "center",
          py: 0.5,
          pl: 2,
          pr: 1,
          justifyContent: "space-between",
        }}
      >
        <IconButton size="small" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
        <Box sx={{display: "flex", alignItems: "center", "&>*": {mr: 2}}}>
          <ButtonDialogSettings editor3d={editor3d} />

          <ToggleSnapping editor3d={editor3d} />
          {/* <ToggleAlignNorth editor3d={editor3d} scene={scene} /> */}
          <Box sx={{p: 1}} />
          <ToggleEdge editor3d={editor3d} />
          {showEdge && <CheckboxEnableOptim editor3d={editor3d} />}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <CardHeight editor3d={editor3d} />
          {/* {multiviews && <ButtonToggleImmersiveView editor3d={editor3d} />} */}
        </Box>
      </Box>
    </Paper>
  );
}
