import {Box, List, ListItemButton} from "@mui/material";

import ListItemElementType from "./ListItemElementType";
import sortByNum from "Utils/sortByNum";

export default function ListElementTypes({
  selectedElementType,
  elementTypes = [],
  onClick,
  options,
}) {
  // options

  const enableClickOnTitle = options?.enableClickOnTitle;

  function handleClick(elementType) {
    if (!enableClickOnTitle && elementType.isTitle) return;
    if (onClick) onClick(elementType);
  }

  return (
    <List dense sx={{width: 1}}>
      {elementTypes
        .sort((a, b) => sortByNum(a.num, b.num))
        .map((elementType) => (
          <ListItemButton
            disableGutters
            sx={{p: 0}}
            key={elementType?.id ?? elementType.name}
            selected={elementType?.id === selectedElementType?.id}
            onClick={() => handleClick(elementType)}
          >
            <ListItemElementType
              key={elementType.id}
              elementType={elementType}
              options={options}
              selected={elementType?.id === selectedElementType?.id}
            />
          </ListItemButton>
        ))}
    </List>
  );
}
