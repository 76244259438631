import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {Menu, MenuItem, Box, Typography, Button} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import useRoomsByScene from "../hooks/useRoomsByScene";
import roomsForDataGrid from "../utils/roomsForDataGrid";
import DialogImportRoomsFromExcel from "./DialogImportRoomsFromExcel";

export default function ButtonExcel({editor, scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const label = "Excel";
  const exportS = "Export";
  const importS = "Import";

  const fileName = "zones.xlsx";

  // data

  let rooms = useRoomsByScene(scene);
  rooms = roomsForDataGrid(rooms);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openImportDialog, setOpenImportDialog] = useState(false);

  // helpers - handlers

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  async function handleExport() {
    setAnchorEl(null);
    const wb = await createExcelWorkbook({template: "ROOMS", data: rooms});
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  // helpers - tools

  const tools = [
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
    },
  ];

  return (
    <>
      <Button
        endIcon={<More />}
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </Button>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogImportRoomsFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
    </>
  );
}
