import {useSelector, useDispatch} from "react-redux";

import {
  setSelectedElementTypeGroupIdsInHome,
  setSelectedPdfIdsInHome,
} from "../boxhomeSlice";

import {Box} from "@mui/material";
import SwitchLinkedPdfsOnly from "./SwitchLinkedPdfsOnly";

import ListElementTypeGroupsMultiple from "Features/elementTypes/components/ListElementTypeGroupsMultiple";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";

import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import ListInputsMultiple from "Features/inputs/components/ListInputsMultiple";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function SectionPdfsInHome({scene}) {
  const dispatch = useDispatch();

  // data

  const linkedPdfsOnly = useSelector((s) => s.boxhome.linkedPdfsOnly);

  const inputs = useInputsByScene(scene, {
    filterByScope: true,
    filterByVersion: true,
  });

  const packages = usePackagesByScene(scene, {
    filterByScope: true,
    filterByVersion: true,
  });

  const pdfIds = useSelector((s) => s.boxhome.selectedPdfIdsInHome);

  const selectedTypeGroupIdsInHome = useSelector(
    (s) => s.boxhome.selectedElementTypeGroupIdsInHome
  );

  // helpers

  const selection = [...pdfIds];

  // helpers - relevant packages and pdfs

  const selectedGroupId = selectedTypeGroupIdsInHome?.[0];
  const relevantPackages = packages.filter(
    (model) =>
      model.elementTypesGroupIds?.includes(selectedGroupId) ||
      model.restrictedTypes?.includes(selectedGroupId)
  );

  const relevantPdfIdsMap = {};
  relevantPackages.forEach((model) => {
    const pdfId = model.fromModel.modelId;
    relevantPdfIdsMap[pdfId] = "ok";
  });
  const relevantPdfIds = Object.keys(relevantPdfIdsMap);

  // helpers - pdfs

  let pdfs = [...inputs];
  if (selectedGroupId && linkedPdfsOnly)
    pdfs = pdfs.filter((pdf) => relevantPdfIds.includes(pdf.id));
  // handlers

  function handleSelectionChange(ids) {
    console.log("ids", ids);
    dispatch(setSelectedPdfIdsInHome(ids));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyItems: "stretch",
        minHeight: 0,
      }}
    >
      <Box sx={{py: 1}}>
        <SwitchLinkedPdfsOnly />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyItems: "stretch",
          minHeight: 0,
          overflowY: "auto",
        }}
      >
        <ListInputsMultiple
          scene={scene}
          inputs={pdfs}
          selection={selection}
          onSelectionChange={handleSelectionChange}
          multipleSelection={false}
        />
      </Box>
    </Box>
  );
}
