export default function getGroupTypesMap(elementTypes) {
  const map = {};

  elementTypes.forEach((type) => {
    const group = type.group;
    const groupTypes = map[group];
    if (!groupTypes) {
      map[group] = [type];
    } else {
      map[group] = [...groupTypes, type];
    }
  });

  return map;
}
