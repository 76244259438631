import React from "react";

import useDrawingShapeImageUrlMap from "../hooks/useDrawingShapeImageUrlMap";
import useDrawingShapeDescriptionMap from "../hooks/useDrawingShapeDescriptionMap";
import useDrawingShapeNameMap from "../hooks/useDrawingShapeNameMap";

import {Box, Typography, Paper} from "@mui/material";

import ImageBasic from "Features/images/components/ImageBasic";

export default function SelectorDrawingShapeFast({drawingShape, onChange}) {
  // data

  const imageUrlMap = useDrawingShapeImageUrlMap();
  const nameMap = useDrawingShapeNameMap();
  const descriptionMap = useDrawingShapeDescriptionMap();

  // helpers

  let shapes = ["SEGMENT", "POLYLINE", "POLYGON"];
  shapes = shapes.map((code) => ({
    code,
    name: nameMap[code],
    imageUrl: imageUrlMap[code],
    description: descriptionMap[code],
  }));

  // handlers

  function handleSelect(code) {
    onChange(code);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {shapes.map((shape) => {
        const selected = shape.code === drawingShape;
        return (
          <Paper
            key={shape.code}
            elevation={selected ? 12 : 0}
            sx={{cursor: "pointer", width: 200, height: 220}}
            onClick={() => {
              handleSelect(shape.code);
            }}
          >
            <Box sx={{width: 1, height: 140}}>
              <ImageBasic url={shape.imageUrl} />
            </Box>
            <Box sx={{p: 1}}>
              <Typography gutterBottom>{shape.name}</Typography>
              <Typography sx={{fontSize: 13}} color="text.secondary">
                {shape.description}
              </Typography>
            </Box>
          </Paper>
        );
      })}
    </Box>
  );
}
