import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setTitle} from "Features/textEditor/editorSlice";

import {InputBase, Box, styled} from "@mui/material";

const Input = styled(InputBase)(({theme}) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: "bold",
  width: "100%",
}));

export default function TitleInput({value, onChange, editing}) {
  function handleTitleChange(e) {
    onChange(e.target.value);
  }
  return (
    <Box sx={{width: 1, px: 1, pt: 1}}>
      <Input
        placeholder="Ajouter un titre à la note"
        value={value}
        onChange={handleTitleChange}
        readOnly={!editing}
        multiline
      />
    </Box>
  );
}
