import { useSelector } from "react-redux";

import NavBar from "./NavBar";
import makeStyles from '@mui/styles/makeStyles';
import {useIsAuthenticated} from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  root: {},
  main: {
    padding: theme.spacing(0),
  },
}));

const PageLayoutSimple = (props) => {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const {id: authenticatedUserId} = useSelector(
    (state) => state.auth.data
  );

  return (
    <div className={classes.root}>
      <NavBar />
      {isAuthenticated && authenticatedUserId && (
        <div className={classes.main}>{props.children}</div>
      )}
    </div>
  );
};
export default PageLayoutSimple;
