/*
 * material : {id,num,code,name}
 */
import {nanoid} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneMaterials(scene) {
  // anonym materials = materials identified with their code only.

  const dispatch = useDispatch();

  const updateSceneMaterial = (materials) => {
    if (!scene) return;
    const materialsIds = materials.map((r) => r.id);
    const sceneMaterialsPrev = scene?.data?.materials
      ? scene.data.materials.filter((r) => r.id)
      : [];

    // unchanged
    let otherMaterials = sceneMaterialsPrev.filter((r) => !materialsIds.includes(r.id));

    // updated
    let updatedPrevMaterials = sceneMaterialsPrev.filter((r) =>
      materialsIds.includes(r.id)
    );
    let updatedNextMaterials = [];
    if (updatedPrevMaterials) {
      updatedNextMaterials = updatedPrevMaterials.map((prevMaterial) => {
        const newMaterial = materials.find((r) => r.id === prevMaterial.id);
        return {...prevMaterial, ...newMaterial};
      });
    }

    // news
    let prevIds = sceneMaterialsPrev.map((r) => r.id);
    let newMaterials = materials.filter((r) => !prevIds.includes(r.id) || !r.id);
    newMaterials = newMaterials.map((r) => ({...r, id: nanoid()}));

    // sceneMaterials

    const sceneMaterials = [
      ...otherMaterials,
      ...updatedNextMaterials.filter((r) => !r.toDelete),
      ...newMaterials,
    ];

    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        materials: sceneMaterials,
      },
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneMaterial;
}
