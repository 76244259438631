import {useState, useEffect} from "react";

import {isJsonString} from "Utils/dataValidations";

import {useDispatch, useSelector} from "react-redux";
import {fetchNote} from "Features/note/noteSlice";
import {unwrapResult} from "@reduxjs/toolkit";

import useAccessToken from "Features/auth/useAccessToken";

export default function useNote({listingId, noteId}) {
  const dispatch = useDispatch();

  // initialization

  let initialDetailedNote; // in state.note
  let initialNote; // in state.notes
  let _initialNote; // initial note to be returned by this hook

  // first look current detailed note
  initialDetailedNote = useSelector((state) => state.note.notes[noteId]);
  // then look state.notes
  const notes = useSelector((state) => state.notes.datasets[listingId]?.data);
  initialNote = notes?.find((note) => note.id === noteId);

  // compute _initialNote
  if (initialDetailedNote) {
    _initialNote = initialDetailedNote;
  } else if (initialNote) {
    _initialNote = initialNote;
  }

  // local state

  const [note, setNote] = useState(_initialNote);
  const [status, setStatus] = useState("idle");

  // access

  const accessToken = useAccessToken();

  // handlers

  async function handleFetchNote() {
    try {
      setStatus("pending");
      const action = await dispatch(
        fetchNote({accessToken, listingId, noteId})
      );
      const result = unwrapResult(action);
      setNote(result.data);
      console.log("set fetched note");
    } catch (er) {
      console.log(er);
    } finally {
      setStatus("succeed");
    }
  }

  // update effect

  useEffect(() => {
    if (accessToken && noteId) {
      handleFetchNote();
    }
  }, [accessToken, Boolean(initialDetailedNote), Boolean(initialNote)]);

  // trigger when the note is updated.
  useEffect(() => {
    console.log("effect : setNote");
    setNote(_initialNote);
  }, [_initialNote]);

  // return hook

  return [note, status];
}
