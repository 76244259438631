import {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";

//import Konva from "konva";

import {Box} from "@mui/material";

import Toolbar from "./components/Toolbar";
import Annotator from "./js/Annotator";
import {downloadURI} from "./utils";

import theme from "Styles/theme";

import {getImageSize} from "Features/images/imageUtils";

export default function ImageAnnotator({
  editor3d,
  url,
  width,
  height,
  mobile,
  viewer,
  editorPdf,
}) {
  const containerRef = useRef();
  const annotatorRef = useRef();

  //Konva.hitOnDragEnabled = true;

  // data

  const fullscreen = useSelector((s) => s.ui.fullscreen);

  // state

  const [tool, setTool] = useState("FREE");
  const [color, setColor] = useState(theme.palette.primary.main);
  const [_, setWritting] = useState(false);
  const [__, setTextPosition] = useState({x: 0, y: 0});
  // const [text, setText] = useState("");

  // handlers

  function handleToolChange(newTool) {
    setTool(newTool);
    annotatorRef.current.setMode(newTool);
  }

  function handleColorChange(newColor) {
    setColor(newColor);
    annotatorRef.current.setColor(newColor);
  }

  function handleDownload() {
    const dataURL = annotatorRef.current?.stage.toDataURL();
    downloadURI(dataURL, "screenshot.png");
  }

  function handleWritting(writting) {
    setWritting(writting);
  }

  function handleTextPosition(position) {
    setTextPosition({x: position.x, y: position.y});
    console.log(position.x, position.y);
  }

  function handleUndo() {
    annotatorRef.current?.undo();
  }

  async function handleCopy() {
    if (window.ClipboardItem) {
      const dataURL = annotatorRef.current?.stage.toDataURL();
      const response = await fetch(dataURL);
      const blob = await response.blob();
      await navigator.clipboard.write([
        new window.ClipboardItem({"image/png": blob}),
      ]);
    }
  }

  async function handleAddToPdf() {
    const imageUrl = annotatorRef.current?.stage.toDataURL();
    const imageSize = await getImageSize({fileURL: imageUrl});
    const positionInPdf = {x: 50, y: 50, dim: 500};
    editorPdf.addImageAnnotation({imageUrl, imageSize}, positionInPdf);
  }

  // init

  function init() {
    const width = containerRef.current.getBoundingClientRect().width;
    const height = containerRef.current.getBoundingClientRect().height;

    annotatorRef.current = new Annotator({
      container: "container",
      width,
      height,
      onWritting: handleWritting,
      onTextPosition: handleTextPosition,
      onModeChange: handleToolChange,
    });

    if (editor3d) editor3d.annotator = annotatorRef.current;
  }

  // effect

  useEffect(() => {
    init();
    return () => {
      annotatorRef.current.clear();
    };
  }, []);

  useEffect(() => {
    if (url) annotatorRef.current.addImage(url);
  }, [url]);

  return (
    <Box sx={{width, height, position: "relative"}}>
      <Box
        sx={{
          zIndex: 10,
          position: "absolute",
          top: (theme) =>
            fullscreen || viewer ? theme.spacing(2) : theme.spacing(7),
          left: (theme) => theme.spacing(2),
        }}
      >
        <Toolbar
          tool={tool}
          onChange={handleToolChange}
          color={color}
          onColorChange={handleColorChange}
          onDownload={handleDownload}
          onCopy={handleCopy}
          onUndo={handleUndo}
          mobile={mobile}
          onAddToPdf={handleAddToPdf}
        />
      </Box>

      <div
        id="container"
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: "0",
        }}
      ></div>
    </Box>
  );
}
