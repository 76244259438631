import {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import {
  // setIsViewerVisible,
  setIsViewerFullScreen,
} from "Features/viewer3D/viewer3DSlice";
import {setOpenSections} from "Features/ui/uiSlice";
import useIssuesetsInScope from "Features/issues/useIssuesetsInScope";
// import useScene from "Features/scenes/useScene";
import useSelectedIssue from "Features/issues/useSelectedIssue";
import Stretch from "Features/ui/components/Stretch";
import ToolbarPhasesMain from "Features/phases/components/ToolbarPhasesMain";
import DataGridPhases from "Features/phases/components/DataGridPhases";

export default function ScenePhases() {
  const dispatch = useDispatch();

  // params

  const {scene} = useOutletContext();
  // const {sceneId} = useParams();
  // const scene = useScene(sceneId);

  // data

  const [modelds, modelsIds, modelsIdsHash] = useIssuesetsInScope();
  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  const selectedIssue = useSelectedIssue();

  // helpers - openSections

  let openSections = useSelector((s) => s.ui.openSections);

  const osHash = Object.values(openSections).join("-");

  openSections = {...openSections, outlet: true, callToAction: false};
  if (
    !openSections.fixedViewersBox &&
    !openSections.viewer3D &&
    !openSections.callToAction
  ) {
    //openSections.callToAction = true;
  } else if (!selectedIssue?.id && !selectedModelId) {
    // case :
    // no selection => display callToAction
    // openSections.callToAction = true;
    // openSections.viewer3D = false;
    // openSections.fixedViewersBox = false;
  }

  // effects

  useEffect(() => {
    dispatch(setIsViewerFullScreen(false));
  }, []);

  // effect - openSections

  useEffect(() => {
    dispatch(setOpenSections(openSections));
  }, [
    openSections.viewer3D,
    openSections.fixedViewersBox,
    openSections.callToAction,
    openSections.outlet,
  ]);

  return (
    <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
      <Box sx={{height: "68px"}} />

      <ToolbarPhasesMain scene={scene} />

      <Stretch propDependency={modelsIdsHash + osHash}>
        <Box sx={{height: 1, width: 1}}>
          <DataGridPhases scene={scene} />
        </Box>
      </Stretch>
    </Box>
  );
}
