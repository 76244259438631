import React, {useEffect, useState} from "react";

import MapEditor from "../js/MapEditor";

import {useSelector, useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {
  setClickedObject,
  setMapClickedProps,
} from "Features/viewer3D/viewer3DSlice";

import {Box} from "@mui/material";

import AzureMap from "./AzureMap";
import GoogleMap from "./GoogleMap";
import Layers from "./Layers";

import useWorksites from "Features/worksites/useWorksites";
import useWorksite from "Features/worksites/useWorksite";

export default function MapViewer({scene, editor}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  // const

  const mapInitOptions = {
    lat: 48.85303399657422,
    lng: 2.350214329829216,
    zoom: 12,
  };

  // data

  const page = searchParams.get("page");

  const rendererSize = useSelector((s) => s.viewer3D.rendererSize);
  //const viewMode = useSelector((s) => s.viewer3D.viewMode); // usefull when map from viewer3D
  //const landingPage = useSelector((s) => s.viewer3D.landingPage); // landing page

  const worksites = useWorksites();
  const worksiteId = useSelector((s) => s.space.worksiteId);
  const worksite = useWorksite({worksiteId});

  const mapProvider = useSelector((s) => s.viewer3D.mapProvider);

  const azmEditorIsLoaded = useSelector((s) => s.viewer3D.azmEditorIsLoaded);

  // state

  const [mapEditorIsCreated, setMapEditorIsCreated] = useState(false); // to trigger editors creation.

  // helper

  //const mapMode = viewMode === "MAP";

  const lat = worksite?.lat;
  const lng = worksite?.lng;

  // helper - display

  const azureVisible = mapProvider === "AZURE";
  const googleVisible = mapProvider === "GOOGLE";

  // helper functions

  function showWorksites() {
    editor.mapEditor.azmEditor.showWorksites();
  }

  // handlers

  function handleMapClick(e) {
    const action = e.action;
    switch (action) {
      case "goToWorksite": {
        setSearchParams({worksite: e.worksiteId});
        break;
      }
      case "seeDetail": {
        dispatch(setClickedObject(e.clickedObject));
        break;
      }
      case "void": {
        dispatch(setClickedObject({}));
        dispatch(setMapClickedProps(e));
        break;
      }
    }
  }

  // effects

  useEffect(() => {
    if (editor) {
      editor.mapEditor = new MapEditor({editor, onClick: handleMapClick});
      setMapEditorIsCreated(true);
    }
  }, [editor]);

  useEffect(() => {
    if (azmEditorIsLoaded && editor) {
      showWorksites();
    }
  }, [azmEditorIsLoaded, editor]);

  useEffect(() => {
    if (typeof lat === "number" && typeof lng === "number") {
      editor.mapEditor.azmEditor.goTo({lat, lng});
    }
  }, [azmEditorIsLoaded, lat, lng]);

  // scene latlng

  useEffect(() => {
    if (
      azmEditorIsLoaded &&
      typeof scene?.lat === "number" &&
      typeof scene?.lng === "number"
    ) {
      editor.mapEditor.azmEditor.setOriginLatLng({
        lat: scene.lat,
        lng: scene.lng,
      });
    }
  }, [scene?.lat, scene?.lng, azmEditorIsLoaded]);

  return (
    <>
      <Box
        sx={{
          visibility: azureVisible ? "visible" : "hidden",
          position: "absolute",
          top: 0,
          height: rendererSize.height,
          right: 0,
          left: 0,
          zIndex: 30,
          bgcolor: "common.white",
          //opacity: 0.9,
        }}
      >
        <Box sx={{width: 1, height: 1, position: "relative"}}>
          <AzureMap
            worksites={worksites}
            editor={editor}
            onClick={handleMapClick}
            mapEditorIsCreated={mapEditorIsCreated}
            mapInitOptions={mapInitOptions}
          />

          {false && <Layers editor={editor} scene={scene} />}
        </Box>
      </Box>

      <Box
        sx={{
          visibility: googleVisible ? "visible" : "hidden",
          position: "absolute",
          top: 0,
          height: rendererSize.height,
          right: 0,
          left: 0,
          zIndex: 30,
          bgcolor: "common.white",
          //opacity: 0.9,
        }}
      >
        <Box sx={{width: 1, height: 1, position: "relative"}}>
          <GoogleMap
            worksites={worksites}
            editor={editor}
            onClick={handleMapClick}
            mapEditorIsCreated={mapEditorIsCreated}
            mapInitOptions={mapInitOptions}
          />
          <Layers editor={editor} scene={scene} />
        </Box>
      </Box>
    </>
  );
}
