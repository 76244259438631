import {AddBox} from "@mui/icons-material";
import {MathUtils, Object3D, MeshBasicMaterial, BoxGeometry, Mesh} from "three";
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer.js";

import {setSelection} from "Features/viewer3D/viewer3DSlice";

import theme from "Styles/theme";

const material = new MeshBasicMaterial({color: 0x00ff00});
const pickMaterial = new MeshBasicMaterial({color: 0xffff00});

export default class CaplaElement {
  object;
  objectCSS;

  position;
  caplaModel;

  color;
  selectedColor;
  pickedColor;

  selectionId;
  pickId;

  isObjectCSSSelected; // selectionId & pickId do not work here...
  onClick; // trigger when the user click on the element.

  constructor({position, caplaModel, codeName, color, onClick}) {
    this.type = "CAPLA_ELEMENT";
    this.entityID = codeName;

    this.position = position;
    this.caplaModel = caplaModel;
    this.codeName = codeName;
    this.modelId = this.caplaModel.modelId;

    this.color = color ? color : theme.palette.common.caplaBlack;
    this.selectedColor = theme.palette.primary.main;
    this.pickedColor = theme.palette.common.yellow;

    this.isObjectCSSSelected = false;

    this.onClick = (element) => onClick(element);

    //this.createObject();
    this.createObjectCSS();
    this.addDivElementListeners();
  }

  createObject() {
    let w, h, d;
    w = h = d = 1;

    const box = new Mesh(new BoxGeometry(w, h, d), material);
    box.position.set(this.position.x, this.position.y, this.position.z);
    box.layers.enable(1);
    this.caplaModel.object.add(box);

    this.object = box;
  }

  createObjectCSS() {
    const divElement = document.createElement("div");

    const svgElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    svgElement.setAttribute("viewBox", "0 0 100 100");
    svgElement.setAttribute("fill", "none");

    //divElement.append(svgElement);

    const pathElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    pathElement.setAttribute(
      "d",
      "m 10 10 l 80 0 l 0 80 l -80 0 z m 40 0 l 0 20 m 0 40 l 0 20 m -40 -40 l 20 0 m 40 0 l 20 0"
    );
    // pathElement.setAttribute("height", "100px");
    // pathElement.setAttribute("width", "100px");

    pathElement.setAttribute("stroke", this.color);
    pathElement.setAttribute("stroke-width", "4px");

    svgElement.append(pathElement);

    divElement.style.height = "34px";
    divElement.style.width = "34px";
    //divElement.style.borderRadius = "12px";
    divElement.style.transform = "translate(-50%,-50%)";
    divElement.style.border = `1px solid ${this.color}`;

    const objectCSS = new CSS2DObject(divElement);

    this.caplaModel.object.add(objectCSS);

    objectCSS.position.set(this.position.x, this.position.y, this.position.z);

    //objectCSS.layers.enable(1);

    this.objectCSS = objectCSS;
  }

  addDivElementListeners() {
    this.objectCSS.element.addEventListener("pointerdown", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.onClick(this.parse());
      // if (this.isObjectCSSSelected) {
      //   this.unselectDivElement();
      // } else {
      //   this.selectDivElement();
      // }
    });

    this.objectCSS.element.addEventListener("pointerup", (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    this.objectCSS.element.addEventListener("pointerover", () => {
      this.pickDivElement();
    });

    this.objectCSS.element.addEventListener("pointerout", () => {
      this.unpickDivElement();
    });
  }

  /*
   * Selection
   */

  pick() {
    this.object.material = pickMaterial;
    this.pickId = this.codeName;
    return this;
  }

  pickDivElement() {
    this.caplaModel.editor.disableClickHandlers(); // used to prevent click propagation.
    if (!this.isObjectCSSSelected) {
      this.objectCSS.element.style.border = `2px solid ${this.pickedColor}`;
      this.objectCSS.element.style.cursor = "pointer";

      const path = this.objectCSS.element?.firstChild?.firstChild;
      if (path) {
        path.setAttribute("stroke-width", "8px");
        path.setAttribute("stroke", this.pickedColor);
      }
    }
  }

  unpick() {
    this.object.material = material;
    this.pickId = null;
  }

  unpickDivElement() {
    this.caplaModel.editor.enableClickHandlers();
    if (!this.isObjectCSSSelected) {
      this.objectCSS.element.style.border = `1px solid ${this.color}`;
      this.objectCSS.element.style.cursor = "inherit";

      const path = this.objectCSS.element?.firstChild?.firstChild;
      if (path) {
        path.setAttribute("stroke-width", "4px");
        path.setAttribute("stroke", this.color);
      }
    }
  }

  select() {
    this.selectionId = this.codeName;
    this.selectDivElement();
    return this;
  }

  selectDivElement() {
    this.isObjectCSSSelected = true;
    this.objectCSS.element.style.border = `2px solid ${this.selectedColor}`;
  }

  unselect() {
    this.selectionId = undefined;
    this.unselectDivElement();
  }

  unselectDivElement() {
    this.objectCSS.element.style.border = `1px solid ${this.color}`;
    this.isObjectCSSSelected = false;
  }

  parse() {
    return {
      type: "CAPLA_ELEMENT",
      name: this.codeName,
      codeName: this.codeName,
      modelId: this.modelId,
      position: this.position,
    };
  }
  /*
   * Show / Hide
   */

  hide() {
    if (this.object) this.object.layers.disable(1);
    if (this.objectCSS) this.objectCSS.element.hidden = true;
  }

  show() {
    if (this.object) this.object.layers.enable(1);
    if (this.objectCSS) this.objectCSS.element.hidden = false;
  }

  // deletion

  delete() {
    this.objectCSS.element.hidden = true;
    this.caplaModel.editor.scene.remove(this.objectCSS);
  }
}
