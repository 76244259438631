import {Box} from "@mui/material";

import ButtonExcelSectors from "./ButtonExcelSectors";

// import useSelectedSector from "../hooks/useSelectedSector";

export default function ToolbarSectorsMain({scene}) {
  // const selectedSector = useSelectedSector(scene);

  return (
    <Box sx={{p: 2, display: "flex"}}>
      <ButtonExcelSectors scene={scene} />
    </Box>
  );
}
