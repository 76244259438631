import addRessourceWorksheetToWorkbook from "./addRessourceWorksheetToWorkbook";
import addRessourcesByRoomsWorksheet from "./addRessourcesByRoomsWorksheet";
import addRessourcesByVersionsWorksheet from "./addRessourcesByVersionsWorksheet";
import fillWorksheetWithMeasurements from "./fillWorksheetWithMeasurements";
import fillWorksheetWithMeasurementsByTypes from "./fillWorksheetWithMeasurementsByTypes";
import fillWorksheetWithMeasurementsWithRessources from "./fillWorksheetWithMeasurementsWithRessources";
import fillWorksheetWithRessourcesTypesRelations from "./fillWorksheetWithRessourcesTypesRelations";
// import fillWorksheetWithRessourcesByRoom from "./fillWorksheetWithRessourcesByRoom";
import fillWorksheetWithRooms from "./fillWorksheetWithRooms";


export default function fillWorkbookWithAllData({workbook, data}) {
  const ressourceWorksheetTemplate = workbook.getWorksheet("ressources");
  const elementWorksheet = workbook.getWorksheet("éléments");
  const dataWorksheet = workbook.getWorksheet("data");
  const roomsWorksheet = workbook.getWorksheet("zones");
  const byRoomWorksheet = workbook.getWorksheet("DPGF par zone");
  const relationsWorksheet = workbook.getWorksheet("hypothèses");
  const byVersionWorksheet = workbook.getWorksheet("DPGF par version");
  const byTypeWorksheet =  workbook.getWorksheet("types d'éléments");

  let groups = [...new Set(data.byRessource.map((r) => r.group))].filter(
    (group) => group
  );
  if (data.groupsInScope?.length > 0 && (data.groupsInScope.length !== 1 || data.groupsInScope[0]))
    groups = data.groupsInScope; 

  const buildingRessources = {};
  data.measurementsWithRessources.forEach((m) => {
    const key = m.ressource_group + m.ressource_num;
    const value = m.ressource_quantity_value;
    const building = m.sector_building;
    const sector = m.sector_name;
    if (building in buildingRessources) {
      if (sector in buildingRessources[building]) {
        if (key in buildingRessources[building][sector]) {
          buildingRessources[building][sector][key] += value;
        } else {
          buildingRessources[building][sector][key] = value;
        }
      } else {
        buildingRessources[building][sector] = {[key]: value, name: sector, z: m.sector_z};
      }
    } else {
      buildingRessources[building] = {[sector]: {[key]: value, name: sector, z: m.sector_z}};
    }
  })

  const buildingTypes = {};
  if (data.measurementsWithTypes) {
    data.measurementsWithTypes.forEach((m) => {
      const key = m.element_type_group + m.element_type_num;
      const value = m.element_type_quantity_value;
      const building = m.sector_building;
      const sector = m.sector_name;
      if (building in buildingTypes) {
        if (sector in buildingTypes[building]) {
          if (key in buildingTypes[building][sector]) {
            buildingTypes[building][sector][key] += value;
          } else {
            buildingTypes[building][sector][key] = value;
          }
        } else {
          buildingTypes[building][sector] = {[key]: value, name: sector, z: m.sector_z};
        }
      } else {
        buildingTypes[building] = {[sector]: {[key]: value, name: sector, z: m.sector_z}};
      }
    })
  }

  let order = 1;

  if (data.byType?.length > 0 && data.measurementsWithTypes) {
    fillWorksheetWithMeasurementsByTypes({
      worksheet: byTypeWorksheet,
      byType: data.byType,
      elementTypes: data.elementTypes,
      buildingTypes,
      rowStartIndex: 3,
    });
    byTypeWorksheet.orderNo = order;
    order += 1
  }


  // ressources
  let currentWS;
  groups.forEach((group) => {
    const ressourceData = data.byRessource.filter((r) => r.group === group);
    // if (data.buildings?.length > 1) {
    //   ressourceData.map((r) => {
    //     r.byBuildings = buildingRessources[r.group + r.num];
    //   })
    // }
    const withDescription = ressourceData.filter((r) => r.description).length > 0;
    const withRefQty = ressourceData.filter((r) => r.refQty).length > 0;
    currentWS = addRessourceWorksheetToWorkbook(
      workbook,
      ressourceWorksheetTemplate,
      group,
      ressourceData,
      buildingRessources,
      withDescription,
      withRefQty,
      data.usesPrices,
      data.ressourcesPU,
    );
    currentWS.orderNo = order;
    order += 1;
  });

  // ressources by version

  if (data.ressourcesByVersion?.length > 0)
    groups.map((g) => {
      currentWS = addRessourcesByVersionsWorksheet(
        workbook,
        byVersionWorksheet,
        `${g} par version`,
        data.ressourcesByVersion.filter(
          (r) => r.type === "VERSION" || r.group === g
        ),
        data.versions
      );
      currentWS.orderNo = order;
      order += 1;
    });

  // ressources by room
  
  if (data.ressourcesByRoom?.length > 0)
    groups.map((g) => {
      currentWS = addRessourcesByRoomsWorksheet(
        workbook,
        byRoomWorksheet,
        `${g} par zones`,
        data.ressourcesByRoom.filter(
          (r) => r.type === "ROOM" || r.type === "SECTOR" || r.group === g
        ),
        data.ressourcesPU,
      );
      currentWS.orderNo = order;
      order += 1;
    });

  // rooms

  if (data.rooms?.length > 0) {
    fillWorksheetWithRooms({
      worksheet: roomsWorksheet,
      rooms: data.rooms,
      rowStartIndex: 3,
    });
    roomsWorksheet.orderNo = order;
    order += 1
  }

  // relations

  if (data.ressourcesTypesRelations) {
    fillWorksheetWithRessourcesTypesRelations({
      worksheet: relationsWorksheet,
      rowStartIndex: 6,
      data,
    });
    relationsWorksheet.orderNo = order;
    order += 1
  }
    
  // elements
  
  if (data.measurements) {
    fillWorksheetWithMeasurements({
      worksheet: elementWorksheet,
      measurements: data.measurements,
      rowStartIndex: 3,
    });
    elementWorksheet.orderNo = order;
    order += 1
  }

  
  // data
  
  if (data.measurementsWithRessources) {
    fillWorksheetWithMeasurementsWithRessources({
      worksheet: dataWorksheet,
      measurementsWithRessources: data.measurementsWithRessources,
        rowStartIndex: 3,
    });
    dataWorksheet.orderNo = order;
    order += 1
  }
  
  // remove worksheets
  if (data.rooms?.length === 0) {
    workbook.removeWorksheet("zones");
  };

  workbook.views = [
    {
      x: 0, y: 0, width: 10000, height: 20000,
      firstSheet: 0, activeTab: 0, visibility: 'visible' // Set activeTab to 0
    }
  ];

  workbook.removeWorksheet("DPGF par version");
  workbook.removeWorksheet("DPGF par zone");
  workbook.removeWorksheet("ressources");
  if (!data.ressourcesTypesRelations)
    workbook.removeWorksheet("hypothèses");
}
    