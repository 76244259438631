import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import SelectorElementType from "./SelectorElementType";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import {
  setIsCreatingElementType,
  setTempElementType,
  setSelectedElementTypeIds,
  updateElementTypesGroup,
} from "../elementTypesSlice";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import updateElementTypesGroupWithOneUpdatedType from "../utils/updateElementTypesGroupWithOneUpdatedType";

import {useKvfTranslationMap} from "Features/kvform/hooks/useKvfTranslationMap";
import useAccessToken from "Features/auth/useAccessToken";
import {setSelectedListItemId} from "Features/leftPanel/leftPanelSlice";
import {
  setSelectedElementTypeId,
  updateTempMeasurementFromElementType,
} from "Features/measurements/measurementsSlice";
import useSelectedMeasurement from "Features/measurements/useSelectedMeasurement";

// import elementTypesGroupToRemote from "../utils/elementTypesGroupToRemote";

export default function FieldItemElementTypeId({item, value, scene, onChange}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // string

  const addS = "Nouveau type";
  const newTitleS = "Créer un titre";
  const cancelS = "Annuler";

  // data

  const translationMap = useKvfTranslationMap();
  // Use only the elementTypes that are not title
  const allTypes = useElementTypesBySceneProxy(scene);
  const types = allTypes.filter((t) => !t.isTitle);
  const isCreatingElementType = useSelector(
    (s) => s.elementTypes.isCreatingElementType
  );
  // const selectedElementTypesGroupProxy = useSelector(
  //   (s) => s.elementTypes.selectedElementTypesGroupProxy
  // );
  const selectedElementTypesGroup = useSelectedElementTypesGroup(scene);
  const selectedMeasurement = useSelectedMeasurement();

  // helpers

  const elementType = types.find((t) => t.id === value);
  // const saveTypeInScene =
  //   selectedElementTypesGroupProxy?.fromScene ||
  //   !selectedElementTypesGroupProxy;
  let options = types.map((t) => {
    return {...t, name: `${t.group} - ${t.num} - ${t.name}`};
  });
  // if (item.id) {
  // restrict to drawingShape
  options = options.filter((t) => t.drawingShape === item.drawingShape);
  // }

  // state

  const [tempNum, setTempNum] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempUnit, setTempUnit] = useState("");
  const [bckpType, setBckpType] = useState(elementType);
  const [newType, setNewType] = useState(elementType);

  // // effect

  // useEffect(() => {
  //   setBckpType(newElementType);
  // }, [newElementType])

  // handlers

  function handleChange(elementType) {
    console.log("HANDLE CHANGE", elementType?.id);
    onChange(elementType?.id ? elementType.id : null);
  }

  function handleNewType() {
    dispatch(setIsCreatingElementType(true));
    dispatch(setSelectedListItemId(null));
    setBckpType(elementType);
    setNewType({...elementType, id: nanoid()});
  }

  async function handleNewTitle() {
    const newTypeTitle = {
      ...newType,
      drawingShape: item.elementType?.drawingShape,
      color: item.elementType?.color,
      num: tempNum,
      name: tempName,
      isTitle: true,
      style: "title1",
    };
    // if (saveTypeInScene) {
    //   elementTypesGroupToRemote({
    //     scene,
    //     types,
    //     selectedGroup: selectedElementTypesGroupProxy,
    //     dispatch,
    //     accessToken,
    //   });
    // }
    const updatedGroup = updateElementTypesGroupWithOneUpdatedType(
      newTypeTitle,
      selectedElementTypesGroup
    );
    await dispatch(
      updateElementTypesGroup({
        accessToken,
        elementTypesGroup: updatedGroup,
      })
    );
    handleCancel();
  }

  async function handleCancel() {
    dispatch(setIsCreatingElementType(false));
    dispatch(setSelectedListItemId(bckpType.id));
    dispatch(setSelectedElementTypeId(bckpType.id));
    dispatch(setSelectedElementTypeIds([bckpType.id]));
    dispatch(updateTempMeasurementFromElementType(bckpType));
  }

  function handleNumChange(e) {
    const newNum = e.target.value;
    setTempNum(newNum);
    setNewType({...newType, num: newNum});
    dispatch(setTempElementType({...newType, num: newNum}));
  }

  function handleNameChange(e) {
    const newName = e.target.value;
    setTempName(newName);
    setNewType({...newType, name: newName});
    dispatch(setTempElementType({...newType, name: newName}));
  }

  function handleUnitChange(e) {
    const newUnit = e.target.value;
    setTempUnit(newUnit);
    setNewType({...newType, unit: newUnit});
    dispatch(setTempElementType({...newType, unit: newUnit}));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <Box
        sx={{
          bgcolor: "background.default",
          width: 1,
          p: 0.5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
        }}
      >
        <Typography sx={{fontSize: 13, fontWeight: "bold"}} noWrap>
          {translationMap["identificationType"]}
        </Typography>
      </Box> */}
      {!isCreatingElementType && (
        <Box sx={{pb: 2}}>
          <SelectorElementType
            elementType={elementType}
            elementTypes={allTypes}
            onChange={handleChange}
          />
        </Box>
      )}
      {/* {!isCreatingElementType && !selectedMeasurement && (
        <Box
          sx={{
            width: 1,
            height: "30px",
            display: "flex",
            justifyContent: "end",
            color: "text.secondary",
            pr: 0.5,
            pb: 0.5,
          }}
        >
          <Button
            size="small"
            startIcon={<Add sx={{width: "15px", height: "15px"}} />}
            onClick={handleNewType}
            color="inherit"
          >
            {addS}
          </Button>
        </Box>
      )} */}
      {/* {isCreatingElementType && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "space-between",
            px: 0.5,
            py: 1,
          }}
        >
          <TextField
            size="small"
            fontSize={13}
            sx={{
              width: 0.24,
            }}
            InputProps={{
              style: {
                fontSize: 13,
              },
            }}
            label={translationMap["num"]}
            variant="outlined"
            onChange={handleNumChange}
          />
          <TextField
            size="small"
            fontSize={13}
            sx={{
              width: 0.75,
            }}
            InputProps={{
              style: {
                fontSize: 13,
              },
            }}
            label={translationMap["name"]}
            variant="outlined"
            onChange={handleNameChange}
          />
        </Box>
      )}
      {isCreatingElementType && (
        <Box
          sx={{
            width: 1,
            color: "text.secondary",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
            px: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{px: 0.5}}>
              Unité :{" "}
            </Typography>
            <Select
              sx={{height: "30px", fontSize: 12}}
              onChange={(e) => handleUnitChange(e)}
            >
              <MenuItem value={"UN"}>UN</MenuItem>
              <MenuItem value={"ML"}>ML</MenuItem>
              <MenuItem value={"M2"}>M2</MenuItem>
              <MenuItem value={"M3"}>M3</MenuItem>
            </Select>
          </Box>
          <Box>
            <Button
              size="small"
              color="inherit"
              onClick={handleCancel}
              sx={{mr: 0.5}}
            >
              {cancelS}
            </Button>
            <Button
              size="small"
              onClick={handleNewTitle}
              variant="outlined"
              color="inherit"
            >
              {newTitleS}
            </Button>
          </Box>
        </Box>
      )} */}
    </Box>
  );
}
