import {useSelector, useDispatch} from "react-redux";

import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import useSelectedElementTypeFromMeasurements from "Features/measurements/useSelectedElementTypeFromMeasurements";
import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedArticle from "Features/articles/hooks/useSelectedArticle";

import useUpdateSceneRessource from "Features/ressources/hooks/useUpdateSceneRessource";
import useUpdateSceneElementType from "Features/elementTypes/hooks/useUpdateSceneElementType";
import {updateArticleTemp} from "Features/articles/articlesSlice";

export default function useDeletePoi(scene) {
  const dispatch = useDispatch();
  const selectedPoiId = useSelector((state) => state.pois.selectedPoiId);

  const sceneModule = useSceneModule();
  const selectedElementType = useSelectedElementType(scene);
  const selectedElementTypeInMeasurements =
    useSelectedElementTypeFromMeasurements(scene);
  const selectedRessource = useSelectedRessource(scene);
  const selectedArticle = useSelectedArticle(scene);

  const updateSceneRessource = useUpdateSceneRessource(scene);
  const updateElementType = useUpdateSceneElementType(scene);

  const deletePoi = async () => {
    if (sceneModule === "RESSOURCES") {
      const newRessource = {...selectedRessource};
      newRessource.pois = selectedRessource.pois.filter(
        (p) => p.id !== selectedPoiId
      );
      await updateSceneRessource(newRessource);
    } else if (sceneModule === "ELEMENT_TYPES") {
      const newElementType = {...selectedElementType};
      newElementType.pois = selectedElementType.pois.filter(
        (p) => p.id !== selectedPoiId
      );
      await updateElementType(newElementType);
    } else if (sceneModule === "EDIT_MEASUREMENTS") {
      const newElementType = {...selectedElementTypeInMeasurements};
      newElementType.pois = selectedElementTypeInMeasurements.pois.filter(
        (p) => p.id !== selectedPoiId
      );
      await updateElementType(newElementType);
    } else if (sceneModule === "ARTICLES") {
      const newPois = selectedArticle.pois.filter(
        (p) => p.id !== selectedPoiId
      );
      const updates = {id: selectedArticle.id, pois: newPois};
      dispatch(updateArticleTemp(updates));
    }
  };

  return deletePoi;
}
