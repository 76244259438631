import {useDispatch, useSelector} from "react-redux";

import {Box, ToggleButton, Tooltip} from "@mui/material";
import {Search} from "@mui/icons-material";

import {setOpenSearchPanel} from "../pdfSlice";

// import {setApplyFilters} from "Features/measurements/measurementsSlice";

export default function ButtonToggleSearchPanel({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const closeS = "Fermer";
  const openS = "Chercher dans le pdf";

  // data

  const isOpen = useSelector((s) => s.pdf.openSearchPanel);

  // helpers

  const title = isOpen ? closeS : openS;

  // handlers

  function handleChange() {
    if (!isOpen) {
      editorPdf?.showSearchPanel();
    } else {
      editorPdf?.hideSearchPanel();
    }

    dispatch(setOpenSearchPanel(!isOpen));
  }

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        color: "divider",
      }}
    >
      <ToggleButton
        selected={isOpen}
        onChange={handleChange}
        value="APPLY_FILTERS"
        sx={{
          width: 30,
          height: 30,
          "&.Mui-selected": {
            bgcolor: "common.darkGrey",
            "&:hover": {
              bgcolor: "common.darkGrey",
            },
          },
        }}
      >
        <Tooltip title={title}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "common.white",
            }}
          >
            <Search fontSize="small" color="inherit" />
          </Box>
        </Tooltip>
      </ToggleButton>
    </Box>
  );
}
