import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {updateOrganisation} from "../organisationsSlice";

import {Box, TextField, IconButton} from "@mui/material";

import {Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedOrganisation from "../hooks/useSelectedOrganisation";

export default function ContainerEditableEmailDomain({organisation}) {
  //
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const labelS = "Domaine (@xxx.xxx)";

  // state

  const initDomain = organisation?.emailDomain;

  const [tempDomain, setTempDomain] = useState(initDomain);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTempDomain(initDomain);
  }, [initDomain]);

  // helpers

  const showSave = initDomain !== tempDomain;
  const organisationId = organisation?.id;

  // handler

  function handleTempDomainChange(e) {
    setTempDomain(e.target.value);
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    await dispatch(
      updateOrganisation({
        accessToken,
        organisationId,
        updates: {emailDomain: tempDomain},
      })
    );
    setLoading(false);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", width: 1, p: 1}}>
      <Box sx={{flexGrow: 1}}>
        <TextField
          label={labelS}
          value={tempDomain ?? ""}
          onChange={handleTempDomainChange}
          size="small"
          fullWidth
        />
      </Box>
      <IconButton
        disabled={loading}
        sx={{visibility: showSave ? "visible" : "hidden", ml: 2}}
        onClick={handleSave}
      >
        <Save />
      </IconButton>
    </Box>
  );
}
