import {useState, useEffect} from "react";
import {nanoid} from "@reduxjs/toolkit";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

import {Vector3} from "three";

import {
  addClippingPlane,
  updateClippingPlanesStatus,
  updateScene,
} from "Features/scenes/scenesSlice";

export default function CardAddClippingPlane({editor3d, scene}) {
  // const clippingPlaneRef = useRef();
  const dispatch = useDispatch();

  // strings

  const addString = "Add a clipping plane";
  const addHelper = "Click on a plane in the 3D scene";
  const planeString = " Clipping plane";

  // data

  const allClippingPlanes = useSelector((state) => state.scenes.clippingPlanes);

  const clippingPlanes = allClippingPlanes.filter(
    (cp) => cp.sceneClientId === scene.clientId
  );

  const count = clippingPlanes.length;

  // local state

  const [adding, setAdding] = useState(false);

  // handlers

  function handleEditorClick(o) {
    if (o?.pickedFaceNormal) {
      const normal = new Vector3();
      normal.copy(o.pickedFaceNormal).negate();
      const origin = new Vector3(o.sceneP.x, o.sceneP.y, o.sceneP.z);

      const newClippingPlane = {
        id: nanoid(),
        sceneClientId: scene.clientId,
        name: `#${count + 1} ${planeString}`,
        normal: {x: normal.x, y: normal.y, z: normal.z},
        origin: {x: origin.x, y: origin.y, z: origin.z},
      };

      const newPlane = editor3d?.clipper.createPlane({
        origin,
        normal,
        id: newClippingPlane.id,
        sceneClientId: newClippingPlane.sceneClientId,
      });
      editor3d?.clipper.enablePlane(newPlane.id);
      editor3d?.clipper.editPlane(newPlane.id);

      editor3d.onProcessEditorClick = console.log;
      setAdding(false);

      dispatch(addClippingPlane(newClippingPlane));
      dispatch(
        updateClippingPlanesStatus({
          clippingPlaneId: newClippingPlane.id,
          disabled: false,
          edit: true,
        })
      );

      // update scene

      const newScene = {...scene};
      const clippingPlanes = scene.data?.clippingPlanes
        ? [...scene.data.clippingPlanes]
        : [];
      clippingPlanes.push(newClippingPlane);

      if (!scene.data) {
        newScene.data = {clippingPlanes};
      } else {
        const newSceneData = {...scene.data};
        newSceneData.clippingPlanes = clippingPlanes;
        newScene.data = newSceneData;
      }
      dispatch(updateScene({scene: newScene}));
    }
  }

  function handleAddPlaneClick() {
    setAdding(true);
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
  }

  // unmount

  useEffect(() => {
    return () => {
      editor3d?.onProcessEditorClick(console.log);
    };
  }, []);

  return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
      {adding ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            bgcolor: "secondary.main",
            color: "common.white",
            borderRadius: 0.5,
            p: 0.5,
            px: 1,
          }}
        >
          <Typography variant="body2" noWrap>
            {addHelper}
          </Typography>
          <CircularProgress size={12} color="inherit" sx={{ml: 2}} />
        </Box>
      ) : (
        <Button startIcon={<Add />} size="small" onClick={handleAddPlaneClick}>
          {addString}
        </Button>
      )}
    </Box>
  );
}
