import {Box, CircularProgress, Typography} from "@mui/material";

import bimbox from "../assets/bimbox.png";

export default function PageDownloadingScene() {

  const label = "Chargement de la bimbox ...";

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 300,
          height: 300,
        }}
      >
        <Box
          sx={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            width: "100px",
            height: "100px",
            position: "relative",
            backgroundImage: `url(${bimbox})`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",

            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CircularProgress color="inherit" size={200} thickness={1} />
        </Box>
      </Box>
      <Typography variant="h6" sx={{mt: 4}}>
        {label}
      </Typography>
    </Box>
  );
}
