import {useDispatch, useSelector} from "react-redux";

import {Button, Box} from "@mui/material";

import {setSelectedElementTypeId} from "../elementTypesSlice";

export default function ButtonDataGridClearSelection({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const clearS = "Clear selection";

  // data

  const id = useSelector((s) => s.elementTypes.selectedElementTypeId);

  // helpers

  const isEmpty = !Boolean(id);

  // handlers

  function handleClear() {
    dispatch(setSelectedElementTypeId(null));
    editor3d?.sceneEditor.unselect();
  }

  // helpers

  return (
    <Box
      sx={{color: "text.secondary", visibility: isEmpty ? "hidden" : "visible"}}
    >
      <Button onClick={handleClear} size="small" color="inherit">
        {clearS}
      </Button>
    </Box>
  );
}
