import {useEffect, useState} from "react";

import Fuse from "fuse.js";

import {InputBase, Box, styled} from "@mui/material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";
import {Search as SearchIcon} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

const Input = styled(InputBase)(({theme}) => ({
  "& .MuiInputBase-root": {color: "inherit", width: "100%"},
  "& .MuiInputBase-input": {
    p: 1,
    pl: 0,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

const tokeniseStringWithQuotesBySpaces = (string) =>
  string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];

export default function SearchBarObjects({
  onSearch,
  objects,
  keys,
  onFocus,
  onBlur,
  darkMode = false,
}) {
  const {t} = useTranslation("common");
  const placeholderString = capitalizeStart(t("common:search"));

  const [text, setText] = useState("");

  // helpers - fuse search

  const options = {
    findAllMatches: true,
    ignoreLocation: true,
    keys,
    threshold: 0.0,
    distance: 0,
  };
  const fuse = new Fuse(objects, options);

  // helpers - color

  const color = darkMode ? "common.white" : "inherit";
  const bgcolor = darkMode
    ? theme.palette.common.caplaBlack
    : theme.palette.background.default;

  // handlers

  function handleChange(e) {
    let newText = e.target.value;
    setText(newText);
    // let result = [];
    // //

    // if (!newText || newText.length === 0) onSearch(objects);

    // objects.forEach((object) => {
    //   keys.forEach((key) => {
    //     const objectString = object[key];
    //     if (typeof objectString === "string") {
    //       if (objectString?.toLowerCase()?.includes(newText?.toLowerCase())) {
    //         result.push(object);
    //       }
    //     }
    //   });
    // });

    // console.log("[DEBUG090]", result.length);

    // onSearch(result);
    newText = tokeniseStringWithQuotesBySpaces(newText);

    if (newText.length === 0) {
      onSearch(objects);
    } else if (onSearch) {
      const result = fuse.search({
        $and: newText.map((searchToken) => {
          const orFields = keys.map((k) => ({[k]: searchToken}));
          return {$or: orFields};
        }),
      });
      onSearch(result.map((r) => r.item));
    }
  }

  // handlers

  function handleFocus() {
    if (onFocus) onFocus();
  }
  function handleBlur() {
    if (onBlur) onBlur();
  }

  // effect

  useEffect(() => {
    if (!text) {
      setText("");
      onSearch(objects);
    }
  }, [text]);

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.shape.borderRadius,
        backgroundColor: bgcolor,
        "&:hover": {
          backgroundColor: lighten(bgcolor, 0.1),
        },
        width: 1,
      }}
    >
      <Box
        sx={{
          height: "100%",
          position: "absolute",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pl: 1,
          color,
        }}
      >
        <SearchIcon fontSize="small" color={darkMode ? "inherit" : "action"} />
      </Box>
      <Input
        placeholder={`${placeholderString} ...`}
        value={text}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fontSize={12}
        inputProps={{size: "small", sx: {m: 0, py: 0.5, fontSize: 12}}}
        sx={{color}}
      />
    </Box>
  );
}
