import {useState} from "react";

import useActionsZonesMap from "../hooks/useActionsZonesMap";

import {Box} from "@mui/material";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateZoneBasic from "./DialogCreateZoneBasic";
import DialogCreateZoneFromImage from "./DialogCreateZoneFromImage";

export default function SectionActionsZones({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateZone, setOpenDialogCreateZone] = useState(false);
  const [openDialogCreateZoneFromImage, setOpenDialogCreateZoneFromImage] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createZone: () => setOpenDialogCreateZone(true),
    createZoneFromImage: () => setOpenDialogCreateZoneFromImage(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateZone() {
    setOpenDialogCreateZone(false);
  }

  // data - handlers

  const actionsMap = useActionsZonesMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateZone && (
        <DialogCreateZoneBasic
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateZone}
          onClose={handleCloseDialogCreateZone}
        />
      )}

      {openDialogCreateZoneFromImage && (
        <DialogCreateZoneFromImage
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateZoneFromImage}
          onClose={() => setOpenDialogCreateZoneFromImage(false)}
        />
      )}
    </>
  );
}
