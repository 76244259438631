/*
 * sector : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useAddSceneBimData(scene) {
  const dispatch = useDispatch();

  const addSceneBimData = ({
    phases,
    sectors,
    rooms,
    elementTypes,
    ressources,
  }) => {
    if (!scene) return;

    let oldPhases = scene?.data?.phases;
    if (!oldPhases) oldPhases = [];
    oldPhases = oldPhases.filter(
      (s) => !phases.map((s) => s.id).includes(s.id)
    );

    let oldSectors = scene?.data?.sectors;
    if (!oldSectors) oldSectors = [];
    oldSectors = oldSectors.filter(
      (s) => !sectors.map((s) => s.id).includes(s.id)
    );

    let oldRooms = scene?.data?.rooms;
    if (!oldRooms) oldRooms = [];
    oldRooms = oldRooms.filter((s) => !rooms.map((s) => s.id).includes(s.id));

    let oldRessources = scene?.data?.ressources;
    if (!oldRessources) oldRessources = [];
    oldRessources = oldRessources.filter(
      (s) => !ressources.map((s) => s.id).includes(s.id)
    );

    let oldElementTypes = scene?.data?.elementTypes;
    if (!oldElementTypes) oldElementTypes = [];
    const newCodes = elementTypes.map((t) => t.code);
    oldElementTypes = oldElementTypes.filter((t) => !newCodes.includes(t.code));

    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        phases: [...oldPhases, ...phases],
        sectors: [...oldSectors, ...sectors],
        rooms: [...oldRooms, ...rooms],
        ressources: [...oldRessources, ...ressources],
        elementTypes: [...oldElementTypes, ...elementTypes],
      },
    };

    dispatch(updateScene({scene: newScene}));
  };

  return addSceneBimData;
}
