import {Box, Dialog, DialogTitle, DialogContent} from "@mui/material";

import SectionCreateElementTypeBasic from "Features/elementTypor/components/SectionCreateElementTypeBasic";

export default function DialogCreateElementType({scene, open, onClose}) {
  // handlers

  async function handleCreated() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      {/* <DialogTitle>{title}</DialogTitle> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          height: 1,
          minHeight: 0,
        }}
      >
        <SectionCreateElementTypeBasic
          scene={scene}
          onCreated={handleCreated}
        />
      </Box>
    </Dialog>
  );
}
