export default function getElementTypeQuantityLabel(elementType, options) {
  if (!elementType) {
    return {quantity: null, label: "-?-"};
  }
  const showMainQuantity = options?.showMainQuantity;

  // unit

  const unit = showMainQuantity ? elementType.mainUnit : "elts";

  // qty

  let qty = showMainQuantity ? elementType.mainQuantity : elementType.measCount;

  if (typeof qty !== "number") {
    qty = "-";
  } else if (isNaN(qty)) {
    qty = " ! ";
  }
  if (showMainQuantity && typeof qty === "number") qty = qty.toFixed(2);

  const label = `${qty} ${unit}`;

  return {quantity: qty, label};
}
