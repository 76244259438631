import React from "react";

import {Box} from "@mui/material";

import ListListings from "./ListListings";
import HeaderListListings from "./HeaderListListings";
import ButtonDialogCreateListing from "./ButtonDialogCreateListing";

import Stretch from "Features/ui/components/Stretch";

export default function SectionListings({scene}) {
  return (
    <Box sx={{position: "relative", height: 1}}>
      <Stretch>
        <HeaderListListings scene={scene} />
        <Box sx={{mt: 2}}></Box>
        <ListListings scene={scene} />
        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            left: "50%",
            bottom: "16px",
            transform: "translateX(-50%)",
          }}
        >
          {scene?.id && <ButtonDialogCreateListing scene={scene} />}
        </Box>
      </Stretch>
    </Box>
  );
}
