import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  Paper,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import {Info} from "@mui/icons-material";

import usePageType from "../usePageType";
import {setOpenMobileDialogScene} from "../navigationSlice";

export default function AppTopBarMobile({scene}) {
  const dispatch = useDispatch();

  // data

  const fullscreen = useSelector((s) => s.ui.fullscreen);
  const pageType = usePageType();
  const isSyncing = useSelector((s) => s.sync.isSyncing);

  // helper

  const colorMap = {
    HOME: {
      background: (theme) =>
        `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
      bgcolor: "common.white",
      color: "common.white",
    },
    SCENE: {
      background: "unset",
      bgcolor: "common.white",
      color: "text.secondary",
    },
    PROFILE: {
      background: "unset",
      bgcolor: "secondary.main",
      color: "common.white",
    },
    DEFAULT: {
      background: "unset",
      bgcolor: "common.white",
      color: "common.black",
    },
  };

  const {background, bgcolor, color} = colorMap[pageType]
    ? colorMap[pageType]
    : colorMap["DEFAULT"];

  // handlers

  function handleInfoClick() {
    dispatch(setOpenMobileDialogScene(true));
  }

  return (
    <Paper
      elevation={9}
      sx={{
        background,
        bgcolor,
        color,
        height: (theme) => theme.spacing(5),
        width: 1,
        display: fullscreen ? "none" : "flex",
        alignItems: "center",
        justifyContent: "space-between",
        pl: 1,
        pr: 0.5,
        zIndex: 90,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {isSyncing && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            color: "common.white",
            background: (theme) =>
              `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
          }}
        >
          <LinearProgress
            color="inherit"
            sx={{height: "2px", ".MuiLinearProgress-bar": {height: "2px"}}}
          />
        </Box>
      )}

      <Box
        sx={{
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{minWidth: 0}}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: "inherit",
            }}
            noWrap
          >
            {scene?.title}
          </Typography>
        </Box>
        <IconButton onClick={handleInfoClick}>
          <Info color="primary" />
        </IconButton>
      </Box>
    </Paper>
  );
}
