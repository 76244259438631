import {useState} from "react";

import {
  Card,
  CardContent,
  FormGroup,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function CardSettingsGrid({editor3d}) {
  const {t} = useTranslation("viewer3D");

  // strings

  const toggleGridString = t("viewer3D:settings:grid:toggleGridVisibility");
  const gridDescriptionString = t("viewer3D:settings:grid:gridDescription");

  // local state

  const [grid, setGrid] = useState(editor3d?.grid.visibility);

  // handlers

  function handleSwitchChange(e) {
    const checked = e.target.checked;
    setGrid(checked);
    editor3d?.grid.toggleVisibility();
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="body2" sx={{mb: 2}} gutterBottom>
          <b>{capitalizeStart(t("viewer3D:scene.grid"))}</b>
        </Typography>
        <Typography variant="body2" sx={{mb: 2}}>
          {gridDescriptionString}
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={grid}
                onChange={handleSwitchChange}
              />
            }
            size="small"
            label={<Typography variant="body2">{toggleGridString}</Typography>}
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
}
