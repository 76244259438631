import {
  SphereGeometry,
  MeshBasicMaterial,
  LineBasicMaterial,
  BufferGeometry,
  Mesh,
  Line3,
  Quaternion,
  Object3D,
  Vector3,
  Group,
  Line,
} from "three";

class FaceDebug {
  constructor(color, radius) {
    this.a = this._createPoint(color, radius);
    this.b = this._createPoint(color, radius);
    this.c = this._createPoint(color, radius);
  }

  _createPoint(color, radius) {
    const material = new MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.8,
    });

    const geometryPoint = new SphereGeometry(radius, 16, 16);
    const point = new Mesh(geometryPoint, material);

    return point;
  }

  enable() {
    this.a.layers.enable(1);
    this.b.layers.enable(1);
    this.c.layers.enable(1);
  }

  disable() {
    this.a.layers.disable(1);
    this.b.layers.disable(1);
    this.c.layers.disable(1);
  }

  loadTo(scene) {
    scene.add(this.a);
    scene.add(this.b);
    scene.add(this.c);
  }

  setPosition(a, b, c) {
    this.a.position.copy(a);
    this.b.position.copy(b);
    this.c.position.copy(c);
  }
}

export default FaceDebug;
