import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";

import ListItemElevation from "./ListItemElevation";

import {setShortcutElevationZ} from "Features/sectors/sectorsSlice";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";

export default function ShortcutElevations({scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const multiselectString = "Sélection multiple";

  // data
  const mode = useMeasurementsScopeMode(scene, viewer);

  const shortcutElevationZ = useSelector((s) => s.sectors.shortcutElevationZ);
  const sectors = useSectorsByScene(scene);
  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false,
    true,
    viewer
  );

  // state

  const [checked, setChecked] = useState(false);

  // helpers

  const usedSectors = new Set(fms.map((m) => m.sectorId));

  const sectorsByBuildings = {};

  sectors
    .filter((s) => usedSectors.has(s.id))
    .map((sector) => {
      const building = sector?.building ? sector.building : "-?-";
      if (sector && building in sectorsByBuildings) {
        sectorsByBuildings[building].push(sector);
      } else if (sector) {
        sectorsByBuildings[building] = [sector];
      }
    });

  const elevations = [];
  const elevationsSet = new Set();
  if (usedSectors.has(undefined) || usedSectors.has(null)) {
    elevationsSet.add("undefined");
    elevations.push({
      id: "undefined",
      name: "-?-",
      title: false,
    });
  }
  Object.keys(sectorsByBuildings)
    .sort()
    .map((building) => {
      if (building !== "-?-")
        elevations.push({
          id: building,
          name: building,
          title: true,
        });
      const buildingSectors = sectorsByBuildings[building];
      const sortedSectors = [...new Set(buildingSectors.map(JSON.stringify))]
        .map(JSON.parse)
        .sort((a, b) => a.z - b.z);
      sortedSectors.forEach((sector) => {
        elevationsSet.add(sector.id);
        elevations.push({
          id: sector.id,
          name: sector.name,
          title: false,
        });
      });
    });

  // handlers

  function handleElevationClick(elevation) {
    if (
      !checked &&
      shortcutElevationZ.length === 1 &&
      elevation.id === shortcutElevationZ[0]
    ) {
      dispatch(setShortcutElevationZ([...elevationsSet]));
    } else if (!checked) {
      dispatch(setShortcutElevationZ([elevation.id]));
    } else if (
      shortcutElevationZ.includes(elevation.id) ||
      shortcutElevationZ.length === 0
    ) {
      let newSelection = shortcutElevationZ.filter(
        (i) => i && i !== elevation.id
      );
      if (newSelection.length === 0) newSelection = [...elevationsSet];
      dispatch(setShortcutElevationZ(newSelection));
    } else {
      dispatch(setShortcutElevationZ([...shortcutElevationZ, elevation.id]));
    }
  }

  function handleCheckChange(e) {
    setChecked(e.target.checked);
  }

  return (
    <>
      <FormControlLabel
        sx={{mb: 1, pl: 1.25}}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckChange}
            size="small"
            sx={{width: "12px", height: "12px", mr: 1}}
          />
        }
        label={
          <Typography sx={{fontSize: 12}} variant="body2">
            {multiselectString}
          </Typography>
        }
      />
      <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
        {elevations.map((elevation) => (
          <ListItemElevation
            key={elevation.id}
            onClick={handleElevationClick}
            elevation={elevation}
          />
        ))}
      </Box>
    </>
  );
}
