import updateTypeNomenclatureFromMeasurements from "Features/elementTypes/utils/updateTypeNomenclatureFromMeasurements";

export default function getNomenclatureTypesFromMeasurements(
  measurements,
  options
) {
  // options

  const parentElementType = options?.parentElementType;
  const articleName = options?.articleName;
  const globalScope = options?.globalScope;
  const groupsWithSortedTypes = options?.groupsWithSortedTypes;
  const sectors = options?.sectors;

  //
  const measNomenclature = updateTypeNomenclatureFromMeasurements(
    [],
    measurements,
    {
      parentElementType,
      articleName,
      globalScope,
      groupsWithSortedTypes,
      sectors,
    }
  );

  console.log("debugAFA89 - measNomenclature", measNomenclature);
  //
  return measNomenclature;
  //
}
