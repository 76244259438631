import {useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

import {
  setIsViewerFullScreen,
  setSelectedPdfModelId,
} from "Features/viewer3D/viewer3DSlice";

import {
  setSelectedElementTypesGroupProxy,
  setSelectedElementTypeId,
} from "Features/elementTypes/elementTypesSlice";

// import {setShow3D} from "Features/pdf/pdfSlice";
// import {setIs3dEnabled} from "Features/viewer3D/viewer3DSlice";
import {newFirstLoadedPage, setOpenSections} from "Features/ui/uiSlice";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
// import {setOpenSections} from "Features/ui/uiSlice";
import Stretch from "Features/ui/components/Stretch";
import ToolbarMeasurementsMain from "Features/measurements/components/ToolbarMeasurementsMain";
import SectionDataGridMeasurements from "Features/measurements/components/SectionDataGridMeasurements";
import {setPdfViewerMode} from "Features/pdf/pdfSlice";
import SectionDataGridToolbar from "Features/measurements/components/SectionDataGridToolbar";
import useMeasurementsFiltersAndShortcuts from "Features/measurements/useMeasurementsFilters";
import SectionMeasurementsCountInScope from "Features/measurements/components/SectionMeasurementsCountInScope";
import SectionMeasurementsFilters from "Features/measurements/components/SectionMeasurementsFilters";
import SectionSelectorMeasurementScope from "Features/measurements/components/SectionSelectorMeasurementScope";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import SignInButton from "Components/SignInButton";
import useProfile from "Features/profile/useProfile";
import SwitchHideSavedMeasurements from "Features/measurements/components/SwitchHideSavedMeasurements";
// import useInitLoadPackageInDataManager from "Features/elementPackages/hooks/useInitLoadPackageInDataManager";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";
import {
  setLastPackageId,
  setSelectedPackageId,
} from "Features/elementPackages/packagesSlice";
import useSceneModule from "Features/navigation/useSceneModule";

export default function SceneMeasurements() {
  const dispatch = useDispatch();
  const {caplaEditor, viewer, scene} = useOutletContext();

  // strings

  const haveToLoginS =
    "Veuillez vous connecter pour accéder au calque de repérage";

  // params

  const {modelId} = useParams();
  const isEditMode = modelId ? true : false;

  // helpers - need login

  const needLogin = false;

  // data

  const os = useSelector((s) => s.ui.openSections);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const profile = useProfile();

  const fullscreen = useIsFullscreen();
  const fullscreenTable = useSelector((s) => s.ui.fullscreenTable);

  const [_, filtersHash] = useMeasurementsFiltersAndShortcuts(scene);

  const moreFilters = useSelector((s) => s.measurements.moreFilters);
  const scopeSelectionMode = useSelector(
    (s) => s.measurements.scopeSelectionMode
  );
  const selectedMeasurementsModel = useSelectedMeasurementsModel();

  const firstLoadedPages = useSelector((s) => s.ui.firstLoadedPages);

  const sceneModule = useSceneModule(scene);
  const selectedTypesGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );

  // state

  const [openFilters, setOpenFilters] = useState(false); // to reset size.

  // helpers

  const outlet = os.outlet;

  // helper - measurements or edit_measurements is first loaded

  const pageIsLoaded =
    firstLoadedPages.includes("EDIT_MEASUREMENTS") ||
    firstLoadedPages.includes("MEASUREMENTS");

  // helpers - hash

  const h1 = fullscreenTable ? "1" : "0";
  const h2 = moreFilters ? "1" : "0";
  const h3 = scopeSelectionMode;
  const h4 = openFilters ? "1" : "0";

  // helpers
  // effects

  useEffect(() => {
    dispatch(setIsViewerFullScreen(false));
    dispatch(setPdfViewerMode("MEASUREMENTS"));
    dispatch(
      newFirstLoadedPage(isEditMode ? "EDIT_MEASUREMENTS" : "MEASUREMENTS")
    );
    if (isEditMode) {
      dispatch(setSelectedMeasurementsModelId(modelId));
      dispatch(
        setSelectedPdfModelId(selectedMeasurementsModel?.fromModel.modelId)
      );
    }
  }, [isEditMode, selectedMeasurementsModel]);

  useEffect(() => {
    const newOs = {
      outlet: false,
      fixedViewersBox: isEditMode,
      viewer3D: !isEditMode,
      callToAction: false,
    };
    if (!pageIsLoaded) dispatch(setOpenSections(newOs));
  }, [pageIsLoaded]);

  useEffect(() => {
    return () => {
      console.log("update selectedGroup proxy", selectedTypesGroupProxy);
      dispatch(setLastPackageId(selectedMeasurementsModel?.id));
      if (sceneModule === "EDIT_MEASUREMENTS") {
        dispatch(setSelectedPackageId(selectedMeasurementsModel?.id));
        if (selectedElementTypeId)
          dispatch(setSelectedElementTypeId(selectedElementTypeId));
        if (selectedTypesGroupProxy)
          dispatch(setSelectedElementTypesGroupProxy(selectedTypesGroupProxy));
      }
    };
  }, [
    selectedMeasurementsModel?.id,
    sceneModule,
    selectedElementTypeId,
    selectedTypesGroupProxy?.id,
  ]);

  // effect init

  // useInitLoadPackageInDataManager();

  return (
    <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
      <Box sx={{height: "68px"}} />
      {!fullscreenTable && !fullscreen && outlet && (
        <SectionMeasurementsCountInScope
          scene={scene}
          mode={isEditMode ? "ONE_MODEL" : "MULTI_MODELS"}
          editor3d={caplaEditor?.editor3d}
        />
      )}
      <SectionSelectorMeasurementScope
        scene={scene}
        viewer={viewer}
        editor3d={caplaEditor?.editor3d}
      />
      <Box sx={{mt: 2}}>
        <SectionMeasurementsFilters scene={scene} caplaEditor={caplaEditor} />
      </Box>
      {!fullscreenTable && outlet && (
        <ToolbarMeasurementsMain
          scene={scene}
          caplaEditor={caplaEditor}
          isEditMode={isEditMode}
        />
      )}
      {!fullscreenTable && outlet && os.fixedViewersBox && isEditMode && (
        <SwitchHideSavedMeasurements />
      )}
      {outlet && (
        <SectionDataGridToolbar
          editor3d={caplaEditor?.editor3d}
          scene={scene}
          viewer={viewer}
        />
      )}
      {!fullscreen && outlet && (
        <Stretch propDependency={filtersHash + h1 + h2 + h3 + h4}>
          <Box sx={{height: 1, width: 1, position: "relative"}}>
            <SectionDataGridMeasurements
              caplaEditor={caplaEditor}
              scene={scene}
              mode={mode}
            />
          </Box>
        </Stretch>
      )}
      {needLogin && profile.status === "idle" && isEditMode && (
        <Box
          sx={{
            position: "fixed",
            zIndex: 200,
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: "common.caplaBlack",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "common.white",
          }}
        >
          <Typography
            variant="body"
            align="center"
            sx={{
              maxWidth: 500,
              mb: 3,
            }}
          >
            {haveToLoginS}
          </Typography>
          <Box>
            <SignInButton />
          </Box>
        </Box>
      )}
    </Box>
  );
}
