import usePackagesByScene from "../usePackagesByScene";

import SelectorAutocompleteMultipleWithDialog from "Components/SelectorAutocompleteMultipleWithDialog";

export default function FieldPackageIds({
  scene,
  name,
  value,
  onChange,
  locked,
  scopedPDFs,
}) {
  // data

  let packages = usePackagesByScene(scene);

  // helpers

  if (scopedPDFs) {
    packages = packages.filter((p) => scopedPDFs.includes(p.fromModel?.modelId));
  }

  const selection = Array.isArray(value)
    ? packages.filter((pack) => value.includes(pack.remoteId))
    : [];

  // handlers

  function handleChange(packages) {
    onChange(packages.map((pack) => pack.remoteId));
  }

  return (
    <SelectorAutocompleteMultipleWithDialog
      name={name}
      value={selection}
      onChange={handleChange}
      options={packages}
      disabled={locked}
      checkAll={true}
    />
  );
}
