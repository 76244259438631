import {Typography, FormControlLabel, Switch, Box} from "@mui/material";

export default function FieldZoneD3({zone, onZoneChange}) {
  // string

  const notD3S = "3D activée";

  // helpers

  const d3 = zone.d3 ? zone.d3 : false;

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    const disable3D = !checked;
    const newZone = {...zone, d3: disable3D};
    onZoneChange(newZone);
  }
  return (
    <Box sx={{p: 1, pl: 3}}>
      <FormControlLabel
        control={<Switch checked={!d3} onChange={handleChange} size="small" />}
        label={
          <Typography
            variant="body2"
            sx={{color: !d3 ? "text.secondary" : "text.primary"}}
          >
            {notD3S}
          </Typography>
        }
      />
    </Box>
  );
}
