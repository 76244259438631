import {useDispatch, useSelector} from "react-redux";

import getArticlesUpdatesFromElementTypesGroups from "Features/articles/utils/getArticlesUpdatesFromElementTypesGroups";

import useArticlesByGroup from "../hooks/useArticlesByGroup";

import {
  Dialog,
  Box,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import {updateArticlesTemp} from "../articlesSlice";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";
import {updateLastUpdatedAt} from "Features/scopes/scopesSlice";

import copyTypes from "../utils/copyTypes";

export default function DialogAutoUpdateArticlesTypes({scene, open, onClose}) {
  const dispatch = useDispatch();

  // strings
  const title = "Mise à jour auto des articles";

  const saveS = "Enregistrer";

  // data

  const articlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const articlesGroup = useSelectedArticlesGroup();

  const elementTypesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(
    scene,
    {
      sortElementTypesByNum: true,
      filterByTypesGroupsIds: articlesGroup?.typesGroupsIds,
      filterByScope: true,
    }
  );

  const articles = useArticlesByGroup(scene, articlesGroup);

  console.log("articlesAAAA", articles);

  // handlers

  function onSave() {
    const articlesWithCoreTypes = articles.map((article) => {
      const newArticle = {
        ...article,
      };

      let types;
      let coreTypes = newArticle.coreTypes;
      if (
        coreTypes &&
        typeof coreTypes === "object" &&
        Object.entries(coreTypes).length > 0
      ) {
        types = copyTypes(coreTypes);
      } else {
        types = {};
      }
      newArticle.types = types;
      return newArticle;
    });

    const updatesBatch = getArticlesUpdatesFromElementTypesGroups(
      articlesWithCoreTypes,
      elementTypesGroups
    );
    console.log("updatesBatch", updatesBatch);
    dispatch(updateArticlesTemp(updatesBatch));
    dispatch(updateLastUpdatedAt()); // to update qty computation.
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>
          Voulez-vous mettre à jour les types d'articles automatiquement ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
