import {useDispatch} from "react-redux";

import {fetchRemoteScene, updateScene} from "Features/scenes/scenesSlice";

import {Edit} from "@mui/icons-material";
import useGetSectorsFromMeasurements from "./useGetSectorsFromMeasurements";
import useAccessToken from "Features/auth/useAccessToken";

export default function useActionsSectorsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const getSectors = useGetSectorsFromMeasurements(scene);

  // handlers

  async function repairSectors() {
    const sectors = getSectors();
    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        sectors,
      },
    };

    dispatch(updateScene({scene: newScene}));
  }

  async function refreshSectors() {
    dispatch(fetchRemoteScene({sceneId: scene.id, accessToken}));
  }
  // actions map

  const actionsMap = {
    editSectors: {
      icon: <Edit fontSize="small" />,
      name: "Edition",
      label: "Editer les secteurs",
    },
    refreshSectors: {
      label: "Rafraichir les secteurs",
      handler: refreshSectors,
    },
    repairSectors: {
      label: "Réparer les secteurs",
      handler: () => repairSectors(),
    },
  };

  return actionsMap;
}
