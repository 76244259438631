import React from "react";

import {useDispatch} from "react-redux";
import {setMainSection} from "Features/viewer3D/viewer3DSlice";

import {Button} from "@mui/material";
export default function ButtonOpenPdf() {
  const dispatch = useDispatch();
  // strings

  const seeString = "Open PDF";

  // handlers

  function handleClick() {
    dispatch(setMainSection("PDFVIEWER"));
  }
  return <Button onClick={handleClick}>{seeString}</Button>;
}
