import {HomeSharp} from "@mui/icons-material";
import {
  DoubleSide,
  MeshPhysicalMaterial,
  PlaneBufferGeometry,
  Triangle,
  Vector3,
  Vector2,
  Mesh,
  Quaternion,
  Plane,
  ShapeBufferGeometry,
  Shape,
  ShapeUtils,
  Path,
} from "three";

export default class Shape3D {
  constructor(points, holes) {
    this.material = new MeshPhysicalMaterial({
      color: 0xf52585,
      side: DoubleSide,
      transparent: true,
      opacity: 0.4,
    });

    const {object, area} = this.createObject(points, holes);
    this.object = object;
    this.area = area;
  }

  createObject(points, holes) {
    const a = new Vector3(points[0].x, points[0].y, points[0].z);
    const b = new Vector3(points[1].x, points[1].y, points[1].z);
    const c = new Vector3(points[2].x, points[2].y, points[2].z);
    const normal = new Vector3();

    const triangle = new Triangle(a, b, c);
    triangle.getNormal(normal);

    // plane object

    const planeGeo = new PlaneBufferGeometry(5, 5);
    const plane = new Mesh(planeGeo, this.material);

    const q = new Quaternion();
    q.setFromUnitVectors(new Vector3(0, 0, 1), normal);

    plane.applyQuaternion(q);
    plane.position.copy(a);
    plane.updateMatrixWorld();

    // projection plane

    const projPlane = new Plane();
    triangle.getPlane(projPlane);

    // get x,y coordinates

    const points2D = points.map((point) => {
      const pointV = new Vector3(point.x, point.y, point.z);
      const projPointV = new Vector3();
      projPlane.projectPoint(pointV, projPointV);
      plane.worldToLocal(projPointV);
      return new Vector2(projPointV.x, projPointV.y);
    });

    // add holes

    const holes2D = holes?.map((hole) => {
      const hole2D = hole.map((point) => {
        const pointV = new Vector3(point.x, point.y, point.z);
        const projPointV = new Vector3();
        projPlane.projectPoint(pointV, projPointV);
        plane.worldToLocal(projPointV);
        return new Vector2(projPointV.x, projPointV.y);
      });
      return hole2D;
    });

    // create shape

    const shape = new Shape(points2D);
    if (holes?.length > 0)
      holes2D.forEach((hole2D) => {
        if (hole2D.length > 2) {
          const hole2DPath = new Path(hole2D);
          shape.holes.push(hole2DPath);
        }
      });

    const shapeGeometry = new ShapeBufferGeometry(shape);
    const shapeMesh = new Mesh(shapeGeometry, this.material);
    shapeMesh.layers.enable(1);

    // move shape

    shapeMesh.applyQuaternion(q);
    shapeMesh.position.copy(a);

    // compute surface
    const areaContour = ShapeUtils.area(points2D);
    const areaHoles = !holes
      ? 0
      : holes2D.reduce((acc, hole2D) => {
          const areaHole = ShapeUtils.area(hole2D);
          return acc + areaHole;
        }, 0);

    return {object: shapeMesh, area: areaContour - areaHoles};
  }
}
