import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ButtonDataGridClearSelection from "./ButtonDataGridClearSelection";
import ToggleDataGridMode from "./ToggleDataGridMode";
import ButtonSelectMeasurementsInDatagrid from "./ButtonSelectMeasurementsInDatagrid";

export default function SectionDataGridToolbar({editor3d, scene, viewer}) {
  // data

  // const id = useSelector((s) => s.measurements.selectedMeasurementId);
  const ids = useSelector((s) => s.measurements.selectedMeasurementIds);
  const types = useSelector((s) => s.measurements.selectedTypesInDatagrid);
  const ressources = useSelector(
    (s) => s.measurements.selectedRessourcesInDatagrid
  );

  // helper

  const noSelection =
    // !Boolean(id) &&
    ids.length === 0 &&
    types.length === 0 &&
    ressources.length === 0;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: 1,
        mb: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "&>*": {mr: 1},
        }}
      >
        {!viewer && noSelection && (
          <ButtonSelectMeasurementsInDatagrid scene={scene} />
        )}
        {!noSelection && <ButtonDataGridClearSelection editor3d={editor3d} />}
      </Box>
      <ToggleDataGridMode scene={scene} />
    </Box>
  );
}
