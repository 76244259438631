import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedPhase} from "../phasesSlice";
import useAddScenePhase from "../hooks/useAddScenePhase";

// import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsNewPhaseInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const createS = "Ajouter";

  // data

  const tempPhase = useSelector((s) => s.phases.tempPhase);
  const addScenePhase = useAddScenePhase(scene);

  // handlers

  async function handleCreateClick() {
    const newPhase = {id: nanoid(), ...tempPhase};
    addScenePhase(newPhase);
    dispatch(setSelectedPhase(newPhase));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
