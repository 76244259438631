// import {useDispatch} from "react-redux";
import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {Box, IconButton, Typography} from "@mui/material";
import {Save} from "@mui/icons-material";

import useVersionsByScene from "../hooks/useVersionsByScene";
import SelectorMultipleVersionsWithChip from "./SelectorMultipleVersionsWithChips";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

function getRevisionIdsHash(ids) {
  console.log("DEBUG ids from getRevisionIdsHash", ids);
  return ids
    ? ids
        .map((id) => (typeof id === "string" ? id : "1"))
        .sort()
        .join()
    : "all";
}

export default function SelectorVersionsForSceneModel({scene, model, variant}) {
  // variant=PDF / MEASUREMENTS_MODEL
  const dispatch = useDispatch();

  const [tempRevisionIds, setTempRevisionIds] = useState(model?.revisionIds);
  useEffect(() => {
    setTempRevisionIds(model?.revisionIds);
  }, [model?.id]);

  // helpers - title

  const count = !tempRevisionIds ? "all" : tempRevisionIds.length;
  const titleAll = "Calque actif pour toutes les versions";
  const titleOne = "Calque actif pour 1 version";
  const titleN = `Calque actif pour ${count} versions`;
  const titleNone = "Calque inactif";

  let title = titleN;
  if (count === 0) {
    title = titleNone;
  } else if (count === 1) {
    title = titleOne;
  } else if (count === "all") {
    title = titleAll;
  }

  // helpers

  const showSave =
    getRevisionIdsHash(tempRevisionIds) !==
    getRevisionIdsHash(model?.revisionIds);

  const versions = useVersionsByScene(scene);

  function handleChange(versionIds) {
    setTempRevisionIds(versionIds);
  }

  function handleSave() {
    console.log("SAVE revisionIds", tempRevisionIds);
    const updatedModel = {...model, revisionIds: tempRevisionIds};
    dispatch(updateModel({updatedModel}));
  }

  return (
    <Box sx={{width: 1}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
        }}
      >
        <Box sx={{display: "flex", justifyContent: "center"}}>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <SelectorMultipleVersionsWithChip
              selectedVersionIds={tempRevisionIds}
              onChange={handleChange}
              versions={versions}
            />

            <IconButton
              size="small"
              sx={{ml: 2, visibility: showSave ? "visible" : "hidden"}}
              onClick={handleSave}
            >
              <Save fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {variant !== "PDF" && (
        <Box sx={{mt: 3, px: 1, py: 0.5, bgcolor: "background.default"}}>
          <Typography
            color="text.secondary"
            sx={{fontSize: 13, fontStyle: "italic"}}
          >
            {title}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
