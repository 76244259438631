import {Box, Paper, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import useDrawingShapeImageUrlMap from "Features/elementTypes/hooks/useDrawingShapeImageUrlMap";
import ImageBasic from "Features/images/components/ImageBasic";
import {useDispatch, useSelector} from "react-redux";
import {setDrawingShape} from "../elementTyporSlice";
import useTempElementType from "../hooks/useTempElementType";

import IconElementType from "Features/elementTypes/components/IconElementType";
import useDrawingShapeNameMap from "Features/elementTypes/hooks/useDrawingShapeNameMap";

const drawingShapes1 = [
  "CIRCLE",
  "RECTANGLE",
  "SEGMENT",
  "POLYLINE",
  "POLYGON",
];
const drawingShapes2 = ["BEAM", "SLOPE", "STAIRS", "GABLE", "SLOPING_POLYGON"];

export default function SectionSelectorDrawingShape({onSelect}) {
  const dispatch = useDispatch();

  // strings

  const nameMap = useDrawingShapeNameMap();

  const imageMap = useDrawingShapeImageUrlMap();

  const drawingShape = useSelector((s) => s.elementTypor.drawingShape);

  const color = useSelector((s) => s.elementTypor.color);

  // handlers

  function handleClick(shape) {
    dispatch(setDrawingShape(shape));
    if (onSelect) onSelect(shape);
  }

  function handleNoDrawingShape() {
    dispatch(setDrawingShape(null));
    if (onSelect) onSelect(null);
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 1,
          pb: 2,
        }}
      >
        <IconButton onClick={handleNoDrawingShape}>
          <Close />
        </IconButton>
        <Box
          sx={{
            width: 200,
            height: 150,
            position: "relative",
          }}
        >
          <ImageBasic url={imageMap[drawingShape]} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mr: 3,
          }}
        >
          {drawingShapes1.map((shape) => {
            const selected = shape === drawingShape;
            return (
              <Box
                //sx={{width: 260, height: 150, p: 1, cursor: "pointer"}}
                sx={{
                  p: 0.5,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                key={shape}
                onClick={() => handleClick(shape)}
              >
                <Paper sx={{mr: 2}} elevation={selected ? 6 : 0}>
                  <IconElementType
                    drawingShape={shape}
                    color={selected ? color : "grey.400"}
                  />
                </Paper>
                <Typography
                  variant="body2"
                  color={selected ? "text.primary" : "text.secondary"}
                >
                  {nameMap[shape]}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: 1,
            flexDirection: "column",
          }}
        >
          {drawingShapes2.map((shape) => {
            const selected = shape === drawingShape;
            return (
              <Box
                //sx={{width: 260, height: 150, p: 1, cursor: "pointer"}}
                sx={{
                  p: 0.5,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                key={shape}
                onClick={() => handleClick(shape)}
              >
                <Paper sx={{mr: 2}} elevation={selected ? 6 : 0}>
                  <IconElementType
                    drawingShape={shape}
                    color={selected ? color : "grey.400"}
                  />
                </Paper>
                <Typography
                  variant="body2"
                  color={selected ? "text.primary" : "text.secondary"}
                >
                  {nameMap[shape]}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
