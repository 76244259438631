import getBoundingRectFromPath from "./getBoundingRectFromPath";
import testPointInRect from "./testPointInRect";

export default function getMeasurementVoidCandidates(
  measurement,
  measurements,
  delta
) {
  const pageNumber = measurement?.drawingProps?.pageNumber;
  if (pageNumber) {
    const rect = getBoundingRectFromPath(measurement.drawingProps.path, delta);
    const allCandidates = measurements.filter(
      (m) => ["SEGMENT", "POLYGON", "RECTANGLE"].includes(m.drawingShape) &&
      m.drawingProps.pageNumber === pageNumber
    );
    const candidates = allCandidates.filter((measurement) => {
      const point1 = measurement.drawingProps.path[0];
      const point2 = measurement.drawingProps.path[1];
      const midX = point1[0] + (point2[0] - point1[0]) * 0.5;
      const midY = point1[1] + (point2[1] - point1[1]) * 0.5;
      const pointc = [midX, midY];
      return testPointInRect(pointc, rect) || 
        testPointInRect(point1, rect) ||
        testPointInRect(point2, rect);
    });
    return candidates;
  }
  return [];
}
