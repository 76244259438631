import {useState} from "react";
import {useDispatch, useSelector} from "react-redux" ;

import {Menu, MenuItem, Typography, Button, Box} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import SelectorRessourceGroupInScope from "./SelectorRessourceGroupInScope";
import SelectorElementTypeGroupInScope from "./SelectorElementTypeGroupInScope";

import {
  setDataGridMode,
  setSelectedTypesInDatagrid,
  setSelectedRoomsInDatagrid,
  setSelectedRessourcesInDatagrid,
} from "Features/measurements/measurementsSlice";

export default function SelectorDataGridMode({scene, viewer, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const allS = "Eléments";
  const byElementTypeS = "Types";
  const byRessourceS = "Articles";
  const byRoomS = "Zones";

  // data

  let mode = useSelector((s) => s.measurements.dataGridMode);
  if (viewer) mode = "BY_RESSOURCE";

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleToolChange(mode) {
    dispatch(setDataGridMode(mode));

    dispatch(setSelectedTypesInDatagrid([]));
    dispatch(setSelectedRessourcesInDatagrid([]));
    dispatch(setSelectedRoomsInDatagrid([]));

    setAnchorEl(null);
  }

  // helpers - tools

  const tools = [
    {
      id: "ALL",
      label: allS,
    },
    {
      id: "BY_ROOM",
      label: byRoomS,
    },
    {
      id: "BY_ELEMENT_TYPE",
      label: byElementTypeS,
    },
    {
      id: "BY_RESSOURCE",
      label: byRessourceS,
    },
  ];

  // helpers - tool

  const tool = tools.find((t) => t.id === mode);

  // helper - button

  const buttonVariant = ["BY_RESSOURCE", "BY_ELEMENT_TYPE"].includes(mode)
    ? "text"
    : "contained";

  return (
    <>
      <Box
        sx={{
          color: "text.secondary",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: 1,
        }}
      >
        {!viewer && (
          <Button
            endIcon={<Down />}
            size="small"
            color="inherit"
            variant={buttonVariant}
            //onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            {tool?.label}
          </Button>
        )}
        {tool.id === "BY_RESSOURCE" && (
          <SelectorRessourceGroupInScope scene={scene} viewer={viewer} caplaEditor={caplaEditor} />
        )}
        {tool.id === "BY_ELEMENT_TYPE" && (
          <SelectorElementTypeGroupInScope scene={scene} />
        )}
      </Box>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={() => handleToolChange(tool.id)}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
