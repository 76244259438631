import {useDispatch, useSelector} from "react-redux";

import {Box, TextField, Typography} from "@mui/material";

import CardSharedboxUrl from "./CardSharedboxUrl";

import {setSelectedSharedboxFormGroup} from "../sharedboxesSlice";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import offerDescriptionMap from "Features/ebusiness/data/offerDescriptionMap";
import EditableImage from "Features/images/components/EditableImage";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

export default function FormSharedbox({
  scene,
  sharedbox,
  onChange,
  canEdit,
  hiddenFields = [],
}) {
  const dispatch = useDispatch();

  // strings

  const nameS = "Nom";
  const descriptionS = "Description";
  const companyS = "Entreprise";
  const codeS = "Code";
  const usesPricesS = "Consultation";
  const priceS = "Prix";
  const offerS = "Offre";
  const inputS = "Fichiers sources";
  const packagesS = "Calques d'éléments";
  const ressourceGroupsS = "Tables d'articles";
  const listingsS = "Jeux de données";
  const onboardingUrlS = "Onboarding (url)";
  const identificationS = "Identification";
  const accessS = "Accès";
  const consultationS = "Consultation";
  const overviewS = "Aperçu";
  const onBoardingS = "Vidéo";
  const orderS = "Offre commerciale";
  const availableDataS = "Données disponibles";
  const versionS = "Version par défaut";

  // data

  const group = useSelector((s) => s.sharedboxes.selectedSharedboxFormGroup);
  const versions = useVersionsByScene(scene);

  // item

  const item = {
    id: sharedbox?.id,
    name: sharedbox?.name,
    description: sharedbox?.description,
    pdfIds: sharedbox?.entities?.pdfIds ? sharedbox.entities.pdfIds : [],
    packageIds: sharedbox?.entities?.packageIds
      ? sharedbox.entities.packageIds
      : [],
    ressourceGroups: sharedbox?.entities?.ressourceGroups
      ? sharedbox.entities.ressourceGroups
      : [],
    listingIds: sharedbox?.entities?.listingIds
      ? sharedbox.entities.listingIds
      : [],
    onboardingUrl: sharedbox?.onboardingUrl,
    company: sharedbox?.company,
    code: sharedbox?.code,
    usesPrices: sharedbox?.usesPrices,
    overviewUrl: sharedbox?.overviewUrl,
    bimboxPrice: sharedbox?.bimboxPrice,
    bimboxOfferKey: sharedbox?.bimboxOfferKey,
    defaultVersionId: sharedbox?.defaultVersionId,
  };

  // helpers

  const offerOptions = [
    {key: "DEFAUT", name: null},
    ...Object.keys(offerDescriptionMap)
      .sort((a, b) => a.localeCompare(b))
      .map((option) => ({key: option, name: option})),
  ];

  const versionsOptions = [
    {key: "all", name: "Toutes les versions"},
    ...versions.map((v) => ({
      key: v.id,
      name: `V${v.num} ${v.name}`,
    })),
  ];

  // handlers

  function handleChange(item) {
    const newSharedbox = {};
    newSharedbox.id = item.id;
    newSharedbox.name = item.name;
    newSharedbox.company = item.company;
    newSharedbox.code = item.code;
    newSharedbox.usesPrices = item.usesPrices;
    newSharedbox.description = item.description;
    newSharedbox.onboardingUrl = item.onboardingUrl;
    newSharedbox.entities = {
      pdfIds: item.pdfIds,
      packageIds: item.packageIds,
      listingIds: item.listingIds,
      ressourceGroups: item.ressourceGroups,
    };
    newSharedbox.overviewUrl = item.overviewUrl;
    newSharedbox.bimboxPrice = item.bimboxPrice;
    newSharedbox.bimboxOfferKey = item.bimboxOfferKey;
    newSharedbox.defaultVersionId = item.defaultVersionId;
    onChange(newSharedbox);
  }

  function handleUrlChange(e) {
    item.onboardingUrl = e.target.value;
    handleChange(item);
  }

  function handleImageUrlChange(overviewUrl) {
    handleChange({...item, overviewUrl});
  }

  function handleGroupChange(group) {
    dispatch(setSelectedSharedboxFormGroup(group));
  }

  // template

  const template = {
    key: "kvfSharedBox",
    groups: [
      {code: "IDENTIFICATION", label: identificationS},
      {code: "ACCESS", label: accessS},
      {code: "CONSULTATION", label: consultationS},
      {code: "OFFER", label: offerS},
    ],
    fields: [
      {
        group: "IDENTIFICATION",
        key: "sectionIdentification",
        type: "section",
        name: identificationS,
      },
      {group: "IDENTIFICATION", key: "name", type: "text", name: nameS},
      {
        group: "IDENTIFICATION",
        key: "description",
        type: "textMultiline",
        name: descriptionS,
      },
      {
        group: "IDENTIFICATION",
        key: "sectionOverviewUrl",
        type: "section",
        name: onBoardingS,
      },
      {
        group: "ACCESS",
        key: "sectionAccess",
        type: "section",
        name: availableDataS,
      },
      {group: "ACCESS", key: "pdfIds", type: "inputs", name: inputS},
      {
        group: "ACCESS",
        key: "packageIds",
        type: "packageIds",
        name: packagesS,
      },
      {
        group: "ACCESS",
        key: "ressourceGroups",
        type: "ressourceGroups",
        name: ressourceGroupsS,
      },
      {
        group: "ACCESS",
        key: "listingIds",
        type: "listingIds",
        name: listingsS,
      },
      {
        group: "ACCESS",
        key: "defaultVersionId",
        type: "selector",
        name: versionS,
        options: versionsOptions,
        sizeKey: 4,
      },
      {
        group: "CONSULTATION",
        key: "sectionConsultation",
        type: "section",
        name: consultationS,
      },
      {group: "CONSULTATION", key: "company", type: "text", name: companyS},
      {group: "CONSULTATION", key: "code", type: "text", name: codeS},
      {
        group: "CONSULTATION",
        key: "usesPrices",
        type: "checkbox",
        name: usesPricesS,
      },
      {
        group: "OFFER",
        key: "sectionOrder",
        type: "section",
        name: orderS,
      },
      {group: "OFFER", key: "bimboxPrice", type: "number", name: priceS},
      {
        group: "OFFER",
        key: "bimboxOfferKey",
        type: "selector",
        name: offerS,
        options: offerOptions,
      },
      {
        group: "OFFER",
        key: "sectionOverviewUrl",
        type: "section",
        name: overviewS,
      },
    ],
  };
  return sharedbox ? (
    <Box>
      <KeyValueForm
        scene={scene}
        item={item}
        template={template}
        onChange={handleChange}
        hiddenFields={hiddenFields}
        canEdit={canEdit}
        sizeKey={3}
        group={group}
        onGroupChange={handleGroupChange}
      />
      {group === "OFFER" && (
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              px: 1,
              bgcolor: "background.default",
              height: 240,
            }}
          >
            <EditableImage
              url={item?.overviewUrl}
              onChange={handleImageUrlChange}
              container="scene-files"
              sceneId={scene?.id}
              readOnly={!canEdit}
            />
          </Box>
          <CardSharedboxUrl sharedbox={item} isOrder={true} scene={scene} />
        </Box>
      )}
      {group === "IDENTIFICATION" && (
        <Box sx={{width: 1, p: 1, pt: 2}}>
          <TextField
            fullWidth
            multiline
            label={<Typography variant="body2">{onboardingUrlS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={item.onboardingUrl ? item.onboardingUrl : ""}
            onChange={handleUrlChange}
          />
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
}
