import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateClippingPlane, updateScene} from "Features/scenes/scenesSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Button,
  DialogActions,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
export default function DialogRenameClippingPlane({
  open,
  clippingPlane,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();
  const {t} = useTranslation(["common"]);

  // string

  const dialogTitleString = capitalizeStart(t("common:rename"));
  const saveButton = capitalizeStart(t("common:save"));

  // local state

  const [newName, setNewName] = useState(clippingPlane?.name);

  // handlers

  function handleNewNameChange(e) {
    setNewName(e.target.value);
  }

  function handleRenameSave() {
    const newClippingPlane = {...clippingPlane, name: newName};
    dispatch(updateClippingPlane(newClippingPlane));
    onClose();

    // update scene

    const newScene = {...scene};
    const clippingPlanes = scene.data?.clippingPlanes
      ? [...scene.data.clippingPlanes]
      : [];
    const newClippingPlanes = clippingPlanes.map((cp) => {
      if (cp.id === newClippingPlane.id) {
        return newClippingPlane;
      } else {
        return cp;
      }
    });
    if (!scene.data) {
      newScene.data = {clippingPlanes: newClippingPlanes};
    } else {
      const newSceneData = {...scene.data};
      newSceneData.clippingPlanes = newClippingPlanes;
      newScene.data = newSceneData;
    }
    dispatch(updateScene({scene: newScene}));
  }

  // update newName

  useEffect(() => {
    console.log("EFFECT");
    if (clippingPlane?.name) setNewName(clippingPlane.name);
  }, [clippingPlane?.name]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitleString} </DialogTitle>
      <DialogContent>
        <InputBase
          defaultValue={clippingPlane?.name}
          onChange={handleNewNameChange}
          sx={{minWidth: 350, maxWidth: 450}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameSave}>{saveButton}</Button>
      </DialogActions>
    </Dialog>
  );
}
