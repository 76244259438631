import React, {useState} from "react";

import {LoadingButton} from "@mui/lab";
import {CloudUpload as Upload} from "@mui/icons-material";

import {updateRemoteModelMetadataService} from "Features/viewer3D/services";

import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonUploadModelData({model, scene}) {
  const accessToken = useAccessToken();

  // strings

  const uploadString = "Upload";

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const canUpload =
    Boolean(scene?.id) && accessToken && Boolean(model?.remoteId);

  // handlers

  async function handleUploadClick() {
    setLoading(true);
    await updateRemoteModelMetadataService({
      accessToken,
      model,
      sceneId: scene.id,
    });
    setLoading(false);
  }
  return (
    <LoadingButton
      size="small"
      variant="outlined"
      startIcon={<Upload />}
      loadingPosition="start"
      onClick={handleUploadClick}
      disabled={!canUpload}
      loading={loading}
    >
      {uploadString}
    </LoadingButton>
  );
}
