import {useState, useEffect} from "react";
// import {useSelector, useDispatch} from "react-redux";

// import {updateModel} from "Features/viewer3D/viewer3DSlice";

import {Grid, Typography, InputBase, Box} from "@mui/material";

export default function FieldZoneName({zone, onZoneChange}) {
  // const dispatch = useDispatch();

  // strings

  const nameS = "Name";

  // props

  const name = zone.name;

  // // data

  // const models = useSelector((s) => s.viewer3D.models);
  // const zoneImageModel = models.find((m) => m.id === zone.imageModelId);

  // state

  const initTempName = name ? name : "";
  const [tempName, setTempName] = useState(initTempName);
  useEffect(() => setTempName(initTempName), [initTempName]);

  // handlers

  function handleInputChange(e) {
    let name = e.target.value;
    setTempName(name);
    onZoneChange({...zone, name});
  }

  // function handleBlur() {
  //   return;
  //   if (tempName.length === 0 || tempName === zone.name) return;
  //   const annotationId = zone.id;
  //   const props = {name: tempName};
  //   editorPdf.annotationsManager.updateAnnotationEntityProps({
  //     annotationId,
  //     props,
  //   });

  //   // update image model name
  //   const updatedModel = {...zoneImageModel, name: tempName};
  //   dispatch(updateModel({updatedModel}));
  // }

  return (
    <Grid
      container
      sx={{
        px: 1,
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid
        xs={6}
        item
        sx={{
          px: 0,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{display: "flex", height: 1, alignItems: "center"}}>
          <Typography sx={{fontSize: 13}} noWrap>
            {nameS}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={6} item sx={{px: 1}}>
        <InputBase
          value={tempName}
          onChange={handleInputChange}
          // onBlur={handleBlur}
          sx={{
            fontSize: 13,
            whiteSpace: "pre-line",
            py: 0,
            "& .MuiInputBase-input": {px: 0},
          }}
        />
      </Grid>
    </Grid>
  );
}
