import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import ProcessConfigScale from "./ProcessConfigScale";

import DisplayBox from "Components/DisplayBox";

import {setMode, setStep} from "Features/viewer3D/viewer3DSlice";

export default function ToolConfigScale({editor3d, onClose}) {
  const dispatch = useDispatch();
  // data

  const mode = useSelector((state) => state.viewer3D.mode);

  // init

  useEffect(() => {
    dispatch(setMode("CONFIG_SCALE"));
    dispatch(setStep("POINT_1"));
  }, []);

  return (
    <div>
      <DisplayBox open={mode === "CONFIG_SCALE"}>
        <ProcessConfigScale editor3d={editor3d} onClose={onClose} />
      </DisplayBox>
    </div>
  );
}
