import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setFiltersOpenedClosed} from "../issuesSlice";

import {ToggleButtonGroup, ToggleButton, Box, Typography} from "@mui/material";

export default function ToggleFilterOpened() {
  const dispatch = useDispatch();

  // strings

  const openedS = "Opened";
  const closedS = "Closed";

  // data

  const filtersOpenedClosed = useSelector((s) => s.issues.filtersOpenedClosed);

  // handlers

  function handleChange(filters) {
    dispatch(setFiltersOpenedClosed(filters));
  }

  return (
    <ToggleButtonGroup
      //exclusive
      value={filtersOpenedClosed}
      onChange={(e, v) => handleChange(v)}
      size="small"
      sx={{height: 30}}
    >
      <ToggleButton value="OPENED">
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography noWrap sx={{fontSize: 12}}>
            {openedS}
          </Typography>
        </Box>
      </ToggleButton>

      <ToggleButton value="CLOSED">
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography noWrap sx={{fontSize: 12}}>
            {closedS}
          </Typography>
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
