import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormBimapp from "./FormBimapp";
import CardBimappUrl from "./CardBimappUrl";

import useSelectedBimapp from "../hooks/useSelectedBimapp";
import {setEditedBimapp, setTempBimapp} from "../bimappsSlice";

export default function SectionBimappInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const isEditing = useSelector((s) => s.bimapps.isEditingBimapp);
  const editedBimapp = useSelector((s) => s.bimapps.editedBimapp);
  const tempBimapp = useSelector((s) => s.bimapps.tempBimapp);
  const selectedBimapp = useSelectedBimapp(scene);

  // helper

  const isTemp = !selectedBimapp && !isEditing;
  const isEdited = selectedBimapp && isEditing;
  const isSelected = selectedBimapp && !isEditing;

  // handlers

  function handleTempBimappChange(bimapp) {
    dispatch(setTempBimapp(bimapp));
  }
  function handleEditedBimappChange(bimapp) {
    dispatch(setEditedBimapp(bimapp));
  }
  return (
    <Box sx={{pt: 4}}>
      <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
        {isTemp && (
          <FormBimapp
            scene={scene}
            bimapp={tempBimapp}
            onChange={handleTempBimappChange}
            canEdit={true}
          />
        )}
        {isSelected && (
          <Box>
            <CardBimappUrl bimapp={selectedBimapp} />
            <FormBimapp bimapp={selectedBimapp} canEdit={false} scene={scene} />
          </Box>
        )}
        {isEdited && (
          <FormBimapp
            scene={scene}
            bimapp={editedBimapp}
            onChange={handleEditedBimappChange}
            canEdit={true}
          />
        )}
      </Box>
    </Box>
  );
}
