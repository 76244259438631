import React from "react";

import {useSelector} from "react-redux";
import useSceneListings from "Features/viewer3D/useSceneListings";

import {Box, Typography} from "@mui/material";

import ListItemListing from "./ListItemListing";

export default function ListListings({scene}) {
  // data

  const {status, data: listings} = useSceneListings({sceneId: scene?.id});

  // components

  const waiting = <Typography>...</Typography>;

  const list = listings.map((listing) => (
    <ListItemListing key={listing.id} listing={listing} />
  ));

  return (
    <Box sx={{bgcolor: "common.white"}}>
      {status === "loaded" ? list : waiting}{" "}
    </Box>
  );
}
