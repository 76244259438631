import {Box} from "@mui/material";

import ToolClipping from "Features/viewer3D/components/ToolClipping";

export default function ShortcutClippingPlanes({editor3d, scene}) {
  return (
    <Box>
      <ToolClipping editor3d={editor3d} scene={scene} />
    </Box>
  );
}
