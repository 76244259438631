import {useDispatch} from "react-redux";

import {Add} from "@mui/icons-material";

export default function useActionsZonesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // actions map

  const actionsMap = {
    createZone: {
      icon: <Add fontSize="small" />,
      name: "Fond de plan",
      label: "Créer un fond de plan",
    },
    createZoneFromImage: {
      label: "Créer depuis une image",
    },
  };

  return actionsMap;
}
