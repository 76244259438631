import {useState} from "react";

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";

export default function FilterParts({editor3d, model}) {
  // const dispatch = useDispatch();
  const {t} = useTranslation("viewer3D");

  // strings

  const filterString = t("viewer3D:filter.parts");

  // data

  const parts = model?.parts;

  // local state

  const [allParts, setAllParts] = useState(true);

  // helpers

  let indeterminate = false;
  const partsCount = parts?.length;
  if (partsCount) {
    const visibleCount = parts.filter((p) => p.visible).length;
    const delta = partsCount - visibleCount;
    indeterminate = !(delta === partsCount || delta === 0);
  }

  // handlers

  function handleAllPartsChange(event) {
    try {
      setAllParts((v) => !v);
      const show = event.target.checked;
      const entity = editor3d?.getEntity(model.id);
      if (show) {
        entity.showAllParts();
      } else {
        entity.hideAllParts();
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleCheckboxChange(e, partId) {
    try {
      const part = parts.find((p) => p.id === partId);
      console.log(part);
      const visible = !part.visible;
      const entity = editor3d?.getEntity(model.id);
      console.log("new visibility", visible);
      entity.setPartVisibility(partId, visible);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", paddingLeft: 0.5}}>
        <Checkbox
          size="small"
          checked={allParts}
          indeterminate={indeterminate}
          onChange={handleAllPartsChange}
        />
        <Typography variant="body2">
          <b>{filterString}</b>
        </Typography>
      </Box>
      <List dense>
        {parts?.map((part) => {
          const visible = part.visible || part.visible === undefined;
          return (
            <ListItem key={part.id} sx={{paddingBottom: 0, paddingTop: 0}}>
              <ListItemIcon sx={{height: 24, width: 24}}>
                <Checkbox
                  size="small"
                  edge="start"
                  checked={visible}
                  onChange={(e) => handleCheckboxChange(e, part.id)}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText>{`${part.name}`}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
