import {useState} from "react";

import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogTitle,
  TextField,
  Box,
  DialogActions,
  Button,
} from "@mui/material";

export default function FormEditRoom({room, onChange}) {
  const dispatch = useDispatch();

  // strings

  const numS = "Num";
  const nameS = "Description";
  const unitS = "Unité";

  const numPlaceholder = "x.x.x";
  const namePlaceholder = "Nom de la pièce";
  const unitPlaceholder = "ml, m², m³,...";

  // state

  const [num, setNum] = useState("");
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [hideNum, setHideNum] = useState("");
  const [isTitle, setIsTitle] = useState(false);
  const [style, setStyle] = useState("normal");

  // handler - onBlur

  function handleBlur() {
    onChange({num, name, unit});
  }
  // handlers

  function handleNumChange(e) {
    setNum(e.target.value);
  }
  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleUnitChange(e) {
    setUnit(e.target.value);
  }
  function handleHideNumChange(e) {
    setHideNum(e.target.value);
  }
  function handleIsTitleChange(e) {
    setIsTitle(e.target.value);
  }
  function handleStyleChange(e) {
    setStyle(e.target.value);
  }

  return (
    <Box sx={{width: 1}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Box sx={{width: 100, mr: 1}}>
          <TextField
            placeholder={numPlaceholder}
            label={numS}
            value={num}
            size="small"
            onChange={handleNumChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{flexGrow: 1, mr: 1}}>
          <TextField
            placeholder={namePlaceholder}
            label={nameS}
            value={name}
            size="small"
            onChange={handleNameChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{width: 100}}>
          <TextField
            placeholder={unitPlaceholder}
            label={unitS}
            value={unit}
            size="small"
            onChange={handleUnitChange}
            onBlur={handleBlur}
          />
        </Box>
      </Box>
    </Box>
  );
}
