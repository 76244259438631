import React from "react";

import {useDispatch} from "react-redux";
import {setOpenCreateElementTypeFastDialog} from "../elementTypesSlice";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";

import {Typography, Button, Box} from "@mui/material";

export default function CardCreateElementType() {
  const dispatch = useDispatch();

  // strings

  const title = "Type d'élément";
  const description =
    "Ajoutez un nouveau type d'élément à votre bimbox. Tous les éléments que vous créerez avec ce type partageront les mêmes propriétés.";

  const createS = "Créer";

  // helpers

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  function handleCreateClick() {
    dispatch(setOpenCreateElementTypeFastDialog(true));
  }

  return (
    <Box
      sx={{
        width: 1,
        p: 2,
        borderRadius: "4px",
        background,
        color: "common.white",
      }}
    >
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>

      <Box sx={{mt: 2}}>
        <Button
          size="small"
          onClick={handleCreateClick}
          color="inherit"
          variant="outlined"
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
