import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {withStyles} from "@mui/styles";

import {setSelectedListItemId} from "../leftPanelSlice";

import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";
import {setSelectedRoomId} from "Features/rooms/roomsSlice";
import {setSelectedMarker} from "Features/markers/markersSlice";
import {
  setSelectedMeasurementsModelId,
} from "Features/measurements/measurementsSlice";
import useSelectedMarker from "Features/markers/hooks/useSelectedMarker";

export default function ListSceneMarkers({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedId = useSelector((s) => s.leftPanel.selectedListItemId);
  const markers = useMarkersByScene(scene?.clientId);
  const selectedMarkersModelId = useSelector(
    (s) => s.markers.selectedMarkersModelId
  );
  const selectedMarker = useSelectedMarker();


  // helpers - items and callback

  const items = markers
    .filter((m) => m.modelId === selectedMarkersModelId)
    .sort((a, b) => a.description.localeCompare(b.description))
    .map((r) => {
      return {id: r.id, num: "", name: r.description};
    });

  const callback = (id) => {
    const marker = markers.find((m) => m.id === id);
    dispatch(
      setSelectedMarker({
        markersModelId: marker?.modelId,
        markerId: marker?.id,
      })
    );
    caplaEditor?.editor3d.sceneEditor.selectMarker({
      markersModelId: marker?.modelId,
      markerId: marker?.id,
    });
    caplaEditor?.editor3d.sceneEditor.focusMarker(marker);
  };
 

  const StyledItem = withStyles({
    root: {
      "&:hover": {
        backgroundColor: "secondary.main",
      },
    },
    selected: {},
  })(ListItem);

  // handlers

  const handleListItemClick = (option) => {
    const id = selectedId !== option.id ? option.id : null;
    dispatch(setSelectedListItemId(id));
    callback(id);
  };

  // effects

  useEffect(() => {
    if (selectedId !== selectedMarker?.id)
      dispatch(setSelectedListItemId(selectedMarker?.id));
    if (caplaEditor?.measDataManager.selectedModelId) {
      // caplaEditor?.measDataManager.setSelectedModelById(null);
      dispatch(setSelectedMeasurementsModelId(null));
    }
    dispatch(setSelectedRoomId(null));
  }, []);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        maxHeight: 1,
        display: items?.length > 0 ? "flex" : "none",
        flexDirection: "column",
        overflow: "auto",
        bgcolor: "common.white",
        mt: 2,
      }}
    >
      <List dense disablePadding>
        {items.map((item) => {
          return (
            <StyledItem
              key={item.id}
              disablePadding
            >
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "stretch",
                  px: 1,
                  py: 0,
                  color: "inherit",
                  bgcolor: "inherit",
                }}
                selected={selectedId === item.id}
                onClick={() => handleListItemClick(item)}
                disableGutters
                divider={true}
              >
                <ListItemText>
                  <Typography sx={{fontSize: 12}}>
                    {item.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </StyledItem>
          );
        })}
      </List>
    </Box>
  );
}
