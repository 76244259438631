import {AppBar, Toolbar, Link, Typography} from "@mui/material";
import Logo from "./Logo";
import Capla360Logo from "../Features/ui/components/Capla360Logo";
import SignInSignOutButton from "./SignInSignOutButton";
import {Link as RouterLink} from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logoContainer: {
    flexGrow: 1,
  },
  toolBar: {
    backgroundColor: "transparent",
    paddingRight: theme.spacing(1),
  },
  appbar: {
    backgroundColor: theme.palette.common.appBarBackground,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const NavBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} elevation={0} position="static">
        <Toolbar disableGutters className={classes.toolBar} variant="dense">
          <div className={classes.logoContainer}>
            <Capla360Logo />
          </div>

          <SignInSignOutButton />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
