import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import {Raycaster, Vector3} from "three";
import theme from "Styles/theme";

import {getImageSize} from "Features/images/imageUtils";
import {setImageOverlayMode} from "Features/viewer3D/viewer3DSlice";

export default class ImageOverlay {
  url; // url of the image being overlayed
  imageSize; // used to compute aspect ratio
  constructor({scene, editor}) {
    this.editor = editor;
    this.scene = scene;
    this.scale = 1;
    this.object = this.createObject();
    this.opacity = 50; // value between 0 and 100
    this.size = "contain";
  }

  createObject() {
    const image = document.createElement("div");
    image.style.height = "24px";
    image.style.width = "24px";
    image.style.transform = "translate(-50%,-50%)";
    //image.style.border = `2px solid ${theme.palette.primary.main}`;
    image.style.opacity = "70%";
    image.style.backgroundSize = "contain";

    image.hidden = true;
    const objectCSS = new CSS2DObject(image);
    objectCSS.layers.enable(1);
    this.scene.add(objectCSS);
    return objectCSS;
  }

  async setImage(url) {
    this.object.element.style.backgroundImage = `url(${url})`;
    this.object.element.style.backgroundPosition = "center center";
    this.object.element.style.backgroundRepeat = "no-repeat";
    this.url = url;
    this.imageSize = await getImageSize({fileURL: this.url});
  }

  toggleImageSize() {
    if (this.size === "contain") {
      this.size = "cover";
      this.object.element.style.backgroundSize = "cover";
    } else {
      this.size = "contain";
      this.object.element.style.backgroundSize = "contain";
    }
  }

  getAspectRatio() {
    const imageRatio = this.imageSize.width / this.imageSize.height;
    return imageRatio;
  }

  setOpacity(opacity) {
    //this.object.element.style.opacity = `${opacity}%`;

    // test resize
    this.scale = 1 + ((opacity - 50) / 50) * 0.1;
  }

  update({camera, bounds}) {
    const raycaster = new Raycaster();
    raycaster.setFromCamera({x: 0, y: 0}, camera);
    const direction = new Vector3().copy(raycaster.ray.direction);
    this.object.position.copy(
      new Vector3().copy(camera.position).add(direction.multiplyScalar(1))
    );

    const width = Math.floor(this.scale * bounds.width);
    const height = Math.floor(this.scale * bounds.height);

    this.object.element.style.height = `${height}px`;
    this.object.element.style.width = `${width}px`;

    // compare aspect ratio
    if (this.imageSize) {
      const imageRatio = this.getAspectRatio();
      const rendererRatio = bounds.width / bounds.height;

      if (imageRatio > rendererRatio && this.size === "contain") {
        this.object.element.style.backgroundSize = "cover";
        this.size = "cover";
      }

      if (imageRatio < rendererRatio && this.size === "cover") {
        this.object.element.style.backgroundSize = "contain";
        this.size = "contain";
      }
    }
  }

  hide() {
    this.object.element.hidden = true;
    this.editor.dispatch(setImageOverlayMode(false));
  }

  show() {
    this.object.element.hidden = false;
    this.editor.dispatch(setImageOverlayMode(true));
  }
}
