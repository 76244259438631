import {createSlice} from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    appMode: null, // set initially by the app, "VIEWER" or "EDITOR"
    desktopOrMobile: null,
    mobile: false,
    language: "en",
    drawer: false,
    listingsModule: false,
    listingModule: false,
    tabModels: "OVERVIEW",
    tabScene: "OVERVIEW",
    tabWorksite: "SCENES",
    fullscreen: false, // if true, means the viewer is fullscreen (hide AppTopBar)
    fullscreenTable: false, // true : to get full table.
    openSections: {
      outlet: false,
      outletDetail: false, // section on the right of the outlet.(redudant with call to action, keep to false)
      viewer3D: false, // viewer 3D with three.js
      fixedViewersBox: true,
      callToAction: false, // section on the right, for example to invite user to select a model to see it in the viewer
    },
    selectedFixedViewer: "PDF", // "MAP", "ZONE"
    firstLoadedPages: [], //'MEASUREMENTS',...
    homeMeasurementsInit: false, // true when home measurements were initialized.
    homeViewMode: "MEASUREMENTS", // "DATA","ISSUES"
    showHomeOutlet: true, // TO DELETE

    shortcutToolViewer3D: "", // "SECTORS"

    selectedModelId: null,
    selectedIssueId: null,

    // animate
    animateNavPanel: false,
    animateCloseNavPanel: false,
    animateBreadcrumbs: false,
    animateSelection: false,
    animateCloseSelection: false,
    animateViewmodes: false,
    animatePackage: false,

    // bestViews
    sceneModuleBestViewMap_withS: {}, // withS = with selection.
    sceneModuleBestViewMap_withoutS: {}, //

    // popover selection
    expandOpeningsInSelectionPopover: true,

    // snackbar

    snackbarMessage: "",
  },
  reducers: {
    setAppMode: (state, action) => {
      state.appMode = action.payload;
    },
    setDesktopOrMobile: (state, action) => {
      state.desktopOrMobile = action.payload;
    },
    setMobile: (state, action) => {
      state.mobile = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    toggleDrawer: (state) => {
      state.drawer = !state.drawer;
    },
    openDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    updateSize: (state, action) => {
      const size = action.payload; // children container of the listings page layout
      state.size = size; // {width,height}
    },
    onListingModuleClick: (state, action) => {
      const module = action.payload;
      state.listingModule = module;
    },
    onListingsModuleClick: (state, action) => {
      const module = action.payload;
      state.listingsModule = module;
    },
    setRunningIn: (state, action) => {
      state.runningIn = action.payload;
    },
    setTabModels: (state, action) => {
      state.tabModels = action.payload;
    },
    setTabScene: (state, action) => {
      state.tabScene = action.payload;
    },
    setTabWorksite: (state, action) => {
      state.tabWorksite = action.payload;
    },
    setFullscreen: (state, action) => {
      state.fullscreen = action.payload;
    },
    setOpenSections: (state, action) => {
      const os = action.payload;
      if (!os.outlet && !os.fixedViewersBox && !os.viewer3D && !os.callToAction)
        os.fixedViewersBox = true;
      state.openSections = os;
    },
    setSelectedFixedViewer: (state, action) => {
      state.selectedFixedViewer = action.payload;
    },
    newFirstLoadedPage: (state, action) => {
      const page = action.payload;
      if (!state.firstLoadedPages.includes(page))
        state.firstLoadedPages.push(page);
    },
    setHomeViewMode: (state, action) => {
      state.homeViewMode = action.payload;
    },
    setShowHomeOutlet: (state, action) => {
      state.showHomeOutlet = action.payload;
    },
    setSelectedIssueId: (state, action) => {
      state.selectedIssueId = action.payload;
    },
    setSelectedModelId: (state, action) => {
      state.selectedModelId = action.payload;
    },
    setFullscreenTable: (state, action) => {
      state.fullscreenTable = action.payload;
    },
    setHomeMeasurementsInit: (state, action) => {
      state.homeMeasurementsInit = action.payload;
    },
    setShortcutToolViewer3D: (state, action) => {
      state.shortcutToolViewer3D = action.payload;
    },
    // animation
    setAnimateNavPanel: (state, action) => {
      state.animateNavPanel = action.payload;
    },
    setAnimateCloseNavPanel: (state, action) => {
      state.animateCloseNavPanel = action.payload;
    },
    setAnimateBreadcrumbs: (state, action) => {
      state.animateBreadcrumbs = action.payload;
    },
    setAnimateSelection: (state, action) => {
      state.animateSelection = action.payload;
    },
    setAnimateCloseSelection: (state, action) => {
      state.animateCloseSelection = action.payload;
    },
    setAnimateViewmodes: (state, action) => {
      state.animateViewmodes = action.payload;
    },
    setAnimatePackage: (state, action) => {
      state.animatePackage = action.payload;
    },
    // best views
    updateSceneModuleBestViewMap: (state, action) => {
      const {sceneModule, bestView, withSelection} = action.payload;
      if (withSelection) {
        state.sceneModuleBestViewMap_withS[sceneModule] = bestView;
      } else {
        state.sceneModuleBestViewMap_withoutS[sceneModule] = bestView;
      }
    },
    updateSceneModuleBestViewMap_withoutS: (state, action) => {
      const {sceneModule, bestView} = action.payload;
      state.sceneModuleBestViewMap_withoutS[sceneModule] = bestView;
    },
    // popover
    setExpandOpeningsInSelectionPopover: (state, action) => {
      state.expandOpeningsInSelectionPopover = action.payload;
    },
    // snackbar
    setSnackbarMessage: (state, action) => {
      state.snackbarMessage = action.payload;
    },
  },
});

export const {
  setAppMode,
  // device
  setDesktopOrMobile,
  setMobile,
  setLanguage,
  //
  toggleDrawer,
  openDrawer,
  updateSize,
  onListingModuleClick,
  onListingsModuleClick,
  setRunningIn,
  setTabModels,
  setTabScene,
  setTabWorksite,
  setFullscreen,
  setFullscreenTable,
  setOpenSections,
  newFirstLoadedPage,
  setHomeViewMode,
  setShowHomeOutlet,
  setSelectedIssueId,
  setSelectedModelId,
  setHomeMeasurementsInit,
  setShortcutToolViewer3D,
  setSelectedFixedViewer,
  // animate
  setAnimateNavPanel,
  setAnimateCloseNavPanel,
  setAnimateBreadcrumbs,
  setAnimateSelection,
  setAnimateCloseSelection,
  setAnimateViewmodes,
  setAnimatePackage,
  // best views
  updateSceneModuleBestViewMap,
  // popover
  setExpandOpeningsInSelectionPopover,
  // snackbar
  setSnackbarMessage,
} = uiSlice.actions;

export default uiSlice.reducer;
