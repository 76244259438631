import {useSelector, useDispatch} from "react-redux";

import {setExpandOptions} from "../filtersSlice";

import {Box, Typography, Button} from "@mui/material";

import SwitchExcludeFilter from "./SwitchExcludeFilter";
import SwitchMultipleSelection from "./SwitchMultipleSelection";
import SwitchFilterByGroup from "./SwitchFilterByGroup";

export default function SectionFilterOptions({tab}) {
  const dispatch = useDispatch();
  // strings

  const seeS = "Voir les options";
  const hideS = "Masquer les options";

  // data

  const expandOptions = useSelector((s) => s.filters.expandOptions);

  // helpers

  const buttonLabel = expandOptions ? hideS : seeS;

  const showFilterByGroup = ["ELEMENT_TYPES", "RESSOURCES"].includes(tab);

  // handlers

  function handleExpandClick() {
    dispatch(setExpandOptions(!expandOptions));
  }
  return (
    <Box>
      <Box
        color="text.secondary"
        sx={{display: "flex", justifyContent: "flex-end"}}
      >
        <Button size="small" color="inherit" onClick={handleExpandClick}>
          {buttonLabel}
        </Button>
      </Box>
      {expandOptions && (
        <Box sx={{display: "flex", flexDirection: "column"}}>
          <SwitchMultipleSelection tab={tab} />
          <SwitchExcludeFilter tab={tab} />
          {showFilterByGroup && <SwitchFilterByGroup tab={tab} />}
        </Box>
      )}
    </Box>
  );
}
