import React, {useState, useEffect} from "react";

import {Grid, Typography, TextField, InputBase, Box} from "@mui/material";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {Event as DateIcon} from "@mui/icons-material";

import frLocale from "date-fns/locale/fr";

export default function FieldDate({
  name,
  value,
  onChange,
  locked,
  sizeKey = 12,
}) {
  // state

  const initTempValue = value ? value : null;
  const [tempValue, setTempValue] = useState(initTempValue);

  useEffect(() => setTempValue(initTempValue), [initTempValue]);

  // helpers

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  // handlers

  function handleChange(date) {
    setTempValue(date);

    if (!date) {
      onChange(null);
    } else if (dateIsValid(date)) {
      let _date = new Date(date);
      _date = _date.toISOString();
      console.log("fieldDate", _date);
      onChange(_date);
    }
  }

  return (
    <Grid
      container
      sx={{
        px: 1,
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid xs={sizeKey} item>
        <Box sx={{display: "flex", height: 1, alignItems: "center"}}>
          <Typography sx={{fontSize: 13}} noWrap>
            {name}
          </Typography>
        </Box>
      </Grid>
      <Grid
        xs={12 - sizeKey}
        item
        sx={{
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          px: 1,
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={frLocale}
        >
          <DatePicker
            disabled={locked}
            value={tempValue}
            onChange={handleChange}
            components={{
              OpenPickerIcon: DateIcon,
            }}
            format="DD-MM-YYYY"
            renderInput={({inputRef, inputProps, InputProps}) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  svg: {width: "16px", height: "16px"},
                  pr: 1,
                }}
              >
                <InputBase
                  ref={inputRef}
                  {...inputProps}
                  sx={{fontSize: 13, width: 1}}
                />
                {InputProps?.endAdornment}
              </Box>
            )}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
