import {useSelector, useDispatch} from "react-redux";

import {toggleLargePanel} from "../selectionSlice";

import {Box, Button, IconButton} from "@mui/material";
import {WidthWide, WidthNormal} from "@mui/icons-material";

export default function ButtonToggleLargePanelInHeader() {
  const dispatch = useDispatch();

  // data

  const panelIsLarge = useSelector((s) => s.selection.panelIsLarge);

  // handlers

  function handleClick() {
    dispatch(toggleLargePanel());
  }
  return (
    <Box sx={{color: "text.secondary"}}>
      <IconButton onClick={handleClick}>
        {panelIsLarge ? <WidthNormal /> : <WidthWide />}
      </IconButton>
    </Box>
  );
}
