import React from "react";

import {Autocomplete, TextField, Typography, Box} from "@mui/material";

import useObjectsets from "../useObjectsets";

export default function SelectorObjectsetProp({label, objectset, onChange}) {
  // props

  const props = objectset?.props ? objectset.props : [];

  // handlers

  function handlePropChange(prop) {
    onChange(prop);
  }
  return (
    <Box sx={{width: 1}}>
      <Autocomplete
        sx={{flexGrow: 1}}
        options={props}
        getOptionLabel={(option) => option}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => (
          <Typography {...props} variant="body2" sx={{pl: 1}}>
            {option}
          </Typography>
        )}
        onChange={(e, v) => handlePropChange(v)}
        //input={input}
        //onInputChange={(e, v) => setInput(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={<Typography variant="body2">{label}</Typography>}
            inputProps={{
              ...params.inputProps,
              sx: (theme) => ({
                fontSize: `${theme.typography.body2.fontSize} `,
              }),
            }}
          />
        )}
      />
    </Box>
  );
}
