export default function fillWorksheetWithMeasurements({
  worksheet,
  measurements,
  rowStartIndex,
}) {
  measurements.forEach(
    (
      {code, type_name, quantity_unit, quantity_value, sector, room, isVoid},
      index
    ) => {
      const row = worksheet.getRow(rowStartIndex + index);
      if (typeof code === "string") row.getCell(1).value = code;
      if (typeof isVoid === "boolean")
        row.getCell(2).value = isVoid ? "vide" : "plein";
      if (typeof type_name === "string") row.getCell(3).value = type_name;
      if (typeof quantity_unit === "string")
        row.getCell(4).value = quantity_unit;
      if (typeof quantity_value === "number" && !isNaN(quantity_value))
        row.getCell(5).value = quantity_value;
      if (typeof sector === "string") row.getCell(6).value = sector;
      if (typeof room === "string") row.getCell(7).value = room;
    }
  );
}
