import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, Input} from "@mui/material";
import {update} from "Features/ifc/ifcSlice";

export default function BlockEditableArticleName({article}) {
  const dispatch = useDispatch();

  // state

  const initName = article?.name ?? "";

  const [name, setName] = useState(initName);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setName(initName);
  }, [initName]);

  // handlers

  function handleBlur() {
    setIsEditing(false);
    const newName = name.trim();
    if (newName !== article.name) {
      dispatch(updateArticleTemp({id: article.id, name}));
    }
  }

  function handleFocus() {
    setIsEditing(true);
  }

  return (
    <Box sx={{height: 30, display: "flex", alignItems: "center", width: 1}}>
      <Input
        fullWidth
        multiline
        disableUnderline
        sx={{
          color: isEditing ? "primary.main" : "text.primary",
          fontSize: 12,
        }}
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoFocus
      />
    </Box>
  );
}
