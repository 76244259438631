import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Typography,
  Chip,
  Paper,
  ClickAwayListener,
  Autocomplete,
  TextField,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import { setSectorsInScope } from "Features/measurements/measurementsSlice";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import { setBuildingInScope } from "Features/buildings/buildingsSlice";

export default function SelectorBuildingInScope({scene}) {
  const dispatch = useDispatch();
  
  // strings
  
  const allS = "Tous";
  
  // data
  
  const nameMap = useSceneModuleNameMap();
  const sectors = useSectorsByScene(scene);
  const buildingInScope = useSelector((s) => s.buildings.buildingInScope);
  
  // state
  
  const [open, setOpen] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  
  // helper - buildings

  let buildings = [... new Set(sectors.map((s) => s.building))].sort();
  buildings = buildings.map((b) => ({id: b, name: b === undefined ? "-?-" : b}));
  
  // helper - label
  
  let label = buildingInScope?.name;
  if (!label) label = allS;
  const textFieldLabel = nameMap["BUILDINGS"];
  
  // handlers
  
  function handleMoreClick() {
    setOpen(true);
    setOpenAutocomplete(true);
  }
  function handleClose() {
    setOpen(false);
    setOpenAutocomplete(false);
  }
  
  function handleChange(building) {
    if (!building) {
      dispatch(setBuildingInScope(undefined));
      dispatch(setSectorsInScope([]));
    } else if (building === "-?-") {
      dispatch(setBuildingInScope(null));
      dispatch(setSectorsInScope([null]));
    } else {
      dispatch(setBuildingInScope(building));
      const sectorsByBuilding = sectors.filter((s) => s.building === building);
      dispatch(setSectorsInScope(sectorsByBuilding.map((s) => s.id)));
    }
    handleClose();
  }
  
  function handleClickAwayListener() {
    setOpen(false);
    setOpenAutocomplete(false);
  }
  
  return (
    <>
    {!open && (
      <Chip
        label={label}
        deleteIcon={<Down />}
        onDelete={handleMoreClick}
        size="small"
      />
    )}
      
    {open && (
      <Paper sx={{p: 1}}>
        <ClickAwayListener onClickAway={handleClickAwayListener}>
          <Box sx={{width: 150}}>
            <Autocomplete
              open={openAutocomplete}
              sx={{flexGrow: 1}}
              options={buildings}
              value={buildingInScope}
              isOptionEqualToValue={(option, value) =>
                option?.id === value?.id
              }
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <Typography {...props} sx={{pl: 1, fontSize: 13}}>
                {option?.name}
                </Typography>
              )}
              onChange={(e, v) => handleChange(v)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  size="small"
                  label={
                    <Typography variant="body2">{textFieldLabel}</Typography>
                  }
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      fontSize: 13,
                    },
                  }}
                />
              )}
            />
          </Box>
        </ClickAwayListener>
      </Paper>
    )}
  </>
  );
}
          