export default function getMeasCountByZoneIdMap(measurements) {
  const map = {};

  if (Array.isArray(measurements)) {
    measurements.forEach((measurement) => {
      const item = map[measurement.zoneId];
      if (!item) {
        map[measurement.zoneId] = 1;
      } else {
        map[measurement.zoneId] += 1;
      }
    });
  }

  return map;
}
