import {createSlice} from "@reduxjs/toolkit";

export const sectorsSlice = createSlice({
  name: "sectors",
  initialState: {
    selectedSectorId: null,
    lastSectorId: null, // last created zone => used to select in the DataGrid the new zone.
    shortcutElevationZ: [],

    isEditingSector: false,
    editedSector: {},
    tempSector: {},
  },
  reducers: {
    setSelectedSectorId: (state, action) => {
      state.selectedSectorId = action.payload;
    },
    setLastSectorId: (state, action) => {
      state.lastSectorId = action.payload;
    },
    setShortcutElevationZ: (state, action) => {
      state.shortcutElevationZ = action.payload;
    },
    setEditedSector: (state, action) => {
      state.editedSector = action.payload;
    },
    setIsEditingSector: (state, action) => {
      state.isEditingSector = action.payload;
    },
    setTempSector: (state, action) => {
      state.tempSector = action.payload;
    },
  },
});

export const {
  setSelectedSectorId,
  setLastSectorId,
  setShortcutElevationZ,
  setIsEditingSector,
  setTempSector,
  setEditedSector,
} = sectorsSlice.actions;

export default sectorsSlice.reducer;
