import {useSelector, useDispatch} from "react-redux";
import {setOpenDialogFsCreateElementType} from "../elementTyporSlice";

import useTempElementType from "../hooks/useTempElementType";

import {Dialog, IconButton, Box} from "@mui/material";
import {Close} from "@mui/icons-material";

import SectionMain from "./SectionMain";
import SectionSelectorDrawingShape from "./SectionSelectorDrawingShape";
import SectionImagePreview from "./SectionImagePreview";

import ContainerElementType from "Features/elementTypes/components/ContainerElementType";
import SectionButtonCreateElementType from "./SectionButtonCreateElementType";

export default function DialogFsCreateType({caplaEditor, scene}) {
  const dispatch = useDispatch();
  // data

  const open = useSelector((s) => s.elementTypor.openDialogFsCreateElementType);

  const tempElementType = useTempElementType();

  // handlers

  function handleClose() {
    dispatch(setOpenDialogFsCreateElementType(false));
    caplaEditor?.editorPdf?.annotationsManager.deleteTempAnnotationToCreateType();
  }
  return (
    <Dialog open={open} fullScreen>
      <Box
        sx={{
          width: 1,
          height: 1,
          bgcolor: "background.default",
          display: "flex",
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{position: "absolute", top: 2, right: 2, zIndex: 100}}
        >
          <Close />
        </IconButton>

        <Box
          sx={{
            flexGrow: 1,
            height: 1,
            display: "flex",
            alignItems: "column",
            bgcolor: "common.white",
            minWidth: 0,
            border: "1px solid red",
          }}
        >
          <SectionMain />
        </Box>
        <Box
          sx={{
            width: 500,
            minWidth: "500px",
            height: 1,
            bgcolor: "background.default",
          }}
        >
          <SectionImagePreview />
          <ContainerElementType elementType={tempElementType} />

          <SectionButtonCreateElementType caplaEditor={caplaEditor} />
        </Box>
      </Box>
    </Dialog>
  );
}
