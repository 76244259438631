import React, {useState} from "react";
import {IconButton, Dialog, Box, alpha} from "@mui/material";
import {Visibility, Close, Fullscreen} from "@mui/icons-material";
import ImageBasic from "./ImageBasic";

import Stretch from "Features/ui/components/Stretch";

export default function ImagePreview({src, outlined = false, detail = false}) {
  const [open, setOpen] = useState(false);

  function handlePreviewClick() {
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
  }

  return (
    <Box sx={{width: 1, height: 1}}>
      <Dialog fullScreen open={open} onClose={handleDialogClose}>
        <Box sx={{position: "relative", width: "100%"}}>
          <Box sx={{position: "absolute", right: 0, zIndex: 900}}>
            <IconButton onClick={handleDialogClose} size="large">
              <Close />
            </IconButton>
          </Box>
          <Stretch>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                border: (theme) => `1px solid ${theme.palette.secondary.main}`,
              }}
            >
              <ImageBasic url={src} innerSize={true} />
            </Box>
          </Stretch>
        </Box>
      </Dialog>
      <Box
        sx={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          position: "relative",
          backgroundImage: `url(${src})`,
          height: 1,
          width: 1,
          ...(outlined && {
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: "4px",
          }),
        }}
      >
        {detail && (
          <Box
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 30,
              height: 30,
              borderRadius: "4px",
              bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
            }}
          >
            <IconButton onClick={handlePreviewClick} size="small">
              <Fullscreen fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}
