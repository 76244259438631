import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

// import {Vector3} from "three";

import {
  addClippingPlane,
  updateClippingPlanesStatus,
} from "Features/scenes/scenesSlice";

export default function useHorizontalClippingPlane({editor3d, scene}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (editor3d?.clipper && scene?.clientId) {
      const newClippingPlane = {
        id: nanoid(),
        sceneClientId: scene.clientId,
        name: "Horizontal plane",
        normal: {x: 0, y: 1, z: 0},
        origin: {x: 0, y: 0, z: 0},
      };

      // const newPlane = editor3d.clipper.createPlane({
      //   origin: new Vector3(0, 0, 0),
      //   normal: new Vector3(0, -1, 0),
      //   id: newClippingPlane.id,
      //   sceneClientId: newClippingPlane.sceneClientId,
      //   horizontal: true,
      // });

      dispatch(addClippingPlane(newClippingPlane));
      dispatch(
        updateClippingPlanesStatus({
          clippingPlaneId: newClippingPlane.id,
          disabled: false,
          edit: true,
        })
      );
    }
  }, [editor3d?.clipper, scene?.clientId]);
}
