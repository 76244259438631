import {useState} from "react";

import {Box, CircularProgress, IconButton, Typography} from "@mui/material";
import {CloudUpload, Delete} from "@mui/icons-material";

import {isImageFile} from "../imageUtils";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";

export default function SelectorImageFile({onFileChange}) {
  //strings

  const selectImageString = "Depuis mon ordinateur";
  // const saveString = "Save";
  const dragS = "Glisser & déposer une image";

  // state

  const [loading, setLoading] = useState(false);
  const [tempUrl, setTempUrl] = useState(null);
  const [hover, setHover] = useState();

  // handlers - drag & drop

  function handleDragEnter() {
    setHover(true);
  }
  function handleDragLeave() {
    setHover(false);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("DROP");
    setHover(false);
    try {
      let file = e.dataTransfer.items[0].getAsFile();
      if (isImageFile(file)) {
        handleFileChange(file);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  // handlers - files

  function handleFileChange(file) {
    setTempUrl(URL.createObjectURL(file));
    onFileChange(file);
  }

  function handleDelete() {
    setTempUrl(null);
    onFileChange(null);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundImage: `url(${tempUrl})`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {!tempUrl && (
        <Box sx={{flex: 1, p: 2, width: 1}}>
          <Box
            sx={{
              bgcolor: "background.default",
              position: "relative",
              width: 1,
              height: 1,
              py: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              sx={{
                position: "absolute",
                height: 1,
                width: 1,
                border: (theme) => `2px dashed ${theme.palette.divider}`,
                borderColor: (theme) =>
                  hover ? theme.palette.primary.flash : theme.palette.divider,
              }}
            />
            <CloudUpload color="action" />
            <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
              {dragS}
            </Typography>
          </Box>
        </Box>
      )}
      {!tempUrl && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "left",
            color: "text.secondary",
          }}
        >
          <FileSelectorButton
            onFileChange={handleFileChange}
            buttonName={selectImageString}
            startIcon="computer"
            buttonVariant="inline"
            buttonColor="inherit"
          />
        </Box>
      )}

      {tempUrl && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            bgcolor: "white",
            //border: (theme) => `1px solid ${theme.palette.primary.main}`,
            position: "absolute",
            top: 4,
            right: 4,
            zIndex: 100,
          }}
        >
          <IconButton size="small" color="primary" onClick={handleDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
