import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    selectedSceneModule: "MEASUREMENTS",
  },
  reducers: {
    setSelectedSceneModule: (state, action) => {
      state.selectedSceneModule = action.payload;
    },
  },
});

export const {setSelectedSceneModule} = onboardingSlice.actions;

export default onboardingSlice.reducer;
