import {useSelector, useDispatch} from "react-redux";
import {useEffect} from "react";

import {setNum} from "../elementTyporSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedElementTypesGroup from "Features/elementTypes/hooks/useSelectedElementTypesGroup";
import useSelectedElementTypeFromMeasurements from "Features/measurements/useSelectedElementTypeFromMeasurements";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";

export default function useTempElementType(scene) {
  const dispatch = useDispatch();

  const name = useSelector((s) => s.elementTypor.name);
  const drawingShape = useSelector((s) => s.elementTypor.drawingShape);
  const color = useSelector((s) => s.elementTypor.color);
  const num = useSelector((s) => s.elementTypor.num);
  const dim1 = useSelector((s) => s.elementTypor.dim1);
  const dim2 = useSelector((s) => s.elementTypor.dim2);
  const height = useSelector((s) => s.elementTypor.height);
  const style = useSelector((s) => s.elementTypor.style);
  const res = useSelector((s) => s.elementTypor.res);
  const fileUrl = useSelector((s) => s.elementTypor.fileUrl);

  const sceneModule = useSceneModule();

  const group = useSelectedElementTypesGroup(scene, {
    inEditMeasurements: sceneModule === "EDIT_MEASUREMENTS",
  });
  const selectedTypeInMeasurements =
    useSelectedElementTypeFromMeasurements(scene);
  const selectedType = useSelectedElementType(scene);

  const selection =
    sceneModule === "EDIT_MEASUREMENTS"
      ? selectedTypeInMeasurements
      : selectedType;

  // init

  useEffect(() => {
    if (selection?.num) {
      dispatch(setNum(selection.num + ".0.0.1"));
    }
  }, [selection?.id]);

  return {
    name,
    drawingShape,
    color,
    num,
    dim1,
    dim2,
    height,
    style,
    isTitle: ["title1", "title2"].includes(style) || !drawingShape,
    group: group?.name,
    res,
    fileUrl,
  };
}
