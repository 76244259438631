import {useSelector} from "react-redux";

import {Typography, Paper} from "@mui/material";

export default function ListItemPhase({phase, onClick}) {
  // data

  const selectedPhaseIds = useSelector((s) => s.phases.selectedPhaseIds);

  // helper - selected

  const selected = selectedPhaseIds.includes(phase.id) || selectedPhaseIds.length === 0;

  // helpers - style

  const bgcolor = selected ? "common.black" : "common.white";
  const color = selected ? "common.white" : "common.black";

  // handlers

  function handleClick() {
    onClick(phase)
  }

  return (
    <Paper
      sx={{
        px: 1,
        py: 0.5,
        cursor: "pointer",
        bgcolor,
      }}
      elevation={selected ? 12 : 1}
      onClick={handleClick}
    >
      <Typography sx={{
          fontWeight: selected ? "bold" : "normal",
          fontSize: 13,
          color,
        }}
        noWrap
      >
        {phase.name}
      </Typography>
    </Paper>
  );
}
