import {useDispatch} from "react-redux";

import {
  Paper,
  LinearProgress,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import {Close} from "@mui/icons-material";

import {setMode} from "Features/viewer3D/viewer3DSlice";
import CheckboxIsSnapping from "Features/measurements/components/CheckboxIsSnapping";

export default function CardIsAddingMarker({editorPdf}) {
  const dispatch = useDispatch();
  // string

  const newS = "New marker";

  // handlers

  function handleCloseClick() {
    dispatch(setMode("DEFAULT"));
    editorPdf?.activePanTool();
    editorPdf?.sceneEditor.disablePointer();
    editorPdf?.sceneEditor.hideTempMarker();
    editorPdf?.logPagePositionOnClick();
  }

  return (
    <Paper elevation={12} sx={{width: 150}}>
      <LinearProgress color="primary" sx={{width: 1}} />
      <Box sx={{p: 1}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" sx={{fontWeight: "bold"}}>
            {newS}
          </Typography>
          <IconButton size="small" onClick={handleCloseClick}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ml: 1}}>
          <CheckboxIsSnapping editorPdf={editorPdf} />
        </Box>
      </Box>
    </Paper>
  );
}
