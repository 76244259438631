import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Typography, Menu, MenuItem, Chip, Box} from "@mui/material";
import {ArrowDropDown as Down, Close} from "@mui/icons-material";

import {setFilterZoneId} from "../measurementsSlice";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "../useMeasurementsScopeMode";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function SelectorFilterZone({scene}) {
  const dispatch = useDispatch();

  // data

  const mode = useMeasurementsScopeMode(scene);
  const [fms] = useFilteredSceneMeasurements(scene, mode, true, false);
  const zones = useZonesByScene(scene);
  const filterZoneId = useSelector((s) => s.measurements.filterZoneId);
  const sceneModuleIconMap = useSceneModuleIconMap();
  const sceneModuleNameMap = useSceneModuleNameMap();

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers - filters

  const msIds = [...new Set(fms.map((m) => m.zoneId))];
  let filters = zones.filter((s) => msIds.includes(s.id));
  if (fms.filter((m) => !m.zoneId).length > 0)
    filters.push({id: "undefined", name: "- ? -"});

  // helper - active filter

  const filterName = sceneModuleNameMap["ZONES"];
  const filterIcon = sceneModuleIconMap["ZONES"];
  const activeFilter = filters.find((f) => f.id === filterZoneId);
  const label = activeFilter ? activeFilter.name : filterName;
  const isActive = Boolean(filterZoneId);

  // handlers

  function handleDeleteClick(e) {
    if (!isActive) {
      setAnchorEl(e.currentTarget);
    } else {
      dispatch(setFilterZoneId(null));
    }
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(filterId) {
    dispatch(setFilterZoneId(filterId));
    setAnchorEl(null);
  }

  return (
    <Box display={zones.length > 0 ? "flex" : "none"}>
      <Chip
        label={label}
        deleteIcon={isActive ? <Close /> : <Down />}
        onDelete={handleDeleteClick}
        size="small"
        icon={filterIcon}
        variant={isActive ? "outlined" : "filled"}
      />
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {filters.map((filter) => {
          const selected = filter.id === activeFilter?.id;
          return (
            <MenuItem
              key={filter.id}
              onClick={() => handleChange(filter?.id)}
              selected={selected}
            >
              <Typography variant="body2">{filter.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
