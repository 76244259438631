/*
 * used to extract geometry from 3D mesh
 */

import {Vector2, ShapeUtils} from "three";
//import concaveman from "concaveman";

export default class GeomEngine {
  // constructor({editor}) {
  //   this.editor = editor;
  // }

  /*
   * utilities
   */

  areaPolygon(points) {
    const points2D = points.map((p) => new Vector2(p[0], p[1]));
    const areaContour = ShapeUtils.area(points2D);
    let area = Math.abs(areaContour);
    area = parseFloat(area.toFixed(4));
    return area;
  }

  getVectorFrom2Points(a, b) {
    const x = b[0] - a[0];
    const y = b[1] - a[1];
    return new Vector2(x, y);
  }

  center(A, B) {
    return new Vector2((A.x + B.x) / 2, (A.y + B.y) / 2);
  }

  round(value) {
    if (!value) return null;
    return parseFloat(value.toFixed(4));
  }
  hashPoint(point) {
    return point[0].toFixed(4) + ";" + point[1].toFixed(4);
  }
  pointFromHash(hash) {
    const [x, y] = hash.split(";");
    return [parseFloat(x), parseFloat(y)];
  }

  mergePoints(points) {
    const roundedPoints = points.map((p) => [
      this.round(p[0]),
      this.round(p[1]),
    ]);
    const hashes = roundedPoints.map((p) => this.hashPoint(p));
    const cleanHashes = [...new Set(hashes)];
    return cleanHashes.map((h) => this.pointFromHash(h));
  }

  removeUselessPointsFromPolygon(polygon) {
    const uselessPointsIndex = [];
    const path = [...polygon];
    path.pop();

    const limit = 0.99;
    const length = path.length;
    path.forEach((point, index) => {
      const prevPoint = index === 0 ? path[length - 1] : path[index - 1];
      const nextPoint = index === length - 1 ? path[0] : path[index + 1];
      const pcV = this.getVectorFrom2Points(prevPoint, point);
      const cnV = this.getVectorFrom2Points(point, nextPoint);
      const dot = pcV.normalize().dot(cnV.normalize());
      if (dot > limit) uselessPointsIndex.push(index);
    });

    const dst = path.filter((point, index) => {
      return !uselessPointsIndex.includes(index);
    });

    const result = [...dst, dst[0]];

    return result;
  }

  getPolygonLineProps(path) {
    if (path.length !== 5) return null;

    const A = new Vector2(path[0][0], path[0][1]);
    const B = new Vector2(path[1][0], path[1][1]);
    const C = new Vector2(path[2][0], path[2][1]);
    const D = new Vector2(path[3][0], path[3][1]);

    const ab = A.distanceTo(B);
    const bc = B.distanceTo(C);
    const cd = C.distanceTo(D);
    const da = D.distanceTo(A);

    const dimMin = ab < bc ? ab : bc;
    const dimMax = ab < bc ? bc : ab;

    const ratio = (dimMax - dimMin) / dimMax;
    if (dimMin > 1.5) return null; // not length shape

    let L1, L2;
    L1 = dimMax === ab ? this.center(A, D) : this.center(A, B);
    L2 = dimMax === ab ? this.center(B, C) : this.center(C, D);

    const thickness = ab < bc ? (ab + cd) / 2 : (bc + da) / 2;
    const length = L1.distanceTo(L2);

    return {
      a: [L1.x, L1.y],
      b: [L2.x, L2.y],
      thickness,
      length,
    };
  }

  getConcaveHull(points2D) {
    //const polygon = concaveman(points2D, 20);
    const polygon = [];
    return polygon;
  }

  /*
   * converter
   */

  elementPointsToMeasurementQuantities(points, pointsZ) {
    // height
    const zInf = Math.min(...pointsZ);
    const zSup = Math.max(...pointsZ);
    const height = zSup - zInf;

    // 2D polygon

    const points2D = points.map((p) => [p.x, p.z]);

    const mergedPoints = this.mergePoints(points2D);

    const _polygon = this.getConcaveHull(mergedPoints);

    const polygon = this.removeUselessPointsFromPolygon(_polygon);

    // line props

    const lineProps = this.getPolygonLineProps(polygon);

    // polygonArea

    const polygonArea = this.areaPolygon(polygon);

    // quantities

    let length, area, volume, perimeter, drawingShape, isLine;

    if (lineProps?.length) {
      length = lineProps.length;
      area = length * height;
      volume = polygonArea * height;
      drawingShape = "POLYGON";
      isLine = true;
    } else {
      area = polygonArea;
      volume = polygonArea * height;
      drawingShape = "POLYGON";
      isLine = false;
    }

    return {
      zInf,
      zSup,
      length: this.round(length),
      area: this.round(area),
      volume: this.round(volume),
      path: polygon,
      drawingShape,
      lineProps,
      isLine,
    };
  }
}
