// import {useSelector} from "react-redux";
import {useState, useEffect} from "react";

// import useSelectedPackage from "../useSelectedPackage";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

export default function DialogDuplicatePackage({
  open,
  onClose,
  model,
  caplaEditor,
}) {
  // strings

  const titleS = "Dupliquer le calque de repérage";
  const newNameLabel = "Nom";

  const duplicateS = "Dupliquer";

  // state - name

  const [name, setName] = useState("");
  useEffect(() => {
    if (model?.id) {
      setName(model.name);
    }
  }, [model?.id]);

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  async function handleDuplicate() {
    setLoading(true);
    if (name?.length > 0) {
      await caplaEditor?.editor3d?.loader.createMeasurementsModel({
        name,
        copyFrom: model,
      });
      caplaEditor?.measDataManager.createOrUpdateModelInManager(model);
    }
    setLoading(false);
    onClose();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent>
        <Box sx={{py: 1}}>
          <TextField
            sx={{width: 400}}
            label={<Typography variant="body2">{newNameLabel}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleDuplicate}>
          {duplicateS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
