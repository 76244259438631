import React from "react";

import {ToggleButton, ToggleButtonGroup, Typography, Box} from "@mui/material";

export default function ToggleNameFromRawType({nameFrom, onChange}) {
  // strings

  const subjectS = "Sujet";
  const authorS = "Auteur";

  // handler

  function handleChange(e, value) {
    if (value !== null) {
      onChange(value);
    }
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <ToggleButtonGroup
        size="small"
        value={nameFrom}
        onChange={handleChange}
        exclusive
        sx={{textTransform: "none"}}
      >
        <ToggleButton value="subject">
          <Typography variant="body2" sx={{textTransform: "none"}}>
            {subjectS}
          </Typography>
        </ToggleButton>
        <ToggleButton value="author">
          <Typography variant="body2" sx={{textTransform: "none"}}>
            {authorS}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
