// TODO description

import { segmentPolylineIntersection } from "../utils/intersections";
import splitSegments from "../utils/split";

export default function deleteSegments({measurements, data}) {
  const {initialSegments: deleteProxies} = data;
  const polylinesById = {};
  for (const item of measurements.filter(
    (m) => m.drawingShape === "POLYLINE"
  )) {
    polylinesById[item.id] = item;
  }
  const polylinesToCutFrom = {};
  const polylinesToDelete = new Set();
  for (const delSegment of deleteProxies) {
    if (Array.isArray(delSegment.path3D)) {
      const segments = [
        [delSegment.path3D[0], delSegment.path3D[3]],
        [delSegment.path3D[1], delSegment.path3D[2]]
      ];
      for (const polyline of Object.values(polylinesById)) {
        if (
          polyline.measModelId === delSegment.measModelId && 
          polyline.zoneId === delSegment.zoneId
        ) {
          let idxToCutFrom = false;
          for (const segment of segments) {
            const cut = segmentPolylineIntersection(
              segment,
              polyline.centerLine,
            );
            if (cut !== false) {
              idxToCutFrom = cut;
              break;
            }
          }
          if (idxToCutFrom !== false) {
            polylinesToDelete.add(polyline.id);
            if (polyline.id in polylinesToCutFrom) {
              polylinesToCutFrom[polyline.id].push(idxToCutFrom);
            } else {
              polylinesToCutFrom[polyline.id] = [idxToCutFrom];
            }
          }
        }
      }
    }
  }
  const polylinesToAdd = [];
  for (const [k, v] of Object.entries(polylinesToCutFrom)) {
    const polyline = polylinesById[k];
    const cuts = v.sort((a, b) => a - b);
    if (cuts.length > 0) {
      polylinesToAdd.push(...splitSegments(polyline, cuts));
    }
  }
  polylinesToDelete.forEach((id) => {
    delete polylinesById[id];
  })
  return measurements
  .filter((m) => m.drawingShape !== "POLYLINE").map((m) => {
    return {...m};
  }).concat(Object.values(polylinesById))
  .concat(polylinesToAdd);
}