import React, {useState} from "react";

import ButtonTool from "./ButtonTool";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import DialogMobileSelectZones from "./DialogMobileSelectZones";

export default function ShortcutMobileZones({editor, scene, viewer}) {
  // data

  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  // state

  const [open, setOpen] = useState(false);

  // helpers

  const label = nameMap["ZONES"];
  const icon = iconMap["ZONES"];

  // handlers

  function handleClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <ButtonTool icon={icon} label={label} onClick={handleClick} />
      <DialogMobileSelectZones
        editor={editor}
        scene={scene}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}
