import React, {useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {setOpenBuildWithExcelDialog} from "Features/navigation/navigationSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import CardEntityTypes from "./CardEntityTypes";
import CardFileSelector from "Features/files/components/CardFileSelector";
import createExcelWorkbookFromFile from "Features/excel/utils/createExcelWorkbookFromFile";
import workbookToEntityTypes from "Features/excel/utils/workbookToEntities";
import ButtonDownloadConfigExcel from "./ButtonDownloadConfigExcel";

export default function DialogBuildWithExcel() {
  const dispatch = useDispatch();

  // string

  const title = "Configuration avec Excel";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const selectExcelS = "Choisir un fichier Excel de paramétrage";

  // data

  const open = useSelector((s) => s.navigation.openBuildWithExcelDialog);

  // state

  const [file, setFile] = useState(null);
  const [entityTypes, setEntityTypes] = useState([]);

  // handlers

  function handleClose() {
    dispatch(setOpenBuildWithExcelDialog(false));
  }

  async function handleFileChange(file) {
    setFile(file);
    const workbook = await createExcelWorkbookFromFile({file});
    const types = workbookToEntityTypes(workbook);
    setEntityTypes(types);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <CardFileSelector
            file={file}
            onFileChange={handleFileChange}
            buttonName={selectExcelS}
            accept=".xlsx"
          />
        </Box>
        <Box>
          <ButtonDownloadConfigExcel />
        </Box>
        <Box sx={{width: 1, mt: 2}}>
          <CardEntityTypes types={entityTypes} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{cancelS}</Button>
        <Button>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
