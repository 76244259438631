import {getModelTypeFromFile} from "../../../utils/miscUtils";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function CreateModelButton({
  variant,
  buttonName,
  editor3d,
  onCreate,
}) {
  // handler

  function handleFileChange(file) {
    if (file) {
      const type = getModelTypeFromFile(file);
      editor3d?.loader.loadFile({file, type});
    }
    onCreate();
  }
  return (
    <FileSelectorButton
      variant={variant}
      buttonName={buttonName}
      onFileChange={handleFileChange}
    />
  );
}
