import useIssuesetsInScope from "./useIssuesetsInScope";
import {useSelector} from "react-redux";
import useScene from "Features/scenes/useScene";
import {useParams} from "react-router-dom";
import useIssuesByScene from "./useIssuesByScene";
import useSceneModule from "Features/navigation/useSceneModule";

export default function useFilteredIssues(scene, viewer) {
  // data

  const [sets, setIds] = useIssuesetsInScope();
  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );
  const filters = useSelector((s) => s.issues.filtersOpenedClosed);
  const sceneModule = useSceneModule();
  const selectedIssueIdUi = useSelector((s) => s.ui.selectedIssueId);
  const selectedIssueIdShortcut = useSelector(
    (s) => s.issues.selectedIssueIdShortcut
  );

  // init

  let allIssues = useIssuesByScene(scene?.id);

  let issues = [...allIssues];

  // home || viewer

  if (sceneModule === "HOME" || viewer) {
    issues = issues.filter((i) => i.id === selectedIssueIdUi);
  }

  // scope

  if (sceneModule !== "HOME" && !viewer) {
    issues = issues.filter((i) => i.listing === selectedIssuesListingId);
  }

  // filter opened

  if (sceneModule !== "HOME" && !viewer) {
    issues = issues.filter(
      (i) =>
        (filters.includes("OPENED") && i.isOpened) ||
        (filters.includes("CLOSED") && !i.isOpened)
    );
  }

  // selected in shortcut

  if (selectedIssueIdShortcut) {
    issues = allIssues.filter((issue) => issue.id === selectedIssueIdShortcut);
  }

  // output

  const ids = issues.map((issue) => issue.id);
  const idsHash = ids.join("-");

  return [issues, ids, idsHash];
}
