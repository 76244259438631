import {useState} from "react";
import {Box} from "@mui/material";

import {useDispatch} from "react-redux";

import {setSelectedArticleId} from "../articlesSlice";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useSelectedArticle from "../hooks/useSelectedArticle";
import useDeleteArticle from "../hooks/useDeleteArticle";
import useUpdateArticlesGroup from "../hooks/useUpdateArticlesGroup";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

export default function DialogDeleteArticle({scene, open, onClose}) {
  const dispatch = useDispatch();
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedArticle = useSelectedArticle();
  const selectedArticlesGroup = useSelectedArticlesGroup();

  const deleteArticle = useDeleteArticle();
  const updateArticlesGroup = useUpdateArticlesGroup();

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    //
    const newSortedArticles = selectedArticlesGroup?.sortedArticles.filter(
      (a) => a.clientId !== selectedArticle.clientId
    );
    const groupUpdates = {
      id: selectedArticlesGroup.id,
      sortedArticles: newSortedArticles,
      sceneId: scene.id,
    };
    //
    setLoading(true);
    await updateArticlesGroup(groupUpdates);
    await deleteArticle(selectedArticle);
    dispatch(setSelectedArticleId(null));
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="article"
      loading={loading}
    />
  );
}
