import {nanoid} from "@reduxjs/toolkit";

export default function excelArticlesToArticles(
  excelArticles,
  articlesMap, // used to repair items to update (clientId)
  defaultValues
) {
  const articlesGroupId = defaultValues?.articlesGroupId;

  return excelArticles.map((article) => {
    const cleanArticle = {...article};

    // articlesGroupId

    if (!cleanArticle.articlesGroupId) {
      cleanArticle.articlesGroupId = articlesGroupId;
    }

    // clientId

    if (!cleanArticle.id) {
      cleanArticle.clientId = nanoid(); // we create a clientId if the article doesn't exit remotely.
    } else {
      const existingArticle = articlesMap[cleanArticle.id];
      if (existingArticle && !existingArticle.clientId) {
        cleanArticle.clientId = nanoid();
      } else {
        cleanArticle.clientId = existingArticle?.clientId;
      }
    }

    // num

    let num = article.num;
    if (typeof num === "number") {
      cleanArticle.num = num.toString();
    }

    // toDelete

    cleanArticle.toDelete = article.toDelete;

    // isAdded

    if (cleanArticle.isAdded === "x") {
      cleanArticle.isAdded = true;
    }

    return cleanArticle;
  });
}
