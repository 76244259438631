import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setApplyFilters} from "Features/measurements/measurementsSlice";
import {setFilteredMeasurementsViewMode} from "Features/measurements/measurementsSlice";

import {Box, ToggleButton, Tooltip} from "@mui/material";
import {FilterAltOff} from "@mui/icons-material";

export default function ButtonApplyFilters({editor}) {
  const dispatch = useDispatch();

  // strings

  // const applyFiltersOn = "Disable filters";
  // const applyFiltersOff = "Enable filters";
  const applyFiltersOff = "Afficher les éléments écartés du filtre";
  const applyFiltersOn = "Masquer les éléments écartés du filtre";

  // data

  const applyFilters = useSelector((s) => s.measurements.applyFilters);

  const inOut = useSelector((s) => s.measurements.filteredMeasurementsViewMode);

  // helpers

  const title = applyFilters ? applyFiltersOn : applyFiltersOff;

  // handlers

  function handleChange() {
    dispatch(setApplyFilters(!applyFilters));
  }
  const handleToggleInOut = () => {
    if (inOut === "IN_AND_OUT") dispatch(setFilteredMeasurementsViewMode("IN"));
    else dispatch(setFilteredMeasurementsViewMode("IN_AND_OUT"));
  };

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        color: "divider",
      }}
    >
      <ToggleButton
        selected={inOut === "IN_AND_OUT"}
        onChange={handleToggleInOut}
        value="APPLY_FILTERS"
        sx={{
          width: 30,
          height: 30,
          "&.Mui-selected": {
            bgcolor: "common.darkGrey",
            "&:hover": {
              bgcolor: "common.darkGrey",
            },
          },
        }}
      >
        <Tooltip title={title}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "common.white",
            }}
          >
            <FilterAltOff fontSize="small" color="inherit" />
          </Box>
        </Tooltip>
      </ToggleButton>
    </Box>
  );
}
