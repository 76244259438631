import {useDispatch} from "react-redux";

import {Box, Button} from "@mui/material";

import {setSelectedMeasurementIds} from "../measurementsSlice";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "../useMeasurementsScopeMode";

export default function ButtonSelectMeasurementsInDatagrid({scene}) {
  const dispatch = useDispatch();
  // strings

  const selectAllS = "Tout sélectionner";

  // data

  const mode = useMeasurementsScopeMode(scene);

  const [ms, msIds] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false
  );

  // handlers

  function handleClick() {
    dispatch(setSelectedMeasurementIds(msIds));
    // TODO select all in PDF viewer and/or 3D viewer if opened
  }

  return (
    <Box sx={{color: "text.secondary"}}>
      <Button size="small" onClick={handleClick} color="inherit">
        {selectAllS}
      </Button>
    </Box>
  );
}
