import {useState} from "react";

import useSelectedZone from "../hooks/useSelectedZone";

import {Box, IconButton} from "@mui/material";
import {Rectangle, AutoFixHigh as Compute, Delete} from "@mui/icons-material";

import cv from "Features/images/js/serviceCV";

export default function SectionPatternRecognition({caplaEditor, scene}) {
  // data

  const selectedZone = useSelectedZone(scene);

  // state

  const [loading, setLoading] = useState(false);

  // handlers
  function handleAddRectangle() {
    caplaEditor.zonesEditor.addPatternRect();
  }

  async function handleCompute() {
    if (loading) return;
    setLoading(true);
    const imageData = await caplaEditor.zonesEditor.getImageDataFromZone(
      selectedZone
    );
    const patternData = caplaEditor.zonesEditor.getPatternRectImageData();

    console.log("TEST 1");
    await cv.load();
    console.log("TEST 2");

    const payload = {imageData, patternData};
    const matches = await cv.findPattern(payload);
    console.log("matches", matches);
    const data3 = matches.data.payload;
    const canvas = document.createElement("canvas");
    canvas.width = data3.width;
    canvas.height = data3.height;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(data3, 0, 0);
    const dataURL = canvas.toDataURL("image/png");
    const imageRecognition = {
      url: dataURL,
      width: data3.width,
      height: data3.height,
    };
    caplaEditor.zonesEditor.setImageRecognition(imageRecognition);
    setLoading(false);
  }

  function handleDelete() {
    setLoading(false);
    caplaEditor.zonesEditor.downloadPattern();
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <IconButton onClick={handleAddRectangle}>
        <Rectangle />
      </IconButton>
      <IconButton onClick={handleCompute} disabled={loading}>
        <Compute />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <Delete />
      </IconButton>
    </Box>
  );
}
