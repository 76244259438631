import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {addSubArticles} from "../articlesSlice";
import getNomenclatureArticleSubArticles from "../utils/getNomenclatureArticleSubArticles";

import transferElementTypeArticleQtiesToChildren from "../utils/transferElementTypeArticleQtiesToChildren";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function useAutoUpdateSubArticlesMap({
  selectedArticlesGroupId,
  elementTypesGroups,
  measurements,
  sectors,
}) {
  const dispatch = useDispatch();

  // dependencies

  const counter =
    measurements?.length +
    elementTypesGroups?.reduce((acc, group) => {
      return acc + group?.elementTypes?.length;
    }, 0);

  console.log("counterSubArticles", counter);

  // data

  const lastUpdateAt = useSelector((s) => s.articles.lastUpdateAt);

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  // data transfor

  elementTypesGroups = elementTypesGroups.map((group) => {
    return {
      ...group,
      elementTypes: transferElementTypeArticleQtiesToChildren(
        group.elementTypes
      ),
    };
  });

  // helpers

  const articles = Object.values(articlesMap)
    .filter((article) => article.articlesGroupId === selectedArticlesGroupId)
    .map((article) => ({...article, ...(article?.updates ?? {})}));

  //

  useEffect(() => {
    const subArticlesMap = {};
    let shouldDispatch = false;
    articles.forEach((article) => {
      if (article.isNomenclature) {
        const subArticles = getNomenclatureArticleSubArticles(
          article,
          elementTypesGroups,
          measurements,
          {fromMeasurements: true, sectors}
        );
        console.log("subArticlesAAA", subArticles);
        //
        // const canDispatch = subArticles?.length > 0 &&
        // article.types &&
        // Object.keys(article.types).length > 0 // why ?
        const canDispatch = subArticles?.length > 0;
        if (canDispatch) {
          shouldDispatch = true;
          subArticlesMap[article.id] = subArticles.map((subArticle, index) => ({
            ...subArticle,
            articleId: article.id, // used for the selection
            index,
            isSubArticle: true,
          })); // the index is used to order from map conversion
        }
      }
    });
    console.log("[EFFECT] dispatch addSubArticles", subArticlesMap);
    if (shouldDispatch) dispatch(addSubArticles(subArticlesMap));
  }, [lastUpdateAt, articles?.length, counter]); // we add the elementTypesGroups.length
}
