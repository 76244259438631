import {useSelector} from "react-redux";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import {getFileSizeString} from "Features/files/utils";

export default function FieldZoneImageSize({zone}) {
  // strings

  const sizeS = "Size";

  // template

  const template = {
    key: "kvtZoneImageSize",
    fields: [
      {
        key: "imageSize",
        name: sizeS,
        type: "text",
      },
    ],
  };

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const imageModel = models.find((m) => m.id === zone.imageModelId);

  // helper

  const fileSize = imageModel?.fileSize;
  const fileSizeString = getFileSizeString(fileSize);

  // helpers - item

  const item = {imageSize: fileSizeString};

  return <KeyValueForm item={item} template={template} />;
}
