import {ReactEditor} from "slate-react";
import {Transforms, Node, Editor, Path} from "slate";

export function withActions(editor) {
  const {isInline, isVoid} = editor;
  editor.isVoid = (element) => {
    return element.type === "action" ? true : isVoid(element);
  };
  return editor;
}

export function insertAction(editor, description) {
  const {selection} = editor;
  ReactEditor.focus(editor);

  const text = {text: ""};
  const paragraph = {type: "paragraph", children: [{text: ""}]};
  const action = [{type: "action", description, children: [text]}, paragraph];

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );
    console.log("parentnode", parentNode);
    if (editor.isVoid(parentNode) || Node.string(parentNode)?.length) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, action, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, {at: parentPath});
      Transforms.insertNodes(editor, action, {at: parentPath, select: true});
    }
  } else {
    console.log("insert at the end");
    Transforms.insertNodes(editor, action, {select: true});
  }
}
