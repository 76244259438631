import React from "react";

import {TextField, Box, Typography} from "@mui/material";
import SelectOptions from "Components/SelectOptions";

export default function FieldElementTypeUnitFast({unit, onChange}) {
  // strings

  const label = "Unit";

  // helpers

  const option = unit;
  const options = ["UN", "ML", "M2", "M3"];

  return (
    <Box sx={{width: 1, display: "flex", mt: -0.5}}>
      <SelectOptions
        option={option}
        options={options}
        onChange={onChange}
        label={label}
        minWidth={100}
      />
    </Box>
  );
}
