import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getSubjectTypeMapFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(3, rowCount - 2).values();

  let valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    valuesA.push(rowValues);
  }

  const fields = [
    "subjectName",
    "subjectCount",
    "subjectDrawingShape",
    "subjectDrawingColor",
    "subjectDotted",
    "id",
    "group",
    "num",
    "name",
    "code",
    "drawingShape",
    "color",
    "dotted",
    "isVoid",
    "unit",
    "dim1",
    "dim2",
    "height",
  ];

  const loadedSubjectType = [];

  valuesA.map((valueA) => {
    const subjectType = {};
    valueA.forEach((value, index) => (subjectType[fields[index]] = value));
    loadedSubjectType.push(subjectType);
  });
  return loadedSubjectType;
}
