import {useState} from "react";

import useSelectedPdfModel from "../useSelectedPdfModel";

import {IconButton, Button} from "@mui/material";
import {Difference} from "@mui/icons-material";

import {urlToBlob, saveBlob} from "Features/files/utils";

import cv from "Features/images/js/serviceCV";

export default function ButtonComputeDifferenceNewOldPdf({
  caplaEditor,
  onDiffComputed,
  disabled,
}) {
  // strings

  const computeS = "Calculer les différences";

  // data

  const pdfNew = useSelectedPdfModel();

  // state

  const [loading, setLoading] = useState(false);

  // handler

  async function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    //
    const {imageData: data1} = await caplaEditor.editorPdf.getOldPdfImage();
    const {
      imageData: data2,
      x,
      y,
      width,
      height,
      pageNumber,
    } = await caplaEditor.editorPdf.loadViewportImageAsyncWithoutAnnotations();

    if (!data1 || !data2) {
      setLoading(false);
      console.log("error : no old / new zone");
      return;
    }
    console.log("imageDiff 1", data1, data2);
    await cv.load();
    const payload = {imageData1: data1, imageData2: data2};
    const differences = await cv.highlightDifferences(payload);
    const data3 = differences.data.payload;
    const canvas = document.createElement("canvas");
    canvas.width = data3.width;
    canvas.height = data3.height;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(data3, 0, 0);
    const dataURL = canvas.toDataURL("image/png");
    const imageDiff = {url: dataURL, width: data3.width, height: data3.height};
    //
    caplaEditor.editorPdf.annotationsManager.updateDifferenceAnnotation({
      imageUrl: dataURL,
      pageNumber: pageNumber,
      width,
      height,
      x,
      y,
    });
    //
    if (onDiffComputed) onDiffComputed(imageDiff);
    //
    setLoading(false);
  }

  return (
    <Button
      disabled={disabled || loading}
      startIcon={<Difference />}
      onClick={handleClick}
    >
      {computeS}
    </Button>
  );
}
