import fillWorksheetWithRessources from "./fillWorksheetWithRessources";

export default function addRessourceWorksheetToWorkbook(
  workbook,
  worksheetTemplate,
  name,
  data,
  buildingRessources,
  withDescription,
  withRefQty,
  withPrices,
  ressourcesPU,
) {
  const worksheet = workbook.addWorksheet(name.slice(0, 31));
  if (worksheetTemplate?.model) worksheet.model = worksheetTemplate.model;
  worksheet.name = name.slice(0, 31);

  worksheet.getRow(1).getCell(1).value = name ? name : "-?-";

  let hasPU;
  if (ressourcesPU && ressourcesPU[name])
    hasPU = true;

  let quantityIdx = 4;
  let buildingIdx = 6;

  if (withRefQty) {
    buildingIdx += 1;
    quantityIdx += 1;
    worksheet.getRow(2).getCell(4).value = "Qté Réf";
    worksheet.getRow(2).getCell(5).value = "Quantité";
    worksheet.getRow(2).getCell(5).fill = {
      type: "pattern", pattern: "solid", fgColor: {argb: "D9D9D9"},
    };
  }

  if (withPrices || hasPU) {
    worksheet.getRow(2).getCell(quantityIdx + 1).value = "P.U.";
    worksheet.getRow(2).getCell(quantityIdx + 1).fill = {
      type: "pattern", pattern: "solid", fgColor: {argb: "D9D9D9"},
    };
    worksheet.getRow(2).getCell(quantityIdx + 2).value = "P.T.";
    worksheet.getRow(2).getCell(quantityIdx + 2).fill = {
      type: "pattern", pattern: "solid", fgColor: {argb: "D9D9D9"},
    };
    quantityIdx += 2;
    buildingIdx += 2;
  }

  if (withDescription) {
    buildingIdx += 1;
    worksheet.getRow(2).getCell(quantityIdx + 1).value = "Description";
    worksheet.getRow(2).getCell(quantityIdx + 1).fill = {
      type: "pattern", pattern: "solid", fgColor: {argb: "D9D9D9"},
    };
    worksheet.columns[quantityIdx].width = 40;
    quantityIdx += 1;
  }

  let maxCol = 0;
  const totals = {};
  const bySectors = {};
  let sectorIdx = 0;
  let startIdx = buildingIdx;
  for (const building of Object.keys(buildingRessources).sort((a, b) => a.localeCompare(b))) {
    const total = [];
    worksheet.getRow(1).getCell(buildingIdx + sectorIdx).value = building;
    worksheet.getRow(1).getCell(buildingIdx + sectorIdx).font = {bold: true};
    for (const sector of Object.values(buildingRessources[building]).sort((a, b) => a.z - b .z)) {
      worksheet.getRow(2).getCell(buildingIdx + sectorIdx).value = sector.name;
      total.push(buildingIdx + sectorIdx);
      Object.entries(sector).forEach(([k, v]) => {
        if (!["name", "z"].includes(k)) {
          if (buildingIdx + sectorIdx > maxCol) maxCol = buildingIdx + sectorIdx;
          if (k in bySectors) {
            bySectors[k][buildingIdx + sectorIdx] = v;
          } else {
            bySectors[k] = {[buildingIdx + sectorIdx] : v};
          }
        }
      });
      sectorIdx += 1;
    }
    worksheet.getRow(2).getCell(buildingIdx + sectorIdx).value = "Total";
    worksheet.getRow(2).getCell(buildingIdx + sectorIdx).font = {bold: true};
    totals[buildingIdx + sectorIdx] = total;
    worksheet.mergeCells(1, startIdx, 1, buildingIdx + sectorIdx);
    buildingIdx += 1;
    startIdx = buildingIdx + sectorIdx;
  }

  data.forEach((d) => {
    const byBuildings = bySectors[d.group + d.num];
    if (byBuildings) {
      Object.entries(totals).forEach(([k, v]) => {
        const w = v.map((x) => byBuildings[x] ? byBuildings[x] : 0).reduce((a, b) => a + b);
        if (w > 0) {
          byBuildings[k] = w;
        }
      });
      d.byBuildings = byBuildings;
    }
  });

  fillWorksheetWithRessources({
    worksheet,
    ressources: data,
    rowStartIndex: 3,
    withRefQty,
    withPrices,
    withDescription,
    areTotals: Object.keys(totals),
    maxCol,
    ressourcesPU,
    hasPU,
  });

  worksheet.mergeCells(1, 0, 1, quantityIdx);

  return worksheet;
}
