import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Paper, Box, Typography, Button} from "@mui/material";

import SectionRelationDetailTypes from "./SectionRelationDetailTypes";
import SectionRelationDetailEditedRessources from "./SectionRelationDetailEditedRessources";

import getRelationResMapFromRelations from "../utils/getRelationResMapFromRelations";
import getRelationsFromTypeIds from "../utils/getRelationsFromTypeIds";
import {setRelationsTypesRessources} from "../relationsSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import getItemsMapById from "Utils/getItemsMapById";
import useElementTypesGroupById from "Features/elementTypes/hooks/useElementTypesGroupById";
import useAccessToken from "Features/auth/useAccessToken";
import {updateElementTypesGroup} from "Features/elementTypes/elementTypesSlice";

export default function SectionEditRelations({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Composition";
  const description = `Une composition est une liste d'articles reliés par des formules à un type d'élément.
  Si plusieurs types sont sélectionnés, ils auront la même composition.`;

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const relations = useSelector((s) => s.relations.relationsTypesRessources); // {typeId-resId:{resId,typeId,mode,func}}
  const resIds = useSelector((s) => s.relations.selectedResIdsInRelations);
  const typeIds = useSelector((s) => s.relations.selectedTypeIdsInRelations);
  const getElementTypesGroup = useElementTypesGroupById();

  // state

  const [tempRelationResMap, setTempRelationResMap] = useState({}); // {resId:{formula,filter}}
  const [loading, setLoading] = useState(false);

  // helpers

  const elementTypesById = getItemsMapById(elementTypes);
  const hashRes = resIds ? [...resIds].sort().join() : "-";
  const hashType = typeIds ? [...typeIds].sort().join() : "-";

  // update tempResMap if resIds / typeIds change
  useEffect(() => {
    const initRelations = getRelationsFromTypeIds(relations, typeIds); // [{resId,typeId,func,..},]
    const relationResMap = getRelationResMapFromRelations(initRelations); // {resId:{formula,filter}} pour les relations initiales, avec filtres sur typesIds
    //
    const newTemp = {};
    if (resIds?.length > 0) {
      resIds.forEach((resId) => {
        // PROBLEM HERE tempRelationResMap overwrite result with new type
        if (tempRelationResMap[resId]) {
          newTemp[resId] = tempRelationResMap[resId];
        } else {
          newTemp[resId] = relationResMap[resId] ?? {formula: "", filter: ""};
        }
      });
      setTempRelationResMap(newTemp);
    } else {
      setTempRelationResMap({});
    }
  }, [hashRes]);

  useEffect(() => {
    const initRelations = getRelationsFromTypeIds(relations, typeIds); // [{resId,typeId,func,..},]
    const relationResMap = getRelationResMapFromRelations(initRelations); // {resId:{formula,filter}} pour les relations initiales, avec filtres sur typesIds
    //
    setTempRelationResMap(relationResMap);
  }, [hashType]);

  const [lastField, setLastField] = useState({
    resId: null,
    field: "formula",
    value: "",
  }); // last field being edited => other updates are managed with on blur.

  // handlers

  function handleLastChange({resId, field, value}) {
    setLastField({resId, field, value});
  }

  function handleRelationResMapChange(map) {
    setTempRelationResMap(map);
  }

  async function handleSave() {
    // compute last edited map : "{resId:{formula,filter}"
    console.log("tempRelationResMap", tempRelationResMap);
    const editedTypesByGroup = {};

    // update
    // typeId1 : 0 resId. => supprimer dans les relations d'origines, les relations du type typeId1

    // TO DELETE

    // TO UPDATE

    // TO ADD

    const editedRelations = {...relations};
    const sceneData = {ressources, elementTypes};
    const tempRelationResMapWithLastField = {...tempRelationResMap};

    setLoading(true);
    if (lastField.resId && lastField.field) {
      tempRelationResMapWithLastField[lastField.resId][lastField.field] =
        lastField.value;
    }

    // compute modified relations and typesToUpdate
    typeIds.forEach((typeId) => {
      const typeRes = [];
      const typeRelationsKeys = Object.keys(relations).filter((k) =>
        k.startsWith(typeId)
      );
      for (const key of typeRelationsKeys) delete editedRelations[key];
      Object.entries(tempRelationResMapWithLastField).forEach(
        ([resId, {formula, filter}]) => {
          const id = typeId + "-" + resId;
          let mode = "AUTO";
          let func;
          if (formula?.length > 0 || filter?.length > 0) {
            mode = "FORMUL";
            func = formula;
            if (filter.length > 0) func = func + ` {{${filter}}}`;
          }
          editedRelations[id] = {typeId, resId, mode};
          if (func) editedRelations[id]["func"] = func;
          typeRes.push(editedRelations[id]);
        }
      );
      const editedType = {...elementTypesById[typeId]};
      editedType.res = typeRes;
      if (editedType.groupId in editedTypesByGroup) {
        editedTypesByGroup[editedType.groupId].push(editedType);
      } else {
        editedTypesByGroup[editedType.groupId] = [editedType];
      }
      // Update measurements
      const modelIds = new Set();
      const measurementsToUpdate = [];
      caplaEditor?.measDataManager
        .getAllMeasurements()
        .filter((m) => m.elementTypeId === editedType.id)
        .forEach((m) => {
          measurementsToUpdate.push({
            ...m,
            res: editedType?.res
              ? editedType.res.map(({resId}) => ({resId, off: false}))
              : [],
          });
          modelIds.add(m.measurementsModelId);
        });
      caplaEditor?.measDataManager.updateMeasurements(measurementsToUpdate);
      for (const modelId of modelIds) {
        caplaEditor?.measDataManager.saveModificationsForModelById(
          modelId,
          sceneData
        );
      }
    });
    // Update types
    for (const [groupId, editedTypes] of Object.entries(editedTypesByGroup)) {
      const group = getElementTypesGroup(groupId);
      const editedTypesIds = new Set(editedTypes.map((t) => t.id));
      const otherTypes = group.elementTypes.filter(
        (t) => !editedTypesIds.has(t.id)
      );
      if (!group.fromScene) {
        const updatedGroup = {
          ...group,
          elementTypes: [...otherTypes, ...editedTypes],
        };
        await dispatch(
          updateElementTypesGroup({
            accessToken,
            elementTypesGroup: {...updatedGroup, sceneId: scene.id},
          })
        );
      }
    }
    // Update relations
    dispatch(setRelationsTypesRessources(editedRelations));
    setLoading(false);
  }

  function handleCancel() {
    const initRelations = getRelationsFromTypeIds(relations, typeIds);
    const relationResMap = getRelationResMapFromRelations(initRelations);
    const newTemp = {};
    if (resIds) {
      resIds.forEach((resId) => {
        newTemp[resId] = relationResMap[resId] ?? {formula: "", filter: ""};
      });
      setTempRelationResMap(newTemp);
    } else {
      setTempRelationResMap({});
    }
    setLoading(true);
  }

  return (
    <Paper
      elevation={6}
      sx={{display: "flex", flexDirection: "column", minWidth: 0}}
    >
      <Box sx={{p: 1}}>
        <Typography variant="body2">{title}</Typography>
      </Box>
      <Box sx={{p: 1, py: 2}}>
        <Typography
          sx={{fontSize: 12, whiteSpace: "pre-line"}}
          color="text.secondary"
        >
          {description}
        </Typography>
      </Box>

      <SectionRelationDetailTypes
        elementTypes={elementTypes}
        typeIds={typeIds}
      />
      <Box sx={{mt: 2, display: "flex"}}>
        <SectionRelationDetailEditedRessources
          ressources={ressources}
          relationResMap={tempRelationResMap}
          onRelationResMapChange={handleRelationResMapChange}
          onLastChange={handleLastChange}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 2,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{flex: 1}}>
          <Button fullWidth onClick={handleCancel}>
            {cancelS}
          </Button>
        </Box>
        <Box sx={{flex: 1}}>
          <Button fullWidth onClick={handleSave} disabled={loading}>
            {saveS}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
