import {useSelector, useDispatch} from "react-redux";

import {Box, Typography, Chip} from "@mui/material";
import {
  CenterFocusStrong as Focus,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import FormMeasurementOverview from "./FormMeasurementOverview";
import ButtonEditMeasurement from "./ButtonEditMeasurement";

import {
  setSelectedPdfModelId,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import {setJump} from "Features/pdf/pdfSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import {addZoneToBuffer, removeZoneFromBuffer} from "Features/zones/zonesSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function SectionMeasurementOverviewInSelectionPanel({
  measurement,
  scene,
  caplaEditor,
  viewer,
}) {
  const dispatch = useDispatch();
  // const mnger = caplaEditor?.editorPdf?.annotationsManager?.measurementsPdfManager;

  // strings

  const xSelectedMeasurementsS = " éléments sélectionnés";
  const focus3dS = "3D";
  const focusPdfS = "Plan PDF";
  const seeZoneS = " le fond de plan";

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const zones = useZonesByScene(scene);
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);

  // helpers

  const measurementsModel = models.find(
    (m) => m.id === measurement?.measurementsModelId
  );
  const elementType = elementTypes.find(
    (t) => t.id === measurement?.elementTypeId
  );

  const zone = zones.find((z) => z.id === measurement?.zoneId);
  const zoneModel = models.find((m) => m.id === zone?.imageModelId);

  // helper (patch : zoneModel.hidden = true by default in the viewer)

  let labelIsShow = zoneModel?.hidden;
  if (viewer) labelIsShow = !labelIsShow;

  // handlers

  function handleFocusPdf() {
    dispatch(
      setJump({
        page: measurement.drawingProps.pageNumber,
        measId: measurement.id,
      })
    );
    dispatch(setSelectedPdfModelId(measurementsModel.fromModel.modelId));
  }

  const handleFocus3d = () => {
    console.log("SELECTED MEASUREMENT UNDER FOCUS", measurement);
    caplaEditor.editor3d.sceneEditor.zoomOutMeasurement(measurement);
  };

  function handleSeeZone() {
    if (zoneModel) {
      const updatedModel = {...zoneModel, hidden: !zoneModel?.hidden};
      const options = {loadTexture: true};
      caplaEditor?.editor3d.loader.loadInitialModel(updatedModel, options);
      const entity = caplaEditor?.editor3d.getEntity(zoneModel.id);
      dispatch(updateModel({updatedModel, sync: false}));
      if (!zoneModel?.hidden) {
        entity?.show();
        dispatch(addZoneToBuffer(zone));
      } else {
        entity?.hide();
        if (!useBuffer) dispatch(removeZoneFromBuffer(zone));
      }
    } else {
      const message = "Pas de zone associée au repérage";
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt, isError: true}));
    }
  }

  return (
    <Box>
      {selectedMeasurementIds.length > 1 && (
        <Box
          sx={{
            p: 1,
            pl: 2,
            display: "flex",
            alignItems: "top",
            width: 1,
            //justifyContent: "space-between",
          }}
        >
          <Typography sx={{p: 1, fontSize: 13, color: "text.secondary"}}>
            {`${selectedMeasurementIds.length}` + xSelectedMeasurementsS}
          </Typography>
        </Box>
      )}
      {selectedMeasurementIds.length === 1 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{fontSize: 12, color: "text.secondary", pl: 1}}>
            {elementType?.name}
          </Typography>
          {!viewer && (
            <ButtonEditMeasurement
              scene={scene}
              caplaEditor={caplaEditor}
              measurement={measurement}
            />
          )}
        </Box>
      )}
      {selectedMeasurementIds.length === 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "primary.flash",
            mt: 1,
            mx: 1,
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "&>*:not(:last-child)": {mr: 1},
            }}
          >
            <Chip
              size="small"
              onClick={handleFocus3d}
              label={focus3dS}
              icon={<Focus />}
            />
            <Chip
              size="small"
              onClick={handleFocusPdf}
              label={focusPdfS}
              icon={<Focus />}
            />
          </Box>
          <Chip
            size="small"
            onClick={handleSeeZone}
            label={labelIsShow ? "Afficher" + seeZoneS : "Masquer" + seeZoneS}
          />
        </Box>
      )}
      {selectedMeasurementIds.length === 1 && (
        <FormMeasurementOverview
          measurement={{
            ...measurement,
            elementType,
          }}
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
    </Box>
  );
}
