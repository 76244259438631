import {nanoid} from "@reduxjs/toolkit";
import React, {useState} from "react";

import {useDispatch} from "react-redux";
import useAddSceneElementType from "../hooks/useAddSceneElementType";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import theme from "Styles/theme";

import FormElementType from "./FormElementType";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";
import {setSelectedElementTypeId} from "../elementTypesSlice";

export default function ButtonDialogCreateElementType({scene}) {
  const dispatch = useDispatch();

  // strings

  const newS = "New";

  const cancelS = "Cancel";
  const createS = "Create";

  // data

  const addSceneElementType = useAddSceneElementType(scene);

  // state

  const defaultType = {
    color: theme.palette.common.yellow,
    dim1: 0.2,
    drawingShape: "SEGMENT",
  };

  const [elementType, setElementType] = useState(defaultType);

  const [open, setOpen] = useState(false);

  // helpers

  const hiddenFields = [
    "group",
    "isTitle",
    "isAgg",
    "parent",
    "export",
    "subject",
  ];

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handleNewClick() {
    dispatch(setOpenSelectionPanel(true));
    dispatch(setSelectedElementTypeId(null));
  }

  function handleElementTypeChange(elementType) {
    setElementType(elementType);
  }

  function handleSave() {
    const newElementType = {
      ...elementType,
      id: nanoid(),
    };
    addSceneElementType(newElementType);
    setOpen(false);
    setElementType(defaultType);
  }

  function handleCancelClick() {
    setOpen(false);
    setElementType(defaultType);
  }

  return (
    <Box>
      <Button
        startIcon={<Add />}
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleNewClick}
      >
        {newS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{newS}</DialogTitle>
        <DialogContent>
          <FormElementType
            elementType={elementType}
            onChange={handleElementTypeChange}
            scene={scene}
            hiddenFields={hiddenFields}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button onClick={handleSave}>{createS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
