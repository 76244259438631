import React, {useEffect} from "react";

import {useDispatch} from "react-redux";
import {setSelectedProgressListingId} from "../progressSlice";
import useSceneListings from "Features/listings/useSceneListings";

import {Autocomplete, Typography, TextField} from "@mui/material";
import useSelectedProgressListing from "../useSelectedProgressListing";

export default function SelectorProgressListing({scene}) {
  const dispatch = useDispatch();

  // strings

  const scopeString = "Progress listing";

  // data

  const progressListings = useSceneListings(scene?.id).filter(
    (l) => l.listingType === "recordset"
  );

  const selectedProgressListing = useSelectedProgressListing(scene);

  // options

  const options = progressListings;

  // handlers

  function handleChange(listing) {
    dispatch(setSelectedProgressListingId(listing?.id));
  }

  // effect - default selection

  // useEffect(() => {
  //   if (progressListings?.length > 0 && !selectedProgressListing) {
  //     dispatch(setSelectedProgressListingId(progressListings[0].id));
  //   }
  // }, [selectedProgressListing?.id, progressListings?.length]);

  return (
    <Autocomplete
      sx={{width: 1}}
      size="small"
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography {...props} variant="body2" sx={{pl: 1}}>
          {option.name}
        </Typography>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={<Typography variant="body2">{scopeString}</Typography>}
          InputProps={{
            ...params.InputProps,
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
        />
      )}
      onChange={(e, v) => handleChange(v)}
      value={selectedProgressListing ? selectedProgressListing : null}
    />
  );
}
