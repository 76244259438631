function removeExprFilters(expr) {
  if (expr) return expr.replace(/\s?\{\{[^\}]+\}\}/g, "");
  else return "";
}

function getExprFiltersStrings(expr) {
  const matches = expr?.matchAll(/\s?\{\{([^\}]+)\}\}/g);
  let filters = [];
  if (matches) filters = [...matches].map((match) => match[1]);
  return filters;
}

export default function parseFuncAndFiltersStrString(string) {
  const funcAndFiltersStr = {};

  const filtersStr = getExprFiltersStrings(string);
  const func = removeExprFilters(string);

  if (filtersStr?.length > 0) funcAndFiltersStr.filtersStr = filtersStr;
  if (func) funcAndFiltersStr.func = func;

  return funcAndFiltersStr;
}
