import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {client} from "API/capla360";
import {createNoteContributionService} from "./services";

export const fetchNote = createAsyncThunk(
  "note/fetchNote",
  async ({accessToken, listingId, noteId}) => {
    const response = await client.get(
      `/listings/${listingId}/notes/${noteId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return {data: response.data};
  }
);

export const updateNote = createAsyncThunk(
  "note/updateNote",
  async ({accessToken, listingId, noteId, note}) => {
    const response = await client.put(
      `/listings/${listingId}/notes/${noteId}`,
      note,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return {data: response.data, listingId, noteId};
  }
);

export const createNoteContribution = createAsyncThunk(
  "note/createContribution",
  createNoteContributionService
);

const noteSlice = createSlice({
  name: "note",
  initialState: {
    notes: {}, // detailed notes object.
    tempNoteElements: {}, // temp elements related to the note. {modelRemoteId:{codeNames:[]}
  },
  reducers: {
    setTempNoteElements: (state, action) => {
      state.tempNoteElements = action.payload;
    },
    addTempNoteElement: (state, action) => {
      const {modelRemoteId, codeName} = action.payload;
      const elements = {...state.tempNoteElements};
      if (Array.isArray(elements[modelRemoteId]?.codeNames)) {
        elements[modelRemoteId].codeNames = [
          ...elements[modelRemoteId].codeNames,
          codeName,
        ];
      } else {
        elements[modelRemoteId] = {codeNames: [codeName]};
      }
      state.tempNoteElements = elements;
    },
    removeTempNoteElement: (state, action) => {
      const {modelRemoteId, codeName} = action.payload;
      const elements = {...state.tempNoteElements};
      const currentElements = elements[modelRemoteId].codeNames;
      if (currentElements[0] === codeName) {
        delete elements[modelRemoteId];
      } else {
        console.log("currentElements", currentElements);
        const newCodeNames = currentElements.filter((c) => c !== codeName);
        elements[modelRemoteId] = {codeNames: newCodeNames};
      }
      state.tempNoteElements = elements;
    },
  },
  extraReducers: {
    [fetchNote.fulfilled]: (state, action) => {
      const note = action.payload.data;
      state.notes[note.id] = note;
    },
    [updateNote.fulfilled]: (state, action) => {
      const note = action.payload.data;
      state.notes[note.id] = note;
    },
    [createNoteContribution.fulfilled]: (state, action) => {
      const {data: contribution, listingId, noteId} = action.payload;
      if (!state.notes[noteId]) {
        state.notes[noteId] = {};
      }
      if (state.notes[noteId].contributions) {
        state.notes[noteId].contributions.push(contribution);
      } else {
        state.notes[noteId].contributions = [contribution];
      }
    },
  },
});

export const {addTempNoteElement, removeTempNoteElement, setTempNoteElements} =
  noteSlice.actions;
export default noteSlice.reducer;
