export default function useMaterialsByScene(scene) {
  //
  let materials = scene?.data?.materials;
  if (!materials) materials = [];
  materials = materials
    .filter((r) => r?.id)
    .map((r) => ({...r, num: r.num ? r.num : "0"}));
  //
  return materials;
}
