import {IconButton, Box, Tooltip} from "@mui/material";
import {Download} from "@mui/icons-material";

import useSelectedPdfModel from "../useSelectedPdfModel";

export default function ButtonDownloadPdfInToolbar({editorPdf}) {
  // data

  const pdfModel = useSelectedPdfModel();

  // strings

  const label = "Télécharger le PDF";

  // helpers - fileName

  const fileName = `${pdfModel?.name}`;

  // handlers

  async function handleDownloadClick() {
    editorPdf?.download(fileName);
  }

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        color: "common.white",
        width: 38,
        height: 38,
        justifyContent: "center",
      }}
    >
      <Tooltip title={label}>
        <IconButton
          onClick={handleDownloadClick}
          color="inherit"
          sx={{height: 30, width: 30, borderRadius: 0}}
        >
          <Download fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
