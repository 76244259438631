/*
 * ressource : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {updateRessourcesGroup} from "../ressourcesSlice";
import useSelectedRessourcesGroup from "./useSelectedRessourcesGroup";

export default function useDeleteSceneRessource(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const ressourcesGroup = useSelectedRessourcesGroup(scene);

  const deleteSceneRessource = async (ressource) => {
    if (!scene) return;
    if (ressource.fromScene) {
      let oldRessources = scene?.data?.ressources.filter(
        (s) => s?.id !== ressource?.id
      );
      if (!oldRessources) oldRessources = [];

      const newScene = {
        ...scene,
        data: {...scene?.data, ressources: [...oldRessources]},
      };

      dispatch(updateScene({scene: newScene}));
    } else {
      if ((accessToken, scene?.id)) {
        const newRessources = ressourcesGroup.ressources.filter(
          (r) => r.id !== ressource.id
        );
        const newGroup = {...ressourcesGroup, ressources: newRessources};
        await dispatch(
          updateRessourcesGroup({accessToken, ressourcesGroup: newGroup})
        );
      }
    }
  };

  return deleteSceneRessource;
}
