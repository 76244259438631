import {useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import useAddSceneRessource from "../hooks/useAddSceneRessource";

export default function ActionsNewRessourceInSelectionPanel({scene}) {
  // const dispatch = useDispatch();
  // strings

  const createS = "Create";

  // data

  const newRessource = useSelector((s) => s.ressources.newRessource);
  const addSceneRessource = useAddSceneRessource(scene);

  // handlers

  function handleCreateClick() {
    const ressource = {
      ...newRessource,
      id: nanoid(),
    };
    if (ressource.num && ressource.name?.length > 0) {
      addSceneRessource(ressource);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
