import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Menu, MenuItem, Box, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogLinkToRessource from "./DialogLinkToRessource";

import useSelectedIssue from "../useSelectedIssue";
import {createIssueFromTitleAndScreenshot} from "../issuesUtils";

import {deleteIssue, updateIssue} from "Features/issues/issuesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import {uploadFileService} from "Features/files/services";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";


export default function ButtonMoreActionsIssue({editor3d, scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const deleteS = "Delete";
  const updateZonesS = "MAJ des fonds de plan";
  const updateFiltersS = "MAJ des filtres";
  const updateFrom3DS = "3D => image";
  const linkToRessourceS = "Lier à un article";

  // data

  const selectedIssue = useSelectedIssue(scene);
  const screenshot = useSelector((s) => s.viewer3D.screenshot);

  const filterZoneId = useSelector((s) => s.measurements.filterZoneId);
  const filterElementTypeId = useSelector((s) => s.measurements.filterElementTypeId);
  const filterRessourceId = useSelector((s) => s.measurements.filterRessourceId);
  const filterSectorId = useSelector((s) => s.measurements.filterSectorId);
  const filterRoomId = useSelector((s) => s.measurements.filterRoomId);
  const filterMaterialId = useSelector((s) => s.measurements.filterMaterialId);
  const filterPhaseId = useSelector((s) => s.measurements.filterPhaseId);
  const filterP1 = useSelector((s) => s.measurements.filterP1);
  const filterP2 = useSelector((s) => s.measurements.filterP2);
  const filterP3 = useSelector((s) => s.measurements.filterP3);

  const zones = useZonesByScene(scene);
  const zonesInScope = useSelector((s) => s.measurements.zonesInScope);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openLinkToRessourceDialog, setOpenLinkToRessourceDialog] =
    useState(false);

  // helpers

  let scopedZones = zones;
  if (zonesInScope.length > 0) {
    const zscope = new Set(zonesInScope);
    scopedZones = scopedZones.filter((z) => zscope.has(z.id));
  }

  // handlers - update filters

  function handleUpdateFilters() {
    const measurementsFilters = {...selectedIssue.context.sceneContext.measurementsFilters};
    measurementsFilters.zones = filterZoneId ? [filterZoneId] : [];
    measurementsFilters.elementTypes = filterElementTypeId ? [filterElementTypeId] : [];
    measurementsFilters.ressources = filterRessourceId ? [filterRessourceId] : [];
    measurementsFilters.sectors = filterSectorId ? [filterSectorId] : [];
    measurementsFilters.rooms = filterRoomId ? [filterRoomId] : [];
    measurementsFilters.materials = filterMaterialId ? [filterMaterialId] : [];
    measurementsFilters.phases = filterPhaseId ? [filterPhaseId] : [];
    measurementsFilters.p1s = filterP1 ? [filterP1] : [];
    measurementsFilters.p2s = filterP2 ? [filterP2] : [];
    measurementsFilters.p3s = filterP3 ? [filterP3] : [];
    const updatedIssue = {
      ...selectedIssue,
      context: {
        ...selectedIssue.context,
        sceneContext: {
          ...selectedIssue.context.sceneContext,
          measurementsFilters,
        }
      }
    };
    dispatch(updateIssue({issue: updatedIssue, accessToken}));
    setAnchorEl(null);
  }

  function handleUpdateZones() {
    const imageModelIds = scopedZones.filter((z) => !z.hidden).map((z) => z.imageModelId)
    const updatedIssue = {
      ...selectedIssue,
      context: {
        ...selectedIssue.context,
        sceneContext: {
          ...selectedIssue.context.sceneContext,
          imageModelIds,
        }
      }
    };
    dispatch(updateIssue({issue: updatedIssue, accessToken}));
    setAnchorEl(null);
  }

  // helpers - handlers - update from 3D

  async function handleUpdateFrom3D() {
    if (screenshot?.url) {
      const sceneListingId = selectedIssue.listing;
      if (accessToken && typeof sceneListingId === "string") {
        // post file
        const url = editor3d?.annotator.stage.toDataURL();
        const fileName = `issue-image-${nanoid()}.png`;
        const response = await fetch(url);
        const blob = await response.blob();
        let file;
        let remoteUrl;
        file = new File([blob], fileName, {type: "image/png"});
        if (file) {
          remoteUrl = await uploadFileService({
            listingId: sceneListingId,
            accessToken,
            file,
            container: "listing-files",
          });
        }

        // post issue
        const newScreenshot = {...screenshot, url: remoteUrl};
        const {imageUrl, context, fromViewMode} =
          createIssueFromTitleAndScreenshot("title", newScreenshot);
        const updatedIssue = {
          ...selectedIssue,
          imageUrl,
          context,
          fromViewMode,
        };
        dispatch(updateIssue({issue: updatedIssue, accessToken}));
      }
    }
    setAnchorEl(null);
  }

  // helpers - handlers - link to ressource

  function handleLinkToRessource() {
    setOpenLinkToRessourceDialog(true);
    setAnchorEl(null);
  }

  function handleCloseLinkToRessourceDialog() {
    setOpenLinkToRessourceDialog(false);
  }

  function handleSaveLinkToRessource(ressource) {
    setOpenLinkToRessourceDialog(false);
    const linkedTo = selectedIssue.linkedTo ? {...selectedIssue.linkedTo} : {};
    linkedTo.ressources = [ressource.id];
    const updatedIssue = {...selectedIssue, linkedTo};
    dispatch(updateIssue({issue: updatedIssue, accessToken}));
  }

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    dispatch(deleteIssue({issue: selectedIssue, accessToken}));
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "UPDATE_ZONES",
      label: updateZonesS,
      handler: handleUpdateZones,
    },
    {
      id: "UPDATE_FILTERS",
      label: updateFiltersS,
      handler: handleUpdateFilters,
    },
    {
      id: "UPDATE_FROM_3D",
      label: updateFrom3DS,
      handler: handleUpdateFrom3D,
    },
    {
      id: "LINK_TO_RESSOURCE",
      label: linkToRessourceS,
      handler: handleLinkToRessource,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogLinkToRessource
        open={openLinkToRessourceDialog}
        onClose={handleCloseLinkToRessourceDialog}
        onSave={handleSaveLinkToRessource}
        scene={scene}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"issue"}
      />
    </>
  );
}
