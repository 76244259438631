import {useState} from "react";
import {Button, Box, Dialog} from "@mui/material";
import {Add} from "@mui/icons-material";

import FormCreateOrganisation from "./FormCreateOrganisation";

export default function ButtonDialogCreateOrganisation() {
  // string

  const createS = "Organisation";

  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleClick() {
    setOpen(true);
  }

  return (
    <>
      <Button startIcon={<Add />} onClick={handleClick}>
        {createS}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <FormCreateOrganisation
          onCancel={() => {
            setOpen(false);
          }}
          onCreated={() => {
            setOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}
