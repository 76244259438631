import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import {Settings} from "@mui/icons-material";

import {
  setGltfOnly,
  setStoreFiles,
  setLoadEdges,
  setRawIfcModel,
  setAutoplacement,
  setLoadIfcStructure,
} from "Features/viewer3D/viewer3DSlice";

export default function ButtonDialogSettings({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const titleString = "Loading settings";

  const rawIfcModelFalseString = "Split by level & categories";
  const gltfOnlyString = "Load only 3D (no data)";
  const storeFilesString = "Store downloaded files in the browser storage";
  const loadEdgesString = "Compute edges at load time";
  const autoplacementString = "Set model origin to '0,0,0";
  const loadIfcStructureString = "Load ifc structure";

  // data

  const gltfOnly = useSelector((s) => s.viewer3D.gltfOnly);
  const storeFiles = useSelector((s) => s.viewer3D.storeFiles);
  const rawIfcModel = useSelector((s) => s.viewer3D.rawIfcModel);
  const loadEdges = useSelector((s) => s.viewer3D.loadEdges);
  const autoplacement = useSelector((s) => s.viewer3D.autoplacement);
  const loadIfcStructure = useSelector((s) => s.viewer3D.loadIfcStructure);

  // state

  const [open, setOpen] = useState(false);

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }

  // handlers - settings

  function handleGltfOnly(e) {
    const checked = e.target.checked;
    editor3d?.loader.setGltfOnly(checked);
    dispatch(setGltfOnly(checked));
  }
  function handleStoreFiles(e) {
    const checked = e.target.checked;
    if (editor3d?.loader) editor3d?.loader.setStoreFiles(checked);
    if (editor3d?.multiScenesLoader)
      editor3d?.multiScenesLoader.setStoreFiles(checked);
    dispatch(setStoreFiles(checked));
  }
  function handleLoadEdges(e) {
    const checked = e.target.checked;
    editor3d?.loader.setLoadEdges(checked);
    dispatch(setLoadEdges(checked));
  }
  function handleRawIfcModel(e) {
    const checked = !e.target.checked;
    editor3d?.loader.setRawIfcModel(checked);
    dispatch(setRawIfcModel(checked));
  }
  function handleAutoplacement(e) {
    const checked = e.target.checked;
    editor3d?.loader.setAutoplacement(checked);
    dispatch(setAutoplacement(checked));
  }
  function handleLoadIfcStructure(e) {
    const checked = e.target.checked;
    editor3d?.loader.setLoadIfcStructure(checked);
    dispatch(setLoadIfcStructure(checked));
  }

  return (
    <Box>
      <IconButton
        size="small"
        onClick={handleOpenDialog}
        sx={{borderRadius: "4px"}}
      >
        <Settings fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{titleString}</DialogTitle>
        <DialogContent>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <FormControlLabel
              sx={{mt: 1}}
              control={
                <Checkbox
                  size="small"
                  checked={gltfOnly}
                  onChange={handleGltfOnly}
                />
              }
              label={<Typography variant="body2">{gltfOnlyString}</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={storeFiles}
                  onChange={handleStoreFiles}
                />
              }
              label={
                <Typography variant="body2">{storeFilesString}</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={loadEdges}
                  onChange={handleLoadEdges}
                />
              }
              label={<Typography variant="body2">{loadEdgesString}</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={!rawIfcModel}
                  onChange={handleRawIfcModel}
                />
              }
              label={
                <Typography variant="body2">
                  {rawIfcModelFalseString}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={autoplacement}
                  onChange={handleAutoplacement}
                />
              }
              label={
                <Typography variant="body2">{autoplacementString}</Typography>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={loadIfcStructure}
                  onChange={handleLoadIfcStructure}
                />
              }
              label={
                <Typography variant="body2">
                  {loadIfcStructureString}
                </Typography>
              }
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
