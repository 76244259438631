import {useState} from "react";
import {useDispatch} from "react-redux";

import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function CardModelSettingsMisc({model, editor3d}) {
  const {t} = useTranslation(["viewer3D", "common"]);
  const dispatch = useDispatch();

  // strings

  const loadingBoxTitle = t("viewer3D:model.settings.loadingBox.title");
  const displayLoadingBoxString = t(
    "viewer3D:model.settings.loadingBox.display"
  );
  const updateLBString = t("viewer3D:model.settings.loadingBox.update");
  const updateLBSimpleButton = t(
    "viewer3D:model.settings.loadingBox.simpleFitButton"
  );
  const updateLBAdvancedButton = t(
    "viewer3D:model.settings.loadingBox.advancedFitButton"
  );
  const updateLBManualButton = t(
    "viewer3D:model.settings.loadingBox.manualFitButton"
  );

  const saveString = capitalizeStart(t("common:save"));

  // data

  const displayed = Boolean(model.displayLoadingBox);

  // state

  const [manualFit, setManualFit] = useState("");
  const [open, setOpen] = useState(false);

  // handlers

  function handleSwitchChange(e) {
    const displayed = e.target.checked;
    const entity = editor3d?.getEntity(model.id);
    if (displayed) {
      entity?.showLoadingBox();
    } else {
      entity?.hideLoadingBox();
    }
    const newModel = {...model, displayLoadingBox: displayed};
    dispatch(updateModel({updatedModel: newModel, sync: false}));
  }

  function handleComputeLBSimple() {
    const entity = editor3d?.getEntity(model.id);
    entity?.fitLoadingBoxSimple();
  }

  function handleComputeLBAdvanced() {
    const entity = editor3d?.getEntity(model.id);
    entity?.fitLoadingBoxAdvanced();
  }

  function handleManualFitChange(e) {
    setManualFit(e.target.value);
  }

  function handleSaveManualFit() {
    const dims = manualFit.split(";");
    const center = {
      x: parseFloat(dims[0]),
      y: -parseFloat(dims[2]),
      z: parseFloat(dims[1]),
    };
    const width = parseFloat(dims[3]);
    const height = parseFloat(dims[5]);
    const depth = parseFloat(dims[4]);

    const entity = editor3d?.getEntity(model.id);
    entity?.fitLoadingBoxManual &&
      entity?.fitLoadingBoxManual({center, width, height, depth});

    setOpen(false);
  }

  return (
    <Box sx={{p: 2}}>
      <Card>
        <CardContent>
          <Typography variant="body2" gutterBottom>
            <b>{loadingBoxTitle}</b>
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={displayed}
                  onChange={handleSwitchChange}
                  size="small"
                />
              }
              label={
                <Typography variant="body2">
                  {displayLoadingBoxString}
                </Typography>
              }
            />
          </FormGroup>
          <Typography variant="body2" gutterBottom sx={{mt: 1}}>
            {updateLBString}
          </Typography>
          <Box sx={{display: "flex", justifyContent: "space-around", mt: 1}}>
            <Button
              size="small"
              variant="outlined"
              sx={{mr: 2, width: "40%"}}
              onClick={handleComputeLBSimple}
            >
              {updateLBSimpleButton}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleComputeLBAdvanced}
              sx={{width: "40%"}}
            >
              {updateLBAdvancedButton}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setOpen(true)}
              sx={{width: "40%"}}
            >
              {updateLBManualButton}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <TextField value={manualFit} onChange={handleManualFitChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveManualFit}>{saveString}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
