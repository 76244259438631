import {useState} from "react";

import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import useUpdateSceneRessources from "../hooks/useUpdateSceneRessources";
import excelRessourcesToRessources from "../utils/excelRessourcesToRessources";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getRessourcesFromFile from "Features/excel/utils/getRessourcesFromFile";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function DialogImportRessourcesFromExcel({
  open,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Importez des articles";

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const updateRessources = useUpdateSceneRessources(scene);

  // state

  const [ressources, setRessources] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    let ressources = await getRessourcesFromFile(file);
    ressources = excelRessourcesToRessources(ressources);
    setRessources(ressources);
    console.log("ressourcesToLoad", ressources);
  }
  function handleCancelClick() {
    onClose();
  }
  async function handleSaveClick() {
    if (loading) return;
    setLoading(true);
    await updateRessources(ressources);
    setLoading(false);
    const triggeredAt = Date.now();
    const message = `Tableau ${ressources[0].group} mis à jour`;
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button disabled={loading} onClick={handleSaveClick}>
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
