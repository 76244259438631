import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Typography,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import useSectorsByScene from "../hooks/useSectorsByScene";

import {setSectorsInScope} from "Features/measurements/measurementsSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";

export default function SelectorSectorInScope({scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const allSectors = useSectorsByScene(scene);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    true,
    false,
    false,
    false,
    viewer,
  );

  const fmSectorIds = [...new Set(fms.map((m) => m.sectorId))];

  let sectors = [{id: undefined, name: "Tous", z: -9999, building: "000000"}];
  fmSectorIds.map((id) => {
    if (!id) {
      sectors.push({id: null, name: "-?-", z: 9999, building: "zzzzzz"});
    } else {
      sectors.push(allSectors.find((s) => s.id === id));
    }
  });

  if (viewer) sectors = sectors.sort((a, b) => a.building ? a.building.localeCompare(b.building) || a.z - b.z : a.z - b.z);
  else sectors = sectors.sort((a, b) => a.z - b.z);

  const sectorsInScope = useSelector((s) => s.measurements.sectorsInScope);
  const scopeSectorId = sectorsInScope[0];
  let scopeSector = sectors.find((i) => i.id === scopeSectorId);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helper - label

  const label = scopeSector?.name;

  // handlers

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(sector) {
    if (!sector || sector.id === undefined) dispatch(setSectorsInScope([]));
    else dispatch(setSectorsInScope([sector.id]));
    setAnchorEl(null);
  }

  return (
    <>
      <Paper
        elevation={12}
        onClick={handleMoreClick}
        size="small"
        sx={{
          color: "common.white",
          bgcolor: "common.caplaBlack",
          px: 1,
          pl: 2,
          display: "flex",
          alignItems: "center",
          borderRadius: "15px",
          height: "30px",
          cursor: "pointer",
        }}
      >
        <Typography sx={{fontSize: 13}}>{label}</Typography>
        <Down sx={{ml: 2}} color="inherit" size="small" />
      </Paper>
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {sectors.map((sector) => {
          const selected = sector?.id === scopeSectorId;
          if (sector)
            return (
              <MenuItem
                key={sector.name}
                onClick={() => handleChange(sector)}
                selected={selected}
              >
                <Typography variant="body2">{sector.name}</Typography>
              </MenuItem>
            );
          return (<></>);
        })}
      </Menu>
    </>
  );
}
