import useSceneListings from "../useSceneListings";

import SelectorAutocompleteMultipleWithDialog from "Components/SelectorAutocompleteMultipleWithDialog";

export default function FieldListingIds({
  scene,
  name,
  value,
  onChange,
  locked,
}) {
  // data

  const listings = useSceneListings(scene?.id);

  // helpers

  const selection = Array.isArray(value)
    ? listings.filter((listing) => value.includes(listing.id))
    : [];

  // handlers

  function handleChange(listings) {
    onChange(listings.map((listing) => listing.id));
  }

  return (
    <SelectorAutocompleteMultipleWithDialog
      name={name}
      value={selection}
      onChange={handleChange}
      options={listings}
      disabled={locked}
      checkAll={true}
    />
  );
}
