import React from "react";

import {useDispatch} from "react-redux";
import {setSceneListingId} from "Features/viewer3D/viewer3DSlice";

import {Box, Typography, ListItemButton, ListItem} from "@mui/material";

import ButtonDialogMemberships from "./ButtonDialogMemberships";
import ListingIconName from "Features/listing/components/ListingIconName";

export default function ListItemListing({listing}) {
  const dispatch = useDispatch();

  // props

  const listingTitle = listing?.name;

  // handlers

  function handleClick() {
    dispatch(setSceneListingId(listing.id));
  }
  return (
    <ListItem
      disablePadding
      secondaryAction={<ButtonDialogMemberships listing={listing} />}
    >
      <ListItemButton onClick={handleClick} divider>
        <ListingIconName listing={listing} />
      </ListItemButton>
    </ListItem>
  );
}
