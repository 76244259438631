import {Typography, Paper, Button, Box} from "@mui/material";

// import {updateModel} from "Features/viewer3D/viewer3DSlice";
// import {decode} from "Features/translations/utils";

export default function ListItemModel({zone, editorPdf, count}) {
  // const dispatch = useDispatch();

  // helpers

  const name = zone?.name;

  // helpers - style

  const bgcolor = "common.caplaBlack";
  const color = "common.white";

  // handlers

  function handleClick() {
    editorPdf?.annotationsManager.goToZone(zone?.id);
  }

  return (
    <Paper
      sx={{
        cursor: "pointer",
        width: "fit-content",
        bgcolor,
        color,
      }}
      elevation={12}
    >
      <Button onClick={handleClick} color="inherit" sx={{height: 30}}>
        <Typography sx={{fontWeight: "bold", fontSize: 13}}>{name}</Typography>
        {count && (
          <Box
            sx={{
              ml: 1,
              height: 16,
              width: 16,
              borderRadius: "8px",
              bgcolor: "secondary.main",
            }}
          >
            <Typography sx={{fontSize: 12}}>{count}</Typography>
          </Box>
        )}
      </Button>
    </Paper>
  );
}
