import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";

import {deleteAllArticles} from "../articlesSlice";

import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useAccessToken from "Features/auth/useAccessToken";

export default function DialogDeleteAllArticles({open, onClose}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // state

  const [loading, setLoading] = useState(false);

  // data

  const articlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    await dispatch(deleteAllArticles({articlesGroupId, accessToken}));
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="allArticles"
      loading={loading}
    />
  );
}
