export default function aggregateMeasurements(measurements) {
  //
  if (!Array.isArray(measurements) || measurements.length === 0) return null;
  //
  const aggregate = {elementTypeId: measurements[0].elementTypeId};

  let length = 0;
  let area = 0;
  let volume = 0;
  //
  let lengthNet = 0;
  let areaNet = 0;
  let volumeNet = 0;
  //
  let lengthRaw = 0;
  let areaRaw = 0;
  let volumeRaw = 0;
  //
  measurements.forEach((meas) => {
    if (meas.length) length += meas.length;
    if (meas.area) area += meas.area;
    if (meas.volume) volume += meas.volume;
    //
    if (meas.lengthNet) lengthNet += meas.lengthNet;
    if (meas.areaNet) areaNet += meas.areaNet;
    if (meas.volumeNet) volumeNet += meas.volumeNet;
    //
    if (meas.lengthRaw) lengthRaw += meas.lengthRaw;
    if (meas.areaRaw) areaRaw += meas.areaRaw;
    if (meas.volumeRaw) volumeRaw += meas.volumeRaw;
  });
  //
  aggregate.length = length;
  aggregate.area = area;
  aggregate.volume = volume;
  //
  aggregate.lengthNet = lengthNet;
  aggregate.areaNet = areaNet;
  aggregate.volumeNet = volumeNet;
  //
  aggregate.lengthRaw = lengthRaw;
  aggregate.areaRaw = areaRaw;
  aggregate.volumeRaw = volumeRaw;
  //
  return aggregate;
}
