import {Chip} from "@mui/material";
import {Web} from "@mui/icons-material";

export default function ChipRessourceWebUrl({webUrl}) {
  // string

  const label = "Site internet";

  // handlers

  function handleClick() {
    window.open(webUrl, "_blank");
  }
  return (
    <Chip size="small" onClick={handleClick} icon={<Web />} label={label} />
  );
}
