import {Box} from "@mui/material";

import SectionImportPdf from "./SectionImportPdf";

export default function SectionStartPdf({scene, onFileChange}) {
  return (
    <Box sx={{display: "flex", flexGrow: 1, bgcolor: "common.white", p: 1}}>
      <SectionImportPdf scene={scene} onFileChange={onFileChange} />
    </Box>
  );
}
