import React from "react";

import {Box, Typography, Paper} from "@mui/material";

import {decode} from "Features/translations/utils";

export default function ListItemModel({model, onClick}) {
  const selected = !model.hidden;

  // helpers

  const name = decode(model.name);

  function handleClick() {
    onClick(model);
  }
  return (
    <Paper
      sx={{px: 1, py: 0.5, cursor: "pointer", maxWidth: 200}}
      elevation={selected ? 12 : 1}
      onClick={handleClick}
    >
      <Typography
        sx={{fontWeight: selected ? "bold" : "normal", fontSize: 13}}
        noWrap
      >
        {name}
      </Typography>
    </Paper>
  );
}
