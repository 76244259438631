import {client} from "API/capla360";

export async function fetchPicturesService({accessToken, listingId}) {
  const response = await client.get(`listings/${listingId}/pictures/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function postPictureService({accessToken, listingId, picture}) {
  const response = await client.post(
    `listings/${listingId}/pictures/`,
    picture,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {clientId: picture.clientId, picture: response.data};
}

export async function fetchPictureService({accessToken, listingId, pictureId}) {
  const response = await client.get(
    `listings/${listingId}/pictures/${pictureId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}

export async function updatePictureService({accessToken, picture}) {
  const listingId = picture.listingId;
  const pictureId = picture.id;
  const response = await client.put(
    `listings/${listingId}/pictures/${pictureId}`,
    picture,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}
