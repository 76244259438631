const markers = [
  {
    type: "REFERENCE",
    id: "1",
    x: 0,
    y: 0,
    z: 0,
    number: 1,
    description: "X=0,Y=0,Z=0",
  },
  {
    type: "REFERENCE",
    id: "2",
    x: 10,
    y: 0,
    z: 0,
    number: 2,
    description: "X=10m,Y=0,Z=0",
  },
  {
    type: "REFERENCE",
    id: "4",
    x: 0,
    y: 0,
    z: -10,
    number: 3,
    description: "X=0,Y=10m,Z=0",
  },
  {
    type: "REFERENCE",
    id: "3",
    x: 0,
    y: 10,
    z: 0,
    number: 4,
    description: "X=0,Y=0,Z=10m",
  },
];

export default markers;
