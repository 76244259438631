import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {FormControlLabel, Checkbox, Typography} from "@mui/material";

import {setIsDrawingOneByOne} from "Features/measurements/measurementsSlice";

export default function CheckboxIsDrawingOneByOne({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const labelS = "Mode rafale";

  // data

  const isDrawingOneByOne = useSelector(
    (s) => s.measurements.isDrawingOneByOne
  );

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    dispatch(setIsDrawingOneByOne(!checked));
    editorPdf?.annotationsManager.measurementsPdfManager.setIsDrawingOneByOne(
      checked
    );
  }

  useEffect(() => {
    if (editorPdf) {
      editorPdf.annotationsManager.measurementsPdfManager.setIsDrawingOneByOne(
        isDrawingOneByOne
      );
    }
  }, [isDrawingOneByOne, editorPdf]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={!isDrawingOneByOne}
          onChange={handleCheckChange}
        />
      }
      label={
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {labelS}
        </Typography>
      }
    />
  );
}
