import theme from "Styles/theme";
import {setMultiviewsSliderPositionX} from "../viewer3DSlice";
import handleImage from "../assets/handle.png";

export default class Multiviews {
  slider; // slider dom element
  el1; // dom element, left part
  el2; // dom element, right part
  containerEl; // three.js initial canvas container

  view1; // object with the left view attributes.
  view2;

  isEnabled; // enabled = the mode is active.

  prevRatio; // used when toggling show3D button.

  draggingHandle; // used to handle sizing when dragging the handle.

  dividerLeft; // position in px of the separator with respect to the window. Used to select the active camera when clicking on the screen

  constructor({editor}) {
    this.initialRatio = 1;
    this.defaultRatio = 0.65; // set when no prev ratio.
    this.editor = editor;
    this.cutCam1 = false; // defined in viewer3Dslice as well.
    this.initEl1();
    this.initEl2();
    this.isEnabled = false;
  }

  initViews = () => {
    this.initContainerEl();

    this.initSlider();
    this.initHandle();

    this.initView1(this.editor.cameras.camera1);
    this.initView2(this.editor.cameras.camera2);

    this.editor.controls.initControls(this.containerEl, this.el1, this.el2);
  };

  initView1(camera) {
    this.view1 = {
      left: 0,
      bottom: 0,
      width: this.initialRatio,
      height: 1.0,
      camera,
    };
  }
  initView2(camera) {
    this.view2 = {
      left: this.initialRatio,
      bottom: 0,
      width: 1 - this.initialRatio,
      height: 1.0,
      camera,
    };
  }
  initContainerEl() {
    this.containerEl = this.editor.domElement;
  }

  initHandle() {
    const handle = document.createElement("div");
    const handleBg = document.createElement("div");

    handle.appendChild(handleBg);

    handle.style.width = "10px";
    handle.style.height = "30px";
    handle.style.backgroundColor = theme.palette.common.caplaBlack;
    handle.style.backgroundImage = `url(${handleImage})`;
    handle.style.backgroundSize = "cover";
    handle.style.backgroundRepeat = "no-repeat";
    handle.style.backgroundPosition = "center center";
    handle.style.cursor = "ew-resize";

    handle.style.position = "absolute";
    handle.style.left = `calc(${this.initialRatio * 100}% )`;
    handle.style.bottom = "10%";
    handle.style.transform = "translate(-50%)";

    handle.style.zIndex = 100;

    handleBg.style.width = "300px";
    handleBg.style.transform = "translate(-50%,-50%)";
    handleBg.style.height = "200px";
    handleBg.style.display = "none";
    handleBg.style.cursor = "ew-resize";
    handleBg.style.display = "flex";
    handleBg.style.alignItems = "center";

    // listeners

    handle.addEventListener("pointerdown", (e) => {
      e.stopPropagation();
      this.draggingHandle = true;
      handleBg.style.display = "block";
    });

    handleBg.addEventListener("pointerup", (e) => {
      e.stopPropagation();
      this.draggingHandle = false;
      handleBg.style.display = "none";
    });
    handleBg.addEventListener("pointerleave", (e) => {
      e.stopPropagation();
      this.draggingHandle = false;
      handleBg.style.display = "none";
    });

    handleBg.addEventListener("pointermove", (e) => {
      if (this.draggingHandle) {
        this.onPointerMove(e);
      }
    });

    this.handle = handle;
    this.handleBg = handleBg;

    this.hideHandle();
  }

  hideHandle() {
    this.handle.style.visibility = "hidden";
    this.handleBg.style.visibility = "hidden";
  }

  showHandle() {
    this.handle.style.visibility = "visible";
    this.handleBg.style.visibility = "visible";
  }

  initSlider() {
    const slider = document.createElement("div");
    slider.style.position = "absolute";
    //slider.style.top = "calc(50% - 12px)";
    //slider.style.left = "calc(50% - 12px)";
    //slider.style.width = "24px";
    //slider.style.height = "24px";
    //slider.style.borderRadius = "50%";
    //slider.style.opacity = "0.7";
    slider.style.backgroundColor = "rgb(255,255,255,0.2)";
    slider.style.top = 0;
    slider.style.bottom = 0;
    slider.style.left = `calc(${this.initialRatio * 100}% - 5px)`;
    //slider.style.width = "1px";
    //slider.style.cursor = "ew-resize"; // removed: we add the handle
    slider.style.width = "10px";
    //slider.style.background = "none";
    slider.style.touchAction = "none"; // disable touch scroll
    slider.style.zIndex = 1;
    //slider.addEventListener("pointerdown", () => this.onPointerDown()); // changed with "handle".

    this.slider = slider;
  }
  initEl1() {
    const el1 = document.createElement("div");
    el1.style.width = `${this.initialRatio * 100}%`;
    el1.style.height = "100%";
    el1.style.position = "absolute";
    el1.style.top = "0px";
    el1.style.zIndex = 10;

    el1.addEventListener("pointerdown", (event) => {
      event.stopPropagation();
      console.log("POINTER DOWN ON EL1");
      this.editor.handlePointerDown();
    });
    el1.addEventListener("pointermove", (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (this.editor.controls.activeMode === "ORBIT2") {
        this.editor.handlePointerMove(event, el1, this.view1.camera);
      }
    });
    el1.addEventListener("pointerup", (event) => {
      this.editor.handlePointerUp();
    });
    el1.addEventListener("click", (event) => {
      //if (!editor.dragging) editor.onClick(event)
      this.editor.onClick(event, el1, this.view1.camera);
    });

    this.el1 = el1;

    this.dividerLeft = this.el1.getBoundingClientRect().right;
  }
  initEl2() {
    const el2 = document.createElement("div");
    el2.style.width = `${(1 - this.initialRatio) * 100}%`;
    el2.style.height = "100%";
    el2.style.position = "absolute";
    el2.style.top = "0px";
    el2.style.left = `${this.initialRatio * 100}%`;
    //el2.style.borderLeft = `1px solid ${theme.palette.secondary.main}`;
    //el2.style.zIndex = "10";

    el2.addEventListener("pointerdown", (event) => {
      event.stopPropagation();
      this.editor.handlePointerDown();
    });
    el2.addEventListener("pointermove", (event) => {
      if (this.editor.controls.activeMode === "ORBIT2") {
        this.editor.handlePointerMove(event, el2, this.view2.camera, true); // true : raycast without Horizontal Plane.
      }
    });
    el2.addEventListener("pointerup", (event) => {
      this.editor.handlePointerUp();
    });
    el2.addEventListener("click", (event) => {
      //if (!editor.dragging) editor.onClick(event)
      this.editor.onClick(event, el2, this.view2.camera);
    });

    this.el2 = el2;
  }

  // init cam1

  initCam1 = () => {
    const aspect1 =
      (this.containerEl.offsetWidth * this.view1.width) /
      this.containerEl.offsetHeight;
    console.log("aspect1", this.el1.offsetWidth);
    const cam1 = this.editor.cameras.camera1;
    const width = 100;
    const height = width / aspect1;
    cam1.bottom = -height / 2;
    cam1.top = height / 2;
    cam1.left = -width / 2;
    cam1.right = width / 2;
    cam1.updateProjectionMatrix();
  };

  // updates

  updateCamerasAspect = () => {
    const aspect1 = this.el1.offsetWidth / this.el1.offsetHeight;
    const cam1 = this.editor.cameras.camera1;
    const width = cam1.right - cam1.left;
    const height = width / aspect1;
    cam1.bottom = -height / 2;
    cam1.top = height / 2;
    cam1.updateProjectionMatrix();

    const aspect2 = this.el2.offsetWidth / this.el2.offsetHeight;
    const cam2 = this.editor.cameras.camera2;
    cam2.aspect = aspect2;
    cam2.updateProjectionMatrix();
  };

  updateStateSliderPositionX() {
    const sliderElLeft = this.slider.getBoundingClientRect().left;
    const sliderWidth = this.slider.offsetWidth;
    const containerElLeft = this.containerEl.getBoundingClientRect().left;

    const sliderLeft = sliderElLeft + sliderWidth / 2 - containerElLeft;
    this.editor.dispatch(setMultiviewsSliderPositionX(sliderLeft));
  }

  updateLabel1Renderer() {
    const width = this.el1.offsetWidth;
    const height = this.el1.offsetHeight;
    this.editor.label1Renderer.setSize(width, height);
  }

  updateLabel2Renderer() {
    const width = this.el2.offsetWidth;
    const height = this.el2.offsetHeight;
    this.editor.label2Renderer.setSize(width, height);
  }

  // listeners

  onPointerDown() {
    this.el1.addEventListener("pointermove", this.onPointerMove);
    this.el2.addEventListener("pointermove", this.onPointerMove);
    window.addEventListener("pointerup", this.onPointerUp);
  }
  onPointerMove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.containerEl) {
      const sliderPos = Math.max(
        0,
        Math.min(
          this.containerEl.getBoundingClientRect().width,
          e.pageX - this.containerEl.getBoundingClientRect().left
        )
      );
      const sliderLeft = sliderPos - this.slider.offsetWidth / 2;
      const size =
        (sliderLeft / this.containerEl.getBoundingClientRect().width) * 100;
      //this.slider.style.left = sliderLeft + "px";
      //this.slider.style.left = `calc(${size}% - 12px)`;
      this.slider.style.left = `calc(${size}% - 5px)`;
      this.handle.style.left = `calc(${size}%)`;

      this.el1.style.width = `${size}%`;
      this.el2.style.width = `${100 - size}%`;
      //this.el2.style.left = sliderPos + "px";
      this.el2.style.left = `${size}%`;

      this.dividerLeft = this.el1.getBoundingClientRect().right;

      this.view1.width = size / 100;
      this.view2.width = 1 - size / 100;
      this.view2.left = size / 100;

      this.updateCamerasAspect();
      this.updateLabel1Renderer();
      this.updateLabel2Renderer();

      this.editor.dispatch(setMultiviewsSliderPositionX(sliderLeft));
    }
  };
  onPointerUp = (e) => {
    this.el1.removeEventListener("pointermove", this.onPointerMove);
    this.el2.removeEventListener("pointermove", this.onPointerMove);
    window.removeEventListener("pointerup", this.onPointerUp);
  };

  // set slider position

  setSliderPosition(ratio) {
    // ratio : 0...,0.1,...0.2...1
    let size = ratio * 100;
    size = size.toFixed(0);

    this.el1.style.width = `${size}%`;
    this.el2.style.width = `${100 - size}%`;
    //this.el2.style.left = sliderPos + "px";
    this.el2.style.left = `${size}%`;

    this.dividerLeft = this.el1.getBoundingClientRect().right;

    this.view1.width = size / 100;
    this.view2.width = 1 - size / 100;
    this.view2.left = size / 100;

    // slider
    this.slider.style.left = `calc(${size}% - 5px)`;
    this.handle.style.left = `calc(${size}%)`;

    const sliderPos = Math.max(
      0,
      Math.min(
        this.containerEl.getBoundingClientRect().width,
        this.slider.getBoundingClientRect().left -
          this.containerEl.getBoundingClientRect().left
      )
    );
    const sliderLeft = sliderPos + this.slider.offsetWidth / 2;

    this.updateCamerasAspect();
    this.updateLabel1Renderer();
    this.updateLabel2Renderer();

    this.editor.dispatch(setMultiviewsSliderPositionX(sliderLeft));
  }

  getSliderPositionRatio() {
    const sliderPos = Math.max(
      0,
      Math.min(
        this.containerEl.getBoundingClientRect().width,
        this.slider.getBoundingClientRect().left -
          this.containerEl.getBoundingClientRect().left
      )
    );
    const sliderLeft = sliderPos - this.slider.offsetWidth / 2;
    const size = sliderLeft / this.containerEl.getBoundingClientRect().width;
    return size;
  }

  // 3D Button

  show3D() {
    if (this.prevRatio) {
      this.setSliderPosition(this.prevRatio);
    } else {
      this.setSliderPosition(this.defaultRatio);
    }
    this.editor.camera2Helper.enable();
    this.el1.style.borderRight = `2px solid ${theme.palette.common.caplaBlack}`;
    this.showHandle();
  }

  hide3D() {
    this.prevRatio = this.getSliderPositionRatio();
    this.setSliderPosition(1);
    this.editor.camera2Helper.disable();
    this.hideHandle();
    this.el1.style.borderRight = "none";
  }

  // enablers

  enableMultiviews() {
    if (!this.isEnabled) {
      console.log("ENABLE MULTIVIEWS");
      this.editor.renderer.setScissorTest(true);
      this.containerEl.parentElement.appendChild(this.el1);
      this.containerEl.parentElement.appendChild(this.el2);
      this.containerEl.parentElement.appendChild(this.slider);
      this.containerEl.parentElement.appendChild(this.handle);

      this.updateCamerasAspect();
      //this.editor.controls.orbitControls.enabled = false;

      // enableMultiviews
      this.editor.camera2Helper.enable();
    }
    this.isEnabled = true;
  }

  disableMultiviews() {
    if (this.isEnabled) {
      console.log("DISABLE MULTIVIEWS");
      this.editor.renderer.setScissorTest(false);
      if (this.el1) this.el1.remove();
      if (this.el2) this.el2.remove();
      if (this.slider) this.slider.remove();
      if (this.handle) this.handle.remove();

      this.editor.camera2Helper.disable();

      this.isEnabled = false;
      // this.editor.controls?.switchTo("ORBIT");
      // if (this.editor.cameras)
      //   this.editor.cameras.enable("CAMERA", this.editor.controls.orbitControls);

      //if (this.editor.controls.orbitControls)
      // this.editor.controls.orbitControls.enabled = true;
    }
  }
}
