import React, {useRef, useEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import useZonesByScene from "../hooks/useZonesByScene";
import useSelectedZone from "../hooks/useSelectedZone";
import {setSelectedZoneId} from "../zonesSlice";

import {Box, IconButton, Paper} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import ZonesEditor from "../js/ZonesEditor";
import LayerZoneViewer from "./LayerZoneViewer";

export default function ViewerZone({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const zonesEditorRef = useRef();

  // strings

  const zonesS = "Fonds de plan";

  // state

  const [zonesEditorIsLoaded, setZonesEditorIsLoaded] = useState(false);

  // data

  const zones = useZonesByScene(scene);
  const selectedZone = useSelectedZone(scene);
  const os = useSelector((s) => s.ui.openSections);
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);

  // helper

  const url = selectedZone?.imageUrl;
  const width = selectedZone?.imageSize?.width;
  const height = selectedZone?.imageSize?.height;

  const showLayer = os.fixedViewersBox && selectedFixedViewer === "ZONE";

  // handlers

  function handleSelectedZoneChange(zone) {
    dispatch(setSelectedZoneId(zone?.id));
  }

  // init

  function init() {
    const width = containerRef.current.getBoundingClientRect().width;
    const height = containerRef.current.getBoundingClientRect().height;

    zonesEditorRef.current = new ZonesEditor({
      caplaEditor,
      container: "container",
      width,
      height,
      containerElement: containerRef.current,
    });

    setZonesEditorIsLoaded(true);

    caplaEditor.zonesEditor = zonesEditorRef.current;
  }

  useEffect(() => {
    if (caplaEditor) {
      init();
      return () => {
        zonesEditorRef.current.clear();
      };
    }
  }, [caplaEditor]);

  useEffect(() => {
    if (url && containerRef.current) {
      zonesEditorRef.current.resetLayers();
      zonesEditorRef.current.addImage({url, width, height});
    }
  }, [url, containerRef.current]);

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      {showLayer && <LayerZoneViewer scene={scene} caplaEditor={caplaEditor} />}

      <Box
        sx={{
          width: 1,
          height: 1,
          position: "relative",
          bgcolor: "background.default",
          boxSizing: "borderBox",
          overflow: "hidden",
        }}
      >
        <div
          id="container"
          ref={containerRef}
          style={{
            border: "1px solid black",
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
            zIndex: 20,
          }}
        ></div>
      </Box>
    </Box>
  );
}
