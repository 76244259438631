import {Vector3, Triangle} from "three";
import Vector from "../js/Vector";

export default function getIntersection(
  event,
  canvas,
  camera,
  raycaster,
  objects,
  clippingPlanes
) {
  let snapping = false;
  let snappingPoint = new Vector3();

  let pointer = {};
  const bounds = canvas.getBoundingClientRect();

  const x1 = event.clientX - bounds.left;
  const x2 = bounds.right - bounds.left;
  pointer.x = (x1 / x2) * 2 - 1;

  const y1 = event.clientY - bounds.top;
  const y2 = bounds.bottom - bounds.top;
  pointer.y = -(y1 / y2) * 2 + 1;

  raycaster.firstHitOnly = false; // WHY ? => because of the clipping planes....(+ edges)
  //raycaster.firstHitOnly = true;

  raycaster.setFromCamera(pointer, camera);
  if (camera.userData.isCam2 || camera.userData.isCam) {
    raycaster.far = 200; // limit raycaster to 100m /!\ cam1 is at Z = 500 !!
    raycaster.layers.set(1);
  }
  if (camera.userData.isCam1) {
    raycaster.far = 1000;
    raycaster.layers.set(3);
  }

  let intersects = raycaster.intersectObjects(objects);

  // filter out edges
  intersects = intersects.filter(({object}) => !object.userData?.isEdge);

  let found = intersects[0];
  // filter by clipping planes
  if (intersects.length > 0 && clippingPlanes?.length > 0) {
    const founds = intersects.filter((elem) =>
      clippingPlanes.every((plane) => plane.distanceToPoint(elem.point) > 0)
    );
    found = founds[0];
  }

  // snapping

  // var wireframe = new THREE.LineSegments(
  //   new THREE.WireframeGeometry(geometry),
  //   new THREE.LineBasicMaterial({color: "aqua"})
  // );
  // mesh.add(wireframe);

  var poi = new Vector3();
  var pos = new Vector3();
  var tp = [new Vector3(), new Vector3(), new Vector3()];
  var tri = new Triangle();
  var bc = new Vector3();
  var idx = 0;
  var normal = new Vector3();
  var facePoints = [];

  //if (intersects.length > 0) {
  if (found) {
    poi.copy(found.point);
    found.object.worldToLocal(poi);
    if (found.face) {
      setPos(found.face);
      found.object.localToWorld(pos);
      //marker.position.copy(pos);
    }
  }

  function getNormal(a, b, c) {
    const AB = new Vector3();
    const AC = new Vector3();
    const A = new Vector3();

    A.copy(a);
    AB.copy(b);
    AC.copy(c);

    found.object.localToWorld(A);
    found.object.localToWorld(AB);
    found.object.localToWorld(AC);

    AB.sub(A);
    AC.sub(A);
    return AB.cross(AC).normalize();
  }

  function setPos(face) {
    tp[0].fromBufferAttribute(
      found.object.geometry.attributes.position,
      face.a
    );
    tp[1].fromBufferAttribute(
      found.object.geometry.attributes.position,
      face.b
    );
    tp[2].fromBufferAttribute(
      found.object.geometry.attributes.position,
      face.c
    );
    tri.set(tp[0], tp[1], tp[2]);
    normal = getNormal(tp[0], tp[1], tp[2]);

    facePoints = [tp[0], tp[1], tp[2]];
    tri.getBarycoord(poi, bc);
    if (bc.x > bc.y && bc.x > bc.z) {
      idx = 0;
    } else if (bc.y > bc.x && bc.y > bc.z) {
      idx = 1;
    } else if (bc.z > bc.x && bc.z > bc.y) {
      idx = 2;
    }
    pos.copy(tp[idx]);
  }
  const threshold =
    found?.distance && pos.distanceTo(found.point) / found.distance;

  if (found && threshold <= 0.02) {
    snapping = true;
    snappingPoint.copy(pos);
  }

  return {
    found,
    snapping,
    snappingPoint,
    //poi: snapping ? snappingPoint : found?.point,
    poi: found?.point,
    normal,
    facePoints,
  };
}
