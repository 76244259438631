import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Typography, Menu, MenuItem, Chip} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import {setSelectedElementTypeGroupInScope} from "Features/measurements/measurementsSlice";
import useElementTypeGroupsByScene from "Features/elementTypes/hooks/useElementTypeGroupsByScene";
import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";

export default function SelectorElementTypeGroupInScope({scene}) {
  const dispatch = useDispatch();

  // data

  let groups = useElementTypeGroupsByScene(scene);
  const ressourcesGroups = useRessourceGroupsByScene(scene);
  if (groups.length === 0) groups = ressourcesGroups;

  const group = useSelector(
    (s) => s.measurements.selectedElementTypeGroupInScope
  );

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const [isFirstMounted, setIsFirstMounted] = useState(false);

  const open = Boolean(anchorEl);

  // helper - label

  const label = group ? group : "-?-";
  const multiG = groups.length > 1;

  // handlers

  function handleMoreClick(e) {
    if (multiG) {
      setAnchorEl(e.currentTarget);
    }
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(group) {
    dispatch(setSelectedElementTypeGroupInScope(group));
    setAnchorEl(null);
  }

  // effect

  useEffect(() => {
    if (!isFirstMounted && groups?.length > 0) {
      dispatch(setSelectedElementTypeGroupInScope(groups[0]));
      setIsFirstMounted(true);
    }
  }, [isFirstMounted, groups?.length]);

  return (
    <>
      <Chip
        label={label}
        deleteIcon={<Down />}
        onDelete={handleMoreClick}
        size="small"
      />
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {groups.map((gr) => {
          const selected = gr === group;
          return (
            <MenuItem
              key={gr}
              onClick={() => handleChange(gr)}
              selected={selected}
            >
              <Typography variant="body2">{gr}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
