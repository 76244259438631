import {useEffect} from "react";

import {useDispatch} from "react-redux";
import {setSelectedPoiId} from "Features/pois/poisSlice";

import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import useSelectedElementTypeFromMeasurements from "Features/measurements/useSelectedElementTypeFromMeasurements";
import useSceneModule from "Features/navigation/useSceneModule";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSelectedArticle from "Features/articles/hooks/useSelectedArticle";

import getItemsMapById from "Utils/getItemsMapById";
import getArticleTypesListItemsProps from "Features/articles/utils/getArticleTypesListItemsProps";

export default function useRelevantPois(scene, options) {
  const dispatch = useDispatch();

  // options

  const filterFromViewer = options?.filterFromViewer;
  const inViewer = options?.inViewer;

  // data

  const sceneModule = useSceneModule();
  const selectedArticle = useSelectedArticle(scene);
  const selectedRessource = useSelectedRessource(scene);
  const selectedElementType = useSelectedElementType(scene);
  const selectedElementTypeFromMeasurements =
    useSelectedElementTypeFromMeasurements(scene);

  const elementTypesProxy = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const elementTypesMap = getItemsMapById(elementTypesProxy);

  // pois

  let pois = [];
  if (sceneModule === "RESSOURCES" || inViewer) {
    pois = selectedRessource?.pois ?? [];
  } else if (sceneModule === "ELEMENT_TYPES") {
    pois = selectedElementType?.pois ?? [];
  } else if (sceneModule === "EDIT_MEASUREMENTS") {
    pois = selectedElementTypeFromMeasurements?.pois ?? [];
  } else if (sceneModule === "ARTICLES") {
    pois = selectedArticle?.pois ?? [];
    // if (selectedArticle?.types) {
    //   const data = {elementTypesMap};
    //   const items = getArticleTypesListItemsProps(selectedArticle.types, data);

    //   items.forEach((item) => {
    //     if (item?.elementType?.pois) {
    //       pois = [...pois, ...item.elementType.pois];
    //     }
    //   });
    // }
  }

  // filters

  let filteredPois = pois.filter((p) => {
    if (filterFromViewer === "PDF") {
      return p.type === "PDF_ZONE" || "PDF_IMAGE";
    } else if (filterFromViewer === "3D") {
      return p.type === "POV_3D";
    } else {
      return false;
    }
  });

  useEffect(() => {
    dispatch(setSelectedPoiId(null));
  }, [
    selectedRessource?.id,
    selectedElementType?.id,
    selectedElementTypeFromMeasurements?.id,
    selectedArticle?.id,
  ]);

  return filteredPois;
}
