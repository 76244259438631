import authEN from "./en/auth.json";
import commonEN from "./en/common.json";
import listingsEN from "./en/listings.json";
import uiEN from "./en/ui.json";
import landingEN from "./en/landing.json";
import viewer3DEN from "./en/viewer3D.json";
import scenesEN from "./en/scenes.json";
import demosEN from "./en/demos.json";
import profileEN from "./en/profile.json";
import notesEN from "./en/notes.json";
import objectsetsEN from "./en/objectsets.json";
import navigationEN from "./en/navigation.json";
import packagesEN from "./en/packages.json";
import kvfEN from "./en/kvf.json";
import measurementsEN from "./en/measurements.json";

import authFR from "./fr/auth.json";
import commonFR from "./fr/common.json";
import listingsFR from "./fr/listings.json";
import uiFR from "./fr/ui.json";
import landingFR from "./fr/landing.json";
import viewer3DFR from "./fr/viewer3D.json";
import scenesFR from "./fr/scenes.json";
import demosFR from "./fr/demos.json";
import profileFR from "./fr/profile.json";
import notesFR from "./fr/notes.json";
import objectsetsFR from "./fr/objectsets.json";
import navigationFR from "./fr/navigation.json";
import packagesFR from "./fr/packages.json";
import kvfFR from "./fr/kvf.json";
import measurementsFR from "./fr/measurements.json";

export const languages = {
  en: "English",
  fr: "Français",
};

const resources = {
  en: {
    auth: authEN,
    common: commonEN,
    listings: listingsEN,
    ui: uiEN,
    landing: landingEN,
    viewer3D: viewer3DEN,
    scenes: scenesEN,
    demos: demosEN,
    profile: profileEN,
    notes: notesEN,
    objectsets: objectsetsEN,
    navigation: navigationEN,
    packages: packagesEN,
    kvf: kvfEN,
    measurements: measurementsEN,
  },
  fr: {
    auth: authFR,
    common: commonFR,
    listings: listingsFR,
    ui: uiFR,
    landing: landingFR,
    scenes: scenesFR,
    viewer3D: viewer3DFR,
    demos: demosFR,
    profile: profileFR,
    notes: notesFR,
    objectsets: objectsetsFR,
    navigation: navigationFR,
    packages: packagesFR,
    kvf: kvfFR,
    measurements: measurementsFR,
  },
};

export default resources;
