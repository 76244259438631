import {useState} from "react";

import {useDispatch} from "react-redux";

import {setSelectedElementTypesGroupProxy} from "../elementTypesSlice";

import useActionsElementTypesMap from "../hooks/useActionsElementTypesMap";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateElementType from "./DialogCreateElementType";
import DialogRenameElementTypesGroup from "./DialogRenameElementTypesGroup";
import DialogDeleteElementTypeGroup from "./DialogDeleteElementTypeGroup";
import DialogAddRelationsElementTypes from "./DialogAddRelationsElementTypes";
import DialogElementTypesGroupToProxies from "./DialogElementTypesGroupToProxies";
import DialogImportTypesRelations from "./DialogImportTypesRelations";
import DialogImportElementTypesFromExcel from "./DialogImportElementTypesFromExcel";
import DialogCreateElementTypeGroup from "./DialogCreateElementTypeGroup";
import DialogCleanElementTypesGroup from "./DialogCleanElementTypesGroup";
import DialogExtractElementTypes from "Features/measurements/components/DialogExtractElementTypes";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

import DialogToRessources from "./DialogToRessources";

export default function SectionActionsElementTypes({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  const dispatch = useDispatch();

  // data

  const selectedGroup = useSelectedElementTypesGroup(scene);
  const selectedPdfModel = useSelectedPdfModel();

  // state

  const [openDialogCreateType, setOpenDialogCreateType] = useState(false);
  const [openDialogCreateGroup, setOpenDialogCreateGroup] = useState(false);
  const [openDialogRename, setOpenDialogRename] = useState(false);
  const [openDialogDeleteGroup, setOpenDialogDeleteGroup] = useState(false);

  const [openDialogToRessources, setOpenDialogToRessources] = useState(false);

  const [openAddRelations, setOpenAddRelations] = useState(false);
  const [openImportRelations, setOpenImportRelations] = useState(false);
  const [openProxyDialog, setOpenProxyDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);

  const [
    openDialogCleanElementTypesGroup,
    setOpenDialogCleanElementTypesGroup,
  ] = useState(false);

  const [openDialogImportTypesFromPdf, setOpenDialogImportTypesFromPdf] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    createType: () => setOpenDialogCreateType(true),
    createGroup: () => setOpenDialogCreateGroup(true),
    renameGroup: () => setOpenDialogRename(true),
    deleteGroup: () => setOpenDialogDeleteGroup(true),
    copyToProxies: () => setOpenProxyDialog(true),
    copyToRessources: () => setOpenDialogToRessources(true),
    importExcelTypes: () => setOpenImportDialog(true),
    importExcelRelations: () => setOpenImportRelations(true),
    cleanElementTypesGroup: () => setOpenDialogCleanElementTypesGroup(true),
    addRelations: () => setOpenAddRelations(true),
    importTypesFromPdf: () => setOpenDialogImportTypesFromPdf(true),
  };

  // handlers

  function handleClick(actionKey) {
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  function handleCreatedGroup(groupProxy) {
    dispatch(setSelectedElementTypesGroupProxy(groupProxy));
  }
  // data - handlers

  const actionsMap = useActionsElementTypesMap({scene});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  // handlers

  function handleCloseDialogCleanElementTypesGroup() {
    setOpenDialogCleanElementTypesGroup(false);
  }

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateType && (
        <DialogCreateElementType
          scene={scene}
          open={openDialogCreateType}
          onClose={() => setOpenDialogCreateType(false)}
        />
      )}
      {openDialogCreateGroup && (
        <DialogCreateElementTypeGroup
          scene={scene}
          open={openDialogCreateGroup}
          onClose={() => setOpenDialogCreateGroup(false)}
          onCreated={handleCreatedGroup}
        />
      )}

      {openDialogRename && (
        <DialogRenameElementTypesGroup
          open={openDialogRename}
          onClose={() => {
            setOpenDialogRename(false);
          }}
        />
      )}
      {openDialogDeleteGroup && (
        <DialogDeleteElementTypeGroup
          open={openDialogDeleteGroup}
          onClose={() => setOpenDialogDeleteGroup(false)}
          elementTypeGroup={selectedGroup}
        />
      )}

      {openAddRelations && (
        <DialogAddRelationsElementTypes
          scene={scene}
          open={openAddRelations}
          onClose={() => setOpenAddRelations(false)}
        />
      )}
      {openImportRelations && (
        <DialogImportTypesRelations
          open={openImportRelations}
          onClose={() => setOpenImportRelations(false)}
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}

      {openProxyDialog && (
        <DialogElementTypesGroupToProxies
          open={openProxyDialog}
          onClose={() => setOpenProxyDialog(false)}
          scene={scene}
        />
      )}

      {openImportDialog && (
        <DialogImportElementTypesFromExcel
          open={openImportDialog}
          onClose={() => setOpenImportDialog(false)}
          scene={scene}
        />
      )}
      {openDialogCleanElementTypesGroup && (
        <DialogCleanElementTypesGroup
          group={selectedGroup}
          scene={scene}
          open={openDialogCleanElementTypesGroup}
          onClose={handleCloseDialogCleanElementTypesGroup}
        />
      )}
      {openDialogImportTypesFromPdf && (
        <DialogExtractElementTypes
          editorPdf={caplaEditor.editorPdf}
          pdfModel={selectedPdfModel}
          scene={scene}
          open={openDialogImportTypesFromPdf}
          onClose={() => setOpenDialogImportTypesFromPdf(false)}
        />
      )}
      <DialogToRessources
        open={openDialogToRessources}
        onClose={() => setOpenDialogToRessources(false)}
      />
    </>
  );
}
