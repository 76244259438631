import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function HeaderTitleCloseGeneric({title, onClose}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
      }}
    >
      <Typography>{title}</Typography>
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </Box>
  );
}
