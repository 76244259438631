import {useDispatch, useSelector} from "react-redux";

import {
  ToggleButton,
  Tooltip,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import {Adjust as Snapping} from "@mui/icons-material";

import {setSnappingMode} from "Features/viewer3D/viewer3DSlice";

export default function ToggleSnapping({editor3d, variant = "toggleButton"}) {
  const dispatch = useDispatch();

  // strings

  const snappingS = "Snapping";

  // data

  const checked = useSelector((state) => state.viewer3D.snappingMode);

  // handlers

  const handleChange = () => {
    dispatch(setSnappingMode(!checked));
    if (editor3d) editor3d.snappingMode = !checked;
    //editorPdf.setSnapIsEnabled(!checked);
  };

  return (
    <Box>
      {variant === "toggleButton" && (
        <Tooltip title={snappingS}>
          <ToggleButton
            value="SNAPPING"
            selected={checked}
            onChange={handleChange}
            aria-label="module"
            sx={{
              color: "common.white",
              width: 30,
              height: 30,
              border: "none",
              "&.Mui-selected": {
                bgcolor: "common.darkGrey",
                "&:hover": {
                  bgcolor: "common.darkGrey",
                },
              },
            }}
          >
            <Snapping fontSize="small" />
          </ToggleButton>
        </Tooltip>
      )}

      {variant === "checkbox" && (
        <FormControlLabel
          control={
            <Checkbox size="small" checked={checked} onChange={handleChange} />
          }
          label={<Typography sx={{fontSize: 13}}>{snappingS}</Typography>}
        />
      )}
    </Box>
  );
}
