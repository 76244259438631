import {useSelector} from "react-redux";

export default function useIssuesetsInScope() {
  const ids = useSelector((s) => s.issues.issuesetsInScope);
  const listings = useSelector((s) => s.listings.data);

  const sets = listings.filter((l) => ids.includes(l?.id));

  const idsHash = ids.join("-");

  return [sets, ids, idsHash];
}
