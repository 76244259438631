import {Box, Typography, Paper} from "@mui/material";
import IconElementType from "./IconElementType";
import {ArrowDropDown as Down} from "@mui/icons-material";

export default function ContainerElementType({elementType, onClick, variant}) {
  //helpers

  const label = `[${elementType?.group}] n° ${elementType?.num}`;

  const clickable = Boolean(onClick);

  // handlers

  function handleClick() {
    if (clickable) onClick(elementType);
  }

  return (
    <Box>
      {variant !== "compact" && (
        <Box sx={{width: 1, p: 0.5, bgcolor: "background.default"}}>
          <Typography
            sx={{fontSize: 12, fontStyle: "italic"}}
            color="text.secondary"
          >
            {label}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: variant === "compact" ? 1 : 2,
          px: 2,
        }}
      >
        <Paper
          elevation={12}
          sx={{
            width: 1,
            p: variant === "compact" ? 0.5 : 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            ...(clickable && {cursor: "pointer"}),
          }}
          onClick={handleClick}
        >
          <Box sx={{minWidth: 0, display: "flex", alignItems: "center"}}>
            <IconElementType {...elementType} />
            <Typography variant="body2" sx={{ml: 1}}>
              {elementType?.name}
            </Typography>
          </Box>
          {clickable ? <Down color="action" /> : <Box />}
        </Paper>
      </Box>
      {elementType?.description && variant !== "compact" && (
        <Box sx={{p: 1, pt: 0}}>
          <Typography
            color="text.secondary"
            sx={{fontSize: 12, whiteSpace: "pre"}}
          >
            {elementType?.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
