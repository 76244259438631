import {createTheme} from "@mui/material/styles";
// import {blue, grey, red} from "@mui/material/colors";
import {red} from "@mui/material/colors";
import {color} from "@mui/system";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 0,
        },
      },
    },
  },
  typography: {fontSize: 13},
  palette: {
    primary: {
      main: "#BC057F", //dark pink
      flash: "#F52585",
      darkest: "#410d65",
      lightest: "#faeef6",
    },
    secondary: {
      main: "#277CEA", // blue
    },
    background: {
      //default: "#ffffff",
      default: "#F4F4F8",
      dark: "#F5F5F5",
    },
    stabilo: {
      green: "#00c87a",
      blue: "#3cbefc",
      purple: "#9d64e2",
      pink: "#f447d1",
      yellow: "#fdff5b",
      orange: "#f8bb00",
    },
    excel: {
      bgTitle1: "#A6A6A6",
      bgTitle2: "#D9D9D9",
      bgQty: "#FFF2CC",
      colorDetail: "#808080",
      colorSubDetail: "#808080",
      colorIsAdded: "#277CEA",
      colorId: "#BFBFBF",
      colorDescription: "#808080",
      colorComment: "#FF0000",
      colorNomenclature: "#808080",
    },
    common: {
      appBarBackground: "#F4F4F8",
      caplaBlack: "#292A2D",
      sideMenu: "#F4F4F8",
      appTitle: "#277CEA",
      pageHeaderBackground: "#ffffff",

      bgTitle1: "#bbbbc0",
      bgTitle2: "#F4F4F8",
      colorDetail: "#777777",

      leftPanel: "#410d65",

      borderGrey: "#E2E3E7",
      lightGrey: "#F4F4F8",
      grey: "#e2e3e7",
      //darkGrey: "#3D3D3E",
      darkGrey: "#565663",
      darkPurple: "#410d65",
      purple: "#9901FF",
      yellow: "#FAB41F",
      yellowDark: "#BE9437",

      lightPink: "#F97CB5",

      green: "#1DB100",
      //red: "#EE220C",
      red: red[500],

      flashGreen: "#6EFA25",

      listingBackground: "#F4F4F8",
      selectedListItemBackground: "#ffffff",
      listingSectionBackground: "#F4F4F8",
      noteSectionBackground: "#ffffff",
      autocompleteSelectionBackground: "#F4F4F8",
      actionBackground: "#F4F4F8",
      detailSectionsBackground: "#ffffff",
    },
  },
});

export default theme;
