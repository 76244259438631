import {Dialog, DialogContent, DialogActions, Button} from "@mui/material";
import {Login} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "authConfig";
export default function SignInDialog({open, onClose}) {
  const {t} = useTranslation("auth");
  const {instance} = useMsal();

  // strings

  const descriptionString = t("auth:dialog.description");
  const buttonString = t("auth:dialog.button");

  // handlers

  const handleLogin = () => {
    try {
      if (window.parent !== window) {
        instance.loginPopup(loginRequest);
      } else {
        //instance.loginRedirect(loginRequest);
        instance.loginPopup(loginRequest);
      }
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>{descriptionString}</DialogContent>
      <DialogActions>
        <Button startIcon={<Login />} onClick={handleLogin}>
          {buttonString}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
