import {nanoid} from "@reduxjs/toolkit";

import {Euler, Object3D, Quaternion, Vector2, Vector3, ShapeUtils} from "three";

import {
  updateModel,
  addTempAnnotation,
  updateTempAnnotation,
  removeTempAnnotation,
  setTempAnnotations,
  setClickedObject,
  updateModelNotAsync,
} from "Features/viewer3D/viewer3DSlice";

import {
  setSelectedZoneId,
  setLastZoneId,
  setTempZoneToCreate,
} from "Features/zones/zonesSlice";

import {setTempElementTypeFromPdf} from "Features/elementTypor/elementTyporSlice";

import Color from "color";

import MeasurementsPdfManager from "Features/measurements/js/MeasurementsPdfManager";
import {getImageSize, loadImageAsync} from "Features/images/imageUtils";

export default class AnnotationsManager {
  drawingZone; // use to trigger action when zone annotation is added (update state)
  creatingZone; // (trigger before the drawing zone, to discriminate type creation.)
  creatingType; // use to trigger action when adding one elementType (update state)

  isDeletingZone; // use to prevent pdfModel update when deleting annotations to hide them.

  isDrawingElement; // used to hide element

  hiddenAnnotation; // annotation being hovered when drawing

  addingFirstZone; // use to trigger create image model once the annotation is added.
  annotationEntities;
  isAddingCut;

  typeArea; // used when one annotation is added. Set when clicking on one type.
  typeStrokeM;

  selectedAnnotationId; // used to select zone annotations.

  constructor({webViewer, caplaEditor}) {
    this.webViewer = webViewer;
    this.caplaEditor = caplaEditor;

    this.annotationEntities = [];
    this.scale = 50; // by default 1cm = 0.5 m
    this.dpi = 72;
    this.meterByInch = 0.0254;

    this.mpd = (this.meterByInch * this.scale) / this.dpi; // meter per dot => used to convert pdf distance to bimbox distance

    this.setDefaultStyles();

    this.measurementsPdfManager = new MeasurementsPdfManager({
      annotationsManager: this,
    });

    this.tempAnnotationToCreateZone = null; //  annotation that will be used to create the zone. Creation process in 2 steps : draw rectangle annotation => dilaog => creation.
    this.tempAnnotationToCreateType = null;

    this.differenceAnnotation = null; // annotation used to display difference between new and old annotation
    this.oldPdfAnnotation = null; // old file to compare
  }

  setCreatingType = (bool) => (this.creatingType = bool);
  setCreatingZone = (bool) => {
    this.creatingZone = bool;
  };

  setIsDeletingZone = (bool) => (this.isDeletingZone = true);

  setSelectedAnnotationId = (id) => (this.selectedAnnotationId = id);

  setIsDrawingElement(bool) {
    this.isDrawingElement = bool;
  }
  setIsAddingCut(bool) {
    this.isAddingCut = bool;
  }
  setTypeArea(area) {
    this.typeArea = area;
  }
  setTypeStrokeM(strokeM) {
    this.typeStrokeM = strokeM;
  }
  setScale(scale) {
    this.scale = scale;
    this.mpd = (this.meterByInch * scale) / this.dpi;
  }

  setDefaultStyles() {
    const {Annotations, documentViewer} = this.webViewer.Core;
    const flashPink = new Annotations.Color(245, 37, 133);
    const transparent = new Annotations.Color(0, 0, 0, 0);
    const blue = new Annotations.Color(39, 124, 234); // blue
    const cyan = new Annotations.Color(0, 255, 254); // cyan
    const blueT = new Annotations.Color(39, 124, 234, 0.8); // blue
    const cyanT = new Annotations.Color(0, 255, 254, 0.8); // cyan

    // zones
    documentViewer
      .getTool(this.webViewer.Core.Tools.ToolNames.RECTANGLE)
      .setStyles({
        StrokeThickness: 5,
        StrokeColor: flashPink,
        FillColor: transparent,
        Opacity: 1,
      });
    // perimeter
    documentViewer
      .getTool(this.webViewer.Core.Tools.ToolNames.PERIMETER_MEASUREMENT)
      .setStyles({
        StrokeThickness: 2,
        StrokeColor: blue,
        FillColor: transparent,
        Opacity: 1,
      });
    documentViewer
      .getTool(this.webViewer.Core.Tools.ToolNames.PERIMETER_MEASUREMENT2)
      .setStyles({
        StrokeThickness: 0.2 / this.mpd,
        StrokeColor: cyan,
        FillColor: transparent,
        Opacity: 1,
      });
    // area
    documentViewer
      .getTool(this.webViewer.Core.Tools.ToolNames.AREA_MEASUREMENT)
      .setStyles({
        StrokeThickness: 2,
        StrokeColor: blue,
        FillColor: blueT,
        Opacity: 1,
      });
    documentViewer
      .getTool(this.webViewer.Core.Tools.ToolNames.AREA_MEASUREMENT2)
      .setStyles({
        StrokeThickness: 2,
        StrokeColor: cyan,
        FillColor: cyanT,
        Opacity: 1,
      });
  }

  getLightDarkGreyColors(color) {
    const colorL = Color(color);
    const colorD = Color(color);
    const colorG = Color(color);
    const _light = colorL.lighten(0.5).rgb().object();
    const _dark = colorD.darken(0.5).rgb().object();
    const _grey = colorG.grayscale().rgb().object();
    const light = {
      r: Math.floor(_light.r),
      g: Math.floor(_light.g),
      b: Math.floor(_light.b),
    };
    const dark = {
      r: Math.floor(_dark.r),
      g: Math.floor(_dark.g),
      b: Math.floor(_dark.b),
    };
    const grey = {
      r: Math.floor(_grey.r),
      g: Math.floor(_grey.g),
      b: Math.floor(_grey.b),
    };
    return {light, dark, grey};
  }

  setMeasurementStyles({color, drawingType, strokeM}) {
    const {Annotations, documentViewer} = this.webViewer.Core;
    const [r, g, b] = color;
    const {light, dark, grey} = this.getLightDarkGreyColors(color);
    const mainColor = new Annotations.Color(r, g, b);
    const mainColorT = new Annotations.Color(r, g, b, 0.8);
    const lightColor = new Annotations.Color(light.r, light.g, light.b);
    const lightColorT = new Annotations.Color(light.r, light.g, light.b, 0.8);
    const darkColor = new Annotations.Color(dark.r, dark.g, dark.b);
    const darkColorT = new Annotations.Color(dark.r, dark.g, dark.b, 0.8);
    const greyColor = new Annotations.Color(grey.r, grey.g, grey.b);
    const greyColorT = new Annotations.Color(grey.r, grey.g, grey.b, 0.8);

    this.webViewer.Core.Tools.Tool.disableAnnotationHoverCursors();

    if (drawingType === "POINT") {
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.COUNT_MEASUREMENT)
        .setStyles({
          StrokeThickness: 1,
          StrokeColor: mainColor,
          FillColor: mainColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.COUNT_MEASUREMENT2)
        .setStyles({
          StrokeThickness: 1,
          StrokeColor: lightColor,
          FillColor: lightColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.COUNT_MEASUREMENT3)
        .setStyles({
          StrokeThickness: 1,
          StrokeColor: darkColor,
          FillColor: darkColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.COUNT_MEASUREMENT4)
        .setStyles({
          StrokeThickness: 1,
          StrokeColor: greyColor,
          FillColor: greyColorT,
        });
    }

    if (drawingType === "POLYLINE") {
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.PERIMETER_MEASUREMENT)
        .setStyles({
          StrokeThickness: strokeM / this.mpd,
          StrokeColor: mainColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.PERIMETER_MEASUREMENT2)
        .setStyles({
          StrokeThickness: strokeM / this.mpd,
          StrokeColor: lightColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.PERIMETER_MEASUREMENT3)
        .setStyles({
          StrokeThickness: strokeM / this.mpd,
          StrokeColor: darkColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.PERIMETER_MEASUREMENT4)
        .setStyles({
          StrokeThickness: strokeM / this.mpd,
          StrokeColor: greyColorT,
        });
    }
    if (drawingType === "POLYGON") {
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.AREA_MEASUREMENT)
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: mainColor,
          FillColor: mainColorT,
        });

      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.AREA_MEASUREMENT2)
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: lightColor,
          FillColor: lightColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.AREA_MEASUREMENT3)
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: darkColor,
          FillColor: darkColorT,
        });
      documentViewer
        .getTool(this.webViewer.Core.Tools.ToolNames.AREA_MEASUREMENT4)
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: greyColor,
          FillColor: greyColorT,
        });
      documentViewer
        .getTool(
          this.webViewer.Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT
        )
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: mainColor,
          FillColor: mainColorT,
        });
      documentViewer
        .getTool(
          this.webViewer.Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT2
        )
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: lightColor,
          FillColor: lightColorT,
        });
      documentViewer
        .getTool(
          this.webViewer.Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT3
        )
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: darkColor,
          FillColor: darkColorT,
        });
      documentViewer
        .getTool(
          this.webViewer.Core.Tools.ToolNames.RECTANGULAR_AREA_MEASUREMENT4
        )
        .setStyles({
          StrokeThickness: 0.5,
          StrokeColor: greyColor,
          FillColor: greyColorT,
        });
    }
  }

  // annotations event handlers - Select / Unselect

  // handleAnnotationSelected = async (modelId, annotation) => {
  //   console.time("haaaaaaaaaaaa");
  //   console.log(
  //     "selectAnnotation",
  //     annotation,
  //     this.parseAnnotation(annotation)
  //   );
  //   const type = await annotation.getCustomData("type");
  //   console.log("haaaaaaaaaaaa", type);
  //   if (type !== "MEASUREMENT" && type !== "ISSUE") {
  //     const annotationEntity = this.getAnnotationEntity(annotation);
  //     if (!annotationEntity?.temp) {
  //       const elementAnnotation =
  //         this.annotationEntityToModelElement(annotationEntity);
  //       this.caplaEditor?.dispatch(
  //         setClickedObject({
  //           type: "PDF_ANNOTATION",
  //           modelId,
  //           name: elementAnnotation?.name,
  //           elementAnnotation,
  //         })
  //       );
  //     }
  //   }

  //   if (type === "ZONE") {
  //     const annotationId = await annotation.getCustomData("annotationId");
  //     console.log("dispatchSelectZoneId", annotationId);
  //     this.caplaEditor?.dispatch(setSelectedZoneId(annotationId));
  //   }
  //   console.timeEnd("haaaaaaaaaaaa");
  // };

  // handleAnnotationUnselected = (annotation) => {
  //   this.caplaEditor?.dispatch(setClickedObject({}));
  // };

  // annotations event handlers - Add

  handleTempAnnotationAdded = ({
    modelId,
    annotation,
    caplaModelId,
    codeName,
  }) => {
    console.log("handleTempAnnotationAdded");
    const countTools = [
      "AnnotationCreateCountMeasurement",
      "AnnotationCreateCountMeasurement2",
      "AnnotationCreateCountMeasurement3",
      "AnnotationCreateCountMeasurement4",
    ];
    const polylineTools = [
      "AnnotationCreatePerimeterMeasurement",
      "AnnotationCreatePerimeterMeasurement2",
      "AnnotationCreatePerimeterMeasurement3",
      "AnnotationCreatePerimeterMeasurement4",
    ];
    const polygonTools = [
      "AnnotationCreateAreaMeasurement",
      "AnnotationCreateAreaMeasurement2",
      "AnnotationCreateAreaMeasurement3",
      "AnnotationCreateAreaMeasurement4",
    ];
    const rectTools = [
      "AnnotationCreateRectangularAreaMeasurement",
      "AnnotationCreateRectangularAreaMeasurement2",
      "AnnotationCreateRectangularAreaMeasurement3",
      "AnnotationCreateRectangularAreaMeasurement4",
    ];

    // helpers
    const isPoint = countTools.includes(annotation.ToolName);
    const isPolyline = polylineTools.includes(annotation.ToolName);
    const isPolygon = polygonTools.includes(annotation.ToolName);
    const isRect = rectTools.includes(annotation.ToolName);

    // label added
    const annotationType = annotation.elementName;
    if (annotationType === "freetext") return;

    // measurement added
    let annotationItem = annotation;
    if (isPoint) annotationItem = this.modifyCountAnnotation(annotation);

    let drawingType;
    if (isPoint) drawingType = "POINT";
    if (isPolyline) drawingType = "POLYLINE";
    if (isPolygon) drawingType = "POLYGON";
    if (isRect) drawingType = "RECT";

    if (isPoint || isPolyline || isPolygon || isRect) {
      const annotationId = nanoid();

      annotationItem.Opacity = 0.8;

      annotationItem.setCustomData("annotationId", annotationId);

      const newAnnotationEntity = {
        annotationId,
        modelId,
        type: "ELEMENT",
        drawingType,
        annotationItem,
        temp: codeName ? false : true,
        codeName,
        caplaModelId,
      };

      this.annotationEntities = [
        ...this.annotationEntities,
        newAnnotationEntity,
      ];

      // new temp annotation.
      const newAnnotation =
        this.annotationEntityToModelElement(newAnnotationEntity);
      this.caplaEditor?.dispatch(addTempAnnotation(newAnnotation));

      if (codeName) this.dispatchUpdateModelWithElements({modelId}); // update pdf model if is updated...
    }
  };

  handleAnnotationChanged = async ({modelId, annotation}) => {
    console.log("HANDLE ANNOT CHANGE 12");
    const annotationEntity = this.getAnnotationEntityFromAnnotation(annotation);
    const newAnnotation = this.annotationEntityToModelElement(annotationEntity);

    if (annotationEntity.temp) {
      this.caplaEditor?.dispatch(updateTempAnnotation(newAnnotation));
    } else {
      await this.dispatchUpdateModelWithElements({modelId});
    }
  };

  handleAnnotationDeleted = async ({modelId, annotation}) => {
    console.log("HANDLE ANNOT DELETE 12");
    const annotationEntity = this.getAnnotationEntityFromAnnotation(annotation);
    if (annotationEntity) {
      // it is the case when deleting element annotations.
      const deletedAnnotation =
        this.annotationEntityToModelElement(annotationEntity);
      if (annotationEntity.temp) {
        this.caplaEditor?.dispatch(removeTempAnnotation(deletedAnnotation));
      }
      this.annotationEntities = this.annotationEntities.filter(
        (e) => e.annotationId !== annotationEntity.annotationId
      );
      await this.dispatchUpdateModelWithElements({modelId});
    }
  };

  modifyCountAnnotation(annotation) {
    const {Annotations, annotationManager} = this.webViewer.Core;

    const dimM = 1;
    const dim = dimM / this.mpd;
    const x = annotation.X;
    const y = annotation.Y;
    const annot = new Annotations.RectangleAnnotation();
    annot.X = x - dim / 2;
    annot.Y = y - dim / 2;
    annot.Width = dim;
    annot.Height = dim;

    const s = annotation.StrokeColor;
    annot.StrokeColor = new Annotations.Color(s.R, s.G, s.B, s.A);
    annot.FillColor = new Annotations.Color(s.R, s.G, s.B, 0.8);
    console.log("delete16");
    annotationManager.deleteAnnotations([annotation]);
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
    return annot;
  }

  // edited Annotations

  editElementAnnotation({caplaModelId, codeName}) {
    const {annotationManager} = this.webViewer.Core;

    const annotationEntities = this.annotationEntities.filter(
      (e) => e.codeName === codeName && e.caplaModelId === caplaModelId
    );
    const shapeEntities = annotationEntities.filter(
      (e) => e.type === "ELEMENT"
    );

    shapeEntities.forEach((e) => {
      this.unlockAnnotation(e.annotationId);
      this.unlockAnnotationDeletion(e.annotationId);
      annotationManager.redrawAnnotation(e.annotationItem);
    });

    const annotations = annotationEntities.map((e) =>
      this.annotationEntityToModelElement(e)
    );

    this.caplaEditor?.dispatch(setTempAnnotations(annotations));
  }

  uneditElementAnnotation({caplaModelId, codeName}) {
    const annotationEntities = this.annotationEntities.filter(
      (e) => e.codeName === codeName && e.caplaModelId === caplaModelId
    );
    const shapeEntities = annotationEntities.filter(
      (e) => e.type === "ELEMENT"
    );

    shapeEntities.forEach((e) => {
      this.lockAnnotation(e.annotationId);
      this.lockAnnotationDeletion(e.annotationId);
    });
    this.caplaEditor?.dispatch(setTempAnnotations([]));
  }

  deleteElementAnnotations({modelId, caplaModelId, codeName}) {
    const {annotationManager} = this.webViewer.Core;

    const annotationEntities = this.annotationEntities.filter(
      (e) => e.codeName === codeName && e.caplaModelId === caplaModelId
    );

    this.annotationEntities = this.annotationEntities.filter(
      (e) => e.codeName !== codeName || e.caplaModelId !== caplaModelId
    );

    annotationEntities.forEach((e) => {
      e.annotationItem.NoDelete = false;
      console.log("delete17");
      annotationManager.deleteAnnotation(e.annotationItem);
    });

    this.dispatchUpdateModelWithElements({modelId});
    this.caplaEditor?.dispatch(setTempAnnotations([]));
  }

  unlistAnnotations() {
    console.log("unlistAnnotations");
    this.annotationEntities.forEach((e) => {
      e.annotationItem.Listable = false;
      e.annotationItem.IsHoverable = false;
    });
  }
  listAnnotations() {
    this.annotationEntities.forEach((e) => {
      e.annotationItem.Listable = true;
      e.annotationItem.IsHoverable = true;
    });
  }

  // temp Annotations

  saveTempAnnotations({
    caplaModelId,
    modelId,
    typeCode,
    codeName,
    subtitle,
    quantities,
    geometry,
    num,
  }) {
    // update annotation entities
    this.annotationEntities = this.annotationEntities.map((e) => {
      if (e.modelId === modelId && e.temp === true) {
        const annot = e.annotationItem;
        annot.setCustomData("type", "CAPLA_ANNOT");
        annot.setCustomData("caplaModelId", caplaModelId);
        annot.setCustomData("codeName", codeName);
        annot.setCustomData("typeCode", typeCode);
        annot.setCustomData("drawingType", e.drawingType);
        return {...e, caplaModelId, codeName, typeCode};
      } else {
        return e;
      }
    });

    // readonly
    this.annotationEntities.forEach((e) => {
      if (e.modelId === modelId && e.temp === true) {
        e.annotationItem.ReadOnly = true;
      }
    });

    // add title
    const annotationEntities = this.annotationEntities.filter(
      (e) => e.modelId === modelId && e.temp === true
    );
    const annotation = annotationEntities[0].annotationItem;
    let x = annotation.getX();
    let y = annotation.getY();

    if (annotationEntities[0].drawingType === "POLYLINE") {
      const A = annotation.getPathPoint(0);
      const B = annotation.getPathPoint(1);
      x = (A.x + B.x) / 2;
      y = (A.y + B.y) / 2;
    } else if (
      annotationEntities[0].drawingType === "POLYGON" ||
      annotationEntities[0].drawingType === "RECT"
    ) {
      const C = annotation.getRect().getCenter();
      x = C.x;
      y = C.y;
    }

    const pageNumber = annotation.PageNumber;
    const title = annotationEntities[0].codeName;
    const annotationsIds = annotationEntities.map((e) => e.annotationId);

    this.createElementTitle({
      x,
      y,
      title,
      subtitle,
      pageNumber,
      caplaModelId,
      codeName,
      typeCode,
      annotationsIds,
      quantities,
      geometry,
      num,
    });

    // update pdf model
    this.dispatchUpdateModelWithElements({modelId});

    // temp = false
    this.annotationEntities = this.annotationEntities.map((e) => ({
      ...e,
      temp: false,
    }));

    this.caplaEditor?.dispatch(setTempAnnotations([]));
  }

  updateLabelAnnotation({
    // caplaModelId,
    codeName,
    subtitle,
    quantities,
    geometry,
  }) {
    const {annotationManager} = this.webViewer.Core;

    const labelAnnotationEntity = this.annotationEntities.find(
      (e) => e.type === "LABEL" && e.codeName === codeName
    );
    console.log(
      "labelAnnotation",
      labelAnnotationEntity,
      codeName,
      this.annotationEntities
    );
    const annot = labelAnnotationEntity.annotationItem;
    const content = `${codeName} \n ${subtitle}`;
    annot.setContents(content);
    annot.setCustomData("geometry", geometry);
    annot.setCustomData("quantities", quantities);

    annotationManager.redrawAnnotation(annot);
  }

  clearTempAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    this.annotationEntities.forEach((e) => {
      if (e.temp === true) {
        console.log("delete18");
        annotationManager.deleteAnnotations([e.annotationItem]);
      }
    });
    this.annotationEntities = this.annotationEntities.filter((e) => !e.temp);
    // state
    this.caplaEditor?.dispatch(setTempAnnotations([]));
  }

  // annotation quantities

  getABLength(A, B) {
    return Math.sqrt((B.x - A.x) ** 2 + (B.y - A.y) ** 2);
  }
  getPathPerimeter(path) {
    const perimeter = path.reduce((ac, cur, index) => {
      if (index === 0) return ac;
      const dist = this.getABLength(path[index], path[index - 1]);
      return ac + dist;
    }, 0);
    return perimeter * this.mpd;
  }
  getPathArea(path) {
    const points = [];
    path.forEach((point) => {
      points.push(new Vector2(point.x, point.y));
    });
    const area = Math.abs(ShapeUtils.area(points));
    return area * this.mpd ** 2;
  }

  getAnnotationQuantities(annotation) {
    let count, perimeter, area;

    switch (annotation.elementName) {
      case "polyline": {
        perimeter = this.getPathPerimeter(annotation.getPath());
        break;
      }
      case "polygon": {
        perimeter = this.getPathPerimeter(annotation.getPath());
        area = this.getPathArea(annotation.getPath());
        break;
      }
    }
    return {count, perimeter, area};
  }

  getAnnotationPath(annotation) {
    if (!annotation.getPath) return;
    return annotation.getPath().map((point) => [point.x, point.y]);
  }

  // addCodeNameLabel

  addLabelToAnnotation(annotation, label) {
    const {
      Annotations,
      annotationManager,
      documentViewer,
      Math: PdfMath,
    } = this.webViewer.Core;

    const pageNumber = annotation.PageNumber;

    const autoSizeTypes = Annotations.FreeTextAnnotation.AutoSizeTypes;
    const annot = new Annotations.FreeTextAnnotation();

    annot.setAutoSizeType(autoSizeTypes.AUTO);
    annot.TextAlign = "center";
    annot.PageNumber = pageNumber;

    annot.FontSize = "12pt";
    annot.FillColor = new Annotations.Color(20, 20, 22);
    annot.TextColor = new Annotations.Color(255, 255, 255);

    //const content = `${title} \n ${subtitle}`;
    const content = label ?? "-";

    annot.setContents(content);
    annot.setPadding(new Annotations.Rect(5, 5, 5, 5));

    const doc = documentViewer.getDocument();
    const pageInfo = doc.getPageInfo(pageNumber);
    const pageMatrix = doc.getPageMatrix(pageNumber);
    const pageRotation = doc.getPageRotation(pageNumber);
    const angle = -(pageRotation * Math.PI) / 180;

    //annot.Rotation = pageRotation;

    annot.fitText(pageInfo, pageMatrix, pageRotation);
    const width = annot.Width * 1.05;
    const height = annot.Height * 1.05;

    const annotationId = nanoid();
    annot.setCustomData("annotationId", annotationId);

    annot.rotate(angle);
    annot.setY(annotation.Y);
    annot.setX(annotation.X);
    annot.Width = height;
    annot.Height = width;
    annot.isContentEditPlaceholder();

    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
  }

  // element title annotations

  async createElementTitle({
    title,
    subtitle,
    x,
    y,
    pageNumber,
    caplaModelId,
    codeName,
    typeCode,
    quantities,
    geometry,
    annotationsIds,
    num,
  }) {
    const {
      Annotations,
      annotationManager,
      documentViewer,
      Math: PdfMath,
    } = this.webViewer.Core;

    console.log("addElementTitle at", x, y);
    const autoSizeTypes = Annotations.FreeTextAnnotation.AutoSizeTypes;

    const annot = new Annotations.FreeTextAnnotation();

    annot.setAutoSizeType(autoSizeTypes.AUTO);
    annot.TextAlign = "center";
    //annot.TextVerticalAlign = "center";

    annot.PageNumber = pageNumber;

    annot.FontSize = "16pt";

    annot.FillColor = new Annotations.Color(20, 20, 22);
    annot.TextColor = new Annotations.Color(255, 255, 255);

    const content = `${title} \n ${subtitle}`;
    annot.setContents(content);
    annot.setPadding(new Annotations.Rect(5, 5, 5, 5));

    const doc = documentViewer.getDocument();
    const pageInfo = doc.getPageInfo(pageNumber);
    const pageMatrix = doc.getPageMatrix(pageNumber);
    const pageRotation = doc.getPageRotation(pageNumber);
    const angle = -(pageRotation * Math.PI) / 180;

    //annot.Rotation = pageRotation;

    annot.fitText(pageInfo, pageMatrix, pageRotation);
    const width = annot.Width * 1.05;
    const height = annot.Height * 1.05;

    const annotationId = nanoid();
    annot.setCustomData("annotationId", annotationId);

    annot.rotate(angle);
    annot.setY(y);
    annot.setX(x);
    annot.Width = height;
    annot.Height = width;
    annot.isContentEditPlaceholder();

    annot.NoDelete = true;

    annotationManager.addAnnotation(annot, {autoFocus: false});
    annotationManager.redrawAnnotation(annot);

    // custom data for pdf export

    annot.setCustomData("type", "CAPLA_ELT");
    annot.setCustomData("caplaModelId", caplaModelId);
    annot.setCustomData("codeName", codeName);
    annot.setCustomData("typeCode", typeCode);
    annot.setCustomData("num", num.toString());
    if (annotationsIds) annot.setCustomData("annotationsIds", annotationsIds);
    if (quantities)
      annot.setCustomData("quantities", JSON.stringify(quantities));
    if (geometry) annot.setCustomData("geometry", JSON.stringify(geometry));

    // add the item to the manager to hide / show

    const annotationEntity = {
      type: "LABEL",
      caplaModelId,
      codeName,
      annotationItem: annot,
    };
    this.annotationEntities.push(annotationEntity);
  }

  // zones annotations

  createFirstZone({modelId}) {
    console.log("create first zone");
    this.addingFirstZone = true;
    const modelZones = this.annotationEntities.filter(
      (e) => e.modelId === modelId && e.type === "ZONE"
    );
    const noZones = modelZones.length === 0;
    if (noZones) {
      const pageBounds = this.getPageBounds(1);
      this.createZoneAnnotation({
        pageBounds,
        modelId,
        name: "Zone 1",
        scale: this.mpd,
      });
    }
  }

  enhanceZoneAnnotations(pageNumber) {
    const {Annotations, annotationManager} = this.webViewer.Core;

    const strokeColorFlash = new Annotations.Color(245, 37, 133); // pink
    const strokeColorGrey = new Annotations.Color(100, 100, 100); // grey

    this.annotationEntities
      .filter((e) => e.type === "ZONE")
      .forEach((e) => {
        if (e.annotationItem.PageNumber === pageNumber) {
          e.annotationItem.StrokeColor = strokeColorFlash;
        } else {
          e.annotationItem.StrokeColor = strokeColorGrey;
        }
        console.log("debug 2405 redraw");
        annotationManager.redrawAnnotation(e.annotationItem);
      });
  }

  createZoneAnnotation({pageBounds, modelId, name, scale}) {
    console.log("create zone annotation 122");
    const {Annotations, annotationManager} = this.webViewer.Core;
    const {pageNumber, x1, y1, x2, y2} = pageBounds;
    const width = x2 - x1;
    const height = y2 - y1;
    const strokeColor = new Annotations.Color(245, 37, 133);
    const annotationId = nanoid();

    const rectangleAnnot = new Annotations.RectangleAnnotation();
    rectangleAnnot.PageNumber = pageNumber;
    // values are in page coordinates with (0, 0) in the top left
    rectangleAnnot.X = x1;
    rectangleAnnot.Y = y1;
    rectangleAnnot.Width = width;
    rectangleAnnot.Height = height;
    rectangleAnnot.Author = annotationManager.getCurrentUser();
    rectangleAnnot.StrokeColor = strokeColor;
    rectangleAnnot.StrokeThickness = 5;
    rectangleAnnot.setCustomData("annotationId", annotationId);
    rectangleAnnot.setCustomData("type", "ZONE");

    const annotationEntity = {
      annotationId,
      annotationItem: rectangleAnnot,
      modelId,
      scale,
      name,
      type: "ZONE",
      position: {x: 0, y: 0, z: 0},
      rotation: {x: -Math.PI / 2, y: 0, z: 0},
      centerInPage: this.getAnnotationCenter(rectangleAnnot), // used to compute translation when annotation size updates.
    };

    this.annotationEntities.push(annotationEntity);
    console.log(
      "new annotation entities",
      this.annotationEntities.map((e) => e.annotationId)
    );

    this.dispatchUpdateModel({modelId});

    annotationManager.addAnnotation(rectangleAnnot);
    // need to draw the annotation otherwise it won't show up until the page is refreshed
    annotationManager.redrawAnnotation(rectangleAnnot);
  }

  getNewZoneDefaultName({annotation, modelId}) {
    const pageNumber = annotation.PageNumber;
    const entities = this.annotationEntities.filter(
      (e) => e.modelId === modelId && e.annotationItem.PageNumber === pageNumber
    );
    return `Zone ${entities.length + 1}`;
  }

  async getTempTypeToCreateFromAnnotation(annotation) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const pageNumber = annotation.PageNumber;
    const pageRotation = docViewer.getCompleteRotation(pageNumber);
    const file = await this.getAnnotationImageFileFromAnnotation(annotation, {
      withFullZoom: true,
    });
    const imageSize = await getImageSize({file});
    const tempElementTypeFromPdf = {
      pdfModelId: this.caplaEditor?.editorPdf.modelId,
      centerInPage: this.getAnnotationCenter(annotation),
      pageNumber,
      pageRotation,
      x: annotation.X,
      y: annotation.Y,
      width: annotation.Width,
      height: annotation.Height,
      fileUrl: URL.createObjectURL(file),
      fileSize: file.size,
      imageSize,
    };
    this.caplaEditor?.dispatch(
      setTempElementTypeFromPdf(tempElementTypeFromPdf)
    );
  }
  async getTempZoneToCreateFromAnnotation(annotation) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const pageNumber = annotation.PageNumber;
    const pageRotation = docViewer.getCompleteRotation(pageNumber);
    const file = await this.getAnnotationImageFileFromAnnotation(annotation, {
      withFullZoom: true,
    });
    const imageSize = await getImageSize({file});
    const tempZone = {
      modelId: this.caplaEditor?.editorPdf.modelId,
      scale: this.mpd,
      centerInPage: this.getAnnotationCenter(annotation),
      pageNumber,
      pageRotation,
      x: annotation.X,
      y: annotation.Y,
      width: annotation.Width,
      height: annotation.Height,
      fileUrl: URL.createObjectURL(file),
      fileSize: file.size,
      imageSize,
    };
    this.caplaEditor?.dispatch(setTempZoneToCreate(tempZone));
  }

  // create zone from rectangle annotation.
  createZoneFromRectangle({annotation, modelId}) {
    const {Annotations, annotationManager} = this.webViewer.Core;
    const strokeColor = new Annotations.Color(100, 100, 100);
    const fillColor = new Annotations.Color(0, 0, 0, 0);
    const annotationId = nanoid();
    const name = this.getNewZoneDefaultName({annotation, modelId});
    annotation.Color = strokeColor;
    annotation.StrokeThickness = 5;
    annotation.FillColor = fillColor;
    annotation.setCustomData("annotationId", annotationId);
    annotation.setCustomData("type", "ZONE");
    annotation.disableRotationControl();
    annotation.NoMove = true;
    annotation.NoResize = true;

    // need to draw the annotation otherwise it won't show up until the page is refreshed
    annotationManager.redrawAnnotation(annotation);

    const annotationEntity = {
      annotationId,
      annotationItem: annotation,
      modelId,
      type: "ZONE",
      position: {x: 0, y: 0, z: 0},
      rotation: {x: -Math.PI / 2, y: 0, z: 0},
      centerInPage: this.getAnnotationCenter(annotation),
      scale: this.mpd,
      name,
    };

    this.annotationEntities.push(annotationEntity);

    this.dispatchUpdateModel({modelId});

    // select
    this.caplaEditor?.dispatch(setSelectedZoneId(annotationId));
    this.caplaEditor?.dispatch(setLastZoneId(annotationId));
    //this.selectAnnotation(annotationId);

    // create entity.
    this.createImageModelFromZoneAnnotation(annotation);

    // end
    this.stopDrawingZone();
  }

  deleteTempAnnotationToCreateZone() {
    const {Annotations, annotationManager} = this.webViewer.Core;
    annotationManager.deleteAnnotation(this.tempAnnotationToCreateZone);
  }
  deleteTempAnnotationToCreateType() {
    console.log("DELETE TEMP ANNOTATION");
    const {Annotations, annotationManager} = this.webViewer.Core;
    annotationManager.deleteAnnotation(this.tempAnnotationToCreateType);
  }

  async createZoneFromTempZone(tempZone, file, options) {
    const {Annotations, annotationManager} = this.webViewer.Core;
    let annotation = this.tempAnnotationToCreateZone;
    console.log("createZoneFromTempZone", annotation);
    if (!annotation) {
      annotation = new Annotations.RectangleAnnotation();
      annotation.PageNumber = tempZone.pageNumber;
      annotation.X = 0;
      annotation.Y = 0;
      annotation.Width = tempZone.width;
      annotation.Height = tempZone.height;
    }

    // update annotation
    const strokeColor = new Annotations.Color(100, 100, 100);
    const fillColor = new Annotations.Color(0, 0, 0, 0);
    const annotationId = tempZone.id;
    annotation.Color = strokeColor;
    annotation.StrokeThickness = 5;
    annotation.FillColor = fillColor;
    annotation.setCustomData("annotationId", annotationId);
    annotation.setCustomData("type", "ZONE");
    annotation.disableRotationControl();
    annotation.NoMove = true;
    annotation.NoResize = true;
    annotationManager.redrawAnnotation(annotation);

    // annotationEntity
    const annotationEntity = {
      annotationId,
      annotationItem: annotation,
      modelId: tempZone.modelId,
      type: "ZONE",
      position: tempZone.position,
      rotation: tempZone.rotation,
      centerInPage: tempZone.centerInPage,
      scale: tempZone.scale,
      name: tempZone.name,
      sectorId: tempZone.sectorId,
      roomId: tempZone.roomId,
      materialId: tempZone.materialId,
      imageSize: tempZone.imageSize,
      d3: tempZone.d3,
    };
    this.annotationEntities.push(annotationEntity);
    //this.dispatchUpdateModel({modelId: tempZone.modelId}); // to update PDF model with zones.Disabled because the update is done later once the imageModelId exist (linkImageModelToAnnotation)

    // select
    this.caplaEditor?.dispatch(setSelectedZoneId(annotationId));
    this.caplaEditor?.dispatch(setLastZoneId(annotationId));

    // create entity.
    await this.createImageModelFromZone(tempZone, file, options);

    // end
    this.stopDrawingZone();
  }

  // loader (from model)

  createAnnotationFromZone(zone) {
    const {Annotations, annotationManager} = this.webViewer.Core;

    const strokeColor = new Annotations.Color(100, 100, 100);
    const annotationId = zone.id;

    const rectangleAnnot = new Annotations.RectangleAnnotation();
    rectangleAnnot.PageNumber = zone.pageNumber;
    // values are in page coordinates with (0, 0) in the top left
    rectangleAnnot.X = zone.x;
    rectangleAnnot.Y = zone.y;
    rectangleAnnot.Width = zone.width;
    rectangleAnnot.Height = zone.height;
    rectangleAnnot.Author = annotationManager.getCurrentUser();
    rectangleAnnot.StrokeColor = strokeColor;
    rectangleAnnot.StrokeThickness = 2;
    rectangleAnnot.setCustomData("annotationId", annotationId);
    rectangleAnnot.setCustomData("type", "ZONE");
    rectangleAnnot.disableRotationControl();
    rectangleAnnot.NoMove = true;
    rectangleAnnot.NoResize = true;

    annotationManager.addAnnotation(rectangleAnnot);

    return rectangleAnnot;
  }

  createAnnotationFromElement(element) {
    if (!element) {
      console.log("warning, undefined element");
      return;
    }
    const {Annotations, annotationManager} = this.webViewer.Core;

    const blue = new Annotations.Color(39, 124, 234); // blue
    const blueT = new Annotations.Color(39, 124, 234, 0.8); // blue

    const annotationId = element.id;

    let annot;
    if (element.annotationType === "polyline") {
      annot = new Annotations.PolylineAnnotation();
      annot.StrokeColor = blue;
      annot.StrokeThickness = 2;
      element.path.forEach((point, index) => {
        annot.setPathPoint(index, point[0], point[1]);
      });
    }
    if (element.annotationType === "polygon") {
      annot = new Annotations.PolygonAnnotation();
      annot.StrokeColor = blue;
      annot.FillColor = blueT;
      annot.StrokeThickness = 2;

      element.path.forEach((point, index) => {
        annot.setPathPoint(index, point[0], point[1]);
      });
    }

    if (element.annotationType === "freetext") {
      annot = new Annotations.FreeTextAnnotation();
    }

    annot.PageNumber = element.pageNumber;
    // values are in page coordinates with (0, 0) in the top left
    annot.X = element.x;
    annot.Y = element.y;

    annot.setCustomData("annotationId", annotationId);

    annot.disableRotationControl();
    annot.ReadOnly = true;

    annotationManager.addAnnotation(annot);

    return annot;
  }

  loadZoneAnnotation(zone, modelId) {
    const {annotationManager} = this.webViewer.Core;

    let annotationEntity = this.findAnnotationEntity(zone.id);
    if (annotationEntity) {
      annotationManager.redrawAnnotation(annotationEntity.annotationItem);
      return;
    }

    const annotation = this.createAnnotationFromZone(zone);

    const props = this.modelZoneToAnnotationEntityProps(zone);
    annotationEntity = {
      modelId,
      annotationItem: annotation,
      centerInPage: this.getAnnotationCenter(annotation),
      ...props,
    };
    annotationManager.redrawAnnotation(annotation);
    this.annotationEntities.push(annotationEntity);

    return annotation;
  }

  loadElementAnnotation(element, modelId) {
    const {annotationManager} = this.webViewer.Core;

    let annotationEntity = this.findAnnotationEntity(element.id);
    if (annotationEntity) {
      annotationManager.redrawAnnotation(annotationEntity.annotationItem);
      return;
    }

    const annotation = this.createAnnotationFromElement(element);
    const props = this.modelElementToAnnotationEntityProps(element);
    annotationEntity = {
      modelId,
      annotationItem: annotation,
      centerInPage: this.getAnnotationCenter(annotation),
      ...props,
    };
    annotationManager.redrawAnnotation(annotation);
    this.annotationEntities.push(annotationEntity);
  }

  loadZoneAnnotations(zones, modelId) {
    if (!zones) return;
    const annots = [];
    zones.forEach((zone) => {
      const annot = this.loadZoneAnnotation(zone, modelId);
      annots.push(annot);
    });
    return annots;
  }

  loadElementsAnnotations(elements, modelId) {
    try {
      if (!elements) return;
      const elementsArray = [];
      Object.entries(elements).forEach(([key, value]) => {
        const newValue = value.map((e) => ({...e, caplaModelId: key}));
        elementsArray.push(...newValue);
      });
      console.log("elementsArray", elementsArray);
      elementsArray.forEach((element) =>
        this.loadElementAnnotation(element, modelId)
      );
    } catch (e) {
      console.log("error", e);
    }
  }

  // selection

  setAnnotationReadOnly(annotation, boolean) {}

  // updates

  getPageBounds(pageNumber) {
    const {documentViewer} = this.webViewer.Core;
    const width = documentViewer.getPageWidth(pageNumber);
    const height = documentViewer.getPageHeight(pageNumber);
    return {
      pageNumber: pageNumber,
      x1: 0,
      y1: 0,
      x2: width,
      y2: height,
    };
  }

  getImageGenerationPropsFromAnnotation(annotation, options) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const pageNumber = annotation.PageNumber;
    const name = options?.name;
    const zoom = docViewer.getZoomLevel();
    const rotation = docViewer.getCompleteRotation(pageNumber);
    const height = docViewer.getPageHeight(pageNumber);
    const width = docViewer.getPageWidth(pageNumber);
    let x1 = annotation.X;
    let y1 = annotation.Y;
    let x2 = x1 + annotation.Width;
    let y2 = y1 + annotation.Height;

    // viewport coordinates (to zoom if necessary)
    const vpCoords = this.caplaEditor?.editorPdf.getViewportPageCoordinates();
    const zoomScale = (vpCoords.x2 - vpCoords.x1) / annotation.Width;
    const fullZoom = zoom * zoomScale;

    let renderRect = {x1, y1, x2, y2};
    if (rotation === 1) {
      renderRect = {
        x1: height - renderRect.y2,
        y1: renderRect.x1,
        x2: height - renderRect.y1,
        y2: renderRect.x2,
      };
    } else if (rotation === 3) {
      renderRect = {
        x2: renderRect.y2,
        y2: width - renderRect.x1,
        x1: renderRect.y1,
        y1: width - renderRect.x2,
      };
    }
    return {zoom, renderRect, name, pageNumber, fullZoom};
  }
  getImageGenerationProps(annotationId) {
    const annotation = this.findAnnotation(annotationId);
    const annotationEntity = this.findAnnotationEntity(annotationId);
    const name = annotationEntity.name;
    return this.getImageGenerationPropsFromAnnotation(annotation, {name});
  }

  async getAnnotationImageFileFromAnnotation(annotation, options) {
    //options:{smallVariant,withFullZoom,name}
    const smallVariant = options?.smallVariant;
    const withFullZoom = options?.withFullZoom;
    const {documentViewer: docViewer} = this.webViewer.Core;
    const {zoom, fullZoom, renderRect, name, pageNumber} =
      this.getImageGenerationPropsFromAnnotation(annotation, options);
    // zoom
    return new Promise((resolve) => {
      docViewer.getDocument().loadCanvas({
        pageNumber,
        drawComplete: function (canvas) {
          canvas.toBlob(async (blob) => {
            const fileName = `${name}-${blob.size}.png`;
            const newFile = new File([blob], fileName, {type: "image/png"});
            resolve(newFile);
          });
        },
        zoom: smallVariant ? 0.1 : withFullZoom ? fullZoom : zoom,
        renderRect,
      });
    });
  }

  async getAnnotationImageFile(annotationId, options) {
    const annotation = this.findAnnotation(annotationId);
    const annotationEntity = this.findAnnotationEntity(annotationId);
    const zoneName = annotationEntity.name;
    const newOptions = {...options, name: zoneName};
    return await this.getAnnotationImageFileFromAnnotation(
      annotation,
      newOptions
    );
  }

  // annotation

  getAnnotationEntity(annotation) {
    const annotationId = annotation.getCustomData("annotationId");
    const annotationEntity = this.annotationEntities.find(
      (e) => e.annotationId === annotationId
    );
    return annotationEntity;
  }

  getAnnotationCenter(annotation) {
    // in pageWithRot coordinates
    if (annotation) {
      const {documentViewer: docViewer} = this.webViewer.Core;
      const {
        PageNumber: pageNumber,
        X: x,
        Y: y,
        Width: width,
        Height: height,
      } = annotation;

      const pageHeight = docViewer.getPageHeight(pageNumber);
      const pageWidth = docViewer.getPageWidth(pageNumber);
      const rotation = docViewer.getCompleteRotation(pageNumber);
      let center = {x: x + width / 2, y: y + height / 2};
      if (rotation === 1) {
        center = {
          x: pageHeight - center.y,
          y: center.x,
        };
      } else if (rotation === 3) {
        center = {
          x: center.y,
          y: pageWidth - center.x,
        };
      }
      return center;
    }
  }

  getAnnotationSize(annotation) {
    if (annotation) {
      const {documentViewer: docViewer} = this.webViewer.Core;
      const {PageNumber: pageNumber, Width: width, Height: height} = annotation;

      const rotation = docViewer.getCompleteRotation(pageNumber);
      let size = {width, height};
      if (rotation) {
        size = {
          width: size.height,
          height: size.width,
        };
      }
      return size;
    }
  }

  selectAnnotation(annotationId) {
    const {annotationManager, Annotations} = this.webViewer.Core;
    const strokeColorPink = new Annotations.Color(245, 37, 133);
    const strokeColorGrey = new Annotations.Color(100, 100, 100);
    if (this.selectedAnnotationId) {
      const oldAnnotation = this.findAnnotation(this.selectedAnnotationId);
      if (oldAnnotation) {
        oldAnnotation.StrokeColor = strokeColorGrey;
        annotationManager.redrawAnnotation(oldAnnotation);
      }
    }
    const annotation = this.findAnnotation(annotationId);
    if (annotation) {
      annotation.StrokeColor = strokeColorPink;
      annotationManager.redrawAnnotation(annotation);
    }
    this.setSelectedAnnotationId(annotationId);
  }

  findAnnotation(annotationId) {
    if (!annotationId) return;
    return this.annotationEntities.find((e) => e.annotationId === annotationId)
      ?.annotationItem;
  }

  findAnnotationEntity(annotationId) {
    return this.annotationEntities.find((e) => e.annotationId === annotationId);
  }

  getAnnotationEntityFromAnnotation(annotation) {
    const annotationId = annotation.getCustomData("annotationId");
    const annotationEntity = this.findAnnotationEntity(annotationId);
    console.log("e", annotationEntity);
    return annotationEntity;
  }

  // updates

  async updateImageModelImageFromUpdatedAnnotation(annotation) {
    const annotationEntity = this.getAnnotationEntityFromAnnotation(annotation);
    const annotationId = annotationEntity.annotationId;
    const imageModelId = annotationEntity.imageModelId;
    const file = await this.getAnnotationImageFile(annotationId);
    const updatedModel = {id: imageModelId};
    this.caplaEditor?.editor3d.loader.updateImageModel({updatedModel, file});
  }

  updateAnnotationEntityPosition({
    annotationId,
    position,
    updateImageModel = true,
  }) {
    const annotationEntity = this.annotationEntities.find(
      (e) => e.annotationId === annotationId
    );

    if (!annotationEntity) return; // TO DO BY CAPLA : check why annotatoinEntity may by empty.

    const newEntity = {...annotationEntity, position};
    this.annotationEntities = this.annotationEntities.map((e) => {
      if (e.annotationId === annotationId) {
        return newEntity;
      } else {
        return e;
      }
    });

    this.dispatchUpdateModel({modelId: annotationEntity.modelId}); // we use this to update the PDF zones.
    if (updateImageModel)
      this.updateImageModelFromZoneAnnotation(annotationEntity.annotationItem);
  }

  updateAnnotationEntityRotation({annotationId, rotation, updateImageModel}) {
    const annotationEntity = this.annotationEntities.find(
      (e) => e.annotationId === annotationId
    );
    if (!annotationEntity) return; // TO DO BY CAPLA : check why annotatoinEntity may by empty.
    const newEntity = {...annotationEntity, rotation};
    this.annotationEntities = this.annotationEntities.map((e) => {
      if (e.annotationId === annotationId) {
        return newEntity;
      } else {
        return e;
      }
    });
    this.dispatchUpdateModel({modelId: annotationEntity.modelId});
    if (updateImageModel)
      this.updateImageModelFromZoneAnnotation(annotationEntity.annotationItem);
  }

  // the keyfunction to update annotation & imageModel !
  updateAnnotationEntityProps({annotationId, props, updateImageModel = true}) {
    // props includes rotation & position
    const annotationEntity = this.annotationEntities.find(
      (e) => e.annotationId === annotationId
    );
    const newEntity = {...annotationEntity, ...props};

    this.annotationEntities = this.annotationEntities.map((e) => {
      if (e.annotationId === annotationId) {
        return newEntity;
      } else {
        return e;
      }
    });
    this.dispatchUpdateModel({modelId: annotationEntity.modelId});
    if (updateImageModel) {
      console.log("UPDATE IMAGE MODEL FROM ZONE ANNOTATION");
      this.updateImageModelFromZoneAnnotation(
        annotationEntity.annotationItem,
        props
      );
    }
  }

  updateAnnotationEntity(annotation) {
    const newAE = this.computeAnnotationEntityFromAnnotationChange(annotation);
    this.annotationEntities = this.annotationEntities.map((e) => {
      if (e.annotationId === newAE.annotationId) {
        return newAE;
      } else {
        return e;
      }
    });
    this.dispatchUpdateModel({modelId: newAE.modelId});
  }

  computeAnnotationEntityFromAnnotationChange(annotation) {
    const annotationEntity = this.getAnnotationEntityFromAnnotation(annotation);

    const imageModelEntity = this.caplaEditor?.editor3d.getEntity(
      annotationEntity.imageModelId
    );
    const newEntity = {...annotationEntity};
    const oldPosition = {...annotationEntity.position};
    const newCenter = this.getAnnotationCenter(annotation);
    const oldCenter = annotationEntity.centerInPage;
    const offsetCenter = this.subAtoB(oldCenter, newCenter);
    const offsetCenterInBox = this.scaleA(offsetCenter, annotationEntity.scale);
    const offset3D = {x: offsetCenterInBox.x, y: -offsetCenterInBox.y, z: 0}; // offset of image in image three.js ref.
    const newModelPosition =
      imageModelEntity.getPositionFromOffsetImage(offset3D);
    newEntity.position = newModelPosition;
    newEntity.centerInPage = newCenter;
    return newEntity;
  }

  // dispatch

  dispatchUpdateModel({modelId}) {
    console.log("UPDATE MODEL WITH ZONES 32", modelId);
    const updates = {
      id: modelId,
      zones: this.getModelZones(modelId),
    };
    this.caplaEditor?.dispatch(updateModelNotAsync({updates, sync: true}));
  }

  dispatchUpdateModelWithElements({modelId}) {
    console.log("UPDATE MODEL WITH ELEMENTS 123");
    const updatedModel = {
      id: modelId,
      elementsAnnotations: this.getModelElementsAnnotations(modelId),
    };
    this.caplaEditor?.dispatch(updateModel({updatedModel, sync: false}));
  }

  // parser - defaut annotation

  parseAnnotation(annotation) {
    const id = annotation.Id;
    const author = annotation.Author;
    const elementName = annotation.elementName;
    const subject = annotation.Subject;

    return {id, author, elementName, subject};
  }

  // parser - model - zones

  getModelZones(modelId) {
    const zoneEntities = this.annotationEntities.filter(
      (e) => e.modelId === modelId && e.type === "ZONE"
    );
    return zoneEntities.map((e) => this.annotationEntityToModelZone(e));
  }

  annotationEntityToModelZone(annotationEntity) {
    const pageNumber = annotationEntity.annotationItem.PageNumber;
    const {documentViewer: docViewer} = this.webViewer.Core;
    const pageRotation = docViewer.getCompleteRotation(pageNumber);

    let width = annotationEntity.annotationItem.Width;
    let height = annotationEntity.annotationItem.Height;

    return {
      id: annotationEntity.annotationId,
      name: annotationEntity.name,
      pageNumber: annotationEntity.annotationItem.PageNumber,
      x: annotationEntity.annotationItem.X,
      y: annotationEntity.annotationItem.Y,
      width,
      height,
      pageRotation,
      position: annotationEntity.position,
      rotation: annotationEntity.rotation,
      scale: annotationEntity.scale,
      imageModelId: annotationEntity.imageModelId,
      sectorId: annotationEntity.sectorId,
      roomId: annotationEntity.roomId,
      materialId: annotationEntity.materialId,

      zBasis: annotationEntity.zBasis,
      elementsH: annotationEntity.elementsH,

      imageSize: annotationEntity.imageSize,
      d3: annotationEntity.d3,
    };
  }

  modelZoneToAnnotationEntityProps(zone) {
    return {
      annotationId: zone.id,
      name: zone.name,
      type: "ZONE",
      width: zone.width,
      height: zone.height,
      pageRotation: zone.pageRotation,
      position: zone.position,
      rotation: zone.rotation,
      scale: zone.scale,
      imageModelId: zone.imageModelId,
      sectorId: zone.sectorId,
      roomId: zone.roomId,
      materialId: zone.materialId,
      zBasis: zone.zBasis,
      elementsH: zone.elementsH,
      imageSize: zone.imageSize,
      d3: zone.d3,
    };
  }

  // parser - model - elements

  // used when annotation is clicked
  annotationEntityToModelElement(annotationEntity) {
    if (!annotationEntity) return;

    let quantities = this.getAnnotationQuantities(
      annotationEntity.annotationItem
    );
    if (annotationEntity.drawingType === "POINT") {
      quantities = {count: 1, perimeter: 0, area: this.typeArea};
    }
    if (annotationEntity.drawingType === "POLYLINE") {
      quantities.area = quantities.perimeter * this.typeStrokeM;
    }

    return {
      id: annotationEntity.annotationId,
      codeName: annotationEntity.codeName,
      typeCode: annotationEntity.typeCode,
      caplaModelId: annotationEntity.caplaModelId,
      pageNumber: annotationEntity.annotationItem.PageNumber,
      annotationType: annotationEntity.annotationItem.elementName,
      drawingType: annotationEntity.drawingType,
      x: annotationEntity.annotationItem.X,
      y: annotationEntity.annotationItem.Y,
      path: this.getAnnotationPath(annotationEntity.annotationItem),
      quantities,
    };
  }

  // used when loading annotations from pdf
  rawAnnotationToAnnotationEntity(annotation, modelId) {
    const type = annotation.getCustomData("type");
    if (type !== "CAPLA_ANNOT" && type !== "CAPLA_ELT") return;

    const {Annotations, annotationManager} = this.webViewer.Core;

    const annotationId = annotation.getCustomData("annotationId");
    const typeCode = annotation.getCustomData("typeCode");
    const caplaModelId = annotation.getCustomData("caplaModelId");
    const drawingType = annotation.getCustomData("drawingType"); // important to compute quantities.
    const codeName = annotation.getCustomData("codeName");

    // update annotation styles
    if (type === "CAPLA_ANNOT") {
      const color = annotation.StrokeColor;
      const colorT = new Annotations.Color(color.R, color.G, color.B, 0.8);
      annotation.Opacity = 1;
      if (drawingType === "POINT") {
        annotation.StrokeColor = color;
        annotation.FillColor = colorT;
      } else if (drawingType === "POLYLINE") {
        annotation.StrokeColor = colorT;
      } else if (drawingType === "POLYGON" || drawingType === "RECT") {
        annotation.FillColor = colorT;
      }
      annotationManager.redrawAnnotation(annotation);
    }

    // annotation entity
    let annotationEntity = {
      annotationId,
      modelId,
      caplaModelId,
      typeCode,
      codeName,
      annotationItem: annotation,
    };
    if (type === "CAPLA_ANNOT") {
      annotationEntity.type = "ELEMENT";
    } else if (type === "CAPLA_ELT") {
      annotationEntity.type = "LABEL";
    }
    return annotationEntity;
  }

  rawAnnotationToCaplaModelItem(annotation, caplaModelId, pdfModelId) {
    try {
      const type = annotation.getCustomData("type");
      const annotCaplaModelId = annotation.getCustomData("caplaModelId");

      if (type !== "CAPLA_ELT" || annotCaplaModelId !== caplaModelId) return;

      const typeCode = annotation.getCustomData("typeCode");
      const codeName = annotation.getCustomData("codeName");
      const quantitiesS = annotation.getCustomData("quantities");
      const quantities = quantitiesS ? JSON.parse(quantitiesS) : [];
      const geometryS = annotation.getCustomData("geometry");
      const geometry = geometryS ? JSON.parse(geometryS) : [];
      const annotationsIdsS = annotation.getCustomData("annotationsIds");
      const annotationsIds = annotationsIdsS ? JSON.parse(annotationsIdsS) : [];
      const numS = annotation.getCustomData("num");
      const num = parseInt(numS);

      const fromPdf = {modelId: pdfModelId, annotationsIds};
      const fromModel = {type: "PDF", modelId: pdfModelId, annotationsIds};
      const item = {
        num,
        typeCode,
        codeName,
        quantities,
        geometry,
        fromPdf,
        fromModel,
      };

      return item;
    } catch (e) {
      console.log(e);
    }
  }

  modelElementToAnnotationEntityProps(element) {
    return {
      annotationId: element.id,
      codeName: element.codeName,
      type: "ELEMENT",
      pageRotation: element.pageRotation,
    };
  }

  getModelElementsAnnotations(modelId) {
    const elementEntities = this.annotationEntities.filter(
      (e) => e?.modelId === modelId && e?.type === "ELEMENT"
    );
    const array = elementEntities.map((e) =>
      this.annotationEntityToModelElement(e)
    );
    const object = {};
    array.forEach((e) => {
      const newElementAnnotation = {
        id: e.id,
        codeName: e.codeName,
        typeCode: e.typeCode,
        pageNumber: e.pageNumber,
        annotationType: e.annotationType,
        x: e.x,
        y: e.y,
        path: e.path,
        quantities: e.quantities,
      };
      if (object[e.caplaModelId]) {
        object[e.caplaModelId] = [
          ...object[e.caplaModelId],
          newElementAnnotation,
        ];
      } else {
        object[e.caplaModelId] = [newElementAnnotation];
      }
    });

    return object;
  }

  // image model

  // used to load bim data
  async createImageModelsFromZones(zones) {
    for (let zone of zones) {
      await this.createImageModelFromZone(zone);
    }
  }

  async createImageModelFromZone(zone, file, options) {
    let _file = file;
    if (!_file)
      _file = await this.getAnnotationImageFile(zone.id, {
        smallVariant: true,
      });
    let width = zone.width * zone.scale;
    let height = zone.height * zone.scale;
    if (zone.pageRotation === 1 || zone.pageRotation === 3) {
      const _width = width;
      width = height;
      height = _width;
    }
    const _options = {
      name: zone.name,
      fromModel: {
        type: "PDF",
        modelId: zone.pdfModelId,
        zoneId: zone.id,
      },
      width,
      height,
      rotation: zone.rotation,
      position: zone.position,
      sectorId: zone.sectorId,
      roomId: zone.roomId, // important ?
      materialId: zone.materialId, // important !
    };
    if (options?.d3) _options.d3 = options.d3;

    this.caplaEditor?.editor3d.loader.loadFile({
      file: _file,
      type: "IMAGE",
      options: _options,
    });
  }

  async createImageModelFromZoneAnnotation(annotation) {
    const annotationEntity = this.getAnnotationEntityFromAnnotation(annotation);

    const zone = this.annotationEntityToModelZone(annotationEntity);
    const file = await this.getAnnotationImageFile(
      annotationEntity.annotationId,
      {
        smallVariant: true,
      }
    );
    let width = zone.width * zone.scale;
    let height = zone.height * zone.scale;
    if (zone.pageRotation === 1 || zone.pageRotation === 3) {
      const _width = width;
      width = height;
      height = _width;
    }
    const options = {
      name: zone.name,
      fromPdf: {modelId: annotationEntity.modelId, zoneId: zone.id},
      fromModel: {
        type: "PDF",
        modelId: annotationEntity.modelId,
        zoneId: zone.id,
      },
      width,
      height,
      zBasis: zone.zBasis,
    };

    console.log("createZoneFrom", zone, options);

    this.caplaEditor?.editor3d.loader.loadFile({file, type: "IMAGE", options});

    // update annotation entity
    const imageSize = await getImageSize({file});
    this.updateAnnotationEntityProps({
      annotationId: zone.id,
      props: {imageSize},
    });
  }

  // update. The annotationEntity has been changed
  // props : added when changing the zone from the form.
  updateImageModelFromZoneAnnotation(annotation, zoneProps) {
    const annotationEntity = this.getAnnotationEntityFromAnnotation(annotation);
    const zone = this.annotationEntityToModelZone(annotationEntity);
    let width = zone.width * zone.scale;
    let height = zone.height * zone.scale;
    if (zone.pageRotation === 1 || zone.pageRotation === 3) {
      const _width = width;
      width = height;
      height = _width;
    }
    const position = zone.position;
    const rotation = zone.rotation;
    const modelId = annotationEntity.imageModelId;
    const name = zone.name;
    const imageSize = zone.imageSize;
    const d3 = zone.d3;
    const zBasis = zone.zBasis;

    let updatedModel = {
      id: modelId,
      name,
      width,
      height,
      position,
      rotation,
      imageSize, //not sure => updated with the file.
      d3,
      zBasis,
    };

    // if the annotation is changed from the form, we compute the updatedModel directly.
    if (zoneProps) {
      updatedModel = {id: modelId};
      if (zoneProps.name) updatedModel.name = zoneProps.name;
      if (zoneProps.position) updatedModel.position = zoneProps.position;
      if (zoneProps.rotation) updatedModel.rotation = zoneProps.rotation;
      if (zoneProps.scale) {
        width = zone.width * zoneProps.scale;
        height = zone.height * zoneProps.scale;
        if (zone.pageRotation === 1 || zone.pageRotation === 3) {
          const _width = width;
          width = height;
          height = _width;
        }
        updatedModel.width = width;
        updatedModel.height = height;
      }
      updatedModel.d3 = zoneProps.d3; // !do not test: can be false !!
      if (zoneProps.zBasis) updatedModel.zBasis = zoneProps.zBasis;
    }
    console.log("update Image Model with", updatedModel);
    this.caplaEditor?.editor3d.loader.updateImageModel({updatedModel});
  }

  linkImageModelToAnnotation(annotationId, modelId) {
    console.log("linkImageModelToAnnotation", annotationId, modelId);
    const annotationEntity = this.findAnnotationEntity(annotationId);
    const newAE = {...annotationEntity, imageModelId: modelId};
    this.annotationEntities = this.annotationEntities.map((e) => {
      if (e.annotationId === annotationId) {
        return newAE;
      } else {
        return e;
      }
    });
    this.dispatchUpdateModel({modelId: annotationEntity.modelId});
  }

  // positioning in 3D scene

  computeAnnotationPositionWithOnePoint(
    annotationId,
    pagePoint,
    boxPoint,
    useMarkerHeight
  ) {
    if (!pagePoint || !boxPoint) return;
    const annotationEntity = this.findAnnotationEntity(annotationId);
    const isVertical = annotationEntity.rotation.x === 0;
    const {annotationItem} = annotationEntity;
    const annotationCenter = this.getAnnotationCenter(annotationItem);

    const bimboxCenter = this.getBimboxPoint({
      pagePoint: annotationCenter,
      refPagePoint: pagePoint,
      refBimboxPoint: boxPoint,
      zone: this.annotationEntityToModelZone(annotationEntity),
      useMarkerHeight,
      isVertical,
    });
    const newPosition = {...bimboxCenter};
    return newPosition;
  }

  // point = pointWithRot
  computeDeltaRotation(
    pagePoint1,
    boxPoint1,
    pagePoint2,
    boxPoint2,
    isVertical
  ) {
    const page12V = new Vector2(
      pagePoint2.x - pagePoint1.x,
      pagePoint2.y - pagePoint1.y
    );
    const box12V = new Vector2(
      boxPoint2.x - boxPoint1.x,
      boxPoint2.z - boxPoint1.z
    );
    const anglePage = page12V.angle();
    const angleBox = box12V.angle();
    if (isVertical) return -angleBox;
    return anglePage - angleBox; // take into account axis orientation (x -1)
  }

  computePosAndRotAfterRotationY(position, rotation, angle, pivotP) {
    const object = new Object3D();
    object.position.set(position.x, position.y, position.z);
    object.rotation.set(rotation.x, rotation.y, rotation.z);
    const pivot = new Object3D();
    pivot.position.set(pivotP.x, pivotP.y, pivotP.z);
    pivot.attach(object);
    pivot.rotation.set(0, angle, 0);
    const finalP = new Vector3();
    const finalQ = new Quaternion();
    const finalE = new Euler();
    object.getWorldPosition(finalP);
    object.getWorldQuaternion(finalQ);
    finalE.setFromQuaternion(finalQ);
    return {
      position: {x: finalP.x, y: finalP.y, z: finalP.z},
      rotation: {x: finalE.x, y: finalE.y, z: finalE.z},
    };
  }

  resetAnnotationTransformation(annotationId) {
    const zero = {x: 0, y: 0, z: 0};
    this.updateAnnotationEntityProps({
      annotationId,
      props: {position: zero, rotation: zero},
    });
  }

  positionAnnotationWithOnePoint(
    annotationId,
    pagePoint,
    boxPoint,
    useMarkerHeight
  ) {
    const newPosition = this.computeAnnotationPositionWithOnePoint(
      annotationId,
      pagePoint,
      boxPoint,
      useMarkerHeight
    );
    this.updateAnnotationEntityPosition({annotationId, position: newPosition});
  }

  positionAnnotationWithTwoPoints(
    annotationId,
    pagePoint1,
    boxPoint1,
    pagePoint2,
    boxPoint2,
    useMarkerHeight
  ) {
    const annotationEntity = this.findAnnotationEntity(annotationId);
    const _position = this.computeAnnotationPositionWithOnePoint(
      annotationId,
      pagePoint1,
      boxPoint1,
      useMarkerHeight
    );
    const _rotation = annotationEntity.rotation;
    const isVertical = _rotation.x === 0;
    const angle = this.computeDeltaRotation(
      pagePoint1,
      boxPoint1,
      pagePoint2,
      boxPoint2,
      isVertical
    );
    console.log("rotate model by", (angle * 180) / Math.PI, "pivot", boxPoint1);
    const {position, rotation} = this.computePosAndRotAfterRotationY(
      _position,
      _rotation,
      angle,
      boxPoint1
    );
    this.updateAnnotationEntityProps({
      annotationId,
      props: {position, rotation},
    });
  }

  // helpers

  getPageSize(pageNumber) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const rotation = docViewer.getCompleteRotation(pageNumber);
    let height = docViewer.getPageHeight(pageNumber);
    let width = docViewer.getPageWidth(pageNumber);
    let _width = width;
    if (rotation) {
      width = height;
      height = _width;
    }
    return {width, height};
  }

  placeAinB(a, b) {
    return {x: a.x - b.x, y: a.y - b.y};
  }

  addAtoB(a, b) {
    return {x: a.x + b.x, y: a.y + b.y};
  }
  addAtoBin3D(a, b) {
    return {x: a.x + b.x, y: a.y + b.y, z: a.z + b.z};
  }
  subAtoB(a, b) {
    return {x: b.x - a.x, y: b.y - a.y};
  }
  subAtoBin3D(a, b) {
    return {x: b.x - a.x, y: b.y - a.y, z: b.z - a.z};
  }
  scaleA(a, scale) {
    return {x: a.x * scale, y: a.y * scale};
  }

  getBoundsWithCenterAndSize(center, size) {
    if (center && size) {
      return {
        x1: center.x - size.width / 2,
        y1: center.y - size.height / 2,
        x2: center.x + size.width / 2,
        y2: center.y + size.height / 2,
      };
    }
  }

  testPointInBounds(point, bounds) {
    if (point && bounds) {
      const {x1, x2, y1, y2} = bounds;
      const {x, y} = point;
      return x >= x1 && x <= x2 && y >= y1 && y <= y2;
    }
  }

  findPointsInAnnotation({annotationId, points}) {
    const annotation = this.findAnnotation(annotationId);
    const size = this.getAnnotationSize(annotation);
    const center = this.getAnnotationCenter(annotation);
    const bounds = this.getBoundsWithCenterAndSize(center, size);
    const inPoints = [];
    points.forEach((point) => {
      if (this.testPointInBounds(point, bounds)) inPoints.push(point);
    });
    return inPoints;
  }

  scalePoint(point) {
    return {
      x: point.x * this.mpd, // mpd : meter per dot
      y: point.y * this.mpd,
    };
  }

  // delete and clear

  clearZoneAnnotations() {
    // when changing pdf
    const {annotationManager} = this.webViewer.Core;
    this.annotationEntities.forEach((e) => {
      if (e.type === "ZONE") {
        annotationManager.deleteAnnotations([e.annotationItem]);
      }
    });
    this.annotationEntities = this.annotationEntities.filter(
      (e) => e.type !== "ZONE"
    );
  }

  deleteAnnotation(annotationId) {
    console.log(("delete20", annotationId));
    const {annotationManager} = this.webViewer.Core;
    const annotationEntity = this.findAnnotationEntity(annotationId);

    // we first delete the zone model before deleting the annotation (NO ! => deleted from react component)
    if (annotationEntity) {
      annotationManager.deleteAnnotations([annotationEntity.annotationItem]);
      this.annotationEntities = this.annotationEntities.filter(
        (e) => e.annotationId !== annotationId
      );
      this.dispatchUpdateModel({modelId: annotationEntity.modelId});
    }
  }

  // triggered when deleted from the editor.
  handleZoneAnnotationDeleted(annotationId) {}

  // drawing a zone

  startDrawingZone() {
    console.log("start drawing zone");
    this.drawingZone = true;
  }

  stopDrawingZone() {
    this.drawingZone = false;
  }

  // navigation

  goToZone(zoneId) {
    const {annotationManager} = this.webViewer.Core;
    const annotation = this.findAnnotation(zoneId);
    if (annotation)
      annotationManager.jumpToAnnotation(annotation, {fitToView: true});
  }

  // misc

  getZoneById(zoneId) {
    let annotationEntity = this.findAnnotationEntity(zoneId);
    return (
      annotationEntity && this.annotationEntityToModelZone(annotationEntity)
    );
  }

  getPointBoundingZone(point) {
    const pageNumber = point.pageNumber;
    let entities = this.annotationEntities.filter(
      (e) => e.annotationItem.PageNumber === pageNumber && e.type === "ZONE"
    );
    const zones = entities.map((e) => this.annotationEntityToModelZone(e));

    let zone = zones.find((e) => {
      const x1 = e.x;
      const y1 = e.y;
      const x2 = e.x + e.width;
      const y2 = e.y + e.height;
      const {x, y} = point;
      return x >= x1 && x <= x2 && y >= y1 && y <= y2;
    });

    return zone;
  }

  /*
   * 2D => 3D
   */

  getZoneRefPoints(zone) {
    // in withRot coordinates.
    const annotation = this.findAnnotation(zone.id);
    const refPagePoint = this.getAnnotationCenter(annotation);

    const refBimboxPoint = {...zone.position};

    return {refPagePoint, refBimboxPoint};
  }

  // use to place the zone at the beginning of the process.
  getBimboxPoint({
    pagePoint, // the point in the page to localize in 3D
    refPagePoint, // point with known position in 3D & page
    refBimboxPoint,
    zone,
    useMarkerHeight,
    isVertical,
  }) {
    const pagePointInRef = this.placeAinB(pagePoint, refPagePoint);
    const scaledPagePointInRef = this.scaleA(pagePointInRef, zone.scale);

    let bimboxPoint;
    const pivot = new Object3D();
    const object = new Object3D();
    const bimboxPointV = new Vector3();
    pivot.position.set(refBimboxPoint.x, refBimboxPoint.y, refBimboxPoint.z);

    if (isVertical) {
      pivot.rotation.set(zone.rotation.x, zone.rotation.y, zone.rotation.z);
      object.position.set(scaledPagePointInRef.x, -scaledPagePointInRef.y, 0);
      pivot.add(object);
    } else {
      // case : horizontal zones
      // 1st : translate. Then : rotate around pivot point.
      const x = scaledPagePointInRef.x;
      //const y = useMarkerHeight ? refBimboxPoint.y : zone.position.y;
      const y = 0;
      const z = scaledPagePointInRef.y;
      object.position.set(x, y, z);
      pivot.add(object);
      pivot.rotation.set(0, zone.rotation.z, 0); // the image is horizontal.
    }
    object.getWorldPosition(bimboxPointV);
    bimboxPoint = {
      x: bimboxPointV.x,
      y: useMarkerHeight ? refBimboxPoint.y : zone.position.y,
      z: bimboxPointV.z,
    };

    return bimboxPoint;
  }

  // used to get point once the zone is located.
  getBimboxPointFromZonePoint(pointWithRot, rawPoint, offset) {
    console.log("offsetTT", offset);
    const zone = this.getPointBoundingZone(rawPoint);
    if (!zone) return;

    let point = pointWithRot;
    if (!point)
      point = this.caplaEditor?.editorPdf.getPointWithRotFromRawPoint(
        rawPoint,
        zone.pageNumber
      );

    const {refPagePoint, refBimboxPoint} = this.getZoneRefPoints(zone);

    const pagePointInRef = this.placeAinB(point, refPagePoint);
    const scaledPagePointInRef = this.scaleA(pagePointInRef, zone.scale);

    const bimboxPoint = {};

    const pivot = new Object3D();
    pivot.rotation.set(zone.rotation.x, zone.rotation.y, zone.rotation.z);
    pivot.position.set(zone.position.x, zone.position.y, zone.position.z);

    const refObject = new Object3D();
    const refLocalPos = this.subAtoBin3D(zone.position, refBimboxPoint);
    refObject.position.set(refLocalPos.x, refLocalPos.y, refLocalPos.z);

    const object = new Object3D();
    object.position.set(
      scaledPagePointInRef.x,
      -scaledPagePointInRef.y,
      offset ? offset : 0
    );

    refObject.add(object);
    pivot.add(refObject);

    const bimboxPointV = new Vector3();
    object.getWorldPosition(bimboxPointV);

    bimboxPoint.x = bimboxPointV.x;
    bimboxPoint.y = bimboxPointV.y;
    bimboxPoint.z = bimboxPointV.z;

    return bimboxPoint;
  }

  // hide & show

  showZoneAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = this.annotationEntities
      .filter((e) => e.type === "ZONE")
      .map((e) => e.annotationItem);
    annotationManager.addAnnotations(annotations);
    annotationManager.drawAnnotationsFromList(annotations);
  }

  hideZoneAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = this.annotationEntities
      .filter((e) => e.type === "ZONE")
      .map((e) => e.annotationItem);
    console.log("delete21");
    annotationManager.deleteAnnotations(annotations);
  }

  showElementsAnnotations(annotationIds) {
    if (annotationIds) {
      const {annotationManager} = this.webViewer.Core;
      const annotations = this.annotationEntities
        .filter(
          (e) => e.type === "ELEMENT" && annotationIds.includes(e.annotationId)
        )
        .map((e) => e.annotationItem);
      annotationManager.showAnnotations(annotations);
    }
  }

  hideElementsAnnotations(annotationIds) {
    if (annotationIds) {
      const {annotationManager} = this.webViewer.Core;
      const annotations = this.annotationEntities
        .filter(
          (e) => e.type === "ELEMENT" && annotationIds.includes(e.annotationId)
        )
        .map((e) => e.annotationItem);
      annotationManager.hideAnnotations(annotations);
    }
  }

  showElementLabel({caplaModelId, codeName}) {
    const {annotationManager} = this.webViewer.Core;
    const annotations = this.annotationEntities
      .filter(
        (e) =>
          e.type === "LABEL" &&
          e.caplaModelId === caplaModelId &&
          e.codeName === codeName
      )
      .map((e) => e.annotationItem);
    annotationManager.showAnnotations(annotations);
  }

  hideElementLabel({caplaModelId, codeName}) {
    const {annotationManager} = this.webViewer.Core;
    const annotations = this.annotationEntities
      .filter(
        (e) =>
          e.type === "LABEL" &&
          e.caplaModelId === caplaModelId &&
          e.codeName === codeName
      )
      .map((e) => e.annotationItem);
    annotationManager.hideAnnotations(annotations);
  }

  hideExternalAnnots() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") !== "MEASUREMENT");

    annotations.forEach((annot) => {
      annot.ReadOnly = false;
    });
    console.log("hide annotations", annotations.length);
    annotationManager.hideAnnotations(annotations);
  }

  showExternalAnnots() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") !== "MEASUREMENT");
    annotationManager.showAnnotations(annotations);
  }

  deleteExternalAnnots() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager.getAnnotationsList();
    // .filter((a) => !a.getCustomData("isFromCapla") === "true");
    console.log("annotationsA", annotations);
    console.log("delete22");
    annotationManager.deleteAnnotations(annotations, {force: true});
  }

  // export

  async addCaplaAnnotation({pdfModel, caplaModels}) {
    const {annotationManager, Annotations} = this.webViewer.Core;

    // we first delete it if it exists

    const annots = annotationManager.getAnnotationsList();
    const caplaAnnot = annots.find(
      (a) => a.getCustomData("type") === "CAPLA_PDF"
    );
    if (caplaAnnot) {
      console.log("delete23");
      annotationManager.deleteAnnotation(caplaAnnot);
    }

    const annot = new Annotations.FreeTextAnnotation();
    const autoSizeTypes = Annotations.FreeTextAnnotation.AutoSizeTypes;

    annot.content = "by CAPLA 360";
    annot.FontSize = "16pt";
    annot.TextColor = new Annotations.Color(255, 255, 255);
    annot.PageNumber = 1;
    annot.X = 0;
    annot.Y = 0;
    annot.setAutoSizeType(autoSizeTypes.AUTO);

    await annot.setCustomData("type", "CAPLA_PDF");
    await annot.setCustomData("pdfModelName", pdfModel?.name);
    await annot.setCustomData("pdfModelId", pdfModel?.id);
    await annot.setCustomData("pdfScale", pdfModel.pdfScale);
    await annot.setCustomData("caplaModels", JSON.stringify(caplaModels));

    annotationManager.addAnnotation(annot, {autoFocus: false});
    return annot.getCustomData("caplaModels");
  }

  tempAnnotationsChangeForExport() {
    const {annotationManager, Annotations} = this.webViewer.Core;
    const annotations = annotationManager.getAnnotationsList();
    annotations.forEach((annotation) => {
      const type = annotation.getCustomData("type");
      if (type === "CAPLA_ANNOT") {
        annotation.Opacity = 0.8;
        annotation.FillColor = new Annotations.Color(20, 20, 20, 0.5);
      }
    });
  }

  updateAnnotationsAfterExport() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager.getAnnotationsList();
    annotations.forEach((annotation) => {
      const type = annotation.getCustomData("type");
      if (type === "CAPLA_ANNOT") annotation.Opacity = 1;
    });
  }

  // import

  loadCaplaAnnotation(pdfModelId) {
    try {
      const {annotationManager} = this.webViewer.Core;
      const annots = annotationManager.getAnnotationsList();
      const annot = annots.find((a) => a.getCustomData("type") === "CAPLA_PDF");
      const caplaModelsString = annot?.getCustomData("caplaModels");
      let caplaModels = caplaModelsString ? JSON.parse(caplaModelsString) : [];
      const pdfScale = annot?.getCustomData("pdfScale");

      // add items

      caplaModels = caplaModels.map((m) => {
        const items = this.getCaplaModelElementsItems(m.id, pdfModelId);
        return {...m, elements: {...m.elements, items}};
      });

      return {caplaModels, pdfScale};
    } catch (e) {
      console.log("error", e);
    }
  }

  async loadCaplaAnnotationsEntities(modelId) {
    console.log("UPDATE MODEL 67");
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager.getAnnotationsList();
    annots.forEach((annot) => {
      const e = this.rawAnnotationToAnnotationEntity(annot, modelId);
      if (e) this.annotationEntities.push(e);
    });
    await this.dispatchUpdateModelWithElements({modelId}); // /!\ => trigger Sync
  }

  getCaplaModelElementsItems(caplaModelId, pdfModelId) {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager.getAnnotationsList();
    const items = [];
    annots.forEach((annot) => {
      const item = this.rawAnnotationToCaplaModelItem(
        annot,
        caplaModelId,
        pdfModelId
      );
      if (item) items.push(item);
    });
    return items;
  }

  // lock - unlock

  lockAnnotation(annotationId) {
    const annotation = this.findAnnotation(annotationId);
    if (annotation) {
      annotation.ReadOnly = true;
    }
  }
  unlockAnnotation(annotationId) {
    const annotation = this.findAnnotation(annotationId);
    if (annotation) {
      annotation.ReadOnly = false;
      annotation.NoDelete = true;
    }
  }
  lockAnnotationDeletion(annotationId) {
    const annotation = this.findAnnotation(annotationId);
    if (annotation) {
      annotation.NoDelete = true;
    }
  }
  unlockAnnotationDeletion(annotationId) {
    const annotation = this.findAnnotation(annotationId);
    if (annotation) {
      annotation.NoDelete = false;
    }
  }

  lockElementAnnotations(codeName) {
    const entities = this.annotationEntities.filter(
      (e) => e.codeName === codeName
    );
    entities.forEach((e) => this.lockAnnotation(e.annotationId));
  }
  unlockElementAnnotations(codeName) {
    const entities = this.annotationEntities.filter(
      (e) => e.codeName === codeName
    );
    entities.forEach((e) => this.unlockAnnotation(e.annotationId));
  }

  lockAnnotations() {
    this.annotationEntities.forEach((e) => this.lockAnnotation(e.annotationId));
  }

  unlockAnnotations() {
    this.annotationEntities.forEach((e) =>
      this.unlockAnnotation(e.annotationId)
    );
  }

  // utils

  getAnnotationsQuantities(annotations) {
    console.log("annotation", annotations);
    return annotations.reduce(
      (ac, cur) => {
        return this.addQuantities(ac, cur.quantities);
      },
      {count: 0, perimeter: 0, area: 0}
    );
  }

  addQuantities(A, B) {
    return {
      count: A.count + 1,
      perimeter: B.perimeter ? A.perimeter + B.perimeter : A.perimeter,
      area: B.area ? A.area + B.area : A.area,
    };
  }

  /*
   * annotation image
   */

  getAnnotationImageProps(annotation) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const pageNumber = annotation.PageNumber;
    const zoom = docViewer.getZoomLevel();
    const rotation = docViewer.getCompleteRotation(pageNumber);
    const height = docViewer.getPageHeight(pageNumber);
    const width = docViewer.getPageWidth(pageNumber);
    let x1 = annotation.X;
    let y1 = annotation.Y;
    let x2 = x1 + annotation.Width;
    let y2 = y1 + annotation.Height;

    let renderRect = {x1, y1, x2, y2};
    if (rotation === 1) {
      renderRect = {
        x1: height - renderRect.y2,
        y1: renderRect.x1,
        x2: height - renderRect.y1,
        y2: renderRect.x2,
      };
    } else if (rotation === 3) {
      renderRect = {
        x2: renderRect.y2,
        y2: width - renderRect.x1,
        x1: renderRect.y1,
        y1: width - renderRect.x2,
      };
    }
    return {zoom, pageRotation: rotation, pageNumber, renderRect};
  }

  /*
   * cuts
   */

  async getAnnotationCut(annotation) {
    const {documentViewer: docViewer} = this.webViewer.Core;
    const {zoom, renderRect, pageNumber} =
      this.getAnnotationImageProps(annotation);

    return new Promise((resolve) => {
      docViewer.getDocument().loadCanvas({
        pageNumber,
        drawComplete: function (canvas) {
          const url = canvas.toDataURL();
          resolve({url, zoom});
        },
        zoom,
        //pageRotation: rotation,
        renderRect,
      });
    });
  }

  setRectangleAnnotStyle() {
    const {documentViewer, Annotations} = this.webViewer.Core;
    const tool = documentViewer.getTool(
      this.webViewer.Core.Tools.ToolNames.RECTANGLE
    );
    //tool.setSnapMode(this.webViewer.Core.Tools.SnapModes.DEFAULT);

    tool.setStyles({
      StrokeThickness: 0.1,
      StrokeColor: new Annotations.Color(0, 255, 254), // cyan
      Opacity: 0.8,
    });
  }

  triggerAddCut() {
    this.setIsAddingCut(true);
    this.setRectangleAnnotStyle();
    this.webViewer.UI.setToolMode(["AnnotationCreateRectangle"]);
  }

  /*
   * Legend annotations
   */

  async createImageAnnotationAsync({
    imageUrl,
    x,
    y,
    width,
    height,
    pageNumber,
    annotationType = "LEGEND",
    showStroke = false,
  }) {
    try {
      const {Annotations, documentViewer: docViewer} = this.webViewer.Core;
      const document = docViewer.getDocument();
      const rotation = docViewer.getCompleteRotation(pageNumber);
      const annot = new Annotations.RectangleAnnotation();
      if (showStroke) {
        annot.StrokeColor = new Annotations.Color(245, 37, 133);
        annot.StrokeThickness = 3;
      }
      //const rotation = context.pageRotation;
      annot.PageNumber = pageNumber;
      annot.X = x;
      annot.Y = y;
      annot.Y = rotation === 1 || rotation === 3 ? y + height : y;
      annot.Width = rotation === 1 || rotation === 3 ? height : width;
      annot.Height = rotation === 1 || rotation === 3 ? width : height;
      console.log("rotation", rotation);
      annot.setCustomData("type", annotationType);
      //
      const doc = await this.webViewer.Core.createDocument(imageUrl, {
        useDownloader: false,
        fileName: annotationType.toLowerCase(),
        loadAsPDF: true,
      });
      //await doc.setRotation(4 - rotation, [1]);
      await doc.rotatePages([1], 4 - rotation);
      annot.addCustomAppearance(doc);
      //
      return annot;
    } catch (e) {
      console.log(e);
    }
  }
  /*
   * old and difference annotation
   */

  deleteOldPdfAndDifferenceAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) =>
        ["OLD_PDF", "DIFFERENCE"].includes(a.getCustomData("type"))
      );
    annotationManager.deleteAnnotations(annotations);
  }

  async updateDifferenceAnnotation({
    imageUrl,
    x,
    y,
    width,
    height,
    pageNumber,
  }) {
    const {annotationManager} = this.webViewer.Core;
    //
    const annot = await this.createImageAnnotationAsync({
      imageUrl,
      x,
      y,
      width,
      height,
      pageNumber,
      type: "DIFFERENCE",
      showStroke: true,
    });
    //
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
    //
    this.differenceAnnotation = annot;
  }

  showDifferenceAnnotation() {
    const {annotationManager} = this.webViewer.Core;
    if (this.differenceAnnotation)
      annotationManager.showAnnotation(this.differenceAnnotation);
  }
  hideDifferenceAnnotation() {
    const {annotationManager} = this.webViewer.Core;
    if (this.differenceAnnotation)
      annotationManager.hideAnnotation(this.differenceAnnotation);
  }

  deleteOldPdfAnnotation() {
    const {annotationManager} = this.webViewer.Core;
    if (this.oldPdfAnnotation) {
      annotationManager.deleteAnnotation(this.oldPdfAnnotation);
    }
  }

  async updateOldPdfAnnotation({imageUrl, x, y, width, height, pageNumber}) {
    const {annotationManager} = this.webViewer.Core;
    //
    if (this.oldPdfAnnotation)
      annotationManager.deleteAnnotation(this.oldPdfAnnotation);
    //
    const annot = await this.createImageAnnotationAsync({
      imageUrl,
      x,
      y,
      width,
      height,
      pageNumber,
      type: "OLD_PDF",
      showStroke: true,
    });
    //
    annotationManager.addAnnotation(annot);
    annotationManager.redrawAnnotation(annot);
    //
    this.oldPdfAnnotation = annot;
  }

  showOldPdfAnnotation() {
    const {annotationManager} = this.webViewer.Core;
    if (this.oldPdfAnnotation)
      annotationManager.showAnnotation(this.oldPdfAnnotation);
  }
  hideOldPdfAnnotation() {
    const {annotationManager} = this.webViewer.Core;
    if (this.oldPdfAnnotation)
      annotationManager.hideAnnotation(this.oldPdfAnnotation);
  }
  async setOldPdfOpacity(newOpacity) {
    const {annotationManager, documentViewer} = this.webViewer.Core;
  }
  /*
   * Issues annotations
   */

  // context annotation

  // used to fit page to issue
  createContextAnnotation(context, issueId) {
    try {
      const {Annotations, annotationManager} = this.webViewer.Core;
      const annot = new Annotations.RectangleAnnotation();
      annot.PageNumber = context.pageNumber;
      const rotation = context.pageRotation;
      const {x1, y1, x2, y2} = context.viewportCoords;
      annot.X = x1;
      annot.Y = rotation === 1 ? y2 : y1;
      annot.Width = Math.abs(x2 - x1);
      annot.Height = Math.abs(y2 - y1);
      annot.setCustomData("type", "ISSUE");
      annot.Id = issueId;
      annotationManager.addAnnotation(annot);
      return annot;
    } catch (e) {
      console.log(e);
    }
  }

  async createIssueAnnotation(issue) {
    try {
      const {annotationManager} = this.webViewer.Core;

      // test if it exists
      const existingAnnot = annotationManager
        .getAnnotationsList()
        .find((a) => a.Id === issue.id);
      if (existingAnnot) return;

      // creation
      const {imageUrl, context} = issue;

      const annot = this.createContextAnnotation(context.pdfContext, issue.id);

      const doc = await this.webViewer.Core.createDocument(imageUrl, {
        useDownloader: false,
        fileName: issue.name,
        loadAsPDF: true,
      });
      console.log("getPageCount", doc.getPageCount(), doc.getType());
      await doc.rotatePages([1], 4 - context.pdfContext.pageRotation);

      annot.addCustomAppearance(doc);
      annotationManager.redrawAnnotation(annot);
    } catch (e) {
      console.log(e);
    }
  }

  async createIssueAnnotations(issues) {
    for (let issue of issues) {
      await this.createIssueAnnotation(issue);
    }
  }

  deleteIssueAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    let annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "ISSUE");
    annotationManager.deleteAnnotations(annots);
  }

  /*
   * zoneImage <=>zoneAnnotation
   */

  getImagePointsCoordsInPdfRef({points, zone}) {
    const {x, y, width, height, pageRotation} = zone;

    // scale
    const mpd = zone.scale;

    function imagePointCoordsInPdfRef(px, py) {
      const _x = px / mpd;
      const _y = py / mpd;

      let X, Y;
      switch (pageRotation) {
        case 0:
          X = width / 2 + _x + x;
          Y = height / 2 - _y + y;
          break;
        case 1:
          X = width / 2 - _y + x;
          Y = height / 2 - _x + y;
          break;
        case 2:
          X = width / 2 - _x + x;
          Y = height / 2 + _y + y;
          break;
        case 3:
          X = width / 2 + _y + x;
          Y = height / 2 + _x + y;
          break;
      }
      return [X, Y];
    }

    return points.map(([x, y]) => {
      return imagePointCoordsInPdfRef(x, y);
    });
  }

  /*
   * BIM data
   */

  testHasBimData() {
    const {annotationManager} = this.webViewer.Core;
    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("hasBimData") === "true");

    return Boolean(annot);
  }

  deleteMeasurementsAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    let annots = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "MEASUREMENT");
    annotationManager.deleteAnnotations(annots);
  }
}
