import {useDispatch} from "react-redux";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

import {FormControlLabel, Switch, Typography} from "@mui/material";

export default function SwitchIsHelper({model}) {
  const dispatch = useDispatch();

  // strings

  const label = "Calque guide";

  // handlers

  function handleChange(e) {
    const isHelper = e.target.checked;
    const updatedModel = {...model, isHelper};
    dispatch(updateModel({updatedModel}));
  }
  return (
    <FormControlLabel
      control={
        <Switch size="small" onChange={handleChange} checked={model.isHelper} />
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
}
