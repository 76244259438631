import React from "react";

import {Button, Box} from "@mui/material";
import {Add} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
import theme from "Styles/theme";
export default function CreateSceneButton({onClick}) {
  const {t} = useTranslation(["scenes"]);

  // strings

  const createString = t("scenes:create.button");

  return (
    <Box>
      <Button
        variant="contained"
        sx={{
          bgcolor: "primary.flash",
          borderRadius: (theme) => theme.spacing(6),
        }}
        startIcon={<Add />}
        onClick={onClick}
      >
        {createString}
      </Button>
    </Box>
  );
}
