import React from "react";

import {useSelector} from "react-redux";
import CardShortcutSelectionZone from "./CardShortcutSelectionZone";

export default function CardShortcutSelection({scene}) {
  // data

  const zoneId = useSelector((s) => s.measurements.shortcutMobileZoneId);

  return <div>{zoneId && <CardShortcutSelectionZone scene={scene} />}</div>;
}
