import React from "react";

import {CircularProgress, Box} from "@mui/material";
import useModelSyncStatus from "Features/viewer3D/useModelSyncStatus";

export default function ListItemSecondaryActionSceneDataset({
  isScene,
  isModel,
  id,
}) {
  const status = useModelSyncStatus(isModel && id);

  const loading = status === "loading" || status === "downloading";

  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {loading && <CircularProgress size={16} />}
    </Box>
  );
}
