import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

import useUpdateElementTypesGroup from "./useUpdateElementTypesGroup";
import useElementTypeGroupsByScene from "./useElementTypeGroupsByScene";
import useElementTypesGroupsProxyBySceneWithElementTypes from "./useElementTypesGroupsProxyBySceneWithElementTypes";

export default function useUpdateSceneElementType(scene) {
  const dispatch = useDispatch();

  const elementTypesGroups =
    useElementTypesGroupsProxyBySceneWithElementTypes(scene);

  // const selectedElementTypesGroup = useSelectedElementTypesGroup(scene, {
  //   inEditMeasurements: sceneModule === "EDIT_MEASUREMENTS",
  // });

  const updateElementTypesGroup = useUpdateElementTypesGroup(scene);

  const updateSceneElementType = async (elementType) => {
    const selectedElementTypesGroup = elementTypesGroups.find(
      (g) => g.id === elementType.groupId
    );
    console.log(
      "debugafa updateSceneElementType. group:",
      selectedElementTypesGroup
    );
    if (selectedElementTypesGroup?.fromScene) {
      if (!scene) return;
      let oldElementTypes = scene?.data?.elementTypes.filter(
        (s) => s?.id !== elementType.id
      );
      let oldElementType = scene?.data?.elementTypes.find(
        (s) => s?.id !== elementType.id
      );
      if (!oldElementTypes) oldElementTypes = [];

      const newScene = {
        ...scene,
        data: {
          ...scene?.data,
          elementTypes: [
            ...oldElementTypes,
            {...oldElementType, ...elementType},
          ],
        },
      };

      dispatch(updateScene({scene: newScene}));
    } else {
      const newElementTypes = selectedElementTypesGroup.elementTypes.map(
        (r) => {
          if (r.id === elementType.id) {
            return {...r, ...elementType};
          } else {
            return r;
          }
        }
      );

      const updatedElementTypesGroup = {
        ...selectedElementTypesGroup,
        elementTypes: newElementTypes,
      };

      await updateElementTypesGroup(updatedElementTypesGroup);
    }
  };

  return updateSceneElementType;
}
