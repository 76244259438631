import copyTypes from "./copyTypes";

export default function getTypeArticlesMap(articles) {
  //

  if (!articles) return {};
  //
  let taMap = {}; // {elementTypeId:[{articleId,articlesGroupId,func,filtersStr,getVoids}]}
  //
  articles.forEach((article) => {
    const articleId = article?.id;
    const articleTypes =
      article?.types && typeof article?.types === "object"
        ? copyTypes(article?.types)
        : {};
    const getVoids = article.code?.startsWith("VIDE");
    //
    if (Object.keys(articleTypes).length === 0) return {};
    //
    Object.entries(articleTypes).forEach(([groupId, typeMap]) => {
      if (groupId !== "filtersStr" && groupId !== "globalFunc") {
        Object.entries(typeMap).forEach(([typeId, value]) => {
          if (typeId !== "filtersStr") {
            const func = value?.func;
            const filtersStr = value?.filtersStr;

            //
            if (!taMap[typeId]) {
              taMap[typeId] = [
                {
                  articleId,
                  articlesGroupId: groupId,
                  func,
                  filtersStr,
                  getVoids,
                },
              ];
            } else {
              taMap[typeId].push({
                articleId,
                articlesGroupId: groupId,
                func,
                filtersStr,
              });
            }
          }
        });
      }
    });
  });
  //
  return taMap;
}
