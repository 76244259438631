import {useState} from "react";

import {Box} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import IconElementType from "./IconElementType";

import DialogSelectorColor from "Components/DialogSelectorColor";
import {ArrowDropUp} from "@mui/icons-material";

export default function SelectorElementTypeColorIcon({
  color,
  drawingShape,
  onChange,
  editable,
}) {
  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleColorChange(color) {
    if (onChange) onChange(color);
    setOpen(false);
  }

  function handleClick() {
    if (editable) setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          cursor: editable ? "pointer" : "default",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 1,
        }}
      >
        <IconElementType color={color} drawingShape={drawingShape} />
        {editable && <Down />}
      </Box>
      <DialogSelectorColor
        color={color}
        onChange={handleColorChange}
        open={open}
        onClose={handleCloseDialog}
      />
    </>
  );
}
