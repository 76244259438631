import {useKvfTranslationMap} from "./useKvfTranslationMap";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function useKvtMeasurementOverview(scene) {
  const translationMap = useKvfTranslationMap();

  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);

  const kvt = {
    key: "measurementOverview",
    groups: [
      {code: "PROPS", label: translationMap["props"]},
      {code: "GEOMETRY", label: translationMap["geometry"]},
      {code: "QUANTITIES", label: translationMap["quantities"]},
      {code: "CATEGORIES", label: translationMap["categories"]},
    ],
    fields: [
      {
        key: "identification",
        name: translationMap["identification"],
        type: "section",
        group: "PROPS",
      },
      {
        group: "PROPS",
        key: "elementTypeId",
        name: translationMap["elementType"],
        type: "elementTypeIdOverview",
      },
      {
        group: "PROPS",
        key: "codeName",
        name: translationMap["codeName"],
        type: "text",
      },
      {
        group: "GEOMETRY",
        key: "sectionGeometry",
        name: translationMap["geometry"],
        type: "section",
      },
      {
        group: "GEOMETRY",
        key: "drawingShape",
        name: translationMap["shape"],
        type: "selector",
        options: [
          {key: "SEGMENT", name: translationMap["segment"]},
          {key: "BEAM", name: translationMap["beam"]},
          {key: "POLYLINE", name: translationMap["polyline"]},
          {key: "GABLE", name: translationMap["gable"]},
          {key: "SLOPE", name: translationMap["slope"]},
          {key: "STAIRS", name: translationMap["stairs"]},
          {key: "RECTANGLE", name: translationMap["rectangle"]},
          {key: "POLYGON", name: translationMap["polygon"]},
          {key: "SLOPING_POLYGON", name: translationMap["slopingPolygon"]},
          {key: "BANK", name: translationMap["bank"]},
          {key: "CIRCLE", name: translationMap["circle"]},
          {key: "BRIDGE", name: translationMap["bridge"]},
          {key: "BOWL", name: translationMap["bowl"]},
        ],
      },
      {
        key: "helperImage",
        type: "imageRead",
        height: "140px",
        group: "GEOMETRY",
      },

      {
        group: "GEOMETRY",
        key: "dim1",
        name: translationMap["dim1"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "STAIRS",
            "POLYLINE",
            "BRIDGE",
            "RECTANGLE",
            "CIRCLE",
            "BANK",
            "GABLE",
          ],
        },
      },
      {
        group: "GEOMETRY",
        key: "dim2",
        name: translationMap["dim2"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SEGMENT", "BEAM", "STAIRS", "RECTANGLE", "BANK"],
        },
      },
      {
        group: "GEOMETRY",
        key: "dim3",
        name: translationMap["dim3"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "GEOMETRY",
        key: "height",
        name: translationMap["height"],
        type: "number",
      },
      {
        group: "GEOMETRY",
        key: "offset",
        name: translationMap["offset"],
        type: "number",
      },
      {
        group: "GEOMETRY",
        key: "zInf",
        name: translationMap["zInf"],
        type: "number",
      },
      {
        group: "GEOMETRY",
        key: "zSup",
        name: translationMap["zSup"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "quantities",
        name: translationMap["quantities"],
        type: "section",
      },
      {
        group: "QUANTITIES",
        key: "count",
        name: translationMap["countX"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "length",
        name: translationMap["lengthInM"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "area",
        name: translationMap["areaInM2"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "volume",
        name: translationMap["volumeInM3"],
        type: "number",
      },
      {
        group: "QUANTITIES",
        key: "res",
        name: translationMap["ressources"],
        type: "ressources",
      },
      {
        group: "CATEGORIES",
        type: "combo",
        key: "sector",
        name: translationMap["sector"],
        options: sectors,
      },
      {
        group: "CATEGORIES",
        type: "combo",
        key: "room",
        name: translationMap["room"],
        options: rooms,
      },
    ],
  };

  return kvt;
}
