import useColoringsByUser from "./useColoringsByUser";
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchColoringScene} from "Features/colorings/coloringsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useColoringScene(coloringId) {
  const scenes = useSelector((s) => s.scenes.items);
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const fetchedColoringSceneId = useSelector(
    (s) => s.colorings.fetchedColoringSceneId
  );

  useEffect(() => {
    if (accessToken && coloringId) {
      dispatch(fetchColoringScene({accessToken, coloringId}));
    }
  }, [accessToken, coloringId]);

  return scenes.find((s) => s.id === fetchedColoringSceneId);
}
