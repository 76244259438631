import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  fetchDocumentsService,
  createDocumentService,
  createVersionService,
} from "./services";

export const fetchDocuments = createAsyncThunk(
  "documents/fetchDocuments",
  fetchDocumentsService
);

export const createDocument = createAsyncThunk(
  "documents/createDocument",
  createDocumentService
);

export const createVersion = createAsyncThunk(
  "documents/createVersion",
  createVersionService
);

const documentsSlice = createSlice({
  name: "documents",
  initialState: {datasets: {}},
  extraReducers: {
    [fetchDocuments.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId] = {data, status: "succeed"};
      state.datasets[listingId].data.sort((a, b) =>
        b.updatedAt.localeCompare(a.updatedAt)
      );
    },
    [createDocument.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId].data.push(data);
      state.datasets[listingId].data.sort((a, b) =>
        b.updatedAt.localeCompare(a.updatedAt)
      );
    },
    [createVersion.fulfilled]: (state, action) => {
      const {data, listingId, documentId} = action.payload;
      const newDocuments = state.datasets[listingId].data.map((document) => {
        if (document.id !== documentId) {
          return document;
        } else {
          return data;
        }
      });
      state.datasets[listingId].data = newDocuments;
    },
  },
});

export default documentsSlice.reducer;
