import React, {useState} from "react";

import {Box, IconButton, Grid, Typography} from "@mui/material";
import {ArrowDropDown as Down, ArrowDropUp as Up} from "@mui/icons-material";

export default function FieldImageRead({imageUrl, height, imageName}) {
  // string

  const imageS = "Image";

  const [open, setOpen] = useState(true);

  return (
    <>
      {!open ? (
        <Grid
          container
          sx={{
            px: 1,
            width: 1,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid xs={6} item>
            <Box sx={{display: "flex", height: 1, alignItems: "center"}}>
              <Typography sx={{fontSize: 13}} noWrap>
                {imageS}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} item>
            <Box
              sx={{
                display: "flex",
                height: 1,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{fontSize: 13}} noWrap>
                {imageName}
              </Typography>
              <IconButton
                onClick={() => setOpen(true)}
                sx={{height: "30px", width: "20px"}}
              >
                <Down fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <img src={imageUrl} height={height} />
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              height: "30px",
              width: "20px",
              position: "absolute",
              right: "4px",
              top: 0,
            }}
          >
            <Up fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
}
