import {useState} from "react";

import {
  Card,
  CardContent,
  FormGroup,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
// import {capitalizeStart} from "Features/translations/utils";

export default function CardBackgroundGrid({editor3d}) {
  const {t} = useTranslation("viewer3D");

  // strings

  const titleString = t("viewer3D:settings.background.title");
  const toggleString = t("viewer3D:settings.background.switchLabel");
  const flashModeString = t("viewer3D:settings.background.flashMode");
  const imageResoAutoString = "Auto image resolution (beta)";

  // local state

  const [transparent, setTransparent] = useState(editor3d?.transparentBackground);
  const [flashMode, setFlashMode] = useState(editor3d?.flashDarkMode.isEnabled);
  const [imageResoAuto, setImageResoAuto] = useState(
    editor3d?.context.imageResoAutoUpdate
  );

  // handlers

  function handleSwitchChange(e) {
    const checked = e.target.checked;
    setTransparent(checked);
    editor3d?.toggleTransparentBackground();
  }

  function handleFlashSwitchChange(e) {
    const checked = e.target.checked;
    setFlashMode(checked);
    if (checked) {
      editor3d?.startFlashDarkMode();
    } else {
      editor3d?.startStandardMode();
    }
  }

  function handleImageResoAutoChange(e) {
    const checked = e.target.checked;
    setImageResoAuto(checked);
    if (editor3d) editor3d.context.imageResoAutoUpdate = checked;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="body2" sx={{mb: 2}} gutterBottom>
          <b>{titleString}</b>
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={transparent}
                onChange={handleSwitchChange}
              />
            }
            size="small"
            label={<Typography variant="body2">{toggleString}</Typography>}
          />
        </FormGroup>

        <FormGroup sx={{mt: 2}}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={flashMode}
                onChange={handleFlashSwitchChange}
              />
            }
            size="small"
            label={<Typography variant="body2">{flashModeString}</Typography>}
          />
        </FormGroup>

        <FormGroup sx={{mt: 2}}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={imageResoAuto}
                onChange={handleImageResoAutoChange}
              />
            }
            size="small"
            label={
              <Typography variant="body2">{imageResoAutoString}</Typography>
            }
          />
        </FormGroup>
      </CardContent>
    </Card>
  );
}
