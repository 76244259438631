import {useDispatch, useSelector} from "react-redux";

import {Box, Paper, IconButton} from "@mui/material";
import {Difference} from "@mui/icons-material";
import {setShowSectionDifference} from "../pdfSlice";

import SectionDifferenceNewOldPdf from "./SectionDifferenceNewOldPdf";

export default function ButtonSectionDifference({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // data

  const show = useSelector((s) => s.pdf.showSectionDifference);

  // handlers

  function handleClick() {
    dispatch(setShowSectionDifference(!show));
  }
  return (
    <Box sx={{display: "flex"}}>
      <Box sx={{}}>
        <Paper elevation={show ? 12 : 3}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{borderRadius: "4px"}}
          >
            <Difference size="small" />
          </IconButton>
        </Paper>
      </Box>
      {show && (
        <Box sx={{ml: 2}}>
          <SectionDifferenceNewOldPdf caplaEditor={caplaEditor} scene={scene} />
        </Box>
      )}
    </Box>
  );
}
