import {createSlice} from "@reduxjs/toolkit";

export const packagesSlice = createSlice({
  name: "packages",
  initialState: {
    selectedPackageId: null,
    lastPackageId: null,
    newPackage: {name: null, fromModel: null, revisionIds: null},

    // dialogs
    openDialogCreatePackage: false,
    openPackagesPanel: false,
  },
  reducers: {
    setSelectedPackageId: (state, action) => {
      state.selectedPackageId = action.payload;
    },
    setLastPackageId: (state, action) => {
      const lastPackageId = action.payload;
      console.log("[STATE] setLastPackageId", lastPackageId);
      state.lastPackageId = lastPackageId;
    },
    setNewPackage: (state, action) => {
      state.newPackage = action.payload;
    },
    setOpenDialogCreatePackage: (state, action) => {
      state.openDialogCreatePackage = action.payload;
    },
    setOpenPackagesPanel: (state, action) => {
      state.openPackagesPanel = action.payload;
    },
  },
});

export const {
  setSelectedPackageId,
  setLastPackageId,
  setNewPackage,
  setOpenDialogCreatePackage,
  setOpenPackagesPanel,
} = packagesSlice.actions;

export default packagesSlice.reducer;
