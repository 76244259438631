import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormRoom from "./FormRoom";

import useSelectedRoom from "../hooks/useSelectedRoom";
import {setEditedRoom, setTempRoom} from "../roomsSlice";

export default function SectionRoomInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const isEditing = useSelector((s) => s.rooms.isEditingRoom);
  const editedRoom = useSelector((s) => s.rooms.editedRoom);
  const tempRoom = useSelector((s) => s.rooms.tempRoom);
  const selectedRoom = useSelectedRoom(scene);

  // helper

  const isTemp = !selectedRoom && !isEditing;
  const isEdited = selectedRoom && isEditing;
  const isSelected = selectedRoom && !isEditing;

  // handlers

  function handleTempRoomChange(room) {
    dispatch(setTempRoom(room));
  }
  function handleEditedRoomChange(room) {
    dispatch(setEditedRoom(room));
  }
  return (
    <Box sx={{pt: 4}}>
      <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
        {isTemp && (
          <FormRoom
            room={tempRoom}
            onChange={handleTempRoomChange}
            canEdit={true}
          />
        )}
        {isSelected && <FormRoom room={selectedRoom} canEdit={false} />}
        {isEdited && (
          <FormRoom
            room={editedRoom}
            onChange={handleEditedRoomChange}
            canEdit={true}
          />
        )}
      </Box>
    </Box>
  );
}
