/*
 * ressource : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import {updateRessourcesGroup} from "../ressourcesSlice";
import useSelectedRessourcesGroup from "./useSelectedRessourcesGroup";

export default function useAddSceneRessource(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const selectedGroup = useSelectedRessourcesGroup(scene);

  const addSceneRessource = async (ressource) => {
    if (selectedGroup.fromScene) {
      if (!scene) return;
      let oldRessources = scene?.data?.ressources;
      if (!oldRessources) oldRessources = [];

      const newRessource = {...ressource, group: selectedGroup.name}; // important : add group

      const newScene = {
        ...scene,
        data: {...scene?.data, ressources: [...oldRessources, newRessource]},
      };

      dispatch(updateScene({scene: newScene}));
    } else {
      const newRessources = Array.isArray(selectedGroup.ressources)
        ? [...selectedGroup.ressources]
        : [];
      newRessources.push(ressource);
      const ressourcesGroup = {...selectedGroup, ressources: newRessources};
      await dispatch(updateRessourcesGroup({accessToken, ressourcesGroup}));
    }
  };

  return addSceneRessource;
}
