import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  // ListItemText,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  Close,
} from "@mui/icons-material";

import ListItemRessource from "./ListItemRessource";
import sortByNum from "Utils/sortByNum";
import getGroupObjectsFromRessources from "../utils/getGroupsFromRessources";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import SelectorRessourcesGroupWithCountAndPreview from "./SelectorRessourcesGroupWithCountAndPreview";
import {useSelector} from "react-redux";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

import {FixedSizeList, VariableSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export default function ListRessourcesMultipleWithGroups({
  scene,
  caplaEditor,
  selectedRessourceIds,
  ressources = [],
  groupsProxy, // [{id,name,fromScene}] : optional. If used, the groups won't be computed from ressources but from this list.
  onSelectionChange,
  multipleSelection,
  selectedGroupId,
  onSelectedGroupProxyChange, // {id,name,fromScene}
  counters, // {groupId:count,} counter used in chip
  showZero, // show counter = 0 in chip
  onCreateGroup, // => show + button to trigger creation.
  onCreateGroupLabel,
  forceSelection, // if true, return one default selection
  hideQuantities,
  maxHeightGroups,
  showTypes = false, // show related types when selected.
  resetSelectionOnGroupChange = true,
  variant = "chips",
}) {
  const dispatch = useDispatch();

  // strings

  const groupS = "Tableau d'articles";

  // data

  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // helpers

  const filteredRessources = ressources.filter(
    (t) => !selectedGroupId || t.groupId === selectedGroupId
  );

  // state

  const [openGroups, setOpenGroups] = useState(false);

  // helpers - groups

  let groupObjects = getGroupObjectsFromRessources(ressources, {
    sortByName: true,
  });
  if (groupsProxy) groupObjects = [...groupsProxy];

  const selectedGroupObject = groupObjects.find(
    (g) => g.id === selectedGroupId
  );

  const emptySelection =
    !selectedGroupId ||
    !groupObjects.map((g) => g.id).includes(selectedGroupId); // to avoid empty name.

  useEffect(() => {
    if (emptySelection && groupObjects?.length > 0) {
      if (forceSelection) onSelectedGroupProxyChange(groupObjects[0]);
    }
  }, [emptySelection, groupObjects?.length]);

  // helpers - groupsLabel

  const count = groupObjects.length;
  const groupsLabel = `Tableaux d'articles (x${count})`;

  // handlers

  function handleGroupClick(group, options) {
    //
    const closeGroupsList = options?.closeGroupsList;
    //
    if (group?.id && selectedGroupId !== group?.id) {
      // used to avoid to have one hidden selection...
      if (resetSelectionOnGroupChange) onSelectionChange([]);
    }
    onSelectedGroupProxyChange(group);
    //
    if (closeGroupsList) setOpenGroups(false);
  }

  function handleClick(ressource) {
    console.log("click on ressource", ressource);
    const id = ressource?.id;
    const idIsSelected = selectedRessourceIds?.includes(id);
    let newIds = selectedRessourceIds?.filter((typeId) => typeId !== id);
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (ressource.isTitle) {
        return;
      } else {
        if (!selectedRessourceIds?.includes(id)) newIds.push(id);
      }
    } else {
      newIds = idIsSelected ? [] : [id];
    }
    if (onSelectionChange) onSelectionChange(newIds);
  }

  // virtualized list

  const itemData = filteredRessources
    .sort((a, b) => sortByNum(a.num, b.num))
    .map((ressource) => {
      let selected = false;
      if (Array.isArray(selectedRessourceIds))
        selected = selectedRessourceIds?.includes(ressource?.id);
      return {...ressource, selected};
    });

  function getItemSize(index) {
    return 28;
  }

  function renderRow(props) {
    const {index, style} = props;
    const ressource = itemData[index];

    return (
      <ListItemButton
        style={{
          ...style,
          marginTop: 0,
          marginBottom: 0,
          paddingBottom: 0,
          paddingTop: 0,
          minHeight: 0,
        }}
        //component="div"
        key={index}
        divider
        disablePadding
        disableGutters
        selected={ressource.selected}
        onClick={() => handleClick(ressource)}
        sx={{m: 0, p: 0}}
      >
        <ListItemRessource
          key={ressource.id}
          scene={scene}
          ressource={ressource}
          selected={ressource.selected}
          options={{
            hideQuantity: hideQuantities,
            showQtyNet: !filterNoVoids,
            showTypes: showTypes,
          }}
          //onClick={handleClick}
        />
      </ListItemButton>
    );
  }

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", flexGrow: 1, minHeight: 0}}
    >
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={groupObjects}
            selectedItem={selectedGroupObject}
            onClick={handleGroupClick}
            counters={counters}
            onCreate={onCreateGroup}
            createLabel={onCreateGroupLabel}
            showZero={showZero}
            maxHeight={maxHeightGroups}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                fontSize: 13,
                color: "secondary.main",
                p: 0.5,
                px: 1,
                visibility: openGroups ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: openGroups ? "common.white" : "secondary.main",
              }}
            >
              <Box
                onClick={() => setOpenGroups((open) => !open)}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: "text.secondary",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {!openGroups ? selectedGroupObject?.name : groupsLabel}
                </Typography>
                {!openGroups ? <Down /> : <Close color="inherit" />}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {!openGroups && (
        <Box
          sx={{
            bgcolor: "common.white",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0,
            //overflowY: "auto",
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <AutoSizer>
            {({height, width}) => (
              <VariableSizeList
                height={height}
                width={width}
                itemSize={getItemSize}
                itemCount={filteredRessources.length}
                overscanCount={5}
                itemData={itemData}
              >
                {renderRow}
              </VariableSizeList>
            )}
          </AutoSizer>
        </Box>
      )}
      {openGroups && variant === "list" && (
        <Box sx={{overflowY: "auto"}}>
          <SelectorRessourcesGroupWithCountAndPreview
            items={groupObjects}
            selectedItem={selectedGroupObject}
            onClick={handleGroupClick}
            counters={counters}
            showZero={showZero}
          />
        </Box>
      )}
    </Box>
  );
}
