import {Box, List, ListItemButton, Typography} from "@mui/material";

export default function SelectorRoomsGroup({selectedItem, items, onClick}) {
  // string

  const noGroupS = "Aucune arborescence";
  // handler

  function handleClick(item) {
    onClick(item, {closeGroupsList: true});
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      {items?.length > 0 ? (
        <List dense disablePadding sx={{bgcolor: "background.default"}}>
          {items.map((item) => {
            let name = item?.name ? item.name : "-?-";
            let selected = item.id === selectedItem?.id;
            let color = "secondary";
            let count;

            return (
              <ListItemButton
                divider
                selected={selected}
                color={color}
                key={item.id}
                size="small"
                onClick={() => handleClick(item)}
              >
                <Box sx={{p: 1, width: 1}}>
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      bgcolor: "common.white",
                      p: 1,
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: 1,
                      }}
                    >
                      <Typography sx={{fontSize: 13}}>{name}</Typography>
                    </Box>
                  </Box>
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Box>
          <Typography>{noGroupS}</Typography>
        </Box>
      )}
    </Box>
  );
}
