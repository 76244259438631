/*
 * ressource : {id,num,code,name,group,unit,dec,refQty}
 */
import {nanoid} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import {updateScene} from "Features/scenes/scenesSlice";
import {updateRessourcesGroup} from "../ressourcesSlice";

import useSelectedRessourcesGroup from "./useSelectedRessourcesGroup";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateSceneRessources(scene) {
  const accessToken = useAccessToken();
  // anonym ressources = ressources identified with their code only.

  const dispatch = useDispatch();

  const selectedGroup = useSelectedRessourcesGroup(scene);

  const updateSceneRessource = async (ressources) => {
    const oldRessources = selectedGroup.ressources;
    const ressourcesIds = ressources.map((r) => r.id);

    // unchanged

    let unchangedRessources = oldRessources.filter(
      (r) => !ressourcesIds.includes(r.id)
    );

    // updated
    let updatedOldRessources = oldRessources.filter((r) =>
      ressourcesIds.includes(r.id)
    );
    let updatedNextRessources = [];
    if (updatedOldRessources) {
      updatedNextRessources = updatedOldRessources.map((prevRessource) => {
        const newRessource = ressources.find((r) => r.id === prevRessource.id);
        return {...prevRessource, ...newRessource};
      });
    }

    // news
    let oldIds = oldRessources.map((r) => r.id);
    let newRessources = ressources.filter((r) => !oldIds.includes(r.id));
    newRessources = newRessources.map((r) => ({id: nanoid(), ...r}));

    // newGroupRessources

    const newGroupRessources = [
      ...unchangedRessources,
      ...updatedNextRessources.filter((r) => !r.toDelete),
      ...newRessources,
    ];

    if (selectedGroup.fromScene) {
      if (!scene) return;
      const newScene = {
        ...scene,
        data: {
          ...scene?.data,
          ressources: newGroupRessources,
        },
      };
      dispatch(updateScene({scene: newScene}));
    } else if (accessToken) {
      const ressourcesGroup = {
        ...selectedGroup,
        ressources: newGroupRessources,
      };
      await dispatch(updateRessourcesGroup({accessToken, ressourcesGroup}));
    }
  };

  return updateSceneRessource;
}
