export default function getSectorMeasurementsMap(measurements) {
  const sectorMap = {};
  measurements.forEach((measurement) => {
    const sectorId = measurement.sectorId ?? "undefined";
    if (!sectorMap[sectorId]) {
      sectorMap[sectorId] = [measurement];
    } else {
      sectorMap[sectorId].push(measurement);
    }
  });
  return sectorMap;
}
