import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FieldZoneSlope({zone, editorPdf}) {
  // strings

  const slopeS = "Orientation";
  const verticalS = "Vertical";
  const horizontalS = "Horizontal";

  // template

  const template = {
    key: "kvtZoneSlope",
    fields: [
      {
        key: "slope",
        name: slopeS,
        type: "selector",
        options: [
          {key: "HORIZONTAL", name: horizontalS},
          {key: "VERTICAL", name: verticalS},
        ],
      },
    ],
  };

  // helpers

  const angle = zone?.rotation.x;
  //const isVertical = angle === 0;
  const isHorizontal = Math.abs(angle + Math.PI / 2) < 0.01;

  //const item = {slope: isVertical ? "VERTICAL" : "HORIZONTAL"};
  const item = {slope: isHorizontal ? "HORIZONTAL" : "VERTICAL"};

  // handlers

  function handleSlopeChange({slope}) {
    const isVertical = slope === "VERTICAL";

    let rotation = {...zone.rotation, x: 0};
    if (!isVertical) rotation.x = -Math.PI / 2;

    editorPdf?.annotationsManager.updateAnnotationEntityRotation({
      annotationId: zone.id,
      rotation,
    });
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleSlopeChange}
    />
  );
}
