import {useSelected, useFocused} from "slate-react";

import {CircularProgress, Box, alpha, IconButton, Button} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import ImagePreview from "Features/images/components/ImagePreview";

export default function Viewpoint({
  attributes,
  children,
  element,
  editor3d,
  // sceneClientId,
}) {
  const showS = "Superposer l'image à la 3D";

  // data

  const selected = useSelected();
  const focused = useFocused();
  const loading = element.loading;

  // handlers

  function handleViewpointClick() {
    // camera
    const camera = element?.camera;
    if (camera) editor3d?.sceneEditor.updateCamera(camera);
    // context
    const sceneContext = element?.context?.sceneContext;
    if (sceneContext) editor3d?.sceneEditor.applyContext(sceneContext);
  }

  function handleImageOverlayClick() {
    handleViewpointClick();
    editor3d?.imageOverlay.setImage(element.image);
    editor3d?.imageOverlay.show();
  }

  return (
    <Box {...attributes} sx={{width: 1, height: 100}}>
      <Box
        contentEditable={false}
        sx={{position: "relative", width: 1, height: 1}}
      >
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              width: "100%",
              zIndex: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: (theme) =>
                alpha(theme.palette.common.caplaBlack, 0.8),
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!loading && (
          <Box
            sx={{
              width: 1,
              height: 1,
              boxShadow: `${
                selected && focused ? "0 0 0 3px #B4D5FF" : "none"
              }`,
            }}
          >
            <ImagePreview src={element.image} outlined detail={false} />
          </Box>
        )}
        <Box
          sx={{
            bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
            position: "absolute",
            top: 0,
            right: 0,
            color: "primary.flash",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            variant="outlined"
            size="small"
            onClick={handleViewpointClick}
            color="inherit"
          >
            <Focus fontSize="small" color="inherit" />
          </IconButton>
        </Box>
        <Box
          sx={{
            bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
            position: "absolute",
            bottom: 0,
            left: 0,
            color: "text.secondary",
          }}
        >
          <Button
            onClick={handleImageOverlayClick}
            size="small"
            color="inherit"
          >
            {showS}
          </Button>
        </Box>
      </Box>
      {children}
    </Box>
  );
}
