import {useState, useRef, useEffect} from "react";
import {useDispatch} from "react-redux";

import {Snackbar, Alert, Box, Button} from "@mui/material";

import {setSelectedMarkersModelId} from "../markersSlice";
import useMarkersModels from "../hooks/useMarkersModels";
import useSelectedMarkersModel from "../hooks/useSelectedMarkersModel";

import SelectorAutocompleteWithCreate from "Components/SelectorAutocompleteWithCreate";

import {createModel} from "Features/viewer3D/viewer3DSlice";
import MarkersModel from "Features/viewer3D/js/MarkersModel";

export default function SelectorMarkersModel({scene, editor3d}) {
  const dispatch = useDispatch();
  const bref = useRef(); // to remove focus when a new option is created.

  // strings

  const scopeString = "Lists of markers";
  const createLabel = "New list";
  const messageS = "New list created";

  // data

  const models = useMarkersModels(scene?.clientId);

  const selectedMarkersModel = useSelectedMarkersModel();

  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  // helpers

  const name = scopeString;
  const value = selectedMarkersModel ? selectedMarkersModel : null;
  const options = models;

  // handlers - snack

  function handleSnackClose() {
    setOpenSnack(false);
  }

  // effects

  useEffect(() => {
    if (options.length > 0 && !value) {
      const modelId = options[0].id;
      dispatch(setSelectedMarkersModelId(modelId));

      models.forEach((m) => {
        const e = editor3d?.getEntity(m.id);
        if (m.id === modelId) {
          e?.show();
          if (!e?.initialMarkersAreLoaded) e?.loadMarkers();
        } else {
          e?.hide();
        }
      });
    }
  }, [options.length, value]);
  // handlers

  // function handleFocus() {
  //   setOpen(true);
  // }
  function handleInputChange() {
    if (!open) setOpen(true);
  }

  function handleChange(v, _) {
    const modelId = v?.id;
    dispatch(setSelectedMarkersModelId(modelId));

    models.forEach((m) => {
      const e = editor3d?.getEntity(m.id);
      if (m.id === modelId) {
        e?.show();
        if (!e?.initialMarkersAreLoaded) e?.loadMarkers();
      } else {
        e?.hide();
      }
    });
    setOpen(false);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleNewOption(option) {
    setOpen(false);
    console.log("option", option);
    setOpenSnack(true);
    bref.current.focus();

    const model = MarkersModel.computeInitialModel({
      name: option.name,
      sceneClientId: scene?.clientId,
    });
    const entity = new MarkersModel({model, editor3d});
    editor3d?.entities.push(entity);
    dispatch(createModel({model}));

    dispatch(setSelectedMarkersModelId(model.id));
  }

  return (
    <Box sx={{width: 300, display: "flex"}}>
      <SelectorAutocompleteWithCreate
        onClose={handleClose}
        name={name}
        value={value}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onNewOption={handleNewOption}
        createLabel={createLabel}
        canCreate={true}
      />
      <Button
        ref={bref}
        sx={{width: "1px", height: "1px", visibility: "hidden"}}
      />

      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{width: "100%"}}
        >
          {messageS}
        </Alert>
      </Snackbar>
    </Box>
  );
}
