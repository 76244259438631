import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchSceneToSyncService} from "./services";

// import useScene from "Features/scenes/useScene";
import {setGotRemoteScene} from "Features/sync/syncSlice";
import useAccessToken from "Features/auth/useAccessToken";
import usePageType from "Features/navigation/usePageType";
import useColoringModel from "Features/colorings/useColoringModel";

/*
 * /!\ if several models with same id (clientId), remote models from the scene & the local models may differ.
 * this is due to models cleaning in the list.
 */
export function useClientAndRemoteSceneModels(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  //const {sceneId} = useParams();
  // const scene = useScene(scene);

  // state

  const remoteScene = useSelector((s) => s.scenes.sceneToSync);

  // data

  const pageType = usePageType();

  const coloringModel = useColoringModel();

  // const gotRemoteScene = useSelector((s) => s.sync.gotRemoteScene);

  // const refreshDate = useSelector((s) => s.scenes.refreshDate);

  let remoteModels = remoteScene?.models ? remoteScene.models : [];

  const scenesStatus = useSelector((s) => s.viewer3D.scenesStatus);

  let models = useSelector((s) => s.viewer3D.models)
    .filter(
      (m) =>
        (m.url ||
          m.fileRemoteUrl ||
          m.type === "MEASUREMENTS" ||
          m.type === "MARKERS") &&
        m.type !== "ANNOTATED_PDF" && // pdf with annotations.
        m.type !== "TEMP_PDF"
    )
    .filter((m) => m.sceneClientId === scene?.clientId);

  // helpers - models filter, coloring only or scene

  if (pageType === "COLORING") {
    models = models.filter((m) => m.id === coloringModel.id);
    remoteModels = remoteModels.filter((m) => m.id === coloringModel.id);
  } else {
    models = models.filter((m) => !m.fromColoring);
    remoteModels = remoteModels.filter((m) => !m.fromColoring);
  }

  // helper - scene status

  const sceneStatus = scenesStatus[scene?.clientId];

  // helpers

  const modelsIds = models.map((m) => m.id);
  const remoteModelsIds = remoteModels.map((m) => m.id);

  // helpers - effect dependency to read remote scene

  const modelsVersionsS =
    models.map((m) => (m?.version ? m.version : "-")).join("-") +
    "-" +
    scene?.version;

  //console.log("modelsVersionsS", modelsVersionsS);

  // helpers - client only

  const modelsIdsClientOnly = [];
  modelsIds.forEach((id) => {
    if (!remoteModelsIds.includes(id)) modelsIdsClientOnly.push(id);
  });

  // helpers - remote only

  const modelsIdsRemoteOnly = [];
  remoteModelsIds.forEach((id) => {
    if (!modelsIds.includes(id)) modelsIdsRemoteOnly.push(id);
  });

  // helpers - client and remote

  const modelsIdsClientAndRemote = [];
  modelsIds.forEach((id) => {
    if (remoteModelsIds.includes(id)) modelsIdsClientAndRemote.push(id);
  });

  // helpers - sceneModels

  let sceneModels = [];

  // scene

  sceneModels.push({
    sceneId: scene?.id,
    sceneClientId: scene?.clientId,
    modelId: null,
    isScene: true,
    id: scene?.clientId,
    clientName: scene?.title,
    clientVersion: scene?.version,
    remoteName: remoteScene?.title,
    remoteVersion: remoteScene?.version,
    nextSync: scene?.nextSync,
    actionType: scene?.nextSync?.action && "updateRemoteScene",
    // syncStatus: scenesSyncStatus.find(
    //   (s) => s.sceneClientId === scene?.clientId
    // ),
  });

  // models

  modelsIdsClientOnly.forEach((id) => {
    const model = models.find((m) => m.id === id);
    sceneModels.push({
      sceneId: scene?.id,
      sceneClientId: scene?.clientId,
      modelId: model.id,
      isModel: true,
      id: model.id,
      type: model.type,
      clientName: model.name,
      clientVersion: model.version,
      nextSync: model.nextSync,
      actionType: model.version ? "deleteClientModel" : "createRemoteModel",
      //syncStatus: modelsSyncStatus.find((s) => s.modelId === model.id),
    });
  });

  modelsIdsRemoteOnly.forEach((id) => {
    const model = remoteModels.find((m) => m.id === id);
    sceneModels.push({
      sceneId: scene?.id,
      sceneClientId: scene?.clientId,
      modelId: model.id,
      isModel: true,
      id: model.id,
      type: model.type,
      remoteName: model.name,
      remoteVersion: model.version,
      actionType: null,
      //syncStatus: modelsSyncStatus.find((s) => s.modelId === model.id),
    });
  });

  modelsIdsClientAndRemote.forEach((id) => {
    const model = models.find((m) => m.id === id);
    const remoteModel = remoteModels.find((m) => m.id === id);
    sceneModels.push({
      sceneId: scene?.id,
      sceneClientId: scene?.clientId,
      modelId: model.id,
      isModel: true,
      id: model.id,
      type: model.type,
      clientName: model.name,
      clientVersion: model.version,
      remoteName: remoteModel.name,
      remoteVersion: remoteModel.version,
      remoteFileRemoteUrl: remoteModel.fileRemoteUrl,
      clientFileRemoteUrl: model.fileRemoteUrl,
      nextSync: model.nextSync,
      actionType: model.nextSync?.action && "updateRemoteModel",
      //syncStatus: modelsSyncStatus.find((s) => s.modelId === model.id),
    });
  });

  // helpers

  // async function getRemoteScene() {
  //   // const remoteScene = await readRemoteSceneService({
  //   //   sceneId: scene.id,
  //   //   accessToken,
  //   // });
  //   const remoteScene = await fetchSceneToSyncService({
  //     sceneId: scene.id,
  //     accessToken,
  //   });
  //   console.log("getRemoteSceneToSync", remoteScene);
  //   setRemoteScene(remoteScene);
  // }

  // const canGetRemoteScene =
  //   Boolean(scene?.id) &&
  //   Boolean(accessToken) &&
  //   ["preloaded", "loaded"].includes(sceneStatus);

  // useEffect(() => {
  //   if (canGetRemoteScene) {
  //     dispatch(setGotRemoteScene(true));
  //     getRemoteScene();
  //   }
  // }, [scene?.id, canGetRemoteScene]); // remove modelsVersionsS from dependencies : too many calls when changing scope.

  sceneModels = sceneModels.map((m) => ({
    ...m,
    clientName: m.clientName ? m.clientName : "-",
    type: m.type ? m.type : "0",
  }));

  sceneModels = sceneModels
    .sort((a, b) => a.clientName.localeCompare(b.clientName))
    .sort((a, b) => a.type.localeCompare(b.type));

  return remoteScene ? sceneModels : []; // used to prevent remote Model creation when remoteScene is not yet downloaded
}
