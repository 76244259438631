import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createContributionService,
  fetchContributionsService,
  fetchExcelContributionsService,
} from "./services";

export const createContribution = createAsyncThunk(
  "contributions/createContribution",
  createContributionService
);

export const fetchContributions = createAsyncThunk(
  "contributions/fetchContributions",
  fetchContributionsService
);

export const fetchExcelContributions = createAsyncThunk(
  "contributions/fetchExcelContributions",
  fetchExcelContributionsService
);

const contributionsSlice = createSlice({
  name: "contributions",
  initialState: {datasets: {}},
  extraReducers: {
    [fetchContributions.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId] = {data};
      state.datasets[listingId].status = "succeed";
    },
    [fetchExcelContributions.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId] = {data};
      state.datasets[listingId].status = "succeed";
    },
    [createContribution.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      if (!state.datasets[listingId]) {
        state.datasets[listingId] = {data: []};
      }
      state.datasets[listingId].data.push(data);
    },
  },
});

export default contributionsSlice.reducer;
