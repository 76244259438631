// import {useState} from "react";
import {useSelector} from "react-redux";


import {Box, Slider} from "@mui/material";

import HeightLabel from "./components/HeightLabel";
// import DialogEditHeight from "./components/DialogEditHeight";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function ToolHeightsSlider({scene, editor3d}) {
  const cam1deltaY = 0.2;

  // data

  const viewMode = useSelector((s) => s.viewer3D.viewMode);

  // const allModels = useSelector((s) => s.viewer3D.models);
  // const models = allModels.filter(
  //   (m) => m.sceneClientId === scene?.clientId && m.center && m.height
  // );

  const cam2Height = useSelector((s) => s.viewer3D.cam2Height);
  const eyesHeight = useSelector((s) => s.viewer3D.eyesHeight);
  const cutCam1 = useSelector((s) => s.viewer3D.cutCam1);

  // helpers - minHeight & maxHeight

  let minHeight = -1;
  let maxHeight = 1;

  const sectors = useSectorsByScene(scene);
  const sectorZs = sectors.map((s) => s.z).filter((z) => typeof z === "number");

  if (sectorZs.length > 0) {
    minHeight = Math.min(...sectorZs) - 1;
    maxHeight = Math.max(...sectorZs) + 1;
  }

  // const size = editor3d?.sceneEditor.getSize();
  // if (size) {
  //   const _minHeight = size.minZ - 1;
  //   const _maxHeight = size.maxZ + 1;
  //   if (_minHeight < minHeight) minHeight = _minHeight;
  //   if (_maxHeight > maxHeight) maxHeight = _maxHeight;
  // }

  let height = cam2Height - eyesHeight;
  if (height < minHeight) height = minHeight;
  if (height > maxHeight) height = maxHeight;

  // state

  // const [openEdit, setOpenEdit] = useState(false);

  // helpers - display

  const display = viewMode === "3D" || viewMode === "MULTIVIEWS";

  // helpers

  const sceneHeights = scene?.data?.heights
    ? scene.data.heights.map((height) => {
        if (typeof height?.value === "number") {
          return parseFloat(height.value.toFixed(2));
        } else {
          return 0;
        }
      })
    : [];

  const optimHeights = sceneHeights.sort().reduce((ac, cur, index) => {
    if (index === 0) return [cur];
    if (ac?.length > 0) {
      const lastValue = ac[ac.length - 1];
      if (cur > lastValue + 1) {
        return [...ac, cur];
      } else {
        return ac;
      }
    }
  }, []);

  let markedHeights = [minHeight, maxHeight, 0];
  if (optimHeights) markedHeights = [...markedHeights, ...optimHeights];
  markedHeights = [...new Set(markedHeights)];

  const marks = markedHeights.map((height) => {
    return {
      value: height,
      label: `${height >= 0 ? "+" : "-"} ${Math.abs(height).toFixed(2)} m`,
    };
  });

  function changeHeight(v) {
    console.log("change Height", v);
    const prevCamY = editor3d?.cameras.camera2.position.y;
    const prevTgtY = editor3d?.controls.orbitControls2.target.y;
    const deltaY = v + eyesHeight - prevCamY;
    editor3d?.cameras.camera2.position.setY(v + eyesHeight);
    editor3d?.controls.orbitControls2.target.setY(prevTgtY + deltaY);
    editor3d?.controls.orbitControls2.update();
    if (cutCam1)
      editor3d?.cameras.camera1.position.setY(v + eyesHeight + cam1deltaY + 10.73);

    // horiontal cut
    editor3d?.clipper.setHorizontalClippingPlane(v + 1); // horiontal cut
  }

  // function changeHeightFromDialog(v, cutCam1) {
  //   const prevCamY = editor3d?.cameras.camera2.position.y;
  //   const prevTgtY = editor3d?.controls.orbitControls2.target.y;
  //   const deltaY = v + eyesHeight - prevCamY;
  //   editor3d?.cameras.camera2.position.setY(v + eyesHeight);
  //   editor3d?.controls.orbitControls2.target.setY(prevTgtY + deltaY);
  //   editor3d?.controls.orbitControls2.update();
  //   if (cutCam1)
  //     editor3d?.cameras.camera1.position.setY(v + eyesHeight + cam1deltaY + 10.73);

  //   // horiontal cut
  //   editor3d?.clipper.setHorizontalClippingPlane(v + 1);
  // }

  // handlers

  function handleSliderChange(e, v) {
    changeHeight(v);
  }

  // function handleHeightChangeFromDialog({height, cutCam1}) {
  //   const v = parseFloat(height);
  //   changeHeightFromDialog(v, cutCam1);
  // }

  // function handleEditClose() {
  //   setOpenEdit(false);
  // }
  // function handleEditCancel() {
  //   setOpenEdit(false);
  // }

  // function handleEditClick() {
  //   setOpenEdit(true);
  // }

  return (
    <Box
      sx={{
        height: 1,
        display: display ? "flex" : "none",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
      onPointerMove={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {true && (
        <Box
          onPointerMove={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          sx={{
            height: 1,
            //bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
            py: 1,
            width: 20,
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "common.black",
          }}
        >
          <Slider
            marks={marks}
            onPointerMove={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            orientation="vertical"
            size="small"
            step={0.01}
            min={minHeight - 1}
            max={maxHeight + 1}
            onChange={handleSliderChange}
            value={height}
            valueLabelDisplay="on"
            valueLabelFormat={(v) =>
              `${v > 0 ? "+" : "-"} ${Math.abs(v).toFixed(2)} m`
            }
            components={{ValueLabel: HeightLabel}}
            sx={{
              color: "common.black",
              mr: 0,
              "& .MuiSlider-rail": {
                color: "common.black",
                opacity: 0.5,
              },
              "& .MuiSlider-mark": {
                height: 2,
                width: 6,
              },
              "& .MuiSlider-markLabel": {
                fontSize: 10,
                left: -50,
              },
              "& .MuiSlider-thumb": {
                height: 4,
                width: 14,
                borderRadius: 0,
                // color: "rgb(250,180,31)",
                color: "primary.light",
                backgroundColor: "#fff",
                border: "2px solid currentColor",
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&:before": {
                  display: "none",
                },
              },
            }}
          />
        </Box>
      )}
      {/* <Box
        sx={{
          borderRadius: "50%",
          mt: -0.5,
          bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 18,
          height: 18,
        }}
      >
        <IconButton
          onClick={handleEditClick}
          sx={{
            width: 24,
            height: 24,
            border: (theme) => `1px solid ${theme.palette.common.black}`,
          }}
        >
          <Height sx={{fontSize: 14}} />
        </IconButton>
      </Box>
      <DialogEditHeight
        open={openEdit}
        onClose={handleEditClose}
        editor3d={editor3d}
        onCancel={handleEditCancel}
        onHeightChange={handleHeightChangeFromDialog}
      /> */}
    </Box>
  );
}
