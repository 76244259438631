import React from "react";

import {useSelector} from "react-redux";

import SelectorAutocomplete from "Components/SelectorAutocomplete";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function FieldTempZoneSector({sector, onChange, scene}) {
  // string

  const sectorS = "Secteur";

  // data

  const sectors = useSectorsByScene(scene);

  // helpers

  const options = sectors
    .map((s) => ({
      ...s,
      z: s.z === 0 || s.z ? s.z : 9999,
      building: s.building ?? "",
      name: s.building ? s.building + " • " + s.name : s.name,
    }))
    .sort((a, b) => a.z - b.z)
    .sort((a, b) => a.building.localeCompare(b.building));

  const option = sector
    ? options.find((option) => option.id === sector.id)
    : null;

  return (
    <SelectorAutocomplete
      name={sectorS}
      options={options}
      onChange={onChange}
      value={option}
    />
  );
}
