import {Button} from "@mui/material";
import {Download} from "@mui/icons-material";

import elementTypesDefault from "../data/elementTypesDefault";

import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";

export default function ButtonDownloadExcelElementTypeGroupGeneric() {
  const label = "liste_generique.xlsx";

  async function handleClick() {
    const types = elementTypesDefault.map((type) => {
      const newType = {...type};
      if (newType?.id) delete newType.id;
      return newType;
    });

    const wb = await createExcelWorkbook({
      template: "ELEMENT_TYPES_WITH_IDS",
      data: types,
    });
    downloadExcelFileFromWorkbook(wb, label);
  }

  return (
    <Button
      startIcon={<Download />}
      onClick={handleClick}
      color="inherit"
      size="small"
    >
      {label}
    </Button>
  );
}
