import getTemplateFromElementTypeName from "Features/elementTypes/utils/getTemplateFromElementTypeName";
import getMeasurementsFromArticleTypes from "./getMeasurementsFromArticleTypes";
import getMeasNomenclatureFromMeasurements from "Features/elementTypes/utils/getMeasNomenclatureFromMeasurements";
import getItemNameFromTemplate from "Features/elementTypes/utils/getItemNameFromTemplate";
import templateNomenclatureMap from "Features/elementTypes/data/templateNomenclatureMap";

import getMeasNomenclatureFromMeasurementsAndArticleTypes from "./getMeasNomenclatureFromMeasurementsAndArticleTypes";
import getNumberOrStringFromString from "Utils/getNumberOrStringFromString";

import updateNumsOfTypeNomenclature from "Features/elementTypes/utils/updateNumsOfTypeNomenclature";

export default function getSubArticlesPropsFromMeasurements(
  article,
  measurements,
  data
) {
  // data

  const groupsWithSortedTypes = data?.groupsWithSortedTypes ?? {};

  // data by Id

  const typeById = {};
  groupsWithSortedTypes.forEach((group) => {
    group.elementTypes?.forEach((elementType) => {
      typeById[elementType.id] = {...elementType, groupId: group.id};
    });
  });

  // helpers

  const filteredMeasurements = getMeasurementsFromArticleTypes(
    measurements,
    article.types,
    data
  );

  // helpers - template

  const template = getTemplateFromElementTypeName(article.name);
  const nomenclature = templateNomenclatureMap[template];
  const fields = nomenclature?.fields;

  let measNomenclature = getMeasNomenclatureFromMeasurementsAndArticleTypes(
    filteredMeasurements,
    article.types,
    template
  );

  // sort items

  measNomenclature = updateNumsOfTypeNomenclature(measNomenclature, template);

  console.log("measNomenclatureV2", measNomenclature);

  //

  const subArticlesProps = measNomenclature.map((nomenclatureItem) => {
    // elementTypes
    const elementTypesIds = nomenclatureItem.elementTypeId ?? [];
    const elementTypes = elementTypesIds.map((id) => typeById[id]);
    //
    const name = getItemNameFromTemplate(nomenclatureItem, template);
    //
    const filtersChuncks = fields.map((field) => {
      const value = nomenclatureItem[field];
      const fieldKeyMap = {
        dim1: "D1",
        dim2: "D2",
        height: "H",
        length: "L",
        p0: "MC",
        p1: "P1",
        p2: "P2",
        p3: "P3",
      };
      let formatedValueForTest = getNumberOrStringFromString(value);
      if (
        ["p0", "p1", "p2", "p3"].includes(field) &&
        typeof formatedValueForTest === "number"
      ) {
        formatedValueForTest = `"${formatedValueForTest}"`;
      }

      return `${fieldKeyMap[field]}===${formatedValueForTest}`; // check if we need to add "" from strings
    });
    const filtersStrFromFields = [filtersChuncks.join(" && ")];
    //
    return {
      name,
      filtersStrFromFields,
      elementTypes,
    };
  });

  return subArticlesProps;
}
