import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogRenameElementTypesGroup from "./DialogRenameElementTypesGroup";
// import DialogImportTypesRelations from "./DialogImportTypesRelations";
import DialogAddRelationsElementTypes from "./DialogAddRelationsElementTypes";

import useCreateRessourcesFromTypes from "../utils/useCreateRessourcesFromTypes";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import useUpdateSceneWithElementTypes from "../hooks/useUpdateSceneWithElementTypes";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";
import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import {
  createElementTypesGroup,
  deleteElementTypesGroup,
  setSelectedElementTypesGroupProxy,
} from "../elementTypesSlice";

import RenameDialog from "Features/ui/components/RenameDialog";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";
import useDeleteSceneRessourcesGroup from "Features/ressources/hooks/useDeleteSceneRessourcesGroup";
import useAccessToken from "Features/auth/useAccessToken";
import {updateScene} from "Features/scenes/scenesSlice";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import sanitizeString from "Utils/sanitizeString";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import sortByNum from "Utils/sortByNum";
import DialogElementTypesGroupToProxies from "./DialogElementTypesGroupToProxies";
import DialogImportTypesRelations from "./DialogImportTypesRelations";
import useUpdateSceneElementType from "../hooks/useUpdateSceneElementType";
import DialogImportElementTypesFromExcel from "./DialogImportElementTypesFromExcel";
import getItemsMapById from "Utils/getItemsMapById";

export default function ButtonMoreElementTypesInLeftPanel({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const toRemoteS = "Mode avancé ✨";
  const toArticlesS = "Convertir en articles";
  const toArticlesTitleS = "Définissez le nom de la nouvelle table d'articles";
  const renameS = "Renommer le groupe";
  const deleteS = "Supprimer le groupe";
  const addRelationsS = "Ajouter des relations";
  const exportRelationsS = "Exporter des relations";
  const cleanRelationsS = "Nettoyer les relations";
  const toProxiesS = "Convertir en proxy";
  const importRelationsS = "Importer des relations";
  const exportS = "Export excel";
  const importS = "Import excel";
  const cleanS = "Nettoyer les groupes";

  // data

  const selectedGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const allTypes = useElementTypesBySceneProxy(scene, {filterByScope: true});
  const createRessourcesFromTypes = useCreateRessourcesFromTypes();
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const selectedTypeGroup = useSelectedElementTypesGroup(scene);
  const updateElementTypes = useUpdateSceneWithElementTypes(scene);
  const updateElementTypesGroup = useUpdateElementTypesGroup();
  const ressourcesGroups = useRessourceGroupsByScene(scene);
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const deleteSceneRessourcesGroup = useDeleteSceneRessourcesGroup();
  const updateElementType = useUpdateSceneElementType(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openToArticlesDialog, setOpenToArticlesDialog] = useState(false);
  const [openDialogRename, setOpenDialogRename] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddRelations, setOpenAddRelations] = useState(false);
  const [openImportRelations, setOpenImportRelations] = useState(false);
  const [openProxyDialog, setOpenProxyDialog] = useState(false);
  const [openImportDialog, setOpenImportDialog] = useState(false);

  // helpers

  const isRemote = Boolean(!selectedTypeGroup?.fromScene);
  let types = allTypes.filter(
    (t) =>
      (t.groupId && t.groupId === selectedGroup?.id) ||
      (!t.groupId && t.group === selectedGroup?.name)
  );

  const measurements = caplaEditor?.measDataManager.getAllMeasurements();
  const elementTypesById = getItemsMapById(allTypes);
  const ressourcesById = getItemsMapById(ressources);

  // handlers

  async function updateTypesByRelations(editedRelations) {
    const groupTypes = types.filter(
      (t) => t.groupId === selectedGroup.id || t.group === selectedGroup.name
    );
    const editedTypes = [];
    const modelIds = new Set();
    const measurementsToUpdate = [];
    groupTypes.forEach((t) => {
      const editedType = {...t};
      const itemRelationKeys = Object.keys(editedRelations).filter((k) =>
        k.startsWith(editedType.id)
      );
      editedType.res = itemRelationKeys.map((k) => editedRelations[k]);
      if (selectedGroup.fromScene) updateElementType(editedType);
      else editedTypes.push(editedType);
      elementTypesById[t.id] = editedType;
      measurements
        .filter((m) => m.elementTypeId === editedType.id)
        .forEach((m) => {
          measurementsToUpdate.push({
            ...m,
            res: editedType?.res
              ? editedType.res.map(({resId}) => ({resId, off: false}))
              : [],
          });
          modelIds.add(m.measurementsModelId);
        });
    });
    const sceneData = {
      ressources,
      elementTypes: Object.values(elementTypesById),
    };
    caplaEditor?.measDataManager.updateMeasurements(measurementsToUpdate);
    for (const modelId of modelIds) {
      caplaEditor?.measDataManager.saveModificationsForModelById(
        modelId,
        sceneData
      );
    }
    if (!selectedGroup.fromScene) {
      const updatedGroup = {...selectedGroup, elementTypes: editedTypes};
      await updateElementTypesGroup(updatedGroup);
    }
  }

  // handlers - to remote

  async function handleToRemote() {
    types = [...new Map(types.map((v) => [v.id, v])).values()];

    const typesIds = new Set(types.map((t) => t.id));
    let oldElementTypes = scene.data.elementTypes.filter(
      (s) => !typesIds.has(s.id)
    );
    const newScene = {
      ...scene,
      data: {...scene.data, elementTypes: [...oldElementTypes]},
    };
    dispatch(updateScene({scene: newScene}));

    const newGroup = {};
    newGroup.name = selectedGroup.name;
    newGroup.fromScene = false;
    newGroup.sceneId = scene.id;
    newGroup.elementTypes = types;
    const result = await dispatch(
      createElementTypesGroup({elementTypesGroup: newGroup, accessToken})
    );
    const {item} = unwrapResult(result);
    const group = {};
    group.id = item.id;
    group.name = item.name;
    group.sceneId = item.sceneId;
    group.fromScene = false;
    group.code = item.code;
    dispatch(setSelectedElementTypesGroupProxy(group));
  }

  // handlers - convert to articles

  const handleToArticles = () => {
    setOpenToArticlesDialog(true);
    setAnchorEl(null);
  };

  const handleToArticlesCancel = () => {
    setOpenToArticlesDialog(false);
  };

  const handleToArticlesConfirm = async (newName) => {
    setOpenToArticlesDialog(false);
    let scene2 = {...scene};
    if (ressourcesGroups.includes(newName)) {
      const {newScene, typesToEdit} = deleteSceneRessourcesGroup(
        scene,
        newName,
        relations
      );
      scene2 = newScene;
      // groups.forEach((g) => {
      for (let idx = 0; idx < groups.length; idx++) {
        const g = groups[idx];
        const groupTypes = allTypes
          .filter((t) => t.groupId === g.id || t.group === g.name)
          .map((t) => {
            if (t.id in typesToEdit) {
              const resSet = typesToEdit[t.id];
              const newT = {...t};
              if (newT.res)
                newT.res = newT.res.filter((r) => !resSet.has(r.resId));
              return newT;
            }
            return {...t};
          });
        if (!g.fromScene) {
          const editedGroup = {...g, elementTypes: groupTypes};
          await updateElementTypesGroup(editedGroup);
        } else {
          // updateElementTypes(groupTypes);
          console.log("NOT IMPLEMENTED");
        }
      }
    }
    const editedTypes = createRessourcesFromTypes(
      scene2,
      types,
      newName,
      relations
    );
    if (!selectedTypeGroup) {
      updateElementTypes(editedTypes);
    } else {
      const editedGroup = {...selectedTypeGroup, elementTypes: editedTypes};
      await updateElementTypesGroup(editedGroup);
    }
  };

  // handlers - add relations

  function handleAddRelations() {
    setOpenAddRelations(true);
    setAnchorEl(null);
  }

  async function handleAddRelationsConfirm(group) {
    let doUpdate = false;
    const editedTypes = [];
    const typesToChange = selectedTypeGroup?.elementTypes;
    const typesWithRelations = group?.elementTypes;
    const updatedRelations = {...relations};
    typesToChange?.forEach((type) => {
      const tRel = typesWithRelations.find((t) => t.code === type.code);
      if (tRel && tRel.res) {
        const newRes = [];
        let oldRes = type.res;
        tRel.res.forEach((r) => {
          const relKey = `${type.id}-${r.resId}`;
          if (relations[relKey]) {
            oldRes = oldRes.filter((r) => r.id !== relKey);
          }
          const newRel = {
            ...r,
            id: relKey,
            typeId: type.id,
          };
          newRes.push(newRel);
          updatedRelations[relKey] = newRel;
        });
        editedTypes.push({
          ...type,
          res: type.res ? [...oldRes, ...newRes] : newRes,
        });
        doUpdate = true;
      } else {
        editedTypes.push({...type});
      }
    });
    if (doUpdate) {
      const editedGroup = {...selectedTypeGroup, elementTypes: editedTypes};
      await updateElementTypesGroup(editedGroup);
    }
    dispatch(setRelationsTypesRessources(updatedRelations));
    setOpenAddRelations(false);
  }

  function handleAddRelationsCancel() {
    setOpenAddRelations(false);
  }

  // handlers - clean

  async function handleClean() {
    const cleanTypesMap = {};
    groups.forEach(async (group) => {
      let shouldUpdate = false;
      const typesToClean = group.elementTypes;
      const cleanTypesByGroup = [];
      typesToClean.forEach((type) => {
        if (!cleanTypesMap[type.id]) {
          cleanTypesByGroup.push(type);
          cleanTypesMap[type.id] = "ok";
        } else {
          shouldUpdate = true;
        }
      });
      if (shouldUpdate) {
        const editedGroup = {...group, elementTypes: cleanTypesByGroup};
        await updateElementTypesGroup(editedGroup);
      }
    });
    setAnchorEl(null);
  }
  // handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    dispatch(
      deleteElementTypesGroup({
        accessToken,
        elementTypesGroup: selectedTypeGroup,
      })
    );
    setOpenDeleteDialog(false);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // handlers -export relations

  async function handleExportRelations() {
    let fileName = "element_types_relations.xlsx";
    if (selectedGroup)
      fileName = sanitizeString(selectedGroup.name) + "_types_relations.xlsx";
    const typesWithRessources = types
      .sort((a, b) => sortByNum(a.num, b.num))
      .map((t) => {
        const usedRessources = Object.keys(relations)
          .filter((k) => k.startsWith(t.id))
          .map((k) => {
            const rel = relations[k];
            const ressource = ressourcesById[rel.resId];
            if (!ressource) return;
            return {
              ...ressource,
              func: rel.func,
            };
          })
          .sort(
            (a, b) => a.group.localeCompare(b.group) || sortByNum(a.num, b.num)
          );
        return {...t, res: usedRessources};
      });
    const data = {
      typesRessourcesRelations: typesWithRessources,
      typesGroup: selectedGroup.name,
      ressources,
    };
    const wb = await createExcelWorkbook({
      template: "ELEMENT_TYPES_RESSOURCES",
      data,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
    setAnchorEl(null);
  }

  // handlers - import relations

  function handleImportRelations() {
    setOpenImportRelations(true);
    setAnchorEl(null);
  }

  function handleConfirmImportRelations(editedRelations) {
    updateTypesByRelations(editedRelations);
    dispatch(setRelationsTypesRessources(editedRelations));
    setOpenImportRelations(false);
  }

  function handleCloseImportRelations() {
    setOpenImportRelations(false);
  }

  // handlers - clean relations

  async function handleCleanRelations() {
    setAnchorEl(null);
    let doUpdate = false;
    const editedTypes = [];
    const updatedRelations = {...relations};
    types?.forEach((type) => {
      if (type.res) {
        const newRes = [];
        type.res.forEach((rel) => {
          const res = ressourcesById[rel.resId];
          if (res) newRes.push(rel);
          else {
            const relKey = `${type.id}-${rel.resId}`;
            delete updatedRelations[relKey];
            doUpdate = true;
          }
        });
        editedTypes.push({
          ...type,
          res: newRes,
        });
      } else {
        editedTypes.push({...type});
      }
    });
    if (doUpdate) {
      const editedGroup = {...selectedTypeGroup, elementTypes: editedTypes};
      await updateElementTypesGroup(editedGroup);
    }
    dispatch(setRelationsTypesRessources(updatedRelations));
  }

  // handlers - to proxies

  function handleToProxies() {
    setOpenProxyDialog(true);
    setAnchorEl(null);
  }

  async function handleToProxiesConfirm(group) {
    const newTypes = types?.map((t) => {
      const newType = {
        ...t,
        id: nanoid(),
        drawingShape: "RECTANGLE",
      };
      if (newType.res) delete newType.res;
      return newType;
    });
    await updateElementTypesGroup({...group, elementTypes: newTypes});
    setOpenProxyDialog(false);
  }

  // helpers excel

  async function handleExport() {
    let fileName = "element_types.xlsx";
    if (selectedGroup)
      fileName = sanitizeString(selectedGroup.name) + "_types.xlsx";
    setAnchorEl(null);
    const wb = await createExcelWorkbook({
      template: "ELEMENT_TYPES_WITH_IDS",
      data: types,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "TO_REMOTE",
      label: toRemoteS,
      visible: !isRemote,
      handler: handleToRemote,
    },
    {
      id: "RENAME",
      label: renameS,
      visible: isRemote,
      handler: () => {
        setOpenDialogRename(true);
      },
    },
    {
      id: "DELETE",
      label: deleteS,
      visible: isRemote,
      handler: handleDelete,
    },
    {
      id: "CLEAN",
      label: cleanS,
      visible: isRemote,
      handler: handleClean,
    },
    {id: "DIVIDER", visible: true},
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
      visible: true,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
      visible: true,
    },
    {id: "DIVIDER", visible: isRemote},
    {
      id: "EXPORT_RELATIONS",
      label: exportRelationsS,
      handler: handleExportRelations,
      visible: isRemote,
    },
    {
      id: "IMPORT_RELATIONS",
      label: importRelationsS,
      handler: handleImportRelations,
      visible: isRemote,
    },
    {id: "DIVIDER", visible: isRemote},
    {
      id: "ADD_RELATIONS",
      label: addRelationsS,
      handler: handleAddRelations,
      visible: isRemote,
    },
    {
      id: "CLEAN_RELATIONS",
      label: cleanRelationsS,
      handler: handleCleanRelations,
      visible: isRemote,
    },
    {id: "DIVIDER", visible: isRemote},
    {
      id: "TO_PROXY",
      label: toProxiesS,
      visible: isRemote,
      handler: handleToProxies,
    },
    {
      id: "TO_ARTICLES",
      label: toArticlesS,
      handler: handleToArticles,
      visible: isRemote,
    },
  ];

  return (
    <Box sx={{display: selectedTypeGroup || !isRemote ? "flex" : "none"}}>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool, idx) => {
          if (tool.visible) {
            if (tool.id === "DIVIDER") return <Divider key={idx} />;
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
          }
        })}
      </Menu>
      <DialogAddRelationsElementTypes
        open={openAddRelations}
        groups={groups.filter((g) => g.id !== selectedTypeGroup?.id)}
        onCancel={handleAddRelationsCancel}
        onConfirm={handleAddRelationsConfirm}
      />
      <DialogImportTypesRelations
        open={openImportRelations}
        onConfirm={handleConfirmImportRelations}
        onCancel={handleCloseImportRelations}
        relations={relations}
        ressourcesById={ressourcesById}
      />
      <RenameDialog
        open={openToArticlesDialog}
        onCancel={handleToArticlesCancel}
        onConfirm={handleToArticlesConfirm}
        title={toArticlesTitleS}
        initial={selectedGroup?.name}
      />
      <DialogRenameElementTypesGroup
        onClose={() => {
          setOpenDialogRename(false);
          setAnchorEl(null);
        }}
        open={openDialogRename}
      />
      <DialogElementTypesGroupToProxies
        open={openProxyDialog}
        groups={groups.filter((g) => g.id !== selectedTypeGroup?.id)}
        onClose={() => setOpenProxyDialog(false)}
        onConfirm={handleToProxiesConfirm}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"elementTypesGroup"}
      />
      <DialogImportElementTypesFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
    </Box>
  );
}
