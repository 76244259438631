import {TextureLoader, MathUtils} from "three";
import Jimp from "jimp";
import {Texture} from "@mui/icons-material";

export default class ImageTexture {
  constructor({url, urlLowReso, urlHightReso}) {
    this.url = url;
    //this.textureRR = new TextureLoader().load(url); // row resolution
    //this.textureLR = urlLowReso && new TextureLoader().load(urlLowReso);
    //this.textureHR = urlHightReso && new TextureLoader().load(urlHightReso);
  }

  async getImageSize() {
    return new Promise((resolve) => {
      const image = new Image();
      const size = {};
      image.addEventListener("load", function () {
        size.width = image.naturalWidth;
        size.height = image.naturalHeight;
        resolve(size);
      });
      image.src = this.url;
    });
  }

  async loadTexture({url}) {
    return new Promise((resolve) => {
      new TextureLoader().load(url, (texture) => {
        resolve(texture);
      });
    });
  }

  async loadTexturesAsync() {
    if (this.url) this.textureRR = await this.loadTexture({url: this.url});
  }

  update({url}) {
    console.log("updateImageTexture, url:", url);
    this.url = url;
    this.textureRR = new TextureLoader().load(url);
  }

  async getModelSizeFromUrl() {
    const imageSize = await this.getImageSize();
    // 1px ?= 1-10-100.. m
    const dim = Math.max(imageSize.width, imageSize.height);
    // dim = 9 > order = 0, 10-99 > 1, 100-999> 2,...
    const order = Math.trunc(Math.log(dim) / Math.log(10));
    const ratio = Math.pow(10, order);
    return {
      width: (imageSize.width / ratio) * 5,
      height: (imageSize.height / ratio) * 5,
    };
  }

  updateLowAndHightReso(urlLowReso, urlHightReso) {
    this.textureLR = new TextureLoader().load(urlLowReso);
    this.textureHR = new TextureLoader().load(urlHightReso);
  }
}
