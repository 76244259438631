import {Box, List} from "@mui/material";

import ListItemEditable from "./ListItemEditable";
import NewItem from "./NewItem";
import {nanoid} from "nanoid";
import getItemsMapById from "Utils/getItemsMapById";

export default function ListOneRankItems({
  allItems = [],
  items,
  selectedItem,
  onAdd,
  onSelect,
  onChangeItem,
  showAddItem,
}) {
  // helpers

  const childrenMap = {};
  allItems.forEach((item, index) => {
    if (index < allItems.length - 1) {
      const nextItem = allItems[index + 1];
      childrenMap[item.id] =
        nextItem.num.split(".").length > item.num.split(".").length;
    } else {
      childrenMap[item.id] = false;
    }
  });

  // handlers

  function handleChange(item) {
    onChangeItem(item);
  }
  function handleAdd(item) {
    let num;
    const nums = items.map((item) => item.num);
    if (nums.length === 0) {
      if (!selectedItem) {
        num = "1";
      } else {
        num = selectedItem.num + ".1";
      }
    } else {
      const lastItem = items[items.length - 1];
      const numArr = lastItem.num.split(".");
      const last = numArr.pop();
      const nextLast = Number(last) + 1;
      num = [...numArr, nextLast.toString()].join(".");
    }
    const newItem = {...item, id: nanoid(), num};
    onAdd(newItem);
  }
  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <List>
        {items?.map((item) => {
          const showArrow = childrenMap[item.id];
          const selected = selectedItem?.id === item.id;
          return (
            <ListItemEditable
              key={item.id}
              item={item}
              selected={selected}
              onSelect={onSelect}
              onChange={handleChange}
              showArrow={showArrow}
            />
          );
        })}
        {showAddItem && <NewItem onAdd={handleAdd} />}
      </List>
    </Box>
  );
}
