import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";

import {Box, Typography, IconButton, TextField} from "@mui/material";
import {Edit, Save, Delete, Update} from "@mui/icons-material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";
import DeleteDialog from "Features/ui/components/DeleteDialog";

import {uploadFileService} from "Features/files/services";
import useAccessToken from "Features/auth/useAccessToken";

import {unzipObjFile} from "Features/files/utils";

export default function FieldFile3d({
  item,
  name,
  scene,
  caplaEditor,
  value,
  onChange,
}) {
  const accessToken = useAccessToken();

  // string

  const helperS = "Fichier .zip contenant les fichiers .mtl et .obj";
  const rotXS = "rotX (deg)";
  const rotYS = "rotY (deg)";

  // data

  const measurements = useSelector((s) => s.measurements.items);

  // state

  const [openEdit, setOpenEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);

  const [scale, setScale] = useState(1);
  const [rotX, setRotX] = useState(0);
  const [rotZ, setRotZ] = useState(0);

  useEffect(() => {
    setFileName(value?.name);
    setScale(value?.scale);
    setRotX(value?.rotX);
    setRotZ(value?.rotZ);
  }, [value?.url, value?.scale, value?.name, value?.rotX, value?.rotZ]);

  useEffect(() => {
    if (item?.file3d?.url) {
      setOpenEdit(false);
    } else {
      setOpenEdit(true);
    }
  }, [item?.file3d?.url, item?.id]);

  // helpers

  const label = value?.name;

  // handler

  function handleScaleChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    setScale(value);
  }

  function handleRotXChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    setRotX(value);
  }
  function handleRotZChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    setRotZ(value);
  }

  function updateMeasurementsWithFile3d(file3d) {
    const filteredMeas = measurements.filter(
      (m) => m.elementTypeId === item.id
    );
    const newMeas = filteredMeas.map((m) => ({
      ...m,
      file3d,
    }));
    caplaEditor?.measDataManager.updateMeasurements(newMeas);
    newMeas.forEach((meas) =>
      caplaEditor.editor3d.sceneEditor.updateMeasurement3D(meas)
    );
  }

  function handleSave() {
    const newFile3d = {...value, scale: Number(scale)};
    newFile3d.rotX = Number(rotX) ?? 0;
    newFile3d.rotZ = Number(rotZ) ?? 0;
    if (isNaN(newFile3d.rotX)) newFile3d.rotX = 0;
    if (isNaN(newFile3d.rotZ)) newFile3d.rotZ = 0;
    //
    //
    // update measurements
    updateMeasurementsWithFile3d(newFile3d);
    //
    onChange(newFile3d);
  }
  // handlers

  async function handleFilesChange(files) {
    const file = files[0];

    const result = await unzipObjFile(files[0]);

    const fileObj = result.fileObj;
    const fileMtl = result.fileMtl;

    const url = URL.createObjectURL(fileObj);
    const mtlUrl = URL.createObjectURL(fileMtl);

    setLoading(true);
    // const remoteUrl = await uploadFileService({
    //   file: fileObj,
    //   sceneId: scene.id,
    //   accessToken,
    //   container: "scene-files",
    //   blobName: file.name,
    // });
    setLoading(false);
    //
    const newFile3d = {
      ...value,
      url: url,
      mtlStr: result.mtlStr,
      mtlUrl,
      name: file.name,
      scale: value?.scale ?? 1,
    };

    // update measurements
    updateMeasurementsWithFile3d(newFile3d);

    onChange(newFile3d);
  }

  function handleEdit() {
    setOpenEdit(true);
  }

  function handleDeleteConfirm() {
    const newFile3d = {};
    const filteredMeas = measurements.filter(
      (m) => m.elementTypeId === item.id
    );
    const newMeas = filteredMeas.map((m) => ({
      ...m,
      file3d: null,
    }));
    caplaEditor?.measDataManager.updateMeasurements(newMeas);
    filteredMeas.forEach((meas) =>
      caplaEditor.editor3d.sceneEditor.updateMeasurement3D(meas)
    );
    //
    onChange(newFile3d);
    //
    setOpen(false);
    setOpenEdit(true);
  }

  return (
    <>
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleDeleteConfirm}
        ressource={"file3d"}
      />
      <Box>
        <Box
          sx={{
            px: 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "background.default",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="body2" sx={{fontWeight: "bold"}}>
            {name}
          </Typography>
          <IconButton onClick={() => setOpen(true)} size="small">
            <Delete fontSize="small" />
          </IconButton>
        </Box>
        {!openEdit ? (
          <Box>
            <Box
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {label}
              </Typography>
              <IconButton size="small" onClick={handleEdit}>
                <Edit fontStyle="small" />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 2,
              }}
            >
              {/* <TextField
                size="small"
                value={scale}
                onChange={handleScaleChange}
              /> */}
              <Box sx={{px: 2, width: 140}}>
                <TextField
                  placeholder={rotXS}
                  size="small"
                  value={rotX}
                  onChange={handleRotXChange}
                />
              </Box>
              <Box sx={{px: 2, width: 140}}>
                <TextField
                  placeholder={rotYS}
                  size="small"
                  value={rotZ}
                  onChange={handleRotZChange}
                />
              </Box>
              <IconButton size="small" onClick={handleSave}>
                <Update fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box sx={{p: 1}}>
              <Typography variant="body2" color="text.secondary">
                {helperS}
              </Typography>
            </Box>
            <ContainerFilesSelector
              multiple={false}
              onFilesChange={handleFilesChange}
              loading={loading}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
