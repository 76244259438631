export default function argbToHex(argbString) {
  const val = argbString.trim().toLowerCase();

  let color = {};
  try {
    let argb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      val
    );
    color.r = parseInt(argb[2], 16);
    color.g = parseInt(argb[3], 16);
    color.b = parseInt(argb[4], 16);
    color.a = parseInt(argb[1], 16) / 255;
  } catch (e) {
    console.log(e);
  }
  const hex =
    "#" +
    ((1 << 24) + (color.r << 16) + (color.g << 8) + color.b)
      .toString(16)
      .slice(1);

  return hex.toLowerCase();
}
