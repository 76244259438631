import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid, frFR} from "@mui/x-data-grid";

// import {format} from "date-fns";

import {setSelectedBimappId} from "../bimappsSlice";
import useBimappsByScene from "../hooks/useBimappsByScene";
import bimappsForDataGrid from "../utils/bimappsForDataGrid";

// import useTranslation from "Features/translations/useTranslation";

export default function DataGridBimapps({scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  const nameS = "Nom";

  // data

  const bimapps = useBimappsByScene(scene);

  const selectedBimappId = useSelector((s) => s.bimapps.selectedBimappId);

  const selectionModel = selectedBimappId ? [selectedBimappId] : [];

  // grid - rows

  const rows = bimappsForDataGrid(bimapps);

  // grid - columns

  const columns = [
    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    // state
    dispatch(setSelectedBimappId(selectionId));
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
