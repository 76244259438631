import {nanoid} from "@reduxjs/toolkit";

export function rawTypeToType({
  rawDrawingShape,
  rawName,
  rawColor,
  rawElementName,
}) {
  let num, name, dim2, height;

  // name

  name = rawName.trim();
  const suffix = "_" + rawElementName;
  name = name.replace(suffix, "");

  // num

  const regex = /(\d+\.?)+/g;
  const found = rawName.match(regex);
  if (found?.length > 0) {
    num = found[0];
    name = name.replace(num, "").trim();
    if (name[0] === "-") name = name.slice(1);
  }

  // dim2 & height

  const regex2 = /(\d+)\sx\s(\d+)/;
  let results = rawName.match(regex2);
  if (results?.length > 1) {
    dim2 = results[1];
    height = results[2];

    dim2 = parseFloat(dim2) / 100;
    height = parseFloat(height) / 100;
  }

  // code

  const code = name.substring(0, 3).toUpperCase();

  // unit

  const unitMap = {
    SEGMENT: "UN",
    POLYLINE: "M2",
    POLYGON: "M2",
  };
  const unit = unitMap[rawDrawingShape] ?? "M2";

  return {
    num,
    name,
    code,
    color: rawColor,
    drawingShape: rawDrawingShape,
    dim2,
    height,
    subject: rawName,
    unit,
  };
}
