import ZipModel from "Features/viewer3D/js/ZipModel";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";
import useSceneMeasurements from "./useSceneMeasurements";
import useSelectedMeasurement from "./useSelectedMeasurement";
import useSelectedMeasurementsModel from "./useSelectedMeasurementsModel";
import useSelectedMeasurementsModelMeasurements from "./useSelectedMeasurementsModelMeasurements";

export default function useSectorsAndZonesOfMeasurements(
  scene,
  mode,
  filtered = false
) {
  // helpers

  const modelFilterOnly = filtered ? false : true;
  const applyFilters = filtered ? true : false;

  // data

  const measurements = useSceneMeasurements(scene);
  const measurementsFromSelectedModel =
    useSelectedMeasurementsModelMeasurements();

  let [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    modelFilterOnly,
    applyFilters,
    false
  );

  if (!filtered) {
    fms = mode === "ONE_MODEL" ? measurementsFromSelectedModel : measurements;
  }

  console.log("MMMS", mode, measurements.length, fms.length);

  const zones = useZonesByScene(scene);

  const zoneM = {};
  const sectorM = {};

  fms.forEach((measurement) => {
    let mZId = measurement.zoneId ? measurement.zoneId : null;
    if (mZId === "null") mZId = null;
    const mZon = mZId && zones.find((z) => z.id === mZId);

    let mSecId = mZon?.sectorId ? mZon.sectorId : null;
    if (mSecId === "null") mSecId = null;

    if (!zoneM[mZId]) {
      zoneM[mZId] = 1;
    } else {
      zoneM[mZId] += 1;
    }

    if (!sectorM[mSecId]) {
      sectorM[mSecId] = 1;
    } else {
      sectorM[mSecId] += 1;
    }
  });

  const s = Object.entries(sectorM).map(([key, value]) => ({
    id: key === "null" ? null : key,
    count: value,
  }));

  const z = Object.entries(zoneM).map(([key, value]) => ({
    id: key === "null" ? null : key,
    count: value,
  }));

  return [s, z];
}
