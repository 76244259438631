const applyStyle = (row, i, isTitle, rank, style) => {
  const cell = row.getCell(i);
  if ((isTitle && style === undefined) || ["title1", "title2"].includes(style)) {
    cell.font = {bold: true};
    if (rank === 1 || style === "title1")
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'bbbbc0'}
      };
    if (rank === 2 || style === "title2")
      cell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F4F4F8'}
      };
  }
  if (style === "detail")
    cell.font = {color: {argb: "777777"}};
  cell.alignment = {vertical: "middle", horizontal: "left", wrapText: false};
};

export default function fillWorksheetWithRessourcesByRoom({
  worksheet,
  ressourcesByRoom,
  rowStartIndex,
  ressourcesPU,
  hasPU,
}) {
  ressourcesByRoom.forEach((item, index) => {
    const lastCol = hasPU ? 6 : 4;
    const row = worksheet.getRow(rowStartIndex + index);

    if (item.type === "SECTOR") {
      if (typeof item.name === "string") {
        row.getCell(1).value = item.name;
      }
    } else if (item.type === "ROOM") {
      if (typeof item.name === "string") {
        row.getCell(1).value = item.name;
      }
    } else if (item.type === "RESSOURCE") {
      if (typeof item.num === "string" && !item.hideNum)
        row.getCell(1).value = item.num;
      if (typeof item.name === "string")
        row.getCell(2).value = item.name;
      if (typeof item.unit === "string" && !item.isTitle)
        row.getCell(3).value = item.unit;
      if (typeof item.quantity === "number" && !isNaN(item.quantity))
        row.getCell(4).value = item.quantity;
      if (typeof item.hardcodedQty === "number" && !isNaN(item.hardcodedQty))
        row.getCell(4).value = "x";
      else if (typeof item.quantity === "number" && item.quantity < 0)
        row.getCell(4).fill = {
          type: 'pattern',
          pattern:'solid',
          fgColor:{argb:'FF0000'}
        };

      if (hasPU && ressourcesPU[item.group][item.num]) {
        const pu = ressourcesPU[item.group][item.num];
        if (pu) row.getCell(5).value = Math.round(pu * 100) / 100;
        if (pu && typeof item.quantity === "number")
          row.getCell(6).value =  Math.round((item.quantity * pu) * 100) / 100;
      }
    }
    if (hasPU) {
      worksheet.getRow(2).getCell(5).value = "P.U.";
      worksheet.getRow(2).getCell(6).value = "P.T.";
    }
    const rank = item.rank ? item.rank : item.num?.split(".").length;
    for (let i = 1; i <= lastCol; i++) {
      if (item.type === "SECTOR") {
        row.height = 22;
        applyStyle(row, i, true, 1, "title1");
      }
      else if (item.type === "ROOM") {
        row.height = 22;
        applyStyle(row, i, true, 2, "title2");
      }
      else applyStyle(row, i, item.isTitle, rank, item.style);
    }
  })
}
