import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import ListItemBuiltAt from "./ListItemBuiltAt";

import {
  // addDateToShortcutBuiltAts,
  removeDateFromShortcutBuiltAts,
  toggleShortcutBuiltAt,
  // initShortcutBuiltAts,
} from "Features/measurements/measurementsSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
// import useMeasurementsFiltersAndShortcuts from "Features/measurements/useMeasurementsFilters";

export default function ShortcutMeasurementsBuiltAtInPdf({
  // editor,
  scene,
  mode,
  // viewer,
}) {
  const dispatch = useDispatch();
  // const mnger = editor?.pdfEditor?.annotationsManager?.measurementsPdfManager;

  // // strings

  // const switchModeString = "Switch mode";
  // const showAllString = "Show all";
  // const hideAllString = "Hide all";

  // data

  const pageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);
  // const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const shortcutBuiltAts = useSelector((s) => s.measurements.shortcutBuiltAts);
  const [fm, fmIds, fmIdsHash] = useFilteredSceneMeasurements(scene, mode);
  // const [filters, filtersHash] = useMeasurementsFiltersAndShortcuts(
  //   scene,
  //   viewer
  // );

  // state

  const [checked, _] = useState(false);

  // helpers - page builts at

  const pageMeasurements = fm.filter(
    (m) => m.drawingProps.pageNumber === pageNumber
  );

  let pageBuiltAts = [...new Set(pageMeasurements.map((m) => m.builtAt))]
    .filter((d) => d)
    .sort((a, b) => a.localeCompare(b));
  pageBuiltAts = [...pageBuiltAts, null]; // to take into account the todos.

  // helpers - show measurements

  // const showMeasurements = fm.filter((m) =>
  //   shortcutBuiltAts.includes(m.builtAt)
  // );
  // const showMeasurementsIds = showMeasurements.map((m) => m.id);

  // helpers

  // function resetFilters() {
  //   editorPdf.annotationsManager.measurementsPdfManager.showMeasurementAnnotations(
  //     fmIds
  //   );
  // }

  // handlers

  function handleBuiltAtClick(builtAt) {
    if (!checked) {
      dispatch(toggleShortcutBuiltAt(builtAt));
    } else {
      pageBuiltAts.forEach((builtAt) => {
        dispatch(removeDateFromShortcutBuiltAts(builtAt));
      });
      dispatch(toggleShortcutBuiltAt(builtAt));
    }
  }

  // handlers - check options

  // function handleShowAll() {
  //   pageBuiltAts.forEach((builtAt) =>
  //     dispatch(addDateToShortcutBuiltAts(builtAt))
  //   );
  // }

  // function handleHideAll() {
  //   pageBuiltAts.forEach((builtAt) =>
  //     dispatch(removeDateFromShortcutBuiltAts(builtAt))
  //   );
  // }

  // function handleCheckChange(e) {
  //   setChecked(e.target.checked);
  // }

  // effect - show Measurements

  // useEffect(() => {
  //   console.log("Ahahaha");
  //   if (mnger) mnger.applyFilters(filters);
  // }, [filtersHash, mnger]);

  return (
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {pageBuiltAts.map((builtAt) => {
        const selected = shortcutBuiltAts.includes(builtAt);
        return (
          <ListItemBuiltAt
            selected={selected}
            key={builtAt}
            builtAt={builtAt}
            onClick={() => handleBuiltAtClick(builtAt)}
          />
        );
      })}

      {/* <Box sx={{display: "flex", flexDirection: "column", mt: 2}}>
        <FormControlLabel
          sx={{mb: 1}}
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckChange}
              size="small"
              sx={{width: "12px", height: "12px", mr: 1}}
            />
          }
          label={
            <Typography sx={{fontSize: 12}} variant="body2">
              {switchModeString}
            </Typography>
          }
        />
      </Box> */}

      {/* <Box sx={{display: "flex"}}>
        <Button size="small" onClick={handleHideAll}>
          {hideAllString}
        </Button>
        <Button size="small" onClick={handleShowAll}>
          {showAllString}
        </Button>
      </Box> */}
    </Box>
  );
}
