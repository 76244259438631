/*
 * version : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneVersion(scene) {
  const dispatch = useDispatch();

  const updateSceneVersion = (version) => {
    if (!scene) return;
    let oldVersions = scene?.data?.versions.filter((s) => s?.id !== version.id);
    if (!oldVersions) oldVersions = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, versions: [...oldVersions, version]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneVersion;
}
