import computeRessourceQuantity, {
  applyFormulaFilter,
} from "Features/ressources/utils/computeRessourceQuantity";

export default function computeMeasurementRessourcesQuantities({
  measurement,
  elementTypesById,
  ressourcesById,
  zonesById,
  roomsById,
  sectorsById,
  filterNoVoids,
}) {
  const mres = measurement.res;
  if (!mres) return;
  const elementType = elementTypesById[measurement.elementTypeId];
  if (!elementType) return;
  return mres.map((mr) => {
    if (mr.off) return mr;
    const ressource = ressourcesById[mr.resId];
    if (!ressource) return mr;
    let res;
    if (elementType?.res && Array.isArray(elementType.res))
      res = elementType?.res?.find((r) => mr.resId === r.resId);
    if (!res) res = {resId: mr.resId, mode: "AUTO"}; // if formula not in elementType, by default, we compute with mode="AUTO"

    const useMeas = applyFormulaFilter(
      measurement,
      res.func,
      zonesById[measurement.zoneId],
      roomsById[measurement.roomId],
      sectorsById[measurement.sectorId],
      filterNoVoids
    );
    //
    if (useMeas) {
      const newMr = {...mr};
      let mQ = computeRessourceQuantity({
        item: measurement,
        res,
        ressource,
        withDecimal: false,
        filterNoVoids: true,
      });
      newMr.qtyRaw = Math.round(mQ * 100000) / 100000;
      if (Array.isArray(measurement.voids)) {
        mQ = computeRessourceQuantity({
          item: measurement,
          res,
          ressource,
          withDecimal: false,
          filterNoVoids: false,
        });
      }
      newMr.qtyNet = Math.round(mQ * 100000) / 100000;
      return newMr;
    } else {
      return mr;
    }
  });
}
