import {useDispatch, useSelector} from "react-redux";
import {Draggable} from "react-beautiful-dnd";

import {
  // updateIsOrthoObject,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";

import {
  ListItemButton,
  ListItem,
  Typography,
  ListItemText,
  CircularProgress,
  Switch,
  Box,
} from "@mui/material";
import {
  RadioButtonUnchecked,
  CheckCircle,
  DragIndicator as Handle,
  // Pause,
} from "@mui/icons-material";

import ButtonDetailModel from "./ButtonDetailModel";

import {getFileSizeString} from "Features/files/utils";
// import useTranslation from "Features/translations/useTranslation";
import useModelLoadingProgress from "Features/viewer3D/useModelLoadingProgress";

export default function ModelListItem({
  model,
  editor3d,
  onClick,
  selected,
  index,
}) {
  const dispatch = useDispatch();
  // const {t} = useTranslation(["viewer3D", "common"]);
  const progress = useModelLoadingProgress(model);

  // props

  const color = model?.color ? model.color : "#FFFFFF";

  // data

  const modelsStatus = useSelector((state) => state.viewer3D.modelsStatus);
  let status = "idle";
  if (modelsStatus[model.sceneClientId])
    status = modelsStatus[model.sceneClientId][model.id];
  if (!status) status = "idle";

  // const detailedModels = useSelector((state) => state.viewer3D.detailedModels);
  // const detailed = detailedModels.includes(model.id);

  // handlers

  function handleButtonClick() {
    onClick(model.id);
  }

  function handleSeeModelClick(e) {
    e.preventDefault();
    e.stopPropagation();
    editor3d?.cameras.fitCameraTo({
      models: [model],
      controls: editor3d?.controls.activeControls,
    });
  }
  const icons = {
    loading: (
      <CircularProgress
        size={16}
        variant="determinate"
        value={progress * 100}
      />
    ),
    idle: <RadioButtonUnchecked fontSize="small" color="inherit" />,
    loaded: <CheckCircle fontSize="small" color="success" />,
  };
  // const icon = status ? icons[status] : <Pause />;

  // helpers

  function handleCheckToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    const checked = e.target.checked;
    const newModel = {...model, hidden: !checked};
    dispatch(updateModel({updatedModel: newModel, sync: false}));
    const entity = editor3d?.getEntity(model.id);
    if (entity) entity.model = newModel;
    if (checked) {
      entity?.show();
    } else {
      entity?.hide();
    }
  }
  return (
    <Draggable draggableId={model.id} index={index}>
      {(provided, _) => (
        <Box
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          sx={{borderLeft: `4px solid ${color}`}}
        >
          <ListItem
            key={model.id}
            secondaryAction={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  color: "divider",
                }}
              >
                <ButtonDetailModel model={model} editor3d={editor3d} />
                <Switch
                  edge="end"
                  checked={!model.hidden}
                  tabIndex={-1}
                  onChange={handleCheckToggle}
                  size="small"
                  sx={{ml: 1}}
                />
              </Box>
            }
            disablePadding
          >
            <ListItemButton
              size="small"
              sx={{width: 1, py: 0}}
              onClick={() => handleButtonClick()}
              selected={selected}
            >
              <Handle fontSize="small" color="action" sx={{ml: -2, mr: 1}} />
              <ListItemText
                primary={
                  <Typography noWrap variant="body2" sx={{pr: 5}}>
                    {model.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    noWrap
                    sx={{pr: 5, color: "text.secondary", fontSize: 12}}
                  >
                    {model.fileSize ? getFileSizeString(model.fileSize) : "-"}
                  </Typography>
                }
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        </Box>
      )}
    </Draggable>
  );
}
