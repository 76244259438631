import articleTypesMapToArray from "./articleTypesMapToArray";

export default function stateToRemoteArticle(article) {
  const remoteArticle = {...article};
  //
  remoteArticle.articlesGroup = remoteArticle.articlesGroupId;
  //
  if (remoteArticle.articlesGroupId) delete remoteArticle.articlesGroupId;

  if (!remoteArticle.id) delete remoteArticle.id;
  //
  if (remoteArticle.types) {
    remoteArticle.types = articleTypesMapToArray(remoteArticle.types);
  }
  if (remoteArticle.coreTypes) {
    remoteArticle.coreTypes = articleTypesMapToArray(remoteArticle.coreTypes);
  }
  //
  return remoteArticle;
}
