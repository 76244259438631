import {client} from "API/capla360";

export async function fetchBimboxOrder(sharedBimboxId) {
  try {
    const response = await client.get(`bimboxOrder/${sharedBimboxId}`);
    return response.data;
  } catch (error) {
    console.log("error");
  }
}
