import extractNomenclatureTypesFromGroup from "./extractNomenclatureTypesFromGroup";
import updateTypeNomenclatureFromMeasurements from "./updateTypeNomenclatureFromMeasurements";

export default function updateTypesGroupWithNomenclature(
  typesGroupWithSortedTypes,
  selectedElementType,
  measurements
) {
  //
  const headerTypeId = selectedElementType.id;
  //
  const oldTypeNomenclature = extractNomenclatureTypesFromGroup(
    typesGroupWithSortedTypes,
    headerTypeId
  );
  //
  const updatedNomenclature = updateTypeNomenclatureFromMeasurements(
    oldTypeNomenclature,
    measurements
  );
  //
  const oldTypeIds = oldTypeNomenclature.map((type) => type.id);
  let updatedTypes = typesGroupWithSortedTypes.elementTypes.filter(
    (t) => !oldTypeIds.includes(t.id)
  );
  //
  const updatedTypesGroup = {
    ...typesGroupWithSortedTypes,
    elementTypes: [...updatedTypes, ...updatedNomenclature],
  };

  return [updatedTypesGroup, updatedNomenclature];
}
