// styles

import stringifyArticleTypes from "Features/articles/utils/stringifyArticleTypes";
import applyStyleToRow from "./applyStyleToRow";

export default function fillWorksheetWithArticlesWithIds({
  worksheet,
  articlesWithIds,
  elementTypesGroups,
  rowStartIndex,
}) {
  //
  const length = articlesWithIds.length;
  worksheet.getRow(2).getCell(2).value = 3 + length;
  worksheet.getRow(2).getCell(2).font = worksheet.getRow(1).getCell(2).font;
  //
  articlesWithIds.forEach((article, index) => {
    const {
      id,
      num,
      name,
      code,
      unit,
      dec,
      refQty,
      hardcodedQty,
      description,
      comment,
      style,
      isAdded,
      types,
      coreTypes,
      isSubArticle,
    } = article;
    //
    const row = worksheet.getRow(rowStartIndex + index);

    applyStyleToRow({row, item: article, rowType: "ARTICLE"});

    if (typeof id === "string") row.getCell(1).value = id;
    if (typeof num === "string") row.getCell(2).value = num;
    if (typeof name === "string") row.getCell(3).value = name;
    if (typeof code === "string" || isSubArticle)
      row.getCell(4).value = isSubArticle ? "-" : code;
    //
    if (coreTypes)
      row.getCell(5).value = stringifyArticleTypes(
        coreTypes,
        elementTypesGroups
      );
    if (typeof description === "string") row.getCell(6).value = description;
    //
    if (typeof unit === "string") row.getCell(7).value = unit;
    if (typeof hardcodedQty === "number") row.getCell(8).value = hardcodedQty;
    if (typeof refQty === "number") row.getCell(9).value = refQty;
    // - gap value
    if (typeof comment === "string") row.getCell(11).value = comment;
    //
  });
}
