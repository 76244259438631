import {Box} from "@mui/material";

import ButtonExcel from "./ButtonExcel";

export default function ToolbarMaterialsMain({scene}) {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
      }}
    >
      <Box sx={{display: "flex", alignItems: "center", ml: 1}}>
        <ButtonExcel scene={scene} />
      </Box>
    </Box>
  );
}
