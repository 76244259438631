import {client} from "API/capla360";

export async function fetchRowsService({accessToken, listingId}) {
  const response = await client.get(`listings/${listingId}/rows/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, listingId};
}

export async function createRowService({accessToken, listingId, rows}) {
  const response = await client.post(`listings/${listingId}/rows/`, rows, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, listingId};
}
