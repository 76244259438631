import React, {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {
  removeTempNoteElement,
  setTempNoteElements,
} from "Features/note/noteSlice";

import {Box, Typography, Chip} from "@mui/material";

export default function LinkedElements({initialLinkedElements, editing}) {
  const dispatch = useDispatch();

  // string

  const linkedElementsString = "Linked elements : ";
  const noElementsString = "0 linked element";

  // data

  const tempNoteElements = useSelector((s) => s.note.tempNoteElements);

  // helper

  const elements = [];
  if (tempNoteElements) {
    Object.entries(tempNoteElements).forEach(([key, value]) => {
      value.codeNames.forEach((codeName) => {
        elements.push({codeName, modelRemoteId: key});
      });
    });
  }

  const initialElements = [];
  if (initialLinkedElements) {
    Object.entries(initialLinkedElements).forEach(([key, value]) => {
      value.codeNames.forEach((codeName) => {
        initialElements.push({codeName, modelRemoteId: key});
      });
    });
  }

  // helpers

  const elts = editing ? elements : initialElements;

  const elementsString =
    elts.length > 0 ? linkedElementsString : noElementsString;

  // handlers

  function handleDeleteClick(modelRemoteId, codeName) {
    dispatch(removeTempNoteElement({modelRemoteId, codeName}));
  }

  // effect

  useEffect(() => {
    if (editing) {
      dispatch(setTempNoteElements(initialLinkedElements));
    }
  }, [editing]);

  return (
    <Box
      sx={{
        px: 1,
        display: "flex",
        alignItems: "center",
        width: 1,
        flexWrap: "wrap",
      }}
    >
      <Typography variant="body2" color="text.secondary" sx={{mr: 1, mb: 0.5}}>
        {elementsString}
      </Typography>
      {elts.map(({codeName, modelRemoteId}) => {
        return editing ? (
          <Chip
            sx={{mr: 1, mb: 0.5}}
            size="small"
            key={codeName}
            label={codeName}
            onDelete={
              editing && (() => handleDeleteClick(modelRemoteId, codeName))
            }
          />
        ) : (
          <Chip
            sx={{mr: 1, mb: 0.5}}
            size="small"
            key={codeName}
            label={codeName}
          />
        );
      })}
    </Box>
  );
}
