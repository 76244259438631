import {useSelector, useDispatch} from "react-redux";
import {useEffect, useState} from "react";

import {
  // toggleShortcutElementType,
  setShortcutElementTypes,
} from "Features/measurements/measurementsSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
// import useElementTypeGroupsByScene from "Features/elementTypes/hooks/useElementTypeGroupsByScene";
// import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import {Box, alpha} from "@mui/material";

import TableGroupedItems from "Components/TableGroupedItems";
import sortByNum from "Utils/sortByNum";
import getElementTypesWithQtiesV2 from "Features/elementTypes/utils/getElementTypesWithQtiesV2";

export default function ShortcutElementTypesIn3D({
  scene,
  mode,
  viewer,
  fullWidth,
}) {
  const dispatch = useDispatch();

  // string

  const headerTitleS = "éléments repérés";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });

  // const elementTypesGroupsProxy = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  // const elementTypesGroups = useElementTypeGroupsByScene(scene);

  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false,
    true,
    viewer
  );

  let measurements = useSelector(
    (s) => s.measurements.measurementsShowedInViewer3dWithoutShortcut
  );
  if (!measurements) measurements = [];

  const ms = viewer ? fms : measurements;

  // local state

  const [expandGroupIds, setExpandGroupIds] = useState();

  // helpers - elementTypeIds from list of measurements.

  // BUG !!! undefined in ms ?!

  const elementTypesWithQties = getElementTypesWithQtiesV2(elementTypes, ms);
  const idQtyMap = elementTypesWithQties.reduce((ac, et) => {
    ac[et.id] = {count: et?.measCount, qty: et?.mainQty, unit: et?.mainUnit};
    return ac;
  }, {});

  const sceneElementTypeIds = ms.map((m) => m?.elementTypeId);

  const elementTypeIdCountMap = [...new Set(sceneElementTypeIds)].reduce(
    (ac, elementTypeId) => {
      ac[elementTypeId] = idQtyMap[elementTypeId];
      return ac;
    },
    {}
  );

  // helpers - for table

  const allSortedItems = elementTypes
    .filter((t) => t?.id)
    .map((t) => {
      const newType = {...t};
      if (!newType.group) {
        newType.groupName = "-?-";
        newType.groupId = "-?-";
      }
      if (!newType.groupId) newType.groupId = t.group;
      if (!newType.groupName) newType.groupName = t.group;
      newType.unit = idQtyMap[t.id]?.unit ?? "-";
      newType.num = t.num ?? "0";
      return newType;
    })
    .sort(
      (a, b) =>
        a.groupName.localeCompare(b.groupName) || sortByNum(a.num, b.num)
    );

  const scopedItemIdsWithCount = Object.entries(elementTypeIdCountMap).map(
    ([id, qtyObject]) => ({
      id,
      qty: qtyObject?.qty,
      count: qtyObject?.count,
    })
  );
  // const scopedItemIdsWithCount = idQtyMap
  //   ? Object.entries(idQtyMap).map(([id, qtyObject]) => ({
  //       id,
  //       qty: qtyObject.qty,
  //       count: qtyObject.count,
  //     }))
  //   : [];

  const showedItemIds = Array.isArray(shortcutElementTypes)
    ? [...shortcutElementTypes]
    : [];
  // const showedItemIds = [...Object.keys(elementTypeIdCountMap)];

  const allGroupIds = [...new Set(allSortedItems.map((item) => item.groupId))];

  useEffect(() => {
    if (!expandGroupIds) setExpandGroupIds(allGroupIds);
  }, [expandGroupIds]);

  //const handleShowedItemsChange = (ids)=>{console.log("ids",ids)},
  // const nomenclatures = ,
  //   expandGroups,
  //   onExpandGroupsChange,

  // handlers

  // function handleElementTypeClick(elementTypeId) {
  //   dispatch(toggleShortcutElementType(elementTypeId));
  // }

  // function handleShowAll() {
  //   dispatch(setShortcutElementTypes(sceneElementTypeIds));
  // }

  // function handleHideAll() {
  //   dispatch(setShortcutElementTypes([]));
  // }

  // handlers - table

  const onShowedItemIdsChange = (ids) => {
    console.log("[AAA] ids");
    dispatch(setShortcutElementTypes(ids));
  };

  const onExpandGroupIdsChange = (ids) => {
    setExpandGroupIds(ids);
  };

  return (
    <Box
      sx={{
        width: fullWidth ? 1 : 300,
        bgcolor: (theme) => alpha(theme.palette.background.default, 0.95),
        p: 0.5,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <TableGroupedItems
        headerTitle={headerTitleS}
        allSortedItems={allSortedItems}
        scopedItemIdsWithCount={scopedItemIdsWithCount}
        showedItemIds={showedItemIds}
        onShowedItemIdsChange={onShowedItemIdsChange}
        expandGroupIds={expandGroupIds}
        onExpandGroupIdsChange={onExpandGroupIdsChange}
        options={{
          disableQties: false,
          disableNums: true,
          disableDescriptions: true,
        }}
      />
    </Box>
  );
}
