export default function updateFiltersStrWithOneFiltersStr(
  filtersStr,
  oneFiltersStr,
  mode
) {
  let result;
  if (mode === "replace") {
    console.log("replaceFiltersStr with", oneFiltersStr);
    if (!oneFiltersStr) {
      result = [];
    }
    result = [oneFiltersStr];
  } else if (mode === "merge") {
    if (!filtersStr?.length > 0) {
      result = [oneFiltersStr];
    } else if (filtersStr && !oneFiltersStr.length > 0) {
      result = filtersStr;
    } else if (Array.isArray(filtersStr) && oneFiltersStr) {
      result = [...new Set([...filtersStr, oneFiltersStr])];
    }
  }

  //
  // console.log(
  //   "debugAFA updateFiltersS",
  //   filtersStr,
  //   oneFiltersStr,
  //   mode,
  //   result
  // );
  //
  return result;
}
