import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import useUpdateSceneElementType from "../hooks/useUpdateSceneElementType";

import {updateScene} from "Features/scenes/scenesSlice";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";

const unitMap = {
  UN: "u",
  ML: "m",
  M2: "m²",
  M3: "m³",
};

const formulMap = {
  UN: "C",
  ML: "L",
  M2: "S",
  M3: "V",
};

export default function useCreateRessourcesFromTypes() {
  const dispatch = useDispatch();
  const createRessourcesFromTypes = (scene, types, tableName, relations) => {
    if (!scene) return;
    let oldRessources = scene?.data?.ressources?.filter(
      (r) => r?.group !== tableName
    );
    if (!oldRessources) oldRessources = [];
    const updatedTypes = [];
    const newRessources = [];
    const editedRelations = {...relations};
    types.forEach((t) => {
      const id = nanoid();
      const relation = {
        id: `${t.id}-${id}`,
        typeId: t.id,
        resId: id,
        mode: "FORMUL",
        func: formulMap[t.unit],
      };
      const newRessource = {
        id,
        num: t.num,
        code: t.code,
        name: t.name,
        description: "",
        group: tableName,
        unit: unitMap[t.unit],
        types: [relation],
      };
      if (t.isTitle) newRessource.isTitle = t.isTitle;
      if (t.style) newRessource.style = t.style;
      newRessources.push(newRessource);
      const updatedType = {...t};
      if (!t.isTitle)
        updatedType.res = t.res ? [...t.res, relation] : [relation];
      updatedTypes.push(updatedType);
      editedRelations[`${t.id}-${id}`] = relation;
    });
    dispatch(setRelationsTypesRessources(editedRelations));
    const newScene = {
      ...scene,
      data: {...scene?.data, ressources: [...oldRessources, ...newRessources]},
    };
    dispatch(updateScene({scene: newScene}));
    return updatedTypes;
  };
  return createRessourcesFromTypes;
}
