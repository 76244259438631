import {useDispatch} from "react-redux";
import {setOpenDialogSelectElementType} from "Features/elementTypes/elementTypesSlice";

import {Box, Typography} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

export default function ToolbarBottomViewerPdfCallToAction() {
  const dispatch = useDispatch();

  // strings

  const selectS = "Sélectionnez un élément / ouvrage à repérer";

  // handlers

  function handleClick() {
    dispatch(setOpenDialogSelectElementType(true));
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: "warning.main",
        color: "common.white",
        borderRadius: "8px",
        p: 1,
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Typography variant="body2" sx={{mr: 2}} noWrap>
        {selectS}
      </Typography>
      <Down sx={{fill: "action.main"}} />
    </Box>
  );
}
