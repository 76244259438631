import {Box} from "@mui/material";
import {useOutletContext} from "react-router-dom";

import useInitFetchViews from "../hooks/useInitFetchViews";

export default function PageViews() {
  //

  const {caplaEditor, scene} = useOutletContext();

  // init

  useInitFetchViews(scene?.id);

  return <Box sx={{width: 1, height: 1}}></Box>;
}
