import {useEffect, useState} from "react";

import Fuse from "fuse.js";

import {InputBase, Box, IconButton} from "@mui/material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";
import {Search as SearchIcon, Close} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

const tokeniseStringWithQuotesBySpaces = (string) =>
  string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];

export default function SearchBarObjects({
  searchText,
  onSearchTextChange,
  onSearch,
  objects,
  keys,
  onFocus,
  onBlur,
  onClose,
  darkMode = false,
}) {
  const {t} = useTranslation("common");
  const placeholderString = capitalizeStart(t("common:search"));

  let text = searchText ?? "";

  // helpers - fuse search

  const options = {
    findAllMatches: true,
    ignoreLocation: true,
    keys,
    threshold: 0.0,
    distance: 0,
  };
  const fuse = new Fuse(objects, options);

  // helpers - color

  const color = darkMode ? "common.white" : "inherit";
  const bgcolor = darkMode
    ? theme.palette.common.caplaBlack
    : theme.palette.background.default;

  // handlers

  function handleChange(e) {
    let newText = e.target.value;
    onSearchTextChange(newText);

    // onSearch(result);
    newText = tokeniseStringWithQuotesBySpaces(newText);

    if (newText.length === 0) {
      onSearch(objects);
    } else if (onSearch) {
      const result = fuse.search({
        $and: newText.map((searchToken) => {
          const orFields = keys.map((k) => ({[k]: searchToken}));
          return {$or: orFields};
        }),
      });
      onSearch(result.map((r) => r.item));
    }
  }

  // handlers

  function handleFocus() {
    if (onFocus) onFocus();
  }
  function handleBlur() {
    if (onBlur) onBlur();
  }
  function handleClose() {
    onSearchTextChange("");
    if (onClose) onClose();
  }

  // // effect

  // useEffect(() => {
  //   if (!text) {
  //     onSearchTextChange("");
  //     onSearch(objects);
  //   }
  // }, [text]);

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.shape.borderRadius,
        backgroundColor: bgcolor,
        "&:hover": {
          backgroundColor: lighten(bgcolor, 0.1),
        },
        width: 1,
        height: "30px",
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <InputBase
        fullWidth
        placeholder={`${placeholderString} ...`}
        value={text}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fontSize={12}
        inputProps={{size: "small", sx: {m: 0, py: 0.5, fontSize: 12}}}
        sx={{color}}
      />
      {text.length > 0 && (
        <IconButton onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
