import {IconButton, Box, Tooltip} from "@mui/material";
import IconElementType from "./IconElementType";

export default function ContainerElementTypeOfRawType({
  id,
  color,
  drawingShape,
  name,
  onClick,
}) {
  // handlers

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    console.log("click elementType", name);
    if (onClick) onClick(id);
  }
  return (
    <Box>
      <Tooltip title={name}>
        <IconButton onClick={handleClick}>
          <IconElementType drawingShape={drawingShape} color={color} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
