import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchColoringJobs} from "./coloringJobsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useColoringJobsByScene(scene) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const jobs = useSelector((s) => s.coloringJobs.items);

  useEffect(() => {
    async function getJobs() {
      dispatch(fetchColoringJobs({accessToken, sceneId: scene?.id}));
    }
    if (accessToken && scene?.id) {
      getJobs();
    }
  }, [scene?.id]);

  return jobs;
}
