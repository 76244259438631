import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import DataGridPricings from "./DataGridPricings";
import SectionTitlePricings from "./SectionTitlePricings";
import ToolbarPricingsMain from "./ToolbarPricingsMain";
import SectionPricingsSummary from "./SectionPricingsSummary";

import { fetchPrices, setSelectedGroupPricing } from "../pricingSlice";

import Stretch from "Features/ui/components/Stretch";
import useSharedboxesByScene from "Features/sharedboxes/hooks/useSharedboxesByScene";
import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";
import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";

export default function PagePricings() {
  const dispatch = useDispatch();

  // params

  const {scene} = useOutletContext();

  // data

  const sharedboxes = useSharedboxesByScene(scene);
  const ressourcesGroups = useRessourceGroupsByScene(scene);
  const pricings = useSelector((s) => s.pricing.pricings);
  const fetchStatus = useSelector((s) => s.pricing.status);
  const selectedGroupPricing = useSelector((s) => s.pricing.selectedGroupPricing);
  let ressources = useMeasurementsByRessourceForDatagrid(scene, "ONE_MODEL", 2);

  // helpers
  if (selectedGroupPricing)
    ressources = ressources.filter((r) => r.group === selectedGroupPricing);

  const ressourceQtyMap = {};
  ressources.forEach((r) => {
    ressourceQtyMap[r.id] = r.hardCodedQty ? r.hardCodedQty : r.quantity;
  })

  let concernedSharedBoxes = [];
  let concernedGroups = new Set();
  sharedboxes.forEach((s) => {
    concernedSharedBoxes.push(s);
    if (s.usesPrices) {
      if (s.entities?.ressourceGroups?.length === 0)
        concernedGroups = new Set(ressourcesGroups);
      else if (s.entities?.ressourceGroups?.length > 0)
        s.entities.ressourceGroups.forEach(concernedGroups.add, concernedGroups);
    }
  })

  const sharedboxesNbByGroup = {};
  concernedGroups.forEach((g) => {
    sharedboxesNbByGroup[g] = concernedSharedBoxes.filter(
      (s) => s.entities.ressourceGroups.includes(g)
    ).length;
  })

  concernedSharedBoxes = concernedSharedBoxes.filter(
    (s) => s.usesPrices && s.entities.ressourceGroups.includes(selectedGroupPricing)
  );

  const resIdsForGroup = new Set(ressources.map((r) => r.id));
  const groupPricings = pricings.filter((p) => resIdsForGroup.has(p.resId));

  const boxesWithPrice = concernedSharedBoxes.map((box) => {
    const boxPricings = groupPricings.filter((p) => p.sharedBimboxId === box.id);
    return {
      ...box,
      pricings: boxPricings,
      accumulatedPrice: boxPricings.reduce((acc, v) => 
        v.pu ? acc + (ressourceQtyMap[v.resId] * parseFloat(v.pu)) : acc,
        0,
      )
    };
  }).sort((a, b) => a.accumulatedPrice - b.accumulatedPrice);

  // effects

  useEffect(() => {
    if (scene?.id && fetchStatus === "idle")
      dispatch(fetchPrices({sceneId: scene.id}));
  }, [scene?.id, fetchStatus])

  useEffect(() => {
    if (!selectedGroupPricing && ressourcesGroups?.length > 0) {
      const relevantGroup = ressourcesGroups.find((g) => concernedGroups.has(g));
      dispatch(setSelectedGroupPricing(relevantGroup));
    }
  }, [ressourcesGroups, selectedGroupPricing])

  useEffect(() => {
    dispatch(setSelectedMeasurementsModelId(null));
    // caplaEditor?.measDataManager.setSelectedModelById(null);
  }, [])

  return (
    <Box sx={{display: "flex", width: 1, height: 1, flexDirection: "column"}}>
      <SectionTitlePricings sceneId={scene?.id} />
      <ToolbarPricingsMain
        ressourcesGroups={ressourcesGroups}
        concernedGroups={concernedGroups}
        sharedboxesNbByGroup={sharedboxesNbByGroup}
      />
      <SectionPricingsSummary sharedboxes={boxesWithPrice} />
      <Stretch>
        <DataGridPricings
          ressources={ressources}
          sharedboxes={boxesWithPrice}
        />
      </Stretch>
    </Box>
  );
}
