import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {setScopeIdToOpen} from "Features/overviewer/overviewerSlice";
import {setSelectedScopeId} from "../scopesSlice";
import {setOpenDialogFsSelectScope} from "Features/overviewer/overviewerSlice";

import {
  Box,
  Menu,
  MenuList,
  MenuItem,
  Tooltip,
  Typography,
  ListItemIcon,
} from "@mui/material";
import {lighten} from "@mui/material";
import {ArrowDropDown, Add} from "@mui/icons-material";
import theme from "Styles/theme";

import ContainerScope from "./ContainerScope";
import SelectorScopeListHeader from "./SelectorScopeListHeader";
import useSceneDefaultScopeId from "../hooks/useSceneDefaultScopeId";

export default function SelectorScope({
  scene,
  selectedScope,
  onChange,
  scopes,
  noScopeLabel,
  viewer,
  onCreateClick,
  onEditClick,
  onDeleteClick,
  onRefreshClick,
  onOpenClick,
  showDefault = false,
}) {
  const dispatch = useDispatch();

  // string

  const createS = "Créer une sous-bimbox";

  // data

  const sceneDefaultScopeId = useSceneDefaultScopeId(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [tempScope, setTempScope] = useState(selectedScope);
  useEffect(() => {
    setTempScope(selectedScope);
  }, [selectedScope?.id, open]);

  // helpers

  const noSelectedScope = selectedScope?.id === "all";

  // helpers - bgcolor

  let bgcolor = lighten(theme.palette.common.caplaBlack, 0.2);
  let color = "common.white";
  if (tempScope && tempScope.id !== "all") {
    bgcolor = theme.palette.background.default;
    color = theme.palette.text.primary;
  }

  // helpers - scope label

  let scopeLabel = selectedScope?.name;
  if (selectedScope?.id === "all") scopeLabel = "Sous-bimbox";

  // handlers

  function handleOpenMenu(e) {
    //setAnchorEl(e.currentTarget);
    dispatch(setOpenDialogFsSelectScope(true));
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(scope) {
    setTempScope(scope);
  }

  function handleCreateClick() {
    onCreateClick();
    setAnchorEl(null);
  }
  function handleDeleteClick() {
    onDeleteClick();
    setAnchorEl(null);
  }
  function handleEditClick() {
    onEditClick();
    setAnchorEl(null);
  }
  function handleApplyClick() {
    //
    dispatch(setSelectedScopeId(tempScope?.id));
    dispatch(setScopeIdToOpen(tempScope?.id));
    //
    // fetchModels(); // manage by auto Fetch
    //
    onOpenClick(tempScope);
    setAnchorEl(null);
  }

  return (
    <Box sx={{display: "flex", minWidth: 0}}>
      <Tooltip title={selectedScope?.name}>
        <Box
          onClick={handleOpenMenu}
          sx={{
            cursor: "pointer",
            px: 1,
            //py: 0.5,
            bgcolor,
            color,
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              fontStyle: noSelectedScope ? "italic" : "normal",
            }}
          >
            {scopeLabel}
          </Typography>
          {<ArrowDropDown fontSize="small" color="action" />}
        </Box>
      </Tooltip>

      {open && (
        <Menu
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          open={open}
          sx={{"& .MuiList-root": {p: 0}}}
        >
          <ContainerScope
            scene={scene}
            scope={tempScope}
            onDeleteClick={handleDeleteClick}
            onEditClick={handleEditClick}
            onOpenClick={handleApplyClick}
            variant="selector"
            hideEditActions={tempScope?.id !== selectedScope?.id}
            hideOpenButton={tempScope?.id === selectedScope?.id}
          />

          <MenuList disablePadding>
            <SelectorScopeListHeader
              onRefreshClick={onRefreshClick}
              onMoreClick={() => {
                setAnchorEl(null);
              }}
            />

            {scopes.map((scope, index) => {
              const selected = scope.id === tempScope?.id;
              const isDefault =
                scope?.id === sceneDefaultScopeId ||
                ("all" === scope?.id && !sceneDefaultScopeId);

              return (
                <MenuItem
                  divider
                  key={scope.id}
                  onClick={() => handleChange(scope)}
                  selected={selected}
                >
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        ml: 1,
                        fontStyle: scope?.id === "all" ? "italic" : "normal",
                        color:
                          scope?.id === "all"
                            ? "text.secondary"
                            : "text.primary",
                      }}
                    >{`${scope.name}`}</Typography>
                    {showDefault && isDefault && (
                      <Box
                        sx={{
                          width: "16px",
                          height: "8px",
                          borderRadius: "4px",
                          bgcolor: "primary.main",
                        }}
                      />
                    )}
                  </Box>
                </MenuItem>
              );
            })}

            {!viewer && onCreateClick && (
              <MenuItem onClick={handleCreateClick} sx={{pt: 1}}>
                <ListItemIcon>
                  <Add />
                </ListItemIcon>
                <Typography variant="body2" sx={{ml: 1}}>
                  {createS}
                </Typography>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
}
