import {useState} from "react";

import useActionsScopesMap from "Features/scopes/hooks/useActionsScopesMap";

import {Box} from "@mui/material";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogFsCreateAndUpdateScope from "./DialogFsCreateAndUpdateScope";

export default function SectionActionsScopes({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogEditScope, setOpenDialogEditScope] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    updateScope: () => setOpenDialogEditScope(true),
    createScope: () => setOpenDialogEditScope(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogEditScope() {
    setOpenDialogEditScope(false);
  }

  // data - handlers

  const actionsMap = useActionsScopesMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogEditScope && (
        <DialogFsCreateAndUpdateScope
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogEditScope}
          onClose={handleCloseDialogEditScope}
        />
      )}
    </>
  );
}
