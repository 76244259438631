import React, {useState} from "react";

import useAccessToken from "Features/auth/useAccessToken";

import {useDispatch} from "react-redux";

import {createContribution} from "Features/contributions/contributionsSlice";
import {createNoteContribution} from "Features/note/noteSlice";

import {Paper, Typography, LinearProgress, Box} from "@mui/material";

import TextEditor from "Features/textEditor/components/TextEditor/TextEditor";
import Toolbar from "Features/textEditor/components/Toolbar";

export default function EditableContributionCard({
  contribution,
  initialEditing,
  listingId,
  sceneClientId,
  entityToId,
  entityToType,
  closeButton,
  onClose,
  isOpened,
  viewer,
}) {
  const dispatch = useDispatch();

  // const

  const initialValue = [
    {
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ];

  // sync

  const accessToken = useAccessToken();

  // initial value
  const initialContributionData = contribution
    ? JSON.parse(contribution.data)
    : initialValue;

  // local state

  const [contributionData, setContributionData] = useState(
    initialContributionData
  );

  const [editing, setEditing] = useState(initialEditing);
  const [loading, setLoading] = useState(false);

  // handlers

  function handleContributonChange(data) {}

  async function handleContributionSave() {
    const newContribution = {
      data: JSON.stringify(contributionData),
      entityToId,
      entityToType,
    };
    try {
      if (entityToType === "note") {
        setLoading(true);
        await dispatch(
          createNoteContribution({
            accessToken,
            contribution: newContribution,
            listingId,
          })
        );
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setContributionData(initialValue);
    }
  }
  return (
    <Box sx={{width: "100%", mb: 1}}>
      <Paper elevation={0}>
        <Box sx={{backgroundColor: "common.white"}}>
          <TextEditor
            initialValue={contributionData}
            onChange={setContributionData}
            listingId={listingId}
            accessToken={accessToken}
            readOnly={!editing}
            placeholder={"Détaillez ici votre contribution"}
          />

          {editing && (
            <Box sx={{padding: 1}}>
              <Toolbar
                onSave={handleContributionSave}
                loading={loading}
                closeButton={closeButton}
                onClose={onClose}
                isOpened={isOpened}
                viewpoint={false}
                viewer={viewer}
              />
            </Box>
          )}
        </Box>
      </Paper>
      {!editing && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Typography variant="caption" color="text.secondary" sx={{mr: 1}}>
            {new Date(contribution?.createdAt).toLocaleDateString()}
          </Typography>

          <Typography variant="caption" color="text.secondary">
            {contribution?.createdBy}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
