import * as atlas from "azure-maps-control";

import {setAzmEditorIsLoaded} from "Features/viewer3D/viewer3DSlice";
import worksitesToGeoJson from "../utils/worksitesToGeoJson";

import theme from "Styles/theme";

export default class AzureMapEditor {
  ready; // to call only once the onReady callback

  map;
  worksitesData;
  worksitesLayer;
  mouseOverMarker; // to stop click map listener.

  onClick;
  clickHandler; // callback called from a react component.

  originLatLng; // lat lng of the (0,0) cartesian origin. Depends on the selected scene / worksite.
  originMarker; // marker to localise the origin of the map

  picturePopup; // popup used to display images

  constructor({map, editor, onClick}) {
    this.map = map;
    this.editor = editor;
    this.isLoaded = false;

    this.addListeners();
    this.onClick = onClick;

    this.originLatLng = {lat: 48.85303399657422, lng: 2.350214329829216};
    this.ready = false;
  }

  setClickHandler = (clickHandler) => {
    this.clickHandler = clickHandler;
  };

  setOriginLatLng(latLng) {
    this.originLatLng = latLng;
    this.originMarker.setOptions({
      position: [this.originLatLng.lng, this.originLatLng.lat],
    });
  }

  addOriginMarker() {
    this.originMarker = new atlas.HtmlMarker({
      position: [this.originLatLng.lng, this.originLatLng.lat],
      color: theme.palette.primary.flash,
    });
    this.map.markers.add(this.originMarker);
  }

  addListeners() {
    this.map.events.add("ready", () => {
      if (!this.ready) {
        this.addDataSources();
        this.addLayers();
        this.addStyleControl();
        this.addOriginMarker();
        this.editor.dispatch(setAzmEditorIsLoaded(true));
        this.ready = true;
      }
    });
  }

  addStyleControl() {
    this.map.controls.add(
      new atlas.control.StyleControl({
        mapStyles: ["satellite_road_labels", "road"],
      }),
      {position: "top-right"}
    );
  }
  addDataSources() {
    this.worksitesData = new atlas.source.DataSource();
    this.map.sources.add(this.worksitesData);
  }

  addLayers() {
    this.worksitesLayer = new atlas.layer.SymbolLayer(
      this.worksitesData,
      null
      // {
      //   iconOptions: {image: "pin-darkblue"},
      // }
    );

    this.map.events.add("mouseover", this.worksitesLayer, () => {
      this.map.getCanvasContainer().style.cursor = "pointer";
      this.mouseOverMarker = true;
    });

    this.map.events.add("mouseout", this.worksitesLayer, () => {
      this.map.getCanvasContainer().style.cursor = "grab";
      this.mouseOverMarker = false;
    });

    this.map.events.add("click", this.worksitesLayer, (e) => {
      const shapeId = e.shapes[0].data.id;
      e.preventDefault();
      //this.onClick({action: "goToWorksite", worksiteId: shapeId});
      this.onClick({
        action: "seeDetail",
        clickedObject: {worksite: {id: shapeId}},
      });
    });

    this.map.events.add("click", (e) => {
      e.preventDefault();
      console.log("click on map", e);
      if (!this.mouseOverMarker) {
        this.onClick({
          action: "void",
          latLng: {lng: e.position[0], lat: e.position[1]},
          screenP: {x: e.clientX, y: e.clientY},
        });
        if (this.clickHandler) {
          this.clickHandler({
            action: "void",
            latLng: {lng: e.position[0], lat: e.position[1]},
            screenP: {x: e.originalEvent?.clientX, y: e.originalEvent?.clientY},
          });
        }
      }
    });
  }
  // map

  resizeMap() {
    this.map.resize();
  }

  goTo({lat, lng}) {
    this.map.setCamera({center: [lng, lat], type: "fly", duration: 500});
  }

  fitCam1Bounds() {
    const {sw, ne} = this.editor.sceneEditor.getCam1BoundsLatLng();
    const bounds = [sw.lng, sw.lat, ne.lng, ne.lat];
    this.map.setCamera({bounds});
  }

  getCenter() {
    const [lng, lat] = this.map.getCamera().center;
    return {lat, lng};
  }

  getCenterPosition(north) {
    const latLng = this.getCenter();
    return this.latLngToCoord(latLng, north);
  }

  getZoom() {
    const zoom = this.map.getCamera().zoom;
    return zoom;
  }

  getBounds() {
    return this.map.getCamera().bounds;
  }
  getBoundsSize(bounds) {
    const [swLng, swLat, neLng, neLat] = bounds;
    const width = atlas.math.getDistanceTo([swLng, swLat], [neLng, swLat]);
    const height = atlas.math.getDistanceTo([swLng, swLat], [swLng, neLat]);
    console.log("get mapSize", width, height);
    return {width, height};
  }
  getSize() {
    const bounds = this.getBounds();
    return this.getBoundsSize(bounds);
  }

  // worksites

  setWorksitesData(worksites) {
    const geoJsonData = worksitesToGeoJson(worksites);
    this.worksitesData.setShapes(geoJsonData);
  }

  showWorksites() {
    this.map.layers.add(this.worksitesLayer);
  }

  // conversions

  latLngToCoord(latLng, north) {
    const origin = [this.originLatLng.lng, this.originLatLng.lat];
    const target = [latLng.lng, latLng.lat];
    const distance = atlas.math.getDistanceTo(origin, target);
    const heading = atlas.math.getHeading(origin, target);
    const headingRad = (heading * Math.PI) / 180;
    const x = Math.sin(headingRad) * distance;
    const y = Math.cos(headingRad) * distance;
    const coord = {x: x, y: 0, z: -y};
    if (north) {
      return this.mapCoordToSceneCoord(coord, north);
    } else {
      return coord;
    }
  }

  // offset coordinates between 2 points
  // used to compute offset between a box and a worksite.
  // no need to take into account the rotation.
  latLngOffset(p1, p2) {
    const origin = [p1.lng, p1.lat];
    const target = [p2.lng, p2.lat];
    const distance = atlas.math.getDistanceTo(origin, target);
    const heading = atlas.math.getHeading(origin, target);
    const headingRad = (heading * Math.PI) / 180;
    const x = Math.sin(headingRad) * distance;
    const y = Math.cos(headingRad) * distance;

    return {x: x, y: 0, z: -y};
  }

  coordToLatLng(coord, north) {
    const origin = [this.originLatLng.lng, this.originLatLng.lat];
    let c = {...coord};
    if (north) c = this.sceneCoordToMapCoord(coord, north);
    const {x, y, z} = c;
    const dist = Math.sqrt(x ** 2 + z ** 2);
    const headingRad = Math.atan2(x, -z);
    const heading = (headingRad * 180) / Math.PI;
    const [lng, lat] = atlas.math.getDestination(origin, heading, dist);
    return {lat, lng};
  }

  computeOriginLatLng(coord, latLng, north) {
    const marker = [latLng.lng, latLng.lat];
    const {x, y, z} = this.sceneCoordToMapCoord(coord, north);
    const dist = Math.sqrt(x ** 2 + z ** 2);
    const headingRad = Math.atan2(-x, +z); // x=>-x and z=>-z
    const heading = (headingRad * 180) / Math.PI;
    const [lng, lat] = atlas.math.getDestination(marker, heading, dist);
    return {lat, lng};
  }

  getDestination(latLng, origin, target) {
    const heading = this.getHeadingFromCoord(origin, target);
    const x = target.x - origin.x;
    const z = target.z - origin.z;
    const dist = Math.sqrt(x ** 2 + z ** 2);
    const [lng, lat] = atlas.math.getDestination(
      [latLng.lng, latLng.lat],
      heading,
      dist
    );
    return {lat, lng};
  }
  getHeading(p1, p2) {
    return atlas.math.getHeading([p1.lng, p1.lat], [p2.lng, p2.lat]);
  }

  getHeadingFromCoord(p1, p2) {
    // in three.js coord
    const x = p2.x - p1.x;
    const z = p2.z - p1.z;
    const headingRad = Math.atan2(x, -z);
    return (headingRad * 180) / Math.PI;
  }

  getSceneNorthRotation(p1, p2) {
    const mapAngle = this.getHeading(p1, p2);
    const sceneAngle = this.getHeadingFromCoord(p1, p2);
    return mapAngle - sceneAngle;
  }

  // pointer

  enablePointer() {
    this.map.getCanvasContainer().style.cursor = "crosshair";
  }
  disablePointer() {
    this.map.getCanvasContainer().style.cursor = "grab";
  }

  // pictures

  loadPicturesDataset(pictures) {
    const datasource = new atlas.source.DataSource();
    this.map.sources.add(datasource);
    const symbolLayer = new atlas.layer.SymbolLayer(datasource, null, {
      iconOptions: {
        icon: "marker-red",
        allowOverlap: true,
        ignorePlacement: true,
      },
    });
    this.map.layers.add(symbolLayer);

    pictures.forEach((picture) => {
      if (picture.lat) {
        const pos = [picture.lng, picture.lat];
        datasource.add(
          new atlas.data.Feature(new atlas.data.Point(pos), {
            url: picture.imageUrl,
          })
        );
      }
    });

    this.picturesPopup = new atlas.Popup({
      position: [0, 0],
      pixelOffset: [0, -18],
    });

    const symbolClicked = (e) => {
      //Make sure the event occurred on a point feature.
      if (e.shapes && e.shapes.length > 0) {
        var properties = e.shapes[0].getProperties();
        var pos = e.shapes[0].getCoordinates();

        //Populate the popupTemplate with data from the clicked point feature.
        this.picturesPopup.setOptions({
          //Update the content of the popup with the image.
          content:
            '<div style="padding:10px;"><img style="max-width:300px;" src="' +
            properties.url +
            '"/></div>',

          //Update the position of the popup with the symbols coordinate.
          position: pos,
        });

        //Open the popup.
        this.picturesPopup.open(this.map);

        //Bring the popup into view by centering the map over the symbol then offsetting it lower.
        this.map.setCamera({
          center: pos,
          centerOffset: [0, -150],
        });
      }
    };

    this.map.events.add("click", symbolLayer, symbolClicked);
  }

  // export

  async getScreenshotCanvas() {
    return this.map.getCanvas();
  }

  // utils

  scene2DCoordToMap2DCoord(x, y, north) {
    const angle = -(north * Math.PI) / 180; // x -1 because regular formular is counterclockwise.
    return {
      x: x * Math.cos(angle) - y * Math.sin(angle),
      y: x * Math.sin(angle) + y * Math.cos(angle),
    };
  }
  sceneCoordToMapCoord(point, north) {
    const {x, y} = this.scene2DCoordToMap2DCoord(point.x, -point.z, north);
    return {x, y: point.y, z: -y};
  }

  map2DToScene2D(x, y, north) {
    const angle = -(north * Math.PI) / 180;
    return {
      x: x * Math.cos(angle) + y * Math.sin(angle),
      y: -x * Math.sin(angle) + y * Math.cos(angle),
    };
  }
  mapCoordToSceneCoord(point, north) {
    const {x, y} = this.map2DToScene2D(point.x, -point.z, north);
    return {x, y: point.y, z: -y};
  }

  //
}
