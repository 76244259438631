import useSceneFromStateAndUrl from "Features/scenes/useSceneFromStateAndUrl";
import {useSelector, useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

import getModelLoadingStatusNum from "../utils/getModelLoadingStatusNum";
import {fetchRemoteModel} from "Features/viewer3D/viewer3DSlice";

export default function useFetchSceneModels(options) {
  const dispatch = useDispatch();

  const withoutPackages = options?.withoutPackages;
  const modelIdsInScope = options?.modelIdsInScope;

  // data

  const accessToken = useAccessToken();
  const scene = useSceneFromStateAndUrl();

  const loadingStatusMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  const models = useSelector((s) => s.viewer3D.models);

  // helpers

  const sceneClientId = scene?.clientId;

  let modelsToFetch = models;
  if (withoutPackages)
    modelsToFetch = models.filter((m) => m.type !== "MEASUREMENTS");

  if (Array.isArray(modelIdsInScope)) {
    modelsToFetch = models.filter((m) => modelIdsInScope.includes(m.id));
  }

  const fetchSceneModels = () => {
    if (accessToken && sceneClientId) {
      for (let i = 0; i < modelsToFetch.length; i++) {
        const model = modelsToFetch[i];
        const num = getModelLoadingStatusNum(model.id, loadingStatusMap);
        if (num < 2) {
          console.log("FEEEETCHING ! sceneModel", model.name, model.type);
          dispatch(
            fetchRemoteModel({
              accessToken,
              remoteId: model.remoteId,
              sceneClientId,
            })
          );
        }
      }
    }
  };

  return [fetchSceneModels, accessToken, sceneClientId];
}
