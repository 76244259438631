import CaplaBox from "./CaplaBox";
import CaplaPlaneBasic from "./CaplaPlaneBasic";
import ImageTexture from "./ImageTexture";
import ImageEntity from "./ImageEntity";

import logo from "../assets/logo_capla.png";

export default class SceneLanding {
  constructor({editor}) {
    this.editor = editor;
    this.entities = [];

    this.createEntities();
  }

  createEntities() {
    //const box = new CaplaBox({width: 1, height: 1, depth: 1});
    const plane = new CaplaPlaneBasic({width: 100, height: 100});
    this.entities.push(plane);

    const imageTexture = new ImageTexture({url: logo});
    const imageEntity = new ImageEntity({
      imageTexture,
      model: {width: 24, height: 24},
    });
    imageEntity.loadImage("RR");
    imageEntity.show();
    imageEntity.object.rotation.set(-Math.PI / 2, 0, -Math.PI / 4);
    imageEntity.object.position.set(-1, 0.2, -3);
    this.entities.push(imageEntity);
  }

  enable() {
    console.log("ENABLE_LANDING");
    this.entities.forEach((e) => {
      console.log("adding scene object", e.object);
      this.editor.scene.add(e.object);
    });
  }

  disable() {
    console.log("DISABLE_LANDING");
    this.entities.forEach((e) => this.editor.scene.remove(e.object));
  }
}
