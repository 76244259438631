import markerImage from "../assets/marker_64.png";
import SceneEditor from "./SceneEditor";
import AnnotationsManager from "./AnnotationsManager";
import Saver from "./Saver";
import Loader from "./Loader";

import {getClosestPoints} from "../utils";

import {
  setPdfCurrentPage,
  setPdfViewerStatus,
  setMode,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import {setOpenDialogFsCreateElementType} from "Features/elementTypor/elementTyporSlice";
import {
  setAnnotCreationTool,
  setSelectedMeasurementIds,
  // updateMeasurement,
  setEditedMeasurement,
} from "Features/measurements/measurementsSlice";
import {
  setPanOrSelect,
  setPdfEditorIsLoadingDoc,
  setPdfModelIdLoaded,
  setHasBimData,
  setExtractedText,
  annotationsLoaded,
  setClickedPointerPosition,
} from "Features/pdf/pdfSlice";
import {setCutBeingAdded} from "Features/cuts/cutsSlice";
import {setOpenDialogCreateZone} from "Features/zones/zonesSlice";

export default class PdfEditor {
  webViewer; // instance of PDFTron web viewer
  viewerEl; // viewer dom element.
  caplaEditor; // main capla editor, use to dispatch event...

  throttleTime; // use for throttling mouse events

  onClick; // pass pageCoordinates on Click

  modelId; // modelId of the model being rendered
  zones; // for initial load of the zones once the doc is loaded.

  pointerMode; // true : the pointer is active.
  snapToLine; // "POINT_ON_LINE" snapping mode
  snapIsEnabled; // true/false : snapping is active.
  snapIsReady; // snap callback started to send back one value. Used in callback to compute pointer position.

  initScale; // to init one document scale

  isEditing; // if true, toolGroup = "editing". Annotation added => new temp annotation.
  editedElement; // {codeName,caplaModelId} annotationElement being edited. Changed when editedObject state is modified
  isLoadingDoc; // if true, do not add annotations / measurements...

  rightClickCoordinates; // to add point in the middle of annotation
  selectedAnnotation; // to add / remove point

  colorsAreSeparated; // used to keep annotations only.

  // editingMode; // editing mode when adding annotaiton. "AUTO_MEASURE", "MEASUREMENTS","ZONE","DEFAULT"

  constructor({webViewer, viewerEl, caplaEditor}) {
    this.webViewer = webViewer;
    this.viewerEl = viewerEl;
    this.caplaEditor = caplaEditor;

    this.snapToLine = false; // cf viewer3D slice pdfSnapToLine.
    this.snapIsEnabled = false;
    this.initScale = 50; // 1:50
    this.snapToZones = false; // used to snap to zones (create segment from zones)

    this.editingMode = "DEFAULT";
    this.isLoadingDoc = false;

    this.testBimDataScope = [];

    this.modelId = null;

    this.annotationsManager = new AnnotationsManager({
      webViewer: this.webViewer,
      caplaEditor,
    });

    this.sceneEditor = new SceneEditor({
      webViewer: this.webViewer,
      dispatch: caplaEditor?.dispatch,
      annotationsManager: this.annotationsManager,
    });

    this.saver = new Saver({pdfEditor: this});
    this.loader = new Loader({pdfEditor: this});

    this.init();

    this.addEventListeners();
    this.removeButtons();
    this.enablePanTool();
    this.initToolbarGroup();

    this.handleClickEvent = (e) => {
      this.logPagePositionOnClick(e);
    };

    this.createMarkerTool();
    this.createMeasurementTool();

    this.showContent = true;
    this.updateSelection = true;
  }

  // init
  init() {
    //this.webViewer.UI.enableFeatures([this.webViewer.UI.Feature.MultiTab]);
  }

  // setters

  setZones(zones) {
    this.zones = zones;
  }

  setClickHandler(handler) {
    this.handleClickEvent = handler;
  }

  resetClickHandler() {
    this.setClickHandler(this.logPagePositionOnClick);
  }

  setOnClick(handler) {
    this.onClick = handler;
  }

  setSnapIsEnabled(bool) {
    this.snapIsEnabled = bool;
  }
  setSnapIsReady(bool) {
    this.snapIsReady = bool;
  }

  setSnapMode(bool) {
    this.snapMode = bool;
  }
  setSnapToLine(bool) {
    this.snapToLine = bool;
  }

  setSnapToZones(bool) {
    this.snapToZones = bool;
  }

  setIsEditing(bool) {
    this.isEditing = bool;
  }
  setEditedElement(element) {
    this.editedElement = element;
    this.annotationsManager.editElementAnnotation({
      codeName: element?.codeName,
      caplaModelId: element?.caplaModelId,
    });
  }
  uneditElement(element) {
    this.editedElement = {};
    this.annotationsManager.uneditElementAnnotation({
      codeName: element?.codeName,
      caplaModelId: element?.caplaModelId,
    });
  }
  setColorsAreSeparated(bool) {
    this.colorsAreSeparated = bool;
  }

  setIsLoadingDoc(bool) {
    this.isLoadingDoc = bool;
  }

  // loaders

  load(model, url) {
    // debug
    console.log("pdfLoad1", model);
    // clear previous models
    this.annotationsManager.clearZoneAnnotations();

    // load model
    const {id: modelId, zones, elementsAnnotations, pdfScale} = model;
    this.modelId = modelId;
    this.setZones(zones);
    this.elementsAnnotations = elementsAnnotations;
    // scale
    this.pdfScale = pdfScale ? pdfScale : this.initScale;
    this.annotationsManager.setScale(this.pdfScale);

    console.log("loading a new doc", url, modelId);
    this.caplaEditor?.dispatch(setPdfViewerStatus("loading"));
    if (url) {
      this.url = url;
      this.setIsLoadingDoc(true);
      this.caplaEditor?.dispatch(setPdfEditorIsLoadingDoc(true));
      //this.webViewer.UI.TabManager.addTab(url, {extension: "pdf"});
      this.setColorsAreSeparated(false);
      this.webViewer.UI.loadDocument(url, {extension: "pdf"});
      // this.webViewer.Core.documentViewer.addEventListener(
      //   "documentLoaded",
      //   () => {
      //     this.annotationsManager.loadZoneAnnotations(zones, modelId);
      //   }
      // );
    }
  }

  // add/remove point to annotation

  addPointToAnnotation = async () => {
    const mnger = this.annotationsManager.measurementsPdfManager;
    //
    const {Point} = this.webViewer.Core.Math;
    const {annotationManager} = this.webViewer.Core;
    if (!this.selectedAnnotation?.getPath) return;
    let annotationPath = this.selectedAnnotation?.getPath();
    const path = annotationPath.map((p) => ({x: p.x, y: p.y}));
    const point = this.rightClickCoordinates;
    const {x, y, index, start} = getClosestPoints(path, point);
    const delta = start ? 1 : 0;
    annotationPath.splice(index + delta, 0, new Point(x, y));

    this.selectedAnnotation.adjustRect();
    //console.log("rightClick", x, y);
    annotationManager.redrawAnnotation(this.selectedAnnotation);
    // update
    const measurement = await mnger.annotationToMeasurementFromPdf(
      this.selectedAnnotation,
      "EDITING"
    );
    mnger.setEditedMeasurement(measurement);
    this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
    //this.editor.sceneEditor.updateMeasurement3D(measurement);
    this.caplaEditor?.editor3d?.sceneEditor.updateMeasurement3D(measurement);
  };

  removePointToAnnotation = async () => {
    const mnger = this.annotationsManager.measurementsPdfManager;
    //
    // const {Point} = this.webViewer.Core.Math;
    const {annotationManager} = this.webViewer.Core;
    if (!this.selectedAnnotation?.getPath) return;
    let annotationPath = this.selectedAnnotation?.getPath();
    const path = annotationPath.map((p) => ({x: p.x, y: p.y}));
    const point = this.rightClickCoordinates;
    // const {x, y, index} = getClosestPoints(path, point);
    const {index} = getClosestPoints(path, point);

    annotationPath.splice(index, 1);

    this.selectedAnnotation.adjustRect();
    //console.log("rightClick", x, y);
    annotationManager.redrawAnnotation(this.selectedAnnotation);
    annotationManager.updateAnnotation(this.selectedAnnotation);

    // update
    const measurement = await mnger.annotationToMeasurementFromPdf(
      this.selectedAnnotation,
      "EDITING"
    );
    mnger.setEditedMeasurement(measurement);
    this.caplaEditor?.dispatch(setEditedMeasurement(measurement));
    // this.editor.sceneEditor.updateMeasurement3D(measurement);
    this.caplaEditor?.editor3d?.sceneEditor.updateMeasurement3D(measurement);
    // update
    // if (this.selectedAnnotation?.getCustomData("type") === "MEASUREMENT") {
    //   const measurement =
    //     await this.annotationsManager.measurementsPdfManager.annotationToMeasurementFromPdf(
    //       this.selectedAnnotation,
    //       "EDITING"
    //     );
    //   this.caplaEditor?.dispatch(updateMeasurement(measurement));
    // }
  };

  // copy text

  extractSelectedText = () => {
    const {documentViewer, Math} = this.webViewer.Core;
    const pageNumber = documentViewer.getCurrentPage();
    const selectedText = documentViewer.getSelectedText();
    const selectedTextQuads = documentViewer.getSelectedTextQuads(pageNumber);
    const pageHeight = documentViewer.getPageHeight(pageNumber);
    const pageWidth = documentViewer.getPageWidth(pageNumber);
    const pageRotation = documentViewer.getCompleteRotation(pageNumber);

    let rects = [];
    if (selectedTextQuads)
      rects = selectedTextQuads
        .map((quad) => {
          const {x1, x2, x3, x4, y1, y2, y3, y4} = quad;
          const _quad = new Math.Quad(x1, y1, x2, y2, x3, y3, x4, y4);
          return _quad.toRect();
        })
        .map(({x1, y1, x2, y2}) => ({x1, y1, x2, y2}));
    this.caplaEditor?.dispatch(
      setExtractedText({
        extractedAt: Date.now(),
        text: selectedText,
        rects,
        pageNumber,
        pdfModelId: this.modelId,
        pageRotation,
        pageWidth,
        pageHeight,
      })
    );
  };

  // event listeners

  addEventListeners = () => {
    const {documentViewer, annotationManager, Tools} = this.webViewer.Core;
    const {contextMenuPopup, textPopup} = this.webViewer.UI;

    documentViewer.addEventListener("documentLoaded", async () => {
      console.log("documentLoaded !");
      this.updateSelection = false;
      //
      this.setIsLoadingDoc(false);
      this.caplaEditor?.dispatch(setPdfEditorIsLoadingDoc(false));
      this.caplaEditor?.dispatch(setPdfModelIdLoaded(this.modelId));
      //
      this.annotationsManager.setScale(this.pdfScale);

      // load zones annotation
      this.annotationsManager.loadZoneAnnotations(this.zones, this.modelId);

      // this.annotationsManager.loadElementsAnnotations(
      //   this.elementsAnnotations,
      //   this.modelId
      // );

      this.caplaEditor?.dispatch(setPdfViewerStatus("loaded"));
      this.sceneEditor.createPointer();
      this.sceneEditor.createTempMarker();
      //this.createMarkerTool(); // should not be created here : creation for each new pdf loaded=>bug

      const doc = documentViewer.getDocument();

      // enable color separation
      // doc.enableColorSeparations({checkIfBaseColorsUsed: false});
      // doc.addEventListener("colorSeparationAdded", (colorData) => {
      //   console.log("colorData", colorData?.name);
      // });
      // update rasterizer
      doc.updateRasterizerOptions({
        pageTransparent: true,
        renderAnnots: true,
        caching: false,
      });

      // update selector tool
      this.enablePanTool();
      this.caplaEditor?.dispatch(setPanOrSelect("PAN"));

      // update context menu
      contextMenuPopup.update([
        {
          type: "actionButton",
          img: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>`,
          onClick: this.addPointToAnnotation,
        },
        {
          type: "actionButton",
          img: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M7 21q-.825 0-1.412-.587Q5 19.825 5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413Q17.825 21 17 21ZM17 6H7v13h10ZM9 17h2V8H9Zm4 0h2V8h-2ZM7 6v13Z"/></svg>`,
          onClick: this.removePointToAnnotation,
        },
      ]);

      // update text menu
      textPopup.update([
        {
          type: "actionButton",
          img: `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"/></svg>`,
          onClick: this.extractSelectedText,
        },
      ]);

      this.updateSelection = true;
    });

    documentViewer.addEventListener("annotationsLoaded", async () => {
      // test hasBimData

      this.caplaEditor?.dispatch(annotationsLoaded(this.modelId));
      if (this.annotationsManager.testHasBimData) {
        this.caplaEditor?.dispatch(
          setHasBimData(this.annotationsManager.testHasBimData())
        );
      }

      // load capla models
      const {caplaModels, pdfScale} =
        this.annotationsManager.loadCaplaAnnotation(this.modelId);

      // we update the state here and not in the doc loaded section to avoid conflict with state updates.
      const updatedModel = {
        id: this.modelId,
        pdfScale: pdfScale ? pdfScale : this.pdfScale,
      };
      await this.caplaEditor?.dispatch(
        updateModel({updatedModel, sync: false})
      );
      if (pdfScale) this.annotationsManager.setScale(pdfScale);

      // await this.editor.loader.loadCaplaModelsFromPdf(caplaModels);
      await this.caplaEditor?.editor3d?.loader.loadCaplaModelsFromPdf(
        caplaModels
      );

      // update pdf model
      // /!\ conflict with update from the document loaded handler. pb with scale.
      this.annotationsManager.loadCaplaAnnotationsEntities(this.modelId);
    });

    documentViewer.addEventListener("pageComplete", (pageNumber) => {
      this.handlePageComplete(pageNumber);
    });

    documentViewer.addEventListener("pageNumberUpdated", (pageNumber) => {
      this.dispatchPageChange(pageNumber);
    });

    documentViewer.addEventListener("toolModeUpdated", (tool) => {
      if (tool instanceof Tools.GenericAnnotationCreateTool) {
        Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = false;
      } else {
        Tools.Tool.ENABLE_ANNOTATION_HOVER_CURSORS = true;
      }
    });

    documentViewer.addEventListener("keyDown", (e) => {
      if (e.key === "Escape") {
        this.enablePanTool();
        console.log("escape from pdfEditor");
        this.sceneEditor.stopProcess();
        this.caplaEditor?.dispatch(setAnnotCreationTool(null));
        this.caplaEditor?.dispatch(setSelectedMeasurementIds([]));
        this.setSnapToZones(false);
      }
    });

    documentViewer.addEventListener("mouseRightUp", (e) => {
      const {pageCoordinates} = this.getPageCoordinates(e);
      this.rightClickCoordinates = pageCoordinates;
    });

    documentViewer.addEventListener("click", (e) => {
      const {
        pageCoordinates,
        windowCoordinates,
        pageCoordinatesWithRot,
        pageRotation,
      } = this.getPageCoordinates(e);
      console.log(
        "click",
        pageCoordinates,
        pageCoordinatesWithRot,
        pageRotation,
        windowCoordinates
      );
      // console.time("timing1")
      // this.caplaEditor?.dispatch(
      //   setClickedPointerPosition({x: e.clientX, y: e.clientY})
      // );
      // console.timeEnd("timing1")
      // console.time("timing2")
      // add point measurement annotation
      const mm = this.annotationsManager.measurementsPdfManager;
      const point = {
        x: pageCoordinates.x,
        y: pageCoordinates.y,
        pageNumber: pageCoordinates.pageNumber,
        pageRotation: pageRotation,
      };
      if (mm.measurementCreationMode === "RECTANGLE_FROM_POINT") {
        mm.createRectangleAnnotationFromPoint(point);
      } else if (mm.measurementCreationMode === "CIRCLE_FROM_POINT") {
        mm.createCircleAnnotationFromPoint(point);
      }
      // console.timeEnd("timing2")
      // console.time("timing3")
      this.dispatchPageChange(pageCoordinates.pageNumber);
      // console.timeEnd("timing3")
      // console.time("timing4")
      this.handleClickEvent(e);
      // console.timeEnd("timing4")
    });

    documentViewer.addEventListener("mouseMove", (e) => {
      this.handleMouseMove(e);
    });

    // documentViewer.addEventListener("mouseMove", (e) => {
    //   this.annotationsManager.handleMouseMove(e);
    // });

    annotationManager.addEventListener(
      "annotationChanged",
      this.handleAnnotationChanged
    );

    // annotationManager.addEventListener(
    //   "annotationSelected",
    //   this.handleAnnotationSelected
    // );
  };

  setAnnotationChangedHandler = (handler) => {
    const {annotationManager} = this.webViewer.Core;
    annotationManager.addEventListener(
      "annotationChanged",
      (annotations, action) => handler(annotations, action)
    );
  };

  handleMouseWheel = (e) => {
    if (e.ctrlKey === true) {
      e.stopPropagation();
      e.preventDefault();
      const {documentViewer} = this.webViewer.Core;
      if (this.throttleTime) {
        return;
      } else {
        this.throttleTime = setTimeout(() => {
          // change zoom level base on the direction of the mouse wheel
          if (e.deltaY < 0) {
            //instance.setZoomLevel(instance.getZoomLevel() * 1.2);
            documentViewer.zoomToMouse(
              documentViewer.getZoomLevel() * 1.2,
              0,
              0
            );
          } else {
            //instance.setZoomLevel(instance.getZoomLevel() * 0.9);
            documentViewer.zoomToMouse(
              documentViewer.getZoomLevel() * 0.9,
              0,
              0
            );
          }
          clearTimeout(this.throttleTime);
          this.throttleTime = null;
        }, 5);
      }
    }
  };

  handlePageComplete = (pageNumber) => {
    this.viewerEl.children[0].contentDocument
      .querySelector(".document")
      .addEventListener("wheel", (e) => this.handleMouseWheel(e));

    this.sceneEditor.updateMarkers({page: pageNumber});
    this.sceneEditor.updatePointer();
  };

  // handleAnnotationSelected = (annotations, action) => {
  //   console.time("haaaaaaaaaaaaaaaaa handleAnnotationSelected")
  //   const annotation = annotations[0];

  //   if (action === "selected") {
  //     this.selectedAnnotation = annotation;
  //     this.annotationsManager.handleAnnotationSelected(
  //       this.modelId,
  //       annotation
  //     );
  //   } else {
  //     this.annotationsManager.handleAnnotationUnselected(annotation);
  //   }
  //   console.timeEnd("haaaaaaaaaaaaaaaaa handleAnnotationSelected")
  // };

  handleAnnotationChanged = async (annotations, action, info) => {
    const annotationEntity = this.annotationsManager.getAnnotationEntity(
      annotations[0]
    );
    const annotType = annotations[0].getCustomData("type");

    if (
      action === "add" &&
      this.isEditing &&
      annotationEntity?.type != "ZONE" &&
      ![
        "MEASUREMENT",
        "SECTORS",
        "ZONES",
        "RESSOURCES",
        "PDF_SCALE",
        "ELEMENT_TYPES",
      ].includes(annotType)
    ) {
      this.annotationsManager.handleTempAnnotationAdded({
        modelId: this.modelId,
        annotation: annotations[0],
        caplaModelId: this.editedElement?.caplaModelId,
        codeName: this.editedElement?.codeName,
      });
    }

    if (
      action === "modify" &&
      this.isEditing &&
      annotationEntity?.type != "ZONE" &&
      annotType !== "MEASUREMENT"
    ) {
      this.annotationsManager.handleAnnotationChanged({
        modelId: this.modelId,
        annotation: annotations[0],
      });
    }

    if (
      action === "delete" &&
      this.isEditing &&
      annotationEntity?.type != "ZONE" &&
      annotType !== "MEASUREMENT"
    ) {
      this.annotationsManager.handleAnnotationDeleted({
        modelId: this.modelId,
        annotation: annotations[0],
      });
    }

    if (
      annotationEntity?.type === "ZONE" &&
      action === "add" &&
      this.annotationsManager.drawingZone
    ) {
      this.annotationsManager.dispatchUpdateModel({
        modelId: annotationEntity?.modelId,
      });
    }

    if (annotationEntity?.type === "ZONE" && action === "modify") {
      this.annotationsManager.updateAnnotationEntity(annotations[0]);
      this.annotationsManager.updateImageModelFromZoneAnnotation(
        annotations[0]
      );
      this.annotationsManager.updateImageModelImageFromUpdatedAnnotation(
        annotations[0]
      );
    }

    if (
      annotationEntity?.type === "ZONE" &&
      action === "delete" &&
      this.annotationsManager.isDeletingZone
    ) {
      this.annotationsManager.handleZoneAnnotationDeleted(
        annotationEntity.annotationId
      );
      this.annotationsManager.setIsDeletingZone(false);
    }

    // create elementType from rectangle.
    if (this.annotationsManager.creatingType && action === "add") {
      this.caplaEditor?.dispatch(setOpenDialogFsCreateElementType(true));
      this.annotationsManager.tempAnnotationToCreateType = annotations[0];
      this.annotationsManager.getTempTypeToCreateFromAnnotation(annotations[0]);
      // this.annotationsManager.createZoneFromRectangle({
      //   annotation: annotations[0],
      //   modelId: this.modelId,
      // });
      this.caplaEditor?.dispatch(setMode("DEFAULT"));
      this.activePanTool();
      this.annotationsManager.setCreatingType(false);
    }

    // create zone from rectangle.
    if (this.annotationsManager.drawingZone && action === "add") {
      this.caplaEditor?.dispatch(setOpenDialogCreateZone(true));
      this.annotationsManager.tempAnnotationToCreateZone = annotations[0];
      this.annotationsManager.getTempZoneToCreateFromAnnotation(annotations[0]);
      // this.annotationsManager.createZoneFromRectangle({
      //   annotation: annotations[0],
      //   modelId: this.modelId,
      // });
      this.caplaEditor?.dispatch(setMode("DEFAULT"));
      this.activePanTool();
    }
    if (this.annotationsManager.addingFirstZone && action === "add") {
      this.annotationsManager.createImageModelFromZoneAnnotation(
        annotations[0]
      );
      this.annotationsManager.addingFirstZone = false;
    }

    if (this.annotationsManager.isAddingCut && action === "add") {
      const cut = await this.annotationsManager.getAnnotationCut(
        annotations[0]
      );
      this.caplaEditor?.dispatch(setCutBeingAdded(cut));
      this.annotationsManager.setIsAddingCut(false);
    }
  };

  // event listeners - on mouse move

  handleMouseMove(e) {
    if (this.sceneEditor.pointerMode) {
      const {documentViewer} = this.webViewer.Core;

      const {
        DEFAULT,
        POINT_ON_LINE,
        // LINE_MID_POINT,
        // LINE_INTERSECTION,
        // PATH_ENDPOINT,
      } = documentViewer.SnapMode;

      const snapMode = this.snapToLine ? POINT_ON_LINE : DEFAULT;

      // const {pageCoordinates, windowCoordinates, pageCoordinatesWithRot} =
      //   this.getPageCoordinates(e);
      const {pageCoordinates, pageCoordinatesWithRot} =
        this.getPageCoordinates(e);
      const {pageNumber, x, y} = pageCoordinates;

      const pageRotation = documentViewer.getCompleteRotation(pageNumber);

      const zoom = this.getZoom();
      const width = documentViewer.getPageWidth(pageNumber);
      const limit2 = ((width * 0.01) / zoom) ** 2;
      const distanceSquare = (a, b) => {
        return (a.x - b.x) ** 2 + (a.y - b.y) ** 2;
      };
      // snapping

      // we first control activation
      if (this.snapIsEnabled && !this.snapIsReady) {
        documentViewer.snapToNearest(pageNumber, x, y, snapMode).then(() => {
          this.setSnapIsReady(true);
        });
      }

      if (this.snapToZones) {
        const {center, width} = this.sceneEditor.snapToZoneCorners(x, y);

        const snapPointWithRot = this.getPageCoordinatesWithRot({
          ...center,
          pageNumber,
        });

        this.sceneEditor.setPointerPosition({
          pageCoordinates: snapPointWithRot,
          rawPageCoordinates: center,
          pageNumber,
          pageRotation,
          snapped: true,
          snapMode: "PATH_ENDPOINT",
        });
        this.sceneEditor.updateProcess({
          rawPageCoordinates: center,
          pageRotation,
          pageNumber,
          width,
        });
      } else if (this.snapIsEnabled && this.snapIsReady) {
        documentViewer
          .snapToNearest(pageNumber, x, y, snapMode)
          .then((snapPoint) => {
            this.setSnapIsReady(true);
            const snapPointWithRot = this.getPageCoordinatesWithRot({
              ...snapPoint,
              pageNumber,
            });

            //test
            const dist2 = distanceSquare(snapPoint, pageCoordinates);
            const snapped = dist2 < limit2;
            const point = !snapped ? pageCoordinatesWithRot : snapPointWithRot;
            const rawPoint = !snapped ? pageCoordinates : snapPoint;
            this.sceneEditor.setPointerPosition({
              pageCoordinates: point,
              rawPageCoordinates: rawPoint,
              snapped,
              snapMode: snapPoint.modeName,
              pageNumber,
              pageRotation,
            });
            this.sceneEditor.updateProcess({
              rawPageCoordinates: rawPoint,
              pageRotation,
              pageNumber,
            });
          });
      } else {
        // const {pageCoordinates, windowCoordinates, pageCoordinatesWithRot} =
        //   this.getPageCoordinates(e);
        const {pageCoordinates, pageCoordinatesWithRot} =
          this.getPageCoordinates(e);

        this.sceneEditor.setPointerPosition({
          pageCoordinates: pageCoordinatesWithRot,
          rawPageCoordinates: pageCoordinates,
          pageNumber,
          pageRotation,
        });
        this.sceneEditor.updateProcess({
          rawPageCoordinates: pageCoordinates,
          pageRotation,
          pageNumber,
        });
      }
    }
  }

  // event listeners - onclick

  handleClickEventAddMarker(e) {
    const {pageCoordinates, windowCoordinates, pageCoordinatesWithRot} =
      this.getPageCoordinates(e);

    this.sceneEditor.addMarker({pageCoordinates: pageCoordinatesWithRot});
    const screenP = {x: e.clientX, y: e.clientY};
    if (this.onClick)
      this.onClick({
        pageCoordinates,
        windowCoordinates,
        pageCoordinatesWithRot,
        screenP,
      });
  }

  handleClickEventCreateMarker(e) {
    // const {pageCoordinates, windowCoordinates, pageCoordinatesWithRot} =
    //   this.getPageCoordinates(e);
    const {pageCoordinates, windowCoordinates} = this.getPageCoordinates(e);
    const screenP = {x: e.clientX, y: e.clientY};
    // we need to take into account snapping
    const snappedCoordWithRot = this.sceneEditor.getPointerPosition();
    const snappedCoord = this.sceneEditor.getPointerRawPosition();

    if (pageCoordinates?.x) {
      // pageCoordinates may take time to be generated because of snapping => do not do anything during snapping loading time.
      this.sceneEditor.setTempMarkerPosition({
        //pageCoordinates: pageCoordinatesWithRot, // doesn't take into accound snapping
        pageCoordinates: snappedCoordWithRot,
      });

      if (this.onClick)
        this.onClick({
          pageCoordinates: snappedCoord,
          windowCoordinates,
          pageCoordinatesWithRot: snappedCoordWithRot,
          screenP,
        });
    }
  }

  handleClickEventMoveTempMarker(e) {
    const pageCoordinates = this.sceneEditor.getPointerPosition();
    console.log("moveTempMarker position p12", pageCoordinates);

    if (pageCoordinates?.x) {
      // pageCoordinates may take time to be generated because of snapping => do not do anything during snapping loading time.
      this.sceneEditor.setTempMarkerPosition({pageCoordinates});

      //this.sceneEditor.setCursor("crosshair");

      const {windowCoordinates} = this.getPageCoordinates(e);
      const screenP = {x: e.clientX, y: e.clientY};
      if (this.onClick)
        this.onClick({
          pageCoordinates,
          windowCoordinates,
          screenP,
        });
    }
  }

  addMarkerOnClick() {
    // !not used ??
    console.log("add marker on click");
    this.webViewer.UI.setToolMode(["AnnotationCreateMarker"]);
    // this.viewerEl.children[0].contentDocument.querySelector(
    //   ".document"
    // ).style.cursor = "crosshair";
    // const body = this.webViewer.UI.iframeWindow.document.querySelector("body");
    // body.style.cursor = "crosshair";
    this.setClickHandler(this.handleClickEventAddMarker);
  }

  createMarkerOnClick() {
    //this.webViewer.UI.setToolMode(["AnnotationCreateMarker"]); // to get the crosshair pointer
    this.setClickHandler(this.handleClickEventCreateMarker);
  }

  moveTempMarkerOnClick() {
    // this.viewerEl.children[0].contentDocument.querySelector(
    //   ".document"
    // ).style.cursor = "crosshair";

    this.setClickHandler(this.handleClickEventMoveTempMarker);
  }

  handleClickEventLogPagePosition(e) {
    const {pageCoordinates, windowCoordinates} = this.getPageCoordinates(e);
    if (this.onClick) this.onClick({pageCoordinates, windowCoordinates});
  }

  logPagePositionOnClick() {
    this.setClickHandler(this.handleClickEventLogPagePosition);
  }

  // dispatch

  dispatchPageChange(page = null) {
    if (!page) page = this.getPage();
    this.caplaEditor?.dispatch(setPdfCurrentPage(page));
  }

  // ui

  disablePopup() {
    console.log("[PdfEditor] disable popup");
    this.webViewer.UI.disableElements(["contextMenuPopup"]);
  }

  enablePopup() {
    console.log("[PdfEditor] enable popup");
    this.webViewer.UI.enableElements(["contextMenuPopup"]);
  }

  removeButtons() {
    this.webViewer.UI.disableElements([
      "header",
      "toolsHeader",
      "annotationPopup",
      "measurementOverlay",
      "annotationOverlay",
      "scaleOverlayContainer",
      "toolsOverlay",
    ]);
  }

  showSearchPanel() {
    console.log("showSearchPanel");
    this.webViewer.UI.openElements(["searchPanel"]);
  }
  hideSearchPanel() {
    console.log("hideSearchPanel");
    this.webViewer.UI.closeElements(["searchPanel"]);
  }

  enablePanTool() {
    console.log("enablePanTool");
    // const {documentViewer} = this.webViewer.Core;
    this.webViewer.UI.enableElements(["panToolButton"]);
    this.webViewer.UI.setToolMode(["Pan"]);
    //this.webViewer.UI.setToolMode(["Shapes"]);
    this.caplaEditor?.dispatch(setPanOrSelect("PAN"));
  }

  enableSelectTool() {
    this.webViewer.UI.setToolMode([this.webViewer.Core.Tools.ToolNames.EDIT]);
    this.caplaEditor?.dispatch(setPanOrSelect("SELECT"));
  }

  initToolbarGroup() {
    this.webViewer.UI.setHeaderItems((header) => {
      header.getHeader("toolbarGroup-Shapes").push({
        type: "toolButton",
        toolName: "Pan",
      });
    });
  }

  setLanguage(language) {
    this.webViewer.UI.setLanguage(language);
  }

  activePanTool() {
    console.log("active Pan Tool");
    setTimeout(() => this.webViewer.UI.setToolMode(["Pan"]), 100);
  }

  // coordinates

  getPage() {
    return this.webViewer.Core.documentViewer.getCurrentPage();
  }
  getZoom() {
    return this.webViewer.Core.documentViewer.getZoomLevel();
  }
  getMouseLocation = (e) => {
    const scrollElement =
      this.webViewer.Core.documentViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;

    return {
      x: e.pageX + scrollLeft,
      y: e.pageY + scrollTop,
    };
  };

  getPageCoordinatesWithRot(pageCoordinates) {
    const {documentViewer} = this.webViewer.Core;
    const pageNumber = pageCoordinates.pageNumber;
    const rot = documentViewer.getCompleteRotation(pageNumber);
    const height = documentViewer.getPageHeight(pageNumber);
    const width = documentViewer.getPageWidth(pageNumber);
    let pageCoordinatesWithRot = {...pageCoordinates};
    if (rot === 1) {
      pageCoordinatesWithRot.x = height - pageCoordinates.y;
      pageCoordinatesWithRot.y = pageCoordinates.x;
    } else if (rot === 3) {
      pageCoordinatesWithRot.x = pageCoordinates.y;
      pageCoordinatesWithRot.y = width - pageCoordinates.x;
    } else if (rot === 2) {
      pageCoordinatesWithRot.x = width - pageCoordinates.x;
      pageCoordinatesWithRot.y = height - pageCoordinates.y;
    }
    return pageCoordinatesWithRot;
  }

  getPointWithRotFromRawPoint(point, pageNumber) {
    const {documentViewer} = this.webViewer.Core;
    const rot = documentViewer.getCompleteRotation(pageNumber);
    const height = documentViewer.getPageHeight(pageNumber);
    const width = documentViewer.getPageWidth(pageNumber);

    // coordinates with rotation.
    let pointWithRot = {...point};
    if (rot === 1) {
      pointWithRot.x = height - point.y;
      pointWithRot.y = point.x;
    } else if (rot === 3) {
      pointWithRot.x = point.y;
      pointWithRot.y = width - point.x;
    } else if (rot === 2) {
      pointWithRot.x = width - point.x;
      pointWithRot.y = height - point.y;
    }

    return pointWithRot;
  }

  getPageCoordinates = (e) => {
    // window coordinates & clicked page.
    const windowCoord = this.getMouseLocation(e);
    const {documentViewer} = this.webViewer.Core;
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();

    const page = displayMode.getSelectedPages(windowCoord, windowCoord);
    const clickedPage =
      page.first !== null ? page.first : documentViewer.getCurrentPage();

    // rotation & dim
    const rot = documentViewer.getCompleteRotation(clickedPage);
    const height = documentViewer.getPageHeight(clickedPage);
    const width = documentViewer.getPageWidth(clickedPage);

    // windowToPage computation, which is not always consistent
    const pageCoordinates = displayMode.windowToPage(windowCoord, clickedPage);

    // coordinates with rotation.
    let pageCoordinatesWithRot = {...pageCoordinates};
    if (rot === 1) {
      pageCoordinatesWithRot.x = height - pageCoordinates.y;
      pageCoordinatesWithRot.y = pageCoordinates.x;
    } else if (rot === 3) {
      pageCoordinatesWithRot.x = pageCoordinates.y;
      pageCoordinatesWithRot.y = width - pageCoordinates.x;
    } else if (rot === 2) {
      pageCoordinatesWithRot.x = width - pageCoordinates.x;
      pageCoordinatesWithRot.y = height - pageCoordinates.y;
    }

    return {
      pageCoordinates,
      windowCoordinates: windowCoord,
      pageCoordinatesWithRot,
      pageRotation: rot,
    };
  };

  getPdfContext() {
    const docViewer = this.webViewer.Core.documentViewer;
    const coords = this.getViewportPageCoordinates();
    const pageNumber = docViewer.getCurrentPage();
    return {
      modelId: this.modelId,
      pageNumber,
      zoom: docViewer.getZoomLevel(),
      pageRotation: docViewer.getCompleteRotation(pageNumber),
      viewportCoords: {
        x1: coords.topLeft.x,
        y1: coords.topLeft.y,
        x2: coords.bottomRight.x,
        y2: coords.bottomRight.y,
      },
    };
  }

  setPdfContext(context) {
    if (context && !this.isLoadingDoc && this.modelId) {
      // const {pageNumber, zoom, pageRotation, viewportCoords} = context;
      const {pageNumber, viewportCoords} = context;
      const {annotationManager, documentViewer} = this.webViewer.Core;
      const annot = this.annotationsManager.createContextAnnotation(context);
      try {
        annotationManager.jumpToAnnotation(annot, {fitToView: true});

        // window top left
        const {x1, y1, x2, y2} = viewportCoords;
        const displayMode = documentViewer
          .getDisplayModeManager()
          .getDisplayMode();
        const windowPointTL = displayMode.pageToWindow(
          {x: x1, y: y1},
          pageNumber
        );
        const windowPointBR = displayMode.pageToWindow(
          {x: x2, y: y2},
          pageNumber
        );
        // scroll
        const elt = documentViewer.getScrollViewElement();
        const {scrollLeft, scrollTop} = elt;

        // clean
        console.log("delete25");
        annotationManager.deleteAnnotation(annot);

        return {windowPointTL, windowPointBR, scrollLeft, scrollTop};
      } catch (error) {
        console.log("Error: prevented faulty setPdfContext");
      }
    }
  }

  getViewportPageCoordinates() {
    const documentContainer =
      this.webViewer.Core.documentViewer.getScrollViewElement();

    const displayMode = this.webViewer.Core.documentViewer
      .getDisplayModeManager()
      .getDisplayMode();

    const currentPage = this.webViewer.Core.documentViewer.getCurrentPage();

    const topLeft = {
      x: documentContainer.offsetLeft + documentContainer.scrollLeft,
      y: documentContainer.offsetTop + documentContainer.scrollTop,
    };
    const botRight = {
      x:
        documentContainer.offsetLeft +
        documentContainer.scrollLeft +
        documentContainer.offsetWidth,
      y:
        documentContainer.offsetTop +
        documentContainer.scrollTop +
        documentContainer.offsetHeight,
    };

    const topLeftPageCoordinate = displayMode.windowToPage(
      topLeft,
      currentPage
    );
    const bottomRightPageCoordinate = displayMode.windowToPage(
      botRight,
      currentPage
    );

    return {
      topLeft: topLeftPageCoordinate,
      bottomRight: bottomRightPageCoordinate,
    };
  }

  // image

  getImageOfPageAsync = async () => {
    return new Promise(async (resolve) => {
      const docViewer = this.webViewer.Core.documentViewer;
      const currentPage = docViewer.getCurrentPage();
      const pageInfo = docViewer.getDocument().getPageInfo(currentPage);

      const loadCanvasOptions = {
        pageNumber: currentPage,
        drawComplete: async (canvas) => {
          let _url = canvas.toDataURL();
          return resolve({
            url: _url,
            width: pageInfo.width,
            height: pageInfo.height,
            pageNumber: currentPage,
          });
        },
      };
      docViewer.getDocument().loadCanvas(loadCanvasOptions);
    });
  };

  loadViewportImageAsyncWithoutAnnotations = async () => {
    return new Promise(async (resolve) => {
      const docViewer = this.webViewer.Core.documentViewer;
      const context = await this.getPdfContext();
      const {pageNumber, pageRotation, viewportCoords, zoom} = context;
      //
      const pageInfo = docViewer.getDocument().getPageInfo(pageNumber);
      //
      const pageHeight = docViewer.getPageHeight(pageNumber);
      const pageWidth = docViewer.getPageWidth(pageNumber);
      const rotation = docViewer.getCompleteRotation(pageNumber);
      //
      const x = viewportCoords.x1;
      const y = viewportCoords.y1;
      const width = viewportCoords.x2 - viewportCoords.x1;
      const height = viewportCoords.y2 - viewportCoords.y1;
      //
      let x1 = x;
      let y1 = y;
      let x2 = x1 + width;
      let y2 = y1 + height;

      let renderRect = {x1, y1, x2, y2};
      if (rotation === 1) {
        renderRect = {
          x1: pageHeight - renderRect.y2,
          y1: renderRect.x1,
          x2: pageHeight - renderRect.y1,
          y2: renderRect.x2,
        };
      } else if (rotation === 3) {
        renderRect = {
          x2: renderRect.y2,
          y2: pageWidth - renderRect.x1,
          x1: renderRect.y1,
          y1: pageWidth - renderRect.x2,
        };
      }

      const loadCanvasOptions = {
        pageNumber,
        renderRect,
        zoom,
        drawComplete: async (canvas) => {
          let _url = canvas.toDataURL();
          const imageData = canvas
            .getContext("2d")
            .getImageData(0, 0, canvas.width, canvas.height);
          return resolve({
            url: _url,
            imageData,
            width,
            height,
            pageNumber,
            x,
            y,
            zoom,
          });
        },
      };
      docViewer.getDocument().loadCanvas(loadCanvasOptions);
    });
  };

  loadViewportImageAsync = async () => {
    return new Promise(async (resolve) => {
      const docViewer = this.webViewer.Core.documentViewer;
      const annotationManager = this.webViewer.Core.annotationManager;
      const doc = await docViewer.getDocument();
      // const list = annotationManager.getAnnotationsList();

      const coordinates = this.getViewportPageCoordinates();
      const currentPage = docViewer.getCurrentPage();
      const zoom = docViewer.getZoomLevel();
      const rotation = docViewer.getCompleteRotation(currentPage);
      const viewingRotation = await docViewer.getRotation(currentPage);
      let coreRotation = rotation - viewingRotation;
      if (coreRotation < 0) coreRotation = coreRotation + 4;

      console.log("completeRotation", rotation, "rotation", viewingRotation);

      const height = docViewer.getPageHeight(currentPage);
      const width = docViewer.getPageWidth(currentPage);
      // coordinates of topleft screen, bottomright screen, in rotated page coordinates
      let renderRect = {
        x1: coordinates.topLeft.x,
        y1: coordinates.topLeft.y,
        x2: coordinates.bottomRight.x,
        y2: coordinates.bottomRight.y,
      };

      // rotation = 1
      let renderRect1 = {
        x1: height - renderRect.y1,
        y1: renderRect.x1,
        x2: height - renderRect.y2,
        y2: renderRect.x2,
      };

      // rotation = 2
      let renderRect2 = {
        x1: width - renderRect.x2,
        y1: height - renderRect.y2,
        x2: width - renderRect.x1,
        y2: height - renderRect.y1,
      };

      // rotation = 3
      let renderRect3 = {
        x1: renderRect.y2,
        y1: width - renderRect.x2,
        x2: renderRect.y1,
        y2: width - renderRect.x1,
      };

      // // if we rotate the page before the image capture.
      // // previous coordinates once the corrected rotation is applied.
      // // for rotation = 1
      // const renderRect3 = {
      //   x1: !rotation ? renderRect.x1 : renderRect.x1,
      //   y1: !rotation ? renderRect.y1 : renderRect.y2,
      //   x2: !rotation ? renderRect.x2 : renderRect.x2,
      //   y2: !rotation ? renderRect.y2 : renderRect.y1,
      // };

      // rotate the page before taking the canvas
      // if (rotation) {
      //   await doc.rotatePages([currentPage], 4 - rotation);
      // }

      let renderRectBefore = renderRect;
      if (coreRotation === 1) renderRectBefore = renderRect1;
      if (coreRotation === 2) renderRectBefore = renderRect2;
      if (coreRotation === 3) renderRectBefore = renderRect3;

      // then, second transformation after viewer rotation

      // viewing rotation = 1
      // renderRect1 = {
      //   x1: height - renderRectBefore.x2,
      //   y1: renderRectBefore.y2,
      //   x2: height - renderRectBefore.x1,
      //   y2: renderRectBefore.y1,

      // };

      renderRect1 = {
        x1: renderRectBefore.x1,
        y1: renderRectBefore.y2,
        x2: renderRectBefore.x2,
        y2: renderRectBefore.y1,
      };

      // viewing rotation = 2
      renderRect2 = {
        x1: renderRectBefore.x2,
        y1: renderRectBefore.y2,
        x2: renderRectBefore.x1,
        y2: renderRectBefore.y1,
      };

      // viewing rotation = 3
      renderRect3 = {
        x1: renderRectBefore.x2,
        y1: renderRectBefore.y1,
        x2: renderRectBefore.x1,
        y2: renderRectBefore.y2,
      };

      renderRect = renderRectBefore;
      if (viewingRotation === 1) renderRect = renderRect1;
      if (viewingRotation === 2) renderRect = renderRect2;
      if (viewingRotation === 3) renderRect = renderRect3;

      const loadCanvasOptions = {
        pageNumber: currentPage,
        pageRotation: viewingRotation,
        drawComplete: async (canvas) => {
          if (rotation !== 0) {
            const corePageRotation =
              (doc.getPageRotation(currentPage) / 90) % 4;
            const zoom = docViewer.getPageZoom(currentPage);
            annotationManager.setAnnotationCanvasTransform(
              canvas.getContext("2d"),
              zoom,
              corePageRotation
            );
          }

          if (rotation === 0) {
            await docViewer.getAnnotationManager().drawAnnotations({
              pageNumber: currentPage,
              overrideCanvas: canvas,
            });
          }

          // reset rotation
          // if (rotation) {
          //   await doc.rotatePages([currentPage], rotation);
          // }

          let _url = canvas.toDataURL();
          //const _url = defaultImage;
          //const url = await rotateImageUrl(_url, rotation); // comment if without annotation . no need if we include the pageRotation parameter
          return resolve(_url);
          //return resolve(url);
        },
        zoom,
      };
      //if (rotation === 0) loadCanvasOptions.renderRect = renderRect;
      loadCanvasOptions.renderRect = renderRect;

      docViewer.getDocument().loadCanvas(loadCanvasOptions);
    });
  };

  // marker

  createMarkerTool() {
    const {documentViewer, Annotations, Tools} = this.webViewer.Core;

    class MarkerAnnotation extends Annotations.CustomAnnotation {
      constructor() {
        super("marker");
        this.Subject = "Marker";
      }
    }
    MarkerAnnotation.prototype.elementName = "marker";

    class CreateMarkerTool extends Tools.GenericAnnotationCreateTool {
      constructor(docViewer) {
        super(docViewer, MarkerAnnotation);
        this.cursor = "crosshair";
      }
    }

    const toolName = "AnnotationCreateMarker";
    const createMarkerTool = new CreateMarkerTool(documentViewer);

    const markerToolButton = {
      type: "toolButton",
      toolName,
    };

    this.webViewer.UI.registerTool({
      toolName,
      toolObject: createMarkerTool,
      buttonImage: markerImage,
      buttonName: "AnnotationCreateMarkerButton",
      tooltip: "Add a marker",
      cursor: "crosshair",
    });

    this.webViewer.UI.setHeaderItems((header) => {
      header.getHeader("toolbarGroup-Annotate").push(markerToolButton);
    });
  }

  createMeasurementTool() {
    const {documentViewer, Annotations, Tools} = this.webViewer.Core;

    class MeasurementAnnotation extends Annotations.CustomAnnotation {
      constructor() {
        super("measurement");
        this.Subject = "Measurement";
      }
    }
    MeasurementAnnotation.prototype.elementName = "measurement";

    class CreateMeasurementTool extends Tools.GenericAnnotationCreateTool {
      constructor(docViewer) {
        super(docViewer, MeasurementAnnotation);
        this.cursor = "crosshair";
      }
    }

    const toolName = "AnnotationCreateMeasurement";
    const createMeasurementTool = new CreateMeasurementTool(documentViewer);

    const measurementToolButton = {
      type: "toolButton",
      toolName,
    };

    this.webViewer.UI.registerTool({
      toolName,
      toolObject: createMeasurementTool,
      buttonImage: markerImage,
      buttonName: "AnnotationCreateMeasurementButton",
      tooltip: "Add a measurement",
      cursor: "crosshair",
    });

    this.webViewer.UI.setHeaderItems((header) => {
      header.getHeader("toolbarGroup-Annotate").push(measurementToolButton);
    });
  }

  triggerCreateRectangle() {
    this.webViewer.UI.setToolMode([
      this.webViewer.Core.Tools.ToolNames.RECTANGLE,
    ]);
    if (this.annotationsManager.creatingZone)
      this.annotationsManager.startDrawingZone();
  }

  triggerDrawPolyline() {
    this.webViewer.UI.setToolMode([
      this.webViewer.Core.Tools.ToolNames.POLYGON,
    ]);
  }

  triggerCreateMarker() {
    this.webViewer.UI.setToolMode(["AnnotationCreateMarker"]);
  }

  triggerCreateMeasurement() {
    this.webViewer.UI.setToolMode(["AnnotationCreateMeasurement"]);
    this.sceneEditor.startProcessMeasure();
  }

  showToolbarGroupMeasure() {
    this.webViewer.UI.setToolbarGroup("toolbarGroup-Measure");
    this.webViewer.UI.enableElements(["toolsHeader"]);
  }

  hideToolbarGroupMeasure() {
    this.webViewer.UI.disableElements(["toolsHeader"]);
  }

  showToolbarGroupToDraw() {
    // menuTool

    // const menuTool = {
    //   dataElement: "menuButton",
    //   element: "menuOverlay",
    //   hidden: ["small-mobile"],
    //   img: "icon-header-settings-line",
    //   title: "component.menuOverlay",
    //   type: "toggleElementButton",
    // };
    // tools
    this.webViewer.UI.setToolbarGroup("toolbarGroup-Measure");
    this.webViewer.UI.enableElements(["toolsHeader"]);
    this.webViewer.UI.setHeaderItems((header) => {
      header
        .getHeader("toolbarGroup-Measure")
        .delete([
          "arcToolGroupButton",
          "distanceToolGroupButton",
          "ellipseAreaToolGroupButton",
          "rectangleAreaToolGroupButton",
          "cloudyRectangleAreaToolGroupButton",
          "countToolGroupButton",
          "areaToolGroupButton",
          "perimeterToolGroupButton",
          "undoButton",
          "redoButton",
          "eraserToolButton",
        ]);

      //header.getHeader("toolbarGroup-Measure").getItems().push(menuTool);
    });

    this.webViewer.UI.setHeaderItems((header) => {
      const items = header.getItems();
      console.log("items", items);
    });

    this.webViewer.UI.setToolMode(["Pan"]);
  }
  hideToolbarGroupToDraw() {
    this.webViewer.UI.disableElements(["toolsHeader"]);
  }

  setToolbarGroupMeasureScale(scale) {
    const {documentViewer} = this.webViewer.Core;
    // tools
    const tools = [
      "AnnotationCreateDistanceMeasurement",
      "AnnotationCreatePerimeterMeasurement",
      "AnnotationCreatePerimeterMeasurement2",
      "AnnotationCreatePerimeterMeasurement3",
      "AnnotationCreatePerimeterMeasurement4",
      "AnnotationCreateAreaMeasurement",
      "AnnotationCreateAreaMeasurement2",
      "AnnotationCreateAreaMeasurement3",
      "AnnotationCreateAreaMeasurement4",
    ];
    tools.forEach((tool) => {
      documentViewer.getTool(tool).setStyles({
        Scale: [
          [100, "cm"],
          [scale, "m"],
        ],
        Precision: 0.001,
      });
    });
  }

  // init measure & drawing tool

  initToolbarToDraw({drawingType, color, strokeM}) {
    // init (when no selection)
    if (!drawingType) this.webViewer.UI.setToolMode(["Pan"]);

    const isPoint = drawingType === "POINT";
    const isPolyline = drawingType === "POLYLINE";
    const isPolygon = drawingType === "POLYGON";

    const pointItem = {
      dataElement: "countToolGroupButton",
      title: "annotation.count",
      toolGroup: "countTools",
      type: "toolGroupButton",
    };

    const polylineItem = {
      dataElement: "perimeterToolGroupButton",
      title: "annotation.perimeter",
      toolGroup: "perimeterTools",
      type: "toolGroupButton",
    };

    const polygonItem = {
      dataElement: "areaToolGroupButton",
      title: "annotation.area",
      toolGroup: "areaTools",
      type: "toolGroupButton",
    };

    const rectItem = {
      dataElement: "rectangleAreaToolGroupButton",
      title: "annotation.rectangleArea",
      toolGroup: "rectangleAreaTools",
      type: "toolGroupButton",
    };

    this.webViewer.UI.setHeaderItems((header) => {
      const items = header.getHeader("toolbarGroup-Annotation").getItems();
      console.log("items", items);
      header
        .getHeader("toolbarGroup-Annotation")
        .delete([
          "arcToolGroupButton",
          "distanceToolGroupButton",
          "ellipseAreaToolGroupButton",
          "rectangleAreaToolGroupButton",
          "cloudyRectangleAreaToolGroupButton",
          "countToolGroupButton",
          "areaToolGroupButton",
          "perimeterToolGroupButton",
          "undoButton",
          "redoButton",
          "eraserToolButton",
        ]);
      if (isPoint)
        header
          .getHeader("toolbarGroup-Annotation")
          .getItems()
          .splice(1, 0, pointItem);
      if (isPolyline)
        header
          .getHeader("toolbarGroup-Annotation")
          .getItems()
          .splice(1, 0, polylineItem);
      if (isPolygon)
        header
          .getHeader("toolbarGroup-Annotation")
          .getItems()
          .splice(1, 0, rectItem, polygonItem);

      // update here the styles (inside the callback)
      if (isPoint) this.webViewer.UI.setToolMode(["AnnotationCreateCount"]);
      //this.webViewer.UI.setToolMode(["AnnotationCreateCountMeasurement"]);
      if (isPolyline)
        this.webViewer.UI.setToolMode(["AnnotationCreatePerimeter"]);
      //this.webViewer.UI.setToolMode(["AnnotationCreatePerimeterMeasurement"]);
      if (isPolygon) this.webViewer.UI.setToolMode(["AnnotationCreateArea"]);
      //this.webViewer.UI.setToolMode(["AnnotationCreateAreaMeasurement"]);

      // colors & stroke

      if (drawingType)
        this.annotationsManager.setMeasurementStyles({
          color,
          drawingType,
          strokeM,
        });
    });
  }

  // export

  download(fileName, xfdf) {
    // before export
    //this.annotationsManager.tempAnnotationsChangeForExport();

    let filename = fileName;
    filename = filename.replace(".pdf", "");

    this.annotationsManager.clearZoneAnnotations();
    // export
    const options = {
      filename,
    };
    if (xfdf) {
      options.xfdfString = xfdf;
    } else {
      options.includeAnnotations = true;
    }
    this.webViewer.UI.downloadPdf(options);

    // after export
    // this.annotationsManager.updateAnnotationsAfterExport();
  }

  // visibility

  toggleShowContent() {
    const {documentViewer} = this.webViewer.Core;
    const doc = documentViewer.getDocument();

    doc.enableSeparation("Yellow", !this.showContent);
    doc.enableSeparation("Magenta", !this.showContent);
    doc.enableSeparation("Cyan", !this.showContent);
    doc.enableSeparation("Black", !this.showContent);
    documentViewer.refreshAll();
    documentViewer.updateView();

    this.showContent = !this.showContent;

    // enable color separation

    //doc.enableColorSeparations({checkIfBaseColorsUsed: false});

    doc.addEventListener("colorSeparationAdded", (colorData) => {
      console.log("colorData", colorData?.name);
    });
    console.log("enableColorSeparation");
  }

  // rotation

  rotatePageClockwise() {
    const {documentViewer} = this.webViewer.Core;
    const pageNumber = documentViewer.getCurrentPage();
    documentViewer.rotateClockwise(pageNumber);
    const rot = documentViewer.getCompleteRotation(pageNumber);
    console.log("rotate page", pageNumber, rot);
  }

  // zoom

  refreshZoom() {
    console.log("refreshZoom");
    const FitMode = this.webViewer.UI.FitMode;
    this.webViewer.UI.setFitMode(FitMode.FitWidth);
  }

  // create image in the pdf

  async addImageInPdfAsync({imageUrl, x, y, width, height, pageNumber}) {
    try {
      const {documentViewer, PDFNet} = this.webViewer.Core;
      const doc = documentViewer.getDocument();
      const pdfDoc = await doc.getPDFDoc();

      await pdfDoc.requirePage(1);
      await PDFNet.runWithCleanup(async () => await main(pdfDoc));
      documentViewer.refreshAll();
      documentViewer.updateView();

      async function main(pdfDoc) {
        pdfDoc.initSecurityHandler();
        pdfDoc.lock();
        const page = await pdfDoc.getPage(pageNumber);

        // create a new page builder that allows us to create new page elements
        const builder = await PDFNet.ElementBuilder.create();

        // create a new page writer that allows us to add/change page elements
        const writer = await PDFNet.ElementWriter.create();

        writer.beginOnPage(page, PDFNet.ElementWriter.WriteMode.e_overlay);
        const image = await PDFNet.Image.createFromURL(pdfDoc, imageUrl);
        const element = await builder.createImageScaled(
          image,
          x,
          y,
          width,
          height
        );
        writer.writePlacedElement(element);
        writer.end();
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  // old pdf and differences

  async getOldPdfImage() {
    const context = await this.getPdfContext();
    const {pageNumber, pageRotation, viewportCoords, zoom} = context;
    const x = viewportCoords.x1;
    const y = viewportCoords.y1;
    const width = viewportCoords.x2 - viewportCoords.x1;
    const height = viewportCoords.y2 - viewportCoords.y1;

    //
    const {url, imageData} =
      await this.caplaEditor.editorPdfInstance2.getPdfImageAsync({
        pageNumber,
        x,
        y,
        width,
        height,
        zoom,
      });
    //
    // await this.annotationsManager.updateOldPdfAnnotation({
    //   imageUrl: url,
    //   x,
    //   y,
    //   width,
    //   height,
    //   pageNumber,
    // });
    return {url, imageData, x, y, width, height, pageNumber};
  }

  // imageLegend

  async addImageAnnotation(imageData, positionInPdf) {
    try {
      //
      const imageUrl = imageData?.imageUrl;
      const imageSize = imageData?.imageSize;
      const x = positionInPdf.x ? Number(positionInPdf.x) : 50;
      const y = positionInPdf.y ? Number(positionInPdf.x) : 50;
      const dim = positionInPdf.dim ? Number(positionInPdf.dim) : 240;
      //
      const {documentViewer, annotationManager} = this.webViewer.Core;
      //
      const pageNumber = documentViewer.getCurrentPage();
      //
      const width = imageSize?.width ? imageSize.width : 100;
      const height = imageSize?.height ? imageSize.height : 80;
      const ratio = width / height;

      // console.log("legendAnnotation", pageNumber);
      const legendAnnotation =
        await this.annotationsManager.createImageAnnotationAsync({
          imageUrl,
          x,
          y,
          width: dim,
          height: dim / ratio,
          pageNumber,
        });

      annotationManager.addAnnotation(legendAnnotation);
      annotationManager.redrawAnnotation(legendAnnotation);
    } catch (e) {
      console.log("error", e);
    }
  }

  // layers

  async getLayers() {
    const {documentViewer} = this.webViewer.Core;
    const doc = documentViewer.getDocument();
    const layers = await doc.getLayersArray();
    return layers;
  }

  // reader

  async processElements(reader, PDFNet) {
    const elements = [];
    // Traverse the page display list
    for (
      let element = await reader.next();
      element !== null;
      element = await reader.next()
    ) {
      const elementType = await element.getType();

      switch (elementType) {
        case PDFNet.Element.Type.e_path: {
          if (element.isClippingPath()) {
            const pathData = await element.getPathData();
            //const textData = await element?.getTextData();
            elements.push({id: element.id, pathData});
          }
          // ...
          break;
        }
        case PDFNet.Element.Type.e_text: {
          const textMtx = await element.getTextMatrix();
          const textData = await element.getTextData();
          elements.push({id: element.id, textData, textMtx});
          // ...
          break;
        }
      }
    }

    return elements;
  }

  async readElements() {
    const allElements = {};
    const {PDFNet} = this.webViewer.Core;
    const doc = await PDFNet.PDFDoc.createFromURL(this.url);
    await PDFNet.initialize();
    const reader = await PDFNet.ElementReader.create();
    const itr = await doc.getPageIterator();
    for (itr; await itr.hasNext(); itr.next()) {
      // Read the page
      const page = await itr.current();
      console.log("page", page);
      reader.beginOnPage(page);
      await this.processElements(reader, PDFNet);
      reader.end();
    }
  }
}
