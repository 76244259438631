import {Vector3, Triangle} from "three";

export default function getGableMeasurementQuantities({path3D, heights, dim1}) {
  if (path3D.length % 2 !== 0) path3D = path3D.slice(0, -1);
  const pathLength = path3D.length;
  const midLength = pathLength / 2;

  let pointsZ = heights ? heights.split(";").map((f) => parseFloat(f)) : [0];
  const zLength = pointsZ.length;
  if (zLength < midLength)
    pointsZ = [
      ...pointsZ,
      ...new Array(midLength - zLength).fill(pointsZ[zLength - 1]),
    ];

  let area1 = 0;
  let area2 = 0;
  let length1 = 0;
  let length2 = 0;
  for (let i = 1; i < midLength; i++) {
    const point11 = path3D[i - 1];
    const point12 = path3D[i];
    const point21 = path3D[pathLength - i];
    const point22 = path3D[pathLength - 1 - i];
    const a1 = new Vector3(point11[0], 0, point11[1]);
    const b1 = new Vector3(point11[0], pointsZ[i - 1], point11[1]);
    const c1 = new Vector3(point12[0], pointsZ[i], point12[1]);
    const d1 = new Vector3(point12[0], 0, point12[1]);
    const t11 = new Triangle(a1, b1, c1);
    const t12 = new Triangle(c1, d1, a1);
    length1 += Math.abs(a1.distanceTo(d1));
    area1 += t11.getArea() + t12.getArea();
    const a2 = new Vector3(point21[0], 0, point21[1]);
    const b2 = new Vector3(point21[0], pointsZ[i - 1], point21[1]);
    const c2 = new Vector3(point22[0], pointsZ[i], point22[1]);
    const d2 = new Vector3(point22[0], 0, point22[1]);
    const t21 = new Triangle(a2, b2, c2);
    const t22 = new Triangle(c2, d2, a2);
    length2 += Math.abs(a2.distanceTo(d2));
    area2 += t21.getArea() + t22.getArea();
  }
  const length = (length1 + length2) * 0.5;
  const area = (area1 + area2) * 0.5;
  const volume = area * Math.abs(dim1);
  return {length, area, volume, count: 1};
}