import {useSelector, useDispatch} from "react-redux";

import {
  Card,
  CardContent,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import {setModelsColor} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";

export default function CardSettingsColors({editor3d}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("viewer3D");
  // strings

  const titleString = t("viewer3D:settings.colors.title");
  // const initialColorString = t("viewer3D:settings.colors.initialColor");
  const caplaColorString = t("viewer3D:settings.colors.caplaColor");
  const transparentColorString = t("viewer3D:settings.colors.transparentColor");
  const radioLabelString = t("viewer3D:settings.colors.radioLabel");

  // data

  const modelsColor = useSelector((state) => state.viewer3D.modelsColor);

  // handlers

  function handleChange(e) {
    dispatch(setModelsColor(e.target.value));
    editor3d?.switchToColor(e.target.value);
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="body2" sx={{mb: 2}}>
          <b>{titleString}</b>
        </Typography>

        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant="body2">{radioLabelString}</Typography>
          </FormLabel>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={modelsColor}
            onChange={handleChange}
          >
            {/* <FormControlLabel
              value="INITIAL"
              control={<Radio size="small" />}
              label={
                <Typography variant="body2">{initialColorString}</Typography>
              }
            /> */}
            <FormControlLabel
              value="CAPLA"
              control={<Radio size="small" />}
              label={
                <Typography variant="body2">{caplaColorString}</Typography>
              }
            />
            <FormControlLabel
              value="TRANSPARENT"
              control={<Radio size="small" />}
              label={
                <Typography variant="body2">
                  {transparentColorString}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
}
