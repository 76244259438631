import {useDispatch} from "react-redux";

import {Box, Switch, Typography, FormControlLabel} from "@mui/material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ButtonSwitchArchived({model}) {
  const dispatch = useDispatch();

  // strings

  const archivedS = "Archiver";

  // helpers

  let archived = model?.archived;
  if (archived === undefined) archived = false;

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    const updatedModel = {...model, archived: checked};

    dispatch(updateModel({updatedModel}));
  }

  return (
    <FormControlLabel
      control={
        <Switch size="small" checked={archived} onChange={handleCheckChange} />
      }
      label={<Typography variant="body2">{archivedS}</Typography>}
    />
  );
}
