import {Box, Divider} from "@mui/material";

import LogikutchReportEnhancer from "./LogikutchReportEnhancer/LogikutchReportEnhancer";
import PolyvalIO from "./PolyvalIO/PolyvalIO";
import ShangriLA from "./customExcels/ShangriLA";

export default function SectionTools() {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      height: 1,
      width: 1,
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        borderLeft: "1px solid grey",
        height: 1
      }}>
        <LogikutchReportEnhancer />
      </Box>
      <Divider />
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        borderLeft: "1px solid grey",
        height: 1
      }}>
        <PolyvalIO />
      </Box>
      <Divider />
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        borderLeft: "1px solid grey",
        height: 1
      }}>
        <ShangriLA />
      </Box>
    </Box>
  );
}
