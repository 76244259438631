import React from "react";

import {useSelector} from "react-redux";

import {Box, Autocomplete, Typography, TextField} from "@mui/material";

export default function SelectorWorksiteMarker({marker, onChange, worksite}) {
  // strings

  const markerString = "Worksite Marker";

  // data

  let markers = worksite?.data?.markers ? worksite.data.markers : [];

  // handler

  function handleMarkerChange(m) {
    onChange(m);
  }

  return (
    <Autocomplete
      fullWidth
      options={markers}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Typography {...props} variant="body2" sx={{pl: 1}}>
          {option.name}
        </Typography>
      )}
      value={marker}
      onChange={(e, v) => handleMarkerChange(v)}
      //input={input}
      //onInputChange={(e, v) => setInput(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={<Typography variant="body2">{markerString}</Typography>}
          InputProps={{
            ...params.InputProps,
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
        />
      )}
    />
  );
}
