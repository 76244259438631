import {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import ToolbarElementTypesMain from "Features/elementTypes/components/ToolbarElementTypesMain";
import DataGridElementTypes from "Features/elementTypes/components/DataGridElementTypes";
import SectionDataGridToolbar from "Features/elementTypes/components/SectionDataGridToolbar";
import SectionTitleElementTypes from "Features/elementTypes/components/SectionTitleElementTypes";
import SectionElementTypesGroupProxy from "Features/elementTypes/components/SectionElementTypesGroupProxy";
import Stretch from "Features/ui/components/Stretch";
import {fetchElementTypesGroups} from "Features/elementTypes/elementTypesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import ToolbarEditDatagridElementTypesGroup from "Features/elementTypes/components/ToolbarEditDatagridElementTypesGroup";

export default function SceneElementTypes() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // params

  const {caplaEditor, scene} = useOutletContext();

  // data

  const os = useSelector((s) => s.ui.openSections);
  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  // load initial data

  // useEffect(() => {
  //   if (accessToken && scene?.id) {
  //     dispatch(fetchElementTypesGroups({accessToken, sceneId: scene?.id}));
  //   }
  // }, [accessToken, scene?.id]);

  // helpers - openSections

  const openSections = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(openSections).join("-");

  // helper - show edit toolbar

  const showEditDatagridToolbar = !selectedGroupProxy?.fromScene;

  if (os.outlet)
    return (
      <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
        <Box sx={{height: "68px"}} />
        <SectionTitleElementTypes />
        <SectionElementTypesGroupProxy
          scene={scene}
          caplaEditor={caplaEditor}
        />
        <ToolbarElementTypesMain scene={scene} />
        <SectionDataGridToolbar editor3d={caplaEditor?.editor3d} />
        {showEditDatagridToolbar && <ToolbarEditDatagridElementTypesGroup />}
        <Stretch propDependency={osHash}>
          <Box sx={{height: 1, width: 1}}>
            <DataGridElementTypes scene={scene} />
          </Box>
        </Stretch>
      </Box>
    );
  return <></>;
}
