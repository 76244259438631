/*
 * material : {id,num,code,name}
 */

import {useDispatch} from "react-redux";

import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneMaterial(scene) {
  const dispatch = useDispatch();

  const updateSceneMaterial = (material) => {
    if (!scene) return;
    let oldMaterials = scene?.data?.materials.filter((s) => s?.id !== material.id);
    if (!oldMaterials) oldMaterials = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, materials: [...oldMaterials, material]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneMaterial;
}
