import articleTypesArrayToMap from "./articleTypesArrayToMap";
import articleTypesMapToArray from "./articleTypesMapToArray";

export default function updateArticleWithArticleTypesNum(
  article,
  elementTypesByGroupIdById
) {
  let newArticle = {...article};
  if (newArticle.types) {
    const typesArray = articleTypesMapToArray(newArticle.types);
    const newTypesArray = typesArray.map((item) => {
      if (item.groupId && item.typeId) {
        const elementType =
          elementTypesByGroupIdById[item.groupId]?.[item.typeId];
        if (elementType) {
          return {
            ...item,
            typeNum: elementType.num,
            typeName: elementType.name,
          };
        }
      }
      return item;
    });
    newArticle = {
      ...newArticle,
      types: articleTypesArrayToMap(newTypesArray),
    };
  }
  // coreTypes (idem)
  if (newArticle.coreTypes) {
    const typesArray = articleTypesMapToArray(newArticle.coreTypes);
    const newTypesArray = typesArray.map((item) => {
      if (item.groupId && item.typeId) {
        const elementType =
          elementTypesByGroupIdById[item.groupId]?.[item.typeId];
        if (elementType) {
          return {
            ...item,
            typeNum: elementType.num,
            typeName: elementType.name,
          };
        }
      }
      return item;
    });
    newArticle = {
      ...newArticle,
      coreTypes: articleTypesArrayToMap(newTypesArray),
    };
  }
  return newArticle;
}
