import sortByNum from "./sortByNum";

export default function ressourcesForDataGrid(ressources, options) {
  // options
  const sortRessourcesByNum = options?.sortRessourcesByNum;

  // helpers
  function getRank(num) {
    return num ? num.split(".").length : null;
  }

  const ranks = [
    ...new Set(ressources.map((r) => getRank(r.num)).filter((rank) => rank)),
  ];
  const rankMin = Math.min(...ranks);

  let result = ressources.map((ressource) => ({
    ...ressource,
    rank: getRank(ressource.num) - rankMin + 1,
    types: ressource.isTitle ? "" : ressource.types?.length,
    __reorder__: ressource.name,
    dec: ressource?.isTitle ? null : ressource.dec,
  }));

  // options
  if (sortRessourcesByNum) result.sort((a, b) => sortByNum(a.num, b.num));

  return result;
}
