import {Box, Typography, TextField, Paper} from "@mui/material";

export default function SectionTempPdf({
  tempFile,
  tempPdfName,
  onTempPdfNameChange,
}) {
  // strings

  const pdfNameS = "Nom d'usage";

  // helpers

  const fileName = tempFile?.name;

  // handlers

  function handleChange(e) {
    onTempPdfNameChange(e.target.value);
  }

  return (
    <Box sx={{width: 1, p: 1}}>
      <Paper sx={{p: 2, width: 1}} elevation={0}>
        <Typography variant="body2" color="text.secondary" noWrap>
          {fileName}
        </Typography>

        <TextField
          sx={{mt: 2}}
          label={pdfNameS}
          fullWidth
          size="small"
          value={tempPdfName}
          onChange={handleChange}
        />
      </Paper>
    </Box>
  );
}
