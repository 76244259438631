import {client} from "API/capla360";

export async function createListingService({accessToken, listing, scene}) {
  const response = await client.post(`/listings/`, listing, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {listing: response.data, scene};
}

export async function createListingServiceV1({accessToken, userId, listing}) {
  const response = await client.post(`/users/${userId}/listings/`, listing, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function fetchSceneListingsService({accessToken, sceneId}) {
  const response = await client.get(`scenes/${sceneId}/listings/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {listings: response.data, sceneId};
}

export async function addListingMembershipService({
  accessToken,
  listingId,
  membership,
}) {
  // membership : {role,email}
  const response = await client.post(
    `listings/${listingId}/memberships/`,
    membership,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}
