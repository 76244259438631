import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";
import {updateRessourcesGroup} from "Features/ressources/ressourcesSlice";
import useRessourcesGroupsProxyByScene from "./useRessourcesGroupsProxy";
import useAccessToken from "Features/auth/useAccessToken";

export default function useRenameSceneRessourcesGroup(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const groupsProxy = useRessourcesGroupsProxyByScene(scene);

  const renameSceneRessourcesGroup = async (
    oldRessourcesGroup,
    newRessourcesGroup
  ) => {
    // first check if group is remote or fromScene

    const proxy = groupsProxy.find((g) => g.name === oldRessourcesGroup);

    // case : fromScene

    if (proxy.fromScene) {
      if (!scene) return;
      const sceneRessources = scene?.data?.ressources
        ? scene.data.ressources
        : [];
      let oldRessources = sceneRessources.filter(
        (r) => r?.group !== oldRessourcesGroup
      );
      if (!oldRessources) oldRessources = [];
      let newRessources = sceneRessources
        .filter((r) => r?.group === oldRessourcesGroup)
        .map((r) => ({...r, group: newRessourcesGroup}));
      if (!newRessources) newRessources = [];
      const newScene = {
        ...scene,
        data: {
          ...scene?.data,
          ressources: [...oldRessources, ...newRessources],
        },
      };
      dispatch(updateScene({scene: newScene}));
    } else {
      const updates = {
        id: proxy.id,
        name: newRessourcesGroup,
        sceneId: scene?.id,
      };
      await dispatch(
        updateRessourcesGroup({ressourcesGroup: updates, accessToken})
      );
    }
  };

  return renameSceneRessourcesGroup;
}
