import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Paper, IconButton, alpha} from "@mui/material";
import {
  Delete,
  CenterFocusStrong as Focus,
} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";
import {updateModelsVisibility} from "Features/viewer3D/viewer3DSlice";
import FileSelectorButton from "Features/files/components/FileSelectorButton";
import ImageBasic from "Features/images/components/ImageBasic";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import { setUseBufferInShortcut, setZonesInBuffer } from "Features/zones/zonesSlice";

export default function FieldIssueImageInSelectionPanel({
  imageUrl,
  onChange,
  isEditing,
  fromViewMode,
  context,
  listingId,
  // issue,
  editor3d,
  scene,
  fullHeight = false,
}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const selectImageS = "Select an image";

  // data

  const zones = useZonesByScene(scene);
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.enabled
  );

  // state

  const [expand, _] = useState(false);
  const [tempImageUrl, setTempImageUrl] = useState(imageUrl);
  useEffect(() => {
    setTempImageUrl(imageUrl);
  }, [imageUrl]);

  // helpers

  const imageModels = models.filter((m) => m.type === "IMAGE");

  // handlers

  async function handleImageChange(file) {
    if (file) {
      const id = nanoid();
      const fileName = `issue-image-${id}.png`;
      const newFile = new File([file], fileName);
      const remoteUrl = await uploadFileService({
        listingId,
        accessToken,
        file: newFile,
        container: "listing-files",
      });
      setTempImageUrl(remoteUrl);
      if (onChange) onChange(remoteUrl);
    }
  }

  function handleDeleteImage() {
    setTempImageUrl(null);
  }

  function handleFocus() {
    if (fromViewMode === "2D" || fromViewMode === "3D") {
      // camera
      const camera = context?.camera;
      if (camera) editor3d?.sceneEditor.updateCamera(camera);
      // models
      const sceneContext = context?.sceneContext;
      if (sceneContext) {
        const zonesForBuffer = sceneContext.imageModelIds.map((mid) => (
          zones.find((z) => z.imageModelId === mid)
        )).filter((z) => z);
        dispatch(setZonesInBuffer(zonesForBuffer));
        editor3d?.sceneEditor.hideAllImageModels();
        editor3d?.sceneEditor.applyContext(sceneContext);
        dispatch(setUseBufferInShortcut(true));
      }
      const ids = context?.sceneContext?.visibleModelsIds;
      if (ids) {
        const modelsVisibility = {};
        imageModels.forEach((model) => {
          modelsVisibility[model.id] = ids.includes(model.id) ? false : true;
        });
        dispatch(updateModelsVisibility(modelsVisibility));
      }
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        width: 1,
        position: "relative",
        minHeight: 100,
        ...(fullHeight && {height: "100%"}),
      }}
    >
      {tempImageUrl && isEditing && (
        <IconButton
          sx={{position: "absolute", top: 0, left: 0, zIndex: 2}}
          size="small"
          onClick={handleDeleteImage}
        >
          <Delete fontSize="small" />
        </IconButton>
      )}
      {!isEditing && (
        <Box
          sx={{
            color: "primary.flash",
            bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 3,
          }}
        >
          <IconButton size="small" color="inherit" onClick={handleFocus}>
            <Focus fontSize="small" color="inherit" />
          </IconButton>
        </Box>
      )}
      {isEditing && (
        <Box
          sx={{
            position: "absolute",
            bottom: "24px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 2,
          }}
        >
          <FileSelectorButton
            buttonName={selectImageS}
            accept={"image/png, image/jpeg"}
            onFileChange={handleImageChange}
            buttonVariant="contained"
          />
        </Box>
      )}
      {tempImageUrl && (
        <Box sx={{height: fullHeight ? "100%" : expand ? 400 : 150}}>
          <ImageBasic url={tempImageUrl} />
        </Box>
      )}
    </Paper>
  );
}
