import sortByNum from "./sortByNum";

export default function phasesForDataGrid(phases) {
  function getRank(num) {
    return num ? num.split(".").length : null;
  }

  const ranks = [
    ...new Set(phases.map((r) => getRank(r.num)).filter((rank) => rank)),
  ];
  const rankMin = Math.min(...ranks);

  return phases
    .map((phase) => ({
      ...phase,
      rank: getRank(phase.num) - rankMin + 1,
    }))
    .sort((a, b) => sortByNum(a.num, b.num));
}
