import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Paper,
} from "@mui/material";
import {PanTool, HighlightAlt as SelectTool} from "@mui/icons-material";

import {setPanOrSelect} from "Features/pdf/pdfSlice";

export default function TogglePanSelect({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const panString = "Pan";
  const selectString = "Select";

  // data

  const tool = useSelector((s) => s.pdf.panOrSelect);

  // handlers

  function handleToolChange(value) {
    if (value) {
      dispatch(setPanOrSelect(value));
      if (value === "PAN") editorPdf?.enablePanTool();
      if (value === "SELECT") editorPdf?.enableSelectTool();
    }
  }

  return (
    <Paper elevation={12}>
      <ToggleButtonGroup
        exclusive
        //disabled={!showViewer}
        value={tool}
        onChange={(e, v) => handleToolChange(v)}
        size="small"
        sx={{bgcolor: "common.caplaBlack", color: "common.white", p: 0.5}}
      >
        <ToggleButton
          value="PAN"
          sx={{
            height: "30px",
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Tooltip title={panString}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "common.white",
              }}
            >
              <PanTool color="inherit" fontSize="small" />
            </Box>
          </Tooltip>
        </ToggleButton>

        <ToggleButton
          value="SELECT"
          sx={{
            height: "30px",
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Tooltip title={selectString}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "common.white",
              }}
            >
              <SelectTool color="inherit" fontSize="small" />
            </Box>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Paper>
  );
}
