import {useState} from "react";

import {Box, Button, Typography, Paper, IconButton} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {useSelector} from "react-redux";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";

import ChipDefaultLabel from "./ChipDefaultLabel";
import useSceneDefaultScopeId from "../hooks/useSceneDefaultScopeId";
import ContainerPackagesCount from "Features/elementPackages/components/ContainerPackagesCount";
import ContainerEntityCount from "Features/overviewer/components/ContainerEntityCount";
import getFixedScope from "../utils/getFixedScope";
import getModelsInScope from "../utils/getModelsInScope";

export default function ContainerScope({
  scene,
  scope,
  onDeleteClick,
  onEditClick,
  onOpenClick,
  onSeeDetailClick,
  variant = "grid", // "selector"
  hideEditActions = false,
  hideOpenButton = false,
  showDefault = false,
}) {
  // strings

  const deleteS = "Supprimer";
  const editS = "Modifier";
  const openS = "Ouvrir";
  const seeS = "Voir le détail";

  // state

  const [hover, setHover] = useState(false);

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const fixedScope = getFixedScope(scope, models);
  const modelsInScope = getModelsInScope(models, scope, {useFixedScope: true});
  const packagesMeasCount = modelsInScope
    .filter((m) => m.type === "MEASUREMENTS")
    .map((m) => m.measCount);

  let measCount = packagesMeasCount.reduce((acc, cur) => acc + cur, 0);
  let packsCount = fixedScope?.data?.packageIds?.length;
  let inputsCount = fixedScope?.data?.inputIds?.length;
  let zonesCount = fixedScope?.data?.zoneIds?.length;

  if (!measCount) measCount = "-";
  if (!packsCount) packsCount = "-";
  if (!inputsCount) inputsCount = "-";
  if (!zonesCount) zonesCount = "-";

  // helpers - color

  const bgcolor =
    variant === "grid"
      ? theme.palette.common.caplaBlack
      : theme.palette.common.caplaBlack;
  const color = variant === "grid" ? "common.white" : "common.white";

  const bgcolorFlash = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;
  // helper

  const scopeId = scope?.id;

  // handlers

  function handleOpenClick(e, scope) {
    e.stopPropagation();
    e.preventDefault();
    if (onOpenClick) onOpenClick(scope);
  }

  function handleSeeDetailClick(e, scope) {
    e.stopPropagation();
    onSeeDetailClick(scope);
  }

  function handleMouseEnter() {
    setHover(true);
  }
  function handleMouseLeave() {
    setHover(false);
  }

  return (
    <Box
      sx={{width: 350, p: 1, minWidth: 350}}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Paper
        sx={{
          p: 1,
          height: 170,
          display: "flex",
          flexDirection: "column",
          color: color,
          bgcolor: lighten(bgcolor, scope?.id === "all" ? 0.05 : 0.1),
          cursor: variant === "grid" ? "pointer" : "normal",
          ":hover": {
            ...(variant === "grid" && {background: bgcolorFlash}),
          },
        }}
        elevation={6}
        onClick={(e) => {
          if (variant === "grid") handleOpenClick(e, scope);
        }}
      >
        <Box
          sx={{width: 1, height: 1, display: "flex", flexDirection: "column"}}
        >
          <Box sx={{flexGrow: 1}}>
            <Box sx={{display: "flex", flexDirection: "column"}}>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "space-between",
                }}
              >
                <Typography>{scope?.name}</Typography>
                {showDefault && (
                  <Box
                    sx={{minWidth: 0, display: "flex", alignItems: "center"}}
                  >
                    <ChipDefaultLabel scene={scene} scopeId={scopeId} />
                  </Box>
                )}
              </Box>
              <Typography
                variant="body2"
                //sx={{whiteSpace: "pre"}}
              >
                {scope?.description}
              </Typography>
            </Box>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Box sx={{display: "flex", mt: 1, "&>*:not(:last-child)": {mr: 1}}}>
              <ContainerEntityCount count={inputsCount} type="INPUTS" />
              <ContainerEntityCount count={zonesCount} type="ZONES" />
              <ContainerEntityCount count={packsCount} type="PACKAGES" />
              <ContainerEntityCount count={measCount} type="MEASUREMENTS" />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: 1,
                mt: 1,
                color: "text.secondary",
              }}
            >
              <Box sx={{color}}>
                {variant === "selector" && !hideOpenButton && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "primary.flash",
                      borderRadius: "4px",
                    }}
                  >
                    <Button
                      color="inherit"
                      size="small"
                      onClick={(e) => handleOpenClick(e, scope)}
                    >
                      {openS}
                    </Button>
                  </Box>
                )}
                {/* {variant === "grid" && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={(e) => handleSeeDetailClick(e, scope)}
                  >
                    {seeS}
                  </Button>
                )} */}
              </Box>
              {variant === "selector" && !hideEditActions && (
                <Box sx={{display: "flex", alignItems: "center", color}}>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => onDeleteClick(scope)}
                  >
                    {deleteS}
                  </Button>
                  <Button
                    color="inherit"
                    sx={{ml: 1}}
                    size="small"
                    onClick={() => onEditClick(scope)}
                  >
                    {editS}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
