import {useState, useEffect} from "react";

import {useSelector} from "react-redux";

import {nanoid} from "@reduxjs/toolkit";

import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyByScene";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

export default function DialogElementTypesGroupToProxies({
  open,
  onClose,
  scene,
}) {
  // strings

  const dialogTitleString = "Convertir en types proxy";
  const saveS = "Convertir";
  const cancelS = "Annuler";
  const help1S =
    "Sélectionner le groupe cible dans lequel les types seront copiés";
  const help2S =
    "Le groupe cible sera écrasé et remplacé par les nouveaux types à chaque conversion";

  // data

  const updateElementTypesGroup = useUpdateElementTypesGroup();
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const allTypes = useElementTypesBySceneProxy(scene);
  const selectedGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  // helpers

  let types = allTypes.filter(
    (t) =>
      (t.groupId && t.groupId === selectedGroup?.id) ||
      (!t.groupId && t.group === selectedGroup?.name)
  );

  // local state

  const [group, setGroup] = useState(null);

  // handlers

  const handleChange = (g) => {
    setGroup(g);
  };

  async function handleSave() {
    const newTypes = types?.map((t) => {
      const newType = {
        ...t,
        id: nanoid(),
        drawingShape: "RECTANGLE",
      };
      if (newType.res) delete newType.res;
      return newType;
    });
    await updateElementTypesGroup({...group, elementTypes: newTypes});
    onClose();
  }

  // effect

  useEffect(() => {
    if (!group && groups && groups.length > 0) setGroup(groups[0]);
  }, [groups, group]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitleString} </DialogTitle>
      <DialogContent sx={{maxWidth: 300}}>
        <Typography variant="body2" sx={{pb: 2}}>
          {help1S}
        </Typography>
        <SelectorAutocomplete
          value={group}
          options={groups}
          onChange={handleChange}
        />
        <Typography variant="body2" sx={{pt: 2}}>
          {help2S}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelS}</Button>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
