import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createBimappService,
  fetchBimappsService,
  updateBimappService,
  deleteBimappService,
} from "./services";

export const createBimapp = createAsyncThunk(
  "bimapps/createBimapp",
  createBimappService
);
export const updateBimapp = createAsyncThunk(
  "bimapps/updateBimapp",
  updateBimappService
);
export const deleteBimapp = createAsyncThunk(
  "bimapps/deleteBimapp",
  deleteBimappService
);

export const fetchBimapps = createAsyncThunk(
  "bimapps/fetchBimapps",
  fetchBimappsService
);

export const bimappsSlice = createSlice({
  name: "bimapps",
  initialState: {
    items: [],

    selectedBimappId: null,

    isEditingBimapp: false,
    editedBimapp: {},
    tempBimapp: {},
  },
  reducers: {
    setSelectedBimappId: (state, action) => {
      state.selectedBimappId = action.payload;
    },
    setIsEditingBimapp: (state, action) => {
      state.isEditingBimapp = action.payload;
    },
    setEditedBimapp: (state, action) => {
      state.editedBimapp = action.payload;
    },
    setTempBimapp: (state, action) => {
      state.tempBimapp = action.payload;
    },
  },
  extraReducers: {
    [createBimapp.fulfilled]: (state, action) => {
      const {item} = action.payload;
      console.log("[STATE] create bimapp", item);
      state.items = [...state.items, item];
    },
    [updateBimapp.fulfilled]: (state, action) => {
      const {item} = action.payload;
      const newItems = state.items.filter((i) => i.id !== item.id);
      state.items = [...newItems, item];
    },
    [deleteBimapp.fulfilled]: (state, action) => {
      const bimappId = action.payload;
      const newItems = state.items.filter((i) => i.id !== bimappId);
      state.items = [...newItems];
    },
    [fetchBimapps.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
  },
});

export const {
  setSelectedBimappId,
  setIsEditingBimapp,
  setEditedBimapp,
  setTempBimapp,
} = bimappsSlice.actions;

export default bimappsSlice.reducer;
