import articleTypesMapToArray from "./articleTypesMapToArray";
import articleTypesArrayToMap from "./articleTypesArrayToMap";
import removeGlobalFiltersStrFromArticleTypes from "./removeGlobalFiltersSrFromArticleTypes";

function cleanFiltersStr(filtersStr) {
  //
  if (!Array.isArray(filtersStr)) return null;
  //
  let clean = filtersStr.filter((filter) => filter?.length > 0);
  //
  if (!clean?.length > 0) {
    return null;
  } else {
    return clean;
  }
}

export default function cleanArticleTypesBasic(
  articleTypes,
  elementTypesByGroupIdById,
  options
) {
  //
  const removeTitles = options?.removeTitles;
  const removeGlobalFiltersStr = options?.removeGlobalFiltersStr;
  //
  let typesArray = articleTypesMapToArray(articleTypes);
  //
  if (removeTitles) {
    typesArray = typesArray.filter((type) => {
      return !elementTypesByGroupIdById[type.groupId]?.[type.typeId]?.isTitle;
    });
  }
  //
  const cleanTypesArray = typesArray.filter(
    ({groupId, typeId, func, filtersStr}, index) => {
      let isClean = true;
      if (!groupId && !typeId && !cleanFiltersStr(filtersStr)) {
        isClean = false;
      } else if (groupId && !typeId && !cleanFiltersStr(filtersStr) && !func) {
        isClean = false;
      } else if (groupId && typeId && !func && !cleanFiltersStr(filtersStr)) {
        isClean = false;
      }
      return isClean;
    }
  );
  //
  let cleanTypes = articleTypesArrayToMap(cleanTypesArray);
  //
  if (removeGlobalFiltersStr)
    cleanTypes = removeGlobalFiltersStrFromArticleTypes(cleanTypes);
  //
  return cleanTypes;
}
