import {useState} from "react";

import {
  Box,
  Menu,
  MenuList,
  Divider,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {ArrowDropDown} from "@mui/icons-material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";

import ButtonReachVersionsModule from "./ButtonReachVersionsModule";
import ChipVersion from "./ChipVersion";

export default function SelectorVersion({
  selectedVersion,
  onChange,
  versions,
  noVersionLabel,
  viewer,
}) {
  // string

  const allVersionsS = noVersionLabel ? noVersionLabel : "Toutes les versions";
  const allVersionsLabel = "Versions";

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  let currentVersion = {
    id: "all",
    num: "0.0",
    name: allVersionsLabel,
    color: theme.palette.background.default,
  };
  if (selectedVersion?.id) currentVersion = selectedVersion;

  // helpers

  const noSelectedVersion = currentVersion.id === "all";

  // helpers - bgcolor

  let bgcolor = lighten(theme.palette.common.caplaBlack, 0.2);
  if (currentVersion?.id && currentVersion?.id !== "all")
    bgcolor = currentVersion.color
      ? currentVersion.color
      : theme.palette.common.yellow;

  // helpers - version label

  const count = versions?.length;
  let versionLabel = "versions";
  if (count === 0) versionLabel = "Versions";
  if (count === 1) versionLabel = "1 version";
  if (count > 1) versionLabel = `${count} versions`;

  if (!noSelectedVersion) versionLabel = `v.${currentVersion?.num}`;

  // handlers

  function handleOpenMenu(e) {
    setAnchorEl(e.currentTarget);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(version) {
    onChange(version);
    setAnchorEl(null);
  }

  return (
    <Box>
      <Tooltip title={currentVersion?.name}>
        <Box
          onClick={handleOpenMenu}
          sx={{
            cursor: "pointer",
            px: 1,
            //py: 0.5,
            bgcolor,
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            color: !noSelectedVersion ? "default" : "common.white",
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              fontStyle: noSelectedVersion ? "italic" : "normal",
            }}
          >
            {versionLabel}
          </Typography>
          {<ArrowDropDown fontSize="small" color="action" />}
        </Box>
      </Tooltip>

      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        <MenuList>
          {!viewer && (
            <MenuItem
              selected={currentVersion?.id === "0"}
              onClick={() => handleChange(null)}
            >
              <Typography variant="body2" sx={{fontStyle: "italic"}}>
                {allVersionsS}
              </Typography>
            </MenuItem>
          )}
          {versions.length > 0 && <Divider />}
          {versions.map((version, index) => {
            const selected = version.id === currentVersion?.id;
            return (
              <MenuItem
                key={version.id}
                onClick={() => handleChange(version)}
                selected={selected}
              >
                <Box sx={{display: "flex", alignItems: "center"}}>
                  <ChipVersion {...version} selected />
                  <Typography
                    variant="body2"
                    sx={{ml: 1}}
                  >{`${version.name}`}</Typography>
                </Box>
              </MenuItem>
            );
          })}
          {!viewer && (
            <Box>
              <Divider sx={{my: 1}} />
              <ButtonReachVersionsModule
                onClick={() => {
                  setAnchorEl(null);
                }}
              />
            </Box>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
}
