import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";

import {deleteRoomsGroup} from "../roomsSlice";

export default function useDeleteRoomsGroup() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteGroup = async (group) => {
    console.log("debugAFA useDeleteRoomsGroup", group);
    if (accessToken && group) {
      await dispatch(deleteRoomsGroup({roomsGroup: group, accessToken}));
    }
  };

  return deleteGroup;
}
