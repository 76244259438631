import {useDispatch} from "react-redux";

import {Typography, Card, CardContent} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {addGltfUrl} from "Features/viewer3D/viewer3DSlice";

export default function CardGLTF({model, editor3d}) {
  const dispatch = useDispatch();

  // strings

  const exportString = "GLTF optim";
  const optimizedString = "The model was optimized";

  // helpers

  const isOptimized = Boolean(model.fileGltfClientId);

  // handlers

  async function handleOptimize() {
    const fileGltf = await editor3d?.gltfExporter.createGltfFile(model);
    console.log("fileGltf", fileGltf);
    dispatch(addGltfUrl({model, fileGltf}));
  }

  return (
    <Card sx={{width: 1}}>
      <CardContent>
        {isOptimized && <Typography>{optimizedString}</Typography>}
        <LoadingButton onClick={handleOptimize}>{exportString}</LoadingButton>
      </CardContent>
    </Card>
  );
}
