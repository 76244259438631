import {
  Scene,
  // AmbientLight,
  // DirectionalLight,
  WebGLRenderer,
  // Vector2,
  Vector3,
  Raycaster,
  // MOUSE,
  Color,
  Clock,
  PCFSoftShadowMap,
} from "three";

// import {
//   acceleratedRaycast,
//   computeBoundsTree,
//   disposeBoundsTree,
// } from "three-mesh-bvh";

import {CSS2DRenderer} from "three/examples/jsm/renderers/CSS2DRenderer.js";
//import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader.js";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader.js";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader.js";

// import {IFCLoader} from "web-ifc-three/IFCLoader";
//import {IFCLoader} from "three/examples/jsm/loaders/IFCLoader";

import getIntersection from "../utils/getIntersection";
import {fitCameraToObject} from "../utils/cameraUtils";

import SceneLanding from "./SceneLanding";
import Marker from "./Marker";
import Grid from "./Grid";
import ConfigCenter from "./ConfigCenter";
import Measure from "./Measure";
import OrbitCenter from "./OrbitCenter";
import Cameras from "./Cameras";
import Pointer from "./Pointer";
import Vector from "./Vector";
import FaceDebug from "./FaceDebug";
// import PolygonImage from "./PolygonImage";
// import LoadingBox from "./LoadingBox";
import Flash from "./Flash";
import Animator from "./Animator";
import FlashDarkMode from "./FlashDarkMode";
import StandardMode from "./StandardMode";
import Controls from "./Controls";
import ImageOverlay from "./ImageOverlay";
import Clipper from "./Clipper";
import Multiviews from "./Multiviews";
import Camera2Helper from "./Camera2Helper";
import MapEditor from "./MapEditor";
import SceneEditor from "./SceneEditor";
import ScenesManager from "./ScenesManager";
import GLTFExporter from "./GLTFExporter";
// import IfcExport from "./IfcExport";
import Syncer from "./Syncer";
import Ombb from "./Ombb";
import Drawer3DObjects from "./Drawer3DObjects";
import GeomEngine from "./GeomEngine";

import {
  setOrbiting,
  setPanning,
  setRendererSize,
  setRendererOffset,
  setClickedObject,
  setPointerPosition,
} from "Features/viewer3D/viewer3DSlice";
import {
  setSelectedMeasurementIds,
  // updateSelectedMeasurementIds,
  // setSelectedMeasurementId,
} from "Features/measurements/measurementsSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";

// const blueprint =
//   "https://capla360staging.blob.core.windows.net/debug/blueprint.png";

class Editor {
  camera;
  renderer;
  controls;
  clipper;
  models;
  pickedEntityExpressID;
  selectedEntity;
  canvasSize;
  domElement;
  isLoaded;

  clickHandlersDisabled; // used when clicking on CSS2D objects

  constructor({
    dispatch,
    onEditorClick,
    onSelectionChange,
    onPositionClicked, // trigger when the new marker has been positioned.
    onEscape, // trigger when Esc is pressed
    onSaveConfigEntity, // trigger when one entity is being saved from the config center
    onModelChange,
    onCameraChange,
    onCam2HeightChange,
    main = true, // main editor. (always true, useful only if several editors for one scene)
    isOrtho = false, // use orthoCamera
    // models,
    // markers,
    // webWorker,
    snappingMode,
    // multiviews,
    caplaEditor,
  }) {
    this.isLoaded = true; // used to test if the object exist (to add pdfEditor)

    this.dispatch = dispatch; // used to dispatch event to redux store.

    this.clock = new Clock();
    this.main = main; // main : left editor.
    this.isOrtho = isOrtho;

    this.loader = undefined; // model loader. changed in Viewer.
    this.loading = false; // true if models are being loaded in the scene.

    this.positioning = false; // true if the user is about to click to position an object.
    this.dragging = false; // true if the user is dragging (orbit controls).
    this.pointerdown = false; // true if the pointer is down.
    this.startDragging = false; // true only when dragging start.

    this.scene = new Scene();
    this.transparentBackground = true;

    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      shadowMap: {enabled: true, type: PCFSoftShadowMap},
    });
    this.labelRenderer = new CSS2DRenderer();
    this.label1Renderer = new CSS2DRenderer();
    this.label2Renderer = new CSS2DRenderer(); // render camera 2

    //this.renderer = new WebGLRenderer({alpha: false, antialias: true});
    this.raycaster = new Raycaster();
    //this.raycaster.layers.set(1); // managed in the getIntersection function, depending on the camera.

    this.pointerPosition = new Vector3(); // pointer position in the scene (with snapping) ?? (and this.position ?)
    this.pointerDistance = undefined; // distance between camera and intersection.

    this.pointerDownPosition = new Vector3();
    this.pointerDownDistance = undefined; // distance between camera and intersection. Used to orbit around the closest point.

    this.orbitCenter = new OrbitCenter();
    this.orbitCenter.load(this.scene);

    this.pointer = new Pointer(0xf7ce55);
    // this.pointer.enable(); why the pointer should be enabled at the begining ?
    this.pointer.disable();

    //this.pointerDebug = new Pointer(0x22ff32, 0.1);
    this.pointer.loadTo(this.scene);
    //this.pointerDebug.loadTo(this.scene);
    this.vector = new Vector(0x22ff32);
    this.vector.loadTo(this.scene);
    this.faceDebug = new FaceDebug(0x22ff32, 1);
    this.faceDebug.loadTo(this.scene);

    this.models = [];
    this.entities = []; // ImageModel, IfcModel, ... instances, Markers... Objects selectable in the 3D space.
    this.objects = []; // Objects that can be selected in the 3D Scenes. Objects are Three.js objects
    this.objectEntities = []; // Entities that have object that should be detected by raycaster. IfcAsyncModel is not an objectEntity. Used in raycaster

    // WIP march 2023: optim to replace "find"
    this.entitiesMap = [];
    this.objectsMap = [];
    this.objectEntitiesMap = [];

    this.positioningMarker = this.createPositioningMarker();

    this.mode = "PICKING"; // POSITIONING_CONFIG_MARKER, POSITIONING_NEW_MARKER "
    this.picking = false; // linked to state.viewer3D.picking.

    this.viewMode = "3D"; // cf state.viewMode default...
    this.snappingMode = snappingMode;

    this.configuredObject = undefined; // object being configured

    // // ifc loader

    //this.ifcLoader = new IFCLoader();
    //this.ifcLoader.ifcManager.setWasmPath("../../../");
    // this.ifcLoader.ifcManager.applyWebIfcConfig({
    //   COORDINATE_TO_ORIGIN: true,
    //   USE_FAST_BOOLS: true,
    // });
    // this.ifcLoader.ifcManager.setupThreeMeshBVH(
    //   computeBoundsTree,
    //   disposeBoundsTree,
    //   acceleratedRaycast
    // );
    // this.ifcLoader.ifcManager.useWebWorkers(webWorker, "../../../IFCWorker.js");

    // draco loader

    // this.dracoLoader = new DRACOLoader();
    // this.dracoLoader.setDecoderPath("../../../draco/");
    // this.dracoLoader.setDecoderConfig({type: "js"});
    // this.dracoLoader.preload();

    // gltf loader

    this.gltfLoader = new GLTFLoader();

    // obj loader

    this.objLoader = new OBJLoader();
    this.mtlLoader = new MTLLoader();

    this.selectedEntity = undefined; // {type,object}
    this.pickedEntity = undefined; // {type,object}

    this.prevFoundId = undefined; // used to test new objet found

    this.onSelectionChange = onSelectionChange; // return the selected entity.parsed

    this.onEscape = onEscape;
    this.onPositionClicked = onPositionClicked;
    this.onEditorClick = onEditorClick;
    this.onProcessEditorClick = () => {}; // this function is defined and called by each process (= controlers)
    this.onModelChange = onModelChange;

    this.onCameraChange = onCameraChange;
    this.onCam2HeightChange = onCam2HeightChange;

    this.configCenter = new ConfigCenter({
      position: {x: 0, y: 1000, z: 0},
      scene: this.scene,
      onSaveConfigEntity,
      editor: this,
    });

    // flash
    this.flash = new Flash();
    this.flash.loadTo(this.scene);

    // dark mode
    this.flashDarkMode = new FlashDarkMode();
    this.flashDarkMode.loadTo(this.scene);

    // standard mode
    this.standardMode = new StandardMode();
    this.standardMode.loadTo(this.scene);

    // animator
    this.animator = new Animator();

    //this.initLight();
    //this.startFlashDarkMode();
    this.startStandardMode();

    // controls

    this.controls = new Controls({
      editor: this,
      onCameraChange: this.onCameraChange,
    });

    // clipper

    this.clipper = new Clipper({editor: this});

    // multiviews

    this.multiviews = new Multiviews({editor: this});

    // camera2 helper

    this.camera2Helper = new Camera2Helper({editor: this});

    // map editor (google maps)

    this.mapEditor = new MapEditor({editor: this});

    // scene editor, to create object on the scene.

    this.sceneEditor = new SceneEditor({editor: this});

    // scenes manager, to load several scenes in a worksite's space

    this.scenesManager = new ScenesManager({editor: this});

    // GLTFExporter, to export three.js models in gltf file

    this.gltfExporter = new GLTFExporter({editor: this});

    // landing scene

    this.sceneLanding = new SceneLanding({editor: this});

    // // Ifc Export, to get JSON from ifc file

    // this.ifcExport = new IfcExport({editor: this});

    // Syncer, to sync operations

    this.syncer = new Syncer({dispatch: this.dispatch});

    // Ombb, used to compute optimun bbox (in 2D)

    this.ombb = new Ombb();

    // drawer 3D objects (2D => 3D)

    this.drawer3DObjects = new Drawer3DObjects();

    // Geometry Engine

    this.geomEngine = new GeomEngine();

    // events

    this.moveEventsCount = 0; // used for hang effect on snap points. cf move handlers
    this.moveEventsLimit = 50;

    if (this.main) {
      this.tempPositioningMarker = this.createPositioningMarker();
      //this._initModels(models);
      //this._initMarkers(markers);
    }

    // image overlay

    this.imageOverlay = new ImageOverlay({scene: this.scene, editor: this});

    this.caplaEditor = caplaEditor;
    this.pointerDownTimestamp = null;

    // grid

    this.addGrid();

    this.opacity = 0.74;
  }

  // -------------------
  // --- * setters * ---
  // -------------------

  // setPdfEditor(pdfEditor) {
  //   this.pdfEditor = pdfEditor;
  // }

  setOpacity(opacity) {
    this.opacity = opacity;
  }

  // -------------------------------
  // --- * canvas & DomElement * ---
  // -------------------------------

  setDomElement(domElement) {
    this.domElement = domElement;
  }

  getDomElement() {
    return this.domElement;
    //return this.domElement1;
  }

  // -------------------------------
  // --- * events * ---
  // -------------------------------

  disableClickHandlers() {
    // prevent actions on 3D elements when clicking on CSSObjects
    // prevent event when closing a temp marker.
    this.clickHandlersDisabled = true;
  }
  enableClickHandlers() {
    this.clickHandlersDisabled = false;
  }

  // ------------------------
  // --- * Webworkers * ---
  // ------------------------

  // enableWebWorker() {
  //   this.ifcLoader.ifcManager.useWebWorkers(true, "../../../IFCWorker.js");
  // }

  // disableWebWorker() {
  //   this.ifcLoader.ifcManager.useWebWorkers(false);
  // }

  // ------------------------
  // --- * Initializers * ---
  // ------------------------

  // _initModels(models) {
  //   models?.forEach((model) => this.addModel(model));
  // }

  initCamera(size) {
    this.cameras = new Cameras(size, this.configCenter);
    this.cameras.activeCamera = this.cameras.camera;
  }

  initRenderer(size, canvas) {
    this.renderer.setSize(size.width, size.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    canvas.appendChild(this.renderer.domElement);
    this.renderer.localClippingEnabled = true;
  }

  initLabelRenderer(size, canvas) {
    this.labelRenderer.setSize(size.width, size.height);
    this.labelRenderer.domElement.style.position = "absolute";
    this.labelRenderer.domElement.style.top = "0px";
    canvas.appendChild(this.labelRenderer.domElement);
  }

  initLabel1Renderer(size, el1) {
    //this.label1Renderer.setSize(size.width, size.height);
    this.label1Renderer.domElement.style.position = "absolute";
    this.label1Renderer.domElement.style.top = "0px";
    this.label1Renderer.domElement.style.zIndex = "5";
    el1.appendChild(this.label1Renderer.domElement);
  }

  initLabel2Renderer(size, el2) {
    this.label2Renderer.domElement.style.position = "absolute";
    this.label2Renderer.domElement.style.top = "0px";
    this.label2Renderer.domElement.style.zIndex = "5";
    el2.appendChild(this.label2Renderer.domElement);
  }

  startLoading() {
    try {
      this.loading = true;
      //this.renderer.setClearAlpha(1);
      this.flash.enable();
      this.startFlashDarkMode();
    } catch (e) {
      console.log(e);
    }
  }

  stopLoading() {
    this.loading = false;
    //this.renderer.setClearAlpha(0);
    this.flash.disable();
    this.startStandardMode();
  }

  startFlashDarkMode() {
    this.stopStandardMode();
    this.flashDarkMode.enable();
    //this.scene.background = new Color(0x292a2d);
    this.scene.background = new Color(0x000000);
  }
  stopFlashDarkMode() {
    this.flashDarkMode.disable();
    this.scene.background = null;
  }

  startStandardMode() {
    this.stopFlashDarkMode();
    this.standardMode.enable();
  }
  stopStandardMode() {
    this.standardMode.disable();
  }

  applySceneSettings(settings) {
    try {
      // camera
      const camera = settings?.initialCamera;
      if (camera) {
        const {name, target, position} = camera;
        const controls = this.controls.activeControls;
        this.cameras.setActiveCameraTo({name, position, target, controls});
      }
      // grid
      const grid = settings?.grid;
      //this.grid.hide();
      if (grid || grid === undefined) this.grid.show();
    } catch (e) {
      console.log(e);
    }
  }
  // }

  // ----------------
  // -- * Grids * ---
  // ----------------

  addGrid() {
    this.grid = new Grid();
    this.grid.load(this.scene);
    this.grid.hide();
  }

  // ----------------
  // -- * Scene * ---
  // ----------------

  toggleTransparentBackground() {
    if (this.transparentBackground) {
      this.scene.background = new Color(0xfafeff);
      this.transparentBackground = false;
    } else {
      this.scene.background = null;
      this.transparentBackground = true;
    }
  }

  // -----------------------------
  // -- * Cameras & Controls * ---
  // -----------------------------

  blockControl() {
    // this.controls.minPolarAngle = 0;
    // this.controls.maxPolarAngle = 0;
    this.controls.orbitControls.minPolarAngle = Math.PI / 2;
    this.controls.orbitControls.maxPolarAngle = Math.PI / 2;
    this.controls.orbitControls.minAzimuthAngle = 0;
    this.controls.orbitControls.maxAzimuthAngle = 0;
  }

  // -----------------
  // -- * Editor * ---
  // -----------------

  // Remove all objects https://stackoverflow.com/questions/29417374/remove-all-objects-from-scene

  removeObjectsWithChildren(obj) {
    if (obj.children.length > 0) {
      const children = obj.children;
      for (var x = children.length - 1; x >= 0; x--) {
        const child = children[x];
        this.removeObjectsWithChildren(child);
        obj.remove(child);
      }
    }
    if (obj.geometry) {
      obj.geometry.dispose();
    }
    if (obj.material) {
      if (obj.material.length) {
        for (let i = 0; i < obj.material.length; ++i) {
          if (obj.material[i].map) obj.material[i].map.dispose();
          if (obj.material[i].lightMap) obj.material[i].lightMap.dispose();
          if (obj.material[i].bumpMap) obj.material[i].bumpMap.dispose();
          if (obj.material[i].normalMap) obj.material[i].normalMap.dispose();
          if (obj.material[i].specularMap)
            obj.material[i].specularMap.dispose();
          if (obj.material[i].envMap) obj.material[i].envMap.dispose();
          obj.material[i].dispose();
        }
      } else {
        if (obj.material.map) obj.material.map.dispose();
        if (obj.material.lightMap) obj.material.lightMap.dispose();
        if (obj.material.bumpMap) obj.material.bumpMap.dispose();
        if (obj.material.normalMap) obj.material.normalMap.dispose();
        if (obj.material.specularMap) obj.material.specularMap.dispose();
        if (obj.material.envMap) obj.material.envMap.dispose();
        obj.material.dispose();
      }
    }
    // obj.removeFromParent();
    // return true;
  }

  clear() {
    try {
      // scene
      // this.scene.traverse(function (object) {
      //   if (object.type === "Mesh") {
      //     const geometry = object.geometry;
      //     const material = object.material;
      //     this.scene.remove(object);
      //     geometry?.dispose();
      //     material?.dispose();
      //   }
      // });
      this.scene.children.forEach((child) => {
        if (child.type === "Object3D") this.removeObjectsWithChildren(child);
        // this.scene.remove(child);
      });
      // entities
      this.entities = [];
      this.selectedEntity = [];

      this.models = [];
      this.objects = [];

      // markers
      this.positioningMarker.visible = false;
      //this.configMarker1.visible = false;
      //this.configMarker2.visible = false;
    } catch (e) {
      console.log(e);
    }
  }

  fitCameraToObject(object) {
    fitCameraToObject(
      this.cameras.activeCamera,
      this.controls.activeControls,
      object
    );
  }

  // --------------------
  // --- * Entities * ---
  // --------------------

  disableAllEntities() {
    this.entities.forEach((entity) => {
      if (entity.disable) entity.disable();
    });
  }

  getEntityByObjectId(id) {
    // to remove
    return this.entities.find((e) => e.object.id === id);
  }
  getEntityById(type, id) {
    return this.entities.find((e) => e.entityID === id && e.type === type);
  }
  getEntity(id) {
    return this.entities.find((e) => e.entityID === id);
  }

  getObjectEntityByObjectId(id) {
    // used
    return this.objectEntities.find((e) => e.object?.id === id);
  }

  get visibleObjects() {
    const objects = this.objectEntities
      .filter((e) => e.type !== "EDGES" && !e.hidden)
      .map((entity) => entity.object);
    const scope = objects.filter(
      (o) => o?.visible === true && !o?.userData?.isEdge
    );
    return scope;
  }

  // -------------------
  // --- * Objects * ---
  // -------------------

  getObjectById(id) {
    return this.objects.find((object) => object.userData.id === id);
  }

  getObjectByUUID(uuid) {
    return this.objects.find((object) => object.uuid === uuid);
  }

  positionObject(object, position, rotation) {
    object.position.set(position.x, position.y, position.z);
    object.rotation.set(rotation.x, rotation.y, rotation.z);
  }
  scaleObject(object, scale) {
    object.scale.set(scale, scale, scale);
  }

  getObjectPosition(object) {
    const position = {
      x: object.position.x,
      y: object.position.y,
      z: object.position.z,
    };
    const rotation = {
      x: object.rotation.x,
      y: object.rotation.y,
      z: object.rotation.z,
    };
    const scale = object.scale.x;
    return {position, rotation, scale};
  }

  deleteObject(uuid) {
    const object = this.objects.find((object) => object.uuid === uuid);
    console.log("deleting object", object);
    this.objects = this.objects.filter((object) => object.uuid != uuid);
    if (object) {
      this.scene.remove(object);
    }
  }

  toggleObjectVisibility(object) {
    object.layers.toggle(1);
    object.children.forEach((child) => child.layers.toggle(1));
  }

  getObjectScreenPosition(object) {
    this.cameras.activeCamera.updateMatrixWorld();
    let p = this.tempPositioningMarker.object.position
      .clone()
      .project(this.cameras.activeCamera);
    const width = window.innerWidth;
    const height = window.innerHeight;
    return {
      x: ((p.x + 1) * width) / 2,
      y: (-(p.y - 1) * height) / 2,
    };
  }

  // -------------------
  // --- * Measure * ---
  // -------------------

  createMeasure() {
    const measure = new Measure(this.scene);
    return measure;
  }

  // -------------------
  // --- * Markers * ---
  // -------------------

  addMarker(model) {
    return new Marker({
      model,
      scene: this.scene,
      entities: this.entities,
      objectEntities: this.objectEntities,
    });
  }

  createPositioningMarker = () => {
    const model = {type: "POSITIONING"};
    const marker = new Marker({marker: model, scene: this.scene});
    this.scene.add(marker.object);
    return marker;
  };

  createConfigMarker(num) {
    const model = {type: `CONFIG_${num}`};
    const marker = new Marker({marker: model, scene: this.scene});

    return marker;
  }

  setTempPositioningMarker(position) {
    this.tempPositioningMarker.object.position.set(
      position.x,
      position.y,
      position.z
    );
    this.tempPositioningMarker.object.visible = true;
  }

  // ----------------
  // -- * modes * ---
  // ----------------

  initConfig() {
    this.configMarker1.visible = false;
    this.configMarker2.visible = false;
    this.positionedObject = undefined;
  }

  switchToColor(color) {
    console.log("editor switch to color", color);
    this.entities.forEach((entity) => {
      if (entity.switchToColor) {
        entity.switchToColor(color);
      }
    });
  }

  setViewMode(mode) {
    this.viewMode = mode;
  }

  // to remove - legacy

  switchToViewMode(mode, option) {
    let object; // used to target the 2D camera
    switch (mode) {
      case "VIEW_2D":
        this.isOrtho = true;
        this.cameras.enable("ORTHO_CAMERA", this.controls.activeControls);
        if (option?.target) {
          this.cameras.focusActiveCameraOnEntity(
            option.target,
            this.controls.activeControls
          );
        }

        break;
      case "VIEW_2D_CONFIG":
        this.isOrtho = true;
        this.cameras.enable(
          "ORTHO_CAMERA_CONFIG",
          this.controls.activeControls
        );
        // this.controls.activeControls.target.copy(this.configCenter.position);
        // this.controls.activeControls.update();
        if (option?.target) {
          this.cameras.focusActiveCameraOnEntity(
            option.target,
            this.controls.activeControls
          );
        }

        break;
      case "VIEW_3D":
        this.isOrtho = false;
        this.sceneEditor.activeCamera3D();
        //this.cameras.enable("CAMERA", this.controls.activeControls);
        break;
    }
  }

  switchToMode(mode) {
    this.mode = mode;
    switch (mode) {
      case "PICKING":
        this.positioning = false;
        this.positioningMarker.object.visible = false;
        this.onEditorMove = undefined;
        if (this.tempPositioningMarker) {
          this.tempPositioningMarker.object.visible = false;
        }
        break;
      case "MOVE_ONE_POINT": // object deplacement
        this.positioning = true;
        break;
      case "POINT_SELECTION":
        break;

      case "POSITIONING_MARKER":
        this.positioning = true;
        this.positioningMarker.object.visible = true;
        break;
      case "POSITIONING_CONFIG_MARKER_1":
        this.positioning = true;
        this.positioningMarker.object.visible = true;
        this.positionedObject = this.configMarker1.object;

        break;
      case "POSITIONING_CONFIG_MARKER_2":
        this.positioning = true;
        this.positioningMarker.object.visible = true;
        this.positionedObject = this.configMarker2.object;
        break;
    }
  }

  // ---------------------
  // --- * Selection * ---
  // ---------------------

  multipleSelectionIsEmpty(ms) {
    const count = ms.length;
    let empty = 0;
    ms.forEach((s) => {
      if (s.expressIDs.length === 0) {
        empty++;
      }
    });
    return count === empty;
  }

  // trigger to update the scene based on the multiple selection.
  // !! risk of infinite loop => handleSelection change is called again...
  // entity multipleSelect should not call  a new State update !!

  multipleSelect(multipleSelection) {
    if (this.multipleSelectionIsEmpty(multipleSelection)) {
      this.clearSelection();
    } else if (
      Array.isArray(multipleSelection) &&
      multipleSelection.length > 0
    ) {
      this.clearSelection();
      multipleSelection.forEach((selection) => {
        const entity = this.getEntity(selection.modelId);
        if (entity?.multipleSelect) {
          let ids;
          // if (entity.type === "IFC_MODEL") ids = selection.expressIDs;
          if (entity.type === "CAPLA_MODEL") ids = selection.codeNames;
          entity.multipleSelect(ids);
        }
      });
    }
  }

  clearSelection() {
    this.objectEntities.forEach((e) => {
      if (e.unselect) e.unselect();
    });
  }

  handlePointerMove(event, canvas, camera, withoutHCP) {
    // HCP : horizontal clipping plane.
    event.stopPropagation();

    const isNearlyAClick = Date.now() - this.pointerDownTimestamp < 80;

    // optim
    if (!this.sceneEditor.optimized && this.pointerDown)
      this.sceneEditor.optimize();

    const isLocked =
      this.controls.activeMode === "LOCK" ||
      this.controls.activeMode === "LOCK2";

    if (!isLocked && this.pointerDown && !isNearlyAClick) {
      this.dragging = true;
    }

    const handleMoveEvent =
      !isLocked &&
      this.sceneEditor.pointerMode &&
      !this.panning &&
      !this.orbiting;

    if (handleMoveEvent) {
      // main check to know if we compute intersection on move.
      try {
        this.moveEventsCount += 1;
        if (this.pointerDown) {
          this.dragging = true;
        }

        if (this.startLeftDragging) {
          this.dispatch(setPanning(true));
          this.panning = true;
          this.startLeftDragging = false;
        }

        const cps = this.clipper.activeClippingPlanes.filter(
          (cp) => !(withoutHCP && cp.horizontal)
        );

        const {found, snapping, snappingPoint, poi, normal, facePoints} =
          getIntersection(
            event,
            canvas,
            camera,
            this.raycaster,
            this.visibleObjects,
            cps.map((cp) => cp.plane)
          );
        // poi
        if (found) {
          if (!this.sceneEditor.markerTemp.overed) this.pointer.enable();
          if (this.moveEventsCount > this.moveEventsLimit) {
            // we update the pointer if the limit is reached. The counter is set to 0 when snapped.
            this.pointer.unlock();
            const position =
              snapping && this.snappingMode ? snappingPoint : poi;
            this.pointer.update({position, normal});
            this.pointerDistance = found.distance;
            this.intersectionNormal = normal;
            this.facePoints = facePoints;
            //this.pointer.update();
            if (snapping && this.snappingMode) {
              this.pointer.lock();
              this.pointer.setSnapped(true);
              //this.pointer.enable();
              this.moveEventsCount = 0; // we reset the counter => hold effect
            } else {
              this.pointer.setSnapped(false);
              //this.pointer.disable();
            }
          }
        } else {
          // no found => we wait for the limit to disable the "snapped" pointer.
          if (
            this.moveEventsCount > this.moveEventsLimit ||
            this.snappingMode === "false"
          )
            this.pointer.disable();
        }

        if (this.onProcessEditorMove) {
          const p = this.pointer.object.position;
          this.onProcessEditorMove({
            sceneP: {x: p.x, y: p.y, z: p.z},
            normal: {x: normal.x, y: normal.y, z: normal.z},
          });
          // used to compute one object position on screen. To heavy in computation.
          //const p = this.getObjectScreenPosition(this.tempPositioningMarker.object);
          //this.onEditorMove(p);
        }

        // position marker
        // Removed => no need the positioningMarker.
        // if (this.positioning && found) {
        //   this.positioningMarker.object.visible = true;
        //   this.positioningMarker.object.position.copy(
        //     this.pointer.object.position
        //   );
        //   this.positioningMarker.object.translateZ(0.0001);
        // }
        // if (
        //   this.positioning &&
        //   !found &&
        //   this.moveEventsCount > this.moveEventsLimit
        // ) {
        //   this.positioningMarker.object.visible = false;
        // }

        // picking
        if (this.picking) {
          // this.picking => set to highlight the faces.
          if (found) {
            this.pick(found);
          } else {
            if (this.pickedEntity) {
              this.pickedEntity.unpick();
              this.pickedEntity = undefined;
              this.prevFoundId = undefined;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async pick(found) {
    try {
      if (!this.orbiting && !this.panning) {
        const objectID = found.object.id;
        const faceIndex = found?.faceIndex;
        const foundId = `${objectID}-${faceIndex}`;
        if (foundId !== this.prevFoundId) {
          this.sceneEditor.unpick();
          if (this.pickedEntity) {
            this.pickedEntity.unpick();
          }
          const entity = this.getObjectEntityByObjectId(objectID);
          if (entity.type === "MEASUREMENT_ELEMENT") {
            this.sceneEditor.pickElementEntity({
              modelId: entity.modelId,
              entityID: entity.entityID,
            });
          } else if (entity?.pick) {
            this.pickedEntity = await entity.pick(faceIndex);
          }
          this.prevFoundId = foundId;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async onClick(event, canvas, camera) {
    // at the end of the handler, we call pointer up handlers. => end of dragging/ orbiting / panning

    const isLocked =
      this.controls.activeMode === "LOCK" ||
      this.controls.activeMode === "LOCK2";

    // we test the active camera when clicking
    const mouseLeft = event.clientX;
    const dividerLeft = this.multiviews.dividerLeft;
    const clickInLeft = dividerLeft > mouseLeft;

    let activeCamera, activeControls;
    if (this.viewMode === "3D" || this.viewMode === "PDF") {
      activeCamera = this.cameras.camera;
      activeControls = this.controls.orbitControls;
    } else {
      if (clickInLeft) {
        activeCamera = this.cameras.camera1;
        activeControls = this.controls.orbitControls1;
      } else {
        activeCamera = this.cameras.camera2;
        activeControls = this.controls.orbitControls2;
      }
    }

    if (!isLocked && !this.clickHandlersDisabled && !this.dragging) {
      const keepSelection = event.ctrlKey || event.metaKey;

      // we first cast object, in case picking was set to false.
      const {found, normal, snapping, snappingPoint, poi} = getIntersection(
        event,
        canvas,
        camera,
        this.raycaster,
        this.visibleObjects,
        this.clipper.activeClippingPlanes.map((cp) => cp.plane)
      );

      // update pointer
      const position = snapping && this.snappingMode ? snappingPoint : poi;
      this.pointer.update({normal, position});

      // get entities & clicked object
      let modelEntity;
      const edgeEntityID = found?.object?.userData?.entityID; // to select the object and not its edges
      console.time("DEBUG_CLICK T1");
      if (edgeEntityID) {
        modelEntity = this.getEntity(edgeEntityID);
      } else {
        modelEntity = this.getObjectEntityByObjectId(found?.object.id);
      }
      console.timeEnd("DEBUG_CLICK T1");
      if (modelEntity) {
        const fromPdf = this.sceneEditor.selectElementEntity({
          modelId: modelEntity?.modelId,
          entityID: modelEntity?.entityID,
          keepSelection,
        });

        if (modelEntity?.type === "MEASUREMENT_ELEMENT") {
          // const pdfModelId = modelEntity.measurementsModel.model.fromModel.modelId;
          // if (this.caplaEditor.openSections.fixedViewersBox && this.caplaEditor.editorPdf.modelId === pdfModelId) {
          //   const annotationManager = this.caplaEditor.editorPdf?.webViewer.Core.annotationManager;
          //   const annots = annotationManager?.getAnnotationsList().filter((a) => a.Id === modelEntity.entityID);
          //   annotationManager.selectAnnotations(annots);
          // } else {
          if (!fromPdf) {
            console.time("DEBUG_CLICK T2");
            this.dispatch(
              setSelectedMeasurementIds(
                this.sceneEditor.selectedElements.map((e) => e.entityID)
              )
            );
            console.timeEnd("DEBUG_CLICK T2");
          }
          const clickedObject = {
            type: "MEASUREMENT",
            name: "-",
            modelId: modelEntity.modelId,
            entityID: modelEntity.entityID,
            clickedAt: Date.now(),
          };
          this.dispatch(setClickedObject(clickedObject));
          this.caplaEditor.editorPdf.annotationsManager.selectAnnotation(
            clickedObject.entityID
          );
        } else {
          const parsedModel = await modelEntity?.parse();
          const clickedObject = {
            type: modelEntity?.type,
            name: parsedModel?.name,
            modelId: parsedModel?.modelId,
            data: parsedModel,
            clickedAt: Date.now(),
          };
          this.dispatch(setClickedObject(clickedObject));
          if (!this.positioning && !clickedObject.type === "MARKER")
            this.dispatch(
              setSelectedZoneId(parsedModel.model.fromModel.zoneId)
            );
        }
      } else if (!keepSelection && !this.sceneEditor.selectionBoxIsEnabled) {
        console.log("CLICK and empty multiselection");
        this.dispatch(setSelectedMeasurementIds([]));
        this.dispatch(setSelectedZoneId(null));
        this.sceneEditor.unselect();
        this.caplaEditor.editorPdf.webViewer.Core.annotationManager.deselectAllAnnotations();
      }

      // if (!found) {
      //   // this.dispatch(setClickedItem({}));
      //   this.sceneEditor.unselect();
      // }

      const distance = found?.distance;
      const point = found?.point;

      //orbit around pointer projection. Orbit center = always middle of the screen
      const cameraP = new Vector3();
      const cameraD = new Vector3();
      activeCamera.getWorldDirection(cameraD);
      cameraP.copy(activeCamera.position);
      if (distance && point) {
        const orbitT = cameraP.add(
          cameraD.normalize().multiplyScalar(distance)
        );
        //this.orbitCenter.enable({position: point});
        this.orbitCenter.enable({position: orbitT});
        //this.controls.orbitControls.target.copy(orbitT);
        //activeControls.target.copy(point);
        activeControls.target.copy(orbitT);
        activeControls.update();
        //this.controls.update();
      }

      try {
        const x = event.clientX;
        const y = event.clientY;
        const positions = {
          screenP: {x, y},
          sceneP: this.pointer.getPosition(),
        };

        this.onEditorClick({
          ...positions,
        });
        this.onProcessEditorClick({
          ...positions,
          normal,
          modelEntity,
          pickedEntity: this.pickedEntity,
          pickedFaceNormal: this.intersectionNormal,
          facePoints: this.facePoints,
        });

        // if (this.mode === "PICKING" || this.mode === "DEFAULT") {
        //   console.log(
        //     "[CLICK]",
        //     this.pickedEntity?.pickId,
        //     this.selectedEntity?.selectionId
        //   );
        //   if (!this.pickedEntity) {
        //     // click away
        //     const unselect = false;
        //     console.log("case0");
        //     if (this.selectedEntity?.unselect) this.selectedEntity.unselect();

        //     this.selectedEntity = undefined;
        //     this.onSelectionChange(undefined, keepSelection, unselect);
        //   } else if (this.pickedEntity && !this.selectedEntity?.selectionId) {
        //     console.log("caseA");
        //     this.selectedEntity?.unselect();
        //     this.selectedEntity = await this.pickedEntity.select({
        //       normal: this.pointer.normal,
        //       poi: this.pointer.object.position,
        //     });
        //     const selectedEntity = await this.selectedEntity.parse();
        //     const unselect = !keepSelection;
        //     this.onSelectionChange(selectedEntity, keepSelection, unselect);
        //   } else if (
        //     this.pickedEntity &&
        //     this.selectedEntity?.selectionId &&
        //     this.pickedEntity.pickId !== this.selectedEntity?.selectionId
        //   ) {
        //     console.log("caseA-Bis");
        //     console.log(this.pickedEntity, this.selectedEntity.selectionId);
        //     this.selectedEntity?.unselect();
        //     this.selectedEntity = await this.pickedEntity.select({
        //       normal: this.pointer.normal,
        //       poi: this.pointer.object.position,
        //     });
        //     const selectedEntity = await this.selectedEntity.parse();
        //     const unselect = false;
        //     this.onSelectionChange(selectedEntity, keepSelection, unselect);
        //   } else if (!this.pickedEntity && this.selectedEntity) {
        //     console.log("caseB");
        //     this.selectedEntity?.unselect();
        //     const selectedEntity = await this.selectedEntity.parse();
        //     const unselect = true;
        //     this.onSelectionChange(selectedEntity, keepSelection, unselect);
        //     this.selectedEntity = undefined;
        //   } else if (
        //     this.pickedEntity?.pickId === this.selectedEntity?.selectionId
        //   ) {
        //     console.log("caseC");
        //     const unselect = false;
        //     const selectedEntity = await this.selectedEntity.parse();
        //     this.selectedEntity?.unselect();
        //     console.log("selected entity id", selectedEntity.expressID);
        //     this.onSelectionChange(selectedEntity, keepSelection, unselect);
        //     this.selectedEntity = undefined;
        //   }
        // }
        if (this.mode === "POSITIONING_MARKER") {
          this.tempPositioningMarker.object.position.copy(
            this.positioningMarker.object.position
          );
          this.tempPositioningMarker.object.visible = true;
          const p = this.positioningMarker.object.position;
          this.onPositionClicked([p.x, p.y, p.z]);
        }
        if (this.mode === "POSITIONING_CONFIG_MARKER_1") {
          this.configMarker1.object.position.copy(
            this.positioningMarker.object.position
          );
          this.configMarker1.object.visible = true;
        }
        if (this.mode === "POSITIONING_CONFIG_MARKER_2") {
          this.configMarker2.object.position.copy(
            this.positioningMarker.object.position
          );
          this.configMarker2.object.visible = true;
        }
      } catch (e) {
        console.log(e);
      }
    }

    // pointer up event

    if (!isLocked) {
      this.pointerDown = false;
      this.orbitCenter.disable();
      this.startLeftDragging = false;
      this.dispatch(setOrbiting(false));
      this.dispatch(setPanning(false));
      this.orbiting = false;
      this.panning = false;
      this.dragging = false;
    }
  }

  // handlers

  handleKeyDown(e) {
    const isLocked =
      this.controls.activeMode === "LOCK" ||
      this.controls.activeMode === "LOCK2";

    if (e.key === "Escape" && !isLocked) {
      if (this.cameras.activeCameraName === "ORTHO_CAMERA_CONFIG")
        this.controls.orbitControls.target.copy(new Vector3(0, 0, 0));
      this.switchToMode("PICKING");
      this.switchToViewMode("VIEW_3D");
      this.configCenter.close();
      this.sceneEditor.hideMarkers();

      this.pointer.disable();

      // pdf viewMode
      this.caplaEditor.pdfEditor?.sceneEditor.stopProcess();
      this.caplaEditor.pdfEditor?.activePanTool();
      this.dispatch(setSelectedMeasurementIds([]));
      this.dispatch(setSelectedZoneId(null));
      this.sceneEditor.unselect();
      if (this.caplaEditor.openSections.fixedViewersBox)
        this.caplaEditor.editorPdf?.webViewer.Core.annotationManager.deselectAllAnnotations();
      //this.pdfEditor?.annotationsManager.measurementsPdfManager.reset();

      // state management
      this.onEscape();
    }
  }

  handlePointerDown(e) {
    const {x, y, z} = this.pointer?.position ? this.pointer?.position : {}; // this.pointerPosition ???

    if (this.sceneEditor.pointerMode)
      this.dispatch(setPointerPosition({x, y, z}));

    const isLocked =
      this.controls.activeMode === "LOCK" ||
      this.controls.activeMode === "LOCK2";

    if (!isLocked) {
      this.pointerDown = true;
      this.pointerDownTimestamp = Date.now();
      this.startDragging = true; // will be changed in handlePositionMove
      this.pointerDownPosition.copy(this.pointerPosition);
      this.dragging = false; // we update the state here and not onPointerUp to differentiate click event.
      if (e?.buttons === 4) {
        this.dispatch(setOrbiting(true));
        this.orbiting = true;
      }
      if (e?.buttons === 1) this.startLeftDragging = true;
    }
  }

  // pointer up : only for mouse center button up.
  handlePointerUp(e) {
    console.log("pointer up");
    const isLocked =
      this.controls.activeMode === "LOCK" ||
      this.controls.activeMode === "LOCK2";

    if (!isLocked) {
      this.dispatch(setOrbiting(false));
      this.orbiting = false;
      this.pointerDown = false;
    }

    if (this.sceneEditor.optimized) this.sceneEditor.restoreState();
  }

  handleResize(canvas) {
    if (canvas && this.cameras) {
      let offsetX, offsetY;
      const bounds = canvas.getBoundingClientRect();
      if (bounds) {
        offsetX = bounds.left;
        offsetY = bounds.top;
      }
      const width = canvas.offsetWidth;
      const height = canvas.offsetHeight;
      this.canvasSize = {width, height};
      this.cameras.updateCamerasAspect({width, height});
      this.renderer.setSize(width, height);
      this.labelRenderer.setSize(width, height);
      this.multiviews.updateStateSliderPositionX();
      this.multiviews.updateCamerasAspect();
      this.multiviews.updateLabel1Renderer();
      this.dispatch(setRendererSize({width, height}));
      this.dispatch(setRendererOffset({top: offsetY, left: offsetX}));
    }
  }
}

export default Editor;
