import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";

import ListItemBuiltAt from "./ListItemBuiltAt";

import {
  addDateToShortcutBuiltAts,
  removeDateFromShortcutBuiltAts,
  toggleShortcutBuiltAt,
  // initShortcutBuiltAts,
} from "Features/measurements/measurementsSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";

export default function ShortcutMeasurementsBuiltAt({scene, mode}) {
  const dispatch = useDispatch();

  // strings

  const switchModeString = "Switch mode";
  const showAllString = "Show all";
  const hideAllString = "Hide all";

  // data

  // const pageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);
  // const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const shortcutBuiltAts = useSelector((s) => s.measurements.shortcutBuiltAts);

  const [fm, fmIds, fmIdsHash, filtersHash] = useFilteredSceneMeasurements(
    scene,
    mode
  );

  // state

  const [checked, setChecked] = useState(false);

  let builtAts = [...new Set(fm.map((m) => m.builtAt))]
    .filter((d) => d)
    .sort((a, b) => a.localeCompare(b));
  builtAts = [...builtAts, null]; // to take into account the todos.

  // handlers

  function handleBuiltAtClick(builtAt) {
    if (!checked) {
      dispatch(toggleShortcutBuiltAt(builtAt ? builtAt : null));
    } else {
      builtAts.forEach((builtAt) => {
        dispatch(removeDateFromShortcutBuiltAts(builtAt));
      });
      dispatch(toggleShortcutBuiltAt(builtAt));
    }
  }

  // handlers - check options

  function handleShowAll() {
    builtAts.forEach((builtAt) => dispatch(addDateToShortcutBuiltAts(builtAt)));
  }

  function handleHideAll() {
    builtAts.forEach((builtAt) =>
      dispatch(removeDateFromShortcutBuiltAts(builtAt))
    );
  }

  function handleCheckChange(e) {
    setChecked(e.target.checked);
  }

  return (
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {builtAts.map((builtAt) => {
        const selected = shortcutBuiltAts.includes(builtAt);
        return (
          <ListItemBuiltAt
            selected={selected}
            key={builtAt}
            builtAt={builtAt}
            onClick={() => handleBuiltAtClick(builtAt)}
          />
        );
      })}

      <Box sx={{display: "flex", flexDirection: "column", mt: 2}}>
        <FormControlLabel
          sx={{mb: 1}}
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckChange}
              size="small"
              sx={{width: "12px", height: "12px", mr: 1}}
            />
          }
          label={
            <Typography sx={{fontSize: 12}} variant="body2">
              {switchModeString}
            </Typography>
          }
        />
      </Box>

      <Box sx={{display: "flex"}}>
        <Button size="small" onClick={handleHideAll}>
          {hideAllString}
        </Button>
        <Button size="small" onClick={handleShowAll}>
          {showAllString}
        </Button>
      </Box>
    </Box>
  );
}
