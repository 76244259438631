import {Box, Typography} from "@mui/material";

export default function ContainerDisconnectedMode() {
  // strings

  const label = "Bimbox éphémère";
  return (
    <Box
      sx={{
        height: 24,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "primary.flash",
        px: 1,
      }}
    >
      <Typography sx={{fontSize: 12}}>{label}</Typography>
    </Box>
  );
}
