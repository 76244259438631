import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {fetchListingService} from "./services";

export const fetchListing = createAsyncThunk(
  "listing/fetchListing",
  fetchListingService
);

const listingSlice = createSlice({
  name: "listing",
  initialState: {datasets: {}},
  reducers: {},
  extraReducers: {
    [fetchListing.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      const dataset = {data, status: "succeed"};
      state.datasets[listingId] = dataset;
    },
    "listings/updateListing/fulfilled": (state, action) => {
      const updatedListing = action.payload;
      state.datasets[updatedListing.id] = updatedListing;
    },
    "members/createMember/fulfilled": (state, action) => {
      const updatedListing = action.payload.data;
      state.datasets[updatedListing.id] = updatedListing;
    },
    "members/updateMember/fulfilled": (state, action) => {
      const updatedListing = action.payload.data;
      state.datasets[updatedListing.id] = updatedListing;
    },
    "relatedlistings/createRelatedListing/fulfilled": (state, action) => {
      const updatedListing = action.payload.data;
      state.datasets[updatedListing.id] = updatedListing;
    },
    "relatedlistings/deleteRelatedListing/fulfilled": (state, action) => {
      const updatedListing = action.payload.data;
      state.datasets[updatedListing.id] = updatedListing;
    },
  },
});

export default listingSlice.reducer;
