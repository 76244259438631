import {client} from "API/capla360";

export const createSharedboxService = async ({accessToken, sharedbox}) => {
  const newSharedbox = {...sharedbox};
  const sceneId = sharedbox.sceneId;
  delete newSharedbox.scene;
  newSharedbox.scene = sharedbox.sceneId;
  const response = await client.post(
    `scenes/${sceneId}/sharedboxes/`,
    newSharedbox,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: {...response.data, sceneId}};
};

export const fetchSharedboxService = async ({
  accessToken,
  sceneId,
  sharedboxId,
}) => {
  const response = await client.get(
    `/scenes/${sceneId}/sharedboxes/${sharedboxId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const remoteSharedbox = response.data;
  console.log("remoteSharedbox", remoteSharedbox);
};

export const fetchSharedboxesService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/sharedboxes/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteSharedboxes = response.data;

  const sharedboxes = remoteSharedboxes.map((r) => {
    return {...r, sceneId: r.scene};
  });

  return {items: sharedboxes, sceneId};
};

export const updateSharedboxService = async ({
  accessToken,
  sceneId,
  sharedbox,
}) => {
  const sharedboxId = sharedbox.id;
  console.log("updateSharedboxService", sharedbox);
  if (!sharedboxId) return sharedbox;
  const response = await client.put(
    `/scenes/${sceneId}/sharedboxes/${sharedboxId}/`,
    sharedbox,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedSharedbox = response.data;
  return {item: {...updatedSharedbox, sceneId: updatedSharedbox.scene}};
};

export const deleteSharedboxService = async ({
  accessToken,
  sharedboxId,
  sceneId,
}) => {
  await client.delete(`/scenes/${sceneId}/sharedboxes/${sharedboxId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return sharedboxId;
};
