import articleTypesArrayToMap from "./articleTypesArrayToMap";
import articleTypesMapToArray from "./articleTypesMapToArray";

export default function removeGlobalFiltersStrFromArticleTypes(articleTypes) {
  const typesArray = articleTypesMapToArray(articleTypes);

  console.log("REMOVE_GF START", articleTypes, typesArray);

  let globalFiltersStr;
  let groupFiltersStrMap = {};
  let hasItems = false;
  let hasItemsMap = {};
  let globalFuncIsTout = false;

  Object.entries(articleTypes).forEach(([groupId, typeMap]) => {
    if (groupId === "filtersStr") {
      globalFiltersStr = typeMap;
      console.log("globalFunc", articleTypes["globalFunc"]);
      if (articleTypes["globalFunc"] === "TOTAL") {
        globalFuncIsTout = true;
      }
    } else if (groupId !== "globalFunc") {
      Object.entries(typeMap).forEach(([typeId, props]) => {
        if (typeId !== "filtersStr") {
          hasItems = true;
          hasItemsMap[groupId] = true;
        }
      });
    }
  });

  const newTypesArray = typesArray.filter((item) => {
    if (!item.groupId && !item.typeId) {
      if (hasItems && !globalFuncIsTout) {
        return false;
      }
    } else if (item.groupId && !item.typeId) {
      if (hasItemsMap[item.groupId]) {
        return false;
      }
    } else return true;
  });

  console.log("REMOVE_GF END", newTypesArray);
  return articleTypesArrayToMap(newTypesArray);
}
