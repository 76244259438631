import {Box, Button, Typography} from "@mui/material";
import {Image} from "@mui/icons-material";

import captureRefAsImageAsync from "../utils/captureRefAsImage";

export default function ButtonCaptureRefAsImage({
  componentRef,
  editorPdf,
  positionInPdf,
}) {
  // strings

  const label = "Ajouter la légende au PDF";

  // handlers

  async function handleClick() {
    try {
      const img = await captureRefAsImageAsync(componentRef);
      editorPdf.addImageAnnotation(img, positionInPdf);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Box sx={{color: "text.secondary", p: 1}}>
      <Typography
        size="small"
        onClick={handleClick}
        color="inherit"
        sx={{cursor: "pointer", fontSize: 12}}
      >
        {label}
      </Typography>
    </Box>
  );
}
