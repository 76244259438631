function getRawMeasurementsBySubjectByAuthor(rawMeasurements, author) {
  const rawMs = rawMeasurements.filter((m) => m.author === author);
  const byS = {};
  rawMs.forEach((rawM) => {
    if (!byS[rawM.subject]) {
      byS[rawM.subject] = [rawM];
    } else {
      byS[rawM.subject].push(rawM);
    }
  });
  return byS;
}
export default function getRawMeasurementSubjectsOverviewByAuthor(
  rawMeasurements,
  author
) {
  const byS = getRawMeasurementsBySubjectByAuthor(rawMeasurements, author);

  const arr = Object.entries(byS).map(([subject, rawMs]) => {
    return {
      subject,
      count: rawMs.length,
      name: subject ? subject : "-",
      color: rawMs[0].color,
      drawingShape: rawMs[0].drawingShape,
      dotted: rawMs[0].dotted,
    };
  });
  return arr.sort((a, b) => a.name.localeCompare(b.name));
}
