import {nanoid} from "@reduxjs/toolkit";

import getTypesFromWorksheet from "Features/excel/utils/getTypesFromWorksheet";
import createExcelWorkbookFromTemplateUrl from "Features/excel/utils/createExcelWorkbookFromTemplateUrl";
import createExcelWorkbookFromFile from "Features/excel/utils/createExcelWorkbookFromFile";

export default async function loadTypesFromExcel({url, file}) {
  // types

  const elementTypes = [];

  // load workbook

  let workbook;
  if (url) workbook = await createExcelWorkbookFromTemplateUrl({url});
  if (file) workbook = await createExcelWorkbookFromFile({file});

  // load types
  const worksheets = workbook.worksheets;
  for (let worksheet of worksheets) {
    const worksheetName = worksheet.name;
    if (worksheetName !== "Couleurs") {
      const types = await getTypesFromWorksheet(worksheet);
      elementTypes.push(
        ...types.map((t) => ({...t, group: worksheetName, id: nanoid()}))
      );
    }
  }
  // return
  return elementTypes;
}
