import React from "react";

import {Grid, Box} from "@mui/material";

import SelectOptionsWithObjects from "Components/SelectOptionsWithObjects";
import FieldTextBasic from "Features/kvform/components/FieldTextBasic";

export default function FieldSubjectTypeVar({code, value, onChange}) {
  // strings

  const HS = "H";
  const labelHS = "H (m)";

  // helpers

  const typeVars = [{id: "H", code: "H", name: HS, label: labelHS}];

  // helpers - options

  const options = typeVars;

  let option = typeVars.find((t) => t.code === code);
  if (!option) option = {};

  // handlers

  function handleTypeVarChange(typeVar) {
    onChange({value, code: typeVar.code});
  }

  function handleTypeVarValueChange(value) {
    onChange({code, value});
  }

  return (
    <Grid container sx={{width: 1}}>
      <Grid item sx={{display: "flex", alignItems: "center"}} xs={6}>
        <SelectOptionsWithObjects
          option={option ? option : null}
          options={options}
          onChange={handleTypeVarChange}
        />
      </Grid>
      <Grid item sx={{pl: 1, display: "flex", alignItems: "center"}} xs={6}>
        <FieldTextBasic
          label={option?.label}
          value={value ? value : ""}
          onChange={handleTypeVarValueChange}
        />
      </Grid>
    </Grid>
  );
}
