import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Chip, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";
import {setSelectedRessourceGroup as selectGroupEditor} from "Features/ressources/ressourcesSlice";
import {setSelectedRessourceGroup as selectGroupViewer} from "Features/measurements/measurementsSlice";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

export default function SelectorRessourceGroupInScope({
  scene,
  viewer,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // data

  let groups = useRessourceGroupsByScene(scene);
  const groupEditor = useSelector((s) => s.ressources.selectedRessourceGroup);
  const groupViewer = useSelector((s) => s.measurements.selectedRessourceGroup);
  const ressources = useRessourcesByScene(scene);
  const is3dEnabled = useSelector((s) => s.viewer3D.is3dEnabled);
  // const models = useSelector((s) => s.viewer3D.models);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const [isFirstMounted, setIsFirstMounted] = useState(false);

  const open = Boolean(anchorEl);

  // helper - groups

  // WARNING : remove ressource groups starting with underscore
  groups = groups.filter((g) => !g?.startsWith("_"));

  // helper - label

  const group = viewer ? groupViewer : groupEditor;
  const label = group ? group : "-?-";
  const multiG = groups.length > 1;

  // handlers

  function handleMoreClick(e) {
    if (multiG) {
      setAnchorEl(e.currentTarget);
    }
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  async function handleChange(group) {
    if (viewer) {
      const usedRessources = new Set(
        ressources.filter((r) => r.group === group).map((r) => r.id)
      );
      caplaEditor?.editor3d?.clear();
      await caplaEditor?.measDataManager?.setModelsFromGroup(
        group,
        "RESSOURCES",
        is3dEnabled,
        usedRessources
      );
      dispatch(selectGroupViewer(group));
    } else dispatch(selectGroupEditor(group));
    setAnchorEl(null);
  }

  // effect

  useEffect(() => {
    if (!isFirstMounted && groups?.length > 0 && !group) {
      if (viewer) dispatch(selectGroupViewer(groups[0]));
      else dispatch(selectGroupEditor(groups[0]));
      setIsFirstMounted(true);
    }
  }, [isFirstMounted, groups?.length]);

  return (
    <>
      {viewer && (
        <Tooltip title={label}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: multiG ? "pointer" : "default",
              bgcolor: "background.default",
              height: "28px",
              borderRadius: "16px",
              px: 1,
              width: 1,
            }}
            onClick={handleMoreClick}
          >
            <Typography
              variant="body2"
              noWrap
              sx={{
                //textOverflow: "ellipsis",
                minWidth: 0,
                whiteSpace: "nowrap",
                //overflow: "hidden",
                //maxWidth: "180px",
              }}
            >
              {label}
            </Typography>
            {multiG && <Down sx={{ml: 1}} />}
          </Box>
        </Tooltip>
      )}
      {!viewer && (
        <Chip
          label={label}
          deleteIcon={<Down />}
          onDelete={handleMoreClick}
          size="small"
        />
      )}
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {groups.map((gr) => {
          const selected = gr === group;
          return (
            <MenuItem
              key={gr}
              onClick={() => handleChange(gr)}
              selected={selected}
            >
              <Typography variant="body2">{gr}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
