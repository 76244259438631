// Take all the polygons of an array of measurements and change their height.
// If the direction is UP the new zInf is at zSup.

export default function flattenSpaces({measurements, params}) {
  const {height, direction} = params;
  return measurements.map((m) => {
    if (m.drawingShape === "POLYGON") {
      const newM = {...m, height};
      if (direction === "UP") {
        if (!m.zFrom || m.zFrom === "offset") {
          newM.offset = m.offset ? m.offset + m.height : m.height;
          newM.zInf += newM.offset;
          newM.zSup = newM.zInf + height;
        } else if (m.zFrom === "zInf") {
          newM.zInf = m.zInf + m.height;
        } else if (m.zFrom === "zSup") {
          newM.zSup = m.zSup + height;
        }
        newM.path3d3 = m.path3d3?.map((p) => ([p[0], p[1] + m.height, p[2]]));
      }
      return newM;
    } else {
      return {...m};
    }
  });
}