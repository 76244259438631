import {MathUtils} from "three";

export default class ObjModel {
  object;
  constructor({editor3d, model}) {
    this.modelId = model.id;
    this.url = model.url;
    this.editor3d = editor3d;
  }

  static async computeInitialModel({file, sceneClientId}) {
    const url = URL.createObjectURL(file);
    const position = {x: 0, y: 0, z: 0};
    const rotation = {x: 0, y: 0, z: 0};
    const name = file.name;

    const model = {
      id: MathUtils.generateUUID(),
      sceneClientId,
      name,
      url,
      type: "OBJ",
      rotation,
      position,
    };

    return model;
  }

  loadObj() {
    this.editor3d?.objLoader.load(this.url, (obj) => {
      this.object = obj;

      this.object.layers.enable(1);

      this.object.children[0].layers.enable(1);

      console.log("load new obj", this.object);

      this.editor3d?.scene.add(this.object);
      this.editor3d?.entities.push(this);
      this.editor3d?.objectEntities.push(this);
    });
  }
}
