import {Box} from "@mui/material";

import ButtonMoreInLeftPanel from "./ButtonMoreInLeftPanel";
import useSceneModule from "Features/navigation/useSceneModule";

import TabSelectorForEditMeasurementsInLeftPanel from "Features/measurements/components/TabSelectorForEditMeasurementsInLeftPanel";

export default function SectionSubHeaderInLeftPanel({
  caplaEditor,
  scene,
  viewer,
}) {
  const sceneModule = useSceneModule();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        py: 2,
      }}
    >
      <Box sx={{minWidth: "30px"}} />

      <Box sx={{flexGrow: 1, display: "flex", justifyContent: "center"}}>
        {["EDIT_MEASUREMENTS", "MEASUREMENTS"].includes(sceneModule) && (
          <TabSelectorForEditMeasurementsInLeftPanel />
        )}
      </Box>

      {/* <ButtonMoreInLeftPanel
        caplaEditor={caplaEditor}
        scene={scene}
        viewer={viewer}
      /> */}
    </Box>
  );
}
