import {useDispatch} from "react-redux";
import {createBimapp} from "../bimappsSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {unwrapResult} from "@reduxjs/toolkit";

export default function useAddSceneBimapp() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const addSceneBimapp = async (bimapp) => {
    const result = await dispatch(createBimapp({bimapp, accessToken}));
    if (result) {
      const {item} = unwrapResult(result);
      return item;
    }
  };

  return addSceneBimapp;
}
