import {Box} from "@mui/material";

import ButtonAddInputs from "./ButtonAddInputs";

export default function ToolbarInputs({editor3d}) {
  return (
    <Box sx={{width: 1, p: 1}}>
      <ButtonAddInputs editor3d={editor3d} />
    </Box>
  );
}
