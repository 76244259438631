import {Box, Typography} from "@mui/material";

import {Palette} from "@mui/icons-material";
import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";
import useEditPackage from "Features/elementPackages/hooks/useEditPackage";
import useLastPackage from "Features/elementPackages/useLastPackage";

export default function ContainerCallToActionEditMeasurementsInTopBar({scene}) {
  // strings

  const editS = "Repérer des éléments";

  // data

  const selectedMeasurementsModel = useLastPackage();
  const editPackage = useEditPackage(scene);

  // handlers

  function handleClick() {
    editPackage(selectedMeasurementsModel?.id);
  }
  return (
    <Box
      sx={{
        height: 28,
        borderRadius: "14px",
        bgcolor: "warning.main",
        color: "common.white",
        px: 1,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Palette sx={{fill: "common.white", mr: 1, fontSize: 16}} />
      <Typography sx={{fontSize: 12}} noWrap>
        {selectedMeasurementsModel?.name}
      </Typography>
    </Box>
  );
}
