import React, {useRef, useEffect, useState} from "react";

import {nanoid} from "@reduxjs/toolkit";

import {useSelector, useDispatch} from "react-redux";
import {setShowToolAddElement} from "Features/viewer3D/viewer3DSlice";
import useObjectsets from "Features/objectsets/useObjectsets";

import {Box} from "@mui/material";
import theme from "Styles/theme";

// import GoogleMapReact from "google-map-react";

export default function GoogleMap({
  editor,
  scene,
  mapEditorIsCreated,
  mapInitOptions,
}) {
  const dispatch = useDispatch();

  // helpers - init

  const mapOptions = {
    zoom: mapInitOptions.zoom + 1,
    center: {lat: mapInitOptions.lat, lng: mapInitOptions.lng},
  };
  const createMapOptions = (map) => ({
    zoomControl: false,
    mapTypeControl: true,
    fullscreenControl: false,
    streetViewControl: true,

    mapTypeControlOptions: {
      //style: map.MapTypeControlStyle.DROPDOWN_MENU,
      mapTypeIds: ["roadmap", "hybrid"],
      position: map.ControlPosition.TOP_RIGHT,
    },
  });

  // helpers - on Load

  const handleApiLoaded = (map, maps) => {
    editor.mapEditor.initGmapEditor({map, maps});
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "common.background",
        //zIndex: 30,
      }}
    >
      {/* {mapEditorIsCreated && (
        // <GoogleMapReact
        //   bootstrapURLKeys={{
        //     key: process.env.REACT_APP_GMAP_KEY,
        //     libraries: ["geometry"],
        //   }}
        //   defaultCenter={mapOptions.center}
        //   defaultZoom={mapOptions.zoom}
        //   yesIWantToUseGoogleMapApiInternals
        //   onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
        //   options={createMapOptions}
        // ></GoogleMapReact>
      )} */}
    </Box>
  );
}
