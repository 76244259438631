import {useSelector} from "react-redux";

export default function useModelSyncStatus(modelId) {
  const modelsSyncStatus = useSelector((s) => s.viewer3D.modelsSyncStatus);
  const model = useSelector((s) => s.viewer3D.models).find(
    (m) => m.id === modelId
  );
  const modelSyncStatus = modelsSyncStatus.find((s) => s.modelId === modelId);
  const detailedModels = useSelector((s) => s.viewer3D.detailedModels);

  if (!modelId) {
    return null;
  } else if (detailedModels.includes(modelId)) {
    return "detailed";
  } else if (
    modelSyncStatus === "loading" ||
    modelSyncStatus === "downloading"
  ) {
    return modelSyncStatus;
  } else if (!model.url && model.fileRemoteUrl) {
    return "to download";
  } else if (model.url && (model.type === "IFC " || model.type === "IMAGE")) {
    return "to load";
  }
}
