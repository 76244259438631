import { Card, CardContent, CardActions, Typography, Button } from "@mui/material";
// import makeStyles from '@mui/styles/makeStyles';

export default function CardRotationConfig({onClose}) {
  // handlers

  function handleCancelClick() {
    onClose();
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="body2">Rotation</Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleCancelClick}
        >
          Annuler
        </Button>
      </CardActions>
    </Card>
  );
}
