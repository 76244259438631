import React, {useState, useEffect} from "react";

import {InputBase, Box} from "@mui/material";

import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import {Event as DateIcon} from "@mui/icons-material";

import frLocale from "date-fns/locale/fr";

export default function FieldProdDeadline({value, onChange, locked}) {
  // state

  const initTempValue = value ? value : null;
  const [tempValue, setTempValue] = useState(initTempValue);

  useEffect(() => setTempValue(initTempValue), [initTempValue]);

  // helpers

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  // handlers

  function handleChange(date) {
    setTempValue(date);

    if (!date) {
      onChange(null);
    } else if (dateIsValid(date)) {
      let _date = new Date(date);
      _date = _date.toISOString();
      onChange(_date);
    }
  }

  return (
    <Box>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <DatePicker
          disabled={locked}
          value={tempValue}
          onChange={handleChange}
          components={{
            OpenPickerIcon: DateIcon,
          }}
          format="DD-MM-YYYY"
          renderInput={({inputRef, inputProps, InputProps}) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                //svg: {width: "16px", height: "16px"},
                pr: 1,
                width: 120,
              }}
            >
              <InputBase
                ref={inputRef}
                {...inputProps}
                sx={{fontSize: (theme) => theme.typography.body2, width: 1}}
              />
              {InputProps?.endAdornment}
            </Box>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
