import {useSelector, useDispatch} from "react-redux";
import {setSelectedFilterTab} from "../filtersSlice";

import {Box} from "@mui/material";
import ContainerFilterTab from "./ContainerFilterTab";

export default function SectionSelectorFilterTab({selectedFilterTab}) {
  const dispatch = useDispatch();

  //

  const filtersByTab = useSelector((s) => s.filters.filtersByTab);

  //

  const tabs = [
    "VERSIONS",
    "INPUTS",
    "PACKAGES",
    "ZONES",
    "ELEMENT_TYPES",
    "RESSOURCES",
    "SECTORS",
    "ROOMS",
    "PHASES",
  ];

  function handleTabClick(tab) {
    dispatch(setSelectedFilterTab(tab));
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        p: 1,
        py: 4,
        minWidth: 0,
        "&>*:not(last-child)": {mr: 1, mb: 1},
      }}
    >
      {tabs.map((tab) => {
        const filter = filtersByTab[tab];
        const exclude = filter?.exclude;
        const count = Array.isArray(filter?.ids) ? filter.ids.length : "none";
        return (
          <ContainerFilterTab
            key={tab}
            tab={tab}
            selectedTab={selectedFilterTab}
            onClick={handleTabClick}
            count={count}
            exclude={exclude}
          />
        );
      })}
    </Box>
  );
}
