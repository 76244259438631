import { useEffect } from "react";
import {useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

import SelectorDrawingShapeFast from "./SelectorDrawingShapeFast";
import SelectorColorFast from "./SelectorColorFast";
import FieldsGeometryFast from "./FieldsGeometryFast";
import FieldElementTypeNameFast from "./FieldElementTypeNameFast";
// import FieldElementTypeCodeFast from "./FieldElementTypeCodeFast";
// import FieldElementTypeNumFast from "./FieldElementTypeNumFast";
import FieldElementTypeUnitFast from "./FieldElementTypeUnitFast";
import AutocompleteFreesolo from "Components/AutocompleteFreesolo";

export default function FormElementTypeFast({elementType, groups, onChange}) {
  // strings

  const nameS = "Comment appelez vous ces éléments ?";
  const shapeS = "Forme";
  const colorS = "Couleur";
  const dimsS = "Géométrie";
  const groupS = "Groupe";

  // helpers

  const name = elementType?.name;
  const group = useSelector((s) => s.measurements.selectedElementTypeGroup);
  const unit = elementType?.unit;
  const drawingShape = elementType?.drawingShape;
  const color = elementType?.color;
  const dims = {
    dim1: elementType?.dim1,
    dim2: elementType?.dim2,
    height: elementType?.height,
  };
  
  // handlers
  function handleGroupChange(group) {
    if (!group) group = "A définir";
    onChange({...elementType, group});
  }
  // function handleNumChange(num) {
    //   onChange({...elementType, num, code: num});
    // }
  function handleUnitChange(unit) {
    onChange({...elementType, unit});
  }
  function handleNameChange(name) {
    onChange({...elementType, name});
  }
    // function handleCodeChange(code) {
      //   onChange({...elementType, code});
      // }
  function handleDrawingShapeChange(drawingShape) {
    onChange({...elementType, drawingShape});
  }
  function handleColorChange(color) {
    onChange({...elementType, color});
  }
  function handleDimsChange(dims) {
    onChange({...elementType, ...dims});
  }

  useEffect(() => {
    handleGroupChange(group);
  }, []);

  return (
    <Box sx={{width: 1}}>
      <Box
        sx={{
          width: 1,
          my: 4,
        }}
      >
        <Typography variant="body2" color="text.secondary" sx={{mb: 1.5}}>
          {nameS}
        </Typography>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Box sx={{width: 200, mr: 1}}>
            {/* <AutocompleteElementTypeGroupFast group={group} groups={groups} onChange={handleGroupChange} /> */}
            <AutocompleteFreesolo initValue={group} values={groups} onChange={handleGroupChange} label={groupS} />
          </Box>
          {/* <Box sx={{width: 100, mr: 1}}>
            <FieldElementTypeNumFast num={num} onChange={handleNumChange} />
          </Box> */}
          <Box sx={{width: 300, mr: 1}}>
            <FieldElementTypeNameFast name={name} onChange={handleNameChange} />
          </Box>
          {/* <Box sx={{width: 100, mr: 1}}>
            <FieldElementTypeCodeFast code={code} onChange={handleCodeChange} />
          </Box> */}
          <Box sx={{width: 100}}>
            <FieldElementTypeUnitFast unit={unit} onChange={handleUnitChange} />
          </Box>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary" sx={{mb: 1}}>
        {shapeS}
      </Typography>
      <SelectorDrawingShapeFast
        drawingShape={drawingShape}
        onChange={handleDrawingShapeChange}
      />
      <Box sx={{width: 1, mt: 4}}>
        <Typography variant="body2" color="text.secondary" sx={{mb: 1}}>
          {colorS}
        </Typography>
        <SelectorColorFast color={color} onChange={handleColorChange} />
      </Box>
      {drawingShape && (
        <Box sx={{width: 1, mt: 3}}>
          <Typography variant="body2" color="text.secondary" sx={{mb: 1}}>
            {dimsS}
          </Typography>
          <FieldsGeometryFast
            dims={dims}
            onChange={handleDimsChange}
            drawingShape={drawingShape}
          />
        </Box>
      )}
    </Box>
  );
}
