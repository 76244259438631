import {useSelector} from "react-redux";
import useProgressRecordsByScene from "./useProgressRecordsByScene";

import {format} from "date-fns";
import {fr} from "date-fns/locale";
import useSelectedProgressListing from "./useSelectedProgressListing";

export default function useProgressRecordsForDataGrid(scene) {
  // data

  const listings = useSelector((s) => s.listings.data);

  const allProgressRecords = useProgressRecordsByScene(scene);
  console.log("allPRogressRecords", allProgressRecords);

  const selectedProgressListing = useSelectedProgressListing(scene);

  // helpers • progressRecords

  const progressRecords = allProgressRecords.filter(
    (r) => r.listing === selectedProgressListing?.id
  );
  // helpers

  function getListingName(listingId) {
    const listing = listings.find((l) => l?.id === listingId);
    return listing?.name;
  }

  // helpers

  function getDateTime(dateData) {
    if (dateData && dateData !== "-" && dateData !== "null") {
      const date = new Date(dateData);
      const formattedDate = format(date, "dd/MM/yy", {locale: fr});
      return `${formattedDate}`;
    }
  }

  // export
  const rows = progressRecords
    .sort((a, b) => a.endAt.localeCompare(b.endAt))
    .map((progressRecord) => ({
      id: progressRecord.id,
      list: getListingName(progressRecord.listing),
      name: progressRecord.name,
      start: getDateTime(progressRecord.startAt),
      end: getDateTime(progressRecord.endAt),
      createdBy: progressRecord.createdBy?.email,
    }));

  return rows;
}
