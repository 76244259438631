import {useDispatch} from "react-redux";
import {createArticle} from "../articlesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {unwrapResult} from "@reduxjs/toolkit";
import {updateArticlesGroup} from "../articlesSlice";

export default function useCreateArticle() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const create = async ({sceneId, article, sortedArticles}) => {
    try {
      const result = await dispatch(createArticle({accessToken, article}));

      const groupUpdates = {
        id: article.articlesGroupId,
        sceneId,
      };
      if (sortedArticles) groupUpdates.sortedArticles = sortedArticles;
      await dispatch(updateArticlesGroup({accessToken, updates: groupUpdates}));
    } catch (e) {
      console.error("[error] useCreateArticle", e);
    }
  };

  return create;
}
