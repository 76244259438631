import React from "react";

import {Grid, Typography} from "@mui/material";

import {getNameFromEmail} from "Features/profile/utils";

import {format} from "date-fns";

export default function GridItemColoringDetailed({coloring}) {
  // helpers

  let user = coloring?.createdBy?.profile?.name;
  if (!user) user = getNameFromEmail(coloring?.createdBy?.email);

  const createdAt = coloring?.createdAt;
  let date = new Date(createdAt);
  date = format(date, "dd/MM/yyyy HH:mm");

  let elements = coloring.model.measurementsData?.measurements.length;
  if (!elements) elements = 0;

  return (
    <Grid container sx={{width: 1, p: 0.5}}>
      <Grid item xs={7}>
        <Typography noWrap variant="body2">
          {user}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">{date}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2" align="right">
          {elements}
        </Typography>
      </Grid>
    </Grid>
  );
}
