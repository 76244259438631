import {useState} from "react";

import {Typography, Box} from "@mui/material";

import FileSelectorButton from "Features/files/components/FileSelectorButton";
import ButtonEnhanceReportFromWorksheet2 from "./ButtonEnhanceReportFromWorksheet2";

export default function LogikutchReportEnhancer() {
  // strings

  const selectS = "Sélectionnez un rapport Logikutch (.xlsx)";

  // state

  const [src, setSrc] = useState(null);

  // helpers

  const srcName = src?.name;

  // handlers

  function handleFileChange(file) {
    setSrc(file);
  }

  return (
    <Box sx={{width: 1, p: 2}}>
      <Box sx={{bgcolor: "background.default", p: 2, width: 1}}>
        <Box
          sx={{display: "flex", width: 1, justifyContent: "center", height: 30}}
        >
          <Typography variant="body2" noWrap>
            {srcName}
          </Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", width: 1}}>
          <FileSelectorButton
            onFileChange={handleFileChange}
            buttonName={selectS}
            accept={".xlsx"}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <ButtonEnhanceReportFromWorksheet2 file={src} />
      </Box>
    </Box>
  );
}
