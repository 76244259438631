// Take all the polygons of an array of measurements and transform them
// to polylines with specified width.

export default function wallsFromSpaces({measurements, params}) {
  const {dim1, height} = params;
  return measurements.map((m) => {
    if (m.drawingShape === "POLYGON") {
      const newM = {
        ...m,
        drawingShape: "POLYLINE",
        dim1,
        height: height && !m.affectedProps?.includes("height") ? 
          height : m.height,
        centerLine: m.path3D,
      };
      if (m.height !== newM.height) {
        if (m.zFrom !== "zSup") {
          newM.zSup = newM.zInf + newM.height;
        } else {
          newM.zInf = newM.zSup - newM.height;
        }
      }
      return newM;
    } else {
      return {...m};
    }
  });
}