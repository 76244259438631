import getQtyWithDec from "./getQtyWithDec";

export default function getRessourceQuantityLabel(ressource, options) {
  if (!ressource) {
    return {quantity: null, label: "-?-"};
  }
  const showQtyNet = options?.showQtyNet;

  const unit = ressource.unit ?? "(?)";

  let qty = showQtyNet ? ressource.qtyNet : ressource.qtyRaw;

  if (typeof ressource?.hardcodedQty === "number") {
    qty = ressource?.hardcodedQty;
  }

  qty = getQtyWithDec(qty, ressource.dec);

  const label = `${qty} ${unit}`;

  return {quantity: qty, label};
}
