import {Box, Typography, IconButton} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import {useKvfTranslationMap} from "Features/kvform/hooks/useKvfTranslationMap";

export default function FieldSectionQuantities({measurement, editorPdf}) {
  const tMap = useKvfTranslationMap();

  // strings

  const title = tMap["quantities"];

  // handlers

  function handleClick() {
    const mnger = editorPdf?.annotationsManager.measurementsPdfManager;
    mnger?.setIsEditing(true);
    mnger?.updateAnnotation(measurement);
  }
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: "background.default",
        px: 1,
      }}
    >
      <Typography sx={{fontSize: 13, fontWeight: "bold"}}>{title}</Typography>
      <IconButton size="small" onClick={handleClick}>
        <Refresh fontSize="small" />
      </IconButton>
    </Box>
  );
}
