import {useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

export default function CardHelperZ() {
  // data

  const pointerPosition = useSelector((s) => s.viewer3D.pointerPosition);
  console.log("AAA", pointerPosition);

  const show = useSelector((state) => state.viewer3D.pointerMode);

  // helper

  const positionIsNumber =
    typeof pointerPosition?.y === "number" && !isNaN(pointerPosition?.y);
  const label = positionIsNumber
    ? `z = ${pointerPosition.y.toFixed(3)} m`
    : "-?-";

  return (
    <Box
      sx={{
        bgcolor: "white",
        px: 0.5,
        borderRadius: "8px",
        display: show ? "flex" : "none",
        ml: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="body2">{label}</Typography>
    </Box>
  );
}
