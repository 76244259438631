import AzureMapEditor from "./AzureMapEditor";
import GoogleMapEditor from "./GoogleMapEditor";

export default class MapEditor {
  editor;
  azmEditor;
  gmapEditor;
  onClick;
  mapProvider; // related to viewer state mapProvider (active one), GOOGLE or AZURE

  constructor({editor, onClick}) {
    this.editor = editor;
    this.isCreated = true; // used for react effect dependencies
    this.onClick = onClick;
    this.mapProvider = "AZURE";
  }

  // map providers

  initAzmEditor({map}) {
    this.azmEditor = new AzureMapEditor({
      map,
      editor: this.editor,
      onClick: this.onClick,
    });
  }
  initGmapEditor({map, maps}) {
    this.gmapEditor = new GoogleMapEditor({map, maps, editor: this.editor});
  }

  setMapProvider(p) {
    this.mapProvider = p;
  }

  // pointer

  enablePointer() {
    this.azmEditor.enablePointer();
  }
  disablePointer() {
    this.azmEditor.disablePointer();
  }

  // map

  goTo(latLng) {
    if (this.mapProvider === "AZURE") {
      this.azmEditor.goTo(latLng);
    } else if (this.mapProvider === "GOOGLE") {
      this.gmapEditor.goTo(latLng);
    }
  }

  getSize() {
    if (this.mapProvider === "AZURE") {
      return this.azmEditor.getSize();
    } else if (this.mapProvider === "GOOGLE") {
      const bounds = this.gmapEditor.getBounds();
      console.log("bounds", bounds);
      return this.azmEditor.getBoundsSize(bounds);
    }
  }

  getCenterPosition(north) {
    if (this.mapProvider === "AZURE") {
      return this.azmEditor.getCenterPosition(north);
    } else if (this.mapProvider === "GOOGLE") {
      const latLng = this.gmapEditor.getCenter();
      console.log("latLng", latLng);
      return this.azmEditor.latLngToCoord(latLng, north);
    }
  }

  // Azure To Google

  azmToGmap() {
    const latLng = this.azmEditor.getCenter();
    const zoom = this.azmEditor.getZoom();
    this.gmapEditor.setZoom(zoom + 1);
    this.gmapEditor.setCenter(latLng);
  }

  gmapToAzm() {}

  // Exports

  async getScreenshotCanvas() {
    if (this.mapProvider === "AZURE") {
      return await this.azmEditor.getScreenshotCanvas();
    } else if (this.mapProvider === "GOOGLE") {
      return await this.gmapEditor.getScreenshotCanvas();
    }
  }
}
