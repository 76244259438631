import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ButtonUpdate3D from "./ButtonUpdate3D";
import ButtonMoreTools from "./ButtonMoreTools";
import ButtonDownloadBimPdf from "./ButtonDownloadBimPdf";

import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useMeasurementsForDataGrid from "../useMeasurementsForDataGrid";
import useMeasurementsByElementTypeForDataGrid from "../useMeasurementsByElementTypeForDataGrid";
import useSelectedMeasurements from "../useSelectedMeasurements";
import useMeasurementsByRoomForDatagrid from "../useMeasurementsByRoomForDatagrid";
import useMeasurementsWithRessourcesForDatagrid from "../useMeasurementsWithRessourcesForDatagrid";
import useMeasurementsByRessourceForDatagrid from "../useMeasurementsByRessourceForDatagrid";

import ButtonDownloadExcelFromTemplate from "Features/excel/components/ButtonDownloadExcelFromTemplate";
// import ButtonDownloadPdf from "Features/pdf/components/ButtonDownloadPdf";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function ToolbarMeasurementsMain({
  scene,
  caplaEditor,
  isEditMode,
  viewer,
}) {
  const mode = isEditMode ? "ONE_MODEL" : "MULTI_MODELS";

  // string

  const fileNameElements = "elements.xlsx";

  // data

  const [fms] = useFilteredSceneMeasurements(scene, mode, false);

  const measurements = useMeasurementsForDataGrid(scene, mode);
  const rooms = useRoomsByScene(scene);

  const byRoom = useMeasurementsByRoomForDatagrid(scene, mode);
  const byType = useMeasurementsByElementTypeForDataGrid(scene, mode);
  const byRessource = useMeasurementsByRessourceForDatagrid(scene, mode);
  const measurementsWithRessources = useMeasurementsWithRessourcesForDatagrid(
    scene,
    mode
  );

  const selectedMeasurements = useSelectedMeasurements();

  const os = useSelector((s) => s.ui.openSections);

  // helpers - selection

  const selectedMeasurement = selectedMeasurements[0];

  // helpers - show download pdf

  const showDownloadPdfButton = isEditMode && os.fixedViewersBox;

  // helpers - excel

  let data = {
    measurements,
    measurementsWithRessources,
    byRessource,
    byType,
    byRoom,
    rooms,
  };
  let template = "ALL";
  let fileName = fileNameElements;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 2,
        justifyContent: "space-between",
        height: "60px",
      }}
    >
      {!selectedMeasurement && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          {/* <ButtonDialogCreateMeasurement scene={scene} /> */}

          <ButtonDownloadExcelFromTemplate
            template={template}
            data={data}
            fileName={fileName}
          />

          {/* {false && showDownloadPdfButton && (
            <ButtonDownloadPdf editorPdf={caplaEditor?.editorPdf} />
          )} */}
          {showDownloadPdfButton && (
            <ButtonDownloadBimPdf
              editorPdf={caplaEditor?.editorPdf}
              scene={scene}
              viewer={viewer}
            />
          )}
          {os.fixedViewersBox && (
            <ButtonUpdate3D editorPdf={caplaEditor?.editorPdf} measurements={fms} />
          )}

          {isEditMode && <ButtonMoreTools caplaEditor={caplaEditor} scene={scene} />}
        </Box>
      )}
      {selectedMeasurement && (
        <Box sx={{display: "flex", alignItems: "center", "&>*": {mr: 1}}}>
          <ButtonDownloadExcelFromTemplate
            template={template}
            data={data}
            fileName={fileName}
          />
          {/* <ButtonDialogEditMeasurement
            caplaEditor={caplaEditor}
            measurement={selectedMeasurement}
            scene={scene}
          />
          <ButtonDeleteMeasurement
            caplaEditor={caplaEditor}
            measurement={selectedMeasurement}
          /> */}
        </Box>
      )}
    </Box>
  );
}
