import {useDispatch} from "react-redux";
import {useState, useEffect} from "react";

import useArticlesByGroup from "../hooks/useArticlesByGroup";
import {updateArticlesTemp, resetSubArticles} from "../articlesSlice";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";

import {Dialog, DialogContent, Button, Typography} from "@mui/material";

import getUpdatesToRefreshQties from "../utils/getUpdatesToRefreshQties";

export default function DialogUpdateArticlesQties({scene, open, onClose}) {
  const dispatch = useDispatch();

  // strings

  const saveS = "Mettre à jour";

  // data

  const group = useSelectedArticlesGroup();
  const articles = useArticlesByGroup(scene, group);
  const elementTypesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(
    scene,
    {
      sortElementTypesByNum: true,
      filterByTypesGroupsIds: group?.typesGroupsIds,
      filterByScope: true,
    }
  );

  // state

  const [batchUpdates, setBatchUpdates] = useState([]);

  useEffect(() => {
    setBatchUpdates(getUpdatesToRefreshQties(articles, elementTypesGroups));
  }, [open]);

  // helpers

  const description = `Nombre d'articles modifiés : ${batchUpdates.length}`;

  // handlers

  function handleClick() {
    dispatch(resetSubArticles());
    dispatch(updateArticlesTemp(batchUpdates));
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <Button onClick={handleClick}>{saveS}</Button>
    </Dialog>
  );
}
