import {useSelector} from "react-redux";

import {Paper} from "@mui/material";

import SelectorPdfModelBlack from "./SelectorPdfModelBlack";

export default function SelectorPdfModelViewer({width, maxWidth}) {
  // data

  const models = useSelector((s) => s.viewer3D.models);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // helpers

  const selectedPdfModel = models.find((m) => m.id === selectedPdfModelId);

  return (
    <Paper
      elevation={12}
      sx={{width: width ? width : 1, ...(maxWidth && {maxWidth})}}
    >
      <SelectorPdfModelBlack model={selectedPdfModel} />
    </Paper>
  );
}
