import {useSelector, useDispatch} from "react-redux";

import {Box, Switch, FormControlLabel, Typography} from "@mui/material";

import {setConfiguredModel} from "../viewer3DSlice";

export default function ButtonShowMasks({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const showPartsString = "Show parts";

  // data

  const {model, showMasks, parsedObjectEntity} = useSelector(
    (state) => state.viewer3D.configuredModel
  );
  const show = Boolean(showMasks);
  const partId = parsedObjectEntity?.partId;

  // handlers

  function handleSwitchChange(e) {
    const checked = e.target.checked;
    dispatch(
      setConfiguredModel({model, showMasks: checked, parsedObjectEntity})
    );

    // display masks
    const entity = editor3d?.getEntity(model.id);
    if (entity?.showMasks && checked) {
      partId ? entity.showMask(partId) : entity.showMasks();
    }
    if (entity?.hideMasks && !checked) {
      partId ? entity.hideMask(partId) : entity.hideMasks();
    }
  }

  return (
    <Box>
      {model?.parts?.length > 0 && (
        <FormControlLabel
          control={
            <Switch size="small" checked={show} onChange={handleSwitchChange} />
          }
          label={<Typography variant="body2">{showPartsString}</Typography>}
        />
      )}
    </Box>
  );
}
