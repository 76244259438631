import {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchSceneToSync} from "../scenesSlice";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import {
  IconButton,
  Box,
  Badge,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";
import {CloudSync, Refresh, Sync} from "@mui/icons-material";

import ListContainerClientAndRemoteSceneModels from "./ListContainerClientAndRemoteSceneModels.js/ListContainerClientAndRemoteSceneModels";

import CheckboxAutoSync from "./CheckboxAutoSync";
import useSyncActions from "Features/scenes/useSyncActions";
import useAccessToken from "Features/auth/useAccessToken";
import {triggerToken} from "Features/auth/authSlice";

export default function ButtonDialogSync({scene, size}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const titleS = "Syncro avec CAPLA 360";
  const tooltip = "Détecter les action à synchroniser";

  // data - autosync

  const autoSyncRef = useRef();
  const autoSync = useSelector((s) => s.scenes.autoSync);
  autoSyncRef.current = autoSync;

  const sceneIsSyncing = useSelector((s) => s.scenes.sceneIsSyncing);

  // state

  const [syncActions, triggerSyncAction] = useSyncActions(scene);
  const [open, setOpen] = useState(false);

  // ref

  const syncActionsRef = useRef();
  syncActionsRef.current = syncActions;

  //const isSyncingRef = useRef(false);

  // helpers

  const isSmall = size === "small";

  const actionsCount = syncActions.length;

  // helpers - autosync

  // const sync = async () => {
  //   if (!isSyncingRef.current && autoSyncRef.current) {
  //     isSyncingRef.current = true;
  //     if (syncActionsRef.current?.length > 0)
  //       console.log("[ButtonDialogSync], sync actions", syncActionsRef.current);
  //     for (let action of syncActionsRef.current) {
  //       await triggerSyncAction(action);
  //     }
  //     syncActionsRef.current = []; // used to prevent another loop of sync Actions.
  //     isSyncingRef.current = false;
  //   }
  // };

  // function triggerAutoSync() {
  //   setInterval(sync, 1000);
  // }
  // handlers

  // handler- dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }

  async function handleFetchSceneToSync() {
    dispatch(triggerToken());
    const triggeredAt = Date.now();
    if (accessToken && scene?.id) {
      await dispatch(fetchSceneToSync({accessToken, sceneId: scene.id}));
      const message = `Données du cloud récupérées pour bimbox ${scene?.name}`;
      dispatch(setSnackbarMessage({message, triggeredAt}));
    } else {
      const message =
        "Les données n'ont pas pu être récupérées. Vérifiez votre connextion.";
      dispatch(setSnackbarMessage({message, triggeredAt}));
    }
  }

  // effects

  // AUTO SYNC
  // useEffect(() => {
  //   if (Boolean(triggerSyncAction)) triggerAutoSync();
  // }, [Boolean(triggerSyncAction)]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          boxSizing: "content-box",
          border: (theme) =>
            `1px solid ${lighten(theme.palette.common.caplaBlack, 0.5)}`,
          bgcolor: lighten(theme.palette.common.caplaBlack, 0.2),
          color: lighten(theme.palette.common.caplaBlack, 0.5),
          ...(isSmall && {width: 24, height: 24}),
        }}
      >
        <Badge
          badgeContent={actionsCount}
          size="small"
          color="primary"
          variant="dot"
        >
          <IconButton
            size="small"
            onClick={handleOpenDialog}
            color="inherit"
            sx={{
              ...(sceneIsSyncing && {animation: "spin 2s linear infinite"}),
              "@keyframes spin": {
                "0%": {transform: "rotate(360deg)"},
                "100%": {transform: "rotate(0deg)"},
              },
            }}
          >
            {sceneIsSyncing ? (
              <Sync fontSize="small" />
            ) : (
              <CloudSync fontSize="small" />
            )}
          </IconButton>
        </Badge>
      </Box>
      <Dialog open={open} onClose={handleCloseDialog} fullWidth>
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{titleS}</Typography>
          <IconButton onClick={handleFetchSceneToSync}>
            <Refresh />
          </IconButton>
        </Box>
        <DialogContent>
          <CheckboxAutoSync />
          <Box sx={{display: "flex", mt: 1}}>
            <ListContainerClientAndRemoteSceneModels scene={scene} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
