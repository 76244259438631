import * as Excel from "exceljs";
import * as XLSX from 'xlsx';

export default async function createExcelWorkbookFromFile({file}) {
  const workbook = new Excel.Workbook();
  const arrayBuffer = await file.arrayBuffer();
  if (file.name?.endsWith(".xlsx")) {
    await workbook.xlsx.load(arrayBuffer);
  } else if (file.name?.endsWith(".xls")) {
    const wb = XLSX.read(arrayBuffer);
    const wbOpts = { bookType: "xlsx", type: "buffer" };
    const resp = XLSX.write(wb, wbOpts);
    await workbook.xlsx.load(resp);
  }
  return workbook;
}
