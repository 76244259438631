import {useDispatch} from "react-redux";
import {fetchSurveys} from "../surveysSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useFetchSurveys(sceneId) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const getSurveys = () => {
    if (sceneId && accessToken) {
      dispatch(fetchSurveys({accessToken, sceneId}));
    }
  };

  return getSurveys;
}
