import {useState} from "react";
import {useMsal} from "@azure/msal-react";
import {
  Button,
  MenuItem,
  Menu,
  Avatar,
  Typography,
  Divider,
} from "@mui/material";
import {ExitToApp} from "@mui/icons-material";

import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: theme.palette.primary.dark,
  },
  email: {
    padding: theme.spacing(1),
  },
}));

const SignOutButton = () => {
  const classes = useStyles();
  const {instance, accounts} = useMsal();
  const email = accounts[0].username;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    setAnchorEl(null);
    instance.logout();
  };

  return (
    <div>
      <Button
        endIcon={<ExitToApp color="primary" size="small" />}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <Avatar
          alt={email.toUpperCase()}
          className={classes.avatar}
          src="/broken-image.jpg"
        />
      </Button>
      <Menu
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <Typography className={classes.email}>{email}</Typography>
        <Divider />
        <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
      </Menu>
    </div>
  );
};

export default SignOutButton;
