import React, {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {setSelectedGroup} from "../librarySlice";

import SelectOptions from "Components/SelectOptions";
import useLibrary from "../hooks/useLibrary";
import getGroups from "../utils/getGroups";

export default function SelectorGroup() {
  const dispatch = useDispatch();
  // data

  const library = useLibrary();
  const selectedGroup = useSelector((s) => s.library.selectedGroup);

  // helpers

  const groups = getGroups(library);

  // init

  useEffect(() => {
    if (!selectedGroup && groups.length > 0)
      dispatch(setSelectedGroup(groups[0]));
  }, [selectedGroup, groups.length]);

  // handlers

  function handleChange(group) {
    dispatch(setSelectedGroup(group));
  }

  return (
    <SelectOptions
      option={selectedGroup}
      options={groups}
      onChange={handleChange}
    />
  );
}
