import useMeasurementsByRessource from "./useMeasurementsByRessource";

import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import sortByNum from "Features/ressources/utils/sortByNum";

export default function useMeasurementsByRessourceForDatagrid(scene, mode, dec, group, typeId = null) {
  const msByR = useMeasurementsByRessource(scene, mode, typeId);
  let ressources = useRessourcesByScene(scene);
  if (group) ressources = ressources.filter((r) => r.group === group);

  // handlers

  function applyDec(quantity, dec) {
    if (typeof quantity === "number" && !isNaN(quantity)) {
      return parseFloat(quantity.toFixed(dec));
    }
  }

  function getRank(num) {
    return num ? num.split(".").length : null;
  }

  const ranks = [
    ...new Set(ressources.map((r) => getRank(r.num)).filter((rank) => rank)),
  ];
  const rankMin = Math.min(...ranks);

  // result
  const result = ressources.map((r) => {
    const quantity = msByR[r.id];
    return {
      ...r,
      quantity: dec ? applyDec(quantity, dec) : applyDec(quantity, r.dec),
      rank: getRank(r.num) - rankMin,
    };
  });

  return result.sort((a, b) => sortByNum(a.num, b.num));
}
