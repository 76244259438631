import {useState} from "react";

import useTakeEditorScreenshot from "Features/viewer3D/useTakeEditorScreenshot";

import {Box, IconButton, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {
  HighlightAlt as Zone,
  TextFields as Text,
  Add,
} from "@mui/icons-material";

import DialogCreatePoi from "./DialogCreatePoi";

export default function ContainerAddPoiFrom3d({caplaEditor, scene}) {
  // data

  const takeScreenshot = useTakeEditorScreenshot({
    caplaEditor,
    scene,
    openAnnotator: false,
  });

  // data

  const [open, setOpen] = useState(false);

  // handler

  async function handleClick() {
    await takeScreenshot();
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", bgcolor: "common.white"}}>
      <DialogCreatePoi
        scene={scene}
        open={open}
        onClose={handleClose}
        poiType="POV_3D"
      />
      <IconButton onClick={handleClick}>
        <Add />
      </IconButton>
    </Box>
  );
}
