import React, {useState} from "react";

import {Grid, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";
import SelectorAutocomplete from "Components/SelectorAutocomplete";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function FormProgressListingPackagesAddItem({scene, onAdd}) {
  // strings

  const label = "Repérage";

  // data

  const packages = usePackagesByScene(scene);

  // state

  const [selectedPackage, setSelectedPackage] = useState(null);

  // handlers

  function handleChange(pack) {
    if (pack) {
      setSelectedPackage(pack);
    } else {
      setSelectedPackage(null);
    }
  }

  function handleAdd() {
    onAdd(selectedPackage);
    setSelectedPackage(null);
  }

  return (
    <Grid sx={{width: 1}} container>
      <Grid xs={10} item>
        <SelectorAutocomplete
          name={label}
          options={packages}
          value={selectedPackage}
          onChange={handleChange}
        />
      </Grid>
      <Grid xs={2} item>
        <IconButton
          size="small"
          onClick={handleAdd}
          disabled={!selectedPackage}
        >
          <Add fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
