import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import CardIsAddingMarker from "Features/markers/components/CardIsAddingMarker";
import useIsFullscreen from "Features/ui/useIsFullscreen";

export default function LayerMarkers({editorPdf, scene}) {
  // data

  const fullscreen = useIsFullscreen();
  const mode = useSelector((s) => s.viewer3D.mode);

  // helper - adding marker

  const isAddingMarker = mode === "ADDING_PDF_MARKER";

  // helper - top

  const top = fullscreen ? "16px" : "68px";

  return (
    <>
      {isAddingMarker && (
        <Box sx={{position: "absolute", top, right: "8px", zIndex: 30}}>
          <CardIsAddingMarker editorPdf={editorPdf} scene={scene} />
        </Box>
      )}
    </>
  );
}
