import {Box, Typography} from "@mui/material";

import SectionCreateFirstScene from "./SectionCreateFirstScene";

export default function SectionCallToActionCreateNewScene() {
  // string

  const noSceneS =
    "👉 Pour créer votre 1ère bimbox *, utilisez le panneau d'édition à droite.";

  const descriptionS = `* Créez une bimbox pour bimifier vos plans PDF: vues 3D, création des éléments/ouvrages, extraction de quantités et collaboration.`;

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <SectionCreateFirstScene />
    </Box>
  );
}
