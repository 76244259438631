import {useSelector} from "react-redux";

import {Box, List, Typography} from "@mui/material";

import ListItemModelLoadingStatus from "./ListItemModelLoadingStatus";

import getModelsInScope from "Features/scopes/utils/getModelsInScope";

export default function SectionModelsInScopeLoadingOverview({scope}) {
  // data

  const loadingStatusModelIdsMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );
  const models = useSelector((s) => s.viewer3D.models);

  // helpers

  const options = {measurementsOnly: true};
  const modelsInScope = getModelsInScope(models, scope, options);

  const sortedModels = modelsInScope.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Box sx={{display: "flex", width: 1, flexDirection: "column"}}>
      <List dense disablePadding sx={{width: 1}}>
        {sortedModels.map((model) => {
          return (
            <ListItemModelLoadingStatus
              key={model.id}
              model={model}
              loadingStatusModelIdsMap={loadingStatusModelIdsMap}
            />
          );
        })}
      </List>
    </Box>
  );
}
