import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import ImageListIssues from "./ImageListIssues";

import {fetchIssuesByScene} from "../issuesSlice";

// import {setImageOverlayMode} from "Features/viewer3D/viewer3DSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function ListIssuesBySceneImageGrid({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  // data

  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );

  let issues = useSelector((s) => s.issues.items).filter(
    (issue) => issue.scene === sceneId
  );

  // filter on open issues & notebook

  issues = issues.filter(
    (i) => i.isOpened && i.listing === selectedIssuesListingId
  );

  // add priority number

  issues = issues.map((issue) => {
    let priorityNumber = 1;
    if (issue.priority === "MEDIUM") priorityNumber = 2;
    if (issue.priority === "LOW") priorityNumber = 3;
    return {...issue, priorityNumber};
  });

  // sort by priority & creation date

  issues = issues
    .sort((a, b) => a.title.localeCompare(b.title))
    .sort((a, b) => a.priorityNumber - b.priorityNumber);

  useEffect(() => {
    if (accessToken && sceneId)
      dispatch(fetchIssuesByScene({sceneId, accessToken}));
  }, [accessToken, sceneId]);

  return (
    <Box>
      <ImageListIssues caplaEditor={caplaEditor} scene={scene} issues={issues} />
    </Box>
  );
}
