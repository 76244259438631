import {Box, Button, Typography, Paper} from "@mui/material";
import {lighten} from "@mui/material/styles";
import ChipDefaultLabel from "./ChipDefaultLabel";
import useSceneDefaultScopeId from "../hooks/useSceneDefaultScopeId";
import ContainerPackagesCount from "Features/elementPackages/components/ContainerPackagesCount";

export default function ContainerScopeInGrid({
  scene,
  scope,
  onOpen,
  onSeeDetail,
  hideAction,
}) {
  // strings

  const openS = "Ouvrir";
  const seeS = "Voir";

  // data

  const sceneDefaultScopeId = useSceneDefaultScopeId(scene);

  // helper

  const isDefault =
    scope?.id === sceneDefaultScopeId ||
    ("all" === scope?.id && !sceneDefaultScopeId);

  const packagesCount = scope?.data?.packageIds?.length;

  // handlers

  function handleOpenClick(e, scope) {
    e.stopPropagation();
    e.preventDefault();
    if (onOpen) onOpen(scope);
  }
  function handleSeeDetail(e, scope) {
    e.stopPropagation();
    onSeeDetail(scope);
  }

  return (
    <Box sx={{width: 350, p: 1, minWidth: 350}}>
      <Paper
        sx={{
          p: 1,
          height: 150,
          display: "flex",
          flexDirection: "column",
          bgcolor: (theme) =>
            lighten(
              theme.palette.common.caplaBlack,
              scope?.id === "all" ? 0.05 : 0.1
            ),
          color: "common.white",
          cursor: "pointer",
        }}
        elevation={6}
        onClick={(e) => handleOpenClick(e, scope)}
      >
        <Box
          sx={{
            width: 1,
            height: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{flexGrow: 1}}>
            <Box>
              <Box
                sx={{
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{fontWeight: "bold"}}>{scope?.name}</Typography>
                {isDefault && <ChipDefaultLabel />}
              </Box>
              <Typography
                variant="body2"
                //color="text.secondary"
                //sx={{whiteSpace: "pre"}}
              >
                {scope?.description}
              </Typography>
            </Box>
            {packagesCount && (
              <Box sx={{display: "flex", mt: 2}}>
                <ContainerPackagesCount count={packagesCount} />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: 1,
              color: "common.white",
            }}
          >
            <Box sx={{visibility: !hideAction ? "visible" : "hidden"}}>
              <Button
                color="inherit"
                size="small"
                onClick={(e) => handleSeeDetail(e, scope)}
              >
                {seeS}
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
