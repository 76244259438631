import {useSelector} from "react-redux";

// import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";
import useFilteredMeasurementsFromPackages from "./useFilteredMeasurementsFromPackages";

import getNumForSorting from "Utils/getNumForSorting";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import computeRessourceQuantity, {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import getItemsMapById from "Utils/getItemsMapById";

export default function useMeasurementsWithRessourcesForDatagrid(scene, mode) {
  // let [measurements] = useFilteredSceneMeasurements(scene, mode);
  let measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });
  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const zones = useZonesByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);

  if (!measurements) return [];
  
  const dontUseForQuantities = new Set(models
    .filter((m) => m.excludeFromQties)
    .map((m) => m.id)
  );
  if (dontUseForQuantities.size > 0)
    measurements = measurements.filter(
      (m) => !dontUseForQuantities.has(m.measurementsModelId)
    );

  // helpers

  const zonesById = getItemsMapById(zones);
  const roomsById = getItemsMapById(rooms);
  const sectorsById = getItemsMapById(sectors);
  const ressourcesById = getItemsMapById(ressources);
  const elementTypesById = getItemsMapById(elementTypes);

  // measurements & ressources

  const measWithRes = [];
  measurements.forEach((m) => {
    const res = m.res;
    if (!res) return;
    const elementType = elementTypesById[m.elementTypeId];
    const room = roomsById[m.roomId];
    const sector = sectorsById[m.sectorId];
    res
      .filter((r) => !r.off)
      .forEach((mr) => {
        const resId = mr.resId;
        const ressource = ressourcesById[resId];
        if (!ressource) return;
        let res = elementType?.res?.find((r) => resId === r.resId);
        if (!res) res = {resId, mode: "AUTO"}; // if formula not in elementType, by default, we compute with mode="AUTO"
        const useMeas = applyFormulaFilter(
          m,
          res?.func,
          zonesById[m.zoneId],
          room,
          sector,
          filterNoVoids
        );
        if (useMeas) {
          // let mQ = filterNoVoids ? mr.qtyRaw : mr.qtyNet;
          let mQ;
          if (!mQ) {
            mQ = computeRessourceQuantity({
              item: m,
              res,
              ressource,
              withDecimal: false,
              filterNoVoids,
            });
          }
          measWithRes.push({
            id: m.id,
            code: m.codeName,
            isVoid: m.isVoid,
            ressource_group: ressource.group,
            ressource_num: ressource.num ? ressource.num : "0",
            ressource_num_for_sorting: ressource.num
              ? getNumForSorting(ressource.num)
              : "0",
            ressource_name: ressource.name,
            ressource_quantity_unit: ressource.unit,
            ressource_quantity_value: mQ,
            sector_name: sector?.name ? sector.name : "-",
            sector_building: sector?.building ? sector.building : "-",
            sector_z: sector?.z ? sector.z : "-",
            room_sector: room?.sector ? room.sector : "-",
            room_num: room?.num ? room.num : "-",
            room_name: room?.name ? room.name : "-",
            room_type: room?.type ? room.type : "-",
            ressource_description: ressource.description,
            element_type_name: elementType?.name ? elementType.name : "-",
            material_name: m.material?.name ? m.material.name : "-",
          });
        }
      });
  });
  return measWithRes;
}
