import {useSelector, useDispatch} from "react-redux";

import {Paper, IconButton, Tooltip, Box} from "@mui/material";
import {Fullscreen, FullscreenExit} from "@mui/icons-material";

// import {setShow3D} from "Features/pdf/pdfSlice";
import {setFullscreen} from "Features/ui/uiSlice";

export default function ButtonFullScreen() {
  const dispatch = useDispatch();

  // strings

  const fullscreenS = "Fullscreen";
  const exitFullscreenS = "Exit fullscreen";

  // data

  const fullscreen = useSelector((s) => s.ui.fullscreen);

  // helpers

  const tooltipTitle = fullscreen ? exitFullscreenS : fullscreenS;
  // handlers

  function handleClick() {
    dispatch(setFullscreen(!fullscreen));
  }

  return (
    <Box>
      <Paper
        sx={{
          borderRadius: "4px",
          bgcolor: "secondary.main",
          color: "common.white",
          p: 0.5,
        }}
      >
        <Tooltip title={tooltipTitle}>
          <IconButton
            size="small"
            onClick={handleClick}
            color="inherit"
            sx={{borderRadius: "4px"}}
          >
            {fullscreen ? (
              <FullscreenExit fontSize="small" />
            ) : (
              <Fullscreen fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      </Paper>
    </Box>
  );
}
