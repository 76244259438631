import {nanoid} from "@reduxjs/toolkit";
import React, {useState, useEffect} from "react";

import useUpdateSceneVersion from "../hooks/useUpdateSceneVersion";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Edit} from "@mui/icons-material";

import FormVersion from "./FormVersion";

export default function ButtonDialogUpdateVersion({scene, version}) {
  // strings

  const editS = "Edit";

  const cancelS = "Cancel";
  const saveS = "Save";

  // data

  const updateSceneVersion = useUpdateSceneVersion(scene);

  // state

  const [tempVersion, setTempVersion] = useState(version);
  useEffect(() => setTempVersion(version), [version?.id]);

  const [open, setOpen] = useState(false);

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handleVersionChange(version) {
    const updatedVersion = {id: tempVersion.id, ...version};
    setTempVersion(updatedVersion);
  }

  function handleSave() {
    console.log("tempVersion", tempVersion);
    updateSceneVersion(tempVersion);
    setOpen(false);
  }

  function handleCancelClick() {
    setOpen(false);
  }

  return (
    <Box sx={{}}>
      <Button
        startIcon={<Edit />}
        color="inherit"
        size="small"
        onClick={handleOpenDialog}
      >
        {editS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{editS}</DialogTitle>
        <DialogContent>
          <FormVersion version={tempVersion} onChange={handleVersionChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button onClick={handleSave}>{saveS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
