import React from "react";
import {Link as LinkRouter} from "react-router-dom";

import useListingtypes from "Features/listingtypes/useListingtypes";

import {Typography, Link, Box} from "@mui/material";

export default function ListingIconName({
  listing,
  short = false,
  small = false,
}) {
  const types = useListingtypes();
  const type = types.find(({id}) => id === listing?.listingType);

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Box
        sx={{
          width: (theme) => (small ? theme.spacing(2) : "24px"),
          height: (theme) => (small ? theme.spacing(2) : "24px"),
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0.5,
          mr: 2,
        }}
        style={{background: type?.color}}
      >
        <img src={type?.imageWhite} width={"100%"} height={"100%"} />
      </Box>
      <Typography noWrap variant={small ? "caption" : "body2"}>
        {listing?.name}
      </Typography>
      {/* <Typography noWrap variant={small ? "caption" : "body1"}>
        <Link
          component={LinkRouter}
          to={`/${type.id}s/${listing.id}`}
          color="inherit"
        >
          {listing?.name}
        </Link>
      </Typography> */}
    </Box>
  );
}
