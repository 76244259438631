import React from "react";

import {Typography, Box} from "@mui/material";

import Stretch from "Features/ui/components/Stretch";

export default function SectionVoidInSelectionPanel() {
  // string

  const noSelection =
    "Sélectionnez une entité (élement, zone, secteur,...) pour voir son détail ici.";
  return (
    <Stretch>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
        }}
      >
        <Typography variant="h6" color="text.secondary" align="center">
          {noSelection}
        </Typography>
      </Box>
    </Stretch>
  );
}
