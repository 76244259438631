const templateNomenclatureMap = {
  "dim1xheight cm": {
    fields: ["dim1", "height"],
    fieldLabel: "elementTypeId", // key used to agregate items for nomenclature.
    scale: 100,
  },
  "dim1xL cm": {
    fields: ["dim1", "length"],
    scale: 100,
    fieldLabel: "elementTypeId",
  },
  "dim1 cm": {
    fields: ["dim1"],
    scale: 100,
    fieldLabel: "elementTypeId",
  },
  "diam cm": {
    fields: ["dim1"],
    scale: 100,
    fieldLabel: "elementTypeId",
  },
  "height m": {
    fields: ["height"],
    scale: 1,
    fieldLabel: "elementTypeId",
  },
  "height cm": {
    fields: ["height"],
    scale: 1,
    fieldLabel: "elementTypeId",
  },
  stairs: {
    fields: ["p1", "p2", "height", "dim1"],
    scale: 1,
    fieldLabel: "elementTypeId",
  },
  p0: {fields: ["p0"], scale: 1, fieldLabel: "elementTypeId"},
  TS: {fields: ["p1"], scale: 1, fieldLabel: "elementTypeId"},
  HA: {fields: ["p2"], scale: 1, fieldLabel: "elementTypeId"},
  p3: {fields: ["p3"], scale: 1, fieldLabel: "elementTypeId"},
};

export default templateNomenclatureMap;
