import React, {useRef, useEffect, useState} from "react";

import useHeightToBottom from "../useHeightToBottom";
import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

const Root = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "autoOverflow" &&
    prop !== "center" &&
    prop !== "height" &&
    prop !== "backgroundColor",
})(({autoOverflow, height, center, theme}) => ({
  width: "100%",
  boxSizing: "border-box",
  height,
  ...(autoOverflow && {overflowY: "auto", overflowX: "hidden"}),
  ...(center && {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
}));

export default function Stretch({
  children,
  disable = false,
  autoOverflow = false,
  center = false,
  backgroundColor,
  propDependency,
  bottomOffset = 0,
}) {
  const ref = useRef();
  const height = useHeightToBottom(ref, disable, propDependency);

  return (
    <Root
      ref={ref}
      height={height - bottomOffset}
      autoOverflow={autoOverflow}
      center={center}
    >
      {/* <div ref={ref} style={{height: height, width: "100%"}}> */}
      {Boolean(height) && (
        <Box sx={{height: "100%", width: "100%"}}>{children}</Box>
      )}
      {/* </div> */}
    </Root>
  );
}
