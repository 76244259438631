import React from "react";

import {Box, Paper, Typography} from "@mui/material";

import {format} from "date-fns";
import {fr} from "date-fns/locale";

export default function ListItemBuiltAt({builtAt, selected = false, onClick}) {
  // helpers

  function getDateTime(dateData) {
    if (dateData && dateData !== "-" && dateData !== "null") {
      const date = new Date(dateData);
      const formattedDate = format(date, "EEEE dd/MM/yyyy", {locale: fr});
      return `${formattedDate}`;
    } else {
      return "...";
    }
  }

  // helpers

  const bgcolor = selected ? "common.caplaBlack" : "common.grey";
  const color = selected ? "common.white" : "text.secondary";

  // handlers

  function handleClick() {
    onClick(builtAt);
  }

  return (
    <Paper
      sx={{
        px: 1,
        py: 0.5,
        cursor: "pointer",
        width: "fit-content",
        bgcolor,
        color,
      }}
      elevation={selected ? 12 : 1}
      onClick={handleClick}
    >
      <Typography
        sx={{fontWeight: selected ? "bold" : "normal", fontSize: 13}}
        color="inherit"
      >
        {getDateTime(builtAt)}
      </Typography>
    </Paper>
  );
}
