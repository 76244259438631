import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogAddBulkRelationsRessource from "./DialogAddBulkRelationsRessource";

import useSelectedRessource from "../hooks/useSelectedRessource";
import useDeleteSceneRessource from "../hooks/useDeleteSceneRessource";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";
import useUpdateElementTypesGroup from "Features/elementTypes/hooks/useUpdateElementTypesGroup";

export default function ButtonMoreActionsRessource({scene}) {
  const dispatch = useDispatch();

  // strings

  const deleteS = "Suppression";
  const bulkRelationsS = "Relations en masse";

  // data

  const deleteSceneRessource = useDeleteSceneRessource(scene);
  const selectedRessource = useSelectedRessource(scene);
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const types = useElementTypesBySceneProxy(scene);
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const updateElementTypesGroup = useUpdateElementTypesGroup();

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openBulkRelations, setOpenBulkRelations] = useState(false);

  // handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    deleteSceneRessource(selectedRessource);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // handlers - bulk relations

  function handleBulkRelations() {
    setOpenBulkRelations(true);
    setAnchorEl(null);
  }

  async function handleBulkRelationsConfirm(codes, formula) {
    const newRelations = new Set();
    const typesToUpdate = new Set();
    const typesGroupsToUpdate = new Set();
    const updatedRelations = {...relations};
    const resId = selectedRessource?.id;
    const codesSet = new Set();
    if (codes !== "") {
      codes.split(/\r?\n|\r|\n/g).map((c) => {
        if (c !== "") {
          codesSet.add(c);
        }
      });
    }
    if (formula !== "") {
      codesSet.forEach((c) => {
        const typeToUpdate = types.find((t) => t.code === c);
        if (typeToUpdate && typeToUpdate.groupId) {
          typesToUpdate.add(typeToUpdate.id);
          typesGroupsToUpdate.add(typeToUpdate.groupId);
          const newKey = `${typeToUpdate.id}-${resId}`;
          updatedRelations[newKey] = {
            id: newKey,
            mode: "FORMUL",
            func: formula,
            typeId: typeToUpdate.id,
            resId,
          };
          newRelations.add(newKey);
        }
      });
    }
    // typesGroupsToUpdate.forEach((tg) => {
    for (const tg of typesGroupsToUpdate) {
      const typesGroup = groups.find((g) => g.id === tg);
      if (typesGroup) {
        const editedTypes = [];
        typesGroup.elementTypes?.forEach((t) => {
          if (typesToUpdate.has(t.id)) {
            const untouchedRes = t.res
              ? t.res.filter((r) => !newRelations.has(r.id))
              : [];
            const relToAdd = [];
            newRelations.forEach((nr) => {
              if (nr.startsWith(t.id)) {
                relToAdd.push(updatedRelations[nr]);
              }
            });
            editedTypes.push({...t, res: [...untouchedRes, ...relToAdd]});
          } else {
            editedTypes.push({...t});
          }
        });
        const editedGroup = {...typesGroup, elementTypes: editedTypes};
        await updateElementTypesGroup(editedGroup);
      }
    }
    if (newRelations.size > 0)
      dispatch(setRelationsTypesRessources(updatedRelations));
    setOpenBulkRelations(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "BULK_RELATIONS",
      label: bulkRelationsS,
      handler: handleBulkRelations,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <DialogAddBulkRelationsRessource
        open={openBulkRelations}
        onConfirm={handleBulkRelationsConfirm}
        onCancel={() => {
          setOpenBulkRelations(false);
        }}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource="ressource"
      />
    </>
  );
}
