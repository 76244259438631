import {useState} from "react";
// import {useDispatch} from "react-redux";

import {Menu, MenuItem, Box, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useSelectedSector from "../hooks/useSelectedSector";
import useDeleteSceneSector from "../hooks/useDeleteSceneSector";

// import useAccessToken from "Features/auth/useAccessToken";
import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function ButtonMoreActionsSector({scene}) {
  // const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const deleteS = "Delete";

  // data

  const selectedSector = useSelectedSector(scene);
  const deleteSector = useDeleteSceneSector(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    deleteSector(selectedSector);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"sector"}
      />
    </>
  );
}
