import {useState, useRef, useEffect} from "react";
import {useDispatch} from "react-redux";

import {
  Card,
  CardContent,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";

import CaplaModel from "../js/CaplaModel";

import {setPositioningObject} from "Features/viewer3D/viewer3DSlice";

export default function ProcessCreateCapla({editor3d}) {
  const dispatch = useDispatch();
  // selector

  const options = ["Type 1", "Type 2", "Type 3"];

  // init

  const caplaRef = useRef();

  function init() {
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
    caplaRef.current = new CaplaModel();
    caplaRef.current.loadTo(editor3d?.scene);
    dispatch(setPositioningObject({editor: true}));
  }

  // local state

  const [type, setType] = useState("Type 1");

  // handlers

  function handleEditorClick(props) {
    const {sceneP} = props;
    caplaRef.current.addBox(sceneP);
  }

  // initialization

  useEffect(() => {
    init();
    return () => {
      if (editor3d) editor3d.onProcessEditorClick = () => {};
    };
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography sx={{mb: 2}}>Add capla</Typography>
        <Autocomplete
          options={options}
          value={type}
          onChange={(e, v) => setType(v)}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Type" />
          )}
        />
      </CardContent>
    </Card>
  );
}
