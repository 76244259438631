import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

import {
  createElementTypesGroup,
  setSelectedElementTypesGroupProxy,
} from "../elementTypesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import {updateScene} from "Features/scenes/scenesSlice";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import sanitizeString from "Utils/sanitizeString";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import sortByNum from "Utils/sortByNum";

import getItemsMapById from "Utils/getItemsMapById";

import {
  LibraryAdd as AddGroup,
  Add,
  Download,
  Upload,
} from "@mui/icons-material";
import {setOpenDialogFsCreateElementType} from "Features/elementTypor/elementTyporSlice";
import getElementTypesWithQtiesV2 from "../utils/getElementTypesWithQtiesV2";

export default function useActionsElementTypesMap({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const selectedGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const allTypes = useElementTypesBySceneProxy(scene, {sortByNum: true});
  const selectedTypeGroup = useSelectedElementTypesGroup(scene);
  //const ressources = useRessourcesByScene(scene);
  //const relations = useSelector((s) => s.relations.relationsTypesRessources);

  // data - measurements

  const measShowedIn3dViewer = useSelector(
    (s) => s.measurements.measShowedInViewer3d
  );

  // helpers

  const isRemote = Boolean(!selectedTypeGroup?.fromScene);
  let types = allTypes.filter(
    (t) =>
      (t.groupId && t.groupId === selectedGroup?.id) ||
      (!t.groupId && t.group === selectedGroup?.name)
  );
  //const ressourcesById = getItemsMapById(ressources);

  // handlers

  async function handleExportExcelTypes() {
    // add qties
    const elementTypesWithQties = getElementTypesWithQtiesV2(
      types,
      measShowedIn3dViewer
    );

    //
    let fileName = "element_types.xlsx";
    if (selectedGroup)
      fileName = sanitizeString(selectedGroup.name) + "_types.xlsx";
    const wb = await createExcelWorkbook({
      template: "ELEMENT_TYPES_WITH_IDS",
      data: elementTypesWithQties,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  // async function handleExportExcelRelations() {
  //   let fileName = "element_types_relations.xlsx";
  //   if (selectedGroup)
  //     fileName = sanitizeString(selectedGroup.name) + "_types_relations.xlsx";
  //   const typesWithRessources = types
  //     .sort((a, b) => sortByNum(a.num, b.num))
  //     .map((t) => {
  //       const usedRessources = Object.keys(relations)
  //         .filter((k) => k.startsWith(t.id))
  //         .map((k) => {
  //           const rel = relations[k];
  //           const ressource = ressourcesById[rel.resId];
  //           if (!ressource) return;
  //           return {
  //             ...ressource,
  //             func: rel.func,
  //           };
  //         })
  //         .sort(
  //           (a, b) => a.group.localeCompare(b.group) || sortByNum(a.num, b.num)
  //         );
  //       return {...t, res: usedRessources};
  //     });
  //   const data = {
  //     typesRessourcesRelations: typesWithRessources,
  //     typesGroup: selectedGroup.name,
  //     ressources,
  //   };
  //   const wb = await createExcelWorkbook({
  //     template: "ELEMENT_TYPES_RESSOURCES",
  //     data,
  //   });
  //   downloadExcelFileFromWorkbook(wb, fileName);
  // }

  // async function handleToRemote() {
  //   types = [...new Map(types.map((v) => [v.id, v])).values()];

  //   const typesIds = new Set(types.map((t) => t.id));
  //   let oldElementTypes = scene.data.elementTypes.filter(
  //     (s) => !typesIds.has(s.id)
  //   );
  //   const newScene = {
  //     ...scene,
  //     data: {...scene.data, elementTypes: [...oldElementTypes]},
  //   };
  //   dispatch(updateScene({scene: newScene}));

  //   const newGroup = {};
  //   newGroup.name = selectedGroup.name;
  //   newGroup.fromScene = false;
  //   newGroup.sceneId = scene.id;
  //   newGroup.elementTypes = types;
  //   const result = await dispatch(
  //     createElementTypesGroup({elementTypesGroup: newGroup, accessToken})
  //   );
  //   const {item} = unwrapResult(result);
  //   const group = {};
  //   group.id = item.id;
  //   group.name = item.name;
  //   group.sceneId = item.sceneId;
  //   group.fromScene = false;
  //   dispatch(setSelectedElementTypesGroupProxy(group));
  // }

  // actions map

  const actionsMap = {
    createGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Groupe",
      label: "Créer une liste d'éléments",
    },
    createType: {
      icon: <Add fontSize="small" />,
      name: "Type",
      label: "Créer un nouvel élément",
      disabled: !selectedGroup,
      //handler: () => dispatch(setOpenDialogFsCreateElementType(true)),
    },
    exportExcelTypes: {
      icon: <Download fontSize="small" />,
      name: "Excel",
      label: "Exporter au format Excel",
      handler: handleExportExcelTypes,
    },
    importExcelTypes: {
      icon: <Upload fontSize="small" />,
      name: "Excel",
      label: "Importer au format Excel",
    },
    importTypesFromPdf: {
      label: "Import depuis annotations PDF",
    },
    renameGroup: {
      label: "Renommer la liste ",
      hidden: !isRemote,
    },
    deleteGroup: {
      label: "Supprimer la liste",
      hidden: !isRemote,
    },
    cleanGroups: {
      label: "Nettoyer les listes",
      hidden: true,
      //handler : handleCleanGroups
    },
    // exportExcelRelations: {
    //   label: "Export excel (compositions)",
    //   handler: handleExportExcelRelations,
    //   hidden: !isRemote,
    // },
    importExcelRelations: {
      label: "Import excel (compositions)",
      hidden: !isRemote,
    },
    convertToRemote: {
      label: "Mode avancé ✨",
      //handler: handleToRemote,
      hidden: !isRemote,
    },
    copyToProxies: {
      label: "Copier en proxies",
      hidden: !isRemote,
    },
    copyToRessources: {
      label: "Copier en articles",
      hidden: !isRemote,
    },
    addRelations: {
      label: "Ajouter des relations",
      hidden: !isRemote,
    },
    cleanElementTypesGroup: {
      label: "Nettoyer la liste",
      hidden: !isRemote,
    },

    // cleanRelations: {
    //   label: "Nettoyer les relations",
    //   handler: handleCleanRelations,
    //   visible: isRemote,
    // },
  };

  return actionsMap;
}
