import CardSettingsGrid from "./CardSettingsGrid";
import CardSettingsColors from "./CardSettingsColors";
import CardSettingsBackground from "./CardSettingsBackground";

export default function ToolSettings({editor3d}) {
  return (
    <div>
      <CardSettingsGrid editor3d={editor3d} />
      <CardSettingsBackground editor3d={editor3d} />
      <CardSettingsColors editor3d={editor3d} />
    </div>
  );
}
