import React from "react";

import {useNavigate} from "react-router-dom";

import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";

import {ExpandMore as Down, ExpandLess as Up} from "@mui/icons-material";

import useSceneModule from "../useSceneModule";

export default function MenuItemSceneParent({
  itemKey,
  icon,
  name,
  path,
  open,
  onChange,
  hasChildren,
}) {
  const navigate = useNavigate();
  const sceneModule = useSceneModule();

  // helper

  const selected = itemKey === sceneModule;

  // handlers

  function handleClick(path) {
    navigate(path);
  }

  function handleOpenChange() {
    onChange(!open);
  }

  return (
    <Box sx={{width: 1}}>
      <ListItem
        secondaryAction={
          hasChildren && (
            <IconButton edge="end" onClick={handleOpenChange}>
              {open ? <Up /> : <Down />}
            </IconButton>
          )
        }
        disablePadding
      >
        <ListItemButton onClick={() => handleClick(path)} selected={selected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            sx={{my: 0}}
            primary={name}
            primaryTypographyProps={{
              fontSize: (theme) => theme.typography.body2.fontSize,
            }}
          />
        </ListItemButton>
      </ListItem>
    </Box>
  );
}
