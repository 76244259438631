import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Typography, Menu, MenuItem, Chip, Box} from "@mui/material";
import {ArrowDropDown as Down, Close} from "@mui/icons-material";

import { setFilterP2 } from "../measurementsSlice";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";

export default function SelectorFilterP2({scene}) {
  const dispatch = useDispatch();

  // strings

  const filterName = "P2";

  // data

  const [fms] = useFilteredSceneMeasurements(scene, "ONE_MODEL", true, false);
  const filterP2 = useSelector((s) => s.measurements.filterP2);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  const isActive = Boolean(filterP2);
  const label = filterP2 ? filterP2 : filterName;
  let filters = [...new Set(fms.filter((m) => m.p2).map((m) => m.p2))];
  filters = filters.sort((a, b) => a.localeCompare(b));

  // handlers

  function handleDeleteClick(e) {
    if (!isActive) {
      setAnchorEl(e.currentTarget);
    } else {
      dispatch(setFilterP2(null));
    }
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(p2) {
    dispatch(setFilterP2(p2));
    setAnchorEl(null);
  }

  return (
    <Box display={filters.length > 0 ? "flex" : "none"}>
      <Chip
        label={label}
        deleteIcon={isActive ? <Close /> : <Down />}
        onDelete={handleDeleteClick}
        size="small"
        variant={isActive ? "outlined" : "filled"}
      />
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {filters.map((filter) => {
          const selected = filter === filterP2;
          return (
            <MenuItem
              key={filter}
              onClick={() => handleChange(filter)}
              selected={selected}
            >
              <Typography variant="body2">{filter}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}