import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";

import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

export default function DialogAddRelationsElementTypes({open, onClose, scene}) {
  const dispatch = useDispatch();

  // strings

  const titleS = "Ajout de relations";
  const addS = "Ajouter";
  const help1S =
    "Sélectionner le groupe à utiliser comme modèle pour ajouter des relations";
  const help2S = "Les ajouts se feront sur la base du code de chaque type";

  // data

  const selectedTypeGroup = useSelectedElementTypesGroup(scene);
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const updateElementTypesGroup = useUpdateElementTypesGroup();
  const relations = useSelector((s) => s.relations.relationsTypesRessources);

  // helpers

  const options = groups.filter((g) => g.id !== selectedTypeGroup?.id);

  // state

  const [group, setGroup] = useState(null);

  useEffect(() => {
    if (!group && groups && groups.length > 0) setGroup(groups[0]);
  }, [groups, group]);

  // handlers

  const handleChange = (g) => {
    setGroup(g);
  };

  // handlers

  async function onConfirm() {
    let doUpdate = false;
    const editedTypes = [];
    const typesToChange = selectedTypeGroup?.elementTypes;
    const typesWithRelations = group?.elementTypes;
    const updatedRelations = {...relations};
    typesToChange?.forEach((type) => {
      const tRel = typesWithRelations.find((t) => t.code === type.code);
      if (tRel && tRel.res) {
        const newRes = [];
        let oldRes = type.res;
        tRel.res.forEach((r) => {
          const relKey = `${type.id}-${r.resId}`;
          if (relations[relKey]) {
            oldRes = oldRes.filter((r) => r.id !== relKey);
          }
          const newRel = {
            ...r,
            id: relKey,
            typeId: type.id,
          };
          newRes.push(newRel);
          updatedRelations[relKey] = newRel;
        });
        editedTypes.push({
          ...type,
          res: type.res ? [...oldRes, ...newRes] : newRes,
        });
        doUpdate = true;
      } else {
        editedTypes.push({...type});
      }
    });
    if (doUpdate) {
      const editedGroup = {...selectedTypeGroup, elementTypes: editedTypes};
      await updateElementTypesGroup(editedGroup);
    }
    dispatch(setRelationsTypesRessources(updatedRelations));
    //
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent sx={{maxWidth: 300}}>
        <Typography variant="body2" sx={{pb: 2}}>
          {help1S}
        </Typography>
        <SelectorAutocomplete
          value={group}
          options={options}
          onChange={handleChange}
        />
        <Typography variant="body2" sx={{pt: 2}}>
          {help2S}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>{addS}</Button>
      </DialogActions>
    </Dialog>
  );
}
