import {useDispatch} from "react-redux";

import {Box, Typography, IconButton} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import {setShowPdfSelector} from "../pdfSlice";

export default function SelectorPdfModelBlack({model}) {
  const dispatch = useDispatch();
  // string

  const selectS = "Sélectionnez un pdf";

  // helper

  const noModel = !Boolean(model?.id);

  function handleMoreClick() {
    dispatch(setShowPdfSelector(true));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        justifyContent: "space-between",
        color: "common.white",
        alignItems: "center",
        bgcolor: "common.caplaBlack",
        borderRadius: "4px",
        p: 0.5,
        pl: 1,
      }}
    >
      <Typography noWrap sx={{fontSize: 13}} color="inherit">
        {!noModel ? model?.name : selectS}
      </Typography>
      <IconButton size="small" onClick={handleMoreClick} color="inherit">
        <Down color="inherit" fontSize="small" />
      </IconButton>
    </Box>
  );
}
