import {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import sanitizeString from "Utils/sanitizeString";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import sortByNum from "Utils/sortByNum";
import getRessourcesWithPUFromFile from "Features/excel/utils/getRessourcesWithPUFromFile";
import { setRessourcesWithPU } from "Features/ressources/ressourcesSlice";

export default function DialogUnitaryPrices({
  open,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Prix unitaires";
  const helperS = "Pour visualiser les prix unitaires (P.U.) et les prix totaux (P.T.) dans l'excel d'export, vous pouvez charger un tableau de prix unitaires"
  const warningS = "Ce tableau ne sera pas synchronisé vers CAPLA 360, il reste en mémoire dans votre naviguateur"
  const saveS = "Charger les P.U.";
  const cancelS = "Annuler";
  const buttonName = "Choisir un fichier";
  const templateS = "Télécharger le modèle";

  // data

  let ressources = useRessourcesByScene(scene);
  const selectedGroup = useSelector(
    (s) => s.measurements.selectedRessourceGroup
  );

  // state

  const [ressourcesPU, setRessourcesPU] = useState(null);
  const [file, setFile] = useState(null);

  // helpers

  if (selectedGroup)
    ressources = ressources.filter((r) => r.group === selectedGroup)
    .sort((a, b) => sortByNum(a.num, b.num));

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    const {excelGroup, excelRessources} = await getRessourcesWithPUFromFile(file);
    const resPU = excelRessources.reduce((obj, item) => {
      if (item.pu) {
        return {
          ...obj,
          [item.num]: item.pu,
        }
      }
      return {...obj};
    }, {});
    setRessourcesPU({excelGroup, resPU});
    console.log("ressourcesToLoad", excelGroup, resPU);
  }
  
  function handleCancelClick() {
    onClose();
  }
  
  function handleSaveClick() {
    dispatch(setRessourcesWithPU(ressourcesPU));
    onClose();
  }
  
  async function handleExportClick() {
    const title = selectedGroup;
    let fileName = sanitizeString(selectedGroup) + "_pu.xlsx";
    const wb = await createExcelWorkbook({
      template: "RESSOURCES_PU", data: {ressources, title}
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{maxWidth: 400}}>
        <Box sx={{width: 1, display: "flex", justifyContent: "center", pb: 1}}>
          <Typography varian="body2">{helperS}</Typography>
        </Box>
        <Stack direction="row" alignItems="center" gap={1} sx={{bgcolor: "stabilo.orange", p: 1, my: 2}}>
          <WarningAmber size="small" fontSize="inherit" />
          <Typography variant="body2">{warningS}</Typography>
          <WarningAmber size="small" fontSize="inherit" />
        </Stack>
        <Box sx={{width: 1, display: "flex", justifyContent: "center", pt: 2}}>
          <CardFileSelector
            file={file}
            onFileChange={handleFileChange}
            buttonName={buttonName}
            accept=".xlsx"
          />
        </Box>
        <Box sx={{width: 1, display: "flex", color: "text.secondary"}}>
          <Button onClick={handleExportClick} sx={{color: "inherit"}}>
            {templateS}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button
          onClick={handleSaveClick}
          disabled={!ressourcesPU}
        >
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
