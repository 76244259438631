import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  createIssueService,
  fetchIssuesService,
  fetchIssuesBySceneService,
  updateIssueService,
  deleteIssueService,
} from "./services";

export const fetchIssues = createAsyncThunk(
  "issues/fetchIssues",
  fetchIssuesService
);
export const fetchIssuesByScene = createAsyncThunk(
  "issues/fetchIssuesByScene",
  fetchIssuesBySceneService
);
export const createIssue = createAsyncThunk(
  "issues/createIssue",
  createIssueService
);
export const updateIssue = createAsyncThunk(
  "issues/updateIssue",
  updateIssueService
);
export const deleteIssue = createAsyncThunk(
  "issues/deleteIssue",
  deleteIssueService
);

const defaultContent = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];

export const issuesSlice = createSlice({
  name: "issues",
  initialState: {
    items: [], // list of issues.
    statusByScene: {}, // {sceneId:"loaded"}, used by useIssues to trigger fetch issuesByScene
    selectedIssuesListingId: null,
    showIssuesInViewer: false,
    selectedIssueId: null,
    selectedIssueIdShortcut: null,
    issuesetsInScope: [], // selected listings ids in issues page.
    filtersOpenedClosed: ["OPENED"], // ["OPENED","CLOSED"] used to filter grid based on isOpened flag.
    openSectionIssueDetailInOutlet: false,
    isEditingIssue: false, // in selection section
    tempIssue: {title: "Xxx", content: defaultContent},
    editedIssue: {},
    selectedIssuesetOrder: null,
    openIssuesInLeftPanel: false,
  },
  reducers: {
    addIssue: (action) => {
      console.log(action);
    },
    setSelectedIssuesListingId: (state, action) => {
      state.selectedIssuesListingId = action.payload;
    },
    setShowIssuesInViewer: (state, action) => {
      state.showIssuesInViewer = action.payload;
    },
    setSelectedIssueId: (state, action) => {
      state.selectedIssueId = action.payload;
    },
    setSelectedIssueIdShortcut: (state, action) => {
      state.selectedIssueIdShortcut = action.payload;
    },
    setIssuesetsInScope: (state, action) => {
      state.issuesetsInScope = action.payload;
    },
    addIssuesetInScope: (state, action) => {
      state.issuesetsInScope.push(action.payload);
    },
    setFiltersOpenedClosed: (state, action) => {
      state.filtersOpenedClosed = action.payload;
    },
    setOpenSectionIssueDetailInOutlet: (state, action) => {
      state.openSectionIssueDetailInOutlet = action.payload;
    },
    // editing
    setIsEditingIssue: (state, action) => {
      state.isEditingIssue = action.payload;
    },
    setTempIssue: (state, action) => {
      state.tempIssue = action.payload;
    },
    setEditedIssue: (state, action) => {
      state.editedIssue = action.payload;
    },
    // metaData
    setSelectedIssuesetOrder: (state, action) => {
      state.selectedIssuesetOrder = action.payload;
    },
    setOpenIssuesInLeftPanel: (state, action) => {
      state.openIssuesInLeftPanel = action.payload;
    },
  },
  extraReducers: {
    [fetchIssues.fulfilled]: (state, action) => {
      const {items} = action.payload;
      const itemsIds = items.map((item) => item.id);
      let newItems = state.items.filter((item) => !itemsIds.includes(item.id));
      state.items = [...newItems, ...items];
    },
    [fetchIssuesByScene.fulfilled]: (state, action) => {
      const {items, sceneId} = action.payload;
      const itemsIds = items.map((item) => item.id);
      let newItems = state.items.filter((item) => !itemsIds.includes(item.id));
      state.items = [...newItems, ...items];
      state.statusByScene[sceneId] = "loaded";
    },

    // "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
    //   const {scene, models, issues} = action.payload;
    //   issues.forEach((issue) => {
    //     state.datasets[issue.listing.id] = {
    //       data: issue.items,
    //       status: "succeed",
    //     };
    //   });
    // },
    [createIssue.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = [...state.items, item];
    },
    [updateIssue.fulfilled]: (state, action) => {
      const updatedIssue = action.payload;
      const newItems = state.items.filter((i) => i.id !== updatedIssue.id);
      state.items = [...newItems, updatedIssue];
    },
    [deleteIssue.fulfilled]: (state, action) => {
      const {issueId} = action.payload;
      state.items = state.items.filter((i) => i.id !== issueId);
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {issues} = action.payload;
      state.items.push(...issues);
      const listingIds = [...new Set(issues.map((i) => i.listing))];
      state.issuesetsInScope.push(...listingIds);
    },
  },
});

export const {
  addIssue,
  setSelectedIssuesListingId,
  setShowIssuesInViewer,
  setSelectedIssueId,
  setSelectedIssueIdShortcut,
  setIssuesetsInScope,
  addIssuesetInScope,
  setFiltersOpenedClosed,
  setOpenSectionIssueDetailInOutlet,
  setIsEditingIssue,
  setEditedIssue,
  setTempIssue,
  setSelectedIssuesetOrder,
  setOpenIssuesInLeftPanel
} = issuesSlice.actions;

// selectors

export const selectIssuesRows = (state) => {
  const issues = state.issues.ids.map((id) => {
    const issue = state.issues.items[id];
    return {id, ...issue};
  });
  return issues;
};

// const issues = {"modelId":{issues:{nodeId:issue,...},elements:{"codeName":[nodeId,...]}}}
// issues of one element : issues[modelRemoteId].elements["codeName"].

export const selectOpenedIssuesByModel = (state) => {
  const issues = [];

  Object.entries(state.issues.datasets).forEach(([key, value]) => {
    if (Array.isArray(value?.data)) issues.push(...value.data);
  });

  const issuesByModel = {};

  issues
    .filter((issue) => issue.isOpened && issue.relationsElements?.length > 0)
    .forEach((issue) => {
      issue.relationsElements.forEach(({sceneModel, elements}) => {
        // issues[sceneModel].issues
        if (issuesByModel[sceneModel]?.issues) {
          issuesByModel[sceneModel].issues[issue.id] = issue;
        } else {
          issuesByModel[sceneModel] = {
            issues: {[issue.id]: issue},
            elements: {},
          };
        }
        // issues[sceneModel].elements
        elements.codeNames.forEach((codeName) => {
          if (issuesByModel[sceneModel].elements[codeName]) {
            issuesByModel[sceneModel].elements[codeName] = [
              ...issuesByModel[sceneModel].elements[codeName],
              issue.id,
            ];
          } else {
            issuesByModel[sceneModel].elements = {
              ...issuesByModel[sceneModel].elements,
              [codeName]: [issue.id],
            };
          }
        });
      });
    });
  return issuesByModel;
};

export default issuesSlice.reducer;
