import sortByNum from "./sortByNum";

export default function materialsForDataGrid(materials) {
  return [...materials]
    .map((material) => ({
      ...material,
      num: material.num ? material.num : "0",
      hasMsts: material.msts?.length > 0,
    }))
    .sort((a, b) => sortByNum(a.num, b.num));
}
