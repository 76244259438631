import {useDispatch} from "react-redux";

import {Box, Paper, Typography} from "@mui/material";

import useSelectedPackage from "Features/elementPackages/useSelectedPackage";
import FileSelectorButton from "Features/files/components/FileSelectorButton";
import { updateModel } from "Features/viewer3D/viewer3DSlice";

export default function CardLoadProcedureFiles({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const title = "Données de procédures";
  let fileName = "Charger ici votre fichier de procédures (JSON)"
  const procedureS = "Choisir un fichier .json";

  // data

  const selectedPackage = useSelectedPackage();

  // helpers

  if (selectedPackage?.measurementsData?.procedures)
    fileName = selectedPackage.measurementsData.procedures[0].fileName;

  // handlers

  async function handleProcedureFileChange(file) {
    try {
      const procedures = await editor3d?.loader.loadProcedureFile(file);
      if (procedures) {
        const measurementsData = {
          ...selectedPackage.measurementsData,
          procedures
        };
        const updatedModel = {...selectedPackage, measurementsData};
        await dispatch(updateModel({updatedModel, sync: true}));
      }
    } catch (e) {
      console.log("error", e);
    }
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 1,
        mt: 2,
        py: 2,
      }}
    >
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      <Typography variant="body2">
        {fileName}
      </Typography>
      <Box sx={{my: 2, color: "inherit"}}>
        <FileSelectorButton
          onFileChange={handleProcedureFileChange}
          buttonVariant="outlined"
          buttonColor="inherit"
          buttonName={procedureS}
          accept={".json"}
        />
      </Box>
    </Paper>
  );
}
