import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import DataGridInputs from "./DataGridInputs";
import SectionTitle from "./SectionTitle";
import ToolbarInputs from "./ToolbarInputs";
import useInputsByScene from "../hooks/useInputsByScene";

import Stretch from "Features/ui/components/Stretch";

import useInitOpenSectionsInPageInputs from "../hooks/useInitOpenSectionsInPageInputs";

export default function PageInputs() {
  // params

  const {caplaEditor, scene} = useOutletContext();

  // data

  const inputs = useInputsByScene(scene);
  const os = useSelector((s) => s.ui.openSections);

  // init

  useInitOpenSectionsInPageInputs(scene);

  if (os.outlet)
    return (
      <Box sx={{width: 1}}>
        <Box sx={{height: 60}} />
        <SectionTitle />
        <Box sx={{py: 2}}>
          <ToolbarInputs editor3d={caplaEditor?.editor3d} />
        </Box>
        <Stretch>
          <DataGridInputs inputs={inputs} />
        </Stretch>
      </Box>
    );
  return <></>;
}
