import React from "react";

import {Box, Paper} from "@mui/material";

import FormLocateSceneInWorksite from "Features/worksites/components/FormLocateSceneInWorksite";
import ToolbarLocate from "./ToolbarLocate";

export default function LayerLocate({scene, editor}) {
  return (
    <Box>
      <Box sx={{position: "absolute", top: "8px", left: "8px"}}>
        <Paper sx={{bgcolor: "common.white", width: 350, p: 1}} elevation={12}>
          <FormLocateSceneInWorksite scene={scene} editor={editor} />
        </Paper>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "8px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <ToolbarLocate editor={editor} scene={scene} />
      </Box>
    </Box>
  );
}
