import {Box} from "@mui/material";

import ContainerSceneOverview from "./ContainerSceneOverview";

export default function GridScenes({scenes}) {
  const scenesInGrid = scenes.map((scene) => ({
    ...scene,
    prodDeadline: scene.prodDeadline ? scene.prodDeadline : "-",
  }));

  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {scenesInGrid
        .sort((a, b) => b.prodDeadline.localeCompare(a.prodDeadline))
        .map((scene, index) => {
          return (
            <Box sx={{mr: 2, mb: 2, width: 280}} key={scene.clientId + index}>
              <ContainerSceneOverview scene={scene} />
            </Box>
          );
        })}
    </Box>
  );
}
