import {Box, FormControlLabel, Switch, Typography, Button} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";

export default function CardImageResolution({model, editor3d}) {
  const {t} = useTranslation("viewer3D");

  // strings

  const switchLabel = t("model.settings.display.hightResolution");
  const resizeButtonString = t("model.settings.resizeButton");

  // data

  const resolution = model.resolution ? model.resolution : "RR";

  const hasReso = model.urlLowReso && model.urlHightReso;

  // handlers

  function handleSwitchChange(e) {
    const c = e.target.checked;
    const entity = editor3d?.getEntity(model.id);

    if (c) {
      entity?.switchResolutionTo("HR");
    } else {
      entity?.switchResolutionTo("LR");
    }
  }

  function handleResizeClick() {
    console.log("Resize image");
    editor3d.loader.loadResizedImages(model);
  }

  function handleRestore() {
    console.log("Model", model);
    const entity = editor3d?.getEntity(model.id);
    entity?.switchResolutionTo("RR");
    if (entity?.hideBackground) entity?.hideBackground();
  }

  //
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {hasReso ? (
        <FormControlLabel
          sx={{width: 1}}
          control={
            <Switch
              size="small"
              checked={resolution === "HR"}
              onChange={handleSwitchChange}
            />
          }
          label={<Typography variant="body2">{switchLabel}</Typography>}
        />
      ) : (
        <Button
          size="small"
          variant="outlined"
          onClick={handleResizeClick}
          sx={{my: 1}}
        >
          {resizeButtonString}
        </Button>
      )}
      <Button size="small" onClick={handleRestore} variant="outlined">
        Restore
      </Button>
    </Box>
  );
}
