import {useState} from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  List,
  ListItemButton,
  Card,
  CardContent,
  CardMedia,
  CardActions,
} from "@mui/material";

import CreateModelButton from "./CreateModelButton";

import imageImage from "../assets/imageModelImage.png";
import ifcImage from "../assets/ifcModelImage.png";

import useTranslation from "Features/translations/useTranslation";

export default function DialogCreateModel({
  open,
  onClose,
  editor3d,
}) {
  const {t} = useTranslation("viewer3D");

  // strings

  const titleString = t("viewer3D:models.createDialog.title");

  const imageType = t("viewer3D:models.createDialog.image.type");
  const imageDescription = t("viewer3D:models.createDialog.image.description");
  const imageAction = t("viewer3D:models.createDialog.image.action");

  const ifcType = t("viewer3D:models.createDialog.ifc.type");
  const ifcDescription = t("viewer3D:models.createDialog.ifc.description");
  const ifcAction = t("viewer3D:models.createDialog.ifc.action");

  // options

  const options = [
    {
      type: "IMAGE",
      img: imageImage,
      title: imageType,
      description: imageDescription,
      action: imageAction,
    },
    {
      type: "IFC",
      img: ifcImage,
      title: ifcType,
      description: ifcDescription,
      action: ifcAction,
    },
  ];

  // local data

  const [selection, setSelection] = useState(options[0]);

  // handlers

  function handleModelCreate() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleString}</DialogTitle>
      <DialogContent>
        <Box sx={{display: "flex"}}>
          <List dense sx={{width: 250}}>
            {options.map((option) => (
              <ListItemButton
                key={option.type}
                selected={option.type === selection.type}
                onClick={() => setSelection(option)}
              >
                <Typography variant="body2">
                  <b>{option.title}</b>
                </Typography>
              </ListItemButton>
            ))}
          </List>
          <Box sx={{width: 8}} />
          <Card sx={{width: 300}}>
            <CardMedia
              height={200}
              image={selection.img}
              alt={selection.type}
              component="img"
            />

            <CardContent sx={{height: 100, overflow: "hidden"}}>
              <Typography variant="body2" gutterBottom>
                <b>{selection.title}</b>
              </Typography>
              <Typography variant="body2">{selection.description}</Typography>
            </CardContent>
            <CardActions>
              <CreateModelButton
                buttonName={selection.action}
                editor3d={editor3d}
                onCreate={handleModelCreate}
              />
            </CardActions>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
