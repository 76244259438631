import getFilteredNomenclatureWithItems from "./getFilteredNomenclatureWithItems";
import getItemsMapById from "./getItemsMapById";

export default function getItemsWithParentsAndGroups(items, groupsWithItems) {
  const groupsIds = [...new Set(items.map((item) => item?.groupId))];

  const sortedItems = [];

  let globalScope = items.find((item) => item.id === "globalScope");
  if (globalScope) {
    sortedItems.push(globalScope);
  }

  groupsWithItems.forEach((group) => {
    if (groupsIds.includes(group.id)) {
      const itemGroup = {id: group.id, name: group.name, isGroup: true};
      sortedItems.push(itemGroup);
      let itemsInGroup = items.filter((item) => item.groupId === group.id);
      const sortedItemsInGroup = getFilteredNomenclatureWithItems(
        group.items,
        itemsInGroup
      );
      // console.log("debug sortedItemsInGroup", group.name, sortedItemsInGroup);

      sortedItemsInGroup.forEach((item) => {
        sortedItems.push(item);
      });
    }
  });

  return sortedItems;
}
