import DialogFsInvalidBimbox from "./DialogFsInvalidBimbox";
import DialogFsSelectScope from "./DialogFsSelectScope";
import DialogFsOpenScope from "./DialogFsOpenScope";
import DialogFsLoadingScope from "./DialogFsLoadingScope";

export default function LayerOverviewerDialogsFs({scene}) {
  return (
    <>
      <DialogFsInvalidBimbox />
      <DialogFsSelectScope scene={scene} />
      <DialogFsOpenScope scene={scene} />
      <DialogFsLoadingScope scene={scene} />
    </>
  );
}
