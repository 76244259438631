import {
  Vector3,
  MeshLambertMaterial,
  MeshBasicMaterial,
  MeshPhysicalMaterial,
  Box3,
  // Object3D,
  Color,
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
} from "three";

import theme from "Styles/theme";

import {
  getIfcSubsets,
  getIfcModelSite,
  getIfcModelProject,
  getSpatialStructureLeaves,
  flatSpatialStructure,
} from "../utils/ifcjsUtils";

import IfcModelElement from "./IfcModelElement";
// import IfcModelSubset from "./IfcModelSubset";
// import LoadingBox from "./LoadingBox";

const pickedMaterial = new MeshPhysicalMaterial({
  color: 0x25fde9, // blue fash
  transparent: true,
  opacity: 0.9,
});

const selectedMaterial = new MeshPhysicalMaterial({
  color: 0xf52585, // pink
  transparent: true,
  opacity: 0.5,
});

// const mainMaterial = new MeshPhysicalMaterial({
//   color: 0x4f6bd6,
//   //emissive: 0x4f6bd6,
//   transparent: true,
//   opacity: 0.8,
// });

export default class IfcModelEntity {
  rawObject; // mesh loaded from the ifcloader
  modelObject; // main mesh.
  object; // in modelObject. Object added to editorEntities.
  filteredObject; // filter by expressIDs.

  jsonProperties; // from json object
  expressIDs;
  elementsProps;

  loaded;
  loadedFromGltf; // the mesh object was loaded from gltf file.

  clickedExpressID; // to know which object was clicked on. cf clickOnFace function.

  constructor({
    model,
    scene,
    onModelChange,
    ifcLoader,
    onLoad,
    onProgress,
    gltfModel,
  }) {
    this.type = "IFC_MODEL_ENTITY"; // the full model from ifc.
    this.model = model;
    this.modelId = model.id;
    this.scene = scene;
    this.gltfModel = gltfModel; // used to convert gltf model into ifcModelEntity.

    this.ifcLoader = ifcLoader;
    this.onProgress = onProgress;
    this.onLoad = onLoad;
    this.loaded = false;

    this.expressIDs = [];
    this.elementsProps = {};

    this.onModelChange = onModelChange;

    this.ifcStructure = null; // computed when loaded.
    this.selectedItems = [];

    this.initialTransformation = {
      position: model.position,
      rotation: model.rotation,
    };

    this.pickId = undefined;
    this.selectionId = undefined; // expressID of the selection

    //this.pickedObject = undefined;
    //this.selectedObject = undefined;

    this.waitingMaterial = new MeshBasicMaterial({color: 0x284a78}); // dark blue, waiting for other model to be loaded

    this.cam1Material = new MeshPhysicalMaterial({
      color: new Color(theme.palette.background.default), // dark blue
      //color: 0xf52585, // pink
      //transparent: true,
      //opacity: 0.8,
    });

    this.pickedMaterial = new MeshPhysicalMaterial({
      color: 0xfab41f, // yellow
      //color: 0xf52585, // pink
      transparent: true,
      opacity: 0.9,
    });

    this.selectedMaterial = new MeshPhysicalMaterial({
      color: 0xf52585, // pink
      transparent: true,
      opacity: 0.9,
    });

    this.filteredMaterial = new MeshPhysicalMaterial({
      color: theme.palette.common.yellow,
      transparent: false,
      //opacity: 0.9,
      side: DoubleSide,
    });

    this.transparentMaterial = new MeshPhysicalMaterial({
      color: theme.palette.common.caplaBlack,
      transparent: true,
      opacity: 0.05,
      side: DoubleSide,
    });

    this.tempMaterial = new MeshBasicMaterial({color: 0x284a78});
  }

  get ifcModelID() {
    if (this.modelObject) {
      return this.modelObject.modelID;
    } else {
      console.log("no modelObject for this ifc model entity");
    }
  }

  /*
   * CHANGE URL
   * - used when the model is loaded from the remote, without any file.
   * - we need to download the file => change the model url in all the Entities.
   * - the change is done from state => editor. No need to update the model.
   */

  resetModelFromState(model) {
    this.model = model;
  }

  /*
   * INITIAL LOADING
   * - loadIfc
   * - compute default position
   * - place model at the site origin
   * - compute bounding box (width,height,depth,center)
   */

  async loadIfc({autoplacement, loadIfcStructure = true, rawIfcModel}) {
    // loader
    const onProgress = (e) => {
      let progress = e.loaded / e.total;
      progress = Math.round((progress + Number.EPSILON) * 100) / 100;
      //console.log("PROGRESS", progress);
      this.onProgress(progress);
    };
    // set loader progress
    this.ifcLoader.ifcManager.setOnProgress(onProgress);
    // object
    this.modelObject = await this.ifcLoader.loadAsync(this.model.url); // the container of the subsets, either the full one or the subsets enitites.

    // keep raw object
    this.rawObject = this.modelObject.clone();

    const modelExpressIDs =
      this.modelObject.geometry.attributes.expressID.array;
    const ids = [...new Set([...modelExpressIDs])];
    this.expressIDs = ids;

    if (rawIfcModel) {
      this.object = this.modelObject;
    } else {
      this.object = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            this.ifcLoader.ifcManager.createSubset({
              modelID: this.modelObject.modelID,
              ids,
              scene: this.modelObject,
              removePrevious: true,
              applyBVH: true,
            })
          );
        }, [200]);
      });
    }

    // we compute here the initial transformation computed by ifcjs loader.
    this.ifcjsPosition = this.modelObject.position;
    this.ifcjsRotation = this.modelObject.rotation;
    // visibility
    //this.modelObject.layers.enable(1);

    if (!rawIfcModel) {
      this.object.layers.enable(1);
      this.modelObject.layers.enable(3);
      //this.modelObject.material = this.cam1Material;
    } else {
      this.object.layers.enable(1);
      this.modelObject.layers.enable(3);
    }

    // ifc subsets
    let ifcStructure;
    if (loadIfcStructure) {
      ifcStructure = await this.getIfcStructure();
      this.ifcStructure = ifcStructure;
    }
    //const ifcStructure = undefined;
    // transformation
    if (autoplacement) {
      await this.setTransformationToSite();
    } else {
      this.modelObject.position.set(
        this.model.position.x,
        this.model.position.y,
        this.model.position.z
      );
      this.modelObject.rotation.set(
        this.model.rotation.x,
        this.model.rotation.y,
        this.model.rotation.z
      );
    }

    // updated model

    const p = this.modelObject.position;
    const r = this.modelObject.rotation;

    const updatedModel = {
      ...this.model,
      position: {x: p.x, y: p.y, z: p.z},
      rotation: {x: r.x, y: r.y, z: r.z},
    };

    // on load end
    this.loaded = true;

    this.onLoad({
      ifcStructure,
      ifcModelID: this.modelObject.modelID,
      updatedModel,
    });

    // keep object material in memory
    this.initialObjectMaterial = this.object.material;

    return updatedModel;
  }

  /*
   * Loader
   */

  async loadGltf() {
    try {
      await this.gltfModel.asyncCreateObject(this.model.urlGltf);
      this.modelObject = this.gltfModel.object;

      // keep raw object
      this.rawObject = this.modelObject.clone();

      const modelExpressIDs =
        this.modelObject.geometry.attributes.expressID.array;
      const ids = [...new Set([...modelExpressIDs])];

      this.object = await new Promise((resolve) => {
        setTimeout(() => {
          console.log("modeID 12", this.modelObject.modelID, this.modelObject);
          resolve(
            this.ifcLoader.ifcManager.createSubset({
              modelID: this.modelObject.modelID,
              ids,
              scene: this.modelObject,
              removePrevious: true,
              applyBVH: true,
            })
          );
        }, [200]);
      });

      this.modelObject.material = this.cam1Material;

      this.object.layers.enable(1);
      this.modelObject.layers.enable(3);
      //
      this.modelObject.position.set(
        this.model.position.x,
        this.model.position.y,
        this.model.position.z
      );
      this.modelObject.rotation.set(
        this.model.rotation.x,
        this.model.rotation.y,
        this.model.rotation.z
      );

      // on load end
      this.loaded = true;
      this.loadedFromGltf = true;
    } catch (e) {
      console.log(e);
    }
  }

  async loadJson() {
    console.log("load json of entity 411");
    if (this.model.urlJson) {
      const response = await fetch(this.model.urlJson);
      this.jsonProperties = await response.json();
      this.psetsMap = this.cachePsets();
    }
  }
  // Get the default position from the setting COORDINATE_TO_ORIGIN = True.
  // Transformation of the model is being done to be observed close to the origin.
  // Coordinates in Three XYZ system.
  async defaultTransformation() {
    const coordinationMatrix =
      await this.ifcLoader.ifcManager.ifcAPI.GetCoordinationMatrix(
        this.ifcModelID
      );
    const p = coordinationMatrix.slice(12, 15);
    return {x: p[0], y: p[1], z: p[2]};
  }
  //  Position the model at the site origin.
  //  Trigger at the model creation => no need to update the model.
  async setTransformationToSite() {
    const {location, refDirection} = await this.site();
    const dp = await this.defaultTransformation();
    const theta = (refDirection.theta() * Math.PI) / 180;
    const validation = isNaN(theta) ? false : Boolean(theta.toString());
    if (validation) {
      const locationV = new Vector3(location.x, location.z, -location.y);
      const defaultPositionV = new Vector3(dp.x, dp.y, dp.z);

      const deltaV = locationV.add(defaultPositionV);

      this.modelObject.position.sub(deltaV);

      // rotation
      const objectCenter = new Vector3();
      objectCenter.copy(this.modelObject.position);
      this.modelObject.rotateY(-theta);
      this.modelObject.updateWorldMatrix();
      this.modelObject.localToWorld(objectCenter.negate());
      this.modelObject.position.sub(objectCenter);

      const p = this.modelObject.position;
      const r = this.modelObject.rotation;

      // update
      this.setInitialTransformation();

      // console
      console.log("Transformation to site", p, r);

      return {
        position: {x: p.x, y: p.y, z: p.z},
        rotation: {x: r.x, y: r.y, z: r.z},
      };
    }
  }

  /*
   * STOREYS AND TYPE LOADING
   * - use the async getIfcStructure method
   * - compute subsets
   */

  async getIfcStructure() {
    const {ifcSubsets, ifcStoreys, ifcTypes, tree, modelId} =
      await getIfcSubsets(this.ifcLoader, this.ifcModelID, this.model.id);
    return {
      ifcSubsets,
      ifcStoreys,
      ifcTypes,
      tree,
      modelId,
      ifcModelID: this.ifcModelID,
    };
  }

  get position() {
    return {
      x: this.object.position.x,
      y: this.object.position.y,
      z: this.object.position.z,
    };
  }

  get rotation() {
    return {
      x: this.object.rotation.x,
      y: this.object.rotation.y,
      z: this.object.rotation.z,
    };
  }

  get uptodateModel() {
    const m = {
      ...this.model,
      rotation: this.rotation,
      position: this.position,
      width: this.width,
      height: this.height,
      depth: this.depth,
      center: this.center,
    };
    return m;
  }

  transform() {
    this.object.rotation.set(
      this.model.rotation.x,
      this.model.rotation.y,
      this.model.rotation.z
    );
    this.object.position.set(
      this.model.position.x,
      this.model.position.y,
      this.model.position.z
    );
  }
  /*
   * Update Model at the redux level with the onModelChange callback.
   * Should be called for each transformation to be persisted.
   */
  updateModel() {
    const m = this.upToDateModel;
    this.model = m;
    //this.onModelChange(m);
  }

  setInitialTransformation() {
    this.initialTransformation = {
      position: this.position,
      rotation: this.rotation,
    };
  }

  resetTransformation() {
    const {position: p, rotation: r} = this.initialTransformation;
    this.object.rotation.set(r.x, r.y, r.z);
    this.object.position.set(p.x, p.y, p.z);
    this.updateModel();
  }

  translate(x, y, z) {
    this.object.position.add(new Vector3(x, y, z));
    this.updateModel();
  }

  /*
   * Pick & Select
   */

  setSelectionOpacity(opacity) {
    this.selectedMaterial.opacity = opacity / 100;
  }

  async pickElement(expressID) {
    if (expressID) {
      const s = await this.ifcLoader.ifcManager.createSubset({
        modelID: this.ifcModelID,
        ids: [expressID],
        material: pickedMaterial,
        scene: this.object,
        removePrevious: true,
      });
      s?.layers.enable(1);
      s?.layers.enable(3);
      //this.object.add(s);
      this.pickedElement = s;
    }
  }

  unpickElement() {
    this.ifcLoader.ifcManager.removeSubset(this.ifcModelID, pickedMaterial);
  }

  hideElement(expressID) {
    this.ifcLoader.ifcManager.removeFromSubset(this.ifcModelID, [expressID]);
  }
  showElement(expressID) {
    const options = {
      modelID: this.ifcModelID,
      ids: [expressID],
      scene: this.modelObject,
      removePrevious: false,
      applyBVH: true,
    };
    this.ifcLoader.ifcManager.createSubset(options);
  }

  async selectElement(expressID) {
    if (expressID !== this.selectedElementExpressID) {
      //this.showElement(this.selectedElementExpressID);
    }
    //this.hideElement(expressID);

    const s = await this.ifcLoader.ifcManager.createSubset({
      modelID: this.ifcModelID,
      ids: [expressID],
      material: this.selectedMaterial,
      scene: this.object,
      removePrevious: true,
    });
    s.layers.enable(1);
    s.layers.enable(3);
    //this.object.add(s);
    this.selectedElement = s;
    this.selectedElementExpressID = expressID;
  }
  async selectElementByFaceIndex(faceIndex) {
    const expressID = await this.ifcLoader.ifcManager.getExpressId(
      this.object.geometry,
      faceIndex
    );

    const s = await this.ifcLoader.ifcManager.createSubset({
      modelID: this.ifcModelID,
      ids: [expressID],
      material: this.selectedMaterial,
      scene: this.object,
      removePrevious: true,
    });
    s.layers.enable(1);
    s.layers.enable(3);
    //this.object.add(s);
    this.selectedElement = s;
    this.selectedElementExpressID = expressID;

    console.log("expressID41", expressID);
    return {expressID};
  }

  async multipleSelect(expressIDs) {
    console.log("multiple select 123", expressIDs, this.ifcModelID, this);
    try {
      const s = await this.ifcLoader.ifcManager.createSubset({
        modelID: this.ifcModelID,
        ids: expressIDs,
        material: selectedMaterial,
        scene: this.object,
        removePrevious: true,
      });
      s.layers.enable(1);
      s.layers.enable(3);
      //this.object.add(s);
      this.selectedElement = s;
    } catch (e) {
      console.log(e);
    }
  }

  unselectElement() {
    try {
      //this.showElement(this.selectedElementExpressID);
      this.ifcLoader.ifcManager.removeSubset(
        this.ifcModelID,
        this.selectedMaterial
      );
      this.selectedElement = null;
      this.selectedElementExpressID = null;
    } catch (e) {
      console.log(e);
    }
  }

  async clickOnFace(faceIndex) {
    if (!faceIndex) return;
    const expressID = await this.ifcLoader.ifcManager.getExpressId(
      this.object.geometry,
      faceIndex
    );
    this.clickedExpressID = expressID;
    return expressID;
  }

  async pick(faceIndex) {
    const expressID = await this.ifcLoader.ifcManager.getExpressId(
      this.object.geometry,
      faceIndex
    );
    this.pickId = expressID;
    if (this.selectionId !== this.pickId) {
      await this.pickElement(expressID);
    }
    return this;
  }

  unpick() {
    this.unpickElement();
    this.pickId = undefined;
  }

  select() {
    this.selectionId = this.pickId;
    this.unpickElement();
    this.selectElement([this.selectionId]);
    return this;
  }

  unselect() {
    this.selectionId = undefined;
    this.unselectElement();
    if (this.pickId) {
      this.pickElement(this.pickId);
    }
  }

  selectItems(selectedIds) {
    this.subsetsEntities.forEach((e) => e.selectItems(selectedIds));
  }

  createModelElement(expressID, material) {
    let jsonProps;
    let psets;

    if (this.jsonProperties) jsonProps = this.jsonProperties[expressID];
    if (this.psetsMap) psets = this.psetsMap.get(expressID);
    console.log("psets 32", psets);

    const entity = new IfcModelElement({
      expressID,
      ifcModelID: this.ifcModelID,
      ifcLoader: this.ifcLoader,
      modelId: this.model.id,
      modelObject: this.modelObject,
      scene: this.scene,
      material,
      jsonProps,
      fromGltf: this.loadedFromGltf,
    });

    return entity;
  }

  async parseIfcElement({faceIndex, expressID}) {
    let id = expressID;
    if (!id) {
      id = await this.ifcLoader.ifcManager.getExpressId(
        this.object.geometry,
        faceIndex
      );
    }
    console.log("parseIfcElement", id);
    const props = await this.getIfcElementProps({expressID: id});
    //const psets = await this.getIfcElementPropertySets({expressID: id});
    return {
      expressID: id,
      ifcModelID: this.ifcModelID,
      modelId: this.modelId,
      name: props.name,
      props,
      //psets,
    };
  }

  async loadElementsProps(onProgress) {
    const size = this.expressIDs.length;
    let progress = 0;
    let step = 1 / 10;
    for (let i = 1; i <= size; i++) {
      // progress

      if (i / size > progress + step) {
        progress = progress + step;
        if (onProgress) onProgress(progress);
      }
      if (i === size && onProgress) onProgress(1);

      const expressID = this.expressIDs[i - 1];
      const exists = this.elementsProps[expressID];
      if (!exists) {
        const props = await this.ifcLoader.ifcManager.getItemProperties(
          this.ifcModelID,
          expressID,
          true
        );
        this.elementsProps[expressID] = props;
      }
    }
  }

  async getIfcElementProps({expressID}) {
    // let props = this.elementsProps[expressID]; // too long to change color...

    const props = await this.ifcLoader.ifcManager.getItemProperties(
      this.ifcModelID,
      expressID,
      true
    );

    return {
      name: props?.Name?.value,
      type: props?.ObjectType?.value,
      tag: props?.Tag?.value,
      description: props?.Description?.value,
    };
  }

  async getIfcElementPropertySets({expressID}) {
    if (!this.fromGltf) {
      // TO DO : manage pset with Quantities attribute
      const psets = await this.ifcLoader.ifcManager.getPropertySets(
        this.ifcModelID,
        expressID,
        true
      );
      const details = psets.map((pset) => {
        const name = pset.Name?.value;
        const description = pset.Description?.value;
        const props = pset.HasProperties?.map((prop) => {
          const key = prop.Name?.value;
          const value = prop.NominalValue?.value;
          return {key, value};
        });

        return {name, description, props};
      });
      return details;
    }
  }

  async parse() {
    // parse the selection or the full model
    // if (this.selectionId) {
    //   const entity = this.createModelElement(
    //     this.selectionId,
    //     this.selectedMaterial
    //   );
    //   const parsedEntity = await entity.parse();
    //   return parsedEntity;
    // } else {
    //   return {
    //     ...this.model,
    //     modelId: this.model.id,
    //     position: this.position,
    //     rotation: this.rotation,
    //   };
    return {
      ...this.model,
      name: this.model.name,
      modelId: this.model.id,
      position: this.position,
      rotation: this.rotation,
    };
    // }
  }

  /*
   *  Filters
   */

  enableTransparency() {
    if (this.object) this.object.material = this.transparentMaterial;
  }
  disableTransparency() {
    if (this.object) this.object.material = this.initialObjectMaterial;
  }
  filterElementsByIds(ids) {
    //this.hide();

    if (ids.length === 0) {
      this.disableTransparency();
      if (this.filteredObject) {
        this.filteredObject.layers.disable(1);
        this.filteredObject.layers.disable(3);
      }
      return;
    }
    this.enableTransparency();
    this.filteredObject = this.ifcLoader.ifcManager.createSubset({
      modelID: this.modelObject.modelID,
      ids,
      scene: this.modelObject,
      removePrevious: true,
      applyBVH: true,
      material: this.filteredMaterial,
    });

    this.filteredObject.layers.enable(1);
    this.filteredObject.layers.enable(3);
  }

  /*
   *  Storeys & Types = subsets
   */

  applyFilters(ifcSubsets, ifcStoreys, ifcTypes) {
    const visibleIds = [];
    const hiddenIds = [];
    console.log(ifcSubsets);
    if (ifcSubsets) {
      ifcSubsets.forEach((subset) => {
        const ifcStorey = ifcStoreys.find(
          (s) => s.expressID === subset.storeyExpressID
        );
        const ifcType = ifcTypes.find((t) => t.type === subset.type);
        if (ifcStorey?.visible && ifcType?.visible) {
          visibleIds.push(...subset.ids);
        } else {
          hiddenIds.push(...subset.ids);
        }
      });
      // create a new object with visible elements

      this.object = this.ifcLoader.ifcManager.createSubset({
        modelID: this.modelObject.modelID,
        ids: visibleIds,
        scene: this.modelObject,
        removePrevious: true,
        applyBVH: true,
      });
      console.log("object", this.object);
      this.object.layers.enable(1);
      this.object.layers.enable(3);
    }
  }

  hide() {
    console.log("hide ifc model entity", this.object);
    if (this.object) {
      this.object.layers.disable(1);
      this.object.layers.disable(3);
      this.object.visible = false;
    }
  }
  show() {
    if (this.object) {
      this.object?.layers.enable(1);
      this.object.layers.disable(3);
      this.object.visible = true;
    }
  }

  toggleVisibility() {
    console.log("toggle visibility");
    this.object.layers.toggle(1); // /!\ the container object is set with layer(1).disable
    //this.object.visible = !this.object.visible;
    // this.subsetsEntities.forEach((s) => {
    //   s.object.visible = !s.object.visible;
    // });
  }
  showAllVisibleSubsets() {
    console.log("show visible subsets");
  }

  hideAllVisibleSubsets() {
    console.log("hide visible subsets");
  }

  toggleSubsetVisibility(subsetUUID, visible) {
    const subset = this.subsetsEntities.find(
      (s) => s.ifcSubset.uuid === subsetUUID
    );
    subset?.toggleVisibility(visible);
  }

  toggleAllStoreysVisibility(visible, types) {
    const hiddenTypes = types
      .filter((t) => t.visible === false)
      .map((t) => t.type);
    this.subsetsEntities.forEach((subset) => {
      if (visible === false) {
        this.toggleSubsetVisibility(subset.ifcSubset.uuid, false);
      } else {
        if (!hiddenTypes.includes(subset.ifcSubset.type)) {
          this.toggleSubsetVisibility(subset.ifcSubset.uuid, true);
        }
      }
    });
  }

  toggleAllTypesVisibility(visible, storeys) {
    const hiddenStoreys = storeys
      ?.filter((s) => s.visible === false)
      .map((s) => s.expressID);
    this.subsetsEntities.forEach((subset) => {
      if (visible === false) {
        this.toggleSubsetVisibility(subset.ifcSubset.uuid, false);
      } else {
        if (!hiddenStoreys.includes(subset.ifcSubset.storeyExpressID)) {
          this.toggleSubsetVisibility(subset.ifcSubset.uuid, true);
        }
      }
    });
  }

  async spatialStructure() {
    const tree = await this.ifcLoader.ifcManager.getSpatialStructure(
      this.ifcModelID
    );
    return tree;
  }

  /*
   * Returns site properties
   * {location, axis, refDirection, props};}
   */
  async site() {
    return await getIfcModelSite(this.ifcLoader, this.ifcModelID);
  }

  async siteCoordinates() {
    const {location, refDirection} = await this.site();
    const theta = refDirection.theta();
    return {x: location.x, y: location.y, z: location.z, theta};
  }

  async project() {
    return await getIfcModelProject(this.ifcLoader, this.ifcModelID);
  }

  async leaves() {
    return await getSpatialStructureLeaves(this.ifcLoader, this.ifcModelID);
  }

  async items() {
    const tree = await this.ifcLoader.ifcManager.getSpatialStructure(
      this.ifcModelID
    );
    return flatSpatialStructure(tree);
  }

  async getEntityProps(expressID) {
    const props = await this.ifcLoader.ifcManager.getItemProperties(
      this.ifcModelID,
      expressID,
      true
    );
    return props;
  }

  async getObjectEntityByID(id) {
    const o = await this.ifcLoader.ifcManager.createSubset({
      modelID: this.ifcModelID,
      ids: [id],
      material: new MeshLambertMaterial({color: 0xffee58, depthTest: true}),
      //scene: this.scene,
      scene: this.object,
      removePrevious: true,
    });
    return o;
  }

  focus(camera, controls, expressID) {
    const boundingBox = new Box3();

    const center = boundingBox.getCenter(new Vector3());
    let pos = new Vector3(0, 0, 0);
    let cameraPos = new Vector3();
    let target;
    if (expressID) {
      target = this.getObjectEntityByID(expressID);
      boundingBox.setFromObject(target);
      pos = boundingBox.getCenter(new Vector3());
    } else {
      target = this.object;
    }
    cameraPos.copy(pos);
    cameraPos.sub(new Vector3(10, 0, 0));
    camera.position.copy(cameraPos);
    controls.target.copy(pos);
    controls.update();
  }

  deleteFrom(scene) {
    scene.remove(this.object);
    this.object.geometry.dispose();
  }

  disable() {
    if (this.object) {
      this.object.layers.disable(1);
    } else {
      console.log("The Ifc Model has not been loaded yet");
    }
  }

  enable() {
    if (this.object) {
      this.object.layers.enable(1);
    } else {
      console.log("The Ifc Model has not been loaded yet");
    }
  }

  getAllItemsOfType(type) {
    return Object.values(this.jsonProperties).filter(
      (item) => item.type === type
    );
  }

  cachePsets() {
    const psets = new Map();
    const reldefinesbyproperties = this.getAllItemsOfType(
      "IFCRELDEFINESBYPROPERTIES"
    );
    for (let rel of reldefinesbyproperties) {
      for (let relObj of rel.RelatedObjects) {
        const prop = this.jsonProperties[rel.RelatingPropertyDefinition?.value];
        if (psets.has(relObj.value)) {
          const arrayPsetQset = psets.get(relObj.value);
          arrayPsetQset.push({name: rel.Name?.value, prop: prop});
          psets.set(relObj.value, arrayPsetQset);
        } else {
          psets.set(relObj.value, [{name: rel.Name?.value, prop: prop}]);
        }
      }
    }
    return psets;
  }

  /*
   * 3D => 2D
   */

  async getElementPoints(expressID) {
    if (!expressID) return [];
    const s = await this.ifcLoader.ifcManager.createSubset({
      modelID: this.ifcModelID,
      ids: [expressID],
      material: this.tempMaterial,
      scene: this.object,
      removePrevious: true,
    });
    if (!s) return [];
    const _geometry = s.geometry;
    console.log("_geometry", _geometry);
    const geometry = _geometry.toNonIndexed();
    const positions = geometry.getAttribute("position");
    const normals = geometry.getAttribute("normal");

    const positionsCount = positions.count;
    const points = [];
    const pointsZ = [];
    for (let i = 0; i < positionsCount; i++) {
      const x = positions.array[3 * i];
      const y = positions.array[3 * i + 1];
      const z = positions.array[3 * i + 2];
      const v = new Vector3(x, y, z);
      this.modelObject.localToWorld(v);
      points.push({x: v.x, y: v.y, z: v.z});
      if (!pointsZ.includes(parseFloat(v.y.toFixed(4))))
        pointsZ.push(parseFloat(v.y.toFixed(4)));
    }
    return {points, pointsZ};
  }

  getSelectedElementPoints() {
    if (this.selectedElement) {
      const _geometry = this.selectedElement.geometry;
      const geometry = _geometry.toNonIndexed();
      const positions = geometry.getAttribute("position");
      const normals = geometry.getAttribute("normal");

      const positionsCount = positions.count;
      const points = [];
      const pointsZ = [];
      for (let i = 0; i < positionsCount / 3; i++) {
        const x = positions.array[3 * i];
        const y = positions.array[3 * i + 1];
        const z = positions.array[3 * i + 2];
        const v = new Vector3(x, y, z);
        this.modelObject.localToWorld(v);
        points.push({x: v.x, y: v.y, z: v.z});
        if (!pointsZ.includes(parseFloat(v.y.toFixed(4))))
          pointsZ.push(parseFloat(v.y.toFixed(4)));
      }
      return {points, pointsZ};
    }
  }

  getSelectedElementBB2DPath() {
    const s = this.selectedElement;

    const index = s.geometry.index.array;
    const idArray = s.geometry.attributes.expressID.array;
    const normalArray = s.geometry.attributes.normal.array;
    const positionArray = s.geometry.attributes.position.array;

    const newIdArray = [];
    const newNormalArray = [];
    const newPositionArray = [];
    index.forEach((i) => {
      newIdArray.push(idArray[i]);
      newNormalArray.push(
        normalArray[3 * i],
        normalArray[3 * i + 1],
        normalArray[3 * i + 2]
      );
      newPositionArray.push(
        positionArray[3 * i],
        positionArray[3 * i + 1],
        positionArray[3 * i + 2]
      );
    });

    const geometry = new BufferGeometry();

    geometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(newPositionArray), 3)
    );
    geometry.setAttribute(
      "expressID",
      new BufferAttribute(new Float32Array(newIdArray), 1)
    );
    geometry.setAttribute(
      "normal",
      new BufferAttribute(new Float32Array(newNormalArray), 3)
    );

    geometry.computeBoundingBox();
    const bb = geometry.boundingBox;

    const min = bb.min;
    const max = bb.max;
    const p1 = [min.x, min.z];
    const p2 = [max.x, min.z];
    const p3 = [max.x, max.z];
    const p4 = [min.x, max.z];
    return [p1, p2, p3, p4, p1];
  }
}
