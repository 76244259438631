import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setSelectedPackageId} from "Features/elementPackages/packagesSlice";

import {Button, Box} from "@mui/material";

export default function ButtonDataGridClearSelection({editor}) {
  const dispatch = useDispatch();

  // strings

  const clearS = "Clear selection";

  // data

  const id = useSelector((s) => s.packages.selectedPackageId);

  // helpers

  const isEmpty = !Boolean(id);

  // handlers

  function handleClear() {
    dispatch(setSelectedPackageId(null));
    //editor.sceneEditor.unselect();
  }

  // helpers

  return (
    <Box
      sx={{color: "text.secondary", visibility: isEmpty ? "hidden" : "visible"}}
    >
      <Button onClick={handleClear} size="small" color="inherit">
        {clearS}
      </Button>
    </Box>
  );
}
