import {useState} from "react";
import {nanoid} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import {
  IconButton,
  TextField,
  Typography,
  Box,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import {Refresh, Add} from "@mui/icons-material";

import {
  // setStoryDefaultId,
  updateScene,
  // updateStory,
} from "Features/scenes/scenesSlice";
import {uploadFileService} from "Features/files/services";
import useTranslation from "Features/translations/useTranslation";
// import {capitalizeStart} from "Features/translations/utils";
import useAccessToken from "Features/auth/useAccessToken";

export default function FormAddFrame({
  editor3d,
  scene,
  story,
  screenshot,
  onCreate,
}) {
  const dispatch = useDispatch();
  const {t} = useTranslation(["scenes", "common"]);
  const accessToken = useAccessToken();

  // string

  const frameTitleString = t("scenes:stories.story.frame.title");
  const frameDescriptionString = t("scenes:stories.story.frame.description");

  // const createString = capitalizeStart(t("common:create"));

  const addFrameString = "Ajouter la note";

  // state

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // handlers

  function handleClick(e) {
    e.stopPropagation();
  }

  async function handleImageRefresh() {
    if (accessToken && scene.id && story) {
      setLoading(true);
      const url = editor3d?.annotator.stage.toDataURL();
      const fileName = `story-image-${nanoid()}.png`;
      const response = await fetch(url);
      const blob = await response.blob();
      let file;
      let remoteUrl;
      file = new File([blob], fileName, {type: "image/png"});

      if (file) {
        remoteUrl = await uploadFileService({
          sceneId: scene.id,
          accessToken,
          file,
          container: "scene-story-images",
        });
      }

      setImageUrl(remoteUrl);
      setLoading(false);
    }
  }

  function handleCreateClick() {
    const newFrame = {
      id: nanoid(),
      title,
      description,
      imageUrl,
      context: screenshot.context,
    };

    const newFrames =
      story.frames?.length > 0 ? [...story.frames, newFrame] : [newFrame];

    const newStory = {...story, frames: newFrames};

    const newStories = scene.data.stories.map((s) => {
      if (s.id === newStory.id) {
        return newStory;
      } else {
        return s;
      }
    });

    const newScene = {...scene, data: {...scene.data, stories: newStories}};

    dispatch(updateScene({scene: newScene}));

    onCreate();
  }
  return (
    <Paper
      sx={{
        width: 250,
        border: (theme) => `1px solid ${theme.palette.divider} `,
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          p: 1,
          //backgroundColor: "background.default",
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          minHeight: 200,
          //opacity: 0.8,
        }}
      >
        <IconButton
          sx={{
            mb: 2,
            position: "absolute",
            top: (theme) => theme.spacing(0.5),
            right: (theme) => theme.spacing(0.5),
          }}
          size="small"
          onClick={handleImageRefresh}
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <Refresh fontSize="small" color="primary" />
          )}
        </IconButton>
        <Box
          sx={{
            p: 0.5,
            borderRadius: "8px",
          }}
        >
          <TextField
            sx={{mb: 2}}
            size="small"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            label={<Typography variant="body2">{frameTitleString}</Typography>}
          />
          <TextField
            size="small"
            fullWidth
            multiline
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label={
              <Typography variant="body2">{frameDescriptionString}</Typography>
            }
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
          />
        </Box>
      </Box>
      <Button
        sx={{m: 1}}
        startIcon={<Add />}
        size="small"
        variant="contained"
        onClick={handleCreateClick}
      >
        {addFrameString}
      </Button>
    </Paper>
  );
}
