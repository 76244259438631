/*
 * sector : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneSector(scene) {
  const dispatch = useDispatch();

  const updateSceneSector = (sector) => {
    if (!scene) return;
    let oldSectors = scene?.data?.sectors.filter((s) => s?.id !== sector.id);
    if (!oldSectors) oldSectors = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, sectors: [...oldSectors, sector]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneSector;
}
