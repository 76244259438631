import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "API/capla360";

const authInitialState = {
  status: "idle",
  data: {
    id: null,
  },
  triggerTokenAt: null, // used to force accessToken silent fetch (cf useAccessToken). Use to get sceneToSync.
};

export const fetchUserId = createAsyncThunk(
  "auth/fetchUserId",
  async ({accessToken}) => {
    const response = await client.get(`/signin/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    triggerToken: (state) => {
      state.triggerTokenAt = Date.now();
    },
  },
  extraReducers: {
    [fetchUserId.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUserId.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.data = action.payload;
    },
    [fetchUserId.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const {triggerToken} = authSlice.actions;

export default authSlice.reducer;
