import {Object3D, Vector3} from "three";
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer.js";

import closeLogo from "../assets/close.png";
import theme from "Styles/theme";

export default class MarkerTemp {
  object;
  objectCSS;
  marker;
  overed; // used to know if the mouse is overing the marker => in handleMoveEditor, the pointer is kept disabled.
  onClose; // handle click on the close button
  delta; // delta position with respect to position.
  position; // used to copy the value
  normal;

  constructor({editor, color, dashed, closable}) {
    this.editor = editor;
    this.delta = 0.001;

    this.color = color;
    this.dashed = dashed; // border style
    this.closable = closable;

    this.create();
  }

  setCloseHandler(handler) {
    this.onClose = handler;
  }

  create() {
    const object = new Object3D();
    object.layers.enable(1);

    // handlers

    const handleCloseClick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.hide();
      if (this.onClose) this.onClose();
    };

    const handleCloseOver = () => {
      this.overed = true;
      this.editor.pointer.hide();
      this.editor.disableClickHandlers();
    };
    const handleCloseLeave = () => {
      this.overed = false;
      this.editor.pointer.show();
      this.editor.enableClickHandlers();
    };

    // label
    const marker = document.createElement("div");
    const closeButton = document.createElement("div");

    if (this.closable) marker.appendChild(closeButton);

    marker.style.height = "20px";
    marker.style.width = "20px";
    marker.style.borderRadius = "10px";
    marker.style.transform = "translate(-10px,-10px)";
    if (this.dashed) marker.style.border = `2px dotted ${this.color}`;
    if (!this.dashed) marker.style.border = `2px solid ${this.color}`;

    if (this.closable) {
      marker.style.cursor = "pointer";
      marker.addEventListener("pointerdown", handleCloseClick);
      marker.addEventListener("pointerover", handleCloseOver);
      marker.addEventListener("pointerleave", handleCloseLeave);
    }

    closeButton.style.height = "16px";
    closeButton.style.width = "16px";
    closeButton.style.backgroundColor = "white";
    closeButton.style.backgroundSize = "cover";
    closeButton.style.backgroundRepeat = "no-repeat";
    closeButton.style.backgroundPosition = "center center";
    closeButton.style.backgroundImage = `url(${closeLogo})`;
    closeButton.style.borderRadius = "8px";
    closeButton.style.position = "absolute";
    closeButton.style.left = "20px";

    closeButton.style.transform = "translate(-8px,-8px)";
    closeButton.style.cursor = "pointer";

    //closeButton.addEventListener("pointerdown", handleCloseClick);
    //closeButton.addEventListener("pointerover", handleCloseOver);
    //closeButton.addEventListener("pointerleave", handleCloseLeave);

    const objectCSS = new CSS2DObject(marker);
    objectCSS.layers.enable(1);

    object.add(objectCSS);

    this.objectCSS = objectCSS;
    this.object = object;
    this.marker = marker;
    this.closeButton = closeButton;

    this.editor.scene.add(object);
  }

  setPosition(p, n) {
    this.position = p;
    this.normal = n;
    if (!n) {
      this.object.position.set(p.x, p.y, p.z);
    } else {
      const positionV = new Vector3(p.x, p.y, p.z);
      const offsetV = new Vector3(n.x, n.y, n.z).normalize();
      positionV.add(offsetV.multiplyScalar(this.delta));
      this.object.position.copy(positionV);
    }
  }

  // show & hide

  show() {
    this.object.layers.enable(1);
    this.objectCSS.layers.enable(1);
    this.marker.hidden = false;
    this.closeButton.hidden = false;
  }

  hide() {
    console.log("hide");
    this.object.layers.disable(1);
    this.objectCSS.layers.disable(1);
    this.marker.hidden = true;
    this.closeButton.hidden = true;
  }
}
