import {useState} from "react";

import useActionsRoomsMap from "../hooks/useActionsRoomsMap";
import useSelectedRoomsGroup from "../hooks/useSelectedRoomsGroup";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateRoomsGroup from "./DialogCreateRoomsGroup";
import DialogCreateRoom from "./DialogCreateRoom";
import DialogFsEditRoomsGroup from "./DialogFsEditRoomsGroup";
import DialogDeleteRoomsGroup from "./DialogDeleteRoomsGroup";
//import DialogDeleteRoom from "./DialogDeleteRoom";
//import DialogImportRoomsFromExcel from "./DialogImportRoomsFromExcel";

export default function SectionActionsRooms({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  const selectedRoomsGroup = useSelectedRoomsGroup(scene);

  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateRoomsGroup, setOpenDialogCreateRoomsGroup] =
    useState(false);

  const [openDialogDeleteRoomsGroup, setOpenDialogDeleteRoomsGroup] =
    useState(false);
  const [openDialogCreateRoom, setOpenDialogCreateRoom] = useState(false);
  //
  const [openDialogDeleteRoom, setOpenDialogDeleteRoom] = useState(false);
  //
  const [openDialogImportRoomsFromExcel, setOpenDialogImportRoomsFromExcel] =
    useState(false);
  //
  const [openDialogEditRoomsGroup, setOpenDialogEditRoomsGroup] =
    useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createRoomsGroup: () => setOpenDialogCreateRoomsGroup(true),
    deleteRoomsGroup: () => setOpenDialogDeleteRoomsGroup(true),
    createRoom: () => setOpenDialogCreateRoom(true),
    deleteRoom: () => setOpenDialogDeleteRoom(true),
    importExcelRooms: () => setOpenDialogImportRoomsFromExcel(true),
    editRoomsGroup: () => setOpenDialogEditRoomsGroup(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateRoomsGroup() {
    setOpenDialogCreateRoomsGroup(false);
  }
  function handleCloseDialogCreateRoom() {
    setOpenDialogCreateRoom(false);
  }

  // data - handlers

  const actionsMap = useActionsRoomsMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateRoomsGroup && (
        <DialogCreateRoomsGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateRoomsGroup}
          onClose={handleCloseDialogCreateRoomsGroup}
        />
      )}
      {openDialogCreateRoom && (
        <DialogCreateRoom
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateRoom}
          onClose={handleCloseDialogCreateRoom}
        />
      )}
      {openDialogEditRoomsGroup && (
        <DialogFsEditRoomsGroup
          open={openDialogEditRoomsGroup}
          onClose={() => {
            setOpenDialogEditRoomsGroup(false);
          }}
          scene={scene}
          roomsGroup={selectedRoomsGroup}
        />
      )}
      {openDialogDeleteRoomsGroup && (
        <DialogDeleteRoomsGroup
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteRoomsGroup}
          onClose={() => setOpenDialogDeleteRoomsGroup(false)}
        />
      )}

      {/* {openDialogDeleteRoom && (
        <DialogDeleteRoom
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogDeleteRoom}
          onClose={() => setOpenDialogDeleteRoom(false)}
        />
      )} */}
      {/* {openDialogImportRoomsFromExcel && (
        <DialogImportRoomsFromExcel
          open={openDialogImportRoomsFromExcel}
          onClose={() => {
            setOpenDialogImportRoomsFromExcel(false);
          }}
          scene={scene}
        />
      )} */}
    </>
  );
}
