import React, {useState} from "react";

import useUpdateSceneRoomsFromAnonymRooms from "../hooks/useUpdateSceneRoomsFromAnonymRooms";
import useUpdateSceneRooms from "../hooks/useUpdateSceneRooms";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getRoomsFromFile from "Features/excel/utils/getRoomsFromFile";
import excelRoomsToRooms from "../utils/excelRoomsToRooms";

export default function DialogImportRoomsFromExcel({open, onClose, scene}) {
  // strings

  const title = "Importez des zones";

  const message = `L'import utilise la colonne "code" pour savoir s'il faut créer une nouvelle zone ou modifier la zone existance.`;

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const updateRooms = useUpdateSceneRooms(scene);

  // state

  const [rooms, setRooms] = useState(null);
  const [file, setFile] = useState(null);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    let rooms = await getRoomsFromFile(file);
    rooms = excelRoomsToRooms(rooms);
    setRooms(rooms);
  }
  function handleCancelClick() {
    onClose();
  }
  function handleSaveClick() {
    updateRooms(rooms);
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{bgcolor: "background.default", p: 1}}>
            <Typography variant="body2">{message}</Typography>
          </Box>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
