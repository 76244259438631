import {useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import TextEditor from "./TextEditor/TextEditor";
import Toolbar from "./Toolbar";
import LinkedElements from "./LinkedElements";

import {
  // selectContext,
  setScreenshot,
  setMainSection,
} from "Features/viewer3D/viewer3DSlice";
import useMeasurementsFiltersAndShortcuts from "Features/measurements/useMeasurementsFilters";

export default function RichTextEditor({
  initialValue,
  onChange,
  onSaveClick,
  loading,
  listingId,
  accessToken,
  caplaEditor,
  isNew,
  editing = false,
  closeButton = false,
  scene,
  sceneClientId,
  initialNoteElements,
  showLinkedElements = true,
  viewpoint,
  showSave = true,
}) {
  const editorRef = useRef();
  const dispatch = useDispatch();

  // data

  //const context = useSelector(selectContext(sceneClientId));
  const models = useSelector((state) => state.viewer3D.models).filter(
    (m) => m.sceneClientId === sceneClientId
  );
  const visibleModelsIds = models.filter((m) => !m.hidden).map((m) => m.id);
  const imageModelIds = models
    .filter((m) => !m.hidden && m.type === "IMAGE")
    .map((m) => m.id);
  const [measurementsFilters] = useMeasurementsFiltersAndShortcuts(scene);
  const pdfExtractedText = useSelector((s) => s.pdf.extractedText);

  // local state

  const [content, setContent] = useState(initialValue);
  const [tempCamera, setTempCamera] = useState(); // set by initViewpoint and used by viewpoint.
  const [tempContext, setTempContext] = useState(); // idem

  // helpers

  async function takeScreenshot(editor) {
    // first render
    const camera = editor.cameras.activeCamera;
    editor.renderer.render(editor.scene, camera);

    // then cature
    const canvas = editor.canvas;
    return new Promise((resolve) => {
      canvas.toBlob(async (blob) => {
        const fileName = `bimbox-${nanoid()}.png`;
        const file = new File([blob], fileName, {type: "image/png"});
        resolve({url: URL.createObjectURL(file), file});
      });
    });
  }

  // helpers - showToolbar

  const {pageNumber} = pdfExtractedText;
  const showToolbar = editing && (pageNumber || showSave || viewpoint);

  // handlers

  async function handleToolClick(tool) {
    if (tool === "action") {
      editorRef.current.addAction("action");
    }
    if (tool === "pdfExtractedText") {
      editorRef.current.addPdfExtractedText(pdfExtractedText);
    }
    if (tool === "viewpoint") {
      dispatch(setMainSection(null));
      dispatch(setScreenshot({url: null}));
      const url = caplaEditor?.editor3d?.annotator.stage.toDataURL();
      const fileName = `note-image-${nanoid()}.png`;
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], fileName, {type: "image/png"});
      editorRef.current.addViewpoint({
        file,
        image: url,
        camera: tempCamera,
        context: tempContext,
      });
    }
    if (tool === "initViewpoint") {
      const {_, url} = await takeScreenshot(caplaEditor?.editor3d);
      const camera = caplaEditor?.editor3d?.cameras.getActiveCamera(
        caplaEditor?.editor3d?.controls.activeControls
      );
      const context = caplaEditor?.editor3d?.context.getContext({models});
      context.sceneContext = {
        ...context.sceneContext,
        visibleModelsIds,
        measurementsFilters,
        imageModelIds,
      };
      setTempCamera(camera);
      setTempContext(context);
      dispatch(setMainSection("SCREENSHOTVIEWER"));
      dispatch(setScreenshot({url, context, camera}));
    }
  }

  function handleSaveClick() {
    onSaveClick();
    console.log(content);
  }

  function handleContentChange(newContent) {
    setContent(newContent);
    onChange(newContent);
  }

  function handleAnnotationCancel() {
    dispatch(setMainSection(null));
    dispatch(setScreenshot({url: null}));
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        boxSizing: "border-box",
        position: "relative",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextEditor
          ref={editorRef}
          initialValue={initialValue}
          onChange={handleContentChange}
          listingId={listingId}
          accessToken={accessToken}
          caplaEditor={caplaEditor}
          readOnly={!editing}
          sceneClientId={sceneClientId}
        />
      </Box>
      {showLinkedElements && (
        <LinkedElements
          editing={editing}
          initialLinkedElements={initialNoteElements}
        />
      )}

      {showToolbar && (
        <Box sx={{py: 1}}>
          <Toolbar
            onToolClick={handleToolClick}
            onSave={handleSaveClick}
            loading={loading}
            update={!isNew}
            closeButton={closeButton}
            onAnnotationCancel={handleAnnotationCancel}
            viewpoint={viewpoint}
            showSave={showSave}
          />
        </Box>
      )}
    </Box>
  );
}
