import getItemsMapById from "Utils/getItemsMapById";

export default function getPackageMetadata(measurements, sceneData) {
  if (!sceneData || !Array.isArray(measurements)) return {};

  const resMap = getItemsMapById(sceneData.ressources);
  const typeMap = getItemsMapById(sceneData.ressources);

  const metaTypesMap = new Set();
  const metaResMap = new Set();

  measurements.forEach((measurement) => {
    const res = measurement.res ?? [];
    const ressources = res.map(({resId}) => resMap[resId]);
    ressources.forEach((ressource) => {
      if (ressource) metaResMap.add(ressource.group);
    });
    const type = typeMap[measurement.elementTypeId];
    if (type) metaTypesMap.add(type.groupId);
  });

  return {
    measCount: measurements.length,
    elementTypesGroupIds: [...metaTypesMap],
    ressourcesGroupIds: [...metaResMap],
  };
}
