import {Box} from "@mui/material";

import ListViews from "./ListViews";

export default function SectionViewsInLeftPanel({scene}) {
  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <ListViews scene={scene} />
    </Box>
  );
}
