import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormMaterial({material, onChange, canEdit, hiddenFields = []}) {
  // strings

  const numS = "Num";
  const nameS = "Nom";
  const codeS = "Code";
  const colorS = "Couleur";

  // template

  const template = {
    key: "kvfMaterial",
    fields: [
      {key: "num", type: "text", name: numS},
      {key: "name", type: "text", name: nameS},
      {key: "code", type: "text", name: codeS},
      {key: "color", type: "color", name: colorS},
    ],
  };
  return material ? (
    <KeyValueForm
      item={material}
      template={template}
      onChange={onChange}
      hiddenFields={hiddenFields}
      canEdit={canEdit}
    />
  ) : (
    <div />
  );
}
