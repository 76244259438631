import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

import FieldCombo from "Features/kvform/components/FieldCombo";

export default function FieldItemElementTypeId({
  item,
  value,
  scene,
  onChange,
  locked,
  sizeKey,
}) {
  // string

  const name = "Type";

  // data

  // Use only the elementTypes that are not title
  const types = useElementTypesBySceneProxy(scene).filter((t) => !t.isTitle);

  // helpers

  const elementType = types.find((t) => t.id === value);

  let options = types
  .map((t) => {
    return {...t, name: `${t.group} - ${t.num} - ${t.name}`};
  });
  // if (item.id) {
  // restrict to drawingShape
  options = options.filter((t) => t.drawingShape === item.drawingShape);
  // }

  // handlers

  function handleChange(elementType) {
    onChange(elementType?.id ? elementType.id : null);
  }
  return (
    <FieldCombo
      name={name}
      value={elementType}
      options={options}
      onChange={handleChange}
      locked={locked}
      sizeKey={sizeKey}
    />
  );
}