import {useState} from "react";

import {
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Switch,
  Box,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";

export default function CardModelDisplayOptions({model, editor3d}) {
  // const dispatch = useDispatch();
  const {t} = useTranslation("viewer3D");

  // strings

  const caplaColorsString = t("model.settings.display.caplaColors");
  const edgesString = t("model.settings.display.edges");

  // data

  const checked = Boolean(model.caplaColors);

  const entity = editor3d?.getEntity(model.id);
  const edges = entity?.edges?.visible;

  // state

  const [showEdges, setShowEdges] = useState(edges);

  // handlers

  function handleSwitchChange(e) {
    const checked = e.target.checked;
    const entity = editor3d.getEntity(model.id);
    if (entity?.switchColorsTo)
      entity.switchColorsTo(checked ? "CAPLA" : "ORIGIN");
  }

  function handleEdgesChange(e) {
    const checked = e.target.checked;
    if (checked) {
      entity.edges?.show();
      setShowEdges(true);
    } else {
      entity.edges?.hide();
      setShowEdges(false);
    }
  }

  return (
    <Box sx={{p: 2}}>
      <Card>
        <CardContent>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={showEdges}
                onChange={handleEdgesChange}
              />
            }
            label={<Typography variant="body2">{edgesString}</Typography>}
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={checked}
                onChange={handleSwitchChange}
              />
            }
            label={<Typography variant="body2">{caplaColorsString}</Typography>}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
