import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {updateMarker} from "Features/viewer3D/viewer3DSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
export default function DialogLocateMarker({open, onClose, locatedMarker}) {
  const dispatch = useDispatch();

  // strings

  const titleString = "Locate the marker";
  const latString = "Latitude";
  const lngString = "Longitude";
  const saveString = "Save";

  // state
  const initLat = "48.85307";
  const initLng = "2.34996";
  const [lat, setLat] = useState(initLat);
  const [lng, setLng] = useState(initLng);

  // handlers - form

  function handleLatChange(e) {
    let l = e.target.value;
    l = l.replace(",", ".");
    setLat(l);
  }

  function handleLngChange(e) {
    let l = e.target.value;
    l = l.replace(",", ".");
    setLng(l);
  }

  // handlers - onSave

  function handleSave() {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);
    const updatedMarker = {...locatedMarker, lat: latitude, lng: longitude};
    dispatch(updateMarker(updatedMarker));
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleString}</DialogTitle>
      <DialogContent>
        <Box sx={{display: "flex", mt: 1}}>
          <TextField
            label={<Typography variant="body2">{latString}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={lat}
            onChange={handleLatChange}
          />
          <TextField
            sx={{ml: 2}}
            label={<Typography variant="body2">{lngString}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={lng}
            onChange={handleLngChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{saveString}</Button>
      </DialogActions>
    </Dialog>
  );
}
