import {
  setTempObjects,
  setObjectsSelection,
  setSelection,
  setToolData,
  setShowToolAddElement,
} from "Features/viewer3D/viewer3DSlice";

import theme from "Styles/theme";

import {nanoid} from "@reduxjs/toolkit";

import {MarkerClusterer} from "@googlemaps/markerclusterer";

export default class MapEditor {
  tempObjects; // used to store temporary objects in creation process. The objects are saved explicitely.
  objects; // objects linked to the map. object = {id,codeName,modelId,draggable}

  markers; // markers ploted on the map [{objectId,marker}]

  isAddingObjects; // used to add objects on click.
  editTool; // used to now which editing tool is being selected. "POINT",... in state : viewer3D.editTool.
  map; // google map
  maps; // object created by googlemap api

  isloaded; // map & maps object are created
  initialModelsLoaded; // first capla models are loaded.

  icon; // icon used in the marker.
  selectedIcon; // selected icon.
  iconsMap; // {"color":icon,....}, map to get the icon color.

  pickedId; // objectId of the picked object.
  selectedIds; // objectIds of the selection.
  selectedModelId; // modelId selected in the map.

  onMapClick; // handler to pass click pointer props to main state.

  sceneOriginLatLng; // {lat,lng} of the scene origin

  editingNote; // used when editing a note. Prevent detail panel opening.

  showedModels; // [modelId,] Models being displayed in the map.

  target; // marker used in the panorama view
  panorama; // panorama

  tempColor; // color used for temporary markers.
  defaultColor; // default color.

  constructor({editor}) {
    this.editor = editor;

    this.tempObjects = [];
    this.objects = [];

    this.markers = [];
    this.iconsMap = {};

    this.selectedIds = [];
    this.pickedId = null;

    this.isLoaded = false;
    this.initialModelsLoaded = false;

    this.editingNote = false;

    this.showedModels = [];

    this.tempColor = theme.palette.primary.light;
    this.defaultColor = "#FFFFFF";
  }

  // Init

  setIsLoaded(bool) {
    this.isLoaded = bool;
  }

  setInitialModelsLoaded(bool) {
    this.initialModelsLoaded = bool;
  }

  initClusterer() {
    const googleMarkers = this.markers
      .filter((marker) => this.showedModels.includes(marker.modelId))
      .map((m) => m.marker);
    this.clusterer = new MarkerClusterer({
      markers: googleMarkers,
      map: this.map,
    });
  }

  // Scene

  setSceneOriginLatLng = (latLng) => {
    this.sceneOriginLatLng = latLng;
  };

  setEditingNote(bool) {
    this.editingNote = bool;
  }

  // Map

  addMapListeners = () => {
    this.map.addListener("click", (e) => {
      const position = e.latLng.toJSON();
      if (this.editTool === "POINT") {
        this.updateTempObjectsOnClick(position);
      }
    });
  };

  setEditTool(tool) {
    this.editTool = tool;
  }

  setMapClickHandler(handler) {
    this.onMapClick = handler;
  }

  // icons

  getIcon(color) {
    let icon;
    icon = this.iconsMap[color];
    if (!icon) {
      icon = this.createIcon(color, 2);
      this.iconsMap[color] = icon;
    }
    return icon;
  }

  createIcon = (strokeColor, strokeWeight) => {
    return {
      path: "m 10 10 l 80 0 l 0 80 l -80 0 z m 40 0 l 0 20 m 0 40 l 0 20 m -40 -40 l 20 0 m 40 0 l 20 0",
      strokeWeight,
      strokeColor,
      rotation: 0,
      scale: 0.5,
      anchor: new this.maps.Point(50, 50),
    };
  };

  initIcons() {
    this.icon = this.createIcon(theme.palette.primary.light, 2);
    this.selectedIcon = this.createIcon(theme.palette.primary.main, 4);
    this.pickedIcon = this.createIcon("#FFFF00", 4);
    this.targetIcon = this.createIcon(theme.palette.common.white, 2);
  }

  setCursor(type) {
    if (this.map) this.map.setOptions({draggableCursor: type});
  }

  getLatLng() {
    return this.map.getCenter().toJSON();
  }

  addMarkerClusterer() {
    const googleMarkers = this.markers.map((m) => m.marker);
  }

  clearMap() {
    this.markers = [];
    this.removeAllMarkers();
  }

  setCenter(latLng) {
    this.map.setCenter(latLng);
  }

  getCenter() {
    return this.map.getCenter().toJSON();
  }

  getBounds() {
    const mapBounds = this.map.getBounds();
    const swLatLng = mapBounds.getNorthEast().toJSON();
    const neLatLng = mapBounds.getSouthWest().toJSON();
    const swCoord = this.latLngToCoord(swLatLng);
    const neCoord = this.latLngToCoord(neLatLng);
    return {
      swPosition: {...swLatLng, ...swCoord},
      nePosition: {...neLatLng, ...neCoord},
    };
  }
  // panorama

  initTarget() {
    this.target = new this.maps.Marker({
      position: this.map.getCenter(),
      draggable: true,
      icon: this.targetIcon,
    });

    this.maps.event.addListener(this.target, "click", () => {
      const latLng = this.target.getPosition().toJSON();
      this.updateTempObjectsOnClick(latLng);
    });
  }

  initPanorama() {
    this.panorama = this.map.getStreetView();
  }

  addPanoramaListeners() {
    this.panorama.addListener("visible_changed", (e) => {
      const show = this.panorama.getVisible();
      if (show) {
        this.target.setMap(this.panorama);
        const heading = this.panorama.getPov().heading;
        const position = this.panorama.getPosition();

        console.log("position 1", position);
        // const marker_position = this.maps.geometry.spherical.computeOffset(
        //   position,
        //   10,
        //   heading
        // );

        // this.target.setPosition(marker_position);
        this.editor.dispatch(setShowToolAddElement(false));
      } else {
        this.editor.dispatch(setShowToolAddElement(true));
        this.target.setMap(null);
      }
    });

    this.panorama.addListener("pov_changed", (e) => {
      const heading = this.panorama.getPov().heading;
      const position = this.panorama.getPosition();

      //console.log("position 2", position);
      const marker_position = this.maps.geometry.spherical.computeOffset(
        position,
        10,
        heading
      );
      this.target.setPosition(marker_position);
    });

    this.panorama.addListener("position_changed", (e) => {
      const heading = this.panorama.getPov().heading;
      const position = this.panorama.getPosition();

      //console.log("position 3", position);
      const marker_position = this.maps.geometry.spherical.computeOffset(
        position,
        10,
        heading
      );
      this.target.setPosition(marker_position);
    });
  }
  // clusterer

  removeModelFromClusterer(modelId) {
    const markers = this.markers
      .filter((m) => m.modelId === modelId)
      .map((m) => m.marker);
    this.clusterer.removeMarkers(markers);
    //this.clusterer.render();
  }

  addModelToClusterer(modelId) {
    const markers = this.markers
      .filter((m) => m.modelId === modelId)
      .map((m) => m.marker);
    this.clusterer.addMarkers(markers);
    //this.clusterer.render();
  }

  // temp objects

  setTempObjects(tempObjects) {
    this.tempObjects = tempObjects;
    this.editor.dispatch(setTempObjects(tempObjects));
  }

  addTempObject(tempObject) {
    console.log("new temp object", tempObject);
    const newTempObjects = [...this.tempObjects, tempObject];
    this.setTempObjects(newTempObjects);
    if (tempObject.type === "POINT") {
      this.addMarker({
        objectId: tempObject.id,
        lat: tempObject.lat,
        lng: tempObject.lng,
        draggable: true,
        temp: true,
        color: this.tempColor,
      });
    }
  }

  clearTempObjects() {
    this.tempObjects.forEach((object) => {
      if (object.type === "POINT") {
        const markerObject = this.markers.find((m) => m.objectId === object.id);
        this.removeMarker(markerObject?.marker);
      }
    });
    this.setTempObjects([]);
  }

  saveTempObjects({modelId, color}) {
    const tempObjectIds = this.tempObjects.map((o) => o.id);
    this.markers = this.markers.map((m) => {
      if (tempObjectIds.includes(m.objectId)) {
        m.marker.setDraggable(false);
        return {...m, temp: false, color, modelId};
      } else {
        return m;
      }
    });
    this.objects.push(...this.tempObjects);
    this.setTempObjects([]);
  }

  // other

  setIsAddingObjects(bool) {
    this.isAddingObjects = bool;
  }

  setMap(map) {
    this.map = map;
  }

  setMaps(maps) {
    console.log("setting maps...");
    this.maps = maps;
  }

  goTo({lat, lng}) {
    console.log("GO TO ", lat, lng);
    const point = new this.maps.LatLng(lat, lng);
    this.map.panTo(point);
    const zoom = this.map.getZoom();
    if (zoom < 17) this.map.setZoom(17);
  }

  goToElement(modelId, codeName) {
    const object = this.objects.find(
      (object) =>
        object.elementCodeName === codeName && object.modelId === modelId
    );
    if (object?.lat) {
      const point = new this.maps.LatLng(object.lat, object.lng);
      this.map.panTo(point);
      this.map.setZoom(19);
    }
  }

  selectObjects(selection) {
    this.editor.dispatch(setObjectsSelection(selection));
  }

  addObject(object) {
    // test if it exists
    const prevIds = this.objects.map((o) => o.id);
    const isNew = !prevIds.includes(object.id);

    if (isNew) {
      this.objects = [...this.objects, object];
      this.addMarker({
        objectId: object.id,
        modelId: object.modelId,
        lat: object.lat,
        lng: object.lng,
        color: object.color,
        draggable: false,
        temp: false,
      });
    }
  }

  selectObject(objectId) {
    let selection;
    if (this.selectedIds.includes(objectId)) {
      this.selectedIds = [];
    } else {
      this.selectedIds = [objectId];
      const object = this.objects.find((o) => o.id === objectId);
      if (object) {
        selection = {
          type: "CAPLA_ELEMENT",
          name: object.elementCodeName,
          codeName: object.elementCodeName,
          modelId: object.modelId,
          position: {
            lat: object.lat,
            lng: object.lng,
            x: object.x,
            y: object.y,
            z: object.z,
          },
        };

        // if (!this.editingNote) {
        //   this.editor.dispatch(setToolData("ITEM_PROPERTIES"));
        // }
      }
    }
    this.editor.dispatch(setSelection(selection));
    this.updateIcons();
  }

  selectElementObjects(modelId, codeName) {
    const object = this.objects.find(
      (object) =>
        object.elementCodeName === codeName && object.modelId === modelId
    );
    if (object) {
      this.selectObject(object.id);
    }
  }

  pickObject(objectId) {
    console.log("pick", objectId);
    this.pickedId = objectId;
    this.updateIcons();
  }

  unpickObject(objectId) {
    console.log("unpick", objectId, this.pickedId);
    if (this.pickedId === objectId) {
      this.pickedId = null;
      this.updateIcons();
    }
  }

  updateIcons() {
    try {
      this.markers.forEach((marker) => {
        if (marker.objectId === this.pickedId) {
          marker.marker.setIcon(this.pickedIcon);
        } else if (this.selectedIds.includes(marker.objectId)) {
          marker.marker?.setIcon(this.selectedIcon);
        } else {
          marker.marker?.setIcon(this.getIcon(marker.color));
        }
      });
    } catch (e) {
      console.log(e);
    }

    // this.objects.forEach((object) => {
    //   if (object.id === this.pickedId) {
    //     object.marker?.setIcon(this.pickedIcon);
    //   } else if (this.selectedIds.includes(object.id)) {
    //     object.marker?.setIcon(this.selectedIcon);
    //   } else {
    //     object.marker?.setIcon(this.icon);
    //   }
    // });
  }

  updateMarkersVisibility() {
    this.markers.forEach((marker) => {
      if (this.showedModels.includes(marker.modelId)) {
        marker.marker.setMap(this.map);
      } else {
        marker.marker.setMap(null);
      }
    });
  }

  // markers

  plotMarker = ({lat, lng, draggable, color}) => {
    const marker = new this.maps.Marker({
      map: this.map,
      position: {lat, lng},
      draggable,
      icon: this.getIcon(color),
    });
    return marker;
  };

  addMarkerListeners(marker, objectId) {
    this.maps.event.addListener(marker, "mouseover", () => {
      this.pickObject(objectId);
    });

    this.maps.event.addListener(marker, "mouseout", () => {
      this.unpickObject(objectId);
    });

    this.maps.event.addListener(marker, "click", () => {
      this.selectObject(objectId);
    });

    this.maps.event.addListener(marker, "dragend", () => {
      this.onMarkerDragEnd(objectId);
    });
  }

  addMarker({lat, lng, draggable, modelId, objectId, temp, color}) {
    if (lat && lng) {
      const marker = this.plotMarker({lat, lng, draggable, color});
      this.addMarkerListeners(marker, objectId);
      this.markers.push({marker, objectId, modelId, temp, color});
    }
  }

  removeMarker(marker) {
    if (marker) {
      marker.setMap(null);
    }
  }

  removeAllMarkers() {
    this.markers.map((m) => this.removeMarker(m.marker));
  }

  onMarkerDragEnd(objectId) {
    const markerObject = this.markers.find((m) => m.objectId === objectId);
    const newPosition = markerObject.marker.getPosition().toJSON();
    const coord = this.latLngToCoord(newPosition);
    if (markerObject.temp) {
      const newTempObjects = this.tempObjects.map((o) => {
        if (o.id === objectId) {
          return {...o, lat: newPosition.lat, lng: newPosition.lng, ...coord};
        } else {
          return o;
        }
      });
      this.setTempObjects(newTempObjects);
    }
    // update marker in 3D.
    this.editor.sceneEditor.setTempObjectPosition(coord);
  }

  // object

  plotObject(object) {
    const {lat, lng, draggable, id: objectId} = object;
    if (lat && lng) {
      const marker = this.plotMarker({lat, lng, draggable});
      this.addMarkerListeners(marker, objectId);
    }
  }

  updateTempObjectsOnClick(position) {
    let {x, y, z, lat, lng} = position;
    if (!x) {
      const coord = this.latLngToCoord(position);
      x = coord.x;
      y = coord.y;
      z = coord.z;
    }
    if (!lat) {
      const latLng = this.coordToLatLng(position);
      lat = latLng.lat;
      lng = latLng.lng;
    }
    const object = {
      id: nanoid(),
      type: "POINT",
      lat,
      lng,
      x,
      y,
      z,
      draggable: true,
    };
    this.clearTempObjects();
    this.addTempObject(object);
  }

  // models

  updateModelColor(modelId, color) {
    const newMarkers = this.markers.map((marker) => {
      if (marker.modelId === modelId) {
        const newMarker = {...marker, color};
        return newMarker;
      } else {
        return marker;
      }
    });
    this.markers = newMarkers;
    this.updateIcons();
  }

  loadElement(element, modelId, color) {
    let {x, y, z, lat, lng} = element.position;
    if (!lat || !lng) {
      const latLng = this.coordToLatLng({x, y, z});
      lat = latLng.lat;
      lng = latLng.lng;
    }
    const object = {
      id: nanoid(),
      type: "POINT",
      lat,
      lng,
      x,
      y,
      z,
      elementCodeName: element.codeName,
      modelId,
      color: color ? color : this.defaultColor,
    };
    this.addObject(object);
  }

  loadModelElements(model) {
    model.elements.items.forEach((item) =>
      this.loadElement(item, model.id, model.color)
    );
  }

  loadModels(models) {
    models.forEach((model) => {
      if (model.elements?.items) {
        this.loadModelElements(model);
      }
    });
    const showedModels = models.filter((m) => !m.hidden).map((m) => m.id);
    this.showedModels = showedModels;

    this.updateMarkersVisibility();

    this.initClusterer();
  }

  hideModel(modelId) {
    this.markers.forEach((marker) => {
      if (marker.modelId === modelId) {
        marker.marker.setMap(null);
      }
    });
    this.removeModelFromClusterer(modelId);
  }

  showModel(modelId) {
    this.markers.forEach((marker) => {
      if (marker.modelId === modelId) {
        marker.marker.setMap(this.map);
      }
    });
    this.addModelToClusterer(modelId);
  }

  // conversion

  latLngToCoord(latLng) {
    const origin = this.sceneOriginLatLng;
    const target = latLng;
    const distance = this.maps.geometry.spherical.computeDistanceBetween(
      origin,
      target
    );
    const heading = this.maps.geometry.spherical.computeHeading(origin, target);
    const headingRad = (heading * Math.PI) / 180;
    const x = Math.sin(headingRad) * distance;
    const y = Math.cos(headingRad) * distance;

    return {x: x, y: 0, z: -y};
  }

  coordToLatLng(coord) {
    const origin = this.sceneOriginLatLng;
    const {x, y, z} = coord;
    const dist = Math.sqrt(x ** 2 + z ** 2);
    const headingRad = Math.atan2(x, -z);
    const heading = (headingRad * 180) / Math.PI;
    const latLng = this.maps.geometry.spherical
      .computeOffset(origin, dist, heading)
      .toJSON();
    return latLng;
  }

  // viewpoint

  getMapViewpoint() {
    if (this.map) {
      return {
        zoom: this.map.getZoom(),
        center: this.map.getCenter().toJSON(),
      };
    }
  }

  setMapViewpoint(viewpoint) {
    if (this.map && viewpoint) {
      const {zoom, center} = viewpoint;
      if (zoom) this.map.setZoom(zoom);
      if (center) this.map.setCenter(center);
    }
  }
}
