import CardCameraDescription from "./CardCameraDescription";
// import CardCameraFitToObject from "./CardCameraFitToObject";
import CardCameraFitToWorld from "./CardCameraFitToWorld";
// import CardTopView from "./CardCameraTopView";

export default function ToolCameras({editor3d}) {
  return (
    <div>
      <CardCameraDescription />
      {/* <CardCameraFitToObject editor3d={editor3d} /> */}
      <CardCameraFitToWorld editor3d={editor3d} />
      {/* <CardTopView /> */}
    </div>
  );
}
