import {colors} from "@mui/material";
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  fetchColoringJobsService,
  fetchColoringJobService,
  createColoringJobService,
  updateColoringJobService,
} from "./services";

export const fetchColoringJobs = createAsyncThunk(
  "coloringJobs/fetchColoringJobs",
  fetchColoringJobsService
);

export const fetchColoringJob = createAsyncThunk(
  "coloringJobs/fetchColoringJob",
  fetchColoringJobService
);

export const createColoringJob = createAsyncThunk(
  "coloringJobs/createColoringJob",
  createColoringJobService
);

export const updateColoringJob = createAsyncThunk(
  "coloringJobs/updateColoringJob",
  updateColoringJobService
);

export const coloringsSlice = createSlice({
  name: "coloringJobs",
  initialState: {
    items: [],
    selectedColoringJob: null,
  },
  reducers: {
    setSelectedColoring: (state, action) => {
      state.selectedColoringJob = action.payload;
    },
  },

  extraReducers: {
    [fetchColoringJobs.fulfilled]: (state, action) => {
      const coloringJobs = action.payload;
      if (!coloringJobs) return;
      const coloringJobsIds = coloringJobs.map((c) => c.id);
      const prevColoringJobs = state.items.filter(
        (i) => !coloringJobsIds.includes(i.id)
      );
      const newColoringJobs = [];
      coloringJobs.forEach((coloringJob) => {
        const oldC = state.items.find((i) => i.id === coloringJob.id);
        if (oldC) {
          newColoringJobs.push({...oldC, ...coloringJob});
        } else {
          newColoringJobs.push(coloringJob);
        }
      });
      state.items = [...prevColoringJobs, ...newColoringJobs];
    },
    [fetchColoringJob.fulfilled]: (state, action) => {
      const job = action.payload;
      const oldJob = state.items.find((s) => s.id === job.id);
      const oldItems = state.items.filter((s) => s.id !== job.id);
      state.items = [...oldItems, {...oldJob, ...job}];
    },
    [createColoringJob.fulfilled]: (state, action) => {
      if (action.payload) {
        const coloringJob = action.payload;
        const prevColoringJobs = state.items.filter(
          (i) => i.id !== coloringJob?.id
        );
        const prevColoringJob = state.items.find(
          (i) => i.id === coloringJob.id
        );
        if (prevColoringJob) {
          state.items.push({...prevColoringJob, ...coloringJob});
        } else {
          state.items.push(coloringJob);
        }
      }
    },
    [updateColoringJob.fulfilled]: (state, action) => {
      if (action.payload) {
        const coloringJob = action.payload;
        const prevColoringJobs = state.items.filter(
          (i) => i.id !== coloringJob?.id
        );
        state.items = [...prevColoringJobs, coloringJob];
      }
    },
  },
});

export const {setSelectedColoringJob} = coloringsSlice.actions;

export default coloringsSlice.reducer;
