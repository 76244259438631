import {Box} from "@mui/material";

import ProcessCut from "./ProcessCut";

export default function ToolCut({editor3d}) {
  return (
    <Box>
      <ProcessCut editor3d={editor3d} />
    </Box>
  );
}
