import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

import {client} from "API/capla360";

import {uploadFileService} from "Features/files/services";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
  ListItemText,
  ListItemIcon,
  Box,
  DialogActions,
  Button,
} from "@mui/material";
import {Preview, Save} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
import FileSelectorButton from "Features/files/components/FileSelectorButton";

import useAccessToken from "Features/auth/useAccessToken";

export default function MenuItemPreviewUrl({scene, model, onClick}) {
  const {t} = useTranslation(["scenes", "common"]);
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const buttonString = "Change preview";

  const dialogTitleString = "Change model preview";
  const deleteString = capitalizeStart(t("common:delete"));
  const saveString = capitalizeStart(t("common:save"));

  // local state

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const url = file && URL.createObjectURL(file);

  // handlers

  function handleMenuItemClick() {
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
    onClick();
  }

  async function handlePreviewDelete() {
    const updatedModel = {...model, previewUrl: ""};
    dispatch(updateModel({updatedModel, sync: true}));
    setOpen(false);
    onClick();
  }

  function handleFileChange(f) {
    setFile(f);
  }
  async function handleSaveClick() {
    if (accessToken && scene?.id) {
      setLoading(true);
      const url = await uploadFileService({
        file,
        sceneId: scene.id,
        accessToken,
        container: "scene-files",
      });

      // data transform

      const updatedModel = {...model, previewUrl: url};
      await dispatch(updateModel({updatedModel, sync: true}));

      // end

      setLoading(false);
    }

    setOpen(false);
    onClick();
  }

  return (
    <>
      <MenuItem onClick={handleMenuItemClick} variant="outlined" size="small">
        <ListItemIcon>
          <Preview fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">{buttonString}</Typography>
        </ListItemText>
      </MenuItem>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitleString}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 300,
              height: 100,
              bgcolor: "background.default",
              mb: 2,
              backgroundImage: `url(${url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />

          <FileSelectorButton onFileChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={handlePreviewDelete} sx={{mr: 1}}>
            {deleteString}
          </Button>
          <LoadingButton
            size="small"
            onClick={handleSaveClick}
            sx={{mr: 1}}
            loadingPosition="start"
            startIcon={<Save />}
            variant="outlined"
            loading={loading}
          >
            {saveString}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
