import { nanoid } from "@reduxjs/toolkit"

export default function splitSegments(polyline, cuts) {
  const path = polyline.drawingProps.path;
  let currentCut = 0;
  const newPolys = [];
  // first cut
  const firstCut = cuts[0];
  const firstPath = path.slice(0, firstCut + 1);
  if (firstPath.length > 1) {
    newPolys.push({
      ...polyline,
      id: nanoid(),
      drawingProps: {
        ...polyline.drawingProps,
        path: firstPath,
      },
      centerLine: polyline.centerLine.slice(0, firstCut + 1),
      affectedByProxy: true
    });
  }
  currentCut = firstCut;
  for (let i = 1; i < cuts.length; i++) {
    const slice = polyline.centerLine.slice(currentCut + 1, cuts[i] + 1);
    if (slice.length > 1) {
      newPolys.push({
        ...polyline,
        id: nanoid(),
        drawingProps: {
          ...polyline.drawingProps,
          path: path.slice(currentCut + 1, cuts[i] + 1),
        },
        centerLine: slice,
        affectedByProxy: true
      });
    }
    currentCut = cuts[i];
  }
  // last cut
  const lastCut = cuts[cuts.length - 1];
  if (lastCut < polyline.centerLine.length - 2) {
    newPolys.push({
      ...polyline,
      id: nanoid(),
      drawingProps: {
        ...polyline.drawingProps,
        path: path.slice(lastCut + 1),
      },
      centerLine : polyline.centerLine.slice(lastCut + 1),
      affectedByProxy: true
    });
  }
  return newPolys;
}