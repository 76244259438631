import React, {useState} from "react";
import {alpha, InputBase, Box, styled} from "@mui/material";
import {Search as SearchIcon} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
const Input = styled(InputBase)(({theme}) => ({
  "& .MuiInputBase-root": {color: "inherit", width: "100%"},
  "& .MuiInputBase-input": {
    p: 1,
    pl: 0,
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
  },
}));

export default function SearchBar({onChange, onFocus, onBlur}) {
  const {t} = useTranslation("common");
  const placeholderString = capitalizeStart(t("common:search"));

  const [text, setText] = useState("");

  function handleChange(e) {
    const newText = e.target.value;
    setText(newText);
    onChange(newText);
  }
  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.shape.borderRadius,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.15),
        "&:hover": {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.25),
        },
        //marginRight: theme.spacing(2),
        marginLeft: 0,
        width: 350,
      }}
    >
      <Box
        sx={{
          padding: (theme) => theme.spacing(0, 2),
          height: "100%",
          position: "absolute",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SearchIcon />
      </Box>
      <Input
        placeholder={`${placeholderString} ...`}
        value={text}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </Box>
  );
}
