export default function stringifyFuncAndFiltersStr(func, filtersStr) {
  let str = "";
  if (typeof func === "string") {
    str = func;
  }

  if (Array.isArray(filtersStr)) {
    filtersStr.forEach((filter) => {
      str += " {{" + filter + "}}";
    });
  }
  return str.trim();
}
