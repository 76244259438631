import {Box} from "@mui/material";

import SectionActionsScopes from "./SectionActionsScopes";

export default function SectionLeftPanelActionsScopes({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["createScope", "updateScope"];
  const actionsMoreKeys = [];

  return (
    <Box>
      <SectionActionsScopes
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
