import {useSelector} from "react-redux";

// import useTempPdfModel from "./useTempPdfModel";

// import useSceneModule from "Features/navigation/useSceneModule";
// import usePageType from "Features/navigation/usePageType";
// // import useSelectedIssue from "Features/issues/useSelectedIssue";
// import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";
// import useColoringModel from "Features/colorings/useColoringModel";
// import useAnnotatedPdfModel from "Features/annotatedPdf/hooks/useAnnotatedPdfModel";
// import useSelectedInput from "Features/inputs/hooks/useSelectedInput";
// // import usePdfModelCandidatesInViewer from "./usePdfModelCandidatesInViewer";

export default function useSelectedPdfModel() {
  const selectedModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  // // const selectedModelIdHome = useSelector((s) => s.ui.selectedModelId);
  // const modelId = useSelector((s) => s.viewer3D.selectedModelId);

  // // const selectedIssue = useSelectedIssue();

  // const selectedMeasurementsModel = useSelectedMeasurementsModel();
  // const selectedInput = useSelectedInput();

  // const tempPdfModel = useTempPdfModel();

  // const annotatedPdfModel = useAnnotatedPdfModel();

  // const coloringModel = useColoringModel();

  const models = useSelector((m) => m.viewer3D.models).filter(
    (m) => m.type === "PDF"
  );

  // const sceneModule = useSceneModule();
  // const pageType = usePageType();

  return models.find((m) => m.id === selectedModelId);

  // let model;

  // if (sceneModule === "EDIT_MEASUREMENTS") {
  //   const id = selectedMeasurementsModel?.fromModel?.modelId;
  //   model = models.find((m) => m.id === id);
  // } else if (sceneModule === "INPUTS") {
  //   if (tempPdfModel) {
  //     model = tempPdfModel;
  //   } else if (selectedInput?.type === "PDF") {
  //     model = selectedInput;
  //   }
  // } else if (sceneModule === "ANNOTATED_PDF") {
  //   model = annotatedPdfModel;
  // } else if (sceneModule === "DATA") {
  //   const _model = models.find((m) => m.id === modelId);
  //   if (_model?.type === "PDF") model = _model;
  // } else if (pageType === "COLORING") {
  //   const pdfModelId = coloringModel?.fromModel?.modelId;
  //   model = models.find((m) => m.id === pdfModelId);
  // // } else if (false && (sceneModule === "HOME" || viewer)) {
  // //   const measurementsModel = models.find((m) => m.id === selectedModelIdHome);
  // //   if (measurementsModel) {
  // //     const pdfModelId = measurementsModel.fromModel.modelId;
  // //     model = models.find((m) => m.id === pdfModelId);
  // //   } else {
  // //     model = models.find((m) => m.id === selectedModelId);
  // //   }
  // } else {
  //   model = models.find((m) => m.id === selectedModelId);
  // }

  // return model;
}
