import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {addSubArticles} from "../articlesSlice";
import getNomenclatureArticleSubArticles from "../utils/getNomenclatureArticleSubArticles";

import transferElementTypeArticleQtiesToChildren from "../utils/transferElementTypeArticleQtiesToChildren";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

import {setArticlesProxies} from "../articlesSlice";
import getTemplateProxies from "../utils/getTemplateProxies";

export default function useAutoUpdateArticlesProxies({articlesGroup}) {
  const dispatch = useDispatch();

  // data

  const lastUpdateAt = useSelector((s) => s.articles.lastUpdateAt);

  const articlesMap = useSelector((s) => s.articles.articlesMap);

  const sortedArticles = articlesGroup?.sortedArticles ?? [];

  const sortedArticlesProxyByGroupId = useSelector(
    (s) => s.articles.sortedArticlesProxyByGroupId
  );

  // helper

  const sortedArticlesProxy = sortedArticlesProxyByGroupId[articlesGroup?.id];
  const shouldSetProxies = !sortedArticlesProxy?.length > 0; // we do not want to update the list afterwards.

  // deps

  const articlesLength = sortedArticles?.length ?? 0;
  const templatesCount = Object.values(articlesMap).filter(
    (article) => article.isTemplate || article.updates?.isTemplate
  ).length;

  useEffect(() => {
    console.log("shouldSetProxies", shouldSetProxies);
    if (shouldSetProxies && articlesLength > 0 && templatesCount > 0) {
      const proxies = getTemplateProxies({
        articlesMap,
        sortedArticles,
        groupId: articlesGroup?.id,
      });
      console.log("[EFFECT] proxies", proxies);
      dispatch(setArticlesProxies(proxies));
    }
  }, [lastUpdateAt, articlesLength, articlesGroup?.id, templatesCount]); // we add the elementTypesGroups.length
}
