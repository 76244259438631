import {useSelector, useDispatch} from "react-redux";

import {Box, TextField, Typography, Paper, InputAdornment} from "@mui/material";

import FieldProdDeadline from "./FieldSceneProdDeadline";
import FieldSceneImage from "./FieldSceneImage";
import FieldSceneProdStatus from "./FieldSceneProdStatus";
import {setEditedScene} from "../scenesSlice";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function FormScene() {
  const dispatch = useDispatch();

  // strings

  const operationS = "Opération";
  const clientReferenceS = "Réf. interne";
  const tradesS = "Lots / corps d'état";
  const buildingsS = "Ouvrages / bâtiments";
  const nameS = "Nom";
  const descriptionS = "Description";
  const productionS = "Production de la bimbox";
  const prodDeadlineS = "Besoin pour le : ";
  const contactS =
    "Personne à contacter pour toute information relative à cette bimbox :";
  const emailS = "Email";
  const onboardingUrlS = "Onboarding (url)";
  const updateS = "Update metadata";

  // data

  const editedScene = useSelector((s) => s.scenes.editedScene);

  // helpers

  const sceneName = editedScene?.title ? editedScene.title : "";
  const clientReference = editedScene?.clientReference;
  const sceneDescription = editedScene?.description
    ? editedScene.description
    : "-";
  const operationName = editedScene?.operationName
    ? editedScene.operationName
    : "";
  const tradesName = editedScene?.tradesName ? editedScene.tradesName : "";
  const buildingsName = editedScene?.buildingsName
    ? editedScene.buildingsName
    : "";
  const prodDeadline = editedScene?.prodDeadline
    ? editedScene.prodDeadline
    : null;
  const imageUrl = editedScene?.imageClientUrl
    ? editedScene.imageClientUrl
    : editedScene.imageUrl;
  const prodStatus = editedScene?.prodStatus;
  const contactEmail = editedScene?.contactEmail
    ? editedScene.contactEmail
    : "";
  const onboardingUrl = editedScene?.onboardingUrl
    ? editedScene.onboardingUrl
    : "";

  // handlers

  function handleClientReferenceChange(e) {
    const clientReference = e.target.value;
    dispatch(setEditedScene({...editedScene, clientReference}));
  }

  function handleTradesNameChange(e) {
    const name = e.target.value;
    dispatch(setEditedScene({...editedScene, tradesName: name}));
  }
  function handleBuildingsNameChange(e) {
    const name = e.target.value;
    dispatch(setEditedScene({...editedScene, buildingsName: name}));
  }
  function handleOperationNameChange(e) {
    const name = e.target.value;
    dispatch(setEditedScene({...editedScene, operationName: name}));
  }
  function handleNameChange(e) {
    const name = e.target.value;
    dispatch(setEditedScene({...editedScene, title: name}));
  }
  function handleDescriptionChange(e) {
    const description = e.target.value;
    dispatch(setEditedScene({...editedScene, description}));
  }
  function handleProdDeadlineChange(date) {
    console.log("newDeadline", date);
    dispatch(setEditedScene({...editedScene, prodDeadline: date}));
  }
  function handleImageChange(url) {
    dispatch(setEditedScene({...editedScene, imageClientUrl: url}));
  }
  function handleProdStatusChange(status) {
    dispatch(setEditedScene({...editedScene, prodStatus: status}));
  }
  function handleContactEmailChange(e) {
    const email = e.target.value;
    dispatch(setEditedScene({...editedScene, contactEmail: email}));
  }
  function handleOnboardingUrlChange(e) {
    const url = e.target.value;
    dispatch(setEditedScene({...editedScene, onboardingUrl: url}));
  }
  function handleUpdateClick(file) {
    const url = URL.createObjectURL(file);
    fetch(url)
      .then((response) => response.json())
      .then((json) => dispatch(setEditedScene({...editedScene, data: json})));
  }

  return (
    <Box sx={{p: 1}}>
      <Box sx={{width: 1, height: 140, mb: 2}}>
        <FieldSceneImage imageUrl={imageUrl} onChange={handleImageChange} />
      </Box>

      <Box sx={{p: 1, mb: 1}}>
        <TextField
          fullWidth
          multiline
          label={<Typography variant="body2">{nameS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={sceneName}
          onChange={handleNameChange}
        />
      </Box>
      <Box sx={{p: 1, mb: 1}}>
        <TextField
          fullWidth
          multiline
          label={<Typography variant="body2">{clientReferenceS}</Typography>}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography sx={{}}>#</Typography>
              </InputAdornment>
            ),
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={clientReference}
          onChange={handleClientReferenceChange}
        />
      </Box>

      <Box sx={{p: 1, mb: 2}}>
        <TextField
          fullWidth
          label={<Typography variant="body2">{descriptionS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={sceneDescription}
          onChange={handleDescriptionChange}
        />
      </Box>

      <Box
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: "4px",
          p: 1,
        }}
      >
        <TextField
          fullWidth
          sx={{mt: 2}}
          label={<Typography variant="body2">{operationS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={operationName}
          onChange={handleOperationNameChange}
        />
        <TextField
          fullWidth
          sx={{mt: 2}}
          label={<Typography variant="body2">{tradesS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={tradesName}
          onChange={handleTradesNameChange}
        />
        <TextField
          fullWidth
          sx={{mt: 2}}
          label={<Typography variant="body2">{buildingsS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={buildingsName}
          onChange={handleBuildingsNameChange}
        />
      </Box>

      <Box sx={{mt: 2, p: 0.5, width: 1}}>
        <Paper sx={{p: 1, width: 1}} elevation={12}>
          <TextField
            fullWidth
            multiline
            label={<Typography variant="body2">{onboardingUrlS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={onboardingUrl}
            onChange={handleOnboardingUrlChange}
          />
        </Paper>
      </Box>

      <Box sx={{p: 1, bgcolor: "background.default", mt: 3}}>
        <Typography variant="body2" color="text.secondary">
          {productionS}
        </Typography>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography variant="body2" sx={{mr: 1}}>
            {prodDeadlineS}
          </Typography>
          <FieldProdDeadline
            value={prodDeadline}
            onChange={handleProdDeadlineChange}
          />
        </Box>
        <Box
          sx={{
            width: 1,
            p: 1,
            display: "flex",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <FieldSceneProdStatus
            value={prodStatus}
            onChange={handleProdStatusChange}
          />
        </Box>
      </Box>
      <Box sx={{p: 1, mt: 3}}>
        <Typography gutterBottom color="text.secondary" variant="body2">
          {contactS}
        </Typography>
        <TextField
          sx={{mt: 1}}
          fullWidth
          label={<Typography variant="body2">{emailS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={contactEmail}
          onChange={handleContactEmailChange}
        />
      </Box>
      <Box sx={{p: 1, mt: 3, flexGrow: 1}}>
        {/* <Button variant="outlined" fullWidth onClick={handleUpdateClick}>
          {updateS}
        </Button> */}
        <FileSelectorButton
          onFileChange={handleUpdateClick}
          buttonName={updateS}
        />
      </Box>
      <Box sx={{height: 60}} />
    </Box>
  );
}
