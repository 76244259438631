// import React, {useEffect, useState} from "react";

// import {useNavigate} from "react-router-dom";
// import {useSelector, useDispatch} from "react-redux";
// import {nanoid} from "@reduxjs/toolkit";
// import {updateScene} from "Features/scenes/scenesSlice";
// import {setScreenshot, setViewMode} from "Features/viewer3D/viewer3DSlice";
// import {setOpenSections} from "Features/ui/uiSlice";

// import useTakeEditorScreenshot from "Features/viewer3D/useTakeEditorScreenshot";

import {Box} from "@mui/material";

// import ImageScene from "Features/scenes/components/ImageScene";
import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function FieldSceneImage({imageUrl, onChange}) {
  // handlers

  function handleFileChange(file) {
    if (file) {
      const url = URL.createObjectURL(file);
      onChange(url);
    }
  }

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      <Box
        sx={{
          position: "absolute",
          bottom: "8px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 20,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <FileSelectorButton
          onFileChange={handleFileChange}
          buttonVariant="contained"
          accept=".png, .jpg"
          multiple={false}
        />
      </Box>

      <Box
        sx={{
          my: 1,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          bgcolor: "background.default",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${imageUrl})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      />
    </Box>
  );
}
