import imageCustom from "./assets/imageCustom.png";
import imageIfc from "./assets/imageIfc.png";
import imageReview from "./assets/imageReview.png";
import imagePdfIn3D from "./assets/imagePdfIn3D.png";
import imagePdf from "./assets/imagePdf.png";

export default function useScenarios() {
  // strings

  const startS = "Pour démarrer rapidement";
  const startDS =
    "Découvrez les 5 composants essentiels pour naviguer facilement dans votre bimbox.";

  const quantitiesV1S = "Repérez des éléments";
  const quantitiesV1DS =
    "Repérez des éléments sur un plan PDF pour extraire des quantités.";

  const reviewS = "Design Review";
  const reviewDS =
    "Create & share a list of observations related to a construction document.";

  const viewIfcS = "View an IFC file";
  const viewIfcDS =
    "Use a free IFC viewer running on your browser. All the files will stay on your computer.";

  const viewPdfS = "Pdf viewer";
  const viewPdfDS = "A pdf viewer for construction document";

  const viewPdfIn3DS = "View pdfs in 3D";
  const viewPdfIn3DDS =
    "Visualize horizontal & vertical blueprints in the same 3D space.";

  const customS = "Custom";
  const customDS = "Create an empty bimbox.";

  const scenariosMap = {
    start: {
      name: startS,
      description: startDS,
      steps: ["discoverUi"],
    },
    quantitiesV1: {
      name: quantitiesV1S,
      description: quantitiesV1DS,
      steps: ["discoverUi", "addFile", "end"],
    },
    viewPdf: {
      name: viewPdfS,
      description: viewPdfDS,
      steps: ["addFile", "end"],
      image: imagePdf,
      options: {
        fileType: "PDF",
      },
    },
    viewIfc: {
      name: viewIfcS,
      description: viewIfcDS,
      steps: ["addFile", "end"],
      image: imageIfc,
    },
    viewPdfIn3D: {
      name: viewPdfIn3DS,
      description: viewPdfIn3DDS,
      steps: ["addFile", "end"],
      image: imagePdfIn3D,
    },
    _review: {
      name: reviewS,
      description: reviewDS,
      image: imageReview,
      steps: [
        "login",
        "connect",
        "addFile",
        "createListing",
        "createIssue",
        "changeSceneImage",
        "changeSceneName",
        "sync",
        "end",
      ],
      options: {
        listingType: "issueset",
      },
    },
    review: {
      name: reviewS,
      description: reviewDS,
      image: imageReview,
      steps: [
        "login",
        "addFile",
        "renameModel",
        "syncBoxForReview",
        "createIssue",
        "changeSceneImage",
        "end",
      ],
      visibleSteps: [
        "addFile",
        "renameModel",
        "createIssue",
        "changeSceneImage",
        "end",
      ],
      options: {
        listingType: "issueset",
      },
    },
    custom: {
      name: customS,
      description: customDS,
      image: imageCustom,
      steps: [],
    },
  };

  const selection = [
    "start",
    //"quantitiesV1",
    // "viewPdf",
    // "viewIfc",
    // "viewPdfIn3D",
    // "review",
    // "custom",
  ];

  const scenarios = selection.map((s) => ({id: s, ...scenariosMap[s]}));

  return scenarios;
}
