import {useState, useEffect} from "react";
import {Box, Button} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {setModelIdBeingLoadedInViewer3d} from "Features/overviewer/overviewerSlice";
import useSelectedPackage from "../useSelectedPackage";
import getModelLoadingStatusNum from "Features/overviewer/utils/getModelLoadingStatusNum";
import useAccessToken from "Features/auth/useAccessToken";
import {fetchRemoteModel} from "Features/viewer3D/viewer3DSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import ContainerLoadingBimbox from "Features/overviewer/components/ContainerLoadingBimbox";

function ScreenLoading({caplaEditor, scene}) {
  const loader = caplaEditor?.editor3d?.loader;
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // string

  const closeS = "Fermer";

  // data

  const model = useSelectedPackage();
  const statusModelMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  // helpers

  const statusNum = getModelLoadingStatusNum(model?.id, statusModelMap);

  const buttonLabel = `${model?.name} + (${statusNum})`;

  // helpers - fetch model

  const remoteId = model?.remoteId;
  const sceneClientId = scene?.clientId;

  // effect

  // useEffect(() => {
  //   if (statusNum === 1) {
  //     if (remoteId && accessToken && sceneClientId) {
  //       dispatch(fetchRemoteModel({remoteId, accessToken, sceneClientId}));
  //     }
  //   } else if (statusNum === 2) {
  //     caplaEditor.measDataManager.createOrUpdateModelInManager(model);
  //     // caplaEditor?.measDataManager.setSelectedModelById(model?.id);
  //     loader.loadInitialModel(model);
  //   } else if (model?.id && statusNum === 4) {
  //     dispatch(setModelIdBeingLoadedInViewer3d(null));
  //   }
  // }, [remoteId, statusNum, accessToken]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "common.caplaBlack",
        zIndex: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ContainerLoadingBimbox />
    </Box>
  );
}

export default function ScreenLoadingPackageInViewer3d({caplaEditor, scene}) {
  const isStagingEnv = useIsStagingEnv();
  // data

  const modelId = useSelector((s) => s.overviewer.modelIdBeingLoadedInViewer3d);
  const selectedPackage = useSelectedPackage();

  const showScreen = useSelector(
    (s) => s.overviewer.showScreenLoadingPackageInViewer3d
  );

  // helper

  //const showScreen = modelId && isStagingEnv && selectedPackage?.id;

  return (
    <Box>
      {showScreen && <ScreenLoading caplaEditor={caplaEditor} scene={scene} />}
    </Box>
  );
}
