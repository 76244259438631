import {useState, useEffect} from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

import TableDifference from "./TableDifference";
import ChipDifferenceType from "./ChipDifferenceType";

export default function ListItemDifference({diff, editor3d}) {
  const [name, setName] = useState("...");
  const [open, setOpen] = useState(false);

  // helpers

  async function fetchName() {
    const props = await editor3d?.ifcLoader.ifcManager.getItemProperties(
      diff.ifcModelID,
      diff.expressID,
      false
    );
    const newName = props.Name?.value;
    setName(newName);
  }

  // update

  useEffect(() => {
    fetchName();
  }, []);

  // handlers

  function handleExpandClick() {
    setOpen((open) => !open);
  }

  return (
    <Box>
      <Box sx={{mb: 1, bgcolor: "common.white", borderRadius: "24px", px: 1}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ChipDifferenceType type={diff.type} />
          <Typography variant="body2" noWrap sx={{ml: 1}}>
            {name}
          </Typography>
          <IconButton onClick={handleExpandClick}>
            <ExpandMore fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {open && (
        <Box sx={{maxHeight: 500, overflow: "auto"}}>
          <TableDifference diff={diff} />
        </Box>
      )}
    </Box>
  );
}
