import {useSelector} from "react-redux";
import useSceneMeasurements from "./useSceneMeasurements";

function getValueFromValues(values) {
  let value0 = values[0];
  values.forEach((value) => {
    if (value !== value0) value0 = null;
  });
  return value0;
}
export default function useSelectedMeasurementMulti(scene) {
  const measurements = useSceneMeasurements(scene);

  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  const selectedMeasurements = measurements.filter((m) =>
    selectedMeasurementIds.includes(m.id)
  );

  const m0 = measurements.find((m) => m.id === selectedMeasurementIds[0]);

  const mMultiple = {};

  if (m0) {
    Object.keys(m0).forEach((key) => {
      const values = selectedMeasurements.map((m) => m[key]);
      const value = getValueFromValues(values);
      mMultiple[key] = value;
    });
  }

  // quantities

  if (mMultiple.elementTypeId) {
    const quantities = selectedMeasurements.reduce(
      (ac, measurement) => {
        const newAc = {...ac};
        newAc.count += 1;
        newAc.length += measurement.length;
        newAc.area += measurement.area;
        newAc.volume += measurement.volume;
        return newAc;
      },
      {count: 0, length: 0, area: 0, volume: 0}
    );
    mMultiple.count = quantities.count;
    mMultiple.length = quantities.length;
    mMultiple.area = quantities.area;
    mMultiple.volume = quantities.volume;
  }

  return mMultiple;
}
