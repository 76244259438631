import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Switch} from "@mui/material";
import {
  ArrowBackIos as Left,
  ArrowForwardIos as Right,
} from "@mui/icons-material";
import {darken, lighten} from "@mui/material/styles";
import {DataGridPro, frFR, GridActionsCellItem} from "@mui/x-data-grid-pro";

import {
  // setEditedElementTypesGroup,
  setSelectedElementTypeIds,
  updateElementTypeInEditedElementTypesGroup,
  moveEditedElementType,
} from "../elementTypesSlice";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
// import useElementTypesDrawingShapesOptions from "../hooks/useElementTypeDrawingShapesOptions";

import elementTypesForDataGrid from "../utils/elementTypesForDataGrid";
import elementTypeFromDatagridToState from "../utils/elementTypeFromDatagridToState";

import DatagridElementTypesContextMenu from "./DatagridElementTypesContextMenu";
import SelectorElementTypeColorIcon from "./SelectorElementTypeColorIcon";

// import FieldSelector from "Features/kvform/components/FieldSelector";
import SelectorDrawingShapeInDatagrid from "./SelectorDrawingShapeInDatagrid";
import IconButtonDialogDeleteElementType from "./IconButtonDialogDeleteElementType";
import ContainerRawType from "./ContainerRawType";
import {parseArgs} from "util";

import {rawTypeToType} from "Features/measurements/utils/rawTypeToType";

import ContainerElementTypeOfRawType from "./ContainerElementTypeOfRawType";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridRawTypesAndElementTypes({
  items,
  elementTypes,
  nameFrom,
  selection,
  onSelectionChange,
  onItemChange,
}) {
  // items = {rawType:,elementType:}

  const dispatch = useDispatch();

  // strings

  const rawTypeS = "Annotation";
  const rawCountS = "x";
  const elementTypeS = "Elt";
  const rankS = "Rang";
  const numS = "Num";
  const codeS = "Code";
  const titleS = "Titre";
  const colorS = "Couleur";
  const nameS = "Nom";
  const unitS = "Unité";
  const ressourcesS = "Art.";
  const drawingShapeS = "Forme";
  const dim1S = "Dim 1 (m)";
  const dim2S = "Dim 2 (m)";
  const heightS = "H (m)";

  // selection model

  const selectionModel = [...selection];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    console.log("selectionChange", selection);
    onSelectionChange(selection);
    // if (selectionId === selectedElementTypeIds[0] && selection.length === 1) {
    //   dispatch(setSelectedElementTypeIds([]));
    // } else {
    //   dispatch(setSelectedElementTypeIds(selection));
    // }
  }

  function handleIsTitleChange(event, row) {
    const elementType = elementTypeFromDatagridToState(row);
    elementType.isTitle = event.target.checked;
    // dispatch(
    //   updateElementTypeInEditedElementTypesGroup({
    //     elementType,
    //     updateNums: true,
    //   })
    // );
  }

  function handleDrawingShapeChange(drawingShape, row) {
    const elementType = elementTypeFromDatagridToState(row);
    elementType.drawingShape = drawingShape;
    // dispatch(
    //   updateElementTypeInEditedElementTypesGroup({
    //     elementType,
    //     updateNums: true,
    //   })
    // );
  }

  function handleColorChange(color, row) {
    const elementType = elementTypeFromDatagridToState(row);
    elementType.color = color;
    // dispatch(
    //   updateElementTypeInEditedElementTypesGroup({
    //     elementType,
    //     updateNums: true,
    //   })
    // );
  }

  function handleProcessRowUpdate(updatedRow) {
    const item = items.find((i) => i.id === updatedRow.id);
    const newItem = {...item};
    newItem.elementType.name = updatedRow.name;
    newItem.elementType.num = updatedRow.num;
    newItem.elementType.dim1 = updatedRow.dim1;
    newItem.elementType.dim2 = updatedRow.dim2;
    newItem.elementType.height = updatedRow.height;

    onItemChange(newItem);
  }

  // grid - columns

  const columns = [
    // {
    //   field: "rank",
    //   headerName: rankS,
    //   // width: 100,
    //   type: "actions",
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<Left size="small" />}
    //       label="<="
    //       onClick={() => handleChangeElementTypeOrder(params.row, "left")}
    //     />,
    //     <GridActionsCellItem
    //       icon={<Right size="small" />}
    //       label="=>"
    //       onClick={() => handleChangeElementTypeOrder(params.row, "right")}
    //     />,
    //   ],
    // },
    {
      field: "rawType",
      headerName: rawTypeS,
      flex: 1,
      renderCell: (params) => {
        const rawType = {
          rawName: params.row.rawName,
          rawColor: params.row.rawColor,
          rawDrawingShape: params.row.rawDrawingShape,
          rawCount: params.row.rawCount,
        };
        return <ContainerRawType rawType={rawType} />;
      },
    },
    {
      field: "rawCount",
      headerName: rawCountS,
      width: 50,
    },
    {
      field: "id",
      headerName: elementTypeS,
      width: 50,
      renderCell: (params) => {
        if (params.row.elementTypeId)
          return (
            <ContainerElementTypeOfRawType
              id={params.row.elementTypeId}
              drawingShape={params.row.drawingShape}
              color={params.row.color}
              name={params.row.name}

              //onChange={(shape) => handleDrawingShapeChange(shape, params.row)}
              //editable={datagridIsInEditMode}
            />
          );
        else return <></>;
      },
    },
    {
      field: "num",
      headerName: numS,
      width: 100,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
      editable: true,
    },
    // {
    //   field: "code",
    //   headerName: codeS,
    //   width: 100,
    //   //editable: datagridIsInEditMode,
    // },
    // {
    //   field: "isTitle",
    //   headerName: titleS,
    //   type: "boolean",
    //   //editable: datagridIsInEditMode,
    //   renderCell: (params) => (
    //     <Switch
    //       size="small"
    //       checked={params.row.isTitle}
    //       onChange={(isTitle) => handleIsTitleChange(isTitle, params.row)}
    //     />
    //   ),
    // },
    // {
    //   field: "drawingShape",
    //   width: 120,
    //   headerName: drawingShapeS,
    //   renderCell: (params) => {
    //     if (!params.row.isTitle)
    //       return (
    //         <SelectorDrawingShapeInDatagrid
    //           drawingShape={params.value}
    //           onChange={(shape) => handleDrawingShapeChange(shape, params.row)}
    //           //editable={datagridIsInEditMode}
    //         />
    //       );
    //     else return <></>;
    //   },
    // },
    // {
    //   field: "color",
    //   headerName: colorS,
    //   renderCell: (params) => {
    //     if (!params.row.isTitle)
    //       return (
    //         <SelectorElementTypeColorIcon
    //           color={params.value}
    //           drawingShape={params.row.drawingShape}
    //           //editable={datagridIsInEditMode}
    //           onChange={(color) => handleColorChange(color, params.row)}
    //         />
    //       );
    //     else return <></>;
    //   },
    // },
    {
      field: "unit",
      headerName: unitS,
      width: 80,
      //editable: datagridIsInEditMode,
    },
    {
      field: "dim1",
      headerName: dim1S,
      width: 80,
      editable: true,
    },
    {
      field: "dim2",
      headerName: dim2S,
      width: 80,
      editable: true,
    },
    {
      field: "height",
      headerName: heightS,
      width: 80,
      editable: true,
    },

    // {
    //   field: "ressources",
    //   headerName: ressourcesS,
    //   width: 80,
    // },
    // {
    //   field: "delete",
    //   type: "actions",
    //   width: 40,
    //   headerName: "",
    //   valueGetter: (params) => params.row.id,
    //   renderCell: (params) => (
    //     <IconButtonDialogDeleteElementType elementTypeId={params.row.id} />
    //   ),
    // },
  ];

  // grid - rows

  const rows = items.map(({rawType, elementType}) => {
    return {
      ...rawType,
      ...elementType,
      elementTypeId: elementType?.id,
      id: rawType.rawId,
    };
  });

  console.log("rows", rows);

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--Disabled": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGridPro
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        sx={{"& .MuiDataGrid-cell--withRenderer": {p: 0}}}
        density="compact"
        checkboxSelection={true}
        rows={rows}
        columns={columns}
        processRowUpdate={handleProcessRowUpdate}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
        getRowClassName={(params) => {
          if (
            params.row.style === "title1" ||
            (params.row.isTitle &&
              params.row.style === undefined &&
              params.row.rank === 1)
          )
            return `super-app-theme--Title1`;
          if (
            params.row.style === "title2" ||
            (params.row.isTitle &&
              params.row.style === undefined &&
              params.row.rank === 2)
          )
            return `super-app-theme--Title2`;
          if (params.row.style === "detail") return `super-app-theme--Child`;
          if (params.row.disabled) return `super-app-theme--Disabled`;
        }}
      />
    </Box>
  );
}
