import {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {
  endLoadingOrder,
  setModelIdsInMeasDataManager,
} from "../overviewerSlice";

export default function useAutoLoadModelsInViewers(caplaEditor) {
  const loader = caplaEditor?.editor3d?.loader;
  const mnger = caplaEditor?.measDataManager;
  const dispatch = useDispatch();

  // data

  const loadModelsTriggeredAt = useSelector(
    (s) => s.overviewer.loadModelsTriggeredAt
  );

  const modelIds = useSelector((s) => s.overviewer.modelIdsToLoad);
  const models = useSelector((s) => s.viewer3D.models);

  const loadingOrderIndex = useSelector((s) => s.overviewer.loadingOrderIndex);
  const loadingOrderIndexOfLastSuccess = useSelector(
    (s) => s.overviewer.loadingOrderIndexOfLastSuccess
  );

  // helpers - models To load

  const modelsToLoad = models.filter((m) => modelIds.includes(m.id));

  // helpers - can load

  const canLoad =
    loader &&
    mnger &&
    loadModelsTriggeredAt &&
    loadingOrderIndex === loadingOrderIndexOfLastSuccess + 1;

  console.log(
    "[DEBUG MODEL LOADER]",
    "canLoad",
    canLoad,
    "orderId",
    loadingOrderIndex,
    "orderIdWithSuccess",
    loadingOrderIndexOfLastSuccess
  );

  // effect - detect & trigger

  // effect - load order

  async function loadModelsAsync(modelsToLoad) {
    console.log("LOAD ASYNC MODELS V2", modelsToLoad);
    await loader.loadAsyncModelsV2(modelsToLoad, false, false);
    dispatch(endLoadingOrder());
  }

  useEffect(() => {
    if (canLoad) {
      // load meas in measDataManager
      const packages = modelsToLoad.filter((m) => m.type === "MEASUREMENTS");
      packages.forEach((model) => {
        if (!model.archived && model.enabled) {
          mnger?.createOrUpdateModelInManager(model, true, true);
        }
      });

      dispatch(setModelIdsInMeasDataManager(modelsToLoad.map((m) => m.id)));

      loadModelsAsync(modelsToLoad); // REALLY NEEDED ???
    }
  }, [canLoad, loadModelsTriggeredAt]);
}
