import {useEffect} from "react";
import useAccessToken from "Features/auth/useAccessToken";
import {useDispatch, useSelector} from "react-redux";
import {fetchColoringsByScene} from "Features/colorings/coloringsSlice";

export default function useColoringsByScene(scene) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  let colorings = useSelector((s) => s.colorings.items);

  colorings = colorings.map((c) => ({...c, packageName: c?.package?.name}));

  useEffect(() => {
    if (accessToken && scene?.id) {
      dispatch(fetchColoringsByScene({accessToken, sceneId: scene?.id}));
    }
  }, [scene?.id]);

  return colorings;
}
