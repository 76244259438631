const listStyleMap = {
  DEFAULT: {fontWeight: "normal", bgcolor: "inherit", color: "inherit"},
  TITLE1: {fontWeight: "bold", bgcolor: "#bbbbc0", color: "text.primary"},
  TITLE2: {
    fontWeight: "bold",
    bgcolor: "background.default",
    color: "text.primary",
  },
  DETAIL: {
    fontWeight: "normal",
    bgcolor: "common.white",
    color: "text.secondary",
  },
};

export default listStyleMap;
