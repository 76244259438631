import {useRef, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, IconButton, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";

import {addPdfMarker} from "Features/viewer3D/viewer3DSlice";
import ActionPopper from "Features/viewer3D/components/ActionPopper";
import ActionSelectMarker from "Features/viewer3D/components/ActionSelectMarker";

export default function ButtonAddPdfMarker({
  caplaEditor,
  scene,
  active,
  show,
  // onCancel,
  onActiveChange,
  zone,
}) {
  const dispatch = useDispatch();

  // strings

  // const createString = "Place a marker";
  // const cancelString = "Cancel";

  const tooltipString = "Add a marker";

  // data

  const pdftronOffset = useSelector((s) => s.viewer3D.pdftronOffset);
  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);

  // virtual element for popper

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef();
  // const mouseRef = useRef();

  // state

  const [open, setOpen] = useState(false);
  const [pagePoint, setPagePoint] = useState();
  const [pageNumber, setPageNumber] = useState();

  useEffect(() => {
    if (!active) {
      setOpen(false); // hide popper when inactivating the tool.
    }
  }, [active]);
  // handlers

  function handleCreateClick() {
    onActiveChange(true);
    caplaEditor?.editorPdf?.triggerCreateMarker(); // to get cross hair pointer...
    caplaEditor?.editorPdf?.setOnClick(handlePdfEditorClick);
    caplaEditor?.editorPdf?.moveTempMarkerOnClick();
    caplaEditor?.editorPdf?.sceneEditor.enablePointer();
  }

  function handleCancelClick() {
    caplaEditor?.editorPdf?.activePanTool();
    caplaEditor?.editorPdf?.sceneEditor.disablePointer();
    caplaEditor?.editorPdf?.logPagePositionOnClick();
    onActiveChange(false);
    setOpen(false);
  }

  function handleButtonClick() {
    if (active) {
      handleCancelClick();
    } else {
      handleCreateClick();
    }
  }

  function handlePdfEditorClick(props) {
    if (active) {
      setOpen(true);
      console.log("click", props);
      const {screenP, pageCoordinates} = props;
      virtualElementRef.current.getBoundingClientRect = generateBBCR(
        screenP.x + pdftronOffset.x,
        screenP.y + pdftronOffset.y
      );
      setPagePoint(pageCoordinates);
      setPageNumber(pageCoordinates.pageNumber);

      // bimbox pointer
      const pagePoint = {...pageCoordinates};
      const refPagePoint = {
        x: (zone.x + zone.width) / 2,
        y: (zone.y + zone.height) / 2,
      };
      const refBimboxPoint = {x: 0, y: 0, z: 0};

      const bimboxPoint =
        caplaEditor?.editorPdf?.annotationsManager.getBimboxPoint({
          zone,
          pagePoint,
          refPagePoint,
          refBimboxPoint,
        });

      caplaEditor?.editor3d?.pointer.enable();
      caplaEditor?.editor3d?.pointer.setPosition(bimboxPoint);
    }
  }

  function handleMarkerSelect(marker) {
    const newMarker = {
      modelId: selectedModelId,
      ...pagePoint,
      markerId: marker.id,
      pageNumber,
    };
    dispatch(addPdfMarker(newMarker));
    caplaEditor?.editorPdf?.sceneEditor.saveTempMarker();

    // close tool
    caplaEditor?.editorPdf?.activePanTool();
    caplaEditor?.editorPdf?.sceneEditor.disablePointer();
    caplaEditor?.editorPdf?.logPagePositionOnClick();
    onActiveChange(false);
    setOpen(false);
  }

  useEffect(() => {
    virtualElementRef.current = {getBoundingClientRect: generateBBCR};
  }, []);

  useEffect(() => {
    caplaEditor?.editorPdf?.setOnClick(handlePdfEditorClick);
  }, [pdftronOffset.x, pdftronOffset.y, active]);

  return (
    <Box sx={{position: "relative"}}>
      <Tooltip title={tooltipString}>
        <IconButton
          size="small"
          onClick={handleButtonClick}
          color="inherit"
          sx={{visibility: show ? "visible" : "hidden"}}
        >
          <Add sx={{fontSize: 12}} />
        </IconButton>
      </Tooltip>

      <Box sx={{zIndex: 900}}>
        {virtualElementRef?.current && (
          <ActionPopper
            anchorEl={virtualElementRef.current}
            open={open && active}
            disablePortal={false}
          >
            <ActionSelectMarker onSelect={handleMarkerSelect} scene={scene} />
          </ActionPopper>
        )}
      </Box>
    </Box>
  );
}
