import {nanoid} from "@reduxjs/toolkit";
import sortByNum from "Utils/sortByNum";

function getFieldsFromRows(row1, row2) {
  // row1 = [cat1,cat1,cat2,cat3]
  // row2 = ["name","","name"]
  let catIndexes = []; // indexes of the new cat.
  let lastCat;
  let fields = [];
  let catIndex = -1;
  row1.forEach((item, index) => {
    if (item && item !== lastCat) {
      catIndexes.push(index);
      catIndex += 1;
    }
    if (fields[catIndex]) {
      fields[catIndex].push(row2[index]);
    } else {
      fields[catIndex] = [row2[index]];
    }
  });
  return [catIndexes, fields];
}

function initTreeWithFirstRank(rows, fields) {
  // fields=[name]
  const tree = [];
  let i = 1;
  rows.forEach((row, index) => {
    if (row[0]) {
      const item = {
        numArray: [i],
        key: row[0],
        rank: 1,
      };
      fields.forEach((field, index) => {
        item[field] = row[index];
      });
      tree.push(item);
      i += 1;
    }
  });
  //
  return tree;
}

function getItemFromRankAndKey(tree, rank, key) {
  return tree.find((item) => item.rank === rank && item.key === key);
}

function addColumnToTree(rank, columnIndex, rows, tree, fields) {
  // fields=[field1,field2,..
  let parentRank = rank - 1;

  let lastParentName;
  let lastItemName;
  let lastParentItem;
  let i = 1;

  rows.forEach((row, index) => {
    let parentName = row[columnIndex - 1] ?? lastParentName;
    let parentItem;
    if (
      row[columnIndex] &&
      parentName + row[columnIndex] !== lastParentName + lastItemName
    ) {
      // new Item
      let parentName = row[columnIndex - 1];
      if (parentName && parentName !== lastParentName) {
        // new Parent.
        lastParentName = parentName;
        parentItem = getItemFromRankAndKey(tree, parentRank, parentName);
        lastParentItem = parentItem;
        i = 1; // initialize
      } else {
        parentName = lastParentName;
        parentItem = lastParentItem;
        i += 1;
      }
      const numArray = [...parentItem.numArray, i];
      const newItem = {numArray, key: row[columnIndex], rank};
      fields.forEach((field, index) => {
        newItem[field] = row[columnIndex + index];
      });
      tree.push(newItem);
    }
  });
}

export default function getNomenclatureItemsFromSortedArray(array) {
  //
  // array : [[key1,key2,key3],[],...]
  // fields :[["name"],["name"],["name","code"]]
  //
  const row1 = array[0];
  const row2 = array[1];
  const row3 = array[2];
  //
  const [catIndexes, fields] = getFieldsFromRows(row1, row2);
  const catCount = fields.length;
  //
  const rows = array.slice(3);
  //
  const rankNames = catIndexes.map((index) => row3[index]);
  //
  const tree = initTreeWithFirstRank(rows, fields[0]);
  if (catCount > 1) {
    for (let i = 1; i < catCount; i += 1) {
      addColumnToTree(i + 1, catIndexes[i], rows, tree, fields[i]);
    }
  }

  // items

  const items = tree.map((row) => {
    const newRow = {...row};
    newRow["num"] = row.numArray.join(".");
    newRow.id = nanoid();
    delete newRow.numArray;
    delete newRow.key;
    delete newRow.rank;
    return newRow;
  });

  const sortedItems = items.sort((a, b) => sortByNum(a.num, b.num));

  return {rankNames, items: sortedItems};
}
