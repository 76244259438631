import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import useSelectedMarkersModel from "../hooks/useSelectedMarkersModel";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ActionsNewMarkerInSelectionPanel({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const createS = "Create";

  // data

  const selectedMarkersModel = useSelectedMarkersModel();
  const tempMarker = useSelector((s) => s.markers.tempMarker);
  // handlers

  function handleCreateClick() {
    const m = {
      ...tempMarker,
      id: nanoid(),
      type: "REFERENCE",
    };

    const entity = editor3d?.getEntity(selectedMarkersModel?.id);
    entity?.addMarker(m);
    // update model
    let oldElements = selectedMarkersModel.elements?.items;
    if (!oldElements) oldElements = [];
    const updatedModel = {
      ...selectedMarkersModel,
      elements: {items: [...oldElements, m]},
    };
    dispatch(updateModel({updatedModel}));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
