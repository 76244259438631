import {Button} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";
import useSelectedMeasurement from "../useSelectedMeasurement";

export default function ButtonRefreshMeasurements3D({editor3d, measurements}) {
  // strings

  const refreshS = "Refresh 3D";

  // data

  const model = useSelectedMeasurementsModel();

  const selectedMeasurement = useSelectedMeasurement();

  // handlers

  function handleRefresh() {
    measurements.forEach((measurement) => {
      if (selectedMeasurement?.id === measurement.id) {
        console.log("[DEBUG 32] selected measurement", measurement);
      }
      const entity = editor3d?.getEntity(model.id);
      entity?.refreshMeasurement3D(measurement);
    });
  }
  return (
    <Button startIcon={<Refresh />} size="small" onClick={handleRefresh}>
      {refreshS}
    </Button>
  );
}
