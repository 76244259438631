import {Box} from "@mui/material";

import SectionActionsSectors from "./SectionActionsSectors";

export default function SectionLeftPanelActionsSectors({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["editSectors"];
  const actionsMoreKeys = ["refreshSectors", "repairSectors"];

  return (
    <Box>
      <SectionActionsSectors
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
