import {nanoid} from "@reduxjs/toolkit";
import React, {useState} from "react";

import useAddScenePhase from "../hooks/useAddScenePhase";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import FormPhase from "./FormPhase";

export default function ButtonDialogCreatePhase({scene}) {
  // strings

  const newS = "New phase";

  const cancelS = "Cancel";
  const createS = "Create";

  // data

  const addScenePhase = useAddScenePhase(scene);

  // state

  const [phase, setPhase] = useState({});

  const [open, setOpen] = useState(false);

  // helper

  const isValid = phase.num?.length > 0;

  console.log("phaseN", phase.num);

  // helper - hiddenFields

  const hiddenFields = [];

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handlePhaseChange(phase) {
    setPhase(phase);
  }

  function handleSave() {
    const newPhase = {...phase, id: nanoid()};
    addScenePhase(newPhase);
    setOpen(false);
    setPhase({});
  }

  function handleCancelClick() {
    setOpen(false);
    setPhase({});
  }

  return (
    <Box>
      <Button
        startIcon={<Add />}
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleOpenDialog}
      >
        {newS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{newS}</DialogTitle>
        <DialogContent>
          <FormPhase
            phase={phase}
            onChange={handlePhaseChange}
            hiddenFields={hiddenFields}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button disabled={!isValid} onClick={handleSave}>
            {createS}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
