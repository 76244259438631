import {useDispatch, useSelector} from "react-redux";

import {ToggleButton, Tooltip, Box} from "@mui/material";
import {Straighten as Measure} from "@mui/icons-material";

import {setToolViewer} from "Features/viewer3D/viewer3DSlice";

export default function ToggleMeasureTool({editor}) {
  const dispatch = useDispatch();

  // strings

  const tipString = "Measure";

  // data

  const tool = useSelector((state) => state.viewer3D.toolViewer);
  const checked = tool === "MEASURE";

  // handlers

  const handleChange = () => {
    if (checked) {
      dispatch(setToolViewer(null));
    } else {
      dispatch(setToolViewer("MEASURE"));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
      }}
    >
      <Tooltip title={tipString}>
        <ToggleButton
          value="MEASURE"
          selected={checked}
          onChange={handleChange}
          aria-label="module"
          sx={{
            width: 30,
            height: 30,
            border: "none",
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Box
            sx={{
              color: "common.white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Measure fontSize="small" color="inherit" />
          </Box>
        </ToggleButton>
      </Tooltip>
    </Box>
  );
}
