import {useSelector} from "react-redux";
import getRessourcesFromGroupRemote from "../utils/getRessourcesFromGroupRemote";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function useRessourcesByScene(scene, option) {
  const editMode = option?.editMode;
  const filterByScope = option?.filterByScope;

  const editedRessources = useSelector((s) => s.ressources.editedRessources);

  const scope = useSelectedScope();

  let ressourcesGroups = useSelector((s) => s.ressources.ressourcesGroups);
  if (filterByScope && scope?.data?.ressourcesGroupIds) {
    ressourcesGroups = ressourcesGroups.filter((g) =>
      scope.data.ressourcesGroupIds.includes(g.id)
    );
  }

  // helper - ressources from scene

  let ressourcesFromScene = scene?.data?.ressources;
  if (!ressourcesFromScene) ressourcesFromScene = [];
  ressourcesFromScene = ressourcesFromScene.map((r) => ({
    ...r,
    groupId: r.group ?? "undefined",
    group: r.group ?? "-?-",
    fromScene: true,
  }));

  // helper - ressources from remote

  let ressourcesFromRemote = [];
  ressourcesGroups.forEach((group) => {
    const ressources = getRessourcesFromGroupRemote(group);
    ressources.forEach((ressource) => ressourcesFromRemote.push(ressource));
  });

  if (!editMode) {
    return [...ressourcesFromScene, ...ressourcesFromRemote];
  } else {
    return editedRessources;
  }
}
