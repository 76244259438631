export default function mergeArticlesBatchUpdates(batch1, batch2) {
  const ids1 = [];
  const ids2 = [];
  const batchMap1 = {};
  const batchMap2 = {};

  batch1.forEach((update) => {
    ids1.push(update.id);
    batchMap1[update.id] = update;
  });

  batch2.forEach((update) => {
    ids2.push(update.id);
    batchMap2[update.id] = update;
  });

  const commonIds = ids1.filter((id) => ids2.includes(id));
  const allIds = [...new Set([...ids1, ...ids2])];

  const mergedUpdates = commonIds.map((id) => ({
    ...batchMap1[id],
    ...batchMap2[id],
  }));

  return allIds.map((id) => {
    if (commonIds.includes(id) || ids1.includes(id)) {
      return batchMap1[id];
    } else {
      return batchMap2[id];
    }
  });
}
