import {createSlice} from "@reduxjs/toolkit";

export const cutsSlice = createSlice({
  name: "cuts",
  initialState: {
    items: [],
  },
  reducers: {
    addCut: (state, action) => {
      state.items.push(action.payload);
    },
    setCutBeingAdded: (state, action) => {
      state.cutBeingAdded = action.payload;
    },
  },
});

export const {addCut, setCutBeingAdded} = cutsSlice.actions;

export default cutsSlice.reducer;
