import useScenesAndRemoteScenes from "Features/scenes/useScenesAndRemoteScenes";
import useScenesAreLoaded from "Features/scenes/useScenesAreLoaded";

import Stretch from "Features/ui/components/Stretch";

import DataGridScenes from "../../DataGridScenes";
import GridScenes from "./GridScenes";

import {Box} from "@mui/material";

//import PageFirstSceneCreation from "../../PageFirstSceneCreation";

export default function SectionMainV2({scenes, mode}) {
  return (
    <Box sx={{display: "flex", width: 1, flexGrow: 1}}>
      {mode === "LIST" && <DataGridScenes scenes={scenes} />}
      {mode === "GRID" && <GridScenes scenes={scenes} />}
      {/* {noScenes && <PageFirstSceneCreation />} */}
    </Box>
  );
}
