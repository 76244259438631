import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

const properValue = (excelValue) => {
  if (excelValue && typeof excelValue === "object") {
    if (excelValue.result) return excelValue.result;
    if (excelValue.hyperlink) return excelValue.text;
    if (excelValue.richText)
      return excelValue.richText.map((t) => t.text).join("");
    return null; // sharedFormula without result for instance
  }
  // typeof date ?
  return excelValue;
};

const properNum = (num) => {
  let proper = false;
  if (num && typeof num === "string") {
    if (num.includes(".")) {
      let possibleProper = true;
      const possibleNumbers = num.split(".");
      possibleNumbers.forEach((n) => {
        if (isNaN(parseInt(n))) possibleProper = false;
      });
      proper = possibleProper;
    } else if (!isNaN(parseInt(num))) {
      proper = true;
    }
  }
  return proper;
};

const incrementNum = (num, mode, firstNull) => {
  if (!num) return null;
  if (mode === "sous-titre") return num + ".0";
  if (firstNull) {
    return num + ".1";
  } else {
    const split = num.split(".");
    const popped = parseInt(split.pop()) + 1;
    return [...split, popped.toString()].join(".");
  }
};

export default async function getRessourcesFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];
  const rowCount = worksheet.rowCount;

  const firstValue = properValue(worksheet.getRow(1).getCell(1).value);

  if (typeof firstValue === "string" && firstValue.toLowerCase() === "start") {
    const start = properValue(worksheet.getRow(1).getCell(2).value);
    const end = properValue(worksheet.getRow(2).getCell(2).value);
    const group = properValue(worksheet.getRow(3).getCell(2).value);

    const columnToUse = {
      num: null,
      name: [],
      mode: null,
      style: null,
      title: null,
      no_num: null,
      qty_ref: null,
      unit: null,
      location: null,
      description: null,
    };
    const header = worksheet.getRow(start);
    for (let i = 1; i <= header.values.length; i++) {
      let value = properValue(header.getCell(i).value);
      if (typeof value === "string") {
        value = value.toLowerCase();
        if (value === "name") columnToUse["name"].push(i);
        else if (value in columnToUse) columnToUse[value] = i;
      }
    }

    const rows = worksheet.getRows(start + 1, end - start).values();

    let lastNum = null;
    let lastUnit = null;
    let firstNull = true;
    let templateStyle = {};
    let loadedRessources = [];
    for (let row of rows) {
      let name = columnToUse["name"]
        .map((i) => properValue(row.getCell(i).value))
        .filter((n) => n);
      if (name && name.length > 0) {
        name = name.join(" ");
        let num = columnToUse.num
          ? properValue(row.getCell(columnToUse.num).value)
          : null;
        if (num && typeof num !== "string") num = num.toString();
        const mode = columnToUse.mode
          ? properValue(row.getCell(columnToUse.mode).value)
          : null;
        if (num && properNum(num)) {
          firstNull = true;
        } else {
          num = incrementNum(lastNum, mode, firstNull);
          firstNull = false;
        }
        const rank = num ? num.split(".").length : null;
        let isTitle = columnToUse.title
          ? properValue(row.getCell(columnToUse.title).value)
          : null;
        let style = columnToUse.style
          ? properValue(row.getCell(columnToUse.style).value)
          : null;
        let hideNum = columnToUse.no_num
          ? properValue(row.getCell(columnToUse.no_num).value)
          : null;
        let unit = columnToUse.unit
          ? properValue(row.getCell(columnToUse.unit).value)
          : null;
        let description = columnToUse.description
          ? properValue(row.getCell(columnToUse.description).value)
          : null;
        let location = columnToUse.location
          ? properValue(row.getCell(columnToUse.location).value)
          : null;
        const refQty = columnToUse.qty_ref
          ? properValue(row.getCell(columnToUse.qty_ref).value)
          : null;
        if (mode === "sous-titre") {
          isTitle = "x";
          hideNum = "x";
          if (!templateStyle["sous-titre"]) {
            templateStyle["sous-titre"] = style;
          } else {
            style = templateStyle["sous-titre"];
          }
        } else if (!templateStyle.info && mode === "info") {
          templateStyle["info"] = {
            style,
            hideNum,
            isTitle,
          };
        } else if (!templateStyle[rank]) {
          templateStyle[rank] = {
            style,
            hideNum,
            isTitle,
          };
        }
        if (!style) {
          if (mode === "info") style = templateStyle.info.style;
          else style = templateStyle[rank].style;
        }
        if (!isTitle) {
          if (mode === "info") isTitle = templateStyle.info.isTitle;
          else isTitle = templateStyle[rank].isTitle;
        }
        if (!hideNum) {
          if (mode === "info") hideNum = templateStyle.info.hideNum;
          else hideNum = templateStyle[rank].hideNum;
        }
        if (!unit && !isTitle) {
          unit = lastUnit;
        }
        loadedRessources.push({
          id: null,
          group,
          num,
          name,
          code: null,
          isTitle: isTitle ? "x" : isTitle,
          style,
          hideNum,
          unit,
          dec: null,
          refQty,
          hardcodedQty: null,
          location,
          description,
          toDelete: null,
        });
        lastNum = num;
        if (!isTitle) lastUnit = unit;
      }
    }
    return loadedRessources;
  } else {
    const rows = worksheet.getRows(2, rowCount - 1).values();

    let valuesA = [];
    for (let row of rows) {
      const rowValues = [];
      for (let i = 1; i <= row.values.length; i++) {
        rowValues.push(properValue(row.getCell(i).value));
      }
      valuesA.push(rowValues);
    }

    const fields = [
      "id",
      "group",
      "num",
      "name",
      "code",
      "isTitle",
      "style",
      "hideNum",
      "unit",
      "dec",
      "refQty",
      "hardcodedQty",
      "location",
      "description",
      "toDelete",
    ];

    const loadedRessources = [];

    valuesA.map((valueA) => {
      const ressource = {};
      valueA.forEach((value, index) => (ressource[fields[index]] = value));
      loadedRessources.push(ressource);
    });

    return loadedRessources;
  }
}
