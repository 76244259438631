import {useState} from "react";

import {Paper, Typography, Box, Button} from "@mui/material";

import useUpdateBimboxMode from "Features/scenes/useUpdateBimboxMode";

export default function SectionConfigModesOneMode({
  name,
  bgcolor,
  modeKey,
  selected,
  onClick,
  open,
  sceneId,
}) {
  const applyS = "Appliquer le mode";

  // data

  const updateBimboxMode = useUpdateBimboxMode();

  // helpers

  const showButton = open && !selected;

  // handlers

  function handleClick() {
    onClick();
  }

  function handleChangeMode() {
    updateBimboxMode({sceneId, bimboxMode: modeKey});
  }

  return (
    <Box sx={{p: 2}}>
      <Paper
        sx={{
          flex: 1,
          color: "white",
          p: 3,
          bgcolor: selected ? bgcolor : "grey.700",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...(modeKey === "EXPERT" &&
            selected && {
              background: (theme) =>
                `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
            }),
        }}
        elevation={selected ? 12 : 3}
        onClick={handleClick}
      >
        <Typography>{name}</Typography>
      </Paper>
      <Box
        sx={{
          visibility: showButton ? "visible" : "hidden",
          mt: 1,
          color: "common.white",
        }}
      >
        <Button color="inherit" onClick={handleChangeMode}>
          {applyS}
        </Button>
      </Box>
    </Box>
  );
}
