import {Box, Typography, Button} from "@mui/material";

export default function FieldSection({title, onEdit, readOnly = true}) {
  const editS = "Edit";

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        width: 1,
        p: 0.5,
        borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{fontSize: 13, fontWeight: "bold"}} noWrap>
        {title}
      </Typography>
      {!readOnly && (
        <Button sx={{height: 20}} onClick={onEdit}>
          {editS}
        </Button>
      )}
    </Box>
  );
}
