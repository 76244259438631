import {useSelector, useDispatch} from "react-redux";

import {updateArticlesGroupTemp} from "../articlesSlice";

import {Box, IconButton} from "@mui/material";
import {ArrowUpward as Up, ArrowDownward as Down} from "@mui/icons-material";

export default function BlockEditableArticleIndex({article, articlesGroup}) {
  const dispatch = useDispatch();

  // handlers

  function handleUpOrDownClick(mode) {
    const oldIndex = articlesGroup.sortedArticles.indexOf(article.clientId);
    let newSortedArticles = articlesGroup.sortedArticles.filter(
      (clientId) => clientId !== article.clientId
    );
    const newIndex = mode === "up" ? oldIndex - 1 : oldIndex + 1;
    newSortedArticles.splice(newIndex, 0, article.clientId);
    dispatch(
      updateArticlesGroupTemp({
        id: articlesGroup.id,
        sortedArticles: newSortedArticles,
      })
    );
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Box sx={{height: 30, width: 30, display: "flex", alignItems: "center"}}>
        <IconButton size="small" onClick={() => handleUpOrDownClick("down")}>
          <Down fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{height: 30, width: 30, display: "flex", alignItems: "center"}}>
        <IconButton size="small" onClick={() => handleUpOrDownClick("up")}>
          <Up fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}
