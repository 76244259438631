import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setSubjectElementTypeMap} from "../annotatedPdfSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import {Button, Box} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function ButtonRefreshSubjectElementTypeMap({scene}) {
  const dispatch = useDispatch();

  // string

  const refreshS = "Rafraîchir";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );

  // handlers

  function handleClick() {
    const newMap = subjectElementTypeMap ? {...subjectElementTypeMap} : {};
    elementTypes.forEach((type) => {
      if (type.subject) {
        newMap[type.subject] = type;
      }
    });
    dispatch(setSubjectElementTypeMap(newMap));
  }
  return (
    <Box
      sx={{
        color: "text.secondary",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button color="inherit" onClick={handleClick}>
        {refreshS}
      </Button>
    </Box>
  );
}
