
import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

const properValue = (excelValue) => {
  if (excelValue && typeof excelValue === "object") {
    if (excelValue.result) return excelValue.result;
    if (excelValue.hyperlink) return excelValue.text;
    if (excelValue.richText)
      return excelValue.richText.map((t) => t.text).join("");
  }
  // typeof date ?
  return excelValue;
};

export default async function getRessourcesWithPUFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];
  const rowCount = worksheet.rowCount;
  const excelGroup = properValue(worksheet.getRow(1).getCell(1).value);
  const rows = worksheet.getRows(3, rowCount - 2).values();
  let valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(properValue(row.getCell(i).value));
    }
    valuesA.push(rowValues);
  }
  const fields = [
    "num",
    "name",
    "unit",
    "pu",
  ];
  const excelRessources = [];
  valuesA.map((valueA) => {
    const ressource = {};
    valueA.forEach((value, index) => (
      ressource[fields[index]] = properValue(value)
    ));
    excelRessources.push(ressource);
  });
  return {excelGroup, excelRessources};
}
