import {useDispatch} from "react-redux";

import {Box, Typography, IconButton, Icon} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

import {setShowPdfSelector} from "../pdfSlice";

import {PictureAsPdf as Pdf} from "@mui/icons-material";
import useSceneModule from "Features/navigation/useSceneModule";

export default function SelectorPdfModelInAppTopBar({model, shrink}) {
  const dispatch = useDispatch();
  // string

  const selectS = "Sélectionnez un pdf";

  // data

  const sceneModule = useSceneModule();

  // helper

  const noModel = !Boolean(model?.id);

  // helper

  const colorIsWarning =
    ["EDIT_MEASUREMENTS", "ONBOARDING"].includes(sceneModule) && noModel;

  // handlers

  function handleMoreClick() {
    dispatch(setShowPdfSelector(true));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: "26px",
        display: "flex",
        justifyContent: "space-between",
        color: "common.white",
        alignItems: "center",
        bgcolor: (theme) =>
          colorIsWarning
            ? "warning.main"
            : lighten(theme.palette.common.caplaBlack, 0.2),
        borderRadius: "13px",
        p: 0,
        pl: 1,
        cursor: "pointer",
      }}
      onClick={handleMoreClick}
    >
      <Pdf sx={{mr: 1, fontSize: 15}} />
      {!shrink && (
        <Typography noWrap sx={{fontSize: 12}} color="inherit">
          {!noModel ? model?.name : selectS}
        </Typography>
      )}
      <IconButton size="small" onClick={handleMoreClick} color="inherit">
        <Down color="inherit" fontSize="small" />
      </IconButton>
    </Box>
  );
}
