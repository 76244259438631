import {nanoid} from "@reduxjs/toolkit";
import React, {useState} from "react";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import theme from "Styles/theme";

import FormProgressRecord from "./FormProgressRecord";

import {useDispatch, useSelector} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";
import useSelectedProgressListing from "../useSelectedProgressListing";
import {createProgressRecord} from "../progressSlice";

export default function ButtonDialogCreateProgressRecord({scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const newS = "New record";

  const cancelS = "Cancel";
  const createS = "Create";

  // data

  const selectedProgressListing = useSelectedProgressListing(scene);
  const userData = useSelector((s) => s.auth.data);

  // helpers

  const listingId = selectedProgressListing?.id;

  // state

  const [progressRecord, setProgressRecord] = useState({
    name: "",
  });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handleProgressRecordChange(progressRecord) {
    setProgressRecord(progressRecord);
  }

  async function handleSave() {
    if (loading) return;

    setLoading(true);

    const newProgressRecord = {
      ...progressRecord,
      type: "PROGRESS",
      scene: scene?.id,
      createdBy: userData.id,
    };
    await dispatch(
      createProgressRecord({
        accessToken,
        progressRecord: newProgressRecord,
        listingId,
      })
    );

    setLoading(false);
    setOpen(false);
  }

  function handleCancelClick() {
    setOpen(false);
  }

  return (
    <Box>
      <Button
        startIcon={<Add />}
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleOpenDialog}
      >
        {newS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{newS}</DialogTitle>
        <DialogContent>
          <FormProgressRecord
            progressRecord={progressRecord}
            onChange={handleProgressRecordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button onClick={handleSave}>{createS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
