import styleRankMap from "../data/styleRankMap";

export default function getArticleChildren(articleIndex, articles) {
  //
  const article = articles[articleIndex];
  if (!article) return [];
  //
  const articleRank = styleRankMap[article.style];
  //
  const tempNum = article.tempNum;
  if (!tempNum) return [];
  //
  const children = [];
  let i = 1;
  let stop = false;
  let i_max = articles.length - articleIndex;
  //
  while (!stop && i <= i_max && i < 500) {
    const childIndex = articleIndex + i;
    const child = articles[childIndex];
    if (child) {
      const childRank = styleRankMap[child.style];
      if (childRank > articleRank) {
        children.push(child);
      } else {
        stop = true;
      }
    }
    i += 1;
  }

  // console.log(
  //   "childrenAA",
  //   article.name,
  //   ":",
  //   children.map((c) => c.name)
  // );
  return children;
}
