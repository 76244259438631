import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";

import {
  Typography,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import sortByNum from "../utils/sortByNum";
import useRoomsByScene from "../hooks/useRoomsByScene";

import {setRoomsInScope} from "Features/measurements/measurementsSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";

export default function SelectorRoomInScope({scene, viewer, editor3d}) {
  const dispatch = useDispatch();

  // data

  // params

  const {modelId} = useParams();
  const isEditMode = modelId ? true : false;

  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    true,
    false,
    false,
    false,
    viewer,
  );
  const fmRoomIds = [...new Set(fms.map((m) => m.roomId))];
  const allRooms = useRoomsByScene(scene);

  let rooms = [{id: undefined, name: "Tous", num: "-9999"}];
  if (viewer || !isEditMode) {
    fmRoomIds.map((id) => {
      if (id === undefined) {
        rooms.push({id: null, name: "-?-", num: "9999"});
      } else if (id) {
        rooms.push(allRooms.find((s) => s.id === id));
      }
    });
  } else {
    allRooms.map((r) => {
      rooms.push({...r, name: `${r.name} (${r.sector})`});
    })
  }
  rooms = rooms.sort((a, b) => sortByNum(a.num, b.num));

  const roomsInScope = useSelector((s) => s.measurements.roomsInScope);
  const scopeRoomId = roomsInScope[0];
  let scopeRoom = rooms.find((i) => i.id === scopeRoomId);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helper - label

  const label = scopeRoom?.name;

  // handlers

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(model) {
    if (model.id === undefined) dispatch(setRoomsInScope([]));
    else dispatch(setRoomsInScope([model.id]));
    setAnchorEl(null);
    editor3d?.sceneEditor.hideAllImageModels();
  }

  return (
    <>
      <Paper
        elevation={12}
        onClick={handleMoreClick}
        size="small"
        sx={{
          color: "common.white",
          bgcolor: "common.caplaBlack",
          px: 1,
          pl: 2,
          display: "flex",
          alignItems: "center",
          borderRadius: "15px",
          height: "30px",
          cursor: "pointer",
        }}
      >
        <Typography sx={{fontSize: 13}}>{label}</Typography>
        <Down sx={{ml: 2}} color="inherit" size="small" />
      </Paper>
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {rooms.map((room) => {
          const selected = room.id === scopeRoomId;
          return (
            <MenuItem
              key={room.name}
              onClick={() => handleChange(room)}
              selected={selected}
            >
              <Typography variant="body2">{room.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
