import {useSelector} from "react-redux";

import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

import {Box} from "@mui/material";

import CardPackageInHome from "./CardPackageInHome";

export default function ListPackagesInHomeAdmin({scene, caplaEditor}) {
  // data

  const allPackages = usePackagesByScene(scene);

  const selectedTypeGroupIdsInHome = useSelector(
    (s) => s.boxhome.selectedElementTypeGroupIdsInHome
  );

  // helpers - relevant packages and pdfs

  const selectedGroupId = selectedTypeGroupIdsInHome?.[0];
  const relevantPackages = allPackages.filter(
    (model) =>
      model.elementTypesGroupIds?.includes(selectedGroupId) ||
      model.restrictedTypes?.includes(selectedGroupId)
  );

  // helpers

  const packages = selectedGroupId ? relevantPackages : allPackages;

  return (
    <Box
      sx={{flexGrow: 1, display: "flex", flexDirection: "column", minWidth: 0}}
    >
      {packages.map((model) => {
        return (
          <CardPackageInHome
            model={model}
            scene={scene}
            caplaEditor={caplaEditor}
          />
        );
      })}
    </Box>
  );
}
