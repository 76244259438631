/*
 * ressource : {id,num,code,name}
 */

import {useDispatch, useSelector} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";
import useRessourceGroupsByScene from "./useRessourceGroupsByScene";
import useRessourcesGroupsProxyByScene from "./useRessourcesGroupsProxy";

import useAccessToken from "Features/auth/useAccessToken";
import {updateRessourcesGroup} from "../ressourcesSlice";

export default function useUpdateSceneRessource(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const groupsProxy = useRessourcesGroupsProxyByScene(scene);
  const ressourcesGroups = useSelector((s) => s.ressources.ressourcesGroups);

  const updateSceneRessource = async (ressource) => {
    //
    const groupProxy = groupsProxy.find((g) => g.name === ressource.group);
    //
    if (groupProxy?.fromScene) {
      if (!scene) return;
      let oldRessources = scene?.data?.ressources.filter(
        (s) => s?.id !== ressource.id
      );
      if (!oldRessources) oldRessources = [];

      const newScene = {
        ...scene,
        data: {...scene?.data, ressources: [...oldRessources, ressource]},
      };

      dispatch(updateScene({scene: newScene}));
    } else {
      const ressourcesGroup = ressourcesGroups.find(
        (g) => g.id === groupProxy.id
      );
      const newRessources = ressourcesGroup.ressources.map((r) => {
        if (r.id === ressource.id) {
          return {...r, ...ressource};
        } else {
          return r;
        }
      });
      const updatedRessourcesGroup = {
        ...ressourcesGroup,
        ressources: newRessources,
      };
      await dispatch(
        updateRessourcesGroup({
          accessToken,
          ressourcesGroup: updatedRessourcesGroup,
        })
      );
    }
  };

  return updateSceneRessource;
}
