import {useDispatch} from "react-redux";
import useAccessToken from "Features/auth/useAccessToken";
import {updateBimapp} from "../bimappsSlice";

export default function useUpdateSceneBimapp() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const updateSceneBimapp = (bimapp) => {
    dispatch(updateBimapp({bimapp, accessToken}));
  };

  return updateSceneBimapp;
}
