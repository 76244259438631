import {useEffect, useState} from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Autocomplete,
  TextField,
  Typography,
  Box,
  Checkbox,
} from "@mui/material";

export default function SelectorAutocompleteMultipleWithDialog({
  name,
  value,
  options,
  onChange,
  disabled,
  checkAll = false,
}) {
  // strings

  const cancelS = "Annuler";
  const createS = "Sauvegarder";
  const allS = "Tout";

  // state

  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  // helpers

  const allIds = new Set(options?.map((o) => o.id));
  const selectedIds = new Set(currentValue?.map((v) => v.id));
  options = options.sort((a, b) => a.name.localeCompare(b.name));

  // handlers

  function handleOptionChange(value, reason) {
    const newValue = reason === "clear" ? [] : value;
    setCurrentValue(newValue);
    onChange(newValue);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setCurrentValue(value);
  }

  function handleCheckbox(option) {
    if (!selectedIds.has(option.id)) {
      setCurrentValue([...currentValue, option]);
    } else {
      setCurrentValue([...currentValue.filter((v) => v.id !== option.id)]);
    }
  }

  function handleAllChecked() {
    const allChecked = allIds.size === selectedIds.size;
    if (!allChecked) setCurrentValue([...options]);
    else setCurrentValue([]);
  }

  function handleSave() {
    setOpen(false);
    onChange(currentValue);
  }

  // effects

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Box sx={{width: 1}}>
      <Box sx={{width: 1, p: 1}}>
        <Autocomplete
          sx={{flexGrow: 1}}
          disabled={disabled}
          size="small"
          options={options}
          multiple
          limitTags={2}
          getOptionLabel={(option) => option?.name}
          value={currentValue}
          open={false}
          onOpen={handleOpen}
          onChange={(e, v, r) => handleOptionChange(v, r)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={name && <Typography variant="body2">{name}</Typography>}
              InputProps={{
                ...params.InputProps,
                sx: {
                  fontSize: 13,
                },
              }}
            />
          )}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <Box
          sx={{display: "flex", minWidth: 300, justifyContent: "space-between"}}
        >
          <DialogTitle>{name}</DialogTitle>
          {checkAll && (
            <FormControlLabel
              sx={{mr: 0}} // to keep a margin...
              control={
                <Checkbox
                  size="small"
                  checked={allIds.size === selectedIds.size}
                  onClick={() => handleAllChecked()}
                  sx={{mr: 2, height: "14px", width: "14px"}}
                />
              }
              label={
                <Typography variant="body2" sx={{px: 1}}>
                  {allS}
                </Typography>
              }
              labelPlacement="start"
            />
          )}
        </Box>
        <DialogContent>
          {options.map((option) => {
            return (
              <Box key={option.id} sx={{display: "flex", minWidth: 300}}>
                <FormControlLabel
                  sx={{ml: 0}} // to keep a margin...
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedIds.has(option.id)}
                      onClick={() => handleCheckbox(option)}
                      sx={{mr: 1, height: "14px", width: "14px"}}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{px: 1}}>
                      {option.name}
                    </Typography>
                  }
                />
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {cancelS}
          </Button>
          <Button variant="contained" onClick={handleSave}>
            {createS}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
