import SelectorAutocompleteMultipleWithDialog from "Components/SelectorAutocompleteMultipleWithDialog";

import useRoomsGroups from "../hooks/useRoomsGroups";

export default function FieldRoomsGroups({
  scene,
  name,
  value,
  onChange,
  locked,
}) {
  // data

  const groups = [...useRoomsGroups(scene)];

  // helpers

  const selection = Array.isArray(value)
    ? groups.filter((group) => value.includes(group.id))
    : [];

  // handlers

  function handleChange(groups) {
    onChange(groups.map((group) => group.id));
  }

  return (
    <SelectorAutocompleteMultipleWithDialog
      name={name}
      value={selection}
      onChange={handleChange}
      options={groups}
      disabled={locked}
      checkAll={true}
    />
  );
}
