import {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import useGetArticleQtyMap from "./useGetArticleQtyMap";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

import {setArticleQtyMap, setArticleQtiesMapBySector} from "../articlesSlice";

import getSectorMeasurementsMap from "../utils/getSectorMeasurementsMap";
import getArticlesForDebug from "../utils/getArticlesForDebug";

export default function useAutoUpdateArticleQtyMap({
  scene,
  articles,
  measurements,
  bySector = false,
}) {
  const dispatch = useDispatch();
  //
  const [getArticleQtyMap, functionUpdatedAt] = useGetArticleQtyMap(scene); // /!\ getArticleQtyMap may change.Base on elementTypes & sectors.
  //
  const lastUpdateAt = useSelector((s) => s.articles.lastUpdateAt);
  //

  useEffect(() => {
    // global
    const articleQtyMap = getArticleQtyMap({articles, measurements});

    // by sectorwu

    const articlesQtiesMapBySector = {};

    if (bySector) {
      const sectorMeasurementsMap = getSectorMeasurementsMap(measurements);
      const measSectorIds = Object.keys(sectorMeasurementsMap);

      Object.entries(sectorMeasurementsMap).forEach(
        ([sectorId, measurements]) => {
          articlesQtiesMapBySector[sectorId] = getArticleQtyMap({
            articles,
            measurements,
          });
        }
      );
      articlesQtiesMapBySector["total"] = articleQtyMap;
    }

    // dispatch

    dispatch(setArticleQtyMap(articleQtyMap));
    if (bySector) {
      dispatch(setArticleQtiesMapBySector(articlesQtiesMapBySector));
    }
  }, [lastUpdateAt, articles?.length]); // articles.length : to trigger with subArticles.
}
