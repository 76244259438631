import {useSelector} from "react-redux";

import AppTopBarDesktop from "./AppTopBarDesktop";
import AppTopBarMobile from "./AppTopBarMobile";

export default function AppTopBar({caplaEditor, scene, viewer}) {
  const dOm = useSelector((s) => s.ui.desktopOrMobile);
  const isMobile = dOm === "MOBILE";

  if (isMobile) {
    return <AppTopBarMobile scene={scene} />;
  } else {
    return <AppTopBarDesktop caplaEditor={caplaEditor} scene={scene} viewer={viewer} />;
  }
}
