import {createSlice} from "@reduxjs/toolkit";

export const selectionSlice = createSlice({
  name: "selection",
  initialState: {
    selectionPanelWidth: 370,
    selectionPanelWidthDefault: 370,
    panelIsLarge: false,
    openSelectionPanel: false,
    selectionTabInViewer: "ressource",
    selectionInViewerFirstRendered: false,

    openElementTypeSelectionSectionInPanel: false,
  },
  reducers: {
    setOpenSelectionPanel: (state, action) => {
      state.openSelectionPanel = action.payload;
    },
    setSelectionPanelWidth: (state, action) => {
      state.selectionPanelWidth = action.payload;
    },
    setSelectionTabInViewer: (state, action) => {
      state.selectionTabInViewer = action.payload;
    },
    setSelectionInViewerFirstRendered: (state, action) => {
      state.selectionInViewerFirstRendered = action.payload;
    },
    toggleLargePanel: (state) => {
      const ratio = 2;
      if (!state.panelIsLarge) {
        state.selectionPanelWidth = ratio * state.selectionPanelWidthDefault;
        state.panelIsLarge = true;
      } else {
        state.selectionPanelWidth = state.selectionPanelWidthDefault;
        state.panelIsLarge = false;
      }
    },
  },
});

export const {
  setOpenSelectionPanel,
  setSelectionPanelWidth,
  setSelectionTabInViewer,
  setSelectionInViewerFirstRendered,
  toggleLargePanel,
} = selectionSlice.actions;

export default selectionSlice.reducer;
