import {Box, Typography} from "@mui/material";

import TopBarToolbox from "./TopBarToolbox";
import SectionTools from "./SectionTools";

import bimbox from "Assets/bimbox.svg";
import Stretch from "Features/ui/components/Stretch";

export default function PageToolbox() {
  return (
    <Box sx={{width: 1, height: 1, display: "flex"}}>
      <Box sx={{width: 1, height: 1, bgcolor: "common.white"}}>
        <Box sx={{height: "60px", p:1}}>
          <TopBarToolbox />
        </Box>
        <Stretch>
          <Box sx={{display: "flex", height: 1}}>
            <Box sx={{
              width: 0.5,
              height: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <Box sx={{
                diplay: "flex",
                flexDirection: "column",
              }}>
                <Box>
                  <Box sx={{width: 300}}>
                    <img src={bimbox} width="100%" />
                  </Box>
                  <Typography variant="h6" align="center">
                    Toolbox by CAPLA Technologies
                  </Typography>
                </Box>
                <Typography variant="body2" align="center">
                    Contact : contact@capla-technologies.com
                  </Typography>
              </Box>
            </Box>
            <Box sx={{display: "flex", height: 1, width: 0.5}}>
              <SectionTools />
            </Box>
          </Box>
        </Stretch>
      </Box>
    </Box>
  );
}
