import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedFormGroup} from "../ressourcesSlice";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtRessource from "Features/kvform/hooks/useKvtRessource";

export default function FormRessource({
  scene,
  ressource,
  onChange,
  hiddenFields = [],
  canEdit = true,
}) {
  const dispatch = useDispatch();

  // template

  const template = useKvtRessource(scene, ressource);

  // data

  const selectedFormGroup = useSelector((s) => s.ressources.selectedFormGroup);
  const ressourceGroup = useSelector(
    (s) => s.ressources.selectedRessourceGroup
  );

  // helpers

  let item = {};
  if (ressource) {
    if (ressource.group) {
      item = {
        ...ressource,
        group: {id: ressource.group, name: ressource.group},
      };
    } else {
      item = {
        ...ressource,
        group: ressourceGroup ? {id: ressourceGroup, name: ressourceGroup} : null,
      }
    };
  }

  // handlers

  function handleChange(ressource) {
    const newRessource = {...ressource, group: ressource.group?.name};
    onChange(newRessource);
  }

  function handleGroupChange(group) {
    dispatch(setSelectedFormGroup(group));
  }

  return ressource ? (
    <Box sx={{width: 1}}>
      <KeyValueForm
        scene={scene}
        item={item}
        template={template}
        onChange={handleChange}
        hiddenFields={hiddenFields}
        sizeKey={5}
        canEdit={canEdit}
        marginTop={true}
        group={selectedFormGroup}
        onGroupChange={handleGroupChange}
      />
    </Box>
  ) : (
    <div />
  );
}
