import {saveBlob, urlToBlob} from "Features/files/utils";
import React from "react";

import {Box, Button} from "@mui/material";
import {Download} from "@mui/icons-material";

import template from "../assets/template_excel_config_fr.xlsx";

export default function ButtonDownloadConfigExcel() {
  // string

  const labelS = "Télécharger le template excel";
  const fileName = "template_config_bimbox.xlsx";

  // handlers

  async function handleClick() {
    const blob = await urlToBlob(template);
    saveBlob(blob, fileName);
  }
  return (
    <Box sx={{color: "text.secondary"}}>
      <Button
        size="small"
        color="inherit"
        startIcon={<Download />}
        onClick={handleClick}
      >
        {labelS}
      </Button>
    </Box>
  );
}
