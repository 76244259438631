import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchOrganisationsService,
  postOrganisationService,
  fetchOrganisationService,
  updateOrganisationService,
  //
  fetchOrganisationNomenclatureService,
  fetchOrganisationNomenclaturesService,
  updateOrganisationNomenclatureService,
  postOrganisationNomenclatureService,
  //
  postUserRelationshipService,
} from "./services";

export const postOrganisation = createAsyncThunk(
  "organisations/postOrganisation",
  postOrganisationService
);

export const fetchOrganisations = createAsyncThunk(
  "organisations/fetchOrganisations",
  fetchOrganisationsService
);

export const fetchOrganisation = createAsyncThunk(
  "organisations/fetchOrganisation",
  fetchOrganisationService
);

export const updateOrganisation = createAsyncThunk(
  "organisations/updateOrganisation",
  updateOrganisationService
);

//
export const fetchOrganisationNomenclatures = createAsyncThunk(
  "organisations/fetchOrganisationNomenclatures",
  fetchOrganisationNomenclaturesService
);
export const postOrganisationNomenclature = createAsyncThunk(
  "organisations/postOrganisationNomenclature",
  postOrganisationNomenclatureService
);

export const fetchOrganisationNomenclature = createAsyncThunk(
  "organisations/fetchOrganisationNomenclature",
  fetchOrganisationNomenclatureService
);

export const updateOrganisationNomenclature = createAsyncThunk(
  "organisations/updateOrganisationNomenclature",
  updateOrganisationNomenclatureService
);

//
export const postUserRelationship = createAsyncThunk(
  "organisations/postUserRelationship",
  postUserRelationshipService
);

const organisationsSlice = createSlice({
  name: "organisations",
  initialState: {items: [], selectedOrganisationId: null},
  reducers: {
    setSelectedOrganisationId: (state, action) => {
      state.selectedOrganisationId = action.payload;
    },
  },
  extraReducers: {
    [postOrganisation.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [fetchOrganisations.fulfilled]: (state, action) => {
      state.items = action.payload;
    },
    [fetchOrganisation.fulfilled]: (state, action) => {
      const {organisationId, data} = action.payload;
      state.datasets[organisationId] = data;
    },
    [updateOrganisation.fulfilled]: (state, action) => {
      console.log("[STATE] organisation update", action.payload);
      const {organisationId, organisation} = action.payload;
      const newItems = state.items.filter((item) => item.id !== organisationId);
      newItems.push(organisation);
      state.items = newItems;
    },
    [postUserRelationship.fulfilled]: (state, action) => {
      const {organisationId, data} = action.payload;
      //state.datasets[organisationId] = data;
    },
    // organisations
    [postOrganisationNomenclature.fulfilled]: (state, action) => {
      const {nomenclature, organisationId} = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === organisationId) {
          if (!item.nomenclatures) {
            return {...item, nomenclatures: [nomenclature]};
          } else {
            return {
              ...item,
              nomenclatures: [...item.nomenclatures, nomenclature],
            };
          }
        } else {
          return item;
        }
      });
    },
    [updateOrganisationNomenclature.fulfilled]: (state, action) => {
      const {nomenclature, organisationId} = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === organisationId) {
          if (!item.nomenclatures) {
            return {...item, nomenclatures: [nomenclature]};
          } else {
            const filteredNomenclatures = item.nomenclatures.filter(
              (n) => n.id !== nomenclature.id
            );
            return {
              ...item,
              nomenclatures: [...filteredNomenclatures, nomenclature],
            };
          }
        } else {
          return item;
        }
      });
    },
    [fetchOrganisationNomenclatures.fulfilled]: (state, action) => {
      const {nomenclatures, organisationId} = action.payload;
      state.items = state.items.map((item) => {
        if (item.id === organisationId) {
          return {...item, nomenclatures: nomenclatures};
        } else {
          return item;
        }
      });
    },
  },
});
export const {setSelectedOrganisationId} = organisationsSlice.actions;

export default organisationsSlice.reducer;
