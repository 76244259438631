import {Box, Typography} from "@mui/material";
import ContainerFilesSelector from "Features/files/components/ContainerFilesSelector";
import ButtonDownloadExcelElementTypeGroupGeneric from "Features/elementTypes/components/ButtonDownloadExcelElementTypeGroupGeneric";

import getElementTypesFromFile from "Features/excel/utils/getElementTypesFromFile";

export default function SectionImportElementTypeGroupFromExcel({
  scene,
  onNewTypes,
}) {
  // strings

  const addS =
    "Importez une liste d'éléments / ouvrages depuis un fichier Excel";

  // handlers

  async function handleFilesChange(files) {
    const file = files[0];
    const elementTypes = await getElementTypesFromFile(file);
    onNewTypes(elementTypes);
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      <Box sx={{color: "text.secondary", p: 1}}>
        <Typography variant="body2">{addS}</Typography>
      </Box>
      <Box sx={{color: "text.secondary"}}>
        <ButtonDownloadExcelElementTypeGroupGeneric />
      </Box>
      <Box sx={{flexGrow: 1}}>
        <ContainerFilesSelector
          onFilesChange={handleFilesChange}
          accept=".xlsx"
          multiple={false}
          bgcolor="warning.main"
        />
      </Box>
    </Box>
  );
}
