import hexToARGB from "./hexToARGB";

export default function applyStyleToCell(cell, style) {
  if (!style) return;

  const {color, bg, bold, italic, alignToRight, underline, center} = style;

  // font

  if (color || bold || underline || italic) {
    const font = {};
    //if (color) font.color = {argb: hexToARGB(color)}; "777777"
    if (color) {
      font.color = {argb: hexToARGB(color)};
    }
    if (bold) font.bold = true;
    if (underline) font.underline = true;
    if (italic) font.italic = true;
    cell.font = font;
  }

  // bgcolor

  if (bg) {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: {argb: hexToARGB(bg)},
    };
  }

  // alignment

  const alignment = {vertical: "middle", horizontal: "left", wrapText: false};
  if (alignToRight) alignment.horizontal = "right";
  if (center) alignment.horizontal = "center";
  cell.alignment = alignment;
}
