import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedVersionForMeasurements } from "../versionsSlice";
import useVersionsByScene from "./useVersionsByScene";

export default function useUpdateDefaultVersionForMeasurements(scene) {
  const dispatch = useDispatch();

  const versions = useVersionsByScene(scene);
  const selectedVersionForMeasurements = useSelector(
    (s) => s.versions.selectedVersionForMeasurements
  );
  useEffect(() => {
    if (!selectedVersionForMeasurements && versions?.length) {
      let defaultVersion = versions[versions.length - 1];
      if (scene.defaultVersionId && scene.defaultVersionId !== "all")
        defaultVersion = versions.find((v) => v.id === scene.defaultVersionId);
      dispatch(setSelectedVersionForMeasurements(defaultVersion));
    }
  }, [versions?.length]);
}
