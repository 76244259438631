import useBestViewProps from "./useBestViewProps";
import {useDispatch} from "react-redux";

import {
  updateSceneModuleBestViewMap,
  setOpenSections,
} from "Features/ui/uiSlice";
import {setOpen} from "Features/navigation/navigationSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";
import {setFullscreen} from "Features/ui/uiSlice";
import {setFixedViewersBoxWidth} from "Features/viewer3D/viewer3DSlice";
import useSceneModule from "Features/navigation/useSceneModule";

export default function useDispatchSetBestView(bestView, withSelection) {
  const dispatch = useDispatch();
  const sceneModule = useSceneModule();

  let [viewProps, hash] = useBestViewProps(bestView);

  const dispatchSetBestView = () => {
    const {
      navPanel,
      outlet,
      viewer3D,
      fixedViewersBox,
      callToAction,
      selection,
      fullscreen,
      fixedViewersBoxWidth,
    } = viewProps;
    dispatch(
      setOpenSections({outlet, viewer3D, fixedViewersBox, callToAction})
    );
    dispatch(setOpen(navPanel));
    dispatch(setOpenSelectionPanel(selection));
    dispatch(setFullscreen(fullscreen));
    dispatch(
      updateSceneModuleBestViewMap({
        sceneModule,
        bestView,
        withSelection,
      })
    );
    if (fixedViewersBoxWidth)
      dispatch(setFixedViewersBoxWidth(fixedViewersBoxWidth));
  };

  return dispatchSetBestView;
}
