import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {
  setSubjectElementTypeMap,
  setSubjectTypeVarMap,
  setSubjectGeoCatMap,
} from "../annotatedPdfSlice";
import useRawMeasurementSubjects from "../hooks/useRawMeasurementSubjects";

import {Box} from "@mui/material";

import ListRawMeasurementsBySubject from "./ListRawMeasurementsBySubject";

export default function SectionSubjects({scene}) {
  const dispatch = useDispatch();
  // data

  const subjects = useRawMeasurementSubjects(scene);

  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );
  const subjectTypeVarMap = useSelector(
    (s) => s.annotatedPdf.subjectTypeVarMap
  );
  const subjectGeoCatMap = useSelector((s) => s.annotatedPdf.subjectGeoCatMap);

  // helpers

  // handlers

  function handleSubjectElementTypeChange(subjectElementType) {
    const newMap = {...subjectElementTypeMap};
    newMap[subjectElementType.subject] = subjectElementType.elementType;
    dispatch(setSubjectElementTypeMap(newMap));
  }

  function handleSubjectTypeVarChange(subjectTypeVar) {
    const newMap = {...subjectTypeVarMap};
    newMap[subjectTypeVar.subject] = subjectTypeVar.typeVar;
    dispatch(setSubjectTypeVarMap(newMap));
  }

  function handleSubjectGeoCatChange(subjectGeoCat) {
    const newMap = {...subjectGeoCatMap};
    newMap[subjectGeoCat.subject] = subjectGeoCat.geoCat;
    dispatch(setSubjectGeoCatMap(newMap));
  }

  return (
    <Box sx={{width: 1}}>
      <ListRawMeasurementsBySubject
        items={subjects}
        onSubjectElementTypeChange={handleSubjectElementTypeChange}
        onSubjectTypeVarChange={handleSubjectTypeVarChange}
        onSubjectGeoCatChange={handleSubjectGeoCatChange}
        scene={scene}
      />
    </Box>
  );
}
