import {nanoid} from "nanoid";

export default function serializePoi({
  id,
  type,
  fromItemType, // "ELEMENT_TYPE", "RESSOURCE",...
  description,
  camera,
  sceneContext,
  modelId,
  pageNumber,
  pageRotation,
  pageWidth,
  pageHeight,
  rects,
  savingMode,
  shortcutTypeIds,
}) {
  const typesMap = {
    PDF_ZONE: "PDF_ZONE",
    PDF_TEXT: "PDF_TEXT",
    POV_3D: "POV_3D",
  };

  const poi = {
    id: id ?? nanoid(),
    type: typesMap[type],
    description,
    fromItemType,
  };

  if (savingMode === "full" && type === "PDF_TEXT") {
    poi.pageNumber = pageNumber;
    poi.pageRotation = pageRotation;
    poi.pageWidth = pageWidth;
    poi.pageHeight = pageHeight;
    poi.rects = rects;
  }

  if (savingMode === "full" && type === "POV_3D") {
    poi.camera = camera;
    poi.sceneContext = sceneContext;
  }

  if (shortcutTypeIds) {
    poi.shortcutTypeIds = shortcutTypeIds;
  }

  const isValid =
    Boolean(poi.id) && Boolean(poi.description) && Boolean(poi.type);

  if (isValid) return poi;
}
