import React from "react";

import {ToggleButtonGroup, ToggleButton, Typography} from "@mui/material";
export default function OpenedFilter({opened, onChange}) {
  // strings

  const openedString = "Opened";
  const closedString = "Closed";

  // handlers

  function handleChange(e, v) {
    onChange(v === "opened");
  }
  return (
    <ToggleButtonGroup
      value={opened ? "opened" : "closed"}
      onChange={handleChange}
      exclusive
      size="small"
    >
      <ToggleButton value="opened" size="small" sx={{textTransform: "none"}}>
        <Typography variant="body2">{openedString}</Typography>
      </ToggleButton>
      <ToggleButton value="closed" size="small" sx={{textTransform: "none"}}>
        <Typography variant="body2">{closedString}</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
