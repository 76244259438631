import React from "react";

import {List, ListItem} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import EditableContributionCard from "Features/contributions/components/EditableContributionCard";
export default function ContributionCardList({
  listingId,
  contributions,
  noteId,
}) {
  return (
    <List disablePadding dense>
      {contributions?.map((contribution) => {
        return (
          <ListItem key={contribution?.id} disableGutters>
            <EditableContributionCard
              contribution={contribution}
              initialEditing={false}
              listingId={listingId}
              entityToId={noteId}
              entityToType={"note"}
              closeButton={false}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
