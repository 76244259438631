import { useDispatch } from "react-redux";

import {Box, IconButton, Typography} from "@mui/material";
import { Refresh } from "@mui/icons-material";

import { fetchPrices } from "../pricingSlice";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function SectionTitlePricings({sceneId}) {
  const dispatch = useDispatch();

  // data

  const nameMap = useSceneModuleNameMap();

  // helpers

  const title = nameMap["PRICINGS"];

  // handlers

  const handleRefresh = () => {
    if (sceneId) dispatch(fetchPrices({sceneId}));
  };

  return (
    <Box sx={{p: 1, pl: "16px", pt: "72px", width: 1, display:"flex", alignItems: "center"}}>
      <Typography variant="h6">{title}</Typography>
      <IconButton onClick={handleRefresh}>
        <Refresh fontSize="small" />
      </IconButton>
    </Box>
  );
}