import getItemsMapById from "Utils/getItemsMapById";

export default function getMeasurementsByElementTypeAndElementTypeGroup(
  measurements,
  elementTypes
) {
  const byTypeMap = {}; // {typeId:{modelId:measCount}
  const byTypeGroupMap = {};

  const elementTypesById = getItemsMapById(elementTypes);

  measurements.forEach((measurement) => {
    const measTypeId = measurement.elementTypeId;
    const measModelId = measurement.measurementsModelId;
    const measTypeGroupId = elementTypesById[measTypeId]?.groupId;

    if (measTypeId) {
      // type

      const byType = byTypeMap[measTypeId];
      if (!byType) {
        byTypeMap[measTypeId] = {[measModelId]: 1};
      } else if (!byType[measModelId]) {
        byTypeMap[measTypeId][measModelId] = 1;
      } else {
        byTypeMap[measTypeId][measModelId] += 1;
      }

      // typeGroup

      const byTypeGroup = byTypeGroupMap[measTypeGroupId];
      if (!byTypeGroup) {
        byTypeGroupMap[measTypeGroupId] = {[measModelId]: 1};
      } else if (!byTypeGroup[measModelId]) {
        byTypeGroupMap[measTypeGroupId][measModelId] = 1;
      } else {
        byTypeGroupMap[measTypeGroupId][measModelId] += 1;
      }
    }
  });

  return [byTypeMap, byTypeGroupMap];
}
