import React from "react";

import {Box, Divider} from "@mui/material";

import MenuItemSceneChild from "./MenuItemSceneChild";
import MenuItemSceneParent from "./MenuItemSceneParent";

export default function MenuTreeVersion({
  items,
  tree,
  openedParents,
  onChange,
}) {
  // tree : [{itemId,childrenIds:[]},]

  // handlers

  function handleOpenChange(parentKey) {
    const isOpen = openedParents.includes(parentKey);
    let next;
    if (isOpen) {
      next = openedParents.filter((i) => i !== parentKey);
    } else {
      next = [...openedParents, parentKey];
    }
    onChange(next);
  }
  return (
    <Box>
      {tree.map(({parentKey, childrenKeys, isDivider}, index) => {
        const itemP = items.find((item) => item.itemKey === parentKey);
        const open = openedParents.includes(parentKey);
        if (isDivider || !itemP) {
          return <Divider sx={{my: 2, mx: 2}} key={index} />;
        } else {
          return (
            <Box key={itemP.itemKey}>
              <MenuItemSceneParent
                {...itemP}
                hasChildren={Boolean(childrenKeys)}
                onChange={() => handleOpenChange(parentKey)}
                open={open}
              />
              {childrenKeys && (
                <Box
                  sx={{
                    display: open ? "flex" : "none",
                    flexDirection: "column",
                    bgcolor: "common.white",
                  }}
                >
                  {childrenKeys.map((childKey) => {
                    const itemC = items.find(
                      (item) => item.itemKey === childKey
                    );
                    return <MenuItemSceneChild {...itemC} key={childKey} />;
                  })}
                </Box>
              )}
            </Box>
          );
        }
      })}
    </Box>
  );
}
