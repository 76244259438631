import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedBimappId, setTempBimapp} from "../bimappsSlice";
import useAddSceneBimapp from "../hooks/useAddSceneBimapp";

import {uploadFileService} from "Features/files/services";
import useAccessToken from "Features/auth/useAccessToken";
import {urlToFile} from "Features/files/utils";
// import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";

export default function ActionsNewBimappInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  // strings

  const createS = "Ajouter";

  // data

  const tempBimapp = useSelector((s) => s.bimapps.tempBimapp);
  const addBimapp = useAddSceneBimapp(scene);

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  async function handleCreateClick() {
    if (loading) return;
    setLoading(true);
    const newBimapp = {...tempBimapp};
    const options = {type: "image/png"};
    const blobName = nanoid() + ".png";

    const imageFile = await urlToFile(tempBimapp.ogImageUrl, blobName, options);

    const imageUrl = await uploadFileService({
      accessToken,
      file: imageFile,
      sceneId,
      container: "scene-files",
      blobName,
      blobContentType: "image/png",
    });

    newBimapp.ogImageUrl = imageUrl;
    newBimapp.sceneId = sceneId;

    const bimapp = await addBimapp(newBimapp);

    dispatch(setSelectedBimappId(bimapp.id));
    dispatch(setTempBimapp({}));
    setLoading(false);
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
