import React, {useEffect, useState} from "react";

import {Box, IconButton, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";

import {fetchStoredFile} from "Database/files/services";
import {downloadFileService} from "Features/files/services";
import {saveBlob, getFileSizeString} from "Features/files/utils";

export default function CardDownloadFile({model}) {
  // strings

  const downloadString = "Télécharger";

  // state

  const [file, setFile] = useState();

  // helpers

  const fileSizeString = getFileSizeString(model.fileSize);

  const downloadFileString = `${downloadString} (${fileSizeString})`;

  async function getFile() {
    if (model?.fileClientId) {
      const f = await fetchStoredFile(model.fileClientId);
      setFile(f);
    } else {
      const f = await downloadFileService({
        url: model.fileRemoteUrl,
        fileName: model.name,
      });
      setFile(f);
    }
  }

  // init
  useEffect(() => {
    getFile();
  }, []);

  // handlers

  function handleDownloadClick() {
    saveBlob(file, file.name);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "common.caplaBlack",
        color: "common.white",
        borderRadius: 0.5,
        px: 1,
      }}
    >
      <Typography variant="body2">{downloadFileString}</Typography>
      <IconButton size="small" color="inherit" onClick={handleDownloadClick}>
        <Download fontSize="small" />
      </IconButton>
    </Box>
  );
}
