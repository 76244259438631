import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";

import * as XLSX from "xlsx";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function DialogLoadMarkers({open, onClose, editor3d}) {
  const dispatch = useDispatch();

  // strings

  const title = "Import markers";

  const description = "Select the list of markers to update";
  const markersListS = "List of markers";

  const description2 = "Select an ifc reference";
  const ifcS = "IFC model";

  const selectFileS = "Select a file";
  const markersS = "markers";

  const cancelS = "Cancel";
  const saveS = "Save";

  // data

  const models = useSelector((s) => s.viewer3D.models);

  // states

  const [markers, setMarkers] = useState([]);
  const [marker0, setMarker0] = useState();
  const [target, setTarget] = useState(null);
  const [ifc, setIfc] = useState(null);
  const [coords, setCoords] = useState(null);

  // helpers

  const options = models.filter((m) => m.type === "MARKERS" && m.enabled);
  const options2 = models.filter((m) => m.type === "IFC");

  // helpers - markers count

  const count = markers.length;

  // helpers - coords

  let coordsS = "x:...; y:...; z:...";
  if (coords?.x) {
    coordsS = `x:${coords.x.toFixed(3)} ; y:${coords.y.toFixed(
      3
    )} ; z:${coords.z.toFixed(3)}`;
  }

  // helper - marker 0

  let marker0S = "no marker";
  if (marker0)
    marker0S = `Ex:  ${marker0.description}, [${marker0.x.toFixed(
      3
    )}; ${marker0.y.toFixed(3)}; ${marker0.z.toFixed(3)}]`;
  // handlers - markers list selection

  function handleTargetChange(target) {
    setTarget(target);
  }

  // handlers - ifc selection

  async function handleIfcChange(ifc) {
    setIfc(ifc);
    const entityIfc = editor3d?.getEntity(ifc.id);
    const siteCoordinates = await entityIfc.siteCoordinates();
    setCoords(siteCoordinates);
  }

  // handlers - load file

  function parseString(string, asNum) {
    if (!asNum) return string;
    const _num = string.replace(",", ".");
    const num = parseFloat(_num);
    const numString = num.toString();
    if (isNaN(num) || numString !== _num) {
      return string;
    } else {
      return num;
    }
  }

  function getField(value, asNum) {
    if (!value) return [];
    let field = parseString(value.toString(), asNum);
    return [field];
  }

  async function handleFileChange(file) {
    const url = URL.createObjectURL(file);
    const res = await fetch(url);
    const arrayBuffer = await res.arrayBuffer();

    let wb = XLSX.read(arrayBuffer);
    let ws = wb.Sheets[wb.SheetNames[0]];
    let aoa = XLSX.utils.sheet_to_json(ws, {header: 1});

    const markers = [];

    aoa.forEach((row, index) => {
      if (index === 0) return;

      const data = {};

      const fields = ["description", "x", "y", "z"];

      fields.forEach((field, index) => {
        const asNum = ["x", "y", "z"].includes(field);
        const [value] = getField(row[index], asNum);
        data[field] = value;
      });

      const marker = {
        id: nanoid(),
        description: data.description,
        x: data.x,
        y: data.y,
        z: data.z,
        type: "REFERENCE",
      };

      if (data.description) markers.push(marker);
    });
    setMarkers(markers);
  }

  // effect

  useEffect(() => {
    let m0 = markers[0];

    if (coords?.x && m0) {
      m0 = {...m0, x: m0.x - coords.x, y: m0.y - coords.y, z: m0.z - coords.z};
    }
    if (m0) {
      const marker0 = {...m0, x: m0.x, y: m0.z, z: -m0.y};
      setMarker0(marker0);
    }
  }, [coords?.x, markers.length]);

  // handlers

  async function handleSave() {
    try {
      const entityMarkers = editor3d?.getEntity(target.id);

      let _markers = [...markers];

      if (ifc) {
        const entityIfc = editor3d?.getEntity(ifc.id);
        const siteCoordinates = await entityIfc.siteCoordinates();
        const {x, y, z} = siteCoordinates;
        _markers = _markers.map((m) => ({
          ...m,
          x: m.x - x,
          y: m.y - y,
          z: m.z - z,
        }));
      }

      // add markers in state

      const newMarkers = _markers.map((m) => ({...m, x: m.x, y: m.z, z: -m.y}));
      const oldItems = target.elements.items;
      const updatedModel = {
        ...target,
        elements: {...target.elements, items: newMarkers},
      };
      dispatch(updateModel({updatedModel}));

      // add markers in 3D
      newMarkers.forEach((marker) => entityMarkers.addMarker(marker));

      // close

      onClose();
    } catch (e) {
      console.log("error", e);
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Box sx={{mt: 1}}>
          <Typography variant="body2" gutterBottom>
            {description}
          </Typography>
          <SelectorAutocomplete
            value={target}
            options={options}
            onChange={handleTargetChange}
            name={markersListS}
          />

          <Typography variant="body2" gutterBottom sx={{mt: 2}}>
            {description2}
          </Typography>
          <SelectorAutocomplete
            value={ifc}
            options={options2}
            onChange={handleIfcChange}
            name={ifcS}
          />
          <Typography variant="caption">{coordsS}</Typography>

          <Box
            sx={{
              width: 1,
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box sx={{display: "flex", alignItems: "baseline"}}>
              <Typography variant="h6">{count}</Typography>
              <Typography variant="body2" sx={{ml: 1}}>
                {markersS}
              </Typography>
            </Box>
            <Typography variant="caption" sx={{mb: 2}}>
              {marker0S}
            </Typography>
            <FileSelectorButton
              onFileChange={handleFileChange}
              buttonVariant="contained"
              buttonName={selectFileS}
              accept=".xlsx,.xls"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelS}</Button>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
