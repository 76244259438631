import React from "react";

import {Box, Grid, Typography} from "@mui/material";

import FieldTextBasic from "Features/kvform/components/FieldTextBasic";

export default function FormTempInputs({tempInputs, onChange}) {
  // strings

  const label = "Nom d'usage";

  // handlers

  function handleInputNameChange(input, name) {
    const newInputs = tempInputs.map((i) => {
      if (i.fileName === input.fileName) {
        return {...i, name};
      } else {
        return i;
      }
    });
    onChange(newInputs);
  }
  return (
    <Box sx={{p: 1, width: 1}}>
      {tempInputs
        .sort((a, b) => a.fileName.localeCompare(b.fileName))
        .map((input) => {
          return (
            <Grid container sx={{width: 1, mb: 1}} key={input.fileName}>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  pr: 2,
                }}
              >
                <Typography variant="body2" noWrap color="text.secondary">
                  {input.fileName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FieldTextBasic
                  label={label}
                  value={input?.name ? input.name : ""}
                  onChange={(newValue) =>
                    handleInputNameChange(input, newValue)
                  }
                />
              </Grid>
            </Grid>
          );
        })}
    </Box>
  );
}
