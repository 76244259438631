import { useDispatch, useSelector } from "react-redux";

import { Typography, FormControlLabel, Checkbox, Box } from "@mui/material";

import { setFilterNoVoids } from "Features/measurements/measurementsSlice";

export default function SectionFiltersNoVoidsInFilterPanel({caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const noVoidsS = "Vide pour Plein";

  // data

  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const enabledPackageIdsInMainScope = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );

  // handler

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setFilterNoVoids(checked));
    for (const modelId of enabledPackageIdsInMainScope) {
      const entity = caplaEditor?.editor3d.getEntity(modelId);
      if (checked) entity?.fillVoids();
      else entity?.substractVoids();
    }
  }

  return (
    <Box sx={{ py: 2 }}>
      <Box sx={{ px: 1, bgcolor: "background.default" }}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={filterNoVoids}
              onChange={handleChange}
            />
          }
          label={<Typography sx={{ fontSize: 13 }}>{noVoidsS}</Typography>}
        />
      </Box>
    </Box>
  );
}
