import {PointLight, SpotLight} from "three";
import {getRandomPower} from "../utils/miscUtils";

class Flash {
  constructor() {
    this.light = new SpotLight(0xf52585, 2);
    this.light.position.set(0, 10, 20);
    this.light.penumbra = 1.1;
    this.light.layers.enable(1);

    this.flash = new PointLight(0x062d89, 30, 500, 1.7);
    this.flash.position.set(200, 300, 100);
    this.flash.layers.enable(1);

    this.flash2 = new PointLight(0xf52585, 30, 500, 1.7); //pink
    this.flash2.position.set(20, 40, 10);
    this.flash2.layers.enable(1);

    this.flash3 = new PointLight(0x9901ff, 30, 500, 1.7); //purple
    this.flash3.position.set(20, 40, 10);
    this.flash3.layers.enable(1);
  }

  loadTo(scene) {
    //scene.add(this.flash, this.light);
  }
  enable() {
    this.light.layers.enable(1);
    this.flash.layers.enable(1);
    this.flash2.layers.enable(1);
    this.flash3.layers.enable(1);
  }
  disable() {
    this.light.layers.disable(1);
    this.flash.layers.disable(1);
    this.flash2.layers.disable(1);
    this.flash3.layers.disable(1);
  }
  randomUpdate() {
    this.flash.power = getRandomPower(this.flash.power);
    this.flash2.power = getRandomPower(this.flash2.power);
    this.flash3.power = getRandomPower(this.flash3.power);
    if (this.flash.power < 100)
      this.flash.position.set(
        Math.random() * 400,
        300 + Math.random() * 200,
        100
      );
    if (this.flash2.power < 100)
      this.flash2.position.set(Math.random() * 40, 30 + Math.random() * 20, 10);
    if (this.flash3.power < 100)
      this.flash3.position.set(Math.random() * 40, 30 + Math.random() * 20, 10);
  }
}
export default Flash;
