import {
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Checkbox,
} from "@mui/material";

export default function FieldTempZoneD3({d3, onChange}) {
  // string

  const notD3S = "3D activé";

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    onChange(!checked);
  }
  return (
    <Box sx={{p: 1, pl: 3}}>
      <FormControlLabel
        control={
          <Checkbox checked={!d3} onChange={handleChange} size="small" />
        }
        label={
          <Typography
            variant="body2"
            sx={{color: !d3 ? "primary.main" : "text.secondary"}}
          >
            {notD3S}
          </Typography>
        }
      />
    </Box>
  );
}
