import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ButtonDeleteMarker from "./ButtonDeleteMarker";
import ButtonRenameMarker from "./ButtonRenameMarker";
// import ToggleMarkersViewMode from "./ToggleMarkersViewMode";
import useMarkersModels from "../hooks/useMarkersModels";
import ButtonLoadMarkers from "./ButtonLoadMarkers";
import ButtonDownloadExcel from "./ButtonDownloadExcel";
// import useMarkersByScene from "../hooks/useMarkersByScene";

import useSceneModule from "Features/navigation/useSceneModule";

export default function ToolbarMarkersMain({scene, editor3d, viewer}) {
  // data

  const selectedMarker = useSelector((s) => s.markers.selectedMarker);
  const markersModels = useMarkersModels(scene?.clientId);
  const sceneModule = useSceneModule();
  // const allMarkers = useMarkersByScene(scene?.clientId);
  // const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);

  // helpers

  const noneSelection = !selectedMarker?.markerId;

  // helpers - selection

  const {markersModelId, markerId} = selectedMarker;
  const markersModel = markersModels.find((m) => m.id === markersModelId);

  //helpers - canEdit

  const canEdit = sceneModule !== "HOME" && !viewer;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        {canEdit && (
          <Box sx={{display: "flex", alignItems: "center", "&>*": {mr: 1}}}>
            <ButtonLoadMarkers editor3d={editor3d} />
          </Box>
        )}
        <ButtonDownloadExcel viewer={viewer} scene={scene} />

        {!noneSelection && (
          <Box sx={{display: "flex", alignItems: "center", "&>*": {mr: 1}}}>
            {canEdit && (
              <ButtonRenameMarker
                markerId={markerId}
                markersModel={markersModel}
                editor3d={editor3d}
              />
            )}
            {canEdit && (
              <ButtonDeleteMarker
                markerId={markerId}
                markersModel={markersModel}
                editor3d={editor3d}
              />
            )}
          </Box>
        )}
      </Box>
      {/* <Box>{canEdit && <ToggleMarkersViewMode />}</Box> */}
    </Box>
  );
}
