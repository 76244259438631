import React from "react";
import * as XLSX from "xlsx";

import {Button, Box} from "@mui/material";
import {Download} from "@mui/icons-material";

import {saveBlob} from "Features/files/utils";
export default function ButtonExportExcel({dataset}) {
  const excelString = "EXCEL";

  // handlers

  function handleDownloadClick() {
    const rows = Array.isArray(dataset) ? dataset : [];
    const json = JSON.stringify(rows);
    const ws = XLSX.utils.json_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    const wopts = {bookType: "xlsx", bookSST: false, type: "array"};
    const wbout = XLSX.write(wb, wopts);
    const blob = new Blob([wbout], {type: "application/octet-stream"});
    saveBlob(blob, "excel.xlsx");
  }
  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Button
        startIcon={<Download />}
        onClick={handleDownloadClick}
        size="small"
      >
        {excelString}
      </Button>
    </Box>
  );
}
