import { useSelector } from "react-redux";

import { Box, Typography, IconButton } from "@mui/material";
import { Stop, Close } from "@mui/icons-material";

import ListItemAddVoid from "./ListItemAddVoid";
import SectionAddVoidsInViewer from "./SectionAddVoidsInViewer";
import SectionVoidHosts from "./SectionVoidHosts";

import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function FieldItemVoids({
  scene,
  item,
  value,
  onChange,
  locked,
  caplaEditor,
}) {
  // strings

  const voidsS = "Vides";
  const allS = "Toutes";
  const noVoidsS = "Aucun vide dans cet élément";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const allMeasurements = useSceneMeasurements(scene);
  const types = useElementTypesBySceneProxy(scene);

  // helpers

  const measurementsModelId = item?.measurementsModelId;
  const measurements = allMeasurements.filter(
    (m) => m.measurementsModelId === measurementsModelId
  );

  // helper - void
  if (!item) console.log("ERROR VOIDS no item");
  console.log(
    "DEBUG VOIDS",
    measurements.filter((m) => !m)
  );
  const voids = measurements.filter(
    (m) =>
      m?.elementTypeId !== item?.elementTypeId &&
      ["SEGMENT", "POLYGON", "RECTANGLE"].includes(m.drawingShape)
  );

  // helpers - itemVoids
  const itemVoids = value
    ? value.map((id) => {
        const m = measurements.find((m) => m.id === id);
        const t = types.find((t) => t.id === m?.elementTypeId);
        if (m) {
          return { ...m, name2: `${t?.name} • ${m.codeName}` };
        } else {
          return { id, name2: "-?-" };
        }
      })
    : [];

  // handlers

  function handleAddVoid(voidId) {
    const prevVoids = item.voids ? item.voids : [];
    const exists = Boolean(prevVoids.find((id) => id === voidId));
    let newVoids = [...prevVoids];
    if (!exists) newVoids.push(voidId);
    caplaEditor?.editor3d.sceneEditor.updateMeasurement3D({
      ...item,
      voids: newVoids,
    });
    onChange(newVoids);
  }

  function handleRemoveVoid(voidId) {
    const prevVoids = item.voids ? item.voids : [];
    const newVoids = prevVoids.filter((id) => id !== voidId);
    caplaEditor?.editor3d.sceneEditor.updateMeasurement3D({
      ...item,
      voids: newVoids,
    });
    onChange(newVoids);
  }

  return (
    <Box>
      <Box sx={{ width: 1, py: 0.5, px: 1 }}>
        <Typography sx={{ fontSize: 13 }}>{voidsS}</Typography>
      </Box>
      {itemVoids.length === 0 && (
        <Box sx={{ width: 1, py: 0.5, px: 1, bgcolor: "background.default" }}>
          <Typography sx={{ fontSize: 13 }}>{noVoidsS}</Typography>
        </Box>
      )}
      {!locked && itemVoids.length > 0 && (
        <Box>
          {itemVoids.map((v) => (
            <Box
              key={v.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 0.5,
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", color: v.color }}
              >
                <Stop color="inherit" fontSize="small" />
                <Typography variant="body2" color="text.primary" sx={{ ml: 1 }}>
                  {v.name2}
                </Typography>
              </Box>
              <IconButton
                size="small"
                sx={{ height: 24 }}
                onClick={() => handleRemoveVoid(v.id)}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
      {locked && itemVoids.length > 0 && (
        <Box>
          {itemVoids
            .sort((a, b) => a.name2.localeCompare(b.name2))
            .map((v) => (
              <Box
                key={v.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 0.25,
                  pl: 1,
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", color: v.color }}
                >
                  <Stop color="inherit" fontSize="small" />
                  <Typography
                    sx={{ mx: 1 }}
                    variant="body2"
                    color="text.primary"
                  >
                    {v.name2}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      )}
      {!locked && os.fixedViewersBox && (
        <SectionAddVoidsInViewer
          scene={scene}
          item={item}
          caplaEditor={caplaEditor}
          onAdd={handleAddVoid}
          onRemove={handleRemoveVoid}
        />
      )}
      {!locked && (
        <Box sx={{ width: 1, pt: 0.5, px: 1 }}>
          <Typography sx={{ fontSize: 13 }}>
            {`${allS} (${voids.length})`}
          </Typography>
        </Box>
      )}
      {!locked && (
        <ListItemAddVoid voids={voids} onAdd={handleAddVoid} types={types} />
      )}

      <Box sx={{ width: 1, flex: 1 }}>
        <SectionVoidHosts
          measurement={item}
          measurements={allMeasurements}
          scene={scene}
          locked={locked}
          caplaEditor={caplaEditor}
        />
      </Box>
    </Box>
  );
}
