import React from "react";

import {Typography, Box} from "@mui/material";

export default function SectionHeader() {
  // strings

  const title = "Sujet <=> Type";

  const description = `Les annotations du PDF sont regroupées en sujet.
    Pour importer une annotation dans le calque de repérage, associez son sujet à un type d'élément.`;
  return (
    <Box sx={{maxWidth: 400, p: 2}}>
      <Typography variant="h6" sx={{pl: "68px", mb: 2}}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
}
