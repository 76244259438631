import React from "react";

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";
import {nanoid} from "@reduxjs/toolkit";

import {Box, IconButton, Tooltip} from "@mui/material";
import {Refresh} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";

import bimbox from "Assets/bimbox.svg";

export default function ContainerSceneImageThumbnail({imageUrl}) {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!imageUrl ? (
        <img src={bimbox} height="100%" />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            position: "relative",
            backgroundImage: `url(${imageUrl})`,
            //border: (theme) => `1px solid ${theme.palette.common.white}`,
            //borderRadius: (theme) => theme.shape.borderRadius,
          }}
        />
      )}
    </Box>
  );
}
