import {
  Box,
  LinearProgress,
  Typography,
  Icon,
  Skeleton,
  IconButton,
} from "@mui/material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import {ExpandMore as More, ExpandLess as Less} from "@mui/icons-material";

const typeSceneModuleMap = {
  MEASUREMENTS: "PACKAGES",
  IMAGE: "ZONES",
  PDF: "INPUTS",
  ELEMENT_TYPE_GROUPS: "ELEMENT_TYPES",
  RESSOURCES: "RESSOURCES",
  MARKERS: "MARKERS",
};

const nameMap = {
  MEASUREMENTS: "calques",
  IMAGE: "fonds de plan",
  PDF: "fichiers PDF",
  ELEMENT_TYPE_GROUPS: "types d'éléments",
  RESSOURCES: "articles",
  MARKERS: "liste de repères",
};

export default function ContainerLoadingEntity({
  type, // BIMBOX,MEASUREMENTS, ELEMENT_TYPE_GROUPS, ...
  ratio = 0, // [0-100]
  totalCount = 0, // total count of ressources
  state = "START",
  expand,
  onExpandChange,
}) {
  // data
  const iconMap = useSceneModuleIconMap();

  // helpers - label

  let label = `${totalCount} ${nameMap[type]}`;
  if (state === "START") label = "";
  if (type === "BIMBOX") label = "Chargement ...";

  // helpers - color

  const bgcolorMap = {
    START: lighten(theme.palette.common.caplaBlack, 0),
    WIP: lighten(theme.palette.common.caplaBlack, 0.1),
    END: lighten(theme.palette.common.caplaBlack, 0.2),
  };

  const bgcolor = bgcolorMap[state];

  // helpers - icons

  const icon = iconMap[typeSceneModuleMap[type]];

  // handlers

  function handleExpandClick() {
    if (onExpandChange) onExpandChange(!expand);
  }

  return (
    <>
      {state === "START" ? (
        <Box sx={{p: 1, display: "flex"}}>
          <Skeleton
            variant="rectangular"
            width={30}
            height={30}
            sx={{mr: 2, bgcolor: "grey.800"}}
          />
          <Skeleton
            variant="rectangular"
            width={240}
            height={30}
            sx={{bgcolor: "grey.700"}}
          />
        </Box>
      ) : (
        <Box
          sx={{
            color: "common.white",
            display: "flex",
            alignItems: "center",
            width: 1,
            mb: type === "BIMBOX" ? 1.5 : 0,
          }}
        >
          <Box
            sx={{
              bgcolor,
              color: "common.white",
              p: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: "4px",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{fontSize: 13, color: "grey.500"}}>
                {label}
              </Typography>
            </Box>
            {state !== "START" && (
              <Box sx={{width: 50}}>
                <LinearProgress
                  color="inherit"
                  sx={{width: 1}}
                  variant="determinate"
                  value={ratio}
                />
              </Box>
            )}
          </Box>
          {type !== "BIMBOX" && (
            <Box
              sx={{
                p: 1,
                ml: 1,
                visibility: state === "START" ? "hidden" : "visible",
              }}
            >
              <Icon>{icon}</Icon>
            </Box>
          )}
          {type === "BIMBOX" && (
            <Box
              sx={{
                p: 1,
                ml: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                size="small"
                color="inherit"
                onClick={handleExpandClick}
                sx={{color: "grey.700"}}
              >
                {expand ? <Less fontSize="small" /> : <More fontSize="small" />}
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
