import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

export default function DialogAddRelationsRessources({
  open,
  groups,
  onCancel,
  onConfirm,
  initialGroup,
}) {
  // strings

  const titleS = "Ajout de relations";
  const addS = "Ajouter";
  const help1S = "Sélectionner le groupe à utiliser comme modèle pour ajouter des relations";
  const help2S = "Les ajouts se feront sur la base du num de chaque article";

  // state

  const [group, setGroup] = useState(null);

  // handlers

  const handleChange = (g) => {
    setGroup(g);
  };

  // effect

  useEffect(() => {
    if (!group && groups && groups.length > 0)
      setGroup(groups.find((g) => g.id !== initialGroup));
  }, [groups, group])

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent sx={{maxWidth: 300}}>
        <Typography variant="body2" sx={{pb: 2}}>
          {help1S}
        </Typography>
        <SelectorAutocomplete
          value={group}
          options={groups}
          onChange={handleChange}
        />
        <Typography variant="body2" sx={{pt: 2}}>
          {help2S}
        </Typography>
      </DialogContent>
      <DialogActions>
      <Button onClick={() => onConfirm(group)}>{addS}</Button>
      </DialogActions>
    </Dialog>
  );
}