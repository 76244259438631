function getBrotherNum(num) {
  const numArray = num.split(".");
  const suffix = numArray.pop();
  const nextSuffix = (Number(suffix) + 1).toString();
  return [...numArray, nextSuffix].join(".");
}

export default function getNextNumFromRankDiff(num, rankDiff) {
  let nextNum;
  if (rankDiff === 0) {
    nextNum = getBrotherNum(num);
  } else if (rankDiff >= 1) {
    nextNum = num;
    for (let i = 0; i < rankDiff; i++) {
      nextNum += ".1";
    }
  } else if (rankDiff < 0) {
    const numArray = num.split(".");
    const parentNumArray = numArray.slice(0, rankDiff);
    const parentNum = parentNumArray.join(".");
    nextNum = getBrotherNum(parentNum);
  }

  return nextNum;
}
