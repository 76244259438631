import {
  Plane,
  Object3D,
  PlaneGeometry,
  MeshBasicMaterial,
  DoubleSide,
  Mesh,
} from "three";
import {TransformControls} from "three/examples/jsm/controls/TransformControls.js";

export default class CaplaPlane {
  static planeMaterial = new MeshBasicMaterial({
    color: 0xffff00,
    side: DoubleSide,
    transparent: true,
    opacity: 0.2,
  });

  isShowed;

  constructor({
    origin,
    normal,
    editor,
    planeSize,
    id,
    sceneClientId,
    horizontal,
  }) {
    this.id = id;
    this.sceneClientId = sceneClientId;
    this.normal = normal;
    this.origin = origin;
    this.editor = editor;
    this.planeSize = planeSize;
    this.horizontal = horizontal; // if true, the plane used to cut at Height + 1m

    this.plane = new Plane();
    this.plane.setFromNormalAndCoplanarPoint(normal, origin);

    this.object = this.getObject();

    this.helper = this.createHelper();

    this.controls = this.newTransformControls();

    this.hide();
  }

  parse() {
    return {
      id: this.id,
      origin: {
        x: this.origin.x,
        y: this.origin.y,
        z: this.origin.z,
      },
      normal: {
        x: this.normal.x,
        y: this.normal.y,
        z: this.normal.z,
      },
    };
  }
  show() {
    this.helper.visible = true;
    this.editor.scene.add(this.controls);
    this.isShowed = true;
  }

  hide() {
    this.helper.visible = false;
    this.editor.scene.remove(this.controls);
    this.isShowed = false;
  }

  remove() {
    this.editor.scene.remove(this.controls);
    this.editor.scene.remove(this.helper);
  }

  newTransformControls() {
    const camera = this.editor.cameras?.camera;
    if (camera) {
      const container = this.editor.getDomElement();
      const controls = new TransformControls(camera, container);
      this.initializeControls(controls);
      const scene = this.editor.scene;
      scene.add(controls);

      controls.addEventListener("mouseDown", () =>
        this.editor.controls.disable()
      );
      controls.addEventListener("mouseUp", () => this.editor.controls.enable());
      return controls;
    }
  }

  initializeControls(controls) {
    controls.attach(this.helper);
    controls.showX = false;
    controls.showY = false;
    controls.setSpace("local");
    controls.traverse((node) => node.layers.enable(1));
    //controls.getRaycaster().layers.enable(1);
    //this.createArrowBoundingBox();
    //controls.children[0].children[0].add(this.arrowBoundingBox);
    controls.addEventListener("change", () => {
      this.plane.setFromNormalAndCoplanarPoint(
        this.normal,
        this.helper.position
      );
      this.origin = this.helper.position;
    });
  }

  getObject() {
    const planeGeometry = new PlaneGeometry(this.planeSize, this.planeSize, 1);
    const object = new Mesh(planeGeometry, CaplaPlane.planeMaterial);
    object.layers.enable(1);
    return object;
  }
  createHelper() {
    const helper = new Object3D();
    helper.lookAt(this.normal);
    helper.position.copy(this.origin);
    helper.layers.enable(1);
    const scene = this.editor.scene;
    scene.add(helper);
    helper.add(this.object);
    return helper;
  }
}
