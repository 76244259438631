import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Box} from "@mui/material";

import DeleteDialog from "Features/ui/components/DeleteDialog";

import useSelectedRessourcesGroup from "../hooks/useSelectedRessourcesGroup";
import useDeleteSceneRessourcesGroup from "../hooks/useDeleteSceneRessourcesGroup";

export default function DialogDeleteRessourcesGroup({scene, open, onClose}) {
  // state

  const [loading, setLoading] = useState(false);

  // data

  const selectedGroup = useSelectedRessourcesGroup(scene);
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const deleteSceneRessourcesGroup = useDeleteSceneRessourcesGroup();

  // handler

  function handleClose() {
    onClose();
    setLoading(false);
  }

  async function handleDeleteConfirm() {
    if (loading) return;
    setLoading(true);
    await deleteSceneRessourcesGroup(scene, selectedGroup, relations);
    setLoading(false);
    onClose();
  }

  return (
    <DeleteDialog
      open={open}
      onCancel={handleClose}
      onConfirm={handleDeleteConfirm}
      ressource="ressourcesGroup"
      loading={loading}
    />
  );
}
