import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";
import {SelectAll} from "@mui/icons-material";

import {setSelectedShortcutRight} from "../shortcutSlice";

import ButtonTool from "Features/viewer3D/components/ToolShortcuts/components/ButtonTool";
import SectionLeftSelectionInViewer from "Features/leftPanel/components/SectionLeftSelectionInViewer";
import useIsFullscreen from "Features/ui/useIsFullscreen";

export default function ShortcutRight({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const selectionS = "Sélection";
  const selectionC = "SELECTION";

  // data

  const shortcutRight = useSelector((s) => s.shortcut.selectedShortcutRight);
  const rendererSize = useSelector((s) => s.viewer3D.rendererSize);
  const fullscreen = useIsFullscreen();

  // helper - viewer size

  const maxHeight = rendererSize.height - 100;

  function handleButtonClick(code) {
    const wasSelected = code === shortcutRight;
    dispatch(setSelectedShortcutRight(wasSelected ? null : code));
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: (theme) => (viewer || fullscreen ? theme.spacing(1) : "68px"),
        right: (theme) => (viewer ? theme.spacing(1) : "48px"),
        zIndex: 30,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          "&>*:not(:last-child)": {mr: 1},
        }}
      >
        <ButtonTool
          icon={<SelectAll />}
          label={selectionS}
          key={selectionC}
          selected={shortcutRight === selectionC}
          onClick={() => handleButtonClick(selectionC)}
          count={1}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          maxHeight: `${maxHeight}px`,
          overflowY: "auto",
          width: 1,
          mr: "-50px",
          pr: "50px",
          pb: 2,
          pl: 1,
          boxSizing: "content-box",
        }}
      >
        <Box
          sx={{
            display: shortcutRight === "SELECTION" ? "flex" : "none",
            p: 1,
            width: 1,
            boxSizing: "border-box",
          }}
        >
          <SectionLeftSelectionInViewer
            caplaEditor={caplaEditor}
            scene={scene}
            viewer={viewer}
          />
        </Box>
      </Box>
    </Box>
  );
}
