import {createSlice} from "@reduxjs/toolkit";

export const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    expandOptions: false,
    openFilterPanel: false,
    selectedFilterTab: "SECTORS",
    filtersByTab: {ELEMENT_TYPES: {ids: null, exclude: false}},
    multipleSelectionTabMap: {ELEMENT_TYPES: false},
    selectedElementTypeGroupId: null,
  },
  reducers: {
    setExpandOptions: (state, action) => {
      state.expandOptions = action.payload;
    },
    setOpenFilterPanel: (state, action) => {
      state.openFilterPanel = action.payload;
    },
    setSelectedFilterTab: (state, action) => {
      state.selectedFilterTab = action.payload;
    },
    setFilterIdsByTab: (state, action) => {
      const {ids, tab} = action.payload;
      state.filtersByTab[tab] = {...state.filtersByTab[tab], ids};
    },
    resetFilterIdsByTab: (state, action) => {
      const tab = action.payload;
      state.filtersByTab[tab] = {...state.filtersByTab[tab], ids: null};
    },
    resetAllFilters: (state, action) => {
      state.filtersByTab = {};
    },
    setFilterExcludeByTab: (state, action) => {
      const {exclude, tab} = action.payload;
      state.filtersByTab[tab] = {...state.filtersByTab[tab], exclude};
    },
    setTabMultipleSelection: (state, action) => {
      const {tab, multipleSelection} = action.payload;
      state.multipleSelectionTabMap[tab] = multipleSelection;
    },
    setSelectedElementTypeGroupId: (state, action) => {
      state.selectedElementTypeGroupId = action.payload;
    },
    setFilterGroupIdsByTab: (state, action) => {
      const {tab, groupIds} = action.payload;
      state.filtersByTab[tab] = {...state.filtersByTab[tab], groupIds};
    },
  },
});

export const {
  setExpandOptions,
  setOpenFilterPanel,
  setSelectedFilterTab,
  resetFilterIdsByTab,
  setFilterIdsByTab,
  setFilterExcludeByTab,
  setTabMultipleSelection,
  setSelectedElementTypeGroupId,
  setFilterGroupIdsByTab,
  resetAllFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
