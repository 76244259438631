import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

const properValue = (excelValue) => {
  if (excelValue && typeof excelValue === "object") {
    if (excelValue.result) return excelValue.result;
    if (excelValue.hyperlink) return excelValue.text;
    if (excelValue.richText)
      return excelValue.richText.map((t) => t.text).join("");
    return null; // sharedFormula without result for instance
  }
  // typeof date ?
  return excelValue;
};

export default async function getRowsFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];
  const rowCount = worksheet.rowCount;

  let valuesA = [];

  const firstValue = properValue(worksheet.getRow(1).getCell(1).value);

  if (typeof firstValue === "string" && firstValue.toLowerCase() === "start") {
    const start = properValue(worksheet.getRow(1).getCell(2).value);
    const end = properValue(worksheet.getRow(2).getCell(2).value);

    const rows = worksheet.getRows(start, end - start).values();

    for (let row of rows) {
      const rowValues = [];
      for (let i = 1; i <= row.values.length; i++) {
        rowValues.push(properValue(row.getCell(i).value));
      }
      valuesA.push(rowValues);
    }
  }

  return valuesA;
}
