import {useDispatch} from "react-redux";

import {setDim1, setDim2, setHeight} from "../elementTyporSlice";
import {Box, Typography, TextField} from "@mui/material";
import useTempElementType from "../hooks/useTempElementType";

export default function SectionStepDimensions({scene}) {
  const dispatch = useDispatch();

  // strings

  const dim1S = "Dim 1 (m)";
  const dim2S = "Dim 2 (m)";
  const heightS = "Hauteur (m)";

  // data

  const tempElementType = useTempElementType();

  const dim1 = tempElementType.dim1 ?? null;
  const dim2 = tempElementType.dim2 ?? null;
  const height = tempElementType.height ?? null;

  // handlers

  function handleDim1Change(e) {
    dispatch(setDim1(e.target.value));
  }
  function handleDim2Change(e) {
    dispatch(setDim2(e.target.value));
  }
  function handleHeightChange(e) {
    dispatch(setHeight(e.target.value));
  }
  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        width: 1,
        alignItems: "center",
        "&>*": {
          mb: 4,
        },
      }}
    >
      <Box>
        <TextField
          size="small"
          label={dim1S}
          value={dim1}
          onChange={handleDim1Change}
        />
      </Box>
      <Box>
        <TextField
          size="small"
          label={dim2S}
          value={dim2}
          onChange={handleDim2Change}
        />
      </Box>
      <Box>
        <TextField
          size="small"
          label={heightS}
          value={height}
          onChange={handleHeightChange}
        />
      </Box>
    </Box>
  );
}
