import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createSharedboxService,
  fetchSharedboxesService,
  updateSharedboxService,
  deleteSharedboxService,
} from "./services";

export const createSharedbox = createAsyncThunk(
  "sharedboxes/createSharedbox",
  createSharedboxService
);
export const updateSharedbox = createAsyncThunk(
  "sharedboxes/updateSharedbox",
  updateSharedboxService
);
export const deleteSharedbox = createAsyncThunk(
  "sharedboxes/deleteSharedbox",
  deleteSharedboxService
);

export const fetchSharedboxes = createAsyncThunk(
  "sharedboxes/fetchSharedboxes",
  fetchSharedboxesService
);

export const sharedboxesSlice = createSlice({
  name: "sharedboxes",
  initialState: {
    items: [],
    isEditingSharedbox: false,
    selectedSharedbox: null,
    editedSharedbox: {},
    tempSharedbox: {},
    selectedSharedboxFormGroup: "IDENTIFICATION",
  },
  reducers: {
    setSelectedSharedbox: (state, action) => {
      state.selectedSharedbox = action.payload;
    },
    setIsEditingSharedbox: (state, action) => {
      state.isEditingSharedbox = action.payload;
    },
    setEditedSharedbox: (state, action) => {
      state.editedSharedbox = action.payload;
    },
    setTempSharedbox: (state, action) => {
      state.tempSharedbox = action.payload;
    },
    setSelectedSharedboxFormGroup: (state, action) => {
      state.selectedSharedboxFormGroup = action.payload;
    },
  },
  extraReducers: {
    [createSharedbox.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = [...state.items, item];
    },
    [updateSharedbox.fulfilled]: (state, action) => {
      const {item} = action.payload;
      const newItems = state.items.filter((i) => i.id !== item.id);
      state.items = [...newItems, item];
    },
    [deleteSharedbox.fulfilled]: (state, action) => {
      const sharedboxId = action.payload;
      const newItems = state.items.filter((i) => i.id !== sharedboxId);
      state.items = [...newItems];
    },
    [fetchSharedboxes.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
  },
});

export const {
  setSelectedSharedbox,
  setIsEditingSharedbox,
  setEditedSharedbox,
  setTempSharedbox,
  setSelectedSharedboxFormGroup,
} = sharedboxesSlice.actions;

export default sharedboxesSlice.reducer;
