// DEPRECATED - same as updateElementTypesGroupWithOneUpdatedType

export default function addElementTypeToElementTypesGroup(
  elementType,
  group
) {
  let types = group.elementTypes;
  if (!Array.isArray(types)) types = [];
  types = types.filter((t) => t.id !== elementType.id);
  types = [...types, elementType];
  return {...group, elementTypes: types};
}
