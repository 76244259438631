import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, Input, InputBase} from "@mui/material";

export default function BlockEditableArticleHardcodedQty({article}) {
  const dispatch = useDispatch();

  // string

  const label = "Qté fixée à";

  // state

  let initHardcodedQty = article?.hardcodedQty;
  if (initHardcodedQty === null) initHardcodedQty = "";

  const [hardcodedQty, setHardcodedQty] = useState(initHardcodedQty);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setHardcodedQty(initHardcodedQty);
  }, [initHardcodedQty, article?.id]);

  // helpers

  const placeholder = "x";

  // handlers

  function handleChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    setHardcodedQty(value);
  }

  function handleBlur() {
    setIsEditing(false);
    let qty = parseFloat(hardcodedQty);
    if (isNaN(qty)) qty = null;
    setHardcodedQty("");
    dispatch(updateArticleTemp({id: article.id, hardcodedQty: qty}));
  }

  function handleFocus() {
    setIsEditing(true);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Typography
        sx={{fontSize: 12, fontStyle: "italic", color: "text.secondary"}}
      >
        {label}
      </Typography>
      <Box
        sx={{
          height: 20,
          display: "flex",
          alignItems: "center",
          bgcolor: "background.default",
          px: 1,
          borderRadius: "4px",
          width: 70,
          mx: 1,
        }}
      >
        <InputBase
          fullWidth
          disableUnderline
          sx={{
            color: isEditing ? "primary.main" : "text.primary",
            fontSize: 12,
          }}
          inputProps={{
            style: {textAlign: "right"},
          }}
          value={hardcodedQty}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
        />
      </Box>
      <Typography sx={{fontSize: 12}}>{article?.unit}</Typography>
    </Box>
  );
}
