import {useEffect, useState} from "react";

import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  VisibilityOff,
  Visibility,
  Close,
} from "@mui/icons-material";

import ListItemElementType from "./ListItemElementType";
import sortByNum from "Utils/sortByNum";
import getGroupObjectsFromElementTypes from "../utils/getGroupsFromElementTypes";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import SelectorElementTypesGroupWithCountAndPreview from "./SelectorElementTypesGroupWithCountAndPreview";

export default function ListElementTypesMultipleWithGroups({
  selectedElementTypeIds,
  elementTypes = [],
  groupsProxy, // [{id,name,code,fromScene}] : optional. If used, the groups won't be computed from elementTypes but from this list.
  onSelectionChange,
  multipleSelection,
  selectedGroupId,
  onSelectedGroupProxyChange, // {id,name,fromScene}
  counters, // {groupId:count,} counter used in chip
  showZero, // show counter = 0 in chip
  onCreateGroup, // => show + button to trigger creation.
  onCreateGroupLabel,
  forceSelection, // if true, return one default selection
  hideQuantities,
  showMainQuantities,
  maxHeightGroups,
  variant = "chips",
  visibleIds,
  onVisibleIdsChange,
  visibleGroupIds,
  onVisibleGroupIdsChange,
  showVisibility = false,
  enableClickOnTitle,
}) {
  // strings

  const optionsS = "Affichage";
  const noTypes = "0 élément à repérer";

  const groupsS = "Listes d'éléments";
  const groupS = "Liste d'éléments";

  // state

  const [expand, setExpand] = useState(false);

  const [openGroups, setOpenGroups] = useState(false);

  // helpers

  // v we commment this liene because we do not want to see all the elementTypes if none group is selected. v
  // const filteredElementTypes = elementTypes.filter(
  //   (t) => !selectedGroupId || t.groupId === selectedGroupId
  // );

  const filteredElementTypes = elementTypes.filter(
    (t) => t.groupId === selectedGroupId
  );

  // console.log(
  //   "elementTypes",
  //   selectedGroupId,
  //   elementTypes,
  //   filteredElementTypes
  // );

  // helpers - groups

  let groupObjects = getGroupObjectsFromElementTypes(elementTypes, {
    sortByName: true,
  });
  if (groupsProxy) groupObjects = [...groupsProxy];

  const selectedGroupObject = groupObjects.find(
    (g) => g.id === selectedGroupId
  );

  const emptySelection =
    !selectedGroupId ||
    !groupObjects.map((g) => g.id).includes(selectedGroupId);

  // helpers - groupLabel

  let groupLabel = selectedGroupObject?.name;
  if (selectedGroupObject?.code)
    groupLabel = `[${selectedGroupObject.code}] ${groupLabel}`;

  useEffect(() => {
    if (emptySelection && groupObjects?.length > 0) {
      if (forceSelection) onSelectedGroupProxyChange(groupObjects[0]);
    }
  }, [selectedGroupId, groupObjects?.length]);

  const visibleGroup =
    !visibleGroupIds || visibleGroupIds.includes(selectedGroupObject?.id);
  // handlers

  function handleExpandChange() {
    setExpand((expand) => !expand);
  }

  function handleGroupClick(group, options) {
    //
    if (group?.id === selectedGroupId) group = null;
    //
    const closeGroupsList = options?.closeGroupsList;
    //
    if ((group?.id && selectedGroupId !== group?.id) || !group) {
      // used to avoid to have one hidden selection...
      onSelectionChange([], {fromGroupChange: true});
    }
    onSelectedGroupProxyChange(group);
    //
    if (closeGroupsList) setOpenGroups(false);
  }

  function handleClick(elementType) {
    console.log("click on elementType", elementType);
    const id = elementType?.id;
    const idIsSelected = selectedElementTypeIds?.includes(id);
    let newIds = selectedElementTypeIds?.filter((typeId) => typeId !== id);
    if (multipleSelection) {
      if (!newIds) newIds = [];
      if (elementType.isTitle && !enableClickOnTitle) {
        return;
      } else {
        if (!selectedElementTypeIds?.includes(id)) newIds.push(id);
      }
    } else {
      newIds = idIsSelected ? [] : [id];
    }
    if (onSelectionChange) onSelectionChange(newIds);

    // change visibility
    if (showVisibility && !visibleIds?.includes(elementType.id)) {
      onVisibleIdsChange([...visibleIds, elementType.id]);
    }
  }

  function handleVisibilityChange(elementType) {
    const wasVisible = visibleIds.includes(elementType?.id);
    let newVisibleIds = visibleIds.filter((id) => id !== elementType.id);
    if (!wasVisible) {
      newVisibleIds.push(elementType.id);
    }
    onVisibleIdsChange(newVisibleIds);
  }

  function handleVisibilityGroupChange(e, groupId) {
    e.stopPropagation();
    let newGroupIds = visibleGroupIds
      ? [...visibleGroupIds]
      : [...groupObjects.map((g) => g.id)];

    if (newGroupIds.includes(groupId)) {
      newGroupIds = newGroupIds.filter((id) => id !== groupId);
    } else {
      newGroupIds.push(groupId);
    }
    if (onVisibleGroupIdsChange) onVisibleGroupIdsChange(newGroupIds);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
      }}
    >
      <Box sx={{py: 2}}>
        {variant === "chips" && (
          <SelectorGenericItemWithChips
            items={groupObjects}
            selectedItem={selectedGroupObject}
            onClick={handleGroupClick}
            counters={counters}
            onCreate={onCreateGroup}
            createLabel={onCreateGroupLabel}
            showZero={showZero}
            maxHeight={maxHeightGroups}
          />
        )}
        {variant === "list" && (
          <Box>
            <Typography
              sx={{
                color: "secondary.main",
                fontSize: 12,
                p: 0.5,
                pl: 1,
                visibility: openGroups ? "hidden" : "visible",
              }}
            >
              {groupS}
            </Typography>
            <Box
              sx={{
                p: 1,
                bgcolor: openGroups ? "common.white" : "secondary.main",
              }}
            >
              <Box
                onClick={() => setOpenGroups((open) => !open)}
                sx={{
                  px: 1,
                  py: 0.5,
                  bgcolor: "common.white",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  color: !openGroups ? "text.primary" : "text.secondary",
                }}
              >
                {showVisibility && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: visibleGroup ? "grey.400" : "grey.200",
                    }}
                  >
                    <IconButton
                      sx={{width: 18, height: 18, color: "inherit"}}
                      onClick={(e) =>
                        handleVisibilityGroupChange(e, selectedGroupObject?.id)
                      }
                    >
                      {visibleGroup ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                )}
                <Typography sx={{fontSize: 13}}>
                  {!openGroups ? groupLabel : groupsS}
                </Typography>
                {openGroups ? <Close color="inherit" /> : <Down />}
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {!openGroups && (
        <Box
          sx={{
            bgcolor: "common.white",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0,
            overflowY: "auto",
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {filteredElementTypes.length > 0 ? (
            <List dense disablePadding>
              {filteredElementTypes
                .sort((a, b) => sortByNum(a.num, b.num))
                .map((elementType) => {
                  let selected = false;
                  if (Array.isArray(selectedElementTypeIds))
                    selected = selectedElementTypeIds?.includes(
                      elementType?.id
                    );
                  return (
                    <ListItemButton
                      disableGutters
                      divider
                      sx={{p: 0}}
                      key={elementType?.id}
                      selected={selected}
                      onClick={() => handleClick(elementType)}
                    >
                      <ListItemElementType
                        key={elementType.id}
                        elementType={elementType}
                        visible={visibleIds?.includes(elementType.id)}
                        onVisibilityChange={handleVisibilityChange}
                        options={{
                          hideQuantity: hideQuantities,
                          showMainQuantity: showMainQuantities,
                          showVisibility: showVisibility,
                        }}
                        selected={selected}
                        //onClick={handleClick}
                      />
                    </ListItemButton>
                  );
                })}
              {/* <ListItemButton dense disabled>
              <ListItemText primary={"-"} />
            </ListItemButton> */}
            </List>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 1,
                flexGrow: 1,
                flexDirection: "column",
                py: 2,
              }}
            >
              <Typography sx={{color: "grey.400"}}>{noTypes}</Typography>
            </Box>
          )}
        </Box>
      )}
      {openGroups && variant === "list" && (
        <Box sx={{overflowY: "auto"}}>
          <SelectorElementTypesGroupWithCountAndPreview
            items={groupObjects}
            selectedItem={selectedGroupObject}
            onClick={handleGroupClick}
            counters={counters}
            showZero={showZero}
          />
        </Box>
      )}
    </Box>
  );
}
