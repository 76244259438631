export default function getFormulaLabel(item, ressource, res) {
  const itemUnitFormulaMap = {
    UN: "C",
    ML: "L",
    M2: "S",
    M3: "V",
  };
  if (res) {
    const {mode, func} = res;
    let formula;
    if (mode === "AUTO") {
      formula = itemUnitFormulaMap[item.unit];
    } else if (mode === "FORMUL") {
      formula = func;
    }
    if (!formula?.length) formula = "-?-";
    return `Quantité = ${formula} (${ressource.unit})`;
  }
}
