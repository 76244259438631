import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  IconButton,
  LinearProgress,
  Step,
  Stepper,
  StepLabel,
  Typography,
  Button,
  Divider,
  TextField,
} from "@mui/material";
import {Close} from "@mui/icons-material";

import DisplayBox from "Components/DisplayBox";

import {
  setMode,
  setStep,
  setSubStep,
  setPositioningObject,
  // setConfiguringEntity,
  setConfiguring,
  setConfigCenter,
} from "Features/viewer3D/viewer3DSlice";

export default function ProcessConfigScale({editor3d, onClose}) {
  const dispatch = useDispatch();

  // initializing

  useEffect(() => {
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
  }, []);

  // strings

  const title = "Scale";

  const step0Name = "Point 1";
  const step1Name = "Point 2";
  const step2Name = "Measure";

  const step0Description = "Click into the scene to select the 1st point.";
  const step1Description = "Click into the scene to select the 2nd point.";
  const step2Description = "Specify the distance.";

  const applyButtonString = "Scale";
  const measuredString = "Mesurée";

  // steps

  const steps = {
    POINT_1: {name: step0Name},
    POINT_2: {name: step1Name},
    MEASURE: {name: step2Name},
  };

  // data

  const mode = useSelector((state) => state.viewer3D.mode);
  const step = useSelector((state) => state.viewer3D.step);
  const activeStep = Object.keys(steps).indexOf(step);

  // state

  const [point1, setPoint1] = useState();
  const [point2, setPoint2] = useState();
  const [scale, setScale] = useState(1);
  const measured = computeDistance(point1, point2);

  const [distance, _] = useState();

  // helper

  function computeDistance(point1, point2) {
    if (point1 && point2) {
      return Math.sqrt(
        (point1.x - point2.x) ** 2 +
          (point1.y - point2.y) ** 2 +
          (point1.z - point2.z) ** 2
      );
    } else {
      return undefined;
    }
  }
  function killProcess() {
    dispatch(setMode("PICKING"));
    dispatch(setStep("DEFAULT"));
    dispatch(setSubStep("DEFAULT"));
    editor3d?.switchToMode("PICKING");
    onClose();
  }

  function setEntityNewScaledModel() {
    editor3d?.configCenter.setEntityNewScaledModel({
      scale,
    });
  }

  function endProcess() {
    editor3d?.configCenter.saveConfigEntity();
    editor3d?.switchToViewMode("VIEW_3D");
    if (editor3d) editor3d.handleEditorClick = () => {};
    dispatch(setMode("PICKING"));
    dispatch(setConfiguring(false));
    dispatch(setConfigCenter(false));
    dispatch(setPositioningObject({editor: false}));
    //dispatch(setConfiguringEntity(undefined));
  }

  // handlers

  function handleEditorClick(props) {
    if (mode === "CONFIG_SCALE") {
      const {sceneP} = props;

      if (step === "POINT_1") {
        setPoint1(sceneP);
        dispatch(setStep("POINT_2"));
      }
      if (step === "POINT_2") {
        setPoint2(sceneP);
        dispatch(setStep("MEASURE"));
      }
    }
  }

  function handleScaleClick() {
    setEntityNewScaledModel();
    endProcess();
  }

  function handleDistanceChange(e) {
    const d = e.target.value;
    if (d > 0 && measured > 0) {
      setScale(d / measured);
    }
  }

  // update handlers

  useEffect(() => {
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
  }, [mode, step]);

  // clean up

  useEffect(() => {
    return () => {
      if (editor3d) editor3d.onProcessEditorClick = () => {};
    };
  }, []);

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        background: (theme) => theme.palette.common.white,
        pb: 2,
      }}
    >
      <LinearProgress sx={{width: 1}} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{p: 2}}>
          <b>{title}</b>
        </Typography>
        <IconButton sx={{mr: 1}} onClick={killProcess}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={{width: 1, mt: 2}}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {Object.keys(steps).map((step) => (
            <Step key={step} size="small">
              <StepLabel>
                <Typography variant="body2">{steps[step].name}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <DisplayBox open={mode === "CONFIG_SCALE" && step === "POINT_1"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step0Description}
        </Typography>
      </DisplayBox>

      <DisplayBox open={mode === "CONFIG_SCALE" && step === "POINT_2"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step1Description}
        </Typography>
      </DisplayBox>

      <DisplayBox open={mode === "CONFIG_SCALE" && step === "MEASURE"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step2Description}
        </Typography>
        <Box
          sx={{display: "flex", flexDirection: "column", alignItems: "center"}}
        >
          <TextField
            size="small"
            value={distance}
            onChange={handleDistanceChange}
          />
          <Typography variant="body2" color="text.secondary">
            {measured > 0
              ? `${measuredString} : ${measured.toFixed(3)} m`
              : "-"}
          </Typography>
        </Box>
      </DisplayBox>

      <Divider sx={{my: 2}} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 1,
          width: 1,
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          value={scale}
          onChange={(e) => setScale(e.target.value)}
          sx={{width: 200}}
        />
        <Button
          variant="outlined"
          size="small"
          sx={{mt: 2}}
          onClick={handleScaleClick}
        >
          {applyButtonString}
        </Button>
      </Box>
    </Box>
  );
}
