import React from "react";

import {Paper, Typography} from "@mui/material";
import {decode} from "Features/translations/utils";
export default function ListItemType({ifcType, count, onClick}) {
  const {type, visible} = ifcType;

  const countString = ` (${count})`;
  return (
    <Paper
      elevation={visible ? 12 : 1}
      sx={{
        width: "fit-content",
        ml: 1,
        py: 0.2,
        px: 1,
        cursor: "pointer",
        display: "flex",
        alignItems: "baseline",
      }}
      onClick={() => onClick(ifcType)}
    >
      <Typography sx={{fontWeight: visible ? "bold" : "normal", fontSize: 13}}>
        {decode(type)}
      </Typography>
      <Typography sx={{ml: 1, fontSize: 12}}>{countString}</Typography>
    </Paper>
  );
}
