import computeMeasurementRessourcesQuantitiesV2 from "Features/quantities/computeMeasurementRessourcesQuantitiesV2";
import computeMeasurementRessourcesQuantities from "Features/quantities/computeMeasurementRessourcesQuantities";

import getItemsMapById from "Utils/getItemsMapById";

export default function getRessourcesWithQuantities(
  ressources,
  measurements,
  elementTypes,
  entitiesMap,
  filterNoVoids
) {
  //
  // console.time("debugtime getRessourcesWithQuantities"); //ok
  //
  const elementTypesById = getItemsMapById(elementTypes);
  const ressourcesById = getItemsMapById(ressources);
  const sectorsById = getItemsMapById(entitiesMap["sectors"]);
  const roomsById = getItemsMapById(entitiesMap["rooms"]);
  const zonesById = getItemsMapById(entitiesMap["zones"]);

  // relations
  const resIdRelTypesMap = {};
  elementTypes.forEach((type) => {
    if (type.res) {
      type.res.forEach(({resId}) => {
        if (resIdRelTypesMap[resId]) {
          resIdRelTypesMap[resId].push(type.id);
        } else {
          resIdRelTypesMap[resId] = [type.id];
        }
      });
    }
  });
  //
  const measurementsWithQty = measurements.map((measurement) => {
    const newM = {...measurement};
    const newRes = computeMeasurementRessourcesQuantities({
      measurement,
      elementTypesById,
      ressourcesById,
      zonesById,
      roomsById,
      sectorsById,
      filterNoVoids,
    });
    if (newRes) newM.res = newRes;
    return newM;
  });

  const qtyMap = {};

  measurementsWithQty.forEach((measurement) => {
    if (Array.isArray(measurement.res)) {
      measurement.res.forEach(({resId, qtyRaw, qtyNet}) => {
        const qtyItem = qtyMap[resId];
        qtyRaw = qtyRaw ?? 0;
        qtyNet = qtyNet ?? 0;
        if (!qtyItem) {
          qtyMap[resId] = {qtyRaw, qtyNet};
        } else {
          qtyMap[resId] = {
            qtyRaw: qtyMap[resId].qtyRaw + qtyRaw,
            qtyNet: qtyMap[resId].qtyNet + qtyNet,
          };
        }
      });
    }
  });

  //
  // console.timeEnd("debugtime getRessourcesWithQuantities");
  //

  return ressources.map((ressource) => ({
    ...ressource,
    typeIds: resIdRelTypesMap[ressource.id],
    qtyNet: qtyMap[ressource.id]?.qtyNet,
    qtyRaw: qtyMap[ressource.id]?.qtyRaw,
  }));
}
