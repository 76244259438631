import {useDispatch} from "react-redux";
import {resetAllFilters} from "Features/filters/filtersSlice";

import {Box, Chip, Tooltip} from "@mui/material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";

import {FilterAlt as Filter} from "@mui/icons-material";

export default function ContainerContextOverviewFilter({
  filtersCount,
  filteredCount,
  count,
  onClick,
}) {
  const dispatch = useDispatch();
  const title = "Filtrer les éléments";

  let filterRatio = 0;
  if (filtersCount) filterRatio = count > 0 ? (filteredCount * 100) / count : 0;

  const label = filterRatio > 0 ? `${filterRatio.toFixed()}%` : "...";

  // helpers

  const showDeleteButton = filtersCount > 0;
  const hasFilters = filtersCount > 0;

  // handlers

  function handleDelete() {
    dispatch(resetAllFilters());
  }

  return (
    <Tooltip title={title}>
      <Box
        sx={{
          color: "common.white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Chip
          size="small"
          label={label}
          onClick={onClick}
          onDelete={showDeleteButton ? handleDelete : undefined}
          icon={<Filter fontSize="small" />}
          style={{
            backgroundColor: hasFilters
              ? theme.palette.common.yellow
              : lighten(theme.palette.common.caplaBlack, 0.1),
            color: "white",
            fontSize: 12,
            height: 18,
          }}
          sx={{
            "& .MuiChip-icon": {fontSize: 12, color: "common.white"},
            "& .MuiChip-deleteIcon": {color: "common.white"},
          }}
        />
      </Box>
    </Tooltip>
  );
}
