import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import useRawMeasurementSubjects from "../hooks/useRawMeasurementSubjects";

import {Menu, MenuItem, Box, Typography, Button} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import DialogImportSubjectTypeMapFromExcel from "./DialogImportSubjectTypeMapFromExcel";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";

import getSubjectTypeMapForExcel from "../utils/getSubjectTypeMapForExcel";

export default function ButtonExcelSubjectElementTypeMap({
  editor,
  scene,
  viewer,
}) {
  const dispatch = useDispatch();

  // strings

  const label = "Excel";
  const exportS = "Export";
  const importS = "Import";

  const fileName = "subjectElementTypeMap.xlsx";

  // data

  const subjects = useRawMeasurementSubjects(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openImportDialog, setOpenImportDialog] = useState(false);

  // helpers - handlers

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  async function handleExport() {
    setAnchorEl(null);
    const subjectTypeMapForExcel = getSubjectTypeMapForExcel(subjects);
    const wb = await createExcelWorkbook({
      template: "SUBJECT_TYPE_MAP",
      data: subjectTypeMapForExcel,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  // helpers - tools

  const tools = [
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
    },
  ];

  return (
    <>
      <Button
        endIcon={<More />}
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </Button>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogImportSubjectTypeMapFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
    </>
  );
}
