export default function getArticleTypesListItemsProps(articleTypes, data) {
  const {elementTypesMap} = data;

  const items = [];
  if (articleTypes) {
    Object.entries(articleTypes).forEach(([groupId, typeMap]) => {
      if (groupId === "filtersStr" && typeMap?.length > 0) {
        const item = {
          id: "globalScope",
          filtersStr: typeMap,
          func: articleTypes["globalFunc"],
        };
        items.push(item);
      } else {
        Object.entries(typeMap ?? {}).forEach(([typeId, props]) => {
          if (typeId === "filtersStr") {
            const item = {
              id: groupId,
              groupId,
              filersStr: props,
            };
            items.push(item);
          } else {
            const elementType = elementTypesMap[typeId];
            const item = {
              id: typeId,
              typeId: typeId,
              groupId: groupId,
              elementType,
              ...props,
            };
            items.push(item);
          }
        });
      }
    });
  }

  return items;
}
