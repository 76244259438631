import React, {useState} from "react";

import {Grid, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function FormProgressListingPackagesEditItem({
  model,
  onClose,
  isEditing,
}) {
  // handlers

  function handleClick() {
    onClose(model);
  }
  return (
    <Grid sx={{width: 1}} container>
      <Grid item xs={10}>
        <Typography variant="body2">{model.name}</Typography>
      </Grid>
      <Grid item xs={2}>
        {isEditing && (
          <IconButton size="small" onClick={handleClick}>
            <Close fontSize="small" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
