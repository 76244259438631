import {createSlice} from "@reduxjs/toolkit";

export const buildboxSlice = createSlice({
  name: "buildbox",
  initialState: {
    modelId: null,
    listingId: null,
    scenarioId: null, // used in the build page. selected build scenario assistant.
    showSectionLoading: false, // loading screen, when automating box creation.
    buildingPageMode: "BASIC", // "ADVANCED", mode in settings.
  },
  reducers: {
    setModelId: (state, action) => {
      state.modelId = action.payload;
    },
    setListingId: (state, action) => {
      state.listingId = action.payload;
    },
    setScenarioId: (state, action) => {
      const scenarioId = action.payload;
      state.scenarioId = scenarioId;
    },
    setShowSectionLoading: (state, action) => {
      state.showSectionLoading = action.payload;
    },
    setBuildingPageMode: (state, action) => {
      state.buildingPageMode = action.payload;
    },
  },
});

export const {
  setListingId,
  setModelId,
  setScenarioId,
  setShowSectionLoading,
  setBuildingPageMode,
} = buildboxSlice.actions;

export default buildboxSlice.reducer;
