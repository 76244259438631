import React from "react";

import {useDispatch} from "react-redux";
import {setSelectedRessourceId} from "../ressourcesSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";

import {Box, Button} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function ButtonCreateRessource() {
  const dispatch = useDispatch();

  // strings

  const newS = "New article";

  // handlers

  function handleClick() {
    dispatch(setOpenSelectionPanel(true));
    dispatch(setSelectedRessourceId(null));
  }

  return (
    <Button
      startIcon={<Add />}
      size="small"
      variant="contained"
      color="secondary"
      onClick={handleClick}
    >
      {newS}
    </Button>
  );
}
