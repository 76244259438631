import React from "react";

import {Box, Paper} from "@mui/material";
import ButtonGoToOrigin from "./ButtonGoToOrigin";
import ButtonCreateMarkerOnAzm from "./ButtonCreateMarkerOnAzm";

import ButtonCreateModelFromMap from "Features/viewer3D/components/ButtonCreateModelFromMap";

export default function ToolbarDefault({editor, scene}) {
  return (
    <Paper sx={{p: 0.5, display: "flex", alignItems: "center"}} elevation={12}>
      <ButtonGoToOrigin editor={editor} scene={scene} />
      <ButtonCreateMarkerOnAzm editor={editor} />
      <ButtonCreateModelFromMap editor={editor} scene={scene} />
    </Paper>
  );
}
