import {Box} from "@mui/material";

import IconButtonPlotPictures from "./IconButtonPlotPictures";
import IconButtonUploadPictures from "./IconButtonUploadPictures";
import IconButtonDownloadPictures from "./IconButtonDownloadPictures";

export default function Toolbar({caplaEditor, listing, tempPictures}) {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <IconButtonPlotPictures caplaEditor={caplaEditor} />
      <IconButtonUploadPictures
        tempPictures={tempPictures}
        listingId={listing.id}
      />
      <IconButtonDownloadPictures
        tempPictures={tempPictures}
        listingId={listing.id}
      />
    </Box>
  );
}
