import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton,
  IconButton,
} from "@mui/material";
import {Launch} from "@mui/icons-material";

import useSelectedPdfModel from "../useSelectedPdfModel";

import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import {setLastPackageId} from "Features/elementPackages/packagesSlice";

export default function SectionPdfPackagesInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // data

  const selectedPdfModel = useSelectedPdfModel();
  const allPackages = usePackagesByScene(scene);

  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  // helpers

  let packages = allPackages.filter(
    (p) => p.fromModel.modelId === selectedPdfModel?.id
  );

  // helpers

  let sceneId = scene?.id;
  if (!sceneId) sceneId = scene?.clientId;

  // handlers

  function handleLaunchPackage(model) {
    navigate(`/bimboxes/${sceneId}/${model.id}/measurements`);
    //dispatch(setLastPackageId(model.id));
    dispatch(setSelectedMeasurementsModelId(model.id));
    // caplaEditor?.measDataManager.setSelectedModelById(model.id);
  }

  return (
    <Box
      sx={{
        width: 1,
        p: 1,
        borderRadius: "4px",
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "text.secondary",
        }}
      >
        {iconMap["PACKAGES"]}
        <Typography sx={{ml: 1}} variant="body2">
          {nameMap["PACKAGES"]}
        </Typography>
      </Box>
      <List dense>
        {packages.map((model) => {
          let ms = model.measurementsData?.measurements;
          if (!ms) ms = [];
          const countLabel = `(${ms.length})`;
          const label = model.name;
          return (
            <ListItem
              key={model.id}
              disablePadding
              secondaryAction={
                <IconButton
                  onClick={() => handleLaunchPackage(model)}
                  size="small"
                >
                  <Launch fontSize="small" />
                </IconButton>
              }
            >
              <ListItemButton>
                <Box sx={{display: "flex", alignItems: "baseline"}}>
                  <Typography variant="body2">{label}</Typography>
                  <Typography
                    sx={{ml: 2, color: "text.secondary", fontSize: 13}}
                  >
                    {countLabel}
                  </Typography>
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
