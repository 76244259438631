import { useDispatch, useSelector } from "react-redux";

import {Box, Tooltip, IconButton, Typography} from "@mui/material";
import {
  ChevronLeft as Left,
  ChevronRight as Right,
  PictureAsPdf as Pdf,
} from "@mui/icons-material";

import {setPdfViewerIsLoaded} from "Features/pdf/pdfSlice";
import {setOpenSections} from "Features/ui/uiSlice";

export default function ViewersToggle({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const openPdfS = "Ouvrir la visionneuse PDF";
  const closePdfS = "Fermer la visionneuse PDF";
  const open3dS = "Ouvrir l'espace 3D";
  const close3dS = "Fermer l'espace 3D";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const pdfViewerIsLoaded = useSelector((s) => s.pdf.pdfViewerIsLoaded);
  const selectedMeasurementIds = useSelector((s) => s.measurements.selectedMeasurementIds);

  // helpers

  const handleW = os.fixedViewersBox ? 24 : 32;

  // handlers
  
  function handleToggleOpenPdfPanel() {
    const _os = {...os};
    _os.fixedViewersBox = !os.fixedViewersBox;
    if (!os.fixedViewersBox && !pdfViewerIsLoaded) {
      dispatch(setPdfViewerIsLoaded(true));
    }
    dispatch(setOpenSections(_os));
    // caplaEditor.setOpenSections(_os, selectedMeasurementIds);
  }

  function handleToggleOpen3dPanel() {
    const _os = {...os};
    _os.viewer3D = !os.viewer3D;
    dispatch(setOpenSections(_os));
    // caplaEditor.setOpenSections(_os, selectedMeasurementIds);
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          zIndex: 30,
          width: handleW,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          left: `-${handleW + 1}px`,
          bottom: "72px",
          //bgcolor: "common.red",
          bgcolor: "primary.main",
          color: "common.white",
        }}
      >
        <Tooltip
          title={os.fixedViewersBox ? closePdfS : openPdfS}
          placement="left"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: os.fixedViewersBox ? 0 : 0.5,
              cursor: "pointer",
            }}
            onClick={handleToggleOpenPdfPanel}
          >
            {!os.fixedViewersBox && <Pdf fontSize="small" />}
            <IconButton
              size="small"
              color="inherit"
              onClick={handleToggleOpenPdfPanel}
            >
              {os.fixedViewersBox ? (
                <Right color="inherit" />
              ) : (
                <Left color="inherit" />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      </Box>

      <Box
        sx={{
          position: "absolute",
          zIndex: 50,
          width: handleW,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          left: "1px",
          bottom: "72px",
          bgcolor: "common.darkPurple",
          color: "common.white",
        }}
      >
        <Tooltip
          title={os.viewer3D ? close3dS : open3dS}
          placement="right"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pt: os.viewer3D ? 0 : 0.5,
              cursor: "pointer",
            }}
            onClick={handleToggleOpen3dPanel}
          >
            {!os.viewer3D && (
              <Typography sx={{fontSize: 13}}>{"3D"}</Typography>
            )}
            <IconButton
              size="small"
              color="inherit"
              onClick={handleToggleOpen3dPanel}
            >
              {os.viewer3D ? (
                <Left color="inherit" />
              ) : (
                <Right color="inherit" />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </>
  );
}