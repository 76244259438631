import {useState} from "react";
import {useDispatch} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useSelectedPdfModel from "../useSelectedPdfModel";

import useAccessToken from "Features/auth/useAccessToken";
import {deleteModel, updateModel} from "Features/viewer3D/viewer3DSlice";
import {deleteRemoteModel} from "Features/scenes/scenesSlice";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function ButtonMoreActionsPdf() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const changeS = "Change file";
  const deleteS = "Delete";

  // data

  const selectedPdfModel = useSelectedPdfModel();

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helpers

  const accept = ".pdf";

  // handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    const model = selectedPdfModel;

    await dispatch(deleteModel({model}));
    if (model.remoteId)
      await dispatch(
        deleteRemoteModel({
          modelRemoteId: model.remoteId,
          modelId: model.id,
          accessToken,
        })
      );
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }
  
  // handlers - change file


  async function handleFileChange(file) {
    console.log("file", file, file.name, file.size);
    const updatedModel = {...selectedPdfModel, fileName: file.name, fileSize: file.size};
    dispatch(updateModel({updatedModel, file}));
  }


  // helpers - tools

  const tools = [
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
    {
      id: "CHANGE_FILE",
      label: changeS,
      handler: handleFileChange,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          if (tool.id === "CHANGE_FILE")
            return (
              <FileSelectorButton
                key={tool.id}
                startIcon="none"
                buttonVariant="text"
                buttonColor="inherit"
                buttonName={changeS}
                accept={accept}
                onFileChange={handleFileChange}
              />
            );
          else return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"model"}
      />
    </>
  );
}
