// import {useDispatch} from "react-redux";

import {Box, Button, Typography} from "@mui/material";

export default function ButtonDisableZonesInViewer3d({editor3d}) {
  // const dispatch = useDispatch();

  // string

  const disableS = "Tout masquer";

  // handlers

  function handleChange() {
    editor3d?.sceneEditor.hideAllImageModels();
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", height: "20px"}}>
      {/* <FormControlLabel
        control={
          <Switch
            checked={enabled}
            onChange={handleChange}
            size="small"
            color="primary"
          />
        }
        label={<Typography sx={{fontSize: 13}}>{disableS}</Typography>}
      /> */}
      <Button
        size="small"
        onClick={handleChange}
      >
        <Typography
          sx={{
            fontSize: 12,
            color: "text.secondary"
          }}
        >
          {disableS}
        </Typography>
      </Button>
    </Box>
  );
}
