import makeStyles from "@mui/styles/makeStyles";

import CardImageDescription from "./CardImageDescription";
import ToolModelsManager from "./ToolModelsManager";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

export default function ToolImages({editor3d}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CardImageDescription />
      <ToolModelsManager editor3d={editor3d} type="IMAGE" />
    </div>
  );
}
