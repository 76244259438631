import {
  AutoFixNormal as Auto,
  Polyline,
  Pentagon as Polygon,
  Square as Rectangle,
  FilterCenterFocus as SegmentFromZones,
  Add as Point,
} from "@mui/icons-material";

import DrawInteriorLine from "Icons/DrawInteriorLine";
import DrawExteriorLine from "Icons/DrawExteriorLine";
import DrawPolyline from "Icons/DrawPolyline";
import DrawSegment from "Icons/DrawSegment";

export default function useAnnotCreationToolsMap() {
  // strings

  const polygonS = "Polygon";
  const polygonIntS = "Interior polygon";
  const polylineS = "Polyline";
  const polylineIntS = "Interior polyline";
  const polylineExtS = "Exterior polyline";
  const segmentS = "Segment";
  const autoS = "Auto";
  const rectangleS = "Rectangle";
  const segmentFromZonesS = "Segment from zones";
  const pointS = "Point";

  const toolMap = {
    POINT: {
      name: pointS,
      icon: <Point fontSize="small" color="inherit" />,
    },
    POLYGON: {
      name: polygonS,
      icon: <Polygon fontSize="small" color="inherit" />,
    },
    POLYGON_INTERIOR: {
      name: polygonIntS,
      icon: <DrawInteriorLine fontSize="small" color="inherit" />,
    },
    POLYLINE: {
      name: polylineS,
      icon: <Polyline fontSize="small" color="inherit" />,
    },
    POLYLINE_INTERIOR: {
      name: polylineIntS,
      icon: <DrawInteriorLine fontSize="small" color="inherit" />,
    },
    POLYLINE_EXTERIOR: {
      name: polylineExtS,
      icon: <DrawExteriorLine fontSize="small" color="inherit" />,
    },
    AUTO_MEASURE: {
      name: autoS,
      icon: <Auto fontSize="small" color="inherit" />,
    },
    SEGMENT: {
      name: segmentS,
      icon: <DrawSegment fontSize="small" color="inherit" />,
    },
    RECTANGLE: {
      name: rectangleS,
      icon: <Rectangle fontSize="small" color="inherit" />,
    },
    SEGMENT_FROM_ZONES: {
      name: segmentFromZonesS,
      icon: <SegmentFromZones fontSize="small" color="inherit" />,
    },
  };

  return toolMap;
}
