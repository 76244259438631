import React from "react";
import useMobileModules from "../hooks/useMobileModules";

import {Box, Checkbox, Typography, Paper} from "@mui/material";

export default function SelectorMobileModules({selection, onChange}) {
  // data

  const momos = useMobileModules();

  // handlers

  function handleCheckChange(code) {
    if (selection.includes(code)) {
      onChange(selection.filter((c) => c !== code));
    } else {
      onChange([...selection, code]);
    }
  }

  return (
    <Box>
      {momos.map((momo) => {
        const checked = selection.includes(momo.code);
        return (
          <Paper key={momo.code} sx={{mb: 2, p: 2}}>
            <Box sx={{display: "flex", justifyContent: "space-between", mb: 1}}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Box
                  sx={{
                    color: "text.secondary",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mr: 1,
                  }}
                >
                  {momo.icon}
                </Box>
                <Typography variant="body2" sx={{fontWeight: "bold"}}>
                  {momo.name}
                </Typography>
              </Box>
              <Checkbox
                checked={checked}
                onChange={() => handleCheckChange(momo.code)}
              />
            </Box>
            <Typography variant="body2">{momo.description}</Typography>
          </Paper>
        );
      })}
    </Box>
  );
}
