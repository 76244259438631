import {useState} from "react";

import {
  Box,
  Paper,
  // Badge,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";
import {Palette as Edit, Visibility, VisibilityOff} from "@mui/icons-material";

export default function ContainerPackageInTopBar({
  id,
  name,
  // pdfName,
  measCount,
  enabled,
  onClick,
  edited,
  onEdit,
}) {
  // strings

  const editS = "Editer les éléments";

  // state

  const [showEdit, setShowEdit] = useState(false);

  // helpers

  // let subTitle = "0 élément";
  // if (measCount === 1) subTitle = "1 élément";
  // if (measCount > 1) subTitle = `x${measCount} éléments`;
  // let subTitle = `x${measCount}`;

  // helpers

  let bgcolor = "background.default";
  if (enabled) bgcolor = "common.white";
  if (edited) bgcolor = "common.yellow";
  if (edited && !enabled) bgcolor = lighten(theme.palette.common.yellow, 0.5);

  let color = "text.secondary";
  if (enabled || edited) color = "text.primary";

  let bgcolorCounter = "background.default";
  if (enabled || edited) bgcolorCounter = "primary";

  // handlers

  function handleMouseEnter(e) {
    e.stopPropagation();
    e.preventDefault();
    setShowEdit(true);
  }

  function handleMouseLeave(e) {
    e.stopPropagation();
    e.preventDefault();
    setShowEdit(false);
  }

  function handleClick() {
    if (onClick) onClick(id);
  }

  function handleEdit() {
    if (onEdit) onEdit(id);
  }
  return (
    <Paper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      sx={{
        height: "24px",
        borderRadius: "12px",
        display: "flex",
        p: 0.5,
        pl: 1,
        cursor: "pointer",
        position: "relative",
        alignItems: "center",
        bgcolor,
      }}
      elevation={enabled ? 6 : 2}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "action",
        }}
      >
        {enabled ? (
          <Visibility
            sx={{fontSize: 14, fill: (theme) => theme.palette.action.main}}
          />
        ) : (
          <VisibilityOff sx={{fontSize: 14}} />
        )}
      </Box>
      {measCount > 0 && !(showEdit && !edited) && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 100,
            top: 0,
            right: 0,
            mr: "-4px",
            ml: "-4px",
            borderRadius: "12px",
            bgcolor,
            border: `1px solid white`,
            px: 0.3,
          }}
        >
          <Typography sx={{fontSize: 10}}>{measCount}</Typography>
        </Box>
      )}
      {showEdit && !edited && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 0,
            mr: "-8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "common.yellow",
            color: "common.white",
            borderRadius: "50%",
            border: `1px solid white`,
          }}
        >
          <Tooltip title={editS}>
            <IconButton
              sx={{width: 24, height: 24}}
              color="inherit"
              onClick={handleEdit}
            >
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          px: 1,
          //overflow: "hidden",
        }}
      >
        <Typography sx={{fontSize: 12}} color={color} noWrap>
          {name}
        </Typography>
        {/* <Typography noWrap sx={{fontSize: 12, color: "text.secondary"}}>
              {subTitle}
            </Typography> */}
      </Box>
    </Paper>
  );
}
