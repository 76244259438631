import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

export default function useColoringModel() {
  const {coloringId} = useParams();
  const models = useSelector((s) => s.viewer3D.models);
  const colorings = useSelector((s) => s.colorings.items);
  const coloring = colorings.find((c) => c.id === coloringId);

  const modelId = coloring?.model;

  return models.find((m) => m.remoteId === modelId);
}
