import {useState, useEffect} from "react";

import {Autocomplete, TextField, Box, Typography} from "@mui/material";

import ContainerElementType from "./ContainerElementType";
import SelectorElementTypeInSelectionPanel from "./SelectorElementTypeInSelectionPanel";

export default function SelectorElementType({
  elementType,
  elementTypes,
  onChange,
  label,
}) {
  const [open, setOpen] = useState(false);

  // options

  const options = elementTypes
    .map((t) => ({
      ...t,
      num: t.num ? t.num : "0",
      label: `${t.group} -> ${t.num} • ${t.name}`,
    }))
    .sort((a, b) => a.num.localeCompare(b.num));

  const initOption = elementType
    ? {
        ...elementType,
        label: `${elementType.group} -> ${elementType.num} • ${elementType.name}`,
      }
    : null;

  // handlers dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handleClick() {
    setOpen(true);
  }

  function handleTypeChange(type) {
    console.log("CHANGE Type 1", type);
    onChange(type);
  }

  return (
    <Box sx={{width: 1}}>
      <ContainerElementType
        elementType={elementType}
        onClick={handleClick}
        variant="compact"
      />
      {open && (
        <SelectorElementTypeInSelectionPanel
          selectedElementType={elementType}
          elementTypes={elementTypes}
          onChange={handleTypeChange}
          onClose={handleCloseDialog}
        />
      )}
      {/* <Autocomplete
        sx={{color: "inherit"}}
        fullWidth
        options={options}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option.label}
        // renderOption={(props, option) => (
        //   <Typography {...props} variant="body2" sx={{pl: 1}}>
        //     {`${option.group} -> ${option.num} • ${option.name}`}
        //   </Typography>
        // )}
        value={initOption}
        onChange={(e, v) => handleTypeChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={
              label ? <Typography variant="body2">{label}</Typography> : null
            }
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
              },
            }}
          />
        )}
      /> */}
    </Box>
  );
}
