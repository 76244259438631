import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import useSelectionMulti from "Features/selection/useSelectionMulti";
import ActionsMeasurementMultiInSelectionPanel from "Features/measurements/components/ActionsMeasurementMultiInSelectionPanel";

export default function SectionActionsMulti({caplaEditor, scene}) {
  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();
  const selectionMulti = useSelectionMulti();

  return (
    <Box sx={{width: 1}}>
      {selectionMulti?.type === "MEASUREMENTS" &&
        ([
          "EDIT_MEASUREMENTS",
          "MEASUREMENTS",
          "ELEMENT_TYPES",
          "ARTICLES",
          "PACKAGES",
        ].includes(sceneModule) ||
          pageType === "COLORING") && (
          <ActionsMeasurementMultiInSelectionPanel
            caplaEditor={caplaEditor}
            scene={scene}
          />
        )}
    </Box>
  );
}
