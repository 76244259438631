import {nanoid} from "nanoid";
import caplaDB, {MODEL_DO, OBJECTSET_DO, SCENE_DO, FILE_DO} from "./db";
import {blobToArrayBuffer, arrayBufferToBlob} from "./utils";
import bimbox from "./assets/bimbox.png";

// database

export async function resetClientDB() {
  await caplaDB.delete();
  caplaDB.init();
}

export async function getStorageInfo() {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(FILE_DO, "readwrite").objectStore(FILE_DO);
    const files = await store.getAll();
    const filesSize = files.reduce((acc, current) => {
      return acc + current.size;
    }, 0);
    const filesCount = files.reduce((acc, current) => {
      return acc + 1;
    }, 0);
    return {filesSize, filesCount};
  } catch (e) {
    console.log(e);
  }
}
// -----------------
// ---* Scene3d *---
// -----------------

export async function createScene({scene, file}) {
  const db = await caplaDB.dbPromise;

  const name = file?.name;
  const lastModified = file?.lastModified;
  const size = file?.size;
  const type = file?.type;
  let fileClientId;
  let storedFile;

  // store file in db if it does not exist
  if (name && lastModified && size && type) {
    const fileStore = db.transaction(FILE_DO, "readonly").objectStore(FILE_DO);
    storedFile = await fileStore.index("name,lastModified,size").get[
      (name, lastModified, size)
    ];
  }
  if (!storedFile && file) {
    fileClientId = nanoid();
    storedFile = await createStoredFile({file, clientId: fileClientId});
  } else {
    fileClientId = storedFile.clientId;
  }

  const storedScene = {...scene, fileClientId};
  const store = db.transaction(SCENE_DO, "readwrite").objectStore(SCENE_DO);
  await store.add(storedScene);

  return storedScene;
}

export async function deleteScene(scene) {
  const db = await caplaDB.dbPromise;
  const tx = db.transaction(SCENE_DO, "readwrite");
  const store = tx.objectStore(SCENE_DO);
  store.delete(scene.clientId);
  return scene;
}

export async function fetchScenes() {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(SCENE_DO, "readonly").objectStore(SCENE_DO);
  const scenes = await store.getAll();
  const updatedScenes = await Promise.all(
    scenes.map(async (scene) => {
      let imageClientUrl = bimbox;
      if (scene.imageFileClientId) {
        const file = await fetchStoredFile(scene.fileClientId);
        imageClientUrl = URL.createObjectURL(file);
      }
      return {...scene, imageClientUrl};
    })
  );
  return updatedScenes;
}
export async function fetchAllStoredScenes() {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(SCENE_DO, "readonly").objectStore(SCENE_DO);
  const scenes = await store.getAll();
  return scenes;
}

// used when scene backup is triggered.
export async function fetchStoredScene(sceneClientId) {
  const db = await caplaDB.dbPromise;
  // get scene
  const store = db.transaction(SCENE_DO, "readonly").objectStore(SCENE_DO);
  const scene = await store.get(sceneClientId);
  // get file
  const file = await fetchStoredFile(scene.fileClientId);
  return {scene, file};
}

export async function fetchSceneById(id) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(SCENE_DO, "readonly").objectStore(SCENE_DO);
  const scene = await store.index("id").get(id);
  const blob = await arrayBufferToBlob(scene.imageArrayBuffer, "image/png");
  const file = new File([blob], `scene_${scene.id}.png`);
  return {
    clientId: scene.clientId,
    id: scene.id,
    title: scene.title,
    imageUrl: scene.imageUrl,
    imageClientUrl: URL.createObjectURL(file),
  };
}

export async function updateScene({scene, file}) {
  let fileClientId = scene.fileClientId;
  let imageClientUrl = scene.imageClientUrl;
  // file update
  if (file) {
    await deleteStoredFile(scene.fileClientId);
    fileClientId = nanoid();
    await createStoredFile({file, clientId: fileClientId});
    imageClientUrl = URL.createObjectURL(file);
  }
  // scene update
  const db = await caplaDB.dbPromise;
  const store = db.transaction(SCENE_DO, "readwrite").objectStore(SCENE_DO);
  const oldStoredScene = await store.get(scene.clientId);
  const newStoredScene = {
    ...oldStoredScene,
    ...scene,
    fileClientId,
    imageClientUrl,
  };
  store.put(newStoredScene);
  return newStoredScene;
}

// ---------------
// ---* Files *---
// ---------------

export async function createStoredFile({
  file,
  clientId,
  resolution = "ORIGINAL",
}) {
  try {
    const {name, size, type, lastModified} = file;
    const arrayBuffer = await blobToArrayBuffer(file);
    const storedFile = {
      clientId,
      name,
      size,
      type,
      lastModified,
      arrayBuffer,
      resolution,
    };

    const db = await caplaDB.dbPromise;
    const tx = db.transaction(FILE_DO, "readwrite");
    const store = tx.objectStore(FILE_DO);
    store.add(storedFile);
    return storedFile;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchStoredFile(clientId) {
  try {
    if (clientId) {
      const db = await caplaDB.dbPromise;
      const store = db.transaction(FILE_DO, "readonly").objectStore(FILE_DO);
      const file = await store.get(clientId);
      if (file) {
        const blob = await arrayBufferToBlob(file.arrayBuffer, file?.type);
        const storedFile = new File([blob], file?.name);
        return storedFile;
      } else {
        return null;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchSceneStoredFiles(sceneClientId) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
  const index = store.index("sceneClientId");
  const models = await index.getAll(sceneClientId);

  const files = await Promise.all(
    models.map((model) => {
      return fetchStoredFile(model.fileClientId);
    })
  );
  return files;
}

export async function fetchModelStoredFile(modelId) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
  const model = await store.get(modelId);
  const storedFile = fetchStoredFile(model.fileClientId);
  return storedFile;
}

export async function updateFile({updates}) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(FILE_DO, "readwrite").objectStore(FILE_DO);
    const oldFile = await store.get(updates.clientId);
    const newFile = {...oldFile, ...updates};
    await store.put(newFile);
    return newFile;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteStoredFile(clientId) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(FILE_DO, "readwrite").objectStore(FILE_DO);
    await store.delete(clientId);
  } catch (e) {
    console.log(e);
  }
}

export async function getFilesStorageSize() {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(FILE_DO, "readwrite").objectStore(FILE_DO);
    const files = await store.getAll();
    const size = files.reduce((acc, current) => {
      return acc + current.size;
    }, 0);
    return size;
  } catch (e) {
    console.log(e);
  }
}

// ----------------
// ---* Models *---
// ----------------

/*
 * used in debug
 */

// export async function fetchStoredModels(sceneClientId) {
//   const db = await caplaDB.dbPromise;
//   const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
//   const index = store.index("sceneClientId");
//   const items = await index.getAll(sceneClientId);
//   const models = await Promise.all(
//     items.map(async (item) => {
//       const file = await fetchStoredFile(item.fileClientId);
//       const fileHR =
//         item.fileHRClientId && (await fetchStoredFile(item.fileHRClientId));
//       const fileLR =
//         item.fileLRClientId && (await fetchStoredFile(item.fileLRClientId));
//       const newModel = {
//         ...item,
//         url: URL.createObjectURL(file),
//         urlLowReso: fileLR && URL.createObjectURL(fileLR),
//         urlHightReso: fileHR && URL.createObjectURL(fileHR),
//       };
//       return {model: newModel, file};
//     })
//   );
//   return models;
// }

// used when scene backup is triggered.
// export async function fetchStoredModel(modelClientId) {
//   const db = await caplaDB.dbPromise;
//   // get model
//   const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
//   const model = await store.get(modelClientId);
//   // get file
//   const file = await fetchStoredFile(model.fileClientId);
//   const fileHR =
//     model.fileHRClientId && (await fetchStoredFile(model.fileHRClientId));
//   const fileLR =
//     model.fileLRClientId && (await fetchStoredFile(model.fileLRClientId));

//   // update
//   const newModel = {
//     ...model,
//     url: URL.createObjectURL(file),
//     urlLowReso: fileLR && URL.createObjectURL(fileLR),
//     urlHightReso: fileHR && URL.createObjectURL(fileHR),
//   };
//   return {model: newModel, file, fileHR, fileLR};
// }

export async function deleteStoredModel(id) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readwrite").objectStore(MODEL_DO);
  try {
    store.delete(id);
  } catch (error) {
    console.log(error);
  }
}

// ----------------
// ---* Objectsets *---
// ----------------
export async function createStoredObjectset(objectset) {
  try {
    const db = await caplaDB.dbPromise;
    const tx = db.transaction(OBJECTSET_DO, "readwrite");
    const store = tx.objectStore(OBJECTSET_DO);

    const oldObjectset = await store.get(objectset.id);
    if (oldObjectset) {
      console.log("UPDATE STORED OBJECTSET IN DB");
      store.put(objectset);
    } else {
      console.log("CREATE STORED OBJECTSET IN DB");
      store.add(objectset);
    }
    return objectset;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchStoredObjectsets({sceneClientId}) {
  const db = await caplaDB.dbPromise;
  const store = db
    .transaction(OBJECTSET_DO, "readonly")
    .objectStore(OBJECTSET_DO);
  const index = store.index("sceneClientId");
  const items = await index.getAll(sceneClientId);
  return items;
}

export async function updateStoredObjectset({objectset}) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db
      .transaction(OBJECTSET_DO, "readwrite")
      .objectStore(OBJECTSET_DO);
    const oldObjectset = await store.get(objectset.id);
    const newObjectset = {...oldObjectset, ...objectset};
    await store.put(newObjectset);
  } catch (e) {
    console.log(e);
  }
}

export async function deleteStoredObjectset({objectsetId}) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db
      .transaction(OBJECTSET_DO, "readwrite")
      .objectStore(OBJECTSET_DO);
    await store.delete(objectsetId);
  } catch (e) {
    console.log(e);
  }
}

// Misc

export async function deleteSceneRessource({sceneClientId}) {
  try {
    const db = await caplaDB.dbPromise;

    const tx = db.transaction(FILE_DO, "readwrite");
    const index = tx.store.index("sceneClientId");
    const files = await index.getAll(sceneClientId);
    files.forEach((file) => {
      tx.store.delete(file.clientId);
    });

    const tx2 = db.transaction(SCENE_DO, "readwrite");
    tx2.store.delete(sceneClientId);
  } catch (e) {
    console.log(e);
  }
}

export async function deleteTempScenes() {
  try {
    const db = await caplaDB.dbPromise;
    const tx = db.transaction(SCENE_DO, "readonly");
    const index = tx.store.index("localStorage");
    const scenes = await index.getAll(0);
    scenes.forEach((scene) =>
      deleteSceneRessource({sceneClientId: scene.clientId})
    );
  } catch (e) {
    console.log(e);
  }
}
