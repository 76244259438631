import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  Typography,
  Chip,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import {
  setSelectedRessourceGroup,
  setNewRessource,
  setTempGroup,
} from "../ressourcesSlice";
import useRessourceGroupsByScene from "../hooks/useRessourceGroupsByScene";

export default function SectionRessourceGroupWithChip({scene}) {
  const dispatch = useDispatch();

  // strings

  const newS = "Table";

  const examplesS = "Ex : Budget, DPGF, Matériaux";
  const title = "Nouvelle table d'articles";
  const nameS = "Nom de la table";
  const saveS = "Créer";
  const cancelS = "Annuler";

  // data

  const groups = useRessourceGroupsByScene(scene);
  const selectedRessourceGroup = useSelector(
    (s) => s.ressources.selectedRessourceGroup
  );
  const newRessource = useSelector((s) => s.ressources.newRessource);

  // state

  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [isFirstMounted, setIsFirstMounted] = useState(false);

  // handlers

  function handleGroupClick(g) {
    const group = selectedRessourceGroup === g ? null : g;
    dispatch(setSelectedRessourceGroup(group));
    dispatch(setNewRessource({...newRessource, group}));
  }

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleCreateClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  function handleSave() {
    dispatch(setTempGroup(name));
    dispatch(setSelectedRessourceGroup(name));
    setOpen(false);
  }

  // effect

  useEffect(() => {
    if (!isFirstMounted && groups?.length > 0) {
      if (!selectedRessourceGroup)
        dispatch(setSelectedRessourceGroup(groups[0]));
      setIsFirstMounted(true);
    }
  }, [isFirstMounted, groups?.length]);

  return (
    <Box sx={{width: 1, p: 1, pl: "16px"}}>
      <Box
        sx={{
          color: "text.secondary",
          display: "flex",
          flexWrap: "wrap",
          "&>*:not(:last-child)": {mr: 1, mb: 0.5},
        }}
      >
        {groups.map((group) => {
          const selected = group === selectedRessourceGroup;
          return (
            <Chip
              key={group}
              label={group ? group : "-?-"}
              variant={selected ? "default" : "outlined"}
              onClick={() => handleGroupClick(group)}
            />
          );
        })}

        <Chip
          label={newS}
          icon={<Add fontSize="small" />}
          onClick={handleCreateClick}
          variant="outlined"
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{p: 1}}>
            <Typography sx={{fontSize: 13, color: "text.secondary", mb: 1}}>
              {examplesS}
            </Typography>
            <TextField
              fullWidth
              label={<Typography variant="body2">{nameS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={name}
              onChange={handleNameChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{cancelS}</Button>
          <Button onClick={handleSave} variant="contained">
            {saveS}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
