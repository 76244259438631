import React from "react";

import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";
import FileSelectorButton from "./FileSelectorButton";

export default function CardFileSelector({
  file,
  onFileChange,
  accept,
  buttonName,
  onClose,
}) {
  // string

  const noFileS = "- ";

  // helpers

  const label = file?.name ? file.name : noFileS;
  return (
    <Box
      sx={{
        p: 1,
        width: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        bgcolor: "background.default",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      {onClose && (
        <IconButton
          size="small"
          sx={{position: "absolute", top: "4px", right: "4px"}}
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
      )}
      <Box sx={{my: 1}}>
        <Typography variant="body2">{label}</Typography>
      </Box>
      <FileSelectorButton
        onFileChange={onFileChange}
        accept={accept}
        buttonName={buttonName}
      />
    </Box>
  );
}
