import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getPricesFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});

  const newPrices = {};
  const otherWs = new Set(["data", "zones", "éléments"]);
  wb.worksheets.forEach((worksheet) => {
    let puIdx = 5;
    const wn = worksheet.name;
    if (!otherWs.has(wn) && !wn.endsWith(" par zones")) {
      newPrices[wn] = {};
      const rowCount = worksheet.rowCount;
      const columnsRow = worksheet.getRow(2);
      for (let i = 1; i <= columnsRow.values.length; i++) {
        if (columnsRow.getCell(i).value === "P.U.") {
          puIdx = i;
        }
      }
      const rows = worksheet.getRows(3, rowCount - 1).values();
      for (let row of rows) {
        const num = row.getCell(1).value;
        const pu = row.getCell(puIdx).value;
        newPrices[wn][num] = pu;
      }
    }
  })

  return newPrices;
}
