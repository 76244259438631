import {client} from "API/capla360";

export const createBimappService = async ({accessToken, bimapp}) => {
  const newBimapp = {...bimapp};
  const sceneId = bimapp.sceneId;
  delete newBimapp.scene;
  newBimapp.scene = bimapp.sceneId;

  const response = await client.post(`scenes/${sceneId}/bimapps/`, newBimapp, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {item: response.data, sceneId};
};

export const fetchBimappsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/bimapps/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteBimapps = response.data;

  const bimapps = remoteBimapps.map((r) => {
    return {...r, sceneId: r.scene};
  });

  return {items: bimapps, sceneId};
};

export const updateBimappService = async ({accessToken, bimapp}) => {
  const bimappId = bimapp.id;
  console.log("updateBimAppService", bimapp);
  if (!bimappId) return bimapp;
  const response = await client.put(`/bimapps/${bimappId}`, bimapp, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const updatedBimapp = response.data;
  return {item: {...updatedBimapp, sceneId: updatedBimapp.scene}};
};

export const deleteBimappService = async ({accessToken, bimappId}) => {
  const response = await client.delete(`/bimapps/${bimappId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return bimappId;
};
