import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSelector} from "react-redux";

export default function useSurveysByScene(scene, options) {
  //
  let surveys = useSelector((s) => s.surveys.items);
  const scope = useSelectedScope();
  //

  surveys = surveys.filter((v) => v.sceneId === scene?.id);

  //
  if (options?.filterByScope && scope?.id && scope?.id !== "all") {
    surveys = surveys.filter(
      (g) => !scope?.data?.surveysIds || scope?.data?.surveysIds.includes(g.id)
    );
  }
  //

  if (options?.sortByName) {
    surveys = surveys.sort((a, b) => a.name.localeCompare(b.name));
  }
  if (options?.sortByScheduledAt) {
    surveys = surveys.sort((a, b) =>
      (a.scheduledAt ?? "-").localeCompare(b.scheduledAt ?? "-")
    );
  }

  return surveys;
}
