import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  fetchQuantitiesService,
  createQuantityService,
  fetchQuantitiesBySceneService,
} from "./services";

export const createQuantity = createAsyncThunk(
  "quantities/createQuantity",
  createQuantityService
);
export const fetchQuantities = createAsyncThunk(
  "quantities/fetchQuantities",
  fetchQuantitiesService
);
export const fetchQuantitiesByScene = createAsyncThunk(
  "quantities/fetchQuantitiesByScene",
  fetchQuantitiesBySceneService
);

export const quantitiesSlice = createSlice({
  name: "quantities",
  initialState: {
    items: [],
    quantitysetsInScope: [], // selected listings ids in quantities page.
    statusByScene: {}, // {sceneId:"loaded"}, used by useIssues to trigger fetch issuesByScene
  },
  reducers: {
    setQuantitysetsInScope: (state, action) => {
      state.quantitysetsInScope = action.payload;
    },
    addQuantitysetInScope: (state, action) => {
      state.quantitysetsInScope.push(action.payload);
    },
  },
  extraReducers: {
    [createQuantity.fulfilled]: (state, action) => {
      const newQuantity = action.payload;
      state.items.push(newQuantity);
    },
    [fetchQuantitiesByScene.fulfilled]: (state, action) => {
      const {items, sceneId} = action.payload;
      const itemsIds = items.map((item) => item.id);
      let newItems = state.items.filter((item) => !itemsIds.includes(item.id));
      state.items = [...newItems, ...items];
      state.statusByScene[sceneId] = "loaded";
    },
  },
});

export const {setQuantitysetsInScope, addQuantitysetInScope} =
  quantitiesSlice.actions;

export default quantitiesSlice.reducer;
