/*
 * used to get list of annotations from existing pdf
 */

import React from "react";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import {Box, Typography, Grid} from "@mui/material";

import SelectorElementType from "Features/elementTypes/components/SelectorElementType";
import IconElementType from "Features/elementTypes/components/IconElementType";
import FieldSubjectTypeVar from "./FieldSubjectTypeVar";
import FieldTextBasic from "Features/kvform/components/FieldTextBasic";

export default function ListRawMeasurementsBySubject({
  items,
  scene,
  onSubjectElementTypeChange,
  onSubjectTypeVarChange,
  onSubjectGeoCatChange,
}) {
  // strings

  const geoCatS = "Catégorie";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene).filter(
    (t) => !t.isTitle && !t.isAgg && !t.disabled
  );

  // handlers

  function handleElementTypeChange(elementType, subject) {
    onSubjectElementTypeChange({elementType, subject});
  }

  function handleSubjectTypeVarChange(subject, typeVar) {
    console.log("subjectTypeVar", subject, typeVar);
    onSubjectTypeVarChange({subject, typeVar});
  }

  function handleSubjectGeoCatChange(subject, geoCat) {
    onSubjectGeoCatChange({subject, geoCat});
  }

  return (
    <Box
      sx={{
        width: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {items.map((m) => {
        const {
          subject,
          color,
          isVoid,
          drawingShape,
          elementType,
          typeVar,
          geoCat,
        } = m;
        let type = elementType?.id ? elementType : null;

        return (
          <Grid
            key={m.subject}
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              pb: 0.5,
              px: 1,
            }}
          >
            <Grid sx={{display: "flex", alignItems: "center"}} item xs={4}>
              <IconElementType
                color={m.color}
                drawingShape={m.drawingShape}
                isVoid={m.isVoid}
              />
              <Box sx={{ml: 1}}>
                <Typography variant="body2">{m.subject}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {`x ${m.count}`}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={3}>
              <SelectorElementType
                elementType={type}
                elementTypes={elementTypes}
                onChange={(elementType) =>
                  handleElementTypeChange(elementType, subject)
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FieldTextBasic
                label={geoCatS}
                value={geoCat}
                onChange={(geoCat) =>
                  handleSubjectGeoCatChange(subject, geoCat)
                }
              />
            </Grid>

            <Grid item xs={3}>
              <FieldSubjectTypeVar
                code={typeVar?.code}
                value={typeVar?.value}
                onChange={(typeVar) =>
                  handleSubjectTypeVarChange(subject, typeVar)
                }
              />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
