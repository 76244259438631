import {Box, Paper, Typography, Icon} from "@mui/material";
import {ViewInAr as Measurements} from "@mui/icons-material";

import ContainerContextOverviewFilter from "./ContainerContextOverviewFilter";
import ButtonShrinkAppTopBar from "Features/navigation/components/ButtonShrinkAppTopBar";

export default function ContainerContextOverviewCounter({
  count,
  onCountClick,
  showFilters,
  filtersCount,
  filteredCount,
  onFilterClick,
  shrink,
  bgcolor,
}) {
  // helpers

  let label = "... élément";

  if (count === 0) label = "Aucun élément";
  if (count === 1) label = "1 élément";
  if (count > 1) label = `${count} éléments`;
  if (shrink) label = `x${count}`;

  const showFilteredLabel = filteredCount >= 0 && filteredCount !== count;

  // handlers

  function handleCountClick() {
    if (onCountClick) onCountClick();
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        //justifyContent: "space-between",
        alignItems: "center",
        color: "common.white",
        bgcolor: bgcolor ?? "secondary.main",
        borderRadius: 16,
        p: 0.5,
        pl: 1,
        //borderRadius: "4px 0 0 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Measurements sx={{fontSize: 14}} />
      </Box>

      <Box sx={{cursor: "pointer"}} onClick={handleCountClick}>
        {showFilters && showFilteredLabel ? (
          <Box sx={{display: "flex", alignItems: "baseline"}}>
            <Typography noWrap sx={{ml: 1, fontSize: 12}}>
              {filteredCount}
            </Typography>
            <Typography noWrap sx={{mr: 0.5, ml: 0.5, fontSize: 10}}>
              {`/${count}`}
            </Typography>
            <Typography noWrap sx={{mr: 1, fontSize: 12}}>
              {"éléments"}
            </Typography>
          </Box>
        ) : (
          <Typography noWrap sx={{mx: 1, fontSize: 12}}>
            {label}
          </Typography>
        )}
      </Box>
      {showFilters && (
        <ContainerContextOverviewFilter
          count={count}
          filteredCount={filteredCount}
          filtersCount={filtersCount}
          onClick={onFilterClick}
        />
      )}
    </Paper>
  );
}
