import {useSelector} from "react-redux";

export default function useSelectedRessourcesGroup(scene, options) {
  //
  // {id,name,ressources:[],fromScene}
  //
  const inEditMeasurements = options?.inEditMeasurements;
  //
  let group = null;

  // data
  const remoteGroups = useSelector((s) => s.ressources.ressourcesGroups);

  const proxyInRessources = useSelector(
    (s) => s.ressources.selectedRessourcesGroupProxy
  );
  const proxyInEditMeasurements = useSelector(
    (s) => s.ressources.selectedRessourceGroupProxyInEditMeasurements
  );
  //
  const ressourcesFromScene = Array.isArray(scene?.data?.ressources)
    ? scene.data.ressources
    : [];

  // helper - selectedProxy

  const proxy = inEditMeasurements
    ? proxyInEditMeasurements
    : proxyInRessources;

  // logic

  if (!proxy) {
    group = null;
  } else if (proxy.id === "undefined") {
    const typesWithNoGroup = ressourcesFromScene.filter((t) => !t.group);
    group = {
      id: "undefined",
      name: "-?-",
      fromScene: true,
      ressources: typesWithNoGroup,
    };
  } else if (proxy.fromScene) {
    const newRessources = ressourcesFromScene.filter((t) => {
      const test = t.group === proxy.name;
      return test;
    });
    group = {
      ...proxy,
      ressources: newRessources,
    };
  } else {
    group = remoteGroups.find((g) => g.id === proxy?.id);
  }
  return group;
}
