import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedSectorId} from "../sectorsSlice";
import useAddSceneSector from "../hooks/useAddSceneSector";

// import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsNewSectorInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const createS = "Ajouter";

  // data

  const tempSector = useSelector((s) => s.sectors.tempSector);
  const addSector = useAddSceneSector(scene);

  // handlers

  async function handleCreateClick() {
    const newSector = {id: nanoid(), ...tempSector};
    addSector(newSector);
    dispatch(setSelectedSectorId(newSector.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
