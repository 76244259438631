import React, {useState} from "react";

import {Box, Checkbox, Grid, Typography} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import DialogSelectorColor from "Components/DialogSelectorColor";

export default function FieldColor({
  name,
  value,
  onChange,
  locked,
  sizeKey = 12,
}) {
  const [open, setOpen] = useState(false);

  // handlers

  function handleOpen() {
    if (!locked) setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Grid
        container
        sx={{
          px: 1,
          py: 0.5,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Grid item xs={sizeKey}>
          <Typography sx={{fontSize: 13}}>{name}</Typography>
        </Grid>
        <Grid item xs={12 - sizeKey}>
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "end",
              bgcolor: value,
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            <Down fontSize="small" />
          </Box>
        </Grid>
      </Grid>
      <DialogSelectorColor
        open={open}
        color={value}
        onChange={onChange}
        onClose={handleClose}
      />
    </>
  );
}
