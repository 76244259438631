import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setSelectedFormGroup} from "Features/measurements/measurementsSlice";

import {ToggleButtonGroup, ToggleButton, Box, Typography} from "@mui/material";

export default function ToggleFormGroups({groups, group, onGroupChange}) {
  const dispatch = useDispatch();

  // handlers

  function handleFormGroupChange(v) {
    if (!v) return;
    onGroupChange(v);
  }

  return (
    <ToggleButtonGroup
      exclusive
      value={group}
      onChange={(e, v) => handleFormGroupChange(v)}
      size="small"
      sx={{
        height: "30px",
      }}
    >
      {groups.map((group) => {
        return (
          <ToggleButton key={group.code} value={group.code} sx={{}}>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography sx={{fontSize: 13}}>{group.label}</Typography>
            </Box>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
