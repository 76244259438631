import {useOutletContext} from "react-router-dom";

import {useSelector, useDispatch} from "react-redux";

import {Box, Grid, Typography, lighten} from "@mui/material";

import CardSceneOverview from "./CardSceneOverview";
import IconButtonMemberships from "Features/share/components/IconButtonMemberships";

import Stretch from "Features/ui/components/Stretch";
import ButtonDialogSync from "Features/scenes/components/ButtonDialogSync";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import SectionElementTypeGroupsInHome from "./SectionElementTypeGroupsInHome";

import PageHomeAdminElementTypeGroups from "./PageHomeAdminElementTypeGroups";
import PageHomeAdminPdfs from "./PageHomeAdminPdfs";
import PageHomeAdminPackages from "./PageHomeAdminPackages";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

import SectionDescription from "./SectionDescription";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";

export default function PageHomeAdmin() {
  const isStagingEnv = useIsStagingEnv();

  // strings

  const measurementsS = "éléments";

  //data

  const {scene, caplaEditor} = useOutletContext();
  const packages = usePackagesByScene(scene);
  const groups = useElementTypesGroupsProxyByScene(scene);
  const inputs = useInputsByScene(scene);

  // data - selection

  const selectedElementTypeGroupId = useSelector(
    (s) => s.boxhome.selectedElementTypeGroupId
  );

  const pdfIds = useSelector((s) => s.boxhome.selectedPdfIdsInHome);

  // helpers - noInput - noGroups

  const noGroup = groups.length === 0;
  const noInput = inputs.length === 0;

  // helper

  let count = 0;
  packages
    .filter((p) => p.enabled)
    .forEach((p) => {
      if (p.measCount) {
        count += p.measCount;
      } else if (p.measurementsData?.measurements) {
        count += p.measurementsData.measurements.length;
      }
    });

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        minWidth: 0,
      }}
    >
      {false && (
        <Box sx={{width: 1}}>
          <Grid container sx={{width: 1}}>
            <Grid item xs={5}>
              <CardSceneOverview scene={scene} />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              {/* <SectionTips scene={scene} /> */}
              <Box sx={{display: "flex", alignItems: "baseline"}}>
                <Typography variant="h2">{count}</Typography>
                <Typography variant="h4" sx={{ml: 1}}>
                  {measurementsS}
                </Typography>
              </Box>
              <Box sx={{display: "flex", alignItems: "center", pt: 3}}>
                <ButtonDialogSync scene={scene} />
                <Box sx={{p: 1}} />
                <IconButtonMemberships scene={scene} />
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          width: 1,
          height: 1,
          justifyItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            minWidth: 0,
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <Box
            sx={{
              p: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //width: 1,
            }}
          >
            <SectionDescription />
          </Box>
          <Box sx={{display: "flex", flexGrow: 1, minHeight: 0}}>
            <Box
              sx={{
                //flexGrow: 1,
                width: "50%",
                p: 3,
                display: "flex",
                flexDirection: "column",
                justifyItems: "stretch",
                minWidth: 0,
                minHeight: 0,
              }}
            >
              <PageHomeAdminElementTypeGroups scene={scene} />
            </Box>
            <Box
              sx={{
                //flexGrow: 1,
                visibility: noGroup ? "hidden" : "visible",
                width: "50%",
                p: 3,
                minWidth: 0,
                display: "flex",
                flexDirection: "column",
                justifyItems: "stretch",
                minHeight: 0,
              }}
            >
              <PageHomeAdminPdfs scene={scene} caplaEditor={caplaEditor} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            //flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            minHeight: 0,
            width: "30%",
            justifyItems: "stretch",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              p: 3,
              minWidth: 0,
              minHeight: 0,
              display: "flex",
              flexDirection: "column",
              justifyItems: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                p: 2,
                minWidth: 0,
                minHeight: 0,
                bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.9),
                border: (theme) =>
                  `1px solid ${lighten(theme.palette.secondary.main, 0.8)}`,
                borderRadius: "8px",
              }}
            >
              <PageHomeAdminPackages scene={scene} caplaEditor={caplaEditor} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
