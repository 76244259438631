import {client} from "API/capla360";
import cleanRessourcesGroupToSync from "./utils/cleanRessourcesGroupToSync";

export const createRessourcesGroupService = async ({
  accessToken,
  ressourcesGroup,
}) => {
  const sceneId = ressourcesGroup.sceneId;
  const serializedGroup = {
    ...ressourcesGroup,
    scene: sceneId,
  };

  const response = await client.post(
    `scenes/${sceneId}/ressourcesgroups/`,
    serializedGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

export const fetchRessourcesGroupsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/ressourcesgroups/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export const updateRessourcesGroupService = async ({
  accessToken,
  ressourcesGroup,
}) => {
  const sceneId = ressourcesGroup.sceneId;
  const cleanGroup = cleanRessourcesGroupToSync(ressourcesGroup);
  console.log("cleanGroup", cleanGroup);
  const response = await client.patch(
    `/scenes/${sceneId}/ressourcesgroups/${ressourcesGroup.id}/`,
    cleanGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

export const deleteRessourcesGroupService = async ({
  accessToken,
  ressourcesGroup,
}) => {
  const ressourcesGroupId = ressourcesGroup.id;
  const sceneId = ressourcesGroup.sceneId;
  console.log("try to delete ressourcesGroup", sceneId, ressourcesGroup);
  await client.delete(
    `/scenes/${sceneId}/ressourcesgroups/${ressourcesGroupId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {ressourcesGroupId};
};
