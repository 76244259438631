import {useState} from "react";
import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import {
  setSelectedRessourcesGroupProxy,
  createRessourcesGroup,
} from "../ressourcesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogCreateRessourcesGroup({
  scene,
  open,
  onClose,
  onCreated,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Nouveau tableau";

  const descriptionS = "Créez un nouveau tableau d'articles";
  const placeholder = "DPGF, Bilan Main d'Oeuvre, Commandes béton, etc";

  const nameS = "Nom";

  const createS = "Créer";

  // state

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }
  function handleClose() {
    setLoading(false);
    onClose();
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    //
    const newGroup = {};
    newGroup.name = name;
    newGroup.fromScene = false;
    newGroup.sceneId = scene?.id;
    newGroup.ressources = [];
    //
    const result = await dispatch(
      createRessourcesGroup({ressourcesGroup: newGroup, accessToken})
    );
    const {item} = unwrapResult(result);
    // v moved in the call back : the setSelection depends on the location of the dialog v

    // const group = {};
    // group.id = item.id;
    // group.name = item.name;
    // group.sceneId = item.sceneId;
    // group.fromScene = false;
    // dispatch(setSelectedRessourcesGroupProxy(group));
    //
    setLoading(false);
    if (onCreated)
      onCreated({id: item?.id, name: item?.name, fromScene: false});
    onClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{descriptionS}</Typography>
        <TextField
          fullWidth
          placeholder={placeholder}
          sx={{mt: 2}}
          label={<Typography variant="body2">{nameS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || name?.length === 0} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
