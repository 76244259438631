import {useDispatch} from "react-redux";

import {setName, setStepBasic, setStyle, setNum} from "../elementTyporSlice";

import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import IconElementType from "Features/elementTypes/components/IconElementType";
import SelectorStyle from "./SelectorStyle";

import ContainerRessources from "./ContainerRessources";

export default function ContainerEditElementType({
  elementType,
  onClick,
  variant,
}) {
  const dispatch = useDispatch();

  // string

  const namePlaceholder = "Nom de l'élément / ouvrage";
  const numPlaceholder = "x.x.x";
  const seeGroupS = "Voir la liste";

  //helpers

  const label = `Elément n°`;

  const clickable = Boolean(onClick);

  // handlers

  function handleClick() {
    if (clickable) onClick(elementType);
  }

  function handleNameChange(e) {
    dispatch(setName(e.target.value));
  }

  function handleNumChange(e) {
    dispatch(setNum(e.target.value));
  }

  function handleSeeGroup() {
    dispatch(setStepBasic("TREE"));
  }

  function handleShapeClick() {
    dispatch(setStepBasic("SHAPE"));
  }
  function handleStyleChange(style) {
    dispatch(setStyle(style));
  }

  return (
    <Box sx={{p: 1}}>
      <Box
        sx={{
          width: 1,
          p: 0.5,

          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SelectorStyle
          style={elementType?.style}
          onChange={handleStyleChange}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: variant === "compact" ? 1 : 2,
          px: 2,
        }}
      >
        <Paper
          elevation={12}
          sx={{
            width: 1,
            p: variant === "compact" ? 0.5 : 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            ...(clickable && {cursor: "pointer"}),
          }}
          onClick={handleClick}
        >
          <Box
            sx={{minWidth: 0, display: "flex", alignItems: "center", width: 1}}
          >
            <IconButton
              onClick={handleShapeClick}
              sx={{
                height: "36px",
                width: "36px",
                visibility: elementType?.isTitle ? "hidden" : "visible",
                border: `2px solid ${elementType.color}`,
              }}
            >
              <IconElementType {...elementType} />
            </IconButton>
            {/* <Typography variant="body2" sx={{ml: 1}}>
              {elementType?.name}
            </Typography> */}
            <Box sx={{flexGrow: 1, ml: 1}}>
              <TextField
                size="small"
                placeholder={namePlaceholder}
                fullWidth
                value={elementType?.name}
                onChange={handleNameChange}
                InputProps={{
                  sx: {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
                }}
              />
            </Box>
          </Box>
          {clickable ? <Down color="action" /> : <Box />}
        </Paper>
      </Box>
    </Box>
  );
}
