import { useState, useEffect } from "react";

import {Grid, Typography, InputBase, Box} from "@mui/material";

export default function FieldZoneScale({zone, onZoneChange}) {

  // strings

  const scaleS = "Scale";

  // props

  const scale = zone.scale;

  // helper

  const test = scale && !isNaN(scale) && !isNaN(parseFloat(scale));
  const initScale = test ? `1:${Number((scale * 72.0 / 0.0254).toFixed(4))}` : "1:50";
  const [formatedScale, setFormatedScale] = useState(initScale);
  useEffect(() => setFormatedScale(initScale), [initScale]);

  // handlers

  function handleInputChange(e) {
    setFormatedScale(e.target.value);
    onZoneChange({...zone, formatedScale: e.target.value});
  }

  return (
    <Grid
      container
      sx={{
        px: 1,
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Grid
        xs={6}
        item
        sx={{
          px: 0,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{display: "flex", height: 1, alignItems: "center"}}>
          <Typography sx={{fontSize: 13}} noWrap>
            {scaleS}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={6} item sx={{px: 1}}>
        <InputBase
          value={formatedScale}
          onChange={handleInputChange}
          sx={{
            fontSize: 13,
            whiteSpace: "pre-line",
            py: 0,
            "& .MuiInputBase-input": {px: 0},
          }}
        />
      </Grid>
    </Grid>
  );
}
