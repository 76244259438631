import {useSelector} from "react-redux";

import {FormControlLabel, Checkbox, Typography} from "@mui/material";

export default function CheckboxEnableOptim({editor3d}) {
  // strings

  const enableString = "Hide edges to boost memory";

  // data

  const enabled = useSelector((state) => state.viewer3D.optimIsEnabled);

  // handlers

  function handleCheckboxChange(e) {
    const checked = e.target.checked;
    editor3d?.sceneEditor.setOptimIsEnabled(checked);
  }

  return (
    <FormControlLabel
      sx={{ml: 0.5}}
      control={
        <Checkbox
          size="small"
          onChange={handleCheckboxChange}
          checked={enabled}
        />
      }
      label={
        <Typography variant="body2" noWrap>
          {enableString}
        </Typography>
      }
    />
  );
}
