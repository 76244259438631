import React from "react";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormMarker({marker, onChange, marginTop, canEdit}) {
  // strings

  const descriptionS = "Description";
  const infoS = "Info";

  // kvf

  const template = {
    key: "kvfMarker",
    fields: [
      //{key: "num", type: "number", name: numS},
      {key: "info", type: "section", name: infoS},
      {key: "description", type: "text", name: descriptionS},
      {key: "x", type: "number", name: "x"},
      {key: "y", type: "number", name: "y"},
      {key: "z", type: "number", name: "z"},
    ],
  };

  // helpers

  const item = marker ? {...marker, y: -marker.z, z: marker.y} : {};

  // handlers

  function handleChange(marker) {
    console.log("marker", marker);
    onChange(marker);
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleChange}
      marginTop={marginTop}
      canEdit={canEdit}
    />
  );
}
