import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogRenameMultiMeasurements from "./DialogRenameMultiMeasurements";
import DialogCopyMeasurements from "./DialogCopyMeasurements";
import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";
import { setSelectedMeasurementIds } from "../measurementsSlice";

export default function ButtonMoreActionsMultiMeasurements({
  scene,
  caplaEditor,
  measurementIds,
}) {
  const dispatch = useDispatch();

  // strings

  const renameS = "Renommer les éléments";
  const copyS = "Copier les éléments";
  const deleteS = "Supprimer les éléments";

  // data

  const allMeasurements = useSelector((s) => s.measurements.items);
  const model = useSelectedMeasurementsModel();

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);

  // helpers - measurements

  const measurements = allMeasurements.filter((m) =>
    measurementIds.includes(m.id)
  );

  // helpers - handlers - rename

  function handleRename() {
    setOpenRenameDialog(true);
    setAnchorEl(null);
  }
  function handleCloseRenameDialog() {
    setOpenRenameDialog(false);
  }

  // helpers - handlers - copy

  function handleCopy() {
    setOpenCopyDialog(true);
    setAnchorEl(null);
  }
  function handleCloseCopyDialog() {
    setOpenCopyDialog(false);
  }

  // handlers - delete callback

  // function handleMeasurementsDeleted() {
  //   const mnger = caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;
  //   dispatch(setSelectedMeasurementIds([]));
  //   // onClose();
  //   mnger?.setIsEditing(false);
  // }

  //  helpers - handlers - delete

  function handleDelete() {
    // 3D
    measurements.forEach((measurement) => {
      const entity = caplaEditor?.editor3d?.getEntity(measurement.measurementsModelId);
      entity?.deleteMeasurement(measurement.id);
      // // state
      // dispatch(deleteMeasurement(measurement.id));
    });

    // state and models
    caplaEditor?.measDataManager.deleteMeasurements(measurements);

    // delete PDF annotations
    const mnger = caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;
    mnger?.deleteMeasurements(measurements.map((m) => m.id));

    // callback
    // handleMeasurementsDeleted();
    dispatch(setSelectedMeasurementIds([]));
    mnger?.setIsEditing(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "COPY",
      label: copyS,
      handler: handleCopy,
    },
    {
      id: "RENAME",
      label: renameS,
      handler: handleRename,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <DialogCopyMeasurements
        scene={scene}
        open={openCopyDialog}
        onClose={handleCloseCopyDialog}
        measurements={measurements}
      />
      <DialogRenameMultiMeasurements
        open={openRenameDialog}
        onClose={handleCloseRenameDialog}
        measurementIds={measurementIds}
        caplaEditor={caplaEditor}
      />
    </>
  );
}
