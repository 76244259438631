import {forwardRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchRemoteModel} from "Features/viewer3D/viewer3DSlice";

import {setOpenDialogFsOpenScope} from "../overviewerSlice";

import {Dialog, IconButton, Typography, Box, Fade} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import theme from "Styles/theme";
import {Close} from "@mui/icons-material";

import useScopesByScene from "Features/scopes/hooks/useScopesByScene";
import SectionSelectorScopeGrid from "./SectionSelectorScopeGrid";
import SectionLoadingScene from "./SectionLoadingScene";
import ContainerLoadingBimbox from "./ContainerLoadingBimbox";
import {useParams} from "react-router-dom";
import ContainerScopeInGrid from "Features/scopes/components/ContainerScopeInGrid";
import SectionModelsInScopeLoadingOverview from "./SectionModelsInScopeLoadingOverview";
import ButtonBackToSelectScope from "./ButtonBackToSelectScope";
import ButtonOpenScope from "./ButtonOpenScope";

import useAccessToken from "Features/auth/useAccessToken";
import getModelsInScope from "Features/scopes/utils/getModelsInScope";
import getModelLoadingStatusNum from "../utils/getModelLoadingStatusNum";
import getScopeIsInState from "../utils/getScopeIsInState";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{enter: 500, exit: 500}} />;
});

export default function DialogFsOpenScope({scene}) {
  const dispatch = useDispatch();
  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const sceneClientId = scene?.clientId;

  // strings

  const packageS = "Calque de repérage";
  const countS = "# éléments";

  // data

  const open = useSelector((s) => s.overviewer.openDialogFsOpenScope);
  const scopes = useScopesByScene(scene, {addScopeAll: true});
  const scopeIdToOpen = useSelector((s) => s.overviewer.scopeIdToOpen);
  const loadingMap = useSelector((s) => s.overviewer.loadingStatusModelIdsMap);
  const models = useSelector((s) => s.viewer3D.models);

  // helpers

  const scopeToOpen = scopes.find((s) => s.id === scopeIdToOpen);

  const modelIdsInState = loadingMap["IN_STATE"];

  const modelsInScope = getModelsInScope(models, scopeToOpen);

  const canOpen = getScopeIsInState(
    modelsInScope.map((m) => m.id),
    loadingMap
  );

  // handlers

  function handleClose() {
    dispatch(setOpenDialogFsOpenScope(false));
  }

  // effect - fetch models

  useEffect(() => {
    if (open && scopeIdToOpen !== "all") {
      for (let i = 0; i < modelsInScope.length; i++) {
        const model = modelsInScope[i];
        const num = getModelLoadingStatusNum(model.id, loadingMap);
        if (num < 2) {
          dispatch(
            fetchRemoteModel({
              accessToken,
              remoteId: model.remoteId,
              sceneClientId,
            })
          );
        }
      }
    }
  }, [open, scopeIdToOpen]);

  return (
    <Dialog open={Boolean(open)} fullScreen TransitionComponent={Transition}>
      <Box
        sx={{
          display: "flex",
          width: 1,
          height: 1,
          flexDirection: "column",
          bgcolor: "common.caplaBlack",
          position: "relative",
          color: "common.white",
        }}
      >
        <Box sx={{color: "grey:900"}}>
          <IconButton
            color="inherit"
            onClick={handleClose}
            sx={{position: "absolute", top: 8, right: 8, zIndex: 10}}
          >
            <Close />
          </IconButton>
        </Box>
        <SectionLoadingScene scene={scene} />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box>
                <ContainerScopeInGrid
                  scene={scene}
                  scope={scopeToOpen}
                  hideAction
                />

                <ButtonBackToSelectScope />
              </Box>

              <Box sx={{width: 280, p: 1, ml: 2}}>
                <Box
                  sx={{
                    display: "flex",
                    borderBottom: "1px solid white",
                    width: 1,
                    justifyContent: "space-between",
                    p: 1,
                    mb: 1,
                  }}
                >
                  <Typography sx={{fontSize: 13}}>{packageS}</Typography>
                  <Typography sx={{fontSize: 13}}>{countS}</Typography>
                </Box>
                <Box sx={{maxHeight: 300, overflowY: "auto"}}>
                  <SectionModelsInScopeLoadingOverview scope={scopeToOpen} />
                </Box>

                <Box sx={{display: "flex", justifyContent: "end", mt: 3}}>
                  <ButtonOpenScope scope={scopeToOpen} canOpen={canOpen} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
