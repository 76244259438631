import {useSelector} from "react-redux";

export default function useSelectedArticle(scene) {
  const articlesMap = useSelector((s) => s.articles.articlesMap);
  const subArticlesMap = useSelector((s) => s.articles.subArticlesMap);

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const selectedArticle = articlesMap[selectedArticleId];

  const subArticlesArray = [];
  Object.entries(subArticlesMap).forEach(([articleId, subMap]) => {
    Object.entries(subMap).forEach(([subArticleId, subArticle]) => {
      subArticlesArray.push({articleId, subArticleId, subArticle});
    });
  });

  const selectedSubArticleItem = subArticlesArray.find(
    (item) => item.subArticleId === selectedArticleId
  );
  //
  let result;

  if (selectedArticle) {
    // updates
    const updates = selectedArticle?.updates ?? {};
    //
    result = {...selectedArticle, ...updates};
  } else if (selectedSubArticleItem) {
    result = selectedSubArticleItem.subArticle;
  } else {
    result = null;
  }

  return result;
}
