import {Button, Box} from "@mui/material";

export default function ButtonLoadElements({model, editor3d}) {
  //strings

  const loadString = "Load elements in 3D scene";

  // handlers

  function handleClick() {
    console.log(model.elements.items);
    const entity = editor3d?.getEntity(model.id);
    entity?.loadElements();
  }

  return (
    <Box sx={{width: 1}}>
      <Button size="small" onClick={handleClick}>
        {loadString}
      </Button>
    </Box>
  );
}
