import {useDispatch, useSelector} from "react-redux";

import {Edit, Add, LibraryAdd as AddGroup} from "@mui/icons-material";

export default function useActionsRoomsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedRoomsGroupId = useSelector((s) => s.rooms.selectedRoomsGroupId);

  // actions map

  const actionsMap = {
    createRoomsGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Arborescence de pièces",
      label: "Créer une arborescence de pièces",
    },
    deleteRoomsGroup: {
      label: "Supprimer les locaux",
      disabled: !selectedRoomsGroupId,
    },
    createRoom: {
      icon: <Add fontSize="small" />,
      name: "Pièce",
      label: "Créer une pièce",
    },
    editRoomsGroup: {
      icon: <Edit fontSize="small" />,
      name: "Editer",
      label: "Editer l'arborescene",
    },
  };

  return actionsMap;
}
