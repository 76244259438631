import {useSelector} from "react-redux";

import {format} from "date-fns";

// import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";
import useFilteredMeasurementsFromPackages from "./useFilteredMeasurementsFromPackages";
import useSceneMeasurements from "./useSceneMeasurements";

import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function useMeasurementsForDataGrid(scene, mode) {
  // data

  // let [measurements] = useFilteredSceneMeasurements(scene, mode);
  let measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });
  const allMeasurements = useSceneMeasurements(scene);

  const models = useSelector((s) => s.viewer3D.models);

  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);

  const elementTypes = useElementTypesBySceneProxy(scene);

  // helpers

  const measurementsById = {};
  for (const item of allMeasurements) {
    measurementsById[item.id] = item;
  }
  const elementTypesById = {};
  for (const item of elementTypes) {
    elementTypesById[item.id] = item;
  }
  const modelsById = {};
  for (const item of models) {
    modelsById[item.id] = item;
  }
  const sectorsById = {};
  for (const item of sectors) {
    sectorsById[item.id] = item;
  }
  const roomsById = {};
  for (const item of rooms) {
    roomsById[item.id] = item;
  }

  // helpers - dateget

  function getDateTime(dateData) {
    if (dateData && dateData !== "-" && dateData !== "null") {
      const date = new Date(dateData);
      const formattedDate = format(date, "dd/MM/yy");
      return `${formattedDate}`;
    }
  }

  // helper - model & categories

  function getMeasurementHeight(m) {
    let h = m.height;
    if (!h) h = 0;
    if (typeof h === "number") {
      return parseFloat(h.toFixed(3));
    } else if (typeof h === "string") {
      h.replace(",", ".");
      return parseFloat(h);
    }
  }

  function getMeasurementCode(m) {
    return m.codeName;
  }

  function getMeasurementZInf(m) {
    let zInf = m.zInf;
    if (typeof zInf !== "number" && typeof m.zInf === "number") {
      const h = m.height ? m.height : 0;
      zInf = m.zSup - h;
    }
    if (isNaN(zInf) || !zInf) zInf = 0;
    return parseFloat(zInf.toFixed(3));
  }

  function getMeasurementZSup(m) {
    try {
      if (!m) return;
      let zSup = m.zSup;
      if (typeof zSup !== "number" && typeof m.zInf === "number") {
        const h = m.height ? m.height : 0;
        zSup = h + m.zInf;
      }
      if (isNaN(zSup) || !zSup) zSup = 0;
      return parseFloat(zSup.toFixed(3));
    } catch (e) {
      console.log("error zSup", m, m?.zSup);
    }
  }

  function getMeasurementsModelName(m) {
    const model = modelsById[m.measurementsModelId];
    return model?.name ? model.name : "-";
  }

  function getSectorCode(m) {
    const sector = sectorsById[m.sectorId];
    return sector?.code ? sector.code : "-";
  }

  function getRoomName(m) {
    const room = roomsById[m.roomId];
    return room?.name ? room.name : "-";
  }

  function getMeasurementLength(m) {
    let length = m.length;
    if (isNaN(length) || !length) length = 0;
    return parseFloat(length.toFixed(3));
  }

  function getQuantityByUnit(m, unit, isVoid) {
    const factor = isVoid ? -1 : 1;
    switch (unit) {
      case "UN":
        return m.count;
      case "ML":
        return factor * m.length;
      case "M2":
        return factor * m.area;
      case "M3":
        return factor * m.volume;
    }
  }

  // export

  const voids = [];
  let rows = measurements
    .map((measurement, index) => {
      const type = elementTypesById[measurement.elementTypeId];
      if (type && Array.isArray(measurement?.voids)) {
        measurement.voids.forEach((voidId) => {
          const voidItem = measurementsById[voidId];
          if (voidItem) {
            const v = {
              id: measurement.id + "-" + voidItem.id,
              code: voidItem.codeName + " <--[ " + measurement.codeName + " ]",
              type_num: type.num,
              type_name: type.name,
              sector: getSectorCode(measurement),
              count: voidItem.count,
              length: voidItem.length,
              area: -1 * voidItem.area,
              volume: -1 * voidItem.volume,
              quantity_unit: type.unit,
              quantity_value: getQuantityByUnit(voidItem, type.unit, true),
              isVoid: true,
            };
            voids.push(v);
          }
        });
      }
      return {
        id: measurement.id,
        index: index + 1,
        code: getMeasurementCode(measurement),
        list: getMeasurementsModelName(measurement),
        measurementsModelId: measurement.measurementsModelId,
        type_num: type?.num,
        type_name: type?.name,
        height_m: getMeasurementHeight(measurement),
        length_m: getMeasurementLength(measurement),
        zInf_m: getMeasurementZInf(measurement),
        zSup_m: getMeasurementZSup(measurement),
        sector: getSectorCode(measurement),
        room: getRoomName(measurement),
        built_at: getDateTime(measurement.builtAt),
        quantity_unit: type?.unit,
        quantity_value: getQuantityByUnit(measurement, type?.unit, false),
        isVoid: measurement.isVoid,
        voidsNb: measurement.voids?.length,
      };
    })
    .sort((a, b) => a.index - b.index);

  rows = [...rows, ...voids];

  return rows;
}
