import React, {useState} from "react";

import {nanoid} from "@reduxjs/toolkit";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import useSceneModuleKvtBasicMap from "Features/buildbox/hooks/useSceneModuleKvtBasicMap";

import useAddSceneRoom from "Features/rooms/hooks/useAddSceneRoom";
import useAddSceneSector from "Features/sectors/hooks/useAddSceneSector";

import {Box, Dialog, DialogTitle, DialogActions, Button} from "@mui/material";

import KeyValueFormBasic from "Features/kvform/components/KeyValueFormBasic";

export default function DialogCreateEntity({
  scene,
  open,
  onClose,
  sceneModule,
}) {
  // strings

  const createS = "Créer";
  const cancelS = "Annuler";

  // data

  const kvtMap = useSceneModuleKvtBasicMap();
  const nameMap = useSceneModuleNameMap();

  const addSector = useAddSceneSector(scene);
  const addRoom = useAddSceneRoom(scene);

  // state

  const [entity, setEntity] = useState({});

  // helper

  const template = kvtMap[sceneModule];
  const title = nameMap[sceneModule];

  // handlers

  function handleClose() {
    setEntity({});
    onClose();
  }
  function handleEntityChange(entity) {
    setEntity(entity);
  }

  function handleCreate() {
    const newEntity = {id: nanoid(), ...entity};
    if (sceneModule === "SECTORS") addSector(newEntity);
    if (sceneModule === "ROOMS") {
      addRoom({...newEntity, num: "0"});
    }
    onClose();
  }

  function handleCancel() {
    setEntity({});
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <Box sx={{width: 600, py: 2, px: 1}}>
        <KeyValueFormBasic
          template={template}
          item={entity}
          onChange={handleEntityChange}
        />
      </Box>

      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleCreate} variant="contained">
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
