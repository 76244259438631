import {Box, Typography, Divider} from "@mui/material";

import HeaderPrice from "./HeaderPrice";

export default function SectionPrice({price, offerKey}) {
  // helpers

  const tva = (price * 20) / 100;
  const total = tva + price;

  const priceLabelS = "Sous-total";
  const tvaLabelS = "TVA (20%)";
  const totalLabelS = "Total";

  const tvaValue = tva.toFixed(2) + " €";
  const totalValue = total.toFixed(2) + " €";
  const priceValue = price.toFixed(2) + " €";

  return (
    <Box>
      <HeaderPrice offerKey={offerKey} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography>{priceLabelS}</Typography>
        <Typography>{priceValue}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          pt: 0,
        }}
      >
        <Typography>{tvaLabelS}</Typography>
        <Typography>{tvaValue}</Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          py: 2,
        }}
      >
        <Typography variant="h6">{totalLabelS}</Typography>
        <Typography variant="h6">{totalValue}</Typography>
      </Box>
    </Box>
  );
}
