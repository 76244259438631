import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import {DataGridPro, frFR, GridActionsCellItem} from "@mui/x-data-grid-pro";
import {
  ArrowBackIos as Left,
  ArrowForwardIos as Right,
} from "@mui/icons-material";

import DatagridRessourcesContextMenu from "./DatagridRessourcesContextMenu";
import IconButtonDialogDeleteRessource from "./IconButtonDialogDeleteRessource";

import {
  setOpenContextMenu,
  setSelectedRessourceIds,
  updateRessourceInEditedRessources,
  moveEditedRessource,
} from "../ressourcesSlice";
import useRessourcesAndTypesByScene from "../hooks/useRessourcesAndTypesByScene";
import ressourcesForDataGrid from "../utils/ressourcesForDataGrid";
import ressourceFromDatagridToState from "../utils/ressourceFromDatagridToState";
// import useRessourcesInLeftPanel from "../hooks/useRessourcesInLeftPanel";
import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridRessourcesGroup({onChange, scene}) {
  const dispatch = useDispatch();

  // strings

  const rankS = "Rang";
  const numS = "Num";
  const nameS = "Nom";
  const titleS = "Titre";
  const quantityS = "Quantité";
  const unitS = "Unité";
  const typeS = "Types";
  const decS = "x,xx";

  // data

  const ressources = useSelector((s) => s.ressources.editedRessources);

  const datagridIsInEditMode = useSelector(
    (s) => s.ressources.datagridIsInEditMode
  );

  const showReorderHelpers = useSelector(
    (s) => s.ressources.showReorderHelpers
  );

  let ressources2 = useMeasurementsByRessourceForDatagrid(scene, "ONE_MODEL");

  const selectedRessourceIds = useSelector(
    (s) => s.ressources.selectedRessourceIds
  );

  const selectedRessourceGroup = useSelector(
    (s) => s.ressources.selectedRessourceGroup
  );

  const editedRessources = useSelector((s) => s.ressources.editedRessources);

  // local states

  const [contextMenu, setContextMenu] = useState(null);

  // selection

  const selectionModel = [...selectedRessourceIds];

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    if (selectionId === selectedRessourceIds[0] && selection.length === 1) {
      dispatch(setSelectedRessourceIds([]));
    } else {
      dispatch(setSelectedRessourceIds(selection));
    }
  }

  // grid - rows

  // if (selectedRessourceGroup) {
  //   ressources = ressources.filter((r) => r.group === selectedRessourceGroup);
  //   ressources2 = ressources2.filter((r) => r.group === selectedRessourceGroup);
  // }

  // ressources = ressources.map((r, i) => {
  //   const rQty = ressources2[i];
  //   const quantity =
  //     typeof rQty.hardcodedQty === "number" ? rQty.hardcodedQty : rQty.quantity;
  //   return {...r, quantity, rank: rQty.rank};
  // });

  const rows = ressourcesForDataGrid(ressources, {
    sortRessourcesByNum: !datagridIsInEditMode,
  });

  // handlers

  function handleChangeRessourceOrder(ressourceId, side) {
    let newRessource = {...editedRessources.find((r) => r.id === ressourceId)};
    let num = newRessource.num;
    if (side === "left") {
      const numArray = num.split(".");
      if (numArray.length !== 1) {
        numArray.pop();
        num = numArray.join(".");
      }
    } else {
      num = num + ".1";
    }
    newRessource.num = num;
    dispatch(
      updateRessourceInEditedRessources({
        ressource: newRessource,
        updateNums: true,
      })
    );
  }

  function handleProcessRowUpdate(updatedRow) {
    const ressource = ressourceFromDatagridToState(updatedRow);
    dispatch(updateRessourceInEditedRessources({ressource, updateNums: true}));
    return updatedRow;
  }

  // grid - columns

  const numValueGetter = (params) => {
    return params.row.hideNum === true ? "" : params.row.num;
  };

  const quantityValueGetter = (params) => {
    return params.row.hardcodedQty
      ? params.row.hardcodedQty
      : params.row.quantity;
  };

  const columns = [
    {
      field: "rank",
      headerName: rankS,
      // width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Left size="small" />}
          label="<="
          onClick={() => handleChangeRessourceOrder(params.row.id, "left")}
        />,
        <GridActionsCellItem
          icon={<Right size="small" />}
          label="=>"
          onClick={() => handleChangeRessourceOrder(params.row.id, "right")}
        />,
      ],
    },
    {
      field: "num",
      headerName: numS,
      width: 100,
      editable: datagridIsInEditMode,
      valueGetter: numValueGetter,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
      editable: datagridIsInEditMode,
    },
    {
      field: "isTitle",
      headerName: titleS,
      type: "boolean",
      editable: datagridIsInEditMode,
    },
    {
      field: "quantity",
      headerName: quantityS,
      width: 70,
      editable: false,
      valueGetter: quantityValueGetter,
    },
    {
      field: "unit",
      headerName: unitS,
      width: 70,
      editable: datagridIsInEditMode,
    },
    {
      field: "dec",
      headerName: decS,
      width: 50,
      editable: datagridIsInEditMode,
    },
    {
      field: "types",
      headerName: typeS,
      width: 70,
    },
    {
      field: "delete",
      type: "actions",
      width: 40,
      headerName: "",
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <IconButtonDialogDeleteRessource ressourceId={params.row.id} />
      ),
    },
  ];

  // handlers

  function handleOpenContextMenu(event) {
    if (datagridIsInEditMode) {
      dispatch(setOpenContextMenu(true));
      event.preventDefault();

      const rightClickedRow = event.currentTarget.getAttribute("data-id");
      if (rightClickedRow) {
        //dispatch(addIdToSelectedRessourceIds(rightClickedRow));
        dispatch(setSelectedRessourceIds([rightClickedRow]));
      }

      setContextMenu(
        contextMenu === null
          ? {mouseX: event.clientX - 2, mouseY: event.clientY - 4}
          : null
      );
    }
  }

  function handleContextMenuClose() {
    setContextMenu(null);
  }

  function handleRowOrderChange(params) {
    const {oldIndex, targetIndex, row} = params;
    const movedEditedRessourceId = row?.id;
    if (targetIndex > 0) {
      const newRows = [...rows];
      newRows.splice(oldIndex, 1);
      newRows.splice(targetIndex - 1, row);
      const prevEditedRessourceId = newRows[targetIndex - 1].id;

      dispatch(
        moveEditedRessource({
          ressourceId: movedEditedRessourceId,
          prevRessourceId: prevEditedRessourceId,
          updateNums: true,
        })
      );
    } else {
      dispatch(
        moveEditedRessource({
          ressourceId: movedEditedRessourceId,
          isFirstItem: true,
          updateNums: true,
        })
      );
    }
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--NoItem": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGridPro
        processRowUpdate={handleProcessRowUpdate}
        columnVisibilityModel={{
          rank: datagridIsInEditMode && showReorderHelpers,
          isTitle: datagridIsInEditMode,
          types: !datagridIsInEditMode,
          delete: datagridIsInEditMode,
        }}
        rowReordering={datagridIsInEditMode && showReorderHelpers}
        onRowOrderChange={handleRowOrderChange}
        slotProps={{
          row: {
            onContextMenu: handleOpenContextMenu,
            style: {
              cursor: datagridIsInEditMode ? "context-menu" : "inherit",
            },
          },
        }}
        //treeData
        //getTreeDataPath={(row) => row.num.split(".")}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
        getRowClassName={(params) => {
          if (
            params.row.style === "title1" ||
            (params.row.isTitle &&
              params.row.style === undefined &&
              params.row.rank === 1)
          )
            return `super-app-theme--Title1`;
          if (
            params.row.style === "title2" ||
            (params.row.isTitle &&
              params.row.style === undefined &&
              params.row.rank === 2)
          )
            return `super-app-theme--Title2`;
          if (params.row.style === "detail") return `super-app-theme--Child`;
          if (params.row.count === 0) return `super-app-theme--NoItem`;
        }}
      />

      <DatagridRessourcesContextMenu
        scene={scene}
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorPosition={
          contextMenu !== null
            ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
            : undefined
        }
        nums={rows.map((row) => row.num)}
      />
    </Box>
  );
}
