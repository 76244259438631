import {useSelector} from "react-redux";
import { useParams } from "react-router-dom";

import {Box, Typography, Tooltip, Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
// import {darken, lighten} from "@mui/material/styles";
import {ExpandMore} from "@mui/icons-material";

// import {setExpandLeftSelectionDetail} from "Features/leftPanel/leftPanelSlice";
import useIssuesByScene from "Features/issues/useIssuesByScene";
// import SectionRessourceGlobalQuantity from "Features/ressources/components/SectionRessourceGlobalQuantity";
// import SectionRessourceQtyVsRef from "Features/ressources/components/SectionRessourceQtyVsRef";
import SectionRessourceQuantitiesBySector from "Features/ressources/components/SectionRessourceQuantitiesBySector";
import SectionRessourceLinkedIssues from "Features/ressources/components/SectionRessourceLinkedIssues";
import SectionRessourceLinkedIssuesExtractedText from "Features/ressources/components/SectionRessourceLinkedIssuesExtractedText";
import SectionRessourceZonesInSelectionPanel from "Features/ressources/components/SectionRessourceZonesInSelectionPanel";
// import SectionRessourceLinkedIssuesImages from "Features/ressources/components/SectionRessourceLinkedIssuesImages";
// import SectionRessourceExtractedText from "Features/ressources/components/SectionRessourceExtractedText";
import ButtonFocusRessourceMeasurementsIn3d from "Features/ressources/components/ButtonFocusRessourceMeasurementsIn3d";
import ToggleMeasurementsVisibility from "Features/measurements/components/ToggleMeasurementsVisibility";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import SectionRessourceTypesInSelectionPanel from "Features/ressources/components/SectionRessourceTypesInSelectionPanel";
import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";
import ButtonFocusOnExtractedText from "Features/pdf/components/ButtonFocusOnExtractedText";
import GridRessourceQuantities from "Features/ressources/components/GridRessourceQuantities";
// import ImageBasic from "Features/images/components/ImageBasic";
import ChipRessourceWebUrl from "Features/ressources/components/ChipRessourceWebUrl";
import ImagePreview from "Features/images/components/ImagePreview";

// const getBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function SectionHomeRessourceInSelectionPanel({
  scene,
  caplaEditor,
  ressource,
  viewer,
}) {
  // const dispatch = useDispatch();
  const {shareLink} = useParams();

  // strings

  const elementsS = "Eléments";
  const issuesS = "Notes";
  const zonesS = "Fonds de plan";
  const showQtiesBySectorS = "Qté par secteur";
 
  // data

  const issues = useIssuesByScene(scene?.id);
  // const expand = useSelector((s) => s.leftPanel.expandLeftSelectionDetail);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    true,
    true
  );
  const quantities = useMeasurementsByRessourceForDatagrid(scene, mode, 5);
  const pricingTable = useSelector((s) => s.pricing.pricingTable);
  // const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // helpers

  // const noMeasurements = fms.length === 0;
  const res = quantities.find((q) => q.id === ressource.id);

  ressource = {...ressource, quantity: res?.quantity};
  const refQty = ressource.refQty ? ressource.refQty : 1;
  const unit = ressource.unit;

  const gap = ((ressource.quantity - refQty) / refQty) * 100;
  const gapValueS = gap.toFixed(1);

  const ressourceIssues = issues.filter((issue) =>
    issue.linkedTo?.ressources?.includes(ressource.id)
  );

  const hasIssues = ressourceIssues.length > 0;

  let pu = null;
  if (shareLink in pricingTable && ressource) {
    pu = pricingTable[shareLink][ressource.id];
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          p: 1,
          pl: 2,
          display: "flex",
          alignItems: "top",
          width: 1,
          //justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="h6">{ressource.name}</Typography>
          <Box
            sx={{
              mt: 0.5,
              width: 1,
              display: "flex",
              alignItems: "center",
              "&>*:not(:last-child)": {mr: 1},
            }}
          >
            <ButtonFocusRessourceMeasurementsIn3d
              ressource={ressource}
              editor3d={caplaEditor?.editor3d}
              scene={scene}
            />
            {ressource.extractedText && (
              <ButtonFocusOnExtractedText
                pdfExtractedText={ressource.extractedText}
                editorPdf={caplaEditor?.editorPdf}
                scene={scene}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{width: 1, mt: 1}}>
        {!ressource.isTitle && (
          <GridRessourceQuantities
            scene={scene}
            ressourceWithQuantities={ressource}
            pu={pu}
          />
        )}
        {ressource.refQty && (
          <Box sx={{
            display: "flex",
            position: "relative",
            width: 1,
            bgcolor: (theme) => Math.abs(gap) > 5 ? 
              theme.palette.primary.lightest : "inherit",
            mb: 1,
          }}>
            {Math.abs(gap) > 5 && (
              <Box
                sx={{
                  position: "absolute",
                  // top: "-2px",
                  // right: "2px",
                  left: "3px",
                  top: "3px",
                  height: "8px",
                  width: "8px",
                  borderRadius: "4px",
                  bgcolor: "primary.flash",
                  zIndex: 2,
                }}
              />
            )}
            <Typography
              variant="body2"
              sx={{
                mx: 1,
                pl: 1,
                my: 0.25,
                fontWeight: Math.abs(gap) > 5 ? "bold" : "normal",
                color: "text.secondary",
              }}
            >
              {`Ecart avec réf (${refQty}${unit}) : ${gapValueS}%`}
            </Typography>
          </Box>
        )}
        {ressource.description && (
          <Box sx={{px: 1, py: 2}}>
            <Typography
              sx={{
                fontSize: 13,
                whiteSpace: "pre-line",
                color: "text.secondary",
                fontStyle: "italic",
              }}
            >
              {ressource.description}
            </Typography>
          </Box>
        )}
        {ressource.imageUrl && (
          <Box sx={{width: 1, height: 140}}>
            {/* <ImageBasic url={ressource.imageUrl} /> */}
            <ImagePreview
              src={ressource.imageUrl}
              width={"100%"}
              height={"100%"}
              // outlined
              detail
            />
          </Box>
        )}
        {ressource.webUrl && (
          <Tooltip title={ressource.webUrl}>
            <Box sx={{p: 0.5}}>
              <ChipRessourceWebUrl webUrl={ressource.webUrl} />
            </Box>
          </Tooltip>
        )}
      </Box>
      {ressource.quantity && (
        <Accordion disableGutters>
          <AccordionSummary
            id={showQtiesBySectorS}
            expandIcon={<ExpandMore />}
            sx={{
              borderTop: "1px solid #e2e3e7",
              borderBottom: "1px solid #e2e3e7",
            }}
          >
            <Typography variant="body2"
              sx={{fontWeight: "bold", color: "text.secondary"}}
            >
              {showQtiesBySectorS}
            </Typography>
          </AccordionSummary>
          <AccordionDetails  sx={{p: 0, bgcolor: "background.default"}}>
            <SectionRessourceQuantitiesBySector
              scene={scene}
              ressource={ressource}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {hasIssues && (
        <Accordion disableGutters>
          <AccordionSummary
            id={hasIssues.toString()}
            expandIcon={<ExpandMore />}
            sx={{
              borderTop: "1px solid #e2e3e7",
              borderBottom: "1px solid #e2e3e7",
            }}
          >
            <Typography variant="body2"
              sx={{fontWeight: "bold", color: "text.secondary"}}
            >
              {issuesS}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SectionRessourceLinkedIssues ressourceIssues={ressourceIssues} />
            <SectionRessourceLinkedIssuesExtractedText
              scene={scene}
              ressource={ressource}
              editorPdf={caplaEditor?.editorPdf}
            />
          </AccordionDetails>
        </Accordion>
      )}
      {ressource.quantity && (
        <Accordion disableGutters>
          <AccordionSummary
            id={elementsS}
            expandIcon={<ExpandMore />}
            sx={{
              borderTop: "1px solid #e2e3e7",
              borderBottom: "1px solid #e2e3e7",
            }}
          >
            <Typography variant="body2"
              sx={{fontWeight: "bold", color: "text.secondary"}}
            >
              {elementsS}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{p: 0}}>
            <SectionRessourceTypesInSelectionPanel
              scene={scene}
              ressource={ressource}
              viewer={viewer}
            />
            <Box
              sx={{
                width: 1,
                pl: 2,
                py: 1,
                color: "text.secondary",
              }}
            >
              <ToggleMeasurementsVisibility measurementsCount={fms.length} />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {ressource.quantity && (
        <Accordion disableGutters>
          <AccordionSummary
            id={zonesS}
            expandIcon={<ExpandMore />}
            sx={{
              borderTop: "1px solid #e2e3e7",
              borderBottom: "1px solid #e2e3e7",
            }}
          >
            <Typography variant="body2"
              sx={{fontWeight: "bold", color: "text.secondary"}}
            >
              {zonesS}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SectionRessourceZonesInSelectionPanel
              scene={scene}
              ressource={ressource}
              caplaEditor={caplaEditor}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}