import {useSelector} from "react-redux";

import {Box} from "@mui/material";
// import {Close} from "@mui/icons-material";

import ListItemClippingPlane from "./ListItemClippingPlane";

// import {updateClippingPlanesStatus} from "Features/scenes/scenesSlice";

export default function CardClippingPlanes({editor3d, scene}) {
  // const dispatch = useDispatch();
  // strings

  // const planeString = "Plane";

  // data

  const allClippingPlanes = useSelector((state) => state.scenes.clippingPlanes);
  const clippingPlanes = allClippingPlanes.filter(
    (cp) => cp.sceneClientId === scene.clientId
  );

  console.log("CLIPPING PLANES", clippingPlanes);

  // handlers

  return (
    <Box>
      {clippingPlanes.length > 0 && (
        <Box sx={{bgcolor: "common.white", p: 1}}>
          {clippingPlanes.map((clippingPlane) => (
            <ListItemClippingPlane
              key={clippingPlane.id}
              clippingPlane={clippingPlane}
              editor3d={editor3d}
              scene={scene}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
