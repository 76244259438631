import {useSelector} from "react-redux";

export default function useImportedAnnotationsCount() {
  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );
  const author = useSelector((s) => s.annotatedPdf.selectedAuthor);
  let rawMeasurements = useSelector((s) => s.annotatedPdf.rawMeasurements);

  const ms = [];

  rawMeasurements
    .filter((m) => m.author === author)
    .forEach((rawMeasurement) => {
      const elementType = subjectElementTypeMap[rawMeasurement.subject];
      if (elementType?.id) {
        ms.push(rawMeasurement);
      }
    });

  const count = ms.length;

  const total = rawMeasurements.filter((m) => m.author === author).length;

  const ratio = total === 0 ? 0 : count / total;

  return {count, total, ratio};
}
