import styleRankMap from "../data/styleRankMap";
import copyTypes from "./copyTypes";

export default function getTemplateProxies({
  sortedArticles,
  articlesMap,
  groupId,
}) {
  //
  let sortedArticlesProxy = [];
  const articlesFromTemplate = [];

  //
  const articlesByClientId = {};
  Object.values(articlesMap).forEach((article) => {
    articlesByClientId[article.clientId] = article;
  });
  //
  const maxIndex = sortedArticles.length - 1;
  //
  const articleSubArticlesMap = {}; // {articleCode:{parentArticle,subArticles:[subArticles]}}
  //
  sortedArticles.forEach((clientId, index) => {
    sortedArticlesProxy.push(clientId);
    const article = articlesByClientId[clientId];
    //
    if (!article) return;
    //
    let articleProxy = {...article};
    //

    // template detection

    if (article.isTemplate) {
      // templates map update
      const parentArticleRank = styleRankMap[article.style];
      const parentCode = article.code;
      let currentIndex = index + 1;
      let stop = false;
      let subArticles = [];
      while (currentIndex <= maxIndex && !stop) {
        const currentArticleClientId = sortedArticles[currentIndex];
        const currentArticle = articlesByClientId[currentArticleClientId];
        const currentArticleRank = styleRankMap[currentArticle.style];
        if (currentArticleRank > parentArticleRank) {
          subArticles.push(currentArticle);
          currentIndex++;
        } else {
          stop = true;
        }
      }
      articleSubArticlesMap[parentCode] = {parentArticle: article, subArticles};
    } else {
      // we move to another article, either the first element of a template, or a regular article.
      const templateArticle = articleSubArticlesMap[article.code];
      if (templateArticle) {
        const parentArticle = templateArticle.parentArticle;
        //articleProxy.coreTypes = copyTypes(parentArticle.coreTypes); // we will copy it from excel
        articlesFromTemplate.push(articleProxy);

        // we add here the subArticles.
        // We do not want to duplicates the types with all the filters of the origin.

        templateArticle.subArticles.forEach((subArticle, index) => {
          const newClientId = article.clientId + "-t-" + index;
          const newId = article.id + "-t-" + index;
          //
          sortedArticlesProxy.push(newClientId);
          //
          const newSubArticle = {
            ...subArticle,
            id: newId,
            clientId: newClientId,
            fromTemplate: true,
          };

          // updates management
          if (subArticle.updates) {
            newSubArticle.updates = {
              ...subArticle.updates,
              id: newId,
            };
          }
          //
          articlesFromTemplate.push(newSubArticle);
        });
      }
    }

    // we detect an article that should inherit from a template.
  });

  console.log("debugafa getTemplateProxies", articlesFromTemplate);

  return {sortedArticlesProxy, articlesFromTemplate, groupId};
}
