import React from "react";

import {useSelector, useDispatch} from "react-redux";
import useSceneListings from "Features/viewer3D/useSceneListings";
import {setSceneListingId} from "Features/viewer3D/viewer3DSlice";

import {Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function SceneBasicHeader({scene}) {
  const dispatch = useDispatch();
  // data

  const {status, data: listings} = useSceneListings({sceneId: scene?.id});
  const listingId = useSelector((state) => state.viewer3D.sceneListingId);

  const listing = listings.find((l) => l.id === listingId);
  const listingTitle = listing ? listing.name : "...";

  // handlers

  function handleClick() {
    dispatch(setSceneListingId(null));
  }

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        p: 1,
        py: 0.5,
        width: 1,
        color: "common.white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ml: 1}} variant="body2" color="inherit">
        {listingTitle}
      </Typography>
      <IconButton size="small" onClick={handleClick} color="inherit">
        <Close fontSize="small" />
      </IconButton>
    </Box>
  );
}
