import {el} from "date-fns/locale";
import theme from "Styles/theme";

export default class Pointer {
  pageCoordinates; // used to keep position in memory for update computation.
  rawPageCoordinates; // used to create annotation with pdftron.
  pageRotation; // use in drawing process, when snapping to line.

  constructor() {
    this.size = 16;

    this.element = this.createDivElement();
  }

  createDivElement() {
    const el = document.createElement("div");
    el.style.position = "absolute";
    el.style.width = `${this.size * 1}px`;
    el.style.height = `${this.size * 1}px`;
    el.style.transform = "translate(-50%,-50%)";
    el.style.borderRadius = "50%";
    el.style.border = `1px solid ${theme.palette.primary.flash}`;
    el.style.zIndex = 35;
    return el;
  }

  update({zoom}) {
    if (this.pageCoordinates) {
      this.element.style.left = `${this.pageCoordinates.x * zoom}px`;
      this.element.style.top = `${this.pageCoordinates.y * zoom}px`;
    }
  }

  setPosition({
    pageCoordinates,
    rawPageCoordinates,
    zoom,
    pageNumber,
    pageRotation,
  }) {
    this.element.style.left = `${pageCoordinates.x * zoom}px`;
    this.element.style.top = `${pageCoordinates.y * zoom}px`;
    this.pageCoordinates = {...pageCoordinates, pageNumber};
    this.rawPageCoordinates = {...rawPageCoordinates, pageNumber};
    this.pageRotation = pageRotation;
  }

  getPosition() {
    return this.pageCoordinates;
  }
  getRawPosition() {
    return this.rawPageCoordinates;
  }
  getPageRotation() {
    return this.pageRotation;
  }

  setStyle(snapMode) {
    switch (snapMode) {
      case "PATH_ENDPOINT": {
        this.element.style.borderRadius = 0;
        this.element.style.visibility = "visible";
        break;
      }
      case "POINT_ON_LINE": {
        this.element.style.borderRadius = "50%";
        this.element.style.visibility = "visible";
        break;
      }
      default: {
        this.element.style.visibility = "hidden";
      }
    }
  }

  show() {
    this.element.style.visibility = "visible";
  }

  hide() {
    this.element.style.visibility = "hidden";
  }

  enable() {
    this.show();
  }

  disable() {
    this.hide();
  }
}
