import getParentNum from "./getParentNum";

export default function getItemsFromSelectedItem({items, selectedItem, rank}) {
  let result = [];
  const selectedRank = selectedItem?.num.split(".").length;
  const allRankItems = items.filter(
    (item) => item.num.split(".").length === rank
  );

  if (!selectedItem) {
    if (rank === 1) {
      result = allRankItems;
    }
  } else {
    result = allRankItems.filter((item) => {
      const itemRank = item.num.split(".").length;
      if (itemRank < selectedRank) {
        return selectedItem.num.startsWith(item.num);
      } else if (itemRank === selectedRank) {
        return itemRank === 1
          ? true
          : getParentNum(selectedItem.num) === getParentNum(item.num);
      } else if (itemRank === selectedRank + 1) {
        return item.num.startsWith(selectedItem.num);
      }
    });
  }
  return result;
}
