import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getMaterialsFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(4, rowCount - 3).values();

  const valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    if (rowValues.length === 0) break;
    valuesA.push(rowValues);
  }

  const fields = [
    "id",
    "num",
    "name",
    "code",
    "color",
    "toDelete",
  ];

  const loadedMaterials = [];

  valuesA.map((valueA) => {
    const material = {};
    valueA.forEach((value, index) => (material[fields[index]] = value));
    loadedMaterials.push(material);
  });
  return loadedMaterials;
}
