import {useSelector, useDispatch} from "react-redux";

import {FormControlLabel, Switch, Typography} from "@mui/material";

import {setShowMeasurements} from "../measurementsSlice";

export default function SwitchShowMeasurements({editorPdf}) {
  const dispatch = useDispatch();
  const labelString = "Show elements";

  const checked = useSelector((s) => s.measurements.showMeasurements);

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setShowMeasurements(checked));
    const manager = editorPdf?.annotationsManager.measurementsPdfManager;
    if (checked && manager) {
      manager.showAllMeasurementAnnotations();
    } else {
      manager.hideAllMeasurementAnnotations();
    }
  }
  return (
    <FormControlLabel
      control={
        <Switch size="small" checked={checked} onChange={handleChange} />
      }
      label={<Typography variant="body2">{labelString}</Typography>}
    />
  );
}
