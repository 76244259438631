import {useSelector, useDispatch} from "react-redux";

import {Box, Button, IconButton, Typography} from "@mui/material";

import SwitchShowReorderHelpers from "./SwitchShowReorderHelpers";

import {setDatagridIsInEditMode, setEditedRessources} from "../ressourcesSlice";
import useRessourcesByScene from "../hooks/useRessourcesByScene";
import useUpdateSceneRessources from "../hooks/useUpdateSceneRessources";
import getSortedItemsArrayByNum from "Utils/getSortedItemsArrayByNum";
import {Refresh} from "@mui/icons-material";

function ButtonBackground({children}) {
  return (
    <Box
      sx={{
        borderRadius: "4px",
        bgcolor: "background.default",
        height: "30px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {children}
    </Box>
  );
}
export default function ToolbarEditRessourcesInDatagrid({scene, hideButtons}) {
  const dispatch = useDispatch();

  // strings

  const description =
    "Modifiez directement le tableau d'articles en cliquant sur les cellules.";

  const updateS = "Num. auto.";

  // data

  const ressources = useRessourcesByScene(scene);

  const editedRessources = useSelector((s) => s.ressources.editedRessources);

  // data - methods

  const updateSceneRessources = useUpdateSceneRessources(scene);

  // handlers

  function handleClear() {
    const cleared = [];
    editedRessources.forEach((r) => {
      cleared.push({
        ...r,
        description: typeof r.description === "string" ? r.description : "",
      });
    });
    updateSceneRessources(cleared);
  }

  return (
    <Box sx={{width: 1, p: 1}}>
      <Box sx={{p: 1}}>
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {description}
        </Typography>
      </Box>

      <Box
        sx={{
          width: 1,
          px: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          bgcolor: "common.white",
        }}
      >
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "text.secondary",
              "&>*:not(:last-child)": {
                mr: 2,
              },
            }}
          >
            <ButtonBackground>
              <SwitchShowReorderHelpers />
            </ButtonBackground>
            <ButtonBackground>
              <Button
                startIcon={<Refresh />}
                size="small"
                color="inherit"
                onClick={handleClear}
                sx={{mr: 1}}
              >
                {updateS}
              </Button>
            </ButtonBackground>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
