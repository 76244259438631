import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {createSharedbox} from "../sharedboxesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useAddSceneSharedbox() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const addSceneSharedbox = async (sharedbox) => {
    const result = await dispatch(createSharedbox({sharedbox, accessToken}));
    if (result) {
      const {item} = unwrapResult(result);
      return item;
    }
  };

  return addSceneSharedbox;
}
