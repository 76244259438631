import {colors} from "@mui/material";
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  fetchPackageService,
  fetchColoringsService,
  fetchColoringsBySceneService,
  fetchColoringsByPackageService,
  createColoringService,
  fetchColoringSceneService,
} from "./services";

export const fetchPackage = createAsyncThunk(
  "colorings/fetchPackage",
  fetchPackageService
);

export const fetchColorings = createAsyncThunk(
  "colorings/fetchColorings",
  fetchColoringsService
);

export const fetchColoringsByScene = createAsyncThunk(
  "colorings/fetchColoringsByScene",
  fetchColoringsBySceneService
);

export const fetchColoringsByPackage = createAsyncThunk(
  "colorings/fetchColoringsByPackage",
  fetchColoringsByPackageService
);

export const createColoring = createAsyncThunk(
  "colorings/createColoring",
  createColoringService
);

export const fetchColoringScene = createAsyncThunk(
  "colorings/fetchColoringScene",
  fetchColoringSceneService
);

export const coloringsSlice = createSlice({
  name: "colorings",
  initialState: {
    items: [],
    selectedColoring: null,
    fetchedColoringSceneId: null,
  },
  reducers: {
    setSelectedColoring: (state, action) => {
      state.selectedColoring = action.payload;
    },
  },

  extraReducers: {
    [fetchColorings.fulfilled]: (state, action) => {
      const colorings = action.payload;

      const coloringsIds = colorings.map((c) => c.id);
      const prevColorings = state.items.filter(
        (i) => !coloringsIds.includes(i.id)
      );
      const newColorings = [];
      colorings.forEach((coloring) => {
        const oldC = state.items.find((i) => i.id === coloring.id);
        if (oldC) {
          newColorings.push({...oldC, ...coloring});
        } else {
          newColorings.push(coloring);
        }
      });
      state.items = [...prevColorings, ...newColorings];
    },
    [fetchColoringsByScene.fulfilled]: (state, action) => {
      let colorings = action.payload;
      if (!colorings) colorings = [];
      const coloringsIds = colorings.map((c) => c.id);
      const prevColorings = state.items.filter(
        (i) => !coloringsIds.includes(i.id)
      );
      const newColorings = [];
      colorings.forEach((coloring) => {
        const oldC = state.items.find((i) => i.id === coloring.id);
        if (oldC) {
          newColorings.push({...oldC, ...coloring});
        } else {
          newColorings.push(coloring);
        }
      });
      state.items = [...prevColorings, ...newColorings];
    },
    [fetchColoringsByPackage.fulfilled]: (state, action) => {
      let colorings = action.payload;
      if (!colorings) colorings = [];
      const coloringsIds = colorings.map((c) => c.id);
      const prevColorings = state.items.filter(
        (i) => !coloringsIds.includes(i.id)
      );
      const newColorings = [];
      colorings.forEach((coloring) => {
        const oldC = state.items.find((i) => i.id === coloring.id);
        if (oldC) {
          newColorings.push({...oldC, ...coloring});
        } else {
          newColorings.push(coloring);
        }
      });
      state.items = [...prevColorings, ...newColorings];
    },
    [createColoring.fulfilled]: (state, action) => {
      if (action.payload) {
        const coloring = action.payload;
        const prevColorings = state.items.filter((i) => i.id !== coloring?.id);
        const prevColoring = state.items.find((i) => i.id === coloring.id);
        if (prevColoring) {
          state.items.push({...prevColoring, ...coloring});
        } else {
          state.items.push(coloring);
        }
      }
    },
    [fetchColoringScene.fulfilled]: (state, action) => {
      if (action.payload) {
        const {scene, coloring} = action.payload;
        state.fetchedColoringSceneId = scene?.id;
        const oldColorings = state.items.filter((c) => c.id !== coloring.id);
        const oldColoring = state.items.find((c) => c.id === coloring.id);
        let newColoring = {...coloring};
        if (oldColoring) newColoring = {...oldColoring, ...coloring};
        state.items = [...oldColorings, newColoring];
      }
    },
  },
});

export const {setSelectedColoring} = coloringsSlice.actions;

export default coloringsSlice.reducer;
