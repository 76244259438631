import {useSelector, useDispatch} from "react-redux";
import {setShortcutElementTypes} from "Features/measurements/measurementsSlice";

import {Box} from "@mui/material";

// import BoxItemLinkedTypes from "./BoxItemLinkedTypes";

import useRessourcesAndTypesByScene from "../hooks/useRessourcesAndTypesByScene";

import TableGroupedItems from "Components/TableGroupedItems";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import sortByNum from "Utils/sortByNum";
import getFormulaLabel from "../utils/getFormulaLabel";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import computeRessourceQuantity, {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function SectionRessourceTypesInSelectionPanel({
  ressource,
  scene,
  viewer,
}) {
  const dispatch = useDispatch();
  // strings

  const title = "éléments repérés (vide pour plein)";

  // data

  const ressourcesAndTypes = useRessourcesAndTypesByScene(scene);
  const item = ressourcesAndTypes.find((r) => r.id === ressource?.id);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const types = item?.types ? item.types : [];

  const elementTypes = useElementTypesBySceneProxy(scene);
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  let [measurements] = useFilteredSceneMeasurements(scene, mode);
  const filteredTypes = elementTypes.filter((type) =>
    type.res?.some(({resId}) => resId === ressource?.id)
  );
  const zones = useZonesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  const dontUseForQuantities = new Set(models
    .filter((m) => m.excludeFromQties)
    .map((m) => m.id)
  );
  if (dontUseForQuantities.size > 0)
    measurements = measurements.filter(
      (m) => !dontUseForQuantities.has(m.measurementsModelId)
    );

  // helpers

  const zonesById = {};
  for (const item of zones) {
    zonesById[item.id] = item;
  }
  const roomsById = {};
  for (const item of rooms) {
    roomsById[item.id] = item;
  }
  const sectorsById = {};
  for (const item of sectors) {
    sectorsById[item.id] = item;
  }

  // helpers - display

  const display = types.length > 0 ? "flex" : "none";

  // helpers - table grouped items

  const allSortedItems = elementTypes
    .map((t) => {
      const newType = {...t};
      if (!newType.group) {
        newType.groupName = "-?-";
        newType.groupId = "-?-";
      }
      if (!newType.groupId) newType.groupId = t.group;
      if (!newType.groupName) newType.groupName = t.group;
      return newType;
    })
    .sort((a, b) => a.groupName.localeCompare(b.groupName))
    .sort((a, b) => sortByNum(a.num, b.num));

  let scopedItemIdsWithCount = filteredTypes.map((t) => {
    let qty = 0;
    let count = 0;
    const res = t.res.find(({resId}) => resId === ressource.id);
    measurements.filter((m) => m.elementTypeId === t.id).forEach((m) => {
      const useMeas = applyFormulaFilter(
        m,
        res?.func,
        zonesById[m.zoneId],
        roomsById[m.roomId],
        sectorsById[m.sectorId],
        filterNoVoids
      );
      if (useMeas) {
        count += 1;
        const mQ = computeRessourceQuantity({
          item: m,
          res,
          ressource,
          withDecimal: false,
          filterNoVoids,
        });
        qty += mQ;
      }
    })
    const description = getFormulaLabel(t, ressource, res);
    return {
      item: {...t, elementTypeId: t.id},
      id: t.id,
      qty,
      description,
      count
    };
  });
  const showedItemIds = shortcutElementTypes;

  console.log("DEBUG 2811", scopedItemIdsWithCount);

  // handlers

  const handleShowedItemIdsChange = (ids) => {
    console.log("ids");
    dispatch(setShortcutElementTypes(ids));
  };

  return (
    <Box
      sx={{
        p: 1,
        pb: 0,
        width: 1,
        display,
        flexDirection: "column",
        bgcolor: "background.default",
      }}
    >
      <TableGroupedItems
        headerTitle={title}
        allSortedItems={allSortedItems}
        scopedItemIdsWithCount={scopedItemIdsWithCount}
        showedItemIds={showedItemIds}
        onShowedItemIdsChange={handleShowedItemIdsChange}
        options={{
          disableExpandGroups: true,
          disableNums: true,
          disableGroupedShowModes: true,
        }}
      />
      {/* <BoxItemLinkedTypes scene={scene} item={item} types={types} /> */}
    </Box>
  );
}
