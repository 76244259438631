import testFuncHasFilters from "./testFuncHasFilters";
import {testMeasurementOnFuncFilters} from "./testMeasurementOnFuncFilters";
import aggregateMeasurements from "Features/measurements/utils/aggregateMeasurements";
import getTypeArticlesMap from "./getTypeArticlesMap";
import getVoidIdMeasurementMap from "Features/measurements/utils/getVoidIdMeasurementMap";

export default function getArticleMeasurementsMap({
  articles,
  measurements,
  elementTypesMap,
  sectorsMap,
}) {
  //

  const amMap = {}; // {articleId: {elementTypeId:{measIds:[],measAggregated, func}}}

  // data

  const voidIdMeasurementMap = getVoidIdMeasurementMap(measurements); // {voidId: measurement}

  //

  if (!articles || !measurements) return amMap;
  //

  const taMap = getTypeArticlesMap(articles); // {elementTypeId: [{articleId, articlesGroupId, func,filtersStr,getVoids}]}

  measurements.forEach((measurement) => {
    const measTypeId = measurement.elementTypeId;
    const measArticles = taMap[measTypeId] ?? [];
    const sector = sectorsMap[measurement.sectorId];

    measArticles.forEach(
      ({articleId, articlesGroupId, func, filtersStr, getVoids}) => {
        let test = true; // add meas to the map.
        if (filtersStr?.length > 0) {
          // test if measurement pass the test.

          test = testMeasurementOnFuncFilters({
            measurement,
            filtersStr,
            sector,
          });
          //
        }
        if (test) {
          if (!amMap[articleId]) {
            amMap[articleId] = {
              [measTypeId]: {
                measurements: [measurement],
                measIds: [measurement.id],
                measAggregated: measurement,
                func,
              },
            };
          } else {
            if (!amMap[articleId][measTypeId]) {
              amMap[articleId][measTypeId] = {
                measurements: [measurement],
                measIds: [measurement.id],
                measAggregated: measurement,
                func,
              };
            } else {
              amMap[articleId][measTypeId].measIds.push(measurement.id);
              amMap[articleId][measTypeId].measurements.push(measurement);
              amMap[articleId][measTypeId].measAggregated =
                aggregateMeasurements([
                  amMap[articleId][measTypeId].measAggregated,
                  measurement,
                ]);
            }
          }
        }
      }
    );
  });

  return amMap;
}
