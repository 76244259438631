import {Box} from "@mui/material";

import ButtonDialogCreateElementType from "./ButtonDialogCreateElementType";
import ButtonDialogUpdateElementType from "./ButtonDialogUpdateElementType";
import ButtonDeleteElementType from "./ButtonDeleteElementType";
// import ButtonDisableTypes from "./ButtonDisableTypes";
import ButtonRefresh from "./ButtonRefresh";
import ButtonDialogEditRessources from "./ButtonDialogEditRessources";
// import ButtonRepairDeletedTypes from "./ButtonRepairDeletedTypes";
import ButtonExcelElementTypes from "./ButtonExcelElementTypes";
// import ButtonLinkRessourceAuto from "./ButtonLinkRessourceAuto";

import useSelectedElementType from "../hooks/useSelectedElementType";
import ButtonRemoveParents from "./ButtonRemoveParents";
import ButtonDeleteSceneTypes from "./ButtonDeleteSceneTypes";
import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyBySceneWithElementTypes";

export default function ToolbarElementTypesMain({scene}) {
  // data

  const selectedElementType = useSelectedElementType(scene);
  const elementTypesGroups =
    useElementTypesGroupsProxyBySceneWithElementTypes(scene);

  // helpers

  const hasSceneTypes = elementTypesGroups.find((g) => g.fromScene);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        <ButtonDialogCreateElementType scene={scene} />
        {!selectedElementType && (
          <Box
            sx={{ml: 1, display: "flex", alignItems: "center", "&>*": {mr: 1}}}
          >
            <ButtonRefresh scene={scene} />
            <ButtonExcelElementTypes scene={scene} />
            {/* <ButtonLinkRessourceAuto scene={scene} /> */}
            <ButtonRemoveParents scene={scene} />
            {/* <ButtonRepairDeletedTypes scene={scene} />
            <ButtonDisableTypes scene={scene} /> */}
            {hasSceneTypes && <ButtonDeleteSceneTypes scene={scene} />}
          </Box>
        )}
        {false && selectedElementType && (
          <Box sx={{display: "flex", alignItems: "center", ml: 2}}>
            <ButtonDialogUpdateElementType
              scene={scene}
              elementType={selectedElementType}
            />
            <ButtonDialogEditRessources
              elementType={selectedElementType}
              scene={scene}
            />
            <ButtonDeleteElementType
              scene={scene}
              elementType={selectedElementType}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
