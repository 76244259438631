import {useDispatch, useSelector} from "react-redux";

import {ToggleButton, Tooltip, Box} from "@mui/material";
import {HighlightAlt as Pick} from "@mui/icons-material";

import {setBoxSelection} from "Features/viewer3D/viewer3DSlice";

export default function ToggleBoxTool({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const tipString = "Box selection";

  // data

  const checked = useSelector((state) => state.viewer3D.boxSelection);
  const viewMode = useSelector((s) => s.viewer3D.viewMode);

  // helpers

  const mode = viewMode === "3D" ? "3D" : "2D";

  // handlers

  const handleChange = () => {
    dispatch(setBoxSelection(!checked));
    if (editor3d) {
      editor3d.controls.orbitControls.enabled = checked;
      editor3d.controls.orbitControls1.enabled = checked;
    }
    if (!checked) {
      editor3d?.sceneEditor.enableSelectionBox(mode);
    } else {
      editor3d?.sceneEditor.disableSelectionBox(mode);
    }
  };

  return (
    <Box sx={{color: "common.white"}}>
      <Tooltip title={tipString}>
        <ToggleButton
          value="PICK"
          selected={checked}
          onChange={handleChange}
          aria-label="module"
          sx={{
            width: 30,
            height: 30,
            border: "none",
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Box
            sx={{color: "common.white", display: "flex", alignItems: "center"}}
          >
            <Pick fontSize="small" color="inherit" />
          </Box>
        </ToggleButton>
      </Tooltip>
    </Box>
  );
}
