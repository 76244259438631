import {Vector2, Vector3, ShapeUtils} from "three";

export default function getSlopingPolygonMeasurementQuantities({
  pathInf,
  slopingA,
  height,
}) {
  const originPoints = pathInf.map((p) => ({x: p[0], y: 0, z: p[1]}));

  const angleRad = slopingA ? slopingA * (Math.PI / 180) : 0.0;
  const pointA = new Vector3(originPoints[0].x, originPoints[0].y, originPoints[0].z);
  const pointB = new Vector3(originPoints[1].x, originPoints[1].y, originPoints[1].z);
  const axis = pointB.clone().sub(pointA).normalize();

  let lastPoint = pointB;
  let length = Math.abs(pointA.distanceTo(pointB));
  const points = [originPoints[0], originPoints[1]];
  for (let i = 2; i < originPoints.length - 1; i++) {
    const pointC = new Vector3(originPoints[i].x, originPoints[i].y, originPoints[i].z);
    const vAtoC = pointC.clone().sub(pointA);
    const projectedPoint = pointA.clone().add(vAtoC.clone().projectOnVector(axis));
    const projectionLength = projectedPoint.distanceTo(pointC) / Math.cos(angleRad);
    const dir = pointC.clone().sub(projectedPoint).normalize().multiplyScalar(projectionLength);
    const pointD = projectedPoint.clone().add(dir);
    points.push({x: pointD.x, y: pointD.y, z: pointD.z});
    length += Math.abs(lastPoint.distanceTo(pointD));
    lastPoint = pointD;
  }
  length += Math.abs(lastPoint.distanceTo(pointA));

  const shapePoints = points.map((p) => new Vector2(p.x, p.z));
  const area = Math.abs(ShapeUtils.area(shapePoints));
  const volume = Math.abs(area * height);
  return {
    length: parseFloat(length.toFixed(5)),
    area: parseFloat(area.toFixed(5)),
    volume: parseFloat(volume.toFixed(5)),
    count: 1
  };
}
