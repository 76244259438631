import {Button} from "@mui/material";

export default function DebugCameraButton({editor3d}) {
  // strings

  const debugString = "Debug Camera";

  // handlers

  function handleClick() {
    const camera = editor3d?.cameras.activeCamera;
    console.log("BEFORE update", camera?.projectionMatrix.toArray());
    camera?.updateProjectionMatrix();
    console.log("AFTER update", camera?.projectionMatrix.toArray());
  }
  return (
    <Button variant="outlined" size="small" onClick={handleClick}>
      {debugString}
    </Button>
  );
}
