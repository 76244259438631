import {useDispatch, useSelector} from "react-redux";
import {useIsAuthenticated} from "@azure/msal-react";

import {setOpenSelectionPanel} from "Features/selection/selectionSlice";

import {Paper, Box, Typography, Button} from "@mui/material";

import bimbox from "Assets/bimbox.svg";
import ContainerLoadingBimbox from "Features/overviewer/components/ContainerLoadingBimbox";
import ButtonDialogCreateScene from "./ButtonDialogCreateScene";

export default function SectionCreateFirstScene() {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const openSelectionPanel = useSelector((s) => s.selection.openSelectionPanel);

  const title = "Votre 1ère bimbox";

  const description = `Créez une bimbox pour bimifier vos plans PDF: vues 3D, repérages 2D et 3D, extraction de quantités et collaboration.`;

  const loginS =
    "⚠️ Vous n'êtes pas connecté à CAPLA360. Votre bimbox est éphémère, aucune donnée ne sera pas enregistrée.";

  const openS = "Ouvrir le panneau de création";

  // handlers

  function handleOpen() {
    dispatch(setOpenSelectionPanel(true));
  }
  return (
    <Paper
      elevation={24}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 400,
        bgcolor: "common.caplaBlack",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "center",
          //bgcolor: "common.caplaBlack",
          //borderRadius: "4px 4px 0 0",
        }}
      >
        <Box sx={{width: 1}}>
          <ContainerLoadingBimbox />
        </Box>
      </Box>
      {!isAuthenticated && (
        <Box sx={{p: 1, bgcolor: "warning.main"}}>
          <Typography variant="body2">{loginS}</Typography>
        </Box>
      )}
      <Box sx={{p: 2}}>
        <Typography variant="h6">{title}</Typography>
        <Typography sx={{mt: 1}}>{description}</Typography>
      </Box>
      {/* <Box sx={{p: 2, display: "flex", justifyContent: "right"}}>
        {!openSelectionPanel ? (
          <Button onClick={handleOpen} variant="outlined" color="inherit">
            {openS}
          </Button>
        ) : (
          <Typography variant="h6">👉</Typography>
        )}
      </Box> */}
      <Box
        sx={{width: 1, p: 1, pb: 3, display: "flex", justifyContent: "center"}}
      >
        <ButtonDialogCreateScene />
      </Box>
    </Paper>
  );
}
