import React from "react";

import {Box} from "@mui/material";

import useListingtypes from "../useListingtypes";

export default function ListIconListingType({
  listingType,
  size = 24,
  unselected = false,
}) {
  const listingTypes = useListingtypes();
  const type = listingTypes.find((t) => t.id === listingType);

  // helpers

  const bgcolor = unselected ? "common.white" : type?.color;
  const image = type?.imageWhite;
  const filter = unselected ? "brightness(0)" : "unset";
  const icon = type?.icon;

  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: "4px",
        bgcolor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: unselected ? "text.secondary" : "common.white",
      }}
    >
      {icon ? (
        icon
      ) : (
        <img src={image} width={"80%"} height={"80%"} style={{filter}} />
      )}
    </Box>
  );
}
