/*
 * room : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useDeleteSceneRoom(scene) {
  const dispatch = useDispatch();

  const deleteSceneRoom = (room) => {
    if (!scene) return;
    let oldRooms = scene?.data?.rooms.filter((s) => s?.id !== room.id);
    if (!oldRooms) oldRooms = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, rooms: [...oldRooms]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return deleteSceneRoom;
}
