import {Dialog, DialogTitle, DialogContent} from "@mui/material";

import SectionProcedureLaunch from "./SectionProcedureLaunch";

export default function DialogProcedures({open, onClose, scene, caplaEditor}) {
  // strings

  const title = "Repérages automatiques";

  //
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <SectionProcedureLaunch
          scene={scene}
          caplaEditor={caplaEditor}
          onAdded={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
