import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  LinearProgress,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import {
  TipsAndUpdates as Tips,
  ArrowDropDown as Down,
  ArrowDropUp as Up,
  Close,
} from "@mui/icons-material";

import {setAnnotCreationTool, setOpenDrawingDetail} from "../measurementsSlice";

// import BoxMeasurementProps from "./BoxMeasurementProps";
import CheckboxIsSnapping from "./CheckboxIsSnapping";
import CheckboxIsDrawingOneByOne from "./CheckboxIsDrawingOneByOne";

import imagePolygon from "../assets/imagePolygon.png";
import imagePolyline from "../assets/imagePolyline.png";
import drawSegment from "../assets/drawSegment.png";
import drawRectangle from "../assets/drawRectangle.png";
import drawSegmentFromZones from "../assets/drawSegmentFromZones.png";
import drawRectangleFromPoint from "../assets/drawRectangleFromPoint.png";
import drawCircleFromPoint from "../assets/drawCircleFromPoint.png";
import useMeasurementCreationMode from "../useMeasurementCreationMode";

export default function CardIsDrawing({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const drawPolylineS = "Draw a polyline";
  const drawPolygonS = "Draw a polygon";
  const drawPolygonIntS = "Draw interior polygon";
  const drawSegmentS = "Draw a segment";
  const drawRectangleS = "Draw a rectangle";
  const drawSegmentFromZonesS = "Draw a segment between 2 zones.";
  const drawRectangleFromPointS = "Draw a rectangle";
  const drawCircleFromPointS = "Draw a circle";

  const tipsS = "Tips";
  const wheelS = "Mouse wheel : zoom + / -";
  const arrowS = "Keyboard arrows : move the pdf";
  const escS = "ESC : stop drawing";

  // data

  const color = useSelector((s) => s.measurements.color);
  const open = useSelector((s) => s.measurements.openDrawingDetail);
  const measurementCreationMode = useMeasurementCreationMode({editorPdf});

  console.log("measurementCraetion", measurementCreationMode);

  // helpers - headerMap

  const headerMap = {
    POLYLINE: {
      url: imagePolyline,
      title: drawPolylineS,
    },
    GABLE: {
      url: imagePolyline,
      title: drawPolylineS,
    },
    BRIDGE: {
      url: imagePolyline,
      title: drawPolylineS,
    },
    POLYLINE_FROM_POLYGON: {
      url: imagePolygon,
      title: drawPolygonS,
    },
    POLYLINE_FROM_RECTANGLE: {
      url: drawRectangle,
      title: drawRectangleS,
    },
    SLOPE: {
      url: imagePolyline,
      title: drawPolylineS,
    },
    STAIRS: {
      url: imagePolyline,
      title: drawPolylineS,
    },
    SEGMENT: {
      url: drawSegment,
      title: drawSegmentS,
    },
    POLYGON: {
      url: imagePolygon,
      title: drawPolygonS,
    },
    BOWL: {
      url: imagePolygon,
      title: drawPolygonS,
    },
    SLOPING_POLYGON: {
      url: imagePolygon,
      title: drawPolygonS,
    },
    POLYGON_INTERIOR_BANK: {
      url: imagePolygon,
      title: drawPolygonIntS,
    },
    RECTANGLE: {
      url: drawRectangle,
      title: drawRectangleS,
    },
    POLYGON_FROM_RECTANGLE: {
      url: drawRectangle,
      title: drawRectangleS,
    },
    SEGMENT_FROM_ZONES: {
      url: drawSegmentFromZones,
      title: drawSegmentFromZonesS,
    },
    RECTANGLE_FROM_POINT: {
      url: drawRectangleFromPoint,
      title: drawRectangleFromPointS,
    },
    CIRCLE_FROM_POINT: {
      url: drawCircleFromPoint,
      title: drawCircleFromPointS,
    },
  };

  const mode = headerMap[measurementCreationMode];
  const url = mode ? mode.url : null;
  const title = mode ? mode.title : "";

  // handlers

  function handleDropClick() {
    dispatch(setOpenDrawingDetail(!open));
  }

  function handleCloseClick() {
    dispatch(setAnnotCreationTool(null));
    editorPdf.annotationsManager.measurementsPdfManager.stopDrawing();
    editorPdf.sceneEditor.stopProcess();
    editorPdf.setSnapToZones(false);
  }

  return (
    <Paper elevation={0} sx={{color: color}}>
      <LinearProgress color="inherit" sx={{width: 1}} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color,
          p: 1,
        }}
      >
        <Box sx={{display: "flex", alignItems: "center", flexGrow: 1}}>
          <Typography variant="body1" sx={{ml: 1, color: "common.black"}}>
            {title}
          </Typography>
        </Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <IconButton size="small" onClick={handleDropClick}>
            {open ? <Up fontSize="small" /> : <Down fontSize="small" />}
          </IconButton>
          <IconButton size="small" onClick={handleCloseClick} color="primary">
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      {open && (
        <>
          {/* <BoxMeasurementProps /> */}
          <Box
            sx={{
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              width: "200px",
              height: "150px",
              position: "relative",
              backgroundImage: `url(${url})`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          />

          <Box sx={{color: "common.black", p: 1}}>
            <Box sx={{bgcolor: "background.default", p: 1}}>
              <Box sx={{display: "flex", alignItems: "base-line"}}>
                <Tips fontSize="small" color="info" />
                <Typography
                  variant="body2"
                  sx={{fontWeight: "bold", mb: 1, ml: 1}}
                >
                  {tipsS}
                </Typography>
              </Box>
              <Typography variant="body2">{wheelS}</Typography>
              <Typography variant="body2">{arrowS}</Typography>
              <Typography variant="body2">{escS}</Typography>
            </Box>
          </Box>

          <Box sx={{mt: 2, px: 2, color: "common.black"}}>
            <CheckboxIsSnapping editorPdf={editorPdf} />
            <CheckboxIsDrawingOneByOne editorPdf={editorPdf} />
          </Box>
        </>
      )}
    </Paper>
  );
}
