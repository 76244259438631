export default function getModelsInScopeByType(models, scope) {
  if (!scope || scope?.id === "all") {
    return {
      PACKAGES: models.filter((m) => m.type === "MEASUREMENTS"),
      ZONES: models.filter((m) => m.type === "IMAGE" && m?.fromModel),
      INPUTS: models.filter((m) => m.type === "PDF"),
    };
  }

  const ids1 = Array.isArray(scope?.data?.packageIds)
    ? scope.data.packageIds
    : [];

  const ids2 = Array.isArray(scope?.data?.inputIds)
    ? scope?.data?.inputIds
    : [];

  const ids3 = Array.isArray(scope?.data?.zoneIds) ? scope?.data?.zoneIds : [];

  return {
    PACKAGES: models.filter((m) => ids1.includes(m.id)),
    ZONES: models.filter((m) => ids2.includes(m.id)),
    INPUTS: models.filter((m) => ids3.includes(m.id)),
  };
}
