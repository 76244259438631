import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Save} from "@mui/icons-material";

import {setIsEditingRessource, setTempRessourceTypes} from "../ressourcesSlice";
import useSelectedRessource from "../hooks/useSelectedRessource";

import {updateElementTypesGroup} from "Features/elementTypes/elementTypesSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useAccessToken from "Features/auth/useAccessToken";
import {
  setEditedRelations,
  setRelationsTypesRessources,
  setRelationsTypesRessourcesToRestore,
} from "Features/relations/relationsSlice";
import useUpdateSceneRessources from "../hooks/useUpdateSceneRessources";
import useElementTypesGroupById from "Features/elementTypes/hooks/useElementTypesGroupById";
import {setSnackbarMessage} from "Features/ui/uiSlice";

const checkUpdateScene = (from, to) => {
  let doUpdate = false;
  Object.keys(to).forEach((k) => {
    if (k !== "types" && from[k] !== to[k]) doUpdate = true;
  });
  return doUpdate;
};

export default function ActionsRessourceInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Save";
  const cancelS = "Cancel";
  const blockedS = "Action annulée : plus de 10 types modifiés détectés";

  // data

  const isEditing = useSelector((s) => s.ressources.isEditingRessource);
  const editedRessource = useSelector((s) => s.ressources.editedRessource);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const updateSceneRessources = useUpdateSceneRessources(scene);
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const relationsToRestore = useSelector(
    (s) => s.relations.relationsTypesRessourcesToRestore
  );
  const editedRelations = useSelector((s) => s.relations.editedRelations);
  const selectedRessource = useSelectedRessource(scene);
  const getElementTypesGroup = useElementTypesGroupById();

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  function handleCancelClick() {
    dispatch(setRelationsTypesRessources(relationsToRestore));
    dispatch(setRelationsTypesRessourcesToRestore(null));
    dispatch(setIsEditingRessource(false));
    dispatch(setTempRessourceTypes([]));
    dispatch(setEditedRelations({}));
  }

  async function updateTypesByRelations() {
    const editedTypesByGroup = {};
    for (const [key, value] of Object.entries(editedRelations)) {
      const editedType = elementTypes.find((t) => t.id === key.slice(0, 21));
      if (editedType) {
        const type = {...editedType};
        if (value === "DELETE") {
          type.res = type.res?.filter((r) => r.id !== key);
        } else if (type.res) {
          const otherRes = type.res.filter((r) => r.id !== key);
          type.res = [...otherRes, relations[key]];
        } else {
          type.res = [relations[key]];
        }
        if (editedType.groupId in editedTypesByGroup) {
          editedTypesByGroup[editedType.groupId].push(type);
        } else {
          editedTypesByGroup[editedType.groupId] = [type];
        }
      }
    }

    await Promise.all(
      Object.entries(editedTypesByGroup).map(async ([groupId, editedTypes]) => {
        const group = getElementTypesGroup(groupId);
        const editedTypesIds = new Set(editedTypes.map((t) => t.id));
        const otherTypes = group.elementTypes.filter(
          (t) => !editedTypesIds.has(t.id)
        );
        console.log("debug 2106 update group", group, group.fromScene);
        if (!group.fromScene) {
          const updatedGroup = {
            ...group,
            elementTypes: [...otherTypes, ...editedTypes],
          };
          return await dispatch(
            updateElementTypesGroup({
              accessToken,
              elementTypesGroup: {...updatedGroup, sceneId: scene.id},
            })
          );
        } else {
          return Promise.resolve();
        }
      })
    );

    // for (const [groupId, editedTypes] of Object.entries(editedTypesByGroup)) {
    //   const group = getElementTypesGroup(groupId);
    //   const editedTypesIds = new Set(editedTypes.map((t) => t.id));
    //   const otherTypes = group.elementTypes.filter(
    //     (t) => !editedTypesIds.has(t.id)
    //   );
    //   if (!group.fromScene) {
    //     const updatedGroup = {
    //       ...group,
    //       elementTypes: [...otherTypes, ...editedTypes],
    //     };

    //     await dispatch(
    //       updateElementTypesGroup({
    //         accessToken,
    //         elementTypesGroup: {...updatedGroup, sceneId: scene.id},
    //       })
    //     );
    //   }
    // }
  }

  async function handleSaveClick() {
    const limit = 20; // if more, prevent saving
    // ressources
    if (loading) return;
    setLoading(true);
    const updatedRessource = {...editedRessource};
    let itemRelationKeys;
    if (relations && Object.keys(editedRelations).length > 0) {
      itemRelationKeys = Object.keys(relations).filter((k) =>
        k.endsWith(editedRessource.id)
      );
      updatedRessource.types = itemRelationKeys.map((k) => relations[k]);
    }
    const doUpdate = checkUpdateScene(selectedRessource, updatedRessource);
    if (doUpdate) {
      await updateSceneRessources([updatedRessource]);
    }
    if (Object.keys(editedRelations).length > limit) {
      alert(blockedS);
      handleCancelClick();
    } else if (Object.keys(editedRelations).length > 0) {
      //setLoading(true);
      await updateTypesByRelations();
      //setLoading(false);
      dispatch(setRelationsTypesRessourcesToRestore(null));
      dispatch(setEditedRelations({}));
    }
    setLoading(false);
    const message = "Articles mis à jour";
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
    dispatch(setIsEditingRessource(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
            disabled={loading}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
