export default function twoLevelsMapToArray(map, field1, field2, itemsField) {
  const result = [];

  const isString = (field) =>
    ["p0", "p1", "p2", "p3", "elementTypeId"].includes(field);
  Object.entries(map).forEach(([key1, value1]) => {
    Object.entries(value1).forEach(([key2, value2]) => {
      const item = {
        [field1]: isString(field1) ? key1 : parseFloat(key1),
        [field2]: isString(field2) ? key2 : parseFloat(key2),
        [itemsField]: value2,
      };
      result.push(item);
    });
  });

  console.log("fieldsAAA", field1, field2, map);
  return result;
}
