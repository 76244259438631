import React from "react";

import {Box} from "@mui/material";

import ButtonGeolocate from "Features/viewer3D/components/ToolHeaderBottom/components/ButtonGeolocate";
import ButtonSetNorth from "Features/viewer3D/components/ToolHeaderBottom/components/ButtonSetNorth";
import ButtonGeolocateWith2Markers from "Features/viewer3D/components/ToolHeaderBottom/components/ButtonGeolocateWith2Markers";

export default function HeaderLocate({editor, scene, viewer}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
        p: 0.5,
        bgcolor: "common.white",
        borderRadius: "4px",
      }}
    >
      <ButtonGeolocateWith2Markers scene={scene} editor={editor} />
      <Box sx={{width: "8px"}} />
      <ButtonSetNorth scene={scene} editor={editor} />
    </Box>
  );
}
