import {Box} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import theme from "Styles/theme";

const getBackgroundColor = (color, mode) => {
  try {
    let col = color ? color : theme.palette.background.default;
    if (!col.includes("#")) col = "#" + col;
    if (mode === "ON") return col;
    if (mode === "OFF") return lighten(col, 0.9);
    if (mode === "UNDEFINED") return lighten(col, 0.5);
  } catch (e) {
    console.log("error", e);
  }
};

export default function SelectionBox({
  itemId,
  color,
  showMode,
  onShowModeChange,
  hide,
}) {
  function handleClick() {
    console.log("showModeX", showMode);
    if (onShowModeChange) {
      if (showMode === "ON" || showMode === "UNDEFINED") {
        onShowModeChange({itemId, showMode: "OFF"});
      } else {
        onShowModeChange({itemId, showMode: "ON"});
      }
    }
  }
  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        width: "16px",
        height: "16px",
        borderRadius: "4px",
        bgcolor: getBackgroundColor(color, showMode),
        visibility: hide ? "hidden" : "visible",
      }}
    />
  );
}
