import {useState, useEffect} from "react";

import {Box, Typography, TextField, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function ContainerEditableColumnTitle({
  title,
  onTitleChange,
  onAdd,
}) {
  // state

  const [tempTitle, setTempTitle] = useState("");
  useEffect(() => {
    setTempTitle(title);
  }, [title]);

  const [editing, setEditing] = useState(false);

  // handlers

  function handleAddClick() {
    onAdd(tempTitle);
    setTempTitle("");
  }

  function handleBlur() {
    onTitleChange(tempTitle);
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        p: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          p: 1,
          borderRadius: "4px",
          bgcolor: "common.white",
          display: "flex",
          alignItems: "center",
          mr: 1,
        }}
      >
        <TextField
          size="small"
          value={tempTitle}
          onChange={(e) => setTempTitle(e.target.value)}
          onBlur={handleBlur}
        />
      </Box>
      {onAdd && (
        <IconButton onClick={handleAddClick} size="small">
          <Add fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
