import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import FormNewScene from "./FormNewScene";
import FormScene from "./FormScene";
import SectionSelectedScene from "./SectionSelectedScene";

import useSelectedScene from "../useSelectedScene";

import usePageType from "Features/navigation/usePageType";
import Stretch from "Features/ui/components/Stretch";

export default function SectionSceneInSelectionPanel({scene, viewer}) {
  // data
  const pageType = usePageType();
  let selectedScene = useSelectedScene();
  // const newScene = useSelector((s) => s.scenes.newScene);
  // const editedScene = useSelector((s) => s.scenes.editedScene);
  const isEditing = useSelector((s) => s.scenes.isEditing);

  // helpers
  if (!selectedScene && pageType !== "HOME") selectedScene = scene;

  // helpers

  const isNewScene = !selectedScene;

  return (
    // <Stretch bottomOffset={viewer ? 0 : 60}>
    <Box>
      <Box sx={{width: 1, height: 1, overflow: "auto"}}>
        {isNewScene && <FormNewScene />}
        {selectedScene && !isEditing && (
          <SectionSelectedScene viewer={viewer} selectedScene={selectedScene} />
        )}
        {selectedScene && isEditing && <FormScene />}
      </Box>
    </Box>
  );
}
