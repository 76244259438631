import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box, Button, alpha, Typography} from "@mui/material";
import {Palette, Add} from "@mui/icons-material";

import useSelectedPackage from "../useSelectedPackage";
import {setLastPackageId} from "../packagesSlice";

import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

export default function ActionsPackageInSelectionPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // strings

  const editS = "Repérer les éléments";
  const createS = "Create";

  // data

  const selectedPackage = useSelectedPackage();
  const newPackage = useSelector((s) => s.packages.newPackage);

  // helpers

  const disableCreation = !newPackage?.fromModel?.modelId;

  // handlers

  function handleEditClick() {
    const sceneId = scene?.id ? scene.id : scene.clientId;
    navigate(`/bimboxes/${sceneId}/${selectedPackage?.id}/measurements`);
    //dispatch(setLastPackageId(selectedPackage.id));
    dispatch(setSelectedMeasurementsModelId(selectedPackage?.id));
    // caplaEditor?.measDataManager.setSelectedModelById(selectedPackage?.id);
    dispatch(setSelectedPdfModelId(selectedPackage?.fromModel.modelId));
  }

  async function handleCreateClick() {
    if (newPackage.name && newPackage.fromModel) {
      const packageModel =
        await caplaEditor?.editor3d?.loader.createMeasurementsModel(newPackage);
      caplaEditor?.measDataManager.createOrUpdateModelInManager(packageModel);
      console.log(
        "create package v1 - input:",
        newPackage,
        "output",
        packageModel
      );
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        {selectedPackage && (
          <Button
            startIcon={<Palette />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        )}
        {!selectedPackage && (
          <Button
            disabled={disableCreation}
            startIcon={<Add />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleCreateClick}
          >
            {createS}
          </Button>
        )}
      </Box>
    </Box>
  );
}
