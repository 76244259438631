import {useEffect} from "react";

import useWorksites from "./useWorksites";

import {useSelector, useDispatch} from "react-redux";
import {setSpace} from "Features/space/spaceSlice";
import {fetchWorksite} from "Features/worksites/worksitesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useWorksite({worksiteId}) {
  const accessToken = useAccessToken();

  const dispatch = useDispatch();
  const worksites = useWorksites();
  const worksite = worksites.find((worksite) => worksite.id === worksiteId);

  let space0;
  if (worksite?.spaces) space0 = worksite.spaces[0];
  const spaceWorksiteId = useSelector((state) => state.space.worksiteId);
  if (spaceWorksiteId !== worksiteId && space0) {
    console.log("update space", space0);
    dispatch(setSpace({space: space0, worksiteId}));
  }

  useEffect(() => {
    if (accessToken && worksiteId) {
      dispatch(fetchWorksite({accessToken, worksiteId}));
    }
  }, [accessToken, worksiteId]);

  return worksite;
}
