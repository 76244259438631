import useTranslation from "Features/translations/useTranslation";

export function useKvfTranslationMap() {
  const {t} = useTranslation("kvf");

  const translationMap = {
    pu: t("pu"),
    noPricing: t("noPricing"),
    identificationType: t("identificationType"),
    areaInM2: t("areaInM2"),
    bank: t("bank"),
    beam: t("beam"),
    builtAt: t("builtAt"),
    category: t("category"),
    categories: t("categories"),
    comment: t("comment"),
    countX: t("countX"),
    circle: t("circle"),
    classification: t("classification"),
    code: t("code"),
    codeName: t("code"),
    color: t("color"),
    description: t("description"),
    depthInM: t("depthInM"),
    dim1InM: t("dim1InM"),
    dim2InM: t("dim2InM"),
    decimal: t("decimal"),
    dim1: t("dim1"),
    dim2: t("dim2"),
    dim3: t("dim3"),
    dotted: t("dotted"),
    elements: t("elements"),
    elementType: t("elementType"),
    execution: t("execution"),
    geometry: t("geometry"),
    geometry2D: t("geometry2D"),
    geometry3D: t("geometry3D"),
    group: t("group"),
    position3D: t("position3D"),
    heightInM: t("heightInM"),
    height: t("height"),
    heightE: t("heightE"),
    heightN: t("heightN"),
    heights: t("heights"),
    hostedTypes: t("hostedTypes"),
    identification: t("identification"),
    material: t("material"),
    info: t("info"),
    isRoom: t("isRoom"),
    isGhost: t("isGhost"),
    isTitle: t("isTitle"),
    style: t("style"),
    hideNum: t("hideNum"),
    title1: t("titre1"),
    title2: t("titre2"),
    normal: t("normal"),
    detail: t("detail"),
    isVoid: t("isVoid"),
    lengthInM: t("lengthInM"),
    location: t("location"),
    localisation: t("localisation"),
    locked: t("locked"),
    mainQuantity: t("mainQuantity"),
    measurementsModel: t("measurementsModel"),
    name: t("name"),
    num: t("num"),
    offset: t("offset"),
    offset2d: t("offset2d"),
    perimeterInM: t("perimeterInM"),
    polyline: t("polyline"),
    polygon: t("polygon"),
    phase: t("phase"),
    preset: t("preset"),
    props: t("props"),
    quantities: t("quantities"),
    ratio: t("ratio"),
    ratioU: t("ratioU"),
    rectangle: t("rectangle"),
    refQty: t("refQty"),
    hardcodedQty: t("hardcodedQty"),
    ressource: t("ressource"),
    ressources: t("ressources"),
    relatedMeasurements: t("relatedMeasurements"),
    relatedMeasurement: t("relatedMeasurement"),
    room: t("room"),
    rotation: t("rotation"),
    sector: t("sector"),
    slope: t("slope"),
    slopeH: t("slopeH"),
    stairs: t("stairs"),
    slopingPolygon: t("slopingPolygon"),
    slopingA: t("slopingA"),
    table: t("table"),
    type: t("type"),
    tree: t("tree"),
    sector: t("sector"),
    segment: t("segment"),
    side: t("side"),
    versioning: t("versioning"),
    version: t("version"),
    isDeletedInVersion: t("isDeletedInVersion"),
    isChangedInVersion: t("isChangedInVersion"),
    faces: t("faces"),
    endBridge: t("endBridge"),
    sideBridge: t("sideBridge"),
    shape: t("shape"),
    strokeMInM: t("strokeMInM"),
    unit: t("unit"),
    updates: t("updates"),
    volumeInM3: t("volumeInM3"),
    voids: t("voids"),
    voidsSection: t("voidsSection"),
    webUrl: t("webUrl"),
    widthInM: t("widthInM"),
    zone: t("zone"),
    zInf: t("zInf"),
    zSup: t("zSup"),
    z: t("z"),
    UN: t("UN"),
    ML: t("ML"),
    M2: t("M2"),
    M3: t("M3"),
    KG: t("KG"),
    HR: t("HR"),
    up: t("up"),
    down: t("down"),
    gable: t("gable"),
    bridge: t("bridge"),
    bowl: t("bowl"),
    additionalParameters: t("additionalParameters"),
  };
  return translationMap;
}
