import {useState, useRef} from "react";
import {nanoid} from "@reduxjs/toolkit";

import {Autocomplete, TextField, Typography, Box, Button} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function SelectorAutocompleteWithCreate({
  name,
  value,
  options,
  onChange,
  // onFocus,
  onInputChange,
  onNewOption,
  createLabel = "-",
  canCreate,
  disabled = false,
}) {
  const inputRef = useRef();

  // state

  const [optionName, setOptionName] = useState("");
  const [open, setOpen] = useState(false);

  // handlers - open

  function openPopper() {
    setOpen(true);
  }
  function closePopper() {
    setOpen(false);
  }

  // handlers

  function handleOptionChange(value) {
    onChange(value);
  }

  function handleInputChange(e) {
    setOptionName(e.target.value);
  }

  function handleCreateClick() {
    if (optionName.length > 0) {
      const value = {id: nanoid(), name: optionName, fromScene: false};
      if (onNewOption) {
        onNewOption(value);
      }
      closePopper();
      inputRef.current.blur();
    }
  }

  return (
    <Box sx={{width: 1, display: "flex"}}>
      <Autocomplete
        sx={{flexGrow: 1}}
        blurOnSelect={true}
        open={open}
        onClose={closePopper}
        onOpen={openPopper}
        disabled={disabled}
        options={options}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name}
        renderOption={(props, option) => (
          <Typography {...props} sx={{pl: 1, fontSize: 13}}>
            {option?.name}
          </Typography>
        )}
        value={value}
        onChange={(e, v, reason) => handleOptionChange(v, reason)}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            onChange={handleInputChange}
            size="small"
            label={<Typography variant="body2">{name}</Typography>}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: 13,
              },
            }}
          />
        )}
        noOptionsText={
          onNewOption && canCreate ? (
            <Button
              size="small"
              onClick={handleCreateClick}
              startIcon={<Add />}
              color="secondary"
              disabled={optionName.length === 0}
            >
              {createLabel}
            </Button>
          ) : (
            <Box />
          )
        }
      />
    </Box>
  );
}
