import {Button} from "@mui/material";

import {Object3D} from "three";
import {PointerLockControls} from "three/examples/jsm/controls/PointerLockControls.js";

export default function ButtonPointerLock({editor3d}) {
  const string = "Immersion";

  // let prevX = 0;

  // function onMouseMove(event, camera, locked) {
  //   if (!locked) return;
  //   const _euler = new Euler(0, 0, 0, "YXZ");
  //   // const _vector = new Vector3();
  //   const _PI_2 = Math.PI / 2;

  //   const pointerSpeed = 1.0;

  //   const minPolarAngle = 0; // radians
  //   const maxPolarAngle = Math.PI; // radians

  //   // const movementX =
  //   //   event.movementX || event.mozMovementX || event.webkitMovementX || 0;

  //   const movementX = event.clientX - prevX;
  //   prevX = event.clientX;

  //   const movementY =
  //     event.movementY || event.mozMovementY || event.webkitMovementY || 0;

  //   //console.log(event, movementX, movementY);
  //   _euler.setFromQuaternion(camera.quaternion);

  //   _euler.y -= movementX * 0.002 * pointerSpeed;
  //   _euler.x -= movementY * 0.002 * pointerSpeed;

  //   _euler.x = Math.max(
  //     _PI_2 - maxPolarAngle,
  //     Math.min(_PI_2 - minPolarAngle, _euler.x)
  //   );
  //   camera.quaternion.setFromEuler(_euler);
  // }

  // function handleClick2() {
  //   if (editor3d?.multiviews.isEnabled) {
  //     editor3d?.controls.orbitControls.enabled = false;
  //     editor3d?.controls.orbitControls2.enabled = false;
  //     editor3d?.controls.activeMode = "LOCK2";

  //     editor3d?.cameras.enable("CAMERA", editor3d?.controls.activeControls);

  //     const el = editor3d?.multiviews.el2;
  //     // const containerEl = editor3d?.multiviews.containerEl;

  //     const cam = editor3d?.cameras.camera2;
  //     console.log(el, cam);
  //     //const lockControls3 = new PointerLockControls(cam, document.body);
  //     //lockControls3.connect();
  //     //console.log(lockControls3.domElement.ownerDocument);
  //     //lockControls3.lock();
  //     //   lockControls3.addEventListener("change", () => {
  //     //     // const dir = new Vector3();
  //     //     // lockControls3.getDirection(dir);
  //     //     // console.log(dir.x.toFixed(2), dir.y.toFixed(2));
  //     //   });

  //     const isLocked = true;
  //     el.ownerDocument.addEventListener("mousemove", (e) => {
  //       onMouseMove(e, cam, isLocked);
  //     });
  //   }
  // }

  function handleClick() {
    if (editor3d && editor3d.multiviews.isEnabled) {
      //editor3d?.controls.orbitControls.enabled = false;
      //editor3d?.controls.orbitControls2.enabled = false;
      //editor3d?.controls.orbitControls2.update();
      editor3d.controls.activeMode = "LOCK2";

      console.log("object", editor3d.controls.orbitControls2.object);

      const el = editor3d.multiviews.el2;
      // const containerEl = editor3d?.multiviews.containerEl;
      const cam = editor3d.cameras.camera2;

      editor3d.controls.orbitControls2.object = new Object3D();
      //editor3d?.controls.orbitControls2.dispose();
      //console.log("Active control", editor3d?.controls.activeControls);
      //editor3d?.cameras.enable("CAMERA", editor3d?.controls.activeControls);

      console.log(el, cam);
      const lockControls3 = new PointerLockControls(cam, el);
      //lockControls3.connect();
      lockControls3.lock();
      lockControls3.addEventListener("change", () => {
        // const dir = new Vector3();
        // lockControls3.getDirection(dir);
        // console.log(dir.x.toFixed(2), dir.y.toFixed(2));
      });
    }
  }

  return <Button onClick={() => handleClick()}>{string}</Button>;
}
