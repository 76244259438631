import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import fitCameraToObject from "../utils/fitCameraToObject";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

export default function CardCameraFitToWorld({editor3d}) {
  const classes = useStyles();

  const seeSelectionString = "Voir la scène complète";
  const seeString = "Voir";

  function handleSeeClick() {
    const selection = editor3d?.scene.children;
    fitCameraToObject(editor3d?.camera, editor3d?.controls.activeControls, selection);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body2">
          <b>{seeSelectionString}</b>
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleSeeClick} variant="outlined" size="small">
          {seeString}
        </Button>
      </CardActions>
    </Card>
  );
}
