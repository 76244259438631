import React from "react";

import {Box, Typography, Tooltip} from "@mui/material";
export default function AngleLabel({children, value}) {
  const angleString = value.toFixed(0) + "°";
  return (
    <Tooltip placement="left" title={angleString}>
      {children}
    </Tooltip>
  );
}
