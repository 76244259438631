import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";

const spaceSlice = createSlice({
  name: "space",
  initialState: {
    id: null,
    name: "",
    worksiteId: null,
    scenesIds: [],
    hiddenScenesIds: [],
    sceneId: null, // selected scene Id;
    status: "idle", // loading, loaded => used when loading the space.
    scenesIdsHash: "-", // used to detect changes on space definition.
    focusedSceneId: null, // scene used to set the coordinates system.
  },
  reducers: {
    setWorksiteId: (state, action) => {
      state.worksiteId = action.payload;
    },
    setScenesIds: (state, action) => {
      const {scenesIds} = action.payload;
      state.scenesIds = scenesIds;
      state.scenesIdsHash = scenesIds.sort().join("-");
    },
    setSceneId: (state, action) => {
      state.sceneId = action.payload;
    },
    setFocusedSceneId: (state, action) => {
      state.focusedSceneId = action.payload;
    },
    setSpaceId: (state, action) => {
      state.id = action.payload;
    },
    setSpace: (state, action) => {
      const {space, worksiteId} = action.payload;
      const scenesIds = space.data?.scenesIds ? [...space.data.scenesIds] : [];
      state.status = "idle";
      state.name = space.name;
      state.scenesIds = scenesIds;
      state.scenesIdsHash = scenesIds.sort().join("-");
      state.sceneId = null;
      state.worksiteId = worksiteId;
      state.id = space.id;
    },
    resetSpace: (state) => {
      state.status = "idle";
      state.name = null;
      state.scenesIds = [];
      state.scenesIdsHash = "-";
      state.sceneId = null;
      state.worksiteId = null;
      state.id = null;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    toggleSceneVisibility: (state, action) => {
      const sceneId = action.payload;
      if (state.hiddenScenesIds.includes(sceneId)) {
        const hidden = [...state.hiddenScenesIds];
        const scenes = hidden.filter((id) => id !== sceneId);
        state.hiddenScenesIds = scenes;
      } else {
        state.hiddenScenesIds = [...state.hiddenScenesIds, sceneId];
      }
    },
    showOneScene: (state, action) => {
      const sceneId = action.payload;
      const hiddenIds = state.scenesIds.filter((id) => id !== sceneId);
      state.hiddenScenesIds = hiddenIds;
    },
  },
});

export const selectorSpaceModels = (state) => {
  const scenes = [...state.scenes.items, ...state.scenes.remoteScenes.items];
  const models = state.viewer3D.models;
  const scenesIds = state.space.scenesIds;
  const scenesClientIds = scenesIds?.map(
    (id) => scenes.find((scene) => scene.id === id)?.clientId
  );
  return models.filter((m) => scenesClientIds.includes(m.sceneClientId));
};

export const selectorSpaceScenes = (state) => {
  const scenes = state.scenes.items;
  const scenesIds = state.space.scenesIds;
  const spaceLocalScenes = scenes.filter((s) => scenesIds.includes(s.id));
  return [...spaceLocalScenes];
};

export const {
  setWorksiteId,
  setSpaceId,
  setScenesIds,
  setSceneId,
  setFocusedSceneId,
  setSpace,
  resetSpace,
  setStatus,
  toggleSceneVisibility,
  showOneScene,
} = spaceSlice.actions;

export default spaceSlice.reducer;
