import SelectorAutocompleteMultiple from "Components/SelectorAutocompleteMultiple";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function FieldSelectorZones({scene, value, onChange, locked}) {
  // data

  const nameMap = useSceneModuleNameMap();
  let zones = useZonesByScene(scene);

  // helpers - options

  const options = zones.map((zone) => ({
    ...zone,
    name: `${zone.pdfModelName} • ${zone.name}`,
  }));
  // helpers

  const name = nameMap["ZONES"];

  // helpers - selection

  const selection = options.filter((z) => value.includes(z.id));

  // handlers

  function handleChange(selectedZones) {
    onChange(selectedZones.map((z) => z.id));
  }

  return (
    <SelectorAutocompleteMultiple
      name={name}
      options={options}
      value={selection}
      onChange={handleChange}
      disabled={locked}
    />
  );
}
