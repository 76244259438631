import {useState} from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Button,
  DialogActions,
} from "@mui/material";
import { useEffect } from "react";

export default function RenameDialog({
  open,
  onCancel,
  onConfirm,
  title,
  initial,
  forceDifferent = false
}) {
  
  // string

  const saveS = "Enregistrer";
  const cancelS = "Annuler";
  
  // local state
  
  const [newName, setNewName] = useState(initial);
  
  // handlers
  
  function handleNewNameChange(e) {
    setNewName(e.target.value);
  }
  
  async function handleRenameSave() {
    onConfirm(newName);
  }
  
  function handleRenameCancel() {
    onCancel();
  }

  // effect

  useEffect(() => {
    setNewName(initial);
  }, [initial])
  
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <InputBase
          value={newName}
          onChange={handleNewNameChange}
          sx={{minWidth: 350, maxWidth: 450}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameCancel}>{cancelS}</Button>
        <Button
          onClick={handleRenameSave}
          disabled={forceDifferent ? newName === initial : false}
        >
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}