import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticlesGroupTemp} from "../articlesSlice";

import {Box, Typography, InputBase} from "@mui/material";

export default function BlockEditableArticlesGroupName({
  articlesGroup,
  canEdit,
  defaultName = "",
  centered = false,
}) {
  const dispatch = useDispatch();

  // state

  let initName = defaultName && !canEdit ? defaultName : articlesGroup?.name;
  if (!initName) initName = "";

  const [name, setName] = useState(initName);
  const [focus, setFocus] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setName(initName);
  }, [initName]);

  // handlers

  function handleChange(e) {
    e.stopPropagation();
    e.preventDefault();
    let newName = e.target.value;
    setName(newName);
  }

  function handleBlur() {
    setIsEditing(false);
    setFocus(false);
    const newName = name.trim();
    if (newName !== articlesGroup.name) {
      dispatch(updateArticlesGroupTemp({id: articlesGroup.id, name}));
    }
  }

  function handleFocus() {
    setIsEditing(true);
    setFocus(true);
  }

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  useEffect(() => {
    return () => setFocus(false);
  }, []);

  return (
    <Box sx={{display: "flex", alignItems: "center", width: 1, height: "30px"}}>
      {canEdit ? (
        <InputBase
          fullWidth
          disableUnderline
          sx={{color: isEditing ? "primary.main" : "text.secondary", p: 0}}
          value={name}
          onChange={handleChange}
          onFocus={handleFocus}
          focus={focus}
          onBlur={handleBlur}
          onClick={handleClick}
          inputProps={{style: {textAlign: centered ? "center" : "left"}}}
        />
      ) : (
        <Typography sx={{fontSize: 13}}>{name}</Typography>
      )}
    </Box>
  );
}
