import {useSelector} from "react-redux";

export default function useSelectedMeasurementsModel() {
  const models = useSelector((s) => s.viewer3D.models);
  const id = useSelector((s) => s.measurements.selectedMeasurementsModelId);

  const c = models.find((c) => c.id === id);

  return c ? c : null;
}
