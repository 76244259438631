import {MathUtils} from "three";

// import {
//   IFCBUILDINGELEMENTPROXY,
//   IFCWALL,
//   IFCOPENINGELEMENT,
//   IFCSLAB,
//   IFCWALLSTANDARDCASE,
// } from "web-ifc";

export async function getIfcModelProject(ifcLoader, ifcModelID) {
  const tree = await ifcLoader.ifcManager.getSpatialStructure(ifcModelID);
  const props = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    tree.expressID
  );

  //const unitName = props?.UnitsInContext?.Units[0].Name.value;
  //const unitPrefix = props?.UnitsInContext?.Units[0].Prefix?.value;

  // with ifcworker, only first properties are computed.
  // we need to drill down properties one by one...
  const unitsExpressID = props.UnitsInContext.value;
  const unitsProps = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    unitsExpressID
  );
  const unitExpressID = unitsProps.Units[0].value;
  const unit = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    unitExpressID
  );
  const unitName = unit.Name.value;
  const unitPrefix = unit.Prefix?.value;

  let lengthScale = 1;
  if (unitName === "METRE" && unitPrefix === "MILLI") lengthScale = 0.001;
  return {props, lengthScale};
}

export async function getIfcModelSite(ifcLoader, ifcModelID) {
  const tree = await ifcLoader.ifcManager.getSpatialStructure(ifcModelID);

  // first load project to get the used unit.
  const {lengthScale} = await getIfcModelProject(ifcLoader, ifcModelID);

  const site = tree?.children[0];
  const props = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    site.expressID
  );

  const objectPlacementExpressID = props.ObjectPlacement.value;
  const objectPlacement = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    objectPlacementExpressID
  );
  const relativePlacement = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    objectPlacement.RelativePlacement.value
  );
  const _location = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    relativePlacement.Location.value
  );
  let _axis;
  if (relativePlacement.Axis?.value)
    _axis = await ifcLoader.ifcManager.getItemProperties(
      ifcModelID,
      relativePlacement.Axis?.value
    );
  let _refDirection;
  if (relativePlacement.RefDirection?.value)
    _refDirection = await ifcLoader.ifcManager.getItemProperties(
      ifcModelID,
      relativePlacement.RefDirection.value
    );
  // const _location = props.ObjectPlacement.RelativePlacement.Location;
  // const _axis = props.ObjectPlacement.RelativePlacement.Axis;
  // const _refDirection = props.ObjectPlacement.RelativePlacement.RefDirection;
  const location = {
    expressID: _location?.expressID,
    x: _location?.Coordinates[0].value * lengthScale,
    y: _location?.Coordinates[1].value * lengthScale,
    z: _location?.Coordinates[2].value * lengthScale,
  };

  const axis = {
    expressID: _axis?.expressID,
    x: _axis?.DirectionRatios[0].value,
    y: _axis?.DirectionRatios[1].value,
    z: _axis?.DirectionRatios[2].value,
  };

  const refDirection = {
    expressID: _refDirection?.expressID,
    x: _refDirection?.DirectionRatios[0].value,
    y: _refDirection?.DirectionRatios[1].value,
    z: _refDirection?.DirectionRatios[2].value,
    theta: function () {
      if (_refDirection) {
        return (Math.atan2(this.y, this.x) * 180) / Math.PI;
      } else {
        return 0;
      }
    },
  };

  const siteProps = {location, axis, refDirection, props};
  return siteProps;
}

export async function getIfcModelStoreys(ifcLoader, ifcModelID) {
  const tree = await ifcLoader.ifcManager.getSpatialStructure(ifcModelID);
  const storeys = tree?.children[0]?.children[0]?.children;
  return storeys;
}

export async function getIfcModelTypes(ifcLoader, ifcModelID) {
  const types = {
    // IfcWall: {code: IFCWALL},
    // IfcWallStandardCase: {code: IFCWALLSTANDARDCASE},
    // IfcSlab: {code: IFCSLAB},
    // IfcOpeningElement: {code: IFCOPENINGELEMENT},
    // IfcBuildingElementProxy: {code: IFCBUILDINGELEMENTPROXY},
  };
  const typesNames = Object.keys(types);

  typesNames.forEach(async (typeName) => {
    const expressIDs = await ifcLoader.ifcManager.getAllItemsOfType(
      ifcModelID,
      types[typeName].code
    );
    types[typeName].expressIDs = expressIDs;
  });

  return types;
}

export async function getIfcSubsets(ifcLoader, ifcModelID, modelId) {
  const tree = await ifcLoader.ifcManager.getSpatialStructure(ifcModelID);
  const {storeysArray, typesArray, subsetsTree} = getLeafNodes(tree);

  async function getProps(expressID) {
    try {
      if (!expressID) return "unknown";
      const props = await ifcLoader.ifcManager.getItemProperties(
        ifcModelID,
        expressID,
        false
      );
      return {
        name: props?.Name?.value,
        height: props?.Elevation?.value,
      };
    } catch (e) {
      console.log(e);
    }
  }
  // storeys

  const ifcStoreys = await Promise.all(
    storeysArray?.map(async (id) => {
      const props = await getProps(id);
      const name = props.name;
      const height = props.height;
      return {
        expressID: id?.toString(),
        name,
        height,
        ifcModelID,
        modelId,
        visible: true,
      };
    })
  );

  // types

  const ifcTypes = typesArray?.map((type) => {
    return {
      type,
      ifcModelID,
      visible: true,
      modelId,
    };
  });

  // subsets

  const ifcSubsets = [];

  Object.keys(subsetsTree).forEach((storeyID) => {
    Object.keys(subsetsTree[storeyID]).forEach((type) => {
      const uuid = MathUtils.generateUUID();
      ifcSubsets.push({
        uuid,
        ifcModelID,
        storeyExpressID: storeyID,
        type,
        modelId,
        ids: subsetsTree[storeyID][type],
      });
    });
  });

  return {ifcSubsets, ifcStoreys, ifcTypes, tree, modelId};
}
export async function getIfcItemProperties(ifcLoader, ifcModelID, expressID) {
  const rawProps = await ifcLoader.ifcManager.getItemProperties(
    ifcModelID,
    expressID,
    false
  );
  const name = rawProps.Name.value;
  return {name};
}

/*
 * rootNode = {children:[{prop,children:[...]}]}
 */

function getLeafNodes(rootNode) {
  const subsetsTree = {};
  const typesArray = [];
  const storeysArray = [];

  let storeyID;

  function updateSubsetsTree(node, storeyID) {
    const {expressID, type} = node;
    if (subsetsTree[storeyID]) {
      if (subsetsTree[storeyID][type]) {
        subsetsTree[storeyID][type].push(expressID);
      } else {
        subsetsTree[storeyID][type] = [expressID];
      }
    } else {
      subsetsTree[storeyID] = {[type]: [expressID]};
    }
  }

  function updateTypesArray(type) {
    if (!typesArray.includes(type)) typesArray.push(type);
  }

  function updateStoreysArray(storeyID) {
    if (!storeysArray.includes(storeyID)) storeysArray.push(storeyID);
  }

  function traverse(acc, node) {
    if (node.children.length > 0) {
      if (node.type === "IFCBUILDINGSTOREY") storeyID = node.expressID;
      return node.children.reduce(traverse, acc);
    }
    acc.push(node);
    // side effects
    updateSubsetsTree(node, storeyID);
    updateTypesArray(node.type);
    updateStoreysArray(storeyID);
    return acc;
  }

  traverse([], rootNode);

  return {subsetsTree, typesArray, storeysArray};
}

export async function getSpatialStructureLeaves(ifcLoader, ifcModelID) {
  const tree = await ifcLoader.ifcManager.getSpatialStructure(ifcModelID);
  const leaves = getLeafNodes(tree);
  return leaves;
}

export function flatSpatialStructure(spatialStructure) {
  const flat = [];
  let storeyID;

  function traverse(acc, node) {
    if (node.children.length > 0) {
      if (node.type === "IFCBUILDINGSTOREY") storeyID = node.expressID;
      return node.children.reduce(traverse, acc);
    }
    acc.push(node);
    flat.push({expressID: node.expressID, storeyID, type: node.type});
    return acc;
  }

  traverse([], spatialStructure);

  return flat;
}
