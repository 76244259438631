import React from "react";
import {Box} from "@mui/material";

export default function ImageBasic({url, outlined, grayscale, innerSize=false}) {
  return (
    <Box
      sx={{
        backgroundSize: innerSize ? "contain" : "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        position: "relative",
        backgroundImage: `url(${url})`,
        height: 1,
        width: 1,
        borderRadius: "4px",
        ...(outlined && {
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }),
        ...(grayscale && {filter: "grayscale(1)"}),
      }}
    />
  );
}
