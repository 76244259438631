import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import {
  setSelectedElementTypeId,
  updateTempMeasurementFromElementType,
  updateTempMeasurement,
  setSelectedFormGroup,
} from "../measurementsSlice";
import useDrawingShapeImage from "../useDrawingShapeImage";

import useSelectedElementTypeFromMeasurements from "../useSelectedElementTypeFromMeasurements";

import useKvtMeasurementV2 from "Features/kvform/hooks/useKvtMeasurementV2";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import Stretch from "Features/ui/components/Stretch";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

export default function FormMeasurementInViewerCreate({
  tempMeasurement,
  scene,
  editorPdf,
}) {
  const dispatch = useDispatch();
  // data

  const drawingShape = useDrawingShapeImage(tempMeasurement?.drawingShape);
  const elementType = useSelectedElementTypeFromMeasurements(scene);
  const elementTypes = useElementTypesBySceneProxy(scene).filter(
    (t) => !t.isTitle && !t.isAgg
  );
  const sectors = useSectorsByScene(scene);
  const phases = usePhasesByScene(scene);
  const selectedFormGroup = useSelector(
    (s) => s.measurements.selectedFormGroup
  );
  const isCreatingElementType = useSelector(
    (s) => s.elementTypes.isCreatingElementType
  );
  const versions = useVersionsByScene(scene);

  // helpers

  const item = {...tempMeasurement, helperImage: drawingShape};
  const manager = editorPdf?.annotationsManager.measurementsPdfManager;
  item.version = versions.find((v) => v.id === tempMeasurement?.versionId);
  item.sector = sectors.find((s) => s.id === tempMeasurement?.sectorId);
  item.phase = phases.find((s) => s.id === tempMeasurement?.phaseId);
  const kvt = useKvtMeasurementV2(scene, item);

  // helper - hidden fields

  const hiddenFields = [
    "quantities",
    "unit",
    "count",
    "length",
    "area",
    "volume",
    "zone",
  ];

  // helpers lockedFields
  const lockedFields = isCreatingElementType ? [] : ["drawingShape"];
  if (elementType?.dim1) lockedFields.push("dim1");
  if (elementType?.dim2) lockedFields.push("dim2");

  // handlers

  function handleChange(m) {
    console.log("updateTempMeas21", m);
    m.phaseId = m.phase?.id;
    m.versionId = m.version?.id;
    if (m.elementType?.id !== m.elementTypeId) {
      dispatch(setSelectedElementTypeId(m.elementTypeId));
      dispatch(
        updateTempMeasurementFromElementType(
          elementTypes.find((t) => t.id === m.elementTypeId)
        )
      );
    } else {
      if (typeof m.offset === "number" && m.offset !== tempMeasurement.offset) {
        m.zInf = null;
        m.zSup = null;
        m.zFrom = "offset";
      } else if (
        typeof m.zInf === "number" &&
        m.zInf !== tempMeasurement.zInf
      ) {
        m.offset = null;
        m.zSup = null;
        m.zFrom = "zInf";
      } else if (
        typeof m.zSup === "number" &&
        m.zSup !== tempMeasurement.zSup
      ) {
        m.offset = null;
        m.zInf = null;
        m.zFrom = "zSup";
      }
      if (m.heightE || m.heightN) {
        if (!m.height) {
          if (m.heightE) {
            if (m.heightN) m.height = m.heightE + m.heightN;
            else m.height = m.heightE;
          } else m.height = m.heightN;
        } else if (!m.heightE) {
          m.heightE = m.height - m.heightN;
        } else if (!m.heightN) {
          m.heightN = m.height - m.heightE;
        }
        if (m.height !== item.height && typeof m.height === "number") {
          m.heightE = null;
          m.heightN = null;
        } else if (
          m.heightE !== item.heightE &&
          typeof m.heightE === "number"
        ) {
          m.heightN = m.height - m.heightE;
        } else if (
          m.heightN !== item.heightN &&
          typeof m.heightN === "number"
        ) {
          m.heightE = m.height - m.heightN;
        }
      }
      dispatch(updateTempMeasurement(m));
      manager?.setTempMeasurement(m);
    }
  }

  function handleGroupChange(group) {
    dispatch(setSelectedFormGroup(group));
  }

  return (
    <Box
      sx={{
        height: 1,
      }}
    >
      <Stretch>
        <KeyValueForm
          scene={scene}
          item={item}
          template={kvt}
          onChange={handleChange}
          hiddenFields={hiddenFields}
          lockedFields={lockedFields}
          group={selectedFormGroup}
          onGroupChange={handleGroupChange}
        />
      </Stretch>
    </Box>
  );
}
