import {Box} from "@mui/material";

import SelectorMarkersModel from "./SelectorMarkersModel";
import ListSceneMarkers from "Features/leftPanel/components/ListSceneMarkers";

export default function SectionMarkersInLeftPanel({
  scene,
  caplaEditor,
}) {
  return (
    <Box sx={{p: 1}}>
      <SelectorMarkersModel scene={scene} editor3d={caplaEditor?.editor3d} />

      <ListSceneMarkers
        scene={scene}
        caplaEditor={caplaEditor}
      />
    </Box>
  );
}
