import {useEffect, useState, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Typography,
  Box,
  LinearProgress,
  Stepper,
  Step,
  StepLabel,
  Button,
  IconButton,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
} from "@mui/material";
import {Close} from "@mui/icons-material";

import ActionPopper from "./ActionPopper";
import ActionSelectMarker from "./ActionSelectMarker";

import DisplayBox from "Components/DisplayBox";

import {
  setMode,
  setStep,
  setSubStep,
  setPositioningObject,
  // setConfiguringEntity,
  setConfiguring,
  setConfigCenter,
} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function ProcessConfigTwoMarkers({editor3d, onClose}) {
  const {t} = useTranslation(["viewer3D", "common"]);
  const dispatch = useDispatch();

  // initializing

  useEffect(() => {
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
    virtualElementRef.current = {getBoundingClientRect: generateBBCR};
  }, []);

  // strings

  const title = t("viewer3D:config.processConfigTwoMarkers.title");
  const step0Name = t("viewer3D:config.processConfigTwoMarkers.step0.name");
  const step1Name = t("viewer3D:config.processConfigTwoMarkers.step1.name");
  const step2Name = t("viewer3D:config.processConfigTwoMarkers.step2.name");
  const step3Name = t("viewer3D:config.processConfigTwoMarkers.step3.name");
  const step0Description = t(
    "viewer3D:config.processConfigTwoMarkers.step0.description"
  );
  const step1Description = t(
    "viewer3D:config.processConfigTwoMarkers.step1.description"
  );
  const step2Description = t(
    "viewer3D:config.processConfigTwoMarkers.step2.description"
  );
  const horizontalString = t(
    "viewer3D:config.processConfigTwoMarkers.step0.horizontal"
  );
  const verticalString = t(
    "viewer3D:config.processConfigTwoMarkers.step0.vertical"
  );
  const nextButtonString = capitalizeStart(t("common:next"));
  const saveButtonString = capitalizeStart(t("common:save"));

  // steps

  const steps = {
    SLOPE: {name: step0Name},
    MARKER1: {name: step1Name},
    MARKER2: {name: step2Name},
    END: {name: step3Name},
  };

  // virtual element for popper

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef();

  // data

  const mode = useSelector((state) => state.viewer3D.mode);
  const step = useSelector((state) => state.viewer3D.step);
  const subStep = useSelector((state) => state.viewer3D.subStep);
  const activeStep = Object.keys(steps).indexOf(step);
  const configuredModel = useSelector(
    (state) => state.viewer3D.configuredModel
  );

  // local data

  // const [position, setPosition] = useState({x: 0, y: 0, z: 0}); // position of actionRef
  // const [screenPos, setScreenPos] = useState({x: 0, y: 0, z: 0});

  const [marker1, setMarker1] = useState(); // markers in mainScene
  const [marker2, setMarker2] = useState();

  const [orientation, setOrientation] = useState("HORIZONTAL");

  // helper

  function killProcess() {
    dispatch(setPositioningObject({editor: false}));
    dispatch(setMode("DEFAULT"));
    dispatch(setStep("DEFAULT"));
    dispatch(setSubStep("DEFAULT"));
    editor3d?.switchToMode("PICKING");
    onClose();
  }

  function setEntityNewModel() {
    editor3d?.configCenter.setEntityNewModel({
      marker1,
      marker2,
      slope: orientation,
    });
  }

  function setPartNewTransformation() {
    editor3d?.configCenter.setPartNewTransformation({
      marker1,
      marker2,
      slope: orientation,
    });
  }

  function endProcess() {
    editor3d?.configCenter.saveConfigEntity();
    editor3d?.configCenter.close();
    editor3d?.switchToViewMode("VIEW_3D");
    if (editor3d) editor3d.handleEditorClick = () => {};
    dispatch(setMode("PICKING"));
    dispatch(setConfiguring(false));
    dispatch(setConfigCenter(false));
    dispatch(setPositioningObject({editor: false}));
    //dispatch(setConfiguringEntity(undefined));
  }

  // handlers

  function handleOrientationChange(e) {
    setOrientation(e.target.value);
  }

  function handleSlopeSave() {
    dispatch(setPositioningObject({editor: true}));
    dispatch(setStep("MARKER1"));
  }

  function handleEditorClick(props) {
    if (mode === "CONFIG_TWO_MARKERS") {
      const {screenP, sceneP} = props;

      virtualElementRef.current.getBoundingClientRect = generateBBCR(
        screenP.x,
        screenP.y
      );

      // if (sceneP.x) setPosition(sceneP);

      if (step === "MARKER1") {
        console.log("locate marker 1 at", sceneP);
        editor3d?.configCenter.setPositionOfMarker(1, sceneP);
        dispatch(setSubStep("SELECT"));
      }
      if (step === "MARKER2") {
        editor3d?.configCenter.setPositionOfMarker(2, sceneP);
        dispatch(setSubStep("SELECT"));
      }
    }
  }

  function handleMarkerSelect(value) {
    if (step === "MARKER1") {
      setMarker1(value);
      dispatch(setStep("MARKER2"));
      dispatch(setSubStep("POSITION"));
    }
    if (step === "MARKER2") {
      setMarker2(value);
      dispatch(setStep("END"));
      dispatch(setSubStep("DEFAULT"));
    }
  }

  function handleSave() {
    if (configuredModel?.parsedObjectEntity?.partId) {
      setPartNewTransformation();
    } else {
      setEntityNewModel();
    }
    endProcess();
  }

  // update handlers

  useEffect(() => {
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
  }, [mode, step]);

  // clean up

  useEffect(() => {
    return () => {
      if (editor3d) editor3d.onProcessEditorClick = () => {};
    };
  }, []);
  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        background: (theme) => theme.palette.common.white,
        pb: 2,
      }}
    >
      <LinearProgress sx={{width: 1}} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{p: 2}}>
          <b>{title}</b>
        </Typography>
        <IconButton sx={{mr: 1}} onClick={killProcess}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={{width: 1, mt: 2}}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {Object.keys(steps).map((step) => (
            <Step key={step} size="small">
              <StepLabel>
                <Typography variant="body2">{steps[step].name}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {virtualElementRef?.current && (
        <ActionPopper
          anchorEl={virtualElementRef.current}
          open={subStep === "SELECT"}
        >
          <ActionSelectMarker onSelect={handleMarkerSelect} />
        </ActionPopper>
      )}

      <DisplayBox open={mode === "CONFIG_TWO_MARKERS" && step === "SLOPE"}>
        <FormControl component="fieldset" sx={{p: 2}}>
          <Typography variant="body2">
            <b>{step0Description}</b>
          </Typography>
          <RadioGroup
            value={orientation}
            onChange={handleOrientationChange}
            name="customized-radios"
            row
          >
            <FormControlLabel
              value="HORIZONTAL"
              control={<Radio size="small" />}
              label={
                <Typography variant="body2">{horizontalString}</Typography>
              }
            />
            <FormControlLabel
              value="VERTICAL"
              control={<Radio size="small" />}
              label={<Typography variant="body2">{verticalString}</Typography>}
            />
          </RadioGroup>
        </FormControl>
        <Button
          sx={{ml: 2}}
          onClick={handleSlopeSave}
          variant="contained"
          size="small"
        >
          {nextButtonString}
        </Button>
      </DisplayBox>

      <DisplayBox open={mode === "CONFIG_TWO_MARKERS" && step === "MARKER1"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step1Description}
        </Typography>
      </DisplayBox>

      <DisplayBox open={mode === "CONFIG_TWO_MARKERS" && step === "MARKER2"}>
        <Typography variant="body2" sx={{p: 2}}>
          {step2Description}
        </Typography>
      </DisplayBox>

      <DisplayBox open={mode === "CONFIG_TWO_MARKERS" && step === "END"}>
        <Button sx={{m: 2}} disabled={step !== "END"} onClick={handleSave}>
          {saveButtonString}
        </Button>
      </DisplayBox>
    </Box>
  );
}
