import {client} from "API/capla360";

export async function fetchProgressRecordsService({accessToken, listingId}) {
  const response = await client.get(`listings/${listingId}/records/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export const fetchProgressRecordsBySceneService = async ({
  accessToken,
  sceneId,
}) => {
  const response = await client.get(`/scenes/${sceneId}/records/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export async function createProgressRecordService({
  accessToken,
  progressRecord,
  listingId,
}) {
  const response = await client.post(
    `listings/${listingId}/records/`,
    progressRecord,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}

export async function fetchProgressRecordService({
  accessToken,
  progressRecordId,
}) {
  const response = await client.get(`records/${progressRecordId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function updateProgressRecordService({
  accessToken,
  progressRecord,
}) {
  const progressRecordId = progressRecord.id;
  const response = await client.put(
    `records/${progressRecordId}`,
    progressRecord,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}

export async function deleteProgressRecordService({
  accessToken,
  progressRecordId,
}) {
  const response = await client.delete(`records/${progressRecordId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {response: response.data, progressRecordId};
}
