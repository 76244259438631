export default function testIsBuggedGeometry(vertices) {
  try {
    let isBugged = false;
    let limit = 50; // object >50m
    const verticesCount = vertices.length / 3;
    let i = 0;

    const vx = [];
    const vy = [];
    const vz = [];

    while (i < verticesCount && !isBugged) {
      const x = vertices[3 * i];
      const y = vertices[3 * i + 1];
      const z = vertices[3 * i + 2];
      if (
        isNaN(x) ||
        isNaN(y) ||
        isNaN(z) ||
        Math.abs(x) === Infinity ||
        Math.abs(y) === Infinity ||
        Math.abs(z) === Infinity
      ) {
        isBugged = true;
        console.log("[BUG GEOMETRY] xyz", x, y, z);
      } else {
        vx.push(vertices[3 * i]);
        vy.push(vertices[3 * i + 1]);
        vz.push(vertices[3 * i + 2]);
      }
      i += 1;
    }

    if (!isBugged) {
      const deltaX = vx.length > 0 ? Math.max(...vx) - Math.min(...vx) : 0;
      const deltaY = vy.length > 0 ? Math.max(...vy) - Math.min(...vy) : 0;
      const deltaZ = vz.length > 0 ? Math.max(...vz) - Math.min(...vz) : 0;

      if (deltaX > limit || deltaY > limit || deltaZ > limit) {
        console.log("[BUG GEOMETRY] delta", deltaX, deltaY, deltaZ);
        isBugged = true;
      }
    }
    return isBugged;
  } catch (e) {
    console.log("error", e);
  }
}
