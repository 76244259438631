import {useSelector} from "react-redux";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function useRessourcesGroupsProxyByScene(scene, options) {
  //
  const scope = useSelectedScope();

  // groupProxy : {id,name,fromScene} (no ressources)

  // remote groups
  const remoteGroups = useSelector((s) => s.ressources.ressourcesGroups);
  const remoteProxy = remoteGroups.map((group) => ({
    id: group.id,
    name: group.name,
    fromScene: false,
  }));

  // groups from scene
  let ressourcesFromScene = scene?.data?.ressources;
  if (!ressourcesFromScene) ressourcesFromScene = [];
  const proxyMap = {};
  const proxyFromScene = [];
  ressourcesFromScene.forEach((res) => {
    if (!proxyMap[res.group]) {
      proxyFromScene.push({
        id: res.group ?? "undefined",
        name: res.group ?? "-?-",
        fromScene: true,
      });
    }
    proxyMap[res.group] = "true";
  });

  // all proxy

  let allProxies = [...remoteProxy, ...proxyFromScene];

  if (options?.sortByName) {
    allProxies = allProxies.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (options?.filterByScope && scope?.data?.ressourcesGroupIds?.length > 0) {
    allProxies = allProxies.filter((g) =>
      scope?.data?.ressourcesGroupIds?.includes(g.id)
    );
  }

  return allProxies;
}
