import {useSelector} from "react-redux";

export default function useMarkersByScene(sceneClientId) {
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.type === "MARKERS" && m.sceneClientId === sceneClientId
  );
  const markers = [];
  models.forEach((model) => {
    let elements = model.elements?.items;
    if (!elements) elements = [];
    const newMarkers = elements.map((e) => ({
      ...e,
      modelId: model.id,
      modelName: model.name,
    }));
    markers.push(...newMarkers);
  });
  return markers;
}
