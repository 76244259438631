import {useSelector, useDispatch} from "react-redux";

import usePackagesByPdfList from "../hooks/usePackagesByPdfList";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";
import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";

import {setSelectedPackageId} from "../packagesSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import {setLastPackageId} from "../packagesSlice";
import {setMeasurementsModelsInScope} from "Features/measurements/measurementsSlice";

import {Box, List, Typography} from "@mui/material";

import ListItemButtonPackage from "./ListItemButtonPackage";

import getModelLoadingStatusNum from "Features/overviewer/utils/getModelLoadingStatusNum";
import {setModelIdBeingLoadedInViewer3d} from "Features/overviewer/overviewerSlice";
import useEditPackage from "../hooks/useEditPackage";

export default function SectionPackagesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const noPackageS = "0 calque de repérage";

  // data

  const options = {
    filterBySelectedVersionForMeasurements: true,
    filterByScope: true,
  };
  const models = usePackagesByPdfList(scene, options);
  const selectedPackageId = useSelector((s) => s.packages.selectedPackageId);
  const versions = useVersionsByScene(scene);
  const selectedVersionForMeasurements = useSelectedVersionForMeasurements();
  const loadingStatusMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );

  const editPackage = useEditPackage(scene);

  // helpers

  const noPackage = models.length === 0;

  // handlers

  function handleModelClick(model) {
    console.log("click on pdfPAckage", model);
    dispatch(setModelIdBeingLoadedInViewer3d(model.id));
    if (!model.isPdf) {
      const pdfModelId = model?.fromModel?.modelId;
      console.log("pdfModelId", pdfModelId);
      let newSelectedPackageId = model.id;
      if (newSelectedPackageId === selectedPackageId) {
        newSelectedPackageId = null;
      }
      if (newSelectedPackageId) {
        dispatch(setSelectedPackageId(newSelectedPackageId));
        dispatch(setSelectedPdfModelId(pdfModelId));
        dispatch(setSelectedMeasurementsModelId(newSelectedPackageId));
        //dispatch(setLastPackageId(newSelectedPackageId));
        // caplaEditor?.measDataManager.setSelectedModelById(newSelectedPackageId);
      } else {
        dispatch(setSelectedPackageId(null));
        dispatch(setSelectedMeasurementsModelId(null));
        dispatch(setMeasurementsModelsInScope([]));
        // caplaEditor?.measDataManager.setSelectedModelById(null);
      }
    }
  }

  function handleLinkClick(model) {
    editPackage(model.id);
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        minHeight: 0,
      }}
    >
      {noPackage ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "grey.400",
            width: 1,
            height: 1,
          }}
        >
          <Typography>{noPackageS}</Typography>
        </Box>
      ) : (
        <List dense disablePadding>
          {models.map((model) => {
            const selected = model.id === selectedPackageId;
            const modificationsCount =
              caplaEditor?.measDataManager.getModificationsCountForModelId(
                model.id
              );
            const loadingStatusNum = getModelLoadingStatusNum(
              model.id,
              loadingStatusMap
            );

            return (
              <ListItemButtonPackage
                key={model.id}
                model={model}
                loadingStatusNum={loadingStatusNum}
                onClick={handleModelClick}
                onLinkClick={handleLinkClick}
                selected={selected}
                versions={versions}
                selectedVersionForMeasurements={selectedVersionForMeasurements}
                modificationsCount={modificationsCount}
              />
            );
          })}
        </List>
      )}
    </Box>
  );
}
