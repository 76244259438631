import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {Functions, Toc} from "@mui/icons-material";

import SectionListAffectRelations from "./SectionListAffectRelations";
import SectionUpdateRelationsFormula from "./SectionUpdateRelationsFormula";

import {setMode} from "../relationsSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import sortByNum from "Utils/sortByNum";

export default function TypesRessourcesRelations({
  scene,
  item,
  value,
  onChange,
}) {
  const dispatch = useDispatch();

  // strings

  const listS = "Associer des relations";
  const functionsS = "Changer les formules";

  // data

  const module = useSceneModule();
  const mode = useSelector((s) => s.relations.mode);
  const types = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const typesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(scene, {
    filterByScope: true,
  });
  const selectedTypesGroupForList = useSelector(
    (s) => s.relations.selectedTypesGroupForList
  );
  const ressourcesGroups = useRessourceGroupsByScene(scene, {
    filterByScope: true,
  });
  const selectedRessourcesGroupForList = useSelector(
    (s) => s.relations.selectedRessourcesGroupForList
  );
  const relations = useSelector((s) => s.relations.relationsTypesRessources);

  // helpers

  let groups;
  // let relations;
  let selectedGroup;
  let itemRelations = [];
  if (module === "ELEMENT_TYPES") {
    selectedGroup = selectedTypesGroupForList;
    groups = ressourcesGroups.map((g) => ({
      id: g ? g : "-?-",
      name: g ? g : "-?-",
    }));
    if (relations) {
      const itemRelationKeys = Object.keys(relations).filter((k) =>
        k.startsWith(item.id)
      );
      itemRelations = itemRelationKeys.map((k) => relations[k]);
    }
  } else {
    selectedGroup = selectedRessourcesGroupForList;
    groups = typesGroups;
    if (relations) {
      const itemRelationKeys = Object.keys(relations).filter((k) =>
        k.endsWith(item.id)
      );
      itemRelations = itemRelationKeys.map((k) => relations[k]);
    }
  }

  groups = groups.sort((a, b) => a.name?.localeCompare(b.name));

  let items = [];
  let itemRelationsSet;
  if (module === "ELEMENT_TYPES") {
    items = ressources.filter((r) => r.group === selectedGroup?.name);
    itemRelationsSet = new Set(itemRelations?.map((r) => r.resId));
  } else {
    items = types.filter(
      (t) => t.groupId === selectedGroup?.id || t.group === selectedGroup?.name
    );
    itemRelationsSet = new Set(itemRelations?.map((r) => r.typeId));
  }
  items = items.sort((a, b) => sortByNum(a.num, b.num));

  let rows = [];
  const usedGroups = {};
  const countByGroup = {};
  groups.forEach((g) => {
    countByGroup[g.id] = 0;
    let groupItems;
    if (module === "ELEMENT_TYPES")
      groupItems = ressources
        .filter((r) => r.group === g?.name)
        .sort((a, b) => sortByNum(a.num, b.num));
    else
      groupItems = types
        .filter((t) => t.groupId === g?.id || t.group === g?.name)
        .sort((a, b) => sortByNum(a.num, b.num));
    groupItems.forEach((i) => {
      if (itemRelationsSet.has(i.id)) {
        countByGroup[g.id] += 1;
        if (g.name in usedGroups) {
          rows.push({...i, isGroup: false});
        } else {
          usedGroups[g.name] = groupItems;
          rows.push({...g, isGroup: true});
          rows.push({...i, isGroup: false});
        }
      }
    });
  });

  const sum = Object.values(countByGroup).reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );
  let relationsS = `${sum} `;
  if (module === "ELEMENT_TYPES") relationsS += "articles";
  else if (module === "RESSOURCES") relationsS += "types";

  // handlers

  const handleModeChange = (m) => {
    dispatch(setMode(m));
  };

  function handleChange() {
    onChange(value ? value : []);
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <Typography variant="h6">{relationsS}</Typography>
        <ToggleButtonGroup
          exclusive
          value={mode}
          onChange={(_, v) => handleModeChange(v)}
          size="small"
          sx={{
            height: "24px",
          }}
        >
          <ToggleButton value="list" sx={{width: "24px"}}>
            <Tooltip title={listS}>
              <Toc fontSize="small" />
            </Tooltip>
          </ToggleButton>
          <ToggleButton value="functions" sx={{width: "24px"}}>
            <Tooltip title={functionsS}>
              <Functions fontSize="small" />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {mode === "list" ? (
        <SectionListAffectRelations
          module={module}
          groups={groups}
          countByGroup={countByGroup}
          selectedGroup={selectedGroup}
          item={item}
          items={items}
          relations={relations}
          itemRelationsSet={itemRelationsSet}
          onChange={handleChange}
        />
      ) : (
        <SectionUpdateRelationsFormula
          module={module}
          usedGroups={usedGroups}
          item={item}
          rows={rows}
          relations={relations}
          itemRelations={itemRelations}
          onChange={handleChange}
        />
      )}
    </Box>
  );
}
