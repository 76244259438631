import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import {
  Add,
  Delete,
  LocationSearching as Locate,
  Save,
} from "@mui/icons-material";

import DialogLocateMarker from "./DialogLocateMarker";
import DialogSaveMarker from "./DialogSaveMarker";

import {
  // setDetailSection,
  // setSelection,
  setPositioningObject,
  setMode,
  setStep,
  deleteMarker,
} from "../viewer3DSlice";

import useTranslation from "Features/translations/useTranslation";

export default function CardMarkersSelector({editor3d, scene}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("viewer");

  // data

  const markers = useSelector((state) => state.viewer3D.markers);

  // state

  const [openLocate, setOpenLocate] = useState(false);
  const [openSave, setOpenSave] = useState(false);

  const [locatedMarker, setLocatedMarker] = useState(null); // used for location & saving the marker.

  // handlers

  function handleMarkerClick(marker) {
    // const entity = {
    //   type: "MARKER",
    //   model: marker,
    // };
    // dispatch(setSelection(entity));
    // dispatch(setDetailSection(true));

    const entity = editor3d?.getEntityById("MARKER", marker.id);
    editor3d?.cameras.fitCameraTo({
      objects: [entity.object],
      controls: editor3d?.controls.activeControls,
    });
  }

  function handleAddMarker() {
    editor3d?.switchToMode("POSITIONING_MARKER");

    dispatch(setPositioningObject({editor: true}));
    dispatch(setMode("PROCESS_ADD_MARKER"));
    dispatch(setStep("POSITION"));
  }

  function handleDeleteClick(id) {
    dispatch(deleteMarker(id));
    const markerEntity = editor3d?.entities.find(
      (e) => e.type === "MARKER" && e.entityID === id
    );
    markerEntity.delete();
  }

  // handlers - location dialog

  function handleLocateClick(marker) {
    setOpenLocate(true);
    setLocatedMarker(marker);
  }
  function handleLocateClose() {
    setOpenLocate(false);
  }

  // handlers - save dialog

  function handleSaveClick(marker) {
    setOpenSave(true);
    setLocatedMarker(marker);
  }
  function handleSaveClose() {
    setOpenSave(false);
  }
  return (
    <>
      <Card sx={{width: 1}}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">
              <b>{t("viewer3D:markers.select.title")}</b>
            </Typography>
            <IconButton onClick={handleAddMarker} color="primary" size="small">
              <Add />
            </IconButton>
          </Box>
          <List dense sx={{maxHeight: 240, overflow: "auto"}}>
            {markers.map((marker) => {
              return (
                <ListItem
                  key={marker.id}
                  disablePadding
                  secondaryAction={
                    <Box sx={{display: "flex", alignItems: "center"}}>
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => handleDeleteClick(marker.id)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => handleLocateClick(marker)}
                      >
                        <Locate fontSize="small" />
                      </IconButton>
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => handleSaveClick(marker)}
                      >
                        <Save fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemButton
                    dense
                    onClick={() => handleMarkerClick(marker)}
                  >
                    <Typography variant="body2">{`#${marker.number}`}</Typography>
                    <Typography variant="body2" noWrap>
                      {marker.description}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>

      <DialogLocateMarker
        open={openLocate}
        onClose={handleLocateClose}
        locatedMarker={locatedMarker}
      />

      <DialogSaveMarker
        open={openSave}
        onClose={handleSaveClose}
        marker={locatedMarker}
        scene={scene}
        editor3d={editor3d}
      />
    </>
  );
}
