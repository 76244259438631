import {useState} from "react";
import {useSelector} from "react-redux";

import {Box, Paper} from "@mui/material";

import ButtonSettingsToolbar from "./ButtonSettingsToolbar";
import ToolbarSettings from "./ToolbarSettings";
import ToggleBoxSelection from "./ToggleBoxSelection";
// import ToggleEdge from "./ToggleEdge";
// import ToggleGrid from "./ToggleGrid";
import TogglePointerMode from "./TogglePointerMode";
import ToggleSnapping from "./ToggleSnapping";
import ButtonMoreViewerOptionsInToolbar from "./ButtonMoreViewerOptionsInToolbar";

import useSceneModule from "Features/navigation/useSceneModule";
import useIsMobile from "Features/ui/useIsMobile";

export default function ToolbarBottomLeftViewer3D({editor3d, scene}) {
  // data

  const sceneModule = useSceneModule();
  const isMobile = useIsMobile();
  const os = useSelector((s) => s.ui.openSections);

  // state

  const [mode, setMode] = useState("DEFAULT");

  // handlers - settings

  function handleSettingsClick() {
    setMode("SETTINGS");
  }
  function handleSettingsClose() {
    setMode("DEFAULT");
  }

  // helpers - pdfAnd3D

  const pdfAnd3D = os.viewer3D && os.fixedViewersBox;

  // helpers - showSettings

  const showSettings = false;

  // helpers - showers

  const showMultiSelect =
    sceneModule === "PACKAGES" ||
    sceneModule === "EDIT_MEASUREMENTS" ||
    sceneModule === "ELEMENT_TYPES" ||
    sceneModule === "MEASUREMENTS" ||
    sceneModule === "ARTICLES";

  const showSnapping =
    !["INPUTS"].includes(sceneModule) && !isMobile && !pdfAnd3D;

  return (
    <Paper
      elevation={12}
      sx={{
        bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      {mode === "DEFAULT" && (
        <Box sx={{display: "flex", alignItems: "center"}}>
          {showSettings && (
            <ButtonSettingsToolbar onClick={handleSettingsClick} />
          )}
          {!isMobile && <TogglePointerMode editor3d={editor3d} />}
          {showSnapping && <ToggleSnapping editor3d={editor3d} />}
          {showMultiSelect && <ToggleBoxSelection editor3d={editor3d} />}
          <ButtonMoreViewerOptionsInToolbar editor3d={editor3d} scene={scene} />
        </Box>
      )}
      {mode === "SETTINGS" && (
        <ToolbarSettings editor3d={editor3d} onClose={handleSettingsClose} />
      )}
    </Paper>
  );
}
