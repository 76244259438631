// WARNING: needs to be working with voids that are not fully contained

import {
  BufferAttribute,
  BufferGeometry,
  Path,
  Shape,
  ShapeGeometry,
  Vector2,
} from "three";

import {
  lineLineIntersection,
  segmentPolylineIntersection,
} from "Features/procedures/utils/intersections";
import {Earcut} from "Features/procedures/utils/Earcut";
import testIsBuggedGeometry from "./testIsBuggedGeometry";

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const prepareTriangulation = (entity, wall) => {
  try {
    let {path: polyVerts2D, zInf: polyZInf, zSup: polyZSup} = wall.props3D;
    const vertices = wall.rawGeometry
      ? Array.from(wall.rawGeometry.attributes.position.array)
      : Array.from(wall.object.geometry.attributes.position.array);
    let indices = Array.from(wall.object.geometry.index.array);
    const result = {
      vertices,
      indices,
      voids: [],
      lastHit: Math.floor(polyVerts2D.length / 2) - 2,
    };
    let nbVerts = vertices.length / 3;
    for (const voidId of wall.voids) {
      const window = entity?.getMeasurement(voidId);
      if (window) {
        const {
          path: voidVerts2D,
          zInf: voidZInf,
          zSup: voidZSup,
        } = window.props3D;

        // Find which segment of the polyline will have the void added
        let hitIdx = false;
        for (const segment of [
          [voidVerts2D[0], voidVerts2D[3]],
          [voidVerts2D[1], voidVerts2D[2]],
        ]) {
          hitIdx = segmentPolylineIntersection(segment, polyVerts2D);
          if (hitIdx !== false) continue;
        }
        // TODO perform box test with void center
        // to get voids that overflow on both sides laterally

        // Find the new points
        const originIdx = 8 * hitIdx;
        const o3 = originIdx * 3;
        const A = new Vector2(vertices[o3 + 0], vertices[o3 + 2]);
        const B = new Vector2(vertices[o3 + 3], vertices[o3 + 5]);
        const C = new Vector2(vertices[o3 + 6], vertices[o3 + 8]);
        const D = new Vector2(vertices[o3 + 9], vertices[o3 + 11]);
        const E = new Vector2(...voidVerts2D[0]);
        const F = new Vector2(...voidVerts2D[3]);
        const G = new Vector2(...voidVerts2D[1]);
        const H = new Vector2(...voidVerts2D[2]);
        const hit0 = lineLineIntersection(A, B, E, F);
        const hit1 = lineLineIntersection(A, B, G, H);
        const hit2 = lineLineIntersection(C, D, G, H);
        const hit3 = lineLineIntersection(C, D, E, F);
        if (hit0 && hit1 && hit2 && hit3) {
          let zInf, zSup, overflow;
          if (voidZInf > polyZInf && voidZSup < polyZSup) {
            zInf = voidZInf;
            zSup = voidZSup;
            overflow = "NONE";
          } else if (voidZInf <= polyZInf && voidZSup >= polyZSup) {
            zInf = polyZInf;
            zSup = polyZSup;
            overflow = "VERTICAL";
          } else if (voidZInf <= polyZInf && voidZSup < polyZSup) {
            zInf = polyZInf;
            zSup = voidZSup;
            overflow = "BOTTOM";
          } else if (voidZInf > polyZInf && voidZSup >= polyZSup) {
            zInf = voidZInf;
            zSup = polyZSup;
            overflow = "TOP";
          }
          const p8 = [hit0[0], zInf, hit0[1]];
          const p9 = [hit1[0], zInf, hit1[1]];
          const p10 = [hit2[0], zInf, hit2[1]];
          const p11 = [hit3[0], zInf, hit3[1]];
          const p12 = [hit0[0], zSup, hit0[1]];
          const p13 = [hit1[0], zSup, hit1[1]];
          const p14 = [hit2[0], zSup, hit2[1]];
          const p15 = [hit3[0], zSup, hit3[1]];
          let voidVertices = [p8, p9, p10, p11, p12, p13, p14, p15].flat();
          const shouldReverse =
            Math.sign(B.x - A.x) !== Math.sign(G.x - E.x) ||
            Math.sign(D.y - A.y) !== Math.sign(F.y - E.y);
          if (shouldReverse) {
            voidVertices = [p9, p8, p11, p10, p13, p12, p15, p14].flat();
          }
          result.voids.push({
            hitIdx,
            overflow,
            vertices: voidVertices,
            originIdx,
            nbVerts,
            zInf: voidZInf,
            zSup: voidZSup,
          });
          result.vertices = [...result.vertices, ...voidVertices];
          nbVerts += 8;
        }
      }
    }
    return result;
  } catch (e) {
    console.log("ERROR", e, "preparing triangulation of", wall);
  }
};

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const cleanIndices = (indices, clean) => {
  const tris = [];
  const toClean = new Set([0, 1, 2, 3, 4, 5, 6, 7].map((c) => c + clean));
  while (indices.length) tris.push(indices.splice(0, 3));
  const newIndices = [];
  for (const tri of tris) {
    let allGood = true;
    for (const idx of tri) {
      if (toClean.has(idx)) {
        allGood = false;
        continue;
      }
    }
    if (allGood) newIndices.push(...tri);
  }
  return newIndices;
};

///////////////////////////////////////////////////////////////////////////////

const makeIs = (originIdx) => {
  return {
    i0: 0 + originIdx,
    i1: 1 + originIdx,
    i2: 2 + originIdx,
    i3: 3 + originIdx,
    i4: 4 + originIdx,
    i5: 5 + originIdx,
    i6: 6 + originIdx,
    i7: 7 + originIdx,
  };
};

const makeJs = (nbVerts) => {
  return {
    j0: 0 + nbVerts,
    j1: 1 + nbVerts,
    j2: 2 + nbVerts,
    j3: 3 + nbVerts,
    j4: 4 + nbVerts,
    j5: 5 + nbVerts,
    j6: 6 + nbVerts,
    j7: 7 + nbVerts,
  };
};

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const sortVoids = (voids, vertices, i0) => {
  const ptFront = new Vector2(vertices[i0], vertices[i0 + 2]);
  return voids.sort((a, b) => {
    const pa = new Vector2(vertices[a.nbVerts], vertices[a.nbVerts + 2]);
    const pb = new Vector2(vertices[b.nbVerts], vertices[b.nbVerts + 2]);
    return pa.distanceTo(ptFront) - pb.distanceTo(ptFront);
  });
};

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const triangulateBottom = (voids, i0, i1, i2, i3) => {
  const overflowedVoids = voids.filter((v) =>
    ["VERTICAL", "BOTTOM"].includes(v.overflow)
  );
  if (overflowedVoids.length === 0) {
    return [i0, i3, i2, i2, i1, i0]; // bottom wall
  } else {
    const firstVoid = overflowedVoids[0];
    let js = makeJs(firstVoid.nbVerts);
    const indices = [i0, i3, js.j3, js.j3, js.j0, i0];
    if (overflowedVoids.length > 1) {
      for (const voidItem of overflowedVoids.slice(1)) {
        const newJs = makeJs(voidItem.nbVerts);
        indices.push(js.j1, js.j2, newJs.j3, newJs.j3, newJs.j0, js.j1);
        js = {...newJs};
      }
    }
    indices.push(js.j1, js.j2, i2, i2, i1, js.j1);
    return indices;
  }
};

///////////////////////////////////////////////////////////////////////////////

const triangulateTop = (voids, i4, i5, i6, i7) => {
  const overflowedVoids = voids.filter((v) =>
    ["VERTICAL", "TOP"].includes(v.overflow)
  );
  if (overflowedVoids.length === 0) {
    return [i4, i5, i6, i6, i7, i4]; // top wall
  } else {
    const firstVoid = overflowedVoids[0];
    let js = makeJs(firstVoid.nbVerts);
    const indices = [i4, js.j4, js.j7, js.j7, i7, i4];
    if (overflowedVoids.length > 1) {
      for (const voidItem of overflowedVoids.slice(1)) {
        const newJs = makeJs(voidItem.nbVerts);
        indices.push(js.j5, newJs.j4, newJs.j7, newJs.j7, js.j6, js.j5);
        js = {...newJs};
      }
    }
    indices.push(js.j5, i5, i6, i6, js.j6, js.j5);
    return indices;
  }
};

///////////////////////////////////////////////////////////////////////////////

const triangulateFlashing = (voids) => {
  const indices = [];
  for (const voidItem of voids) {
    const {overflow, nbVerts} = voidItem;
    const {j0, j1, j2, j3, j4, j5, j6, j7} = makeJs(nbVerts);
    // TODO handle HORIZONTAL, FRONT and BACK overflow
    indices.push(j3, j7, j4, j4, j0, j3); // front
    indices.push(j1, j5, j6, j6, j2, j1); // back
    if (!["VERTICAL", "TOP"].includes(overflow))
      indices.push(j4, j7, j6, j6, j5, j4); // top
    if (!["BOTTOM", "VERTICAL"].includes(overflow))
      indices.push(j0, j1, j2, j2, j3, j0); // bottom
  }
  return indices;
};

///////////////////////////////////////////////////////////////////////////////

const triangulateSidesOneVoid = (voidItem, i0, i1, i2, i3, i4, i5, i6, i7) => {
  const newIndices = [];
  const {overflow, nbVerts} = voidItem;
  const {j0, j1, j2, j3, j4, j5, j6, j7} = makeJs(nbVerts);
  // Left
  newIndices.push(i2, j2, j6, j6, i6, i2, i7, j7, j3, j3, i3, i7);
  // Right
  newIndices.push(i0, j0, j4, j4, i4, i0, i5, j5, j1, j1, i1, i5);
  if (!["BOTTOM", "VERTICAL"].includes(overflow)) {
    newIndices.push(i0, i1, j1, j1, j0, i0); // right bit
    newIndices.push(i2, i3, j3, j3, j2, i2); // left bit
  }
  if (!["TOP", "VERTICAL"].includes(overflow)) {
    newIndices.push(i4, j4, j5, j5, i5, i4); // right bit
    newIndices.push(i6, j6, j7, j7, i7, i6); // left bit
  }
  return newIndices;
};

///////////////////////////////////////////////////////////////////////////////

const triangulateSidesMultiVoids = (vertices, voids, i0) => {
  const o3 = i0 * 3;
  const wallPoints = [
    [vertices[o3 + 3 * 0], vertices[o3 + 3 * 0 + 1]],
    [vertices[o3 + 3 * 1], vertices[o3 + 3 * 1 + 1]],
    [vertices[o3 + 3 * 5], vertices[o3 + 3 * 5 + 1]],
    [vertices[o3 + 3 * 4], vertices[o3 + 3 * 4 + 1]],
  ];
  const idxMap = {0: i0 + 0, 1: i0 + 1, 2: i0 + 5, 3: i0 + 4};
  const rightToLeftMap = {0: i0 + 3, 1: i0 + 2, 2: i0 + 6, 3: i0 + 7};
  let verts = [...wallPoints.flat()];
  let holes = [];

  let idx = 4;
  for (const voidItem of voids) {
    const j0 = voidItem.nbVerts;
    const h3 = j0 * 3;
    const windowPoints = [
      [vertices[h3 + 3 * 0], vertices[h3 + 3 * 0 + 1]],
      [vertices[h3 + 3 * 1], vertices[h3 + 3 * 1 + 1]],
      [vertices[h3 + 3 * 5], vertices[h3 + 3 * 5 + 1]],
      [vertices[h3 + 3 * 4], vertices[h3 + 3 * 4 + 1]],
    ];
    idxMap[idx] = j0 + 0;
    idxMap[idx + 1] = j0 + 1;
    idxMap[idx + 2] = j0 + 5;
    idxMap[idx + 3] = j0 + 4;
    rightToLeftMap[idx] = j0 + 3;
    rightToLeftMap[idx + 1] = j0 + 2;
    rightToLeftMap[idx + 2] = j0 + 6;
    rightToLeftMap[idx + 3] = j0 + 7;
    verts = [...verts, ...windowPoints.flat()];
    holes.push(idx);
    idx += 4;
  }

  const tris = Earcut.triangulate(verts, holes);

  const rightTris = tris.map((t) => idxMap[t]);
  const leftTris = tris.map((t) => rightToLeftMap[t]);
  for (let i = 0, il = leftTris.length / 3; i < il; i++) {
    let x = leftTris[i * 3];
    leftTris[i * 3] = leftTris[i * 3 + 2];
    leftTris[i * 3 + 2] = x;
  }
  return [...rightTris, ...leftTris];
};

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const performTriangulation = (data) => {
  const newIndices = [];
  let {vertices, indices} = data;
  for (let seg = 0; seg <= data.lastHit; seg++) {
    const voids = data.voids.filter((v) => v.hitIdx === seg);
    if (voids.length > 0) {
      const originIdx = seg * 8;
      // Remove indices for the segment
      indices = cleanIndices(indices, originIdx);
      // Get indices of segment extremities
      const {i0, i1, i2, i3, i4, i5, i6, i7} = makeIs(originIdx);
      // TODO triangulate front and back if lateral overflow
      if (seg === 0) {
        newIndices.push(i0, i4, i7, i7, i3, i0); // front wall 047730
      }
      if (seg === data.lastHit) {
        newIndices.push(i2, i6, i5, i5, i1, i2); // back wall 265512
      }
      newIndices.push(...triangulateFlashing(voids));
      const sortedFront2Back = sortVoids(voids, vertices, i0);
      newIndices.push(...triangulateBottom(sortedFront2Back, i0, i1, i2, i3));
      newIndices.push(...triangulateTop(sortedFront2Back, i4, i5, i6, i7));
      if (voids.length === 1) {
        newIndices.push(
          ...triangulateSidesOneVoid(voids[0], i0, i1, i2, i3, i4, i5, i6, i7)
        );
      } else {
        newIndices.push(
          ...triangulateSidesMultiVoids(vertices, sortedFront2Back, i0)
        );
      }
    }
  }
  return {
    vertices,
    indices: [...indices, ...newIndices],
  };
};

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

export const updatePolylineGeometry = (wall, vertices, indices, voidsVerts) => {
  const isBuggedGeometry = testIsBuggedGeometry(vertices);
  let shapeGeometry;
  if (isBuggedGeometry) {
    shapeGeometry = wall.object.geometry;
  } else {
    shapeGeometry = new BufferGeometry();
    shapeGeometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(vertices), 3)
    );
    shapeGeometry.setIndex(indices);
    shapeGeometry.computeVertexNormals();
  }

  if (!wall.rawGeometry) wall.rawGeometry = wall.object.geometry;
  wall.netGeometry = shapeGeometry;
  wall.object.geometry = shapeGeometry;
  if (Array.isArray(wall.voids) && wall.voids.length === 1)
    wall.addEdges(wall.object);
  // wall.addVoidObjects(voidsVerts);
};
