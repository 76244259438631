import {ToggleButton, ToggleButtonGroup} from "@mui/material";

export default function SelectorStyle({style, onChange}) {
  const stylesMap = {
    title1: "Titre",
    title2: "Ss-titre",
    normal: "Elément",
    detail: "Détail",
  };

  const styleCodes = Object.keys(stylesMap);

  function handleChange(e, newStyle) {
    if (!newStyle) return;
    onChange(newStyle);
  }

  return (
    <ToggleButtonGroup
      exclusive
      value={style}
      onChange={handleChange}
      size="small"
    >
      {styleCodes.map((styleCode) => {
        return (
          <ToggleButton key={styleCode} value={styleCode}>
            {stylesMap[styleCode]}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
