import React from "react";

import useDeleteScenePhase from "../hooks/useDeleteScenePhase";

import {Box, Typography, Button} from "@mui/material";
import {Delete} from "@mui/icons-material";

export default function ButtonDeletePhase({scene, phase}) {
  // strings

  const deleteS = "Delete";

  // data

  const deleteScenePhase = useDeleteScenePhase(scene);

  // handlers

  function handleDelete() {
    deleteScenePhase(phase);
  }

  return (
    <Box sx={{}}>
      <Button
        startIcon={<Delete />}
        color="inherit"
        size="small"
        onClick={handleDelete}
      >
        {deleteS}
      </Button>
    </Box>
  );
}
