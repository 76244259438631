import {decode} from "Features/translations/utils";
import {Box3, Vector3, MeshBasicMaterial, BufferAttribute} from "three";
import Vector from "./Vector";

class IfcModelElement {
  constructor({
    expressID,
    ifcModelID,
    ifcLoader,
    modelId,
    modelObject,
    material,
    scene,
    jsonProps,
    fromGltf,
  }) {
    this.type = "IFC_MODEL_ELEMENT";
    this.expressID = expressID;
    this.ifcModelID = ifcModelID;
    this.ifcLoader = ifcLoader;
    this.modelId = modelId;
    this.modelObject = modelObject;
    this.material = material;
    this.scene = scene;
    this.jsonProps = jsonProps;
    this.fromGltf = fromGltf;
  }

  async getProps() {
    let props;
    if (!this.fromGltf) {
      props = await this.ifcLoader.ifcManager.getItemProperties(
        this.ifcModelID,
        this.expressID,
        true
      );
    } else {
      props = this.jsonProps;
    }

    return {
      name: props.Name?.value,
      type: props.ObjectType?.value,
      tag: props.Tag?.value,
      description: props.Description?.value,
    };
  }

  async propertySets() {
    if (!this.fromGltf) {
      // TO DO : manage pset with Quantities attribute
      const psets = await this.ifcLoader.ifcManager.getPropertySets(
        this.ifcModelID,
        this.expressID,
        true
      );
      const details = psets.map((pset) => {
        const name = pset.Name?.value;
        const description = pset.Description?.value;
        const props = pset.HasProperties?.map((prop) => {
          const key = prop.Name?.value;
          const value = prop.NominalValue?.value;
          return {key, value};
        });

        return {name, description, props};
      });
      return details;
    }
  }

  getCenter() {
    const s = this.ifcLoader.ifcManager.createSubset({
      modelID: this.ifcModelID,
      ids: [this.expressID],
      material: new MeshBasicMaterial({color: 0x99f321}),
      scene: this.modelObject,
      removePrevious: true,
    });
    const index = s.geometry.index.array;
    const idArray = s.geometry.attributes.expressID.array;
    const normalArray = s.geometry.attributes.normal.array;
    const positionArray = s.geometry.attributes.position.array;

    const newIdArray = [];
    const newNormalArray = [];
    const newPositionArray = [];
    index.forEach((i) => {
      newIdArray.push(idArray[i]);
      newNormalArray.push(
        normalArray[3 * i],
        normalArray[3 * i + 1],
        normalArray[3 * i + 2]
      );
      newPositionArray.push(
        positionArray[3 * i],
        positionArray[3 * i + 1],
        positionArray[3 * i + 2]
      );
    });
    s.geometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(newPositionArray), 3)
    );
    s.geometry.setAttribute(
      "expressID",
      new BufferAttribute(new Float32Array(newIdArray), 1)
    );
    s.geometry.setAttribute(
      "normal",
      new BufferAttribute(new Float32Array(newNormalArray), 3)
    );

    s.geometry.computeBoundingBox();
    const bb = s.geometry.boundingBox;
    const centerV = new Vector3();
    const sizeV = new Vector3();
    bb && bb.getCenter(centerV);
    bb && bb.getSize(sizeV);
    this.scene.remove(s);
    return {
      x: Number(centerV.x.toFixed(3)),
      y: Number(centerV.y.toFixed(3)),
      z: Number(centerV.z.toFixed(3)),
    };
  }

  async parse() {
    const props = await this.getProps();
    const psets = await this.propertySets();
    return {
      type: this.type,
      expressID: this.expressID,
      name: props.name,
      props,
      psets,
      ifcModelID: this.ifcModelID,
      modelId: this.modelId,
    };
  }

  async parseFlat() {
    const props = await this.getProps();
    const psets = await this.propertySets();
    const center = this.getCenter();

    const psetsFlat = {};
    psets.forEach((pset, index) => {
      const psetName = pset.name;
      pset.props?.forEach(({key, value}) => {
        const prop = `pset${index}_${decode(key)}`;
        psetsFlat[prop] = value;
      });
    });

    const parse = {
      expressID: this.expressID,
      name: props.name,
      ...props,
      ...psetsFlat,
      ...center,
    };
    return parse;
  }
}

export default IfcModelElement;
