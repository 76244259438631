import {MathUtils} from "three";

export default class PdfModel {
  constructor() {}

  static async computeInitialModel({
    file,
    sceneClientId,
    name,
    type: optionType,
  }) {
    const id = MathUtils.generateUUID();
    const type = optionType ? optionType : "PDF";
    const url = URL.createObjectURL(file);
    const fileSize = file.size;
    const fileName = file.name;
    const position = {x: 0, y: 0, z: 0};
    const rotation = {x: -Math.PI / 2, y: 0, z: 0};
    const center = {x: 0, y: 0, z: 0};
    const hidden = false;
    const autoloading = true;
    //
    const model = {
      id,
      sceneClientId,
      name: name ? name : file.name,
      url,
      fileSize,
      fileName,
      type,
      rotation,
      position,
      center,
      hidden,
      autoloading,
      enabled: true,
    };
    return {model};
  }
}
