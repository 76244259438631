import {useLayoutEffect, useState} from "react";
import {useSelector} from "react-redux";

function useHeightToBottom(ref, disable, propDependency) {
  // initial state

  const initialWindowHeight = window.innerHeight;

  // data

  const toolGroup = useSelector((state) => state.viewer3D.toolGroup);

  // local state

  const [windowHeight, setWindowHeight] = useState(initialWindowHeight);
  const [height, setHeight] = useState();

  // compute height

  useLayoutEffect(() => {
    if (ref.current) {
      const windowH = window.innerHeight;
      const refTop = ref.current.getBoundingClientRect().top;
      const height = windowH - refTop;
      setHeight(height);
      setWindowHeight(windowH);
    }

    //update state when screen resize

    function handleWindowResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [
    height,
    windowHeight,
    ref.current,
    ref.current?.childElementCount,
    disable,
    toolGroup,
    propDependency,
  ]);

  // effect result

  return height;
}

export default useHeightToBottom;
