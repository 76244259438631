import {useDispatch, useSelector} from "react-redux";

import useSyncActions from "Features/scenes/useSyncActions";

// import {updateModelNotAsync} from "Features/viewer3D/viewer3DSlice";
import {setEditedTimeStamp} from "Features/measurements/measurementsSlice";
import ContainerContextOverview from "Features/overviewer/components/ContainerContextOverview";
import getMeasurementsFromFilters from "Features/measurements/utils/getMeasurementsFromFilters";
import getFiltersCount from "Features/filters/utils/getFiltersCount";
// import getMeasurementsDataFromMeasurementsAndModelId from "Features/measurements/utils/getMeasurementsDataFromMeasurementsAndModelId";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import {triggerSave} from "../overviewerSlice";

export default function SectionContextOverviewInTopBar({
  scene,
  shrink,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  const mnger = caplaEditor?.measDataManager;

  // data

  const measIdsShowedInViewer3d = useSelector(
    (s) => s.measurements.measIdsShowedInViewer3d
  );
  const modifications = useSelector((s) => s.measurements.modifications);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const [syncActions, triggerSyncAction] = useSyncActions(scene); // to display save button when updating zone/ scene /...

  // helper - filters

  const filters = useSelector((s) => s.filters.filtersByTab);

  // helpers

  const filteredMeasurements = getMeasurementsFromFilters(
    measIdsShowedInViewer3d,
    filters
  );

  // helpers - counters

  const count = measIdsShowedInViewer3d.length;

  const filteredCount = filteredMeasurements?.length;
  const filtersCount = getFiltersCount(filters);

  // helpers - modifications

  const measIdsToUpdate = Object.values(modifications).flat();

  const modificationCounts = measIdsToUpdate.length + syncActions.length;

  console.log("modificationCounts", modificationCounts);
  // handlers

  function handleCancelClick() {
    // dispatch(setModifiedMeasurements([]));
    // const meas =
    //   mnger.models[modelId]?.measurementsData
    //     ?.measurements;
    // if (meas) dispatch(setMeasurements(meas));
  }

  function handleSaveClick() {
    if (measIdsToUpdate?.length > 0) {
      mnger?.saveModifications({elementTypes, ressources});
      dispatch(setEditedTimeStamp(`${Date.now()}`));
    }
    dispatch(triggerSave()); // we split the logic for saving meas + other models & scene (save=> autosync)
  }

  return (
    <ContainerContextOverview
      count={count}
      showFilters={false}
      filteredCount={filteredCount}
      filtersCount={filtersCount}
      modificationsCount={modificationCounts} // to display save button
      onSaveClick={handleSaveClick}
      onCancelClick={handleCancelClick}
      shrink={shrink}
    />
  );
}
