import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";

import {setSelectedScopeId} from "Features/scopes/scopesSlice";
import useScopesByScene from "Features/scopes/hooks/useScopesByScene";
import useSceneModule from "Features/navigation/useSceneModule";

export default function useInitScopeSelection(scene) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  //

  const scopeParam = searchParams.get("scope");

  useEffect(() => {
    if (scopeParam) {
      dispatch(setSelectedScopeId(scopeParam));
    }
  }, [scopeParam]);
}
