import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FieldZoneHeight({zone, onZoneChange}) {
  // strings

  const heightS = "Z (m)";

  // template

  const template = {
    key: "kvtZoneHeight",
    fields: [
      {
        key: "height",
        name: heightS,
        type: "number",
      },
    ],
  };

  // helpers - item

  const item = {height: zone?.position?.y};

  // handlers

  function handleHeightChange({height}) {
    const position = {...zone.position};
    position.y = parseFloat(height);
    // editorPdf.annotationsManager.updateAnnotationEntityPosition({
    //   annotationId: zone.id,
    //   position,
    // });
    const newZone = {...zone, position};
    if (onZoneChange) onZoneChange(newZone);
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleHeightChange}
    />
  );
}
