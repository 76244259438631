import {useEffect, useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {MathUtils} from "three";

import {
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  Button,
  IconButton,
} from "@mui/material";
import {Close} from "@mui/icons-material";

import ActionPopper from "./ActionPopper";
import ActionNameMarker from "./ActionNameMarker";
import ContainerPosition from "./ContainerPosition";
import ToggleSnapping from "./ToggleSnapping";

import {nextStep} from "../utils/modeUtils";

import DisplayBox from "Components/DisplayBox";

import useTranslation from "Features/translations/useTranslation";
import {
  setStep,
  setMode,
  setPositioningObject,
  addMarker,
  updateModel,
  setPointerMode,
} from "Features/viewer3D/viewer3DSlice";
import {capitalizeStart} from "Features/translations/utils";
import useSelectedMarkersModel from "Features/markers/hooks/useSelectedMarkersModel";

export default function ProcessMarkerAdd({editor3d}) {
  const {t} = useTranslation(["viewer3D", "common"]);
  const dispatch = useDispatch();

  // strings

  const title = t("viewer3D:markers.addMarkerProcess.title");
  const positioningString = t("viewer3D:markers.addMarkerProcess.positioning");
  const localisingString = t("viewer3D:markers.addMarkerProcess.naming");
  const step1 = t("viewer3D:markers.addMarkerProcess.step1");
  const step2 = t("viewer3D:markers.addMarkerProcess.step2");
  const seeString = capitalizeStart(t("common:see"));

  // steps

  const steps = {POSITION: {name: step1}, NAME: {name: step2}};

  // virtual element for popper

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef();

  // data

  const mode = useSelector((state) => state.viewer3D.mode);
  const step = useSelector((state) => state.viewer3D.step);
  const activeStep = Object.keys(steps).indexOf(step);

  const selectedMarkersModel = useSelectedMarkersModel();

  // local data

  const [position, setPosition] = useState({x: 0, y: 0, z: 0});
  // const [screenPos, setScreenPos] = useState({x: 0, y: 0, z: 0});

  // helper

  function killProcess() {
    dispatch(setPositioningObject({editor: false}));
    dispatch(setMode("DEFAULT"));
    dispatch(setStep("DEFAULT"));
    editor3d?.switchToMode("PICKING");
  }

  // handlers

  // function handleEditorMove(screenP) {
  //   setScreenPos(screenP);
  //   virtualElementRef.current.getBoundingClientRect = generateBBCR(
  //     screenP.x,
  //     screenP.y
  //   );
  // }

  function handleEditorClick(props) {
    if (!editor3d?.dragging) {
      const {screenP, sceneP} = props;

      virtualElementRef.current.getBoundingClientRect = generateBBCR(
        screenP.x,
        screenP.y
      );

      if (sceneP.x) setPosition(sceneP);
      if (step === "POSITION") {
        dispatch(setStep(nextStep(mode, step)));
      }
    }
  }

  function handlePositionChange(position) {
    setPosition(position);
  }

  function handleSeeButtonClick() {
    dispatch(setStep(nextStep(mode, step)));
    editor3d?.setTempPositioningMarker(position);
    const o = editor3d?.tempPositioningMarker.object;
    editor3d?.fitCameraToObject(editor3d?.tempPositioningMarker.object);
    const screenP = editor3d?.getObjectScreenPosition(o);
    virtualElementRef.current.getBoundingClientRect = generateBBCR(
      screenP.x,
      screenP.y
    );
  }

  function handleAddMarker(description) {
    //dispatch(setPositioningObject({editor3d?.: false}));
    const {x, y, z} = position;
    const m = {
      x,
      y,
      z,
      description,
      id: MathUtils.generateUUID(),
      type: "REFERENCE",
    };
    const entity = editor3d?.getEntity(selectedMarkersModel?.id);
    entity?.addMarker(m);
    editor3d?.switchToMode("PICKING");
    dispatch(addMarker(m));
    dispatch(setMode("DEFAULT"));
    dispatch(setStep("DEFAULT"));

    // update model
    let oldElements = selectedMarkersModel.elements?.items;
    if (!oldElements) oldElements = [];
    const updatedModel = {
      ...selectedMarkersModel,
      elements: {items: [...oldElements, m]},
    };
    dispatch(updateModel({updatedModel}));

    // clean markers

    editor3d?.sceneEditor.disablePointer();
    dispatch(setPointerMode(false));
  }

  // editor3d?. onClick update

  useEffect(() => {
    if (editor3d) editor3d.onProcessEditorClick = handleEditorClick;
    // editor3d?..onEditorMove = handleEditorMove // to heavy. used to display action box close to the object.
    virtualElementRef.current = {getBoundingClientRect: generateBBCR};
  }, []);

  return (
    <Box
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider}`,
        background: (theme) => theme.palette.common.white,
      }}
    >
      <LinearProgress sx={{width: 1}} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" sx={{p: 2}}>
          <b>{title}</b>
        </Typography>
        <IconButton sx={{mr: 1}} onClick={killProcess}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={{width: 1, mt: 2}}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {Object.keys(steps).map((step) => (
            <Step key={step} size="small">
              <StepLabel>
                <Typography variant="body2">{steps[step].name}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <DisplayBox open={mode === "PROCESS_ADD_MARKER" && step === "POSITION"}>
        <Typography variant="body2" sx={{p: 2}}>
          {positioningString}
        </Typography>
        <Box sx={{p: 2}}>
          <ContainerPosition
            position={position}
            onPositionChange={handlePositionChange}
          />
        </Box>
        <Button
          sx={{ml: 2, mb: 2}}
          variant="outlined"
          size="small"
          onClick={handleSeeButtonClick}
        >
          {seeString}
        </Button>
      </DisplayBox>

      <Box>
        <DisplayBox open={mode === "PROCESS_ADD_MARKER" && step === "NAME"}>
          <Typography variant="body2" sx={{p: 2}}>
            {localisingString}
          </Typography>
          <Box sx={{p: 2}}>
            <ContainerPosition position={position} readOnly />
          </Box>
        </DisplayBox>
      </Box>

      <Box sx={{zIndex: 100}}>
        {virtualElementRef?.current && (
          <ActionPopper
            anchorEl={virtualElementRef.current}
            open={step === "NAME"}
          >
            <ActionNameMarker onAdd={handleAddMarker} />
          </ActionPopper>
        )}
      </Box>
      <Box sx={{pl: 2}}>
        <ToggleSnapping editor3d={editor3d} variant="checkbox" />
      </Box>
    </Box>
  );
}
