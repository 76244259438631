import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {Box, Button, Typography, Paper} from "@mui/material";
import {Add} from "@mui/icons-material";

import {
  setSelectedIssuesListingId,
} from "Features/issues/issuesSlice";
import useTakeEditorScreenshot from "Features/viewer3D/useTakeEditorScreenshot";
import SelectorSceneListingBlack from "Features/listings/components/SelectorSceneListingBlack";
import useSceneListings from "Features/listings/useSceneListings";

export default function ToolbarAddIssue({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const createS = "New";

  // data

  const sceneListings = useSceneListings(scene?.id).filter(
    (l) => l.listingType === "issueset"
  );

  // state

  const initSelectedListing = sceneListings[0];

  const [selectedListing, setSelectedListing] = useState(initSelectedListing);
  useEffect(() => {
    setSelectedListing(initSelectedListing);
    dispatch(setSelectedIssuesListingId(initSelectedListing?.id));
  }, [initSelectedListing?.id]);

  // data (depends on state)

  const takeScreenshot = useTakeEditorScreenshot({
    caplaEditor,
    scene,
    target: {listingType: "issueset", listingId: selectedListing?.id},
  });

  // handlers

  function handleTakeScreenshot() {
    takeScreenshot();
  }

  function handleListingChange(listing) {
    setSelectedListing(listing);
    dispatch(setSelectedIssuesListingId(listing.id));
  }

  return (
    <Paper sx={{bgcolor: "common.caplaBlack", color: "common.white", p: 0.5}}>
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "&>:not(:last-child)": {mr: 1},
          }}
        >
          <SelectorSceneListingBlack
            listing={selectedListing}
            listings={sceneListings}
            onChange={handleListingChange}
          />
          {!viewer && (
            <Button
              startIcon={<Add />}
              onClick={handleTakeScreenshot}
              variant="contained"
              sx={{
                bgcolor: "primary.flash",
                height: 30,
                borderRadius: "4px",
              }}
            >
              <Typography sx={{fontSize: 13}} noWrap>
                {createS}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
