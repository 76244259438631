// DEPRECATED

import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {
  Button,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";

import SelectorModels from "Features/pdf/components/SelectorModels";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

export default function DialogCreateMeasurementsModel({
  open,
  onClose,
  onCreate,
  scene,
  editor3d,
}) {
  // const dispatch = useDispatch();

  // strings

  const {t} = useTranslation(["packages", "common"]);
  const addS = capitalizeStart(t("ui:add"));
  const cancelS = capitalizeStart(t("ui:cancel"));

  const titleS = t("packages:createNewPackage");
  const nameS = capitalizeStart(t("ui:name"));

  const selectPdfModelS = "Select a PDF";

  // data

  const pdfModels = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.type === "PDF"
  ).sort();

  const selectedPdfModel = useSelectedPdfModel();

  // state

  const [name, setName] = useState("");
  const [pdfModelId, setPdfModelId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedPdfModel && !pdfModelId) {
      setPdfModelId(selectedPdfModel?.id);
    } else if (pdfModels?.length === 1 && !pdfModelId) {
      setPdfModelId(pdfModels[0].id);
    }
  }, [pdfModelId, pdfModels?.length]);

  // helper

  let model = pdfModels.find((m) => m.id === pdfModelId);
  if (!model) model = null;

  // handlers - model

  function handleNameChange(e) {
    e.stopPropagation();
    setName(e.target.value);
  }

  function handlePdfModelChange(m) {
    setPdfModelId(m?.id);
  }

  function handleCancelClick() {
    if (onClose) onClose();
  }

  async function handleAddClick() {
    if (name.length > 0 && pdfModelId) {
      setLoading(true);
      // create model
      const fromModel = {type: "PDF", modelId: pdfModelId};
      await editor3d?.loader.createMeasurementsModel({name, fromModel});
      setLoading(false);
      if (onCreate) onCreate();
      onClose();
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={handleNameChange}
          label={<Typography variant="body2">{nameS}</Typography>}
          variant="outlined"
          size="small"
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          sx={{my: 2}}
        />
        <Typography variant="body2" sx={{mt: 3, mb: 1}}>
          {selectPdfModelS}
        </Typography>
        <Box sx={{width: 300}}>
          <SelectorModels
            model={model}
            onChange={handlePdfModelChange}
            models={pdfModels}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <LoadingButton
          loading={loading}
          startIcon={<Add />}
          loadingPosition="start"
          onClick={handleAddClick}
        >
          {addS}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
