import {client} from "API/capla360";

export async function fetchSurveysService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/surveys/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedSurveys = response.data;
  const surveys = sharedSurveys.map((r) => {
    return {...r, sceneId: r.scene};
  });
  return {items: surveys, sceneId};
}

export async function createSurveyService({accessToken, survey, fromScopeId}) {
  const newSurvey = {...survey};
  const sceneId = survey.sceneId;
  delete newSurvey.scene;
  newSurvey.scene = survey.sceneId;
  const response = await client.post(`scenes/${sceneId}/surveys/`, newSurvey, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      fromScope: fromScopeId,
    },
  });
  return {item: {...response.data, sceneId}, fromScopeId};
}

export async function fetchSurveyService({accessToken, sceneId, surveyId}) {
  const response = await client.get(`/scenes/${sceneId}/surveys/${surveyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {item: {...response.data, sceneId}};
}

export async function updateSurveyService({
  accessToken,
  surveyId,
  sceneId,
  updates,
}) {
  const response = await client.put(
    `/scenes/${sceneId}/surveys/${surveyId}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedSurvey = response.data;
  return {item: {...updatedSurvey, sceneId: updatedSurvey.scene}};
}

export const deleteSurveyService = async ({accessToken, surveyId, sceneId}) => {
  await client.delete(`/scenes/${sceneId}/surveys/${surveyId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return surveyId;
};
