import {useDispatch} from "react-redux";
import {
  fetchOrganisationNomenclatures,
  setSelectedOrganisationId,
} from "../organisationsSlice";

import {List, ListItemButton, Typography} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";

export default function ListOrganisations({organisations}) {
  const accessToken = useAccessToken();

  //
  const dispatch = useDispatch();

  function handleClick(organisation) {
    dispatch(setSelectedOrganisationId(organisation.id));
    dispatch(
      fetchOrganisationNomenclatures({
        accessToken,
        organisationId: organisation.id,
      })
    );
  }
  return (
    <List>
      {organisations.map((organisation) => (
        <ListItemButton
          key={organisation.id}
          divider
          onClick={() => handleClick(organisation)}
        >
          <Typography>{organisation.name}</Typography>
        </ListItemButton>
      ))}
    </List>
  );
}
