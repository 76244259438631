import React from "react";

import {Box, Divider, Typography} from "@mui/material";

import ListItemButtonScenes from "./ListItemButtonScenes";

import MenuItemsScene from "./MenuItemsScene";
import CardSceneInMenu from "./CardSceneInMenu";

import SectionSceneRating from "Features/onboarding/components/SectionSceneRating";

import useTranslation from "Features/translations/useTranslation";

export default function NavSectionScene({scene}) {
  // strings
  const {t} = useTranslation("navigation");

  const sceneMenuString = t("sceneMenu");

  return (
    <Box sx={{mt: 4}}>
      <ListItemButtonScenes />

      {/* <Typography
        sx={{pl: 2, mt: 2, mb: 1, fontSize: 13, color: "text.secondary"}}
      >
        {sceneMenuString}
      </Typography> */}
      <CardSceneInMenu scene={scene} />
      {/* <SectionSceneRating scene={scene} /> */}
      <MenuItemsScene />
    </Box>
  );
}
