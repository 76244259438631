// TODO description

import { pointInsidePolygon } from "../utils/intersections";

export default function affectTypeByProxy({
  measurements,
  params,
  data
}) {
  const {targetGroupId, proxyPackageId, proxyGroupId} = params;
  const {measurementsByPackageId, typesById} = data;
  const targetTypes = Object.values(typesById).filter(
    (t) => t.groupId === targetGroupId
  );
  let proxiesTypes = Object.values(typesById);
  if (proxyGroupId) {
    proxiesTypes = proxiesTypes.filter(
      (t) => t.groupId === proxyGroupId
    );
  }
  proxiesTypes = new Set(proxiesTypes.map((t) => t.id));
  const proxies = measurementsByPackageId[proxyPackageId];
  const proxiesToUse = proxies.filter(
    (p) => proxiesTypes.has(p.elementTypeId)
  );
  const usedProxies = new Set();
  return measurements.map((m) => {
    if (usedProxies.size !== proxiesToUse.length) {
      for (const proxy of proxiesToUse) {
        if (
          proxy.zoneId === m.zoneId &&
          !usedProxies.has(proxy.id) &&
          Array.isArray(proxy.path3D) &&
          Array.isArray(m.path3D) &&
          pointInsidePolygon(proxy.path3D[0], m.path3D)
        ) {
          const proxyType = typesById[proxy.elementTypeId];
          const newType = targetTypes.find((t) => t.code === proxyType.code);
          if (newType) {
            usedProxies.add(proxy.id);
            return {
              ...m,
              elementTypeId: newType.id,
              color: newType.color,
              affectedByProxy: true,
            };
          }
        }
      }
    }
    return {...m};
  });
}