import {Box} from "@mui/material";

import {useSelector} from "react-redux";

import ListItemZoneInPdf from "./ListItemZoneInPdf";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
import getMeasCountByZoneIdMap from "Features/zones/utils/getMeasCountByZoneIdMap";

export default function ShortcutZonesInPdf({editorPdf, scene, viewer}) {
  const zones = useZonesByScene(scene);

  const pdfModel = useSelectedPdfModel(viewer);

  // data - measurements

  const measurements = useSelector((s) => s.pdf.measurementsShowedInViewerPdf);

  // helpers - countMap

  const measCountByZoneIdMap = getMeasCountByZoneIdMap(measurements);

  // helpers - items

  const items = zones.filter((z) => z.pdfModelId === pdfModel?.id);

  return (
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {items.map((zone) => (
        <ListItemZoneInPdf
          key={zone.id}
          zone={zone}
          editorPdf={editorPdf}
          count={measCountByZoneIdMap[zone.id]}
        />
      ))}
    </Box>
  );
}
