import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedSharedbox, setTempSharedbox} from "../sharedboxesSlice";
import useAddSceneSharedbox from "../hooks/useAddSceneSharedbox";

export default function ActionsNewSharedboxInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id;

  // strings

  const createS = "Ajouter";

  // data

  const tempSharedbox = useSelector((s) => s.sharedboxes.tempSharedbox);
  const addSharedbox = useAddSceneSharedbox(scene);

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  async function handleCreateClick() {
    if (loading) return;
    setLoading(true);
    const newSharedbox = {...tempSharedbox};
    newSharedbox.sceneId = sceneId;
    const sharedbox = await addSharedbox(newSharedbox);
    dispatch(setSelectedSharedbox(sharedbox));
    dispatch(setTempSharedbox({}));
    setLoading(false);
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
