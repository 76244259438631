import {useSelector, useDispatch} from "react-redux";
import {Box, Typography, TextField, Grid} from "@mui/material";
import {setName, setCode} from "../elementTyporSlice";

export default function SectionStepIdentification({}) {
  const dispatch = useDispatch();

  // strings

  const nameS = "Nom";
  const codeS = "Code";

  // data

  const name = useSelector((s) => s.elementTypor.name);
  const code = useSelector((s) => s.elementTypor.code);

  // handlers

  function handleNameChange(e) {
    dispatch(setName(e.target.value));
  }
  function handleCodeChange(e) {
    dispatch(setCode(e.target.value));
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        alignItems: "center",
        justifyContent: "center",
        px: 8,
      }}
    >
      <Box sx={{display: "flex", width: 1}}>
        <Grid container sx={{width: 1}} spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              value={name}
              onChange={handleNameChange}
              size="small"
              label={nameS}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={codeS}
              fullWidth
              value={code}
              onChange={handleCodeChange}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
