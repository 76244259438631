import React from "react";

import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ShortcutMobileZones from "./components/ShortcutMobileZones";
import CardShortcutSelection from "./components/CardShortcutSelection";

export default function ToolShortcutsMobile({editor, scene, viewer}) {
  // data

  const tool = useSelector((s) => s.ui.shortcutToolViewer3D);

  const zoneId = useSelector((s) => s.measurements.shortcutMobileZoneId);

  // helpers

  const noSelection = !zoneId;

  return (
    <Box>
      {!noSelection && <CardShortcutSelection scene={scene} />}
      {noSelection && (
        <Box>
          <ShortcutMobileZones editor={editor} scene={scene} viewer={viewer} />
        </Box>
      )}
    </Box>
  );
}
