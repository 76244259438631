import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {addMeasurementProgress} from "../progressSlice";
import useSelectedMeasurement from "Features/measurements/useSelectedMeasurement";

import {IconButton, Box} from "@mui/material";
import {CheckCircle} from "@mui/icons-material";

export default function ButtonAddMeasurementsProgressInToolbar() {
  const dispatch = useDispatch();

  // data

  const progressRecordId = useSelector(
    (s) => s.progress.selectedProgressRecordId
  );

  const progressListingId = useSelector(
    (s) => s.progress.selectedProgressListingId
  );

  const selectedMeasurement = useSelectedMeasurement();

  // helpers

  const valid = progressRecordId && progressListingId && selectedMeasurement;

  // handlers

  function handleClick() {
    const measurementProgress = {
      measurementId: selectedMeasurement?.id,
      progressRecordId,
      progressListingId,
      progress: 1,
    };

    if (valid) {
      dispatch(addMeasurementProgress(measurementProgress));
    }
  }
  return (
    <Box
      sx={{
        color: "common.white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "primary.main",
      }}
    >
      <IconButton
        sx={{height: 30, width: 30}}
        color="inherit"
        onClick={handleClick}
      >
        <CheckCircle color="inherit" fontSize="small" />
      </IconButton>
    </Box>
  );
}
