import {Box} from "@mui/material";

// import ToolFilter from "Features/viewer3D/components/ToolFilter";
import ToolQuickFilterByStorey from "Features/viewer3D/components/ToolQuickFilterByStorey";

export default function ShortcutStoreys({editor3d, scene}) {
  return (
    <Box>
      <ToolQuickFilterByStorey editor3d={editor3d} scene={scene} />
    </Box>
  );
}
