export default function useDrawingShapeDescriptionMap() {
  // strings

  const segmentD = "Ouvertures, menuiseries, portes, réservations.";
  const polylineD = "Murs, cloisons, parement";
  const polygonD = "Dalles, zone, surface, plancher, plafond";

  return {
    SEGMENT: segmentD,
    POLYLINE: polylineD,
    POLYGON: polygonD,
  };
}
