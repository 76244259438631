import {Vector3} from "three";

export function fitCameraToObject(camera, controls, object) {
  let cameraDirection = new Vector3();
  let objectPosition = new Vector3();

  if (camera.type != "PerspectiveCamera") {
    console.log("The camera should be a PerspectiveCamera");
    return;
  } else {
    camera.getWorldDirection(cameraDirection);
    objectPosition.copy(object.position);
    cameraDirection.normalize().multiplyScalar(2);

    camera.position.copy(objectPosition.sub(cameraDirection));
    camera.updateWorldMatrix();
    controls.target.copy(object.position);
    controls.update();
  }
}
