import {useSelector} from "react-redux";

import useFilteredMeasurementsFromPackages from "./useFilteredMeasurementsFromPackages";
// import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";

// import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function useMeasurementsByRoom(scene, mode) {
  // const [measurements] = useFilteredSceneMeasurements(scene, mode);
  const measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  //const rooms = useRoomsByScene(scene);

  if (!measurements) return [];

  const roomMeasMap = {};

  measurements
    .filter((m) => m.isRoom)
    .forEach((measurement) => {
      const roomId = measurement.roomId;
      //const room = rooms.find((room) => room.id === roomId);
      if (!roomId) return;

      const roomMeas = roomMeasMap[roomId];
      if (!roomMeas) {
        roomMeasMap[roomId] = {
          length: measurement.length,
          area: measurement.area,
          areaV: measurement.length * measurement.height,
          heights: [measurement.height],
        };
      } else {
        roomMeasMap[roomId] = {
          length: roomMeas.length + measurement.length,
          area: roomMeas.area + measurement.area,
          areaV: roomMeas.areaV + measurement.length * measurement.height,
          heights: [...roomMeas.heights, measurement.height],
        };
      }
      // voids
      if (!filterNoVoids) {
        const voids = measurement.voids;
        if (voids?.length > 0) {
          const voidMs = measurements.filter((m) => voids.includes(m.id));
          voidMs.forEach((voidM) => {
            roomMeasMap[roomId] = {
              length: roomMeasMap[roomId].length,
              area: roomMeasMap[roomId].area,
              areaV: roomMeasMap[roomId].areaV - voidM.area,
              heights: roomMeasMap[roomId].heights,
            };
          });
        }
      }
    });

  // helper

  const msByR = [];
  Object.entries(roomMeasMap).forEach(([roomId, quantities]) => {
    const mByR = {
      roomId,
      ...quantities,
    };
    msByR.push(mByR);
  });

  return msByR;
}
