import {useDispatch} from "react-redux";
import {deleteBimapp} from "../bimappsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteSceneBimapp() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteSceneBimapp = (bimappId) => {
    dispatch(deleteBimapp({bimappId, accessToken}));
  };

  return deleteSceneBimapp;
}
