/*
 * pricing : {id,sharedBimboxId,resId,pu}
 */
import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import { createPrice, deletePrice, updatePrice } from "../pricingSlice";

export default function useUpdatePrices(scene) {
  const dispatch = useDispatch();
  if (!scene) return;

  const updatePrices = (pricings, prices) => {
    for (const [resId, pricing] of Object.entries(prices)) {
      const oldPrice = pricings.find((p) => p.resId === resId);
      if (oldPrice) {
        const oldPu = parseFloat(oldPrice.pu);
        if (pricing.pu && pricing.pu !== oldPu) {
          dispatch(updatePrice({
            sceneId: scene.id,
            price: {...oldPrice, pu: pricing.pu}
          }));
        } else if (!pricing.pu) {
          dispatch(deletePrice({
            sceneId: scene.id,
            price: oldPrice
          }));
        }
      } else if (pricing.pu) {
        dispatch(createPrice({
          sceneId: scene.id,
          price: {
            ...pricing,
            id: nanoid(),
          }
        }));
      }
    }
    // if (!scene) return;
    // const pricesIds = prices.map((r) => r.id);
    // const scenePricesPrev = scene?.data?.prices
    //   ? scene.data.prices
    //   : [];

    // // unchanged
    // let otherPrices = scenePricesPrev.filter(
    //   (r) => !pricesIds.includes(r.id)
    // );

    // // updated
    // let updatedPrevPrices = scenePricesPrev.filter((r) =>
    //   pricesIds.includes(r.id)
    // );
    // let updatedNextPrices = [];
    // if (updatedPrevPrices) {
    //   updatedNextPrices = updatedPrevPrices.map((prevPrice) => {
    //     const newPrice = prices.find((r) => r.id === prevPrice.id);
    //     return {...prevPrice, ...newPrice};
    //   });
    // }

    // // news
    // let prevIds = scenePricesPrev.map((r) => r.id);
    // let newPrices = prices.filter((r) => !prevIds.includes(r.id));
    // newPrices = newPrices.map((r) => ({id: nanoid(), ...r}));

    // const newScene = {
    //   ...scene,
    //   data: {
    //     ...scene?.data,
    //     prices: [
    //       ...otherPrices,
    //       ...updatedNextPrices.filter((r) => !r.toDelete),
    //       ...newPrices,
    //     ],
    //   },
    // };

    // dispatch(updateScene({scene: newScene}));
  };

  return updatePrices;
}
