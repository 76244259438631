import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Button, Typography} from "@mui/material";

import ContainerRotation from "./components/ContainerRotation";

import TitleCloseHeader from "Components/TitleCloseHeader";
import {
  setSelectedModelId,
  setSelectedPdfModelId,
} from "Features/viewer3D/viewer3DSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";

export default function ToolRotation({caplaEditor, onClose}) {
  const dispatch = useDispatch();
  // strings

  const rotationString = "Rotation";
  const rotateString = "Rotate";

  const horizontalizeString = "Horizontal";
  const verticalizeString = "Vertical";

  // data

  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);
  const models = useSelector((s) => s.viewer3D.models);

  let oldModelId;
  let oldPdfModelId;
  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // helpers

  function radToDeg(r) {
    return {
      x: ((r.x * 180) / Math.PI).toFixed(2),
      y: ((r.y * 180) / Math.PI).toFixed(2),
      z: ((r.z * 180) / Math.PI).toFixed(2),
    };
  }

  function degToRad(r) {
    return {
      x: (r.x / 180) * Math.PI,
      y: (r.y / 180) * Math.PI,
      z: (r.z / 180) * Math.PI,
    };
  }

  function rotateEntity(rotation) {
    const r = degToRad(rotation);
    const partId = clickedObject?.partId;

    const updatedModel = {
      id: clickedModel.id,
      rotation: r,
    };

    oldModelId = selectedModelId;
    oldPdfModelId = selectedPdfModelId;
    if (clickedModel?.fromModel) {
      dispatch(setSelectedModelId(clickedModel.fromModel.modelId));
      dispatch(setSelectedPdfModelId(clickedModel.fromModel.modelId));
      caplaEditor.editorPdf.annotationsManager.selectAnnotation(
        clickedModel.fromModel.zoneId
      );
    }

    if (modelEntity.type === "IMAGE_MODEL") {
      caplaEditor.editor3d?.loader.updateImageModel({updatedModel});
      // update pdf
      const fromModel = clickedModel.fromModel;
      const zoneId = fromModel?.zoneId;
      // const pdfModelId = fromModel?.pdfModelId;
      caplaEditor.editorPdf?.annotationsManager.updateAnnotationEntityRotation({
        annotationId: zoneId,
        rotation: {x: r.x, y: r.y, z: r.z},
        updateImageModel: false,
      });
    } else if (modelEntity.rotatePart && partId) {
      modelEntity.rotatePart(partId, r.x, r.y, r.z);
    } else if (modelEntity.type === "IFC_MODEL") {
      modelEntity.setRotation(r.x, r.y, r.z);
    }
  }

  // helper

  const clickedModel = models?.find((m) => m.id === clickedObject?.modelId);

  const modelEntity = caplaEditor.editor3d?.getEntity(clickedModel?.id);

  console.log("Rotate XXX", modelEntity);

  let selectionR = clickedModel?.rotation;
  let selectionName = clickedModel?.name;
  // let selectionType = clickedModel?.type;

  // if (modelEntity?.type === "IFC_MODEL") {
  //   selectionR = modelEntity.rotation;
  //   selectionName = modelEntity.model.name;
  //   selectionType = "MODEL";
  // }

  // state

  const initRotation = selectionR ? radToDeg(selectionR) : {x: 0, y: 0, z: 0};
  const [rotation, setRotation] = useState(initRotation);
  const [isVertical, setIsVertical] = useState(false);

  const slopeString = isVertical ? horizontalizeString : verticalizeString;

  // helpers

  const canRotate = Boolean(selectionName);

  // updates

  useEffect(() => {
    console.log("EFFECT 409 clickedModel", clickedModel);
    if (selectionR) {
      setRotation(radToDeg(selectionR));
    }
    if (clickedModel?.fromModel?.zoneId)
      dispatch(setSelectedZoneId(clickedModel.fromModel.zoneId));
  }, [clickedObject.clickedAt]);

  // handlers

  function handleRotationChange(r) {
    setRotation(r);
  }

  function handleRotateClick() {
    rotateEntity(rotation);
  }

  function handleSlopeClick() {
    if (isVertical) {
      rotateEntity({x: -90, y: rotation.y, z: rotation.z});
      setIsVertical(false);
    } else {
      rotateEntity({x: 0, y: rotation.y, z: rotation.z});
      setIsVertical(true);
    }
  }

  return (
    <Box sx={{backgroundColor: "background.default", width: 1, p: 1}}>
      <TitleCloseHeader
        title={rotationString}
        onClose={onClose}
        divider={false}
      />
      {selectionName && (
        <Box>
          <Box sx={{width: 1, backgroundColor: "white", p: 1}}>
            <Typography variant="body2" noWrap>
              {selectionName}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{mt: 2}}>
        <ContainerRotation
          rotation={rotation}
          onRotationChange={handleRotationChange}
        />
      </Box>

      <Box sx={{display: "flex", mt: 2}}>
        <Button
          variant="small"
          onClick={handleRotateClick}
          disabled={!canRotate}
        >
          {rotateString}
        </Button>
        <Button
          variant="small"
          onClick={handleSlopeClick}
          disabled={!canRotate}
        >
          {slopeString}
        </Button>
      </Box>
    </Box>
  );
}
