import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ListContainerIssuesInViewer from "Features/issues/components/ListContainerIssuesInViewer";
import useIsFullscreen from "Features/ui/useIsFullscreen";

export default function LayerIssues({caplaEditor, scene}) {
  // data

  const showIssues = useSelector((s) => s.issues.showIssuesInViewer);

  const fullscreen = useIsFullscreen();

  // helper - top

  const top = fullscreen ? "16px" : "68px";

  return (
    <>
      {showIssues && (
        <Box sx={{position: "absolute", top, left: "8px", zIndex: 30}}>
          <ListContainerIssuesInViewer
            caplaEditor={caplaEditor}
            scene={scene}
            viewer="PDF"
          />
        </Box>
      )}
    </>
  );
}
