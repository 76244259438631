import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";

import ToolbarAddIssue from "Features/issues/components/ToolbarAddIssue";
import ToolbarDefaultPdf from "Features/pdf/components/ToolbarDefaultPdf";
import ToolbarCreateZone from "Features/zones/components/ToolbarCreateZone";
import ToolbarAddMarkerOnPdf from "Features/markers/components/ToolbarAddMarkerOnPdf";

import ToolbarBottomViewerPdfEditMeasurements from "./ToolbarBottomViewerPdfEditMeasurements";

export default function ToolbarBottomViewerPdf({caplaEditor, scene, viewer}) {
  // data

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  // helpers - show default

  const showDefault =
    !["ZONES", "MARKERS", "ISSUES", "EDIT_MEASUREMENTS"].includes(
      sceneModule
    ) && pageType !== "COLORING";

  return (
    <Box>
      {showDefault && <ToolbarDefaultPdf editorPdf={caplaEditor?.editorPdf} />}
      {sceneModule === "ISSUES" && (
        <ToolbarAddIssue
          caplaEditor={caplaEditor}
          viewer={viewer}
          scene={scene}
        />
      )}
      {(sceneModule === "EDIT_MEASUREMENTS" || pageType === "COLORING") && (
        <ToolbarBottomViewerPdfEditMeasurements
          caplaEditor={caplaEditor}
          scene={scene}
        />
      )}
      {sceneModule === "ZONES" && (
        <ToolbarCreateZone caplaEditor={caplaEditor} scene={scene} />
      )}
      {sceneModule === "MARKERS" && (
        <ToolbarAddMarkerOnPdf caplaEditor={caplaEditor} scene={scene} />
      )}
    </Box>
  );
}
