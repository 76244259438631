import React from "react";

import {Box, Typography, Paper} from "@mui/material";

import {decode} from "Features/translations/utils";

export default function ListItemScene({scene, onClick, selected = true}) {
  // helpers

  const title = decode(scene.title);

  function handleClick() {
    onClick(scene);
  }
  return (
    <Paper
      sx={{px: 1, py: 0.5, cursor: "pointer", width: "fit-content"}}
      elevation={selected ? 12 : 1}
      onClick={handleClick}
    >
      <Typography sx={{fontWeight: selected ? "bold" : "normal", fontSize: 13}}>
        {title}
      </Typography>
    </Paper>
  );
}
