import {useSelector} from "react-redux";
import getRawMeasurementSubjectsOverviewByAuthor from "../utils/getRawMeasurementSubjectsOverviewByAuthor";

export default function useRawMeasurementSubjects(scene) {
  const author = useSelector((s) => s.annotatedPdf.selectedAuthor);
  const rawMeasurements = useSelector((s) => s.annotatedPdf.rawMeasurements);

  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );
  const subjectTypeVarMap = useSelector(
    (s) => s.annotatedPdf.subjectTypeVarMap
  );
  const subjectGeoCatMap = useSelector((s) => s.annotatedPdf.subjectGeoCatMap);

  let subjects = getRawMeasurementSubjectsOverviewByAuthor(
    rawMeasurements,
    author
  );

  subjects = subjects.map((subject) => {
    const elementType = subjectElementTypeMap[subject.subject];
    const typeVar = subjectTypeVarMap[subject.subject];
    const geoCat = subjectGeoCatMap[subject.subject];
    return {...subject, elementType, typeVar, geoCat};
  });

  return subjects;
}
