import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormSharedbox from "./FormSharedbox";
import CardSharedboxUrl from "./CardSharedboxUrl";

import {setEditedSharedbox, setTempSharedbox} from "../sharedboxesSlice";

export default function SectionSharedboxInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const isEditing = useSelector((s) => s.sharedboxes.isEditingSharedbox);
  const editedSharedbox = useSelector((s) => s.sharedboxes.editedSharedbox);
  const tempSharedbox = useSelector((s) => s.sharedboxes.tempSharedbox);
  const selectedSharedbox = useSelector((s) => s.sharedboxes.selectedSharedbox);

  // helper

  const isTemp = !selectedSharedbox && !isEditing;
  const isEdited = selectedSharedbox && isEditing;
  const isSelected = selectedSharedbox && !isEditing;

  // handlers

  function handleTempSharedboxChange(sharedbox) {
    dispatch(setTempSharedbox(sharedbox));
  }
  function handleEditedSharedboxChange(sharedbox) {
    dispatch(setEditedSharedbox(sharedbox));
  }
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
        {isTemp && (
          <FormSharedbox
            scene={scene}
            sharedbox={tempSharedbox}
            onChange={handleTempSharedboxChange}
            canEdit={true}
          />
        )}
        {isSelected && (
          <Box sx={{width: 1}}>
            <CardSharedboxUrl sharedbox={selectedSharedbox} scene={scene} />
            <FormSharedbox
              sharedbox={selectedSharedbox}
              canEdit={false}
              scene={scene}
            />
          </Box>
        )}
        {isEdited && (
          <Box sx={{width: 1}}>
            <FormSharedbox
              scene={scene}
              sharedbox={editedSharedbox}
              onChange={handleEditedSharedboxChange}
              canEdit={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
