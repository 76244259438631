import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";

import {setAutoSync} from "../articlesSlice";

import {resetAreFetched, cancelUpdates} from "../articlesSlice";

import useUpdateInBatchArticlesToUpdate from "../hooks/useUpdateInBatchArticlesToUpdate";

import useAccessToken from "Features/auth/useAccessToken";
import useArticlesToSync from "../hooks/useArticlesToSync";
import useArticlesGroupsToSync from "../hooks/useArticlesGroupsToSync";

import {
  Paper,
  Box,
  IconButton,
  Menu,
  Icon,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  MenuItem,
  ListItemText,
} from "@mui/material";

import {Sync, ArrowDropDown} from "@mui/icons-material";

import ContainerArticlesToSync from "./ContainerArticlesToSync";

export default function ButtonSyncArticles({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  // strings

  const autoSyncS = "Synchro auto";
  const updateS = "Mettre à jour";

  // data

  const autoSync = useSelector((s) => s.articles.autoSync);
  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const articlesToSync = useArticlesToSync(scene);
  const articlesGroupsToSync = useArticlesGroupsToSync(scene);

  // data - func

  const updateInBatch = useUpdateInBatchArticlesToUpdate();

  // state

  const [isLoading, setIsLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  const syncCount = articlesToSync?.length + articlesGroupsToSync?.length;
  const shouldSync = syncCount > 0;

  // helpers - functions

  async function fetchArticlesGroupsAndArticles() {
    dispatch(resetAreFetched());
  }
  // handlers

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAutoSyncChange(e) {
    const checked = e.target.checked;
    dispatch(setAutoSync(checked));
  }

  async function handleUpdate() {
    handleClose();
    if (isLoading) return;
    setIsLoading(true);
    await fetchArticlesGroupsAndArticles();
    setIsLoading(false);
  }

  function handelCancel() {
    dispatch(cancelUpdates());
    handleClose();
    setIsLoading(false);
  }

  async function handleSave() {
    handleClose();
    if (isLoading) return;
    setIsLoading(true);
    await updateInBatch(accessToken);
    setIsLoading(false);
  }

  async function handleSyncButtonClick() {
    if (isLoading) return;
    setIsLoading(true);
    if (shouldSync) {
      await updateInBatch(accessToken);
    } else {
      await fetchArticlesGroupsAndArticles();
    }
    setIsLoading(false);
  }

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box>
          <ContainerArticlesToSync
            count={syncCount}
            onCancel={handelCancel}
            onSave={handleSave}
          />
        </Box>
        <Box sx={{px: 1}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoSync}
                onChange={handleAutoSyncChange}
                size="small"
              />
            }
            label={<Typography variant="body2">{autoSyncS}</Typography>}
          />
        </Box>
        <Divider />

        <MenuItem onClick={handleUpdate}>
          {/* <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon> */}
          <ListItemText>
            <Typography variant="body2">{updateS}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>

      <Paper
        elevation={shouldSync ? 6 : 0}
        sx={{
          borderRadius: "4px",
          alignItems: "stretch",
          color: shouldSync ? "warning.main" : "grey.500",
          //mr: 1,
          pl: 1,
          display: "flex",
          //alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            size="small"
            onClick={handleSyncButtonClick}
            color="inherit"
            sx={{
              animation: isLoading ? "rotation 2s infinite" : "none",
              "@keyframes rotation": {
                "0%": {
                  transform: "rotate(0deg)",
                },
                "100%": {
                  transform: "rotate(-360deg)",
                },
              },
            }}
          >
            <Sync fontSize="small" />
          </IconButton>
        </Box>

        <IconButton size="small" color="inherit" onClick={handleClick}>
          <ArrowDropDown fontSize="small" />
        </IconButton>
      </Paper>
    </>
  );
}
