import {Vector3, Line3, Vector4} from "three";

export async function rotateImageUrl(url, rotation) {
  if (rotation === 0) return url;

  var img = new Image();

  return new Promise((resolve) => {
    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (rotation === 1 || rotation === 3) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (rotation) {
        case 2: // 180 => 0
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 1: // 270 => 0
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 3: // 90 => 0
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      resolve(canvas.toDataURL());
    };

    img.src = url;
  });
}

function getDistance(point, point1, point2) {
  console.log("getDistance", point, point1, point2);
  const A = new Vector3(point.x, point.y, 0);
  const B = new Vector3(point1.x, point1.y, 0);
  const C = new Vector3(point2.x, point2.y, 0);
  const D = new Vector3();
  const line = new Line3(B, C);
  line.closestPointToPoint(A, false, D);

  const AB = A.distanceTo(B);
  const AC = A.distanceTo(C);

  const start = AB <= AC;

  return {d: D.distanceTo(A), x: D.x, y: D.y, start};
}

export function getClosestPoints(path, point) {
  let _d, _x, _y, _index, _start;
  path.forEach((p, ind) => {
    if (ind === 0) return;
    const point1 = path[ind - 1];
    const point2 = path[ind];
    const {d, x, y, start} = getDistance(point, point1, point2);
    if (!_d) {
      _d = d;
      _x = x;
      _y = y;
      _index = start ? ind - 1 : ind;
      _start = start;
    } else if (d < _d) {
      _d = d;
      _x = x;
      _y = y;
      _index = start ? ind - 1 : ind;
      _start = start;
    }
  });
  return {x: _x, y: _y, index: _index, start: _start};
}
