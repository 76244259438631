import {useState} from "react";

import {Box, Button} from "@mui/material";

import {useSelector, useDispatch} from "react-redux";
import useSelectedElementTypesGroupInMeasurements from "Features/measurements/useSelectedElementTypesGroupInMeasurements";
import useTempElementType from "../hooks/useTempElementType";
import {updateElementTypesGroup} from "Features/elementTypes/elementTypesSlice";

import useAccessToken from "Features/auth/useAccessToken";
import {nanoid} from "@reduxjs/toolkit";
import updateElementTypesGroupWithOneUpdatedType from "Features/elementTypes/utils/updateElementTypesGroupWithOneUpdatedType";
import {setOpenDialogFsCreateElementType} from "../elementTyporSlice";

export default function SectionButtonCreateElementType({caplaEditor}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // string

  const createS = "Créer le type";

  // state

  const [loading, setLoading] = useState(false);

  // data

  const group = useSelectedElementTypesGroupInMeasurements();

  const tempElementType = useTempElementType();

  // handlers

  async function handleCreate() {
    //
    if (loading) return;
    setLoading(true);
    //
    const newType = {
      id: nanoid(),
      ...tempElementType,
    };
    //
    const updatedGroup = updateElementTypesGroupWithOneUpdatedType(
      newType,
      group
    );
    //
    caplaEditor?.editorPdf?.annotationsManager.deleteTempAnnotationToCreateType();
    console.log("[DEBUG] POST DELETE 1");
    //
    await dispatch(
      updateElementTypesGroup({
        accessToken,
        elementTypesGroup: updatedGroup,
      })
    );

    //
    setLoading(false);
    //
    dispatch(setOpenDialogFsCreateElementType(false));
    console.log("[DEBUG] POST DELETE 2");
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        p: 1,
        py: 2,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        color="secondary"
        variant="contained"
        fullWidth
        onClick={handleCreate}
      >
        {createS}
      </Button>
    </Box>
  );
}
