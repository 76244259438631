import {useSelector, useDispatch} from "react-redux";
import {Box, Typography, TextField} from "@mui/material";

import SectionSelectorDrawingShape from "./SectionSelectorDrawingShape";
import SectionSelectorColor from "./SectionSelectorColor";

import {goToNext} from "../elementTyporSlice";

export default function SectionStepShape({}) {
  const dispatch = useDispatch();

  // handler

  function handleSelect(shape) {
    dispatch(goToNext());
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        minHeight: 0,
        p: 2,
      }}
    >
      <Box sx={{overflow: "auto", flexGrow: 1, minHeight: 0, display: "flex"}}>
        <SectionSelectorDrawingShape />
        <SectionSelectorColor />
      </Box>
    </Box>
  );
}
