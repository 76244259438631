// styles

const styleStringMap = {
  title1: "titre_1",
  title2: "titre_2",
  detail: "détail",
};

const applyStyle = (row, i, isTitle, rank, style) => {
  if (
    (isTitle && style === undefined) ||
    ["title1", "title2"].includes(style)
  ) {
    row.getCell(i).font = {bold: true};
    if (rank === 1 || style === "title1")
      row.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {argb: "bbbbc0"},
      };
    if (rank === 2 || style === "title2")
      row.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {argb: "F4F4F8"},
      };
  }
  if (style === "detail") row.getCell(i).font = {color: {argb: "777777"}};
};

export default function fillWorksheetWithRessourcesWithIds({
  worksheet,
  ressourcesWithIds,
  rowStartIndex,
}) {
  ressourcesWithIds.forEach(
    (
      {
        id,
        group,
        rank,
        num,
        name,
        code,
        isTitle,
        unit,
        dec,
        refQty,
        hardcodedQty,
        description,
        location,
        style,
        hideNum,
      },
      index
    ) => {
      const row = worksheet.getRow(rowStartIndex + index);

      for (let i = 1; i <= 15; i++) {
        applyStyle(row, i, isTitle, rank, style);
      }

      if (typeof id === "string") row.getCell(1).value = id;
      if (typeof group === "string") row.getCell(2).value = group;
      if (typeof num === "string") row.getCell(3).value = num;
      if (typeof name === "string") row.getCell(4).value = name;
      if (typeof code === "string") row.getCell(5).value = code;
      if (typeof isTitle === "boolean")
        row.getCell(6).value = isTitle ? "x" : "";
      if (typeof style === "string")
        row.getCell(7).value = styleStringMap[style];
      if (typeof hideNum === "boolean")
        row.getCell(8).value = hideNum ? "x" : "";
      if (typeof unit === "string") row.getCell(9).value = unit;
      if (typeof dec === "number") row.getCell(10).value = dec;
      if (typeof refQty === "number") row.getCell(11).value = refQty;
      if (typeof hardcodedQty === "number")
        row.getCell(12).value = hardcodedQty;
      if (typeof location === "string") row.getCell(13).value = location;
      if (typeof description === "string") row.getCell(14).value = description;
    }
  );
}
