import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

import ButtonToggleIsOpenedInDataGrid from "./ButtonToggleIsOpenedInDataGrid";
import ButtonSwitchPriorityInDataGrid from "./ButtonSwitchPriorityInDataGrid";
import IconButtonShowIssue from "./IconButtonShowIssue";

import {setSelectedIssueId} from "../issuesSlice";
import useIssuesForDataGrid from "../useIssuesForDataGrid";
import useFilteredIssues from "../useFilteredIssues";

export default function DataGridIssues({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // strings

  const titleS = "Title";
  const priorityS = "Priority";
  const actionS = "Action";
  const showS = "Show";

  // data

  const selectedIssueId = useSelector((s) => s.issues.selectedIssueId);
  const [issues] = useFilteredIssues(scene);

  // helpers - selectionModel

  const selectionModel = selectedIssueId ? [selectedIssueId] : [];

  // grid - rows

  const rows = useIssuesForDataGrid(scene);

  // grid - columns

  const columns = [
    {
      field: "title",
      headerName: titleS,
      flex: 0.2,
    },

    {
      field: "priority",
      headerName: priorityS,
      width: 150,
      renderCell: (params) => (
        <ButtonSwitchPriorityInDataGrid
          id={params.row.id}
          _listingId={params.row._listingId}
          priority={params.row.priority}
        />
      ),
    },
    {
      field: "action",
      headerName: actionS,
      width: 100,
      valueGetter: (params) => params.row.is_opened,
      renderCell: (params) => (
        <ButtonToggleIsOpenedInDataGrid
          id={params.row.id}
          _listingId={params.row._listingId}
          opened={params.row.is_opened}
        />
      ),
    },
    {
      field: "show",
      headerName: showS,
      width: 70,
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <IconButtonShowIssue
          issueId={params.row.id}
          caplaEditor={caplaEditor}
          issues={issues}
          scene={scene}
        />
      ),
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    if (selection?.length > 0) {
      dispatch(setSelectedIssueId(selection[0]));
    }
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
