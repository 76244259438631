import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";
import ListInputsMultiple from "./ListInputsMultiple";
import useInputsByScene from "../hooks/useInputsByScene";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";
import useSelectedInput from "../hooks/useSelectedInput";
import {setSelectedInputId} from "../inputsSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

export default function SectionInputsInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  //
  caplaEditor.timeEnd = performance.now();
  console.log(
    "debugtime navtime toInputs",
    caplaEditor.timeEnd - caplaEditor.timeStart
  );

  // strings

  const noInputS = "Aucun PDF";

  // data

  const inputs = useInputsByScene(scene, {filterByScope: true});

  console.log("[DEBUG] inputs", inputs);

  const versions = useVersionsByScene(scene);

  const selectedVersionForMeasurements = useSelector(
    (s) => s.versions.selectedVersionForMeasurements
  );

  // data - selection

  const selectedInput = useSelectedInput();
  console.log("[DEBUG] selectedInput", selectedInput);

  // helper - selection

  let selection = null;
  if (selectedInput) selection = [selectedInput.id];

  // helpers

  const items = inputs
    .filter((input) => {
      if (!selectedVersionForMeasurements) {
        return true;
      } else {
        if (!Array.isArray(input?.revisionIds)) {
          return true;
        } else {
          return input.revisionIds.includes(selectedVersionForMeasurements.id);
        }
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  // handlers

  function handleSelectionChange(selection) {
    let id;
    console.log("[DEBUG] selection inputs", selection);
    if (selection?.length > 0) id = selection[0];
    dispatch(setSelectedInputId(id));
    if (id) dispatch(setSelectedPdfModelId(id));
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        minHeight: 0, // make it works !!!
        overflow: "auto",
      }}
    >
      {inputs?.length > 0 ? (
        <ListInputsMultiple
          scene={scene}
          inputs={items}
          selection={selection}
          onSelectionChange={handleSelectionChange}
          sceneVersions={versions}
          selectedVersionForMeasurements={selectedVersionForMeasurements}
          showVersionIcon={true}
          multipleSelection={false}
        />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            justifyItems: "strech",
          }}
        >
          <Box sx={{display: "flex"}}>
            <Typography variant="h6" color="divider">
              {noInputS}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
