import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Button, IconButton, Box, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {
  VideoCall,
  Add,
  Sync,
  Adjust as Open,
  CheckCircleOutline as Close,
  DataObject as Elements,
  PictureAsPdf as PdfExtractedTextIcon,
} from "@mui/icons-material";

import {addTempNoteElement} from "Features/note/noteSlice";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function Toolbar({
  onToolClick,
  onSave,
  onClose,
  loading,
  update = false,
  isOpened = false,
  closeButton,
  viewpoint = false,
  elements = false,
  showSave = true,
  viewer,
  onAnnotationCancel,
}) {
  const {t} = useTranslation("common");
  const dispatch = useDispatch();

  // data

  const selection = useSelector((state) => state.viewer3D.selection);

  const allModels = useSelector((state) => state.viewer3D.models);
  const model = allModels.find((m) => m.id === selection?.modelId);

  const codeName = selection?.codeName;
  const modelRemoteId = model?.remoteId;

  // strings

  const closeString = capitalizeStart(t("common:close"));
  const openString = capitalizeStart(t("common:open"));
  const addString = capitalizeStart(t("common:add"));
  const cancelString = capitalizeStart(t("common:cancel"));

  const addAnnotationString = "Annotate the viewpoint ...";
  const selectElementString = "Select an element";

  const closeButtonString = isOpened ? closeString : openString;

  // state

  const [annotating, setAnnotating] = useState(false);
  const [selecting, setSelecting] = useState(false);

  // helpers

  const selectString = codeName ? codeName : selectElementString;

  // validation

  const canSelect = codeName && modelRemoteId;

  // handlers

  function handleInitViewpointClick() {
    setAnnotating(true);
    onToolClick("initViewpoint");
  }

  function handleViewpointClick() {
    setAnnotating(false);
    onToolClick("viewpoint");
  }

  function handleCancelClick() {
    setAnnotating(false);
    onAnnotationCancel();
  }

  // handlers - selection

  function handleInitElementSelectionClick() {
    setSelecting(true);
  }
  function handleSelectClick() {
    dispatch(addTempNoteElement({modelRemoteId, codeName}));
    setSelecting(false);
  }

  function handleSelectCancel() {
    setSelecting(false);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        background: (theme) => theme.palette.background.default,
        padding: 0.5,
        position: "relative",
      }}
    >
      {annotating && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: "common.white",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography variant="body2" align="center" color="text.secondary">
            {addAnnotationString}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Button
              size="small"
              startIcon={<Add />}
              onClick={handleViewpointClick}
            >
              {addString}
            </Button>
            <Button size="small" onClick={handleCancelClick}>
              {cancelString}
            </Button>
          </Box>
        </Box>
      )}
      {selecting && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            bgcolor: "common.white",
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Typography variant="body2" align="center" color="text.secondary">
            {selectString}
          </Typography>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Button
              size="small"
              startIcon={<Add />}
              onClick={handleSelectClick}
              disabled={!canSelect}
            >
              {addString}
            </Button>
            <Button size="small" onClick={handleSelectCancel}>
              {cancelString}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          "&>*": {
            marginRight: 0.5,
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => onToolClick("pdfExtractedText")}
        >
          <PdfExtractedTextIcon color="action" />
        </IconButton>
        {viewpoint && (
          <IconButton size="small" onClick={handleInitViewpointClick}>
            <VideoCall color="action" />
          </IconButton>
        )}
        {elements && (
          <IconButton size="small" onClick={handleInitElementSelectionClick}>
            <Elements fontSize="small" color="action" />
          </IconButton>
        )}
      </Box>
      <Box sx={{display: "flex", alignItems: "center"}}>
        {closeButton && !viewer && (
          <Button
            variant="outlined"
            onClick={onClose}
            size="small"
            sx={{mr: 1}}
            startIcon={isOpened ? <Close /> : <Open />}
          >
            {closeButtonString}
          </Button>
        )}

        {showSave && (
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            onClick={onSave}
            disableElevation
            loading={loading}
            loadingPosition="start"
            startIcon={update ? <Sync /> : <Add />}
          >
            {update ? "Enregistrer" : "Ajouter"}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}
