import {useState} from "react";
import {useDispatch} from "react-redux";

import {Box, Paper, Typography, Button} from "@mui/material";

import difference from "Utils/difference";

import {setMultipleSelection} from "Features/viewer3D/viewer3DSlice";
import SelectorObjectset from "Features/objectsets/components/SelectorObjectset";
import SelectorObjectsetProp from "Features/objectsets/components/SelectorObjectsetProp";
import useTranslation from "Features/translations/useTranslation";

export default function FormCreateDifference({scene, editor3d, onCreate}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("objectsets");

  // strings

  const descriptionString = t("compare.description");
  const oldString = t("compare.oldDataset");
  const newString = t("compare.newDataset");
  const idString = t("compare.identifier");
  const idHelper = t("compare.identifierHelper");
  const createString = t("compare.compare");

  // state

  const [objectsetOld, setObjectsetOld] = useState(null);
  const [objectsetNew, setObjectsetNew] = useState(null);
  const [idNew, setIdNew] = useState(null);
  const [idOld, setIdOld] = useState(null);

  // helpers

  const canCompute = objectsetOld && objectsetNew && idNew && idOld;

  function arrayToObject(array, key) {
    const object = {};
    array.forEach((el) => {
      const element = {...el};
      delete element.id;
      const id = element[key];
      object[id] = element;
    });
    return object;
  }

  function computeDifference() {
    const entity = editor3d?.getEntity(objectsetNew.modelId);
    const ifcModelID = entity?.ifcModelID;
    const modelId = objectsetNew.modelId;

    const oldItems = objectsetOld.items;
    const newItems = objectsetNew.items;
    const oldO = arrayToObject(oldItems, idOld);
    const newO = arrayToObject(newItems, idNew);
    const exceptKeys = ["expressID", "x", "y", "z"];
    const {news, deletes, changes} = difference(oldO, newO, exceptKeys);

    // differences

    const differences = [];
    // changes
    Object.keys(changes).forEach((c) => {
      if (Object.keys(changes[c])?.length > 0) {
        const item = {
          type: "CHANGE",
          ifcModelID,
          modelId,
          expressID: newO[c].expressID,
          changes: changes[c],
        };
        differences.push(item);
      }
    });
    // news
    Object.keys(news).forEach((n) => {
      const item = {
        type: "NEW",
        ifcModelID,
        modelId,
        expressID: newO[n].expressID,
        new: news[n],
      };
      differences.push(item);
    });
    // deletes
    Object.keys(deletes).forEach((d) => {
      const item = {
        type: "DELETE",
        ifcModelID,
        modelId,
        expressID: oldO[d].expressID,
        old: deletes[d],
      };
      differences.push(item);
    });

    console.log("DIFFERENCES", differences);
    const expressIDsDiff = differences
      .filter((d) => d.type !== "DELETE")
      .map((d) => d.expressID);

    // multiple selection

    const ms = [
      {
        modelId: objectsetNew.modelId,
        ifcModelID,
        expressIDs: expressIDsDiff,
        type: "IFC_MODEL_ELEMENTS",
      },
    ];
    dispatch(setMultipleSelection(ms));

    editor3d?.multipleSelect(ms);

    // send result to parent

    onCreate(differences);
  }

  // function selectDifference() {}

  // handlers

  function handleObjectsetOldChange(objectset) {
    setObjectsetOld(objectset);
  }

  function handleObjectsetNewChange(objectset) {
    setObjectsetNew(objectset);
  }

  function handleIdOldChange(prop) {
    setIdOld(prop);
  }

  function handleIdNewChange(prop) {
    setIdNew(prop);
  }

  function handleCreateClick() {
    computeDifference();
  }

  return (
    <Paper sx={{bgcolor: "common.white"}}>
      <Box sx={{p: 1, mb: 2}}>
        <Box
          sx={{
            bgcolor: "secondary.main",
            color: "common.white",
            p: 1,
          }}
        >
          <Typography variant="body2" color="inherit">
            {descriptionString}
          </Typography>
        </Box>
      </Box>
      <Box sx={{display: "flex"}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            p: 0.5,
            "&>*:not(:last-child)": {
              mb: 1,
            },
          }}
        >
          <Typography variant="body2" align="center">
            {oldString}
          </Typography>
          <SelectorObjectset
            sceneClientId={scene?.clientId}
            onChange={handleObjectsetOldChange}
          />
          <SelectorObjectsetProp
            objectset={objectsetOld}
            label={idString}
            onChange={handleIdOldChange}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
            p: 0.5,
            "&>*:not(:last-child)": {
              mb: 1,
            },
          }}
        >
          <Typography variant="body2" align="center">
            {newString}
          </Typography>
          <SelectorObjectset
            sceneClientId={scene?.clientId}
            onChange={handleObjectsetNewChange}
          />
          <SelectorObjectsetProp
            objectset={objectsetOld}
            label={idString}
            onChange={handleIdNewChange}
          />
        </Box>
      </Box>
      <Box sx={{p: 1}}>
        {" "}
        <Typography variant="caption">{idHelper}</Typography>
      </Box>

      <Box sx={{p: 1}}>
        <Button disabled={!canCompute} onClick={handleCreateClick}>
          {createString}
        </Button>
      </Box>
    </Paper>
  );
}
