import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtPhase from "Features/kvform/hooks/useKvtPhase";

export default function FormPhase({
  phase,
  onChange,
  hiddenFields = [],
  canEdit
}) {
  // template

  const template = useKvtPhase();

  return phase ? (
    <KeyValueForm
      item={phase}
      template={template}
      onChange={onChange}
      hiddenFields={hiddenFields}
      sizeKey={5}
      canEdit={canEdit}
    />
  ) : (
    <div />
  );
}
