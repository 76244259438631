import React from "react";

import {Box, Typography} from "@mui/material";

import ImageBasic from "Features/images/components/ImageBasic";

export default function CardImageModelPreview({previewUrl}) {
  // strings

  const previewS = "Aperçu";

  return (
    <Box>
      <Box sx={{px: 1, py: 0.5, bgcolor: "background.default"}}>
        <Typography sx={{fontSize: 13}}>{previewS}</Typography>
      </Box>
      <Box sx={{height: 240, width: 1}}>
        <ImageBasic url={previewUrl} />
      </Box>
    </Box>
  );
}
