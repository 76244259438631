import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import getElementTypesCountFromGroups from "Features/elementTypes/utils/getElementTypesCountFromGroups";

export const overviewerSlice = createSlice({
  name: "overviewer",
  initialState: {
    // save and sync
    saveTriggeredAt: null,
    //
    oneFirstScopeWasOpened: false, // use to trigger ScreenStartApp in App. Open a scope = loading model data + viewer.loader.
    lastOpenedScopeIsOutOfDateTestWasDone: false, // used in initial loading to trigger/not trigger the DialogFsSelectModel
    //
    modelIdsInMeasDataManager: [], // use to init dataManager with data in store + used when creating a package.
    // [1] updated when fetchSceneOverview is fullfilled.
    sceneOverview: null,
    sceneIdOfSceneOverview: null,
    currentSceneIsRemote: null,
    //
    sceneIdClickedFromScene: null,
    //
    sceneIdOfSceneLight: null, // use to trigger initFetchSceneLight
    modelsCountFromSceneLight: null,
    //
    sceneIdOfFetchedElementTypeGroups: null, // use to track initial scene download
    elementTypesCount: 0, //
    //
    scopeIdToOpen: null, // set when clicking on "Open Scope" // or from the scope selector when clicking on open // use to trigger fetching & loading in 3D.
    lastOpenedScopeId: null, // set when the Scope has been opened ( = models loaded)
    openedScopeIds: [], // array of scopes that where already opened.
    //
    // DialogFs (Fullscreen) Open Values
    //
    openDialogFsInvalidBimbox: false,
    openDialogFsFetchSceneData: false,
    openDialogFsSelectScope: false,
    openDialogFsOpenScope: false,
    openDialogFsLoadingScope: false,
    //
    loadingStatusModelIdsMap: {
      LIGHT: [],
      IN_STATE: [],
      IN_3D_OVERVIEW: [],
      IN_3D_DETAILED: [],
    },
    //
    // Main scope = packages in scope + version + helpers
    //
    packageIdsInMainScope: null, // null = all, [] = none // counter in the left panel - scope + version + pdf (in ui = black bgcolor)
    enabledPackageIdsInMainScope: null, //selected = data loaded in measurement.items // visible in 3D, as "transparent" if not in filter
    editablePackageIdsInMainScope: null, // can be edited [id]
    helperPackageIdsInMainScope: null, // visible, but are not counted in counter
    measCountPackageIdMap: {}, // {packageId:measCount}
    scopeAndCounter: {
      entityType: "DEFAULT",
      iconType: "DEFAULT",
      callToAction: "...",
      packageIdCounterMap: {},
    },
    //
    // Downloading models in state
    //
    loadingRatioByScopeIdMap: {}, // used to trigger viewer3D loading once the data are in state.{scopeId:{modelIdsToFetch:[],ratio:80}}
    //
    // Loading models
    modelIdBeingLoadedInViewer3d: null, // use to display info on the loading screen; <ScreenLoadingPackageInViewer3d/>
    showScreenLoadingPackageInViewer3d: false,
    //
    // load models in viewer3d
    //
    loadModelsTriggeredAt: null,
    modelIdsToLoad: [],
    loadingOrderIndex: 0,
    loadingOrderIndexOfLastSuccess: 0,
  },
  reducers: {
    // save and sync
    triggerSave: (state) => {
      state.saveTriggeredAt = Date.now();
    },
    // clear scene
    //
    clearSceneData: (state) => {
      state.sceneIdOfSceneOverview = null;
      state.sceneIdClickedFromScene = null;
    },
    //
    setLastOpenedScopeIsOutOfDateTestWasDone: (state, action) => {
      state.lastOpenedScopeIsOutOfDateTestWasDone = action.payload;
    },
    setOneFirstScopeWasOpened: (state, action) => {
      state.oneFirstScopeWasOpened = action.payload;
    },
    //
    setModelIdsInMeasDataManager: (state, action) => {
      state.modelIdsInMeasDataManager = action.payload;
    },
    addModelIdToMeasDataManager: (state, action) => {
      const modelId = action.payload;
      if (!state.modelIdsInMeasDataManager.includes(modelId)) {
        state.modelIdsInMeasDataManager.push(modelId);
      }
    },
    // mainScope
    setMainScopeWithOneModel: (state, action) => {
      const packageId = action.payload;
      state.packageIdsInMainScope = [packageId];
      state.enabledPackageIdsInMainScope = [packageId];
      state.editablePackageIdsInMainScope = [packageId];
      state.packageIdsInMainScope = null;
    },
    setMeasCountPackageIdMap: (state, action) => {
      state.measCountPackageIdMap = action.payload;
    },
    setPackageIdsInMainScope: (state, action) => {
      const ids = action.payload;
      state.packageIdsInMainScope = ids;
    },
    setEnabledPackageIdsInMainScope: (state, action) => {
      const ids = action.payload;
      state.enabledPackageIdsInMainScope = ids;
    },
    setHelperPackageIdsInMainScope: (state, action) => {
      state.helperPackageIdsInMainScope = action.payload;
    },
    setScopeAndCounter: (state, action) => {
      state.scopeAndCounter = action.payload;
    },
    // initial loading
    setSceneIdClickedFromScenes: (state, action) => {
      state.sceneIdClickedFromScene = action.payload;
    },
    setLoadedSceneId: (state, action) => {
      state.loadedSceneId = action.payload;
    },
    setOpenDialogFsFetchSceneData: (state, action) => {
      state.openDialogFsFetchSceneData = action.payload;
    },
    setOpenDialogFsSelectScope: (state, action) => {
      state.openDialogFsSelectScope = action.payload;
    },
    setOpenDialogFsOpenScope: (state, action) => {
      state.openDialogFsOpenScope = action.payload;
    },
    setOpenDialogFsLoadingScope: (state, action) => {
      state.openDialogFsLoadingScope = action.payload;
    },
    setOpenDialogFsInvalidBimbox: (state, action) => {
      state.openDialogFsInvalidBimbox = action.payload;
    },
    //
    setScopeIdToOpen: (state, action) => {
      const id = action.payload;
      state.scopeIdToOpen = id;
    },
    openScope: (state, action) => {
      const id = action.payload;
      console.log("openScopeId in state", id);
      state.lastOpenedScopeId = id;
      const oldIds = state.openedScopeIds;
      state.openedScopeIds = [...new Set([...oldIds, id])];
    },
    // loading Status
    updateStatusMapWith: (state, action) => {
      const {statusKey, newIds} = action.payload;
      const oldIds = state.loadingStatusModelIdsMap[statusKey];
      state.loadingStatusModelIdsMap[statusKey] = [
        ...new Set([...oldIds, ...newIds]),
      ];
    },
    updateLoadingRatioByScopeIdMap: (state, action) => {
      const {ratio, scopeId, modelIdsToFetch} = action.payload;
      const scopeData = state.loadingRatioByScopeIdMap[scopeId] ?? {};
      if (ratio) {
        state.loadingRatioByScopeIdMap[scopeId] = {...scopeData, ratio};
      }
      if (modelIdsToFetch) {
        state.loadingRatioByScopeIdMap[scopeId] = {
          ...scopeData,
          modelIdsToFetch,
        };
      }
    },
    // loading models
    setModelIdBeingLoadedInViewer3d: (state, action) => {
      state.modelIdBeingLoadedInViewer3d = action.payload;
    },
    // load Models in 3D
    setLoadModelsTriggeredAt: (state) => {
      state.loadModelsTriggeredAt = Date.now();
    },
    triggerNewLoadingOrder: (state, action) => {
      state.showScreenLoadingPackageInViewer3d = true;
      const {modelIds} = action.payload;
      const lastOrderEnded =
        state.loadingOrderIndex === state.loadingOrderIndexOfLastSuccess;
      if (lastOrderEnded) {
        state.loadingOrderIndex += 1;
        state.loadModelsTriggeredAt = Date.now();
        state.modelIdsToLoad = modelIds;
      }
    },
    endLoadingOrder: (state) => {
      state.showScreenLoadingPackageInViewer3d = false;
      state.loadingOrderIndexOfLastSuccess = state.loadingOrderIndex;
    },
  },
  extraReducers: {
    "scenes/fetchRemoteSceneOverview/fulfilled": (state, action) => {
      const response = action.payload;
      console.log("FETCHED REMOTE SCENE OVERVIEW", response);
      const remoteSceneOverview = response?.remoteSceneOverview;
      const sceneId = response?.sceneId;
      state.sceneOverview = remoteSceneOverview;
      state.sceneIdOfSceneOverview = sceneId;
      state.currentSceneIsRemote = true;
    },
    "scenes/fetchRemoteSceneOverview/rejected": (state, action) => {
      const error = action.error;
      console.log("error fetching Remote scene", error, action);
      state.currentSceneIsRemote = false;
      state.openDialogFsInvalidBimbox = true;
      //state.sceneIdOfSceneOverview = sceneId;
    },
    "scenes/fetchRemoteSceneLight/fulfilled": (state, action) => {
      const scene = action.payload;
      const sceneId = scene?.id;
      console.log("sceneAA", scene);
      const modelIds = scene?.models ? scene.models.map((m) => m.id) : [];
      //const modelsCountFromSceneLight = modelIds.length;
      state.modelsCountFromSceneLight = modelIds.length;
      state.sceneIdOfSceneLight = sceneId;
      const oldIds = state.loadingStatusModelIdsMap["LIGHT"];
      state.loadingStatusModelIdsMap["LIGHT"] = [
        ...new Set([...oldIds, ...modelIds]),
      ];
    },
    "viewer3D/fetchRemoteModel/fulfilled": (state, action) => {
      const model = action.payload;
      const modelId = model?.id;
      console.log("FETCH REMOTE MODEL", model);
      const oldIds = state.loadingStatusModelIdsMap["IN_STATE"];
      state.loadingStatusModelIdsMap["IN_STATE"] = [
        ...new Set([...oldIds, modelId]),
      ];
    },
    "elementTypes/fetchElementTypesGroups/fulfilled": (state, action) => {
      const response = action.payload;
      const sceneId = response?.sceneId;
      const groups = response?.items ? response.items : [];
      if (sceneId) {
        state.sceneIdOfFetchedElementTypeGroups = sceneId;
        state.elementTypesCount = getElementTypesCountFromGroups(groups);
      }
    },
  },
});

export const {
  // save
  triggerSave,
  // init
  clearSceneData,
  //
  setOneFirstScopeWasOpened,
  setLastOpenedScopeIsOutOfDateTestWasDone,
  // measDataManager
  setModelIdsInMeasDataManager,
  addModelIdToMeasDataManager,
  // main scope
  setMainScopeWithOneModel,
  setPackageIdsInMainScope,
  setEnabledPackageIdsInMainScope,
  setHelperPackageIdsInMainScope,
  setScopeAndCounter,
  setMeasCountPackageIdMap,
  // initial loading
  setSceneIdClickedFromScenes,
  setLoadedSceneId,
  setModelIdBeingLoadedInViewer3d,
  setOpenDialogFsFetchSceneData,
  setOpenDialogFsSelectScope,
  setOpenDialogFsOpenScope,
  setOpenDialogFsLoadingScope,
  setOpenDialogFsInvalidBimbox,
  setScopeIdToOpen,
  lastOpenedScopeId,
  openScope,
  updateStatusMapWith,
  // load in state
  updateLoadingRatioByScopeIdMap,
  // load
  setLoadModelsTriggeredAt,
  triggerNewLoadingOrder,
  endLoadingOrder,
} = overviewerSlice.actions;

export default overviewerSlice.reducer;
