import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";

import ListItemElement from "./ListItemElement";
import ListItemTypeCode from "./ListItemTypeCode";

import {
  hideCaplaElements,
  showCaplaElements,
} from "Features/viewer3D/viewer3DSlice";

export default function ShortcutPdfElements({editorPdf, scene}) {
  const dispatch = useDispatch();

  // strings

  const multiselectString = "Multiple selection";
  const multiselectTypesString = "Multiple selection (types)";

  const showAllString = "Show all";
  const hideAllString = "Hide all";

  // data

  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const pdfCurrentPage = useSelector((s) => s.viewer3D.pdfCurrentPage);

  const allModels = useSelector((s) => s.viewer3D.models);
  const model = allModels.find((m) => m.id === selectedPdfModelId);

  const hiddenElements = useSelector((s) => s.viewer3D.hiddenCaplaElements);

  console.log("model312", model);

  // state

  const [checked, setChecked] = useState(false);
  const [checkedTypes, setCheckedTypes] = useState(false);
  const [selectedTypeCodes, setSelectedTypeCodes] = useState([]);

  // helpers - capla models - types

  const caplaModels = allModels.filter(
    (m) => m.type === "CAPLA" && m.sceneClientId === scene?.clientId
  );
  const types = [];
  caplaModels.forEach((model) => {
    const ts = model.elements.types;
    types.push(...ts);
  });
  console.log("types 23", types);

  // helpers

  let annotations = [];
  let elementsAnnotations = model?.elementsAnnotations;
  if (!elementsAnnotations) {
    elementsAnnotations = [];
  } else {
    elementsAnnotations = Object.entries(elementsAnnotations).forEach(
      ([key, value]) => {
        const newValue = value.map((v) => ({...v, caplaModelId: key}));
        annotations.push(...newValue);
      }
    );
  }
  let elements = [];
  annotations.forEach((annotation) => {
    const element = elements.find(
      (e) =>
        annotation.caplaModelId === e.caplaModelId &&
        annotation.codeName === e.codeName
    );
    if (element) {
      elements = elements.filter(
        (e) =>
          e.caplaModelId !== element.caplaModelId ||
          e.codeName !== element.codeName
      );
      elements = [
        ...elements,
        {
          ...element,
          annotationsIds: [...element.annotationsIds, annotation.id],
        },
      ];
    } else {
      const type = types.find((t) => t.code === annotation.typeCode);
      elements = [
        ...elements,
        {
          pageNumber: annotation.pageNumber,
          codeName: annotation.codeName,
          typeCode: annotation.typeCode,
          caplaModelId: annotation.caplaModelId,
          annotationsIds: [annotation.id],
          color: type?.color,
        },
      ];
    }
  });

  console.log("elementsBEFORE", elements);

  elements = elements.filter((e) => e.pageNumber === pdfCurrentPage);

  console.log("elements 12", elements);

  // helpers - type codes

  const typeCodes = [...new Set(elements.map((e) => e.typeCode))];

  // helpers - display elements

  let showedElements = elements;
  if (selectedTypeCodes.length > 0 || checkedTypes) {
    showedElements = elements.filter((e) =>
      selectedTypeCodes.includes(e.typeCode)
    );
  }

  // helpers - function

  function showElementAnnotations(element) {
    editorPdf.annotationsManager.showElementsAnnotations(
      element.annotationsIds
    );
    editorPdf.annotationsManager.showElementLabel({
      codeName: element.codeName,
      caplaModelId: element.caplaModelId,
    });
    dispatch(
      showCaplaElements([
        {codeName: element.codeName, caplaModelId: element.caplaModelId},
      ])
    );
  }

  function hideElementAnnotations(element) {
    editorPdf.annotationsManager.hideElementsAnnotations(
      element.annotationsIds
    );
    editorPdf.annotationsManager.hideElementLabel({
      codeName: element.codeName,
      caplaModelId: element.caplaModelId,
    });
    dispatch(
      hideCaplaElements([
        {codeName: element.codeName, caplaModelId: element.caplaModelId},
      ])
    );
  }

  // handlers - element

  function handleElementClick(element) {
    const elementIsHidden = hiddenElements
      .map((e) => e.caplaModelId + e.codeName)
      .includes(element.caplaModelId + element.codeName);
    if (checked) {
      if (elementIsHidden) {
        showElementAnnotations(element);
      } else {
        hideElementAnnotations(element);
      }
    } else {
      const toHideElements = elements.filter(
        (e) =>
          e.caplaModelId !== element.caplaModelId ||
          e.codeName !== element.codeName
      );
      toHideElements.forEach((element) => hideElementAnnotations(element));
      showElementAnnotations(element);
    }
  }

  // handlers - type code

  function handleTypeCodeClick(typeCode) {
    if (!checkedTypes) {
      if (selectedTypeCodes.includes(typeCode)) {
        setSelectedTypeCodes([]);
        elements.forEach((element) => showElementAnnotations(element));
      } else {
        setSelectedTypeCodes([typeCode]);
        elements
          .filter((e) => e.typeCode === typeCode)
          .forEach((e) => showElementAnnotations(e));
        elements
          .filter((e) => e.typeCode !== typeCode)
          .forEach((e) => hideElementAnnotations(e));
      }
    } else {
      let newSelectedTypeCodes;
      if (selectedTypeCodes.includes(typeCode)) {
        newSelectedTypeCodes = selectedTypeCodes.filter((c) => c !== typeCode);
      } else {
        newSelectedTypeCodes = [...selectedTypeCodes, typeCode];
      }
      setSelectedTypeCodes(newSelectedTypeCodes);
      elements
        .filter((e) => newSelectedTypeCodes.includes(e.typeCode))
        .forEach((e) => showElementAnnotations(e));
      elements
        .filter((e) => !newSelectedTypeCodes.includes(e.typeCode))
        .forEach((e) => hideElementAnnotations(e));
    }
  }

  // handlers - multiple select

  function handleCheckChange(e) {
    const checked = e.target.checked;
    setChecked(checked);
  }
  function handleCheckTypesChange(e) {
    const checked = e.target.checked;
    setCheckedTypes(checked);
  }

  // handlers - hide all

  function handleHideAll() {
    showedElements.forEach((element) => {
      hideElementAnnotations(element);
    });
  }
  function handleShowAll() {
    showedElements.forEach((element) => {
      showElementAnnotations(element);
    });
  }

  return (
    <Box>
      <Box sx={{display: "flex"}}>
        <Box sx={{mr: 2, "&>*:not(:last-child)": {mb: 1}}}>
          {typeCodes.map((typeCode) => {
            let selected = selectedTypeCodes.includes(typeCode);
            if (!checkedTypes && selectedTypeCodes.length === 0)
              selected = true;
            const color = types.find((t) => t.code === typeCode)?.color;
            return (
              <ListItemTypeCode
                key={typeCode}
                typeCode={typeCode}
                onClick={handleTypeCodeClick}
                selected={selected}
                color={color}
              />
            );
          })}
        </Box>
        <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
          {showedElements.map((element) => {
            const selected = !hiddenElements
              .map((e) => e.caplaModelId + e.codeName)
              .includes(element.caplaModelId + element.codeName);
            return (
              <ListItemElement
                key={element.codeName}
                element={element}
                onClick={handleElementClick}
                selected={selected}
              />
            );
          })}
        </Box>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", mt: 2}}>
        <FormControlLabel
          sx={{mb: 1}}
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckChange}
              size="small"
              sx={{width: "12px", height: "12px", mr: 1}}
            />
          }
          label={
            <Typography sx={{fontSize: 12}} variant="body2">
              {multiselectString}
            </Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={checkedTypes}
              onChange={handleCheckTypesChange}
              size="small"
              sx={{width: "12px", height: "12px", mr: 1}}
            />
          }
          label={
            <Typography sx={{fontSize: 12}} variant="body2">
              {multiselectTypesString}
            </Typography>
          }
        />
      </Box>

      <Box sx={{display: "flex"}}>
        <Button size="small" onClick={handleHideAll}>
          {hideAllString}
        </Button>
        <Button size="small" onClick={handleShowAll}>
          {showAllString}
        </Button>
      </Box>
    </Box>
  );
}
