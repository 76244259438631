import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {createRowService, fetchRowsService} from "./services";

export const createRow = createAsyncThunk("rows/createRow", createRowService);

export const fetchRows = createAsyncThunk("rows/fetchRows", fetchRowsService);

const rowsSlice = createSlice({
  name: "rows",
  initialState: {datasets: {}},
  extraReducers: {
    [fetchRows.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId] = {data};
      state.datasets[listingId].status = "succeed";
    },
    [createRow.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId].data.push(data);
    },
  },
});

export default rowsSlice.reducer;
