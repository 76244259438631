import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import {useSelector} from "react-redux";

export default function useInputsByScene(scene, options) {
  //
  const filterByScope = options?.filterByScope;
  const sortByName = options?.sortByName;
  //
  const allModels = useSelector((s) => s.viewer3D.models);
  const scope = useSelectedScope();
  //
  let filteredModels = allModels.filter(
    (m) =>
      m.sceneClientId === scene?.clientId &&
      ["IFC", "PDF", "GLB"].includes(m.type)
  );
  //
  if (filterByScope)
    filteredModels = filteredModels.filter(
      (m) => !scope?.data?.inputIds || scope?.data?.inputIds?.includes(m.id)
    );
  if (sortByName)
    filteredModels = filteredModels.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  //
  return filteredModels;
}
