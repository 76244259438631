import {Paper} from "@mui/material";

import ToggleProgressView3DMode from "./ToggleProgressView3DMode";

export default function ToolbarProgressBottomRightViewer3D({editor3d}) {
  return (
    <Paper
      elevation={12}
      sx={{
        bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      <ToggleProgressView3DMode editor3d={editor3d} />
    </Paper>
  );
}
