import cleanMeasurementPaths from "Features/measurements/utils/cleanMeasurementPaths";
import computeMeasurementRessourcesQuantitiesV2 from "Features/quantities/computeMeasurementRessourcesQuantitiesV2";
import computeMeasurementRessourcesQuantities from "Features/quantities/computeMeasurementRessourcesQuantities";

export default function getUpdatedMeasurementsFromTypes({
  thePackage,
  elementTypesById,
  ressourcesById,
  caplaEditor,
  zonesById,
  roomsById,
  sectorsById,
  filterNoVoids,
}) {
  const usedTypes = new Set();
  let packageMeasurements = thePackage.measurementsData?.measurements?.map(
    (m) => {
      let newM;
      const type = elementTypesById[m.elementTypeId];
      const res = type?.res
        ? type.res.map(({resId}) => ({resId, off: false}))
        : [];
      if (res) {
        newM = {...m, res};
      } else {
        newM = {...m};
      }
      // log some warnings
      if (!type) console.log("WARNING no type associated to", m);
      else if (!type.unit) console.log("WARNING no unit for type", type);
      if (type) usedTypes.add(type);
      // fix isVoid and dotted
      if (typeof type?.isVoid !== "boolean") newM.isVoid = null;
      if (typeof type?.dotted !== "boolean") newM.dotted = null;
      // if no unit take unit from type
      if (!newM.unit && type?.unit) newM.unit = type.unit;
      // clean path (decimal places <=4 )
      newM = cleanMeasurementPaths(newM);
      // fix offset/zInf/zSup issues
      const zone = zonesById[m.zoneId];
      const height = newM.height ? newM.height : 0;
      const zoneY = zone?.position?.y ? zone.position.y : 0;
      if (!newM.zFrom || newM.zFrom === "offset") {
        if (!newM.offset) newM.offset = 0;
        if (zone?.zBasis === "ZSUP") {
          newM.zSup = newM.offset + zoneY;
          newM.zInf = newM.zSup - height;
        } else {
          newM.zInf = newM.offset + zoneY;
          newM.zSup = newM.zInf + height;
        }
        newM.zFrom = "offset";
      } else if (newM.zFrom === "zInf") {
        newM.offset = 0;
        newM.zSup = newM.zInf + height;
      } else if (newM.zFrom === "zSup") {
        newM.offset = 0;
        newM.zInf = newM.zSup - height;
      }
      caplaEditor?.editor3d?.sceneEditor.updateMeasurement3D(newM);
      // recalculate quantities
      const quantities = caplaEditor?.getMeasurementQuantities(newM);
      newM.length = quantities.length;
      newM.area = quantities.area;
      newM.volume = quantities.volume;
      if (quantities.lengthP) newM.lengthP = quantities.lengthP;
      if (quantities.areaP) newM.areaP = quantities.areaP;
      // force count to 1
      newM.count = 1;
      return newM;
    }
  );
  if (!packageMeasurements) packageMeasurements = [];
  else {
    packageMeasurements = packageMeasurements.map((measurement) => {
      const newM = {...measurement};
      const newRes = computeMeasurementRessourcesQuantities({
        measurement,
        elementTypesById,
        ressourcesById,
        zonesById,
        roomsById,
        sectorsById,
        filterNoVoids,
      });
      if (newRes) newM.res = newRes;
      return newM;
    });
  }
  return [packageMeasurements, usedTypes];
}
