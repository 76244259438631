import { useSelector } from "react-redux";

import {Box, Typography, Grid} from "@mui/material";

import useRessourcesByScene from "../hooks/useRessourcesByScene";
import useRessourceGroupsByScene from "../hooks/useRessourceGroupsByScene";
import computeRessourceQuantity from "../utils/computeRessourceQuantity";
import sortByNum from "../utils/sortByNum";

export default function BoxItemRessources({
  ressources = [],
  scene,
  item,
  sizeKey,
}) {
  // strings

  const noArticleS = "0 article";
  const excludeFromQtiesS = "Exclu des quantités";

  // data

  const allRessources = useRessourcesByScene(scene);
  const ressourceGroups = useRessourceGroupsByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // helpers
  
  const dontUseForQuantities = new Set(models
    .filter((m) => m.excludeFromQties)
    .map((m) => m.id)
  );

  const ressourcesById = {};
  for (const item of allRessources) {
    ressourcesById[item.id] = item;
  }

  let mrs, mrsByGroup;
  const elementType = item.elementType;
  const noArticle = ressources?.length === 0;
  const excludedFromQties = dontUseForQuantities.has(item.measurementsModelId);

  if (!noArticle && !excludedFromQties) {
    // helpers - mrs (measurement ressources)
    mrs = ressources
      .map(({resId}) => {
        const ressource = ressourcesById[resId];
        const res = elementType?.res ?
          elementType.res.find((r) => r.resId === resId) : [];
        return {resId, ressource, res};
      })
      .filter((item) => item.ressource)
      .sort((a, b) => sortByNum(a.ressource.num, b.ressource.num));
    // helpers - mrs by group (to display ressources by group)
    mrsByGroup = [];
    ressourceGroups.forEach((group) => {
      if (group) {
        mrsByGroup.push({
          group,
          mrs: mrs.filter(({ressource}) => ressource.group === group),
        });
      }
    });
  }

  return (
    <Box sx={{width: 1}}>
      {noArticle && (
        <Box sx={{display: "flex", justifyContent: "center", py: 0.25}}>
          <Typography sx={{fontSize: 13}} color="text.secondary">
            {noArticleS}
          </Typography>
        </Box>
      )}
      {excludedFromQties && (
        <Box sx={{display: "flex", justifyContent: "center", py: 0.25}}>
          <Typography sx={{fontSize: 13}} color="text.secondary">
            {excludeFromQtiesS}
          </Typography>
        </Box>
      )}
      {!noArticle && !excludedFromQties && (
        <Box sx={{width: 1}}>
          {mrsByGroup.map((groupMrs, idx) => {
            const noArticle = groupMrs.mrs.length === 0;
            return noArticle ? (
              <Box key={idx} />
            ) : (
              <Box key={idx} >
                <Box
                  sx={{
                    width: 1,
                    px: 1,
                    bgcolor: "common.caplaBlack",
                    color: "common.white",
                  }}
                >
                  <Typography sx={{fontSize: 13}}>{groupMrs.group}</Typography>
                </Box>
                {noArticle && (
                  <Box
                    sx={{display: "flex", justifyContent: "center", py: 0.25}}
                  >
                    <Typography sx={{fontSize: 13}} color="text.secondary">
                      {noArticleS}
                    </Typography>
                  </Box>
                )}
                {groupMrs.mrs.map(({resId, ressource, res}) => {
                  // const mres = item.res?.find((r) => r.resId === resId);
                  // let quantity = filterNoVoids ? mres.qtyRaw : mres.qtyNet;
                  // if (!quantity)
                  const quantity = computeRessourceQuantity({
                    item,
                    res,
                    ressource,
                    withDecimal: true,
                    filterNoVoids
                  });
                  const mode = res?.mode;
                  let formula = "";
                  if (mode === "AUTO") formula = `x ${item.unit}`;
                  if (mode === "RATIO")
                    formula = `[ratio] ${ressource.ratioV} / ${ressource.ratioU}`;
                  if (mode === "FORMUL") formula = res?.func;

                  return (
                    <Box key={resId}>
                      <Grid
                        container
                        sx={{
                          px: 1,
                          py: 0.25,
                          width: 1,
                          minWidth: 1,
                          borderBottom: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                        }}
                      >
                        <Grid xs={sizeKey} item key={resId + "name"}>
                          <Box
                            sx={{
                              display: "flex",
                              height: 1,
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{fontSize: 13}} noWrap>
                              {`${ressource?.name} (${ressource?.unit}) `}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          xs={12 - sizeKey}
                          item
                          key={resId + "quantity"}
                          sx={{
                            borderLeft: (theme) =>
                              `1px solid ${theme.palette.divider}`,
                            px: 1,
                          }}
                        >
                          <Typography sx={{fontSize: 13}} noWrap>
                            {quantity}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          width: 1,
                          px: 1,
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Typography
                          sx={{fontSize: 13, color: "text.secondary"}}
                        >
                          {formula}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
