import getArticlesUpdatesFromElementTypesGroups from "../utils/getArticlesUpdatesFromElementTypesGroups";
import getTempArticleWithCoreTypesAsTypes from "./getTempArticleWithCoreTypesAsTypes";
import mergeArticlesBatchUpdates from "../utils/mergeArticlesBatchUpdates";
import resetArticlesTypes from "./resetArticlesTypes";

export default function getUpdatesToRefreshQties(
  articles,
  elementTypesGroupsWithSortedItems
) {
  // to change the qty, we just need to change the types of the articles.
  // we'll keep the change in default updates.

  // STEP 1 : reset Types

  const [newArticles, batchUpdates1] = resetArticlesTypes(articles);

  // STEP 2 : start with new Types as core Types

  const articlesWithCoreTypes = newArticles.map((article) =>
    getTempArticleWithCoreTypesAsTypes(article)
  );

  const batchUpdates2 = getArticlesUpdatesFromElementTypesGroups(
    articlesWithCoreTypes,
    elementTypesGroupsWithSortedItems
  );

  return mergeArticlesBatchUpdates(batchUpdates1, batchUpdates2);
}
