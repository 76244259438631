import {useDispatch} from "react-redux";
import {fetchViews} from "../viewsSlice";
import {useEffect} from "react";

import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchViews(sceneId) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sceneId && accessToken) {
      dispatch(fetchViews({accessToken, sceneId}));
    }
  }, [sceneId, accessToken]);
}
