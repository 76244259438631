import {MathUtils} from "three";

export default class ZipModel {
  constructor({model}) {
    this.type = "ZIP_MODEL";
    this.entityID = model.id;
    this.model = model;
  }

  static async computeInitialModel({file, sceneClientId}) {
    const id = MathUtils.generateUUID(); // create promise
    const type = "ZIP";
    const url = URL.createObjectURL(file);
    const name = file.name;
    const fileSize = file.size;
    const fileName = file.name;

    return {id, type, url, name, fileSize, fileName, sceneClientId};
  }
}
