import {Box} from "@mui/material";

import BoxItemRessources from "./BoxItemRessources";

import FieldSection from "Features/kvform/components/FieldSection";

export default function FieldItemRessources({
  scene,
  item,
  value,
}) {
  // strings

  const ressourcesS = "Articles";

  // const

  const sizeKey = 8;

  return (
    <Box>
      <FieldSection
        title={ressourcesS}
        readOnly={true}
      />
      <BoxItemRessources
        ressources={value ? value : []}
        item={item}
        scene={scene}
        sizeKey={sizeKey}
      />
    </Box>
  );
}
