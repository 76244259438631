import {useDispatch} from "react-redux";
import { nanoid } from "@reduxjs/toolkit";

import {updateScene} from "Features/scenes/scenesSlice";
import { setRelationsTypesRessources } from "Features/relations/relationsSlice";

export default function useCopySceneRessourcesGroup(scene) {
  const dispatch = useDispatch();
  if (!scene) return;

  const copySceneRessourcesGroup = (
    oldRessourcesGroup,
    newRessourcesGroup,
    relations,
    forDemo,
  ) => {
    let oldRessources = scene?.data?.ressources;
    if (!oldRessources) oldRessources = [];
    const editedRelations = {...relations};
    const newRessources = [];
    oldRessources.filter((r) => r.group === oldRessourcesGroup).forEach((r) => {
      const newId = nanoid();
      const itemRelationKeys = Object.keys(relations).filter(
        (k) => k.endsWith(r.id)
      );
      itemRelationKeys.forEach((k) => {
        const oldRelation = relations[k];
        const relId =  k.replace(r.id, newId);
        const newRel = {
            ...oldRelation,
            id: relId,
            resId: newId,
        };
        if (forDemo) {
          newRel.mode = "FORMUL";
          newRel.func = "C";
        }
        editedRelations[relId] = newRel;
      })
      const newRessource = {...r, id: newId, group: newRessourcesGroup};
      if (r.types) {
        newRessources.types = r.types.map((t) => ({...t, resId: newId}));
      }
      newRessources.push(newRessource);
    })
    dispatch(setRelationsTypesRessources(editedRelations));
    const newScene = {
      ...scene,
      data: {...scene?.data, ressources: [...oldRessources, ...newRessources]},
    };
    dispatch(updateScene({scene: newScene}));
    return editedRelations;
  };

  return copySceneRessourcesGroup;
}