import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionInfo from "./SectionInfo";
import FieldZoneName from "./FieldZoneName";
import FieldZoneSector from "./FieldZoneSector";
import FieldZoneRoom from "./FieldZoneRoom";
import FieldZoneScale from "./FieldZoneScale";
import FieldZoneSlope from "./FieldZoneSlope";
import FieldZoneImageSize from "./FieldZoneImageSize";
import FieldZoneNorth from "./FieldZoneNorth";
import FieldZoneHeight from "./FieldZoneHeight";
import SectionTransformation from "./SectionTransformation";
import SectionZoneImage from "./SectionZoneImage";
import SectionElements from "./SectionElements";
import FieldZoneZBasis from "./FieldZoneZBasis";
// import FieldZoneElementsH from "./FieldZoneElementsH";
import FieldZoneD3 from "./FieldZoneD3";
import CardZoneSelectionDetail from "./CardZoneSelectionDetail";
import {setSelectedFormGroupCode} from "../zonesSlice";

import {setEditedZone} from "Features/zones/zonesSlice";
import InputZoneMarkers from "Features/pdf/components/InputZoneMarkers";
import ToggleFormGroups from "Features/kvform/components/ToggleFormGroups";

export default function FormZone({scene, caplaEditor, zone}) {
  const dispatch = useDispatch();

  // strings

  const propsS = "Props";
  const threeDS = "3D";
  const imageS = "Image";

  // data

  const selectedFormGroupCode = useSelector(
    (s) => s.zones.selectedFormGroupCode
  );

  // helpers - groups

  const groups = [
    {code: "PROPS", label: propsS},
    {code: "IMAGE", label: imageS},
    {code: "3D", label: threeDS},
  ];

  // helper - group

  const group = selectedFormGroupCode;

  // handlers

  function handleGroupChange(group) {
    dispatch(setSelectedFormGroupCode(group));
  }

  function handleZoneChange(newZone) {
    dispatch(setEditedZone(newZone));
  }
  return (
    <Box sx={{width: 1}}>
      <Box sx={{width: 1, display: "flex", justifyContent: "center", py: 2}}>
        <ToggleFormGroups
          groups={groups}
          group={group}
          onGroupChange={handleGroupChange}
        />
      </Box>

      {group === "PROPS" && (
        <Box>
          <SectionInfo />
          <FieldZoneName
            zone={zone}
            onZoneChange={handleZoneChange}
          />
          <FieldZoneSector
            zone={zone}
            scene={scene}
            onZoneChange={handleZoneChange}
          />
          <FieldZoneRoom
            zone={zone}
            scene={scene}
            onZoneChange={handleZoneChange}
          />
          <FieldZoneScale
            zone={zone}
            onZoneChange={handleZoneChange}
          />
          <SectionElements />
          <FieldZoneZBasis
            zone={zone}
            onZoneChange={handleZoneChange}
          />
          {/* <FieldZoneElementsH zone={zone} caplaEditor={caplaEditor} /> */}
          <FieldZoneD3
            zone={zone}
            onZoneChange={handleZoneChange}
          />
        </Box>
      )}

      {group === "IMAGE" && (
        <Box>
          <SectionZoneImage zone={zone} caplaEditor={caplaEditor} />
          <FieldZoneImageSize zone={zone} />
        </Box>
      )}

      {group === "3D" && (
        <Box>
          <SectionTransformation zone={zone} editorPdf={caplaEditor?.editorPdf} />
          <FieldZoneSlope zone={zone} editorPdf={caplaEditor?.editorPdf} />
          <FieldZoneNorth zone={zone} editorPdf={caplaEditor?.editorPdf} />
          <FieldZoneHeight
            zone={zone}
            onZoneChange={handleZoneChange}
          />
          <InputZoneMarkers zone={zone} caplaEditor={caplaEditor} scene={scene} />

          <Box sx={{p: 2, width: 1}}>
            <Box sx={{p: 1, bgcolor: "background.default"}}>
              <CardZoneSelectionDetail zone={zone} editor3d={caplaEditor?.editor3d} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
