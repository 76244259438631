import gsap from "gsap";
import {Vector3} from "three";

import {getRotationPath} from "Features/viewer3D/utils/geometryUtils";

export default class Animator {
  constructor() {
    this.transformer = gsap;
  }

  // /!\ the object material is not returned correctly
  fade({object, duration = 1, delay = 0, onComplete = () => ""}) {
    const opacity = 0;
    gsap.to(object.material, {duration, delay, opacity, onComplete});
  }

  reveal({object, duration = 1, delay = 0, onComplete = () => ""}) {
    const opacity = 0.8;
    gsap.to(object.material, {duration, delay, opacity, onComplete});
  }

  move(vector, transform, duration = 1, delay = 0) {
    const x = transform.x;
    const y = transform.y;
    const z = transform.z;
    gsap.to(vector, {duration, delay, x, y, z});
  }
  rotate(vector, transform, duration = 1, delay = 0) {
    const x = transform.x;
    const y = transform.y;
    const z = transform.z;
    gsap.to(vector, {duration, delay, x, y, z});
  }
  animate(vector, transforms, duration = 2, delay = 0) {
    const tl = gsap.timeline();
    for (const transform of transforms) {
      const x = transform.p[0];
      const y = transform.p[1];
      const z = transform.p[2];
      const duration = transform.delay;
      tl.to(vector, {duration, delay, x, y, z});
    }
  }
  moveAlongPath(vector, path, duration = 2, delay = 0) {
    const tl = gsap.timeline();
    for (const point of path) {
      const x = point.x;
      const y = point.y;
      const z = point.z;
      tl.to(vector, {
        duration: duration / path.length,
        delay,
        x,
        y,
        z,
        ease: "none",
      });
    }
  }
  play(animation, editor) {
    switch (animation.type) {
      case "CAMERA_ROTATION":
        const center = animation.target;
        const start = animation.camera;
        const duration = animation.duration ? animation.duration : 2;

        editor.cameras.setActiveCameraTo({
          name: "CAMERA",
          position: start,
          target: center,
          controls: editor.controls.orbitControls,
        });

        const path = getRotationPath(center, start, 60);
        const vector = editor.cameras.activeCamera.position;
        this.moveAlongPath(vector, path, duration);
        break;

      case "LOAD_MODEL":
        console.log("load model", animation.modelId);
        break;
      case "LINK":
        console.log("go to", animation.url);
        break;
    }
  }
}
