import React from "react";

import {useSelector} from "react-redux";

import {
  Box,
  List,
  ListItem,
  Card,
  CircularProgress,
  ListItemIcon,
  Typography,
  alpha,
} from "@mui/material";
import {RadioButtonUnchecked, CheckCircle} from "@mui/icons-material";
import useTranslation from "Features/translations/useTranslation";
export default function CardModelsLoader({sceneClientId}) {
  const {t} = useTranslation("viewer3D");

  // strings

  const loadingString = t("models.loading");

  // icons

  const icons = {
    loading: <CircularProgress size={16} />,
    idle: <RadioButtonUnchecked fontSize="small" color="inherit" />,
    loaded: <CheckCircle fontSize="small" color="success" />,
  };
  // data

  const status = useSelector((state) => state.viewer3D.modelsStatus);
  const allModels = useSelector((state) => state.viewer3D.models);
  const models = allModels.filter(
    (model) => model.sceneClientId === sceneClientId
  );
  const sceneModels = models.map((model) => {
    const newModel = {...model};
    const modelStatus = status[sceneClientId]
      ? status[sceneClientId][model.id]
      : "idle";
    newModel.status = modelStatus;
    return newModel;
  });

  return (
    <Card
      sx={{
        color: "common.white",
        backgroundColor: (theme) => alpha(theme.palette.common.caplaBlack, 0.8),
      }}
    >
      <Typography variant="body2" noWrap sx={{p: 0.5}}>
        {loadingString}
      </Typography>
      <List dense color="inherit">
        {sceneModels.map(({id, name, status}) => {
          const icon = icons[status];
          return (
            <ListItem key={id} color="inherit">
              <ListItemIcon sx={{color: "common.white"}}>{icon}</ListItemIcon>
              <Typography noWrap variant="body2">
                {name}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
}
