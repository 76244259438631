import {Box} from "@mui/material";

import SceneBasicHeader from "./components/SceneBasicHeader";
import SectionPictures from "./components/SectionPictures";

export default function ScenePicturesEditor({scene, caplaEditor, listing}) {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SceneBasicHeader scene={scene} />
      <SectionPictures scene={scene} caplaEditor={caplaEditor} listing={listing} />
    </Box>
  );
}
