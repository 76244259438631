import {Box} from "@mui/material";

import SceneNotesEditor from "Features/notes/components/SceneNotesEditor";
import ScenePicturesEditor from "Features/pictures/components/ScenePicturesEditor";

export default function SectionListingDetail({caplaEditor, scene, viewer, listing}) {
  // helpers

  const isNotes = listing?.listingType === "noteset";
  const isPictures = listing?.listingType === "pictureset";

  return (
    <Box sx={{height: 1}}>
      {isNotes && (
        <SceneNotesEditor caplaEditor={caplaEditor} scene={scene} viewer={viewer} />
      )}
      {isPictures && (
        <ScenePicturesEditor
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
          listing={listing}
        />
      )}
    </Box>
  );
}
