export default function getModelIdsInScope(scope) {
  const ids1 = scope?.data?.packageIds;
  const ids2 = scope?.data?.inputIds;
  const ids3 = scope?.data?.zoneIds;
  const ids4 = scope?.data?.markerIds;

  const ids = [];
  if (ids1) ids.push(...ids1);
  if (ids2) ids.push(...ids2);
  if (ids3) ids.push(...ids3);
  if (ids4) ids.push(...ids4);

  return ids;
}
