import {parse} from "date-fns";
import parseElementTypeArticleQties from "./parseElementTypeArticleQties";

function articleQtiesArrayToMap(articleQtiesArray) {
  let map = {}; // {articleCode:func,...}

  articleQtiesArray.forEach(({articleCode, func}) => {
    map[articleCode] = func;
  });
}

function mergeOneArticleQtyToArticlesQtiesArray(articleQty, articleQtiesArray) {
  let newQties = [...articleQtiesArray];
  const articleQtiesMap = articleQtiesArrayToMap(articleQtiesArray);
  const exist =
    typeof articleQtiesMap === "object" &&
    articleQtiesMap[articleQty?.articleCode];
  if (!exist) {
    newQties.push(articleQty);
  } else {
    newQties.filter((item) => item.articleCode !== articleQty.articleCode);
    newQties.push(articleQty);
  }
  return newQties;
}

function stringifyArticleQtiesArray(articleQtiesArray) {
  return articleQtiesArray
    .map(({articleCode, func}) => {
      return `${articleCode}=${func}`;
    })
    .join("--");
}

export default function mergeArticleQties(oldArticlesQties, newArticlesQties) {
  //
  let result;

  if (!oldArticlesQties || oldArticlesQties.length === 0) {
    result = newArticlesQties;
  } else if (!newArticlesQties || newArticlesQties.length === 0) {
    result = oldArticlesQties;
  } else if (oldArticlesQties === newArticlesQties) {
    result = oldArticlesQties;
  } else {
    //
    const oldArray = parseElementTypeArticleQties(oldArticlesQties);
    const newArray = parseElementTypeArticleQties(newArticlesQties);

    let mergedArray = [...oldArray];
    newArray.forEach((item) => {
      mergedArray = mergeOneArticleQtyToArticlesQtiesArray(item, mergedArray);
    });

    result = stringifyArticleQtiesArray(mergedArray);
  }
  return result;
}
