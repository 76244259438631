import {Button, Box} from "@mui/material";
import {Add} from "@mui/icons-material";

import elementTypesDefault from "Features/elementTypes/data/elementTypesDefault";
import useUpdateSceneWithElementTypes from "Features/elementTypes/hooks/useUpdateSceneWithElementTypes";

export default function ButtonAddDefaultElementTypes({scene, onAddDefault}) {
  const addS = "Liste générique";

  // data

  const updateSceneWithElementTypes = useUpdateSceneWithElementTypes(scene);
  //

  function handleClick() {
    updateSceneWithElementTypes(elementTypesDefault);
    const el0 = elementTypesDefault[0];
    const groupProxy = {id: el0.group, name: el0.group};
    onAddDefault(groupProxy);
  }

  return (
    <Box sx={{color: "text.secondary"}}>
      <Button
        color="inherit"
        startIcon={<Add />}
        size="small"
        onClick={handleClick}
      >
        {addS}
      </Button>
    </Box>
  );
}
