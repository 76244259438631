import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import ListItemDifference from "./ListItemDifference";

export default function ListDifference({diffs, onClose, editor3d}) {
  return (
    <Box sx={{width: 1}}>
      <Box sx={{display: "flex"}}>
        <IconButton size="small" color="primary" onClick={() => onClose()}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
      {diffs.map((diff, index) => (
        <Box sx={{mb: 1}} key={index}>
          <ListItemDifference diff={diff} editor3d={editor3d} />
        </Box>
      ))}
    </Box>
  );
}
