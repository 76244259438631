import {useState, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import useSelectedArticle from "../hooks/useSelectedArticle";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useGetFilteredMeasurements from "Features/measurements/useGetFilteredMeasurements";

import useUpdateArticle from "../hooks/useUpdateArticle";

import {updateArticleTemp} from "../articlesSlice";
import {setSelectedElementTypesGroupProxy} from "Features/elementTypes/elementTypesSlice";

import {Box, Typography} from "@mui/material";

import ListElementTypesMultipleWithGroups from "Features/elementTypes/components/ListElementTypesMultipleWithGroups";
import SectionArticleTypesFormulas from "./SectionArticleTypesFormulas";
import SectionSelectedArticleOverview from "./SectionSelectedArticleOverview";
import getArticleTypesCount from "../utils/getArticleTypesCount";
import removeItemFromArticleTypes from "../utils/removeItemFromArticleTypes";
import updateArticleTypesWithOneItem from "../utils/updateArticleTypesWithOneItem";
import {SignalCellularNoSimOutlined} from "@mui/icons-material";

export default function SectionArticleTypes({scene, options}) {
  const dispatch = useDispatch();

  // options

  const showCoreTypes = options?.showCoreTypes;
  const variant = options?.variant;

  // strings

  const formulaS = "Formules";

  // data

  const selectedArticle = useSelectedArticle();
  const selectedArticlesGroup = useSelectedArticlesGroup();

  let elementTypes = useElementTypesBySceneProxy(scene, {
    sortByNum: true,
    filterByScope: true,
    filterByTypesGroupsIds: selectedArticlesGroup?.typesGroupsIds,
  });

  const groupsProxy = useElementTypesGroupsProxyBySceneWithElementTypes(scene, {
    sortByName: true,
    filterByScope: true,
    filterByTypesGroupsIds: selectedArticlesGroup?.typesGroupsIds,
  });

  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  // helpers

  let tempTypes = selectedArticle?.types ?? {};
  if (showCoreTypes) tempTypes = selectedArticle?.coreTypes ?? {};

  const selectedElementTypesIds = Object.keys(
    tempTypes[selectedGroupProxy?.id] ?? []
  );

  // helpers - typeSelectionLabel

  const typesCount = getArticleTypesCount(tempTypes);
  const noTypes = typesCount === 0;
  let typeSelectionLabel = "Sélectionnez les repérages concernés";
  if (!noTypes)
    typeSelectionLabel = `${typesCount} repérage${
      typesCount > 1 ? "s" : ""
    } sélectionné${typesCount > 1 ? "s" : ""}`;

  // handlers

  function handleSelectedElementTypesGroupProxyChange(groupProxy) {
    dispatch(setSelectedElementTypesGroupProxy(groupProxy));
  }

  function handleSelectionChange(ids, options) {
    console.log("SELECTION Change", ids);
    if (options?.fromGroupChange) return;
    const groupId = selectedGroupProxy?.id;
    const newTempTypesOfGroup = {};
    ids.forEach((id) => {
      const oldValue = tempTypes[groupId]?.[id];
      const newFunc = oldValue?.func?.length ? oldValue.func : "C";
      newTempTypesOfGroup[id] = {...oldValue, func: newFunc};
    });
    const newTempTypes = {...tempTypes};
    newTempTypes[groupId] = newTempTypesOfGroup;
    console.log("dispatch newType", newTempTypes);
    //setTempTypes(newTempTypes);
    //
    const updates = {id: selectedArticle.id};
    const field = showCoreTypes ? "coreTypes" : "types";
    updates[field] = newTempTypes;
    //
    dispatch(updateArticleTemp(updates));
  }

  function handleArticleTypeChange(item) {
    console.log("debugAFA109 changeItem", item);
    const newTempTypes = updateArticleTypesWithOneItem(tempTypes, item, {
      updateMode: "replace",
    });
    const updates = {id: selectedArticle.id};
    const field = showCoreTypes ? "coreTypes" : "types";
    updates[field] = newTempTypes;
    dispatch(updateArticleTemp(updates));
  }

  function handleArticleTypeFuncChange(item) {
    const newTempTypes = updateArticleTypesWithOneItem(tempTypes, item, {
      funcOnly: true,
      updateMode: "replace",
    });
    const updates = {id: selectedArticle.id};
    const field = showCoreTypes ? "coreTypes" : "types";
    updates[field] = newTempTypes;
    dispatch(updateArticleTemp(updates));
  }

  function handleArticleTypeFiltersStrChange(item) {
    const newTempTypes = updateArticleTypesWithOneItem(tempTypes, item, {
      filtersStrOnly: true,
      updateMode: "replace",
    });
    const updates = {id: selectedArticle.id};
    const field = showCoreTypes ? "coreTypes" : "types";
    updates[field] = newTempTypes;
    dispatch(updateArticleTemp(updates));
  }

  function handleRemoveArticleType({groupId, typeId}) {
    console.log(
      "handleRemoveArticleTypeAA",
      selectedArticle.types,
      groupId,
      typeId
    );
    let newTempTypes;
    try {
      newTempTypes = removeItemFromArticleTypes({
        groupId,
        typeId,
        articleTypes: tempTypes,
      });
      console.log("handleRemoveArticleTypeAA", newTempTypes);
    } catch (e) {
      console.log("error", e);
    }
    console.log("handleRemoveArticleTypeBB", newTempTypes);
    const updates = {id: selectedArticle.id};
    const field = showCoreTypes ? "coreTypes" : "types";
    updates[field] = newTempTypes;
    dispatch(updateArticleTemp(updates));
  }

  function handleSaveArticle() {}

  return (
    <Box
      sx={{
        width: 1,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <Box></Box>
      <Box
        sx={{
          width: 1,
          flex: 1,
          display: "flex",
          minHeight: 0,
        }}
      >
        {variant === "wide" && (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
            }}
          >
            <Box sx={{p: 1}}>
              <Box
                sx={{
                  bgcolor: "background.default",
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{fontSize: 13}}>
                  {typeSelectionLabel}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: 0,
              }}
            >
              <ListElementTypesMultipleWithGroups
                elementTypes={elementTypes}
                groupsProxy={groupsProxy}
                selectedElementTypeIds={selectedElementTypesIds}
                onSelectionChange={handleSelectionChange}
                multipleSelection={true}
                selectedGroupId={selectedGroupProxy?.id}
                onSelectedGroupProxyChange={
                  handleSelectedElementTypesGroupProxyChange
                }
                maxHeightGroups={180}
                //counters={groupCountMap}
                //showZero={true}
                forceSelection={false} // to setup global scope
                //variant="list"
                enableClickOnTitle={true}
              />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            overflow: "auto",
          }}
        >
          <Box sx={{p: 1}}>
            <Box
              sx={{
                bgcolor: "background.default",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{fontSize: 13}}>{formulaS}</Typography>
            </Box>
          </Box>
          <SectionArticleTypesFormulas
            article={selectedArticle}
            selectedGroupProxy={selectedGroupProxy}
            elementTypes={elementTypes}
            onArticleTypeFuncChange={handleArticleTypeFuncChange}
            onArticleTypeFiltersStrChange={handleArticleTypeFiltersStrChange}
            onArticleTypeItemChange={handleArticleTypeChange}
            onRemoveArticleType={handleRemoveArticleType}
            showCoreTypes={showCoreTypes}
          />
        </Box>
      </Box>
    </Box>
  );
}
