import React, {useState} from "react";

import {
  Box,
  Select,
  FormControl,
  MenuItem,
  Typography,
  InputBase,
  Menu,
  ListItemButton,
  IconButton,
} from "@mui/material";
import {styled} from "@mui/material/styles";

import {ArrowDropDown as Down} from "@mui/icons-material";

export default function SelectorSceneListingBlack({
  listings = [],
  listing,
  onChange,
}) {
  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers - menu

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }
  // handlers - listing item

  function handleListingClick(listing) {
    onChange(listing);
    setAnchorEl(null);
  }

  return (
    <>
      <Box
        sx={{
          width: 150,
          display: "flex",
          justifyContent: "space-between",
          color: "common.white",
          alignItems: "center",
        }}
      >
        <Typography noWrap sx={{fontSize: 13}} color="inherit">
          {listing?.name}
        </Typography>
        <IconButton size="small" onClick={handleMoreClick} color="inherit">
          <Down color="inherit" fontSize="small" />
        </IconButton>
      </Box>

      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {listings.map((listing) => (
          <MenuItem
            key={listing.id}
            onClick={() => handleListingClick(listing)}
          >
            <Typography variant="body2">{listing.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
