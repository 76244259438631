import theme from "Styles/theme";
import applyStyleToCell from "./applyStyleToCell";

export default function applyStyleToRow({row, item, rowType, rowLength}) {
  if (rowType === "ARTICLE") {
    rowLength = 11;
    let genericStyle = {};
    //
    if (item.style === "title1") {
      genericStyle.bg = theme.palette.excel.bgTitle1;
      genericStyle.bold = true;
    } else if (item.style === "title2") {
      genericStyle.bg = theme.palette.excel.bgTitle2;
      genericStyle.bold = true;
    } else if (item.style === "overview") {
      genericStyle.underline = true;
      genericStyle.bold = true;
    } else if (item.style === "element") {
      genericStyle.bold = true;
    } else if (item.style === "section") {
      genericStyle.underline = true;
    } else if (item.style === "detail") {
      genericStyle.alignToRight = true;
    } else if (item.style === "subDetail") {
      genericStyle.alignToRight = true;
      genericStyle.italic = true;
      genericStyle.color = theme.palette.excel.colorSubDetail;
    }
    //
    if (item.isAdded && item.style !== "subDetail") {
      genericStyle.color = theme.palette.excel.colorIsAdded;
    }
    //
    if (item.isNomenclature) {
      genericStyle.underline = true;
      genericStyle.italic = true;
      genericStyle.alignToRight = true;
      genericStyle.color = theme.palette.excel.colorNomenclature;
    }
    //
    for (let i = 1; i <= rowLength; i++) {
      const style = {...genericStyle};
      style.alignToRight = false;
      style.underline = false;
      style.bold = false;
      // id
      if (i === 1) {
        style.color = theme.palette.excel.colorId;
        style.alignToRight = true;
      }
      // name
      if (i === 3 && genericStyle.alignToRight) style.alignToRight = true;
      if (i === 3 && genericStyle.underline) style.underline = true;
      if (i === 3 && genericStyle.bold) style.bold = true;
      if (i === 3 && genericStyle.italic) style.italic = true;

      // code
      if (i === 4) {
        style.color = theme.palette.excel.colorDescription;
        style.center = true;
      }

      // elements
      if (i === 5) {
        style.color = theme.palette.excel.colorDescription;
        style.center = true;
      }

      // description
      if (i === 6) {
        style.italic = true;
        //style.color = theme.palette.excel.colorDescription;
      }

      // unit
      if (i === 7) style.center = true;

      // qty
      if (i === 8 && item.hardcodedQty) style.italic = true;
      if (i === 8 && typeof item?.qty === "number")
        style.bg = theme.palette.excel.bgQty;

      // comment
      if (i === 11) {
        style.color = theme.palette.excel.colorComment;
      }

      applyStyleToCell(row.getCell(i), style);
    }
  } else if (rowType === "ARTICLE_WITH_QTIES") {
    let genericStyle = {};
    //
    if (item.style === "title1") {
      genericStyle.bg = theme.palette.excel.bgTitle1;
      genericStyle.bold = true;
    } else if (item.style === "title2") {
      genericStyle.bg = theme.palette.excel.bgTitle2;
      genericStyle.bold = true;
    } else if (item.style === "overview") {
      genericStyle.underline = true;
      genericStyle.bold = true;
    } else if (item.style === "element") {
      genericStyle.bold = true;
    } else if (item.style === "section") {
      genericStyle.underline = true;
    } else if (item.style === "detail") {
      genericStyle.alignToRight = true;
    } else if (item.style === "subDetail") {
      genericStyle.alignToRight = true;
      genericStyle.italic = true;
      genericStyle.color = theme.palette.excel.colorSubDetail;
    }
    //
    if (item.isAdded && item.style !== "subDetail") {
      genericStyle.color = theme.palette.excel.colorIsAdded;
    }
    //
    if (item.isNomenclature) {
      genericStyle.underline = true;
      genericStyle.italic = true;
      genericStyle.alignToRight = true;
      genericStyle.color = theme.palette.excel.colorNomenclature;
    }
    //
    for (let i = 1; i <= rowLength; i++) {
      const style = {...genericStyle};
      style.alignToRight = false;
      style.underline = false;
      style.bold = false;

      // id
      if (i === 1) {
        style.color = theme.palette.excel.colorId; // id
        style.alignToRight = true;
      }

      // name
      if (i === 3 && genericStyle.alignToRight) style.alignToRight = true;
      if (i === 3 && genericStyle.underline) style.underline = true;
      if (i === 3 && genericStyle.bold) style.bold = true;
      if (i === 3 && genericStyle.italic) style.italic = true;

      // code
      if (i === 4) {
        style.color = theme.palette.excel.colorDescription;
        style.center = true;
      }

      // elements
      if (i === 5) {
        style.color = theme.palette.excel.colorDescription;
        style.center = true;
      }

      // description
      if (i === 6) {
        style.italic = true;
        style.color = theme.palette.excel.colorDescription;
      }

      // unit
      if (i === 7) style.center = true;

      // qty
      if (i === 8) style.alignToRight = true;
      if (item.hardcodedQty && i === 8) style.italic = true;
      if (i === 8 && genericStyle.bold) style.bold = true;
      if (i === 8 && typeof item?.qty === "number")
        style.bg = theme.palette.excel.bgQty;

      // qtyRef
      if (i === 9) style.alignToRight = true;

      // comment
      if (i === 11) {
        style.color = theme.palette.excel.colorComment;
      }

      // qtyBySector
      if (i >= 13) style.alignToRight = true;

      applyStyleToCell(row.getCell(i), style);
    }
  } else if (rowType === "ELEMENT_TYPE") {
    let genericStyle = {};
    //
    if (item.style === "title1") {
      genericStyle.bg = theme.palette.excel.bgTitle1;
      genericStyle.bold = true;
    } else if (item.style === "title2") {
      genericStyle.bg = theme.palette.excel.bgTitle2;
      genericStyle.bold = true;
    } else if (item.style === "detail") {
      genericStyle.color = theme.palette.excel.colorDetail;
      genericStyle.alignToRight = true;
    }
    //
    for (let i = 1; i <= rowLength; i++) {
      const style = {...genericStyle};
      style.alignToRight = false;
      style.underline = false;
      style.bold = false;
      delete style.color;

      // id
      if (i === 1) {
        style.color = theme.palette.excel.colorId; // id
        style.alignToRight = true;
      }
      // name
      if (i === 2) {
        style.bold = genericStyle.bold;
        style.color = genericStyle.color;
        style.alignToRight = genericStyle.alignToRight;
        // no qty
        if (!item.mainQty && item.unit)
          style.color = theme.palette.excel.colorIsAdded;
      }
      // drawing shape
      if (i === 4 && item.drawingShape) {
        style.bg = item.color; // id
        //style.color = "#FFFFFF";
      }

      // unit
      if (i === 5) {
        style.center = true;
      }

      // qties
      if (i === 6) {
        style.alignToRight = true;
      }
      // articleQties
      if (i === 7) {
        style.color = theme.palette.excel.colorDescription;
      }

      // description
      if (i === 8) style.color = theme.palette.excel.colorDescription;

      applyStyleToCell(row.getCell(i), style);
    }
  }
}
