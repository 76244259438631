import {useSelector} from "react-redux";

import {Box} from "@mui/material";

// import ToolbarBottomViewerPdf from "./ToolbarBottomViewerPdf";

// import ToolMeasurement from "Features/viewer3D/components/ToolMeasurement";
import ListContainerIssuesInViewer from "Features/issues/components/ListContainerIssuesInViewer";
// import CardMeasurementsInViewer from "Features/measurements/components/CardMeasurementsInViewer";
// import ButtonToggle3D from "Features/pdf/components/ButtonToggle3D";
// import useSceneModule from "Features/navigation/useSceneModule";
// import CardIsDrawing from "Features/measurements/components/CardIsDrawing";
// import FormMeasurementInViewer from "Features/measurements/components/FormMeasurementInViewer";
import useIsFullscreen from "Features/ui/useIsFullscreen";

export default function LayerDefault({caplaEditor, scene}) {
  // data

  const showIssues = useSelector((s) => s.issues.showIssuesInViewer);
  // const showQuantitiesInViewer = useSelector(
  //   (s) => s.measurements.showQuantitiesInViewer
  // );
  const fullscreen = useIsFullscreen();
  // const imageOverlayProps = useSelector((s) => s.viewer3D.imageOverlayProps);
  // const imageOverlayMode = useSelector((s) => s.viewer3D.imageOverlayMode);

  // const sceneModule = useSceneModule();

  // const pdfViewerMode = useSelector((s) => s.pdf.pdfViewerMode);

  // const measurementsMode = useSelector((s) => s.measurements.measurementsMode);

  // helper - top

  const top = fullscreen ? "16px" : "68px";

  // helper - image overlay

  // const {x, y, width, height, url} = imageOverlayProps;

  // helper - show 3D

  // const show3D = sceneModule === "VIEWER_PDF" || fullscreen;

  return (
    <>
      {showIssues && (
        <Box sx={{position: "absolute", top, left: "8px", zIndex: 30}}>
          <ListContainerIssuesInViewer
            caplaEditor={caplaEditor}
            scene={scene}
            viewer="PDF"
          />
        </Box>
      )}

      {/* {showQuantitiesInViewer && (
        <Box sx={{position: "absolute", top, left: "8px", zIndex: 30}}>
          <CardMeasurementsInViewer editor={editor} />
        </Box>
      )} */}

      {/* {pdfViewerMode === "MEASUREMENTS" && (
        <Box sx={{position: "absolute", top, right: "8px", zIndex: 30}}>
          {measurementsMode ? (
            <CardIsDrawing type={measurementsMode} />
          ) : (
            <CardMeasurementDetailInViewer editor={editor} />
          )}
        </Box>
      )}

      {pdfViewerMode === "MEASUREMENTS" && (
        <Box sx={{position: "absolute", top: "60px", left: "8px", zIndex: 30}}>
          <FormMeasurementInViewer editor={editor} scene={scene} />
        </Box>
      )} */}

      {/* {imageOverlayMode && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 25,
            width,
            height,
            left: x,
            top: y,
            border: "1px solid red",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundImage: `url(${url})`,
          }}
        >
          test
        </Box>
      )} */}
    </>
  );
}
