export default function isDateInScope({
  date,
  dateMin,
  dateMax,
  todo,
  done,
  builtAts,
}) {
  const d = date && date.split("T")[0];
  const min = dateMin && dateMin.split("T")[0];
  const max = dateMax && dateMax.split("T")[0];

  let r = true;

  if (done && !date) {
    r = false;
  } else if (builtAts?.length === 0) {
    r = false;
  } else if (builtAts?.length > 0) {
    r = builtAts.includes(date);
  } else if (!todo && !date && !dateMin && !dateMax) {
    r = true;
  } else if (todo && !date) {
    r = true;
  } else if (todo && date) {
    r = false;
  } else if (!date && (dateMin || dateMax)) {
    r = false;
  } else if (!dateMin && !dateMax) {
    r = true;
  } else if (dateMin && !dateMax) {
    r = d >= min;
  } else if (!dateMin && dateMax) {
    r = d <= max;
  } else if (dateMin && dateMax) {
    r = d >= min && d <= max;
  }

  return r;
}
