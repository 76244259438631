import {Dialog, DialogContent, Box, DialogTitle} from "@mui/material";

import FormEditSurvey from "./FormEditSurvey";

export default function DialogEditSurvey({open, onClose, survey, scene}) {
  // strings

  const title = "Modifier la viste";

  // handlers

  function handleSurveyUpdated() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{pt: 2}}>
          <FormEditSurvey
            scene={scene}
            survey={survey}
            onSurveyUpdated={handleSurveyUpdated}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
