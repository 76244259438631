import React from "react";

import {Box} from "@mui/material";

import Logo from "./Logo";
import ButtonCloseMenu from "./ButtonCloseMenu";

export default function Header() {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: (theme) => theme.spacing(6.5),
        p: 1,
        position: "relative",
      }}
    >
      <Logo />

      <Box
        sx={{
          position: "absolute",
          right: "8px",
        }}
      >
        <ButtonCloseMenu />
      </Box>
    </Box>
  );
}
