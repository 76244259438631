import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedSector from "../hooks/useSelectedSector";
import useUpdateSceneSector from "../hooks/useUpdateSceneSector";
import {
  setIsEditingSector,
  setEditedSector,
  // setTempSectorTypes,
} from "../sectorsSlice";

export default function ActionsSectorInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.sectors.isEditingSector);
  const selectedSector = useSelectedSector(scene);
  const editedSector = useSelector((s) => s.sectors.editedSector);

  const updateSector = useUpdateSceneSector(scene);

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingSector(true));
    dispatch(setEditedSector({...selectedSector}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingSector(false));
  }

  async function handleSaveClick() {
    // sectors
    const updatedSector = {...editedSector};
    updateSector(updatedSector);
    dispatch(setIsEditingSector(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
