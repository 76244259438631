import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGridPro, GridActionsCellItem, frFR} from "@mui/x-data-grid-pro";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import {setSelectedZoneId} from "../zonesSlice";
import zonesForDataGrid from "../utils/zonesForDataGrid";
import useZonesByScene from "../hooks/useZonesByScene";

import {
  setSelectedPdfModelId,
  setSelectedModelId,
} from "Features/viewer3D/viewer3DSlice";
import useIsNarrow from "Features/ui/useIsNarrow";

export default function DataGridZones({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // strings

  const sectorS = "Sector";
  const pdfS = "PDF";
  const nameS = "Name";
  const sizeS = "Size";
  const focusS = "Focus";

  // data

  const zones = useZonesByScene(scene);
  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);
  const lastZoneId = useSelector((s) => s.zones.lastZoneId);
  const models = useSelector((s) => s.viewer3D.models);
  const os = useSelector((s) => s.ui.openSections);
  const isNarrow = useIsNarrow();
  const sectorsInScope = useSelector((s) => s.measurements.sectorsInScope);
  const roomsInScope = useSelector((s) => s.measurements.roomsInScope);
  // const zonesInScope = useSelector((s) => s.measurements.zonesInScope);

  // helpers - scopedZones

  let scopedZones = zones;
  // if (zonesInScope.length > 0) {
  //   const zscope = new Set(zonesInScope);
  //   scopedZones = scopedZones.filter((z) => zscope.has(z.id));
  // }
  if (roomsInScope.length > 0) {
    const rs = new Set(roomsInScope);
    scopedZones = scopedZones.filter((z) => rs.has(z.roomId));
    if (rs.has(null)) scopedZones = [].concat(scopedZones, [...zones.filter((z) => !z.roomId)]);
  } else if (sectorsInScope.length > 0 && (sectorsInScope[0] !== undefined && sectorsInScope.slice(-1) !== null)) {
    const ss = new Set(sectorsInScope);
    scopedZones = scopedZones.filter((z) => ss.has(z.sectorId));
    if (ss.has(null)) scopedZones = [].concat(scopedZones, [...zones.filter((z) => !z.sectorId)]);
  }

  // selection model

  const selectionModel = selectedZoneId ? [selectedZoneId] : [];

  // grid - rows

  const rows = zonesForDataGrid(scopedZones, models);

  // grid - columns

  const columns = [
    {
      field: "sector",
      headerName: sectorS,
      width: 100,
      hide: isNarrow,
    },
    {
      field: "pdf",
      headerName: pdfS,
      flex: 1,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
    {
      field: "fileSize",
      headerName: sizeS,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Focus fontSize="small" />}
          onClick={() => handleFocus(params.row.id)}
          label={focusS}
        />,
      ],
    },
  ];

  // handlers

  function handleFocus(zoneId) {
    dispatch(setSelectedZoneId(zoneId));
    const zone = scopedZones.find((zone) => zone.id === zoneId);
    dispatch(setSelectedModelId(zone?.pdfModelId));
    dispatch(setSelectedPdfModelId(zone?.pdfModelId));
    if (os.viewer3D) {
      const imageModel = models.find((m) => m.id === zone?.imageModelId);
      caplaEditor?.editor3d?.sceneEditor?.focusImageModel(imageModel);
    }
    if (os.fixedViewersBox) caplaEditor?.editorPdf?.annotationsManager.goToZone(zone?.id);
  }

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    const selectedZone = scopedZones.find((z) => z.id === selectionId);
    // state
    dispatch(setSelectedZoneId(selectionId));
    if (selectedZone) {
      dispatch(setSelectedModelId(selectedZone?.pdfModelId));
      dispatch(setSelectedPdfModelId(selectedZone?.pdfModelId));
      
      // // camera
      // const imageModel = models.find((m) => m.id === selectedZone?.imageModelId);
      // caplaEditor?.editor3d.sceneEditor.focusImageModel(imageModel);

      // jump in 3D
      caplaEditor?.editor3d?.sceneEditor?.jumpModel(selectedZone?.imageModelId);
      
      // selection
      caplaEditor?.editorPdf.annotationsManager?.selectAnnotation(selectionId);
      // caplaEditor?.editorPdf.annotationsManager.goToZone(selectionId);
    }
  }

  // effect - selection of last element

  useEffect(() => {
    if (lastZoneId && zones?.length > 0) {
      caplaEditor?.editorPdf?.annotationsManager.selectAnnotation(lastZoneId);
      dispatch(setSelectedZoneId(lastZoneId));
      console.log("[EFFECT412");
    }
  }, [lastZoneId, zones?.length]);

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGridPro
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
