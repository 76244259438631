import {useDispatch} from "react-redux";

import useInputsByScene from "./useInputsByScene";
import {setOpenLeftPanel} from "Features/navigation/navigationSlice";

export default function useInitOpenSectionInPageInputs(scene) {
  const dispatch = useDispatch();
  const inputs = useInputsByScene(scene);
  const noInputs = inputs.length === 0;

  if (noInputs) {
    //dispatch(setOpenLeftPanel(false));
  }
}
