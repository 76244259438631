import React from "react";
import {useSelector} from "react-redux";
import { Card, CardContent, Typography, List, ListItem } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export default function CardSites() {
  const sites = useSelector((state) => state.viewer3D.sites);
  return (
    <Card>
      <CardContent>
        <Typography variant="body2">
          <b>Sites</b>
        </Typography>
        <List>
          {sites.map((site) => (
            <ListItem key={site.name}>{site.name}</ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
