import {useDispatch} from "react-redux";

import {Box, Typography, Button} from "@mui/material";

import {setAnnotCreationTool} from "Features/measurements/measurementsSlice";
import {setMode} from "Features/viewer3D/viewer3DSlice";

export default function SectionHelperDrawZone({editorPdf}) {
  const dispatch = useDispatch();

  // string

  const addS = "Dessinez un rectangle";
  const cancelS = "Annuler";

  // handlers

  function handleCancelClick() {
    dispatch(setMode("DEFAULT"));
    dispatch(setAnnotCreationTool(null));
    editorPdf?.enablePanTool();
    editorPdf?.sceneEditor.stopProcess();
    editorPdf?.annotationsManager.stopDrawingZone();
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "background.default",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Typography color="text.secondary">{addS}</Typography>
      <Box sx={{color: "text.secondary", mt: 2}}>
        <Button color="inherit" variant="outlined" onClick={handleCancelClick}>
          {cancelS}
        </Button>
      </Box>
    </Box>
  );
}
