import {client} from "API/capla360";

export async function createMapService({accessToken, userId, listingId, map}) {
  const response = await client.post(
    `/users/${userId}/listings/${listingId}/maps/`,
    map,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}

export async function fetchMapsService({accessToken, userId, listingId}) {
  const response = await client.get(
    `/users/${userId}/listings/${listingId}/maps/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}
