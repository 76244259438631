import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useDeleteSceneElementType(scene) {
  const dispatch = useDispatch();

  const deleteSceneElementType = (elementType) => {
    if (!scene) return;
    let oldElementTypes = scene?.data?.elementTypes.filter(
      (s) => s?.id !== elementType.id
    );
    if (!oldElementTypes) oldElementTypes = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, elementTypes: [...oldElementTypes]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return deleteSceneElementType;
}
