import {useSelector, useDispatch} from "react-redux";
import {setModeForOverview} from "../navigationSlice";

import {Box} from "@mui/material";
import SectionConfigModesOneMode from "./SectionConfigModesOneMode";

export default function SectionConfigModes({scene}) {
  const dispatch = useDispatch();

  // data

  const modes = [
    {key: "BASIC", name: "Basic", bgcolor: "secondary.main"},
    {key: "STANDARD", name: "Standard", bgcolor: "common.purple"},
    {key: "PRO", name: "Pro", bgcolor: "primary.flash"},
    {key: "EXPERT", name: "Expert"},
  ];

  // state

  const showedMode = useSelector((s) => s.navigation.modeForOverview);

  // handlers

  function handleModeClick(modeKey) {
    dispatch(setModeForOverview(modeKey));
  }
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "stretch",
        px: 10,
        "&>*:not(:last-child)": {mr: 3},
      }}
    >
      {modes.map((mode) => {
        const selected =
          mode.key === scene?.bimboxMode ||
          (mode?.key === "BASIC" && !scene.bimboxMode);
        const open = mode.key === showedMode;
        return (
          <SectionConfigModesOneMode
            key={mode.key}
            modeKey={mode.key}
            name={mode.name}
            bgcolor={mode.bgcolor}
            selected={selected}
            open={open}
            sceneId={scene?.id}
            onClick={() => handleModeClick(mode.key)}
          />
        );
      })}
    </Box>
  );
}
