import {client} from "API/capla360";
import cleanElementTypesGroupToSync from "./utils/cleanElementTypesGroupToSync";

export const createElementTypesGroupService = async ({
  accessToken,
  elementTypesGroup,
  fromScopeId,
}) => {
  const sceneId = elementTypesGroup.sceneId;
  const serializedGroup = {
    ...elementTypesGroup,
    scene: sceneId,
  };

  const response = await client.post(
    `scenes/${sceneId}/typesgroups/`,
    serializedGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        fromScope: fromScopeId,
      },
    }
  );
  return {item: response.data, fromScopeId};
};

export const fetchElementTypesGroupsService = async ({
  accessToken,
  sceneId,
}) => {
  const response = await client.get(`/scenes/${sceneId}/typesgroups/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export const updateElementTypesGroupService = async ({
  accessToken,
  elementTypesGroup,
}) => {
  const sceneId = elementTypesGroup.sceneId;
  const cleanGroup = cleanElementTypesGroupToSync(elementTypesGroup);
  console.log("cleanGroup", cleanGroup);
  const response = await client.patch(
    `/scenes/${sceneId}/typesgroups/${elementTypesGroup.id}/`,
    cleanGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

export const deleteElementTypesGroupService = async ({
  accessToken,
  elementTypesGroup,
}) => {
  const elementTypesGroupId = elementTypesGroup.id;
  const sceneId = elementTypesGroup.sceneId;
  console.log("try to delete typesGroup", sceneId, elementTypesGroup);
  await client.delete(
    `/scenes/${sceneId}/typesgroups/${elementTypesGroupId}/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return {elementTypesGroupId};
};
