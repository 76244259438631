import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setSubjectElementTypeMap} from "../annotatedPdfSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import {Box, Button} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function ButtonRefreshMapping({scene}) {
  const dispatch = useDispatch();

  // strings

  const refreshS = "Mettre à jour";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const rawMeasurements = useSelector((s) => s.annotatedPdf.rawMeasurements);

  // handlers

  function handleClick() {
    const _subjectElementTypeMap = {};
    rawMeasurements.forEach((rawMeasurement) => {
      const elementType = elementTypes.find(
        (t) => t.subject === rawMeasurement.subject
      );
      if (elementType)
        _subjectElementTypeMap[rawMeasurement.subject] = elementType;
    });
    dispatch(setSubjectElementTypeMap(_subjectElementTypeMap));
  }
  return (
    <Box sx={{color: "text.secondary"}}>
      <Button
        startIcon={<Refresh />}
        size="small"
        onClick={handleClick}
        color="inherit"
      >
        {refreshS}
      </Button>
    </Box>
  );
}
