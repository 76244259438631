import {client} from "API/capla360";

export async function fetchContributionsService({accessToken, listingId}) {
  const response = await client.get(`listings/${listingId}/contributions/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, listingId};
}

export async function fetchExcelContributionsService({
  listingId,
  targetLocalId,
}) {
  const response = await client.get(
    `excelcontributions/${listingId}/${targetLocalId}/`,
    {}
  );
  return {data: response.data, listingId};
}

export async function createContributionService({
  accessToken,
  listingId,
  contribution,
}) {
  const response = await client.post(
    `listings/${listingId}/contributions/`,
    contribution,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, listingId};
}
