import {useDispatch} from "react-redux";

import {unwrapResult} from "@reduxjs/toolkit";

import {Refresh} from "@mui/icons-material";
import {Button} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";
import {fetchElementTypesGroups} from "../elementTypesSlice";

export default function ButtonRefresh({scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const refreshS = "Refresh";

  // handlers

  async function handleRefresh() {
    if (accessToken && scene?.id) {
      console.log("refresh");
      const result = await dispatch(
        fetchElementTypesGroups({accessToken, sceneId: scene?.id})
      );
      console.log("Fetch groups", unwrapResult(result));
    }
  }

  return (
    <Button
      color="inherit"
      onClick={handleRefresh}
      size="small"
      startIcon={<Refresh />}
    >
      {refreshS}
    </Button>
  );
}
