import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

const properValue = (excelValue) => {
  if (excelValue && typeof excelValue === "object") {
    if (excelValue.result) return excelValue.result;
    if (excelValue.hyperlink) return excelValue.text;
    if (excelValue.richText)
      return excelValue.richText.map((t) => t.text).join("");
    return null; // sharedFormula without result for instance
  }
  // typeof date ?
  return excelValue;
};

export default async function getRessourcesRelationsFromFile(
  file, typesById, relations
) {
  const editedTypesGroups = new Set();
  const editedRelations = {...relations};
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];
  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(6, rowCount - 1).values();
  let ressourceId, typeGroup;
  for (const row of rows) {
    const id = properValue(row.getCell(1).value);
    const rNum = properValue(row.getCell(2).value);
    const tNum = properValue(row.getCell(5).value);
    const typeValue = properValue(row.getCell(6).value);
    const code = properValue(row.getCell(7).value);
    const drawingShape = properValue(row.getCell(8).value);
    const color = properValue(row.getCell(9).value);
    let formula = properValue(row.getCell(10).value);
    const condition = properValue(row.getCell(11).value);
    const toDelete = properValue(row.getCell(12).value);
    if (rNum && rNum !== " ") {
      ressourceId = id;
    } else if (tNum || code || drawingShape || color || formula || condition) {
      let type = typesById[id];
      if (!type) {
        type = Object.values(typesById).find(
          (t) => t.groupName === typeGroup && t.num === tNum
        );
      }
      if (type) {
        editedTypesGroups.add(type.groupId || type.group);
        const relKey = `${type.id}-${ressourceId}`;
        if (toDelete === "x") {
          delete editedRelations[relKey];
        } else if (formula) {
          if (condition) formula += ` {{${condition}}}`;
          editedRelations[relKey] = {
            id: relKey,
            typeId: type.id,
            resId: ressourceId,
            mode: "FORMUL",
            func: formula,
          }
        } else {
          editedRelations[relKey] = {
            id: relKey,
            typeId: type.id,
            resId: ressourceId,
            mode: "AUTO",
          }
        }
      }
    } else if (typeValue) {
      typeGroup = typeValue.trim();
    }
  }
  return {editedRelations, editedTypesGroups};
}
