import React from "react";
import SelectorProgressRecordInToolbar from "./SelectorProgressRecordInToolbar";

import {Box} from "@mui/material";
import ButtonAddMeasurementsProgressInToolbar from "./ButtonAddMeasurementsProgressInToolbar";

export default function ToolbarProgress({scene}) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <SelectorProgressRecordInToolbar scene={scene} />
      <ButtonAddMeasurementsProgressInToolbar />
    </Box>
  );
}
