import {Box, Divider} from "@mui/material";

// import LinkModelsFromDB from "./components/LinkModelsFromDB";
import ResetCameraButton from "./components/ResetCameraButton";
import DebugCameraButton from "./components/DebugCameraButton";
import ButtonPointerLock from "./components/ButtonPointerLock";
import SwitchBeta from "./components/SwitchBeta";

export default function ToolDebug({editor3d}) {
  return (
    <Box
      sx={{
        width: 300,
        height: 1,
        backgroundColor: "background.default",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box sx={{p: 1, display: "flex", width: 1, justifyContent: "center"}}>
        <ButtonPointerLock editor3d={editor3d} />
      </Box>
      <Divider />
      <Box sx={{p: 1, display: "flex", width: 1, justifyContent: "center"}}>
        <ResetCameraButton editor3d={editor3d} />
      </Box>
      <Box sx={{p: 1, display: "flex", width: 1, justifyContent: "center"}}>
        <DebugCameraButton editor3d={editor3d} />
      </Box>
      <Divider />
      {/* <LinkModelsFromDB scene={scene} /> */}
      <Box sx={{p: 1, display: "flex", width: 1, justifyContent: "center"}}>
        <SwitchBeta />
      </Box>
    </Box>
  );
}
