import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsZones from "Features/zones/components/SectionActionsZones";

export default function SectionLeftPanelActionsZones({scene, caplaEditor}) {
  // data

  const sceneModule = useSceneModule();

  // actions - main

  const actionsMainKeys = ["createZone"];
  const actionsMoreKeys = ["createZoneFromImage"];

  return (
    <Box>
      <SectionActionsZones
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
