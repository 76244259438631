import React from "react";

import {useSelector} from "react-redux";

import SelectorAutocomplete from "Components/SelectorAutocomplete";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function FieldTempZoneRoom({room, onChange, scene, sectorCode}) {
  // string

  const roomS = "Zone";

  // data

  const rooms = useRoomsByScene(scene);

  // helpers

  let options = rooms
    .map((s) => ({
      ...s,
      z: s.z === 0 || s.z ? s.z : 9999,
    }))
    .sort((a, b) => a.z - b.z);

  if (sectorCode) {
    options = options.filter((o) => !o.sector || o.sector === sectorCode);
  }

  const option = room ? room : null;

  return (
    <SelectorAutocomplete
      name={roomS}
      options={options}
      onChange={onChange}
      value={option}
    />
  );
}
