import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {Box, Typography, Button} from "@mui/material";
import {Room as Marker} from "@mui/icons-material";

import ButtonAddPdfMarker from "./ButtonAddPdfMarker";
import DialogLocateZone from "./DialogLocateZone";

import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";

export default function InputZoneMarkers({zone, caplaEditor, scene}) {
  // strings

  const noMarkerString = "0 marker";
  const oneMarkerString = "1 marker";
  const markersString = "markers";

  const addMarkerString = "Click on the pdf to locate a marker.";

  const moveString = "Locate zone";
  const cancelString = "Cancel";

  // data

  const pdfMarkers = useSelector((s) => s.viewer3D.pdfMarkers);
  const markers = useMarkersByScene(scene?.clientId);

  // helpers

  let inPdfMarkers = caplaEditor?.editorPdf.annotationsManager.findPointsInAnnotation(
    {
      annotationId: zone.id,
      points: pdfMarkers,
    }
  );
  if (!Array.isArray(inPdfMarkers)) inPdfMarkers = [];

  const inPdfMarkersIds = inPdfMarkers.map((m) => m.markerId);

  const inMarkers = markers?.filter((m) => inPdfMarkersIds.includes(m.id));

  const noMarkers = inMarkers.length === 0;

  let markersLabel = noMarkerString;
  if (inMarkers?.length > 0) {
    markersLabel =
      inMarkers.length === 1
        ? oneMarkerString
        : `${inMarkers.length} ${markersString}`;
  }

  // state

  const [show, setShow] = useState(true);

  const [active, setActive] = useState(false); // add marker button

  const [open, setOpen] = useState(false);

  const initSelection = inPdfMarkers
    .map((m) => ({...m, checked: true}))
    .sort((a, b) => a.num - b.num);
  const [selection, setSelection] = useState(initSelection);

  useEffect(() => {
    setSelection(initSelection);
  }, [inPdfMarkers.length]);

  const [useMarkerHeight, setUseMarkerHeight] = useState(false);

  // handlers - add markers

  function handleActiveChange(active) {
    setActive(active);
  }

  function handleCancelClick() {
    setActive(false);
    caplaEditor?.editorPdf?.activePanTool();
    caplaEditor?.editorPdf?.sceneEditor.disablePointer();
    caplaEditor?.editorPdf?.sceneEditor.hideTempMarker();
    caplaEditor?.editorPdf?.logPagePositionOnClick();
  }

  // handlers dialog

  function handleDialogOpen() {
    setOpen(true);
  }
  function handleDialogClose() {
    setOpen(false);
  }
  function handleSelectionChange(selection) {
    setSelection(selection);
  }
  function handleUseMarkerHeightChange(bool) {
    setUseMarkerHeight(bool);
  }

  // handlers - locate zone

  const handleLocateZone = () => {
    const annotationId = zone.id;
    const selectionChecked = selection.filter((m) => m.checked);
    if (selectionChecked.length === 1) {
      const pagePoint = selectionChecked[0];
      const boxPoint = markers.find((m) => m.id === pagePoint?.markerId);
      console.log("locate zone", pagePoint, boxPoint);
      caplaEditor?.editorPdf?.annotationsManager.positionAnnotationWithOnePoint(
        annotationId,
        pagePoint,
        boxPoint,
        useMarkerHeight
      );
    }
    if (selectionChecked.length === 2) {
      const pagePoint1 = selectionChecked[0];
      const boxPoint1 = markers.find((m) => m.id === pagePoint1?.markerId);
      const pagePoint2 = selectionChecked[1];
      const boxPoint2 = markers.find((m) => m.id === pagePoint2?.markerId);

      caplaEditor?.editorPdf?.annotationsManager.positionAnnotationWithTwoPoints(
        annotationId,
        pagePoint1,
        boxPoint1,
        pagePoint2,
        boxPoint2,
        useMarkerHeight
      );
    }

    setOpen(false);
  };

  return (
    <Box sx={{bgcolor: "common.darkGrey", p: 0.5, position: "relative"}}>
      <DialogLocateZone
        open={open}
        scene={scene}
        onClose={handleDialogClose}
        onLocate={handleLocateZone}
        selection={selection}
        onSelectionChange={handleSelectionChange}
        useMarkerHeight={useMarkerHeight}
        onUseMarkerHeightChange={handleUseMarkerHeightChange}
      />
      {active && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1,
            bgcolor: "common.darkGrey",
            color: "common.white",
            p: 0.5,
          }}
        >
          <Typography sx={{fontSize: 12}}>{addMarkerString}</Typography>
          <Button
            sx={{position: "absolute", bottom: 0, right: 0}}
            color="inherit"
            onClick={handleCancelClick}
          >
            <Typography sx={{fontSize: 12}}>{cancelString}</Typography>
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: 1,
          justifyContent: "space-between",
          color: "common.white",
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Marker sx={{fontSize: 12, color: "common.white", mr: 2}} />
          <Typography color="common.white" sx={{fontSize: 12}}>
            {markersLabel}
          </Typography>
        </Box>
        <ButtonAddPdfMarker
          caplaEditor={caplaEditor}
          scene={scene}
          show={show}
          active={active}
          onActiveChange={handleActiveChange}
          zone={zone}
        />
      </Box>

      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "flex-end",
          mt: 1,
          color: "common.white",
        }}
      >
        <Button
          sx={{visibility: noMarkers ? "hidden" : "visible"}}
          size="small"
          variant="outlined"
          color="inherit"
          onClick={handleDialogOpen}
        >
          <Typography sx={{fontSize: 12}}>{moveString}</Typography>
        </Button>
      </Box>
    </Box>
  );
}
