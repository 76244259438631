import { useState } from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";

import ListItemPhase from "./ListItemPhase";

import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import { setSelectedPhaseIds } from "Features/phases/phasesSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";

export default function ShortcutPhases({scene}) {
  const dispatch = useDispatch();

  // strings

  const multiselectString = "Sélection multiple";

  // data

  const phases = usePhasesByScene(scene);
  let selectedPhaseIds = useSelector((s) => s.phases.selectedPhaseIds);
  const [fms] = useFilteredSceneMeasurements(scene);

  // state

  const [checked, setChecked] = useState(false);

  // helper

  const phasesShortcut = [];
  const phasesSet = new Set();
  let hasUndefined = false;
  fms.forEach((m) => {
    if (!m.phaseId && !hasUndefined) {
      phasesShortcut.push({id: "undefined", name: "- ? -", num: "9999"});
      hasUndefined = true;
      phasesSet.add("undefined");
    } else if (!phasesSet.has(m.phaseId)) {
      const thePhase = phases.find((p) => p.id === m.phaseId);
      if (thePhase) phasesShortcut.push(thePhase);
      phasesSet.add(m.phaseId);
    }
  });

  // handlers

  function handlePhaseClick(phase) {
    if (!checked && selectedPhaseIds.length === 1 && phase.id === selectedPhaseIds[0]) {
      dispatch(setSelectedPhaseIds([...phasesSet]));
    } else if (!checked) {
      dispatch(setSelectedPhaseIds([phase.id]));
    } else if (selectedPhaseIds.includes(phase.id)) {
      let newSelection = selectedPhaseIds.filter((i) => i && i !== phase.id);
      if (newSelection.length === 0) newSelection = [...phasesSet];
      dispatch(setSelectedPhaseIds(newSelection));
    } else {
      dispatch(setSelectedPhaseIds([...selectedPhaseIds, phase.id]));
    }
  }

  function handleCheckChange(e) {
    setChecked(e.target.checked);
  }

  return (
    <>
    <FormControlLabel
      sx={{mb: 1, pl: 1.25}}
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheckChange}
          size="small"
          sx={{width: "12px", height: "12px", mr: 1}}
        />
      }
      label={
        <Typography sx={{fontSize: 12}} variant="body2">
          {multiselectString}
        </Typography>
      }
    />
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {phasesShortcut.sort((a, b) => a.num - b.num).map((phase) => (
        <ListItemPhase
          key={phase.id}
          onClick={handlePhaseClick}
          phase={phase}
        />
      ))}
    </Box>
    </>
  );
}
