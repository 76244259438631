import {useState} from "react";
// import {useNavigate} from "react-router-dom";

import {Typography, Button, Box} from "@mui/material";

// import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";
import DialogExtractElementTypes from "Features/annotatedPdf/components/DialogExtractElementTypes";

export default function CardExtractElementTypes({scene}) {
  // const navigate = useNavigate();
  // const sceneId = scene?.id ? scene.id : scene?.clientId;

  // strings

  const title = "Nouveaux types d'éléments";
  const description = `Dans un PDF, les annotations sont regroupées par "sujet".
    Pour copier une annotation dans votre calque de repérage, son sujet doit être associé à un type d'élément.
    Si vous ne trouvez pas les bons types d'élément dans votre bimbox, vous pouvez en créer un pour chaque sujet.`;

  const buttonLabelS = "Extraire";

  // data

  const [open, setOpen] = useState(false);

  // helpers

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  function handleClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <Box
      sx={{
        width: 1,
        p: 2,
        borderRadius: "4px",
        background,
        color: "common.white",
      }}
    >
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
        {description}
      </Typography>

      <Box sx={{mt: 2}}>
        <Button
          size="small"
          onClick={handleClick}
          color="inherit"
          variant="outlined"
        >
          {buttonLabelS}
        </Button>
      </Box>

      <DialogExtractElementTypes
        scene={scene}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
}
