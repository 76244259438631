import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Menu,
  MenuItem,
  Box,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useSelectedIssueset from "../useSelectedIssueset";

import useAccessToken from "Features/auth/useAccessToken";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import { deleteListing, updateListing } from "Features/listings/listingsSlice";

export default function ButtonMoreActionsIssueSet({sceneId}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const renameS = "Rename";
  const deleteS = "Delete";
  const renameDialogS = "Renommer le notebook";
  const nameS = "Nouveau nom";
  const cancelS = "Annuler";
  const saveS = "Confirmer";

  // data

  const listingId = useSelector((s) => s.issues.selectedIssuesListingId);
  const selectedIssueset = useSelectedIssueset(sceneId);

  // state
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [name, setName] = useState(selectedIssueset?.name);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);

  // helpers - tools
  
  const tools = [
    {
      id: "RENAME",
      label: renameS,
      handler: handleRename,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  // handlers - rename

  function handleRename() {
    setOpenRenameDialog(true);
    setAnchorEl(null);
  }

  async function handleRenameConfirm() {
    setOpenRenameDialog(false);
    setAnchorEl(null);
    const updatedData = {name};
    dispatch(updateListing({listingId, accessToken, updatedData}));
  }

  function handleRenameCancel() {
    setOpenRenameDialog(false);
  }

  // handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    dispatch(deleteListing({listingId, accessToken}));
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // effect

  useEffect(() => {
    if (selectedIssueset?.name && !name)
      setName(selectedIssueset?.name);
  }, [selectedIssueset?.name])
  
  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <Dialog open={openRenameDialog} maxWidth={false}>
        <DialogTitle>{renameDialogS}</DialogTitle>
        <DialogContent>
          <Box
            sx={{display: "flex", flexDirection: "column", mt: 2, width: 300}}
          >
            <TextField
              size="small"
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              label={<Typography variant="body2">{nameS}</Typography>}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameCancel} >
            {cancelS}
          </Button>
          <Button onClick={handleRenameConfirm}>
            {saveS}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"issue"}
      />
    </>
  );
}
