import {useSelector, useDispatch} from "react-redux";

import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  alpha,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  setConfiguredModel,
  updateConfiguredModel,
  setRightSection,
} from "Features/viewer3D/viewer3DSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    background: alpha(theme.palette.common.white, 0.9),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  scale: {width: 150},
  position: {
    display: "flex",
    "&>*:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  content: {
    "&>*:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function CardPositionConfig({editor3d}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {model} = useSelector((state) => state.viewer3D.configuredModel);
  const object = editor3d?.getEntityById("IMAGE_MODEL", model.id).object;

  // displayed values to the user.

  const _posX = model?.position.x ? +model?.position.x.toFixed(3) : 0;
  const _posY = model?.position.z ? -model?.position.z.toFixed(3) : 0;
  const _posZ = model?.position.y ? +model?.position.y.toFixed(3) : 0;

  const _rotX = model?.rotation.x
    ? +((model.rotation.x * 180) / Math.PI).toFixed(1)
    : 0;
  const _rotY = model?.rotation.y
    ? +((model.rotation.y * 180) / Math.PI).toFixed(1)
    : 0;
  const _rotZ = model?.rotation.z
    ? +((model.rotation.z * 180) / Math.PI).toFixed(1)
    : 0;

  const scale = model?.scale ? +model?.scale.toFixed(3) : 1;

  function handlePositionChange(e) {
    let newValue = parseFloat(e.target.value);
    const axis = e.target.id;

    newValue = axis === "z" ? -newValue : newValue;

    const updatedModel = {
      ...model,
      position: {...model.position, [axis]: newValue},
    };

    dispatch(updateConfiguredModel(updatedModel));

    editor3d?.positionObject(object, updatedModel.position, updatedModel.rotation);
  }

  function handleRotationChange(e) {
    let newValue = (parseFloat(e.target.value) * Math.PI) / 180;
    const axis = e.target.id;

    // newValue = axis === "z" ? -newValue : newValue;

    const updatedModel = {
      ...model,
      rotation: {...model.rotation, [axis]: newValue},
    };

    dispatch(updateConfiguredModel(updatedModel));
    editor3d?.positionObject(object, updatedModel.position, updatedModel.rotation);
  }

  function handleScaleChange(e) {
    const scale = parseFloat(e.target.value);
    const updatedModel = {
      ...model,
      scale,
    };
    dispatch(updateConfiguredModel(updatedModel));
    object.scale.set(scale, scale, scale);
  }

  function handleOnSaveClick() {
    dispatch(setConfiguredModel({}));
    dispatch(setRightSection(false));
  }

  return (
    <Card className={classes.root}>
      {model && (
        <CardContent className={classes.content}>
          <div className={classes.header}>
            <Typography variant="body2">
              <b>Position de l'image</b>
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              label="Echelle"
              className={classes.scale}
              onChange={handleScaleChange}
              value={scale}
              type="number"
            />
          </div>
          <div className={classes.position}>
            <TextField
              id="x"
              size="small"
              label="X"
              value={_posX}
              variant="outlined"
              type="number"
              onChange={handlePositionChange}
            />
            <TextField
              id="z"
              size="small"
              label="Y"
              value={_posY}
              variant="outlined"
              type="number"
              onChange={handlePositionChange}
            />
            <TextField
              id="y"
              size="small"
              label="Z"
              value={_posZ}
              variant="outlined"
              type="number"
              onChange={handlePositionChange}
            />
          </div>

          <div className={classes.position}>
            <TextField
              id="x"
              size="small"
              label="rotation X"
              value={_rotX}
              variant="outlined"
              type="number"
              onChange={handleRotationChange}
            />
            <TextField
              id="y"
              size="small"
              label="rotation Y"
              value={_rotY}
              variant="outlined"
              type="number"
              onChange={handleRotationChange}
            />
            <TextField
              id="z"
              size="small"
              label="rotation Z"
              value={_rotZ}
              variant="outlined"
              type="number"
              onChange={handleRotationChange}
            />
          </div>
        </CardContent>
      )}
      <CardActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disableElevation
          onClick={handleOnSaveClick}
        >
          Enregistrer
        </Button>
      </CardActions>
    </Card>
  );
}
