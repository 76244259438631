import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import useSceneModule from "Features/navigation/useSceneModule";
import useSectionDims from "Features/ui/useSectionDims";
import ToolbarRessourcesMain from "Features/ressources/components/ToolbarRessourcesMain";
import DataGridRessources from "Features/ressources/components/DataGridRessources";
import ToolbarEditDatagrid from "Features/ressources/components/ToolbarEditDatagrid";
import SectionRessourceGroupWithChips from "Features/ressources/components/SectionRessourceGroupWithChips";
import useIssuesetsInScope from "Features/issues/useIssuesetsInScope";
import Stretch from "Features/ui/components/Stretch";

export default function SceneRessources() {
  // params

  const {scene, caplaEditor} = useOutletContext();

  // data

  const sceneModule = useSceneModule();
  const [_, __, modelsIdsHash] = useIssuesetsInScope();

  // helpers - openSections

  const dims = useSectionDims();
  const dimsHash = Object.values(dims).join("-");

  const openSections = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(openSections).join("-");

  const openSelectionPanel = useSelector((s) => s.selection.openSelectionPanel);
  const openHash = openSelectionPanel ? "1" : "0";

  // helpers - strech deps

  const propDependency =
    modelsIdsHash + dimsHash + sceneModule + osHash + openHash;

  if (openSections.outlet)
    return (
      <Box
        sx={{
          bgcolor: "common.white",
          width: 1,
          height: 1,
        }}
      >
        <Box sx={{height: "68px"}} />
        <SectionRessourceGroupWithChips scene={scene} />
        <ToolbarRessourcesMain scene={scene} caplaEditor={caplaEditor} />
        <ToolbarEditDatagrid scene={scene} />
        <Stretch propDependency={propDependency}>
          <Box sx={{width: 1, height: 1}}>
            <DataGridRessources scene={scene} />
          </Box>
        </Stretch>
      </Box>
    );
  return <></>;
}
