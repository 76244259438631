import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedFormGroup} from "../elementTypesSlice";
import useElementTypesDrawingShapesOptions from "../hooks/useElementTypeDrawingShapesOptions";

import KeyValueForm from "Features/kvform/components/KeyValueForm";
import polyline from "Features/measurements/assets/polyline.png";
import polygon from "Features/measurements/assets/polygon.png";
import gable from "Features/measurements/assets/gable.png";
import bridge from "Features/measurements/assets/bridge.png";
import bowl from "Features/measurements/assets/bowl.png";
import segment from "Features/measurements/assets/segment.png";
import rectangle from "Features/measurements/assets/rectangle.png";
import circle from "Features/measurements/assets/circle.png";
import slope from "Features/measurements/assets/slope.png";
import bank from "Features/measurements/assets/bank.png";
import stairs from "Features/measurements/assets/stairs.png";
import beam from "Features/measurements/assets/beam.png";
import slopingPolygon from "Features/measurements/assets/slopingPolygon.png";
// import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";

import useTranslation from "Features/translations/useTranslation";

export default function FormElementType({
  scene,
  caplaEditor,
  elementType,
  elementTypes,
  onChange,
  horizontal = false,
  horizontalHeader = false,
  hiddenFields = [],
  canEdit = true,
}) {
  const dispatch = useDispatch();

  // strings
  const {t} = useTranslation("measurements");

  const descriptionS = "Description";
  const codeS = t("code");
  const nameS = t("name");
  // const materialS = t("material");

  const identityS = t("identity");
  const geometryS = t("geometry");
  const ressourcesS = t("ressources");
  const group3DS = "3D";

  const numS = t("num");
  const groupS = t("group");
  const groupIdS = "groupId";
  const isTitleS = t("title");
  const styleS = t("style");
  const title1S = t("title1");
  const title2S = t("title2");
  const normalS = t("normal");
  const detailS = t("detail");
  const isAggS = t("isAgg");

  const colorS = t("color");
  const unitS = t("unit");
  const isVoidS = t("isVoid");
  const dottedS = t("dotted");
  const drawingShapeS = t("drawingShape");
  const heightS = t("height");
  const heightES = t("heightE");
  const heightNS = t("heightN");
  const slopeHS = t("slopeH");
  const dim1S = t("dim1");
  const dim2S = t("dim2");
  const dim3S = t("dim3");
  const offsetS = t("offset");
  const sideS = t("side");
  const facesS = t("faces");
  const endBridgeS = t("endBridge");
  const sideBridgeS = t("sideBridge");
  const upS = t("up");
  const downS = t("down");
  const slopingAS = t("slopingA");
  const relatedTypeS = t("relatedType");
  const heightsS = t("heights");
  const treeS = t("tree");
  const identificationS = t("identification");
  const shapeS = t("shape");
  const geometry2DS = t("geometry2D");
  const geometry3DS = t("geometry3D");
  const file3dS = "Fichier 3D";
  const relatedMeasurementsS = t("relatedMeasurements");

  // const quantitiesS = t("quantities");
  const exportS = t("export");
  const subjectS = t("subject");
  const heightFromRoomS = t("heightFromRoom");

  const unS = t("UN");
  const mlS = t("ML");
  const m2S = t("M2");
  const m3S = t("M3");

  const parentS = t("parent");
  const articlesS = "Articles";
  const funcS = "Formules";

  // data

  // const materials = useMaterialsByScene(scene);

  const selectedFormGroup = useSelector(
    (s) => s.elementTypes.selectedFormGroup
  );

  const drawingShapesOptions = useElementTypesDrawingShapesOptions();

  // template

  const template = {
    key: "kvfElementType",
    groups: [
      {code: "IDENTITY", label: identityS},
      {code: "GEOMETRY", label: geometryS},
      //{code: "ARTICLES", label: ressourcesS},
      {code: "ARTICLES", label: articlesS},
      {code: "3D", label: group3DS},
    ],
    fields: [
      {
        group: "IDENTITY",
        key: "identification",
        type: "section",
        name: identificationS,
      },

      {group: "IDENTITY", key: "name", type: "text", name: nameS, width: 240},
      {
        group: "IDENTITY",
        key: "description",
        type: "textMultiline",
        name: descriptionS,
      },
      {
        group: "IDENTITY",
        key: "code",
        type: "text",
        name: codeS,
        width: 240,
      },
      {
        group: "IDENTITY",
        key: "unit",
        type: "selector",
        width: 70,
        name: unitS,
        options: [
          {key: "UN", name: unS},
          {key: "ML", name: mlS},
          {key: "M2", name: m2S},
          {key: "M3", name: m3S},
        ],
      },
      // {
      //   group: "IDENTITY",
      //   key: "material",
      //   type: "combo",
      //   name: materialS,
      //   width: 100,
      //   options: materials,
      // },
      {group: "IDENTITY", key: "tree", type: "section", name: treeS},
      {
        group: "IDENTITY",
        key: "groupId",
        type: "text",
        name: groupIdS,
        width: 80,
      },
      {group: "IDENTITY", key: "num", type: "text", name: numS, width: 100},
      //{group: "IDENTITY", key: "group", type: "text", name: groupS, width: 80},
      {group: "IDENTITY", key: "isTitle", type: "checkbox", name: isTitleS},
      {
        group: "IDENTITY",
        key: "style",
        type: "selector",
        width: 70,
        name: styleS,
        options: [
          {key: "title1", name: title1S},
          {key: "title2", name: title2S},
          {key: "normal", name: normalS},
          {key: "detail", name: detailS},
        ],
      },
      // {group: "IDENTITY", key: "isAgg", type: "checkbox", name: isAggS},
      // {
      //   group: "IDENTITY",
      //   key: "parent",
      //   type: "combo",
      //   name: parentS,
      //   options: elementTypes,
      // },
      {group: "GEOMETRY", key: "shape", type: "section", name: shapeS},
      {
        group: "GEOMETRY",
        key: "drawingShape",
        name: drawingShapeS,
        type: "selector",
        width: 100,
        options: drawingShapesOptions,
      },
      {
        group: "GEOMETRY",
        key: "helperImage",
        type: "imageRead",
        height: "140px",
      },
      {group: "GEOMETRY", key: "color", type: "color", name: colorS},
      {
        group: "GEOMETRY",
        key: "isVoid",
        name: isVoidS,
        type: "checkbox",
        width: 50,
      },
      {
        group: "GEOMETRY",
        key: "dotted",
        name: dottedS,
        type: "checkbox",
        width: 50,
      },
      {
        group: "GEOMETRY",
        key: "geometry2D",
        type: "section",
        name: geometry2DS,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "POLYLINE",
            "BRIDGE",
            "SLOPE",
            "STAIRS",
            "RECTANGLE",
            "CIRCLE",
            "BANK",
            "GABLE",
          ],
        },
      },
      {
        group: "GEOMETRY",
        key: "dim1",
        name: dim1S,
        type: "number",
        width: 60,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "POLYLINE",
            "BRIDGE",
            "SLOPE",
            "STAIRS",
            "RECTANGLE",
            "CIRCLE",
            "BANK",
            "GABLE",
          ],
        },
      },
      {
        group: "GEOMETRY",
        key: "dim2",
        name: dim2S,
        type: "number",
        width: 60,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "POLYLINE",
            "BRIDGE",
            "SLOPE",
            "STAIRS",
            "RECTANGLE",
            "BANK",
          ],
        },
      },
      {
        group: "GEOMETRY",
        key: "dim3",
        name: dim3S,
        type: "number",
        width: 60,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "GEOMETRY",
        key: "geometry3D",
        type: "section",
        name: geometry3DS,
      },
      {
        group: "GEOMETRY",
        key: "height",
        name: heightS,
        type: "number",
        width: 60,
        condition: {
          type: "showIfNotEqual",
          key: "drawingShape",
          values: ["GABLE"],
        },
      },
      {
        group: "GEOMETRY",
        key: "heights",
        name: heightsS,
        type: "text",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["GABLE"],
        },
      },
      {
        group: "GEOMETRY",
        key: "heightE",
        name: heightES,
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "GEOMETRY",
        key: "heightN",
        name: heightNS,
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        group: "GEOMETRY",
        key: "side",
        name: sideS,
        type: "selector",
        options: [
          {key: "UP", name: upS},
          {key: "DOWN", name: downS},
        ],
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM", "BRIDGE", "BOWL"],
        },
      },
      {
        group: "GEOMETRY",
        key: "faces",
        name: facesS,
        type: "selector",
        options: [
          {key: "END", name: endBridgeS},
          {key: "SIDE", name: sideBridgeS},
        ],
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BRIDGE"],
        },
      },
      {
        group: "GEOMETRY",
        key: "slopeH",
        name: slopeHS,
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SLOPE"],
        },
      },
      {
        group: "GEOMETRY",
        key: "relatedMeasurements",
        type: "section",
        name: relatedMeasurementsS,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BANK"],
        },
      },
      {
        group: "GEOMETRY",
        key: "relatedT",
        type: "combo",
        options: elementTypes,
        name: relatedTypeS,
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BANK"],
        },
      },
      {
        group: "GEOMETRY",
        key: "slopingA",
        name: slopingAS,
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SLOPING_POLYGON"],
        },
      },
      {
        group: "GEOMETRY",
        key: "offset",
        name: offsetS,
        type: "number",
      },
      {
        group: "GEOMETRY",
        key: "heightFromRoom",
        name: heightFromRoomS,
        type: "checkbox",
        width: 50,
      },
      {
        group: "3D",
        type: "fileUrl",
        key: "file3d",
        name: file3dS,
      },
      {group: "IDENTITY", key: "export", type: "section", name: exportS},
      {group: "IDENTITY", key: "subject", type: "text", name: subjectS},
      {
        group: "ARTICLES",
        key: "articleQties",
        type: "textMultiline",
        name: funcS,
      },
    ],
  };

  // if (elementType?.id)
  //   template.fields.push({
  //     group: "ARTICLES",
  //     key: "res",
  //     type: "relations",
  //     name: ressourcesS,
  //   });

  // template Horizontal

  const keys = [
    "num",
    "name",
    "group",
    "code",
    "drawingShape",
    "dotted",
    "isVoid",
    "unit",
    "dim1",
    "dim2",
    "dim3",
    "height",
    "offset",
  ];
  const fields = keys.map((k) => template.fields.find((f) => f.key === k));
  const templateH = {key: "elementTypeH", fields};

  // helper - image

  const helperImageMap = {
    POLYLINE: polyline,
    BRIDGE: bridge,
    BOWL: bowl,
    GABLE: gable,
    POLYGON: polygon,
    SEGMENT: segment,
    RECTANGLE: rectangle,
    CIRCLE: circle,
    SLOPE: slope,
    STAIRS: stairs,
    BANK: bank,
    BEAM: beam,
    SLOPING_POLYGON: slopingPolygon,
  };
  const helperImage = helperImageMap[elementType?.drawingShape];

  // item

  const item = {
    ...elementType,
    helperImage,
    isTitle: elementType?.isTitle ? elementType.isTitle : false,
    // parent: elementTypes?.find((t) => t.id === elementType?.parent),
    // relatedT: elementTypes.find((t) => t.id === elementType?.relatedT),
  };

  // handlers

  function handleChange(item) {
    const type = {
      ...item,
      parent: item?.parent?.id,
      relatedT: item?.relatedT?.id,
    };
    onChange(type);
  }

  function handleGroupChange(group) {
    dispatch(setSelectedFormGroup(group));
  }

  return elementType || horizontalHeader ? (
    <Box
      sx={{
        //minWidth: horizontal ? "unset" : 400,
        width: horizontal ? 1 : "unset",
      }}
    >
      <KeyValueForm
        item={item}
        caplaEditor={caplaEditor}
        scene={scene}
        template={horizontal ? templateH : template}
        onChange={handleChange}
        horizontal={horizontal}
        horizontalHeader={horizontalHeader}
        hiddenFields={hiddenFields}
        group={selectedFormGroup}
        onGroupChange={handleGroupChange}
        canEdit={canEdit}
        sizeKey={4}
        lockedFields={["groupId"]}
      />
    </Box>
  ) : (
    <div />
  );
}
