import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  TextField,
  Button,
  DialogActions,
  Box,
} from "@mui/material";

import {updateElementTypesGroup} from "../elementTypesSlice";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

import useAccessToken from "Features/auth/useAccessToken";

export default function DialogRenameElementTypesGroup({open, onClose, scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // string

  const dialogTitleString = "Renommer le groupe";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const selectedElementTypesGroup = useSelectedElementTypesGroup();

  // local state

  const [newName, setNewName] = useState(selectedElementTypesGroup?.name);
  const [newCode, setNewCode] = useState(selectedElementTypesGroup?.code);

  useEffect(() => {
    setNewName(selectedElementTypesGroup?.name);
  }, [selectedElementTypesGroup?.name]);

  useEffect(() => {
    setNewCode(selectedElementTypesGroup?.code);
  }, [selectedElementTypesGroup?.code]);

  const [loading, setLoading] = useState(false);

  // handlers

  function handleNewNameChange(e) {
    setNewName(e.target.value);
  }
  function handleNewCodeChange(e) {
    setNewCode(e.target.value);
  }

  async function handleRenameSave() {
    if (loading) return;
    setLoading(true);
    const elementTypesGroup = {
      id: selectedElementTypesGroup.id,
      sceneId: selectedElementTypesGroup.sceneId,
      name: newName.trim(),
    };
    if (newCode) elementTypesGroup.code = newCode.trim();
    await dispatch(updateElementTypesGroup({accessToken, elementTypesGroup}));
    setLoading(false);
    onClose();
  }

  function handleRenameCancel() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitleString} </DialogTitle>
      <DialogContent>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <TextField
            size="small"
            value={newCode}
            onChange={handleNewCodeChange}
            sx={{width: 80, mr: 1}}
          />
          <TextField
            size="small"
            value={newName}
            onChange={handleNewNameChange}
            sx={{minWidth: 350, maxWidth: 450}}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameCancel}>{cancelS}</Button>
        <Button onClick={handleRenameSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
