import React from "react";

import {useNavigate} from "react-router-dom";

import {ListItemButton, ListItemText, ListItemIcon} from "@mui/material";
import {ArrowCircleLeft as Back} from "@mui/icons-material";

export default function ListItemButtonScenes() {
  const navigate = useNavigate();

  // string

  const scenesString = "Bimboxes";

  // handlers

  function handleClick() {
    navigate("/");
  }

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <Back />
      </ListItemIcon>
      <ListItemText
        sx={{my: 0}}
        primary={scenesString}
        primaryTypographyProps={{
          fontSize: (theme) => theme.typography.body2.fontSize,
        }}
      />
    </ListItemButton>
  );
}
