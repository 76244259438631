import {Box, Typography, Paper} from "@mui/material";

import ImageBasic from "Features/images/components/ImageBasic";
import ButtonOpenDemo from "./ButtonOpenDemo";

export default function SectionProduct({
  name,
  description,
  overviewUrl,
  demoUrl,
}) {
  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box sx={{pb: 2}}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body">{description}</Typography>
      </Box>
      <Box sx={{width: 1}}>
        <Paper sx={{position: "relative"}}>
          <img src={overviewUrl} width={"100%"} />
          <Paper
            sx={{
              background: (theme) => `linear-gradient(
                    to bottom,
                    transparent 0%,
                    transparent 40%,
                    ${theme.palette.secondary.main} 90%,
                    ${theme.palette.secondary.main} 100%
                  )`,
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Box
              sx={{
                width: 1,
                height: 1,
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <ButtonOpenDemo demoUrl={demoUrl} />
            </Box>
          </Paper>
        </Paper>
      </Box>
      <Box
        sx={{
          padding: 2,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      ></Box>
    </Box>
  );
}
