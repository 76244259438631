import {useState} from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";

export default function ButtonOpenDemo({demoUrl}) {
  // strings

  const openS = "Consulter un aperçu du métré";

  const warningS = "Avertissement";

  const messageS = `Dans ce démonstrateur gratuit, les quantités sont remplies au hasard.`;

  const seeS = "Ouvrir le démonstrateur";

  // state

  const [open, setOpen] = useState(false);

  // handler

  function handleOpenClick() {
    setOpen(true);
  }

  function handleOpenDemo() {
    window.open(demoUrl, "_blank", "noreferrer");
  }

  return (
    <Box sx={{pb: 2, color: "white"}}>
      <Button variant="outlined" color="inherit" onClick={handleOpenClick}>
        {openS}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{warningS}</DialogTitle>
        <DialogContent>
          <Typography sx={{whiteSpace: "pre"}}>{messageS}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpenDemo}>{seeS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
