import {
  LineBasicMaterial,
  BufferGeometry,
  BufferAttribute,
  Line,
  SphereGeometry,
  Mesh,
  MeshBasicMaterial,
} from "three";

import Shape3D from "./Shape3D";

const materialSphere = new MeshBasicMaterial({
  color: "#F52585",
  transparent: true,
  opacity: 0.8,
});

class Drawer {
  constructor({onClosingLine, scene}) {
    this.points = [];
    this.scene = scene;

    this.closingMaterial = new LineBasicMaterial({color: "#F52585"});
    this.material = new LineBasicMaterial({color: "#277CEA"});

    this.nextPointIndex = 1;
    this.closingLine = false;
    this.closingPointPosition = undefined; // coordinates of closing points
    this.onClosingLine = onClosingLine;
    this.stop = false;

    this.shape = undefined; // shape to get the surface
    this.contour = undefined; // contour of the shape
    this.holes = []; // arrays of 3D points

    this.shapesCount = 0; // 0=> contour, 1=> 1st hole, 2=> 2nd hole

    this.objects = [];

    this.object = this.initializePolygon();
  }

  initializePolygon() {
    const MAX_POINTS = 100;
    const geometry = new BufferGeometry();
    const positions = new Float32Array(MAX_POINTS * 3);
    geometry.setAttribute("position", new BufferAttribute(positions, 3));
    // draw range
    const drawCount = 0;
    geometry.setDrawRange(0, drawCount);
    // line
    const line = new Line(geometry, this.material);
    line.layers.enable(1);
    line.frustumCulled = false;
    // add to scene
    this.scene.add(line);
    return line;
  }

  addShape() {
    this.shape = new Shape3D(this.contour);
    this.scene.add(this.shape.object);
  }

  updateShapeWithHoles() {
    this.scene.remove(this.shape.object);
    this.shape = new Shape3D(this.contour, this.holes);
    this.scene.add(this.shape.object);
  }

  createSphere(color, radius) {
    const geometry = new SphereGeometry(radius, 16, 16);
    return new Mesh(geometry, materialSphere);
  }

  getDistance(p1, p2) {
    return Math.sqrt(
      (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2 + (p1.z - p2.z) ** 2
    );
  }
  isClosingLine(nextPoint) {
    const threshold = 0.05;
    this.closingLine = false;
    const d1 = this.getDistance(nextPoint, this.points[this.points.length - 1]);
    const d2 = this.getDistance(nextPoint, this.points[0]);
    if (d2 / d1 <= threshold) {
      this.closingLine = true;
    }
  }

  handleClosingLine() {
    console.log("closing line");
    if (!this.drawingHoles) {
      this.contour = [...this.points];
      this.addShape();
      this.drawingHoles = true;
      this.onClosingLine({contour: this.points, area: this.shape.area});
    } else {
      this.holes.push([...this.points]);
      this.updateShapeWithHoles();
      this.onClosingLine({hole: this.points, area: this.shape.area});
    }
    this.shapesCount += 1;
    this.objects.push(this.object);

    this.points = [];
    this.nextPointIndex = 1;
    this.closingLine = false;
    this.closingPointPosition = undefined; // coordinates of closing points
    this.stop = false;
    this.object = this.initializePolygon();
  }

  addPoint(p) {
    if (!this.stop) {
      let point;
      if (this.closingLine) {
        point = this.points[0];
        //this.stop = true;
      } else {
        point = p;
      }
      const pointsCount = this.points.length;
      const index = pointsCount === 0 ? 0 : 3 * pointsCount;
      const positions = this.object.geometry.attributes.position.array;
      positions[index] = point.x;
      positions[index + 1] = point.y;
      positions[index + 2] = point.z;
      this.object.geometry.setDrawRange(0, pointsCount + 1); // new Point
      this.object.geometry.attributes.position.needsUpdate = true;
      this.points.push(point);

      if (this.closingLine) {
        // callback
        this.handleClosingLine();
      }
      return point;
    }
  }

  drawNextPoint(point) {
    const pointsCount = this.points.length;

    if (pointsCount > 0 && !this.stop) {
      this.isClosingLine(point);

      const index = pointsCount === 0 ? 0 : 3 * pointsCount;
      const positions = this.object.geometry.attributes.position.array;
      positions[index] = point.x;
      positions[index + 1] = point.y;
      positions[index + 2] = point.z + 1;
      this.object.geometry.attributes.position.needsUpdate = true;

      if (this.nextPointIndex === pointsCount) {
        this.nextPointIndex += 1;
        this.object.geometry.setDrawRange(0, pointsCount + 1); // we add the next point
      }

      if (this.closingLine) {
        this.object.material = this.closingMaterial;
      } else {
        this.object.material = this.material;
      }
    }
  }

  clear() {
    try {
      this.points = [];
      if (this.object) this.scene.remove(this.object);
      this.objects.forEach((object) => this.scene.remove(object));
      if (this.shape) this.scene.remove(this.shape.object);
    } catch (e) {
      console.log(e);
    }
  }
}
export default Drawer;
