export default function getTemplateFromElementTypeName(name) {
  if (name.includes("larg. cm")) {
    return "dim1 cm";
  } else if (name.includes("ép. cm")) {
    return "height cm";
  } else if (name.includes("LxH cm")) {
    return "dim1xheight cm";
  } else if (name.includes("ht. m")) {
    return "height m";
  } else if (name.includes("escaliers")) {
    return "stairs";
  } else if (name.includes("diam. cm")) {
    return "diam cm";
  } else if (name.includes("d1xd2 cm")) {
    return "dim1xdim2 cm";
  } else if (name.includes("d1xL cm")) {
    return "dim1xL cm";
  } else if (name.includes("(béton)") || name === "Béton") {
    return "p3";
  } else if (name.includes("(par localisation)")) {
    return "p0";
  } else if (name.includes("(HA)")) {
    return "HA";
  } else if (name.includes("(TS)")) {
    return "TS";
  }
}
