import Color from "color";

export default class MeasurementsExtractor {
  rawMeasurements;
  constructor({webViewer}) {
    this.webViewer = webViewer;
    this.annotations = [];
  }

  getAnnotationColor(annot) {
    const {R, G, B} = annot.Color;
    const colorObject = Color({r: R, g: G, b: B});
    return colorObject.hex();
  }

  extractRawMeasurements(withoutAnnotations) {
    const {annotationManager} = this.webViewer.Core;
    const annots = annotationManager.getAnnotationsList();
    this.rawMeasurements = annots.map((a) =>
      this.annotationToRawMeasurement(a, withoutAnnotations)
    );
    this.annotations = annots;
  }

  elementNameToDrawingShape(e) {
    const map = {
      line: "SEGMENT",
      polyline: "POLYLINE",
      polygon: "POLYGON",
      square: "RECTANGLE",
      circle: "CIRCLE",
    };
    return map[e];
  }

  annotationToRawMeasurement(annot, withoutAnnotation) {
    const color = this.getAnnotationColor(annot);
    const subject = annot.Subject + "_" + annot.elementName;
    const id = annot.Id;
    const drawingShape = this.elementNameToDrawingShape(annot.elementName);
    //const isVoid = annot.Style === "dash";
    const dotted = annot.Style === "dash";
    const author = annot.Author + "_" + annot.elementName;
    const annotation = annot;
    const rawMeasurement = {
      id,
      author,
      subject,
      color,
      drawingShape,
      dotted,
      elementName: annot.elementName,
    };
    if (!withoutAnnotation) rawMeasurement.annotation = annotation;
    return rawMeasurement;
  }

  getRawMeasurementsByAuthor() {
    const rawMs = this.rawMeasurements;
    const byA = {};
    rawMs.forEach((rawM) => {
      if (!byA[rawM.author]) {
        byA[rawM.author] = [rawM];
      } else {
        byA[rawM.author].push(rawM);
      }
    });
    return byA;
  }

  getRawMeasurementsByAuthorOverview() {
    const byS = this.getRawMeasurementsByAuthor();
    const arr = Object.entries(byS).map(([author, rawMs]) => {
      return {author, count: rawMs.length, name: author ? author : "-"};
    });
    return arr.sort((a, b) => a.name.localeCompare(b.name));
  }

  getAuthorRawMeasurementsBySubject(author, nameFrom) {
    const rawMs = this.rawMeasurements.filter(
      (m) => !author || m.author === author
    );
    const byS = {};
    rawMs.forEach((rawM) => {
      const subject = nameFrom === "author" ? rawM.author : rawM.subject;
      if (!byS[subject]) {
        byS[subject] = [rawM];
      } else {
        byS[subject].push(rawM);
      }
    });
    return byS;
  }

  getAuthorRawMeasurementsBySubjectOverview(author, nameFrom) {
    // nameFrom : to tell if the subject is the subject of the author.
    // author : to filter
    const byS = this.getAuthorRawMeasurementsBySubject(author, nameFrom);
    const arr = Object.entries(byS).map(([rawTypeSubject, rawMs], index) => {
      const subject = rawMs[0].subject ?? "-?-";
      const author = rawMs[0].author ?? "-?-";
      const rawName = nameFrom === "author" ? author : subject;
      return {
        subject,
        author,
        rawName,
        rawId: index,
        rawCount: rawMs.length,
        rawColor: rawMs[0].color,
        rawDrawingShape: rawMs[0].drawingShape,
        //isVoid: rawMs[0].isVoid,
        rawDotted: rawMs[0].dotted,
        rawElementName: rawMs[0].elementName,
      };
    });
    return arr.sort((a, b) => a.rawName.localeCompare(b.rawName));
  }
}
