import {Box, Typography} from "@mui/material";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import ChipFilterOverview from "./ChipFilterOverview";

export default function SectionFilterTitle({tab}) {
  const nameMap = useSceneModuleNameMap();
  const name = nameMap[tab] ? nameMap[tab] : "-?-";
  //const title = "Filtrer sur les " + name.toLowerCase();
  const title = name;
  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "background.default",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <ChipFilterOverview tab={tab} />
    </Box>
  );
}
