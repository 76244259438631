import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import {Launch} from "@mui/icons-material";

import FormElementType from "./FormElementType";
import FormElementTypeFast from "./FormElementTypeFast";
import {
  setOpenCreateElementTypeFastDialog,
  setNewElementType,
} from "../elementTypesSlice";
import useAddSceneElementType from "../hooks/useAddSceneElementType";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

import {
  updateTempMeasurementFromElementType,
  setSelectedElementTypeGroup,
} from "Features/measurements/measurementsSlice";
import theme from "Styles/theme";
import SelectorAutocomplete from "Components/SelectorAutocomplete";

export default function DialogCreateElementTypeFast({
  scene,
  initElementType = {},
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Quel type d'élément voulez vous repérer ?";
  const title2 = "Nouveau type";

  const openModuleD = "Pour plus d'options :";
  const openModuleS = "mode avancé";

  const cancelS = "Annuler";
  const createS = "Ajouter";

  const copyTypeS = "Depuis un type existant";

  // data

  const open = useSelector(
    (s) => s.elementTypes.openCreateElementTypeFastDialog
  );
  const elementTypes = useElementTypesBySceneProxy(scene);

  const addSceneElementType = useAddSceneElementType(scene);

  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );

  const newElementType = useSelector((s) => s.elementTypes.newElementType);

  // state

  const [elementType, setElementType] = useState(initElementType);
  useEffect(() => {
    setElementType(initElementType);
  }, [selectedRessourceId]);

  const [advancedMode, setAdvancedMode] = useState(false);

  // helper - groups

  const groups = [...new Set(elementTypes.map((t) => t.group))];

  // handlers

  function handleElementTypeChange(type) {
    setElementType(type);
    dispatch(setSelectedElementTypeGroup(type.group));
  }
  function handleClose() {
    dispatch(setOpenCreateElementTypeFastDialog(false));
    setAdvancedMode(false);
  }
  function handleCancel() {
    setElementType({});
    dispatch(setOpenCreateElementTypeFastDialog(false));
    setAdvancedMode(false);
  }
  function handleSave() {
    if (!elementType.group) elementType.group = "A définir";
    const maxNum = (
      elementTypes.filter((t) => t.group === elementType.group).length + 1
    ).toString();
    const type = {
      ...elementType,
      dim1: elementType.dim1 ? parseFloat(elementType.dim1) : null,
      dim2: elementType.dim2 ? parseFloat(elementType.dim2) : null,
      height: elementType.height ? parseFloat(elementType.height) : null,
      num: elementType.num ? elementType.num : maxNum, // "0",
      code: elementType.code ? elementType.code : maxNum,
      id: nanoid(),
    };
    addSceneElementType(type);
    dispatch(setOpenCreateElementTypeFastDialog(false));
    setAdvancedMode(false);
    //dispatch(setSelectedElementTypeId(type.id));
    dispatch(setSelectedElementTypeGroup(type.group));
    dispatch(updateTempMeasurementFromElementType(type));
  }

  function handleOpenModuleClick() {
    setAdvancedMode(true);
  }

  function handleNewElementTypeChange(type) {
    if (!type) type = {};
    dispatch(setNewElementType(type));
    setElementType(type);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <DialogTitle>{advancedMode ? title2 : title}</DialogTitle>
      <DialogContent>
        {!advancedMode && (
          <FormElementTypeFast
            elementType={elementType}
            elementTypes={elementTypes}
            groups={groups}
            onChange={handleElementTypeChange}
          />
        )}
        {advancedMode && (
          <Box
            sx={{backgroundColor: theme.palette.background.default, padding: 2}}
          >
            <SelectorAutocomplete
              name={copyTypeS}
              options={elementTypes}
              onChange={handleNewElementTypeChange}
            />
          </Box>
        )}
        {advancedMode && (
          <Box sx={{height: 500}}>
            <FormElementType
              scene={scene}
              elementType={newElementType}
              elementTypes={elementTypes}
              onChange={handleNewElementTypeChange}
              canEdit={true}
            />
          </Box>
        )}
        <Box sx={{p: 2}} />
      </DialogContent>
      <DialogActions>
        <Box sx={{pr: 4}}>
          {!advancedMode && (
            <Box
              sx={{
                color: "text.secondary",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: 1,
                p: 1,
                //bgcolor: "background.default",
                borderRadius: "4px",
              }}
            >
              <Typography variant="body2" color="inherit">
                {openModuleD}
              </Typography>
              <Button
                startIcon={<Launch />}
                size="small"
                color="inherit"
                sx={{ml: 2}}
                onClick={handleOpenModuleClick}
              >
                {openModuleS}
              </Button>
            </Box>
          )}
        </Box>
        <Button variant="outlined" onClick={handleCancel}>
          {cancelS}
        </Button>
        <Button variant="contained" onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
