import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Button,
  DialogActions,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
export default function DialogRenamePackage({open, model, onClose}) {
  const dispatch = useDispatch();
  const {t} = useTranslation(["packages", "common"]);

  // string

  const dialogTitleString = t("packages:renamePackage");
  const saveButton = capitalizeStart(t("common:save"));

  // local state

  const [newName, setNewName] = useState(model?.name);

  // handlers

  function handleNewNameChange(e) {
    setNewName(e.target.value);
  }

  function handleRenameSave() {
    const updates = {...model, name: newName};
    dispatch(updateModel({updatedModel: updates, sync: true}));
    onClose();
  }

  // update newName

  useEffect(() => {
    if (model?.name) setNewName(model.name);
  }, [model?.name]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{dialogTitleString} </DialogTitle>
      <DialogContent>
        <InputBase
          defaultValue={model?.name}
          onChange={handleNewNameChange}
          sx={{minWidth: 350, maxWidth: 450}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameSave}>{saveButton}</Button>
      </DialogActions>
    </Dialog>
  );
}
