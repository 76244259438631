import {useState} from "react";

import {Button, Box} from "@mui/material";

import DialogLoadMarkers from "./DialogLoadMarkers";

export default function ButtonLoadMarkers({editor3d}) {
  //Strings

  const loadS = "Import";

  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  return (
    <Box>
      <Button onClick={handleClick} size="small" variant="contained">
        {loadS}
      </Button>
      <DialogLoadMarkers
        open={open}
        onClose={handleClose}
        editor3d={editor3d}
      />
    </Box>
  );
}
