export default function getNomenclatureItemChildren(
  itemId,
  nomenclature,
  options
) {
  // options

  const numField = options?.numField || "num";

  //

  const children = [];

  // data tranformation

  const itemsByIdWithIndex = nomenclature.reduce((acc, item, index) => {
    acc[item.id] = {...item, index};
    return acc;
  }, {});

  // get item

  const item = itemsByIdWithIndex[itemId];

  // get children

  let childrenRank;
  let stop = false;
  let nextIndex = item.index + 1;
  const itemRank = item[numField].split(".").length;
  let i = 0;
  let limit = 100;
  while (!stop && i < limit) {
    const nextItem = nomenclature[nextIndex + i];
    const nextItemRank = nextItem[numField].split(".").length;
    if (!childrenRank && nextItemRank > itemRank) {
      childrenRank = nextItemRank;
      children.push(nextItem);
    } else if (childrenRank && nextItemRank < childrenRank) {
      stop = true;
    } else if (childrenRank && nextItemRank > childrenRank) {
      stop = true;
    } else if (childrenRank && nextItemRank === itemRank) {
      children.push(nextItem);
    } else if (!childrenRank && nextItemRank <= itemRank) {
      stop = true;
    } else {
      stop = true;
    }
    i++;
  }

  return children;
}
