import {Vector3} from "three";

export default function angleFromU2V(u, v, norm) {
  const dotUV = u.dot(v);
  const lengthU = u.length();
  const lengthV = v.length();

  const cross = new Vector3();
  cross.crossVectors(u, v);

  let angle = Math.acos(dotUV / (lengthU * lengthV));

  const dotNorm = norm.dot(cross);
  if (dotNorm < 0) {
    angle = -angle;
  }
  return angle;
}
