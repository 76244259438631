import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setAutoSync} from "Features/scenes/scenesSlice";

import {Box, FormControlLabel, Checkbox, Typography} from "@mui/material";

export default function CheckboxAutoSync() {
  const dispatch = useDispatch();

  // string

  const autoSyncS = "Auto sync";

  // data

  const checked = useSelector((s) => s.scenes.autoSync);

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    dispatch(setAutoSync(checked));
  }

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={checked}
            onChange={handleCheckChange}
          />
        }
        label={<Typography variant="body2">{autoSyncS}</Typography>}
      />
    </Box>
  );
}
