import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import ContentEditor from "./ContentEditor";
import TitleEditor from "./TitleEditor";
import NoteHeader from "./NoteHeader";

import {postNote} from "Features/notes/notesSlice";
import {updateNote} from "Features/note/noteSlice";
import useAccessToken from "Features/auth/useAccessToken";

const defaultContent = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];

export default function NoteEditor({
  initialNote,
  listingId,
  noteId,
  onSaveSucess,
  caplaEditor,
  editing,
  onDeleteNote,
  onEditNote,
  sceneClientId,
  viewer,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // is new ?

  const isNew = !Boolean(noteId);

  // initial data

  const initialContent = initialNote?.data
    ? JSON.parse(initialNote.data)
    : defaultContent;

  const initialTitle = initialNote?.title ? initialNote.title : "";

  const initialNoteElements = remoteToStateElements(
    initialNote?.relationsElements
  );

  // data

  const tempNoteElements = useSelector((s) => s.note.tempNoteElements);

  // local state

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [content, setContent] = useState(initialContent);
  // const [linkedElements, setLinkedElements] = useState(initialNoteElements);

  // updates

  useEffect(() => {
    console.log("effect");
    setTitle(initialTitle);
    setContent(initialContent);
  }, [initialTitle]);

  // helper

  function stateToRemoteElements(elements) {
    if (elements) {
      const remoteElements = [];
      Object.entries(elements).forEach(([key, value]) => {
        remoteElements.push({
          sceneModel: key,
          elements: value,
        });
      });

      return remoteElements;
    }
  }

  function remoteToStateElements(elts) {
    if (Array.isArray(elts)) {
      const stateElements = {};
      elts.forEach(({elements, sceneModel}) => {
        stateElements[sceneModel] = elements;
      });
      return stateElements;
    }
  }

  // handlers

  function handleContentChange(newContent) {
    setContent(newContent);
  }

  async function handleNoteSave() {
    const relationsElements = stateToRemoteElements(tempNoteElements);
    const note = {
      title,
      data: JSON.stringify(content),
      relationsElements,
    };
    setLoading(true);
    if (isNew) {
      await dispatch(postNote({note, listingId, accessToken}));
    } else {
      await dispatch(updateNote({accessToken, listingId, noteId, note}));
    }
    setLoading(false);
    onSaveSucess();
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        minHeight: 0,
        flexDirection: "column",
        width: 1,
        position: "relative",
      }}
    >
      {!editing && !viewer && (
        <Box
          sx={{
            position: "absolute",
            right: (theme) => theme.spacing(0.5),
            top: (theme) => theme.spacing(0.5),
            zIndex: 2,
          }}
        >
          <NoteHeader onDelete={onDeleteNote} onEdit={onEditNote} />
        </Box>
      )}
      <TitleEditor value={title} onChange={setTitle} editing={editing} />
      <ContentEditor
        loading={loading}
        initialValue={content}
        listingId={listingId}
        accessToken={accessToken}
        onSaveClick={handleNoteSave}
        onChange={handleContentChange}
        caplaEditor={caplaEditor}
        isNew={isNew}
        editing={editing}
        sceneClientId={sceneClientId}
        initialNoteElements={initialNoteElements}
        viewer={viewer}
      />
    </Box>
  );
}
