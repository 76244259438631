import React, {useEffect} from "react";

import {Box, Typography} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";

import {useDispatch, useSelector} from "react-redux";
import {updateGroupSelectionMap} from "Features/library/librarySlice";

import IconElementType from "Features/elementTypes/components/IconElementType";

import {DataGrid, frFR} from "@mui/x-data-grid";

import useTranslation from "Features/translations/useTranslation";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridElementTypes({elementTypes}) {
  const {i18n} = useTranslation();
  const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  const idS = "ID";
  const numS = "Num";
  const groupS = "Group";
  const codeS = "Code";
  const colorS = "Color";
  const nameS = "Name";
  const shapeS = "Shape";
  const unitS = "Unit";
  const style3DS = "Style 3D";
  const ressourcesS = "Art.";

  // data

  const groupSelectionMap = useSelector((s) => s.library.groupSelectionMap);
  const selectedGroup = useSelector((s) => s.library.selectedGroup);

  // grid - rows

  const rows = elementTypes;

  // grid - columns

  const columns = [
    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
    {
      field: "code",
      headerName: codeS,
      width: 100,
    },
    {
      field: "drawingShape",
      headerName: shapeS,
      width: 80,
      renderCell: (params) => (
        <Box>
          {!params.row.isTitle && !params.row.isAgg && (
            <IconElementType
              color={params.row.color}
              drawingShape={params.value}
              isVoid={params.row.isVoid}
            />
          )}
        </Box>
      ),
    },
    {
      field: "unit",
      headerName: unitS,
      width: 80,
    },
  ];

  // helpers - selection

  let selection = groupSelectionMap[selectedGroup];
  if (!selection) selection = [];

  // handlers

  function handleSelectionChange(selection) {
    dispatch(updateGroupSelectionMap({group: selectedGroup, selection}));
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--Disabled": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        checkboxSelection={true}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selection}
        getRowClassName={(params) => {
          if (params.row.isTitle) return `super-app-theme--Title`;
          if (params.row.parent) return `super-app-theme--Child`;
          if (params.row.disabled) return `super-app-theme--Disabled`;
        }}
      />
    </Box>
  );
}
