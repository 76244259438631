import {
  Box,
  FormControLabel,
  Switch,
  Button,
  FormControlLabel,
  Typography,
} from "@mui/material";

export default function SectionArticleCoreTypesManagement({
  article,
  showCoreTypes,
  onShowCoreTypesChange,
  onSaveAsCore,
}) {
  // strings

  const showCoreTypesStr = "Données sources";
  const saveS = "Enregistrer comme données sources";

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    onShowCoreTypesChange(checked);
  }

  function handleSaveAsCore() {
    if (onSaveAsCore) onSaveAsCore();
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 1,
        bgcolor: "background.default",
      }}
    >
      <Box sx={{pl: 1}}>
        <FormControlLabel
          control={
            <Switch checked={showCoreTypes} onChange={handleCheckChange} />
          }
          label={
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {showCoreTypesStr}
            </Typography>
          }
        />
      </Box>
      {!showCoreTypes && (
        <Box sx={{px: 1, color: "text.secondary"}}>
          <Button size="small" onClick={handleSaveAsCore} color="inherit">
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
