import React from "react";

import {Box} from "@mui/material";

import ToolbarDefault from "./ToolbarDefault";

export default function LayerDefault({editor, scene}) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "16px",
        left: "50%",
        transform: "translate(-50%)",
        zIndex: 50,
      }}
    >
      <ToolbarDefault editor={editor} scene={scene} />
    </Box>
  );
}
