import {client} from "API/capla360";
import cleanRoomsGroupToSync from "./utils/cleanRoomsGroupToSync";

// CREATE

export const createRoomsGroupService = async ({
  accessToken,
  roomsGroup,
  fromScopeId,
}) => {
  const sceneId = roomsGroup.sceneId;
  const serializedRoomsGroup = {
    ...roomsGroup,
    scene: sceneId,
  };
  delete serializedRoomsGroup.sceneId;

  const response = await client.post(
    `scenes/${sceneId}/roomsgroups/`,
    serializedRoomsGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        fromScope: fromScopeId,
      },
    }
  );
  return {item: response.data, fromScopeId};
};

export const createRoomService = async ({accessToken, room}) => {
  const roomsGroupId = room.roomsGroupId;
  const serializedRoomsGroup = {
    ...room,
    roomsGroup: roomsGroupId,
  };
  delete serializedRoomsGroup.roomsGroupId;

  const response = await client.post(
    `roomsgroups/${roomsGroupId}/rooms/`,
    serializedRoomsGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

//  FETCH

export const fetchRoomsGroupsService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/roomsgroups/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export const fetchRoomsGroupService = async ({
  accessToken,
  sceneId,
  roomsGroupId,
}) => {
  const response = await client.get(
    `/scenes/${sceneId}/rooms_groups/${roomsGroupId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {items: response.data, sceneId};
};

//  UPDATE

export const updateRoomsGroupService = async ({accessToken, updates}) => {
  const sceneId = updates.sceneId;
  const cleanUpdates = cleanRoomsGroupToSync(updates);

  const response = await client.patch(
    `/scenes/${sceneId}/roomsgroups/${updates.id}/`,
    cleanUpdates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

export const updateRoomService = async ({accessToken, room}) => {
  const roomsGroupId = room.roomsGroupId;
  const cleanRoomsGroup = cleanRoomsGroupToSync(room);
  console.log("cleanRoomsGroup", cleanRoomsGroup);
  const response = await client.patch(
    `/roomsgroups/${roomsGroupId}/rooms/${room.id}/`,
    cleanRoomsGroup,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data};
};

//  DELETE

export const deleteRoomsGroupService = async ({accessToken, roomsGroup}) => {
  const roomsGroupId = roomsGroup.id;
  const sceneId = roomsGroup.sceneId;
  console.log("try to delete typesRoomsGroup", sceneId, roomsGroup);
  await client.delete(`/scenes/${sceneId}/roomsgroups/${roomsGroupId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {roomsGroupId};
};

export const deleteRoomService = async ({accessToken, room}) => {
  const roomsGroupId = room.roomsGroupId;
  const roomId = room.id;
  await client.delete(`/roomsgroups/${roomsGroupId}/rooms/${roomId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {roomId};
};
