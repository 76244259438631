import {nanoid} from "nanoid";
import caplaDB, {FILE_DO} from "../db";
import {blobToArrayBuffer, arrayBufferToBlob} from "../utils";

//  create

export async function createStoredFile({
  file,
  resolution = "ORIGINAL",
  sceneClientId,
}) {
  try {
    if (file) {
      const {name, size, type, lastModified} = file;
      const arrayBuffer = await blobToArrayBuffer(file);
      const storedFile = {
        clientId: nanoid(),
        name,
        size,
        type,
        lastModified,
        arrayBuffer,
        resolution,
        sceneClientId,
      };

      const db = await caplaDB.dbPromise;
      const tx = db.transaction(FILE_DO, "readwrite");
      const store = tx.objectStore(FILE_DO);
      store.add(storedFile);
      return storedFile;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
  }
}

//  fetch

export async function fetchStoredFile(clientId) {
  console.log("fetch file", clientId);
  // console.trace();
  try {
    if (clientId) {
      const db = await caplaDB.dbPromise;
      const store = db.transaction(FILE_DO, "readonly").objectStore(FILE_DO);
      const file = await store.get(clientId);
      if (file) {
        const blob = await arrayBufferToBlob(file.arrayBuffer, file?.type);
        const storedFile = new File([blob], file?.name);
        return storedFile;
      } else {
        return null;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

// delete

export async function deleteStoredFile(clientId) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(FILE_DO, "readwrite").objectStore(FILE_DO);
    await store.delete(clientId);
  } catch (e) {
    console.log(e);
  }
}
