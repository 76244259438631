import {useDispatch} from "react-redux";
import {nanoid} from "nanoid";

import {addElementTypeToEditedElementTypesGroup} from "../elementTypesSlice";

import useSelectedEditedElementType from "../hooks/useSelectedEditedElementType";

import {Menu, MenuItem} from "@mui/material";

import getNextNum from "Utils/getNextNum";

export default function DatagridElementTypesContextMenu({
  scene,
  open,
  onClose,
  anchorPosition,
  nums,
}) {
  const dispatch = useDispatch();

  // strings

  const duplicateS = "Dupliquer";
  const insertS = "Insérer un type";

  // data

  const selectedEditedElementType = useSelectedEditedElementType();

  // handlers

  function handleDuplicate() {
    const duplicatedElementType = {...selectedEditedElementType};
    duplicatedElementType.id = nanoid();
    duplicatedElementType.name = `${selectedEditedElementType.name} (copie)`;
    dispatch(addElementTypeToEditedElementTypesGroup(duplicatedElementType));
    // end
    onClose();
  }

  function handleInsert() {
    const num = selectedEditedElementType?.num;
    const nextNum = getNextNum(num, nums);
    const newElementType = {
      id: nanoid(),
      groupId: selectedEditedElementType.groupId,
      num: nextNum,
      code: nextNum,
      name: "",
    };
    dispatch(addElementTypeToEditedElementTypesGroup(newElementType));
    // end
    onClose();
  }

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            onClose();
          },
        },
      }}
    >
      <MenuItem onClick={handleInsert}>{insertS}</MenuItem>
      <MenuItem onClick={handleDuplicate}>{duplicateS}</MenuItem>
    </Menu>
  );
}
