import {createSlice} from "@reduxjs/toolkit";

export const inputsSlice = createSlice({
  name: "inputs",
  initialState: {
    selectedInputId: null,
    tempInput: {}, // {fileUrl,fileName,name}
  },
  reducers: {
    setSelectedInputId: (state, action) => {
      state.selectedInputId = action.payload;
    },
    setTempInput: (state, action) => {
      state.tempInput = action.payload;
    },
  },
});

export const {setSelectedInputId, setTempInput} = inputsSlice.actions;

export default inputsSlice.reducer;
