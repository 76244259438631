import React from "react";

import useRessourcesByScene from "../hooks/useRessourcesByScene";

import ButtonDownloadExcelFromTemplate from "Features/excel/components/ButtonDownloadExcelFromTemplate";

export default function ButtonDownloadExcelRessources({scene}) {
  // data

  const ressources = useRessourcesByScene(scene);

  // helpers

  const fileName = "dpgf.xlsx";

  return (
    <ButtonDownloadExcelFromTemplate
      template={"RESSOURCES"}
      data={ressources}
      fileName={fileName}
    />
  );
}
