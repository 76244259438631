import {useEffect} from "react";
import {useOutletContext} from "react-router-dom";
import {useDispatch} from "react-redux";

import {Box} from "@mui/material";

import DataGridBimapps from "./DataGridBimapps";
import SectionTitleBimapps from "./SectionTitleBimapps";

import {fetchBimapps} from "../bimappsSlice";

import useAccessToken from "Features/auth/useAccessToken";
import Stretch from "Features/ui/components/Stretch";

export default function PageBimapps() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // params

  const {scene} = useOutletContext();

  // init

  useEffect(() => {
    if (scene?.id && accessToken) {
      dispatch(fetchBimapps({sceneId: scene.id, accessToken}));
    }
  }, [scene?.id, accessToken]);

  return (
    <Box sx={{width: 1}}>
      <Box sx={{height: "60px"}} />
      <SectionTitleBimapps />

      <Stretch>
        <DataGridBimapps scene={scene} />
      </Stretch>
    </Box>
  );
}
