import React from "react";

import {Box, Typography, Tooltip} from "@mui/material";
export default function HeightLabel({children, value}) {
  return (
    <Tooltip placement="left" title={value}>
      {children}
    </Tooltip>
  );
}
