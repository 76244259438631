import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setOpen} from "Features/navigation/navigationSlice";

import {Box, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import zoomInOut from "Features/ui/zoomInOut";

export default function ButtonCloseMenu() {
  const dispatch = useDispatch();

  const open = useSelector((s) => s.navigation.open);

  const animate = useSelector((s) => s.ui.animateCloseNavPanel);

  function handleMenuClick() {
    dispatch(setOpen(false));
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "text.secondary",
      }}
    >
      {open && (
        <IconButton
          size="small"
          onClick={handleMenuClick}
          color="inherit"
          sx={{
            ...(animate && {
              borderColor: "primary.flash",
              borderStyle: "solid",
              borderWidth: "3px",
              animation: `${zoomInOut} 1s infinite ease`,
            }),
          }}
        >
          <Close fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
