import {Autocomplete, TextField, Typography, Box} from "@mui/material";

export default function SelectorMeasurement({
  measurement,
  measurements,
  onChange,
  types,
}) {
  // options

  const options = measurements
    .map((m, id) => {
      const t = types.find((t) => t.id === m.elementTypeId);
      if (m) {
        return {...m, name2: `${t?.name} • ${m.codeName}`};
      } else {
        return {id, name2: "-?-"};
      }
    })
    .sort((a, b) => a.name2.localeCompare(b.name2));

  // handlers

  function handleMeasurementChange(measurement) {
    onChange(measurement);
  }

  return (
    <Box sx={{width: 1}}>
      <Autocomplete
        //sx={{flexGrow: 1}}
        fullWidth
        options={options}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => `${option?.codeName}`}
        renderOption={(props, option) => (
          <Typography {...props} variant="body2" sx={{pl: 1}}>
            {option.name2}
          </Typography>
        )}
        value={measurement ? measurement : null}
        onChange={(e, v) => handleMeasurementChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            //label={<Typography variant="body2">{modelsString}</Typography>}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
              },
            }}
          />
        )}
      />
    </Box>
  );
}
