import {useState} from "react";
// import {useNavigate} from "react-router-dom";

import {Box, Button, Typography} from "@mui/material";
import {Add, Layers} from "@mui/icons-material";

// import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";
import DialogCreateMeasurementsModel from "Features/measurements/components/DialogCreateMeasurementsModel";

export default function CardCreatePackage({scene, editor3d}) {
  // const navigate = useNavigate();
  // const sceneId = scene?.id ? scene.id : scene?.clientId;

  // strings

  const title = "Calques de repérage";
  const description = `Repérez sur les fonds de plans du PDF des élements : objets, zones, pièces,...
     Vous pouvez créer plusieurs repérages par PDF.`;

  const createS = "Créer un repérage";

  // data

  // const pathMap = useSceneModulePathMap(sceneId);

  // state

  const [open, setOpen] = useState(false);

  // helper

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    //navigate(pathMap["ZONES"]);
  }

  return (
    <Box
      sx={{
        width: 1,
        background,
        color: "common.white",
        p: 2,
        borderRadius: "4px",
      }}
    >
      <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
        <Layers />
        <Typography variant="h6" sx={{ml: 2}}>
          {title}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
        {description}
      </Typography>

      <Box sx={{mt: 2}}>
        <Button
          size="small"
          startIcon={<Add />}
          onClick={handleClick}
          color="inherit"
          variant="outlined"
        >
          {createS}
        </Button>
      </Box>

      <DialogCreateMeasurementsModel
        editor3d={editor3d}
        scene={scene}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
}
