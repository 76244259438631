import {Raycaster, Vector3} from "three";

import {
  updateClippingPlanesStatus,
  disableAllClippingPlanes,
} from "Features/scenes/scenesSlice";
import {
  updateModel,
  updateIfcStoreys,
  updateIfcTypes,
} from "Features/viewer3D/viewer3DSlice";

export default class Context {
  constructor({editor3d, dispatch, sceneClientId}) {
    this.editor3d = editor3d;
    this.dispatch = dispatch;
    this.sceneClientId = sceneClientId;
    this.raycaster = new Raycaster(new Vector3(), new Vector3(0, 1, 0), 0, 20);
    this.imageResoAutoUpdate = false;
  }

  getContext({models}) {
    // camera
    // const camera = this.editor3d?.cameras.getActiveCameraProps(
    //   this.editor3d?.controls.activeControls
    // );

    // visible models

    const visibleModelsIds = models.filter((m) => !m.hidden).map((m) => m.id);

    // clipping planes
    const activeClippingPlanesIds = this.editor3d?.clipper?.activeClippingPlanes
      ?.filter((cp) => Boolean(cp))
      .map((cp) => cp.id);
    return {activeClippingPlanesIds, visibleModelsIds};
  }

  setContext({context, models}) {
    try {
      // visible models

      if (Array.isArray(context?.visibleModelsIds)) {
        models.forEach((model) => {
          const entity = this.editor3d?.getEntity(model.id);
          if (!model.hidden && !context.visibleModelsIds.includes(model.id)) {
            entity.hide();
            const newModel = {...model, hidden: true};
            this.dispatch(updateModel({updatedModel: newModel, sync: false}));
          }
          if (model.hidden && context.visibleModelsIds.includes(model.id)) {
            entity.show();
            const newModel = {...model, hidden: false};
            this.dispatch(updateModel({updatedModel: newModel, sync: false}));
          }
        });
      }

      // clipping planes
      if (Array.isArray(context?.activeClippingPlanesIds)) {
        this.editor3d?.clipper.setActiveClippingPlanesFromIds(
          context?.activeClippingPlanesIds
        );
        this.dispatch(disableAllClippingPlanes());
        context?.activeClippingPlanesIds.forEach((id) => {
          this.dispatch(
            updateClippingPlanesStatus({clippingPlaneId: id, disabled: false})
          );
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // TO DO : remove
  apply(current, target) {
    try {
      const {models} = current;
      const {
        hiddenModelsIds,
        ifcStoreys: targetIfcStoreys,
        ifcTypes: targetIfcTypes,
      } = target;
      models.forEach((model) => {
        const entity = this.editor3d?.getEntity(model.id);

        // model visibility
        if (
          model.hidden &&
          !hiddenModelsIds?.includes(model.id) &&
          entity.show
        ) {
          entity.show();
          const newModel = {...model, hidden: false};
          this.dispatch(updateModel({updatedModel: newModel, sync: false}));
        } else if (
          !model.hidden &&
          hiddenModelsIds?.includes(model.id) &&
          entity.hide
        ) {
          entity.hide();
          const newModel = {...model, hidden: true};
          this.dispatch(updateModel({updatedModel: newModel, sync: false}));
        }

        // filters
        if (entity && entity?.type === "IFC_MODEL" && entity.object.visible) {
          // filters should not be applied if the model is not displayed
          entity.applyFilters(targetIfcStoreys, targetIfcTypes);
          this.dispatch(updateIfcStoreys(targetIfcStoreys));
          this.dispatch(updateIfcTypes(targetIfcTypes));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateImagesReso() {
    // images
    const imageEntities = this.editor3d?.objectEntities.filter(
      (e) =>
        e.type === "IMAGE_ENTITY" &&
        e.model.sceneClientId === this.sceneClientId
    );

    const imageModels = this.editor3d?.entities.filter(
      (e) =>
        e.type === "IMAGE_MODEL" && e.model.sceneClientId === this.sceneClientId
    );
    //
    const position = new Vector3();
    const direction = new Vector3();
    position.copy(this.editor3d?.cameras.activeCamera.position);
    this.editor3d?.cameras.activeCamera.getWorldDirection(direction);
    this.raycaster.set(position, direction);
    const intersections = this.raycaster.intersectObjects(
      imageEntities.map((e) => e.object)
    );
    const imageEntity = this.editor3d?.getObjectEntityByObjectId(
      intersections[0]?.object.id
    );
    const imageModelId = imageEntity?.modelId;

    // update reso
    imageModels.forEach((e) => {
      if (e.entityID === imageModelId) {
        if (e.resolution === "LR") e.switchResolutionTo("HR");
      } else {
        if (e.resolution === "HR") e.switchResolutionTo("LR");
      }
    });
  }
}
