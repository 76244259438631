import {useSelector, useDispatch} from "react-redux";
import {useState, useEffect} from "react";
import {Box, Typography, Paper, Slider, IconButton} from "@mui/material";
import {Visibility, VisibilityOff, Refresh} from "@mui/icons-material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import ButtonComputeDifferenceNewOldPdf from "./ButtonComputeDifferenceNewOldPdf";
import {setSelectedPdfModelIdOld} from "../pdfSlice";
import PdfWebViewerInstance2 from "./PdfWebViewerInstance2";

import cv from "Features/images/js/serviceCV";

export default function SectionDifferenceNewOldPdf({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const oldS = "Avant";
  const diffS = "Différences";

  // data

  const inputs = useInputsByScene(scene);
  const selectedPdfModelIdOld = useSelector((s) => s.pdf.selectedPdfModelIdOld);
  const urlLoadedInInstance2 = useSelector((s) => s.pdf.urlLoadedInInstance2);

  // state

  const [oldImageUrl, setOldImageUrl] = useState(null);
  const [isComputingOldPdfUrl, setIsComputingOldPdfUrl] = useState(false);
  const [showOld, setShowOld] = useState(false);
  const [showDiff, setShowDiff] = useState(false);
  const [imageDiffUrl, setImageDiffUrl] = useState(null);

  // helper - oldPdfModel

  const oldPdfModel = inputs.find(
    (input) => input.id === selectedPdfModelIdOld
  );

  // helpers

  const oldPdfIsLoaded = urlLoadedInInstance2 === oldPdfModel?.url;

  // helper - disabled showOld

  const disableShowOld =
    isComputingOldPdfUrl ||
    !oldPdfIsLoaded ||
    !selectedPdfModelIdOld ||
    !oldImageUrl;

  // effect - update oldPdfAnnotation

  async function update() {
    setIsComputingOldPdfUrl(true);
    const {url, x, y, width, height, pageNumber} =
      await caplaEditor.editorPdf.getOldPdfImage();

    setIsComputingOldPdfUrl(false);
    await caplaEditor.editorPdf.annotationsManager.updateOldPdfAnnotation({
      imageUrl: url,
      x,
      y,
      width,
      height,
      pageNumber,
    });
    setOldImageUrl(url);
  }

  useEffect(() => {
    return () => {
      caplaEditor.editorPdf.annotationsManager.deleteOldPdfAndDifferenceAnnotations();
      setImageDiffUrl(null);
      setOldImageUrl(null);
      setIsComputingOldPdfUrl(false);
      setShowOld(false);
      setShowDiff(false);
    };
  }, []);
  //   useEffect(() => {
  //     if (!selectedPdfModelIdOld) {
  //       caplaEditor.editorPdf.annotationsManager.deleteOldPdfAnnotation();
  //     } else if (oldPdfIsLoaded) {
  //       update();
  //     }
  //   }, [selectedPdfModelIdOld, oldPdfIsLoaded]);

  // handlers

  async function handleOldPdfChange(input) {
    dispatch(setSelectedPdfModelIdOld(input?.id));
    if (!input) {
      setIsComputingOldPdfUrl(false);
    }
  }

  function handleVisibilityChange(mode) {
    if (mode === "old") {
      setShowOld(!showOld);
      if (showOld) {
        caplaEditor.editorPdf.annotationsManager.hideOldPdfAnnotation();
      } else {
        caplaEditor.editorPdf.annotationsManager.showOldPdfAnnotation();
      }
    } else if (mode === "diff") {
      setShowDiff(!showDiff);
      if (showDiff) {
        caplaEditor.editorPdf.annotationsManager.hideDifferenceAnnotation();
      } else {
        caplaEditor.editorPdf.annotationsManager.showDifferenceAnnotation();
      }
    }
  }

  // handlers - opacity

  function handleOldPdfOpacityChange(e) {
    const opacity = e.target.value;
    caplaEditor.editorPdf.annotationsManager.setOldPdfOpacity(opacity / 100);
  }

  // handler - refresh oldPDF

  function handleRefreshClick() {
    update();
  }

  // handler onDiffImage

  function handleDiffComputed(imageDiff) {
    setImageDiffUrl(imageDiff.url);
  }

  return (
    <Paper sx={{display: "flex", alignItems: "stretch"}}>
      <Box sx={{mr: 1, p: 1, width: 210}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <IconButton
              disabled={disableShowOld}
              sx={{mr: 1}}
              size="small"
              onClick={() => {
                handleVisibilityChange("old");
              }}
            >
              {showOld ? (
                <Visibility fontSize="small" />
              ) : (
                <VisibilityOff fontSize="small" />
              )}
            </IconButton>
            <Typography sx={{fontSize: 13}}>{oldS}</Typography>
          </Box>
          <IconButton
            disabled={!oldPdfIsLoaded}
            size="small"
            onClick={handleRefreshClick}
            sx={{
              "@keyframes spin": {
                from: {
                  transform: "rotate(0deg)",
                },
                to: {
                  transform: "rotate(360deg)",
                },
              },
              ...(isComputingOldPdfUrl && {
                animation: "spin 1s linear infinite",
              }),
            }}
          >
            <Refresh fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", px: 1}}>
          <SelectorAutocomplete
            options={inputs}
            value={oldPdfModel ?? null}
            onChange={handleOldPdfChange}
          />
          <Box
            sx={{
              width: "10px",
              height: "10px",
              bgcolor: "background.default",
              border: "1px solid grey",
              visibility: "hidden",
            }}
          >
            {caplaEditor && (
              <PdfWebViewerInstance2 scene={scene} caplaEditor={caplaEditor} />
            )}
          </Box>
        </Box>
        {/* <Box
          sx={{
            minWidth: 200,
            px: 1,
            pr: 2,
            display: "flex",
            alignItems: "center",
            width: 1,
          }}
        >
          <Slider
            onChange={handleOldPdfOpacityChange}
            size="small"
            disabled={disableShowOld}
          />
        </Box> */}
      </Box>
      <Box sx={{p: 1}}>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <IconButton
            disabled={!imageDiffUrl}
            sx={{mr: 1}}
            size="small"
            onClick={() => {
              handleVisibilityChange("diff");
            }}
          >
            {showDiff ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
          <Typography sx={{fontSize: 13}}>{diffS}</Typography>
        </Box>
        <ButtonComputeDifferenceNewOldPdf
          scene={scene}
          caplaEditor={caplaEditor}
          onDiffComputed={handleDiffComputed}
          disabled={!oldPdfIsLoaded}
        />
      </Box>
    </Paper>
  );
}
