import {useState} from "react";

import {FormControlLabel, Switch, Typography} from "@mui/material";

export default function SwitchBeta({editor3d, model}) {
  const labelString = "Transparent image";

  // helpers

  const entity = editor3d?.getEntity(model.id);
  let initialTransparent = true;
  if (entity) {
    initialTransparent = entity.isTransparent;
  }

  // state

  const [transparent, setTransparent] = useState(initialTransparent);

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    setTransparent(checked);
    entity?.setTransparent(checked);
  }

  return (
    <FormControlLabel
      control={
        <Switch size="small" checked={transparent} onChange={handleChange} />
      }
      label={<Typography variant="body2">{labelString}</Typography>}
    />
  );
}
