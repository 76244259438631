import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {createMapviewService, fetchMapviewsService} from "./services";

export const createMapview = createAsyncThunk(
  "mapviews/createMapview",
  createMapviewService
);
export const fetchMapviews = createAsyncThunk(
  "mapviews/fetchMapviews",
  fetchMapviewsService
);

const mapviewsSlice = createSlice({
  name: "mapviews",
  initialState: {data: [], status: "idle"},
  extraReducers: {
    [createMapview.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data.push(action.payload);
      }
    },
    [fetchMapviews.fulfilled]: (state, action) => {
      if (action.payload) {
        state.data.push(...action.payload);
        state.status = "loaded";
      }
    },
  },
});

export default mapviewsSlice.reducer;
