import useElementTypeGroupsByScene from "Features/elementTypes/hooks/useElementTypeGroupsByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import getItemsMapById from "Utils/getItemsMapById";
import {useSelector} from "react-redux";

export default function useFilterIdsByEntityFromShortcut(scene) {
  //
  let elementType = useSelector((s) => s.measurements.shortcutElementTypes);

  let elementTypeGroups = useSelector(
    (s) => s.measurements.shortcutElementTypeGroups
  );

  let elementTypes = useElementTypesBySceneProxy(scene);

  let sector = useSelector((s) => s.sectors.shortcutElevationZ);

  let version = useSelector((s) => s.versions.shortcutVersions);

  // update elememtType ids

  const elementTypeById = getItemsMapById(elementTypes);
  const newElementTypeIds = [];
  elementType?.forEach((id) => {
    const type = elementTypeById[id];
    if (
      !elementTypeGroups ||
      (elementTypeGroups && elementTypeGroups.includes(type?.groupId))
    ) {
      newElementTypeIds.push(id);
    }
  });

  // PATCH : "no shortcut to apply" should be null. However, still [] in some places...

  //if (elementType?.length === 0) elementType = null;
  if (sector?.length === 0) sector = null;
  if (version?.length === 0) version = null;

  return {
    elementType: newElementTypeIds,
    sector,
    version,
  };
}
