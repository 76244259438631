import {client} from "API/capla360";

// organisation

export async function fetchOrganisationsService({accessToken}) {
  const response = await client.get(`/organisations/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function postOrganisationService({accessToken, organisation}) {
  const response = await client.post(`/organisations/`, organisation, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function fetchOrganisationService({accessToken, organisationId}) {
  const response = await client.get(`/organisations/${organisationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, organisationId};
}

export async function updateOrganisationService({
  accessToken,
  organisationId,
  updates,
}) {
  const response = await client.patch(
    `/organisations/${organisationId}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {organisation: response.data, organisationId};
}

// nomenclatures

export async function fetchOrganisationNomenclaturesService({
  accessToken,
  organisationId,
}) {
  const response = await client.get(
    `/organisations/${organisationId}/nomenclatures/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {nomenclatures: response.data, organisationId};
}

export async function postOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclature,
}) {
  const response = await client.post(
    `/organisations/${organisationId}/nomenclatures/`,
    nomenclature,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {nomenclature: response.data, organisationId};
}

export async function fetchOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclatureId,
}) {
  const response = await client.get(
    `/organisations/${organisationId}/nomenclatures/${nomenclatureId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {nomenclature: response.data, organisationId};
}

export async function updateOrganisationNomenclatureService({
  accessToken,
  organisationId,
  nomenclatureId,
  updates,
}) {
  const response = await client.patch(
    `/organisations/${organisationId}/nomenclatures/${nomenclatureId}/`,
    updates,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {nomenclature: response.data, organisationId};
}

// user Relationship (TODO)
export async function postUserRelationshipService({
  accessToken,
  organisationId,
  userRelationship,
}) {
  const response = await client.post(
    `/organisations/${organisationId}/user_relationships/`,
    userRelationship,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, organisationId};
}
