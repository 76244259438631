import imageExtensions from "image-extensions";
import isUrl from "is-url";

import {ReactEditor} from "slate-react";

import {Transforms, Node, Editor, Path} from "slate";

import {nanoid} from "nanoid";
import {uploadFileService} from "Features/files/services";

export function withImages(editor, {accessToken, listingId}) {
  const {isVoid, insertData} = editor;

  editor.insertData = async (data) => {
    const text = data.getData("text/plain");
    const {files} = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", async () => {
            const url = reader.result;
            const id = nanoid();
            insertImage(editor, url, id);
            try {
              const remoteUrl = await uploadFileService({
                accessToken,
                listingId,
                file,
                container: "listing-files",
              });
              console.log("image posted ?", remoteUrl);
              updateImage(editor, remoteUrl, id);
            } catch (error) {
              console.log(error);
            }
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };
  return editor;
}

const insertImage = (editor, url, id) => {
  const {selection} = editor;
  ReactEditor.focus(editor);

  const text = {text: ""};
  const paragraph = {type: "paragraph", children: [{text: ""}]};
  const image = [
    {type: "image", status: "loading", id, url, children: [text]},
    paragraph,
  ];

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );
    console.log("parentnode", parentNode);
    if (editor.isVoid(parentNode) || Node.string(parentNode)?.length) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, {at: parentPath});
      Transforms.insertNodes(editor, image, {at: parentPath, select: true});
    }
  } else {
    console.log("insert at the end");
    Transforms.insertNodes(editor, image, {select: true});
  }
};

const updateImage = (editor, url, id) => {
  console.log("start update", id);
  Transforms.setNodes(
    editor,
    {url, status: "succeed"},
    {
      at: [],
      match: (node, path) => {
        return node.id === id;
      },
    }
  );
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};
