import {useState, useEffect} from "react";

import {Box, Typography, Input, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

import getListItemStyle from "Styles/getListItemStyle";

import parseFuncAndFiltersStrString from "../utils/parseFuncAndFiltersStrString";
import stringifyFuncAndFiltersStr from "../utils/stringifyFuncAndFiltersStr";
import IconElementType from "Features/elementTypes/components/IconElementType";

export default function ListItemArticleType({
  articleId,
  typeId,
  color,
  drawingShape,
  groupId,
  num,
  name,
  func,
  onFuncChange,
  filtersStr,
  onFiltersStrChange,
  onTypeItemChange,
  isTitle,
  isGroup,
  style,
  onRemove,
  showInput,
}) {
  // local state

  const initExpr = stringifyFuncAndFiltersStr(func, filtersStr);

  const [tempExpr, setTempExpr] = useState(initExpr);

  useEffect(() => {
    setTempExpr(initExpr);
  }, [initExpr, articleId]);

  // helpers - styles

  const {fontWeight, bgcolor, color: fontColor} = getListItemStyle({style});

  // helper - label

  let label = isGroup ? name : `${num ?? ""} • ${name}`;
  if (!isGroup && !groupId && !typeId)
    label = `Appliqué sur tous les ouvrages/éléments`;

  // handlers

  function handleBlur() {
    const funcAndFiltersStr = parseFuncAndFiltersStrString(tempExpr);
    const func = funcAndFiltersStr.func;
    const filtersStr = funcAndFiltersStr.filtersStr;
    //if (func || func === "") onFuncChange({groupId, typeId, func});
    //if (filtersStr) onFiltersStrChange({groupId, typeId, filtersStr});
    onTypeItemChange({groupId, typeId, filtersStr, func});
  }

  function handleRemoveClick() {
    onRemove({groupId, typeId});
  }

  return (
    <Box sx={{width: 1, display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          fontWeight,
          bgcolor: isGroup ? "common.caplaBlack" : bgcolor,
          color: isGroup ? "common.white" : fontColor,
          fontSize: 13,
          p: 0.5,
        }}
      >
        <Typography sx={{fontSize: 13}}>{label}</Typography>
      </Box>
      {showInput && (
        <Box sx={{display: "flex", alignItems: "center", width: 1}}>
          <IconButton size="small" onClick={handleRemoveClick} sx={{mr: 1}}>
            <Close fontSize="small" />
          </IconButton>
          {!isTitle && typeId && groupId && (
            <IconElementType
              color={color}
              drawingShape={drawingShape}
              size="small"
            />
          )}
          <Box sx={{px: 1, flexGrow: 1}}>
            <Box
              sx={{
                borderRadius: "8px",
                border: (theme) => `1px solid ${theme.palette.divider}`,
                px: 1,
                bgcolor: "common.white",
                width: 1,
              }}
            >
              <Input
                fullWidth
                disableUnderline
                sx={{fontSize: 13}}
                value={tempExpr}
                onChange={(e) => setTempExpr(e.target.value)}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
