import {createSlice} from "@reduxjs/toolkit";

export const annotatedPdfSlice = createSlice({
  name: "annotatedPdf",
  initialState: {
    modelId: null,
    rawMeasurements: [],
    selectedAuthor: null,
    subjectElementTypeMap: {}, // {subject:elementType}
    subjectTypeVarMap: {}, // {subject:{varType,value}}
    subjectGeoCatMap: {}, // {subject:geoCat}
    subjectTempTypeMap: {}, // {subject:tempType}, tempType = loaded from excel.
  },
  reducers: {
    setModelId: (state, action) => {
      state.modelId = action.payload;
    },
    setRawMeasurements: (state, action) => {
      state.rawMeasurements = action.payload;
    },
    setSelectedAuthor: (state, action) => {
      state.selectedAuthor = action.payload;
    },
    setSubjectElementTypeMap: (state, action) => {
      state.subjectElementTypeMap = action.payload;
    },
    setSubjectTypeVarMap: (state, action) => {
      state.subjectTypeVarMap = action.payload;
    },
    setSubjectGeoCatMap: (state, action) => {
      state.subjectGeoCatMap = action.payload;
    },
    setSubjectTempTypeMap: (state, action) => {
      state.subjectTempTypeMap = action.payload;
    },
  },
});

export const {
  setModelId,
  setRawMeasurements,
  setSelectedAuthor,
  setSubjectElementTypeMap,
  setSubjectTypeVarMap,
  setSubjectGeoCatMap,
  setSubjectTempTypeMap,
} = annotatedPdfSlice.actions;

export default annotatedPdfSlice.reducer;
