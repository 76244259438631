import React from "react";

import {Grid} from "@mui/material";

import FieldTextBasic from "./FieldTextBasic";
import FieldNumberBasic from "./FieldNumberBasic";

export default function KeyValueFormBasic({template, item, onChange}) {
  // handler
  function handleFieldChange(key, value) {
    const newItem = {...item, [key]: value};
    if (onChange) onChange(newItem);
  }

  return (
    <Grid container sx={{width: 1}}>
      {template.fields.map((field) => {
        return (
          <Grid item xs={field.xs} sx={{px: 1}} key={field.key}>
            {field.type === "text" && (
              <FieldTextBasic
                label={field.label}
                value={item[field.key]}
                onChange={(v) => handleFieldChange(field.key, v)}
              />
            )}
            {field.type === "number" && (
              <FieldNumberBasic
                label={field.label}
                value={item[field.key]}
                onChange={(v) => handleFieldChange(field.key, v)}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
