import {useState} from "react";

import {useSelector} from "react-redux";

import useActionsPackagesMap from "../hooks/useActionsPackagesMap";

import {Box} from "@mui/material";

import SectionGenericActions from "Components/SectionGenericActions";
import DialogCreatePackage from "./DialogCreatePackage";

export default function SectionActionsPackages({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // data

  const openDialogCreatePackage = useSelector(
    (s) => s.packages.openDialogCreatePackage
  );
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
  };

  // handlers

  function handleClick(actionKey) {
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  function handlePackageCreated(model) {
    console.log("Package created", model);
  }

  // data - handlers

  const actionsMap = useActionsPackagesMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogXxx && <Box>dialog</Box>}

      {openDialogCreatePackage && (
        <DialogCreatePackage
          scene={scene}
          caplaEditor={caplaEditor}
          initialRestrictedGroupIds={null}
          onPackageCreated={handlePackageCreated}
        />
      )}
    </>
  );
}
