import React from "react";
import {SvgIcon} from "@mui/material";
import {ReactComponent as Logo} from "./assets/drawSegment.svg";
export default function Ifc(props) {
  return (
    <SvgIcon {...props}>
      <Logo />
    </SvgIcon>
  );
}
