import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import {DataGridPro, frFR, GridActionsCellItem} from "@mui/x-data-grid-pro";

import elementTypeFromDatagridToState from "Features/elementTypes/utils/elementTypeFromDatagridToState";

import ContainerRawType from "Features/elementTypes/components/ContainerRawType";
import ContainerElementTypeBasic from "Features/elementTypes/components/ContainerElementTypeBasic";

import {rawTypeToType} from "Features/measurements/utils/rawTypeToType";
import DialogSelectorElementTypesGeneric from "Features/elementTypes/components/DialogSelectorElementTypesGeneric";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridRawMeasAndElementTypes({
  scene,
  items,
  elementTypes,
  nameFrom,
  selection,
  onSelectionChange,
  onItemChange,
}) {
  // items = {id:,rawType:,elementType:}

  const dispatch = useDispatch();

  // strings

  const rawTypeS = "Annotation";
  const countS = "x";
  const elementTypeS = "ElementType";
  const offsetS = "Offset (m)";
  const zInfS = "Z inf (m)";
  const zZupS = "Z sup (m)";
  const heightS = "H (m)";

  // selection model

  const selectionModel = [...selection];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    console.log("selectionChange", selection);
    onSelectionChange(selection);
    // if (selectionId === selectedElementTypeIds[0] && selection.length === 1) {
    //   dispatch(setSelectedElementTypeIds([]));
    // } else {
    //   dispatch(setSelectedElementTypeIds(selection));
    // }
  }

  function handleProcessRowUpdate(updatedRow) {
    const newElementType = {...updatedRow.elementType};
    if (updatedRow.offset) newElementType.offset = updatedRow.offset;
    if (updatedRow.zInf) newElementType.zInf = updatedRow.zInf;
    if (updatedRow.height) newElementType.height = updatedRow.height;
    const newItem = {...updatedRow, elementType: newElementType};
    onItemChange(newItem);
  }

  function handleItemElementTypeChange(item, newElementType) {
    const newItem = {...item, elementType: newElementType};
    delete newItem.zInf;
    delete newItem.offset;
    delete newItem.height; // the height is saved in the elementType object of the newItem.
    onItemChange(newItem);
  }

  // grid - columns

  const columns = [
    {
      field: "rawType",
      headerName: rawTypeS,
      flex: 1,
      renderCell: (params) => {
        // const rawType = {
        //   rawName: params.row.rawName,
        //   rawColor: params.row.rawColor,
        //   rawDrawingShape: params.row.rawDrawingShape,
        //   rawCount: params.row.rawCount,
        // };
        return <ContainerRawType rawType={params.value} />;
      },
    },
    {field: "count", headerName: countS, width: 50},
    {
      field: "elementType",
      headerName: elementTypeS,
      flex: 1,
      renderCell: (params) => {
        return (
          <ContainerElementTypeBasic
            scene={scene}
            elementType={params.value}
            onChange={(newElementType) =>
              handleItemElementTypeChange(params.row, newElementType)
            }
          />
        );
      },
    },
    {field: "offset", headerName: offsetS, width: 100, editable: true},
    {field: "zInf", headerName: zInfS, width: 100, editable: true},
    {field: "height", headerName: heightS, width: 100, editable: true},
  ];

  // grid - rows

  const rows = items.map((item) => ({
    ...item,
    count: item.rawType.rawCount,
    offset: item.elementType?.offset,
    zInf: item.elementType?.zInf,
    height: item.elementType?.height,
  }));

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--Disabled": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGridPro
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        sx={{"& .MuiDataGrid-cell--withRenderer": {p: 0}}}
        density="compact"
        checkboxSelection={true}
        rows={rows}
        columns={columns}
        processRowUpdate={handleProcessRowUpdate}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
        getRowClassName={(params) => {
          if (
            params.row.style === "title1" ||
            (params.row.isTitle &&
              params.row.style === undefined &&
              params.row.rank === 1)
          )
            return `super-app-theme--Title1`;
          if (
            params.row.style === "title2" ||
            (params.row.isTitle &&
              params.row.style === undefined &&
              params.row.rank === 2)
          )
            return `super-app-theme--Title2`;
          if (params.row.style === "detail") return `super-app-theme--Child`;
          if (params.row.disabled) return `super-app-theme--Disabled`;
        }}
      />
    </Box>
  );
}
