import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {unwrapResult, nanoid} from "@reduxjs/toolkit";

import {createElementTypesGroup} from "Features/elementTypes/elementTypesSlice";

import {Box} from "@mui/material";

import SectionElementTypeGroupsInHome from "./SectionElementTypeGroupsInHome";
import HeaderEntityElementTypeGroups from "./HeaderEntityElementTypeGroups";
import SectionImportElementTypeGroupFromExcel from "./SectionImportElementTypeGroupFromExcel";
import SectionTempElementTypeGroup from "Features/elementTypes/components/SectionTempElementTypeGroup";
import {setSelectedElementTypeGroupIdsInHome} from "../boxhomeSlice";

import useAccessToken from "Features/auth/useAccessToken";
import SectionStartElementTypeGroups from "./SectionStartElementTypeGroups";

import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";

export default function PageHomeAdminElementTypeGroups({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // data

  const groups = useElementTypesGroupsProxyByScene(scene);

  // state

  const [mode, setMode] = useState("LIST"); // "IMPORT", "TEMP"
  useEffect(() => {
    if (!groups.length) {
      setMode("START");
    }
  }, [groups?.length]);

  const [tempTypes, setTempTypes] = useState([]);

  const initGroupName = tempTypes[0]?.group ? tempTypes[0].group : "";
  const [tempGroupName, setTempGroupName] = useState(initGroupName);
  useEffect(() => {
    setTempGroupName(initGroupName);
  }, [initGroupName]);

  // handlers

  function handleModeChange(mode) {
    setMode(mode);
  }

  function handleTempTypesChange(tempTypes) {
    setTempTypes(tempTypes);
    if (tempTypes?.length > 0) setMode("TEMP");
  }

  function handleTempGroupNameChange(name) {
    setTempGroupName(name);
  }

  function handleAdd() {
    setMode("IMPORT");
  }
  function handleCancel() {
    setTempTypes([]);
    setMode("LIST");
  }

  function handleAddDefault() {
    setMode("LIST");
  }

  async function handleCreate() {
    const newGroup = {};
    newGroup.name = tempGroupName;
    newGroup.fromScene = false;
    newGroup.sceneId = scene.id;
    newGroup.elementTypes = tempTypes.map((type) => ({...type, id: nanoid()}));
    const rawGroup = await dispatch(
      createElementTypesGroup({elementTypesGroup: newGroup, accessToken})
    );
    const {item} = unwrapResult(rawGroup);
    console.log("create types group", item);
    dispatch(setSelectedElementTypeGroupIdsInHome([item.id]));
    setMode("LIST");
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyItems: "stretch",
        flexDirection: "column",
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <HeaderEntityElementTypeGroups
        mode={mode}
        onAdd={handleAdd}
        onCancel={handleCancel}
        onCreate={handleCreate}
      />
      {mode === "START" && (
        <SectionStartElementTypeGroups
          scene={scene}
          onNewTypes={handleTempTypesChange}
          onAddDefault={handleAddDefault}
        />
      )}
      {mode === "LIST" && <SectionElementTypeGroupsInHome scene={scene} />}
      {mode === "IMPORT" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SectionImportElementTypeGroupFromExcel
            scene={scene}
            onNewTypes={handleTempTypesChange}
          />
        </Box>
      )}
      {mode === "TEMP" && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SectionTempElementTypeGroup
            tempTypes={tempTypes}
            tempGroupName={tempGroupName}
            onTempGroupNameChange={handleTempGroupNameChange}
            onCancel={handleCancel}
          />
        </Box>
      )}
    </Box>
  );
}
