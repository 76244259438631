import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {unwrapResult, nanoid} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import SectionPackagesInHome from "./SectionPackagesInHome";
import HeaderEntityPackages from "./HeaderEntityPackages";

import SectionImportPdf from "./SectionImportPdf";
import SectionTempPdf from "./SectionTempPdf";

import useAccessToken from "Features/auth/useAccessToken";

import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";

export default function PageHomeAdminPackages({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyItems: "stretch",
        flexDirection: "column",
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <HeaderEntityPackages />

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          pt: 2,
          minWidth: 0,
          minHeight: 0,
          overflowY: "auto",
        }}
      >
        <SectionPackagesInHome scene={scene} caplaEditor={caplaEditor} />
      </Box>
    </Box>
  );
}
