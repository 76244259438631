import {Box, Paper} from "@mui/material";

import ButtonCreateZone from "./ButtonCreateZone";

import ButtonSetScaleFromMeasurement from "Features/pdf/components/ButtonSetScaleFromMeasurement";

export default function ToolbarCreateZone({caplaEditor, scene}) {
  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0.5,
        background,
      }}
    >
      <ButtonSetScaleFromMeasurement editorPdf={caplaEditor?.editorPdf} />
      <Box sx={{pr: 1}} />
      <ButtonCreateZone caplaEditor={caplaEditor} scene={scene} />
    </Paper>
  );
}
