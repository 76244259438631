import {useSelector} from "react-redux";
import {Box} from "@mui/material";
import ImageBasic from "Features/images/components/ImageBasic";

export default function SectionImagePreview() {
  const tempElementTypeFromPdf = useSelector(
    (s) => s.elementTypor.tempElementTypeFromPdf
  );
  console.log(tempElementTypeFromPdf);

  const url = tempElementTypeFromPdf?.fileUrl;

  return (
    <Box sx={{width: 1, minWidth: 150, height: 300}}>
      <ImageBasic url={url} />
    </Box>
  );
}
