import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "API/capla360";

export const postImage = createAsyncThunk(
  "images/postImage",
  async ({accessToken, userId, listingId, image}) => {
    const response = await client.post(
      `/users/${userId}/listings/${listingId}/upload/`,
      image,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchImages = createAsyncThunk(
  "images/fetchImages",
  async ({accessToken, userId, listingId}) => {
    const response = await client.get(
      `/users/${userId}/listings/${listingId}/images/`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

export const deleteImage = createAsyncThunk(
  "images/deleteImage",
  async ({accessToken, userId, listingId, imageId}) => {
    console.log("deleting");
    const response = await client.delete(
      `/users/${userId}/listings/${listingId}/images/${imageId}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const imagesSlice = createSlice({
  name: "images",
  initialState: {data: []},
  reducers: {},
  extraReducers: {
    [postImage.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [fetchImages.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default imagesSlice.reducer;
