import {Paper, Typography} from "@mui/material";

export default function TopBarToolbox() {
  return (
    <Paper
      elevation={9}
      sx={{
        background: "unset",
        bgcolor: "common.white",
        color: "common.black",
        height: (theme) => theme.spacing(5),
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        pl: 1,
        pr: 0.5,
        zIndex: 90,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Typography>Toolbox</Typography>
    </Paper>
  );
}