import {useState} from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@mui/material";

import useUpdateSceneMaterials from "../hooks/useUpdateSceneMaterials";
import excelMaterialsToMaterials from "../utils/excelMaterialsToMaterials";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getMaterialsFromFile from "Features/excel/utils/getMaterialsFromFile";

export default function DialogImportMaterialsFromExcel({open, onClose, scene}) {
  // strings

  const title = "Importez des matériaux";

  const message = `L'import utilise la colonne "code" pour savoir s'il faut créer un nouveau matériau ou modifier le matériau existant.`;

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const updateMaterials = useUpdateSceneMaterials(scene);

  // state

  const [materials, setMaterials] = useState(null);
  const [file, setFile] = useState(null);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    let materials = await getMaterialsFromFile(file);
    materials = excelMaterialsToMaterials(materials);
    setMaterials(materials);
  }
  function handleCancelClick() {
    onClose();
  }
  function handleSaveClick() {
    updateMaterials(materials);
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{bgcolor: "background.default", p: 1}}>
            <Typography variant="body2">{message}</Typography>
          </Box>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
