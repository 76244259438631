import {useEffect, useState} from "react";

import {Typography, Box, TextField, IconButton} from "@mui/material";
import {Add, ArrowForwardIos as Arrow} from "@mui/icons-material";

export default function ListItemEditable({
  item,
  selected,
  onChange,
  onAdd,
  onSelect,
  showArrow,
}) {
  // state

  const [editing, setEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(item?.name);
  }, [item?.name]);

  // handlers

  function handleChange(e) {
    setName(e.target.value);
  }

  function handleBlur() {
    if (item) {
      const newItem = {...item, name};
      setIsFocused(false);
      onChange(newItem);
    }
  }

  function handleAddItem() {
    const newItem = {name};
    setName("");
    onAdd(newItem);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0.5,
        ...(selected && {bgcolor: "secondary.light"}),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 1,
        }}
      >
        <TextField
          size="small"
          value={name}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
        />
      </Box>
      {!item && (
        <IconButton size="small" onClick={handleAddItem}>
          <Add fontSize="small" />
        </IconButton>
      )}
      {item && !isFocused && (
        <IconButton size="small" onClick={() => onSelect(item)}>
          <Arrow fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
}
