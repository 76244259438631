import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import {
  // setSelectedMeasurementId,
  setSelectedMeasurementIds,
} from "../measurementsSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import {setClickedObject} from "Features/viewer3D/viewer3DSlice";

export default function ButtonBackMeasurementInSelectionPanel({editor3d}) {
  const dispatch = useDispatch();

  // string

  const newS = "New";

  // data

  const sceneModule = useSceneModule();

  // helper - label

  const label = sceneModule === "EDIT_MEASUREMENTS" ? newS : "...";
  // handlers

  function handleClick() {
    dispatch(setSelectedMeasurementIds([]));
    if (sceneModule === "HOME") {
      dispatch(setClickedObject({}));
      editor3d?.sceneEditor.unselect();
    }
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {label}
    </Button>
  );
}
