import {useState} from "react";

import {Box} from "@mui/material";

import SelectorPoisIcons from "./SelectorPoisIcons";

export default function ContainerSelectorPois({
  scene,
  viewer,
  caplaEditor,
  pois,
  selectedPoiId,
  onSelectionChange,
}) {
  // helper

  // handlers

  function handleSelectionChange(poiId) {
    onSelectionChange(poiId);
  }

  return (
    <Box>
      <SelectorPoisIcons
        scene={scene}
        caplaEditor={caplaEditor}
        pois={pois}
        onSelectionChange={handleSelectionChange}
        selectedPoiId={selectedPoiId}
      />
    </Box>
  );
}
