import React from "react";

import {useSelector} from "react-redux";

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItem,
} from "@mui/material";

import ListIconDatasetType from "./ListIconDatasetType";
import useModelSyncStatus from "Features/viewer3D/useModelSyncStatus";
import ListItemSecondaryActionSceneDataset from "./ListItemSecondaryActionSceneDataset";

export default function ListItemSceneDataset({
  id,
  isModel,
  isListing,
  type,
  name,
  onClick,
}) {
  // data

  const status = useModelSyncStatus(isModel && id);

  // helper

  const selectedModelId = useSelector((s) => s.ui.selectedModelId);
  const selected = isModel && selectedModelId === id;

  const toLoad = status && status !== "detailed";

  const color = toLoad ? "text.secondary" : "text.primary";

  // handlers

  function handleClick() {
    if (onClick) onClick({id, isModel, isListing});
  }
  return (
    <ListItemButton
      sx={{py: 0.5}}
      onClick={handleClick}
      divider
      selected={selected}
    >
      <ListItem
        disablePadding
        secondaryAction={
          <ListItemSecondaryActionSceneDataset
            id={id}
            isModel={isModel}
            isListing={isListing}
          />
        }
      >
        <ListItemIcon>
          <ListIconDatasetType
            isModel={isModel}
            isListing={isListing}
            type={type}
            unselected={false}
          />
        </ListItemIcon>
        <ListItemText>
          <Typography sx={{fontSize: 13}} noWrap color={color}>
            {name}
          </Typography>
        </ListItemText>
      </ListItem>
    </ListItemButton>
  );
}
