export default function getModelsLoadingRatio(
  models,
  loadingStatusModelIdsMap
) {
  const idsToLoad = models.map((m) => m.id);

  const idsInState = [
    ...loadingStatusModelIdsMap["IN_STATE"],
    ...loadingStatusModelIdsMap["IN_3D_OVERVIEW"],
    ...loadingStatusModelIdsMap["IN_3D_DETAILED"],
  ];

  const idsToLoadInState = [];
  idsToLoad.forEach((id) => {
    if (idsInState.includes(id)) idsToLoadInState.push(id);
  });

  let ratio = 0;

  const count = idsToLoadInState?.length ? idsToLoadInState.length : 0;
  const total = idsToLoad?.length ? idsToLoad.length : 0;

  if (total > 0) {
    ratio = (count / total) * 100;
  }

  return ratio;
}
