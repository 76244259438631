import {Box} from "@mui/material";

import ProcessCreateCapla from "./ProcessCreateCapla";

export default function ToolCapla({editor3d}) {
  return (
    <Box>
      <ProcessCreateCapla editor3d={editor3d} />
    </Box>
  );
}
