import {useDispatch, useSelector} from "react-redux";

import {ToggleButton, Tooltip, Box} from "@mui/material";
import {RadioButtonChecked as Pointer} from "@mui/icons-material";

import {setPointerMode} from "Features/viewer3D/viewer3DSlice";

export default function TogglePointerMode({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const tipString = "Show pointer";

  // data

  const checked = useSelector((state) => state.viewer3D.pointerMode);

  // handlers

  const handleChange = () => {
    if (checked) {
      dispatch(setPointerMode(false));
      editor3d?.sceneEditor.setPointerMode(false);
    } else {
      dispatch(setPointerMode(true));
      editor3d?.sceneEditor.setPointerMode(true);
    }
  };

  return (
    <Box sx={{color: "common.white"}}>
      <Tooltip title={tipString}>
        <ToggleButton
          value="HIDE"
          selected={checked}
          onChange={handleChange}
          aria-label="module"
          sx={{
            width: 30,
            height: 30,
            border: "none",
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Box
            sx={{color: "common.white", display: "flex", alignItems: "center"}}
          >
            <Pointer fontSize="small" color="inherit" />
          </Box>
        </ToggleButton>
      </Tooltip>
    </Box>
  );
}
