import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {updateLoadingRatioByScopeIdMap} from "../overviewerSlice";

import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import getModelIdsInScope from "Features/scopes/utils/getModelIdsInScope";
import useFetchSceneModels from "./useFetchSceneModels";
import getFixedScope from "Features/scopes/utils/getFixedScope";
import useScopeToOpen from "Features/scopes/hooks/useScopeToOpen";
import {update} from "Features/ifc/ifcSlice";
import getModelsInScope from "Features/scopes/utils/getModelsInScope";

export default function useAutoFetchSceneModelsInScope() {
  const dispatch = useDispatch();

  // data

  const selectedScope = useSelectedScope();

  const scopeIdToOpen = useSelector((s) => s.overviewer.scopeIdToOpen);
  const scopeToOpen = useScopeToOpen({useFixedScope: true});

  const models = useSelector((s) => s.viewer3D.models);
  // local state

  const [fetchedScopeIdMap, setFetchedScopeIdMap] = useState({});

  // data - fetchModels

  const modelIdsToFetch = getModelIdsInScope(scopeToOpen);
  const modelsToFetch = getModelsInScope(models, scopeToOpen);
  const options = {modelIdsInScope: modelIdsToFetch};
  const [fetchModels] = useFetchSceneModels(options);

  // helper

  const userWantsToOpen = scopeIdToOpen === selectedScope?.id;

  const scopeIsNotFetched =
    selectedScope?.id && !fetchedScopeIdMap[selectedScope?.id];

  const canFetch = userWantsToOpen && scopeIsNotFetched;

  //

  useEffect(() => {
    console.log("EFFECT333", fetchedScopeIdMap, "canFetch", canFetch);
    if (canFetch) {
      console.log(
        scopeToOpen?.data,
        "FETCHING MODELS: modelIdsToFetch",
        modelIdsToFetch,
        "modelsToFetch",
        modelsToFetch,
        "models",
        models
      );
      const newMap = {...fetchedScopeIdMap, [selectedScope.id]: "ok"};
      setFetchedScopeIdMap(newMap);
      //
      dispatch(
        updateLoadingRatioByScopeIdMap({
          scopeId: scopeIdToOpen,
          modelIdsToFetch,
        })
      );
      //
      fetchModels();
    }
  }, [selectedScope?.id, canFetch]);
}
