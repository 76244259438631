export default function getPrevAndNextItems(currentId, items) {
  if (!currentId) return [null, null];

  const maxIndex = items.length - 1;
  const currentIndex = items.map((i) => i.id).indexOf(currentId);

  if (maxIndex === 0) return [items[0], items[0]];
  if (maxIndex === -1) return [null, null];

  let nextIndex = currentIndex === maxIndex ? 0 : currentIndex + 1;
  let prevIndex = currentIndex === 0 ? maxIndex : currentIndex - 1;
  // let nextItem;
  // let prevItem;

  // let limitNext = 0;
  // let limitPrev = 0;

  // while (!nextItem && limitNext < 1000) {
  //   let _nextItem = items[nextIndex];
  //   if (!_nextItem?.isTitle && !_nextItem?.noPricing && _nextItem?.quantity) {
  //     nextItem = _nextItem;
  //   } else {
  //     if (nextIndex === maxIndex) {
  //       nextIndex = 0;
  //     } else {
  //       nextIndex += 1;
  //     }
  //   }
  //   limitNext += 1;
  // }

  // while (!prevItem && limitPrev < 1000) {
  //   let _prevItem = items[prevIndex];
  //   if (!_prevItem?.isTitle && !_prevItem?.noPricing && _prevItem?.quantity) {
  //     prevItem = _prevItem;
  //   } else {
  //     if (prevIndex === 0) {
  //       prevIndex = maxIndex;
  //     } else {
  //       prevIndex += -1;
  //     }
  //   }
  //   limitPrev += 1;
  // }

  return [items[prevIndex], items[nextIndex]];
}
