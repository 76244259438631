import React, {useState, useEffect} from "react";

import {Dialog, DialogActions, Button} from "@mui/material";

import SelectorAutocompleteMultiple from "Components/SelectorAutocompleteMultiple";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useUpdateSceneRoom from "../hooks/useUpdateSceneRoom";

export default function DialogEditRoomZones({scene, room, open, onClose}) {
  // strings

  const name = "Fonds de plan";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const zones = useZonesByScene(scene);
  const updateRoom = useUpdateSceneRoom(scene);

  // state

  const [roomZones, setRoomZones] = useState([]);

  useEffect(() => {
    if (open && room) {
      const roomZoneIds = room.zoneIds ? room.zoneIds : [];
      const newZones = zones.filter((z) => roomZoneIds.includes(z.id));
      setRoomZones(newZones);
    }
  }, [open, room?.id, zones.length]);

  // handlers

  function handleChange(zones) {
    setRoomZones(zones);
  }

  function handleSave() {
    const newRoom = {...room, zoneIds: roomZones.map((z) => z.id)};
    updateRoom(newRoom);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <SelectorAutocompleteMultiple
        name={name}
        value={roomZones}
        onChange={handleChange}
        options={zones}
      />
      <DialogActions>
        <Button onClick={onClose}>{cancelS}</Button>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
