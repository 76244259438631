import React from "react";

import {Card, Typography, CardContent, alpha} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
export default function CardImageDescription() {
  // strings
  const {t} = useTranslation("viewer3D");

  const imagesString = t("viewer3D:images.description.title");
  const descriptionString = t("viewer3D:images.description.detail");

  return (
    <Card
      sx={{
        width: "100%",
        color: "common.white",
        background: (theme) => alpha(theme.palette.secondary.main, 0.9),
      }}
    >
      <CardContent>
        <Typography variant="body2">
          <b>{imagesString}</b>
        </Typography>
        <Typography variant="body2">{descriptionString}</Typography>
      </CardContent>
    </Card>
  );
}
