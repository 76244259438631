import { useSelector } from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import DataGridSharedboxes from "./DataGridSharedboxes";
import SectionTitleSharedboxes from "./SectionTitleSharedboxes";

import Stretch from "Features/ui/components/Stretch";

export default function PageSharedboxes() {

  const {scene} = useOutletContext();
  const os = useSelector((s) => s.ui.openSections);

  if (os.outlet)
    return (
      <Box sx={{width: 1}}>
        <Box sx={{height: "60px"}} />
        <SectionTitleSharedboxes />
        <Stretch>
          <DataGridSharedboxes scene={scene} />
        </Stretch>
      </Box>
    );
  return (<></>);
}
