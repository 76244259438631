import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {createListing} from "Features/listings/listingsSlice";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";

import FormCreateListing from "Features/listings/components/FormCreateListing";
import useAccessToken from "Features/auth/useAccessToken";
import {unwrapResult} from "@reduxjs/toolkit";

export default function ButtonDialogCreateListing({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const titleString = "Create a dataset";
  const buttonString = "Create";
  const cancelString = "Cancel";

  // state

  const initListing = {
    name: "",
    type: null,
    description: "",
    scenes: [scene.id],
  };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listing, setListing] = useState(initListing);

  // handlers - dialog

  function handleClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  // handlers - listing

  function handleListingChange(listing) {
    setListing((oldListing) => ({...oldListing, ...listing}));
  }

  // handlers - save & cancel

  function handleCancel() {
    setOpen(false);
  }
  async function handleSave() {
    setLoading(true);
    const result = await dispatch(createListing({accessToken, listing, scene}));
    const {listing: newListing} = unwrapResult(result);
    console.log("new listing created", newListing);
    setLoading(false);
    setOpen(false);
  }

  return (
    <Box>
      <Button onClick={handleClick} size="small" variant="outlined">
        {buttonString}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{titleString}</DialogTitle>
        <DialogContent>
          <FormCreateListing onChange={handleListingChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{cancelString}</Button>
          <LoadingButton
            loading={loading}
            startIcon={<Add />}
            loadingPosition="start"
            onClick={handleSave}
          >
            {buttonString}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
