import {forwardRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
  setOneFirstScopeWasOpened,
  setOpenDialogFsSelectScope,
  setOpenDialogFsOpenScope,
} from "../overviewerSlice";
import {setScopeIdToOpen} from "../overviewerSlice";

import {Dialog, IconButton, Box, Fade} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import theme from "Styles/theme";
import {Close} from "@mui/icons-material";

import useScopesByScene from "Features/scopes/hooks/useScopesByScene";
import SectionSelectorScopeGrid from "./SectionSelectorScopeGrid";
import SectionLoadingScene from "./SectionLoadingScene";
import ContainerLoadingBimbox from "./ContainerLoadingBimbox";
import {useParams} from "react-router-dom";
import useFetchSceneModels from "../hooks/useFetchSceneModels";
import {setSelectedScopeId} from "Features/scopes/scopesSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={{enter: 0, exit: 1000}} />;
});

export default function DialogFsSelectScope({scene}) {
  const dispatch = useDispatch();
  const {sceneId} = useParams();

  // data

  const open = useSelector((s) => s.overviewer.openDialogFsSelectScope);

  const sceneIdOfLoadedScopes = useSelector((s) => s.scopes.itemsSceneId);
  const models = useSelector((s) => s.viewer3D.models);
  const modelsCountFromSceneLight = useSelector(
    (s) => s.overviewer.modelsCountFromSceneLight
  );

  const [fetchSceneModelsWithoutPackage, accessToken, sceneClientId] =
    useFetchSceneModels({
      withoutPackages: true,
    });

  // fetch models

  const canFetchModels =
    modelsCountFromSceneLight > 0 &&
    modelsCountFromSceneLight === models.length &&
    Boolean(sceneClientId) &&
    Boolean(accessToken);

  useEffect(() => {
    if (canFetchModels) {
      console.log("FETCH SCENE MODELS (WITHOUT PACKAGES)");
      //fetchSceneModelsWithoutPackage();
    }
  }, [canFetchModels]);

  // helpers

  const scopesAreLoaded = sceneIdOfLoadedScopes === sceneId;

  // handlers

  function handleClose() {
    dispatch(setOpenDialogFsSelectScope(false));
  }

  function handleOpenScope(scope) {
    console.log("click on scope", scope);
    let sid = scope?.id;
    if (!sid) sid = "all";

    dispatch(setOpenDialogFsSelectScope(false));
    dispatch(setOneFirstScopeWasOpened(true));
    //dispatch(setScopeIdToOpen(scope.id));
    dispatch(setSelectedScopeId(sid));
    dispatch(setScopeIdToOpen(sid));
  }

  function handleSeeDetail(scope) {
    dispatch(setOpenDialogFsSelectScope(false));
    dispatch(setScopeIdToOpen(scope.id));
    dispatch(setSelectedScopeId(scope.id));
    dispatch(setOpenDialogFsOpenScope(true));
  }

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <Box
        sx={{
          display: "flex",
          width: 1,
          height: 1,
          flexDirection: "column",
          bgcolor: "common.caplaBlack",
          position: "relative",
          color: "grey.700",
        }}
      >
        <IconButton
          color="inherit"
          onClick={handleClose}
          sx={{position: "absolute", top: 8, right: 8, zIndex: 10}}
        >
          <Close />
        </IconButton>
        <Box sx={{position: "absolute", top: 0, right: 0, left: 0, zIndex: 1}}>
          <SectionLoadingScene scene={scene} />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {scopesAreLoaded ? (
            <SectionSelectorScopeGrid
              scene={scene}
              onOpen={handleOpenScope}
              onSeeDetail={handleSeeDetail}
            />
          ) : (
            <Box
              sx={{
                width: 350,
                height: 300,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ContainerLoadingBimbox />
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
