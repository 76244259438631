import {useKvfTranslationMap} from "./useKvfTranslationMap";

import {useSelector} from "react-redux";
import useMeasurementsModels from "Features/measurements/useMeasurementsModels";

export default function useKvtMeasurement(sceneClientId) {
  const translationMap = useKvfTranslationMap();

  // data

  const categories = useSelector((s) => s.measurements.categories).filter(
    (c) => c.sceneClientId === sceneClientId
  );

  const measurementsModels = useMeasurementsModels(sceneClientId);

  const kvtMeasurement = {
    key: "measurement",
    fields: [
      {
        key: "shape",
        name: translationMap["shape"],
        type: "section",
      },
      {
        key: "color",
        name: translationMap["color"],
        type: "color",
      },
      {
        key: "isVoid",
        name: translationMap["isVoid"],
        type: "checkbox",
      },
      {
        type: "section",
        key: "quantities",
        name: translationMap["quantities"],
      },
      {
        key: "count",
        name: translationMap["countX"],
        type: "number",
      },
      {
        key: "length",
        name: translationMap["lengthInM"],
        type: "number",
      },
      {
        key: "area",
        name: translationMap["areaInM2"],
        type: "number",
      },
      {
        key: "volume",
        name: translationMap["volumeInM3"],
        type: "number",
      },
      {type: "section", key: "execution", name: translationMap["execution"]},
      {
        key: "builtAt",
        name: translationMap["builtAt"],
        type: "date",
      },
    ],
  };

  return kvtMeasurement;
}
