import {useMsal} from "@azure/msal-react";

import {Button, Box} from "@mui/material";
import {Logout} from "@mui/icons-material";

export default function SignOutButtonSimple({color}) {
  const {instance} = useMsal();

  // string

  const logoutString = "Logout";

  // handlers

  function handleClick() {
    instance.logout();
  }
  return (
    <Box
      sx={{
        color: color ? color : "common.white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        startIcon={<Logout />}
        size="small"
        variant="outlined"
        color="inherit"
        onClick={handleClick}
      >
        {logoutString}
      </Button>
    </Box>
  );
}
