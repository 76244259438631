import {Button, Box} from "@mui/material";
import {Download} from "@mui/icons-material";

export default function ButtonDownloadSceneDataInViewer({handleClick, inBlack}) {
  const downloadS = "Excel";
  return (
    <Box sx={{color: "common.white", width: 1}}>
      <Button
        startIcon={<Download />}
        onClick={handleClick}
        size="small"
        fullWidth
        variant={inBlack ? "outlined" : "contained"}
        color={inBlack ? "inherit" : "secondary"}
      >
        {downloadS}
      </Button>
    </Box>
  );
}