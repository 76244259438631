/*
 * elementType : {id,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useAddSceneElementType(scene) {
  const dispatch = useDispatch();

  const addSceneElementType = (elementType) => {
    if (!scene) return;
    let oldElementTypes = scene?.data?.elementTypes;
    if (!oldElementTypes) oldElementTypes = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, elementTypes: [...oldElementTypes, elementType]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return addSceneElementType;
}
