import {useDispatch, useSelector} from "react-redux";

import {Box, Typography, ListItem, ListItemButton, List} from "@mui/material";
import {ArrowForwardIos as Arrow} from "@mui/icons-material";

import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";
import {setLeftPanelSection} from "Features/leftPanel/leftPanelSlice";
import {
  // setSelectedElementTypeGroupInScope,
  setSelectedRessourceGroup,
} from "Features/measurements/measurementsSlice";
import {setShowScreenDatagridHelper} from "Features/leftPanel/leftPanelSlice";

import useRessourcesByScene from "../hooks/useRessourcesByScene";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function SectionSceneRessourcesInLeftPanel({
  scene,
  caplaEditor,
  inBlack,
}) {
  const dispatch = useDispatch();

  // label

  const allS = "Tous les éléments";

  // data

  let resGroups = useRessourceGroupsByScene(scene, {sortByName: true});
  const is3dEnabled = useSelector((s) => s.viewer3D.is3dEnabled);
  const ressources = useRessourcesByScene(scene);
  const packages = usePackagesByScene(scene);

  // WARNING : remove ressource groups starting with underscore
  resGroups = resGroups.filter((g) => !g?.startsWith("_"));

  // handlers

  async function handleGroupClick(group) {
    dispatch(setLeftPanelSection("MEASUREMENTS"));
    // dispatch(setSelectedElementTypeGroupInScope(group));
    const usedRessources = new Set(
      ressources.filter((r) => r.group === group).map((r) => r.id)
    );
    caplaEditor?.editor3d?.clear();
    await caplaEditor?.measDataManager?.setModelsFromGroup(
      group,
      "RESSOURCES",
      is3dEnabled,
      usedRessources
    );
    dispatch(setSelectedRessourceGroup(group));
  }

  function handleAllClick() {
    dispatch(setLeftPanelSection("ELEMENT_TYPES"));
    dispatch(setShowScreenDatagridHelper(false));
    caplaEditor?.measDataManager?.setModelsInShortMemory(
      packages.map((m) => m.id)
    );
  }

  return (
    <Box
      sx={{
        width: 1,
        borderRadius: "4px",
        height: 1,
        overflow: "auto",
      }}
    >
      <List
        sx={{
          bgcolor: inBlack ? "common.caplaBlack" : "common.white",
          color: inBlack ? "common.white" : "common.black",
          ...(inBlack && {
            border: (theme) => `1px solid ${theme.palette.background.default}`,
          }),
        }}
        disablePadding
      >
        {resGroups.map((group) => {
          const label = group;

          return (
            <ListItem
              key={group}
              disablePadding
              divider
              color="inherit"
              sx={{
                "&.MuiListItem-divider": {
                  borderColor: inBlack ? "common.white" : "divider",
                },
              }}
            >
              <ListItemButton onClick={() => handleGroupClick(group)}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: 1,
                    minWidth: 0,
                  }}
                >
                  <Typography variant="body2" noWrap>
                    {label}
                  </Typography>
                  <Arrow fontSize="small" />
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      {/* <ListItem
        disablePadding
        //divider
        color="inherit"
        sx={{
          "&.MuiListItem-divider": {
            borderColor: inBlack ? "common.white" : "divider",
          },
        }}
      >
        <ListItemButton sx={{mt: 2}} onClick={() => handleAllClick()}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: 1,
              minWidth: 0,
            }}
          >
            <Typography variant="body2" sx={{fontStyle: "italic"}} noWrap>
              {allS}
            </Typography>
            <Arrow fontSize="small" />
          </Box>
        </ListItemButton>
      </ListItem> */}
    </Box>
  );
}
