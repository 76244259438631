import {Box} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import {
  setFilterIdsByTab,
  setSelectedElementTypeGroupId,
} from "../filtersSlice";

import ListElementTypesMultipleWithGroups from "Features/elementTypes/components/ListElementTypesMultipleWithGroups";

export default function SectionFilterList({tab, items}) {
  const dispatch = useDispatch();

  // data

  const filtersByTab = useSelector((s) => s.filters.filtersByTab);
  const multipleMap = useSelector((s) => s.filters.multipleSelectionTabMap);

  // data - elementTypes
  const selectedElementTypeGroupId = useSelector(
    (s) => s.filters.selectedElementTypeGroupId
  );
  const groupCountMap = {};
  const elementTypeFilterIds = filtersByTab["ELEMENT_TYPES"]?.ids;
  items.forEach((elementType) => {
    if (elementTypeFilterIds?.includes(elementType?.id)) {
      const groupId = elementType?.groupId ? elementType.groupId : "undefined";
      let oldCount = groupCountMap[groupId];
      let newCount = 1;
      if (oldCount) {
        newCount = oldCount + 1;
      }
      groupCountMap[groupId] = newCount;
    }
  });

  // handlers

  function handleIdsChange(tab, ids) {
    dispatch(setFilterIdsByTab({tab, ids}));
  }

  // handlers - elementTypes

  function handleSelectedElementTypeGroupProxyChange(groupProxy) {
    dispatch(setSelectedElementTypeGroupId(groupProxy?.id));
  }

  const listMap = {
    ELEMENT_TYPES: (
      <ListElementTypesMultipleWithGroups
        elementTypes={items}
        selectedElementTypeIds={filtersByTab["ELEMENT_TYPES"]?.ids}
        onSelectionChange={(ids) => handleIdsChange("ELEMENT_TYPES", ids)}
        multipleSelection={multipleMap["ELEMENT_TYPES"]}
        selectedGroupId={selectedElementTypeGroupId}
        onSelectedGroupProxyChange={handleSelectedElementTypeGroupProxyChange}
        counters={groupCountMap}
      />
    ),
  };

  let list = listMap[tab];
  if (!list) return <Box>A faire</Box>;

  return <Box>{list}</Box>;
}
