import {client} from "API/capla360";

export const createIssueService = async ({
  accessToken,
  listingId,
  issue,
  sceneId,
}) => {
  const transformedIssue = {...issue, scene: sceneId};
  if (!issue.context) {
    const viewpoint = issue.content?.filter((c) => c.type === "viewpoint");
    if (viewpoint?.length > 0) {
      transformedIssue.context = viewpoint[0].context;
    } else {
      transformedIssue.context = {sceneContext: {}};
    }
  }

  const response = await client.post(
    `listings/${listingId}/issues/`,
    transformedIssue,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {item: response.data, listingId};
};

export const fetchIssuesService = async ({accessToken, listingId}) => {
  const response = await client.get(`/listings/${listingId}/issues/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, listingId};
};

export const fetchIssuesBySceneService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/issues/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export const updateIssueService = async ({accessToken, issue}) => {
  const issueId = issue.id;
  const listingId = issue.listingId ? issue.listingId : issue.listing;
  const response = await client.put(
    `/listings/${listingId}/issues/${issueId}`,
    issue,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
};

export const deleteIssueService = async ({accessToken, issue}) => {
  const issueId = issue.id;
  const listingId = issue.listingId ? issue.listingId : issue.listing;
  const response = await client.delete(
    `/listings/${listingId}/issues/${issueId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {listingId, issueId};
};
