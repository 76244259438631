import useUpdateSceneRessource from "Features/ressources/hooks/useUpdateSceneRessource";

export default function useAddPoiToRessource(scene) {
  const updateRessource = useUpdateSceneRessource(scene);

  const addPoiToRessource = async ({poi, ressource}) => {
    const newRessource = {...ressource};
    if (ressource.pois) {
      newRessource.pois = [...ressource.pois, poi];
    } else {
      newRessource.pois = [poi];
    }
    await updateRessource(newRessource);
  };

  return addPoiToRessource;
}
