import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "nanoid";

import {Menu, MenuItem} from "@mui/material";

// import {setOpenContextMenu} from "../ressourcesSlice";
import useSelectedRessource from "../hooks/useSelectedRessource";
import {addRessourceToEditedRessources} from "../ressourcesSlice";
// import useAddSceneRessource from "../hooks/useAddSceneRessource";

import getNextNum from "Utils/getNextNum";
import useSelectedEditedRessource from "../hooks/useSelectedEditedRessource";

export default function DatagridRessourcesContextMenu({
  scene,
  open,
  onClose,
  anchorPosition,
  nums,
}) {
  const dispatch = useDispatch();

  // strings

  const duplicateS = "Dupliquer";
  const insertS = "Insérer un article";

  // data

  const selectedEditedRessource = useSelectedEditedRessource();

  // handlers

  function handleDuplicate() {
    const duplicatedRessource = {...selectedEditedRessource};
    duplicatedRessource.id = nanoid();
    dispatch(addRessourceToEditedRessources(duplicatedRessource));
    // end
    onClose();
  }

  function handleInsert() {
    const num = selectedEditedRessource?.num;
    const nextNum = getNextNum(num, nums);
    const newRessource = {
      id: nanoid(),
      group: selectedEditedRessource.group,
      num: nextNum,
      code: nextNum,
      name: "",
    };
    dispatch(addRessourceToEditedRessources(newRessource));
    // end
    onClose();
  }

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            onClose();
          },
        },
      }}
    >
      <MenuItem onClick={handleInsert}>{insertS}</MenuItem>
      <MenuItem onClick={handleDuplicate}>{duplicateS}</MenuItem>
    </Menu>
  );
}
