import React from "react";

import {Button, Box} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import useRoomsByScene from "../hooks/useRoomsByScene";

import getCleanRooms from "../utils/getCleanRooms";
import useSetSceneRooms from "../hooks/useSetSceneRooms";

export default function ButtonRefreshRooms({scene}) {
  // string

  const refreshS = "Refresh";

  // data

  const rooms = useRoomsByScene(scene);
  const setRooms = useSetSceneRooms(scene);

  // handlers

  function handleClick() {
    const newRooms = getCleanRooms(rooms);
    setRooms(newRooms);
  }
  return (
    <Box sx={{}}>
      <Button
        startIcon={<Refresh />}
        size="small"
        onClick={handleClick}
        color="inherit"
      >
        {refreshS}
      </Button>
    </Box>
  );
}
