import useFilterIdsByEntityFromShortcut from "./useFilterIdsByEntityFromShortcut";
import useFilterIdsByEntityFromSelection from "./useFilterIdsByEntityFromSelection";
import getFilteredMeasurementsByEntities from "./utils/getFilteredMeasurementsByEntities";
import getGlobalFilterIdsByEntity from "./utils/getGlobalFilterIdsByEntity";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useArticlesByGroup from "Features/articles/hooks/useArticlesByGroup";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useSelectedArticlesGroup from "Features/articles/hooks/useSelectedArticlesGroup";

export default function useGetFilteredMeasurements({
  scene,
  // applyFiltersMultiple,
  applyShortcuts,
  applySelection,
  keepDeletedMeas,
}) {
  // from shortcuts

  const idsFromShortcut = useFilterIdsByEntityFromShortcut(scene);

  // from selection

  const idsFromSelection = useFilterIdsByEntityFromSelection(scene);

  // shortcut + selection +

  const filterIdsByEntity = getGlobalFilterIdsByEntity({
    idsFromShortcutByEntity: applyShortcuts ? idsFromShortcut : null,
    idsFromSelectionByEntity: applySelection ? idsFromSelection : null,
  });

  // data

  const articlesGroup = useSelectedArticlesGroup();

  // entities map

  const entitiesMap = {};

  entitiesMap["elementType"] = useElementTypesBySceneProxy(scene);
  entitiesMap["ressource"] = useRessourcesByScene(scene);
  entitiesMap["article"] = useArticlesByGroup(scene, articlesGroup);
  entitiesMap["sector"] = useSectorsByScene(scene);

  const getFilteredMeasurements = (measurements) => {
    let fms = getFilteredMeasurementsByEntities(
      measurements,
      filterIdsByEntity,
      entitiesMap
    );

    if (!keepDeletedMeas) {
      fms = fms.filter((fm) => !fm?.deleted);
    }
    return fms;
  };

  return getFilteredMeasurements;
}
