import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {setSubjectElementTypeMap} from "../annotatedPdfSlice";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getSubjectTypeMapFromFile from "Features/excel/utils/getSubjectTypeMapFromFile";
import excelSubjectTypeMapToSubjectTypeMap from "../utils/excelSubjectTypeMapToSubjectTypeMap";

export default function DialogImportSubjectTypeMapFromExcel({
  open,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Importez une table Annotation <=> Type";

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );

  // state

  const [subjectTypeMap, setSubjectTypeMap] = useState(null);
  const [file, setFile] = useState(null);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    let excelSubjectTypeMap = await getSubjectTypeMapFromFile(file);
    const _subjectTypeMap =
      excelSubjectTypeMapToSubjectTypeMap(excelSubjectTypeMap);
    setSubjectTypeMap(_subjectTypeMap);
  }
  function handleCancelClick() {
    onClose();
  }
  function handleSaveClick() {
    console.log("SAVE subjectTypeMap", subjectTypeMap);
    //dispatch(setSubjectTempTypeMap(subjectTypeMap));
    dispatch(
      setSubjectElementTypeMap({...subjectElementTypeMap, ...subjectTypeMap})
    );
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          {/* <Box sx={{bgcolor: "background.default", p: 1}}>
            <Typography variant="body2">{message}</Typography>
          </Box> */}
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
