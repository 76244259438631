import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";

import {updateArticleTemp} from "../articlesSlice";

import {Box, Button, Typography, Menu, ListItemButton} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import getStyleFromItem from "../utils/getStyleFromItem";

export default function BlockEditableArticleStyle({article}) {
  const dispatch = useDispatch();

  // state

  const style = article.style || "normal";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // helpers

  const styleLabelMap = {
    title1: "Titre 1",
    title2: "Titre 2",
    element: "Ouvrage",
    overview: "Ouvrage (résumé)",
    normal: "Normal",
    detail: "Détail",
    subDetail: "Sous-détail",
  };

  const options = [
    "title1",
    "title2",
    "normal",
    "overview",
    "element",
    "detail",
    "subDetail",
  ];

  // helpers

  const label = styleLabelMap[style];

  // handlers

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleStyleClick(style) {
    dispatch(updateArticleTemp({id: article.id, style}));
    setAnchorEl(null);
  }
  return (
    <>
      <Box sx={{color: "text.secondary", height: "20px", px: 2}}>
        <Button
          size="small"
          color="inherit"
          colosize="small"
          onClick={handleClick}
          endIcon={<Down />}
        >
          <Typography sx={{fontSize: 10}}>{label}</Typography>
        </Button>
        {/* <ListItemButton>
          <Box sx={{}}>
            <Typography varian>{label}</Typography>
          </Box>
        </ListItemButton> */}
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        sx={{p: 0, m: 0}}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((option) => {
          const {bg, color, italic, bold, underline, alignToRight} =
            getStyleFromItem({
              style: option,
            });
          return (
            <ListItemButton
              sx={{p: 0, m: 0}}
              key={option}
              size="small"
              onClick={() => handleStyleClick(option)}
            >
              <Box
                sx={{
                  bgcolor: bg ?? "unset",
                  width: 1,
                  p: 0.5,
                  display: "flex",
                  justifyContent: alignToRight ? "end" : "start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    fontStyle: italic ? "italic" : "normal",
                    fontWeight: bold ? "bold" : "normal",
                    textDecoration: underline ? "underline" : "none",
                  }}
                >
                  {styleLabelMap[option]}
                </Typography>
              </Box>
            </ListItemButton>
          );
        })}
      </Menu>
    </>
  );
}
