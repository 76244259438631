import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";

import {setShowScreenDatagridHelper} from "Features/leftPanel/leftPanelSlice";
import {setShowScreenModelsLoaderHelper} from "Features/viewer3D/viewer3DSlice";
import {setShowScreenPdfViewerHelper} from "Features/pdf/pdfSlice";

import {Checkbox, Typography, Box, FormControlLabel} from "@mui/material";

export default function ButtonDisableViewerHelperScreens() {
  const dispatch = useDispatch();

  // strings

  const label = "Cochez cette case pour passer le mode introductif.";

  // state

  const [checked, setChecked] = useState("false");

  // init

  useEffect(() => {
    let disableScreens = localStorage.getItem("disableViewerHelperScreens");
    if (!disableScreens) disableScreens = "false";
    setChecked(disableScreens);
    if (disableScreens === "true") {
      dispatch(setShowScreenDatagridHelper(false));
      dispatch(setShowScreenModelsLoaderHelper(false));
      dispatch(setShowScreenPdfViewerHelper(false));
    } else {
      dispatch(setShowScreenDatagridHelper(true));
      dispatch(setShowScreenModelsLoaderHelper(true));
      dispatch(setShowScreenPdfViewerHelper(true));
    }
  }, []);
  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    localStorage.setItem(
      "disableViewerHelperScreens",
      checked ? "true" : "false"
    );
    setChecked(checked ? "true" : "false");
    if (checked) {
      dispatch(setShowScreenDatagridHelper(false));
      dispatch(setShowScreenModelsLoaderHelper(false));
      dispatch(setShowScreenPdfViewerHelper(false));
    } else {
      dispatch(setShowScreenDatagridHelper(true));
      dispatch(setShowScreenModelsLoaderHelper(true));
      dispatch(setShowScreenPdfViewerHelper(true));
    }
  }

  return (
    <Box sx={{pl: 2}}>
      <FormControlLabel
        sx={{color: "common.white"}}
        control={
          <Checkbox
            checked={checked === "true"}
            onChange={handleChange}
            size="small"
            sx={{color: "common.white"}}
          />
        }
        label={
          <Typography color="inherit" variant="body2">
            {label}
          </Typography>
        }
      />
    </Box>
  );
}
