import getItemsMapById from "Utils/getItemsMapById";
export default function getMeasurementsCountByRessourceGroupIdMap(
  measurements,
  ressources
) {
  //
  const groupIdCountMap = {};
  //
  const ressourceMap = getItemsMapById(ressources);
  //
  measurements.forEach((measurement) => {
    if (measurement.res) {
      measurement.res.forEach(({resId}) => {
        const measRes = ressourceMap[resId];
        const measResGroupId = measRes ? measRes.groupId : "undefined";
        const prev = groupIdCountMap[measResGroupId];
        groupIdCountMap[measResGroupId] = prev ? prev + 1 : 1;
      });
    }
  });
  //
  return groupIdCountMap;
}
