import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import ListRawSubjectElementTypes from "./ListRawSubjectElementTypes";
import ButtonExcelSubjectElementTypeMap from "./ButtonExcelSubjectElementTypeMap";
import ButtonRefreshSubjectElementTypeMap from "./ButtonRefreshSubjectElementTypeMap";
//import ListRawSubjectElementTypesHeader from "./ListRawSubjectElementTypesHeader";

import {setSubjectElementTypeMap} from "../annotatedPdfSlice";
import useRawMeasurementSubjects from "../hooks/useRawMeasurementSubjects";
// import useAnnotatedPdfModel from "../hooks/useAnnotatedPdfModel";

import useAddSceneElementTypes from "Features/elementTypes/hooks/useAddSceneElementTypes";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
// import Stretch from "Features/ui/components/Stretch";
// import ButtonExcel from "Features/rooms/components/ButtonExcel";
// import SelectOptions from "Components/SelectOptions";

export default function DialogExtractElementTypes({open, onClose, scene}) {
  const dispatch = useDispatch();

  // strings

  const title = "Extract types from PDF annotations";

  const extractS = "Extract";
  const cancelS = "Cancel";

  // data

  const addSceneElementTypes = useAddSceneElementTypes(scene);

  const elementTypes = useElementTypesBySceneProxy(scene);

  const subjects = useRawMeasurementSubjects(scene);

  // const pdfModel = useAnnotatedPdfModel();

  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );

  // helpers

  // const mnger = editor?.pdfEditor?.annotationsManager?.measurementsPdfManager;

  // helpers - canExtract

  const canExtract = true;

  // handlers

  function handleClose() {
    onClose();
  }

  // handlers - subjectCode

  function handleSubjectElementTypeChange(subjectElementType) {
    const newMap = {...subjectElementTypeMap};
    newMap[subjectElementType.subject] = subjectElementType.elementType;
    dispatch(setSubjectElementTypeMap(newMap));
  }

  // handlers - extract

  async function handleExtract() {
    const types = [];
    Object.entries(subjectElementTypeMap).forEach(([subject, elementType]) => {
      if (elementType.code && elementType.group) {
        let type = {...elementType, subject};
        const existingType = elementTypes.find(
          (t) => t.code === elementType.code && t.group === elementType.group
        );
        if (!existingType) {
          type.id = nanoid();
        } else {
          type.id = existingType.id;
        }
        types.push(type);
      }
    });
    console.log("ADD TYPES", types);
    addSceneElementTypes(types);
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false} fullScreen>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{mt: 2}}>
          {/* <ListRawSubjectElementTypesHeader /> */}
          <Box sx={{p: 1, display: "flex", "&>*:not(:last-child)": {mr: 1}}}>
            <ButtonExcelSubjectElementTypeMap scene={scene} />
            <ButtonRefreshSubjectElementTypeMap scene={scene} />
          </Box>
          <ListRawSubjectElementTypes
            items={subjects}
            scene={scene}
            onSubjectElementTypeChange={handleSubjectElementTypeChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelS}</Button>
        <Button disabled={!canExtract} onClick={handleExtract}>
          {extractS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
