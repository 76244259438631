import {Box} from "@mui/material";

import usePageType from "../usePageType";
import useSceneModule from "../useSceneModule";

import {useIsAuthenticated} from "@azure/msal-react";

// import ButtonCancelBuildScenario from "Features/buildbox/components/ButtonCancelBuildScenario";
// import ToggleViewer3D from "Features/viewer3D/components/ToggleViewer3D";
import ButtonDialogSync from "Features/scenes/components/ButtonDialogSync";
// import ButtonDialogShare from "Features/share/components/ButtonDialogShare";
import IconButtonMemberships from "Features/share/components/IconButtonMemberships";
import ConnectButton from "Features/scenes/components/ConnectButton";
import ButtonProfile from "Features/profile/components/ButtonProfile";

export default function ButtonRightTopBar({scene}) {
  // data

  const pageType = usePageType();
  const sceneModule = useSceneModule();
  const isAuthenticated = useIsAuthenticated();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {pageType === "SCENE" && sceneModule === "HOME" && scene?.id && (
        <IconButtonMemberships scene={scene} />
      )}
      {(pageType === "SCENE" || pageType === "COLORING") && scene?.id && (
        <ButtonDialogSync scene={scene} />
      )}
      {pageType === "HOME" && <ButtonProfile />}
      {/* {pageType === "SCENE" && sceneModule === "HOME" && scene?.id && (
        <ButtonDialogShare />
      )} */}
      {/* {pageType === "SCENE" && !scene?.id && isAuthenticated && (
        <ConnectButton />
      )} */}
      {/* {pageType === "SCENE" && <ToggleViewer3D editor={editor} />} */}
    </Box>
  );
}
