import {useSelector, useDispatch} from "react-redux";

import {setEnabledPackageIdsInMainScope} from "../overviewerSlice";

import usePackagesInMainScope from "../hooks/usePackagesInMainScope";
import useSceneModule from "Features/navigation/useSceneModule";

import {useNavigate, useParams} from "react-router-dom";

import {Box} from "@mui/material";

import ContainerPackageInTopBar from "Features/elementPackages/components/ContainerPackageInTopBar";
import usePdfsByScene from "Features/pdf/hooks/usePdfsByScene";
import getItemsMapById from "Utils/getItemsMapById";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import useIsStagingEnv from "../hooks/useIsStagingEnv";

import SectionPackagesInTopBarCallToAction from "./SectionPackagesInTopBarCallToAction";

export default function SectionPackagesInTopBar({scene}) {
  const dispatch = useDispatch();
  const {sceneId} = useParams();
  const navigate = useNavigate();
  const isStagingEnv = useIsStagingEnv();

  // data

  const sceneModule = useSceneModule();

  const addHelpers = ["EDIT_MEASUREMENTS"].includes(sceneModule);
  const models = usePackagesInMainScope({addHelpers});

  const pdfModels = usePdfsByScene(scene);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const os = useSelector((s) => s.ui.openSections);

  const enabledPackageIds = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );
  const editedPackageId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );
  const scopeAndCounter = useSelector((s) => s.overviewer.scopeAndCounter);

  // helpers

  const pdfModelsMap = getItemsMapById(pdfModels);

  const modelIdCountMap = scopeAndCounter.modelIdCountMap ?? {};

  // helpers - packages in list

  const packagesInPdf = models
    .filter((m) => m.fromModel.modelId === selectedPdfModelId)
    .sort((a, b) => a.name.localeCompare(b.name));

  const packagesOutPdf = models
    .filter((m) => m.fromModel.modelId !== selectedPdfModelId)
    .sort((a, b) => a.name.localeCompare(b.name));

  let packagesInList = packagesInPdf;

  if (os.viewer3D) packagesInList = [...packagesInPdf, ...packagesOutPdf];

  // helpers - callToAction

  // let showCallToAction =
  //   !["EDIT_MEASUREMENTS"].includes(sceneModule) &&
  //   enabledPackageIds.length === 0 &&
  //   packagesInList?.length > 0;

  const showCallToAction = false;

  // handlers

  function handleSelect(modelId) {
    const newEnabledIds = enabledPackageIds.filter((id) => id !== modelId);
    if (!enabledPackageIds.includes(modelId)) newEnabledIds.push(modelId);
    dispatch(setEnabledPackageIdsInMainScope(newEnabledIds));
  }

  function handleEdit(modelId) {
    dispatch(setSelectedMeasurementsModelId(modelId));
    let url = `/bimboxes/${sceneId}/${modelId}/measurements`;
    if (isStagingEnv) url = url + "?env=staging";
    navigate(url);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        minHeight: 0,
        borderRadius: "8px",
        p: 1,
        //...(showCallToAction && {bgcolor: "warning.light"}),
      }}
    >
      <Box
        sx={{
          //border: "1px solid orange",
          display: "flex",
          flexWrap: "wrap",
          flexGrow: 1,
          minHeight: 0,
          //maxWidth: 400,
          overflow: "hidden",
          p: 2,
          "&>*:not(:last-child)": {
            mr: 2,
            mb: 1,
          },
        }}
      >
        {packagesInList.map((m) => {
          const name = m.name;
          const pdf = pdfModelsMap[m.fromModel?.modelId];
          const pdfName = pdf ? pdf.name : "-?-";
          const measCount = modelIdCountMap[m.id] ?? "?";
          const enabled = enabledPackageIds?.includes(m.id);
          const edited =
            sceneModule === "EDIT_MEASUREMENTS" && editedPackageId === m.id;
          //
          return (
            <ContainerPackageInTopBar
              key={m.id}
              id={m.id}
              name={name}
              pdfName={pdfName}
              measCount={measCount}
              enabled={enabled}
              onClick={handleSelect}
              edited={edited}
              onEdit={handleEdit}
            />
          );
        })}
      </Box>
      {showCallToAction && <SectionPackagesInTopBarCallToAction />}
    </Box>
  );
}
