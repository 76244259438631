import {useSelector} from "react-redux";
// import {useSelector, useDispatch} from "react-redux";
// import {useEffect} from "react";

// import {updateModelMeasurements} from "./measurementsSlice";

export default function useSceneMeasurements(scene) {
  // const dispatch = useDispatch();

  // data

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) =>
      m.sceneClientId === scene?.clientId &&
      m.type === "MEASUREMENTS" &&
      m.enabled
  );

  // const loadedMeasurementsModels = useSelector(
  //   (s) => s.measurements.loadedMeasurementsModels
  // );

  // const scenesStatus = useSelector((s) => s.viewer3D.scenesStatus);

  // // helper - sceneStatus

  // const sceneStatus = scenesStatus[scene?.clientId];

  // // helpers - models to load

  // const modelsToLoad = models.filter(
  //   (m) => !loadedMeasurementsModels.includes(m.id)
  // );

  // // effect - update measurements to load

  // const shouldLoad = sceneStatus === "preloaded" && modelsToLoad.length > 0;

  // useEffect(() => {
  //   if (shouldLoad) {
  //     console.log("haaaaaaaaaaaa", "model loading")
  //     modelsToLoad.forEach((model) => {
  //       dispatch(
  //         updateModelMeasurements({
  //           measurementsModelId: model.id,
  //           measurements: model?.measurementsData?.measurements,
  //           pdfModelId: model.fromModel?.modelId,
  //         })
  //       );
  //     });
  //   }
  // }, [shouldLoad]);

  const modelIds = new Set(models.map((m) => m.id));

  let measurements = useSelector((s) => s.measurements.items);

  measurements = measurements.filter(
    (m) => modelIds.has(m.measurementsModelId) && m.deleted !== true
  );

  // console.log("haaaaaaaaaaaa", measurements.length)

  return measurements;
}
