import {Vector2} from "three";

export function snapPointToCircle(origin, radius, point) {
  const O = new Vector2(origin.x, origin.y);
  const P = new Vector2(point.x, point.y);
  const OP = P.sub(O);
  const angle = OP.angle();

  const OC = OP.normalize().multiplyScalar(radius);
  //const C = OC.sub(O);

  return {
    pos: {
      x: Math.floor(OC.x),
      y: Math.floor(OC.y),
    },
    angleRad: angle,
  };
}

export function snapAngleRadToCircle(origin, radius, angle) {
  const O = new Vector2(0, 0);
  const P = new Vector2(radius, 0);
  P.rotateAround(O, angle);

  return {
    x: Math.floor(P.x),
    y: Math.floor(P.y),
  };
}
