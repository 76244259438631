import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Menu,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Checkbox,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import {
  MoreVert as More,
  Delete,
  ContentCut as Edit,
  Edit as Rename,
} from "@mui/icons-material";

import DialogRenameClippingPlane from "./DialogRenameClippingPlane";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";
import {
  updateClippingPlanesStatus,
  updateClippingPlane,
  deleteClippingPlane,
  updateScene,
} from "Features/scenes/scenesSlice";

export default function ListItemClippingPlane({clippingPlane, editor3d, scene}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("common");

  // strings

  const deleteString = capitalizeStart(t("delete"));
  const saveString = capitalizeStart(t("save"));
  const editString = capitalizeStart(t("edit"));
  const renameString = capitalizeStart(t("rename"));

  // data

  const clippingPlanesStatus = useSelector(
    (state) => state.scenes.clippingPlanesStatus
  );

  const cps = clippingPlanesStatus.find(
    (cp) => cp.clippingPlaneId === clippingPlane.id
  );
  const clippingPlaneId = cps?.clippingPlaneId;
  const disabled = cps?.disabled;
  const edit = cps?.edit;

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);

  const open = Boolean(anchorEl);

  // handlers - save

  function handleSaveClick() {
    dispatch(updateClippingPlanesStatus({clippingPlaneId, edit: false}));
    const editorPlane = editor3d?.clipper.savePlane(clippingPlaneId);
    const newClippingPlane = {...clippingPlane, ...editorPlane};
    dispatch(updateClippingPlane(newClippingPlane));

    // update scene

    const newScene = {...scene};
    const clippingPlanes = scene.data?.clippingPlanes
      ? [...scene.data.clippingPlanes]
      : [];
    const newClippingPlanes = clippingPlanes.map((cp) => {
      if (cp.id === newClippingPlane.id) {
        return newClippingPlane;
      } else {
        return cp;
      }
    });
    if (!scene.data) {
      newScene.data = {clippingPlanes: newClippingPlanes};
    } else {
      const newSceneData = {...scene.data};
      newSceneData.clippingPlanes = newClippingPlanes;
      newScene.data = newSceneData;
    }
    dispatch(updateScene({scene: newScene}));
  }

  // handlers - check

  function handleCheckChange(e) {
    const checked = e.target.checked;
    dispatch(updateClippingPlanesStatus({clippingPlaneId, disabled: !checked}));
    if (checked) {
      editor3d?.clipper.enablePlane(clippingPlaneId);
    } else {
      editor3d?.clipper.disablePlane(clippingPlaneId);
    }
  }
  // handlers - menu

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  // -- Rename

  function handleRenameClick() {
    setRenameOpen(true);
    setAnchorEl(null);
  }

  function handleRenameDialogClose() {
    setRenameOpen(false);
  }

  // handlers - edit

  function handleEditClick() {
    setAnchorEl(null);
    dispatch(updateClippingPlanesStatus({clippingPlaneId, edit: true}));
    editor3d?.clipper.editPlane(clippingPlaneId);
  }

  // handlers - delete

  function handleDeleteClick() {
    setDeleteOpen(true);
    setAnchorEl(null);
  }

  function handleDeleteConfirm() {
    editor3d?.clipper.deletePlane(clippingPlaneId);
    dispatch(deleteClippingPlane(clippingPlaneId));

    // update scene

    const newScene = {...scene};
    const clippingPlanes = scene.data?.clippingPlanes
      ? [...scene.data.clippingPlanes]
      : [];
    const newClippingPlanes = clippingPlanes.filter(
      (cp) => cp.id !== clippingPlaneId
    );
    if (!scene.data) {
      newScene.data = {clippingPlanes: newClippingPlanes};
    } else {
      const newSceneData = {...scene.data};
      newSceneData.clippingPlanes = newClippingPlanes;
      newScene.data = newSceneData;
    }
    dispatch(updateScene({scene: newScene}));
  }

  function handleDeleteCancel() {
    setDeleteOpen(false);
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{display: "flex", alignItems: "center"}}>
        <Checkbox
          size="small"
          checked={!disabled}
          onChange={handleCheckChange}
        />
        <Typography variant="body2">{clippingPlane.name}</Typography>
      </Box>
      {edit ? (
        <Button size="small" onClick={handleSaveClick}>
          {saveString}
        </Button>
      ) : (
        <IconButton fontSize="small" onClick={handleMoreClick}>
          <More fontSize="small" />
        </IconButton>
      )}

      <Menu open={open} onClose={handleMenuClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleRenameClick}>
          <ListItemIcon>
            <Rename fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{renameString} </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{editString} </Typography>
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{deleteString} </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>

      <DeleteDialog
        open={deleteOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource="clippingPlane"
      />

      <DialogRenameClippingPlane
        open={renameOpen}
        clippingPlane={clippingPlane}
        onClose={handleRenameDialogClose}
        scene={scene}
      />
    </Box>
  );
}
