import React from "react";

import {useDispatch} from "react-redux";
import {setSelectedIssueId} from "Features/ui/uiSlice";

import {Button} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function ButtonClearIssueSelectionUi() {
  const dispatch = useDispatch();

  // strings

  const clearS = "clear selection";

  // handlers

  function handleClick() {
    dispatch(setSelectedIssueId(null));
  }

  return (
    <Button startIcon={<Close />} onClick={handleClick} size="small">
      {clearS}
    </Button>
  );
}
