import {Box, Typography} from "@mui/material";
import ContainerElementType from "Features/elementTypes/components/ContainerElementType";
import ContainerElementTypeRessourcesWithQuantities from "./ContainerElementTypeRessourcesWithQuantities";
import useDrawingShapeImage from "Features/measurements/useDrawingShapeImage";
import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function SectionElementTypeWithRessourcesOverview({
  elementType,
  scene,
  viewer,
}) {
  const kvt = {
    key: "drawingShapeImage",
    fields: [
      {
        key: "helperImage",
        type: "imageRead",
        height: "110px",
      },
    ],
  };
  const drawingShapeImage = useDrawingShapeImage(elementType?.drawingShape);

  return (
    <Box>
      <KeyValueForm
        item={{...elementType, helperImage: drawingShapeImage}}
        template={kvt}
      />
      <ContainerElementType elementType={elementType} />

      <ContainerElementTypeRessourcesWithQuantities
        elementType={elementType}
        scene={scene}
        viewer={viewer}
      />
    </Box>
  );
}
