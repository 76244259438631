import {Button} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function ButtonUpdate3D({measurements, editorPdf}) {
  const buttonS = "3D";
  // console.log("measurements", measurements);

  function handleRefresh() {
    const mnger = editorPdf?.annotationsManager.measurementsPdfManager;
    measurements.forEach((measurement) => {
      mnger?.updateMeasurement3D(measurement);
    });
  }

  return (
    <Button
      color="inherit"
      startIcon={<Refresh />}
      size="small"
      onClick={handleRefresh}
    >
      {buttonS}
    </Button>
  );
}
