import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
} from "@mui/material";

export default function SectionSettings({
  isPdfLayout,
  onIsPdfLayoutChange,
  positionInPdf,
  onPositionInPdfChange,
  showQties,
  onShowQtiesChange,
}) {
  const pdfLayoutS = "Format PDF";
  const showQtiesS = "Quantités";

  const x = positionInPdf?.x;
  const y = positionInPdf?.y;
  const dim = positionInPdf?.dim;

  function handleXChange(e) {
    onPositionInPdfChange({x: e.target.value, y, dim});
  }
  function handleYChange(e) {
    onPositionInPdfChange({x, y: e.target.value, dim});
  }
  function handleDimChange(e) {
    onPositionInPdfChange({x, y, dim: e.target.value});
  }

  return (
    <Box sx={{px: 2}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 1,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={isPdfLayout}
              onChange={(e) => onIsPdfLayoutChange(e.target.checked)}
            />
          }
          label={<Typography sx={{fontSize: 12}}>{pdfLayoutS}</Typography>}
        />
        <FormControlLabel
          sx={{ml: 2}}
          control={
            <Switch
              size="small"
              checked={showQties}
              onChange={(e) => onShowQtiesChange(e.target.checked)}
            />
          }
          label={<Typography sx={{fontSize: 12}}>{showQtiesS}</Typography>}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 1,
          mt: 1,
        }}
      >
        <TextField
          sx={{width: 60}}
          value={x}
          onChange={handleXChange}
          size="small"
          label={<Typography sx={{fontSize: 12}}>X</Typography>}
          InputProps={{
            sx: {fontSize: 12},
          }}
        />
        <TextField
          sx={{width: 60}}
          value={y}
          onChange={handleYChange}
          size="small"
          label={<Typography sx={{fontSize: 12}}>Y</Typography>}
          InputProps={{
            sx: {fontSize: 12},
          }}
        />
        <TextField
          sx={{width: 60}}
          value={dim}
          onChange={handleDimChange}
          size="small"
          label={<Typography sx={{fontSize: 12}}>{"dim"}</Typography>}
          InputProps={{
            sx: {fontSize: 12},
          }}
        />
      </Box>
    </Box>
  );
}
