import React, {useState} from "react";
import {useNavigate, useLocation} from "react-router";
import {Box, Fade, Typography, Button} from "@mui/material";
import {ViewInAr as BoxIcon} from "@mui/icons-material";

import Stretch from "Features/ui/components/Stretch";

import Landing from "../components/Landing";
import BoxCard from "../components/BoxCard";
import SceneImage from "../components/SceneImage";

export default function MobileLayout({scene}) {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  // local states

  const [landing, setLanding] = useState(true);

  // helpers

  // handlers

  function handleSceneOpenClick() {
    setLanding(false);
    setTimeout(() => navigate(`${pathname}/viewer`), 500);
  }

  return (
    <Box>
      <Stretch>
        <Fade in={landing} timeout={{enter: 0, exit: 500}}>
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: 1,
                color: "common.white",
                background: (theme) =>
                  `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
              }}
            >
              <Box
                sx={{
                  width: 1,
                  height: (theme) => theme.spacing(7),
                  display: "flex",
                  alignItems: "center",
                  fontSize: 24,
                  p: 1,
                }}
              >
                <BoxIcon fontSize="inherit" />
                <Typography variant="h5" color="inherit" noWrap sx={{ml: 2}}>
                  <b>{scene?.title}</b>
                </Typography>
              </Box>
            </Box>
            <Box sx={{flexGrow: 1, width: 1, height: 1}}>
              <SceneImage scene={scene} />
            </Box>
            <Button
              variant="contained"
              sx={{
                position: "absolute",
                top: "50%",
                borderRadius: (theme) => theme.shape.borderRadius,
              }}
              onClick={handleSceneOpenClick}
            >
              <Typography variant="h6">Open the box</Typography>
            </Button>
          </Box>
        </Fade>
      </Stretch>
    </Box>
  );
}
