export default function markersForDataGrid(markers) {
  if (!Array.isArray(markers)) {
    return [];
  } else {
    return markers.map((m) => ({
      id: m.id,
      description: m.description,
      x: m.x.toFixed(3),
      y: m.z.toFixed(3),
      z: -m.y.toFixed(3),
    }));
  }
}
