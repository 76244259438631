import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";

import {setSelectedRoomId, setTempRoom} from "../roomsSlice";
import useAddSceneRoom from "../hooks/useAddSceneRoom";

// import useAccessToken from "Features/auth/useAccessToken";
// import {urlToFile} from "Features/files/utils";
// import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";


export default function ActionsNewRoomInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const createS = "Ajouter";

  // data

  const tempRoom = useSelector((s) => s.rooms.tempRoom);
  const addRoom = useAddSceneRoom(scene);

  // handlers

  async function handleCreateClick() {
    const newRoom = {id: nanoid(), ...tempRoom};
    addRoom(newRoom);
    dispatch(setSelectedRoomId(newRoom.id));
    dispatch(setTempRoom({}));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
