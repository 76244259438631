import {MathUtils, Object3D, MeshBasicMaterial, BoxGeometry, Mesh} from "three";

import CaplaElement from "./CaplaElement";

const material = new MeshBasicMaterial({color: 0x00ff00});

class CaplaModel {
  type;
  model;
  entityID;
  object;
  editor3d;

  selectedElement; // selectedElement codeName

  constructor({model, editor3d}) {
    this.editor3d = editor3d;
    this.type = "CAPLA_MODEL";
    this.entityID = model.id;
    this.model = model;
    this.modelId = model.id;

    this.color = model.color;

    this.object = this.createObject();

    this.elements = [];

    this.selectedElement = null;
  }

  static computeInitialModel({name, sceneClientId}) {
    const id = MathUtils.generateUUID();
    const type = "CAPLA";
    const position = {x: 0, y: 0, z: 0};
    const rotation = {x: 0, y: 0, z: 0};
    const center = {x: 0, y: 2, z: 0};
    const width = 10;
    const depth = 10;
    const height = 2;
    const hidden = false;

    return {
      id,
      type,
      sceneClientId,
      name,
      position,
      rotation,
      center,
      width,
      depth,
      height,
      hidden,
    };
  }

  createObject() {
    const object = new Object3D();
    object.layers.enable(1);
    this.editor3d?.scene.add(object);
    return object;
  }

  addBox(position) {
    let w, h, d;
    w = h = d = 1;

    const box = new Mesh(new BoxGeometry(w, h, d), material);
    box.position.set(position.x, position.y, position.z);
    box.layers.enable(1);
    this.object.add(box);
  }

  // Elements

  addElement(element) {
    const hasCoord = typeof element.position?.x === "number";

    if (hasCoord) {
      const caplaElement = new CaplaElement({
        position: element.position,
        caplaModel: this,
        codeName: element.codeName,
        color: this.color,
        onClick: this.onElementClick,
      });
      this.elements.push(caplaElement);
      this.editor3d?.objectEntities.push(caplaElement);
    }
  }

  deleteElement(codeName) {
    const caplaElement = this.elements.find(
      (element) => element.codeName === codeName
    );
    caplaElement.delete();
    // const newElements = this.elements.filter(
    //   (element) => element.codeName !== codeName
    // );
  }

  loadElements() {
    if (this.model.elements?.items) {
      this.model.elements.items.map((item) => {
        this.addElement(item);
      });
    }
  }

  // Hide & Show

  hide() {
    console.log("hidding", this.elements);
    this.elements.forEach((e) => e.hide());
  }

  show() {
    this.elements.forEach((e) => e.show());
  }

  // Selection

  // CAPLA model plays the role of the this.editor3d? when the user click on CSSObjects.

  dispatchSelection = (parsedElement) => {
    this.editor3d?.onSelectionChange(parsedElement);
  };

  dispatchEmptySelection = () => {
    this.editor3d?.onSelectionChange(null);
  };

  onElementClick = (element) => {
    console.log("EL12", element);
    if (element.codeName === this.selectedElement) {
      //element.unselect(); the selection is managed eventually by this.editor3d?.multipleSelect
      this.selectedElement = null;
      this.dispatchEmptySelection();
    } else {
      this.selectedElement = element.codeName;
      this.dispatchSelection(element);

      //element.select();
    }
  };

  multipleSelect(codeNames) {
    this.elements.forEach((element) => {
      if (codeNames.includes(element.codeName)) {
        element.select();
      } else {
        element.unselect();
      }
    });
  }
}

export default CaplaModel;
