import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  fetchPricesService,
  createPriceService,
  updatePriceService,
  deletePriceService,
} from "./services";

import formatPriceWithSpaces from "./utils/formatPriceWithSpaces";

export const fetchPrices = createAsyncThunk(
  "pricing/fetchPrices",
  fetchPricesService
);

export const createPrice = createAsyncThunk(
  "pricing/createPrice",
  createPriceService
);

export const updatePrice = createAsyncThunk(
  "pricing/updatePrice",
  updatePriceService
);

export const deletePrice = createAsyncThunk(
  "pricing/deletePrice",
  deletePriceService
);

export const pricingSlice = createSlice({
  name: "pricing",
  initialState: {
    pricingTable: {},
    pricings: [],
    selectedGroupPricing: null,
    refPrice: 0,
    status: "idle",
    editedValue: "",
    backupValue: "",
  },
  reducers: {
    setSelectedGroupPricing: (state, action) => {
      state.selectedGroupPricing = action.payload;
    },
    setRefPrice: (state, action) => {
      state.refPrice = action.payload;
    },
    setEditedValue: (state, action) => {
      state.editedValue = action.payload;
    },
    setBackupValue: (state, action) => {
      state.backupValue = action.payload;
    },
  },
  extraReducers: {
    [fetchPrices.pending]: (state) => {
      state.status = "loading";
    },
    [fetchPrices.fulfilled]: (state, action) => {
      state.pricings = action.payload;
      for (let i = 0; i < state.pricings.length; i++) {
        const {sharedBimboxId, resId, pu} = state.pricings[i];
        if (sharedBimboxId in state.pricingTable) {
          state.pricingTable[sharedBimboxId][resId] = pu;
        } else {
          state.pricingTable[sharedBimboxId] = {[resId]: pu};
        }
      }
      state.status = "loaded";
    },
    [createPrice.fulfilled]: (state, action) => {
      const newPricing = action.payload;
      state.pricings.push(newPricing);
      const {sharedBimboxId, resId, pu} = newPricing;
      if (sharedBimboxId in state.pricingTable) {
        state.pricingTable[sharedBimboxId][resId] = pu;
      } else {
        state.pricingTable[sharedBimboxId] = {[resId]: pu};
      }
      // state.editedValue = formatPriceWithSpaces(pu);
    },
    [createPrice.rejected]: (state) => {
      state.editedValue = "";
    },
    [updatePrice.fulfilled]: (state, action) => {
      const newPricing = action.payload;
      const pricings = state.pricings.map((pricing) => {
        if (pricing.id === newPricing.id) {
          return newPricing;
        } else {
          return pricing;
        }
      });
      state.pricings = pricings;
      const {sharedBimboxId, resId, pu} = newPricing;
      if (sharedBimboxId in state.pricingTable) {
        state.pricingTable[sharedBimboxId][resId] = pu;
      } else {
        state.pricingTable[sharedBimboxId] = {[resId]: pu};
      }
      // state.editedValue = formatPriceWithSpaces(pu);
    },
    [updatePrice.rejected]: (state) => {
      state.editedValue = state.backupValue;
    },
    [deletePrice.fulfilled]: (state, action) => {
      const pricingId = action.payload;
      const pricing = state.pricings.find((p) => p.id === pricingId);
      if (pricing) {
        const newPricings = state.pricings.filter(
          (pricing) => pricing.id !== pricingId
        );
        state.pricings = newPricings;
        const {sharedBimboxId, resId} = pricing;
        if (sharedBimboxId in state.pricingTable) {
          delete state.pricingTable[sharedBimboxId][resId];
        }
      }
      // state.editedValue = "";
    },
    [deletePrice.rejected]: (state) => {
      state.editedValue = state.backupValue;
    },
  },
});

export const {
  setSelectedGroupPricing,
  setRefPrice,
  setEditedValue,
  setBackupValue,
} = pricingSlice.actions;

export default pricingSlice.reducer;