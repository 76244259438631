import React from "react";

import {useNavigate} from "react-router-dom";

import useProfile from "Features/profile/useProfile";

import {
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";

import {getNameFromEmail, getInitialsFromName} from "Features/profile/utils";

export default function ListItemButtonProfile() {
  const navigate = useNavigate();

  // string

  const descriptionString = "See profile";

  // data

  const {data: profile} = useProfile();

  // helper

  const email = profile?.user;

  let name = profile?.name;
  if (name?.length === 0) name = getNameFromEmail(email);

  const initials = getInitialsFromName(name);

  console.log("profile", profile, profile?.name, email);

  // handlers

  function handleClick() {
    navigate("/profile");
  }

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemAvatar>
        <Avatar sx={{width: 30, height: 30}}>
          <Typography>{initials}</Typography>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{my: 0}}
        primary={name}
        primaryTypographyProps={{
          fontSize: (theme) => theme.typography.body2.fontSize,
        }}
        secondary={descriptionString}
      />
    </ListItemButton>
  );
}
