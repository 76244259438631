import {useDispatch, useSelector} from "react-redux";

import {
  ToggleButton,
  Paper,
  Box,
} from "@mui/material";
import {DirectionsWalk as Walk} from "@mui/icons-material";

import {setWalkMode} from "../viewer3DSlice";

export default function ToggleButtonWalkMode({editor3d}) {
  const dispatch = useDispatch();

  const selected = useSelector((state) => state.viewer3D.walkMode);
  const multiviews = useSelector((state) => state.viewer3D.multiviews);

  const handleChange = () => {
    console.log("CHANGE TOGGLE", multiviews);
    if (!selected) {
      if (multiviews) {
        editor3d?.sceneEditor.optimize();
        if (editor3d) editor3d.activeMode = "LOCK2";
        editor3d?.controls.switchTo("LOCK2");
      } else {
        if (editor3d) editor3d.activeMode = "LOCK";
        editor3d?.controls.switchTo("LOCK");
      }
      dispatch(setWalkMode(true));
    }
  };

  return (
    <Paper
      sx={{
        bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        color: "divider",
      }}
    >
      <ToggleButton
        value="WALK_MODE"
        selected={selected}
        onChange={handleChange}
        aria-label="module"
        sx={{
          width: 30,
          height: 30,
          "&.Mui-selected": {
            bgcolor: "common.darkGrey",
            "&:hover": {
              bgcolor: "common.darkGrey",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "common.white",
          }}
        >
          <Walk fontSize="small" color="inherit" />
        </Box>
      </ToggleButton>
    </Paper>
  );
}
