import sortByNum from "./sortByNum";

export default function roomsForDataGrid(rooms) {
  return [...rooms]
    .map((room) => ({
      ...room,
      num: room.num ? room.num : "0",
      hasMsts: room.msts?.length > 0,
    }))
    .sort((a, b) => sortByNum(a.num, b.num));
}
