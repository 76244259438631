import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Menu, MenuItem, Typography, Button, Box} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import SelectorRessourceGroupInScope from "./SelectorRessourceGroupInScope";
import SelectorElementTypeGroupInScope from "./SelectorElementTypeGroupInScope";

import {
  setDataGridMode,
  setSelectedTypesInDatagrid,
  setSelectedRoomsInDatagrid,
  setSelectedRessourcesInDatagrid,
} from "Features/measurements/measurementsSlice";

export default function ToggleDataGridMode({scene}) {
  const dispatch = useDispatch();

  // strings

  const allS = "Par élément";
  const byElementTypeS = "Par type";
  const byRessourceS = "Par article";
  const byRoomS = "Par zone";

  // data

  const mode = useSelector((s) => s.measurements.dataGridMode);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleToolChange(mode) {
    dispatch(setDataGridMode(mode));
    dispatch(setSelectedTypesInDatagrid([]));
    dispatch(setSelectedRessourcesInDatagrid([]));
    dispatch(setSelectedRoomsInDatagrid([]));
    setAnchorEl(null);
  }

  // helpers - tools

  const tools = [
    {
      id: "ALL",
      label: allS,
    },
    {
      id: "BY_ROOM",
      label: byRoomS,
    },
    {
      id: "BY_ELEMENT_TYPE",
      label: byElementTypeS,
    },
    {
      id: "BY_RESSOURCE",
      label: byRessourceS,
    },
  ];

  // helpers - tool

  const tool = tools.find((t) => t.id === mode);

  // helper - button

  const buttonVariant = ["BY_RESSOURCE", "BY_ELEMENT_TYPE"].includes(mode)
    ? "text"
    : "contained";

  return (
    <>
      <Box
        sx={{color: "text.secondary", display: "flex", alignItems: "center"}}
      >
        <Button
          endIcon={<More />}
          size="small"
          color="secondary"
          variant={buttonVariant}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {tool?.label}
        </Button>
        {tool.id === "BY_RESSOURCE" && (
          <Box sx={{ml: 1}}>
            <SelectorRessourceGroupInScope scene={scene} />
          </Box>
        )}
        {tool.id === "BY_ELEMENT_TYPE" && (
          <Box sx={{ml: 1}}>
            <SelectorElementTypeGroupInScope scene={scene} />
          </Box>
        )}
      </Box>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={() => handleToolChange(tool.id)}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
