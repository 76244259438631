import {useDispatch} from "react-redux";
import {updateElementTypesGroup} from "../elementTypesSlice";
import useSelectedElementTypesGroup from "./useSelectedElementTypesGroup";

import useAccessToken from "Features/auth/useAccessToken";
import useAddSceneElementType from "./useAddSceneElementType";
import addElementTypeToElementTypesGroup from "../utils/addElementTypeToElementTypesGroup";
import useSceneModule from "Features/navigation/useSceneModule";

import resetElementTypesGroupNums from "../utils/resetElementTypesGroupNums";

export default function useCreateElementType(scene, options) {
  // options
  const resetNums = options?.resetNums;
  //
  const accessToken = useAccessToken();
  const addSceneElementType = useAddSceneElementType(scene);
  const sceneId = scene?.id;
  const dispatch = useDispatch();
  //
  const sceneModule = useSceneModule();
  const inEditMeasurements = sceneModule === "EDIT_MEASUREMENTS";

  const group = useSelectedElementTypesGroup(scene, {inEditMeasurements});

  async function createElemenType(newElementType) {
    const elementType = {
      ...newElementType,
    };
    if (group.fromScene) {
      elementType.group = group.name;
      addSceneElementType(elementType);
    } else {
      let updatedGroup = addElementTypeToElementTypesGroup(elementType, group);
      if (resetNums) {
        updatedGroup = resetElementTypesGroupNums(updatedGroup);
      }

      await dispatch(
        updateElementTypesGroup({
          accessToken,
          elementTypesGroup: updatedGroup,
        })
      );
    }
  }

  return createElemenType;
}
