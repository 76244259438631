import {createSlice} from "@reduxjs/toolkit";

export const zonesSlice = createSlice({
  name: "zones",
  initialState: {
    selectedZoneId: null,
    lastZoneId: null, // last created zone => used to select in the DataGrid the new zone.

    showZoneFormDetail: true, // to show / hide detail in pdf view.
    openDialogCreateZone: false,
    tempZoneToCreate: {}, // temp zone generated when drawing the rectangle.

    scopeSelectionMode: "ALL",
    sectorsFilter: [], // ids of sectors used as filters.
    roomsFilter: [], // ids of rooms used as filters.
    filterSectorIdInViewer: null, // id of the sector
    filterRoomIdInViewer: null,

    selectedFormGroupCode: "PROPS",

    // edition
    isEditingZone: false,
    editedZone: null,

    // zonesToShowInViewer3d
    // hoverSelectorZonesToShow: false,
    zonesInViewer3dSelected: true,
    enableZonesInViewer3d: true,
    // showZoneNamesInViewer3d: true,
    useBufferInShortcut: false,
    zonesInBuffer: [],

    // zoneViewer
    zoneIdsInZoneViewer: [],

    // differences in zone Viewer
    oldZoneId: null, // used to compute differences
    showOld: true,
    showNew: true,
    showDiff: true,
    imageDiff: {url: null, width: 0, height: 0},
  },
  reducers: {
    setZonesInBuffer: (state, action) => {
      state.zonesInBuffer = action.payload;
    },
    addZoneToBuffer: (state, action) => {
      const zoneToAdd = action.payload;
      const alreadyInBuffer = state.zonesInBuffer.find(
        (z) => z.id === zoneToAdd.id
      );
      if (!alreadyInBuffer) state.zonesInBuffer.push(action.payload);
    },
    removeZoneFromBuffer: (state, action) => {
      const zoneToRemove = action.payload;
      state.zonesInBuffer = [
        ...state.zonesInBuffer.filter((z) => z.id !== zoneToRemove.id),
      ];
      if (state.zonesInBuffer.length === 0) state.useBufferInShortcut = false;
    },
    setUseBufferInShortcut: (state, action) => {
      state.useBufferInShortcut = action.payload;
    },
    setSelectedZoneId: (state, action) => {
      state.selectedZoneId = action.payload;
    },
    setLastZoneId: (state, action) => {
      state.lastZoneId = action.payload;
    },
    setSectorsFilter: (state, action) => {
      state.sectorsFilter = action.payload;
    },
    setRoomsFilter: (state, action) => {
      state.roomsFilter = action.payload;
    },
    setFilterSectorIdInViewer: (state, action) => {
      state.filterSectorIdInViewer = action.payload;
    },
    setFilterRoomIdInViewer: (state, action) => {
      state.filterRoomIdInViewer = action.payload;
    },
    setShowZoneFormDetail: (state, action) => {
      state.showZoneFormDetail = action.payload;
    },
    setOpenDialogCreateZone: (state, action) => {
      state.openDialogCreateZone = action.payload;
    },
    setTempZoneToCreate: (state, action) => {
      state.tempZoneToCreate = action.payload;
    },
    setScopeSelectionMode: (state, action) => {
      state.scopeSelectionMode = action.payload;
    },
    setSelectedFormGroupCode: (state, action) => {
      state.selectedFormGroupCode = action.payload;
    },
    // edition
    setIsEditingZone: (state, action) => {
      state.isEditingZone = action.payload;
    },
    setEditedZone: (state, action) => {
      state.editedZone = action.payload;
    },
    // zones to show in 3D viewer
    setZonesInViewer3dSelected: (state, action) => {
      state.zonesInViewer3dSelected = action.payload;
    },
    // setHoverSelectorZonesToShow: (state, action) => {
    //   state.hoverSelectorZonesToShow = action.payload;
    // },
    setEnableZonesInViewer3d: (state, action) => {
      state.enableZonesInViewer3d = action.payload;
    },
    // zoneViewer
    setZoneIdsInZoneViewer: (state, action) => {
      state.zoneIdsInZoneViewer = action.payload;
    },
    removeZoneFromZoneViewer: (state, action) => {
      state.zoneIdsInZoneViewer = state.zoneIdsInZoneViewer.filter(
        (id) => id !== action.payload
      );
    },
    // differences
    setOldZoneId: (state, action) => {
      state.oldZoneId = action.payload;
    },
    setShowOld: (state, action) => {
      state.showOld = action.payload;
    },
    setShowNew: (state, action) => {
      state.showNew = action.payload;
    },
    setShowDiff: (state, action) => {
      state.showDiff = action.payload;
    },
    setImageDiff: (state, action) => {
      state.imageDiff = action.payload;
    },
  },
});

export const {
  setUseBufferInShortcut,
  setZonesInBuffer,
  addZoneToBuffer,
  removeZoneFromBuffer,
  setSelectedZoneId,
  setLastZoneId,
  setSectorsFilter,
  setRoomsFilter,
  setShowZoneFormDetail,
  setOpenDialogCreateZone,
  setTempZoneToCreate,
  setScopeSelectionMode,
  setSelectedFormGroupCode,
  setEditedZone,
  setIsEditingZone,
  setFilterSectorIdInViewer,
  setFilterRoomIdInViewer,
  // setHoverSelectorZonesToShow,
  setZonesInViewer3dSelected,
  setEnableZonesInViewer3d,
  //
  setZoneIdsInZoneViewer,
  removeZoneFromZoneViewer,
  //
  setOldZoneId,
  setShowOld,
  setShowNew,
  setShowDiff,
  setImageDiff,
} = zonesSlice.actions;

export default zonesSlice.reducer;
