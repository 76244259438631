// import React from "react";

import {Box, Typography, Paper} from "@mui/material";

// import HeaderSelectionMoreButton from "./HeaderSelectionMoreButton";
// import HeaderSelectionBackButton from "./HeaderSelectionBackButton";
import ButtonResetSelectionMulti from "./ButtonResetSelectionMulti";

import useSelectionMulti from "Features/selection/useSelectionMulti";
import ButtonMoreActionsMultiMeasurements from "Features/measurements/components/ButtonMoreActionsMultiMeasurements";

export default function HeaderSelectionMulti({caplaEditor, scene}) {
  // strings

  const selectedS = "sélectionnés";

  // data

  const selectionMulti = useSelectionMulti();

  // helpers

  const type = selectionMulti?.type;
  const label = selectionMulti?.label;
  const count = selectionMulti.items.length;
  const title = `${count} ${label} ${selectedS}`;

  return (
    <Box
      sx={{
        p: 1,
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          p: 0.5,
          height: (theme) => theme.spacing(5),
          width: 1,
          alignItems: "center",
          justifyContent: "space-between",
          //overflow: "hidden",
        }}
      >
        <ButtonResetSelectionMulti caplaEditor={caplaEditor} />
        <Typography
          variant="body2"
          sx={{fontWeight: "bold", minWidth: 0}}
          noWrap
        >
          {title}
        </Typography>
        <Box sx={{display: "flex", alignItems: "center"}}>
          {type === "MEASUREMENTS" && (
            <ButtonMoreActionsMultiMeasurements
              measurementIds={selectionMulti?.items}
              caplaEditor={caplaEditor}
              scene={scene}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
}
