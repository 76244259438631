import {useSelector, useDispatch} from "react-redux";

import {Box, Tooltip, Typography, Paper} from "@mui/material";
import {ArrowDropDown, ArrowDropDown as Down} from "@mui/icons-material";

import useTempMeasurement from "../useTempMeasurement";

import IconElementType from "Features/elementTypes/components/IconElementType";
import {setOpenDialogSelectElementType} from "Features/elementTypes/elementTypesSlice";

export default function SelectorElementTypeInToolbar({editorPdf, scene}) {
  const dispatch = useDispatch();

  //data

  const tempMeasurement = useTempMeasurement({editorPdf, scene});
  const isCreatingElementType = useSelector(
    (s) => s.elementTypes.isCreatingElementType
  );
  const tempElementType = useSelector((s) => s.elementTypes.tempElementType);

  // helpers - elementType

  let elementType = tempMeasurement?.elementType;
  if (isCreatingElementType) elementType = tempElementType;

  // helpers

  const name = elementType?.name ?? "-?-";
  const num = elementType?.num ?? "-?-";
  const label = `${num} • ${name}`;

  const tooltipTitle = elementType?.name ? `+ ${elementType.name}` : "-";

  // handler

  function handleOnClick() {
    dispatch(setOpenDialogSelectElementType(true));
  }
  return (
    <>
      <Tooltip title={label}>
        <Paper
          elevation={12}
          sx={{
            border: "1px solid white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 0.5,
          }}
          onClick={handleOnClick}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconElementType
              color={tempMeasurement?.color}
              drawingShape={tempMeasurement?.drawingShape}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              ml: 1,
              cursor: "pointer",
              minWidth: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography noWrap sx={{fontSize: 13}} color="inherit">
              {label}
            </Typography>
          </Box>

          <Box
            sx={{
              color: "action.main",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowDropDown sx={{fill: "action.main"}} />
          </Box>
        </Paper>
      </Tooltip>
    </>
  );
}
