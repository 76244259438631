import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsPackages from "./SectionActionsPackages";

export default function SectionLeftPanelActionsPackages({scene, caplaEditor}) {
  // data

  const sceneModule = useSceneModule();

  // actions - main

  const actionsMainKeys = ["createPackage", "refreshPackages"];
  const actionsMoreKeys = [];

  return (
    <Box>
      {sceneModule === "PACKAGES" && (
        <SectionActionsPackages
          scene={scene}
          caplaEditor={caplaEditor}
          actionsMainKeys={actionsMainKeys}
          actionsMoreKeys={actionsMoreKeys}
        />
      )}
    </Box>
  );
}
