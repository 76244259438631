import {useDispatch, useSelector} from "react-redux";

import {Button, Box} from "@mui/material";
import {Add} from "@mui/icons-material";

import ButtonDialogAddInputs from "./ButtonDialogAddInputs";

import {setSelectedInputId} from "../inputsSlice";

import {setOpenSelectionPanel} from "Features/selection/selectionSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

export default function ButtonAddInputs({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const addS = "Nouveau";

  // data

  const isOpened = useSelector((s) => s.selection.openSelectionPanel);

  const selectedInputId = useSelector((s) => s.inputs.selectedInputId);

  // helpers

  const hide = !selectedInputId && isOpened;

  // handlers

  function handleClick() {
    if (!isOpened) {
      dispatch(setOpenSelectionPanel(true));
    }
    if (selectedInputId) {
      dispatch(setSelectedInputId(null));
      dispatch(setSelectedPdfModelId(null));
    }
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Box sx={{display: hide ? "none" : "flex", mr: 1}}>
        <Button
          startIcon={<Add />}
          size="small"
          variant="contained"
          color="secondary"
          onClick={handleClick}
        >
          {addS}
        </Button>
      </Box>
      <ButtonDialogAddInputs editor3d={editor3d} />
    </Box>
  );
}
