import {useState} from "react";

import useActionsViewsMap from "../hooks/useActionsViewsMap";

import SectionGenericActions from "Components/SectionGenericActions";

import DialogCreateView from "./DialogCreateView";

export default function SectionActionsViews({
  scene,
  caplaEditor,
  actionsMainKeys = [],
  actionsMoreKeys = [],
}) {
  // state

  const [openDialogXxx, setOpenDialogXxx] = useState(false);

  const [openDialogCreateView, setOpenDialogCreateView] = useState(false);

  // handlerMap

  const setOpenDialogMap = {
    launchXxx: () => setOpenDialogXxx(true),
    createView: () => setOpenDialogCreateView(true),
  };

  // handlers

  function handleClick(actionKey) {
    console.log("trigger", actionKey);
    //
    const setOpenDialog = setOpenDialogMap[actionKey];
    if (setOpenDialog) setOpenDialog();
    //
    const action = actionsMap[actionKey];
    if (action?.handler) action.handler();
  }

  // handlers - close Dialogs

  function handleCloseDialogCreateView() {
    setOpenDialogCreateView(false);
  }

  // data - handlers

  const actionsMap = useActionsViewsMap({scene, caplaEditor});

  const actionsMain = actionsMainKeys.map((key) => ({key, ...actionsMap[key]}));
  const actionsMore = actionsMoreKeys.map((key) => ({key, ...actionsMap[key]}));

  return (
    <>
      <SectionGenericActions
        actionsMain={actionsMain}
        actionsMore={actionsMore}
        onClick={handleClick}
      />

      {openDialogCreateView && (
        <DialogCreateView
          scene={scene}
          caplaEditor={caplaEditor}
          open={openDialogCreateView}
          onClose={handleCloseDialogCreateView}
        />
      )}
    </>
  );
}
