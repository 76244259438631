import {useState} from "react";
import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import ImageListPictures from "./ImageListPictures";
import Toolbar from "./Toolbar";

import {
  getImageFileLatLng,
  drawSideUpImageAsync,
} from "Features/pictures/utils";
import Stretch from "Features/ui/components/Stretch";
import {addTempPictures} from "Features/pictures/picturesSlice";

export default function SectionPictures({caplaEditor, listing}) {
  const dispatch = useDispatch();

  // state

  const [tempPictures, setTempPictures] = useState([]); // useState to manage files.
  const [focused, setFocused] = useState(false);

  // helpers

  const bgcolor = focused ? "common.yellow" : "common.white";

  // handlers - drag n drop

  function handleDragEnter(e) {
    e.preventDefault();
    setFocused(true);
  }
  function handleDragLeave(e) {
    e.preventDefault();
    setFocused(false);
  }
  async function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setFocused(false);
    // const files = e.dataTransfer.items.array.map((i) => i.getAsFile());
    const newPictures = [];
    if (e.dataTransfer.items != null) {
      const files = [];
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        const file = e.dataTransfer.items[i].getAsFile();
        files.push(file);
      }
      for (let file of files) {
        const latLng = await getImageFileLatLng(file); // and orientation
        let newFile = file;
        console.log("orientation", latLng?.orientation);
        if (latLng?.orientation)
          newFile = await drawSideUpImageAsync(file, latLng.orientation);
        const picture = {
          clientId: nanoid(),
          file: newFile,
          imageUrl: URL.createObjectURL(newFile),
        };
        if (latLng) {
          picture.lat = latLng.lat;
          picture.lng = latLng.lng;
        }
        newPictures.push(picture);
      }
    }
    setTempPictures(newPictures);

    // state
    const stateNewPictures = newPictures.map((pix) => ({
      clientId: pix.clientId,
      imageUrl: pix.imageUrl,
      lat: pix.lat,
      lng: pix.lng,
    }));

    dispatch(addTempPictures(stateNewPictures));
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  return (
    <Box>
      <Toolbar listing={listing} caplaEditor={caplaEditor} tempPictures={tempPictures} />
      <Box
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        sx={{bgcolor, width: 1}}
      >
        <Stretch>
          <Box sx={{height: 1, overflowY: "auto"}}>
            <ImageListPictures />
          </Box>
        </Stretch>
      </Box>
    </Box>
  );
}
