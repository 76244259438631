import {Box} from "@mui/material";

import IconButtonPoi from "./IconButtonPoi";

export default function SelectorPoisIcons({
  caplaEditor,
  scene,
  pois = [],
  selectedPoiId,
  onSelectionChange,
}) {
  // handlers

  function handleClick(poi) {
    if (onSelectionChange) onSelectionChange(poi?.id);
  }
  //

  return (
    <Box sx={{display: "flex", flexWrap: "wrap"}}>
      {pois.map((poi, index) => {
        const selected = poi.id === selectedPoiId;
        return (
          <Box
            key={poi.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //p: 0.5,
            }}
          >
            <IconButtonPoi
              index={index + 1}
              scene={scene}
              caplaEditor={caplaEditor}
              poi={poi}
              onClick={handleClick}
              selected={selected}
            />
          </Box>
        );
      })}
    </Box>
  );
}
