/*
 * room : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useSetSceneRooms(scene) {
  const dispatch = useDispatch();

  const setSceneRooms = (rooms) => {
    if (!scene) return;
    const newScene = {
      ...scene,
      data: {...scene?.data, rooms},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return setSceneRooms;
}
