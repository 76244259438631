import {useState} from "react";

import {Box, IconButton, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {
  HighlightAlt as Zone,
  TextFields as Text,
  Add,
} from "@mui/icons-material";

export default function ContainerAddPoiFromPdf({caplaEditor}) {
  // state

  const [mode, setMode] = useState("TEXT");

  // handler

  function handleChange(e, value) {
    if (value !== null) {
      setMode(value);
    }
  }

  function handleClick() {}

  return (
    <Box sx={{display: "flex", alignItems: "center", bgcolor: "common.white"}}>
      <ToggleButtonGroup
        size="small"
        value={mode}
        onChange={handleChange}
        exclusive
        sx={{textTransform: "none"}}
      >
        <ToggleButton value="ZONE">
          <Zone fontSize="small" />
        </ToggleButton>
        <ToggleButton value="TEXT">
          <Text fontSize="small" />
        </ToggleButton>
      </ToggleButtonGroup>
      <IconButton onClick={handleClick}>
        <Add />
      </IconButton>
    </Box>
  );
}
