import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography, List, ListItem} from "@mui/material";

import ListRessourcesMultipleWithGroups from "./ListRessourcesMultipleWithGroups";

import useRessourcesByScene from "../hooks/useRessourcesByScene";
import getMeasurementsCountByRessourceGroupIdMap from "../utils/getMeasurementsCountByRessourceGroupIdMap";

//import useRessourcesGroupsProxyBySceneWithRessources from "../hooks/useRessourcesGroupsProxyBySceneWithRessources";

import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";
import {
  setSelectedRessourceId,
  setSelectedRessourcesGroupProxy,
} from "Features/ressources/ressourcesSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

import useRessourcesGroupsProxyByScene from "../hooks/useRessourcesGroupsProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import getRessourcesWithQuantities from "../utils/getRessourcesWithQuantities";

export default function SectionRessourcesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();

  //
  caplaEditor.timeEnd = performance.now();
  console.log(
    "debugtime navtime ressources",
    caplaEditor.timeEnd - caplaEditor.timeStart
  );
  console.time("debugtime sectionRessourcesInLeftPanel");
  //

  // strings

  const noGroupS = "0 tableau d'articles";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const pdfOnly = os.fixedViewersBox && !os.viewer3D;
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  let ressources = useRessourcesByScene(scene, {filterByScope: true});
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);
  const types = useElementTypesBySceneProxy(scene, {filterByScope: true});

  const zones = useZonesByScene(scene);

  const fms = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: true,
    filterPackagesByVersion: true,
    filterPackagesBySelectedPdf: pdfOnly,
  });

  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );
  const selectedGroupProxy = useSelector(
    (s) => s.ressources.selectedRessourcesGroupProxy
  );
  const groupsProxy = useRessourcesGroupsProxyByScene(scene, {
    sortByName: true,
    filterByScope: true,
  });
  const shortcutRessources = useSelector(
    (s) => s.measurements.shortcutRessources
  );
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // helpers

  const groupCountMap = getMeasurementsCountByRessourceGroupIdMap(
    fms,
    ressources
  );

  const entitiesMap = {
    zones: zones,
    sectors: sectors,
    rooms: rooms,
  };

  // helpers - qties

  let ressourcesWithQuantities = getRessourcesWithQuantities(
    ressources,
    fms,
    types,
    entitiesMap,
    filterNoVoids
  );

  // helpers - no group

  const noGroup = groupsProxy.length === 0;

  // handlers

  function handleSelectionChange(ids) {
    let id = null;
    if (Array.isArray(ids) && ids.length > 0) {
      id = ids[0];
    }
    dispatch(setSelectedRessourceId(id));
    //
    //
    // text extraction
    //
    if (id) {
      const ressource = ressources.find((r) => r.id === id);
      const pdfExtractedText = ressource?.extractedText;
      if (pdfExtractedText && caplaEditor) {
        const text = pdfExtractedText?.text;
        const pageNumber = pdfExtractedText?.pageNumber;
        const pdfModelId = pdfExtractedText?.pdfModelId;
        const pageRotation = pdfExtractedText?.pageRotation;
        const pageWidth = pdfExtractedText?.pageWidth;
        const pageHeight = pdfExtractedText?.pageHeight;
        const rects = pdfExtractedText?.rects;
        let {x1, y1, x2, y2} = rects[0];
        if (pageWidth && (pageRotation === 0 || pageRotation === 1)) {
          x1 = 0;
          x2 = pageWidth;
        }
        if (pageHeight && (pageRotation === 1 || pageRotation === 2)) {
          y1 = 0;
          y2 = pageHeight;
        }
        const viewportCoords = {x1, x2, y1, y2};
        const context = {pageNumber, pageRotation, viewportCoords};
        if (selectedPdfModelId === pdfModelId) {
          caplaEditor.editorPdf?.setPdfContext(context);
          // highlight text
          caplaEditor.editorPdf?.sceneEditor.removeHighlightedExtractedText();
          caplaEditor.editorPdf?.sceneEditor.highlightExtractedText(
            pdfExtractedText
          );
        } else if (!selectedPdfModelId) {
          dispatch(setSelectedPdfModelId(pdfModelId));
        }
      }
    }
  }

  function handleSelectedRessourcesGroupProxyChange(groupProxy) {
    dispatch(setSelectedRessourcesGroupProxy(groupProxy));
  }

  function handleVisibleIdsChange(ids) {
    //dispatch(setShortcutRessources(ids));
    // TODO : manage several ressource group visibility
  }

  console.timeEnd("debugtime sectionRessourcesInLeftPanel");

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          //border: "2px solid red",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          minHeight: 0, // make it works !!!
        }}
      >
        {noGroup ? (
          <Box
            sx={{
              width: 1,
              display: "flex",
              height: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{color: "grey.400"}}>{noGroupS}</Typography>
          </Box>
        ) : (
          <ListRessourcesMultipleWithGroups
            scene={scene}
            caplaEditor={caplaEditor}
            ressources={ressourcesWithQuantities}
            groupsProxy={groupsProxy}
            selectedRessourceIds={[selectedRessourceId]}
            onSelectionChange={handleSelectionChange}
            multipleSelection={false}
            selectedGroupId={selectedGroupProxy?.id}
            onSelectedGroupProxyChange={
              handleSelectedRessourcesGroupProxyChange
            }
            maxHeightGroups={180}
            counters={groupCountMap}
            showZero={true}
            forceSelection={true}
            variant="list"
            visibleIds={shortcutRessources}
            onVisibleIdsChange={handleVisibleIdsChange}
            showVisibility={false}
            // onCreateGroup={() => {
            //   setOpenDialogCreate(true);
            // }}
            // onCreateGroupLabel={createGroupS}
          />
        )}
      </Box>
    </>
  );
}
