import {useDispatch, useSelector} from "react-redux";
import {updateArticlesGroup} from "../articlesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {unwrapResult} from "@reduxjs/toolkit";

export default function useUpdateArticlesGroup() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const updateGroup = async (updates) => {
    if (accessToken && updates?.sceneId && updates.id) {
      const result = await dispatch(
        updateArticlesGroup({
          updates,
          accessToken,
        })
      );
      const {item} = unwrapResult(result);
      return item;
    }
  };
  return updateGroup;
}
