// super dangerous hook !!
// should manage relation updates in reducer directly.
//
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setRelationsTypesRessources} from "../relationsSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function useInitAndUpdateRelations(scene) {
  const dispatch = useDispatch();

  const formulMap = {
    UN: "C",
    ML: "L",
    M2: "S",
    M3: "V",
  };

  const types = useElementTypesBySceneProxy(scene);
  const updatedAt = useSelector((s) => s.relations.updatedAt);

  useEffect(() => {
    if (types.length > 0) {
      const relations = {};
      types.forEach((t) => {
        if (t.res && t.res.length > 0) {
          t.res.forEach((r) => {
            relations[`${t.id}-${r.resId}`] = {
              id: `${t.id}-${r.resId}`,
              typeId: t.id,
              resId: r.resId,
              mode: "FORMUL",
              func: r.func ? r.func : formulMap[t.unit],
            };
          });
        }
      });
      dispatch(setRelationsTypesRessources(relations));
    }
  }, [types.length, updatedAt]);
}
