/*
 * phase : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateScenePhase(scene) {
  const dispatch = useDispatch();

  const updateScenePhase = (phase) => {
    if (!scene) return;
    let oldPhases = scene?.data?.phases.filter((s) => s?.id !== phase.id);
    if (!oldPhases) oldPhases = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, phases: [...oldPhases, phase]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateScenePhase;
}
