import {useSelector} from "react-redux";

export default function usePdfModelUrl(model, viewer) {
  const useOpti = useSelector((s) => s.pdf.useOptimized);

  let url = model?.url ? model.url : model?.fileRemoteUrl;
  if (model?.fileLowResoRemoteUrl && useOpti && !viewer)
    url = model?.fileLowResoRemoteUrl;
  return url;
}
