import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createObjectsetService,
  fetchStoredObjectsetsService,
  updateStoredObjectsetService,
  deleteStoredObjectsetService,
} from "./services";

export const createObjectset = createAsyncThunk(
  "objectsets/createObjectset",
  createObjectsetService
);

export const fetchStoredObjectsets = createAsyncThunk(
  "objectsets/fetchStoredObjectsets",
  fetchStoredObjectsetsService
);

export const updateStoredObjectset = createAsyncThunk(
  "objectsets/updateStoredObjectset",
  updateStoredObjectsetService
);

export const deleteStoredObjectset = createAsyncThunk(
  "objectsets/deleteStoredObjectset",
  deleteStoredObjectsetService
);

export const objectsetsSlice = createSlice({
  name: "objectsets",
  initialState: {
    items: [],
    sceneSelectionId: null, // id selected in the scene
    fetchedStoredScenes: [], // array of sceneClientId for with the objetsets were fetched from the store.
  },
  reducers: {
    toggleMode: (state) => {},
    setObjectsetSceneSelection: (state, action) => {
      state.sceneSelectionId = action.payload;
    },
  },
  extraReducers: {
    [fetchStoredObjectsets.fulfilled]: (state, action) => {
      const {objectsets, sceneClientId} = action.payload;
      if (!state.fetchedStoredScenes.includes(sceneClientId)) {
        state.fetchedStoredScenes.push(sceneClientId);
        state.items.push(...objectsets);
      }
    },
    [createObjectset.fulfilled]: (state, action) => {
      const newObjectset = action.payload;
      state.items.push(newObjectset);
    },
    [updateStoredObjectset.fulfilled]: (state, action) => {
      const newObjectset = action.payload;
      const newItems = state.items.map((objectset) => {
        if (objectset.id === newObjectset.id) {
          return newObjectset;
        } else {
          return objectset;
        }
      });
      state.items = newItems;
    },
    [deleteStoredObjectset.fulfilled]: (state, action) => {
      const objectsetId = action.payload;
      const newItems = state.items.filter(
        (objectset) => objectset.id !== objectsetId
      );
      state.items = newItems;
    },
  },
});

export const {setObjectsetSceneSelection} = objectsetsSlice.actions;

export default objectsetsSlice.reducer;
