import {Vector2} from "three";
import theme from "Styles/theme";

class SelectionHelper {
  constructor(editor) {
    this.element = document.createElement("div");
    this.editor = editor;
    //this.element.classList.add(cssClassName);
    this.element.style.pointerEvents = "none";
    this.element.style.position = "absolute";

    this.container = this.editor.canvas.parentElement; // by default, 3D.

    this.startPoint = new Vector2();
    this.pointTopLeft = new Vector2();
    this.pointBottomRight = new Vector2();

    this.isDown = false;

    this.onPointerDown = function (event) {
      this.isDown = true;
      this.onSelectStart(event);
    }.bind(this);

    this.onPointerMove = function (event) {
      if (this.isDown) {
        this.onSelectMove(event);
      }
    }.bind(this);

    this.onPointerUp = function () {
      this.isDown = false;
      this.onSelectOver();
    }.bind(this);
  }

  enable(mode) {
    if (mode === "2D") {
      this.container = this.editor.multiviews.el1;
    } else {
      this.container = this.editor.canvas.parentElement;
    }
    this.container.addEventListener("pointerdown", this.onPointerDown);
    this.container.addEventListener("pointermove", this.onPointerMove);
    this.container.addEventListener("pointerup", this.onPointerUp);
  }

  disable() {
    this.container.removeEventListener("pointerdown", this.onPointerDown);
    this.container.removeEventListener("pointermove", this.onPointerMove);
    this.container.removeEventListener("pointerup", this.onPointerUp);
  }

  onSelectStart(event) {
    // start
    this.container.appendChild(this.element);

    this.rect = this.container.getBoundingClientRect();
    this.element.style.border = `1px solid ${theme.palette.secondary.main}`;
    this.element.style.zIndex = 90;
    this.element.style.left = event.clientX - this.rect.left + "px";
    this.element.style.top = event.clientY - this.rect.top + "px";
    this.element.style.width = "0px";
    this.element.style.height = "0px";

    this.startPoint.x = event.clientX - this.rect.left;
    this.startPoint.y = event.clientY - this.rect.top;
  }

  onSelectMove(event) {
    this.element.style.display = "flex";
    this.rect = this.container.getBoundingClientRect();

    this.pointBottomRight.x = Math.max(
      this.startPoint.x,
      event.clientX - this.rect.left
    );
    this.pointBottomRight.y = Math.max(
      this.startPoint.y,
      event.clientY - this.rect.top
    );
    this.pointTopLeft.x = Math.min(
      this.startPoint.x,
      event.clientX - this.rect.left
    );
    this.pointTopLeft.y = Math.min(
      this.startPoint.y,
      event.clientY - this.rect.top
    );

    this.element.style.left = this.pointTopLeft.x + "px";
    this.element.style.top = this.pointTopLeft.y + "px";
    this.element.style.width =
      this.pointBottomRight.x - this.pointTopLeft.x + "px";
    this.element.style.height =
      this.pointBottomRight.y - this.pointTopLeft.y + "px";
  }

  onSelectOver() {
    this.container.removeChild(this.element);
  }
}

export default SelectionHelper;
