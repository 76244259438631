import {useSelector} from "react-redux";

export default function usePackagesInMainScope(options) {
  const addHelpers = options?.addHelpers;
  //
  const models = useSelector((s) => s.viewer3D.models);
  const packageIdsInMainScope = useSelector(
    (s) => s.overviewer.packageIdsInMainScope
  );
  const helperPackageIdsInMainScope = useSelector(
    (s) => s.overviewer.helperPackageIdsInMainScope
  );
  //
  let measurementModels = models.filter((m) => m.type === "MEASUREMENTS");
  //
  if (Array.isArray(packageIdsInMainScope)) {
    measurementModels = measurementModels.filter(
      (m) =>
        packageIdsInMainScope.includes(m.id) ||
        (addHelpers && helperPackageIdsInMainScope?.includes(m.id))
    );
  }
  //
  return measurementModels;
}
