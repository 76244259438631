import {MeshPhysicalMaterial, Color, PlaneGeometry, Mesh} from "three";
import theme from "Styles/theme";
export default class CaplaPlaneBasic {
  constructor({width, height}) {
    this.width = width;
    this.height = height;

    this.material = new MeshPhysicalMaterial({
      color: new Color(theme.palette.common.caplaBlack),
    });

    this.object = this.createObject();
  }

  createObject() {
    const planeGeometry = new PlaneGeometry(this.width, this.height, 1);
    const object = new Mesh(planeGeometry, this.material);
    object.rotateX(-Math.PI / 2);

    object.layers.enable(1);
    return object;
  }
}
