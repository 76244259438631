export default function fillWorksheetWithRooms({
  worksheet,
  rooms,
  rowStartIndex,
}) {
  rooms.forEach(({id, num, name, code, sector, surface, hsp, type}, index) => {
    const row = worksheet.getRow(rowStartIndex + index);
    row.getCell(1).value = id;
    row.getCell(2).value = num;
    row.getCell(3).value = name;
    row.getCell(4).value = code;
    row.getCell(5).value = sector;
    if (typeof surface === "number") row.getCell(6).value = surface;
    if (typeof hsp === "number") row.getCell(7).value = hsp;
    if (typeof type === "string") row.getCell(8).value = type;
  });
}
