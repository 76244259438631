import { nanoid } from "@reduxjs/toolkit";

import sortByNum from "./sortByNum";

export default function elementTypesForDataGrid(elementTypes, options) {
  // options
  const sortElementTypesByNum = options?.sortElementTypesByNum;

  // helpers
  function getRank(num) {
    return num ? num.toString().split(".").length : null;
  }

  const ranks = [
    ...new Set(elementTypes.map((r) => getRank(r.num)).filter((rank) => rank)),
  ];
  const rankMin = Math.min(...ranks);

  let result = [...elementTypes].map((t) => ({
    ...t,
    id: t.id ? t.id : nanoid(),
    rank: getRank(t.num) - rankMin + 1,
    num: typeof t?.num === "string" ? t.num : "0",
    style3D: t.style3D ? t.style3D : "TRANSPARENT",
    shape: t.drawingShape,
    code: t.isTitle || t.isAgg ? "" : t.code,
    unit: t.isTitle ? "" : t.unit,
    material: t.material ? t.material.name : "",
    color: t.material ? t.material.color : t.color,
    ressources: t.res?.length ? t.res.length : t.isTitle ? "" : 0,
    __reorder__: t.name,
  }));

  // options
  if (sortElementTypesByNum) result.sort((a, b) => sortByNum(a.num, b.num));

  return result;
}
