import React, {useState, useEffect} from "react";

import {Grid, Typography, InputBase, Box} from "@mui/material";
export default function FieldNumber({
  name,
  value,
  onChange,
  locked,
  sizeKey = 12,
  horizontal = false,
  horizontalHeader = false,
  width,
}) {
  // state

  const initTempValue = typeof value === "number" ? value : "";
  const [tempValue, setTempValue] = useState(initTempValue);
  useEffect(() => setTempValue(initTempValue), [initTempValue]);

  // handlers

  function handleInputChange(e) {
    const value = e.target.value.replace(",", ".");
    setTempValue(value);
  }

  function handleBlur() {
    const value = tempValue === "" ? null : parseFloat(tempValue);
    onChange(value);
  }

  return (
    <Grid
      container
      sx={{
        px: 1,
        width: horizontal ? width : 1,
        minWidth: horizontal ? width : 1,
        borderBottom: horizontal
          ? "unset"
          : (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      {(!horizontal || horizontalHeader) && (
        <Grid xs={horizontalHeader ? 12 : sizeKey} item>
          <Box sx={{display: "flex", height: 1, alignItems: "center"}}>
            <Typography sx={{fontSize: 13}} noWrap>
              {name}
            </Typography>
          </Box>
        </Grid>
      )}
      {!horizontalHeader && (
        <Grid
          xs={horizontal ? 12 : 12 - sizeKey}
          item
          sx={{
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
            px: 1,
          }}
        >
          <InputBase
            disabled={locked}
            value={tempValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            sx={{fontSize: 13, py: 0, "& .MuiInputBase-input": {p: 0}}}
          />
        </Grid>
      )}
    </Grid>
  );
}
