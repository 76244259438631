import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Switch} from "@mui/material";
import {
  ArrowBackIos as Left,
  ArrowForwardIos as Right,
} from "@mui/icons-material";
import {darken, lighten} from "@mui/material/styles";
import {DataGridPro, frFR, GridActionsCellItem} from "@mui/x-data-grid-pro";

import {
  // setEditedElementTypesGroup,
  setSelectedElementTypeIds,
  updateElementTypeInEditedElementTypesGroup,
  moveEditedElementType,
} from "../elementTypesSlice";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
// import useElementTypesDrawingShapesOptions from "../hooks/useElementTypeDrawingShapesOptions";

import elementTypesForDataGrid from "../utils/elementTypesForDataGrid";
import elementTypeFromDatagridToState from "../utils/elementTypeFromDatagridToState";

import DatagridElementTypesContextMenu from "./DatagridElementTypesContextMenu";
import SelectorElementTypeColorIcon from "./SelectorElementTypeColorIcon";

// import FieldSelector from "Features/kvform/components/FieldSelector";
import SelectorDrawingShapeInDatagrid from "./SelectorDrawingShapeInDatagrid";
import IconButtonDialogDeleteElementType from "./IconButtonDialogDeleteElementType";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridElementTypes({scene}) {
  const dispatch = useDispatch();

  // strings

  const rankS = "Rang";
  const numS = "Num";
  const codeS = "Code";
  const titleS = "Titre";
  const colorS = "Couleur";
  const nameS = "Nom";
  const unitS = "Unité";
  const ressourcesS = "Art.";
  const drawingShapeS = "Forme";

  // data

  let elementTypes = useElementTypesBySceneProxy(scene);
  // const drawingShapesOptions = useElementTypesDrawingShapesOptions();

  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  const selectedElementTypeIds = useSelector(
    (s) => s.elementTypes.selectedElementTypeIds
  );

  const datagridIsInEditMode = useSelector(
    (s) => s.elementTypes.datagridIsInEditMode
  );

  const editedElementTypesGroup = useSelector(
    (s) => s.elementTypes.editedElementTypesGroup
  );

  const showReorderHelpers = useSelector(
    (s) => s.elementTypes.showElementTypesReorderHelpers
  );

  // local states

  const [contextMenu, setContextMenu] = useState(null);

  // selection model

  const selectionModel = datagridIsInEditMode ? [] : [...selectedElementTypeIds];

  // grid - rows

  let types = elementTypes;
  if (datagridIsInEditMode)
    types = Array.isArray(editedElementTypesGroup?.elementTypes)
      ? editedElementTypesGroup.elementTypes.map((t) => ({
          ...t,
          groupId: editedElementTypesGroup.id,
        }))
      : [];
  if (selectedGroupProxy) {
    types = types.filter(
      (t) =>
        (t.groupId && t.groupId === selectedGroupProxy.id) ||
        (!t.groupId && t.group === selectedGroupProxy.name)
    );
  } else {
    types = [];
  }

  const rows = elementTypesForDataGrid(types, {
    sortElementTypesByNum: !datagridIsInEditMode,
  });

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    if (selectionId === selectedElementTypeIds[0] && selection.length === 1) {
      dispatch(setSelectedElementTypeIds([]));
    } else {
      dispatch(setSelectedElementTypeIds(selection));
    }
  }

  function handleIsTitleChange(event, row) {
    const elementType = elementTypeFromDatagridToState(row);
    elementType.isTitle = event.target.checked;
    dispatch(
      updateElementTypeInEditedElementTypesGroup({
        elementType,
        updateNums: true,
      })
    );
  }

  function handleDrawingShapeChange(drawingShape, row) {
    const elementType = elementTypeFromDatagridToState(row);
    elementType.drawingShape = drawingShape;
    dispatch(
      updateElementTypeInEditedElementTypesGroup({
        elementType,
        updateNums: true,
      })
    );
  }

  function handleColorChange(color, row) {
    const elementType = elementTypeFromDatagridToState(row);
    elementType.color = color;
    dispatch(
      updateElementTypeInEditedElementTypesGroup({
        elementType,
        updateNums: true,
      })
    );
  }

  function handleProcessRowUpdate(updatedRow) {
    const elementType = elementTypeFromDatagridToState(updatedRow);
    dispatch(
      updateElementTypeInEditedElementTypesGroup({
        elementType,
        updateNums: true,
      })
    );
    return updatedRow;
  }

  function handleChangeElementTypeOrder(row, side) {
    const elementType = elementTypeFromDatagridToState(row);
    let num = elementType.num;
    if (side === "left") {
      const numArray = num.split(".");
      if (numArray.length !== 1) {
        numArray.pop();
        num = numArray.join(".");
      }
    } else {
      num = num + ".1";
    }
    elementType.num = num;
    dispatch(
      updateElementTypeInEditedElementTypesGroup({
        elementType,
        updateNums: true,
      })
    );
  }
  function handleRowOrderChange(params) {
    const {oldIndex, targetIndex, row} = params;
    const movedEditedElementTypeId = row?.id;
    if (targetIndex > 0) {
      const newRows = [...rows];
      newRows.splice(oldIndex, 1);
      newRows.splice(targetIndex - 1, row);
      const prevEditedElementTypeId = newRows[targetIndex - 1].id;

      dispatch(
        moveEditedElementType({
          elementTypeId: movedEditedElementTypeId,
          prevElementTypeId: prevEditedElementTypeId,
          updateNums: true,
        })
      );
    } else {
      dispatch(
        moveEditedElementType({
          elementTypeId: movedEditedElementTypeId,
          isFirstItem: true,
          updateNums: true,
        })
      );
    }
  }

  function handleOpenContextMenu(event) {
    if (datagridIsInEditMode) {
      event.preventDefault();

      const rightClickedRow = event.currentTarget.getAttribute("data-id");
      if (rightClickedRow) {
        dispatch(setSelectedElementTypeIds([rightClickedRow]));
      }

      setContextMenu(
        contextMenu === null
          ? {mouseX: event.clientX - 2, mouseY: event.clientY - 4}
          : null
      );
    }
  }

  function handleContextMenuClose() {
    setContextMenu(null);
  }

  // grid - columns

  const columns = [
    {
      field: "rank",
      headerName: rankS,
      // width: 100,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Left size="small" />}
          label="<="
          onClick={() => handleChangeElementTypeOrder(params.row, "left")}
        />,
        <GridActionsCellItem
          icon={<Right size="small" />}
          label="=>"
          onClick={() => handleChangeElementTypeOrder(params.row, "right")}
        />,
      ],
    },
    {
      field: "num",
      headerName: numS,
      width: 100,
      editable: datagridIsInEditMode,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
      editable: datagridIsInEditMode,
    },
    {
      field: "code",
      headerName: codeS,
      width: 100,
      editable: datagridIsInEditMode,
    },
    {
      field: "isTitle",
      headerName: titleS,
      // type: "boolean",
      editable: datagridIsInEditMode,
      renderCell: (params) => (
        <Switch size="small" checked={params.row.isTitle} onChange={(isTitle) => handleIsTitleChange(isTitle, params.row)} />
      ),
    },
    {
      field: "drawingShape",
      width: 120,
      headerName: drawingShapeS,
      renderCell: (params) => {
        if (!params.row.isTitle)
          return <SelectorDrawingShapeInDatagrid
            drawingShape={params.value}
            onChange={(shape) => handleDrawingShapeChange(shape, params.row)}
            editable={datagridIsInEditMode}
          />;
        else return <></>;
      },
    },
    {
      field: "color",
      headerName: colorS,
      renderCell: (params) => {
        if (!params.row.isTitle)
          return <SelectorElementTypeColorIcon
            color={params.value}
            drawingShape={params.row.drawingShape}
            editable={datagridIsInEditMode}
            onChange={(color) => handleColorChange(color, params.row)}
          />;
          else return <></>;
      },
    },
    {
      field: "unit",
      headerName: unitS,
      width: 80,
      editable: datagridIsInEditMode,
    },
    {
      field: "ressources",
      headerName: ressourcesS,
      width: 80,
    },
    {
      field: "delete",
      type: "actions",
      width: 40,
      headerName: "",
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <IconButtonDialogDeleteElementType elementTypeId={params.row.id} />
      ),
    },
  ];

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--Disabled": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGridPro
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        sx={{"& .MuiDataGrid-cell--withRenderer": {p: 0}}}
        density="compact"
        columnVisibilityModel={{
          rank: datagridIsInEditMode && showReorderHelpers,
          isTitle: datagridIsInEditMode,
          ressources: !datagridIsInEditMode,
          delete: datagridIsInEditMode,
        }}
        rows={rows}
        columns={columns}
        rowReordering={datagridIsInEditMode && showReorderHelpers}
        onRowOrderChange={handleRowOrderChange}
        processRowUpdate={handleProcessRowUpdate}
        onRowSelectionModelChange={handleSelectionChange}
        slotProps={{
          row: {
            onContextMenu: handleOpenContextMenu,
            style: {
              cursor: datagridIsInEditMode ? "context-menu" : "inherit",
            },
          },
        }}
        rowSelectionModel={selectionModel}
        getRowClassName={(params) => {
          if (params.row.style === "title1" ||
            (params.row.isTitle && params.row.style === undefined && params.row.rank === 1)
          )
            return `super-app-theme--Title1`;
          if (params.row.style === "title2" ||
            (params.row.isTitle && params.row.style === undefined && params.row.rank === 2)
          )
            return `super-app-theme--Title2`;
          if (params.row.style === "detail") return `super-app-theme--Child`;
          if (params.row.disabled) return `super-app-theme--Disabled`;
        }}
      />
      <DatagridElementTypesContextMenu
        scene={scene}
        open={contextMenu !== null}
        onClose={handleContextMenuClose}
        anchorPosition={
          contextMenu !== null
            ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
            : undefined
        }
        nums={rows.map((row) => row.num)}
      />
    </Box>
  );
}
