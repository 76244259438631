import {Box} from "@mui/material";
import SectionConfigModulesColumn from "./SectionConfigModulesColumn";

export default function SectionConfigModules({scene}) {
  const col1 = {
    label: "Projet",
    modules: ["INPUTS", "SECTORS", "ZONES", "MARKERS", "VERSIONS"],
  };
  const col2 = {
    label: "Repérage",
    modules: [
      "ELEMENT_TYPES",
      "PACKAGES",
      "EDIT_MEASUREMENTS",
      "MEASUREMENTS",
      "PROCEDURES",
    ],
  };
  const col3 = {
    label: "Quantités",
    modules: ["RESSOURCES", "RELATIONS", "ROOMS", "PHASES"],
  };
  const col4 = {
    label: "Jeux de données",
    modules: ["SURVEYS", "SAMPLES", "PRICINGS", "ISSUES", "PROGRESS"],
  };
  const col5 = {
    label: "Exports",
    modules: ["VIEWS", "REPORTS", "SHAREDBOXES", "BIMAPPS"],
  };

  const cols = [col1, col2, col3, col4, col5];

  return (
    <Box sx={{display: "flex", justifyContent: "space-between"}}>
      {cols.map((col) => {
        return (
          <Box sx={{p: 1, px: 2, minWidth: 0}}>
            <SectionConfigModulesColumn
              title={col.label}
              moduleKeys={col.modules}
              scene={scene}
            />
          </Box>
        );
      })}
    </Box>
  );
}
