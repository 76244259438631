import {capitalizeStart} from "Features/translations/utils";

export function getNameFromEmail(email) {
  if (!email) return "";

  let names = email.split("@")[0];
  names = names.split(".");
  if (names.length > 1) {
    names = `${capitalizeStart(names[0])} ${capitalizeStart(names[1])}`;
  } else {
    names = capitalizeStart(names[0]);
  }
  return names;
}

export function getInitialsFromName(name) {
  if (!name) return "?";

  const names = name.split(" ");
  if (names.length > 1) {
    return `${names[0].charAt(0).toUpperCase()}${names[1]
      .charAt(0)
      .toUpperCase()}`;
  } else {
    return `${names[0].charAt(0).toUpperCase()}`;
  }
}
