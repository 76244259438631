import {Box} from "@mui/material";
import FieldRoomsGroups from "Features/rooms/components/FieldRoomsGroups";
import useSelectedSurvey from "../hooks/useSelectedSurvey";

export default function SectionSurveyInSelectionPanel(scene) {
  // data

  const selectedSurvey = useSelectedSurvey(scene);

  // helpers

  const roomsGroupsIds = [];

  // handlers

  function handleRoomsGroupsChange(ids) {
    console.log("debugAFA", ids);
  }
  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <FieldRoomsGroups
        scene={scene}
        name="Pièces"
        value={roomsGroupsIds}
        onChange={handleRoomsGroupsChange}
      />
    </Box>
  );
}
