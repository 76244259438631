export default function getTypeRessourceMap(types, ressources) {
  const typeRessourceMap = [];
  types.forEach((type) => {
    const res = type.res;
    if (res && Array.isArray(res)) {
      res.forEach(({resId, mode, func}) => {
        const ressource = ressources.find((r) => r.id === resId);
        if (ressource) {
          typeRessourceMap.push({
            typeId: type.id,
            typeGroup: type.group ? type.group : "-?-",
            typeNum: type.num ? type.num : "0",
            typeName: type.name,
            typeIsTitle: type.isTitle,
            resId,
            resGroup: ressource.group ? ressource.group : "-?-",
            resNum: ressource.num ? ressource.num : "0",
            resName: ressource.name,
            mode,
            func,
          });
        }
      });
    }
  });
  return typeRessourceMap;
}
