import {client} from "API/capla360";

export async function createNoteContributionService({
  accessToken,
  listingId,
  contribution,
}) {
  const response = await client.post(
    `listings/${listingId}/contributions/`,
    contribution,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {
    data: response?.data,
    listingId,
    noteId: contribution?.entityToId,
  };
}
