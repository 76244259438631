import React, {useState, useEffect} from "react";

import {useSelector} from "react-redux";
export default function useModelLoadingProgress(model) {
  //
  const [pro, setPro] = useState(0);
  //
  const modelsProgress = useSelector((state) => state.viewer3D.modelsProgress);
  const sceneProgress = modelsProgress[model?.sceneClientId];
  let progress = 0;
  if (sceneProgress) {
    progress = sceneProgress[model?.id];
    if (!progress) progress = 0;
  }
  useEffect(() => {
    console.log("EFFECT, progress");
    setPro(progress);
  }, [progress]);
  //
  return pro;
}
