import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { nanoid } from "@reduxjs/toolkit";

import { TextField } from "@mui/material"

import {
  createPrice,
  updatePrice,
  deletePrice,
  setBackupValue,
  setEditedValue,
} from "../pricingSlice";
import formatPriceWithSpaces from "../utils/formatPriceWithSpaces";

export default function TextFieldRessourcePriceInSelectionDetail({scene, data}) {
  const dispatch = useDispatch();
  const {shareLink} = useParams();

  // data

  const pricings = useSelector((s) => s.pricing.pricings);
  const editedValue = useSelector((s) => s.pricing.editedValue);

  // state

  const [price, setPrice] = useState(data.value);
  
  // handler

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);
    dispatch(setEditedValue(value));
  };

  const backupThePrice = (e) => {
    dispatch(setBackupValue(e.target.value));
  };

  const updateThePrice = () => {
    const resId = data.resId;
    let pu = parseFloat(price.replace(/\s/g, ''));
    if (typeof pu !== "number" || !pu || isNaN(pu)) {
      pu = null;
    }
    const pricing = pricings.find((p) => 
      p.sharedBimboxId === shareLink &&
      p.resId === resId
    );
    if (pricing) {
      if (pu)
        dispatch(updatePrice({
          sceneId: scene?.id,
          price:{...pricing, pu}
        }));
      else {
        dispatch(deletePrice({
          sceneId: scene?.id,
          price: pricing
        }));
      }
    } else if (pu) {
      dispatch(createPrice({
        sceneId: scene?.id,
        price: {
          id: nanoid(),
          sharedBimboxId: shareLink,
          resId,
          pu
        }
      }));
    }
    setPrice(formatPriceWithSpaces(editedValue));
  };

  // effect

  useEffect(() => {
    setPrice(data.value);
    dispatch(setEditedValue(data.value));
  }, [data.value])

  return (
    <TextField
      fullWidth
      autoComplete="off"
      value={price ? price : ""}
      onFocus={(e) => backupThePrice(e)}
      onChange={(e) => handlePriceChange(e)}
      onBlur={updateThePrice}
      sx={{
        display: "flex",
        height: "40px",
        py: 0,
        input: {
          display: "flex",
          height: "40px",
          color: (theme) => `${theme.palette.secondary.main}`,
          textAlign: "center",
          p: 0,
        },
        border: (theme) => `2px solid ${theme.palette.stabilo.orange}`,
        [`& fieldset`]: {border: "none"},
      }}
    />
  )
}