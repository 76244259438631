import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {setIsEditing, setEditedPdfModel} from "Features/pdf/pdfSlice";

export default function ActionsInputInSelectionPanel() {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.pdf.isEditing);
  const selectedPdfModel = useSelectedPdfModel();
  const editedPdfModel = useSelector((s) => s.pdf.editedPdfModel);

  // helper - ressource

  // let pdfmodel = isEditing ? editedPdfModel : selectedPdfModel;

  // handlers

  function handleEditClick() {
    dispatch(setIsEditing(true));
    dispatch(setEditedPdfModel({...selectedPdfModel}));
  }

  function handleCancelClick() {
    dispatch(setIsEditing(false));
  }

  function handleSaveClick() {
    // update pdfmodel
    const updatedModel = {...editedPdfModel};
    dispatch(updateModel({updatedModel}));
    // end
    dispatch(setIsEditing(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* <Box sx={{flexGrow: 1}}>
        <Button variant="contained" color="primary" fullWidth>
          {deleteS}
        </Button>
      </Box> */}

      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
