import {nanoid} from "@reduxjs/toolkit";
import getItemNameFromTemplate from "./getItemNameFromTemplate";
import getMeasNomenclatureFromMeasurements from "./getMeasNomenclatureFromMeasurements";
import getTemplateFromElementTypeName from "./getTemplateFromElementTypeName";

import updateNumsOfTypeNomenclature from "./updateNumsOfTypeNomenclature";

import templateNomenclatureMap from "../data/templateNomenclatureMap";

import {testMeasurementOnFuncFilters} from "Features/articles/utils/testMeasurementOnFuncFilters";

const testTwoItemsOnFields = (item1, item2, fields) => {
  let test = 1;
  fields.forEach((field) => {
    test = test * item1[field] === item2[field] ? 1 : 0;
  });
  return Boolean(test);
};

function getGroupOfElementType(elementTypeId, groups) {
  let item;
  let groupId;
  groups.forEach((group) => {
    let elementType = group.elementTypes.find((t) => t.id === elementTypeId);
    if (elementType) {
      item = elementType;
      groupId = group.id;
    }
  });
  return [groupId, item];
}

export default function updateTypeNomenclatureFromMeasurements(
  typeNomenclature,
  measurements,
  options
) {
  // options

  const parentElementType = options?.parentElementType;
  const articleName = options?.articleName;
  const globalScope = options?.globalScope;
  const groupsWithSortedTypes = options?.groupsWithSortedTypes;
  const sectors = options?.sectors;

  // firstType

  let firstType;
  if (parentElementType) {
    firstType = parentElementType;
  } else {
    firstType = typeNomenclature.find((type) => type.num.endsWith("1"));
  }

  // filter measurents

  if (parentElementType) {
    measurements = measurements.filter(
      (m) => m.elementTypeId === parentElementType.id
    );
  }

  if (globalScope) {
    measurements = measurements.filter((m) => {
      const sector = sectors.find((s) => s.id === m.sectorId);
      const test = testMeasurementOnFuncFilters({
        measurement: m,
        sector,
        filtersStr: globalScope,
      });
      return test;
    });
    console.log("MeasMM", measurements);
  }

  if (!firstType && !globalScope) return typeNomenclature;

  // template

  const label = articleName ?? firstType?.name;
  const template = getTemplateFromElementTypeName(label);

  if (!template) return typeNomenclature;

  const nomenclature = templateNomenclatureMap[template];
  const fields = nomenclature.fields;

  const measNomenclature = getMeasNomenclatureFromMeasurements(
    measurements,
    template
  );

  console.log("MEAS_NOMENCLATURE", measNomenclature);

  // result

  let newTypeItems = [];

  // new elementTypes
  const newElementTypes = [];

  measNomenclature.forEach((item) => {
    const existingType = typeNomenclature.find((type) => {
      return testTwoItemsOnFields(type, item, fields);
    });
    if (!existingType) {
      if (!item.elementTypeId) {
        // this is the usual case
        const newType = {
          ...firstType,
          name: getItemNameFromTemplate(item, template),
          newId: nanoid(), // to fake the new element.
          fromTypeId: firstType?.id,
          num: `${firstType?.num ?? "0"}.1`,
          color: firstType?.color,
          fields,
          //color: lighten(firstType.color, Math.random() * 0.5),
        };
        // add fields
        fields.forEach((field) => {
          newType[field] = item[field];
        });
        newElementTypes.push(newType);
      } else {
        // this is the case when measurementsNome contains an array of elementTypes :
        const elementTypes = item.elementTypeId.map((elementTypeId) => {
          const [groupId, elementType] = getGroupOfElementType(
            elementTypeId,
            groupsWithSortedTypes
          );
          if (elementType && groupId) {
            return {...elementType, groupId};
          } else {
            return null;
          }
        });
        let filtersStr;
        if (globalScope) {
          if (template === "p0") {
            filtersStr = [`MC==="${item.p0}"`];
          } else if (template === "p3") {
            filtersStr = [`P3==="${item.p3}"`];
          }
        }

        const subArticlePropsItem = {
          elementTypes,
          name: getItemNameFromTemplate(item, template),
          filtersStr,
        };
        newTypeItems.push(subArticlePropsItem);
      }

      //
    }
  });

  // updateNums

  if (!globalScope) {
    const newNomenclature = [...typeNomenclature, ...newElementTypes];
    newTypeItems = updateNumsOfTypeNomenclature(newNomenclature, template);
  }

  return newTypeItems;
}
