import { useDispatch, useSelector } from "react-redux";

import {Box, Chip} from "@mui/material";

import { setSelectedGroupPricing } from "../pricingSlice";

export default function ToolbarPricingsMain({
  ressourcesGroups,
  concernedGroups,
  sharedboxesNbByGroup,
}) {
  const dispatch = useDispatch();

  // data

  const selectedGroupPricing = useSelector((s) => s.pricing.selectedGroupPricing);

  // helpers

  const options = ressourcesGroups
    .filter((g) => concernedGroups.has(g))
    .map((g) => ({id: g, name: g, count: sharedboxesNbByGroup[g]}));

  // handlers

  const handleChange = (value) => {
    dispatch(setSelectedGroupPricing(value.name));
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        width: 1,
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {options.map((option) => {
        return (
          <Chip
            size="small"
            key={option.id}
            label={`${option.name} (${option.count})`}
            variant={
              option.name === selectedGroupPricing ? "filled" : "outlined"
            }
            onClick={() => handleChange(option)}
          />
        );
      })}
    </Box>
  );
}