import useSectorsByScene from "./useSectorsByScene";

// elevations =
// bat A
// - RDC
// - R+1
// - R+2
//..
export default function useElevationsFromSceneSectors(scene) {
  const sectors = useSectorsByScene(scene);
  const elevations = sectors
    .filter((s) => typeof s.z === "number")
    .map((s) => ({...s, label: `${s.code} (${s.z} m)`}))
    .sort((a, b) => b.z - a.z);
  return elevations;
}
