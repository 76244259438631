import {useSelector} from "react-redux";

export default function useSelectedEditedElementType() {
  const editedElementTypesGroup = useSelector(
    (s) => s.elementTypes.editedElementTypesGroup
  );

  const selectedTypeId = useSelector(
    (s) => s.elementTypes.selectedElementTypeId
  );

  const types = Array.isArray(editedElementTypesGroup?.elementTypes)
    ? editedElementTypesGroup.elementTypes
    : [];

  return types.find((t) => t.id === selectedTypeId);
}
