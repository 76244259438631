import {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {unwrapResult, wrapresult} from "@reduxjs/toolkit";
import {fetchNotes} from "Features/notes/notesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useNotes({listingId}) {
  const dispatch = useDispatch();

  // data

  const notesDataset = useSelector((state) => state.notes.datasets[listingId]);
  const initialNotes = notesDataset?.data;
  const initialStatus = notesDataset?.status;

  // local state

  const [notes, setNotes] = useState(initialNotes);
  const [status, setStatus] = useState(initialStatus);

  // auth

  const accessToken = useAccessToken();

  // handler

  async function getNotes() {
    try {
      setStatus("loading");
      const result = await dispatch(fetchNotes({listingId, accessToken}));
      const fetchedNotes = unwrapResult(result);
      setNotes(fetchedNotes.data);
    } catch (error) {
      console.log(error);
      setStatus("failed");
    } finally {
      setStatus("succeed");
    }
  }

  // effect

  useEffect(() => {
    if (!initialStatus && accessToken && listingId) {
      getNotes();
      console.log("fetch notes");
    }
  }, [accessToken, status, listingId]);

  // effect when initial notes change
  useEffect(() => {
    setNotes(initialNotes);
  }, [initialNotes]);

  // return
  return [notes, status];
}
