import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {updateScene} from "Features/scenes/scenesSlice";

import {Box, Typography, Button} from "@mui/material";
import useSectorsByScene from "../hooks/useSectorsByScene";
import getBuildingSectorsMap from "../utils/getBuildingSectorsMap";
import ContainerEditBuilding from "./ContainerEditBuilding";
import ContainerAddBuilding from "./ContainerAddBuilding";
import {nanoid} from "@reduxjs/toolkit";
import getItemsMapById from "Utils/getItemsMapById";

export default function SectionEditSectors({scene, onSaved}) {
  const dispatch = useDispatch();

  // string

  const saveS = "Enregistrer";
  const title = "Secteurs de la bimbox";
  const addBuildingS = "Ensemble de secteurs (ex: Bat.A)";

  // data

  const sectors = useSectorsByScene(scene);

  // state

  const [tempSectors, setTempSectors] = useState(sectors);
  useEffect(() => {
    setTempSectors(sectors);
  }, [sectors.length]);

  // helpers

  const buildingSectorsMap = getBuildingSectorsMap(tempSectors);

  const buildings = Object.keys(buildingSectorsMap);

  // handlers

  function handleAddBuilding(building) {
    const newSector = {id: nanoid(), code: "R+x", building};
    const newSectors = [...tempSectors, newSector];
    setTempSectors(newSectors);
  }

  function handleSectorsChange(sectors) {
    const newSectorsMap = getItemsMapById(sectors);
    const newSectors = tempSectors.map((oldSector) => {
      const newSector = newSectorsMap[oldSector.id];
      if (newSector) {
        return newSector;
      } else {
        return oldSector;
      }
    });
    setTempSectors(newSectors);
  }

  function handleAddSector(sector) {
    setTempSectors([...tempSectors, sector]);
  }

  function handleDeleteSector(sector) {
    setTempSectors(tempSectors.filter((t) => t.id !== sector.id));
  }

  function handleSave() {
    const newSectors = tempSectors.map((tempSector) => {
      return {
        ...tempSector,
        name: tempSector.name ?? "secteur " + tempSector.code,
      };
    });
    const newScene = {
      ...scene,
      data: {...scene?.data, sectors: newSectors},
    };
    dispatch(updateScene({scene: newScene}));
    onSaved();
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "auto",
        p: 8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "common.white",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Box sx={{color: "common.white", mt: 2}}>
          <Button color="primary" variant="contained" onClick={handleSave}>
            {saveS}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: 1,
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      >
        {buildings
          .sort((a, b) => a.localeCompare(b))
          .map((building) => {
            const buildingSectors = buildingSectorsMap[building];
            return (
              <Box sx={{p: 1}} key={building}>
                <ContainerEditBuilding
                  building={building !== "undefined" ? building : null}
                  sectors={buildingSectors}
                  onChange={handleSectorsChange}
                  onAddSector={handleAddSector}
                  onDeleteSector={handleDeleteSector}
                />
              </Box>
            );
          })}
        <Box sx={{p: 1}}>
          <ContainerAddBuilding onAdd={handleAddBuilding} />
        </Box>
      </Box>
    </Box>
  );
}
