import {useState} from "react";
// import {useDispatch} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogEditRoomZones from "./DialogEditRoomZones";

import useSelectedRoom from "../hooks/useSelectedRoom";
import useDeleteSceneRoom from "../hooks/useDeleteSceneRoom";

// import useAccessToken from "Features/auth/useAccessToken";
import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function ButtonMoreActionsRoom({scene}) {
  // const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const deleteS = "Delete";
  const editRoomZonesS = "Zones";

  // data

  const selectedRoom = useSelectedRoom(scene);
  const deleteRoom = useDeleteSceneRoom(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [openEditRoomZonesDialog, setOpenEditRoomZonesDialog] = useState(false);

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    deleteRoom(selectedRoom);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - handlers - edit room zones

  function handleEditRoomZones() {
    setOpenEditRoomZonesDialog(true);
    setAnchorEl(null);
  }
  function handleCancelEditRoomZones() {
    setOpenEditRoomZonesDialog(false);
  }
  // helpers - tools

  const tools = [
    {
      id: "ZONES",
      label: editRoomZonesS,
      handler: handleEditRoomZones,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogEditRoomZones
        scene={scene}
        room={selectedRoom}
        open={openEditRoomZonesDialog}
        onClose={handleCancelEditRoomZones}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"room"}
      />
    </>
  );
}
