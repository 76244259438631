import React from "react";

import {Box} from "@mui/material";
export default function SceneImage({scene}) {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        background: (theme) => `url(${scene?.imageUrl}) `,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
      }}
    ></Box>
  );
}
