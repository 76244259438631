import {useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {nanoid} from "@reduxjs/toolkit";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

// import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";

import {setCopiedMeasurements} from "Features/measurements/measurementsSlice";
// import {updateModel} from "Features/viewer3D/viewer3DSlice";
import SelectorAutocomplete from "Components/SelectorAutocomplete";
import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

export default function DialogCopyMeasurements({
  open,
  onClose,
  measurements,
  scene,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Copiez les éléments";
  const description = `[Optionnel] Sélectionnez un repère`;
  const label = "Repère";
  const cancelS = "Annuler";
  const saveS = "Copier";

  const countLabel = `Eléments / ouvrages sélectionnés : x${measurements?.length}`;

  // data

  const {modelId} = useParams();
  const pdfModelId = useSelectedPdfModel()?.id;
  const markers = useMarkersByScene(scene?.clientId);
  // const measurementsModel = useSelectedMeasurementsModel();
  const options = markers.map((m) => {
    return {name: m.description};
  });

  // state

  const [marker, setMarker] = useState({name: ""});

  // handlers

  function handleCancel() {
    onClose();
  }

  function handleMarkerChange(m) {
    if (m) setMarker(m);
    else setMarker({name: ""});
  }

  function handleSave() {
    // new measurements
    const newMeasurements = measurements.map((m) => {
      let newMeasurement = {
        ...m,
        id: nanoid(),
      };
      return newMeasurement;
    });

    dispatch(
      setCopiedMeasurements({
        modelId,
        pdfModelId,
        measurements: newMeasurements,
        marker: markers.find((m) => m.description === marker.name),
      })
    );

    console.log("copied measurements", newMeasurements);

    // end
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {countLabel}
        </Typography>
        <Box sx={{p: 2}}>
          <Box sx={{bgcolor: "background.default", p: 1, borderRadius: "4px"}}>
            <Typography variant="body2" sx={{mb: 2}}>
              {description}
            </Typography>
            <SelectorAutocomplete
              options={options}
              value={marker}
              onChange={handleMarkerChange}
              name={label}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleSave} variant="contained">
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
