import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  setMeasurementsModelsInScope,
  setShortcutElementTypes,
  setSectorsInScope,
} from "./measurementsSlice";

import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function useMeasurementsModelsInitialScope(scene) {
  const dispatch = useDispatch();

  // data

  const ids = useSelector((s) => s.measurements.measurementsModelsInScope);

  const elementTypes = useElementTypesBySceneProxy(scene);
  
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  const scenesStatus = useSelector((s) => s.viewer3D.scenesStatus);
  
  const measurementsModels = useSelector((s) => s.viewer3D.models).filter((m) =>
    m.type === "MEASUREMENTS" &&
    m.sceneClientId === scene?.clientId &&
    m.enabled
  );

  const sectors = useSectorsByScene(scene);

  // helper - scene Status

  const sceneStatus = scenesStatus[scene?.clientId];

  // helper - shouldUpdate

  const shouldUpdate = sceneStatus === "preloaded";

  // effect

  useEffect(() => {
    dispatch(setMeasurementsModelsInScope(measurementsModels.map((m) => m.id)));
    dispatch(setSectorsInScope([undefined, ...sectors.map((s) => s.id), null]));
  }, [shouldUpdate]);

  useEffect(() => {
    if (shortcutElementTypes === null && elementTypes.length > 0) {
      dispatch(setShortcutElementTypes(elementTypes.map((t) => t.id)));
    }
  }, [shortcutElementTypes, elementTypes.length]);

  return ids;
}
