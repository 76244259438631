import {client} from "API/capla360";

export async function createFileService({
  file,
  file_name,
  listingId,
  userId,
  accessToken,
  onUploadProgress,
}) {
  // prepare form data

  let formData = new FormData();
  formData.append("file", file);
  formData.append("file_name", file_name);
  formData.append("listing", listingId);

  // api call

  const response = await client.post(
    `/users/${userId}/listings/${listingId}/files/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      onUploadProgress,
    }
  );
  return response.data;
}

export async function createDocumentService({
  accessToken,
  userId,
  listingId,
  document,
}) {
  const response = await client.post(
    `/users/${userId}/listings/${listingId}/documents/`,
    document,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, listingId};
}
export async function fetchDocumentsService({accessToken, userId, listingId}) {
  const response = await client.get(
    `/users/${userId}/listings/${listingId}/documents/`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, listingId};
}

export async function createVersionService({
  accessToken,
  userId,
  listingId,
  documentId,
  file,
  name,
  onUploadProgress,
}) {
  // prepare form data

  let formData = new FormData();
  formData.append("file", file);
  formData.append("name", name);
  formData.append("listing", listingId);

  const response = await client.post(
    `/users/${userId}/listings/${listingId}/documents/${documentId}/`,
    formData,

    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      onUploadProgress,
    }
  );
  return {data: response.data, listingId, documentId};
}
