import {Box} from "@mui/material";

import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useOutletContext} from "react-router-dom";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

import ListElementTypesMultipleWithGroups from "Features/elementTypes/components/ListElementTypesMultipleWithGroups";
import ListRessourcesMultipleWithGroups from "Features/ressources/components/ListRessourcesMultipleWithGroups";
import {
  setSelectedTypeIdsInRelations,
  setSelectedResIdsInRelations,
  setSelectedResGroupProxyIdInRelations,
  setSelectedTypeGroupProxyIdInRelations,
} from "../relationsSlice";

import SectionTitle from "./SectionTitle";
import SectionEditRelations from "./SectionEditRelations";
import getRelationsFromTypeIds from "../utils/getRelationsFromTypeIds";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import getMeasurementsByRessourceWithQuantity from "Features/measurements/utils/getMeasurementsByRessourceWithQuantity";
import getMeasurementsFromPackages from "Features/measurements/utils/getMeasurementsFromPackages";

export default function PageRelations() {
  const dispatch = useDispatch();

  const width = 300;
  // params

  const {scene, caplaEditor} = useOutletContext();

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);

  const typeGroupId = useSelector(
    (s) => s.relations.selectedTypeGroupProxyIdInRelations
  );
  const resGroupId = useSelector(
    (s) => s.relations.selectedResGroupProxyIdInRelations
  );
  const typeIds = useSelector((s) => s.relations.selectedTypeIdsInRelations);
  const resIds = useSelector((s) => s.relations.selectedResIdsInRelations);

  const relationMap = useSelector((s) => s.relations.relationsTypesRessources);

  // data - measurements

  const packages = usePackagesByScene(scene);
  const measurements = getMeasurementsFromPackages(packages);

  // helpers

  const elementTypesWithMainQties = elementTypes.map((type) => ({
    ...type,
    mainUnit: "art.",
    mainQuantity: type.res?.length ?? 0,
  }));

  // helpers - measurements of selected types

  const fms = measurements.filter((m) => typeIds?.includes(m.elementTypeId));

  // helpers - ressourceByQties

  const measByResIdWithQty = getMeasurementsByRessourceWithQuantity(
    fms,
    elementTypes,
    ressources
  );

  const ressourcesWithQty = ressources.map((r) => ({
    ...r,
    qtyNet: measByResIdWithQty[r.id]?.qtyNet,
    qtyRaw: measByResIdWithQty[r.id]?.qtyRaw,
  }));

  // handlers

  function handleTypeSelectionChange(ids) {
    dispatch(setSelectedTypeIdsInRelations(ids));
  }
  function handleResSelectionChange(ids) {
    dispatch(setSelectedResIdsInRelations(ids));
  }

  function handleTypeGroupProxyChange(groupProxy) {
    dispatch(setSelectedTypeGroupProxyIdInRelations(groupProxy?.id));
  }
  function handleResGroupProxyChange(groupProxy) {
    console.log("DEBUG99", groupProxy);
    dispatch(setSelectedResGroupProxyIdInRelations(groupProxy?.id));
  }

  // init
  const typeIdsHash = typeIds?.length > 0 ? [...typeIds].sort().join() : "";

  useEffect(() => {
    const relationsFromTypeIds = getRelationsFromTypeIds(relationMap, typeIds);
    const newResIds = relationsFromTypeIds.map((rel) => rel?.resId);

    const resIds = [...new Set(newResIds)];
    dispatch(setSelectedResIdsInRelations(resIds));
  }, [typeIdsHash]);

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "background.default",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
      }}
    >
      <Box sx={{width: 1, height: 0, bgcolor: "common.white"}}></Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "top",
          justifyItems: "stretch",
          p: 5,
          minHeight: 0,
        }}
      >
        <Box
          sx={{
            bgcolor: "common.white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyItems: "stretch",
            p: 1,
            width,
            minWidth: width,
          }}
        >
          <SectionTitle sceneModule={"ELEMENT_TYPES"} scene={scene} />
          <ListElementTypesMultipleWithGroups
            elementTypes={elementTypesWithMainQties}
            selectedElementTypeIds={typeIds}
            selectedGroupId={typeGroupId}
            onSelectionChange={handleTypeSelectionChange}
            onSelectedGroupProxyChange={handleTypeGroupProxyChange}
            //multipleSelection={true}
            forceSelection={true}
            showMainQuantities={true}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            px: 3,
            minWidth: 0,
          }}
        >
          <Box sx={{display: "flex", minWidth: 0, mt: 2}}>
            <SectionEditRelations scene={scene} caplaEditor={caplaEditor} />
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: "common.white",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            justifyItems: "stretch",
            p: 1,
            width,
            minWidth: width,
          }}
        >
          <SectionTitle sceneModule={"RESSOURCES"} scene={scene} />
          <ListRessourcesMultipleWithGroups
            ressources={ressourcesWithQty}
            selectedRessourceIds={resIds}
            selectedGroupId={resGroupId}
            onSelectionChange={handleResSelectionChange}
            onSelectedGroupProxyChange={handleResGroupProxyChange}
            multipleSelection={true}
            resetSelectionOnGroupChange={false}
            forceSelection={true}
            hideQuantities={false}
          />
        </Box>
      </Box>
    </Box>
  );
}
