import {useDispatch} from "react-redux";

import {Button, Box} from "@mui/material";
import {Delete} from "@mui/icons-material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ButtonDeleteMarker({markerId, markersModel, editor3d}) {
  const dispatch = useDispatch();

  console.log("deleteModel", markerId, markersModel);
  // strings

  const deleteS = "Delete";

  // handlers

  function handleDeleteClick() {
    const oldItems = markersModel.elements.items;
    const newItems = oldItems.filter((m) => m.id !== markerId);
    const updatedModel = {
      ...markersModel,
      elements: {...markersModel.elements, items: newItems},
    };
    dispatch(updateModel({updatedModel}));
    editor3d?.sceneEditor.deleteMarker({
      markersModelId: markersModel.id,
      markerId,
    });
  }

  return (
    <Button
      sx={{height: 30}}
      startIcon={<Delete />}
      size="small"
      onClick={handleDeleteClick}
    >
      {deleteS}
    </Button>
  );
}
