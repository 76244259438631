import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import AddImagePartProcess from "./components/AddImagePartProcess";

import {setMode} from "Features/viewer3D/viewer3DSlice";

export default function ToolAddImagePart({editor3d, onClose}) {
  const dispatch = useDispatch();

  // data

  const mode = useSelector((state) => state.viewer3D.mode);

  // init

  useEffect(() => {
    dispatch(setMode("DRAWING_PART"));
  }, []);

  return (
    <Box>
      {mode === "DRAWING_PART" && (
        <AddImagePartProcess editor3d={editor3d} onClose={onClose} />
      )}
    </Box>
  );
}
