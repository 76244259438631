import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {useDispatch} from "react-redux";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import {useNavigate} from "react-router-dom";

export default function useEditPackage(scene) {
  const isStagingEnv = useIsStagingEnv();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sceneId = scene?.id ? scene.id : scene?.clientId;

  return (modelId) => {
    if (modelId) {
      dispatch(setSelectedMeasurementsModelId(modelId));
      let url = `/bimboxes/${sceneId}/${modelId}/measurements`;
      if (isStagingEnv) url = url + "?env=staging";
      navigate(url);
    }
  };
}
