import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {useSearchParams} from "react-router-dom";

import {Box} from "@mui/material";

import WebViewer from "@pdftron/webviewer";

import Layers from "./Layers";

import PdfEditor from "../js/PdfEditor";
import usePdfModelUrl from "../usePdfModelUrl";

import {
  setClickedObject,
  setPdftronOffset,
} from "Features/viewer3D/viewer3DSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
// import useFilteredIssues from "Features/issues/useFilteredIssues";
import useTranslation from "Features/translations/useTranslation";
import useSceneModule from "Features/navigation/useSceneModule";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import {
  setSelectedMeasurementIds,
  updateSelectedMeasurementIds,
} from "Features/measurements/measurementsSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import {setJump, setShowPdfSelector} from "../pdfSlice";
// import { setCurrentSelection } from "Features/selection/selectionSlice";

export default function PdfWebViewer({
  model,
  // editor,
  isPDF,
  scene,
  viewer,
  caplaEditor,
}) {
  const {i18n} = useTranslation();
  const dispatch = useDispatch();
  // const [searchParams] = useSearchParams();
  const sceneModule = useSceneModule();

  // refs

  const viewerRef = useRef();
  const pdfEditorRef = useRef();

  // helpers - mnger

  const mnger =
    pdfEditorRef.current?.annotationsManager?.measurementsPdfManager;
  // const aMnger = pdfEditorRef.current?.annotationsManager;

  // data

  const measurementsShowedInViewerPdf = useSelector(
    (s) => s.pdf.measurementsShowedInViewerPdf
  );
  const pdfPageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);
  console.log("DEBUG32", measurementsShowedInViewerPdf);

  // measurements in page

  const measurementsInPage = measurementsShowedInViewerPdf.filter(
    (measurement) => {
      return measurement?.drawingProps?.pageNumber === pdfPageNumber;
    }
  );

  // data
  const mode = useMeasurementsScopeMode(scene, viewer);

  const language = i18n.language;
  const pdfEditorIsLoadingDoc = useSelector((s) => s.pdf.pdfEditorIsLoadingDoc);
  const pdfModelIdLoaded = useSelector((s) => s.pdf.pdfModelIdLoaded);

  const os = useSelector((s) => s.ui.openSections);
  // const osHash = Object.values(os).join("-");

  const urlToUse = usePdfModelUrl(model, viewer);

  // const currentSelection = useSelector((s) => s.selection.currentSelection);
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  const hideSavedMeasurements = useSelector(
    (s) => s.measurements.hideSavedMeasurements
  );
  // const modifiedMeasurements = useSelector(
  //   (s) => s.measurements.modifiedMeasurements
  // );
  const modifiedMeasurements = caplaEditor
    ? Object.values(caplaEditor.measDataManager.modifications)
        .map((m) => m.modifiedIds)
        .flat()
    : [];

  const jump = useSelector((s) => s.pdf.jump);

  // data - annotations measurements

  const [fm, fmIds, fmIdsHash, filtersHash] = useFilteredSceneMeasurements(
    scene,
    mode,
    true
  );
  // const [fi, fiIds, fiIdsHash] = useFilteredIssues(scene, viewer);

  const [fmInViewer, fmIdsInViewer, fmIdsHashInViewer, filterHashInViewer] =
    useFilteredSceneMeasurements(scene, mode, false, true, true, true, viewer);

  // init

  async function init() {
    if (!caplaEditor?.editorPdf) {
      const wv = await WebViewer(
        {
          path: "../../../pdftron/public",
          licenseKey: process.env.REACT_APP_PDFTRON_KEY,
          initialDoc: model?.url,
          extension: "pdf",
          enableMeasurement: true,
          fullAPI: true,
        },
        viewerRef.current
      );
      const {Annotations, annotationManager} = wv.Core;
      annotationManager.removeEventListener("annotationDeselected");
      annotationManager.addEventListener(
        "annotationSelected",
        (annotations, action) => {
          // console.log("haaaaaaaaaaaaaaaaaaaaaa", action, caplaEditor.editorPdf.updateSelection, annotations)
          if (action === "selected") {
            const selectedAnnotation = annotations[0];
            const selectedType = selectedAnnotation?.uk?.type;
            caplaEditor.editorPdf.selectedAnnotation = selectedAnnotation;
            if (selectedType !== "MEASUREMENT" && selectedType !== "ISSUE") {
              const annotationEntity =
                caplaEditor.editorPdf.annotationsManager.getAnnotationEntity(
                  selectedAnnotation
                );
              if (!annotationEntity?.temp) {
                const elementAnnotation =
                  caplaEditor.editorPdf.annotationsManager.annotationEntityToModelElement(
                    annotationEntity
                  );
                dispatch(
                  setClickedObject({
                    type: "PDF_ANNOTATION",
                    modelId: selectedAnnotation.uk.measurementsModelId,
                    name: elementAnnotation?.name,
                    elementAnnotation,
                  })
                );
              }
            }
            if (selectedType === "ZONE") {
              const selectionId = selectedAnnotation.uk.annotationId;
              dispatch(setSelectedZoneId(selectionId));
              caplaEditor.editorPdf.annotationsManager.selectAnnotation(
                selectionId
              );
            }
            const measurementsSelected = annotations.filter(
              (a) => a.uk.type === "MEASUREMENT"
            );
            if (
              measurementsSelected.length > 0 &&
              caplaEditor.editorPdf.updateSelection
            ) {
              dispatch(
                updateSelectedMeasurementIds(
                  measurementsSelected.map((m) => m.Id)
                )
              );
              // 3D
              {
                measurementsSelected.forEach((annot) => {
                  caplaEditor?.editor3d.sceneEditor.selectElementEntity({
                    modelId: annot.uk.measurementsModelId,
                    entityID: annot.Id,
                    keepSelection: true,
                    fromPdf: true,
                  });
                });
              }
            }
          } else if (
            action === "deselected" &&
            caplaEditor.editorPdf.updateSelection
          ) {
            // if (caplaEditor.editorPdf.updateSelection) {
            const selected = annotationManager.getSelectedAnnotations();
            if (selected.length > 0) {
              const measurementsDeselected = annotations.filter(
                (a) => a.uk.type === "MEASUREMENT"
              );
              if (measurementsDeselected.length > 0) {
                dispatch(
                  updateSelectedMeasurementIds(
                    measurementsDeselected.map((m) => m.Id)
                  )
                );
                // 3D
                {
                  measurementsDeselected.forEach((annot) => {
                    caplaEditor.editor3d.sceneEditor.unselectElementEntity({
                      modelId: annot.uk.measurementsModelId,
                      entityID: annot.Id,
                      fromPdf: true,
                    });
                  });
                }
              } else if (annotations[0].uk.type === "ZONE") {
                dispatch(setSelectedZoneId(null));
                dispatch(setClickedObject({}));
                caplaEditor.editorPdf.annotationsManager.selectAnnotation(null);
              }
            } else {
              dispatch(setSelectedMeasurementIds([]));
              dispatch(setSelectedZoneId(null));
              dispatch(setClickedObject({}));
              caplaEditor.editorPdf.annotationsManager.selectAnnotation(null);
              caplaEditor.editor3d.sceneEditor.unselect();
            }
          }
        }
      );
      const {
        SelectionModel,
        BoxSelectionModel,
        CalloutSelectionModel,
        FreeTextSelectionModel,
        LineSelectionModel,
        PathSelectionModel,
        PolygonSelectionModel,
        RedactionSelectionModel,
        TextSelectionModel,
      } = Annotations;
      for (const selection of [
        BoxSelectionModel,
        CalloutSelectionModel,
        FreeTextSelectionModel,
        LineSelectionModel,
        PathSelectionModel,
        PolygonSelectionModel,
        RedactionSelectionModel,
        TextSelectionModel,
      ]) {
        SelectionModel.setCustomHandlers(selection, {
          drawSelectionOutline(ctx, annotation) {
            const x = annotation.X;
            const y = annotation.Y;
            const w = annotation.Width;
            const h = annotation.Height;
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgba(57, 255, 20, 0.5)";
            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x + w, y);
            ctx.lineTo(x + w, y + h);
            ctx.lineTo(x, y + h);
            ctx.closePath();
            ctx.stroke();
          },
          testSelection(
            annotation,
            x,
            y,
            pageMatrix,
            zoom,
            rotation,
            {originalTestSelection}
          ) {
            // return true;
            if (annotation instanceof Annotations.PolygonAnnotation) {
              return Annotations.SelectionAlgorithm.insidePolygonTest(
                annotation,
                x,
                y,
                pageMatrix,
                zoom
              );
            }
            return originalTestSelection(
              annotation,
              x,
              y,
              pageMatrix,
              zoom,
              rotation
            );
          },
        });
      }
      pdfEditorRef.current = new PdfEditor({
        webViewer: wv,
        viewerEl: viewerRef.current,
        caplaEditor,
      });
      caplaEditor?.setEditorPdf(pdfEditorRef.current);
      console.log("a new editorPdf is created");
    }
  }

  // helpers

  let offsetX, offsetY;
  const bounds = viewerRef.current?.getBoundingClientRect();
  if (bounds) {
    offsetX = bounds.left;
    offsetY = bounds.top;
  }

  // handlers

  function handleClick(e) {
    console.log("click", e.clientX, e.clientY);
  }

  // effects

  // useEffect(() => {
  //   if (editor?.isLoaded) editor.setPdfEditor(pdfEditorRef.current); // init();
  // }, [editor?.isLoaded]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (urlToUse && model?.url && pdfEditorRef.current && isPDF) {
      pdfEditorRef.current.load(model, urlToUse);
      dispatch(setShowPdfSelector(false));
    }
  }, [urlToUse, model?.url, pdfEditorRef.current]);

  useEffect(() => {
    if (language && pdfEditorRef.current) {
      pdfEditorRef.current.setLanguage(language);
    }
  }, [language, pdfEditorRef.current]);

  useEffect(() => {
    dispatch(setPdftronOffset({x: offsetX, y: offsetY}));
  }, [offsetX, offsetY]);

  // CASE 1 VIEWER effect - create measurements annotations

  useEffect(() => {
    if (
      viewer &&
      mnger &&
      model?.id &&
      !pdfEditorIsLoadingDoc &&
      os.fixedViewersBox &&
      model?.id === pdfModelIdLoaded &&
      !["ZONES", "DATA"].includes(sceneModule)
    ) {
      if (jump && pdfPageNumber !== jump.page) {
        const {documentViewer} = pdfEditorRef.current.webViewer.Core;
        documentViewer.setCurrentPage(jump.page);
        return;
      }
      let measurements = fm.filter(
        (m) =>
          m?.pdfModelId === model?.id &&
          m.drawingProps?.pageNumber === pdfPageNumber
      );
      // remove duplicates
      const mIds = [...new Set(measurements.map((m) => m.id))];
      measurements = mIds.map((id) => measurements.find((m) => m.id === id));
      console.log(
        "EFFECT301 - CREATE",
        measurements.length,
        "ANNOTATION from",
        pdfPageNumber
      );
      mnger.createAnnotationsFromMeasurements(measurements);
      console.log("EFFECT301 - end", pdfPageNumber);
    }
  }, [
    mnger,
    fmIdsHash,
    //fmIdsHashInViewer,
    model?.id,
    // model?.version,
    pdfEditorIsLoadingDoc, // we do not create annotations before loading the doc.
    sceneModule,
    os.fixedViewersBox,
    pdfModelIdLoaded,
    pdfPageNumber,
    jump,
  ]);

  // CASE 2 EDITOR effect - create measurements annotations

  useEffect(() => {
    if (
      !viewer &&
      mnger &&
      model?.id &&
      !pdfEditorIsLoadingDoc &&
      os.fixedViewersBox &&
      model?.id === pdfModelIdLoaded &&
      !["ZONES", "DATA"].includes(sceneModule)
    ) {
      if (jump && pdfPageNumber !== jump.page) {
        const {documentViewer} = pdfEditorRef.current.webViewer.Core;
        documentViewer.setCurrentPage(jump.page);
        return;
      }
      mnger.createAnnotationsFromMeasurements(measurementsInPage);
      console.log("EFFECT301 - end", pdfPageNumber);
    }
  }, [
    mnger,
    measurementsInPage?.length,
    model?.id,
    pdfEditorIsLoadingDoc, // we do not create annotations before loading the doc.
    sceneModule,
    os.fixedViewersBox,
    pdfModelIdLoaded,
    pdfPageNumber,
    jump,
  ]);

  //case 1 - VIEWER -  effect - display annotations - update in Edit Mode

  useEffect(() => {
    if (mnger && os.fixedViewersBox && viewer) {
      console.log("PDF apply filter");
      pdfEditorRef.current.updateSelection = false;
      let idsToShow = [...fmIdsInViewer];
      if (hideSavedMeasurements) {
        const modified = new Set(modifiedMeasurements);
        idsToShow = fmIdsInViewer.filter((i) => modified.has(i));
      }
      mnger.applyFilterByMeasurementIds({
        measurementIds: idsToShow,
        pageNumber: pdfPageNumber,
      });
      // apply selection if needed
      const selected = new Set(selectedMeasurementIds);
      const {annotationManager} = pdfEditorRef.current.webViewer.Core;
      const annots = annotationManager
        .getAnnotationsList()
        .filter((a) => selected.has(a.Id));
      if (annots.length > 0) {
        annotationManager.selectAnnotations(annots);
        if (jump) {
          annotationManager.jumpToAnnotation(annots[0], {fitToView: true});
        }
      }
      if (!jump) pdfEditorRef.current.updateSelection = true;
      else dispatch(setJump(null));
    }
  }, [
    mnger,
    fmIdsHashInViewer,
    os.fixedViewersBox,
    filterHashInViewer,
    pdfModelIdLoaded,
    pdfPageNumber,
    jump,
    hideSavedMeasurements,
    modifiedMeasurements,
  ]);

  //case 2 - EDITOR -  effect - display annotations - update in Edit Mode

  useEffect(() => {
    if (mnger && !viewer && os.fixedViewersBox && viewer) {
      pdfEditorRef.current.updateSelection = false;
      let idsToShow = [...measurementsInPage];
      if (hideSavedMeasurements) {
        const modified = new Set(modifiedMeasurements);
        idsToShow = idsToShow.filter((i) => modified.has(i));
      }
      mnger.applyFilterByMeasurementIds({
        measurementIds: idsToShow,
        pageNumber: pdfPageNumber,
      });
      // apply selection if needed
      const selected = new Set(selectedMeasurementIds);
      const {annotationManager} = pdfEditorRef.current.webViewer.Core;
      const annots = annotationManager
        .getAnnotationsList()
        .filter((a) => selected.has(a.Id));
      if (annots.length > 0) {
        annotationManager.selectAnnotations(annots);
        if (jump) {
          annotationManager.jumpToAnnotation(annots[0], {fitToView: true});
        }
      }
      if (!jump) pdfEditorRef.current.updateSelection = true;
      else dispatch(setJump(null));
    }
  }, [
    mnger,
    //measurementsInPage.length,
    os.fixedViewersBox,
    //filterHashInViewer,
    pdfModelIdLoaded,
    pdfPageNumber,
    jump,
    hideSavedMeasurements,
    modifiedMeasurements,
  ]);

  // effect - zones annotation

  useEffect(() => {
    if (pdfEditorRef.current && os.fixedViewersBox) {
      if (sceneModule === "ZONES" || sceneModule === "EDIT_MEASUREMENTS") {
        pdfEditorRef.current.sceneEditor.showZoneAnnotations();
      } else {
        pdfEditorRef.current.sceneEditor.hideZoneAnnotations();
      }
    }
  }, [pdfEditorRef.current, sceneModule, os.fixedViewersBox, model?.id]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        position: "relative",
        borderLeft: (theme) => `2px solid ${theme.palette.primary.main}`,
      }}
      onClick={handleClick}
    >
      <Layers caplaEditor={caplaEditor} scene={scene} viewer={viewer} />
      <div ref={viewerRef} style={{height: "100%"}} />
    </Box>
  );
}
