import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createRoomsGroupService,
  fetchRoomsGroupsService,
  fetchRoomsGroupService,
  updateRoomsGroupService,
  deleteRoomsGroupService,
  createRoomService,
  updateRoomService,
  deleteRoomService,
} from "./services";

export const createRoomsGroup = createAsyncThunk(
  "rooms/createRoomsGroup",
  createRoomsGroupService
);
export const createRoom = createAsyncThunk(
  "rooms/createRoom",
  createRoomService
);
//
export const fetchRoomsGroups = createAsyncThunk(
  "rooms/fetchRoomsGroups",
  fetchRoomsGroupsService
);
export const fetchRoomsGroup = createAsyncThunk(
  "rooms/fetchRoomsGroup",
  fetchRoomsGroupService
);
//
export const updateRoomsGroup = createAsyncThunk(
  "rooms/updateRoomsGroup",
  updateRoomsGroupService
);
export const updateRoom = createAsyncThunk(
  "rooms/updateRoom",
  updateRoomService
);
//
export const deleteRoomsGroup = createAsyncThunk(
  "rooms/deleteRoomsGroup",
  deleteRoomsGroupService
);
export const deleteRoom = createAsyncThunk(
  "rooms/deleteRoom",
  deleteRoomService
);

export const roomsSlice = createSlice({
  name: "rooms",
  initialState: {
    items: [],
    roomsGroups: [],
    selectedRoomId: null,
    selectedRoomIds: [],
    selectedRoomsGroupId: null,
    isEditingRoom: false,
    editedRoom: {},
    tempRoom: {},
  },
  reducers: {
    setSelectedRoomId: (state, action) => {
      state.selectedRoomId = action.payload;
    },
    setSelectedRoomIds: (state, action) => {
      state.selectedRoomIds = action.payload;
    },
    setSelectedRoomsGroupId: (state, action) => {
      state.selectedRoomsGroupId = action.payload;
    },
    setIsEditingRoom: (state, action) => {
      state.isEditingRoom = action.payload;
    },
    setEditedRoom: (state, action) => {
      state.editedRoom = action.payload;
    },
    setTempRoom: (state, action) => {
      state.tempRoom = action.payload;
    },
  },
  extraReducers: {
    [createRoomsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      const newRoomsGroup = {...item};
      newRoomsGroup.sceneId = item.scene;
      delete newRoomsGroup.scene;
      state.roomsGroups.push(newRoomsGroup);
    },
    [createRoom.fulfilled]: (state, action) => {
      const {item} = action.payload;
      const newRoom = {...item};
      newRoom.roomsGroupId = item.roomsGroup;
      delete newRoom.roomsGroup;
      state.items.push(newRoom);
    },
    [fetchRoomsGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      const newRoomsGroups = items.map((item) => {
        const newRoomsGroup = {...item};
        newRoomsGroup.sceneId = item.scene;
        delete newRoomsGroup.scene;
        return newRoomsGroup;
      });
      state.roomsGroups = newRoomsGroups;
    },
    [updateRoomsGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      const newRoomsGroup = {...item};
      newRoomsGroup.sceneId = item.scene;
      delete newRoomsGroup.scene;
      state.roomsGroups = state.roomsGroup.map((g) => {
        if (g.id === item.id) {
          return newRoomsGroup;
        } else {
          return g;
        }
      });
    },
    [deleteRoomsGroup.fulfilled]: (state, action) => {
      const {roomsGroupId} = action.payload;

      if (state.selectedRoomsGroupId === roomsGroupId) {
        state.selectedRoomsGroupId = null;
      }
      state.roomsGroups = state.roomsGroups.filter(
        (r) => r.roomsGroupId !== roomsGroupId
      );
    },
  },
});

export const {
  setSelectedRoomId,
  setSelectedRoomIds,
  setSelectedRoomsGroupId,
  setIsEditingRoom,
  setEditedRoom,
  setTempRoom,
} = roomsSlice.actions;

export default roomsSlice.reducer;
