// updates = updates of types only.
// use types & elementTypes articleQties props of types.

import ChipDefaultLabel from "Features/scopes/components/ChipDefaultLabel";
import getArticleChildren from "./getArticleChildren";
import updateArticleTypesWithElementTypesChildren from "./updateArticleTypesWithElementTypesChildren";

import mergeTwoArticleTypes from "./mergeTwoArticleTypes";
import cleanArticleTypes from "./cleanArticleTypes";
import getArticleTypesCount from "./getArticleTypesCount";
import cleanArticleTypesBasic from "./cleanArticleTypesBasic";
import getElementTypesByGroupIdById from "Features/elementTypes/utils/getElementTypesByGroupIdById";
import transferElementTypeArticleQtiesToChildren from "./transferElementTypeArticleQtiesToChildren";
import testArticleTypesHasGlobalOrGroupScope from "./testArticleTypesHasGlobalOrGroupScope";
import removeInheritedTypesFromArticle from "./removeInheritedTypesFromArticle";
import updateArticleWithArticleTypesNum from "./updateArticleWithArticleTypesNum";

import articleTypesMapToArray from "./articleTypesMapToArray";
import getArticlesForDebug from "./getArticlesForDebug";

// elementTypesGroups = [{id,code,elementTypes:[{id,code}]}]

export default function getArticlesUpdatesFromElementTypesGroups(
  articles,
  groupsWithSortedElementTypes
) {
  let updatesBatch = [];

  // STEP 0 : keep only articles with tempNum (= the one with a rank)

  articles = articles.filter((article) => article.tempNum); // we remove all other articles.

  // STEP 1 : transfer articleQties for elementTypes children

  groupsWithSortedElementTypes = groupsWithSortedElementTypes.map((group) => {
    return {
      ...group,
      elementTypes: transferElementTypeArticleQtiesToChildren(
        group.elementTypes
      ),
    };
  });

  console.log("STEP1-11", articles, groupsWithSortedElementTypes);

  // STEP 2 - data transfo

  const elementTypesByGroupIdById = getElementTypesByGroupIdById(
    groupsWithSortedElementTypes
  );

  // DATA TRANSFO = ADD NUM TO types (for debug)

  articles = articles.map((article) => {
    return updateArticleWithArticleTypesNum(article, elementTypesByGroupIdById);
  });

  // STEP 3 : for each article,inherit parents types from coreTypes

  let enhancedArticlesMap = {};

  articles.forEach((article, index) => {
    const typesToTransfer = article.coreTypes;
    const shouldTransfer =
      getArticleTypesCount(article.types) > 0 ||
      testArticleTypesHasGlobalOrGroupScope(article.types);

    // we update enhancedArticlesMap with the article if it doesn't exist yet.
    if (!enhancedArticlesMap[article.id]) {
      enhancedArticlesMap[article.id] = {...article};
    }
    if (shouldTransfer) {
      const children = getArticleChildren(index, articles);
      children.forEach((child) => {
        //
        //if (child.isSubArticle) return; useless
        //
        let relevantArticle = enhancedArticlesMap[child.id]; // used to keep other transfered types.
        if (!relevantArticle) relevantArticle = child;
        //
        const inheritedCount = relevantArticle.inheritedCount || 0;
        //
        const mergeOptions = {
          transferGlobalFiltersStr: true,
          transferParentsFiltersStr: true,
          removeParentItems: true,
        };
        const childNewTypes = mergeTwoArticleTypes(
          typesToTransfer,
          relevantArticle.types,
          mergeOptions
        );

        console.log(
          "childNewTypes",
          articleTypesMapToArray(typesToTransfer, {debugFormat: true}),
          articleTypesMapToArray(relevantArticle.types, {debugFormat: true}),
          articleTypesMapToArray(childNewTypes, {debugFormat: true})
        );

        // we merge existing type on top of the transferred types.
        enhancedArticlesMap[child.id] = {
          ...child,
          types: childNewTypes,
          inheritedCount: inheritedCount + 1,
        };
      });
    }
  });

  console.log(
    "enhancedArticlesMap",
    Object.values(enhancedArticlesMap).map((a) => ({
      num: a.tempNum,
      name: a.name,
      types: a.types,
    }))
  );

  // DATA MAP=>ARRAY

  let enhancedArticles = Object.values(enhancedArticlesMap);

  // STEP 2 : for each article, duplicate types for elementTypes children & update func

  enhancedArticles.forEach((article) => {
    const [updatedTypes, isUpdated] =
      updateArticleTypesWithElementTypesChildren(
        article,
        groupsWithSortedElementTypes
      );

    console.log(
      "updatedTypes v1",
      article.name,
      updatedTypes,
      article.inheritedCount,
      isUpdated
    );

    if (
      isUpdated ||
      article?.inheritedCount ||
      article.coreTypes?.["globalFunc"] === "TOTAL"
    ) {
      let removeGlobalFiltersStr = true;
      if (
        article.types?.["globalFunc"] === "TOTAL" ||
        article.coreTypes?.["globalFunc"] === "TOTAL"
      ) {
        removeGlobalFiltersStr = false;
      }
      const cleanTypes = cleanArticleTypesBasic(
        updatedTypes,
        elementTypesByGroupIdById,
        {removeTitles: true, removeGlobalFiltersStr}
      );
      console.log("updatedTypes v2", article.name, cleanTypes);
      updatesBatch.push({id: article.id, types: cleanTypes});
    }
  });

  return updatesBatch;
}
