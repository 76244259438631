import {useState} from "react";

import {IconButton, TextField, Box, Paper} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function ContainerAddBuilding({onAdd}) {
  const placeholder = "Bât, cage, ilôt,...";

  const [name, setName] = useState("");

  return (
    <Paper
      sx={{p: 1, bgcolor: "grey.800", display: "flex", alignItems: "center"}}
    >
      <TextField
        size="small"
        InputProps={{
          sx: {
            color: "common.white",
            fontSize: (theme) => theme.typography.body2.fontSize,
          },
        }}
        value={name}
        placeholder={placeholder}
        onChange={(e) => setName(e.target.value)}
      />

      <Box
        sx={{
          bgcolor: "primary.flash",
          borderRadius: "50%",
          color: "common.white",
          ml: 1,
        }}
      >
        <IconButton size="small" color="inherit" onClick={() => onAdd(name)}>
          <Add fontSize="small" />
        </IconButton>
      </Box>
    </Paper>
  );
}
