// import {useSelector, useDispatch} from "react-redux";

import {Box, Grid, Typography} from "@mui/material";

import useMeasurementsByRessourceBySectorMap from "Features/measurements/useMeasurementsByRessourceBySectorMap";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
// import {setExpandLeftSelectionQtiesBySector} from "Features/leftPanel/leftPanelSlice";

export default function SectionRessourceQuantitiesBySector({scene, ressource}) {
  // const dispatch = useDispatch();

  // data

  const map = useMeasurementsByRessourceBySectorMap(scene);
  const sectors = useSectorsByScene(scene);
  // const expand = useSelector(
  //   (s) => s.leftPanel.expandLeftSelectionQtiesBySector
  // );

  // helper

  const ressourcesBySectorMap = map[ressource?.id];

  let resBySector;
  if (!ressourcesBySectorMap) {
    resBySector = [];
  } else {
    resBySector = Object.entries(ressourcesBySectorMap).map(
      ([sectorId, quantity]) => {
        const sector = sectors.find((s) => s.id === sectorId);
        let name = sector?.name;
        if (!name) name = "-?-";

        let z = sector?.z;
        if (!z && z !== 0) {
          z = 9999;
        }
        return {sectorId, name, z, quantity, building: sector?.building};
      }
    );
  }

  // helper - display

  const display = resBySector.length >= 1 ? "block" : "none";

  // handler

  // function handleClick() {
  //   dispatch(setExpandLeftSelectionQtiesBySector(!expand));
  // }
  return (
    <Box sx={{display, color: "text.secondary"}}>
      <Box
        sx={{
          width: 1,
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        {resBySector
          .sort((a, b) =>
            a.building
              ? a.building.localeCompare(b.building) || a.z - b.z
              : a.z - b.z
          )
          .map(({sectorId, name, quantity}) => {
            if (typeof quantity === "number")
              return (
                <Grid
                  container
                  sx={{
                    width: 1,
                    px: 1,
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  }}
                  key={sectorId}
                >
                  <Grid
                    item
                    xs={6}
                    sx={{
                      borderRight: (theme) =>
                        `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Typography variant="body2">{name}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{display: "flex", justifyContent: "end"}}
                  >
                    <Typography variant="body2">
                      {quantity ? quantity.toFixed(2) : 0}
                    </Typography>
                  </Grid>
                </Grid>
              );
          })}
      </Box>
    </Box>
  );
}
