import {useSelector} from "react-redux";

export default function useSelectedElementTypesGroupInMeasurements() {
  //
  const selectedGroupName = useSelector(
    (s) => s.measurements.selectedElementTypeGroup
  );

  const groups = useSelector((s) => s.elementTypes.elementTypesGroups);

  return groups.find((g) => g.name === selectedGroupName);
}
