import argbToHex from "./argbToHex";
import theme from "Styles/theme";

import getStyleAndPropsFromCell from "./getStyleFromCell";

export default function getItemPropsFromNameCellStyle(cell) {
  //
  const bgTitle1 = theme.palette.excel.bgTitle1.toLowerCase();
  const bgTitle2 = theme.palette.excel.bgTitle2.toLowerCase();
  const colorIsAdded = theme.palette.excel.colorIsAdded.toLowerCase();
  //
  const {style} = getStyleAndPropsFromCell(cell);
  //
  let itemStyle = "normal";
  if (style.bg === bgTitle1) {
    itemStyle = "title1";
  } else if (style.bg === bgTitle2) {
    itemStyle = "title2";
  } else if (style.bold && !style.alignToRight && style.underline) {
    itemStyle = "overview";
  } else if (style.bold && !style.alignToRight && !style.underline) {
    itemStyle = "element";
  } else if (style.alignToRight && !style.italic) {
    itemStyle = "detail";
  } else if (style.alignToRight && style.italic) {
    itemStyle = "subDetail";
  } else if (style.underline && !style.bold) {
    itemStyle = "section";
  }
  //
  let itemIsAdded = false;
  if (style.color === colorIsAdded) {
    itemIsAdded = true;
  }
  //
  let itemIsNomenclature = false;
  if (style.italic && style.underline && style.alignToRight) {
    itemIsNomenclature = true;
    itemStyle = "detail";
  }

  return {
    style: itemStyle,
    isAdded: itemIsAdded,
    isNomenclature: itemIsNomenclature,
  };
}
