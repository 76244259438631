import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {IconButton} from "@mui/material";
import {Upload} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {uploadFileService} from "Features/files/services";

import {postPicture} from "Features/pictures/picturesSlice";

export default function IconButtonUploadPictures({tempPictures, listingId}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // data

  const pictures = useSelector((s) => s.pictures.items);
  const savedPicturesClientId = pictures
    .filter((p) => p.id)
    .map((p) => p.clientId);

  const picturesToUpload = tempPictures.filter(
    (p) => !savedPicturesClientId.includes(p.clientId)
  );

  console.log("pictures", picturesToUpload);

  // handlers

  async function handleUploadClick() {
    for (let pix of picturesToUpload) {
      const imageUrl = await uploadFileService({
        file: pix.file,
        listingId,
        accessToken,
        container: "listing-files",
      });
      const picture = {
        ...pix,
        imageUrl,
      };
      await dispatch(postPicture({listingId, accessToken, picture}));
    }
  }

  return (
    <IconButton size="small" onClick={handleUploadClick}>
      <Upload fontSize="small" />
    </IconButton>
  );
}
