import {useState} from "react";

import {Box, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import SelectorMeasurement from "./SelectorMeasurement";

export default function ListItemAddVoid({voids, onAdd, types}) {
  // states

  const [itemVoid, setItemVoid] = useState(null);

  // handlers

  function handleVoidChange(t) {
    setItemVoid(t);
  }

  function handleAdd() {
    if (onAdd) onAdd(itemVoid.id);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", pl: 1, pt: 1}}>
      <SelectorMeasurement
        measurement={itemVoid}
        measurements={voids}
        onChange={handleVoidChange}
        types={types}
      />
      <IconButton
        size="small"
        onClick={handleAdd}
        sx={{mx: 0.5, heigh: 24}}
        disabled={!Boolean(itemVoid)}
      >
        <Add fontSize="small" />
      </IconButton>
    </Box>
  );
}
