import {useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";
import {setNewElementType, updateElementTypesGroup} from "../elementTypesSlice";
import useAddSceneElementType from "../hooks/useAddSceneElementType";

import {Box, Button, alpha} from "@mui/material";
import {Add} from "@mui/icons-material";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

import useAccessToken from "Features/auth/useAccessToken";
import addElementTypeToElementTypesGroup from "../utils/addElementTypeToElementTypesGroup";

export default function ActionsNewElementTypeInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const createS = "Create";

  // data

  const newElementType = useSelector((s) => s.elementTypes.newElementType);
  const addSceneElementType = useAddSceneElementType(scene);
  const selectedElementTypesGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const selectedElementTypesGroup = useSelectedElementTypesGroup(scene);

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const saveTypeInScene =
    selectedElementTypesGroupProxy?.fromScene ||
    !selectedElementTypesGroupProxy;

  // handlers

  async function handleCreateClick() {
    const elementType = {
      ...newElementType,
      id: nanoid(),
    };
    if (saveTypeInScene) {
      addSceneElementType(elementType);
    } else {
      setLoading(true);
      const updatedGroup = addElementTypeToElementTypesGroup(
        elementType,
        selectedElementTypesGroup
      );

      await dispatch(
        updateElementTypesGroup({
          accessToken,
          elementTypesGroup: updatedGroup,
        })
      );
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
          disabled={loading}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
