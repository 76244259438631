import { useSelector } from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import DataGridRooms from "./DataGridRooms";
import ToolbarRoomsMain from "./ToolbarRoomMain";
import SectionTitleRooms from "./SectionTitleRooms";

import Stretch from "Features/ui/components/Stretch";

export default function PageRooms() {
  // params

  const {caplaEditor, scene} = useOutletContext();
  const os = useSelector((s) => s.ui.openSections);

  if (os.outlet)
    return (
      <Box sx={{width: 1}}>
        <Box sx={{height: "60px"}} />
        <SectionTitleRooms />
        <ToolbarRoomsMain scene={scene} />
        <Stretch>
          <DataGridRooms scene={scene} editor3d={caplaEditor?.editor3d} />
        </Stretch>
      </Box>
    );
  return (<></>);
}
