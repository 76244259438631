import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setBeta} from "Features/viewer3D/viewer3DSlice";
import {FormControlLabel, Switch, Typography} from "@mui/material";

export default function SwitchBeta() {
  const dispatch = useDispatch();
  const labelString = "Active beta";

  const checked = useSelector((s) => s.viewer3D.beta);
  function handleChange(e) {
    dispatch(setBeta(e.target.checked));
  }
  return (
    <FormControlLabel
      control={
        <Switch size="small" checked={checked} onChange={handleChange} />
      }
      label={<Typography variant="body2">{labelString}</Typography>}
    />
  );
}
