import useElementTypesBySceneProxy from "./useElementTypesBySceneProxy";

export default function useElementTypeGroupsByScene(scene) {
  const elementTypes = useElementTypesBySceneProxy(scene);

  const groupsFromElementTypes = [
    ...new Set(elementTypes.map((r) => r.group)),
  ].filter((g) => g);

  const elementTypesWithNoGroup =
    elementTypes.filter((r) => !r.group).length > 0;

  const groups = [...new Set([...groupsFromElementTypes])];

  if (elementTypesWithNoGroup) groups.push(null);

  return groups;
}
