import {useSelector} from "react-redux";
import {useEffect} from "react";
import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";

export default function useMeasurementsModelsInScope(scene) {
  // data

  const showHelperModelIds = useSelector((s) => s.helpers3d.showHelperModelIds);
  const selectedVersion = useSelectedVersionForMeasurements();
  let measurementModels = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.type === "MEASUREMENTS" && m.sceneClientId === scene?.clientId
  );

  if (selectedVersion) {
    measurementModels = measurementModels.filter(
      (m) =>
        !m.revisionIds ||
        (m.revisionIds && m.revisionIds?.includes(selectedVersion?.id))
    );
  }

  const scopeInputId = useSelector((s) => s.measurements.scopeInputId);

  let ids = useSelector((s) => s.measurements.measurementsModelsInScope); // /!\ ids to check. Where does it come from ?

  // helpers

  let measurementModelsIds = measurementModels.map((m) => m.id);
  if (ids.length > 0) {
    const idsWithHelpers = [...ids, ...showHelperModelIds];
    measurementModels = measurementModels.filter((m) =>
      idsWithHelpers?.includes(m.id)
    );
    measurementModelsIds = ids;
  }

  // helpers

  if (scopeInputId) {
    measurementModels = measurementModels.filter(
      (m) => m.fromModel?.modelId === scopeInputId
    );
    measurementModelsIds = measurementModels.map((m) => m.id);
  }

  const idsHash = measurementModelsIds.join("-");
  return [measurementModels, measurementModelsIds, idsHash]; // /!\ ids to check ...
}
