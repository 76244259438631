import React, {useRef, useEffect, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";
import {setMode, addMarker} from "Features/viewer3D/viewer3DSlice";

import {IconButton, Box, Typography, Tooltip} from "@mui/material";
import {Room as Marker} from "@mui/icons-material";

import ActionPopper from "Features/viewer3D/components/ActionPopper";
import ActionNameMarker from "Features/viewer3D/components/ActionNameMarker";

export default function ButtonCreateMarkerOnAzm({editor, type}) {
  const dispatch = useDispatch();

  // strings

  const createString = "Create a marker";

  // data

  const azmEditorIsLoaded = useSelector((s) => s.viewer3D.azmEditorIsLoaded);

  const mode = useSelector((s) => s.viewer3D.mode);

  const markers = useSelector((state) => state.viewer3D.markers);
  const lastMarker = [...markers].sort((a, b) => a - b).pop();
  const number = lastMarker ? lastMarker.number + 1 : 1;

  // virtual element for popper

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef();

  // state

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0, z: 0});

  // helper

  const adding = mode === "ADDING_PDF_MARKER";

  // handlers

  function handleCreateClick() {
    dispatch(setMode("ADDING_PDF_MARKER"));
    editor.mapEditor.azmEditor.enablePointer(); // to get cross hair pointer...
    editor.mapEditor.azmEditor.setClickHandler(handleMapEditorClick);
    setActive(true);
  }

  // handlers - click pdf listener

  const handleMapEditorClick = (props) => {
    if (active) {
      setOpen(true);
      const {screenP, latLng} = props;
      virtualElementRef.current.getBoundingClientRect = generateBBCR(
        screenP.x,
        screenP.y
      );
      const position = editor.mapEditor.azmEditor.latLngToCoord(latLng);
      setPosition(position);
    }
  };

  useEffect(() => {
    if (azmEditorIsLoaded) {
      editor.mapEditor.azmEditor.setClickHandler(handleMapEditorClick);
    }
  }, [active, azmEditorIsLoaded]);

  // handlers - marker name

  function handleMarkerName(name) {
    const marker = {
      id: nanoid(),
      description: name,
      x: position.x,
      y: position.y,
      z: position.z,
      number,
      type: "REFERENCE",
    };

    editor.addMarker(marker);
    dispatch(addMarker(marker));

    // close tool
    dispatch(setMode("DEFAULT"));
    editor.mapEditor.azmEditor.disablePointer();
    setActive(false);
    setOpen(false);
  }

  // effects

  useEffect(() => {
    virtualElementRef.current = {getBoundingClientRect: generateBBCR};
  }, []);

  useEffect(() => {
    if (mode === "DEFAULT" && azmEditorIsLoaded) {
      editor.mapEditor.azmEditor.disablePointer();
      setOpen(false);
      setActive(false);
    }
  }, [mode, azmEditorIsLoaded]);

  return (
    <Box>
      {!adding && (
        <Tooltip title={createString}>
          <IconButton
            size="small"
            onClick={handleCreateClick}
            sx={{borderRadius: "4px"}}
          >
            <Marker />
          </IconButton>
        </Tooltip>
      )}

      <Box sx={{zIndex: 100}}>
        {virtualElementRef?.current && (
          <ActionPopper
            anchorEl={virtualElementRef.current}
            open={open}
            disablePortal={false}
          >
            <ActionNameMarker onAdd={handleMarkerName} />
          </ActionPopper>
        )}
      </Box>
    </Box>
  );
}
