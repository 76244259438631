import getArraysIntersection from "Utils/getArraysIntersection";

export default function getGlobalFilterIdsByEntity({
  idsFromShortcutByEntity,
  idsFromSelectionByEntity,
}) {
  const entities = [
    "elementType",
    "elementTypeGroup",
    "ressource",
    "ressourceGroup",
    "sector",
    "article",
    "articlesGroup",
  ];

  const map = {};

  entities.forEach((entity) => {
    const idsFromShortcut = idsFromShortcutByEntity
      ? idsFromShortcutByEntity[entity]
      : null;
    const idsFromSelection = idsFromSelectionByEntity
      ? idsFromSelectionByEntity[entity]
      : null;
    if (idsFromShortcut || idsFromSelection) {
      map[entity] = getArraysIntersection([idsFromShortcut, idsFromSelection]);
    }
  });

  return map;
}
