import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";
import useSectorsByScene from "./useSectorsByScene";
import getItemsMapById from "Utils/getItemsMapById";

export default function useGetSectorsFromMeasurements(scene) {
  const measurements = useFilteredMeasurementsFromPackages({scene});

  const sectors = useSectorsByScene(scene);
  const sectorsMap = getItemsMapById(sectors);

  const sectorsIdsCountMap = {};

  const getSectors = () => {
    measurements.forEach((measurement) => {
      if (measurement.sectorId) {
        if (sectorsIdsCountMap[measurement.sectorId]) {
          sectorsIdsCountMap[measurement.sectorId] += 1;
        } else {
          sectorsIdsCountMap[measurement.sectorId] = 1;
        }
      }
    });

    const sectorsIds = Object.keys(sectorsIdsCountMap);

    // new

    const newSectorsMap = {...sectorsMap};

    sectorsIds.forEach((sectorId) => {
      let index = 1;
      const sector = sectorsMap[sectorId];
      if (!sector) {
        newSectorsMap[sectorId] = {
          id: sectorId,
          name: `Secteur ${index} (x${sectorsIdsCountMap[sectorId]})`,
          building: "00 = A définir",
          code: `S${index}`,
          z: 0,
        };
        index += 1;
      }
    });

    return Object.values(newSectorsMap);
  };

  return getSectors;
}
