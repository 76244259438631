import listStyleMap from "./listStyleMap";

export default function getListItemStyle(item) {
  let styleType = "DEFAULT";
  if (item.style === "title1") styleType = "TITLE1";
  if (item.style === "title2") styleType = "TITLE2";
  if (item.style === "detail") styleType = "DETAIL";
  const {fontWeight, bgcolor, color} = listStyleMap[styleType];
  return {fontWeight, bgcolor, color};
}
