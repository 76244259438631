import {useDispatch, useSelector} from "react-redux";

import {Button, Box} from "@mui/material";
import {Close} from "@mui/icons-material";

import {
  setSelectedMeasurementIds,
  // setSelectedMeasurementId,
  setSelectedTypesInDatagrid,
  setSelectedRessourcesInDatagrid,
  setSelectedRoomsInDatagrid,
} from "../measurementsSlice";

import {setSelectedIssueId} from "Features/ui/uiSlice";
import {setSelectedRessourceIdInHome} from "Features/boxhome/boxhomeSlice";
import {setSelectedRessourceId} from "Features/ressources/ressourcesSlice";

export default function ButtonDataGridClearSelection({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const clearS = "sélection";

  // data

  // const datagridMode = useSelector((s) => s.measurements.dataGridMode);

  // const id = useSelector((s) => s.measurements.selectedMeasurementId);
  const ids = useSelector((s) => s.measurements.selectedMeasurementIds);
  const types = useSelector((s) => s.measurements.selectedTypesInDatagrid);
  const ressources = useSelector(
    (s) => s.measurements.selectedRessourcesInDatagrid
  );

  // helpers

  let isEmpty =
    // !Boolean(id) &&
    ids.length === 0 &&
    types.length === 0 &&
    ressources.length === 0;

  // handlers

  function handleClear() {
    editor3d?.sceneEditor.unselect();
    dispatch(setSelectedMeasurementIds([]));
    dispatch(setSelectedRessourceIdInHome(null)); //delete?
    dispatch(setSelectedRessourceId(null)); //delete?
    dispatch(setSelectedTypesInDatagrid([]));
    dispatch(setSelectedRessourcesInDatagrid([]));
    dispatch(setSelectedRoomsInDatagrid([]));
    dispatch(setSelectedIssueId(null));
  }

  // helpers

  return (
    <Box
      sx={{color: "text.secondary", visibility: isEmpty ? "hidden" : "visible"}}
    >
      <Button
        startIcon={<Close />}
        onClick={handleClear}
        size="small"
        color="primary"
        variant="contained"
      >
        {clearS}
      </Button>
    </Box>
  );
}
