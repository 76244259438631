import React from "react";

import {Box, Paper, Typography} from "@mui/material";

export default function ListItemSector({sector, selected = false, onClick}) {
  // handlers

  function handleClick() {
    onClick(sector);
  }

  // helper

  const bgcolor = selected ? "common.caplaBlack" : "common.grey";
  const color = selected ? "common.white" : "text.secondary";

  // const bgcolor = selected ? "primary.flash" : "common.caplaBlack";
  // const color = "common.white";

  // helper

  const label = sector?.code ? sector.code : "- ? -";

  return (
    <Paper
      sx={{
        px: 1,
        py: 0.5,
        cursor: "pointer",
        width: "fit-content",
        bgcolor,
        color,
        maxWidth: 240,
        overflow: "hidden",
      }}
      elevation={selected ? 12 : 1}
      onClick={handleClick}
    >
      <Typography
        noWrap
        sx={{fontWeight: selected ? "bold" : "normal", fontSize: 13}}
      >
        {label}
      </Typography>
    </Paper>
  );
}
