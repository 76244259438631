import {useDispatch, useSelector} from "react-redux";

import {IconButton, Box} from "@mui/material";
import {Visibility as Show} from "@mui/icons-material";

import {
  setSelectedPdfModelId,
  setSelectedModelId,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import {updateMeasurementsShortcuts} from "Features/measurements/measurementsSlice";

export default function IconButtonShowIssue({caplaEditor, issueId, issues, scene}) {
  const dispatch = useDispatch();

  // data

  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.enabled
  );

  // handlers

  function handleClick() {
    const issue = issues.find((issue) => issue.id === issueId);
    console.log("issue", issue, issueId);
    const issuePdfModelId = issue?.context?.pdfContext?.modelId;
    if (issuePdfModelId) {
      if (issuePdfModelId === selectedPdfModelId) {
        caplaEditor?.editorPdf?.setPdfContext(issue.context.pdfContext);
        // measurements filters
        dispatch(
          updateMeasurementsShortcuts(
            issue.context?.pdfContext?.measurementsFilters
          )
        );
      } else {
        dispatch(setSelectedModelId(issuePdfModelId));
        dispatch(setSelectedPdfModelId(issuePdfModelId));
      }
    }
    if (issue.fromViewMode === "2D" || issue.fromViewMode === "3D") {
      caplaEditor?.editor3d?.imageOverlay.setImage(issue.imageUrl);
      caplaEditor?.editor3d?.imageOverlay.show();
      const camera = issue.context?.camera;
      if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);

      // measurements filters
      dispatch(
        updateMeasurementsShortcuts(
          issue.context?.sceneContext?.measurementsFilters
        )
      );

      // models
      const ids = issue.context?.sceneContext?.visibleModelsIds;
      if (ids) {
        models.forEach((model) => {
          let updatedModel = {...model, hidden: true};
          if (ids.includes(model?.id)) updatedModel.hidden = false;
          dispatch(
            updateModel({updatedModel, sync: false, updateModelInStore: false})
          );
        });
      }
    }
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      <IconButton size="small" onClick={handleClick}>
        <Show fontSize="small" />
      </IconButton>
    </Box>
  );
}
