import {client} from "API/capla360";
export async function fetchProfileService({accessToken}) {
  const response = await client.get(`/profile`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function updateProfileService({accessToken, updates}) {
  const response = await client.put(`/profile`, updates, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
