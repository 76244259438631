import React, {useState} from "react";

import {Box} from "@mui/material";

import CreateSceneButton from "./CreateSceneButton";
import CreateSceneDialog from "./CreateSceneDialog";
export default function ButtonDialogCreateScene() {
  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Box>
      <CreateSceneButton onClick={handleOpen} />
      <CreateSceneDialog open={open} onClose={handleClose} />
    </Box>
  );
}
