import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

// import {format} from "date-fns";

import {setSelectedMarker} from "../markersSlice";
import useMarkersByScene from "../hooks/useMarkersByScene";
import markersForDataGrid from "../utils/markersForDataGrid";
import useSelectedMarker from "../hooks/useSelectedMarker";

// import useTranslation from "Features/translations/useTranslation";

export default function DataGridMarkers({editor3d, scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  // const idS = "ID";
  const descriptionS = "Description";

  // data

  const allMarkers = useMarkersByScene(scene?.clientId);
  const selectedMarker = useSelectedMarker();
  const selectedMarkersModelId = useSelector(
    (s) => s.markers.selectedMarkersModelId
  );

  // helpers - markers

  const markers = allMarkers.filter(
    (m) => m.modelId === selectedMarkersModelId
  );

  console.log("markers", markers);
  // selection model

  const selectionModel = selectedMarker?.id ? [selectedMarker?.id] : [];

  // grid - rows

  const rows = markersForDataGrid(markers);

  // grid - columns

  const columns = [
    //{field: "id", headerName: idString, flex: 0.5},
    {
      field: "description",
      headerName: descriptionS,
      flex: 1,
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    const marker = markers.find((m) => m.id === selectionId);

    // state
    dispatch(
      setSelectedMarker({markersModelId: marker?.modelId, markerId: marker?.id})
    );

    // selection
    editor3d?.sceneEditor.selectMarker({
      markersModelId: marker?.modelId,
      markerId: marker?.id,
    });

    // camera
    editor3d?.sceneEditor.focusMarker(marker);
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
