import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import * as atlas from "azure-maps-control";
import * as atlasServices from "azure-maps-rest";

import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import InputSearch from "./InputSearch";
import LayerDefault from "./LayerDefault";

import AzureMapEditor from "../js/AzureMapEditor";

export default function AzureMap({
  editor,
  worksites,
  onClick,
  scene,
  mapEditorIsCreated,
  mapInitOptions,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  // ref

  const ref = useRef();
  const azmEditorRef = useRef();

  // data
  const page = searchParams.get("page");

  const azmEditorIsLoaded = useSelector((s) => s.viewer3D.azmEditorIsLoaded);
  const worksitesPage = useSelector((s) => s.viewer3D.worksitesPage);
  const rendererSize = useSelector((s) => s.viewer3D.rendererSize);
  //const viewMode = useSelector((s) => s.viewer3D.viewMode);

  // states

  const [search, setSearch] = useState("");

  // init

  function init() {
    if (mapEditorIsCreated) {
      const map = new atlas.Map("myMap", {
        center: [mapInitOptions.lng, mapInitOptions.lat],
        zoom: mapInitOptions.zoom,
        view: "Auto",
        //style: "satellite_road_labels",
        preserveDrawingBuffer: true, // to take screenshot
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: "hWXAA9U_bRppRQyBGxS0ZRA25PbL2MwzTlEzSauhYBw",
        },
      });

      editor.mapEditor.initAzmEditor({map});
    }
  }

  // handlers

  function handleSearchChange(search) {
    setSearch(search);
    searchService();
  }

  function searchService() {
    const map = azmEditorRef.current.map;
    const pipeline = atlasServices.MapsURL.newPipeline(
      new atlasServices.MapControlCredential(map)
    );
    const searchURL = new atlasServices.SearchURL(pipeline);

    const options = {
      lon: map.getCamera().center[0],
      lat: map.getCamera().center[1],
      maxFuzzyLevel: 4,
      view: "Auto",
    };
    searchURL
      .searchAddress(atlasServices.Aborter.timeout(10000), search)
      .then((results) => {
        const data = results.geojson.getFeatures();
        console.log("data", data);
      });
  }
  // effect

  useEffect(() => {
    init();
  }, [mapEditorIsCreated]);

  // resize

  useEffect(() => {
    if (azmEditorIsLoaded && editor) {
      editor.mapEditor.azmEditor.resizeMap();
    }
  }, [azmEditorIsLoaded, rendererSize?.height, page, editor]);

  // worksites data

  useEffect(() => {
    if (worksites?.length > 0 && azmEditorIsLoaded) {
      console.log("worksitesdata", worksites);
      editor.mapEditor.azmEditor.setWorksitesData(worksites);
    }
  }, [worksites?.length, azmEditorIsLoaded]);

  // resize

  useEffect(() => {
    if (azmEditorIsLoaded && editor) {
      editor.mapEditor.azmEditor.map.resize();
    }
  }, [azmEditorIsLoaded, editor]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        position: "relative",
      }}
    >
      {/* <Box
        sx={{
          position: "absolute",
          zIndex: 10,
          top: "8px",
          left: "8px",
          bgcolor: "common.white",
        }}
      >
        <InputSearch search={search} onSearchChange={handleSearchChange} />
      </Box> */}

      {/* {!worksitesPage && <LayerDefault editor={editor} />} */}

      <div id="myMap" ref={ref} style={{width: "100%", height: "100%"}} />
    </Box>
  );
}
