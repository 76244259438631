import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateProfile} from "Features/profile/profileSlice";

import {
  Box,
  Button,
  LoadingPosition,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useProfile from "../useProfile";

export default function ButtonDialogEditProfile() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const {data: profile} = useProfile();

  // strings

  const editString = "Edit";
  const title = "Your profile";
  const nameString = "Name";
  const cancelString = "Cancel";
  const saveString = "Save";

  // state

  const initName = profile.name;

  const [open, setOpen] = useState(false);
  const [name, setName] = useState(initName);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(initName);
  }, [initName]);

  // handlers

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleCancel() {
    setOpen(false);
    setLoading(false);
  }

  // handler - save

  async function handleSave() {
    if (name.length > 0) {
      setLoading(true);
      const updates = {...profile.data, name};
      await dispatch(updateProfile({accessToken, updates}));
      setLoading(false);
      setOpen(false);
    }
  }

  return (
    <Box>
      <Button startIcon={<Edit />} fontSize="small" onClick={handleOpen}>
        {editString}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{mt: 2}}>
            <TextField
              sx={{mt: 2}}
              label={<Typography variant="body2">{nameString}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{cancelString}</Button>
          <LoadingButton
            loading={loading}
            startIcon={<Save />}
            loadingPosition="start"
            onClick={handleSave}
          >
            {saveString}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
