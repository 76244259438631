import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Paper,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import { setBuildingInScope } from "../buildingsSlice";

import { setSectorsInScope } from "Features/measurements/measurementsSlice";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";

export default function SelectorBuildingInScope({scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    true,
    false,
    false,
    false,
    viewer,
  );
  const fmSectorIds = [...new Set(fms.map((m) => m.sectorId))];
  const sectors = useSectorsByScene(scene);
  
  let allBuildings = [];
  let noSectors = false;
  let noBuildings = false;
  fmSectorIds.map((id) => {
    if (id) {
      const sector = sectors.find((s) => s.id === id);
      if (sector?.building) allBuildings.push(sector.building);
      else noBuildings = true;
    } else noSectors = true;
  })
  allBuildings = [...new Set(allBuildings)].sort();

  const buildings = [{id: null, name: "Tous"}];
  allBuildings.map((b) => buildings.push({id: b, name: b}));
  if (noSectors || noBuildings) {
    buildings.push({id: undefined, name: "-?-"});
  } 

  const buildingInScope = useSelector((s) => s.buildings.buildingInScope);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
    
  // helper - label
  
  const label = buildings.find((b) => b.id === buildingInScope)?.name;

  // handlers

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(building) {
    dispatch(setBuildingInScope(building.id));
    if (building.id === null || buildings.filter((b) => b.id).length === 0) {
      dispatch(setSectorsInScope([]));
    } else if (building.id === undefined) {
      const sectorsInScope = sectors.filter((s) => !s.building);
      dispatch(setSectorsInScope([...sectorsInScope.map((s) => s.id), null]));
    } else {
      const sectorsInScope = sectors.filter((s) => s.building === building.id);
      dispatch(setSectorsInScope(sectorsInScope.map((s) => s.id)));
    }
    setAnchorEl(null);
  }

  return (
    <>
      <Paper
        elevation={12}
        onClick={handleMoreClick}
        size="small"
        sx={{
          color: "common.white",
          bgcolor: "common.caplaBlack",
          px: 1,
          pl: 2,
          display: "flex",
          alignItems: "center",
          borderRadius: "15px",
          height: "30px",
          cursor: "pointer",
        }}
      >
        <Typography sx={{fontSize: 13}}>{label}</Typography>
        <Down sx={{ml: 2}} color="inherit" size="small" />
      </Paper>
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {buildings.map((building) => {
          const selected = building.id === buildingInScope;
          return (
            <MenuItem
              key={building.name}
              onClick={() => handleChange(building)}
              selected={selected}
            >
              <Typography variant="body2">{building.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
