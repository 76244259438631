export default function getItemsWithNums(items) {
  let parent = 0;
  let child = 1;

  const itemsWithNums = [];

  items.forEach((item) => {
    let num;
    if (item.isTitle) {
      parent += 1;
      num = parent.toString();
      child = 1;
    } else {
      num = parent + "." + child;
      child += 1;
    }
    itemsWithNums.push({...item, num});
  });

  return itemsWithNums;
}
