import {useSelector} from "react-redux";

import {Box, Typography, IconButton} from "@mui/material";
import {Add, Update} from "@mui/icons-material";

import useUpdateSceneRessource from "../hooks/useUpdateSceneRessource";

import useSceneModule from "Features/navigation/useSceneModule";
import PdfExtractedText from "Features/textEditor/components/TextEditor/components/PdfExtractedText";

export default function SectionRessourceExtractedText({
  editorPdf,
  scene,
  ressource,
}) {
  // strings

  const helperS = "Sélectionner le texte dans le .pdf du CCTP";
  // const addS = "Ajouter le lien";
  // const updateS = "Mettre à jour le lien";

  // data

  const updateRessource = useUpdateSceneRessource(scene);
  const newText = useSelector((s) => s.pdf.extractedText);
  const sceneModule = useSceneModule();

  // helper

  const canEdit = sceneModule === "RESSOURCES";

  // helpers

  const currentElement = {pdfExtractedText: ressource.extractedText};
  const newElement = {pdfExtractedText: newText};

  const hasCurrentElement = Boolean(ressource.extractedText);

  const canUpdate =
    Boolean(ressource.extractedText) &&
    Boolean(newText?.text) &&
    ressource.extractedText.text !== newText?.text;
  const canCreate = !Boolean(ressource.extractedText) && Boolean(newText?.text);

  const showHelper =
    !Boolean(ressource.extractedText) && !Boolean(newText?.text);

  // handlers

  function handleUpdate() {
    const newRessource = {...ressource, extractedText: newText};
    updateRessource(newRessource);
  }

  return (
    <Box sx={{p: 1, width: 1}}>
      {canEdit && showHelper && (
        <Box sx={{width: 1, bgcolor: "background.default", p: 1}}>
          <Typography sx={{fontSize: 13}}>{helperS}</Typography>
        </Box>
      )}
      {canEdit && canCreate && (
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PdfExtractedText
            attributes={{}}
            element={newElement}
            editorPdf={editorPdf}
            withoutText={true}
            scene={scene}
          />
          <IconButton size="small" onClick={handleUpdate}>
            <Add fontSize="small" />
          </IconButton>
        </Box>
      )}

      {hasCurrentElement && (
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PdfExtractedText
            attributes={{}}
            element={currentElement}
            editorPdf={editorPdf}
            withoutText={true}
          />
          {canUpdate && canEdit && (
            <IconButton size="small" onClick={handleUpdate}>
              <Update fontSize="small" />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
}
