import {useDispatch} from "react-redux";

import {deleteArticles} from "../articlesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateArticlesBatch() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteBatch = async ({articlesIds, groupId}) => {
    if (accessToken && articlesIds?.length > 0 && groupId) {
      await dispatch(deleteArticles({accessToken, articlesIds, groupId}));
    } else {
      console.error("Missing parameters in useDeleteArticlesBatch");
    }
  };

  return deleteBatch;
}
