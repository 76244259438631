import {useState} from "react";
import {useSelector} from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  InputBase,
  Button,
  DialogActions,
} from "@mui/material";

import downloadExcelFileFromWorkbook from "../utils/downloadExcelFileFromWorkbook";
import createExcelWorkbook from "../utils/createExcelWorkbook";

import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useMeasurementsForDataGrid from "Features/measurements/useMeasurementsForDataGrid";
import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";
import useMeasurementsByRoomForDatagrid from "Features/measurements/useMeasurementsByRoomForDatagrid";
import useMeasurementsByElementTypeForDataGrid from "Features/measurements/useMeasurementsByElementTypeForDataGrid";
import useMeasurementsWithRessourcesForDatagrid from "Features/measurements/useMeasurementsWithRessourcesForDatagrid";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import getRessourcesByRoom from "Features/measurements/utils/getRessourcesByRoom";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useBuildingsFromSectors from "Features/buildings/hooks/useBuildingsFromSectors";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import sanitizeString from "Utils/sanitizeString";
import sortByNum from "Utils/sortByNum";
import getRessourcesByVersion from "Features/measurements/utils/getRessourcesByVersion";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

export default function DialogDownloadSceneDataInViewer({
  scene,
  onClose,
  exportRelations = false,
  exportVersions = false,
}) {
  // strings

  const dialogTitleString = "Nom du fichier";
  const saveS = "Télécharger";
  const cancelS = "Annuler";

  // data

  const mode = useMeasurementsScopeMode(scene, true);
  const [filteredMeasurements] = useFilteredSceneMeasurements(scene, mode);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const zones = useZonesByScene(scene);
  const buildings = useBuildingsFromSectors(sectors);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const typesGroups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const allMeasurements = useSceneMeasurements(scene);
  const ressources = useRessourcesByScene(scene);
  const pricingTable = useSelector((s) => s.pricing.pricingTable);
  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const measurements = useMeasurementsForDataGrid(scene, mode);
  const byRoom = useMeasurementsByRoomForDatagrid(scene, mode);
  const byType = useMeasurementsByElementTypeForDataGrid(scene, mode);
  let byRessource = useMeasurementsByRessourceForDatagrid(scene, mode, 2);
  const measurementsWithRessources = useMeasurementsWithRessourcesForDatagrid(
    scene,
    mode
  );
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const selectedGroup = useSelector(
    (s) => s.measurements.selectedRessourceGroup
  );
  let ressourcesPU = useSelector((s) => s.ressources.ressourcesWithPU);
  const versions = useVersionsByScene(scene);

  // helpers

  const groupsInScope = [selectedGroup];
  let filename = "elements";
  if (scene && !selectedGroup)
    filename = sanitizeString(
      scene.tradesName ? scene.tradesName : scene.title
    );
  else if (selectedGroup) filename = sanitizeString(selectedGroup);
  const fileNameElements = filename + ".xlsx";

  if (scene?.usesPrices) {
    const table = Object.values(pricingTable);
    if (table.length === 1) {
      const map = table[0];
      byRessource = byRessource.map((r) => {
        const puS = map[r.id];
        return {...r, pu: puS ? parseFloat(puS) : null};
      });
    }
  }

  // local state

  const [newName, setNewName] = useState(fileNameElements);
  const [loading, setLoading] = useState(false);

  // handlers

  function handleNewNameChange(e) {
    setNewName(e.target.value);
  }

  async function handleRenameSave() {
    if (loading) return;
    setLoading(true);
    const ressourcesByRoom = getRessourcesByRoom({
      measurements: filteredMeasurements,
      elementTypes,
      ressources,
      rooms,
      filterNoVoids,
      sectors,
      zones,
      ressourcesPU,
    });
    let data = {
      measurements,
      measurementsWithRessources,
      byRessource,
      byType,
      byRoom,
      ressourcesByRoom,
      rooms,
      buildings,
      sectors,
      groupsInScope,
      usesPrices: scene?.usesPrices,
      ressourcesPU,
    };
    if (exportRelations) {
      const groupsMap = {};
      for (const group of typesGroups) {
        groupsMap[group.id] = group.name;
      }
      const typesById = {};
      for (const item of elementTypes) {
        typesById[item.id] = {
          ...item,
          groupName: groupsMap[item.groupId],
        };
      }
      const ressourcesWithTypes = ressources
        .filter((r) => r.group === selectedGroup)
        .sort((a, b) => sortByNum(a.num, b.num))
        .map((r) => {
          const usedTypes = Object.keys(relations)
            .filter((k) => k.endsWith(r.id))
            .map((k) => {
              const rel = relations[k];
              const type = typesById[rel.typeId];
              if (!type) return;
              const typeGroup = typesGroups.find((g) => g.id === type.groupId);
              return {
                ...type,
                groupName: typeGroup?.name,
                func: rel.func,
              };
            })
            .sort(
              (a, b) =>
                a.groupName.localeCompare(b.groupName) ||
                sortByNum(a.num, b.num)
            );
          return {...r, types: usedTypes};
        });
      data = {
        ...data,
        ressourcesTypesRelations: ressourcesWithTypes,
        ressourcesTable: selectedGroup,
        elementTypes,
      };
    }
    if (exportVersions && versions?.length > 0) {
      const ressourcesByVersion = getRessourcesByVersion({
        measurements: allMeasurements,
        elementTypes,
        ressources: ressources.filter((r) => r.group === selectedGroup),
        rooms,
        filterNoVoids,
        sectors,
        zones,
        versions,
      });
      data = {...data, ressourcesByVersion, versions};
    }
    let template = "ALL";
    console.log("create excel from", data);
    try {
      const wb = await createExcelWorkbook({template, data});
      downloadExcelFileFromWorkbook(wb, newName);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    onClose();
  }

  function handleRenameCancel() {
    onClose();
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{dialogTitleString} </DialogTitle>
      <DialogContent>
        <InputBase
          value={newName}
          onChange={handleNewNameChange}
          sx={{minWidth: 350, maxWidth: 450}}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameCancel}>{cancelS}</Button>
        <Button onClick={handleRenameSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
