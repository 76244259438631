import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, Input, InputBase} from "@mui/material";

export default function BlockEditableArticleRefQty({article}) {
  const dispatch = useDispatch();

  // string

  const label = "Qté de référence";

  // state

  let initRefQty = article?.refQty;
  if (initRefQty === null) initRefQty = "";

  const [refQty, setRefQty] = useState(initRefQty);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setRefQty(initRefQty);
  }, [initRefQty, article?.id]);

  // helpers

  const placeholder = "x";

  // handlers

  function handleChange(e) {
    let value = e.target.value;
    value = value.replace(",", ".");
    setRefQty(value);
  }

  function handleBlur() {
    setIsEditing(false);
    let qty = parseFloat(refQty);
    if (isNaN(qty)) qty = null;
    setRefQty("");
    dispatch(updateArticleTemp({id: article.id, harcodedQty: qty}));
  }

  function handleFocus() {
    setIsEditing(true);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Typography sx={{fontSize: 12}}>{label}</Typography>
      <Box
        sx={{
          height: 20,
          display: "flex",
          alignItems: "center",
          bgcolor: "background.default",
          px: 1,
          borderRadius: "4px",
          width: 70,
          mx: 1,
        }}
      >
        <InputBase
          fullWidth
          disableUnderline
          sx={{
            color: isEditing ? "primary.main" : "text.primary",
            fontSize: 12,
          }}
          inputProps={{
            style: {textAlign: "right"},
          }}
          value={refQty}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
        />
      </Box>
      <Typography sx={{fontSize: 12}}>{article?.unit}</Typography>
    </Box>
  );
}
