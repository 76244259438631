import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import useSelectedCategory from "./useSelectedCategory";
import useSelectedMeasurementsModel from "./useSelectedMeasurementsModel";
import useSelectedMeasurementsModelMeasurements from "./useSelectedMeasurementsModelMeasurements";
import useSelectedPreset from "./useSelectedPreset";

import {setDim1} from "Features/measurements/measurementsSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

export default function useTempMeasurement({editorPdf, scene}) {
  const dispatch = useDispatch();

  // default values

  const dim1Default = 0.2;
  // const sideBeam = "down";

  // data

  const measurements = useSelectedMeasurementsModelMeasurements();
  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedElementTypeId = useSelector(
    (s) => s.measurements.selectedElementTypeId
  );
  const selectedElementType = elementTypes.find(
    (t) => t.id === selectedElementTypeId
  );

  const selectedCategory = useSelectedCategory();
  const selectedMeasurementsModel = useSelectedMeasurementsModel();
  const selectedPreset = useSelectedPreset();
  // const pdfModelId = useSelector((s) => s.measurements.pdfModelId);
  let codeName = useSelector((s) => s.measurements.codeName);
  let isVoid = useSelector((s) => s.measurements.drawVoid);
  if (!isVoid) isVoid = false;
  const dotted = useSelector((s) => s.measurements.dotted);
  const color = useSelector((s) => s.measurements.color);
  const height = useSelector((s) => s.measurements.height);
  const heightE = useSelector((s) => s.measurements.heightE);
  const heightN = useSelector((s) => s.measurements.heightN);
  const heights = useSelector((s) => s.measurements.heights);
  const side = useSelector((s) => s.measurements.side);
  const faces = useSelector((s) => s.measurements.faces);
  const slopeH = useSelector((s) => s.measurements.slopeH);
  const rot = useSelector((s) => s.measurements.rot);
  const dim1 = useSelector((s) => s.measurements.dim1);
  const dim2 = useSelector((s) => s.measurements.dim2);
  const dim3 = useSelector((s) => s.measurements.dim3);
  const offset = useSelector((s) => s.measurements.offset);
  const offset2d = useSelector((s) => s.measurements.offset2d);
  const zInf = useSelector((s) => s.measurements.zInf);
  const zSup = useSelector((s) => s.measurements.zSup);
  const zFrom = useSelector((s) => s.measurements.zFrom);
  const drawingShape = useSelector((s) => s.measurements.drawingShape);
  const builtAt = useSelector((s) => s.measurements.builtAt);
  const exeComment = useSelector((s) => s.measurements.exeComment);
  const geoCat = useSelector((s) => s.measurements.geoCat);
  const isRoom = useSelector((s) => s.measurements.isRoom);
  const isGhost = useSelector((s) => s.measurements.isGhost);
  const unit = useSelector((s) => s.measurements.unit);
  const quantityTemplates = useSelector(
    (s) => s.measurements.quantityTemplates
  );
  const zoneId = useSelector((s) => s.measurements.zoneId);
  const zones = useZonesByScene(scene);
  const selectedZone = zones.find((z) => z.id === zoneId);

  const sectorId = useSelector((s) => s.measurements.sectorId);
  const sectors = useSectorsByScene(scene);
  const selectedSector = sectors.find((s) => s.id === sectorId);

  const versionId = useSelector((s) => s.measurements.versionId);
  const versions = useVersionsByScene(scene);
  const selectedVersion = versions.find((s) => s.id === versionId);

  const roomId = useSelector((s) => s.measurements.roomId);
  const rooms = useRoomsByScene(scene);
  const selectedRoom = rooms.find((s) => s.id === roomId);

  let materialId = useSelector((s) => s.measurements.materialId);
  if (!materialId) materialId = selectedElementType?.material?.id;

  const materials = useMaterialsByScene(scene);
  let selectedMaterial;
  if (materialId) {
    selectedMaterial = materials.find((s) => s.id === materialId);
  }

  //const colorToApply = selectedMaterial ? selectedMaterial.color : color;
  const colorToApply = color;

  const ressourceId = useSelector((s) => s.measurements.ressourceId);
  const ressources = useRessourcesByScene(scene);
  const selectedRessource = ressources.find((r) => r.id === ressourceId);

  const phaseId = useSelector((s) => s.measurements.phaseId);
  const phases = usePhasesByScene(scene);
  const selectedPhase = phases.find((r) => r.id === phaseId);

  const hostedTypes = useSelector((s) => s.measurements.hostedTypes);
  const voids = useSelector((s) => s.measurements.voids);

  // helper - num & code

  const nums = measurements
    .filter((m) => m.elementTypeId === selectedElementType?.id)
    .map((m, index) => (m.num ? m.num : index + 1));
  const max = Math.max(...nums);

  const num = max >= 0 ? max + 1 : 1;

  if (!codeName || codeName === "") {
    const prefix = selectedElementType?.code
      ? selectedElementType?.code + "-"
      : "";
    codeName = prefix + (num / 1000).toFixed(3).split(".")[1];
  }

  // helper - res

  const res = selectedElementType?.res
    ? selectedElementType.res.map(({resId}) => ({resId, off: false}))
    : [];

  // helper - file3d

  const file3d = selectedElementType?.file3d ?? null;

  // tempMeasurement

  const tempMeasurement = {
    num,
    codeName,
    category: selectedCategory,
    //measurementsModel: selectedMeasurementsModel,
    measurementsModelId: selectedMeasurementsModel?.id,
    pdfModelId: selectedMeasurementsModel?.fromModel?.modelId,
    preset: selectedPreset,
    zone: selectedZone ? selectedZone : null,
    elementType: selectedElementType,
    elementTypeId: selectedElementTypeId,
    sector: selectedSector ? selectedSector : null,
    sectorId,
    room: selectedRoom ? selectedRoom : null,
    roomId,
    material: selectedMaterial ? selectedMaterial : null,
    materialId,
    ressource: selectedRessource ? selectedRessource : null,
    ressourceId,
    phaseId,
    phase: selectedPhase ? selectedPhase : null,
    isVoid,
    dotted,
    color: colorToApply,
    height,
    heightE,
    heightN,
    heights,
    side,
    faces,
    slopeH,
    rot,
    dim1,
    dim2,
    dim3,
    zInf,
    zSup,
    zFrom,
    offset,
    offset2d,
    drawingShape,
    builtAt,
    exeComment,
    geoCat,
    isRoom,
    isGhost,
    unit,
    quantityTemplates,
    res,
    hostedTypes,
    voids,
    versionId,
    version: selectedVersion ?? null,
    file3d,
  };

  // effects - default value

  useEffect(() => {
    if (["SEGMENT", "POLYLINE", "CIRCLE"].includes(drawingShape)) {
      if (!dim1) {
        dispatch(setDim1(dim1Default));
      }
    }
  }, [dim1, drawingShape]);

  // effects - WARNING : fired 8x by 8x

  useEffect(() => {
    const manager = editorPdf?.annotationsManager?.measurementsPdfManager;
    if (manager) {
      manager.setDim1(dim1);
      manager.setDim2(dim2);
      manager.setDim3(dim3);
      manager.setDrawVoid(isVoid);
      manager.setDotted(dotted);
      manager.setColor(colorToApply);
      manager.setElementTypeId(selectedElementTypeId);
      manager.setTempMeasurement(tempMeasurement);
    }
  }, [
    // codeName,
    // num,
    // builtAt,
    // rot,
    dim1,
    dim2,
    dim3,
    // height,
    zInf,
    zSup,
    offset,
    // offset2d,
    // geoCat,
    colorToApply,
    isVoid,
    dotted,
    selectedElementTypeId,
    selectedMeasurementsModel?.id,
    // sectorId,
    // roomId,
    // materialId,
    // ressourceId,
    // isRoom,
    // isGhost,
    Boolean(editorPdf?.annotationsManager?.measurementsPdfManager),
  ]);

  return tempMeasurement;
}
