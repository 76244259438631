import {Box} from "@mui/material";

import useSelection from "Features/selection/useSelection";
import FormRoom from "Features/rooms/components/FormRoom";

export default function SectionHomeRoomInSelectionPanel({scene}) {
  // data

  const selection = useSelection({scene});
  const room = selection?.item;

  return (
    <Box>
      <FormRoom room={room} canEdit={false} />
    </Box>
  );
}
