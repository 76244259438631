import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setToolViewer} from "Features/viewer3D/viewer3DSlice";

import {ToggleButton, Tooltip, Box} from "@mui/material";
import {OpenWith as Position} from "@mui/icons-material";

export default function ToggleMoveTool({editor}) {
  const dispatch = useDispatch();

  // strings

  const tipString = "Move";

  // data

  const tool = useSelector((state) => state.viewer3D.toolViewer);
  const checked = tool === "POSITION";

  // handlers

  const handleChange = () => {
    if (checked) {
      dispatch(setToolViewer(null));
    } else {
      dispatch(setToolViewer("POSITION"));
    }
  };

  return (
    <Box sx={{color: "common.white"}}>
      <Tooltip title={tipString}>
        <ToggleButton
          value="POSITION"
          selected={checked}
          onChange={handleChange}
          aria-label="module"
          sx={{
            width: 30,
            height: 30,
            border: "none",
            "&.Mui-selected": {
              bgcolor: "common.darkGrey",
              "&:hover": {
                bgcolor: "common.darkGrey",
              },
            },
          }}
        >
          <Box
            sx={{color: "common.white", display: "flex", alignItems: "center"}}
          >
            <Position fontSize="small" color="inherit" />
          </Box>
        </ToggleButton>
      </Tooltip>
    </Box>
  );
}
