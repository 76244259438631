import {useSelector, useDispatch} from "react-redux";

import {Box, Tooltip, Typography} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import {setSelectedTypesInDatagrid} from "../measurementsSlice";
import useMeasurementsByElementTypeForDataGrid from "../useMeasurementsByElementTypeForDataGrid";

import sortByNum from "Features/rooms/utils/sortByNum";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function DataGridMeasurementsByElementType({
  mode,
  scene,
  withProgress = true,
  editor3d,
}) {
  const dispatch = useDispatch();

  // strings

  const numString = "Num";
  const typeString = "Type";
  const countString = "x";
  const unitString = "Unit";
  const quantityString = "Qty";
  const ratioString = "%";
  const focusS = "Focus";

  // data

  const os = useSelector((s) => s.ui.openSections);

  const measurements = useMeasurementsByElementTypeForDataGrid(scene, mode);

  const group = useSelector(
    (s) => s.measurements.selectedElementTypeGroupInScope
  );

  // helpers

  const narrowGrid = (os.fixedViewersBox || os.viewer3D) && os.outlet;

  // grid - rows

  const rows = measurements.filter(
    (m) => !group || m.group === group
  ).sort((a, b) => sortByNum(a.num, b.num));

  // grid - columns

  const columns = [
    {field: "num", headerName: numString, width: 90, hide: narrowGrid},
    {
      field: "type",
      headerName: typeString,
      flex: 0.2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography
            variant="body2"
            sx={{
              fontWeight:
                params.row.isTitle || params.row.isGroup ? "bold" : "normal",
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "count",
      headerName: countString,
      width: 60,
      align: "center",
      hide: narrowGrid,
    },
    {field: "unit", headerName: unitString, width: 80},
    {field: "quantity", headerName: quantityString, width: 100},
    {
      field: "ratio",
      hide: narrowGrid || (withProgress ? false : true),
      headerName: ratioString,
      width: 100,
      renderCell: (params) => {
        const percentS = (params.value * 100).toFixed();
        return (
          <Box sx={{width: 1, height: 1}}>
            {!params.row.isTitle && params.row.quantity != "-" && (
              <Box
                sx={{
                  height: 1,
                  width: `${(params.value * 100).toFixed()}%`,
                  bgcolor: (theme) => theme.palette.secondary.light,
                  display: "flex",
                  alignItems: "center",
                  pl: 1,
                }}
              >
                <Typography variant="body2" sx={{fontWeight: "bold"}}>
                  {percentS + "%"}
                </Typography>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      hide: !os.viewer3D,
      getActions: (params) => {
        if (!params.row.isTitle && params.row.count > 0) {
          return [
            <GridActionsCellItem
              icon={<Focus />}
              onClick={() => handleFocusClick(params.row.id)}
              label={focusS}
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  // console.log(
  //   "DataGridMeasurementsByElementType",
  //   "measurements", measurements,
  //   "rows", rows,
  //   "columns", columns,
  // )

  // selectionModel

  const selectionModel = useSelector(
    (s) => s.measurements.selectedTypesInDatagrid
  );

  // handlers

  function handleSelectionChange(selection) {
    // let measurement;
    // if (selection.length > 0)
    //   measurement = measurements.find((m) => m.id === selection[0]);
    // if (!measurement?.isGroup)
    dispatch(setSelectedTypesInDatagrid(selection));
  }

  function handleFocusClick(elementTypeId) {
    console.log("focus on elementType", elementTypeId);
    editor3d?.sceneEditor.focusElementType(elementTypeId);
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Group": {
          bgcolor: "background.default",
          fontWeight: "bolder",
        },
        "& .super-app-theme--Title": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--NoItem": {
          color: "divider",
          fontSize: 13,
        },
      }}
    >
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        getRowClassName={(params) => {
          if (params.row.isGroup) return `super-app-theme--Group`;
          if (params.row.isTitle) return `super-app-theme--Title`;
          if (params.row.parent && params.row.count > 0)
            return `super-app-theme--Child`;
          if (params.row.count === 0) return `super-app-theme--NoItem`;
        }}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
