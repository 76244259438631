import {useEffect} from "react";
import {useSelector} from "react-redux";

import {setLastOpenedScopeId} from "Database/caplaLocalStorage";

export default function useUpdateRestoreSession() {
  const scopeId = useSelector((s) => s.scopes.selectedScopeId);

  function saveScopeId() {
    // if (scopeId && scopeId !== "all") setLastOpenedScopeId(scopeId); // if we don't update for "all", the app is opened with the last limited scope.
    if (scopeId) setLastOpenedScopeId(scopeId);
  }

  useEffect(() => {
    saveScopeId();
  }, [scopeId]);
}
