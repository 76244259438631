import {useDispatch} from "react-redux";

import {updateArticlesBatch} from "../articlesSlice";
import {updateArticlesGroup} from "../articlesSlice";

import useArticlesToSync from "./useArticlesToSync";
import useArticlesGroupsToSync from "./useArticlesGroupsToSync";

export default function useUpdateInBatchArticlesToUpdate() {
  const dispatch = useDispatch();

  const articlesToSync = useArticlesToSync();
  const articlesGroupsToSync = useArticlesGroupsToSync();

  const updatesBatch = articlesToSync
    .filter((article) => article.updates)
    .map((article) => ({
      ...article.updates,
      articlesGroupId: article.articlesGroupId,
    }));

  const groupsUpdates = articlesGroupsToSync
    .filter((group) => group.updates)
    .map((group) => ({
      ...group.updates,
      sceneId: group.sceneId,
    }));

  const update = async (accessToken) => {
    if (updatesBatch.length > 0) {
      await dispatch(updateArticlesBatch({accessToken, updatesBatch}));
    }
    if (groupsUpdates.length > 0) {
      await Promise.all(
        groupsUpdates.map((groupUpdates) =>
          dispatch(updateArticlesGroup({accessToken, updates: groupUpdates}))
        )
      );
    }
  };

  return update;
}
