import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box, Switch} from "@mui/material";
import {Launch, Refresh} from "@mui/icons-material";
import {DataGridPro, GridActionsCellItem, frFR} from "@mui/x-data-grid-pro";

import DatagridCellPackageColorings from "./DatagridCellPackageColorings";

import {setLastPackageId, setSelectedPackageId} from "../packagesSlice";

import {
  setSelectedPdfModelId,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import {setOpenSections} from "Features/ui/uiSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useUpdateRessourcesFromTypes from "../hooks/useUpdateRessourcesFromTypes";

export default function DatagridPackages({
  scene,
  packagesForDatagrid,
  packages,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // strings

  const nameS = "Name";
  const elementsS = "Elements";
  const coloringsS = "Colorings";
  const enabledS = "Enabled";
  const excludedS = "Excluded";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const selectedPackageId = useSelector((s) => s.packages.selectedPackageId);
  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const zones = useZonesByScene(scene);
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const refreshPackages = useUpdateRessourcesFromTypes({
    caplaEditor,
    packages,
    elementTypes,
    ressources,
    zones,
    rooms,
    sectors,
  });

  // helpers

  const sceneId = scene?.id ? scene.id : scene.clientId;
  const selectionModel = selectedPackageId ? [selectedPackageId] : [];

  // grid - rows

  const rows = packagesForDatagrid;

  // grid - columns

  const columns = [
    {field: "name", headerName: nameS, flex: 1},
    {field: "elements", headerName: elementsS, width: 100},
    {
      field: "colorings",
      headerName: coloringsS,
      width: 100,
      renderCell: (params) => {
        const packageId = params.row.remoteId;
        return packageId ? (
          <DatagridCellPackageColorings
            packageId={packageId}
            coloringsCount={params.value}
          />
        ) : (
          <Box />
        );
      },
    },
    {
      field: "enabled",
      headerName: enabledS,
      width: 65,
      renderCell: (params) => (
        <Switch
          size="small"
          checked={params.row.enabled}
          onChange={(e) => handleEnabledChange(e, params.row)}
        />
      ),
    },
    {
      field: "excludeFromQties",
      headerName: excludedS,
      width: 65,
      renderCell: (params) => (
        <Switch
          size="small"
          checked={params.row.excludeFromQties}
          onChange={(e) => handleExcludedChange(e, params.row)}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      renderHeader: () => {
        return (
          <GridActionsCellItem
            icon={<Refresh fontSize="small" sx={{color: "inherit"}} />}
            onClick={() => updateAllRessourcesFromTypes()}
            label="Update all"
          />
        );
      },
      getActions: (params) => [
        <GridActionsCellItem
          disabled={!params.row.enabled}
          icon={
            <Refresh
              fontSize="small"
              sx={{
                color: params.row.enabled ? "inherit" : "#a9a9abff",
              }}
            />
          }
          onClick={() => updateRessourcesFromTypes(params.row.id)}
          label="Update"
        />,
        <GridActionsCellItem
          disabled={!params.row.enabled}
          icon={
            <Launch
              fontSize="small"
              sx={{
                color: params.row.enabled ? "inherit" : "#a9a9abff",
              }}
            />
          }
          onClick={() => handleLaunchClick(params.row)}
          label="Launch"
        />,
      ],
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    dispatch(setSelectedPackageId(selection ? selection[0] : null));
  }

  function handleLaunchClick(thePackage) {
    if (thePackage.enabled) {
      const packageId = thePackage.id;
      navigate(`/bimboxes/${sceneId}/${packageId}/measurements`);
      dispatch(setSelectedMeasurementsModelId(packageId));
      // caplaEditor?.measDataManager.setSelectedModelById(packageId);
      //dispatch(setLastPackageId(packageId));
      const fullPackage = packages.find((p) => p.id === thePackage.id);
      dispatch(setSelectedPdfModelId(fullPackage?.fromModel.modelId));
      dispatch(
        setOpenSections({
          outlet: false,
          viewer3D: os.viewer3D,
          fixedViewersBox: true,
          callToAction: false,
        })
      );
    }
  }

  async function handleEnabledChange(event, row) {
    const thePackage = packages.find((p) => p.id === row.id);
    const updatedModel = {
      ...thePackage,
      enabled: event.target.checked,
    };
    await dispatch(updateModel({updatedModel, sync: true}));
  }

  async function handleExcludedChange(event, row) {
    const thePackage = packages.find((p) => p.id === row.id);
    const updatedModel = {
      ...thePackage,
      excludeFromQties: event.target.checked,
    };
    await dispatch(updateModel({updatedModel, sync: true}));
  }

  function updateRessourcesFromTypes(id) {
    refreshPackages([id]);
  }

  function updateAllRessourcesFromTypes() {
    refreshPackages(rows.map((r) => r.id));
  }

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--false": {
          color: "#a9a9abff",
        },
      }}
    >
      <DataGridPro
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
        getRowClassName={(params) => `super-app-theme--${params.row.enabled}`}
      />
    </Box>
  );
}
