import React from "react";

import useColoringsByPackage from "../useColoringsByPackage.js";

import {Box, Typography} from "@mui/material";
import GridItemColoringDetailed from "./GridItemColoringDetailed.js";

export default function SectionColoringsByPackage({packageId}) {
  // data

  const colorings = useColoringsByPackage(packageId);

  return (
    <Box sx={{width: 1}}>
      {colorings.map((coloring) => {
        return (
          <GridItemColoringDetailed coloring={coloring} key={coloring.id} />
        );
      })}
    </Box>
  );
}
