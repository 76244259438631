import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchViewsService,
  createViewService,
  fetchViewService,
  updateViewService,
  deleteViewService,
} from "./services";

export const createView = createAsyncThunk(
  "views/createView",
  createViewService
);

export const fetchViews = createAsyncThunk(
  "views/fetchViews",
  fetchViewsService
);

export const fetchView = createAsyncThunk("views/fetchView", fetchViewService);

export const updateView = createAsyncThunk(
  "views/updateView",
  updateViewService
);
export const deleteView = createAsyncThunk(
  "views/deleteView",
  deleteViewService
);

const viewsSlice = createSlice({
  name: "views",
  initialState: {items: [], selectedViewId: null},
  reducers: {
    setSelectedViewId: (state, action) => {
      state.selectedViewId = action.payload;
    },
  },
  extraReducers: {
    [createView.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items.push(item);
    },
    [fetchViews.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
    [fetchView.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [updateView.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [deleteView.fulfilled]: (state, action) => {
      const viewId = action.payload;
      state.items = state.items.filter((i) => i.id !== viewId);
    },
  },
});
export const {setSelectedViewId} = viewsSlice.actions;

export default viewsSlice.reducer;
