import React from "react";
import {Popper, Box, alpha} from "@mui/material";

export default function ActionPopper({
  anchorEl,
  open,
  children,
  disablePortal = true,
}) {
  return (
    <Popper
      disablePortal={disablePortal}
      open={open}
      anchorEl={anchorEl}
      placement="auto-end"
      modifiers={[
        {
          name: "arrow",
          enabled: true,
        },
        {
          name: "offset",
          options: {
            offset: [10, 10],
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            rootBoundary: "viewport",
            altAxis: true,
            altBoundary: true,
            tether: true,
            padding: 0,
          },
        },
      ]}
    >
      <Box
      // sx={{
      //   background: (theme) => alpha(theme.palette.common.white, 0.9),
      //   p: 2,
      //   border: (theme) => `1px solid ${theme.palette.primary.main}`,
      // }}
      >
        {children}
      </Box>
    </Popper>
  );
}
