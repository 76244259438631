import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "API/capla360";
import {
  createListingService,
  addListingMembershipService,
  fetchSceneListingsService,
} from "./services";

export const fetchUserListings = createAsyncThunk(
  "listings/fetchUserListings",
  async ({accessToken, userId}) => {
    const response = await client.get(`/users/${userId}/listings/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
);

export const fetchSceneListings = createAsyncThunk(
  "listings/fetchSceneListings",
  fetchSceneListingsService
);

export const createListing = createAsyncThunk(
  "listings/createListing",
  createListingService
);

export const deleteListing = createAsyncThunk(
  "listings/deleteListing",
  async ({accessToken, listingId}) => {
    const response = await client.delete(`/listings/${listingId}/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
);

export const updateListing = createAsyncThunk(
  "listings/updateListing",
  async ({accessToken, listingId, updatedData}) => {
    console.log("updateListingAAA", listingId);
    const response = await client.put(`/listings/${listingId}/`, updatedData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
);

// memberships

export const addListingMembership = createAsyncThunk(
  "listings/addListingMembership",
  addListingMembershipService
);

export const listingsSlice = createSlice({
  name: "listings",
  initialState: {
    data: [],
    status: "idle",
    scenesStatus: {}, // loadingStatus : {sceneId:,status}
  },
  reducers: {
    addListing: (state, action) => {
      const {listingId, listingItem} = action.payload;
      state.ids.push(listingId);
      state.items[listingId] = listingItem;
    },
    selectListing: (state, action) => {
      state.selectedListingId = action.payload;
    },
  },
  extraReducers: {
    "scenes/fetchRemoteScene/fulfilled": (state, action) => {
      const data = action.payload;
      const remoteScene = data?.remoteScene;
      if (!remoteScene || data.updateSceneStateOnly) return;
      const newListings = [];
      console.log("STATE", remoteScene.listings);
      remoteScene.listings.forEach((listing) => {
        const existingListing = state.data.find((l) => l?.id === listing.id); // TODO improve by using sets
        if (!existingListing) newListings.push(existingListing);
      });
      state.data = [...state.data, ...newListings];
    },
    "scenes/fetchSharedRemoteScene/fulfilled": (state, action) => {
      const {listings} = action.payload;
      state.data = listings;
    },
    "viewer3D/fetchSceneListings/fulfilled": (state, action) => {
      const {listings} = action.payload;
      const newListings = [];
      listings.forEach((listing) => {
        const existingListing = state.data.find((l) => l?.id === listing.id); // TODO improve by using sets
        if (!existingListing) newListings.push(listing);
      });
      state.data = [...state.data, ...newListings];
    },
    [fetchSceneListings.pending]: (state, action) => {
      const {sceneId} = action.payload;
      state.scenesStatus[sceneId] = "loading";
    },
    [fetchSceneListings.fulfilled]: (state, action) => {
      const {listings, sceneId} = action.payload;
      const newListings = [];
      listings.forEach((listing) => {
        const existingListing = state.data.find((l) => l?.id === listing.id); // TODO improve by using sets
        if (!existingListing) newListings.push(listing);
      });
      state.data = [...state.data, ...newListings];
      state.scenesStatus[sceneId] = "loaded";
    },
    [fetchSceneListings.rejected]: (state, action) => {
      const {sceneId} = action.payload;
      state.scenesStatus[sceneId] = "failed";
    },
    [fetchUserListings.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUserListings.fulfilled]: (state, action) => {
      state.status = "succeed";
      // state.data = action.payload;
    },

    [fetchUserListings.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    [createListing.fulfilled]: (state, action) => {
      const {listing: newList} = action.payload;
      state.data.push(newList);
      state.data = state.data
        .slice()
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
    [updateListing.fulfilled]: (state, action) => {
      const updatedList = action.payload;
      const data = state.data.filter(({id}) => id !== updatedList.id);
      const oldListing = state.data.find(({id}) => id === updatedList.id);
      const newData = [...data, {...oldListing, ...updatedList}];
      state.data = newData
        .slice()
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
    [deleteListing.fulfilled]: (state, action) => {
      const {deletedListingId} = action.payload;
      const data = state.data.filter(({id}) => id !== deletedListingId);
      state.data = data;
    },
    [addListingMembership.fulfilled]: (state, action) => {
      const updatedList = action.payload;
      const data = state.data.filter(({id}) => id !== updatedList.id);
      const newData = [...data, updatedList];
      state.data = newData
        .slice()
        .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    },
  },
});

// selectors

export const selectAllListings = (state) => state.listings.data;
export const selectSelectedListing = (state) => {
  return (
    state.listings.selectedListingId &&
    state.listings.items[state.listings.selectedListingId]
  );
};
export const selectListingsTree = (state) => {
  // listingsTree = [{operationName: "xxx", listings: [{listingName: xxx, listingId: xxx, listingAvatar: xxx}]}]

  const tree = state.listings.operations.ids.map((operationId) => {
    const operation = state.listings.operations.items[operationId];
    const listings = operation.listingsIds.map((listingId) => {
      const listing = state.listings.items[listingId];
      return {
        listingName: listing.name,
        listingId,
        listingAvatar: listing.avatar,
      };
    });
    return {operationName: operation.name, listings};
  });
  return tree;
};

// selectors
export const selectListingsGridData = (state) => state.listings.data;

export const selectListingById = (listingId) => (state) => {
  return state.listings.data.find((listing) => listing.id === listingId);
};
// actions exports

export const {addListing, selectListing} = listingsSlice.actions;

// default export

export default listingsSlice.reducer;
