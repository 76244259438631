import {useDispatch} from "react-redux";

import {Switch, Box} from "@mui/material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ButtonSwitchEnableScope({editor3d, model}) {
  const dispatch = useDispatch();

  // helpers

  let show = model?.enabled;
  if (show === undefined) show = false;

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    const updatedModel = {...model, enabled: checked};
    dispatch(updateModel({updatedModel}));
    const entity = editor3d?.getEntity(model.id);
    if (checked) {
      if (entity?.enable) entity.enable();
      editor3d?.loader.loadInitialModel(model);
    } else {
      if (entity?.disable) entity.disable();
    }
  }

  return (
    <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
      <Switch size="small" checked={show} onChange={handleCheckChange} />
    </Box>
  );
}
