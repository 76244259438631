import React from "react";

import {Box, Paper, Typography} from "@mui/material";

export default function ListItemElement({element, selected = false, onClick}) {
  // handlers

  function handleClick() {
    onClick(element);
  }

  return (
    <Paper
      sx={{px: 1, py: 0.5, cursor: "pointer", width: "fit-content"}}
      elevation={selected ? 12 : 1}
      onClick={handleClick}
    >
      <Typography sx={{fontWeight: selected ? "bold" : "normal", fontSize: 13}}>
        {element.codeName}
      </Typography>
    </Paper>
  );
}
