import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchSurveysService,
  createSurveyService,
  fetchSurveyService,
  updateSurveyService,
  deleteSurveyService,
} from "./services";

export const createSurvey = createAsyncThunk(
  "surveys/createSurvey",
  createSurveyService
);

export const fetchSurveys = createAsyncThunk(
  "surveys/fetchSurveys",
  fetchSurveysService
);

export const fetchSurvey = createAsyncThunk(
  "surveys/fetchSurvey",
  fetchSurveyService
);

export const updateSurvey = createAsyncThunk(
  "surveys/updateSurvey",
  updateSurveyService
);
export const deleteSurvey = createAsyncThunk(
  "surveys/deleteSurvey",
  deleteSurveyService
);

const surveysSlice = createSlice({
  name: "surveys",
  initialState: {items: [], selectedSurveyId: null},
  reducers: {
    setSelectedSurveyId: (state, action) => {
      state.selectedSurveyId = action.payload;
    },
  },
  extraReducers: {
    [createSurvey.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items.push(item);
    },
    [fetchSurveys.fulfilled]: (state, action) => {
      const {items} = action.payload;
      state.items = items;
    },
    [fetchSurvey.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [updateSurvey.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.items = state.items.filter((i) => i.id !== item.id);
      state.items.push(item);
    },
    [deleteSurvey.fulfilled]: (state, action) => {
      const surveyId = action.payload;
      state.items = state.items.filter((i) => i.id !== surveyId);
    },
  },
});
export const {setSelectedSurveyId} = surveysSlice.actions;

export default surveysSlice.reducer;
