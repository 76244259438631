/*
 * sector : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useDeleteSceneSector(scene) {
  const dispatch = useDispatch();

  const deleteSceneSector = (sector) => {
    if (!scene) return;
    let oldSectors = scene?.data?.sectors.filter((s) => s?.id !== sector.id);
    if (!oldSectors) oldSectors = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, sectors: [...oldSectors]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return deleteSceneSector;
}
