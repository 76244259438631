import {
  createStoredObjectset,
  fetchStoredObjectsets,
  updateStoredObjectset,
  deleteStoredObjectset,
} from "Database/services";

export async function createObjectsetService(objectset) {
  const newObjectset = await createStoredObjectset(objectset);
  return newObjectset;
}

export async function fetchStoredObjectsetsService({sceneClientId}) {
  const storedObjectsets = await fetchStoredObjectsets({sceneClientId});
  return {objectsets: storedObjectsets, sceneClientId};
}

export async function updateStoredObjectsetService({objectset}) {
  await updateStoredObjectset({objectset});
  return objectset;
}

export async function deleteStoredObjectsetService({objectsetId}) {
  await deleteStoredObjectset({objectsetId});
  return objectsetId;
}
