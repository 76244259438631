import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid, frFR} from "@mui/x-data-grid";

import {setSelectedMaterialId} from "../materialsSlice";
import useMaterialsBySceneWithMsts from "../hooks/useMaterialsBySceneWithMsts";
import materialsForDataGrid from "../utils/materialsForDataGrid";

export default function DataGridMaterials({scene}) {
  const dispatch = useDispatch();

  // strings

  const numS = "Num";
  const nameS = "Nom";
  const codeS = "Code";
  const colorS = "Couleur";

  // data

  const materials = useMaterialsBySceneWithMsts(scene);
  const selectedMaterialId = useSelector((s) => s.materials.selectedMaterialId);
  const selectionModel = selectedMaterialId ? [selectedMaterialId] : [];

  // grid - rows

  const rows = materialsForDataGrid(materials);

  // grid - columns

  const columns = [
    {
      field: "num",
      headerName: numS,
      width: 80,
    },

    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
    {
      field: "code",
      headerName: codeS,
      width: 80,
    },
    {
      field: "color",
      headerName: colorS,
      width: 100,
      renderCell: (params) => {
        return (
          <Box
            sx= {{
              width: 1,
              height: 1,
              bgcolor: `${params.value}`,
            }}
          ></Box>
        )
      }
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    if (selectionId === selectedMaterialId) {
      dispatch(setSelectedMaterialId(null));
    } else {
      dispatch(setSelectedMaterialId(selectionId));
    };
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
