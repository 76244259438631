import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

import {setSelectedProgressRecordId} from "../progressSlice";
import useProgressRecordsForDataGrid from "../useProgressRecordsForDataGrid";

// import useTranslation from "Features/translations/useTranslation";

export default function DataGridProgressRecords({scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  // const idS = "ID";
  const nameS = "Name";
  const startS = "Start";
  const endS = "End";
  const createdByS = "By";

  // data

  const selectedProgressRecordId = useSelector(
    (s) => s.progress.selectedProgressRecordId
  );

  // grid - rows

  const rows = useProgressRecordsForDataGrid(scene);

  // grid - columns

  const columns = [
    {
      field: "name",
      headerName: nameS,
      flex: 0.2,
    },
    {
      field: "createdBy",
      headerName: createdByS,
      flex: 0.2,
    },
    {
      field: "start",
      headerName: startS,
      width: 100,
    },
    {
      field: "end",
      headerName: endS,
      width: 100,
    },
  ];

  // grid - selection model

  const selectionModel = selectedProgressRecordId
    ? [selectedProgressRecordId]
    : [];

  // handlers

  function handleSelectionChange(selection) {
    if (selection) {
      dispatch(setSelectedProgressRecordId(selection[0]));
    }
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
