import {nanoid} from "@reduxjs/toolkit";

import {
  Mesh,
  // MeshStandardMaterial,
  MeshLambertMaterial,
  BufferGeometry,
} from "three";
import {MathUtils} from "three";

import IfcModelEntity from "./IfcModelEntity";

export default class GLBModel {
  constructor({editor3d, model}) {
    this.editor3d = editor3d;
    this.loader = editor3d?.gltfLoader;
    this.type = "GLB";
    this.url = model.url;
  }

  static computeInitialModel({file, sceneClientId}) {
    const url = URL.createObjectURL(file);
    const position = {x: 0, y: 0, z: 0};
    const rotation = {x: 0, y: 0, z: 0};
    const name = file.name;

    const model = {
      id: MathUtils.generateUUID(),
      sceneClientId,
      name,
      url,
      type: "GLB",
      rotation,
      position,
    };

    return model;
  }

  asyncCreateObject = async () => {
    const scene = this.editor3d?.scene;
    let object = await this.getMeshes(this.url);
    if (Array.isArray(object)) object = object[0];
    //object.geometry.computeBoundsTree();

    //object.layers.enable(1);
    scene.add(object);
    console.log("new object", object);
    this.object = object;
  };

  loadObject() {
    this.object.layers.enable(1);
    if (this.object.children[0]) this.object.children[0].layers.enable(1);

    this.editor3d?.scene.add(this.object);
    this.editor3d?.entities.push(this);
    this.editor3d?.objectEntities.push(this);
  }

  loadIfcModelEntity() {
    const model = {
      id: nanoid(),
      name: "test",
      type: "IFC",
    };
    this.ifcModelEntity = new IfcModelEntity({
      model,
      ifcLoader: this.editor3d?.ifcLoader,
    });
    this.ifcModelEntity.modelObject = this.object;
    this.ifcModelEntity.object = this.object;
    this.editor3d?.objectEntities.push(this.ifcModelEntity);
  }

  setupMeshAsModel(mesh) {
    this.editor3d?.ifcLoader.ifcManager.state.models.push({mesh});
  }

  async getGltfMesh(url) {
    const allMeshes = await this.getMeshes(url);
    console.log("getAllMeshes", allMeshes);
    const geometry = this.getGeometry(allMeshes);
    const materials = this.getMaterials(allMeshes);
    this.cleanUpLoadedInformation(allMeshes);
    console.log("create Gltf mesh", geometry, materials);
    return new Mesh(geometry, materials);
  }

  async getMeshes(url) {
    console.log("XXX", url);
    const gltf = await this.loader.loadAsync(url);
    const result = gltf.scene;
    console.log("result gltf", result);
    if (gltf.scene.children[0].children.length > 0) {
      return gltf.scene.children[0].children;
    } else {
      return gltf.scene.children;
    }
  }

  cleanUpLoadedInformation(allMeshes) {
    allMeshes.forEach((mesh) => {
      mesh.geometry.attributes = {};
      mesh.geometry.dispose();
      mesh.material.dispose();
    });
  }

  getMaterials(allMeshes) {
    return allMeshes.map((mesh) => {
      const material = mesh.material;
      return new MeshLambertMaterial({
        color: material.color,
        transparent: true,
        opacity: material.opacity,
        side: 2,
      });
    });
  }

  getGeometry(meshes) {
    const geometry = new BufferGeometry();
    this.setupGeometryAttributes(geometry, meshes);
    this.setupGeometryIndex(meshes, geometry);
    this.setupGroups(meshes, geometry);
    return geometry;
  }

  setupGeometryAttributes(geometry, meshes) {
    const geometries = meshes.map((m) => m.geometry).filter((g) => Boolean(g));
    // meshes[0].geometry => geometries[0]
    if (geometries[0]) {
      geometry.setAttribute("expressID", geometries[0].attributes._expressid);
      geometry.setAttribute("position", geometries[0].attributes.position);
      geometry.setAttribute("normal", geometries[0].attributes.normal);
    }
  }

  setupGeometryIndex(meshes, geometry) {
    const indices = meshes.map((mesh) => {
      const index = mesh.geometry.index;
      return index ? index.array : [];
    });

    const indexArray = [];
    for (let i = 0; i < indices.length; i++) {
      for (let j = 0; j < indices[i].length; j++) {
        indexArray.push(indices[i][j]);
      }
    }
    geometry.setIndex(indexArray);
  }

  setupGroups(meshes, geometry) {
    const groupLengths = meshes.map((mesh) => {
      const index = mesh.geometry.index;
      return index ? index.count : 0;
    });
    let start = 0;
    let materialIndex = 0;
    geometry.groups = groupLengths.map((count) => {
      const result = {start, count, materialIndex};
      materialIndex++;
      start += count;
      return result;
    });
  }

  getModelID() {
    const allIDs = this.editor3d?.objectEntities
      .map((e) => e.ifcModelID)
      .filter((id) => typeof id === "number");
    if (allIDs.length === 0) return 0;
    return Math.max(...allIDs) + 1;
  }
}
