import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import { addZoneToBuffer, removeZoneFromBuffer, setUseBufferInShortcut } from "Features/zones/zonesSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setShortcutToolViewer3D } from "Features/ui/uiSlice";

export default function SectionRessourceZonesInSelectionPanel({
  ressource,
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Fonds de plan utilisés pour le repérage";

  // data

  const mode = useMeasurementsScopeMode(scene);
  const [fms] = useFilteredSceneMeasurements(scene, mode);
  const allZones = useZonesByScene(scene);
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);
  const modelsWithInitialTexture = useSelector(
    (s) => s.viewer3D.modelsWithInitialTexture
  );
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.type === "IMAGE"
  );

  // helpers

  const measurements = fms.filter(
    (m) =>
      m.res?.map(({resId}) => resId).includes(ressource?.id) ||
      m.ressourceId === ressource?.id
  );
  const zoneIds = new Set(measurements.map((m) => m.zoneId));
  const zones = allZones.filter((z) => zoneIds.has(z.id));
  const display = zoneIds?.size > 0 ? "flex" : "none";
  
  // handlers 

  async function handleZoneVisibility(zone, zoneModel) {
    const loaded = modelsWithInitialTexture.includes(zone.imageModelId);
    if (!loaded) {
      const options = {hideImageModels: true, loadTexture: true};
      await caplaEditor?.editor3d.loader.loadInitialModel(zoneModel, options);
    }
    const updatedModel = {...zoneModel, hidden: !zoneModel?.hidden};
    dispatch(updateModel({updatedModel, sync: false}));
    const entity = caplaEditor?.editor3d.getEntity(zoneModel.id);
    if (zoneModel?.hidden) {
      entity?.show();
      dispatch(addZoneToBuffer(zone));
    } else {
      entity?.hide();
      if (!useBuffer) dispatch(removeZoneFromBuffer(zone));
    }
    dispatch(setShortcutToolViewer3D("ZONES"));
    dispatch(setUseBufferInShortcut(true));
  }

  return (
    <Box
      sx={{
        p: 0,
        width: 1,
        display,
        flexDirection: "column",
      }}
    >
      <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
        {title}
      </Typography>
      <List dense disablePadding >
        {zones.map((zone) => {
          const zoneModel = models.find((m) => m.id === zone.imageModelId);
          const loaded = modelsWithInitialTexture.includes(zone.imageModelId);
          const selected = loaded && zoneModel?.hidden === false;
          return (
            <ListItem
              key={zone.id}
              disablePadding
            >
              <ListItemButton
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "stretch",
                  alignItems: "center",
                  py: 0,
                }}
                onClick={() => handleZoneVisibility(zone, zoneModel)}
                disableGutters
                divider={true}
              >
                {!selected && (
                  <VisibilityOff
                    fontSize="small"
                    sx={{color: "text.secondary"}}
                  />
                )}
                {selected && (
                  <Visibility
                    fontSize="small"
                    sx={{color: "text.primary"}}
                  />
                )}
                <ListItemText sx={{pl: 1}}>
                  <Typography variant="body2">
                    {zone.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
