import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getShareLink} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useShareLink({scene, propDependency}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  useEffect(() => {
    if (accessToken && scene?.id && !scene?.shareLink) {
      dispatch(getShareLink({scene, accessToken}));
    }
  }, [accessToken, scene?.id, scene?.shareLink, propDependency]);

  return scene?.shareLink;
}
