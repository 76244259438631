import useProgressRecordsByScene from "./useProgressRecordsByScene";
import {useSelector} from "react-redux";

export default function useSelectedProgressRecord(scene) {
  const selectedProgressRecordId = useSelector(
    (s) => s.progress.selectedProgressRecordId
  );
  const progressRecords = useProgressRecordsByScene(scene);

  return progressRecords.find((r) => r.id === selectedProgressRecordId);
}
