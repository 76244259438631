import React from "react";

import {Box, Paper, Typography, IconButton} from "@mui/material";
import {Category, PushPin} from "@mui/icons-material";

export default function CardModelName({model, pin, onPinChange}) {
  // string

  const helperString = "💪 Click on a model in the 3D scene.";

  // helpers

  const nameString = model ? model.name : helperString;

  // handlers

  function handlePinClick() {
    onPinChange(!pin);
  }

  return (
    <Paper
      sx={{
        p: 1,
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        maxWidth: "200px",
      }}
    >
      <Category sx={{mr: 1, fontSize: 14}} color="action" />
      <Typography sx={{fontSize: 13}} noWrap>
        {nameString}
      </Typography>
      <IconButton onClick={handlePinClick}>
        <PushPin
          sx={{
            fontSize: 13,
            transform: !pin ? "rotate(20deg)" : "unset",
            color: pin ? "black" : "action",
          }}
        />
      </IconButton>
    </Paper>
  );
}
