import {useSelector, useDispatch} from "react-redux";

import {Box, Typography} from "@mui/material";

import FormRessource from "./FormRessource";
import SectionRessourceExtractedText from "./SectionRessourceExtractedText";
import useSelectedRessource from "../hooks/useSelectedRessource";
import {
  setEditedRessource,
  setIsEditingRessource,
  setNewRessource,
  setTempRessourceTypes,
} from "../ressourcesSlice";
// import useRessourcesAndTypesByScene from "../hooks/useRessourcesAndTypesByScene";

import EditableImage from "Features/images/components/EditableImage";

export default function SectionRessourceInSelectionPanel({scene, editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const imageS = "Image";

  // data

  const selectedFormGroup = useSelector((s) => s.ressources.selectedFormGroup);
  const selectedRessource = useSelectedRessource(scene);
  const isEditingRessource = useSelector(
    (s) => s.ressources.isEditingRessource
  );
  const editedRessource = useSelector((s) => s.ressources.editedRessource);
  const selectedGroup = useSelector((s) => s.ressources.selectedRessourceGroup);

  // const ressourcesAndTypes = useRessourcesAndTypesByScene(scene);

  console.log("editedRessource91", editedRessource);

  let newRessource = useSelector((s) => s.ressources.newRessource);
  newRessource = {...newRessource};
  newRessource.group = selectedGroup;

  // helper

  const ressource = isEditingRessource ? editedRessource : selectedRessource;

  console.log("ressourceXXX", ressource);

  // handlers

  function handleRessourceChange(ressource) {
    if (!isEditingRessource) {
      dispatch(setIsEditingRessource(true));
      // // dispatch(setEditedRessource({...selectedRessource}));
      // const ressourceAndTypes = ressourcesAndTypes.find(
      //   (r) => r.id === ressource.id
      // );
      // let types = ressourceAndTypes.types ? ressourceAndTypes.types : [];
      // types = types.map((t) => ({
      //   ...t,
      //   resId: ressource.id,
      //   status: "INIT",
      // }));
      // dispatch(setTempRessourceTypes(types));
    }
    dispatch(setEditedRessource(ressource));
  }

  function handleNewRessourceChange(ressource) {
    dispatch(setNewRessource(ressource));
    console.log("newRessource", ressource);
  }

  function handleRessourceImageUrlChange(imageUrl) {
    console.log("imageUrl", imageUrl);
    dispatch(setEditedRessource({...ressource, imageUrl}));
  }

  return (
    <Box sx={{width: 1}}>
      {selectedRessource ? (
        <>
          <SectionRessourceExtractedText
            ressource={ressource}
            editorPdf={editorPdf}
            scene={scene}
          />
          <FormRessource
            scene={scene}
            ressource={ressource}
            onChange={handleRessourceChange}
            canEdit={true}
          />
          {selectedFormGroup === "INFO" && (
            <Box
              sx={{
                width: 1,
                height: 160,
                //bgcolor: "background.default",
                display: "flex",
                flexDirection: "column",
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  px: 1,
                  bgcolor: "background.default",
                }}
              >
                <Typography sx={{fontSize: 13}} noWrap>
                  {imageS}
                </Typography>
              </Box>
              <EditableImage
                url={ressource?.imageUrl}
                onChange={handleRessourceImageUrlChange}
                container="scene-files"
                sceneId={scene?.id}
                readOnly={!isEditingRessource}
              />
            </Box>
          )}
        </>
      ) : (
        <FormRessource
          scene={scene}
          ressource={newRessource}
          onChange={handleNewRessourceChange}
          canEdit={true}
        />
      )}
    </Box>
  );
}
