export default function translateAndScalePath(
  path,
  pointRefOld,
  pointRefNew,
  scale
) {
  const newPath = path.map((point) => {
    const [oldPointX, oldPointY] = point;
    const newPointX = (oldPointX - pointRefOld[0]) * scale + pointRefNew[0];
    const newPointY = (oldPointY - pointRefOld[1]) * scale + pointRefNew[1];

    return [newPointX, newPointY];
  });

  return newPath;
}
