import {useState} from "react";
import {useDispatch} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogCopyMeasurements from "./DialogCopyMeasurements";
import {
  updateTempMeasurement,
  // setSelectedMeasurementId,
  setSelectedElementTypeId,
  // deleteMeasurement,
  setIsEditingMeasurement,
  setSelectedMeasurementIds,
} from "../measurementsSlice";
import useSelectedMeasurement from "../useSelectedMeasurement";
import useTempMeasurement from "../useTempMeasurement";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import DialogUpdateMeasurementRessources from "./DialogUpdateMeasurementRessources";
import useSceneModule from "Features/navigation/useSceneModule";

export default function ButtonMoreActionsMeasurement({
  scene,
  caplaEditor,
  selection,
}) {
  const dispatch = useDispatch();

  // strings

  const deleteS = "Delete";
  const copyTypeS = "Copy type";
  const copyElementS = "Copy element";
  const resetS = "Reset to type";
  const focusS = "See in PDF";
  const updateRessourcesS = "Update articles";

  // data

  const sceneModule = useSceneModule();
  const selectedMeasurement = useSelectedMeasurement();
  const tempMeasurement = useTempMeasurement({
    editorPdf: caplaEditor?.editorPdf,
    scene,
  });
  const materials = useMaterialsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openUpdateRessources, setopenUpdateRessources] = useState(false);

  // helpers

  const mnger =
    caplaEditor?.editorPdf?.annotationsManager?.measurementsPdfManager;

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);

    mnger.deleteMeasurement(selectedMeasurement.id);
    mnger.setIsEditing(false);

    const entity = caplaEditor?.editor3d?.getEntity(
      selectedMeasurement.measurementsModelId
    );
    entity?.deleteMeasurement(selectedMeasurement.id);

    // dispatch(deleteMeasurement(selectedMeasurement.id));
    caplaEditor?.measDataManager.deleteMeasurements([selectedMeasurement]);
    dispatch(setIsEditingMeasurement(false));
    dispatch(setSelectedMeasurementIds([]));
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - handlers
  function handleCopyType() {
    const material = materials.find(
      (m) => m.id === selectedMeasurement.materialId
    );
    const newM = {
      ...selectedMeasurement,
      material,
      sector: selectedMeasurement.sectorId
        ? sectors.find((r) => r.id === selectedMeasurement.sectorId)
        : null,
      room: selectedMeasurement.roomId
        ? rooms.find((r) => r.id === selectedMeasurement.roomId)
        : null,
      color: material ? material.color : selectedMeasurement.color,
    };

    if (newM.voids) delete newM.voids;
    dispatch(updateTempMeasurement(newM));
    dispatch(setSelectedMeasurementIds([]));
    dispatch(setSelectedElementTypeId(selectedMeasurement.elementTypeId));
    setAnchorEl(null);
  }

  function handleCopyElement() {
    setOpenCopyDialog(true);
    setAnchorEl(null);
  }

  function handleCloseCopyDialog() {
    setOpenCopyDialog(false);
  }

  function handleUpdateRessources() {
    setopenUpdateRessources(true);
    setAnchorEl(null);
  }

  function handleCloseUpdateRessourcesDialog() {
    setopenUpdateRessources(false);
  }

  // helper - reset handler

  function handleReset() {
    dispatch(
      updateTempMeasurement({
        ...tempMeasurement,
        ...tempMeasurement.elementType,
      })
    );
    setAnchorEl(null);
  }

  // helper - focus

  function handleFocus() {
    mnger.jumpToAnnotationId(selectedMeasurement.id, {fitToView: true});
    mnger.highlightAnnotation(selectedMeasurement.id);
  }

  // helpers - tools

  const resetTool = {
    id: "RESET",
    label: resetS,
    handler: handleReset,
  };
  let editTools = [
    {
      id: "FOCUS",
      label: focusS,
      handler: handleFocus,
    },
    {
      id: "COPY_ELEMENT",
      label: copyElementS,
      handler: handleCopyElement,
    },
    {
      id: "COPY_TYPE",
      label: copyTypeS,
      handler: handleCopyType,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
    {
      id: "UPDATE_RESSOURCES",
      label: updateRessourcesS,
      handler: handleUpdateRessources,
    },
  ];

  if (sceneModule === "MEASUREMENTS") {
    editTools = [
      {
        id: "DELETE",
        label: deleteS,
        handler: handleDelete,
      },
    ];
  }

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {selection?.type === "TEMP_MEASUREMENT" && (
          <MenuItem key={resetTool.id} onClick={resetTool.handler}>
            <Typography variant="body2">{resetTool.label}</Typography>
          </MenuItem>
        )}
        {selection?.type !== "TEMP_MEASUREMENT" &&
          editTools.map((tool) => {
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
      <DialogCopyMeasurements
        scene={scene}
        open={openCopyDialog}
        onClose={handleCloseCopyDialog}
        measurements={[selectedMeasurement]}
      />
      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"measurement"}
      />
      <DialogUpdateMeasurementRessources
        open={openUpdateRessources}
        scene={scene}
        measurement={selectedMeasurement}
        onClose={handleCloseUpdateRessourcesDialog}
        caplaEditor={caplaEditor}
      />
    </>
  );
}
