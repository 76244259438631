import {useDispatch, useSelector} from "react-redux";

import {Typography, Paper, Grid, Box} from "@mui/material";
import {PriorityHigh, PictureAsPdf as Pdf} from "@mui/icons-material";

import ButtonClearIssueSelectionUi from "./ButtonClearIssueSelectionUi";

import {updateMeasurementsShortcuts} from "Features/measurements/measurementsSlice";
import {
  updateModel,
  setSelectedPdfModelId,
} from "Features/viewer3D/viewer3DSlice";
import {setSelectedIssueId} from "Features/ui/uiSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";

export default function ImageListIssues({issues, caplaEditor, scene}) {
  const dispatch = useDispatch();

  // data

  const selectedIssueId = useSelector((s) => s.ui.selectedIssueId);
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.enabled
  );
  const mobile = useSelector((s) => s.ui.mobile);

  // handlers

  function handleClick(issue) {
    console.log("clickedissue", issue);

    if (issue?.id) dispatch(setOpenSelectionPanel(true));

    const selected = issue.id === selectedIssueId;
    let issuePdfModel;
    let modelId = issue?.context?.pdfContext?.modelId;
    if (modelId) issuePdfModel = models.find((m) => m.id === modelId);

    let newSelection = false;
    if (selected) {
      dispatch(setSelectedIssueId(issue.id));
    } else {
      dispatch(setSelectedIssueId(issue.id));
      newSelection = true;
    }

    if (
      (issue.fromViewMode === "2D" || issue.fromViewMode === "3D") &&
      newSelection
    ) {
      //editor3d?.imageOverlay.setImage(issue.imageUrl);
      //editor3d?.imageOverlay.show();

      const camera = issue.context?.camera;
      if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);

      // measurements filters
      dispatch(
        updateMeasurementsShortcuts(
          issue.context?.sceneContext?.measurementsFilters
        )
      );

      // models
      const ids = issue.context?.sceneContext?.visibleModelsIds;
      if (ids) {
        models.forEach((model) => {
          let updatedModel = {...model, hidden: true};
          if (ids.includes(model?.id)) updatedModel.hidden = false;
          dispatch(
            updateModel({
              updatedModel,
              sync: false,
              updateModelInStore: false,
            })
          );
        });
      }
    } else if (
      //newSelection &&
      issue.fromViewMode === "PDF" &&
      issuePdfModel?.url &&
      caplaEditor?.editorPdf &&
      issuePdfModel?.id
      //issuePdfModel?.id === selectedModelId
    ) {
      caplaEditor?.editorPdf.setPdfContext(issue.context.pdfContext);

      dispatch(setSelectedPdfModelId(issuePdfModel?.id));

      // measurements filters
      dispatch(
        updateMeasurementsShortcuts(
          issue.context?.pdfContext?.measurementsFilters
        )
      );
    }
  }
  return (
    <Box sx={{width: 1}}>
      <Box sx={{height: 30}}>
        {selectedIssueId && <ButtonClearIssueSelectionUi />}
      </Box>
      <Grid sx={{width: 1, pt: 2, pl: 2}} container spacing={3}>
        {issues.map((issue) => {
          const url = issue.imageUrl;
          const selected = selectedIssueId === issue.id;
          const isFromPdf = issue?.context?.pdfContext?.modelId;
          return (
            <Grid item xs={mobile ? 12 : 4} key={issue.id}>
              <Paper
                elevation={selected ? 12 : 3}
                sx={{
                  minWidth: 0,
                  borderRadius: "9px",
                  border: selected
                    ? (theme) => `1px solid ${theme.palette.primary.flash}`
                    : "unset",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    bgcolor: "common.white",
                    minWidth: 0,
                    borderRadius: "8px",
                    position: "relative",
                  }}
                  onClick={(e) => handleClick(issue)}
                >
                  <Box
                    sx={{
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      width: "100%",
                      height: "140px",
                      position: "relative",
                      backgroundImage: `url(${url})`,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRadius: "8px 8px 0 0",
                      position: "relative !important",
                    }}
                  />
                  {isFromPdf && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                      }}
                    >
                      <Pdf fontSize="small" color="action" />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: 1,
                      height: 54,
                      p: 1,
                      bgcolor: "common.darkGrey",
                      color: "warning.main",
                      borderRadius: "0 0 8px 8px",
                      position: "relative",
                    }}
                  >
                    {issue.priority === "HIGH" && (
                      <PriorityHigh
                        sx={{position: "absolute", left: "-3px"}}
                        fontSize="small"
                        color="inherit"
                      />
                    )}
                    <Typography
                      variant="body2"
                      sx={{ml: 1, color: "common.white", fontSize: 13}}
                    >
                      {issue.title}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
