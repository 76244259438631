import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  setEditedRelations,
  setRelationsTypesRessources,
  setRelationsTypesRessourcesToRestore,
  setSelectedRessourcesGroupForList,
  setSelectedTypesGroupForList,
} from "../relationsSlice";

// import Stretch from "Features/ui/components/Stretch";
import IconElementType from "Features/elementTypes/components/IconElementType";

const formulMap = {
  UN: "C",
  M: "L",
  M2: "S",
  M3: "V",
};

export default function SectionListAffectRelations({
  module,
  groups,
  countByGroup,
  selectedGroup,
  item,
  items,
  relations,
  itemRelationsSet,
  onChange,
}) {
  const dispatch = useDispatch();

  // data

  const relationsToRestore = useSelector(
    (s) => s.relations.relationsTypesRessourcesToRestore
  );
  const editedRelations = useSelector((s) => s.relations.editedRelations);

  // helpers

  const styleMap = {
    DEFAULT: {fontWeight: "normal", bgcolor: "inherit", color: "inherit"},
    TITLE1: {fontWeight: "bold", bgcolor: "#bbbbc0", color: "text.primary"},
    TITLE2: {
      fontWeight: "bold",
      bgcolor: "background.default",
      color: "text.primary",
    },
    DETAIL: {
      fontWeight: "normal",
      bgcolor: "common.white",
      color: "text.secondary",
    },
  };

  // handlers

  const handleGroupClick = (group) => {
    if (module === "ELEMENT_TYPES")
      dispatch(setSelectedTypesGroupForList(group));
    else dispatch(setSelectedRessourcesGroupForList(group));
  };

  const handleToggle = (it) => {
    if (!relationsToRestore)
      dispatch(setRelationsTypesRessourcesToRestore(relations));
    const relationsToEdit = {...editedRelations};
    const updatedRelations = {...relations};
    if (!itemRelationsSet.has(it.id)) {
      itemRelationsSet.add(it.id);
      if (module === "ELEMENT_TYPES") {
        const relKey = `${item.id}-${it.id}`;
        updatedRelations[relKey] = {
          id: relKey,
          typeId: item.id,
          resId: it.id,
          mode: "FORMUL",
          func: formulMap[item.unit],
        };
        relationsToEdit[relKey] = "CREATE";
      } else if (module === "RESSOURCES") {
        const relKey = `${it.id}-${item.id}`;
        updatedRelations[relKey] = {
          id: relKey,
          typeId: it.id,
          resId: item.id,
          mode: "FORMUL",
          func: formulMap[it.unit],
        };
        relationsToEdit[relKey] = "CREATE";
      }
    } else if (itemRelationsSet.has(it.id)) {
      itemRelationsSet.delete(it.id);
      if (module === "ELEMENT_TYPES") {
        delete updatedRelations[`${item.id}-${it.id}`];
        relationsToEdit[`${item.id}-${it.id}`] = "DELETE";
      } else if (module === "RESSOURCES") {
        delete updatedRelations[`${it.id}-${item.id}`];
        relationsToEdit[`${it.id}-${item.id}`] = "DELETE";
      }
    }
    dispatch(setEditedRelations(relationsToEdit));
    dispatch(setRelationsTypesRessources(updatedRelations));
    onChange();
  };

  // effect

  useEffect(() => {
    if (module === "RESSOURCES" && groups.length > 0 && !selectedGroup)
      dispatch(setSelectedRessourcesGroupForList(groups[0]));
    if (module === "ELEMENT_TYPES" && groups.length > 0 && !selectedGroup)
      dispatch(setSelectedTypesGroupForList(groups[0]));
  }, [module, groups.length, selectedGroup]);

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <Box
        sx={{
          color: "text.secondary",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          px: 1,
          pt: 2,
          "&>*:not(:last-child)": {mr: 1},
          maxHeight: 150,
          overflow: "auto",
        }}
      >
        {groups.map((group) => {
          return (
            <Chip
              size="small"
              key={group.id}
              label={`${group.name} (${countByGroup[group.id]})`}
              variant={group.id === selectedGroup?.id ? "filled" : "outlined"}
              onClick={() => handleGroupClick(group)}
              sx={{mb: 1}}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          width: 1,
          //height: 1,
          //maxHeight: 1,
          overflowY: "auto",
          display: items?.length > 0 ? "flex" : "none",
          flexDirection: "column",
          overflow: "auto",
          bgcolor: "common.white",
          mt: 2,
        }}
      >
        <List dense disablePadding sx={{pb: "52px"}}>
          {items.map((it) => {
            let styleType = "DEFAULT";
            if (it.isTitle && it.rank === 1 && it.style === undefined)
              styleType = "TITLE1";
            if (it.isTitle && it.rank === 2 && it.style === undefined)
              styleType = "TITLE2";
            if (it.style === "title1") styleType = "TITLE1";
            if (it.style === "title2") styleType = "TITLE2";
            if (it.style === "detail") styleType = "DETAIL";
            const {fontWeight, bgcolor, color} = styleMap[styleType];
            return (
              <ListItem
                key={it.id}
                disablePadding
                secondaryAction={
                  !it.isTitle && (
                    <Checkbox
                      edge="end"
                      onChange={() => handleToggle(it)}
                      checked={itemRelationsSet.has(it.id)}
                    />
                  )
                }
              >
                <ListItemButton
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "stretch",
                    px: 1,
                    py: 0,
                    bgcolor,
                    color,
                  }}
                  selected={itemRelationsSet.has(it.id)}
                  onClick={() => handleToggle(it)}
                  disableGutters
                  divider={true}
                >
                  {module === "RESSOURCES" && !it.isTitle && (
                    <Box sx={{pr: 1}}>
                      <IconElementType
                        color={it.color}
                        drawingShape={it.drawingShape}
                        size={"small"}
                      />
                    </Box>
                  )}
                  <ListItemText>
                    <Typography sx={{fontWeight, fontSize: 12}}>
                      {it.num?.length > 0 && !it.hideNum
                        ? `${it.num} • ${it.name}`
                        : it.name}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
