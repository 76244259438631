const applyStyle = (row, i, isTitle, rank, style) => {
  if ((isTitle && style === undefined) || ["title1", "title2"].includes(style)) {
    row.getCell(i).font = {bold: true};
    if (rank === 1 || style === "title1")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'bbbbc0'}
      };
    if (rank === 2 || style === "title2")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F4F4F8'}
      };
  }
  if (style === "detail")
    row.getCell(i).font = {color: {argb: "777777"}};
};

export default function fillWorksheetWithRessourcesPU({
  worksheet,
  data,
}) {
  const rowStartIndex = 3;
  const filledNums = new Set();
  worksheet.getRow(1).getCell(1).value = data.title;
  data.ressources.forEach(({
    num,
    name,
    unit,
    isTitle,
    style,
  }, index) => {
    const row = worksheet.getRow(rowStartIndex + index);
    const rank = num ? num.split(".").length : null;
    for (let i = 1; i <= 4; i++) {
      applyStyle(row, i, isTitle, rank, style);
    }
    row.getCell(1).value = num;
    row.getCell(2).value = name;
    row.getCell(3).value = unit;
    if (filledNums.has(num))
      row.getCell(1).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'FF0000'}
      };
    filledNums.add(num);
  })
}