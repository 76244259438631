import {format} from "date-fns";

import {Box, Typography, Paper} from "@mui/material";

import ImageScene from "./ImageScene";
import ConnectButton from "./ConnectButton";
import FieldSceneProdStatus from "./FieldSceneProdStatus";
import ButtonOpenScene from "./ButtonOpenScene";
import SectionSceneOnboardingVideo from "./SectionSceneOnboardingVideo";

import usePageType from "Features/navigation/usePageType";
import ButtonDialogShare from "Features/share/components/ButtonDialogShare";

export default function SectionSelectedScene({viewer, selectedScene}) {
  // strings

  // const operationS = "Opération";
  // const tradesS = "Lots & Corps d'état";
  // const buildingsS = "Bâtiments / Ouvrages";
  const productionS = "Production de la Bimbox";
  const prodDeadlineS = "Besoin pour le :";
  // const emptyS = "Non renseigné";
  const contactS =
    "A contacter pour toute information relative à cette bimbox :";

  // data

  const scene = selectedScene;
  const pageType = usePageType();

  // helpers
  let sceneDeadline = "";
  if (scene?.prodDeadline) {
    const d = new Date(scene?.prodDeadline);
    sceneDeadline = format(d, "dd/MM/yyyy");
  }

  return (
    <Box sx={{width: 1, height: 1, position: "relative"}}>
      <Box sx={{width: 1, height: 200, position: "relative"}}>
        <ImageScene scene={scene} />
        {!viewer && (
          <Box
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: "16px",
            }}
          >
            {pageType === "HOME" && <ButtonOpenScene />}
            {pageType !== "HOME" && scene?.id && (
              <ButtonDialogShare scene={scene} />
            )}
            {pageType !== "HOME" && !viewer && !scene?.id && <ConnectButton />}
          </Box>
        )}
      </Box>
      {/* <Box sx={{p: 1, display: "flex", alignItems: "baseline"}}>
        <Typography variant="h6">{scene?.title}</Typography>
        {scene?.nextSync?.action && <Typography sx={{ml: 1}}>...</Typography>}
      </Box> */}
      {scene?.description && (
        <Box sx={{p: 2, mb: 1}}>
          <Typography sx={{fontSize: 13, color: "text.secondary"}}>
            {scene?.description}
          </Typography>
        </Box>
      )}

      <Box sx={{p: 2}}>
        {(scene?.operationName ||
          scene?.tradesName ||
          scene?.buildingsName) && (
          <Box
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              border: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {scene?.operationName && (
              <Typography variant="body1">{scene?.operationName}</Typography>
            )}

            {scene?.tradesName && (
              <Typography sx={{mt: 2, fontWeight: "bold"}} variant="body2">
                {scene?.tradesName}
              </Typography>
            )}

            {scene?.buildingsName && (
              <Typography sx={{mt: 2}} variant="body2" color="text.secondary">
                {scene?.buildingsName}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {scene?.onboardingUrl && (
        <Box sx={{width: 1, height: 240, p: 2}}>
          <Paper elevation={12} sx={{widht: 1, height: 1}}>
            <SectionSceneOnboardingVideo onboardingUrl={scene?.onboardingUrl} />
          </Paper>
        </Box>
      )}

      {!viewer && Boolean(sceneDeadline) && (
        <Box sx={{p: 1}}>
          <Box sx={{p: 1, bgcolor: "background.default"}}>
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {productionS}
            </Typography>
            <Box sx={{display: "flex", alignItems: "baseline"}}>
              <Typography variant="body2">{prodDeadlineS}</Typography>
              <Typography variant="body2" sx={{ml: 1}}>
                {sceneDeadline}
              </Typography>
            </Box>
            <Box
              sx={{
                width: 1,
                p: 1,
                display: "flex",
                justifyContent: "center",
                mt: 2,
              }}
            >
              <FieldSceneProdStatus value={scene.prodStatus} readOnly={true} />
            </Box>
          </Box>
        </Box>
      )}
      {scene?.contactEmail && (
        <Box sx={{width: 1, p: 2}}>
          <Box sx={{p: 1, bgcolor: "background.default"}}>
            <Typography sx={{fontSize: 13, color: "text.secondary"}}>
              {contactS}
            </Typography>
            <Typography sx={{mt: 1, color: "text.secondary", fontSize: 13}}>
              {scene.contactEmail}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
