import React from "react";

import useListingtypes from "Features/listingtypes/useListingtypes";

import {TextField, Typography} from "@mui/material";

export default function MetaDataForm({type, metaData, onChange}) {
  // data

  const metaDataObject = metaData ? JSON.parse(metaData) : {};
  const metaFields = type?.metaFields;

  // handlers

  function handleFieldChange(e, id, type) {
    const value = type === "int" ? parseInt(e.target.value) : e.target.value;
    const newObject = {[id]: value};
    const newMetaDataObject = {...metaDataObject, ...newObject};
    const newMetaData = JSON.stringify(newMetaDataObject);
    console.log(newMetaData);
    onChange(newMetaData);
  }

  return (
    <div>
      <Typography variant="body2">Meta Data</Typography>
      {metaFields?.map(({id, name, type}) => {
        return (
          <TextField
            key={id}
            value={metaDataObject[id] ? metaDataObject[id] : ""}
            name={id}
            onChange={(e) => handleFieldChange(e, id, type)}
            label={name}
            variant="outlined"
            size="small"
          />
        );
      })}
    </div>
  );
}
