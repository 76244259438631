import {useDispatch} from "react-redux";
import {updateElementTypesGroup} from "../elementTypesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useUpdateElementTypesGroup() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const updateGroup = async (elementTypesGroup) => {
    await dispatch(updateElementTypesGroup({elementTypesGroup, accessToken}));
  };

  return updateGroup;
}
