import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ToolIfcs from "./ToolIfcs";
import ToolMarkers from "./ToolMarkers";
import ToolCameras from "./ToolCameras";
import ToolImages from "./ToolImages";
import ToolSettings from "./ToolSettings";
import ToolMeasure from "./ToolMeasure/ToolMeasure";
import ToolClipping from "./ToolClipping";
import ToolPosition from "./ToolPosition";
import ToolModels from "./ToolModels";
import ToolCapla from "./ToolCapla";
import ToolDebug from "./ToolDebug";
import ToolRotation from "./ToolRotation";
import ToolDifference from "./ToolDifference";
import ToolHide from "./ToolHide";
import ToolPositionConfig from "./ToolPositionConfig";
import ToolOneMarkerConfig from "./ToolOneMarkerConfig";
import ToolTwoMarkersConfig from "./ToolTwoMarkersConfig";
import ToolRotationConfig from "./ToolRotationConfig";
import ToolConfigScale from "./ToolConfigScale";
import ToolCut from "./ToolCut";
import ToolAddImagePart from "./ToolAddImagePart";
import ToolFilter from "./ToolFilter";
import ToolTree from "./ToolTree";
import ToolConfigHelp from "./ToolConfigHelp";

export default function ToolContainer({
  tool,
  toolConfig,
  caplaEditor,
  onClose,
  scene,
}) {
  // data

  const configCenter = useSelector((state) => state.viewer3D.configCenter);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {!configCenter && (
        <Box>
          {tool === "CAMERAS" && <ToolCameras editor3d={caplaEditor.editor3d} />}
          {tool === "IFC" && <ToolIfcs editor3d={caplaEditor.editor3d} />}
          {tool === "MARKERS" && <ToolMarkers editor3d={caplaEditor.editor3d} scene={scene} />}
          {tool === "IMAGES" && <ToolImages editor3d={caplaEditor.editor3d} />}
          {tool === "SETTINGS" && <ToolSettings editor3d={caplaEditor.editor3d} />}
          {tool === "MEASURE" && (
            <ToolMeasure editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
          {tool === "CLIPPING" && (
            <ToolClipping editor3d={caplaEditor.editor3d} onClose={onClose} scene={scene} />
          )}
          {tool === "POSITION" && (
            <ToolPosition caplaEditor={caplaEditor} onClose={onClose} scene={scene} />
          )}
          {tool === "MODELS" && (
            <ToolModels editor3d={caplaEditor.editor3d} onClose={onClose} scene={scene} />
          )}
          {tool === "CAPLA" && <ToolCapla editor3d={caplaEditor.editor3d} />}

          {tool === "FILTER" && (
            <ToolFilter editor3d={caplaEditor.editor3d} />
          )}
          {tool === "HIDE" && <ToolHide editor3d={caplaEditor.editor3d} onClose={onClose} />}
          {tool === "TREE" && (
            <ToolTree editor3d={caplaEditor.editor3d} scene={scene} />
          )}
          {tool === "DEBUG" && <ToolDebug editor3d={caplaEditor.editor3d} />}
          {tool === "ROTATION" && (
            <ToolRotation caplaEditor={caplaEditor} onClose={onClose} />
          )}
          {tool === "DIFFERENCE" && (
            <ToolDifference editor3d={caplaEditor.editor3d} scene={scene} />
          )}
        </Box>
      )}
      {configCenter && (
        <Box>
          {toolConfig === "POSITION_CONFIG" && (
            <ToolPositionConfig editor3d={caplaEditor.editor3d} />
          )}
          {toolConfig === "SCALE" && (
            <ToolConfigScale editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
          {toolConfig === "ROTATION_CONFIG" && (
            <ToolRotationConfig onClose={onClose} />
          )}
          {toolConfig === "ONE_MARKER_CONFIG" && (
            <ToolOneMarkerConfig editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
          {toolConfig === "TWO_MARKERS_CONFIG" && (
            <ToolTwoMarkersConfig editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
          {toolConfig === "CUT" && <ToolCut editor3d={caplaEditor.editor3d} />}
          {toolConfig === "HELP" && <ToolConfigHelp />}
          {toolConfig === "DRAWING_PART" && (
            <ToolAddImagePart editor3d={caplaEditor.editor3d} onClose={onClose} />
          )}
        </Box>
      )}
    </Box>
  );
}
