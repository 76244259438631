import {useDispatch, useSelector} from "react-redux";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function useUpdatePdfZone() {
  const dispatch = useDispatch();
  const models = useSelector((s) => s.viewer3D.models);
  const updatePdfZone = (updatedZone) => {
    const pdfModel = models.find((m) => m.id === updatedZone.pdfModelId);

    const zone = {...updatedZone};

    delete zone.pdfModelId;
    delete zone.pdfModelName;
    delete zone.sectorCode;
    delete zone.zForSorting;
    delete zone.imageUrl;
    delete zone.imageModelName;

    const newZones = pdfModel.zones.map((z) => {
      if (z.id === updatedZone.id) {
        return zone;
      } else {
        return z;
      }
    });

    const updatedModel = {...pdfModel, zones: newZones};

    dispatch(updateModel({updatedModel}));
  };

  return updatePdfZone;
}
