import {useSelector, useDispatch} from "react-redux";
import {useState, useEffect} from "react";
import {unwrapResult} from "@reduxjs/toolkit";
import {fetchStoredObjectsets} from "./objectsetsSlice";

export default function useObjectsets({sceneClientId}) {
  const dispatch = useDispatch();

  // data

  const allObjectsets = useSelector((state) => state.objectsets.items);
  const objectsets = allObjectsets.filter(
    (o) => o.sceneClientId === sceneClientId
  );
  const fetchedStoredScenes = useSelector(
    (state) => state.objectsets.fetchedStoredScenes
  );

  // state

  const [objectsetsState, setObjectsetsState] = useState(objectsets);

  // effects

  async function getStoredObjectsets({sceneClientId}) {
    const result = await dispatch(fetchStoredObjectsets({sceneClientId}));
    const o = unwrapResult(result);
    setObjectsetsState(o.objectsets);
  }

  useEffect(() => {
    if (sceneClientId && !fetchedStoredScenes.includes(sceneClientId)) {
      getStoredObjectsets({sceneClientId});
    }
  }, [sceneClientId]);

  useEffect(() => {
    console.log("use effect");
    setObjectsetsState(objectsets);
  }, [sceneClientId, objectsets?.length]);

  // return

  return objectsetsState;
}
