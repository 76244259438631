import {useSelector, useDispatch} from "react-redux";

import {Box, TextField} from "@mui/material";
import {setName, setNum} from "../elementTyporSlice";

export default function SectionName({}) {
  const dispatch = useDispatch();

  // strings

  const numS = "num";
  const nameS = "name";

  // data

  const num = useSelector((s) => s.elementTypor.num);
  const name = useSelector((s) => s.elementTypor.name);

  // handlers

  function handleNameChange(e) {
    const name = e.target.value;
    dispatch(setName(name));
  }

  function handleNumChange(e) {
    const num = e.target.value;
    dispatch(setNum(num));
  }

  return (
    <Box
      sx={{
        width: 1,
        flexGrow: 1,
        display: "flex",
        border: "1px solid red",
        justifyContent: "center",
        //alignItems: "center",
        pt: 12,
      }}
    >
      <Box>
        <Box sx={{display: "flex", alignItems: "center"}}>
          <TextField
            sx={{width: 80, mr: 2}}
            value={num}
            onChange={handleNumChange}
            label={numS}
          />
          <TextField value={name} onChange={handleNameChange} label={nameS} />
        </Box>
      </Box>
    </Box>
  );
}
