/*
 * Download a blob
 * (blob,fileName)
 */

import JSZip from "jszip";

export const saveBlob = (function () {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  return function saveData(blob, fileName) {
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  };
})();

export function blobToBase64(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
}

export async function urlToBlob(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
}

export async function urlToFile(url, name, options) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], name, options);
}

export async function unzip(f) {
  const zip = await JSZip.loadAsync(f);
  const files = [];
  zip.forEach((relativePath, file) => files.push(file));
  const name = files[0].name;
  const buffer = await files[0].async("arraybuffer");
  const blob = new Blob([buffer]);
  return new File([blob], name);
}

export async function unzipObjFile(f) {
  const zip = await JSZip.loadAsync(f);
  const files = [];
  const result = {};
  let fileMtl;
  let fileObj;
  zip.forEach((relativePath, file) => {
    if (file.unsafeOriginalName.endsWith(".mtl")) {
      fileMtl = file;
    }
    if (file.unsafeOriginalName.endsWith(".obj")) {
      fileObj = file;
    }
  });
  //
  result.mtlStr = await fileMtl.async("string");
  const nameMtl = fileMtl.name;
  const bufferMtl = await fileMtl.async("arraybuffer");
  const blobMtl = new Blob([bufferMtl]);
  result.fileMtl = new File([blobMtl], nameMtl);
  //
  const name = fileObj.name;
  const buffer = await fileObj.async("arraybuffer");
  const blob = new Blob([buffer]);
  result.fileObj = new File([blob], name);

  return result;
}

export function getFileSizeString(s) {
  if (!s || isNaN(s)) return "-";
  const size = (s / 1000000 < 1 ? s / 1000 : s / 1000000).toFixed(1);
  const sizeString = `${size} ${s / 1000000 < 1 ? "Ko" : "Mo"}`;
  return sizeString;
}

export function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });
}
