import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import useDrawingShapeImage from "../useDrawingShapeImage";

import {setSelectedMeasurementOverviewFormGroup} from "Features/leftPanel/leftPanelSlice";
import useKvtMeasurementOverview from "Features/kvform/hooks/useKvtMeasurementOverview";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";

export default function FormMeasurementOverview({
  scene,
  caplaEditor,
  measurement,
}) {
  const dispatch = useDispatch();
  // data

  const template = useKvtMeasurementOverview(scene);

  const group = useSelector(
    (s) => s.leftPanel.selectedMeasurementOverviewFormGroup
  );

  const drawingShape = useDrawingShapeImage(measurement?.drawingShape);

  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);

  // helpers

  const item = {
    ...measurement,
    helperImage: drawingShape,
    sector: sectors.find((s) => s.id === measurement?.sectorId),
    room: rooms.find((r) => r.id === measurement?.roomId),
  };

  if (item.voids?.length > 0) {
    template.fields = [
      ...template.fields.slice(0, 18),
      {
        group: "QUANTITIES",
        type: "section",
        key: "voids",
        name: "Quantités nettes",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "voidsCount",
        name: "Nombre de vides",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "lengthNet",
        name: "Longueur nette (m)",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "areaNet",
        name: "Aire nette (m²)",
      },
      {
        group: "QUANTITIES",
        type: "number",
        key: "volumeNet",
        name: "Volume net (m³)",
      },
      ...template.fields.slice(18),
    ];
  }
  if (item.p0)
    template.fields.push({
      group: "CATEGORIES",
      type: "text",
      key: "p0",
      name: "Mot clé",
    });
  if (item.p1)
    template.fields.push({
      group: "CATEGORIES",
      type: "text",
      key: "p1",
      name: "P1",
    });
  if (item.p2)
    template.fields.push({
      group: "CATEGORIES",
      type: "text",
      key: "p2",
      name: "P2",
    });
  if (item.p3)
    template.fields.push({
      group: "CATEGORIES",
      type: "text",
      key: "p3",
      name: "P3",
    });

  // handlers

  function handleGroupChange(group) {
    dispatch(setSelectedMeasurementOverviewFormGroup(group));
  }

  return (
    <Box sx={{width: 1}}>
      <KeyValueForm
        caplaEditor={caplaEditor}
        template={template}
        scene={scene}
        item={item}
        canEdit={false}
        group={group}
        onGroupChange={handleGroupChange}
        sizeKey={4}
      />
    </Box>
  );
}
