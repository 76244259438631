import React from "react";

import PropsOldNewTable from "Components/PropsOldNewTable";
export default function TableDifference({diff}) {
  let props = [];
  if (diff?.changes) {
    const changes = diff.changes;
    Object.keys(changes).forEach((change) => {
      const item = {
        key: change,
        valueOld: changes[change].old,
        valueNew: changes[change].new,
      };
      props.push(item);
    });
  }
  return <PropsOldNewTable props={props} />;
}
