import {useState} from "react";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

// import SectionMeasurementsModelsInScopeWithChips from "./SectionMeasurementsModelsInScopeWithChips";
// import SectionMeasurementsScope from "./SectionMeasurementsScope";
// import ToolbarMeasurementsMainInHome from "./ToolbarMeasurementsMainInHome";
import SectionDataGridToolbar from "./SectionDataGridToolbar";
import SectionDataGridMeasurements from "./SectionDataGridMeasurements";
// import SectionZonesInScopeWithChips from "./SectionZonesInScopeWithChips";
// import SectionSectorsInScopeWithChips from "./SectionSectorsInScopeWithChips";
// import SectionDetailedScope from "./SectionDetailedScope";
import SectionMeasurementsCountInScope from "./SectionMeasurementsCountInScope";
import SectionMeasurementsFilters from "./SectionMeasurementsFilters";
// import SelectorPackageInScope from "./SelectorPackageInScope";
import SectionSelectorMeasurementScope from "./SectionSelectorMeasurementScope";

// import useMeasurementsFiltersAndShortcuts from "../useMeasurementsFilters";
import useFilteredSceneMeasurements from "../useFilteredSceneMeasurements";
import useMeasurementsScopeMode from "../useMeasurementsScopeMode";

import Stretch from "Features/ui/components/Stretch";
// import ButtonFullScreenTable from "Features/ui/components/ButtonFullScreenTable";
// import SelectorSectorInScope from "Features/sectors/components/SelectorSectorInScope";
// import SelectorInputInScope from "Features/inputs/components/SelectorInputInScope";
import useIsMobile from "Features/ui/useIsMobile";

export default function SectionMeasurementsInHome({caplaEditor, scene, viewer}) {
  // const dispatch = useDispatch();

  // data
  const isMobile = useIsMobile();
  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms, fmsIds, fmsIdsHash] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    true
  );

  const fullscreenTable = useSelector((s) => s.ui.fullscreenTable);
  const os = useSelector((s) => s.ui.openSections);

  const w1 = useSelector((s) => s.viewer3D.viewerWidth);
  const w2 = useSelector((s) => s.viewer3D.fixedViewersBoxWidth);
  const open = useSelector((s) => s.navigation.open);

  // const oboInDatagrid = useSelector((s) => s.measurements.oboInDatagrid);

  const moreFilters = useSelector((s) => s.measurements.moreFilters);

  const scopeSelectionMode = useSelector(
    (s) => s.measurements.scopeSelectionMode
  );

  // state

  const [openFilters, setOpenFilters] = useState(false);

  // helpers - hash

  const h1 = fullscreenTable ? "1" : "0";
  const h2 = w1 + "-" + w2;
  const h3 = open ? "1" : "0";
  const h4 = moreFilters ? "1" : "0";
  const h5 = scopeSelectionMode;
  const h6 = openFilters ? "1" : "0";

  // helpers - isEditMode

  // const isEditMode = oboInDatagrid;

  return (
    <Box
      sx={{
        display: "flex",
        minWidth: 0,
        minHeight: 0,
        flexDirection: "column",
        width: 1,
      }}
    >
      <SectionMeasurementsCountInScope scene={scene} mode={mode} editor3d={caplaEditor?.editor3d} />

      <SectionSelectorMeasurementScope scene={scene} viewer={viewer} />

      {!isMobile && (
        <Box sx={{mt: 4}}>
          <SectionMeasurementsFilters
            scene={scene}
            caplaEditor={caplaEditor}
          />
        </Box>
      )}

      {/* {!fullscreenTable && os.outlet && (
        <ToolbarMeasurementsMainInHome
          scene={scene}
          caplaEditor={caplaEditor}
          showToggleViewMode={false}
          isEditMode={isEditMode}
          viewer={viewer}
        />
      )} */}
      {os.outlet && (
        <SectionDataGridToolbar editor3d={caplaEditor?.editor3d} scene={scene} viewer={viewer} />
      )}
      {os.outlet && (
        <Stretch propDependency={fmsIdsHash + h1 + h2 + h3 + h4 + h5 + h6}>
          <Box
            sx={{
              height: 1,
              width: 1,
              position: "relative",
              bgcolor: "common.white",
            }}
          >
            <SectionDataGridMeasurements
              caplaEditor={caplaEditor}
              scene={scene}
              mode={mode}
              withProgress={false}
            />
            {/* <Box sx={{position: "absolute", bottom: "16px", left: "16px"}}>
              <ButtonFullScreenTable />
            </Box> */}
          </Box>
        </Stretch>
      )}
    </Box>
  );
}
