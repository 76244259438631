import {Box} from "@mui/material";

import SectionImportElementTypeGroupFromExcel from "./SectionImportElementTypeGroupFromExcel";
import ButtonAddDefaultElementTypes from "./ButtonAddDefaultElementTypes";

export default function SectionStartElementTypeGroups({
  scene,
  onNewTypes,
  onAddDefault,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyItems: "stretch",
      }}
    >
      <Box sx={{py: 2}}>
        <ButtonAddDefaultElementTypes
          scene={scene}
          onNewTypes={onNewTypes}
          onAddDefault={onAddDefault}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyItems: "stretch",
          bgcolor: "common.white",
        }}
      >
        <SectionImportElementTypeGroupFromExcel
          scene={scene}
          onNewTypes={onNewTypes}
        />
      </Box>
    </Box>
  );
}
