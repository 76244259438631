import {useSelector} from "react-redux";

import useSceneModule from "Features/navigation/useSceneModule";

export default function useSelectedVersionForMeasurements() {
  const sceneModule = useSceneModule();
  let version = useSelector(
    (s) => s.versions.selectedVersionForMeasurements
  );

  if (["EDIT_MEASUREMENTS"].includes(sceneModule)) {
    version = null;
  }

  return version;
}
