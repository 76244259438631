import React from "react";

import {Autocomplete, TextField, Typography, Box} from "@mui/material";

import useListingTypes from "Features/listingtypes/useListingtypes";

export default function SelectorListingType({type, onChange}) {
  // strings

  const typeString = "Type";

  // data

  const types = useListingTypes();

  // handlers

  function handleChange(type) {
    onChange(type);
  }

  return (
    <Box sx={{width: 1}}>
      <Autocomplete
        sx={{flexGrow: 1}}
        options={types}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <Typography {...props} variant="body2" sx={{pl: 1}}>
            {option.name}
          </Typography>
        )}
        onChange={(e, v) => handleChange(v)}
        value={type}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={<Typography variant="body2">{typeString}</Typography>}
            InputProps={{
              ...params.InputProps,
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
          />
        )}
      />
    </Box>
  );
}
