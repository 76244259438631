import {Box} from "@mui/material";

import SelectorElementTypesGroupProxy from "./SelectorElementTypesGroupProxy";
import ButtonMoreElementTypesInLeftPanel from "./ButtonMoreElementTypesInLeftPanel";

export default function SectionElementTypesGroupProxy({scene, caplaEditor}) {
  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box>
        <SelectorElementTypesGroupProxy scene={scene} />
      </Box>
      <ButtonMoreElementTypesInLeftPanel scene={scene} caplaEditor={caplaEditor} />
    </Box>
  );
}
