import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {fetchSceneListings} from "Features/viewer3D/viewer3DSlice";

import {IconButton, CircularProgress} from "@mui/material";
import {Sync} from "@mui/icons-material";
import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonRefreshSceneListings({scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // state

  const [loading, setLoading] = useState(false);

  async function handleClick() {
    if (!loading && scene?.id && accessToken) {
      setLoading(true);
      const result = await dispatch(
        fetchSceneListings({accessToken, sceneId: scene.id})
      );
      console.log("scene listings", unwrapResult(result));
      setLoading(false);
    }
  }

  return (
    <IconButton size="small" onClick={handleClick}>
      {!loading ? <Sync fontSize="small" /> : <CircularProgress size={16} />}
    </IconButton>
  );
}
