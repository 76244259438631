import {createSlice} from "@reduxjs/toolkit";

export const materialsSlice = createSlice({
  name: "materials",
  initialState: {
    selectedMaterialId: null,
    isEditingMaterial: false,
    editedMaterial: {},
    newMaterial: {},
  },
  reducers: {
    setSelectedMaterialId: (state, action) => {
      state.selectedMaterialId = action.payload;
    },
    setIsEditingMaterial: (state, action) => {
      state.isEditingMaterial = action.payload;
    },
    setEditedMaterial: (state, action) => {
      state.editedMaterial = action.payload;
    },
    setNewMaterial: (state, action) => {
      state.newMaterial = action.payload;
    },
  },
});

export const {
  setSelectedMaterialId,
  setIsEditingMaterial,
  setEditedMaterial,
  setNewMaterial,
} = materialsSlice.actions;

export default materialsSlice.reducer;
