import {Object3D} from "three";

import SceneElement from "./SceneElement";

export default class ScenesManager {
  constructor({editor}) {
    this.editor = editor;
    this.scenesElements = [];
  }

  async loadScenes(scenes, models, focusedSceneId) {
    console.log("loadScenes & Models", scenes, models);
    // clear

    this.editor.disableAllEntities();

    // models

    await this.editor.multiScenesLoader.loadAsyncModelsV2(models);

    // scene element

    scenes.forEach((scene) => {
      let sceneElt = this.scenesElements.find(
        (e) => e.sceneClientId === scene.clientId
      );
      if (!sceneElt) {
        sceneElt = new SceneElement({editor: this.editor, scene});
        this.scenesElements.push(sceneElt);
      }
      sceneElt.loadObjectEntities();
      sceneElt.applyOffset();
      sceneElt.applyVisibility();
    });

    // offsets

    this.setBase(focusedSceneId);
  }

  deltaOffset(offset1, offset0) {
    const p1 = offset1.position;
    const p0 = offset0.position;
    const r1 = offset1.rotation;
    const r0 = offset0.rotation;

    return {
      position: {x: p1.x - p0.x, y: p1.y - p0.y, z: p1.z - p0.z},
      rotation: {x: r1.x - r0.x, y: r1.y - r0.y, z: r1.z - r0.z},
    };
  }

  setBase(sceneId) {
    if (sceneId) {
      const baseSceneElt = this.scenesElements.find(
        (e) => e.sceneId === sceneId
      );
      if (baseSceneElt) {
        const {position: p, rotation: r} = baseSceneElt.offset;

        const pivot = new Object3D();
        this.scenesElements.forEach((e) => pivot.attach(e.object));
        pivot.position.set(-p.x, -p.y, -p.z);
        pivot.rotation.set(-r.x, -r.y, -r.z);

        this.scenesElements.forEach((e) => {
          this.editor.scene.attach(e.object);
        });

        this.scenesElements.forEach((e) => {
          e.applyVisibility();
        });
      }
    }
  }

  getScene({sceneId, sceneClientId}) {
    this.scenesElements.find(
      (e) => e.sceneId === sceneId || e.sceneClientId === sceneClientId
    );
  }

  updateVisibility(hiddenScenesIds) {
    this.scenesElements.forEach((e) => {
      if (hiddenScenesIds.includes(e.sceneId)) {
        e.hide();
      } else {
        e.show();
      }
    });
  }
}
