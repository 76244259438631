import {useDispatch, useSelector} from "react-redux";
import {createArticlesGroup} from "../articlesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {unwrapResult} from "@reduxjs/toolkit";

export default function useCreateArticlesGroup() {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const selectedScopeId = useSelector((s) => s.scopes.selectedScopeId);

  const create = async (articlesGroup) => {
    if (accessToken && articlesGroup?.sceneId) {
      const result = await dispatch(
        createArticlesGroup({
          articlesGroup,
          accessToken,
          fromScopeId: selectedScopeId,
        })
      );
      const {item} = unwrapResult(result);
      return item;
    }
  };
  return create;
}
