export default function removeItemsWithDuplicatedId(items) {
  const idMap = {};
  const newItems = [];
  const duplicatedItems = [];
  items.forEach((item) => {
    const duplicated = Boolean(idMap[item.id]);
    if (duplicated) {
      duplicatedItems.push(item);
    } else {
      newItems.push(item);
      idMap[item.id] = "ok";
    }
  });
  return [newItems, duplicatedItems];
}
