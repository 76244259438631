import {useState} from "react";

import {Paper, Icon, Box, IconButton} from "@mui/material";
import {ArrowDropDown, ArrowBackIos as Back} from "@mui/icons-material";

import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleBackPathMap from "Features/navigation/useSceneModuleBackPathMap";
import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import {useNavigate} from "react-router-dom";
import MenuRelatedModules from "Features/navigation/components/MenuRelatedModules";
import useSceneModuleRelatedModulesMap from "Features/navigation/useSceneModuleRelatedModulesMap";

export default function IconModuleInLeftPanel({
  scene,
  bgcolor = "secondary.main",
}) {
  //

  const sceneModule = useSceneModule();
  const iconMap = useSceneModuleIconMap();
  const backPathMap = useSceneModuleBackPathMap();
  const navigate = useNavigate();
  const relatedModulesMap = useSceneModuleRelatedModulesMap();

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers

  const showBack = ["VIEW"].includes(sceneModule);

  const showDropDown = relatedModulesMap[sceneModule]?.length > 0;

  // handlers

  function handleClickBack() {
    navigate(backPathMap[sceneModule]);
  }

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <MenuRelatedModules
        scene={scene}
        anchorEl={anchorEl}
        open={open}
        sceneModule={sceneModule}
        onClose={handleClose}
      />
      {showBack && (
        <Box>
          <IconButton onClick={handleClickBack}>
            <Back />
          </IconButton>
        </Box>
      )}
      {!showBack && (
        <Paper
          sx={{
            bgcolor,
            borderRadius: "4px",
            alignItems: "stretch",
            color: "common.white",
            //mr: 1,
            pl: 1,
            display: "flex",
            //alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 1,
            }}
          >
            <Icon>{iconMap[sceneModule]}</Icon>
          </Box>

          <IconButton
            disabled={!showDropDown}
            size="small"
            color="inherit"
            onClick={handleClick}
          >
            <ArrowDropDown fontSize="small" />
          </IconButton>
        </Paper>
      )}
    </>
  );
}
