// import {Mat} from "@techstark/opencv-js";
import {Vector2, Vector3, ShapeUtils, Triangle} from "three";

export default function getBankMeasurementQuantities({
  pathInf,
  pathSup,
  height,
}) {
  const pointsInf = pathInf.map((p) => ({x: p[0], y: 0, z: p[1]}));
  //const pointsSup = pathSup.map((p) => ({x: p[0], y: height, z: p[1]}));

  const shapeInfPoints = pathInf.map((p) => new Vector2(p[0], p[1]));
  const shapeSupPoints = pathSup.map((p) => new Vector2(p[0], p[1]));

  const areaInf = ShapeUtils.area(shapeInfPoints);
  const areaSup = ShapeUtils.area(shapeSupPoints);

  const volume = ((areaInf + areaSup) / 2) * height;

  const vectorsInf = pathInf.map((p) => new Vector3(p[0], 0, p[1]));
  const vectorsSup = pathSup.map((p) => new Vector3(p[0], height, p[1]));

  const count = pointsInf.length - 1;
  let area = 0;

  vectorsSup.forEach((p, index) => {
    if (index <= count - 1) {
      const a = p;
      const b = vectorsSup[index + 1];
      const c = vectorsInf[index];
      const d = vectorsInf[index + 1];

      const t1 = new Triangle(a, b, c);
      const t2 = new Triangle(b, d, c);

      area += t1.getArea() + t2.getArea();
    }
  });

  return {area: Math.abs(area), volume: Math.abs(volume), count: 1};
}
