import {useSelector} from "react-redux";
import sortByNum from "Utils/sortByNum";

export default function useSelectedElementTypesGroup(scene, options) {
  //
  // {id,name,elementTypes:[],fromScene}
  //
  const inEditMeasurements = options?.inEditMeasurements;
  const sortElementTypesByNum = options?.sortElementTypesByNum;
  //
  let group = null;

  // data
  const remoteGroups = useSelector((s) => s.elementTypes.elementTypesGroups);

  const proxyInElementTypes = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const proxyInEditMeasurements = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  //
  const elementTypesFromScene = Array.isArray(scene?.data?.elementTypes)
    ? scene.data.elementTypes
    : [];

  // helper - selectedProxy

  const proxy = inEditMeasurements
    ? proxyInEditMeasurements
    : proxyInElementTypes;

  // logic

  if (!proxy) {
    group = null;
  } else if (proxy.id === "undefined") {
    const typesWithNoGroup = elementTypesFromScene.filter((t) => !t.group);
    group = {id: "undefined", name: "-?-", elementTypes: typesWithNoGroup};
  } else if (proxy.fromScene) {
    const newElementTypes = elementTypesFromScene.filter((t) => {
      const test = t.group === proxy.name;

      return test;
    });

    group = {
      ...proxy,
      elementTypes: newElementTypes,
    };
  } else {
    group = remoteGroups.find((g) => g.id === proxy?.id);
  }

  // sort
  if (sortElementTypesByNum) {
    const sortedTypes = [...group.elementTypes].sort((a, b) =>
      sortByNum(a.num, b.num)
    );
    group = {...group, elementTypes: sortedTypes};
  }
  return group;
}
