import {useSelector, useDispatch} from "react-redux";
import {Box, Typography, TextField} from "@mui/material";

import {goToNext, setColor} from "../elementTyporSlice";

import {SketchPicker} from "react-color";

const presetColors = [
  "#808080",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",

  "#666666",
  "#9F0500",
  "#C45100",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0C797D",
  "#0062B1",
  "#653294",
];
export default function SectionSelectorColor({onChange}) {
  const dispatch = useDispatch();

  // data

  const color = useSelector((s) => s.elementTypor.color);

  // handler

  function handleSelect(shape) {
    dispatch(goToNext());
  }

  function handleChange(color) {
    dispatch(setColor(color.hex));
    if (onChange) onChange(color.hex);
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",

        alignItems: "center",
        minHeight: 0,
      }}
    >
      <SketchPicker
        onChange={handleChange}
        color={color}
        presetColors={presetColors}
      />
    </Box>
  );
}
