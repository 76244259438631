import {useDispatch} from "react-redux";

import {Box, Switch, Typography} from "@mui/material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ButtonSwitchExcludeFromQties({model}) {
  const dispatch = useDispatch();

  // strings

  const excludeFromQtiesS = "Exclure des quantités";

  // helpers

  let exclude = model?.excludeFromQties;
  if (exclude === undefined) exclude = false;

  // handlers

  function handleCheckChange(e) {
    const checked = e.target.checked;
    const updatedModel = {...model, excludeFromQties: checked};
    dispatch(updateModel({updatedModel}));
  }

  return (
    <Box sx={{display: "flex", p: 1}}>
      <Box sx={{mt: 0.3}}>
        <Typography variant="body2">{excludeFromQtiesS}</Typography>
      </Box>
      <Box sx={{pl: 1}}>
        <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
          <Switch size="small" checked={exclude} onChange={handleCheckChange} />
        </Box>
      </Box>
    </Box>
  );
}
