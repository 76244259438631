import React from "react";

import useMeasurementsByElementTypeForDataGrid from "Features/measurements/useMeasurementsByElementTypeForDataGrid";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";

import {Box, Typography} from "@mui/material";

export default function SectionElementTypeGlobalQuantity({elementType, scene}) {
  const mode = useMeasurementsScopeMode(scene);
  const types = useMeasurementsByElementTypeForDataGrid(scene, mode);
  const type = types.find((t) => t.id === elementType.id);

  const quantity = type?.quantity;
  const unit = type?.unit;

  return (
    <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 3}}>
      <Box sx={{display: "flex", alignItems: "baseline"}}>
        <Typography variant="h4">{quantity}</Typography>
        <Typography variant="body2" sx={{ml: 1}}>
          {unit}
        </Typography>
      </Box>
    </Box>
  );
}
