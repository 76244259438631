// TODO description

import { nanoid } from "@reduxjs/toolkit"

import { segmentPolylineIntersection } from "../utils/intersections";
// import makeBufferLine, { makeCenterLine } from "../utils/bufferLine";
import splitSegments from "../utils/split";

export default function changeTypeSegments({
  measurements,
  params,
  data,
}) {
  const {targetGroupId, proxyPackageId, props} = params;
  const {measurementsByPackageId, typesById} = data;
  const targetTypes = Object.values(typesById).filter(
    (t) => t.groupId === targetGroupId
  );
  // const proxiesTypes = new Set(Object.values(proxiesTypes).map((t) => t.id));
  const proxies = measurementsByPackageId[proxyPackageId].filter(
    (p) => p.drawingShape === "SEGMENT"
  );
  // const proxiesToUse = proxies.filter(
  //   (p) => proxiesTypes.has(p.elementTypeId)
  // );
  const polylinesById = {};
  for (const item of measurements.filter(
    (m) => m.drawingShape === "POLYLINE"
  )) {
    polylinesById[item.id] = item;
  }
  const newPolylines = [];
  const polylinesToCutFrom = {};
  const polylinesToDelete = new Set();
  for (const changeSegment of proxies) {
    if (Array.isArray(changeSegment.path3D)) {
      const proxyType = typesById[changeSegment.elementTypeId];
      const newType = targetTypes.find((t) => t.code === proxyType?.code);
      if (newType) {
        const segments = [
          [changeSegment.path3D[0], changeSegment.path3D[3]],
          [changeSegment.path3D[1], changeSegment.path3D[2]]
        ];
        for (const polyline of Object.values(polylinesById)) {
          let idxToCutFrom = false;
          if (polyline.zoneId !== changeSegment.zoneId) continue;
          for (const segment of segments) {
            const cut = segmentPolylineIntersection(
              segment,
              polyline.centerLine,
            );
            if (cut !== false) {
              idxToCutFrom = cut;
              break;
            }
          }
          if (idxToCutFrom !== false) {
            polylinesToDelete.add(polyline.id);
            if (polyline.id in polylinesToCutFrom) {
              polylinesToCutFrom[polyline.id].push(idxToCutFrom);
            } else {
              polylinesToCutFrom[polyline.id] = [idxToCutFrom];
            }
            if (newType) {
              // const centerLine = makeCenterLine(polyline.path3D);
              const newPath = polyline.drawingProps.path.slice(idxToCutFrom, idxToCutFrom + 2);
              const centerPath = polyline.centerLine.slice(idxToCutFrom, idxToCutFrom + 2);
              // console.log("haaaaaaaaaaa", idxToCutFrom, polyline.drawingProps.path, newPath)
              if (newPath.length > 1) {
                const dim1 = props?.dim1 ? props.dim1 : polyline.dim1;
                const height = props?.height ? props.height : polyline.height;
                let zFrom, zInf, zSup, offset;
                if (props?.offset) {
                  offset = props.offset;
                  zFrom = "offset";
                } else {
                  offset = polyline.offset;
                }
                if (props?.zInf) {
                  zInf = props.zInf;
                  zFrom = "zInf";
                } else {
                  zInf = polyline.zInf;
                }
                if (props?.zSup) {
                  zSup = props.zSup;
                  zFrom = "zSup";
                } else {
                  zSup = polyline.zSup;
                }
                if (!zFrom) zFrom = polyline.zFrom;
                // const newPath = polyline.drawingProps.path.slice(idxToCutFrom, idxToCutFrom + 2);
                // let path3D = polyline.path3D;
                // const path3DLen = path3D.length;
                // if (path3DLen === polyline.drawingProps.path.length * 2 + 1) {
                //   path3D = makeCenterLine(path3D);
                // }
                // const centerPath = path3D.slice(idxToCutFrom, idxToCutFrom + 2);
                // const newPath3D = makeBufferLine(centerPath, dim1);
                // const newPath3D = polyline.path3D.slice(idxToCutFrom, idxToCutFrom + 2);
                // let newPath3D3 = makeBufferLine(newPath3D, dim1);
                let zFrom3D3 = polyline.path3d3[0][1];
                if (zFrom === "zInf") zFrom3D3 = zInf;
                else if (zFrom === "zSup") zFrom3D3 = zSup - height;
                else zFrom3D3 += polyline.offset ? offset - polyline.offset : offset; 
                newPolylines.push({
                  ...polyline,
                  id: nanoid(),
                  drawingProps: {
                    ...polyline.drawingProps,
                    path: newPath,
                  },
                  centerLine: centerPath,
                  // path3D: newPath3D,
                  // path3d3: newPath3D.map((p) => {return [p[0], zFrom3D3, p[1]]}),
                  elementTypeId: newType.id,
                  color: newType.color,
                  affectedByProxy: true,
                  dim1,
                  height,
                  offset,
                  zInf,
                  zSup,
                  zFrom
                });
              }
            }
          }
        }
      }
    }
  }
  for (const [k, v] of Object.entries(polylinesToCutFrom)) {
    const polyline = polylinesById[k];
    const cuts = v.sort((a, b) => a - b);
    if (cuts.length > 0) {
      newPolylines.push(...splitSegments(polyline, cuts));
    }
  }
  polylinesToDelete.forEach((id) => {
    delete polylinesById[id];
  })
  return measurements
  .filter((m) => m.drawingShape !== "POLYLINE").map((m) => {
    return {...m};
  }).concat(Object.values(polylinesById))
  .concat(newPolylines);
}