import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import usePageType from "Features/navigation/usePageType";

export default function useSceneFromStateAndUrl() {
  const {sceneId} = useParams();
  const scenes = useSelector((s) => s.scenes.items);

  let scene = scenes.find((scene) => scene.id === sceneId);
  if (!scene) scene = scenes.find((scene) => scene.clientId === sceneId);

  return scene;
}
