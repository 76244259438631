import {useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";
import DialogUnitaryPrices from "Features/pricing/components/DialogUnitaryPrices";

export default function ButtonMoreRessourcesInLeftPanelInViewer({scene}) {
  const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings
  
  const puS = "Prix unitaires";
  
  // data

  
  // state
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
 
  const [openImportPUDialog, setOpenImportPUDialog] = useState(false);

  // handlers - import PU

  function handleImportPU() {
    setAnchorEl(null);
    setOpenImportPUDialog(true);
  }
  function handleCloseImportPUDialog() {
    setOpenImportPUDialog(false);
  }
  
  // helpers - tools
  
  const tools = [
    {
      id: "UNITARY_PRICES",
      label: puS,
      handler: handleImportPU,
    },
  ];
  
  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
      <DialogUnitaryPrices
        open={openImportPUDialog}
        onClose={handleCloseImportPUDialog}
        scene={scene}
      />
    </>
  );
}      