import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import SelectorGroup from "./SelectorGroup";
import CardSelectedTypes from "./CardSelectedTypes";
import DataGridElementTypes from "./DataGridElementTypes";
import HeaderLibrary from "./HeaderLibrary";
import getGroupTypesMap from "../utils/getGroupTypesMap";
import useLibrary from "../hooks/useLibrary";

import Stretch from "Features/ui/components/Stretch";

export default function PageSceneLibrary() {
  // params

  const {scene} = useOutletContext();

  // data

  const library = useLibrary();
  const selectedGroup = useSelector((s) => s.library.selectedGroup);

  // helpers

  const groupTypesMap = getGroupTypesMap(library);
  let elementTypes = groupTypesMap[selectedGroup];
  if (!elementTypes) elementTypes = [];

  return (
    <Box sx={{width: 1}}>
      <Box sx={{height: "68px"}} />
      <HeaderLibrary />
      <Box sx={{px: 2, display: "flex", alignItems: "center", mb: 2}}>
        <SelectorGroup />
        <Box sx={{ml: 2}}>
          <CardSelectedTypes scene={scene} />
        </Box>
      </Box>
      <Stretch>
        <DataGridElementTypes elementTypes={elementTypes} />
      </Stretch>
    </Box>
  );
}
