import React from "react";

import {Box, Paper, Typography, IconButton, Button} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function ContainerPoiDetail({
  viewer,
  poi,
  onClose,
  onDelete,
  canEdit,
}) {
  const deleteS = "Supprimer";
  //
  const title = poi?.description;
  //
  function handleDelete() {
    onDelete();
  }
  return (
    <Box sx={{width: "240px"}}>
      <Paper sx={{width: 1}} elevation={12}>
        <Box
          sx={{
            width: 1,
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">{title}</Typography>
          <IconButton size="small" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
        {!viewer && canEdit && (
          <Box
            sx={{
              width: 1,
              display: "flex",
              justifyContent: "end",
              mt: 1,
              p: 1,
              color: "text.secondary",
            }}
          >
            <Button size="small" onClick={handleDelete} color="inherit">
              {deleteS}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
