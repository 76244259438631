import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedIssue from "../useSelectedIssue";

import {updateIssue} from "Features/issues/issuesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {setIsEditingIssue, setEditedIssue} from "Features/issues/issuesSlice";

export default function ActionsIssueInSelectionPanel() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.issues.isEditingIssue);
  const selectedIssue = useSelectedIssue();
  const editedIssue = useSelector((s) => s.issues.editedIssue);

  // helper - ressource

  // let issue = isEditing ? editedIssue : selectedIssue;

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingIssue(true));
    dispatch(setEditedIssue({...selectedIssue}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingIssue(false));
  }

  function handleSaveClick() {
    dispatch(updateIssue({accessToken, issue: editedIssue}));
    dispatch(setIsEditingIssue(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* <Box sx={{flexGrow: 1}}>
        <Button variant="contained" color="primary" fullWidth>
          {deleteS}
        </Button>
      </Box> */}

      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
