import {Object3D, Vector3} from "three";
import Marker from "./Marker";
import angleFromU2V from "../utils/angleFromU2V";

class ConfigCenter {
  constructor({position, scene, onSaveConfigEntity, editor}) {
    this.object = new Object3D();
    this.object.position.set(position.x, position.y, position.z);

    this.marker1 = new Marker({marker: {type: "CONFIG_1"}, scene});
    this.marker2 = new Marker({marker: {type: "CONFIG_2"}, scene});

    this.markersMap = {MARKER1: this.marker1, MARKER2: this.marker2};
    this.position = new Vector3(position.x, position.y, position.z);
    this.entity = undefined; // the entity being configured

    this.onSaveConfigEntity = onSaveConfigEntity;
    this.editor = editor;
  }

  configEntity({entity, objectEntity}) {
    console.log("[ConfigCenter] config entity", entity, objectEntity);
    this.entity = entity;
    this.objectEntity = objectEntity;
    this.entity.object.position.copy(this.position);
    this.entity.object.rotation.set(0, 0, 0);
    if (this.entity.type === "IMAGE_MODEL") {
      this.entity.showBackground();
      this.entity.hideImageEntity();
    }
    if (objectEntity && objectEntity.type === "IMAGE_PART_ENTITY") {
      const partId = objectEntity.partId;
      this.entity.initPartConfigDisplay(partId);
    }
  }

  saveConfigEntity() {
    this.entity.updateTransformation();
    //this.entity.hideBackground();
    //this.entity.showImageEntity();

    //this.entity.updateMask();  // Uncomment when mask is ready
    this.onSaveConfigEntity(this.entity.parse());
    // if (this.entity.type === "IMAGE_MODEL") {
    //   this.entity.hideBackground();
    //   this.entity.showImageEntity();
    // }
  }

  setPositionOfMarker(num, p) {
    this.markersMap[`MARKER${num}`].object.position.set(p.x, p.y, p.z);
    this.markersMap[`MARKER${num}`].object.visible = true;
  }

  hideMarkers() {
    this.marker1.object.visible = false;
    this.marker2.object.visible = false;
  }

  moveEntityToMainScene() {
    this.entity?.updateTransformation();
    // if (this.entity?.type === "IMAGE_MODEL") {
    //   this.entity.hideBackground();
    //   this.entity.showImageEntity();
    // }
  }

  moveEntityToConfigCenter() {
    this.entity.object.position.copy(this.position);
    this.entity.object.rotation.set(0, 0, 0);
  }

  setEntityNewMask(points) {
    if (this.entity.type === "IMAGE_MODEL") {
      const mask = [
        points.map((point) => {
          const {width, height} = this.entity.model;
          const x = (point.x - this.position.x + width / 2) / width;
          const y = (point.y - this.position.y + height / 2) / height;
          return [x, y];
        }),
      ];
      if (this.entity.setMask) this.entity.setMask(mask);
    }
  }

  createPart({pointsArray, name, id}) {
    const {width, height} = this.entity.model;
    const mask = pointsArray.map((points) => {
      return points.map((point) => {
        const {width, height} = this.entity.model;
        const x = (point.x - this.position.x + width / 2) / width;
        const y = (point.y - this.position.y + height / 2) / height;
        return [x, y];
      });
    });
    const offset = this.position;
    if (this.entity.createPart)
      this.entity.createPart({id, name, mask, offset}); // Note : the position is eventually updated with entity.updatePartsTransformations.
  }

  setEntityNewScaledModel({scale}) {
    const m = {...this.entity.model};
    m.width = this.entity.width * scale;
    m.height = this.entity.height * scale;
    this.entity.model = m;
    return m;
  }

  setEntityNewModel({marker1, marker2, slope}) {
    const orientation = slope;
    const m1 = marker1;
    const m2 = marker2;

    const M1 = new Vector3(m1.x, m1.y, m1.z); // marker 1 in main scene
    const M2 = new Vector3(m2.x, m2.y, m2.z);

    const M1_config = new Vector3(); // marker 1 in config center (translated to 0,0,0)
    const M2_config = new Vector3();

    M1_config.copy(this.marker1.object.position);
    M1_config.sub(this.position); // send M1_config from the config center to the main scene.
    M2_config.copy(this.marker2.object.position);
    M2_config.sub(this.position);

    // angle diff between config & reality

    const X = new Vector3(1, 0, 0);
    const Y = new Vector3(0, 1, 0);
    const Z = new Vector3(0, 0, 1);

    // WARNING : M2_config is being changed !!! => error in computing scaling
    const _M2_config = M2_config.clone();
    const M1M2_config = _M2_config.sub(M1_config);
    const _M2 = M2.clone();
    const M1M2 = _M2.sub(M1);

    const angleX_config = angleFromU2V(X, M1M2_config, Z);
    const angleX = angleFromU2V(X, M1M2, Y);
    const angleDiff = angleX - angleX_config;

    // scale
    const distance = M2.distanceTo(M1);
    const imageDistance = M2_config.distanceTo(M1_config);
    const scale = distance / imageDistance;

    // transformation

    const object = new Object3D();
    object.scale.set(scale, scale, scale);
    if (orientation === "HORIZONTAL") {
      object.rotation.set(-Math.PI / 2, 0, 0);
      object.rotateZ(angleDiff);
    }
    if (orientation === "VERTICAL") {
      object.rotation.set(0, 0, 0);
      const delta = M1M2_config.dot(X) >= 0 ? 0 : Math.PI;
      object.rotateY(angleX + delta);
    }

    object.position.copy(M1);
    object.updateWorldMatrix();
    object.localToWorld(M1_config.negate());
    object.position.copy(M1_config);

    object.updateWorldMatrix();

    const newPosition = {
      x: object.position.x,
      y: object.position.y,
      z: object.position.z,
    };
    const newRotation = {
      x: object.rotation.x,
      y: object.rotation.y,
      z: object.rotation.z,
    };

    // update model

    const m = {...this.entity.model};
    m.rotation = newRotation;
    m.position = newPosition;
    m.width = this.entity.width * scale;
    m.height = this.entity.height * scale;
    m.center = {x: newPosition.x, y: newPosition.y, z: newPosition.z};
    this.entity.model = m;

    return m;
  }

  setPartNewTransformation({marker1, marker2, slope}) {
    // we update the entity.rotation & entity.transformation attributes

    const orientation = slope;
    const m1 = marker1;
    const m2 = marker2;

    const M1 = new Vector3(m1.x, m1.y, m1.z); // marker 1 in main scene
    const M2 = new Vector3(m2.x, m2.y, m2.z);

    const M1_config = new Vector3(); // marker 1 in config center (translated to 0,0,0)
    const M2_config = new Vector3();

    M1_config.copy(this.marker1.object.position);
    M1_config.sub(this.position); // send M1_config from the config center to the main scene.
    M2_config.copy(this.marker2.object.position);
    M2_config.sub(this.position);

    // angle diff between config & reality

    const X = new Vector3(1, 0, 0);
    const Y = new Vector3(0, 1, 0);
    const Z = new Vector3(0, 0, 1);

    // WARNING : M2_config is being changed !!! => error in computing scaling
    const _M2_config = M2_config.clone();
    const M1M2_config = _M2_config.sub(M1_config);
    const _M2 = M2.clone();
    const M1M2 = _M2.sub(M1);

    const angleX_config = angleFromU2V(X, M1M2_config, Z);
    const angleX = angleFromU2V(X, M1M2, Y);
    const angleDiff = angleX - angleX_config;

    // scale
    const distance = M2.distanceTo(M1);
    const imageDistance = M2_config.distanceTo(M1_config);
    const scale = distance / imageDistance;

    // transformation

    const object = new Object3D();
    object.scale.set(scale, scale, scale);
    if (orientation === "HORIZONTAL") {
      object.rotation.set(-Math.PI / 2, 0, 0);
      object.rotateZ(angleDiff);
    }
    if (orientation === "VERTICAL") {
      object.rotation.set(0, 0, 0);
      const delta = M1M2_config.dot(X) >= 0 ? 0 : Math.PI;
      object.rotateY(angleX + delta);
    }

    object.position.copy(M1);
    object.updateWorldMatrix();
    object.localToWorld(M1_config.negate());
    object.position.copy(M1_config);

    object.updateWorldMatrix();

    const newPosition = {
      x: object.position.x,
      y: object.position.y,
      z: object.position.z,
    };
    const newRotation = {
      x: object.rotation.x,
      y: object.rotation.y,
      z: object.rotation.z,
    };

    // update transformation

    this.objectEntity.rotation = newRotation; // transformations in the world coordinate system.
    this.objectEntity.position = newPosition;

    // update model

    const newModel = {...this.entity.model};
    const newPart = {
      id: this.objectEntity.partId,
      name: this.objectEntity.name,
      mask: this.objectEntity.mask,
      rotation: newRotation,
      position: newPosition,
    };
    const newParts = this.entity.model.parts.map((part) => {
      if (part.id === newPart.id) {
        return newPart;
      } else {
        return part;
      }
    });
    newModel.parts = newParts;
    this.entity.model = newModel;
    return newModel;
  }

  close() {
    this.moveEntityToMainScene();
    if (this.entity?.updatePartsTransformations)
      this.entity.updatePartsTransformations();
    this.hideMarkers();
    if (this.entity?.hideBackground) {
      this.entity.hideBackground();
    }
    if (this.entity?.initMainSceneDisplay) this.entity.initMainSceneDisplay();
    //
    if (this.entity?.model)
      this.editor.controls.setOrbitControlsTarget(this.entity.model.position);
    //
    this.entity = undefined;
  }
}

export default ConfigCenter;
