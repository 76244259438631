import * as atlas from "azure-maps-control";

export default function worksitesToGeoJson(worksites) {
  return new atlas.data.FeatureCollection(
    worksites.map((w) => worksiteToGeoJson(w))
  );
}

function worksiteToGeoJson(worksite) {
  const {id, lng, lat, name, imageUrl, description} = worksite;
  const geoJsonFeature = new atlas.data.Feature(
    new atlas.data.Point([lng, lat]),
    {
      name,
      description,
      imageUrl,
    },
    id
  );
  return geoJsonFeature;
}
