const openingsSample = [
  {
    id: 1,
    sector: "R+1",
    typeName: "Portes",
    elements: [
      {
        id: "1221-1231-bazer",
        reference: "P-02",
      },
      {
        id: "1221-1232-bazer",
        reference: "P-03",
      },
      {
        id: "1221-1234-bazer",
        reference: "P-04",
      },
    ],
  },
  {
    id: 2,
    sector: "R+2",
    typeName: "Réservations CVC",
    elements: [
      {
        id: "1221-1331-bazer",
        reference: "CVC-01",
      },
      {
        id: "1221-1932-bazer",
        reference: "CVC-02",
      },
      {
        id: "1221-13234-bazer",
        reference: "CVC-03",
      },
    ],
  },
];

let openingsSampleFlat = [];
openingsSample.forEach(({id, sector, typeName, elements}) => {
  elements.forEach(({id: elementId, reference}) => {
    const newElement = {
      id: elementId,
      sector,
      typeName,
      reference,
    };
    openingsSampleFlat.push(newElement);
  });
});

export const openingsTable = openingsSampleFlat;
