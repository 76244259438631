import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Typography, IconButton} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setOpenSections} from "Features/ui/uiSlice";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";

export default function PdfExtractedText({
  attributes,
  children,
  element,
  editorPdf,
  withoutText,
}) {
  const dispatch = useDispatch();

  // const selected = useSelected();
  // const focused = useFocused();
  // const loading = element.loading;

  const {pdfExtractedText} = element;
  const text = pdfExtractedText?.text;
  const pageNumber = pdfExtractedText?.pageNumber;
  const pdfModelId = pdfExtractedText?.pdfModelId;
  const pageRotation = pdfExtractedText?.pageRotation;
  const pageWidth = pdfExtractedText?.pageWidth;
  const pageHeight = pdfExtractedText?.pageHeight;
  const rects = pdfExtractedText?.rects;

  // data

  const models = useSelector((state) => state.viewer3D.models);
  const pdfModel = models.find((m) => m.id === pdfModelId);
  const os = useSelector((s) => s.ui.openSections);
  const pdfModelIdLoaded = useSelector((s) => s.pdf.pdfModelIdLoaded);
  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );

  // state

  // effect

  // /!\ this effect is call when the selection panel width=0...
  // useEffect(() => {
  //   if (
  //     selectedRessourceId &&
  //     pdfModelIdLoaded &&
  //     pdfModelIdLoaded === pdfModelId &&
  //     rects[0]
  //   ) {
  //     let {x1, y1, x2, y2} = rects[0];
  //     if (pageWidth && (pageRotation === 0 || pageRotation === 1)) {
  //       x1 = 0;
  //       x2 = pageWidth;
  //     }
  //     if (pageHeight && (pageRotation === 1 || pageRotation === 2)) {
  //       y1 = 0;
  //       y2 = pageHeight;
  //     }
  //     const viewportCoords = {x1, x2, y1, y2};
  //     const context = {pageNumber, pageRotation, viewportCoords};
  //     editorPdf?.setPdfContext(context);
  //     // highlight text
  //     editorPdf?.sceneEditor.removeHighlightedExtractedText();
  //     editorPdf?.sceneEditor.highlightExtractedText(pdfExtractedText);
  //   }
  // }, [pdfModelId, pageNumber, pdfModelIdLoaded, text, selectedRessourceId]);

  // helper

  const textInfo = `${pdfModel?.name} • p${pageNumber} `;

  // handlers

  function handleFocusClick() {
    // os
    if (!os.fixedViewersBox) {
      dispatch(
        setOpenSections({...os, fixedViewersBox: true, viewer3D: false})
      );
    }
    dispatch(setSelectedPdfModelId(pdfModel?.id));
    // context
    if (pdfModelIdLoaded === pdfModel?.id && rects[0]) {
      let {x1, y1, x2, y2} = rects[0];
      if (pageWidth && (pageRotation === 0 || pageRotation === 2)) {
        x1 = 0;
        x2 = pageWidth;
      }
      if (pageHeight && (pageRotation === 1 || pageRotation === 3)) {
        y1 = 0;
        y2 = pageHeight;
      }
      const viewportCoords = {x1, x2, y1, y2};
      const context = {pageNumber, pageRotation, viewportCoords};
      editorPdf?.setPdfContext(context);
      // highlight
      editorPdf?.sceneEditor.removeHighlightedExtractedText();
      editorPdf?.sceneEditor.highlightExtractedText(pdfExtractedText);
    }
  }

  return (
    <Box
      {...attributes}
      sx={{
        width: 1,
        bgcolor: "background.default",
        p: 0.5,
        borderRadius: "4px",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        display: "flex",
        cursor: "pointer",
      }}
      contentEditable={false}
      onClick={handleFocusClick}
    >
      <Box
        sx={{
          display: "flex",
          width: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
          }}
        >
          <Typography variant="body2">{textInfo}</Typography>
          {!withoutText && (
            <Typography sx={{fontSize: 13, color: "text.secondary"}} noWrap>
              {text}
            </Typography>
          )}
        </Box>
        <IconButton size="small" onClick={handleFocusClick}>
          <Focus fontSize="small" />
        </IconButton>
      </Box>

      {children}
    </Box>
  );
}
