import {createSlice} from "@reduxjs/toolkit";
import theme from "Styles/theme";

export const elementTyporSlice = createSlice({
  name: "elementTypor",
  initialState: {
    openDialogFsCreateElementType: false,
    //
    step: "name",
    //
    stepBasic: "GLOBAL", // "SHAPE"
    completed: {}, // {IDENTIFICATION:true,...}
    //
    name: "",
    code: "",
    drawingShape: "POLYLINE",
    color: theme.palette.secondary.main,
    dim1: null,
    dim2: null,
    height: null,
    offset: null,
    num: "",
    style: "normal", //title1,title2,detail
    fileUrl: null,
    //
    tempElementTypeFromPdf: null,
    //
    res: [], // {resId,mode,func}
  },
  reducers: {
    setOpenDialogFsCreateElementType: (state, action) => {
      state.openDialogFsCreateElementType = action.payload;
    },
    setTempElementTypeFromPdf: (state, action) => {
      state.tempElementTypeFromPdf = action.payload;
    },
    //
    setName: (state, action) => {
      state.name = action.payload;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setColor: (state, action) => {
      state.color = action.payload;
    },
    setNum: (state, action) => {
      state.num = action.payload;
    },
    setDrawingShape: (state, action) => {
      state.drawingShape = action.payload;
    },
    setDim1: (state, action) => {
      state.dim1 = action.payload;
    },
    setDim2: (state, action) => {
      state.dim2 = action.payload;
    },
    setHeight: (state, action) => {
      state.height = action.payload;
    },
    setStyle: (state, action) => {
      state.style = action.payload;
    },
    setRes: (state, action) => {
      state.res = action.payload;
    },
    setFileUrl: (state, action) => {
      state.fileUrl = action.payload;
    },
    initResWithRessource: (state, action) => {
      const ressource = action.payload;
      state.res = [{resId: ressource.id, mode: "AUTO"}];
    },
    //
    goToNext: (state, action) => {
      if (state.step === "name") {
        state.step = "drawingShape";
      }
      const nextMap = {
        IDENTIFICATION: "SHAPE",
        SHAPE: "COLOR",
        COLOR: "GEOMETRY",
        GEOMETRY: "GEOMETRY",
      };
      state.stepBasic = nextMap[state.stepBasic];
    },
    goToPrev: (state) => {
      if (state.step === "drawingShape") {
        state.step = "name";
      }
    },
    //
    setStepBasic: (state, action) => {
      state.stepBasic = action.payload;
    },
    completeStep: (state, action) => {
      const code = action.payload;
      state.completed[code] = true;
    },
    resetTempElementType: (state, action) => {
      state.completed = {};
      state.stepBasic = "SHAPE";
      state.name = "";
      state.code = "";
      state.dim1 = null;
      state.dim2 = null;
      state.height = null;
      state.res = [];
      state.fileUrl = null;
    },
  },
});

export const {
  setOpenDialogFsCreateElementType,
  setTempElementTypeFromPdf,
  setName,
  setNum,
  setCode,
  setColor,
  setDrawingShape,
  setDim1,
  setDim2,
  setHeight,
  setStyle,
  setRes,
  setFileUrl,
  initResWithRessource,
  //
  goToNext,
  goToPrev,
  //
  setStepBasic,
  completeStep,
  resetTempElementType,
  //
} = elementTyporSlice.actions;

export default elementTyporSlice.reducer;
