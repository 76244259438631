import articleTypesArrayToMap from "./articleTypesArrayToMap";
import articleTypesMapToArray from "./articleTypesMapToArray";

export default function removeItemFromArticleTypes({
  groupId,
  typeId,
  articleTypes,
}) {
  let articleTypesArray;
  try {
    articleTypesArray = articleTypesMapToArray(articleTypes);
    console.log("debugAFA109-12", articleTypesArray);
  } catch (e) {
    console.log("error_debugAFA", e);
  }

  const newArticleTypesArray = articleTypesArray.filter((item) => {
    if (!groupId && !typeId) {
      return !(!item.groupId && !item.typeId);
    } else if (groupId && !typeId) {
      return item.groupId !== groupId && !item.typeId;
    } else if (groupId && typeId) {
      return item.groupId !== groupId || item.typeId !== typeId;
    }
  });
  console.log("newArticleTypesArray", newArticleTypesArray);
  const result = articleTypesArrayToMap(newArticleTypesArray);

  return result;
}
