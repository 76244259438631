import React from "react";

import {TextField, Box, Typography} from "@mui/material";

export default function FieldElementTypeNameFast({name, onChange}) {
  const label = "Nom";

  return (
    <Box sx={{width: 1}}>
      <TextField
        value={name ? name : ""}
        fullWidth
        onChange={(e) => {
          onChange(e.target.value);
        }}
        label={<Typography variant="body2">{label}</Typography>}
        size="small"
        InputProps={{
          sx: {
            fontSize: (theme) => theme.typography.body2.fontSize,
          },
        }}
      />
    </Box>
  );
}
