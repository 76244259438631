import {Box, Typography} from "@mui/material";

import ButtonDownloadBimPdf from "Features/measurements/components/ButtonDownloadBimPdf";

export default function SectionDownloadBimData({scene, editorPdf}) {
  // strings

  const title = "Format .bim.pdf";

  const description = `Vous pouvez enregistrer les données de la bimbox (secteurs, zones, types,éléments,...) dans le pdf.
    Vous pourrez ainsi récupérer toutes ces données en chargeant le fichier .bim.pdf dans une nouvelle bimbox.`;

  // const downloadS = "Télécharger";

  // helper - style

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  return (
    <Box sx={{background, color: "common.white", p: 1, borderRadius: "4px"}}>
      <Typography sx={{mb: 1}} variant="h6">
        {title}
      </Typography>
      <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
        {description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: 1,
          justifyContent: "end",
          mt: 2,
        }}
      >
        <ButtonDownloadBimPdf
          scene={scene}
          editorPdf={editorPdf}
          buttonVariant="outlined"
        />
      </Box>
    </Box>
  );
}
