import {useDispatch} from "react-redux";
import {deleteSurvey} from "../surveysSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteSurvey() {
  //
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteS = async (survey) => {
    const surveyId = survey.id;
    const sceneId = survey.sceneId;
    if ((surveyId, sceneId, accessToken)) {
      await dispatch(deleteSurvey({surveyId, sceneId, accessToken}));
    }
  };

  return deleteS;
}
