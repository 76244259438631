import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import ProcessMoveOnePoint from "./ProcessMoveOnePoint";
import CardPositionOnePoint from "./CardPositionOnePoint";

import DisplayBox from "Components/DisplayBox";

import {
  setMode,
  setStep,
  setSnappingMode,
  setPointerMode,
} from "Features/viewer3D/viewer3DSlice";

export default function ToolPosition({caplaEditor, onClose, scene}) {
  const dispatch = useDispatch();

  // data

  const mode = useSelector((state) => state.viewer3D.mode);

  // launch Process Directly

  useEffect(() => {
    dispatch(setMode("MOVE_ONE_POINT"));
    dispatch(setStep("ORIGIN"));
    // active snapping by default
    dispatch(setSnappingMode(true));
    if (caplaEditor.editor3d) caplaEditor.editor3d.snappingMode = true;
    // active picking
    //dispatch(setPicking(true));
    //caplaEditor.editor3d?.picking = true;
    // active pointer
    dispatch(setPointerMode(true));
    caplaEditor.editor3d?.sceneEditor.setPointerMode(true);
    caplaEditor.editor3d?.imageOverlay.hide(); // to hide top screen...

    return () => {
      dispatch(setMode("DEFAULT"));
      dispatch(setSnappingMode(false));
      if (caplaEditor.editor3d) caplaEditor.editor3d.snappingMode = false;
    };
  }, []);

  return (
    <div>
      <DisplayBox open={mode !== "MOVE_ONE_POINT"}>
        <CardPositionOnePoint editor3d={caplaEditor.editor3d} />
      </DisplayBox>
      <DisplayBox open={mode === "MOVE_ONE_POINT"}>
        <ProcessMoveOnePoint caplaEditor={caplaEditor} onClose={onClose} scene={scene} />
      </DisplayBox>
    </div>
  );
}
