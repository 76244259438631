import {useState, useEffect} from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import FormTempInputs from "./FormTempInputs";

import {getModelTypeFromFile} from "Features/viewer3D/utils/miscUtils";

export default function DialogAddInputs({
  editor3d,
  open,
  onClose,
  tempFiles,
  onAddInputs,
}) {
  // strings

  const title = "Noms d'usage des fichiers";

  const saveS = "Ajouter";
  const cancelS = "Annuler";

  // state

  const [tempInputs, setTempInputs] = useState(tempFiles);

  useEffect(() => {
    if (tempFiles && tempFiles.length > 0) {
      const inputs = tempFiles.map((f) => ({file: f, fileName: f.name}));
      setTempInputs(inputs);
    }
  }, [tempFiles?.length, open]);

  // helpers

  let canSave = true;
  tempInputs?.forEach((tempInput) => {
    if (!tempInput?.name || tempInput?.name?.length === 0) canSave = false;
  });

  // handlers

  function handleTempInputsChange(inputs) {
    setTempInputs(inputs);
  }

  async function handleSave() {
    let inputs = [];
    for (let tempInput of tempInputs) {
      const {file, name} = tempInput;
      const type = getModelTypeFromFile(file);
      const options = {name};
      const newInput = await editor3d?.loader.loadFile({
        file,
        type,
        options,
        openOnceLoaded: false,
      });
      inputs.push(newInput);
    }
    if (onAddInputs) onAddInputs(inputs);
    setTempInputs([]);
    onClose();
  }

  function handleCancel() {
    onClose();
    setTempInputs([]);
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{display: "flex", minWidth: 400, flexDirection: "column"}}>
          <FormTempInputs
            tempInputs={tempInputs}
            onChange={handleTempInputsChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button disabled={!canSave} onClick={handleSave} variant="contained">
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
