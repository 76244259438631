import {useSelector} from "react-redux";
import {useEffect} from "react";

export default function useMeasurementCreationMode({editorPdf}) {
  const drawingShape = useSelector((s) => s.measurements.drawingShape);
  const annotCreationTool = useSelector(
    (s) => s.measurements.annotCreationTool
  );

  let mode = "DEFAULT";

  if (annotCreationTool === "POLYLINE_INTERIOR") mode = "POLYLINE_INTERIOR";

  if (annotCreationTool === "SCALE_SEGMENT") mode = "SCALE_SEGMENT";

  if (annotCreationTool === "POLYLINE_EXTERIOR") mode = "POLYLINE_EXTERIOR";
  if (annotCreationTool === "POLYLINE" && drawingShape === "POLYLINE")
    mode = "POLYLINE";
  if (annotCreationTool === "POLYLINE" && drawingShape === "SLOPE")
    mode = "SLOPE";
  if (annotCreationTool === "POLYLINE" && drawingShape === "STAIRS")
    mode = "STAIRS";
  if (annotCreationTool === "POLYLINE" && drawingShape === "GABLE")
    mode = "GABLE";
  if (annotCreationTool === "POLYLINE" && drawingShape === "BRIDGE")
    mode = "BRIDGE";
  if (annotCreationTool === "POLYGON") mode = "POLYGON";
  if (annotCreationTool === "POLYGON" && drawingShape === "POLYLINE")
    mode = "POLYLINE_FROM_POLYGON";
  if (annotCreationTool === "POLYGON" && drawingShape === "SLOPING_POLYGON")
    mode = "SLOPING_POLYGON";
  if (annotCreationTool === "POLYGON" && drawingShape === "BOWL")
    mode = "BOWL";
  if (annotCreationTool === "POLYGON" && drawingShape === "BANK") mode = "BANK";
  if (annotCreationTool === "POLYGON_INTERIOR" && drawingShape === "BANK")
    mode = "POLYGON_INTERIOR_BANK";

  if (annotCreationTool === "SEGMENT") mode = "SEGMENT";
  if (annotCreationTool === "SEGMENT" && drawingShape === "BEAM") mode = "BEAM";
  if (annotCreationTool === "SEGMENT" && drawingShape === "POLYLINE")
    mode = "POLYLINE_FROM_SEGMENT";

  if (annotCreationTool === "RECTANGLE" && drawingShape === "POLYGON")
    mode = "POLYGON_FROM_RECTANGLE";
  if (annotCreationTool === "RECTANGLE" && drawingShape === "RECTANGLE")
    mode = "RECTANGLE";
  if (annotCreationTool === "RECTANGLE" && drawingShape === "POLYLINE")
    mode = "POLYLINE_FROM_RECTANGLE";

  if (annotCreationTool === "POINT" && drawingShape === "RECTANGLE")
    mode = "RECTANGLE_FROM_POINT";
  if (annotCreationTool === "POINT" && drawingShape === "CIRCLE")
    mode = "CIRCLE_FROM_POINT";
  if (annotCreationTool === "SEGMENT_FROM_ZONES") mode = "SEGMENT_FROM_ZONES";

  useEffect(() => {
    if (
      editorPdf?.annotationsManager?.measurementsPdfManager
        .measurementCreationMode
    ) {
      editorPdf.annotationsManager.measurementsPdfManager.setMeasurementCreationMode(
        mode
      );
    }
  }, [
    editorPdf?.annotationsManager?.measurementsPdfManager
      ?.measurementCreationMode,
    mode,
  ]);

  return mode;
}
