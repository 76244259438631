import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import LayerMain from "./LayerMain";
import LayerDefault from "./LayerDefault";
import LayerIssues from "./LayerIssues";
// import LayerMeasurements from "./LayerMeasurements";
// import LayerMeasurementsEdit from "./LayerMeasurementsEdit";
//import LayerZones from "./LayerZones";
import LayerMarkers from "./LayerMarkers";
import DialogLoadingModel from "./DialogLoadingModel";
import DialogLoadBimData from "./DialogLoadBimData";
//import DialogTextExtractor from "./DialogTextExtractor";

import useIsDrawing from "Features/measurements/useIsDrawing";
import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";
import DialogAddCut from "Features/cuts/components/DialogAddCut";

export default function Layers({caplaEditor, scene, viewer}) {
  // data

  const pdfViewerStatus = useSelector((s) => s.viewer3D.pdfViewerStatus);

  const openSections = useSelector((s) => s.ui.openSections);

  const mode = useSelector((s) => s.pdf.pdfViewerMode);

  const isDrawing = useIsDrawing();

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  // helper

  const loadingPdf = pdfViewerStatus === "loading";

  const display =
    openSections.fixedViewersBox && !loadingPdf ? "block" : "none";

  const editing = isDrawing;

  return (
    <Box sx={{display}}>
      <DialogLoadingModel />
      <DialogAddCut />
      <DialogLoadBimData caplaEditor={caplaEditor} scene={scene} />
      {/* <DialogTextExtractor caplaEditor={caplaEditor} scene={scene} /> */}

      <LayerMain caplaEditor={caplaEditor} scene={scene} viewer={viewer} />

      {mode === "DEFAULT" && !editing && (
        <LayerDefault caplaEditor={caplaEditor} scene={scene} />
      )}
      {mode === "ISSUES" && !isDrawing && (
        <LayerIssues caplaEditor={caplaEditor} scene={scene} />
      )}
      {/* {(sceneModule === "MEASUREMENTS" || sceneModule === "HOME" || viewer) && (
        <LayerMeasurements caplaEditor={caplaEditor} scene={scene} viewer={viewer} />
      )} */}
      {/* {(sceneModule === "EDIT_MEASUREMENTS" || pageType === "COLORING") && (
        <LayerMeasurementsEdit caplaEditor={caplaEditor} scene={scene} />
      )} */}
      {/* {sceneModule === "ZONES" && (
        <LayerZones caplaEditor={caplaEditor} scene={scene} viewer={viewer} />
      )} */}
      {sceneModule === "MARKERS" && (
        <LayerMarkers editorPdf={caplaEditor?.editorPdf} scene={scene} />
      )}
    </Box>
  );
}
