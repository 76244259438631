import {useSelector} from "react-redux";

import {Box, Button, Typography, Divider} from "@mui/material";
import updateTypesGroupWithNomenclature from "Features/elementTypes/utils/updateTypesGroupWithNomenclature";
import useSelectedElementTypesGroupInMeasurements from "../useSelectedElementTypesGroupInMeasurements";
import useSelectedElementTypesGroup from "Features/elementTypes/hooks/useSelectedElementTypesGroup";
import useSelectedElementTypeFromMeasurements from "../useSelectedElementTypeFromMeasurements";
import useUpdateElementTypesGroup from "Features/elementTypes/hooks/useUpdateElementTypesGroup";
import updateMeasurementsTypeFromTypeNomenclature from "Features/elementTypes/utils/updateMeasurementsFromTypeNomenclature";

export default function SectionAutoMeasTypesDetails({
  scene,
  caplaEditor,
  onSaved,
}) {
  // strings

  const saveS = "Enregistrer";

  const title = "Extraction d'une nomenclature";

  const descriptionS = `
    - Entrée 1 : repérages visible en 3D
    - Entrée 2 : un ouvrage sélectionné à gauche
    - Sortie 1: nomenclature de l'ouvrage
    - Sortie 2 : requalification des repérages
    `;

  // data

  const measIn3d = useSelector((s) => s.measurements.measShowedInViewer3d);

  const typesGroup = useSelectedElementTypesGroup(scene, {
    inEditMeasurements: true,
    sortElementTypesByNum: true,
  });

  const selectedElementType = useSelectedElementTypeFromMeasurements(scene);

  // data func

  const updateGroup = useUpdateElementTypesGroup();

  // helpers

  const measIn3dCount = measIn3d?.length;
  const measIn3dCountLabel = `${measIn3dCount} repérage${
    measIn3dCount > 1 ? "s" : ""
  }`;

  // handlers

  async function handleSaveClick() {
    if (typesGroup && selectedElementType && measIn3d) {
      const [newTypesGroup, updatedNomenclature] =
        updateTypesGroupWithNomenclature(
          typesGroup,
          selectedElementType,
          measIn3d
        );
      //
      console.log("[DEBUG] newTypesGroup", newTypesGroup.elementTypes);
      //
      await updateGroup(newTypesGroup);
      //
      const newMs = updateMeasurementsTypeFromTypeNomenclature(
        measIn3d,
        updatedNomenclature
      );
      caplaEditor?.measDataManager.updateMeasurements(newMs);
      //
      onSaved();
    }
  }

  return (
    <Box sx={{p: 1}}>
      <Typography>{title}</Typography>
      <Typography sx={{fontSize: 13, whiteSpace: "pre-line"}}>
        {descriptionS}
      </Typography>
      <Divider />
      <Box sx={{p: 2}}>
        <Typography variant="body2">{measIn3dCountLabel}</Typography>
        <Box sx={{display: "flex", p: 1, justifyContent: "end"}}>
          <Button onClick={handleSaveClick} size="small">
            {saveS}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
