import React, {useState} from "react";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

import {Box, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import SelectorElementType from "Features/elementTypes/components/SelectorElementType";

export default function ListItemAdHostedType({scene, onAdd}) {
  // data

  const elementTypes = useElementTypesBySceneProxy(scene);

  // states

  const [type, setType] = useState(null);

  // handlers

  function handleTypeChange(t) {
    setType(t);
  }

  function handleAdd() {
    if (onAdd) onAdd(type);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", p: 0.5}}>
      <SelectorElementType
        elementType={type}
        elementTypes={elementTypes}
        onChange={handleTypeChange}
      />
      <IconButton size="small" onClick={handleAdd} sx={{ml: 0.5}}>
        <Add fontSize="small" />
      </IconButton>
    </Box>
  );
}
