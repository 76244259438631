import {useDispatch, useSelector} from "react-redux";

import {Box, Button, Chip, Typography} from "@mui/material";

import ButtonDisableZonesInViewer3d from "./ButtonDisableZonesInViewer3d";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import SearchBarObjects from "Features/search/components/SearchBarObjects";
import { setZonesInScope } from "Features/measurements/measurementsSlice";
import { setUseBufferInShortcut } from "../zonesSlice";

export default function HeaderInShortcutZones({editor3d, scene}) {
  const dispatch = useDispatch();
  // strings

  const zonesS = "Fonds de plan";
  const noSelectionS = "Affichez des fonds de plan en 3D";
  const openBufferS = "Voir la sélection";
  const selectionLengthS = "fonds de plan sélectionnés";
  const closeBufferS = "Voir toute la liste";

  // data

  const zones = useZonesByScene(scene);
  const useBuffer = useSelector((s) => s.zones.useBufferInShortcut);
  const zonesInBuffer = useSelector((s) => s.zones.zonesInBuffer);

  // handlers

  const handleSearch = (zonesFound) => {
    dispatch(setZonesInScope([... new Set(zonesFound.map((z) => z.id))]));
  };

  const handleSeeBuffer = () => {
    dispatch(setUseBufferInShortcut(true));
  };

  const handleCloseBuffer = () => {
    dispatch(setUseBufferInShortcut(false));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        width: 250,
      }}
    >
      <Box sx={{display: "flex", alignItems: "baseline", mt: 2, pb: 1, pl: 1}}>
        <Typography variant="h6" sx={{fontWeight: "bold"}}>
          {zonesS}
        </Typography>
        {zonesInBuffer?.length > 0 && (
          <Box sx={{ml: 1}}>
            <ButtonDisableZonesInViewer3d editor3d={editor3d} />
          </Box>
        )}
      </Box>
      <Box sx={{width: 1, height: 55, pl: 1}}>
        {!useBuffer && zonesInBuffer?.length === 0 && (
          <Chip
            label={noSelectionS}
            size="small"
            sx={{
              color: "common.white",
              bgcolor: "secondary.main",
              width: 1,
              mb: 1,
            }}
          />
        )}
        {!useBuffer && zonesInBuffer?.length > 0 && (
          <Box sx={{width: 1, mb: 0.5, color: "secondary.main"}}>
            <Button
              onClick={handleSeeBuffer}
              size="small"
              color="inherit"
            >
              {openBufferS + ` (${zonesInBuffer.length})`}
            </Button>
          </Box>
        )}
        {!useBuffer && (
          <SearchBarObjects
            objects={zones}
            onSearch={handleSearch}
            keys={["name", "building", "sectorCode", "sectorName", "roomName"]}
          />
        )}
        {useBuffer && (
          <Typography variant="body2" sx={{
            color: "text.secondary",
            pl: 0.5,
            py: 0.5,
          }} >
            {`${zonesInBuffer.length} ` + selectionLengthS}
          </Typography>
        )}
        {useBuffer && (
          <Box sx={{width: 1, color: "secondary.main"}}>
            <Button
              onClick={handleCloseBuffer}
              size="small"
              color="inherit"
            >
              {closeBufferS + ` (${zones.length})`}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
