import {Box, Typography} from "@mui/material";

import ListItemEditedRelationRessource from "./ListItemEditedRelationRessource";
import getItemsMapById from "Utils/getItemsMapById";
import sortByNum from "Utils/sortByNum";

export default function SectionRelationDetailEditedRessources({
  ressources,
  relationResMap,
  onLastChange,
  onRelationResMapChange,
}) {
  //

  console.log("debug 30-05 ressources", ressources, relationResMap);

  // handlers

  function handleLastChange({resId, field, value}) {
    onLastChange({resId, field, value});
  }

  function handleOnBlur({resId, formula, filter}) {
    const newMap = {...relationResMap};
    newMap[resId] = {formula, filter};
    onRelationResMapChange(newMap);
  }

  // helpers

  const ressourceMap = getItemsMapById(ressources);

  // helpers

  const items = relationResMap
    ? Object.entries(relationResMap).map(([resId, {formula, filter}]) => {
        const ressource = ressourceMap[resId];
        return {resId, ressource, formula, filter};
      })
    : [];
  console.log("itemsX", items, ressources);
  const sortedItems = items.sort(
    (a, b) =>
      a.ressource?.group.localeCompare(b.ressource?.group) ||
      sortByNum(a.ressource?.num, b.ressource?.num)
  );

  const itemsInList = [];
  const groupMap = {};

  sortedItems.forEach((item) => {
    if (!groupMap[item.ressource?.group]) {
      const groupItem = {type: "group", name: item.ressource?.group};
      itemsInList.push(groupItem);
      itemsInList.push(item);
      // check why item.ressource doesn't exist
      groupMap[item.ressource?.group] = groupItem;
    } else {
      itemsInList.push(item);
    }
  });
  return (
    <Box sx={{display: "flex", flexDirection: "column", width: 1}}>
      {itemsInList.map((item) => {
        if (item.type === "group") {
          return (
            <Box
              key={item.name}
              sx={{
                bgcolor: "common.caplaBlack",
                color: "common.white",
                p: 1,
                py: 0.5,
                width: 1,
                mt: 0.5,
              }}
            >
              <Typography sx={{fontSize: 13, color: "common.white"}}>
                {item.name}
              </Typography>
            </Box>
          );
        } else {
          const {resId, ressource, formula, filter} = item;
          return (
            <ListItemEditedRelationRessource
              key={resId}
              resId={resId}
              ressource={ressource}
              formula={formula}
              filter={filter}
              onChange={handleLastChange}
              onBlur={handleOnBlur}
            />
          );
        }
      })}
    </Box>
  );
}
