import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import ProcessMeasure from "./components/ProcessMeasure";

import {
  setMode,
  setSnappingMode,
  // setPicking,
  setPointerMode,
} from "Features/viewer3D/viewer3DSlice";

export default function ToolMeasure({editor3d, onClose}) {
  const dispatch = useDispatch();

  // data

  const mode = useSelector((state) => state.viewer3D.mode);

  // init

  useEffect(() => {
    dispatch(setMode("MEASURE"));
    editor3d?.switchToMode("MEASURE");
    // active snapping by default
    dispatch(setSnappingMode(true));
    if (editor3d) editor3d.snappingMode = true;
    // active picking
    //dispatch(setPicking(true));
    //editor3d?.picking = true;
    // active pointer
    dispatch(setPointerMode(true));
    editor3d?.sceneEditor.setPointerMode(true);
    editor3d?.imageOverlay.hide(); // to hide top screen...

    return () => {
      dispatch(setMode("DEFAULT"));
      // inactive snapping
      dispatch(setSnappingMode(false));
      if (editor3d) editor3d.snappingMode = false;
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "common.white",
        //background: (theme) => alpha(theme.palette.secondary.main, 0.9),
      }}
    >
      {mode === "MEASURE" && (
        <ProcessMeasure editor3d={editor3d} onClose={onClose} />
      )}
    </Box>
  );
}
