import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setOpenSections} from "Features/ui/uiSlice";
import {setModuleViewMode} from "../progressSlice";
import {setViewMode} from "Features/viewer3D/viewer3DSlice";

import {Box, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

export default function ToggleProgressViewMode() {
  const dispatch = useDispatch();

  // data

  const openSections = useSelector((s) => s.ui.openSections);
  //{outlet, viewer3D, fixedViewersBox, callToAction}

  const viewer3D = openSections.viewer3D;
  const viewerPDF = openSections.fixedViewersBox;

  let mode = null;
  if (viewer3D) mode = "3D";
  if (viewerPDF) mode = "PDF";

  // handlers

  function handleModeChange(mode) {
    if (mode === "PDF") {
      dispatch(
        setOpenSections({
          ...openSections,
          viewer3D: false,
          fixedViewersBox: true,
        })
      );
      dispatch(setViewMode("PDF"));
      dispatch(setModuleViewMode("PDF"));
    } else if (mode === "3D") {
      dispatch(
        setOpenSections({
          ...openSections,
          viewer3D: true,
          fixedViewersBox: false,
        })
      );
      dispatch(setViewMode("3D"));
      dispatch(setModuleViewMode("3D"));
    } else {
      dispatch(
        setOpenSections({
          ...openSections,
          viewer3D: false,
          fixedViewersBox: false,
        })
      );
      dispatch(setModuleViewMode("DEFAULT"));
    }
  }

  return (
    <Box>
      <ToggleButtonGroup
        exclusive
        value={mode}
        onChange={(e, v) => handleModeChange(v)}
        size="small"
        sx={{height: 30}}
      >
        <ToggleButton value="PDF">
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{fontSize: 13}}>PDF</Typography>
          </Box>
        </ToggleButton>

        <ToggleButton value="3D">
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{fontSize: 13}}>3D</Typography>
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
