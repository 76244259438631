import {client} from "API/capla360";

export const fetchPricesService = async ({
  sceneId,
  sharedBimboxId,
}) => {
  const baseUrl = `/scenes/${sceneId}/pricings/`;
  const query = sharedBimboxId ? 
    `${baseUrl}?sharelink=${sharedBimboxId}` : baseUrl;
  const response = await client.get(query, {
    headers: {},
  });
  return response.data;
};

export const createPriceService = async ({
  sceneId,
  price,
}) => {
  const response = await client.post(
    `scenes/${sceneId}/pricings/`,
    price,
    {
      headers: {},
    }
  );
  return response.data;
};

export const updatePriceService = async ({
  sceneId,
  price,
}) => {
  const response = await client.patch(
    `scenes/${sceneId}/pricings/${price.id}/`,
    price,
    {
      headers: {},
    }
  );
  return response.data;
};

export const deletePriceService = async ({
  sceneId,
  price,
}) => {
  const priceId = price.id;
  await client.delete(
    `scenes/${sceneId}/pricings/${priceId}/`,
    {
      headers: {},
    }
  );
  return priceId;
};