import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Typography,
  Checkbox,
  Box,
  FormControlLabel,
} from "@mui/material";

import ListItemStorey from "./ListItemStorey";

import {
  toggleIfcStoreyVisibility,
  toggleAllStoreysVisibility,
  switchOneStoreyVisibility,
} from "Features/viewer3D/viewer3DSlice";
// import useTranslation from "Features/translations/useTranslation";

export default function ListStoreys({editor3d, model}) {
  const dispatch = useDispatch();
  // const {t} = useTranslation("viewer3D");

  // strings

  const allString = "All";

  const multiSelectString = "Multiple selection";

  // data

  const editorEntity = editor3d?.getEntity(model?.id);
  const ifcModelID = editorEntity?.ifcModelID;

  const ifcSubsets = useSelector((state) => state.viewer3D.ifcSubsets).filter(
    (s) => s.ifcModelID === ifcModelID
  );
  const ifcTypes = useSelector((state) => state.viewer3D.ifcTypes).filter(
    (s) => s.ifcModelID === ifcModelID
  );
  const ifcStoreys = useSelector((state) => state.viewer3D.ifcStoreys).filter(
    (s) => s.ifcModelID === ifcModelID
  );

  const storeysCount = ifcStoreys.length;
  const visibleStoreysCount = ifcStoreys.filter(
    (s) => s.visible === true
  ).length;

  const none = visibleStoreysCount === 0;
  const all = storeysCount - visibleStoreysCount === 0;
  const indeterminate = !none && !all;

  // local state

  const [allLayers, setAllLayers] = useState(true);
  const [multiSelect, setMultiSelect] = useState(false);

  // handlers

  function handleMultiSelectChange(e) {
    setMultiSelect(e.target.checked);
  }

  function handleAllLayersChange(event) {
    try {
      const newChecked = event.target.checked;
      setAllLayers((v) => !v);

      // new ifc storeys
      const newIfcStoreys = ifcStoreys.map((ifcStorey) => {
        return {...ifcStorey, visible: newChecked};
      });

      // apply filters
      editorEntity.applyFilters(newIfcStoreys, ifcTypes);

      // state
      if (newChecked)
        dispatch(toggleAllStoreysVisibility({ifcModelID, visible: true}));
      if (!newChecked)
        dispatch(toggleAllStoreysVisibility({ifcModelID, visible: false}));
    } catch (e) {
      console.log(e);
    }
  }

  function handleStoreyClick(storey) {
    const {ifcModelID, expressID: storeyExpressID} = storey;
    try {
      // state
      if (!multiSelect) {
        // update of ifcStoreys

        const newIfcStoreys = ifcStoreys.map((ifcStorey) => {
          if (ifcStorey.expressID !== storeyExpressID) {
            return {...ifcStorey, visible: false};
          } else {
            return {...ifcStorey, visible: true};
          }
        });
        editorEntity.applyFilters(newIfcStoreys, ifcTypes);
        dispatch(
          switchOneStoreyVisibility({ifcModelID, expressID: storeyExpressID})
        );
      } else {
        dispatch(
          toggleIfcStoreyVisibility({ifcModelID, expressID: storeyExpressID})
        );

        // update of ifcStoreys

        const newIfcStoreys = ifcStoreys.map((ifcStorey) => {
          if (ifcStorey.expressID !== storeyExpressID) {
            return ifcStorey;
          } else {
            const newIfcStorey = {...ifcStorey};
            newIfcStorey.visible = !ifcStorey.visible;
            return newIfcStorey;
          }
        });

        // simple
        editorEntity.applyFilters(newIfcStoreys, ifcTypes);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    // update all layers checkbox
    if (all) setAllLayers(true);
    if (none) setAllLayers(false);
  }, [visibleStoreysCount]);

  return (
    <Box>
      <Box sx={{mt: 2, "&>*:not(:last-child)": {mb: 1}}}>
        {ifcStoreys
          ?.sort((a, b) => b.height - a.height)
          .map((storey) => {
            const count = ifcSubsets
              .filter((s) => s.storeyExpressID === storey.expressID)
              .reduce((ac, subset) => ac + subset.ids.length, 0);

            return (
              <ListItemStorey
                key={storey.expressID}
                storey={storey}
                onClick={handleStoreyClick}
                count={count}
              />
            );
          })}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              value={multiSelect}
              onChange={handleMultiSelectChange}
            />
          }
          label={
            <Typography sx={{fontSize: 12}}>{multiSelectString}</Typography>
          }
        />
        <Box sx={{display: "flex", alignItems: "center", paddingLeft: 1}}>
          <Checkbox
            size="small"
            checked={allLayers}
            indeterminate={indeterminate}
            onChange={handleAllLayersChange}
          />
          <Typography sx={{fontSize: 12}}>{allString}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
