import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import ListItemSector from "./ListItemSector";

// import {
//   updateModel,
//   setViewMode,
//   setMultiviews,
// } from "Features/viewer3D/viewer3DSlice";
import {setShortcutSectors} from "Features/measurements/measurementsSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
// import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useSectorsAndZonesOfMeasurements from "Features/measurements/useSectorsAndZonesOfMeasurements";

export default function ShortcutSectors({scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const sectors = useSectorsByScene(scene);
  const zones = useZonesByScene(scene);
  const models = useSelector((m) => m.viewer3D.models);
  const mode = useMeasurementsScopeMode(scene, viewer);

  const [ss, _] = useSectorsAndZonesOfMeasurements(scene, mode, true);

  const selection = useSelector((s) => s.measurements.shortcutSectors);

  // helpers - items

  const items = ss
    .map(({id, _}) => {
      const sector = sectors.find((s) => s.id === id);
      return sector ? sector : {id: null, z: -1000};
    })
    .sort((a, b) => b.z - a.z);

  // helper

  // function setViewBySector(sector) {
  //   const sectorId = sector?.id;
  //   const sectorZ = sector?.z;

  //   const selectedZones = zones.filter((z) => z.sectorId === sectorId);
  //   const zoneImageModelIds = selectedZones.map((z) => z.imageModelId);

  //   const allZoneImageModels = models.filter(
  //     (m) => m.type === "IMAGE" && m.fromModel
  //   );

  //   // models - hidden

  //   allZoneImageModels.forEach((model) => {
  //     const updatedModel = {
  //       ...model,
  //       hidden: !zoneImageModelIds.includes(model.id),
  //     };
  //     dispatch(updateModel({updatedModel, sync: false}));
  //   });

  //   // 3D => 2D

  //   editor.setViewMode("MULTIVIEWS");
  //   editor.multiviews.hide3D();
  //   dispatch(setViewMode("MULTIVIEWS"));
  //   dispatch(setMultiviews(true));

  //   // camera & images opacity
  //   // at the end to get the multiviews.el1 before

  //   editor.sceneEditor.setViewBySector({zoneImageModelIds});

  //   // camera2 (walkthrought mode)

  //   if (typeof sectorZ === "number") editor.sceneEditor.setCam2Height(sectorZ);
  // }

  // handlers

  function handleSectorClick(sector) {
    let newSelection;
    if (selection.includes(sector.id)) {
      newSelection = selection.filter((id) => id !== sector.id);
    } else {
      newSelection = [...selection, sector.id];
    }
    dispatch(setShortcutSectors(newSelection));
  }

  return (
    <Box sx={{mt: 1, "&>*:not(:last-child)": {mb: 1}}}>
      {items.map((sector) => (
        <ListItemSector
          key={sector.id}
          sector={sector}
          onClick={handleSectorClick}
          selected={selection.includes(sector.id)}
        />
      ))}
    </Box>
  );
}
