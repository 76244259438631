import {useState} from "react";
// import {useDispatch} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useSelectedBimapp from "../hooks/useSelectedBimapp";
import useDeleteSceneBimapp from "../hooks/useDeleteSceneBimapp";

// import useAccessToken from "Features/auth/useAccessToken";
import DeleteDialog from "Features/ui/components/DeleteDialog";

export default function ButtonMoreActionsBimapp({scene}) {
  // const dispatch = useDispatch();
  // const accessToken = useAccessToken();

  // strings

  const deleteS = "Delete";

  // data

  const selectedBimapp = useSelectedBimapp(scene);
  const deleteBimapp = useDeleteSceneBimapp(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);
    deleteBimapp(selectedBimapp);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - tools

  const tools = [
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"bimapp"}
      />
    </>
  );
}
