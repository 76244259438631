// NEEDED ?

import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

import {
  Box,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  // Typography,
  Divider,
} from "@mui/material";

import MenuTreeVersion from "./MenuTreeVersion";

import useSceneModule from "../useSceneModule";
import {setOpenedParents} from "../navigationSlice";
import useSceneModulePathMap from "../useSceneModulePathMap";
import useSceneModuleNameMap from "../useSceneModuleNameMap";
import useSceneModuleIconMap from "../useSceneModuleIconMap";

// import useScene from "Features/scenes/useScene";
// import useMarkersModels from "Features/markers/hooks/useMarkersModels";

export default function MenuItemsScene() {
  const navigate = useNavigate();
  const {sceneId} = useParams();
  // const scene = useScene(sceneId);
  const sceneModule = useSceneModule();
  const dispatch = useDispatch();

  // data

  // const markersModels = useMarkersModels(scene?.clientId);
  // const models = useSelector((s) => s.viewer3D.models).filter(
  //   (m) => m.sceneClientID === scene?.clientID
  // );

  const pathMap = useSceneModulePathMap(sceneId);
  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  const openedParents = useSelector((s) => s.navigation.openedParents);

  // helpers - items

  const itemCodes = [
    "HOME",
    "ADMIN",
    "ASSISTANT",
    "BUILD",
    "SECTORS",
    "ROOMS",
    "PHASES",
    "VERSIONS",
    "ELEMENT_TYPES",
    "RESSOURCES",
    "MARKERS",
    "DATA",
    "VIEWER_3D",

    "INPUTS",
    "ZONES",

    "MEASUREMENTS",
    "PACKAGES",
    "EDIT_MEASUREMENTS",

    "DATASETS",
    "ISSUESETS",
    "ISSUES",
    "PROGRESS",

    "BIMAPPS",
  ];

  const items = itemCodes.map((code) => {
    return {
      name: nameMap[code],
      icon: iconMap[code],
      path: pathMap[code],
      itemKey: code,
      show: true,
    };
  });
  // helpers - tree

  const tree = [
    {parentKey: "ADMIN", childrenKeys: ["ASSISTANT"]},
    {isDivider: true},
    {parentKey: "HOME"},
    {
      parentKey: "BUILD",
      childrenKeys: [
        "SECTORS",
        "ROOMS",
        "PHASES",
        "VERSIONS",
        "ELEMENT_TYPES",
        "RESSOURCES",
        "MARKERS",
        "DATA",
        "VIEWER_3D",
      ],
    },
    {parentKey: "INPUTS", childrenKeys: ["ZONES"]},

    {
      parentKey: "MEASUREMENTS",
      childrenKeys: ["PACKAGES", "EDIT_MEASUREMENTS"],
    },

    {parentKey: "DATASETS", childrenKeys: ["ISSUESETS", "ISSUES", "PROGRESS"]},
    {parentKey: "BIMAPPS"},
  ];
  // handlers

  function handleClick(path) {
    navigate(path);
  }

  function handleOpenedChange(openedParents) {
    dispatch(setOpenedParents(openedParents));
  }
  return (
    <Box>
      <MenuTreeVersion
        items={items}
        tree={tree}
        openedParents={openedParents}
        onChange={handleOpenedChange}
      />
      {false &&
        items.map(({name, icon, path, key, show}) => {
          if (!show) return;
          const selected = key === sceneModule;
          return (
            <Box key={key}>
              {name === "DIVIDER" ? (
                <Divider sx={{my: 2, mx: 2}} />
              ) : (
                <ListItemButton
                  onClick={() => handleClick(path)}
                  selected={selected}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    sx={{my: 0}}
                    primary={name}
                    primaryTypographyProps={{
                      fontSize: (theme) => theme.typography.body2.fontSize,
                    }}
                  />
                </ListItemButton>
              )}
            </Box>
          );
        })}
    </Box>
  );
}
