import {Button} from "@mui/material";

import {useMsal} from "@azure/msal-react";

import {loginRequest} from "authConfig";

const SignInButton = () => {
  const {instance} = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <>
      <Button
        onClick={handleLogin}
        variant="contained"
        color="primary"
        disableElevation
        sx={{width: 1}}
      >
        Se connecter
      </Button>
    </>
  );
};

export default SignInButton;
