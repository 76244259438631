import {useSelector} from "react-redux";

// import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";
import useFilteredMeasurementsFromPackages from "./useFilteredMeasurementsFromPackages";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import computeRessourceQuantity, {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function useMeasurementsByRessource(scene, mode, typeId = null) {
  // data

  // let [measurements] = useFilteredSceneMeasurements(scene, mode);
  let measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });
  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const zones = useZonesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);
  if (!measurements) return [];

  const dontUseForQuantities = new Set(models
    .filter((m) => m.excludeFromQties)
    .map((m) => m.id)
  );
  if (dontUseForQuantities.size > 0)
    measurements = measurements.filter(
      (m) => !dontUseForQuantities.has(m.measurementsModelId)
    );

  // helpers

  const zonesById = {};
  for (const item of zones) {
    zonesById[item.id] = item;
  }
  const roomsById = {};
  for (const item of rooms) {
    roomsById[item.id] = item;
  }
  const sectorsById = {};
  for (const item of sectors) {
    sectorsById[item.id] = item;
  }
  const ressourcesById = {};
  for (const item of ressources) {
    ressourcesById[item.id] = item;
  }
  const elementTypesById = {};
  for (const item of elementTypes) {
    elementTypesById[item.id] = item;
  }

  const mByResMap = {};
  measurements.forEach((measurement) => {
    if (typeId && measurement.elementTypeId !== typeId) return;
    const mres = measurement.res;
    if (!mres) return;
    const elementType = elementTypesById[measurement.elementTypeId];
    if (!elementType) return;
    mres
      .filter((r) => !r.off)
      .forEach((mr) => {
        const resId = mr.resId;
        const ressource = ressourcesById[resId];
        if (!ressource) return;
        let res;
        if (elementType?.res && Array.isArray(elementType.res))
          res = elementType?.res?.find((r) => resId === r.resId);
        if (!res) res = {resId, mode: "AUTO"}; // if formula not in elementType, by default, we compute with mode="AUTO"
        const useMeas = applyFormulaFilter(
          measurement,
          res.func,
          zonesById[measurement.zoneId],
          roomsById[measurement.roomId],
          sectorsById[measurement.sectorId],
          filterNoVoids
        );
        if (useMeas) {
          // let mQ = filterNoVoids ? mr.qtyRaw : mr.qtyNet;
          let mQ;
          if (!mQ) {
            mQ = computeRessourceQuantity({
              item: measurement,
              res,
              ressource,
              withDecimal: false,
              filterNoVoids,
            });
          }
          const mByRes = mByResMap[resId];
          if (!mByRes) {
            if (ressource?.hardcodedQty)
              mByResMap[resId] = parseFloat(ressource.hardcodedQty);
            else mByResMap[resId] = mQ;
          } else {
            if (ressource?.hardcodedQty)
              mByResMap[resId] = parseFloat(ressource.hardcodedQty);
            else mByResMap[resId] += mQ;
          }
        }
      });
  });
  return mByResMap;
}
