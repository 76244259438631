import {useState} from "react";

import {IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import DialogCreateNomenclature from "./DialogCreateNomenclature";

export default function IconButtonDialogCreateNomenclature({
  onNomenclatureCreated,
}) {
  const [open, setOpen] = useState(false);

  // handlers

  function handleClose() {
    setOpen(false);
  }
  function handleNomenclatureCreated(nomenclature) {
    onNomenclatureCreated(nomenclature);
    setOpen(false);
  }
  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Add fontSize="small" />
      </IconButton>
      <DialogCreateNomenclature
        open={open}
        onClose={handleClose}
        onNomenclatureCreated={handleNomenclatureCreated}
      />
    </>
  );
}
