import getItemsMapById from "Utils/getItemsMapById";
import excelRowToElementType from "./excelRowToElementType";
import sortByNum from "Utils/sortByNum";
import getNextNumWithRank1 from "Utils/getNextNumWithRank1";
import getItemsWithNums from "Features/library/utils/getItemsWithNums";
import getItemsWithNumsFromItemsWithStylesBasic from "Utils/getItemsWithNumsFromItemsWithStyles";
import {nanoid} from "@reduxjs/toolkit";

export default function getUpdatedElementTypesGroupFromExcelRows(
  elementTypesGroup,
  excelRows
) {
  let oldItems = elementTypesGroup?.elementTypes;
  if (!Array.isArray(oldItems)) oldItems = [];
  const oldItemsByIds = getItemsMapById(oldItems);

  // items from Excel

  const itemsFromExcel = [];
  for (let row of excelRows) {
    const item = excelRowToElementType(row);
    if (item.name || item.toDelete)
      itemsFromExcel.push(excelRowToElementType(row));
  }

  console.log("itemsFromExcel", itemsFromExcel);

  // items to update

  const itemsToUpdateIds = itemsFromExcel
    .filter((item) => item.id)
    .map((item) => item.id);

  // items to delete

  const itemsToDeleteIds = itemsFromExcel
    .filter((item) => item.id && item.toDelete)
    .map((item) => item.id);

  // unchanged items (we remove items with no name)

  const unchangedItems = oldItems.filter(
    (item) => !itemsToUpdateIds.includes(item.id) && item.name?.length > 0
  );

  // add id to itemsFromExcel

  let itemsFromExcelWithIds = itemsFromExcel.map((item) => {
    if (!item.id || !oldItemsByIds[item.id]) {
      return {...item, id: nanoid()};
    } else {
      return item;
    }
  });

  // remove itemsToDelete

  itemsFromExcelWithIds = itemsFromExcelWithIds.filter(
    (item) => !item.toDelete
  );

  // add num to itemsFromExcel

  const sortedUnchangedItems = unchangedItems.sort((a, b) =>
    sortByNum(a.num, b.num)
  );
  const lastNum = sortedUnchangedItems[sortedUnchangedItems.length - 1]?.num;
  const nextNum = getNextNumWithRank1(lastNum);
  const itemsFromExcelWithIdsAndNums = getItemsWithNumsFromItemsWithStylesBasic(
    itemsFromExcelWithIds,
    nextNum
  );

  // merge oldItems and itemsFromExcel

  const itemsFromExcelWithIdsAndNumsMergedWithOldItems =
    itemsFromExcelWithIdsAndNums
      .map((item) => {
        const oldItem = oldItemsByIds[item.id];
        return oldItem ? {...oldItem, ...item} : item;
      })
      .filter((item) => !itemsToDeleteIds.includes(item.id));

  // new items

  const newItems = [
    ...unchangedItems,
    ...itemsFromExcelWithIdsAndNumsMergedWithOldItems,
  ];

  // return

  const newGroup = {...elementTypesGroup, elementTypes: newItems};

  return newGroup;
}
