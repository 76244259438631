import {createSlice} from "@reduxjs/toolkit";

export const shortcutSlice = createSlice({
    name: "shortcut",
    initialState: {
        selectedShortcutRight: "",
    },
    reducers: {
      setSelectedShortcutRight: (state, action) => {
        state.selectedShortcutRight = action.payload;
      },
    },
  });
  
  export const {
    setSelectedShortcutRight,
  } = shortcutSlice.actions;
  
  export default shortcutSlice.reducer;