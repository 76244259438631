import React from "react";

import {useSelector} from "react-redux";

import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FormBimapp({
  scene,
  bimapp,
  onChange,
  canEdit,
  hiddenFields = [],
}) {
  // strings

  const nameS = "Nom";
  const descriptionS = "Description";
  const imageS = "Image";
  const zonesS = "Zones";
  const momosS = "Modules (mobiles)";
  const listingsS = "Jeux de données";
  const packagesS = "Calques d'éléments";

  // data

  const models = useSelector((s) => s.viewer3D.models);

  // helpers

  function getPdfModelIdsFromZoneIds(models, zoneIds) {
    const zoneModels = models.filter((m) =>
      zoneIds.includes(m.fromModel?.zoneId)
    );
    return [...new Set(zoneModels.map((m) => m.fromModel?.modelId))];
  }
  // item

  const item = {};
  if (bimapp?.id) item.id = bimapp?.id;
  item.name = bimapp?.name;
  item.description = bimapp?.description;
  item.image = {url: bimapp?.ogImageUrl, name: bimapp?.name};
  item.zoneIds = bimapp?.entities?.zoneIds ? bimapp.entities.zoneIds : [];
  item.pdfIds = bimapp?.entities?.pdfIds ? bimapp.entities.pdfIds : [];
  item.momoCodes = bimapp?.entities?.momoCodes ? bimapp.entities.momoCodes : [];
  item.listingIds = bimapp?.entities?.listingIds
    ? bimapp.entities.listingIds
    : [];
  item.packageIds = bimapp?.entities?.packageIds
    ? bimapp.entities.packageIds
    : [];

  // handler

  function handleChange(item) {
    const bimapp = {};
    if (item?.id) bimapp.id = item?.id;
    bimapp.name = item?.name;
    bimapp.description = item?.description;
    bimapp.ogImageUrl = item?.image?.url;

    bimapp.entities = {};
    //zones
    bimapp.entities.zoneIds = item.zoneIds;
    //pdf
    if (item.zoneIds?.length > 0) {
      bimapp.entities.pdfIds = getPdfModelIdsFromZoneIds(models, item.zoneIds);
    }
    //momos
    bimapp.entities.momoCodes = item.momoCodes;
    // listings
    bimapp.entities.listingIds = item.listingIds;
    // packages
    bimapp.entities.packageIds = item.packageIds;

    // end
    onChange(bimapp);
  }

  // template

  const template = {
    key: "kvfSector",
    fields: [
      {key: "name", type: "text", name: nameS},
      {key: "description", type: "textMultiline", name: descriptionS},
      {key: "image", type: "image", name: imageS},
      {key: "zoneIds", type: "zones", name: zonesS},
      {key: "packageIds", type: "packageIds", name: packagesS},
      //{key: "momoCodes", type: "momos", name: momosS},
      {key: "listingIds", type: "listingIds", name: listingsS},
    ],
  };
  return bimapp ? (
    <KeyValueForm
      scene={scene}
      item={item}
      template={template}
      onChange={handleChange}
      hiddenFields={hiddenFields}
      canEdit={canEdit}
    />
  ) : (
    <div />
  );
}
