import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {setDatagridIsInEditMode, setEditedRessources} from "../ressourcesSlice";

import {
  Dialog,
  IconButton,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";

import DataGridRessourcesGroup from "./DataGridRessourcesGroup";
import ToolbarEditDatagrid from "./ToolbarEditDatagrid";
import ToolbarEditRessourcesInDatagrid from "./ToolbarEditRessourcesInDatagrid";
import useUpdateSceneRessources from "../hooks/useUpdateSceneRessources";
import useRenameSceneRessourcesGroup from "../hooks/useRenameSceneRessourcesGroup";

export default function DialogFsEditRessourcesGroup({
  open,
  initRessourcesGroup,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();

  // strings

  const cancelS = "Annuler";
  const saveS = "Enregistrer";

  const groupS = "Tableau d'articles";

  // data

  const renameSceneRessourcesGroup = useRenameSceneRessourcesGroup(scene);

  // state - name

  const initName = initRessourcesGroup?.name ?? "";
  const [tempName, setTempName] = useState(initName);
  useEffect(() => {
    setTempName(initName);
  }, [initName]);

  // state - loading

  const [loading, setLoading] = useState(false);

  // init - ressources

  // ressources are updated when the dialog open.
  // ressources are managed with the editedRessources

  useEffect(() => {
    if (open) {
      dispatch(setEditedRessources(initRessourcesGroup.ressources));
      dispatch(setDatagridIsInEditMode(true));
    }
  }, [open]);

  // handlers

  function handleCancel() {
    onClose();
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    await renameSceneRessourcesGroup(initName, tempName);
    onClose();
  }

  return (
    <Dialog fullScreen open={open}>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
            bgcolor: "background.default",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{fontSize: 13, color: "text.secondary", mr: 2}}>
              {groupS}
            </Typography>
            <TextField
              sx={{minWidth: 250}}
              InputProps={{sx: {fontSize: 13, fontWeight: "bold"}}}
              size="small"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
            />
          </Box>
          <Box sx={{display: "flex"}}>
            <Button sx={{mr: 1}} size="small" onClick={handleCancel}>
              {cancelS}
            </Button>
            <Button
              disabled={!tempName || loading}
              size="small"
              onClick={handleSave}
            >
              {saveS}
            </Button>
          </Box>
        </Box>
        <ToolbarEditRessourcesInDatagrid scene={scene} />
        <Box sx={{display: "flex", flexGrow: 1, width: 1}}>
          <DataGridRessourcesGroup scene={scene} />
        </Box>
      </Box>
    </Dialog>
  );
}
