import React from "react";

import {Box, Paper, Typography} from "@mui/material";

export default function ListItemTypeCode({
  typeCode,
  selected = false,
  onClick,
  color,
}) {
  // handlers

  function handleClick() {
    onClick(typeCode);
  }

  // helper

  const typeColor = `rgb(${color[0]},${color[1]},${color[2]})`;

  const border = `1px solid ${typeColor}`;
  const textColor = selected ? "common.white" : typeColor;
  const bgcolor = selected ? typeColor : "common.white";

  return (
    <Paper
      sx={{
        px: 1,
        py: 0.5,
        cursor: "pointer",
        width: "60px",
        height: "24px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        bgcolor,
        border,
        color: textColor,
      }}
      elevation={selected ? 3 : 0}
      onClick={handleClick}
    >
      <Typography
        noWrap
        sx={{fontWeight: selected ? "bold" : "normal", fontSize: 13}}
      >
        {typeCode}
      </Typography>
    </Paper>
  );
}
