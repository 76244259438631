export default function getBuildingSectorsMap(sectors) {
  const map = {};
  sectors.forEach((sector) => {
    const buildingItem = map[sector.building];
    if (!buildingItem) {
      map[sector.building] = [sector];
    } else {
      map[sector.building].push(sector);
    }
  });
  return map;
}
