import {Box, Typography, Paper} from "@mui/material";

import SectionContextOverviewInTopBar from "./SectionContextOverviewInTopBar";

export default function SectionMainScopeInTopBar({
  scene,
  caplaEditor,
  viewer,
  shrink,
}) {
  return (
    <Box sx={{display: "flex"}}>
      <SectionContextOverviewInTopBar
        caplaEditor={caplaEditor}
        scene={scene}
        viewer={viewer}
        shrink={shrink}
      />
    </Box>
  );
}
