// import {useDispatch, useSelector} from "react-redux";
// import {nanoid} from "@reduxjs/toolkit";

import {Box, IconButton} from "@mui/material";
import {AddAPhoto} from "@mui/icons-material";

import useTakeEditorScreenshot from "Features/viewer3D/useTakeEditorScreenshot";
// import {setMainSection, setScreenshot} from "Features/viewer3D/viewer3DSlice";


export default function ButtonScreenshot({caplaEditor, scene}) {
  // const dispatch = useDispatch();

  // data

  // const viewMode = useSelector((state) => state.viewer3D.viewMode);
  const takeScreenshot = useTakeEditorScreenshot({
    caplaEditor,
    scene,
  });
  // handlers

  function handleClick() {
    takeScreenshot();
  }

  // function handleClick_old() {
  //   console.log("viewMode", viewMode);
  //   // first render
  //   if (caplaEditor) {
  //     if (viewMode === "3D") {
  //       const camera = caplaEditor.cameras.activeCamera;
  //       caplaEditor.renderer.render(caplaEditor.scene, camera);
  //     } else if (viewMode === "MULTIVIEWS") {
  //       const camera1 = caplaEditor.cameras.camera1;
  //       caplaEditor.label1Renderer.render(caplaEditor.scene, camera1);
  //       caplaEditor.renderer.render(caplaEditor.scene, camera1);
  //       console.log("screenshot multiviews");
  //     }
  //     // then cature
  //     const canvas = caplaEditor.canvas;
  //     canvas.toBlob(async (blob) => {
  //       const fileName = `bb-${nanoid()}.png`;
  //       const newFile = new File([blob], fileName, {type: "image/png"});
  //       dispatch(setMainSection("SCREENSHOTVIEWER"));
  //       dispatch(setScreenshot({url: URL.createObjectURL(newFile)}));
  //     });
  //   }
  // }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "common.white",
      }}
    >
      <IconButton onClick={handleClick} color="inherit" size="small">
        <AddAPhoto fontSize="small" />
      </IconButton>
    </Box>
  );
}
