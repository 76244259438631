import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import DataGridMeasurements from "./DataGridMeasurements";
import DataGridMeasurementsByElementType from "./DataGridMeasurementsByElementType";
import DataGridMeasurementsByRessource from "./DataGridMeasurementsByRessource";
import DataGridMeasurementsByRoom from "./DataGridMeasurementsByRoom";

export default function SectionDataGridMeasurements({
  caplaEditor,
  scene,
  mode,
  withProgress,
}) {
  // data

  const dataGridMode = useSelector((s) => s.measurements.dataGridMode);

  return (
    <Box sx={{width: 1, height: 1}}>
      {dataGridMode === "ALL" && (
        <DataGridMeasurements caplaEditor={caplaEditor} mode={mode} scene={scene} />
      )}
      {dataGridMode === "BY_ELEMENT_TYPE" && (
        <DataGridMeasurementsByElementType
          withProgress={withProgress}
          editor3d={caplaEditor?.editor3d}
          mode={mode}
          scene={scene}
        />
      )}
      {dataGridMode === "BY_RESSOURCE" && (
        <DataGridMeasurementsByRessource
          withProgress={withProgress}
          caplaEditor={caplaEditor}
          mode={mode}
          scene={scene}
        />
      )}
      {dataGridMode === "BY_ROOM" && (
        <DataGridMeasurementsByRoom caplaEditor={caplaEditor} mode={mode} scene={scene} />
      )}
    </Box>
  );
}
