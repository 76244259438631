import {Box, IconButton, Tooltip, Paper} from "@mui/material";
import {ZoomOutMap as ZoomOut} from "@mui/icons-material";

export default function ButtonZoomOut({editor3d, location = "toolbar"}) {
  // strings

  const tipString = "Zoom out";

  // handlers

  function handleClick() {
    editor3d?.sceneEditor.zoomOut();
  }

  //
  if (location === "toolbar") {
    return (
      <Tooltip title={tipString}>
        <Box sx={{bgcolor: "common.caplaBlack", color: "common.white"}}>
          <IconButton
            sx={{width: 30, height: 30, borderRadius: 1, color: "inherit"}}
            onClick={handleClick}
          >
            <ZoomOut fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={tipString}>
        <Paper
          elevation={1}
          sx={{
            p: 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 34,
            width: 34,
          }}
        >
          <IconButton sx={{borderRadius: "4px"}} onClick={handleClick}>
            <ZoomOut fontSize="small" />
          </IconButton>
        </Paper>
      </Tooltip>
    );
  }
}
