import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Button,
  Typography,
} from "@mui/material";

import {Explore as North} from "@mui/icons-material";

export default function ButtonSetNorth({scene, editor}) {
  const dispatch = useDispatch();

  // strings

  const titleString = "Set the bimbox orientation";

  const rotString = "Angle (deg) to North";

  const saveString = "Save";

  // props

  const rotation = scene?.rotation;

  // initState

  const initRot = rotation ? `${rotation}` : "0";

  // state

  const [open, setOpen] = useState(false);

  const [rot, setRot] = useState(initRot);
  useEffect(() => {
    setRot(initRot);
  }, [rotation]);

  // helper

  const canSave = rot.length > 0;

  // handlers

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // handlers - form

  function handleRotChange(e) {
    let r = e.target.value;
    r = r.replace(",", ".");
    setRot(r);
  }

  // handlers - action

  async function handleSaveClick() {
    const rotation = parseFloat(rot);
    const newScene = {...scene, rotation};
    await dispatch(updateScene({scene: newScene}));
    setOpen(false);
  }

  return (
    <>
      <IconButton
        sx={{
          width: 34,
          height: 34,
          borderRadius: "4px",
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        onClick={handleOpen}
      >
        <North fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{titleString}</DialogTitle>
        <DialogContent>
          <Box sx={{mt: 2, display: "flex", alignItems: "center"}}>
            <TextField
              label={<Typography variant="body2">{rotString}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={rot}
              onChange={handleRotChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={!canSave} onClick={handleSaveClick}>
            {saveString}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
