import {useState} from "react";
import {useMsal} from "@azure/msal-react";
import {Button, Avatar, IconButton, Box, Typography} from "@mui/material";
import {Login} from "@mui/icons-material";

import {loginRequest} from "authConfig";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

function SignInButton({avatar = false, ...props}) {
  const {t} = useTranslation("auth");
  const {instance} = useMsal();

  const color = props.color;

  const handleLogin = () => {
    try {
      let usePopup = window.parent !== window;
      usePopup = true;
      if (usePopup) {
        instance.loginPopup(loginRequest);
      } else {
        instance.loginRedirect(loginRequest);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{color: color ? color : "primary.flash"}}>
      {avatar ? (
        <IconButton onClick={handleLogin}>
          <Login />
        </IconButton>
      ) : (
        <Button
          {...props}
          variant="outlined"
          color="inherit"
          onClick={handleLogin}
          disableElevation
          size="small"
          startIcon={<Login />}
        >
          <Typography variant="body2" noWrap>
            {capitalizeStart(t("auth:login"))}
          </Typography>
        </Button>
      )}
    </Box>
  );
}

export default SignInButton;
