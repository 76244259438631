import {useState} from "react";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import {
  TextField,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import {postNote} from "Features/notes/notesSlice";
// import {
//   setStoryDefaultId,
//   updateScene,
//   updateStory,
// } from "Features/scenes/scenesSlice";
import {uploadFileService} from "Features/files/services";
import useTranslation from "Features/translations/useTranslation";
// import {capitalizeStart} from "Features/translations/utils";
import useAccessToken from "Features/auth/useAccessToken";
import {createNoteFromTitleAndScreenshot} from "Features/notes/notesUtils";
import {setSelectedNoteId} from "Features/viewer3D/viewer3DSlice";

export default function FormAddNote({
  editor3d,
  sceneListingId,
  screenshot,
  onCreate,
}) {
  const dispatch = useDispatch();
  const {t} = useTranslation(["scenes", "common"]);
  const accessToken = useAccessToken();

  // string

  const frameTitleString = t("scenes:stories.story.frame.title");

  const addFrameString = "Add the note";

  // state

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");

  // helpers

  const canCreate = title.length > 0;

  // handlers

  function handleClick(e) {
    e.stopPropagation();
  }

  async function handleCreateClick() {
    if (!loading && canCreate) {
      if (accessToken && sceneListingId) {
        setLoading(true);

        // post file

        const url = editor3d?.annotator.stage.toDataURL();
        const fileName = `note-image-${nanoid()}.png`;
        const response = await fetch(url);
        const blob = await response.blob();
        let file;
        let remoteUrl;
        file = new File([blob], fileName, {type: "image/png"});

        if (file) {
          remoteUrl = await uploadFileService({
            listingId: sceneListingId,
            accessToken,
            file,
            container: "listing-files",
          });
        }

        // post note
        const newScreenshot = {...screenshot, url: remoteUrl};
        const note = createNoteFromTitleAndScreenshot(title, newScreenshot);
        const result = await dispatch(
          postNote({note, listingId: sceneListingId, accessToken})
        );
        const newNote = unwrapResult(result);
        console.log("New note added", newNote);
        dispatch(setSelectedNoteId(newNote.data.id));

        setLoading(false);
      }
      onCreate();
    }
  }
  return (
    <Paper
      sx={{
        border: (theme) => `1px solid ${theme.palette.divider} `,
        bgcolor: "common.caplaBlack",
        p: 1,
        color: "common.white",
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{width: 300, mr: 2}}>
          <TextField
            sx={{
              "& label": {
                color: "common.white",
              },
              "& label.Mui-focused": {
                color: "common.white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "common.white",
                },
                "&:hover fieldset": {
                  borderColor: "primary.main",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "primary.main",
                },
              },
            }}
            fullWidth
            size="small"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputProps={{
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
                color: "common.white",
              },
            }}
            label={
              <Typography variant="body2" color="inherit">
                {frameTitleString}
              </Typography>
            }
          />
        </Box>

        <Button
          sx={{m: 1, whiteSpace: "nowrap"}}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : <Add />
          }
          size="small"
          variant="contained"
          onClick={handleCreateClick}
        >
          {addFrameString}
        </Button>
      </Box>
    </Paper>
  );
}
