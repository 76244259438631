import {useSelector} from "react-redux";

import ContainerLoadingEntity from "./ContainerLoadingEntity";

export default function ContainerLoadingProgressModelsHeader({
  scene,
  onExpandChange,
  expand,
}) {
  // data

  const sceneOverview = useSelector((s) => s.overviewer.sceneOverview);
  const models = useSelector((s) => s.viewer3D.models);
  const loadingStatusMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );
  const sceneIdOfSceneLight = useSelector(
    (s) => s.overviewer.sceneIdOfSceneLight
  );

  // helpers

  const sceneModels = models.filter(
    (m) => m.sceneClientId === sceneOverview?.clientId
  );

  const modelIdsInState = loadingStatusMap["IN_STATE"];

  // helpers - totalCount

  const totalCount = sceneModels?.length;

  // helper - counter - models in state.

  let countInState = 0;
  sceneModels.forEach((model) => {
    if (modelIdsInState.includes(model.id)) countInState += 1;
  });

  // helper - ratio

  let ratio = totalCount ? (countInState / totalCount) * 100 : 0;

  // helpers - states

  let state = "START";
  if (sceneIdOfSceneLight && sceneIdOfSceneLight === sceneOverview?.id)
    state = "WIP";
  if (ratio > 99) state = "END";

  return (
    <ContainerLoadingEntity
      ratio={ratio}
      totalCount={totalCount}
      state={state}
      type={"BIMBOX"}
      expand={expand}
      onExpandChange={onExpandChange}
    />
  );
}
