import React from "react";

import useSceneListings from "Features/viewer3D/useSceneListings";
import {useSelector, useDispatch} from "react-redux";
import {setSelectedNoteId} from "Features/viewer3D/viewer3DSlice";

import {Button, Box} from "@mui/material";
import {ArrowBackIos as Arrow} from "@mui/icons-material";

export default function NoteSectionHeader({onBackClick, scene}) {
  // data
  const {status, data: listings} = useSceneListings({sceneId: scene?.id});
  const listingId = useSelector((state) => state.viewer3D.sceneListingId);

  const listing = listings.find((l) => l.id === listingId);
  const listingName = listing?.name;

  // handlers

  function handleBackClick() {
    onBackClick();
  }
  return (
    <Box>
      <Button
        size="small"
        startIcon={<Arrow fontSize="small" />}
        onClick={handleBackClick}
      >
        {listingName}
      </Button>
    </Box>
  );
}
