import React, {useState} from "react";
import {useSelector} from "react-redux";

import useUpdateSceneWithElementTypes from "../hooks/useUpdateSceneWithElementTypes";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getElementTypesWithRessourcesFromFile from "Features/excel/utils/getElementTypesWithRessourcesFromFile";
import getTypeRessourceMapFromFile from "Features/excel/utils/getTypeRessourceMapFromFile";
import excelTypeRessourceMapToElementTypesWithRessources from "../utils/excelTypeRessourceMapToElementTypesWithRessources";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";
import updateElementTypesGroupWithManyUpdatedTypes from "../utils/updateElementTypesGroupWithManyUpdatedTypes";

export default function DialogImportTypeRessourceMapFromExcel({
  open,
  onClose,
  scene,
}) {
  // strings

  const title = "Importez les liens type <=> article";

  // const message = `L'import utilise la colonne "code" pour savoir s'il faut créer une nouvelle zone ou modifier la zone existance.`;

  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  const buttonName = "Choisir un fichier";

  // data

  const updateElementTypes = useUpdateSceneWithElementTypes(scene);
  const updateElementTypesGroup = useUpdateElementTypesGroup();

  const sceneElementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const sceneRessources = useRessourcesByScene(scene, {filterByScope: true});
  const selectedTypesGroup = useSelectedElementTypesGroup(scene);
  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  // state

  const [elementTypes, setElementTypes] = useState(null);
  const [file, setFile] = useState(null);

  // handlers

  async function handleFileChange(file) {
    setFile(file);

    const typeRessourceMap = await getTypeRessourceMapFromFile(file);
    const elementTypesWithRessources =
      excelTypeRessourceMapToElementTypesWithRessources(
        typeRessourceMap,
        sceneElementTypes,
        sceneRessources
      );

    setElementTypes(
      elementTypesWithRessources.filter(
        (t) => t.group === selectedGroupProxy.name
      )
    );
    console.log("elementTypesToLoad", elementTypesWithRessources);
  }
  function handleCancelClick() {
    onClose();
  }
  async function handleSaveClick() {
    if (!selectedTypesGroup) {
      updateElementTypes(elementTypes);
    } else {
      const updatedGroup = updateElementTypesGroupWithManyUpdatedTypes(
        elementTypes,
        selectedTypesGroup
      );
      await updateElementTypesGroup(updatedGroup);
    }

    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          {/* <Box sx={{bgcolor: "background.default", p: 1}}>
            <Typography variant="body2">{message}</Typography>
          </Box> */}
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
