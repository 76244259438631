import React, {useState} from "react";

import {nanoid} from "@reduxjs/toolkit";
import {uploadFileService} from "Features/files/services";
import useAccessToken from "Features/auth/useAccessToken";

import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function FormColoringJob({coloringJob, onChange, sceneId}) {
  const accessToken = useAccessToken();

  // strings

  const nameS = "Name";
  const descriptionS = "Description";
  const selectImageS = "Select an image";
  const isOpenedS = "Opened";

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const name = coloringJob?.name;
  const description = coloringJob?.description;
  const imageUrl = coloringJob?.imageUrl;
  const isOpened = coloringJob?.isOpened;

  // handlers

  function handleIsOpenedChange(e) {
    const checked = e.target.checked;
    onChange({...coloringJob, isOpened: checked});
  }

  function handleNameChange(e) {
    const name = e.target.value;
    onChange({...coloringJob, name});
  }
  function handleDescriptionChange(e) {
    const description = e.target.value;
    onChange({...coloringJob, description});
  }

  async function handleFileChange(file) {
    setLoading(true);
    const fileName = `job-image-${nanoid()}.png`;
    const newFile = new File([file], fileName);
    if (file) {
      const imageUrl = await uploadFileService({
        sceneId: sceneId,
        accessToken,
        file: newFile,
        container: "scene-files",
      });
      onChange({...coloringJob, imageUrl});
    }
    setLoading(false);
  }

  return (
    <Box sx={{display: "flex", flexDirection: "column"}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isOpened}
            onChange={handleIsOpenedChange}
            size="small"
          />
        }
        label={<Typography variant="body2">{isOpenedS}</Typography>}
        sx={{mb: 1}}
      />
      <TextField
        value={name}
        onChange={handleNameChange}
        size="small"
        label={<Typography variant="body2">{nameS}</Typography>}
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
      />
      <TextField
        multiline
        fullWidth
        value={description}
        onChange={handleDescriptionChange}
        size="small"
        label={<Typography variant="body2">{descriptionS}</Typography>}
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        sx={{mt: 2}}
      />
      <Box
        sx={{
          mt: 2,
          width: 1,
          height: 240,
          bgcolor: "background.default",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          position: "relative",
          backgroundImage: `url(${imageUrl})`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <FileSelectorButton
            buttonName={selectImageS}
            accept={"image/png, image/jpeg"}
            onFileChange={handleFileChange}
          />
        )}
      </Box>
    </Box>
  );
}
