import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import {
  ArrowBack,
  Edit as Rename,
  MoreVert as More,
  Delete,
  Settings as Config,
  Downloading as Load,
  RestartAlt as ResetPosition,
  Palette,
} from "@mui/icons-material";

import {
  setSelectedModelId,
  deleteModel,
  setConfiguring,
  setConfigCenter,
  setConfiguredModel,
} from "Features/viewer3D/viewer3DSlice";

import DialogRenameModel from "./DialogRenameModel";
import DialogChangeColor from "./DialogChangeColor";
import MenuItemPreviewUrl from "./MenuItemPreviewUrl";

import DeleteDialog from "Features/ui/components/DeleteDialog";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function HeaderDetailModel({model, editor3d, scene}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("common");

  // strings

  const loadString = capitalizeStart(t("load"));
  const renameString = capitalizeStart(t("rename"));
  const configString = capitalizeStart(t("config"));

  const changeColorString = "Change color";

  const resetString = capitalizeStart(t("reset"));
  const deleteString = capitalizeStart(t("delete"));

  // data

  const detailedModels = useSelector((state) => state.viewer3D.detailedModels);
  const isDetailed = detailedModels.includes(model.id);

  // props

  const title = model?.name;
  const type = model?.type;

  const color = model?.color ? model.color : "#FFFFFF";

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openColorDialog, setOpenColorDialog] = useState(false);

  // handlers

  function handleBackClick() {
    dispatch(setSelectedModelId(null));
  }

  // -- Menu

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  // -- Load

  function handleLoadClick() {
    if (model.type === "IFC" && !isDetailed)
      editor3d?.loader.loadIfcModelEntity(model, {autoplacement: false});
    setAnchorEl(null);
  }

  // -- Rename

  function handleRenameClick() {
    setOpenRenameDialog(true);
    setAnchorEl(null);
  }

  function handleRenameDialogClose() {
    setOpenRenameDialog(false);
  }

  // -- Color

  function handleChangeColorClick() {
    setOpenColorDialog(true);
    setAnchorEl(null);
  }

  function handleColorDialogClose() {
    setOpenColorDialog(false);
  }

  // -- Preview url

  function handlePreviewClick() {
    setAnchorEl(null);
  }

  // -- Edit

  function handleEditClick() {
    if (model.type === "IMAGE") {
      const editorEntity = editor3d?.getEntity(model.id);
      dispatch(setConfiguring(true));
      dispatch(setConfigCenter(true));
      dispatch(setConfiguredModel({model, showParts: false}));
      editor3d?.configCenter.configEntity({entity: editorEntity});
      editor3d?.switchToViewMode("VIEW_2D_CONFIG", {target: editorEntity});
    }
    setAnchorEl(null);
  }

  // -- Reset position

  function handleResetPositionClick() {
    const entity = editor3d?.getEntity(model.id);
    if (entity?.resetPosition) entity.resetPosition();
    setAnchorEl(null);
  }

  // -- Delete

  function handleDeleteClick() {
    setDeleteOpen(true);
    setAnchorEl(null);
  }

  function handleDeleteConfirm() {
    const editorEntity = editor3d?.getEntity(model.id);
    if (editorEntity?.deleteFrom) editorEntity.deleteFrom(editor3d?.scene);
    if (editorEntity?.delete) editorEntity.delete();
    dispatch(deleteModel({model}));
    setDeleteOpen(false);
    dispatch(setSelectedModelId(null));
  }

  function handleDeleteCancel() {
    setDeleteOpen(false);
  }

  return (
    <>
      <Box
        sx={{
          width: 1,
          display: "flex",
          p: 1,
          pl: 2,
          alignItems: "center",
          justifyContent: "space-between",
          borderLeft: `4px solid ${color}`,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: 0,
          }}
        >
          <IconButton size="small" onClick={handleBackClick}>
            <ArrowBack fontSize="small" />
          </IconButton>
          <Typography noWrap sx={{ml: 1}} variant="body2">
            <b>{title}</b>
          </Typography>
        </Box>
        <IconButton onClick={handleMoreClick} sx={{minWidth: 0}}>
          <More fontSize="small" />
        </IconButton>
      </Box>

      <Menu open={open} onClose={handleMenuClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleRenameClick}>
          <ListItemIcon>
            <Rename fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{renameString} </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleChangeColorClick}>
          <ListItemIcon>
            <Palette fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{changeColorString} </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItemPreviewUrl
          scene={scene}
          model={model}
          onClick={handlePreviewClick}
        />
        {!isDetailed && (
          <MenuItem onClick={handleLoadClick}>
            <ListItemIcon>
              <Load fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{loadString} </Typography>
            </ListItemText>
          </MenuItem>
        )}
        {type === "IMAGE" && (
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <Config fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{configString} </Typography>
            </ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleResetPositionClick}>
          <ListItemIcon>
            <ResetPosition fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{resetString} </Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{deleteString} </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>

      <DialogRenameModel
        open={openRenameDialog}
        onClose={handleRenameDialogClose}
        model={model}
      />
      <DeleteDialog
        open={deleteOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource="model"
      />
      <DialogChangeColor
        open={openColorDialog}
        onClose={handleColorDialogClose}
        model={model}
        editor3d={editor3d}
      />
    </>
  );
}
