import {Box} from "@mui/material";

import SectionHomeRessourceInSelectionPanel from "./SectionHomeRessourceInSelectionPanel";
import SectionHomeElementTypeInSelectionPanel from "./SectionHomeElementTypeInSelectionPanel";
import SectionHomeZoneInSelectionPanel from "./SectionHomeZoneInSelectionPanel";
import SectionHomeMeasurementInSelectionPanel from "./SectionHomeMeasurementInSelectionPanel";
import SectionHomeRoomInSelectionPanel from "./SectionHomeRoomInSelectionPanel";

import useSelectedIssueInModule from "Features/issues/useSelectedIssueInModule";
// import useSelectedIssue from "Features/issues/useSelectedIssue";
import useSelection from "Features/selection/useSelection";
import FormIssueDetailInSelectionPanel from "Features/issues/components/FormIssueDetailInSelectionPanel";
import SectionSceneInSelectionPanel from "Features/scenes/components/SectionSceneInSelectionPanel";

export default function SectionHomeSelectionInSelectionPanel({
  scene,
  caplaEditor,
  viewer,
}) {
  // data

  const selectedIssue = useSelectedIssueInModule();
  //const selectedIssue = useSelectedIssue();
  const selection = useSelection({scene, viewer});

  return (
    <Box>
      {selectedIssue && caplaEditor?.editor3d && (
        <FormIssueDetailInSelectionPanel
          issue={selectedIssue}
          editor3d={caplaEditor?.editor3d}
          scene={scene}
          isEditing={false}
        />
      )}
      {selection?.type === "SCENE" && (
        <SectionSceneInSelectionPanel scene={scene} viewer={viewer} />
      )}
      {selection?.type === "MEASUREMENTS_ELEMENT_TYPE" && (
        <SectionHomeElementTypeInSelectionPanel scene={scene} viewer={viewer} />
      )}
      {selection?.type === "HOME_RESSOURCE" && (
        <SectionHomeRessourceInSelectionPanel
          scene={scene}
          viewer={viewer}
          caplaEditor={caplaEditor}
          ressource={selection}
        />
      )}
      {selection?.type === "ZONE" && (
        <SectionHomeZoneInSelectionPanel
          scene={scene}
          viewer={viewer}
          caplaEditor={caplaEditor}
        />
      )}
      {selection?.type === "MEASUREMENT" && (
        <SectionHomeMeasurementInSelectionPanel
          scene={scene}
          editorPdf={caplaEditor?.editorPdf}
          viewer={viewer}
        />
      )}
      {selection?.type === "ROOM" && (
        <SectionHomeRoomInSelectionPanel scene={scene} />
      )}
    </Box>
  );
}
