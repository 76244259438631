import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  IconButton,
  Menu,
  FormControlLabel,
  Typography,
  Switch,
  Tooltip,
  Slider,
} from "@mui/material";
import {MoreHoriz as More, Close} from "@mui/icons-material";

import {
  setGlobalTransparency,
  setGrid,
  setShowEdge,
  setShowVoid,
} from "../viewer3DSlice";
import {setFilteredMeasurementsViewMode} from "Features/measurements/measurementsSlice";

export default function ButtonMoreViewerOptionsInToolbar({editor3d}) {
  const dispatch = useDispatch();

  // strings

  const title = "Affichage 3D";
  const gridS = "Afficher la grille";
  const edgesS = "Afficher les arêtes";
  const voidsS = "Afficher les vides";
  const transparencyS = "Activer la transparence";
  const inOutS = "In & Out";

  // data

  const showGrid = useSelector((state) => state.viewer3D.grid);
  const showEdge = useSelector((state) => state.viewer3D.showEdge);
  const showVoid = useSelector((state) => state.viewer3D.showVoid);
  const globalTransparency = useSelector(
    (state) => state.viewer3D.globalTransparency
  );
  const inOut = useSelector((s) => s.measurements.filteredMeasurementsViewMode);

  // state

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const [opacity, setOpacity] = useState(editor3d?.opacity);

  // handlers

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  const handleToggleGrid = () => {
    if (showGrid) {
      dispatch(setGrid(false));
      editor3d?.grid.hide();
    } else {
      dispatch(setGrid(true));
      editor3d?.grid.show();
    }
  };

  const handleToggleEdges = () => {
    if (showEdge) {
      dispatch(setShowEdge(false));
      editor3d?.sceneEditor.hideEdge();
    } else {
      dispatch(setShowEdge(true));
      editor3d?.sceneEditor.showEdge();
    }
  };

  const handleToggleVoids = () => {
    if (showVoid) {
      dispatch(setShowVoid(false));
      editor3d?.sceneEditor.hideVoid();
    } else {
      dispatch(setShowVoid(true));
      editor3d?.sceneEditor.showVoid();
    }
  };

  const handleToggleTransparency = () => {
    if (globalTransparency) {
      dispatch(setGlobalTransparency(false));
      editor3d?.sceneEditor.setTransparency(false);
    } else {
      dispatch(setGlobalTransparency(true));
      editor3d?.sceneEditor.setTransparency(true);
    }
  };

  const handleToggleInOut = () => {
    if (inOut === "IN_AND_OUT") dispatch(setFilteredMeasurementsViewMode("IN"));
    else dispatch(setFilteredMeasurementsViewMode("IN_AND_OUT"));
  };

  const handleOpacityChange = (e) => {
    const value = e.target.value;
    setOpacity(value);
    editor3d?.setOpacity(value);
    editor3d?.sceneEditor.showTransparent();
  };

  // helpers

  const tools = [
    {
      id: "GRID",
      label: gridS,
      handler: handleToggleGrid,
      checked: showGrid,
    },
    {
      id: "EDGES",
      label: edgesS,
      handler: handleToggleEdges,
      checked: showEdge,
    },
    // {
    //   id: "VOIDS",
    //   label: voidsS,
    //   handler: handleToggleVoids,
    //   checked: showVoid,
    // },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        bgcolor: "common.caplaBlack",
        color: "common.white",
      }}
    >
      <Tooltip title={title}>
        <IconButton size="small" color="inherit" onClick={handleClick}>
          <More fontSize="small" color="inherit" />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <Box
          sx={{
            display: "flex",
            width: 1,
            justifyContent: "space-between",
            pl: 1,
            pb: 1,
            alignItems: "center",
          }}
        >
          <Typography variant="body2" fontWeight="bold">
            {title}
          </Typography>
          <IconButton size="small" onClick={() => setAnchorEl(null)}>
            <Close fontSize="small" color="inherit" />
          </IconButton>
        </Box>
        {tools.map(({id, label, handler, checked}) => {
          return (
            <Box key={id} sx={{px: 2}}>
              <FormControlLabel
                control={
                  <Switch size="small" onChange={handler} checked={checked} />
                }
                label={<Typography variant="body2">{label}</Typography>}
              />
            </Box>
          );
        })}
        <Box key={"VOIDS"} sx={{px: 2}}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={handleToggleVoids}
                checked={showVoid}
                disabled={true}
              />
            }
            label={<Typography variant="body2">{voidsS}</Typography>}
          />
        </Box>
        <Box sx={{px: 2}}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={handleToggleTransparency}
                checked={globalTransparency}
              />
            }
            label={<Typography variant="body2">{transparencyS}</Typography>}
          />
          <Slider
            value={opacity}
            min={0}
            max={1}
            step={0.01}
            onChange={(e) => handleOpacityChange(e)}
            size="small"
            disabled={!globalTransparency}
          />
        </Box>
        <Box sx={{pl: 2}}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={handleToggleInOut}
                checked={inOut === "IN_AND_OUT"}
              />
            }
            label={<Typography variant="body2">{inOutS}</Typography>}
          />
        </Box>
      </Menu>
    </Box>
  );
}
