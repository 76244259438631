import {client} from "API/capla360";
export async function fetchOperationsService({accessToken}) {
  const response = await client.get(`/operations/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function postOperationService({accessToken, operation}) {
  const response = await client.post(`/operations/`, operation, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function fetchOperationService({accessToken, operationId}) {
  const response = await client.get(`/operations/${operationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, operationId};
}

export async function updateOperationService({
  accessToken,
  operationId,
  operation,
}) {
  const response = await client.get(`/operations/${operationId}`, operation, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {data: response.data, operationId};
}

export async function postUserRelationshipService({
  accessToken,
  operationId,
  userRelationship,
}) {
  const response = await client.post(
    `/operations/${operationId}/user_relationships/`,
    userRelationship,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return {data: response.data, operationId};
}
