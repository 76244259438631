import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchOperationsService,
  postOperationService,
  fetchOperationService,
  updateOperationService,
  postUserRelationshipService,
} from "./services";

export const postOperation = createAsyncThunk(
  "operations/postOperation",
  postOperationService
);

export const fetchOperations = createAsyncThunk(
  "operations/fetchOperations",
  fetchOperationsService
);

export const fetchOperation = createAsyncThunk(
  "operations/fetchOperation",
  fetchOperationService
);

export const updateOperation = createAsyncThunk(
  "operations/updateOperation",
  updateOperationService
);

export const postUserRelationship = createAsyncThunk(
  "operations/postUserRelationship",
  postUserRelationshipService
);

const operationsSlice = createSlice({
  name: "operations",
  initialState: {data: [], datasets: {}, status: "idle"},
  extraReducers: {
    [postOperation.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [fetchOperations.fulfilled]: (state, action) => {
      state.data.push(...action.payload);
      state.status = "loaded";
    },
    [fetchOperation.fulfilled]: (state, action) => {
      const {operationId, data} = action.payload;
      state.datasets[operationId] = data;
    },
    [postUserRelationship.fulfilled]: (state, action) => {
      const {operationId, data} = action.payload;
      state.datasets[operationId] = data;
    },
  },
});

export default operationsSlice.reducer;
