import {Paper, Typography} from "@mui/material";

export default function ListItemIssueInViewer({
  issue,
  selected,
  onClick,
}) {
  // helpers

  const bgcolor = selected ? "common.darkGrey" : "common.caplaBlack";
  const elevation = selected ? 12 : 3;

  // handlers

  function handleClick() {
    onClick(issue);
  }

  //

  return (
    <Paper
      elevation={elevation}
      sx={{
        bgcolor,
        color: "common.white",
        px: 1,
        py: 0.3,
        width: "fit-content",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Typography variant="body2">{issue?.title}</Typography>
    </Paper>
  );
}
