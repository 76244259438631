import {useState} from "react";
import {useDispatch} from "react-redux";

import {Menu, MenuItem, Typography, Button} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import useMaterialsByScene from "../hooks/useMaterialsByScene";
import materialsForDataGrid from "../utils/materialsForDataGrid";
import DialogImportMaterialsFromExcel from "./DialogImportMaterialsFromExcel";

export default function ButtonExcel({scene}) {
  const dispatch = useDispatch();

  // strings

  const label = "Excel";
  const exportS = "Export";
  const importS = "Import";

  const fileName = "materials.xlsx";

  // data

  let materials = useMaterialsByScene(scene);
  materials = materialsForDataGrid(materials);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openImportDialog, setOpenImportDialog] = useState(false);

  // helpers - handlers

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  async function handleExport() {
    setAnchorEl(null);
    const wb = await createExcelWorkbook({template: "MATERIALS", data: materials});
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  // helpers - tools

  const tools = [
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
    },
  ];

  return (
    <>
      <Button
        endIcon={<More />}
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </Button>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogImportMaterialsFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
    </>
  );
}
