export default function getZonesDeltaInPx(zoneOld, zoneNew) {
  //
  const Lx = zoneOld.position.x - zoneNew.position.x;
  const wn = zoneNew.imageSize.width;
  const wo = zoneOld.imageSize.width;
  const _wn = zoneNew.width;
  const _wo = zoneOld.width;
  //
  const Ly = zoneOld.position.z - zoneNew.position.z;
  const hn = zoneNew.imageSize.height;
  const ho = zoneOld.imageSize.height;
  const _hn = zoneNew.height;
  const _ho = zoneOld.height;
  //
  const sn = zoneNew.scale;
  const so = zoneOld.scale;
  //
  const s1 = wn / _wn;
  const s2 = so / sn;
  const s3 = _wo / wo;
  const s = s1 * s2 * s3;

  const dx = (Lx * wn) / (_wn * sn) + (1 / 2) * (wn - wo * s);
  const dy = (Ly * hn) / (_hn * sn) + (1 / 2) * (hn - ho * s);
  //
  return {deltaX: dx, deltaY: dy};
}
