import {useState} from "react";

import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import useCreateArticle from "../hooks/useCreateArticle";
import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";
import useSelectedArticle from "../hooks/useSelectedArticle";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import FormEditArticle from "./FormEditArticle";

export default function DialogCreateArticle({open, onClose, scene}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id;

  // string

  const title = "Ajouter un nouvel article";

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);
  const [tempArticle, setTempArticle] = useState({});

  // data

  const createArticle = useCreateArticle();
  const selectedGroup = useSelectedArticlesGroup();
  const selectedArticle = useSelectedArticle(scene);

  // helper

  const groupLabel = selectedGroup?.name ?? "/!\\ Groupe non sélectionné";

  // handlers

  function handleClose() {
    onClose();
  }

  function handleTempArticleChange(article) {
    setTempArticle(article);
  }

  async function handleSave() {
    const clientId = nanoid();

    // sortedArticles
    let sortedArticles = [];
    if (selectedGroup?.sortedArticles)
      sortedArticles = [...selectedGroup.sortedArticles];
    const selectedIndex = sortedArticles?.indexOf(selectedArticle?.clientId);
    if (!selectedIndex) {
      sortedArticles.push(clientId);
    } else {
      sortedArticles.splice(selectedIndex + 1, 0, clientId);
    }

    // article
    const article = {
      ...tempArticle,
      articlesGroupId: selectedGroup.id,
      clientId,
    };
    if (selectedArticle?.isAdded) article.isAdded = true;
    //
    if (loading) return;
    setLoading(true);
    await createArticle({sceneId, article, sortedArticles});
    setLoading(false);
    const message = `Article "${article.num} ${article.name}" ajouté`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            bgcolor: "common.caplaBlack",
            color: "white",
            p: 0.5,
            mb: 2,
            borderRadius: "4px",
            px: 1,
          }}
        >
          <Typography sx={{fontSize: 13}}>{groupLabel}</Typography>
        </Box>

        <FormEditArticle
          onChange={handleTempArticleChange}
          article={tempArticle}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
