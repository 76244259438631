import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
} from "@mui/material";

import {
  toggleIfcStoreyVisibility,
  toggleAllStoreysVisibility,
} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";

export default function FilterStoreys({editor3d, model}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("viewer3D");

  // strings

  const filterString = t("viewer3D:filter.storeys");

  // data

  const editorEntity = editor3d?.getEntity(model?.id);
  const ifcModelID = editorEntity?.ifcModelID;

  const ifcSubsets = useSelector((state) => state.viewer3D.ifcSubsets).filter(
    (s) => s.ifcModelID === ifcModelID
  );
  const ifcTypes = useSelector((state) => state.viewer3D.ifcTypes).filter(
    (s) => s.ifcModelID === ifcModelID
  );
  const ifcStoreys = useSelector((state) => state.viewer3D.ifcStoreys).filter(
    (s) => s.ifcModelID === ifcModelID
  );

  const storeysCount = ifcStoreys.length;
  const visibleStoreysCount = ifcStoreys.filter(
    (s) => s.visible === true
  ).length;

  const none = visibleStoreysCount === 0;
  const all = storeysCount - visibleStoreysCount === 0;
  const indeterminate = !none && !all;

  // local state

  const [allLayers, setAllLayers] = useState(true);

  // helpers

  function showAllLayers() {
    dispatch(toggleAllStoreysVisibility({ifcModelID, visible: true}));
    editorEntity.toggleAllStoreysVisibility(true, ifcTypes);
  }

  function hideAllLayers() {
    dispatch(toggleAllStoreysVisibility({ifcModelID, visible: false}));
    editorEntity.toggleAllStoreysVisibility(false, ifcTypes);
  }
  // handlers

  function handleAllLayersChange(event) {
    try {
      const newChecked = event.target.checked;
      setAllLayers((v) => !v);

      // new ifc storeys
      const newIfcStoreys = ifcStoreys.map((ifcStorey) => {
        return {...ifcStorey, visible: newChecked};
      });

      // apply filters
      editorEntity.applyFilters(newIfcStoreys, ifcTypes);

      // state
      if (newChecked)
        dispatch(toggleAllStoreysVisibility({ifcModelID, visible: true}));
      if (!newChecked)
        dispatch(toggleAllStoreysVisibility({ifcModelID, visible: false}));
    } catch (e) {
      console.log(e);
    }
  }

  function handleCheckboxChange(e, ifcModelID, storeyExpressID) {
    try {
      // state
      dispatch(
        toggleIfcStoreyVisibility({ifcModelID, expressID: storeyExpressID})
      );

      // update of ifcStoreys
      const newIfcStoreys = ifcStoreys.map((ifcStorey) => {
        if (ifcStorey.expressID !== storeyExpressID) {
          return ifcStorey;
        } else {
          const newIfcStorey = {...ifcStorey};
          newIfcStorey.visible = !ifcStorey.visible;
          return newIfcStorey;
        }
      });

      // simple
      editorEntity.applyFilters(newIfcStoreys, ifcTypes);

      // advanced
      // const subsets = ifcSubsets.filter(
      //   (s) =>
      //     s.ifcModelID === ifcModelID && s.storeyExpressID === storeyExpressID
      // );
      // subsets.forEach((subset) => {
      //   const ifcType = ifcTypes.find(
      //     (ifcType) => ifcType.type === subset.type
      //   );
      //   if (ifcType?.visible) {
      //     editorEntity.toggleSubsetVisibility(subset.uuid);
      //   }
      // });
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    // update all layers checkbox
    if (all) setAllLayers(true);
    if (none) setAllLayers(false);
  }, [visibleStoreysCount]);

  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", paddingLeft: 0.5}}>
        <Checkbox
          size="small"
          checked={allLayers}
          indeterminate={indeterminate}
          onChange={handleAllLayersChange}
        />
        <Typography variant="body2">
          <b>{filterString}</b>
        </Typography>
      </Box>
      <List dense>
        {ifcStoreys?.map((storey) => {
          const count = ifcSubsets
            .filter((s) => s.storeyExpressID === storey.expressID)
            .reduce((ac, subset) => ac + subset.ids.length, 0);
          return (
            <ListItem
              key={storey.expressID}
              sx={{paddingBottom: 0, paddingTop: 0}}
            >
              <ListItemIcon sx={{height: 24, width: 24}}>
                <Checkbox
                  size="small"
                  edge="start"
                  checked={storey.visible}
                  onChange={(e) =>
                    handleCheckboxChange(e, storey.ifcModelID, storey.expressID)
                  }
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText>{`${storey.name} (${count})`}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
