import {nanoid} from "@reduxjs/toolkit";

import flattenSpaces from "./scripts/flattenSpaces";
import wallsFromSpaces from "./scripts/wallsFromSpaces";
import affectTypeByProxy from "./scripts/affectTypeByProxy";
import deleteSegments from "./scripts/deleteSegments";
import addVoids from "./scripts/addVoids";
import affectPropsByProxy from "./scripts/affectPropsByProxy";
import createPV from "./scripts/createPV";
import cleanMeasurementPaths from "Features/measurements/utils/cleanMeasurementPaths";
import changeTypeSegments from "./scripts/changeTypeSegments";
import makeBufferLine, { makeCenterLine } from "./utils/bufferLine";

const actionsMap = {
  "flatten_spaces": flattenSpaces,
  "walls_from_spaces": wallsFromSpaces,
  "affect_type_by_proxy": affectTypeByProxy,
  "delete_segments": deleteSegments,
  "add_voids": addVoids,
  "affect_props_by_proxy": affectPropsByProxy,
  "create_pv": createPV,
  "change_type_segments": changeTypeSegments,
}

export default function applyProcedures(packages, procedures, data) {
  const packagesToUpdate = new Set();
  const {measurementsByPackageId} = data;
  const validIds = new Set(Object.keys(measurementsByPackageId));
  for (const procedure of procedures) {
    let outMeas = [];
    data.initialPolygons = [];
    data.initialSegments = [];
    for (const inputId of procedure.inputs) {
      if (validIds.has(inputId)) {
        for (const meas of measurementsByPackageId[inputId]) {
          const newMeas = {
            ...meas,
            id: nanoid(),
            measModelId: inputId,
            affectedByProxy: false,
          };
          if (newMeas.drawingShape === "POLYLINE") {
            newMeas.centerLine = makeCenterLine(newMeas.path3D);
          }
          outMeas.push(newMeas);
          if (newMeas.drawingShape === "POLYGON")
            data.initialPolygons.push(newMeas);
          else if (newMeas.drawingShape === "SEGMENT")
            data.initialSegments.push(newMeas);
        }
      } else {
        console.log(`ERROR input ${inputId} is not a valid package id`);
      }
    }
    if (procedure.actions) {
      for (const action of procedure.actions) {
        outMeas = actionsMap[action.action]({
          measurements: outMeas,
          params: action.params,
          data,
        });
      }
    }
    if (procedure.options?.removeNotChangedByProxy) {
      outMeas = outMeas.filter((m) => m.affectedByProxy);
    }     
    if (procedure.options?.removeInitialSegments) {
      const inSegments = new Set(data.initialSegments.map((s) => s.id));
      outMeas = outMeas.filter((m) => !inSegments.has(m.id));
    }
    outMeas = outMeas.map((m) => {
      const newM = cleanMeasurementPaths(m);
      if (newM.measModelId) delete newM.measModelId;
      if (newM.affectedProps) delete newM.affectedProps;
      if (typeof newM.affectedByProxy === "boolean")
        delete newM.affectedByProxy;
      if (newM.centerLine) {
        const newPath3D = makeBufferLine(newM.centerLine, newM.dim1);
        const zFrom3D3 = newM.path3d3[0][1];
        newM.path3D = newPath3D;
        newM.path3d3 = newPath3D.map((p) => {return [p[0], zFrom3D3, p[1]]});
        delete newM.centerLine;
      }
      return newM;
    });
    const outputId = procedure.output;
    if (validIds.has(outputId)) {
      measurementsByPackageId[outputId] = outMeas;
      packagesToUpdate.add(outputId)
    } else {
      console.log(`ERROR output ${outputId} is not a valid package id`);
    }
  }
  const updatedPackages = [];
  for (const packageId of packagesToUpdate) {
    const pack = packages.find((p) => p.id === packageId);
    const updatedPackage = {
      ...pack,
      measurementsData: {
        ...pack.measurementsData,
        measurements: measurementsByPackageId[packageId]
      }
    };
    // if (
    //   updatedPackage.measurementsData.procedures
    // )
    //   delete updatedPackage.measurementsData.procedures;
    updatedPackages.push(updatedPackage);
  }
  return updatedPackages;
} 