export default function getMeasurementsDataFromMeasurementsAndModelId(
  measurements,
  modelId
) {
  const msData = measurements
    .filter((m) => m.measurementsModelId === modelId && m.deleted !== true)
    .map((measurement) => {
      const m = {...measurement};
      delete m.measurementsModelId;
      delete m.measurementsModel;
      delete m.pdfModelId;
      delete m.elementType;
      delete m.zone;
      delete m.room;
      delete m.material;
      delete m.sector;
      delete m.ressource;
      delete m.phase;
      delete m.version;
      delete m.lengthNet;
      delete m.areaNet;
      delete m.volumeNet;
      return m;
    });
  return msData;
}
