import {useState} from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getRessourcesRelationsFromFile from "Features/excel/utils/getRessourcesRelationsFromFile";

export default function DialogImportRessourcesRelations({
  open,
  onConfirm,
  onCancel,
  typesById,
  relations,
}) {
  // strings

  const title = "Importez des relations";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";
  const buttonName = "Choisir un fichier";

  // state

  const [file, setFile] = useState(null);
  const [editedRels, setEditedRels] = useState(null);
  const [editedGroups, setEditedGroups] = useState(null);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    const {editedRelations, editedTypesGroups} = 
      await getRessourcesRelationsFromFile(
      file, typesById, relations
    );
    setEditedRels(editedRelations);
    setEditedGroups(editedTypesGroups);
  }
  function handleCancelClick() {
    onCancel();
  }
  function handleSaveClick() {
    onConfirm(editedRels, editedGroups);
  }
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
