import React from "react";

import { Card, Typography, CardContent, alpha } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: alpha(theme.palette.secondary.main, 0.9),
    color: "white",
  },
}));

export default function CardCameraDescription() {
  const classes = useStyles();

  // strings

  const camerasString = "Cameras";

  const descriptionString =
    "Déplacez vous facilement dans votre modèle à partir de caméras pré-configurées.";
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body2">
          <b>{camerasString}</b>
        </Typography>
        <Typography variant="body2">{descriptionString}</Typography>
      </CardContent>
    </Card>
  );
}
