export function getModelTypeFromFile(file) {
  let type;
  const extension = file.name.split(".").pop();
  if (
    ["png", "jpg", "jpeg"].includes(extension) ||
    file.type === "image/png" ||
    file.type === "image/jpeg"
  )
    type = "IMAGE";
  if (extension === "ifc" || extension === "ifcZIP") type = "IFC";
  if (extension === "dxf") type = "DXF";
  if (extension === "drc") type = "DRACO";
  if (extension === "pdf") type = "PDF";
  if (extension === "gltf") type = "GLTF";
  if (extension === "glb") type = "GLB";
  if (extension === "obj") type = "OBJ";
  if (extension.toLowerCase() === "zip") type = "ZIP";
  return type;
}

export function storedModelToRemoteModel(model, sceneId) {
  const transformation = JSON.stringify({
    position: model.position,
    rotation: model.rotation,
    center: model.center,
    width: model.width,
    height: model.height,
    depth: model.depth,
    mask: model.mask,
    parts: model.parts,
  });
  const remoteModel = {
    ...model,
    transformation,
    clientId: model.id,
    scene: sceneId,
    sceneId,
    autoloading: Boolean(model.autoloading),
  };
  return remoteModel;
}

export function getRandomPower(power) {
  let newPower = power;
  if (Math.random() > 0.93 || power > 100) {
    // if (flash.power < 100)
    //   flash.position.set(Math.random() * 400, 300 + Math.random() * 200, 100);
    newPower = 50 + Math.random() * 500;
  }
  return newPower;
}

export function updateModelFromRemote(model, remoteModel) {
  const newModel = {...model};
  newModel.center = remoteModel.center;
  newModel.depth = remoteModel.depth;
  newModel.width = remoteModel.width;
  newModel.height = remoteModel.height;
  newModel.num = remoteModel.num;
  newModel.position = remoteModel.position;
  newModel.rotation = remoteModel.rotation;
  newModel.version = remoteModel.version;
  newModel.elementsAnnotations = remoteModel.elementsAnnotations;
  newModel.measurementsData = remoteModel.measurementsData;
  newModel.enabled = remoteModel.enabled;
  newModel.zones = remoteModel.zones;
  newModel.pdfScale = remoteModel.pdfScale;
  newModel.fromPdf = remoteModel.fromPdf;
  newModel.fromModel = remoteModel.fromModel;
  newModel.ifcData = remoteModel.ifcData;
  newModel.fromColoring = remoteModel.fromColoring;
  newModel.d3 = remoteModel.d3;
  newModel.isHelper = remoteModel.isHelper;
  newModel.helperIds = remoteModel.helperIds;
  newModel.sectorId = remoteModel.sectorId;
  return newModel;
}
