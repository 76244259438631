import React from "react";

import {Box} from "@mui/material";

import SignInSignOutButton from "Features/auth/components/SignInSignOutButton";
import LanguageSelector from "Features/translations/components/LanguageSelector";

export default function BottomBar() {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        bgcolor: "common.caplaBlack",
        alignItems: "center",
        justifyContent: "space-between",
        height: (theme) => theme.spacing(5),
        minHeight: (theme) => theme.spacing(5),
        p: 0.5,
        overflow: "hidden",
        mt: 2,
      }}
    >
      <LanguageSelector />

      <Box sx={{mb: 1, mt: 1, ml: 2}}>
        <SignInSignOutButton avatar={false} />
      </Box>
    </Box>
  );
}
