import {Box, IconButton, Badge, Typography} from "@mui/material";
import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function SectionSelectorEntity({
  selection,
  onChange,
  countMap = {},
}) {
  // const
  const entities = ["INPUTS", "ZONES", "PACKAGES"];

  // data

  const iconMap = useSceneModuleIconMap();
  const nameMap = useSceneModuleNameMap();

  // handlers

  function handleClick(ent) {
    onChange(ent);
  }
  return (
    <Box sx={{width: 1, display: "flex", "&>*:not(:last-child)": {mr: 2}}}>
      {entities.map((entity) => {
        const selected = selection === entity;
        const count = countMap[entity] ? countMap[entity] : 0;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: selected ? "background.default" : "common.white",
              }}
            >
              <Badge badgeContent={count}>
                <IconButton onClick={() => handleClick(entity)}>
                  {iconMap[entity]}
                </IconButton>
              </Badge>
            </Box>
            <Typography
              align="center"
              sx={{
                fontSize: "12px",
                color: selected ? "text.primary" : "text.secondary",
                fontWeight: selected ? "bold" : "normal",
                mt: 1,
              }}
            >
              {nameMap[entity]}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
