// re-compute num (x.x.x) depending on an array of items and their orginal num.
// compute the num based on the first item num, the rank and order of each element.
// items are based on their group

// helpers
function getRank(num) {
  return num ? num.split(".").length : null;
}

// ["2.2.2"  x   "3"]
function getParentNumFollower(num, rankDiff) {
  const numArr = num.split(".");
  numArr.splice(numArr.length - rankDiff, rankDiff);
  const suffix = numArr.pop();
  return [...numArr, (Number(suffix) + 1).toString()].join(".");
}

// ["2.2.1","x.x.x"]

function getNumFollower(num) {
  const numArr = num.split(".");
  const suffix = numArr.pop();
  return [...numArr, (Number(suffix) + 1).toString()].join(".");
}

// ["2.2.1","x.x.x.x"]
function getNumFirstChild(num) {
  return num + ".1";
}

export default function refreshGroupedItemsNums(items, group) {
  const groupedItems = group ? items.filter((i) => i.group === group) : items;
  const otherItems = group ? items.filter((i) => i.group !== group) : [];
  const newGroupedItems = [];

  let prevNum = groupedItems[0].num;

  for (let i = 0; i < groupedItems.length; i++) {
    const item = {...groupedItems[i]};
    if (i === 0) {
      newGroupedItems.push(item);
    } else {
      const rankDiffNumVsPrev = getRank(item.num) - getRank(prevNum);
      if (rankDiffNumVsPrev === 0) {
        item.num = getNumFollower(prevNum);
      } else if (rankDiffNumVsPrev < 0) {
        item.num = getParentNumFollower(prevNum, -1 * rankDiffNumVsPrev);
      } else {
        item.num = getNumFirstChild(prevNum);
      }
      newGroupedItems.push(item);
      prevNum = item.num;
    }
  }
  return [...otherItems, ...newGroupedItems];
}

// let newElementTypes = elementTypes
//   .filter((t) => t.code)
//   .map((elementType) => {
//     // reorder by num
//     const newType = {...elementType};
//     let parentNum = elementType.num.split(".");
//     parentNum.pop();
//     parentNum = parentNum.join(".");
//     const parentElement = numTypeMap[parentNum];
//     if (parentElement?.isAgg) {
//       newType.parent = parentElement.id;
//       newType.group = parentElement.group;
//     }
//     if (parentElement?.isTitle) {
//       newType.group = parentElement.group;
//     }
//     // remove deleted ressources
//     const res = elementType.res;
//     if (res) {
//       const newRes = res.filter(({resId}) =>
//         ressources.find((res) => res.id === resId)
//       );
//       newType.res = newRes;
//     }
