import {useSelector} from "react-redux";

export default function useRelevantAnnotCreationTools() {
  let tools = [];

  const drawingShape = useSelector((s) => s.measurements.drawingShape);

  switch (drawingShape) {
    case "POLYLINE": {
      //tools = ["POLYLINE", "POLYLINE_INTERIOR", "POLYLINE_EXTERIOR"];
      tools = ["SEGMENT", "POLYLINE", "POLYGON", "RECTANGLE"];
      break;
    }
    case "GABLE": {
      tools = ["POLYLINE"];
      break;
    }
    case "BRIDGE": {
      tools = ["POLYLINE"];
      break;
    }
    case "SLOPE": {
      tools = ["POLYLINE"];
      break;
    }
    case "STAIRS": {
      tools = ["POLYLINE"];
      break;
    }
    case "BEAM": {
      tools = ["SEGMENT"];
      break;
    }
    case "POLYGON": {
      //tools = ["POLYGON", "AUTO_MEASURE"];
      tools = ["RECTANGLE", "POLYGON"];
      break;
    }
    case "SLOPING_POLYGON": {
      tools = ["POLYGON"];
      break;
    }
    case "BOWL": {
      tools = ["POLYGON"];
      break;
    }
    case "BANK": {
      //tools = ["POLYGON", "POLYGON_INTERIOR"];
      tools = ["POLYGON"];
      break;
    }
    case "SEGMENT": {
      //tools = ["SEGMENT", "SEGMENT_FROM_ZONES"];
      tools = ["SEGMENT"];
      break;
    }
    case "RECTANGLE": {
      tools = ["POINT"];
      break;
    }
    case "CIRCLE": {
      tools = ["POINT"];
      break;
    }
  }

  return tools;
}
