export default function articleTypesArrayToMap(articleTypesArray) {
  const typesMap = {};

  articleTypesArray.forEach(
    ({groupId, typeId, typeNum, typeName, filtersStr, func}) => {
      if (!groupId && filtersStr) {
        typesMap["filtersStr"] = filtersStr;
        typesMap["globalFunc"] = func;
      } else if (!typeId && filtersStr) {
        if (typesMap[groupId]) {
          typesMap[groupId].filtersStr = filtersStr;
        } else {
          typesMap[groupId] = {filtersStr};
        }
      } else if (groupId && typeId) {
        if (typesMap[groupId]) {
          typesMap[groupId][typeId] = {filtersStr, func, typeNum, typeName};
        } else {
          typesMap[groupId] = {[typeId]: {filtersStr, func, typeNum, typeName}};
        }
      }
    }
  );

  return typesMap;
}
