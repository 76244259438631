import {useSelector, useDispatch} from "react-redux";
import {setOpenDialogCreatePackage} from "Features/elementPackages/packagesSlice";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

import {Box, Typography, Paper, Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import IconModuleInLeftPanel from "Features/ui/components/IconModuleInLeftPanel";

export default function HeaderInLeftPanelOnboarding({scene}) {
  const dispatch = useDispatch();

  // strings

  const titleS = "Repérer les éléments sur un calque";
  const createS = "Nouveau calque";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const groupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );

  // helpers

  let mode = "DEFAULT";
  if ((elementTypes.length > 0 || groupProxy?.id) && selectedPdfModelId) {
    mode = "CREATE";
  }

  // handlers

  function handleClick() {
    dispatch(setOpenDialogCreatePackage(true));
  }

  return (
    <Box sx={{p: 1, width: 1}}>
      {mode === "DEFAULT" && (
        <Paper
          elevation={0}
          sx={{
            p: 1,
            width: 1,
            height: (theme) => theme.spacing(5),
            display: "flex",
            alignItems: "center",

            bgcolor: "common.white",
          }}
        >
          <IconModuleInLeftPanel bgcolor="grey.400" />
          <Typography sx={{color: "grey.400", ml: 1}} variant="body1">
            {titleS}
          </Typography>
        </Paper>
      )}
      {mode === "CREATE" && (
        <Box
          sx={{
            width: 1,
            borderRadius: "4px",
            bgcolor: "warning.main",
            color: "common.white",
          }}
        >
          <Button
            onClick={handleClick}
            startIcon={<Add />}
            color="inherit"
            sx={{width: 1, height: 1}}
          >
            {createS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
