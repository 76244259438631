import {Dialog, DialogTitle, DialogContent} from "@mui/material";
import FormEditNomenclature from "./FormEditNomenclature";

export default function DialogEditNomenclature({
  open,
  onClose,
  nomenclature,
  onNomenclatureCreated,
  onNomenclatureUpdated,
  onNomenclatureDeleted,
}) {
  // strings

  const titleCreate = "Ajouter une nomenclature";
  const titleUpdate = "Mettre à jour la nomenclature";

  // helpers

  const title = nomenclature ? titleUpdate : titleCreate;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormEditNomenclature
          nomenclature={nomenclature}
          onNomenclatureCreated={onNomenclatureCreated}
          onNomenclatureUpdated={onNomenclatureUpdated}
          onNomenclatureDeleted={onNomenclatureDeleted}
        />
      </DialogContent>
    </Dialog>
  );
}
