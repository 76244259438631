import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setShowScreenPdfViewerHelper} from "../pdfSlice";

import {Box, Typography, alpha, Button} from "@mui/material";

export default function ScreenHelperPdfViewer() {
  const dispatch = useDispatch();

  // strings

  const pdfS = "Visionneuse PDF";

  const description = `- Zoom: ctrl + roulette souris
  - Scroll: roulette souris ou flèche clavier`;

  const openS = "Voir un PDF";

  // data

  const helper3D = useSelector((s) => s.viewer3D.showScreenModelsLoaderHelper);
  const os = useSelector((s) => s.ui.openSections);

  // helper

  const showDetail = !helper3D;

  // handlers

  function handleClick() {
    dispatch(setShowScreenPdfViewerHelper(false));
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 40,
        color: "common.white",
        bgcolor: (theme) =>
          alpha(theme.palette.common.caplaBlack, showDetail ? 0.9 : 1),
        borderLeft: (theme) => `1px solid white`,
      }}
    >
      <Box
        sx={{
          display: os.fixedViewersBox ? "flex" : "none",
          alignItems: "center",
          pt: "150px",
          width: 1,
          height: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant="h4">{pdfS}</Typography>
        <Typography
          variant="body2"
          sx={{
            my: 8,
            whiteSpace: "pre-line",
            visibility: showDetail ? "visible" : "hidden",
          }}
        >
          {description}
        </Typography>
        <Button
          color="inherit"
          variant="outlined"
          onClick={handleClick}
          sx={{
            visibility: showDetail ? "visible" : "hidden",
          }}
        >
          {openS}
        </Button>
      </Box>
    </Box>
  );
}
