import {createSlice} from "@reduxjs/toolkit";

export const markersSlice = createSlice({
  name: "markers",
  initialState: {
    markersModelsInScope: [],
    selectedMarkersModelId: null, // used when creating a new marker
    selectedMarker: {markersModelId: null, markerId: null},

    isEditing: false,
    editedMarker: {},
    tempMarker: {x: 0, y: 0, z: 0},
  },
  reducers: {
    setMarkersModelsInScope: (state, action) => {
      state.markersModelsInScope = action.payload;
    },
    setSelectedMarkersModelId: (state, action) => {
      state.selectedMarkersModelId = action.payload;
    },
    setSelectedMarker: (state, action) => {
      state.selectedMarker = action.payload;
    },
    setEditedMarker: (state, action) => {
      state.editedMarker = action.payload;
    },
    setTempMarker: (state, action) => {
      state.tempMarker = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
  },
});

export const {
  setMarkersModelsInScope,
  setSelectedMarkersModelId,
  setSelectedMarker,
  setEditedMarker,
  setTempMarker,
  setIsEditing,
} = markersSlice.actions;

export default markersSlice.reducer;
