import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {createPsetService, fetchPsetsService} from "./services";

export const createPset = createAsyncThunk(
  "psets/createPset",
  createPsetService
);

export const fetchPsets = createAsyncThunk(
  "psets/fetchPsets",
  fetchPsetsService
);

const psetsSlice = createSlice({
  name: "psets",
  initialState: {datasets: {}},
  extraReducers: {
    [fetchPsets.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId] = {data};
      state.datasets[listingId].status = "succeed";
    },
    [createPset.fulfilled]: (state, action) => {
      const {data, listingId} = action.payload;
      state.datasets[listingId].data.push(data);
    },
  },
});

export default psetsSlice.reducer;
