import CaplaPlane from "./CaplaPlane";
import {Vector3} from "three";

export default class Clipper {
  editor;
  clippingPlanes;
  heightHCP; // horizontal clipping plane height

  constructor({editor}) {
    this.editor = editor;
    this.clippingPlanes = [];
    this.planeSize = 50;
    this.clippingPlanesStatus = []; // [{clipplingPlaneId,horizontal,disabled,edit}]
    this.activeClippingPlanes = [];
    this.cut3DWithActiveHCP = true; // false in multiview mode = we want all the scene.
  }

  setCut3DWithActiveHCP(value) {
    this.cut3DWithActiveHCP = value;
  }
  setStatus(status) {
    this.clippingPlanesStatus = status;
  }

  createPlane({id, sceneClientId, normal, origin, horizontal}) {
    const plane = new CaplaPlane({
      id,
      sceneClientId,
      editor: this.editor,
      origin,
      normal,
      planeSize: this.planeSize,
      horizontal,
    });
    this.clippingPlanes.push(plane);
    this.clippingPlanesStatus.push({
      clippingPlaneId: plane.id,
      disabled: true,
      edit: false,
      horizontal,
    });
    this.updateMaterials();
    return plane;
  }

  editPlane(planeId) {
    const newCps = this.clippingPlanesStatus.map((cps) => {
      if (cps.clientPlaneId === planeId) {
        return {...cps, edit: true};
      } else {
        return cps;
      }
    });
    this.clippingPlanesStatus = newCps;

    const plane = this.clippingPlanes.find((cp) => cp.id === planeId);
    console.log("edit plane", planeId, this.clippingPlanes);
    plane?.show();
  }

  savePlane(planeId) {
    const newCps = this.clippingPlanesStatus.map((cps) => {
      if (cps.clientPlaneId === planeId) {
        return {...cps, edit: false};
      } else {
        return cps;
      }
    });
    this.clippingPlanesStatus = newCps;

    const plane = this.clippingPlanes.find((cp) => cp.id === planeId);
    plane.hide();
    return plane.parse();
  }

  enablePlane(planeId) {
    const newCps = this.clippingPlanesStatus.map((cps) => {
      if (cps.clientPlaneId === planeId) {
        return {...cps, disabled: false};
      } else {
        return cps;
      }
    });
    this.clippingPlanesStatus = newCps;

    const plane = this.clippingPlanes.find((cp) => cp.id === planeId);
    this.activeClippingPlanes.push(plane);

    this.updateMaterials();
  }

  disablePlane(planeId) {
    const newCps = this.clippingPlanesStatus.map((cps) => {
      if (cps.clientPlaneId === planeId) {
        return {...cps, disabled: true};
      } else {
        return cps;
      }
    });
    this.clippingPlanesStatus = newCps;

    this.activeClippingPlanes = this.activeClippingPlanes.filter(
      (cp) => cp.id !== planeId
    );

    this.updateMaterials();
  }

  deletePlane(planeId) {
    const plane = this.clippingPlanes.find((cp) => cp.id === planeId);
    this.disablePlane(planeId);
    plane.remove();
    this.updateMaterials();
  }

  disableAllClippingPlanes() {
    this.activeClippingPlanes = [];
    this.clippingPlanesStatus = this.clippingPlanesStatus.map((cps) => {
      if (cps.horizontal) return cps;
      return {
        ...cps,
        disabled: true,
      };
    });
    this.updateMaterials();
  }

  hideAllClippingPlanes() {
    this.clippingPlanes.forEach((plane) => plane.hide());
    this.clippingPlanesStatus = this.clippingPlanesStatus.map((cps) => ({
      ...cps,
      edit: false,
    }));
  }

  setActiveClippingPlanesFromIds(ids) {
    if (Array.isArray(ids)) {
      this.disableAllClippingPlanes();
      ids.forEach((id) => this.enablePlane(id));
    }
  }

  updateMaterials() {
    try {
      const planes = this.activeClippingPlanes
        .filter((clp) => !clp.horizontal)
        .map((clp) => clp.plane);
      const horizontalPlane = this.activeClippingPlanes.find(
        (cp) => cp.horizontal
      )?.plane;

      this.editor.objectEntities.forEach((entity) => {
        const mesh = entity.object;

        if (entity.cam1Material && horizontalPlane)
          entity.cam1Material.clippingPlanes = [horizontalPlane, ...planes];

        if (entity.cam1Material && !horizontalPlane)
          entity.cam1Material.clippingPlanes = [];

        if (mesh?.material) {
          this.updateMaterial(mesh, planes, horizontalPlane);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateMaterial(mesh, planes, horizontalPlane) {
    // active planes to cut.
    let _planes = [...planes];

    if (!Array.isArray(mesh?.material)) {
      if (mesh.material.userData?.doNotClip) return;
      if (horizontalPlane) _planes = [..._planes, horizontalPlane];
      mesh.material.clippingPlanes = _planes;
      return;
    }

    // cutting 3D
    if (this.cut3DWithActiveHCP && horizontalPlane)
      _planes = [...planes, horizontalPlane];
    mesh?.material.forEach((m) => {
      if (m.userData?.doNotClip) return;
      m.clippingPlanes = _planes;
    });
  }

  initSceneClippingPlanes(clippingPlanes) {
    // disable & hide all clipping planes
    this.disableAllClippingPlanes();
    this.hideAllClippingPlanes();

    clippingPlanes.forEach(({id, sceneClientId, origin, normal}) => {
      const cp = this.clippingPlanes.find((cp) => cp.id === id);
      if (!cp) {
        const originV = new Vector3(origin.x, origin.y, origin.z);
        const normalV = new Vector3(normal.x, normal.y, normal.z);
        this.createPlane({origin: originV, normal: normalV, id, sceneClientId});
      }
    });
  }

  // horizontal clipping plane, used to cut at H + 1m

  setHorizontalClippingPlane(height) {
    this.heightHCP = height;
    const cpH = this.clippingPlanes.find((cp) => cp.horizontal);
    cpH?.plane.setFromNormalAndCoplanarPoint(
      new Vector3(0, -1, 0),
      new Vector3(0, height, 0)
    );
    this.origin = {x: 0, y: height, z: 0};
  }

  enableHorizontalClippingPlane() {
    const cpH = this.clippingPlanes.find((cp) => cp.horizontal);
    this.enablePlane(cpH.id);
  }

  disableHorizontalClippingPlane() {
    const cpH = this.clippingPlanes.find((cp) => cp.horizontal);
    this.disablePlane(cpH.id);
  }
}
