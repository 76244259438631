import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Box, Button, alpha} from "@mui/material";
import {Launch, Edit} from "@mui/icons-material";

import useSelectedMeasurement from "../useSelectedMeasurement";
import {setSelectedMeasurementsModelId} from "../measurementsSlice";
import {setLastPackageId} from "Features/elementPackages/packagesSlice";
import {setJump} from "Features/pdf/pdfSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setOpenSelectionPanel} from "Features/selection/selectionSlice";
import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";
// import {setOpenSections} from "Features/ui/uiSlice";

export default function ButtonEditMeasurement({scene, measurement}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // scene

  let sceneId = scene?.id;
  if (!sceneId) sceneId = scene?.clientId;

  // strings

  const seeS = "Editer";

  // data

  const openSelectionPanel = useSelector((s) => s.selection.openSelectionPanel);
  // const os = useSelector((s) => s.ui.openSections);

  // helper - measurementsModel

  const measurementsModelId = measurement?.measurementsModelId;

  // handlers

  function handleSeeClick() {
    navigate(`/bimboxes/${sceneId}/${measurementsModelId}/measurements`);
    //dispatch(setLastPackageId(measurementsModelId));
    dispatch(
      setJump({
        page: measurement.drawingProps.pageNumber,
        measId: measurement.id,
      })
    );
    dispatch(setSelectedPdfModelId(measurement.pdfModelId));
    dispatch(setSelectedMeasurementsModelId(measurementsModelId));
    if (!openSelectionPanel) {
      dispatch(setOpenSelectionPanel(true));
    }
    dispatch(setSelectedShortcutRight(null));
    // caplaEditor?.measDataManager.setSelectedModelById(measurementsModelId);
    // dispatch(
    //   setOpenSections({
    //     outlet: !os.viewer3D,
    //     viewer3D: os.viewer3D,
    //     fixedViewersBox: true,
    //     callToAction: false,
    //   })
    // );
  }

  return (
    <Box sx={{color: "text.secondary"}}>
      <Button
        startIcon={<Edit />}
        //variant="contained"
        color="secondary"
        fullWidth
        onClick={handleSeeClick}
      >
        {seeS}
      </Button>
    </Box>
  );
}
