import {
  MeshBasicMaterial,
  PlaneBufferGeometry,
  DoubleSide,
  Mesh,
  LineLoop,
  Object3D,
} from "three";

import {
  imageModelPickedLineMaterial,
  imageModelLineMaterial,
} from "../utils/materials";

import MaskGeometry from "./MaskGeometry";

import theme from "Styles/theme";

export default class ImagePartEntity {
  constructor({imageTexture, model, part, scene, imageModel}) {
    this.type = "IMAGE_PART_ENTITY";
    this.imageTexture = imageTexture;
    this.modelId = model.id;
    this.model = model;

    this.imageModel = imageModel; // used to position the part with respect to the image entity (model)
    this.scene = scene;

    this.name = part.name;
    //this.position = part.position ? part.position : model.position; // in the world coordinate system. => set by default the same as the parent entity. /!\ doesn't work in space...
    this.position = part.position ? part.position : {x: 0, y: 0, z: 0}; // position in main image coord syst (we add it).
    this.rotation = part.rotation ? part.rotation : {x: 0, y: 0, z: 0};
    //this.rotation = {x: -Math.PI / 2, y: 0, z: 0};
    this.mask = part.mask;
    this.partId = part.id;
    this.visible = part.visible;

    this.maskGeometry = undefined; // created with the image loader.

    this.pickId = undefined; // used by the editor click handler
    this.selectionId = undefined; // will be the image model id

    this.materialLR = new MeshBasicMaterial({
      color: theme.palette.secondary.main,
      side: DoubleSide,
      transparent: true,
      opacity: 0.8,
    });

    this.materialHR = new MeshBasicMaterial({
      color: theme.palette.secondary.main,
      side: DoubleSide,
      transparent: true,
      opacity: 0.8,
    });

    this.materialRR = new MeshBasicMaterial({
      color: theme.palette.secondary.main,
      side: DoubleSide,
      alphaTest: 0.1,
      //transparent: true,
      //opacity: 0.8,
    });
  }

  /*
   * LOADER
   */

  updateMaterialsFromTexture() {
    this.materialLR =
      this.imageTexture.textureLR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureLR,
        side: DoubleSide,
        transparent: true,
        opacity: 0.8,
      });

    this.materialHR =
      this.imageTexture.textureHR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureHR,
        side: DoubleSide,
        transparent: true,
        opacity: 0.8,
      });

    this.materialRR = new MeshBasicMaterial({
      map: this.imageTexture.textureRR,
      side: DoubleSide,
      alphaTest: 0.1,
      //transparent: true,
      //opacity: 0.8,
    });
  }

  loadImage(resolution) {
    console.log(
      "CREATE IMAGE PART IN SCENE",
      resolution,
      this.model,
      this.position,
      this.mask
    );
    this.createObject(resolution);
    this.updateHelpers();
  }

  createObject(resolution) {
    // geometry

    // const plane = new PlaneBufferGeometry(this.model.width, this.model.height);

    this.maskGeometry = new MaskGeometry({
      width: this.model.width,
      height: this.model.height,
      mask: this.mask,
    });
    const plane = this.maskGeometry.geometry;

    // object
    const materialsMap = {
      RR: this.materialRR,
      LR: this.materialLR,
      HR: this.materialHR,
    };
    this.object = new Mesh(plane, materialsMap[resolution]);
    this.object.layers.enable(1);

    // transformation
    //this.scene.attach(this.object);
    this.object.position.set(this.position.x, this.position.y, this.position.z);
    this.object.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
    // this.imageModel.object.attach(this.object);
    this.imageModel.object.add(this.object);
    //this.object.position.set(this.position.x, this.position.y, this.position.z);
    //this.object.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z);
  }

  /*
   * UPDATES
   */

  resetTransformation() {
    this.position = {x: 0, y: 0, z: 0};
    this.rotation = {x: 0, y: 0, z: 0};
  }

  /*
   * TRANSFORMATIONS
   */

  // update entity rotation & position based on is rotation & position attributes
  updateTransformation() {
    console.log("updateTransformation12");
    try {
      this.scene.attach(this.object);
      this.object.position.set(
        this.position.x,
        this.position.y,
        this.position.z
      );
      this.object.rotation.set(
        this.rotation.x,
        this.rotation.y,
        this.rotation.z
      );
      this.imageModel.object.attach(this.object);
    } catch (e) {
      console.log(e);
    }
  }
  /*
   * Resolution
   */

  updateResoMaterials(reso) {
    this.materialRR =
      this.imageTexture.url &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureRR,
        side: DoubleSide,
        alphaTest: 0.05,
        transparent: true,
        opacity: 0.8,
      });
    // !! we changed this.materialRR reference

    this.materialLR =
      this.imageTexture.textureLR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureLR,
        side: DoubleSide,
      });

    this.materialHR =
      this.imageTexture.textureHR &&
      new MeshBasicMaterial({
        map: this.imageTexture.textureHR,
        side: DoubleSide,
      });

    this.switchResolutionTo(reso);
  }

  switchResolutionTo(resolution) {
    console.log("switch resolution to", resolution);
    if (this.object) {
      if (resolution === "LR") {
        this.object.material = this.materialLR
          ? this.materialLR
          : this.materialRR;
      }
      if (resolution === "HR") {
        console.log("switch to HD");
        this.object.material = this.materialHR
          ? this.materialHR
          : this.materialRR;
      }
      if (resolution === "RR") {
        console.log("switch to Raw Image");
        this.object.material = this.materialRR;
      }
    }
  }

  /*
   * Hide & Show
   */

  show() {
    this.hidden = false;
    if (this.object) {
      this.object.layers.enable(1);
      this.object.layers.enable(3);
      this.object.visible = true;
    }
  }

  hide() {
    this.hidden = true;
    if (this.object) {
      this.object.layers.disable(1);
      this.object.layers.disable(3);
      this.object.visible = false;
    }
  }

  setOpacity(opacity) {
    if (opacity) {
      if (this.materialRR) {
        if (opacity > 99) {
          this.materialRR.transparent = false;
        } else {
          this.materialRR.transparent = true;
          this.materialRR.opacity = opacity / 100;
        }
      }

      if (this.materialLR) this.materialLR.opacity = opacity / 100;
      if (this.materialHR) this.materialHR.opacity = opacity / 100;
    }
  }

  updateMask(mask) {
    this.maskGeometry.updateMask(mask);
    this.object.geometry = this.maskGeometry.geometry;
    //this.updateHelpers();
  }

  updateHelpers() {
    // create or update helpers
    // clean
    if (this.helper && this.pickHelper) {
      this.object.remove(this.helper, this.pickHelper);
    }
    // geometry
    //const geometry = this.object.geometry;
    const geometry = this.maskGeometry.contourGeometry;

    // helper
    this.helper = new LineLoop(geometry, imageModelLineMaterial);
    //this.helper.layers.enable(1);
    // pick helper
    this.pickHelper = new LineLoop(geometry, imageModelPickedLineMaterial);
    this.pickHelper.scale.set(1.01, 1.01, 1.01);
    //this.pickHelper.layers.enable(2);
    // updates
    this.object.add(this.pickHelper, this.helper);
  }

  /*
   * Pick & Select
   */

  pick() {
    this.pickHelper.layers.enable(2);
    this.pickId = this.partId;
    return this;
  }

  unpick() {
    this.pickHelper.layers.disable(2);
    this.pickId = undefined;
  }

  select() {
    this.selectionId = this.partId;
    return this;
  }
  unselect() {
    this.selectionId = undefined;
  }

  parse() {
    return {
      type: "IMAGE_PART_ENTITY",
      name: this.name,
      partId: this.partId,
      modelId: this.model.id,
      rotation: this.rotation,
      position: this.position,
      model: {
        ...this.model,
      },
    };
  }
}
