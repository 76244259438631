import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionLeftPanelActionsElementTypes from "./SectionLeftPanelActionsElementTypes";
import SectionLeftPanelActionsPackages from "Features/elementPackages/components/SectionLeftPanelActionsPackages";
import SectionLeftPanelActionsInputs from "Features/inputs/components/SectionLeftPanelActionsInputs";
import SectionLeftPanelActionsMeasurements from "Features/measurements/components/SectionLeftPanelActionsMeasurements";
import SectionLeftPanelActionsSectors from "Features/sectors/components/SectionLeftPanelActionsSectors";
import SectionLeftPanelActionsZones from "Features/zones/components/SectionLeftPanelActionsZones";
import SectionLeftPanelActionsViews from "Features/views/components/SectionLeftPanelActionsViews";
import SectionLeftPanelActionsRessources from "Features/ressources/components/SectionLeftPanelActionsRessources";
import SectionLeftPanelActionsSurveys from "Features/surveys/components/SectionLeftPanelActionsSurveys";
import SectionLeftPanelActionsRooms from "Features/rooms/components/SectionLeftPanelActionsRooms";
import SectionLeftPanelActionsScopes from "Features/scopes/components/SectionLeftPanelActionsScopes";
import SectionLeftPanelActionsArticles from "Features/articles/components/SectionLeftPanelActionsArticles";

export default function SectionLeftPanelActions({scene, caplaEditor}) {
  // data

  const sceneModule = useSceneModule();

  return (
    <Box>
      {sceneModule === "INPUTS" && (
        <SectionLeftPanelActionsInputs
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ZONES" && (
        <SectionLeftPanelActionsZones scene={scene} caplaEditor={caplaEditor} />
      )}
      {sceneModule === "PACKAGES" && (
        <SectionLeftPanelActionsPackages
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ELEMENT_TYPES" && (
        <SectionLeftPanelActionsElementTypes
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "RESSOURCES" && (
        <SectionLeftPanelActionsRessources
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ARTICLES" && (
        <SectionLeftPanelActionsArticles
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "EDIT_MEASUREMENTS" && (
        <SectionLeftPanelActionsMeasurements
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "SECTORS" && (
        <SectionLeftPanelActionsSectors
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "VIEWS" && (
        <SectionLeftPanelActionsViews scene={scene} caplaEditor={caplaEditor} />
      )}
      {sceneModule === "SURVEYS" && (
        <SectionLeftPanelActionsSurveys
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      {sceneModule === "ROOMS" && (
        <SectionLeftPanelActionsRooms scene={scene} caplaEditor={caplaEditor} />
      )}
      {sceneModule === "SCOPES" && (
        <SectionLeftPanelActionsScopes
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
    </Box>
  );
}
