import {CircularProgress, Box} from "@mui/material";

import {useSelected, useFocused} from "slate-react";

import ImagePreview from "Features/images/components/ImagePreview";

export default function Image({attributes, children, element}) {
  const selected = useSelected();
  const focused = useFocused();
  const loading = element.status === "loading";

  const testBase64 = element.url.includes("base64");

  return (
    <Box {...attributes} sx={{width: "100%", position: "relative"}}>
      <div contentEditable={false}>
        {loading && (
          <Box sx={{position: "absolute"}}>
            <CircularProgress />
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            height: 200,
            boxShadow: `${selected && focused ? "0 0 0 3px #B4D5FF" : "none"}`,
            ...(testBase64 && {border: "3px solid red"}),
          }}
        >
          <ImagePreview
            src={element.url}
            width={"100%"}
            height={"100%"}
            outlined
            detail
          />
        </Box>
      </div>
      {children}
    </Box>
  );
}
