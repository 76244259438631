export function nextStep(mode, step) {
  const modeStep = `${mode}_${step}`;

  switch (modeStep) {
    case "PROCESS_ADD_MARKER_POSITION":
      return "NAME";
  }
}

export function getAction(mode, step) {
  const modeStep = `${mode}_${step}`;

  switch (modeStep) {
    case "PROCESS_ADD_MARKER_POSITION":
      return "NAME_MARKER";
  }
}
