import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  alpha,
  Typography,
  Card,
  CardContent,
  Autocomplete,
  TextField,
  styled,
} from "@mui/material";
import {Layers as Storey, Category as Type} from "@mui/icons-material";

import FilterStoreys from "./FilterStoreys";
import FilterTypes from "./FilterTypes";
import FilterParts from "./FilterParts";

import useTranslation from "Features/translations/useTranslation";

export default function ToolFilter({editor3d}) {
  const {t} = useTranslation("viewer3D");

  // strings

  const titleString = t("viewer3D:filter.title");

  // data

  const allModels = useSelector((state) => state.viewer3D.models);
  const sceneClientId = useSelector((state) => state.scenes.sceneClientId);
  const models = allModels.filter(
    (m) =>
      (m.type === "IFC" || m.parts?.length > 0) &&
      m.sceneClientId === sceneClientId
  );
  const selectedModelId = useSelector(
    (state) => state.viewer3D.selectedModelId
  );
  const selectedModel = models.find((model) => model.id === selectedModelId);

  // init state

  const initModel = selectedModel ? selectedModel : models[0];
  const initFilter = initModel?.type === "IFC" ? "STOREY" : "PART";

  // local data

  const [filter, setFilter] = useState(initFilter);
  const [model, setModel] = useState(initModel);

  // helpers

  const isImage = model?.type === "IMAGE";

  // update state

  useEffect(() => {
    console.log("[ToolFitler] effect");
    if (selectedModel?.id) {
      setModel(selectedModel);
    }
  }, [selectedModel?.id]);

  useEffect(() => {
    if (initFilter) {
      setFilter(initFilter);
    }
  }, [initFilter]);

  // handlers

  function handleModelChange(e, v) {
    setModel(v);
    if (v?.type === "IMAGE") {
      setFilter("PART");
    } else {
      setFilter("STOREY");
    }
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="body2" sx={{mb: 2}}>
          <b>{titleString}</b>
        </Typography>
        <Box sx={{display: "flex", justifyContent: "space-between", mb: 2}}>
          <Autocomplete
            sx={{mr: 1, flexGrow: 1}}
            options={models}
            value={model}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <Typography
                {...props}
                key={option.id}
                variant="body2"
                sx={{pl: 1}}
              >
                {option.name}
              </Typography>
            )}
            onChange={handleModelChange}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Model"
                inputProps={{
                  ...params.inputProps,
                  sx: (theme) => ({
                    fontSize: theme.typography.body2.fontSize,
                  }),
                }}
              />
            )}
          />
          <ToggleButtonGroup
            orientation="horizontal"
            value={filter}
            exclusive
            size="small"
            onChange={(e, v) => setFilter(v)}
            sx={{
              background: (theme) => alpha(theme.palette.common.white, 0.85),
              visibility: isImage && "hidden",
            }}
          >
            <ToggleButton value="STOREY" aria-label="module">
              <Storey fontSize="small" />
            </ToggleButton>
            <ToggleButton value="TYPE" aria-label="module">
              <Type fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {filter === "STOREY" && <FilterStoreys editor3d={editor3d} model={model} />}
        {filter === "TYPE" && <FilterTypes editor3d={editor3d} model={model} />}
        {filter === "PART" && <FilterParts editor3d={editor3d} model={model} />}
      </CardContent>
    </Card>
  );
}
