import caplaDB, {MODEL_DO} from "../db";

import {fetchStoredFile} from "../files/services";
import {fetchStoredRessource} from "../ressources/services";

export async function createStoredModel({storedModel}) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(MODEL_DO, "readwrite").objectStore(MODEL_DO);

    const oldModel = await store.get(storedModel.id);
    if (oldModel) {
      store.put({...oldModel, ...storedModel});
    } else {
      store.add(storedModel);
    }
    return storedModel;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchStoredModel(modelId) {
  try {
    const db = await caplaDB.dbPromise;
    const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
    const storedModel = await store.get(modelId);
    if (storedModel) {
      const file =
        storedModel.fileClientId &&
        (await fetchStoredFile(storedModel.fileClientId));
      const fileHR =
        storedModel.fileHRClientId &&
        (await fetchStoredFile(storedModel.fileHRClientId));
      const fileLR =
        storedModel.fileLRClientId &&
        (await fetchStoredFile(storedModel.fileLRClientId));

      const fileGltf =
        storedModel.fileGltfClientId &&
        (await fetchStoredFile(storedModel.fileGltfClientId));

      const fileJson =
        storedModel.fileJsonClientId &&
        (await fetchStoredFile(storedModel.fileJsonClientId));

      const newModel = {
        ...storedModel,
        url: file && URL.createObjectURL(file),
        urlLowReso: fileLR && URL.createObjectURL(fileLR),
        urlHightReso: fileHR && URL.createObjectURL(fileHR),
        urlGltf: fileGltf && URL.createObjectURL(fileGltf),
        urlJson: fileJson && URL.createObjectURL(fileJson),
      };
      return {model: newModel, file, fileLR, fileHR, fileGltf, fileJson};
    } else {
      return undefined;
    }
  } catch (e) {
    console.log(e);
  }
}

export async function fetchStoredModels(sceneClientId, withoutFiles) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
  const index = store.index("sceneClientId");
  const items = await index.getAll(sceneClientId);
  let models = await Promise.all(
    items.map(async (item) => {
      const file =
        item?.fileClientId &&
        !withoutFiles &&
        item.enabled &&
        (await fetchStoredFile(item.fileClientId));
      const fileHR =
        item?.fileHRClientId &&
        !withoutFiles &&
        item.enabled &&
        (await fetchStoredFile(item.fileHRClientId));
      const fileLR =
        item?.fileLRClientId &&
        !withoutFiles &&
        item.enabled &&
        (await fetchStoredFile(item.fileLRClientId));
      const fileGltf =
        item?.fileGltfClientId &&
        !withoutFiles &&
        item.enabled &&
        (await fetchStoredFile(item.fileGltfClientId));
      const fileJson =
        item?.fileJsonClientId &&
        !withoutFiles &&
        item.enabled &&
        (await fetchStoredFile(item.fileJsonClientId));
      const newModel = {
        ...item,
        url: file && URL.createObjectURL(file),
        urlLowReso: fileLR && URL.createObjectURL(fileLR),
        urlHightReso: fileHR && URL.createObjectURL(fileHR),
        urlGltf: fileGltf && URL.createObjectURL(fileGltf),
        urlJson: fileJson && URL.createObjectURL(fileJson),
        //fileSize: file ? file.size : 0, /! delete the fileSize saved from the remote
      };
      return {model: newModel, file, fileHR, fileLR, fileGltf, fileJson};
    })
  );
  // clean edge case : model with fileClientId but file is empty.
  models = models.filter(
    (m) => !(!m.model.url && m.model.fileClientId && m.enabled)
  );

  return models;
}

export async function updateStoredModel(updates) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readwrite").objectStore(MODEL_DO);
  try {
    const oldModel = await store.get(updates.id);
    if (oldModel) {
      const newModel = {...oldModel, ...updates};
      await store.put(newModel);
      return newModel;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function updateStoredModels(models) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readwrite").objectStore(MODEL_DO);
  try {
    models.forEach(async (model) => {
      const oldModel = await store.get(model.id);
      if (oldModel) {
        const newModel = {...oldModel, ...model};
        await store.put(newModel);
      }
    });
  } catch (error) {
    console.log(error);
  }
}

export async function fetchAllStoredModels() {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readonly").objectStore(MODEL_DO);
  const items = await store.getAll();
  return items;
}

export async function deleteStoredModel(id) {
  const db = await caplaDB.dbPromise;
  const store = db.transaction(MODEL_DO, "readwrite").objectStore(MODEL_DO);
  console.log("store", id);
  try {
    store.delete(id);
  } catch (error) {
    console.log(error);
  }
}

export async function deleteStoredModelsByScene(sceneClientId) {
  const models = await fetchStoredModels(sceneClientId, true);
  for (const model of models) {
    await deleteStoredModel(model.id);
  }
}
