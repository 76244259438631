import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, List} from "@mui/material";
import getItemsMapById from "Utils/getItemsMapById";

import ListItemArticleType from "./ListItemArticleType";
import ListItemArticleTypeGlobalScope from "./ListItemArticleTypeGlobalScope";
import ListItemArticleTypeGroupScope from "./ListItemArticleTypeGroupScope";

import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import getItemsWithParentsAndGroups from "Utils/getItemsWithParentsAndGroups";
import getArticleTypesCount from "../utils/getArticleTypesCount";

import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";
import getArticleTypesListItemsProps from "../utils/getArticleTypesListItemsProps";

export default function SectionArticleTypesFormulas({
  scene,
  article,
  selectedGroupProxy,
  elementTypes,
  onArticleTypeFuncChange,
  onArticleTypeFiltersStrChange,
  onArticleTypeItemChange,
  onRemoveArticleType,
  showCoreTypes,
}) {
  const dispatch = useDispatch();

  console.log("articleXXX", article);

  // data

  const articlesGroup = useSelectedArticlesGroup();
  const elementTypesGroups = useElementTypesGroupsProxyByScene(scene, {
    sortByName: true,
    filterByScope: true,
    filterByTypesGroupsIds: articlesGroup?.typesGroupsIds,
  });

  // data articleTypes

  let articleTypes = showCoreTypes ? article?.coreTypes : article?.types;
  if (!articleTypes) articleTypes = {};

  // helpers

  const typesCount = getArticleTypesCount(articleTypes);
  let scope = "global";
  if (selectedGroupProxy && typesCount === 0) scope = "typesGroup";
  if (selectedGroupProxy && typesCount > 0) scope = "type";

  // helpers

  const elementTypesMap = getItemsMapById(elementTypes);

  // helpers - items

  const data = {elementTypesMap};
  const items = getArticleTypesListItemsProps(articleTypes, data);

  // helpers - input for sortedItems

  const groupsWithItems = elementTypesGroups.map((group) => {
    // data transformation to use the getItemsWithParentsAndGroups
    return {
      ...group,
      items: elementTypes.filter((type) => type.groupId === group.id),
    };
  });

  let sortedItems = getItemsWithParentsAndGroups(items, groupsWithItems);

  if (!sortedItems?.length > 0) {
    sortedItems = [{id: "globalScope", filtersStr: ""}];
  }
  console.log("sortedItems", sortedItems);

  //  handlers

  function handleFuncChange({groupId, typeId, func}) {
    onArticleTypeFuncChange({groupId, typeId, func});
  }

  function handleFiltersStrChange({groupId, typeId, filtersStr}) {
    console.log("handleFiltersStrChange", groupId, typeId, filtersStr);
    onArticleTypeFiltersStrChange({groupId, typeId, filtersStr});
  }

  function handleTypeItemChange({groupId, typeId, filtersStr, func}) {
    console.log("handleTypeItemChange", groupId, typeId, filtersStr);
    onArticleTypeItemChange({groupId, typeId, filtersStr, func});
  }

  function handleRemove({groupId, typeId}) {
    onRemoveArticleType({groupId, typeId});
  }

  return (
    <Box sx={{width: 1}}>
      {/* {scope === "global" && (
        <ListItemArticleTypeGlobalScope
          articleId={article.id}
          filtersStr={article?.types?.filtersStr}
          onFiltersStrChange={handleFiltersStrChange}
        />
      )}
      {scope === "typesGroup" && (
        <ListItemArticleTypeGroupScope
          articleId={article.id}
          groupProxy={selectedGroupProxy}
          filtersStr={article?.types?.filtersStr}
          onFiltersStrChange={handleFiltersStrChange}
        />
      )} */}
      {true && (
        <List sx={{width: 1}}>
          {sortedItems.map((item, index) => {
            // showInput
            let showInput = false;
            if (!item.isGroup && !item.isTitle) {
              showInput = true;
            } else if (
              item.id === "globalScope"
              //(item.filtersStr || sortedItems.length === 1)
            ) {
              showInput = true;
            } else if (index === sortedItems.length - 1) {
              showInput = true;
            } else if (
              // title only.
              item.isTitle &&
              sortedItems[index + 1]?.num &&
              !sortedItems[index + 1]?.num?.startsWith(item.num)
            ) {
              showInput = true;
            }

            return (
              <ListItemArticleType
                key={item.id + index}
                articleId={article?.id}
                typeId={item.typeId}
                color={item.color}
                drawingShape={item.drawingShape}
                groupId={item.groupId}
                func={item.func}
                filtersStr={item.filtersStr}
                name={item.name}
                isGroup={item.isGroup}
                isTitle={item.isTitle}
                style={item.style}
                num={item.num}
                //onFuncChange={handleFuncChange}
                //onFiltersStrChange={handleFiltersStrChange}
                onTypeItemChange={handleTypeItemChange}
                onRemove={handleRemove}
                showInput={showInput}
              />
            );
          })}
        </List>
      )}
    </Box>
  );
}
