import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getTypesFromWorksheet(worksheet) {
  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(2, rowCount - 1).values();

  const valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    valuesA.push(rowValues);
  }

  const fields = [
    "name",
    "isTitle",
    "code",
    "drawingShape",
    "unit",
    "color",
    "dim1",
    "dim2",
    "height",
  ];

  const loadedTypes = [];

  valuesA.map((valueA) => {
    const type = {};
    valueA.forEach((value, index) => (type[fields[index]] = value));
    loadedTypes.push(type);
  });
  return loadedTypes;
}
