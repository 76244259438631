import React from "react";

import {useSelector} from "react-redux";

import {List} from "@mui/material";

import ListItemSceneDataset from "./ListItemSceneDataset";

export default function ListSceneDatasets({datasets, selection, onClick}) {
  //handlers

  function handleClick({id, isModel, isListing}) {
    if (onClick) onClick({id, isListing, isModel});
  }

  return (
    <List dense sx={{bgcolor: "common.white"}} disablePadding>
      {datasets?.map((dataset) => {
        return (
          <ListItemSceneDataset
            key={dataset.id}
            {...dataset}
            onClick={handleClick}
          />
        );
      })}
    </List>
  );
}
