import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  setSelectionInViewerFirstRendered,
  setSelectionTabInViewer,
} from "Features/selection/selectionSlice";
import {setSelectedRessourceId} from "Features/ressources/ressourcesSlice";
import {setShortcutToolViewer3D} from "Features/ui/uiSlice";
import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";
import {setSelectedRessourcesInDatagrid} from "Features/measurements/measurementsSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";

import {Box, List} from "@mui/material";
import ListItemSceneRessources from "./ListItemSceneRessources";
import SearchBarObjects from "Features/search/components/SearchBarObjects";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useMeasurementsByRessourceForDatagrid from "Features/measurements/useMeasurementsByRessourceForDatagrid";

function getRank(num) {
  return num ? num.split(".").length : null;
}

export default function ListSceneRessources({scene, viewer, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  const selectionInViewerFirstRendered = useSelector(
    (s) => s.selection.selectionInViewerFirstRendered
  );
  const ressourcesInDatagrid = useSelector(
    (s) => s.measurements.selectedRessourcesInDatagrid
  );
  const group = useSelector((s) => s.measurements.selectedRessourceGroup);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const quantities = useMeasurementsByRessourceForDatagrid(
    scene,
    mode,
    null,
    group
  );
  const noQuantities = useSelector((s) => s.ressources.noQuantities);

  // helpers

  let ressourcesInLeftPanel = quantities;
  ressourcesInLeftPanel = ressourcesInLeftPanel.filter((r) =>
    noQuantities ? noQuantities : r.isTitle || r.quantity
  );

  // state

  const [filteredGroup, setFilteredGroup] = useState(group);
  const [filteredRessources, setFilteredRessources] = useState(
    ressourcesInLeftPanel
  );

  // handlers

  function handleListItemClick(ressource) {
    console.log("click on ressource", ressource);
    // set selected pdf (CCTP) if related document.
    const pdfModelId = ressource?.extractedText?.pdfModelId;
    if (pdfModelId && !selectedPdfModelId) {
      dispatch(setSelectedPdfModelId(pdfModelId));
    }
    //
    let newSelection;
    if (ressourcesInDatagrid.includes(ressource.id)) {
      newSelection = [];
      dispatch(setSelectedRessourceId(null));
    } else if (ressource?.id) {
      newSelection = [ressource.id];
      dispatch(setSelectedRessourceId(ressource.id));
      dispatch(setSelectionTabInViewer("ressource"));
      if (!selectionInViewerFirstRendered) {
        dispatch(setShortcutToolViewer3D("SELECTION"));
        dispatch(setSelectedShortcutRight("SELECTION"));
        dispatch(setSelectionInViewerFirstRendered(true));
      }
    }
    handleSelectionChange(newSelection);
    //
    // go to extracted text
    //
    const pageNumber = ressource?.extractedText?.pageNumber;
    const pageRotation = ressource?.extractedText?.pageRotation;
    const pageWidth = ressource?.extractedText?.pageWidth;
    const pageHeight = ressource?.extractedText?.pageHeight;
    const rects = ressource?.extractedText?.rects;
    if (rects) {
      let {x1, y1, x2, y2} = rects[0];
      if (pageWidth && (pageRotation === 0 || pageRotation === 2)) {
        x1 = 0;
        x2 = pageWidth;
      }
      if (pageHeight && (pageRotation === 1 || pageRotation === 3)) {
        y1 = 0;
        y2 = pageHeight;
      }
      const viewportCoords = {x1, x2, y1, y2};
      const context = {pageNumber, pageRotation, viewportCoords};
      caplaEditor?.editorPdf?.setPdfContext(context);
      // highlight text
      caplaEditor?.editorPdf?.sceneEditor.removeHighlightedExtractedText();
      caplaEditor?.editorPdf?.sceneEditor.highlightExtractedText(
        ressource?.extractedText
      );
    }
  }

  function handleSelectionChange(selection) {
    dispatch(setSelectedRessourcesInDatagrid(selection));
  }

  function handleSearch(objects) {
    setFilteredRessources(objects);
  }

  // effect

  useEffect(() => {
    if (group !== filteredGroup) {
      setFilteredGroup(group);
      setFilteredRessources(ressourcesInLeftPanel);
    }
  }, [group]);

  return (
    <>
      <SearchBarObjects
        objects={ressourcesInLeftPanel}
        onSearch={handleSearch}
        keys={["num", "name"]}
      />
      <Box sx={{flexGrow: 1, overflow: "auto", bgcolor: "common.white"}}>
        <Box
          sx={{
            width: 1,
            height: 1,
            overflow: "auto",
          }}
        >
          <List dense disablePadding>
            {filteredRessources.map((ressource) => {
              const selected = ressourcesInDatagrid.includes(ressource.id);
              const resWithProperQuantity = quantities.find(
                (q) => q.id === ressource.id
              );
              if (
                ressource.isTitle ||
                resWithProperQuantity?.quantity ||
                noQuantities
              )
                return (
                  <ListItemSceneRessources
                    key={ressource.id}
                    ressource={ressource}
                    onRessourceClick={handleListItemClick}
                    selected={selected}
                    resWithProperQuantity={resWithProperQuantity}
                  />
                );
              return <></>;
            })}
          </List>
        </Box>
      </Box>
    </>
  );
}
