import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, alpha} from "@mui/material";

import {
  setShortcutElementTypes,
  // toggleShortcutElementType,
} from "Features/measurements/measurementsSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";

import TableGroupedItems from "Components/TableGroupedItems";
import sortByNum from "Utils/sortByNum";
import getElementTypesWithMainQties from "Features/elementTypes/utils/getElementTypesWithMainQties";

export default function ShortcutElementTypesInPdf({
  scene,
  mode,
  viewer,
  editorPdf,
}) {
  const dispatch = useDispatch();

  // string

  const headerTitleS = "éléments repérés";

  // data - showed measurements

  const measurements = useSelector(
    (s) => s.pdf.measurementsShowedInViewerPdfWithoutShortcut
  );

  // data

  const pageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const shortcutElementTypes = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false,
    true,
    viewer
  );

  // local state

  const [expandGroupIds, setExpandGroupIds] = useState();

  // helpers - page measurements

  let ms = viewer ? fms : measurements;
  if (!ms) ms = [];

  const pageMeasurements = ms.filter(
    (m) =>
      m.drawingProps?.pageNumber === pageNumber &&
      m.pdfModelId === selectedPdfModelId
  );

  // helpers - elementTypeIds from list of measurements.

  const sceneElementTypeIds = pageMeasurements.map((m) => m.elementTypeId);

  const elementTypeIdCountMap = sceneElementTypeIds.reduce(
    (ac, elementTypeId) => {
      const item = ac[elementTypeId];
      if (!item) {
        ac[elementTypeId] = 1;
      } else {
        ac[elementTypeId] += 1;
      }
      return ac;
    },
    {}
  );

  // elementTypes with Qties

  const elementTypesWithMainQties = getElementTypesWithMainQties(
    elementTypes,
    pageMeasurements,
    filterNoVoids
  );

  // helpers - for table

  const allSortedItems = elementTypesWithMainQties
    .map((t) => {
      const newType = {...t};
      if (!newType.group) {
        newType.groupName = "-?-";
        newType.groupId = "-?-";
      }
      if (!newType.groupId) newType.groupId = t.group;
      if (!newType.groupName) newType.groupName = t.group;
      newType.unit = t.mainUnit;
      newType.qty = t.mainQuantity;
      return newType;
    })
    .sort((a, b) => a.groupName.localeCompare(b.groupName))
    .sort((a, b) => sortByNum(a.num, b.num));

  const scopedItemIdsWithCount = Object.entries(elementTypeIdCountMap).map(
    ([id, qty]) => ({id, count: qty})
  );

  const showedItemIds = Array.isArray(shortcutElementTypes)
    ? [...shortcutElementTypes]
    : [];

  const allGroupIds = [...new Set(allSortedItems.map((item) => item.groupId))];

  useEffect(() => {
    if (!expandGroupIds) setExpandGroupIds(allGroupIds);
  }, [expandGroupIds]);

  // handlers

  // function handleElementTypeClick(elementTypeId) {
  //   dispatch(toggleShortcutElementType(elementTypeId));
  // }

  // function handleShowAll() {
  //   dispatch(setShortcutElementTypes(pageElementTypesIds));
  // }

  // function handleHideAll() {
  //   dispatch(setShortcutElementTypes([]));
  // }

  // handlers - table

  const onShowedItemIdsChange = (ids) => {
    dispatch(setShortcutElementTypes(ids));
  };

  const onExpandGroupIdsChange = (ids) => {
    setExpandGroupIds(ids);
  };

  return (
    <Box
      sx={{
        width: 300,
        bgcolor: (theme) => alpha(theme.palette.background.default, 0.95),
        p: 0.5,
      }}
    >
      <TableGroupedItems
        editorPdf={editorPdf}
        headerTitle={headerTitleS}
        allSortedItems={allSortedItems}
        scopedItemIdsWithCount={scopedItemIdsWithCount}
        showedItemIds={showedItemIds}
        onShowedItemIdsChange={onShowedItemIdsChange}
        expandGroupIds={expandGroupIds}
        onExpandGroupIdsChange={onExpandGroupIdsChange}
        options={{
          disableQties: false,
          disableNums: false,
          disableDescriptions: true,
        }}
      />
    </Box>
  );
}
