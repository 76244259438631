import Jimp from "jimp";

export async function loadImageAsync(url) {
  const image = new Image();
  return new Promise((resolve) => {
    image.onload = () => {
      resolve(image);
    };
    image.src = url;
  });
}

export async function createImageAsync(url) {
  const image = new Image();
  return new Promise((resolve) => {
    image.onload = () => {
      resolve(image);
    };
    image.crossOrigin = "anonymous";
    image.src = url;
  });
}

export function getImageSize({file, fileURL}) {
  // inputs : either the file or the url
  const imageURL = fileURL ? fileURL : URL.createObjectURL(file);
  const image = new Image();
  const size = {};
  return new Promise((resolve, reject) => {
    image.addEventListener("load", function () {
      size.width = this.naturalWidth;
      size.height = this.naturalHeight;
      resolve(size);
    });
    image.src = imageURL;
  });
}

export async function resizeImagePOT({file, fileURL, fileName, pot}) {
  // inputs: either the file or the url
  let width, height;
  if (pot) {
    width = 2 ** pot;
    height = 2 ** pot;
  } else {
    const size = await getImageSize({file, fileURL});
    const sizeMax = Math.max(size.width, size.height);
    const p = Math.trunc(Math.log2(sizeMax));
    width = 2 ** p;
    height = 2 ** p;
  }
  return await resizeImage({file, fileURL, fileName, width, height});
}

async function resizeImage({file, fileURL, fileName, width, height}) {
  try {
    const url = fileURL ? fileURL : URL.createObjectURL(file);
    return new Promise((resolve) => {
      Jimp.read(url, (err, img) => {
        if (err) throw err;
        img.resize(width, height);
        img.getBuffer(Jimp.AUTO, function (error, imageData) {
          const blob = new Blob([imageData]);
          const resizedFile = new File(
            [blob],
            `${fileName}-${width}x${height}`
          );
          resolve(resizedFile);
        });
      });
    });
  } catch (err) {
    console.log(err);
  }
}

export async function scaleImageUrl({srcUrl, srcZoom, dstZoom}) {
  const img = await loadImageAsync(srcUrl);
  const {width, height} = await getImageSize({fileURL: srcUrl});
  const scale = dstZoom / srcZoom;
  const canvas = document.createElement("canvas");
  canvas.height = height * scale;
  canvas.width = width * scale;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height, 0, 0, width * scale, height * scale);
  return canvas.toDataURL();
}

export function isImageFile(file) {
  if (!file) {
    return false;
  }
  const mimeType = file.type;

  const imageMimeTypes = ["image/jpeg", "image/png"];

  return imageMimeTypes.includes(mimeType);
}
