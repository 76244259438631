import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {useParams} from "react-router-dom";
import {nanoid} from "@reduxjs/toolkit";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";

import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";

import {
  // addMeasurements,
  setCopiedMeasurements,
} from "Features/measurements/measurementsSlice";
// import {updateModel} from "Features/viewer3D/viewer3DSlice";
import SelectorAutocomplete from "Components/SelectorAutocomplete";
import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import addCodeNameToMeasurements from "Features/annotatedPdf/utils/addCodeNameToMeasurements";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import SelectorElementType from "Features/elementTypes/components/SelectorElementType";
import translateAndScalePath from "../utils/translateAndScalePath";

export default function DialogPasteMeasurements({
  open,
  onClose,
  copiedMeasurements,
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  console.log("copied Measurements from paste", copiedMeasurements);

  // strings

  const title = "Coller les éléments";
  const heightD = "Nouvelle hauteur";
  const heightS = "H (m)";
  const offsetZS = "Offset Z (m)";
  const slopeHD = "Nouvelle hauteur (pente)";
  const slopeHS = "S (m)";
  const markerS = "Point de repère";
  const zonesS = "Fond de plan";
  const sectorsS = "Nouveau secteur";
  const newRoomsS = "Nouvelles zones";
  const typesS = "Nouveau type";
  const deleteRessourcesS = "Supprimer les ressources";
  const cancelS = "Annuler";
  const saveS = "Coller";
  const scaleS = "Rapport d'échelle";

  // data

  // const {modelId} = useParams();
  const markers = useMarkersByScene(scene?.clientId);
  const zones = useZonesByScene(scene);
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);
  const types = useElementTypesBySceneProxy(scene).filter((t) => !t.isTitle);
  const measurementsModel = useSelectedMeasurementsModel();
  const pageNumber = useSelector((s) => s.viewer3D.pdfCurrentPage);

  // state

  const [height, setHeight] = useState(null);
  const [offsetZ, setOffsetZ] = useState(null);
  const [slopeH, setSlopeH] = useState(null);
  const [marker, setMarker] = useState({name: ""});
  const [zone, setZone] = useState({name: ""});
  const [sector, setSector] = useState({name: ""});
  const [newRooms, setNewRooms] = useState("");
  const [type, setType] = useState(null);
  const [deleteRessources, setDeleteRessources] = useState(false);
  const [scale, setScale] = useState(null);
  // const [numsMap, setNumsMap] = useState({});

  // helpers

  const markersOptions = markers.map((m) => {
    return {name: m.description};
  });

  const zonesOptions = zones.filter(
    (z) =>
      z.pdfModelId === measurementsModel?.fromModel?.modelId &&
      z.pageNumber === pageNumber
  );

  // handlers

  function handleCancel() {
    onClose();
  }

  function handleHeightChange(e) {
    setHeight(e.target.value);
  }

  function handleScaleChange(e) {
    setScale(e.target.value);
  }

  function handleOffsetZChange(e) {
    setOffsetZ(e.target.value);
  }

  function handleSlopeHChange(e) {
    setSlopeH(e.target.value);
  }

  function handleMarkerChange(m) {
    setMarker(m);
  }

  function handleZoneChange(z) {
    setZone(z);
  }

  function handleSectorChange(s) {
    setSector(s);
  }

  function handleNewRoomsChange(e) {
    setNewRooms(e.target.value);
  }

  function handleTypeChange(t) {
    setType(t);
  }

  function handleDeleteRessource() {
    setDeleteRessources(!deleteRessources);
  }

  function handleSave() {
    // new measurements
    const numsMap = {};
    if (newRooms !== "") {
      newRooms.split(/\r?\n|\r|\n/g).map((n) => {
        if (n !== "") {
          const kv = n.split("\t");
          numsMap[kv[0]] = kv[1];
        }
      });
    }
    const newMarker = markers.find(
      (m) => m.description === marker?.name && m.description?.length > 0
    );
    console.log("newMarker", newMarker);

    let newMeasurements = copiedMeasurements.measurements.map((m) => {
      // offsetZ
      let zInf = m.zInf;
      let zSup = m.zSup;

      if (offsetZ?.length > 0) {
        zInf = zInf + Number(offsetZ);
        zSup = zSup + Number(offsetZ);
      }

      // drawingProps
      let newDrawingProps;
      if (zone.id) {
        newDrawingProps = {
          ...m.drawingProps,
          pageNumber: zone.pageNumber,
        };
      }
      if (newMarker) {
        let newPath;
        if (copiedMeasurements.marker) {
          newPath = [];
          const scaleF = scale ? parseFloat(scale) : 1;
          const dx = newMarker.px - copiedMeasurements.marker.px * scaleF;
          const dy = newMarker.py - copiedMeasurements.marker.py * scaleF;

          const pointRefOld = [
            copiedMeasurements.marker.px,
            copiedMeasurements.marker.py,
          ];
          const pointRefNew = [newMarker.px, newMarker.py];

          // m.drawingProps.path.forEach((p) => {
          //   newPath.push([p[0] + dx, p[1] + dy]);
          // });
          newPath = translateAndScalePath(
            m.drawingProps.path,
            pointRefOld,
            pointRefNew,
            scaleF
          );
        }
        newDrawingProps = {
          ...m.drawingProps,
          pageNumber: newMarker.pn,
          path: newPath ? newPath : m.drawingProps.path,
        };
      }
      const oldRoom = rooms.find((r) => r.id === m.roomId);
      let newRoom;
      if (oldRoom && numsMap[oldRoom.num]) {
        newRoom = rooms.find((r) => r.num === numsMap[oldRoom.num]);
      }
      const newMeasurement = {
        ...m,
        zInf,
        zSup,
        voids: [],
        res: deleteRessources ? [] : m.res,
        height: height ? parseFloat(height) : m.height,
        roomId: newRoom ? newRoom.id : m.roomId,
        drawingProps: newDrawingProps ? newDrawingProps : m.drawingProps,
      };
      if (slopeH && m.drawingShape === "SLOPE")
        newMeasurement.slopeH = parseFloat(slopeH);
      if (zone.id) {
        newMeasurement.zoneId = zone.id;
        // newMeasurement.zInf = null;
        // newMeasurement.zSup = null;
      }
      if (sector?.id) newMeasurement.sectorId = sector?.id;
      if (type?.id) {
        newMeasurement.elementTypeId = type.id;
        newMeasurement.color = type.color;
        if (type.material) {
          newMeasurement.materialId = type.material.id;
          newMeasurement.color = type.material.color;
        }
      }

      return newMeasurement;
    });

    // add codeName

    newMeasurements = addCodeNameToMeasurements(newMeasurements);

    const newMs = newMeasurements?.map((m) => {
      return {
        ...m,
        measurementsModelId: measurementsModel.id,
        pdfModelId: measurementsModel.fromModel.modelId,
      };
    });
    // dispatch(addMeasurements(newMs));
    console.log("Paste new measurements", newMs);
    caplaEditor?.measDataManager.addMeasurements(newMs);
    // caplaEditor?.measDataManager.createOrUpdateModelInManager({
    //   ...measurementsModel,
    //   measurementsData: {
    //     ...measurementsModel.measurementsData,
    //     measurements: newMeasurements
    //   }
    // });

    const newCopied = copiedMeasurements.measurements.map((m) => {
      return {...m, id: nanoid()};
    });

    dispatch(
      setCopiedMeasurements({...copiedMeasurements, measurements: newCopied})
    );
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="text.secondary" sx={{mr: 2}}>
            {scaleS}
          </Typography>
          <Box sx={{width: 150}}>
            <TextField
              value={scale}
              fullWidth
              onChange={handleScaleChange}
              label={<Typography variant="body2">{scaleS}</Typography>}
              size="small"
              InputProps={{
                sx: {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="text.secondary" sx={{mr: 2}}>
            {offsetZS}
          </Typography>
          <Box sx={{width: 100}}>
            <TextField
              value={offsetZ}
              fullWidth
              onChange={handleOffsetZChange}
              label={<Typography variant="body2">{offsetZS}</Typography>}
              size="small"
              InputProps={{
                sx: {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="text.secondary" sx={{mr: 2}}>
            {heightD}
          </Typography>
          <Box sx={{width: 100}}>
            <TextField
              value={height}
              fullWidth
              onChange={handleHeightChange}
              label={<Typography variant="body2">{heightS}</Typography>}
              size="small"
              InputProps={{
                sx: {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="text.secondary" sx={{mr: 2}}>
            {slopeHD}
          </Typography>
          <Box sx={{width: 100}}>
            <TextField
              value={slopeH}
              fullWidth
              onChange={handleSlopeHChange}
              label={<Typography variant="body2">{slopeHS}</Typography>}
              size="small"
              InputProps={{
                sx: {
                  fontSize: (theme) => theme.typography.body2.fontSize,
                },
              }}
            />
          </Box>
        </Box>
        <Box sx={{bgcolor: "background.default", p: 1, borderRadius: "4px"}}>
          <SelectorAutocomplete
            options={markersOptions}
            value={marker}
            onChange={handleMarkerChange}
            name={markerS}
          />
        </Box>
        <Box sx={{bgcolor: "background.default", p: 1, borderRadius: "4px"}}>
          <SelectorAutocomplete
            options={zonesOptions}
            value={zone}
            onChange={handleZoneChange}
            name={zonesS}
          />
        </Box>
        {/* {copiedMeasurements.modelId === modelId && ( */}
        <Box sx={{bgcolor: "background.default", p: 1, borderRadius: "4px"}}>
          <SelectorAutocomplete
            options={sectors}
            value={sector}
            onChange={handleSectorChange}
            name={sectorsS}
          />
        </Box>
        {/* )} */}
        {/* {copiedMeasurements.modelId === modelId && ( */}
        <Box sx={{p: 2, display: "flex", alignItems: "center"}}>
          <Typography variant="body2" color="text.secondary" sx={{mr: 2}}>
            {newRoomsS}
          </Typography>
          <Box>
            <TextField
              multiline
              value={newRooms}
              onChange={handleNewRoomsChange}
            />
          </Box>
        </Box>
        {/* )} */}
        <Box sx={{bgcolor: "background.default", p: 1, borderRadius: "4px"}}>
          <SelectorElementType
            elementType={type}
            elementTypes={types}
            onChange={handleTypeChange}
            label={typesS}
          />
          {/* <SelectorAutocomplete
            options={types}
            value={type}
            onChange={handleTypeChange}
            name={typesS}
          /> */}
        </Box>
        {/* {copiedMeasurements.modelId !== modelId && ( */}
        <Box sx={{pl: 2}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={deleteRessources}
                onChange={handleDeleteRessource}
                size="small"
              />
            }
            label={
              <Typography color="inherit" variant="body2">
                {deleteRessourcesS}
              </Typography>
            }
          />
        </Box>
        {/* )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleSave} variant="contained">
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
