import {useSelector} from "react-redux";

import useFilteredSceneMeasurements from "./useFilteredSceneMeasurements";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import computeRessourceQuantity, {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function useMeasurementsByRessourceBySectorMap(scene, mode) {
  // data

  let [measurements] = useFilteredSceneMeasurements(scene, mode);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const zones = useZonesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);

  if (!measurements) return {};

  const dontUseForQuantities = new Set(models
    .filter((m) => m.excludeFromQties)
    .map((m) => m.id)
  );
  if (dontUseForQuantities.size > 0)
    measurements = measurements.filter(
      (m) => !dontUseForQuantities.has(m.measurementsModelId)
    );

  // helpers

  const zonesById = {};
  for (const item of zones) {
    zonesById[item.id] = item;
  }
  const roomsById = {};
  for (const item of rooms) {
    roomsById[item.id] = item;
  }
  const sectorsById = {};
  for (const item of sectors) {
    sectorsById[item.id] = item;
  }
  const ressourcesById = {};
  for (const item of ressources) {
    ressourcesById[item.id] = item;
  }
  const elementTypesById = {};
  for (const item of elementTypes) {
    elementTypesById[item.id] = item;
  }
  
  const mMap = {};
  measurements.forEach((measurement) => {
    const res = measurement.res;
    if (!res) return;
    const elementType = elementTypesById[measurement.elementTypeId];
    let sectorId = measurement.sectorId;
    if (sectorId === "undefined" || sectorId === "null" || !sectorId)
      sectorId = "null";
    res
      .filter((r) => !r.off)
      .forEach((mr) => {
        const resId = mr.resId;
        const ressource = ressourcesById[resId];
        if (!ressource) return;
        const res = elementType?.res?.find((r) => resId === r.resId);
        const useMeas = applyFormulaFilter(
          measurement,
          res?.func,
          zonesById[measurement.zoneId],
          roomsById[measurement.roomId],
          sectorsById[measurement.sectorId],
          filterNoVoids
        );
        if (useMeas) {
          // let mQ = filterNoVoids ? mr.qtyRaw : mr.qtyNet;
          let mQ;
          if (!mQ) {
            mQ = computeRessourceQuantity({
              item: measurement,
              res,
              ressource,
              withDecimal: false,
              filterNoVoids,
            });
          }
          let mapItemByRes = mMap[resId];
          let mapItemBySector;
          if (mapItemByRes) mapItemBySector = mMap[resId][sectorId];

          if (!mapItemByRes) {
            mMap[resId] = {[sectorId]: mQ};
          } else if (!mapItemBySector) {
            mMap[resId][sectorId] = mQ;
          } else {
            mMap[resId][sectorId] += mQ;
          }
        }
      });
  });

  return mMap;
}
