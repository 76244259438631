import React from "react";
import {Category} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
export default function NoModelSection() {
  const {t} = useTranslation("viewer3D");
  const callToActionString = t("models.noModelAction");
  return (
    <Box
      sx={{
        fontSize: 240,
        width: 1,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "GrayText",
        flexDirection: "column",
      }}
    >
      <Category fontSize="inherit" color="inherit" />
      <Typography>{callToActionString}</Typography>
    </Box>
  );
}
