import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsElementTypes from "Features/elementTypes/components/SectionActionsElementTypes";

export default function SectionLeftPanelActionsElementTypes({
  scene,
  caplaEditor,
}) {
  // data

  const sceneModule = useSceneModule();

  // actions - main

  const actionsMainKeys = [
    "createGroup",
    "createType",
    "exportExcelTypes",
    "importExcelTypes",
  ];
  const actionsMoreKeys = [
    "renameGroup",
    "deleteGroup",
    //
    "divider",
    "importTypesFromPdf",
    //"cleanGroups",
    "divider",
    "exportExcelRelations",
    "importExcelRelations",
    "divider",
    "addRelations",
    //  "cleanRelations",
    "divider",
    "copyToProxies",
    "copyToRessources",
    "divider",
    "cleanElementTypesGroup",
  ];

  return (
    <Box>
      {sceneModule === "ELEMENT_TYPES" && (
        <SectionActionsElementTypes
          scene={scene}
          caplaEditor={caplaEditor}
          actionsMainKeys={actionsMainKeys}
          actionsMoreKeys={actionsMoreKeys}
        />
      )}
    </Box>
  );
}
