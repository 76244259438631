import React from "react";

import {useSelector, useDispatch} from "react-redux";

import {setShortcutMobileZoneId} from "Features/measurements/measurementsSlice";

import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {Paper, Box, Typography, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function CardShortcutSelectionZone({scene}) {
  const dispatch = useDispatch();

  // data
  const zones = useZonesByScene(scene);
  const zoneId = useSelector((s) => s.measurements.shortcutMobileZoneId);
  const zone = zones.find((z) => z.id === zoneId);

  // handlers

  function handleClose() {
    dispatch(setShortcutMobileZoneId(null));
  }
  return (
    <Box sx={{p: 0.5}}>
      <Paper elevation={12} sx={{display: "flex", alignItems: "center", p: 1}}>
        <Typography variant="body2" sx={{mr: 1}}>
          {zone?.name}
        </Typography>
        <IconButton onClick={handleClose} size="small">
          <Close fontSize="small" />
        </IconButton>
      </Paper>
    </Box>
  );
}
