import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function FieldZoneSector({zone, scene, onZoneChange}) {
  // strings

  const sectorS = "Sector";

  // data

  const sectors = useSectorsByScene(scene, {sortByZ: true});

  // template

  const template = {
    key: "kvtZoneSector",
    fields: [
      {
        key: "sector",
        name: sectorS,
        type: "combo",
        options: sectors,
        canCreate: false,
      },
    ],
  };

  // helpers - item

  const item = {sector: sectors.find((s) => s.id === zone.sectorId)};

  // handlers

  function handleSectorChange({sector}) {
    // const annotationId = zone.id;
    // const props = {sectorId: sector?.id};
    // editorPdf.annotationsManager.updateAnnotationEntityProps({
    //   annotationId,
    //   props,
    // });
    onZoneChange({...zone, sectorId: sector?.id});
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleSectorChange}
    />
  );
}
