import {
  createRemoteModel,
  updateRemoteModel,
} from "Features/viewer3D/viewer3DSlice";

export default class Syncer {
  constructor({dispatch}) {
    this.dispatch = dispatch;
  }
  // constructor({editor}) {
  //   this.editor = editor;
  //   this.dispatch = editor.dispatch;
  // }

  getUploadOps({models}) {
    const ops = models.map((m) => m.nextSync).filter((s) => s.action);
    console.log(ops);
  }

  async syncUpModel({model, sceneId, accessToken}) {
    if (!model.version) {
      await this.dispatch(
        createRemoteModel({modelId: model.id, sceneId, accessToken})
      );
    } else {
      await this.dispatch(
        updateRemoteModel({modelId: model.id, sceneId, accessToken})
      );
    }
  }
}
