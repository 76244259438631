import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  fetchProgressRecordsService,
  createProgressRecordService,
  updateProgressRecordService,
  deleteProgressRecordService,
  fetchProgressRecordsBySceneService,
} from "./services";

export const createProgressRecord = createAsyncThunk(
  "progress/createProgressRecord",
  createProgressRecordService
);
export const updateProgressRecord = createAsyncThunk(
  "progress/updateProgressRecord",
  updateProgressRecordService
);
export const deleteProgressRecord = createAsyncThunk(
  "progress/deleteProgressRecord",
  deleteProgressRecordService
);

export const fetchProgressRecords = createAsyncThunk(
  "progress/fetchProgressRecords",
  fetchProgressRecordsService
);
export const fetchProgressRecordsByScene = createAsyncThunk(
  "progress/fetchProgressRecordsByScene",
  fetchProgressRecordsBySceneService
);

export const progressSlice = createSlice({
  name: "progress",
  initialState: {
    items: [],
    measurementsProgress: [], // {measurementId,progressListingId,progressRecordId,progress}
    moduleViewMode: "DEFAULT",
    statusByScene: {}, // {sceneId:"loaded"}, used by useProgressRecordsByScene to trigger fetch issuesByScene

    selectedProgressListingId: null, // recordset id
    selectedProgressRecordId: null,

    progressTabsIndex: 0,

    view3DMode: "DONE",

    isEditingProgressListing: false,
    editedProgressListing: null,
  },
  reducers: {
    setSceneRecordsLoadingStatus: (state, action) => {
      const {sceneId, status} = action.payload;
      state.statusByScene[sceneId] = status;
    },
    setModuleViewMode: (state, action) => {
      state.moduleViewMode = action.payload;
    },
    setSelectedProgressListingId: (state, action) => {
      state.selectedProgressListingId = action.payload;
    },
    setSelectedProgressRecordId: (state, action) => {
      state.selectedProgressRecordId = action.payload;
    },
    setProgressTabsIndex: (state, action) => {
      state.progressTabsIndex = action.payload;
    },
    addMeasurementProgress: (state, action) => {
      const {measurementId, progressListingId, progressRecordId, progress} =
        action.payload;
      const mps = state.measurementsProgress.filter(
        (mp) =>
          mp.measurementId !== measurementId ||
          mp.progressRecordId !== progressRecordId
      );
      state.measurementsProgress = [
        ...mps,
        {measurementId, progressRecordId, progressListingId, progress},
      ];
    },
    setView3DMode: (state, action) => {
      state.view3DMode = action.payload;
    },
    // edition
    setIsEditingProgressListing: (state, action) => {
      state.isEditingProgressListing = action.payload;
    },
    setEditedProgressListing: (state, action) => {
      state.editedProgressListing = action.payload;
    },
  },
  extraReducers: {
    [createProgressRecord.fulfilled]: (state, action) => {
      const newProgressRecord = action.payload;
      state.items.push(newProgressRecord);
    },
    [updateProgressRecord.fulfilled]: (state, action) => {
      const progressRecord = action.payload;
      state.items = state.items.filter((i) => i.id !== progressRecord.id);
      state.items.push(progressRecord);
    },
    [deleteProgressRecord.fulfilled]: (state, action) => {
      const {progressRecordId} = action.payload;
      state.items = state.items.filter((i) => i.id !== progressRecordId);
    },
    [fetchProgressRecordsByScene.fulfilled]: (state, action) => {
      const {items, sceneId} = action.payload;
      const itemsIds = items.map((item) => item.id);
      let newItems = state.items.filter((item) => !itemsIds.includes(item.id));
      state.items = [...newItems, ...items];
      state.statusByScene[sceneId] = "loaded";
    },
  },
});

export const {
  setModuleViewMode,
  setSelectedProgressListingId,
  setSelectedProgressRecordId,
  setProgressTabsIndex,
  addMeasurementProgress,
  setView3DMode,
  setIsEditingProgressListing,
  setEditedProgressListing,
  setSceneRecordsLoadingStatus,
} = progressSlice.actions;

export default progressSlice.reducer;
