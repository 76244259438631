export default function getCleanRooms(rooms) {
  const codes = rooms.map((r) => r.code);

  const uniqueCodes = [...new Set(codes)];
  const uniqueRooms = uniqueCodes.map((code) =>
    rooms.find((r) => r.code === code)
  );

  return uniqueRooms;
}
