import React, {useState} from "react";

import {
  Box,
  Select,
  FormControl,
  MenuItem,
  Typography,
  InputBase,
  Menu,
  ListItemButton,
  IconButton,
} from "@mui/material";
import {styled} from "@mui/material/styles";

import {ArrowDropDown as Down} from "@mui/icons-material";

export default function SelectorModelInToolbar({models = [], model, onChange}) {
  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers - menu

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }
  // handlers - model item

  function handleListingClick(model) {
    onChange(model);
    setAnchorEl(null);
  }

  return (
    <>
      <Box
        sx={{
          width: 150,
          display: "flex",
          justifyContent: "space-between",
          color: "common.white",
          alignItems: "center",
        }}
      >
        <Typography noWrap sx={{fontSize: 13}} color="inherit">
          {model?.name}
        </Typography>
        <IconButton size="small" onClick={handleMoreClick} color="inherit">
          <Down color="inherit" fontSize="small" />
        </IconButton>
      </Box>

      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {models.map((model) => (
          <MenuItem key={model.id} onClick={() => handleListingClick(model)}>
            <Typography variant="body2">{model.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
