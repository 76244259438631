import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";

import {createSurvey} from "../surveysSlice";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function DialogCreateSurvey({open, onClose, scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const sceneId = scene?.id;

  // strings

  const title = "Nouvelle visite";

  const createS = "Créer";

  // data

  const scopeId = useSelector((s) => s.scopes.selectedScopeId);

  // state

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  // handlers

  function handleClose() {
    onClose();
  }

  async function handleCreate() {
    const survey = {name, sceneId};
    //
    if (loading) return;
    setLoading(true);
    //
    await dispatch(createSurvey({accessToken, survey, fromScopeId: scopeId}));
    setLoading(false);
    const message = `Nouvelle viste créée`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
    handleClose();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField value={name} onChange={(e) => setName(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate}>{createS}</Button>
      </DialogActions>
    </Dialog>
  );
}
