// ex1 :
// input = "BET=V; COF=2*S"
// output : [{articleCode : "BET", func: V}, {articleCode : "COF", func: 2*S}]

export default function parseElementTypeArticleQties(articleQties) {
  try {
    return articleQties.split("--").map((articleQty) => {
      let [articleCode, func] = articleQty.split("=");
      articleCode = articleCode.trim();
      func = func.trim();
      return {articleCode, func};
    });
  } catch (e) {
    console.log("error in parseElementTypeArticleQties", e);
    return [];
  }
}
