import React from "react";

import {useSelector} from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";

export default function DialogLocateZone({
  scene,
  open,
  onClose,
  onLocate,
  selection,
  onSelectionChange,
  useMarkerHeight,
  onUseMarkerHeightChange,
}) {
  // strings

  const titleString = "Locate the zone with markers";
  const descriptionString =
    "Use markers from the 3D scene to locate the pdf zone.";

  const usedMarkers = "Used markers";

  const useMarkerHeightString = "Use markers height in the computation";

  const locateString = "Locate the zone";

  // data

  const markers = useMarkersByScene(scene?.clientId);
  const inPdfMarkersIds = selection.map((s) => s.markerId);
  const inMarkers = markers.filter((m) => inPdfMarkersIds.includes(m.id));

  // handlers

  function handleCheckboxChange(e, pdfMarker) {
    const checked = e.target.checked;
    const pdfMarkers = selection.filter(
      (m) =>
        m.modelId !== pdfMarker.modelId ||
        m.pageNumber !== pdfMarker.pageNumber ||
        m.markerId !== pdfMarker.markerId ||
        m.num !== pdfMarker.num
    );
    const newSelection = [...pdfMarkers, {...pdfMarker, checked}];
    onSelectionChange(newSelection.sort((a, b) => a.num - b.num));
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{titleString}</DialogTitle>
      <DialogContent>
        <Typography>{descriptionString}</Typography>

        <Box sx={{bgcolor: "background.default", p: 1, mt: 2}}>
          <Typography>
            <b>{usedMarkers}</b>
          </Typography>

          <Box sx={{display: "flex", flexDirection: "column"}}>
            {selection.map((pdfMarker) => {
              const marker = markers.find((m) => m.id === pdfMarker.markerId);
              const description = marker?.description;
              const {markerId, modelId, pageNumber, checked, num} = pdfMarker;
              const key = markerId + "-" + modelId + "-" + pageNumber;

              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={checked}
                      size="small"
                      onChange={(e) => handleCheckboxChange(e, pdfMarker)}
                    />
                  }
                  label={<Typography variant="body2">{description}</Typography>}
                />
              );
            })}
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={useMarkerHeight}
              size="small"
              onChange={(e) => onUseMarkerHeightChange(e.target.checked)}
            />
          }
          label={
            <Typography variant="body2">{useMarkerHeightString}</Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onLocate}>
          {locateString}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
