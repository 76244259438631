/*
 * room : {id,num,code,name}
 */
import {nanoid} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneRooms(scene) {
  // anonym rooms = rooms identified with their code only.

  const dispatch = useDispatch();

  const updateSceneRoom = (rooms) => {
    if (!scene) return;
    const roomsIds = rooms.map((r) => r.id);
    const sceneRoomsPrev = scene?.data?.rooms
      ? scene.data.rooms.filter((r) => r.id)
      : [];

    // unchanged
    let otherRooms = sceneRoomsPrev.filter((r) => !roomsIds.includes(r.id));

    // updated
    let updatedPrevRooms = sceneRoomsPrev.filter((r) =>
      roomsIds.includes(r.id)
    );
    let updatedNextRooms = [];
    if (updatedPrevRooms) {
      updatedNextRooms = updatedPrevRooms.map((prevRoom) => {
        const newRoom = rooms.find((r) => r.id === prevRoom.id);
        return {...prevRoom, ...newRoom};
      });
    }

    // news
    let prevIds = sceneRoomsPrev.map((r) => r.id);
    let newRooms = rooms.filter((r) => !prevIds.includes(r.id) || !r.id);
    newRooms = newRooms.map((r) => ({...r, id: nanoid()}));

    // sceneRooms

    const sceneRooms = [
      ...otherRooms,
      ...updatedNextRooms.filter((r) => !r.toDelete),
      ...newRooms,
    ];

    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        rooms: sceneRooms,
      },
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneRoom;
}
