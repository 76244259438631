import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import SectionSelectorDrawingShape from "./SectionSelectorDrawingShape";
import SectionStepTitle from "./SectionStepTitle";
import SectionName from "./SectionName";

export default function SectionMain() {
  //data

  const step = useSelector((s) => s.elementTypor.step);

  // render

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", height: 1, minWidth: 1}}
    >
      <SectionStepTitle />
      {step === "name" && <SectionName />}
      {step === "drawingShape" && <SectionSelectorDrawingShape />}
    </Box>
  );
}
