import articleTypesMapToArray from "./articleTypesMapToArray";
import articleTypesArrayToMap from "./articleTypesArrayToMap";

export default function replaceOneItemInArticleTypes(item, articleTypes) {
  const typesArray = articleTypesMapToArray(articleTypes);
  const newTypesArray = typesArray.map((type) => {
    if (type.groupId === item.groupId && type.typeId === item.typeId) {
      return item;
    }
    return type;
  });
  return articleTypesArrayToMap(newTypesArray);
}
