import React from "react";

import {Card, CardContent, Typography, alpha, Box} from "@mui/material";
import {FlashOn as Flash, ContentCut as Cut} from "@mui/icons-material";
import useTranslation from "Features/translations/useTranslation";
export default function CardConfigCenterDescription() {
  const {t} = useTranslation("viewer3D");

  // strings

  const title = t("viewer3D:configCenter.help.title");
  const description = t("viewer3D:configCenter.help.description");
  const flash = t("viewer3D:configCenter.help.flash");
  const cut = t("viewer3D:configCenter.help.cut");

  return (
    <Card sx={{background: (theme) => alpha(theme.palette.common.yellow, 1)}}>
      <CardContent>
        <Typography variant="body2" sx={{mb: 2}}>
          <b>{title}</b>
        </Typography>
        <Typography variant="body2" sx={{mb: 1}}>
          {description}
        </Typography>
        <Box sx={{display: "flex", mb: 1}}>
          <Flash fontSize="small" color="action" sx={{m: 0.5}} />
          <Typography variant="body2">{`${flash}`}</Typography>
        </Box>
        <Box sx={{display: "flex"}}>
          <Cut fontSize="small" color="action" sx={{m: 0.5}} />
          <Typography variant="body2">{cut}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
