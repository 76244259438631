import React, {useState} from "react";

import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

import {IconButton, Box} from "@mui/material";
import {People} from "@mui/icons-material";

import DialogEditMemberships from "Features/viewer3D/components/DialogEditMemberships";
export default function IconButtonMemberships({scene}) {
  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Box
        sx={{
          width: 24,
          height: 24,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          boxSizing: "content-box",
          borderRadius: "50%",
          bgcolor: lighten(theme.palette.common.caplaBlack, 0.2),
          color: lighten(theme.palette.common.caplaBlack, 0.5),
          border: (theme) =>
            `1px solid ${lighten(theme.palette.common.caplaBlack, 0.5)}`,
        }}
      >
        <IconButton onClick={handleOpen} size="small" color="inherit">
          <People fontSize="small" />
        </IconButton>
      </Box>
      <DialogEditMemberships open={open} onClose={handleClose} scene={scene} />
    </>
  );
}
