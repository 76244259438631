import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {addSceneMembershipService} from "Features/scenes/services";

import {Box, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function InputAddMembership({scene, onAdd}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const memberString = "User email";
  const addString = "Add";

  // state

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleAddClick() {
    if (accessToken) {
      const membership = {email, role: "viewer"};
      const sceneId = scene.id;
      setLoading(true);
      await addSceneMembershipService({accessToken, sceneId, membership});
      setLoading(false);
      onAdd();
      setEmail("");
    }
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <TextField
        sx={{mr: 2, width: 340}}
        label={<Typography variant="body2">{memberString}</Typography>}
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        size="small"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <LoadingButton
        loading={loading}
        startIcon={<Add />}
        loadingPosition="start"
        onClick={handleAddClick}
      >
        {addString}
      </LoadingButton>
    </Box>
  );
}
