import { useSelector } from "react-redux";

import useElementTypesBySceneProxy from "./useElementTypesBySceneProxy";

// import useSceneMeasurements from "Features/measurements/useSceneMeasurements";
import useSelectedMeasurementsModelMeasurements from "Features/measurements/useSelectedMeasurementsModelMeasurements";
import useFilteredMeasurementsFromPackages from "Features/measurements/useFilteredMeasurementsFromPackages";

export default function useElementTypesSceneQuantities(scene, mode) {
  const isEditMode = mode === "ONE_MODEL";

  // const allMeasurements = useSceneMeasurements(scene);

  // const measurementsModelsInScope = useSelector(
  //   (s) => s.measurements.measurementsModelsInScope
  // );

  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const selectedModelMeasurements = useSelectedMeasurementsModelMeasurements();
  const filteredMeasurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });

  const measurements = isEditMode
    ? selectedModelMeasurements
    : filteredMeasurements;
  // : allMeasurements.filter((m) =>
  //     measurementsModelsInScope.includes(m.measurementsModelId)
  //   );

  const elementTypes = useElementTypesBySceneProxy(scene);

  const q = {};

  for (let m of measurements) {
    let qItem = q[m.elementTypeId];
    const length = !filterNoVoids && m.lengthNet ? m.lengthNet : m.length;
    const area = !filterNoVoids && m.areaNet ? m.areaNet : m.area;
    const volume = !filterNoVoids && m.volumeNet ? m.volumeNet : m.volume;
    if (!qItem) {
      q[m.elementTypeId] = {
        count: 1,
        length: length,
        area: area,
        volume: volume,
      };
    } else {
      q[m.elementTypeId] = {
        count: 1 + qItem.count,
        length: length + qItem.length,
        area: area + qItem.area,
        volume: volume + qItem.volume,
      };
    }
  }

  // // voids

  // for (let m of measurements) {
  //   if (m.isVoid && Array.isArray(m.hostedTypes)) {
  //     m.hostedTypes.forEach((typeId) => {
  //       const qItem = q[typeId];
  //       if (qItem) {
  //         q[typeId] = {
  //           count: qItem.count,
  //           length: qItem.length,
  //           area: qItem.area - m.area,
  //           volume: qItem.volume - m.volume,
  //         };
  //       }
  //     });
  //   }
  // }

  const r = {};

  Object.entries(q).forEach(([key, value]) => {
    const elementType = elementTypes.find((t) => t.id === key);
    let mainQ = value.count;
    if (elementType?.unit === "ML") mainQ = value.length;
    if (elementType?.unit === "M2") mainQ = value.area;
    if (elementType?.unit === "M3") mainQ = value.volume;
    r[key] = { ...value, mainQuantity: mainQ };
  });

  return r;
}
