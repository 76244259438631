import {useEffect} from "react";
import {Outlet, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {Box} from "@mui/material";

import BoxResizable from "Components/BoxResizable";

import {
  // setIsLoaded,
  // setScenesDialog,
  setViewerWidth,
  setFixedViewersBoxWidth,
  setIsViewerFullScreen,
  setCanUpdateModels,
  updateScenesStatus,
  fetchStoredModel,
  fetchStoredModels,
  setModelsLoaderOptions,
} from "Features/viewer3D/viewer3DSlice";
import {
  // setOpenSelectionPanel,
  setSelectionPanelWidth,
} from "Features/selection/selectionSlice";
import {
  setNavPanelSceneModulesWidth,
  setOpen,
  // setLeftPanelWidth,
  setOpenLeftPanel,
} from "Features/navigation/navigationSlice";
import {setSelectedFixedViewer, setOpenSections} from "Features/ui/uiSlice";
import {setFilteredMeasurementsViewMode} from "Features/measurements/measurementsSlice";
// import useSceneIsLoaded from "Features/scenes/useSceneIsLoaded";
// import useScenes from "Features/scenes/useScenes";
import useScene from "Features/scenes/useScene";
import useSectionDims from "Features/ui/useSectionDims";
// import useSelectedScene from "Features/scenes/useSelectedScene";
// import useSceneModule from "Features/navigation/useSceneModule";
import useColoringScene from "Features/colorings/useColoringScene";
import useMeasurementsModelsInitialScope from "Features/measurements/useMeasurementsModelsInitialScope";
import usePageType from "Features/navigation/usePageType";
// import useIsFullscreen from "Features/ui/useIsFullscreen";
// import useIs3dEnabled from "Features/navigation/useIs3dEnabled";
import NavPanel from "Features/navigation/components/NavPanel";
import Stretch from "Features/ui/components/Stretch";
import Viewer3D from "Features/viewer3D/components/Viewer3D";
import PdfViewer from "Features/pdf/components/SectionPdfViewer";
import MapViewer from "Features/map/components/MapViewer";
import PageDownloadingScene from "Features/scenes/components/PageDownloadingScene";
import DialogBuildWithExcel from "Features/buildbox/components/DialogBuildWithExcel";
import DialogCreateElementTypeFast from "Features/elementTypes/components/DialogCreateElementTypeFast";
import NavPanelSceneModule from "Features/navigation/components/NavPanelSceneModules";
import SectionLeftPanel from "Features/leftPanel/components/SectionLeftPanel";
// import SectionLeftSelectionInViewer from "Features/leftPanel/components/SectionLeftSelectionInViewer";
import PageLandingSceneInViewer from "Features/scenes/components/PageLandingSceneInViewer";
import useSharedRemoteScene from "Features/scenes/useSharedRemoteScene";
import ViewersToggle from "Features/scenes/components/ViewersToggle";

import useUpdateDefaultVersionForMeasurements from "Features/versions/hooks/useUpdateDefaultVersionForMeasurements";
import useInitScopeSelection from "Features/overviewer/hooks/useInitScopeSelection";
import useInitAndUpdateRelations from "Features/relations/hooks/useInitAndUpdateRelations";

export default function AppDesktopViewer({caplaEditor}) {
  const dispatch = useDispatch();

  // params

  const params = useParams();

  // data

  const modelsLoaderOptions = useSelector(
    (s) => s.viewer3D.modelsLoaderOptions
  );
  const dims = useSectionDims();
  const scene = useScene(params.sceneId);
  const sharedRemoteScene = useSharedRemoteScene({
    shareLink: params.shareLink,
    caplaEditor,
  });
  const coloringScene = useColoringScene(params.coloringId);
  const showDataPreview = useSelector((s) => s.viewer3D.showDataPreview);
  const scenesStatus = useSelector((state) => state.viewer3D.scenesStatus);
  const sceneStatus = scenesStatus[scene?.clientId];
  const os = useSelector((s) => s.ui.openSections);
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);
  const space = false; // used to display space & multiscenes
  const remoteDataOnly = useSelector((s) => s.sync.remoteDataOnly);
  const pageType = usePageType();
  const leftPanelSection = useSelector((s) => s.leftPanel.leftPanelSection);

  // helpers • active scene

  const isHome = Object.keys(params).length === 0;

  const isShared = Boolean(params.shareLink);
  const isColoring = Boolean(params.coloringId);

  let activeScene = scene;
  if (isShared) activeScene = sharedRemoteScene;
  if (isColoring) activeScene = coloringScene;

  // helpers - pdf3d

  const isPdf3d = os.fixedViewersBox && os.viewer3D;

  // helpers - loading scene

  const isLoadingScene = !Boolean(activeScene?.id);

  // helpers - show landing page

  const showLandingPage = leftPanelSection === "SCENE";

  // effect - initial measurements scope

  useMeasurementsModelsInitialScope(activeScene);

  // effect - nav panel & left panel

  useEffect(() => {
    if (isShared) {
      dispatch(setOpen(false));

      const openSections = {
        outlet: false,
        fixedViewersBox: true,
        viewer3D: true,
        callToAction: false,
      };
      dispatch(setOpenSections(openSections));
      // caplaEditor.setOpenSections(openSections);
      dispatch(setOpenLeftPanel(true));
      dispatch(setNavPanelSceneModulesWidth(0));
      dispatch(setViewerWidth(0.65));
      dispatch(setSelectionPanelWidth(300));
      dispatch(setFilteredMeasurementsViewMode("IN_AND_OUT"));
      dispatch(
        setModelsLoaderOptions({
          ...modelsLoaderOptions,
          imagesOff: true,
        })
      );
    }
  }, [isShared]);

  // effects - can update models

  useEffect(() => {
    if (isShared) {
      dispatch(setCanUpdateModels(false));
    } else {
      dispatch(setCanUpdateModels(true));
    }
  }, [isShared]);

  // handlers

  function handleWidthChange(width) {
    dispatch(setViewerWidth(width));
    if (showDataPreview && os.outlet) dispatch(setFixedViewersBoxWidth(width));
  }

  // effects

  useEffect(() => {
    if (isHome) {
      dispatch(setIsViewerFullScreen(false));
    }
  }, [isHome]);

  /*
   * SCENE & MODELS PRE-LOADING
   */

  // preload models in scene

  async function fetchClientModels(sceneClientId) {
    const result = await dispatch(fetchStoredModels(sceneClientId, false));
    const {models} = unwrapResult(result);
    for (let model of models) {
      if (model.enabled) await dispatch(fetchStoredModel(model.id));
    }
    dispatch(updateScenesStatus({sceneClientId, status: "preloaded"})); // scene's models in state
  }

  useEffect(() => {
    if (
      !space &&
      !isShared &&
      sceneStatus === "idle" &&
      scene?.clientId &&
      (!remoteDataOnly || !scene?.id)
    ) {
      fetchClientModels(scene.clientId); // preload all the models in the store.
    }
  }, [scene?.clientId, sceneStatus, space, remoteDataOnly, isShared]);

  /*
   * COLORING PAGES
   */
  useEffect(() => {
    if (pageType === "NEW_COLORING") dispatch(setOpen(false));
    if (pageType === "COLORING") dispatch(setOpen(false));
  }, [pageType]);

  /*
   * WORKSITES & FIXED VIEWER
   */
  useEffect(() => {
    if (pageType === "WORKSITES") {
      dispatch(setSelectedFixedViewer("MAP"));
    } else {
      dispatch(setSelectedFixedViewer("PDF"));
    }
  }, [pageType]);

  // Auto update

  useUpdateDefaultVersionForMeasurements(activeScene);

  // super dangerous updates !!!

  useInitAndUpdateRelations(activeScene);

  return (
    <Stretch>
      <DialogBuildWithExcel scene={activeScene} />
      <DialogCreateElementTypeFast scene={scene} />

      {isLoadingScene ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 99,
          }}
        >
          <PageDownloadingScene />
        </Box>
      ) : (
        <Box
          sx={{
            height: 0,
            width: 0,
            display: "none",
          }}
        >
          <Outlet
            context={{
              caplaEditor: caplaEditor,
              scene: activeScene,
              viewer: isShared,
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
          display: "flex",
          boxSizing: "border-box",
          //border: (theme) => `8px solid ${theme.palette.primary.flash}`,
        }}
      >
        <NavPanelSceneModule
          scene={activeScene}
          viewer={isShared}
          caplaEditor={caplaEditor}
        />
        <NavPanel scene={activeScene} />

        <SectionLeftPanel
          caplaEditor={caplaEditor}
          scene={activeScene}
          viewer={isShared}
        />

        <Box
          sx={{
            flexGrow: 1,
            position: "relative",
            display: "flex",
            //flexDirection: "column",
            height: 1, // (for flexDirection = row)
          }}
        >
          {showLandingPage && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: 90,
              }}
            >
              <PageLandingSceneInViewer
                scene={activeScene}
                caplaEditor={caplaEditor}
              />
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
              width: 1,
              position: "relative",
              display: "flex",
              boxSizing: "border-box",
              minWidth: 0,
              minHeight: 0,
              boxSizing: "border-box",
            }}
          >
            {/* <Box
              sx={{position: "absolute", top: "16px", left: "16px", zIndex: 90}}
            >
              <SectionLeftSelectionInViewer
                caplaEditor={caplaEditor}
                scene={activeScene}
                viewer={isShared}
              />
            </Box> */}

            <BoxResizable
              width={dims.viewer3DWidth}
              onChange={handleWidthChange}
              isWidthInPercent={true}
              left={false}
              right={isPdf3d ? true : false}
              hide={!os.viewer3D}
              viewer={isShared}
            >
              <Viewer3D
                // onEditorCreated={handleEditorCreated}
                scene={activeScene}
                viewer={isShared}
                caplaEditor={caplaEditor}
              />
            </BoxResizable>

            <Box
              sx={{
                flexGrow: 1,
                position: "relative",
                borderLeft: "0px solid red",
                boxSizing: "border-box",
              }}
            >
              <ViewersToggle caplaEditor={caplaEditor} />
              {selectedFixedViewer === "MAP" && (
                <MapViewer scene={activeScene} caplaEditor={caplaEditor} />
              )}
              {selectedFixedViewer === "PDF" && (
                <PdfViewer
                  scene={activeScene}
                  viewer={isShared}
                  caplaEditor={caplaEditor}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Stretch>
  );
}
