// DEPRECATED NEARLY

import { useSelector } from "react-redux";
// import {useEffect} from "react";
// import {useDispatch, useSelector} from "react-redux";
// import {unwrapResult} from "@reduxjs/toolkit";

// import {fetchSceneListings} from "Features/listings/listingsSlice";
// import useAccessToken from "Features/auth/useAccessToken";

function useSceneListings(sceneId) {
  // const dispatch = useDispatch();

  // data

  // const scenesStatus = useSelector((state) => state.listings.scenesStatus);
  const listings = useSelector((state) => state.listings.data);

  // helper

  // const status = scenesStatus[sceneId];
  const sceneListings = listings.filter((l) =>
    l?.scenes.map((s) => s.id).includes(sceneId)
  );

  // // auth

  // const accessToken = useAccessToken();

  // // handler
  
  // useEffect(() => {
  //   async function getListings() {
  //     const result = await dispatch(fetchSceneListings({accessToken, sceneId}));
  //     const listings = unwrapResult(result);
  //     console.log("fetch listings 12", listings);
  //   }
  //   if (
  //     status === "idle" &&
  //     accessToken &&
  //     sceneId
  //   ) {
  //     getListings();
  //   }
  // }, [sceneId]);

  // result
  return sceneListings;
}

export default useSceneListings;
