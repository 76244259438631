import {useSelector, useDispatch} from "react-redux";

import {Box, Typography, IconButton} from "@mui/material";
import {ArrowBack as Prev, ArrowForward as Next} from "@mui/icons-material";
import {goToNext, goToPrev} from "../elementTyporSlice";

export default function SectionStepTitle() {
  const dispatch = useDispatch();

  const titleMap = {
    drawingShape: "Choisissez une forme 2D/3D",
    name: "Donnez un nom au type",
  };

  const step = useSelector((s) => s.elementTypor.step);

  const title = titleMap[step] ?? "-";

  // helper

  const hidePrev = step === "name";

  const hideNext = step === "drawingShape";

  // handlers

  function handleGoToNext() {
    dispatch(goToNext());
  }
  function handleGoToPrev() {
    dispatch(goToPrev());
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.default",
      }}
    >
      <Box
        sx={{display: "flex", alignItems: "center", justifyContent: "center"}}
      >
        <IconButton
          sx={{visibility: hidePrev ? "hidden" : "visible"}}
          onClick={handleGoToPrev}
        >
          <Prev />
        </IconButton>
        <Typography sx={{px: 3}} variant="h5">
          {title}
        </Typography>
        <IconButton
          sx={{visibility: hideNext ? "hidden" : "visible"}}
          onClick={handleGoToNext}
        >
          <Next />
        </IconButton>
      </Box>
    </Box>
  );
}
