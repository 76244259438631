import {useSelector, useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedProgressListing from "../useSelectedProgressListing";
import {
  setIsEditingProgressListing,
  setEditedProgressListing,
} from "../progressSlice";

import {updateListing} from "Features/listings/listingsSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsProgressListingInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.progress.isEditingProgressListing);
  const selectedProgressListing = useSelectedProgressListing(scene);
  const editedProgressListing = useSelector(
    (s) => s.progress.editedProgressListing
  );

  // helper - ressource

  // let progressListing = isEditing
  //   ? editedProgressListing
  //   : selectedProgressListing;

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingProgressListing(true));
    dispatch(setEditedProgressListing({...selectedProgressListing}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingProgressListing(false));
  }

  async function handleSaveClick() {
    const updatedData = {metaData: editedProgressListing.metaData};
    console.log("updating ListingLLL", editedProgressListing.id, updatedData);
    const result = await dispatch(
      updateListing({
        listingId: editedProgressListing.id,
        accessToken,
        updatedData,
      })
    );
    const listing = unwrapResult(result);
    console.log("updatedListingLLL", listing);
    // end
    dispatch(setIsEditingProgressListing(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* <Box sx={{flexGrow: 1}}>
        <Button variant="contained" color="primary" fullWidth>
          {deleteS}
        </Button>
      </Box> */}

      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
