import {useDispatch} from "react-redux";

import {Box, Button} from "@mui/material";
import {ToggleOn, ToggleOff} from "@mui/icons-material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function CardGroupedActions({models, editor3d}) {
  const dispatch = useDispatch();
  // strings

  // const loadAllString = "Load all";
  const showAllString = "Show all";
  const hideAllString = "Hide all";

  // data

  // const detailedModels = useSelector((state) => state.viewer3D.detailedModels);

  // handlers

  function handleShowAll() {
    models.forEach((model) => {
      const newModel = {...model, hidden: false};
      dispatch(updateModel({updatedModel: newModel, sync: false}));
      const entity = editor3d?.getEntity(model.id);
      if (entity) entity.model = newModel;
      entity?.show();
    });
  }

  function handleHideAll() {
    models.forEach((model) => {
      const newModel = {...model, hidden: true};
      dispatch(updateModel({updatedModel: newModel, sync: false}));
      const entity = editor3d?.getEntity(model.id);
      if (entity) entity.model = newModel;
      entity?.hide();
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button size="small" startIcon={<ToggleOn />} onClick={handleShowAll}>
        {showAllString}
      </Button>
      <Button
        size="small"
        startIcon={<ToggleOff color="action" />}
        onClick={handleHideAll}
      >
        {hideAllString}
      </Button>
    </Box>
  );
}
