import computeRessourceQuantity from "Features/ressources/utils/computeRessourceQuantity";
import getItemsMapById from "Utils/getItemsMapById";

export default function getMeasurementsByRessourceWithQuantity(
  measurements,
  elementTypes,
  ressources
) {
  // data maps

  const resMap = getItemsMapById(ressources);
  const typeMap = getItemsMapById(elementTypes);

  // get measurements by ressourceId with quantities sum

  const measByResId = {};

  measurements.forEach((measurement) => {
    if (Array.isArray(measurement?.res)) {
      measurement.res.map(({resId}) => {
        const mapItem = measByResId[resId];
        if (!mapItem) {
          measByResId[resId] = {...measurement};
        } else {
          const newCount = measurement.count + mapItem.count;
          const newLength = measurement.length + mapItem.length;
          const newArea = measurement.area + mapItem.area;
          const newVolume = measurement.volume + mapItem.volume;
          //
          const newMeas = {...mapItem};
          //
          newMeas.count = newCount;
          newMeas.length = newLength;
          newMeas.area = newArea;
          newMeas.volume = newVolume;
          //
          measByResId[resId] = newMeas;
        }
      });
    }
  });

  // measByResId is up to date. Now, compute quantities

  const mapWithQties = {};

  Object.entries(measByResId).map(([resId, measurement]) => {
    const type = typeMap[measurement.elementTypeId];
    const res = type?.res?.find((r) => r.resId === resId);
    const ressource = resMap[resId];
    const qty = computeRessourceQuantity({
      item: measurement,
      res: res,
      ressource,
      withDecimal: true,
      filterNoVoids: false,
    });
    mapWithQties[resId] = {...measurement, qty, qtyNet: qty, qtyRaw: qty};
  });

  return mapWithQties;
}
