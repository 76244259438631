import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";

import addCodeNameToMeasurements from "../utils/addCodeNameToMeasurements";

import useSelectedMeasurementsModel from "Features/measurements/useSelectedMeasurementsModel";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
// import {addMeasurements, setModifiedMeasurements} from "Features/measurements/measurementsSlice";
import {setLastPackageId} from "Features/elementPackages/packagesSlice";

export default function ActionsAnnotatedPdfInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sceneId = scene?.id ? scene.id : scene?.clientId;

  // strings

  const createS = "Importer les annotations";

  // data

  const subjectElementTypeMap = useSelector(
    (s) => s.annotatedPdf.subjectElementTypeMap
  );
  const subjectTypeVarMap = useSelector(
    (s) => s.annotatedPdf.subjectTypeVarMap
  );
  const subjectGeoCatMap = useSelector((s) => s.annotatedPdf.subjectGeoCatMap);

  const author = useSelector((s) => s.annotatedPdf.selectedAuthor);
  let rawMeasurements = useSelector((s) => s.annotatedPdf.rawMeasurements);

  const target = useSelectedMeasurementsModel();

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const disabled = !Boolean(target?.id);

  rawMeasurements = rawMeasurements.filter((m) => m.author === author);

  // handlers

  async function handleCreateClick() {
    if (loading) return;
    setLoading(true);
    const mnger =
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager;

    const measurements = [];
    const annotations = mnger?.extractor.annotations;

    for (let rawMeasurement of rawMeasurements) {
      const elementType = subjectElementTypeMap[rawMeasurement.subject];
      const typeVar = subjectTypeVarMap[rawMeasurement.subject];
      const geoCat = subjectGeoCatMap[rawMeasurement.subject];

      const annotation = annotations.find(
        (annotation) => annotation.Id === rawMeasurement.id
      );

      const enhancedElementType = {...elementType};
      delete enhancedElementType.count;

      if (typeVar?.code === "H" && typeVar.value) {
        const height = typeVar.value.replace(",", ".");
        enhancedElementType.height = parseFloat(height);
      }
      if (geoCat) enhancedElementType.geoCat = geoCat;

      if (elementType && elementType.id) {
        const measurement = await mnger?.annotationToMeasurementFromElementType(
          annotation,
          enhancedElementType
        );
        measurements.push(measurement);
      }
    }

    // add codeName

    const newMeasurements = addCodeNameToMeasurements(measurements);

    // model

    if (target) {
      const updatedModel = {
        ...target,
        measurementsData: {
          ...target.measurementsData,
          measurements: newMeasurements,
        },
      };
      dispatch(updateModel({updatedModel}));
      caplaEditor?.measDataManager.createOrUpdateModelInManager(updatedModel);

      // // state

      // const newMs = newMeasurements.map((m) => ({
      //   ...m,
      //   measurementsModelId: target.id,
      //   pdfModelId: target.fromModel.modelId,
      // }));
      // dispatch(addMeasurements(newMs));
      // dispatch(setModifiedMeasurements([]));
    }

    setLoading(false);
    // caplaEditor?.measDataManager.setSelectedModelById(target?.id);
    navigate(`/bimboxes/${sceneId}/${target?.id}/measurements`);
    //dispatch(setLastPackageId(target?.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleCreateClick}
          disabled={disabled}
        >
          {createS}
        </Button>
      </Box>
    </Box>
  );
}
