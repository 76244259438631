import {useState} from "react";
import {useDispatch} from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
} from "@mui/material";

// import {updateMeasurements} from "../measurementsSlice";

export default function DialogRenameMultiMeasurements({
  open,
  onClose,
  measurementIds,
  caplaEditor
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Rename";

  const saveS = "Save";
  const cancelS = "Cancel";

  // const nameListS = "New names";

  // state

  const [newNames, setNewNames] = useState("");

  // handlers

  function handleNewNamesChange(e) {
    setNewNames(e.target.value);
  }

  function handleCancelClick() {
    onClose();
  }

  function handleSaveClick() {
    onClose();
    const newNamesA = newNames.split(/\r?\n|\r|\n/g);
    const updates = [];
    measurementIds.forEach((id, index) => {
      if (newNamesA[index]) updates.push({id, codeName: newNamesA[index]});
    });
    // dispatch(updateMeasurements(updates));
    caplaEditor?.measDataManager.updateMeasurements(updates);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            multiline
            value={newNames}
            onChange={handleNewNamesChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
