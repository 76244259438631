//
// tempNum is a temporary number that is used to sort articles as a tree.
// tempNum is not stored in the database.
// tempNum = "1", "1.1.1",...

import {get} from "react-scroll/modules/mixins/scroller";
import getNextNumFromRankDiff from "./getNextNumFromRankDiff";
import getItemsMapById from "Utils/getItemsMapById";

import styleRankMap from "../data/styleRankMap";

export default function articlesWithStyleToArticlesWithTempNum(articles) {
  // init
  let firstNumIsSet = false;
  const articleIdTempNumMap = {};
  let prevNum;
  //
  articles.forEach((article, index) => {
    //
    const articleRank = styleRankMap[article.style];
    //
    if (articleRank) {
      if (!firstNumIsSet) {
        firstNumIsSet = true;
        const numArray = Array(articleRank).fill("1");
        const num = numArray.join(".");
        articleIdTempNumMap[article.id] = num;
        prevNum = num;
      } else {
        const rankDiff = articleRank - prevNum.split(".").length;
        const nextNum = getNextNumFromRankDiff(prevNum, rankDiff);
        articleIdTempNumMap[article.id] = nextNum;
        prevNum = nextNum;
      }
    }
  });

  const articlesWithTempNum = articles.map((article) => {
    return {
      ...article,
      tempNum: articleIdTempNumMap[article.id],
    };
  });

  return articlesWithTempNum;
}
