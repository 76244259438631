import theme from "Styles/theme";
import {
  SphereGeometry,
  MeshBasicMaterial,
  LineBasicMaterial,
  BufferGeometry,
  Mesh,
  Line3,
  Quaternion,
  Object3D,
  Vector3,
  Group,
  Line,
} from "three";

class Vector {
  constructor(color = theme.palette.primary.flash) {
    this.object = this._create(color);
  }

  _create(color) {
    const group = new Group();
    const material = new MeshBasicMaterial({
      color,
      transparent: true,
      opacity: 0.8,
    });
    const materialLine = new LineBasicMaterial({
      color,
    });
    //const geometryPoint = new SphereGeometry(0.0005, 16, 16);

    const points = [new Vector3(0, 0, 0), new Vector3(0.1, 0, 0)];
    const geometryLine = new BufferGeometry().setFromPoints(points);

    //const point = new Mesh(geometryPoint, material);
    const line = new Line(geometryLine, materialLine);

    //group.add(point);
    group.add(line);

    return group;
  }

  enable() {
    this.object.children.forEach((c) => {
      c.layers.enable(1);
      c.layers.enable(3);
      c.visible = true;
    });
    this.object.layers.enable(1);
    this.object.layers.enable(3);
    this.object.visible = true;
  }

  disable() {
    console.log("disablePointerVector");
    this.object.children.forEach((c) => {
      c.layers.disable(1);
      c.layers.disable(3);
      c.visible = false;
    });
    this.object.layers.disable(1);
    this.object.layers.disable(3);
    this.object.visible = false;
  }

  loadTo(scene) {
    scene.add(this.object);
  }

  setPosition(originV, normalV) {
    const q = new Quaternion();
    q.setFromUnitVectors(new Vector3(1, 0, 0), normalV.normalize());
    this.object.rotation.set(0, 0, 0);
    this.object.applyQuaternion(q);
    this.object.position.copy(originV);
  }
}

export default Vector;
