export default function fillWorksheetWithMeasurementsWithRessources({
  worksheet,
  measurementsWithRessources,
  rowStartIndex,
}) {
  measurementsWithRessources.forEach(
    (
      {
        id,
        code,
        isVoid,
        ressource_group,
        ressource_num,
        ressource_num_for_sorting,
        ressource_name,
        ressource_quantity_unit,
        ressource_quantity_value,
        room_num,
        room_name,
        room_type,
        ressource_description,
        element_type_name,
        sector_name,
        sector_building,
        material_name,
      },
      index
    ) => {
      const row = worksheet.getRow(rowStartIndex + index);
      row.getCell(1).value = id;
      if (typeof code === "string")
        row.getCell(2).value = code;
      if (typeof isVoid === "boolean")
        row.getCell(3).value = isVoid ? "vide" : "plein";
      row.getCell(4).value = element_type_name;
      row.getCell(5).value = material_name;
      if (typeof ressource_group === "string")
        row.getCell(6).value = ressource_group;
      if (typeof ressource_num === "string")
        row.getCell(7).value = ressource_num;
      if (typeof ressource_num_for_sorting === "string")
        row.getCell(8).value = ressource_num_for_sorting;
      if (typeof ressource_name === "string")
        row.getCell(9).value = ressource_name;
      if (typeof ressource_description === "string")
        row.getCell(10).value = ressource_description;
      if (typeof ressource_quantity_unit === "string")
        row.getCell(11).value = ressource_quantity_unit;
      if (
        typeof ressource_quantity_value === "number" &&
        !isNaN(ressource_quantity_value)
      )
        row.getCell(12).value = ressource_quantity_value;
      if (typeof sector_building === "string")
        row.getCell(13).value = sector_building;
      if (typeof sector_name === "string") row.getCell(14).value = sector_name;
      if (typeof room_num === "string") row.getCell(15).value = room_num;
      if (typeof room_name === "string") row.getCell(16).value = room_name;
      if (typeof room_type === "string") row.getCell(17).value = room_type;
    }
  );
}
