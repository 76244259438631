import {useState} from "react";
import {useMsal} from "@azure/msal-react";
import {useIsAuthenticated} from "@azure/msal-react";
import {useSelector} from "react-redux";

import {
  ListItemButton,
  ListItemIcon,
  Avatar,
  ListItemText,
  Tooltip,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";

import {loginRequest} from "authConfig";

import DialogLogout from "./DialogLogout";

export default function ListItemButtonAuthInNavPanel() {
  // string

  const signInS = "Se connecter";
  const signOutS = "Se déconnecter";

  // data

  const navPanelSceneModuleWidth = useSelector(
    (s) => s.navigation.navPanelSceneModulesWidth
  );
  const {instance, accounts} = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // state

  const [open, setOpen] = useState(false);

  // helpers - email

  let email;
  if (Array.isArray(accounts)) email = accounts[0]?.username;
  let avatarS = email ? email.toUpperCase().charAt(0) : "?";

  // helpers - isSmallSize

  const isSmallSize = navPanelSceneModuleWidth < 80;

  // helpers

  const label = isAuthenticated ? signOutS : signInS;

  // handlers

  const handleLogin = () => {
    try {
      if (window.parent !== window) {
        instance.loginPopup(loginRequest);
      } else {
        instance.loginRedirect(loginRequest);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLogoutConfirm = () => {
    setOpen(false);
    instance.logout();
  };

  function handleLogout() {
    setOpen(true);
  }

  function handleClick() {
    if (isAuthenticated) {
      handleLogout();
    } else {
      handleLogin();
    }
  }

  return (
    <>
      <DialogLogout
        open={open}
        onConfirm={handleLogoutConfirm}
        onCancel={() => setOpen(false)}
      />
      <ListItemButton
        dense
        onClick={handleClick}
        color="common.white"
        sx={{
          minHeight: 32,
          color: "white",
          py: 0,
          pb: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: isSmallSize ? "center" : "start",
          "&.Mui-selected": {bgcolor: "secondary.main"},
        }}
      >
        <Tooltip title={email} placement="bottom">
          <Box
            sx={{
              py: 0,
              color: "inherit",
              bgcolor: isAuthenticated
                ? lighten(theme.palette.common.caplaBlack, 0.2)
                : "warning.main",
              width: 24,
              height: 24,
              borderRadius: "50%",
              fontSize: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: isSmallSize ? 0 : 3,
            }}
          >
            <Typography>{avatarS}</Typography>
          </Box>
        </Tooltip>

        {!isSmallSize && (
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              color: "common.white",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        )}
      </ListItemButton>
    </>
  );
}
