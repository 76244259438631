import {Box} from "@mui/material";
import ChipVersion from "./ChipVersion";
import theme from "Styles/theme";

export default function SectionVersionChips({
  selectedVersionIds,
  versions,
  onClick,
  hideAllHelper,
}) {
  const allObject = {
    id: "all",
    name: "Toutes les versions",
    num: "<->",
    color: theme.palette.primary.flash,
  };
  let allVersions = [...versions];
  if (!hideAllHelper) allVersions = [allObject, ...versions];

  const selection = Array.isArray(selectedVersionIds)
    ? selectedVersionIds
    : [...versions.map((v) => v.id), "all"];

  return (
    <Box sx={{display: "flex", width: 1, flexWrap: "wrap"}}>
      {allVersions.map((version, index) => (
        <Box
          key={version.id}
          sx={{pr: index === 0 && !hideAllHelper ? 2 : 0.5, pb: 0.5}}
        >
          <ChipVersion
            {...version}
            onClick={onClick}
            selected={selection.includes(version.id)}
          />
        </Box>
      ))}
    </Box>
  );
}
