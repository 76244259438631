import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useUpdateSceneWithElementTypes(scene) {
  const dispatch = useDispatch();

  const updateSceneElementTypes = (elementTypes) => {
    if (!scene) return;

    let elementTypesIds = elementTypes.map((t) => t.id);
    const sceneElementTypesPrev = scene?.data?.elementTypes
      ? scene.data.elementTypes
      : [];

    // unmodified
    let otherElementTypes = scene?.data?.elementTypes?.filter(
      (t) => !elementTypesIds.includes(t.id)
    );
    if (!otherElementTypes) otherElementTypes = [];

    // modified
    let prevElementTypes = sceneElementTypesPrev.filter((t) =>
      elementTypesIds.includes(t.id)
    );
    const updatedPrevElementTypes = prevElementTypes.map((prevElementType) => {
      const updatedElementType = elementTypes.find(
        (t) => t.id === prevElementType.id
      );
      return {...prevElementType, ...updatedElementType};
    });

    // new
    let prevIds = sceneElementTypesPrev.map((r) => r.id);
    let newElementTypes = elementTypes.filter((r) => !prevIds.includes(r.id));
    //newElementTypes = newElementTypes.map((r) => ({id: nanoid(), ...r})); // no need : id added in from excel converter

    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        elementTypes: [
          ...newElementTypes,
          ...otherElementTypes,
          ...updatedPrevElementTypes.filter((t) => !t.toDelete),
        ],
      },
    };

    dispatch(updateScene({scene: newScene}));
  };

  return updateSceneElementTypes;
}
