import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormIssueDetailInSelectionPanel from "./FormIssueDetailInSelectionPanel";
import CardLinkedRessources from "./CardLinkedRessources";

import useSelectedIssue from "../useSelectedIssue";

import {setTempIssue, setEditedIssue} from "Features/issues/issuesSlice";

export default function SectionIssueInSelectionPanel({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // data

  const selectedIssue = useSelectedIssue();

  const isEditing = useSelector((s) => s.issues.isEditingIssue);
  const tempIssue = useSelector((s) => s.issues.tempIssue);
  const editedIssue = useSelector((s) => s.issues.editedIssue);

  // helper • issue

  let issue = selectedIssue;
  if (isEditing) {
    issue = editedIssue;
  } else if (!isEditing && !selectedIssue) {
    issue = tempIssue;
  }

  // helper - isEditing

  const isEditingForm = isEditing || !selectedIssue;

  // handlers

  function handleChange(issue) {
    if (isEditing) {
      dispatch(setEditedIssue(issue));
    } else {
      dispatch(setTempIssue(issue));
    }
  }

  return (
    <Box sx={{width: 1}}>
      <FormIssueDetailInSelectionPanel
        issue={issue}
        onChange={handleChange}
        caplaEditor={caplaEditor}
        scene={scene}
        isEditing={isEditingForm}
      />
      <Box sx={{mt: 1}}>
        <CardLinkedRessources scene={scene} issue={issue} />
      </Box>
    </Box>
  );
}
