// styles

import addSectorsToRow from "./addSectorsToRow";
import applyStyleToRow from "./applyStyleToRow";

export default function fillWorksheetWithArticlesQties({
  worksheet,
  data,
  rowStartIndex,
}) {
  const sectors = data.sectors;
  const articlesQtiesForExcel = data.articlesQtiesForExcel;

  const length = articlesQtiesForExcel.length;
  worksheet.getRow(2).getCell(2).value = 3 + length;
  worksheet.getRow(2).getCell(2).font = worksheet.getRow(1).getCell(2).font;

  // ROW LENGTH

  const rowLength = 12 + sectors.length;

  // HEADER

  const rowHeader = worksheet.getRow(rowStartIndex - 1);
  addSectorsToRow(rowHeader, sectors, 13, 1);

  // ROWS

  articlesQtiesForExcel.forEach(({excelRow, article}, index) => {
    const row = worksheet.getRow(rowStartIndex + index);

    // value
    excelRow.forEach((value, index) => {
      row.getCell(index + 1).value = value;
    });

    // style
    applyStyleToRow({
      row,
      item: article,
      rowType: "ARTICLE_WITH_QTIES",
      rowLength,
    });
  });
}
