import useSelectedElementTypeGroupProxysInHome from "../hooks/useSelectedElementTypeGroupProxysInHome";
import useSelectedPdfsInHome from "../hooks/useSelectedPdfsInHome";

import {Box, Typography} from "@mui/material";
import {lighten} from "@mui/material/styles";

import ListPackagesInHomeAdmin from "./ListPackagesInHomeAdmin";
import CardTempPackage from "./CardTempPackage";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function SectionPackagesInHome({scene, caplaEditor}) {
  // strings

  const noPackageS = "0 calque de repérage";

  // data

  const selectedGroups = useSelectedElementTypeGroupProxysInHome(scene);
  const selectedPdfs = useSelectedPdfsInHome();
  const packages = usePackagesByScene(scene);

  // helpers

  const canCreate = selectedGroups.length > 0 && selectedPdfs.length > 0;

  const noPackage = packages.length === 0;

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        justifyContent: "space-between",
        minWidth: 0,
      }}
    >
      {noPackage && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            color: (theme) => theme.palette.secondary.light,
          }}
        >
          <Typography variant="h6" color="inherit">
            {noPackageS}
          </Typography>
        </Box>
      )}
      <ListPackagesInHomeAdmin scene={scene} caplaEditor={caplaEditor} />
      <Box sx={{display: "flex", width: 1}}>
        <CardTempPackage scene={scene} caplaEditor={caplaEditor} />
      </Box>
    </Box>
  );
}
