import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {Box, Typography, Button} from "@mui/material";

import useSelectedMeasurementsModelsData from "../useSelectedMeasurementsModelData";
import useSelectedMeasurementsModel from "../useSelectedMeasurementsModel";

import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useApplyProcedures from "Features/elementPackages/hooks/useApplyProcedures";
import ListPackagesMultiple from "Features/elementPackages/components/ListPackagesMultiple";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function SectionVoidsAutoAdd({scene, caplaEditor, onAdded}) {
  // strings

  const title = "Ajout automatique des vides 🪄";
  const title2 = "Retirer tous les vides";

  const description =
    "Les segments qui intersectent une polyligne seront automatiquement ajoutés comme vides de la polyligne.";
  const description2 =
    "Toutes les liaisons avec des vides des polylignes seront retirées.";

  const addS = "Ajouter";
  const removeS = "Retirer";

  const selectS =
    "Sélectionnez les calques qui contiennent les vides à ajouter.";
  // data

  const {measurements} = useSelectedMeasurementsModelsData(scene);
  const model = useSelectedMeasurementsModel();
  const packages = usePackagesByScene(scene, {filterByPdf: true});
  const elementTypes = useElementTypesBySceneProxy(scene);
  const zones = useZonesByScene(scene);
  const ressources = useRessourcesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const doProcedures = useApplyProcedures({
    caplaEditor,
    packages,
    elementTypes,
    ressources,
    zones,
    rooms,
    sectors,
    filterNoVoids,
  });

  // state

  const initVoidModelIds = [model?.id];
  const [voidModelIds, setVoidModelIds] = useState(initVoidModelIds);
  useEffect(() => {
    setVoidModelIds(initVoidModelIds);
  }, [model?.id]);

  // helpers

  const addVoidsProcedures = [
    {
      inputs: [model?.id],
      output: model?.id,
      options: {
        removeInitialSegments: false, // false ! if true, remove columns, beams, etc...
      },
      actions: [
        {
          action: "add_voids",
          params: {
            voidsPackageIds: [...voidModelIds],
          },
        },
      ],
    },
  ];

  // handlers

  function handleClick() {
    doProcedures(addVoidsProcedures);
    if (onAdded) onAdded();
  }

  function handleSelectionChange(ids) {
    setVoidModelIds(ids);
  }

  function handleClick2() {
    const newMs = measurements.map((m) => {
      const newM = {...m};
      if (newM.voids) delete newM.voids;
      return newM;
    });
    const updatedModel = {
      ...model,
      measurementsData: {
        ...model.measurementsData,
        measurements: newMs,
      },
    };
    caplaEditor?.dispatch(updateModel({updatedModel, sync: true}));
    caplaEditor?.measDataManager.createOrUpdateModelInManager(updatedModel);
    caplaEditor?.measDataManager.setShortMemory();
    if (onAdded) onAdded();
  }

  return (
    <Box
      sx={{
        "&>*:not(:last-child)": {
          mb: 1,
        },
      }}
    >
      <Box sx={{p: 1, bgcolor: "background.default", borderRadius: "8px"}}>
        <Box>
          <Typography sx={{fontWeight: "bold", mb: 1}}>{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Box sx={{display: "flex", justifyContent: "end", p: 1, width: 1}}>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleClick}
          >
            {addS}
          </Button>
        </Box>
        <Box>
          <Box sx={{p: 1}}>
            <Typography variant="body2" color="text.secondary">
              {selectS}
            </Typography>
          </Box>
          <ListPackagesMultiple
            packages={packages}
            multipleSelection={true}
            selection={voidModelIds}
            onSelectionChange={handleSelectionChange}
          />
        </Box>
      </Box>
      <Box sx={{p: 1, bgcolor: "background.default", borderRadius: "8px"}}>
        <Typography sx={{fontWeight: "bold", mb: 1}}>{title2}</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between"}}>
          <Typography variant="body2">{description2}</Typography>

          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleClick2}
          >
            {removeS}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
