import {Box, Paper} from "@mui/material";

import ButtonSetScaleFromMeasurement from "./ButtonSetScaleFromMeasurement";
import ButtonDownloadPdfInToolbar from "./ButtonDownloadPdfInToolbar";

export default function ToolbarDefaultPdf({editorPdf}) {
  // helpers - background

  const bgcolor = "common.caplaBlack";

  return (
    <Paper
      elevation={12}
      sx={{
        display: "flex",
        bgcolor,
        p: 0.5,
        borderRadius: "4px",
        alignItems: "center",
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "&>*:not(:last-child)": {mr: 1},
        }}
      >
        <ButtonSetScaleFromMeasurement editorPdf={editorPdf} />
        {/* <ButtonDownloadPdfInToolbar editorPdf={editorPdf} /> */}
      </Box>
    </Paper>
  );
}
