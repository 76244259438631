import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Box, Button} from "@mui/material";
import theme from "Styles/theme";
import {DataGrid, frFR, GridActionsCellItem} from "@mui/x-data-grid";
import {Launch} from "@mui/icons-material";

import {format} from "date-fns";

import CardSceneNameInDatagrid from "./CardSceneNameInDatagrid";
import FieldSceneProdStatus from "./FieldSceneProdStatus";
import SectionCallToActionCreateNewScene from "./SectionCallToActionCreateNewScene";

import {setSelectedSceneClientId} from "../scenesSlice";
import useScenesAreLoaded from "../useScenesAreLoaded";
import scenesToScenesForDatagrid from "../utils/scenesToScenesForDatagrid";

// import useAccessToken from "Features/auth/useAccessToken";
// import useDownloadRemoteScene from "Features/scenes/useDownloadRemoteScene";
import {setOpenSections} from "Features/ui/uiSlice";
// import useTranslation from "Features/translations/useTranslation";

export default function DataGridScenes({scenes}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const accessToken = useAccessToken();
  const darkMode = true;

  // strings

  const nameS = "Bimbox";
  const operationS = "Opération";
  const deadlineS = "Pour le";
  const statusS = "Statut";

  const openS = "Ouvrir";

  // data

  const selectedSceneClientId = useSelector(
    (s) => s.scenes.selectedSceneClientId
  );
  // const [downloadRemoteScene] = useDownloadRemoteScene(accessToken);

  const scenesAreLoaded = useScenesAreLoaded();

  // helpers

  const noScenes = scenes?.length === 0 && scenesAreLoaded;

  async function handleOpenScene(row) {
    // if (row.isRemote) {
    //   await downloadRemoteScene(row.remoteId);
    // }
    const sceneId = row.remoteId ? row.remoteId : row.id;
    navigate(`/bimboxes/${sceneId}/measurements`);
    dispatch(
      setOpenSections({
        outlet: false,
        fixedViewersBox: true,
        viewer3D: false,
        callToAction: false,
      })
    );
  }
  // grid - columns

  const columns = [
    {
      field: "name",
      headerName: nameS,
      flex: 0.5,
      renderCell: (params) => {
        return (
          <CardSceneNameInDatagrid
            name={params.row.name}
            connected={Boolean(params.row.remoteId)}
            isRemote={params.row.isRemote}
            darkMode={darkMode}
          />
        );
      },
    },
    {
      field: "operation",
      headerName: operationS,
      flex: 0.5,
    },
    {
      field: "deadline",
      headerName: deadlineS,
      size: 120,
      valueGetter: (params) =>
        params.value !== "-"
          ? format(new Date(params.value), "dd/MM/yyyy")
          : "-",
    },
    {
      headerName: statusS,
      field: "status",
      width: 150,
      renderCell: (params) => {
        return (
          <FieldSceneProdStatus
            value={params.value}
            readOnly={true}
            size="small"
          />
        );
      },
    },
    {
      headerName: "-",
      field: "actions",
      //type: "actions",
      width: 100,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              borderRadius: "4px",
              bgcolor: "primary.flash",
              color: "white",
              height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              color="inherit"
              size="small"
              onClick={() => handleOpenScene(params.row)}
            >
              {openS}
            </Button>
          </Box>
        );
      },
      // getActions: (params) => [
      //   <GridActionsCellItem
      //     icon={
      //       <Launch fontSize="small" color={darkMode ? "inherit" : "action"} />
      //     }
      //     onClick={() => handleOpenScene(params.row)}
      //     label={openS}
      //   />,
      // ],
    },
  ];

  // grid - row

  let rows = scenesToScenesForDatagrid(scenes);

  // selection

  const selection = selectedSceneClientId ? [selectedSceneClientId] : [];

  // handlers

  function handleSelectionChange(selection) {
    const id = selection[0] ? selection[0] : null;
    dispatch(setSelectedSceneClientId(id));
  }

  return (
    <Box sx={{height: 1, width: 1, position: "relative"}}>
      {noScenes ? (
        <SectionCallToActionCreateNewScene />
      ) : (
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          density="compact"
          rows={rows}
          columns={columns}
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selection}
          hideFooter
          sx={{
            ...(darkMode && {
              color: "white",
              "& .MuiDataGrid-cellContent, & .MuiDataGrid-columnHeader": {
                color: "inherit",
              },
            }),
          }}
        />
      )}
    </Box>
  );
}
