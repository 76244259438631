import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

const properValue = (excelValue) => {
  if (excelValue && typeof excelValue === "object") {
    if (excelValue.result) return excelValue.result;
    if (excelValue.hyperlink) return excelValue.text;
    if (excelValue.richText)
      return excelValue.richText.map((t) => t.text).join("");
    return null; // sharedFormula without result for instance
  }
  // typeof date ?
  return excelValue;
};

export default async function getElementTypesRelationsFromFile(
  file, ressourcesById, relations
) {
  const editedRelations = {...relations};
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];
  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(6, rowCount - 1).values();
  let typeId, ressourceTable;
  for (const row of rows) {
    const id = properValue(row.getCell(1).value);
    const tNum = properValue(row.getCell(2).value);
    const rNum = properValue(row.getCell(7).value);
    const ressourceValue = properValue(row.getCell(8).value);
    const unit = properValue(row.getCell(9).value);
    let formula = properValue(row.getCell(10).value);
    const condition = properValue(row.getCell(11).value);
    const toDelete = properValue(row.getCell(12).value);
    if (tNum && tNum !== " ") {
      typeId = id;
    } else if (rNum || unit || formula || condition) {
      let ressource = ressourcesById[id];
      if (!ressource) {
        ressource = Object.values(ressourcesById).find(
          (r) => r.group === ressourceTable && r.num === rNum
        );
      }
      if (ressource) {
        const relKey = `${typeId}-${ressource.id}`;
        if (toDelete === "x") {
          delete editedRelations[relKey];
        } else if (formula) {
          if (condition) formula += ` {{${condition}}}`;
          editedRelations[relKey] = {
            id: relKey,
            typeId: typeId,
            resId: ressource.id,
            mode: "FORMUL",
            func: formula,
          }
        } else {
          editedRelations[relKey] = {
            id: relKey,
            typeId: typeId,
            resId: ressource.id,
            mode: "AUTO",
          }
        }
      }
    } else if (ressourceValue) {
      ressourceTable = ressourceValue.trim();
    }
  }
  return editedRelations;
}