import {useDispatch, useSelector} from "react-redux";

import {IconButton, Box, Typography} from "@mui/material";
import {
  ArrowBackIos as Back,
  ArrowForwardIos as Forward,
} from "@mui/icons-material";

import {setSelectedRessourceId} from "Features/ressources/ressourcesSlice";
import {setSelectedRessourcesInDatagrid} from "Features/measurements/measurementsSlice";
// import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
// import useRessourcesInLeftPanel from "Features/ressources/hooks/useRessourcesInLeftPanel";
// import ButtonFocusOnExtractedText from "Features/pdf/components/ButtonFocusOnExtractedText";
// import ButtonFocusRessourceMeasurementsIn3d from "Features/ressources/components/ButtonFocusRessourceMeasurementsIn3d";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import getPrevAndNextItems from "Utils/getPrevAndNextItems";
import sortByNum from "Utils/sortByNum";

export default function HeaderRessourceSelectionHelper({
  scene,
  viewer,
  selectedRessource,
  // caplaEditor,
}) {
  const dispatch = useDispatch();

  // string

  const ressourceS = "Article";

  // data

  // const ressourcesInLeftPanel = useRessourcesInLeftPanel(scene, viewer);
  const groupEditor = useSelector((s) => s.ressources.selectedRessourceGroup);
  const groupViewer = useSelector((s) => s.measurements.selectedRessourceGroup);
  // const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  // const noQuantities = useSelector((s) => s.ressources.noQuantities);
  let ressourcesInLeftPanel = useRessourcesByScene(scene);

  // helpers
  const group = viewer ? groupViewer : groupEditor;
  if (group) ressourcesInLeftPanel = ressourcesInLeftPanel.filter((r) => r.group === group);
  ressourcesInLeftPanel = ressourcesInLeftPanel.sort((a, b) => sortByNum(a.num, b.num));

  const [prevRessource, nextRessource] = getPrevAndNextItems(
    selectedRessource?.id,
    ressourcesInLeftPanel,
  );

  // handlers

  function handleBackClick() {
    dispatch(setSelectedRessourceId(prevRessource.id));
    dispatch(setSelectedRessourcesInDatagrid([prevRessource.id]));
  }
  function handleForwardClick() {
    dispatch(setSelectedRessourceId(nextRessource.id));
    dispatch(setSelectedRessourcesInDatagrid([nextRessource.id]));
  }

  return (
    <Box
      sx={{
        display: selectedRessource ? "flex" : "none",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "background.default",
        color: "text.secondary",
        px: 1,
        py: 0.2,
      }}
    >
      <IconButton size="small" onClick={handleBackClick} color="inherit">
        <Back fontSize="small" color="inherit" />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "text.secondary",
        }}
      >
        <Typography sx={{fontSize: 13}} color="inherit">
          {`${ressourceS} ${selectedRessource?.num}`}
        </Typography>
      </Box>

      <IconButton size="small" onClick={handleForwardClick} color="inherit">
        <Forward fontSize="small" color="inherit" />
      </IconButton>
    </Box>
  );
}
