import getItemsWithNumsFromItemsWithWeights from "./getItemsWithNumsFromItemsWithWeights";

export default function getItemsWithNumsFromItemsWithStyles(items, firstNum) {
  //
  // items style : title1, title2, normal

  const styleWeighttMap = {detail: 1, normal: 2, title2: 3, title1: 4};

  const itemsWithWeights = items.map((item) => ({
    ...item,
    weight: styleWeighttMap[item.style] ?? 2,
  }));

  return getItemsWithNumsFromItemsWithWeights(itemsWithWeights, firstNum);
}
