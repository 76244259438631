import {useState} from "react";

import {useDispatch} from "react-redux";
import {
  postOrganisationNomenclature,
  updateOrganisationNomenclature,
} from "../organisationsSlice";

import {Box} from "@mui/material";

import CardNomenclatures from "Features/nomenclatures/components/CardNomenclatures";

import useAccessToken from "Features/auth/useAccessToken";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function ContainerEditableNomenclatures({organisation}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // state

  const [loading, setLoading] = useState(false);

  // helpers

  const nomenclatures = organisation?.nomenclatures;
  const organisationId = organisation?.id;

  // handlers

  async function handleCreateNomenclature(nomenclature) {
    if (loading) return;
    setLoading(true);
    await dispatch(
      postOrganisationNomenclature({accessToken, organisationId, nomenclature})
    );
    setLoading(false);
    const message = "Nouvelle nomenclature ajoutée";
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
  }

  async function handleUpdateNomenclature(nomenclature) {
    if (loading) return;
    setLoading(true);
    const updates = {
      name: nomenclature.name,
      tree: nomenclature.tree,
    };
    await dispatch(
      updateOrganisationNomenclature({
        accessToken,
        organisationId,
        nomenclatureId: nomenclature.id,
        updates,
      })
    );
    setLoading(false);
    const message = "Nomenclature mise à jour";
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
  }

  return (
    <Box sx={{width: 1, p: 2}}>
      <CardNomenclatures
        nomenclatures={nomenclatures}
        onNomenclatureCreated={handleCreateNomenclature}
        onNomenclatureUpdated={handleUpdateNomenclature}
      />
    </Box>
  );
}
