import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, FormControlLabel, Switch, Typography} from "@mui/material";

import ListItemVersion from "./ListItemVersion";

import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";
import {
  setShortcutVersions,
  setShowDeletedInVersion,
} from "Features/versions/versionsSlice";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import {setEditedTimeStamp} from "Features/measurements/measurementsSlice";
import SelectorMultipleVersionsWithChip from "Features/versions/components/SelectorMultipleVersionsWithChips";

export default function ShortcutVersions({scene, viewer}) {
  const dispatch = useDispatch();

  // strings

  const showDeletedS = "Afficher les suppressions";
  const title = "Dernière version de modification";

  // data

  const showDeletedInVersion = useSelector(
    (s) => s.versions.showDeletedInVersion
  );
  const shortcutVersions = useSelector((s) => s.versions.shortcutVersions);
  const versions = useVersionsByScene(scene);
  const mode = useMeasurementsScopeMode(scene, viewer);
  const [fms] = useFilteredSceneMeasurements(
    scene,
    mode,
    false,
    true,
    false,
    true,
    viewer
  );

  // helpers

  const versionsSet = new Set(fms.map((m) => m.versionId));

  const usedVersions = versions.filter((v) => versionsSet.has(v.id));

  if (versionsSet.has(undefined) || versionsSet.has(null)) {
    versionsSet.add("undefined");
    usedVersions.push({
      id: "undefined",
      num: "0",
      name: "-?-",
    });
  }

  // helpers

  const allVersionIds = usedVersions.map((v) => v.id);
  const selectedVersionIds =
    shortcutVersions.length === 0 ? allVersionIds : shortcutVersions;

  // handlers

  // function handleVersionClick(version) {
  //   if (
  //     shortcutVersions.includes(version.id) ||
  //     shortcutVersions.length === 0
  //   ) {
  //     let newSelection = shortcutVersions.filter((i) => i && i !== version.id);
  //     if (newSelection.length === 0) newSelection = [...versionsSet];
  //     dispatch(setShortcutVersions(newSelection));
  //   } else {
  //     dispatch(setShortcutVersions([...shortcutVersions, version.id]));
  //   }
  // }

  function handleCheckChange(e) {
    dispatch(setShowDeletedInVersion(e.target.checked));
    dispatch(setEditedTimeStamp(`${Date.now()}`));
  }

  function handleSelectionChange(selection) {
    console.log("selection", selection);
    dispatch(setShortcutVersions(Array.isArray(selection) ? selection : []));
  }

  return (
    <>
      {/* <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
        {usedVersions
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((version) => (
            <ListItemVersion
              key={version.id}
              onClick={handleVersionClick}
              version={version}
            />
          ))}
      </Box> */}
      <Typography sx={{mb: 2}}>{title}</Typography>
      <SelectorMultipleVersionsWithChip
        versions={usedVersions}
        selectedVersionIds={selectedVersionIds}
        onChange={handleSelectionChange}
        options={{hideAllHelper: true}}
      />
      <FormControlLabel
        sx={{mt: 1, pl: 1.25}}
        control={
          <Switch
            checked={showDeletedInVersion}
            onChange={handleCheckChange}
            size="small"
          />
        }
        label={
          <Typography sx={{fontSize: 12}} variant="body2">
            {showDeletedS}
          </Typography>
        }
      />
    </>
  );
}
