import {useSelector, useDispatch} from "react-redux";

import {setSelectedScopeIdInModule} from "../scopesSlice";

import {Add, Edit} from "@mui/icons-material";

export default function useActionsScopesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();
  // data

  const selectedScopeIdInModule = useSelector(
    (s) => s.scopes.selectedScopeIdInModule
  );

  // handlers

  function handleCreateScope() {
    dispatch(setSelectedScopeIdInModule(null));
  }

  // actions map

  const actionsMap = {
    createScope: {
      icon: <Add fontSize="small" />,
      name: "Sous-bimbox",
      label: "Créer une sous-bimbox",
      handler: handleCreateScope,
    },
    updateScope: {
      icon: <Edit fontSize="small" />,
      name: "Modifier",
      label: "Modifier",
      disabled: !selectedScopeIdInModule,
    },
  };

  return actionsMap;
}
