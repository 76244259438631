import {createSlice} from "@reduxjs/toolkit";

export const leftPanelSlice = createSlice({
  name: "leftPanel",
  initialState: {
    leftPanelSelection: {type: "SCENE", item: {}},
    leftPanelSection: "SCENE", // SCENE, MEASUREMENTS
    leftPanelSelectionPrev: null,
    showLeftSelectionInViewer: false,
    showScreenDatagridHelper: true,
    expandLeftSelectionDetail: false, // detail in selection section
    expandLeftSelectionQtiesBySector: false,
    selectedMeasurementOverviewFormGroup: "PROPS",
    selectedListItemId: null,
    //
    tabInEditMeasurements: "ELEMENT_TYPES", // "RESSOURCES"
  },
  reducers: {
    setLeftPanelSelection: (state, action) => {
      state.leftPanelSelection = action.payload;
    },
    setShowLeftSelectionInViewer: (state, action) => {
      state.showLeftSelectionInViewer = action.payload;
    },
    setShowScreenDatagridHelper: (state, action) => {
      state.showScreenDatagridHelper = action.payload;
    },
    setExpandLeftSelectionDetail: (state, action) => {
      state.expandLeftSelectionDetail = action.payload;
    },
    setExpandLeftSelectionQtiesBySector: (state, action) => {
      state.expandLeftSelectionQtiesBySector = action.payload;
    },
    setLeftPanelSection: (state, action) => {
      state.leftPanelSection = action.payload;
    },
    setSelectedMeasurementOverviewFormGroup: (state, action) => {
      state.selectedMeasurementOverviewFormGroup = action.payload;
    },
    setSelectedListItemId: (state, action) => {
      state.selectedListItemId = action.payload;
    },
    setTabInEditMeasurements: (state, action) => {
      state.tabInEditMeasurements = action.payload;
    },
  },
});

export const {
  setLeftPanelSelection,
  setLeftPanelSelectionPrev,
  setShowLeftSelectionInViewer,
  setShowScreenDatagridHelper,
  setExpandLeftSelectionDetail,
  setExpandLeftSelectionQtiesBySector,
  setLeftPanelSection,
  setSelectedMeasurementOverviewFormGroup,
  setSelectedListItemId,
  setTabInEditMeasurements,
} = leftPanelSlice.actions;

export default leftPanelSlice.reducer;
