export default function excelSubjectTypeMapToSubjectTypeMap(
  excelSubjectTypeMap
) {
  const subjectTypeMap = {};
  excelSubjectTypeMap.forEach((item) => {
    const type = {};
    type.group = item.group;
    type.name = item.name;
    type.code = item.code;
    type.num = item?.num ? item.num.toString() : "0";
    type.drawingShape = item.drawingShape;
    type.color = item.color;
    type.dotted = item.dotted === "x";
    type.isVoid = item.isVoid === "x";
    if (typeof item.height === "number") type.height = item.height;
    if (typeof item.dim1 === "number") type.dim1 = item.dim1;
    if (typeof item.dim2 === "number") type.dim2 = item.dim2;
    type.unit = item.unit;

    subjectTypeMap[item.subjectName] = type;
  });
  return subjectTypeMap;
}
