import React from "react";

import useSelection from "Features/selection/useSelection";

import {Box, Typography} from "@mui/material";
import SectionElementTypeGlobalQuantity from "Features/elementTypes/components/SectionElementTypeGlobalQuantity";

export default function SectionHomeElementTypeInSelectionPanel({scene}) {
  // data

  const selection = useSelection({scene});
  const elementType = selection?.item;

  return (
    <Box sx={{width: 1, height: 1, bgcolor: "common.white"}}>
      <SectionElementTypeGlobalQuantity
        elementType={elementType}
        scene={scene}
      />
    </Box>
  );
}
