import {Vector2, Vector3} from "three";

function intersectLines(start0, dir0, start1, dir1) {
  var dd = dir0.x * dir1.y - dir0.y * dir1.x;
  // dd=0 => lines are parallel. we don't care as our lines are never parallel.
  var dx = start1.x - start0.x;
  var dy = start1.y - start0.y;
  var t = (dx * dir1.y - dy * dir1.x) / dd;
  return new Vector2(start0.x + t * dir0.x, start0.y + t * dir0.y);
}

// computes the minimum area enclosing rectangle
// (aka oriented minimum bounding box)

export default class Ombb {
  constructor() {
    this.BestObbArea = Number.MAX_VALUE;
    this.BestObb = [];
  }

  updateOmbb(
    leftStart,
    leftDir,
    rightStart,
    rightDir,
    topStart,
    topDir,
    bottomStart,
    bottomDir
  ) {
    var obbUpperLeft = intersectLines(leftStart, leftDir, topStart, topDir);
    var obbUpperRight = intersectLines(rightStart, rightDir, topStart, topDir);
    var obbBottomLeft = intersectLines(
      bottomStart,
      bottomDir,
      leftStart,
      leftDir
    );
    var obbBottomRight = intersectLines(
      bottomStart,
      bottomDir,
      rightStart,
      rightDir
    );
    var distLeftRight = obbUpperLeft.distanceTo(obbUpperRight);
    var distTopBottom = obbUpperLeft.distanceTo(obbBottomLeft);
    var obbArea = distLeftRight * distTopBottom;

    if (obbArea < this.BestObbArea) {
      this.BestObb = [
        obbUpperLeft,
        obbBottomLeft,
        obbBottomRight,
        obbUpperRight,
      ];
      this.BestObbArea = obbArea;
    }
  }

  calcOmbb(points) {
    this.BestObbArea = Number.MAX_VALUE;
    this.BestObb = [];

    const convexHull = points.map(([x, y]) => new Vector2(x, y));
    // compute directions of convex hull edges

    var edgeDirs = [];

    for (var i = 0; i < convexHull.length; i++) {
      const convexHull_ip1 = new Vector2();
      convexHull_ip1.copy(convexHull[(i + 1) % convexHull.length]);
      edgeDirs.push(convexHull_ip1.sub(convexHull[i]));
      edgeDirs[i].normalize();
    }

    // compute extreme points
    var minPt = new Vector2(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
    var maxPt = new Vector2(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);
    var leftIdx, rightIdx, topIdx, bottomIdx;

    for (var i = 0; i < convexHull.length; i++) {
      var pt = convexHull[i];

      if (pt.x < minPt.x) {
        minPt.x = pt.x;
        leftIdx = i;
      }

      if (pt.x > maxPt.x) {
        maxPt.x = pt.x;
        rightIdx = i;
      }

      if (pt.y < minPt.y) {
        minPt.y = pt.y;
        bottomIdx = i;
      }

      if (pt.y > maxPt.y) {
        maxPt.y = pt.y;
        topIdx = i;
      }
    }

    // initial caliper lines + directions
    //
    //        top
    //      <-------
    //      |      A
    //      |      | right
    // left |      |
    //      V      |
    //      ------->
    //       bottom
    var leftDir = new Vector2(0.0, -1);
    var rightDir = new Vector2(0, 1);
    var topDir = new Vector2(-1, 0);
    var bottomDir = new Vector2(1, 0);

    // execute rotating caliper algorithm
    for (var i = 0; i < convexHull.length; i++) {
      // of course the acos() can be optimized.
      // but it's a JS prototype anyways, so who cares.
      var phis =
        // 0=left, 1=right, 2=top, 3=bottom
        [
          Math.acos(leftDir.dot(edgeDirs[leftIdx])),
          Math.acos(rightDir.dot(edgeDirs[rightIdx])),
          Math.acos(topDir.dot(edgeDirs[topIdx])),
          Math.acos(bottomDir.dot(edgeDirs[bottomIdx])),
        ];

      var lineWithSmallestAngle = phis.indexOf(Math.min.apply(Math, phis));
      switch (lineWithSmallestAngle) {
        case 0: // left
          leftDir.copy(edgeDirs[leftIdx]);
          rightDir.copy(leftDir);
          rightDir.negate();
          //topDir = leftDir.orthogonal();
          topDir = new Vector2(leftDir.y, -leftDir.x);
          bottomDir.copy(topDir);
          bottomDir.negate();
          leftIdx = (leftIdx + 1) % convexHull.length;
          break;
        case 1: // right
          rightDir.copy(edgeDirs[rightIdx]);
          leftDir.copy(rightDir);
          leftDir.negate();
          //topDir = leftDir.orthogonal();
          topDir = new Vector2(leftDir.y, -leftDir.x);
          bottomDir.copy(topDir);
          bottomDir.negate();
          rightIdx = (rightIdx + 1) % convexHull.length;
          break;
        case 2: // top
          topDir.copy(edgeDirs[topIdx]);
          bottomDir.copy(topDir);
          bottomDir.negate();
          //leftDir = bottomDir.orthogonal();
          leftDir = new Vector2(bottomDir.y, -bottomDir.x);
          rightDir.copy(leftDir);
          rightDir.negate();
          topIdx = (topIdx + 1) % convexHull.length;
          break;
        case 3: // bottom
          bottomDir.copy(edgeDirs[bottomIdx]);
          topDir.copy(bottomDir);
          topDir.negate();
          //leftDir = bottomDir.orthogonal();
          leftDir = new Vector2(bottomDir.y, -bottomDir.x);
          rightDir.copy(leftDir);
          rightDir.negate();
          bottomIdx = (bottomIdx + 1) % convexHull.length;
          break;
      }

      this.updateOmbb(
        convexHull[leftIdx],
        leftDir,
        convexHull[rightIdx],
        rightDir,
        convexHull[topIdx],
        topDir,
        convexHull[bottomIdx],
        bottomDir
      );
    }

    let [p1, p2, p3, p4] = this.BestObb;
    if (p1.x === p4.x && p1.y === p4.y) {
      // in case p1 = p4 & p2 = p3, edge case in the algo
      const _p1 = p1;
      const _p2 = {x: p1.x, y: p2.y};
      const _p3 = p2;
      p1 = _p1;
      p2 = _p2;
      p3 = _p3;
    }
    const p1p2 = [p2.x - p1.x, p2.y - p1.y];
    const p2p3 = [p3.x - p2.x, p3.y - p2.y];
    const dim1 = Math.sqrt(p1p2[0] ** 2 + p1p2[1] ** 2);
    const dim2 = Math.sqrt(p2p3[0] ** 2 + p2p3[1] ** 2);
    const dimMax = dim1 < dim2 ? dim2 : dim1;
    const dimMin = dim1 < dim2 ? dim1 : dim2;

    return {ombb: this.BestObb, dimMax, dimMin};
  }
}
