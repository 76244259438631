import {useSelector} from "react-redux";

import {IconButton} from "@mui/material";
import {ScatterPlot as Plot} from "@mui/icons-material";

export default function IconButtonPlotPictures({caplaEditor}) {
  // data

  const pictures = useSelector((s) => s.pictures.items);

  // handlers

  function handlePlotClick() {
    caplaEditor?.mapEditor?.azmEditor.loadPicturesDataset(pictures);
  }
  return (
    <IconButton size="small" onClick={handlePlotClick}>
      <Plot fontSize="small" />
    </IconButton>
  );
}
