// The Viewer3D component is the main interface,
// made of several controlers and one View3D.
// This component state depends on each controlers.

import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, alpha} from "@mui/material";
import {styled} from "@mui/material/styles";

import View3D from "./View3D";
import SectionScreenshotViewer from "./SectionScreenshotViewer";
import SliderCamVerticalAngle from "./SliderCamVerticalAngle";
import ButtonCenterCam1 from "./ButtonCenterCam1";
import ButtonCenterCam2 from "./ButtonCenterCam2";
import Header from "./Header";
import ToggleButtonWalkMode from "./ToggleButtonWalkMode";
import SectionDatasets from "./SectionDatasets";
import LayerDefault from "./LayerDefault";

import Editor from "../js/Editor"; // class
import {getModelTypeFromFile} from "../utils/miscUtils";
import Loader from "../js/Loader";
import Context from "../js/Context";

import {deleteTempScenes} from "Database/services";

import {
  // setIsLoaded,
  initSceneState,
  updateModel,
  setStep,
  setMode,
  setSubStep,
  updateIsOrthoObject,
  setRightSection,
  setDetailSection,
  setSelection,
  setShowSelectionCard,
  setConfiguring,
  setMarkerPosition,
  setPositioningObject,
  setConfigCenter,
  setMainTool,
  setToolData,
  setToolViewer,
  setLoadEdges,
  setLoadIfcStructure,
  setRawIfcModel,
  updateMultipleSelection,
  setMultipleSelection,
  clearMultipleSelection,
  setSelectedModelId,
  setCam2Height,
  setTempObjects,
  setEditedObject,
  setClickedObject,
  setFirstRendered,
  setIsLoaded,
} from "Features/viewer3D/viewer3DSlice";
import {setClippingPlanes} from "Features/scenes/scenesSlice";
import useHorizontalClippingPlane from "Features/viewer3D/useHorizontalClippingPlane";

import {
  selectorSpaceModels,
  selectorSpaceScenes,
} from "Features/space/spaceSlice";
import useProfile from "Features/profile/useProfile";

import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";

import {unzip} from "Features/files/utils";

import useMeasurementsModelsInScope from "Features/measurements/useMeasurementsModelsInScope";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";

import useIs3dEnabled from "Features/navigation/useIs3dEnabled";
import ScreenLoadingModelsInViewer3D from "./ScreenLoadingModelsInViewer3D";
import {setScopeMeasurementsCount} from "Features/measurements/measurementsSlice";
import useSelectedVersionForMeasurements from "Features/versions/hooks/useSelectedVersionForMeasurements";
import ScreenLoadingPackageInViewer3d from "Features/elementPackages/components/ScreenLoadingPackageInViewer3d";

const Root = styled("div")({
  width: "100%",
  height: "100%",
  boxSizing: "border-box",
  position: "relative",
  display: "flex",
  border: (theme) => `2px solid ${theme.palette.common.caplaBlack}`,
});

export default function Viewer3D({
  scene,
  mobile = false,
  story = false,
  viewer = false,
  space = false,
  landingPage = false,
  webWorker = true,
  caplaEditor,
}) {
  //
  const dispatch = useDispatch();

  // data

  const selectedVersionForMeasurements = useSelectedVersionForMeasurements();
  const is3dEnabled = useIs3dEnabled();

  const showMeasurements = useSelector((s) => s.measurements.showMeasurements);

  const firstRendered = useSelector((s) => s.viewer3D.firstRendered);

  const profile = useProfile();
  const walkMode = useSelector((state) => state.viewer3D.walkMode);
  const imageOverlayMode = useSelector(
    (state) => state.viewer3D.imageOverlayMode
  );

  const boxOnly = useSelector((state) => state.viewer3D.loadLoadingBoxOnly);
  const rawIfcModel = useSelector((state) => state.viewer3D.rawIfcModel);
  const snappingMode = useSelector((state) => state.viewer3D.snappingMode);
  const multiviews = useSelector((state) => state.viewer3D.multiviews);
  const deltaX = Math.floor(
    useSelector((state) => state.viewer3D.multiviewsSliderPositionX)
  );
  const showSelectionCard = useSelector(
    (state) => state.viewer3D.showSelectionCard
  );

  const mainSection = useSelector((state) => state.viewer3D.mainSection);

  const viewMode = useSelector((state) => state.viewer3D.viewMode);
  const show3D = useSelector((state) => state.viewer3D.show3D);

  const isOrthoObject = useSelector((state) => state.viewer3D.isOrthoObject); // 2 editors.

  const allModels = useSelector((state) => state.viewer3D.models);
  const models = allModels.filter((m) => m.sceneClientId === scene?.clientId);

  const hiddenModelsHash = models
    .filter((m) => m.hidden)
    .map((m) => m.id)
    .join("-");
  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);

  const spaceModels = useSelector(selectorSpaceModels);
  const spaceScenes = useSelector(selectorSpaceScenes);
  const spaceId = useSelector((state) => state.space.id);
  const spaceStatus = useSelector((state) => state.space.status);
  const spaceSceneId = useSelector((state) => state.space.focusedSceneId);
  const spaceScenesIdsHash = useSelector((state) => state.space.scenesIdsHash);
  const spaceHiddenScenesIds = useSelector(
    (state) => state.space.hiddenScenesIds
  );

  const scenesStatus = useSelector((state) => state.viewer3D.scenesStatus);
  const sceneStatus = scenesStatus[scene?.clientId];

  const markers = useSelector((state) => state.viewer3D.markers);

  const configuring = useSelector((state) => state.viewer3D.configuring);

  const os = useSelector((s) => s.ui.openSections);

  // multiple selection

  const selection = useSelector((state) => state.viewer3D.selection);
  const multipleSelection = useSelector(
    (state) => state.viewer3D.multipleSelection
  );
  const multipleSelectionMode = useSelector(
    (state) => state.viewer3D.multipleSelectionMode
  );
  if (caplaEditor?.editor3d && multipleSelectionMode)
    // we disable multiselection if mode = false.
    caplaEditor?.editor3d?.multipleSelect(multipleSelection);

  // selected Measurement

  // const selectedMeasurement = useSelectedMeasurement();
  // const selectedMeasurementIds = useSelector((s) => s.measurements.selectedMeasurementIds);

  // // selected ressource group

  // const selectedRessourceGroup = useSelector(
  //   (s) => s.measurements.selectedRessourceGroup
  // );

  // const ressources = useRessourcesByScene(scene);

  // Init

  useEffect(() => {
    if (viewer) dispatch(setMainTool("MODELS"));
    deleteTempScenes();
  }, []);

  // loader & context

  useEffect(() => {
    if (caplaEditor?.editor3d && scene?.clientId) {
      const loader = new Loader({
        caplaEditor: caplaEditor,
        scene,
        dispatch,
        boxOnly,
        rawIfcModel,
      });
      const context = new Context({
        editor3d: caplaEditor?.editor3d,
        sceneClientId: scene.clientId,
        dispatch,
      });
      caplaEditor.editor3d.loader = loader;
      caplaEditor.editor3d.context = context;
      console.log("EFFECT !! New loader for scene ", scene?.title);
    }
  }, [caplaEditor?.editor3d, scene?.clientId]);

  useEffect(() => {
    if (caplaEditor?.editor3d) {
      caplaEditor.editor3d.multiScenesLoader = new Loader({
        caplaEditor: caplaEditor,
        dispatch,
      });
    }
  }, [caplaEditor?.editor3d]);

  // local state

  const [toolConfig, setToolConfig] = useState();

  const [editorLoaded, setEditorLoaded] = useState(false);

  const [_, setPointerMessage] = useState("");

  const [_model, setScreenP] = useState({x: 0, y: 0}); // position of the mouse in the screen.

  // const [pdfEditor, setPdfEditor] = useState();

  // handlers • new or sd entities

  // REDUNDANCY ?
  function handleEditorConfigEntitySave(entity) {
    if (entity.model) {
      dispatch(updateModel({updatedModel: entity.model, sync: true}));
    }
  }

  function handleEditorModelChange(model, sync = false) {
    const newModel = {...model};
    const file = newModel.file;
    delete newModel.file;
    dispatch(updateModel({updatedModel: newModel, file, sync}));
  }

  // handlers • modes & views

  function handleCloseConfigClick() {
    dispatch(setConfiguring(false));
    dispatch(setConfigCenter(false));
    dispatch(updateIsOrthoObject({mainScene: false}));
    caplaEditor?.editor3d?.switchToViewMode("VIEW_3D");
    caplaEditor?.editor3d?.configCenter.close();
  }

  function handleEditorEscape() {
    if (!walkMode) {
      dispatch(setConfiguring(false));
      dispatch(setConfigCenter(false));
      dispatch(setMode("DEFAULT"));
      dispatch(setStep("DEFAULT"));
      dispatch(setSubStep("DEFAULT"));
      dispatch(updateIsOrthoObject({mainScene: false, editor: false}));
      dispatch(setPositioningObject({editor: false}));
      dispatch(setRightSection(false));
      dispatch(setDetailSection(false));
      dispatch(setSelection(undefined));
      dispatch(setToolData(null));
      dispatch(setTempObjects([]));
      dispatch(setToolViewer(null));
      dispatch(setClickedObject({}));
      dispatch(setEditedObject({}));
      setToolConfig();
    }
  }

  // handlers • interactions

  const handleEditorClick = (props) => {
    setScreenP(props?.screenP);
    if (props?.sceneP?.y) {
      const message = `Z : ${props.sceneP?.y.toFixed(3)} m`;
      setPointerMessage(message);
    } else {
      setPointerMessage("");
    }
  };

  // !! risk of infinite loop : caplaEditor new selection => new state => new caplaEditor selection => new state...
  function handleEditorSelectionChange(
    selectedItem,
    keepSelection = false,
    unselect
  ) {
    try {
      const multiSelectionItem = {
        type: selectedItem?.type,
        modelId: selectedItem?.modelId,
        ifcModelID: selectedItem?.ifcModelID,
        expressIDs: [selectedItem?.expressID],
        codeNames: [selectedItem?.codeName],
      };

      if (multipleSelectionMode) {
        if (selectedItem?.type === "IFC_MODEL_ELEMENT") {
          if (keepSelection && !unselect) {
            console.log("case1");
            dispatch(updateMultipleSelection(multiSelectionItem));
          } else if (!keepSelection && unselect) {
            console.log("case2");
            dispatch(updateMultipleSelection(multiSelectionItem));
          } else if (!keepSelection && !selectedItem) {
            console.log("case3");
            dispatch(clearMultipleSelection());
          } else if (
            !keepSelection &&
            selection?.expressID === selectedItem?.expressID
          ) {
            // previous selection & 1 item selected.
            console.log("case4");
            dispatch(clearMultipleSelection());
          } else {
            console.log("case5");
            selectedItem &&
              dispatch(setMultipleSelection([multiSelectionItem]));
            !selectedItem && dispatch(setMultipleSelection([]));
          }
        } else {
          dispatch(setMultipleSelection([multiSelectionItem]));
        }

        // dispatch(setSelection(unselect ? undefined : selectedItem)); ????
        dispatch(setSelection(selectedItem));
        dispatch(setShowSelectionCard(true));
      } else {
        dispatch(setSelection(selectedItem));
        dispatch(setShowSelectionCard(true));
      }

      //dispatch(setDetailSection(true));
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (caplaEditor?.editor3d) {
      caplaEditor.editor3d.onSelectionChange = handleEditorSelectionChange;
    }
  }, [selection?.expressID, caplaEditor?.editor3d, multipleSelectionMode]);

  // trigger when positioning the marker in the main caplaEditor
  function handleEditorPositionClicked(position) {
    dispatch(setMarkerPosition(position));
  }

  /*
   * Camera
   */

  // used to remove the selection card.
  // /!\ cost ressources !! => comment.
  const handleCameraChange = () => {};

  const handleCam2HeightChange = (height) => {
    dispatch(setCam2Height(height));
  };

  useEffect(() => {
    if (editorLoaded && caplaEditor?.editor3d)
      caplaEditor.editor3d.controls.onCameraChange = handleCameraChange;
  }, [showSelectionCard]);

  /*
   * Drag & Drop
   */

  function handleViewerDragEnter(e) {
    e.preventDefault();
  }

  function handleViewerDragLeave(e) {}

  function handleViewerDragOver(e) {
    e.preventDefault();
  }
  async function handleViewerDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    try {
      let file = e.dataTransfer.items[0].getAsFile();
      console.log("file name", file.name);
      if (file.name.split(".").pop() === "ifcZIP") {
        file = await unzip(file);
      }
      console.log("file", file);

      const type = getModelTypeFromFile(file);

      caplaEditor?.editor3d?.loader.loadFile({file, type});

      caplaEditor?.editor3d?.startStandardMode(); //  stop black event
    } catch (e) {
      console.log(e);
    }
  }

  // editors creation
  useEffect(() => {
    if (caplaEditor && !caplaEditor.editor3d) {
      const newEditor3d = new Editor({
        dispatch,
        onEditorClick: handleEditorClick,
        onSelectionChange: handleEditorSelectionChange,
        onPositionClicked: handleEditorPositionClicked,
        onEscape: handleEditorEscape,
        onSaveConfigEntity: handleEditorConfigEntitySave,
        onModelChange: handleEditorModelChange,
        onCameraChange: handleCameraChange,
        onCam2HeightChange: handleCam2HeightChange,
        main: true,
        isOrtho: isOrthoObject.editor,
        models,
        markers,
        webWorker,
        snappingMode,
        multiviews,
        caplaEditor,
      });
      setEditorLoaded(true);
      caplaEditor?.setEditor3d(newEditor3d);
      dispatch(setIsLoaded(true));
      console.log("a new editor3d is created");
    }
  }, [caplaEditor]);

  // Scene intialisation :scene change => disable all models + init selection

  function cleanEditor() {
    console.log("clean editor3d");
    dispatch(setSelectedModelId(null));
    if (!viewer) dispatch(initSceneState());
    caplaEditor?.editor3d?.disableAllEntities();
    // WARNING v : conflict with scene loader... : status = "preloaded" is changed to "idle"
    // if (sceneStatus !== "idle" && scene?.clientId) {
    //   dispatch(setSelection(undefined));
    //   dispatch(
    //     updateScenesStatus({sceneClientId: scene.clientId, status: "idle"})
    //   );
    // }
  }

  useEffect(() => {
    if (!space && scene?.clientId) {
      cleanEditor();
    }
  }, [scene?.clientId]);

  // init - mobile setup

  useEffect(() => {
    const loader = caplaEditor?.editor3d?.loader;
    if (mobile && loader) {
      dispatch(setRawIfcModel(true));
      dispatch(setLoadEdges(false));
      dispatch(setLoadIfcStructure(false));
      loader.setRawIfcModel(true);
      loader.setLoadEdges(false);
      loader.setLoadIfcStructure(false);
    }
  }, [mobile, caplaEditor?.editor3d?.loader]);
  // init and update space

  // load scenes in space

  useEffect(() => {
    if (
      spaceId &&
      caplaEditor?.editor3d?.scenesManager &&
      spaceStatus === "loaded" // set by the useSpace hook.
    ) {
      caplaEditor?.editor3d?.scenesManager.loadScenes(
        spaceScenes,
        spaceModels,
        spaceSceneId
      );
    }
  }, [
    spaceScenesIdsHash,
    spaceId,
    spaceSceneId,
    spaceStatus,
    caplaEditor?.editor3d?.scenesManager,
  ]);

  // update space when scene visibility change

  const hash = [...spaceHiddenScenesIds].sort().join("-");
  useEffect(() => {
    if (caplaEditor?.editor3d?.scenesManager && spaceId) {
      caplaEditor?.editor3d.scenesManager.updateVisibility(
        spaceHiddenScenesIds
      );
    }
  }, [caplaEditor?.editor3d?.scenesManager, hash, spaceId]);

  /*
   * LOAD MODELS IN SCENE
   */

  useEffect(() => {
    if (caplaEditor?.editor3d?.loader && scene?.clientId) {
      console.log("LOAD MODELS IN SCENE - STATUS", sceneStatus, is3dEnabled);
      let modelsToLoad = models.filter((m) =>
        ["MARKERS", "IMAGE"].includes(m.type)
      );
      const measurementModels = models.filter((m) => m.type === "MEASUREMENTS");

      if (
        sceneStatus === "preloaded" &&
        is3dEnabled &&
        modelsToLoad.length > 0
      ) {
        caplaEditor?.editor3d?.loader.loadAsyncModelsV2(
          modelsToLoad,
          mobile,
          viewer
          // selectedMeasurementIds,
        );
      }
      if (
        !viewer &&
        sceneStatus === "preloaded" &&
        is3dEnabled &&
        measurementModels?.length > 0
      ) {
        caplaEditor?.editor3d?.loader.loadAsyncModelsV2(
          measurementModels,
          mobile,
          viewer
          // selectedMeasurementIds,
        );
      }
    }
  }, [
    is3dEnabled,
    sceneStatus,
    scene?.clientId,
    caplaEditor?.editor3d?.loader,
    profile?.id,
    // selectedRessourceGroup,
  ]); // profile to trigger loading when connecting for the first time

  // first render

  useEffect(() => {
    if (sceneStatus === "loaded" && !firstRendered) {
      caplaEditor?.editor3d?.sceneEditor.zoomOut();
      dispatch(setFirstRendered(true));
    }
  }, [sceneStatus, firstRendered]);

  //init clippingPlanes

  useEffect(() => {
    if (scene?.clientId) {
      if (scene?.data?.clippingPlanes?.length > 0) {
        dispatch(setClippingPlanes(scene.data.clippingPlanes));
        caplaEditor?.editor3d?.clipper.initSceneClippingPlanes(
          scene.data.clippingPlanes
        );
      }
    }
  }, [scene?.clientId]);

  // initial settings

  // image overlay

  useEffect(() => {
    if (imageOverlayMode) {
      caplaEditor?.editor3d?.imageOverlay.show();
    }
  }, [Boolean(caplaEditor?.editor3d?.imageOverlay)]);

  // landing page

  useEffect(() => {
    if (caplaEditor?.editor3d?.sceneLanding && Boolean(landingPage)) {
      caplaEditor?.editor3d.startFlashDarkMode();
      caplaEditor?.editor3d.sceneLanding.enable();
    }
  }, [Boolean(landingPage), caplaEditor?.editor3d?.sceneLanding]);

  // horizontal clipping plane

  useHorizontalClippingPlane({editor3d: caplaEditor?.editor3d, scene});

  //
  // image models in scope
  //

  useEffect(() => {
    if (
      sceneStatus === "loaded" &&
      caplaEditor?.editor3d?.sceneEditor &&
      is3dEnabled &&
      !viewer // we disable auto / show models from hidden props for the viewer
    ) {
      models
        .filter((m) => m.type !== "MEASUREMENTS")
        .forEach((model) => {
          if (model.hidden) {
            caplaEditor?.editor3d.sceneEditor.hideModel(model?.id);
          } else {
            caplaEditor?.editor3d.sceneEditor.showModel(model?.id);
          }
        });
    }
  }, [
    is3dEnabled,
    hiddenModelsHash,
    sceneStatus,
    caplaEditor?.editor3d?.sceneEditor,
    selectedModelId,
  ]);

  //
  // FILTERS 3D mesurements in scope
  //

  const style = useSelector((s) => s.measurements.filteredMeasurementsViewMode);
  const applyFilters = useSelector((s) => s.measurements.applyFilters);
  const filterRefreshDate = useSelector(
    (s) => s.measurements.filterRefreshDate
  );
  const scopeMode = useMeasurementsScopeMode(scene, viewer);
  const [fmInViewer, fmIdsInViewer, fmIdsHashInViewer, filterHashInViewer] =
    useFilteredSceneMeasurements(
      scene,
      scopeMode,
      false,
      true,
      true,
      true,
      viewer
    );
  const [measurementsModelsInScope, ids, measurementsModelsInScopeHash] =
    useMeasurementsModelsInScope(scene);

  // case 1 - VIEWER -

  useEffect(() => {
    if (
      sceneStatus === "loaded" &&
      caplaEditor?.editor3d?.sceneEditor &&
      is3dEnabled &&
      viewer
      //(viewer || !viewer)
    ) {
      dispatch(
        setScopeMeasurementsCount(showMeasurements ? fmIdsInViewer.length : 0)
      );

      caplaEditor?.editor3d.sceneEditor.applyFiltersToMeasurementsByMeasurementIds(
        measurementsModelsInScope.map((m) => m.id),
        showMeasurements ? fmIdsInViewer : [],
        style,
        applyFilters
      );
    }
  }, [
    is3dEnabled,
    sceneStatus,
    caplaEditor?.editor3d?.sceneEditor,
    selectedModelId,
    style,
    viewer,
    applyFilters,
    filterRefreshDate,
    fmIdsHashInViewer,
    measurementsModelsInScopeHash,
    showMeasurements,
    selectedVersionForMeasurements?.id,
  ]);

  // case 2 - EDITOR -

  let measIds = useSelector((s) => s.measurements.measIdsShowedInViewer3d);

  if (!measIds) measIds = [];
  const measIdsHash = [...measIds].sort().join();

  let enabledPackageIds = useSelector(
    (s) => s.overviewer.enabledPackageIdsInMainScope
  );
  if (!enabledPackageIds) enabledPackageIds = [];
  const packIdsHash = [...enabledPackageIds].sort().join();

  useEffect(() => {
    if (
      sceneStatus === "loaded" &&
      caplaEditor?.editor3d?.sceneEditor &&
      is3dEnabled &&
      !viewer
      //false
    ) {
      console.log(
        "[EFFECT31] applyFiltersToMeasurements",
        enabledPackageIds?.length,
        measIds?.length
      );
      caplaEditor?.editor3d.sceneEditor.applyFiltersToMeasurementsByMeasurementIds(
        enabledPackageIds ? enabledPackageIds : [],
        showMeasurements ? measIds : [],
        style,
        applyFilters
      );
    }
  }, [
    is3dEnabled,
    sceneStatus,
    caplaEditor?.editor3d?.sceneEditor,
    style,
    viewer,
    filterRefreshDate,
    showMeasurements,
    measIdsHash,
    packIdsHash,
  ]);

  // init Camera on measurements

  const homeMeasurementsInit = useSelector((s) => s.ui.homeMeasurementsInit);
  const filtersAppliedToMeasurementsIn3DAt = useSelector(
    (s) => s.measurements.filtersAppliedToMeasurementsIn3DAt
  );
  const initZoomOutWithEntities = useSelector(
    (s) => s.viewer3D.initZoomOutWithEntities
  );

  useEffect(() => {
    if (
      caplaEditor?.editor3d?.sceneEditor &&
      homeMeasurementsInit &&
      !initZoomOutWithEntities
    ) {
      caplaEditor?.editor3d.sceneEditor.zoomOut();
      caplaEditor?.editor3d.sceneEditor.hideMarkersModels();
    }
  }, [
    initZoomOutWithEntities,
    homeMeasurementsInit,
    filtersAppliedToMeasurementsIn3DAt,
    caplaEditor?.editor3d?.sceneEditor,
  ]);

  return !editorLoaded ? (
    <div />
  ) : (
    <Root>
      {/* {false && !story && !mobile && !viewer && !space && !landingPage && (
        <SideBar caplaEditor={caplaEditor?.editor3d} scene={scene} viewer={viewer} />
      )} */}

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative", // for positioning the config center frame
            flexGrow: 1,
            minWidth: 0,
            width: 1,
            boxSizing: "border-box",
            display: "flex",
            overflow: "hidden",
            //border: "3px solid yellow",
          }}
        >
          <Box
            sx={{
              //border: "3px solid green",
              flexGrow: 1,
              boxSizing: "border-box",
              //height: 1, // if height:1 => bug in safari. (height=0)
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                //border: "3px solid red",
                flexGrow: 1,
                height: 1,
                boxSizing: "border-box",
                position: "relative",
                zIndex: 0,
              }}
              onDragEnter={handleViewerDragEnter}
              onDragLeave={handleViewerDragLeave}
              onDrop={handleViewerDrop}
              onDragOver={handleViewerDragOver}
            >
              {mainSection && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    bgcolor: "common.white",
                    zIndex: 60,
                  }}
                >
                  <Box sx={{height: 1, width: 1}}>
                    {mainSection === "SCREENSHOTVIEWER" &&
                      os.viewer3D && // idem, prevent 2 annotator objects.. mainSection==="SCREENSHOTVIEWER" when screenshot from .pdf
                      viewMode !== "PDF" && ( // to prevent 2 annotator objects in paralled => pb.
                        <SectionScreenshotViewer
                          scene={scene}
                          editor3d={caplaEditor.editor3d}
                          editorPdf={caplaEditor.editorPdf}
                          viewer={viewer}
                          mobile={mobile}
                        />
                      )}
                  </Box>
                </Box>
              )}

              {/* DOUTE !!! 
                <ProcessPick editor3d={caplaEditor?.editor3d} viewer={viewer} /> */}
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  width: 1,
                  zIndex: 10,
                }}
              >
                <Header
                  editor3d={caplaEditor?.editor3d}
                  config={configuring}
                  story={story}
                />
              </Box>
              {multiviews && show3D && !mobile && (
                <Box
                  sx={{
                    zIndex: 30,
                    position: "absolute",
                    bottom: (theme) => theme.spacing(2),
                    left: deltaX + 16,
                    //right: 8,
                    bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
                    borderRadius: 4,
                  }}
                >
                  <ToggleButtonWalkMode editor3d={caplaEditor?.editor3d} />
                </Box>
              )}

              {multiviews && show3D && (
                <Box
                  sx={{
                    position: "absolute",
                    top: (theme) => theme.spacing(16),
                    bottom: (theme) => theme.spacing(16),
                    zIndex: 2,
                    right: -9,
                  }}
                >
                  <SliderCamVerticalAngle />
                </Box>
              )}
              {multiviews && show3D && (
                <Box
                  sx={{
                    position: "absolute",
                    top: (theme) => theme.spacing(1),
                    left: deltaX + 8,
                    zIndex: 15,
                    display: "flex",
                    "&>*:not(:last-child)": {
                      mr: 2,
                    },
                  }}
                >
                  <ButtonCenterCam2 editor3d={caplaEditor?.editor3d} />
                </Box>
              )}
              {multiviews && show3D && (
                <Box
                  sx={{
                    position: "absolute",
                    top: (theme) => theme.spacing(1),
                    left: deltaX - 38,
                    zIndex: 15,
                  }}
                >
                  <ButtonCenterCam1 editor3d={caplaEditor?.editor3d} />
                </Box>
              )}
              {!landingPage && (
                <LayerDefault
                  caplaEditor={caplaEditor}
                  scene={scene}
                  viewer={viewer}
                />
              )}

              {viewer && (
                <ScreenLoadingModelsInViewer3D
                  scene={scene}
                  caplaEditor={caplaEditor}
                />
              )}

              <View3D
                editor3d={caplaEditor?.editor3d}
                ortho={isOrthoObject?.editor}
              />
              <ScreenLoadingPackageInViewer3d
                scene={scene}
                caplaEditor={caplaEditor}
              />
            </Box>
          </Box>
          <SectionDatasets
            caplaEditor={caplaEditor}
            viewer={viewer}
            scene={scene}
          />
        </Box>
      </Box>
    </Root>
  );
}
