// DEPRECATED NEARLY

import {Box, Typography} from "@mui/material";

//import BoxNavigationSceneModule from "./BoxNavigationSceneModule";

import usePageType from "../usePageType";

// import BoxNavigationSceneName from "Features/scenes/components/BoxNavigationSceneName";
import BoxNavigationColoringName from "Features/colorings/components/BoxNavigationColoringName";

export default function TitleTopBar({scene, viewer}) {
  // data

  const pageType = usePageType();

  // strings

  const myBimboxesString = "Mes bimboxes";
  const createSceneString = "Bimbox Creation Assistant";
  const assistantString = "Bimbox Creation Assistant";
  const profileString = "Profile";
  const toolboxString = "Toolbox";
  const coloringsString = "My colorings";
  const worksitesString = "Worksites";
  const libraryString = "Library";

  // helpers - components

  let title = {
    HOME: myBimboxesString,
    NEW_SCENE: createSceneString,
    ASSISTANT: assistantString,
    PROFILE: profileString,
    TOOLBOX: toolboxString,
    COLORINGS: coloringsString,
    WORKSITES: worksitesString,
    LIBRARY: libraryString,
  };

  return (
    <Box>
      {viewer && (
        <Typography
          sx={{
            fontWeight: "bold",
            color: "inherit",
          }}
        >
          {scene?.title}
        </Typography>
      )}
      {pageType === "HOME" && <Typography>{title["HOME"]}</Typography>}

      {pageType === "NEW_SCENE" && (
        <Typography>{title["NEW_SCENE"]}</Typography>
      )}

      {pageType === "ASSISTANT" && (
        <Typography>{title["ASSISTANT"]}</Typography>
      )}

      {pageType === "PROFILE" && <Typography>{title["PROFILE"]}</Typography>}

      {pageType === "TOOLBOX" && <Typography>{title["TOOLBOX"]}</Typography>}

      {pageType === "COLORINGS" && (
        <Typography>{title["COLORINGS"]}</Typography>
      )}

      {pageType === "COLORING" && <BoxNavigationColoringName scene={scene} />}

      {pageType === "WORKSITES" && (
        <Typography>{title["WORKSITES"]}</Typography>
      )}

      {pageType === "LIBRARY" && (
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography>{title["LIBRARY"]}</Typography>
          <Box
            sx={{
              height: 24,
              borderRadius: 12,
              width: 50,
              bgcolor: "common.yellow",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: 2,
            }}
          >
            <Typography sx={{fontSize: 13}}>beta</Typography>
          </Box>
        </Box>
      )}

      {/* {pageType === "SCENE" && (
        <BoxNavigationSceneName scene={scene} viewer={viewer} />
      )} */}
    </Box>
  );
}
