import { useSelector } from "react-redux";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useElementTypesSceneQuantities from "Features/elementTypes/hooks/useElementTypesQuantities";
// import elementTypesForDataGrid from "Features/elementTypes/utils/elementTypesForDataGrid";
import sortByNum from "Features/elementTypes/utils/sortByNum";
import useMeasurementsByElementType from "./useMeasurementsByElementType";
import getItemsMapById from "Utils/getItemsMapById";
// import getTitleTypesFromMeasurements from "./utils/getTitleTypesFromMeasurements";

export default function useMeasurementsByElementTypeForDataGrid(scene, mode) {
  // data

  let typesWithMeasurements = useMeasurementsByElementType(scene, mode);
  const elementTypesQuantities = useElementTypesSceneQuantities(scene, mode);
  const elementTypes = useElementTypesBySceneProxy(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // helper - model & presets

  const typesById = getItemsMapById(elementTypes);

  function getElementTypeQuantity(t) {
    let q;
    const length = !filterNoVoids && t.lengthNet ? t.lengthNet : t.length;
    const area = !filterNoVoids && t.areaNet ? t.areaNet : t.area;
    const volume = !filterNoVoids && t.volumeNet ? t.volumeNet : t.volume;
    if (t?.unit === "UN") q = t.count;
    if (t?.unit === "ML") q = length;
    if (t?.unit === "M2") q = area;
    if (t?.unit === "M3") q = volume;
    if (q !== 0 && !q) q = "-";
    return q;
  }

  function getElementTypeQuantityRatio(t) {
    const totalQuantities = elementTypesQuantities[t.id];
    const measurementsQuantity = getElementTypeQuantity(t);
    let ratio = totalQuantities?.mainQuantity
      ? measurementsQuantity / totalQuantities.mainQuantity
      : 0;
    if (isNaN(ratio)) ratio = 0;
    //return (ratio * 100).toFixed(1) + "%";
    return ratio;
  }

  // export - restricted to created measurements

  let rows = typesWithMeasurements.map((m) => {
    const t = typesById[m.elementTypeId];
    return {
      id: t?.id,
      num: t?.num ? t.num.toString() : "0",
      type: t?.name ? t.name : "-",
      count: m?.count ?? 0,
      unit: t?.unit,
      quantity: getElementTypeQuantity(m),
      ratio: getElementTypeQuantityRatio(m),
      group: t?.group ? t.group : "-",
      style: t?.style,
      isTitle: t?.isTitle,
      group: t?.group,
    };
  });

  // // add parents
  // const titleTypes = getTitleTypesFromMeasurements(measurements, elementTypes);
  // rows.push(
  //   ...titleTypes.map((t) => ({...t, type: t.name, quantity: "", unit: ""}))
  // );

  // sort & filter
  rows
    .filter((r) => r.id)
    .sort((a, b) => a.group - b.group || sortByNum(a.num, b.num));

  // add groups
  const groups = [];
  const rowsWithGroups = [];
  rows.forEach((row) => {
    if (groups.includes(row.group)) {
      rowsWithGroups.push(row);
    } else if (row.group) {
      groups.push(row.group);
      rowsWithGroups.push({ id: row.group, type: row.group, isGroup: true });
      rowsWithGroups.push(row);
    }
  });

  // export - all types

  return rowsWithGroups;
}
