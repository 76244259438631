export default function getGroupObjectsFromRessources(ressources, options) {
  let groups = [];
  const groupsMap = {};

  const sortByName = options?.sortByName;

  ressources.forEach((ressource) => {
    const exists = groupsMap[ressource.groupId];
    //
    if (!exists) {
      groups.push({
        id: ressource.groupId,
        name: ressource.group,
        fromScene: ressource.fromScene,
      });
      groupsMap[ressource.groupId] = "ok";
    }
  });

  if (sortByName)
    groups.sort((a, b) =>
      a.name && b.name ? a.name.localeCompare(b.name) : 1
    );

  return groups;
}
