import {useState} from "react";

import {Box, Paper, Typography} from "@mui/material";

import ListNomenclatures from "./ListNomenclatures";
import IconButtonDialogCreateNomenclature from "./ButtonDialogCreateNomenclature";
import DialogEditNomenclature from "./DialogEditNomenclature";

export default function CardNomenclatures({
  nomenclatures = [],
  onNomenclatureCreated,
  onNomenclatureDeleted,
  onNomenclatureUpdated,
}) {
  // strings

  const title = "Nomenclatures";

  // state

  const [nomenclature, setNomenclature] = useState(null);
  const [openDialogEditNomenclature, setOpenDialogEditNomenclature] =
    useState(false);

  // handlers

  function handleNomenclatureClick(nomenclature) {
    setNomenclature(nomenclature);
    setOpenDialogEditNomenclature(true);
  }

  function handleNomenclatureUpdated(nomenclature) {
    setOpenDialogEditNomenclature(false);
    onNomenclatureUpdated(nomenclature);
  }

  //

  return (
    <>
      {openDialogEditNomenclature && (
        <DialogEditNomenclature
          open={openDialogEditNomenclature}
          onNomenclatureUpdated={handleNomenclatureUpdated}
          nomenclature={nomenclature}
          onClose={() => setOpenDialogEditNomenclature(false)}
        />
      )}
      <Paper sx={{width: 1}}>
        <Box
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">{title}</Typography>
          <IconButtonDialogCreateNomenclature
            onNomenclatureCreated={onNomenclatureCreated}
          />
        </Box>
        <Box sx={{width: 1}}>
          <ListNomenclatures
            nomenclatures={nomenclatures}
            onClick={handleNomenclatureClick}
          />
        </Box>
      </Paper>
    </>
  );
}
