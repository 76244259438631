import {Box} from "@mui/material";

import SectionActionsArticles from "./SectionActionsArticles";

export default function SectionLeftPanelActionsArticles({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = [
    "createArticlesGroup",
    "createArticle",
    "refreshQties",
    "exportExcelArticles",
    "importExcelArticles",
  ];
  const actionsMoreKeys = [
    "forceLastUpdate",
    "resetProxies",
    "autoUpdateArticles",
    "divider",
    "cleanArticles",
    "divider",
    "deleteArticlesGroup",
    "deleteAllArticles",
    "deleteAllArticleTypes",
    "deleteArticle",
  ];

  return (
    <Box>
      <SectionActionsArticles
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
