import React from "react";

import {Box} from "@mui/material";

import CardProfile from "Features/profile/components/CardProfile";

export default function Profile() {
  return (
    <Box>
      <Box sx={{pt: "60px"}} />
      <Box sx={{p: 2}}>
        <CardProfile />
      </Box>
    </Box>
  );
}
