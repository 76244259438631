import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setRelationsTypesRessources} from "Features/relations/relationsSlice";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getElementTypesRelationsFromFile from "Features/excel/utils/getElementTypesRelationsFromFile";
import getItemsMapById from "Utils/getItemsMapById";

import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import useUpdateSceneElementType from "../hooks/useUpdateSceneElementType";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import getMeasurementsFromPackages from "Features/measurements/utils/getMeasurementsFromPackages";

export default function DialogImportTypesRelations({
  open,
  onClose,
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Importez des relations";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";
  const buttonName = "Choisir un fichier";

  //const measurements = caplaEditor?.measDataManager.getAllMeasurements(); // data - super dangerous !! => to change with measFromPackages.

  const packages = usePackagesByScene(scene);
  const measurements = getMeasurementsFromPackages(packages);

  // data

  const relations = useSelector((s) => s.relations.relationsTypesRessources);
  const ressources = useRessourcesByScene(scene);
  const allTypes = useElementTypesBySceneProxy(scene);
  const updateElementTypesGroup = useUpdateElementTypesGroup();
  const selectedGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const updateElementType = useUpdateSceneElementType(scene);

  // helpers - types

  let types = allTypes.filter(
    (t) =>
      (t.groupId && t.groupId === selectedGroup?.id) ||
      (!t.groupId && t.group === selectedGroup?.name)
  );

  // helpers

  const elementTypesById = getItemsMapById(allTypes);
  const ressourcesById = getItemsMapById(ressources);

  // state

  const [file, setFile] = useState(null);
  const [editedRelations, setEditedRelations] = useState(null);

  // handlers - updateTypesByRelations

  async function updateTypesByRelations(editedRelations) {
    const groupTypes = types.filter(
      (t) => t.groupId === selectedGroup.id || t.group === selectedGroup.name
    );
    const editedTypes = [];
    const modelIds = new Set();
    const measurementsToUpdate = [];
    groupTypes.forEach((t) => {
      const editedType = {...t};
      const itemRelationKeys = Object.keys(editedRelations).filter((k) =>
        k.startsWith(editedType.id)
      );
      editedType.res = itemRelationKeys.map((k) => editedRelations[k]);
      if (selectedGroup.fromScene) updateElementType(editedType);
      else editedTypes.push(editedType);
      elementTypesById[t.id] = editedType;
      measurements
        .filter((m) => m.elementTypeId === editedType.id)
        .forEach((m) => {
          measurementsToUpdate.push({
            ...m,
            res: editedType?.res
              ? editedType.res.map(({resId}) => ({resId, off: false}))
              : [],
          });
          modelIds.add(m.measurementsModelId);
        });
    });
    const sceneData = {
      ressources,
      elementTypes: Object.values(elementTypesById),
    };
    caplaEditor?.measDataManager.updateMeasurements(measurementsToUpdate);
    for (const modelId of modelIds) {
      caplaEditor?.measDataManager.saveModificationsForModelById(
        modelId,
        sceneData
      );
    }
    if (!selectedGroup.fromScene) {
      const updatedGroup = {...selectedGroup, elementTypes: editedTypes};
      await updateElementTypesGroup(updatedGroup);
    }
    onClose();
  }

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    const newRel = await getElementTypesRelationsFromFile(
      file,
      ressourcesById,
      relations
    );
    setEditedRelations(newRel);
  }
  function handleCancelClick() {
    onClose();
  }
  function handleSaveClick() {
    updateTypesByRelations(editedRelations);
    dispatch(setRelationsTypesRessources(editedRelations));
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
