export default function getRelationsFromTypeIds(relationMap = {}, typeIds) {
  if (!typeIds) return [];
  const relationsArray = Object.values(relationMap ? relationMap : {});

  const result = relationsArray.filter((relation) => {
    let includeType = false;
    if (typeIds) {
      includeType = typeIds.includes(relation.typeId);
    }
    return includeType;
  });
  return result;
}
