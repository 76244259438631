// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "555f0cb2-4094-45b2-b0c7-24e333fb64e6",
    authority:
      "https://login.capla360.com/4c8104e2-9797-4f88-b558-c693accc14e4/B2C_1_signin-signup-1",
    knownAuthorities: ["capla360.b2clogin.com", "login.capla360.com"],
    redirectUri: process.env.REACT_APP_USERFLOW_REDIRECT_URI,
    //redirectUri: "https://notes.capla360.com/auth",
    //redirectUri: "https://capla360.b2clogin.com/capla360.onmicrosoft.com/oauth2/authresp",
    postLogoutRedirectUri: process.env.REACT_APP_USERFLOW_LOGOUT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  //scopes: ["User.Read"],
  extraQueryParameters: {ui_locales: "fr"},
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
