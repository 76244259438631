/*
 * phase : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useAddScenePhase(scene) {
  const dispatch = useDispatch();

  const addScenePhase = (phase) => {
    if (!scene) return;
    let oldPhases = scene?.data?.phases;
    if (!oldPhases) oldPhases = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, phases: [...oldPhases, phase]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return addScenePhase;
}
