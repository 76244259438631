import React from "react";

import {Box} from "@mui/material";

export default function SectionSceneOnboardingVideo({onboardingUrl}) {
  return (
    <Box sx={{width: 1, height: 1, bgcolor: "background.default"}}>
      <iframe
        src={onboardingUrl}
        allowFullScreen
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </Box>
  );
}
