import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

// import useSelectedSharedbox from "../hooks/useSelectedSharedbox";
import useUpdateSceneSharedbox from "../hooks/useUpdateSceneSharedbox";
import {
  setIsEditingSharedbox,
  setEditedSharedbox,
  setSelectedSharedbox,
  // setTempSharedboxTypes,
} from "../sharedboxesSlice";

export default function ActionsSharedboxInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // strings

  const saveS = "Enregistrer";
  const editS = "Editer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.sharedboxes.isEditingSharedbox);
  // const selectedSharedbox = useSelectedSharedbox(scene);
  const selectedSharedbox = useSelector((s) => s.sharedboxes.selectedSharedbox);
  const editedSharedbox = useSelector((s) => s.sharedboxes.editedSharedbox);
  const updateSharedbox = useUpdateSceneSharedbox(scene?.id);

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingSharedbox(true));
    dispatch(setEditedSharedbox({...selectedSharedbox}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingSharedbox(false));
  }

  function handleSaveClick() {
    const updatedSharedbox = {...editedSharedbox};
    updateSharedbox(updatedSharedbox);
    dispatch(setSelectedSharedbox(updatedSharedbox));
    dispatch(setIsEditingSharedbox(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
