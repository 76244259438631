import {Box, Chip} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function ButtonFocusRessourceMeasurementsIn3d({
  scene,
  ressource,
  editor3d,
}) {
  // strings

  const zoomS = "3D";

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);

  // handlers

  function handleClick() {
    const types = elementTypes.filter(
      (type) =>
        type.res?.filter(({resId}) => resId === ressource.id)?.length > 0
    );
    editor3d?.sceneEditor.focusElementTypes(types.map((t) => t.id));
  }
  return (
    <Box sx={{color: "primary.flash"}}>
      <Chip size="small" onClick={handleClick} label={zoomS} icon={<Focus />} />
    </Box>
  );
}
