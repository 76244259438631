import {useSelector} from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import ToolbarSectorsMain from "Features/sectors/components/ToolbarSectorsMain";
import DataGridSectors from "Features/sectors/components/DataGridSectors";
import SectionTitleSectors from "Features/sectors/components/SectionTitleSectors";
import useIssuesetsInScope from "Features/issues/useIssuesetsInScope";
import Stretch from "Features/ui/components/Stretch";

export default function SceneSectors() {
  // params

  const {scene, caplaEditor} = useOutletContext();

  // data

  const [_, __, modelsIdsHash] = useIssuesetsInScope();

  // helpers

  const openSections = useSelector((s) => s.ui.openSections);
  const osHash = Object.values(openSections).join("-");

  if (openSections.outlet)
    return (
      <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
        <Box sx={{height: "68px"}} />
        <SectionTitleSectors />
        <ToolbarSectorsMain scene={scene} />
        <Stretch propDependency={modelsIdsHash + osHash}>
          <Box sx={{height: 1, width: 1}}>
            <DataGridSectors scene={scene} />
          </Box>
        </Stretch>
      </Box>
    );
  return <></>;
}
