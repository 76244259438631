import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedElementTypesGroupProxy} from "../elementTypesSlice";
import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyBySceneWithElementTypes";

import {
  setSelectedElementTypeGroup,
  setSelectedTypesInDatagrid,
} from "Features/measurements/measurementsSlice";

import DialogCreateElementTypeGroup from "./DialogCreateElementTypeGroup";
import SelectorGenericItemWithChips from "Components/SelectorGenericItemWithChips";
import {useIsAuthenticated} from "@azure/msal-react";

export default function SelectorElementTypesGroupProxy({scene}) {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  // strings

  const createLabel = "Créer un nouveau groupe";

  // data

  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene); // {id,name,fromScene}

  const selectedGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  // helper canCreate

  const canCreate = isAuthenticated && scene?.id;

  // helpers

  const value = selectedGroup ? selectedGroup : null;
  const options = groups;

  // effects

  useEffect(() => {
    if (options.length > 0 && !value) {
      dispatch(setSelectedElementTypesGroupProxy(options[0]));
      dispatch(setSelectedElementTypeGroup(options[0].name));
    }
  }, []);

  // handlers

  function handleCreate() {
    setOpenDialogCreate(true);
  }

  function handleCloseDialogCreate() {
    setOpenDialogCreate(false);
  }

  function handleGroupClick(group) {
    const proxy = group ? group : null;
    dispatch(setSelectedElementTypesGroupProxy(proxy));
    dispatch(setSelectedElementTypeGroup(proxy?.name)); // ?
    dispatch(setSelectedTypesInDatagrid([])); // ? in the datagrid, the selection should be managed from the redux state.
  }

  function handleOnCreation(group) {
    if (!group) return;
    dispatch(
      setSelectedElementTypesGroupProxy({id: group.id, name: group.name})
    );
  }

  return (
    <>
      <Box sx={{width: 1, display: "flex", flexDirection: "column"}}>
        <SelectorGenericItemWithChips
          items={options}
          selectedItem={value}
          onClick={handleGroupClick}
          onCreate={canCreate && handleCreate}
          createLabel={createLabel}
          maxHeight={200}
        />
        <DialogCreateElementTypeGroup
          open={openDialogCreate}
          onClose={handleCloseDialogCreate}
          scene={scene}
          onCreate={handleOnCreation}
        />
      </Box>
    </>
  );
}
