import {useSelector, useDispatch} from "react-redux";

import useGetFilteredMeasurements from "./useGetFilteredMeasurements";
import {
  setMeasIdsShowedInViewer3d,
  setMeasShowedInViewer3d,
  setMeasurementsShowedInViewer3dWithoutShortcut,
} from "./measurementsSlice";
import {
  setMeasurementsShowedInViewerPdf,
  setMeasurementsShowedInViewerPdfWithoutShortcut,
} from "Features/pdf/pdfSlice";

export default function useUpdateMeasurementsShowedInViewers(
  scene,
  caplaEditor
) {
  //
  const dispatch = useDispatch();

  const pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // const packages = usePackagesByScene(scene, options);
  // const packagesPdf = usePackagesByScene(scene, optionsPdf);

  // const measurements = getMeasurementsFromPackages(packages);
  // const measurementsPdf = getMeasurementsFromPackages(packagesPdf);

  const measurements = useSelector((s) => s.measurements.items).filter(
    (m) => m?.id
  );

  const getFilteredMeasurements = useGetFilteredMeasurements({
    scene,
    applySelection: true,
    applyShortcuts: true,
    keepDeletedMeas: false,
  });
  const getFilteredMeasurementsWithoutShortcut = useGetFilteredMeasurements({
    scene,
    applySelection: true,
    applyShortcuts: false,
  });

  let fmsIn3d = getFilteredMeasurements(measurements);
  let fmsIn3dWithoutShortcut =
    getFilteredMeasurementsWithoutShortcut(measurements);

  let fmsInPdf = fmsIn3d.filter((m) => m?.pdfModelId === pdfModelId);
  let fmsInPdfWithoutShortcut = fmsIn3dWithoutShortcut.filter(
    (m) => m?.pdfModelId === pdfModelId
  );

  //

  const fmIdsIn3d = fmsIn3d.map((m) => m?.id);

  //

  dispatch(setMeasIdsShowedInViewer3d(fmIdsIn3d));
  dispatch(setMeasShowedInViewer3d(fmsIn3d));
  dispatch(
    setMeasurementsShowedInViewer3dWithoutShortcut(fmsIn3dWithoutShortcut)
  );
  //
  dispatch(setMeasurementsShowedInViewerPdf(fmsInPdf));
  dispatch(
    setMeasurementsShowedInViewerPdfWithoutShortcut(fmsInPdfWithoutShortcut)
  );

  //
}
