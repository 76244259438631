import fillWorksheetWithRessourcesByRoom from "./fillWorksheetWithRessourcesByRoom";

export default function addRessourcesByRoomsWorksheet(
    workbook,
    worksheetTemplate,
    name,
    ressourcesByRoom,
    ressourcesPU,
  ) {
    const worksheet = workbook.addWorksheet(name.slice(0, 31));
    if (worksheetTemplate?.model) worksheet.model = worksheetTemplate.model;
    worksheet.name = name.slice(0, 31);

    worksheet.getRow(1).getCell(1).value = name ? name : "-?-";
    
    let hasPU;
    if (ressourcesPU && ressourcesPU[name.replace(" par zones", "")])
      hasPU = true;

    fillWorksheetWithRessourcesByRoom({
      worksheet,
      ressourcesByRoom,
      rowStartIndex: 3,
      ressourcesPU,
      hasPU,
    });

    worksheet.mergeCells(1, 0, 1, hasPU ? 6 : 4);

    return worksheet;
}