import transferElementTypeArticleQtiesToChildren from "Features/articles/utils/transferElementTypeArticleQtiesToChildren";
export default function getElementTypesByGroupIdById(groupsWithElementTypes) {
  const typesMap = {};
  //
  if (!Array.isArray(groupsWithElementTypes)) return typesMap;
  //
  groupsWithElementTypes.forEach((group) => {
    typesMap[group.id] = {};
    if (Array.isArray(group.elementTypes)) {
      group.elementTypes.forEach((type, index) => {
        typesMap[group.id][type.id] = {...type, index}; // the index is used to get children of one element.
      });
    }
  });
  //
  return typesMap;
}
