export default function getElementTypesWithMainQties(
  elementTypes,
  measurements,
  filterNoVoids
) {
  if (!Array.isArray(measurements) || !Array.isArray(measurements)) {
    console.log("ERROR - empty list of elementTypes or measurements");
    return [];
  }
  const q = {};
  for (let m of measurements) {
    let qItem = q[m.elementTypeId];
    let length = m.length;
    let area = m.area;
    let volume = m.volume;
    if (filterNoVoids !== true && m.lengthNet) {
      length = m.lengthNet;
      area = m.areaNet;
      volume = m.volumeNet;
    }
    if (!qItem) {
      q[m.elementTypeId] = {
        measCount: 1,
        count: 1,
        length: length,
        area: area,
        volume: volume,
      };
    } else {
      q[m.elementTypeId] = {
        measCount: 1 + qItem.measCount,
        count: 1 + qItem.count, // ?? why not m.count ??
        length: length + qItem.length,
        area: area + qItem.area,
        volume: volume + qItem.volume,
      };
    }
  }

  const unitMap = {
    UN: "u",
    ML: "m",
    M2: "m²",
    M3: "m³",
  };

  const result = elementTypes.map((elementType) => {
    const value = q[elementType.id];

    let mainQ = value ? value.count : null;
    const mainU = unitMap[elementType?.unit] ? unitMap[elementType?.unit] : "-";

    if (value) {
      if (elementType?.unit === "ML") {
        mainQ = value.length;
      }
      if (elementType?.unit === "M2") {
        mainQ = value.area;
      }
      if (elementType?.unit === "M3") {
        mainQ = value.volume;
      }
      if (elementType?.unit === "UN") {
        mainQ = value.count;
      }
    }

    return {
      ...elementType,
      mainQuantity: mainQ,
      mainUnit: mainU,
      measCount: value ? value.measCount : 0,
    };
  });

  return result;
}
