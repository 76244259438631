import {Button} from "@mui/material";
import {AutoFixHigh as Enhance} from "@mui/icons-material";

import * as Excel from "exceljs";

// import rawDataToObjectsWorksheet2 from "./utils/rawDataToObjectsWorksheet2";
// import reduceObjects from "./utils/reduceObjects";

import {blobToArrayBuffer} from "Database/utils";
import {urlToBlob, saveBlob} from "Features/files/utils";
import templateDPGF from "Features/excel/assets/template-dpgf.xlsx";

export default function ButtonMakePolyvalReady({file}) {
  // strings

  const enhanceS = "Export Polyval";

  // helper

  const fileName = file?.name ? `${file.name} [DPGF].xlsx` : "";

  // handlers

  async function handleClick() {
    // // load dst
    // const dstWB = new Excel.Workbook();
    // const blob = await urlToBlob(templateDPGF);
    // const arrayBuffer = await blobToArrayBuffer(blob);
    // await dstWB.xlsx.load(arrayBuffer);
    // const dstWS = dstWB.worksheets[0];

    // // load src
    // const srcWB = new Excel.Workbook();
    // const arrayBuffer2 = await blobToArrayBuffer(file);
    // await srcWB.xlsx.load(arrayBuffer2);
    // const srcWS = srcWB.worksheets[1];

    // // load data
    // const data = [];
    // srcWS.eachRow(function (row, rowNumber) {
    //   const rowValues = row.values;
    //   const rowStyle = row.getCell(1).style;
    //   data.push({rowValues, rowStyle});
    // });
    // // const rawObjects = rawDataToObjectsWorksheet2(data);
    // // const objects = reduceObjects(rawObjects);
    // let objects;

    // // write data
    // objects.forEach(
    //   (
    //     {
    //       // rawIndex,
    //       type,
    //       num,
    //       name,
    //       // srcName,
    //       quantity,
    //       // srcQuantity,
    //       // unit,
    //       unitS,
    //       // height,
    //     },
    //     index
    //   ) => {
    //     const row = dstWS.getRow(3 + index);

    //     row.getCell(2).value = name;

    //     if (type === "ARTICLE" || type === "ARTICLE_PROXY") {
    //       row.getCell(1).value = num;
    //       row.getCell(3).value = unitS;
    //       row.getCell(4).value = quantity;
    //       //row.getCell(5).value = height;
    //       //row.getCell(6).value = srcQuantity;
    //     }
    //     //style
    //     if (type === "TITLE") row.getCell(2).font = {bold: true};
    //   }
    // );

    // // export
    // const newBuffer = await dstWB.xlsx.writeBuffer();
    // const newBlob = new Blob([newBuffer], {type: "application/octet-stream"});
    // saveBlob(newBlob, fileName);
  }

  return (
    <Button
      variant="contained"
      size="small"
      startIcon={<Enhance />}
      onClick={handleClick}
    >
      {enhanceS}
    </Button>
  );
}