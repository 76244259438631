import getUpdatedMeasurementsFromTypes from "../utils/getUpdatedMeasurementsFromTypes";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {updateModelNotAsync} from "Features/viewer3D/viewer3DSlice";

import {useDispatch} from "react-redux";

import getItemsMapById from "Utils/getItemsMapById";

export default function useUpdateRessourcesFromTypes({
  caplaEditor,
  packages,
  elementTypes,
  ressources,
  zones,
  rooms,
  sectors,
  filterNoVoids,
}) {
  const dispatch = useDispatch();

  const elementTypesById = getItemsMapById(elementTypes);
  const ressourcesById = getItemsMapById(ressources);
  const zonesById = getItemsMapById(zones);
  const roomsById = getItemsMapById(rooms);
  const sectorsById = getItemsMapById(sectors);

  const updateRessourcesFromTypes = async (packagesIdsToUpdate) => {
    for (const packageId of packagesIdsToUpdate) {
      const thePackage = packages.find((p) => p.id === packageId);
      if (!thePackage.archived && thePackage.enabled) {
        const usedTypesGroups = new Set();
        const usedRessourcesGroups = new Set();
        const [newMs, usedTypes] = getUpdatedMeasurementsFromTypes({
          caplaEditor,
          thePackage,
          elementTypesById,
          ressourcesById,
          zonesById,
          roomsById,
          sectorsById,
          filterNoVoids,
        });
        usedTypes.forEach((t) => {
          usedTypesGroups.add(t.groupId);
          t.res?.forEach((r) => {
            const ressource = ressourcesById[r.resId];
            if (ressource) usedRessourcesGroups.add(ressource.group);
          });
        });
        const updatedModel = {
          ...thePackage,
          measCount: newMs.length,
          elementTypesGroupIds: [...usedTypesGroups],
          ressourcesGroupIds: [...usedRessourcesGroups],
          measurementsData: {
            ...thePackage.measurementsData,
            measurements: newMs,
          },
        };
        const updates = {
          id: updatedModel.id,
          measurementsData: updatedModel.measurementsData,
        };
        const sceneData = {elementTypes, ressources};
        dispatch(updateModelNotAsync({updates, sync: true, sceneData}));
        // await caplaEditor?.dispatch(updateModel({updatedModel, sync: true}));
        caplaEditor?.measDataManager.createOrUpdateModelInManager(updatedModel);
      }
    }
  };
  return updateRessourcesFromTypes;
}
