import {useEffect} from "react";

import {Paper, Box} from "@mui/material";

// import {Vector3} from "three";

import CardClippingPlanes from "./components/CardClippingPlanes";
import CardAddClippingPlane from "./components/CardAddClippingPlane";

import TitleCloseHeader from "Components/TitleCloseHeader";

export default function ToolClipping({editor3d, onClose, scene}) {
  // const clippingPlaneRef = useRef();

  // strings

  const title = "Clipping";

  // local state

  // const [_, setAdding] = useState(false);

  // handlers

  function handleClose() {
    if (onClose) onClose();
  }

  // function handleEditorClick(o) {
  //   if (o?.pickedFaceNormal) {
  //     const normal = new Vector3();
  //     normal.copy(o.pickedFaceNormal).negate();
  //     const origin = new Vector3(o.sceneP.x, o.sceneP.y, o.sceneP.z);
  //     editor3d?.clipper.createPlane({origin, normal});
  //     editor3d?.onProcessEditorClick = console.log;
  //   }

  //   setAdding(false);
  // }

  // function handleAddPlaneClick() {
  //   setAdding(true);
  //   editor3d?.onProcessEditorClick = handleEditorClick;
  // }

  // unmount

  useEffect(() => {
    return () => {
      editor3d?.onProcessEditorClick(console.log);
    };
  }, []);

  return (
    <Paper sx={{bgcolor: "background.default"}}>
      <TitleCloseHeader title={title} onClose={handleClose} divider={false} />
      <Box sx={{p: 1}}>
        <CardAddClippingPlane editor3d={editor3d} scene={scene} />
      </Box>
      <Box sx={{p: 1}}>
        <CardClippingPlanes editor3d={editor3d} scene={scene} />
      </Box>
    </Paper>
  );
}
