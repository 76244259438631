import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Paper,
  IconButton,
  // Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  Error as Issues,
  Home,
  Refresh,
} from "@mui/icons-material";

import SelectorDatagridMode from "./SelectorDatagridMode";

import {
  setLeftPanelSection,
  setLeftPanelSelection,
  setShowLeftSelectionInViewer,
} from "../leftPanelSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import IconModuleInLeftPanel from "Features/ui/components/IconModuleInLeftPanel";
import {setLastPackageId} from "Features/elementPackages/packagesSlice";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";
import {
  setScopeSelectionMode,
  setScopeInputId,
  setSectorsInScope,
  setMeasurementsModelsInScope,
  setRoomsInScope,
  setFilters,
  setSelectedRessourcesInDatagrid,
  setShortcutElementTypes,
  setSelectedMeasurementIds,
  setSelectedMeasurementsModelId,
  setSelectedRessourceGroup,
  setFilteredMeasurementsViewModeBckp,
  setFilteredMeasurementsViewMode,
} from "Features/measurements/measurementsSlice";
import {setBuildingInScope} from "Features/buildings/buildingsSlice";
import {
  setOpenIssuesInLeftPanel,
  setSelectedIssueId,
} from "Features/issues/issuesSlice";
import {
  setSelectedIssueId as setSelectedIssueIdInModule,
  setShortcutToolViewer3D,
} from "Features/ui/uiSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import {setShortcutElevationZ} from "Features/sectors/sectorsSlice";
import {setSelectedRessourceId} from "Features/ressources/ressourcesSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import {setSelectedShortcutRight} from "Features/shortcut/shortcutSlice";
import {setSelectedPhaseIds} from "Features/phases/phasesSlice";
import SelectorVersionForMeasurements from "Features/versions/components/SelectorVersionForMeasurements";

import useSceneHasVersions from "Features/versions/hooks/useSceneHasVersions";
import {fetchRemoteScene} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";

import IconButtonRefreshInLeftPanel from "./IconButtonRefreshInLeftPanel";

import {
  fetchArticlesGroups,
  fetchArticles,
} from "Features/articles/articlesSlice";
import ButtonSyncArticles from "Features/articles/components/ButtonSyncArticles";

// import SelectorShowHelpers from "Features/helpers3d/components/SelectorShowHelpers";

export default function HeaderInLeftPanel({scene, caplaEditor, viewer}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  // strings

  const allS = "Tous les éléments";

  // data

  const sceneModule = useSceneModule();
  const namesMap = useSceneModuleNameMap();
  const models = useSelector((s) => s.viewer3D.models);
  const modelId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );
  let typeGroups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const types = useElementTypesBySceneProxy(scene);
  let ressourceGroups = useRessourceGroupsByScene(scene);
  const ressourceGroup = useSelector(
    (s) => s.measurements.selectedRessourceGroup
  );
  const defaultScope = useSelector(
    (s) => s.measurements.scopeDefaultSelectionMode
  );
  const inOutMode = useSelector(
    (s) => s.measurements.filteredMeasurementsViewMode
  );

  const sceneHasVersions = useSceneHasVersions(scene);

  const leftPanelSection = useSelector((s) => s.leftPanel.leftPanelSection);

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );

  // helper - showGroupSelectorInViewer

  const showGroupSelectorInViewer =
    viewer && leftPanelSection !== "ELEMENT_TYPES";

  // helpers

  let options = [];
  let selectedOption;
  if (sceneModule === "EDIT_MEASUREMENTS") {
    options = models.filter((m) => m.type === "MEASUREMENTS");
    const selectedMeasurementsModel = options.find((o) => o.id === modelId);
    if (!selectedMeasurementsModel) options = [];
    else {
      options = options.filter(
        (o) =>
          o.enabled &&
          o.fromModel.modelId === selectedMeasurementsModel.fromModel.modelId &&
          o.id !== selectedMeasurementsModel.id
      );
      if (selectedMeasurementsModel.restrictedTypes?.length > 0) {
        const restricted = new Set(selectedMeasurementsModel.restrictedTypes);
        typeGroups = typeGroups.filter((g) => restricted.has(g.id));
      }
    }
    options = options.sort((a, b) => a.name.localeCompare(b.name));
    selectedOption = selectedMeasurementsModel;
  } else if (sceneModule === "MEASUREMENTS") {
    options = [
      {id: null, name: allS},
      ...ressourceGroups.map((g) => {
        return {id: g, name: g};
      }),
    ];
    selectedOption = ressourceGroup
      ? {id: ressourceGroup, name: ressourceGroup}
      : options[0];
  }

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleBackClick() {
    handleReset();
    dispatch(setSelectedRessourceGroup(null));
    dispatch(setLeftPanelSection("SCENE"));
  }

  function handleIssueClick() {
    dispatch(setOpenIssuesInLeftPanel(true));
    dispatch(setFilteredMeasurementsViewModeBckp(inOutMode));
    dispatch(setFilteredMeasurementsViewMode("IN"));
  }

  function handleReset() {
    console.log("[click] reset from header");
    if (sceneModule === "RESSOURCES") {
      dispatch(fetchRemoteScene({sceneId, accessToken}));
    } else if (sceneModule === "ARTICLES") {
      dispatch(fetchArticlesGroups({sceneId, accessToken}));
      if (selectedArticlesGroupId)
        dispatch(
          fetchArticles({
            sceneId,
            articlesGroupId: selectedArticlesGroupId,
            accessToken,
          })
        );
    } else {
      dispatch(setScopeSelectionMode(defaultScope));
      dispatch(setScopeInputId(null));
      dispatch(setSectorsInScope([]));
      dispatch(setRoomsInScope([]));
      dispatch(setBuildingInScope(null));
      dispatch(setMeasurementsModelsInScope([]));
      dispatch(setFilters({reset: true}));
      dispatch(setShowLeftSelectionInViewer(false));
      dispatch(setLeftPanelSelection({}));
      dispatch(setSelectedRessourcesInDatagrid([]));
      dispatch(setSelectedRessourceId(null));
      dispatch(setSelectedIssueId(null));
      dispatch(setSelectedIssueIdInModule(null));
      dispatch(setShortcutElementTypes(types.map((t) => t.id)));
      dispatch(setShortcutElevationZ([]));
      dispatch(setSelectedPhaseIds([]));
      dispatch(setSelectedMeasurementIds([]));
      dispatch(setSelectedZoneId(null));
      dispatch(setShortcutToolViewer3D(null));
      dispatch(setSelectedShortcutRight(null));
      caplaEditor?.editor3d.sceneEditor.hideAllImageModels();
      if (caplaEditor?.openSections.fixedViewersBox)
        caplaEditor.editorPdf.webViewer.Core.annotationManager.deselectAllAnnotations();
      if (caplaEditor?.openSections.viewer3D)
        caplaEditor?.editor3d.sceneEditor.unselect();
    }
  }

  const handlePackageChange = (option) => {
    navigate(`bimboxes/${scene.id}/${option?.id}/measurements`);
    //dispatch(setLastPackageId(option?.id));
    dispatch(setSelectedMeasurementsModelId(option?.id));
    // caplaEditor?.measDataManager.setSelectedModelById(option?.id);
    setAnchorEl(null);
  };

  const handleArticleGroupChange = (option) => {
    if (option.name !== allS) dispatch(setSelectedRessourceGroup(option.name));
    else dispatch(setSelectedRessourceGroup(null));
    setAnchorEl(null);
  };

  const selectorCallback =
    sceneModule === "EDIT_MEASUREMENTS"
      ? handlePackageChange
      : handleArticleGroupChange;

  return (
    <Box sx={{p: 1, display: "flex", width: 1}}>
      <Paper
        elevation={12}
        sx={{
          p: 1,
          width: 1,
          height: (theme) => theme.spacing(5),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!viewer && <IconModuleInLeftPanel scene={scene} />}
        {viewer && (
          <IconButton onClick={handleBackClick}>
            <Home fontSize="small" />
          </IconButton>
        )}
        {!viewer &&
          !["EDIT_MEASUREMENTS", "MEASUREMENTS"].includes(sceneModule) && (
            <Typography sx={{fontWeight: "bold", fontSize: 15}}>
              {namesMap[sceneModule]}
            </Typography>
          )}
        {viewer && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {showGroupSelectorInViewer && (
              <Box
                sx={{maxWidth: sceneHasVersions ? 200 : 250, display: "flex"}}
              >
                <SelectorDatagridMode
                  scene={scene}
                  viewer={viewer}
                  caplaEditor={caplaEditor}
                />
              </Box>
            )}
            {sceneHasVersions && (
              <Box sx={{display: "flex", ml: 0.5}}>
                <SelectorVersionForMeasurements
                  scene={scene}
                  viewer={viewer}
                  caplaEditor={caplaEditor}
                />
              </Box>
            )}
          </Box>
        )}
        {false && sceneModule === "EDIT_MEASUREMENTS" && (
          <Box
            sx={{
              flewGrow: 1,
              display: "flex",
              alignItems: "center",
              minWidth: 0,
            }}
          >
            <Button
              fullWidth
              endIcon={<Down />}
              size="small"
              color="inherit"
              noWrap
              sx={{
                //width: 1,
                minWidth: 0,
                fontWeight: "bold",
                fontSize: 15,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <Typography noWrap>{selectedOption?.name}</Typography>
            </Button>
            <Menu
              open={open}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            >
              {options.map((option) => {
                return (
                  <MenuItem
                    key={option.id}
                    onClick={() => {
                      selectorCallback(option);
                    }}
                  >
                    <Typography variant="body2">{option.name}</Typography>
                  </MenuItem>
                );
              })}
              {/* <Box>
                <Divider sx={{my: 0.5}} />
                <SelectorShowHelpers scene={scene} caplaEditor={caplaEditor} />
              </Box> */}
            </Menu>
          </Box>
        )}
        {sceneModule === "MEASUREMENTS" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              fullWidth
              endIcon={<Down />}
              size="small"
              color="inherit"
              onClick={(e) => {
                if (options.length > 0) setAnchorEl(e.currentTarget);
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 15,
                  textOverflow: "ellipsis",
                  minWidth: 0,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "215px",
                }}
              >
                {selectedOption?.name}
              </Typography>
            </Button>
            <Menu
              open={open}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
            >
              {options.map((option, i) => {
                return (
                  <Box key={i}>
                    {i === 1 && <Divider key={"divider"} />}
                    <MenuItem
                      key={option.id}
                      onClick={() => {
                        selectorCallback(option);
                      }}
                    >
                      <Typography variant="body2">{option.name}</Typography>
                    </MenuItem>
                  </Box>
                );
              })}
            </Menu>
          </Box>
        )}
        {sceneModule === "ARTICLES" && <ButtonSyncArticles scene={scene} />}
        {([
          "EDIT_MEASUREMENTS",
          "MEASUREMENTS",
          "ELEMENT_TYPES",
          "RESSOURCES",
          //"ARTICLES",
          "ISSUES",
          "PACKAGES",
        ].includes(sceneModule) ||
          viewer) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {viewer && caplaEditor?.openSections.viewer3D && (
              <IconButton onClick={handleIssueClick}>
                <Issues fontSize="small" />
              </IconButton>
            )}
            <IconButton onClick={handleReset}>
              <Refresh fontSize="small" />
            </IconButton>
          </Box>
        )}
        {false && (
          <Box>
            <IconButtonRefreshInLeftPanel scene={scene} />
          </Box>
        )}
      </Paper>
    </Box>
  );
}
