import React from "react";

import {Box, IconButton, Typography} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function SectionInfo() {
  // strings

  const labelS = "Info";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "background.default",
        width: 1,
        px: 1,
        py: 0.5,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography sx={{fontSize: 13, fontWeight: "bold"}}>{labelS}</Typography>
    </Box>
  );
}
