import React, {useState} from "react";

import {readRemoteSceneService} from "Features/scenes/services";

import {Box, Typography, IconButton, CircularProgress} from "@mui/material";

import {LoadingButton} from "@mui/lab";
import {
  Cloud as Remote,
  Computer as Local,
  CloudUpload as Upload,
} from "@mui/icons-material";

import useSyncActions from "Features/scenes/useSyncActions";

import useAccessToken from "Features/auth/useAccessToken";

export default function ListHeader({scene}) {
  const accessToken = useAccessToken();

  // strings

  const localDataS = "Local data";
  const remoteDataS = "Remote data";

  const uploadS = "Upload";

  // data

  const [syncActions, triggerSyncAction] = useSyncActions(scene);
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleUploadClick() {
    if (!loading) {
      setLoading(true);
      for (let action of syncActions) {
        await triggerSyncAction(action, true); // force = true
      }
      setLoading(false);
    }
  }

  async function handleLogRemoteModels() {
    const remoteScene = await readRemoteSceneService({
      accessToken,
      sceneId: scene?.id,
    });
    console.log("remoteScene", remoteScene);
  }

  // helpers

  const variant = syncActions.length > 0 ? "contained" : "default";
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        pb: 1,
        mb: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          width: "45%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Local color="action" />
          <Typography variant="body2" noWrap sx={{fontWeight: "bold", ml: 2}}>
            {localDataS}
          </Typography>
        </Box>
        <LoadingButton
          loading={loading}
          startIcon={<Upload />}
          loadingPosition="start"
          onClick={handleUploadClick}
          variant={variant}
        >
          {uploadS}
        </LoadingButton>
      </Box>
      <Box sx={{width: "10%"}} />
      <Box sx={{width: "45%", display: "flex", alignItems: "center"}}>
        <Remote
          color="action"
          sx={{cursor: "pointer"}}
          onClick={handleLogRemoteModels}
        />
        <Typography variant="body2" noWrap sx={{fontWeight: "bold", ml: 2}}>
          {remoteDataS}
        </Typography>
      </Box>
    </Box>
  );
}
