import {useIsAuthenticated} from "@azure/msal-react";
import SignInButton from "./SignInButton";
import SignOutButtonSimple from "./SignOutButtonSimple";

const SignInSignOutButton = ({avatar, color}) => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <SignOutButtonSimple color={color} />;
  } else {
    return <SignInButton avatar={avatar} />;
  }
};

export default SignInSignOutButton;
