import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedRoom from "../hooks/useSelectedRoom";
import useUpdateSceneRoom from "../hooks/useUpdateSceneRoom";
import {setIsEditingRoom, setEditedRoom} from "../roomsSlice";

export default function ActionsRoomInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.rooms.isEditingRoom);
  const selectedRoom = useSelectedRoom(scene);
  const editedRoom = useSelector((s) => s.rooms.editedRoom);

  const updateRoom = useUpdateSceneRoom(scene);

  // handlers

  function handleEditClick() {
    dispatch(setIsEditingRoom(true));
    dispatch(setEditedRoom({...selectedRoom}));
  }

  function handleCancelClick() {
    dispatch(setIsEditingRoom(false));
  }

  async function handleSaveClick() {
    // rooms
    const updatedRoom = {...editedRoom};
    updateRoom(updatedRoom);
    dispatch(setIsEditingRoom(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
