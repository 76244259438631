export default function cleanMeasurementsBeforeSaving(measurements) {
  return measurements.map((measurement) => {
    const m = {...measurement};
    delete m.elementType;
    delete m.measurementsModel;
    delete m.room;
    delete m.sector;
    delete m.zone;
    delete m.ressource;
    delete m.phase;
    return m;
  });
}
