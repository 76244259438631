import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getElementTypesFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(2, rowCount - 1).values();

  let valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    valuesA.push(rowValues);
  }
  const fields = [
    "id",
    "group",
    "num",
    "isTitle",
    "style",
    "name",
    "code",
    "description",
    "drawingShape",
    "color",
    "dim1",
    "dim2",
    "height",
    "unit",
    "toDelete",
    "res",
  ];

  const loadedElementTypes = [];

  valuesA.map((valueA) => {
    const elementType = {};
    valueA.forEach((value, index) => {
      if (fields[index] === "res") {
        if (value) {
          elementType[fields[index]] = JSON.parse(value);
        }
      } else {
        elementType[fields[index]] = value;
      }
    });
    loadedElementTypes.push(elementType);
  });
  return loadedElementTypes;
}
