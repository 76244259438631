import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import HeaderInLeftPanel from "./HeaderInLeftPanel";
import ListSceneRessources from "./ListSceneRessources";

import SectionMeasurementsFilters from "Features/measurements/components/SectionMeasurementsFilters";
// import ButtonDownloadSceneData from "Features/excel/components/ButtonDownloadSceneData";
import SectionPricingInViewer from "Features/pricing/components/SectionPricingInViewer";
import {
  setScopeSelectionMode,
  setSectorsInScope,
  setRoomsInScope,
  setScopeDefaultSelectionMode,
} from "Features/measurements/measurementsSlice";
import { setBuildingInScope } from "Features/buildings/buildingsSlice";
import SelectorMeasurementsScope from "Features/measurements/components/SelectorMeasurementsScope";
import SectionSelectorMeasurementScope from "Features/measurements/components/SectionSelectorMeasurementScope";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import ButtonMoreRessourcesInLeftPanelInViewer from "./ButtonMoreRessourcesInLeftPanelInViewer";
import ButtonDownloadSceneDataInViewer from "Features/excel/components/ButtonDownloadSceneDataInViewer";
import DialogDownloadSceneDataInViewer from "Features/excel/components/DialogDownloadSceneDataInViewer";

export default function SectionRessourcesInLeftPanelInViewer({
  scene,
  caplaEditor,
  viewer,
}) {
  const dispatch = useDispatch();

  const defaultScope = useSelector((s) => s.measurements.scopeDefaultSelectionMode);
  // const groupViewer = useSelector((s) => s.measurements.selectedRessourceGroup);
  const mode = useMeasurementsScopeMode(scene, viewer);
  let [fms] = useFilteredSceneMeasurements(scene, mode);
  const sectors = useSectorsByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);

  // state

  const [download, setDownload] = useState(false)

  // helpers

  const dontUseForQuantities = new Set(models
    .filter((m) => m.excludeFromQties)
    .map((m) => m.id)
  );
  if (dontUseForQuantities.size > 0 && fms)
    fms = fms.filter(
      (m) => !dontUseForQuantities.has(m.measurementsModelId)
    );

  const fmSectorIds = [...new Set(fms.map((m) => m.sectorId))];
  const fmRoomIds = [...new Set(fms.map((m) => m.roomId))];
  let buildings = [];
  fmSectorIds.map((id) => {
    if (id) {
      const sector = sectors.find((s) => s.id === id);
      if (sector?.building) buildings.push(sector.building);
    }
  })
  const scopeDefault = buildings.length > 1 ? "BY_BUILDING" : (
    fmSectorIds.filter((si) => si).length > 1 ? "BY_SECTOR" : (
      fmRoomIds.length > 1 ? "BY_ROOM" : "BY_SECTOR"
    )
  );

  // effects

  useEffect(() => {
    if (defaultScope !== scopeDefault) {
      dispatch(setScopeDefaultSelectionMode(scopeDefault))
      dispatch(setScopeSelectionMode(scopeDefault));
      dispatch(setSectorsInScope([]));
      dispatch(setRoomsInScope([]));
      dispatch(setBuildingInScope(null));
    }
  }, [defaultScope, sectors.length])

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderInLeftPanel scene={scene} caplaEditor={caplaEditor} viewer={viewer} />
      {scene?.usesPrices ? (
        <SectionPricingInViewer scene={scene} />
      ) : (
        <>
          <Box sx={{display: "flex", p: 1, justifyContent: "space-between"}}>
            <SelectorMeasurementsScope scene={scene} viewer={viewer} />
            <ButtonMoreRessourcesInLeftPanelInViewer scene={scene} />
          </Box>
          <Box sx={{width: 1, p: 1}}>
            <SectionSelectorMeasurementScope scene={scene} viewer={viewer} editor3d={caplaEditor?.editor3d} />
          </Box>
        </>
      )}
      <Box sx={{mt: 2, mb: 1}}>
        <SectionMeasurementsFilters scene={scene} caplaEditor={caplaEditor} />
      </Box>
      
      <ListSceneRessources scene={scene} viewer={viewer} caplaEditor={caplaEditor} />
      
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          justifyContent: "center",
          color: "text.secondary",
          width: 1,
        }}
      >
        <ButtonDownloadSceneDataInViewer
          handleClick={() => setDownload(true)}
        />
        {download && (
          <DialogDownloadSceneDataInViewer
            scene={scene}
            onClose={() => setDownload(false)}
            exportRelations={true}
            exportVersions={true}
          />
        )}
      </Box>
    </Box>
  );
}