import React from "react";

import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import LayerDefault from "./LayerDefault";
import LayerLocate from "./LayerLocate";

export default function Layers({editor, scene}) {
  // data

  const toolGroup = useSelector((s) => s.viewer3D.toolGroup);
  const worksitesPage = useSelector((s) => s.viewer3D.worksitesPage);

  // helpers

  const isDefault = !worksitesPage && toolGroup !== "LOCATE";

  return (
    <Box sx={{zIndex: 30}}>
      {isDefault && <LayerDefault editor={editor} scene={scene} />}

      {toolGroup === "LOCATE" && !worksitesPage && (
        <LayerLocate editor={editor} scene={scene} />
      )}
    </Box>
  );
}
