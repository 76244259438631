import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {
  fetchProgressRecordsByScene,
  setSceneRecordsLoadingStatus,
} from "./progressSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useProgressRecordsByScene(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const sceneId = scene?.id;

  let progressRecords = useSelector((s) => s.progress.items)?.filter(
    (record) => record.scene === sceneId
  );

  const status = useSelector((s) => s.progress.statusByScene)[sceneId];

  // state

  const [canFetch, setCanFetch] = useState(false);

  function fetch() {
    if (!canFetch) {
      setTimeout(() => setCanFetch(true), 50);
    } else {
      dispatch(setSceneRecordsLoadingStatus({sceneId, status: "loading"}));
      dispatch(fetchProgressRecordsByScene({accessToken, sceneId}));
      console.log("fetching 24 scene records");
    }
  }
  useEffect(() => {
    if (status !== "loaded" && status !== "loading" && sceneId && accessToken) {
      fetch();
    }
  }, [status, accessToken, sceneId, canFetch]);

  return progressRecords;
}
