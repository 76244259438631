import {useEffect} from "react";
import {useNavigate} from "react-router";

import { setProfileStatus } from "Features/profile/profileSlice";

export default function Logout() {
  const navigate = useNavigate();
  setProfileStatus("idle");
  useEffect(() => {
    navigate("/");
  }, []);
  return <div>...</div>;
}
