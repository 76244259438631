import {useKvfTranslationMap} from "./useKvfTranslationMap";

export default function useKvtPhase() {
  const translationMap = useKvfTranslationMap();

  const kvt = {
    key: "phase",
    fields: [
      {
        key: "num",
        name: translationMap["num"],
        type: "text",
      },
      {
        key: "name",
        name: translationMap["name"],
        type: "text",
      },
      {
        key: "color",
        name: translationMap["color"],
        type: "color",
      },
    ],
  };

  return kvt;
}
