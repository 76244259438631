import testArticleHasTypes from "./testArticleHasTypes";

export default function resetArticlesTypes(articles) {
  const newArticles = [];
  const batchUpdates = [];

  articles.forEach((article) => {
    const newArticle = {...article};
    if (testArticleHasTypes(article)) {
      delete newArticle.types;
      batchUpdates.push({id: article.id, types: {}});
    }
    newArticles.push(newArticle);
  });

  return [newArticles, batchUpdates];
}
