import {Typography, Box, Tooltip} from "@mui/material";

import {PictureAsPdf as Pdf} from "@mui/icons-material";
export default function SectionInputNameWithIcon({
  model,
  variant = "MODEL_NAME",
}) {
  // variant="MODEL_NAME", "FILE_NAME"
  return (
    <Box sx={{width: 1, display: "flex", alignItems: "center"}}>
      <Box
        sx={{
          color: "red",
          mr: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 1,
        }}
      >
        <Pdf color="inherit" />
      </Box>
      <Tooltip title={model?.fileName}>
        <Typography variant="body2" noWrap>
          {variant == "MODEL_NAME" ? model?.name : model?.fileName}
        </Typography>
      </Tooltip>
    </Box>
  );
}
