import {useDispatch} from "react-redux";
import {updateEditedElementTypesGroupNums} from "../elementTypesSlice";

import {Button, Box} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function ButtonUpdateEditedElementTypesGroupNums() {
  const dispatch = useDispatch();

  // strings

  const updateS = "Num. auto";

  // handlers

  function handleUpdate() {
    dispatch(updateEditedElementTypesGroupNums());
  }
  return (
    <Box sx={{color: "white"}}>
      <Button
        startIcon={<Refresh />}
        onClick={handleUpdate}
        size="small"
        color="inherit"
      >
        {updateS}
      </Button>
    </Box>
  );
}
