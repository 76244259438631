import html2canvas from "html2canvas";
import {getImageSize} from "../imageUtils";

export default async function captureRefAsImageAsync(ref) {
  try {
    const canvas = await html2canvas(ref.current);
    const imageUrl = canvas.toDataURL("image/png"); // Convert to PNG
    const size = await getImageSize({fileURL: imageUrl});
    //const size = {width: 100, height: 60};
    return {imageUrl, imageSize: size};
  } catch (e) {
    console.log("error", e);
  }
}
