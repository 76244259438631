import {useRef, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Button, Box, Typography} from "@mui/material";
import {AddLocation as Add} from "@mui/icons-material";

import {setMode, updateModel} from "Features/viewer3D/viewer3DSlice";
import useSelectedMarkersModel from "Features/markers/hooks/useSelectedMarkersModel";
import ActionPopper from "Features/viewer3D/components/ActionPopper";
import ActionNameMarker from "Features/viewer3D/components/ActionNameMarker";

export default function ButtonCreateMarkerOnPdf({caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const createString = "New";

  const callToActionString = "Locate the marker on the pdf ...";

  // data

  const mode = useSelector((s) => s.viewer3D.mode);
  const pdftronOffset = useSelector((s) => s.viewer3D.pdftronOffset);

  const selectedMarkersModel = useSelectedMarkersModel();

  // virtual element for popper

  function generateBBCR(x, y) {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    });
  }
  const virtualElementRef = useRef();

  // state

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [pagePoint, setPagePoint] = useState();
  const [rawPagePoint, setRawPagePoint] = useState();
  // const [pageNumber, setPageNumber] = useState();

  // helper

  const adding = mode === "ADDING_PDF_MARKER";

  // handlers

  function handleCreateClick() {
    caplaEditor.editorPdf.updateSelection = false;
    dispatch(setMode("ADDING_PDF_MARKER"));
    caplaEditor?.editorPdf.triggerCreateMarker(); // to get cross hair pointer...
    caplaEditor?.editorPdf.setOnClick(handlePdfEditorClick);
    caplaEditor?.editorPdf.createMarkerOnClick();
    caplaEditor?.editorPdf.sceneEditor.enablePointer();
    setActive(true);
  }

  // handlers - click pdf listener

  const handlePdfEditorClick = (props) => {
    console.log("click prposp", props);

    if (active) {
      setOpen(true);
      const {screenP, pageCoordinatesWithRot, pageCoordinates} = props;
      virtualElementRef.current.getBoundingClientRect = generateBBCR(
        screenP.x + pdftronOffset.x,
        screenP.y + pdftronOffset.y
      );
      setPagePoint(pageCoordinatesWithRot);
      setRawPagePoint(pageCoordinates);
      // setPageNumber(pageCoordinates.pageNumber);
    }
  };

  // handlers - marker name

  function handleMarkerName(name) {
    const p = caplaEditor?.editorPdf?.annotationsManager.getBimboxPointFromZonePoint(
      pagePoint,
      rawPagePoint
    );

    const marker = {
      id: nanoid(),
      description: name,
      x: p.x,
      y: p.y,
      z: p.z,
      type: "REFERENCE",
      pn: rawPagePoint.pageNumber,
      px: rawPagePoint.x,
      py: rawPagePoint.y,
    };

    const entity = caplaEditor?.editor3d?.getEntity(selectedMarkersModel?.id);
    entity?.addMarker(marker);

    // update model
    let oldElements = selectedMarkersModel?.elements?.items;
    if (!oldElements) oldElements = [];
    const updatedModel = {
      ...selectedMarkersModel,
      elements: {items: [...oldElements, marker]},
    };
    dispatch(updateModel({updatedModel}));

    // close tool
    dispatch(setMode("DEFAULT"));
    caplaEditor?.editorPdf.activePanTool();
    caplaEditor?.editorPdf.sceneEditor.disablePointer();
    caplaEditor?.editorPdf.logPagePositionOnClick();
    caplaEditor.editorPdf.updateSelection = true;
    setActive(false);
    setOpen(false);
  }

  // effects

  useEffect(() => {
    virtualElementRef.current = {getBoundingClientRect: generateBBCR};
  }, []);

  useEffect(() => {
    caplaEditor?.editorPdf?.setOnClick(handlePdfEditorClick);
    caplaEditor?.editorPdf?.sceneEditor.enablePointer();
  }, [pdftronOffset.x, pdftronOffset.y, active]);

  // close poper when press Esc
  useEffect(() => {
    if (mode === "DEFAULT" && caplaEditor?.editorPdf) {
      caplaEditor?.editorPdf?.activePanTool();
      caplaEditor?.editorPdf?.sceneEditor.disablePointer();
      caplaEditor?.editorPdf?.sceneEditor.hideTempMarker();
      caplaEditor?.editorPdf?.logPagePositionOnClick();
      setActive(false);
      setOpen(false);
    }
  }, [mode, caplaEditor?.editorPdf]);

  return (
    <Box sx={{color: "common.white"}}>
      {!adding && (
        <Box sx={{color: "common.white"}}>
          <Button
            startIcon={<Add />}
            onClick={handleCreateClick}
            size="small"
            variant="outlined"
            color="inherit"
          >
            {createString}
          </Button>
        </Box>
      )}
      {adding && (
        <Typography variant="body2" color="inherit">
          {callToActionString}
        </Typography>
      )}
      <Box sx={{zIndex: 100}}>
        {virtualElementRef?.current && (
          <ActionPopper anchorEl={virtualElementRef.current} open={open}>
            <ActionNameMarker onAdd={handleMarkerName} />
          </ActionPopper>
        )}
      </Box>
    </Box>
  );
}
