import {Box} from "@mui/material";

import SectionActionsRessources from "./SectionActionsRessources";

export default function SectionLeftPanelActionsRessources({
  scene,
  caplaEditor,
}) {
  // actions - main

  const actionsMainKeys = [
    "createRessourcesGroup",
    "createRessource",
    "exportExcelRessources",
    "importExcelRessources",
    "editRessourcesGroup",
  ];
  const actionsMoreKeys = ["deleteRessourcesGroup", "deleteRessource"];

  return (
    <Box>
      <SectionActionsRessources
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
