export default function addFiltersStrToOneArticleTypesItem(item, filtersStr) {
  if (!item || !filtersStr?.length > 0) {
    return item;
  }

  const newItem = {...item};

  let newFiltersStr;
  if (!item.filtersStr || item.filtersStr.length === 0) {
    newFiltersStr = filtersStr;
  } else {
    newFiltersStr = [...new Set([...filtersStr, ...item.filtersStr])];
  }

  if (newFiltersStr?.length > 0) {
    newItem.filtersStr = newFiltersStr;
  }

  return newItem;
}
