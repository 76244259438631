import React from "react";

import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";

import {
  Construction as Build,
  Accessibility as Assistant,
  People,
  Star,
  ViewInAr as Scene,
  HomeRepairService as Toolbox,
  Palette as Colorings,
  Store as Worksites,
  LocalLibrary as Library,
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import usePageType from "../usePageType";

export default function MenuItemsHome() {
  const navigate = useNavigate();

  // strings

  const scenesS = "Bimboxes";
  const buildAssistantS = "Creation assistant";
  const myCreationsS = "Created by me";
  const sharedWithMeS = "Shared with me";
  const favoritesS = "Starred";
  const toolboxS = "Toolbox";
  const coloringsS = "Colorings";
  const worksitesS = "Worksites";
  const libraryS = "Library";

  // data

  const pageType = usePageType();

  // helpers

  const items = [
    // {
    //   id: 1.1,
    //   name: buildAssistantS,
    //   icon: <Assistant fontSize="small" />,
    //   path: "/bimboxes/new",
    //   divider: true,
    // },
    {
      id: 1,
      name: scenesS,
      icon: <Scene fontSize="small" />,
      path: "/",
      pageType: "HOME",
    },
    // {
    //   id: 1.1,
    //   name: libraryS,
    //   icon: <Library fontSize="small" />,
    //   path: "/library",
    //   pageType: "LIBRARY",
    // },
    {
      id: 2,
      name: coloringsS,
      icon: <Colorings fontSize="small" />,
      path: "/colorings",
      pageType: "COLORINGS",
      divider: true,
    },
    {
      id: 3,
      name: toolboxS,
      icon: <Toolbox fontSize="small" />,
      path: "/toolbox",
      pageType: "TOOLBOX",
      //divider: true,
    },
    // {
    //   id: 4,
    //   name: worksitesS,
    //   icon: <Worksites fontSize="small" />,
    //   path: "/worksites",
    //   pageType: "WORKSITES",
    // },

    // {
    //   id: 2,
    //   name: myCreationsS,
    //   icon: <Build fontSize="small" />,
    // },
    // {
    //   id: 3,
    //   name: sharedWithMeS,
    //   icon: <People fontSize="small" />,
    // },
    // {
    //   id: 4,
    //   name: favoritesS,
    //   icon: <Star fontSize="small" />,
    // },
  ];

  // handlers

  function handleClick(path) {
    navigate(path);
  }

  return (
    <Box>
      {items.map((item) => (
        <Box key={item.id}>
          <ListItemButton
            sx={{my: 0, py: 0.5}}
            onClick={() => handleClick(item.path)}
            selected={item.pageType === pageType}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>
              <Typography variant="body2">{item.name}</Typography>
            </ListItemText>
          </ListItemButton>
          {item.divider && <Divider sx={{my: 2, mx: 2}} />}
        </Box>
      ))}
    </Box>
  );
}
