import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";

import {Box, Button, alpha} from "@mui/material";
import {Launch} from "@mui/icons-material";

import useSelection from "Features/selection/useSelection";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";
import {
  // setSelectedMeasurementId,
  setSelectedMeasurementsModelId,
} from "Features/measurements/measurementsSlice";

export default function ActionsHomeMeasurementInSelectionPanel({
  caplaEditor,
  scene,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // strings

  const openPackageS = "Ouvrir le calque de repérage";

  // data

  let {sceneId, modelId} = useParams();
  if (!sceneId) sceneId = scene.clientId;
  const selection = useSelection({scene, editorPdf: caplaEditor?.editorPdf});
  if (!modelId) modelId = selection?.item?.measurementsModelId;
  const pathMap = useSceneModulePathMap(sceneId, modelId);


  // helper - measurement

  let measurement = selection?.item;

  // handlers

  function handleOpenClick() {
    navigate(pathMap["EDIT_MEASUREMENTS"]);
    dispatch(setSelectedMeasurementsModelId(measurement.measurementsModelId));
    // caplaEditor?.measDataManager.setSelectedModelById(measurement.measurementsModelId);
    // dispatch(setSelectedMeasurementId(measurement.id));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Launch />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleOpenClick}
        >
          {openPackageS}
        </Button>
      </Box>
    </Box>
  );
}
