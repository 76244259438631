import {useSelector} from "react-redux";

import ContainerLoadingEntity from "./ContainerLoadingEntity";

export default function ContainerLoadingProgressModels({scene, type}) {
  // data

  const sceneOverview = useSelector((s) => s.overviewer.sceneOverview);
  const models = useSelector((s) => s.viewer3D.models);
  const loadingStatusMap = useSelector(
    (s) => s.overviewer.loadingStatusModelIdsMap
  );
  const sceneIdOfSceneLight = useSelector(
    (s) => s.overviewer.sceneIdOfSceneLight
  );
  const sceneIdOfFetchedElementTypeGroups = useSelector(
    (s) => s.overviewer.sceneIdOfFetchedElementTypeGroups
  );
  const elementTypesCount = useSelector((s) => s.overviewer.elementTypesCount);

  const ressourcesCount = scene?.data?.ressources?.length ?? 0;

  // helpers

  const sceneModels = models.filter(
    (m) => m.sceneClientId === sceneOverview?.clientId
  );

  const modelIdsInState = loadingStatusMap["IN_STATE"];

  const modelsByType = sceneModels.filter((m) => m.type === type);

  // helpers - totalCountByType

  let totalCountByType = modelsByType.length;
  if (type === "ELEMENT_TYPE_GROUPS") {
    totalCountByType = elementTypesCount;
  } else if (type === "RESSOURCES") {
    totalCountByType = ressourcesCount;
  }

  // helper - counter - models in state.

  let countInStateByType = 0;
  modelsByType.forEach((model) => {
    if (modelIdsInState.includes(model.id)) countInStateByType += 1;
  });

  // helper - ratio

  let ratio = totalCountByType
    ? (countInStateByType / totalCountByType) * 100
    : 0;

  // helpers - states

  let state = "START";
  if (type === "ELEMENT_TYPE_GROUPS") {
    if (sceneIdOfFetchedElementTypeGroups === sceneOverview?.id) {
      state = "END";
      ratio = 100;
    }
  } else if (type === "RESSOURCES") {
    if (sceneIdOfSceneLight && sceneIdOfSceneLight === sceneOverview?.id) {
      state = "END";
      ratio = 100;
    }
  }
  if (sceneIdOfSceneLight && sceneIdOfSceneLight === sceneOverview?.id)
    state = "WIP";
  if (ratio > 99) state = "END";

  return (
    <ContainerLoadingEntity
      ratio={ratio}
      totalCount={totalCountByType}
      state={state}
      type={type}
    />
  );
}
