import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import computeArticlesQties from "../utils/computeArticlesQties";
import getItemsMapById from "Utils/getItemsMapById";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

export default function useGetArticleQtyMap(scene) {
  const elementTypes = useElementTypesBySceneProxy(scene);
  const sectors = useSectorsByScene(scene);

  const elementTypesMap = getItemsMapById(elementTypes);
  const sectorsMap = getItemsMapById(sectors);

  const lastUpdateAt = useSelector((s) => s.articles.lastUpdateAt);

  // state
  let initFunction = () => {
    return [];
  };
  //const [getMap, setGetMap] = useState(initFunction);

  const tempGetMap = ({measurements, articles, noVoids}) => {
    const map = computeArticlesQties({
      articles,
      measurements,
      elementTypesMap,
      sectorsMap,
      noVoids,
    });
    return map;
  };

  return [tempGetMap, Date.now()];
}
