import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {blockIsEmpty} from "./utils";
import {client} from "API/capla360";

export const postImage = createAsyncThunk(
  "images/postImage",
  async ({accessToken, userId, listingId, image}) => {
    console.log("posting");
    const response = await client.post(
      `/users/${userId}/listings/${listingId}/upload/`,
      image,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const editorSlice = createSlice({
  name: "editor",
  initialState: {
    selectedTool: "text",
    selectedBlock: {},
    blocks: [],
    ui: {actionsToolbar: false},
    title: "",
  },
  reducers: {
    setTool: (state, action) => {
      state.selectedTool = action.payload;
    },
    selectBlock: (state, action) => {
      const block = action.payload;
      const newId = state.selectedBlock.id != block.id;
      state.selectedBlock = newId ? block : {};
    },
    createBlock: (state, action) => {
      state.blocks.push(action.payload);
    },
    updateBlock: (state, action) => {
      const {blockId, data, type} = action.payload;
      const newBlocks = state.blocks.map((block) => {
        if (block.id !== blockId) {
          return block;
        } else {
          if (data) {
            block.data = data;
          }
          if (type) {
            block.type = type;
          }
          return block;
        }
      });
      state.blocks = newBlocks;
      // + update selected block if necessary
      if (blockId === state.selectedBlock.id) {
        state.selectedBlock.data = data;
      }
    },
    deleteBlock: (state, action) => {
      state.blocks = state.blocks.filter((block) => block.id != action.payload);
    },
    updateBlocksWithSelectedBlock: (state) => {
      if (state.selectedBlock.id) {
        if (!blockIsEmpty(state.selectedBlock)) {
          const newBlocks = state.blocks.map((block) => {
            if (block.id === state.selectedBlock.id) {
              return {...state.selectedBlock};
            } else {
              return block;
            }
          });
          state.blocks = newBlocks;
        } else {
          // we delete the block
          state.blocks = state.blocks.filter(
            (block) => block.id != state.selectedBlock.id
          );
        }
      }
    },
    updateSelectedBlock: (state, action) => {
      const {data: newData, type} = action.payload;
      const newBlock = {...state.selectedBlock};
      if (newData) {
        newBlock.data = newData;
      }
      if (type) {
        newBlock.type = type;
      }
      state.selectedBlock = newBlock;
    },
    updateBlocks: (state, action) => {
      state.blocks = action.payload;
    },
    clean: (state) => {
      state.blocks = [];
      state.title = "";
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    init: (state, action) => {
      const note = action.payload;
      state.title = note.title;
      state.blocks = note.blocks;
    },
  },
});

export const {
  setTool,
  selectBlock,
  createBlock,
  deleteBlock,
  updateBlock,
  updateBlocksWithSelectedBlock,
  clean,
  init,
  setTitle,
  updateSelectedBlock,
  updateBlocks,
} = editorSlice.actions;

export default editorSlice.reducer;
