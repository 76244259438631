import {urlToBlob} from "Features/files/utils";
import {blobToArrayBuffer} from "Database/utils";

import * as Excel from "exceljs";

export default async function createExcelWorkbookFromTemplateUrl({url}) {
  const workbook = new Excel.Workbook();
  const blob = await urlToBlob(url);
  const arrayBuffer = await blobToArrayBuffer(blob);
  return await workbook.xlsx.load(arrayBuffer);
}
