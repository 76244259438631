import React, {useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {setNewScene} from "../scenesSlice";

import {setConnectSceneOnCreation} from "../scenesSlice";

import {
  Box,
  TextField,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {ExpandMore as More, ExpandLess as Less} from "@mui/icons-material";

import FieldProdDeadline from "./FieldSceneProdDeadline";
import {useIsAuthenticated} from "@azure/msal-react";

export default function FormNewScene() {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  // strings

  const placeholderS = "[Lot X, Lot Y] Opération XXX";
  const savedDataS = "Enregistrer la bimbox";

  const scopeS = "Périmètre";
  const message = `Une bimbox est un ensemble d'éléments de votre projet.
  Ces éléments sont des objets, des pièces, des zones, et plus généralement, tout ce qui peut être localisé.
  Utilisez les champs Opération, Lots & Ouvrages pour préciser le périmètre de ces éléments.`;

  const operationS = "Opération";
  const tradesS = "Lots / corps d'état";
  const buildingsS = "Ouvrages / bâtiments";

  const nameMessage = "Donnez un nom à votre bimbox.";
  const nameS = "Nom";
  const descriptionS = "Description";

  const productionS = "Production de la bimbox";
  const prodDeadlineS = "Besoin pour le : ";

  const moreS = "Plus d'information";

  // data

  const newScene = useSelector((s) => s.scenes.newScene);
  const connectSceneOnCreation = useSelector(
    (s) => s.scenes.connectSceneOnCreation
  );

  // state

  const [open, setOpen] = useState(false);

  // helper - SaveData

  const disableSaveData = !isAuthenticated;
  //const saveData = connectSceneOnCreation;
  const saveData = disableSaveData ? false : connectSceneOnCreation;

  // helpers

  const sceneName = newScene?.title ? newScene.title : "";
  const sceneDescription = newScene?.description ? newScene.description : "";
  const operationName = newScene?.operationName ? newScene.operationName : "";
  const tradesName = newScene?.tradesName ? newScene.tradesName : "";
  const buildingsName = newScene?.buildingsName ? newScene.buildingsName : "";
  const prodDeadline = newScene?.prodDeadline ? newScene.prodDeadline : null;

  // handlers

  function handleSaveChange(e) {
    const checked = e.target.checked;
    dispatch(setConnectSceneOnCreation(checked));
  }
  function handleTradesNameChange(e) {
    const name = e.target.value;
    dispatch(setNewScene({...newScene, tradesName: name}));
  }
  function handleBuildingsNameChange(e) {
    const name = e.target.value;
    dispatch(setNewScene({...newScene, buildingsName: name}));
  }
  function handleOperationNameChange(e) {
    const name = e.target.value;
    dispatch(setNewScene({...newScene, operationName: name}));
  }
  function handleNameChange(e) {
    const name = e.target.value;
    dispatch(setNewScene({...newScene, title: name}));
  }
  function handleDescriptionChange(e) {
    const description = e.target.value;
    dispatch(setNewScene({...newScene, description}));
  }
  function handleProdDeadlineChange(date) {
    console.log("newDeadline", date);
    dispatch(setNewScene({...newScene, prodDeadline: date}));
  }

  return (
    <Box sx={{p: 1}}>
      <Box sx={{p: 1, mt: 3}}>
        <Typography variant="body2" sx={{color: "text.secondary", mb: 2}}>
          {nameMessage}
        </Typography>
        <TextField
          fullWidth
          placeholder={placeholderS}
          label={<Typography variant="body2">{nameS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={sceneName}
          onChange={handleNameChange}
        />
        <FormControlLabel
          size="small"
          disabled={disableSaveData}
          control={
            <Checkbox
              size="small"
              checked={saveData}
              onChange={handleSaveChange}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: 13,
                color: disableSaveData ? "text.disabled" : "text.secondary",
              }}
            >
              {savedDataS}
            </Typography>
          }
        />
      </Box>
      <Box sx={{display: "flex", alignItems: "center", px: 2, mt: 4}}>
        <Typography color="text.secondary" sx={{mr: 1, fontSize: 13}}>
          {moreS}
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <Less fontSize="small" /> : <More fontSize="small" />}
        </IconButton>
      </Box>
      {open && (
        <Box>
          <TextField
            sx={{my: 2}}
            fullWidth
            multiline
            label={<Typography variant="body2">{descriptionS}</Typography>}
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            size="small"
            value={sceneDescription}
            onChange={handleDescriptionChange}
          />
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: "4px",
              p: 1,
            }}
          >
            <Typography gutterBottom>{scopeS}</Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{whiteSpace: "pre-line", fontSize: 13}}
            >
              {message}
            </Typography>
            <TextField
              fullWidth
              sx={{mt: 2}}
              label={<Typography variant="body2">{operationS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={operationName}
              onChange={handleOperationNameChange}
            />
            <TextField
              fullWidth
              sx={{mt: 2}}
              label={<Typography variant="body2">{tradesS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={tradesName}
              onChange={handleTradesNameChange}
            />
            <TextField
              fullWidth
              sx={{mt: 2}}
              label={<Typography variant="body2">{buildingsS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={buildingsName}
              onChange={handleBuildingsNameChange}
            />
          </Box>

          <Box sx={{p: 1, bgcolor: "background.default", mt: 3}}>
            <Typography variant="body2" color="text.secondary">
              {productionS}
            </Typography>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Typography variant="body2" sx={{mr: 1}}>
                {prodDeadlineS}
              </Typography>
              <FieldProdDeadline
                value={prodDeadline}
                onChange={handleProdDeadlineChange}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
