import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

import {Box, List, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import DialogCreateModel from "./DialogCreateModel";
import ModelListItem from "./ModelListItem";
import NoModelSection from "./NoModelSection";
import CardGroupedActions from "./CardGroupedActions";
// import TypesFilterToolbar from "./TypesFilterToolbar";
import KeywordsFilterToolbar from "./KeywordsFilterToolbar";
import ButtonLoadAllModels from "./ButtonLoadAllModels";
import ButtonCreateCaplaModel from "./ButtonCreateCaplaModel";

import TitleCloseHeader from "Components/TitleCloseHeader";

import {
  updateModels,
  setSelectedModelId,
} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";
import Stretch from "Features/ui/components/Stretch";

export default function SectionListModels({
  editor3d,
  scene,
  onClose,
  viewer = false,
}) {
  const {t} = useTranslation(["viewer3D", "common"]);
  const dispatch = useDispatch();
  const sceneClientId = scene?.clientId;

  //strings

  const title = t("viewer3D:models.title");
  //const addString = t("viewer3D:models.create");

  // data

  const selectedModelId = useSelector(
    (state) => state.viewer3D.selectedModelId
  );
  const allModels = useSelector((state) => state.viewer3D.models);

  const models = allModels
    .filter((m) => m.type !== "ZIP")
    .filter((m) => m.sceneClientId === sceneClientId)
    .map((m, index) => ({...m, num: m.num ? m.num : index}))
    .sort((a, b) => a.num - b.num);
  const noModel = models ? models.length === 0 : false;

  // filters

  const types = [...new Set(models.map((m) => m.type))];

  const keywords = models.reduce((ac, cur) => {
    if (cur.keywords) {
      const kw = cur.keywords.split(";");
      const newAc = [...ac, ...kw];
      return [...new Set(newAc)];
    } else {
      return ac;
    }
  }, []);

  const modelsFilters = useSelector((state) => state.viewer3D.modelsFilters);
  const noneFilters =
    modelsFilters.types.length === 0 && modelsFilters.keywords.length === 0;

  const filteredModels = models.filter(
    (m) =>
      noneFilters ||
      modelsFilters.types.includes(m.type) ||
      (m.keywords &&
        m.keywords
          .split(";")
          .filter((kw) => modelsFilters.keywords.includes(kw)).length > 0)
  );

  // local state

  const [openDialog, setOpenDialog] = useState(false);

  // handlers

  function handleAddClick() {
    setOpenDialog(true);
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  function handleModelClick(newModelId) {
    dispatch(setSelectedModelId(newModelId));
    //const m = models.find((model) => model.id === newModelId);
    // if (m.type === "IMAGE") editor3d.imageOverlay.setImage(m.url);
  }

  // drag n drop

  function handleDragEnd(result) {
    const {destination, source, draggableId} = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newModels = Array.from(models);
    const draggableModel = models.find(({id}) => id === draggableId);
    newModels.splice(source.index, 1);
    newModels.splice(destination.index, 0, draggableModel);

    const orderedNewModels = newModels.map((m, index) => ({
      ...m,
      num: index + 1,
      nextSync: {...m.nextSync, action: true, data: true},
    }));

    dispatch(updateModels({models: orderedNewModels}));
  }

  return (
    <Box
      sx={{
        width: 1,
        height: "100%",
        position: "relative",
      }}
    >
      <TitleCloseHeader title={title} onClose={onClose} />
      <Box sx={{mt: 1, width: 1}} />
      {!viewer && (
        <Box
          sx={{
            width: 1,
            pr: 1,
            pl: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* {noModel ? <Box /> : <TypesFilterToolbar types={types} />} */}
          <IconButton size="small" onClick={handleAddClick}>
            <Add fontSize="small" color="primary" />
          </IconButton>
        </Box>
      )}

      {noModel ? (
        <Box />
      ) : (
        <Box sx={{px: 1, width: 1, mt: 1}}>
          <KeywordsFilterToolbar keywords={keywords} />
        </Box>
      )}
      {!noModel && (
        <Box sx={{mt: 2, px: 2}}>
          <ButtonLoadAllModels models={filteredModels} editor3d={editor3d} />
        </Box>
      )}
      <Box sx={{mt: 2, px: 1}}>
        <CardGroupedActions models={filteredModels} editor3d={editor3d} />
      </Box>

      <Stretch>
        <Box
          sx={{
            position: "relative",
            height: 1,
            mt: 1,
            overflow: "auto",
          }}
        >
          {noModel && <NoModelSection />}
          {scene?.clientId && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={scene?.clientId}>
                {(provided, _) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <List
                      dense
                      sx={{
                        pt: 0,
                      }}
                    >
                      {filteredModels.map((model, index) => {
                        const selected = model.id === selectedModelId;
                        return (
                          <ModelListItem
                            model={model}
                            editor3d={editor3d}
                            key={model.id}
                            onClick={handleModelClick}
                            selected={selected}
                            index={index}
                          />
                        );
                      })}
                    </List>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          <Box sx={{height: 24}} />
        </Box>
      </Stretch>
      <DialogCreateModel
        open={openDialog}
        onClose={handleDialogClose}
        editor3d={editor3d}
      />

      <Box
        sx={{position: "absolute", bottom: 8, zIndex: 20, left: 0, right: 0}}
      >
        <ButtonCreateCaplaModel editor3d={editor3d} />
      </Box>
    </Box>
  );
}
