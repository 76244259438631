import React, {useState, useEffect} from "react";

import {Grid, Typography, InputBase, Box} from "@mui/material";
export default function FieldTextMultiline({
  name,
  value,
  onChange,
  locked,
  sizeKey = 12,
  horizontal = false,
  width = 100,
}) {
  // state

  const initTempValue = value ? value : "";
  const [tempValue, setTempValue] = useState(initTempValue);
  useEffect(() => setTempValue(initTempValue), [initTempValue]);

  // handlers

  function handleInputChange(e) {
    let value = e.target.value;
    //value = value.replace(",", ".");
    if (!locked) setTempValue(value);
  }

  function handleBlur() {
    if (!locked) onChange(tempValue);
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          px: 1,
          bgcolor: "background.default",
        }}
      >
        <Typography sx={{fontSize: 13}} noWrap>
          {name}
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          p: 1,
        }}
      >
        <InputBase
          value={tempValue}
          multiline={true}
          onChange={handleInputChange}
          onBlur={handleBlur}
          sx={{
            width: 1,
            fontSize: 13,
            whiteSpace: "pre-line",
            py: 0,
            "& .MuiInputBase-input": {p: 0},
          }}
        />
      </Box>
    </Box>
  );
}
