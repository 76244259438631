import {ListItem, Typography, Box} from "@mui/material";
import ContainerModelLoadingStatus from "./ContainerModelLoadingStatus";

export default function ListItemModelLoadingStatus({
  model,
  loadingStatusModelIdsMap,
}) {
  return (
    <ListItem>
      <Box sx={{display: "flex", justifyContent: "space-between", width: 1}}>
        <Typography variant="body2">{model.name ? model.name : "-"}</Typography>
        <ContainerModelLoadingStatus
          modelId={model?.id}
          count={model?.measCount}
          loadingStatusModelIdsMap={loadingStatusModelIdsMap}
        />
      </Box>
    </ListItem>
  );
}
