import {
  // ShapeGeometry,
  Shape,
  Vector2,
  Vector3,
  // Matrix4,
  // MeshLambertMaterial,
  Mesh,
  // DoubleSide,
  Triangle,
  PlaneBufferGeometry,
  Plane,
  ShapeBufferGeometry,
  Path,
  Quaternion,
  ShapeUtils,
  ExtrudeGeometry,
  BufferGeometry,
  BufferAttribute,
  // MathUtils,
} from "three";

// import theme from "Styles/theme";

// import {testIsClockwise} from "../utils/testIsClockwise";
import getBoxVerticesFromPoints from "../utils/getBoxVerticesFromPoints";

function projectPoint(pointA, pointB, length) {
  const dir = pointB.clone().sub(pointA).normalize().multiplyScalar(length);
  return pointA.clone().add(dir);
}

function getAngle(A, B, C) {
  const AB = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
  const BC = Math.sqrt(Math.pow(B.x - C.x, 2) + Math.pow(B.y - C.y, 2));
  const AC = Math.sqrt(Math.pow(C.x - A.x, 2) + Math.pow(C.y - A.y, 2));
  const angleRad = Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB));
  return (angleRad * 180) / Math.PI;
}

// const polygonWinding = (pts) => {
// 	const len = pts.length;
// 	let [minx, miny, mi] = [pts[0][0], pts[0][1], 0];
// 	for (let i = 1; i < len; i++) {
// 		const [pix, piy] = [pts[i][0], pts[i][1]];
// 		if (pix < minx || pix == minx && piy < miny) [minx, miny, mi] = [pix, piy, i];
// 	}
// 	const [mp, mn] = [mi != 0 ? mi - 1 : len - 1, mi != len - 1 ? mi + 1 : 0];
// 	const [a, b, c] = [pts[mp], pts[mi], pts[mn]];
// 	const det = (b[0] - a[0]) * (c[1] - a[1]) - (c[0] - a[0]) * (b[1] - a[1]);
// 	return det < 0 ? 1 : -1; // cw : ccw
// };

// function handleInvertedGeometry(geometry) {
//   const index = geometry.index.array
//   for (let i = 0, il = index.length / 3; i < il; i++) {
//     let x = index[i * 3]
//     index[i * 3] = index[i * 3 + 2]
//       index[i * 3 + 2] = x
//   }
//   geometry.index.needsUpdate = true
// }

export default class Drawer3DObjects {
  // constructor({editor}) {
  //   this.editor = editor;
  // }

  drawPolygon({path, holesPaths, zInf, zSup, material}) {
    // init

    let Z = typeof zInf === "number" ? zInf : zSup;
    if (!Z) Z = 0;

    const points = path.map((p) => ({x: p[0], y: Z, z: p[1]}));
    const holes = holesPaths?.map((path) => {
      return path.map((p) => ({x: p[0], y: Z, z: p[1]}));
    });

    // general case with points & holes

    // const a = new Vector3(points[0].x, points[0].y, points[0].z);
    // const b = new Vector3(points[1].x, points[1].y, points[1].z);
    // const c = new Vector3(points[2].x, points[2].y, points[2].z);

    const a = new Vector3(0, Z, 0); // particular case : horizonal plane
    const b = new Vector3(0, Z, 1);
    const c = new Vector3(1, Z, 0);

    const normal = new Vector3();

    const triangle = new Triangle(a, b, c);
    triangle.getNormal(normal);

    // plane object

    const planeGeo = new PlaneBufferGeometry(1, 1);
    const plane = new Mesh(planeGeo, material);

    const q = new Quaternion();
    q.setFromUnitVectors(new Vector3(0, 0, 1), normal);

    plane.applyQuaternion(q);
    plane.position.copy(a);
    plane.updateMatrixWorld();

    // projection plane

    const projPlane = new Plane();
    triangle.getPlane(projPlane);

    // get x,y coordinates

    const points2D = points.map((point) => {
      const pointV = new Vector3(point.x, point.y, point.z);
      const projPointV = new Vector3();
      projPlane.projectPoint(pointV, projPointV);
      plane.worldToLocal(projPointV);
      return new Vector2(projPointV.x, projPointV.y);
    });

    // add holes

    const holes2D = holes?.map((hole) => {
      const hole2D = hole.map((point) => {
        const pointV = new Vector3(point.x, point.y, point.z);
        const projPointV = new Vector3();
        projPlane.projectPoint(pointV, projPointV);
        plane.worldToLocal(projPointV);
        return new Vector2(projPointV.x, projPointV.y);
      });
      return hole2D;
    });

    // create shape

    const shape = new Shape(points2D);
    if (holes?.length > 0)
      holes2D.forEach((hole2D) => {
        if (hole2D.length > 2) {
          const hole2DPath = new Path(hole2D);
          shape.holes.push(hole2DPath);
        }
      });

    const shapeGeometry = new ShapeBufferGeometry(shape);
    const shapeMesh = new Mesh(shapeGeometry, material);
    shapeMesh.layers.enable(1);

    // move shape

    shapeMesh.applyQuaternion(q);
    shapeMesh.position.copy(a);

    // compute surface
    const areaContour = ShapeUtils.area(points2D);
    const areaHoles = !holes
      ? 0
      : holes2D.reduce((acc, hole2D) => {
          const areaHole = ShapeUtils.area(hole2D);
          return acc + areaHole;
        }, 0);

    // draw

    // this.editor.scene.add(shapeMesh);
    // shapeMesh.layers.enable(1);
    // shapeMesh.layers.enable(3);

    return {object: shapeMesh, area: areaContour - areaHoles};
  }

  drawPolygonV2({path3, holesPaths, zInf, zSup, material}) {
    // init

    // in 3D (not only in horizontal plane)
    if (!path3) return;

    // init

    const points = path3.map((p) => ({x: p[0], y: p[1], z: p[2]}));

    // general case with points

    const a = new Vector3(points[0].x, points[0].y, points[0].z);
    const b = new Vector3(points[1].x, points[1].y, points[1].z);
    const c = new Vector3(points[2].x, points[2].y, points[2].z);

    const normal = new Vector3();

    const triangle = new Triangle(a, b, c);
    triangle.getNormal(normal);

    // plane object

    const planeGeo = new PlaneBufferGeometry(1, 1);
    const plane = new Mesh(planeGeo, material);

    const q = new Quaternion();
    q.setFromUnitVectors(new Vector3(0, 0, 1), normal);

    plane.applyQuaternion(q);
    plane.position.copy(a);
    plane.updateMatrixWorld();

    // projection plane

    const projPlane = new Plane();
    triangle.getPlane(projPlane);

    // get x,y coordinates

    const points2D = points.map((point) => {
      const pointV = new Vector3(point.x, point.y, point.z);
      const projPointV = new Vector3();
      projPlane.projectPoint(pointV, projPointV);
      plane.worldToLocal(projPointV);
      return new Vector2(projPointV.x, projPointV.y);
    });

    // create shape

    const shape = new Shape(points2D);

    const shapeGeometry = new ShapeBufferGeometry(shape);
    const shapeMesh = new Mesh(shapeGeometry, material);
    shapeMesh.layers.enable(1);

    // move shape

    shapeMesh.applyQuaternion(q);
    shapeMesh.position.copy(a);

    // draw

    // this.editor.scene.add(shapeMesh);
    // shapeMesh.layers.enable(1);
    // shapeMesh.layers.enable(3);

    return {object: shapeMesh};
  }

  // TODO : get linearLength and slope from PDF annotation
  // and simplify the procedure by setting z from slope directly
  drawStairs({path, zInf, zSup, material, height = null}) {
    if (path.length % 2 !== 0) path = path.slice(0, -1);

    let indices = [];
    let vertices = [];

    if (path.length === 4) {
      vertices.push(
        path[0][0],
        zInf,
        path[0][1],
        path[1][0],
        zSup,
        path[1][1],
        path[2][0],
        zSup,
        path[2][1],
        path[3][0],
        zInf,
        path[3][1]
      );
      indices.push(
        0,
        1,
        2,
        0,
        2,
        3 // bottom
      );
      if (height) {
        vertices.push(
          path[0][0],
          zInf + height,
          path[0][1],
          path[1][0],
          zSup + height,
          path[1][1],
          path[2][0],
          zSup + height,
          path[2][1],
          path[3][0],
          zInf + height,
          path[3][1]
        );
        indices.push(
          4,
          5,
          6,
          4,
          6,
          7, // top
          0,
          4,
          7,
          0,
          7,
          3, // front
          1,
          5,
          6,
          1,
          6,
          2, // back
          3,
          7,
          6,
          3,
          6,
          2, // left
          1,
          5,
          4,
          1,
          4,
          0 // right
        );
      } else {
        material.side = 2;
      }
    } else if (path.length > 2) {
      const lengths = [];
      const pathTypes = [];
      const midLength = path.length / 2;

      let linearLength = 0;
      let lastPoint1 = path[0];
      let lastPoint2 = path[path.length - 1];

      for (let i = 1; i < midLength; i++) {
        const point1 = path[i];
        const point2 = path[path.length - 1 - i];
        const v11 = new Vector2(lastPoint1[0], lastPoint1[1]);
        const v12 = new Vector2(point1[0], point1[1]);
        const v21 = new Vector2(lastPoint2[0], lastPoint2[1]);
        const v22 = new Vector2(point2[0], point2[1]);
        const length1 = v11.distanceTo(v12);
        const length2 = v21.distanceTo(v22);
        const ml = (length1 + length2) * 0.5;
        lengths.push(ml);
        linearLength += ml;
        if (length1 < length2) {
          const nexPoint1 = path[i + 1];
          const nv1 = new Vector2(nexPoint1[0], nexPoint1[1]);
          const angle = getAngle(v11, v12, nv1);
          const p1 = projectPoint(v21, v22, length1);
          const p2 = projectPoint(v11, v12, length2);
          const il = vertices.length / 3;
          const il1 = il + 1;
          const il2 = il + 2;
          vertices.push(
            lastPoint1[0],
            NaN,
            lastPoint1[1],
            v12.x,
            NaN,
            v12.y,
            p1.x,
            NaN,
            p1.y,
            lastPoint2[0],
            NaN,
            lastPoint2[1]
          );
          indices.push(il, il1, il2, il2, il + 3, il);
          lastPoint1 = point1;
          pathTypes.push("straight");
          if (angle.toFixed(1) === "90.0" && i < midLength - 1) {
            vertices.push(v22.x, NaN, v22.y, p2.x, NaN, p2.y);
            indices.push(il2, il1, il + 4, il1, il + 5, il + 4);
            lastPoint2 = [p2.x, p2.y];
            pathTypes.push("square-right");
            lengths.push(0);
          } else {
            vertices.push(v22.x, NaN, v22.y);
            indices.push(il2, il1, il + 4);
            lastPoint2 = [v22.x, v22.y];
            pathTypes.push("right");
            lengths.push(0);
          }
        } else if (length2 < length1) {
          const nexPoint2 = path[path.length - 2 - i];
          const nv2 = new Vector2(nexPoint2[0], nexPoint2[1]);
          const angle = getAngle(v21, v22, nv2);
          const p1 = projectPoint(v21, v22, length1);
          const p2 = projectPoint(v11, v12, length2);
          const il = vertices.length / 3;
          const il1 = il + 1;
          const il2 = il + 2;
          vertices.push(
            lastPoint1[0],
            NaN,
            lastPoint1[1],
            p2.x,
            NaN,
            p2.y,
            v22.x,
            NaN,
            v22.y,
            lastPoint2[0],
            NaN,
            lastPoint2[1]
          );
          indices.push(il, il1, il2, il2, il + 3, il);
          lastPoint2 = point2;
          pathTypes.push("straight");
          if (angle.toFixed(1) === "90.0" && i < midLength - 1) {
            vertices.push(p1.x, NaN, p1.y, v12.x, NaN, v12.y);
            indices.push(il2, il1, il + 4, il1, il + 5, il + 4);
            lastPoint1 = [p1.x, p1.y];
            pathTypes.push("square-left");
            lengths.push(0);
          } else {
            vertices.push(v12.x, NaN, v12.y);
            indices.push(il2, il1, il + 4);
            lastPoint1 = [v12.x, v12.y];
            pathTypes.push("left");
            lengths.push(0);
          }
        } else {
          pathTypes.push("straight");
          const il = vertices.length / 3;
          const il2 = il + 2;
          vertices.push(
            lastPoint1[0],
            NaN,
            lastPoint1[1],
            v12.x,
            NaN,
            v12.y,
            v22.x,
            NaN,
            v22.y,
            lastPoint2[0],
            NaN,
            lastPoint2[1]
          );
          indices.push(il, il + 1, il2, il2, il + 3, il);
          lastPoint1 = point1;
          lastPoint2 = point2;
        }
      }

      const slope = (zSup - zInf) / linearLength;

      // TODO : if slope is known vectorize below by putting it in the rolling above
      let oi = 0;
      let startingZ = zInf;
      let ni = vertices.length / 3;
      const stop = pathTypes.length - 1;
      for (let i = 0; i <= stop; i++) {
        const startIdx = oi * 3;
        const pathType = pathTypes[i];
        const newZ = slope * lengths[i] + startingZ;
        if (pathType === "straight") {
          vertices[startIdx + 1] = startingZ;
          vertices[startIdx + 4] = newZ;
          vertices[startIdx + 7] = newZ;
          vertices[startIdx + 10] = startingZ;
          startingZ = newZ;
          if (height) {
            const pt1 = vertices.slice(startIdx + 0, startIdx + 3);
            const pt2 = vertices.slice(startIdx + 3, startIdx + 6);
            const pt3 = vertices.slice(startIdx + 6, startIdx + 9);
            const pt4 = vertices.slice(startIdx + 9, startIdx + 12);
            vertices.push(
              pt1[0],
              pt1[1] + height,
              pt1[2],
              pt2[0],
              pt2[1] + height,
              pt2[2],
              pt3[0],
              pt3[1] + height,
              pt3[2],
              pt4[0],
              pt4[1] + height,
              pt4[2]
            );
            indices.push(
              ni,
              ni + 1,
              ni + 2,
              ni,
              ni + 2,
              ni + 3, // top // 4, 5, 6, 4, 6, 7,
              oi + 3,
              ni + 3,
              ni + 2,
              oi + 3,
              ni + 2,
              oi + 2, // left // 3, 7, 6, 3, 6, 2,
              oi + 1,
              ni + 1,
              ni,
              oi + 1,
              ni,
              oi // right // 1, 5, 4, 1, 4, 0,
            );
            if (i === 0) {
              indices.push(oi, ni, ni + 3, oi, ni + 3, oi + 3); // front // 0, 4, 7, 0, 7, 3,
            } else if (i === stop) {
              indices.push(oi + 1, ni + 1, ni + 2, oi + 1, ni + 2, oi + 2); // back // 1, 5, 6, 1, 6, 2,
            }
            ni += 4;
          } else {
            material.side = 2;
          }
          oi += 4;
        } else if (pathType === "right") {
          vertices[startIdx + 1] = newZ;
          if (height) {
            const pt1 = vertices.slice(startIdx, startIdx + 3);
            vertices.push(pt1[0], pt1[1] + height, pt1[2]);
            indices.push(
              ni - 3,
              ni,
              ni - 2, // top
              oi - 2,
              ni - 2,
              ni,
              oi - 2,
              ni,
              oi // left
            );
            if (i === stop) {
              indices.push(oi, ni - 3, ni, oi, oi - 3, ni - 3); // back
            }
            ni += 1;
          } else {
            material.side = 2;
          }
          oi += 1;
        } else if (pathType === "left") {
          vertices[startIdx + 1] = newZ;
          if (height) {
            const pt1 = vertices.slice(startIdx, startIdx + 3);
            vertices.push(pt1[0], pt1[1] + height, pt1[2]);
            indices.push(
              ni - 3,
              ni,
              ni - 2, // top
              oi - 3,
              ni,
              ni - 3,
              oi - 3,
              oi,
              ni // right
            );
            if (i === stop) {
              indices.push(oi, ni - 2, ni, oi, oi - 2, ni - 2); // back
            }
            ni += 1;
          } else {
            material.side = 2;
          }
          oi += 1;
        } else if (pathType === "square-right") {
          vertices[(oi + 0) * 3 + 1] = newZ;
          vertices[(oi + 1) * 3 + 1] = newZ;
          if (height) {
            const pt1 = vertices.slice(startIdx + 0, startIdx + 3);
            const pt2 = vertices.slice(startIdx + 3, startIdx + 6);
            vertices.push(
              pt1[0],
              pt1[1] + height,
              pt1[2],
              pt2[0],
              pt2[1] + height,
              pt2[2]
            );
            indices.push(
              ni - 3,
              ni,
              ni - 2,
              ni - 3,
              ni + 1,
              ni, // top // 4, 5, 6, 4, 6, 7,
              oi,
              ni - 2,
              ni,
              oi,
              oi - 2,
              ni - 2, // left // 3, 7, 6, 3, 6, 2,
              oi,
              ni,
              ni + 1,
              oi,
              ni + 1,
              oi + 1 // front // 0, 4, 7, 0, 7, 3,
            );
            ni += 2;
          } else {
            material.side = 2;
          }
          oi += 2;
        } else if (pathType === "square-left") {
          vertices[(oi + 0) * 3 + 1] = newZ;
          vertices[(oi + 1) * 3 + 1] = newZ;

          if (height) {
            const pt1 = vertices.slice(startIdx + 0, startIdx + 3);
            const pt2 = vertices.slice(startIdx + 3, startIdx + 6);
            vertices.push(
              pt1[0],
              pt1[1] + height,
              pt1[2],
              pt2[0],
              pt2[1] + height,
              pt2[2]
            );
            indices.push(
              ni - 3,
              ni,
              ni - 2,
              ni - 3,
              ni + 1,
              ni, // top // 4, 5, 6, 4, 6, 7,
              oi - 3,
              ni + 1,
              ni - 3,
              oi - 3,
              oi + 1,
              ni + 1, // right // 1, 5, 4, 1, 4, 0,
              oi + 1,
              ni,
              ni + 1,
              oi + 1,
              oi,
              ni // front // 0, 4, 7, 0, 7, 3,
            );
            ni += 2;
          } else {
            material.side = 2;
          }
          oi += 2;
        }
      }
      // if (height) {
      //   const nbPoints = vertices.length / 3;
      //   const i4 = nbPoints / 2;
      //   const i7 = i4 + 3;
      //   // front
      //   indices.push(0, i4, i7, 0, i7, 3);
      //   // back
      //   indices.push(i4 - 3, nbPoints - 3, nbPoints - 2, i4 - 3, nbPoints - 2, i4 - 2);
      // }
    } else {
      console.log(
        "Error drawing stairs, needs more than 2 points to construct triangles"
      );
    }

    const shapeGeometry = new BufferGeometry();
    shapeGeometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(vertices), 3)
    );
    shapeGeometry.setIndex(indices);
    shapeGeometry.computeVertexNormals();

    // material.wireframe = true;
    const shapeMesh = new Mesh(shapeGeometry, material);
    shapeMesh.layers.enable(1);

    return {object: shapeMesh};
  }

  drawPolygon3D3({path3, material, height}) {
    // in 3D (not only in horizontal plane)
    if (!path3) return;

    // init

    const points = path3.map((p) => ({x: p[0], y: p[1], z: p[2]}));

    // general case with points

    const a = new Vector3(points[0].x, points[0].y, points[0].z);
    const b = new Vector3(points[1].x, points[1].y, points[1].z);
    const c = new Vector3(points[2].x, points[2].y, points[2].z);

    const normal = new Vector3();

    const triangle = new Triangle(a, b, c);
    triangle.getNormal(normal);

    const Z = new Vector3(0, 1, 0);

    // test

    let shouldInverse = false;
    const product = Z.dot(normal);
    if (product < -0.9) shouldInverse = true;

    // plane object

    const planeGeo = new PlaneBufferGeometry(1, 1);
    const plane = new Mesh(planeGeo, material);

    const q = new Quaternion();
    q.setFromUnitVectors(new Vector3(0, 0, 1), normal);

    plane.applyQuaternion(q);
    plane.position.copy(a);
    plane.updateMatrixWorld();

    // projection plane

    const projPlane = new Plane();
    triangle.getPlane(projPlane);

    // get x,y coordinates

    const points2D = points.map((point) => {
      const pointV = new Vector3(point.x, point.y, point.z);
      const projPointV = new Vector3();
      projPlane.projectPoint(pointV, projPointV);
      plane.worldToLocal(projPointV);
      return new Vector2(projPointV.x, projPointV.y);
    });

    // const _points2D = points2D.map(({x, y}) => ({x, y}));
    // const isClockwise = testIsClockwise(_points2D);
    // console.log("DrawShape", isClockwise, height, _points2D);
    // if (!isClockwise) points2D.reverse();

    // create shape

    const shape = new Shape(points2D);

    // create polygon 3D

    let geometry;
    if (height) {
      const extrudeSettings = {
        // steps: 2,
        depth: shouldInverse ? -height : height,
        bevelEnabled: false,
      };
      geometry = new ExtrudeGeometry(shape, extrudeSettings);
    } else {
      geometry = new ShapeBufferGeometry(shape);
      material.side = 2;
    }
    const polygon3D = new Mesh(geometry, material);
    polygon3D.layers.enable(1);

    // move polygon

    polygon3D.applyQuaternion(q);
    polygon3D.position.copy(a);

    return {object: polygon3D};
  }

  drawBeam({path, zInf, material, height, heightE, heightN, side, dim1, dim3}) {
    if (path.length % 2 !== 0) path = path.slice(0, -1);

    if (side !== "UP") {
      if (height) zInf += height;
      else if (heightE && !heightN) zInf += heightE;
      else if (heightN && !heightE) zInf += heightN;
      else zInf += heightE + heightN;
    }

    let newPoints;
    if (dim3) {
      const len = dim1 + dim3;
      const v11 = new Vector2(path[0][0], path[0][1]);
      const v12 = new Vector2(path[3][0], path[3][1]);
      const v21 = new Vector2(path[1][0], path[1][1]);
      const v22 = new Vector2(path[2][0], path[2][1]);
      const p1 = projectPoint(v12, v11, len);
      const p2 = projectPoint(v22, v21, len);
      const p3 = projectPoint(v21, v22, len);
      const p4 = projectPoint(v11, v12, len);
      newPoints = [p1, p2, p3, p4].map((p) => [p.x, p.y]);
    }

    let separation, end;
    if (heightE && heightN) {
      if (side === "UP") separation = heightN;
      else separation = -heightN;
    }
    if (height && side === "UP") end = height;
    else if (height) end = -height;

    let idx = 0;
    let indices = [];
    let vertices = [];
    const points = newPoints ? newPoints : path;

    vertices.push(
      points[0][0],
      zInf,
      points[0][1],
      points[1][0],
      zInf,
      points[1][1],
      points[2][0],
      zInf,
      points[2][1],
      points[3][0],
      zInf,
      points[3][1]
    );
    indices.push(0, 3, 2, 2, 1, 0); // bottom
    if (separation) {
      const zSep = zInf + separation;
      vertices.push(
        points[0][0],
        zSep,
        points[0][1],
        points[1][0],
        zSep,
        points[1][1],
        points[2][0],
        zSep,
        points[2][1],
        points[3][0],
        zSep,
        points[3][1]
      );
      if (!dim3) indices.push(4, 5, 6, 6, 7, 4); // top
      indices.push(
        3,
        7,
        6,
        6,
        2,
        3, // left
        0,
        1,
        5,
        5,
        4,
        0, // right
        0,
        4,
        7,
        7,
        3,
        0, // front
        2,
        6,
        5,
        5,
        1,
        2 // back
      );
      if (newPoints) {
        vertices.push(
          path[0][0],
          zSep,
          path[0][1],
          path[1][0],
          zSep,
          path[1][1],
          path[2][0],
          zSep,
          path[2][1],
          path[3][0],
          zSep,
          path[3][1]
        );
        idx += 4;
      }
    }
    if (end) {
      const zEnd = zInf + end;
      vertices.push(
        path[0][0],
        zEnd,
        path[0][1],
        path[1][0],
        zEnd,
        path[1][1],
        path[2][0],
        zEnd,
        path[2][1],
        path[3][0],
        zEnd,
        path[3][1]
      );
      const i1 = idx + 1;
      const i2 = idx + 2;
      const i3 = idx + 3;
      const i4 = idx + 4;
      const i5 = idx + 5;
      const i6 = idx + 6;
      const i7 = idx + 7;
      let newIndices = [];
      if (dim3) newIndices.push(idx, i3, i2, i2, i1, idx); // bottom
      newIndices.push(
        i4,
        i5,
        i6,
        i6,
        i7,
        i4, // top
        i3,
        i7,
        i6,
        i6,
        i2,
        i3, // left
        idx,
        i1,
        i5,
        i5,
        i4,
        idx, // right
        idx,
        i4,
        i7,
        i7,
        i3,
        idx, // front
        i2,
        i6,
        i5,
        i5,
        i1,
        i2 // back
      );
      if (separation) newIndices = newIndices.map((i) => i + 4);
      indices.push(...newIndices);
    }

    const shapeGeometry = new BufferGeometry();
    shapeGeometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(vertices), 3)
    );
    shapeGeometry.setIndex(indices);
    shapeGeometry.computeVertexNormals();

    shapeGeometry.clearGroups();
    if (separation) {
      shapeGeometry.addGroup(0, 30, 0);
      shapeGeometry.addGroup(30, indices.length, 1);
    } else if (heightN) {
      shapeGeometry.addGroup(0, indices.length, 0);
    } else {
      shapeGeometry.addGroup(0, indices.length, 1);
    }

    const shapeMesh = new Mesh(shapeGeometry, material);
    shapeMesh.layers.enable(1);

    return {object: shapeMesh};
  }

  drawSlopingPolygon({path3, material, height, slopingA}) {
    // in 3D (not only in horizontal plane)
    if (!path3) return;

    // init

    const originPoints = path3.map((p) => ({x: p[0], y: p[1], z: p[2]}));

    const angleRad = slopingA * (Math.PI / 180);
    const pointA = new Vector3(
      originPoints[0].x,
      originPoints[0].y,
      originPoints[0].z
    );
    const pointB = new Vector3(
      originPoints[1].x,
      originPoints[1].y,
      originPoints[1].z
    );
    const axis = pointB.clone().sub(pointA).normalize();

    const points = [originPoints[0], originPoints[1]];
    for (let i = 2; i < originPoints.length - 1; i++) {
      const pointC = new Vector3(
        originPoints[i].x,
        originPoints[i].y,
        originPoints[i].z
      );
      const vAtoC = pointC.clone().sub(pointA);
      const projectedPoint = pointA
        .clone()
        .add(vAtoC.clone().projectOnVector(axis));
      const projectionLength =
        projectedPoint.distanceTo(pointC) / Math.cos(angleRad);
      const dir = pointC
        .clone()
        .sub(projectedPoint)
        .normalize()
        .multiplyScalar(projectionLength);
      const pointD = projectedPoint.clone().add(dir);
      points.push({x: pointD.x, y: pointD.y, z: pointD.z});
    }

    // general case with points

    const a = new Vector3(points[0].x, points[0].y, points[0].z);
    const b = new Vector3(points[1].x, points[1].y, points[1].z);
    const c = new Vector3(points[2].x, points[2].y, points[2].z);

    const normal = new Vector3();

    const triangle = new Triangle(a, b, c);
    triangle.getNormal(normal);

    const Z = new Vector3(0, 1, 0);

    // test

    let shouldInverse = false;
    const product = Z.dot(normal);
    if (product < -0.9) shouldInverse = true;

    // plane object

    const planeGeo = new PlaneBufferGeometry(1, 1);
    const plane = new Mesh(planeGeo, material);

    const q = new Quaternion();
    q.setFromUnitVectors(new Vector3(0, 0, 1), normal);

    plane.applyQuaternion(q);
    plane.position.copy(a);
    plane.updateMatrixWorld();

    // projection plane

    const projPlane = new Plane();
    triangle.getPlane(projPlane);

    // get x,y coordinates

    const points2D = points.map((point) => {
      const pointV = new Vector3(point.x, point.y, point.z);
      const projPointV = new Vector3();
      projPlane.projectPoint(pointV, projPointV);
      plane.worldToLocal(projPointV);
      return new Vector2(projPointV.x, projPointV.y);
    });

    // create shape

    const shape = new Shape(points2D);

    // create polygon 3D
    let geometry;
    if (height) {
      const extrudeSettings = {
        // steps: 2,
        depth: shouldInverse ? -height : height,
        bevelEnabled: false,
      };
      geometry = new ExtrudeGeometry(shape, extrudeSettings);
    } else {
      geometry = new ShapeBufferGeometry(shape);
      material.side = 2;
    }
    const polygon3D = new Mesh(geometry, material);
    polygon3D.layers.enable(1);

    // move polygon

    polygon3D.applyQuaternion(q);
    polygon3D.position.copy(a);
    polygon3D.updateMatrixWorld();

    // rotate around the line from 2 firsts points

    const q2 = new Quaternion();

    q2.setFromAxisAngle(axis, angleRad);

    polygon3D.applyQuaternion(q2);
    polygon3D.position.sub(pointA);
    polygon3D.position.applyQuaternion(q2);
    polygon3D.position.add(pointA);

    return {object: polygon3D};
  }

  drawSlope3D({path, zInf, slopeH, height, material}) {
    return this.drawStairs({
      path,
      zInf,
      zSup: zInf + slopeH,
      material,
      height,
    });
  }

  drawPolyline({
    path,
    zInf,
    height,
    heights,
    material,
    side,
    faces,
    drawingShape,
  }) {
    if (path.length % 2 !== 0) path = path.slice(0, -1);

    const midLength = path.length / 2;

    if (drawingShape === "BRIDGE" && side !== "UP" && height) {
      zInf += height;
      height = -height;
    }

    let pointsZ = [];
    if (!heights && height) {
      pointsZ = new Array(midLength).fill(height);
    } else if (heights) {
      pointsZ = heights.split(";").map((f) => parseFloat(f));
      const zLength = pointsZ.length;
      if (zLength < midLength)
        pointsZ = [
          ...pointsZ,
          ...new Array(midLength - zLength).fill(pointsZ[zLength - 1]),
        ];
    }

    let indices = [];
    let vertices = [];

    if (path.length === 4) {
      vertices.push(
        path[0][0],
        zInf,
        path[0][1],
        path[1][0],
        zInf,
        path[1][1],
        path[2][0],
        zInf,
        path[2][1],
        path[3][0],
        zInf,
        path[3][1]
      );
      indices.push(0, 3, 2, 2, 1, 0); // bottom
      if (pointsZ.length > 0) {
        vertices.push(
          path[0][0],
          zInf + pointsZ[0],
          path[0][1],
          path[1][0],
          zInf + pointsZ[1],
          path[1][1],
          path[2][0],
          zInf + pointsZ[1],
          path[2][1],
          path[3][0],
          zInf + pointsZ[0],
          path[3][1]
        );
        if (drawingShape !== "BRIDGE") {
          indices.push(4, 5, 6, 6, 7, 4); // top
          indices.push(3, 7, 6, 6, 2, 3); // left
          indices.push(0, 1, 5, 5, 4, 0); // right
          indices.push(0, 4, 7, 7, 3, 0); // front
          indices.push(2, 6, 5, 5, 1, 2); // back
        } else if (faces !== "SIDE") {
          indices.push(0, 4, 7, 7, 3, 0); // front
          indices.push(2, 6, 5, 5, 1, 2); // back
        } else {
          indices.push(3, 7, 6, 6, 2, 3); // left
          indices.push(0, 1, 5, 5, 4, 0); // right
        }
      } else {
        material.side = 2;
      }
    } else if (path.length > 2) {
      const stop = midLength - 1;
      let lastPoint1 = path[0];
      let lastPoint2 = path[path.length - 1];
      for (let i = 1; i < midLength; i++) {
        const point1 = path[i];
        const point2 = path[path.length - 1 - i];
        const v12 = new Vector2(point1[0], point1[1]);
        const v22 = new Vector2(point2[0], point2[1]);
        const il = vertices.length / 3;
        const il2 = il + 2;
        vertices.push(
          lastPoint1[0],
          zInf,
          lastPoint1[1],
          v12.x,
          zInf,
          v12.y,
          v22.x,
          zInf,
          v22.y,
          lastPoint2[0],
          zInf,
          lastPoint2[1]
        );
        indices.push(il, il + 3, il2, il2, il + 1, il); // bottom 032210
        if (pointsZ.length > 0) {
          const z = pointsZ[i];
          const lz = pointsZ[i - 1];
          vertices.push(
            lastPoint1[0],
            zInf + lz,
            lastPoint1[1],
            v12.x,
            zInf + z,
            v12.y,
            v22.x,
            zInf + z,
            v22.y,
            lastPoint2[0],
            zInf + lz,
            lastPoint2[1]
          );
          const il1 = il + 1;
          const il3 = il + 3;
          const il4 = il + 4;
          const il5 = il + 5;
          const il6 = il + 6;
          const il7 = il + 7;
          if (drawingShape !== "BRIDGE") {
            indices.push(
              il4,
              il5,
              il6,
              il6,
              il7,
              il4, // top 456674
              il3,
              il7,
              il6,
              il6,
              il2,
              il3, // left 376623
              il,
              il1,
              il5,
              il5,
              il4,
              il // right 015540
            );
            if (i === 1) {
              indices.push(il, il4, il7, il7, il3, il); // front 047730
            } else if (i === stop) {
              indices.push(il2, il6, il5, il5, il1, il2); // back 265512
            }
          } else if (faces !== "SIDE") {
            if (i === 1) {
              indices.push(il, il4, il7, il7, il3, il); // front 047730
            } else if (i === stop) {
              indices.push(il2, il6, il5, il5, il1, il2); // back 265512
            }
          } else {
            indices.push(
              il3,
              il7,
              il6,
              il6,
              il2,
              il3, // left 376623
              il,
              il1,
              il5,
              il5,
              il4,
              il // right 015540
            );
          }
        } else {
          material.side = 2;
        }
        lastPoint1 = point1;
        lastPoint2 = point2;
      }
    } else {
      console.log("Error, needs more than 2 points to construct triangles");
    }

    const shapeGeometry = new BufferGeometry();
    shapeGeometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(vertices), 3)
    );
    shapeGeometry.setIndex(indices);
    shapeGeometry.computeVertexNormals();

    // material.wireframe = true;
    const shapeMesh = new Mesh(shapeGeometry, material);
    shapeMesh.layers.enable(1);

    return {object: shapeMesh};
  }

  /*
   * Bank
   */

  _getVerticesArrayFromABCD(A, B, C, D) {
    // 1 line : A,B, 2nd line : CD. Get 2 triangles
    return [
      A.x,
      A.y,
      A.z,
      B.x,
      B.y,
      B.z,
      C.x,
      C.y,
      C.z,

      B.x,
      B.y,
      B.z,
      D.x,
      D.y,
      D.z,
      C.x,
      C.y,
      C.z,
    ];
  }
  drawBank({pathInf, pathSup, zInf, zSup, material}) {
    const pointsInf = pathInf.map((p) => [p[0], zInf, p[1]]);
    const pointsSup = pathSup.map((p) => [p[0], zSup, p[1]]);

    const indices = [];
    const vertices = [pointsInf[0], pointsSup[0]];
    const count = pointsInf.length - 1;
    for (let i = 0; i < count; i++) {
      vertices.push(pointsInf[i + 1], pointsSup[i + 1]);
      const i2 = i * 2;
      indices.push(i2, i2 + 2, i2 + 3, i2, i2 + 3, i2 + 1);
    }

    const geometry = new BufferGeometry();
    geometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(vertices.flat()), 3)
    );
    geometry.setIndex(indices);
    geometry.computeVertexNormals();

    const mesh = new Mesh(geometry, material);

    mesh.layers.enable(1);

    return {object: mesh};
  }

  getBoxGeometry(path1, path2) {
    const geometry = new BufferGeometry();
    const {positions, normals} = getBoxVerticesFromPoints([...path1, ...path2]);

    geometry.setAttribute(
      "position",
      new BufferAttribute(new Float32Array(positions), 3)
    );
    geometry.setAttribute(
      "normal",
      new BufferAttribute(new Float32Array(normals), 3)
    );
    return geometry;
  }
}
