import articleTypesArrayToMap from "./articleTypesArrayToMap";

export default function remoteToStateArticle(article) {
  const stateArticle = {...article};
  //
  stateArticle.articlesGroupId = stateArticle.articlesGroup;
  //
  if (stateArticle.articlesGroup) delete stateArticle.articlesGroup;
  //
  if (stateArticle.types && Array.isArray(stateArticle.types)) {
    stateArticle.types = articleTypesArrayToMap(stateArticle.types);
  }
  if (stateArticle.coreTypes && Array.isArray(stateArticle.coreTypes)) {
    stateArticle.coreTypes = articleTypesArrayToMap(stateArticle.coreTypes);
  }
  return stateArticle;
}
