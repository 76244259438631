import {useState} from "react";

import DialogAddInputs from "./DialogAddInputs";

import FileSelectorButton from "Features/files/components/FileSelectorButton";

export default function ButtonDialogAddInputs({editor3d}) {
  // strings

  const addS = "Ajouter des fichiers";

  // state

  const [tempFiles, setTempFiles] = useState([]);
  const open = tempFiles?.length > 0;

  // handlers

  function handleFilesChange(files) {
    setTempFiles(files);
  }
  function handleClose() {
    setTempFiles([]);
  }

  return (
    <>
      <FileSelectorButton
        onFileChange={handleFilesChange}
        multiple={true}
        buttonName={addS}
        buttonColor="inherit"
        buttonVariant="text"
      />
      <DialogAddInputs
        editor3d={editor3d}
        open={open}
        onClose={handleClose}
        tempFiles={tempFiles}
      />
    </>
  );
}
