import {useDispatch} from "react-redux";
import {fetchRemoteModel} from "Features/viewer3D/viewer3DSlice";
import {CloudDownload as Download} from "@mui/icons-material";
import {Box, Typography, Button} from "@mui/material";
import useAccessToken from "Features/auth/useAccessToken";

export default function SectionDownloadPackage({model, scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const upToDate = "Modèle à jour";
  const outOfDate = "Mettre à jour le modèle";
  const downloadS = "Mise à jour";
  const idS = "Id :";

  const label = upToDate;

  // handlers

  function handleDownload() {
    console.log("download remote model for", model);
    if (accessToken && model?.remoteId) {
      dispatch(
        fetchRemoteModel({
          remoteId: model.remoteId,
          accessToken,
          sceneClientId: scene?.clientId,
        })
      );
    }
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 1,
        }}
      >
        <Box>
          <Typography variant="body2">{label}</Typography>
        </Box>
        <Box sx={{color: "text.secondary"}}>
          <Button
            startIcon={<Download />}
            size="small"
            color="inherit"
            onClick={handleDownload}
          >
            {downloadS}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          p: 0.5,
          px: 1,
          width: 1,
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <Typography
          sx={{fontSize: 12, fontStyle: "italic"}}
          color="text.secondary"
          noWrap
        >
          {idS + " " + model?.id}
        </Typography>
      </Box>
    </Box>
  );
}
