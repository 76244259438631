import {useSelector, useDispatch} from "react-redux";

import {setSelectedElementTypeGroupIdsInHome} from "../boxhomeSlice";

import {Box} from "@mui/material";
import ListElementTypeGroupsMultiple from "Features/elementTypes/components/ListElementTypeGroupsMultiple";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import SwitchLinkedTypeGroupsOnly from "./SwitchLinkedTypeGroupsOnly";

export default function SectionElementTypeGroupsInHome({scene}) {
  const dispatch = useDispatch();

  // data

  const elementTypeGroups = useElementTypesGroupsProxyByScene(scene);
  const packages = usePackagesByScene(scene);

  const linkedTypeGroupsOnly = useSelector(
    (s) => s.boxhome.linkedTypeGroupsOnly
  );

  // data selection

  const selectedGroupIds = useSelector(
    (s) => s.boxhome.selectedElementTypeGroupIdsInHome
  );

  // helpers

  let typesWithQties = elementTypeGroups.map((group) => {
    const packagesRelatedToGroup = packages.filter(
      (model) =>
        model.elementTypesGroupIds?.includes(group.id) ||
        model.restrictedTypes?.includes(group.id)
    );
    const qtyValue = packagesRelatedToGroup.length;
    const qtyUnit = "calques";
    return {...group, qtyValue, qtyUnit, name: group?.name ?? "-?-"};
  });

  // helpers

  if (linkedTypeGroupsOnly)
    typesWithQties = typesWithQties.filter((type) => type.qtyValue > 0);

  // helpers - sort

  typesWithQties = typesWithQties.sort((a, b) => a.name.localeCompare(b.name));

  // handlers

  function handleSelectionChangeGroup(groupIds) {
    dispatch(setSelectedElementTypeGroupIdsInHome(groupIds));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyItems: "stretch",
        pt: 2,
        flexDirection: "column",
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box sx={{py: 1}}>
        <SwitchLinkedTypeGroupsOnly />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "auto",
          minHeight: 0,
        }}
      >
        <ListElementTypeGroupsMultiple
          items={typesWithQties}
          selection={selectedGroupIds}
          onSelectionChange={handleSelectionChangeGroup}
          multipleSelection={false}
          divider={false}
        />
      </Box>
    </Box>
  );
}
