import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import { setSelectedPackageId } from "../packagesSlice";

import { setSelectedListItemId } from "Features/leftPanel/leftPanelSlice";

export default function ButtonBackPackagesInSelectionPanel() {
  const dispatch = useDispatch();

  // string

  const backS = "New";

  // handlers

  function handleClick() {
    dispatch(setSelectedPackageId(null));
    dispatch(setSelectedListItemId(null));
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {backS}
    </Button>
  );
}