import {createSlice} from "@reduxjs/toolkit";

const syncSlice = createSlice({
  name: "sync",
  initialState: {
    remoteDataOnly: true,
    isSyncing: false,
    startDownloadingRemote: false, // global state used in useScene. If local state, true>false>true...
    gotRemoteScene: false, // used in useClientAndRemoteSceneModels to trigger donwload.
  },
  reducers: {
    setRemoteDataOnly: (state, action) => {
      state.remoteDataOnly = action.payload;
    },
    setIsSyncing: (state, action) => {
      state.isSyncing = action.payload;
    },
    setStartDownloadingRemote: (state, action) => {
      state.startDownloadingRemote = action.payload;
    },
    setGotRemoteScene: (state, action) => {
      state.gotRemoteScene = action.payload;
    },
  },
});

export const {
  setRemoteDataOnly,
  setIsSyncing,
  setStartDownloadingRemote,
  setGotRemoteScene,
} = syncSlice.actions;

export default syncSlice.reducer;
