import React from "react";

import {Card, CardContent, Typography, alpha} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";

export default function CardMarkersDescription() {
  const {t} = useTranslation("viewer3D");
  return (
    <Card
      sx={{
        width: "100%",
        color: "common.white",
        background: (theme) => alpha(theme.palette.secondary.main, 0.9),
      }}
    >
      <CardContent>
        <Typography variant="body2">
          <b>{t("viewer3D:markers.description.title")}</b>
        </Typography>
        <Typography variant="body2">
          {t("viewer3D:markers.description.detail")}
        </Typography>
      </CardContent>
    </Card>
  );
}
