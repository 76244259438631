import React from "react";

import useDeleteSceneVersion from "../hooks/useDeleteSceneVersion";

import {Box, Typography, Button} from "@mui/material";
import {Delete} from "@mui/icons-material";

export default function ButtonDeleteVersion({scene, version}) {
  // strings

  const deleteS = "Delete";

  // data

  const deleteSceneVersion = useDeleteSceneVersion(scene);

  // handlers

  function handleDelete() {
    deleteSceneVersion(version);
  }

  return (
    <Box sx={{}}>
      <Button
        startIcon={<Delete />}
        color="inherit"
        size="small"
        onClick={handleDelete}
      >
        {deleteS}
      </Button>
    </Box>
  );
}
