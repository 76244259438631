import {useState} from "react";
import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {Box, TextField, Button} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";
import {postOrganisation} from "Features/organisations/organisationsSlice";

export default function FormCreateOrganisation({onCreated, onCancel}) {
  const dispatch = useDispatch();

  //  strings

  const createString = "Créer";
  const cancelString = "Annuler";

  // data

  const accessToken = useAccessToken();

  // local state

  const [name, setName] = useState("");
  const [emailDomain, setEmailDomain] = useState("");
  const [loading, setLoading] = useState(false);

  // handlers

  function handleNameChange(e) {
    setName(e.target.value);
  }

  function handleEmailDomainChange(e) {
    setEmailDomain(e.target.value);
  }

  async function handleSaveClick() {
    try {
      if (loading) return;
      const organisation = {name, emailDomain};
      setLoading(true);
      const result = await dispatch(
        postOrganisation({accessToken, organisation})
      );
      const newOrganisation = unwrapResult(result);
      console.log("new organisation created:", newOrganisation);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      onCreated();
    }
  }

  function handleCancelClick() {
    onCancel();
  }

  return (
    <Box sx={{p: 2, width: 300}}>
      <Box sx={{mb: 2}}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          label="Nom"
          value={name}
          onChange={handleNameChange}
        />
      </Box>
      <Box>
        <TextField
          fullWidth
          size="small"
          value={emailDomain}
          variant="outlined"
          label="Domaine"
          onChange={handleEmailDomainChange}
        />
      </Box>

      <Box sx={{display: "flex", alignItems: "center", mt: 2}}>
        <Button variant="outlined" color="primary" onClick={handleCancelClick}>
          {cancelString}
        </Button>
        <Button
          sx={{ml: 2}}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSaveClick}
          disabled={loading}
        >
          {createString}
        </Button>
      </Box>
    </Box>
  );
}
