const styleRankMap = {
  title1: 1,
  title2: 2,
  title3: 3,
  overview: 4, // ouvrage de synthèse
  element: 4,
  detail: 5,
  subDetail: 6,
  offset: 6, // (to be removed)
};

export default styleRankMap;
