import {useDispatch} from "react-redux";

import {setStyle, setNum} from "../elementTyporSlice";

import useSelectedElementTypesGroup from "Features/elementTypes/hooks/useSelectedElementTypesGroup";
import useSceneModule from "Features/navigation/useSceneModule";

import {Box, TextField, Typography, Button} from "@mui/material";

import ListElementTypes from "Features/elementTypes/components/ListElementTypes";
import SectionTitleAndStyle from "./SectionTitleAndStyle";
import useTempElementType from "../hooks/useTempElementType";

export default function SectionStepTree({scene}) {
  const dispatch = useDispatch();

  // strings

  const moveToTopS = "Placer en 1er";

  // data

  const sceneModule = useSceneModule();
  const inEditMeasurements = sceneModule === "EDIT_MEASUREMENTS";
  const group = useSelectedElementTypesGroup(scene, {inEditMeasurements});
  const tempElementType = useTempElementType();

  // helpers

  const elementTypes = Array.isArray(group?.elementTypes)
    ? group.elementTypes
    : [];

  const newElementTypes = [...elementTypes, tempElementType];

  const style = tempElementType?.style;
  const num = tempElementType?.num ?? null;

  // helpers options

  const options = {hideQuantity: true, hideNum: true, enableClickOnTitle: true};

  // handlers

  function handleStyleChange(style) {
    dispatch(setStyle(style));
  }

  function handleNumChange(e) {
    dispatch(setNum(e.target.value));
  }

  function handleTypeClick(type) {
    dispatch(setNum(type.num + ".0.0.1"));
  }

  function handleMoveToTop() {
    dispatch(setNum("0"));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        width: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          minHeight: 0,
          width: 1,
          bgcolor: "common.white",
          flexGrow: 1,
        }}
      >
        <ListElementTypes
          elementTypes={newElementTypes}
          options={options}
          selectedElementType={tempElementType}
          onClick={handleTypeClick}
        />
      </Box>
      <Box sx={{color: "text.secondary", px: 2}}>
        <Button color="inherit" size="small" onClick={handleMoveToTop}>
          {moveToTopS}
        </Button>
      </Box>
    </Box>
  );
}
