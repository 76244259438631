import {Box, Typography, ListItemButton} from "@mui/material";
import {Add} from "@mui/icons-material";

import getRessourceStyleObject from "../utils/getRessourceStyleObject";
import getRessourceQuantityLabel from "../utils/getRessourceQuantityLabel";

export default function ListItemButtonRessourceBasic({
  ressource,
  selected,
  options,
  onClick,
}) {
  // options

  const hideQuantity = options?.hideQuantity;
  const showQtyNet = options?.showQtyNet;

  // helper - styles

  const {fontWeight, bgcolor, color} = getRessourceStyleObject(ressource);

  // helper - qtyLabel

  const {quantity, label: qtyLabel} = getRessourceQuantityLabel(ressource, {
    showQtyNet,
  });

  // handler

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) onClick(ressource);
  }

  return (
    <ListItemButton onClick={handleClick} selected={selected} sx={{m: 0, p: 0}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          bgcolor,
          fontWeight,
          color,
          p: 0.5,
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography sx={{fontWeight, ml: 1, fontSize: 12}}>
            {ressource.num?.length > 0
              ? `${ressource.num} • ${ressource.name}`
              : ressource.name}
          </Typography>
        </Box>
        {!hideQuantity && !ressource.isTitle && (
          <Box sx={{ml: 1}}>
            <Typography
              sx={{
                fontSize: 12,
                color: quantity > 0 ? "text.secondary" : "divider",
              }}
            >
              {qtyLabel}
            </Typography>
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}
