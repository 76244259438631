import {
  Home,
  ViewInAr as Admin,
  ContentCopy as Data,
  Construction as Build,
  Error as Issues,
  ViewInAr as Measurements,
  //DataObject as ElementTypes,
  Category as ElementTypes,
  ViewInAr as Viewer3D,
  Image as Zones,
  VerticalSplit as Sectors,
  Adjust as Markers,
  CheckCircle as Progress,
  LocalGroceryStore as Ressources,
  ViewTimeline as Phases,
  Palette as EditMeasurements,
  Layers as Packages,
  Folder as Issuesets,
  TableChart as Datasets,
  //ContentCopy as Inputs,
  PictureAsPdf as Inputs,
  MeetingRoom as Rooms,
  Accessibility as Assistant,
  PictureAsPdf as AnnotatedPdf,
  AppShortcut as Bimapps,
  Launch as Sharedboxes,
  AutoStories as Library,
  FormatColorFill as Materials,
  Euro as Pricings,
  Update as Versions,
  Palette as Onboarding,
  Calculate as Relations,
  SettingsSuggest as Procedures,
  Note as Views,
  Summarize as Reports,
  Troubleshoot as Samples,
  Event as Surveys,
  GroupWork as Scopes,
  TableChart as Articles,
} from "@mui/icons-material";

export default function useSceneModuleIconMap() {
  return {
    HOME: <Home fontSize="small" color="inherit" />,
    ASSISTANT: <Assistant fontSize="small" color="inherit" />,
    ADMIN: <Admin fontSize="small" color="inherit" />,
    BUILD: <Build fontSize="small" color="inherit" />,
    INPUTS: <Inputs fontSize="small" color="inherit" />,
    DATA: <Data fontSize="small" color="inherit" />,
    VIEWER_3D: <Viewer3D fontSize="small" color="inherit" />,
    MARKERS: <Markers fontSize="small" color="inherit" />,
    ZONES: <Zones fontSize="small" color="inherit" />,
    SECTORS: <Sectors fontSize="small" color="inherit" />,
    ROOMS: <Rooms fontSize="small" color="inherit" />,
    PHASES: <Phases fontSize="small" color="inherit" />,
    VERSIONS: <Versions fontSize="small" color="inherit" />,
    MEASUREMENTS: <Measurements fontSize="small" color="inherit" />,
    EDIT_MEASUREMENTS: <EditMeasurements fontSize="small" color="inherit" />,
    ELEMENT_TYPES: <ElementTypes fontSize="small" color="inherit" />,
    RESSOURCES: <Ressources fontSize="small" color="inherit" />,
    PACKAGES: <Packages fontSize="small" color="inherit" />,
    ISSUESETS: <Issuesets fontSize="small" color="inherit" />,
    ISSUES: <Issues fontSize="small" color="inherit" />,
    PROGRESS: <Progress fontSize="small" color="inherit" />,
    DATASETS: <Datasets fontSize="small" color="inherit" />,
    ANNOTATED_PDF: <AnnotatedPdf fontSize="small" color="inherit" />,
    BIMAPPS: <Bimapps fontSize="small" color="inherit" />,
    SHAREDBOXES: <Sharedboxes fontSize="small" color="inherit" />,
    LIBRARY: <Library fontSize="small" color="inherit" />,
    MATERIALS: <Materials fontSize="small" color="inherit" />,
    PRICINGS: <Pricings fontSize="small" color="inherit" />,
    ONBOARDING: <Onboarding fontSize="small" color="inherit" />,
    RELATIONS: <Relations fontSize="small" color="inherit" />,
    PROCEDURES: <Procedures fontSize="small" color="inherit" />,
    VIEWS: <Views fontSize="small" color="inherit" />,
    REPORTS: <Reports fontSize="small" color="inherit" />,
    SURVEYS: <Surveys fontSize="small" color="inherit" />,
    SAMPLES: <Samples fontSize="small" color="inherit" />,
    SCOPES: <Scopes fontSize="small" color="inherit" />,
    ARTICLES: <Articles fontSize="small" color="inherit" />,
  };
}
