import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Typography,
  Chip,
  Paper,
  ClickAwayListener,
  Autocomplete,
  TextField,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import { setRoomsInScope } from "Features/measurements/measurementsSlice";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function SelectorRoomInScope({scene}) {
  const dispatch = useDispatch();

  // strings

  const allS = "Tous";

  // data

  const nameMap = useSceneModuleNameMap();
  const rooms = useRoomsByScene(scene);
  const roomsFilter = useSelector((s) => s.measurements.roomsInScope);

  // state

  const [open, setOpen] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  // helper - room

  const roomId = roomsFilter.length > 0 ? roomsFilter[0] : null;
  const roomInScope = rooms.find((s) => s.id === roomId);

  // helper - label

  let label = roomInScope?.name;
  if (!label) label = allS;
  const textFieldLabel = nameMap["ROOMS"];

  // handlers

  function handleMoreClick(e) {
    setOpen(true);
    setOpenAutocomplete(true);
  }
  function handleClose() {
    setOpen(false);
    setOpenAutocomplete(false);
  }

  function handleChange(room) {
    if (!room?.id) dispatch(setRoomsInScope([]));
    else dispatch(setRoomsInScope([room.id]));
    handleClose();
  }

  function handleClickAwayListener() {
    setOpen(false);
    setOpenAutocomplete(false);
  }

  return (
    <>
      {!open && (
        <Chip
          label={label}
          deleteIcon={<Down />}
          onDelete={handleMoreClick}
          size="small"
        />
      )}

      {open && (
        <Paper sx={{p: 1}}>
          <ClickAwayListener onClickAway={handleClickAwayListener}>
            <Box sx={{width: 150}}>
              <Autocomplete
                open={openAutocomplete}
                sx={{flexGrow: 1}}
                options={rooms}
                value={roomInScope}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option) => (
                  <Typography {...props} sx={{pl: 1, fontSize: 13}}>
                    {option?.name}
                  </Typography>
                )}
                onChange={(e, v) => handleChange(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    size="small"
                    label={
                      <Typography variant="body2">{textFieldLabel}</Typography>
                    }
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        fontSize: 13,
                      },
                    }}
                  />
                )}
              />
            </Box>
          </ClickAwayListener>
        </Paper>
      )}
    </>
  );
}
