import {keyframes} from "@mui/system";

const zoomInOut = keyframes`
0% {
  border-width : 0px;
  }
  50% {
    border-width : 3px;
  }
  100% {
    border-width : 0px;
  }
`;
export default zoomInOut;
