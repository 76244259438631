import React, {useState} from "react";

import {Chip, Menu, MenuItem, Typography, Box} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import {blueGrey, green} from "@mui/material/colors";

export default function FieldSceneProdStatus({
  value,
  onChange,
  readOnly = false,
  size,
}) {
  const draftS = "0 - Brouillon";
  const wipS = "1 - Travail en cours";
  const prodS = "2 - En production";
  const archivedS = "3 - Archivé";

  const prodStatusMap = {
    DRAFT: draftS,
    WIP: wipS,
    PROD: prodS,
    ARCHIVED: archivedS,
  };
  const bgcolorMap = {
    DRAFT: blueGrey[500],
    WIP: (theme) => theme.palette.common.yellow,
    PROD: green[600],
    ARCHIVED: "common.caplaBlack",
  };

  let label = prodStatusMap[value];
  if (!label) label = draftS;

  let bgcolor = bgcolorMap[value];
  if (!bgcolor) bgcolor = bgcolorMap["DRAFT"];

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleDeleteClick(e) {
    setAnchorEl(e.currentTarget);
  }
  function handleChange(code) {
    if (onChange) onChange(code);
    setAnchorEl(null);
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Chip
        label={label}
        deleteIcon={<More />}
        size={size ? size : "normal"}
        sx={{
          bgcolor,
          color: "common.white",
          ".MuiChip-deleteIcon": {
            color: "common.white",
            ":hover": {
              color: "common.white",
            },
          },
        }}
        {...(!readOnly && {onDelete: handleDeleteClick})}
      />
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {Object.entries(prodStatusMap).map(([code, label]) => (
          <MenuItem key={code} onClick={() => handleChange(code)}>
            <Typography variant="body2">{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
