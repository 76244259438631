import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import {Close, ArrowDropDown as Down, Image} from "@mui/icons-material";

import {setOpenSections, setSelectedFixedViewer} from "Features/ui/uiSlice";
import zoomInOut from "Features/ui/zoomInOut";
import {setIs3dEnabled} from "Features/viewer3D/viewer3DSlice";

export default function TogglePdf3d({caplaEditor}) {
  const dispatch = useDispatch();

  //strings

  const viewPdfS = "Vue PDF";
  const view3DS = "Vue 3D";
  const viewPDF3DS = "PDF et 3D";
  // const viewImageS = "Fonds de plan";
  const closeViewersS = "Fermer les visionneuses";

  // data

  const os = useSelector((s) => s.ui.openSections);
  const animate = useSelector((s) => s.ui.animateViewmodes);
  const selectedMeasurementIds = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );
  const selectedFixedViewer = useSelector((s) => s.ui.selectedFixedViewer);

  // helpers

  let mode;
  if (os.fixedViewersBox) mode = "PDF";
  if (os.viewer3D) mode = "3D";
  if (os.fixedViewersBox && os.viewer3D) mode = "MX";
  // if (os.callToAction) mode = "IMAGE";

  // helpers - colors

  // const lightPink = (theme) => lighten(theme.palette.primary.flash, 0.4);
  const lightPink = "secondary.main";

  // handlers

  function handleModeChange(mode) {
    const openSections = {...os};
    if (mode === "PDF") {
      openSections.fixedViewersBox = true;
      openSections.viewer3D = false;
    } else if (mode === "3D") {
      openSections.fixedViewersBox = false;
      openSections.viewer3D = true;
      dispatch(setIs3dEnabled(true));
    } else if (mode === "MX") {
      openSections.fixedViewersBox = true;
      openSections.viewer3D = true;
      dispatch(setIs3dEnabled(true));
    }
    // openSections.callToAction = newMode.includes("IMAGE");
    // if (mode.length === 0) openSections.outlet = true;
    // else openSections.outlet = false;
    dispatch(setOpenSections(openSections));
    // caplaEditor?.setOpenSections(openSections, selectedMeasurementIds);
  }

  function handleDownClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (selectedFixedViewer === "PDF") {
      dispatch(setSelectedFixedViewer("ZONE"));
    } else {
      dispatch(setSelectedFixedViewer("PDF"));
    }
  }

  function handleCloseViewer() {
    dispatch(
      setOpenSections({
        ...os,
        outlet: true,
      })
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 0.5,
        height: 24,
        //height: (theme) => theme.spacing(5),
        ...(animate && {
          borderColor: "primary.flash",
          borderStyle: "solid",
          borderWidth: "3px",
          animation: `${zoomInOut} 1s infinite ease`,
        }),
        //bgcolor: "common.lightPink",
      }}
    >
      <Paper
        // elevation={12}
        sx={{
          bgcolor: "common.caplaBlack",
          // p: 0.5,
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          color: "divider",
          ...(animate && {
            borderColor: "primary.flash",
            borderStyle: "solid",
            borderWidth: "3px",
            animation: `${zoomInOut} 1s infinite ease`,
          }),
        }}
      >
        <ToggleButtonGroup
          exclusive
          value={mode}
          onChange={(_, v) => handleModeChange(v)}
          size="small"
          sx={{
            height: "24px",
            // bgcolor: "primary.flash",
            // color: "common.white",
          }}
        >
          <ToggleButton
            value="PDF"
            sx={{
              //width: "24px",
              "&.Mui-selected": {
                bgcolor: lightPink,
                "&:hover": {
                  bgcolor: lightPink,
                },
              },
            }}
          >
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Tooltip title={viewPdfS}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "common.white",
                  }}
                >
                  {selectedFixedViewer === "PDF" ? (
                    <Typography sx={{fontSize: 12, color: "common.white"}}>
                      PDF
                    </Typography>
                  ) : (
                    <Image color="inherit" sx={{fontSize: 16}} />
                  )}
                </Box>
              </Tooltip>
              <IconButton
                sx={{color: "common.white", width: 18, height: 18}}
                onClick={handleDownClick}
              >
                <Down color="inherit" />
              </IconButton>
            </Box>
          </ToggleButton>

          <ToggleButton
            value="3D"
            sx={{
              "&.Mui-selected": {
                bgcolor: lightPink,
                "&:hover": {
                  bgcolor: lightPink,
                },
              },
            }}
          >
            <Tooltip title={view3DS}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{fontSize: 12, color: "common.white"}}>
                  3D
                </Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
          <ToggleButton
            value="MX"
            sx={{
              "&.Mui-selected": {
                bgcolor: lightPink,
                "&:hover": {
                  bgcolor: lightPink,
                },
              },
            }}
          >
            <Tooltip title={viewPDF3DS}>
              <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography sx={{fontSize: 12, color: "common.white"}}>
                  MX
                </Typography>
              </Box>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>

      <Tooltip title={closeViewersS}>
        <Box
          sx={{
            ml: 1,
            color: "common.caplaBlack",
            //bgcolor: "common.caplaBlack",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            width: 24,
            height: 24,
          }}
        >
          <IconButton color="inherit" size="small" onClick={handleCloseViewer}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
}
