import {useDispatch} from "react-redux";

import {
  Add,
  LibraryAdd as AddGroup,
  Upload,
  Download,
  Edit,
} from "@mui/icons-material";

import useSelectedRessourcesGroup from "./useSelectedRessourcesGroup";
import useSelectedRessource from "./useSelectedRessource";

import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import sanitizeString from "Utils/sanitizeString";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import getItemsSortedByNum from "Utils/getItemsSortedByNum";

export default function useActionsRessourcesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedGroup = useSelectedRessourcesGroup(scene);
  const selectedRessource = useSelectedRessource(scene);

  // handlers

  async function handleExportExcelRessources() {
    let fileName = "articles.xlsx";
    if (selectedGroup) {
      fileName = sanitizeString(selectedGroup.name) + "_articles.xlsx";
      const groupRessources = selectedGroup.ressources.map((r) => ({
        ...r,
        group: selectedGroup.name,
      }));
      const ressources = getItemsSortedByNum(groupRessources);
      const wb = await createExcelWorkbook({
        template: "RESSOURCES_WITH_IDS",
        data: ressources,
        withIds: true,
      });
      downloadExcelFileFromWorkbook(wb, fileName);
    }
  }
  // actions map

  const actionsMap = {
    createRessourcesGroup: {
      icon: <AddGroup fontSize="small" />,
      name: "Tableau d'articles",
      label: "Créer un tableau d'articles",
    },
    deleteRessourcesGroup: {
      label: "Supprimer le tableau d'articles",
    },
    createRessource: {
      icon: <Add fontSize="small" />,
      name: "Article",
      label: "Créer un article",
    },
    deleteRessource: {
      label: "Supprimer l'article",
      disabled: !selectedRessource,
    },
    exportExcelRessources: {
      icon: <Download fontSize="small" />,
      name: "Excel",
      label: "Exporter au format Excel",
      handler: handleExportExcelRessources,
    },
    importExcelRessources: {
      icon: <Upload fontSize="small" />,
      name: "Excel",
      label: "Importer au format Excel",
    },
    editRessourcesGroup: {
      icon: <Edit fontSize="small" />,
      name: "Editer",
      label: "Modifier le tableau",
    },
  };

  return actionsMap;
}
