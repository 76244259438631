import { useEffect } from "react";
import {useDispatch} from "react-redux";

import useVersionsByScene from "../hooks/useVersionsByScene";
import {setSelectedVersionForMeasurements} from "../versionsSlice";
import useSelectedVersionForMeasurements from "../hooks/useSelectedVersionForMeasurements";

import SelectorVersion from "./SelectorVersion";

export default function SelectorVersionForMeasurements({scene, viewer, caplaEditor}) {
  const dispatch = useDispatch();
  const versions = useVersionsByScene(scene);

  const selectedVersion = useSelectedVersionForMeasurements();

  function handleChange(version) {
    dispatch(setSelectedVersionForMeasurements(version));
  }

  useEffect(() => {
    caplaEditor?.measDataManager.setSelectedVersionId(selectedVersion?.id);
    caplaEditor?.measDataManager.setShortMemory();
  }, [selectedVersion])

  return (
    <SelectorVersion
      selectedVersion={selectedVersion}
      versions={versions}
      onChange={handleChange}
      viewer={viewer}
    />
  );
}
