import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";

import useUpdateRoomsGroup from "../hooks/useUpdateRoomsGroup";

import {
  Dialog,
  IconButton,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";

import SectionEditableTree from "Features/tree/components/SectionEditableTree";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function DialogFsEditRoomsGroup({
  open,
  roomsGroup,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();
  // strings

  const cancelS = "Annuler";
  const saveS = "Enregistrer";

  const groupS = "Arborescence de pièces";

  //

  const update = useUpdateRoomsGroup(scene);

  // state

  const initName = roomsGroup?.name ?? "";
  const initRankNames = roomsGroup?.data?.rankNames ?? [];
  const initItems = roomsGroup?.data?.items ?? [];

  const [tempName, setTempName] = useState(initName);
  const [tempRankNames, setTempRankNames] = useState(initRankNames);
  const [tempItems, setTempItems] = useState(initItems);

  console.log("debugAFA tempItems", tempItems);

  useEffect(() => {
    setTempName(initName);
    setTempRankNames(initRankNames);
    setTempItems(initItems);
  }, [open]);

  // state - loading

  const [loading, setLoading] = useState(false);

  // handlers

  function handleCancel() {
    onClose();
  }

  async function handleSave() {
    if (loading) return;
    setLoading(true);
    const updates = {
      id: roomsGroup.id,
      sceneId: roomsGroup.sceneId,
      name: tempName,
      data: {rankNames: tempRankNames, items: tempItems},
    };
    const updatedRoomsGroup = await update(updates);
    //
    const message = "Arborescence mise à jour";
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({triggeredAt, message}));
    //
    onClose();
  }

  return (
    <Dialog fullScreen open={open}>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
            bgcolor: "background.default",
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Typography sx={{fontSize: 13, color: "text.secondary", mr: 2}}>
              {groupS}
            </Typography>
            <TextField
              sx={{minWidth: 250}}
              InputProps={{sx: {fontSize: 13, fontWeight: "bold"}}}
              size="small"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
            />
          </Box>
          <Box sx={{display: "flex"}}>
            <Button sx={{mr: 1}} size="small" onClick={handleCancel}>
              {cancelS}
            </Button>
            <Button
              disabled={!tempName || loading}
              size="small"
              onClick={handleSave}
            >
              {saveS}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{display: "flex", flexGrow: 1, width: 1, border: "1px solid red"}}
        >
          <SectionEditableTree
            name={tempName}
            rankNames={tempRankNames}
            items={tempItems}
            onNameChange={setTempName}
            onRankNamesChange={setTempRankNames}
            onItemsChange={setTempItems}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
