import React from "react";

import {Box, Typography} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import ButtonRefreshSceneListings from "./ButtonRefreshSceneListings";

export default function HeaderListListings({scene}) {
  const {t} = useTranslation("viewer3D");

  const title = t("datasets");

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h6">
        <b>{title}</b>
      </Typography>
      <ButtonRefreshSceneListings scene={scene} />
    </Box>
  );
}
