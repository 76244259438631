import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getRoomsFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(4, rowCount - 3).values();

  const valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    valuesA.push(rowValues);
  }

  const fields = [
    "id",
    "num",
    "name",
    "code",
    "sector",
    "surface",
    "hsp",
    "type",
    "toDelete",
  ];

  const loadedRooms = [];

  valuesA.map((valueA) => {
    const room = {};
    valueA.forEach((value, index) => (room[fields[index]] = value));
    loadedRooms.push(room);
  });
  return loadedRooms;
}
