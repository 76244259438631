import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {createMapService, fetchMapsService} from "./services";

export const createMap = createAsyncThunk("maps/createMap", createMapService);
export const fetchMaps = createAsyncThunk("maps/fetchMaps", fetchMapsService);

const mapsSlice = createSlice({
  name: "maps",
  initialState: {data: [], status: "idle"},
  extraReducers: {
    [createMap.fulfilled]: (state, action) => {
      state.data.push(action.payload);
    },
    [fetchMaps.fulfilled]: (state, action) => {
      state.data.push(...action.payload);
      state.status = "loaded";
    },
  },
});

export default mapsSlice.reducer;
