import {Vector2} from "three";

export default class Polyline {
  static getPolygonFromPolyline(path, width) {
    const extPath = this.getExteriorPolyline(path, width / 2);
    const intPath = this.getExteriorPolyline(path, -width / 2);
    intPath.reverse();
    const polygon = [...extPath, ...intPath, extPath[0]];
    return polygon;
  }

  static centerPoint(point, P1, inverse = false) {
    if (inverse) {
      return {x: point.x + P1.x, y: point.y + P1.y};
    } else {
      return {x: point.x - P1.x, y: point.y - P1.y};
    }
  }

  static rotatePoint(point, angle) {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    return {
      x: cos * point.x + sin * point.y,
      y: -sin * point.x + cos * point.y,
    };
  }

  static angleP0P1(point0, point1) {
    const P0P1 = new Vector2(point1.x - point0.x, point1.y - point0.y);
    return P0P1.angle();
  }

  static mainToP1(point, P1, P0) {
    const centered = this.centerPoint(point, P1);
    const angle = this.angleP0P1(P0, P1);
    return this.rotatePoint(centered, angle);
  }

  static p1ToMain(point, P1, P0) {
    const angle = -this.angleP0P1(P0, P1);
    const rotated = this.rotatePoint(point, angle);
    return this.centerPoint(rotated, P1, true);
  }

  static getExteriorPolyline(path, width, isClosed) {
    let length = path.length;
    let exteriorPath = [];
    for (let i = 0; i < length; i++) {
      if (i === 0) {
        const Pend = path[length - 2];
        const P0 = path[i + 0];
        const P1 = path[i + 1];
        if (!isClosed) {
          exteriorPath.push(this.exteriorCoordStart(P0, P1, width));
        } else {
          exteriorPath.push(this.exteriorCoordMiddle(Pend, P0, P1, width));
        }
      } else if (i === length - 1) {
        const P0 = path[i - 1];
        const P1 = path[i];
        if (!isClosed) {
          exteriorPath.push(this.exteriorCoordEnd(P0, P1, width));
        } else {
          exteriorPath.push(exteriorPath[0]);
        }
      } else {
        const P0 = path[i - 1];
        const P1 = path[i];
        const P2 = path[i + 1];
        exteriorPath.push(this.exteriorCoordMiddle(P0, P1, P2, width));
      }
    }
    return exteriorPath;
  }

  static exteriorCoordStart(P0, P1, width) {
    const p0 = this.mainToP1(P0, P1, P0);
    const p0Ext = {x: p0.x, y: p0.y + width};
    const coords = this.p1ToMain(p0Ext, P1, P0);
    return coords;
  }

  static exteriorCoordEnd(P0, P1, width) {
    const p1Ext = {x: 0, y: width};
    return this.p1ToMain(p1Ext, P1, P0);
  }

  static exteriorCoordMiddle(P0, P1, P2, width) {
    const p2 = this.mainToP1(P2, P1, P0);
    const angle = new Vector2(p2.x, p2.y).angle();
    const deltaX = -Math.tan(angle / 2) * width;
    const p1Ext = {x: deltaX, y: width};
    return this.p1ToMain(p1Ext, P1, P0);
  }
}
