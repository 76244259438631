import getNomenclatureFromWorksheet from "Features/excel/utils/getNomenclatureFromWorksheet";
import getRowsFromFile from "Features/excel/utils/getRowsFromFile";
import FileSelectorButton from "Features/files/components/FileSelectorButton";
import getNomenclatureItemsFromSortedArray from "../utils/getNomenclatureItemsFromSortedArray";

export default function ButtonLoadNomenclatureFromExcel({
  onNomenclatureLoaded,
}) {
  // strings

  const buttonName = "Charger depuis Excel";

  // handlers

  async function handleFileChange(file) {
    const rows = await getRowsFromFile(file);
    console.log("debugAFA rows", rows);
    const nomenclature = getNomenclatureItemsFromSortedArray(rows);
    console.log("load nomenclature", nomenclature);
    onNomenclatureLoaded(nomenclature);
  }
  return (
    <div>
      <FileSelectorButton
        accept=".xlsx"
        onFileChange={handleFileChange}
        buttonName={buttonName}
      />
    </div>
  );
}
