import {useState} from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

export default function DialogAddBulkRelationsRessource({
  open,
  onCancel,
  onConfirm,
}) {
  // strings

  const titleS = "Ajout de relations en masse";
  const addS = "Appliquer";
  const codesS = "Liste de codes des types déléments à associer";
  const formulaS = "Formule à utiliser";

  // state

  const [codes, setCodes] = useState("");
  const [formula, setFormula] = useState("");

  // handlers

  const handleCodesChange = (e) => {
    setCodes(e.target.value);
  };

  const handleFormulaChange = (e) => {
    setFormula(e.target.value);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{titleS}</DialogTitle>
      <DialogContent sx={{maxWidth: 300}}>
        <Typography variant="body2" sx={{pb: 2}}>
          {codesS}
        </Typography>
        <Box sx={{display: "flex", maxHeight: 250, overflow: "auto"}}>
          <TextField
            multiline
            fullWidth
            value={codes}
            onChange={handleCodesChange}
            size="small"
            InputProps={{
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
              },
            }}
          />
        </Box>
        <Typography variant="body2" sx={{pt: 2, pb: 1}}>
          {formulaS}
        </Typography>
        <Box sx={{width: 1}}>
          <TextField
            value={formula}
            fullWidth
            onChange={handleFormulaChange}
            size="small"
            InputProps={{
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
      <Button onClick={() => onConfirm(codes, formula)}>{addS}</Button>
      </DialogActions>
    </Dialog>
  );
}