import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";

import {Edit} from "@mui/icons-material";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ButtonRenameMarker({editor3d, markerId, markersModel}) {
  const dispatch = useDispatch();

  // strings

  const renameS = "Edit";

  const titleS = "Modify the marker";

  const altimetryS = "Altimetry Z (m)";

  const saveS = "Save";
  const cancelS = "Cancel";

  // helper

  const marker = markersModel.elements.items.find((m) => m.id === markerId);

  // state

  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState(marker?.description);
  const [Z, setZ] = useState(marker?.y);
  useEffect(() => {
    setDescription(marker?.description);
  }, [marker?.description]);
  useEffect(() => {
    setZ(marker?.y);
  }, [marker?.y]);

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }

  // handlers

  function handleZChange(e) {
    let z = e.target.value;
    z = z.replace(",", ".");
    setZ(z);
  }
  function handleSaveClick() {
    const altimetry = parseFloat(Z);

    const oldItems = markersModel.elements.items;
    let newItems = oldItems.filter((m) => m.id !== markerId);
    newItems = [...newItems, {...marker, description, y: altimetry}];
    const updatedModel = {
      ...markersModel,
      elements: {...markersModel.elements, items: newItems},
    };
    dispatch(updateModel({updatedModel}));

    //3D

    const entity = editor3d?.getEntity(markersModel.id);
    entity?.updateMarkerZ({markerId, Z: altimetry});
    handleCloseDialog();
  }

  return (
    <>
      <Button
        sx={{height: 30}}
        startIcon={<Edit />}
        size="small"
        onClick={handleOpenDialog}
      >
        {renameS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{titleS}</DialogTitle>
        <DialogContent>
          <Box sx={{p: 1}}>
            <TextField
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Box sx={{display: "flex", alignItems: "center", mt: 5}}>
              <Typography variant="body2">{altimetryS}</Typography>
              <TextField
                sx={{ml: 1}}
                InputProps={{
                  sx: {fontSize: (theme) => theme.typography.body2.fontSize},
                }}
                size="small"
                value={Z}
                onChange={handleZChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{cancelS}</Button>
          <Button onClick={handleSaveClick}>{saveS}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
