import React from "react";
import {Box, Divider} from "@mui/material";

import SectionProduct from "./SectionProduct";
import SectionPrice from "./SectionPrice";
import ButtonOrder from "./ButtonOrder";

export default function SectionOrder({
  bimboxName,
  bimboxDescription,
  bimboxOverviewUrl,
  bimboxPrice,
  bimboxOfferKey,
  sharedBimboxId,
  operationName,
}) {
  const demoUrl = `https://app.capla360.com/shared/bimbox/${sharedBimboxId}/viewer`;

  return (
    <Box sx={{width: 1, height: 1}}>
      <Box sx={{mb: 2, p: 3}}>
        <SectionProduct
          name={bimboxName}
          description={bimboxDescription}
          overviewUrl={bimboxOverviewUrl}
          demoUrl={demoUrl}
        />
      </Box>

      <Divider />
      <Box sx={{p: 2}}>
        <SectionPrice price={bimboxPrice} offerKey={bimboxOfferKey} />
        <ButtonOrder
          operationName={operationName}
          bimboxName={bimboxName}
          demoUrl={demoUrl}
        />
      </Box>
    </Box>
  );
}
