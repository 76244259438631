import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
// import {useNavigate, useParams} from "react-router-dom";

import {Box} from "@mui/material";

import ListSceneDatasets from "./components/ListSceneDatasets";
import ListSceneDatasetsFilters from "./components/ListSceneDatasetsFilters";

import {
  //setSelectedModelId,
  setSelectedPdfModelId,
  updateModel,
} from "Features/viewer3D/viewer3DSlice";
import {setSelectedModelId, setSelectedIssueId} from "Features/ui/uiSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
//import {setSelectedIssueId} from "Features/issues/issuesSlice";
import useSceneListings from "Features/listings/useSceneListings";
// import IconButtonDownloadAndLoadModels from "Features/viewer3D/components/IconButtonDownloadAndLoadModels";

export default function ListContainerSceneDatasets({scene, caplaEditor}) {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const {sceneId} = useParams();

  // strings

  // const dataS = "FILES & DATA";

  // data

  let allModels = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.enabled && m.type !== "IMAGE"
  );

  // remove duplicates
  const modelIds = [...new Set(allModels.map((m) => m.id))];
  allModels = modelIds.map((id) => allModels.find((m) => m.id === id));

  let listings = useSceneListings(scene?.id);

  const selectedModelId = useSelector((s) => s.ui.selectedModelId);
  const selectedPdfModelId = useSelector(
    (s) => s.viewer3D.setSelectedPdfModelId
  );
  const selectedMeasurementsModelId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );

  // helpers

  listings = listings.map((l) => ({...l, type: l.listingType}));
  const models = allModels.filter((m) => m.sceneClientId === scene?.clientId);

  let modelTypes = models.map((m) => m.type);
  modelTypes = [...new Set(modelTypes)];

  let listingTypes = listings.map((m) => m.listingType);
  listingTypes = [...new Set(listingTypes)];

  const types = [...modelTypes, ...listingTypes];

  const datasets = [
    ...models.map((m) => ({...m, isModel: true})),
    ...listings.map((m) => ({...m, isListing: true})),
  ];

  // state

  const [selectedTypes, setSelectedTypes] = useState(types);

  useEffect(() => {
    setSelectedTypes(types);
  }, [types?.length]);

  // helpers

  let filteredDatasets = datasets.filter(
    (m) =>
      selectedTypes.includes(m.type) || selectedTypes.includes(m.listingType)
  );

  const filteredListingDatasets = filteredDatasets
    .filter((d) => d.isListing)
    .sort((a, b) => a.listingType.localeCompare(b.listingType));
  const filteredModelDatasets = filteredDatasets
    .filter((d) => d.isModel)
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => a.type.localeCompare(b.type));

  // handler - title click

  // function handleGoToData() {
  //   navigate(`/bimboxes/${sceneId}/data`);
  // }

  // handlers

  function handleTypeClick(type) {
    if (selectedTypes.includes(type)) {
      setSelectedTypes((types) => types.filter((t) => t !== type));
    } else {
      setSelectedTypes((types) => [...types, type]);
    }
  }

  function handleDatasetClick({id, isListing, isModel}) {
    // we unselect the issue
    dispatch(setSelectedIssueId(null));
    // we select the model
    if (isModel) {
      const clickedModel = models.find((m) => m.id === id);

      // update model filters
      models.forEach((model) => {
        let updatedModel = {...model};
        if (model.id === id) {
          updatedModel.hidden = false;
        } else {
          updatedModel.hidden = true;
        }
        dispatch(
          updateModel({updatedModel, sync: false, updateModelInStore: false})
        );
      });

      // camera
      const camera = clickedModel?.screenshot?.camera;
      if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);

      if (selectedModelId === id) {
        if (clickedModel.type === "PDF" && selectedPdfModelId === id)
          dispatch(setSelectedPdfModelId(null));
        if (
          clickedModel.type === "MEASUREMENTS" &&
          selectedMeasurementsModelId === id
        )
          dispatch(setSelectedMeasurementsModelId(null));
          // caplaEditor?.measDataManager.setSelectedModelById(null);
        dispatch(setSelectedModelId(null));
      } else {
        dispatch(setSelectedModelId(id));
        if (clickedModel.type === "PDF" && selectedPdfModelId !== id)
          dispatch(setSelectedPdfModelId(id));
        dispatch(setSelectedMeasurementsModelId(null)); // to avoid annotation display in pdf overview
        // caplaEditor?.measDataManager.setSelectedModelById(null);
        if (
          clickedModel.type === "MEASUREMENTS" &&
          selectedMeasurementsModelId !== id
        ) {
          dispatch(setSelectedMeasurementsModelId(id));
          // caplaEditor?.measDataManager.setSelectedModelById(id);
          dispatch(setSelectedPdfModelId(clickedModel.fromModel.modelId));
        }
      }
    }
  }

  useEffect(() => {
    return () => {
      // dispatch(setSelectedModelId(null));  //comment : edge case : pdf + outlet.
      //dispatch(setSelectedMeasurementsModelId(null));
    };
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{fontWeight: "bold", cursor: "pointer"}}
          color="text.secondary"
          onClick={handleGoToData}
        >
          {dataS}
        </Typography>
        <IconButtonDownloadAndLoadModels scene={scene} caplaEditor={caplaEditor} />
      </Box> */}

      <ListSceneDatasetsFilters
        selectedTypes={selectedTypes}
        onClick={handleTypeClick}
        modelTypes={modelTypes}
        listingTypes={listingTypes}
      />
      <Box sx={{py: 1}} />
      <Box sx={{flexGrow: 1, overflow: "auto"}}>
        <ListSceneDatasets
          datasets={filteredModelDatasets}
          onClick={handleDatasetClick}
        />
        {filteredListingDatasets?.length > 0 && <Box sx={{my: 1}} />}
        <ListSceneDatasets
          datasets={filteredListingDatasets}
          onClick={handleDatasetClick}
        />
      </Box>

      <Box sx={{height: "16px"}} />
    </Box>
  );
}
