import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Paper, Typography} from "@mui/material";

import NotesList from "./components/NotesList";
import NoteEditor from "./components/NoteEditor";
import CreateNoteButton from "./components/CreateNoteButton";
//import SceneHeader from "./components/SceneHeader";
import SceneBasicHeader from "./components/SceneBasicHeader";
import OpenedFilter from "./components/OpenedFilter";
import NoteSectionHeader from "./components/NoteSectionHeader";
import CallToActionLogin from "./components/CallToActionLogin";

import {updateNote} from "Features/note/noteSlice";
import {setSelectedNoteId} from "Features/viewer3D/viewer3DSlice";
import useNotes from "Features/notes/useNotes";
import useNote from "Features/note/useNote";
// import useProfile from "Features/profile/useProfile";
import EditableContributionCard from "Features/contributions/components/EditableContributionCard";
import ContributionCardList from "Features/contributions/components/ContributionCardList";
//import ListingHeaderSelector from "Features/listings/components/ListingSelectorHeader/ListingSelectorHeader";
import useTranslation from "Features/translations/useTranslation";
import useAccessToken from "Features/auth/useAccessToken";

export default function SceneNotesEditor({scene, caplaEditor, viewer}) {
  const {t} = useTranslation("notes");
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const contributionsString = t("contributions.title");

  // local state
  const [edit, setEdit] = useState(false); // Edition of the list (add a note / edit a note)
  const [editingNote, setEditingNote] = useState(false); // Editing the note
  const [openedFilter, setOpenedFilter] = useState(true);

  // data

  const sceneListingId = useSelector((state) => state.viewer3D.sceneListingId);
  const noteId = useSelector((state) => state.viewer3D.selectedNoteId);

  const [notes] = useNotes({listingId: sceneListingId});
  const [note] = useNote({noteId, listingId: sceneListingId});

  // const {data: profile} = useProfile();
  // const canCreateListing = profile?.canCreateListing;

  useEffect(() => {
    if (noteId) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [noteId]);

  // helpers

  const canCreateNote = Boolean(sceneListingId);

  // handlers

  function handleNoteClick(note) {
    dispatch(setSelectedNoteId(note.id));
    setEdit(true);
  }

  function handleNoteCreateClick() {
    dispatch(setSelectedNoteId(null));
    setEdit(true);
    setEditingNote(true);
  }

  function handleNoteEditClose() {
    setEdit(false);
    setEditingNote(false);
    dispatch(setSelectedNoteId(null));
  }

  function handleNoteSaveSuccess() {
    dispatch(setSelectedNoteId(null));
    setEdit(false);
    setEditingNote(false);
  }

  function handleEditNote() {
    console.log("edit note");
    setEditingNote(true);
  }

  function handleDeleteNote() {
    console.log("delete note");
  }

  function handleCloseNote() {
    const newNote = {id: note.id, isOpened: !note.isOpened};
    dispatch(
      updateNote({
        accessToken,
        listingId: sceneListingId,
        noteId: note.id,
        note: newNote,
      })
    );
    dispatch(setSelectedNoteId(null));
  }

  function handleOpenedFilterChange(opened) {
    setOpenedFilter(opened);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: 1,
          height: 1,
          display: !edit ? "flex" : "none",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {/* <SceneHeader scene={scene} /> */}
        {/* <ListingHeaderSelector /> */}
        <SceneBasicHeader scene={scene} />
        {notes && !viewer && (
          <Box
            sx={{
              display: "flex",
              width: 1,
              justifyContent: "center",
              mb: 2,
              mt: 2,
            }}
          >
            <OpenedFilter
              opened={openedFilter}
              onChange={handleOpenedFilterChange}
            />
          </Box>
        )}
        <NotesList
          notes={notes?.filter((n) => n.isOpened === openedFilter)}
          editor3d={caplaEditor?.editor3d}
          onNoteClick={handleNoteClick}
          scene={scene}
        />
        {sceneListingId && (
          <CreateNoteButton
            onClick={handleNoteCreateClick}
            disabled={!canCreateNote}
          />
        )}
      </Box>

      <Box
        sx={{
          width: 1,
          flexGrow: 1,
          minHeight: 0,
          display: edit ? "flex" : "none",
          flexDirection: "column",
          padding: 2,
          overflow: "auto",
          position: "relative",
        }}
      >
        <NoteSectionHeader onBackClick={handleNoteEditClose} scene={scene} />
        <Paper
          sx={{
            width: 1,
            mt: 2,
            //flexGrow: 1,
            //minHeight: 0,
            //display: "flex",
            //flexDirection: "column",
            position: "relative",
          }}
        >
          {noteId ? (
            <NoteEditor
              initialNote={note}
              listingId={sceneListingId}
              onSaveSucess={handleNoteSaveSuccess}
              caplaEditor={caplaEditor}
              noteId={noteId}
              onDeleteNote={handleDeleteNote}
              onEditNote={handleEditNote}
              editing={editingNote}
              sceneClientId={scene?.clientId}
              viewer={viewer}
            />
          ) : (
            // new note
            <NoteEditor
              initialNote={note}
              listingId={sceneListingId}
              onSaveSucess={handleNoteSaveSuccess}
              caplaEditor={caplaEditor}
              editing={true}
              sceneClientId={scene?.clientId}
            />
          )}
        </Paper>
        {noteId && (
          <Box sx={{mt: 3}}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {contributionsString}
            </Typography>
            <ContributionCardList
              listingId={sceneListingId}
              contributions={note?.contributions}
              noteId={noteId}
            />
            {accessToken ? (
              <EditableContributionCard
                initialEditing={true}
                entityToId={noteId}
                entityToType="note"
                listingId={sceneListingId}
                closeButton={true}
                isOpened={note?.isOpened}
                onClose={handleCloseNote}
                sceneClientId={scene?.clientId}
                viewer={viewer}
              />
            ) : (
              <CallToActionLogin />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
