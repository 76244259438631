import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

import {setRawMeasurements, setSelectedAuthor} from "../annotatedPdfSlice";
import useAnnotatedPdfModel from "../hooks/useAnnotatedPdfModel";
import getAuthorsFromRawMeasurements from "../utils/getAuthorsFromRawMeasurements";

import SelectOptions from "Components/SelectOptions";

export default function CardLoadMeasurements({editorPdf}) {
  const dispatch = useDispatch();

  // strings

  const annotationsS = "Annotations détectées";
  const noAnnotationsS = "Aucune annotation détectée";
  const authorS = "Auteur des annotations";

  // data

  const annotatedPdfModel = useAnnotatedPdfModel();
  const rawMeasurements = useSelector((s) => s.annotatedPdf.rawMeasurements);
  const author = useSelector((s) => s.annotatedPdf.selectedAuthor);
  const modelIdsWithAnnotationsLoaded = useSelector(
    (s) => s.pdf.modelIdsWithAnnotationsLoaded
  );

  // helpers

  const authors = getAuthorsFromRawMeasurements(rawMeasurements);

  const annotationsAreLoaded = modelIdsWithAnnotationsLoaded.includes(
    annotatedPdfModel?.id
  );

  // helper

  const mnger = editorPdf?.annotationsManager?.measurementsPdfManager;
  const hasAnnotations = rawMeasurements.length > 0;

  // helper - title

  const title = hasAnnotations
    ? `${annotationsS} (${rawMeasurements.length})`
    : noAnnotationsS;

  // effect - extraction

  useEffect(() => {
    if (
      mnger &&
      annotatedPdfModel?.id &&
      annotatedPdfModel.id === mnger?.modelId &&
      annotationsAreLoaded
    ) {
      mnger.extractor.extractRawMeasurements(true);
      dispatch(setRawMeasurements(mnger.extractor.rawMeasurements));
    }
  }, [mnger, annotatedPdfModel?.id, mnger?.modelId, annotationsAreLoaded]);

  // handlers

  function handleAuthorChange(author) {
    dispatch(setSelectedAuthor(author));
  }

  return (
    <Box sx={{mt: 1, bgcolor: "background.default", p: 1}}>
      <Typography variant="body2">{title}</Typography>
      <SelectOptions
        options={authors}
        option={author}
        onChange={handleAuthorChange}
        label={authorS}
      />
    </Box>
  );
}
