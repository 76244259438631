import {Box, Typography} from "@mui/material";

import IconElementType from "./IconElementType";

export default function ContainerRawType({rawType = {}}) {
  // rawType = types from annotations

  const {rawName, rawDrawingShape, rawColor, rawCount} = rawType;

  // label

  return (
    <Box sx={{display: "flex", alignItems: "center", minWidth: 0}}>
      <IconElementType drawingShape={rawDrawingShape} color={rawColor} />
      <Typography sx={{ml: 1, fontSize: 13}} noWrap>
        {rawName}
      </Typography>
    </Box>
  );
}
