import {Object3D, MathUtils} from "three";
import Marker from "./Marker";

export default class MarkersModel {
  constructor({model, editor3d}) {
    this.editor3d = editor3d;
    this.modelId = model.id;
    this.entityID = model.id;
    this.type = "MARKERS_MODEL";
    this.markers = []; // markers entity
    this.initialItems = model.elements?.items ? model.elements.items : [];
    this.object = this.createObject();
    this.selectedMarkerId = null;
    this.initialMarkersAreLoaded = false; // set to true when "loadMarkers" is triggered
  }

  /*
   * Model initialization
   */

  static computeInitialModel({name, sceneClientId}) {
    const id = MathUtils.generateUUID();
    const type = "MARKERS";
    const position = {x: 0, y: 0, z: 0};
    const rotation = {x: 0, y: 0, z: 0};
    const center = {x: 0, y: 2, z: 0};
    const width = 10;
    const depth = 10;
    const height = 2;
    const hidden = false;
    const enabled = true;
    const elements = {items: []};

    return {
      id,
      enabled,
      type,
      sceneClientId,
      name,
      elements,
      position,
      rotation,
      center,
      width,
      depth,
      height,
      hidden,
    };
  }

  /*
   * Constructor helpers
   */

  createObject() {
    const object = new Object3D();
    //object.layers.enable(1);
    this.editor3d?.scene.add(object);
    this.editor3d?.objectEntities.push(this);
    this.editor3d?.entities.push(this);
    return object;
  }

  /*
   * ADD / REMOVE MARKER
   */

  addMarker(marker) {
    try {
      const markerEntity = new Marker({marker, modelId: this.modelId});
      this.object.add(markerEntity.object);
      this.markers.push(markerEntity);
      this.editor3d?.objectEntities.push(markerEntity);
      this.editor3d?.entities.push(markerEntity);
    } catch (e) {
      console.log("error", e);
    }
  }

  updateMarkerZ({markerId, Z}) {
    const marker = this.getMarker(markerId);
    if (marker) marker.object.position.y = Z;
  }

  updateMarkerPosition({markerId, x, y, z}) {
    const marker = this.getMarker(markerId);
    if (marker) {
      marker.object.position.x = x;
      marker.object.position.y = y;
      marker.object.position.z = z;
    }
  }

  /*
   * SEARCH MARKER
   */

  getMarker(markerId) {
    return this.markers.find((m) => m.entityID === markerId);
  }

  /*
   * LOADER
   */

  loadMarkers() {
    this.initialItems.map((item) => {
      this.addMarker(item);
    });
    this.initialMarkersAreLoaded = true;
  }

  /*
   * ENABLE / DISABLE
   */

  enable() {
    this.editor3d?.scene.add(this.object);
    this.markers.forEach((marker) => this.editor3d?.objectEntities.push(marker));
  }

  disable() {
    this.editor3d?.scene.remove(this.object);
    this.markers.forEach((marker) => {
      if (this.editor3d) this.editor3d.objectEntities = this.editor3d.objectEntities.filter(
        (e) => e.entityID !== marker.entityID
      );
    });
  }

  /*
   * SHOW / HIDE
   */

  show() {
    this.markers.forEach((m) => m.show());
  }
  hide() {
    this.markers.forEach((m) => m.hide());
  }

  /*
   * SELECTION
   */

  selectMarker(markerId) {
    console.log("selectMarker", markerId);
    const newMarker = this.getMarker(markerId);
    newMarker?.select();
  }

  unselectMarker(markerId) {
    console.log("unselectMarker", markerId);
    const newMarker = this.getMarker(markerId);
    newMarker?.unselect();
  }

  /*
   * DELETE
   */

  deleteMarker(markerId) {
    const marker = this.getMarker(markerId);
    if (this.editor3d) this.editor3d.entities = this.editor3d.entities.filter(
      (e) => e.entityID !== markerId
    );
    this.object.remove(marker.object);
  }
}
