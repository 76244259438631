import {Box} from "@mui/material";

import ToggleFilterOpened from "./ToggleFilterOpened";

import useIssuesForDataGrid from "../useIssuesForDataGrid";

import ButtonExportExcel from "Components/ButtonExportExcel";

export default function ToolbarIssuesMain({scene}) {

  const dataset = useIssuesForDataGrid(scene);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 2,
        mt: 2,
        mb: 1,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          "&>*:not(:last-child)": {mr: 1},
        }}
      >
        <ButtonExportExcel dataset={dataset} />
        <ToggleFilterOpened />
      </Box>
    </Box>
  );
}