export default function getArraysIntersection(arrays) {
  // Handle empty or invalid input
  if (!arrays || !arrays.length) {
    return [];
  }
  arrays = arrays.filter((array) => Array.isArray(array));

  // Convert all arrays to sets for efficient membership checking
  const sets = arrays.map((array) => new Set(array));

  // Find the intersection of all sets
  const intersection = sets.reduce((acc, curr) => {
    return new Set([...acc].filter((x) => curr.has(x)));
  }, sets[0]);

  // Convert the intersection set back to an array
  let result = [];
  if (intersection) result = [...intersection];
  return result;
}
