import React, {useState} from "react";

import {
  FormControl,
  Select,
  MenuItem,
  InputBase,
  Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";

import useTranslate from "Features/translations/useTranslation";
import {languages} from "../resources";

const CaplaInput = styled(InputBase)(({theme}) => ({
  "& .MuiInputBase-input": {
    borderRadius: 5,
    //border: `1px solid ${theme.palette.common.white}`,
    color: theme.palette.common.primary,
    fontSize: theme.typography.body2.fontSize,
    display: "flex",
    paddingLeft: theme.spacing(1),
    paddingRight: `${theme.spacing(2.5)} !important`,
  },
}));

const CaplaSelect = styled(Select)(({theme}) => ({
  "& .MuiSvgIcon-root": {
    color: theme.palette.common.primary,
    fontSize: theme.typography.body2.fontSize,
  },
}));

export default function LanguageSelector() {
  // local state

  const {i18n} = useTranslate();
  const browserLanguage = i18n.language;
  const defaultLanguage = languages[browserLanguage] ? browserLanguage : "en";
  const [language, setLanguage] = useState(defaultLanguage);

  // handlers

  function handleSelectChange(e) {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }

  return (
    <FormControl>
      <CaplaSelect
        input={<CaplaInput />}
        color="primary"
        value={language}
        onChange={handleSelectChange}
        renderValue={(v) => v.toUpperCase()}
      >
        {Object.keys(languages).map((l) => {
          return (
            <MenuItem key={l} color="inherit" value={l}>
              <Typography variant="body2">{languages[l]}</Typography>
            </MenuItem>
          );
        })}
      </CaplaSelect>
    </FormControl>
  );
}
