import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";

export default function DialogLogout({open, onConfirm, onCancel}) {
  // string

  const description = "Etes-vous sur de vouloir vous déconnecter ?";

  const confirmS = "Confirmer";
  const cancelS = "Annuler";

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelS}</Button>
        <Button onClick={onConfirm}>{confirmS}</Button>
      </DialogActions>
    </Dialog>
  );
}
