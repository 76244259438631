import {useState} from "react";
import { useSelector } from "react-redux";

import { Button, TextField } from "@mui/material";

import {useMsal} from "@azure/msal-react";

import axios from "axios";

import apiConfig from "API/apiConfig";
import PageLayoutSimple from "Components/PageLayoutSimple";
import FetchUserIdButton from "Features/auth/FetchUserIdButton";
import useAccessToken from "Features/auth/useAccessToken";

export default function Test() {
  const {instance, accounts} = useMsal();
  const tokenRequestConfig = {
    account: accounts[0],
    scopes: [
      "https://capla360.onmicrosoft.com/api/capla.read",
      "https://capla360.onmicrosoft.com/api/capla.write",
    ],
  };
  const api = axios.create({baseURL: apiConfig.baseURL});

  const [name, setName] = useState("");

  const token = useAccessToken();
  const {id: userId} = useSelector(
    (state) => state.auth.data
  );

  function handleChange(e) {
    setName(e.target.value);
  }

  async function handleProfileClick() {
    const token = await instance.acquireTokenSilent(tokenRequestConfig);
    const response = await api.get("/profile/", {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    });
    console.log(response.data);
  }

  async function handleListingsClick() {
    const token = await instance.acquireTokenSilent(tokenRequestConfig);
    const response = await api.get(`users/${userId}/listings/`, {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      },
    });
    console.log(response.data);
  }

  async function handleCreateClick() {
    const token = await instance.acquireTokenSilent(tokenRequestConfig);
    const response = await api.post(
      `users/${userId}/listings/`,
      {name},
      {
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    console.log(response.data);
  }

  async function handleGetUserIdClick() {
    const response = await api.get(`signin/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
  }

  return (
    <PageLayoutSimple>
      <Button onClick={handleProfileClick}>get profile</Button>
      <Button onClick={handleListingsClick} variant="outlined">
        get user listings
      </Button>
      <div>
        <TextField onChange={handleChange} value={name} />
        <Button onClick={handleCreateClick}>Create List</Button>
      </div>
      <div>
        <Button onClick={handleGetUserIdClick}>Get User Id</Button>
      </div>
      <FetchUserIdButton />
    </PageLayoutSimple>
  );
}
