export default function updateNumsOfTypeNomenclature(
  nomenclatureItems,
  template
) {
  //const firstItem = nomenclatureItems.find((item) => item.num.endsWith(".1"));
  //
  // const itemsToSort = nomenclatureItems.filter(
  //   (item) => item.id !== firstItem.id
  // );
  //
  //let sortedItems = [...itemsToSort];
  //
  let sortedItems = [...nomenclatureItems];
  //

  if (template === "dim1xheight cm") {
    sortedItems = sortedItems
      .sort((a, b) => a.height - b.height)
      .sort((a, b) => a.dim1 - b.dim1);
  } else if (template === "dim1xdim2 cm") {
    sortedItems = sortedItems
      .sort((a, b) => a.dim2 - b.dim2)
      .sort((a, b) => a.dim1 - b.dim1);
  } else if (template === "dim1xL cm") {
    sortedItems = sortedItems
      .sort((a, b) => a.length - b.length)
      .sort((a, b) => a.dim1 - b.dim1);
  } else if (template === "dim1 cm" || template === "diam cm") {
    sortedItems = sortedItems.sort((a, b) => a.dim1 - b.dim1);
  } else if (template === "height m" || template === "height cm") {
    sortedItems = sortedItems.sort((a, b) => a.height - b.height);
  } else if (template === "stairs") {
    sortedItems = sortedItems
      .sort((a, b) => a.dim1 - b.dim1)
      .sort((a, b) => parseFloat(a.p1 ?? "0") - parseFloat(a.p1 ?? "0"))
      .sort((a, b) => a.height - b.height)
      .sort((a, b) => a.p2.localeCompare(b.p2));
  } else if (template === "p0") {
    sortedItems = sortedItems.sort((a, b) => a.p0?.localeCompare(b.p0));
  } else if (template === "p3") {
    sortedItems = sortedItems.sort((a, b) => a.p3?.localeCompare(b.p3));
  } else if (template === "p1") {
    sortedItems = sortedItems.sort(
      (a, b) => parseFloat(a.p1 ?? "0") - parseFloat(b.p1 ?? "0")
    );
  } else if (template === "p2") {
    sortedItems = sortedItems.sort(
      (a, b) => parseFloat(a.p2 ?? "0") - parseFloat(b.p2 ?? "0")
    );
  }
  //
  console.log("debugAFA updateNumsOfTypeNomenclature", template, sortedItems);
  //
  // const headerNum = firstItem?.num
  //   ? firstItem.num.split(".").slice(0, -1).join(".")
  //   : "0";

  const sortedItemsWithNums = sortedItems.map((item, index) => {
    //const num = `${headerNum}.${index + 2}`;
    const num = `${index + 1}`;
    return {...item, num};
  });

  //return [firstItem, ...sortedItemsWithNums];
  return [...sortedItemsWithNums];
}
