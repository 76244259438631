import articleTypesMapToArray from "./articleTypesMapToArray";
import articleTypesArrayToMap from "./articleTypesArrayToMap";

export default function removeParentItemsFromArticleTypes(articleTypes, item) {
  // compute

  let typesArray = articleTypesMapToArray(articleTypes);

  console.log("debugAFA99 removeParent BEFORE", typesArray);
  // remove parents

  const newItems = [];

  typesArray.forEach((type) => {
    let remove = false;
    if (type.groupId && type.typeId && type.typeId !== item.typeId) {
      if (type.typeNum && item.typeNum) {
        if (item.typeNum.startsWith(type.typeNum)) {
          remove = true;
        }
      }
    }
    if (!remove) newItems.push(type);
  });

  console.log("debugAFA99 removeParent AFTER", newItems);
  return articleTypesArrayToMap(newItems);

  //
}
