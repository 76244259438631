import {createSlice} from "@reduxjs/toolkit";

export const ifcSlice = createSlice({
  name: "ifc",
  initialState: {
    ifcModelsInScope: [],
    selectedIfcModelId: null,
    updatedAt: null, // use as a dependency to refresh states when loading ifc data is done.
    storeysInScopeIds: [],
    propsTypesInScope: [],
    edgeCasesIn: [], // [expressIDs] : individual elements added to the scope.
    edgeCasesOut: [], // [expressIDs] : elements that should not be in the scope.
    dataGridMode: "ALL",
  },
  reducers: {
    setIfcModelsInScope: (state, action) => {
      state.ifcModelsInScope = action.payload;
    },
    setSelectedIfcModelId: (state, action) => {
      state.selectedIfcModelId = action.payload;
    },
    update: (state) => {
      state.updatedAt = new Date().toISOString();
    },
    setStoreysInScope: (state, action) => {
      state.storeysInScopeIds = action.payload;
    },
    setPropsTypesInScope: (state, action) => {
      state.propsTypesInScope = action.payload;
    },
    setDataGridMode: (state, action) => {
      state.dataGridMode = action.payload;
    },
    resetScope: (state, action) => {
      state.storeysInScopeIds = [];
      state.propsTypesInScope = [];
    },
    addEdgeCaseIn: (state, action) => {
      state.edgeCasesIn.push(action.payload);
    },
    addEdgeCaseOut: (state, action) => {
      state.edgeCasesOut.push(action.payload);
    },
    resetEdgeCasesIn: (state) => {
      state.edgeCasesIn = [];
    },
  },
});

export const {
  setIfcModelsInScope,
  setSelectedIfcModelId,
  update,
  setStoreysInScope,
  setPropsTypesInScope,
  setDataGridMode,
  resetScope,
  resetEdgeCasesIn,
  addEdgeCaseIn,
  addEdgeCaseOut,
} = ifcSlice.actions;

export default ifcSlice.reducer;
