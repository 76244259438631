import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchRemoteSceneOverview} from "Features/scenes/scenesSlice";
import {useParams} from "react-router-dom";
import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchRemoteSceneOverview() {
  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken && sceneId) {
      dispatch(fetchRemoteSceneOverview({sceneId, accessToken}));
    }
  }, [sceneId, accessToken]);
}
