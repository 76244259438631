import {Box} from "@mui/material";

import SectionVersionChips from "./SectionVersionChips";

export default function SelectorMultipleVersionsWithChip({
  versions,
  selectedVersionIds,
  onChange,
  options,
}) {
  // selection = null => all
  // selection = [] => none
  // selection = [id1,id2] => RAS

  // options

  const hideAllHelper = options?.hideAllHelper;

  // handlers

  function handleClick(versionId) {
    let newSelection = Array.isArray(selectedVersionIds)
      ? [...selectedVersionIds]
      : null;

    if (versionId === "all") {
      if (!selectedVersionIds) {
        newSelection = [];
      } else {
        newSelection = null;
      }
    } else {
      if (!selectedVersionIds) {
        newSelection = [versionId];
      } else {
        newSelection = newSelection.filter((id) => id !== versionId);
        if (!selectedVersionIds.includes(versionId)) {
          newSelection = [...newSelection, versionId];
          if (newSelection.length === versions.length) {
            newSelection = null;
          }
        }
      }
    }
    onChange(newSelection);
  }

  return (
    <Box sx={{width: 1, display: "flex", flexWrap: "wrap"}}>
      <SectionVersionChips
        versions={versions}
        selectedVersionIds={selectedVersionIds}
        onClick={handleClick}
        hideAllHelper={hideAllHelper}
      />
    </Box>
  );
}
