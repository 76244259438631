import cleanMeasurementsBeforeSaving from "../utils/cleanMeasurementsBeforeSaving";

export default class Saver {
  constructor({pdfEditor}) {
    this.pdfEditor = pdfEditor;
  }

  async savePdfScale(pdfScale) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "PDF_SCALE");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "PDF_SCALE");
    await annot.setCustomData("pdfScale", JSON.stringify(pdfScale));
  }

  async savePhases(phases) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "PHASES");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "PHASES");
    await annot.setCustomData("phases", JSON.stringify(phases));
  }

  async saveSectors(sectors) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "SECTORS");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "SECTORS");
    await annot.setCustomData("sectors", JSON.stringify(sectors));
  }

  async saveRooms(rooms) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "ROOMS");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "ROOMS");
    await annot.setCustomData("rooms", JSON.stringify(rooms));
  }

  async saveZones(zones) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "ZONES");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "ZONES");
    await annot.setCustomData("zones", JSON.stringify(zones));
  }

  async saveRessources(ressources) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "RESSOURCES");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "RESSOURCES");
    await annot.setCustomData("ressources", JSON.stringify(ressources));

    console.log("ressourcesSaved21", ressources);
  }

  async saveElementTypes(elementTypes) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "ELEMENT_TYPES");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "ELEMENT_TYPES");
    await annot.setCustomData("elementTypes", JSON.stringify(elementTypes));
  }

  async savePackages(packages) {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "PACKAGES");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "PACKAGES");
    await annot.setCustomData("packages", JSON.stringify(packages));
  }

  async saveMeasurements(measurements) {
    const cleanMs = cleanMeasurementsBeforeSaving(measurements);
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "MEASUREMENTS");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("type", "MEASUREMENTS");
    await annot.setCustomData("measurements", JSON.stringify(cleanMs));
  }

  async addHasBimDataAnnot() {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("hasBimData") === "true");
    if (!annot) {
      annot = new Annotations.PolygonAnnotation();
      annot.PageNumber = 1;
      annot.X = 0;
      annot.Y = 0;
      annotationManager.addAnnotation(annot);
    }

    await annot.setCustomData("hasBimData", "true");
  }

  async getMetaAnnotationsXfdf() {
    const {annotationManager} = this.pdfEditor.webViewer.Core;
    let annots = annotationManager
      .getAnnotationsList()
      .filter(
        (a) => !["MEASUREMENT", "ZONE"].includes(a.getCustomData("type"))
      );
    return await annotationManager.exportAnnotations({annotList: annots});
  }

  async getMetaAndMeasurementsAnnotationsXfdf() {
    const {annotationManager} = this.pdfEditor.webViewer.Core;
    let annots = annotationManager
      .getAnnotationsList()
      .filter((a) => !["ZONE"].includes(a.getCustomData("type")));
    return await annotationManager.exportAnnotations({annotList: annots});
  }

  async saveBimData({
    phases,
    sectors,
    rooms,
    zones,
    elementTypes,
    ressources,
    packages,
    measurements,
    pdfScale,
  }) {
    await this.savePhases(phases);
    await this.saveSectors(sectors);
    await this.saveRooms(rooms);
    await this.saveZones(zones);
    await this.saveElementTypes(elementTypes);
    await this.saveRessources(ressources);
    await this.savePackages(packages);
    await this.saveMeasurements(measurements);
    await this.savePdfScale(pdfScale);

    await this.addHasBimDataAnnot();
  }
}
