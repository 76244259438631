export default function fillWorksheetWithSubjectTypeMap({
  worksheet,
  subjectTypeMap,
  rowStartIndex,
}) {
  subjectTypeMap.forEach(
    (
      {
        subjectName,
        subjectCount,
        subjectDrawingShape,
        subjectColor,
        subjectDotted,
        id,
        group,
        num,
        name,
        code,
        drawingShape,
        color,
        dotted,
        isVoid,
        unit,
        dim1,
        dim2,
        height,
      },
      index
    ) => {
      const row = worksheet.getRow(rowStartIndex + index);
      if (typeof subjectName === "string") row.getCell(1).value = subjectName;
      if (typeof subjectCount === "number") row.getCell(2).value = subjectCount;
      if (typeof subjectDrawingShape === "string")
        row.getCell(3).value = subjectDrawingShape;
      if (typeof subjectColor === "string") row.getCell(4).value = subjectColor;
      if (typeof subjectDotted === "boolean")
        row.getCell(5).value = subjectDotted ? "x" : "";
      if (typeof id === "string") row.getCell(6).value = id;
      if (typeof group === "string") row.getCell(7).value = group;
      if (typeof num === "string") row.getCell(8).value = num;
      if (typeof name === "string") row.getCell(9).value = name;
      if (typeof code === "string") row.getCell(10).value = code;
      if (typeof drawingShape === "string")
        row.getCell(11).value = drawingShape;
      if (typeof color === "string") row.getCell(12).value = color;
      if (typeof dotted === "boolean")
        row.getCell(13).value = dotted ? "x" : "";
      if (typeof isVoid === "boolean")
        row.getCell(14).value = isVoid ? "x" : "";
      if (typeof unit === "string") row.getCell(15).value = unit;
      if (typeof dim1 === "number") row.getCell(16).value = dim1;
      if (typeof dim2 === "number") row.getCell(17).value = dim2;
      if (typeof height === "number") row.getCell(18).value = height;

      // style
      if (color && typeof subjectColor === "string") {
        row.getCell(4).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {argb: color.substring(1)},
        };
        row.getCell(4).font = {color: {argb: "FFFFFF"}};
      }

      if (color && typeof color === "string") {
        row.getCell(12).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {argb: color.substring(1)},
        };
        row.getCell(12).font = {color: {argb: "FFFFFF"}};
      }
    }
  );
}
