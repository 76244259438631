import {filter} from "jszip";
import useRessourcesByScene from "./useRessourcesByScene";
import {useSelector} from "react-redux";

export default function useSelectedRessource(scene, options) {
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  //
  const selectedRessourceId = useSelector(
    (s) => s.ressources.selectedRessourceId
  );
  const selectedRessourceId2 = useSelector(
    (s) => s.ressources.selectedRessourceIdInEditMeasurements
  );
  //
  const inEditMeasurements = options?.inEditMeasurements;

  const id = inEditMeasurements ? selectedRessourceId2 : selectedRessourceId;

  return ressources.find((s) => s.id === id);
}
