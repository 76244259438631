import {Box} from "@mui/material";

import ToolMarkers from "Features/viewer3D/components/ToolMarkers";

export default function ShortcutMarkers({editor3d, scene}) {
  return (
    <Box sx={{width: 300}}>
      <ToolMarkers editor3d={editor3d} scene={scene} />
    </Box>
  );
}
