import React, {useState} from "react";

import {TextField, Button, Box, Typography, alpha} from "@mui/material";
import {styled} from "@mui/material/styles";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

const CaplaTextField = styled(TextField)(({theme}) => ({
  "& .MuiInputBase-input": {
    fontSize: theme.typography.body2.fontSize,
    zIndex: 3,
  },
}));
export default function ActionNameMarker({onAdd}) {
  const {t} = useTranslation(["common,viewer3D"]);

  // string
  const addString = capitalizeStart(t("common:add"));
  const markerNameString = t("viewer3D:markers.addMarkerProcess.markerName");

  // local state

  const [name, setName] = useState("");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        p: 1,
        backgroundColor: "common.white",
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        zIndex: 90,
      }}
    >
      <CaplaTextField
        label={<Typography variant="body2">{markerNameString}</Typography>}
        sx={{mr: (theme) => theme.spacing(2)}}
        size="small"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <Button variant="outlined" size="small" onClick={() => onAdd(name)}>
        {addString}
      </Button>
    </Box>
  );
}
