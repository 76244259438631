import {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {updateElementTypesGroup} from "Features/elementTypes/elementTypesSlice";
import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
} from "@mui/material";

import ListRawSubjectElementTypes from "./ListRawSubjectElementTypes";
import ListRawSubjectElementTypesHeader from "./ListRawSubjectElementTypesHeader";

import SelectOptions from "Components/SelectOptions";

import useAddSceneElementTypes from "Features/elementTypes/hooks/useAddSceneElementTypes";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
// import Stretch from "Features/ui/components/Stretch";

import ToggleNameFromRawType from "./ToggleNameFromRawType";
import DataGridRawTypesAndElementTypes from "Features/elementTypes/components/DataGridRawTypesAndElementTypes";
import useSelectedElementTypesGroup from "Features/elementTypes/hooks/useSelectedElementTypesGroup";
import {rawTypeToType} from "../utils/rawTypeToType";
import useAccessToken from "Features/auth/useAccessToken";

export default function DialogExtractElementTypes({
  editorPdf,
  pdfModel,
  open,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Extraire les éléments / ouvrages des annotations du PDF";

  const filterS = "Filtrer les annotations par auteur";

  const authorS = "Auteur";
  const elementTypesS = "Elements";
  const subjectsS = "Sujets";

  const extractS = "Extraire";
  const cancelS = "Annuler";

  // data

  const addSceneElementTypes = useAddSceneElementTypes(scene);

  const elementTypes = useElementTypesBySceneProxy(scene);

  const selectedElementTypesGroup = useSelectedElementTypesGroup(scene);
  console.log("selectedElementTypesGroup", selectedElementTypesGroup);

  // state

  const [nameFrom, setNameFrom] = useState("subject");
  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState("");

  const [measurementsBySubjectOverview, setMeasurementsBySubjectOverview] =
    useState([]);

  const [rawMeasurements, setRawMeasurements] = useState([]);

  const initSubjectElementTypeMap = {};
  elementTypes.forEach((type) => {
    if (type.subject) {
      initSubjectElementTypeMap[type.subject] = type;
    }
  });
  const [subjectElementTypeMap, setSubjectElementTypeMap] = useState({}); // {subject:elementType}
  useEffect(() => {
    setSubjectElementTypeMap(initSubjectElementTypeMap);
  }, [open]);

  // state - selection (checkbox in datagrid)

  const [selection, setSelection] = useState([]);

  // helpers

  const mnger = editorPdf?.annotationsManager?.measurementsPdfManager;

  // helpers - items

  const items = measurementsBySubjectOverview.map((rawType) => ({
    id: rawType.rawId,
    rawType,
    elementType: subjectElementTypeMap[rawType.subject],
  }));

  // helpers - canExtract

  const canExtract = true;

  // helpers - count

  let count = rawMeasurements?.filter(
    (m) => !author || m.author === author
  )?.length;
  if (!count) count = "...";

  // helpers - title 2

  let count2 = measurementsBySubjectOverview?.length;
  if (!count2) count2 = "...";
  const title2 = `${count2} ${subjectsS}`;

  // handlers

  function handleClose() {
    onClose();
  }

  // handlers - subjectCode

  function handleSubjectElementTypeChange(subjectElementType) {
    const newMap = {...subjectElementTypeMap};
    newMap[subjectElementType.subject] = subjectElementType.elementType;
    setSubjectElementTypeMap(newMap);
  }

  // handlers - extract

  async function handleExtract() {
    const selectedItems = items.filter((item) => selection.includes(item.id));
    const types = [];
    selectedItems.forEach(({rawType, elementType}) => {
      let type = {...elementType, subject: rawType.rawName};
      if (elementType) {
        const existingType = elementTypes.find(
          (t) => t.code === elementType.code
        );
        if (!existingType) {
          type.id = nanoid();
        } else {
          type.id = existingType.id;
        }
      } else {
        type = rawTypeToType(rawType);
      }
      types.push(type);
    });
    let newTypes;
    if (Array.isArray(selectedElementTypesGroup.elementTypes)) {
      newTypes = [...selectedElementTypesGroup.elementTypes, ...types];
    } else {
      newTypes = types;
    }
    const elementTypesGroup = {
      ...selectedElementTypesGroup,
      elementTypes: newTypes,
    };
    await dispatch(updateElementTypesGroup({accessToken, elementTypesGroup}));
    //addSceneElementTypes(types);
    onClose();
  }

  // handlers - author

  function handleAuthorChange(author) {
    setAuthor(author);
  }

  // effects

  useEffect(() => {
    if (mnger && pdfModel?.id) {
      mnger.extractor.extractRawMeasurements();
      setRawMeasurements(mnger.extractor.rawMeasurements);

      const authors = mnger.extractor.getRawMeasurementsByAuthorOverview();
      setAuthors(authors.map((a) => a.name));

      const mBySubject =
        mnger.extractor.getAuthorRawMeasurementsBySubjectOverview(
          author,
          nameFrom
        );
      setMeasurementsBySubjectOverview(mBySubject);
    }
  }, [pdfModel?.id, mnger, author, open, nameFrom]);

  // debug

  const debugClick = async () => {
    const layers = await editorPdf.getLayers();
    console.log("layers", layers);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false} fullScreen>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Button onClick={debugClick}>debug</Button>
        <Box sx={{mt: 2}}>
          <Box sx={{display: "flex", alignItems: "baseline"}}>
            <Typography variant="h6" sx={{mr: 1}}>
              {count}
            </Typography>
            <Typography variant="body2">{elementTypesS}</Typography>
            <Box sx={{ml: 2}}>
              <ToggleNameFromRawType
                nameFrom={nameFrom}
                onChange={setNameFrom}
              />
            </Box>
          </Box>

          <Box sx={{mt: 1, bgcolor: "background.default", p: 1}}>
            <Typography variant="body2">{filterS}</Typography>
            <SelectOptions
              options={authors}
              value={author}
              onChange={handleAuthorChange}
              label={authorS}
            />
          </Box>

          <Box
            sx={{
              mt: 2,
              mb: 1,
            }}
          >
            <Typography variant="body2">{title2}</Typography>
          </Box>

          <DataGridRawTypesAndElementTypes
            items={items}
            scene={scene}
            selection={selection}
            onSelectionChange={setSelection}
          />
          {/* <ListRawSubjectElementTypes
            items={items}
            scene={scene}
            onSubjectElementTypeChange={handleSubjectElementTypeChange}
          /> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelS}</Button>
        <Button disabled={!canExtract} onClick={handleExtract}>
          {extractS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
