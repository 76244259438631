import {createSlice} from "@reduxjs/toolkit";

export const issuesetsSlice = createSlice({
  name: "issuesets",
  initialState: {
    selectedIssuesetId: null,
  },
  reducers: {
    setSelectedIssuesetId: (state, action) => {
      state.selectedIssuesetId = action.payload;
    },
  },
});

export const {setSelectedIssuesetId} = issuesetsSlice.actions;

export default issuesetsSlice.reducer;
