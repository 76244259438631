import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {Box, Typography, alpha} from "@mui/material";
// import {ViewInAr as Bimbox} from "@mui/icons-material";

import ButtonDisableViewerHelperScreens from "./ButtonDisableViewerHelperScreens";

import SectionSceneRessourcesInLeftPanel from "Features/ressources/components/SectionSceneRessourcesInLeftPanel";
// import ButtonDownloadSceneData from "Features/excel/components/ButtonDownloadSceneData";
import ButtonDownloadSceneDataInViewer from "Features/excel/components/ButtonDownloadSceneDataInViewer";
import { fetchPrices } from "Features/pricing/pricingSlice";
import DialogDownloadSceneDataInViewer from "Features/excel/components/DialogDownloadSceneDataInViewer";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function PageLandingSceneInViewer({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const {shareLink} = useParams();

  // strings

  const description = "Cliquez sur un tableau bimboxé pour l'ouvrir";

  // data

  const packages = usePackagesByScene(scene);

  // state

  const [download, setDownload] = useState(false)

  const handleExportClick = () => {
    caplaEditor?.measDataManager.setModelsInShortMemory(
      packages.filter((p) => !p.archived).map((p) => p.id),
      false
    );
    setDownload(true);
  };

  // Fetch prices if needed

  const fetchStatus = useSelector((s) => s.pricing.status);

  useEffect(() => {
    if (scene?.id && scene?.usesPrices && fetchStatus === "idle")
      dispatch(fetchPrices({
        sceneId: scene?.id,
        sharedBimboxId: shareLink,
      }));
  }, [scene?.id, fetchStatus])

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        color: "common.white",
        bgcolor: (theme) => alpha(theme.palette.common.caplaBlack, 1),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Typography variant="body1" sx={{mt: 3}}>
        {description}
      </Typography>
      <Box sx={{width: 450, mt: 5, minHeight: 0, flexGrow: 0.5}}>
        <SectionSceneRessourcesInLeftPanel scene={scene} caplaEditor={caplaEditor} inBlack />
      </Box>
      <Box sx={{width: 260, mt: 8, pb: 8}}>
        <ButtonDownloadSceneDataInViewer
          inBlack
          handleClick={handleExportClick}
        />
        {download && (
          <DialogDownloadSceneDataInViewer
            scene={scene}
            onClose={() => setDownload(false)}
            caplaEditor={caplaEditor}
          />
        )}
      </Box>
      <Box sx={{position: "absolute", bottom: "16px", left: "16px"}}>
        <ButtonDisableViewerHelperScreens />
      </Box>
    </Box>
  );
}
