import {
  MeshLambertMaterial,
  MeshPhongMaterial,
  MeshBasicMaterial,
  TextureLoader,
  SpriteMaterial,
  Color,
  DoubleSide,
  LineBasicMaterial,
} from "three";

import markerSpritePink from "../textures/markerSpritePink.png";
import markerSpriteBlue from "../textures/markerSpriteBlue.png";
import markerSprite from "../textures/markerSprite.png";

export const pickedColor = new Color(0xbc057f);
export const selectedColor = new Color(0x277cea);
export const noColor = new Color();
export const configMarker1Color = new Color(0xbc057f);

export const preselectMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.6,
  color: 0xff88ff,
  depthTest: false,
});

export const selectedMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.9,
  color: 0x277cea,
  depthTest: false,
});

export const pickedMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.8,
  color: 0xbc057f,
  depthTest: true,
});

export const defaultMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.5,
  //color: 0xfab41f,
  color: 0x277cea,
  depthTest: true,
});

// ---* All *---

export const lineMaterial = new LineBasicMaterial({color: 0x000000});

// ---* Image Model *---

export const imageModelLineMaterial = new LineBasicMaterial({
  color: 0x000000,
});

export const imageModelPickedLineMaterial = new LineBasicMaterial({
  color: 0xfab41f,
});

export const imageModelMaterial = new MeshBasicMaterial({
  transparent: true,
  opacity: 0.8,
  color: 0xf4f4f8,
  depthTest: true,
});

// ---* Ifc Model *---

export const ifcModelMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.9,
  //color: 0xfab41f,
  color: 0x277cea,
  depthTest: true,
});
// ---* Ifc Model Entity *---

export const ifcModelEntityMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.5,
  //color: 0xfab41f,
  color: 0x277cea,
  depthTest: true,
});

// ---* Object Model *---

export const objectModelMaterial = new MeshLambertMaterial({
  transparent: true,
  opacity: 0.5,
  color: 0x410d65, // dark purple
  depthTest: true,
});

// ---* Marker *----

const pinkTexture = new TextureLoader().load(markerSpritePink);
const blueTexture = new TextureLoader().load(markerSpriteBlue);
const defaultTexture = new TextureLoader().load(markerSprite);

export const pickedMarkerMaterial = new SpriteMaterial({
  map: pinkTexture,
  alphaTest: 0.5,
  transparent: true,
});
export const selectedMarkerMaterial = new SpriteMaterial({
  map: blueTexture,
  alphaTest: 0.5,
  transparent: true,
});
export const defaultMarkerMaterial = new SpriteMaterial({
  map: defaultTexture,
  alphaTest: 0.5,
  transparent: true,
  side: DoubleSide,
});
export const config1MarkerMaterial = new SpriteMaterial({
  map: blueTexture,
  alphaTest: 0.5,
  transparent: true,
});
export const config2MarkerMaterial = new SpriteMaterial({
  map: pinkTexture,
  alphaTest: 0.5,
  transparent: true,
});

// ---* Ifc Model Subset *---

export const subsetMaterial = (index) => {
  const colors = [
    0x277cea, 0x3d72df, 0x4f6bd6, 0x6163ce, 0x725dc5, 0x8355bd, 0x944eb4,
    0xa547ac, 0xb73fa3, 0xc9389b, 0xd93092, 0xea2989,
  ];
  const _index = index % colors.length;
  const color = colors[_index];

  const material = new MeshLambertMaterial({
    transparent: true,
    opacity: 0.8,
    color,
    depthTest: true,
  });
  return material;
};

// ---* Measure *---

export const measureLineMaterial = new LineBasicMaterial({
  color: 0xfab41f,
  linewidth: 5,
});
