import {Box} from "@mui/material";
import BlockEditableArticlesGroupTypesGroups from "./BlockEditableArticlesGroupTypesGroups";

import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

export default function SectionArticlesGroupInSelectionPanel({scene}) {
  // data

  const articlesGroup = useSelectedArticlesGroup();

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{flexGrow: 1, overflow: "auto"}}>
        <BlockEditableArticlesGroupTypesGroups
          scene={scene}
          articlesGroup={articlesGroup}
        />
      </Box>
    </Box>
  );
}
