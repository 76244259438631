export default function scenesToScenesForDatagrid(scenes) {
  return scenes
    .filter((s) => s.clientId)
    .map((scene) => {
      return {
        id: scene.clientId,
        remoteId: scene.id,
        isRemote: scene.isRemote,
        name: scene.title,
        operation: scene.operationName,
        deadline: scene.prodDeadline ? scene.prodDeadline : "-",
        status: scene.prodStatus,
        contact: scene.contactEmail,
      };
    })
    .sort((a, b) => b.deadline.localeCompare(a.deadline));
}
