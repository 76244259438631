import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardContent,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
export default function FormLoadingSettings({model}) {
  const {t} = useTranslation("viewer3D");
  const dispatch = useDispatch();

  // strings

  const title = t("model.settings.loading.title");
  const loadingTypeLabel = t("model.settings.loading.loadingType.label");
  const manualString = t("model.settings.loading.loadingType.manual");
  const autoString = t("model.settings.loading.loadingType.auto");

  // data

  const autoloading = model?.autoloading;
  const loadingMode = autoloading ? "auto" : "manual";

  console.log("loading mode", loadingMode);

  // handlers

  function handleIsLoadingModelChange() {}

  function handleLoadingModelChange() {}

  function handleAutoloadingChange(e) {
    const newMode = e.target.value;
    const newAutoloading = newMode === "auto" ? true : false;
    const newModel = {...model, autoloading: newAutoloading};
    dispatch(updateModel({updatedModel: newModel}));
  }
  return (
    <Box sx={{p: 2}}>
      <Card>
        <CardContent>
          <Typography variant="body2" gutterBottom>
            <b>{title}</b>
          </Typography>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography variant="body2">{loadingTypeLabel}</Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-label="loadingType"
              defaultValue="auto"
              name="row-radio-buttons-group"
              onChange={handleAutoloadingChange}
              value={loadingMode}
            >
              <FormControlLabel
                value={"auto"}
                control={<Radio size="small" />}
                label={<Typography variant="body2">{autoString}</Typography>}
              />
              <FormControlLabel
                value={"manual"}
                control={<Radio size="small" />}
                label={<Typography variant="body2">{manualString}</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    </Box>
  );
}
