import {useState, useEffect} from "react";

import {Box, TextField, Typography} from "@mui/material";

export default function ListItemEditedRelationRessource({
  resId,
  ressource,
  formula,
  filter,
  onChange,
  onBlur,
}) {
  const formulaS = "Formule";
  const filterS = "Condition";

  // state

  const [formulaV, setFormulaV] = useState(formula);
  const [filterV, setFilterV] = useState(filter);

  useEffect(() => {
    setFormulaV(formula);
    setFilterV(filter);
  }, [formula, filter]);

  // helper

  const label = ressource?.num + " • " + ressource?.name;

  // handlers

  function handleBlur() {
    onBlur({resId, formula: formulaV, filter: filterV});
  }

  function handleFormulaChange(e) {
    const value = e.target.value;
    setFormulaV(value);
    onChange({resId, field: "formula", value});
  }
  function handleFilterChange(e) {
    const value = e.target.value;
    setFilterV(value);
    onChange({resId, field: "filter", value});
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mt: 1,
        px: 1,
        justifyContent: "space-between",
        minWidth: 0
      }}
    >
      <Box sx={{display: "flex", minWidth: 0}}>
        <Typography sx={{fontSize: 13}} noWrap>
          {label}
        </Typography>
      </Box>
      <Box sx={{display: "flex"}}>
        <Box sx={{px: 0.5, width: 100}}>
          <TextField
            size="small"
            fullWidth
            label={<Typography sx={{fontSize: 13}}>{formulaS}</Typography>}
            InputProps={{
              sx: {fontSize: 13},
            }}
            value={formulaV}
            onChange={handleFormulaChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box sx={{px: 0.5, width: 100}}>
          <TextField
            size="small"
            fullWidth
            label={<Typography sx={{fontSize: 13}}>{filterS}</Typography>}
            InputProps={{
              sx: {fontSize: 13},
            }}
            value={filterV}
            onChange={handleFilterChange}
            onBlur={handleBlur}
          />
        </Box>
      </Box>
    </Box>
  );
}
