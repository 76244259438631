import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {fetchScopes} from "../scopesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useFetchScopeByScene(scene) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const sceneId = scene?.id;

  async function getScopes() {
    try {
      if (accessToken && scene?.id) {
        const result = await dispatch(fetchScopes({accessToken, sceneId}));
        if (result) {
          console.log("Fetch scopes", unwrapResult(result));
        }
      }
    } catch (e) {
      console.log("error getting scopes", e);
    }
  }

  useEffect(() => {
    if (sceneId && accessToken) {
      getScopes();
    }
  }, [sceneId, accessToken]);
}
