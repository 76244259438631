import {createSlice} from "@reduxjs/toolkit";

export const librarySlice = createSlice({
  name: "library",
  initialState: {
    name: "",
    elementTypes: [],
    groupSelectionMap: {}, // {group:selectedElementTypesIds}
    selectedGroup: null,
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setElementTypes: (state, action) => {
      state.elementTypes = action.payload;
    },
    updateGroupSelectionMap: (state, action) => {
      const {group, selection} = action.payload;
      state.groupSelectionMap[group] = selection;
    },
    resetGroupSelectionMap: (state) => {
      state.groupSelectionMap = {};
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
  },
});

export const {
  setElementTypes,
  updateGroupSelectionMap,
  setSelectedGroup,
  resetGroupSelectionMap,
  setName,
} = librarySlice.actions;

export default librarySlice.reducer;
