import {useSelector, useDispatch} from "react-redux";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useSelectedMarker from "../hooks/useSelectedMarker";
import useSelectedMarkersModel from "../hooks/useSelectedMarkersModel";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {setIsEditing, setEditedMarker} from "Features/markers/markersSlice";

export default function ActionsMarkerInSelectionPanel({editor3d}) {
  const dispatch = useDispatch();
  // strings

  const saveS = "Save";
  const editS = "Edit";
  const cancelS = "Cancel";

  // data

  const isEditing = useSelector((s) => s.markers.isEditing);
  const selectedMarker = useSelectedMarker();
  const editedMarker = useSelector((s) => s.markers.editedMarker);
  const selectedMarkersModel = useSelectedMarkersModel();

  // helper - ressource

  let marker = isEditing ? editedMarker : selectedMarker;

  // handlers

  function handleEditClick() {
    dispatch(setIsEditing(true));
    dispatch(setEditedMarker({...selectedMarker}));
  }

  function handleCancelClick() {
    dispatch(setIsEditing(false));
  }

  function handleSaveClick() {
    // update marker
    const oldItems = selectedMarkersModel.elements.items;
    let newItems = oldItems.filter((m) => m.id !== marker.id);
    newItems = [...newItems, marker];
    const updatedModel = {
      ...selectedMarkersModel,
      elements: {...selectedMarkersModel.elements, items: newItems},
    };
    dispatch(updateModel({updatedModel}));

    //3D

    const entity = editor3d?.getEntity(selectedMarkersModel?.id);
    entity?.updateMarkerPosition({
      markerId: marker.id,
      x: marker.x,
      y: marker.y,
      z: marker.z,
    });
    // end
    dispatch(setIsEditing(false));
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {/* <Box sx={{flexGrow: 1}}>
        <Button variant="contained" color="primary" fullWidth>
          {deleteS}
        </Button>
      </Box> */}

      {!isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Edit />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleEditClick}
          >
            {editS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
