import {client} from "API/capla360";

export async function fetchQuantitiesService({accessToken, listingId}) {
  const response = await client.get(`listings/${listingId}/quantities/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export const fetchQuantitiesBySceneService = async ({accessToken, sceneId}) => {
  const response = await client.get(`/scenes/${sceneId}/quantities/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return {items: response.data, sceneId};
};

export async function createQuantityService({
  accessToken,
  quantity,
  listingId,
}) {
  const response = await client.post(
    `listings/${listingId}/quantities/`,
    quantity,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return response.data;
}

export async function fetchQuantityService({accessToken, quantityId}) {
  const response = await client.get(`quantities/${quantityId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function updateQuantityService({accessToken, quantity}) {
  const quantityId = quantity.id;
  const response = await client.put(`quantities/${quantityId}`, quantity, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}

export async function deleteQuantityService({accessToken, quantityId}) {
  const response = await client.delete(`quantities/${quantityId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
}
