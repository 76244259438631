import getItemsMapById from "Utils/getItemsMapById";

const makeMeasurementVoids = (measurement, measurementsById) => {
  const measurementVoids = [];
  measurement.voids.forEach((voidId) => {
    const voidItem = measurementsById[voidId];
    if (voidItem && voidItem.drawingShape === "SEGMENT") {
      let area = 0;
      // WARNING ONLY WORKS FOR LATERALLY FULLY CONTAINED VOIDS
      if (
        voidItem.zInf >= measurement.zInf &&
        voidItem.zSup <= measurement.zSup
      ) {
        area = voidItem.area;
      } else if (
        voidItem.zInf < measurement.zInf &&
        voidItem.zSup > measurement.zSup
      ) {
        area = voidItem.length * measurement.height;
      } else if (
        voidItem.zInf < measurement.zInf &&
        voidItem.zSup <= measurement.zSup
      ) {
        area = voidItem.length * (voidItem.zSup - measurement.zInf);
      } else if (
        voidItem.zInf >= measurement.zInf &&
        voidItem.zSup > measurement.zSup
      ) {
        area = voidItem.length * (measurement.zSup - voidItem.zInf);
      }
      const v = {
        id: measurement.id + "-" + voidItem.id,
        codeName: voidItem.codeName + " <--[ " + measurement.codeName + " ]",
        res: measurement.res,
        isVoid: true,
        elementTypeId: measurement.elementTypeId,
        count: 1,
        length: voidItem.length,
        area: area,
        volume: measurement.dim1 ? measurement.dim1 * area : voidItem.volume,
        unit: measurement.unit,
        roomId: measurement.roomId,
        sectorId: measurement.sectorId,
      };
      measurementVoids.push(v);
    }
  });
  return measurementVoids;
};

const addVoidsToMeasurements = (measurements) => {
  const meas = [];
  const measurementsById = getItemsMapById(measurements);
  measurements.forEach((measurement) => {
    if (Array.isArray(measurement?.voids) && measurement.voids.length > 0) {
      let lengthNet = measurement.length;
      let areaNet = measurement.area;
      let volumeNet = measurement.volume;
      const newMeas = {...measurement, voidsCount: measurement.voids.length};
      const voids = makeMeasurementVoids(measurement, measurementsById);
      for (const v of voids) {
        lengthNet -= v.length;
        areaNet -= v.area;
        volumeNet -= v.volume;
      }
      meas.push({
        ...newMeas,
        lengthNet: Math.round(lengthNet * 10000) / 10000,
        areaNet: Math.round(areaNet * 10000) / 10000,
        volumeNet: Math.round(volumeNet * 10000) / 10000,
      });
    } else {
      meas.push(measurement);
    }
  });
  return meas;
};

export default function getMeasurementsFromPackages(packages) {
  const meas = [];
  packages.forEach((model) => {
    const measurements = model?.measurementsData?.measurements ?? [];
    measurements.forEach((m) => {
      if (m) {
        meas.push({...m, measurementsModelId: model.id});
      }
    });
  });
  return addVoidsToMeasurements(meas);
}
