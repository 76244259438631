import React from "react";

import {Box, Paper} from "@mui/material";
import theme from "Styles/theme";

export default function SelectorColorFast({color, onChange}) {
  // helpers

  const colors = [
    theme.palette.stabilo.green,
    theme.palette.stabilo.blue,
    theme.palette.stabilo.purple,
    theme.palette.stabilo.pink,
    theme.palette.stabilo.yellow,
  ];
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      {colors.map((c) => {
        const selected = c === color;
        return (
          <Paper
            key={c}
            onClick={() => {
              onChange(c);
            }}
            elevation={selected ? 12 : 0}
            sx={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              bgcolor: c,
              cursor: "pointer",
            }}
          />
        );
      })}
    </Box>
  );
}
