import {Box, Typography, TextField} from "@mui/material";

export default function FormPdfModel({pdfModel, onChange}) {
  // string

  const nameS = "Nom d'usage";
  const messageName = "Donnez un nom d'usage à ce fichier.";

  // helper
  const name = pdfModel?.name ? pdfModel.name : "";

  // handlers

  function handleNameChange(e) {
    const name = e.target.value;
    const newPdfModel = {...pdfModel, name};
    onChange(newPdfModel);
  }

  return (
    <Box sx={{width: 1, p: 1}}>
       <Box sx={{p: 1, mt: 2}}>
        <Typography variant="body2" sx={{mb: 2}}>
          {messageName}
        </Typography>
        <TextField
          fullWidth
          label={<Typography variant="body2">{nameS}</Typography>}
          InputProps={{
            sx: {fontSize: (theme) => theme.typography.body2.fontSize},
          }}
          size="small"
          value={name}
          onChange={handleNameChange}
        />
      </Box>
    </Box>
  );
}
