import {Box} from "@mui/material";

import ToolQuickFilterByType from "Features/viewer3D/components/ToolQuickFilterByType";

export default function ShortcutTypes({editor3d, scene}) {
  return (
    <Box>
      <ToolQuickFilterByType editor3d={editor3d} scene={scene} />
    </Box>
  );
}
