/*
 * used to get list of annotations from existing pdf
 */

import React, {useState} from "react";

import {useSelector} from "react-redux";

import {Box, Typography, Grid} from "@mui/material";

import {rawTypeToType} from "../utils/rawTypeToType";

import FormElementType from "Features/elementTypes/components/FormElementType";

import IconElementType from "Features/elementTypes/components/IconElementType";

export default function ListRawSubjectElementTypes({
  items,
  scene,
  onSubjectElementTypeChange,
}) {
  // data

  const tempElementType = useSelector((s) => s.measurements.tempElementType);

  // handlers

  function handleElementTypeChange(elementType, subject) {
    onSubjectElementTypeChange({subject, elementType});
  }

  return (
    <Box sx={{width: 1}}>
      <Grid
        container
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          pb: 0.5,
        }}
      >
        <Grid sx={{display: "flex", alignItems: "center"}} item xs={3}>
          <Typography>"-"</Typography>
        </Grid>

        <Grid item xs={9} sx={{pl: 1}}>
          <FormElementType
            horizontal={true}
            horizontalHeader={true}
            scene={scene}
            elementType={tempElementType}
          />
        </Grid>
      </Grid>
      {items.map((m) => {
        const {subject, color, isVoid, drawingShape, elementType} = m;
        let type = elementType;
        if (!elementType?.num) {
          type = rawTypeToType({
            isVoid,
            drawingShape,
            subject,
            color,
          });
        }

        return (
          <Grid
            key={m.subject}
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              pb: 0.5,
            }}
          >
            <Grid sx={{display: "flex", alignItems: "center"}} item xs={3}>
              <IconElementType
                color={m.color}
                drawingShape={m.drawingShape}
                isVoid={m.isVoid}
              />
              <Box sx={{ml: 1}}>
                <Typography variant="body2">{m.subject}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {`x ${m.count}`}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={9} sx={{pl: 1}}>
              <FormElementType
                horizontal={true}
                elementType={type}
                scene={scene}
                onChange={(elementType) => {
                  handleElementTypeChange(elementType, subject);
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}
