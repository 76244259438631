import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import DialogRenamePackage from "./DialogRenamePackage";
import DialogDuplicatePackage from "./DialogDuplicatePackage";

import {setSelectedPackageId} from "../packagesSlice";
import useSelectedPackage from "../useSelectedPackage";
import usePackagesByScene from "../usePackagesByScene";

import useAccessToken from "Features/auth/useAccessToken";
import {deleteModel, updateModel} from "Features/viewer3D/viewer3DSlice";
import {deleteRemoteModel} from "Features/scenes/scenesSlice";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useUpdateRessourcesFromTypes from "../hooks/useUpdateRessourcesFromTypes";
import useApplyProcedures from "../hooks/useApplyProcedures";

export default function ButtonMoreActionsPackage({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const deleteS = "Supprimer le repérage";
  const renameS = "Renommer le repérage";
  const duplicateS = "Dupliquer le repérage";
  const applyProceduresS = "Appliquer les procédures";
  const deleteProcedureS = "Supprimer les procédures";
  const refreshS = "Rafraîchir le repérage";

  // data

  const selectedPackage = useSelectedPackage();
  const packages = usePackagesByScene(scene);
  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const zones = useZonesByScene(scene);
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);
  const refreshPackages = useUpdateRessourcesFromTypes({
    caplaEditor,
    packages,
    elementTypes,
    ressources,
    zones,
    rooms,
    sectors,
    filterNoVoids,
  });
  const doProcedures = useApplyProcedures({
    caplaEditor,
    packages,
    elementTypes,
    ressources,
    zones,
    rooms,
    sectors,
    filterNoVoids,
  });

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenamePackage, setOpenRenamePackage] = useState(false);
  const [openDialogDuplicate, setOpenDialogDuplicate] = useState(false);

  // helpers

  const procedures = selectedPackage?.measurementsData?.procedures;

  // handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    setOpenDeleteDialog(false);
    setAnchorEl(null);

    await dispatch(deleteModel({model: selectedPackage}));
    dispatch(setSelectedPackageId(null));
    if (selectedPackage?.remoteId)
      await dispatch(
        deleteRemoteModel({
          modelRemoteId: selectedPackage.remoteId,
          modelId: selectedPackage.id,
          accessToken,
        })
      );
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // handlers - rename

  function handleRename() {
    setAnchorEl(null);
    setOpenRenamePackage(true);
  }
  function handleCloseRenamePackage() {
    setAnchorEl(null);
    setOpenRenamePackage(false);
  }

  // handlers - apply procedures

  const handleApplyProcedures = () => {
    doProcedures(procedures);
    setAnchorEl(null);
  };

  // handlers - delete procedures

  const deleteProcedures = async () => {
    const measurementsData = {
      ...selectedPackage.measurementsData,
    };
    delete measurementsData.procedures;
    const updatedModel = {...selectedPackage, measurementsData};
    await dispatch(updateModel({updatedModel, sync: true}));
    setAnchorEl(null);
  };

  // handlers - duplicate

  const handleDuplicate = () => {
    setOpenDialogDuplicate(true);
    setAnchorEl(null);
  };

  // handlers - refresh

  function handleRefresh() {
    refreshPackages([selectedPackage.id]);
    setAnchorEl(null);
  }

  // helpers - tools

  const tools = [
    {
      id: "APPLY_PROCEDURES",
      label: applyProceduresS,
      handler: handleApplyProcedures,
      visible: procedures,
    },
    {
      id: "DELETE_PROCEDURES",
      label: deleteProcedureS,
      handler: deleteProcedures,
      visible: procedures,
    },
    {
      id: "RENAME",
      label: renameS,
      handler: handleRename,
      visible: true,
    },
    {
      id: "REFRESH",
      label: refreshS,
      handler: handleRefresh,
      visible: true,
    },
    {
      id: "DUPLICATE",
      label: duplicateS,
      handler: handleDuplicate,
      visible: true,
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
      visible: true,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools
          .filter((t) => t.visible)
          .map((tool) => {
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
          })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"package"}
      />

      <DialogRenamePackage
        open={openRenamePackage}
        onClose={handleCloseRenamePackage}
        model={selectedPackage}
      />

      {openDialogDuplicate && (
        <DialogDuplicatePackage
          open={openDialogDuplicate}
          onClose={() => setOpenDialogDuplicate(false)}
          model={selectedPackage}
          caplaEditor={caplaEditor}
        />
      )}
    </>
  );
}
