import {useSelector} from "react-redux";
export default function useIsCreatingZone() {
  // data

  const mode = useSelector((s) => s.viewer3D.mode);

  // output

  return mode === "CREATE_PDF_ZONE";
}
