import html2canvas from "html2canvas";

export default class GoogleMapEditor {
  constructor({map, maps}) {
    this.map = map;
    this.maps = maps;
  }

  // map

  setCenter(latLng) {
    this.map.setCenter(latLng);
  }
  setZoom(zoom) {
    this.map.setZoom(zoom);
  }
  goTo({lat, lng}) {
    const point = new this.maps.LatLng(lat, lng);
    this.map.panTo(point);
  }
  getCenter() {
    return this.map.getCenter().toJSON();
  }
  getBounds() {
    const bounds = this.map.getBounds();
    const ne = bounds.getNorthEast().toJSON();
    const sw = bounds.getSouthWest().toJSON();
    return [sw.lng, sw.lat, ne.lng, ne.lat];
  }
  // export

  async getScreenshotCanvas() {
    const div = this.map.getDiv();
    const canvas = await html2canvas(div, {
      backgroundColor: null,
      useCORS: true,
    });
    return canvas;
  }
}
