import useElementTypesBySceneProxy from "./useElementTypesBySceneProxy";
import {useSelector} from "react-redux";

export default function useSelectedElementType(scene) {
  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const selectedElementTypeId = useSelector(
    (s) => s.elementTypes.selectedElementTypeId
  );
  return elementTypes.find((s) => s.id === selectedElementTypeId);
}
