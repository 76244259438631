import {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {addModelIdToMeasDataManager} from "Features/overviewer/overviewerSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";

import {Box, TextField, Paper, Button, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

import useSelectedElementTypeGroupProxysInHome from "../hooks/useSelectedElementTypeGroupProxysInHome";
import useSelectedPdfsInHome from "../hooks/useSelectedPdfsInHome";
import CheckboxOpenAfterCreation from "./CheckboxOpenAfterCreation";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {useNavigate} from "react-router-dom";

export default function CardTempPackage({scene, caplaEditor}) {
  const dispatch = useDispatch();
  const sceneId = scene?.id ?? scene.clientId;
  const isStagingEnv = useIsStagingEnv();
  const navigate = useNavigate();

  // string

  const createS = "Nouveau calque de repérage";
  const helperS = "Sélectionnez une bibliothèque et un plan PDF";

  // data

  const selectedGroups = useSelectedElementTypeGroupProxysInHome(scene);
  const selectedPdfs = useSelectedPdfsInHome();

  // helper

  const pdf = selectedPdfs[0];
  const group = selectedGroups[0];
  const name1 = selectedGroups[0]?.name ?? "Lot ?";
  const name2 = selectedPdfs[0]?.name ?? "Secteur ?";

  // state

  const initName = name1 + " • " + name2;

  const [name, setName] = useState(initName);
  useEffect(() => setName(initName), [initName]);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(true);

  // helper

  const canCreate = Boolean(pdf && group);

  // handlers

  async function handleCreate() {
    if (loading) return;
    setLoading(true);
    const fromModel = {type: "PDF", modelId: pdf.id};
    const input = {
      name,
      fromModel,
      elementTypesGroupIds: [group.id],
      restrictedTypes: selectedGroups.map((g) => g.id),
    };
    const packageModel =
      await caplaEditor?.editor3d?.loader.createMeasurementsModel(input);
    caplaEditor?.measDataManager.createOrUpdateModelInManager(packageModel);

    console.log("create package v2 input:", input, "output", packageModel);
    const packageId = packageModel?.id;
    setLoading(false);

    // to trigger the updateDataManegrWithMeasurement hook.
    dispatch(addModelIdToMeasDataManager(packageId));

    // open the package
    console.log("openXXX", open);
    // if (open) {
    //   dispatch(setSelectedMeasurementsModelId(packageId));
    //   let url = `/bimboxes/${sceneId}/${packageId}/measurements`;
    //   if (isStagingEnv) url = url + "?env=staging";
    //   navigate(url);
    // }
  }

  return (
    <Box sx={{p: 1, display: "flex", width: 1}}>
      <Paper
        elevation={0}
        sx={{p: 1, display: "flex", flexDirection: "column", width: 1}}
      >
        <Typography variant="body2" color="text.secondary" sx={{mb: 1}}>
          {helperS}
        </Typography>
        <TextField
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 1,
            borderRadius: "8px",
            //px: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              bgcolor: "primary.flash",
              color: "common.white",
              width: 1,
            }}
          >
            <Button
              disabled={!canCreate}
              startIcon={<Add />}
              fullWidth
              color="inherit"
              onClick={handleCreate}
            >
              {createS}
            </Button>
          </Box>
        </Box>
        <CheckboxOpenAfterCreation
          checked={open}
          onChange={(checked) => setOpen(checked)}
        />
      </Paper>
    </Box>
  );
}
