import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";

import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  alpha,
  CircularProgress,
  IconButton,
} from "@mui/material";

import {Close} from "@mui/icons-material";

export default function DialogLoadingModel() {
  // strings

  const loadingString = "Loading the model ...";

  // data

  const pdfViewerStatus = useSelector((s) => s.viewer3D.pdfViewerStatus);

  const loadingPdf = pdfViewerStatus === "loading";

  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleCloseClick() {
    setOpen(false);
  }
  // effect

  useEffect(() => {
    setOpen(loadingPdf);
  }, [loadingPdf]);

  return (
    <Box
      sx={{
        display: open ? "flex" : "none",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 100,
        bgcolor: (theme) => alpha(theme.palette.common.caplaBlack, 1),
        justifyContent: "center",
        alignItems: "center",
        color: "common.white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress
          size={100}
          sx={{mb: 10, color: "background.default"}}
        />
        <Typography
          align="center"
          variant="h5"
          sx={{color: "background.default"}}
        >
          {loadingString}
        </Typography>
      </Box>
      <IconButton
        color="inherit"
        size="small"
        sx={{position: "absolute", top: "8px", right: "8px"}}
        onClick={handleCloseClick}
      >
        <Close fontSize="small" />
      </IconButton>
    </Box>
  );
}
