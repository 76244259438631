import {Box, Typography} from "@mui/material";

import SelectionBox from "./SelectionBox";
import theme from "Styles/theme";

export default function ItemGeneric({
  itemId,
  color,
  show,
  num,
  name,
  count,
  qty,
  unit,
  style,
  isTitle,
  description,
  showDescription,
  onShowChange,
  disableQty,
  disableNum,
  hideShowButton,
  showQties,
  isPdfLayout,
}) {
  // helpers

  const bgcolorsMap = {
    title1: theme.palette.common.bgTitle1,
    title2: theme.palette.common.bgTitle2,
  };
  const colorsMap = {
    detail: theme.palette.common.colorDetail,
  };

  const bgcolor = bgcolorsMap[style]
    ? bgcolorsMap[style]
    : theme.palette.common.divider;

  const textColor = colorsMap[style]
    ? colorsMap[style]
    : theme.palette.common.black;

  let truncatedQty = typeof qty === "number" ? qty?.toString() : "-";
  if (truncatedQty && truncatedQty.includes(".")) {
    const fraction = truncatedQty.split(".")[1];
    if (fraction.length > 2)
      truncatedQty = truncatedQty.replace(
        `.${fraction}`,
        `.${fraction.slice(0, 2)}`
      );
  }
  if (unit) truncatedQty = truncatedQty + " " + unit;

  if (!showQties) truncatedQty = count >= 0 ? count + " u" : "-";

  // handlers

  function handleShowModeChange(itemId, showMode) {
    if (onShowChange) onShowChange(itemId, showMode === "ON");
  }

  function handleTextClick() {
    if (!isTitle && onShowChange) onShowChange(itemId, !show);
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: 1,
        "&>*:not(:last-child)": {
          mr: 1,
        },
      }}
    >
      {!hideShowButton && !(isTitle && isPdfLayout) && (
        <SelectionBox
          itemId={itemId}
          color={color}
          showMode={show ? "ON" : "OFF"}
          onShowModeChange={handleShowModeChange}
          hide={isTitle}
        />
      )}
      <Box
        sx={{flexGrow: 1, cursor: isTitle ? "cursor" : "pointer"}}
        onClick={handleTextClick}
      >
        <Box
          sx={{
            px: 0.5,
            bgcolor,
            color: textColor,
            py: 0.25,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{fontSize: 13, fontStyle: show ? "normal" : "italic"}}
            color={show ? textColor : "text.secondary"}
          >
            {disableNum ? name : `${num} • ${name}`}
          </Typography>
          {!disableQty && unit && (
            <Box sx={{display: "flex"}}>
              <Typography
                sx={{fontSize: 13, whiteSpace: "nowrap"}}
                color={show ? "secondary.main" : "text.secondary"}
              >
                {truncatedQty}
              </Typography>
              {/* <Typography sx={{fontSize: 13, ml: 1}}>{unit}</Typography> */}
            </Box>
          )}
        </Box>
        {showDescription && description && (
          <Box
            sx={{
              px: 1,
              display: "flex",
              justifyContent: "flex-end",
              //borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              sx={{fontSize: 12, fontStyle: "italic"}}
              color="text.secondary"
            >
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
