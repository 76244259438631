import getUpdatedMeasurementsFromTypes from "../utils/getUpdatedMeasurementsFromTypes";

import applyProcedures from "Features/procedures/applyProcedures";
import { updateModel } from "Features/viewer3D/viewer3DSlice";
import getItemsMapById from "Utils/getItemsMapById";

export default function useApplyProcedures({
  caplaEditor,
  packages,
  elementTypes,
  ressources,
  zones,
  rooms,
  sectors,
  filterNoVoids,
}) {
  const measurementsByPackageId = {};
  for (const p of packages) {
    const measurements = p.measurementsData?.measurements;
    measurementsByPackageId[p.id] = measurements ?? [];
  }
  const elementTypesById = getItemsMapById(elementTypes);
  const ressourcesById = getItemsMapById(ressources);
  const zonesById = getItemsMapById(zones);
  const roomsById = getItemsMapById(rooms);
  const sectorsById = getItemsMapById(sectors);
  const data = {
    measurementsByPackageId,
    typesById: elementTypesById,
    zonesById,
  };
  const doIt = async (procedures) => {
    const updatedPackages = applyProcedures(packages, procedures, data);
    for (const model of updatedPackages) {
      const ms = model.measurementsData?.measurements?.map((m) => {
        const quantities = caplaEditor.computeQuantitiesFromPaths({
          drawingShape: m.drawingShape,
          path: m.drawingProps?.path,
          path3D: m.path3D,
          path3d2: m.path3d2,
          height: m.height,
          heights: m.heights,
          heightE: m.heightE,
          heightN: m.heightN,
          slopeH: m.slopeH,
          slopingA: m.slopingA,
          dim1: m.dim1,
          dim2: m.dim2,
          mpd: zonesById[m.zoneId]?.scale,
        });
        return {
          ...m,
          measurementsModelId: model.id,
          pdfModelId: model.fromModel.modelId,
          ...quantities,
        };
      });
      const thePackage = {
        ...model,
        measurementsData: {
          ...model.measurementsData,
          measurements: ms,
        },
      };
      const usedTypesGroups = new Set();
      const usedRessourcesGroups = new Set();
      const [newMs, usedTypes] = getUpdatedMeasurementsFromTypes({
        caplaEditor,
        thePackage,
        elementTypesById,
        ressourcesById,
        zonesById,
        roomsById,
        sectorsById,
        filterNoVoids,
      });
      usedTypes.forEach((t) => {
        usedTypesGroups.add(t.groupId);
        t.res?.forEach((r) => {
          const ressource = ressourcesById[r.resId];
          if (ressource) usedRessourcesGroups.add(ressource.group);
        });
      });
      const updatedModel = {
        ...thePackage,
        measCount: newMs.length,
        elementTypesGroupIds: [...usedTypesGroups],
        ressourcesGroupIds: [...usedRessourcesGroups],
        measurementsData: {
          ...thePackage.measurementsData,
          measurements: newMs,
        },
      };
      const entity = caplaEditor?.editor3d.getEntity(updatedModel.id);
      entity?.updateMeasurementsData(updatedModel.measurementsData);
      entity?.loadMeasurements();
      caplaEditor?.dispatch(updateModel({ updatedModel, sync: true }));
      caplaEditor?.measDataManager.createOrUpdateModelInManager(updatedModel);
      caplaEditor?.measDataManager.setShortMemory();
    }
  };
  return doIt;
}
