export default class Loader {
  constructor({pdfEditor}) {
    this.pdfEditor = pdfEditor;
  }

  getElementTypes() {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "ELEMENT_TYPES");

    const typesS = annot.getCustomData("elementTypes");

    const types = JSON.parse(typesS);

    return types;
  }

  getRessources() {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "RESSOURCES");

    const ressourcesS = annot.getCustomData("ressources");

    const ressources = JSON.parse(ressourcesS);

    return ressources;
  }

  getPdfScale() {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    let annot = annotationManager
      .getAnnotationsList()
      .find((a) => a.getCustomData("type") === "PDF_SCALE");

    const pdfScaleS = annot.getCustomData("pdfScale");

    const pdfScale = parseFloat(JSON.parse(pdfScaleS));

    return pdfScale;
  }

  getBimData() {
    const {annotationManager, Annotations} = this.pdfEditor.webViewer.Core;

    const annots = annotationManager.getAnnotationsList();

    let pdfScaleA = annots.find((a) => a.getCustomData("type") === "PDF_SCALE");
    const pdfScaleS = pdfScaleA?.getCustomData("pdfScale");
    const pdfScale = pdfScaleS && parseFloat(JSON.parse(pdfScaleS));

    let phasesA = annots.find((a) => a.getCustomData("type") === "PHASES");
    const phasesS = phasesA?.getCustomData("phases");
    const phases = phasesS && JSON.parse(phasesS);

    let sectorsA = annots.find((a) => a.getCustomData("type") === "SECTORS");
    const sectorsS = sectorsA?.getCustomData("sectors");
    const sectors = sectorsS && JSON.parse(sectorsS);

    let roomsA = annots.find((a) => a.getCustomData("type") === "ROOMS");
    const roomsS = roomsA?.getCustomData("rooms");
    const rooms = roomsS && JSON.parse(roomsS);

    let zonesA = annots.find((a) => a.getCustomData("type") === "ZONES");
    const zonesS = zonesA?.getCustomData("zones");
    const zones = zonesS && JSON.parse(zonesS);

    let typesA = annots.find(
      (a) => a.getCustomData("type") === "ELEMENT_TYPES"
    );
    const typesS = typesA?.getCustomData("elementTypes");
    const elementTypes = typesS && JSON.parse(typesS);

    let ressourcesA = annots.find(
      (a) => a.getCustomData("type") === "RESSOURCES"
    );
    const ressourcesS = ressourcesA?.getCustomData("ressources");
    const ressources = ressourcesS && JSON.parse(ressourcesS);

    let packagesA = annots.find((a) => a.getCustomData("type") === "PACKAGES");
    const packagesS = packagesA?.getCustomData("packages");
    const packages = packagesS && JSON.parse(packagesS);

    let measurementsA = annots.find(
      (a) => a.getCustomData("type") === "MEASUREMENTS"
    );
    const measurementsS = measurementsA?.getCustomData("measurements");
    const measurements = measurementsS && JSON.parse(measurementsS);

    return {
      phases,
      sectors,
      rooms,
      zones,
      elementTypes,
      ressources,
      packages,
      measurements,
      pdfScale,
    };
  }
}
