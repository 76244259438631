import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {fetchRemoteSceneLight} from "Features/scenes/scenesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {useParams} from "react-router-dom";
import useIsStagingEnv from "./useIsStagingEnv";

export default function useInitFetchRemoteSceneLight() {
  const isStagingEnv = useIsStagingEnv();

  const dispatch = useDispatch();
  // data

  const {sceneId} = useParams();
  const accessToken = useAccessToken();
  const currentSceneIsRemote = useSelector(
    (s) => s.overviewer.currentSceneIsRemote
  );
  const sceneIdOfSceneOverview = useSelector(
    (s) => s.overviewer.sceneIdOfSceneOverview
  );

  // helper

  const canFetch =
    accessToken && currentSceneIsRemote && sceneId === sceneIdOfSceneOverview;

  useEffect(() => {
    if (canFetch && isStagingEnv) {
      dispatch(fetchRemoteSceneLight({sceneId, accessToken}));
    }
  }, [canFetch]);
}
