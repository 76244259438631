import theme from "Styles/theme.js";

const modelLoadingStatusMap = {
  UNDEFINED: {
    num: 0,
  },
  LIGHT: {
    num: 1,
    color: theme.palette.common.darkGrey,
    label: "aperçu",
    description: `le modèle a été téléchargé depuis fetchSceneLight, 
        qui contient la liste des modèles, leur nom et les quantités d'éléments associés.
        accessible depuis viewer3D.models, sans les measurements.`,
  },
  IN_STATE: {
    num: 2,
    color: theme.palette.primary.main,
    label: "données accessibles",
    description: `le modèle et toutes ses données ont été téléchargés.
        endpoint clé : fetchModel
        acessible depuis viewer3D.models, avec les measurements.`,
  },
  IN_3D_OVERVIEW: {
    num: 3,
    color: theme.palette.primary.flash,
    label: "présent en 3D, mais pas détaillé",
    description: `le modèle a été instancié dans la scene three.js
        un entityModel est associé`,
  },
  IN_3D_DETAILED: {
    num: 4,
    color: theme.palette.common.yellow,
    label: "présent en 3D, détaillé",
    description: `le modèle a été instancié en détail dans la vue 3D
        on a par exemple créé les MEasurementsElement
        ou ajouté la texture pour les images`,
  },
};

export default modelLoadingStatusMap;
