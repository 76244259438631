import {Dialog, IconButton, Box} from "@mui/material";
import {Close} from "@mui/icons-material";

import SectionEditSectors from "./SectionEditSectors";

export default function DialogFsEditSectors({open, onClose, scene}) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexGrow: 1,
          bgcolor: "common.caplaBlack",
          position: "relative",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "grey.400",
          }}
        >
          <Close />
        </IconButton>
        <SectionEditSectors scene={scene} onSaved={onClose} />
      </Box>
    </Dialog>
  );
}
