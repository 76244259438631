import {Box, Typography, ListItemButton} from "@mui/material";

import IconButtonUpload from "./IconButtonUpload";

import ListIconModelType from "Features/viewer3D/components/ListIconModelType";
import ButtonUpdateSceneClientFromRemote from "Features/scenes/components/ButtonUpdateSceneClientFromRemote";

export default function ListItemClientAndRemoteSceneModel({
  clientAndRemoteSceneModel,
}) {
  let isModel = true,
    isScene = false,
    clientName = "-?-",
    clientVersion = 1,
    remoteName = "-?-",
    remoteVersion = 1,
    actionType = "-?-",
    id = "-?-",
    sceneId = "-?-",
    type = "-?-";

  try {
    isModel = clientAndRemoteSceneModel?.isModel;
    isScene = clientAndRemoteSceneModel?.isScene;
    clientName = clientAndRemoteSceneModel?.clientName;
    clientVersion = clientAndRemoteSceneModel?.clientVersion;
    remoteName = clientAndRemoteSceneModel?.remoteName;
    remoteVersion = clientAndRemoteSceneModel?.remoteVersion;
    actionType = clientAndRemoteSceneModel?.actionType;
    id = clientAndRemoteSceneModel?.id;
    sceneId = clientAndRemoteSceneModel?.sceneId;
    type = clientAndRemoteSceneModel?.type;
  } catch (e) {
    console.log("ERROR", e);
  }

  const clientV = clientVersion ? `v${clientVersion}` : "-";
  const remoteV = remoteVersion ? `v${remoteVersion}` : "";

  // helpers

  const needSceneUpdate = isScene && clientVersion < remoteVersion;

  // handlers

  function handleClick() {
    console.log("[clientAndRemoteSceneModel]", clientAndRemoteSceneModel);
  }
  return (
    <ListItemButton
      sx={{bgcolor: isScene ? "background.default" : "common.white"}}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          width: 1,
          alignItems: "center",
        }}
      >
        {isModel && <ListIconModelType type={type} />}
        {isScene && <Box sx={{width: 24}} />}
        <Typography variant="body2" noWrap sx={{width: "35%", ml: 2}}>
          {clientName}
        </Typography>
        <Typography variant="body2" noWrap sx={{width: "7.5%"}}>
          {clientV}
        </Typography>
        <Box
          sx={{
            width: "5%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {!needSceneUpdate && actionType && <IconButtonUpload modelId={id} />}
          {needSceneUpdate && (
            <ButtonUpdateSceneClientFromRemote sceneId={sceneId} />
          )}
          {(!needSceneUpdate || !actionType) && <Box />}
        </Box>
        <Box sx={{width: "10%"}} />
        <Typography variant="body2" noWrap sx={{width: "35%"}}>
          {remoteName}
        </Typography>
        <Typography variant="body2" noWrap sx={{width: "7.5%"}}>
          {remoteV}
        </Typography>
      </Box>
    </ListItemButton>
  );
}
