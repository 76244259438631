import {useSelector, useDispatch} from "react-redux";

import {Box, Typography} from "@mui/material";

import FormProgressListingPackages from "./FormProgressListingPackages";
import CardProgressListingUrl from "./CardProgressListingUrl";
import SectionProgressListingMobileModules from "./SectionProgressListingMobileModules";

import useSelectedProgressListing from "../useSelectedProgressListing";
import {setEditedProgressListing} from "../progressSlice";

export default function SectionProgressListingInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // string

  const packagesS = "Repérages";

  // data

  const selectedProgressListing = useSelectedProgressListing(scene);
  const isEditing = useSelector((s) => s.progress.isEditingProgressListing);
  const editedProgressListing = useSelector(
    (s) => s.progress.editedProgressListing
  );

  // helpers

  const progressListing = isEditing
    ? editedProgressListing
    : selectedProgressListing;

  // handlers

  function handleProgressListingPackagesChange(packageIds) {
    let newProgressListing;
    if (progressListing.metaData) {
      newProgressListing = {
        ...progressListing,
        metaData: {...progressListing.metaData, packageIds},
      };
    } else {
      newProgressListing = {...progressListing, metaData: {packageIds}};
    }
    if (isEditing) {
      dispatch(setEditedProgressListing(newProgressListing));
    }
  }
  return (
    <Box sx={{width: 1}}>
      {!isEditing && (
        <CardProgressListingUrl
          scene={scene}
          progressListing={progressListing}
        />
      )}
      {progressListing && (
        <Box sx={{p: 1, width: 1}}>
          <Typography sx={{mb: 1, fontWeight: "bold"}} variant="body2">
            {packagesS}
          </Typography>
          <FormProgressListingPackages
            scene={scene}
            listing={progressListing}
            onChange={handleProgressListingPackagesChange}
            isEditing={isEditing}
          />
        </Box>
      )}
      <Box sx={{mt: 3}}>
        <SectionProgressListingMobileModules
          listing={selectedProgressListing}
          scene={scene}
        />
      </Box>
    </Box>
  );
}
