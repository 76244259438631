import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {deleteProgressRecord} from "Features/progress/progressSlice";
import useAccessToken from "Features/auth/useAccessToken";

import {Button} from "@mui/material";
import {Delete} from "@mui/icons-material";

export default function ButtonDeleteProgressRecord({progressRecord}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // strings

  const deleteS = "Delete";

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  async function handleDelete() {
    if (loading) return;

    setLoading(true);
    await dispatch(
      deleteProgressRecord({accessToken, progressRecordId: progressRecord?.id})
    );

    setLoading(false);
  }

  return (
    <Button
      startIcon={<Delete />}
      size="small"
      onClick={handleDelete}
      color="inherit"
    >
      {deleteS}
    </Button>
  );
}
