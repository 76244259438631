import {Box} from "@mui/material";
import theme from "Styles/theme";

import ChipVersion from "./ChipVersion";

export default function ContainerSelectedVersionsOverview({
  versions,
  selectedVersionIds,
  disabled,
}) {
  const selection = selectedVersionIds
    ? selectedVersionIds
    : [...versions.map((v) => v.id)];
  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      {versions.map((ver) => {
        const version = {
          ...ver,
          color: disabled ? theme.palette.divider : ver.color,
        };
        return (
          <Box sx={{mr: 0.4}}>
            <ChipVersion
              key={version.id}
              variant="overview"
              {...version}
              selected={selection.includes(version.id)}
            />
          </Box>
        );
      })}
    </Box>
  );
}
