import fillWorksheetWithRessourcesByVersion from "./fillWorksheetWithRessourcesByVersion";

export default function addRessourcesByVersionsWorksheet(
    workbook,
    worksheetTemplate,
    name,
    ressourcesByVersion,
    versions
  ) {
    const worksheet = workbook.addWorksheet(name.slice(0, 31));
    if (worksheetTemplate?.model) worksheet.model = worksheetTemplate.model;
    worksheet.name = name.slice(0, 31);

    worksheet.getRow(1).getCell(1).value = name ? name : "-?-";

    fillWorksheetWithRessourcesByVersion({
      worksheet,
      ressourcesByVersion,
      rowStartIndex: 3,
      versions
    });

    worksheet.mergeCells(1, 0, 1, 4);

    return worksheet;
}