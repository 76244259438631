import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {setModelsFilters} from "Features/viewer3D/viewer3DSlice";

import {Box, Chip} from "@mui/material";
export default function KeywordsFilterToolbar({keywords}) {
  const dispatch = useDispatch();

  // strings

  const allString = "all";

  // data

  const modelsFilters = useSelector((state) => state.viewer3D.modelsFilters);
  const keywordsFilters = modelsFilters.keywords;

  // helpers

  const all = keywordsFilters.length === 0;

  const orderedKeywords = keywords
    ? keywords.sort((a, b) => a.localeCompare(b))
    : [];

  // handlers

  function handleChipClick(keyword) {
    dispatch(setModelsFilters({keywords: [keyword]}));
  }

  function handleAllClick() {
    dispatch(setModelsFilters({keywords: []}));
  }

  return keywords.length <= 1 ? (
    <Box />
  ) : (
    <Box
      sx={{
        color: "common.grey",
        display: "flex",
        flexWrap: "wrap",
        "&>*:not(:last-child)": {mr: 0.5, mb: 0.5},
      }}
    >
      <Chip
        label={allString}
        size="small"
        color={all ? "primary" : "default"}
        variant="outlined"
        onClick={handleAllClick}
        sx={{fontSize: 12, height: 20}}
      />
      {orderedKeywords.map((keyword) => {
        const selected = keywordsFilters.includes(keyword);
        return (
          <Chip
            key={keyword}
            label={keyword}
            size="small"
            color={selected || all ? "primary" : "default"}
            onClick={() => handleChipClick(keyword)}
            sx={{fontSize: 12, height: 20}}
          />
        );
      })}
    </Box>
  );
}
