import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import ButtonCreateMarker from "./ButtonCreateMarker";

import {setSelectedMarkersModelId} from "../markersSlice";
import useMarkersModels from "../hooks/useMarkersModels";

import SelectorModelInToolbar from "Features/viewer3D/components/SelectorModelInToolbar";

export default function ToolbarAddMarker({editor3d, scene}) {
  const dispatch = useDispatch();
  // data

  const markersModels = useMarkersModels(scene?.clientId);
  const selectedMarkersModelId = useSelector(
    (s) => s.markers.selectedMarkersModelId
  );
  const selectedMarkersModel = markersModels.find(
    (m) => m.id === selectedMarkersModelId
  );

  // handlers

  function handleModelChange(model) {
    dispatch(setSelectedMarkersModelId(model?.id));
  }

  // effect

  useEffect(() => {
    if (!selectedMarkersModelId && markersModels.length > 0) {
      dispatch(setSelectedMarkersModelId(markersModels[0].id));
    }
  }, [selectedMarkersModelId, markersModels?.length]);

  return (
    <Box sx={{alignItems: "center", pl: 0.5, display: "flex"}}>
      <SelectorModelInToolbar
        models={markersModels}
        model={selectedMarkersModel}
        onChange={handleModelChange}
      />
      <ButtonCreateMarker editor3d={editor3d} />
    </Box>
  );
}
