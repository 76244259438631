import {Box} from "@mui/material";

import useSelection from "../useSelection";

import usePageType from "Features/navigation/usePageType";
import ButtonBackScenesInSelectionPanel from "Features/scenes/components/ButtonBackScenesInSelectionPanel";
import ButtonBackMeasurementInSelectionPanel from "Features/measurements/components/ButtonBackMeasurementInSelectionPanel";
import ButtonBackMarkerInSelectionPanel from "Features/markers/components/ButtonBackMarkerInSelectionPanel";
import ButtonBackIssuesInSelectionPanel from "Features/issues/components/ButtonBackIssuesInSelectionPanel";
import ButtonBackZonesInSelectionPanel from "Features/zones/components/ButtonBackZonesInSelectionPanel";
import ButtonBackElementTypesInSelectionPanel from "Features/elementTypes/components/ButtonBackElementTypesInSelectionPanel";
import ButtonBackPdfInSelectionPanel from "Features/pdf/components/ButtonBackPdfInSelectionPanel";
import ButtonBackRessourcesInSelectionPanel from "Features/ressources/components/ButtonBackRessourcesInSelectionPanel";
import ButtonBackRoomsInSelectionPanel from "Features/rooms/components/ButtonBackRoomsInSelectionPanel";
import ButtonBackSectorsInSelectionPanel from "Features/sectors/components/ButtonBackSectorsInSelectionPanel";
// import ButtonBackBimappsInSelectionPanel from "Features/bimapps/components/ButtonBackBimappsInSelectionPanel";
import ButtonBackSharedboxInSelectionPanel from "Features/sharedboxes/components/ButtonBackSharedboxInSelectionPanel";
import ButtonBackPackagesInSelectionPanel from "Features/elementPackages/components/ButtonBackPackagesInSelectionPanel";
import ButtonBackMaterialsInSelectionPanel from "Features/materials/components/ButtonBackMaterialsInSelectionPanel";

export default function HeaderSelectionBackButton({caplaEditor, scene}) {
  // data

  const selection = useSelection({caplaEditor, scene});
  const pageType = usePageType();

  return (
    <Box>
      {selection.type === "SCENE" && pageType === "HOME" && (
        <ButtonBackScenesInSelectionPanel />
      )}

      {selection.type === "SECTOR" && <ButtonBackSectorsInSelectionPanel />}
      {selection.type === "ROOM" && <ButtonBackRoomsInSelectionPanel />}
      {selection.type === "MARKER" && (
        <ButtonBackMarkerInSelectionPanel editor3d={caplaEditor?.editor3d} />
      )}
      {selection.type === "MATERIAL" && <ButtonBackMaterialsInSelectionPanel />}
      {selection.type === "ELEMENT_TYPE" && (
        <ButtonBackElementTypesInSelectionPanel />
      )}
      {(selection.type === "RESSOURCE" ||
        selection.type === "HOME_RESSOURCE") && (
        <ButtonBackRessourcesInSelectionPanel />
      )}
      {selection.type === "PDF_MODEL" && <ButtonBackPdfInSelectionPanel />}
      {selection.type === "ZONE" && <ButtonBackZonesInSelectionPanel editorPdf={caplaEditor?.editorPdf} />}
      {selection.type === "PACKAGE" && <ButtonBackPackagesInSelectionPanel />}

      {selection.type === "MEASUREMENT" && (
        <ButtonBackMeasurementInSelectionPanel editor3d={caplaEditor?.editor3d} />
      )}

      {selection.type === "ISSUE" && <ButtonBackIssuesInSelectionPanel />}
      {selection.type === "SHAREDBOX" && <ButtonBackSharedboxInSelectionPanel />}
      {/* {selection.type === "BIMAPP" && <ButtonBackBimappsInSelectionPanel />} */}
    </Box>
  );
}
