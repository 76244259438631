import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FieldZoneZBasis({zone, onZoneChange}) {
  // strings

  const zBasisS = "Basis";
  const zInfS = "z Inf";
  const zSupS = "z Sup";

  // template

  const template = {
    key: "kvtZoneZBasis",
    fields: [
      {
        key: "zBasis",
        name: zBasisS,
        type: "selector",
        options: [
          {key: "ZINF", name: zInfS},
          {key: "ZSUP", name: zSupS},
        ],
      },
    ],
  };

  // item

  const item = {zBasis: zone.zBasis ? zone.zBasis : "ZINF"};

  // handlers

  function handleZBasisChange({zBasis}) {
    // const annotationId = zone.id;
    // const props = {zBasis};
    const newZone = {...zone, zBasis};
    if (onZoneChange) onZoneChange(newZone);
    // editorPdf.annotationsManager.updateAnnotationEntityProps({
    //   annotationId,
    //   props,
    // });
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleZBasisChange}
    />
  );
}
