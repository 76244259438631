import {useDispatch} from "react-redux";
import {createArticlesBatch} from "../articlesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useCreateArticlesBatch() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const create = async (articlesBatch) => {
    await dispatch(createArticlesBatch({articles: articlesBatch, accessToken}));
  };

  return create;
}
