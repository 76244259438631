export default function updateMeasurementFromType(measurement, type) {
  if (type) {
    const updates = {};
    const reserved = ["id", "num"];
    const measurementKeys = new Set(Object.keys(measurement));
    const keys = Object.keys(type);
    keys.forEach((key) => {
      if (!reserved.includes(key)) {
        const value = type[key];
        if (measurementKeys.has(key) && value !== undefined && value !== null) {
          updates[key] = value;
        }
      }
    });
    if (updates.res) {
      updates.res = updates.res.map(({resId}) => ({resId, off: false}));
    }
    return {...measurement, ...updates};
  }
  else return measurement;
}
