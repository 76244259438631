import {useSelector, useDispatch} from "react-redux";

import {Box, FormControlLabel, Switch, Typography} from "@mui/material";

import {setShowElementTypesReorderHelpers} from "../elementTypesSlice";

export default function SwitchShowElementTypesReorderHelpers() {
  const dispatch = useDispatch();

  // strings

  const showS = "Changer l'ordre";

  // data

  const show = useSelector(
    (s) => s.elementTypes.showReorderElementTypesHelpers
  );

  // handlers

  function handleSwitchChange(e, checked) {
    dispatch(setShowElementTypesReorderHelpers(checked));
  }
  return (
    <Box sx={{pl: 2, color: "white"}}>
      <FormControlLabel
        color="inherit"
        control={
          <Switch checked={show} onChange={handleSwitchChange} size="small" />
        }
        label={<Typography variant="body2">{showS}</Typography>}
      />
    </Box>
  );
}
