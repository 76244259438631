import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  List,
  ListItemButton,
  IconButton,
  ListItem,
  Typography,
} from "@mui/material";
import {
  CenterFocusStrong as Focus,
  ExpandMore as More,
  ExpandLess as Less,
} from "@mui/icons-material";

import useSceneMeasurements from "../useSceneMeasurements";
import getMeasurementVoidCandidates from "../utils/getMeasurementVoidCandidates";

import {setExpandOpeningsInSelectionPopover} from "Features/ui/uiSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function SectionAddVoidsInViewer({
  scene,
  item,
  caplaEditor,
  onAdd,
  onRemove,
}) {
  const dispatch = useDispatch();

  // strings

  const voidsS = "A proximité";

  // data

  const openOpenings = useSelector(
    (s) => s.ui.expandOpeningsInSelectionPopover
  );
  const measurements = useSceneMeasurements(scene);
  const types = useElementTypesBySceneProxy(scene);
  const preCandidates = measurements.filter((m) =>
    m.measurementsModelId === item?.measurementsModelId &&
    m.id !== item?.id &&
    m.drawingProps.pageNumber ===
    item?.drawingProps?.pageNumber
  )

  const candidates = item
    ? getMeasurementVoidCandidates(item, preCandidates, 0.2)
    : [];

  // helpers

  const mnger = caplaEditor?.editorPdf?.annotationsManager?.measurementsPdfManager;

  // handlers

  function handleFocus(voidId) {
    mnger.highlightAnnotation(voidId);
  }

  function handleToggleVoid(voidId) {
    let voids = item.voids ? [...item.voids] : [];
    const existingVoid = voids.includes(voidId);
    if (existingVoid) {
      onRemove(voidId);
    } else {
      onAdd(voidId);
    }
  }

  function toggleOpenOpenings() {
    dispatch(setExpandOpeningsInSelectionPopover(!openOpenings));
  }

  // renderer

  if (candidates.length > 0) {
    return (
      <Box sx={{display:"flex", flexDirection: "column", width: 1, height: 1}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography sx={{fontSize: 13, p: 1}} >
            {`${voidsS} (${candidates.length})`}
          </Typography>
          <IconButton size="small" onClick={toggleOpenOpenings}>
            {openOpenings ? (
              <Less fontSize="small" />
            ) : (
              <More fontSize="small" />
            )}
          </IconButton>
        </Box>
        {openOpenings && (
          <Box sx={{width: 1, height: 1}}>
            <List
              dense
              disablePadding
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                height: "100%",
                maxHeight: 300,
                overflowY: "auto",
              }}
            >
              {candidates.map((measurement) => {
                if (!measurement) console.log("ERROR VOIDS no measurement in candidates");
                const t = types.find((t) => t.id === measurement?.elementTypeId);
                const isVoid = item.voids?.includes(
                  measurement.id
                );
                return (
                  <ListItem
                    key={measurement?.id}
                    sx={{py: 0.1, px: 0}}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => handleFocus(measurement.id)}
                      >
                        <Focus fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      onClick={() => handleToggleVoid(measurement.id)}
                    >
                      <Typography
                        variant="body2"
                        sx={
                          isVoid
                            ? {fontWeight: "bold"}
                            : {color: "text.secondary"}
                        }
                      >
                        {`${t?.name} • ${measurement.codeName}`}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </Box>
    );
  } else {
    return <></>;
  }
}
