import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {fetchProfile} from "./profileSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useProfile() {
  const dispatch = useDispatch();
  const profileInStore = useSelector((state) => state.profile);

  const accessToken = useAccessToken();

  useEffect(() => {
    if (profileInStore.status === "idle" && accessToken) {
      dispatch(fetchProfile({accessToken}));
    }
  }, [profileInStore.status, accessToken]);

  return profileInStore;
}
