export default function useDrawingShapeNameMap() {
  // strings

  const rectangleS = "Rectangle";
  const circleS = "Cercle";
  const segmentS = "Segment";
  const beamS = "Poutre";
  const polylineS = "Polyligne";
  const slopeS = "Pente";
  const stairsS = "Escaliers";
  const gableS = "Mur pignon";
  const polygonS = "Polygone";
  const slopingPolygonS = "Rampant";
  return {
    RECTANGLE: rectangleS,
    CIRCLE: circleS,
    SEGMENT: segmentS,
    BEAM: beamS,
    POLYLINE: polylineS,
    SLOPE: slopeS,
    STAIRS: stairsS,
    GABLE: gableS,
    POLYGON: polygonS,
    SLOPING_POLYGON: slopingPolygonS,
  };
}
