import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";
import PanelPackagesHeader from "./PanelPackagesHeader";
import usePackagesByScene from "../usePackagesByScene";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import ListPackagesWithVisibility from "./ListPackagesWithVisibility";
import {setLastPackageId, setOpenPackagesPanel} from "../packagesSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

export default function PanelPackages({scene}) {
  const dispatch = useDispatch();
  const isStagingEnv = useIsStagingEnv();
  const navigate = useNavigate();
  const sceneId = scene?.id ?? scene?.clientId;

  // data
  const os = useSelector((s) => s.ui.openSections);
  const pdfOnly = os.fixedViewersBox && !os.viewer3D;
  const sceneModule = useSceneModule();

  const packages = usePackagesByScene(scene, {
    // filterByPdf: ["INPUTS"].includes(sceneModule) || pdfOnly,
    filterByPdf: ["INPUTS"].includes(sceneModule) || pdfOnly,
    filterByScope: true,
    sortByName: true,
  });
  const selectedId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );

  // handlers

  function handleClick(modelId) {
    dispatch(setSelectedMeasurementsModelId(modelId));
    dispatch(setLastPackageId(modelId));
    dispatch(setOpenPackagesPanel(false));
    let url = `/bimboxes/${sceneId}/${modelId}/measurements`;
    if (isStagingEnv) url = url + "?env=staging";
    navigate(url);
  }

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        bgcolor: "common.white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PanelPackagesHeader />
      <Box sx={{flexGrow: 1, overflow: "auto"}}>
        <ListPackagesWithVisibility
          selection={selectedId}
          onSelectionChange={handleClick}
          packages={packages}
        />
      </Box>
    </Box>
  );
}
