import {useSelector, useDispatch} from "react-redux";

import {setSelectedSurveyId} from "../surveysSlice";

import useSurveysByScene from "../hooks/useSurveysByScene";

import {Box} from "@mui/material";

import ListSurveys from "./ListSurveys";

export default function SectionSurveysInLeftPanel({scene}) {
  const dispatch = useDispatch();
  //
  const surveys = useSurveysByScene(scene, {
    sortByScheduledAt: true,
    filterByScope: true,
  });
  console.log("debug 6-6 surveys", surveys);
  //
  const selection = useSelector((s) => s.surveys.selectedSurveyId);

  // handlers

  function handleSelectionChange(id) {
    dispatch(setSelectedSurveyId(id));
  }
  return (
    <Box>
      <ListSurveys
        surveys={surveys}
        selection={selection}
        onSelectionChange={handleSelectionChange}
      />
    </Box>
  );
}
