import {Box, Typography, Icon, Tooltip} from "@mui/material";
import {
  Layers as Packages,
  PictureAsPdf as Inputs,
  Image as Zones,
  ViewInAr as Measurements,
} from "@mui/icons-material";
import {lighten} from "@mui/material/styles";
import theme from "Styles/theme";

export default function ContainerEntityCount({count, color, type, size}) {
  const label = `x${count}`;

  const titlePrefixMap = {
    PACKAGES: "Nombre de calques",
    INPUTS: "Nombre de PDF",
    ZONES: "Nombre de fonds de plans",
    MEASUREMENTS: "Nombre d'éléments",
  };
  const title = `${titlePrefixMap[type]} : x${count}`;

  const isSmall = size === "SMALL";

  const iconMap = {
    PACKAGES: <Packages sx={{fontSize: isSmall ? 12 : 14}} />,
    INPUTS: <Inputs sx={{fontSize: isSmall ? 12 : 14}} />,
    ZONES: <Zones sx={{fontSize: isSmall ? 12 : 14}} />,
    MEASUREMENTS: <Measurements sx={{fontSize: isSmall ? 12 : 14}} />,
  };

  let bgcolor = lighten(theme.palette.common.caplaBlack, 0.2);

  return (
    <Tooltip title={title} placement="top">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "common.white",
          bgcolor,
          px: 1,
          minWidth: "content",
          height: 24,
          borderRadius: 12,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {iconMap[type]}
        </Box>
        <Typography sx={{fontSize: isSmall ? 10 : 12, ml: 1}} noWrap>
          {label}
        </Typography>
      </Box>
    </Tooltip>
  );
}
