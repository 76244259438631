import {useState, useEffect} from "react";
import {useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

export default function CardIfcSite({editor3d, model}) {
  // strings

  const title = "Site coordinates";

  // data

  const detailedModels = useSelector((s) => s.viewer3D.detailedModels);
  const isDetailed = detailedModels.includes(model?.id);

  // state
  const [site, setSite] = useState({
    x: "...",
    y: "...",
    z: "...",
    theta: "...",
  });

  // helpers

  async function getSite() {
    const entity = editor3d?.getEntity(model.id);
    const s = await entity?.siteCoordinates();

    if (typeof s?.x === "number" && typeof s?.theta === "number") {
      setSite({
        x: s.x.toFixed(3),
        y: s.y.toFixed(3),
        z: s.z.toFixed(3),
        theta: s.theta.toFixed(1),
      });
    }

    // let pset = await editor3d.ifcLoader.ifcManager.ifcAPI.GetLine(
    //   s.ifcModelID,
    //   s.locationExpressID
    // );

    // console.log("pset", pset);

    // editor3d.ifcLoader.ifcManager.ifcAPI.WriteLine(s.ifcModelID, pset);
    // console.log("GOOD");
  }

  // effects

  useEffect(() => {
    getSite();
  }, [model?.id, isDetailed]);

  return (
    <Box sx={{p: 2, width: 1}}>
      <Typography gutterBottom variant="body2">
        <b>{title}</b>
      </Typography>
      <Typography variant="body2">{`x: ${site.x} m`}</Typography>
      <Typography variant="body2">{`y: ${site.y} m`}</Typography>
      <Typography variant="body2">{`z: ${site.z} m`}</Typography>
      <Typography variant="body2">{`theta: ${site.theta}°`}</Typography>
    </Box>
  );
}
