import {Box} from "@mui/material";

import useSelection from "Features/selection/useSelection";
import FormMeasurement from "Features/measurements/components/FormMeasurement";

export default function SectionHomeMeasurementInSelectionPanel({
  scene,
  editorPdf,
  viewer,
}) {
  const selection = useSelection({scene, editorPdf, viewer});
  const measurement = selection?.item;

  return (
    <Box>
      <FormMeasurement
        measurement={measurement}
        isEditing={false}
        scene={scene}
      />
    </Box>
  );
}
