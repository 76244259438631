import {useRef, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";

import {fetchArticles, fetchArticlesGroups} from "../articlesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useAutoFetchArticles(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const sceneId = scene?.id;

  const syncRef = useRef();
  useEffect(() => {
    syncRef.current = {
      isFetchingArticlesGroups: false,
      isFetchingArticlesByGroup: {},
    };
  }, []);

  // data
  const areFetchedArticlesGroups = useSelector(
    (s) => s.articles.areFetchedArticlesGroups
  );
  const areFetchedArticlesGroupMap = useSelector(
    (s) => s.articles.areFetchedArticlesGroupMap
  );
  const articlesGroupsMap = useSelector((s) => s.articles.articlesGroupsMap);

  const selectedArticlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );

  // helpers

  const articlesGroupsIds = Object.keys(articlesGroupsMap);

  // effect - fetch articles groups

  async function fetchGroups(accessToken, sceneId) {
    syncRef.current.isFetchingArticlesGroups = true;
    await dispatch(fetchArticlesGroups({accessToken, sceneId}));
    syncRef.current.isFetchingArticlesGroups = false;
  }

  useEffect(() => {
    if (
      !areFetchedArticlesGroups &&
      accessToken &&
      syncRef?.current &&
      !syncRef?.current.isFetchingArticlesGroups
    ) {
      fetchGroups(accessToken, sceneId);
    }
  }, [accessToken, areFetchedArticlesGroups, sceneId, syncRef?.current]);

  // effect - fetch articles

  const shouldFetchSelectedArticlesGroup =
    !areFetchedArticlesGroupMap[selectedArticlesGroupId];

  async function fetchArticlesByGroup(accessToken, groupId) {
    syncRef.current.isFetchingArticlesByGroup[groupId] = true;
    await dispatch(fetchArticles({accessToken, articlesGroupId: groupId}));
    syncRef.current.isFetchingArticlesByGroup[groupId] = false;
  }

  useEffect(() => {
    if (
      shouldFetchSelectedArticlesGroup &&
      accessToken &&
      selectedArticlesGroupId &&
      syncRef?.current &&
      !syncRef?.current.isFetchingArticlesByGroup[selectedArticlesGroupId]
    ) {
      fetchArticlesByGroup(accessToken, selectedArticlesGroupId);
    }
  }, [
    accessToken,
    areFetchedArticlesGroups,
    sceneId,
    syncRef?.current,
    shouldFetchSelectedArticlesGroup,
    selectedArticlesGroupId,
  ]);
}
