import getMeasNomenclatureType from "./getMeasNomenclatureType";
import getTemplateFromElementTypeName from "./getTemplateFromElementTypeName";
import templateNomenclatureMap from "../data/templateNomenclatureMap";

export default function updateMeasurementsTypeFromTypeNomenclature(
  measurements,
  typeNomenclature
) {
  console.log("debugAFA89 - measurements", typeNomenclature);
  //
  const firstType = typeNomenclature.find((type) => type.num.endsWith(".1"));
  const template = getTemplateFromElementTypeName(firstType.name);
  const nomenclature = templateNomenclatureMap[template];
  const fields = nomenclature.fields;

  const updatedMeasurements = [];
  measurements.forEach((meas) => {
    const newType = getMeasNomenclatureType(meas, typeNomenclature, fields);
    if (newType) {
      updatedMeasurements.push({
        ...meas,
        elementTypeId: newType.id,
        color: newType.color,
      });
    }
  });
  return updatedMeasurements;
}
