import {Box, Typography} from "@mui/material";

export default function SectionDescription() {
  // string

  const descriptionS = `Les ouvrages / éléments de votre bimbox sont gérés depuis des calques de repérage.
Créez un calque de repérage à partir d'une légende (bibliothèque d'ouvrages / éléments) et d'un plan au format PDF.`;
  return (
    <Box sx={{display: "flex", width: 1}}>
      <Typography sx={{whiteSpace: "pre-wrap", color: "text.secondary"}}>
        {descriptionS}
      </Typography>
    </Box>
  );
}
