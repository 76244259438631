import {Box} from "@mui/material";

import BlockEditableArticleComment from "./BlockEditableArticleComment";
import BlockEditableArticleHardcodedQty from "./BlockEditableArticleHardcodedQty";
import BlockEditableArticleRefQty from "./BlockEditableArticleRefQty";

export default function SectionArticleQtyDetail({article}) {
  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: "4px",
      }}
    >
      <BlockEditableArticleComment article={article} />
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          "&>*": {mb: 0.5},
          pr: 1,
        }}
      >
        <BlockEditableArticleHardcodedQty article={article} />
        <BlockEditableArticleRefQty article={article} />
      </Box>
    </Box>
  );
}
