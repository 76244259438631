import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ListItemModel from "./ListItemModel";

import useSceneModule from "Features/navigation/useSceneModule";
import {updateModel} from "Features/viewer3D/viewer3DSlice";

export default function ShortcutModels({scene}) {
  const dispatch = useDispatch();

  // strings

  const multiselectString = "Multiple selection";

  // data

  const allModels = useSelector((s) => s.viewer3D.models);

  let models = allModels
    .filter((m) => m.sceneClientId === scene?.clientId && m.enabled)
    .filter((m) => ["IFC", "MEASUREMENTS"].includes(m.type));

  console.log("models42", models);

  const visibleIds = models.filter((m) => !m.hidden).map((m) => m.id);

  console.log("visibleIds", visibleIds);

  const sceneModule = useSceneModule();

  const measurementsModelsInScope = useSelector(
    (s) => s.measurements.measurementsModelsInScope
  );

  if (sceneModule === "MEASUREMENTS" && Array.isArray(models)) {
    models = models.filter((m) => measurementsModelsInScope.includes(m.id));
  }

  // state

  const [checked, setChecked] = useState(true);

  // helper

  function showModel(modelId) {
    const model = models.find((m) => m.id === modelId);
    console.log("showModel", model?.name);
    const updatedModel = {...model, hidden: false};
    dispatch(
      updateModel({updatedModel, sync: false, updateModelInStore: false})
    );
    //const entity = editor.getEntity(modelId);
    //entity?.show();
  }

  function hideModel(modelId) {
    const model = models.find((m) => m.id === modelId);
    console.log("hideModel", model?.name);
    const updatedModel = {...model, hidden: true};
    dispatch(
      updateModel({updatedModel, sync: false, updateModelInStore: false})
    );
    //const entity = editor.getEntity(modelId);
    //entity?.hide();
  }

  // handlers - models

  function handleModelClick(model) {
    if (checked) {
      console.log("isVisible", visibleIds.includes(model.id), model);
      if (visibleIds.includes(model.id)) {
        hideModel(model.id);
      } else {
        showModel(model.id);
      }
    } else {
      const toHideIds = visibleIds.filter((id) => id !== model.id);
      toHideIds.forEach((id) => hideModel(id));
      showModel(model.id);
    }
  }

  // handlers - multiple select

  function handleCheckChange(e) {
    const checked = e.target.checked;
    setChecked(checked);
  }

  return (
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {models
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((m) => {
          return (
            <ListItemModel key={m.id} model={m} onClick={handleModelClick} />
          );
        })}
      <FormControlLabel
        sx={{mt: 1}}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckChange}
            size="small"
          />
        }
        label={
          <Typography sx={{fontSize: 12}} variant="body2">
            {multiselectString}
          </Typography>
        }
      />
    </Box>
  );
}
