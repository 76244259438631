import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormPhase from "./FormPhase";

import {setEditedPhase, setTempPhase} from "../phasesSlice";

export default function SectionPhaseInSelectionPanel({scene}) {
  const dispatch = useDispatch();

  // data

  const isEditing = useSelector((s) => s.phases.isEditingPhase);
  const editedPhase = useSelector((s) => s.phases.editedPhase);
  const tempPhase = useSelector((s) => s.phases.tempPhase);
  const selectedPhase = useSelector((s) => s.phases.selectedPhase);

  // helper

  const isTemp = !selectedPhase && !isEditing;
  const isEdited = selectedPhase && isEditing;
  const isSelected = selectedPhase && !isEditing;

  // handlers

  function handleTempPhaseChange(phase) {
    dispatch(setTempPhase(phase));
  }
  function handleEditedPhaseChange(phase) {
    dispatch(setEditedPhase(phase));
  }
  return (
    <Box sx={{pt: 4}}>
      <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
        {isTemp && (
          <FormPhase
            phase={tempPhase}
            onChange={handleTempPhaseChange}
            canEdit={true}
          />
        )}
        {isSelected && <FormPhase phase={selectedPhase} canEdit={false} />}
        {isEdited && (
          <FormPhase
            phase={editedPhase}
            onChange={handleEditedPhaseChange}
            canEdit={true}
          />
        )}
      </Box>
    </Box>
  );
}
