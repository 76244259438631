import { getFileSizeString } from "Features/files/utils";

export default function zonesForDataGrid(zones, models) {
  return zones.map((zone) => {
    let imageModel;
    if (models)
      imageModel = models.find((m) => m.id === zone?.imageModelId);
    return {
      id: zone.id,
      image: zone.imageUrl,
      imageName: zone.imageModelName,
      name: zone.name,
      pdf: zone.pdfModelName,
      page: zone.pageNumber,
      sector: zone.sectorCode ? zone.sectorCode : "-",
      fileSize: getFileSizeString(imageModel?.fileSize),
    };
  });
}
