import KeyValueForm from "Features/kvform/components/KeyValueForm";

export default function FieldZoneNorth({zone, editorPdf}) {
  // strings

  const northS = "North (deg)";

  // template

  const template = {
    key: "kvtZoneNorth",
    fields: [
      {
        key: "north",
        name: northS,
        type: "number",
      },
    ],
  };

  // helpers - compute north

  function getNorthFromRotation(r) {
    if (!r) return;
    if (r.x === 0) {
      return ((r.y * 180) / Math.PI).toFixed(1); // vertical zone
    } else {
      return ((r.z * 180) / Math.PI).toFixed(1); // horizontal zone
    }
  }

  function updateRotationFromNorth(r, north) {
    if (!r) return;
    const angle = (parseFloat(north) * Math.PI) / 180;
    const rotation = {...r};
    if (r.x === 0) {
      rotation.y = angle; // vertical zone
    } else {
      rotation.z = angle; // horizontal zone
    }
    return rotation;
  }

  // helpers - item

  const north = getNorthFromRotation(zone?.rotation);
  const item = {north};

  // handlers

  function handleNorthChange({north}) {
    if (typeof north !== "number" || isNaN(north)) return;
    const rotation = updateRotationFromNorth(zone.rotation, north);
    editorPdf?.annotationsManager.updateAnnotationEntityRotation({
      annotationId: zone.id,
      rotation,
    });
  }

  return (
    <KeyValueForm
      item={item}
      template={template}
      onChange={handleNorthChange}
    />
  );
}
