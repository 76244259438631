export default function getQtyWithDec(qty, dec) {
  let result;
  if (!dec) dec = 0;
  if (typeof qty === "number") {
    const decInt = parseInt(dec);
    result = parseFloat(qty.toFixed(decInt));
  } else {
    result = null;
  }
  return result;
}
