export const lineLineIntersection = (A, B, C, D) => {
  const a1 = B.y - A.y;
  const b1 = A.x - B.x;
  const c1 = a1 * A.x + b1 * A.y;
  const a2 = D.y - C.y;
  const b2 = C.x - D.x;
  const c2 = a2 * C.x + b2 * C.y;
  const determinant = a1 * b2 - a2 * b1;
  if (determinant == 0) {
    return null; // lines are parallel
  } else {
    var x = (b2 * c1 - b1 * c2) / determinant;
    var y = (a1 * c2 - a2 * c1) / determinant;
    return [x, y];
  }
};

export function segmentSegmentIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) return false; // no length
  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  if (denominator === 0) return false; // parallel...
  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) return false; // no intersection
  return true;
}

export function segmentPolylineIntersection(segment, polyline) {
  const x1 = segment[0][0];
  const y1 = segment[0][1];
  const x2 = segment[1][0];
  const y2 = segment[1][1];
  let intersect = false;
  for (let i = 0; i < polyline.length - 1; i++) {
    const x3 = polyline[i][0];
    const y3 = polyline[i][1];
    const x4 = polyline[i + 1][0];
    const y4 = polyline[i + 1][1];
    if (segmentSegmentIntersection(x1, y1, x2, y2, x3, y3, x4, y4)) {
      intersect = i;
      break;
    }
  }
  return intersect;
}

export function pointInsidePolygon(point, polygon) {
  const n = polygon.length;
  let isIn = false;
  const x = point[0];
  const y = point[1];
  let x1, x2, y1, y2;
  x1 = polygon[n - 1][0];
  y1 = polygon[n - 1][1];
  for (let i = 0; i < n; ++i) {
    x2 = polygon[i][0];
    y2 = polygon[i][1];
    if (y < y1 !== y < y2 && x < ((x2 - x1) * (y - y1)) / (y2 - y1) + x1) {
      isIn = !isIn;
    }
    x1 = x2;
    y1 = y2;
  }
  return isIn;
}

export function pointInsideTriangle(Ax, Ay, Bx, By, Cx, Cy, x, y) {
  var inside = false;
  if ((x != Ax || y != Ay) && (x != Bx || y != By) && (x != Cx || y != Cy)) {
    if (
      ((Ay < y && By >= y) || (By < y && Ay >= y)) &&
      Ax + ((y - Ay) / (By - Ay)) * (Bx - Ax) < x
    )
      inside = !inside;
    if (
      ((By < y && Cy >= y) || (Cy < y && By >= y)) &&
      Bx + ((y - By) / (Cy - By)) * (Cx - Bx) < x
    )
      inside = !inside;
    if (
      ((Cy < y && Ay >= y) || (Ay < y && Cy >= y)) &&
      Cx + ((y - Cy) / (Ay - Cy)) * (Ax - Cx) < x
    )
      inside = !inside;
  }
  return inside;
}