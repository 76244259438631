import React from "react";
import {Typography, Box} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import logo from "../assets/caplaDark.png";
import logoWhite from "../assets/logo100white.png";
import {useNavigate} from "react-router";

export default function Capla360Logo({
  withText = false,
  white = "false",
  onClick,
}) {
  const navigate = useNavigate();

  const logoSize = 18;

  function handleLogoClick() {
    if (onClick) onClick();
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "& :hover": {
          cursor: "pointer",
        },
      }}
      onClick={handleLogoClick}
    >
      <img src={white ? logoWhite : logo} width={logoSize} height={logoSize} />
      {withText && (
        <Typography
          sx={{
            color: white ? "common.white" : "black",
            marginLeft: 2,
            minWidth: 150,
            fontWeight: "bold",
          }}
        >
          CAPLA 360
        </Typography>
      )}
    </Box>
  );
}
