export default function getStateScopeFromRemoteItem(remoteItem) {
  return {
    id: remoteItem.id,
    version: remoteItem.version,
    name: remoteItem.name,
    description: remoteItem.description,
    imageUrl: remoteItem.imageUrl,
    sceneId: remoteItem.scene,
    data: remoteItem.data,
  };
}
