import {useSelector} from "react-redux";

import {Box, CircularProgress} from "@mui/material";
import {CloudUpload as Upload} from "@mui/icons-material";

export default function IconButtonUpload({modelId}) {
  // data

  const modelsSyncStatus = useSelector((s) => s.viewer3D.modelsSyncStatus);

  // helper

  const status = modelsSyncStatus.find((s) => s.modelId === modelId);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "20px",
        color: "primary.main",
      }}
    >
      {status?.isUploading ? (
        <CircularProgress size={16} />
      ) : (
        <Upload fontSize="small" color="inherit" />
      )}
    </Box>
  );
}
