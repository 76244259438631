import {saveBlob} from "Features/files/utils";

export default async function downloadExcelFileFromWorkbook(
  workbook,
  fileName
) {
  try {
    const newBuffer = await workbook.xlsx.writeBuffer();
    const newBlob = new Blob([newBuffer], {type: "application/octet-stream"});
    saveBlob(newBlob, fileName);
  } catch (e) {
    console.log("error", e);
  }
}
