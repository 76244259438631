import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getSectorsFromFile(file) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(4, rowCount - 3).values();

  const valuesA = [];
  for (let row of rows) {
    const rowValues = [];
    for (let i = 1; i <= row.values.length; i++) {
      rowValues.push(row.getCell(i).value);
    }
    valuesA.push(rowValues);
  }

  const fields = ["code", "name", "z", "building"];

  const loadedSectors = [];

  valuesA.map((valueA) => {
    const sector = {};
    valueA.forEach((value, index) => (sector[fields[index]] = value));
    loadedSectors.push(sector);
  });
  return loadedSectors;
}
