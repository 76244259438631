import {useState} from "react";
import {useDispatch} from "react-redux";

import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@mui/material";
import useCreateRessourcesGroupFromTypesGroup from "../utils/useCreateRessourcesGroupFromTypesGroup";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function DialogToRessources({open, onClose, scene}) {
  const dispatch = useDispatch();

  // strings

  const title = "Conversion des ouvrages en articles";

  const description = `Un tableau d'articles sera créé automatiquement à partir des ouvrages de la liste sélectionnée.`;

  const createS = "Créer les articles";

  // data

  const elementTypesGroup = useSelectedElementTypesGroup(scene);

  const create = useCreateRessourcesGroupFromTypesGroup();

  // state

  const [loading, setLoading] = useState(false);

  // handlers

  const handleToArticlesConfirm = async (newName) => {
    setLoading(true);
    await create(elementTypesGroup);
    setLoading(false);
    const message = "Table d'article créée avec succès";
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  };

  function handleClose() {
    if (!loading) onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleToArticlesConfirm}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
