import {useDispatch, useSelector} from "react-redux";

import {setFilterGroupIdsByTab} from "../filtersSlice";
import {FormControlLabel, Typography, Switch, Box} from "@mui/material";

export default function SwitchFilterByGroup({tab}) {
  const dispatch = useDispatch();
  // string

  const label = "Filtrer sur le groupe sélectionné";

  // data

  const selectedElementTypeGroupId = useSelector(
    (s) => s.filters.selectedElementTypeGroupId
  );

  const filtersByTab = useSelector((s) => s.filters.filtersByTab);

  // helpers

  const tabCheckedMap = {
    ELEMENT_TYPES: Boolean(
      filtersByTab[tab]?.groupIds?.includes(selectedElementTypeGroupId)
    ),
  };
  const checked = Boolean(tabCheckedMap[tab]);

  const tabSelectedGroupIdMap = {
    ELEMENT_TYPES: selectedElementTypeGroupId,
  };

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    const oldGroupIds = filtersByTab[tab]?.groupIds;
    let newGroupIds = [];
    if (!Array.isArray(oldGroupIds)) {
      newGroupIds = [tabSelectedGroupIdMap[tab]];
    } else {
      newGroupIds = oldGroupIds.filter(
        (gid) => gid !== tabSelectedGroupIdMap[tab]
      );
      if (checked) {
        newGroupIds.push(tabSelectedGroupIdMap[tab]);
      }
    }
    if (newGroupIds.length === 0) newGroupIds = null;
    dispatch(setFilterGroupIdsByTab({tab, groupIds: newGroupIds}));
  }

  return (
    <Box sx={{pl: 2}}>
      <FormControlLabel
        control={
          <Switch size="small" checked={checked} onChange={handleChange} />
        }
        label={<Typography sx={{fontSize: 13}}>{label}</Typography>}
      />
    </Box>
  );
}
