import {createSlice} from "@reduxjs/toolkit";

const widthSmall = 74;
const widthLarge = 240;
export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    widthSmall: widthSmall,
    widthLarge: widthLarge,
    open: false, // navigation panel
    openSceneModule: false, // navigation Panel (scene modules)
    navPanelWidth: 260, // navigation panel width in px
    leftPanelWidth: 340, // left panel width in px // 380
    navPanelSceneModulesWidth: widthSmall, // SMALL = 50 vs 240
    openedParents: [], // deployed parents in the scene menu

    openSelectionDetail: false, // trigger by the arrow button in the section header
    openLeftPanel: true,
    selectionDetailLevel: "SCENE", // "SCENE","MODEL","MODEL_NEW", used to display detail of the selected element.

    lastSelectionType: null, // ISSUE, .. : used to adapt selection detail in selection panel

    openBuildWithExcelDialog: false,

    openMobileDialogScene: true,

    shrinkAppTopBar: false,
    openPanelPackages: false,

    // config
    openDialogFsSceneModuleConfig: false,
    enabledModules: [],
    modeForOverview: null, // selected mode to show available modules
    showedModules: [
      "SCOPES",
      "SECTORS",
      "INPUTS",
      "ZONES",
      "ELEMENT_TYPES",
      "PACKAGES",
      "EDIT_MEASUREMENTS",
      "MEASUREMENTS",
      "ARTICLES",
      "SHAREDBOXES",
      //"VIEWS",
    ],
  },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setOpenLeftPanel: (state, action) => {
      state.openLeftPanel = action.payload;
    },
    setSelectionDetailLevel: (state, action) => {
      state.selectionDetailLevel = action.payload;
    },
    setOpenSelectionDetail: (state, action) => {
      state.openSelectionDetail = action.payload;
    },
    setLastSelectionType: (state, action) => {
      state.lastSelectionType = action.payload;
    },
    setOpenedParents: (state, action) => {
      state.openedParents = action.payload;
    },
    setOpenBuildWithExcelDialog: (state, action) => {
      state.openBuildWithExcelDialog = action.payload;
    },
    setOpenMobileDialogScene: (state, action) => {
      state.openMobileDialogScene = action.payload;
    },
    setLeftPanelWidth: (state, action) => {
      state.leftPanelWidth = action.payload;
    },
    setNavPanelSceneModulesWidth: (state, action) => {
      const sizeKey = action.payload;
      if (sizeKey === 0) {
        state.navPanelSceneModulesWidth = 0;
      } else {
        state.navPanelSceneModulesWidth =
          sizeKey === "SMALL" ? state.widthSmall : state.widthLarge;
      }
    },
    setShrinkAppTopBar: (state, action) => {
      state.shrinkAppTopBar = action.payload;
    },
    setOpenPanelPackages: (state, action) => {
      state.openPanelPackages = action.payload;
    },
    // config
    setOpenDialogFsSceneModuleConfig: (state, action) => {
      state.openDialogFsSceneModuleConfig = action.payload;
    },
    setEnabledModules: (state, action) => {
      state.enabledModules = action.payload;
    },
    setShowedModules: (state, action) => {
      state.showedModules = action.payload;
    },
    setModeForOverview: (state, action) => {
      state.modeForOverview = action.payload;
    },
  },
});

export const {
  setOpen,
  setSelectionDetailLevel,
  setOpenSelectionDetail,
  setLastSelectionType,
  setOpenedParents,
  setOpenBuildWithExcelDialog,
  setOpenMobileDialogScene,
  setOpenLeftPanel,
  setLeftPanelWidth,
  setNavPanelSceneModulesWidth,
  setShrinkAppTopBar,
  setOpenPanelPackages,
  setOpenDialogFsSceneModuleConfig,
  setShowedModules,
  setEnabledModules,
  setModeForOverview,
} = navigationSlice.actions;

export default navigationSlice.reducer;
