export default function addCodeNameToMeasurements(measurements) {
  const countMap = {}; // {typeId:count}

  const ms = [];

  measurements.forEach((measurement) => {
    const typeId = measurement.elementTypeId;
    let count = countMap[typeId];
    if (!count) {
      countMap[typeId] = 1;
    } else {
      countMap[typeId] += 1;
    }
    const countS = countMap[typeId].toString();
    ms.push({
      ...measurement,
      codeName: measurement.codeName ? measurement.codeName : countS,
    });
  });

  return ms;
}
