import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import {Close} from "@mui/icons-material";

// import SelectorPdfModelViewer from "./SelectorPdfModelViewer";

import {setShowPdfSelector, setTempPdfModelId} from "../pdfSlice";

import {getFileSizeString} from "Features/files/utils";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setSelectedMeasurementsModelId} from "Features/measurements/measurementsSlice";
import useSceneModule from "Features/navigation/useSceneModule";

import {useState, useEffect} from "react";
import SearchBarObjects from "Features/search/components/SearchBarObjects";
import SectionAddInputs from "Features/inputs/components/SectionAddInputs";
import usePageType from "Features/navigation/usePageType";

import ContainerEntityCount from "Features/overviewer/components/ContainerEntityCount";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

// import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function SectionSelectorPdf({viewer, caplaEditor, scene}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sceneId = scene?.id ?? scene?.clientId;
  const isStagingEnv = useIsStagingEnv();

  // strings

  const filesS = "Fichiers source";
  // const messageS = "Selectionnez un PDF";

  // data

  const packages = usePackagesByScene(scene, {
    filterByScope: true,
    filterByVersion: true,
  });

  const sceneModule = useSceneModule();
  const pageType = usePageType();
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId
  );
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // helpers - packageCountByInputIdMap

  const packageCountByInputIdMap = {};
  models
    .filter((m) => m.type === "PDF")
    .forEach((input) => {
      const measModels = packages.filter(
        (p) => p.fromModel?.modelId === input.id
      );
      packageCountByInputIdMap[input.id] = measModels.length;
    });

  // helpers

  const pdfsWithPackages = new Set(packages.map((p) => p.fromModel?.modelId));
  let pdfs = models.filter((m) => m.type === "PDF");
  // if (sceneModule === "EDIT_MEASUREMENTS")
  //   pdfs = pdfs.filter((m) => pdfsWithPackages.has(m.id));
  pdfs = pdfs.sort((a, b) => a.name.localeCompare(b.name));

  // state

  const [filteredPdfs, setFilteredPdfs] = useState(pdfs);
  useEffect(() => {
    setFilteredPdfs(pdfs);
  }, [pdfs?.length]);

  // helpers - display

  const display = pageType === "SCENE";

  // handlers

  const closeSelector = () => {
    dispatch(setShowPdfSelector(false));
  };

  const handleClick = (pdf) => {
    dispatch(setSelectedPdfModelId(pdf.id));
    dispatch(setTempPdfModelId(null));
    if (["EDIT_MEASUREMENTS", "ONBOARDING"].includes(sceneModule)) {
      const thepackage = packages.find(
        (p) => p.fromModel?.modelId === pdf.id && p.enabled
      );
      if (thepackage) {
        const modelId = thepackage.id;
        dispatch(setSelectedMeasurementsModelId(modelId));
        let url = `/bimboxes/${sceneId}/${modelId}/measurements`;
        if (isStagingEnv) url = url + "?env=staging";
        navigate(url);
      } else {
        const url = `/bimboxes/${sceneId}/onboarding`;
        navigate(url);
      }

      // caplaEditor?.measDataManager.setSelectedModelById(thepackage?.id);
    }
    dispatch(setShowPdfSelector(false));
  };

  const handleSearch = (objects) => {
    setFilteredPdfs(objects);
  };

  return (
    <Box
      sx={{
        width: 1,
        bgcolor: "background.default",
        display: display ? "flex" : "none",
        flexDirection: "column",
        justifyItems: "stretch",
      }}
    >
      <Box
        sx={{
          p: 1,
          pl: 2,
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h6">{filesS}</Typography>

        <IconButton onClick={closeSelector}>
          <Close />
        </IconButton>
      </Box>

      <Box sx={{pb: 1}}>
        <SearchBarObjects
          objects={pdfs}
          onSearch={handleSearch}
          keys={["name"]}
        />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          width: 1,
          borderRadius: "4px",
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minWidth: 1,
          minHeight: 0,
        }}
      >
        <List
          sx={{
            bgcolor: "common.white",
            color: "common.black",
            width: 1,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          dense
          disablePadding
        >
          {filteredPdfs.map((pdf) => {
            const label = pdf.name;
            const size = getFileSizeString(pdf.fileSize);
            const count = packageCountByInputIdMap[pdf.id];
            return (
              <ListItem key={pdf.id} disablePadding divider color="inherit">
                <ListItemButton
                  onClick={() => handleClick(pdf)}
                  selected={pdf.id === selectedPdfModelId}
                >
                  <Box
                    sx={{
                      width: 1,
                      minWidth: 0,
                    }}
                  >
                    <Typography variant="body2" noWrap>
                      {label}
                    </Typography>
                    <Typography
                      sx={{color: "text.secondary", fontSize: 13}}
                      noWrap
                    >
                      {size}
                      {/* <Arrow fontSize="small" /> */}
                    </Typography>
                  </Box>

                  {count !== 0 && (
                    <Box>
                      <ContainerEntityCount
                        count={packageCountByInputIdMap[pdf.id]}
                        type={"PACKAGES"}
                        size="SMALL"
                      />
                    </Box>
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      {!viewer && (
        <Box sx={{p: 1}}>
          <SectionAddInputs caplaEditor={caplaEditor} scene={scene} />
        </Box>
      )}
    </Box>
  );
}
