import twoLevelsMapToArray from "./twoLevelsMapToArray";
import getTwoLevelsMapFromItems from "./getTwoLevelsMapFromItems";

export default function getMergedItems(items, fields, itemsLabel) {
  //
  let itemsMap = {};
  let mergedItems = [];
  //

  if (fields.length === 2) {
    itemsMap = getTwoLevelsMapFromItems(
      items,
      fields[0],
      fields[1],
      itemsLabel
    );
    mergedItems = twoLevelsMapToArray(
      itemsMap,
      fields[0],
      fields[1],
      itemsLabel
    );
    console.log("fieldsAAA", itemsMap);
  } else if (fields.length === 1) {
    const itemsMap = {};
    const isString = ["p0", "p1", "p2", "p3"].includes(fields[0]);
    items.forEach((item) => {
      let approxValue;
      if (isString) {
        approxValue = item[fields[0]];
      } else {
        approxValue = parseFloat(item[fields[0]].toFixed(3));
      }

      const oldItems = itemsMap[approxValue];
      if (oldItems) {
        itemsMap[approxValue] = [...new Set([...oldItems, item[itemsLabel]])];
      } else {
        itemsMap[approxValue] = [item[itemsLabel]];
      }
    });
    mergedItems = Object.keys(itemsMap).map((key) => {
      return {
        [fields[0]]: isString ? key : parseFloat(key),
        [itemsLabel]: itemsMap[key],
      };
    });
  } else if (fields.length === 4) {
    const f0s = items.map((item) => item[fields[0]]);
    const f1s = items.map((item) => item[fields[1]]);
    const f2s = items.map((item) => item[fields[2]]);
    const f3s = items.map((item) => item[fields[3]]);
    const resultMap = {};
    f0s.forEach((f0) => {
      f1s.forEach((f1) => {
        f2s.forEach((f2) => {
          f3s.forEach((f3) => {
            const item = items.find(
              (item) =>
                item[fields[0]] === f0 &&
                item[fields[1]] === f1 &&
                item[fields[2]] === f2 &&
                item[fields[3]] === f3
            );
            if (item) {
              if (!resultMap[f0 + f1 + f2 + f3 + "-"]) {
                resultMap[f0 + f1 + f2 + f3 + "-"] = item;
                mergedItems.push({
                  [fields[0]]: f0,
                  [fields[1]]: f1,
                  [fields[2]]: f2,
                  [fields[3]]: f3,
                });
              }
            }
          });
        });
      });
    });
  }

  console.log("mergedItems", mergedItems);
  return mergedItems;
}
