// a,b = "1.3.xx"

export default function sortByNum(a, b) {
  if (!a || !b) return 0;

  let as = a.trim().split(".");
  let bs = b.trim().split(".");

  const aNums = as.map((numS) => {
    let num = parseInt(numS);
    if (isNaN(num)) num = 0;
    return num;
  });

  const bNums = bs.map((numS) => {
    let num = parseInt(numS);
    if (isNaN(num)) num = 0;
    return num;
  });

  let s = 0;

  aNums.forEach((aNum, index) => {
    const bNum = bNums[index];
    if (s === 0 && bNums[index] === undefined) {
      s = 1;
    } else if (s === 0 && aNum < bNum) {
      s = -1;
    } else if (s === 0 && aNum > bNum) {
      s = 1;
    }
    if (
      s === 0 &&
      aNums[index + 1] === undefined &&
      bNums[index + 1] !== undefined
    ) {
      s = -1;
    }
  });
  return s;
}
