import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import useVersionsByScene from "Features/versions/hooks/useVersionsByScene";

import {Box, Typography, TextField} from "@mui/material";

// import CardLoadAnnotatedPdf from "./CardLoadAnnotatedPdf";
import ButtonSwitchExcludeFromQties from "./ButtonSwitchExcludeFromQties";
import ButtonSwitchArchived from "./ButtonSwitchArchived";

import {setNewPackage} from "../packagesSlice";
import useSelectedPackage from "../useSelectedPackage";

import useColoringJobsByScene from "Features/coloringJobs/useColoringJobsByScene";
import CardColoringJob from "Features/coloringJobs/components/CardColoringJob";
// import CardNewJob from "Features/coloringJobs/components/CardNewJob";
import ButtonSwitchEnableModel from "Features/viewer3D/components/ButtonSwitchEnableModel";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import SelectorAutocompleteMultipleWithDialog from "Components/SelectorAutocompleteMultipleWithDialog";
import {updateModel} from "Features/viewer3D/viewer3DSlice";
// import CardFileName from "Features/pdf/components/CardFileName";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import SelectorModels from "Features/pdf/components/SelectorModels";
import CardLoadProcedureFiles from "Features/procedures/components/CardLoadProcedureFiles";
import SelectorVersionsForSceneModel from "Features/versions/components/SelectorVersionsForSceneModel";
import SelectorVersion from "Features/versions/components/SelectorVersion";
import SectionInputNameWithIcon from "Features/inputs/components/SectionInputNameWithIcon";
import SwitchIsHelper from "Features/helpers3d/components/SwitchIsHelper";
import SectionDownloadPackage from "./SectionDownloadPackage";
import SectionPackageSectorId from "./SectionPackageSectorId";
import ButtonUpdateMeasurementsWithPackageSector from "./ButtonUpdateMeasurementsWithPackageSector";

export default function SectionPackageInSelectionPanel({
  editor3d,
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // strings

  const pdfS = "Calque de";
  const enabledS = "Activé";
  const restrictedTypesS = "Groupes (types)";
  const nameS = "Nom";
  const selectPdfModelS = "Choisir PDF";

  const noVersionLabelS = "Aucune version";

  // data

  const selectedPackage = useSelectedPackage();
  const coloringJobs = useColoringJobsByScene(scene);
  const groups = useElementTypesGroupsProxyBySceneWithElementTypes(scene);
  const inputs = useInputsByScene(scene);
  // const os = useSelector((s) => s.ui.openSections);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const versions = useVersionsByScene(scene);

  // state

  const [name, setName] = useState("");
  const [version, setVersion] = useState();
  const [pdfModelId, setPdfModelId] = useState(selectedPdfModelId);

  // dynamic update of reduxState

  useEffect(() => {
    const newPackage = {};
    if (name) newPackage.name = name;
    if (version) newPackage.revisionId = version;
    if (pdfModelId) newPackage.fromModel = {type: "PDF", modelId: pdfModelId};
    dispatch(setNewPackage(newPackage));
  }, [name, version, pdfModelId]);

  // helpers

  let value = [];
  if (selectedPackage?.restrictedTypes) {
    const usedGroups = new Set(selectedPackage.restrictedTypes);
    value = groups.filter((g) => usedGroups.has(g.id));
  }
  const coloringJob = coloringJobs.find(
    (j) => j.packageId === selectedPackage?.remoteId
  );

  const selectedInput = inputs.find(
    (i) => i.id === selectedPackage?.fromModel?.modelId
  );
  const model = inputs.find((m) => m.id === pdfModelId);

  // handlers

  function handleVersionChange(version) {
    setVersion(version);
  }

  function handleNameChange(e) {
    e.stopPropagation();
    const newName = e.target.value;
    setName(newName);
  }

  function handlePdfModelChange(m) {
    const modelId = m?.id;
    setPdfModelId(modelId);
  }

  const handleChange = (types) => {
    const formatedTypes = types.map((t) => t.id);
    const updatedModel = {...selectedPackage, restrictedTypes: formatedTypes};
    dispatch(updateModel({updatedModel}));
  };

  // effect

  useEffect(() => {
    setPdfModelId(selectedPdfModelId);
  }, [selectedPdfModelId]);

  return (
    <Box sx={{width: 1}}>
      {coloringJob && (
        <Box sx={{mt: 1}}>
          <CardColoringJob coloringJob={coloringJob} scene={scene} />
        </Box>
      )}

      {!selectedPackage && (
        <Box sx={{p: 1}}>
          {!pdfModelId && (
            <Box sx={{py: 1}}>
              <Typography variant="body2" sx={{mb: 1}}>
                {selectPdfModelS}
              </Typography>
              <SelectorModels
                model={model}
                onChange={handlePdfModelChange}
                models={inputs}
              />
            </Box>
          )}
          <Box sx={{display: "flex", pt: 1, pb: 2}}>
            <SelectorVersion
              selectedVersion={version}
              versions={versions}
              onChange={handleVersionChange}
              noVersionLabel={noVersionLabelS}
            />
          </Box>
          <TextField
            value={name}
            onChange={handleNameChange}
            label={<Typography variant="body2">{nameS}</Typography>}
            variant="outlined"
            size="small"
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            sx={{mb: 2, width: 1}}
          />
        </Box>
      )}
      {selectedPackage && (
        <Box sx={{p: 1, display: "flex"}}>
          <Box sx={{mr: 1}}>
            <Typography variant="body2" noWrap color="text.secondary">
              {pdfS}
            </Typography>
          </Box>
          <Box sx={{display: "flex", minWidth: 0}}>
            <SectionInputNameWithIcon model={selectedInput} />
          </Box>
          s
        </Box>
      )}
      {selectedPackage && (
        <SectionDownloadPackage model={selectedPackage} scene={scene} />
      )}

      {selectedPackage && (
        <Box
          sx={{pt: 2, px: 3, display: "flex", justifyContent: "space-between"}}
        >
          <ButtonSwitchArchived model={selectedPackage} />
          <SwitchIsHelper model={selectedPackage} />
        </Box>
      )}

      {selectedPackage && versions?.length > 0 && (
        <Box sx={{display: "flex", p: 2, mt: 1}}>
          <SelectorVersionsForSceneModel
            model={selectedPackage}
            scene={scene}
          />
        </Box>
      )}

      {selectedPackage && (
        <Box sx={{display: "flex", px: 2, width: 1, mt: 2}}>
          <SelectorAutocompleteMultipleWithDialog
            name={restrictedTypesS}
            value={value}
            onChange={handleChange}
            options={groups}
          />
        </Box>
      )}

      {selectedPackage && (
        <Box
          sx={{
            display: "flex",
            px: 2,
            width: 1,
            mt: 2,
            flexDirection: "column",
          }}
        >
          <SectionPackageSectorId model={selectedPackage} scene={scene} />
          <ButtonUpdateMeasurementsWithPackageSector
            model={selectedPackage}
            scene={scene}
            caplaEditor={caplaEditor}
          />
        </Box>
      )}

      {/* {!coloringJob && selectedPackage && (
        <CardNewJob
          packageId={selectedPackage?.remoteId}
          packageName={selectedPackage?.name}
          scene={scene}
        />
      )} */}
      {/* {selectedPackage && (
        <Box sx={{mt: 2}}>
          <CardLoadAnnotatedPdf scene={scene} editor3d={editor3d} />
        </Box>
      )} */}
      {selectedPackage && (
        <Box sx={{px: 2, mt: 3}}>
          <Box sx={{display: "flex", p: 1, mt: 2}}>
            <Box sx={{mt: 0.3}}>
              <Typography variant="body2">{enabledS}</Typography>
            </Box>
            <Box sx={{pl: 1}}>
              <ButtonSwitchEnableModel
                model={selectedPackage}
                editor3d={editor3d}
              />
            </Box>
          </Box>
          <ButtonSwitchExcludeFromQties model={selectedPackage} />
        </Box>
      )}
      <Box sx={{p: 2}}>
        <CardLoadProcedureFiles editor3d={editor3d} />
      </Box>
    </Box>
  );
}
