import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ListIssuesInViewer from "./ListIssuesInViewer";

import useIssuesByScene from "../useIssuesByScene";

export default function ListContainerIssuesInViewer({scene, caplaEditor, viewer}) {
  // data

  const sceneIssues = useIssuesByScene(scene?.id);
  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  // helpers

  const issues = sceneIssues
    .filter((i) => i.listing === selectedIssuesListingId)
    .filter((i) => {
      if (viewer === "3D") {
        return i.fromViewMode === "2D" || i.fromViewMode === "3D";
      } else if (viewer === "PDF") {
        return (
          i.fromViewMode === "PDF" &&
          i.context?.pdfContext?.modelId === selectedPdfModelId
        );
      }
    });

  console.log("sceneIssues", sceneIssues, issues);

  return (
    <Box>
      <ListIssuesInViewer issues={issues} caplaEditor={caplaEditor} />
    </Box>
  );
}
