import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";
import {DataGridPro, frFR, GridActionsCellItem} from "@mui/x-data-grid-pro";

import {
  setSelectedMeasurementIds,
} from "../measurementsSlice";
import useMeasurementsForDataGrid from "../useMeasurementsForDataGrid";

import {setPdfViewerMode} from "Features/pdf/pdfSlice";
import {setLastSelectionType} from "Features/navigation/navigationSlice";
import {
  setSelectedPdfModelId,
  setSelectedModelId,
} from "Features/viewer3D/viewer3DSlice";
import useIsNarrow from "Features/ui/useIsNarrow";
import useIsMobile from "Features/ui/useIsMobile";

export default function DataGridMeasurements({mode, caplaEditor, scene}) {
  const dispatch = useDispatch();

  // strings

  const typeString = "Type";
  const codeString = "Code";
  const sectorString = "Secteur";
  const roomString = "Zone";
  const voidsString = "Vides";
  const zInfString = "zInf";
  const lengthString = "L (m)";
  const heightString = "H (m)";
  const quantityUnitString = "U";
  const quantityValueString = "Qty";
  const focusS = "Focus";

  // data

  const measurements = useMeasurementsForDataGrid(scene, mode);

  const selectionModel = useSelector(
    (s) => s.measurements.selectedMeasurementIds
  );

  const pdfViewerMode = useSelector((s) => s.pdf.pdfViewerMode);

  const os = useSelector((s) => s.ui.openSections);

  const ms = useSelector((s) => s.measurements.items);

  const isNarrow = useIsNarrow();

  const isMobile = useIsMobile();

  // helper - showFocus

  const showFocus = (measurementId) => {
    if (os.viewer3D) {
      return caplaEditor?.editor3d?.sceneEditor.testEntityExists({entityID: measurementId});
    } else if (os.fixedViewersBox) {
      return true;
    } else {
      return false;
    }
  };

  // grid - rows

  const rows = measurements;

  // grid - columns

  const columns = [
    {field: "type_name", headerName: typeString, flex: 1, hide: isNarrow},
    {field: "code", headerName: codeString, width: 120},
    {field: "sector", headerName: sectorString, width: 100, hide: isNarrow},
    {field: "room", headerName: roomString, flex: 1, hide: isNarrow},
    {field: "zInf_m", headerName: zInfString, width: 80, hide: isNarrow},
    {field: "length_m", headerName: lengthString, width: 80, hide: isNarrow},
    {field: "height_m", headerName: heightString, width: 80, hide: isNarrow},
    {
      field: "quantity_unit",
      headerName: quantityUnitString,
      width: 40,
      hide: isNarrow,
    },
    {
      field: "quantity_value",
      headerName: quantityValueString,
      width: 80,
      hide: isNarrow,
    },
    {field: "voidsNb", headerName: voidsString, width: 40, hide: isNarrow},
    {
      field: "actions",
      type: "actions",
      width: 50,
      hide: !os.viewer3D && !os.fixedViewersBox,
      getActions: (params) => {
        if (!params.row.isTitle && showFocus(params.row.id)) {
          return [
            <GridActionsCellItem
              icon={<Focus />}
              onClick={() => handleFocusClick(params.row.id)}
              label={focusS}
            />,
          ];
        } else {
          return [];
        }
      },
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    if (selection) {
      // select the pdf

      let measurement;
      if (selection.length === 1)
        measurement = ms.find((m) => m.id === selection[0]);

      if (measurement?.pdfModelId) {
        dispatch(setSelectedPdfModelId(measurement?.pdfModelId));
        dispatch(setSelectedModelId(measurement?.pdfModelId));
      }

      dispatch(setSelectedMeasurementIds(selection));
      dispatch(setLastSelectionType("MEASUREMENT"));

      // switch to measurements mode

      if (pdfViewerMode !== "MEASUREMENTS")
        dispatch(setPdfViewerMode("MEASUREMENTS"));

      // select annotations
      if (os.fixedViewersBox && !isMobile) {
        caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.selectMeasurements(
          selection
        );
        caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.jumpToAnnotationId(
          selection[0]
        );
      }
      // select measurement in 3D
      if (os.viewer3D && measurement) {
        const modelId = measurement.measurementsModelId;
        const entityID = measurement.id;

        caplaEditor?.editor3d?.sceneEditor.selectElementEntity({modelId, entityID});
      }
    }
  }

  function handleFocusClick(measurementId) {
    const measurement = ms.find((m) => m.id === measurementId);
    if (os.viewer3D) {
      const entity = caplaEditor?.editor3d?.sceneEditor.testEntityExists({
        entityID: measurementId,
      });
      console.log("focusOnEntity", entity);
      caplaEditor?.editor3d?.sceneEditor.focusMeasurement(measurement);
    }
    if (os.fixedViewersBox && !isMobile) {
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.jumpToAnnotationId(
        measurementId
      );
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.highlightAnnotation(
        measurementId
      );
    }
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGridPro
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
