const applyStyle = (row, i, isTitle, rank, style) => {
  if ((isTitle && style === undefined) || ["title1", "title2"].includes(style)) {
    row.getCell(i).font = {bold: true};
    if (rank === 1 || style === "title1")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'bbbbc0'}
      };
    if (rank === 2 || style === "title2")
      row.getCell(i).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'F4F4F8'}
      };
  }
  if (style === "detail")
    row.getCell(i).font = {color: {argb: "777777"}};
};

function removeFormulaFilter(formula) {
  return formula.replace(/\s?\{\{[^\}]+\}\}/g, "");
}

const drawingShapeFR = {
  "SEGMENT": "Segment",
  "BEAM": "Poutre",
  "POLYLINE": "Polyligne",
  "SLOPE": "Pente",
  "STAIRS": "Escaliers",
  "RECTANGLE": "Rectangle",
  "POLYGON": "Polygone",
  "BANK": "Talus",
  "CIRCLE": "Cercle",
  "SLOPING_POLYGON": "Rampant",
  "GABLE": "Mur pignon",
  "BRIDGE": "Voute",
  "BOWL": "Coupole",
}

export default function fillWorksheetWithTypesRessourcesRelations({
  worksheet,
  data,
  rowStartIndex,
}) {
  let idx = 0;
  if (typeof data.typesGroup === "string")
    worksheet.getRow(1).getCell(1).value = data.typesGroup;
  data.typesRessourcesRelations.forEach(
    (
      {
        id,
        num,
        name,
        code,
        drawingShape,
        color,
        style,
        isTitle,
        rank,
        res,
      },
      index
    ) => {
      let row = worksheet.getRow(rowStartIndex + index + idx);

      for (let i = 1; i < 13; i++) {
        applyStyle(row, i, isTitle, rank, style);
      }

      if (typeof id === "string") row.getCell(1).value = id;
      row.getCell(1).font = {color: {argb: "D9D9D9"}};
      if (typeof num === "string") row.getCell(2).value = num;
      if (typeof name === "string") row.getCell(3).value = name;
      if (typeof code === "string") row.getCell(4).value = code;
      let applyColor = false;
      if (!isTitle && !["title1, title2"].includes(style)) {
        if (typeof drawingShape === "string")
          row.getCell(5).value = drawingShapeFR[drawingShape];
        if (typeof color === "string") {
          applyColor = true;
          row.getCell(6).value = color;
        }
      }

      if (applyColor) {
        row.getCell(6).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {argb: color.substring(1)},
        };
        row.getCell(6).font = {color: {argb: "FFFFFF"}};
      }

      let tableName = "";

      if (Array.isArray(res) && res.length > 0) {
        res.filter((r) => r).forEach((r) => {
          idx += 1;
          row = worksheet.getRow(rowStartIndex + index + idx);
          if (typeof r.group === "string" && r.group !== tableName) {
            row.getCell(8).value = r.group;
            for (let i = 2; i < 13; i++) {
              const cell = row.getCell(i);
              cell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'f4f4f8' }
              }
              cell.font = {color: {argb: "002060"}, italic: true};
            }
            row.getCell(8).font = {
              color: {argb: "777777"}
            };
            tableName = r.group;
            idx += 1;
            row = worksheet.getRow(rowStartIndex + index + idx);
          }
          if (typeof r.id === "string") row.getCell(1).value = r.id;
          row.getCell(1).font = {color: {argb: "D9D9D9"}};
          row.getCell(2).value = " ";
          if (typeof r.num === "string" && typeof r.name === "string") {
            let context;
            const split = r.num.split(".");
            if (split.length > 0) {
              split.pop();
              const parentNum = split.join(".");
              const parent = data.ressources?.find((re) =>
                re.group === r.group && re.num === parentNum
              );
              if (parent) context = parent.name;
            }
            row.getCell(7).value = r.num;
            row.getCell(8).value = `${context ? "(" + context + ")" : ""} ${r.name}`;
          }
          if (typeof r.unit === "string")
            row.getCell(9).value = r.unit;
          if (typeof r.func === "string") {
            const formulaOnly = removeFormulaFilter(r.func);
            row.getCell(10).value = formulaOnly;
            if (formulaOnly !== r.func) {
              row.getCell(11).value = r.func.replace(formulaOnly, "")
              .replace("{{", "").replace("}}", "");
            }
          }
          for (let i = 2; i < 13; i++) {
            const cell = row.getCell(i);
            cell.fill = {
              type: 'pattern',
              pattern:'solid',
              fgColor:{ argb:'f4f4f8' }
            }
            cell.font = {color: {argb: "002060"}, italic: true};
          }
        })
      }
    }
  );
}
