/*
* nomenclature : [{id,num,...}]
* items : [{id,...}]
-
* Filtered = we keep nomenclature items which are parent of the items (from the num) / or share the item id.
* output = merge nomenclature and item object : {...nomenclatureItem,...item}.
*/

import getItemsMapById from "./getItemsMapById";
import sortByNum from "./sortByNum";
import getNomenclatureItemChildren from "./getNomenclatureItemChildren";

//import aIsParentOfB from "./aIsParentOfB";

export default function getFilteredNomenclatureWithItems(
  nomenclature,
  items,
  options
) {
  if (!items) return [];

  // options

  const numField = options?.numField ?? "num";
  const selectedItemIds = options?.selectedItemIds ?? [];
  const showChildren = options?.showChildren ?? false;

  // data preparation

  const nomenclatureItemsById = getItemsMapById(nomenclature);

  const nomenclatureItemsByNum = nomenclature.reduce((acc, item) => {
    acc[item[numField]] = item;
    return acc;
  }, {});

  // add num to items

  const itemsWithNum = items.map((item) => ({
    ...item,
    [numField]: nomenclatureItemsById[item.id]?.[numField],
  }));

  // fill result

  const resultMap = {};

  itemsWithNum.forEach((item) => {
    // we add merged items
    const mergedItem = {...nomenclatureItemsById[item.id], ...item};
    resultMap[item[numField]] = mergedItem;
    // we add parent items from nomenclature
    const numArray = item[numField]?.split(".") ?? [];
    let parentNum = "";
    numArray.forEach((num, index) => {
      if (index < numArray.length - 1) {
        parentNum = index === 0 ? `${num}` : parentNum + "." + num;
        if (!resultMap[parentNum]) {
          if (nomenclatureItemsByNum[parentNum]) {
            resultMap[parentNum] = nomenclatureItemsByNum[parentNum];
          }
        }
      }
    });
    // we add children items of selectedItems if showChildren
    if (showChildren) {
      selectedItemIds.forEach((id) => {
        const children = getNomenclatureItemChildren(id, nomenclature, {
          numField: "tempNum",
        });
        children.forEach((child) => {
          resultMap[child[numField]] = child;
        });
      });
    }
  });

  const resultArray = Object.values(resultMap).sort((a, b) =>
    sortByNum(a[numField], b[numField])
  );

  return resultArray;
}
