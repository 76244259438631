export default function useElementTypesDrawingShapesOptions() {
  const segmentS = "Segment";
  const polylineS = "Polyligne";
  const rectangleS = "Rectangle";
  const polygonS = "Polygone";
  const circleS = "Cercle";
  const slopeS = "Pente";
  const stairsS = "Escaliers";
  const bankS = "Talus";
  const beamS = "Poutre";
  const slopingPolygonS = "Rampant";
  const gableS = "Mur pignon";
  const bridgeS = "Voute";
  const bowlS = "Coupole";

  return [
    {key: "SEGMENT", name: segmentS},
    {key: "BEAM", name: beamS},
    {key: "POLYLINE", name: polylineS},
    {key: "SLOPE", name: slopeS},
    {key: "STAIRS", name: stairsS},
    {key: "RECTANGLE", name: rectangleS},
    {key: "POLYGON", name: polygonS},
    {key: "BANK", name: bankS},
    {key: "CIRCLE", name: circleS},
    {key: "SLOPING_POLYGON", name: slopingPolygonS},
    {key: "GABLE", name: gableS},
    {key: "BRIDGE", name: bridgeS},
    {key: "BOWL", name: bowlS},
  ];
}
