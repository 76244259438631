import {useRef, useEffect} from "react";
import {useDispatch} from "react-redux";

import {Box, Typography, Button} from "@mui/material";

import Drawer from "../js/Drawer";

import {
  setMode,
  // setStep,
  // setSubStep,
  setPositioningObject,
  // setConfiguringEntity,
  setConfiguring,
  setConfigCenter,
} from "Features/viewer3D/viewer3DSlice";

export default function ProcessCut({editor3d}) {
  const dispatch = useDispatch();

  // drawer

  const drawerRef = useRef();

  // helpers

  function init() {
    drawerRef.current = new Drawer({
      onClosingLine: handleClosingLine,
      scene: editor3d?.scene,
    });
    dispatch(setPositioningObject({editor: true}));
    editor3d?.pointer.enable();
    if (editor3d) {
      editor3d.onProcessEditorClick = handleEditorClick;
      editor3d.onProcessEditorMove = handleEditorMove;
    }
  }

  // init

  useEffect(() => {
    init();
    return () => {
      if (editor3d) {
        editor3d.onProcessEditorMove = () => {};
        editor3d.onProcessEditorClick = () => {};
      }
    };
  }, []);

  // handlers

  function handleEditorClick(props) {
    const {sceneP} = props;
    drawerRef.current.addPoint(sceneP);
  }

  function handleEditorMove({sceneP}) {
    drawerRef.current.drawNextPoint(sceneP);
  }

  function handleClosingLine({contour}) {
    console.log("handleClosing line", contour);
    editor3d?.configCenter.setEntityNewMask(contour);
  }

  function handleSaveClick() {
    drawerRef.current.clear();
    editor3d?.configCenter.saveConfigEntity();
    editor3d?.configCenter.close();
    editor3d?.switchToViewMode("VIEW_3D");
    dispatch(setMode("DEFAULT"));
    dispatch(setConfiguring(false));
    dispatch(setConfigCenter(false));
    dispatch(setPositioningObject({editor: false}));
  }

  return (
    <Box>
      <Typography>Coupe en cours ...</Typography>
      <Button onClick={handleSaveClick}>Valider</Button>
    </Box>
  );
}
