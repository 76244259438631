import React from "react";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

import {Typography, Box} from "@mui/material";

export default function SectionTitleSharedboxes() {
  // data

  const nameMap = useSceneModuleNameMap();

  // helper - title

  const title = nameMap["SHAREDBOXES"];

  // helper - top

  return (
    <Box sx={{p: 1, pl: "16px", pt: "12px", width: 1}}>
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
}
