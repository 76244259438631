import React from "react";

import {useSelector} from "react-redux";
import useMarkersByScene from "../hooks/useMarkersByScene";
import useSceneModule from "Features/navigation/useSceneModule";

import ButtonExportExcel from "Components/ButtonExportExcel";

import markersForDataGrid from "../utils/markersForDataGrid";

export default function ButtonDownloadExcel({viewer, scene}) {
  // data

  const sceneModule = useSceneModule();
  const allMarkers = useMarkersByScene(scene?.clientId);
  const selectedModelId = useSelector((s) => s.viewer3D.selectedModelId);
  const markersModelsInScope = useSelector(
    (s) => s.markers.markersModelsInScope
  );

  // helpers - markers

  let markers = [];

  if (sceneModule === "HOME" || viewer) {
    markers = allMarkers.filter((m) => m.modelId === selectedModelId);
  } else if (sceneModule === "MARKERS") {
    markers = allMarkers.filter((m) =>
      markersModelsInScope.includes(m.modelId)
    );
  }

  // helpers - dataset

  const dataset = markersForDataGrid(markers);

  return <ButtonExportExcel dataset={dataset} />;
}
