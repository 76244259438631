/*
 * elementType : {id,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useAddSceneElementTypes(scene) {
  const dispatch = useDispatch();

  const addSceneElementTypes = (elementTypes) => {
    if (!scene) return;
    let oldElementTypes = scene?.data?.elementTypes;
    if (!oldElementTypes) oldElementTypes = [];

    // remove new types from old list

    const newCodes = elementTypes.map((t) => t.code);
    oldElementTypes = oldElementTypes.filter((t) => !newCodes.includes(t.code));

    const newScene = {
      ...scene,
      data: {
        ...scene?.data,
        elementTypes: [...oldElementTypes, ...elementTypes],
      },
    };

    dispatch(updateScene({scene: newScene}));
  };

  return addSceneElementTypes;
}
