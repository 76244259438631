import React, {useState} from "react";

import {Box, Button, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

import DialogEditListingMobileModules from "./DialogEditListingMobileModules";

export default function CardMobileModule({scene, editor}) {
  // strings

  const title = "Modules (mobile)";
  const description = `Selectionnez les modules auxquels vous pourrez accéder depuis votre mobile.`;

  const createS = "Ajouter des modules";

  // state

  const [open, setOpen] = useState(false);

  // helper

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // handlers

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Box
      sx={{
        width: 1,
        background,
        color: "common.white",
        p: 2,
        borderRadius: "4px",
      }}
    >
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
        {description}
      </Typography>

      <Box sx={{mt: 2, display: "flex", justifyContent: "end", width: 1}}>
        <Button
          size="small"
          startIcon={<Add />}
          onClick={handleClick}
          color="inherit"
          variant="outlined"
        >
          {createS}
        </Button>
      </Box>

      <DialogEditListingMobileModules
        editor={editor}
        scene={scene}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
}
