import React from "react";

import {Box, List, ListItem, Typography} from "@mui/material";

import {Vector2} from "three";

export default function MeasuresTableItem({points, isHole = false}) {
  // strings

  const openingString = "Opening";

  // helpers

  const distances = points.reduce((acc, current, index) => {
    if (index >= 1) acc.push(computeDistance(current, points[index - 1]));
    return acc;
  }, []);

  const angles = points.reduce((acc, current, index) => {
    if (index >= 1) acc.push(computeAngle(points[index - 1], current));
    return acc;
  }, []);

  // helpers

  function computeDistance(point1, point2) {
    if (point1 && point2) {
      return Math.sqrt(
        (point1.x - point2.x) ** 2 +
          (point1.y - point2.y) ** 2 +
          (point1.z - point2.z) ** 2
      );
    } else {
      return undefined;
    }
  }

  function computeAngle(point1, point2) {
    const v = new Vector2(point2.x - point1.x, -point2.z + point1.z);
    const angle = (v.angle() * 180) / Math.PI;
    return angle.toFixed(2);
  }

  return (
    <Box
      sx={{
        width: 1,
        backgroundColor: isHole ? "background.default" : "common.white",
        p: isHole && 1,
        mb: isHole && 1,
      }}
    >
      {isHole && (
        <Typography variant="body2" color="primary">
          <b>{openingString}</b>
        </Typography>
      )}
      <List dense sx={{width: 1}}>
        {points.map(({x, y, z}, index) => {
          return (
            <ListItem key={index} disableGutters disablePadding>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  width: 1,
                }}
              >
                {index > 0 && (
                  <Box sx={{display: "flex", alignItems: "baseline"}}>
                    <Typography variant="body2">
                      <b>{`${distances[index - 1].toFixed(3)} m`}</b>
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ml: 2}}
                    >{`(${angles[index - 1]}°)`}</Typography>
                  </Box>
                )}

                <Typography
                  variant="caption"
                  color="text.secondary"
                >{`x:${x.toFixed(3)} y:${-z.toFixed(3)} z:${y.toFixed(
                  3
                )}`}</Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
