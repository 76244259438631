export default function getDim1FromPath3(path3) {
  // coordinates in 2d

  const A = [path3[0][0], -path3[0][2]];
  const B = [path3[1][0], -path3[1][2]];
  const C = [path3[2][0], -path3[2][2]];
  const D = [path3[3][0], -path3[3][2]];

  const dx = C[0] - B[0];
  const dy = C[1] - B[1];

  return Math.sqrt(dx ** 2 + dy ** 2);
}
