import {useKvfTranslationMap} from "Features/kvform/hooks/useKvfTranslationMap";

export default function useSceneModuleFormBasicTemplateMap() {
  const tMap = useKvfTranslationMap();

  const sectorKvt = {
    fields: [
      {key: "name", type: "text", xs: 8, label: tMap["name"]},
      {key: "code", type: "text", xs: 2, label: tMap["code"]},
      {key: "z", type: "number", xs: 2, label: tMap["z"]},
    ],
  };

  const roomKvt = {
    fields: [
      //{key: "num", type: "text", xs: 2, label: tMap["num"]},
      {key: "name", type: "text", xs: 6, label: tMap["name"]},
      {key: "code", type: "text", xs: 4, label: tMap["code"]},
      {key: "sector", type: "text", xs: 2, label: tMap["sector"]},
    ],
  };

  return {
    SECTORS: sectorKvt,
    ROOMS: roomKvt,
  };
}
