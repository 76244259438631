import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {decode} from "Features/translations/utils";

// props = [{key:"",valueOld:"",valueNew:""},...]
export default function PropsTable({props, header = true}) {
  const {t} = useTranslation("common");

  const keyString = t("common:property");
  const valueOldString = t("common:before");
  const valueNewString = t("common:after");

  return (
    <TableContainer component={Paper}>
      <Table sx={{}} aria-label="simple table" size="small">
        {header && (
          <TableHead>
            <TableRow>
              <TableCell sx={{color: (theme) => theme.palette.text.secondary}}>
                {keyString}
              </TableCell>
              <TableCell
                sx={{color: (theme) => theme.palette.text.secondary}}
                align="right"
              >
                {valueOldString}
              </TableCell>
              <TableCell
                sx={{color: (theme) => theme.palette.text.secondary}}
                align="right"
              >
                {valueNewString}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {props?.map((prop) => (
            <TableRow
              key={prop.key}
              sx={{"&:last-child td, &:last-child th": {border: 0}}}
            >
              <TableCell component="th" scope="row">
                {decode(prop.key)}
              </TableCell>
              <TableCell align="right">{decode(prop.valueOld)}</TableCell>
              <TableCell align="right">{decode(prop.valueNew)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
