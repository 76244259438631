import React, {useState} from "react";

import {useSelector} from "react-redux";
import {Autocomplete, TextField, Typography, Box, Button} from "@mui/material";

import useTranslation from "Features/translations/useTranslation";
import {nanoid} from "@reduxjs/toolkit";

export default function SelectorAutocomplete({
  name,
  value,
  options,
  onChange,
  onClose,
  onNewOption,
  createLabel = "-",
  canCreate,
}) {
  // state

  const [optionName, setOptionName] = useState("");

  // handlers

  function handleOptionChange(value) {
    onChange(value);
  }

  function handleInputChange(e) {
    setOptionName(e.target.value);
  }

  function handleCreateClick() {
    if (optionName.length > 0) {
      const value = {id: nanoid(), name: optionName};
      if (onNewOption) {
        onNewOption(value);
        onClose();
      }
    }
  }

  function handleClose(e, reason) {
    if (reason === "blur" || reason === "escape") onClose();
  }

  return (
    <Box sx={{width: 1}}>
      <Autocomplete
        open
        onClose={handleClose}
        sx={{flexGrow: 1}}
        options={options}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name}
        renderOption={(props, option) => (
          <Typography {...props} sx={{pl: 1, fontSize: 13}}>
            {option?.name}
          </Typography>
        )}
        value={value}
        onChange={(e, v) => handleOptionChange(v)}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleInputChange}
            size="small"
            label={<Typography variant="body2">{name}</Typography>}
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: 13,
              },
            }}
          />
        )}
        noOptionsText={
          onNewOption && canCreate ? (
            <Button size="small" onClick={handleCreateClick}>
              {createLabel}
            </Button>
          ) : (
            <Box />
          )
        }
      />
    </Box>
  );
}
