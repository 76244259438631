function countDecimalPlaces(number) {
  if (Number.isInteger(number)) {
    return 0; // No decimal places in an integer
  }

  const decimalPart = number.toString().split(".")[1];
  return decimalPart ? decimalPart.length : 0;
}

function cleanFloat(number) {
  try {
    if (countDecimalPlaces(number) > 4) {
      return Number(number.toFixed(4));
    } else {
      return number;
    }
  } catch (e) {
    console.log("error cleaningFloat", e);
  }
}

// path = [[x,y],[x,y]]
function cleanPath(path) {
  return path.map((point) => {
    return point.map((coordinate) => {
      return cleanFloat(coordinate);
    });
  });
}

export default function cleanMeasurementPaths(measurement) {
  let cleanMeasurement = {...measurement};

  if (measurement?.drawingProps?.path?.length > 0) {
    cleanMeasurement.drawingProps = {
      ...measurement.drawingProps,
      path: cleanPath(measurement.drawingProps.path),
    };
  }

  if (Array.isArray(measurement.path3D)) {
    cleanMeasurement.path3D = cleanPath(measurement.path3D);
  }

  if (Array.isArray(measurement.path3d3)) {
    cleanMeasurement.path3d3 = cleanPath(measurement.path3d3);
  }

  cleanMeasurement.zInf =
    Math.abs(measurement?.zInf) < 0.0001 ? 0 : measurement.zInf;

  return cleanMeasurement;
}
