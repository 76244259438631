import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import ListItemScene from "./ListItemScene";

// import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {
  selectorSpaceScenes,
  showOneScene,
  toggleSceneVisibility,
} from "Features/space/spaceSlice";

export default function ShortcutScenes(){ // ({editor3d, scene}) {
  const dispatch = useDispatch();

  // strings

  const multiselectString = "Multiple selection";

  // data

  const spaceScenes = useSelector(selectorSpaceScenes);
  const hiddenScenesIds = useSelector((s) => s.space.hiddenScenesIds);

  // const allModels = useSelector((s) => s.viewer3D.models);
  // const models = allModels
  //   .filter((m) => m.sceneClientId === scene?.clientId)
  //   .filter((m) => ["IFC", "IMAGE"].includes(m.type));

  // const visibleIds = models.filter((m) => !m.hidden).map((m) => m.id);

  // state

  const [checked, setChecked] = useState(false);

  // helper

  // function showModel(modelId) {
  //   const entity = editor3d?.getEntity(modelId);
  //   entity.show();
  //   const updatedModel = {id: modelId, hidden: false};
  //   dispatch(updateModel({updatedModel, sync: false}));
  // }

  // function hideModel(modelId) {
  //   const entity = editor3d?.getEntity(modelId);
  //   entity.hide();
  //   const updatedModel = {id: modelId, hidden: true};
  //   dispatch(updateModel({updatedModel, sync: false}));
  // }

  // handlers - models

  function handleSceneClick(scene) {
    if (checked) {
      dispatch(toggleSceneVisibility(scene.id));
    } else {
      dispatch(showOneScene(scene.id));
    }
  }

  // handlers - multiple select

  function handleCheckChange(e) {
    const checked = e.target.checked;
    setChecked(checked);
  }
  return (
    <Box sx={{"&>*:not(:last-child)": {mb: 1}}}>
      {spaceScenes
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((s) => {
          const selected = !hiddenScenesIds.includes(s.id);
          return (
            <ListItemScene
              key={s.id}
              scene={s}
              onClick={handleSceneClick}
              selected={selected}
            />
          );
        })}
      <FormControlLabel
        sx={{mt: 1}}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckChange}
            size="small"
          />
        }
        label={
          <Typography sx={{fontSize: 12}} variant="body2">
            {multiselectString}
          </Typography>
        }
      />
    </Box>
  );
}
