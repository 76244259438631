import {Box} from "@mui/material";

import CardLoadMeasurements from "./CardLoadMeasurements";
import CardExtractElementTypes from "./CardExtractElementTypes";
import SectionImportOverview from "./SectionImportOverview";

// import useAnnotatedPdfModel from "../hooks/useAnnotatedPdfModel";

export default function SectionAnnotatedPdfModelInSelectionPanel({
  editorPdf,
  scene,
}) {
  // data

  // const annotatedPdfModel = useAnnotatedPdfModel();

  return (
    <Box>
      <CardLoadMeasurements editorPdf={editorPdf} />
      <Box sx={{p: 2, mt: 2}}>
        <CardExtractElementTypes scene={scene} />
      </Box>

      <Box sx={{width: 1, mt: 2}}>
        <SectionImportOverview />
      </Box>
    </Box>
  );
}
