import {useState} from "react";
import {useDispatch} from "react-redux";

import {initResWithRessource} from "Features/elementTypor/elementTyporSlice";

import {Box, Typography, Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import theme from "Styles/theme";

import getRessourceStyleObject from "../utils/getRessourceStyleObject";
import getRessourceQuantityLabel from "../utils/getRessourceQuantityLabel";

import SectionTableRessourceTypes from "./SectionTableRessourceTypes";

export default function ListItemRessource({
  scene,
  ressource,
  selected,
  options,
}) {
  const dispatch = useDispatch();

  // const

  const limit = 0.05;

  // strings

  const addS = "élément / ouvrage";

  // state

  const [openDialog, setOpenDialog] = useState(false);

  // options

  const hideQuantity = options?.hideQuantity;
  const showQtyNet = options?.showQtyNet;
  const showTypes = options?.showTypes;

  // warning

  const showWarning =
    ressource.unit && ressource.unit !== "PM" && !ressource.typeIds?.length > 0;

  // helper - styles

  let {fontWeight, bgcolor, color} = getRessourceStyleObject(ressource);
  if (selected) bgcolor = theme.palette.action.selected;

  // helper - qtyLabel

  const {quantity, label: qtyLabel} = getRessourceQuantityLabel(ressource, {
    showQtyNet,
  });

  // helper - ressource name

  let label = ressource.name;

  if (ressource.num) label = `${ressource.num} • ${ressource.name}`;
  if (ressource.extractedText) label = label + ".";

  const hasPois = ressource?.pois?.length > 0;

  // helper - refWarning

  let delta = 0;
  if (ressource?.refQty && typeof quantity === "number") {
    delta = Math.abs(quantity - ressource.refQty) / ressource.refQty;
  }
  const refWarning = delta > limit;

  // helper - qtyColor

  let qtyColor = quantity > 0 ? "text.secondary" : "divider";
  if (refWarning) qtyColor = "red";

  // handler

  function handleAddElementTypeClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setOpenDialog(true);
    dispatch(initResWithRessource(ressource));
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 1,
          bgcolor,
          px: 1,
          py: 0.5,
          ...(hasPois && {
            borderRight: (theme) => `2px solid ${theme.palette.primary.flash}`,
          }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 1,
            fontWeight,
            color,
          }}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            {showWarning && (
              <Box
                sx={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  mr: 0,
                  bgcolor: "red",
                }}
              />
            )}
            <Typography sx={{fontWeight, ml: 1, fontSize: 12}}>
              {label}
            </Typography>
          </Box>
          {!hideQuantity && !ressource.isTitle && (
            <Box sx={{ml: 1}}>
              <Typography
                noWrap
                sx={{
                  fontSize: 12,
                  color: qtyColor,
                  fontStyle: ressource.hardcodedQty ? "italic" : "normal",
                }}
              >
                {qtyLabel}
              </Typography>
            </Box>
          )}
        </Box>
        {/* {selected && showTypes && (
          <Box sx={{bgcolor: "background.default", p: 2}}>
            <Box
              sx={{
                bgcolor: "white",
                color: "text.secondary",
                borderRadius: "8px",
                width: 1,
              }}
            >
              <SectionTableRessourceTypes scene={scene} ressource={ressource} />
            </Box>
          </Box>
        )} */}
      </Box>
    </>
  );
}
