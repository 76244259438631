import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {updateArticleTemp} from "../articlesSlice";

import {Box, Typography, InputBase} from "@mui/material";

export default function BlockEditableArticleUnit({article}) {
  const dispatch = useDispatch();

  // strings

  const placeholder = "Unité";
  // state

  let initUnit = article?.unit;
  if (!initUnit) initUnit = "";

  const [unit, setUnit] = useState(initUnit);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setUnit(initUnit);
  }, [initUnit]);

  // handlers

  function handleChange(e) {
    e.stopPropagation();
    e.preventDefault();
    let newUnit = e.target.value;
    setUnit(newUnit);
  }

  function handleBlur() {
    setIsEditing(false);
    let newUnit = unit.trim();
    if (newUnit === "") newUnit = null;
    if ((newUnit && newUnit !== article.unit) || (!newUnit && article.unit)) {
      dispatch(updateArticleTemp({id: article.id, unit}));
    }
  }

  function handleFocus() {
    setIsEditing(true);
  }

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "baseline",
        width: "50px",
        //height: "30px",
      }}
    >
      <InputBase
        fullWidth
        placeholder={placeholder}
        disableUnderline
        sx={{
          color: isEditing ? "primary.main" : "text.secondary",
          p: 0,
          fontSize: 12,
        }}
        //inputProps={{style: {textAlign: "right"}}}
        value={unit}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
      />
    </Box>
  );
}
