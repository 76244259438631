import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  Typography,
  Chip,
  Paper,
  ClickAwayListener,
  Autocomplete,
  TextField,
} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import { setSectorsInScope } from "Features/measurements/measurementsSlice";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function SelectorSectorInScope({scene}) {
  const dispatch = useDispatch();

  // strings

  const allS = "Tous";

  // data

  const nameMap = useSceneModuleNameMap();
  const sectors = useSectorsByScene(scene);
  const sectorsFilter = useSelector((s) => s.measurements.sectorsInScope);

  // state

  const [open, setOpen] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);

  // helper - sector

  const sectorId = sectorsFilter.length > 0 ? sectorsFilter[0] : null;
  const sectorInScope = sectors.find((s) => s.id === sectorId);

  // helper - label

  let label = sectorInScope?.name;
  if (!label) label = allS;
  const textFieldLabel = nameMap["SECTORS"];

  // handlers

  function handleMoreClick() {
    setOpen(true);
    setOpenAutocomplete(true);
  }
  function handleClose() {
    setOpen(false);
    setOpenAutocomplete(false);
  }

  function handleChange(sector) {
    if (!sector?.id) dispatch(setSectorsInScope([]));
    else dispatch(setSectorsInScope([sector.id]));
    handleClose();
  }

  function handleClickAwayListener() {
    setOpen(false);
    setOpenAutocomplete(false);
  }

  return (
    <>
      {!open && (
        <Chip
          label={label}
          deleteIcon={<Down />}
          onDelete={handleMoreClick}
          size="small"
        />
      )}

      {open && (
        <Paper sx={{p: 1}}>
          <ClickAwayListener onClickAway={handleClickAwayListener}>
            <Box sx={{width: 150}}>
              <Autocomplete
                open={openAutocomplete}
                sx={{flexGrow: 1}}
                options={sectors}
                value={sectorInScope}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option) => (
                  <Typography {...props} sx={{pl: 1, fontSize: 13}}>
                    {option?.name}
                  </Typography>
                )}
                onChange={(e, v) => handleChange(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoFocus
                    size="small"
                    label={
                      <Typography variant="body2">{textFieldLabel}</Typography>
                    }
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        fontSize: 13,
                      },
                    }}
                  />
                )}
              />
            </Box>
          </ClickAwayListener>
        </Paper>
      )}
    </>
  );
}
