import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, Menu, MenuItem, Typography} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import SelectorSectorInScope from "./SelectorSectorInScope";
import SelectorRoomInScope from "./SelectorRoomInScope";
import SelectorBuildingInScope from "./SelectorBuildingsInScope";

import {
  setScopeSelectionMode,
  setSectorsInScope,
  setRoomsInScope,
} from "Features/measurements/measurementsSlice";
import { setBuildingInScope } from "Features/buildings/buildingsSlice";

export default function SelectorZonesScope({scene, editor3d}) {
  const dispatch = useDispatch();

    // strings

    const allS = "Tous";
    const byBuildingS = "Par bâtiment";
    const bySectorS = "Par secteur";
    const byRoomS = "Par zone";
  
    // data
  
    let scopeSelectionMode = useSelector(
      (s) => s.measurements.scopeSelectionMode
    );
  
    // state
  
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
  
    // helpers - tools
  
    const tools = [
      {
        id: "BY_BUILDING",
        label: byBuildingS,
      },
      {
        id: "BY_SECTOR",
        label: bySectorS,
      },
      {
        id: "BY_ROOM",
        label: byRoomS,
      },
    ];
    let label = tools.find((t) => t.id === scopeSelectionMode)?.label;
    if (!label) label = allS;
  
    //handlers

    const viewer = false;
  
    function handleToolChange(toolId) {
      dispatch(setBuildingInScope(undefined));
      dispatch(setSectorsInScope([]));
      dispatch(setRoomsInScope([]));
      dispatch(setScopeSelectionMode(toolId));
      setAnchorEl(null);
    }

  return (
    <>
      <Box
        sx={{color: "text.secondary", display: "flex", alignItems: "center"}}
      >
        <Button
          endIcon={<Down />}
          size="small"
          color="inherit"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {label}
        </Button>
        {scopeSelectionMode === "BY_BUILDING" && (
          <Box sx={{ml: 1}}>
            <SelectorBuildingInScope scene={scene} viewer={viewer} />
          </Box>
        )}
        {scopeSelectionMode === "BY_SECTOR" && (
          <Box sx={{ml: 1}}>
            <SelectorSectorInScope scene={scene} viewer={viewer} />
          </Box>
        )}
        {scopeSelectionMode === "BY_ROOM" && (
          <Box sx={{ml: 1}}>
            <SelectorRoomInScope scene={scene} viewer={viewer} editor3d={editor3d} />
          </Box>
        )}
      </Box>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem
              key={tool.id}
              onClick={() => {
                handleToolChange(tool.id);
              }}
            >
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
