export default function fillWorksheetWithTypeRessourceMap({
  worksheet,
  typeRessourceMap,
  rowStartIndex,
}) {
  typeRessourceMap.forEach(
    (
      {
        typeId,
        typeGroup,
        typeNum,
        typeName,
        typeIsTitle,
        resId,
        resGroup,
        resNum,
        resName,
        mode,
        func,
      },
      index
    ) => {
      const row = worksheet.getRow(rowStartIndex + index);

      row.getCell(1).value = typeId;
      row.getCell(2).value = typeGroup;
      row.getCell(3).value = typeNum;
      row.getCell(4).value = typeName;
      row.getCell(5).value = resId;
      row.getCell(6).value = resGroup;
      row.getCell(7).value = resNum;
      row.getCell(8).value = resName;
      row.getCell(9).value = mode;
      row.getCell(10).value = func;

      if (typeIsTitle) row.getCell(4).font = {bold: true};
    }
  );
}
