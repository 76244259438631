import React from "react";

import {Box, Typography} from "@mui/material";
import {
  Image,
  PictureAsPdf as Pdf,
  GridView as Capla,
  Widgets as Measurements,
  Place as Marker,
  Image as Zone,
} from "@mui/icons-material";
import {red} from "@mui/material/colors";

export default function ListIconModelType({
  type = "CAPLA",
  size = 24,
  unselected = false,
}) {
  type = type.toString();

  const iconsMap = {
    IFC: {
      icon: (
        <Typography sx={{fontSize: 11}}>
          <b>IFC</b>
        </Typography>
      ),
      bgcolor: "common.yellow",
    },
    GLB: {
      icon: (
        <Typography sx={{fontSize: 11}}>
          <b>GLB</b>
        </Typography>
      ),
      bgcolor: "common.yellow",
    },
    IMAGE: {
      icon: <Image sx={{fontSize: 16}} />,
      bgcolor: "secondary.main",
    },
    ZONE: {
      icon: <Zone sx={{fontSize: 16}} />,
      bgcolor: "secondary.main",
    },
    MARKERS: {
      icon: <Marker sx={{fontSize: 16}} />,
      bgcolor: "common.caplaBlack",
    },
    PDF: {
      icon: <Pdf sx={{fontSize: 16}} />,
      bgcolor: red[700],
    },
    CAPLA: {
      icon: <Capla sx={{fontSize: 16}} />,
      bgcolor: "primary.main",
    },
    MEASUREMENTS: {
      icon: <Measurements sx={{fontSize: 16}} />,
      bgcolor: "primary.flash",
    },
    DEFAULT: {
      icon: <Typography sx={{fontSize: 11}}>U</Typography>,
      bgcolor: "common.caplaBlack",
    },
  };

  // helper

  const icon = iconsMap[type]?.icon ?? iconsMap["DEFAULT"].icon;
  const bgcolor = iconsMap[type]
    ? iconsMap[type].bgcolor
    : iconsMap["DEFAULT"].bgcolor;

  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: "4px",
        bgcolor: unselected ? "common.white" : bgcolor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: unselected ? "text.secondary" : "common.white",
      }}
    >
      {icon}
    </Box>
  );
}
