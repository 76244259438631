import {Box, Checkbox, Typography, FormControlLabel} from "@mui/material";
import useIsStagingEnv from "./useIsStagingEnv";
import {useSearchParams} from "react-router-dom";

export default function CheckboxIsStagingEnv() {
  const [searchParams, setSearchParams] = useSearchParams();

  // strings

  const label = "version beta";

  // data

  const isStagingEnv = useIsStagingEnv();

  // handlers

  function handleChange() {
    if (!isStagingEnv) {
      setSearchParams({
        ...searchParams,
        env: "staging",
      });
      localStorage.setItem("isStagingEnv", "true");
    } else {
      setSearchParams({});
      localStorage.setItem("isStagingEnv", "false");
    }
  }
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            color="warning"
            checked={isStagingEnv}
            onChange={handleChange}
          />
        }
        label={
          <Typography variant="body2" color="grey.400">
            {label}
          </Typography>
        }
      />
    </Box>
  );
}
