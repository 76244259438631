import React, {useState} from "react";

import {Vector2} from "three";

import {Box, Typography, Button} from "@mui/material";

import SelectorMarker from "Features/viewer3D/components/SelectorMarker";
import SelectorWorksiteMarker from "Features/worksites/components/SelectorWorksiteMarker";

export default function SectionMarkersBase({scene, onSave}) {
  console.log(scene);
  // strings

  const descriptionString =
    "Update offsets linking scene markers to worksite markers. Z components are not taken into account.";

  const marker1String = "1";
  const marker2String = "2";
  const buttonString = "Update offset";

  // state

  const [mS1, setmS1] = useState(null);
  const [mW1, setmW1] = useState(null);
  const [mS2, setmS2] = useState(null);
  const [mW2, setmW2] = useState(null);

  // helpers - delta

  const offsetP = {x: mS1?.x - mW1?.x, y: mS1?.y - mW1?.y, z: mS1?.z - mW1?.z};

  const abS = new Vector2(mS2?.x - mS1?.x, -mS2?.z + mS1?.z);
  const abW = new Vector2(mW2?.x - mW1?.x, -mW2?.z + mW1?.z);
  const angleS = abS.angle();
  const angleW = abW.angle();
  const offsetR = ((angleS - angleW) * 180) / Math.PI;

  // helpers - string

  let deltaString = "-";
  if (typeof offsetP.x === "number") {
    deltaString = `Δx = ${offsetP.x.toFixed(3)}m, Δy = ${-offsetP.z.toFixed(
      3
    )}m,  ΔNorth = ${offsetR.toFixed(2)}°`;
  }

  // handlers

  function handleUpdateClick() {
    onSave({offsetP, offsetR});
  }

  return (
    <Box sx={{p: 1, bgcolor: "background.default"}}>
      <Typography variant="body2">{descriptionString}</Typography>
      <Typography variant="body2" sx={{mt: 1}}>
        {deltaString}
      </Typography>

      <Box sx={{width: 1, mt: 2}}>
        <Box sx={{display: "flex", width: 1, alignItems: "center"}}>
          <Typography variant="body2" sx={{mr: 1}}>
            <b>{marker1String}</b>
          </Typography>
          <SelectorMarker marker={mS1} onChange={(m) => setmS1(m)} />
          <Box sx={{mr: 1}} />
          <SelectorWorksiteMarker
            worksite={scene?.worksite}
            marker={mW1}
            onChange={(m) => setmW1(m)}
          />
        </Box>

        <Box sx={{display: "flex", width: 1, alignItems: "center", mt: 1}}>
          <Typography variant="body2" sx={{mr: 1}}>
            <b>{marker2String}</b>
          </Typography>
          <SelectorMarker marker={mS2} onChange={(m) => setmS2(m)} />
          <Box sx={{mr: 1}} />
          <SelectorWorksiteMarker
            worksite={scene?.worksite}
            marker={mW2}
            onChange={(m) => setmW2(m)}
          />
        </Box>
      </Box>
      <Box sx={{width: 1, display: "flex", justifyContent: "flex-end", mt: 1}}>
        <Button onClick={handleUpdateClick} variant="outlined" size="small">
          {buttonString}
        </Button>
      </Box>
    </Box>
  );
}
