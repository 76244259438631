import {useState} from "react";
import {useDispatch} from "react-redux";

import {Box, Typography, IconButton, Paper} from "@mui/material";
import {Close, Edit, ExpandLess, ExpandMore} from "@mui/icons-material";

import {
  setOpenSectionIssueDetailInOutlet,
  setSelectedIssueId,
} from "Features/issues/issuesSlice";
import Stretch from "Features/ui/components/Stretch";
import ContentEditor from "Features/notes/components/SceneNotesEditor/components/ContentEditor";

export default function SectionIssueInOutlet({
  caplaEditor,
  issue,
  onEditClick,
}) {
  const dispatch = useDispatch();

  // state

  const [expand, setExpand] = useState(false);

  // helper

  const contentIsVoid =
    issue?.content?.length === 1 &&
    issue?.content[0].children[0]?.text?.length === 0;

  // handlers

  function handleClose() {
    dispatch(setSelectedIssueId(null));
    dispatch(setOpenSectionIssueDetailInOutlet(false));
  }

  function handleEdit() {
    onEditClick();
  }
  return (
    <Box sx={{width: 1, display: "flex", flexDirection: "column"}}>
      <Box sx={{height: "60px"}} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: 1,
          p: 1,
          mb: 2,
        }}
      >
        <Typography sx={{fontWeight: "bold"}}>{issue.title}</Typography>
        <Box sx={{display: "flex", alignItems: "center", "&>*": {mr: 1}}}>
          <IconButton onClick={handleEdit} size="small">
            {<Edit fontSize="small" />}
          </IconButton>
          <IconButton onClick={handleClose} size="small">
            {<Close fontSize="small" />}
          </IconButton>
        </Box>
      </Box>

      <Stretch>
        <Box sx={{width: 1, height: 1, overflow: "auto"}}>
          {issue?.imageUrl && (
            <Box sx={{px: 4}}>
              <Paper
                elevation={12}
                sx={{width: 1, position: "relative"}}
                square
              >
                <IconButton
                  size="small"
                  sx={{position: "absolute", top: 0, right: 0}}
                  onClick={() => {
                    setExpand((expand) => !expand);
                  }}
                >
                  {expand ? (
                    <ExpandLess size="small" />
                  ) : (
                    <ExpandMore size="small" />
                  )}
                </IconButton>
                <img
                  src={issue.imageUrl}
                  width="100%"
                  height={expand ? "auto" : 400}
                  style={{objectFit: "cover"}}
                />
              </Paper>
            </Box>
          )}

          {issue?.content && !contentIsVoid && (
            <Box
              sx={{
                mt: 4,
                px: 2,
                width: 1,
                minHeight: 200,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ContentEditor
                initialValue={issue.content}
                listingId={issue?.listing}
                //accessToken={accessToken}
                //onSaveClick={handleIssueSave}
                //onChange={handleContentChange}
                caplaEditor={caplaEditor}
                isNew={false}
                editing={false}
                showLinkedElements={false}
              />
            </Box>
          )}
        </Box>
      </Stretch>
    </Box>
  );
}
