// import React, {useState, useEffect} from "react";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

import {Box, Typography, IconButton} from "@mui/material";
import {Stop, Close} from "@mui/icons-material";

// import FieldSection from "Features/kvform/components/FieldSection";
import ListItemAddHostedType from "./ListItemAddHostedType";
import sortByNum from "../utils/sortByNum";

export default function FieldItemHostedTypes({
  scene,
  item,
  // value,
  onChange,
  locked,
}) {
  // strings

  const hostedTypesS = "Hôtes (Types)";

  // const

  // const sizeKey = 8;

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);

  // helpers

  const hostedTypesIds = item.hostedTypes ? item.hostedTypes : [];
  const hostedTypes = elementTypes.filter((t) => hostedTypesIds.includes(t.id));

  // handlers

  function handleAddHostedType(type) {
    const prevHostedTypes = item.hostedTypes ? item.hostedTypes : [];
    const exists = Boolean(prevHostedTypes.find((id) => id === type.id));
    let newHostedTypes = [...prevHostedTypes];
    if (!exists) newHostedTypes.push(type.id);
    onChange(newHostedTypes);
  }

  function handleRemoveType(typeId) {
    const prevHostedTypes = item.hostedTypes ? item.hostedTypes : [];
    const newHostedTypes = prevHostedTypes.filter((id) => id !== typeId);
    onChange(newHostedTypes);
  }

  return (
    <Box sx={{width: 1}}>
      <Box sx={{width: 1, pt: 0.5, px: 1}}>
        <Typography sx={{fontSize: 13, color: "text.secondary"}}>
          {hostedTypesS}
        </Typography>
      </Box>
      {!locked && (
        <Box>
          {hostedTypes.map((t) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 0.5,
              }}
            >
              <Box sx={{display: "flex", alignItems: "center", color: t.color}}>
                <Stop color="inherit" fontSize="small" />
                <Typography variant="body2" color="text.primary" sx={{ml: 1}}>
                  {t.name}
                </Typography>
              </Box>
              <IconButton
                size="small"
                sx={{height: 24}}
                onClick={() => handleRemoveType(t.id)}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
      {!locked && (
        <ListItemAddHostedType scene={scene} onAdd={handleAddHostedType} />
      )}
      {locked && (
        <Box>
          {hostedTypes
            .sort((a, b) => sortByNum(a.num, b.num))
            .map((t) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",

                  p: 0.25,
                  pl: 1,
                }}
              >
                <Box
                  sx={{display: "flex", alignItems: "center", color: t.color}}
                >
                  <Stop color="inherit" fontSize="small" />
                  <Typography sx={{mx: 1, fontSize: 13}} color="text.primary">
                    {t.num}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    {t.name}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
}
