import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {PointerLockControls} from "three/examples/jsm/controls/PointerLockControls.js";

import {MOUSE, TOUCH, Vector2, Vector3, Object3D} from "three";

import {
  setCam2Height,
  setWalkMode,
  setCam2Polar,
} from "Features/viewer3D/viewer3DSlice";

import debounce from "lodash.debounce";

// import CameraControls from "camera-controls";

// const subsetOfTHREE = {
//   MOUSE: MOUSE,
//   Vector2: Vector2,
//   Vector3: Vector3,
//   Vector4: Vector4,
//   Quaternion: Quaternion,
//   Matrix4: Matrix4,
//   Spherical: Spherical,
//   Box3: Box3,
//   Sphere: Sphere,
//   Raycaster: Raycaster,
//   MathUtils: {
//     DEG2RAD: MathUtils.DEG2RAD,
//     clamp: MathUtils.clamp,
//   },
// };

export default class Controls {
  userAction; // "ORBIT", "PAN"

  constructor({editor, onCameraChange}) {
    this.editor = editor;
    this.onCameraChange = onCameraChange;

    // mode
    this.activeMode = "ORBIT"; // ORBIT, LOCK, ORBIT2, LOCK2 (2 = multiviews)

    // lock
    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.canJump = false;
    this.moveUp = false;
    this.moveDown = false;
    this.canFly = false;
    this.verticalStep = 0.2; // move every 20cm.
    this.velocityRate = 0.2; // use to config the speed

    this.prevTime = performance.now();
    this.velocity = new Vector3();
    this.direction = new Vector3();
    this.vertex = new Vector3();

    this.keyPressed = {};

    this.walkHeight = 0;

    this.cam1deltaY = 0.2; // delta above cam2 (eyes)
    this.eyesHeight = 1.6; // eyes of cam2 above the floor.
  }

  initControls = (rendererElement, el1, el2) => {
    //CameraControls.install({THREE: subsetOfTHREE});

    const camera = this.editor.cameras.activeCamera;

    // Views

    this.orbitControls1 =
      el1 && new OrbitControls(this.editor.cameras.camera1, el1);
    this.orbitControls1.minPolarAngle = 0;
    this.orbitControls1.maxPolarAngle = 0;
    this.orbitControls1.minAzimuthAngle = 0;
    this.orbitControls1.maxAzimuthAngle = 0;
    this.orbitControls1.target.copy(new Vector3(0, -1000, 0));
    this.orbitControls1.mouseButtons = {
      MIDDLE: MOUSE.ROTATE,
      LEFT: MOUSE.PAN,
    };
    this.orbitControls1.touches.ONE = TOUCH.PAN;
    this.orbitControls1.touches.TWO = TOUCH.DOLLY_ROTATE;
    this.orbitControls1.update();

    this.orbitControls2 =
      el2 && new OrbitControls(this.editor.cameras.camera2, el2);
    //this.orbitControls2.maxDistance = 1000;
    this.orbitControls2.mouseButtons = {
      MIDDLE: MOUSE.ROTATE,
      LEFT: MOUSE.PAN,
    };
    this.orbitControls2.update();

    this.orbitControls2.addEventListener(
      "change",
      debounce(() => {
        console.log("CAMERA2 CONTROL CHANGE");
        const height = this.editor.cameras.camera2.position.y;
        this.editor.onCam2HeightChange(height);
        // horiontal cut
        this.editor.clipper.setHorizontalClippingPlane(
          height - this.eyesHeight + 1
        );

        if (this.editor.multiviews.cutCam1)
          this.editor.cameras.camera1.position.setY(
            height + this.cam1deltaY + 10.73
          );

        const angle = this.getCamPolarAngle(this.editor.cameras.camera2);
        this.editor.dispatch(setCam2Polar(angle));
      }, 30)
    );

    // Orbit Controls

    this.orbitControls = new OrbitControls(camera, rendererElement);

    //this.orbitControls.maxDistance = 1000;
    this.orbitControls.enableDamping = false;
    this.orbitControls.target.set(0, 0, 0);
    this.orbitControls.mouseButtons = {
      MIDDLE: MOUSE.ROTATE,
      //RIGHT: MOUSE.PAN,
      LEFT: MOUSE.PAN,
    };
    //this.orbitControls.touches.ONE = TOUCH.DOLLY_ROTATE; BUG => no controls... should desactivate control 1 ?
    //this.orbitControls.touches.TWO = TOUCH.PAN;

    this.orbitControls.addEventListener("change", () => {
      this.onCameraChange();
    });

    this.orbitControls.update();

    // Orbit Controls Enhanced

    //this.orbitControls0 = new CameraControls(camera, rendererElement);

    // Pointerlock Controls

    this.lockControls = new PointerLockControls(
      this.editor.cameras.camera,
      rendererElement
    );

    this.lockControls2 = new PointerLockControls(
      this.editor.cameras.camera2,
      this.editor.multiviews.el2
    );

    this.lockControls?.addEventListener("unlock", () => {
      if (this.activeMode === "LOCK") {
        console.log("UNLOCK event - lockControl");
        const direction = new Vector3();
        const target = new Vector3();
        this.lockControls.getDirection(direction);
        target.copy(this.editor.cameras.activeCamera.position);
        target.add(direction.normalize().multiplyScalar(2)); // target = 2m away from the camera.
        this.orbitControls.target.set(target.x, target.y, target.z);
        this.switchTo("ORBIT");
        this.editor.dispatch(setWalkMode(false));
      }
    });

    this.lockControls2.addEventListener("unlock", () => {
      this.editor.sceneEditor.restoreState(); // end of optim
      if (this.activeMode === "LOCK2") {
        console.log("UNLOCK event - lockControl2");
        const direction = new Vector3();
        const target = new Vector3();
        this.lockControls2.getDirection(direction);
        target.copy(this.editor.cameras.camera2.position);
        target.add(direction.normalize().multiplyScalar(2)); // target = 2m away from the camera.
        this.orbitControls2.target.set(target.x, target.y, target.z);
        this.switchTo("ORBIT2");
        this.editor.dispatch(setWalkMode(false));
      }
    });

    this.lockControls?.addEventListener("change", (e) => {
      this.onCameraChange();
    });
    this.lockControls2.addEventListener(
      "change",
      debounce(() => {
        //const cam2Height = this.editor.cameras.camera2.position.y;
        //this.editor.onCam2HeightChange(cam2Height);

        const angle = this.getCamPolarAngle(this.editor.cameras.camera2);
        this.editor.dispatch(setCam2Polar(angle));

        // this.editor.cameras.camera2.updateProjectionMatrix();
        // const dir = new Vector3();
        // this.lockControls2.getDirection(dir);
        // console.log(dir.x);
      }, 10)
    );

    // active controls

    this.activeControls = this.orbitControls;
  };

  setCam2ToHoriz() {
    const cam = this.orbitControls2.object;
    const direction = new Vector3();
    const position = new Vector3();
    position.copy(cam.position);
    cam.getWorldDirection(direction);
    const target = new Vector3(direction.x, 0, direction.z).normalize();
    position.add(target.multiplyScalar(3));
    this.orbitControls2.target.set(position.x, position.y, position.z);
    this.orbitControls2.update();
    console.log(
      "setCam2ToHoriz",
      (this.orbitControls.getPolarAngle() / Math.PI) * 180
    );
  }

  resetControls() {
    this.orbitControls.target.set(0, 0, 0);
    this.orbitControls1.target.set(0, -1000, 0);
    this.orbitControls2.target.set(0, 0, 0);
    this.orbitControls.update();
    this.orbitControls1.update();
    this.orbitControls2.update();
  }
  setOrbitControlsTarget(target) {
    this.orbitControls.target.set(target.x, target.y, target.z);
    this.orbitControls.update();
  }

  // lock() {
  //   this.lockControls.lock();
  //   this.lockControls2.lock();
  // }

  // unlock() {
  //   this.lockControls.unlock();
  //   this.lockControls2.unlock();
  // }

  switchTo(mode) {
    console.log("switch to mode", mode);
    try {
      if (mode === "ORBIT") {
        //this.lockControls.unlock();
        if (this.orbitControls) this.orbitControls.enabled = true;
        this.activeControls = this.orbitControls;
        this.activeMode = "ORBIT";
      } else if (mode === "LOCK") {
        this.lockControls?.lock();
        this.orbitControls.enabled = false; // used to prevent scrolling event for example.
        this.activeControls = this.lockControls;
        this.activeMode = "LOCK";
        this.walkHeight = this.lockControls?.getObject().position.y.toFixed(3);
      } else if (mode === "ORBIT2") {
        //this.lockControls2?.unlock();
        if (this.orbitControls2) this.orbitControls2.enabled = true;
        this.orbitControls2.object = this.editor.cameras.camera2; // /!\  object is removed in Lock2 controls,
        this.activeControls = this.orbitControls2;
        this.activeMode = "ORBIT2";
        this.editor.cameras.activeCamera = this.editor.cameras.camera2;
      } else if (mode === "ORBIT1") {
        //this.lockControls2?.unlock();
        if (this.orbitControls1) this.orbitControls1.enabled = true;
        this.orbitControls1.object = this.editor.cameras.camera1; // /!\  object is removed in Lock2 controls,
        this.activeControls = this.orbitControls1;
        this.activeMode = "ORBIT1";
        this.editor.cameras.activeCamera = this.editor.cameras.camera1;
      } else if (mode === "LOCK2") {
        this.orbitControls2.object = new Object3D(); // /!\ if camera2, the MouseEvent.mouseMovement in lockControls is kept to 0...
        this.lockControls2.lock();
        this.activeControls = this.lockControls2;
        this.activeMode = "LOCK2";
        this.walkHeight = this.lockControls2.getObject().position.y.toFixed(3);
      }
    } catch (e) {
      console.log(e);
    }
  }

  updateLockControls(lockControls, time) {
    this.editor.raycaster.ray.origin.copy(lockControls.getObject().position);
    this.editor.raycaster.ray.origin.y -= 10;

    const intersections = this.editor.raycaster.intersectObjects(
      this.editor.objects,
      false
    );

    const onObject = intersections.length > 0;

    const delta = (time - this.prevTime) / 1000;

    this.velocity.x -= this.velocity.x * 10.0 * delta;
    this.velocity.z -= this.velocity.z * 10.0 * delta;

    this.velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

    this.direction.z = Number(this.moveForward) - Number(this.moveBackward);
    this.direction.x = Number(this.moveRight) - Number(this.moveLeft);
    this.direction.normalize(); // this ensures consistent movements in all directions

    this.direction.y = Number(this.moveUp) - Number(this.moveDown);

    if (this.moveForward || this.moveBackward) {
      this.velocity.z -= this.direction.z * 400.0 * delta * this.velocityRate;

      // vertical direction based on camera orientation

      const cameraDirection = new Vector3();
      lockControls?.getObject().getWorldDirection(cameraDirection);
      const deltaY = cameraDirection.y;

      if (Math.abs(deltaY) > 0.65) {
        const deltaH = this.verticalStep * deltaY;
        const newY = lockControls.getObject().position.y + deltaH;

        lockControls.getObject().position.y = newY;

        // TO DO : conditional for lock2
        this.editor.dispatch(setCam2Height(newY));
        // horiontal cut
        this.editor.clipper.setHorizontalClippingPlane(newY - this.eyesHeight);
        if (this.editor.multiviews.cutCam1)
          this.editor.cameras.camera1.position.setY(
            newY + this.cam1deltaY + 10.73
          );
      }
    }

    if (this.moveLeft || this.moveRight) {
      this.velocity.x -= this.direction.x * 400.0 * delta * this.velocityRate;
    }

    if (this.moveUp || this.moveDown) {
      this.canFly = true;
    }

    if (onObject === true) {
      this.velocity.y = Math.max(0, this.velocity.y);
      this.canJump = true;
    }

    const distanceRight = -this.velocity.x * delta;
    const distanceForward = -this.velocity.z * delta;

    lockControls.moveRight(distanceRight);
    lockControls.moveForward(distanceForward);

    if (this.canFly) {
      lockControls.getObject().position.y +=
        this.direction.y * this.verticalStep;
      // update message
      this.walkHeight = lockControls.getObject().position.y.toFixed(3);
      //
      this.canFly = false;
    }
  }

  update(time) {
    // views

    if (this.editor.multiviews.isEnabled) {
      this.orbitControls1?.update();
      this.orbitControls2?.update();
    }

    // orbit
    if (this.activeMode === "ORBIT" && !this.editor.multiviews.isEnabled) {
      this.orbitControls?.update();
      //this.orbitControls0?.update(time);
    }

    // lock

    if (this.activeMode === "LOCK" && this.lockControls?.isLocked === true) {
      this.updateLockControls(this.lockControls, time);
    }
    if (this.activeMode === "LOCK2" && this.lockControls2.isLocked === true) {
      this.updateLockControls(this.lockControls2, time);
    }
    this.prevTime = time;
  }

  onKeyDown = (event) => {
    event.stopPropagation();
    event.preventDefault();

    switch (event.code) {
      case "ShiftLeft":
      case "ShiftRight":
        this.keyPressed["Shift"] = true;
        break;

      case "ArrowUp":
      case "KeyW":
        if (this.keyPressed["Shift"]) {
          this.moveUp = true;
        } else {
          this.moveForward = true;
        }

        break;

      case "ArrowLeft":
      case "KeyA":
        this.moveLeft = true;
        break;

      case "ArrowDown":
      case "KeyS":
        if (this.keyPressed["Shift"]) {
          this.moveDown = true;
        } else {
          this.moveBackward = true;
        }

        break;

      case "ArrowRight":
      case "KeyD":
        this.moveRight = true;
        break;

      case "Space":
        if (this.canJump === true) this.velocity.y += 350;
        this.canJump = false;
        break;
    }
  };

  onKeyUp = (event) => {
    event.stopPropagation();
    event.preventDefault();
    switch (event.code) {
      case "ArrowUp":
      case "KeyW":
        if (this.keyPressed["Shift"]) {
          this.moveUp = false;
        } else {
          this.moveForward = false;
        }

        break;

      case "ArrowLeft":
      case "KeyA":
        this.moveLeft = false;
        break;

      case "ArrowDown":
      case "KeyS":
        if (this.keyPressed["Shift"]) {
          this.moveDown = false;
        } else {
          this.moveBackward = false;
        }

        break;

      case "ArrowRight":
      case "KeyD":
        this.moveRight = false;
        break;

      case "ShiftLeft":
      case "ShiftRight":
        this.keyPressed["Shift"] = false;
        break;
    }
  };

  getCamPolarAngle(cam) {
    const direction = new Vector3();
    cam.getWorldDirection(direction);
    const planeProjection = new Vector2(direction.x, direction.z);
    const distance = planeProjection.length();
    const height = direction.y;
    const tan = distance / height;
    const angleRad = Math.atan(tan);
    const angleDeg = (angleRad * 180) / Math.PI;
    if (angleDeg >= 0) {
      return 90 - angleDeg;
    } else {
      return -angleDeg - 90;
    }
  }

  /*
   * Enable / disable
   */

  enable() {
    this.activeControls.enabled = true;
  }

  disable() {
    this.activeControls.enabled = false;
  }
}
