import React from "react";

import {ImageList, ImageListItem, Paper} from "@mui/material";
import {useSelector} from "react-redux";

export default function ImageListPictures() {
  const pictures = useSelector((s) => s.pictures.items);

  return (
    <ImageList sx={{width: 1, px: 1}} cols={2} gap={8}>
      {pictures.map((item) => {
        const url = item.imageUrl;
        const saved = item.id;
        return (
          <Paper key={url} elevation={saved ? 1 : 12} sx={{minWidth: 0}}>
            <ImageListItem
              sx={{cursor: "pointer", bgcolor: "common.white", minWidth: 0}}
            >
              <img src={`${url}`} loading="lazy" style={{height: 200}} />
            </ImageListItem>
          </Paper>
        );
      })}
    </ImageList>
  );
}
