import {useSelector} from "react-redux";

import {Box, Button} from "@mui/material";
import {BuildCircle as Repair} from "@mui/icons-material";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";
import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";

export default function ButtonRemoveParents({scene}) {
  // strings

  const label = "Remove parents";

  // data

  const group = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  const sceneElementTypes = useElementTypesBySceneProxy(scene);
  const selectedGroup = useSelectedElementTypesGroup(scene);

  // helpers

  let elementTypes;
  if (selectedGroup) elementTypes = selectedGroup.elementTypes;
  else elementTypes = sceneElementTypes.filter((t) => t.group === group);
  const updateElementTypesGroup = useUpdateElementTypesGroup();

  // handlers

  async function handleClick() {
    const editedTypes = [];
    elementTypes.forEach((t) => {
      const editedType = {...t};
      if (editedType.parent) delete editedType.parent;
      editedTypes.push(editedType);
    });
    await updateElementTypesGroup({
      ...selectedGroup,
      elementTypes: editedTypes,
    });
  }

  return (
    <Box>
      <Button
        startIcon={<Repair />}
        size="small"
        onClick={handleClick}
        color="inherit"
        disabled={!group}
      >
        {label}
      </Button>
    </Box>
  );
}
