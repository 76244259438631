import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

import {Box, Button} from "@mui/material";
import {Download} from "@mui/icons-material";

import sanitizeString from "Utils/sanitizeString";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";

import articlesToExcelArticlesQties from "../utils/articlesToExcelArticlesQties";
import useElementTypesGroupsProxyBySceneWithElementTypes from "Features/elementTypes/hooks/useElementTypesGroupsProxyBySceneWithElementTypes";

export default function ButtonExportQtiesInExcel({
  scene,
  articles,
  articlesQtiesMapBySector,
  sectors,
}) {
  // string

  const exportS = "Export Excel";

  // data

  const articlesGroup = useSelectedArticlesGroup();
  const typeGroups = useElementTypesGroupsProxyBySceneWithElementTypes(scene, {
    filterByScope: true,
  });

  // handlers

  async function handleClick() {
    //
    const articlesQtiesForExcel = articlesToExcelArticlesQties(
      articles,
      articlesQtiesMapBySector,
      sectors,
      typeGroups
    );
    //
    let fileName = "articles.xlsx";
    if (articlesGroup) {
      fileName = sanitizeString(articlesGroup.name) + "_articles.xlsx";
      const wb = await createExcelWorkbook({
        template: "ARTICLES_WITH_QTIES",
        data: {
          articlesQtiesForExcel,
          sectors,
        },
      });
      downloadExcelFileFromWorkbook(wb, fileName);
    }
  }
  return (
    <Box sx={{p: 1}}>
      <Button
        fullWidth
        onClick={handleClick}
        variant="contained"
        color="secondary"
        startIcon={<Download />}
      >
        {exportS}
      </Button>
    </Box>
  );
}
