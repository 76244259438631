import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Chip} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";

import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setOpenSections} from "Features/ui/uiSlice";

export default function ButtonFocusOnExtractedText({
  editorPdf,
  pdfExtractedText,
}) {
  const dispatch = useDispatch();
  // strings

  const zoomS = "Documentation";

  // helper

  // const text = pdfExtractedText?.text;
  const pageNumber = pdfExtractedText?.pageNumber;
  const pdfModelId = pdfExtractedText?.pdfModelId;
  const pageRotation = pdfExtractedText?.pageRotation;
  const pageWidth = pdfExtractedText?.pageWidth;
  const pageHeight = pdfExtractedText?.pageHeight;
  const rects = pdfExtractedText?.rects;

  // data

  const models = useSelector((state) => state.viewer3D.models);
  const pdfModel = models.find((m) => m.id === pdfModelId);
  const os = useSelector((s) => s.ui.openSections);
  const pdfModelIdLoaded = useSelector((s) => s.pdf.pdfModelIdLoaded);

  // state

  // effect

  useEffect(() => {
    if (pdfModelIdLoaded && pdfModelIdLoaded === pdfModelId && rects?.[0]) {
      let {x1, y1, x2, y2} = rects[0];
      if (pageWidth && (pageRotation === 0 || pageRotation === 1)) {
        x1 = 0;
        x2 = pageWidth;
      }
      if (pageHeight && (pageRotation === 1 || pageRotation === 2)) {
        y1 = 0;
        y2 = pageHeight;
      }
      const viewportCoords = {x1, x2, y1, y2};
      const context = {pageNumber, pageRotation, viewportCoords};
      editorPdf?.setPdfContext(context);
      // highlight text
      editorPdf?.sceneEditor.removeHighlightedExtractedText();
      editorPdf?.sceneEditor.highlightExtractedText(pdfExtractedText);
    }
  }, [pdfModelId, pageNumber, pdfModelIdLoaded, rects?.[0]]);

  // helper

  // const textInfo = `${pdfModel?.name} • p${pageNumber} `;

  // handlers

  function handleFocusClick() {
    console.log("pdfExtractedText", pdfExtractedText);
    // os
    if (!os.fixedViewersBox) {
      dispatch(
        setOpenSections({...os, fixedViewersBox: true, viewer3D: false})
      );
    }
    dispatch(setSelectedPdfModelId(pdfModel?.id));
    // context
    if (pdfModelIdLoaded === pdfModel?.id && rects[0]) {
      let {x1, y1, x2, y2} = rects[0];
      if (pageWidth && (pageRotation === 0 || pageRotation === 2)) {
        x1 = 0;
        x2 = pageWidth;
      }
      if (pageHeight && (pageRotation === 1 || pageRotation === 3)) {
        y1 = 0;
        y2 = pageHeight;
      }
      const viewportCoords = {x1, x2, y1, y2};
      const context = {pageNumber, pageRotation, viewportCoords};
      editorPdf?.setPdfContext(context);
      // highlight
      editorPdf?.sceneEditor.removeHighlightedExtractedText();
      editorPdf?.sceneEditor.highlightExtractedText(pdfExtractedText);
    }
  }

  return (
    <Chip
      size="small"
      icon={<Focus />}
      label={zoomS}
      onClick={handleFocusClick}
    />
  );
}
