import {useSelector} from "react-redux";

import useArticlesGroups from "./useArticlesGroups";
import sortByNum from "Utils/sortByNum";
import articlesWithStyleToArticlesWithTempNum from "../utils/articlesWithStyleToArticlesWithTempNum";

export default function useArticlesByScene(scene, options) {
  //
  const filterByArticlesGroupId = options?.filterByArticlesGroupId;
  //
  const articlesGroup = useArticlesGroups(scene?.id);
  const articlesGroupsMap = useSelector((s) => s.articles.articlesGroupsMap);
  const articlesMap = useSelector((s) => s.articles.articlesMap);
  //
  let articles = Object.values(articlesMap).map((article) => {
    let fullArticle = {...article};
    // updates
    const updates = fullArticle?.updates ?? {};
    fullArticle = {...fullArticle, ...updates};
    //
    return fullArticle;
  });
  //

  if (filterByArticlesGroupId) {
    articles = articles.filter(
      (article) => article.groupId === filterByArticlesGroupId
    );
  }

  // add tempNum

  articles = articlesWithStyleToArticlesWithTempNum(articles);

  return articles;
}
