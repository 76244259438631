import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Menu, MenuItem, Typography, IconButton} from "@mui/material";
import {MoreHoriz as More} from "@mui/icons-material";

import useSelectedZone from "../hooks/useSelectedZone";
import useUpdatePdfZone from "../hooks/useUpdatePdfZone";

import useAccessToken from "Features/auth/useAccessToken";
import {deleteModel, updateModel} from "Features/viewer3D/viewer3DSlice";
import {deleteRemoteModel} from "Features/scenes/scenesSlice";
import {uploadFileService} from "Features/files/services";
import DeleteDialog from "Features/ui/components/DeleteDialog";
import {urlToFile} from "Features/files/utils";

export default function ButtonMoreActionsZone({caplaEditor, scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const repairTransformationS = "Repair 3D";
  const deleteS = "Delete";
  const updatePreviewS = "Update preview";
  const repairNullImageModelS = "Repair null image model";

  // data

  const selectedZone = useSelectedZone(scene);
  const models = useSelector((s) => s.viewer3D.models);
  const updatePdfZone = useUpdatePdfZone();

  console.log(
    selectedZone,
    "image models",
    models.filter((m) => m.type === "IMAGE")
  );

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // helper - zone image model

  const zoneImageModel = models.find(
    (m) => m.id === selectedZone?.imageModelId
  );

  console.log("zoneImageModel", zoneImageModel);

  // helpers - handlers - delete

  function handleDelete() {
    setOpenDeleteDialog(true);
    setAnchorEl(null);
  }

  async function handleDeleteConfirm() {
    // object in 3D
    if (zoneImageModel?.id) {
      const zoneImageModelE = caplaEditor?.editor3d?.getEntity(zoneImageModel.id);
      zoneImageModelE?.delete();
    }

    // remote
    if (zoneImageModel?.id && zoneImageModel?.remoteId) {
      dispatch(
        deleteRemoteModel({
          accessToken,
          modelId: zoneImageModel.id,
          modelRemoteId: zoneImageModel.remoteId,
        })
      );
    }

    // annotation
    console.log("delete47");
    caplaEditor?.editorPdf?.annotationsManager.setIsDeletingZone(true);
    caplaEditor?.editorPdf?.annotationsManager.deleteAnnotation(selectedZone.id);

    // state
    await dispatch(deleteModel({model: zoneImageModel}));

    // close dialog
    setOpenDeleteDialog(false);
    setAnchorEl(null);
  }

  function handleDeleteCancel() {
    setOpenDeleteDialog(false);
  }

  // helpers - handlers - repair

  function handleRepair() {
    setAnchorEl(null);
    //
    const imageModel = models.find((m) => m.id === selectedZone.imageModelId);
    console.log("zoneZ", selectedZone, imageModel);
    //
    updatePdfZone(selectedZone);
  }

  // helpers - handlers - repair

  function handleRepairNullImageModel() {
    // zone.imageModelId = null (when creating heavy zone ?)
    setAnchorEl(null);
    //
    const imageModel = models.find(
      (m) => m.fromModel?.zoneId === selectedZone.id
    );
    if (imageModel) {
      const updatedZone = {...selectedZone, imageModelId: imageModel.id};
      updatePdfZone(updatedZone);
    }
  }

  // helpers - handlers - update image preview

  async function updatePreview() {
    setAnchorEl(null);
    //
    const url = caplaEditor?.editor3d?.annotator.stage.toDataURL();
    const options = {type: "image/png"};
    const blobName = "preview_" + nanoid() + ".png";
    const imageFile = await urlToFile(url, blobName, options);
    const previewUrl = await uploadFileService({
      accessToken,
      file: imageFile,
      sceneId: scene?.id,
      container: "scene-files",
      blobName,
      blobContentType: "image/png",
    });
    const newZoneImageModel = {...zoneImageModel, previewUrl};
    dispatch(updateModel({updatedModel: newZoneImageModel}));
  }

  // helpers - tools

  const tools = [
    {
      id: "REPAIR_TRANSFORMATION",
      label: repairTransformationS,
      handler: handleRepair,
    },
    {
      id: "REPAIR_NULL_IMAGE_MODEL",
      label: repairNullImageModelS,
      handler: handleRepairNullImageModel,
      hide: Boolean(selectedZone?.imageModelId),
    },
    {
      id: "DELETE",
      label: deleteS,
      handler: handleDelete,
    },
    {
      id: "UPDATE_PREVIEW",
      label: updatePreviewS,
      handler: updatePreview,
    },
  ];

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <More fontSize="small" />
      </IconButton>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools
          .filter((tool) => !tool.hide)
          .map((tool) => {
            return (
              <MenuItem key={tool.id} onClick={tool.handler}>
                <Typography variant="body2">{tool.label}</Typography>
              </MenuItem>
            );
          })}
      </Menu>

      <DeleteDialog
        open={openDeleteDialog}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        ressource={"zone"}
      />
    </>
  );
}
