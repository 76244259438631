import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import useUpdateSceneWithElementTypes from "../hooks/useUpdateSceneWithElementTypes";
import {updateElementTypesGroup} from "../elementTypesSlice";
import useElementTypesGroupsProxyBySceneWithElementTypes from "../hooks/useElementTypesGroupsProxyBySceneWithElementTypes";
import excelElementTypesToElementTypes from "../utils/excelElementTypesToElementTypes";
import useSelectedElementTypesGroup from "../hooks/useSelectedElementTypesGroup";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
  Button,
} from "@mui/material";

import CardFileSelector from "Features/files/components/CardFileSelector";
import getElementTypesFromFile from "Features/excel/utils/getElementTypesFromFile";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import {createElementTypesGroup} from "../elementTypesSlice";
import useAccessToken from "Features/auth/useAccessToken";
import updateElementTypesWithElementTypes from "../utils/updateElementTypesWithElementTypes";
import {setUpdatedAt} from "Features/relations/relationsSlice";
import getExcelRowsFromFile from "Features/excel/utils/getExcelRowsFromFile";
import getUpdatedElementTypesGroupFromExcelRows from "../utils/getUpdatedElementTypesGroupFromExcelRows";

export default function DialogImportElementTypesFromExcel({
  open,
  onClose,
  scene,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Importez des types d'éléments";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";
  const buttonName = "Choisir un fichier";

  // data

  const selectedGroup = useSelectedElementTypesGroup(scene);

  // state

  const [newGroup, setNewGroup] = useState(null);

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // handlers

  async function handleFileChange(file) {
    setFile(file);
    setLoading(true);
    const excelRows = await getExcelRowsFromFile(file, 4);
    const newGroup = getUpdatedElementTypesGroupFromExcelRows(
      selectedGroup,
      excelRows
    );
    console.log(
      "[WARNING !] new elementTypes will be",
      newGroup?.elementTypes?.map((t) => ({style: t.style, num: t.num})),
      "oldTypes",
      selectedGroup.elementTypes
    );
    setNewGroup(newGroup);
    setLoading(false);
  }

  function handleCancelClick() {
    onClose();
  }
  async function handleSaveClick() {
    setLoading(true);

    await dispatch(
      updateElementTypesGroup({
        accessToken,
        elementTypesGroup: newGroup,
      })
    );

    dispatch(setUpdatedAt(Date.now()));

    setLoading(false);
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 1}}>
          <Box sx={{width: 1, display: "flex", justifyContent: "center", p: 2}}>
            <CardFileSelector
              file={file}
              onFileChange={handleFileChange}
              buttonName={buttonName}
              accept=".xlsx"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>{cancelS}</Button>
        <Button onClick={handleSaveClick} disabled={loading}>
          {saveS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
