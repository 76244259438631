import {Typography, IconButton, Box, Tooltip} from "@mui/material";
import {ContentCopy as Copy, BugReport} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import {fetchSharedboxService} from "../services";

import {fetchSharedRemoteSceneService} from "Features/scenes/services";

export default function CardSharedboxUrl({sharedbox, isOrder = false, scene}) {
  const accessToken = useAccessToken();

  // helpers

  const url = `/${sharedbox.id}`;
  const baseURL = process.env.REACT_APP_SHARED_BOX_BASE_URL;
  let link = `${baseURL}${url}`;
  if (isOrder)
    link = link
      .replace("shared/bimbox", "order")
      .replace("https://api.capla360.com/", "https://app.capla360.com/")
      .replace("localhost:8000", "localhost:3000");

  // strings

  const label = "Copier le lien de la sous-bimbox";
  const copyS = "Copier";

  // handlers

  function handleCopyLink() {
    navigator.clipboard.writeText(link);
  }

  function handleBugReport() {
    fetchSharedboxService({
      accessToken,
      sceneId: scene?.id,
      sharedboxId: sharedbox.id,
    });
    fetchSharedRemoteSceneService({
      shareLink: sharedbox.id,
      options: {debug: true},
    });
  }

  return (
    <Box
      sx={{
        //bgcolor: "background.default",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
        p: 1,
        //borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          bgcolor: "background.default",
          px: 1,
        }}
      >
        <Typography sx={{fontSize: 12}} color="text.secondary" noWrap>
          {label}
        </Typography>
        <Tooltip title={copyS}>
          <IconButton size="small" onClick={handleCopyLink}>
            <Copy fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <IconButton onClick={handleBugReport} size="small">
        <BugReport fontSize="small" />
      </IconButton>
    </Box>
  );
}
