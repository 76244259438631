import createExcelWorkbookFromFile from "./createExcelWorkbookFromFile";

export default async function getExcelRowsFromFile(file, rowStart) {
  const wb = await createExcelWorkbookFromFile({file});
  const worksheet = wb.worksheets[0];

  const rowCount = worksheet.rowCount;
  const rows = worksheet.getRows(rowStart, rowCount - 1).values();

  return rows;
}
