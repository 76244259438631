export default function stringifyArticleTypesArrayItem(
  item,
  elementTypesGroups,
  elementTypesByGroupIdById
) {
  if (!item) return "";
  const {groupId, typeId, filtersStr, func} = item;

  //
  const group = elementTypesGroups.find((group) => group.id === groupId);
  const elementType = elementTypesByGroupIdById[groupId]?.[typeId];
  //
  if (!groupId && filtersStr) {
    return "{{" + filtersStr + "}}";
  } else if (groupId && !typeId && filtersStr) {
    return group?.code + " {{" + filtersStr + "}}";
  } else if (groupId && typeId && filtersStr) {
    return group?.code + "." + elementType?.code + " {{" + filtersStr + "}}";
  } else if (groupId && typeId && !filtersStr) {
    return group?.code + "." + elementType?.code;
  }
}
