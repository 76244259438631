/*
 * material : {id,num,name,code,color}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useDeleteSceneMaterial(scene) {
  const dispatch = useDispatch();

  const deleteSceneMaterial = (material) => {
    if (!scene) return;
    let oldMaterials = scene?.data?.materials.filter((s) => s?.id !== material.id);
    if (!oldMaterials) oldMaterials = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, materials: [...oldMaterials]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return deleteSceneMaterial;
}
