import {useSelector, useDispatch} from "react-redux";

import {setConnectSceneOnCreation} from "../scenesSlice";

import {Box, Typography, FormControlLabel, Checkbox} from "@mui/material";

import {useIsAuthenticated} from "@azure/msal-react";

export default function CheckboxConnectNewScene() {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  // strings

  const savedDataS = "Enregistrer la bimbox";

  // data

  const connectSceneOnCreation = useSelector(
    (s) => s.scenes.connectSceneOnCreation
  );

  // helper - SaveData

  const disableSaveData = !isAuthenticated;
  const saveData = disableSaveData ? false : connectSceneOnCreation;

  // handlers

  function handleSaveChange(e) {
    const checked = e.target.checked;
    dispatch(setConnectSceneOnCreation(checked));
  }

  return (
    <Box sx={{px: 2, color: "inherit"}}>
      <FormControlLabel
        size="small"
        disabled={disableSaveData}
        control={
          <Checkbox
            size="small"
            checked={saveData}
            onChange={handleSaveChange}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: 13,
              color: "inherit",
            }}
          >
            {savedDataS}
          </Typography>
        }
      />
    </Box>
  );
}
