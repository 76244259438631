import React from "react";

import {Box, TextField, Typography} from "@mui/material";

export default function FieldsGeometryFast({dims, onChange, drawingShape}) {
  // helpers

  const show = (drawingShape, dim) => {
    switch (drawingShape) {
      case "SEGMENT": {
        return true;
      }
      case "POLYLINE": {
        return ["D1", "H"].includes(dim);
      }
      case "POLYGON": {
        return dim === "H";
      }
    }
  };
  const dimensions = [
    {
      code: "D1",
      label: "D1 (m)",
      onChange: handleD1Change,
      show: show(drawingShape, "D1"),
      value: dims.dim1 ? dims.dim1 : "",
    },
    {
      code: "D2",
      label: "D2 (m)",
      onChange: handleD2Change,
      show: show(drawingShape, "D2"),
      value: dims.dim2 ? dims.dim2 : "",
    },
    {
      code: "H",
      label: "H (m)",
      onChange: handleHChange,
      show: show(drawingShape, "H"),
      value: dims.height ? dims.height : "",
    },
  ];

  // handlers

  function handleD1Change(e) {
    let dim1 = e.target.value;
    dim1 = dim1.replace(",", ".");
    onChange({...dims, dim1});
  }
  function handleD2Change(e) {
    let dim2 = e.target.value;
    dim2 = dim2.replace(",", ".");
    onChange({...dims, dim2});
  }
  function handleHChange(e) {
    let height = e.target.value;
    height = height.replace(",", ".");
    onChange({...dims, height});
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {dimensions.map((dim) => {
        if (dim.show) {
          return (
            <Box sx={{width: "30%"}} key={dim.code}>
              <TextField
                value={dim.value}
                fullWidth
                onChange={dim.onChange}
                label={<Typography variant="body2">{dim.label}</Typography>}
                size="small"
                InputProps={{
                  sx: {
                    fontSize: (theme) => theme.typography.body2.fontSize,
                  },
                }}
              />
            </Box>
          );
        }
      })}
    </Box>
  );
}
