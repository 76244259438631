import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {
  createArticlesGroupService,
  fetchArticlesGroupsService,
  fetchArticlesGroupService,
  updateArticlesGroupService,
  deleteArticlesGroupService,
  //
  createArticleService,
  createArticlesBatchService,
  fetchArticlesService,
  updateArticleService,
  updateArticlesBatchService,
  deleteArticleService,
  deleteArticlesService,
  deleteAllArticlesService,
  //
} from "./services";

export const createArticlesGroup = createAsyncThunk(
  "articles/createArticlesGroup",
  createArticlesGroupService
);
export const createArticle = createAsyncThunk(
  "articles/createArticle",
  createArticleService
);
export const createArticlesBatch = createAsyncThunk(
  "articles/createArticlesBatch",
  createArticlesBatchService
);
//
export const fetchArticlesGroups = createAsyncThunk(
  "articles/fetchArticlesGroups",
  fetchArticlesGroupsService
);
export const fetchArticlesGroup = createAsyncThunk(
  "articles/fetchArticlesGroup",
  fetchArticlesGroupService
);
export const fetchArticles = createAsyncThunk(
  "articles/fetchArticles",
  fetchArticlesService
);
//
export const updateArticlesGroup = createAsyncThunk(
  "articles/updateArticlesGroup",
  updateArticlesGroupService
);
export const updateArticle = createAsyncThunk(
  "articles/updateArticle",
  updateArticleService
);
export const updateArticlesBatch = createAsyncThunk(
  "articles/updateArticlesBatch",
  updateArticlesBatchService
);
//
export const deleteArticlesGroup = createAsyncThunk(
  "articles/deleteArticlesGroup",
  deleteArticlesGroupService
);
export const deleteAllArticles = createAsyncThunk(
  "articles/deleteAllArticles",
  deleteAllArticlesService
);
export const deleteArticle = createAsyncThunk(
  "articles/deleteArticle",
  deleteArticleService
);
export const deleteArticles = createAsyncThunk(
  "articles/deleteArticles",
  deleteArticlesService
);

export const articlesSlice = createSlice({
  name: "articles",
  initialState: {
    //
    areFetchedArticlesGroups: false,
    areFetchedArticlesGroupMap: {}, // {groupId:true,...}

    //
    articlesMap: {},
    articlesMapProxy: {}, // articles + articles computed from templates. // TO DELETE
    articlesFromTemplateMap: {}, // {articleCode:[articles]} // TO DELETE
    //
    sortedArticlesProxyByGroupId: {}, // {groupId:[clientId,...]}
    //
    subArticlesMap: {},
    articleQtyMap: {},
    articleQtiesMapBySector: {},
    articlesGroupsMap: {},
    selectedArticleId: null,
    selectedArticleIds: [],
    selectedArticlesGroupId: null,
    autoSync: false,
    groupsViewVariant: "list", // "chips" / "lists"
    //
    articleIdTempNumMap: {}, // used to sort articles as a tree
    //
    lastUpdateAt: null, // use to trigger effect when articles are updated
  },
  reducers: {
    //
    addSubArticles: (state, action) => {
      const subArticlesMap = action.payload; // {articleId:[subArticles]}
      Object.entries(subArticlesMap).forEach(
        ([articleId, subArticlesArray]) => {
          const oldSubArticleMap = state.subArticlesMap[articleId];
          if (!oldSubArticleMap) {
            console.log("[STATE] addSubArticles", subArticlesArray);
            state.subArticlesMap[articleId] = subArticlesArray.reduce(
              (acc, subArticle) => {
                acc[subArticle.id] = subArticle;
                return acc;
              },
              {}
            );
          } else {
            subArticlesArray.forEach((subArticle) => {
              const oldSubArticle =
                state.subArticlesMap[articleId][subArticle.id];
              if (!oldSubArticle) {
                state.subArticlesMap[articleId][subArticle.id] = subArticle;
              } else {
                state.subArticlesMap[articleId][subArticle.id] = {
                  ...oldSubArticle,
                  ...subArticle,
                };
              }
            });
          }
        }
      );
    },
    //
    resetAreFetched: (state) => {
      state.areFetchedArticlesGroups = false;
      state.areFetchedArticlesGroupMap = {};
    },
    //
    setGroupsViewVariant: (state, action) => {
      state.groupsViewVariant = action.payload;
    },
    //
    setSelectedArticleId: (state, action) => {
      state.selectedArticleId = action.payload;
    },
    setSelectedArticleIds: (state, action) => {
      state.selectedArticleIds = action.payload;
    },
    setSelectedArticlesGroupId: (state, action) => {
      state.selectedArticlesGroupId = action.payload;
    },
    //
    setAutoSync: (state, action) => {
      state.autoSync = action.payload;
    },
    //
    updateArticlesGroupTemp: (state, action) => {
      const updates = action.payload;
      if (!updates) return;
      const articlesGroupId = updates.id;
      const oldArticlesGroup = state.articlesGroupsMap[articlesGroupId];
      const oldArticlesGroupUpdates = oldArticlesGroup.updates
        ? oldArticlesGroup.updates
        : {};
      const updatedArticlesGroup = {
        ...oldArticlesGroup,
        updates: {...oldArticlesGroupUpdates, ...updates},
        clientUpdatedAt: Date.now(),
      };
      console.log("[STATE] updateArticlesGroupTemp", updatedArticlesGroup);
      state.articlesGroupsMap[articlesGroupId] = updatedArticlesGroup;
      state.lastUpdateAt = Date.now();
    },
    updateArticleTemp: (state, action) => {
      const updates = action.payload;
      if (!updates) return;
      const articleId = updates.id;
      const oldArticle = state.articlesMap[articleId];
      if (!oldArticle) return;
      const oldArticleUpdates = oldArticle.updates ? oldArticle.updates : {};
      const updatedArticle = {
        ...state.articlesMap[articleId],
        updates: {...oldArticleUpdates, ...updates},
        clientUpdatedAt: Date.now(),
      };
      console.log("[STATE] updateArticleTemp", updatedArticle);
      state.articlesMap[articleId] = updatedArticle;
      //
      // remove subArticles if article doesn't have any type or is not a nomenclature.
      //
      if (!updates.isNomenclature) {
        console.log("[DELETE SUBARTICLES] of ", oldArticle?.name);
        delete state.subArticlesMap[articleId];
      }
      //
      state.lastUpdateAt = Date.now();
    },
    cancelUpdates: (state) => {
      Object.values(state.articlesMap).forEach((article) => {
        if (article.clientUpdatedAt) {
          const newArticle = {...article};
          delete newArticle.clientUpdatedAt;
          delete newArticle.updates;
          state.articlesMap[article.id] = newArticle;
        }
      });
    },
    updateArticlesTemp: (state, action) => {
      const updatesBatch = action.payload;
      if (!updatesBatch) return;
      //
      const subArticlesArray = [];
      Object.entries(state.subArticlesMap).forEach(([articleId, subMap]) => {
        Object.entries(subMap).forEach(([subArticleId, subArticle]) => {
          subArticlesArray.push({articleId, subArticleId, subArticle});
        });
      });
      //
      updatesBatch.forEach((updates) => {
        const articleId = updates.id;
        //
        const oldArticle = state.articlesMap[articleId];
        const oldSubArticleItem = subArticlesArray.find(
          (subA) => subA.subArticleId === articleId
        );
        //
        if (oldArticle) {
          const oldArticleUpdates = oldArticle.updates
            ? oldArticle.updates
            : {};
          const updatedArticle = {
            ...state.articlesMap[articleId],
            updates: {...oldArticleUpdates, ...updates},
            clientUpdatedAt: Date.now(),
          };
          state.articlesMap[articleId] = updatedArticle;
          //
          // remove subArticles if article doesn't have any type.
          if (updates.types && Object.keys(updates.types).length === 0) {
            state.subArticlesMap[updates.id] = {};
          }
        } else if (oldSubArticleItem) {
          const updatedSubArticle = {
            ...oldSubArticleItem.subArticle,
            ...updates,
          };
          if (state.subArticlesMap[oldSubArticleItem?.articleId]) {
            state.subArticlesMap[oldSubArticleItem.articleId][
              oldSubArticleItem.subArticleId
            ] = updatedSubArticle;
          }
        }

        //
      });
      //
      state.lastUpdateAt = Date.now();
    },
    setArticleIdTempNumMap: (state, action) => {
      state.articleIdTempNumMap = action.payload;
    },
    setArticleQtyMap: (state, action) => {
      state.articleQtyMap = action.payload;
    },
    setArticleQtiesMapBySector: (state, action) => {
      state.articleQtiesMapBySector = action.payload;
    },
    //
    triggerLastUpdate: (state) => {
      state.lastUpdateAt = Date.now();
    },
    resetSubArticles: (state) => {
      state.subArticlesMap = {};
    },
    // TEMPLATE SUB-ARTICLES
    setArticlesProxies: (state, action) => {
      const {articlesFromTemplate, sortedArticlesProxy, groupId} =
        action.payload;
      //
      console.log("[STATE] setArticlesProxies", articlesFromTemplate);
      state.sortedArticlesProxyByGroupId[groupId] = sortedArticlesProxy;
      //
      articlesFromTemplate.forEach((article) => {
        state.articlesMap[article.id] = article;
      });
    },
    resetProxies: (state, action) => {
      const {groupId} = action.payload;
      state.articlesMapProxy = {};
      state.sortedArticlesProxyByGroupId[groupId] = [];
      // remove articles fromTemplate from the articlesMap
      const articlesNotFromTemplate = Object.values(state.articlesMap).filter(
        (article) => !article.fromTemplate
      );
      state.articlesMap = articlesNotFromTemplate.reduce((acc, article) => {
        acc[article.id] = article;
        return acc;
      }, {});
    },
  },
  extraReducers: {
    // create
    [createArticlesGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.articlesGroupsMap[item.id] = item;
    },
    [createArticle.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.articlesMap[item.id] = item;
    },
    [createArticlesBatch.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.articlesMap[item.id] = item;
      });
    },
    // fetch
    [fetchArticlesGroups.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.articlesGroupsMap[item.id] = item;
      });
      state.areFetchedArticlesGroups = true;
    },
    [fetchArticles.fulfilled]: (state, action) => {
      const {items, articlesGroupId} = action.payload;
      items.forEach((item) => {
        state.articlesMap[item.id] = item;
      });
      state.areFetchedArticlesGroupMap[articlesGroupId] = true;
    },
    // update
    [updateArticlesGroup.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.articlesGroupsMap[item.id] = item;
    },

    [updateArticlesBatch.fulfilled]: (state, action) => {
      const {items} = action.payload;
      console.log("[STATE] updateArticlesBatch", items);
      items.forEach((item) => {
        const newItem = {...state.articlesMap[item.id], ...item};
        delete newItem.clientUpdatedAt;
        delete newItem.updates;
        state.articlesMap[item.id] = newItem;
      });
    },

    // delete
    [deleteAllArticles.fulfilled]: (state, action) => {
      const {articlesGroupId} = action.payload;
      const newArticlesMap = {};
      Object.keys(state.articlesMap).forEach((articleId) => {
        if (state.articlesMap[articleId].articlesGroupId !== articlesGroupId) {
          newArticlesMap[articleId] = state.articlesMap[articleId];
        }
      });
      state.articlesMap = newArticlesMap;
    },
    [deleteArticlesGroup.fulfilled]: (state, action) => {
      const {articlesGroupId} = action.payload;

      if (state.selectedArticlesGroupId === articlesGroupId) {
        state.selectedArticlesGroupId = null;
      }
      delete state.articlesGroupsMap[articlesGroupId];
    },
    [deleteArticle.fulfilled]: (state, action) => {
      const {articleId} = action.payload;
      delete state.articlesMap[articleId];
    },
    [deleteArticles.fulfilled]: (state, action) => {
      const {articlesIds, groupId} = action.payload;
      //
      const articlesClientIds = articlesIds.map(
        (id) => state.articlesMap[id].clientId
      );
      //
      articlesIds.forEach((articleId) => {
        delete state.articlesMap[articleId];
      });
      //
      if (groupId) {
        const articlesGroup = state.articlesGroupsMap[groupId];
        const newArticlesGroup = {
          ...articlesGroup,
          sortedArticles: articlesGroup?.sortedArticles?.filter(
            (id) => !articlesClientIds.includes(id)
          ),
        };
        state.articlesGroupsMap[groupId] = newArticlesGroup;
      }
    },
  },
});

export const {
  resetAreFetched,
  //
  setGroupsViewVariant,
  //
  setSelectedArticleId,
  setSelectedArticleIds,
  setSelectedArticlesGroupId,
  setIsEditingArticle,
  setEditedArticle,
  setTempArticle,
  //
  setAutoSync,
  //
  updateArticleTemp,
  updateArticlesTemp,
  updateArticlesGroupTemp,
  cancelUpdates,
  //
  setArticleIdTempNumMap,
  //
  setArticleQtyMap,
  setArticleQtiesMapBySector,
  triggerLastUpdate,
  //
  addSubArticles,
  resetSubArticles,
  //
  setArticlesProxies,
  resetProxies,
} = articlesSlice.actions;

export default articlesSlice.reducer;
