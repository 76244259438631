import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {Box, Button, Card, CardActionArea, CardContent, Typography} from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import { Launch } from "@mui/icons-material";

import { setRefPrice } from "../pricingSlice";
import formatPriceWithSpaces from "../utils/formatPriceWithSpaces";

const getRefBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

export default function SectionPricingsSummary({sharedboxes}) {
  const dispatch = useDispatch();

  // data

  const refPrice = useSelector((s) => s.pricing.refPrice);

  // state

  const [refBox, setRefBox] = useState(null);

  // helpers

  let initRef;
  let minPrice = 0;
  if (sharedboxes.length > 0) {
    const withResponse = sharedboxes.find((s) => s.accumulatedPrice > 0);
    if (withResponse) {
      initRef = withResponse;
      minPrice = withResponse.accumulatedPrice;
    }
  }

  // handlers

  const handleRefChange = (box) => {
    const value = box.accumulatedPrice;
    if (value && value > 0) dispatch(setRefPrice(value));
    setRefBox(box);
  };

  // effect

  useEffect(() => {
    if (initRef) {
      dispatch(setRefPrice(initRef.accumulatedPrice));
      setRefBox(initRef);
    }
  }, [initRef])

  return (
    <>
      <Box
        sx={{
          px: 2,
          pt: 1,
          display: "flex",
          flexWrap: "wrap",
          width: 1,
          "&>*:not(:last-child)": {mr: 1},
        }}
      >
        {sharedboxes.map((box, id) => {
          const isGreen = refPrice > 0 && box.accumulatedPrice === minPrice;
          const isRed = refPrice > 0 && id === sharedboxes.length - 1;
          const spread = Math.round((box.accumulatedPrice / refPrice) * 100 - 100);
          return (
            <Card key={`pricing${id}`}
              sx={{
                mb: 1,
                minWidth: 150,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                // border: refPrice > 0 && spread === 0 ? "1px solid #fdff5b" : "none",
                bgcolor: (theme) => refPrice > 0 && spread === 0 ?
                  getRefBackgroundColor(theme.palette.stabilo.yellow) :
                  (box.accumulatedPrice === 0 ? "inherit" : "common.white"),
              }}
            >
              <CardActionArea
                // disabled={box.accumulatedPrice === 0}
                onClick={() => handleRefChange(box)}
              >
                <CardContent sx={{p: 0, m: 0, "&:last-child": {pb: 1}}}>
                <Typography
                    variant="body2"
                    sx={{pt: 1}}
                  >
                    {box.company}
                  </Typography>
                  <Typography
                    variant="body2"
                  >
                    {`(${box.code})`}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{fontWeight: "bold", pt: 1}}
                    color={isGreen ? "green" : (isRed ? "red" : "inherit")}
                  >
                    {`${box.accumulatedPrice > 0 ? formatPriceWithSpaces(box.accumulatedPrice) : "-"} €`}
                  </Typography>
                  {refPrice > 0 && spread === 0 ? (
                    <Typography
                      variant="body2"
                      sx={{pb: 1}}
                      color={isGreen ? "green" : (isRed ? "red" : "inherit")}
                    >
                      ref
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{pb: 1}}
                      color={isGreen ? "green" : (isRed ? "red" : "inherit")}
                    >
                      {box.accumulatedPrice > 0 ?
                        (spread > 0 ? `+ ${spread} %` : `- ${Math.abs(spread)} %`) : "-"}
                    </Typography>
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
      <Box
        sx={{
          color: "text.secondary",
          width: 1,
          display: "flex",
          textAlign: "center",
          px: 2,
          pb: 3,
        }}
      >
        <Button
          color="inherit"
          size="small"
          onClick={() => openInNewTab(
            `https://app.capla360.com/shared/bimbox/${refBox?.id}/viewer`
          )}
          endIcon={<Launch />}
        >
          {`Ouvrir la sous-bimbox ${refBox?.name} (${refBox?.company})`}
        </Button>
      </Box>
    </>
  );
}