import {useState} from "react";
import {useSelector} from "react-redux";

import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

import {Menu, MenuItem, Typography, Button} from "@mui/material";
import {ExpandMore as More} from "@mui/icons-material";

import DialogImportElementTypesFromExcel from "./DialogImportElementTypesFromExcel";
import DialogImportTypeRessourceMapFromExcel from "./DialogImportTypeRessourceMapFromExcel";

import createExcelWorkbook from "Features/excel/utils/createExcelWorkbook";
import downloadExcelFileFromWorkbook from "Features/excel/utils/downloadExcelFileFromWorkbook";
import getTypeRessourceMap from "../utils/getTypeRessourceMap";
import sortByNum from "../utils/sortByNum";

export default function ButtonExcelElementTypes({scene}) {
  // strings

  const label = "Imp.Exp.";
  const exportS = "Export";
  const importS = "Import";
  const exportMapS = "Export Type<=>Article";
  const importMapS = "Import Type<=>Article";

  const fileName = "types.xlsx";
  const fileNameMap = "types-articles.xlsx";

  // data

  let elementTypes = useElementTypesBySceneProxy(scene, {filterByScope: true});
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const selectedGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  let elementTypesWithIds = elementTypes.map((t) => ({
    ...t,
    num: t.num ? t.num.toString() : "0",
    group: t.group ? t.group : "-?-",
  }));
  if (selectedGroupProxy)
    elementTypesWithIds = elementTypesWithIds.filter(
      (t) => t.group === selectedGroupProxy?.name
    );
  elementTypesWithIds = elementTypesWithIds
    .sort((a, b) => sortByNum(a.num, b.num))
    .sort((a, b) => a.group.localeCompare(b.group));

  const typeRessourceMap = getTypeRessourceMap(
    elementTypes.filter((t) => t.group === selectedGroupProxy?.name),
    ressources
  )
    .sort((a, b) => a.resGroup.localeCompare(b.typeGroup))
    .sort((a, b) => a.typeGroup.localeCompare(b.resGroup))
    .sort((a, b) => sortByNum(a.typeNum, b.typeNum));

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openImportMapDialog, setOpenImportMapDialog] = useState(false);

  // helpers - handlers

  function handleImport() {
    setAnchorEl(null);
    setOpenImportDialog(true);
  }
  function handleCloseImportDialog() {
    setOpenImportDialog(false);
  }

  async function handleExport() {
    setAnchorEl(null);
    const wb = await createExcelWorkbook({
      template: "ELEMENT_TYPES_WITH_IDS",
      data: elementTypesWithIds,
    });
    downloadExcelFileFromWorkbook(wb, fileName);
  }

  function handleImportMap() {
    setAnchorEl(null);
    setOpenImportMapDialog(true);
  }
  function handleCloseImportMapDialog() {
    setOpenImportMapDialog(false);
  }

  async function handleExportMap() {
    setAnchorEl(null);
    const wb = await createExcelWorkbook({
      template: "TYPE_RESSOURCE_MAP",
      data: typeRessourceMap,
    });
    downloadExcelFileFromWorkbook(wb, fileNameMap);
  }

  // helpers - tools

  const tools = [
    {
      id: "EXPORT",
      label: exportS,
      handler: handleExport,
    },
    {
      id: "IMPORT",
      label: importS,
      handler: handleImport,
    },
    {
      id: "EXPORT_MAP",
      label: exportMapS,
      handler: handleExportMap,
    },
    {
      id: "IMPORT_MAP",
      label: importMapS,
      handler: handleImportMap,
    },
  ];

  return (
    <>
      <Button
        endIcon={<More />}
        size="small"
        color="inherit"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </Button>

      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem key={tool.id} onClick={tool.handler}>
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <DialogImportElementTypesFromExcel
        open={openImportDialog}
        onClose={handleCloseImportDialog}
        scene={scene}
      />
      <DialogImportTypeRessourceMapFromExcel
        open={openImportMapDialog}
        onClose={handleCloseImportMapDialog}
        scene={scene}
      />
    </>
  );
}
