import React from "react";

import ColorSelector from "./ColorSelector";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Paper,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import {
  ArrowForward as Arrow,
  Gesture as Free,
  Delete,
  Download,
  ContentCopy as Copy,
  Update,
  TextFields as Text,
  Undo,
} from "@mui/icons-material";
export default function Toolbar({
  tool,
  onChange,
  color,
  onColorChange,
  onDownload,
  onCopy,
  onAddToPdf,
  onUndo,
  mobile,
}) {
  //strings

  const downloadString = "Download";
  const copyString = "Copy";
  const pdfS = "+ Pdf";

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        //bgcolor: "common.caplaBlack",
        p: 0.5,
        borderRadius: "4px",
      }}
    >
      <ToggleButtonGroup
        exclusive
        value={tool}
        onChange={(e, v) => onChange(v)}
        size="small"
        sx={{
          backgroundColor: "common.white",
        }}
      >
        <ToggleButton value="ARROW">
          <Arrow fontSize="small" />
        </ToggleButton>
        <ToggleButton value="FREE">
          <Free fontSize="small" />
        </ToggleButton>
        {!mobile && (
          <ToggleButton value="TEXT">
            <Text fontSize="small" />
          </ToggleButton>
        )}
        {!mobile && (
          <ToggleButton value="DELETE">
            <Delete fontSize="small" />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      <Box sx={{ml: 0.5}}>
        <ColorSelector color={color} onChange={onColorChange} mobile={mobile} />
      </Box>
      <Divider orientation="vertical" variant="middle" flexItem sx={{mx: 1}} />
      <IconButton size="small" onClick={onUndo}>
        <Undo fontSize="small" />
      </IconButton>
      <Divider orientation="vertical" variant="middle" flexItem sx={{mx: 1}} />
      {!mobile && (
        <Box>
          <Button size="small" startIcon={<Download />} onClick={onDownload}>
            {downloadString}
          </Button>
          <Button
            sx={{ml: 1}}
            size="small"
            startIcon={<Copy />}
            onClick={onCopy}
          >
            {copyString}
          </Button>
          <Button sx={{ml: 1}} size="small" onClick={onAddToPdf}>
            {pdfS}
          </Button>
        </Box>
      )}
      {mobile && (
        <Box>
          <IconButton size="small" onClick={onDownload}>
            <Download />
          </IconButton>
          <IconButton sx={{ml: 1}} size="small" onClick={onCopy}>
            <Copy />
          </IconButton>
        </Box>
      )}
    </Paper>
  );
}
