import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useMsal} from "@azure/msal-react";

import {fetchUserId} from "Features/auth/authSlice";

function useAccessToken() {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const {instance, accounts} = useMsal();
  const account = accounts[0];
  const username = account?.username;

  const tokenRequestConfig = {
    account,
    scopes: [
      "https://capla360.onmicrosoft.com/api/capla.read",
      "https://capla360.onmicrosoft.com/api/capla.write",
    ],
  };

  const fetchStatus = useSelector((s) => s.auth.status);
  const {id: userId} = useSelector((state) => state.auth.data);

  const triggerTokenAt = useSelector((s) => s.auth.triggerTokenAt);

  // computation in effect

  useEffect(() => {
    // console.time("[EFFECT] access token");
    async function getToken() {
      try {
        const {accessToken} = await instance.acquireTokenSilent(
          tokenRequestConfig
        );
        setToken(accessToken);
      } catch (e) {
        console.log("fail to silently access token: ", e);
        //if (e instanceof InteractionRequiredAuthError) {
        console.log("acquire Token Popup");
        const {accessToken} = await instance.acquireTokenRedirect(
          tokenRequestConfig
        );
        setToken(accessToken);
        //}
      }
    }
    if (username) {
      getToken();
    }
    // console.timeEnd("[EFFECT] access token");
  }, [username, triggerTokenAt]);

  useEffect(() => {
    async function getUserId() {
      dispatch(fetchUserId({accessToken: token}));
    }
    if (token && !userId && fetchStatus === "idle") {
      getUserId();
    }
  }, [token]);

  // effect output
  return token;
}

export default useAccessToken;
