import React, {useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {setFilterNoVoids} from "../measurementsSlice";

import {
  Typography,
  Menu,
  Chip,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import {
  ArrowDropDown as Down,
  Close,
  CancelPresentation as Voids,
} from "@mui/icons-material";

export default function SelectorFilterVoids({caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const noVoidsShortS = "V.pr.P";
  const noVoidsS = "Vide pour Plein";
  const voidsS = "Vides";

  // data

  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helper - active filter

  const filterName = voidsS;
  const activeFilter = filterNoVoids;
  const label = activeFilter ? noVoidsShortS : filterName;
  const isActive = Boolean(activeFilter);

  // handlers

  function handleDeleteClick(e) {
    if (!isActive) {
      setAnchorEl(e.currentTarget);
    } else {
      dispatch(setFilterNoVoids(false));
      const entities = caplaEditor?.editor3d.entities.filter(
        (e) => e.type === "MEASUREMENTS_MODEL"
      );
      for (const entity of entities) {
        entity?.substractVoids();
      }
    }
  }
  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setFilterNoVoids(checked));
    const entities = caplaEditor?.editor3d.entities.filter(
      (e) => e.type === "MEASUREMENTS_MODEL"
    );
    for (const entity of entities) {
      if (checked) entity?.fillVoids();
      else entity?.substractVoids();
    }
    setAnchorEl(null);
  }

  return (
    <>
      <Chip
        label={label}
        deleteIcon={isActive ? <Close /> : <Down />}
        onDelete={handleDeleteClick}
        size="small"
        icon={<Voids />}
        variant={isActive ? "outlined" : "filled"}
      />
      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        <Box sx={{px: 1}}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={filterNoVoids}
                onChange={handleChange}
              />
            }
            label={<Typography variant="body2">{noVoidsS}</Typography>}
          />
        </Box>
      </Menu>
    </>
  );
}
