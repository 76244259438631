import argbToHex from "./argbToHex";
import theme from "Styles/theme";

export default function getItemPropsFromQtyCellStyleAndValue(cell) {
  const italic = cell.font?.italic;
  const value = cell.value;

  const props = {};

  if (italic) {
    props.hardcodedQty = value;
  }

  return props;
}
