import {GLTFExporter as ThreeGLTFExporter} from "three/examples/jsm/exporters/GLTFExporter.js";
import {MeshStandardMaterial} from "three";

export default class GLTFExporter {
  constructor({editor}) {
    this.editor = editor;
  }

  // initOptions() {
  //   this.options = {
  //     trs: false,
  //     onlyVisible: true,
  //     truncateDrawRange: true,
  //     binary: false,
  //     maxTextureSize: 4096,
  //   };
  // }

  initOptions() {
    this.options = {
      trs: false,
      onlyVisible: true,
      truncateDrawRange: true,
      binary: true,
      maxTextureSize: 4096,
    };
  }

  saveString(text, filename) {
    return new File([new Blob([text], {type: "text/plain"})], filename);
  }

  saveArrayBuffer(buffer, filename) {
    return new File(
      [new Blob([buffer], {type: "application/octet-stream"})],
      filename
    );
  }

  exportGLTF = async (input, modelName) => {
    const gltfExporter = new ThreeGLTFExporter();
    return new Promise((resolve) => {
      gltfExporter.parse(
        input,
        (result) => {
          console.log("export GLTF", result);
          if (result instanceof ArrayBuffer) {
            resolve(this.saveArrayBuffer(result, `${modelName}.glb`));
          } else {
            const output = JSON.stringify(result, null, 2);
            resolve(this.saveString(output, `${modelName}.gltf`));
          }
        },
        function (error) {
          console.log("An error happened during parsing", error);
        },
        this.options
      );
    });
  };

  setStandardMaterial(object) {
    console.log("set material for object", object);
    if (object.material) {
      object.material = new MeshStandardMaterial({
        color: object.material.color,
        opacity: object.material.opacity,
      });
    }
  }
  async createGltfFile(model) {
    const objectEntities = this.editor.objectEntities.filter(
      (e) => e?.model?.id === model.id && e.type === "IFC_MODEL_ENTITY"
    );
    const objects = objectEntities.map((e) => e.rawObject);
    //const objects = objectEntities.map((e) => e.modelObject);
    //objects.forEach((object) => this.setStandardMaterial(object));

    return await this.exportGLTF(objects, model.name);
  }
}
