import React, {useState, useEffect} from "react";

import {useParams, useLocation, useNavigate} from "react-router-dom";
import {
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Power} from "@mui/icons-material";
import connect from "../assets/connect.png";

import {useDispatch} from "react-redux";
import {createRemoteScene, postSceneImage} from "Features/scenes/scenesSlice";
import {unwrapResult} from "@reduxjs/toolkit";

import {fetchStoredScene} from "Database/services";

import SignInDialog from "Features/auth/components/SignInDialog";

import useTranslation from "Features/translations/useTranslation";
import useAccessToken from "Features/auth/useAccessToken";
import useScene from "Features/scenes/useScene";
import useProfile from "Features/profile/useProfile";

import {useIsAuthenticated} from "@azure/msal-react";
import {unwrap} from "idb";
import useSelectedScene from "../useSelectedScene";
import usePageType from "Features/navigation/usePageType";

export default function ConnectButton() {
  const {t} = useTranslation(["scenes", "profile", "ui"]);
  const accessToken = useAccessToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageType = usePageType();

  const {pathname} = useLocation();

  const isAuthenticated = useIsAuthenticated();
  const {sceneId} = useParams();
  const activeScene = useScene(sceneId);
  const selectedScene = useSelectedScene();
  let scene = activeScene;
  if (pageType === "HOME") scene = selectedScene;

  // strings

  const connectButtonString = t("scenes:connect.button");

  const dialogTitleString = t("scenes:connect.dialog.title");
  const dialogDescriptionString = t("scenes:connect.dialog.description");
  const dialogButtonString = t("scenes:connect.dialog.button");

  const connectedString = t("scenes:connect:connected");

  const noSubMessage = t("profile:subscription.noSubMessage");

  const contactButtonString = t("ui:contactButton");

  // data

  const profile = useProfile();

  // local state

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSigninDialog, setOpenSigninDialog] = useState(false);
  const [expand, setExpand] = useState(false);

  // helpers

  const connected = Boolean(scene?.id);

  // helpers - style

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // helpers - can create scene

  // const canCreateScene = profile.data.canCreateScene
  const canCreateScene = true;

  // handlers expand button

  function handleMouseOver() {
    setExpand(true);
  }

  function handleMouseLeave() {
    setExpand(false);
  }

  // handlers

  function handleHeaderConnectClick() {
    if (isAuthenticated) {
      setOpenDialog(true);
    } else {
      setOpenSigninDialog(true);
    }
  }

  function handleDialogClose() {
    setOpenDialog(false);
    setLoading(false);
  }

  async function handleDialogConnectClick() {
    setLoading(true);
    // create the scene
    const result = await dispatch(
      createRemoteScene({sceneClientId: scene.clientId, accessToken})
    );
    const remoteScene = unwrapResult(result);

    // stop process
    setLoading(false);
    setOpenDialog(false);

    // navigate to new url width scene remote id
    const url = pathname.replace(scene.clientId, remoteScene.id);
    navigate(url);
  }

  function handleSigninDialogClose() {
    setOpenSigninDialog(false);
  }

  useEffect(() => {
    if (isAuthenticated) {
      setOpenSigninDialog(false);
    }
  }, [isAuthenticated]);
  return (
    <Box sx={{color: "common.white"}}>
      {connected ? (
        // <Typography variant="caption" color="inherit">
        //   {connectedString}
        // </Typography>
        <div />
      ) : (
        <Paper
          elevation={12}
          sx={{
            background,
            color: "common.white",
            height: 34,
            borderRadius: 17,
            display: "flex",
            alignItems: "center",
          }}
          onMouseLeave={handleMouseLeave}
          onMouseOver={handleMouseOver}
        >
          <Button
            startIcon={<Power />}
            sx={{
              borderRadius: (theme) => theme.shape.borderRadius,
              px: 1,
            }}
            color="inherit"
            onClick={handleHeaderConnectClick}
          >
            <Typography noWrap variant="body2">
              {expand ? connectButtonString : "..."}
            </Typography>
          </Button>
        </Paper>
      )}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogTitleString}</DialogTitle>
        <DialogContent>
          <Box sx={{display: "flex", justifyContent: "center"}}>
            <img src={connect} />
          </Box>
          <Typography variant="body2">{dialogDescriptionString}</Typography>
          {!canCreateScene && (
            <Box
              sx={{
                mt: 6,
                p: 2,
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
                color: "common.white",
              }}
            >
              <Typography variant="body2" sx={{mb: 2}}>
                {noSubMessage}
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={() => {
                  window.location.href =
                    "mailto:support@capla-technologies.com";
                }}
              >
                {contactButtonString}
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton
            startIcon={<Power />}
            onClick={handleDialogConnectClick}
            loading={loading}
            loadingPosition="start"
            disabled={!canCreateScene}
          >
            {dialogButtonString}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <SignInDialog open={openSigninDialog} onClose={handleSigninDialogClose} />
    </Box>
  );
}
