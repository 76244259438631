import {Box, Button, Tooltip} from "@mui/material";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import useSceneModuleIconMap from "Features/navigation/useSceneModuleIconMap";
import useSceneModulePathMap from "Features/navigation/useSceneModulePathMap";
import {useNavigate, useParams} from "react-router-dom";

export default function ButtonReachVersionsModule({onClick}) {
  const label = "Gérer les versions";

  const navigate = useNavigate();
  const {sceneId} = useParams();

  const iconsMap = useSceneModuleIconMap();
  const namesMap = useSceneModuleNameMap();
  const pathsMap = useSceneModulePathMap(sceneId);

  const icon = iconsMap["VERSIONS"];
  const name = namesMap["VERSIONS"];
  const path = pathsMap["VERSIONS"];

  function handleClick() {
    navigate(path);
    if (onClick) onClick();
  }
  return (
    <Box sx={{color: "text.secondary", width: 1}}>
      <Tooltip title={label} placement="bottom">
        <Button
          fullWidth
          startIcon={icon}
          size="small"
          color="inherit"
          onClick={handleClick}
        >
          {name}
        </Button>
      </Tooltip>
    </Box>
  );
}
