import React from "react";

import {Box} from "@mui/material";

import ButtonDataGridClearSelection from "./ButtonDataGridClearSelection";

export default function SectionDataGridToolbar({editor}) {
  return (
    <Box sx={{display: "flex", alignItems: "center", px: 1}}>
      <ButtonDataGridClearSelection editor={editor} />
    </Box>
  );
}
