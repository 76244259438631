const articleTypesMapToArray = (articleTypesMap, options) => {
  //
  const debugFormat = options?.debugFormat;
  //
  const array = [];
  if (!articleTypesMap) return array;
  Object.entries(articleTypesMap).forEach(([groupId, typeMap]) => {
    if (groupId === "filtersStr" && typeMap?.length > 0) {
      array.push({
        filtersStr: typeMap,
        func: articleTypesMap["globalFunc"],
      });
    } else {
      if (typeof typeMap === "object") {
        Object.entries(typeMap).forEach(([typeId, props]) => {
          if (typeId === "filtersStr") {
            array.push({groupId, filtersStr: props});
          } else {
            if (debugFormat) {
              array.push({
                typeNum: props.typeNum,
                typeName: props.typeName,
                func: props.func,
                filtersStr: props.filtersStr,
              });
            } else {
              array.push({groupId, typeId, ...props});
            }
          }
        });
      }
    }
  });
  return array;
};

export default articleTypesMapToArray;
