import getElementTypeChildren from "Features/elementTypes/utils/getElementTypeChildren";
import mergeArticleQties from "./mergeArticleQties";

function testTypeHasArticleQties(type) {
  return type.articleQties && type.articleQties.length > 0;
}

export default function transferElementTypeArticleQtiesToChildren(
  sortedElementTypes
) {
  const inheritedTypeQtiesMap = {}; // {elementTypeId:articleQties}
  //
  sortedElementTypes.forEach((elementType, index) => {
    if (testTypeHasArticleQties(elementType)) {
      const children = getElementTypeChildren(index, sortedElementTypes);
      if (Array.isArray(children) && children.length > 0) {
        children.forEach((child) => {
          if (!inheritedTypeQtiesMap[child.id]) {
            inheritedTypeQtiesMap[child.id] = elementType.articleQties;
          } else {
            inheritedTypeQtiesMap[child.id] = mergeArticleQties(
              inheritedTypeQtiesMap[child.id],
              elementType.articleQties
            ); // important : this order to keep specificity.
          }
        });
      }
    }
  });

  // merge inherited articleQties to existing items.
  const newElementTypes = sortedElementTypes.map((elementType) => {
    return {
      ...elementType,
      articleQties: mergeArticleQties(
        inheritedTypeQtiesMap[elementType.id],
        elementType.articleQties
      ),
    };
  });

  console.log(
    "debugAFA newElementTypes",
    newElementTypes.map((t) => ({
      num: t.num,
      name: t.name,
      articleQties: t.articleQties,
    }))
  );

  return newElementTypes;
}
