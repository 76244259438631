import {useState, useEffect} from "react";

import {Box, Button, Typography, InputBase, Paper} from "@mui/material";

import ListElementTypes from "./ListElementTypes";

export default function SectionTempElementTypeGroup({
  tempTypes,
  tempGroupName,
  onTempGroupNameChange,
}) {
  // state

  function handleGroupNameChange(e) {
    onTempGroupNameChange(e.target.value);
  }

  return (
    <Box
      sx={{display: "flex", flexDirection: "column", justifyItems: "stretch"}}
    >
      <Box sx={{mt: 2, p: 1, display: "flex", alignItems: "center"}}>
        <Paper elevation={0} sx={{width: 1, px: 1}}>
          <InputBase
            variant="body2"
            value={tempGroupName}
            onChange={handleGroupNameChange}
          />
        </Paper>
      </Box>

      <Box sx={{display: "flex", flexGrow: 1, overflow: "auto", minHeight: 0}}>
        <ListElementTypes elementTypes={tempTypes} />
      </Box>
    </Box>
  );
}
