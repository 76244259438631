import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, ToggleButtonGroup, ToggleButton, Tooltip} from "@mui/material";

import {setAnnotCreationTool} from "../measurementsSlice";
import useRelevantAnnotCreationTools from "../useRelevantAnnotCreationTools";
import useAnnotCreationToolsMap from "../useAnnotCreationToolsMap";

import {setShowQuantitiesInViewer} from "Features/measurements/measurementsSlice";

export default function ToolbarMeasurementPdfButtons({editorPdf}) {
  const dispatch = useDispatch();

  // data

  const tool = useSelector((s) => s.measurements.annotCreationTool);
  const tools = useRelevantAnnotCreationTools();
  const toolMap = useAnnotCreationToolsMap();

  // handlers

  function handleToolChange(tool) {
    dispatch(setAnnotCreationTool(tool));
    if (tool === "POLYLINE") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawPolyline();
    } else if (tool === "POLYLINE_INTERIOR") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawPolylineInterior();
    } else if (tool === "POLYLINE_EXTERIOR") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawPolylineExterior();
    } else if (tool === "POLYGON") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawPolygon();
    } else if (tool === "POLYGON_INTERIOR") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawPolygon();
    } else if (tool === "SEGMENT") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawSegment();
    } else if (tool === "RECTANGLE") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawRectangle();
    } else if (tool === "POINT") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerAddPoint();
    } else if (tool === "SEGMENT_FROM_ZONES") {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerDrawSegmentFromZones();
    } else {
      editorPdf?.annotationsManager.measurementsPdfManager.triggerPanTool();
    }
  }

  // effects

  // useEffect(() => {
  //   if (editorPdf) {
  //     console.log("EFFECT12-updateMeasurementTools");
  //     dispatch(setShowQuantitiesInViewer(true));
  //     editorPdf?.annotationsManager.measurementsPdfManager.updateMeasurementTools();
  //   }
  // }, [editorPdf]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setShowQuantitiesInViewer(false));
  //   };
  // }, []);

  // effects - init selected measuremnts model

  // useEffect(() => {
  //   if (!selectedMeasurementsModel?.id && models?.length > 0) {
  //     dispatch(setSelectedMeasurementsModelId(models[0].id));
  //   }
  // }, [models?.length, selectedMeasurementsModel?.id]);
  
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        "&>:not(:last-child)": {mr: 1},
      }}
    >
      <ToggleButtonGroup
        exclusive
        value={tool}
        onChange={(_, v) => handleToolChange(v)}
        size="small"
        sx={{
          height: 30,
          color: "common.white ",
        }}
      >
        {tools.map((tool) => {
          return (
            <ToggleButton key={tool} value={tool}>
              <Tooltip title={toolMap[tool].name}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "common.white",
                  }}
                >
                  {toolMap[tool].icon}
                </Box>
              </Tooltip>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
}
