import React, {useState, useEffect} from "react";

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Button,
  Typography,
} from "@mui/material";

import {
  GpsFixed as Located,
  GpsNotFixed as NotLocated,
  LocationSearching,
} from "@mui/icons-material";

import SelectorMarker from "Features/viewer3D/components/SelectorMarker";

export default function ButtonGeolocateWith2Markers({scene, editor}) {
  const dispatch = useDispatch();

  // 45.76841
  // 4.81537
  // 45.77025
  // 4.81415

  // strings

  const titleString = "Geolocate the bimbox";

  const descriptionString = "Select 2 geolocated markers";

  const lngString = "Longitude";
  const latString = "Latitude";
  const rotString = "North (deg)";

  const updateString = "Update from delta with worksite";

  const saveString = "Save";

  // props

  const sceneLat = scene?.lat;
  const sceneLng = scene?.lng;
  const sceneRot = scene?.rotation;

  const worksite = scene?.worksite;

  // initState

  const initLat = sceneLat ? sceneLat.toString() : "48.85307";
  const initLng = sceneLng ? sceneLng.toString() : "2.34996";
  const initRot = sceneRot ? sceneRot.toString() : "0";

  // state

  const [open, setOpen] = useState(false);

  const [lat, setLat] = useState(initLat);
  const [lng, setLng] = useState(initLng);
  const [rot, setRot] = useState(initRot);

  const [marker1, setMarker1] = useState(null);
  const [marker2, setMarker2] = useState(null);

  useEffect(() => {
    setLat(initLat);
    setLng(initLng);
    setRot(initRot);
  }, [sceneLat, sceneLng, sceneRot]);

  // helper - validation

  const canUpdateFromDelta = typeof scene?.offset?.position.x === "number";

  // helper

  const located = sceneLat && sceneLng;

  const canSave = lng.length > 0 && lat.length > 0;

  // helpers - computation

  function computeLatLngRot() {
    if (marker1?.lat && marker2?.lat) {
      const north = editor.mapEditor.azmEditor.getSceneNorthRotation(
        marker1,
        marker2
      );
      const coord = {x: marker1.x, y: marker1.y, z: marker1.z};
      const latLng = {lat: marker1.lat, lng: marker1.lng};
      const {lat, lng} = editor.mapEditor.azmEditor.computeOriginLatLng(
        coord,
        latLng,
        north
      );
      setRot(north.toFixed(2));
      setLat(lat.toFixed(8));
      setLng(lng.toFixed(8));
    }
  }
  // handlers

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // handlers - form

  function handleLatChange(e) {
    let l = e.target.value;
    l = l.replace(",", ".");
    setLat(l);
  }

  function handleLngChange(e) {
    let l = e.target.value;
    l = l.replace(",", ".");
    setLng(l);
  }

  function handleRotChange(e) {
    let r = e.target.value;
    r = r.replace(",", ".");
    setRot(r);
  }

  function handleMarker1Change(marker) {
    setMarker1(marker);
  }

  function handleMarker2Change(marker) {
    setMarker2(marker);
  }

  useEffect(() => {
    computeLatLngRot();
  }, [marker1?.lat, marker2?.lat]);

  // handlers - action

  async function handleSaveClick() {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);
    const rotation = parseFloat(rot);
    const newScene = {...scene, lat: latitude, lng: longitude, rotation};
    await dispatch(updateScene({scene: newScene}));
    setOpen(false);
  }

  // handlers - locate

  function handleLocateClick() {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);
    const latLng = {lat: latitude, lng: longitude};
    editor.mapEditor.goTo(latLng);
  }

  // handlers - update from delta

  function handleUpdateFromDelta() {
    const latLng = {lat: worksite.lat, lng: worksite.lng};
    const origin = {x: 0, y: 0, z: 0};
    const target = scene.offset.position;
    const {lat, lng} = editor.mapEditor.azmEditor.getDestination(
      latLng,
      origin,
      target
    );

    const north = (-scene.offset.rotation.y * 180) / Math.PI;

    setRot(north.toFixed(2));
    setLat(lat.toFixed(8));
    setLng(lng.toFixed(8));
  }

  return (
    <>
      <IconButton
        sx={{
          width: 34,
          height: 34,
          borderRadius: "4px",
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        onClick={handleOpen}
      >
        {located ? (
          <Located fontSize="small" />
        ) : (
          <NotLocated fontSize="small" />
        )}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{titleString}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{mb: 2}} variant="body2">
              {descriptionString}
            </Typography>

            <Box sx={{display: "flex"}}>
              <Box sx={{width: 200}}>
                <SelectorMarker
                  marker={marker1}
                  onChange={handleMarker1Change}
                  geolocated={true}
                />
              </Box>
              <Box sx={{width: 200, ml: 1}}>
                <SelectorMarker
                  marker={marker2}
                  onChange={handleMarker2Change}
                  geolocated={true}
                />
              </Box>
            </Box>
            <Box sx={{mt: 3, display: "flex", alignItems: "center"}}>
              <TextField
                label={<Typography variant="body2">{latString}</Typography>}
                InputProps={{
                  sx: {fontSize: (theme) => theme.typography.body2.fontSize},
                }}
                size="small"
                value={lat}
                onChange={handleLatChange}
              />
              <TextField
                sx={{ml: 2}}
                label={<Typography variant="body2">{lngString}</Typography>}
                InputProps={{
                  sx: {fontSize: (theme) => theme.typography.body2.fontSize},
                }}
                size="small"
                value={lng}
                onChange={handleLngChange}
              />
              <TextField
                sx={{ml: 2}}
                label={<Typography variant="body2">{rotString}</Typography>}
                InputProps={{
                  sx: {fontSize: (theme) => theme.typography.body2.fontSize},
                }}
                size="small"
                value={rot}
                onChange={handleRotChange}
              />
            </Box>
          </Box>
          <Button
            disabled={!canUpdateFromDelta}
            size="small"
            sx={{mt: 1}}
            onClick={handleUpdateFromDelta}
          >
            {updateString}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button disabled={!canSave} onClick={handleSaveClick}>
            {saveString}
          </Button>
        </DialogActions>
        {lat && lng && (
          <IconButton
            size="small"
            sx={{position: "absolute", top: "8px", right: "8px"}}
            onClick={handleLocateClick}
          >
            <LocationSearching fontSize="small" />
          </IconButton>
        )}
      </Dialog>
    </>
  );
}
