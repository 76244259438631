import {Box, Typography, ListItemButton, List} from "@mui/material";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import ContainerEntityCount from "Features/overviewer/components/ContainerEntityCount";

import ContainerSelectedVersionsOverview from "Features/versions/components/ContainerSelectedVersionsOverview";

export default function ListInputsMultiple({
  scene,
  inputs,
  selection,
  onSelectionChange,
  multipleSelection,
  showVersionIcon,
  sceneVersions,
  selectedVersionForMeasurements,
}) {
  // data

  const packages = usePackagesByScene(scene, {
    filterByScope: true,
    filterByVersion: true,
  });

  // helpers - packageCountByInputIdMap

  const packageCountByInputIdMap = {};
  inputs.forEach((input) => {
    const measModels = packages.filter(
      (p) => p.fromModel?.modelId === input.id
    );
    packageCountByInputIdMap[input.id] = measModels.length;
  });

  // handlers

  function handleClick(input) {
    let newSelection = selection ? [...selection] : [];
    if (multipleSelection) {
      if (selection.includes(input.id)) {
        newSelection = newSelection.filter((id) => id !== input.id);
      } else {
        newSelection.push(input.id);
      }
    } else {
      if (selection?.includes(input.id)) {
        newSelection = [];
      } else {
        newSelection = [input.id];
      }
    }
    if (onSelectionChange) onSelectionChange(newSelection);
  }

  return (
    <List disablePadding dense>
      {inputs.map((input) => {
        const selected = selection?.includes(input.id);
        const count = packageCountByInputIdMap[input.id];
        return (
          <ListItemButton
            divider
            sx={{bgcolor: "common.white"}}
            key={input.id}
            selected={selected}
            onClick={() => {
              handleClick(input);
            }}
          >
            <Box sx={{display: "flex", alignItems: "center", width: 1}}>
              {showVersionIcon && (
                <Box sx={{mr: 1}}>
                  <ContainerSelectedVersionsOverview
                    versions={
                      !selectedVersionForMeasurements
                        ? sceneVersions
                        : [selectedVersionForMeasurements]
                    }
                    selectedVersionIds={input.revisionIds}
                  />
                </Box>
              )}

              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 1,
                }}
              >
                <Typography noWrap sx={{fontSize: 13}}>
                  {input.name}
                </Typography>
                {count !== 0 && (
                  <Box>
                    <ContainerEntityCount
                      count={packageCountByInputIdMap[input.id]}
                      type={"PACKAGES"}
                      size="SMALL"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
