import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {CenterFocusStrong as Focus} from "@mui/icons-material";
import {DataGrid, frFR, GridActionsCellItem} from "@mui/x-data-grid";

// import {format} from "date-fns";

import {setSelectedRoomId} from "../roomsSlice";
// import useSelectedRoom from "../hooks/useSelectedRoom";
import useRoomsBySceneWithMsts from "../hooks/useRoomsBySceneWithMsts";
import roomsForDataGrid from "../utils/roomsForDataGrid";

// import useTranslation from "Features/translations/useTranslation";

export default function DataGridRooms({editor3d, scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  // const idS = "ID";
  const numS = "Num";
  const nameS = "Nom";
  const codeS = "Code";
  const sectorS = "Secteur";

  const focusS = "Focus";

  // data

  const rooms = useRoomsBySceneWithMsts(scene);

  const selectedRoomId = useSelector((s) => s.rooms.selectedRoomId);

  const selectionModel = selectedRoomId ? [selectedRoomId] : [];

  // handlers

  function handleFocus(msts) {
    editor3d?.sceneEditor.focusMeasurement(msts[0]);
  }

  // grid - rows

  const rows = roomsForDataGrid(rooms);

  // grid - columns

  const columns = [
    {
      field: "num",
      headerName: numS,
      width: 80,
    },

    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
    {
      field: "code",
      headerName: codeS,
      width: 80,
    },
    {
      field: "sector",
      headerName: sectorS,
      width: 100,
    },
    {
      field: "hasMsts",
      type: "actions",
      width: 50,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Focus fontSize="small" />}
          onClick={() => handleFocus(params.row.msts)}
          label={focusS}
        />,
      ],
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    // state
    dispatch(setSelectedRoomId(selectionId));
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
