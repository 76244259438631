import {useSelector} from "react-redux";

// import useElementTypesByScene from "./useElementTypesByScene";
import useElementTypesBySceneProxy from "./useElementTypesBySceneProxy";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import sortByNum from "Utils/sortByNum";

export default function useElementTypesGroupsProxyBySceneWithElementTypes(
  scene,
  options
) {
  //
  // [{id,name,fromScene}]
  //
  //
  const sortByName = options?.sortByName;
  const sortElementTypesByNum = options?.sortElementTypesByNum;
  const filterByScope = options?.filterByScope;
  const filterByTypesGroupsIds = options?.filterByTypesGroupsIds;

  // data

  const scope = useSelectedScope();
  const elementTypes = useElementTypesBySceneProxy(scene);
  let remoteGroups = useSelector((s) => s.elementTypes.elementTypesGroups);

  // helpers

  const groupIdsInScope = scope?.data?.typesGroupIds;

  // logic

  let groupsMap = {}; // id:{id,name,fromScene}
  let groups = [];

  // --- sceneGroups
  elementTypes
    .filter((t) => t.fromScene)
    .forEach((type) => {
      const groupsMapItem = groupsMap[type.groupId];
      if (!groupsMapItem) {
        groupsMap[type.groupId] = {
          id: type.groupId,
          name: type.group,
          fromScene: type.fromScene,
          elementTypes: [type],
        };
      } else {
        const newElementTypes = [...groupsMapItem.elementTypes, type];
        groupsMap[type.groupId] = {
          ...groupsMapItem,
          elementTypes: newElementTypes,
        };
      }
    });
  const sceneGroups = [...Object.values(groupsMap)];

  // --- add remotes
  groups = [...sceneGroups, ...remoteGroups];

  if (sortByName) {
    groups = groups.sort((a, b) => a.name.localeCompare(b.name));
  }

  if (filterByScope && groupIdsInScope?.length > 0) {
    groups = groups.filter((g) => groupIdsInScope.includes(g.id));
  }

  if (filterByTypesGroupsIds && filterByTypesGroupsIds.length > 0) {
    groups = groups.filter((g) => filterByTypesGroupsIds.includes(g.id));
  }

  if (sortElementTypesByNum) {
    groups = groups.map((group) => {
      let newElementTypes = [];
      if (Array.isArray(group.elementTypes)) {
        newElementTypes = [...group.elementTypes].sort((a, b) =>
          sortByNum(a.num, b.num)
        );
      }

      return {...group, elementTypes: newElementTypes};
    });
  }
  return groups;
}
