import {Dialog, DialogTitle, DialogContent} from "@mui/material";
import FormEditNomenclature from "./FormEditNomenclature";

export default function DialogCreateNomenclature({
  open,
  onClose,
  onNomenclatureCreated,
}) {
  // strings

  const title = "Ajouter une nomenclature";

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormEditNomenclature onNomenclatureCreated={onNomenclatureCreated} />
      </DialogContent>
    </Dialog>
  );
}
