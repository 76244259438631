import { useState } from "react";
import {Box, Typography, TextField, Autocomplete} from "@mui/material";

export default function AutocompleteFreesolo({initValue, values, onChange, label}) {

  const [value, setValue] = useState(initValue ? initValue : "");
  const [inputValue, setInputValue] = useState(initValue ? initValue : "");

  return (
    <Box sx={{width: 1}}>
      <Autocomplete
        freeSolo
        size="small"
        fullWidth
        options={values}
        value={value}
        inputValue={inputValue}
        onChange={(_, newValue) => {
          setValue(newValue);
          onChange(newValue);
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onBlur={() => {
          onChange(inputValue);
        }}
        sx={{
          fontSize: (theme) => theme.typography.body2.fontSize,
        }}
        renderInput={(params) => <TextField
          {...params}
          label={label && (<Typography variant="body2">{label}</Typography>)}
        />}
      />
    </Box>
    );
  }