import React from "react";

import {useSelector} from "react-redux";
import {Typography, Box, Paper} from "@mui/material";
import {LocalMovies as StoryIcon} from "@mui/icons-material";

import CardModelsLoader from "Features/viewer3D/components/CardModelsLoader";
export default function HeaderSceneStory() {
  const story = useSelector((state) => state.scenes.readStory);
  const sceneClientId = useSelector((state) => state.scenes.sceneClientId);

  // data

  const modelsStatus = useSelector((state) => state.viewer3D.modelsStatus);
  const status = modelsStatus[sceneClientId];
  const statusArray = status ? Object.values(status) : [];
  const sceneLoaded =
    statusArray.filter((s) => s === "loaded").length === statusArray.length;

  return (
    <Box sx={{p: 1, width: 1, display: "flex", justifyContent: "flex-end"}}>
      {sceneLoaded ? (
        <Paper
          sx={{
            width: 1,
            maxWidth: 500,
            p: 0.5,
            display: "flex",
            alignItems: "center",
            color: "common.white",
            background: (theme) =>
              `linear-gradient(to left,${theme.palette.primary.main},${theme.palette.secondary.main})`,
          }}
        >
          <StoryIcon fontSize="small" />
          <Typography sx={{ml: 1}} noWrap>
            {story?.title}
          </Typography>
        </Paper>
      ) : (
        <CardModelsLoader sceneClientId={sceneClientId} />
      )}
    </Box>
  );
}
