import {Button} from "@mui/material";

export default function ResetCameraButton({editor3d}) {
  // strings

  const resetString = "Reset Camera";

  // handlers

  function handleClick() {
    editor3d?.cameras.resetCameraToOrigin(editor3d?.controls.activeControls);
  }
  return (
    <Button variant="outlined" size="small" onClick={handleClick}>
      {resetString}
    </Button>
  );
}
