import React from "react";

import {Box, Typography} from "@mui/material";

export default function Logo() {
  return (
    <Box
      sx={{
        background: (theme) =>
          `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`,
        height: "30px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: 2,
        color: "common.white",
      }}
    >
      <Typography sx={{fontWeight: "bold"}}> CAPLA 360</Typography>
    </Box>
  );
}
