import {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";
import useUpdateSceneRessource from "Features/ressources/hooks/useUpdateSceneRessource";
import useSelectedRessourcesGroup from "Features/ressources/hooks/useSelectedRessourcesGroup";

export default function useAutoUpdateRessourceExtractedText(scene) {
  const dispatch = useDispatch();
  // data

  const selectedRessource = useSelectedRessource();
  const sceneModule = useSceneModule();
  const pdfExtractedText = useSelector((s) => s.pdf.extractedText);
  const updateRessource = useUpdateSceneRessource(scene);

  const selectedGroup = useSelectedRessourcesGroup(scene);

  // state

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  // helper

  const {text} = pdfExtractedText;

  async function updateRes() {
    try {
      if (loading) return;
      setLoading(true);
      const newRessource = {
        ...selectedRessource,
        extractedText: pdfExtractedText,
      };
      await updateRessource(newRessource);
      const message = `Ressource ${selectedRessource.num} lié au CCTP p${pdfExtractedText.pageNumber}`;
      const triggeredAt = Date.now();
      dispatch(setSnackbarMessage({message, triggeredAt}));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("[error] update ressource extracted text", e);
    }
  }

  useEffect(() => {
    if (
      pdfExtractedText?.extractedAt &&
      sceneModule === "RESSOURCES" &&
      !loading
    ) {
      setCount((count) => count + 1);
      updateRes();
    }
  }, [pdfExtractedText?.extractedAt, sceneModule]); // if "loading" in dependencies => infinite call of the effect

  // effect to prevent loading=true to block the link.
  useEffect(() => {
    setLoading(false);
  }, [selectedGroup?.id]);
}
