import {useSelector} from "react-redux";

import {Typography, Paper} from "@mui/material";

// import {decode} from "Features/translations/utils";

export default function ListItemElevation({elevation, onClick}) {
  // data

  const shortcutElevationZ = useSelector((s) => s.sectors.shortcutElevationZ);

  // helpers

  const selected = (shortcutElevationZ.includes(elevation.id) || shortcutElevationZ.length === 0) &&
    !elevation.title;

  // helpers - style

  const cursor = elevation.title ? "default" : "pointer";
  const bgcolor = elevation.title
    ? "transparent"
    : selected
    ? "common.black"
    : "common.white";
  const color = elevation.title
    ? "common.black"
    : selected
    ? "common.white"
    : "common.black";

  let paperElevation = selected ? 12 : 1;
  if (elevation.title) paperElevation = 0;

  // handlers

  function handleClick() {
    onClick(elevation);
  }

  return (
    <Paper
      sx={{px: 1, py: 0.5, cursor, bgcolor}}
      elevation={paperElevation}
      onClick={elevation.title ? null : handleClick}
    >
      <Typography
        sx={{
          fontWeight: selected || elevation.title ? "bold" : "normal",
          fontSize: 13,
          color,
        }}
        noWrap
      >
        {elevation.name}
      </Typography>
    </Paper>
  );
}
