import {useDispatch} from "react-redux";
// import { useOutletContext } from "react-router-dom";

import {deleteSharedbox} from "../sharedboxesSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteSceneSharedbox(scene) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  const deleteSceneSharedbox = (sharedboxId) => {
    dispatch(deleteSharedbox({sharedboxId, accessToken, sceneId: scene.id}));
  };

  return deleteSceneSharedbox;
}
