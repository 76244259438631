import {useState} from "react";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  InputBase,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

export default function DialogCopyRessourcesTable({
  open,
  onCancel,
  onConfirm,
  title,
  initial,
  forceDifferent = false
}) {
  
  // string

  const saveS = "Enregistrer";
  const cancelS = "Annuler";
  const forDemoS = "Pour démo";
  
  // local state
  
  const [newName, setNewName] = useState(initial);
  const [forDemo, setForDemo] = useState(false);
  
  // handlers
  
  function handleNewNameChange(e) {
    setNewName(e.target.value);
  }
  
  async function handleRenameSave() {
    onConfirm(newName, forDemo);
  }
  
  function handleRenameCancel() {
    onCancel();
  }

  // effect

  useEffect(() => {
    setNewName(initial);
  }, [initial])
  
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <InputBase
          value={newName}
          onChange={handleNewNameChange}
          sx={{minWidth: 350, maxWidth: 450}}
        />
      </DialogContent>
      <DialogActions
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <FormControlLabel
          sx={{ml: 1}} // to keep a margin...
          control={
            <Checkbox
              size="small"
              checked={forDemo}
              onClick={() => setForDemo(!forDemo)}
              sx={{mr: 1, height: "14px", width: "14px"}}
            />
          }
          label={
            <Typography variant="body2">
              {forDemoS}
            </Typography>
          }
        />
        <Box>
          <Button onClick={handleRenameCancel}>{cancelS}</Button>
          <Button
            onClick={handleRenameSave}
            disabled={forceDifferent ? newName === initial : false}
          >
            {saveS}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}