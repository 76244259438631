import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import { DataGridPro, frFR } from "@mui/x-data-grid-pro";

import formatPriceWithSpaces from "../utils/formatPriceWithSpaces";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getRefBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

export default function DataGridPricings({ressources, sharedboxes}) {
  // strings

  const numS = "Num";
  const nameS = "Article";
  const quantityS = "Qté"
  const unitS = "Unité";

  // data

  const refPrice = useSelector((s) => s.pricing.refPrice);

  // helpers

  let isRef = null;
  const pricings = {};
  const boxesCols = [];
  sharedboxes.forEach((box) => {
    if (box.accumulatedPrice > 0) {
      const priceByRessource = {};
      box.pricings.forEach((p) => {
        priceByRessource[p.resId] = parseFloat(p.pu);
      })
      pricings[box.code] = priceByRessource;
      boxesCols.push(box.code);
      if (box.accumulatedPrice === refPrice)
        isRef = `pu${box.code}`;
    }
  })

  const rows = ressources.map((r) => {
    const extraCols = {};
    let isMin = null;
    let isMax = null;
    let minPrice = Infinity;
    let maxPrice = -Infinity;
    boxesCols.forEach((c) => {
      const p = pricings[c][r.id];
      if (p) {
        const puc =  `pu${c}`;
        extraCols[puc] = p;
        if (p < minPrice) {
          isMin = puc;
          minPrice = p;
        }
        if (p > maxPrice && isMin !==  puc) {
          isMax = puc;
          maxPrice = p;
        }
      }
    })
    return {...r, ...extraCols, isMin, isMax};
  });
  
  const numValueGetter = (params) => {
    return params.row.hideNum === true ? "" : params.row.num;
  };

  const quantityValueGetter = (params) => {
    return params.row.hardcodedQty ? params.row.hardcodedQty : params.row.quantity;
  };

  const columns = [
    {
      field: "num",
      headerName: numS,
      width: 100,
      editable: false,
      valueGetter: numValueGetter,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
      editable: false,
    },
    {
      field: "quantity",
      headerName: quantityS,
      width: 70,
      editable: false,
      valueGetter: quantityValueGetter,
    },
    {
      field: "unit",
      headerName: unitS,
      width: 70,
      editable: false,
    },
  ];

  sharedboxes.forEach((s) => {
    if (s.accumulatedPrice > 0) {
      let className = "";
      if (`pu${s.code}` === isRef)
        className = "isRef";
      columns.push({
        field: `pu${s.code}`,
        headerName: `P.U. ${s.code}`,
        headerClassName: className,
        headerAlign: "center",
        width: 100,
        editable: false,
        valueFormatter: ({ value }) => value ? `${formatPriceWithSpaces(value)} €` : "",
        align: "center",
      });
    }
  });

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        "& .super-app-theme--Title1": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.common.darkGrey,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.common.darkGrey,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Title2": {
          bgcolor: (theme) =>
            getBackgroundColor(
              theme.palette.background.default,
              theme.palette.mode
            ),
          fontWeight: "bold",
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.background.default,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Child": {
          color: "text.secondary",
          fontSize: 13,
        },
        "& .super-app-theme--NoItem": {
          color: "divider",
          fontSize: 13,
        },
        "& .super-app-theme--normal": {
          bgcolor: "common.white",
        },
        "& .isMin": {
          color: "green",
        },
        "& .isMax": {
          color: "red",
        },
        "& .isRef": {
          bgcolor: (theme) =>
            getRefBackgroundColor(
              theme.palette.stabilo.yellow,
              theme.palette.mode
            ),
        },
      }}
    >
      <DataGridPro 
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
        rows={rows}
        columns={columns}
        rowSelection={null}
        getRowClassName={(params) => {
          if (params.row.style === "title1" ||
            (params.row.isTitle && params.row.style === undefined && params.row.rank === 1)
          )
            return `super-app-theme--Title1`;
          if (params.row.style === "title2" ||
            (params.row.isTitle && params.row.style === undefined && params.row.rank === 2)
          )
            return `super-app-theme--Title2`;
          if (params.row.style === "detail")
            return `super-app-theme--Child`;
          if (params.row.count === 0) return `super-app-theme--NoItem`;
          return "super-app-theme--normal";
        }}
        getCellClassName={(params) => {
          let classes = ""
          if (params.field === params.row.isMin) {
            classes += " isMin";
          } else if (params.field === params.row.isMax) {
            classes += " isMax";
          }
          if (params.field === isRef &&
            !(params.row.style === "title1" || params.row.style === "title2" ||
              (params.row.isTitle && params.row.style === undefined && [1, 2].includes(params.row.rank))
            )
          )
            classes += " isRef";
          return classes;
        }}
      />
    </Box>
  );
}