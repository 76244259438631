import {Box, Chip, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function SelectorGenericItemWithChips({
  selectedItem,
  items,
  onCreate,
  createLabel = "Nouveau",
  onClick,
  maxHeight,
  counters, // {itemId:3, itemId:5}
  showZero,
}) {
  // helper

  const hideCreateChip = !Boolean(onCreate);

  // handler

  function handleClick(item) {
    onClick(item);
  }

  function handleCreateClick() {
    onCreate();
  }

  return (
    <Box
      sx={{
        pl: 1,
        display: "flex",
        flexWrap: "wrap",
        width: 1,
        ...(maxHeight && {maxHeight}),
        ...(maxHeight && {overflowY: "auto"}),
      }}
    >
      {items.map((item) => {
        const fromScene = item.fromScene;
        let label = item?.name ? item.name : "-";
        let selected = item.id === selectedItem?.id;
        let color = "secondary";
        let variant = selected ? "contained" : "outlined";
        let count;
        if (counters) {
          count = counters[item.id];
          if (!count) {
            color = "default";
            if (showZero) label = label + " x0";
          } else {
            label = label + " x" + count;
          }
        }
        if (fromScene) label = label + ".";

        return (
          <Chip
            sx={{
              mb: 0.5,
              mr: 0.5,
              ...(counters && !count && {color: "text.secondary"}),
            }}
            variant={variant}
            color={color}
            key={item.id}
            size="small"
            label={label}
            onClick={() => handleClick(item)}
          />
        );
      })}
      {!hideCreateChip && (
        <Tooltip title={createLabel}>
          <Chip
            sx={{ml: 2, mb: 0.5}}
            icon={<Add />}
            size="small"
            onClick={handleCreateClick}
            //color="primary"
            variant="outlined"
          />
        </Tooltip>
      )}
    </Box>
  );
}
