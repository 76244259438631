import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsViews from "./SectionActionsViews";

export default function SectionLeftPanelActionsViews({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["createView"];
  const actionsMoreKeys = [];

  return (
    <Box>
      <SectionActionsViews
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
