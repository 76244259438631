import React from "react";

import {Box, Typography, Button} from "@mui/material";
export default function SectionLagLngDeltaBase({scene, onSave, editor}) {
  const descriptionString = "Update offsets based on latitude & longitudes";
  const buttonString = "Update offset";

  // helpers

  const target = {lat: scene?.lat, lng: scene?.lng};
  const origin = {lat: scene?.worksite?.lat, lng: scene?.worksite?.lng};

  let rotation = scene?.rotation;
  if (rotation && rotation > 360) rotation = rotation - 360;
  if (rotation && rotation < -360) rotation = rotation + 360;

  let delta;
  if (typeof target.lat === "number" && typeof origin.lat === "number") {
    delta = editor.mapEditor.azmEditor.latLngOffset(origin, target);
  }
  console.log("delta", target, origin);

  let deltaString = "-";
  if (delta?.x) {
    deltaString = `Δx = ${delta.x.toFixed(3)}m, Δy = ${-delta.z.toFixed(
      3
    )}m,  ΔNorth = ${rotation.toFixed(2)}°`;
  }
  // handlers

  function handleUpdateClick() {
    onSave(delta, rotation);
  }
  return (
    <Box sx={{p: 1, bgcolor: "background.default"}}>
      <Typography variant="body2">{descriptionString}</Typography>
      <Typography variant="body2" sx={{mt: 1}}>
        {deltaString}
      </Typography>
      <Box sx={{width: 1, display: "flex", justifyContent: "flex-end", mt: 1}}>
        <Button onClick={handleUpdateClick} variant="outlined" size="small">
          {buttonString}
        </Button>
      </Box>
    </Box>
  );
}
