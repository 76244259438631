import {client} from "API/capla360";
import {remoteSceneToState} from "Features/scenes/utils";

export async function fetchPackageService({accessToken, packageId}) {
  try {
    const response = await client.get(`packages/${packageId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = response.data;
    console.log("responseData12", response.data);
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchColoringSceneService({accessToken, coloringId}) {
  try {
    // api call
    const response = await client.get(`colorings/${coloringId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // data process
    let {scene, coloring, model} = response.data;
    scene = remoteSceneToState(scene);

    let models = scene.models;

    models = models.map((m) => {
      if (m.type === "IMAGE" || m.type === "MARKERS") {
        return {...m, hidden: true};
      } else {
        return {...m, hidden: false};
      }
    });

    return {scene, models, coloring, model};
  } catch (e) {
    console.log(e);
  }
}

export async function fetchColoringsService({accessToken}) {
  try {
    const response = await client.get(`colorings/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchColoringsBySceneService({accessToken, sceneId}) {
  try {
    const response = await client.get(`scenes/${sceneId}/colorings/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    let colorings = response.data;
    colorings = colorings.map((c) => ({
      ...c,
      packageId: c.package,
      sceneId: c.scene,
      modelRemoteId: c.model,
    }));
    return colorings;
  } catch (e) {
    console.log(e);
  }
}

export async function createColoringService({accessToken, packageId}) {
  try {
    const response = await client.post(
      `packages/${packageId}/colorings/`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const responseData = response.data;
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export async function fetchColoringsByPackageService({accessToken, packageId}) {
  try {
    const response = await client.get(`packages/${packageId}/colorings/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = response.data;
    return responseData;
  } catch (e) {
    console.log(e);
  }
}
