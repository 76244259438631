import {useState, useEffect} from "react";

import {
  Box,
  TextField,
  Paper,
  InputAdornment,
  Typography,
  IconButton,
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {getLanguage} from "azure-maps-control";

function getLastChar(str) {
  if (!str) return;
  return str.charAt(str.length - 1);
}

export default function ContainerEditSector({sector, onChange, onDelete}) {
  // state

  const [tempZ, setTempZ] = useState(sector.z);
  useEffect(() => {
    setTempZ(sector.z);
  }, [sector.z]);
  // handlers

  function handleChange(field, e) {
    const newSector = {...sector};
    let value = e.target.value;
    if (field === "z") {
      let valueS = value;
      valueS = valueS.replace(",", ".");
      value =
        !isNaN(Number(valueS)) && valueS !== "" && getLastChar(valueS) !== "."
          ? Number(valueS)
          : valueS;
      setTempZ(value);
    } else {
      newSector[field] = value;
      if (onChange) onChange(newSector);
    }
  }

  function handleBlur() {
    const newSector = {...sector, z: tempZ};
    if (onChange) onChange(newSector);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Box sx={{color: "grey.400", mr: 1}}>
        <IconButton size="small" onClick={onDelete} color="inherit">
          <Delete fontSize="small" />
        </IconButton>
      </Box>
      <Box sx={{display: "flex", alignItems: "end"}}>
        <Paper sx={{bgcolor: "grey.600", mr: 1, p: 1}}>
          <TextField
            sx={{width: 100}}
            size="small"
            value={sector.code}
            onChange={(e) => handleChange("code", e)}
            InputProps={{
              sx: {
                fontSize: (theme) => theme.typography.body1.fontSize,
                color: "common.white",
              },
            }}
          />
        </Paper>
        <TextField
          size="small"
          variant="standard"
          //variant="outlined"
          value={tempZ}
          onChange={(e) => handleChange("z", e)}
          onBlur={handleBlur}
          InputProps={{
            sx: {
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: "common.white",
              borderBottom: "1px solid",
              borderBottomColor: "grey.600",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={{color: "white"}}>{"m"}</Typography>
              </InputAdornment>
            ),
          }}
          sx={{
            width: 80,
            "& .MuiInput-underline:after": {
              borderBottomColor: "primary.main",
            },
          }}
        />
      </Box>
    </Box>
  );
}
