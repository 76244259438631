import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setToolViewer} from "../viewer3DSlice";

import useSceneModule from "Features/navigation/useSceneModule";
import usePageType from "Features/navigation/usePageType";

import {Box, Paper} from "@mui/material";

import ToolShortcutsMobile from "./ToolShortcutsMobile";
import ButtonFullScreen from "./ButtonFullScreen";

import ToolbarBottomLeftViewer3D from "./ToolbarBottomLeftViewer3D";

import ButtonCloseImageOverlay from "./ButtonCloseImageOverlay";
import ToolHeightsSlider from "./ToolHeightsSlider";

import ButtonToggle2D3D from "./ButtonToggle2D3D";
import ButtonRefresh3DViewer from "./ButtonRefreshViewer3D";
import useIsFullscreen from "Features/ui/useIsFullscreen";

export default function LayerDefaultMobile({editor, scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const imageOverlay = useSelector((s) => s.viewer3D.imageOverlayMode);
  const viewMode = useSelector((state) => state.viewer3D.viewMode);
  const deltaX = Math.floor(
    useSelector((state) => state.viewer3D.multiviewsSliderPositionX)
  );
  const show3D = useSelector((state) => state.viewer3D.show3D);
  const show3DFromPdf = useSelector((s) => s.pdf.show3D);
  const fullscreen = useIsFullscreen();
  const showIssues = useSelector((s) => s.issues.showIssuesInViewer);

  const sceneModule = useSceneModule();
  const pageType = usePageType();

  const mode = useSelector((s) => s.viewer3D.mode);
  const tool = useSelector((state) => state.viewer3D.toolViewer);

  const os = useSelector((s) => s.ui.openSections);
  const openSelectionPanel = useSelector((s) => s.selection.openSelectionPanel);

  // helper

  const multiviews = viewMode === "MULTIVIEWS";

  let heightsLeft = 8;
  if (multiviews) {
    heightsLeft = show3D ? deltaX - 10 : deltaX - 24;
  }

  // helper - top

  const top = fullscreen ? "8px" : "68px";

  // helper - show bottom left toolbar

  let hideBottomLeft =
    (show3DFromPdf && sceneModule !== "EDIT_MEASUREMENTS") ||
    (["ISSUES", "MARKERS"].includes(sceneModule) && !fullscreen);
  //if (openSelectionPanel && os.outlet) hideBottomLeft = true;

  // helper - toggle

  const showTogglePdf3d =
    sceneModule === "EDIT_MEASUREMENTS" || pageType === "COLORING";

  // helper - datasetOverview

  // const showDatasetOverview =
  //   (["HOME"].includes(sceneModule) || viewer) && !os.callToAction;
  const showDatasetOverview = false;

  // helper - selectionDetail

  let showSelectionDetail =
    sceneModule !== "EDIT_MEASUREMENTS" && pageType !== "COLORING";
  showSelectionDetail = false;

  // handlers

  function handleToolClose() {
    dispatch(setToolViewer(null));
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top,
          left: "8px",
          zIndex: 20,
        }}
      >
        <ToolShortcutsMobile
          editor={editor}
          scene={scene}
          sceneViewer="3D"
          viewer={viewer}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          top,
          right: "18px",
          zIndex: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{p: 0.25, bgcolor: "common.caplaBlack", ml: 1.5}}
          elevation={12}
        >
          <ButtonToggle2D3D editor={editor} />
        </Paper>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: "16px",
          left: "16px",
          zIndex: 30,
          display: "flex",
        }}
      >
        {!show3DFromPdf && <ButtonFullScreen editor={editor} scene={scene} />}
        <Box sx={{width: "8px"}} />

        {!hideBottomLeft && (
          <ToolbarBottomLeftViewer3D
            editor={editor}
            scene={scene}
            viewer={viewer}
          />
        )}
      </Box>

      {multiviews && (
        <Box
          sx={{
            position: "absolute",
            top: (theme) => theme.spacing(16),
            bottom: (theme) => theme.spacing(16),
            left: heightsLeft,
            zIndex: 30,
          }}
          onPointerMove={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ToolHeightsSlider scene={scene} editor={editor} />
        </Box>
      )}

      {imageOverlay && (
        <Box
          sx={{
            position: "absolute",
            bottom: "8px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 30,
          }}
        >
          <ButtonCloseImageOverlay editor={editor} />
        </Box>
      )}
    </>
  );
}
