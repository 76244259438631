import {useSelector, useDispatch} from "react-redux";

import {Typography, Paper, IconButton} from "@mui/material";
import {Image} from "@mui/icons-material";

import {setSelectedIssueId} from "Features/issues/issuesSlice";
import {setSelectedIssueId as setSelectedIssueIdInModule} from "Features/ui/uiSlice";
import {setShortcutElementTypes} from "Features/measurements/measurementsSlice";
import {
  setSelectedModelId,
  setSelectedPdfModelId,
} from "Features/viewer3D/viewer3DSlice";
import { setEnableZonesInViewer3d, setUseBufferInShortcut, setZonesInBuffer } from "Features/zones/zonesSlice";
import { setSelectionTabInViewer } from "Features/selection/selectionSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function ListItemIssue({issue, caplaEditor, scene}) {
  const dispatch = useDispatch();

  // data

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.enabled
  );
  const zones = useZonesByScene(scene);
  const types = useElementTypesBySceneProxy(scene);
  const selectedPdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);
  const selectedIssueId = useSelector((s) => s.issues.selectedIssueId);

  // helpers

  const imageModels = models.filter((m) => m.type === "IMAGE");

  const selected = issue.id === selectedIssueId;

  let bgcolor = selected ? "primary.flash" : "common.caplaBlack";
  const color = selected ? "common.white" : "common.white";

  let badImage;
  issue.content?.map((c) => {
    if (c.type === "image" && c.url.includes("base64")) badImage = true;
  });

  const emptyContext = {
    imageModelIds: [],
    visibleModelsIds: [],
    measurementsFilters: {
      disabledTypes: [],
      elementTypes: [],
      materials: [],
      measurementsModelIds: [],
      p1s: [],
      p2s: [],
      p3s: [],
      phases: [],
      ressources: [],
      rooms: [],
      sectors: [],
      zones: [],
    },
  };

  // handlers

  function handleIssueClick() {
    if (issue.id !== selectedIssueId) {
      dispatch(setSelectedIssueId(issue.id));
      dispatch(setSelectedIssueIdInModule(issue.id));
      dispatch(setSelectionTabInViewer("issue"));
      const sceneContext = issue.context?.sceneContext;
      if (sceneContext)
        caplaEditor?.editor3d?.sceneEditor.applyContext(sceneContext, false);
      dispatch(setShortcutElementTypes(types.map((t) => t.id)));
      const issueModelIds = sceneContext.imageModelIds;
      if (issueModelIds?.length > 0) {
        const zonesForBuffer = issueModelIds.map((mid) => (
          zones.find((z) => z.imageModelId === mid).filter((z) => z)
        ));
        dispatch(setZonesInBuffer(zonesForBuffer));
        dispatch(setEnableZonesInViewer3d(true));
      }
    } else {
      dispatch(setSelectedIssueId(null));
      dispatch(setSelectedIssueIdInModule(null));
      dispatch(setZonesInBuffer([]));
      dispatch(setEnableZonesInViewer3d(false));
      caplaEditor?.editor3d?.sceneEditor.applyContext(emptyContext);
    }
  }

  function handleFocusClick(e) {
    e.stopPropagation();
    console.log("issueFocus", issue, imageModels);
    const issuePdfModelId = issue?.context?.pdfContext?.modelId;
    const sceneContext = issue?.context?.sceneContext;
    const issueModelIds = sceneContext?.imageModelIds;
    if (issueModelIds?.length > 0) {
      const zonesForBuffer = issueModelIds.map((mid) => (
        zones.find((z) => z.imageModelId === mid)
      )).filter((z) => z);
      dispatch(setZonesInBuffer(zonesForBuffer));
      if (sceneContext) {
        caplaEditor?.editor3d?.sceneEditor.hideAllImageModels();
        caplaEditor?.editor3d?.sceneEditor.applyContext(sceneContext);
      }
      dispatch(setUseBufferInShortcut(true));
      dispatch(setEnableZonesInViewer3d(true));
    }
    if (issuePdfModelId) {
      if (issuePdfModelId === selectedPdfModelId) {
        if (!selected) {
          caplaEditor?.editorPdf?.setPdfContext(issue.context.pdfContext);
        }
      } else {
        dispatch(setSelectedModelId(issuePdfModelId));
        dispatch(setSelectedPdfModelId(issuePdfModelId));
      }
    }
    if (issue.fromViewMode === "2D" || issue.fromViewMode === "3D") {
      const sceneContext = issue.context?.sceneContext;
      if (sceneContext) caplaEditor?.editor3d?.sceneEditor.applyContext(sceneContext, true, false);
    }
  }

  return (
    <Paper
      sx={{
        px: 1,
        py: 0.5,
        cursor: "pointer",
        maxWidth: 250,
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        bgcolor,
        color,
        height: 24,
        borderRadius: 12,
        ...(badImage && {border: "3px solid red"}),
      }}
      elevation={selected ? 6 : 1}
      onClick={handleIssueClick}
    >
      <Typography sx={{fontWeight: "bold", fontSize: 13, mr: 1, ml: 0}} noWrap>
        {issue.title}
      </Typography>
      <IconButton
        size="small"
        onClick={(e) => handleFocusClick(e)}
        color="inherit"
      >
        <Image fontSize="small" color="inherit" />
      </IconButton>
    </Paper>
  );
}
