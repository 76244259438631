import React from "react";

import {Button} from "@mui/material";
import {Download} from "@mui/icons-material";
import downloadExcelFileFromWorkbook from "../utils/downloadExcelFileFromWorkbook";
import createExcelWorkbook from "../utils/createExcelWorkbook";

export default function ButtonDownloadExcelFromTemplate({
  template,
  data,
  fileName,
  fullWidth = false,
  variant = "text",
}) {
  // strings

  const downloadS = "Excel";

  // handlers

  async function handleClick() {
    console.log("create excel from", data, template);
    try {
      const wb = await createExcelWorkbook({template, data});
      downloadExcelFileFromWorkbook(wb, fileName);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Button
      startIcon={<Download />}
      onClick={handleClick}
      size="small"
      color="inherit"
      fullWidth={fullWidth}
      variant={variant}
    >
      {downloadS}
    </Button>
  );
}
