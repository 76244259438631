import {useSelector} from "react-redux";

import {List, ListItemButton, Divider, Box} from "@mui/material";

import NotePreview from "./NotePreview";

export default function NotesList({notes, scene, editor3d, onNoteClick}) {
  // data

  const models = useSelector((state) => state.viewer3D.models).filter(
    (m) => m.sceneClientId === scene.clientId
  );

  // handlers

  async function handleViewpointClick(viewpoint) {
    console.log(viewpoint);
    const {name, position, target, context, image} = viewpoint;
    editor3d?.cameras.setActiveCameraTo({
      name,
      position,
      target,
      controls: editor3d?.controls.orbitControls,
    });
    editor3d?.context.setContext({context, models});
    await editor3d?.imageOverlay.setImage(image);
  }

  function handleNoteClick(note) {
    onNoteClick(note);
  }

  return (
    <List dense sx={{py: 0, height: 1, overflow: "auto"}}>
      {notes
        ?.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((note) => (
          <Box key={note.id}>
            <ListItemButton
              disableRipple
              sx={{p: 0, backgroundColor: "common.white"}}
              onClick={() => handleNoteClick(note)}
            >
              <NotePreview
                note={note}
                onViewpointClick={handleViewpointClick}
              />
            </ListItemButton>
            <Divider variant="inset" component="li" />
          </Box>
        ))}
      <Box sx={{height: (theme) => theme.spacing(10)}} />
    </List>
  );
}
