import {useSelector} from "react-redux";

// import useSelection from "Features/selection/useSelection";

export default function useSelectedIssue() {
  const issues = useSelector((s) => s.issues.items);
  const selectedIssueId = useSelector((s) => s.issues.selectedIssueId);
  const selectedIssue = issues.find((i) => i.id === selectedIssueId);
  return selectedIssue;
}
