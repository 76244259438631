// Add types to the articleTypes.
// The added types are the children of the elementTypes.
// Return the articleTypes
import copyTypes from "./copyTypes";

import getElementTypeChildren from "Features/elementTypes/utils/getElementTypeChildren";
import getElementTypesByGroupIdById from "Features/elementTypes/utils/getElementTypesByGroupIdById";
import parseElementTypeArticleQties from "./parseElementTypeArticleQties";

export default function updateArticleTypesWithElementTypesChildren(
  article,
  groupsWithSortedElementTypes
) {
  const types = article.types;
  if (!types) return [types, false];

  // STEP 1 : prepare data

  const elementTypesByGroupIdById = getElementTypesByGroupIdById(
    groupsWithSortedElementTypes
  );

  //

  let isUpdated = false;
  let newTypes = {};
  let oldTypes = copyTypes(types);
  //

  //
  Object.entries(oldTypes).forEach(([groupId, typesMap]) => {
    if (groupId === "filtersStr") {
      newTypes[groupId] = typesMap; // we use this to keep the global func.
      //updatedTypes[groupId] = typesMap; // it m
      //
      // IF WE APPLY EVERY ELEMENT TYPES...AT GLOBAL SCOPE
      // if (typesMap.length > 0 && types["globalFunc"] === "TOTAL" && false) {
      //   // we don't get object here. we keep this behaviour for subarticles computation.
      //   //
      //   let updatedTypesArray = [{func: "TOTAL", filtersStr: typesMap}];
      //   //
      //   groupsWithSortedElementTypes.forEach((group) => {
      //     if (group?.id) {
      //       const elementTypes = group.elementTypes;
      //       elementTypes.forEach((elementType) => {
      //         const articleQties = elementType.articleQties;
      //         if (articleQties) {
      //           const articleCodeFuncArray =
      //             parseElementTypeArticleQties(articleQties);
      //           articleCodeFuncArray.forEach(({articleCode, func}) => {
      //             const props = {func, filtersStr: typesMap}; //typesMap = filtersSt of global scope.
      //             if (article.code === articleCode) {
      //               updatedTypes = {
      //                 ...updatedTypes,
      //                 [group.id]: {
      //                   ...updatedTypes[group.id],
      //                   [elementType.id]: props,
      //                 },
      //               };
      //               // we use the func specified in the articleQties prop of the elementTy=pe
      //               isUpdated = true;
      //             }
      //           });
      //         }
      //       });
      //     }
      //   });
      // }
    } else if (groupId !== "globalFunc") {
      newTypes[groupId] = {};
      Object.entries(typesMap).forEach(([typeId, props]) => {
        if (typeId === "filtersStr") {
          newTypes[typeId] = props;
        } else {
          // we upate the article based on the elementType.
          const elementType = elementTypesByGroupIdById[groupId][typeId];
          const articleQties = elementType?.articleQties;
          //

          //
          //
          if (article.name === "Vides") {
            console.log(
              "articleQties Béton",
              article.name,
              elementType?.name,
              "qties",
              elementType?.articleQties
            );
          }

          //
          if (articleQties) {
            const articleCodeFuncArray =
              parseElementTypeArticleQties(articleQties);
            articleCodeFuncArray.forEach(({articleCode, func}) => {
              if (article.code === articleCode) {
                // we do that only if we decide to update the item.
                newTypes[groupId][typeId] = {
                  ...props,
                  func, // we use the func specified in the articleQties prop of the elementType.
                };
                isUpdated = true;
              }
            });
          }
          // add elementType children
          let elementTypes = groupsWithSortedElementTypes.find(
            (g) => g.id === groupId
          )?.elementTypes;

          const elementTypeChildren = getElementTypeChildren(
            elementType?.index,
            elementTypes
          );
          // if (article.name === "Béton") {
          //   console.log(
          //     "elementTypeChildren",
          //     article.name,
          //     elementTypeChildren
          //   );
          // }

          // console.log(
          //   "elementTypeChildren",
          //   elementType.name,
          //   elementTypeChildren.map((c) => c.name)
          // );
          if (Array.isArray(elementTypeChildren)) {
            elementTypeChildren.forEach((child) => {
              const articleQties =
                child.articleQties?.length > 0
                  ? child.articleQties
                  : elementType.articleQties;

              // if (article.name === "Béton") {
              //   console.log(
              //     "elementTypeChild articleQties",
              //     articleQties,
              //     article.name,
              //     child?.name
              //   );
              // }
              if (articleQties) {
                const articleCodeFuncArray =
                  parseElementTypeArticleQties(articleQties);
                articleCodeFuncArray.forEach(({articleCode, func}) => {
                  if (article.code === articleCode) {
                    //
                    if (article?.name === "Béton") {
                      console.log(
                        "Link element type",
                        child.name,
                        "to article",
                        article.name,
                        func
                      );
                    }

                    // we have a canditate to add to the article types.
                    // the challenge is to know if we should keep it or not: the typeItem may exist.
                    const typeItemCanditate = {groupId, typeId: child.id, func};

                    const typeInOldTypes = oldTypes[groupId]?.[child.id];

                    if (!typeInOldTypes || !typeInOldTypes.func) {
                      newTypes[groupId][child.id] = {
                        ...props,
                        func, // we use the func specified in the articleQties prop of the elementType.
                      };
                      isUpdated = true;
                    }
                  }
                });
              }
            });
          }
        }

        //
        // we keep the type is not matched where found.
        // no more valid thanks to the code in the children loop
        // if (!updatedTypes[groupId][typeId])
        //   updatedTypes[groupId][typeId] = props;
      });
    }
  });

  console.log("updatedTypesRESULT", newTypes);

  return [newTypes, isUpdated];
}
