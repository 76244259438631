import {useKvfTranslationMap} from "./useKvfTranslationMap";

import useRessourceGroupsByScene from "Features/ressources/hooks/useRessourceGroupsByScene";

export default function useKvtRessource(scene, ressource) {
  const translationMap = useKvfTranslationMap();

  const ressourceGroups = useRessourceGroupsByScene(scene);
  const options = ressourceGroups
    .filter((g) => g)
    .map((g) => ({id: g, name: g}));

  const kvt = {
    key: "ressource",
    groups: [
      {code: "PROPS", label: translationMap["props"]},
      {code: "INFO", label: translationMap["info"]},
      {code: "ELEMENTS", label: translationMap["elements"]},
    ],
    fields: [
      {
        key: "identification",
        name: translationMap["identification"],
        type: "section",
        group: "PROPS",
      },
      {
        key: "name",
        name: translationMap["name"],
        type: "text",
        group: "PROPS",
      },
      // {
      //   key: "code",
      //   name: translationMap["code"],
      //   type: "text",
      //   group: "PROPS",
      // },
      {
        key: "tree",
        name: translationMap["tree"],
        type: "section",
        group: "PROPS",
      },
      {
        key: "group",
        name: translationMap["table"],
        type: "combo",
        options: options,
        group: "PROPS",
      },
      {
        key: "num",
        name: translationMap["num"],
        type: "text",
        group: "PROPS",
      },
      {
        key: "isTitle",
        name: translationMap["isTitle"],
        type: "checkbox",
        group: "PROPS",
      },
      {
        group: "PROPS",
        key: "style",
        type: "selector",
        width: 70,
        name: translationMap["style"],
        options: [
          {key: "title1", name: translationMap["title1"]},
          {key: "title2", name: translationMap["title2"]},
          {key: "normal", name: translationMap["normal"]},
          {key: "detail", name: translationMap["detail"]},
        ],
      },
      {
        key: "hideNum",
        name: translationMap["hideNum"],
        type: "checkbox",
        group: "PROPS",
      },
      {
        key: "props",
        name: translationMap["props"],
        type: "section",
        group: "PROPS",
      },
      {
        key: "refQty",
        name: translationMap["refQty"],
        type: "number",
        group: "PROPS",
      },
      {
        key: "hardcodedQty",
        name: translationMap["hardcodedQty"],
        type: "number",
        group: "PROPS",
      },
      {
        key: "unit",
        name: translationMap["unit"],
        type: "text",
        group: "PROPS",
      },
      {
        key: "dec",
        name: translationMap["decimal"],
        type: "number",
        group: "PROPS",
      },
      {
        key: "ratioV",
        name: translationMap["ratio"],
        type: "number",
        group: "PROPS",
      },
      {
        key: "ratioU",
        name: translationMap["ratioU"],
        type: "selector",
        options: [
          {key: "UN", name: translationMap["UN"]},
          {key: "ML", name: translationMap["ML"]},
          {key: "M2", name: translationMap["M2"]},
          {key: "M3", name: translationMap["M3"]},
        ],
        group: "PROPS",
      },
      {
        key: "pu",
        name: translationMap["pu"],
        type: "number",
        group: "PROPS",
      },
      {
        key: "noPricing",
        name: translationMap["noPricing"],
        type: "checkbox",
        group: "PROPS",
      },
      {
        key: "description",
        name: translationMap["description"],
        type: "textMultiline",
        group: "INFO",
      },
      {
        key: "location",
        name: translationMap["location"],
        type: "textMultiline",
        group: "INFO",
      },
      {
        key: "webUrl",
        name: translationMap["webUrl"],
        type: "textMultiline",
        group: "INFO",
      },
    ],
  };

  if (ressource?.id)
    kvt.fields.push({
      key: "types",
      name: translationMap["elements"],
      type: "relations",
      group: "ELEMENTS",
    });

  return kvt;
}
