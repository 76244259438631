import {useState} from "react";
import {useDispatch} from "react-redux";

import {
  Box,
  Button,
  IconButton,
  Avatar,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Menu,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Save, Close} from "@mui/icons-material";

import useProfile from "../useProfile";
import {getNameFromEmail, getInitialsFromName} from "../utils";
import {updateProfile} from "../profileSlice";
import usePageType from "Features/navigation/usePageType";

import useAccessToken from "Features/auth/useAccessToken";
import LanguageSelector from "Features/translations/components/LanguageSelector";
import SignInSignOutButton from "Features/auth/components/SignInSignOutButton";
import SignInButton from "Features/auth/components/SignInButton";
import CheckboxIsStagingEnv from "Features/overviewer/hooks/CheckboxIsStagingEnv";

export default function ButtonProfile() {
  const dispatch = useDispatch();

  // strings

  const editString = "Editer le profil";
  const title = "Your profile";
  const nameString = "Name";
  const cancelString = "Cancel";
  const saveString = "Save";

  // data

  const {data: profile} = useProfile();
  const accessToken = useAccessToken();
  const pageType = usePageType();

  // helpers

  const email = profile?.user;

  let initName = profile?.name;
  if (initName?.length === 0) initName = getNameFromEmail(email);

  const initials = getInitialsFromName(initName);

  console.log("profile", profile, initName, email);

  // state

  const [openMenu, setOpenMenu] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState(initName);
  const [loading, setLoading] = useState(false);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleMenuOpen(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleEditOpen() {
    setOpenDialog(true);
  }
  function handleEditClose() {
    setOpenDialog(false);
  }
  function handleEditCancel() {
    setOpenDialog(false);
    setLoading(false);
  }
  async function handleEditSave() {
    if (name.length > 0) {
      setLoading(true);
      const updates = {...profile.data, name};
      await dispatch(updateProfile({accessToken, updates}));
      setLoading(false);
      setOpenDialog(false);
    }
  }

  return (
    <>
      {!email && (
        <Box sx={{mx: 1}}>
          <SignInButton {...(pageType === "HOME" && {color: "white"})} />
        </Box>
      )}
      {email && (
        <Box sx={{color: "common.caplaBlack", mx: 1}}>
          <IconButton onClick={handleMenuOpen} size="small" color="inherit">
            <Avatar
              sx={{
                width: 30,
                height: 30,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                bgcolor: "secondary.main",
                color: "common.white",
              }}
            >
              <Typography>{initials}</Typography>
            </Avatar>
          </IconButton>
          <Menu
            open={open}
            onClose={handleMenuClose}
            anchorEl={anchorEl}
            transformOrigin={{horizontal: "right", vertical: "top"}}
            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            sx={{m: 0, p: 0, "& .MuiList-root": {p: 0}}}
          >
            <Box
              sx={{
                display: "flex",
                width: 230,
                flexDirection: "column",
                alignItems: "center",
                p: 0,
                m: 0,
              }}
            >
              {profile.logoUrl && (
                <Box>
                  <img src={profile.logoUrl} width="100%" height="auto" />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{p: 1}}>
                  <Typography variant="body2">{email}</Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    borderRadius: "100%",
                    bgcolor: "common.white",
                  }}
                >
                  <IconButton size="small" onClick={handleMenuClose}>
                    <Close fontSize="small" color="common.darkGrey" />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{mx: 1, mb: 1, mt: 2}}>
                <Button onClick={handleEditOpen}>{editString}</Button>
              </Box>
              <Box sx={{mx: 1}}>
                <LanguageSelector />
              </Box>
              <Box sx={{m: 1}}>
                <SignInSignOutButton avatar={false} color={"black"} />
              </Box>
            </Box>
            <Box sx={{bgcolor: "background.default", mt: 4, px: 2}}>
              <CheckboxIsStagingEnv />
            </Box>
          </Menu>
        </Box>
      )}
      <Dialog open={openDialog} onClose={handleEditClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{mt: 2}}>
            <TextField
              sx={{mt: 2}}
              label={<Typography variant="body2">{nameString}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel}>{cancelString}</Button>
          <LoadingButton
            loading={loading}
            startIcon={<Save />}
            loadingPosition="start"
            onClick={handleEditSave}
          >
            {saveString}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
