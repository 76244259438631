import {useSelector, useDispatch} from "react-redux";

import {setSelectedViewId} from "../viewsSlice";

import {useNavigate} from "react-router-dom";

import {List, ListItemButton, Typography} from "@mui/material";
import useViewsByScene from "../hooks/useViewsByScene";

export default function ListViews({scene}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // data

  const views = useViewsByScene(scene, {sortByName: true});
  const selectedViewId = useSelector((s) => s.views.selectedViewId);

  // handlers

  function handleClick(e, viewId) {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSelectedViewId(viewId));
  }

  function handleLinkClick(e, viewId) {
    e.stopPropagation();
    e.preventDefault();
    const url = `bimboxes/${scene?.id}/views/${viewId}`;
    navigate(url);
  }

  return (
    <List dense>
      {views.map((view) => {
        const selected = view.id === selectedViewId;

        return (
          <ListItemButton
            key={view.id}
            onClick={(e) => handleClick(e, view.id)}
            selected={selected}
          >
            {
              <Typography
                onClick={(e) => handleLinkClick(e, view.id)}
                sx={{":hover": {textDecoration: "underline"}}}
              >
                {view.name}
              </Typography>
            }
          </ListItemButton>
        );
      })}
    </List>
  );
}
