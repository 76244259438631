import { useSelector } from "react-redux";

import useFilteredMeasurementsFromPackages from "./useFilteredMeasurementsFromPackages";

import getNumForSorting from "Utils/getNumForSorting";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import getItemsMapById from "Utils/getItemsMapById";

function getQuantity(unit, m, filterNoVoids) {
  let q;
  const length = !filterNoVoids && m.lengthNet ? m.lengthNet : m.length;
  const area = !filterNoVoids && m.areaNet ? m.areaNet : m.area;
  const volume = !filterNoVoids && m.volumeNet ? m.volumeNet : m.volume;
  if (unit === "UN") q = m.count;
  if (unit === "ML") q = length;
  if (unit === "M2") q = area;
  if (unit === "M3") q = volume;
  return q;
}

export default function useMeasurementsWithElementTypes(scene) {
  // data

  let measurements = useFilteredMeasurementsFromPackages({
    scene,
    filterPackagesByScope: false,
    filterPackagesByVersion: false,
    filterPackagesBySelectedPdf: false,
    filterPackagesBySelectedMeasurementsModel: false,
  });
  const elementTypes = useElementTypesBySceneProxy(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const models = useSelector((s) => s.viewer3D.models);
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  if (!measurements) return [];

  const dontUseForQuantities = new Set(
    models.filter((m) => m.excludeFromQties).map((m) => m.id)
  );
  if (dontUseForQuantities.size > 0)
    measurements = measurements.filter(
      (m) => !dontUseForQuantities.has(m.measurementsModelId)
    );

  // helpers

  const roomsById = getItemsMapById(rooms);
  const sectorsById = getItemsMapById(sectors);
  const elementTypesById = getItemsMapById(elementTypes);

  // measurements & types

  const measWithType = [];
  measurements.forEach((m) => {
    const elementType = elementTypesById[m.elementTypeId];
    const room = roomsById[m.roomId];
    const sector = sectorsById[m.sectorId];
    measWithType.push({
      id: m.id,
      code: m.codeName,
      isVoid: m.isVoid,
      element_type_group: elementType?.group,
      element_type_num: elementType?.num ? elementType?.num : "0",
      element_type_num_for_sorting: elementType?.num
        ? getNumForSorting(elementType?.num)
        : "0",
      element_type_name: elementType?.name ? elementType.name : "-",
      element_type_unit: elementType?.unit,
      element_type_quantity_value: getQuantity(
        elementType?.unit,
        m,
        filterNoVoids
      ),
      sector_name: sector?.name ? sector.name : "-",
      sector_building: sector?.building ? sector.building : "-",
      sector_z: sector?.z ? sector.z : "-",
      room_sector: room?.sector ? room.sector : "-",
      room_num: room?.num ? room.num : "-",
      room_name: room?.name ? room.name : "-",
      room_type: room?.type ? room.type : "-",
      material_name: m.material?.name ? m.material.name : "-",
    });
  });
  return measWithType;
}
