import {Box, LinearProgress, Typography} from "@mui/material";

import bimbox from "Assets/bimbox.svg";
import base64 from "Assets/base64";

import {keyframes} from "@mui/system";

// const zoomInOut = keyframes`
//   from {
//     filter: "hue-rotate(0deg)"
//   }
//   to {
//     filter: "hue-rotate(90deg)"
//   }
// `;
const zoomInOut = keyframes`
  from {
    width: 40
  }
  to {
    width: 500"
  }
`;

export default function ContainerLoadingBimbox() {
  const label = "Chargement de la bimbox ...";

  return (
    <Box
      sx={{
        bgcolor: "common.caplaBlack",
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: 300,
          height: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <img
          src={`data:image/svg+xml;base64,${base64}`}
          width="100%"
          sx={{filter: "hue-rotate(90deg)"}}
          //style={{filter: "hue-rotate(90deg)"}}
          // style={{
          //   animation: `${zoomInOut} 2s infinite ease alternate`,
          //   animationName: "zoomInOut",
          // }}
        /> */}
        <Box
          sx={{
            backgroundSize: "contain", //: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            position: "relative",
            backgroundImage: `url(${`data:image/svg+xml;base64,${base64}`})`,
            height: 1,
            width: 1,
            borderRadius: "4px",
            filter: "hue-rotate(90deg)",
            animation: `ripple 2s ease-in-out alternate infinite`,
            "@keyframes ripple": {
              "0%": {
                //transform: "scale(.8)",
                opacity: 1,
                filter: "hue-rotate(0deg) invert(0)",
              },
              "100%": {
                //transform: "scale(2.4)",
                opacity: 0.8,
                filter: "hue-rotate(180deg) invert(1)",
              },
            },
          }}
        />
      </Box>
    </Box>
  );
}
