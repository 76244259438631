import polyline from "./assets/polyline.png";
import gable from "./assets/gable.png";
import polygon from "./assets/polygon.png";
import segment from "./assets/segment.png";
import rectangle from "./assets/rectangle.png";
import circle from "./assets/circle.png";
import slope from "./assets/slope.png";
import stairs from "./assets/stairs.png";
import beam from "./assets/beam.png";
import bank from "./assets/bank.png";
import slopingPolygon from "./assets/slopingPolygon.png";
import bridge from "./assets/bridge.png";
import bowl from "./assets/bowl.png";

export default function useDrawingShapeImage(drawingShape) {
  const helperImageMap = {
    POLYLINE: polyline,
    GABLE: gable,
    POLYGON: polygon,
    SEGMENT: segment,
    RECTANGLE: rectangle,
    CIRCLE: circle,
    SLOPE: slope,
    STAIRS: stairs,
    BEAM: beam,
    BANK: bank,
    SLOPING_POLYGON: slopingPolygon,
    BRIDGE: bridge,
    BOWL: bowl,
  };
  return helperImageMap[drawingShape];
}
