import {useSelector} from "react-redux";

export default function useScopesByScene(scene, options) {
  const addScopeAll = options?.addScopeAll;
  const sortByName = options?.sortByName;

  let allScopes = useSelector((s) => s.scopes.items);
  allScopes = allScopes ?? [];

  const sceneScopes = allScopes.filter((s) => s.sceneId === scene?.id);

  const scopeAll = {
    id: "all",
    name: "Tous les éléments",
    description: "Afficher tous les éléments de la bimbox.",
  };

  let result = scene ? [...sceneScopes] : [...allScopes];

  if (sortByName)
    result = result.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

  if (addScopeAll) result.push(scopeAll);

  return result;
}
