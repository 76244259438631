import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Input,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { setEditedRelations, setRelationsTypesRessources, setRelationsTypesRessourcesToRestore } from "../relationsSlice";

import sortByNum from "Utils/sortByNum";
import getFilteredNomenclatureWithItems from "Utils/getFilteredNomenclatureWithItems";
import IconElementType from "Features/elementTypes/components/IconElementType";
import Stretch from "Features/ui/components/Stretch";

export default function SectionUpdateRelationsFormula({
  module,
  usedGroups,
  item,
  rows,
  relations,
  itemRelations,
  onChange
}) {
  const dispatch = useDispatch();
  
  // strings
  
  let noRelationsS = "Aucune relation pour ";

  // data

  const relationsToRestore = useSelector(
    (s) => s.relations.relationsTypesRessourcesToRestore
  );
  const editedRelations = useSelector((s) => s.relations.editedRelations);

  // state

  const [formula, setFormula] = useState({});
  
  // helpers

  const styleMap = {
    DEFAULT: {fontWeight: "normal", bgcolor: "inherit", color: "inherit"},
    TITLE1: {fontWeight: "bold", bgcolor: "#bbbbc0", color: "text.primary"},
    TITLE2: {fontWeight: "bold", bgcolor: "background.default", color: "text.primary"},
    DETAIL: {fontWeight: "normal", bgcolor: "common.white", color: "text.secondary"},
  };

  if (module === "ELEMENT_TYPES") {
    noRelationsS += "ce type d'élément";
  } else if (module === "RESSOURCES") {
    noRelationsS += "cet article";
  }

  let currentGroup;
  const alreadyDisplayed = new Set();

  // handlers

  const handleFormulaChange = (e, id) => {
    const newFormula = {...formula};
    newFormula[id] = e.target.value;
    setFormula(newFormula);
  }

  const handleEdit = (id) => {
    if (!relationsToRestore)
      dispatch(setRelationsTypesRessourcesToRestore(relations));
    const relationsToEdit = {...editedRelations};
    const updatedRelations = {...relations};
    if (module === "ELEMENT_TYPES") {
      const relKey = `${item.id}-${id}`;
      updatedRelations[relKey] = {
        id: relKey,
        typeId: item.id,
        resId: id,
        mode: "FORMUL",
        func: formula[id],
      };
      relationsToEdit[relKey] = "UPDATE";
    } else if (module === "RESSOURCES") {
      const relKey = `${id}-${item.id}`;
      updatedRelations[relKey] = {
        id: relKey,
        typeId: id,
        resId: item.id,
        mode: "FORMUL",
        func: formula[id],
      };
      relationsToEdit[relKey] = "UPDATE";
    }
    dispatch(setEditedRelations(relationsToEdit));
    dispatch(setRelationsTypesRessources(updatedRelations));
    onChange();
  }

  // effect

  useEffect(() => {
    const initFormula = {};
    itemRelations.forEach((rel) => {
      if (module === "ELEMENT_TYPES")
        initFormula[rel.resId] = rel.func;
      else if (module === "RESSOURCES")
        initFormula[rel.typeId] = rel.func;
    })
    setFormula(initFormula);
  }, [itemRelations])

  return (
    <Stretch>
    {itemRelations.length > 0 ? (
      <List dense disablePadding sx={{pt: 2, height: 1, overflowY: "auto", pb: "52px"}} >
        {rows.map((it) => {
          if (it.isGroup) {
            currentGroup = it;
            const primaryText = it?.num?.length > 0 ?
            `${it?.num} • ${it?.name}` : it?.name;
            return (
              <ListItem
                key={it.id}
                disablePadding
                dense
                divider
                sx={{
                  p: 0,
                  m: 0,
                  bgcolor: "common.caplaBlack",
                  color: "common.white",
                }}
              >
                <ListItemText>
                  <Typography sx={{fontSize: 12, px: 2}}>
                    {primaryText}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          }
          const toReturn = [];
          const contextItems = getFilteredNomenclatureWithItems(usedGroups[currentGroup.name], [it]);
          contextItems.sort((a, b) => sortByNum(a.num, b.num)).map((ci) => {
            if (!alreadyDisplayed.has(ci.id)) {
              alreadyDisplayed.add(ci.id);
              const primaryText = ci?.num?.length > 0 ?
              `${ci?.num} • ${ci?.name}` : ci?.name;
              let styleType = "DEFAULT";
              if (ci.isTitle && ci.rank === 1 && ci.style === undefined)
                styleType = "TITLE1";
              if (ci.isTitle && ci.rank === 2 && ci.style === undefined)
                styleType = "TITLE2";
              if (ci.style === "title1") styleType = "TITLE1";
              if (ci.style === "title2") styleType = "TITLE2";
              if (ci.style === "detail") styleType = "DETAIL";
              const {fontWeight, bgcolor, color} = styleMap[styleType];
              toReturn.push(
                <ListItem
                  key={ci.id}
                  disablePadding
                  dense
                  divider
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 0,
                    m: 0,
                    bgcolor,
                    color,
                  }}
                >
                  <ListItemText>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                      px: 2,
                      m: 0,
                    }}>
                      {module === "RESSOURCES" && !ci.isTitle && (
                        <Box sx={{pr: 1}}>
                          <IconElementType
                            color={ci.color}
                            drawingShape={ci.drawingShape}
                            size={"small"}
                          />
                        </Box>
                      )}
                      <Typography sx={{fontWeight, fontSize: 12}}>
                        {primaryText}
                      </Typography>
                    </Box>
                    {ci.id === it.id && (
                      <Box sx={{px: 1}}>
                        <Input
                          fullWidth
                          sx={{
                            fontSize: 13,
                            bgcolor: "common.white",
                            px: 1,
                            border:"1px solid grey",
                            borderRadius:"4px"
                          }}
                          value={formula[ci.id]}
                          onChange={(e) => handleFormulaChange(e, ci.id)}
                          onBlur={() => handleEdit(ci.id)}
                          disableUnderline
                        />
                      </Box>
                    )}
                  </ListItemText>
                </ListItem>
              );
            }
          })
          return toReturn;
        })}
      </List>
    ) : (
      <Box sx={{px: 2}}>
        <Typography variant="body2">
          {noRelationsS}
        </Typography>
      </Box>
      )}
    </Stretch>
  );   
}