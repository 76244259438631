import SelectorAutocompleteMultipleWithDialog from "Components/SelectorAutocompleteMultipleWithDialog";

import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";

export default function FieldSelectorInputs({scene, value, onChange, locked}) {
  // data

  const nameMap = useSceneModuleNameMap();
  let inputs = useInputsByScene(scene);

  // helpers - options

  const options = inputs.map((input) => ({
    ...input,
  }));
  // helpers

  const name = nameMap["INPUTS"];

  // helpers - selection

  const selection = options.filter((i) => value.includes(i.id));

  // handlers

  function handleChange(selectedInputs) {
    onChange(selectedInputs.map((z) => z.id));
  }

  return (
    <SelectorAutocompleteMultipleWithDialog
      name={name}
      options={options}
      value={selection}
      onChange={handleChange}
      disabled={locked}
      checkAll={true}
    />
  );
}
