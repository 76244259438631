import {SphereGeometry, MeshBasicMaterial, Mesh} from "three";

class OrbitCenter {
  constructor() {
    this.object = this._create();
  }

  _create() {
    const geometry = new SphereGeometry(0.005, 16, 16);
    const material = new MeshBasicMaterial({color: 0xf52585});
    return new Mesh(geometry, material);
  }

  enable({position}) {
    if (!position) {
      position = {x: 0, y: 0, z: 0};
    }
    this.object.position.set(position.x, position.y, position.z);
    this.object.layers.enable(1);
  }

  disable() {
    this.object.layers.disable(1);
  }

  load(scene) {
    scene.add(this.object);
  }
}

export default OrbitCenter;
