import {client} from "API/capla360";
import {remoteSceneToState} from "Features/scenes/utils";

export async function fetchColoringJobsService({accessToken, sceneId}) {
  try {
    const response = await client.get(`scenes/${sceneId}/coloringJobs/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = response.data;
    console.log("responseData90", response.data);
    return responseData.map((job) => ({
      ...job,
      sceneId: job.scene,
      packageId: job.package,
    }));
  } catch (e) {
    console.log(e);
  }
}

export async function fetchColoringJobService({accessToken, coloringJobId}) {
  try {
    const response = await client.get(`coloringJobs/${coloringJobId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const coloringJob = response.data;
    return coloringJob;
  } catch (e) {
    console.log(e);
  }
}

export async function createColoringJobService({
  accessToken,
  sceneId,
  coloringJob,
}) {
  try {
    const response = await client.post(
      `scenes/${sceneId}/coloringJobs/`,
      coloringJob,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const job = response.data;
    return {...job, packageId: job.package, sceneId: job.scene};
  } catch (e) {
    console.log(e);
  }
}

export async function updateColoringJobService({accessToken, coloringJob}) {
  try {
    const response = await client.put(
      `coloringJobs/${coloringJob?.id}`,
      coloringJob,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const job = response.data;
    return {...job, packageId: job.package, sceneId: job.scene};
  } catch (e) {
    console.log(e);
  }
}
