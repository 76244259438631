import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import sortByNum from "Features/rooms/utils/sortByNum";
// import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";
import {useKvfTranslationMap} from "Features/kvform/hooks/useKvfTranslationMap";

export default function FormDefaultMeasurement({
  scene,
  defaultMeasurement,
  onChange,
}) {
  // strings

  const titleS = "Valeurs par défaut";
  const zSupS = "Z sup (m)";
  const zInfS = "Z inf (m)";
  const offsetS = "Offset (m)";

  // data

  const translationMap = useKvfTranslationMap();

  const sectors = useSectorsByScene(scene);
  let rooms = useRoomsByScene(scene);
  // const materials = useMaterialsByScene(scene);

  // helper - constrain rooms to selected sector
  const code = defaultMeasurement.sector?.code;
  if (code) rooms = rooms.filter((r) => r.sector === code);

  // helper - template

  const template = {
    key: "kvfDefaultMeasurement",
    fields: [
      // {
      //   key: "title",
      //   type: "section",
      //   name: titleS,
      // },
      //{
      //   key: "codeName",
      //   type: "text",
      //   name: translationMap["codeName"],
      // },
      {
        key: "dim1",
        name: translationMap["dim1"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: [
            "SEGMENT",
            "BEAM",
            "STAIRS",
            "POLYLINE",
            "RECTANGLE",
            "CIRCLE",
            "BANK",
            "GABLE",
          ],
        },
      },
      {
        key: "dim2",
        name: translationMap["dim2"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["SEGMENT", "BEAM", "STAIRS", "RECTANGLE", "BANK"],
        },
      },
      {
        key: "dim3",
        name: translationMap["dim3"],
        type: "number",
        condition: {
          type: "showIfEqual",
          key: "drawingShape",
          values: ["BEAM"],
        },
      },
      {
        key: "height",
        type: "number",
        name: translationMap["height"],
      },
      {
        key: "zSup",
        type: "number",
        name: zSupS,
      },
      {
        key: "zInf",
        type: "number",
        name: zInfS,
      },
      {
        key: "offset",
        type: "number",
        name: offsetS,
      },
      // {
      //   key: "sector",
      //   type: "combo",
      //   name: translationMap["sector"],
      //   options: sectors,
      // },
      // {
      //   key: "room",
      //   type: "combo",
      //   name: translationMap["room"],
      //   options: rooms
      //     .filter((r) => r.num)
      //     .sort((a, b) => sortByNum(a.num, b.num)),
      // },
      // {
      //   key: "material",
      //   type: "combo",
      //   name: translationMap["material"],
      //   options: materials
      //     .filter((m) => m.num)
      //     .sort((a, b) => sortByNum(a.num, b.num)),
      // },
    ],
  };

  function handleChange(m) {
    // auto compute height from zSup
    if (
      !defaultMeasurement.height &&
      typeof defaultMeasurement.zInf == "number" &&
      typeof m.zSup == "number"
    ) {
      m.height = m.zSup - defaultMeasurement.zInf;
    } else if (
      // auto compute height from zInf
      !defaultMeasurement.height &&
      typeof defaultMeasurement.zSup == "number" &&
      typeof m.zInf == "number"
    ) {
      m.height = defaultMeasurement.zSup - m.zInf;
    }

    if (
      typeof m.offset === "number" &&
      m.offset !== defaultMeasurement.offset
    ) {
      m.zInf = null;
      m.zSup = null;
      m.zFrom = "offset";
    } else if (
      typeof m.zInf === "number" &&
      m.zInf !== defaultMeasurement.zInf
    ) {
      m.offset = null;
      m.zSup = null;
      m.zFrom = "zInf";
    } else if (
      typeof m.zSup === "number" &&
      m.zSup !== defaultMeasurement.zSup
    ) {
      m.offset = null;
      m.zInf = null;
      m.zFrom = "zSup";
    }
    //

    onChange(m);
  }

  return (
    <KeyValueForm
      item={defaultMeasurement}
      onChange={handleChange}
      template={template}
    />
  );
}
