import getFuncObjectFromMultipleFunc from "./getFuncObjectFromMultipleFunc";

export default function getRelationResMapFromRelations(relations) {
  if (!Array.isArray(relations) || relations.length === 0) return [];

  const relationsByResId = {};
  
  relations.forEach((relation) => {
    const relResId = relation.resId;
    if (!relationsByResId[relResId]) {
      relationsByResId[relResId] = [relation];
    } else {
      relationsByResId[relResId].push(relation);
    }
  });

  const result = {};
  for (const [resId, relations] of Object.entries(relationsByResId)) {
    const funcObject = getFuncObjectFromMultipleFunc(relations);
    result[resId] = {
      formula: funcObject.formula,
      filter: funcObject.filter,
    };
  };
  return result;
}
