import useSceneMeasurements from "Features/measurements/useSceneMeasurements";

export default function useRoomsBySceneWithMsts(scene) {
  // data
  const measurements = useSceneMeasurements(scene);
  //
  let rooms = scene?.data?.rooms;
  if (!rooms) rooms = [];
  rooms = rooms.filter((r) => r?.id);
  //
  rooms = rooms.map((r) => {
    const msts = measurements.filter((m) => m.roomId === r.id);
    return {...r, msts};
  });
  //
  return rooms;
}
