export default function workbookToEntityTypes(workbook) {
  const types = [];

  workbook.eachSheet((worksheet) => {
    const typeCode = worksheet.getRow(1).getCell(1).value;
    const worksheetName = worksheet.name;

    switch (typeCode) {
      case "INFO": {
        types.push({type: "INFO", worksheetName});
        break;
      }
      case "SECTORS": {
        types.push({type: "SECTORS", worksheetName});
        break;
      }
      case "RESSOURCES": {
        const code = worksheet.getRow(3).getCell(1).value;
        const label = worksheet.getRow(3).getCell(2).value;
        types.push({type: "RESSOURCES", code, label, worksheetName});
        break;
      }
      case "TYPES": {
        types.push({type: "TYPES", worksheetName});
        break;
      }
      case "TYPES-RESSOURCES": {
        const ressourceCode = worksheet.getRow(3).getCell(1).value;
        types.push({type: "TYPES-RESSOURCES", ressourceCode, worksheetName});
        break;
      }
    }
  });

  return types;
}
