import {Box, IconButton, Typography} from "@mui/material";
import {Refresh} from "@mui/icons-material";

export default function SectionTransformation({zone, editorPdf}) {
  // strings

  const transformationS = "3D transformation";

  // handlers

  function handleResetClick() {
    editorPdf?.annotationsManager.resetAnnotationTransformation(zone.id);
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        bgcolor: "background.default",
        width: 1,
        px: 1,
      }}
    >
      <Typography sx={{fontSize: 13, fontWeight: "bold"}}>
        {transformationS}
      </Typography>
      <IconButton size="small" onClick={handleResetClick}>
        <Refresh sx={{fontSize: 13}} />
      </IconButton>
    </Box>
  );
}
