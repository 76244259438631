// used to update the shortcutElementTypeIds when selection in leftPanel changes

import {useSelector, useDispatch} from "react-redux";
import {useEffect} from "react";

import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";

import {setShortcutElementTypes} from "Features/measurements/measurementsSlice";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import useSceneModule from "Features/navigation/useSceneModule";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSelectedArticlesGroup from "Features/articles/hooks/useSelectedArticlesGroup";

export default function useUpdateShortcutElementTypesFromLeftPanel(
  scene,
  viewer
) {
  const dispatch = useDispatch();

  const sceneModule = useSceneModule();

  // elementTypes
  const fms = useSelector(
    (s) => s.measurements.measurementsShowedInViewer3dWithoutShortcut
  );
  const sceneElementTypeIds = fms.map((m) => m.elementTypeId);
  const elementTypeIds = [...new Set(sceneElementTypeIds)];

  const allElementTypes = useElementTypesBySceneProxy(scene);
  const allElementTypesIds = allElementTypes.map((t) => t.id);

  // dependencies to refresh the list

  const selectedPackageId = useSelector((s) => s.packages.selectedPackageId);
  const selectedRessourceIds = useSelector(
    (s) => s.ressources.selectedRessourceIds
  );
  const selectedElementTypeIds = useSelector(
    (s) => s.elementTypes.selectedElementTypeIds
  );
  const selectedElementTypeId = useSelector(
    (s) => s.elementTypes.selectedElementTypeId
  );

  const selectedMeasurementsModelId = useSelector(
    (s) => s.measurements.selectedMeasurementsModelId
  );
  const groupProxyInEditMeasurements = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxyInEditMeasurements
  );
  const groupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  const articlesGroupId = useSelector(
    (s) => s.articles.selectedArticlesGroupId
  );
  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  // useEffect(() => {
  //   if (!viewer && sceneModule !== "EDIT_MEASUREMENTS")
  //     dispatch(setShortcutElementTypes(elementTypeIds));
  // }, [
  //   selectedRessourceIds,
  //   selectedElementTypeIds,
  //   selectedMeasurementsModelId,
  //   elementTypeIds.join(),
  //   groupProxy?.id,
  // ]);

  // effect at the beginning

  // useEffect(() => {
  //   if (!viewer && sceneModule !== "EDIT_MEASUREMENTS")
  //     dispatch(setShortcutElementTypes(elementTypeIds));
  // }, [elementTypeIds?.length]);

  useEffect(() => {
    if (!viewer && sceneModule === "ARTICLES" && articlesGroupId) {
      dispatch(setShortcutElementTypes(allElementTypesIds));
    }
  }, [
    sceneModule,
    articlesGroupId,
    selectedArticleId,
    allElementTypesIds?.length,
  ]);

  useEffect(() => {
    if (!viewer && sceneModule === "ELEMENT_TYPES" && groupProxy?.id) {
      //
      const elementTypes = allElementTypes?.filter(
        (t) => t.groupId === groupProxy?.id
      );
      //
      dispatch(setShortcutElementTypes(elementTypes?.map((t) => t.id)));
    }
  }, [
    selectedMeasurementsModelId,
    sceneModule,
    groupProxy?.id,
    selectedElementTypeId,
  ]);

  useEffect(() => {
    if (
      !viewer &&
      sceneModule === "EDIT_MEASUREMENTS" &&
      groupProxyInEditMeasurements?.id
    ) {
      console.log("debug 31-05 update package", Date.now());
      //
      const elementTypes = allElementTypes?.filter(
        (t) => t.groupId === groupProxyInEditMeasurements?.id
      );
      //
      dispatch(setShortcutElementTypes(elementTypes?.map((t) => t.id)));
    }
  }, [
    selectedMeasurementsModelId,
    sceneModule,
    groupProxyInEditMeasurements?.id,
  ]);

  useEffect(() => {
    if (viewer) {
      dispatch(setShortcutElementTypes(allElementTypesIds));
    }
  }, [allElementTypesIds.length]);
}
