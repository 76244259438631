import React from "react";

import {Box, TextField, Typography} from "@mui/material";

export default function FieldTextBasic({label, value, onChange}) {
  function handleChange(e) {
    onChange(e.target.value);
  }
  return (
    <Box sx={{width: 1}}>
      <TextField
        value={value ? value : ""}
        fullWidth
        onChange={handleChange}
        label={<Typography variant="body2">{label}</Typography>}
        size="small"
        InputProps={{
          sx: {
            fontSize: (theme) => theme.typography.body2.fontSize,
          },
        }}
      />
    </Box>
  );
}
