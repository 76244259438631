import getModelLoadingStatusNum from "./getModelLoadingStatusNum";

export default function getScopeIsInState(modelIdsInScope, statusMap) {
  let count = 0;
  modelIdsInScope.forEach((modelId) => {
    const num = getModelLoadingStatusNum(modelId, statusMap);
    if (num >= 2) count = count + 1;
  });
  return count === modelIdsInScope.length;
}
