import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setSelectionPanelWidth} from "../selectionSlice";

import {Box, Paper} from "@mui/material";

import HeaderSelection from "./HeaderSelection";
import HeaderSelectionMulti from "./HeaderSelectionMulti";
import SectionSelection from "./SectionSelection";
import SectionSelectionMulti from "./SectionSelectionMulti";
import SectionActions from "./SectionActions";
import SectionActionsMulti from "./SectionActionsMulti";
import useSelectionMulti from "../useSelectionMulti";

import Stretch from "Features/ui/components/Stretch";
import zoomInOut from "Features/ui/zoomInOut";
import usePageType from "Features/navigation/usePageType";
import useSceneModule from "Features/navigation/useSceneModule";
import useIsCreatingZone from "Features/zones/hooks/useIsCreatingZone";
import HeaderSelectionByModule from "./HeaderSelectionByModule";

export default function PanelSelection({caplaEditor, scene, viewer}) {
  const dispatch = useDispatch();

  // data

  const pageType = usePageType();
  const sceneModule = useSceneModule();

  const width = useSelector((s) => s.selection.selectionPanelWidth);
  const defaultWidth = useSelector(
    (s) => s.selection.selectionPanelWidthDefault
  );

  const open = useSelector((s) => s.selection.openSelectionPanel);

  const selectionMulti = useSelectionMulti();

  const animate = useSelector((s) => s.ui.animateSelection);

  const isCreatingZone = useIsCreatingZone();

  const selectedArticleId = useSelector((s) => s.articles.selectedArticleId);

  const isLargePanel = useSelector((s) => s.selection.panelIsLarge);

  // effect to double size

  const doubleSize =
    sceneModule === "ARTICLES" && selectedArticleId && isLargePanel;

  useEffect(() => {
    if (doubleSize) {
      if (width !== defaultWidth * 2) {
        dispatch(setSelectionPanelWidth(defaultWidth * 2));
      }
    } else {
      if (width !== defaultWidth) {
        dispatch(setSelectionPanelWidth(defaultWidth));
      }
    }
  }, [sceneModule, doubleSize, isLargePanel]);

  //

  // helper

  const isMulti = selectionMulti?.items?.length > 1;

  const showDefaultHeader = !isMulti && !["ARTICLES"].includes(sceneModule);

  const showSpecificHeader = ["ARTICLES"].includes(sceneModule);

  // helper • display

  //const display = (open || pageType === "HOME") && !["LIBRARY", "PRICINGS"].includes(sceneModule);
  const display =
    open && !["LIBRARY", "PRICINGS", "RELATIONS"].includes(sceneModule);

  return (
    <Box
      sx={{
        height: 1,
        //borderLeft: (theme) => `2px solid ${theme.palette.primary.flash}`,
        position: "relative",
      }}
    >
      <Paper
        elevation={12}
        sx={{
          boxSizing: "border-box",
          display: display ? "flex" : "none",
          flexDirection: "column",
          width,
          minWidth: width,
          justifyContent: "space-between",
          height: 1,
          bgcolor: "common.white",
          //borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          position: "relative",
          ...(animate && {
            borderColor: "primary.flash",
            borderStyle: "solid",
            borderWidth: "3px",
            animation: `${zoomInOut} 1s infinite ease`,
          }),
        }}
      >
        {!isCreatingZone && (
          <Box>
            {isMulti && (
              <HeaderSelectionMulti scene={scene} caplaEditor={caplaEditor} />
            )}
            {showDefaultHeader && (
              <HeaderSelection
                scene={scene}
                caplaEditor={caplaEditor}
                viewer={viewer}
              />
            )}
            {showSpecificHeader && <HeaderSelectionByModule scene={scene} />}
          </Box>
        )}
        <Box
          sx={{
            overflowY: "auto",
            position: "relative",
            flexGrow: 1,
            boxSizing: "border-box",
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            //border: "3px solid orange",
          }}
        >
          {isMulti ? (
            <SectionSelectionMulti scene={scene} caplaEditor={caplaEditor} />
          ) : (
            <SectionSelection
              caplaEditor={caplaEditor}
              scene={scene}
              viewer={viewer}
            />
          )}
        </Box>

        <Box sx={{boxSizing: "border-box"}}>
          {!viewer && (
            <Box sx={{width: 1}}>
              {isMulti ? (
                <SectionActionsMulti caplaEditor={caplaEditor} scene={scene} />
              ) : (
                <SectionActions caplaEditor={caplaEditor} scene={scene} />
              )}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
