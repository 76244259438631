import {useState} from "react";

import {useDispatch} from "react-redux";
import {setSnackbarMessage} from "Features/ui/uiSlice";

import {nanoid} from "@reduxjs/toolkit";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import FormEditRessource from "./FormEditRessource";
import useAddSceneRessource from "../hooks/useAddSceneRessource";
import useSelectedRessourcesGroup from "../hooks/useSelectedRessourcesGroup";

export default function DialogCreateRessource({open, onClose, scene}) {
  const dispatch = useDispatch();

  // string

  const title = "Ajouter un nouvel article";

  const createS = "Créer";

  // state

  const [loading, setLoading] = useState(false);
  const [tempRessource, setTempRessource] = useState({});

  // data

  const addSceneRessource = useAddSceneRessource(scene);
  const selectedGroup = useSelectedRessourcesGroup(scene);

  // helper

  const groupLabel = selectedGroup?.fromScene
    ? selectedGroup.name
    : selectedGroup.name + " ✨";
  // handlers

  function handleClose() {
    onClose();
  }

  function handleTempRessourceChange(ressource) {
    setTempRessource(ressource);
  }

  async function handleSave() {
    const ressource = {...tempRessource, id: nanoid()};
    console.log("[DialogCreateRessource] ressource", ressource);
    if (loading) return;
    setLoading(true);
    await addSceneRessource(ressource);
    setLoading(false);
    const message = `Article "${ressource.num} ${ressource.name}" ajouté`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{bgcolor: "background.default", p: 0.5, mb: 2}}>
          <Typography sx={{fontSize: 13}}>{groupLabel}</Typography>
        </Box>
        <FormEditRessource onChange={handleTempRessourceChange} />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleSave}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
