import React, {useState} from "react";

import {MoreHoriz as More, Edit, Delete} from "@mui/icons-material";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";

export default function NoteHeader({onDelete, onEdit}) {
  // strings

  const deleteString = "Supprimer";
  const editString = "Modifier";

  // local state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // handlers

  function handleDelete() {
    onDelete();
    setAnchorEl(null);
  }

  function handleEdit() {
    onEdit();
    setAnchorEl(null);
  }

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  return (
    <Box
      sx={{
        width: 1,
        //backgroundColor: "background.default",
        //borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <IconButton onClick={handleMoreClick} size="small" color="inherit">
        <More fontSize="small" color="inherit" />
      </IconButton>
      <Menu open={open} onClose={handleMenuClose} anchorEl={anchorEl}>
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{editString}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{deleteString}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}
