export default function fillWorksheetWithMaterials({
    worksheet,
    materials,
    rowStartIndex,
  }) {
    materials.forEach(({id, num, name, code, color}, index) => {
      const rowNum = rowStartIndex + index;
      const row = worksheet.getRow(rowNum);
      row.getCell(1).value = id;
      row.getCell(2).value = num;
      row.getCell(3).value = name;
      row.getCell(4).value = code;
      const colorCell = row.getCell(5);
      colorCell.value = color;
      colorCell.fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb: color.substring(1)},
      };
    });
  }
  