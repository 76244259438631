import {client} from "API/capla360";
import {BlobServiceClient} from "@azure/storage-blob";
import {FileLoader} from "three";

export async function createFileService({
  file,
  name,
  storagePathType,
  listingId,
  accessToken,
  onUploadProgress,
}) {
  // prepare form data

  let formData = new FormData();
  formData.append("file", file);
  formData.append("listing", listingId);
  formData.append("name", name);
  if (storagePathType) {
    formData.append("storage_path_type", storagePathType);
  }

  // api call

  const response = await client.post(
    `/listings/${listingId}/files/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      onUploadProgress,
    }
  );
  return response.data;
}

export async function downloadFileService({url, fileName, onProgress}) {
  const loader = new FileLoader();
  loader.setResponseType("arraybuffer");
  return new Promise((resolve) =>
    loader.load(
      url,
      (arraybuffer) => {
        const blob = new Blob([arraybuffer]);
        resolve(new File([blob], fileName));
      },
      (xhr) => {
        if (onProgress) onProgress(xhr.loaded / xhr.total);
        console.log("debug file download", xhr.loaded / xhr.total);
      }
    )
  );
}

export async function ___downloadFileService({url, fileName}) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName);
}

export async function ____downloadFileService({url, fileName, onProgress}) {
  console.log("downloadurl14", url);
  try {
    const response = await fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.status + " " + response.statusText);
        }

        if (!response.body) {
          throw Error("ReadableStream not yet supported in this browser.");
        }

        // to access headers, server must send CORS header "Access-Control-Expose-Headers: content-encoding, content-length x-file-size"
        // server must send custom x-file-size header if gzip or other content-encoding is used
        const contentEncoding = response.headers.get("content-encoding");
        const contentLength = response.headers.get(
          contentEncoding ? "x-file-size" : "content-length"
        );
        if (contentLength === null) {
          throw Error("Response size header unavailable");
        }

        const total = parseInt(contentLength, 10);
        let loaded = 0;

        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader
                  .read()
                  .then(({done, value}) => {
                    if (done) {
                      console.log("done111");
                      controller.close();
                      return;
                    }
                    loaded += value.byteLength;
                    if (onProgress) onProgress(loaded / total);
                    controller.enqueue(value);
                    read();
                  })
                  .catch((error) => {
                    console.error(error);
                    controller.error(error);
                  });
              }
            },
          })
        );
      })
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], fileName);
        return file;
      })
      .catch((error) => {
        console.error(error);
      });
    return response;
  } catch (e) {
    console.log(e);
  }
}

export async function _downloadFileService({url, fileName}) {
  //"https://capla360staging.blob.core.windows.net/listing-files/d2d5e0ee-85d4-4360-baca-44191ccf08e0/Hotel_R2_sQOfm8H.png"

  console.log("donwloading", url);
  const blobName = url.split("/").splice(4).join("/");
  const containerName = url.split("/")[3];
  const account = process.env.REACT_APP_AZ_STORAGE_ACCOUNT;
  const sas = "";

  console.log("file serivce", containerName, account, blobName);
  try {
    const blobServiceClient = new BlobServiceClient(
      `https://${account}.blob.core.windows.net${sas}`
    );
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    const blobResponse = await blobClient.download();
    //const downloaded = await blobToString(await blobResponse.blobBody);
    const downloaded = blobResponse.blobBody;
    return downloaded;

    // [Browsers only] A helper method used to convert a browser Blob into string.
    async function blobToString(blob) {
      const fileReader = new FileReader();
      return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
          resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
      });
    }
  } catch (e) {
    console.log(e);
  }
}

export async function uploadFileService({
  file,
  organisationId,
  visitId,
  sceneId,
  listingId,
  worksiteId,
  onProgress,
  accessToken,
  container,
  blobName,
  blobContentType,
}) {
  console.log("[uploadFileService56] file", file);
  const name = blobName ? blobName : file.name;

  const sas = await getSasService({
    sceneId,
    listingId,
    worksiteId,
    visitId,
    organisationId,
    accessToken,
  });
  console.log("uploading file with name", name);
  const account = process.env.REACT_APP_AZ_STORAGE_ACCOUNT;

  let containerClientId;
  switch (container) {
    case "organisation":
      containerClientId = organisationId;
      break;
    case "visit":
      containerClientId = visitId;
      break;
    case "scene-files":
      containerClientId = sceneId;
      break;
    case "listing-files":
      containerClientId = listingId;
      break;
    // case "scene-image":
    //   containerClientId = `original/${sceneId}`;
    //   break;
    case "scene-image":
      containerClientId = sceneId;
      break;
    case "scene-story-images":
      containerClientId = sceneId;
      break;
    case "scene-sharedurl-og-image":
      containerClientId = sceneId;
      break;
    case "worksite-image":
      containerClientId = worksiteId;
      break;
  }
  const sasUrl = `https://${account}.blob.core.windows.net/${container}?${sas}`;
  try {
    const blobServiceClient = new BlobServiceClient(sasUrl);
    const containerClient =
      blobServiceClient.getContainerClient(containerClientId);
    const blockBlobClient = containerClient.getBlockBlobClient(name);
    // optionz
    let options = {};
    if (onProgress) {
      options.progress = (ev) => onProgress((ev.loadedBytes / file.size) * 100);
    }
    if (blobContentType) {
      options.blobHTTPHeaders = {blobContentType};
    }

    await blockBlobClient.uploadData(file, options);
    const fullUrl = blockBlobClient.url;
    return fullUrl.split("?")[0];
  } catch (e) {
    console.log(e);
  }
}

export async function getSasService({
  organisationId,
  visitId,
  sceneId,
  listingId,
  worksiteId,
  accessToken,
}) {
  let sas;
  let url = `sas/scenes/${sceneId}`;
  if (listingId) url = `sas/listings/${listingId}`;
  if (worksiteId) url = `sas/worksites/${worksiteId}`;
  if (organisationId) url = `sas/organisations/${organisationId}`;
  if (visitId) url = `sas/visits/${visitId}`;

  const response = await client.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  sas = response.data;
  return sas;
}
