import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

// import {fetchStoredScene} from "./scenesSlice";
import useDownloadRemoteScene from "./useDownloadRemoteScene";

import {setIsSyncing} from "Features/sync/syncSlice";
import useAccessToken from "Features/auth/useAccessToken";
import {setStartDownloadingRemote} from "Features/sync/syncSlice";
import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";

/*
 * sceneId is used in the url params. Should be a remote id by default.
 * if no result with scene.id, we look for match with scene.clientId
 *
 * step 1 : we look in stored scenes. If no scene, step 2 : look in remote scene.
 * step 2 : we use the downloaded remote scene.
 */

export default function useScene(sceneId, caplaEditor) {
  const isStagingEnv = useIsStagingEnv();

  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // data - functions

  const [downloadRemoteScene] = useDownloadRemoteScene(
    accessToken,
    caplaEditor
  ); // used to download scene only if it doesn't exist.

  // const fetchScenesStatus = useSelector(
  //   (state) => state.scenes.fetchScenesStatus
  // );
  // const localScenesAreFetched = fetchScenesStatus === "success";

  // data

  // const remoteDataOnly = useSelector((s) => s.sync.remoteDataOnly);

  const startDownloadingRemote = useSelector(
    (s) => s.sync.startDownloadingRemote
  );

  // data - downloading state of the scene

  const downloads = useSelector((s) => s.scenes.downloads);
  const sceneDownload = downloads.find((d) => d.sceneId === sceneId);
  const sceneDownloadStatus = sceneDownload ? sceneDownload.status : "idle";

  //const scenes = useScenes(remoteDataOnly);
  const scenes = useSelector((state) => state.scenes.items);

  // helper - scene

  let scene = scenes.find((scene) => scene.id === sceneId);
  if (!scene) scene = scenes.find((scene) => scene.clientId === sceneId);

  // helper - client scene

  // async function getStoredScene() {
  //   console.log("FETCH STORED SCENE", sceneId);
  //   dispatch(fetchStoredScene(sceneId));
  // }

  // remote scene

  async function getRemoteScene() {
    dispatch(setIsSyncing(true));
    await downloadRemoteScene(sceneId);
    dispatch(setIsSyncing(false));
  }

  // effects

  const canDownload =
    Boolean(sceneId) &&
    Boolean(accessToken) &&
    Boolean(downloadRemoteScene) &&
    // (localScenesAreFetched || remoteDataOnly) &&
    sceneDownloadStatus === "idle" &&
    startDownloadingRemote === false &&
    caplaEditor;

  useEffect(() => {
    dispatch(setStartDownloadingRemote(false));
  }, [sceneDownloadStatus === "idle"]);

  useEffect(() => {
    if (canDownload && !isStagingEnv) {
      console.log("GET REMOTE FROM USESCENE 912");
      dispatch(setStartDownloadingRemote(true));
      getRemoteScene();
    }
  }, [canDownload, sceneId]);

  // useEffect(() => {
  //   getStoredScene();
  // }, [sceneId]);

  return scene;
}
