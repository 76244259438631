import useIsStagingEnv from "Features/overviewer/hooks/useIsStagingEnv";
import {useParams} from "react-router-dom";

export default function useSceneModuleBackPathMap() {
  const isStagingEnv = useIsStagingEnv();
  const {sceneId} = useParams();

  let urlMap = {
    SURVEY: `/bimboxes/${sceneId}/surveys`,
    VIEW: `/bimboxes/${sceneId}/views`,
    REPORT: `/bimboxes/${sceneId}/reports`,
  };

  if (isStagingEnv) {
    Object.keys(urlMap).forEach((key) => {
      urlMap[key] = urlMap[key] + "?env=staging";
    });
  }
  return urlMap;
}
