import MeasurementsDataLoader from "./MeasurementsDataLoader";

import {updateModelNotAsync} from "Features/viewer3D/viewer3DSlice";
import {
  removeModificationsForModelById,
  setMeasurements,
  setModificationsForModelById,
  setShortcutElementTypes,
} from "Features/measurements/measurementsSlice";
import getMeasurementsDataFromMeasurementsAndModelId from "Features/measurements/utils/getMeasurementsDataFromMeasurementsAndModelId";
import getItemsMapById from "Utils/getItemsMapById";

export default class MeasurementsDataManager {
  constructor({editor}) {
    this.editor = editor;
    this.models = {};
    this.selectedModelId = null;
    this.modelIdsInShortMemory = [];
    this.useGroups = false;
    this.modelIdsFromGroups = [];
    this.restrictedTypes = null;
    this.restrictedRessources = null;
    this.selectedVersionId = null;
    this.modifications = {};
    this.shortcutElementTypes = [];
    this.voids = {};
    this.loader = new MeasurementsDataLoader({caplaEditor: editor});
    //
    this.debugString = "Test";
  }

  // --------------------------------------------------------------------------
  // getters
  // --------------------------------------------------------------------------

  getModificationsCountForModelId(modelId) {
    const modifiedIds = new Set(this.modifications[modelId]?.modifiedIds ?? []);
    return modifiedIds.size;
  }

  getModifiedMeasurementIds() {
    if (this.selectedModelId) {
      return new Set(
        this.modifications[this.selectedModelId]?.modifiedIds ?? []
      );
    } else {
      return new Set(
        Object.values(this.modifications)
          .map((m) => m.modifiedIds)
          .flat()
      );
    }
  }

  getModelMeasurements(modelId) {
    const model = this.models[modelId];
    let meas;
    if (this.modifications[modelId]) {
      meas = this.modifications[modelId].modifiedItems;
    } else {
      meas = model?.measurementsData?.measurements;
    }
    return meas ?? [];
  }

  getAllMeasurements() {
    let meas = [];
    for (const modelId of Object.keys(this.models)) {
      meas = meas.concat(this.getModelMeasurements(modelId));
    }
    return meas;
  }

  // --------------------------------------------------------------------------
  // setters
  // --------------------------------------------------------------------------

  setSelectedVersionId(versionId) {
    this.selectedVersionId = versionId;
  }
  setShortcutElementTypes(typeIds) {
    this.shortcutElementTypes = typeIds;
  }

  // Set model in Manager

  createOrUpdateModelInManager(model, updateMeas = true) {
    console.log("[DEBUG] add model ids in short memory", model, updateMeas);
    const measurementsModelId = model.id;
    const pdfModelId = model.fromModel.modelId;
    let meas = model?.measurementsData?.measurements;
    if (Array.isArray(meas)) {
      if (updateMeas)
        meas = meas.map((m) => ({...m, measurementsModelId, pdfModelId}));
    } else {
      meas = [];
    }
    meas = this.addVoidsToMeasurements(meas);
    // be sure not to have duplicates with same id
    meas = [...new Map(meas.map((m) => [m.id, m])).values()];
    this.models[model.id] = {
      ...model,
      measurementsData: {
        ...model.measurementsData,
        measurements: meas,
      },
    };
  }

  // --------------------------------------------------------------------------
  // Modifications
  // --------------------------------------------------------------------------

  addModelModifications(modifiedModelId, measurements, modifiedMeasurementIds) {
    this.modifications[modifiedModelId] = {
      modifiedIds: modifiedMeasurementIds,
      modifiedItems: measurements,
    };
    this.editor.dispatch(
      setModificationsForModelById({
        modelId: modifiedModelId,
        modifiedIds: modifiedMeasurementIds,
      })
    );
    this.setShortMemory();
  }

  removeModificationsForModelById(modelId) {
    if (this.modifications[modelId]) delete this.modifications[modelId];
    this.setShortMemory();
  }

  saveModificationsForModelById(modelId, sceneData) {
    const model = this.models[modelId]; //  / ! \ some fields of the model are not updated in the dataMnger. Ex : model.version => this causes to block the version at one number, (next sync : v4 in client, v5 in remote => blocks sync !!)
    const measurements = getMeasurementsDataFromMeasurementsAndModelId(
      this.getModelMeasurements(modelId),
      modelId
    );
    const updatedModel = {
      ...model,
      measurementsData: {
        ...model.measurementsData,
        measurements: measurements,
      },
    };
    this.createOrUpdateModelInManager(updatedModel);
    this.removeModificationsForModelById(updatedModel.id);
    this.editor.dispatch(removeModificationsForModelById(modelId));
    // save only measurementsData
    const updates = {
      id: updatedModel.id,
      measurementsData: updatedModel.measurementsData,
    };
    this.editor.dispatch(updateModelNotAsync({updates, sync: true, sceneData}));
  }
  saveModifications(sceneData) {
    for (const modelId of Object.keys(this.modifications)) {
      this.saveModificationsForModelById(modelId, sceneData);
    }
  }

  // --------------------------------------------------------------------------
  // Manipulate measurements
  // --------------------------------------------------------------------------

  addMeasurements(measurements) {
    const modelIds = new Set(measurements.map((m) => m.measurementsModelId));
    modelIds.forEach((id) => {
      let items, modifiedIds;
      if (!this.modifications[id]) {
        items = this.models[id]?.measurementsData?.measurements;
        if (!items) items = [];
        modifiedIds = [];
      } else {
        items = this.modifications[id].modifiedItems;
        modifiedIds = this.modifications[id].modifiedIds;
      }
      items = [...items, ...measurements];
      this.addModelModifications(id, items, [
        ...new Set([...modifiedIds, ...measurements.map((m) => m.id)]),
      ]);
      // // TODO - move this bit out
      // this.editor.dispatch(
      //   setShortcutElementTypes([
      //     ...new Set([
      //       ...this.shortcutElementTypes,
      //       ...measurements.map((m) => m.elementTypeId),
      //     ]),
      //   ])
      // );
    });
  }

  deleteMeasurements(measurements) {
    const modelIds = new Set();
    const measurementIds = new Set();
    for (const measurement of measurements) {
      modelIds.add(measurement.measurementsModelId);
      measurementIds.add(measurement.id);
    }
    modelIds.forEach((id) => {
      let items, modifiedIds;
      if (!this.modifications[id]) {
        items = this.models[id].measurementsData.measurements;
        modifiedIds = [];
      } else {
        items = this.modifications[id].modifiedItems;
        modifiedIds = this.modifications[id].modifiedIds;
      }
      this.addModelModifications(
        id,
        items.map((i) => {
          if (measurementIds.has(i.id)) return {...i, deleted: true};
          return {...i};
        }),
        [...new Set([...modifiedIds, ...measurements.map((m) => m.id)])]
      );
    });
  }

  updateMeasurements(measurements) {
    console.log("debug 31-05 updateMeasurements", measurements.length);
    const modelIds = new Set();
    const measurementIds = new Set();
    for (const measurement of measurements) {
      modelIds.add(measurement.measurementsModelId);
      measurementIds.add(measurement.id);
    }
    modelIds.forEach((id) => {
      let items, modifiedIds;
      if (!this.modifications[id]) {
        items = this.getModelMeasurements(id);
        modifiedIds = [];
      } else {
        items = this.modifications[id].modifiedItems;
        modifiedIds = this.modifications[id].modifiedIds;
      }
      items = this.addVoidsToMeasurements(items);
      const modifiedItems = items.map((i) => {
        let item = {...i};
        if (measurementIds.has(i.id)) {
          let updatedItem = measurements.find((m) => m.id === i.id);
          if (Array.isArray(updatedItem.voids)) {
            let lengthNet = updatedItem.length;
            let areaNet = updatedItem.area;
            let volumeNet = updatedItem.volume;
            updatedItem = {
              ...updatedItem,
              voidsCount: updatedItem.voids.length,
            };
            const voids = this.makeMeasurementVoids(updatedItem);
            for (const v of voids) {
              lengthNet -= v.length;
              areaNet -= v.area;
              volumeNet -= v.volume;
            }
            updatedItem.lengthNet = Math.round(lengthNet * 10000) / 10000;
            updatedItem.areaNet = Math.round(areaNet * 10000) / 10000;
            updatedItem.volumeNet = Math.round(volumeNet * 10000) / 10000;
          }
          item = {
            ...i,
            ...updatedItem,
          };
        }
        return item;
      });
      this.addModelModifications(id, modifiedItems, [
        ...new Set([...modifiedIds, ...measurements.map((m) => m.id)]),
      ]);
    });
  }

  // --------------------------------------------------------------------------
  // Set items in redux state
  // --------------------------------------------------------------------------

  setShortMemory() {
    let meas = [];
    if (this.useGroups && this.modelIdsFromGroups.length > 0) {
      const restrictedMeas = new Set();
      this.modelIdsFromGroups.forEach((id) => {
        const model = this.models[id];
        if (
          model.revisionIds?.length !== 0 &&
          (!this.selectedVersionId ||
            !model.revisionIds ||
            model.revisionIds.includes(this.selectedVersionId))
        ) {
          const modelMeas = this.getModelMeasurements(id);
          if (this.restrictedRessources) {
            modelMeas.forEach((m) => {
              m.res?.forEach((r) => {
                if (this.restrictedRessources.has(r.resId)) {
                  restrictedMeas.add(m.id);
                  m.voids?.forEach((v) => restrictedMeas.add(v));
                  return;
                }
              });
            });
          } else if (this.restrictedTypes) {
            modelMeas.forEach((m) => {
              if (this.restrictedTypes.has(m.elementTypeId)) {
                restrictedMeas.add(m.id);
                m.voids?.forEach((v) => restrictedMeas.add(v));
              }
            });
          }
          meas = meas.concat(modelMeas);
        }
      });
      if (restrictedMeas.size > 0) {
        meas = meas.filter((m) => restrictedMeas.has(m.id));
      }
    } else if (!this.useGroups) {
      this.modelIdsInShortMemory.forEach((id) => {
        meas = meas.concat(this.getModelMeasurements(id));
      });
    }
    this.editor.dispatch(setMeasurements(meas));
  }

  // --------------------------------------------------------------------------
  // Select models to load an display
  // --------------------------------------------------------------------------

  setModelsInShortMemory(modelIds, load3D = true) {
    //
    console.time("debugtime setModelsInShortMemory");
    //
    this.modelIdsInShortMemory = [];
    for (const modelId of modelIds) {
      this.modelIdsInShortMemory.push(modelId);
      const model = this.models[modelId];
      console.log("setModelsInShortMemory", model);
      if (load3D && model) {
        this.editor?.editor3d?.loader?.loadInitialModel(model);
      }
    }
    console.time("debugtime setShortMemory");
    this.setShortMemory();
    console.timeEnd("debugtime setShortMemory");
    //
    console.timeEnd("debugtime setModelsInShortMemory");
    //
  }
  setModelsFromGroup(
    groupId,
    group = "RESSOURCES",
    showIn3D = true,
    restricted = null
  ) {
    const modelIdsFromGroups = [];
    if (!groupId) {
      this.useGroups = false;
      this.restrictedRessources = null;
      this.restrictedTypes = null;
    } else {
      this.useGroups = true;
      for (const m of Object.values(this.models)) {
        if (group === "RESSOURCES" && m.ressourcesGroupIds?.includes(groupId)) {
          this.restrictedRessources = restricted;
          this.restrictedTypes = null;
          modelIdsFromGroups.push(m.id);
          if (showIn3D) {
            this.editor.editor3d?.loader?.loadInitialModel(m);
          }
        } else if (
          group === "ELEMENT_TYPES" &&
          m.elementTypesGroupIds?.includes(groupId)
        ) {
          this.restrictedRessources = null;
          this.restrictedTypes = restricted;
          modelIdsFromGroups.push(m.id);
          if (showIn3D) {
            this.editor.editor3d?.loader?.loadInitialModel(m);
          }
        } else if (!m.ressourcesGroupIds && !m.elementTypesGroupIds) {
          this.restrictedRessources = null;
          this.restrictedTypes = null;
          modelIdsFromGroups.push(m.id);
          if (showIn3D) {
            this.editor.editor3d?.loader?.loadInitialModel(m);
          }
        }
      }
    }
    this.modelIdsFromGroups = modelIdsFromGroups;
    this.setShortMemory();
  }

  // --------------------------------------------------------------------------
  // Voids
  // --------------------------------------------------------------------------

  makeMeasurementVoids(measurement, measurementsById = null) {
    const modelId = measurement.measurementsModelId;
    if (!measurementsById) {
      const measurements = this.getModelMeasurements(modelId);
      measurementsById = getItemsMapById(measurements);
    }
    const measurementVoids = [];
    measurement.voids.forEach((voidId) => {
      const voidItem = measurementsById[voidId];
      if (voidItem && voidItem.drawingShape === "SEGMENT") {
        let area = 0;
        // WARNING ONLY WORKS FOR LATERALLY FULLY CONTAINED VOIDS
        if (
          voidItem.zInf >= measurement.zInf &&
          voidItem.zSup <= measurement.zSup
        ) {
          area = voidItem.area;
        } else if (
          voidItem.zInf < measurement.zInf &&
          voidItem.zSup > measurement.zSup
        ) {
          area = voidItem.length * measurement.height;
        } else if (
          voidItem.zInf < measurement.zInf &&
          voidItem.zSup <= measurement.zSup
        ) {
          area = voidItem.length * (voidItem.zSup - measurement.zInf);
        } else if (
          voidItem.zInf >= measurement.zInf &&
          voidItem.zSup > measurement.zSup
        ) {
          area = voidItem.length * (measurement.zSup - voidItem.zInf);
        }
        const v = {
          id: measurement.id + "-" + voidItem.id,
          codeName: voidItem.codeName + " <--[ " + measurement.codeName + " ]",
          res: measurement.res,
          isVoid: true,
          elementTypeId: measurement.elementTypeId,
          count: 1,
          length: voidItem.length,
          area: area,
          volume: measurement.dim1 ? measurement.dim1 * area : voidItem.volume,
          unit: measurement.unit,
          roomId: measurement.roomId,
          sectorId: measurement.sectorId,
        };
        measurementVoids.push(v);
      }
    });
    return measurementVoids;
  }

  addVoidsToMeasurements(measurements) {
    const meas = [];
    const measurementsById = getItemsMapById(measurements);
    measurements.forEach((measurement) => {
      if (Array.isArray(measurement?.voids)) {
        let lengthNet = measurement.length;
        let areaNet = measurement.area;
        let volumeNet = measurement.volume;
        const newMeas = {...measurement, voidsCount: measurement.voids.length};
        const voids = this.makeMeasurementVoids(measurement, measurementsById);
        for (const v of voids) {
          lengthNet -= v.length;
          areaNet -= v.area;
          volumeNet -= v.volume;
        }
        meas.push({
          ...newMeas,
          lengthNet: Math.round(lengthNet * 10000) / 10000,
          areaNet: Math.round(areaNet * 10000) / 10000,
          volumeNet: Math.round(volumeNet * 10000) / 10000,
        });
      } else {
        meas.push(measurement);
      }
    });
    return meas;
  }
}
