import { nanoid } from "@reduxjs/toolkit";

export default function excelTypeRessourceMapToElementTypesWithRessources(
  typeRessourceMap,
  types,
  ressources
) {
  let typesWithRessources = [];

  const _typeRessourceMap = typeRessourceMap.map((item) => {
    let resNum = item.resNum;
    let typeNum = item.typeNum;
    if (typeof resNum === "number") resNum = resNum.toString();
    if (typeof typeNum === "number") typeNum = typeNum.toString();
    return {...item, resNum, typeNum};
  });
  _typeRessourceMap.forEach(
    ({typeGroup, typeNum, resGroup, resNum, mode, func, toDelete}) => {
      const type = types.find(
        (t) => t.group === typeGroup && t.num === typeNum
      );
      const res = ressources.find(
        (r) => r.group === resGroup && r.num === resNum
      );
      if (type && res) {
        const typeWithRes = typesWithRessources.find((t) => t.id === type.id);
        if (!typeWithRes && !toDelete) {
          typesWithRessources.push({
            ...type,
            res: [{id: nanoid(), resId: res.id, mode, func}],
          });
        } else {
          let oldTypeRessources = [];
          if (typeWithRes) {
            oldTypeRessources = typeWithRes.res.filter(
              (r) => r.resId !== res.id
            );
          }
          const newTypeRessources = [...oldTypeRessources];
          if (!toDelete) newTypeRessources.push({resId: res.id, mode, func});
          const newType = {
            ...type,
            res: newTypeRessources,
          };
          typesWithRessources = typesWithRessources.filter(
            (t) => t.id !== type.id
          );
          typesWithRessources.push(newType);
        }
      }
    }
  );
  return typesWithRessources;
}
