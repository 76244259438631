import React, {useState} from "react";

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import DialogUpdateColoringJob from "./DialogUpdateColoringJob";

export default function CardColoringJob({scene, coloringJob}) {
  // strings

  const copyLinkS = "Copy link";
  const editS = "Edit";
  const openedS = "Opened job !";
  const closedS = "Closed job";

  // state

  const [open, setOpen] = useState(false);

  // helpers - props

  let job = coloringJob;
  if (!job) job = {};
  const {id, imageUrl, name, description, isOpened} = job;

  // helpers

  const bgcolor = isOpened ? "primary.flash" : "common.caplaBlack";
  const openLabel = isOpened ? openedS : closedS;

  // handlers

  function handleDialogClose() {
    setOpen(false);
  }

  function handleEditClick() {
    setOpen(true);
  }

  function handleCopyLink() {
    const url = `/coloringJobs/${id}/newColoring`;
    const baseURL = process.env.REACT_APP_URL_PREFIX;
    const link = `${baseURL}${url}`;
    navigator.clipboard.writeText(`${baseURL}${url}`);
  }
  return (
    <>
      <DialogUpdateColoringJob
        open={open}
        onClose={handleDialogClose}
        coloringJob={coloringJob}
        scene={scene}
      />
      <Card sx={{width: 1}}>
        <CardMedia component="img" height="140" image={imageUrl} alt={name} />
        <CardContent sx={{position: "relative"}}>
          <Typography gutterBottom>{name}</Typography>
          <Typography variant="body2" sx={{whiteSpace: "pre"}}>
            {description}
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
              color: "common.white",
              bgcolor,
              borderRadius: "8px",
              px: 1,
            }}
          >
            <Typography sx={{fontSize: 13}}>{openLabel}</Typography>
          </Box>
        </CardContent>
        <CardActions>
          {isOpened && (
            <Button size="small" onClick={handleCopyLink}>
              {copyLinkS}
            </Button>
          )}
          <Button size="small" onClick={handleEditClick}>
            {editS}
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
