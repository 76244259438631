import {Box, Button} from "@mui/material";

export default function ButtonOrder({operationName, bimboxName, demoUrl}) {
  // strings

  const orderS = "Passer commande";

  // handlers

  const mailto = "contact@capla-technologies.com";
  const subject = `[${operationName}] Commande de la bimbox ${bimboxName}`;
  const body = `Je souhaiterais commander la bimbox ${demoUrl}`;
  const emailUrl = `mailto:${mailto}?subject=${subject}&body=${body}`;

  function handleOrderClick() {
    window.open(emailUrl);
  }
  return (
    <Box sx={{width: 1, px: 1}}>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={handleOrderClick}
      >
        {orderS}
      </Button>
    </Box>
  );
}
