import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
} from "@mui/material";

import {
  toggleIfcTypeVisibility,
  toggleAllTypesVisibility,
} from "Features/viewer3D/viewer3DSlice";
import useTranslation from "Features/translations/useTranslation";

export default function FilterTypes({editor3d, model}) {
  const dispatch = useDispatch();
  const {t} = useTranslation("viewer3D");

  // strings

  const filterString = t("viewer3D:filter.types");

  // data

  const editorEntity = editor3d?.getEntity(model?.id);
  const ifcModelID = editorEntity?.ifcModelID;

  const ifcSubsets = useSelector((state) => state.viewer3D.ifcSubsets).filter(
    (s) => s.ifcModelID === ifcModelID
  );
  const ifcTypes = useSelector((state) => state.viewer3D.ifcTypes).filter(
    (s) => s.ifcModelID === ifcModelID
  );
  const ifcStoreys = useSelector((state) => state.viewer3D.ifcStoreys).filter(
    (s) => s.ifcModelID === ifcModelID
  );

  const typesCount = ifcTypes.length;
  const visibleTypesCount = ifcTypes.filter((s) => s.visible === true).length;

  const none = visibleTypesCount === 0;
  const all = typesCount - visibleTypesCount === 0;
  const indeterminate = !none && !all;

  // local state

  const [allTypes, setAllTypes] = useState(true);

  // helpers

  // function showAllTypes() {
  //   dispatch(toggleAllTypesVisibility({ifcModelID, visible: true}));
  //   editorEntity.toggleAllTypesVisibility(true, ifcStoreys);
  // }

  // function hideAllTypes() {
  //   dispatch(toggleAllTypesVisibility({ifcModelID, visible: false}));
  //   editorEntity.toggleAllTypesVisibility(false, ifcStoreys);
  // }
  
  // handlers

  function handleAllTypesChange(event) {
    try {
      const newChecked = event.target.checked;

      // new ifc storeys
      const newIfcTypes = ifcTypes.map((ifcType) => {
        return {...ifcType, visible: newChecked};
      });

      // apply filters
      editorEntity.applyFilters(ifcStoreys, newIfcTypes);
      setAllTypes((v) => !v);
      if (newChecked)
        dispatch(toggleAllTypesVisibility({ifcModelID, visible: true}));
      if (!newChecked)
        dispatch(toggleAllTypesVisibility({ifcModelID, visible: false}));
    } catch (e) {
      console.log(e);
    }
  }

  function handleCheckboxChange(e, ifcModelID, type) {
    try {
      // state
      dispatch(toggleIfcTypeVisibility({ifcModelID, type}));

      // update of ifcTypes
      const newIfcTypes = ifcTypes.map((ifcType) => {
        if (ifcType.type !== type) {
          return ifcType;
        } else {
          const newIfcType = {...ifcType};
          newIfcType.visible = !ifcType.visible;
          return newIfcType;
        }
      });

      // simple
      editorEntity.applyFilters(ifcStoreys, newIfcTypes);

      // advanced
      // const subsets = ifcSubsets.filter(
      //   (s) => s.ifcModelID === ifcModelID && s.type === type
      // );
      // subsets.forEach((subset) => {
      //   const ifcStorey = ifcStoreys.find(
      //     (ifcStorey) => ifcStorey.expressID === subset.storeyExpressID
      //   );
      //   if (ifcStorey?.visible) {
      //     editorEntity.toggleSubsetVisibility(subset.uuid);
      //   }
      // });
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    // update all layers checkbox
    if (all) setAllTypes(true);
    if (none) setAllTypes(false);
  }, [visibleTypesCount]);

  return (
    <>
      <Box sx={{display: "flex", alignItems: "center", paddingLeft: 0.5}}>
        <Checkbox
          size="small"
          checked={allTypes}
          indeterminate={indeterminate}
          onChange={handleAllTypesChange}
        />
        <Typography variant="body2">
          <b>{filterString}</b>
        </Typography>
      </Box>
      <List dense>
        {ifcTypes?.map((type) => {
          const count = ifcSubsets
            .filter((s) => s.type === type.type)
            .reduce((ac, subset) => ac + subset.ids.length, 0);
          return (
            <ListItem key={type.type} sx={{paddingBottom: 0, paddingTop: 0}}>
              <ListItemIcon sx={{height: 24, width: 24}}>
                <Checkbox
                  size="small"
                  edge="start"
                  checked={type.visible}
                  onChange={(e) =>
                    handleCheckboxChange(e, type.ifcModelID, type.type)
                  }
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText>{`${type.type} (${count})`}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
