import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Box, Button, Menu, MenuItem, Typography} from "@mui/material";
import {ArrowDropDown as Down} from "@mui/icons-material";

import {
  setScopeSelectionMode,
  setSectorsInScope,
  setRoomsInScope,
} from "../measurementsSlice";

import { setBuildingInScope } from "Features/buildings/buildingsSlice";

export default function SelectorMeasurementsScope() {
  const dispatch = useDispatch();

  // strings

  const byBuildingS = "Par bâtiment";
  const bySectorS = "Par secteur";
  const byRoomS = "Par zone";

  // data

  let scopeSelectionMode = useSelector(
    (s) => s.measurements.scopeSelectionMode
  );

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers - tools

  const tools = [
    {
      id: "BY_BUILDING",
      label: byBuildingS,
    },
    {
      id: "BY_SECTOR",
      label: bySectorS,
    },
    {
      id: "BY_ROOM",
      label: byRoomS,
    },
  ];
  const label = tools.find((t) => t.id === scopeSelectionMode)?.label;

  //handlers

  function handleToolChange(toolId) {
    dispatch(setBuildingInScope(null));
    dispatch(setSectorsInScope([]));
    dispatch(setRoomsInScope([]));
    dispatch(setScopeSelectionMode(toolId));
    setAnchorEl(null);
  }

  return (
    <>
      <Box
        sx={{
          color: "text.secondary",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          endIcon={<Down />}
          size="small"
          color="inherit"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          {label}
        </Button>
      </Box>
      <Menu open={open} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        {tools.map((tool) => {
          return (
            <MenuItem
              key={tool.id}
              onClick={() => {
                handleToolChange(tool.id);
              }}
            >
              <Typography variant="body2">{tool.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
