import useElementTypesDrawingShapesOptions from "../hooks/useElementTypeDrawingShapesOptions";

import {Box, Typography} from "@mui/material";

import SelectOptionsWithObjects from "Components/SelectOptionsWithObjects";

export default function SelectorDrawingShapeInDatagrid({
  drawingShape,
  onChange,
  editable,
}) {
  const options = useElementTypesDrawingShapesOptions();

  let option = options.find((o) => o.key === drawingShape);
  if (!option) option = null;

  function handleOptionChange(option) {
    if (onChange) onChange(option?.key);
  }
  return (
    <Box sx={{width: 1, p: 0, m: 0}}>
      {!editable ? (
        <Typography variant="body2" sx={{fontWeight: "inherit"}}>
          {option?.name}
        </Typography>
      ) : (
        <SelectOptionsWithObjects
          option={option}
          onChange={handleOptionChange}
          options={options}
        />
      )}
    </Box>
  );
}
