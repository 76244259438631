import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {Button} from "@mui/material";

import {setSelectedZoneId} from "../zonesSlice";

// import useScene from "Features/scenes/useScene";
import {setOpenSections, setFullscreen} from "Features/ui/uiSlice";
import {
  setSelectedPdfModelId,
  setViewMode,
} from "Features/viewer3D/viewer3DSlice";
import {setIsViewerFullScreen} from "Features/viewer3D/viewer3DSlice";

export default function ButtonLinkToZoneInPdf({zone}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log("zone", zone);
  // strings

  const linkS = "See in PDF";

  // data

  const {sceneId} = useParams();
  // const scene = useScene(sceneId);
  const openSections = useSelector((s) => s.ui.openSections);

  // handlers

  function handleClick() {
    const url = `bimboxes/${sceneId}/zones`;
    navigate(url);
    dispatch(setFullscreen(false));
    dispatch(setIsViewerFullScreen(false));

    dispatch(
      setOpenSections({
        ...openSections,
        outlet: true,
        viewer3D: false,
        fixedViewersBox: true,
      })
    );
    dispatch(setViewMode("PDF"));
    dispatch(setSelectedZoneId(zone.id));
    dispatch(setSelectedPdfModelId(zone.pdfModelId));
  }

  return (
    <Button size="small" onClick={handleClick}>
      {linkS}
    </Button>
  );
}
