import {useSelector, useDispatch} from "react-redux";

import {setSelectedScopeIdInModule} from "../scopesSlice";

import {Box} from "@mui/material";

import ListScopes from "./ListScopes";

import useScopesByScene from "../hooks/useScopesByScene";

export default function SectionScopesInLeftPanel({scene, caplaEditor}) {
  const dispatch = useDispatch();
  //
  const scopes = useScopesByScene(scene);
  //
  const selectedScopeIdInModule = useSelector(
    (s) => s.scopes.selectedScopeIdInModule
  );
  const selectedScopeIdInScene = useSelector((s) => s.scopes.selectedScopeId);

  // handlers

  function handleSelectionChange(id) {
    dispatch(setSelectedScopeIdInModule(id));
  }
  return (
    <Box sx={{flexGrow: 1, display: "flex", flexDirection: "column", width: 1}}>
      <ListScopes
        scopes={scopes}
        selectedScopeId={selectedScopeIdInModule}
        selectedScopeIdInScene={selectedScopeIdInScene}
        onSelectionChange={handleSelectionChange}
      />
    </Box>
  );
}
