import {Box, Typography} from "@mui/material";
import offerDescriptionMap from "../data/offerDescriptionMap";

export default function HeaderPrice({offerKey}) {
  let offerDescription = offerDescriptionMap["PEINTURE_LOGEMENTS"];

  if (offerKey) offerDescription = offerDescriptionMap[offerKey];

  const {name, description, priceLabel, details} = offerDescription;

  return (
    <Box sx={{pb: 1}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <Box>
          <Typography sx={{fontWeight: "bold"}}>{name}</Typography>
        </Box>
        <Box
          sx={{
            px: 1,
            borderRadius: "15px",
            height: "24px",
            bgcolor: "common.yellow",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2">{priceLabel}</Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2">{description}</Typography>
      </Box>

      <Box sx={{px: 1}}>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{whiteSpace: "pre"}}
        >
          {details}
        </Typography>
      </Box>
    </Box>
  );
}
