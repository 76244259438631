import {useDispatch} from "react-redux";

import {setStyle, setNum} from "../elementTyporSlice";

import useSelectedElementTypesGroup from "Features/elementTypes/hooks/useSelectedElementTypesGroup";
import useSceneModule from "Features/navigation/useSceneModule";

import {Box, TextField, Typography, List} from "@mui/material";

import ListElementTypes from "Features/elementTypes/components/ListElementTypes";
import SectionTitleAndStyle from "./SectionTitleAndStyle";
import useTempElementType from "../hooks/useTempElementType";
import useSelectedRessourcesGroup from "Features/ressources/hooks/useSelectedRessourcesGroup";
import ListItemButtonRessourceBasic from "Features/ressources/components/ListItemButtonRessourceBasic";
import useSelectedRessource from "Features/ressources/hooks/useSelectedRessource";

export default function SectionStepRessources({scene}) {
  const dispatch = useDispatch();

  // helpers

  const styleS = "Style";
  const numS = "Num";

  const numPlaceholder = "x.x.x";

  // data

  const sceneModule = useSceneModule();
  const inEditMeasurements = sceneModule === "EDIT_MEASUREMENTS";
  const tempElementType = useTempElementType();

  const group = useSelectedRessourcesGroup(scene, {
    inEditMeasurements,
  });

  let resIds = [];
  if (tempElementType?.res)
    resIds = tempElementType.res.map(({resId}) => resId);

  // helpers

  const ressources = Array.isArray(group?.ressources) ? group.ressources : [];

  // helpers options

  const options = {hideQuantity: true};

  // handlers

  return (
    <Box sx={{display: "flex", minHeight: 0, width: 1}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          minHeight: 0,
          minWidth: 0,
          flexGrow: 1,
        }}
      >
        <List>
          {ressources.map((ressource) => {
            const selected = resIds.includes(ressource.id);
            return (
              <ListItemButtonRessourceBasic
                ressource={ressource}
                selected={selected}
              />
            );
          })}
        </List>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField size="small" />
      </Box>
    </Box>
  );
}
