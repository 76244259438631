import {useSelector} from "react-redux";

export default function useMeasurementsModels(sceneClientId) {
  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) =>
      m.sceneClientId === sceneClientId &&
      m.type === "MEASUREMENTS" &&
      m.enabled
  );
  return models;
}
