import updateFiltersStrWithOneFiltersStr from "./updateFiltersStrWithOneFiltersStr";

export default function updateFiltersStrWithFiltersStr(
  filtersStr,
  filtersStrToAdd,
  mode
) {
  let newFiltersStr = Array.isArray(filtersStr) ? [...filtersStr] : [];
  //
  // EDGE CASES
  if (mode === "replace" && !filtersStrToAdd) return [];

  //
  if (Array.isArray(filtersStrToAdd)) {
    filtersStrToAdd.forEach((oneFiltersStr) => {
      newFiltersStr = updateFiltersStrWithOneFiltersStr(
        newFiltersStr,
        oneFiltersStr,
        mode
      );
    });
  }

  return newFiltersStr;
}
