import getFixedScope from "./getFixedScope";
import getModelIdsInScope from "./getModelIdsInScope";

export default function getModelsInScope(models, scope, options) {
  // options

  const measurementsOnly = options?.measurementsOnly;
  const useFixedScope = options?.useFixedScope;

  // result

  let modelIdsInScope;
  if (useFixedScope) {
    const fixedScope = getFixedScope(scope, models);
    modelIdsInScope = getModelIdsInScope(fixedScope);
  } else {
    modelIdsInScope = getModelIdsInScope(scope);
  }

  let result = models.filter((m) => modelIdsInScope.includes(m.id));

  // all

  if (scope?.id === "all" || !scope?.id) result = [...models];

  // more filters
  if (measurementsOnly)
    result = result.filter((m) => m.type === "MEASUREMENTS");

  return result;
}
