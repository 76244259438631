import articleTypesMapToArray from "./articleTypesMapToArray";
import articleTypesArrayToMap from "./articleTypesArrayToMap";

export default function applyInnerGlobalFiltersStrToArticleTypes(articleTypes) {
  const typesArray = articleTypesMapToArray(articleTypes);

  let globalFiltersStr;
  let globalFunc;
  let groupFiltersStrMap = {};

  Object.entries(articleTypes).forEach(([groupId, typeMap]) => {
    if (groupId === "filtersStr") {
      globalFiltersStr = typeMap;
    } else if (groupId === "globalFunc") {
      globalFunc = typeMap;
    } else {
      Object.entries(typeMap).forEach(([typeId, props]) => {
        if (typeId === "filtersStr") {
          groupFiltersStrMap[groupId] = typeMap;
        }
      });
    }
  });

  const newTypesArray = typesArray.map((item) => {
    if (item.groupId && item.typeId) {
      const groupFiltersStr = groupFiltersStrMap[item.groupId];
      //
      let filtersStr = [...(item.filtersStr ?? [])];
      if (groupFiltersStr) {
        filtersStr = [...new Set([...(filtersStr ?? []), ...groupFiltersStr])];
      }
      if (globalFiltersStr) {
        filtersStr = [...new Set([...(filtersStr ?? []), ...globalFiltersStr])];
      }
      return {
        ...item,
        filtersStr,
      };
    } else {
      return item;
    }
  });

  return articleTypesArrayToMap(newTypesArray);
}
