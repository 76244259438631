import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormMarker from "./FormMarker";

import useSelectedMarker from "../hooks/useSelectedMarker";

import {setTempMarker, setEditedMarker} from "Features/markers/markersSlice";

export default function SectionMarkersInSelectionPanel() {
  const dispatch = useDispatch();

  // data

  const selectedMarker = useSelectedMarker();
  const editedMarker = useSelector((s) => s.markers.editedMarker);
  const tempMarker = useSelector((s) => s.markers.tempMarker);

  const isEditing = useSelector((s) => s.markers.isEditing);

  // helpers

  let marker = selectedMarker;
  if (!selectedMarker) {
    marker = tempMarker;
  } else if (selectedMarker && isEditing) {
    marker = editedMarker;
  }

  // handlers

  function handleChange(_marker) {
    const marker = {..._marker, y: _marker.z, z: -_marker.y};
    if (isEditing) {
      dispatch(setEditedMarker(marker));
    } else if (!isEditing && !selectedMarker) {
      dispatch(setTempMarker(marker));
    }
  }
  return (
    <Box>
      <FormMarker
        marker={marker}
        onChange={handleChange}
        marginTop={true}
        canEdit={!selectedMarker || isEditing}
      />
    </Box>
  );
}
