import React from "react";

import {Box} from "@mui/material";

import {Cloud, Laptop} from "@mui/icons-material";

import defaultImage from "../assets/bimbox.png";

export default function ImageScene({scene, children}) {
  let url = scene?.imageUrl ? scene.imageUrl : scene?.imageClientUrl;
  if (!url) url = defaultImage;

  const fromCloud = Boolean(scene?.imageUrl);

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        width: "100%",
        height: "100%",
        position: "relative",
        backgroundImage: `url(${url})`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {children}
      <Box
        sx={{
          position: "absolute",
          bottom: "4px",
          right: "4px",
          color: "background.default",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* {fromCloud ? (
          <Cloud fontSize="small" color="inherit" />
        ) : (
          <Laptop fontSize="small" color="inherit" />
        )} */}
      </Box>
    </Box>
  );
}
