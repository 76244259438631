import getItemsMapById from "Utils/getItemsMapById";

export default function getVoidsById(measurements) {
  const voidsById = {};
  //
  const measById = getItemsMapById(measurements);
  //
  measurements.forEach((measurement) => {
    if (measurement.voids?.length > 0) {
      measurement.voids.forEach((id) => {
        if (!voidsById[id]) {
          voidsById[id] = measById[id];
        }
      });
    }
  });
  //
  return voidsById;
}
