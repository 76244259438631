import { useSelector } from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import SelectorZonesScope from "Features/zones/components/SelectorZonesScope";
import DataGridZones from "Features/zones/components/DataGridZones";
// import ToolbarZonesMain from "Features/zones/components/ToolbarZonesMain";
import SectionTitleZones from "Features/zones/components/SectionTitleZones";
import useIsFullscreen from "Features/ui/useIsFullscreen";
import Stretch from "Features/ui/components/Stretch";
import useIssuesetsInScope from "Features/issues/useIssuesetsInScope";

export default function SceneZones() {
  // params

  const {caplaEditor, scene} = useOutletContext();

  // data

  const scopeArray = useIssuesetsInScope();
  const modelsIdsHash = scopeArray[2];
  const sectorsFilter = useSelector((s) => s.measurements.sectorsInScope);
  const openSections = useSelector((s) => s.ui.openSections);
  const fullscreen = useIsFullscreen();

  // helpers

  const sectorsFilterHash = sectorsFilter.join("-");
  const fullscreenS = fullscreen ? "1" : "0";
  const osHash = Object.values(openSections).join("-");

  if (openSections.outlet)
    return (
      <Box sx={{bgcolor: "common.white", width: 1, height: 1}}>
        <Box sx={{height: "68px"}} />
        <SectionTitleZones />
        {!fullscreen && (
          <Box sx={{ml: 1}}>
            <SelectorZonesScope scene={scene} editor3d={caplaEditor?.editor3d} />
          </Box>
        )}
        {/* <ToolbarZonesMain /> */}
        <Box sx={{p: 2}} />
        {!fullscreen && (
          <Stretch
            propDependency={
              modelsIdsHash + osHash + sectorsFilterHash + fullscreenS
            }
          >
            <Box sx={{height: 1, width: 1}}>
              <DataGridZones caplaEditor={caplaEditor} scene={scene} />
            </Box>
          </Stretch>
        )}
      </Box>
    );
  return (<></>);
}
