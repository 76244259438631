import {useSelector, useDispatch} from "react-redux";

import {Box} from "@mui/material";

import FormElementType from "./FormElementType";
import sortByNum from "../utils/sortByNum";
import useElementTypesBySceneProxy from "../hooks/useElementTypesBySceneProxy";

import {
  setEditedElementType,
  setIsEditingElementType,
  setNewElementType,
} from "Features/elementTypes/elementTypesSlice";
import useSelectedElementType from "Features/elementTypes/hooks/useSelectedElementType";
import SelectorAutocomplete from "Components/SelectorAutocomplete";
import theme from "Styles/theme";

export default function SectionElementTypeInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const dispatch = useDispatch();

  // strings

  const copyTypeS = "Depuis un type existant";

  // data

  const selectedElementType = useSelectedElementType(scene);

  const newElementType = useSelector((s) => s.elementTypes.newElementType);

  const isEditing = useSelector((s) => s.elementTypes.isEditingElementType);

  const editedElementType = useSelector(
    (s) => s.elementTypes.editedElementType
  );

  const selectedElementTypesGroupProxy = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );
  console.log(selectedElementTypesGroupProxy, "selectedElementTypeGroupProxy");

  let elementTypes = useElementTypesBySceneProxy(scene);
  elementTypes = elementTypes
    .filter((t) => !t.isTitle && !t.parent)
    .map((t) => ({
      ...t,
      num: t.num ? t.num.toString() : "0",
      name: `${t.group} -> ${t.num} • ${t.name}`,
    }));

  elementTypes = elementTypes.sort((a, b) => sortByNum(a.num, b.num));

  // helper

  const elementType = isEditing ? editedElementType : selectedElementType;
  // console.log("elementTypeXXX", elementType);

  // helper - newElementType

  const newElementTypeWithGroupId = {
    ...newElementType,
    groupId: selectedElementTypesGroupProxy?.id,
  };
  // handlers

  function handleElementTypeChange(type) {
    if (!isEditing) dispatch(setIsEditingElementType(true));
    dispatch(setEditedElementType(type));
  }

  function handleNewElementTypeChange(type) {
    if (!type) type = {};
    dispatch(setNewElementType(type));
  }

  function handleNewElementTypeFromExisting(type) {
    if (!type) type = {};
    const regex = new RegExp(`^(${type.num}\\s)`);
    const newType = {...type, name: type.name.replace(regex, "")};
    dispatch(setNewElementType(newType));
  }

  return (
    <Box sx={{width: 1}}>
      {selectedElementType && (
        <FormElementType
          caplaEditor={caplaEditor}
          scene={scene}
          elementType={elementType}
          elementTypes={elementTypes}
          onChange={handleElementTypeChange}
          canEdit={true}
        />
      )}
      {!selectedElementType && (
        <Box
          sx={{backgroundColor: theme.palette.background.default, padding: 2}}
        >
          <SelectorAutocomplete
            name={copyTypeS}
            options={elementTypes}
            onChange={handleNewElementTypeFromExisting}
          />
        </Box>
      )}
      {!selectedElementType && (
        <FormElementType
          caplaEditor={caplaEditor}
          scene={scene}
          elementType={newElementTypeWithGroupId}
          elementTypes={elementTypes}
          onChange={handleNewElementTypeChange}
          canEdit={true}
        />
      )}
    </Box>
  );
}
