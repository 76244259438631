import { useSelector } from "react-redux";
import {useOutletContext} from "react-router-dom";

import {Box} from "@mui/material";

import DatagridPackages from "./DatagridPackages";
// import ToolbarPackagesMain from "./ToolbarPackagesMain";
import SectionDataGridToolbar from "./SectionDataGridToolbar";
import SectionTitlePackages from "./SectionTitlePackages";
import getPackagesForDatagrid from "../utils/getPackagesForDatagrid";
import usePackagesByScene from "../usePackagesByScene";

import useColoringJobsByScene from "Features/coloringJobs/useColoringJobsByScene";
import useColoringsByScene from "Features/colorings/useColoringsByScene";
import Stretch from "Features/ui/components/Stretch";

export default function PagePackages() {
  // params
  
  const {caplaEditor, scene} = useOutletContext();

  // data

  const jobs = useColoringJobsByScene(scene);
  const packages = usePackagesByScene(scene);
  const colorings = useColoringsByScene(scene);
  const os = useSelector((s) => s.ui.openSections);

  // helpers

  const packagesForDatagrid = getPackagesForDatagrid(packages, jobs, colorings);

  if (os.outlet)
    return (
      <Box sx={{width: 1}}>
        <Box sx={{height: 60}} />
        <SectionTitlePackages />
        {/* <ToolbarPackagesMain editor3d={caplaEditor?.editor3d} scene={scene} /> */}
        <SectionDataGridToolbar />
        <Stretch>
          <DatagridPackages
            scene={scene}
            packagesForDatagrid={packagesForDatagrid}
            packages={packages}
            caplaEditor={caplaEditor}
          />
        </Stretch>
      </Box>
    );
  return (<></>);
}
