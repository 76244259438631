import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {useSearchParams} from "react-router-dom";

import {Box} from "@mui/material";

import WebViewer from "@pdftron/webviewer";

import Layers from "./Layers";

import PdfEditorInstance2 from "../js/PdfEditorInstance2";
import usePdfModelUrl from "../usePdfModelUrl";

import {
  setClickedObject,
  setPdftronOffset,
} from "Features/viewer3D/viewer3DSlice";
import useFilteredSceneMeasurements from "Features/measurements/useFilteredSceneMeasurements";
// import useFilteredIssues from "Features/issues/useFilteredIssues";
import useTranslation from "Features/translations/useTranslation";
import useSceneModule from "Features/navigation/useSceneModule";
import useMeasurementsScopeMode from "Features/measurements/useMeasurementsScopeMode";
import {
  setSelectedMeasurementIds,
  updateSelectedMeasurementIds,
} from "Features/measurements/measurementsSlice";
import {setSelectedZoneId} from "Features/zones/zonesSlice";
import {setJump, setShowPdfSelector} from "../pdfSlice";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
// import { setCurrentSelection } from "Features/selection/selectionSlice";

export default function PdfWebViewerInstance2({scene, caplaEditor, fileUrl}) {
  const {i18n} = useTranslation();
  const language = i18n.language;
  const dispatch = useDispatch();
  const sceneModule = useSceneModule();

  // data

  const oldPdfModelId = useSelector((s) => s.pdf.selectedPdfModelIdOld);
  const inputs = useInputsByScene(scene);
  const oldPdfModel = inputs.find((input) => input.id === oldPdfModelId);

  // refs

  const viewerRef = useRef();
  const pdfEditorRef = useRef();

  // init

  async function init() {
    const wv = await WebViewer.WebComponent(
      {
        path: "../../../pdftron/public",
        licenseKey: process.env.REACT_APP_PDFTRON_KEY,
        initialDoc: oldPdfModel?.url,
        extension: "pdf",
        //enableMeasurement: true,
        //fullAPI: true,
      },
      viewerRef.current
    );
    pdfEditorRef.current = new PdfEditorInstance2({
      webViewer: wv,
      caplaEditor,
    });
    caplaEditor?.setEditorPdfInstance2(pdfEditorRef.current);
  }

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!fileUrl && oldPdfModel?.url && pdfEditorRef.current) {
      pdfEditorRef.current.load({url: oldPdfModel.url});
    }
    if (fileUrl) {
      pdfEditorRef.current.load({url: fileUrl});
    }
  }, [oldPdfModel?.url, pdfEditorRef.current, fileUrl]);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        position: "relative",
      }}
    >
      <div ref={viewerRef} style={{height: "100%"}} />
    </Box>
  );
}
