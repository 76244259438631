import {Button} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function ButtonCloseImageOverlay({editor3d}) {
  // strings

  const closeS = "Image superposée";

  // handlers

  function handleClose() {
    editor3d?.imageOverlay.hide();
  }

  return (
    <Button
      startIcon={<Close />}
      onClick={handleClose}
      variant="contained"
      color="secondary"
    >
      {closeS}
    </Button>
  );
}
