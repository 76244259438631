import getItemsMapById from "Utils/getItemsMapById";
export default function getMeasurementsCountByElementTypeGroupIdMap(
  measurements,
  elementTypes
) {
  //
  const groupIdCountMap = {};
  //
  const typeMap = getItemsMapById(elementTypes);
  //
  measurements.forEach((measurement) => {
    const measType = typeMap[measurement.elementTypeId];
    const measTypeGroupId = measType ? measType.groupId : "undefined";
    const prev = groupIdCountMap[measTypeGroupId];
    groupIdCountMap[measTypeGroupId] = prev ? prev + 1 : 1;
  });
  //
  return groupIdCountMap;
}
