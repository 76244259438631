import React from "react";

import {Typography, IconButton, Box} from "@mui/material";
import {ContentCopy as Copy} from "@mui/icons-material";

export default function CardProgressListingUrl({scene, progressListing}) {
  // helpers

  let sceneId = scene?.id;

  const url = `/bimbox/${sceneId}/progress/${progressListing?.id}`;
  const baseURL = process.env.REACT_APP_MOBILE_URL_PREFIX;
  const link = `${baseURL}${url}`;

  // handlers

  function handleCopyLink() {
    navigator.clipboard.writeText(link);
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 1,
        px: 1,
      }}
    >
      <Typography sx={{fontSize: 12}} color="text.secondary" noWrap>
        {link}
      </Typography>
      <IconButton size="small" onClick={handleCopyLink}>
        <Copy fontSize="small" />
      </IconButton>
    </Box>
  );
}
