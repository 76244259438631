import React from "react";

import {Box} from "@mui/material";

import FormProgressListingPackagesAddItem from "./FormProgressListingPackagesAddItem";
import FormProgressListingPackagesEditItem from "./FormProgressListingPackagesEditItem";
import usePackagesByScene from "Features/elementPackages/usePackagesByScene";

export default function FormProgressListingPackages({
  scene,
  listing,
  onChange,
  isEditing,
}) {
  // data

  const allPackages = usePackagesByScene(scene);

  // helpers

  let packageIds = listing?.metaData?.packageIds;
  if (!packageIds) packageIds = [];

  const packages = packageIds
    .map((id) => allPackages.find((p) => p.remoteId === id))
    .filter((p) => p);

  // handlers

  function handleAddPackage(pack) {
    onChange([...packageIds, pack.remoteId]);
  }

  function handleClose(pack) {
    onChange(packageIds.filter((id) => id !== pack.remoteId));
  }

  console.log("packagesPPP", packageIds, listing);
  return (
    <Box>
      {packages.map((model) => (
        <FormProgressListingPackagesEditItem
          key={model.id}
          model={model}
          onClose={() => handleClose(model)}
          isEditing={isEditing}
        />
      ))}
      {isEditing && (
        <Box sx={{mt: 1}}>
          <FormProgressListingPackagesAddItem
            scene={scene}
            onAdd={handleAddPackage}
          />
        </Box>
      )}
    </Box>
  );
}
