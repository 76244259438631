import {useSelector, useDispatch} from "react-redux";
import {setShowScreenDatagridHelper} from "../leftPanelSlice";

import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

import {Box, Typography, alpha, Button} from "@mui/material";
import useSceneMeasurements from "Features/measurements/useSceneMeasurements";

export default function ScreenHelperDatagrid({scene}) {
  const dispatch = useDispatch();

  // strings

  // const pdfS = "Tableau";
  const ressourcesS = "articles";
  const measurementsS = "éléments repérés";

  const description = `- Cliquez sur un article pour voir sa localisation en 3D et les plans utilisés pour son repérage.
  - Filtrez les articles par secteurs ou par zone. 
  - Téléchargez les données au format .xlsx`;

  const seeS = "Voir les articles";

  // data

  const openLeftPanel = useSelector((s) => s.navigation.openLeftPanel);

  const selectedRessourceGroup = useSelector(
    (s) => s.measurements.selectedRessourceGroup
  );
  const res = useRessourcesByScene(scene).filter(
    (r) => !r.isTitle && r.group === selectedRessourceGroup
  );
  const resIds = res.map((r) => r.id);
  const measurements = useSceneMeasurements(scene);

  const resMeas = measurements.filter((m) => {
    const measResIds = m.res ? m.res.map((r) => r.resId) : [];
    const intersection = measResIds.filter((id) => resIds.includes(id));
    return intersection.length > 0;
  });

  // helpers

  const measCountLabel = `${resMeas.length} ${measurementsS}`;
  const resCountLabel = `${res.length} ${ressourcesS}`;

  // handlers

  function handleClick() {
    dispatch(setShowScreenDatagridHelper(false));
  }
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        color: "common.white",
        bgcolor: (theme) => alpha(theme.palette.common.caplaBlack, 0.9),
        borderLeft: (theme) => `1px solid white`,
      }}
    >
      <Box
        sx={{
          display: openLeftPanel ? "flex" : "none",
          p: 4,
          alignItems: "center",
          pt: "150px",
          width: 1,
          height: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant="h4">{selectedRessourceGroup}</Typography>

        <Typography variant="body2">{resCountLabel}</Typography>
        <Typography variant="body2">{measCountLabel}</Typography>

        <Typography variant="body2" sx={{my: 8, whiteSpace: "pre-line"}}>
          {description}
        </Typography>
        <Button variant="outlined" color="inherit" onClick={handleClick}>
          {seeS}
        </Button>
      </Box>
    </Box>
  );
}
