import useUpdateSceneWithElementTypes from "../hooks/useUpdateSceneWithElementTypes";

import {Box, Typography, Button} from "@mui/material";

import elementTypesDefault from "../data/elementTypesDefault";

export default function CardCreateElementTypeGroupGeneric({
  scene,
  onGroupProxyCreated,
}) {
  const title = "Liste générique";
  const createS = "Créer la liste";
  const description =
    "Cette liste contient des éléments génériques : rectangles, polylignes, polygones,...";

  const updateSceneWithElementTypes = useUpdateSceneWithElementTypes(scene);
  //

  function handleClick() {
    updateSceneWithElementTypes(elementTypesDefault);
    const el0 = elementTypesDefault[0];
    const groupProxy = {id: el0.group, name: el0.group, fromScene: true};
    onGroupProxyCreated(groupProxy);
  }

  return (
    <Box
      sx={{p: 1, width: 1, bgcolor: "background.default", borderRadius: "4px"}}
    >
      <Typography variant="body2" sx={{mb: 2, fontWeight: "bold"}}>
        {title}
      </Typography>
      <Typography sx={{fontSize: 13}}>{description}</Typography>
      <Box sx={{width: 1, display: "flex", justifyContent: "end", mt: 2}}>
        <Box sx={{bgcolor: "warning.main", color: "common.white"}}>
          <Button size="small" color="inherit" onClick={handleClick}>
            {createS}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
