import {useSelector, useDispatch} from "react-redux";
import {resetFilterIdsByTab} from "../filtersSlice";

import {Box, Chip} from "@mui/material";
import {FilterAlt as Filter, Close} from "@mui/icons-material";

export default function ChipFilterOverview({tab}) {
  const dispatch = useDispatch();

  // data

  const filtersByTab = useSelector((s) => s.filters.filtersByTab);
  const filters = filtersByTab[tab];

  // helpers

  const count = Array.isArray(filters?.ids) ? filters.ids.length : "none";
  const exclude = filters?.exclude;

  const label = count === "none" ? `Aucun filtre` : `x${count}`;

  let color = "secondary";
  if (exclude) color = "primary";

  // handlers

  function handleCloseClick() {
    dispatch(resetFilterIdsByTab(tab));
  }
  return (
    <Chip
      size="small"
      icon={<Filter />}
      label={label}
      color={color}
      onDelete={count !== "none" ? handleCloseClick : undefined}
    />
  );
}
