import React, {useState} from "react";

import {useDispatch} from "react-redux";
import {setElementTypes, setName} from "../librarySlice";

import {
  Box,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import {Upload} from "@mui/icons-material";

import CardFileSelector from "Features/files/components/CardFileSelector";

import {urlToBlob, saveBlob} from "Features/files/utils";
import loadTypesFromExcel from "../utils/loadTypesFromExcel";

import library from "../assets/library.xlsx";

export default function ButtonLoadExcelLibrary({scene}) {
  const dispatch = useDispatch();
  // strings

  const loadS = "Charger une nouvelle bibliothèque";

  const downloadS = "Télécharger un modèle";
  const selectS = "Choisir un Excel";

  const countS = "Nombre d'éléments: ";

  const cancelS = "Annuler";
  const saveS = "Charger";

  // state

  const [open, setOpen] = useState(false);

  const [file, setFile] = useState(null);
  const [types, setTypes] = useState([]);

  // helpers

  async function handleFileChange(file) {
    setFile(file);
    let types = await loadTypesFromExcel({file});
    types = types.filter((t) => t.name);
    types = types.map((t) => ({...t, isTitle: Boolean(t.isTitle)}));
    setTypes(types);
  }

  const countLabel = `${countS} ${types.length}`;

  // handlers

  async function handleDownload() {
    const blob = await urlToBlob(library);
    saveBlob(blob, "template.xlsx");
  }

  function handleSave() {
    dispatch(setElementTypes(types));
    dispatch(setName(file?.name));
    setOpen(false);
    setFile(null);
  }

  return (
    <Box>
      <Box sx={{color: "text.secondary"}}>
        <Button
          color="inherit"
          startIcon={<Upload />}
          size="small"
          onClick={() => {
            setOpen(true);
          }}
        >
          {loadS}
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{loadS}</DialogTitle>
        <DialogContent>
          <Box>
            <CardFileSelector
              onFileChange={handleFileChange}
              file={file}
              buttonName={selectS}
              accept={".xlsx"}
            />
            <Box sx={{color: "text.secondary"}}>
              <Button onClick={handleDownload} size="small" color="inherit">
                {downloadS}
              </Button>
            </Box>
            <Typography variant="body2" sx={{mt: 3}}>
              {countLabel}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{cancelS}</Button>
          <Button onClick={handleSave} disabled={types.length === 0}>
            {saveS}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
