import {Sprite} from "three";
import {
  defaultMarkerMaterial,
  config1MarkerMaterial,
  config2MarkerMaterial,
  pickedMarkerMaterial,
} from "../utils/materials";
import {CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer.js";

class Marker {
  constructor({marker, modelId}) {
    this.object = this._createMarker(marker);
    this.type = "MARKER";
    this.entityID = marker?.id;
    this.modelId = modelId;
    this.props = {type: marker?.type};
  }

  _createMarker(marker) {
    const types = ["REFERENCE", "POSITIONING", "CONFIG_1", "CONFIG_2"];
    const factory = {
      REFERENCE: () => new Sprite(defaultMarkerMaterial),
      POSITIONING: () => new Sprite(defaultMarkerMaterial),
      CONFIG_1: () => new Sprite(config1MarkerMaterial),
      CONFIG_2: () => new Sprite(config2MarkerMaterial),
    };
    if (types.includes(marker?.type)) {
      // marker
      const object = factory[marker.type]();
      object.layers.enable(1);
      object.scale.x = 0.2;
      object.scale.y = 0.2;

      const x = marker?.x ? marker.x : 0;
      const y = marker?.y ? marker.y : 0;
      const z = marker?.z ? marker.z : 0;

      object.position.set(x, y, z);

      if (marker.type != "REFERENCE") object.visible = false;

      object.userData.type = "MARKER";
      if (marker?.id) object.userData.entityID = marker.id;

      // target
      const target = document.createElement("div");
      target.style.height = "24px";
      target.style.width = "24px";
      target.style.borderRadius = "12px";
      target.style.transform = "translate(-12px,-12px)";
      target.style.border = "1px solid black";
      const objectCSS = new CSS2DObject(target);

      //
      // if (marker.type == "CONFIG_1" || marker.type == "CONFIG_2")
      //   marker.add(objectCSS);

      // show

      object.layers.enable(1);

      return object;
    } else {
      console.log("marker type not allowed");
    }
  }

  show() {
    if (this.object) this.object.layers.enable(1);
  }
  hide() {
    if (this.object) this.object.layers.disable(1);
  }
  pick() {
    this.object.material = pickedMarkerMaterial;
    return this;
  }

  unpick() {
    this.object.material = defaultMarkerMaterial;
  }

  select() {
    console.log("selectMarker", this);
    this.object.material = pickedMarkerMaterial;
    return this;
  }

  unselect() {
    this.object.material = defaultMarkerMaterial;
  }

  parse() {
    return {type: "MARKER", marker: this.marker, entityID: this.entityID};
  }

  // load() {
  //   this.scene.add(this.object);
  //   if (this.props.type === "REFERENCE") {
  //     if (this.entities) this.entities.push(this);
  //     if (this.objectEntities) this.objectEntities.push(this);
  //   }
  // }

  // delete() {
  //   this.scene.remove(this.object);
  //   const newEntities = this.entities.filter(
  //     (e) => e.type !== "MARKER" || e.marker?.id !== this.marker.id
  //   );
  //   const newObjectEntities = this.entities.filter(
  //     (e) => e.type !== "MARKER" || e.marker?.id !== this.marker.id
  //   );
  //   this.entities = newEntities;
  //   this.objectEntities = newObjectEntities;
  // }
}
export default Marker;
