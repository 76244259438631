import {useSelector} from "react-redux";

import {Box, IconButton, CircularProgress} from "@mui/material";
import {SystemUpdateAlt as Load, Visibility} from "@mui/icons-material";

export default function ButtonDetailModel({model, editor3d}) {
  // data

  const detailedModels = useSelector((state) => state.viewer3D.detailedModels);

  const modelsStatus = useSelector((state) => state.viewer3D.modelsStatus);
  const status = modelsStatus[model.sceneClientId][model.id];

  // helpers

  const detailed = detailedModels.includes(model.id);

  // handlers

  function handleSeeClick(e) {
    e.preventDefault();
    e.stopPropagation();
    editor3d?.cameras.fitCameraTo({
      models: [model],
      controls: editor3d?.controls.activeControls,
    });
  }

  function handleLoadClick() {
    editor3d?.loader.loadModelFilesAndLoadDetail(model);
  }

  return (
    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {detailed && (
        <IconButton size="small" onClick={handleSeeClick} color="inherit">
          <Visibility fontSize="small" />
        </IconButton>
      )}
      {!detailed && status !== "loading" && (
        <IconButton size="small" onClick={handleLoadClick}>
          <Load fontSize="small" />
        </IconButton>
      )}
      {!detailed && status === "loading" && <CircularProgress size={20} />}
    </Box>
  );
}
