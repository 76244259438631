import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Paper} from "@mui/material";

import {setSelectedMarkersModelId} from "../markersSlice";
import useMarkersModels from "../hooks/useMarkersModels";

import SelectorModelInToolbar from "Features/viewer3D/components/SelectorModelInToolbar";
import ButtonCreateMarkerOnPdf from "Features/viewer3D/components/ButtonCreateMarkerOnPdf";

export default function ToolbarAddMarkerOnPdf({caplaEditor, scene}) {
  const dispatch = useDispatch();
  // data

  const markersModels = useMarkersModels(scene?.clientId);
  const selectedMarkersModelId = useSelector(
    (s) => s.markers.selectedMarkersModelId
  );
  const selectedMarkersModel = markersModels.find(
    (m) => m.id === selectedMarkersModelId
  );
  const mode = useSelector((s) => s.viewer3D.mode);

  // bgcolor

  const background = (theme) =>
    `linear-gradient(to right,${theme.palette.secondary.main},${theme.palette.primary.main})`;

  // helper

  const show = mode !== "ADDING_PDF_MARKER";

  // handlers

  function handleModelChange(model) {
    dispatch(setSelectedMarkersModelId(model?.id));
  }

  // effect

  useEffect(() => {
    if (!selectedMarkersModelId && markersModels.length > 0) {
      dispatch(setSelectedMarkersModelId(markersModels[0].id));
    }
  }, [selectedMarkersModelId, markersModels?.length]);

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0.5,
        background,
      }}
    >
      {show && (
        <SelectorModelInToolbar
          models={markersModels}
          model={selectedMarkersModel}
          onChange={handleModelChange}
        />
      )}
      <ButtonCreateMarkerOnPdf caplaEditor={caplaEditor} />
    </Paper>
  );
}
