import {useState} from "react";

import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";

import {
  Dialog,
  Box,
  // Typography,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

export default function DialogLinkToRessource({scene, onSave, open, onClose}) {
  // string

  const title = "Liez la note à un article";

  const ressourceS = "Article";

  const cancelS = "Annuler";
  const saveS = "Ajouter";

  // data

  const ressources = useRessourcesByScene(scene);

  // helpers

  const options = ressources.map((r) => (
    {...r, name: `${r.group} -> ${r.num} • ${r.name}`}
  ));

  // state

  const [ressource, setRessource] = useState(null);

  // handlers

  function handleChange(res) {
    setRessource(res);
  }

  function handleCancel() {
    onClose();
  }

  function handleSave() {
    onSave(ressource);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{p: 2}}>
          <SelectorAutocomplete
            name={ressourceS}
            value={ressource}
            options={options}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelS}</Button>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
