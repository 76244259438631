import {useOutletContext} from "react-router-dom";

import SectionSubjects from "./SectionSubjects";
import SectionHeader from "./SectionHeader";
import ButtonRefreshMapping from "./ButtonRefreshMapping";

import {Box} from "@mui/material";

import Stretch from "Features/ui/components/Stretch";

export default function PageAnnotatedPdf() {
  const {scene} = useOutletContext();

  return (
    <Box sx={{width: 1}}>
      <Box sx={{height: "60px"}} />
      <SectionHeader />

      <ButtonRefreshMapping scene={scene} />

      <Stretch>
        <Box sx={{width: 1, height: 1, overflow: "auto"}}>
          <SectionSubjects scene={scene} />
        </Box>
      </Stretch>
    </Box>
  );
}
