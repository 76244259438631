// return true if the measurement matches the filter

import computeExpr from "./computeExpr";

export function testMeasurementOnFuncFilters({
  measurement,
  filtersStr,
  sector,
  zone,
  room,
}) {
  if (!Array.isArray(filtersStr) || filtersStr?.length === 0) {
    return true;
  } else {
    return filtersStr.reduce(
      (sum, cur) =>
        sum && computeExpr({expr: cur, measurement, zone, room, sector}),
      true
    );
  }
}
