import React from "react";

import {Box, Typography} from "@mui/material";

import SignInButton from "./SignInButton";

export default function CardCallToActionSignin({open}) {
  // strings

  const callToActionString = "⚠️ You are not logged in.";

  // helpers

  const display = !open ? "none" : "flex";

  return (
    <Box
      sx={{
        display,
        bgcolor: "common.caplaBlack",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0.8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="body2" sx={{mb: 4, color: "common.white"}}>
          {callToActionString}
        </Typography>
        <SignInButton />
      </Box>
    </Box>
  );
}
