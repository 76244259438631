import React from "react";

import {useDispatch} from "react-redux";
import {setSelectedSceneClientId} from "../scenesSlice";

import {Box, Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

export default function ButtonBackScenesInSelectionPanel() {
  const dispatch = useDispatch();

  // string

  const backS = "+ bimbox";

  // handlers

  function handleClick() {
    // state
    dispatch(setSelectedSceneClientId(null));
  }

  return (
    <Button
      startIcon={<Back />}
      size="small"
      onClick={handleClick}
      sx={{whiteSpace: "nowrap"}}
    >
      {backS}
    </Button>
  );
}
