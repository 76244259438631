function removeFormulaFilter(formula) {
  if (formula) return formula.replace(/\s?\{\{[^\}]+\}\}/g, "");
  else return "";
}

function getMainQuantity(item, unit) {
  try {
    switch (unit) {
      case "UN":
        return item.count;
      case "ML":
        return item.length;
      case "M2":
        return item.area;
      case "M3":
        return item.volume;
      default:
        return item.count;
    }
  } catch (e) {
    console.log("error", e);
  }
}

function computeFormula(expr, item, zone, room, sector) {
  const {
    count,
    perimeter,
    length,
    area,
    volume,
    lengthNet,
    areaNet,
    volumeNet,
    lengthRaw,
    areaRaw,
    volumeRaw,
    dim1,
    dim2,
    dim3,
    height,
    heightE,
    heightN,
    lengthP,
    areaP,
    p1,
    p2,
    p3,
    offset,
    zInf,
    zSup,
  } = item;
  try {
    return Function(
      `"use strict";
      const C=${count ? count : 0};
      const P=${perimeter ? perimeter : 0};
      const L=${length ? length : 0};
      const A=${area ? area : 0};
      const S=${area ? area : 0};
      const V=${volume ? volume : 0};
      const L_RAW=${lengthRaw ? lengthRaw : 0};
      const A_RAW=${areaRaw ? areaRaw : 0};
      const S_RAW=${areaRaw ? areaRaw : 0};
      const V_RAW=${volumeRaw ? volumeRaw : 0};
      const L_NET=${lengthNet ? lengthNet : 0};
      const A_NET=${areaNet ? areaNet : 0};
      const S_NET=${areaNet ? areaNet : 0};
      const V_NET=${volumeNet ? volumeNet : 0};
      const D1=${dim1 ? dim1 : 0};
      const D2=${dim2 ? dim2 : 0};
      const D3=${dim3 ? dim3 : 0};
      const H=${height ? height : 0};
      const HE=${heightE ? heightE : 0};
      const HN=${heightN ? heightN : 0};
      const VE=${heightE ? heightE * length * dim1 : 0};
      const VN=${heightN ? heightN * length * (dim1 + 2 * dim3) : 0};
      const LP=${lengthP ? lengthP : 0};
      const SP=${areaP ? areaP : 0};
      const P1=${p1 ? (typeof p1 === "string" ? '"' + p1 + '"' : p1) : 0};
      const P2=${p2 ? (typeof p2 === "string" ? '"' + p2 + '"' : p2) : 0};
      const P3=${p3 ? (typeof p3 === "string" ? '"' + p3 + '"' : p3) : 0};
      const OFFSET=${
        zone?.position?.z && offset
          ? zone.position.z + offset
          : offset
          ? offset
          : 0
      };
      const ZINF=${zInf ? zInf : 0};
      const ZSUP=${zSup ? zSup : 0};
      const SECTEUR=${sector?.code ? '"' + sector.code + '"' : 0};
      const BAT=${sector?.building ? '"' + sector.building + '"' : 0};
      const ZONE=${room?.code ? '"' + room.code + '"' : 0};
      return(${expr})`
    )();
  } catch (e) {
    console.log("ERROR computing formula", expr, e);
  }
}

export function applyFormulaFilter(
  meas,
  func,
  zone,
  room,
  sector,
  filterNoVoids
) {
  meas = {
    ...meas,
    lengthRaw: meas.length,
    areaRaw: meas.area,
    volumeRaw: meas.volume,
  };
  if (filterNoVoids !== true && meas.lengthNet) {
    meas = {
      ...meas,
      length: meas.lengthNet,
      area: meas.areaNet,
      volume: meas.volumeNet,
    };
  } else if (!meas.lengthNet) {
    meas = {
      ...meas,
      lengthNet: meas.length,
      areaNet: meas.area,
      volumeNet: meas.volume,
    };
  }
  const matches = func?.matchAll(/\s?\{\{([^\}]+)\}\}/g);
  const filter = matches ? [...matches] : [];
  if (filter.length === 0) return true;
  else
    return filter.reduce(
      (sum, next) => sum && computeFormula(next[1], meas, zone, room, sector),
      true
    );
}

export default function computeRessourceQuantity({
  item,
  res,
  ressource,
  withDecimal,
  filterNoVoids,
}) {
  return 1.24;
  // try {
  //   if (res) {
  //     const {mode, func} = res;
  //     let q;

  //     if (mode === "AUTO") {
  //       q = getMainQuantity(item, item.unit);
  //     } else if (mode === "RATIO") {
  //       q = getMainQuantity(item, ressource?.ratioU) * ressource?.ratioV;
  //     } else if (mode === "FORMUL") {
  //       item = {
  //         ...item,
  //         lengthRaw: item.length,
  //         areaRaw: item.area,
  //         volumeRaw: item.volume,
  //       };
  //       if (filterNoVoids !== true && item.lengthNet) {
  //         item = {
  //           ...item,
  //           length: item.lengthNet,
  //           area: item.areaNet,
  //           volume: item.volumeNet,
  //         };
  //       } else if (!item.lengthNet) {
  //         item = {
  //           ...item,
  //           lengthNet: item.length,
  //           areaNet: item.area,
  //           volumeNet: item.volume,
  //         };
  //       }
  //       q = computeFormula(removeFormulaFilter(func), item);
  //     }
  //     if (typeof q !== "number" || isNaN(q)) q = null;

  //     if (item.isVoid) q = -q;

  //     if (withDecimal && typeof q === "number") {
  //       const decN = ressource?.dec ? ressource.dec : 0;
  //       const dec = parseInt(decN);
  //       q = parseFloat(q.toFixed(dec));
  //     }

  //     return q;
  //   }
  // } catch (e) {
  //   console.log("e", e);
  // }
}
