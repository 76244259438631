import React, {useState, useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import useAccessToken from "Features/auth/useAccessToken";
import {createListing} from "Features/listings/listingsSlice";

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";
import FormNewProgressListing from "./FormNewProgressListing";
import {setSelectedProgressListingId} from "../progressSlice";

import {format} from "date-fns";

export default function ButtonDialogCreateProgressListing({scene}) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  // string
  const buttonLabel = "Avancement";
  const defaultName = "Avancement";

  const title = "Créez un avancement";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const {email} = useSelector((state) => state.auth.data);

  // state

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const initListing = {
    name: `${defaultName} - ${format(new Date(), "dd/MM/yy")}`,
    description: "",
    listingType: "recordset",
    scenes: [scene.id],
    userMemberships: [
      {
        user: {email},
        role: "admin",
      },
    ],
  };
  const [progressListing, setProgressListing] = useState(initListing);
  useEffect(() => {
    setProgressListing(initListing);
  }, [scene?.id, email]);

  // handlers

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  function handleProgressListingChange(listing) {
    setProgressListing(listing);
  }
  function handleCancelClick() {
    setOpen(false);
  }
  async function handleSaveClick() {
    if (loading) return;
    setLoading(true);

    const listing = {...progressListing};
    const result = await dispatch(createListing({accessToken, listing, scene}));
    const {listing: newListing} = unwrapResult(result);
    dispatch(setSelectedProgressListingId(newListing.id));
    setLoading(false);
    setOpen(false);
  }
  return (
    <Box>
      <Box sx={{color: "text.secondary"}}>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<Add />}
          onClick={handleOpenDialog}
        >
          {buttonLabel}
        </Button>
      </Box>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{mt: 1}}>
            <FormNewProgressListing
              onChange={handleProgressListingChange}
              listing={progressListing}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button onClick={handleSaveClick}>{saveS}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
