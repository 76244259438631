import React from "react";

import {useSelector} from "react-redux";
import useWorksite from "Features/worksites/useWorksite";

import {IconButton, Tooltip, Box} from "@mui/material";
import {Navigation as Origin} from "@mui/icons-material";

export default function ButtonGoToOrigin({editor, scene}) {
  // strings

  const goToOriginString = "Go to origin";

  // data

  const spacePage = useSelector((s) => s.viewer3D.spacePage);
  const worksitesPage = useSelector((s) => s.viewer3D.worksitesPage);
  const worksiteId = useSelector((s) => s.space.worksiteId);
  const worksite = useWorksite({worksiteId});

  // helpers

  const latW = worksite?.lat;
  const lngW = worksite?.lng;
  const latS = scene?.lat;
  const lngS = scene?.lng;

  let lat = latS;
  let lng = lngS;
  if (spacePage) {
    lat = latW;
    lng = lngW;
  }

  // handlers

  function handleClick() {
    if (worksitesPage) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const crd = pos.coords;
          if (crd) {
            editor.mapEditor.azmEditor.goTo({
              lat: crd.latitude,
              lng: crd.longitude,
            });
          }
        },
        (err) => console.log(err)
      );
    } else if (typeof lat === "number" && typeof lng === "number") {
      editor.mapEditor.goTo({lat, lng});
    }
  }
  return (
    <Box>
      <Tooltip title={goToOriginString}>
        <IconButton
          size="small"
          sx={{borderRadius: "4px"}}
          onClick={handleClick}
        >
          <Origin fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
