import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Box,
  Typography,
  InputBase,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {setCutAtHeight} from "Features/viewer3D/viewer3DSlice";

export default function CardHeight({editor3d}) {
  const dispatch = useDispatch();
  const cam1deltaY = 0.2;

  // string

  const heightString = "Z (m) =";
  const cutLabel = "Cut at Z + 1m";

  // data

  const eyesHeight = useSelector((s) => s.viewer3D.eyesHeight);
  const cam2Height = useSelector((s) => s.viewer3D.cam2Height);
  const cutCam1 = useSelector((s) => s.viewer3D.cutCam1);
  const cutAtHeight = useSelector((s) => s.viewer3D.cutAtHeight);

  const floorHeight = cam2Height - eyesHeight;

  // state - height

  const initHeight = floorHeight.toFixed(2);

  const [height, setHeight] = useState(initHeight);

  useEffect(() => {
    console.log("update state");
    setHeight(initHeight);
  }, [initHeight]);

  // handlers

  function handleInputChange(e) {
    let h = e.target.value;
    h.replace(",", ".");
    setHeight(e.target.value);
  }

  function handleBlur() {
    const h = parseFloat(height);
    const prevCamY = editor3d?.cameras.camera2.position.y;
    const prevTgtY = editor3d?.controls.orbitControls2.target.y;
    const deltaY = h + eyesHeight - prevCamY;
    editor3d?.cameras.camera2.position.setY(h + eyesHeight);
    editor3d?.controls.orbitControls2.target.setY(prevTgtY + deltaY);
    editor3d?.controls.orbitControls2.update();
    if (cutCam1)
      editor3d?.cameras.camera1.position.setY(h + eyesHeight + cam1deltaY + 10.73);
    // horiontal cut
    console.log("card height", h + 1);
    editor3d?.clipper.setHorizontalClippingPlane(h + 1);
  }

  // handlers - cut

  function handleCutChange(e) {
    const checked = e.target.checked;
    dispatch(setCutAtHeight(checked));
    if (checked) {
      editor3d?.clipper.enableHorizontalClippingPlane();
    } else {
      editor3d?.clipper.disableHorizontalClippingPlane();
    }
  }

  return (
    <Box sx={{p: 0}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          bgcolor: "common.white",
          opacity: 0.8,
          px: 1,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={cutAtHeight}
              onChange={handleCutChange}
            />
          }
          label={
            <Typography variant="body2" noWrap>
              {cutLabel}
            </Typography>
          }
        />
        <Typography variant="body2" noWrap>
          {heightString}
        </Typography>
        <InputBase
          sx={{
            ml: 1,
            width: 80,
            fontSize: (theme) => theme.typography.body2.fontSize,
          }}
          value={height}
          onChange={handleInputChange}
          onBlur={handleBlur}
        />
      </Box>
    </Box>
  );
}
