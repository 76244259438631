import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import {
  Videocam,
  Close,
  FilterAlt,
  FilterAltOff,
  Image,
} from "@mui/icons-material";

import SelectorIssueset from "./SelectorIssueset";

import { setOpenIssuesInLeftPanel } from "../issuesSlice";
import useIssuesByScene from "../useIssuesByScene";

import {setSelectedIssueId} from "Features/issues/issuesSlice";
import {setSelectedIssueId as setSelectedIssueIdInModule, setShortcutToolViewer3D} from "Features/ui/uiSlice";
import {setFilteredMeasurementsViewMode, setShortcutElementTypes} from "Features/measurements/measurementsSlice";
import { setSelectionTabInViewer } from "Features/selection/selectionSlice";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import SectionMeasurementsFilters from "Features/measurements/components/SectionMeasurementsFilters";
import { setSelectedShortcutRight } from "Features/shortcut/shortcutSlice";
import { setUseBufferInShortcut, setZonesInBuffer } from "Features/zones/zonesSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function SectionIssuesInLeftPanel({
  scene,
  caplaEditor,
  width,
  inOutMode,
  viewer = true,
}) {
  const dispatch = useDispatch();
  
  // strings

  const issuesS = "Notes";
  const filterS = "Filtrer";
  const zonesS = "Fonds de plan";
  const camS = "Voir le point de vue";
  const allContextS = "Tout le contexte";

  // data

  let issues = useIssuesByScene(scene?.id);
  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );
  const selectedIssueId = useSelector((s) => s.issues.selectedIssueId);
  const types = useElementTypesBySceneProxy(scene);
  const zones = useZonesByScene(scene);
  const overlayMode = useSelector((s) => s.viewer3D.imageOverlayMode);

  // state

  const [filtered, setFiltered] = useState(null);
  const [overlayed, setOverlayed] = useState(null);

  // helpers

  issues = issues.filter((i) => i.listing === selectedIssuesListingId);
  issues = issues.sort((a, b) => a.title.localeCompare(b.title));
  // const imageModels = models.filter((m) => m.type === "IMAGE");
  const emptyContext = {
    imageModelIds: [],
    visibleModelsIds: [],
    measurementsFilters: {
      disabledTypes: [],
      elementTypes: [],
      materials: [],
      measurementsModelIds: [],
      p1s: [],
      p2s: [],
      p3s: [],
      phases: [],
      ressources: [],
      rooms: [],
      sectors: [],
      zones: [],
    },
  };

  // handlers

  const closeSectionIssues = () => {
    dispatch(setFilteredMeasurementsViewMode(inOutMode));
    dispatch(setOpenIssuesInLeftPanel(false));
  }

  function handleIssueClick(issue) {
    dispatch(setSelectedShortcutRight("SELECTION"));
    dispatch(setSelectionTabInViewer("issue"));
    setOverlayed(null);
    caplaEditor?.editor3d?.imageOverlay?.hide();
    if (issue.id !== selectedIssueId) {
      dispatch(setSelectedIssueId(issue.id));
      dispatch(setSelectedIssueIdInModule(issue.id));
      dispatch(setShortcutElementTypes(types.map((t) => t.id)));

    } else {
      dispatch(setSelectedIssueId(null));
      dispatch(setSelectedIssueIdInModule(null));
      dispatch(setZonesInBuffer([]));
    }
  }

  function handleCameraClick(e, context) {
    e.stopPropagation();
    const camera = context?.camera;
    if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);
  }

  function handleFilterClick(e, issueId, sceneContext) {
    e.stopPropagation();
    if (sceneContext) {
      if (filtered !== issueId) {
        caplaEditor?.editor3d?.sceneEditor.applyContext(sceneContext, false, true);
        setFiltered(issueId);
      } else {
        caplaEditor?.editor3d?.sceneEditor.applyContext(emptyContext, false, true);
        setFiltered(null);
      }
    }
  }

  function handleImageClick(e, issue) {
    e.stopPropagation();
    const issueModelIds = issue.context?.sceneContext?.imageModelIds;
    const zonesForBuffer = issueModelIds?.map((mid) => (
      zones.find((z) => z.imageModelId === mid)
    )).filter((z) => z);
    dispatch(setZonesInBuffer(zonesForBuffer ? zonesForBuffer : []));
    const sceneContext = issue.context?.sceneContext;
    if (sceneContext) {
      caplaEditor?.editor3d?.sceneEditor.hideAllImageModels();
      caplaEditor?.editor3d?.sceneEditor.applyContext(sceneContext);
    }
    dispatch(setUseBufferInShortcut(true));
    dispatch(setShortcutToolViewer3D("ZONES"));
  }

  function handleAllContextClick(e, issue, hasFilters, issueModelIds) {
    handleCameraClick(e, issue.context);
    if (hasFilters)
      handleFilterClick(e, issue.id, issue.context?.sceneContext);
    if (issueModelIds?.length > 0)
      handleImageClick(e, issue);
  }

  function handleOverlayClick(e, issue) {
    e.stopPropagation();
    if (overlayed !== issue.id || !overlayMode) {
      setOverlayed(issue.id);
      caplaEditor?.editor3d?.imageOverlay.setImage(issue.imageUrl);
      caplaEditor?.editor3d?.imageOverlay.show();
      const camera = issue.context?.camera;
      if (camera) caplaEditor?.editor3d?.sceneEditor.updateCamera(camera);
    } else {
      setOverlayed(null);
      caplaEditor?.editor3d?.imageOverlay.hide();
    }
  }

  return (
    <Paper
      elevation={viewer ? 12 : 0}
      sx={{
        width: width,
        minWidth: width,
        height: 1,
        maxHeight: 1,
        bgcolor: "common.white",
        flexDirection: "column",
        position: "relative",
        zIndex: 50,
        display: width > 0 ? "flex" : "none",
      }}
    >
      <Box 
        sx={{
          position: "absolute",
          top: 10,
          right: 1,
          display: viewer ? "flex" : "none",
        }} 
      >
        <IconButton onClick={closeSectionIssues}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{
        display: viewer ? "flex" : "none",
        alignItems: "center",
        m: 2
      }}>
        <Typography variant="h6" sx={{fontWeight: "bold"}}>
          {issuesS}
        </Typography>
      </Box>
      {viewer && (
        <SectionMeasurementsFilters
          scene={scene}
          caplaEditor={caplaEditor}
        />
      )}
      <Box
        sx={{
          width: 1,
          bgcolor: "common.white",
          px: 2,
          mt: 2,
          borderRadius: "8px",
        }}
      >
        <SelectorIssueset scene={scene} />
      </Box>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minWidth: 1,
          mt: 3,
        }}
      >
        <List
          sx={{
            bgcolor: "background.default",
            color: "common.black",
            width: 1,
          }}
          disablePadding
          dense
        >
          {issues.map((issue) => {
            const selected = issue.id === selectedIssueId;
            const sceneContext = issue.context?.sceneContext;
            const issueModelIds = sceneContext?.imageModelIds;
            let hasFilters = false;
            if (sceneContext?.measurementsFilters) {
              for (const [key, value] of Object.entries(sceneContext.measurementsFilters)) {
                if (key !== "measurementsModelIds" && value?.length > 0)
                  hasFilters = true;
              }
            }
            return (
              <ListItem
                key={issue.id}
                disablePadding
                divider
                sx={{
                  color: "inherit",
                }}
                selected={selected}
              >
                <ListItemButton
                  onClick={() => handleIssueClick(issue)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: 1,
                    p: 0,
                    m: 1,
                    borderRadius: "10px",
                    bgcolor: "common.white",
                    border: (theme) => `1px solid ${theme.palette.divider}`
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      width: 1,
                      minWidth: 0,
                      pt: 1,
                      px: 2,
                      fontWeight: "bold"
                    }}
                  >
                    {issue.title}
                  </Typography>
                  <Box sx={{width: 1, pl: 1.5, color: "secondary.main"}}>
                    <Button
                      onClick={(e) => handleOverlayClick(e, issue)}
                      size="small"
                      color="inherit"
                    >
                      {`${overlayed === issue.id && overlayMode ? "Masquer" : "Voir"} l'image 3D`}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 1,
                      height:"25px",
                      minWidth: 0,
                      pb: 1,
                      px: 1.5
                    }}
                  >
                    <Box sx={{color: "text.secondary"}}>
                      <Button
                        onClick={(e) => handleAllContextClick(e, issue, hasFilters, issueModelIds)}
                        size="small"
                        color="inherit"
                      >
                        {allContextS}
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{width: "25px"}}>
                        <Tooltip
                          title={camS}
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => handleCameraClick(e, issue.context)}
                          >
                            <Videocam fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box sx={{width: "25px"}}>
                        <Tooltip
                          title={filterS}
                          sx={{display: hasFilters ? "flex" : "none"}}
                        >
                          <IconButton
                            size="small"
                            onClick={(e) => handleFilterClick(e, issue.id, sceneContext)}
                            sx={{display: hasFilters ? "flex" : "none"}}
                          >
                            {filtered !== issue.id ? 
                              <FilterAlt fontSize="small" /> :
                              <FilterAltOff fontSize="small" />
                            }
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{minWidth: "60px"}}
                      >
                        <Tooltip
                          title={zonesS}
                          sx={{display: issueModelIds?.length > 0 ? "flex" : "none"}}
                        >
                          <Chip
                            size="small"
                            onClick={(e) => handleImageClick(e, issue)}
                            sx={{display: issueModelIds?.length > 0 ? "flex" : "none", flexDirection: "row"}}
                            label={issueModelIds ? `x ${issueModelIds.length}` : ""}
                            icon={<Image />}
                          >
                          </Chip>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Paper>
  );
}