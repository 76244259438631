import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setShowMeasurements} from "../measurementsSlice";

import {Box, FormControlLabel, Switch, Typography} from "@mui/material";

export default function ToggleMeasurementsVisibility({measurementsCount}) {
  const dispatch = useDispatch();

  // strings

  const measurementsS = "Afficher les éléments";

  // data

  const checked = useSelector((s) => s.measurements.showMeasurements);

  // helper

  const label = `${measurementsS} (${measurementsCount})`;

  // handlers

  function handleChange(e) {
    const checked = e.target.checked;
    dispatch(setShowMeasurements(checked));
  }

  return (
    <FormControlLabel
      control={
        <Switch
          size="small"
          checked={checked}
          onChange={handleChange}
          color="secondary"
          // sx={{
          //   "& .MuiSwitch-switchBase.Mui-checked": {
          //     color: "common.darkGrey",
          //   },
          //   "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          //     backgroundColor: "common.grey",
          //   },
          // }}
        />
      }
      label={<Typography sx={{fontSize: 13}}>{label}</Typography>}
    />
  );
}
