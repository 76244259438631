import removeFuncFilters from "./removeFuncFilters";
import computeExpr from "./computeExpr";

import cleanMeasurementRawAndNetQties from "Features/measurements/utils/cleanMeasurementRawAndNetQties";

export default function computeMeasurementQtyFromFunc({
  measurement,
  func,
  noVoids,
  voidsById,
}) {
  const cleanMeas = cleanMeasurementRawAndNetQties(measurement, {noVoids});
  //const expr = removeFuncFilters(func);

  let q =
    func === "x"
      ? 0
      : computeExpr({
          expr: func,
          measurement: cleanMeas,
          voidsById,
        });

  if (typeof q !== "number" || isNaN(q)) q = null;

  if (measurement.isVoid) q = -q;

  return q;
}
