import {useState, useEffect} from "react";

import {Box, TextField, Button} from "@mui/material";
import ButtonLoadNomenclatureFromExcel from "./ButtonLoadNomenclatureFromExcel";

export default function FormEditNomenclature({
  nomenclature,
  onNomenclatureCreated,
  onNomenclatureUpdated,
  onNomenclatureDeleted,
}) {
  // strings

  const nameS = "Nom";
  const treeS = "Catégories";

  const createS = "Créer";
  const updateS = "Mettre à jour";

  // helper

  const buttonLabel = nomenclature ? updateS : createS;

  // state

  const [tempRankNames, setTempRankNames] = useState();
  const [tempName, setTempName] = useState("");
  const [tempItems, setTempItems] = useState([]);

  // init

  useEffect(() => {
    if (nomenclature?.id) {
      setTempName(nomenclature.name);
      setTempRankNames(nomenclature.tree.rankNames);
      setTempItems(nomenclature.tree.items);
    }
  }, [nomenclature?.id]);

  const [loading, setLoading] = useState(false);

  // handlers

  function handleNameChange(e) {
    setTempName(e.target.value);
  }

  function handleNomenclatureChange({rankNames, items}) {
    setTempItems(items);
    setTempRankNames(rankNames);
  }

  async function handleSave() {
    const tempNomenclature = {
      name: tempName,
      tree: {
        rankNames: tempRankNames,
        items: tempItems,
      },
    };
    if (nomenclature) {
      onNomenclatureUpdated({...nomenclature, ...tempNomenclature});
    } else {
      onNomenclatureCreated(tempNomenclature);
    }
  }
  return (
    <Box sx={{pt: 2}}>
      <Box sx={{mb: 3, width: 200}}>
        <TextField
          fullWidth
          size="small"
          label={nameS}
          value={tempName}
          onChange={handleNameChange}
        />
      </Box>
      <ButtonLoadNomenclatureFromExcel
        onNomenclatureLoaded={handleNomenclatureChange}
      />
      <Box sx={{display: "flex", width: 1, justifyContent: "end"}}>
        <Button size="small" onClick={handleSave}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
}
