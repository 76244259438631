import {useDispatch} from "react-redux";

import {updateScene} from "Features/scenes/scenesSlice";
import {setRelationsTypesRessources} from "Features/relations/relationsSlice";
import {
  deleteRessourcesGroup,
  removeGroupFromInitRessourceGroups,
} from "../ressourcesSlice";
import useAccessToken from "Features/auth/useAccessToken";

export default function useDeleteSceneRessourcesGroup() {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  //

  const deleteSceneRessourcesGroup = async (
    scene,
    ressourcesGroup,
    relations
  ) => {
    if (!scene) return;
    if (ressourcesGroup.fromScene) {
      const sceneRessources = scene.data?.ressources
        ? scene.data.ressources
        : [];
      let oldRessources = sceneRessources.filter(
        (s) => s?.group !== ressourcesGroup.name
      );
      if (!oldRessources) oldRessources = [];
      const typesToEdit = {};
      if (relations && typeof relations === "object") {
        const ressourcesIdsTodelete = new Set(
          sceneRessources
            .filter((s) => s?.group === ressourcesGroup.name)
            .map((r) => r.id)
        );
        const editedRelations = {...relations};
        const relationsKeys = Object.keys(relations);
        ressourcesIdsTodelete.forEach((rId) => {
          const relationsToDelete = relationsKeys.filter((k) =>
            k.endsWith(rId)
          );
          relationsToDelete.forEach((rel) => {
            const typeId = rel.slice(0, 21);
            if (!(typeId in typesToEdit)) typesToEdit[typeId] = new Set();
            typesToEdit[typeId].add(rId);
            delete editedRelations[rel];
          });
        });
        dispatch(setRelationsTypesRessources(editedRelations));
      }
      const newScene = {
        ...scene,
        data: {...scene?.data, ressources: [...oldRessources]},
      };
      dispatch(updateScene({scene: newScene}));
      dispatch(removeGroupFromInitRessourceGroups(ressourcesGroup));
      return {newScene, typesToEdit};
    } else if (accessToken && scene?.id) {
      await dispatch(deleteRessourcesGroup({accessToken, ressourcesGroup}));
    }
  };
  return deleteSceneRessourcesGroup;
}
