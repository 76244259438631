import {useSelector} from "react-redux";

export default function usePdfsByScene(scene) {
  const models = useSelector((s) => s.viewer3D.models);

  const pdfs = models.filter(
    (m) => m.type === "PDF" && m.sceneClientId === scene?.clientId
  );

  return pdfs;
}
