import {useMemo} from "react";
import {useSelector} from "react-redux";

import useSceneMeasurements from "./useSceneMeasurements";
import useSelectedMeasurementsModel from "./useSelectedMeasurementsModel";
import useMeasurementsModelsInScope from "./useMeasurementsModelsInScope";

import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useSceneModule from "Features/navigation/useSceneModule";
import {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import getItemsMapById from "Utils/getItemsMapById";

export default function useFilteredSceneMeasurements(
  scene,
  mode = "ONE_MODEL",
  modelFilterOnly = false, // = modelsInScope by default. can specify the scope with other "filters"
  applyFilters = true, // = scope + filters
  applyShortcuts = false,
  applyDatagridSelection = false,
  applyFilterByGroup = false
) {
  // mode = "ONE_MODEL"/"MULTI_MODELS"

  // data

  const sceneModule = useSceneModule();
  const selectedTypesGroup = useSelector(
    (s) => s.elementTypes.selectedElementTypesGroupProxy
  );

  const models = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.sceneClientId === scene?.clientId && m.enabled
  );
  // const modelIds = models.map((m) => m.id);

  const elementTypes = useElementTypesBySceneProxy(scene);
  const ressources = useRessourcesByScene(scene);
  const ressourcesById = getItemsMapById(ressources);

  const mm = useSelectedMeasurementsModel();
  const measurements = useSceneMeasurements(scene);

  const selectedRessourceGroup = useSelector(
    (s) => s.measurements.selectedRessourceGroup
  );

  const zones = useZonesByScene(scene);
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);

  // data - timestamp to refresh from edition

  const editedTimestamp = useSelector((s) => s.measurements.editedTimestamp);

  // data

  const showHelperModelIds = useSelector((s) => s.helpers3d.showHelperModelIds);

  // data - main scope

  const [_, measurementsModelsInScope] = useMeasurementsModelsInScope(scene);

  // data - scope

  const todoInScope = useSelector((s) => s.measurements.todoInScope);
  const doneInScope = useSelector((s) => s.measurements.doneInScope);

  const sectorsInScope = useSelector((s) => s.measurements.sectorsInScope);
  const roomsInScope = useSelector((s) => s.measurements.roomsInScope);
  const buildingInScope = useSelector((s) => s.buildings.buildingInScope);

  const filterByBuiltAt = useSelector((s) => s.measurements.filterByBuiltAt);
  const filterByBuiltAtMin = useSelector(
    (s) => s.measurements.filterByBuiltAtMin
  );
  const filterByBuiltAtMax = useSelector(
    (s) => s.measurements.filterByBuiltAtMax
  );
  const elementTypesInScope = useSelector(
    (s) => s.measurements.elementTypesInScope
  );
  const keywordsInScope = useSelector((s) => s.measurements.keywordsInScope);

  // data - filters

  const filterZoneId = useSelector((s) => s.measurements.filterZoneId);
  const filterSectorId = useSelector((s) => s.measurements.filterSectorId);
  const filterRoomId = useSelector((s) => s.measurements.filterRoomId);
  const filterMaterialId = useSelector((s) => s.measurements.filterMaterialId);
  const filterRessourceId = useSelector(
    (s) => s.measurements.filterRessourceId
  );
  const filterPhaseId = useSelector((s) => s.measurements.filterPhaseId);
  const filterP1 = useSelector((s) => s.measurements.filterP1);
  const filterP2 = useSelector((s) => s.measurements.filterP2);
  const filterP3 = useSelector((s) => s.measurements.filterP3);
  const filterGeoCat = useSelector((s) => s.measurements.filterGeoCat);
  const filterElementTypeId = useSelector(
    (s) => s.measurements.filterElementTypeId
  );
  const filterNoVoids = useSelector((s) => s.measurements.filterNoVoids);

  // data - datagrid selection

  const selectedTypesInDatagrid = useSelector(
    (s) => s.measurements.selectedTypesInDatagrid
  );
  const selectedRoomsInDatagrid = useSelector(
    (s) => s.measurements.selectedRoomsInDatagrid
  );
  const selectedMaterialsInDatagrid = useSelector(
    (s) => s.measurements.selectedMaterialsInDatagrid
  );
  const selectedRessourcesInDatagrid = useSelector(
    (s) => s.measurements.selectedRessourcesInDatagrid
  );

  // helpers - shortcuts

  let builtAtsShortcut = useSelector((s) => s.measurements.shortcutBuiltAts);
  if (!builtAtsShortcut) builtAtsShortcut = [];

  const elementTypesShortcut = useSelector(
    (s) => s.measurements.shortcutElementTypes
  );

  const elevationShortcut = useSelector((s) => s.sectors.shortcutElevationZ);

  const phaseShortcut = useSelector((s) => s.phases.selectedPhaseIds);

  const roomsShortcut = useSelector((s) => s.rooms.selectedRoomId);

  const versionsShortcut = useSelector((s) => s.versions.shortcutVersions);
  const showDeletedInVersion = useSelector(
    (s) => s.versions.showDeletedInVersion
  );
  // helpers - ressources functions (too bad no relations in viewer)

  const resFuncs = {};
  elementTypes.forEach((t) => {
    t.res?.forEach((r) => {
      resFuncs[`${t.id}-${r.resId}`] = r.func;
    });
  });

  // helpers - zones, rooms and sectors by ids

  const zonesById = {};
  for (const item of zones) {
    zonesById[item.id] = item;
  }
  const roomsById = {};
  for (const item of rooms) {
    roomsById[item.id] = item;
  }
  const sectorsById = {};
  for (const item of sectors) {
    sectorsById[item.id] = item;
  }

  // helpers - edit mode

  const isEditMode = mode === "ONE_MODEL";

  // helpers -  disable elements Types

  // const disabledElementTypes = elementTypes
  //   .filter((t) => t.disabled)
  //   .map((t) => t.id);

  // helpers - filters (scope) hash

  const helpers3dH = showHelperModelIds.join(";");
  const modelsH = measurementsModelsInScope.join(";");
  // const disabledH = disabledElementTypes.join("-");
  const disabledH = "";
  const sectorsH = sectorsInScope.map((id) => (id ? id : "null")).join(";");
  const roomsH = roomsInScope.map((id) => (id ? id : "null")).join(";");
  const buildingH = buildingInScope ? buildingInScope : "null";
  // const zonesH = zonesInScope.join(";");

  //let scopeHash = modelsH + disabledH + sectorsH + zonesH;
  let scopeHash =
    modelsH + disabledH + sectorsH + roomsH + buildingH + helpers3dH;

  const h1 = todoInScope ? "1" : "0";
  const h2 = doneInScope ? "1" : "0";
  const h3 = keywordsInScope.join(";");
  const h4 = elementTypesInScope.join(";");
  const h5 = filterByBuiltAtMax;
  const h6 = filterByBuiltAtMin;

  let scopeFiltersHash = h1 + h2 + h3 + h4 + h5 + h6;

  let h_1 = filterElementTypeId ? filterElementTypeId : "null";
  let h_2 = filterSectorId ? filterSectorId : "null";
  let h_3 = filterRoomId ? filterRoomId : "null";
  let h_4 = filterNoVoids ? "1" : "0";
  let h_5 = filterGeoCat ? filterGeoCat : "null";
  let h_6 = filterZoneId ? filterZoneId : "null";
  let h_7 = filterMaterialId ? filterMaterialId : "null";
  let h_8 = filterRessourceId ? filterRessourceId : "null";
  let h_9 = filterPhaseId ? filterPhaseId : "null";
  let h_10 = filterP1 ? filterP1 : "null";
  let h_11 = filterP2 ? filterP2 : "null";
  let h_12 = filterP3 ? filterP3 : "null";

  let filtersHash =
    h_1 + h_2 + h_3 + h_4 + h_5 + h_6 + h_7 + h_8 + h_9 + h_10 + h_11 + h_12;

  const ha = elementTypesShortcut ? elementTypesShortcut.join("-") : "";
  const hb = builtAtsShortcut.join("-");
  const hc = elevationShortcut ? elevationShortcut.join("-") : "";
  const hd = phaseShortcut ? phaseShortcut.join("-") : "";
  const he = roomsShortcut ? roomsShortcut : "";
  const hf = versionsShortcut ? versionsShortcut.join("-") : "";
  const hg = showDeletedInVersion.toString();

  let shortcutsH = ha + hb + hc + hd + he + hf + hg;

  let hA = selectedTypesInDatagrid.join(";");
  let hB = selectedRoomsInDatagrid.join(";");
  let hC = selectedRessourcesInDatagrid.join(";");
  let hD = selectedMaterialsInDatagrid.join(";");

  const datagridSelectionH = hA + hB + hC + hD;

  let hG1 = selectedRessourceGroup ? selectedRessourceGroup : "-?-";
  const groupsH = hG1;

  let hash = editedTimestamp + scopeHash;
  if (!modelFilterOnly) hash = hash + scopeFiltersHash;
  if (applyFilters) hash = hash + filtersHash;
  if (applyShortcuts) hash = hash + shortcutsH;
  if (applyDatagridSelection) hash = hash + datagridSelectionH;
  if (applyFilterByGroup) hash = hash + groupsH;

  const filteredMs = useMemo(() => {
    /*
     * Filters - scope
     */

    // // remove disabled elementTypes
    // let fm = measurements.filter(
    //   (m) =>
    //     modelIds.includes(m.measurementsModelId) &&
    //     !disabledElementTypes.includes(m.elementTypeId)
    // );

    let fm = measurements;

    if (sceneModule === "ELEMENT_TYPES" && selectedTypesGroup) {
      const scopedTypes = new Set(
        elementTypes
          .filter((t) =>
            t.groupId
              ? t.groupId === selectedTypesGroup.id
              : t.group === selectedTypesGroup.name
          )
          .map((t) => t.id)
      );
      fm = fm.filter((m) => scopedTypes.has(m.elementTypeId));
    }

    // models in scope
    if (mm?.id && isEditMode) {
      fm = fm.filter(
        (m) =>
          m.measurementsModelId === mm?.id ||
          showHelperModelIds.includes(m.measurementsModelId)
      );
    }

    if (!showDeletedInVersion) {
      // remove !isEditMode condition.
      // const mmS = new Set(measurementsModelsInScope);
      // fm = fm.filter((m) =>
      //   mmS.has(m.measurementsModelId)
      // );
      fm = fm.filter((m) => !m.isDeletedInV);
    }

    /*
     * Filters - scope - zones & sectors
     */

    if (!modelFilterOnly && sectorsInScope.length > 0) {
      const sS = new Set(sectorsInScope);
      if (sS.has(null)) {
        fm = fm.filter((m) => sS.has(m.sectorId) || !m.sectorId);
      } else {
        fm = fm.filter((m) => sS.has(m.sectorId));
      }
    }
    if (!modelFilterOnly && roomsInScope.length > 0) {
      const rS = new Set(roomsInScope);
      if (rS.has(null)) {
        fm = fm.filter((m) => rS.has(m.roomId) || !m.roomId);
      } else {
        fm = fm.filter((m) => rS.has(m.roomId));
      }
    }

    if (!modelFilterOnly) {
      // filter on keywords
      if (keywordsInScope.length > 0) {
        const modelsInScopeIds = models
          .filter((m) => {
            if (!m.keywords) {
              return false;
            } else {
              let inScope = false;
              keywordsInScope.forEach((kw) => {
                if (!inScope) {
                  if (m.keywords.includes(kw)) inScope = true;
                }
              });
              return inScope;
            }
          })
          .map((m) => m.id);

        fm = fm.filter((m) => modelsInScopeIds.includes(m.measurementsModelId));
      }

      // filter by built At
      if (filterByBuiltAt) {
        fm = fm.filter((m) => {
          if (m.builtAt) {
            const builtAtM = m.builtAt.split("T")[0];
            const builtAtF = filterByBuiltAt.split("T")[0];
            return builtAtM === builtAtF;
          } else return false;
        });
      }
      if (filterByBuiltAtMin && !todoInScope) {
        fm = fm.filter((m) => {
          if (m.builtAt) {
            const builtAtM = m.builtAt.split("T")[0];
            const builtAtF = filterByBuiltAtMin.split("T")[0];
            return builtAtM >= builtAtF;
          } else return false;
        });
      }
      if (filterByBuiltAtMax && !todoInScope) {
        fm = fm.filter((m) => {
          if (m.builtAt) {
            const builtAtM = m.builtAt.split("T")[0];
            const builtAtF = filterByBuiltAtMax.split("T")[0];
            return builtAtM <= builtAtF;
          } else return false;
        });
      }
      if (todoInScope) {
        fm = fm.filter((m) => !m.builtAt);
      }
      if (doneInScope) {
        fm = fm.filter((m) => m.builtAt);
      }

      // filter by element type
      if (elementTypesInScope.length > 0) {
        const etS = new Set(elementTypesInScope);
        fm = fm.filter((m) => etS.has(m.elementTypeId));
      }
    }

    /*
     * Filters
     */

    if (applyFilters && filterElementTypeId) {
      if (filterElementTypeId === "undefined") {
        fm = fm.filter((m) => !m.elementTypeId);
      } else {
        fm = fm.filter((m) => m.elementTypeId === filterElementTypeId);
      }
    }
    if (applyFilters && filterZoneId) {
      if (filterZoneId === "undefined") {
        fm = fm.filter((m) => !m.zoneId);
      } else {
        fm = fm.filter((m) => m.zoneId === filterZoneId);
      }
    }
    if (applyFilters && filterSectorId) {
      if (filterSectorId === "undefined") {
        fm = fm.filter((m) => !m.sectorId);
      } else {
        fm = fm.filter((m) => m.sectorId === filterSectorId);
      }
    }
    if (applyFilters && filterRoomId) {
      if (filterRoomId === "undefined") {
        fm = fm.filter((m) => !m.roomId);
      } else {
        fm = fm.filter((m) => m.roomId === filterRoomId);
      }
    }
    if (applyFilters && filterMaterialId) {
      if (filterMaterialId === "undefined") {
        fm = fm.filter((m) => !m.materialId);
      } else {
        fm = fm.filter((m) => m.materialId === filterMaterialId);
      }
    }
    if (applyFilters && filterRessourceId) {
      const ressource = ressourcesById[filterRessourceId];
      if (!ressource.isTitle) {
        if (filterRessourceId === "undefined") {
          fm = fm.filter((m) => !m.res || m.res.length === 0);
        } else {
          fm = fm.filter((m) =>
            m.res?.map((r) => r.resId).includes(filterRessourceId)
          );
        }
      }
    }
    if (applyFilters && filterPhaseId) {
      if (filterPhaseId === "undefined") {
        fm = fm.filter((m) => !m.phaseId);
      } else {
        fm = fm.filter((m) => m.phaseId === filterPhaseId);
      }
    }
    if (applyFilters && filterGeoCat) {
      if (filterGeoCat === "undefined") {
        fm = fm.filter((m) => !m.geoCat);
      } else {
        fm = fm.filter((m) => m.geoCat === filterGeoCat);
      }
    }
    if (applyFilters && filterP1) {
      fm = fm.filter((m) => m.p1 === filterP1);
    }
    if (applyFilters && filterP2) {
      fm = fm.filter((m) => m.p2 === filterP2);
    }
    if (applyFilters && filterP3) {
      fm = fm.filter((m) => m.p3 === filterP3);
    }
    /*
     * Shortcuts
     */

    if (applyShortcuts) {
      // filter shortcut builtAts
      if (builtAtsShortcut.length > 0) {
        fm = fm.filter((m) => {
          if (m.builtAt) {
            const builtAtM = m.builtAt.split("T")[0];
            const builtAtsS = builtAtsShortcut.map((at) =>
              at ? at.split("T")[0] : null
            );
            return builtAtsS.includes(builtAtM);
          } else return builtAtsShortcut.includes(null);
        });
      }

      // filter shortcut elementTypes
      if (elementTypesShortcut) {
        const etsS = new Set(elementTypesShortcut);
        fm = fm.filter((m) => etsS.has(m.elementTypeId));
      }
      // filter shortcut elevations
      if (elevationShortcut.length > 0) {
        const eS = new Set(elevationShortcut);
        fm = fm.filter((m) => eS.has(m.sectorId) || !m.sectorId);
        if (!eS.has("undefined")) fm = fm.filter((m) => m.sectorId);
        else if (
          elevationShortcut.length === 1 &&
          elevationShortcut[0] === "undefined"
        )
          fm = fm.filter((m) => !m.sectorId);
      }
      // filter shortcut elevations
      if (roomsShortcut) {
        fm = fm.filter((m) => m.roomId === roomsShortcut);
      }
      // filter shortcut phases
      if (phaseShortcut.length > 0) {
        const pS = new Set(phaseShortcut);
        fm = fm.filter((m) => pS.has(m.phaseId) || !m.phaseId);
        if (!pS.has("undefined")) fm = fm.filter((m) => m.phaseId);
        else if (phaseShortcut.length === 1 && phaseShortcut[0] === "undefined")
          fm = fm.filter((m) => !m.phaseId);
      }
      // filter shortcut versions
      if (versionsShortcut.length > 0) {
        const vS = new Set(versionsShortcut);
        fm = fm.filter((m) => vS.has(m.versionId) || !m.versionId);
        if (!vS.has("undefined")) fm = fm.filter((m) => m.versionId);
        else if (
          versionsShortcut.length === 1 &&
          versionsShortcut[0] === "undefined"
        )
          fm = fm.filter((m) => !m.versionId);
      }
    }

    /*
     * datagrid
     */
    if (applyDatagridSelection && selectedTypesInDatagrid.length > 0) {
      const stdS = new Set(selectedTypesInDatagrid);
      fm = fm.filter((m) => stdS.has(m.elementTypeId));
    }
    if (applyDatagridSelection && selectedRoomsInDatagrid.length > 0) {
      const srdS = new Set(selectedRoomsInDatagrid);
      fm = fm.filter((m) => srdS.has(m.roomId));
    }
    if (applyDatagridSelection && selectedMaterialsInDatagrid.length > 0) {
      const smdS = new Set(selectedMaterialsInDatagrid);
      fm = fm.filter((m) => smdS.has(m.materialId));
    }
    if (applyDatagridSelection && selectedRessourcesInDatagrid.length > 0) {
      const ressources = selectedRessourcesInDatagrid.map(
        (id) => ressourcesById[id]
      );
      const ressourcesIds = ressources
        .filter((r) => !r.isTitle)
        .map((r) => r.id);
      const sredS = new Set(ressourcesIds);
      if (sredS.size !== 0) {
        fm = fm.filter((m) => {
          let intersection = false;
          m.res?.forEach(({resId}) => {
            if (sredS.has(resId)) {
              intersection = applyFormulaFilter(
                m,
                resFuncs[`${m.elementTypeId}-${resId}`],
                zonesById[m.zoneId],
                roomsById[m.roomId],
                sectorsById[m.sectorId],
                filterNoVoids
              );
            }
          });
          return intersection;
        });
      }
    }

    /*
     * filter by group
     */
    if (applyFilterByGroup && selectedRessourceGroup) {
      const fRes = ressources.filter((r) => r.group === selectedRessourceGroup);
      const fResIds = new Set(fRes.map((r) => r.id));
      fm = fm.filter((m) => {
        let intersection = false;
        m.res?.forEach(({resId}) => {
          if (fResIds.has(resId)) {
            intersection = applyFormulaFilter(
              m,
              resFuncs[resId],
              zonesById[m.zoneId],
              roomsById[m.roomId],
              sectorsById[m.sectorId],
              filterNoVoids
            );
          }
        });
        return intersection;
      });
    }

    // /*
    //  * handle voids
    //  */

    // const voidIds = new Set(
    //   fm.reduce((ac, cur) => {
    //     if (Array.isArray(cur.voids)) {
    //       return [...ac, ...cur.voids];
    //     } else {
    //       return ac;
    //     }
    //   }, [])
    // );

    // const voidMeasurements = measurements.filter((m) => voidIds.has(m.id));

    // if (!filterNoVoids) {
    //   fm = [...fm, ...voidMeasurements];
    // }

    // be sure not to have duplicates with same id
    return [...new Map(fm.map((m) => [m.id, m])).values()];
  }, [
    mm?.id,
    hash,
    modelFilterOnly,
    applyFilters,
    applyShortcuts,
    applyDatagridSelection,
    selectedRessourceGroup,
    selectedTypesGroup,
    sceneModule,
    measurements.length,
  ]);

  /*
   * results
   */
  const fmIds = filteredMs.map((m) => m.id);
  const fmIdsHash = fmIds.join("-");

  return [filteredMs, fmIds, fmIdsHash, hash];
}
