import React from "react";

import {Box} from "@mui/material";

import CardMobileModules from "Features/mobileModules/components/CardMobileModules";
import CardAddMobileModules from "Features/mobileModules/components/CardAddMobileModules";
export default function SectionProgressListingMobileModules({listing, scene}) {
  // helpers

  let momos = listing?.metaData?.mobileModules;
  if (!momos) momos = [];

  const noModules = momos.length === 0;

  return (
    <Box sx={{width: 1}}>
      {noModules ? (
        <Box sx={{p: 2, width: 1}}>
          <CardAddMobileModules scene={scene} />
        </Box>
      ) : (
        <Box sx={{p: 1, width: 1}}>
          <CardMobileModules moduleCodes={momos} scene={scene} />
        </Box>
      )}
    </Box>
  );
}
