import {useDispatch} from "react-redux";

import {setOpenDialogCreatePackage} from "../packagesSlice";

import usePackagesByScene from "../usePackagesByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useUpdateRessourcesFromTypes from "../hooks/useUpdateRessourcesFromTypes";

import {Refresh, Add} from "@mui/icons-material";
import useSelectedPdfModel from "Features/pdf/useSelectedPdfModel";

export default function useActionsPackagesMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // data

  const selectedPdfModel = useSelectedPdfModel();
  const packages = usePackagesByScene(scene, {filterByScope: true});
  const elementTypes = useElementTypesBySceneProxy(scene, {
    filterByScope: true,
  });
  const zones = useZonesByScene(scene);
  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  const rooms = useRoomsByScene(scene);
  const sectors = useSectorsByScene(scene);
  const refreshPackages = useUpdateRessourcesFromTypes({
    caplaEditor,
    packages,
    elementTypes,
    ressources,
    zones,
    rooms,
    sectors,
  });

  // handlers

  function handleCreatePackage() {
    dispatch(setOpenDialogCreatePackage(true));
  }
  async function handleRefreshPackages() {
    console.log("refresh packages", packages);
    refreshPackages(packages.map((p) => p.id));
  }

  // actions map

  const actionsMap = {
    createPackage: {
      icon: <Add fontSize="small" />,
      name: "Calque",
      label: "Créer un nouveau calque",
      handler: handleCreatePackage,
      disabled: !selectedPdfModel,
    },
    refreshPackages: {
      icon: <Refresh fontSize="small" />,
      name: "Rafraichir",
      label: "Rafraichir les quantités",
      handler: handleRefreshPackages,
      disabled: !packages?.length > 0,
    },
    oneActionWithDialog: {
      icon: <Add fontSize="small" />,
      name: "Xxx",
      label: "Xxx",
    },
  };

  return actionsMap;
}
