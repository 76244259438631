import React from "react";

import {Box, Typography} from "@mui/material";
import {PowerOff as Unconnected} from "@mui/icons-material";

export default function CardSceneNameInDatagrid({
  name,
  connected,
  isRemote,
  darkMode,
}) {
  let color = isRemote ? "text.secondary" : "text.primary";
  if (darkMode) color = "common.white";
  return (
    <Box sx={{width: 1, display: "flex", alignItems: "center"}}>
      {!connected && (
        <Unconnected color="action" fontSize="small" sx={{mr: 1}} />
      )}
      <Typography variant="body2" noWrap color={color}>
        {name}
      </Typography>
    </Box>
  );
}
