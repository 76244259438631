import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Box, Button, alpha} from "@mui/material";
import {Launch} from "@mui/icons-material";

import useSelectedMeasurement from "../useSelectedMeasurement";
import {setSelectedMeasurementsModelId} from "../measurementsSlice";
import {setLastPackageId} from "Features/elementPackages/packagesSlice";
import {setJump} from "Features/pdf/pdfSlice";
import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
// import {setOpenSections} from "Features/ui/uiSlice";

export default function ActionsMeasurementMultiModelsInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // scene

  let sceneId = scene?.id;
  if (!sceneId) sceneId = scene?.clientId;

  // strings

  const seeS = "Voir le repérage";

  // data

  const selectedMeasurement = useSelectedMeasurement();
  // const os = useSelector((s) => s.ui.openSections);

  // helper - measurementsModel

  const measurementsModelId = selectedMeasurement?.measurementsModelId;

  // handlers

  function handleSeeClick() {
    navigate(`/bimboxes/${sceneId}/${measurementsModelId}/measurements`);
    //dispatch(setLastPackageId(measurementsModelId));
    dispatch(
      setJump({
        page: selectedMeasurement.drawingProps.pageNumber,
        measId: selectedMeasurement.id,
      })
    );
    dispatch(setSelectedPdfModelId(selectedMeasurement.pdfModelId));
    dispatch(setSelectedMeasurementsModelId(measurementsModelId));
    // caplaEditor?.measDataManager.setSelectedModelById(measurementsModelId);
    // dispatch(
    //   setOpenSections({
    //     outlet: !os.viewer3D,
    //     viewer3D: os.viewer3D,
    //     fixedViewersBox: true,
    //     callToAction: false,
    //   })
    // );
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      <Box sx={{flexGrow: 1}}>
        <Button
          startIcon={<Launch />}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleSeeClick}
        >
          {seeS}
        </Button>
      </Box>
    </Box>
  );
}
