import styleMap from "Styles/listStyleMap";

export default function getElementTypeStyleObject(elementType) {
  let styleType = "DEFAULT";
  if (
    elementType?.isTitle &&
    elementType?.rank === 1 &&
    elementType?.style === undefined
  )
    styleType = "TITLE1";
  if (
    elementType?.isTitle &&
    elementType?.rank === 2 &&
    elementType?.style === undefined
  )
    styleType = "TITLE2";
  if (elementType.style === "title1") styleType = "TITLE1";
  if (elementType.style === "title2") styleType = "TITLE2";
  if (elementType.style === "detail") styleType = "DETAIL";

  const styleObject = styleMap[styleType];

  return styleObject;
}
