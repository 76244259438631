import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Box, Typography, Paper} from "@mui/material";
// import {Edit} from "@mui/icons-material";

import DialogScale from "./DialogScale";

import useSelectedPdfModel from "../useSelectedPdfModel";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

export default function ButtonDialogPdfScale({scene, editorPdf}) {
  const dispatch = useDispatch();

  // string

  //const scaleStartString = "Scale  1 : ";
  const scaleStartString = "1 :";

  const scaleS = "Echelle";

  // data

  const model = useSelectedPdfModel();
  const zones = useZonesByScene(scene);
  const currentPage = useSelector((s) => s.viewer3D.pdfCurrentPage);

  // state

  const [_, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  // helpers

  const scale = model?.pdfScale ? model.pdfScale : "?";
  const items = zones.filter(
    (z) => z.pdfModelId === model?.id && z.pageNumber === currentPage
  );

  // effects

  useEffect(() => {
    if (typeof scale === "number" && editorPdf) {
      editorPdf.setToolbarGroupMeasureScale(scale);
    }
  }, [scale, editorPdf]);

  // handlers - over

  function handleMouseOver() {
    setShow(true);
  }
  function handleMouseLeave() {
    setShow(false);
  }

  // handlers - dialog

  function handleOpenDialog() {
    console.log("openDialog");
    setOpen(true);
  }
  function handleCloseDialog() {
    console.log("closeDialog");
    setOpen(false);
  }
  function handleScaleSave(scale) {
    const scaleFloat = parseFloat(scale);
    const updatedModel = {
      id: model.id,
      pdfScale: scaleFloat,
    };
    dispatch(updateModel({updatedModel}));
    editorPdf.annotationsManager.setScale(scaleFloat);
    setOpen(false);
  }

  return (
    <Paper
      sx={{
        bgcolor: "common.caplaBlack",
        color: "common.white",
        cursor: "pointer",
        p: 0.5,
        px: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpenDialog}
        sx={{display: "flex", alignItems: "center", height: "30px"}}
      >
        <Typography sx={{fontSize: 13, mr: 1}}>{scaleS}</Typography>
        <Typography variant="body2" noWrap>
          {`${scaleStartString} ${Math.round(parseFloat(scale))}`}
        </Typography>
      </Box>
      <DialogScale
        open={open}
        onClose={handleCloseDialog}
        onSave={handleScaleSave}
        scale={scale}
        zones={items}
      />
    </Paper>
  );
}
