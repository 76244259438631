import React, {useState} from "react";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import {Palette} from "@mui/icons-material";

import SectionColoringsByPackage from "Features/colorings/components/SectionColoringsByPackage";

export default function DatagridCellPackageColorings({
  coloringsCount,
  packageId,
}) {
  // strings

  const coloringsS = "Colorings";

  // helpers

  const countLabel = coloringsCount ? `x ${coloringsCount}` : 0;
  // state

  const [open, setOpen] = useState(false);

  // handlers

  function handleClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  return (
    <>
      <Typography
        variant="body2"
        sx={{cursor: "pointer"}}
        onClick={handleClick}
      >
        {countLabel}
      </Typography>

      <Dialog open={open} onClose={handleClose} maxWidth={false}>
        <DialogTitle>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Palette color="action" />
            <Typography sx={{fontWeight: "bold", ml: 2}}>
              {coloringsS}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{width: 500}}>
            <SectionColoringsByPackage packageId={packageId} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
