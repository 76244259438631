// 1.10.1 => 01.10.01

export default function getNumForSorting(num) {
  const numbers = num.split(".");
  const numbersForSorting = numbers.map((number) => {
    if (number.length === 1) {
      return "0" + number;
    } else {
      return number;
    }
  });
  return numbersForSorting.join(".");
}
