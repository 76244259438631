import {useSelector} from "react-redux";

import LayerDefaultDesktop from "./LayerDefaultDesktop";
import LayerDefaultMobile from "./LayerDefaultMobile";

export default function LayerDefault({caplaEditor, scene, viewer}) {
  const dOm = useSelector((s) => s.ui.desktopOrMobile);
  const isMobile = dOm === "MOBILE";

  if (!isMobile) {
    return (
      <LayerDefaultDesktop caplaEditor={caplaEditor} scene={scene} viewer={viewer} />
    );
  } else {
    return <LayerDefaultMobile caplaEditor={caplaEditor} scene={scene} viewer={viewer} />;
  }
}
