import React from "react";

import {ToggleButton, ToggleButtonGroup, Typography, Box} from "@mui/material";

export default function ToggleExtractMode({mode, onChange}) {
  // strings

  const typeS = "Eléments";
  const measS = "Repérages";

  // handler

  function handleChange(e, value) {
    if (value !== null) {
      onChange(value);
    }
  }

  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <ToggleButtonGroup
        size="small"
        value={mode}
        onChange={handleChange}
        exclusive
        sx={{textTransform: "none"}}
      >
        <ToggleButton value="ELEMENT_TYPES">
          <Typography variant="body2" sx={{textTransform: "none"}}>
            {typeS}
          </Typography>
        </ToggleButton>
        <ToggleButton value="MEASUREMENTS">
          <Typography variant="body2" sx={{textTransform: "none"}}>
            {measS}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
