export default function useDrawingShapeDefaultUnitMap() {
  return {
    POLYLINE: "M2",
    GABLE: "M2",
    POLYGON: "M2",
    SEGMENT: "UN",
    RECTANGLE: "UN",
    CIRCLE: "UN",
    SLOPE: "M2",
    STAIRS: "UN",
    BEAM: "M3",
    BANK: "M2",
    SLOPING_POLYGON: "M2",
    BRIDGE: "M2",
    BOWL: "M2",
  };
}
