import {useState} from "react";
import {useSelector} from "react-redux";

import {Box, TextField, Divider, Typography} from "@mui/material";

import MetaDataForm from "./MetaDataForm";

import useListingTypes from "Features/listingtypes/useListingtypes";
import SelectorListingType from "Features/listingtypes/components/SelectorListingType";

export default function FormCreateListingForm({onChange}) {
  // strings

  const nameString = "Nom";
  const descriptionString = "Description";

  // data

  const types = useListingTypes();
  const {email} = useSelector((state) => state.auth.data);

  // local state

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState(types[0]);
  const [metaData, setMetaData] = useState();

  const newDataset = {
    name,
    listingType: type?.id,
    description,
    metaData,
    userMemberships: [
      {
        user: {email},
        role: "admin",
      },
    ],
  };

  // handlers

  function handleNameChange(e) {
    const newName = e.target.value;
    setName(newName);
    onChange({...newDataset, name: newName});
  }

  function handleDescriptionChange(e) {
    const newDescription = e.target.value;
    setDescription(newDescription);
    onChange({...newDataset, description: newDescription});
  }

  function handleTypeChange(newType) {
    setType(newType);
    onChange({...newDataset, type: newType.id});
  }

  function handleMetaDataChange(newMetaData) {
    setMetaData(newMetaData);
  }

  return (
    <Box sx={{p: 1, display: "flex", flexDirection: "column"}}>
      <SelectorListingType type={type} onChange={handleTypeChange} />
      <TextField
        value={name}
        onChange={handleNameChange}
        label={<Typography variant="body2">{nameString}</Typography>}
        variant="outlined"
        size="small"
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        sx={{mb: 1, mt: 1}}
      />
      <TextField
        value={description}
        onChange={handleDescriptionChange}
        label={<Typography variant="body2">{descriptionString}</Typography>}
        variant="outlined"
        size="small"
        multiline
        InputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
        sx={{mb: 1}}
      />
      <Divider sx={{my: 2}} />
      <MetaDataForm
        metaData={metaData}
        onChange={handleMetaDataChange}
        type={type}
      />
    </Box>
  );
}
