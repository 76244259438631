import {Box} from "@mui/material";

import ContainerElementType from "Features/elementTypes/components/ContainerElementType";
import getItemsMapById from "Utils/getItemsMapById";

export default function SectionRelationDetailTypes({elementTypes, typeIds}) {
  const typeMap = getItemsMapById(elementTypes);

  return (
    <Box>
      {typeIds?.map((typeId) => {
        const elementType = typeMap[typeId];
        return (
          <ContainerElementType
            key={typeId}
            elementType={elementType}
            variant="compact"
          />
        );
      })}
    </Box>
  );
}
