// DEPRECATED

import {useDispatch, useSelector} from "react-redux";

import {ToggleButton, Tooltip} from "@mui/material";
import {BorderAll as Edge} from "@mui/icons-material";

import {setShowEdge} from "Features/viewer3D/viewer3DSlice";

export default function ToggleEdge({editor3d}) {
  const dispatch = useDispatch();
  // strings

  const tipString = "Edge";

  // data

  const show = useSelector((state) => state.viewer3D.showEdge);

  const handleChange = () => {
    if (show) {
      dispatch(setShowEdge(false));
      editor3d?.sceneEditor.hideEdge();
    } else {
      dispatch(setShowEdge(true));
      editor3d?.sceneEditor.showEdge();
    }
  };

  return (
    <Tooltip title={tipString}>
      <ToggleButton
        value="SHOW_GRID"
        selected={show}
        onChange={handleChange}
        aria-label="module"
        sx={{
          color: "common.white",
          width: 30,
          height: 30,
          border: "none",
          "&.Mui-selected": {
            bgcolor: "common.darkGrey",
            "&:hover": {
              bgcolor: "common.darkGrey",
            },
          },
        }}
      >
        <Edge fontSize="small" color="inherit" />
      </ToggleButton>
    </Tooltip>
  );
}
