import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ButtonDialogCreateVersion from "./ButtonDialogCreateVersion";
import ButtonDialogUpdateVersion from "./ButtonDialogUpdateVersion";
import ButtonDeleteVersion from "./ButtonDeleteVersion";

// import useSelectedVersion from "../hooks/useSelectedVersion";

export default function ToolbarVersionsMain({scene}) {
  // const selectedVersion = useSelectedVersion(scene);
  const selectedVersion = useSelector((s) => s.versions.selectedVersion);
  return (
    <Box sx={{p: 2, display: "flex"}}>
      <ButtonDialogCreateVersion scene={scene} />
      {selectedVersion && (
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ButtonDialogUpdateVersion scene={scene} version={selectedVersion} />
          <ButtonDeleteVersion scene={scene} version={selectedVersion} />
        </Box>
      )}
    </Box>
  );
}
