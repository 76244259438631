import {PointLight, SpotLight} from "three";
import {getRandomPower} from "../utils/miscUtils";

class FlashDarkMode {
  constructor() {
    this.light = new SpotLight(0xf52585, 2);
    this.light.position.set(0, 3, 1);
    this.light.penumbra = 1.1;

    this.flash = new PointLight(0x062d89, 30, 500, 1.7);
    this.flash.position.set(200, 300, 100);

    this.isEnabled = false;
  }

  loadTo(scene) {
    scene.add(this.flash);
    //scene.add(this.light);
  }
  enable() {
    this.light.layers.enable(1);
    this.flash.layers.enable(1);
    this.isEnabled = true;
  }
  disable() {
    this.light.layers.disable(1);
    this.flash.layers.disable(1);
    this.isEnabled = false;
  }
  randomUpdate() {
    this.flash.power = getRandomPower(this.flash.power);
    if (this.flash.power < 100)
      this.flash.position.set(
        Math.random() * 400,
        300 + Math.random() * 200,
        100
      );
  }
}
export default FlashDarkMode;
