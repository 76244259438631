import React from "react";

import {IconButton, Paper, Tooltip, Badge} from "@mui/material";

export default function ButtonTool({icon, onClick, label, selected, count}) {
  return (
    <Paper
      elevation={selected ? 12 : 1}
      sx={{
        height: "34px",
        width: "34px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip title={label} placement="left-end">
        {/* <Badge badgeContent={count > 0 ? 0 : 1} variant="dot" color="primary"> */}
        <IconButton
          onClick={onClick}
          size="small"
          sx={{
            borderRadius: "4px",
            color: selected ? "common.black" : "action",
          }}
        >
          {icon}
        </IconButton>
        {/* </Badge> */}
      </Tooltip>
    </Paper>
  );
}
