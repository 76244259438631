import useFilteredIssues from "./useFilteredIssues";

import {useSelector} from "react-redux";

import {format} from "date-fns";

export default function useIssuesForDataGrid(scene) {
  const [fi] = useFilteredIssues(scene);
  const listings = useSelector((s) => s.listings.data);

  // helpers - date

  function getDateTime(dateData) {
    if (dateData && dateData !== "-" && dateData !== "null") {
      const date = new Date(dateData);
      const formattedDate = format(date, "dd/MM/yyyy");
      return `${formattedDate}`;
    }
  }

  function getListingName(listingId) {
    const listing = listings.find((l) => l?.id === listingId);
    return listing?.name;
  }

  // helper - priority

  function getPriorityNumber(priority) {
    let number = 0;
    if (priority === "HIGH") number = 1;
    if (priority === "MEDIUM") number = 2;
    if (priority === "LOW") number = 3;
    return number;
  }

  // output

  let result = fi.map((issue) => ({
    id: issue.id,
    list: getListingName(issue.listing),
    title: issue.title,
    is_opened: issue.isOpened,
    priority: issue.priority,
    created_at: getDateTime(issue.createdAt),
    _createdAt: issue.createdAt,
    _listingId: issue.listing,
    _priorityNumber: getPriorityNumber(issue.priority),
  }));

  // sort

  result = result.sort(
    (a, b) => a._priorityNumber - b._priorityNumber || a.title.localeCompare(b.title)
  );

  return result;
}
