import React, {useState, useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import {setSelectedProgressRecordId} from "Features/progress/progressSlice";

import {
  Box,
  MenuItem,
  Typography,
  Menu,
  IconButton,
  ListItemIcon,
  Tooltip,
} from "@mui/material";

import {ArrowDropDown as Down} from "@mui/icons-material";
import useProgressRecordsByScene from "Features/progress/useProgressRecordsByScene";
import useSelectedProgressRecord from "../useSelectedProgressRecord";

import formatDate from "../formatDate";

export default function SelectorProgressRecordInToolbar({scene}) {
  const dispatch = useDispatch();
  // data

  let progressRecords = useProgressRecordsByScene(scene);
  progressRecords = progressRecords.sort((a, b) =>
    a.endAt.localeCompare(b.endAt)
  );

  const selectedProgressRecordId = useSelector(
    (s) => s.progress.selectedProgressRecordId
  );

  const progressRecord = useSelectedProgressRecord(scene);

  // state

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // helpers - tooltip

  const tooltipTitle = progressRecord?.name
    ? `${progressRecord.name} • ${formatDate(
        progressRecord.startAt
      )} - ${formatDate(progressRecord.endAt)}`
    : "-";

  // handlers - menu

  function handleMoreClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }
  // handlers - model item

  function handleProgressRecordClick(progressRecord) {
    dispatch(setSelectedProgressRecordId(progressRecord?.id));
    setAnchorEl(null);
  }

  // effects

  // useEffect(() => {
  //   if (!selectedProgressRecordId && progressRecords?.length > 0) {
  //     dispatch(setSelectedProgressRecordId(progressRecords[0].id));
  //   }
  // }, [progressRecords?.length, selectedProgressRecordId]);

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Box
          sx={{
            width: 100,
            display: "flex",
            justifyContent: "space-between",
            color: "common.white",
            alignItems: "center",
            borderRadius: "4px",
            //p: 0.5,
            mr: 1,
          }}
        >
          <Typography
            noWrap
            sx={{fontSize: 13, ml: 0.5, fontWeight: "bold"}}
            color="inherit"
          >
            {progressRecord?.name}
          </Typography>
          <IconButton size="small" onClick={handleMoreClick} color="inherit">
            <Down color="inherit" fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>

      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={open}>
        {progressRecords.map((progressRecord) => (
          <MenuItem
            key={progressRecord.id}
            onClick={() => handleProgressRecordClick(progressRecord)}
          >
            <Typography variant="body2">{progressRecord.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
