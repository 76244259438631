import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

// import {format} from "date-fns";

import {setSelectedSectorId} from "../sectorsSlice";
// import useSelectedSector from "../hooks/useSelectedSector";
import useSectorsByScene from "../hooks/useSectorsByScene";
import sectorsForDataGrid from "../utils/sectorsForDataGrid";

// import useTranslation from "Features/translations/useTranslation";

export default function DataGridSectors({scene}) {
  // const {i18n} = useTranslation();
  // const isFr = i18n.language === "fr";
  const dispatch = useDispatch();

  // strings

  // const idS = "ID";
  // const numS = "Num";
  const codeS = "Code";
  const nameS = "Name";
  const zS = "Z";

  // data

  const sectors = useSectorsByScene(scene);

  const selectedSectorId = useSelector((s) => s.sectors.selectedSectorId);

  const selectionModel = selectedSectorId ? [selectedSectorId] : [];

  // grid - rows

  const rows = sectorsForDataGrid(sectors);

  // grid - columns

  const columns = [
    //{field: "id", headerName: idString, flex: 0.5},
    // {
    //   field: "num",
    //   headerName: numS,
    //   flex: 0.2,
    // },
    {
      field: "code",
      headerName: codeS,
      width: 100,
    },
    {
      field: "name",
      headerName: nameS,
      flex: 1,
    },
    {
      field: "z",
      headerName: zS,
      width: 100,
    },
  ];

  // handlers

  function handleSelectionChange(selection) {
    const selectionId = selection[0];
    // state
    dispatch(setSelectedSectorId(selectionId));
  }

  return (
    <Box sx={{height: 1, width: 1}}>
      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectionModel}
      />
    </Box>
  );
}
