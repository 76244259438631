import {Box, TextField, Typography} from "@mui/material";

export default function FieldTempZoneHeight({height, onChange}) {
  const heightString = "Z (m)";

  // handlers

  function handleHeightChange(e) {
    onChange(e.target.value);
  }
  return (
    <Box sx={{width: 1}}>
      <TextField
        value={height}
        fullWidth
        onChange={handleHeightChange}
        label={<Typography variant="body2">{heightString}</Typography>}
        size="small"
        InputProps={{
          sx: {
            fontSize: (theme) => theme.typography.body2.fontSize,
          },
        }}
      />
    </Box>
  );
}
