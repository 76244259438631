import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import {
  ExpandMore as More,
  ExpandLess as Less,
  FilterAlt as Filter,
} from "@mui/icons-material";

import SelectorFilterSector from "./SelectorFilterSector";
import SelectorFilterRoom from "./SelectorFilterRoom";
import SelectorFilterElementType from "./SelectorFilterElementType";
import SelectorFilterVoids from "./SelectorFilterVoids";
import SelectorFilterZone from "./SelectorFilterZone";
import SelectorFilterMaterial from "./SelectorFilterMaterial";
import SelectorFilterRessource from "./SelectorFilterRessource";
import SelectorFilterPhase from "./SelectorFilterPhase";
import SelectorFilterP1 from "./SelectorFilterP1";
import SelectorFilterP2 from "./SelectorFilterP2";
import SelectorFilterP3 from "./SelectorFilterP3";

import {setNoQuantities} from "Features/ressources/ressourcesSlice";

export default function SectionMeasurementsFilters({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const filtersS = "Filtres";
  const noQuantitiesS = "Articles sans quantités";

  // data

  const noQuantities = useSelector((s) => s.ressources.noQuantities);

  // state

  const [open, setOpen] = useState(false);

  // hanlders

  function handleOpen(open) {
    //onOpen(open);
    setOpen(open);
  }

  return (
    <Box>
      <Box sx={{display: "flex", alignItems: "center", px: 2, pb: 1}}>
        <Filter color="action" fontSize="small" sx={{mr: 1}} />
        <Typography sx={{fontSize: 13, color: "text.secondary", mr: 1}}>
          {filtersS}
        </Typography>
        <IconButton size="small" onClick={() => handleOpen(!open)}>
          {open ? <Less fontSize="small" /> : <More fontSize="small" />}{" "}
        </IconButton>
      </Box>
      {open && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1, mb: 0.5},
            px: 2,
          }}
        >
          <SelectorFilterZone scene={scene} />
          <SelectorFilterElementType scene={scene} />
          <SelectorFilterRessource scene={scene} />
          <SelectorFilterSector scene={scene} />
          <SelectorFilterRoom scene={scene} />
          <SelectorFilterMaterial scene={scene} />
          <SelectorFilterPhase scene={scene} />
          <SelectorFilterVoids caplaEditor={caplaEditor} />
          <SelectorFilterP1 scene={scene} />
          <SelectorFilterP2 scene={scene} />
          <SelectorFilterP3 scene={scene} />
        </Box>
      )}
      {open && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            px: 3,
            mt: 1,
            pb: 2,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={noQuantities}
                onChange={() => {
                  dispatch(setNoQuantities(!noQuantities));
                }}
              />
            }
            label={<Typography variant="body2">{noQuantitiesS}</Typography>}
          />
        </Box>
      )}
    </Box>
  );
}
