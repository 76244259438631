import {Box, Paper, Typography} from "@mui/material";

import useSelectedArticlesGroup from "../hooks/useSelectedArticlesGroup";

import BlockEditableArticlesGroupName from "./BlockEditableArticlesGroupName";

export default function HeaderInSelectionPanelArticlesGroup({scene}) {
  // data

  const group = useSelectedArticlesGroup();

  return (
    <Box sx={{p: 1, width: 1, display: "flex"}}>
      <Paper
        sx={{
          p: 1,
          heigth: "40px",
          maxHeight: "40px",
          width: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        elevation={6}
      >
        <Box sx={{minWidth: 0}}>
          <BlockEditableArticlesGroupName
            articlesGroup={group}
            canEdit={true}
            centered={true}
          />
        </Box>
      </Paper>
    </Box>
  );
}
