export function blockIsEmpty(block) {
  if (block.type === "text") {
    return !block.data?.text > 0;
  }
  if (block.type === "action") {
    const emptyDescription = block.data?.description?.length === 0;
    const noData = !Boolean(block.data?.description);

    return emptyDescription || noData;
  }
  if (block.type === "image") {
    return !Boolean(block.data?.url);
  }
  if (block.type === "photo") {
    return !Boolean(block.data?.url);
  }
}
