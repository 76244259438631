import {useDispatch} from "react-redux";

import {Button} from "@mui/material";
import {ArrowBackIos as Back} from "@mui/icons-material";

import {setSelectedPdfModelId} from "Features/viewer3D/viewer3DSlice";
import {setSelectedInputId} from "Features/inputs/inputsSlice";

import { setSelectedListItemId } from "Features/leftPanel/leftPanelSlice";

export default function ButtonBackPdfInSelectionPanel() {
  const dispatch = useDispatch();

  // string

  const newS = "New";

  // handlers

  function handleClick() {
    dispatch(setSelectedPdfModelId(null));
    dispatch(setSelectedInputId(null));
    dispatch(setSelectedListItemId(null));
  }

  return (
    <Button startIcon={<Back />} size="small" onClick={handleClick}>
      {newS}
    </Button>
  );
}
