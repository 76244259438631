import {useDispatch} from "react-redux";
import {openScope} from "../overviewerSlice";

import {Box, Button} from "@mui/material";

export default function ButtonOpenScope({scope, canOpen}) {
  const dispatch = useDispatch();

  // label

  const label = "Ouvrir la bimbox";

  // handler

  function handleClick() {
    console.log("scopeId", scope.id);
    dispatch(openScope(scope?.id));
  }

  return (
    <Box sx={{bgcolor: "primary.main", borderRadius: "4px"}}>
      <Button color="inherit" onClick={handleClick} disabled={!canOpen}>
        {label}
      </Button>
    </Box>
  );
}
