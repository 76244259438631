import articlesSlice from "Features/articles/articlesSlice";
import articleTypesMapToArray from "Features/articles/utils/articleTypesMapToArray";
import getArticlesElementTypesIds from "Features/articles/utils/getArticlesElementTypesIds";
import getItemsMapById from "Utils/getItemsMapById";

import {testMeasurementOnFuncFilters} from "Features/articles/utils/testMeasurementOnFuncFilters";
import getVoidIdMeasurementMap from "./getVoidIdMeasurementMap";

function hasNonEmptyIntersection(arr1, arr2) {
  try {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
    let test = false;
    let i = 0;
    while (!test && i < arr1.length) {
      test = arr2.includes(arr1[i]);
      i += 1;
    }
    return test;
  } catch (e) {
    console.log("error computing intersection", arr1, arr2);
  }
}

export default function getFilteredMeasurementsByEntities(
  measurements = [],
  filterIdsByEntity = {},
  entitiesMap // entitiesMap = {elementType:[elementTypes,...]}
) {
  // filterEntities = ["elementTypeGroup","elementType","ressource","ressourcesGroup","sector",...]
  const filterEntities = Object.keys(filterIdsByEntity);

  //
  const entitiesIdBase = ["elementType", "sector", "zone"];
  // const entitiesNameBase = ["p1"];

  // entitiesById

  const elementTypesById = getItemsMapById(entitiesMap["elementType"]);
  const ressourcesById = getItemsMapById(entitiesMap["ressource"]);
  const articlesById = getItemsMapById(entitiesMap["article"]);
  const sectorsById = getItemsMapById(entitiesMap["sector"]);

  // voidIds

  const voidIdMeasurementMap = getVoidIdMeasurementMap(measurements);

  const filteredMeas = measurements.filter((meas) => {
    let shouldIncludeMeas = true;
    const measElementType = elementTypesById[meas.elementTypeId];
    //
    let index = 0;
    while (shouldIncludeMeas && index < filterEntities.length) {
      const filterEntity = filterEntities[index];
      let filterIds = filterIdsByEntity[filterEntity];
      // disable filter when type is a title. // the line that replace empty array with null is problematic
      // if (filterEntity === "elementType") {
      //   filterIds = filterIds?.filter((id) => !elementTypesById[id]?.isTitle);
      //   //if (filterIds?.length === 0) filterIds = null;
      // }
      // generic
      if (Array.isArray(filterIds) && entitiesIdBase.includes(filterEntity)) {
        shouldIncludeMeas = filterIds.includes(meas[filterEntity + "Id"]);
      } else if (filterEntity === "elementTypeGroup") {
        // elementTypeGroup
        //const measTypeGroup = elementTypesById[meas.elementTypeId];
        const measType = elementTypesById[meas.elementTypeId];
        shouldIncludeMeas = filterIds.includes(measType?.groupId);
      } else if (filterEntity === "ressource") {
        const ressources = filterIds.map((id) => ressourcesById[id]);
        const ressourcesIds = ressources
          .filter((r) => !r?.isTitle)
          .map((r) => r?.id); // to avoid filter when click on a title.
        if (ressourcesIds.length > 0) {
          // filter only if selected ressources are not empty
          const measType = elementTypesById[meas.elementTypeId];
          if (!measType?.res || !filterIds) {
            shouldIncludeMeas = false;
          } else {
            const measResIds = measType.res.map(({resId}) => resId);
            shouldIncludeMeas = hasNonEmptyIntersection(measResIds, filterIds);
          }
        }
      } else if (filterEntity === "article") {
        const articles = filterIds.map((id) => articlesById[id]);
        const sector = sectorsById[meas.sectorId];

        // test on filtersStr
        shouldIncludeMeas = false;
        articles.forEach((article) => {
          //
          const getVoids = article?.code?.startsWith("VIDE"); //

          const typesArray = articleTypesMapToArray(article?.types);
          typesArray.forEach(({groupId, typeId, filtersStr}) => {
            if (typeId === meas.elementTypeId || (!groupId && !typeId)) {
              // console.log(
              //   "testMeasFilters",
              //   meas.drawingShape,
              //   filtersStr,
              //   sector?.name

              // );
              if (getVoids && measElementType.drawingShape !== "SEGMENT") {
                shouldIncludeMeas = false;
              } else {
                shouldIncludeMeas = testMeasurementOnFuncFilters({
                  measurement: meas,
                  filtersStr,
                  sector,
                });
              }
            }

            if (getVoids) {
              const hostMeas = voidIdMeasurementMap[meas.id];
              if (hostMeas && hostMeas.elementTypeId === typeId) {
                shouldIncludeMeas = testMeasurementOnFuncFilters({
                  measurement: hostMeas,
                  filtersStr,
                  sector,
                });
              }
            }
          });
        });

        //
      }
      index += 1;
    }
    return shouldIncludeMeas;
  });

  return filteredMeas;
}
