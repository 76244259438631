import {nanoid} from "@reduxjs/toolkit";

export default function excelRessourcesToRessources(ressources) {
  const stringStyleMap = {
    titre_1: "title1",
    titre_2: "title2",
    détail: "detail",
  };
  return ressources.map((ressource) => {
    let num = ressource.num;
    const id = ressource.id ? ressource.id : nanoid();
    if (typeof num === "number") num = num.toString();
    if (!num) num = "0";
    const isTitle = ressource.isTitle === "x";
    const toDelete = ressource.toDelete === "x";
    const hideNum = ressource.hideNum === "x";
    const stateRessource = {...ressource, id, num, isTitle, toDelete, hideNum};
    delete stateRessource.style;
    if (ressource.style)
      stateRessource.style = stringStyleMap[ressource.style];
    return stateRessource;
  });
}
