import articleTypesMapToArray from "./articleTypesMapToArray";
import mergeTwoMeasNomenclatures from "Features/articles/utils/mergeTwoMeasNomenclatures";

import getMeasNomenclatureFromMeasurements from "Features/elementTypes/utils/getMeasNomenclatureFromMeasurements";
import templateNomenclatureMap from "Features/elementTypes/data/templateNomenclatureMap";

export default function getMeasNomenclatureFromMeasurementsAndArticleTypes(
  measurements,
  articleTypes,
  template
) {
  //
  if (!template) return [];
  //
  const templateObject = templateNomenclatureMap[template];
  const fields = templateObject.fields;
  const fieldLabel = templateObject.fieldLabel;

  //
  const articleTypesArray = articleTypesMapToArray(articleTypes);

  let mergedNomenclature = [];

  articleTypesArray.forEach((articleType) => {
    const relatedMeasurements = measurements.filter(
      (meas) => meas.elementTypeId === articleType.typeId
    );
    const relatedNomenclature = getMeasNomenclatureFromMeasurements(
      relatedMeasurements,
      template
    );
    mergedNomenclature = mergeTwoMeasNomenclatures(
      mergedNomenclature,
      relatedNomenclature,
      fields,
      fieldLabel
    );
  });

  return mergedNomenclature;
  //
}
