import copyTypes from "./copyTypes";

export default function getTempArticleWithCoreTypesAsTypes(article, options) {
  const removeTypes = options?.removeTypes;

  const newArticle = {
    ...article,
  };

  // remove types
  if (removeTypes) {
  }

  let types;
  let coreTypes = newArticle.coreTypes;
  if (
    coreTypes &&
    typeof coreTypes === "object" &&
    Object.entries(coreTypes).length > 0
  ) {
    types = copyTypes(coreTypes);
  } else {
    types = {};
  }
  newArticle.types = types;
  return newArticle;
}
