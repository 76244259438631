import React from "react";

import {useSelector, useDispatch} from "react-redux";

import {updateModel} from "Features/viewer3D/viewer3DSlice";
import {setShortcutMobileZoneId} from "Features/measurements/measurementsSlice";

import useSceneModuleNameMap from "Features/navigation/useSceneModuleNameMap";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";

import {
  Dialog,
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import {Close} from "@mui/icons-material";

export default function DialogMobileSelectZones({
  scene,
  editor,
  open,
  onClose,
}) {
  const dispatch = useDispatch();

  // data

  const zones = useZonesByScene(scene);
  const nameMap = useSceneModuleNameMap();
  const imageModels = useSelector((s) => s.viewer3D.models).filter(
    (m) => m.type === "IMAGE"
  );
  const viewMode = useSelector((s) => s.viewer3D.viewMode);

  // helpers

  const title = nameMap["ZONES"];

  // helpers - items

  const items = [];
  const sectorCodes = [];
  zones
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => b.zForSorting - a.zForSorting)
    .forEach((zone) => {
      const isHorizontal = Math.abs(zone.rotation.x + Math.PI / 2) < 0.01;
      if (isHorizontal) {
        if (!sectorCodes.includes(zone.sectorCode)) {
          items.push({itemType: "SECTOR", name: zone.sectorCode});
          sectorCodes.push(zone.sectorCode);
        }
        items.push({itemType: "ZONE", ...zone});
      }
    });

  // handlers

  function handleZoneClick(zoneId) {
    dispatch(setShortcutMobileZoneId(zoneId));
    // zone
    const zone = zones.find((z) => z.id === zoneId);
    const imageModel = imageModels.find((m) => m.id === zone.imageModelId);

    // show - hide models
    const entity = editor.getEntity(imageModel.id);
    entity?.show();
    const updatedModel = {...imageModel, hidden: false};
    dispatch(updateModel({updatedModel, sync: false}));

    // camera
    if (!zone?.imageModelId) return;
    const modelIds = [zone.imageModelId];
    if (viewMode === "3D") {
      editor.sceneEditor.focusImageModel(imageModel);
    } else {
      editor.sceneEditor.fitCam1To({modelIds});
    }

    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{p: 1, width: 1, pb: 2}}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            elevation={12}
          >
            <Typography>{title}</Typography>
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Paper>
        </Box>

        <Box sx={{flexGrow: 1, overflow: "auto", width: 1}}>
          <List>
            {items.map((item) => {
              if (item.itemType === "SECTOR") {
                return (
                  <ListItem sx={{bgcolor: "background.default"}}>
                    <ListItemText>{item.name}</ListItemText>
                  </ListItem>
                );
              } else {
                return (
                  <ListItem divider>
                    <ListItemButton onClick={() => handleZoneClick(item.id)}>
                      <Typography sx={{fontWeight: "bold"}}>
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                );
              }
            })}
          </List>
        </Box>
      </Box>
    </Dialog>
  );
}
