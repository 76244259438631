import {Menu, MenuItem, Divider, Typography} from "@mui/material";

export default function MenuGenericActions({
  open,
  onClose,
  anchorEl,
  actions,
  onClick,
}) {
  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      {actions.map((action, idx) => {
        if (!action?.hidden) {
          if (action?.key === "divider") return <Divider key={idx} />;
          return (
            <MenuItem
              disabled={action.disabled}
              key={action?.key}
              onClick={() => onClick(action?.key)}
            >
              <Typography variant="body2">{action?.label}</Typography>
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
}
