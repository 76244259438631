// import {useDispatch} from "react-redux";

// import {updateMeasurements} from "../measurementsSlice";
import {Box, Typography, IconButton, Tooltip} from "@mui/material";

import {Close} from "@mui/icons-material";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";

export default function SectionVoidHosts({
  measurement,
  measurements,
  scene,
  locked,
  caplaEditor,
}) {
  const title = "Eléments hôtes";
  // const dispatch = useDispatch();

  // data

  const elementTypes = useElementTypesBySceneProxy(scene);
  const sectors = useSectorsByScene(scene);

  //const models = useSelector((s) => s.viewer3D.models);

  const hosts = [];

  measurements.forEach((m) => {
    if (Array.isArray(m.voids) && m.voids.includes(measurement.id)) {
      hosts.push(m);
    }
  });

  function handleClick(host) {
    // remove void from host
    console.log("HOST", host);
    const newVoids = host.voids?.filter((vid) => vid !== measurement.id);
    const newMeasurement = {...host, voids: newVoids};
    // dispatch(updateMeasurements([newMeasurement]));
    caplaEditor?.measDataManager.updateMeasurements([newMeasurement]);
    caplaEditor?.editor3d.sceneEditor.updateMeasurement3D(newMeasurement);
  }

  return (
    <Box sx={{p: 1}}>
      <Typography variant="body2" sx={{mb: 1}}>
        {title}
      </Typography>
      {hosts.map((host) => {
        let elementType = elementTypes.find(
          (type) => type.id === host.elementTypeId
        );
        let elementTypeName = elementType?.name;
        if (!elementType?.name) elementTypeName = "Type -?-";

        let sector = sectors.find((sector) => sector.id === host.sectorId);

        let sectorName = sector?.name;
        if (!sectorName) sectorName = "Secteur -?-";
        return (
          <Box
            key={host.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="body2" noWrap>
                {elementTypeName}
              </Typography>
              <Typography noWrap sx={{fontSize: 13, fontStyle: "italic"}}>
                {sectorName}
              </Typography>
            </Box>
            {!locked && (
              <Tooltip title={"Retirer le vide de l'hôte"}>
                <IconButton onClick={() => handleClick(host)}>
                  <Close />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
