import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchWorksitesService,
  postWorksiteService,
  fetchWorksiteService,
  updateWorksiteService,
  createSpaceService,
  updateSpaceService,
} from "./services";

export const postWorksite = createAsyncThunk(
  "worksites/postWorksite",
  postWorksiteService
);

export const fetchWorksites = createAsyncThunk(
  "worksites/fetchWorksites",
  fetchWorksitesService
);

export const fetchWorksite = createAsyncThunk(
  "worksites/fetchWorksite",
  fetchWorksiteService
);

export const updateWorksite = createAsyncThunk(
  "worksites/updateWorksite",
  updateWorksiteService
);

// spaces

export const createSpace = createAsyncThunk(
  "worksites/createSpace",
  createSpaceService
);

export const updateSpace = createAsyncThunk(
  "worksites/updateSpace",
  updateSpaceService
);

const worksitesSlice = createSlice({
  name: "worksites",
  initialState: {items: [], status: "idle"},

  reducers: {
    detachScene: (state, action) => {
      const {worksiteId, sceneId} = action.payload;
      const newItems = state.items.map((worksite) => {
        if (worksite.id !== worksiteId) {
          return worksite;
        } else {
          const newScenes = worksite.scenes.filter((s) => s.id !== sceneId);
          return {...worksite, scenes: newScenes};
        }
      });
      state.items = newItems;
    },
  },
  extraReducers: {
    [postWorksite.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
    [fetchWorksites.fulfilled]: (state, action) => {
      const newWorksites = action.payload;
      const newWorksitesIds = newWorksites.map((w) => w.id);
      const oldWorksitesIds = state.items.map((i) => i.id);
      // existing worksites
      const oldWorksites = state.items.map((worksite) => {
        if (newWorksitesIds.includes(worksite.id)) {
          const newWorksite = newWorksites.find((w) => w.id === worksite.id);
          return {...worksite, ...newWorksite};
        } else {
          return worksite;
        }
      });
      // new worksites
      const addedWorksites = newWorksites.filter(
        (w) => !oldWorksitesIds.includes(w.id)
      );
      state.items = [...oldWorksites, ...addedWorksites];
      state.status = "loaded";
    },
    [fetchWorksite.fulfilled]: (state, action) => {
      const worksite = action.payload;
      state.items = state.items.filter((i) => i.id !== worksite.id);
      state.items = [...state.items, worksite];
    },
    [fetchWorksites.pending]: (state, action) => {
      state.status = "pending";
    },
    [updateWorksite.fulfilled]: (state, action) => {
      const worksite = action.payload;
      const newItems = state.items.map((item) => {
        if (item.id === worksite.id) {
          return worksite;
        } else {
          return item;
        }
      });
      state.items = newItems;
    },
    [createSpace.fulfilled]: (state, action) => {
      const {space, worksiteId} = action.payload;
      const newItems = state.items.map((worksite) => {
        if (worksite.id === worksiteId) {
          const newWorksite = {
            ...worksite,
            spaces: [...worksite.spaces, space],
          };
          return newWorksite;
        } else {
          return worksite;
        }
      });
      state.items = newItems;
    },
    [updateSpace.fulfilled]: (state, action) => {
      const {space, worksiteId} = action.payload;
      const newItems = state.items.map((worksite) => {
        if (worksite.id === worksiteId) {
          const oldSpaces = worksite.spaces.filter((s) => s.id !== space.id);
          const newWorksite = {
            ...worksite,
            spaces: [...oldSpaces, space],
          };
          return newWorksite;
        } else {
          return worksite;
        }
      });
      state.items = newItems;
    },
  },
});

export const {detachScene} = worksitesSlice.actions;

export default worksitesSlice.reducer;
