import React, {useState} from "react";

import {Box, Typography, Chip} from "@mui/material";
import {Videocam as Camera} from "@mui/icons-material";

import IsOpenedIcon from "../../IsOpenedIcon";
import {getViewpoints} from "Features/notes/notesUtils";

import {formatDistance} from "date-fns";
import {fr} from "date-fns/locale";

export default function NotePreview({note, onViewpointClick}) {
  const isOpened = note?.isOpened;
  const title = note?.title;
  const createdAt = note?.createdAt;
  const createdBy = note?.createdBy;

  const createdFor = formatDistance(new Date(createdAt), new Date(), {
    locale: fr,
    addSuffix: true,
  });

  const viewpoints = getViewpoints(note);

  const [viewpointsCounter, setViewpointsCounter] = useState(0);

  // handlers

  function handleViewpointsClick(e) {
    e.stopPropagation();
    e.preventDefault();
    onViewpointClick(viewpoints[viewpointsCounter]);
    const count = (viewpointsCounter + 1) % viewpoints.length;
    setViewpointsCounter(count);
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        py: 1,
        px: 2,
      }}
    >
      <IsOpenedIcon isOpened={isOpened} />
      <Box sx={{ml: 2, flexGrow: 1, minWidth: 0}}>
        <Typography variant="body2">
          <b>{title}</b>
        </Typography>
        <Box sx={{mt: 0.5}}>
          {viewpoints.length > 0 && (
            <Chip
              size="small"
              onClick={handleViewpointsClick}
              label={
                <Typography variant="body2">{viewpoints.length}</Typography>
              }
              icon={<Camera />}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "text.secondary",
            width: 1,
            mt: 0.5,
          }}
        >
          <Typography
            color="inherit"
            noWrap
            sx={{minWidth: 0, flexGrow: 0, flexShrink: 1, pr: 1, fontSize: 12}}
          >
            {createdBy}
          </Typography>
          <Box
            sx={{
              minWidth: 0,
              flexShrink: 0,
            }}
          >
            <Typography
              color="inherit"
              sx={{
                minWidth: "max-content",
                fontSize: 12,
              }}
            >
              {createdFor}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
