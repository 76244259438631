function cleanNumber(number) {
  let _number;
  if (typeof number === "number" && !isNaN(number)) {
    _number = number;
  } else if (typeof number === "string") {
    _number = Number(number);
    if (isNaN(_number)) _number = null;
  } else if (isNaN(number)) {
    _number = null;
  }
  return _number;
}

export default function cleanElementType(meas) {
  let zInf, zSup, offset, dim1, dim2, height;

  const keyMap = {
    zInf,
    zSup,
    offset,
    dim1,
    dim2,
    height,
  };

  Object.entries(keyMap).forEach(([key, value]) => {
    if (meas[key]) keyMap[key] = cleanNumber(meas[key]);
  });

  return {
    ...meas,
    zInf: keyMap.zInf,
    zSup: keyMap.zSup,
    offset: keyMap.offset,
    dim1: keyMap.dim1,
    dim2: keyMap.dim2,
    height: keyMap.height,
  };
}
