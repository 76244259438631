import {useState} from "react";

import {
  Button,
  Dialog,
  TextField,
  Typography,
  Box,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

export default function ButtonCreateCaplaModel({editor3d}) {
  // strings

  const createModelString = "Create a new model";
  const nameString = "Name";
  const saveString = "Save";

  // state

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");

  // helpers

  const canCreate = name.length > 0;

  // handlers

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleSave() {
    editor3d?.loader.createCaplaModel({name});
    setOpen(false);
  }

  return (
    <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
      <Button onClick={handleOpen}>{createModelString}</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="body2">{createModelString}</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{mt: 2}}
            size="small"
            InputProps={{
              sx: {fontSize: (theme) => theme.typography.body2.fontSize},
            }}
            label={<Typography variant="body2">{nameString}</Typography>}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!canCreate} onClick={handleSave}>
            {saveString}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
