import React from "react";

import {useSelector} from "react-redux";

import {Button, Box} from "@mui/material";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";

export default function ButtonUpdateMeasurementsWithPackageSector({
  model,
  caplaEditor,
  scene,
}) {
  // data

  const sectors = useSectorsByScene(scene);
  const sector = sectors.find((s) => s.id === model?.sectorId);

  const measurements = useSelector((s) => s.measurements.items).filter(
    (m) => m.measurementsModelId === model?.id
  );

  // string

  const refreshS = `Appliquer le secteur ${sector?.name} à tous les repérages`;

  // handlers

  function handleClick() {
    const sectorId = model?.sectorId;
    const newMeasurements = measurements.map((meas) => ({
      ...meas,
      sectorId: meas?.sectorId ?? sectorId,
    }));
    caplaEditor?.measDataManager.updateMeasurements(newMeasurements);
  }

  return (
    <Box sx={{color: "text.secondary"}}>
      <Button size="small" onClick={handleClick} color="inherit">
        {refreshS}
      </Button>
    </Box>
  );
}
