export default function testArticleTypesHasGlobalOrGroupScope(articleTypes) {
  let test = false;
  if (articleTypes) {
    Object.entries(articleTypes).forEach(([groupId, typeMap]) => {
      if (groupId === "filtersStr") {
        test = true;
      } else if (groupId !== "globalFunc") {
        Object.keys(typeMap).forEach((typeId) => {
          if (typeId === "filtersStr") {
            test = true;
          }
        });
      }
    });
  }
  return test;
}
