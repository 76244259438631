import React, {useState} from "react";

import useMobileModules from "../hooks/useMobileModules";

import {Box, Typography, Button} from "@mui/material";
import DialogEditListingMobileModules from "./DialogEditListingMobileModules";

export default function CardMobileModules({moduleCodes = [], scene}) {
  // string

  const modulesS = "Modules (mobile)";
  const editS = "Modifier";

  // data

  const mobileModules = useMobileModules();

  // state

  const [open, setOpen] = useState(false);

  // helpers

  const momos = moduleCodes.map((code) =>
    mobileModules.find((m) => m.code === code)
  );

  // handlers

  function handleEditClick() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  return (
    <Box sx={{p: 1, border: (theme) => `1px solid ${theme.palette.divider}`}}>
      <Typography variant="body2" color="text.secondary" sx={{mb: 1}}>
        {modulesS}
      </Typography>
      {momos.map((momo) => {
        return (
          <Box
            sx={{display: "flex", alignItems: "center", mb: 1}}
            key={momo.code}
          >
            <Box
              sx={{
                color: "text.secondary",
                mr: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {momo.icon}
            </Box>
            <Typography variant="body2">{momo.name}</Typography>
          </Box>
        );
      })}
      <Box
        sx={{
          width: 1,
          display: "flex",
          justifyContent: "end",
          color: "text.secondary",
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={handleEditClick}
          color="inherit"
        >
          {editS}
        </Button>
      </Box>
      <DialogEditListingMobileModules
        open={open}
        scene={scene}
        onClose={handleClose}
      />
    </Box>
  );
}
