import React from "react";

import {List, ListItem, Typography, IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";

export default function ListMembers({listing}) {
  let members = listing?.userMemberships?.map(({role, user}) => ({
    role,
    email: user.email,
  }));
  if (!members) members = [];

  return (
    <List>
      {members.map((member) => (
        <ListItem
          key={member.email}
          secondaryAction={
            <IconButton>
              <Delete />
            </IconButton>
          }
        >
          <Typography variant="body2">{member.email}</Typography>
        </ListItem>
      ))}
    </List>
  );
}
