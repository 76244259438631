import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/lab/Autocomplete";

import {
  updateConfiguredModel,
  // setPositioningObject,
} from "Features/viewer3D/viewer3DSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  markerSection: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

export default function CardOneMarkerConfig({editor3d, onClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // data

  const markers = useSelector((state) => state.viewer3D.markers);
  const {model} = useSelector((state) => state.viewer3D.configuredModel);
  const positioningObject = useSelector(
    (state) => state.viewer3D.positioningObject
  );
  const positioning = positioningObject.editor2;

  // local state

  const [marker, setMarker] = useState();

  // handlers
  function handleMarkerChange(_, value) {
    setMarker(value);
  }

  function handlePositionClick() {
    // // The user triggers the positioning mode.
    // if (!positioning) {
    //   editor2.switchToMode("POSITIONING_CONFIG_MARKER_1");
    //   dispatch(setPositioningObject({...positioningObject, editor2: true}));
    //   // The user stops the positioning mode.
    // } else {
    //   editor2.switchToMode("PICKING");
    //   dispatch(setPositioningObject({...positioningObject, editor2: false}));
    // }
  }

  function handleSaveClick(e) {
    e.stopPropagation();
    const modelObject = editor3d?.configuredObject;
    const markerObject = editor3d?.getEntityById("MARKER", marker.id).object;
    // const cm1Pos = editor2.configMarker1.object.position.clone();

    modelObject.position.copy(markerObject.position);
    modelObject.updateWorldMatrix();
    // modelObject.localToWorld(cm1Pos.negate());
    // modelObject.position.copy(cm1Pos);

    const newPosition = {
      x: modelObject.position.x,
      y: modelObject.position.y,
      z: modelObject.position.z,
    };
    const newRotation = {
      x: modelObject.rotation.x,
      y: modelObject.rotation.y,
      z: modelObject.rotation.z,
    };
    const newModel = {...model, rotation: newRotation, position: newPosition};
    dispatch(updateConfiguredModel(newModel));

    // editor2.switchToMode("PICKING");
    // dispatch(setPositioningObject({editor2: false}));

    onClose();
  }

  function handleCancelClick() {
    onClose();
    // editor2.hideConfigMarkers();
    // editor2.switchToMode("PICKING");
    // dispatch(setPositioningObject({editor2: false}));
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="body2" gutterBottom>
          <b>Positonnement par rapport à un point de repère</b>
        </Typography>
        <Typography variant="body2">
          Positionnez l'image par rapport à un point de repère existant.
        </Typography>
        <div className={classes.markerSection}>
          <Autocomplete
            size="small"
            options={markers}
            getOptionLabel={(option) =>
              `#${option.number} : ${option.description} `
            }
            style={{width: 250}}
            onChange={handleMarkerChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Point de repère"
                variant="outlined"
                size="small"
              />
            )}
          />
          <Button variant="outlined" onClick={handlePositionClick}>
            {positioning ? "Annuler" : "Positionner le point"}
          </Button>
        </div>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          disableElevation
          color="primary"
          size="small"
          onClick={handleCancelClick}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          size="small"
          onClick={handleSaveClick}
        >
          Recaler l'image
        </Button>
      </CardActions>
    </Card>
  );
}
