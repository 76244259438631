export default function getBoundingRectFromPath(path, delta) {
  const p = path[0];
  let x1 = p[0];
  let y1 = p[1];
  let x2 = p[0];
  let y2 = p[1];
  path.forEach(([x, y]) => {
    if (x <= x1) x1 = x;
    if (x >= x2) x2 = x;
    if (y <= y1) y1 = y;
    if (y >= y2) y2 = y;
  });
  const width = Math.max(x2 - x1, 100); // to handle thin polylines
  const height = Math.max(y2 - y1, 100);
  return [
    x1 - width * delta,
    y1 - height * delta,
    x2 + width * delta,
    y2 + height * delta,
  ];
}
