import { useSelector } from "react-redux";

import {Box} from "@mui/material";

import ButtonDialogCreatePhase from "./ButtonDialogCreatePhase";
import ButtonDialogUpdatePhase from "./ButtonDialogUpdatePhase";
import ButtonDeletePhase from "./ButtonDeletePhase";

// import useSelectedPhase from "../hooks/useSelectedPhase";

export default function ToolbarPhasesMain({scene}) {
  // const selectedPhase = useSelectedPhase(scene);
  const selectedPhase = useSelector((s) => s.phases.selectedPhase);
  return (
    <Box sx={{p: 2, display: "flex"}}>
      <ButtonDialogCreatePhase scene={scene} />
      {selectedPhase && (
        <Box sx={{display: "flex", alignItems: "center"}}>
          <ButtonDialogUpdatePhase scene={scene} phase={selectedPhase} />
          <ButtonDeletePhase scene={scene} phase={selectedPhase} />
        </Box>
      )}
    </Box>
  );
}
