import {useState, useRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {TreeView, TreeItem} from "@mui/lab";
import {ExpandMore, ChevronRight} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";

import TreeModel from "tree-model";

import {setMultipleSelection} from "Features/viewer3D/viewer3DSlice";
import {decode} from "Features/translations/utils";

export default function ToolTree({editor3d, scene}) {
  const dispatch = useDispatch();
  // data

  const allModels = useSelector((state) => state.viewer3D.models);
  const models = allModels.filter((m) => m.sceneClientId === scene?.clientId);
  const modelsIds = models.map((m) => m.id);

  const allTrees = useSelector((state) => state.viewer3D.ifcTrees);
  const trees = allTrees.filter((t) => modelsIds.includes(t.modelId));
  const tree = trees[0];

  const selection = useSelector((state) => state.viewer3D.selection);

  // init

  const treeRef = useRef();
  const rootRef = useRef();

  useEffect(() => {
    if (tree) {
      treeRef.current = new TreeModel();
      rootRef.current = treeRef.current.parse(tree.tree);
    }
  }, [tree]);

  // state

  const [selected, setSelected] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [parentsTree, setParentsTree] = useState();
  const displayed = getDisplayed(parentsTree, expanded);
  const [names, setNames] = useState([]);
  // const [selectedElements, setSelectedElements] = useState([]); // ids of the selected elements (leafs)

  async function asyncSetNames() {
    if (Array.isArray(displayed)) {
      const newNames = await Promise.all(
        displayed.map(async (display) => {
          const props = await editor3d?.ifcLoader.ifcManager.getItemProperties(
            tree.ifcModelID,
            display,
            true
          );
          const name = props?.Name?.value;
          return {expressID: display, name};
        })
      );
      setNames(newNames);
    }
  }

  // effects

  useEffect(() => {
    asyncSetNames(displayed);
  }, [selection?.expressID, displayed && displayed[displayed.length - 1]]);

  useEffect(() => {
    if (selection?.expressID) {
      const selectedNode = rootRef.current.first((node) => {
        return node.model.expressID === selection.expressID;
      });
      if (selectedNode) {
        const path = selectedNode.getPath();
        // const parentNode = path[path.length - 2];
        // const children = parentNode.children;
        // const childrenTree = children.map((node) => ({
        //   expressID: node.model.expressID,
        // }));
        const parentsIDs = path.map((node) => node.model.expressID);
        //const t = getTreeWithAllChildren(parentsIDs, childrenTree);
        const t = getTree(parentsIDs);
        setParentsTree(t);
        parentsIDs.pop();
        const newExpanded = parentsIDs.map((id) => id.toString());

        setExpanded(newExpanded);
        setSelected([selection.expressID.toString()]);
      }
    }
  }, [selection?.expressID]);

  // helpers

  function getDisplayed(tree, expanded) {
    return tree?.children.reduce(
      (ac, current) => {
        if (expanded.includes(current.expressID.toString())) {
          return [
            ...new Set([
              ...ac,
              current.expressID,
              ...getDisplayed(current, expanded),
            ]),
          ];
        } else {
          return [...new Set([...ac, current.expressID])];
        }
      },
      [tree.expressID]
    );
  }

  function getTree(array) {
    const currentArray = [...array];
    const nextArray = currentArray.splice(1);
    if (nextArray.length > 0) {
      return {expressID: array[0], children: [getTree(nextArray)]};
    } else {
      return {expressID: array[0]};
    }
  }

  // function getTreeWithAllChildren(array, childrenTree) {
  //   const currentArray = [...array];
  //   const nextArray = currentArray.splice(1);
  //   if (nextArray.length > 1) {
  //     return {
  //       expressID: array[0],
  //       children: [getTreeWithAllChildren(nextArray, childrenTree)],
  //     };
  //   } else {
  //     return {
  //       expressID: array[0],
  //       children: childrenTree,
  //     };
  //   }
  // }

  function selectElement(expressID) {
    const entity = editor3d?.getEntity(tree.modelId);
    const ids = getLeaves(expressID, rootRef.current);

    const multipleSelectionItem = {
      modelId: tree.modelId,
      ifcModelID: entity?.ifcModelID,
      expressIDs: ids,
    };

    dispatch(setMultipleSelection([multipleSelectionItem]));
  }

  function getLeaves(expressID, treeObject) {
    const leaves = [];
    const node = treeObject.first((n) => n.model.expressID === expressID);

    function traverse(acc, node) {
      if (node.children.length > 0) {
        return node.children.reduce(traverse, acc);
      }
      acc.push(node);
      // side effects
      leaves.push(node.model.expressID);

      return acc;
    }

    traverse([], node);

    return leaves;
  }

  // handlers

  const handleToggle = (_, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (_, nodeIds) => {
    setSelected(nodeIds);
    selectElement(parseInt(nodeIds[0]));
  };

  // render

  const renderTree = (nodes) => {
    //let label = nodes.expressID;
    let label = "";
    const labelObject = names?.find((n) => n.expressID === nodes.expressID);
    if (labelObject) label = decode(labelObject?.name);
    return (
      <TreeItem
        key={nodes.expressID}
        nodeId={nodes.expressID.toString()}
        label={<Typography variant="body2">{label}</Typography>}
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <Box>
      {parentsTree && (
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
          multiSelect
          sx={{flexGrow: 1, maxHeight: 600, maxWidth: 400, overflowY: "auto"}}
        >
          {renderTree(parentsTree)}
        </TreeView>
      )}
    </Box>
  );
}
