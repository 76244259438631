import {useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";

import SliderImageOpacity from "./SliderImageOpacity";
import SliderImageTranslate from "./SliderImageTranslate";
import SliderImageCrop from "./SliderImageCrop";
import ButtonLinkToZoneInPdf from "./ButtonLinkToZoneInPdf";

import ToggleShowMainPart from "Features/viewer3D/components/ToggleShowMainPart";

export default function CardModelSelectionDetail({zone, editor3d}) {
  // string

  const in3DS = "Fond de plan en 3D";

  // data

  const model = useSelector((s) => s.viewer3D.models).find(
    (m) => m.id === zone?.imageModelId
  );

  return (
    <Box sx={{p: 0.5}}>
      <Box sx={{width: 1, display: "flex"}}>
        <Typography variant="body2" sx={{fontWeight: "bold"}} noWrap>
          {in3DS}
        </Typography>
      </Box>

      <Box sx={{width: 1, display: "flex", justifyContent: "end"}}>
        <ButtonLinkToZoneInPdf zone={zone} />
      </Box>

      <Box sx={{display: "flex", width: 1, justifyContent: "center", my: 1}}>
        <ToggleShowMainPart modelId={zone?.imageModelId} editor3d={editor3d} />
      </Box>
      {model?.showMainPart && (
        <Box sx={{width: 1}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
              mt: 1,
            }}
          >
            <SliderImageOpacity editor3d={editor3d} modelId={zone?.imageModelId} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            <SliderImageTranslate
              editor3d={editor3d}
              modelId={zone?.imageModelId}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
            }}
          >
            <SliderImageCrop editor3d={editor3d} modelId={zone?.imageModelId} />
          </Box>
        </Box>
      )}
    </Box>
  );
}
