import {useDispatch, useSelector} from "react-redux";

import {Box} from "@mui/material";

import {setSelectedFormGroup} from "../measurementsSlice";
import useDrawingShapeImage from "../useDrawingShapeImage";
import useSceneMeasurements from "../useSceneMeasurements";

import useKvtMeasurementV2 from "Features/kvform/hooks/useKvtMeasurementV2";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import usePhasesByScene from "Features/phases/hooks/usePhasesByScene";
import useElementTypesBySceneProxy from "Features/elementTypes/hooks/useElementTypesBySceneProxy";
import useSectorsByScene from "Features/sectors/hooks/useSectorsByScene";
import useRoomsByScene from "Features/rooms/hooks/useRoomsByScene";
import useRessourcesByScene from "Features/ressources/hooks/useRessourcesByScene";
import useMaterialsByScene from "Features/materials/hooks/useMaterialsByScene";

export default function FormMeasurement({
  measurement,
  onChange,
  scene,
  isEditing,
}) {
  const dispatch = useDispatch();

  // data

  const drawingShape = useDrawingShapeImage(measurement?.drawingShape);
  const elementTypes = useElementTypesBySceneProxy(scene);

  const zones = useZonesByScene(scene);
  const phases = usePhasesByScene(scene);
  const sectors = useSectorsByScene(scene);
  const rooms = useRoomsByScene(scene);
  const materials = useMaterialsByScene(scene);
  const ressources = useRessourcesByScene(scene);

  const measurements = useSceneMeasurements(scene);

  const selectedFormGroup = useSelector(
    (s) => s.measurements.selectedFormGroup
  );

  // helper

  const zone = zones.find((z) => z.id === measurement?.zoneId);
  const phase = phases.find((p) => p.id === measurement?.phaseId);
  const elementType = elementTypes.find(
    (t) => t.id === measurement.elementTypeId
  );
  const room = rooms.find((r) => r.id === measurement.roomId);
  const material = materials.find((m) => m.id === measurement.materialId);
  const sector = sectors.find((s) => s.id === measurement.sectorId);
  const ressource = ressources.find((r) => r.id === measurement.ressourceId);

  let relatedM = measurements.find((m) => m.id === measurement?.relatedMId);
  relatedM = {
    ...relatedM,
    name: relatedM?.codeName ? relatedM.codeName : "- ? -",
  };

  const item = {
    ...measurement,
    helperImage: drawingShape,
    zone,
    relatedM,
    phase,
    sector,
    room,
    material,
    ressource,
  };

  // helper - kvt

  const kvt = useKvtMeasurementV2(scene, item);

  // helper - hidden fields

  const hiddenFields = [];

  // helper - lockedFields

  const lockedFields = [];
  lockedFields.push("zone");
  if (elementType?.dim1 && !isEditing) lockedFields.push("dim1");
  if (elementType?.dim2 && !isEditing) lockedFields.push("dim2");

  // handlers

  function handleChange(m) {
    const newM = {
      ...m,
      relatedMId: m?.relatedM?.id,
      phaseId: m?.phase?.id,
      sectorId: m?.sector?.id,
      zoneId: m?.zone?.id,
      roomId: m?.room?.id,
      materialId: m?.material?.id,
      color: m?.material
        ? m?.material.color
        : m?.elementType
        ? m.elementType.color
        : m?.color,
      ressourceId: m?.ressource?.id,
    };
    onChange(newM);
  }

  function handleGroupChange(group) {
    dispatch(setSelectedFormGroup(group));
  }

  return (
    <Box>
      <KeyValueForm
        scene={scene}
        item={item}
        template={kvt}
        onChange={handleChange}
        hiddenFields={hiddenFields}
        lockedFields={lockedFields}
        canEdit={isEditing}
        group={selectedFormGroup}
        onGroupChange={handleGroupChange}
      />
    </Box>
  );
}
