import {useSelector} from "react-redux";

export default function useSelectedMeasurements() {
  const ids = useSelector((s) => s.measurements.selectedMeasurementIds);
  const items = useSelector((s) => s.measurements.items);
  const categories = useSelector((s) => s.measurements.categories);
  const models = useSelector((s) => s.viewer3D.models);
  const presets = useSelector((s) => s.measurements.presets);

  return items
    .filter((i) => ids.includes(i.id))
    .map((m) => ({
      ...m,
      category: categories.find((c) => c.id === m.categoryId),
      measurementsModel: models.find((c) => c.id === m.measurementsModelId),
      preset: presets.find((p) => p.id === m.presetId),
    }));
}
