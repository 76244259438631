import {nanoid} from "@reduxjs/toolkit";
import React, {useState} from "react";

import useAddSceneVersion from "../hooks/useAddSceneVersion";

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {Add} from "@mui/icons-material";

import FormVersion from "./FormVersion";

export default function ButtonDialogCreateVersion({scene}) {
  // strings

  const newS = "Nouvelle version";

  const cancelS = "Annuler";
  const createS = "Créer";

  // data

  const addSceneVersion = useAddSceneVersion(scene);

  // state

  const [version, setVersion] = useState({});

  const [open, setOpen] = useState(false);

  // helper

  const isValid = version.num?.length > 0;

  console.log("versionN", version.num);

  // helper - hiddenFields

  const hiddenFields = [];

  // handlers - dialog

  function handleOpenDialog() {
    setOpen(true);
  }
  function handleCloseDialog() {
    setOpen(false);
  }
  // handlers

  function handleVersionChange(version) {
    setVersion(version);
  }

  function handleSave() {
    const newVersion = {...version, id: nanoid()};
    addSceneVersion(newVersion);
    setOpen(false);
    setVersion({});
  }

  function handleCancelClick() {
    setOpen(false);
    setVersion({});
  }

  return (
    <Box>
      <Button
        startIcon={<Add />}
        size="small"
        variant="contained"
        color="secondary"
        onClick={handleOpenDialog}
      >
        {newS}
      </Button>

      <Dialog open={open} onClose={handleCloseDialog} maxWidth={false}>
        <DialogTitle>{newS}</DialogTitle>
        <DialogContent>
          <FormVersion
            version={version}
            onChange={handleVersionChange}
            hiddenFields={hiddenFields}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick}>{cancelS}</Button>
          <Button disabled={!isValid} onClick={handleSave}>
            {createS}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
