import computeRessourceQuantity, {applyFormulaFilter} from "Features/ressources/utils/computeRessourceQuantity";
import sortByNum from "Features/ressources/utils/sortByNum";

function sumArray(array) {
  if (!array) return 0;
  return array.reduce((ac, cur) => ac + cur, 0);
}

export default function getRessourcesByRoom({
  measurements,
  elementTypes,
  ressources,
  rooms,
  filterNoVoids,
  sectors,
  zones,
  ressourcesPU,
}) {
  if (!measurements || !rooms) return [];

  try {

    // helpers

    const zonesById = {};
    for (const item of zones) {
      zonesById[item.id] = item;
    }
    const roomsById = {};
    for (const item of rooms) {
      roomsById[item.id] = item;
    }
    const sectorsById = {};
    for (const item of sectors) {
      sectorsById[item.id] = item;
    }
    const ressourcesById = {};
    for (const item of ressources) {
      ressourcesById[item.id] = item;
    }
    const elementTypesById = {};
    for (const item of elementTypes) {
      elementTypesById[item.id] = item;
    }
  
    // measurements & ressources

    const resByRoom = {}; // {roomId:{resId:[Qty]}]}
    measurements.forEach((measurement) => {
      const res = measurement.res;
      if (!res) return;
      const roomId = measurement.roomId;
      const elementType = elementTypesById[measurement.elementTypeId];
      res
        .filter((r) => !r.off)
        .forEach((mr) => {
          const resId = mr.resId;
          const ressource = ressourcesById[resId];
          if (!ressource) return;
          let res = elementType?.res?.find((r) => resId === r.resId);
          if (!res) res = {resId, mode: "AUTO"}; // if formula not in elementType, by default, we compute with mode="AUTO"
          const useMeas = applyFormulaFilter(
            measurement,
            res?.func,
            zonesById[measurement.zoneId],
            roomsById[measurement.roomId],
            sectorsById[measurement.sectorId],
            filterNoVoids
          );
          if (useMeas) {
            // let mQ = filterNoVoids ? mr.qtyRaw : mr.qtyNet;
            let mQ;
            if (!mQ) {
              mQ = computeRessourceQuantity({
                item: measurement,
                res,
                ressource,
                withDecimal: false,
                filterNoVoids,
              });
            }
            if (roomId && !resByRoom[roomId]) {
              resByRoom[roomId] = {[resId]: [mQ]};
            } else if (resByRoom[roomId] && !resByRoom[roomId][resId]) {
              resByRoom[roomId][resId] = [mQ];
            } else if (resByRoom[roomId] && resByRoom[roomId][resId]) {
              resByRoom[roomId][resId].push(mQ);
            }
          }
        });
    });

    // helpers

    let lastParent;
    const result = [];
    const sortedSectors = sectors.sort((a, b) =>  a.z - b.z || a.name?.localeCompare(b.name));
    sortedSectors.forEach((sector) => {
      const sortedRooms = rooms.filter((r) => r.sector === sector.code)
      .sort((a, b) => sortByNum(a.num, b.num));
      if (sortedRooms.length > 0) result.push({type: "SECTOR", name: sector.name});
      const sortedRessources = ressources.sort((a, b) => sortByNum(a.num, b.num));
      sortedRooms.forEach((room) => {
        const roomRessources = resByRoom[room.id];
        if (roomRessources) {
          result.push({type: "ROOM", name: room.name});
          sortedRessources.forEach((ressource) => {
            const qties = roomRessources[ressource.id];
            if (qties) {
              const split = ressource.num.split(".");
              if (split.length > 0) {
                split.pop();
                const parentNum = split.join(".");
                if (parentNum !== lastParent) {
                  const parent = ressources.find((rp) =>
                    rp.group === ressource.group && rp.num === parentNum
                  );
                  if (parent) {
                    result.push({
                      ...parent,
                      type: "RESSOURCE",
                    });
                    lastParent = parentNum;
                  }
                }
              }
              const qty = sumArray(qties);
              const resToPush = {
                ...ressource,
                type: "RESSOURCE",
                quantity: qty,
              };
              if (ressourcesPU) {
                const puForGroup = ressourcesPU[ressource.group];
                if (puForGroup) {
                  const resPu = puForGroup[ressource.num];
                  if (resPu) {
                    resToPush.pu = resPu;
                    resToPush.pt = qty * resPu;
                  }
                }
              }
              result.push(resToPush);
            }
          });
        }
      });
    })
    return result;
  } catch (e) {
    console.log(e);
  }
}
