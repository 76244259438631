import React from "react";

import {Box} from "@mui/material";

import {
  HorizontalRule as Segment,
  Polyline,
  Rectangle,
  Pentagon as Polygon,
  Circle,
  Escalator as Slope,
  Stairs,
  Terrain as Bank,
  QuestionMark as None,
  WebAsset as Beam,
  ScreenRotation as SlopingPolygon,
  ChangeHistory as Gable,
  FormatUnderlined as Bridge,
  Nightlight as Bowl,
} from "@mui/icons-material";

export default function IconElementType({
  color,
  drawingShape,
  isVoid,
  size = "medium",
}) {
  // helpers

  const iconMap = {
    SEGMENT: (
      <Segment sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    POLYLINE: (
      <Polyline sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    GABLE: (
      <Gable sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    BRIDGE: (
      <Bridge
        sx={{fontSize: size === "small" ? 14 : 20, transform: "rotate(180deg)"}}
        color="inherit"
      />
    ),
    BOWL: (
      <Bowl
        sx={{fontSize: size === "small" ? 14 : 20, transform: "rotate(90deg)"}}
        color="inherit"
      />
    ),
    RECTANGLE: (
      <Rectangle sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    POLYGON: (
      <Polygon sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    CIRCLE: (
      <Circle sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    SLOPE: (
      <Slope sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    STAIRS: (
      <Stairs sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />
    ),
    SLOPING_POLYGON: (
      <SlopingPolygon
        sx={{fontSize: size === "small" ? 14 : 20}}
        color="inherit"
      />
    ),
    BANK: <Bank sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />,
    BEAM: <Beam sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />,
    NONE: <None sx={{fontSize: size === "small" ? 14 : 20}} color="inherit" />,
  };

  const icon = iconMap[drawingShape] ? iconMap[drawingShape] : iconMap["NONE"];
  return (
    <Box
      sx={{
        width: size === "small" ? 20 : 30,
        minWidth: size === "small" ? 20 : 30,
        height: size === "small" ? 20 : 30,
        minHeight: size === "small" ? 20 : 30,
        borderRadius: size === "small" ? 10 : 15,
        bgcolor: "common.white",
        color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: isVoid ? "1px dashed black" : "unset",
      }}
    >
      {icon}
    </Box>
  );
}
