import {useSelector, useDispatch} from "react-redux";
import {useState, useEffect} from "react";

import {
  setShowOld,
  setShowNew,
  setShowDiff,
  setOldZoneId,
  setImageDiff,
} from "Features/zones/zonesSlice";
import useZonesByScene from "../hooks/useZonesByScene";

import {Box, Typography, Paper, IconButton, Button} from "@mui/material";
import {Difference, Visibility, VisibilityOff} from "@mui/icons-material";
import ButtonToggleNewOld from "./ButtonToggleNewOld";

import SelectorAutocomplete from "Components/SelectorAutocomplete";

import getItemsMapById from "Utils/getItemsMapById";

import cv from "Features/images/js/serviceCV";
import SliderOpacity from "./SliderOpacity";
import getZonesDeltaInPx from "../utils/getZonesDeltaInPx";

export default function SectionZoneDifferences({caplaEditor, scene}) {
  const dispatch = useDispatch();

  // strings

  const oldS = "Avant";
  const newS = "Après";
  const diffS = "Différences";

  const addedElements = "ajouts";
  const deletedElements = "suppressions";

  const computeS = "Calculer les différences";

  // data

  const allZones = useZonesByScene(scene);

  const selectedZoneId = useSelector((s) => s.zones.selectedZoneId);
  const oldZoneId = useSelector((s) => s.zones.oldZoneId);

  const showOld = useSelector((s) => s.zones.showOld);
  const showNew = useSelector((s) => s.zones.showNew);
  const showDiff = useSelector((s) => s.zones.showDiff);

  // state

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [imageOldOpacity, setImageOldOpacity] = useState(100);
  const [imageNewOpacity, setImageNewOpacity] = useState(100);

  useEffect(() => {
    if (!open) {
      setLoading(false);
    }
  }, [open]);

  // helpers

  const zonesMap = getItemsMapById(allZones);

  const oldZone = zonesMap[oldZoneId];
  const newZone = zonesMap[selectedZoneId];

  const canDiff = oldZone && newZone && oldZone?.id !== newZone?.id;

  const imageDiff = useSelector((s) => s.zones.imageDiff);

  // helper - thereIsDiff

  const thereIsDiff = oldZone && newZone && imageDiff?.url;

  // handlers

  function handleVisibilityChange(mode) {
    if (mode === "new") {
      dispatch(setShowNew(!showNew));
      if (showNew) {
        caplaEditor.zonesEditor.hideImage();
      } else {
        caplaEditor.zonesEditor.showImage();
      }
    } else if (mode === "old") {
      dispatch(setShowOld(!showOld));
      if (showOld) {
        caplaEditor.zonesEditor.hideImageOld();
      } else {
        caplaEditor.zonesEditor.showImageOld();
      }
    } else if (mode === "diff") {
      dispatch(setShowDiff(!showDiff));
      if (showDiff) {
        caplaEditor.zonesEditor.hideImageDiff();
      } else {
        caplaEditor.zonesEditor.showImageDiff();
      }
    }
  }

  // handler old

  function handleOldChange(zone) {
    if (!zone) {
      caplaEditor.zonesEditor.clearLayerDifferences();
      return;
    }
    console.log("zoneOld", zone);
    dispatch(setOldZoneId(zone?.id));
    const scale1 = newZone.imageSize.width / newZone.width;
    const scale2 = zone.scale / newZone.scale;
    const scale3 = zone.width / zone.imageSize.width;
    const scale = scale1 * scale2 * scale3;

    console.log("scale", scale);
    const {deltaX, deltaY} = getZonesDeltaInPx(zone, newZone);

    const imageNode = caplaEditor.zonesEditor.imageNode;
    const newImageX = imageNode.x();
    const newImageY = imageNode.y();

    let offsetX = newImageX + deltaX;
    let offsetY = newImageY + deltaY;

    const imageOld = {
      url: zone.imageUrl,
      width: zone.imageSize.width,
      height: zone.imageSize.height,
      offsetX: offsetX,
      offsetY: offsetY,
      scale: scale,
    };

    if (zone?.id && zone?.id !== oldZoneId) {
      caplaEditor.zonesEditor.setImageOld(imageOld);
    }
  }

  function handleImageOldOpacityChange(opacity) {
    setImageOldOpacity(opacity);
    caplaEditor.zonesEditor.setImageOldOpacity(opacity);
  }

  // handler - new

  function handleImageNewOpacityChange(opacity) {
    setImageNewOpacity(opacity);
    caplaEditor.zonesEditor.setImageOpacity(opacity);
  }

  // handler - diff

  async function handleComputeDiff() {
    if (loading) return;
    setLoading(true);
    const {imageDataOld: data1, imageDataNew: data2} =
      await caplaEditor.zonesEditor.getOldAndNewImageData();

    if (!data1 || !data2) {
      setLoading(false);
      console.log("error : no old / new zone");
      return;
    }
    console.log("imageDiff 1", data1, data2);
    await cv.load();
    const payload = {imageData1: data1, imageData2: data2};
    const differences = await cv.highlightDifferences(payload);
    const data3 = differences.data.payload;
    const canvas = document.createElement("canvas");
    canvas.width = data3.width;
    canvas.height = data3.height;
    const ctx = canvas.getContext("2d");
    ctx.putImageData(data3, 0, 0);
    const dataURL = canvas.toDataURL("image/png");
    const imageDiff = {url: dataURL, width: data3.width, height: data3.height};
    console.log("imageDiff 2", imageDiff);
    dispatch(setImageDiff(imageDiff));
    caplaEditor.zonesEditor.setImageDiff(imageDiff);
    setLoading(false);
  }

  return (
    <Box sx={{display: "flex", alignItems: "end"}}>
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: 2,
        }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          <Difference />
        </IconButton>
      </Paper>
      {open && (
        <Paper
          sx={{
            display: "flex",
            alignItems: "stretch",
          }}
          elevation={6}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyItems: "stretch",
              p: 1,
            }}
          >
            <Paper
              sx={{display: "flex", alignItems: "center", mb: 2, p: 0.5}}
              elevation={showNew ? 12 : 0}
            >
              <IconButton
                sx={{mr: 1}}
                size="small"
                onClick={() => {
                  handleVisibilityChange("new");
                }}
              >
                {showNew ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <Typography sx={{fontSize: 13}}>{newS}</Typography>
            </Paper>
            <Box sx={{flexGrow: 1, display: "flex"}}>
              <Typography sx={{fontSize: 13}}>{newZone?.name}</Typography>
            </Box>
            <Box sx={{}}>
              <SliderOpacity
                value={imageNewOpacity}
                onChange={handleImageNewOpacityChange}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyItems: "stretch",
              p: 1,
            }}
          >
            <Paper
              sx={{display: "flex", alignItems: "center", mb: 2, p: 0.5}}
              elevation={showOld && oldZone ? 12 : 0}
            >
              <IconButton
                disabled={!oldZone}
                sx={{mr: 1}}
                size="small"
                onClick={() => {
                  handleVisibilityChange("old");
                }}
              >
                {showOld ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <Typography sx={{fontSize: 13}}>{oldS}</Typography>
            </Paper>
            <Box sx={{display: "flex", width: 1, flexGrow: 1}}>
              <SelectorAutocomplete
                value={oldZone ?? null}
                options={allZones}
                onChange={handleOldChange}
              />
            </Box>
            <SliderOpacity
              value={imageOldOpacity}
              onChange={handleImageOldOpacityChange}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyItems: "stretch",
              p: 1,
              mb: 1,
            }}
          >
            <Paper
              sx={{display: "flex", alignItems: "center", mb: 2, p: 0.5}}
              elevation={showDiff && imageDiff?.url ? 12 : 0}
            >
              <IconButton
                disabled={!imageDiff?.url}
                sx={{mr: 1}}
                size="small"
                onClick={() => {
                  handleVisibilityChange("diff");
                }}
              >
                {showDiff ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <Typography sx={{fontSize: 13}}>{diffS}</Typography>
            </Paper>

            <Button disabled={loading || !canDiff} onClick={handleComputeDiff}>
              {computeS}
            </Button>

            <Box
              sx={{
                mt: 1,
                visibility: newZone && oldZone ? "visible" : "hidden",
              }}
            >
              <ButtonToggleNewOld caplaEditor={caplaEditor} />
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
}
