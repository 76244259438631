import React from "react";

import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";

export default function SectionSelectorRoomsGroupRankNames({
  selectedModeCode,
  onSelectionChange,
  onRankNamesChange,
}) {
  // strings

  const descriptionS = "Choisissez les niveaux de l'arborescence";

  // helpers

  const modes = [
    {
      code: "LEVELS",
      rankNames: ["Niveaux"],
      description: "Niveaux",
    },
    {
      code: "LEVELS_APTS",
      rankNames: ["Niveaux", "Appartements"],
      description: "Niveaux | Appartements",
    },
    {
      code: "ALLEY_LEVELS_APTS",
      rankNames: ["Allée", "Niveaux", "Appartements"],
      description: "Allée | Niveaux | Appartements",
    },
  ];

  // handlers

  function handleCheckChange(e, mode) {
    const checked = e.target.checked;
    if (checked) {
      onSelectionChange(mode.code);
      onRankNamesChange(mode.rankNames);
    } else {
      onSelectionChange(null);
      onRankNamesChange([]);
    }
  }

  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        {descriptionS}
      </Typography>
      <Box sx={{display: "flex", flexDirection: "column", mt: 1}}>
        {modes.map((mode) => {
          const checked = mode.code === selectedModeCode;
          return (
            <FormControlLabel
              key={mode.code}
              label={
                <Typography variant="body2">{mode.description}</Typography>
              }
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  onChange={(e) => handleCheckChange(e, mode)}
                />
              }
            />
          );
        })}
      </Box>
    </Box>
  );
}
