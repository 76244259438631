import React from "react";

import {useDispatch} from "react-redux";
import {fetchRemoteSceneService} from "../services";
import {updateScene} from "../scenesSlice";

import {IconButton} from "@mui/material";
import {CloudUpload} from "@mui/icons-material";
import useAccessToken from "Features/auth/useAccessToken";

export default function ButtonUpdateSceneClientFromRemote({sceneId}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  async function handleClick() {
    //dispatch(fetchRemoteScene({sceneId, accessToken})); // /!\ models url is set with remote model's url...or even deleted. See the service
    const fetchResult = await fetchRemoteSceneService({accessToken, sceneId});
    const scene = fetchResult.remoteScene;
    delete scene.models;
    delete scene.userMemberships;
    delete scene.members;
    scene.data = JSON.parse(scene.data);
    dispatch(updateScene({scene}));
  }

  return (
    <IconButton sx={{height: 30, width: 30}} onClick={handleClick}>
      <CloudUpload fontSize="small" color="secondary" />
    </IconButton>
  );
}
