import {Box} from "@mui/material";

import FormTempInput from "./FormTempInput";

export default function SectionTempInputInSelectionPanel({editor3d}) {
  return (
    <Box sx={{width: 1}}>
      <FormTempInput editor3d={editor3d} />
    </Box>
  );
}
