import React from "react";

import {List, ListItemButton, Typography} from "@mui/material";

export default function ListPackagesMultiple({
  packages,
  selection,
  onSelectionChange,
  multipleSelection,
}) {
  // handlers

  function handleSelectionChange(packageId) {
    let newSelection = [...selection];
    if (multipleSelection) {
      if (selection.includes(packageId)) {
        newSelection = newSelection.filter((id) => id !== packageId);
      } else {
        newSelection.push(packageId);
      }
    } else {
      if (selection.includes(packageId)) {
        newSelection = [];
      } else {
        newSelection = [packageId];
      }
    }
    if (onSelectionChange) onSelectionChange(newSelection);
  }
  return (
    <List dense disablePadding>
      {packages.map((item) => {
        const selected = selection.includes(item.id);
        return (
          <ListItemButton
            divider
            key={item.id}
            selected={selected}
            onClick={() => handleSelectionChange(item.id)}
          >
            <Typography variant="body2" noWrap>
              {item.name}
            </Typography>
          </ListItemButton>
        );
      })}
    </List>
  );
}
