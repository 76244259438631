import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import ListItemIssue from "./ListItemIssue";

import useIssuesByScene from "Features/issues/useIssuesByScene";

export default function ShortcutIssues({caplaEditor, scene, viewer}) {
  // data

  let issues = useIssuesByScene(scene?.id);

  const selectedIssuesListingId = useSelector(
    (s) => s.issues.selectedIssuesListingId
  );

  // helpers - filtered issues

  issues = issues.filter((i) => i.listing === selectedIssuesListingId);

  // helpers - sort

  issues = issues.sort((a, b) => a.title.localeCompare(b.title));

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignContent: "start",
      alignItems: "start",
      "&>*:not(:last-child)": {mb: 1},
    }}>
      {issues.map((issue) => (
        <ListItemIssue
          key={issue.id}
          issue={issue}
          caplaEditor={caplaEditor}
          scene={scene}
          viewer={viewer}
        />
      ))}
    </Box>
  );
}
