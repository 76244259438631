import PageHeader from "Features/ui/components/PageHeader";
import {
  Shape,
  ShapeBufferGeometry,
  ShapeGeometry,
  MeshLambertMaterial,
  DoubleSide,
  Mesh,
  Vector2,
  Vector3,
  TextureLoader,
  BufferAttribute,
  Path,
  BufferGeometry,
} from "three";

class MaskGeometry {
  constructor({width, height, mask}) {
    this.width = width;
    this.height = height;

    const {geometry, maskPoints, contourGeometry} = this.createMaskGeometry({
      width,
      height,
      mask,
    });
    this.geometry = geometry;
    this.contourGeometry = contourGeometry;
    this.maskPoints = maskPoints;
  }

  createContourGeometry(maskPoints) {
    const points = maskPoints.map((p) => new Vector3(p[0], p[1], 0));
    const geometry = new BufferGeometry().setFromPoints(points);
    return geometry;
  }

  createPolygonGeometry(maskCoordinates) {
    const maskVectors = maskCoordinates.map((path) => {
      return path.map((c) => new Vector2(c[0], c[1]));
    });

    //const shape = new Shape([...maskVectors[0], maskVectors[0][0]]);
    if (maskVectors[0].length > 0) {
      const shape = new Shape(maskVectors[0]);

      maskVectors.forEach((hole, index) => {
        if (index > 0 && hole.length > 2) {
          const holePath = new Path(hole);
          shape.holes.push(holePath);
        }
      });
      const shapeGeometry = new ShapeBufferGeometry(shape);
      return shapeGeometry;
    } else {
      console.log("invalid geometry");
    }
  }

  createMaskGeometry({width, height, mask}) {
    // default mask
    if (!mask) {
      mask = [
        [
          [0, 0],
          [1, 0],
          [1, 1],
          [0, 1],
        ],
      ];
    }
    // polygon base geometry

    const maskCoordinates = mask.map((path) => {
      return path.map((c) => [c[0] * width, c[1] * height]);
    });

    const geometry = this.createPolygonGeometry(maskCoordinates);

    // geometry

    const position = geometry.getAttribute("position");
    const count = position.count;
    const UVs = new Float32Array(count * 2);
    const newPositions = new Float32Array(count * 3);

    let positions = position.array;

    for (let i = 0; i < count; i++) {
      let x = positions[3 * i];
      let y = positions[3 * i + 1];
      UVs[2 * i] = x / width;
      UVs[2 * i + 1] = y / height;
      newPositions[3 * i] = x - width / 2;
      newPositions[3 * i + 1] = y - height / 2;
      newPositions[3 * i + 2] = 0;
    }

    geometry.setAttribute("uv", new BufferAttribute(UVs, 2));
    geometry.setAttribute("position", new BufferAttribute(newPositions, 3));

    // maskpoints

    const maskPoints = mask[0].map((c) => [
      c[0] * width - width / 2,
      c[1] * height - height / 2,
    ]);

    // contour Geometry
    const contourGeometry = this.createContourGeometry(maskPoints);

    return {geometry, maskPoints, contourGeometry};
  }

  updateMask(newMask) {
    // this.maskPoints

    this.maskPoints = newMask[0].map((c) => [
      c[0] * this.width - this.width / 2,
      c[1] * this.height - this.height / 2,
    ]);

    // contour Geometry
    this.contourGeometry = this.createContourGeometry(this.maskPoints);

    // polygon base geometry

    const maskCoordinates = newMask.map((path) => {
      return path.map((c) => [c[0] * this.width, c[1] * this.height]);
    });

    const geometry = this.createPolygonGeometry(maskCoordinates);

    // geometry

    const position = geometry.getAttribute("position");
    const count = position.count;
    const UVs = new Float32Array(count * 2);
    const newPositions = new Float32Array(count * 3);

    let positions = position.array;

    for (let i = 0; i < count; i++) {
      let x = positions[3 * i];
      let y = positions[3 * i + 1];
      UVs[2 * i] = x / this.width;
      UVs[2 * i + 1] = y / this.height;
      newPositions[3 * i] = x - this.width / 2;
      newPositions[3 * i + 1] = y - this.height / 2;
      newPositions[3 * i + 2] = 0;
    }

    geometry.setAttribute("uv", new BufferAttribute(UVs, 2));
    geometry.setAttribute("position", new BufferAttribute(newPositions, 3));

    if (this.geometry?.dispose) this.geometry.dispose();
    this.geometry = geometry;
  }
}

export default MaskGeometry;
