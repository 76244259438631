import {Box} from "@mui/material";

import RichTextEditor from "Features/textEditor/components/RichTextEditor";

export default function ContentEditor({
  listingId,
  accessToken,
  onSaveClick,
  loading,
  initialValue,
  onChange,
  caplaEditor,
  isNew,
  editing,
  sceneClientId,
  initialNoteElements,
  showLinkedElements,
  viewpoint,
  showSave,
  viewer,
}) {
  // Text editor, if editing, needs to be render with an active token.

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        ...(isNew && {minHeight: 200}),
      }}
    >
      {listingId && (!editing || viewer || accessToken) && (
        <RichTextEditor
          initialValue={initialValue}
          listingId={listingId}
          accessToken={accessToken}
          loading={loading}
          onSaveClick={onSaveClick}
          onChange={onChange}
          caplaEditor={caplaEditor}
          isNew={isNew}
          editing={editing}
          closeButton={false}
          sceneClientId={sceneClientId}
          initialNoteElements={initialNoteElements}
          showLinkedElements={showLinkedElements}
          viewpoint={viewpoint}
          showSave={showSave}
        />
      )}
    </Box>
  );
}
