import {useState, useRef, useEffect} from "react";
import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {Box, TextField, Divider, Button, Typography} from "@mui/material";

import TitleCloseHeader from "Components/TitleCloseHeader";

import {setMode} from "Features/viewer3D/viewer3DSlice";
import Drawer from "Features/viewer3D/js/Drawer";
import validatePart from "Features/viewer3D/utils/validatePart";

export default function AddImagePartProcess({editor3d, onClose}) {
  const dispatch = useDispatch();

  // drawer

  const drawerRef = useRef();

  // strings

  const title = "Adding Parts";
  const createString = "Add part";
  const partNameString = "Part name";

  // state

  const [name, setName] = useState("");
  const [pointsArray, setPointsArray] = useState([[]]); // array of polylines

  // helpers

  function init() {
    drawerRef.current = new Drawer({
      scene: editor3d?.scene,
      onClosingLine: handleClosingLine,
    });
    if (editor3d) {
      editor3d.onProcessEditorClick = handleEditorClick;
      editor3d.onProcessEditorMove = handleEditorMove;
    }
  }

  function endProcess() {
    drawerRef.current.clear();
    editor3d?.switchToMode("DEFAULT");
    dispatch(setMode("DEFAULT"));
  }

  const canCreatePart = validatePart({mask: pointsArray}) && name.length > 0;

  // handlers

  function handleClose() {
    onClose();
    endProcess();
  }

  function handleCreatePart() {
    const part = {
      id: nanoid(),
      name,
      pointsArray,
    };
    editor3d?.configCenter.createPart(part);

    onClose();
    endProcess();
    return part;
  }

  function handleEditorClick(props) {
    const {sceneP} = props;

    const index = drawerRef.current.shapesCount;

    const point = drawerRef.current.addPoint(sceneP);

    setPointsArray((pointsArray) => {
      const newPointsArray = [...pointsArray];
      if (newPointsArray[index]) {
        newPointsArray[index].push(point);
      } else {
        newPointsArray[index] = [point];
      }

      return newPointsArray;
    });
  }

  function handleEditorMove({sceneP}) {
    drawerRef.current.drawNextPoint(sceneP);
  }

  function handleClosingLine({contour, hole, area}) {
    console.log("create new shape", {contour, hole, area});
  }

  // init

  useEffect(() => {
    init();
    return () => {
      endProcess();
    };
  }, []);

  return (
    <Box>
      <TitleCloseHeader title={title} onClose={handleClose} />

      <Box sx={{p: 1}}>
        <TextField
          size="small"
          value={name}
          label={<Typography variant="body2">{partNameString}</Typography>}
          onChange={(e) => setName(e.target.value)}
          inputProps={{
            sx: (theme) => ({
              fontSize: theme.typography.body2.fontSize,
            }),
          }}
        />
      </Box>
      <Divider sx={{my: 1}} />
      <Box>
        <Button
          size="small"
          variant="outlined"
          onClick={handleCreatePart}
          disabled={!canCreatePart}
        >
          {createString}
        </Button>
      </Box>
    </Box>
  );
}
