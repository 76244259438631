import {useState} from "react";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";

import useUpdateSceneWithElementTypes from "../hooks/useUpdateSceneWithElementTypes";
import {
  createElementTypesGroup,
  setSelectedElementTypesGroupProxyInEditMeasurements,
} from "../elementTypesSlice";

import {Box, Typography, TextField, Button} from "@mui/material";
import theme from "Styles/theme";

import useAccessToken from "Features/auth/useAccessToken";
import {setSnackbarMessage} from "Features/ui/uiSlice";

export default function CardCreateElementTypeGroupWithName({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();

  // strings

  const title = "Nouvelle liste vide";
  const createS = "Créer la liste";
  const placeholder = "Liste d'ouvrages. Ex: Lot GO, Men.ext,...";

  // data

  const updateSceneWithElementTypes = useUpdateSceneWithElementTypes(scene);

  // state

  const [name, setName] = useState("");

  // handlers

  function createGroupFromScene() {
    const defaultElement = {
      id: nanoid(),
      name: "Polyligne",
      num: 1,
      drawingShape: "POLYLINE",
      color: theme.palette.common.yellow,
      group: name,
    };
    updateSceneWithElementTypes([defaultElement]);
  }

  async function createGroupRemote() {
    const newGroup = {};
    newGroup.name = name;
    newGroup.fromScene = false;
    newGroup.sceneId = scene?.id;
    newGroup.elementTypes = [];
    //
    const result = await dispatch(
      createElementTypesGroup({elementTypesGroup: newGroup, accessToken})
    );
    const _result = unwrapResult(result);
    const group = _result?.item;
    delete group.elementTypes;
    if (group)
      dispatch(setSelectedElementTypesGroupProxyInEditMeasurements(group));
    //
    const message = `Liste "${newGroup.name}" créée`;
    const triggeredAt = Date.now();
    dispatch(setSnackbarMessage({message, triggeredAt}));
  }

  function handleClick() {
    if (accessToken && scene?.id) {
      createGroupRemote();
    } else {
      createGroupFromScene();
    }
  }

  return (
    <Box
      sx={{p: 1, width: 1, bgcolor: "background.default", borderRadius: "4px"}}
    >
      <Typography variant="body2" sx={{mb: 2, fontWeight: "bold"}}>
        {title}
      </Typography>
      <TextField
        placeholder={placeholder}
        size="small"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        InputProps={{sx: {fontSize: 13}}}
      />
      <Box sx={{width: 1, display: "flex", justifyContent: "end", mt: 2}}>
        <Box sx={{bgcolor: "warning.main", color: "common.white"}}>
          <Button size="small" color="inherit" onClick={handleClick}>
            {createS}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
