import React from "react";

import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
} from "@mui/material";

export default function SelectOptionsWithObjects({
  option,
  options,
  onChange,
  label,
}) {
  function handleChange(e) {
    const v = e.target.value;
    if (v) {
      onChange(v);
    } else {
      onChange(null);
    }
  }

  return (
    <FormControl margin="dense" fullWidth size="small">
      <InputLabel id="select">
        <Typography variant="body2">{label}</Typography>
      </InputLabel>
      <Select
        labelId="select"
        id="style3D"
        value={option}
        renderValue={(value) => (
          <Typography variant="body2">{value.name}</Typography>
        )}
        label={label}
        onChange={handleChange}
        inputProps={{
          sx: {fontSize: (theme) => theme.typography.body2.fontSize},
        }}
      >
        {options.map((option) => (
          <MenuItem value={option} key={option.id ? option.id : option.key}>
            <Typography variant="body2">
              {option ? option.name : "- ? -"}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
