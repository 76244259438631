function testItemsOnFields(item1, item2, fields) {
  let test = 1;
  fields.forEach((field) => {
    test = test * (item1[field] === item2[field] ? 1 : 0);
  });

  return Boolean(test);
}
export default function getMeasNomenclatureType(
  meas,
  typeNomenclature,
  fields
) {
  const type = typeNomenclature.find((item) =>
    testItemsOnFields(meas, item, [...fields, "drawingShape"])
  );

  return type;
}
