import {useDispatch} from "react-redux";
import {setOpenPackagesPanel} from "../packagesSlice";

import {Box, IconButton, Typography, Paper} from "@mui/material";
import {Close, Layers as PackagesIcon} from "@mui/icons-material";

export default function PanelPackagesHeader() {
  const dispatch = useDispatch();

  // strings

  const title = "Sélectionnez un calque";

  const handleClose = () => {
    dispatch(setOpenPackagesPanel(false));
  };

  return (
    <Box sx={{p: 1, width: 1}}>
      <Paper
        elevation={12}
        sx={{
          p: 1,
          width: 1,
          height: (theme) => theme.spacing(5),
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PackagesIcon color="action" />
        <Typography>{title}</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Paper>
    </Box>
  );
}
