import {Box} from "@mui/material";

import CardConfigCenterDescription from "./CardConfigCenterDescription";

export default function ToolConfigHelp() {
  return (
    <Box>
      <CardConfigCenterDescription />
    </Box>
  );
}
