import {useState} from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  Typography,
  Button,
  TextField,
} from "@mui/material";

import SelectorImageFile from "Features/images/components/SelectorImageFile";
import {getImageSize} from "Features/images/imageUtils";

export default function DialogCreateZoneFromImage({
  scene,
  caplaEditor,
  open,
  onClose,
}) {
  // strings

  const title = "Créer un fond de plan à partir d'une image";
  const createS = "Créer";

  // state

  const [tempFile, setTempFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  // handlers

  function handleFileChange(file) {
    setTempFile(file);
  }

  function handleClose() {
    setTempFile(null);
    onClose();
  }

  async function handleCreate() {
    const imageSize = getImageSize({file: tempFile});

    const width = imageSize.width;
    const height = imageSize.height;

    const options = {width, height, name};

    await caplaEditor?.editor3d.loader.loadFile({
      file: tempFile,
      type: "IMAGE",
      options,
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <Box sx={{p: 1}}>
        <TextField
          label="Nom du fond de plan"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box sx={{width: 400, height: 300}}>
        <SelectorImageFile onFileChange={handleFileChange} />
      </Box>
      <DialogActions>
        <Button disabled={loading} onClick={handleCreate}>
          {createS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
