import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {Slider, Box, Typography, InputBase, Button} from "@mui/material";

import throttle from "lodash.throttle";

import {setImageTranslationRangeMax} from "Features/viewer3D/viewer3DSlice";

export default function SliderImageTranslate({modelId, editor3d, enabled}) {
  const dispatch = useDispatch();

  // strings

  const translateString = "Translation";
  const rangeMaxString = "Max (m):";
  const resetS = "Reset";

  // props

  const entity = editor3d?.getEntity(modelId);

  // data

  const rangeMax = useSelector((s) => s.viewer3D.imageTranslationRangeMax);

  // state

  const initDelta = entity?.delta ? entity.delta : 0;
  const [delta, setDelta] = useState(initDelta);
  useEffect(() => setDelta(initDelta), [modelId]);

  // helpers

  const maxH = 200;

  // handler - delta

  function handleDeltaChange(_, newValue) {
    setDelta(newValue);
    if (entity?.tempTranslate) {
      const delta = (newValue / maxH) * rangeMax;
      entity.tempTranslate(delta);
    }
  }

  // handler - max range

  function handleRangeMaxChange(e) {
    let max = e.target.value;
    max = max === "" ? 1 : max;
    max = parseFloat(max);
    dispatch(setImageTranslationRangeMax(max));
  }

  // handler - reset translation

  function handleReset() {
    if (entity?.tempTranslate) entity.tempTranslate(0);
    setDelta(0);
  }

  return (
    <Box sx={{width: 1, bgcolor: "background.default"}}>
      <Box
        sx={{
          minWidth: 200,
          px: 1,
          pr: 2,
          display: "flex",
          alignItems: "center",
          width: 1,
        }}
      >
        <Typography sx={{fontSize: 12, mr: 2}}>{translateString}</Typography>
        <Slider
          size="small"
          min={-maxH}
          max={maxH}
          value={delta}
          onChange={throttle(handleDeltaChange, 100)}
          disabled={!enabled}
        />
      </Box>
      <Box
        sx={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            ml: 2,
            p: 0.5,
          }}
        >
          <Typography noWrap sx={{fontSize: 13, mr: 1}}>
            {rangeMaxString}
          </Typography>
          <InputBase
            value={rangeMax}
            onChange={handleRangeMaxChange}
            sx={{
              fontSize: 13,
              width: "50px",
              color: enabled ? "inherit" : "text.secondary",
            }}
          />
        </Box>
        <Box sx={{pr: 1}}>
          <Button
            size="small"
            variant="outlined"
            onClick={handleReset}
            disabled={!enabled}
          >
            {resetS}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
