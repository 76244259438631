import {useDispatch} from "react-redux";

import {setSnackbarMessage} from "Features/ui/uiSlice";

import useUpdateElementTypesGroup from "../hooks/useUpdateElementTypesGroup";
import useCleanElementTypesGroup from "../hooks/useCleanElementTypesGroup";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";

export default function DialogCleanElementTypesGroup({
  scene,
  group,
  open,
  onClose,
}) {
  const dispatch = useDispatch();

  // strings

  const title = "Nettoyer la liste";
  const saveS = "Enregistrer";

  const description = `Opération de nettoyage : 
    - supprime les relations aux articles inexistants
    - réordonne les numéros
    - corrige les nombres mal formatés`;

  const message = "Liste nettoyée";

  // data

  const updateElementTypesGroup = useUpdateElementTypesGroup();
  const cleanElementTypesGroup = useCleanElementTypesGroup(scene);

  // handlers

  async function handleSave() {
    const date = Date.now();
    let cleanGroup = cleanElementTypesGroup(group);
    updateElementTypesGroup(cleanGroup);
    dispatch(setSnackbarMessage({message, triggeredAt: date}));
    onClose();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{saveS}</Button>
      </DialogActions>
    </Dialog>
  );
}
