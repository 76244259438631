import {useDispatch} from "react-redux";
import {setAnnotCreationTool} from "../measurementsSlice";

import {Button} from "@mui/material";

export default function ButtonStopDrawing({caplaEditor}) {
  const dispatch = useDispatch();

  // strings

  const stopS = "Stop";

  // handlers

  function handleClick() {
    dispatch(setAnnotCreationTool(null));
    caplaEditor.editorPdf.annotationsManager.measurementsPdfManager.stopDrawing();
    caplaEditor.editorPdf.sceneEditor.stopProcess();
    caplaEditor.editorPdf.setSnapToZones(false);
  }

  //

  return (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={handleClick}
    >
      {stopS}
    </Button>
  );
}
