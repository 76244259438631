import {useSelector} from "react-redux";

import {CenterFocusStrong} from "@mui/icons-material";
import {List, ListItemButton, Box, Typography, IconButton} from "@mui/material";

export default function ListMeasurements({
  measurements,
  selection,
  onSelectionChange,
  multiple,
  caplaEditor,
}) {
  //

  // strings

  const measurementsS = "Repérages";

  // data

  const os = useSelector((s) => s.ui.openSections);

  // handlers

  function handleClick(measurement) {
    console.log("measurement X", measurement);
    const mId = measurement.id;
    let newSelection = selection ? selection.filter((id) => id !== mId) : [];
    const wasSelected = selection.includes(mId);
    if (multiple) {
      newSelection = wasSelected ? newSelection : [...newSelection, mId];
    } else {
      newSelection = wasSelected ? newSelection : [mId];
    }
    if (onSelectionChange) onSelectionChange(newSelection);
  }

  function handleFocusClick(e, measurement) {
    e.stopPropagation();
    e.preventDefault();
    const measurementId = measurement.id;
    if (os.viewer3D) {
      const entity = caplaEditor?.editor3d?.sceneEditor.testEntityExists({
        entityID: measurementId,
      });
      console.log("focusOnEntity", entity);
      caplaEditor?.editor3d?.sceneEditor.focusMeasurement(measurement);
    }
    if (os.fixedViewersBox) {
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.jumpToAnnotationId(
        measurementId
      );
      caplaEditor?.editorPdf?.annotationsManager.measurementsPdfManager.highlightAnnotation(
        measurementId
      );
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        flexGrow: 1,
        width: 1,
      }}
    >
      <Typography variant="body2" color="secondary" sx={{ml: 1}}>
        {measurementsS}
      </Typography>
      <Box sx={{display: "flex", minHeight: 0, overflow: "auto", width: 1}}>
        <List dense sx={{bgcolor: "common.white", width: 1}}>
          {measurements.map((measurement) => {
            const label = measurement.elementType?.name;
            const selected = selection?.includes(measurement.id);
            return (
              <ListItemButton
                selected={selected}
                divider
                key={measurement.id}
                onClick={() => handleClick(measurement)}
                sx={{
                  px: 1,
                  m: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{fontSize: 12}}>{label}</Typography>
                <IconButton
                  onClick={(e) => handleFocusClick(e, measurement)}
                  size="small"
                >
                  <CenterFocusStrong fontSize="small" />
                </IconButton>
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Box>
  );
}
