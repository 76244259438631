export default function getPackagesForDatagrid(packages, jobs, colorings) {
  return packages.map((model) => {
    return {
      id: model.id,
      remoteId: model.remoteId,
      name: model.name,
      elements: model.measurementsData?.measurements?.length,
      coloringJob: jobs?.find((j) => j.packageId === model.remoteId),
      colorings: colorings?.filter((c) => c.packageId === model.remoteId)
        .length,
      enabled: model.enabled,
      excludeFromQties: model.excludeFromQties,
    };
  });
}
