import useSceneListings from "Features/listings/useSceneListings";
import {useSelector} from "react-redux";

export default function useSelectedIssueset(sceneId) {
  // data

  const issuesets = useSceneListings(sceneId).filter(
    (l) => l.listingType === "issueset"
  );
  const id = useSelector((s) => s.issues.selectedIssuesListingId);

  // helper

  const selectedIssueset = issuesets.find((s) => s.id === id);

  return selectedIssueset;
}
