import {useSelector} from "react-redux";

import useFetchOrganisations from "../hooks/useFetchOrganisations";

import {Box} from "@mui/material";
import ButtonDialogCreateOrganisation from "./ButtonDialogCreateOrganisation";
import ListOrganisations from "./ListOrganisations";
import SectionSelectedOrganisation from "./SectionSelectedOrganisation";

export default function PageOrganisations() {
  // init

  useFetchOrganisations();

  // data

  const organisations = useSelector((s) => s.organisations.items);

  return (
    <Box sx={{display: "flex", width: 1, height: 1}}>
      <Box sx={{flexGrow: 1}}>
        <ButtonDialogCreateOrganisation />
        <ListOrganisations organisations={organisations} />
      </Box>
      <Box sx={{width: 450, bgcolor: "common.white"}}>
        <SectionSelectedOrganisation />
      </Box>
    </Box>
  );
}
