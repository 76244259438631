export default function useRoomsByScene(scene) {
  //
  let rooms = scene?.data?.rooms;
  if (!rooms) rooms = [];
  rooms = rooms
    .filter((r) => r?.id)
    .map((r) => ({...r, num: r.num ? r.num : "0"}));
  //
  return rooms;
}
