import {GridHelper} from "three";

class Grid {
  constructor() {
    this.object = new GridHelper(100, 50);
    this.object.layers.enable(2);
  }

  load(scene) {
    scene.add(this.object);
  }

  toggleVisibility() {
    this.object.visible = !this.object.visible;
  }

  get visibility() {
    return this.object.visible;
  }

  show() {
    this.object.visible = true;
  }

  hide() {
    this.object.visible = false;
  }
}

export default Grid;
