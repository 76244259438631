import React, {useState} from "react";

import {useSelector} from "react-redux";
import useMarkersByScene from "Features/markers/hooks/useMarkersByScene";

import {
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  Autocomplete,
  alpha,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function ActionSelectMarker({onSelect, scene}) {
  const {t} = useTranslation(["common,viewer3D"]);

  // string
  const nextString = capitalizeStart(t("common:save"));
  const markerNameString = t(
    "viewer3D:config.processConfigTwoMarkers.action.markerName"
  );

  // data

  const markers = useMarkersByScene(scene?.clientId);

  // local state

  const [marker, setMarker] = useState();

  // helper

  function handleMarkerChange(e, v) {
    setMarker(v);
  }

  function handleNextClick() {
    setMarker(undefined);
    onSelect(marker);
  }

  return (
    <Paper
      elevation={12}
      sx={{
        display: "flex",
        alignItems: "flex-end",
        backgroundColor: "common.caplaBlack",
        //border: (theme) => `1px solid ${theme.palette.primary.main}`,
        p: 1,
        color: "common.white",
      }}
    >
      <Autocomplete
        size="small"
        options={markers}
        getOptionLabel={(option) => option.description}
        sx={{
          width: 200,
          mr: 2,
          "& .MuiAutocomplete-popupIndicator": {
            color: "common.white",
          },
        }}
        onChange={handleMarkerChange}
        renderOption={(props, option) => (
          <Typography {...props} variant="body2" sx={{pl: 1}}>
            {option.description}
          </Typography>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <Typography variant="body2" sx={{color: "common.white"}}>
                {markerNameString}
              </Typography>
            }
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              sx: {
                fontSize: (theme) => theme.typography.body2.fontSize,
                color: "common.white",
                "& fieldset": {
                  borderColor: "white !important",
                },
                "&:hover fieldset": {
                  borderColor: "white !important",
                },
                "&.Mui-focused": {
                  borderColor: "white !important",
                },
              },
            }}
          />
        )}
      />
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        onClick={handleNextClick}
      >
        {nextString}
      </Button>
    </Paper>
  );
}
