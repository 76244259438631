import imagesLogoWhite from "./assets/images-white-100.png";

import docsLogo from "./assets/documents-color-100.png";
import docsLogoWhite from "./assets/documents-white-100.png";

import metadocsLogoWhite from "./assets/metadocs-white-100.png";

import observationsLogoWhite from "./assets/observations-white-100.png";

import tasksLogoWhite from "./assets/tasks-white-100.png";

import picturesLogoWhite from "./assets/pictures-white-100.png";

import notesLogo from "./assets/notes-color-100.png";
import notesLogoWhite from "./assets/notes-white-100.png";

import quantitiesLogoWhite from "./assets/quantities-white-100.png";

import companiesLogoWhite from "./assets/companies-white-100.png";

import personsLogoWhite from "./assets/persons-white-100.png";

import genericjobsLogoWhite from "./assets/genericjobs-white-100.png";

import risksLogoWhite from "./assets/risks-white-100.png";

import buildingsLogoWhite from "./assets/buildings-white-100.png";

import zonesLogoWhite from "./assets/zones-white-100.png";

import objectsLogo from "./assets/objects-color-100.png";
import objectsLogoWhite from "./assets/objects-white-100.png";

import openingsLogo from "./assets/openings-color-100.png";
import openingsLogoWhite from "./assets/openings-white-100.png";

import mapLogo from "./assets/map-color-100.png";
import mapLogoWhite from "./assets/map-white-100.png";

import mapviewsLogoWhite from "./assets/mapviews-white-100.png";
import mapviewsLogo from "./assets/mapviews-color-100.png";

import psetsLogo from "./assets/psets-color-100.png";
import psetsLogoWhite from "./assets/psets-white-100.png";

import rowsLogo from "./assets/rows-color-100.png";
import rowsLogoWhite from "./assets/rows-white-100.png";

import contributionsLogo from "./assets/contributions-color-100.png";
import contributionsLogoWhite from "./assets/contributions-white-100.png";

import {CheckCircle as Record} from "@mui/icons-material";

import {
  blue,
  teal,
  yellow,
  red,
  deepOrange,
  green,
  pink,
  blueGrey,
  purple,
  deepPurple,
  brown,
  orange,
  amber,
  indigo,
} from "@mui/material/colors";

export default function useListingtypes() {
  // strings

  const createIssuesetS = "Create a notebook";
  const createQuantitysetS = "Create a nomenclature of quantities";
  const createRecordsetS = "Create a list of progress records";

  // data

  const listingTypes = [
    {
      id: "imageset",
      name: "Images",
      imageWhite: imagesLogoWhite,
      color: pink[500],
      createString: "Créez une image.",
    },
    {
      id: "pictureset",
      name: "Pictures",
      imageWhite: picturesLogoWhite,
      color: indigo[600],
      createString: "Ajoutez une photo",
    },
    {
      id: "docset",
      name: "Fichiers",
      image: docsLogo,
      imageWhite: docsLogoWhite,
      color: deepPurple[400],
      createString: "Créez un document.",
    },
    {
      id: "metadocset",
      name: "Documents",
      imageWhite: metadocsLogoWhite,
      color: deepPurple[600],
    },
    {
      id: "mapset",
      name: "Maps",
      image: mapLogo,
      imageWhite: mapLogoWhite,
      color: blue[400],
      createString: "Créez une carte.",
    },
    {
      id: "mapviewset",
      name: "Vues 2d",
      image: mapviewsLogo,
      imageWhite: mapviewsLogoWhite,
      color: blue[500],
      createString: "Créez une vue 2D.",
    },
    {
      id: "buildingset",
      name: "Ouvrages",
      imageWhite: buildingsLogoWhite,
      color: blueGrey[400],
    },
    {
      id: "zoneset",
      name: "Zones",
      imageWhite: zonesLogoWhite,
      color: blueGrey[500],
    },

    {
      id: "objectset",
      name: "Objets",
      image: objectsLogo,
      imageWhite: objectsLogoWhite,
      color: blueGrey[700],
      createString: "Créez un objet.",
    },
    {
      id: "openingset",
      name: "Réservations",
      image: openingsLogo,
      imageWhite: openingsLogoWhite,
      color: blueGrey[700],
      createString: "Créez une réservation.",
    },
    {
      id: "genericjobset",
      name: "Postes de travail standards",
      imageWhite: genericjobsLogoWhite,
      color: brown[500],
    },
    {
      id: "taskset",
      name: "Tâches",
      imageWhite: tasksLogoWhite,
      color: orange[500],
    },
    {
      id: "observationset",
      name: "Observations",
      imageWhite: observationsLogoWhite,
      color: amber[600],
    },
    {
      id: "noteset",
      name: "Notes",
      image: notesLogo,
      imageWhite: notesLogoWhite,
      color: deepOrange[500],
      createString: "Créez une note.",
    },
    {
      id: "quantityset",
      name: "Quantities",
      imageWhite: quantitiesLogoWhite,
      color: green[500],
      createSetString: createQuantitysetS,
    },
    {
      id: "issueset",
      name: "Notes",
      image: notesLogo,
      imageWhite: notesLogoWhite,
      color: deepPurple[500],
      createSetString: createIssuesetS,
    },
    {
      id: "recordset",
      name: "Progress",
      icon: <Record sx={{fontSize: 16}} />,
      color: purple[500],
      createSetString: createRecordsetS,
    },
    {
      id: "riskset",
      name: "Risques",
      imageWhite: risksLogoWhite,
      color: red[700],
    },
    {
      id: "companyset",
      name: "Entreprises",
      imageWhite: companiesLogoWhite,
      color: green[500],
    },
    {
      id: "personset",
      name: "Intervenants",
      imageWhite: personsLogoWhite,
      color: green[700],
    },

    {
      id: "psetset",
      name: "Tableaux de propriétés",
      image: psetsLogo,
      imageWhite: psetsLogoWhite,
      color: teal[500],
      metaFields: [{id: "psetTemplateId", type: "int", name: "ID du modèle"}],
      createString: "Créez un tableau de propriétés.",
    },
    {
      id: "rowset",
      name: "Tableau",
      image: rowsLogo,
      imageWhite: rowsLogoWhite,
      color: teal[400],
    },
    {
      id: "contributionset",
      name: "Contributions",
      image: contributionsLogo,
      imageWhite: contributionsLogoWhite,
      color: teal[400],
      metaFields: [{id: "categories", type: "string", name: "Catégories"}],
    },
  ];

  return listingTypes;
}
