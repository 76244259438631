import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setOpenSections} from "Features/ui/uiSlice";
import useImportedAnnotationsCount from "../hooks/useImportedAnnotationsCount";

import {Box, Typography, Button} from "@mui/material";

export default function SectionImportOverview() {
  const dispatch = useDispatch();

  // string

  const importsS = "imports";
  const annotationsS = "annotations";

  const seeTableS = "Ouvrir la table de correspondance";

  // data

  const {count, total, ratio} = useImportedAnnotationsCount();
  const os = useSelector((s) => s.ui.openSections);

  // helpers

  const label = `${importsS} / ${total} ${annotationsS}`;

  const showOpen = !os.outlet;

  // handlers

  function handleClick() {
    dispatch(
      setOpenSections({
        outlet: true,
        fixedViewersBox: false,
        viewer3D: false,
        callToAction: false,
      })
    );
  }

  return (
    <Box sx={{px: 1}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
          width: 1,
        }}
      >
        <Typography variant="h4" sx={{mr: 1}}>
          {count}
        </Typography>
        <Typography variant="body2">{label}</Typography>
      </Box>
      {showOpen && (
        <Box sx={{color: "text.secondary", mt: 1}}>
          <Button color="inherit" size="small" onClick={handleClick}>
            {seeTableS}
          </Button>
        </Box>
      )}
    </Box>
  );
}
