import {useSelector} from "react-redux";

import useRessourcesByScene from "./useRessourcesByScene";

export default function useRessourceGroupsByScene(scene, options) {
  // /!\ group = group.name (the hook is kept unchanged with remote groups)
  // we remove the behaviour with initRessourceGroups and tempGroup
  // we should change this hook with useRessourcesGroupsProxy

  const ressources = useRessourcesByScene(scene, {filterByScope: true});
  // const initRessourceGroups = useSelector(
  //   (s) => s.ressources.initRessourceGroups
  // );
  // const tempGroup = useSelector((s) => s.ressources.tempGroup);

  let groupsFromRessources = [
    ...new Set(
      ressources
        .filter((r) => r.group && typeof r.group === "string")
        .map((r) => r.group)
    ),
  ];

  if (options?.sortByName) {
    groupsFromRessources = groupsFromRessources.sort((a, b) =>
      a.localeCompare(b)
    );
  }

  return groupsFromRessources;
}
