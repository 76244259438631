import {useSelector, useDispatch} from "react-redux";

import {Box, FormControlLabel, Switch, Typography} from "@mui/material";

import {setShowReorderHelpers} from "../ressourcesSlice";

export default function SwitchShowReorderHelpers() {
  const dispatch = useDispatch();

  // strings

  const showS = "Changer l'ordre";
  // data

  const show = useSelector((s) => s.ressources.showReorderHelpers);

  // handlers

  function handleSwitchChange(e, checked) {
    dispatch(setShowReorderHelpers(checked));
  }
  return (
    <Box sx={{pl: 2}}>
      <FormControlLabel
        color="inherit"
        control={
          <Switch checked={show} onChange={handleSwitchChange} size="small" />
        }
        label={<Typography sx={{fontSize: 13}}>{showS}</Typography>}
      />
    </Box>
  );
}
