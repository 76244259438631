import {IconButton, Box, alpha, Tooltip} from "@mui/material";
import {Navigation as Localise} from "@mui/icons-material";

export default function ButtonCenterCam1({editor3d}) {
  function handleClick() {
    const cam1 = editor3d?.cameras.camera1;
    const orb1 = editor3d?.controls.orbitControls1;
    const cam2 = editor3d?.cameras.camera2;

    cam2.updateMatrixWorld();

    cam1.position.setX(cam2.position.x);
    cam1.position.setZ(cam2.position.z);

    cam1.updateMatrixWorld();

    orb1.target.setX(cam2.position.x);
    orb1.target.setZ(cam2.position.z);
    orb1.update();

    editor3d?.camera2Helper.update();
  }

  // strings

  const tooltipString = "Center the map on the observer";

  return (
    <Box
      sx={{
        bgcolor: (theme) => alpha(theme.palette.common.white, 0.8),
        borderRadius: "50%",
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Tooltip title={tooltipString}>
        <IconButton onClick={handleClick} size="small">
          <Localise fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
