/*
 * sector : {id,num,code,name}
 */

import {useDispatch} from "react-redux";
import {updateScene} from "Features/scenes/scenesSlice";

export default function useAddSceneSector(scene) {
  const dispatch = useDispatch();

  const addSceneSector = (sector) => {
    if (!scene) return;
    let oldSectors = scene?.data?.sectors;
    if (!oldSectors) oldSectors = [];

    const newScene = {
      ...scene,
      data: {...scene?.data, sectors: [...oldSectors, sector]},
    };

    dispatch(updateScene({scene: newScene}));
  };

  return addSceneSector;
}
