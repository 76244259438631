import {useDispatch, useSelector} from "react-redux";

import {Box, Typography} from "@mui/material";
import {ArrowForwardIos as Arrow} from "@mui/icons-material";

// import {setSelectedIssueId as setSelectedIssueIdInHome} from "Features/ui/uiSlice";
// import {setLeftPanelSelection} from "Features/leftPanel/leftPanelSlice";
import { setSelectionTabInViewer } from "Features/selection/selectionSlice";
import { setSelectedIssueId } from "Features/issues/issuesSlice";
import {setSelectedIssueId as setSelectedIssueIdInModule} from "Features/ui/uiSlice";

export default function SectionRessourceLinkedIssues({ressourceIssues}) {
  const dispatch = useDispatch();

  // strings

  const notesS = "Notes";

  // data

  const listings = useSelector((s) => s.listings.data);

  // helpers

  const display = ressourceIssues.length > 0 ? "block" : "none";

  // handlers

  function handleIssueClick(issue) {
    console.log("issue", issue);
    // dispatch(setSelectedIssueIdInHome(issue.id));
    // dispatch(setLeftPanelSelection({type: "ISSUE", item: issue}));
    dispatch(setSelectedIssueId(issue.id));
    dispatch(setSelectedIssueIdInModule(issue.id));
    dispatch(setSelectionTabInViewer("issue"));
  }

  return (
    <Box sx={{display}}>
      <Typography variant="body2" color="text.secondary">
        {notesS}
      </Typography>
      {ressourceIssues.map((issue) => {
        const listing = listings.find(
          (listing) => listing?.id === issue.listing
        );
        return (
          <Box key={issue.id} sx={{p: 1}}>
            <Box
              sx={{
                width: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                bgcolor: "background.default",
                borderRadius: "16px",
                cursor: "pointer",
              }}
              onClick={() => handleIssueClick(issue)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  minWidth: 0,
                }}
              >
                {listing?.name && (
                  <Typography sx={{mr: 0.5}} variant="body2" noWrap>
                    {`${listing?.name} • `}
                  </Typography>
                )}
                <Typography variant="body2" noWrap>
                  {issue?.title}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Arrow fontSize="small" />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
