import React from "react";

import {Box, Divider} from "@mui/material";

import ListItemButtonCreateScene from "./ListItemButtonCreateScene";
import ListItemButtonProfile from "./ListItemButtonProfile";
import MenuItemsHome from "./MenuItemsHome";
import CardCallToActionSignin from "Features/auth/components/CardCallToActionSignin";

import {useIsAuthenticated} from "@azure/msal-react";

export default function NavSectionHome() {
  // data

  const isAuthenticated = useIsAuthenticated();

  //
  return (
    <Box sx={{px: 1, mt: 4}}>
      {/* <ListItemButtonCreateScene />
      <Box sx={{p: 1}} /> */}
      <MenuItemsHome />
      <Divider sx={{my: 2}} />
      {isAuthenticated ? (
        <ListItemButtonProfile />
      ) : (
        <CardCallToActionSignin open={true} />
      )}
    </Box>
  );
}
