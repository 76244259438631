import {Box} from "@mui/material";

import useSelectionFromClickedObject from "Features/selection/useSelectionFromClickedObject";
import SectionIssueInSelectionPanel from "Features/issues/components/SectionIssueInSelectionPanel";
import SectionZoneInSelectionPanel from "Features/zones/components/SectionZoneInSelectionPanel";
import SectionMeasurementInSelectionPanel from "Features/measurements/components/SectionMeasurementInSelectionPanel";

export default function SectionViewer3DSelectionInSelectionPanel({
  scene,
  caplaEditor,
}) {
  const selection = useSelectionFromClickedObject({scene, editorPdf: caplaEditor?.editorPdf});

  return (
    <Box>
      {selection?.type === "ZONE" && (
        <SectionZoneInSelectionPanel scene={scene} caplaEditor={caplaEditor} />
      )}
      {selection?.type === "MEASUREMENT" && (
        <SectionMeasurementInSelectionPanel scene={scene} editorPdf={caplaEditor?.editorPdf} />
      )}
      {selection?.type === "ISSUE" && (
        <SectionIssueInSelectionPanel scene={scene} caplaEditor={caplaEditor} />
      )}
    </Box>
  );
}
