import {Box} from "@mui/material";

import SectionActionsSurveys from "./SectionActionsSurveys";

export default function SectionLeftPanelActionsSurveys({scene, caplaEditor}) {
  // actions - main

  const actionsMainKeys = ["fetchSurveys", "createSurvey", "editSurvey"];
  const actionsMoreKeys = ["deleteSurvey"];

  return (
    <Box>
      <SectionActionsSurveys
        scene={scene}
        caplaEditor={caplaEditor}
        actionsMainKeys={actionsMainKeys}
        actionsMoreKeys={actionsMoreKeys}
      />
    </Box>
  );
}
