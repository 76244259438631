import {useEffect} from "react";

import {useDispatch} from "react-redux";
import {fetchRoomsGroups} from "../roomsSlice";

import useAccessToken from "Features/auth/useAccessToken";

export default function useInitFetchRoomsGroups(scene) {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  useEffect(() => {
    if (scene?.id && accessToken) {
      dispatch(fetchRoomsGroups({accessToken, sceneId: scene?.id}));
    }
  }, [accessToken, scene?.id]);
}
