import React from "react";

import {useSelector} from "react-redux";

import usePageType from "../usePageType";

import {Box} from "@mui/material";

import Header from "./Header";
import BottomBar from "./BottomBar";
import MenuItemCreateScene from "./MenuItemCreateScene";
import NavSectionHome from "./NavSectionHome";
import NavSectionScene from "./NavSectionScene";

import zoomInOut from "Features/ui/zoomInOut";

export default function NavPanel({scene}) {
  // data

  const pageType = usePageType();

  const open = useSelector((s) => s.navigation.open);
  const navPanelWidth = useSelector((s) => s.navigation.navPanelWidth);

  const animateNavPanel = useSelector((s) => s.ui.animateNavPanel);

  // render

  return (
    <Box
      sx={{
        display: open ? "flex" : "none",
        flexDirection: "column",
        width: navPanelWidth,
        height: 1,
        minWidth: navPanelWidth,
        bgcolor: "background.dark",
        borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        overflowY: "auto",
        position: "relative",
        ...(animateNavPanel && {
          borderColor: "primary.flash",
          borderStyle: "solid",
          borderWidth: "3px",
          animation: `${zoomInOut} 1s infinite ease`,
        }),
      }}
    >
      <Header />
      <Box sx={{flexGrow: 1, width: 1}}>
        {(pageType === "HOME" ||
          pageType === "PROFILE" ||
          pageType === "TOOLBOX" ||
          pageType === "ORDER" ||
          pageType === "COLORINGS" ||
          pageType === "COLORING" ||
          pageType === "WORKSITES" ||
          pageType === "LIBRARY" ||
          pageType === "PRICINGS" ||
          pageType === "NEW_SCENE") && <NavSectionHome />}
        {pageType === "SCENE" && <NavSectionScene scene={scene} />}
      </Box>

      <BottomBar />
    </Box>
  );
}
