import {Typography, Box} from "@mui/material";

import useIssuesByScene from "Features/issues/useIssuesByScene";
import PdfExtractedText from "Features/textEditor/components/TextEditor/components/PdfExtractedText";

export default function SectionRessourceLinkedIssuesExtractedText({
  scene,
  editorPdf,
  ressource,
}) {
  // strings

  const title = "Extraits des pièces écrites";

  // data

  const issues = useIssuesByScene(scene?.id);
  // const listings = useSelector((s) => s.listings.data);

  // helpers

  const ressourceIssues = issues.filter((issue) =>
    issue.linkedTo?.ressources?.includes(ressource.id)
  );

  const pdfExtractedTexts = [];
  ressourceIssues.forEach((issue) => {
    const content = issue.content ? issue.content : [];
    const texts = content.filter(
      (element) => element.type === "pdfExtractedText"
    );
    pdfExtractedTexts.push(...texts);
  });

  const noText = pdfExtractedTexts.length === 0;

  const display = noText ? "none" : "block";

  //
  return (
    <Box sx={{display, p: 0.5, "&>*": {mb: 1}}}>
      <Typography variant="body2" color="text.secondary" gutterBottom>
        {title}
      </Typography>
      {pdfExtractedTexts.map((element) => (
        <PdfExtractedText
          attributes={{}}
          children
          element={element}
          editorPdf={editorPdf}
        />
      ))}
    </Box>
  );
}
