import React from "react";

import {useSelector, useDispatch} from "react-redux";
import {setProgressTabsIndex} from "Features/progress/progressSlice";

import {Box, Tabs, Tab} from "@mui/material";

export default function TabsProgress() {
  const dispatch = useDispatch();

  // strings

  const label1 = "Records";
  const label2 = "Elements";

  // data

  const index = useSelector((s) => s.progress.progressTabsIndex);

  // handlers

  function handleChange(e, v) {
    dispatch(setProgressTabsIndex(v));
  }
  return (
    <Box
      sx={{
        mt: 2,
        width: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Tabs value={index} onChange={handleChange}>
        <Tab label={label1} />
        <Tab label={label2} />
      </Tabs>
    </Box>
  );
}
